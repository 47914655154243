import React, { useEffect } from "react";
import LoadingIndicator from "src/generic/loading-indicator";
import UserTable from "./user-table/user-table";
import { useUserManagement } from "core/user-management/user-management-context";
import NoSearchResults from "../../common/no-search-results";

const UsersListPage = () => {
    const { userManagementProviderBusy, users, getUsers, filter, filteredUsers } = useUserManagement();

    useEffect(() => {
        getUsers();
    }, []);

    return userManagementProviderBusy ? (
        <LoadingIndicator isGlobal={false} />
    ) : filter.isUserFilterActive && filter.isResultEmpty ? (
        <NoSearchResults />
    ) : (
        <UserTable users={filter.isUserFilterActive ? filteredUsers : users} />
    );
};

export default UsersListPage;
