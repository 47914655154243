import React from "react";
import { StriimCheckbox, StriimTypography, StriimMessageBox, StriimControlLabel } from "@striim/striim-ui";
import { Formik, Form } from "formik";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import * as Yup from "yup";
import { Grid, Box } from "@mui/material";

import FormikField from "src/generic/formik-field";
import CustomFormikField from "./formik-field";
import PropertySetSelect from "../../../../common/propertyset-select";
import { USER_MANAGEMENT_STRINGS } from "../../../constants";
import Dictionary from "app/components/common/helpable/online-help-dictionary";
import Tracker from "../../../../../../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../../../../../core/services/tracker/constants";

const validationSchema = Yup.object({
    name: Yup.string()
        .required("Required")
        .test("cannotStartWithaNumber", "Striim username cannot start with a number", value => isNaN(parseInt(value)))
        .test("noSpaces", "Striim username cannot contain spaces", value => value && value.indexOf(" ") === -1)
        .matches(new RegExp(/^\w+$/), "Striim username can contain only letters, numbers or underscores"),
    email: Yup.string().email(),
    password: Yup.string().test("passwordRequired", "Required", function(password) {
        if (this.parent.ldapuser || password) {
            return true;
        }
        return false;
    }),
    ldapUserId: Yup.string().when("ldapuser", {
        is: true,
        then: Yup.string().required("Required")
    }),
    propertyset: Yup.string().when("ldapuser", {
        is: true,
        then: Yup.string().required("Required")
    }),
    ldapuser: Yup.boolean(),
    phone: Yup.string().matches(new RegExp(/^[0-9]{0,15}$/), "Must be a valid number")
});

const CreateUserBasicInfo = ({ formRef }) => {
    const getUsernameHelperText = isLdapUser =>
        (isLdapUser
            ? USER_MANAGEMENT_STRINGS.helperText.usernameLDAP
            : USER_MANAGEMENT_STRINGS.helperText.username
        ).map(v => (
            <React.Fragment key={v}>
                <FiberManualRecordIcon sx={{ height: "8px" }} />
                <StriimTypography variant="caption3" color="greyscale.600" sx={{ fontFamily: "Inter" }}>
                    {v}
                </StriimTypography>
                <br />
            </React.Fragment>
        ));

    const ldapMessageInfoHeading = (
        <Grid container spacing={3} paddingRight={4} color="greyscale.900">
            <Grid item xs={12}>
                <StriimTypography variant="body4" color="greyscale.900">
                    Striim combines the LDAP property specified in USER_USERID with the USER_BASE_DN to look up users in
                    LDAP.
                </StriimTypography>
            </Grid>
            <Grid item xs={12}>
                <StriimTypography variant="body4" color="greyscale.900">
                    For example, with the property set below, and the LDAP User ID jsmith, Striim would search for:
                    "uid=jsmith,ou=Users, dc=mydomain,dc=com"
                </StriimTypography>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <StriimTypography variant="bodySemibold" color="additional.azure">
                            CREATE PROPERTYSET LDAP1{" "}
                        </StriimTypography>
                        (
                    </Grid>
                    <Grid item xs={12}>
                        <StriimTypography variant="body4" color="greyscale.900">
                            PROVIDER_URL:"
                        </StriimTypography>
                        <StriimTypography color="success.800">ldap://203.0.113.45:389/</StriimTypography>
                        ",
                    </Grid>
                    <Grid item xs={12}>
                        <StriimTypography variant="body4" color="greyscale.900">
                            SECURITY_AUTHENTICATION:
                        </StriimTypography>
                        <StriimTypography color="success.800">simple</StriimTypography>,
                    </Grid>
                    <Grid item xs={12}>
                        <StriimTypography variant="body4" color="greyscale.900">
                            SECURITY_PRINCIPAL: "
                        </StriimTypography>
                        <StriimTypography color="success.800">cn=Manager,dc=mydomain,dc=com</StriimTypography>
                        ",
                    </Grid>
                    <Grid item xs={12}>
                        <StriimTypography variant="body4" color="greyscale.900">
                            SECURITY_CREDENTIALS:
                        </StriimTypography>
                        <StriimTypography color="success.800">secret</StriimTypography>,
                    </Grid>
                    <Grid item xs={12} sx={{ wordWrap: "break-word" }}>
                        <StriimTypography variant="body4" color="greyscale.900">
                            USER_BASE_DN:"
                        </StriimTypography>
                        <StriimTypography color="success.800" noWrap={false}>
                            ou=Users,dc=mydomain,dc=com
                        </StriimTypography>
                        ",
                    </Grid>
                    <Grid item xs={12}>
                        <StriimTypography variant="body4" color="greyscale.900">
                            USER_RDN:
                        </StriimTypography>
                        <StriimTypography color="success.800">uid</StriimTypography>,
                    </Grid>
                    <Grid item xs={12}>
                        <StriimTypography variant="body4" color="greyscale.900">
                            USER_USERID:
                        </StriimTypography>
                        <StriimTypography color="success.800">uid</StriimTypography>);
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <StriimTypography
                    variant="body4"
                    component="a"
                    href={Dictionary.get()["USERS_LDAP_AUTHENTICATION"].href}
                    target="_blank"
                    sx={{
                        color: ({ palette }) => `${palette.secondary[500]} !important`
                    }}
                    onClick={() => {
                        Tracker.getInstance().track(TRACKER_STRINGS.schema.learnMore, {
                            event: TRACKER_STRINGS.eventClicked.learnMore
                        });
                        return true;
                    }}
                >
                    Learn more
                </StriimTypography>
                <StriimTypography variant="body4" color="greyscale.900" ml={1}>
                    about using LDAP authentication.
                </StriimTypography>
            </Grid>
        </Grid>
    );

    const getFormattedStriimUsername = value => {
        return value?.toLowerCase().replace(/[^a-z0-9]/g, "");
    };

    const handleLdapUserIdChange = (value, setFieldValue) => {
        const username = getFormattedStriimUsername(value);
        formRef.current.values.ldapIdEqualsUsername && setFieldValue("name", username);
        setFieldValue("ldapUserId", value);
    };

    return (
        <>
            <Grid container>
                <Formik
                    initialValues={{
                        name: "",
                        email: "",
                        password: "",
                        propertyset: "",
                        ldapUserId: "",
                        ldapuser: false,
                        ldapIdEqualsUsername: false,
                        firstName: "",
                        lastName: "",
                        phone: "",
                        timezone: ""
                    }}
                    validationSchema={validationSchema}
                    validateOnChange
                    innerRef={formRef}
                >
                    {({ values, handleChange, setFieldValue }) => {
                        values.propertyset = values?.ldapuser ? values.propertyset : "";
                        values.password = !values?.ldapuser ? values.password : "";
                        values.ldapUserId = values?.ldapuser ? values.ldapUserId : "";
                        values.name = values.ldapIdEqualsUsername
                            ? getFormattedStriimUsername(values.ldapUserId)
                            : values.name;
                        return (
                            <Box component={Form}>
                                <Grid item xs={12}>
                                    <Grid container direction="column">
                                        <Grid item xs={12}>
                                            <StriimTypography variant="h3" color="primary.900">
                                                {USER_MANAGEMENT_STRINGS.label.basicInfo}
                                            </StriimTypography>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} mt={1}>
                                                <StriimControlLabel
                                                    control={
                                                        <StriimCheckbox
                                                            onChange={e => {
                                                                handleChange(e);
                                                                values.password = "";
                                                                values.propertyset = "";
                                                                values.ldapUserId = "";
                                                                setFieldValue("ldapIdEqualsUsername", false);
                                                            }}
                                                            name="ldapuser"
                                                        />
                                                    }
                                                    sx={{ ".MuiTypography-root": { color: "greyscale.600" } }}
                                                    label="LDAP user"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <StriimTypography color="greyscale.600" variant="caption3">
                                                    {USER_MANAGEMENT_STRINGS.label.ldapInfo}
                                                </StriimTypography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} justifyContent="space-between">
                                            <Grid container item xs={6} md={5} lg={4} spacing={2}>
                                                {values.ldapuser && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Box
                                                                required
                                                                component={CustomFormikField}
                                                                name="ldapUserId"
                                                                label={USER_MANAGEMENT_STRINGS.inputLabel.ldapUserId}
                                                                placeholder="Input value"
                                                                helperText={
                                                                    USER_MANAGEMENT_STRINGS.helperText.ldapUserId
                                                                }
                                                                disabled={!values.ldapuser}
                                                                setFieldValue={setFieldValue}
                                                                onChange={handleLdapUserIdChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <StriimControlLabel
                                                                control={
                                                                    <StriimCheckbox
                                                                        onChange={e => {
                                                                            handleChange(e);
                                                                        }}
                                                                        name="ldapIdEqualsUsername"
                                                                    />
                                                                }
                                                                label={
                                                                    USER_MANAGEMENT_STRINGS.label.ldapIdEqualsUsername
                                                                }
                                                                sx={{
                                                                    ".MuiTypography-root": { color: "greyscale.600" }
                                                                }}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                <Grid item xs={12}>
                                                    <Box
                                                        required
                                                        component={FormikField}
                                                        name="name"
                                                        label={USER_MANAGEMENT_STRINGS.inputLabel.username}
                                                        helperText={
                                                            values.ldapIdEqualsUsername
                                                                ? USER_MANAGEMENT_STRINGS.helperText.generatedUsername
                                                                : getUsernameHelperText(values.ldapuser)
                                                        }
                                                        disabled={values.ldapuser && values.ldapIdEqualsUsername}
                                                    />
                                                </Grid>
                                                {!values.ldapuser ? (
                                                    <Grid item xs={12}>
                                                        <Box
                                                            required
                                                            component={FormikField}
                                                            name="password"
                                                            label={USER_MANAGEMENT_STRINGS.inputLabel.password}
                                                            helperText={USER_MANAGEMENT_STRINGS.helperText.password}
                                                            disabled={values.ldapuser}
                                                            type="password"
                                                            previewPassword
                                                        />
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12}>
                                                        <Box
                                                            component={PropertySetSelect}
                                                            name="propertyset"
                                                            label={USER_MANAGEMENT_STRINGS.inputLabel.propertyset}
                                                            placeholder="Select"
                                                            helperText={USER_MANAGEMENT_STRINGS.helperText.propertySet}
                                                            disabled={!values.ldapuser}
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item xs={12}>
                                                    <Box
                                                        component={FormikField}
                                                        name="firstName"
                                                        label={USER_MANAGEMENT_STRINGS.inputLabel.firstName}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box
                                                        component={FormikField}
                                                        name="lastName"
                                                        label={USER_MANAGEMENT_STRINGS.inputLabel.lastName}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box
                                                        component={FormikField}
                                                        name="email"
                                                        label={USER_MANAGEMENT_STRINGS.inputLabel.email}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box
                                                        component={FormikField}
                                                        name="phone"
                                                        label={USER_MANAGEMENT_STRINGS.inputLabel.phone}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box
                                                        component={FormikField}
                                                        name="timezone"
                                                        label={USER_MANAGEMENT_STRINGS.inputLabel.timezone}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={6} justifyContent="center">
                                                {values.ldapuser && (
                                                    <Grid item>
                                                        <StriimMessageBox
                                                            customCardStyles={{
                                                                maxWidth: "360px",
                                                                ".MuiCardHeader-content": {
                                                                    width: "100%"
                                                                }
                                                            }}
                                                            text={ldapMessageInfoHeading}
                                                            type="NOTIFICATION"
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        );
                    }}
                </Formik>
            </Grid>
        </>
    );
};

export default CreateUserBasicInfo;

export { validationSchema };
