import uiControl from "app/components/common/editor/control/wae-to-typed-event/wae-to-typed-event";
import resolver from "./typedEventTypesResolver";

var Transformation = {};

Transformation.OnlyDbSources = true;

Transformation.createColumnControl = function() {
    return uiControl
        .extend({
            hideLabel: true,
            hidden: false
        })
        .create();
};

Transformation.buildTql = function(tqlBuilder, columns) {
    for (let i = 0; i < columns.length; i++) {
        var column = columns[i];
        if (!column.type || !column.column) {
            return;
        }

        let type = column?.type;
        if (typeof column.type === "object") {
            type = type?.type;
        }
        tqlBuilder.addColumn("data[" + i + "]", GetConvertFunction(type), null, column.column);

        if (i === 1) {
            tqlBuilder.addMetaFilteringStatement("TableName", "=", column.table);
        }
    }
};

function GetConvertFunction(type) {
    return resolver(type);
}

export default Transformation;
