import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import resources from "app/components/common/editor/resources_en.json";

var module = {};
module.validate = function (model, connectionCheckAPI) {
    if (connectionCheckAPI) {
        return $.when(module.internalValidate(model), connectionCheckAPI(model));
    } else {
        return $.when(module.internalValidate(model));
    }
};

module.internalValidate = function (model) {
    var $def = new $.Deferred();
    console.log(model);
    var validator = new module._Validator(model);
    validator.validate();
    if (validator.errors.length > 0) {
        $def.reject(validator.errors);
    } else {
        $def.resolve();
    }

    return $def;
};

module._Validator = function (model) {
    this.model = model;
    this.errors = [];
    this.required_fields = ["name", "inputStream", "adapter"];
    this.populateAdapterProps();
};
module._Validator.prototype = {
    populateAdapterProps: function () {
        var that = this;
        if (this.model.get("adapter").handler) {
            var adapter = propertyTemplateService.getPropertyTemplateById(this.model.get("adapter").handler);
            if (!adapter) {
                return;
            }
            _.each(adapter.propertyMap, function (entry) {
                if (entry.required) {
                    that.required_fields.push("adapter." + entry.name);
                }
            });
            if (adapter.get("requiresFormatter")) {
                this.required_fields.push("formatter");
                this.populateFormatterProps();
            }
        }
    },
    populateFormatterProps: function () {
        var that = this;
        if (this.model.get("formatter").handler) {
            var adapter = propertyTemplateService.getPropertyTemplateById(this.model.get("formatter").handler);
            _.each(adapter.propertyMap, function (entry) {
                if (entry.required) {
                    that.required_fields.push("formatter." + entry.name);
                }
            });
        }
    },
    validate: function () {
        var that = this;
        _.each(this.required_fields, function (required_field) {
            if (required_field.indexOf(".") === -1) {
                that.checkInMainObject(required_field);
            } else {
                that.checkInSubObject(required_field);
            }
        });
    },
    checkInMainObject: function (required_field) {
        if (!this.isValid(this.model.get(required_field))) {
            var lang = resources[required_field.toLowerCase()];
            var text = lang ? lang.name : required_field;
            this.errors.push(text);
        }
    },
    checkInSubObject: function (required_field) {
        var chunks = required_field.split(".");
        var parent = chunks[0];
        var child = chunks[1];
        if (!this.isValid(this.model.get(parent)["properties"][child])) {
            var lang = resources[child.toLowerCase()];
            var text = lang ? lang.name : child;
            this.errors.push(text);
        }
    },

    isValid: function (value) {
        if (!_.isEmpty(value) && value.handler !== "") {
            return true;
        }

        return false;
    },
};

export default {
    validate: module.validate,
};
