import App from "app";
import React from "react";
import ReactDOM from "react-dom/client";
import api from "core/api/api";
import _ from "underscore";
import growl from "app/components/common/growl";
import { template, className, regions, ui, triggers } from "core/decorators";
import { LayoutView } from "marionette";
import mainTemplate from "./templates/main-template.html";
import setupTemplate from "./templates/setup-template.html";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import MetaObjects from "core/services/metaStoreService/meta-objects";
import FormBuilder from "app/components/common/editor/form-builder";
import UIControl from "app/components/common/editor/control/ui-control";
import $ from "jquery";
import Backbone from "backbone";
import PageBlocker from "app/components/flow/designer/page-blocker";
import alertDialogTemplate from "./templates/alert-dialog-template.html";
import noAlertsTemplate from "./templates/alerts-not-setup-template.html";
import ModalManager from "app/components/common/modal/ModalManager";
import NProgress from "nprogress";
import securityService from "core/services/securityService/securityService";
import { LeftSidebar } from "./alertmanager-left-sidebar";
import { TopSection } from "./alertmanager-top-section";
import { AlertManagerDetailView } from "./alertmanager-right-content";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import MarionetteWrapper from "../../components/marionette-wrapper-for-react";
import UpgradeToCloudComponent from "../../../src/modules/apps/pages/unauthorized-pages/upgrade-to-cloud";
import UnauthorizedComponent from "../../../src/modules/apps/pages/unauthorized-pages/alert-manager-unauthorized";
import utils from "core/utils";
import { getSysAlertComponentType, getTab, ALERT_TYPE } from "./alert-utils";
import ConfigValidation from "src/modules/apps/pages/alert-manager/components/config-validation/config-validation";
import navigateTo from "../../../src/navigate-to";

NProgress.configure({
    minimum: 0.25,
    trickleRate: 0.1,
    trickleSpeed: 2000
});

let modalManager = new ModalManager({
    container: "body"
});

/**
 * Main Alert layout with the left and right sidebars.
 */
@template(mainTemplate)
@className("alerts-manager-container")
@regions({
    topSection: ".top-section",
    leftSidebar: ".left-side-bar",
    content: ".content"
})
export class AlertMainLayoutView extends LayoutView {
    onRender() {
        this.listenTo(App.vent, "alertmanager:show:detail", model => {
            this.showDetail(model);
        });
        this.listenTo(App.vent, "alertmanager:add:alert", model => {
            this.alertAdded(model);
        });

        this.listenTo(App.vent, "alertmanager:delete:alert", model => {
            this.updateViewOnDelete(model);
        });

        if (this.model.get("isLiteMode")) {
            this.$el.addClass("lite");
        }
        if (this.model.get("showNew") === true || this.model.get("alertCollection").length === 0) {
            let model = new MetaObjects.SYSALERTRULE.Model();
            this.showDetail(model);
            this.getRegion("leftSidebar").show(new LeftSidebar({ model: this.model }));
        } else {
            if (this.model.get("item")) {
                let model = this.model.get("alertCollection").findWhere({ id: this.model.get("item") });
                if (model) {
                    this.showDetail(model);
                }
            } else {
                this.getRegion("content").show(new ClickDetailView());
            }
            this.getRegion("leftSidebar").show(new LeftSidebar({ model: this.model }));
        }
        this.getRegion("topSection").show(new TopSection({ model: this.model }));
    }

    alertAdded(model) {
        const objectName = model.get("objectName");
        const category = utils.getName(objectName)
            ? utils.getName(objectName)
            : `${getSysAlertComponentType(objectName)}`;
        this.model.get("alertCollection").add(model);
        this.model.set("tab", getTab(model.toJSON()));
        this.model.set("category", category);
        this.model.set("item", model.get("id"));
        this.getRegion("leftSidebar").show(new LeftSidebar({ model: this.model }));
    }

    updateViewOnDelete(model) {
        const objectName = model.get("objectName");
        this.model.set(
            "category",
            utils.getName(objectName) ? utils.getName(objectName) : `${getSysAlertComponentType(objectName)}`
        );
        this.getRegion("leftSidebar").show(new LeftSidebar({ model: this.model }));
        this.showDetail();
    }

    showDetail(model) {
        this.currentDetailModel = model;
        if (model) {
            NProgress.start();
            setTimeout(() => {
                this.$(".left-list-item").removeClass("active");
                this.$('*[data-id="' + model.get("id") + '"]').addClass("active");
                NProgress.done();
            }, 200);

            this.getRegion("content").show(
                new AlertManagerDetailView({
                    model: model,
                    reference: this.model.get("reference")
                })
            );
        } else {
            this.getRegion("content").show(new ClickDetailView());
        }
    }
}

@template('<div class="unauthorizedTemplate"></div>')
@regions({
    alertsUnauthorizedContainer: ".unauthorizedTemplate"
})
class UnauthorizedView extends LayoutView {
    onRender() {
        this.getRegion("alertsUnauthorizedContainer").show(
            MarionetteWrapper(securityService.isDeveloperLicense() ? UpgradeToCloudComponent : UnauthorizedComponent)
        );
    }
}

@template(
    '<div class="click-left-message"><img src="/app/images/striimline/alert-details-empty.svg"/><div class="image-description">Select an alert from the list or <a href="#/alertmanager/new" class="add-alert">create a new alert</a></div></div>'
)
@ui({
    addButton: ".add-alert"
})
class ClickDetailView extends LayoutView {
    onRender() {
        const that = this;
        this.ui.addButton.click(function() {
            let model = new MetaObjects.SYSALERTRULE.Model();
            App.vent.trigger("alertmanager:show:detail", model);
        });
    }
}

const ConnectionModel = Backbone.Model.extend({
    defaults: {
        smtpUrl: "",
        smtpPort: 465,
        smtp_auth: false,
        starttls_enable: false,
        smtpUser: "",
        smtpPassword: "",
        threadCount: 5,
        fromAddress: "",
        SMTPConfig: ""
    },
    validate: function() {
        let validationError = {};
        if (!this.get("smtpUrl")) {
            validationError["SMTPConfig"] = "Please fill the SMTP URL";
        }
        if (!this.get("smtpPort")) {
            validationError["smtpPort"] = "Please fill the SMTP Port";
        }
        if (!this.get("threadCount")) {
            validationError["threadCount"] = "Please specify the number of threads";
        }
        if (!this.get("fromAddress")) {
            validationError["fromAddress"] = "Please specify the FROM address";
        }
        if (this.get("smtp_auth")) {
            if (!this.get("smtpUser")) {
                validationError["smtpUser"] = "SMTP User is required when using SMTP Auth";
            }
            if (!this.get("smtpPassword")) {
                validationError["smtpPassword"] = "SMTP Password is required when using SMTP Auth";
            }
        }
        return _.isEmpty(validationError) ? undefined : validationError;
    }
});

const SlackConnectionModel = Backbone.Model.extend({
    defaults: {
        slackAuthToken: "",
        slackConfig: "",
        validateChannel: ""
    },
    validate: function() {
        let validationError = {};
        if (!this.get("slackAuthToken")) {
            validationError["slackConfig"] = "Please fill the OAuth Token";
        }
        return _.isEmpty(validationError) ? undefined : validationError;
    }
});

const TeamsConnectionModel = Backbone.Model.extend({
    defaults: {
        teamsClientId: "",
        teamsClientSec: "",
        teamsRefToken: "",
        validateChannel: "",
        teamsConfig: ""
    },
    validate: function() {
        let validationError = {};
        if (!this.get("teamsClientId")) {
            validationError["teamsClientId"] = "Please fill the Client ID";
        }
        if (!this.get("teamsClientSec")) {
            validationError["teamsClientSec"] = "Please fill the Client Secret";
        }
        if (!this.get("teamsRefToken")) {
            validationError["teamsRefToken"] = "Please fill the Refresh Token";
        }
        return _.isEmpty(validationError) ? undefined : validationError;
    }
});

const getUserFriendlyType = type => {
    switch (type) {
        case ALERT_TYPE.EMAIL:
            return "email";
        case ALERT_TYPE.SLACK:
            return "Slack";
        case ALERT_TYPE.TEAMS:
            return "Teams";
        case ALERT_TYPE.WEB:
            return "web";
    }
    return type;
};

@regions({
    config: ".setup-config"
})
@template(setupTemplate)
@className("alerts-manager-container")
@ui({
    errorBox: ".validations.message.error",
    errorMessage: ".error-message",
    slackValidation: ".slack-validation",
    teamsValidation: ".teams-validation"
})
export class SetupView extends LayoutView {
    initialize() {
        this.viewEnabled = true;

        if (!securityService.isSegmentationFeatureEnabled(addonFeaturesKeys.ALERTMANAGER)) {
            this.template = _.template("");
            this.viewEnabled = false;
            navigateTo.AlertManager();
            return;
        }
    }

    serializeData() {
        let data = {};
        data["type"] = getUserFriendlyType(this.options.type);
        return data;
    }

    onRender() {
        if (!this.viewEnabled) {
            return;
        }

        this.pageBlocker = new PageBlocker("#top-bar");
        let model;
        let form;
        const { type, existingConfigDtls } = this.options;

        switch (type) {
            case ALERT_TYPE.EMAIL: {
                model = new ConnectionModel();

                if (existingConfigDtls) {
                    const {
                        SMTP_AUTH,
                        SMTP_PORT,
                        SMTP_FROM,
                        SMTP_USER,
                        SMTP_PASSWORD,
                        SMTP_URL,
                        SMTP_START_TLS
                    } = existingConfigDtls;
                    model.set({
                        smtpUrl: SMTP_URL,
                        smtpPassword: SMTP_PASSWORD,
                        smtpPort: SMTP_PORT,
                        fromAddress: SMTP_FROM,
                        smtpUser: SMTP_USER,
                        smtp_auth: SMTP_AUTH,
                        starttls_enable: SMTP_START_TLS
                    });
                }
                form = new FormBuilder({
                    model: model,
                    submitText: "Save",
                    cancelText: "Cancel",
                    readOnly: false,
                    containerClass: " alert-config-form"
                });
                form.addStaticControl(
                    UIControl.HeaderWithHelp("Configure SMTP", "ALERT_CONFIGURE_SMTP").create(),
                    "header"
                );
                form.addStaticControl(
                    UIControl.SMTPConnection(
                        model,
                        "smtpUrl",
                        "smtpPort",
                        "smtpUser",
                        "smtpPassword",
                        "fromAddress",
                        "starttls_enable",
                        "smtp_auth"
                    ).create(),
                    "SMTPConfig"
                );
                this.getRegion("config").show(form.create());
                if (!model.get("smtp_auth")) this.$el.find(".smtp-auth-field").hide();

                break;
            }
            case ALERT_TYPE.SLACK: {
                model = new SlackConnectionModel();

                if (existingConfigDtls) {
                    const { SLACKAUTHTOKEN } = existingConfigDtls;
                    model.set({
                        slackAuthToken: SLACKAUTHTOKEN
                    });
                }

                form = new FormBuilder({
                    model: model,
                    submitText: "Save",
                    cancelText: "Cancel",
                    readOnly: false,
                    containerClass: " alert-config-form",
                    labels: {
                        slackauthtoken: { name: "OAuth Token" },
                        validatechannel: { name: "Channel Name" },
                        validatebutton: { name: "Validate TokenButton" }
                    }
                });
                form.addStaticControl(
                    UIControl.HeaderWithHelp("Configure Slack", "ALERT_CONFIGURE_SLACK").create(),
                    "header"
                );
                form.addRequiredControl(
                    UIControl.TextField.WithPlaceholder("Slack OAuth Token").create(),
                    "slackAuthToken"
                );

                form.addControl(
                    UIControl.TextField.WithPlaceholder("Enter channel name to validate").create(),
                    "validateChannel"
                );
                this.validateButton = UIControl.Button("Validate Token").create();

                var messageBox = UIControl.ErrorBox().create();
                this.validateButton.on("clicked", () => {
                    this.ui.slackValidation.slideUp();
                    let channel = this.$el.find(".from-control-container-Channel_Name input").val();
                    let authToken = this.$el.find(".from-control-container-OAuth_Token input").val();
                    if (!authToken || !channel) {
                        messageBox.setType("ERROR");
                        !authToken
                            ? messageBox.setMessage("Value for OAuth token is required")
                            : messageBox.setMessage("Value for Channel name is required");
                        messageBox.setHide(false);
                        return;
                    }

                    api.sendTestSlack(authToken, channel).then(
                        d => {
                            messageBox.setType("SUCCESS");
                            messageBox.setMessage(d);
                            messageBox.setHide(false);
                        },
                        e => {
                            messageBox.setType("ERROR");
                            messageBox.setMessage(e.message ? e.message : e);
                            messageBox.setHide(false);
                        }
                    );
                });

                form.addStaticControl(messageBox, "errorMessageBox");
                form.addControl(this.validateButton, "validateButton");
                this.getRegion("config").show(form.create());
                break;
            }
            case ALERT_TYPE.TEAMS: {
                model = new TeamsConnectionModel();
                if (existingConfigDtls) {
                    const { TEAMSCLIENTID, TEAMSCLIENTSECRET, TEAMSREFTOKEN } = existingConfigDtls;
                    model.set({
                        teamsClientId: TEAMSCLIENTID,
                        teamsClientSec: TEAMSCLIENTSECRET,
                        teamsRefToken: TEAMSREFTOKEN
                    });
                }
                form = new FormBuilder({
                    model: model,
                    submitText: "Save",
                    cancelText: "Cancel",
                    readOnly: false,
                    containerClass: " alert-config-form"
                });
                form.addStaticControl(
                    UIControl.HeaderWithHelp("Configure Teams", "ALERT_CONFIGURE_TEAMS").create(),
                    "header"
                );
                form.addStaticControl(
                    UIControl.TeamsConnection(model, "teamsClientId", "teamsClientSec", "teamsRefToken").create(),
                    "TeamsConfig"
                );
                this.getRegion("config").show(form.create());
                break;
            }
        }

        model.on("invalid", () => {
            this.showErrors(model);
        });

        model.on("valid", () => {
            this.hideErrors(model);
        });

        form.on("form-builder:save", () => {
            model.validationError = model.validate();
            if (model.validationError === undefined) {
                model.trigger("valid", model);
                this.createAlertApp(model);
            } else {
                model.trigger("invalid", model);
            }
        });

        form.on("form-builder:cancel", () => {
            navigateTo.AlertManager();
        });
    }

    showErrors(model) {
        let errors = "";
        _.each(model.validationError, function(error) {
            errors += error + " <br />";
        });
        this.ui.errorMessage.html(errors);
        this.ui.errorBox.show();
    }

    hideErrors() {
        this.ui.errorBox.hide();
    }

    createAlertApp(model) {
        const progressContainer = this.$el.find("#config-validation-progress")[0];
        const root = ReactDOM.createRoot(progressContainer);
        root.render(<ConfigValidation />);
        let apiMethod = "updateConfigAndReRunApp";
        if (this.options.type === ALERT_TYPE.SLACK) {
            apiMethod = "updateConfigAndReRunSlackApp";
        }
        if (this.options.type === ALERT_TYPE.TEAMS) {
            apiMethod = "updateConfigAndReRunTeamsApp";
        }

        if (this.validateButton && this.validateButton.$el) {
            this.validateButton.$el.hide();
        }

        const sendTestButton = this.$el.find(".test-alert-connection");
        if (sendTestButton) {
            sendTestButton.hide();
        }

        api[apiMethod](model.toJSON())
            .then(() => {
                growl.success(`${utils.capitalize(this.options.type)} Configured`, "Success");
                root.render(<></>);
                navigateTo.AlertManager();
            })
            .fail(e => {
                sendTestButton.show();
                growl.error(`Error configuring ${utils.capitalize(this.options.type)}: ${e.message}`);
                root.render(<></>);
            });
    }

    onShow() {
        setTimeout(() => {
            this.$el
                .find(".from-control-container-Validate_TokenButton")
                .closest("li")
                .first()
                .css({ position: "relative" });
            this.$el
                .find(".from-control-container-Validate_TokenButton a")
                .css({ height: "36px", marginBottom: "3px", position: "absolute", top: "32px", "z-index": "2" })
                .addClass("ml-8px btn secondary");
        }, 50);
    }
}

export function showAlertsFor(objectName) {
    function proceed() {
        let alertDialogView = new AlertDialogView({
            model: new Backbone.Model({ objectName: objectName })
        });

        modalManager.add(alertDialogView);
        new App.FormMixinDialog.Controller({
            view: alertDialogView
        });

        alertDialogView.on("form:submit", () => {
            alertDialogView.destroy();
        });
    }

    function showNoAlerts() {
        let alertDialogView = new AlertsNotSetupView({
            model: new Backbone.Model()
        });

        modalManager.add(alertDialogView);
        new App.FormMixinDialog.Controller({
            view: alertDialogView
        });

        alertDialogView.on("form:submit", () => {
            navigateTo.AlertManager();
            alertDialogView.destroy();
        });
    }

    api.hasAlertAppBeenCreated()
        .then(isEnabled => {
            if (isEnabled) {
                proceed();
            } else {
                showNoAlerts();
            }
        })
        .fail(e => {
            showNoAlerts();
        });
}

/**
 * Main View, the only one that's exported to the main view
 */
@template(alertDialogTemplate)
@regions({
    alertDialogContent: ".alerts-list"
})
export class AlertDialogView extends App.FormMixinDialog.View {
    initialize(options) {
        this.options = options;
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
    }

    onRender() {
        let that = this;
        let promises = [metaStoreService.fetchCollection(metaStoreService.entities.SYSALERTRULE)];
        if (this.model.get("objectName").indexOf(".APPLICATION.") !== -1) {
            promises.push(api.getApplicationComponentIdentifiers(this.model.get("objectName")));
        }
        $.when.apply($, promises).then((alerts, appComponents) => {
            _.each(appComponents, appComponent => {
                appComponent.nsName = appComponent.namespace;
                appComponent.id = appComponent.nsName + "." + appComponent.type + "." + appComponent.name;
            });

            function matchedAlerts() {
                let matchedAlerts = new Backbone.Collection();
                alerts.each(alert => {
                    if (
                        alert.get("objectName").indexOf(that.model.get("objectName")) !== -1 ||
                        _.findWhere(appComponents, {
                            id: alert.get("objectName")
                        })
                    ) {
                        matchedAlerts.add(alert);
                    }
                });
                return matchedAlerts;
            }

            let tab = "components";
            const pageModel = new Backbone.Model({
                tab: tab,
                category: null,
                item: null,
                isLiteMode: true,
                alertCollection: matchedAlerts(),
                reference: that.model.get("objectName")
            });

            this.getRegion("alertDialogContent").show(new AlertMainLayoutView({ model: pageModel }));
        });
    }
}

/**
 * Main View, the only one that's exported to the main view
 */
@template(noAlertsTemplate)
@triggers({
    "click .submit": "form:submit"
})
class AlertsNotSetupView extends App.FormMixinDialog.View {
    initialize(options) {
        this.options = options;
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
    }
}
