import React from "react";
import Marionette from "marionette";
import ReactDOM from "react-dom/client";

const wrapReactInMarionette = (component, props) => {
    return class extends Marionette.View {
        constructor() {
            super();
            this.formBuilderRef = React.createRef();
        }

        get className() {
            const fieldsGrouped = props.formFields?.some(field => field.group);
            const groupedClass = fieldsGrouped ? "grouped-property-template-fields" : "";
            return `marionette-wrapper-for-react-form-builder ${groupedClass}`.trim();
        }

        getRoot() {
            if (!this.root) {
                this.root = ReactDOM.createRoot(this.el);
            }
            return this.root;
        }

        onShow() {
            const root = this.getRoot();
            const reactProps = {
                ...props,
                ref: this.formBuilderRef
            };
            root.render(React.createElement(component, reactProps));
        }

        onDestroy() {
            const root = this.getRoot();
            root.unmount();
        }

        validateForm = () => {
            if (this.formBuilderRef.current) {
                return this.formBuilderRef.current.validateForm();
            }
        };
    };
};

const FormBuilderMarionetteWrapper = (component, props) => {
    const WrapperView = wrapReactInMarionette(component, props);
    return new WrapperView();
};

export default FormBuilderMarionetteWrapper;
