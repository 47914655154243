import _ from "underscore";

export default function (components, numberOfRows, numberOfCols) {
    this.canUpdate = function (component, newLayout) {
        var offset = this._getOffset(component, newLayout);

        if (offset.x === 0 && offset.y === 0) {
            return false;
        }

        var containsOutOfBoundElements = _.some(
            components,
            function (arrayComponent) {
                if (arrayComponent === component) {
                    return false;
                }

                if (!arrayComponent._active) {
                    return false;
                }
                var newPosition = this._getNewPosition(arrayComponent, offset);

                if (newPosition.x < 0 || newPosition.x + arrayComponent.width > numberOfCols) {
                    return true;
                }

                if (newPosition.y < 0 || newPosition.y + arrayComponent.height > numberOfRows) {
                    return true;
                }
            }.bind(this)
        );

        return !containsOutOfBoundElements;
    };

    this.update = function (component, newLayout) {
        var offset = this._getOffset(component, newLayout);

        _.each(
            components,
            function (arrayComponent) {
                if (!arrayComponent._active) {
                    return;
                }
                if (arrayComponent === component) {
                    return;
                }

                arrayComponent.set(this._getNewPosition(arrayComponent, offset));
            }.bind(this)
        );
    };

    this._getOffset = function (component, newLayout) {
        return {
            x: component.x - newLayout.x,
            y: component.y - newLayout.y,
        };
    };

    this._getNewPosition = function (component, offset) {
        return {
            x: component.x - offset.x,
            y: component.y - offset.y,
        };
    };
}
