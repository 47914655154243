import _ from "underscore";

function filterEmptyRows(reportData) {
    let result = [];

    reportData.forEach((row) => {
        for (let key in row) {
            if (
                row.hasOwnProperty(key) &&
                key !== "time-serie" &&
                row[key] !== null &&
                typeof row[key] !== "undefined"
            ) {
                result.push(row);
                break;
            }
        }
    });

    return result;
}

export default function parseReport(json, showEmptyRows) {
    if (!json) {
        return null;
    }

    if (!json["time-series-data"]) {
        return null;
    }

    const timeSeriesData = json["time-series-data"];

    let columns = [];
    let data = [];

    columns.push("time-serie");
    for (let key in timeSeriesData) {
        if (timeSeriesData.hasOwnProperty(key)) {
            columns.push(key);
        }
    }

    function addColumnData(columnData, columnName) {
        columnData.forEach((c) => {
            let row = data.filter((d) => {
                return d["time-serie"] === c.first;
            });

            if (row.length > 1) {
                console.log("More than one row with timestamp:" + c.first);
                return;
            }

            if (row.length === 0) {
                row = {
                    "time-serie": c.first,
                };
                data.push(row);
            } else {
                row = row[0];
            }
            row[columnName] = c.second;
        });
    }

    columns.forEach((col) => {
        let columnData = timeSeriesData[col];
        if (columnData && _.isArray(columnData)) {
            addColumnData(columnData, col);
        }
    });

    if (!showEmptyRows) {
        data = filterEmptyRows(data);
    }

    return {
        columns: columns,
        data: data,
    };
}
