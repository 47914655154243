import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { StriimTypography, StriimModal } from "@striim/striim-ui";
import growl from "app/components/common/growl";
import { AddPropertySetModalContent, PropertySetsService } from "./";
import { useTheme } from "@mui/material";

const useStyles = makeStyles(({ spacing }) => ({
    buttonInvalid: {
        backgroundColor: "grey",
        opacity: ".3",
        pointerEvents: "none"
    },
    dialogContent: {
        paddingTop: spacing(2),
        paddingBottom: spacing(3),
        borderBottom: ({ paletteV2 }) => "1px solid" + paletteV2?.greyscale[200]
    }
}));

export default function AddPropertySetModal({ setIsModalShown, onAdded }) {
    const theme = useTheme();
    const classes = useStyles({ paletteV2: theme.palette });
    const [isValid, setIsValid] = useState(false);
    const formValues = useRef();

    const onSave = () => {
        PropertySetsService.createPropertySet(formValues.current.name, formValues.current.nsName.value)
            .then(() => {
                growl.success("Property set saved successfully");
                onAdded && onAdded();
            })
            .catch(e => {
                growl.error("Property set not saved, please try again: " + e);
            })
            .finally(() => {
                setIsModalShown(false);
            });
    };

    const setFormValues = values => (formValues.current = values);

    return (
        <StriimModal
            onCancel={() => setIsModalShown(false)}
            onConfirm={onSave}
            isVisible={true}
            size="medium"
            autoHeight
            titleContent={<StriimTypography variant={"h2"}>New Property Set</StriimTypography>}
            cancelButtonProps={{
                "data-test-id": "add-property-set-cancel-button"
            }}
            confirmButtonProps={{
                className: isValid ? "" : classes.buttonInvalid,
                "data-test-id": "add-property-set-confirm-button"
            }}
            confirmContent={"Save"}
            dialogProps={{ "data-test-id": "add-property-set-modal" }}
            dialogContentProps={{
                className: classes.dialogContent
            }}
        >
            <AddPropertySetModalContent setIsValid={setIsValid} setFormValues={setFormValues} />
        </StriimModal>
    );
}

AddPropertySetModal.propTypes = {
    setIsModalShown: PropTypes.func.isRequired,
    onAdded: PropTypes.func.isRequired
};
