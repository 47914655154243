import BaseTable from "./basetable";
import statusManagement from "src/status-management";
import formatter from "app/components/monitor/common/valueFormatter";

var TargetsTable = {};

TargetsTable.View = BaseTable.View.extend({
    columns: [
        {
            label: "Name",
            srcfield: "label",
        },
        {
            label: "CPU",
            srcfield: "cpu-per-node",
            dataName: "cpu",
        },
        {
            label: "Nodes",
            srcfield: "nodes",
        },
        {
            label: "Rate",
            srcfield: "rate",
        },
        {
            label: "Events in",
            srcfield: "eventsIn",
        },
        {
            label: "Events out",
            srcfield: "eventsOut",
        },
        {
            label: "Output/sec",
            srcfield: "outputPerSecond",
            view: BaseTable.CellItemSparklineView,
        },
        {
            label: "",
            srcfield: "",
            view: BaseTable.MoreDetailsTargetView,
            colName: "moreDetails"
        }
    ],

    dataMapper: function (server) {
        return {
            label: server["full-name"],
            cpu: formatter.toPercentageString(server["most-recent-data"]["cpu-per-node"]),
            nodes: server["num-servers"],
            rate: formatter.formatRate(server["most-recent-data"]["rate"]),
            eventsIn: server["most-recent-data"]["input"],
            eventsOut: server["most-recent-data"]["output"],
            outputPerSecond: server["time-series-data"]["rate"],
            allValues: server,
        };
    },

    filterColumns: function (appStatus) {
        if (statusManagement.isApplicationCreated(appStatus)) {
            this.columns = this.columns.filter(function (c) {
                return c.colName !== "moreDetails";
            });
        }
    },
});

export default TargetsTable;
