import App from "app";
import ComponentController from "lib/controllers/component-controller";
import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import "lib/vendor/jquery.event.drag-drop";
import NestedTypes from "backbone.nestedtypes";

var modal = {};

modal.Controller = ComponentController.extend({
    initialize: function () {
        ComponentController.prototype.initialize.apply(this, arguments);

        this.listenTo(App.vent, "window:resize", function () {
            var view = this.getView();
            if (view) {
                view.computeDimensions();
                view.computePosition();

                // trigger isn't working when you are resize too often (ex. through CTRL + mousewheel)
                setTimeout(function() {
                    view.computeDimensions();
                    view.computePosition();
                }, 100);
            }
        });
    },
});

modal.Model = NestedTypes.Model.extend({
    defaults: {
        classes: "",
        height: null,
        width: null,
        top: 0,
        left: 0,
        position: "best", // top, center, best, full
        scrollable: false, // should we have scrollable content
        // Advanced: the element the morph animation should grow "into"
        morphEnd: NestedTypes.options({
            get: function () {
                if (!this.attributes.morphEnd) {
                    return {
                        width: this.width,
                        height: this.height,
                        top: this.top,
                        left: this.left,
                    };
                }

                var $end = _(this.attributes.morphEnd).isString()
                    ? this.$(this.attributes.morphEnd)
                    : $(this.attributes.morphEnd);
                return {
                    width: $end.outerWidth(),
                    height: $end.outerHeight(),
                    top: $end.offset().top - $(document).scrollTop(),
                    left: $end.position().left,
                };
            },
        }),
        // A dom node from which the modal should appear to "morph" from
        morphStart: NestedTypes.options({
            get: function () {
                // If origin is a DOM node...
                if (this.attributes.morphStart instanceof HTMLElement || this.attributes.morphStart instanceof jQuery) {
                    var $origin = $(this.attributes.morphStart);
                    if ($origin[0].parentNode) {
                        return {
                            width: $origin.outerWidth(),
                            height: $origin.outerHeight(),
                            top: $origin.offset().top,
                            left: $origin.offset().left,
                        };
                    }
                }
                // Else if it's a plain old object with height, width, etc. props
                else if (_(this.attributes.morphStart).isObject()) {
                    return this.attributes.morphStart;
                }

                // If neither of the above options yielded a setting, just center it.
                var h = this.height * 0.5;
                var w = this.width * 0.5;
                return {
                    width: w,
                    height: h,
                    top: this.top + (this.height - h) / 2,
                    left: this.left + (this.width - w) / 2,
                };
            },
        }),
        // No need to specify. If specify origin, this will be set to morph. Otherwise, fade
        animation: NestedTypes.options({
            get: function () {
                var animation = this.attributes.animation || "fade";
                // Force return object
                if (!_(animation).isObject()) {
                    animation = {
                        show: animation,
                        hide: animation,
                    };
                }
                return animation;
            },
        }),
        // Should modal automatically reposition on screen resize?
        autoposition: NestedTypes.options({
            get: function () {
                return this.draggable ? false : this.attributes.autoposition;
            },
        }),
        draggable: false,
    },
    initialize: function () {
        if (!this.id) {
            this.id = _.uniqueId("modal-");
        }
    },
});

modal.View = Backbone.Marionette.LayoutView.extend({
    className: "striim-modal modal-hidden",
    speed: 300,
    is_open: false, // TODO: do these need to go into the model?
    initialize: function (options) {
        // Validate modalSettings
        this.options.modalSettings || (this.options.modalSettings = {});
        if (!(this.options.modalSettings instanceof Backbone.Model)) {
            // If the modal is accidentally used as a mixin, as before, we'll have more options in the
            // options parameter
            var modelOptsFromOptions = _(options).pick(_(modal.Model.prototype.__attributes).keys().concat(["origin"]));
            _(this.options.modalSettings).extend(modelOptsFromOptions);

            // Backwards compatibility for "origin" (morphStart)
            this.options.modalSettings.morphStart = this.options.modalSettings.origin;
            delete this.options.modalSettings.origin;

            this.modalModel = new modal.Model(this.options.modalSettings);
        } else {
            this.modalModel = this.options.modalSettings;
        }

        this.listenTo(this, "render", function () {
            if (this.modalModel.classes) {
                this.className += " " + this.modalModel.classes;
                this.$el.addClass(this.className);
            }
        });

        // When the modal's coordinates change, reposition the modal
        // They will change when they are initially set onShow, and when the window is resized
        this.listenTo(this.modalModel, "change:position", function () {
            this.renderPosition();
        });
        this.listenTo(this.modalModel, "change:dimensions", function () {
            this.renderDimensions();
        });
    },
    computeDimensions: function () {
        if (this.modalModel.position === "full") {
            this.modalModel.width = "100%";
            this.modalModel.height = "auto";
        } else {
            this.modalModel.height = this.modalModel.height || this.$el.outerHeight(); // keep initial height
            this.modalModel.width = this.$el.outerWidth();
        }

        // DEV-9165 Improve modal size/background - we want scroll
        if (this.modalModel.scrollable) {
            var scroll_top = $(document).scrollTop();
            var screen_height = $(window).height();
            var min_top = scroll_top + Math.max(20, screen_height * 0.1);
            var header_height = this.$el.find("header").outerHeight();
            var footer_height = this.$el.find("footer").outerHeight();
            var bottom_margin = parseInt(this.$el.css("margin-bottom"));

            var fitWithinScreenHeight = screen_height - min_top - header_height - footer_height - bottom_margin;
            this.modalModel.bodyHeight = Math.min(this.modalModel.height, fitWithinScreenHeight);
        }

        this.modalModel.trigger("change:dimensions");
    },
    // Position (and set size of) the modal on the screen
    // Run computeDimensions first!
    computePosition: function () {
        var position = this.modalModel.position;

        var scroll_top = $(document).scrollTop();
        var screen_height = $(window).height();
        var screen_width = $(window).width();
        var min_top = scroll_top + Math.max(20, screen_height * 0.1);

        // center: vertically and horizontally centered. YMMV
        this.modalModel.top = (screen_height - this.modalModel.height) / 2 + scroll_top;
        this.modalModel.left = (screen_width - this.modalModel.width) / 2;

        // top: positioned at the top of the screen.
        switch (position) {
            case "top":
                this.modalModel.top = min_top;
                break;
            // best: golden section. aimed so top 1/3-ish of modal is in the top 1/3-ish of the screen.
            // This is good for small modals, and large modals will simply behave like "top"
            case "best":
                var ratio = 1.618;
                var screen_gs = screen_height - screen_height / ratio;
                var editor_gs = this.modalModel.height - this.modalModel.height / ratio;
                var center_line = screen_gs / 2; // Center of screen's golden section
                this.modalModel.top = scroll_top + center_line - editor_gs / 2; // Center modal gs in screen gs
                break;
            // full: fill the whole screen
            case "full":
                var pad = 0;
                this.modalModel.top = this.modalModel.freeze ? pad : scroll_top + pad;
                this.modalModel.left = pad;
                break;
        }

        // For debugging positioning:
        // console.log(this.id, ': positioning', this.modalModel.height, 'px high editor on', screen_height, 'px high screen in', this.modalModel.position, 'position at', Math.ceil(this.modalModel.top), 'px. Min top:', Math.ceil(min_top), 'Scroll top:', scroll_top);

        // Round, and make sure top does not go off-screen
        if (position !== "full") {
            this.modalModel.top = Math.ceil(Math.max(min_top, this.modalModel.top));
        }
        this.modalModel.trigger("change:position");
    },

    fitPositionToModalContent: function () {
        var scroll_top = $(document).scrollTop();
        var screen_height = $(window).height();
        var min_top = 20;

        var height = this.$el.height();
        this.modalModel.top = (screen_height - height) / 2 + scroll_top;
        this.modalModel.top = Math.ceil(Math.max(min_top, this.modalModel.top));
        this.modalModel.trigger("change:position");
    },

    renderPosition: function () {
        this.$el.css({
            top: this.modalModel.top,
            left: this.modalModel.left,
        });
    },
    renderDimensions: function () {
        if (this.modalModel.position === "full") {
            this.$el.css({
                width: this.modalModel.width,
                height: this.modalModel.height,
            });
        }
        if (this.modalModel.scrollable) {
            this.$el.find("#dialog-body").css({
                maxHeight: this.modalModel.bodyHeight,
            });
        }
    },
    _appear: function (opts) {
        var _this = this;
        opts || (opts = {});
        _(opts).defaults({
            speed: this.speed,
        });
        var deferred = new $.Deferred();

        this.$el
            .removeClass("modal-hidden")
            .css({
                opacity: 0,
            })
            .animate(
                {
                    opacity: 1,
                },
                opts.speed,
                function () {
                    deferred.resolveWith(_this);
                }.bind(this)
            );
        return deferred.promise();
    },
    _disappear: function (opts) {
        opts || (opts = {});
        _(opts).defaults({
            speed: this.speed,
        });
        var deferred = new $.Deferred();
        this.$el.fadeOut(
            opts.speed,
            function () {
                deferred.resolveWith(this);
            }.bind(this)
        );
        return deferred.promise();
    },
    _show: function (opts) {
        var _this = this;
        opts || (opts = {});
        var deferred = new $.Deferred();

        var finish = function () {
            // Make draggable
            if (this.modalModel.draggable) {
                this.$el.addClass("draggable");
                this.$el
                    .drag("start", function () {
                        _this.$el.addClass("dragging");
                    })
                    .drag(
                        "drag",
                        function (e, dd) {
                            var coords = [dd.offsetX, dd.offsetY];
                            $(this).css({
                                bottom: "auto",
                                right: "auto",
                                left: coords[0],
                                top: coords[1],
                            });
                        },
                        {
                            relative: true,
                        }
                    )
                    .drag("end", function () {
                        _this.$el.removeClass("dragging");
                    });
            }

            //FIXME it must be triggered from extend model (ex. after load dynamic content)
            setTimeout(function() {
                //FIXME call to Dimensions after to Position must be sequent
                _this.computeDimensions();
                _this.computePosition();
                _this.computeDimensions();
                _this.computePosition();
            }, 200);

            deferred.resolveWith(this);
        }.bind(this);

        var show_animation = opts.animation || this.modalModel.animation.show;

        // Animate the modal
        var origin = this.modalModel.morphStart;

        if (show_animation === "morph" && origin) {
            // Show the modal in the page flow, but still invisible (to accurately calculate morph end)
            this.$el.removeClass("modal-hidden").css({
                opacity: 0,
            });

            var _origin_box_end = this.modalModel.morphEnd;

            this.$proxy = $("<div/>", {
                class: "modal-proxy",
                css: _({
                    opacity: 0,
                }).extend(origin),
            })
                .insertBefore(this.$el)
                .animate(
                    {
                        width: _origin_box_end.width,
                        height: _origin_box_end.height,
                        left: _origin_box_end.left,
                        top: _origin_box_end.top,
                        opacity: 1,
                    },
                    this.speed,
                    function () {
                        this._appear({
                            speed: this.speed * 0.5,
                        }).then(function () {
                            if (this.$proxy) {
                                this.$proxy.remove();
                                this.$proxy = null;
                            }
                            finish();
                        });
                    }.bind(this)
                );
        } else {
            this._appear().then(function() {
                finish();
                deferred.resolveWith(this);
            });
        }

        return deferred.promise();
    },
    _hide: function (opts) {
        opts || (opts = {});

        var deferred = new $.Deferred();
        // Finish function
        var finish = function () {
            this.$el
                .css({
                    display: "",
                })
                .addClass("modal-hidden");

            if (this.$proxy) {
                this.$proxy.remove();
                delete this.$proxy;
            }
            deferred.resolveWith(this);
        }.bind(this);

        var hide_animation = opts.animation || this.modalModel.animation.hide;

        var origin = this.modalModel.morphStart;
        var morphing = hide_animation === "morph" && origin;

        // If we're morphing, create the proxy while the element is still visible
        if (morphing) {
            // Add scrollTop distance to origin
            var scroll_top = $(document).scrollTop();
            origin.top -= scroll_top;

            var _origin_box_end = this.modalModel.morphEnd;

            this.$proxy = $("<div/>", {
                class: "modal-proxy",
                css: _(_origin_box_end).extend({
                    opacity: 1,
                }),
            }).insertBefore(this.$el);
        }

        // Fade out
        this._disappear({
            speed: morphing ? this.speed * 0.5 : this.speed,
        }).then(function () {
            if (morphing) {
                this.$proxy.animate(
                    _(origin).extend({
                        opacity: 0,
                    }),
                    this.speed,
                    function () {
                        if (this.$proxy) {
                            this.$proxy.remove();
                            this.$proxy = null;
                        }
                        _(finish).defer();
                    }
                );
            } else {
                finish();
            }
        });
        return deferred.promise();
    },
    blur: function () {
        if (this.blurred) {
            return;
        }
        this.blurred = true;
        this.$el.addClass("blurred");
        this.$blurscreen = $("<div/>", {
            class: "blur-screen",
        })
            .appendTo(this.$el)
            .css({
                opacity: 0,
            })
            .animate({
                opacity: 1,
            });
    },
    unblur: function () {
        if (!this.blurred) {
            return;
        }
        var _this = this;
        this.blurred = false;
        this.$el.removeClass("blurred");
        if (this.$blurscreen) {
            this.$blurscreen.animate(
                {
                    opacity: 0,
                },
                function () {
                    $(this).remove();
                    _this.$blurscreen = null;
                }
            );
        }
    },
    // When the view is shown, show the modal
    onShow: function () {
        this.computeDimensions();
        this.computePosition();

        this.in_progress = true;
        this._show().then(
            function () {
                this.is_open = true;
                this.in_progress = false;
                this.trigger("modal:show");
            }.bind(this)
        );
    },

    // Destroy the view by fading out first
    destroy: function (isCancelEvent = false) {
        this.trigger("before:modal:hide", this);
        this.in_progress = true;
        this._hide().then(
            function () {
                this.is_open = false;
                this.in_progress = false;
                this.trigger("modal:hide", this);

                // Actually destroy the view
                Backbone.Marionette.LayoutView.prototype.destroy.apply(this, [...arguments, { isCancelEvent }]);
            }.bind(this)
        );
    },
});

export default modal;
