import Backbone from "backbone";
import _ from "underscore";
import dateLib from "core/utils/date-utils";
import debuggerConfig from "app/components/debug/debugger-config";
import debugNetworkTemplate from "./templates/debug-network.html";
import Papa from "papaparse";
import { saveAs as saveFileAs } from "file-saver";

export const DebugNetworkView = Backbone.Marionette.LayoutView.extend({
    template: _.template("<div></div>"),
    regions: {
        logData: "div"
    },
    initialize: function() {
        this.listenTo(debuggerConfig, "change:debugNetworkRecording", () => {
            if (!debuggerConfig.isDebugNetworkRecording()) {
                setTimeout(() => {
                    this.render();
                }, 300);
            }
        });
    },
    onRender: function () {
        this.getRegion("logData").show(new TableBody());
        var that = this;
        setTimeout(function() {
            that.arrangeChildren();
        }, 200);
    },
    arrangeChildren: function() {
        var outs = this.$el.find(".out");
        _.each(outs, function(out) {
            var $out = $(out);
            var id = $out.attr("data-req");
            var $nextAll = $out.nextAll('[data-res="' + id + '"]');
            var next = $nextAll[0];
            //console.log($next);
            $(next).detach();
            $(next).insertAfter($out);
            $(next).trigger("row:arranged");
        });
    }
});

const RowView = Backbone.Marionette.ItemView.extend({
    tagName: "tr",
    template: _.template(
        '<td>{{displayTime}}</td><td>{{direction}}</td><td>{{displayData}} <textarea class="full-json">{{json}}</textarea> </td><td> <button class="button">View</button></td>'
    ),
    onShow: function() {
        var that = this;
        this.$el.addClass(this.model.get("isIncoming") ? "in" : "out");
        if (this.model.get("isIncoming")) {
            this.$el.attr("data-res", this.model.get("data").callbackIndex);
        } else {
            this.$el.attr("data-req", this.model.get("data").callbackIndex);
        }
        this.$el.attr("data-time", this.model.get("time"));

        this.$el.on("row:arranged", function() {
            var $prev = that.$el.prev();
            var timeTaken = that.$el.attr("data-time") - that.$el.prev().attr("data-time");
            that.$el.find(".time-taken").html("Time Taken: " + timeTaken + " ms");
        });
    },
    events: {
        "click .button": "toggleDetails"
    },
    serializeData: function() {
        var data = this.model.toJSON();
        data.direction = this.model.get("isIncoming") ? "Response" : "Request";
        data.displayTime = dateLib(this.model.get("time")).format("h:m:s .SSS");
        data.json = JSON.stringify(data.data, null, 2);
        data.displayData = data.data.class
            ? JSON.stringify(data.data)
            : "Response for callback " +
              data.data.callbackIndex +
              " | Size: " +
              data.json.length +
              'B | <span class="time-taken"></span>';
        return data;
    },
    toggleDetails: function() {
        this.$el.find(".full-json").toggle();
    }
});

const TableBody = Backbone.Marionette.CompositeView.extend({
    template: _.template(debugNetworkTemplate),
    childView: RowView,
    childViewContainer: "tbody",
    events: {
        "click #export-button": "download"
    },

    initialize: function() {
        var logs = debuggerConfig.getPersistedNetworkLogs();
        this.collection = new Backbone.Collection(logs);
    },

    download() {
        var logs = debuggerConfig.getPersistedNetworkLogs();
        let csvLogs = [];
        _.each(logs, function(log) {
            let csvLog = {};
            csvLog["Is Incoming"] = log["isIncoming"];
            csvLog["Time"] = new Date(log["time"]);
            csvLog["Payload"] = JSON.stringify(log["data"]);
            csvLogs.push(csvLog);
        });
        var csv = Papa.unparse(csvLogs);

        saveFileAs(
            new Blob([csv], {
                type: "text/csv"
            }),
            "STRIIM_NETWORK_DEBUG_" + _.now() + ".csv"
        );
    }
});
