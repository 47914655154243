import Backbone from "backbone";
import NestedTypes from "backbone.nestedtypes";
import _ from "underscore";
import Select from "./../select/select";
import gridTemplate from "./grid.html";
import rowTemplate from "./row.html";
import "./../behaviors";
import typesDataSource from "./../select/types-datasource.json";

const TypeField = {};

TypeField.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(rowTemplate),
    tagName: "tr",

    modelEvents: {
        "change:name": "modified",
        "change:alias": "modified",
        "change:type": "modified",
        "change:isKey": "keyChanged"
    },

    ui: {
        name: ".name",
        alias: ".alias",
        controls: ".action-controls",
        types: ".types",
        isKey: ".checkbox"
    },

    regions: {
        typesRegion: ".types"
    },

    triggers: {
        "click .icon-close": {
            event: "removeField",
            preventDefault: true, // this param is optional and will default to true
            stopPropagation: true
        }
    },

    events: {
        "keyup @ui.name": "changeName",
        "keyup @ui.alias": "changeAlias",
        "click  @ui.isKey": "setKey"
    },

    initialize: function() {
        var _this = this;
        this.types = Select(this.model.types, {
            addNew: true,
            addNewText: "",
            placeholder: "Enter type",
            doNotFilterCharacters: true,
            customCssClass: "type-field-select",
            allowClear: false
        }).create();
        this.listenTo(this.types.model, "change:value", function(viewModel) {
            _this.model.type = viewModel.value;
        });
    },

    modified: function() {
        this.trigger("modified");
    },

    keyChanged: function() {
        if (this.model.isKey) {
            this.ui.isKey.addClass("active");
        } else {
            this.ui.isKey.removeClass("active");
        }
        this.trigger("modified");
    },

    changeName: function() {
        this.model.name = this.ui.name.val();
    },

    changeAlias: function() {
        this.model.alias = this.ui.alias.val();
    },

    setKey: function() {
        if (this.model.enabled) {
            this.trigger("change-key");
        }
    },

    setEnabled: function(enabled) {
        enabled = !!enabled;
        this.model.enabled = enabled;
        this.ui.controls.toggle(enabled);
        this.ui.name.prop("disabled", !enabled);
        this.ui.alias.prop("disabled", !enabled);
        this.ui.isKey.toggleClass("disabled", !enabled);
        this.types.setEnabled(enabled);
        this.ui.types.show();
    },

    onRender: function() {
        this.$el.attr("data-backbone-cid", this.model.cid); //TODO: is it really needed?
        this.typesRegion.show(this.types);
        this.types.setValue(this.model.type);
    }
});

TypeField.CollectionView = Backbone.Marionette.CompositeView.extend({
    tagName: "table",
    template: _.template(gridTemplate),
    childView: TypeField.View,
    childViewOptions: function(model) {
        return {
            enabled: model.enabled
        };
    },
    modified: function() {
        this.trigger("modified");
    },
    changeKey: function(view) {
        this.collection.each(function(c) {
            c.isKey = view.model === c;
        });
    },
    childEvents: {
        removeField: "removeField",
        modified: "modified",
        "change-key": "changeKey"
    },
    behaviors: {
        Sortable: {}
    },
    ui: {
        table: "tbody",
        cancel: ".cancel",
        save: ".save"
    },
    events: {
        "click @ui.cancel": "cancel",
        "click @ui.save": "save"
    },
    setEnabled: function(enabled) {
        this.children.each(function(c) {
            c.setEnabled(enabled);
        });
        this.$el.find("tbody").sortable(enabled ? "enable" : "disable");
    },
    scrollToBottom: function() {
        var scrollTo_val = this.$("tbody").prop("scrollHeight");
        this.$("tbody").scrollTop(scrollTo_val);
    },
    removeField: function(childView) {
        this.removeChildView(childView);
        this.trigger("remove", childView);
        this.trigger("modified");
    }
});

TypeField.Model = NestedTypes.Model.extend({
    defaults: {
        name: String,
        alias: String,
        type: String,
        isKey: Boolean,
        enabled: true,
        types: typesDataSource
    },
    validate: function() {
        if (!this.name) {
            return "name cannot be empty!";
        }
    },
    properties: {
        hasErrors: function() {
            return this.validate();
        }
    }
});

TypeField.Collection = NestedTypes.Collection.extend({
    model: TypeField.Model,
    properties: {
        hasErrors: function() {
            if (this.models.length === 0) {
                return true;
            }

            var unique = _.uniq(this.models, false, function(m) {
                return m.alias || m.name;
            });

            if (unique.length !== this.models.length) {
                return true;
            }

            return _.some(this.models, function(m) {
                return m.hasErrors;
            });
        },
        hasKey: function() {
            return _.some(this.models, function(m) {
                return m.isKey;
            });
        }
    }
});

export default TypeField;
