import api from "../../../../core/api/api";
import metaStoreService from "../../../../core/services/metaStoreService/meta-store-service";
import utils from "../../../../core/utils";
import { getTablesKey } from "./target";

const fetchTargetModel = async (appId: string): Promise<Object | null> => {
    const nsName = utils.getNamespace(appId);
    const shortAppName = utils.getName(appId);
    var targetModel = await metaStoreService.findById(`${nsName}.TARGET.${shortAppName}_Target`);
    return targetModel ?? null;
};
const getTargetTables = async (appId: string): Promise<string | null> => {
    try {
        var targetModel = await fetchTargetModel(appId);
        if (!targetModel) return "";
        const tablesKey = getTablesKey(targetModel.get("adapter").handler);
        return targetModel?.adapter?.properties[tablesKey] ?? null;
    } catch (err) {
        return "";
    }
};

const saveTargetWithTable = async (appId: string, tables: string) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            const targetModel = await fetchTargetModel(appId);
            const shortAppName = utils.getName(appId);
            if (!targetModel) {
                reject("Could not fetch Target Model");
            }
            const tablesKey = getTablesKey(targetModel.get("adapter").handler);
            targetModel.get("adapter").properties[tablesKey] = tables;
            await targetModel.save({ flow: shortAppName }).then(
                () => {},
                e => reject(e)
            );
            await api.compile(appId);
            resolve();
        } catch (error) {
            reject("Could not save Target Model");
        }
    });
};

export { fetchTargetModel, getTargetTables, saveTargetWithTable };
