import React, { useState } from "react";
import { Grid } from "@mui/material";

import { styles } from "./content-frame.styles";
import HelpGuide from "../help-guide/help-guide";

interface ContentFrameProps {
    content: React.ReactNode;
    children: React.ReactNode;
    helpUrl: string;
}

const ContentFrame = ({ content, helpUrl, children }: ContentFrameProps) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    return (
        <Grid container display="flex" justifyContent="space-between" sx={styles.wrapper}>
            <Grid item sx={styles.contentContainer}>
                {children}
            </Grid>
            <HelpGuide content={content} helpUrl={helpUrl} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        </Grid>
    );
};

export default ContentFrame;
