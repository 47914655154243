import React, { useEffect, useState } from "react";
import UsageBreakdown from "./striim-unit-usage-breakdown";
import numeral from "numeral";
import { convertNameToHumanRedable } from "app/components/common/editor/control-resource-resolver";
import NoDataComponent from "./components/no-data-component";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ spacing }) => ({
    wrapper: {
        height: "100%"
    },
    title: {
        fontSize: 16,
        fontWeight: 600
    },
    content: {
        height: 441,
        paddingRight: spacing(6)
    }
}));
const formatDate = dateString => {
    const date = new Date(dateString);
    const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true
    };
    return date.toLocaleDateString("en-US", options).replace(",", "");
};

const UsageBreakdownController = ({ allUsageSummary, goToUsagePlanTab, unit, api, basicMinEventUsage }) => {
    const classes = useStyles();
    let latestItem;
    let selectedOption;

    const breakdownOptions = (allUsageSummary || []).map(item => {
        const option = {
            value: item.billingId,
            label: formatDate(item.startTime)
        };
        if (item?.usageTotal > 0) {
            latestItem = item;
            selectedOption = option;
        }
        return option;
    });

    const [billingId, setBillingId] = useState(breakdownOptions.length > 0 && latestItem?.billingId);
    const [breakdown, setBreakdown] = useState(null);
    const [selectedBillingOption, setSelectedBillingOption] = useState(selectedOption);

    useEffect(() => {
        if (!billingId) {
            return;
        }

        api.meteringGetItemizedUsage(billingId).then(x => {
            const formatted = formatBreakdown(x);
            setBreakdown(formatted);
        });
    }, [api, billingId]);

    if (breakdownOptions.length < 1) {
        return (
            <div className={classes.wrapper}>
                <div className={classes.title}>Usage Breakdown</div>
                <div className={classes.content}>
                    <NoDataComponent
                        text={`There is no usage to show at this time.
                        Once you start using Striim you can see the details of your usage here.`}
                        image
                    />
                </div>
            </div>
        );
    }

    return (
        <UsageBreakdown
            breakdownOptions={breakdownOptions}
            billingId={billingId}
            setBillingId={setBillingId}
            selectedBillingOption={selectedBillingOption}
            setSelectedBillingOption={setSelectedBillingOption}
            breakdown={breakdown}
            goToUsagePlanTab={goToUsagePlanTab}
            unit={unit}
            basicMinEventUsage={basicMinEventUsage}
        />
    );
};

export default UsageBreakdownController;

const numberFormat = "0.[00]";
const formatBreakdown = breakdown => {
    if (!breakdown) {
        return breakdown;
    }
    breakdown.usageItemized = breakdown.usageItemized || [];
    breakdown.usageUnit = "Striim Units";
    // new metering show items sorted by server
    //breakdown.usageItemized.sort((a, b) => (a.usageValue < b.usageValue ? 1 : -1));

    return {
        ...breakdown,
        usageTotalFormatted: numeral(breakdown.usageTotal.toFixed(2)).format(numberFormat),
        usageCreditFormatted: numeral(breakdown.usageCredit.toFixed(2)).format(numberFormat),
        usageBalanceFormatted: numeral(breakdown.usageBalance.toFixed(2)).format(numberFormat),
        usageUnitFormatted: convertNameToHumanRedable(breakdown.usageUnit)
    };
};
