import React, { useRef, useState } from "react";
import { StriimTypography, StriimModal } from "@striim/striim-ui";
import growl from "app/components/common/growl";
import { AddPropertyVariableModalContent } from "./";
import { PropertyVariablesService } from "./";

const AddPropertyVariableModal = ({ setIsModalShown, onAdded }) => {
    const [isValid, setIsValid] = useState(false);
    const formValues = useRef();

    const onSave = () => {
        PropertyVariablesService.savePropertyVariable(formValues.current)
            .then(() => {
                growl.success("Property variable saved successfully");
                onAdded && onAdded();
            })
            .catch(e => {
                growl.error("Error saving property variable: " + e);
            })
            .finally(() => {
                setIsModalShown(false);
            });
    };

    const setFormValues = values => (formValues.current = values);

    return (
        <StriimModal
            onCancel={() => setIsModalShown(false)}
            onConfirm={onSave}
            isVisible={true}
            size="small"
            autoHeight
            titleContent={<StriimTypography variant={"h2"}>New Property Variable</StriimTypography>}
            confirmButtonProps={{
                disabled: isValid ? false : true,
                "data-test-id": "add-property-variable-confirm-button"
            }}
            cancelButtonProps={{
                "data-test-id": "add-property-variable-cancel-button"
            }}
            dialogProps={{
                "data-test-id": "add-property-variable-modal"
            }}
            confirmContent={"Save"}
        >
            <AddPropertyVariableModalContent setIsValid={setIsValid} setFormValues={setFormValues} />
        </StriimModal>
    );
};

export default AddPropertyVariableModal;
