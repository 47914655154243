export const styles = {
    container: {
        backgroundColor: "additional.white",
        border: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
        borderRadius: "10px",
        boxShadow: "0px 6px 15px 0px rgba(72, 82, 92, 0.12)"
    },
    securityImage: {
        width: "64px",
        height: "64px",
    },
    closeIcon: {
        width: 20,
        height: 20,
        cursor: "pointer"
    }
};