import React, { useState, useMemo, useEffect } from "react";
import { Clear, Search } from "@mui/icons-material";
import { Grid, InputAdornment } from "@mui/material";
import { StriimButton, StriimIconButton, StriimInputField } from "@striim/striim-ui-v2";
import { components } from "react-select";

import { styles } from "./source-details-table.styles";
import useDebounce from "../../../../../hooks/useDebounce";
import { TableData } from "../entity-table/utils";

interface JobTableFiltersProps {
    entityName: string;
    tableData: TableData[];
    sensitiveDataIdentifiers: { label: string; value: string }[];
    setFilteredTableData: React.Dispatch<React.SetStateAction<TableData[]>>;
}

const SourceDetailsTableFilter: React.FC<JobTableFiltersProps> = ({
    entityName,
    tableData,
    setFilteredTableData,
    sensitiveDataIdentifiers
}) => {
    const [filters, setFilters] = useState({
        searchQuery: "",
        selectedSensitiveDataIdentifier: null
    });

    useEffect(() => {
        handleClearFilters();
    }, [tableData]);

    const hasFilters = useMemo(() => filters.searchQuery || filters.selectedSensitiveDataIdentifier, [filters]);

    const filterTable = () => {
        hasFilters
            ? setFilteredTableData(
                  tableData.filter(table => {
                      const matchesSearch = table.name.toLowerCase().includes(filters.searchQuery?.toLowerCase());
                      const matchesSensitiveIdentifier =
                          !filters.selectedSensitiveDataIdentifier ||
                          table.sensitiveDataIdentifiers.includes(filters.selectedSensitiveDataIdentifier);
                      return matchesSearch && matchesSensitiveIdentifier;
                  })
              )
            : setFilteredTableData(null);
    };

    const setFiltersDebounced = useDebounce(filterTable, 500);

    const handleFilterChange = (field: string, value: any) => {
        setFilters(prevFilters => ({ ...prevFilters, [field]: value }));
    };

    const clearSearch = () => {
        setFilters(prevFilters => ({ ...prevFilters, searchQuery: "" }));
        setFiltersDebounced();
    };

    const handleClearFilters = () => {
        setFilters({
            searchQuery: "",
            selectedSensitiveDataIdentifier: null
        });
        setFilteredTableData(tableData);
    };

    return (
        <Grid container height={36} justifyContent="space-between">
            <Grid item display={"flex"} height={36} gap={1.5} width={"auto"}>
                <StriimInputField
                    sx={styles.searchInput}
                    placeholder={`Search by ${entityName} name`}
                    value={filters.searchQuery}
                    onChange={searchValue => {
                        handleFilterChange("searchQuery", searchValue);
                        setFiltersDebounced();
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search opacity={0.45} />
                            </InputAdornment>
                        ),
                        endAdornment: filters.searchQuery ? (
                            <StriimIconButton
                                variant="primary"
                                sx={styles.endAdornmentContainer}
                                onClick={() => {
                                    clearSearch();
                                }}
                            >
                                <Clear sx={styles.iconButton} />
                            </StriimIconButton>
                        ) : null
                    }}
                />
                <StriimInputField
                    id="data-test-id-sensitive-data-identifier"
                    name="sensitiveDataIdentifiers"
                    select
                    placeholder="All Sensitive data identifiers"
                    sx={styles.filter}
                    value={
                        sensitiveDataIdentifiers.find(
                            option => option.value === filters.selectedSensitiveDataIdentifier
                        ) || ""
                    }
                    onChange={option => {
                        handleFilterChange("selectedSensitiveDataIdentifier", option?.value);
                        setFiltersDebounced();
                    }}
                    SelectProps={{
                        options: sensitiveDataIdentifiers,
                        components: {
                            Option: componentProps => (
                                <components.Option {...componentProps}>
                                    <Grid display="flex" alignItems="center" gap={1}>
                                        <div>{componentProps.data.label}</div>
                                    </Grid>
                                </components.Option>
                            )
                        }
                    }}
                />
            </Grid>
            <StriimButton
                variant="text"
                data-test-id="clear-filters"
                onClick={handleClearFilters}
                disabled={!hasFilters}
            >
                Clear filters
            </StriimButton>
        </Grid>
    );
};

export default SourceDetailsTableFilter;
