import _ from "underscore";
import App from "app";
import NodeViewBase from "./nodeViewBase";
import utils from "core/utils";
import appStatusSynchronizer from "core/appStatusSynchronizer";
import statusManagement from "src/status-management";
import metaObjectHelper, {
    resolveSourceValidationDscription
} from "app/components/common/editor/meta-object-editors/metaObjectHelper";
import allowedTargetNodeTypesResolver from "../allowedTargetNodeTypesResolver";
import graphNodeTemplate from "./templates/graphNodeTemplate.html";
import { iconMaker } from "app/components/common/icon-maker/icon-maker";

export default NodeViewBase.extend({
    /*
     options: {
     appModel
     }
     */

    template: _.template(graphNodeTemplate),
    tagName: "div",
    className: "node graph-node jsplumb-draggable",
    regions: {
        icon: ".icon-container"
    },
    ui: {
        node: "", // whole $el
        connectNewNode: ".connect-new-node",
        counter: ".counter",
        description: ".description",
        formErrorCount: ".form-error-count"
    },

    events: {
        "mousedown @ui.node": "onNodeMouseDown",
        "mousemove @ui.node": "onNodeMouseMove",
        "mouseup @ui.node": "onNodeMouseUp",
        "click @ui.node": "onNodeClick",
        "click @ui.connectNewNode": "onConnectNewNode"
    },

    initialize: function() {
        this.model.on(
            "change:isSelected",
            function() {
                this.$el.toggleClass("selected", this.model.isSelected);
            }.bind(this)
        );
        this.model.on("change:recentDataRate", this.setRecentDataRate.bind(this));
        this.model.on("change:additionalStatisticsInfo", this.setRecentDataRate.bind(this));
    },

    onRender: function() {
        NodeViewBase.prototype.onRender.call(this);
        this.setRecentDataRate();
        this.toggleRecentDataVisibility(statusManagement.isApplicationRunning(this.options.appModel.flowStatus));
        var allowedChildren = allowedTargetNodeTypesResolver.getAllowedChildren(this.model.metaObject.type);
        allowedChildren = _.without(allowedChildren, utils.ENTITIES.STREAM);
        this.setConnectNewNodeVisibile(allowedChildren.length > 0);
        this.addClipboardListener();
        //  console.log(this.model.className);
        this.getRegion("icon").show(iconMaker.createGraphNodeIcon(this.model.metaObject.type, this.model.className));

        this._updateDescription();
        this.ui.formErrorCount.hide();
    },

    onShow: function() {
        this.setupDnd();
    },

    _updateDescription: function() {
        const _this = this;

        function updateDescription() {
            metaObjectHelper
                .descriptionResolver(_this.model.metaObject, _this.model.applicationId)
                .then(description => {
                    if (!_this.ui.description.html) {
                        return;
                    }
                    if (description.title) {
                        _this.ui.description.html(description.title);
                        _this.ui.description.attr("title", description.description);
                    } else {
                        _this.ui.description.html(description);
                        _this.ui.description.attr("title", description);
                    }
                });
        }
        updateDescription();

        this.listenTo(appStatusSynchronizer, this.model.applicationId, updateDescription);

        this.listenTo(App.vent, "component:validation", validationResult => {
            if (
                validationResult.modelId === _this.model.id ||
                (validationResult.modelId === "" && _this.model.id === "temp-node")
            ) {
                _this.ui.description.html(resolveSourceValidationDscription(validationResult.status));
                _this.ui.description.attr("title", validationResult.message || "");
            }
        });
        this.listenTo(App.vent, "component:form-validation", formValidationResult => {
            if (
                formValidationResult.modelId === _this.model.id ||
                (formValidationResult.modelId === "" && _this.model.id === "temp-node")
            ) {
                this.ui.formErrorCount.show();
                this.ui.formErrorCount.html(formValidationResult.errorsCount);
                this.$el.addClass("form-validation-error");
            }
        });
        this.listenTo(App.vent, "component:form-save", formValidationResult => {
            if (
                formValidationResult.modelId === _this.model.id ||
                (formValidationResult.modelId === "" && _this.model.id === "temp-node")
            ) {
                this.ui.formErrorCount.hide();
                this.$el.removeClass("form-validation-error");
            }
        });
    },

    setConnectNewNodeVisibile: function(isVisible) {
        if (isVisible) {
            this.ui.connectNewNode.css("display", "");
        } else {
            this.ui.connectNewNode.hide();
        }
    },

    toggleRecentDataVisibility: function(isVisible) {
        this.ui.counter.toggleClass("counter-visible", isVisible);
    }
});
