import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing }) => ({
    dialogAction: {
        display: "none"
    },
    overflowVisible: {
        overflow: "visible"
    },
    heightAuto: {
        height: "auto"
    },
    formContainer: {
        margin: spacing(2, 0)
    },
    modal:{
        marginBottom: spacing(3)
    }
}));
