import { ItemView } from "marionette";
import actionsTemplate from "./actionsView.html";
import { template, on, modelEvent, ui } from "core/decorators";
import App from "app";

@ui({
    btnOk: "button.submit",
    btnCancel: "button.cancel"
})
@template(actionsTemplate)
export default class ActionsView extends ItemView {
    disabled = false;
    cancelDisabled = false;

    @on("click @ui.btnOk")
    onSubmit() {
        if (this.disabled) {
            return;
        }

        if (!this.model.trackingEnabled || this.model.isDirty) {
            this.trigger("submit");
        }
    }

    @on("click @ui.btnCancel")
    onCancel() {
        if (this.cancelDisabled) {
            return;
        }

        this.trigger("cancel");
    }

    @modelEvent("change:isDirty")
    @modelEvent("change:trackingEnabled")
    onDirtyChange() {
        if (!this.model.trackingEnabled) {
            return;
        }
        App.vent.trigger("save:adapter:defaults");
        let disabled = this.model.isDirty === false;
        this.ui.btnOk.toggleClass("disabled", disabled);
        this.disabled = disabled;
    }

    @modelEvent("change:submitText")
    updateSubmitText() {
        this.ui.btnOk.text(this.model.submitText);
    }

    disableSubmit() {
        this.disabled = true;
        this.ui.btnOk.addClass("disabled");
    }

    enableSubmit() {
        this.disabled = false;
        this.ui.btnOk.removeClass("disabled");
    }

    disableCancel() {
        this.cancelDisabled = true;
        this.ui.btnCancel.addClass("disabled");
    }
}
