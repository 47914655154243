import React, { useState, useEffect, useMemo } from "react";
import { Clear, Search } from "@mui/icons-material";
import { Grid, InputAdornment } from "@mui/material";
import {
    StriimButton,
    StriimIconButton,
    StriimInputField,
    StriimTypography,
    StriimButtonGroupSimple
} from "@striim/striim-ui";
import { components } from "react-select";

import { styles } from "./job-apps-table.styles";
import useDebounce from "../../../../../hooks/useDebounce";
import getAvailableSources from "../../../source-list-utils";
import { TABS } from "../job-list-insights-view/job-list-insights-view";
import { formatUnixTimestamp } from "../../discovery/components/jobs-list-table";
import dateLib from "core/utils/date-utils";

//completedTime - in seconds
export const getRelativeTime = (completedTime: number): string | null => {
    if (!completedTime) return null;
    const now = dateLib();
    const date = dateLib(completedTime * 1000);
    let relativeTimeValue;

    if (now.diff(date, "day") >= 1) {
        relativeTimeValue = formatUnixTimestamp(completedTime * 1000);
    } else {
        relativeTimeValue = date.fromNow();
    }
    return relativeTimeValue;
};

interface JobTableFiltersProps {
    sourceApps: AppProps[];
    setTableData: React.Dispatch<React.SetStateAction<AppProps[]>>;
    selectedTab: string;
    setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
    completedTime?: number;
}

const JobAppsTableFilter: React.FC<JobTableFiltersProps> = ({
    sourceApps,
    setTableData,
    selectedTab,
    setSelectedTab,
    completedTime = null
}) => {
    const [filters, setFilters] = useState({
        searchQuery: "",
        selectedSource: null,
        selectedNamespace: null
    });
    const hasFilters = useMemo(() => filters.searchQuery || filters.selectedSource || filters.selectedNamespace, [
        filters
    ]);
    const [availableAppSources, setAvailableAppSources] = useState<{ label: string; value: string }[]>([]);

    useEffect(() => {
        if (!sourceApps) {
            return;
        }
        setAvailableAppSources(getAvailableSources(sourceApps));
    }, [sourceApps]);

    const namespaceList = [...new Set(sourceApps.map(app => app.nsName))].map(nsName => ({
        label: nsName,
        value: nsName
    }));

    const filterApps = () => {
        setTableData(
            sourceApps.filter(app => {
                const matchesSearch = app.name.toLowerCase().includes(filters.searchQuery?.toLowerCase());
                const matchesSource =
                    !filters.selectedSource ||
                    app.sources.some(source => source["adapter-name"] === filters.selectedSource);
                const matchesNamespace = !filters.selectedNamespace || app.nsName === filters.selectedNamespace;
                return matchesSearch && matchesSource && matchesNamespace;
            })
        );
    };

    const setFiltersDebounced = useDebounce(filterApps, 500);

    const handleFilterChange = (field: string, value: any) => {
        setFilters(prevFilters => ({ ...prevFilters, [field]: value }));
    };

    const clearSearch = () => {
        setFilters(prevFilters => ({ ...prevFilters, searchQuery: "" }));
        setFiltersDebounced();
    };

    const handleClearFilters = () => {
        setFilters({
            searchQuery: "",
            selectedSource: null,
            selectedNamespace: null
        });
        setTableData(sourceApps);
    };

    const handleChange = value => {
        setSelectedTab(value);
    };

    const relativeTime = useMemo(() => {
        return getRelativeTime(completedTime);
    }, [completedTime]);

    return (
        <Grid
            container
            flexDirection="column"
            height={36}
            gap={1.5}
            alignContent="space-between"
            mb={selectedTab === TABS.LIST ? 3 : 1}
        >
            <Grid item mb={3} sx={styles.headingContainer}>
                <StriimButtonGroupSimple
                    handleChange={handleChange}
                    tabs={Object.values(TABS)}
                    selectedTab={selectedTab}
                />

                {selectedTab === TABS.INSIGHTS && !!relativeTime && (
                    <StriimTypography variant="caption3" sx={styles.simpleName}>
                        {`UPDATED ${relativeTime.toUpperCase()}`}
                    </StriimTypography>
                )}
                {selectedTab === TABS.LIST ? (
                    <Grid display={"flex"} height={36} gap={1.5} width={"auto"}>
                        <StriimInputField
                            sx={{ width: 306 }}
                            placeholder="Search by App name"
                            value={filters.searchQuery}
                            onChange={searchValue => {
                                handleFilterChange("searchQuery", searchValue);
                                setFiltersDebounced();
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search opacity={0.45} />
                                    </InputAdornment>
                                ),
                                endAdornment: filters.searchQuery ? (
                                    <StriimIconButton
                                        variant="primary"
                                        sx={styles.endAdornmentContainer}
                                        onClick={() => {
                                            clearSearch();
                                        }}
                                    >
                                        <Clear sx={styles.iconButton} />
                                    </StriimIconButton>
                                ) : null
                            }}
                        />
                        <StriimInputField
                            id="data-test-id-namespace"
                            name="namespace"
                            select
                            placeholder="Namespace"
                            sx={styles.autoWidth}
                            value={namespaceList.find(option => option.value === filters.selectedNamespace) || ""}
                            onChange={option => {
                                handleFilterChange("selectedNamespace", option?.value);
                                setFiltersDebounced();
                            }}
                            SelectProps={{
                                options: namespaceList,
                                components: {
                                    Option: componentProps => (
                                        <components.Option {...componentProps}>
                                            <Grid display="flex" alignItems="center" gap={1}>
                                                <div>{componentProps.data.label}</div>
                                            </Grid>
                                        </components.Option>
                                    )
                                }
                            }}
                        />
                        <StriimInputField
                            id="data-test-id-sources"
                            name="sources"
                            select
                            placeholder="Source"
                            sx={styles.autoWidth}
                            value={availableAppSources.find(option => option.value === filters.selectedSource) || ""}
                            onChange={option => {
                                handleFilterChange("selectedSource", option?.value);
                                setFiltersDebounced();
                            }}
                            SelectProps={{
                                options: availableAppSources,
                                components: {
                                    Option: componentProps => (
                                        <components.Option {...componentProps}>
                                            <Grid display="flex" alignItems="center" gap={1}>
                                                <div>{componentProps.data.label}</div>
                                            </Grid>
                                        </components.Option>
                                    )
                                }
                            }}
                        />

                        <StriimButton
                            variant="text"
                            data-test-id="clear-filters"
                            onClick={handleClearFilters}
                            disabled={!hasFilters}
                        >
                            Clear filters
                        </StriimButton>
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
};

export default JobAppsTableFilter;
