import React, { useMemo } from "react";
import { StriimTypography, StriimLink } from "@striim/striim-ui";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Box, Grid } from "@mui/material";

import FormikField from "src/generic/formik-field";
import { USER_MANAGEMENT_STRINGS } from "../../../constants";
import Dictionary from "app/components/common/helpable/online-help-dictionary";

const validationSchema = Yup.object({
    email: Yup.string().email(),
    password: Yup.string().test("passwordRequired", "Required", function(password) {
        if (this.parent.ldapuser || password) {
            return true;
        }
        return false;
    }),
    phone: Yup.string().matches(new RegExp(/^[0-9]{0,15}$/), "Must be a valid number")
});

const BasicInfo = React.memo(({ userDetails, formRef, passwordValue, isEditable = true }) => {
    const basicInfo = useMemo(
        () =>
            userDetails
                ? {
                      name: `${
                          userDetails.firstName || userDetails.lastName
                              ? `${userDetails.firstName || ""} ${userDetails.lastName || ""}`
                              : userDetails["User Id"]
                      } ${userDetails.originType?.toLowerCase() === "ldap" ? ` (LDAP user)` : ``}`,
                      username: userDetails["User Id"],
                      isLdapUser: userDetails.originType?.toLowerCase() === "ldap",
                      propertyset: userDetails.ldap_propertyset || "-",
                      alias: userDetails.aliasFor || "-",
                      firstName: userDetails.firstName || (isEditable ? "" : "-"),
                      lastName: userDetails.lastName || (isEditable ? "" : "-"),
                      emailAddress:
                          (userDetails.contactMechanisms || []).find(x => x.contactType === "email")?.data ||
                          (isEditable ? "" : "-"),
                      phone:
                          (userDetails.contactMechanisms || []).find(x => x.contactType === "phone")?.data ||
                          (isEditable ? "" : "-"),
                      timezone: userDetails.timezone || (isEditable ? "" : "-")
                  }
                : { name: "-", firstName: "-", lastName: "-", emailAddress: "-", phone: "-", timezone: "-" },
        [userDetails]
    );

    return (
        <Grid item xs={12}>
            <Grid container>
                {basicInfo.isLdapUser && (
                    <Grid item display="flex" alignItems="center">
                        <StriimTypography variant="caption">{USER_MANAGEMENT_STRINGS.label.ldapInfo}</StriimTypography>
                        <StriimLink
                            href={Dictionary.get()["USERS_LDAP_AUTHENTICATION"].href}
                            target="_blank"
                            sx={{
                                color: ({ palette }) => `${palette.secondary[500]} !important`
                            }}
                        >
                            {USER_MANAGEMENT_STRINGS.button.learnMoreLDAP}
                        </StriimLink>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Formik
                    initialValues={{
                        username: basicInfo.username || "",
                        firstName: basicInfo.firstName || "",
                        lastName: basicInfo.lastName || "",
                        password: passwordValue,
                        email: basicInfo.emailAddress || "",
                        phone: basicInfo.phone || "",
                        timezone: basicInfo.timezone || "",
                        ldapuser: basicInfo.isLdapUser || false
                    }}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    validateOnChange
                    innerRef={formRef}
                >
                    {({ values, handleChange }) => {
                        return (
                            <Box component={Form}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item xs={12} marginBottom={1}>
                                        <StriimTypography variant="h3" color="primary.900">
                                            {USER_MANAGEMENT_STRINGS.label.basicInfo}
                                        </StriimTypography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1} direction="column">
                                            <Grid item xs={12}>
                                                <StriimTypography variant="h5" color="greyscale.800">
                                                    {USER_MANAGEMENT_STRINGS.label.username}
                                                </StriimTypography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <StriimTypography variant="body4" color="greyscale.700">
                                                    {basicInfo.username}
                                                </StriimTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {basicInfo.isLdapUser ? (
                                        <>
                                            <Grid item xs={5}>
                                                <StriimTypography>
                                                    {USER_MANAGEMENT_STRINGS.label.propertyset}
                                                </StriimTypography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <StriimTypography>{basicInfo.propertyset}</StriimTypography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <StriimTypography>
                                                    {USER_MANAGEMENT_STRINGS.label.alias}
                                                </StriimTypography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <StriimTypography>{basicInfo.alias}</StriimTypography>
                                            </Grid>
                                        </>
                                    ) : isEditable ? (
                                        <Grid item xs={12}>
                                            <Box
                                                required
                                                component={FormikField}
                                                name="password"
                                                label={USER_MANAGEMENT_STRINGS.inputLabel.password}
                                                type="password"
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <StriimTypography>
                                                    {USER_MANAGEMENT_STRINGS.label.password}
                                                </StriimTypography>
                                            </Grid>
                                            <Grid item>
                                                <StriimTypography>*****</StriimTypography>
                                            </Grid>
                                        </Grid>
                                    )}

                                    {isEditable ? (
                                        <>
                                            <Grid item>
                                                <Box
                                                    component={FormikField}
                                                    name="firstName"
                                                    label={USER_MANAGEMENT_STRINGS.inputLabel.firstName}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Box
                                                    component={FormikField}
                                                    name="lastName"
                                                    label={USER_MANAGEMENT_STRINGS.inputLabel.lastName}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Box
                                                    component={FormikField}
                                                    name="email"
                                                    label={USER_MANAGEMENT_STRINGS.inputLabel.email}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Box
                                                    component={FormikField}
                                                    name="phone"
                                                    label={USER_MANAGEMENT_STRINGS.inputLabel.phone}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Box
                                                    component={FormikField}
                                                    name="timezone"
                                                    label={USER_MANAGEMENT_STRINGS.inputLabel.timezone}
                                                />
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <StriimTypography>
                                                        {USER_MANAGEMENT_STRINGS.label.firstName}
                                                    </StriimTypography>
                                                </Grid>
                                                <Grid item>
                                                    <StriimTypography>{basicInfo.firstName}</StriimTypography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <StriimTypography>
                                                        {USER_MANAGEMENT_STRINGS.label.lastName}
                                                    </StriimTypography>
                                                </Grid>
                                                <Grid item>
                                                    <StriimTypography>{basicInfo.lastName}</StriimTypography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <StriimTypography>
                                                        {USER_MANAGEMENT_STRINGS.label.emailAddress}
                                                    </StriimTypography>
                                                </Grid>
                                                <Grid item>
                                                    <StriimTypography>{basicInfo.emailAddress}</StriimTypography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <StriimTypography>
                                                        {USER_MANAGEMENT_STRINGS.label.phone}
                                                    </StriimTypography>
                                                </Grid>
                                                <Grid item>
                                                    <StriimTypography>{basicInfo.phone}</StriimTypography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <StriimTypography>
                                                        {USER_MANAGEMENT_STRINGS.label.timezone}
                                                    </StriimTypography>
                                                </Grid>
                                                <Grid item>
                                                    <StriimTypography>{basicInfo.timezone}</StriimTypography>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Box>
                        );
                    }}
                </Formik>
            </Grid>
        </Grid>
    );
});

BasicInfo.displayName = "BasicInfo";
export default BasicInfo;

export { validationSchema };
