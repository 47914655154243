import FlowDatasource from "./FlowDatasource";

var _cache = {};

function getKey(applicationId) {
    return "flowdatasource_" + applicationId;
}

var CachedFlowDatasource = FlowDatasource.extend({
    load: function () {
        var cacheKey = getKey(this.options.appName);

        return new Promise((resolve, reject) => {
            let cached = _cache[cacheKey];
            if (cached) {
                this.rewriteValues(cached);
                resolve(this.graphModel);
                return;
            }

            FlowDatasource.prototype.load.apply(this).then(() => {
                _cache[cacheKey] = this;
                resolve(this.graphModel);
            }).catch(reject);
        });
    },

    invalidateCache: function () {
        let key = getKey(this.options.appName);
        if (_cache[key]) {
            delete _cache[key];
        }
    },

    rewriteValues: function (cached) {
        let keys = Object.keys(cached);

        for (let i = 0; i < keys.length; i++) {
            this[keys[i]] = cached[keys[i]];
        }
    },
});

export default CachedFlowDatasource;
