import _ from "underscore";
import React from "react";
import ReactDOM from "react-dom/client";
import BaseControl from "./../base-control";
import ConnectionProfileSelector from "../../../../../../src/generic/connection-profile-selector/connection-profile-selector";
import { StriimTheme } from "@striim/striim-ui";

const Model = BaseControl.Model.extend({
    defaults: {
        value: null
    }
});

const View = BaseControl.View.extend({
    template: _.template(`<div>   </div>`),
    getViewValue: function() {
        return this.model.get("value");
    },

    setValue: function(value) {
        this.model.set("value", value);
        this.forceChanged();
    },

    valueChanged: function(value) {
        this.model.set("value", value);
        this.options.onChange(value);
    },

    onRender: function() {
        try {
            const root = ReactDOM.createRoot(this.$el[0]);
            root.render(
                <StriimTheme>
                    <ConnectionProfileSelector
                        name={this.options.name}
                        // use the label from base-control
                        // so hide the react label
                        label={<></>}
                        value={this.model.get("value")}
                        required={this.options.required}
                        enabled={this.options.enabled}
                        supportingConnectionProfiles={this.options.supportedEndpoints}
                        onChange={this.valueChanged.bind(this)}
                    />
                </StriimTheme>
            );
        } catch (e) {
            console.error("Error rendering connection profile select component: ", e);
        }
    }
});

function buildConnectionProfileSelectField() {
    return function(options) {
        return _.extend({}, BaseControl, {
            View: View.extend({
                initialize: function() {
                    this.options = options;
                    View.prototype.initialize.apply(this, arguments);
                }
            }),
            Model: Model
        });
    };
}

export default buildConnectionProfileSelectField();
