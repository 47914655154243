import React, { useState, useEffect } from "react";
import { StriimNavbar, useNavbarStateManager, StriimChip, StriimTypography } from "@striim/striim-ui";
import { Grid } from "@mui/material";

import App from "app";
import securityService from "core/services/securityService/securityService";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import Tracker from "core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../core/services/tracker/constants";
import { SECTIONS } from "../apps/pages/manage-striim/constants";
import {
    HomeIcon,
    AppIcon,
    MonitorIcon,
    ManageIcon,
    DashboardIcon,
    ConsoleIcon,
    Insights
} from "../../generic/icon/customIcons";

import headerStore from "src/stores/header";
import LatestApps from "./components/latest-apps";
import LatestDashboards from "./components/latest-dashboards";
import StriimAISubmenu from "./components/striim-ai-submenu";
import copilotService from "core/services/copilotService";

const AIButton = () => {
    const chipStyles = {
        ml: 1,
        backgroundColor: "transparent",
        "&.MuiChip-root": {
            padding: 1 / 2,
            borderRadius: "4px",
            border: ({ palette }) => `1px solid ${palette.secondary[500]}`,
            height: ({ spacing }) => spacing(2),
            "&:hover": {
                backgroundColor: "transparent"
            }
        }
    };

    return (
        <Grid sx={{ display: "flex", alignItems: "center" }}>
            Striim AI
            <StriimChip
                type="tag-chip"
                label={
                    <StriimTypography variant="caption4" color="secondary.500">
                        NEW
                    </StriimTypography>
                }
                hideAvatar
                sx={chipStyles}
                clickable={false}
            />
        </Grid>
    );
};

const MenuLabels = {
    apps: "Apps",
    createApp: "Create an App",
    viewAllApps: "View all Apps",
    monitor: "Monitor",
    manageStriim: "Manage Striim",
    alerts: "Alert Manager",
    connectionProfiles: "Connection Profiles",
    files: "Files",
    metadata: "Metadata",
    propertySets: "Property Sets",
    embeddingGenerator: "Vector Embeddings Generator",
    propertyVariables: "Property Variables",
    usersAndRoles: "Users + Roles",
    vaults: "Vaults",
    dashboards: "Dashboards",
    viewAllDashboards: "View All Dashboards",
    console: "Console",
    striimAI: <AIButton />
};

const getSelectedOption = function(to) {
    let url = to || window.location.href;
    if (url.indexOf("#/landing") !== -1) {
        return ["/landing"];
    }
    if (url.indexOf("#/monitor") !== -1) {
        return ["/monitor"];
    }
    if (url.indexOf("#/sherlock-ai") !== -1) {
        return ["/striim-ai", "/sherlock-ai"];
    }
    if (url.indexOf("#/embedding-generator") !== -1) {
        return ["/striim-ai", "/embedding-generator"];
    }
    if (url.indexOf("#/console") !== -1) {
        return ["/console"];
    }
    if (url.indexOf("#/apps") !== -1 || url.indexOf("#/applications") !== -1) {
        return ["/applications", "/applications"];
    }
    if (url.indexOf("#/dashboards") !== -1 || url.indexOf("#/dashboards-list") !== -1) {
        return ["/dashboards", "/dashboards-list"];
    }
    if (url.indexOf("#/dashboard/") !== -1) {
        return ["/dashboards", url.substr(url.indexOf("/dashboard/"))];
    }
    if (url.indexOf("#/users") !== -1) {
        return ["/manage-striim", "/users"];
    }
    if (url.indexOf("#/alertmanager") !== -1) {
        return ["/manage-striim", "/alertmanager"];
    }
    if (url.indexOf("#/manage-striim/connection-profiles") !== -1) {
        return ["/manage-striim", "/manage-striim/connection-profiles"];
    }
    if (url.indexOf("#/manage-striim/files") !== -1) {
        return ["/manage-striim", "/manage-striim/files"];
    }
    if (url.indexOf("#/manage-striim/vaults") !== -1) {
        return ["/manage-striim", "/manage-striim/vaults"];
    }
    if (url.indexOf("#/manage-striim/metadata-manager") !== -1) {
        return ["/manage-striim", "/manage-striim/metadata-manager"];
    }
    if (url.indexOf("#/manage-striim/property-sets") !== -1) {
        return ["/manage-striim", "/manage-striim/property-sets"];
    }
    if (url.indexOf("#/manage-striim/property-variables") !== -1) {
        return ["/manage-striim", "/manage-striim/property-variables"];
    }
    if (url.indexOf("#/create-app") !== -1 || url.indexOf("/#/appwizard") !== -1) {
        return ["/applications", "/create-app"];
    }
    if (url.indexOf("#/flow") !== -1) {
        return ["/applications", url.substr(url.indexOf("/flow"))];
    }
    return [to];
};

const NavBar = ({ setContentLayout }) => {
    const [refresh, setRefresh] = useState(0);
    const navbarStateManager = useNavbarStateManager();
    const [loaded, setLoaded] = useState(null);

    useEffect(() => {
        if (loaded) {
            document.querySelector("#page-content").style.transition = "margin-left 0.3s ease";
            document.querySelector("#content").style.transition = "margin-left 0.3s ease 0s, width 0.3s ease 0s";
        }
        setLoaded(true);
    }, [loaded]);

    useEffect(() => {
        let [selectedRoute, selectedSubroute] = getSelectedOption();
        const { route: currentRoute, subRoute: currentSubroute } = navbarStateManager.currentSelection || {};
        if (currentRoute !== selectedRoute || currentSubroute !== selectedSubroute) {
            navbarStateManager.updateSelection({
                route: selectedRoute,
                subRoute: selectedSubroute
            });
        }
    }, [navbarStateManager]);

    const navbarClick = ({ route: to, subRoute }) => {
        to = to.charAt(1).toUpperCase() + to.slice(2);
        let [selectedRoute, selectedSubroute] = getSelectedOption();
        navbarStateManager.updateSelection({
            route: selectedRoute,
            subRoute: selectedSubroute
        });
        Tracker.getInstance().track(TRACKER_STRINGS.schema.navigationBar, {
            event: TRACKER_STRINGS.eventClicked.navigationBar,
            buttonClicked: subRoute || to,
            currentPage: selectedSubroute || selectedRoute
        });
    };

    useEffect(() => {
        const onSegmentationRefresh = () => setRefresh(refresh + 1);
        App.vent.on("segmentation:refreshedstates", onSegmentationRefresh);

        return () => App.vent.off("segmentation:refreshedstates", onSegmentationRefresh);
    }, [refresh]);

    const alertManagerAvailable = securityService.isSegmentationFeatureEnabled(addonFeaturesKeys.ALERTMANAGER);

    const routes = [
        {
            route: "/striim-ai",
            label: MenuLabels.striimAI,
            icon: Insights,
            submenu: [
                {
                    customComponent: (
                        <StriimAISubmenu
                            navbarStateManager={navbarStateManager}
                            enableCopilot={copilotService.isCopilotEnabled}
                        />
                    ),
                    showSeparator: false
                }
            ]
        },
        { route: "/landing", label: "Home", icon: HomeIcon },
        {
            route: "/applications",
            label: MenuLabels.apps,
            icon: AppIcon,
            submenu: [
                { label: MenuLabels.createApp, route: `/create-app` },
                { label: MenuLabels.viewAllApps, route: `/applications` },
                {
                    customComponent: <LatestApps headerStore={headerStore} navbarStateManager={navbarStateManager} />
                }
            ]
        },
        { route: "/monitor", label: MenuLabels.monitor, icon: MonitorIcon },
        {
            route: "/manage-striim",
            label: MenuLabels.manageStriim,
            icon: ManageIcon,
            submenu: [
                {
                    route: alertManagerAvailable ? "/alertmanager" : "/alert-manager",
                    label: MenuLabels.alerts
                },
                {
                    label: MenuLabels.connectionProfiles,
                    route: "/manage-striim/connection-profiles"
                },
                {
                    label: MenuLabels.files,
                    route: `/manage-striim/${SECTIONS.files}`
                },
                {
                    label: MenuLabels.metadata,
                    route: "/manage-striim/metadata-manager"
                },
                {
                    label: MenuLabels.propertySets,
                    route: "/manage-striim/property-sets"
                },
                {
                    label: MenuLabels.propertyVariables,
                    route: "/manage-striim/property-variables"
                },
                { label: MenuLabels.usersAndRoles, route: "/users" },
                { label: MenuLabels.vaults, route: "/manage-striim/vaults" }
            ]
        },
        {
            route: "/dashboards",
            label: MenuLabels.dashboards,
            icon: DashboardIcon,
            submenu: [
                {
                    label: MenuLabels.viewAllDashboards,
                    route: `/dashboards-list`
                },
                {
                    customComponent: (
                        <LatestDashboards headerStore={headerStore} navbarStateManager={navbarStateManager} />
                    )
                }
            ]
        },
        { route: "/console", label: MenuLabels.console, icon: ConsoleIcon }
    ];

    let isAdmin = false;
    App.user.roles.forEach(function(role) {
        isAdmin =
            isAdmin || (role?.domain === "Global" && (role?.roleName === "admin" || role?.roleName === "saasadmin"));
    });

    if (!App?.userPermissions)
        // Logged out
        return null;

    const deleteRouteByLabel = (routes, label) => {
        const index = routes.findIndex(route => route.label === label);
        if (index !== -1) {
            routes.splice(index, 1);
        } else {
            routes.forEach(route => {
                if (route.submenu) {
                    deleteRouteByLabel(route.submenu, label);
                }
            });
        }
    };

    if (App.userPermissions["*:*:apps_ui:*"] === false) {
        deleteRouteByLabel(routes, MenuLabels.apps);
    }
    if (App.userPermissions["*:*:dashboard_ui:*"] === false) {
        deleteRouteByLabel(routes, MenuLabels.dashboards);
    }
    if (App.userPermissions["*:*:monitor_ui:*"] === false) {
        deleteRouteByLabel(routes, MenuLabels.monitor);
    }
    if (!isAdmin) {
        deleteRouteByLabel(routes, MenuLabels.alerts);
        deleteRouteByLabel(routes, MenuLabels.usersAndRoles);
    }

    return (
        <StriimNavbar
            routes={routes}
            navbarStateManager={navbarStateManager}
            onSelection={navbarClick}
            onNavbarToggle={setContentLayout}
        />
    );
};

export default NavBar;
