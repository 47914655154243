import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import resources from "../system_health_monitor_resources.json";

import { StriimTypography, StriimList, StriimListItem } from "@striim/striim-ui";
import { ListSubheader, Grid, LinearProgress } from "@mui/material";
import formatter from "app/components/monitor/common/valueFormatter";
import MetricCard from "src/modules/homepage/common/metric_card.jsx";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles(() => ({
    dropDownBar: {
        margin: 10,
        backgroundColor: resources.emptyColor,
        minHeight: 8,
        minWidth: 95
    },
    bar: {
        backgroundColor: resources.memoryColor
    },
    customCardStyles: {
        border: theme => `1px solid ${theme.palette.greyscale[200]}`,
        borderRadius: "0px"
    }
}));

const MemoryMonitorTile = ({ value, data }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const getPieChartData = () => [
        { name: "Used", color: getDisplayColor(value), value: value },
        { name: "Free", color: resources.emptyColor, value: 1 - value }
    ];

    const getDisplayColor = value =>
        value > 0.9
            ? theme.palette.critical.main
            : value < 0.5
            ? theme.palette.success.main
            : theme.palette.warning.main;

    const getDisplayText = () => {
        return !value ? "Fetching…" : value === 0 ? "0%" : formatter.toPercentageString(value);
    };

    const getDropDownList = () => {
        return (
            <StriimList
                sx={{ minWidth: "320px" }}
                subheader={<ListSubheader disableSticky>Memory usage per node</ListSubheader>}
            >
                {data.map(nodeData => (
                    <StriimListItem
                        key={nodeData.name + nodeData.mem}
                        text={
                            <Grid container>
                                <Grid item xs={5}>
                                    <StriimTypography variant="body">{nodeData.name}</StriimTypography>
                                </Grid>
                                <Grid item xs>
                                    <LinearProgress
                                        className={classes.dropDownBar}
                                        variant="determinate"
                                        value={nodeData.mem * 100}
                                        sx={{
                                            ".MuiLinearProgress-bar": {
                                                backgroundColor:
                                                    nodeData.cpu > 0.9
                                                        ? "critical.main"
                                                        : nodeData.cpu < 0.5
                                                        ? "success.main"
                                                        : "warning.main"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <StriimTypography display="inline" variant="bodySemibold">
                                        {getDisplayText(nodeData.mem)}
                                    </StriimTypography>
                                </Grid>
                            </Grid>
                        }
                    />
                ))}
            </StriimList>
        );
    };
    return (
        <MetricCard
            title="Memory Use"
            pieChartData={getPieChartData()}
            displayText={getDisplayText()}
            displayColor={getDisplayColor(value)}
            dropdownList={getDropDownList()}
            customCardStyles={classes.customCardStyles}
        />
    );
};

export default MemoryMonitorTile;

MemoryMonitorTile.propTypes = {
    value: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired
};
