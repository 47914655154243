import React, { useState } from "react";
import { StriimButton, StriimTable, StriimTypography } from "@striim/striim-ui";
import { ManageAccounts } from "src/generic/icon/customIcons";
import {
    AddRolesModal,
    applyCellRendererToColumns,
    EmptyTableInfo
} from "src/modules/apps/pages/user-management/common";
import { roleTableColumns } from "../../constants";
import { Grid, useTheme } from "@mui/material";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";
import styles from "../../../common/table-element.styles";

const getActionsCell = (role, revokedRoles, setRevokedRoles) => {
    const revoked = revokedRoles.map(r => r.name).indexOf(role.name) !== -1;

    return (
        <Grid container justifyContent="space-between">
            <StriimButton
                variant="text"
                onClick={() => {
                    setRevokedRoles([...revokedRoles, role]);
                }}
                data-testid={`${role.name}-revoke-button`}
                disabled={revoked}
                sx={styles.buttonWrapper}
            >
                {revoked ? "Revoked" : "Revoke"}
            </StriimButton>
            <StriimButton
                variant="text"
                onClick={() => {
                    setRevokedRoles(revokedRoles.filter(r => r.name !== role.name));
                }}
                data-testid={`${role.name}-undo-button`}
                style={revoked ? {} : { display: `none` }}
                sx={styles.buttonWrapper}
            >
                Undo
            </StriimButton>
        </Grid>
    );
};

const RoleTable = ({ roles, editable, setAddedRoles, addedRoles = [], revokedRoles = [], setRevokedRoles }) => {
    const { palette } = useTheme();

    const [addRoleModalVisible, setAddRoleModalVisible] = useState(false);

    const columnsWithRenderer = applyCellRendererToColumns(roleTableColumns, revokedRoles);

    const columns = editable ? columnsWithRenderer : columnsWithRenderer.slice(0, 2);

    let rolesToShow = [...roles.concat(addedRoles)];

    if (editable) {
        rolesToShow = rolesToShow.map(role => {
            const actions = getActionsCell(role, revokedRoles, setRevokedRoles);
            return { ...role, actions };
        });
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid container item alignItems="center" justifyContent="space-between">
                    <StriimTypography variant="h3" color="primary.900">
                        Roles
                    </StriimTypography>
                    {editable && (
                        <StriimButton
                            variant="text"
                            onClick={() => setAddRoleModalVisible(true)}
                            data-testid="add-role-button"
                            startIcon={<AddIcon />}
                        >
                            Add role
                        </StriimButton>
                    )}
                </Grid>
                <Grid item md={12}>
                    <StriimTable
                        data={rolesToShow}
                        columns={columns}
                        TableProps={{ "data-testid": "roles-table" }}
                        TableHeaderCellProps={{
                            style: {
                                backgroundColor: palette.greyscale[50]
                            }
                        }}
                        NoRowsComponent={
                            <EmptyTableInfo
                                Icon={ManageAccounts}
                                text={`You can start adding roles for new user using the 'Add role' button`}
                            />
                        }
                        indexedRow
                        TableBodyProps={{ seperated: true }}
                    />
                </Grid>
            </Grid>
            {editable && (
                <AddRolesModal
                    rolesToExclude={rolesToShow}
                    visible={addRoleModalVisible}
                    onConfirm={selectedValues => {
                        selectedValues = selectedValues || [];
                        setAddedRoles([...addedRoles, ...selectedValues]);
                        setAddRoleModalVisible(false);
                    }}
                    onCancel={() => {
                        setAddRoleModalVisible(false);
                    }}
                />
            )}
        </>
    );
};

export default RoleTable;
