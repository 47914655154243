import React from "react";
import { Grid, LinearProgress } from "@mui/material";
import { StriimTypography, StriimChip } from "@striim/striim-ui";
import numeral from "numeral";

import { styles } from "./entity-summary.styles";
import CollapsibleSummary from "./collapsible-summary";

interface EntitySummaryProps {
    title?: string;
    defaultOpen?: boolean;
    sensitiveColumns: number;
    totalColumns: number;
    rowsSampled: number;
    identifiers: string[];
    eventType: string;
    fieldType: string;
}

const EntitySummary = ({
    title = "Overview",
    defaultOpen = false,
    sensitiveColumns,
    totalColumns,
    rowsSampled,
    identifiers,
    eventType,
    fieldType
}: EntitySummaryProps) => {
    const successPercent = sensitiveColumns === 0 ? 0 : (sensitiveColumns * 100) / totalColumns;

    return (
        <Grid item alignItems={"center"} sx={[styles.container, styles.overviewWrapper]} width={332} mt={"94px"}>
            <CollapsibleSummary title={title} defaultOpen={defaultOpen}>
                <Grid container p={2} flexDirection={"column"} gap={1}>
                    <StriimTypography variant="caption1" color="greyscale.900" width={"100%"}>
                        {fieldType}s w/ Sensitive Data
                    </StriimTypography>
                    <StriimTypography variant="body" color="primary.800" fontSize={22}>
                        {sensitiveColumns}/{totalColumns}
                    </StriimTypography>
                    <LinearProgress variant="determinate" value={successPercent} sx={styles.bar} />
                </Grid>
                <Grid container p={2} gap={1} flexDirection={"column"} mb={2}>
                    <StriimTypography variant="caption1" color="greyscale.700">
                        {eventType}s Sampled
                    </StriimTypography>
                    <StriimTypography variant="body" color="primary.800" fontSize={22}>
                        {numeral(rowsSampled).format("0,0")}
                    </StriimTypography>
                </Grid>
                <Grid container flexDirection={"column"} px={2} pt={3} pb={2} sx={styles.borderTop}>
                    <StriimTypography variant="h4" color="primary.700">
                        Data Identifiers Discovered
                    </StriimTypography>
                    <Grid
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                        mt={2}
                        item
                        gap={1.5}
                        maxHeight={330}
                        overflow={"scroll"}
                    >
                        {identifiers.map(identifier => (
                            <StriimChip
                                key={identifier}
                                type="tag-chip"
                                variant="intresting"
                                label={identifier}
                                sx={styles.chip}
                            />
                        ))}
                    </Grid>
                </Grid>
            </CollapsibleSummary>
        </Grid>
    );
};

export default EntitySummary;
