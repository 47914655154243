import React from "react";
import SourceSensitiveDataTable from "../source-sensitive-data-table/source-sensitive-data-table";
import JobAppsTable from "../job-apps-table/job-apps-table";
import { ViewType } from "../../pages/guardian-view-app";
import DataIdentifierAppsTable from "../data-identifier-apps-table/data-identifier-apps-table";
import SelectedSourceApps from "../selected-source-apps/selected-source-apps";

export const viewAllTypes = {
    topSources: "topSources",
    topApps: "topApps",
    sensitiveDataIdentifier: "sensitiveDataIdentifier",
    sourceAppsList: "sourceAppsList"
};

export const getViewAllTitle = (viewType: ViewType) => {
    switch (viewType.type) {
        case viewAllTypes.topSources:
            return "All Sources with Sensitive Data";
        case viewAllTypes.topApps:
            return "All Apps with Sensitive Data";
        case viewAllTypes.sensitiveDataIdentifier:
            return `Apps with ${viewType.sensitiveDataIdentifier}`;
        case viewAllTypes.sourceAppsList:
            return "Apps using the Source:"
        default:
            "";
    }
};

const ViewAllContent = ({
    viewType,
    appsDetails,
    allTableData,
    appsWithSDI,
    jobName,
    setViewType,
    setViewAllView,
    setSelectedSource,
    selectedSource,
    appsInfoData
}) => {
    switch (viewType.type) {
        case viewAllTypes.topSources:
            return (
                <SourceSensitiveDataTable
                    data={appsDetails}
                    setViewType={setViewType}
                    setViewAllView={setViewAllView}
                    setSelectedSource={setSelectedSource}
                />);
        case viewAllTypes.topApps:
            return <JobAppsTable tableData={allTableData} jobName={jobName} />;
        case viewAllTypes.sensitiveDataIdentifier:
            return <DataIdentifierAppsTable tableData={appsWithSDI} jobName={jobName} />;
        case viewAllTypes.sourceAppsList:
            return (
                <SelectedSourceApps
                    jobName={jobName}
                    allTableData={allTableData}
                    selectedSource={selectedSource}
                    appsInfoData={appsInfoData}
                />);
    }
};

export default ViewAllContent;
