const DatabaseReader = [{ name: "Username", key: "Username" }];

const DatabaseWriter = [
    { name: "Hostname", key: "host" },
    { name: "Port", key: "port" },
    { name: "Username", key: "username" }
];

const readerData = {
    ORACLE: [...DatabaseReader],
    POSTGRES: [...DatabaseReader],
    YUGABYTEDB: [...DatabaseReader],
    YUGABYTECDC: [...DatabaseReader],
    MYSQL: [...DatabaseReader],
    SQLSERVER: [...DatabaseReader],
    MARIADB: [...DatabaseReader],
    MARIADBXPAND: [...DatabaseReader],
    ORACLEREADER: [...DatabaseReader],
    MYSQLREADER: [...DatabaseReader],
    MSSQLREADER: [...DatabaseReader],
    POSTGRESQLREADER: [...DatabaseReader],
    MARIADBXPANDREADER: [...DatabaseReader],
    SERVICENOWREADER: [
        { name: "Connection URL", key: "ConnectionUrl" },
        { name: "Username", key: "UserName" }
    ],
    SALESFORCEPARDOTREADER: [
        { name: "Username", key: "UserName" },
        { name: "Consumer Key", key: "consumerKey" },
        { name: "Business Unit ID", key: "BusinessUnitId" }
    ],
    HUBSPOTREADER: [{ name: "Authentication Mode", key: "AuthMode" }],
    INCREMENTALBATCHREADER: [
        { name: "Connection URL", key: "ConnectionURL" },
        { name: "Username", key: "Username" }
    ],
    S3READER: [
        { name: "Bucket Name", key: "bucketname" },
        { name: "Object Name Prefix", key: "objectnameprefix" }
    ],
    HDFSREADER: [
        { name: "Hadoop URL", key: "hadoopurl" },
        { name: "Wildcard", key: "wildcard" }
    ],
    GCSREADER: [
        { name: "Bucket Name", key: "BucketName" },
        { name: "Object Detection Mode", key: "ObjectDetectionMode" },
        { name: "Project Id", key: "ProjectId" }
    ],
    ADLSREADER: [
        { name: "Account Name", key: "AccountName" },
        { name: "Client Id", key: "ClientId" },
        { name: "Container", key: "Container" },
        { name: "Object Detection Mode", key: "ObjectDetectionMode" },
        { name: "Object Filter", key: "ObjectFilter" },
        { name: "Tenant Id", key: "TenantId" }
    ],
    INTERCOMREADER: [{ name: "Authentication Mode", key: "AuthMode" }],
    GOOGLEADSREADER: [
        {
            name: "Client Customer ID",
            key: "clientCustomerId"
        },
        {
            name: "Manager ID",
            key: "managerId"
        },
        {
            name: "Client ID",
            key: "clientId"
        }
    ],
    JIRAREADER: [
        {
            name: "Auth mode",
            key: "AuthMode"
        },
        {
            name: "Endpoint URL",
            key: "EndpointUrl"
        },
        {
            name: "Username",
            key: "Username"
        },
        {
            name: "Client ID",
            key: "ClientId"
        }
    ],
    ZENDESKREADER: [
        { name: "Connection URL", key: "ConnectionUrl" },
        { name: "Authentication Mode", key: "AuthMode" },
        { name: "Username", key: "UserName" }
    ],
    GOOGLEANALYTICSREADER: [
        { name: "Mode", key: "Mode" },
        { name: "Client ID", key: "ClientId" },
        { name: "Property ID", key: "PropertyId" }
    ],
    LINKEDINADSREADER: [
        { name: "Mode", key: "Mode" },
        { name: "Client ID", key: "ClientId" }
    ],
    BAMBOOHRREADER: [
        { name: "Mode", key: "Mode" },
        { name: "Domain", key: "Domain" }
    ],
    PAYMOREADER: [{ name: "Mode", key: "Mode" }]
};

const writerData = {
    "ORACLE DATABASE": [...DatabaseWriter, { name: "SID", key: "sid" }],
    POSTGRESQL: [...DatabaseWriter, { name: "Database", key: "dbName" }],
    MYSQL: [...DatabaseWriter],
    "SQL SERVER DATABASE": [...DatabaseWriter, { name: "Database", key: "dbName" }],
    MARIADB: [...DatabaseWriter]
};

export { readerData, writerData };
