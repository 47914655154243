import React from "react";
import { StriimButton, StriimLink, StriimTypography, StriimGradientChip } from "@striim/striim-ui";
import { Grid } from "@mui/material";

import CloseIcon from "src/generic/icon/customIcons/Close.svg";
import { styles } from "./ai-insights-popover.styles";
import { Sherlock } from "../../../../../generic/icon/customIcons";
import dictionary from "../../../../../../app/components/common/helpable/online-help-dictionary";

interface AIInsightsPopoverProps {
    onDiscoverSensitiveData: Function;
    onClose: Function;
}

const AIInsightsPopover = ({ onDiscoverSensitiveData, onClose }: AIInsightsPopoverProps) => {
    return (
        <Grid container width={400} p={2} sx={styles.container}>
            <Grid container justifyContent={"space-between"} mb={1}>
                <Sherlock style={{ ...styles.securityImage, alignSelf: "flex-start" }} />
                <CloseIcon style={styles.closeIcon} onClick={onClose} data-test-id="insights-close-btn" />
            </Grid>
            <Grid container alignItems={"center"} mt={2} mb={1} gap={1}>
                <StriimGradientChip version="ai" />
                <StriimTypography variant="h3" color="primary.700">
                    Discover Sensitive Data with Sherlock AI
                </StriimTypography>
            </Grid>
            <Grid container>
                <Grid item mb={1}>
                    <StriimTypography variant="body4" color="greyscale.700">
                        Discover sensitive data identifiers that can flow through your app by examining the sources,
                        enabling you to design the app to meet your data governance policies.
                    </StriimTypography>
                </Grid>
                <Grid container alignItems={"center"} gap={1}>
                    <StriimButton variant="secondary" onClick={onDiscoverSensitiveData}>
                        Discover Sensitive Data
                    </StriimButton>
                    <StriimLink href={dictionary.get()["AI_INSIGHTS_SHERLOCK"].href} target="_blank">
                        Learn more
                    </StriimLink>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AIInsightsPopover;
