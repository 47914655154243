// UI-wide constants
// TODO: Better place for these?
import App from "app";

App.ENTITIES = {
    STREAM: "STREAM",
    SOURCE: "SOURCE",
    EXTERNALSOURCE: "EXTERNALSOURCE",
    CACHE: "CACHE",
    WINDOW: "WINDOW",
    CQ: "CQ",
    TARGET: "TARGET",
    WACTIONSTORE: "WACTIONSTORE",
    TYPE: "TYPE",
    FLOW: "FLOW",
    PROPERTYTEMPLATE: "PROPERTYTEMPLATE",
    DEPLOYMENTGROUP: "DG",
    APPLICATION: "APPLICATION",
    NAMESPACE: "NAMESPACE",
    VISUALIZATION: "VISUALIZATION",
    WIZARD: "WIZARD"
};

export const PRESERVED_NAMES = {
    OAuth: "OAuth",
    OAuthAutomated: "OAuthAutomated",
    ManualOAuth: "Manual OAuth",
    azureSas: "Azure SAS",
    keepTargetTableData: "Keep target table data",
    truncateTargetTableData: "Truncate target table data",
    replaceTargetTableData: "Replace target table data",
    ResourceOwnerPasswordCredentialOAuth: "Resource Owner Password Credential OAuth"
};
