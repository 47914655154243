import React from "react";
import { StriimButton, StriimMessageBox, StriimLink, StriimTypography } from "@striim/striim-ui";
import { Grid } from "@mui/material";

import WarningIcon from "src/generic/icon/manage-striim/warning-triangle.svg";

const styles = {
    messageBox: {
        backgroundColor: "warning.50",
        borderColor: "warning.100",
        "& svg": {
            fontSize: "20px",
            color: "warning.500"
        }
    }
};

interface WarningMessageBoxProps {
    name: string;
    handleDoneClick: () => void;
}

const WarningMessageBox = ({ name, handleDoneClick }: WarningMessageBoxProps) => {
    return (
        <StriimMessageBox
            type="WARNING"
            text={
                <Grid container display={"flex"} justifyContent={"space-between"}>
                    <Grid item>
                        <StriimTypography variant="body4">
                            {name} already have active apps associated. Cannot delete your connection profile at this
                            state.{" "}
                        </StriimTypography>
                        <StriimLink
                            href=""
                            target="_blank"
                            onClick={() => {
                                //TODO: Replace with contextID
                                // window.open(Dictionary.get()[cp].href, "_blank");
                            }}
                        >
                            View Documentation
                        </StriimLink>
                    </Grid>
                    <Grid item>
                        <StriimButton type="text" onClick={() => handleDoneClick()}>
                            Done
                        </StriimButton>
                    </Grid>
                </Grid>
            }
            customCardStyles={styles.messageBox}
            customIcon={<WarningIcon />}
        />
    );
};

export default WarningMessageBox;
