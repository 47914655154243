export default {
    process: function (newNodeType, nodeModel, parentNode, predefinedComponentName, initialProperties) {
        if (!nodeModel || !nodeModel.metaObject || !nodeModel.metaObject.autoProperties) {
            return;
        }

        const autoPropertiesKeys = Object.keys(nodeModel.metaObject.autoProperties);

        //Iterate through all the autoProperties
        for (let i = 0; i < autoPropertiesKeys.length; i++) {
            const autoProperty = nodeModel.metaObject.autoProperties[autoPropertiesKeys[i]];

            if (!autoProperty.properties) {
                continue;
            }

            const autoPropertyPropertiesKeys = Object.keys(autoProperty.properties);

            //Iterate through all the properties of each autoProperty
            for (let j = 0; j < autoPropertyPropertiesKeys.length; j++) {
                const key = autoPropertyPropertiesKeys[j];

                //If property contains "encrypted" field it means this is password and needs to be cleared
                if (autoProperty.properties[key] && autoProperty.properties[key].encrypted) {
                    autoProperty.properties[key] = null;
                }
            }
        }
    },
};
