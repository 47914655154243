import { MenuListProps } from "react-select";

export interface SelectWorkspaceProps {
    onChange: (value: workspaceObject) => void;
    value: workspaceObject;
    error: boolean;
    errorText: string;
    name: string;
    label: string;
    placeholder: string;
    enabled: boolean;
    // made it any since Values type is unknown here
    formRef: any;
}

export enum APICAllStatus {
    NOT_STARTED = "NOT_STARTED",
    FAILURE = "FAILURE",
    SUCCESS = "SUCCESS",
    FETCHING = "FETCHING"
}

export interface workspaceObject {
    Workspace: string;
    WorkspaceId: string;
}
export interface APIResponseType {
    error: null | string;
    status: APICAllStatus;
    data: null | Array<optionsType>;
}

export interface optionsType {
    label: string;
    value: string;
}
export interface APIWorkspaceObjectType {
    workSpaceID: string;
    workSpaceName: string;
}

export interface CustomMenuListProps extends MenuListProps {
    apiResponse: APIResponseType;
}
