import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing }) => ({
    wrapper: {
        padding: spacing(2),
        borderRight: `1px solid ${palette.paper.border}`,
        height: "100%"
    },
    noData: {
        marginBottom: spacing(3)
    },
    noDataIllustration: {
        width: "24px!important",
        height: "24px!important",
        color: "transparent!important",
        marginBottom: spacing(1)
    },
    subtitle: {
        display: "block",
        marginBottom: 20
    },
    unitsAmount: {
        fontWeight: 700,
        fontSize: 32,
        marginBottom: 10
    },
    credits: {
        "& .usage": {
            marginRight: 10
        },
        "& .positive": {
            //color: "green"
        },
        "& .negative": {
            //color: "red"
        }
    }
}));
