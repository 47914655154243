import { Box, Grid } from "@mui/material";
import React, { useMemo } from "react";
import { AdapterIcon } from "../../../../../../../generic/icon/component-icon/component-icon";
import { styles } from "./create-app-page-adapters-list.styles";
import { StriimTypography, StriimTooltip } from "@striim/striim-ui-v2";
import { recentlyUsedAdaptersService } from "../../../services";
import adapterSecurityCheck from "/core/services/securityService/adapter-security-check";

interface AdaptersListProps {
    adapters: any;
    handleSelection: Function;
    type: String;
    selectedView: "list" | "grid";
    isAscendingSort: boolean;
    filters: {
        id: string;
        category: string;
    };
}

const CreateAppPageAdaptersList: React.FC<AdaptersListProps> = ({
    adapters,
    handleSelection,
    type,
    selectedView,
    isAscendingSort,
    filters
}) => {
    const recentlyUsedAdapters = useMemo(() => {
        let recentlyUsedAdapters: string[] = [];

        if (type.toUpperCase() === "SOURCE") {
            recentlyUsedAdapters = recentlyUsedAdaptersService.getSources();
        } else {
            recentlyUsedAdapters = recentlyUsedAdaptersService.getTargets();
        }
        return adapters
            .filter(adapter => recentlyUsedAdapters.includes(adapter.value))
            .sort((a, b) => recentlyUsedAdapters.indexOf(a.value) - recentlyUsedAdapters.indexOf(b.value));
    }, [type]);

    /**
     * First, split the arrays as disabled and enabled.
     * Then, sort the subarrays
     * Then, merge both the arrays.
     */
    const sortedAdapters = useMemo(() => {
        let enabledAdapters = adapters.filter(a => !!a.isEnabled);
        enabledAdapters.sort((a, b) => (isAscendingSort ? a.id.localeCompare(b.id) : b.id.localeCompare(a.id)));
        let disabledAdapters = adapters.filter(a => !a.isEnabled);
        disabledAdapters.sort((a, b) => (isAscendingSort ? a.id.localeCompare(b.id) : b.id.localeCompare(a.id)));
        return [].concat(enabledAdapters, disabledAdapters);
    }, [adapters, isAscendingSort]);

    return (
        <Grid sx={styles.gridContainer}>
            <Grid sx={styles.scrollableContainer}>
                <Grid
                    data-test-id={`supported-adapter-list-${selectedView}-display`}
                    sx={selectedView === "list" ? styles.itemListView : styles.itemGridView}
                >
                    {sortedAdapters.map(adapter => {
                        return (
                            <StriimTooltip
                                title={
                                    adapter.isEnabled
                                        ? null
                                        : "Enabling the selected adapter requires additional license, please contact support@striim.com."
                                }
                                arrow
                                placement="bottom"
                                slotProps={{
                                    tooltip: {
                                        sx: {
                                            fontFamily: "Inter"
                                        }
                                    }
                                }}
                            >
                                <Grid
                                    container
                                    flexDirection={"row"}
                                    sx={styles.itemContainer}
                                    style={adapter.isEnabled ? { opacity: 1 } : { opacity: 0.3, cursor: "not-allowed" }}
                                    onClick={adapter.isEnabled ? () => handleSelection(adapter) : null}
                                    data-test-id={`adapter-${adapter.id}`}
                                    key={adapter.id}
                                >
                                    <AdapterIcon
                                        spriteId={adapter.spriteId}
                                        newlyReleased={adapter.newlyReleased && filters.category !== "new"}
                                        useNative
                                        name={adapter.icon}
                                        type={type}
                                        size={selectedView === "list" ? 26 : 56}
                                        showBorder={selectedView !== "list"}
                                    />
                                    <Box sx={styles.itemText}>
                                        <StriimTypography
                                            variant="caption3"
                                            color={selectedView === "list" ? "primary.900" : "primary.600"}
                                        >
                                            {adapter.id}
                                        </StriimTypography>
                                    </Box>
                                </Grid>
                            </StriimTooltip>
                        );
                    })}
                </Grid>
            </Grid>
            <Grid container flexDirection={"column"} gap={2} p={3} sx={styles.recentlyUsedContainer}>
                <StriimTypography variant="caption2" sx={styles.recentlyUsedText} color="greyscale.700">
                    Recently Used
                </StriimTypography>
                {recentlyUsedAdapters.map(adapter => {
                    return (
                        <Grid
                            container
                            flexDirection={"row"}
                            sx={styles.itemContainer}
                            onClick={adapter.isEnabled ? () => handleSelection(adapter) : null}
                            data-test-id={`recently-used-adapter-${adapter.id}`}
                            style={adapter.isEnabled ? { opacity: 1 } : { opacity: 0.3, cursor: "not-allowed" }}
                            key={adapter.id}
                        >
                            <AdapterIcon
                                spriteId={adapter.spriteId}
                                newlyReleased={adapter.newlyReleased}
                                useNative
                                name={adapter.icon}
                                type={type}
                                size={selectedView === "list" ? 26 : 56}
                                showBorder={selectedView !== "list"}
                            />
                            <Box sx={styles.itemText}>
                                <StriimTypography
                                    variant="caption3"
                                    color={selectedView === "list" ? "primary.900" : "primary.600"}
                                >
                                    {adapter.id}
                                </StriimTypography>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default CreateAppPageAdaptersList;
