import _ from "underscore";
import dateLib from "core/utils/date-utils";

export function formatTableValue(val, key) {
    if (key === "time-taken-for-last-late-checkpoint" || key === "time-taken-for-last-checkpoint") {
        if (val === -1) {
            return "Not Ready";
        }
        if (val === -2) {
            return "Fetching…";
        }
    } else if (key === "logminer-start-duration") {
        return val + "ms";
    }
    if (_.isNumber(val) && val > 1400000000000 && val < 10000000000000) {
        // timestamp yo
        return dateLib(val).format("MMM Do YYYY, h:mm:ss a");
    }

    if (val === null || val === undefined) {
        return "Fetching…";
    }

    if (typeof val === "object") {
        return JSON.stringify(val);
    }

    return val;
}

export function getHumanReadableString(dashedName) {
    if (!dashedName) {
        return "";
    }
    dashedName = dashedName[0].toUpperCase() + dashedName.substr(1);
    let camelCased = dashedName.replace(/-([a-z])/g, function(g) {
        return " " + g[1].toUpperCase();
    });
    return camelCased;
}
