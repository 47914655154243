import React, { useState, useEffect } from "react";
import { Box, Link } from "@material-ui/core";
import { StriimTypography } from "@striim/striim-ui";
import SystemInfoModel from "../../../../../../../../../core/SystemInfoModel";

const DownloadAgentModalContent = () => {
    const [downloadURL, setDownloadURL] = useState("");
    const [version, setVersion] = useState("");

    useEffect(() => {
        let systemInfoModel = new SystemInfoModel();
        setDownloadURL(systemInfoModel.agentDownloadUrl);
        setVersion(systemInfoModel.version);
    }, []);
    return (
        <div id={"modal--download-agent"}>
            <StriimTypography variant="h3">Version: {version}</StriimTypography>
            <StriimTypography variant="body4">
                The Striim Forwarding Agent is a stripped-down version of a Striim server node that can be used to run
                sources locally on a remote host. To use the Agent, download the appropriate installer from the links
                below and install the Striim Forwarding Agent the machine that has your source. Then, in your
                application, create a flow for the source that will run on the agent, and deploy it to the agent's
                deployment group.
            </StriimTypography>
            <StriimTypography variant="body4">
                To Download the Striim Forwarding Agent, click <Link href={downloadURL}> here</Link>
            </StriimTypography>
        </div>
    );
};

export default DownloadAgentModalContent;
