import { useEffect, useState } from "react";

export const useTemplateGroup = (templates = [], expandAllDefaultState = false) => {
    const [expandAll, setExpandAll] = useState(expandAllDefaultState);
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    useEffect(() => {
        if (expandAll || expandAllDefaultState) {
            setFilteredTemplates(templates);
        } else {
            setFilteredTemplates(templates.slice(0, 4));
        }
    }, [expandAll, expandAllDefaultState, templates]);
    return {
        expandAll,
        setExpandAll,
        filteredTemplates
    };
};
