import App from "app";
import NestedTypes from "backbone.nestedtypes";
import factory from "core/services/appservice/query_manager/strategies/factory";
import PollingQueryDataLoader from "core/services/appservice/query_manager/PollingQueryDataLoader";
import queryService from "core/services/dataService/queryService";
import "core/data-types";
import "core/services/appservice/query_manager/models/datasource";

var module = App.module("QueryManager.Models", function (Models, App, Backbone) {
    Models.DataSourceModel = NestedTypes.Model.extend({
        defaults: {
            key: String,
            query: queryService.WAQueryModel,
            subscriberCount: 0,
            retentionStrategyType: String,
            categoryField: String,
            data: Array,
            totalSeries: 0,
            fieldList: Backbone.Collection,
            lastUpdated: 0,
            pollingEnabled: Boolean,
        },
        properties: {
            canPollQuery: function () {
                return this.query && this.query.canPoll;
            },
        },
        initialize: function () {
            this.retentionStrategy = factory.getStrategy(this.retentionStrategyType, this.categoryField);
            this.retentionStrategy.categoryField = this.categoryField;
            this.data = [];
            this.lastUsedHash = {};

            this.listenTo(this.query, "query:start", this.resetQueryListeners);
            if (this.query.instance_uuid !== "") {
                this.startListeningForQueryData();
            }
        },
        resetQueryListeners: function () {
            this.stopListeningForQueryData();
            this.startListeningForQueryData();
            this.tryStartQueryPolling();
        },
        startListeningForQueryData: function () {
            this.query.incrementSubscribers();
            this.data = [];
            this.lastUpdated = new Date().getTime();
            this.totalSeries = 0;

            this.fieldList = this.query.fields;
            this.listenTo(this.query, "incoming:data", this.handleIncomingData);
        },
        stopListeningForQueryData: function () {
            if (this.query) {
                this.stopListening(this.query, "incoming:data");
            }
            if (this._previousAttributes.query && this._previousAttributes.query.instance_uuid !== "") {
                this.query.decrementSubscribers();
                this.data = [];
                this.lastUpdated = new Date().getTime();
                this.totalSeries = 0;
            }
        },
        handleIncomingData: function (incomingData) {
            if (!this.query) {
                return;
            }
            this.data = this.retentionStrategy.add(
                incomingData,
                this.data,
                this.fieldList,
                this.categoryField,
                this.lastUsedHash,
                this.totalSeries
            );

            if (!this.canPollQuery) {
                this.data = this.retentionStrategy.evict(this.data, this.lastUsedHash, this.totalSeries);
            }
            this.lastUpdated = new Date().getTime();

            this.tryStartQueryPolling();
        },
        tryStartQueryPolling: function () {
            if (!this.pollingEnabled || this.pollingQueryDataLoader || !this.canPollQuery) {
                return;
            }

            this.pollingQueryDataLoader = new PollingQueryDataLoader(this.query);
            this.pollingQueryDataLoader.on(
                "onDataLoaded",
                function (newData) {
                    this._clearData();
                    this.data = this.retentionStrategy.add(
                        newData,
                        this.data,
                        this.fieldList,
                        this.categoryField,
                        this.lastUsedHash,
                        this.totalSeries
                    );
                    this.lastUpdated = new Date().getTime();
                }.bind(this)
            );

            this.pollingQueryDataLoader.start();
        },
        tryClearData: function (time) {
            if (time > this.lastUpdated) {
                this._clearData();
            }
        },
        stopQueryPolling: function () {
            if (this.pollingQueryDataLoader) {
                this.pollingQueryDataLoader.stop();
            }
        },
        incrementSubscribers: function () {
            this.subscriberCount++;
        },
        decrementSubscribers: function () {
            this.subscriberCount--;
            if (this.subscriberCount === 0) {
                this.stopListeningForQueryData(this.query.instance_uuid);
            }
        },
        _clearData: function () {
            this.data = [];
            this.lastUpdated = new Date().getTime();
        },
    });
});

export default module;
