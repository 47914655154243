import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { StriimInputField, StriimControlLabel, StriimRadioGroup, StriimRadio } from "@striim/striim-ui";
import { Box } from "@mui/material";
import { StreamOption } from "../../sentinel.types";
import { handleNameValidation } from "../../panel/sentinel-panel-container";

interface OutputStreamSelectorProps {
    streams: StreamOption[];
    streamOption: { label: string; value: string | number } | null;
    setStreamOption: Dispatch<SetStateAction<{ label: string; value: string | number } | null>>;
    isDisabled?: boolean;
    isValid?: boolean;
    validationError?: string;
    nsName: string;
    isNewSelected: boolean;
    setIsNewSelected: Dispatch<SetStateAction<boolean>>;
}
const errorMsgMap = {
    required: "Output stream is required",
    duplicate: "This name already exists",
    invalidFormat: "Please enter a name with only alphanumeric or underscore characters.",
    noSpaces: "Please enter a name without spaces.",
    startsWithNumber: "Name cannot start with a number"
};

const OutputStreamSelector: React.FC<OutputStreamSelectorProps> = ({
    streams = [],
    streamOption = null,
    setStreamOption = () => {},
    isDisabled = false,
    isValid = true,
    nsName,
    isNewSelected,
    setIsNewSelected
}) => {
    const [validationError, setValidationError] = useState<string | null>();

    const handleOptionChange = (_, value) => {
        setIsNewSelected(value === "new");
        setStreamOption(null);
    };

    const updateInputValue = input => {
        setStreamOption(input);
    };

    useEffect(() => {
        const setValidationMsg = async () => {
            const errMsg = await handleNameValidation(streamOption?.label, "STREAM", nsName);
            setValidationError(errMsg ? errorMsgMap[errMsg] : null);
        };
        setValidationMsg();
    }, [streamOption]);

    return (
        <Box sx={{ display: "grid", p: 2, backgroundColor: "greyscale.50" }}>
            <StriimRadioGroup
                sx={{ gap: 1 }}
                required
                row={false}
                onChange={handleOptionChange}
                name="outputStreamType"
                value={isNewSelected ? "new" : "existing"}
            >
                <StriimControlLabel value={"new"} label="New Output" control={<StriimRadio />} disabled={isDisabled} />
                {isNewSelected && (
                    <StriimInputField
                        name="NewOutputName"
                        onChange={name =>
                            updateInputValue({ label: name, value: name ? nsName + ".STREAM." + name : "" })
                        }
                        placeholder="Type the new stream name"
                        disabled={isDisabled}
                        error={!isValid}
                        helperText={validationError}
                    />
                )}
                <StriimControlLabel
                    value={"existing"}
                    label="Existing Output"
                    control={<StriimRadio />}
                    disabled={isDisabled}
                />
                {!isNewSelected && (
                    <StriimInputField
                        SelectProps={{ options: streams }}
                        select
                        value={streamOption}
                        onChange={updateInputValue}
                        required
                        disabled={isDisabled}
                        error={!isValid}
                        helperText={!isValid && "Choose a stream from the dropdown"}
                    />
                )}
            </StriimRadioGroup>
        </Box>
    );
};

export default OutputStreamSelector;
