import $ from "jquery";

var namespace = "click.eventsLogViewDropdownHide";

export default {
    //TODO: any better way to do that? clicked outside an open dropdown
    attach: function () {
        $(document).bind(namespace, function (evt) {
            var $target = $(evt.target);
            if ($target.closest(".filters").length === 0 && $target.closest(".ui-datepicker-header").length === 0) {
                $(".hide-dropdown").hide();
            }
        });
    },

    detach: function () {
        $(document).unbind(namespace);
    },
};
