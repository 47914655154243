import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";

import { StriimChip, StriimTypography, StriimTooltip } from "@striim/striim-ui";
import { useStylesItem, styles } from "./template-item.styles";
import classNames from "classnames";
import RecommendedIcon from "app/images/Recommended.svg";
import { Grid, Box, useTheme } from "@mui/material";
import ChevronRight from "../../../../images/Chevron_Right.svg";
import { isAppAdapter } from "../../../../../../../wizards/utils/checkAdapterType";

const ILCDCChip = ({ subtitle, url, isAutomatedILCDC, sourceAdapter }) => {
    const classes = useStylesItem(useTheme());
    let getChipText = function(text, isSchemaConversion) {
        if (text === "Start from scratch" || text === "Import TQL file") {
            return null;
        }
        if (isAutomatedILCDC) {
            return "Automated Pipeline";
        }
        if (text.indexOf("CDC") !== -1 || text.indexOf("MSJet") !== -1 || text.indexOf("OJet") !== -1) {
            return "CDC";
        }
        if (isAppAdapter(sourceAdapter)) {
            return "Automated Mode";
        }
        if (text.indexOf("Initial Load") !== -1) {
            return isSchemaConversion ? "IL (AUTO-SCHEMA CONVERSION)" : "IL";
        }
    };

    let isSchemaConversion = url && url?.indexOf("to-create-schema") !== -1;
    let text = getChipText(subtitle, isSchemaConversion);
    const chipClassName = useMemo(() => {
        if (isSchemaConversion && !isAutomatedILCDC) {
            return classNames(classes.chipRoot, "IL");
        }
        return classNames(classes.chipRoot, text?.replaceAll(" ", "_").replaceAll("->", "_"));
    });
    return (
        <Grid item container className={classes.chipContainer}>
            {text ? (
                <StriimChip
                    type="tag-chip"
                    hideAvatar
                    variant="default"
                    label={text}
                    classes={{
                        label: classNames(classes.chipLabel),
                        root: classes.chipRoot
                    }}
                />
            ) : (
                <></>
            )}
        </Grid>
    );
};
const getTooltip = (subtitle, isAutomatedILCDC, isCardDisabled) => {
    if (isCardDisabled) {
        return (
            <StriimTypography sx={styles.tooltip} variant="caption">
                Enabling the selected adapter requires additional license, please contact support@striim.com
            </StriimTypography>
        );
    }

    let idx = subtitle.indexOf(" to ");
    if (idx != -1) {
        let sourceTitle = subtitle.substring(0, idx);
        let targetTitle = subtitle.substring(idx + 3);
        if (isAutomatedILCDC) {
            return (
                <StriimTypography sx={styles.tooltip} variant="caption">
                    You can use this wizard to create a Striim application that syncs your existing {sourceTitle} data
                    to {targetTitle} and then automatically starts to capture your {sourceTitle} data and writes them to{" "}
                    {targetTitle}.<br />
                    The application creates the schemas and tables at the target before syncing any data
                </StriimTypography>
            );
        } else if (subtitle.indexOf("Initial Load") !== -1) {
            return (
                <StriimTypography sx={styles.tooltip} variant="caption">
                    You can use this wizard to create a Striim application to sync your existing {sourceTitle} data to
                    {targetTitle}.The application creates the schemas and tables at the target before syncing any data.
                </StriimTypography>
            );
        } else {
            return (
                <StriimTypography sx={styles.tooltip} variant="caption">
                    You can use this wizard to create a Striim application to capture your {sourceTitle} data and write
                    it to {targetTitle}.{" "}
                </StriimTypography>
            );
        }
    }
};
const getSubtitle = (subtitle, isAutomatedILCDC) => {
    let idx = subtitle.indexOf(" to ");
    if (idx != -1) {
        let sourceTitle = subtitle.substring(0, idx);
        let targetTitle = subtitle.substring(idx + 3).trim();
        sourceTitle = sourceTitle.replace("Initial Load", isAutomatedILCDC ? "" : "IL");
        if (targetTitle !== "Database") {
            targetTitle = targetTitle.replace("Database", "");
        }

        return (
            <Box>
                <Box style={styles.sourceTitle}>
                    <span>{sourceTitle}</span>
                    <ChevronRight style={styles.icon} /> <br />
                </Box>
                <Box style={styles.targetTitle}>{targetTitle}</Box>
            </Box>
        );
    }
    return subtitle;
};
export const TemplateItem = React.memo(
    ({ subtitle, content, onClick, url, isAutomatedILCDC, isCardDisabled, onHomePage = false, sourceAdapter }) => {
        const classes = useStylesItem(useTheme());
        const handleCardClick = () => {
            if (!isCardDisabled) {
                onClick();
            }
        };
        return (
            <StriimTooltip arrow title={getTooltip(subtitle, isAutomatedILCDC, isCardDisabled)}>
                <Card
                    style={{
                        width: onHomePage ? "250px" : "auto"
                    }}
                    className={isCardDisabled ? classes.disabledTemplate : classes.templateItem}
                    variant="outlined"
                    onClick={handleCardClick}
                    data-test-id={"template-item-entry"}
                    id="template-card"
                >
                    <CardContent className={classes.templateItemContent} id="template-item-content">
                        <Box className={classes.ChipBoxContainer} id="chip-container">
                            <RecommendedIcon style={{ opacity: isAutomatedILCDC ? "100%" : "0" }} />
                            <ILCDCChip
                                subtitle={subtitle}
                                url={url}
                                isAutomatedILCDC={isAutomatedILCDC}
                                sourceAdapter={sourceAdapter}
                            />
                        </Box>
                        <Grid container alignItems="center" justifyContent="center" className={classes.icons}>
                            {content}
                        </Grid>
                        <hr className={classes.separator} />
                        <Grid item container sx={styles.subtitleContainer} justifyContent="center">
                            <StriimTypography
                                align="center"
                                variant="body"
                                className={classes.subtitle}
                                id="template-item-subtitle"
                            >
                                {getSubtitle(subtitle, isAutomatedILCDC)}
                                {/*DEV-26429*/}
                            </StriimTypography>
                        </Grid>
                    </CardContent>
                </Card>
            </StriimTooltip>
        );
    }
);
TemplateItem.displayName = "TemplateItem";

TemplateItem.propTypes = {
    subtitle: PropTypes.node,
    content: PropTypes.node,
    onClick: PropTypes.func,
    url: PropTypes.string
};
