import * as Yup from "yup";
import metaStoreService from "core/services/metaStoreService/meta-store-service";

const targetNameValidation = Yup.string()
    .required("Please enter a target name")
    .test("noSpaces", "Please enter a target name without spaces.", haveNoSpacesValidation)
    .test(
        "onlyAlphanumeric",
        "Please enter a target name with only alphanumeric or underscore characters.",
        onlyAlphanumericValidation
    )
    .test("cannotStartWithaNumber", "Target name cannot start with a number", doesNotStartWithaNumberValidation);

const targetNameFullValidation = () =>
    targetNameValidation.test("nameAlreadyExistsAsync", "This target name already exists", async function(name) {
        return await nameAlreadyExistsValidation(name, "TARGET", this.parent);
    });

const getTargetNameValidation = () => {
    return targetNameFullValidation();
};

const serviceEndpointValidation = Yup.string().required("Please enter a service endpoint");

const portValidation = Yup.string()
    .required("Please enter a port")
    .test("onlyNumbers", "Please enter a valid port", containsOnlyNumbers);

const accessKeyValidation = Yup.string().required("Please enter an access key");

const dbMappingValidation = Yup.array().of(
    Yup.object().shape({
        sourceDatabase: Yup.string().required("Please enter a source database name"),
        targetDatabase: Yup.string().required("Please enter a target database name")
    })
);

const tableMappingValidation = Yup.array().of(
    Yup.object().shape({
        sourceTable: Yup.string().required("Please enter a source table name"),
        targetTable: Yup.string().required("Please enter a target table name")
    })
);

const cosmosCassandraTableMappingValidation = Yup.array().of(
    Yup.object().shape({
        sourceTable: Yup.string().required("Please enter a source table name"),
        targetTable: Yup.string()
            .test(
                "lowercaseValidation",
                "Please enter the target table name in lowercase",
                value => value === value?.toLowerCase()
            )
            .required("Please enter a target table name")
    })
);

const accountEndpointValidation = Yup.string().required("Please enter an account endpoint");

const accountKeyValidation = Yup.string().required("Please enter an account key");

const keySpaceValidation = Yup.string().required("Please enter a key space");

const hostValidation = Yup.string().required("Please enter a host");

const usernameValidation = Yup.string().required("Please enter a username");

const passwordValidation = Yup.string().required("Please enter a password");

export {
    getTargetNameValidation,
    serviceEndpointValidation,
    portValidation,
    accessKeyValidation,
    dbMappingValidation,
    tableMappingValidation,
    cosmosCassandraTableMappingValidation,
    accountEndpointValidation,
    accountKeyValidation,
    keySpaceValidation,
    hostValidation,
    usernameValidation,
    passwordValidation
};

function haveNoSpacesValidation(value) {
    return value && value.indexOf(" ") === -1;
}

function onlyAlphanumericValidation(value) {
    const nonAlphanumericOrUnderscore = /\W/;
    return !nonAlphanumericOrUnderscore.test(value);
}

function doesNotStartWithaNumberValidation(value) {
    return isNaN(parseInt(value));
}

function containsOnlyNumbers(value) {
    return !isNaN(value);
}

function nameAlreadyExistsValidation(name, entityType, parent) {
    if (!targetNameValidation.isValidSync(name)) {
        return false;
    }

    const nsName = parent.nsName;
    return new Promise(resolve => {
        metaStoreService
            .findById(nsName + "." + metaStoreService.entities[entityType] + "." + name)
            .then(item => {
                resolve(item ? false : true);
            })
            .fail(() => {
                resolve(false);
            });
    });
}
