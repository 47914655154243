import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import UIControl from "app/components/common/editor/control/ui-control";

var Transformation = {};

Transformation.OnlyDbSources = false;

Transformation.createColumnControl = function () {
    return UIControl.ColumnsWithFunctionPicker.extend({
        hideLabel: true,
        hidden: false,
        transformationConfig: {
            preFillColumns: false,
            addAvailable: true,
            autoFillAlias: true,
            functionsSource: getFunctionsListPerType,
            labels: {
                column2: "Function",
                column3: "Alias",
            },
        },
    }).create();
};

function getFunctionsListPerType(columnType) {
    return propertyTemplateService.functions;
}

Transformation.buildTql = function (tqlBuilder, columns) {
    columns.forEach(function (column) {
        if (!column.column) {
            return;
        }

        if (column.tqlFunction) {
            let f = propertyTemplateService.getMaskingFunctionById(column.tqlFunction);
            tqlBuilder.addColumn(column.column, f.maskingFunction, f.params, column.alias);
        } else {
            tqlBuilder.addColumn(column.column, null, null, column.alias);
        }
    });
};

export default Transformation;
