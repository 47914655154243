import App from "app";
import WALeafletMapMixin from "app/components/dashboard/visualization/charts/leafletmap/mixins/leafletmap-leafletmap";
import "app/components/dashboard/visualization/charts/chart";

App.module("Chart.LeafletMap", function (Chart, App, Backbone, Marionette, $, _) {
    Chart.View = App.Chart.Default.ChartView.extend({
        initialize: function () {
            _(this).extend(WALeafletMapMixin);
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
        },
    });
});
