export const styles = {
    statsBox: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: ({ spacing }) => spacing(2, 3),
        gap: 1,
        "&:not(:last-child)": {
            borderRight: ({ palette }) => `1px solid ${palette.greyscale[200]}`
        }
    },
    statLabel: {
        color: "greyscale.700"
    },
    statValue: {
        fontSize: "22px"
    },
    statsWrapper: {
        border: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    }
};
