import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    componentIconContainer: {
        width: '24px',
        height: '24px',
        top: '2px',
        position: 'relative'
    },
    componentIcon: {
        fontSize: '16px',
        padding: 0
    },
    dataTableWrapper: {
        "& .sourceCellClass": {
            display: "flex"
        }
    }
}));
