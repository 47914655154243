import React from "react";
import PropTypes from "prop-types";
import CheckIcon from "@material-ui/icons/Check";
import { typeAddonTile } from "../add-on-features/addon-tile";

import useStyles from "./plan-includes-list.styles";
import { addonFeaturesTitles } from "../add-on-features/add-on-features-utils";
import {StriimTypography} from "../StriimTypographyCustom";

const PlanIncludesList = ({ list }) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            {list.map(item => (
                <StriimTypography key={item.addonKey} variant="caption3" className={classes.item}>
                    <CheckIcon />
                    {addonFeaturesTitles[item.addonKey]}
                </StriimTypography>
            ))}
        </div>
    );
};

PlanIncludesList.prototype = {
    list: PropTypes.arrayOf(PropTypes.shape(typeAddonTile))
};

export default PlanIncludesList;
