import Backbone from "backbone";
import _ from "underscore";
import dateLib from "core/utils/date-utils";
import template from "./templates/lag-reporting-template.html";
import lagRouteTemplate from "./templates/lag-route-template.html";
import lagRouteGraphTemplate from "./templates/lag-route-graph-template.html";
import colorService from "core/services/appservice/colorService";
import stringUtils from "core/utils/string-utils";
import { tooltipStriimlineHighcharts } from "../../../../styles/materialize/components-striimline/tooltip.export";

var LagReportingModule = {};

LagReportingModule.LagRouteView = Backbone.Marionette.ItemView.extend({
    template: _.template(lagRouteTemplate),
    serializeData: function() {
        var data = this.model.toJSON();
        var firstEntry = data.report[0];
        var time = dateLib(firstEntry.timestamp).format("MMM D YYYY, h:mm:ss.SS a");
        data.firstComponentName = firstEntry.componentName;
        data.firstComponentType = firstEntry.componentType;
        data.time = time;
        return data;
    }
});

LagReportingModule.LagReportGraphView = Backbone.Marionette.ItemView.extend({
    template: _.template(lagRouteGraphTemplate),
    serializeData: function() {
        var data = this.model.toJSON();
        var firstEntry = data.report[0];
        var time = dateLib(firstEntry.timestamp).format("MMM D YYYY, h:mm:ss.SS a");
        data.firstComponentName = firstEntry.componentName;
        data.firstComponentType = firstEntry.componentType;
        data.time = time;
        return data;
    },
    onShow: function() {
        var categories = _.map(this.model.get("report"), function(val) {
            return val.componentName;
        });
        var max = 1;
        var latencies = _.map(this.model.get("report"), function(val) {
            if (parseFloat(val.latency) > max) {
                max = parseFloat(val.latency);
            }
            return parseFloat(val.latency);
        });

        var chartConfig = {
            chart: {
                type: "bar",
                backgroundColor: null,
                borderWidth: 0,
                style: {
                    overflow: "visible"
                }
            },
            title: {
                text: "Latency",
                style: {
                    color: colorService.theme.colors.text[0]
                }
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: categories,
                crosshair: false,
                style: {
                    color: colorService.theme.colors.text[1]
                },
                labels: {
                    style: {
                        color: colorService.theme.colors.text[0]
                    }
                }
            },
            yAxis: {
                title: {
                    text: "Latency (ms)",
                    style: {
                        color: colorService.theme.colors.text[0]
                    }
                },
                min: 0,
                max: max,
                labels: {
                    style: {
                        color: colorService.theme.colors.text[0]
                    }
                }
            },
            tooltip: {
                enabled: true,
                hideDelay: 0,
                ...tooltipStriimlineHighcharts
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                },
                series: {
                    animation: false,
                    lineWidth: 1,
                    shadow: false,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    marker: {
                        radius: 1,
                        states: {
                            hover: {
                                radius: 2
                            }
                        }
                    },
                    fillOpacity: 0.25
                }
            },
            series: [
                {
                    name: "Latency",
                    data: latencies,
                    showInLegend: false
                }
            ]
        };
        this.$el.find(".highcharts-container").highcharts(chartConfig);
    }
});

LagReportingModule.LagReportAbstractCollectionView = Backbone.Marionette.CollectionView.extend({
    emptyView: Backbone.Marionette.ItemView.extend({
        template: _.template(
            '<div style="width:100%; text-align: center" class="p-24px ta-c well"> No latency report found for this application.</div>'
        )
    }),
    initialize: function(options) {
        this.model = options.model;
        this.collection = new Backbone.Collection();
        this.createCollection();
    },
    createCollection: function() {
        var lagReport;
        var that = this;
        lagReport = this.model.get("lagReport");
        lagReport = this._convertIntoArrayIfNeeded(lagReport);

        _.each(lagReport, function(data) {
            if (typeof data === "string") {
                data = JSON.parse(data);
            }
            if (!_.isArray(data)) {
                data = [data];
            }
            data.forEach(item => {
                item.server = that._getServerName(item);
                that.collection.add(item);
            });
        });
    },
    _convertIntoArrayIfNeeded: function(json) {
        //When there is only single element -> backend sends Object instead of Array.
        if (json && Array.isArray(json) === false) {
            return [json];
        }

        return json;
    },
    _getServerName(data) {
        if (data && data.report && data.report.length && data.report.length > 0) {
            return data.report[0]["serverName"] || "";
        }
        return "";
    }
});

LagReportingModule.LagReportGraphCollectionView = LagReportingModule.LagReportAbstractCollectionView.extend({
    childView: LagReportingModule.LagReportGraphView
});

LagReportingModule.LagReportCollectionView = LagReportingModule.LagReportAbstractCollectionView.extend({
    childView: LagReportingModule.LagRouteView
});

LagReportingModule.View = Backbone.Marionette.LayoutView.extend({
    className: "lag-reporting-container",
    chartType: "chart",
    regions: {
        tableCollectionRegion: "#tableView",
        chartCollectionRegion: "#chartView"
    },
    modelEvents: {
        change: "renderViews"
    },

    template: _.template(template),
    initialize: function(options) {
        this.model = options.model;
    },
    onRender: function() {
        var that = this;
        this.$el.find(".graph-view-button").click(function() {
            that.$el.find(".chart-view").show();
            that.$el.find(".graph-view-button").removeClass("secondary");

            that.$el.find(".table-view").hide();
            that.$el.find(".table-view-button").addClass("secondary");
        });

        this.$el.find(".table-view-button").click(function() {
            that.$el.find(".table-view").show();
            that.$el.find(".table-view-button").removeClass("secondary");

            that.$el.find(".chart-view").hide();
            that.$el.find(".graph-view-button").addClass("secondary");
        });
    },

    _dataChanged: function() {
        if (!this.model || !this.model.get("lagReport")) {
            return true;
        }

        const lastDataHash = stringUtils.calculateHash(JSON.stringify(this.model.get("lagReport")));
        if (!this._lastDataHash) {
            this._lastDataHash = lastDataHash;
            return true;
        }

        const result = lastDataHash !== this._lastDataHash;
        this._lastDataHash = lastDataHash;
        return result;
    },

    renderViews: function() {
        if (!this._dataChanged()) {
            return;
        }

        this.chartCollectionRegion.empty();
        this.chartCollectionRegion.show(
            new LagReportingModule.LagReportGraphCollectionView({
                model: this.model
            })
        );

        this.tableCollectionRegion.empty();
        this.tableCollectionRegion.show(
            new LagReportingModule.LagReportCollectionView({
                model: this.model
            })
        );
    }
});

export default LagReportingModule;
