import { ItemView, CompositeView } from "marionette";
import React from "react";
import ReactDOM from "react-dom/client";
import api from "core/api/api";
import layoutTemplate from "./templates/progressLayoutTemplate.html";
import progressTemplate from "./templates/progressTemplate.html";
import layoutTemplateLite from "./templates/progressLayoutTemplateLite.html";
import progressEntryTemplate from "./templates/progresslayoutTableEntry.html";
import Resources from "app/components/common/editor/resources_en.json";
import "highcharts";
import $ from "jquery";
import _ from "underscore";
import dateLib from "core/utils/date-utils";
import { template, ui, regions, childView, childViewContainer, tagName, emptyView, className } from "core/decorators";
import App from "app";
import masterDetailView from "app/components/common/master-detail-view/master-detail-view";
import Backbone from "backbone";
const INTERVAL = 5000;
import utils from "core/utils";
import numeral from "numeral";
import { AppStatusChip } from "src/modules/apps/pages/app-list/components/app-tile/components/app-status-indicators/app-status-indicators.jsx";
import Tracker from "core/services/tracker/tracker";
import TableProgressTable, { AdapterIOTimes } from "../../../../src/modules/table-progress/table-progress";
import tableProgressStore from "../../../../src/modules/table-progress/table-progress.store.jsx";
import { StriimTheme } from "@striim/striim-ui";
import { TYPES } from "../../../../core/services/monitorService";
import targetLagInformationDialog from "../../monitor/appdetails/appdata/tables/TargetLagInformationDialog";
import ModalManager from "../../common/modal/ModalManager";
import Dictionary from "app/components/common/helpable/online-help-dictionary";
import statusManagement from "../../../../src/status-management";
import appStatusSynchronizer from "core/appStatusSynchronizer";
import { initMaterialDesignDropdown } from "../../../../core/utils/material-design/material-design-dropdown";
import { tooltipStriimlineHighcharts } from "../../../styles/materialize/components-striimline/tooltip.export";
import { flowStriimline } from "../../../styles/materialize/components-striimline/routes/flow";
import Dropdown from "app/components/common/dropdown";
import menu from "app/components/common/menu/menu";
import menuOption from "app/components/common/menu/menu-option";
import StackedLinearProgress from "../../../../src/modules/table-progress/Components/stacked-linear-progress/stacked-linear-progress";
import TableProgressModal from "../../../../src/modules/table-progress/table-progress-modal/table-progress-modal";
import { TRACKER_STRINGS } from "../../../../core/services/tracker/constants";

let executeOnce = false;

/**
 * Utility method that converts an object into a readable HTML string
 * @param obj
 * @returns {string}
 */
// const getStatsAsHTML = function(obj) {
//     let metrics = [];
//     _.each(obj, function(value, name) {
//         name = Resources[name] ? Resources[name]["name"] : name;
//         metrics.push(" <b> " + name + "</b>: " + value + " ");
//     });
//     return metrics.join(",");
// };
//
// const isIL = function(model) {
//     if (model && model.get("adapter") && model.get("adapter").handler) {
//         return moduleModel.get("adaptersWithTableMonitoring")[model.get("adapter").handler] === "IL";
//     }
//     return false;
// };
//
// const isCDC = function(model) {
//     if (model && model.get("adapter") && model.get("adapter").handler) {
//         return moduleModel.get("adaptersWithTableMonitoring")[model.get("adapter").handler] === "CDC";
//     }
//     return false;
// };

/**
 *
 * @type Model
 */
let moduleModel = null;
let sourceCount = {};

const modalManager = new ModalManager({
    container: "body"
});

/**
 * Main model for this module .. stores the objects and stats
 */
class Model extends Backbone.Model {
    defaults() {
        return {
            id: null,
            subscribers: 0,
            appComponents: new Backbone.Collection(),
            leeStats: new Backbone.Collection(),
            stats: new Backbone.Model(),
            vent: new Backbone.Model()
        };
    }

    initialize() {
        this.set("subscribers", 1);
        this.pullLatestMetrics();
    }
    pullLatestMetrics() {
        this.apiTimer = setInterval(() => {
            if (this.get("subscribers") > 0) {
                this.getStats();
            } else {
                console.log("!!!!! Self destructing timer since no subscribers are available.");
                clearInterval(this.apiTimer);
                this.clear();
            }
        }, INTERVAL);
        this.getStats();
        // this.setRefreshTimer();
        executeOnce = false;
    }
    // setRefreshTimer() {
    //     this.refreshTime = 6;
    //     if (this.lastRefreshTimer) {
    //         clearInterval(this.lastRefreshTimer);
    //     }
    //     this.lastRefreshTimer = setInterval(() => {
    //         this.refreshTime -= 1;
    //         if (this.refreshTime == 0) {
    //             this.refreshTime = 5;
    //         }
    //         $("#last-refresh-time").text(this.refreshTime);
    //     }, 1000);
    // }

    /**
     * This method gets called every 5 seconds. We have to fetch the latest API stats
     */
    getStats() {
        // this.refreshTime = 6;
        api.getMonitoringStatsForApp(this.get("id"), TYPES.FULL).always(stats => {
            this.setLatestSourceCount(stats);
            this.set("stats", stats);
        });
    }

    setLatestSourceCount(stats) {
        try {
            let maxLee = -99999;
            _.each(stats, statx => {
                if (statx?.type === "TARGET") {
                    let lee = statx["lag-end2end"]?.data;
                    let sources = [];
                    _.each(lee, entry => {
                        if (entry.lee > maxLee) {
                            maxLee = entry.lee;
                        }
                        sources.push(entry.source);
                    });
                    if (window._fakeLeeProgress) {
                        lee = {
                            "ns-name": "admin",
                            name: "test"
                        };
                        sources = [];
                    }
                    if (lee) {
                        this.get("leeStats").add(
                            {
                                id: statx["ns-name"] + "." + statx["name"],
                                label: statx["ns-name"] + "." + statx["name"],
                                sources: sources,
                                allValues: statx
                            },
                            { merge: true }
                        );
                    }
                }
            });

            let that = this;

            if ($("#leedropdown li").length === 1 && this.get("leeStats").length > 0) {
                this.get("leeStats").forEach(model => {
                    $("#leedropdown").append(
                        `<li  style="padding: 12px 16px" class="open-lee-modal"
                            data-target-id="${model.get("id")}" title="${model.get("id")}"
                            >
                            ${model.get("id")}
                        </li>`
                    );
                });
                $(".open-lee-modal").click(function() {
                    let target = $(this).attr("data-target-id");
                    let model = that.get("leeStats").get(target);
                    targetLagInformationDialog(modalManager, model, model.get("sources"));
                });
                $("#lee-show-button").show();
                $(".stat-throughput")
                    .removeClass("col-xs-4")
                    .addClass("col-xs-2");
                $(".stat-lee").show();
                $("#val-lee").html(maxLee);
            }
        } catch (e) {
            console.error(e);
        }
    }
}

/**
 * Main View, the only one that's exported to the main view
 */
@regions({
    masterDetailContainer: ".sources-and-targets-container"
})
@ui({
    inputRate: "#val-input-rate",
    outputRate: "#val-output-rate",
    outputTotal: "#val-output",
    inputTotal: "#val-input",
    inputRateContainer: "#input-rate-container",
    outputRateContainer: "#output-rate-container",
    totalDiscarded: "#val-discard",
    progressBarsContainer: ".initial-load-progress-bars",
    automatedIlProgressBar: "#automated-il-progress",
    outputRateUnitContainer: "#output-rate-unit",
    inputRateUnitContainer: "#input-rate-unit"
})
@template(layoutTemplate)
class ProgressView extends App.FormMixinDialog.View {
    initialize(options) {
        options.classes = "large";
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
        if (moduleModel === null || moduleModel.get("id") !== this.model.app.get("id")) {
            moduleModel = new Model({ id: this.model.app.get("id") });
        } else {
            let count = moduleModel.get("subscribers");
            count++;
            moduleModel.set("subscribers", count);
        }
        this.sparkLineID = "spark-line-app-throughput";
    }

    onRender() {
        (async () => {
            try {
                const sources = await this.model.app.getApplicationComponentsByTypes(
                    ["SOURCE", "EXTERNALSOURCE"],
                    true
                );
                const sourceIds = sources.map(sourceAdapter => sourceAdapter.get("id"));
                const promises = [
                    this.model.app.getApplicationComponentsByType("TARGET", true),
                    api.getAdaptersWithTableMonitoring(sourceIds)
                ];
                const [targets, sourcesWithTableMonitoring] = await Promise.all(promises);
                this.checkIfInitialLoadApp(sources, targets, sourcesWithTableMonitoring);
                this.setMasterDetailLayout();
            } catch (error) {
                console.log(error);
            }
            this.listenTo(moduleModel, "change:stats", () => {
                this.renderStats();
            });
            this.renderStats();
            this.$el.find("#refresh-button").click(() => {
                moduleModel.getStats();
            });
        })();
    }

    onDestroy() {
        let count = moduleModel.get("subscribers");
        count--;
        moduleModel.set("subscribers", count);
    }
    onBeforeDestroy() {
        //remove the react table progress on component destroy
        if (this.reactTableProgressView) {
            this.reactTableProgressView.unmount();
        }
    }

    /**
     *
     * @param sources
     * @param targets
     * @param adaptersWithTableMonitoring
     */
    checkIfInitialLoadApp(sources, targets, sourcesWithTableMonitoring) {
        const sourcesWithDBReader = [];
        sources.each(source => {
            if (sourcesWithTableMonitoring[source.get("id")] === "IL") {
                sourcesWithDBReader.push(source);
            }
        });
        if (sourcesWithDBReader.length >= 1) {
            moduleModel.get("appComponents").add(sources.toArray());
            moduleModel.get("appComponents").add(targets.toArray());
            this.$(".sources-and-targets-title").show();
        }
    }

    /**
     * Shows the masterDetailLayout..
     */
    setMasterDetailLayout() {
        setTimeout(() => {
            if (this.isLite !== true) {
                try {
                    const destroyModal = () => this.trigger("form:submit");
                    if (!this.reactTableProgressView) {
                        this.reactTableProgressView = ReactDOM.createRoot(this.$el.find(".react-table-progress")[0]);
                    }
                    this.reactTableProgressView.render(
                        <StriimTheme preselector=".striim.materialize.light:not(#\20)">
                            <TableProgressModal destroyModal={destroyModal} appModel={this.model.app} />
                        </StriimTheme>
                    );
                } catch (error) {
                    console.log(error);
                }
            }
        }, 500);
    }

    /**
     * Once we get the latest stats, let's go ahead and render the newly fetched stats.
     */
    renderStats() {
        let stats = moduleModel.get("stats");
        let appStatus = appStatusSynchronizer.getStatus(moduleModel.get("id"));

        var appStats = stats[this.model.app.uuid];
        let totalDiscardedEvents = 0;
        _.each(stats, stat => {
            if (stat.type === "TARGET") {
                totalDiscardedEvents += stat["most-recent-data"]["discarded-event-count"] || 0;
            }
        });
        this.ui.totalDiscarded.html(totalDiscardedEvents);

        if (appStats && appStats["most-recent-data"]) {
            this.renderSparkLine(
                appStats["most-recent-data"]["source-rate"],
                appStats["most-recent-data"]["target-rate"],
                appStats["most-recent-data"]["timestamp"],
                appStatus
            );
            this.renderAppStats(appStats["most-recent-data"], appStatus);
        }
    }

    /**
     * Render the parts below the sparkLine - the ones with appRates and stuff.
     * @param mostRecentData
     * @param appStatus
     */
    renderAppStats(mostRecentData, appStatus) {
        let totalOP;
        if (
            (mostRecentData["target-acked"] !== null || mostRecentData["target-acked"] !== undefined) &&
            mostRecentData["target-acked"] >= 0
        ) {
            totalOP = mostRecentData["target-acked"];

            if (totalOP > 0 && !executeOnce) {
                Tracker.getInstance().track(TRACKER_STRINGS.schema.targetAcked, {
                    event: TRACKER_STRINGS.eventLabel.targetAcked,
                    targetAcked: totalOP
                });
                executeOnce = true;
            }
        } else {
            totalOP = mostRecentData["target-output"];
        }

        let totalIP = mostRecentData["source-input"];
        if (statusManagement.isValidStateToShowAdapterRate(appStatus)) {
            this.ui.inputRate.html(numeral(mostRecentData["source-rate"]).format("0.[00] a"));
            this.ui.outputRate.html(numeral(mostRecentData["target-rate"]).format("0.[00] a"));
            this.ui.outputRateUnitContainer.show();
            this.ui.inputRateUnitContainer.show();
        } else {
            this.ui.inputRate.html("-");
            this.ui.outputRate.html("-");
            this.ui.outputRateUnitContainer.hide();
            this.ui.inputRateUnitContainer.hide();
        }

        this.ui.inputTotal.html(numeral(totalIP).format("0.[0000] a"));
        this.ui.outputTotal.html(numeral(totalOP).format("0.[0000] a"));

        if (statusManagement.isApplicationCrash(appStatus)) {
            this.ui.inputRateContainer.addClass("red-color");
            this.ui.outputRateContainer.addClass("red-color");
            this.ui.inputTotal.addClass("grey-color");
            this.ui.outputTotal.addClass("grey-color");
        } else {
            this.ui.inputRateContainer.removeClass("red-color");
            this.ui.outputRateContainer.removeClass("red-color");
            this.ui.inputTotal.removeClass("grey-color");
            this.ui.outputTotal.removeClass("grey-color");
        }
    }

    /**
     * High charts: Load the application progress bar
     * @param inRate
     * @param outRate
     * @param time
     * @param appStatus
     */
    renderSparkLine(inRate, outRate, time, appStatus) {
        try {
            let appCrash = statusManagement.isApplicationCrash(appStatus);
            let inColor = appCrash ? flowStriimline.lineCrashColor : flowStriimline.lineInColor;
            let outColor = appCrash ? flowStriimline.lineCrashColor : flowStriimline.lineOutColor;
            let outDashStyle = appCrash ? "dash" : "solid";
            const inTime = time;
            const outTime = time + 200;
            outRate = outRate || 0;
            inRate = inRate || 0;
            const prevValue = [inTime - 1000, 0];
            if (this.chart) {
                this.chart.series[0].addPoint([inTime, inRate]);
                this.chart.series[1].addPoint([outTime, outRate]);

                if (this.chart.series[0].data.length > 30) {
                    this.chart.series[0].removePoint(0, false);
                }
                if (this.chart.series[1].data.length > 30) {
                    this.chart.series[1].removePoint(0, false);
                }
                this.chart.series[0].update({
                    color: inColor
                });
                this.chart.series[1].update({
                    color: outColor,
                    dashStyle: appCrash ? "dash" : "solid"
                });

                this.chart.reflow();
            } else {
                if ($("#" + this.sparkLineID).length === 0) {
                    return;
                }
                Highcharts.setOptions({
                    global: {
                        useUTC: false
                    }
                });
                this.chart = new Highcharts.Chart({
                    chart: {
                        renderTo: this.sparkLineID,
                        backgroundColor: "transparent",
                        animation: false
                    },
                    title: {
                        text: ""
                    },
                    credits: {
                        enabled: false
                    },
                    xAxis: {
                        labels: {
                            enabled: !this.isLite
                        },
                        type: "datetime"
                    },
                    yAxis: {
                        maxPadding: 0,
                        minPadding: 0,
                        gridLineWidth: 1,
                        endOnTick: false,
                        min: 0,
                        labels: {
                            enabled: true
                        },
                        title: {
                            text: "",
                            style: {
                                fontWeight: 900
                            }
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        enabled: true,
                        pointFormat: "",
                        headerFormat: "",
                        pointFormatter: function() {
                            return `${this.series.name}: ${numeral(this.y ?? 0).format(
                                "0.[00] a"
                            )} records/s <br /> at ${dateLib(this.x).format("h:mm:ss a ")}`;
                        },
                        ...tooltipStriimlineHighcharts
                    },
                    plotOptions: {
                        series: {
                            enableMouseTracking: true,
                            lineWidth: 4,
                            shadow: false,
                            states: {
                                hover: {
                                    lineWidth: 6
                                }
                            },
                            marker: {
                                enabled: false,
                                radius: 0,
                                states: {
                                    hover: {
                                        radius: 2
                                    }
                                }
                            }
                        }
                    },
                    series: [
                        {
                            type: "line",
                            color: inColor,
                            name: "In",
                            data: [prevValue, [inTime, inRate]]
                        },
                        {
                            type: "line",
                            color: outColor,
                            dashStyle: outDashStyle,
                            name: "Out",
                            data: [prevValue, [outTime, outRate]]
                        }
                    ]
                });
            }
        } catch (e) {
            console.log(e);
        }
    }
}

@template(layoutTemplateLite)
@className("")
class ProgressViewLite extends ProgressView {
    initialize() {
        this.isLite = true;
        this.sparkLineID = "spark-line-app-throughput-lite";
        if (moduleModel === null || moduleModel.get("id") !== this.model.app.get("id")) {
            moduleModel = new Model({ id: this.model.app.get("id") });
        } else {
            let count = moduleModel.get("subscribers");
            count++;
            moduleModel.set("subscribers", count);
        }
        this.model.set("link", Dictionary.get()["DISCARDED_EVENTS"].href);
    }

    toggleElementsEnabled(appStatus, refreshExceptionsCount) {
        this.modificationsEnabled = statusManagement.isApplicationCreated(appStatus);
        this.$el.toggleClass("toolbar-enabled", this.modificationsEnabled);

        if (typeof refreshExceptionsCount === "undefined") {
            refreshExceptionsCount = true;
        }
        if (refreshExceptionsCount && statusManagement.isApplicationCrash(appStatus)) {
            setTimeout(() => {
                this._updateExceptionIcon().finally(result => {
                    if (!result) {
                        setTimeout(() => {
                            this._updateExceptionIcon();
                        }, 3000);
                    }
                });
            }, 3000);
        }
    }

    onShow() {
        var _this = this;

        this.$el.find("#app-progress-show-button").click(() => {
            App.vent.trigger("show:table:progress:view");
        });

        this.$el.find(".tooltip").tooltipster({
            position: "bottom",
            offsetY: -5,
            contentAsHTML: true,
            functionReady: function(_origin, _tooltip) {
                _this.actionConfigurationMenu.hide();
            }
        });

        setTimeout(() => {
            let elems = document.querySelectorAll("#lee-show-button");
            elems.forEach(function(el) {
                initMaterialDesignDropdown(el);
            });
        }, 100);

        var menuModel = new menu.Model({
            options: _this.actions
        });

        var menuView = new menu.View({
            model: menuModel
        });

        _this.listenTo(menuView, "option:click", function(optionId) {
            if (this.actionsEnabled === false) {
                return false;
            }

            menuView.trigger("dropdown:hide");
            var menuAction = _this["execute_" + optionId + "Action"];
            if (menuAction) {
                menuAction.bind(this)();
            } else {
                alert(optionId);
            }
        });

        this.actionConfigurationMenu = new Dropdown({
            parentView: _this,
            content: menuView,
            trigger: _this.ui.actionConfiguration,
            position: "bottom",
            classes: "app-configuration-dropdown",
            on_show: function(tooltip) {
                //
                // set droppdown top baseline
                // and set arrow color - we need to use additional span element
                //
                var arrowBorderSpan = tooltip.find(".tooltipster-arrow-bottom>span");
                arrowBorderSpan.addClass("tooltipster-border");
                arrowBorderSpan.removeAttr("style");

                var arrowSpan = arrowBorderSpan.clone();
                arrowSpan.addClass("background");
                arrowSpan.removeAttr("style");

                tooltip.find(".tooltipster-arrow-bottom").append(arrowSpan);
                _this.ui.configurationActionTooltip.tooltipster("hide");
            }
        });

        this.toggleElementsEnabled(
            this.model.app.metaInfoStatus.isValid ? this.model.app.flowStatus : FlowStatus.INVALID,
            false
        );
    }

    destroy() {
        Backbone.Marionette.LayoutView.prototype.destroy.apply(this, arguments);
    }

    checkIfInitialLoadApp(sources, targets, sourcesWithTableMonitoring) {
        const sourcesWithDBReader = [];
        sources.each(source => {
            if (sourcesWithTableMonitoring[source.get("id")]) {
                sourcesWithDBReader.push(source);
            }
        });
        if (sourcesWithDBReader.length >= 1) {
            this.$el.find("#app-progress-show-button").show();
        }
    }
}
export default {
    FullView: ProgressView,
    LiteView: ProgressViewLite
};
