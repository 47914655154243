import _ from "underscore";
import BaseControl from "./../base-control";
import dateLib from "core/utils/date-utils";
import template from "./custom-date-range.html";
import $__app_components_common_editor_control_ui_control from "app/components/common/editor/control/ui-control";

function applyTime(date, timeString) {
    if (!date || !timeString) {
        return;
    }

    let time = dateLib(timeString, "HH:mm:ss");
    date = date.set("hour", time.hour()).set("minute", time.minute());
    return date;
}

export default function(predefinedOptions) {
    let defaultPredefinedOption = predefinedOptions[0];

    let Model = BaseControl.Model.extend({
        defaults: {
            value: null,
            predefinedOptions: predefinedOptions
        }
    });

    let View = BaseControl.View.extend({
        template: _.template(template),
        className: "date-range-picker",

        regions: {
            startDateRegion: ".startDate",
            startTimeRegion: ".startTime",
            endTimeRegion: ".endTime",
            endDateRegion: ".endDate"
        },

        initialize: function() {
            this._UIControl = $__app_components_common_editor_control_ui_control;

            BaseControl.View.prototype.initialize.apply(this, arguments);
        },

        getViewValue: function() {
            let from = this.startDate.getValue();
            let to = this.endDate.getValue();
            from = applyTime(from, this.startTime.getValue());
            to = applyTime(to, this.endTime.getValue());

            return {
                from: from,
                to: to
            };
        },

        setViewValue: function() {},

        onRender: function() {
            let from = defaultPredefinedOption.from();
            let to = defaultPredefinedOption.to();
            this.startDate = this._UIControl.DatePicker.extend({
                minDate: -1,
                maxDate: "0"
            }).create();
            this.startDateRegion.show(this.startDate);
            this.startDate.setValue(from);

            this.startTime = this._UIControl.TimePicker();
            this.startTimeRegion.show(this.startTime);
            this.startTime.setValue(from);

            this.endTime = this._UIControl.TimePicker();
            this.endTimeRegion.show(this.endTime);
            this.endTime.setValue(to);

            this.endDate = this._UIControl.DatePicker.extend({
                minDate: -1,
                maxDate: "0"
            }).create();
            this.endDateRegion.show(this.endDate);
            this.endDate.setValue(to);

            BaseControl.View.prototype.onRender.apply(this, arguments);
        }
    });

    return _.extend({}, BaseControl, {
        Model: Model,
        View: View
    });
}
