export default {
    PropertyNames: {
        StorageProvider: "storageProvider",
        Frequency: "frequency",
    },
    StorageProviders: {
        InMemory: "inmemory",
        ElasticSearch: "elasticsearch",
        JDBC: "jdbc",
    },
};
