import _ from "underscore";
import metaobjectConverter from "core/services/metaStoreService/metaobject-converter";

// Go through nodes array n and compute information for a basic, unoptimized layered graph
export default function (nodes, alternativeAlgorithmUpToBottom) {
    // Nodes with no incoming edges
    var root_nodes = _.filter(nodes, function (node, id) {
        return nodes[id].edges_in.length === 0;
    });

    function sortComponentsWithSameOrder(row, nodes) {
        // components on same level should have unique sort order
        // The order should based on index of parent and max node depth
        if (!row || row.length === 0) {
            return row;
        }

        // sort all nodes. The indexOf parent node will be used to set unique order
        var nodes_by_order = _.sortBy(nodes, function (node) {
            return node.order;
        });

        // when nodes have same parent then indexOf node sorted by index will be used
        var nodes_by_name = _.sortBy(nodes, function (node) {
            return metaobjectConverter.getName(node.id);
        });

        // set node order to high number.
        _(row).each(function (node) {
            node.order = node.order === Number.POSITIVE_INFINITY ? 1000000 : (node.order + 1) * 10000;
        });

        // group nodes by same order
        var sameOrderRowGroups = _(row).groupBy(function (node) {
            return node.order;
        });

        row = [];
        _(sameOrderRowGroups).each(function (sameOrderRows) {
            if (sameOrderRows.length <= 1) {
                row.push(sameOrderRows[0]);
                return;
            }

            // sort components on one level with same sort order
            // based on parent index
            var innerSort = _(sameOrderRows).sortBy(function (node) {
                if (node.edges_in.length === 0) {
                    return 0;
                }
                var parentNode = _.find(nodes_by_order, function (n) {
                    return n.id === node.edges_in[0];
                });
                node.parentIndex = nodes_by_order.indexOf(parentNode);
                // parent index has higher priority
                // then number of nodes
                // when those two are equal, then nodes should be displayed by name
                return node.parentIndex * 1000000 + -node.maxDepth * 1000 + nodes_by_name.indexOf(node);
            });

            //if all parentIndex equals then return

            // set unique sort oder
            innerSort.forEach(function (node, index) {
                node.order = node.order + index;
            });
            Array.prototype.push.apply(row, innerSort);
        });
        return row;
    }

    // Compute each node's downward weight. (How many nodes are reachable from this node)

    var max_depth;
    var compute_weight = function (id) {
        var node = nodes[id];
        var weight = 1;
        if (nodes[id].edges_out.length > 0) {
            _.each(nodes[id].edges_out, function (outgoing_id) {
                weight = weight + compute_weight(outgoing_id);
            });
        }
        node.weight = weight - 1;
        return weight;
    };
    _.each(root_nodes, function (node) {
        compute_weight(node.id);
    });

    // Compute each node's depth. (The furthest distance between this node and a root node)
    var compute_depth = function (id, depth) {
        var node = nodes[id];
        node.depth = _.has(node, "depth")
            ? _.max([node.depth, depth])
            : nodes[id].edges_out.length === 0 && nodes[id].edges_in.length === 0
            ? Number.POSITIVE_INFINITY
            : depth;

        if (_.isFinite(node.depth)) {
            max_depth = _.max([max_depth, node.depth]);
        }

        if (nodes[id].edges_out.length > 0) {
            _.each(nodes[id].edges_out, function (outgoing_id) {
                return compute_depth(outgoing_id, depth + 1);
            });
        }
    };
    _.each(root_nodes, function (node) {
        compute_depth(node.id, 0);
    });

    // Assign each node to an initial layer, based on the node depth. Orphan nodes, whose depth is infinity, get assigned the layer one below the largest (that is, at the bottom)
    _.each(nodes, function (node) {
        node.layer = !max_depth ? 0 : !_.isFinite(node.depth) ? max_depth + 1 : node.depth;
    });

    var nodes_by_depth;

    if (alternativeAlgorithmUpToBottom) {
        nodes_by_depth = _.sortBy(nodes, function (node) {
            return node.depth;
        });
        _.each(nodes_by_depth, function (node) {
            node.layer = nodes[node.id].edges_in.length === 0 ? 0 : nodes[nodes[node.id].edges_in[0]].layer + 1;
        });
    } else {
        nodes_by_depth = _.sortBy(nodes, function (node) {
            return node.depth;
        });
        nodes_by_depth.reverse();
        _.each(nodes_by_depth, function (node) {
            // Find the child with the smallest level (closest to top).
            var min_child_layer, min_child;
            _.each(nodes[node.id].edges_out, function (outgoing_id) {
                if (!min_child_layer || nodes[outgoing_id].layer <= min_child_layer) {
                    min_child = nodes[outgoing_id];
                    min_child_layer = nodes[outgoing_id].layer;
                }
            });

            if (min_child) {
                node.layer = _.max([min_child_layer - 1, node.layer]); // push parent nodes down to one level above the level of the smallest-level child
            }
        });
    }

    // Assign initial coordinates
    // "Weight" the nodes to determine horizontal order
    _(nodes).each(function (node) {
        // lightest-weight or root nodes will be further left
        node.order = node.weight === 0 ? Number.POSITIVE_INFINITY : node.depth === 0 ? 0 : node.depth * node.weight;
    });

    // Organize the nodes into layers (rows), sorting each row by the node order
    var layers = _(nodes).groupBy(function (node) {
        return node.layer;
    });
    _(layers).each(function (row, r) {
        row = sortComponentsWithSameOrder(row, nodes);

        row = _(row).sortBy(function (node) {
            return node.order;
        });
        _.each(row, function (node, i) {
            node.coords = [i, node.layer];
        });
        layers[r] = row;
    });

    return nodes;
}
