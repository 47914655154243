import metaStoreService from "core/services/metaStoreService/meta-store-service";
import Application from "app/components/common/editor/meta-object-editors/application";
import Cq from "app/components/common/editor/meta-object-editors/cq";
import Source from "app/components/common/editor/meta-object-editors/source";
import ExternalSource from "app/components/common/editor/meta-object-editors/externalsource";
import Cache from "app/components/common/editor/meta-object-editors/cache";
import EventTable from "app/components/common/editor/meta-object-editors/eventTable";
import Stream from "app/components/common/editor/meta-object-editors/stream";
import Target from "app/components/common/editor/meta-object-editors/target";
import Type from "app/components/common/editor/meta-object-editors/type";
import Window from "app/components/common/editor/meta-object-editors/window";
import Router from "app/components/common/editor/meta-object-editors/router";
import Wactionstore from "app/components/common/editor/meta-object-editors/wactionstore";
import ProcessComponent from "app/components/common/editor/meta-object-editors/processComponent";
import Group from "app/components/common/editor/meta-object-editors/group";
import ExternalCache from "app/components/common/editor/meta-object-editors/externalcache";

var module = {};

module.createForMetadataObject = function (metadata_object, options) {
    options = options || {};
    var type = metadata_object.get("type");
    var subtype = metadata_object.get("isEventTable") ? "EventTable" : "";

    var editor = module.getEditor(type, subtype);

    if (metadata_object.get("isEditable") === false) {
        options.autoSave = false;
        options.readOnly = true;
    }

    var editor_instance = new editor(metadata_object, options);

    try {
        // Can throw error when click same node many times (often) to open details
        return editor_instance.create();
    } catch (e) {
        console.error(e);
        return null
    }
};

module.getEditor = function (type, subtype) {
    switch (type) {
        case metaStoreService.entities.APPLICATION:
            return Application;
        case metaStoreService.entities.CQ:
            return Cq;
        case metaStoreService.entities.SOURCE:
            return Source;
        case metaStoreService.entities.EXTERNALSOURCE:
            return ExternalSource;
        case metaStoreService.entities.CACHE:
            return (subtype || "").toLowerCase() === "eventtable" ? EventTable : Cache;
        case metaStoreService.entities.STREAM:
            return Stream;
        case metaStoreService.entities.TARGET:
            return Target;
        case metaStoreService.entities.TYPE:
            return Type;
        case metaStoreService.entities.WACTIONSTORE:
            return Wactionstore;
        case metaStoreService.entities.WINDOW:
            return Window;
        case metaStoreService.entities.OPENPROCESSOR:
            return ProcessComponent;
        case metaStoreService.entities.ROUTER:
            return Router;
        case metaStoreService.entities.GROUP:
            console.log("SHOWING GROUP EDITOR");
            return Group;
        case metaStoreService.entities.EXTERNALCACHE:
            return ExternalCache;
        default:
            throw new Error("Form for " + type + " has not been built yet.");
    }
};

export default {
    createForMetadataObject: module.createForMetadataObject,
};
