import App from "app";
import Dummy from "./dummy";
import All from "./all";
import AllCategory from "./all_with_category";
import Current from "./current";
import CurrentCategory from "./current_with_category";
import CurrentWithCategoryForValidationStats from "./validation_stats_strategy";

export default {
    getStrategy: function (strategy, categoryField) {
        var categoryFieldSet = categoryField ? true : false; // for nulls, false and undefined and empty strings
        if (strategy === App.QueryManager.RETENTION_STRATEGY_ALL && !categoryFieldSet) {
            return new All();
        }
        if (strategy === App.QueryManager.RETENTION_STRATEGY_ALL && categoryFieldSet) {
            return new AllCategory();
        }
        if (strategy === App.QueryManager.RETENTION_STRATEGY_CURRENT && !categoryFieldSet) {
            return new Current();
        }
        if (strategy === App.QueryManager.RETENTION_STRATEGY_CURRENT && categoryFieldSet) {
            if (categoryField && categoryField.indexOf("STRIIM_VALIDATION_STATS") !== -1) {
                return new CurrentWithCategoryForValidationStats();
            }
            return new CurrentCategory();
        }

        return new Dummy();
    },
};
