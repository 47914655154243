import App from "app";
import grid_helper_template from "./templates/grid-helper-template.html";
import "jqGrid";
App.module("GridHelper", function (GridHelper, App, Backbone, Marionette, $, _) {
    GridHelper.View = Backbone.Marionette.ItemView.extend({
        template: _.template(grid_helper_template),
        defaultGridSchema: {
            datatype: "local",
            autowidth: true,
            //autoheight: true,
            scrollOffset: 0,
            shrinkToFit: true,
            sortable: true,
            resizable: true,
            headertitles: true,
            ignoreCase: true,
            //rowList: [5, 10, 15, 20, 30, 50],
            show_lines: true,
            show_headers: true,
            gridComplete: function () {
                //checkboxformatter
                if ($(this).getGridParam().multiselect) {
                    $(this.grid.hDiv).find(".ui-jqgrid-htable").addClass("hasCheckbox");
                    $(this).addClass("hasCheckbox");
                    var $checkboxes = $(this).find("input.cbox").add($(this.grid.hDiv).find("input.cbox"));
                    _.each($checkboxes, function (checkbox) {
                        if (!$(checkbox).hasClass("styled")) {
                            $(checkbox).addClass("styled");
                            $(checkbox).wrap('<div class="checkbox-admin checkbox-success"></div>');
                            $(checkbox).after($("<label />").attr("for", $(checkbox).attr("id")));
                        }
                    });
                }

                if (!$(this).getGridParam().show_lines) {
                    $(this).addClass("remove-grid-border");
                }

                if (!$(this).getGridParam().show_headers) {
                    $(this.grid.hDiv).find(".ui-jqgrid-htable").hide();
                }
            },
        },
        events: {
            "keyup .js-grid-search": "filterData",
        },

        initialize: function (options) {
            this.schema = $.extend(this.defaultGridSchema, options.tableModel);
            this.model = new Backbone.Model({});
            this.model.set("cid", this.model.cid);
            var self = this;
            this.listenTo(this, "hidePagination", this.hidePagination);
        },
        onRender: function () {
            var self = this;
            if (this.schema.pagination) {
                this.schema.pager = self.$el.find("#gridPager2-" + self.model.cid);
            } else {
                self.$el.find("#gridPager2-" + self.model.cid).hide();
            }

            if (!this.schema.search) {
                self.$el.find("#grid-search-" + self.model.cid).hide();
            }

            self.grid = self.$el.find("#gridRegion-" + self.model.cid).jqGrid(this.schema);
        },
        filterData: function (event) {
            var searchContent = this.$el.find("#grid-text-search-" + this.model.cid).val();
            var condition = {
                groupOp: "OR",
                rules: [],
            };
            var postData = this.grid.getGridParam().postData;
            _.each(this.grid.getGridParam().colModel, function (datum) {
                condition.rules.push({
                    field: datum.name,
                    op: "cn",
                    data: searchContent,
                });
            });
            postData.filters = condition;
            this.grid.setGridParam("postData", postData);
            this.grid.trigger("reloadGrid", [
                {
                    page: 1,
                },
            ]);
        },
        hidePagination: function () {
            this.$el.find("#gridPager2-" + this.model.cid).hide();
        },
    });
});
