import { getDatabaseType } from "../../../../app/components/appwizard/component-wizards/wizards/common/databaseTypes";
import { DB_PROVIDERS } from "../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/configs/db-providers";
import metaObjectHelper from "../../../../app/components/common/editor/meta-object-editors/metaObjectHelper";
import { Job, JobStatuses, JobVersion } from "../guardian/guardian-job.types";
import { Source } from "./pii.types";

const ENTITIES = {
    KafkaReader: {
        whatIsEntity: "Topic-Partition",
        whatIsEvent: "Row"
    },
    ADLSReader: {
        whatIsEntity: "Directory",
        whatIsEvent: "Document"
    },
    FileReader: {
        whatIsEntity: "Directory",
        whatIsEvent: "Row"
    },
    MultiFileReader: {
        whatIsEntity: "Directory",
        whatIsEvent: "Row"
    },
    HDFSReader: {
        whatIsEntity: "Directory",
        whatIsEvent: "Row"
    },
    MongoDBReader: {
        whatIsEntity: "Collection",
        whatIsEvent: "Document",
        whatIsField: "Field"
    },
    MongoCosmosDBReader: {
        whatIsEntity: "Collection",
        whatIsEvent: "Document",
        whatIsField: "Field"
    },
    CosmosDBReader: {
        whatIsEntity: "Container",
        whatIsEvent: "Document",
        whatIsField: "Field"
    },
    SnowflakeReader: {
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column"
    },
    S3Reader: {
        whatIsEntity: "Directory",
        whatIsEvent: "Document"
    },
    GCSReader: {
        whatIsEntity: "Directory",
        whatIsEvent: "Document"
    },
    MariaDBReader: {
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column"
    },
    IncrementalBatchReader: {
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column"
    }
};

const getFieldType = parserName => {
    switch (parserName) {
        case "JSONParser":
        case "XMLParser":
        case "XMLParserV2":
            return "Field";
        case "AvroParser":
        case "ParquetParser":
        case "DSVParser":
            return "Column";
        default:
            return "Field";
    }
};

const getEventType = parserName => {
    switch (parserName) {
        case "JSONParser":
        case "XMLParser":
        case "XMLParserV2":
            return "Document";
        case "AvroParser":
        case "ParquetParser":
        case "DSVParser":
            return "Row";
        default:
            return "Row";
    }
};

const getCollapsibleSectionContent = (hasSensitiveData = true) => {
    const text = hasSensitiveData
        ? `Sherlock detected sensitive data in your source sample. Its AI engines may occasionally misclassify data. Add a Sentinel to detect and protect sensitive data in real-time for each source in your App.`
        : `Sherlock did not detect sensitive data in your source sample. There may still be sensitive data present in records that were not scanned. 
        
        Sherlock’s AI engines may occasionally misclassify data. You can still add Sentinel to safeguard sensitive data, in case it appears in the future or if something was misclassified`;
    return text;
};

const getSourcesIconMapping = sources => {
    const sourcesObj = {};
    sources?.forEach(source => {
        const sourceData = source.adapter.properties;
        const adapterName = sourceData.adapterName;
        let sourceDB, type, eventType, fieldType;
        if (sourceData.adapterName === "DatabaseReader") {
            sourceDB = sourceData.DatabaseProviderType;
            type = DB_PROVIDERS[sourceDB.toUpperCase()]?.whatIsEntity ?? "Table";
            eventType = DB_PROVIDERS[sourceDB.toUpperCase()]?.whatIsEvent ?? "Row";
            fieldType = DB_PROVIDERS[sourceDB.toUpperCase()]?.whatIsField ?? "Column";
        } else {
            sourceDB = sourceData.adapterName;
            const dbType = getDatabaseType(sourceDB);
            if (DB_PROVIDERS[dbType]) {
                type = DB_PROVIDERS[dbType]?.whatIsEntity;
                eventType = DB_PROVIDERS[dbType]?.whatIsEvent;
                fieldType = DB_PROVIDERS[dbType]?.whatIsField;
            } else {
                const parserName = source.parser?.properties?.parserName;
                const entity = ENTITIES[sourceDB];
                if (!entity) {
                    type = "Entity";
                    fieldType = "Field";
                    eventType = "Event";
                } else {
                    type = entity.whatIsEntity;
                    fieldType = parserName ? getFieldType(parserName) : entity.whatIsField;
                    eventType = parserName ? getEventType(parserName) : entity.whatIsEvent;
                }
            }
        }
        const className = metaObjectHelper.getIconClassByMetaObject(source);
        const name = source?.name;
        const outputStream = source?.outputStream;
        sourcesObj[name] = {
            className,
            type,
            outputStream,
            eventType,
            fieldType,
            sourceDB,
            adapterName
        };
    });
    return sourcesObj;
};

type Metrics = {
    sensitiveEntities: number;
    sensitiveIdentifiers: string[];
    totalEntities: number;
};

const getFormattedSourcesData = (reportForJob: Job[], sources): Source[] => {
    const sourcesObj = getSourcesIconMapping(sources);
    const data = reportForJob.reduce((acc, curr) => {
        const sourceComponentName = curr.sourceName.split(".").pop();
        //If the source from job report is not present in the current app
        if (!sourcesObj[sourceComponentName]) {
            return acc;
        }
        let metrics: Metrics | null = null;
        let result = {},
            piiData = {};
        try {
            metrics = JSON.parse(curr.metrics);
            piiData = JSON.parse(curr.piiData);
        } catch (error) {
            console.error(error);
        }
        result["name"] = sourceComponentName;
        result["className"] = sourcesObj[sourceComponentName].className;
        result["type"] = sourcesObj[sourceComponentName].type;
        result["outputStream"] = sourcesObj[sourceComponentName].outputStream;
        result["eventType"] = sourcesObj[sourceComponentName].eventType;
        result["fieldType"] = sourcesObj[sourceComponentName].fieldType;
        result["sourceType"] = sourcesObj[sourceComponentName].sourceDB;
        result["adapterName"] = sourcesObj[sourceComponentName].adapterName;
        result["status"] = curr.status;

        const entities = Object.keys(piiData);
        result["totalEntities"] = metrics?.totalEntities || 0;
        result["entitiesWithSensitiveData"] = metrics?.sensitiveEntities || 0;
        let sensitiveIdentifiersInEntities = {};
        result["entities"] = entities.map(entity => {
            let piiFields = [],
                entityPIIData = {};
            try {
                entityPIIData = JSON.parse(piiData?.[entity]);
                piiFields = entityPIIData["PII fields"];
            } catch (error) {
                console.error(error);
            }
            let sensitiveDataIdentifiersInEntity = new Set();
            let columns = [];
            piiFields.forEach(column => {
                const identifierTypes = Object.keys(column.identifierType);
                const confidenceScoreTotal =
                    (Object.values(column.identifierType) || []).reduce((x: number, y: number) => x + y, 0) || 0;
                const entityConfidenceScores = [];
                identifierTypes.forEach(type => {
                    sensitiveDataIdentifiersInEntity.add(type);
                    sensitiveIdentifiersInEntities[type] = sensitiveIdentifiersInEntities[type] ?? [];
                    !sensitiveIdentifiersInEntities[type]?.includes(entity) &&
                        sensitiveIdentifiersInEntities[type].push(entity);

                    entityConfidenceScores.push({
                        name: type,
                        confidenceScore: ((column.identifierType[type] / confidenceScoreTotal) * 100).toFixed(0)
                    });
                });
                columns.push({
                    name: column.fieldName,
                    sensitiveDataIdentifiers: entityConfidenceScores
                });
            });
            return {
                name: entity,
                eventType: result["eventType"],
                fieldType: result["fieldType"],
                sensitiveDataIdentifiers: Array.from(sensitiveDataIdentifiersInEntity),
                columnsWithSensitiveData: piiFields.length,
                totalColumns: entityPIIData["Total Fields"],
                rowsSampled: entityPIIData["Number Of Rows Sampled"],
                columns
            };
        });
        result["sensitiveIdentifiersInEntities"] = sensitiveIdentifiersInEntities;
        result["errorMessage"] = curr.otherDetails;
        acc.push(result);
        return acc;
    }, []);
    return data;
};

const getFormattedJobVersionsList = (jobVersions: JobVersion[]) => {
    return jobVersions
        ?.filter(
            job =>
                job.status === JobStatuses.COMPLETED ||
                job.status === JobStatuses.ERROR ||
                job.status === JobStatuses.RUNNING
        )
        .sort((x, y) => Number(y.completedTime) - Number(x.completedTime));
};

const getFormattedJobVersionsListByStartTime = (jobVersions: JobVersion[]) => {
    return jobVersions
        ?.filter(
            job =>
                job.status === JobStatuses.COMPLETED ||
                job.status === JobStatuses.ERROR ||
                job.status === JobStatuses.RUNNING
        )
        .sort((x, y) => Number(y.startTime) - Number(x.startTime));
};

const getFormattedChartData = data => {
    const formattedList = data.map(item => ({
        ...item,
        name: item.name
    }));
    const otherData =
        formattedList.length > 5
            ? formattedList.slice(4).reduce((accumulated, current) => {
                  current.items.map(item => accumulated.add(item));
                  return accumulated;
              }, new Set())
            : null;
    return [
        ...formattedList.slice(0, 4),
        ...(otherData ? [{ name: "Others", count: otherData.size, items: formattedList.slice(4) }] : [])
    ];
};

const getPluralizedEntityName = entity => {
    switch (entity) {
        case "Directory":
            return "Directories";
        default:
            return `${entity}s`;
    }
};

export {
    getPluralizedEntityName,
    getFormattedSourcesData,
    getCollapsibleSectionContent,
    getFormattedJobVersionsList,
    getFormattedJobVersionsListByStartTime,
    getFormattedChartData
};
