import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing }) => ({
    createDashboardBtn: {
        marginTop: spacing(4)
    },
    createDashboardFormContainer: {
        width: "100%"
    }
}));
