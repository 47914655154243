import App from "app";
import iframeDetector from "core/iframeDetector";
import component_template from "./component-template.html";
import "core/services/metadataService/defaultmetaobject";
import "./grid-component-controls";
import "backbone.nestedtypes";
import NestedTypes from "backbone.nestedtypes";
import ensureRegions from "../../common/_ensureRegions";
import { mousePos } from "../../common/drag-and-drop";

App.module("Dashboard.Grid.Component", function (Component, App, Backbone, Marionette, $, _, template) {
    var Grids = NestedTypes.Collection.extend({
        model: App.Dashboard.Grid.Model,
    });

    App.Dashboard.Grid.Settings = NestedTypes.Model.extend({
        defaults: {
            cols: 12,
            cellheight: 20,

            /**
             * property is calculated during rendering
             */
            rows: 0,

            /**
             * page background color
             */
            background: "",
        },
        _createCSSHeight: function (num) {
            return _(num).isNumber() ? num * this.cellheight : num;
        },
        _createCSSWidth: function (num) {
            return _(num).isNumber() ? (num / this.cols) * 100 + "%" : num;
        },
        _createHeight: function (num) {
            return parseInt(num) / this.cellheight;
        },
        _createWidth: function (num) {
            return (parseInt(num) / 100) * this.cols;
        },
        getCSS: function (data) {
            var layout = {};

            if ("width" in data) layout.width = this._createCSSWidth(data.width);
            if ("height" in data) {
                var height = this._createCSSHeight(data.height);
                var margin = 10; // TODO: store this as a default everywhere for the margin. Don't even keep it in the CSS.
                if ("margin" in (data.style || {})) {
                    margin = parseInt(data.style.margin);
                }
                //height -= margin*2;
                layout.height = height + "px";
            }
            if ("x" in data) layout.left = this._createCSSWidth(data.x);
            if ("y" in data) layout.top = this._createCSSHeight(data.y) + "px";

            return layout;
        },
        getLayout: function (data) {
            var layout = {};

            if ("left" in data) layout.x = this._createWidth(data.left);
            if ("top" in data) layout.y = this._createHeight(data.top);
            if ("width" in data) layout.width = this._createWidth(data.width);
            if ("height" in data) layout.height = this._createHeight(data.height);

            return layout;
        },
    });

    App.Dashboard.Grid.Style = NestedTypes.Model.extend({
        defaults: {
            margin: 0,
            background: NestedTypes.Model.extend({
                defaults: {
                    color: "transparent",
                },
            }),
            border: NestedTypes.Model.extend({
                defaults: {
                    color: "transparent",
                    size: 0,
                },
            }),
        },
    });

    // TODO: this shouldn't extend the MetaObject now
    Component.Model = App.Metadata.DefaultMetaObject.Model.extend({
        defaults: {
            _editable: true, // Can this component be moved and resized
            _active: false, // is this component one of the ones being moused over?
            //_hover: false, // is this the component that is being directly moused over? TODO: remove
            _frozen: false, // is the component currently being resized/moved
            _polling: true, // TODO: create a model for vispanel that extends this. e.g. polling...
            settings: App.Dashboard.Grid.Settings,
            style: App.Dashboard.Grid.Style, // Background, border, etc.
            controls: App.Dashboard.Grid.Component.Controls.Model,
            x: null,
            y: null,
            width: null,
            height: null,
            content_id: String, // TODO: this should be serialized version of content
            content: null, // If no grid, then define content
        },
        set_ids: function () {
            // Because of other objects like QV and page that extend this, we need to preserve the DefaultMetaObject ID assign method
            App.Metadata.DefaultMetaObject.Model.prototype.set_ids.apply(this, arguments);

            // If the component has no ID, assign one
            if (!this.id)
                this.set("id", _.uniqueId("gridcomponent"), {
                    silent: true,
                });
        },
        serialize: function () {
            var json = {
                x: this.x,
                y: this.y,
                width: this.width,
                height: this.height,
            };
            if (this.id) json.id = this.id;
            if (this.style) json.style = this.style;
            if (this.content_id || this.content) json.content_id = this.content_id || this.content.name;

            return json;
        },
    });

    // The Grid Component (a grid cell) and its controls.
    // The abstract base implementation is below. It is used by the page's grid components and by each visualization
    Component.View = Marionette.LayoutView.extend({
        template: _.template(component_template),
        regions: {
            controls: ".controls:first",
            content: ".content",
        },
        initialize: function () {
            ensureRegions(this);
            _(this).bindAll("initializeDrag", "startDrag", "performDrag", "finishDrag");

            this.listenTo(this.model, "change:x change:y change:width change:height", this.renderPosition);

            this.listenTo(this.model, "change:style", function () {
                this.trigger("component:style", this);
                this.renderStyles();
            });

            this.listenTo(this.model, "change:_active", this.renderActiveState);
        },

        /* Render and Show
        ----------------------------------------------------------------------------------*/

        onRender: function () {
            ensureRegions(this);
            this.renderPosition();
            if (!iframeDetector.isInIframe()) {
                this.renderControls();
                this.renderActiveState();
            }

            this.renderStyles();
        },
        renderPosition: function () {
            this.$el.css(this.model.settings.getCSS(this.model.toJSON()));
        },
        renderControls: function () {
            ensureRegions(this);
            var controlsview = new App.Dashboard.Grid.Component.Controls.View({
                model: this.model.controls,
            });
            this.getRegion("controls").show(controlsview);
        },
        renderActiveState: function () {
            var controlsview = this.getRegion("controls").currentView;

            if (this.model._active) {
                var classnames = "active";

                if (this.model._editable) {
                    var $grid = this.$el.parent();
                    // A better way (in some cases) to set the z-index: detach and move the node in the DOM tree
                    if ($grid.children(".grid-component").length > 1) {
                        this.$el.detach().appendTo($grid);
                    }
                    _(
                        function () {
                            this.$el.addClass(classnames);
                            controlsview.model.set("_active", true);

                            controlsview.$el.toggleClass("float-right", this._shouldControlsFloatRight(controlsview));
                        }.bind(this)
                    ).defer();
                }
            } else {
                this.$el.removeClass("active");
                controlsview.model.set("_active", false);
            }
        },

        _shouldControlsFloatRight: function (controlsview) {
            var windowWidth = $(window).width();
            if (windowWidth <= 0) {
                return false;
            }

            var elOffsetLeft = this.$el.offset().left;

            // get space left between beginning of visualization
            // and window right border
            var diff = windowWidth - elOffsetLeft;

            // calculate width of header panel
            var controlsWidth = 0;
            var region = controlsview.getRegion("header");
            if (region && region.currentView && region.currentView.$el) {
                controlsWidth = region.currentView.$el.width();
            }

            if (controlsWidth > diff) {
                return true;
            }

            return false;
        },

        renderStyles: function () {
            // Render component styles
            if (this.model.style.background) {
                var bg = this.model.style.background.color == "transparent" ? "" : this.model.style.background.color;
                this.$(".component-style").first().css("background", bg);
            }
            if (this.model.style.border) {
                this.$(".component-style")
                    .first()
                    .css("border", this.model.style.border.size + "px solid " + this.model.style.border.color);
            }
            var margin = "margin" in this.model.style ? this.model.style.margin : 0;
            // We use the element's padding property to set what the viewer will see as a margin. It works.
            this.$el.css("padding", margin);
        },
        onShow: function () {
            ensureRegions(this);
            var _this = this;
            this.$el.on("mouseleave." + this.model.id, function (e) {
                _this.trigger("mouseleave", e);
            });
            this.$el.on("mouseenter." + this.model.id, function (e) {
                _this.trigger("mouseenter", e);
            });

            // unselect all components when clicking on the grid (DEV-4748)
            var componentsStyleNode = this.$el.find(".component-style");
            componentsStyleNode.on(
                "click",
                function (e) {
                    if (e.target !== componentsStyleNode[0]) {
                        return;
                    }
                    this.model.components.models.forEach(function (model) {
                        model.set("_active", false);
                    });
                }.bind(this)
            );

            // Add component ID and classes (component ID data needed for drag/drop)
            this.$el.data("id", this.model.get("id"));
        },

        /* Drag lifecycle
        ----------------------------------------------------------------------------------*/

        initializeDrag: function (ev, dd) {
            var component = this; // TODO: remove
            // Direction to drag
            var $handle = $(ev.target).closest(".handle");
            dd.dir = $handle.length > 0 ? $handle.data("direction") : "move";
            dd.event = dd.dir == "move" ? "move" : "resize";

            // Do not permit any interaction if this component is not editable
            if (!this.model._editable) return false;

            if (dd.event == "move") {
                // The presence of these properties will enable the grid to draw a drop target area
                //console.log('Moving from', gridview.model.id);
                dd.item = component;
                dd.preferred_component_dimensions = component.model.pick("width", "height");
                dd.component_dimensions = $.extend(true, {}, dd.preferred_component_dimensions);
            }

            component.trigger("component:" + dd.event + "init", component, ev, dd);
            App.vent.trigger("component:" + dd.event + "init", component, ev, dd);
        },
        startDrag: function (ev, dd) {
            if (!dd.event) return false;
            var component = this;
            this.model.set("_frozen", true);

            dd.start_layout = _(component.model.toJSON()).pick(["x", "y", "width", "height"]);
            dd.layout = $.extend(true, {}, dd.start_layout);

            // Determine the pixel width and height of one grid unit (for snapping)
            dd.draggrid = {
                width: $(dd.drag).outerWidth() / dd.start_layout.width,
                height: component.model.settings.cellheight,
            };

            // Where within the component did the user press down?
            // max with 0 to discard situations in which user presses down outside the component,
            // such as on a grid handle. We want the proxy to always appear under the cursor.
            if (dd.event != "new") {
                dd.x_within_dragged = Math.max(0, mousePos.pageX - $(dd.drag).offset().left);
                dd.y_within_dragged = Math.max(0, mousePos.pageY - $(dd.drag).offset().top);
            }

            //component.trigger('child:component:'+dd.event+'start', component, dd);
            component.trigger("component:" + dd.event + "start", component, dd);
            App.vent.trigger("component:" + dd.event + "start", component, dd);
        },
        pxToNearestGridLine: function (val, grid) {
            return Math.round(val / grid);
        },
        performDrag: function (e, dd) {
            dd.snap = this.model.controls.snap && !e.shiftKey;

            var component = this; // TODO: remove

            var newLayout = _(dd.layout).clone();

            if (dd.snap) {
                var deltaX = dd.deltaX;
                var deltaY = dd.deltaY;

                var offsetX_gridline = this.pxToNearestGridLine(dd.gridX, dd.draggrid.width);
                var offsetY_gridline = this.pxToNearestGridLine(dd.gridY, dd.draggrid.height);

                var distanceFromGridlineX = dd.gridX - offsetX_gridline * dd.draggrid.width;
                var distanceFromGridlineY = dd.gridY - offsetY_gridline * dd.draggrid.height;

                deltaX -= distanceFromGridlineX;
                deltaY -= distanceFromGridlineY;
            } else {
                var deltaX_grid = dd.deltaX / dd.draggrid.width;
                var deltaY_grid = dd.deltaY / dd.draggrid.height;
            }

            if (dd.dir == "move") {
                if (dd.snap) {
                    newLayout.x = this.pxToNearestGridLine(dd.gridX - dd.x_within_dragged, dd.draggrid.width);
                    newLayout.y = this.pxToNearestGridLine(dd.gridY - dd.y_within_dragged, dd.draggrid.height);
                } else {
                    newLayout.x = dd.start_layout.x + deltaX_grid;
                    newLayout.y = dd.start_layout.y + deltaY_grid;
                }
            } else {
                if (dd.dir.indexOf("E") !== -1) {
                    if (dd.snap) {
                        newLayout.width = offsetX_gridline - dd.start_layout.x;
                    } else {
                        newLayout.width = dd.start_layout.width + deltaX_grid;
                    }
                } else if (dd.dir.indexOf("W") !== -1) {
                    if (dd.snap) {
                        newLayout.x = offsetX_gridline;
                        var rightEdge = dd.start_layout.x + dd.start_layout.width;
                        newLayout.width = rightEdge - newLayout.x;
                    } else {
                        newLayout.width = dd.start_layout.width + deltaX_grid * -1;
                        newLayout.x = dd.start_layout.x + deltaX_grid;
                    }
                }

                if (dd.dir.indexOf("S") !== -1) {
                    if (dd.snap) {
                        newLayout.height = offsetY_gridline - dd.start_layout.y;
                    } else {
                        newLayout.height = dd.start_layout.height + deltaY_grid;
                    }
                } else if (dd.dir.indexOf("N") !== -1) {
                    if (dd.snap) {
                        newLayout.y = offsetY_gridline;
                        var bottomEdge = dd.start_layout.y + dd.start_layout.height;
                        newLayout.height = bottomEdge - newLayout.y;
                    } else {
                        newLayout.height = dd.start_layout.height + deltaY_grid * -1;
                        newLayout.y = dd.start_layout.y + deltaY_grid;
                    }
                }
            }

            this.trigger("validateLayout", newLayout);

            // TODO: if there is empty unused space at top of grid, allow grid to "shrink wrap" it.
            // Permit grid resize, and also shift components inside upward.

            // Update the running layout and set the component's CSS
            _(dd.layout).extend(newLayout);

            this.trigger("component:updatePosition", this, e, dd, newLayout);
            component.model.set(newLayout);
            App.vent.trigger("component:" + dd.event, this, e, dd);
        },
        finishDrag: function (e, dd) {
            $(dd.drag).removeClass("lifted");
            if (dd.$placeholder) dd.$placeholder.remove();
            this.model.set("_frozen", false);

            this.trigger("component:" + dd.event + "end", this, e, dd);
            App.vent.trigger("component:" + dd.event + "end", this, e, dd);
        },
    });
});
