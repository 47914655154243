const sxStyles = {
    documentationItem: {
        paddingBottom: 2
    },
    listItem: {
        padding: 0,
        fontWeight: 400,
        height: 0
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
        gap: 4
    },
    videoContainer: {
        display: "flex",
        justifyContent: "end"
    },
    header: {
        pb: 2.5
    },
    list: {
        maxWidth: 420,
        margin: 0
    },
    linkItem: {
        fontWeight: 400,
        ml: -1.75,
        color: "secondary.500",
        "&:hover": {
            color: "secondary.700"
        },
        "&:active": {
            color: "secondary.900"
        }
    }
};

export default sxStyles;
