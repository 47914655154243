import { VaultTypes } from "./constants";
import api from "core/api/api";
// TODO: index.js is not working for the core/services/metaStoreService dependencies
import metaobjectUtilities from "core/services/metadataService/metaobject-utilities";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import { number } from "prop-types";

const imageRoot = "src/generic/icon/manage-striim";

const VaultsService = {
    getNamespaces: async function() {
        return await metaobjectUtilities.getFilteredNamespaces();
    },
    saveVault: async vault => {
        //TODO: probably this can be refactored a bit, please look into vault.tests.js for inspiration
        return new Promise((resolve, reject) => {
            const newVault = new metaStoreService.create(metaStoreService.entities.VAULT);

            const vaultPropertyMaps = [];
            Object.keys(vault.properties).forEach(key => {
                let value = vault.properties[key];
                if (typeof value === number) {
                    value = value.toString();
                }
                vaultPropertyMaps.push({
                    name: key,
                    value: value
                });
            });

            newVault.set("nsName", vault.nsName);
            newVault.set("name", vault.name);

            newVault.set("properties", vaultPropertyMaps);
            newVault
                .save()
                .then(function() {
                    resolve(vault);
                })
                .fail(function() {
                    reject("error saving vault");
                });
        });
    },
    deleteVault: function(name, nsName) {
        return new Promise((resolve, reject) => {
            api.dropObject(
                {
                    type: metaStoreService.entities.VAULT,
                    name: metaObjectConverter.getShortId(nsName, name),
                    nsName: nsName
                },
                true
            )
                .then(() => {
                    resolve();
                })
                .fail(e => {
                    reject(e);
                });
        });
    },
    getVaults: async function() {
        return new Promise((resolve, reject) => {
            metaStoreService
                .fetchCollection(metaStoreService.entities.VAULT)
                .then(vaults => {
                    resolve(vaults.models);
                })
                .fail(() => {
                    reject();
                });
        });
    },
    getVaultValues: async vaultKey => {
        return api.getVaultAllValues(vaultKey);
    },
    addVaultValue: async (...args) => {
        return api.addVaultValue(...args);
    },
    dropVaultValue: async (...args) => {
        return api.dropVaultValue(...args);
    },

    alterVaultValue: async (...args) => {
        return api.alterVaultValue(...args);
    },
    alterVault: async (...args) => {
        return api.alterVault(...args);
    },

    getVaultTypes: () => [
        {
            value: VaultTypes.STRIIM_VAULT,
            label: "Striim Vault",
            icon: `${imageRoot}/06_StriimLogo.png`
        },
        {
            value: VaultTypes.HASHICORP_VAULT,
            label: "HashiCorp Vault",
            icon: `${imageRoot}/07_HashicorpLogo.png`
        },
        {
            value: VaultTypes.AZURE_VAULT,
            label: "Azure Key Vault",
            icon: `${imageRoot}/08_AzureLogo.png`
        },
        {
            value: VaultTypes.GOOGLE_SECRET_MANAGER,
            label: "Google Secret Manager",
            icon: `${imageRoot}/14_Google_Secret_Manager.svg`
        }
    ],
    getVaultValueTypes: value => {
        return value
            ? {
                  label: value,
                  value: value
              }
            : [
                  { label: "STRING", value: "STRING" },
                  { label: "FILE", value: "FILE" }
              ];
    },
    getVaultUsage: (vault, key) => {
        return vault && key ? `[[${vault?.nsName}.${vault?.name}.${key}]]` : "";
    },
    getNamespace: vault => `[${vault?.attributes.nsName}]`
};
export default VaultsService;
