export const styles = {
    header: {
        display: "flex",
        alignItems: "center",
        gap: 1,
        textWrap: "nowrap"
    },
    appLink: {
        cursor: "pointer",
        color: theme => theme.palette.secondary[500]
    },
    iconButton: {
        minWidth: "unset",
        px: 1,
        py: 1 / 2,
        "& .MuiButton-startIcon": {
            mx: 0
        }
    },
    icon: {
        fontSize: 16,
        fill: "none",
        "& > path": {
            stroke: ({ palette }) => palette.secondary[500]
        }
    },
    backIcon: {
        fontSize: "20px !important"
    }
};
