import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing, shadows, palette, breakpoints }) => ({
    appTile: {
        position: "relative",
        cursor: "pointer",
        backgroundColor: palette.paper.background,
        border: `1px solid ${palette.paper.border}`,
        borderRadius: 8,
        minHeight: 60,
        padding: spacing(2),
        minWidth: 1000,
        "&:hover": {
            boxShadow: shadows[8]
        },

        "&:not(:last-child)": {
            marginBottom: 0
        },
        [breakpoints.down(1140)]: {
            overflow: "visible",
            minWidth: "fit-content"
        }
    },
    appTileWrapper: {
        display: "flex",
        alignItems: "center",
        overflow: "hidden"
    },
    appTileNameContainer: {
        display: "flex",
        alignItems: "center",
        flexBasis: "100%",
        overflow: "hidden",
        minWidth: "200px",
        maxWidth: "20%"
    },
    appNames: {
        flexDirection: "column"
    },
    appNamesList: {
        flexDirection: "column",
        marginLeft: spacing(1)
    },
    title: {
        marginBottom: spacing(0.5),
        color: palette.text.primary,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "135px"
    },
    subTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "12px",
        lineHeight: "20px"
    },
    appInfoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "relative",
        minWidth: "80px"
    },
    appInfoContainerDivider: {
        minWidth: "180px",
        paddingRight: spacing(4),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "relative",
        "&:after": {
            content: '""',
            position: "absolute",
            top: "50%",
            right: "16px",
            width: "1px",
            height: "18px",
            textAlign: "center",
            transform: "translate(-50%,-50%)",
            background: "#90999D"
        }
    },
    appInfoContainerText: {
        marginRight: spacing(1),
        color: v5theme => v5theme.palette.greyscale[700]
    },
    appDetailsSpacing: { minWidth: "86px", marginRight: spacing(3) },
    gridSourceTargetList: { paddingLeft: spacing(2), minWidth: "200px" },
    gridAppActionButton: {
        marginLeft: "auto",
        width: 40
    },
    appDetailsContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexBasis: "100%",
        maxWidth: "100%"
    },
    appSelector: { paddingRight: spacing(1) },
    appInfoWrapper: {
        display: "flex",
        alignItems: "center",
        margin: "auto",
        width: "100%"
    },
    appStatsWidth: {
        minWidth: "250px"
    },
    targetListsTileWide: {
        flexWrap: "nowrap"
    },
    targetListsEmptyTileWide: {
        whiteSpace: "nowrap"
    },
    appDataContainer: {
        flexWrap: "nowrap",
        justifyContent: "flex-end",
        alignItems: "center"
    }
}));
