import React, { useRef } from "react";
import DownloadIcon from "src/generic/icon/wizard-icon/Download.svg";
import { StriimButton } from "@striim/striim-ui-v2";
import { generateAutomatedReport } from "../../../apps/pages/app-list/components/automated-app-group-detail/services/download-reports.service";

const Printable = ({ component, name, id = "section-to-print", buttonLabel = "Download" }) => {
    const styleRef = useRef(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const styles = {
        downloadIcon: {
            fill: "none"
        }
    };

    return (
        <div style={{ display: "inline" }} ref={containerRef}>
            <style ref={styleRef} />
            <StriimButton variant="text" sx={{ gap: 1 }} onClick={() => generateAutomatedReport(name, false)}>
                <DownloadIcon style={styles.downloadIcon} />
                {buttonLabel}
            </StriimButton>
            <div id="hiddenDiv" style={{ display: "none" }}>
                <div id={id} style={{ width: "700px", letterSpacing: "0.01px" }}>
                    {component}
                </div>
            </div>
        </div>
    );
};

export default Printable;
