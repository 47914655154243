export const styles = {
    chip: {
        margin: ({ spacing }) => spacing(0.4)
    },
    subTitle: {
        margin: "0px",
        marginRight: ({ spacing }) => spacing(4) + "!important",
        color: ({ palette }) => palette.primary[700]
    },
    wrapperInner: {
        marginTop: ({ spacing }) => spacing(3),
        marginBottom: ({ spacing }) => spacing(5)
    }
};
