import noDataTemplate from "app/components/monitor/appdetails/appdata/templates/noDataTemplate";

export default {
    updateChartSeries: function () {
        if (!this.chartConfig.series) {
            return;
        }

        var chart = this.$el.highcharts();

        while (chart.series.length > 0) {
            chart.series[0].remove(false);
        }

        this.chartConfig.series.forEach(chain => chart.addSeries(chain, false));

        // Check if there is no data
        if (!this.model.dataSource.data.length) {
            var placeholder = new noDataTemplate({
                text: "No events in the specified time range."
            });
            if (!chart.customNoDataLabel) {
                chart.customNoDataLabel = chart.renderer.html('<div id="no-data-container"></div>', 180, 120)
                    .add();
                    this.$el.find("#no-data-container").html(placeholder.el);
            }
        } else if (chart.customNoDataLabel) {
            // Remove the "No data" text
            chart.customNoDataLabel.destroy();
            chart.customNoDataLabel = null;
        }
    }
};
