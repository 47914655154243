import React from "react";
import { Section } from "../components/report-components";
import { Box, Grid } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import { getEntityReportChartData } from "../../discover-pii-panel/components/panel-results/panel-results";
import {
    SherlockIdentifiersTable,
    TopHighSdiApps,
    TopHighSdiTable
} from "../components/report-tables/sherlock-report-tables";
import { styles } from "../sentinel-reports/global-report/sentinel-global-report.styles";
import { getPriorityImage } from "../../sentinel-live-monitor/components/sentinel-table/sentinel-table-sdi";
import { ImportanceLabelWithIcon } from "../components/report-tables/report-table-componets";

export const DiscoveredSDITable = ({ sourceData }) => {
    const chartData = getEntityReportChartData(sourceData[0].sensitiveIdentifiersInEntities);
    return <SherlockIdentifiersTable data={chartData} totalEvents={1} />;
};

export const TopHighIdentifiers = ({ identifiers }) => {
    return (
        <>
            {identifiers &&
                identifiers.map(table => {
                    const tableName = table.entityName;
                    return (
                        <Section showborderBottom={false} key={tableName}>
                            <Box my={2}>
                                <StriimTypography variant="h3">{tableName}</StriimTypography>
                            </Box>
                            <TopHighSdiTable data={table.COLUMN_SDI_IMPORTANCE_MATRIX} />
                        </Section>
                    );
                })}
        </>
    );
};

export const ImportanceLegend = () => {
    return (
        <Grid container mt={1}>
            <Box display={"flex"} gap={3}>
                <StriimTypography variant="caption" sx={styles.lightText}>
                    Importance
                </StriimTypography>
                <ImportanceLabelWithIcon label={"High"} icon={getPriorityImage("HIGH")} />
                <ImportanceLabelWithIcon label={"Medium"} icon={getPriorityImage("MEDIUM")} />
                <ImportanceLabelWithIcon label={"Low"} icon={getPriorityImage("LOW")} />
            </Box>
        </Grid>
    );
};

export const TopHighImportanceSDIs = ({ identifiers }) => {
    const { appInfo, appSdiInfo } = identifiers;
    return (
        <Grid container my={2} gap={2}>
            <StriimTypography variant="h1" color="primary.700">
                Top Apps with High Importance Identifiers
            </StriimTypography>
            {appSdiInfo &&
                appSdiInfo.map(sdiItem => {
                    const sdiName = sdiItem.identifierType;
                    const sortedAppEntitiesInfo =
                        sdiItem?.appEntitiesInfo.sort((x, y) => y.numOfEntities - x.numOfEntities) ?? [];
                    return (
                        <Section showborderBottom={false} key={sdiName}>
                            <Box mb={2}>
                                <StriimTypography variant="h3">Top 10 Apps W/ {sdiName}</StriimTypography>
                            </Box>
                            <TopHighSdiApps data={sortedAppEntitiesInfo} appInfo={appInfo} />
                        </Section>
                    );
                })}
        </Grid>
    );
};
