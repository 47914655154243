import React, { useState } from "react";
import { StriimInputField, StriimButton, StriimModal, StriimTypography } from "@striim/striim-ui";
import LocalStore from "./file_browser.store";
import UploadModal from "./file_upload_modal";
import PropTypes from "prop-types";

const FileBrowserAsTextbox = ({ onChange, defaultValue }) => {
    const modalState = useState(false);
    const showValueModalState = useState(false);
    let localStore = new LocalStore();
    let setValue = function(value) {
        onChange(value);
    };

    return (
        <div>
            <StriimButton
                variant={"primaryText"}
                onClick={() => {
                    showValueModalState[1](true);
                }}
            >
                View
            </StriimButton>
            <StriimButton
                variant={"primary"}
                onClick={() => {
                    modalState[1](true);
                }}
            >
                Upload
            </StriimButton>
            <StriimModal
                isVisible={showValueModalState[0]}
                size="xSmall"
                titleContent={<StriimTypography variant="h2">Selected Value for the field: </StriimTypography>}
                cancelContent={false}
                confirmContent={"Ok"}
                onConfirm={() => {
                    showValueModalState[1](false);
                }}
            >
                <StriimInputField defaultValue={defaultValue} disabled={true} />
            </StriimModal>
            <UploadModal localStore={localStore} modalState={modalState} onSelect={setValue} />
        </div>
    );
};
FileBrowserAsTextbox.propTypes = {
    onChange: PropTypes.func.isRequired
};

export default FileBrowserAsTextbox;
