import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./create-app-name-app-page.styles.js";
import { Box, Grid } from "@material-ui/core";
import { StriimTypography, StriimButton } from "@striim/striim-ui";
import resources from "app/components/createapp/resources";
import { Formik, Form } from "formik";
import FormikField from "src/generic/formik-field";
import NamespaceSelect from "src/modules/dashboards/pages/dashboard-list/components/add-dashboard-dialog/components/namespace-select/namespace-select";
import useCreateAppsStore from "src/stores/create-apps/useCreateAppsStore";
import NamespaceValidation from "src/modules/dashboards/pages/dashboard-list/components/add-dashboard-dialog/components/namespace-select/namespace.validation";
import * as Yup from "yup";
import growl from "app/components/common/growl";
import securityService from "core/services/securityService/securityService";
import { checkDrivers } from "app/components/appwizard/component-wizards/wizards/source/base-cdc/drivers-check-view";
import appTemplates from "app/components/appwizard/templates";
import { getNameValidation } from "./name.validation";
import LoadingIndicator from "../../../../generic/loading-indicator/loading-indicator";
import { Observer } from "mobx-react-lite";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HelpResources from "app/components/common/helpable/online-help-dictionary";
import { useTheme } from "@mui/material/styles";
import localStorageUtils from "app/components/appwizard/component-wizards/wizards/source/database-reader/local-storage-utils.js";
import {
    getCDCName,
    getILName,
    isAutomatedWizard
} from "../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";
import Tracker from "core/services/tracker/tracker";
import CreateAppNameAppPageTemplates from "../create-app-name-app-page-templates/create-app-name-app-page-templates";
import { getCurrentAppTypeInfo } from "../create-app-name-app-page-templates/utils";
import { DB_PROVIDERS } from "../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/configs/db-providers.js";
import APPTYPE from "../create-app-page-templates/services/create-app-page-app-types.const.json";

export const getValidationSchema = (createFromScratch, entityType, isILCDC) =>
    Yup.object({
        name: getNameValidation(createFromScratch, entityType, isILCDC),
        nsName: NamespaceValidation
    });

const NameApp = () => {
    const themeV5 = useTheme();
    const classes = useStyles(themeV5.palette);
    const navigate = useNavigate();
    const createAppStore = useCreateAppsStore();
    const { templateId } = useParams();
    const [showDriversCheck, setShowDriversCheck] = useState(false);
    const entityType = "APPLICATION";

    const isILCDC = isAutomatedWizard();
    const handleSubmit = useCallback(
        async ({ nsName, name, password }) => {
            // Clean Up localstorage wizard data
            let appName = isILCDC ? getILName(name) : name;
            localStorageUtils.cleanUp(`${nsName}.${appName}`);
            createAppStore.createFromScratch
                ? createAppStore.createNewApp(appName, nsName, templateId)
                : createAppStore.import_app(nsName, password);
        },
        [createAppStore, templateId]
    );
    const currentAdapter = securityService.getCurrentAppAdapterLicenseName().toLowerCase();
    const namespaceOpts = nsFromTqlOptions || [];
    if (currentAdapter) namespaceOpts.push({ label: currentAdapter, value: currentAdapter });

    useEffect(() => {
        if (createAppStore.namespaceFromTql) {
            growl.warning(resources.namespaceInTql(createAppStore.namespaceFromTql), resources.namespaceInTqlTitle);
        }
        if (createAppStore.namespaceFromAppName) {
            growl.warning(resources.namespaceInTql(createAppStore.namespaceFromAppName), resources.namespaceInTqlTitle);
        }
    }, [createAppStore]);

    useEffect(() => {
        if (!templateId || securityService.isSnowflakePartnerConnect() || securityService.isAppAdapterLicense()) {
            return;
        }

        if (!securityService.isSaaSEnvironment()) {
            checkDrivers({ customOptions: "", templateID: templateId }).then(result => {
                setShowDriversCheck(result);
            });
        }
    }, [setShowDriversCheck, templateId]);

    const goToAppsList = e => {
        e.preventDefault();
        navigate(`/applications`);
    };

    const trackEventClicked = () => {
        const createFromScratch = createAppStore?.createFromScratch ?? false;
        const createAppStore = createAppStore?.createFromWizard ?? false;
        const buttonClicked = createFromScratch ? (createFromWizard ? `Next` : `Save`) : `Import`;
        const createFrom = createFromScratch ? (createFromWizard ? "Wizard" : "Start from Scratch") : "Import";

        const eventData = {
            buttonClicked: buttonClicked,
            createFrom: createFrom,
            event: "Create App:Clicked"
        };

        if (createFromScratch && createFromWizard) {
            eventData.isAutomatedILCDC = isILCDC;
            eventData.sourceAdapter = sourceTitle;
            eventData.targetAdapter = targetTitle;
            eventData.uses = templateDetails.uses;

            Tracker.getInstance().track("create-app-wizard", eventData);
        } else {
            Tracker.getInstance().track("create-app-flow", eventData);
        }
    };

    const nsFromTqlOptions = createAppStore.namespaceFromTql
        ? [
              {
                  label: createAppStore.namespaceFromTql,
                  value: createAppStore.namespaceFromTql
              }
          ]
        : createAppStore.namespaceFromAppName
        ? [
              {
                  label: createAppStore.namespaceFromAppName,
                  value: createAppStore.namespaceFromAppName
              }
          ]
        : [];

    let title = createAppStore.createFromScratch
        ? isILCDC
            ? resources.nameYourAppGroup
            : resources.nameYourApp
        : `Importing ${createAppStore.fileName}`;
    let templateDetails;
    let sourceTitle;
    let targetTitle;
    if (templateId) {
        templateDetails = appTemplates.getByURL(templateId);
        if (templateDetails) {
            let appTitle = templateDetails.title;
            let idx = appTitle.indexOf(" to ");
            sourceTitle = appTitle.substring(0, idx).replace("Initial Load", "");
            targetTitle = appTitle.substring(idx + 3).replace("Database", "");
        }
    }

    const currentAppTypeInfo = getCurrentAppTypeInfo(
        createAppStore.selectedAppType,
        templateDetails?.title,
        sourceTitle,
        targetTitle,
        isILCDC,
        createAppStore.fileName
    );

    return (
        <Observer>
            {() => {
                return (
                    <>
                        {createAppStore.inProgress && <LoadingIndicator />}
                        {isILCDC ? (
                            <CreateAppNameAppPageTemplates
                                createAppStore={createAppStore}
                                currentAppTypeInfo={currentAppTypeInfo}
                                currentAdapter={currentAdapter}
                                handleSubmit={handleSubmit}
                                handleCancel={goToAppsList}
                                trackEventClicked={trackEventClicked}
                            />
                        ) : (
                            <div className={classes.wrapper}>
                                {showDriversCheck && (
                                    <Grid
                                        container
                                        className={classes.driversCheckContainer}
                                        data-test-id="driversCheckContainer"
                                    >
                                        <Grid item xs={12} className={classes.driversCheck}>
                                            <StriimTypography variant="body" align="center" component="p">
                                                Note: Before proceeding to the next step, please make sure all drivers
                                                are properly installed.
                                                <a
                                                    href={HelpResources.get().WIZARDINSTALLDRIVERS.href}
                                                    title={HelpResources.get().WIZARDINSTALLDRIVERS.title}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <HelpOutlineIcon />
                                                </a>
                                            </StriimTypography>
                                        </Grid>
                                    </Grid>
                                )}
                                <Box>
                                    <Formik
                                        initialValues={{
                                            name: createAppStore.initialName,
                                            nsName:
                                                createAppStore.namespaceFromAppName ||
                                                currentAdapter ||
                                                createAppStore.initialNamespace,
                                            password: ""
                                        }}
                                        initialTouched={{
                                            name: true
                                        }}
                                        validationSchema={getValidationSchema(
                                            createAppStore.createFromScratch,
                                            entityType
                                        )}
                                        validateOnChange
                                        onSubmit={handleSubmit}
                                    >
                                        <Grid container justifyContent="space-around">
                                            <Grid item>
                                                <Box
                                                    component={Form}
                                                    className={classes.form}
                                                    style={{ overflow: "visible" }}
                                                >
                                                    <Grid className={classes.headingContainer}>
                                                        <StriimTypography
                                                            variant="h2"
                                                            align="center"
                                                            className={classes.heading}
                                                            data-test-id="create-app-header"
                                                        >
                                                            {title}
                                                        </StriimTypography>
                                                        {isILCDC && (
                                                            <StriimTypography
                                                                variant={"body4"}
                                                                hidden={!isILCDC}
                                                                className={classes.subtitle}
                                                            >
                                                                This wizard creates and runs 2 Apps to sync your data
                                                                from {sourceTitle} to {targetTitle}: one App for initial
                                                                load (IL) and another App for CDC.
                                                                <ul>
                                                                    <li>
                                                                        The IL App will run first and create schemas in
                                                                        {targetTitle} that match the schemas in{" "}
                                                                        {sourceTitle}. It will then load the existing
                                                                        table rows from your {sourceTitle} database to{" "}
                                                                        {targetTitle}.
                                                                    </li>
                                                                    <li>
                                                                        Once the IL App has successfully loaded all
                                                                        existing table rows to {targetTitle}, the CDC
                                                                        App will automatically start reading the table
                                                                        changes in {sourceTitle} and writing the table
                                                                        changes to {targetTitle}.
                                                                    </li>
                                                                </ul>
                                                            </StriimTypography>
                                                        )}
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        item
                                                        xs={12}
                                                        justifyContent="center"
                                                        className={classes.formContent}
                                                    >
                                                        {createAppStore.createFromScratch && (
                                                            <Box
                                                                required
                                                                component={FormikField}
                                                                disabled={createAppStore.inProgress}
                                                                name="name"
                                                                label="Name"
                                                                className={classes.formInput}
                                                            />
                                                        )}
                                                        <Box mb={2} width="100%">
                                                            <NamespaceSelect
                                                                required
                                                                name="nsName"
                                                                label="Namespace"
                                                                disabled={
                                                                    createAppStore.inProgress ||
                                                                    (!createAppStore.createFromScratch &&
                                                                        (!!createAppStore.namespaceFromTql ||
                                                                            !!createAppStore.namespaceFromAppName))
                                                                }
                                                                doNotCreateNamespaces
                                                                namespaceOptions={namespaceOpts}
                                                            />
                                                        </Box>
                                                        {!createAppStore.createFromScratch && (
                                                            <Box
                                                                component={FormikField}
                                                                name="password"
                                                                label="Passphrase for passwords"
                                                                type="password"
                                                                disabled={createAppStore.inProgress}
                                                                helperText="All passwords are encrypted using a passphrase during export. If this application does not have any encrypted passwords, leave this value empty."
                                                                className={classes.formInput}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        className={classes.bottomButtonsContainer}
                                                        alignItems="center"
                                                        justifyContent="flex-end"
                                                    >
                                                        <Box
                                                            className={classes.bottomButton}
                                                            component={StriimButton}
                                                            variant="secondary"
                                                            data-test-id="cancel-app-button"
                                                            disabled={createAppStore.inProgress}
                                                            onClick={goToAppsList}
                                                        >
                                                            Cancel
                                                        </Box>
                                                        <Box
                                                            className={classes.bottomButton}
                                                            component={StriimButton}
                                                            type="submit"
                                                            variant="primary"
                                                            data-test-id={
                                                                createAppStore.createFromScratch
                                                                    ? "submit-app-button"
                                                                    : "import-app-button"
                                                            }
                                                            disabled={createAppStore.inProgress}
                                                            onClick={trackEventClicked}
                                                        >
                                                            {createAppStore.createFromScratch
                                                                ? createAppStore.createFromWizard
                                                                    ? `Next`
                                                                    : `Save`
                                                                : `Import`}
                                                        </Box>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Formik>
                                </Box>
                            </div>
                        )}
                    </>
                );
            }}
        </Observer>
    );
};

export default NameApp;
