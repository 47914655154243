import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { Box, Grid } from "@mui/material";
import { StriimButton, StriimModal, StriimTypography } from "@striim/striim-ui-v2";

import { styles } from "../guardian.styles";
import JobDetailsHeader from "../components/job-details-header/job-details-header";
import CollapsibleHeading from "../components/collapsible-heading/collapsible-heading";
import AI_ROUTES from "../../routes.json";
import dictionary from "../../../../../app/components/common/helpable/online-help-dictionary";
import growl from "../../../../../app/components/common/growl";
import LoadingIndicator from "../../../../generic/loading-indicator";
import guardianService from "../guardian-service";
import { Job, JobStatuses } from "../guardian-job.types";
import JobListInsightsView, { TABS } from "../components/job-list-insights-view/job-list-insights-view";
import ViewAllPage from "../components/view-all-page/view-all-page";
import metaStoreService from "../../../../../core/services/metaStoreService/meta-store-service";
import metaObjectHelper from "../../../../../app/components/common/editor/meta-object-editors/metaObjectHelper";
import ViewAllContent, { getViewAllTitle, viewAllTypes } from "../components/view-all-page/view-all-content";
import { fetchSherlockGlobalReportData } from "../../reports/reports.helper";
import useStores from "../../../../utils/use-stores";
import SelectedSourceAppsHeading from "../components/selected-source-apps/selected-source-apps-heading";
import { getAdapterObject } from "../../reports/components/report-tables/sherlock-report-tables";

export type AppDataStatus = {
    sensitiveData: number;
    nonSensitiveData: number;
    errors: number;
    totalSources: number;
    sourcesWithPII: number;
    totalApps: number;
};

export type ViewType = { type: string; sensitiveDataIdentifier?: string };

const getJobCompletedTime = (jobs: Job[]): number => {
    return Number([...jobs].sort((x, y) => y?.completedTime - x?.completedTime)[0].completedTime / 1000);
};

const GuardianViewApp = ({}) => {
    const { jobName } = useParams<{ jobName: string }>();
    const [jobs, setJobs] = useState<Job[]>([]);
    const [job, setJob] = useState<Job>({});
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const [appDataStatus, setAppDataStatus] = useState<AppDataStatus>();
    const [viewAllView, setViewAllView] = useState<boolean>(false);
    const [viewType, setViewType] = useState<ViewType>({ type: "" });
    const [appsDetails, setAppsDetails] = useState([]);
    const [allTableData, setAllTableData] = useState([]);
    const [appsWithSDI, setAppsWithSDI] = useState([]);
    const [defaultSelectedTab, setDefaultSelectedTab] = useState(TABS.LIST);
    const { store } = useStores();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [cancelJob, setCancelJob] = useState(false);
    const intervalIDRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const [sources, setSources] = useState([]);
    const [selectedSource, setSelectedSource] = useState(sources[0]);
    const [appsInfoData, setAppsInfoData] = useState([]);

    const viewAllTitle = getViewAllTitle(viewType);

    let hasError = false,
        isPending = true,
        hasCompleted = false;

    const getSourceInfo = (sourcesList) => {
        const sourcesInfo = [];

        sourcesList.forEach((source, index) => {
            const DatabaseProviderTypes = source?.sourceTypeMetrics?.DatabaseProviderTypes;
            const DatabaseProviderTypeInfo = !DatabaseProviderTypes
                ? ""
                : DatabaseProviderTypes.length > 1
                    ? "Default"
                    : DatabaseProviderTypes[0];
            const sourceObject = getAdapterObject(
                metaStoreService.entities.SOURCE,
                {
                    DatabaseProviderType: DatabaseProviderTypeInfo,
                    adapterName: source.sourceType
                }
            );
            const sourceIcon = metaObjectHelper.getIconClassByMetaObject(
                sourceObject
            );

            sourcesInfo.push({
                source: {
                    name: source.sourceType,
                    className: sourceIcon
                },
                sensitiveApps: source?.sourceTypeMetrics?.numSensitiveApps || 0,
                sensitiveEntities: source?.sourceTypeMetrics?.numSensitiveEntities || 0,
                id: index
            });
        });

        setAppsDetails(sourcesInfo);
    };

    const getJobDetails = async () => {
        try {
            const result = await guardianService.getReportForJob(jobName);
            const jobInfo = await guardianService.getJob(jobName.split(".")[1], jobName.split(".")[0]);
            const sourcesListData = await guardianService.getSourceTypeInfo(jobName);
            const appsListData = await guardianService.getAppInfoBySourceType(jobName);
            const sourcesList = JSON.parse(sourcesListData);
            const jobDetails = JSON.parse(result);
            const appsInfoDataObject = JSON.parse(appsListData);

            setJob(jobInfo[0]);
            setSources(sourcesList.map(source => source.sourceType));
            setAppsInfoData(appsInfoDataObject);

            getSourceInfo(sourcesList);

            const jobMetrics = jobDetails.reduce(
                (acc, item) => {
                    const { piiData, appName, status, sourceName } = item;
                    let piiDataParsed = {};
                    let isPII = false;

                    try {
                        piiDataParsed = JSON.parse(piiData);
                    } catch (error) {}
                    if (Object.keys(piiDataParsed)?.length) {
                        const entities = Object.keys(piiDataParsed);
                        isPII = entities.some(entity => {
                            if (piiDataParsed[entity]) {
                                let entityPIIData = {};
                                try {
                                    entityPIIData = JSON.parse(piiDataParsed[entity]);
                                } catch (error) {
                                    console.error(error);
                                }
                                return entityPIIData["Number Of PII Fields"];
                            }
                            return false;
                        });
                    }

                    const isNonPII = !isPII;
                    const isError = status === "ERROR";
                    const isCompleted = status === "COMPLETED";

                    // Add to sourceList if not already present
                    acc.sourceList.add(sourceName);

                    // Add to sourceWithPII if piiData is not empty
                    if (isPII) {
                        acc.sourceWithPII.add(sourceName);
                    }

                    // Handle errors
                    if (isError) {
                        acc.errorData.add(appName);
                        // Remove from sensitiveData and nonSensitiveData if present
                        acc.sensitiveData.delete(appName);
                        acc.nonSensitiveData.delete(appName);
                    } else {
                        // Handle sensitive and non-sensitive data
                        if (isPII && isCompleted) {
                            acc.sensitiveData.add(appName);
                            // Remove from nonSensitiveData if present
                            acc.nonSensitiveData.delete(appName);
                        } else if (isNonPII && isCompleted) {
                            if (!acc.sensitiveData.has(appName)) {
                                acc.nonSensitiveData.add(appName);
                            }
                        }
                    }

                    // Add to totalApps if not already present
                    acc.totalApps.add(appName);

                    return acc;
                },
                {
                    sensitiveData: new Set(),
                    nonSensitiveData: new Set(),
                    errorData: new Set(),
                    sourceList: new Set(),
                    sourceWithPII: new Set(),
                    totalApps: new Set()
                }
            );

            setAppDataStatus({
                sensitiveData: jobMetrics.sensitiveData.size,
                nonSensitiveData: jobMetrics.nonSensitiveData.size,
                errors: jobMetrics.errorData.size,
                totalSources: jobMetrics.sourceList.size,
                sourcesWithPII: jobMetrics.sourceWithPII.size,
                totalApps: jobMetrics.totalApps.size
            });
            hasError = jobDetails.some(job => job.status === JobStatuses.ERROR);
            isPending =
                jobInfo?.some(job => [JobStatuses.CREATED, JobStatuses.RUNNING].includes(job.status)) ||
                !jobDetails.length ||
                jobDetails.some(job => [JobStatuses.CREATED, JobStatuses.RUNNING].includes(job.status));
            hasCompleted = !hasError && !isPending;
            setJobs(jobDetails);
        } catch (error) {
            growl.error(error);
        } finally {
            setLoading(false);
        }
    };

    const checkJobDetails = () => {
        getJobDetails();

        intervalIDRef.current = setInterval(() => {
            isPending ? getJobDetails() : intervalIDRef?.current && clearInterval(intervalIDRef.current);
        }, 5000);
    };

    useEffect(() => {
        checkJobDetails();

        return () => {
            intervalIDRef?.current && clearInterval(intervalIDRef.current);
        };
    }, []);

    const updateDescription = async updatedDescription => {
        try {
            await guardianService.updateJobDescription(job.name, job.nsName, updatedDescription, { ...job });
            const jobInfo = await guardianService.getJob(job.name, job.nsName);
            setJob(jobInfo[0]);
        } catch (error) {
            growl.error("Error updating description", error);
        }
    };

    const handleRetry = async () => {
        setLoading(true);

        try {
            await guardianService.retryPiiJob(`${job.nsName}.${job.name}`);
            checkJobDetails();
        } catch (error) {
            growl.error("Retry error", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);

        try {
            await guardianService.deleteJob(job.name, job.nsName);
            growl.success("Report deleted successfully.");
            setShowDeleteModal(false);
        } catch (error) {
            growl.error("Delete report error", error);
        } finally {
            navigate(AI_ROUTES.sherlockAI);
            setLoading(false);
        }
    };

    const handleCancel = async () => {
        setLoading(true);

        try {
            await guardianService.cancelPIIJob(`${job.nsName}.${job.name}`)
            growl.success("Report cancelled successfully.");
            setShowDeleteModal(false);
        } catch (error) {
            growl.error("Cancel report error", error);
        } finally {
            navigate(AI_ROUTES.sherlockAI);
            setLoading(false);
        }
    }

    const hasJobError = jobs.some(job => job.status === JobStatuses.ERROR);
    const isJobPending = jobs.length
        ? jobs.some(job => [JobStatuses.CREATED, JobStatuses.RUNNING].includes(job.status))
        : !jobs.length
        ? true
        : false;
    const jobStatus = isJobPending ? JobStatuses.RUNNING : hasJobError ? JobStatuses.ERROR : JobStatuses.COMPLETED;
    const errorMessages = hasJobError
        ? jobs.filter(job => job.status === JobStatuses.ERROR).map(job => job.otherDetails || "")
        : [];
    const completedTime = !isJobPending ? getJobCompletedTime(jobs) : null;
    const generationTime = Number(jobs[0]?.generationTime / 1000);
    const isSelectedSourcesList = viewAllTitle === "Apps using the Source:";
    const viewAllPageBackAction = isSelectedSourcesList
        ? () => {
            setViewType({ type: viewAllTypes.topSources });
            setViewAllView(true);
        }
        : () => {
            setDefaultSelectedTab(TABS.INSIGHTS);
            setViewAllView(false);
        }

    const SourcesListeHeading =
        <SelectedSourceAppsHeading
            name={`${viewAllTitle} ${selectedSource}`}
            subtitle={`REPORT NAME: ${jobName}`}
            options={sources}
            onItemClick={setSelectedSource}
        />;

    return (
        <Grid container sx={styles.guardianContainer} flexDirection={"column"}>
            {loading ? (
                <LoadingIndicator isGlobal={false} />
            ) : viewAllView ? (
                <ViewAllPage
                    name={viewAllTitle}
                    subtitle={`REPORT NAME: ${jobName}`}
                    setViewAllView={setViewAllView}
                    completedTime={completedTime}
                    setDefaultSelectedTab={setDefaultSelectedTab}
                    customHeading={isSelectedSourcesList ? SourcesListeHeading : null}
                    onBackAction={viewAllPageBackAction}
                >
                    <ViewAllContent
                        viewType={viewType}
                        appDataStatus={appDataStatus}
                        appsDetails={appsDetails}
                        allTableData={allTableData}
                        appsWithSDI={appsWithSDI}
                        jobName={jobName}
                        setViewType={setViewType}
                        setViewAllView={setViewAllView}
                        setSelectedSource={setSelectedSource}
                        selectedSource={selectedSource}
                        appsInfoData={appsInfoData}
                    />
                </ViewAllPage>
            ) : (
                <>
                    <JobDetailsHeader
                        name={jobName}
                        onBack={() => navigate(AI_ROUTES.sherlockAI)}
                        onDownload={() => {}}
                        generationTime={generationTime}
                        completedTime={completedTime}
                        showRetry={jobStatus === JobStatuses.ERROR}
                        description={job?.description || ""}
                        onRetry={handleRetry}
                        status={jobStatus}
                        updateDescription={updateDescription}
                        fetchReportData={async () => {
                            const data = await fetchSherlockGlobalReportData(jobs, store.apps, jobName);
                            const timeRange = { from: job?.startTime, to: job?.completedTime };
                            return {
                                ...data,
                                appDataStatus,
                                appsDetails,
                                jobStatus,
                                timeRange
                            };
                        }}
                        onDelete={() => setShowDeleteModal(true)}
                        onCancel={() => {
                            setCancelJob(true);
                            setShowDeleteModal(true)
                        }}
                    />
                    <JobDetailsScrollWrapper>
                        {actionTriggered => (
                            <>
                                {isJobPending ? (
                                    <Grid container flexDirection={"column"} gap={1} p={3}>
                                        <StriimTypography variant="h3" color="primary.700">
                                            Discovering Sensitive Data
                                        </StriimTypography>
                                        <StriimTypography variant="body4" color="greyscale.800" maxWidth={600}>
                                            Sherlock is analyzing a sample of the sources in your apps.
                                        </StriimTypography>
                                        <StriimTypography variant="body4" color="greyscale.800">
                                            This discovery may take up to several minutes depending on data volumes and
                                            hardware (CPU/GPU) configuration. Sampling is used.
                                        </StriimTypography>
                                    </Grid>
                                ) : (
                                    <Grid p={2} sx={styles.collapsibleSection}>
                                        <CollapsibleHeading
                                            title="How to use these results"
                                            defaultOpen={true}
                                            url={dictionary.get()["AI_INSIGHTS_SHERLOCK"].href}
                                            externalTrigger={actionTriggered}
                                        >
                                            Sherlock detected sensitive data in your apps. Its AI engines may
                                            occasionally misclassify data. You can go to the app and add Sentinel to
                                            detect and protect sensitive data in real time.
                                        </CollapsibleHeading>
                                    </Grid>
                                )}
                                <Grid
                                    container
                                    flex={1}
                                    px={4}
                                    py={3}
                                    flexDirection={"column"}
                                    height={"calc(100% - 106px)"}
                                >
                                    <Grid container width={"100%"}>
                                        <JobListInsightsView
                                            jobs={jobs}
                                            appDataStatus={appDataStatus}
                                            status={jobStatus}
                                            setViewAllView={setViewAllView}
                                            setViewType={setViewType}
                                            appsDetails={appsDetails}
                                            setAllTableData={setAllTableData}
                                            setAppsWithSDI={setAppsWithSDI}
                                            completedTime={completedTime}
                                            defaultSelectedTab={defaultSelectedTab}
                                            isJobPending={isJobPending}
                                            errorMessages={errorMessages}
                                            jobName={jobName}
                                            handleRetry={handleRetry}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </JobDetailsScrollWrapper>
                </>
            )}

            {showDeleteModal && (
                <StriimModal
                    autoHeight
                    isVisible={showDeleteModal}
                    titleContent={
                        <Box>
                            <StriimTypography variant="h2" color="primary.700">
                                {`${cancelJob ? 'Cancel' : 'Delete'} ${jobName}`}
                            </StriimTypography>
                        </Box>
                    }
                    onCancel={() => {
                        setShowDeleteModal(false);
                        setCancelJob(false);
                    }}
                    size="small"
                    footerContent={
                        <Grid container justifyContent="flex-end">
                            <StriimButton
                                onClick={() => {
                                    setCancelJob(false);
                                    setShowDeleteModal(false);
                                }}
                                data-test-id="delete-report-modal-cancel-button"
                                variant="text"
                                sx={{ marginRight: 1 }}
                            >
                                Return to Report
                            </StriimButton>

                            <StriimButton
                                onClick={cancelJob
                                    ? () => handleCancel()
                                    : () => handleDelete()
                                }
                                data-test-id="delete-report-modal-confirm-button"
                                variant="error"
                            >
                                {cancelJob ? "Cancel Report" : "Delete Report"}
                            </StriimButton>
                        </Grid>
                    }
                >
                    <Grid container alignItems="center" mt={2} justifyContent="flext-start">
                        <StriimTypography variant="body4" color="greyscale.900">
                            Are you sure you want to {!cancelJob ? "delete" : "cancel"}? If you proceed, your
                            report will be {!cancelJob ? "deleted" : "cancelled"}. This action cannot be undone.
                        </StriimTypography>
                    </Grid>
                </StriimModal>
            )}
        </Grid>
    );
};

const JobDetailsScrollWrapper = ({ children }) => {
    const scrollRef = useRef<HTMLDivElement>();
    const [actionTriggered, setActionTriggered] = useState<boolean>(false);

    useEffect(() => {
        const handleScroll = () => {
            setActionTriggered(true);
        };

        scrollRef.current?.addEventListener("scroll", handleScroll);

        return () => {
            scrollRef.current?.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Grid ref={scrollRef} container flexDirection={"column"} flex={1} sx={styles.guardianContentWrapper}>
            {children(actionTriggered)}
        </Grid>
    );
};

export default GuardianViewApp;
