import Backbone from "backbone";
import _ from "underscore";
import $ from "jquery";
import api from "core/api/api";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";
import $__core_services_metaStoreService_meta_objects from "core/services/metaStoreService/meta-objects";

var module = {}; //core/services/metaStoreService/metaStoreService
module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "FLOW",
        objects: Object,
        flowStatus: String,
    },

    fetchObjects: function (deep) {
        var $def = $.Deferred();
        var that = this;

        api.getApplicationComponents(this.id, deep)
            .then(function(objects) {
                var collection = that._getCollectionFromObjects(objects);
                $def.resolve(collection);
            })
            .fail(function (e) {
                $def.reject(e);
            });
        return $def;
    },

    getApplicationComponentsByType: function (type, deep) {
        var $def = $.Deferred();
        var that = this;
        api.getApplicationComponentsByType(this.get("id"), type, deep === true)
            .then(function(objects) {
                var collection = that._getCollectionFromObjects(objects);
                $def.resolve(collection);
            })
            .fail(function (e) {
                $def.reject(e);
            });
        return $def;
    },
    getApplicationComponentsByTypes: function (types, deep) {
        var $def = $.Deferred();
        var that = this;
        api.getApplicationComponentsByTypes(this.get("id"), types, deep === true)
            .then(function(objects) {
                var collection = that._getCollectionFromObjects(objects);
                $def.resolve(collection);
            })
            .fail(function (e) {
                $def.reject(e);
            });
        return $def;
    },


    _getCollectionFromObjects: function (objects) {
        var metaObjects = $__core_services_metaStoreService_meta_objects;
        var returnCollection = new Backbone.Collection();
        _.each(objects, function (object) {
            var metaObject = metaObjects[object.type];
            if (metaObject) {
                var Model = metaObject.Model;
                var model = new Model(object);
                returnCollection.add(model);
            }
        });
        return returnCollection;
    },
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model,
});

export default module;
