var VERSION;
try {
    VERSION = WA_VERSION;
} catch (error) {
    console.log("WA_VERSION is not defined: ", error);
}

function getDefaultRootPathForHost(host) {
    host = host || window.location.host;
    switch (true) {
        case /us-striim.app/.test(host):
        case /stg-az-striimcloudapp.com/.test(host):
            return "https://www.striim.com/docs/Azure/StriimCloud/" + getDocVersion();

        case /striim.app/.test(host): // testing for striim.app has to come in the last "case" statement
        case /stg-striimcloudapp.com/.test(host):
        case /dev-striimcloudapp.com/.test(host):
            return "https://www.striim.com/docs/GCP/StriimCloud/" + getDocVersion();

        default:
            return "/onlinedocs";
    }
}
function getIndexHTMLPath() {
    return ROOT_PATH + "/en/index-en.html";
}

function getRootPath() {
    return ROOT_PATH + "/index.html";
}

function getPDFPath() {
    return ROOT_PATH + "/Striim_documentation.pdf";
}

function getDocVersion(version) {
    version = version || VERSION;
    let versionsArray = version.split(".");
    let cleanedVersionArray = versionsArray.map(entry => {
        if (entry.length > 0) {
            return entry[0];
        }
        return entry;
    });
    // 3 for webui, 2 for Striim UI I guess.
    const docsVersionNumber = cleanedVersionArray.slice(0, 3).join(".");
    return docsVersionNumber;
}
let ROOT_PATH = getDefaultRootPathForHost();

let dictionary = {
    get: function() {
        return {
            DEFAULT: {
                title: "Online Docs",
                href: getIndexHTMLPath() + ""
            },
            APPS: {
                title: "Apps Guide",
                href: getIndexHTMLPath() + "?contextId=page_apps"
            },
            WIZARD: {
                title: "App Wizard Guide",
                href: getIndexHTMLPath() + "?contextId=page_appwizard"
            },
            WIZARDSOURCE: {
                title: "App Wizard Source Guide",
                href: getIndexHTMLPath() + "?contextId=prog_config_app_template_source"
            },
            WIZARDTARGET: {
                title: "App Wizard Target Guide",
                href: getIndexHTMLPath() + "?contextId=prog_config_app_template_target"
            },
            WIZARDSOURCE_HubSpot: {
                href: getIndexHTMLPath() + "?contextId=scai_source_hubspot_11"
            },
            WIZARDSOURCE_Stripe: {
                href: getIndexHTMLPath() + "?contextId=scai_source_stripe_11"
            },
            WIZARDSOURCE_Zendesk: {
                href: getIndexHTMLPath() + "?contextId=scai_source_zendesk_11"
            },
            WIZARDTARGET_BigQuery: {
                href: getIndexHTMLPath() + "?contextId=scai_target_bigquery_11"
            },
            WIZARDINSTALLDRIVERS: {
                title: "Installing third-party drivers",
                href: getIndexHTMLPath() + "?contextId=config_install_drivers "
            },
            METADATAMANAGER: {
                title: "Metadata Manager Guide",
                href: getIndexHTMLPath() + "?contextId=page_metadata_manager"
            },
            MONITOR: {
                title: "Monitor Guide",
                href: getIndexHTMLPath() + "?contextId=page_monitor"
            },
            DASHBOARDS: {
                title: "Dashboards Guide",
                href: getIndexHTMLPath() + "?contextId=page_dashboards"
            },
            "DASHBOARD-ColumnRangeChart": {
                title: "Dashboards Guide",
                href: getIndexHTMLPath() + "?contextId=page_dashboards"
            },
            "DASHBOARD-SelectVisualization": {
                title: "Dashboards Guide",
                href: getIndexHTMLPath() + "?contextId=page_dashboards"
            },
            "DASHBOARD-PredictiveChart": {
                title: "Dashboards Guide",
                href: getIndexHTMLPath() + "?contextId=vis_props_line_chart"
            },
            "DASHBOARD-ImageMap": {
                title: "Dashboards Guide",
                href: getIndexHTMLPath() + "?contextId=vis_props_vector_map"
            },
            "DASHBOARD-BarChart": {
                title: "Building Bar Charts",
                href: getIndexHTMLPath() + "?contextId=vis_props_barchart"
            },
            "DASHBOARD-Choropleth": {
                title: "Building Choropleth Maps",
                href: getIndexHTMLPath() + "?contextId=vis_props_chloropleth_map"
            },
            "DASHBOARD-Gauge": {
                title: "Building Gauge Charts",
                href: getIndexHTMLPath() + "?contextId=vis_props_gauge"
            },
            "DASHBOARD-HeatMap": {
                title: "Building Heat Maps",
                href: getIndexHTMLPath() + "?contextId=vis_props_heat_map"
            },
            "DASHBOARD-Icon": {
                title: "Building Icon Maps",
                href: getIndexHTMLPath() + "?contextId=vis_props_icon"
            },
            "DASHBOARD-LeafletMap": {
                title: "Building Leaflet Maps",
                href: getIndexHTMLPath() + "?contextId=vis_props_leaflet_map"
            },
            "DASHBOARD-LineChart": {
                title: "Building Line, Scatter and Bubble charts",
                href: getIndexHTMLPath() + "?contextId=vis_props_line_chart"
            },
            "DASHBOARD-AreaChart": {
                title: "Building Line, Scatter and Bubble charts",
                href: getIndexHTMLPath() + "?contextId=vis_props_line_chart"
            },
            "DASHBOARD-ScatterPlot": {
                title: "Building Line, Scatter and Bubble charts",
                href: getIndexHTMLPath() + "?contextId=vis_props_scatter_chart"
            },
            "DASHBOARD-Bubble": {
                title: "Building Line, Scatter and Bubble charts",
                href: getIndexHTMLPath() + "?contextId=vis_props_bubble_chart"
            },
            "DASHBOARD-PieChart": {
                title: "Building Pie and Donut charts",
                href: getIndexHTMLPath() + "?contextId=vis_props_pie_chart"
            },
            "DASHBOARD-DonutChart": {
                title: "Building Pie and Donut charts",
                href: getIndexHTMLPath() + "?contextId=vis_props_donut_chart"
            },
            "DASHBOARD-Table": {
                title: "Building Table Visualizations",
                href: getIndexHTMLPath() + "?contextId=vis_props_table"
            },
            "DASHBOARD-Value": {
                title: "Building Value Visualizations",
                href: getIndexHTMLPath() + "?contextId=vis_props_value"
            },
            "DASHBOARD-Label": {
                title: "Building Label Visualizations",
                href: getIndexHTMLPath() + "?contextId=vis_props_value"
            },
            "DASHBOARD-VectorMap": {
                title: "Building Vector Maps",
                href: getIndexHTMLPath() + "?contextId=vis_props_vector_map"
            },
            "DASHBOARD-WordCloud": {
                title: "Building Word Clouds",
                href: getIndexHTMLPath() + "?contextId=vis_props_word_cloud"
            },
            "APPS-ADAPTER-PARSER-DEFAULT": {
                href: getIndexHTMLPath() + "?contextId=adp_parsers"
            },
            "APPS-ADAPTER-READERS-DEFAULT": {
                href: getIndexHTMLPath() + "?contextId=adp_readers"
            },
            "APPS-ADAPTER-WRITERS-DEFAULT": {
                href: getIndexHTMLPath() + "?contextId=adp_writers"
            },
            "APPS-ADAPTER-FORMATTERS-DEFAULT": {
                href: getIndexHTMLPath() + "?contextId=adp_formatters"
            },
            PROPERTYTEMPLATE_DatabaseReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_databasereader"
            },
            PROPERTYTEMPLATE_FileReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_filereader"
            },
            PROPERTYTEMPLATE_GGTrailReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_ggtrailreader"
            },
            PROPERTYTEMPLATE_HDFSReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_hdfsreader"
            },
            PROPERTYTEMPLATE_HPNonStopEnscribeReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_hpnonstopreaders"
            },
            PROPERTYTEMPLATE_HPNonStopSQLMPReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_hpnonstopreaders"
            },
            PROPERTYTEMPLATE_HPNonStopSQLMXReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_hpnonstopreaders"
            },
            PROPERTYTEMPLATE_HTTPReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_httpreader"
            },
            PROPERTYTEMPLATE_JMSReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_jmsreader"
            },
            "PROPERTYTEMPLATE_KafkaReader_0.8.0": {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_kafkareader"
            },
            "PROPERTYTEMPLATE_KafkaReader_0.9.0": {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_kafkareader"
            },
            "PROPERTYTEMPLATE_KafkaReader_0.10.0": {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_kafkareader"
            },
            "PROPERTYTEMPLATE_KafkaReader_0.11.0": {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_kafkareader"
            },
            "PROPERTYTEMPLATE_KafkaReader_2.1.0": {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_kafkareader"
            },
            PROPERTYTEMPLATE_MongoDBReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_mongodbreader"
            },
            PROPERTYTEMPLATE_MQTTReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_mqttreader"
            },
            PROPERTYTEMPLATE_MSSqlReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_mssqlreader"
            },
            PROPERTYTEMPLATE_MSJet: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_msjet"
            },
            PROPERTYTEMPLATE_MapRFSReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_maprfsreader"
            },
            PROPERTYTEMPLATE_MultiFileReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_multifilereader"
            },
            PROPERTYTEMPLATE_MysqlReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_mysqlreader"
            },
            PROPERTYTEMPLATE_MariaDBReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_mariadbreader"
            },
            PROPERTYTEMPLATE_MariaDBXpandReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_mariadbreader"
            },
            PROPERTYTEMPLATE_OPCUAReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_opcuareader"
            },
            PROPERTYTEMPLATE_OracleReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_oraclereader"
            },
            PROPERTYTEMPLATE_S3Reader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_s3reader"
            },
            PROPERTYTEMPLATE_ServiceNowReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_servicenowreader"
            },
            PROPERTYTEMPLATE_SalesForceReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_salesforcereader"
            },
            PROPERTYTEMPLATE_ZendeskReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_zendeskreader"
            },
            PROPERTYTEMPLATE_SalesforcePardotReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_salesforcepardotreader"
            },
            PROPERTYTEMPLATE_GoogleAdsReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_googleadsreader"
            },
            PROPERTYTEMPLATE_SalesforceCDCReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_salesforcecdcreader"
            },
            PROPERTYTEMPLATE_HubSpotReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_hubspotreader"
            },
            PROPERTYTEMPLATE_SalesForcePlatformEventReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_salesforceplatformeventreader"
            },
            PROPERTYTEMPLATE_SalesForcePushTopicReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_salesforcepushtopicreader"
            },
            PROPERTYTEMPLATE_SalesforceWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_salesforcewriter"
            },
            PROPERTYTEMPLATE_SalesforceMarketingCloudWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_salesforcemarketingcloudwriter"
            },
            PROPERTYTEMPLATE_ServiceNowWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_salesforcewriter"
            },
            PROPERTYTEMPLATE_TCPReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_tcpreader"
            },
            PROPERTYTEMPLATE_UDPReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_udpreader"
            },
            PROPERTYTEMPLATE_WindowsEventLogReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_windowseventlogreader"
            },
            PROPERTYTEMPLATE_JMXReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_jmxreader"
            },
            PROPERTYTEMPLATE_ContinuousDataGenerator: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_continuousdatagenerator"
            },
            PROPERTYTEMPLATE_GCSReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_gcsreader"
            },
            PROPERTYTEMPLATE_AALParser: {
                href: getIndexHTMLPath() + "?contextId=adp_parser_props_aalparser"
            },
            PROPERTYTEMPLATE_AvroParser: {
                href: getIndexHTMLPath() + "?contextId=adp_parser_props_avroparser"
            },
            PROPERTYTEMPLATE_BinaryParser: {
                href: getIndexHTMLPath() + "?contextId=adp_parser_props_binaryparser"
            },
            PROPERTYTEMPLATE_CollectdParser: {
                href: getIndexHTMLPath() + "?contextId=adp_parser_props_collectdparser"
            },
            PROPERTYTEMPLATE_DSVParser: {
                href: getIndexHTMLPath() + "?contextId=adp_parser_props_dsvparser"
            },
            PROPERTYTEMPLATE_FreeFormTextParser: {
                href: getIndexHTMLPath() + "?contextId=adp_parser_props_freeformtextparser"
            },
            PROPERTYTEMPLATE_GGTrailParser: {
                href: getIndexHTMLPath() + "?contextId=adp_parser_props_ggtrailparser"
            },
            PROPERTYTEMPLATE_JSONParser: {
                href: getIndexHTMLPath() + "?contextId=adp_parser_props_jsonparser"
            },
            PROPERTYTEMPLATE_NVPParser: {
                href: getIndexHTMLPath() + "?contextId=adp_parser_props_nvpparser"
            },
            PROPERTYTEMPLATE_NetflowParser: {
                href: getIndexHTMLPath() + "?contextId=adp_parser_props_netflowparser"
            },
            PROPERTYTEMPLATE_SNMPParser: {
                href: getIndexHTMLPath() + "?contextId=adp_parser_props_snmpparser"
            },
            PROPERTYTEMPLATE_StriimParser: {
                href: getIndexHTMLPath() + "?contextId=adp_parser_props_striimparser"
            },
            PROPERTYTEMPLATE_XMLParser: {
                href: getIndexHTMLPath() + "?contextId=adp_parser_props_xmlparser"
            },
            PROPERTYTEMPLATE_XMLParserV2: {
                href: getIndexHTMLPath() + "?contextId=adp_parser_props_xmlparserv2"
            },
            PROPERTYTEMPLATE_AzureBlobWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_azureblobwriter"
            },
            PROPERTYTEMPLATE_PostgreSQLReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_postgresqlreader"
            },
            PROPERTYTEMPLATE_AzureEventHubWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_azureeventhubwriter"
            },
            PROPERTYTEMPLATE_SnowflakeWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_snowflakewriter"
            },
            PROPERTYTEMPLATE_SpannerWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_spannerwriter"
            },
            PROPERTYTEMPLATE_BigQueryWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_bigquerywriter"
            },
            PROPERTYTEMPLATE_ClouderaHiveWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_clouderahivewriter"
            },
            PROPERTYTEMPLATE_GooglePubSubWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_google_pubsub_writer"
            },
            PROPERTYTEMPLATE_CosmosDBWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_cosmosdbwriter"
            },
            PROPERTYTEMPLATE_DatabaseWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_databasewriter"
            },
            PROPERTYTEMPLATE_EmailAdapter: {
                href: getIndexHTMLPath() + "?contextId=pg_fundamentals_sendingAlerts"
            },
            PROPERTYTEMPLATE_FileWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_filewriter"
            },
            PROPERTYTEMPLATE_GCSWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_gcswriter"
            },
            PROPERTYTEMPLATE_HBaseWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_hbasewriter"
            },
            PROPERTYTEMPLATE_HDFSWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_hdfswriter"
            },
            PROPERTYTEMPLATE_HDInsightHDFSWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_hdinsighthdfswriter"
            },
            PROPERTYTEMPLATE_HDInsightKafkaWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_hdinsightkafkawriter"
            },
            PROPERTYTEMPLATE_ADLSReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_adlsgen2reader"
            },
            //DO-NOT-REMOVE-BELOW-COMMENT
            //add-new-adapter
            PROPERTYTEMPLATE_YugabyteReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_yugabytereader"
            },
            PROPERTYTEMPLATE_PaymoReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_paymoreader"
            },
            PROPERTYTEMPLATE_BambooHrReader: {
                href: getIndexHTMLPath() + "?contextId=apps_reader_props_bamboohrreader"
            },
            PROPERTYTEMPLATE_LinkedInAdsReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_linkedinadsreader"
            },
            PROPERTYTEMPLATE_GoogleAnalyticsReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_googleanalyticsreader"
            },
            PROPERTYTEMPLATE_JiraReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_jirareader"
            },
            PROPERTYTEMPLATE_StripeReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_stripereader"
            },
            PROPERTYTEMPLATE_IntercomReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_intercomreader"
            },
            PROPERTYTEMPLATE_HazelcastWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_hazelcastwriter"
            },
            PROPERTYTEMPLATE_HortonworksHiveWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_hortonworkshivewriter"
            },
            PROPERTYTEMPLATE_JMSWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_jmswriter"
            },
            PROPERTYTEMPLATE_JPAWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_jpawriter"
            },
            "PROPERTYTEMPLATE_KafkaWriter_0.9.0": {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_kafkawriter"
            },
            "PROPERTYTEMPLATE_KafkaWriter_0.10.0": {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_kafkawriter"
            },
            "PROPERTYTEMPLATE_KafkaWriter_0.8.0": {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_kafkawriter"
            },
            "PROPERTYTEMPLATE_KafkaWriter_0.11.0": {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_kafkawriter"
            },
            "PROPERTYTEMPLATE_KafkaWriter_2.1.0": {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_kafkawriter"
            },
            PROPERTYTEMPLATE_KinesisWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_kinesiswriter"
            },
            PROPERTYTEMPLATE_KuduWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_kuduwriter"
            },
            PROPERTYTEMPLATE_MQTTWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_mqttwriter"
            },
            PROPERTYTEMPLATE_MapRDBWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_maprdbwriter"
            },
            PROPERTYTEMPLATE_MapRFSWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_maprfswriter"
            },
            PROPERTYTEMPLATE_MapRStreamWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_maprstreamwriter"
            },
            PROPERTYTEMPLATE_RedshiftWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_redshiftwriter"
            },
            PROPERTYTEMPLATE_S3Writer: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_s3writer"
            },
            PROPERTYTEMPLATE_HiveWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_hivewriter"
            },
            PROPERTYTEMPLATE_HTTPWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_httpwriter"
            },
            PROPERTYTEMPLATE_CassandraWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_cassandrawriter"
            },
            PROPERTYTEMPLATE_CassandraCosmosDBWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_cassandracosmosdbwriter"
            },
            PROPERTYTEMPLATE_CosmosDBReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_cosmos_reader"
            },
            PROPERTYTEMPLATE_MongoCosmosDBReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_mongo_cosmos_reader"
            },
            PROPERTYTEMPLATE_MongoCosmosDBWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_mongo_cosmos_writer"
            },
            PROPERTYTEMPLATE_SysOut: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_sysout"
            },
            PROPERTYTEMPLATE_ADLSGen2Writer: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_adlsgen2writer"
            },
            PROPERTYTEMPLATE_WebAlertAdapter: {
                href: getIndexHTMLPath() + "?contextId=pg_fundamentals_sendingAlerts"
            },
            PROPERTYTEMPLATE_DSVFormatter: {
                href: getIndexHTMLPath() + "?contextId=adp_formatter_props_dsvformatter"
            },
            PROPERTYTEMPLATE_AvroFormatter: {
                href: getIndexHTMLPath() + "?contextId=adp_formatter_props_avroformatter"
            },
            PROPERTYTEMPLATE_JSONFormatter: {
                href: getIndexHTMLPath() + "?contextId=adp_formatter_props_jsonformatter"
            },
            PROPERTYTEMPLATE_ParquetFormatter: {
                href: getIndexHTMLPath() + "?contextId=adp_formatter_props_parquetformatter"
            },
            PROPERTYTEMPLATE_XMLFormatter: {
                href: getIndexHTMLPath() + "?contextId=adp_formatter_props_xmlformatter"
            },
            PROPERTYTEMPLATE_AzureSQLDWHWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_azuresqldw"
            },
            PROPERTYTEMPLATE_MongoDBWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_mongowriter"
            },
            PROPERTYTEMPLATE_IncrementalBatchReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_incrementalbatch"
            },
            PROPERTYTEMPLATE_SpannerBatchReader: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_incrementalbatch"
            },
            PROPERTYTEMPLATE_CassandraCosmosDBWrite: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_cassandracosmosdbwriter"
            },
            PROPERTYTEMPLATE_FabricLakehouseFileWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_fabriclakehousefilewriter"
            },
            PROPERTYTEMPLATE_FabricDataWarehouseWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_fabricdatawarehousewriter"
            },
            PROPERTYTEMPLATE_MicrosoftDataverseWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_dataversewriter"
            },
            EXCEPTIONSTORE: {
                href: getIndexHTMLPath() + "?contextId=ddl_create_exceptionstore"
            },
            PROPERTYTEMPLATE_SlackAlertAdapter: {
                href: getIndexHTMLPath() + "?contextId=pg_fundamentals_sendingAlerts"
            },
            PROPERTYTEMPLATE_OJet: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_props_ojet"
            },
            WIZARD_OJet: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_setup_ojet"
            },
            WIZARD_MSJet: {
                href: getIndexHTMLPath() + "?contextId=adp_reader_setup_msjet"
            },
            PROPERTYTEMPLATE_DeltaLakeWriter: {
                href: getIndexHTMLPath() + "?contextId=adp_writer_props_deltalake"
            },
            DISCARDED_EVENTS: {
                href: getIndexHTMLPath() + "?contextId=adp_writers_viewiing_discarded_events"
            },
            MANAGE_STRIIM_FILES: {
                href: getIndexHTMLPath() + "?contextId=page_files"
            },
            MANAGE_STRIIM_METADATA_MANAGER: {
                href: getIndexHTMLPath() + "?contextId=page_metadata_manager"
            },
            MANAGE_STRIIM_PROPERTY_SETS: {
                href: getIndexHTMLPath() + "?contextId=page_property_sets"
            },
            MANAGE_STRIIM_PROPERTY_VARIABLES: {
                href: getIndexHTMLPath() + "?contextId=page_property_variables"
            },
            MANAGE_STRIIM_VAULTS: {
                href: getIndexHTMLPath() + "?contextId=page_vaults"
            },
            MANAGE_STRIIM_KEYS: {
                href: getIndexHTMLPath() + "?contextId=page_shield_profiles"
            },
            MANAGE_STRIIM_EMBEDDING_GENERATOR: {
                href: getIndexHTMLPath() + "?contextId=embedding_generator"
            },
            MANAGE_STRIIM_CONNECTION_PROFILE: {
                href: getIndexHTMLPath() + "?contextId=page_connection_profiles"
            },
            ALERT_CONFIGURE_SMTP: {
                href: getIndexHTMLPath() + "?contextId=alert_setup_email"
            },
            ALERT_CONFIGURE_SLACK: {
                href: getIndexHTMLPath() + "?contextId=alert_setup_slack"
            },
            ALERT_CONFIGURE_TEAMS: {
                href: getIndexHTMLPath() + "?contextId=alert_setup_teams"
            },
            USERS_LDAP_AUTHENTICATION: {
                href: getIndexHTMLPath() + "?contextId=admin_using_ldap_authentication"
            },
            ADVANCED_SETTINGS_ORACLE_PROCESS: {
                href: getIndexHTMLPath() + "?contextId=oraclereader-create-dictionary-file"
            },
            ADVANCED_SETTINGS_ORACLE_HALT: {
                href: getIndexHTMLPath() + "?contextId=oraclereader-create-quiescemarker-table"
            },
            ADVANCED_SETTINGS_MSJET_HALT: {
                href: getIndexHTMLPath() + "?contextId=msjet-create-quiescemarker-table"
            },
            ADVANCED_SETTINGS_POSTGRES_TRACKINGTABLE: {
                href: getIndexHTMLPath() + "?contextId=postgresqlreader-setup-for-schema-evolution"
            },
            AI_INSIGHTS_: {
                // WAITING FOR INFORMATION
                href: getIndexHTMLPath() + "?contextId="
            },
            CREATE_APP_AUTOMATED_PIPELINE: {
                href: getIndexHTMLPath() + "?contextId=prog_create_automated_pipeline"
            },
            AI_INSIGHTS_SHERLOCK: {
                href: getIndexHTMLPath() + "?contextId=sherlock-intro"
            },
            AI_INSIGHTS_SENTINEL: {
                href: getIndexHTMLPath() + "?contextId=sentinel-intro"
            },
            AI_INSIGHTS_ENGINE: {
                href: getIndexHTMLPath() + "?contextId=pii-select-ai-engine"
            }
        };
    },
    getRootPath: getRootPath,
    getDefaultRootPathForHost: getDefaultRootPathForHost,
    getIndexHTMLPath: getIndexHTMLPath,
    getDocVersion: getDocVersion,
    getPDFPath: getPDFPath,
    updateRootPath: function(rootPath) {
        ROOT_PATH = rootPath;
    }
};

export default dictionary;
