import _ from "underscore";
import BaseControl from "./../base-control";
import FieldDataSource from "./../select/field-datasource";
import Column from "./column";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import template from "./columns-list.html";

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "columns-list",
    events: {
        "click @ui.add": "newColumnAdded",
        "click @ui.reset": "columnsReset",
    },
    regions: {
        columns: ".columns div",
    },
    ui: {
        optionsRow: ".addColumn",
        add: ".addColumn .add",
        reset: ".addColumn .reset",
        loader: ".columns .loader",
        errorMessage: ".columns-errors",
    },

    initialize: function (options) {
        this.config = options.transformationConfig || {};

        this.gridView = new Column.CollectionView({
            collection: new Column.Collection([]),
            model: this.model,
            config: this.config,
        });

        this.attachListeners();
    },

    toggleLoader: function (visible) {
        if (this.ui.loader) {
            this.ui.loader.toggleClass("hidden-element", !visible);
        }
    },

    attachListeners: function () {
        this.listenTo(this.gridView, "remove", function (childView) {
            this.gridView.collection.remove(childView.model);
        });

        this.listenTo(this.gridView, "modified", function () {
            this.setValueFromView();
        });
    },

    newColumnAdded: function () {
        this.gridView.collection.push({
            column: "",
            tqlFunction: "",
            alias: "",
            columns: this.columnsDataSource,
        });
        this.gridView.scrollToBottom();
    },

    columnsReset: function () {
        this.setColumns([]);
        this.fillAllColumns();
    },

    fillAllColumns: function() {
        this.columnsDataSource.then(fields => {
            fields.forEach(field => {
                this.gridView.collection.push({
                    column: field.id,
                    tqlFunction: "",
                    alias: field.text,
                    columns: fields,
                    columnType: field.description,
                });
            });

            this.gridView.scrollToBottom();
            this.trigger("value:set", this.getViewValue());
        });
    },

    getViewValue: function () {
        return this.gridView.collection.toJSON();
    },

    hasErrors: function () {
        return this.gridView.collection.hasErrors;
    },

    setViewValue: function () {
        this.setColumns(this.getValue());
    },

    setColumns: function (columns) {
        this.gridView.collection.reset(columns);
        this.setViewEnabled();
    },

    clearColumnsDataSource: function () {
        this.setColumns([]);
        this.columnsDataSource = [];
        this.gridView.setColumnsDataSource([]);
    },

    setStream: function(componentId) {
        metaStoreService.findById(componentId).then(
            function(componentModel) {
                if (!componentModel) {
                    return;
                }
                var fieldName =
                    componentModel.type === metaStoreService.entities.CACHE
                        ? componentModel.typename
                        : componentModel.dataType;

                if (this.config.columnsSource) {
                    if (typeof this.config.columnsSource === "function") {
                        this.columnsDataSource = this.config.columnsSource(componentModel.id, fieldName);
                    } else if (Array.isArray(this.config.columnsSource)) {
                        this.columnsDataSource = this.config.columnsSource;
                    } else {
                        this.columnsDataSource = [];
                        console.error("Unknown source type: " + JSON.stringify(this.columnsDataSource));
                    }
                } else {
                    this.columnsDataSource = new FieldDataSource(fieldName);
                }

                this.gridView.setColumnsDataSource(this.columnsDataSource);

                if (!this.model.value || this.model.value.length === 0) {
                    if (this.config.preFillColumns) {
                        this.columnsReset();
                    } else {
                        if (this.gridView.collection && this.gridView.collection.length === 0) {
                            this.newColumnAdded();
                        }
                    }
                }
            }.bind(this)
        );
    },

    setViewEnabled: function () {
        BaseControl.View.prototype.setViewEnabled.apply(this);
        var enabled = this.getEnabled();
        this.ui.optionsRow.toggle(enabled);
        this.gridView.setEnabled(enabled);
    },

    onRender: function () {
        if (this.hidden) {
            return;
        }

        BaseControl.View.prototype.onRender.call(this);
        this.gridView.collection.reset(this.getValue());
        this.columns.show(this.gridView);

        if (this.config.addAvailable) {
            this.ui.add.toggle(true);
        }

        if (this.config.addLabel) {
            this.ui.add.html(this.config.addLabel);
        }

        if (this.config.resetAvailable) {
            this.ui.reset.toggle(true);
        }
    },
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: Array,
    },
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
