import React, { useEffect } from "react";
import { Box, Grid, SvgIcon } from "@mui/material";
import { styles } from "./automated-app-group-details.styles";
import InfoFilled from "../../../../../../../app/images/striimline/info-fill.svg";

import automatedGroupStore from "./automated-app-group-details.store";
import { observer } from "mobx-react-lite";

import { useAppGroupStatusSynchronizer, useTableProgressStoreData } from "./hooks";
import { verifyStatusToFetchTime } from "./services";

import {
    AutomatedAppGroupErrorsComponent,
    AutomatedAppGroupPlayer,
    AppProgressLinearProgress,
    AutomatedAppGroupProgressInfo,
    AutomatedAppGroupDeployComponent,
    AutomatedGroupTableProgress,
    AutomatedAppGroupConfirmationModal,
    AppGroupDescription,
    AppGroupStageInfo
} from "./Components";

import { FlowStatus } from "../../../../../../status-management";
import LoadingIndicator from "../../../../../../generic/loading-indicator/loading-indicator";
import { sortAutomatedApps } from "../automated-app-group/utils/progress-details.helper";

export interface AutomatedAppGroupProps {
    selectedGroup: {
        groupType: "ILCDC" | "INTERNAL";
        name: string;
        applications: Array<string>;
        id: string;
    };
}

const AutomatedAppGroupDetail: React.FunctionComponent<AutomatedAppGroupProps> = observer(({ selectedGroup }) => {
    const appIds = sortAutomatedApps(selectedGroup?.applications);
    const [ilAppId, cdcAppId] = appIds;

    let currentStage = automatedGroupStore.statusInfo?.stageInfo;

    const tableProgressStorePollCondition =
        automatedGroupStore.currentFocusedApp === "IL" && !["CREATED", "DEPLOYED"].includes(currentStage);
    useTableProgressStoreData(ilAppId, tableProgressStorePollCondition);

    useAppGroupStatusSynchronizer(selectedGroup);

    useEffect(() => {
        automatedGroupStore.reset();
        automatedGroupStore.fetch(selectedGroup);
    }, [selectedGroup]);

    useEffect(() => {
        currentStage = automatedGroupStore.statusInfo?.stageInfo;     
        if (verifyStatusToFetchTime(automatedGroupStore.currentFocusedAppStatus)) {
            automatedGroupStore.updateStartStopTime(
                automatedGroupStore?.currentFocusedApp,
                automatedGroupStore?.currentFocusedAppStatus
            );
        }        
    }, [automatedGroupStore.appStatuses]);

    return (
        <div>
            <Grid sx={styles.wrapperContainer}>
                {automatedGroupStore.isAppTransitioning && (
                    <LoadingIndicator isLoading={false} isGlobal={false} variant="indeterminate" />
                )}
                <Grid direction={"row"} container sx={styles.wrapperGrid}>
                    <Grid item sx={styles.iconGrid}>
                        <SvgIcon component={InfoFilled} sx={styles.infoIcon} />
                    </Grid>
                    <Grid item container xs direction={"column"} sx={styles.contentGrid}>
                        <Grid item sx={styles.descriptionGrid}>
                            <AppGroupDescription />
                            <Grid
                                container
                                justifyContent={"space-between"}
                                alignItems="center"
                                pt={2}
                                data-test-id="automated-app-group-player"
                                flexDirection={"row-reverse"}
                            >
                                <Box sx={styles.playerBox}>
                                    <AutomatedAppGroupPlayer selectedGroup={selectedGroup} />
                                </Box>
                                <AutomatedGroupTableProgress />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container direction={"column"} sx={styles.progressContainer}>
                        <AppGroupStageInfo />
                        <AppProgressLinearProgress />
                        <AutomatedAppGroupErrorsComponent selectedGroup={selectedGroup} />
                        <AutomatedAppGroupProgressInfo appIds={automatedGroupStore.appIds} />
                    </Grid>
                </Grid>
            </Grid>

            <AutomatedAppGroupDeployComponent />
            <AutomatedAppGroupConfirmationModal />
        </div>
    );
});

export default AutomatedAppGroupDetail;
