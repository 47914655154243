import api from "core/api/api";
import _ from "underscore";
import { v4 as uuidv4 } from "uuid";
export const TYPES = {
    MOST_RECENT_DATA: "MOST_RECENT_DATA",
    FULL: "FULL"
};
const INTERVALTIME = 5000;

class Subscriber {
    callBackFn = null;
    objectFQNs = [];
    constructor(objectFQNs, callBackFn) {
        this.objectFQNs = objectFQNs;
        this.callBackFn = callBackFn;
        this.uuid = uuidv4();
        this.isActive = true;
    }

    getUUID() {
        return this.uuid;
    }

    getCallback() {
        return this.callBackFn;
    }

    setActive(isActive) {
        this.isActive = isActive;
    }

    getObjectFQNs() {
        return this.objectFQNs;
    }
}

export class MonitorService {
    objectsToQuery = {};
    subscribers = [];
    timer = null;
    latestAPIResponse = null;
    start = null;
    end = null;
    type = null;
    shouldLoop = true;
    constructor(type, shouldLoop) {
        this.type = type;
        this.shouldLoop = shouldLoop;
    }

    init() {
        setTimeout(() => {
            this.execute();
        }, 2000);
    }

    setShouldLoop(value) {
        this.shouldLoop = value;
    }

    setType(type) {
        this.type = type;
    }

    setStart(start) {
        this.start = start;
    }

    setEnd(end) {
        this.end = end;
    }

    async execute() {
        await this.doAPI();
        this.doCleanupSubscribers();
        if (!this.shouldLoop) return;
        setTimeout(() => {
            this.execute();
        }, INTERVALTIME);
    }

    async doAPI() {
        try {
            let components = Object.keys(this.objectsToQuery);
            if (!components || components.length === 0) {
                //console.log("No need to query for monitor data");
                return;
            }
            let apiResponses = await api.getMonitoringStatsForComponentsWithoutTableInfo(
                components,
                this.type,
                this.start,
                this.end
            );
            //console.log(apiResponses);

            this.latestAPIResponse = apiResponses;
            this.subscribers.forEach((subscriber) => {
                let resolveAPIData = [];
                if (!subscriber.isActive) return;
                subscriber.getObjectFQNs().forEach((fqn) => {
                    resolveAPIData.push(apiResponses[fqn]);
                });
                if (subscriber.getObjectFQNs().length === 1) {
                    subscriber.getCallback().call(null, resolveAPIData[0]);
                } else {
                    subscriber.getCallback().call(null, resolveAPIData);
                }
            });
        } catch (e) {
            console.error(e);
        }
    }

    doCleanupSubscribers() {
        let activeSubscribers = [];
        _.each(this.subscribers, (subscriber, i) => {
            if (subscriber && subscriber.isActive) {
                activeSubscribers.push(subscriber);
            }
        });
        this.subscribers = activeSubscribers;
        _.each(this.objectsToQuery, (count, fqn) => {
            if (count < 1) {
                delete this.objectsToQuery[fqn];
            }
        });
    }

    subscribe(objectFQNs, callBackFn) {
        if (!_.isArray(objectFQNs)) objectFQNs = [objectFQNs];
        let subscriber = new Subscriber(objectFQNs, callBackFn);
        this.subscribers.push(subscriber);
        _.each(objectFQNs, (objectFQN) => {
            if (objectFQN.indexOf(".") === -1) return;
            if (!this.objectsToQuery[objectFQN]) {
                this.objectsToQuery[objectFQN] = 0;
            }
            this.objectsToQuery[objectFQN]++;
        });
        return subscriber;
    }

    unSubscribe(subscriber) {
        if (subscriber) {
            subscriber.setActive(false);
            subscriber.getObjectFQNs().forEach((fqn) => {
                if (fqn.indexOf(".") === -1) return;
                this.objectsToQuery[fqn]--;
            });
        }
    }
}

let monitorService = new MonitorService(TYPES.MOST_RECENT_DATA, true);

export default monitorService;
