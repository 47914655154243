import _ from "underscore";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import BaseControl from "./../base-control";
import UIControl from "app/components/common/editor/control/ui-control";
import template from "./typedevent-to-waevent.html";

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "columns-splitter",

    regions: {
        tableNameInputRegion: ".table-name-input",
        operationControlRegion: ".operation",
    },

    ui: {
        operationContainer: ".operation-container",
    },

    setViewValue: function () {},

    getViewValue: function () {
        var result = [];

        if (this.typeName) {
            result.push({ column: this.typeName, tableName: this.tableName });
        }

        console.log(result);
        return result;
    },

    setEnabled: function (enabled) {
        BaseControl.View.prototype.setEnabled.apply(this, [enabled]);
        this.operation.setEnabled(false);
    },

    initialize: function (options) {
        this.tableNameInput = UIControl.TextField.create();
        this.operation = UIControl.TextField.create();
    },

    onRender: function () {
        this.tableNameInputRegion.show(this.tableNameInput);

        this.operationControlRegion.show(this.operation);
        this.operation.model.value = "INSERT";
        this.operation.setEnabled(false);
        this.operation.$el.find("input").css("cssText", "pointer-events: none;");

        if (this.model.value && this.model.value[0]) {
            this.tableName = this.model.value[0].tableName;
            this.tableNameInput.setValue(this.model.value[0].tableName);
        }
        let self = this;
        this.listenTo(
            this.tableNameInput.model,
            "change:value",
            function (viewModel) {
                self.tableName = viewModel.value;
                this.trigger(UIControl.EVENT.VALUE_CHANGED, this.getViewValue());
            }.bind(this)
        );
    },

    setStream: function (componentId) {
        let self = this;

        metaStoreService.findById(componentId).then(function(metaDataObject) {
            if (metaDataObject) {
                var dataType = metaDataObject.dataType;
                self.typeName =
                    MetaObjectConverter.getNamespace(dataType) + "." + MetaObjectConverter.getName(dataType);
            }

            self.trigger(UIControl.EVENT.VALUE_CHANGED, self.getViewValue());
        });
    },
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: Array,
    },
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
