import React from "react";
import { StriimMessageBox, StriimTypography, StriimButton, StriimCircularLoader } from "@striim/striim-ui";
import { Box } from "@mui/material";
import classes from "./test-connection.styles";
import Error from "../../generic/icon/wizard-icon/errorFilled.svg";
import Success from "../../generic/icon/wizard-icon/successFilled.svg";
import MissingDriver from "./missing-driver";
import utils from "core/utils";
import WithTooltip from "../tooltip/tooltip";
import { TEST_PRIMARY_CONNECTION } from "../fd-form-builder/action-button";

interface TestConnectionMsgBoxProps {
    loading: boolean;
    testConnection: () => void;
    connectionSuccess?: boolean;
    errorMsg?: string;
    unavailableDriverDBType?: string;
    lastValidationDate: string;
    testCxnBtnName: string;
    handler: string;
}


const TestConnectionMsgBox = ({
    loading,
    testConnection,
    connectionSuccess,
    errorMsg,
    unavailableDriverDBType,
    lastValidationDate,
    testCxnBtnName,
    handler
}: TestConnectionMsgBoxProps) => {
    const getValidateButton = (retest?) => {
        const adapterName = handler ? utils.getName(handler) : "";
        let cxnType = "";
        let btnTooltip = null;

        if (adapterName === "OJet") {
            btnTooltip = "Testing Capture Connection";
            if (testCxnBtnName === TEST_PRIMARY_CONNECTION) {
                cxnType = "Primary ";
                btnTooltip = "Testing Source Database Connection";
            }
        }

        const testCxnLabel = `Test ${cxnType}Connection`;
        const retestCxnLabel = `Re-test ${cxnType}Connection`;

        return WithTooltip(
            <StriimButton
                disabled={loading}
                onClick={testConnection}
                sx={classes.testConnectionBtn}
                variant="secondary"
            >
                {retest ? retestCxnLabel : testCxnLabel}
            </StriimButton>,
            btnTooltip
        );
    };

    return (
        <>
            {!connectionSuccess && !errorMsg && !unavailableDriverDBType && (
                <StriimMessageBox
                    customCardStyles={{ ...classes.infoBg, ...classes.accordionMsgBox }}
                    heading={
                        loading ? (
                            <StriimTypography sx={classes.header} variant={"body4"}>
                                Testing in Progress
                            </StriimTypography>
                        ) : null
                    }
                    type="INFO"
                    text={getValidateButton()}
                    customIcon={loading ? <StriimCircularLoader /> : <></>}
                ></StriimMessageBox>
            )}
            {connectionSuccess && (
                <StriimMessageBox
                    customCardStyles={{ ...classes.successBg, ...classes.accordionMsgBox }}
                    heading={
                        <StriimTypography sx={classes.header} variant={"body4"}>
                            Tested : {lastValidationDate}
                        </StriimTypography>
                    }
                    type="SUCCESS"
                    text={getValidateButton(true)}
                    customIcon={
                        <Box sx={{ ...classes.iconStyles, ...classes.iconFrame }}>
                            <Success />
                        </Box>
                    }
                ></StriimMessageBox>
            )}
            {errorMsg && (
                <StriimMessageBox
                    customCardStyles={{ ...classes.errorBg, ...classes.accordionMsgBox }}
                    heading={
                        <StriimTypography sx={classes.header} variant={"body4"}>
                            Testing Failed : {errorMsg}
                        </StriimTypography>
                    }
                    type="WARNING"
                    text={getValidateButton(true)}
                    customIcon={
                        <Box sx={{ ...classes.iconStyles, ...classes.iconFrame }}>
                            <Error />
                        </Box>
                    }
                ></StriimMessageBox>
            )}
            {unavailableDriverDBType && (
                <StriimMessageBox
                    customCardStyles={{ ...classes.errorBg, ...classes.accordionMsgBox }}
                    heading={
                        <StriimTypography sx={classes.header} variant={"body4"}>
                            <MissingDriver databaseType={unavailableDriverDBType} />
                        </StriimTypography>
                    }
                    type="WARNING"
                    text={getValidateButton(true)}
                    customIcon={
                        <Box sx={{ ...classes.iconStyles, ...classes.iconFrame }}>
                            <Error />
                        </Box>
                    }
                ></StriimMessageBox>
            )}
        </>
    );
};

export default TestConnectionMsgBox;
