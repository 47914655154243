export const styles = {
    cardContent: {
        height: "100%",
        border: ({ palette }) => `1px solid ${palette.greyscale["200"]}`,
        borderRadius: 2,
        borderTopLeftRadius: 0
    },
    jobsList: {
        width: "100%",
        padding: 2
    },
    jobsListHeading: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 2
    },
    buttonGroupButton: {
        textTransform: "uppercase",
        "& span": {
            fontFamily: "Inter !important"
        },
        height: "22px"
    },
    docIcon: {
        width: 14,
        height: 14,
        fill: ({ palette }) => palette.greyscale[100]
    },
    link: {
        paddingX: 0.5,
        paddingY: 0,
        height: "18px",
        minWidth: "20px"
    }
};
