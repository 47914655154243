import userPreferences from "../../../../core/services/userPreferences/user-preferences";

type GraphNodePosition = {
    left: number;
    top: number;
}

function ensureApplicationInstance(applicationId: string): Record<string, Record<string, GraphNodePosition>> {
    let nodesPosition: Record<string, Record<string, GraphNodePosition>> = userPreferences.get("nodesPosition");
    if (!nodesPosition) {
        nodesPosition = {};
    }
    if (!nodesPosition[applicationId]) {
        nodesPosition[applicationId] = {};
    }
    return nodesPosition;
}

export function savePosition(applicationId: string, nodeId: string, position: GraphNodePosition) {
    const nodesPosition = ensureApplicationInstance(applicationId);
    const savedPosition = nodesPosition[applicationId][nodeId];
    if (savedPosition) {
        position.left = savedPosition.left + position.left;
        position.top = savedPosition.top + position.top;
    }

    nodesPosition[applicationId][nodeId] = position;
    userPreferences.put("nodesPosition", nodesPosition);
}

export function loadByApplication(applicationId: string): Record<string, GraphNodePosition> {
    const nodesPosition = ensureApplicationInstance(applicationId);
    return nodesPosition[applicationId];
}

export function clearByApplication(applicationId: string) {
    const nodesPosition = ensureApplicationInstance(applicationId);
    nodesPosition[applicationId] = {};
    userPreferences.put("nodesPosition", nodesPosition);
}
