import React, { useRef } from "react";
import { Grid, Box } from "@mui/material";
import { StriimTypography, StriimDataTable } from "@striim/striim-ui-v2";

import { SentinelsFilter, ActionsFilter, IdentifiersFilter, ImportanceFilter } from "./sentinel-table-filters";
import { styles } from "./sentinel-table.styles";
import StriimCircularProgress from "../../../../apps/pages/manage-striim/connection-profiles/components/circular-progress/circular-progress";

const ACTIONS = {
    encrypted: "ENCRYPT",
    masked: "MASK",
    noAction: "NO_ACTION"
};

const SentinelTableCommon = ({
    tableColumns,
    title,
    tableData,
    showSentinelsFilter = false,
    showActionsFilter = false,
    showIdentifiersFilter = false,
    showImportanceFilter = false,
    headerHeight = 48,
    manyRowsHeader = false,
    maxHeight = 400,
    customFilterName = undefined,
    showProgress = false
}) => {
    const gridRef = useRef();

    const [filters, setFilters] = React.useState({
        importance: "",
        identifiers: "",
        actions: "",
        sentinels: ""
    });

    const updateFilter = (key, value) => {
        setFilters((prev) => ({ ...prev, [key]: value }));
    };

    const filteredData = React.useMemo(() => {
        return tableData.filter((item) => {
            return (
                (filters.importance === "" || item.importance?.toLowerCase().includes(filters.importance.toLowerCase())) &&
                (filters.identifiers === "" || item.label?.toLowerCase().includes(filters.identifiers.toLowerCase())) &&
                (filters.actions === "" || item[ACTIONS[filters.actions]] > 0) &&
                (filters.sentinels === "" || item.name?.toLowerCase().includes(filters.sentinels.toLowerCase()))
            )
        })
    }, [tableData, filters]);

    return (
        <Grid container>
            <Grid item xs={12} sx={styles.headingContainer}>
                <Box display="flex">
                    <StriimTypography variant="h4" display="inline" sx={{ marginRight: "8px !important" }}>{title}</StriimTypography>
                    {showProgress && <StriimCircularProgress size={16} />}
                </Box>

                {!showProgress && (
                    <Box sx={styles.filtersWrapper}>
                        {showImportanceFilter && (
                            <ImportanceFilter updateFilter={updateFilter} />
                        )}

                        {showIdentifiersFilter && (
                            <IdentifiersFilter
                                tableData={tableData}
                                updateFilter={updateFilter}
                            />
                        )}

                        {showActionsFilter && (
                            <ActionsFilter updateFilter={updateFilter} />
                        )}

                        {showSentinelsFilter && (
                            <SentinelsFilter
                                tableData={tableData}
                                customName={customFilterName}
                                updateFilter={updateFilter}
                            />
                        )}
                    </Box>
                )}
            </Grid>

            <Grid container width="100%">
                <Grid
                    width="100%"
                    maxHeight={maxHeight === null ? undefined : maxHeight}
                    sx={manyRowsHeader ? [styles.dataTable, styles.dataTableManyHeaderRows] : styles.dataTable}
                >
                    {!showProgress && (
                        <StriimDataTable
                            ref={gridRef}
                            data={filteredData}
                            columns={tableColumns}
                            headerHeight={headerHeight}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SentinelTableCommon;
