import React from "react";
import PropTypes from "prop-types";
import { LinearProgress } from "@mui/material";
import useStyles from "./loading-indicator.styles";
import classNames from "classnames";

const LoadingIndicator = ({ isGlobal, className, classes: propClasses, variant, color, value, valueBuffer, sx }) => {
    const classes = useStyles();
    const globalLoaderClass = isGlobal ? classes.loader : null;
    const childClassNames = classNames(globalLoaderClass, className);
    return (
        <LinearProgress
            className={childClassNames}
            {...{ variant, classes: propClasses, color, value, valueBuffer, sx }}
        />
    );
};

LoadingIndicator.propTypes = {
    isGlobal: PropTypes.bool,
    className: PropTypes.string,
    classes: PropTypes.object,
    color: PropTypes.oneOf(["primary", "secondary"]),
    value: PropTypes.number,
    valueBuffer: PropTypes.number,
    variant: PropTypes.oneOf(["determinate", "indeterminate", "buffer", "query"]),
    sx: PropTypes.object
};

LoadingIndicator.defaultProps = {
    variant: "indeterminate",
    color: "secondary",
    isGlobal: true
};

export default LoadingIndicator;
