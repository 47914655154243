import { sortBy } from "lodash";

interface Coordinates {
    clientX: number;
    clientY: number;
    pageX: number;
    pageY: number;
}

interface DropHandler {
    key: string;
    targetElement?: HTMLElement;
    callback: (droppedData: any, droppedRect: DOMRect) => void;
}

export let mousePos: Coordinates = {
    clientX: undefined,
    clientY: undefined,
    pageX: undefined,
    pageY: undefined
};

let proxyElement: HTMLElement;
let mouseDown = false;
let currentDraggedKey: string = null;
let dropData: any = null;
let listenersDisposers: Function[] = [];
let handlers: DropHandler[] = [];
let onMouseMoveInProgress = null;

function isInBox(targetElement) {
    const rect = targetElement.getBoundingClientRect();
    if (rect.left > mousePos.clientX) {
        return false;
    }

    if (rect.left + rect.width < mousePos.clientX) {
        return false;
    }

    if (rect.top > mousePos.clientY) {
        return false;
    }

    if (rect.top + rect.height < mousePos.clientY) {
        return false;
    }

    return true;
}

function savePosition(event: MouseEvent) {
    mousePos = {
        clientX: event.clientX,
        clientY: event.clientY,
        pageX: event.pageX,
        pageY: event.pageY
    };
}

const mouseMoveListener = (event: MouseEvent) => {
    savePosition(event);

    if (!mouseDown) {
        return;
    }

    if (proxyElement) {
        proxyElement.style.top = mousePos.clientY + "px";
        proxyElement.style.left = mousePos.clientX + "px";
    } else {
        if (onMouseMoveInProgress) {
            onMouseMoveInProgress();
        }
    }
};

const mouseUpListener = () => {
    mouseDown = false;
    if (!proxyElement) {
        return;
    }

    const targetRect = proxyElement.getBoundingClientRect();
    const registeredHandlersForGivenKey = handlers.filter(x => x.key === currentDraggedKey);

    registeredHandlersForGivenKey
        .filter(x => !x.targetElement)
        .forEach(handler => {
            handler.callback(dropData, targetRect);
        });

    const handlersMatchedByCoordinates = registeredHandlersForGivenKey.filter(
        x => x.targetElement && isInBox(x.targetElement)
    );
    const sortedSpecificHandlers = sortBy(handlersMatchedByCoordinates, handler => {
        const rect = handler.targetElement.getBoundingClientRect();
        return rect.width + rect.height;
    });

    if (sortedSpecificHandlers.length > 0) {
        sortedSpecificHandlers[0].callback(dropData, targetRect);
    }

    proxyElement.remove();
    proxyElement = null;
    dropData = null;
};

window.addEventListener("mousemove", mouseMoveListener);
window.addEventListener("mouseup", mouseUpListener);

export function onDrag(
    key: string,
    attachTo: HTMLElement | HTMLElement[],
    getDropData: (clickedElement: HTMLElement) => any,
    intermediateParentElement?: HTMLElement, // default to "body"
    updateProxyElement?: (clickedElement: HTMLElement) => HTMLElement,
    onStart?: (dropData: any) => void
) {
    const onMouseDownListener = function(e: MouseEvent) {
        currentDraggedKey = key;

        savePosition(e);
        mouseDown = true;

        const clickedElement = this;
        onMouseMoveInProgress = () => {
            proxyElement = clickedElement.cloneNode(true) as HTMLElement;
            if (updateProxyElement) {
                updateProxyElement(proxyElement);
            }

            if (proxyElement) {
                proxyElement.style.top = mousePos.clientY + "px";
                proxyElement.style.left = mousePos.clientX + "px";
            }

            if (intermediateParentElement) {
                intermediateParentElement.appendChild(proxyElement);
            } else {
                document.body.appendChild(proxyElement);
            }
        }

        dropData = getDropData(clickedElement);

        if (typeof onStart === "function") {
            onStart(dropData);
        }
    };

    if (Array.isArray(attachTo)) {
        attachTo.forEach(element => {
            element.addEventListener("mousedown", onMouseDownListener);
        });
    } else {
        attachTo.addEventListener("mousedown", onMouseDownListener);
    }

    listenersDisposers[key] = () => {
        if (Array.isArray(attachTo)) {
            attachTo.forEach(element => {
                element.removeEventListener("mousedown", onMouseDownListener);
            });
        } else {
            attachTo.removeEventListener("mousedown", onMouseDownListener);
        }
    };
}

export function onDrop(
    key: string,
    callback: (droppedData: any, droppedRect: DOMRect) => void,
    targetElement?: HTMLElement
): void {
    handlers.push({
        key,
        callback,
        targetElement
    });
}

export function dispose() {
    window.removeEventListener("mousemove", mouseMoveListener);
    window.removeEventListener("mouseup", mouseUpListener);
    listenersDisposers.forEach(disposer => disposer());
    mouseDown = false;
}
