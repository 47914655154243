import api from "core/api/api";
import _ from "underscore";
import { template, className, regions } from "../../../../../../../core/decorators";
import { LayoutView } from "marionette";
import App from "app";
import ReactDOM from "react-dom/client";
import ensureRegions from "../../../../../common/_ensureRegions";
import { getParams } from "src/modules/wizards/services";
import { SelectPDB, PDBLoadingIndicator } from "../../../../../../../src/modules/wizards/common/select-pdb/select-pdb";
import ErrorBox from "src/generic/error-box/error-box.tsx";

@template("<div style='height:70vh;'><div class='pdb-progress'></div><div class='pdb-error-box'></div></div>")
@className("oracle-get-pdb")
class OracleGetPdbView extends LayoutView {
    initialize() {
        App.vent.trigger("appwizard:next:disable");
        App.vent.trigger("appwizard:back:disable");
    }
    showLoader() {
        try {
            if (!this.loadingContainer) {
                this.loadingContainer = ReactDOM.createRoot(this.$el.find(".pdb-progress")[0]);
            }
            this.loadingContainer.render(<PDBLoadingIndicator type={this.options.type}/>);
        } catch (e) {
            console.log("Error rendering Linear progress", e);
        }
    }
    showErrorBox(errorMessage) {
        const message =
            errorMessage ??
            `Unable to query GV$DATABASE table. Please make sure the specified user has the necessary permissions.`;
        try {
            this.$el.find(".pdb-progress").remove();
            if (!this.errorContainer) {
                this.errorContainer = ReactDOM.createRoot(this.$el.find(".pdb-error-box")[0]);
            }
            this.errorContainer.render(
                <>
                    <PDBLoadingIndicator isLoading={false} showMessage={false} />
                    <ErrorBox message={message} />
                </>
            );
        } catch (error) {
            console.log("Error rendering error component", error);
        }
    }
    onRender() {
        this.showLoader();
    }
}
@template("<div class='pdb-select-container'></div>")
@regions({
    container: ".pdb-select-container"
})
class PdbView extends LayoutView {
    initialize() {
        ensureRegions(this);
        let model = App.reqres.request("get:wizard:model");
        this.model = model;
        App.vent.on("appwizard:retry:checkpdb", () => {
            this.fetchData();
        });
    }

    async canMoveForward() {
        return true;
    }

    async checkisPDBSupported(params) {
        try {
            let res = await api.OracleisCDB(params);
            return res === "YES";
        } catch (error) {
            throw error;
        }
    }
    async validateConnection(type, params, agentName) {
        try {
            const { ConnectionURL, UserName, Password, sslConfigMap } = params;
            let data = await api.validateConnection(
                type,
                UserName,
                Password,
                ConnectionURL,
                agentName,
                false,
                null,
                sslConfigMap
            );
            data = JSON.parse(data);
            if (data.result === false) {
                if (data.message) {
                    throw data;
                }
                throw "Connection Validation faiiled";
            }
        } catch (error) {
            throw error;
        }
    }
    async fetchPdb() {
        try {
            const connectionParams = this.model.get("connectionParams");
            const agentName = connectionParams.get("agentName");
            const type = connectionParams.get("type");

            connectionParams.unset("connectionUrl");
            connectionParams.unset("downstreamConnectionURL");

            const isDownStreamFilled = connectionParams.get("supportDownStreamCapture");
            const params = await getParams(type, connectionParams, isDownStreamFilled);

            delete params["containerName"];
            // validate the connection before checking the PDB
            try {
                await this.validateConnection(type.toLowerCase(), params, agentName);
            } catch (validationError) {
                throw new Error("Validation error: " + validationError?.message);
            }

            const isPDB = await this.checkisPDBSupported(params);

            this.model.set("isPDB", isPDB);
            connectionParams.set("isPDB", isPDB);

            if (isPDB) {
                const pdbs = await api.OraclegetPDBs(params);
                return JSON.parse(pdbs);
            } else {
                return null;
            }
        } catch (error) {
            this.model.set("isPDB", false);
            this.getPDBView.showErrorBox(error?.message);
            throw error;
        }
    }

    async fetchData() {
        try {
            const type = this.model.get("connectionParams").get("type");
            const getPDBView = new OracleGetPdbView({type});
            this.getPDBView = getPDBView;
            this.getRegion("container").show(getPDBView);
            const res = await this.fetchPdb();
            if (!res || res.length === 0) {
                // move to validate connection page
                this.options.vent.trigger("do:next");
                return;
            }
            // show select PDB page
            const selectPDBView = new OracleSelectPdbView({ pdbList: res });
            this.getRegion("container").show(selectPDBView);
        } catch (error) {
            App.vent.trigger("appwizard:retry:show");
            App.vent.trigger("appwizard:back:enable");
        }
    }
    onDestroy() {
        App.vent.off("appwizard:retry:checkpdb");
    }
    onRender() {
        this.fetchData();
    }
}

@template('<div class="pdb-container"> </div>')
@className("oracle-get-pdb")
class OracleSelectPdbView extends LayoutView {
    initialize() {
        let model = App.reqres.request("get:wizard:model");
        this.model = model;
    }
    showReactContent() {
        try {
            const pdbList = this.options.pdbList;
            const connectionParams = this.model.get("connectionParams");
            const root = ReactDOM.createRoot(this.$el.find(".pdb-container")[0]);

            root.render(<SelectPDB pdbList={pdbList} model={connectionParams} />);
        } catch (error) {
            console.log("Error rendering PDB form", error);
        }
    }

    onShow() {
        App.vent.trigger("appwizard:next:disable");
        App.vent.trigger("appwizard:back:enable");
        this.showReactContent();
    }
}

export default PdbView;
