import Backbone from "backbone";
import { View } from "../database-reader/base-database-reader";
import { DB_PROVIDERS } from "../database-reader/configs/db-providers";
const module = {};
class SalesforcePardotView extends View {
    setup() {
        this.label = "SalesforcePardot";
        this.model.get("connectionParams").set("type", DB_PROVIDERS.SALESFORCEPARDOT.id);
        this.model.set("isCDC", false);
    }
}

module.View = SalesforcePardotView;
module.vent = new Backbone.Model();
export default module;
