import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { isUserAdmin } from "../../../core/user-management/update.control";
import securityService from "../../../core/services/securityService/securityService";
import MONITOR_ROUTES from "./routes.json";
import propertyTemplateService from "../../../core/services/metaStoreService/property-template-service";

export default function MonitorRoot({ children }) {
    const isAdmin = isUserAdmin();

    if (!isAdmin && securityService.isDeveloperLicense()) {
        return <Navigate to={{ pathname: MONITOR_ROUTES.unauthorized }} />;
    }

    const updateHeader = () => {
        const { clusterName } = propertyTemplateService.getServerInfo();
        const title = "Monitor (Cluster: " + clusterName + ")";
        document.title = title;
    };

    useEffect(() => {
        updateHeader();
    }, []);

    return <>{children}</>;
}
