import App from "app";
import React, { useEffect } from "react";
import {
    DisclaimerSection,
    LogoSection,
    Section,
    SherlockAppReportTitle,
    TimeFilterSection
} from "../components/report-components";
import { Box, Grid } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import { DiscoveredSDITable, ImportanceLegend, TopHighIdentifiers } from "./sherlock-report-components";
import { SourceCharts } from "../components/report-charts/sherlock-report-charts";

const SherlockAppReport = ({ reportData, triggerDownload }) => {
    const { sourceData, appName, timeRange, sourceIcons, targetIcons, identifiers } = reportData;

    useEffect(() => {
        setTimeout(() => triggerDownload(), 500);
    }, [sourceData]);

    return (
        <Grid>
            <LogoSection />
            <Grid m={5}>
                <SherlockAppReportTitle
                    appText={appName}
                    sourceIcons={sourceIcons}
                    targetIcons={targetIcons}
                    username={App?.user?.name ?? ""}
                />
                <TimeFilterSection selectedRange={timeRange} hideFilterTime={true} />
                <DisclaimerSection type={"Sherlock"} />
                <Box my={2}>
                    <StriimTypography variant="h1">Summary</StriimTypography>
                </Box>
                <Grid container gap={2}>
                    <StriimTypography variant="h3">Top 10 Sensitive Data Identifiers Discovered</StriimTypography>
                    <DiscoveredSDITable sourceData={sourceData} />
                </Grid>
                <SourceCharts sourceData={sourceData} />
                <Section showborderBottom={false}>
                    <StriimTypography variant="h3">Top 10 Tables with High Importance Identifiers</StriimTypography>
                    <ImportanceLegend />
                    <TopHighIdentifiers identifiers={identifiers} />
                </Section>
            </Grid>
        </Grid>
    );
};

export default SherlockAppReport;
