import _ from "underscore";

export default function (array) {
    var retVal = [];
    _.each(array, function (elem) {
        retVal.push({
            id: elem,
            text: elem,
        });
    });
    return retVal;
}
