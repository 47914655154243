import React from "react";
import { Grid } from "@mui/material";
import { styles } from "../guardian.styles";
import SelectTables from "../../components/select-tables-component/select-tables-component";

const GuardianSelectStep = ({ apps, tableData, selectedRows, setSelectedRows }) => {
    return (
        <Grid container sx={styles.guardianContentContainer} p={2}>
            <SelectTables
                selectable={true}
                apps={apps}
                tableData={tableData}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
            />
        </Grid>
    );
};

export default GuardianSelectStep;
