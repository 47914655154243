import _ from "underscore";

function _shift_node(row, start_node, dist, stop_at_hit) {
    if (dist === 0) {
        return;
    }

    var movable_subset;

    var get_movable_subset = function (start_node, dir) {
        // dir must be either greater than 0 (move right), or less than 0 (move left)
        var row_array = _(row).sortBy(function (node) {
            return node.coords[0];
        }); // Just in case...
        var first = row_array[0].id;
        var last = row_array[row_array.length - 1].id;
        var row_by_x = {};
        _(row).each(function (node) {
            row_by_x[node.coords[0]] = node;
        });

        // Gather up an array of nodes to push this step. This array consists of all the target node's neighbors in the direction of movement that are flush with each other (e.g. are not separated by empty space that can fit another node). If we are just shifting this node until it hits a neighbor, the movable subset naturally consists of only this node

        var movable_subset = [];
        var movable_node = start_node;
        var c = 0;
        var space = 0;
        var x = movable_node.coords[0];

        function getNextLowerX(value) {
            // find next value lower than value parameter. For example, when value === 10
            // and array is [5, 10, 15, 20, 25]
            // it will return 15

            var result = -10000000;
            Object.keys(row_by_x).forEach((key) => {
                key = parseInt(key);
                if (key <= value && key > result) {
                    result = key;
                }
            });
            if (result === -10000000) {
                return undefined;
            }
            return result;
        }

        function getNextHigherX(value) {
            // find next value higher value parameter. For example when value === 10
            // and array is [1, 2, 5, 10, 15, 20]
            // it will return 5

            var result = 10000000;
            Object.keys(row_by_x).forEach((key) => {
                key = parseInt(key);
                if (key >= value && key < result) {
                    result = key;
                }
            });
            if (result === 10000000) {
                return undefined;
            }
            return result;
        }

        while (true) {
            // If we are at a node...
            if (movable_node) {
                // If we've hit a node after empty space, don't add it, and stop here
                if (space > 0) {
                    break;
                } else {
                    movable_subset.push(movable_node);
                    var end_of_row = (dir > 0 && movable_node.id === last) || (dir < 0 && movable_node.id === first);
                    if (end_of_row) {
                        break;
                    }
                }
                // If we are to stop at a hit, and there is already a node in the movable_subset, we have run into a neighbor and must now stop
                if (stop_at_hit && movable_subset.length > 0) {
                    break;
                }
            } else {
                space++;
            }

            // Step to the next space
            var newX = dir < 0 ? getNextLowerX(x - 1) : getNextHigherX(x + 1);
            if (newX === undefined) {
                break;
            } else {
                // increase number of spaces by absolute difference between x and newX
                space += Math.abs(Math.abs(x) - Math.abs(newX)) - 1;
            }

            x = newX;
            movable_node = row_by_x[x];

            if (c > 100) {
                throw new Error(
                    "Movable subset computation timed out for " + start_node.id + " in row " + start_node.layer
                );
            }
            c++;
        }

        return {
            nodes: movable_subset,
            space: space,
        };
    };

    // If we are to stop at a hit, we need to compute the max distance we can move, since the desired behavior is that this node stops when it hits a neighbor, rather than pushes them
    if (stop_at_hit) {
        movable_subset = get_movable_subset(start_node, dist); // + or - infinity dist will act as either "right" or "left"
        dist = movable_subset.space;
    }

    if (dist === 0 || !dist) {
        return;
    }

    var num_steps = Math.abs(dist);
    var step = dist / num_steps; // Our step size is either 1 or -1

    // Move this node step by step, until the require moved distance is reached, by "pushing" the nodes around it and collapsing any empty space
    for (var step_num = 0; step_num < num_steps; step_num++) {
        movable_subset = get_movable_subset(start_node, step).nodes;

        // Move each node in our subset
        _.each(movable_subset, function (node_to_move) {
            node_to_move.coords[0] += step;
        });
    }
}

// Align children with their parents
export default {
    align: function (nodes) {
        var layers = _(nodes).groupBy(function (node) {
            return node.layer;
        });
        _(layers).each(function (row, r) {
            layers[r] = _(row).sortBy(function (node) {
                return node.coords[0];
            });
        });

        _.each(layers, function (row, r) {
            if (r === 0) {
                return; // start from the second layer
            }

            var row_by_x = row.reverse();

            // Process nodes starting from the least important nodes further to the right, to give "final say" on desired shifting to more important nodes on the left
            _.each(row_by_x, function (node) {
                var edgesIn = nodes[node.id].edges_in;
                if (!edgesIn || _.isEmpty(edgesIn)) {
                    return; // this is a root node
                }

                var parents_by_depth = _.groupBy(edgesIn, function (parent_id) {
                    return nodes[parent_id].depth;
                });
                parents_by_depth = _.toArray(parents_by_depth);
                var deepest_parents = [];
                _.each(parents_by_depth[parents_by_depth.length - 1], function (id) {
                    deepest_parents.push(nodes[id]);
                });

                deepest_parents = _.sortBy(deepest_parents, function (parent) {
                    return Math.abs(node.coords[0] - parent.coords[0]);
                });

                var nearest_deep_parent = deepest_parents[0];
                var dist = nearest_deep_parent.coords[0] - node.coords[0];

                if (dist !== 0) {
                    node.aligned_to_parent = true;
                    _shift_node(layers[node.layer], node, dist);
                }
            });
        });

        var unaligned = _(nodes).filter(function (node) {
            return !node.aligned_to_parent;
        });

        // Take nodes that did not get affected by parent position and ensure they are aligned with their children
        _(unaligned).each(function (node) {
            if (nodes[node.id].edges_out.length === 0) {
                return; // Not sure this ever happens, but just in case
            }
            var nearest_children = _(nodes[node.id].edges_out).sortBy(function (id) {
                return Math.abs(node.coords[0] - nodes[id].coords[0]);
            });
            var nearest_child = nodes[nearest_children[0]];
            var dist = nearest_child.coords[0] - node.coords[0];

            if (!dist) {
                return;
            }

            _shift_node(layers[node.layer], node, dist, true);
        });

        return nodes;
    },
};
