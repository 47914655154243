export const styles = {
    smallFont: {
        fontSize: "12px"
    },
    timeContainer: {
        display: "flex",
        gap: 2,
        alignItems: "center"
    },
    infoIcon: {
        width: 16,
        height: 16,
        fill: "#161616"
    },
    lightText: {
        color: "rgba(32, 32, 32, 0.50)"
    },
    titleFont: {
        fontFamily: "Nunito",
        fontSize: "32px",
        fontWeight: "600"
    },
    subTitleFont: {
        fontFamily: "Nunito",
        fontSize: "32px",
        fontWeight: "400"
    }
};
