export const SECTIONS = {
    files: "files",
    vaults: "vaults",
    metadatamanager: "metadata-manager",
    propertysets: "property-sets",
    propertyvariables: "property-variables",
    embeddinggenerator: "embedding-generator"
};

export const UPLOAD_PATH = "Platform/UploadedFiles/";
