import TypesMapping from "./typesMapping.json";
import ConvertFunctions from "app/components/common/editor/control/select/types-datasource.json";

function resolveTypedEventType(waEventType) {
    var type = "java.lang.String";
    try {
        for (let i = 0; i < TypesMapping.length; i++) {
            let dbTypes = TypesMapping[i].dbTypes;

            for (let j = 0; j < dbTypes.length; j++) {
                if (dbTypes[j].toUpperCase() === waEventType.toUpperCase()) {
                    type = TypesMapping[i].type;
                }
            }
        }
    } catch (e) {
        return type;
    }

    return type;
}

export default function resolveConvertFunction(waEventType) {
    let type = resolveTypedEventType(waEventType);

    for (let i = 0; i < ConvertFunctions.length; i++) {
        if (ConvertFunctions[i].id === type) {
            return ConvertFunctions[i].convertFunction;
        }
    }

    return null;
}
