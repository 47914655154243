import AdlsGen2 from "./flow-designer-icon/adls-gen2.svg";
import Apache from "./flow-designer-icon/apache.svg";
import App from "./flow-designer-icon/app.svg";
import Autolayout from "./flow-designer-icon/autolayout.svg";
import Avro from "./flow-designer-icon/avro.svg";
import AzureEventHub from "./flow-designer-icon/azure-event-hub.svg";
import AzureEventhub from "./flow-designer-icon/azure-eventhub.svg";
import AzureHdInsights from "./flow-designer-icon/azure-hd-insights.svg";
import AzureHdfs from "./flow-designer-icon/azure-hdfs.svg";
import AzureKafka from "./flow-designer-icon/azure-kafka.svg";
import AzureNewlogo from "./flow-designer-icon/azure-newlogo.svg";
import AzurePostgresql from "./flow-designer-icon/azure-postgresql.svg";
import AzureSynapseAnalytics from "./flow-designer-icon/azure-synapse-analytics.svg";
import AzureStorage from "./flow-designer-icon/azure-storage.svg";
import Azure from "./flow-designer-icon/azure.svg";
import Azurepostgresql from "./flow-designer-icon/azurepostgresql.svg";
import Back from "./flow-designer-icon/back.svg";
import Bigquery from "./flow-designer-icon/bigquery.svg";
import Binary from "./flow-designer-icon/binary.svg";
import Cache1 from "./flow-designer-icon/cache-1.svg";
import Cache from "./flow-designer-icon/cache.svg";
import Cassandra from "./flow-designer-icon/cassandra.svg";
import ChoroplethMap from "./flow-designer-icon/choropleth-map.svg";
import Cisco from "./flow-designer-icon/cisco.svg";
import ClouderaHive from "./flow-designer-icon/cloudera-hive.svg";
import Cloudsql from "./flow-designer-icon/cloudsql.svg";
import Cloudstorage from "./flow-designer-icon/cloudstorage.svg";
import Cluster from "./flow-designer-icon/cluster.svg";
import Collectd from "./flow-designer-icon/collectd.svg";
import ComponentsOutputOracle from "./flow-designer-icon/components-output-oracle.svg";
import ComponentsProcessingOpenProcessor from "./flow-designer-icon/components-processing-open-processor.svg";
import Cosmosdb from "./flow-designer-icon/cosmosdb.svg";
import Cq from "./flow-designer-icon/cq.svg";
import DataStriim from "./flow-designer-icon/data-striim.svg";
import DataWarehouse from "./flow-designer-icon/data-warehouse.svg";
import Database from "./flow-designer-icon/database.svg";
import Db from "./flow-designer-icon/db.svg";
import DelimSeparated from "./flow-designer-icon/delim-separated.svg";
import Delimiter from "./flow-designer-icon/delimiter.svg";
import Databricks from "./flow-designer-icon/databricks.svg";
import Description from "./flow-designer-icon/description.svg";
import Email from "./flow-designer-icon/email.svg";
import EventTable from "./flow-designer-icon/event-table.svg";
import Exporttql from "./flow-designer-icon/exporttql.svg";
import ExternalSource from "./flow-designer-icon/externalsource.svg";
import FileWriter from "./flow-designer-icon/file-writer.svg";
import File from "./flow-designer-icon/file.svg";
import Flow from "./flow-designer-icon/flow.svg";
import Freeformtext from "./flow-designer-icon/freeformtext.svg";
import Goldengate from "./flow-designer-icon/goldengate.svg";
import GooglebigqueryCopy from "./flow-designer-icon/googlebigquery copy.svg";
import Googlebigquery from "./flow-designer-icon/googlebigquery.svg";
import GooglecloudplatformWriter from "./flow-designer-icon/googlecloudplatform-writer.svg";
import Googlecloudplatform from "./flow-designer-icon/googlecloudplatform.svg";
import Googlecloudstroge from "./flow-designer-icon/googlecloudstroge.svg";
import Grab from "./flow-designer-icon/grab.svg";
import Hadoop from "./flow-designer-icon/hadoop.svg";
import Hazelcast from "./flow-designer-icon/hazelcast.svg";
import Hbase from "./flow-designer-icon/hbase.svg";
import Hdfs from "./flow-designer-icon/hdfs.svg";
import Hive from "./flow-designer-icon/hive.svg";
import Hp from "./flow-designer-icon/hp.svg";
import Http from "./flow-designer-icon/http.svg";
import IncrementalBatchReader from "./flow-designer-icon/incremental-batch-reader.svg";
import Jdbc from "./flow-designer-icon/jdbc.svg";
import Jms from "./flow-designer-icon/jms.svg";
import Jmx from "./flow-designer-icon/jmx.svg";
import Jpa from "./flow-designer-icon/jpa.svg";
import Json from "./flow-designer-icon/json.svg";
import Kafka from "./flow-designer-icon/kafka.svg";
import Lakehouse from "./flow-designer-icon/lakehouse.svg";
import Key from "./flow-designer-icon/key.svg";
import Kinesis from "./flow-designer-icon/kinesis.svg";
import Kudu from "./flow-designer-icon/kudu.svg";
import Launch from "./flow-designer-icon/launch.svg";
import MariaDB from "./flow-designer-icon/mariadb.svg";
import Mapr from "./flow-designer-icon/mapr.svg";
import Maprdb from "./flow-designer-icon/maprdb.svg";
import Maprfs from "./flow-designer-icon/maprfs.svg";
import Maprstreams from "./flow-designer-icon/maprstreams.svg";
import Memsql from "./flow-designer-icon/memsql.svg";
import Mongodb from "./flow-designer-icon/mongodb.svg";
import MongoCosmos from "./flow-designer-icon/mongo-cosmos.svg";
import Monitor from "./flow-designer-icon/monitor.svg";
import Mqtt from "./flow-designer-icon/mqtt.svg";
import Mssql from "./flow-designer-icon/mssql.svg";
import MultiFileReader from "./flow-designer-icon/multi-file-reader.svg";
import Mysql from "./flow-designer-icon/mysql.svg";
import NameValuePair from "./flow-designer-icon/name-value-pair.svg";
import Nav from "./flow-designer-icon/nav.svg";
import Netflow from "./flow-designer-icon/netflow.svg";
import Node from "./flow-designer-icon/node.svg";
import Nvp from "./flow-designer-icon/nvp.svg";
import Objects from "./flow-designer-icon/objects.svg";
import OpcUaReader from "./flow-designer-icon/opc-ua-reader.svg";
import Opcua from "./flow-designer-icon/opcua.svg";
import OpenProcessor from "./flow-designer-icon/open-processor.svg";
import Oracle from "./flow-designer-icon/oracle.svg";
import OutputSql from "./flow-designer-icon/output-sql.svg";
import PostgrateSql from "./flow-designer-icon/postgrate-sql.svg";
import PostgreSql from "./flow-designer-icon/postgreSQL.svg";
import PostgresNotselected from "./flow-designer-icon/postgres-notselected.svg";
import Postgresqlcdc from "./flow-designer-icon/postgresqlcdc.svg";
import Redshift from "./flow-designer-icon/redshift.svg";
import Router from "./flow-designer-icon/router.svg";
import S3 from "./flow-designer-icon/s3.svg";
import SAPHana from "./flow-designer-icon/saphana.svg";
import S3Reader from "./flow-designer-icon/s3reader.svg";
import Salesforce from "./flow-designer-icon/salesforce.svg";
import Server from "./flow-designer-icon/server.svg";
import Snmp from "./flow-designer-icon/snmp.svg";
import Snowflake from "./flow-designer-icon/snowflake.svg";
import Source from "./flow-designer-icon/source.svg";
import Spanner from "./flow-designer-icon/spanner.svg";
import Sql from "./flow-designer-icon/sql.svg";
import Start from "./flow-designer-icon/start.svg";
import Status from "./flow-designer-icon/status.svg";
import Store from "./flow-designer-icon/store.svg";
import Stream from "./flow-designer-icon/stream.svg";
import Striim from "./flow-designer-icon/striim.svg";
import SysOut from "./flow-designer-icon/sys-out.svg";
import Target from "./flow-designer-icon/target.svg";
import Tcp from "./flow-designer-icon/tcp.svg";
import Template from "./flow-designer-icon/template.svg";
import ThreatmapDark from "./flow-designer-icon/threatmap-dark.svg";
import Threatmap from "./flow-designer-icon/threatmap.svg";
import Tooltip from "./flow-designer-icon/tooltip.svg";
import Tpc from "./flow-designer-icon/tpc.svg";
import TransformationAdduserdata from "./flow-designer-icon/transformation-adduserdata.svg";
import TransformationFieldrenamer from "./flow-designer-icon/transformation-fieldrenamer.svg";
import TransformationFieldsplitter from "./flow-designer-icon/transformation-fieldsplitter.svg";
import TransformationRemovefieldCopy from "./flow-designer-icon/transformation-removefield copy.svg";
import TransformationRemovefield from "./flow-designer-icon/transformation-removefield.svg";
import TransformationSecurity from "./flow-designer-icon/transformation-security.svg";
import TransformationTypedeventenricher from "./flow-designer-icon/transformation-typedeventenricher.svg";
import TransformationShield from "./flow-designer-icon/transformation-shield.svg";
import TransformationSentinel from "./flow-designer-icon/transformation-sentinel.svg";
import TransformationTypedeventtowaevent from "./flow-designer-icon/transformation-typedeventtowaevent.svg";
import TransformationTypeeventfilter from "./flow-designer-icon/transformation-typeeventfilter.svg";
import TransformationTypemodifier from "./flow-designer-icon/transformation-typemodifier.svg";
import TransformationValueFilter from "./flow-designer-icon/transformation-value-filter.svg";
import TransformationWaeventdatamodifier from "./flow-designer-icon/transformation-waeventdatamodifier.svg";
import TransformationWaeventtodwstage from "./flow-designer-icon/transformation-waeventtodwstage.svg";
import TransformationWaeventtotypedevent from "./flow-designer-icon/transformation-waeventtotypedevent.svg";
import TransformationWametafilterCopy from "./flow-designer-icon/transformation-wametafilter-copy.svg";
import TransformationWametafilter from "./flow-designer-icon/transformation-wametafilter.svg";
import Type from "./flow-designer-icon/type.svg";
import Udp from "./flow-designer-icon/udp.svg";
import Undeploy from "./flow-designer-icon/undeploy.svg";
import VectorMap from "./flow-designer-icon/vector-map.svg";
import Waction from "./flow-designer-icon/waction.svg";
import WaeventToDwstage from "./flow-designer-icon/waevent-to-dwstage.svg";
import Warning from "./flow-designer-icon/warning.svg";
import Webalert from "./flow-designer-icon/webalert.svg";
import Window from "./flow-designer-icon/window.svg";
import Windows from "./flow-designer-icon/windows.svg";
import Xml from "./flow-designer-icon/xml.svg";
import QuickStart from "./flow-designer-icon/quick-start.svg";
import ServiceNow from "./flow-designer-icon/servicenow.svg";
import HubSpotReader from "./flow-designer-icon/hubspot.svg";
import GoogleAds from "./flow-designer-icon/googleads.svg";
import JiraReader from "./flow-designer-icon/jira.svg";
import FabricMirror from "./flow-designer-icon/fabric-mirror.svg";
//DO-NOT-REMOVE-BELOW-COMMENT
//add-new-import
import BambooHr from "./flow-designer-icon/bamboohr.svg";
import YugabyteDB from "./flow-designer-icon/yugabytedb.svg";
import Stripe from "./flow-designer-icon/stripe.svg";
import Zendesk from "./flow-designer-icon/zendesk.svg";
import ADLS from "./flow-designer-icon/adls.svg";
import Intercom from "./flow-designer-icon/intercom.svg";
import Dataverse from "./flow-designer-icon/dataverse.svg";
import Yellowbrick from "./flow-designer-icon/yellowbrick.svg";
import Db2Zos from "./flow-designer-icon/db2-zos.svg";
import GoogleAnalytics from "./flow-designer-icon/googleanalytics.svg";
import LinkedInAds from "./flow-designer-icon/linkedinads.svg";
import GoogleSheets from "./flow-designer-icon/googlesheets.svg";
import Paymo from "./flow-designer-icon/paymo.svg";

export { AdlsGen2 };
export { Apache };
export { App };
export { Autolayout };
export { Avro };
export { AzureEventHub };
export { AzureEventhub };
export { AzureHdInsights };
export { AzureHdfs };
export { AzureKafka };
export { AzureNewlogo };
export { AzurePostgresql };
export { AzureSynapseAnalytics };
export { AzureStorage };
export { Azure };
export { Azurepostgresql };
export { Back };
export { Bigquery };
export { Binary };
export { Cache1 };
export { Cache };
export { Cassandra };
export { ChoroplethMap };
export { Cisco };
export { ClouderaHive };
export { Cloudsql };
export { Cloudstorage };
export { Cluster };
export { Collectd };
export { ComponentsOutputOracle };
export { ComponentsProcessingOpenProcessor };
export { Cosmosdb };
export { Cq };
export { DataStriim };
export { DataWarehouse };
export { Database };
export { Db };
export { DelimSeparated };
export { Delimiter };
export { Databricks };
export { Description };
export { Email };
export { EventTable };
export { Exporttql };
export { ExternalSource };
export { FileWriter };
export { File };
export { Flow };
export { Freeformtext };
export { Goldengate };
export { GooglebigqueryCopy };
export { Googlebigquery };
export { GooglecloudplatformWriter };
export { Googlecloudplatform };
export { Googlecloudstroge };
export { Grab };
export { Hadoop };
export { Hazelcast };
export { Hbase };
export { Hdfs };
export { Hive };
export { Hp };
export { Http };
export { IncrementalBatchReader };
export { Jdbc };
export { Jms };
export { Jmx };
export { Jpa };
export { Json };
export { Kafka };
export { Key };
export { Kinesis };
export { Kudu };
export { Lakehouse };
export { Launch };
export { MariaDB };
export { Mapr };
export { Maprdb };
export { Maprfs };
export { Maprstreams };
export { Memsql };
export { Mongodb };
export { MongoCosmos };
export { Monitor };
export { Mqtt };
export { Mssql };
export { MultiFileReader };
export { Mysql };
export { NameValuePair };
export { Nav };
export { Netflow };
export { Node };
export { Nvp };
export { Objects };
export { OpcUaReader };
export { Opcua };
export { OpenProcessor };
export { Oracle };
export { OutputSql };
export { PostgrateSql };
export { PostgreSql };
export { PostgresNotselected };
export { Postgresqlcdc };
export { Redshift };
export { Router };
export { SAPHana };
export { S3 };
export { S3Reader };
export { Salesforce };
export { Server };
export { Snmp };
export { Snowflake };
export { Source };
export { Spanner };
export { Sql };
export { Start };
export { Status };
export { Store };
export { Stream };
export { Striim };
export { SysOut };
export { Target };
export { Tcp };
export { Template };
export { ThreatmapDark };
export { Threatmap };
export { Tooltip };
export { Tpc };
export { TransformationAdduserdata };
export { TransformationFieldrenamer };
export { TransformationFieldsplitter };
export { TransformationRemovefieldCopy };
export { TransformationRemovefield };
export { TransformationSecurity };
export { TransformationTypedeventenricher };
export { TransformationShield };
export { TransformationSentinel };
export { TransformationTypedeventtowaevent };
export { TransformationTypeeventfilter };
export { TransformationTypemodifier };
export { TransformationValueFilter };
export { TransformationWaeventdatamodifier };
export { TransformationWaeventtodwstage };
export { TransformationWaeventtotypedevent };
export { TransformationWametafilterCopy };
export { TransformationWametafilter };
export { Type };
export { Udp };
export { Undeploy };
export { VectorMap };
export { Waction };
export { WaeventToDwstage };
export { Warning };
export { Webalert };
export { Window };
export { Windows };
export { Xml };
export { QuickStart };
export { Zendesk };
export { HubSpotReader };
export { Intercom };
export { Yellowbrick };
export { Db2Zos };
export { FabricMirror };

let icons = {};
icons["adls-gen2"] = AdlsGen2;
icons["apache"] = Apache;
icons["app"] = App;
icons["autolayout"] = Autolayout;
icons["avro"] = Avro;
icons["azure-event-hub"] = AzureEventHub;
icons["azure-eventhub"] = AzureEventhub;
icons["azure-hd-insights"] = AzureHdInsights;
icons["azure-hdfs"] = AzureHdfs;
icons["azure-kafka"] = AzureKafka;
icons["azure-newlogo"] = AzureNewlogo;
icons["azure-postgresql"] = AzurePostgresql;
icons["azure-synapse-analytics"] = AzureSynapseAnalytics;
icons["azure-storage"] = AzureStorage;
icons["azure"] = Azure;
icons["azurepostgresql"] = Azurepostgresql;
icons["back"] = Back;
icons["bigquery"] = Bigquery;
icons["binary"] = Binary;
icons["cache-1"] = Cache1;
icons["cache"] = Cache;
icons["cassandra"] = Cassandra;
icons["choropleth-map"] = ChoroplethMap;
icons["cisco"] = Cisco;
icons["cloudera-hive"] = ClouderaHive;
icons["cloudsql"] = Cloudsql;
icons["cloudstorage"] = Cloudstorage;
icons["cluster"] = Cluster;
icons["collectd"] = Collectd;
icons["components-output-oracle"] = ComponentsOutputOracle;
icons["components-processing-open-processor"] = ComponentsProcessingOpenProcessor;
icons["cosmosdb"] = Cosmosdb;
icons["cq"] = Cq;
icons["data-striim"] = DataStriim;
icons["data-warehouse"] = DataWarehouse;
icons["database"] = Database;
icons["db"] = Db;
icons["delim-separated"] = DelimSeparated;
icons["delimiter"] = Delimiter;
icons["databricks"] = Databricks;
icons["description"] = Description;
icons["email"] = Email;
icons["event-table"] = EventTable;
icons["exporttql"] = Exporttql;
icons["externalsource"] = ExternalSource;
icons["file-writer"] = FileWriter;
icons["file"] = File;
icons["flow"] = Flow;
icons["freeformtext"] = Freeformtext;
icons["goldengate"] = Goldengate;
icons["googlebigquery copy"] = GooglebigqueryCopy;
icons["googlebigquery"] = Googlebigquery;
icons["googlecloudplatform-writer"] = GooglecloudplatformWriter;
icons["googlecloudplatform"] = Googlecloudplatform;
icons["googlecloudstroge"] = Googlecloudstroge;
icons["googleads"] = GoogleAds;
icons["grab"] = Grab;
icons["hadoop"] = Hadoop;
icons["hazelcast"] = Hazelcast;
icons["hbase"] = Hbase;
icons["hdfs"] = Hdfs;
icons["hive"] = Hive;
icons["hp"] = Hp;
icons["http"] = Http;
icons["incremental-batch-reader"] = IncrementalBatchReader;
icons["jdbc"] = Jdbc;
icons["jms"] = Jms;
icons["jmx"] = Jmx;
icons["jpa"] = Jpa;
icons["json"] = Json;
icons["icon-components-output-kafka"] = Kafka;
icons["kafka"] = Kafka;
icons["key"] = Key;
icons["kinesis"] = Kinesis;
icons["kudu"] = Kudu;
icons["lakehouse"] = Lakehouse;
icons["launch"] = Launch;
icons["mariadb"] = MariaDB;
icons["mapr"] = Mapr;
icons["maprdb"] = Maprdb;
icons["maprfs"] = Maprfs;
icons["maprstreams"] = Maprstreams;
icons["memsql"] = Memsql;
icons["mongodb"] = Mongodb;
icons["mongo"] = Mongodb;
icons["mongo-cosmos"] = MongoCosmos;
icons["monitor"] = Monitor;
icons["mqtt"] = Mqtt;
icons["mssql"] = Mssql;
icons["multi-file-reader"] = MultiFileReader;
icons["mysql"] = Mysql;
icons["name-value-pair"] = NameValuePair;
icons["nav"] = Nav;
icons["netflow"] = Netflow;
icons["node"] = Node;
icons["nvp"] = Nvp;
icons["objects"] = Objects;
icons["opc-ua-reader"] = OpcUaReader;
icons["opcua"] = Opcua;
icons["open-processor"] = OpenProcessor;
icons["oracle"] = Oracle;
icons["output-sql"] = OutputSql;
icons["postgrate-sql"] = PostgrateSql;
icons["postgreSQL"] = PostgreSql;
icons["postgres-notselected"] = PostgresNotselected;
icons["postgresqlcdc"] = Postgresqlcdc;
icons["redshift"] = Redshift;
icons["router"] = Router;
icons["saphana"] = SAPHana;
icons["s3"] = S3;
icons["s3reader"] = S3Reader;
icons["salesforce"] = Salesforce;
icons["hubspot"] = HubSpotReader;
icons["server"] = Server;
icons["snmp"] = Snmp;
icons["snowflake"] = Snowflake;
icons["source"] = Source;
icons["spanner"] = Spanner;
icons["sql"] = Sql;
icons["start"] = Start;
icons["status"] = Status;
icons["store"] = Store;
icons["stream"] = Stream;
icons["striim"] = Striim;
icons["sys-out"] = SysOut;
icons["target"] = Target;
icons["tcp"] = Tcp;
icons["template"] = Template;
icons["threatmap-dark"] = ThreatmapDark;
icons["threatmap"] = Threatmap;
icons["tooltip"] = Tooltip;
icons["tpc"] = Tpc;
icons["transformation-adduserdata"] = TransformationAdduserdata;
icons["transformation-fieldrenamer"] = TransformationFieldrenamer;
icons["transformation-fieldsplitter"] = TransformationFieldsplitter;
icons["transformation-removefield copy"] = TransformationRemovefieldCopy;
icons["transformation-removefield"] = TransformationRemovefield;
icons["transformation-security"] = TransformationSecurity;
icons["transformation-typedeventenricher"] = TransformationTypedeventenricher;
icons["transformation-shield"] = TransformationShield;
icons["transformation-sentinel"] = TransformationSentinel;
icons["transformation-typedeventtowaevent"] = TransformationTypedeventtowaevent;
icons["transformation-typeeventfilter"] = TransformationTypeeventfilter;
icons["transformation-typemodifier"] = TransformationTypemodifier;
icons["transformation-value-filter"] = TransformationValueFilter;
icons["transformation-waeventdatamodifier"] = TransformationWaeventdatamodifier;
icons["transformation-waeventtodwstage"] = TransformationWaeventtodwstage;
icons["transformation-waeventtotypedevent"] = TransformationWaeventtotypedevent;
icons["transformation-wametafilter-copy"] = TransformationWametafilterCopy;
icons["transformation-wametafilter"] = TransformationWametafilter;
icons["type"] = Type;
icons["udp"] = Udp;
icons["undeploy"] = Undeploy;
icons["vector-map"] = VectorMap;
icons["waction"] = Waction;
icons["waevent-to-dwstage"] = WaeventToDwstage;
icons["warning"] = Warning;
icons["webalert"] = Webalert;
icons["window"] = Window;
icons["windows"] = Windows;
icons["xml"] = Xml;
icons["quick-start"] = QuickStart;
icons["servicenow"] = ServiceNow;
icons["jira"] = JiraReader;
//DO-NOT-REMOVE-BELOW-COMMENT
//add-new-property
icons["yugabytedb"] = YugabyteDB;
icons["bamboohr"] = BambooHr;
icons["googlesheets"] = GoogleSheets;
icons["stripe"] = Stripe;
icons["zendesk"] = Zendesk;
icons["adls"] = ADLS;
icons["intercom"] = Intercom;
icons["dataverse"] = Dataverse;
icons["yellowbrick"] = Yellowbrick;
icons["db2-zos"] = Db2Zos;
icons["googleanalytics"] = GoogleAnalytics;
icons["linkedinads"] = LinkedInAds;
icons["paymo"] = Paymo;
icons["fabricmirror"] = FabricMirror;
export { icons };

import React from "react";
import PropTypes from "prop-types";

export default class IconByName extends React.Component {
    render() {
        if (!icons[this.props.icon]) {
            // eslint-disable-next-line no-console
            console.error("Icon not found:" + this.props.icon);
            return <div>{this.props.icon}</div>;
        }
        let Tag = icons[this.props.icon];
        return <Tag {...this.props} />;
    }
}

IconByName.propTypes = {
    icon: PropTypes.string.isRequired
};
