import React, { forwardRef, useState, useImperativeHandle } from "react";
import { sxStyles } from "./collapsible-section.styles";
import { ChevronUp, ChevronDown } from "src/generic/icon/customIcons";
import { StriimTypography } from "@striim/striim-ui-v2";
import { SvgIcon, Grid, Box } from "@mui/material";

const CollapsibleSection = (
    { children, label, isExpanded, content = undefined, color = "#00A7E5", fullWidth = false, ...rest },
    ref
) => {
    const [expanded, setExpanded] = useState(isExpanded ?? false);
    useImperativeHandle(ref, () => ({
        collapse() {
            setExpanded(false);
        },
        unCollapse() {
            setExpanded(true);
        }
    }));
    return (
        <Box sx={sxStyles.parentGrid}>
            <Grid container onClick={() => setExpanded(prevState => !prevState)}>
                <Grid
                    item
                    sx={{
                        ...sxStyles.iconGrid,
                        ...(expanded ? sxStyles.showBorder : sxStyles.hideBorder)
                    }}
                >
                    <SvgIcon
                        component={expanded ? ChevronUp : ChevronDown}
                        sx={{
                            ...sxStyles.collapsibleIcon,
                            "& > path": {
                                stroke: color
                            }
                        }}
                    />
                </Grid>
                <Grid
                    sx={{
                        ...sxStyles.childGrid,
                        ...(expanded ? sxStyles.showBorder : sxStyles.hideBorder)
                    }}
                    data-test-id="collapsible-container-header"
                    {...rest}
                >
                    <Grid container sx={sxStyles.childGridContainer}>
                        <Grid item>
                            <StriimTypography
                                variant="body4"
                                sx={{ color: color }}
                                data-test-id="vault-value-collapsible-label"
                            >
                                {label}
                            </StriimTypography>
                        </Grid>
                        {content && (
                            <Grid
                                item
                                sx={
                                    fullWidth
                                        ? {
                                              flexGrow: 1
                                          }
                                        : {}
                                }
                            >
                                {/* Add container prop here */}
                                <StriimTypography variant="body4" sx={sxStyles.typography}>
                                    {content}
                                </StriimTypography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ padding: expanded ? 1.25 : 0, marginLeft: 5 }}>
                <Box sx={expanded ? sxStyles.expanded : sxStyles.collapsed}>{children}</Box>
            </Box>
        </Box>
    );
};

export default forwardRef(CollapsibleSection);
