import React from "react";
import { StriimCircularLoader, StriimTypography } from "@striim/striim-ui";
import { Grid } from "@mui/material";

import { styles } from "./discovery-in-progress.styles";

interface DiscoveryInProgressProps {}

const DiscoveryInProgress = ({}: DiscoveryInProgressProps) => {
    return (
        <Grid container p={2} gap={2} flexDirection={"column"} sx={styles.container}>
            <Grid container flexDirection={"column"} gap={1} width={"100"}>
                <Grid item display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <StriimTypography variant="h4">Discovering Sensitive Data</StriimTypography>
                    <StriimCircularLoader />
                </Grid>
            </Grid>
            <Grid container flexDirection={"column"} gap={1} width={"100"}>
                <StriimTypography variant="body4" color="greyscale.800">
                    Sherlock is analyzing a sample of your sources.
                </StriimTypography>
                <StriimTypography variant="body4" color="greyscale.800">
                    This discovery may take up to several minutes depending on data volumes and hardware (CPU/GPU) configuration. Sampling is used.
                </StriimTypography>
            </Grid>
        </Grid>
    );
};

export default DiscoveryInProgress;
