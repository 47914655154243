import React, { useCallback, useState, useRef, useMemo } from "react";
import { observer } from "mobx-react-lite";

import useStores from "src/utils/use-stores";
import NoApps from "./components/no-apps";
import AppListFilters from "./components/app-list-filters";
import LoadingIndicator from "src/generic/loading-indicator";
import { useReactHeader } from "src/hooks/useReactHeader";
import useEffect from "src/hooks/useInitializedEffect";
import useStyles from "./app-list-page.styles";
import { SubNav, AppList } from "./components/app-list";
import AppGroups from "./components/app-group/app-groups";
import DataTable from "src/modules/apps/pages/app-list/components/app-list/data-table/data-table";
import AppListService from "./app-list-service";
import { Grid } from "@mui/material";
import AutomatedAppGroupDetail from "./components/automated-app-group-detail/automated-app-group-details";

const POLLING_INTERVAL = 15_000;

const AppListPage = observer(() => {
    const { store, groupsStore } = useStores();
    const classes = useStyles();
    const selectedMode = AppListService.getSelectedView();
    const [mode, setMode] = useState(selectedMode ? selectedMode : "list");
    const [loading, setLoading] = useState(true);
    const [selectedGroup, setSelectedGroup] = useState();
    const [selectedApps, setSelectedApps] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [clearSelections, setClearSelections] = useState(false);
    const [groups, setGroups] = useState([]);
    const [filteredApps, setFilteredApps] = useState([]);
    const [apps, setApps] = useState();
    const [isFiltering, setIsFiltering] = useState(false);
    const [editColumnsModalSubmitted, setEditColumnsModalSubmitted] = useState(false);
    const [stage, setStage] = useState("Initial");

    const ref = useRef();

    const appGroupSort = (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    const appSort = (a, b) => (a.id.toLowerCase() > b.id.toLowerCase() ? 1 : -1);
    const appSortDesc = (a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? 1 : -1);

    useReactHeader({ title: "Apps" });
    const MemoizedAppGroupDetails = useMemo(() => {
        if (selectedGroup?.groupType === "ILCDC") {
            return <AutomatedAppGroupDetail stage={stage} selectedGroup={selectedGroup} />;
        }
    }, [selectedGroup, stage]);

    const selectAllTiles = () => {
        setSelectAll(true);
        setClearSelections(false);
        setSelectedApps(filteredApps);
    };

    const clearAppSelections = () => {
        setSelectAll(false);
        setClearSelections(true);
        setSelectedApps([]);
    };

    const onModeChange = () => {
        const newMode = mode === "grid" ? "list" : "grid";
        AppListService.setSelectedView(newMode);
        setMode(newMode);
    };

    useEffect(() => {
        setGroups(groupsStore.getOrderedGroups().sort(appGroupSort));
        // update selectedGroup since it might have changed on the server
        const foundGroup = groupsStore.groups.find(value => selectedGroup?.name === value?.name);
        foundGroup && setSelectedGroup(foundGroup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupsStore.groups]);

    useEffect(() => {
        clearAppSelections();
        const sortType = selectedGroup?.groupType === "ILCDC" ? appSortDesc : appSort;
        setApps(store.apps.sort(sortType));
    }, [selectedGroup]);

    useEffect(() => {
        setApps(store.apps.sort(appSort));
    }, [store.apps]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await store.fetchApps();
            await groupsStore.fetch();
            await store.getAppListTableStats();
            store.listenToAppStatusChange(updateBasedOnAppChange);
            setApps(store.apps.sort(appSort));
            setLoading(false);
        };
        fetchData();

        const interval = setInterval(async () => {
            await store.getAppListTableStats();
        }, POLLING_INTERVAL);
        return () => clearInterval(interval);
    }, [groupsStore, store]);

    useEffect(() => {
        const handle = setInterval(async () => {
            await store.refreshAllStatuses();
        }, POLLING_INTERVAL);

        return () => {
            if (handle) {
                clearInterval(handle);
            }
        };
    }, [store]);

    const updateBasedOnAppChange = useCallback(() => {
        ref.current?.filter();
    }, []);

    const dropApplication = () =>
        new Promise(async (resolve, reject) => {
            try {
                await store.fetchApps();
                await groupsStore.fetch();
                await store.getAppListTableStats();
                resolve();
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    return (
        <>
            {loading ? (
                <LoadingIndicator />
            ) : !apps?.length ? (
                <NoApps />
            ) : (
                <>
                    <AppListFilters
                        selectedGroup={selectedGroup}
                        sourceApps={apps}
                        filteredApps={filteredApps}
                        isFiltering={isFiltering}
                        setFilteredApps={setFilteredApps}
                        setSelectedGroup={setSelectedGroup}
                        setIsFiltering={setIsFiltering}
                        ref={ref}
                    />
                    <Grid container justifyContent="center" className={classes.appList}>
                        <AppGroups
                            groups={groups}
                            selectedGroup={selectedGroup}
                            onGroupChange={group => {
                                // clear filters when changing groups, not when initializing with a new group
                                if (selectedGroup) {
                                    ref.current.clearFilters();
                                }
                                setSelectedGroup({ ...group });
                            }}
                            apps={apps}
                            filteredApps={filteredApps}
                            isFiltering={isFiltering}
                        />
                        <Grid item xs={10} sx={{ height: "81vh " }}>
                            <SubNav
                                mode={mode}
                                onModeChange={onModeChange}
                                apps={filteredApps}
                                selectedApps={selectedApps}
                                selectAllTiles={selectAllTiles}
                                clearAppSelections={clearAppSelections}
                                setEditColumnsModalSubmitted={setEditColumnsModalSubmitted}
                            />
                            {mode === "list" ? (
                                <DataTable
                                    setSelectedApps={setSelectedApps}
                                    filteredApps={filteredApps}
                                    selectedGroup={selectedGroup}
                                    clearSelections={clearSelections}
                                    setClearSelections={setClearSelections}
                                    editColumnsModalSubmitted={editColumnsModalSubmitted}
                                    dropApplication={dropApplication}
                                />
                            ) : (
                                <AppList
                                    apps={filteredApps}
                                    selectAll={selectAll}
                                    setSelectAll={setSelectAll}
                                    clearSelections={clearSelections}
                                    setSelectedApps={setSelectedApps}
                                    setClearSelections={setClearSelections}
                                    dropApplication={dropApplication}
                                />
                            )}
                            {MemoizedAppGroupDetails}
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
});

export default AppListPage;
