import { data2 } from "../../../sentinel-live-monitor/components/timeline-view-chart/testData";
import {
    AllAppsTableTypeRowFields,
    SentinelTableTypeRowFields
} from "../../../sentinel-live-monitor/components/sentinel-table/sentinel-table-utils";
import { random } from "underscore";
import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";

export const flowDesignerPreviewData = {
    eventsProcessed: 201,
    eventsWithSensitiveData: 101,
    eventsWithoutSensitiveData: 100,
    eventsTaggedAsSensitive: 95,
    occurrencesOfSensitiveData: 920,
    sensitiveDataActions: {
        ENCRYPTED: 500,
        MASKED: 500,
        NO_ACTION: 920
    },
    sensitiveDataIdentifierOccurrences: {
        SOCIAL_SECURITY_NUMBER: {
            label: "Social Security Number",
            total: 400,
            importance: "MEDIUM",
            MASK: 200,
            ENCRYPT: 100,
            NO_ACTION: 100
        },
        CREDIT_CARD_NUMBER: {
            label: "Credit Card Number",
            total: 800,
            importance: "HIGH",
            ENCRYPT: 40,
            MASK: 400,
            NO_ACTION: 0
        },
        NAME: {
            label: "Name",
            total: 300,
            importance: "LOW",
            MASK: 0,
            ENCRYPT: 100,
            NO_ACTION: 100
        },
        ADDRESS: {
            label: "Address",
            total: 200,
            importance: "MEDIUM",
            MASK: 0,
            ENCRYPT: 100,
            NO_ACTION: 100
        },
        SSN: {
            label: "SSN",
            total: 100,
            importance: "MEDIUM",
            MASK: 0,
            ENCRYPT: 100,
            NO_ACTION: 0
        },
        AGE: {
            label: "Age",
            total: 100,
            importance: "LOW",
            MASK: 0,
            ENCRYPT: 100,
            NO_ACTION: 0
        }
    },
    sentinels: {
        "admin.sentinel1": {
            discoveryEnabled: true,
            taggingEnabled: false
        },
        "admin.sentinel2": {
            discoveryEnabled: false,
            taggingEnabled: true
        },
        "admin.sentinel3": {
            discoveryEnabled: true,
            taggingEnabled: true
        }
    }
};

export const flowDesignerPreviewDataForIndividualSentinel = {
    eventsProcessed: 20,
    eventsWithSensitiveData: 10,
    eventsWithoutSensitiveData: 10,
    eventsTaggedAsSensitive: 9,
    occurrencesOfSensitiveData: 92,
    sensitiveDataActions: {
        ENCRYPTED: 50,
        MASKED: 50,
        NO_ACTION: 92
    },
    sensitiveDataIdentifierOccurrences: {
        SOCIAL_SECURITY_NUMBER: {
            label: "Social Security Number",
            total: 40,
            importance: "MEDIUM",
            MASK: 20,
            ENCRYPT: 10,
            NO_ACTION: 10
        }
    },
    sentinels: {
        "admin.sentinel1": {
            discoveryEnabled: true,
            taggingEnabled: false
        },
        "admin.sentinel2": {
            discoveryEnabled: false,
            taggingEnabled: true
        },
        "admin.sentinel3": {
            discoveryEnabled: true,
            taggingEnabled: false
        }
    }
};

export const allSentinelsEventsProcessed = {
    eventsProcessed: 400,
    eventsWithSensitiveData: 300,
    eventsWithoutSensitiveData: 100,
    occurrencesOfSensitiveData: 2920,
    sensitiveDataActions: {
        ENCRYPTED: 900,
        MASKED: 500,
        NO_ACTION: 920
    },
    sensitiveIdentifiers: 6,
    eventsTaggedAsSensitive: 95,
    sentinels: {
        "admin.sentinel1": {
            eventsProcessed: 1502,
            eventsWithSensitiveData: 502,
            eventsWithoutSensitiveData: 1000,
            eventsWithSensitiveDataTagged: 108,
            discoveryEnabled: true,
            taggingEnabled: true
        },
        "admin.sentinel2": {
            eventsProcessed: 3009,
            eventsWithSensitiveData: 2009,
            eventsWithoutSensitiveData: 1000,
            eventsWithSensitiveDataTagged: 0,
            discoveryEnabled: true,
            taggingEnabled: false
        },
        "admin.sentinel3": {
            eventsProcessed: 309,
            eventsWithSensitiveData: 209,
            eventsWithoutSensitiveData: 100,
            eventsWithSensitiveDataTagged: 10,
            discoveryEnabled: false,
            taggingEnabled: true
        }
    }
};

export const appSentinelsEventsProcessedChartData = chartData => {
    return {
        sensitiveData: chartData,
        nonSensitiveData: chartData,
        taggedSensitiveData: chartData
    };
};

export const individualSentinelEventsProcessed = {
    eventsProcessed: 100,
    eventsWithSensitiveData: 30,
    eventsWithoutSensitiveData: 70,
    occurrencesOfSensitiveData: 220,
    sensitiveDataActions: {
        ENCRYPTED: 90,
        MASKED: 50,
        NO_ACTION: 20
    },
    sensitiveIdentifiers: 2,
    eventsTaggedAsSensitive: 5,
    sentinels: {
        "admin.sentinel1": {
            eventsProcessed: 1502,
            eventsWithSensitiveData: 502,
            eventsWithoutSensitiveData: 1000,
            eventsWithSensitiveDataTagged: 108,
            discoveryEnabled: true,
            taggingEnabled: true
        },
        "admin.sentinel2": {
            eventsProcessed: 3009,
            eventsWithSensitiveData: 2009,
            eventsWithoutSensitiveData: 1000,
            eventsWithSensitiveDataTagged: 0,
            discoveryEnabled: true,
            taggingEnabled: false
        },
        "admin.sentinel3": {
            eventsProcessed: 309,
            eventsWithSensitiveData: 209,
            eventsWithoutSensitiveData: 100,
            eventsWithSensitiveDataTagged: 10,
            discoveryEnabled: false,
            taggingEnabled: true
        }
    }
};

export const allSentinelsSDIDrillDownEvents = (sdi, chartData) => ({
    sdi: sdi, //Real api would have it in readable format eg: "Credit Card Number"
    eventsProcessed: 200,
    eventsWithSensitiveData: 100,
    eventsWithoutSensitiveData: 100,
    occurrencesOfSensitiveData: 1920,
    sensitiveDataActions: {
        ENCRYPTED: 500,
        MASKED: 500,
        NO_ACTION: 920
    },
    sensitiveIdentifiers: 6,
    data: {
        sensitiveData: chartData,
        nonSensitiveData: chartData,
        total: chartData
    },
    sentinels: {
        "admin.sentinel1": {
            discoveryEnabled: true,
            taggingEnabled: false,
            eventsProcessed: 3000,
            eventsWithSensitiveData: 900,
            eventsWithSDI: {
                total: 200,
                eventsWithSensitiveData: 100,
                eventsWithoutSensitiveData: 100
            }
        },
        "admin.sentinel2": {
            discoveryEnabled: true,
            taggingEnabled: false,
            eventsProcessed: 2500,
            eventsWithSensitiveData: 1250,
            eventsWithSDI: {
                total: 500,
                eventsWithSensitiveData: 200,
                eventsWithoutSensitiveData: 140
            }
        }
    }
});

export const sentinelsSDIDrillDownEvents = (sdi, chartData) => ({
    sdi: sdi, //Real api would have it in readable format eg: "Credit Card Number"
    eventsProcessed: 100,
    eventsWithSensitiveData: 50,
    eventsWithoutSensitiveData: 50,
    occurrencesOfSensitiveData: 192,
    sensitiveDataActions: {
        ENCRYPTED: 50,
        MASKED: 50,
        NO_ACTION: 92
    },
    sensitiveIdentifiers: 6,
    data: {
        sensitiveData: chartData,
        nonSensitiveData: chartData,
        total: chartData
    }
});

export const sdiDrilldownFindingsChart = chartData => ({
    sdi: "Credit Card Number",
    eventsProcessed: 113,
    eventsWithSensitiveData: 11,
    eventsWithoutSensitiveData: 100,
    occurrencesOfSensitiveData: 222,
    sensitiveDataActions: {
        ENCRYPTED: 111,
        MASKED: 111,
        NO_ACTION: 0
    },
    sensitiveIdentifiers: 6,
    data: {
        encrypted: chartData,
        masked: chartData,
        noAction: chartData
    },
    sentinels: {
        "admin.sentinel1": {
            eventsWithIdentifier: 640,
            occurrences: 640,
            ENCRYPTED: 500,
            MASKED: 500,
            NO_ACTION: 920,
            discoveryEnabled: true,
            taggingEnabled: false
        },
        "admin.sentinel2": {
            eventsWithIdentifier: 640,
            occurrences: 640,
            ENCRYPTED: 500,
            MASKED: 500,
            NO_ACTION: 920,
            discoveryEnabled: false,
            taggingEnabled: true
        }
    }
});

export const AppsWithSentinelsDashboard = {
    applications: 120,
    appsWithSentinel: 80,
    appsWithDiscovery: 80,
    appsWithoutDiscovery: 40,
    importance: {
        HIGH: { ENCRYPT: 4, MASK: 1, NO_ACTION: 0 },
        MEDIUM: { ENCRYPT: 2, MASK: 1, NO_ACTION: 1 },
        LOW: { ENCRYPT: 0, MASK: 1, NO_ACTION: 1 }
    }
};

export const RunningAppsWithSentinels = {
    applicationsTotal: 120,
    Sentinels: 80,
    RunningApps: ["admin.App1", "sales.App2", "admin.App3"]
};

export const SentinelsDetails = {
    applications: 120,
    sentinels: [
        {
            name: "sentinel1",
            namespace: "admin",
            appName: "admin.App1",
            sensitiveIdentifiers: [
                {
                    name: "Credit Card Number",
                    importance: "HIGH",
                    action: "ENCRYPT"
                },
                {
                    name: "Name",
                    importance: "MEDIUM",
                    action: "NO_ACTION"
                }
            ],
            taggingEnabled: true,
            discoveryEnabled: true
        },
        {
            name: "sentinel2",
            namespace: "admin",
            appName: "admin.App1",
            sensitiveIdentifiers: [
                {
                    name: "SSN",
                    importance: "HIGH",
                    action: "MASK"
                }
            ],
            taggingEnabled: false,
            discoveryEnabled: true
        },
        {
            name: "sentinel3",
            namespace: "admin",
            appName: "admin.App1",
            sensitiveIdentifiers: [
                {
                    name: "Email",
                    importance: "MEDIUM",
                    action: "ENCRYPT"
                }
            ],
            taggingEnabled: true,
            discoveryEnabled: true
        },
        {
            name: "sentinel10",
            namespace: "admin",
            appName: "admin.App1",
            sensitiveIdentifiers: [
                {
                    name: "Email",
                    importance: "MEDIUM",
                    action: "ENCRYPT"
                }
            ],
            taggingEnabled: true,
            discoveryEnabled: true
        },
        {
            name: "sentinel4",
            namespace: "sales",
            appName: "sales.App2",
            sensitiveIdentifiers: [
                {
                    name: "Phone Number",
                    importance: "LOW",
                    action: "MASK"
                },
                {
                    name: "Email",
                    importance: "LOW",
                    action: "NO_ACTION"
                }
            ],
            taggingEnabled: false,
            discoveryEnabled: true
        },
        {
            name: "sentinel5",
            namespace: "sales",
            appName: "sales.App2",
            sensitiveIdentifiers: [
                {
                    name: "Date of Birth",
                    importance: "HIGH",
                    action: "ENCRYPT"
                }
            ],
            taggingEnabled: false,
            discoveryEnabled: false
        },
        {
            name: "sentinel6",
            namespace: "admin",
            appName: "admin.App3",
            sensitiveIdentifiers: [
                {
                    name: "Address",
                    importance: "MEDIUM",
                    action: "MASK"
                }
            ],
            taggingEnabled: true,
            discoveryEnabled: false
        },
        {
            name: "sentinel7",
            namespace: "admin",
            appName: "admin.App3",
            sensitiveIdentifiers: [
                {
                    name: "Driver's License",
                    importance: "HIGH",
                    action: "ENCRYPT"
                }
            ],
            taggingEnabled: true,
            discoveryEnabled: false
        },
        {
            name: "sentinel8",
            namespace: "admin",
            appName: "admin.App3",
            sensitiveIdentifiers: [
                {
                    name: "Bank Account",
                    importance: "HIGH",
                    action: "ENCRYPT"
                }
            ],
            taggingEnabled: false,
            discoveryEnabled: false
        }
    ]
};
export const eventsProcessedDataForApps = chartData => {
    const getAppData = count => {
        let applications = {};
        for (let i = 1; i < count; i++) {
            applications[`admin.app${i}`] = {
                events: Math.floor(Math.random() * 10000), // Random events
                eventsWithSensitiveData: Math.floor(Math.random() * 5000), // Random sensitive data events
                eventsTagged: Math.floor(Math.random() * 4000) // Random tagged events
            };
        }
        return applications;
    };

    return {
        eventsProcessed: 200,
        eventsWithSensitiveData: 100,
        eventsWithoutSensitiveData: 100,
        occurrencesOfSensitiveData: 1920,
        sensitiveDataActions: {
            ENCRYPTED: 500,
            MASKED: 500,
            NO_ACTION: 920
        },
        sensitiveIdentifiers: 6,
        eventsTaggedAsSensitive: 95,
        data: {
            sensitiveData: chartData,
            nonSensitiveData: chartData,
            taggedSensitiveData: chartData
        },
        applications: {
            "admin.app1": {
                events: 6000,
                eventsWithSensitiveData: 3000,
                eventsTagged: 3000
            },
            "admin.app2": {
                events: 4000,
                eventsWithSensitiveData: 1000,
                eventsTagged: 1000
            },
            ...getAppData(25)
        }
    };
};

export const SDIDrillDownEventsProcessedForApps = chartData => {
    return {
        sdi: "Credit Card Number",
        eventsProcessed: 100,
        eventsWithSensitiveData: 55,
        eventsWithoutSensitiveData: 45,
        occurrencesOfSensitiveData: 192,
        sensitiveDataActions: {
            ENCRYPTED: 50,
            MASKED: 50,
            NO_ACTION: 92
        },
        sensitiveIdentifiers: 6,
        data: {
            sensitiveData: chartData,
            nonSensitiveData: chartData,
            identifierSensitiveData: chartData
        },
        applications: {
            "admin.app3": {
                eventsProcessed: 6000,
                eventsWithSensitiveData: 3000,
                eventsTagged: 3000,
                eventsWithoutSensitiveData: 500,
                eventsWithSDI: 100,
                sentinels: 2
            },
            "admin.app4": {
                eventsProcessed: 4000,
                eventsWithSensitiveData: 1000,
                eventsTagged: 1000,
                eventsWithoutSensitiveData: 500,
                eventsWithSDI: 0,
                sentinels: 1
            }
        }
    };
};

const getRandomImportance = () => {
    const importances = ["HIGH", "MEDIUM", "LOW"];
    return importances[Math.floor(Math.random() * importances.length)];
};

export const sensitiveDataOccurrences = chartData => {
    // Function to add random sensitive data identifiers
    const getRandomSensitiveDataIdentifiers = count => {
        let sensitiveDataIdentifierOccurrences = {};

        for (let i = 0; i < count; i++) {
            const key = `SENSITIVE_DATA_${i + 1}`;
            const importance = getRandomImportance();
            const name = `${importance} SDI ${i + 1}`;
            sensitiveDataIdentifierOccurrences[key] = {
                label: name,
                eventsWithIdentifier: Math.floor(Math.random() * 1000),
                occurrences: Math.floor(Math.random() * 500),
                importance: importance,
                ENCRYPT: Math.floor(Math.random() * 500),
                MASK: Math.floor(Math.random() * 300),
                NO_ACTION: Math.floor(Math.random() * 200),
                SentinelCount: Math.floor(Math.random() * 200)
            };
        }
        return sensitiveDataIdentifierOccurrences;
    };

    return {
        eventsProcessed: 3750,
        eventsWithSensitiveData: 1000,
        eventsWithoutSensitiveData: 1000,
        occurrencesOfSensitiveData: 6000,
        sensitiveDataActions: {
            ENCRYPTED: 3000,
            MASKED: 2000,
            NO_ACTION: 1000
        },
        sensitiveIdentifiers: 6,
        data: {
            encrypted: chartData,
            masked: chartData,
            noAction: chartData
        },
        sentinels: {
            "admin.sentinel1": { discoveryEnabled: true, taggingEnabled: false },
            "admin.sentinel2": { discoveryEnabled: false, taggingEnabled: true }
        },
        sensitiveDataIdentifierOccurrences: {
            CREDIT_CARD_NUMBER: {
                label: "Credit Card Number",
                eventsWithIdentifier: 1200,
                occurrences: 1200,
                importance: "HIGH",
                ENCRYPT: 1000,
                MASK: 200,
                NO_ACTION: 0,
                sentinelCount: 2
            },
            NAME: {
                label: "Name",
                eventsWithIdentifier: 240,
                occurrences: 480,
                importance: "MEDIUM",
                ENCRYPT: 260,
                MASK: 100,
                NO_ACTION: 120,
                sentinelCount: 2
                // this count is only for the All Sentinels option
            },
            ADDRESS: {
                label: "Address",
                eventsWithIdentifier: 240,
                occurrences: 380,
                importance: "LOW",
                ENCRYPT: 0,
                MASK: 0,
                NO_ACTION: 380,
                sentinelCount: 2
                // this count is only for the All Sentinels option
            },
            ...getRandomSensitiveDataIdentifiers(25)
        }
    };
};

export const testDataBreakdownTable = [
    {
        name: "App1",
        processedEvents: 3000,
        dataTagged: "900 (100%)",
        disabled: false,
        sensitiveData: 900,
        nonSensitiveData: 2800,
        dynamicData: 300,
        tooltipDate: "JUL 3, 2024 1:01PM",
        identifiers: [
            { name: "Credit card number", importance: "High" },
            { name: "SSN", importance: "Medium" },
            { name: "Phone Number", importance: "Low" }
        ],
        identifiersAmount: 960,
        occurrencesAmount: 1000,
        actions: [
            { name: "Encrypted", amount: 800 },
            { name: "Masked", amount: 200 },
            { name: "NoAction", amount: 100 }
        ]
    },
    {
        name: "App2",
        processedEvents: 750,
        dataTagged: "0",
        disabled: false,
        sensitiveData: 600,
        nonSensitiveData: 750,
        dynamicData: 300,
        tooltipDate: "JUL 3, 2024 1:01PM",
        identifiers: [
            { name: "Gender", importance: "High" },
            { name: "Birthdate", importance: "Medium" },
            { name: "Phone Number", importance: "Low" }
        ],
        identifiersAmount: 240,
        occurrencesAmount: 400,
        actions: [
            { name: "Encrypted", amount: 400 },
            { name: "Masked", amount: 600 },
            { name: "NoAction", amount: 130 }
        ]
    },
    {
        name: "App3",
        processedEvents: 650,
        dataTagged: "0",
        disabled: false,
        sensitiveData: 500,
        nonSensitiveData: 650,
        dynamicData: 200,
        tooltipDate: "JUL 3, 2024 1:01PM",
        identifiers: [
            { name: "Gender", importance: "High" },
            { name: "Birthdate", importance: "Medium" },
            { name: "Phone Number", importance: "Low" }
        ],
        identifiersAmount: 140,
        occurrencesAmount: 300,
        actions: [
            { name: "Encrypted", amount: 300 },
            { name: "Masked", amount: 0 },
            { name: "NoAction", amount: 150 }
        ]
    }
];

export const testDataApp = [...Array(59).keys()]
    .map(mockID => ({
        [SentinelTableTypeRowFields.name]: "App " + mockID,
        [SentinelTableTypeRowFields.namespace]: "admin",
        [SentinelTableTypeRowFields.events]: random(2_000, 11_000),
        [SentinelTableTypeRowFields.sentinels]: random(0, 10)
    }))
    .map(row => ({
        ...row,
        [SentinelTableTypeRowFields.sensitiveData]: Math.round(
            row[SentinelTableTypeRowFields.events] * random(6, 9) * 0.1
        )
    }))
    .map(row => ({
        ...row,
        [SentinelTableTypeRowFields.nonSensitiveData]:
            row[SentinelTableTypeRowFields.events] - row[SentinelTableTypeRowFields.sensitiveData],
        [SentinelTableTypeRowFields.eventsType]: Math.round(
            row[SentinelTableTypeRowFields.sensitiveData] * random(0, 30) * 0.01
        )
    }));

export const appLevelSensitiveDataIdentifierOccurrences = {
    eventsProcessed: 200,
    eventsWithSensitiveData: 100,
    eventsWithoutSensitiveData: 100,
    occurrencesOfSensitiveData: 1920,
    sensitiveDataActions: {
        ENCRYPTED: 500,
        MASKED: 500,
        NO_ACTION: 920
    },
    sensitiveIdentifiers: 6,
    data: {
        encrypted: [
            [1726648380000, 100],
            [1726648440000, 0],
            [1726648560000, 300]
            // Total 60/24 sets for the 1 hour/24 hour window
        ],
        masked: [
            [1726648380000, 0],
            [1726648440000, 10],
            [1726648560000, 50]
            // Total 60/24 sets for the 1 hour/24 hour window
        ],
        noAction: [
            [1726648380000, 1],
            [1726648440000, 2],
            [1726648560000, 100]
            // Total 60/24 sets for the 1 hour/24 hour window
        ]
    },
    sensitiveDataIdentifierOccurrences: {
        CREDIT_CARD_NUMBER: {
            label: "Credit Card Number",
            eventsWithIdentifier: 640,
            occurrences: 640,
            importance: "HIGH",
            ENCRYPT: 260,
            MASK: 260,
            NO_ACTION: 0,
            applications: 20
        },
        NAME: {
            label: "Name",
            eventsWithIdentifier: 240,
            occurrences: 480,
            importance: "LOW",
            ENCRYPT: 260,
            MASK: 0,
            NO_ACTION: 0,
            applications: 10
        }
    }
};

export const SDIDrillDownOccurrences = chartData => {
    // Function to add more data into sensitiveDataIdentifierOccurrences
    const getSensitiveDataEntries = count => {
        let sensitiveDataIdentifierOccurrences = {};
        for (let i = 0; i < count; i++) {
            const key = `SENSITIVE_DATA_${i + 4}`; // Unique key for new entries (to avoid overlap)
            sensitiveDataIdentifierOccurrences[key] = {
                label: key,
                eventsWithIdentifier: Math.floor(Math.random() * 1000),
                occurrences: Math.floor(Math.random() * 1000),
                importance: getRandomImportance(),
                ENCRYPT: Math.floor(Math.random() * 500),
                MASK: Math.floor(Math.random() * 500),
                NO_ACTION: Math.floor(Math.random() * 300),
                applications: Math.floor(Math.random() * 100) // Randomized number of applications
            };
        }
        return sensitiveDataIdentifierOccurrences;
    };

    return {
        eventsProcessed: 200,
        eventsWithSensitiveData: 100,
        eventsWithoutSensitiveData: 100,
        occurrencesOfSensitiveData: 1920,
        sensitiveDataActions: {
            ENCRYPTED: 500,
            MASKED: 500,
            NO_ACTION: 920
        },
        sensitiveIdentifiers: 6,
        data: {
            ENCRYPT: chartData,
            MASK: chartData,
            NO_ACTION: chartData
        },
        sensitiveDataIdentifierOccurrences: {
            CREDIT_CARD_NUMBER: {
                label: "Credit Card Number",
                eventsWithIdentifier: 640,
                occurrences: 640,
                importance: "HIGH",
                ENCRYPT: 260,
                MASK: 260,
                NO_ACTION: 0,
                applications: 20
            },
            NAME: {
                label: "Name",
                eventsWithIdentifier: 240,
                occurrences: 480,
                importance: "LOW",
                ENCRYPT: 360,
                MASK: 20,
                NO_ACTION: 0,
                applications: 10
            },
            AGE: {
                label: "Age",
                eventsWithIdentifier: 140,
                occurrences: 480,
                importance: "MEDIUM",
                ENCRYPT: 40,
                MASK: 100,
                NO_ACTION: 20,
                applications: 44
            },
            ...getSensitiveDataEntries(25)
        }
    };
};

export const SDIDrillDownOccurrencesForApps = chartData => {
    return {
        sdi: "Credit Card Number",
        eventsProcessed: 50,
        eventsWithSensitiveData: 40,
        eventsWithoutSensitiveData: 10,
        occurrencesOfSensitiveData: 80,
        sensitiveDataActions: {
            ENCRYPTED: 30,
            MASKED: 30,
            NO_ACTION: 20
        },
        sensitiveIdentifiers: 5,
        data: {
            encrypted: chartData,
            masked: chartData,
            noAction: chartData
        },
        applications: {
            "admin.app1": {
                sentinels: 2,
                eventsWithIdentifier: 240,
                occurrences: 480,
                ENCRYPT: 260,
                MASK: 0,
                NO_ACTION: 0
            },
            "admin.app2": {
                sentinels: 1,
                eventsWithIdentifier: 40,
                occurrences: 480,
                ENCRYPT: 0,
                MASK: 0,
                NO_ACTION: 10
            }
        }
    };
};

export const testDataForAllAppsTable = [...Array(59).keys()]
    .map(mockID => ({
        [AllAppsTableTypeRowFields.name]: "App " + mockID,
        [AllAppsTableTypeRowFields.namespace]: "admin",
        [AllAppsTableTypeRowFields.events]: random(2_000, 11_000),
        [AllAppsTableTypeRowFields.eventsTagged]: random(2_000, 11_000)
    }))
    .map(row => ({
        ...row,
        [AllAppsTableTypeRowFields.sensitiveData]: Math.round(
            row[AllAppsTableTypeRowFields.events] * random(6, 9) * 0.1
        )
    }))
    .map(row => ({
        ...row,
        [AllAppsTableTypeRowFields.nonSensitiveData]:
            row[AllAppsTableTypeRowFields.events] - row[AllAppsTableTypeRowFields.sensitiveData]
    }));

export const SDIWithApplicationDetails = () => {
    const Adapters = Object.keys(PredefinedComponentNames);
    const getRandomAdapter = () => {
        return Adapters[Math.floor(Math.random() * Adapters.length)];
    };
    const getApplicationData = count => {
        let applications = {};
        for (let i = 1; i < count; i++) {
            const appKey = `admin.app${i}`;
            applications[appKey] = {
                sourceAdapters: [getRandomAdapter()],
                targetAdapters: [getRandomAdapter()],
                occurrences: Math.floor(Math.random() * 1000),
                eventsWithIdentifier: Math.floor(Math.random() * 500),
                sensitiveDataActions: {
                    ENCRYPTED: Math.floor(Math.random() * 100),
                    MASKED: Math.floor(Math.random() * 100),
                    NO_ACTION: Math.floor(Math.random() * 100)
                }
            };
        }
        return applications;
    };

    const getSDIs = count => {
        const sdiArray = {};
        for (let i = 1; i <= count; i++) {
            const key = `Sensitive_Data_${i}`;
            sdiArray[key] = {
                name: key,
                importance: getRandomImportance(),
                totalEventsWithIdentifier: Math.floor(Math.random() * 1000),
                totalOccurrences: Math.floor(Math.random() * 2000),
                applications: getApplicationData(Math.floor(Math.random() * 30)) // Generate application data for random count
            };
        }
        return sdiArray;
    };

    return getSDIs(10);
};
