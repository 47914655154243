import React, { useContext, useState } from "react";
import { StriimButton, StriimInputField, StriimTypography } from "@striim/striim-ui";
import { Grid, SvgIcon } from "@mui/material";
import { ActionLabel, ImportanceLabel } from "../sidebar.consts";
import { ArrowBack } from "../../../../../../generic/icon/customIcons";
import { SentinelMonitorContext } from "../../sentinel-monitor-context";
import { styles } from "./sentinel-table.styles";
import SentinelAppsDropdown from "../sentinel-dropdown/sentinel-apps-dropdown";

const SentinelTableHeader = ({ filterState, updateFilterState }) => {
    const { setSentinalByAction, RunningAppDetails, appSentinelMapping } = useContext(SentinelMonitorContext);
    const [selectedApps, setSelectedApps] = useState(RunningAppDetails.runningApps);

    const actions = [
        { value: "encrypt", label: ActionLabel["encrypt"] },
        { value: "mask", label: ActionLabel["mask"] },
        { value: "no_action", label: ActionLabel["no_action"] }
    ];

    const importance = [
        { value: "high", label: ImportanceLabel["high"] },
        { value: "medium", label: ImportanceLabel["medium"] },
        { value: "low", label: ImportanceLabel["low"] }
    ];

    const handleAppsSelect = selectedApps => updateFilterState("apps", selectedApps);

    const BackButton = () => {
        return (
            <StriimButton
                variant="text"
                startIcon={<SvgIcon component={ArrowBack} sx={styles.backIcon} />}
                sx={styles.iconButton}
                onClick={() =>
                    setSentinalByAction({
                        action: null,
                        importance: null,
                        apps: null
                    })
                }
            />
        );
    };

    const ActionDropdown = () => (
        <StriimInputField
            onChange={e => updateFilterState("action", e?.value)}
            select
            SelectProps={{ options: actions, isClearable: false }}
            value={{ value: filterState["action"], label: ActionLabel[filterState["action"]] }}
        />
    );

    const ImportanceDropdown = () => (
        <StriimInputField
            onChange={e => updateFilterState("importance", e?.value)}
            select
            SelectProps={{ options: importance, isClearable: false }}
            value={{ value: filterState["importance"], label: ImportanceLabel[filterState["importance"]] }}
        />
    );

    return (
        <Grid mb={2}>
            <StriimTypography variant="h3" mb={2} sx={styles.header}>
                <BackButton /> Sentinels with <ActionDropdown /> on <ImportanceDropdown />
                in
                <SentinelAppsDropdown
                    apps={RunningAppDetails.runningApps}
                    sentinelsCount={RunningAppDetails.sentinels}
                    handleAppsSelect={handleAppsSelect}
                    selectedApps={selectedApps}
                    setSelectedApps={setSelectedApps}
                    appSentinelMapping={appSentinelMapping}
                />
            </StriimTypography>
        </Grid>
    );
};

export default SentinelTableHeader;
