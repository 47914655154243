import SingleColumn from "app/components/common/editor/control/specific-meta-filter/single-column-dropdown-list";

var Transformation = {};

Transformation.OnlyDbSources = false;

Transformation.createColumnControl = function () {
    return SingleColumn.extend({
        hideLabel: true,
        hidden: false,
        transformationConfig: {
            preFillColumns: true,
            resetAvailable: true,
            autoFillAlias: true,
            allowClearColumns: false,
            labels: {
                column1: "Field Name and Type",
            },
        },
    }).create();
};

Transformation.buildTql = function (tqlBuilder, columns) {
    columns.forEach(
        function (column) {
            if (!column.column) {
                return;
            }

            tqlBuilder.addColumn(column.column, null, null, null);
        }.bind(this)
    );
};

export default Transformation;
