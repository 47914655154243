import { result } from "underscore";
import api from "../../../../core/api/api";
import { targetInfoType } from "../target/Types";
import { validationUtils } from "../utils";
import { getParams } from "./wizards-methods-helper";

async function validateConnection(target: string, targetInfo: targetInfoType) {
    const targetName: string = validationUtils.getTargetType(target).toUpperCase();
    const APIParams: Object = await validationUtils.getParams(targetName, targetInfo);
    try {
        switch (targetName) {
            case "ORACLE":
                return await api.OracleValidateConnection(APIParams);
            case "MSSQL":
            case "SQLSERVER":
                return await api.MSSQLValidateConnection(APIParams);
            case "MYSQL":
                return await api.MySQLValidateConnection(APIParams);
            case "POSTGRES":
                return await api.PostgresValidateConnection(APIParams);
            case "MARIADB":
                return await api.MariaDBValidateConnection(APIParams);
            case "BIGQUERY":
                return await api.BigQueryValidateConnection(APIParams);
            case "DATABRICKS":
            case "DELTALAKEWRITER":
                return await api.DeltaLakeWriterValidateConnection(APIParams);
            case "SNOWFLAKEWRITER":
                return await api.SnowflakeWriterValidateConnection(APIParams);
            case "FABRICDATAWAREHOUSEWRITER":
                return await api.FabricDataWarehouseWriterValidateConnection(APIParams);
            default:
                return {
                    message: "API method (ValidateConnection) for " + targetName + " is not configured",
                    result: false
                };
        }
    } catch (e) {
        return new Error(e);
    }
}

async function checkTargetPrivileges(target: string, targetInfo: targetInfoType) {
    const targetName: string = validationUtils.getTargetType(target).toUpperCase();
    const APIParams: Object = await validationUtils.getParams(targetName, targetInfo);
    APIParams["ConnectionURL"] = APIParams["ConnectionURL"] ?? APIParams["URL"];
    delete APIParams["URL"];
    try {
        switch (targetName) {
            case "ORACLE":
                return await api.OracleCheckTargetPrivileges(APIParams);
            case "MSSQL":
            case "SQLSERVER":
                return await api.MSSQLCheckTargetPrivileges(APIParams);
            case "MYSQL":
                return await api.MySQLCheckTargetPrivileges(APIParams);
            case "POSTGRES":
                return await api.PostgresCheckTargetPrivileges(APIParams);
            case "MARIADB":
                return await api.MariaDBCheckTargetPrivileges(APIParams);
            case "BIGQUERY":
                return await api.BigQueryCheckTargetPrivileges(APIParams);
            case "DELTALAKEWRITER":
                return await api.DeltaLakeWriterCheckTargetPrivileges(APIParams);
            case "SNOWFLAKEWRITER":
                return await api.SnowflakeWriterCheckTargetPrivileges(APIParams);
            case "FABRICDATAWAREHOUSEWRITER":
                return await api.FabricDataWarehouseWriterCheckTargetPrivileges(APIParams);
            default:
                return {
                    message: "API method (CheckTargetPrivileges) for " + targetName + " is not configured",
                    result: false
                };
        }
    } catch (e) {
        return new Error(e);
    }
}

async function checkSourcePrivileges(sourceName: string, sourceParams: targetInfoType) {
    const APIToCall = sourceName + "CheckPrivileges";
    const isDownStreamFilled = sourceParams.get("supportDownStreamCapture");
    const APIParams = await getParams(sourceName, sourceParams, isDownStreamFilled);
    const agentName = sourceParams.get("agentName") || null;
    try {
        switch (sourceName.toUpperCase()) {
            case "ORACLE":
                return await api.ORACLECheckPrivileges(APIParams, agentName);
            case "MSJET":
                return await api.MSJETCheckPrivileges(APIParams, agentName);
            case "OJET":
                return await api.OJETCheckPrivileges(APIParams, agentName);
            case "MARIADB":
                return await api.MARIADBCheckPrivileges(APIParams, agentName);
            case "MARIADBXPAND":
                return await api.MARIADBXPANDCheckPrivileges(APIParams, agentName);
            case "MSSQL":
            case "SQLSERVER":
                return await api.SQLSERVERCheckPrivileges(APIParams, agentName);
            case "MYSQL":
                return await api.MYSQLCheckPrivileges(APIParams, agentName);
            case "POSTGRES":
                return await api.POSTGRESCheckPrivileges(APIParams, agentName);
            case "SALESFORCE":
                return await api.SALESFORCECheckPrivileges(APIParams, agentName);
            default:
                return {
                    message: "API method (CheckTargetPrivileges) for " + sourceName + " is not configured",
                    result: false
                };
        }
    } catch (e) {
        return new Error(e);
    }
}

export { validateConnection, checkTargetPrivileges, checkSourcePrivileges };
