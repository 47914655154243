import $ from "jquery";
import _ from "underscore";
import TextField from "./text-field";
import metaStoreService from "core/services/metaStoreService/meta-store-service";

var MetaObjectTextModel = TextField.Model.extend({
    defaults: {
        value: String,
        metaObjectType: Object,
        nsName: String
    }
});

var MetaObjectTextView = TextField.View.extend({
    events: {
        "keyup input": "setValueFromView",
        "change input": "setValueFromView"
    },
    initialize: function () {
        TextField.View.prototype.initialize.apply(this, arguments);

        /**listen to changes in nsName**/
        this.listenTo(this, "value:update:nsName", this.updateNsName);
    },
    updateNsName: function (value) {
        var _this = this;
        this.model.set("nsName", value);
        this.validateAndSetValue();
    },
    validateAndSetValue: function () {
        /**check if value is valid and set the metaobject model if autosave**/
        var _this = this;
        this.nameValidator()
            .then(function (validationError) {
                if (_.isEmpty(validationError)) {
                    _this.hideError();
                    _this.trigger("value:set", _this.getValue());
                }
            })
            .fail(function (validationError) {
                _this.trigger("value:invalid", validationError, _this.getValue());
            });
    },
    validate: function () {
        var _this = this;
        return this.nameValidator()
            .then(function (validationError) {
                if (_.isEmpty(validationError)) {
                    _this.hideError();
                    return false;
                }
                return true;
            })
            .fail(function (validationError) {
                _this.trigger("value:invalid", validationError, _this.getValue());
                return true;
            });
    },
    setValueFromView: function () {
        var viewValue = this.getViewValue();

        if (_.isEqual(viewValue, this.model.value)) {
            return;
        }

        this.model.value = viewValue;
        this.validateAndSetValue();
    },
    getViewValue: function () {
        return $.trim(this.$("input").val());
    },
    setViewValue: function () {
        if (!this.getValue()) {
            this.$("input").val("");
        } else {
            this.$("input").val(this.getValue());
        }
    },
    nameValidator: function () {
        var deferred = $.Deferred();
        var metaObjectType = this.model.metaObjectType,
            nonAlphanumericOrUnderscore = /\W/g,
            viewValue = this.getViewValue();

        //if not a valid type return
        if (Array.isArray(metaObjectType)) {
            for (var i = 0; i < metaObjectType.length; i++) {
                if (!metaStoreService.entities[metaObjectType[i]]) {
                    deferred.reject("The provided type is not yet implemented");
                    return;
                }
            }
        } else {
            if (!metaStoreService.entities[metaObjectType]) {
                deferred.reject("The provided type is not yet implemented");
            }
        }

        //else check if it already exists
        //or if it is empty
        if (!viewValue.length) {
            deferred.reject("Value for name is required");
        } else if (viewValue.indexOf(" ") !== -1) {
            deferred.reject("Please enter a name without spaces.");
        } else if (nonAlphanumericOrUnderscore.test(viewValue)) {
            deferred.reject("Please enter a name with only alphanumeric or underscore characters.");
        } else if (!isNaN(parseInt(viewValue))) {
            deferred.reject("Name cannot start with a number");
        } else {
            if (Array.isArray(metaObjectType)) {
                var promises = [];
                for (var i = 0; i < metaObjectType.length; i++) {
                    var promise = metaStoreService
                        .findById(this.model.nsName + "." + metaObjectType[i].toUpperCase() + "." + viewValue)
                        .then(function (item) {
                            if (item) {
                                deferred.reject("This name already exists");
                                return;
                            }
                        });
                    promises.push(promise);
                }

                $.when.apply($, promises).then(function () {
                    deferred.resolve();
                });
            } else {
                if (!this.model.nsName) {
                    deferred.resolve();
                }

                metaStoreService
                    .findById(this.model.nsName + "." + metaObjectType.toUpperCase() + "." + viewValue)
                    .then(function (item) {
                        if (item) {
                            deferred.reject("This name already exists");
                        } else {
                            deferred.resolve();
                        }
                    });
            }
        }

        return deferred.promise();
    }
});

export default function (nsName, metaObjectType) {
    var Model = MetaObjectTextModel.extend({
        defaults: {
            nsName: nsName,
            metaObjectType: metaObjectType
        }
    });

    return _.extend({}, TextField, {
        View: MetaObjectTextView,
        Model: Model
    });
}
