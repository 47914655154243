import App from "app";
import api from "core/api/api";
import _ from "underscore";
import Backbone from "backbone";
import menu from "app/components/common/menu/menu";
import menuOption from "app/components/common/menu/menu-option";
import Dropdown from "app/components/common/dropdown";
import growl from "app/components/common/growl";
import appExporter from "./appExporter";
import statusManagement, { FlowStatus } from "src/status-management";
import toolbarGraphActionsTemplate from "./templates/toolbarGraphActionsTemplate.html";
import ProgressView from "app/components/flow/designer/progressView";
import "bowser";
import ModalManager from "app/components/common/modal/ModalManager";
import exceptionsApi from "./app-exceptions/exception-store-api";
import { segmentationVariants } from "src/modules/common/segmentation/segmentation.consts";
import { segmentationIndicator, segmentationTooltip } from "app/components/common/segmentationComponents";
import { showAlertsFor } from "app/components/alertmanager/alertmanager";
import securityService from "core/services/securityService/securityService";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import appStatusSynchronizer from "core/appStatusSynchronizer";
import AIInsightsIcon from "src/modules/ai-insights/discover-pii-panel/components/ai-insights-icon/ai-insights-icon.tsx";
import MarionetteWrapper from "../../marionette-wrapper-for-react";

var ToolbarGraphActions = {};

var modalManager = new ModalManager({
    container: "body"
});

// Timeout handle for exceptions store refresh function
let _exceptionsTimeout = null;
let _initialLoadTimeout = null;

ToolbarGraphActions.View = Backbone.Marionette.ItemView.extend({
    template: _.template(toolbarGraphActionsTemplate),
    tagName: "ul",
    className: "menu-icons",

    actionsEnabled: true,

    ui: {
        buttonDelete: "#action-delete",
        actionConfiguration: "#action-configuration",
        actionCut: "#action-cut",
        actionCopy: "#action-copy",
        actionPaste: "#action-paste",
        myObjects: "#action-myObjects",
        actionAutoLayout: "#action-autoLayout",
        actionProgressLayout: "#action-progress",
        actionAlerts: "#action-alerts",
        exceptions: "#exceptions",
        exceptionsCount: ".exceptions-count",
        configurationActionTooltip: ".configuration-action.tooltip" // Dropdown and tooltip are cannot be in same DOM el.
    },
    regions: {
        mainRegion: "#action-discover"
    },

    events: {
        "click @ui.editButton": "toggleEditMode",
        "click @ui.buttonDelete": "onDelete",
        "click @ui.actionCut": "onCut",
        "click @ui.actionCopy": "onCopy",
        "click @ui.actionPaste": "onPaste",
        "click @ui.myObjects": "onMyObjects",
        "click @ui.actionAutoLayout": "onAutoLayout",
        "click @ui.actionProgressLayout": "onProgressLayoutClick",
        "click @ui.actionAlerts": "onAlertsClick",
        "click @ui.exceptions": "onExceptionsClick",
        "click @ui.exceptionsCount": "onExceptionsClick"
    },

    initialize: function() {
        this.model.nodesClipboard.on("change:elements", () => this._toggleCopyPasteActions(this.model.app.flowStatus));
        this.model.on("change:nodes", () => this._toggleCopyPasteActions(this.model.app.flowStatus));
    },

    onRender: function() {
        var _this = this;

        // if onBeforeDestroy was not called then force
        // to clear timout of exception store when rendering again
        if (_exceptionsTimeout) {
            clearTimeout(_exceptionsTimeout);
        }
        if (_initialLoadTimeout) {
            clearTimeout(_initialLoadTimeout);
        }

        var menuOptions = [
            {
                id: "appsettings",
                text: "App settings",
                description: "Show application settings"
            },
            {
                id: "export",
                text: "Export",
                description: "Export this application as a TQL script"
            },
            {
                id: "monitor",
                /*icon: 'area-chart',*/ text: "Monitor",
                description: "Monitor App Performance"
            },
            {
                id: "recompile",
                /*icon: 'area-chart',*/ text: "Recompile",
                description: "Recompiles this application",
                title: "Application should be in Created state to recompile"
            }
            //{ id: 'explorer', text: 'Explore', description: 'Show explore' }
        ];

        if (this.options.isAutomatedGroup) {
            menuOptions.push({ id: "goToAutomationGroup", text: "Go to the Automation Pipeline" });
        }

        if (!this.model.app.isDataValidationEnabled) {
            menuOptions.push({
                id: "dashboard",
                text: "View Validation Dashboard",
                description: "Opens a dashboard related with this application",
                enabled: false,
                title: "Data validation is not enabled for this application."
            });
        } else {
            menuOptions.push({
                id: "dashboard",
                text: "View Validation Dashboard",
                description: "Opens a dashboard related with this application"
            });
        }

        this.actions = new menuOption.Model.Collection();
        menuOptions.forEach(function(mi) {
            var miModel = new menuOption.Model(mi);
            miModel.id = mi.id;
            _this.actions.add(miModel);
        });

        var menuModel = new menu.Model({
            options: _this.actions
        });

        var menuView = new menu.View({
            model: menuModel
        });

        _this.listenTo(menuView, "option:click", function(optionId) {
            if (this.actionsEnabled === false) {
                return false;
            }

            menuView.trigger("dropdown:hide");
            //this.trigger('menu-option-click', optionId);
            var menuAction = _this["execute_" + optionId + "Action"];
            if (menuAction) {
                menuAction.bind(this)();
            } else {
                alert(optionId);
            }
        });

        this.actionConfigurationMenu = new Dropdown({
            parentView: _this,
            content: menuView,
            trigger: _this.ui.actionConfiguration,
            position: "bottom",
            classes: "app-configuration-dropdown",
            on_show: function(tooltip /*, dropDownContainer*/) {
                //
                // set droppdown top baseline
                // and set arrow color - we need to use additional span element
                //
                var arrowBorderSpan = tooltip.find(".tooltipster-arrow-bottom>span");
                arrowBorderSpan.addClass("tooltipster-border");
                arrowBorderSpan.removeAttr("style");

                var arrowSpan = arrowBorderSpan.clone();
                arrowSpan.addClass("background");
                arrowSpan.removeAttr("style");

                tooltip.find(".tooltipster-arrow-bottom").append(arrowSpan);
                _this.ui.configurationActionTooltip.tooltipster("hide");
            }
        });

        this.toggleElementsEnabled(
            this.model.app.metaInfoStatus.isValid ? this.model.app.flowStatus : FlowStatus.INVALID,
            false
        );
        function toggleRecompileEnabled(enabled) {
            _this.actions
                .findWhere({
                    id: "recompile"
                })
                .set({
                    enabled: enabled
                });
        }
        this.listenTo(appStatusSynchronizer, this.model.app.id, function(status) {
            let isApplicationStatusCreated = statusManagement.isApplicationCreated(status);
            toggleRecompileEnabled(isApplicationStatusCreated);
        });
        if (!statusManagement.isApplicationCreated(this.model.app.flowStatus)) {
            toggleRecompileEnabled(false);
        } else {
            if (!this.model.get("app")) return;
            api.isEligibleForDataValidation(this.model.get("app").get("id")).then(function(isEligible) {
                if (!isEligible) {
                    let obj = _this.actions.findWhere({
                        id: "dashboard"
                    });
                    if (obj) {
                        obj.set({
                            enabled: false,
                            title: "This application does not support Data Validation."
                        });
                    }
                }
            });
        }

        //this.ui.exceptions.html(customIcons("exceptions-store"));
        //this.ui.actionProgressLayout.html(customIcons("application_progress"));
        this._initializeExceptions();
    },

    onBeforeDestroy: function() {
        if (_exceptionsTimeout) {
            clearTimeout(_exceptionsTimeout);
        }
        if (_initialLoadTimeout) {
            clearTimeout(_initialLoadTimeout);
        }

        if (this.progressView) {
            this.progressView.destroy();
        }
        this.actionConfigurationMenu.destroy();
    },

    onShow: function() {
        var _this = this;

        // .toolbar-graph-menu (Actions)
        this.$el.find(".tooltip").tooltipster({
            position: "bottom",
            offsetY: -5,
            contentAsHTML: true,
            functionReady: function(_origin, _tooltip) {
                _this.actionConfigurationMenu.hide();
                /*var $toolbar = $("#toolbar");
                tooltip.offset({
                    top: $toolbar.position().top + $toolbar.outerHeight(),
                    left: tooltip.offset().left
                });*/
            }
        });

        this.listenTo(App.vent, "show:table:progress:view", () => {
            this.onProgressLayoutClick();
        });

        // check licenses
        this._checkLicense(this.ui.exceptions, addonFeaturesKeys.EXCEPTION_STORE);
        this._checkLicense(this.ui.actionAlerts, addonFeaturesKeys.ALERTMANAGER);
        this._addAIInsightsIcon();
    },

    _checkLicense: function(el, license) {
        const variant = securityService.getSegmentationVariant(license);
        if (variant === segmentationVariants.none) {
            return;
        }

        el.append(segmentationIndicator(variant, "seg-indicator-top-bar-action"));

        const tooltipNeeded = [addonFeaturesKeys.ALERTMANAGER];
        if (tooltipNeeded.indexOf(license) === -1) {
            return;
        }

        const enabled = securityService.isSegmentationFeatureEnabled(license);
        const tooltipElement = el.parent();
        if (!enabled) {
            tooltipElement.tooltipster(segmentationTooltip(variant, license, tooltipElement));
        }
    },

    toggleMyObjectsActive: function(active) {
        this.ui.myObjects.parent().toggleClass("active", active);
    },

    onCut: function() {
        if (!this.modificationsEnabled) {
            return;
        }
        if (this.actionsEnabled === false) {
            return;
        }

        this.trigger("nodes-cut");
    },

    onCopy: function() {
        if (!this.modificationsEnabled) {
            return;
        }
        if (this.actionsEnabled === false) {
            return;
        }
        if (this.model.nodes.getSelectedNodes().length !== 1) {
            return;
        }

        this.trigger("nodes-copy");
    },

    onPaste: function() {
        if (!this.modificationsEnabled) {
            return;
        }
        if (this.actionsEnabled === false) {
            return;
        }

        this.trigger("nodes-paste");
    },

    onDelete: function() {
        if (!this.modificationsEnabled) {
            return;
        }
        if (this.actionsEnabled === false) {
            return;
        }
        this.trigger("node-delete");
    },

    onMyObjects: function() {
        // if (this.actionsEnabled == false) {
        //     return;
        // }
        this.trigger("my-objects");
    },

    onAutoLayout: function() {
        if (this.actionsEnabled === false) {
            return;
        }
        this.trigger("graph-autolayout");
    },

    onProgressLayoutClick: function() {
        this.progressView = new ProgressView.FullView({
            model: this.model
        });

        modalManager.add(this.progressView);
        new App.FormMixinDialog.Controller({
            view: this.progressView
        });

        this.progressView.on("form:submit", () => {
            this.progressView.destroy();
            this.progressView = null;
        });
    },

    onAlertsClick: function() {
        if (!securityService.isSegmentationFeatureEnabled(addonFeaturesKeys.ALERTMANAGER)) {
            return;
        }

        showAlertsFor(this.model.get("app").get("id"));
    },

    onExceptionsClick: function() {
        this._updateExceptionIcon().finally(() => {
            this.trigger("show-app-exceptions", this.hasExceptions);
        });
    },

    onDiscoverClick: function() {
        this.trigger("discover-pii");
    },

    onSentinelLiveMonitorClick: function() {
        this.trigger("sentinel-live-monitor");
    },

    execute_appsettingsAction: function() {
        this.trigger("show-app-settings");
    },

    execute_exportAction: function() {
        appExporter.exportApplication(this.model.current_app_full);
    },

    execute_monitorAction: function() {
        var url = "#/monitor/" + this.model.current_app_full;
        App.navigate(url, {
            trigger: true
        });
    },

    execute_dashboardAction: function() {
        if (this.model.app.applicationDashboardId) {
            var url = "#/dashboard/view/" + this.model.app.applicationDashboardId;
            App.navigate(url, {
                trigger: true
            });
        } else {
            var dashboardName = this.model.app.name + "_AppDashboard";
            api.createDataValidationDashboard(this.model.app.id, dashboardName)
                .then(function(dashboardID) {
                    growl.success(null, "Created Application Dashboard.");
                    var url = "#/dashboard/view/" + dashboardID;
                    App.navigate(url, {
                        trigger: true
                    });
                })
                .fail(function(e) {
                    growl.error("Could not create dashboard: " + e.message);
                });
        }
    },

    execute_recompileAction: function() {
        App.vent.trigger("compilation-needed", true);
    },

    execute_goToAutomationGroupAction: function() {
        this.options.moveToAppGroups();
    },

    toggleElementsEnabled: function(appStatus, refreshExceptionsCount) {
        this.modificationsEnabled = statusManagement.isApplicationCreated(appStatus);
        this.$el.toggleClass("toolbar-enabled", this.modificationsEnabled);
        this._toggleCopyPasteActions(appStatus);

        if (typeof refreshExceptionsCount === "undefined") {
            refreshExceptionsCount = true;
        }
        if (refreshExceptionsCount && statusManagement.isApplicationCrash(appStatus)) {
            setTimeout(() => {
                this._updateExceptionIcon().finally(result => {
                    if (!result) {
                        setTimeout(() => {
                            this._updateExceptionIcon();
                        }, 3000); // update was in progress, try to retry action
                    }
                });
            }, 3000);
        }
    },

    setAllActionsEnabled: function(actionsEnabled) {
        this.$el.toggleClass("locked", !actionsEnabled);
        this.actionsEnabled = actionsEnabled;
    },

    _toggleCopyPasteActions: function(appStatus) {
        const selectedNodes = this.model.nodes.filter(node => node.isSelected);
        const isCreated = statusManagement.isApplicationCreated(appStatus);
        const selectedDuplicableNodes = selectedNodes.filter(node => node.duplicable);

        this.ui.actionCut.toggleClass("disabled", !(selectedNodes.length > 0 && isCreated));
        this.ui.actionCopy.toggleClass(
            "disabled",
            !(selectedNodes.length === 1 && selectedDuplicableNodes.length === 1 && isCreated)
        );
        this.ui.actionPaste.toggleClass("disabled", !(this.model.nodesClipboard.containElements && isCreated));
        // For ILCDC Apps
        // Disable deleting app. So disable deleting if selectedNodes > 0
        const disableDelete = this.options.isAutomatedGroup ? !(selectedNodes.length > 0 && isCreated) : !isCreated;
        this.ui.buttonDelete.toggleClass("disabled", disableDelete);
        this.ui.actionAutoLayout.toggleClass("disabled", !(this.model.nodes.length > 0));
    },

    // when true, then Named query should not be called again
    _updatingExceptionIconInProgress: false,

    _updateExceptionIcon: function() {
        const _this = this;
        const appId = this.model.get("app").get("id");

        if (this._updatingExceptionIconInProgress) {
            console.log("Update exceptions store in progress");
            return new Promise(resolve => {
                resolve(false);
            });
        }
        this._updatingExceptionIconInProgress = true;

        return new Promise((resolve, reject) => {
            exceptionsApi
                .checkExceptionsEnabled(appId)
                .then(enabled => {
                    if (enabled) {
                        exceptionsApi
                            .countExceptions(_this.model.get("app"))
                            .then(count => {
                                if (_this.ui.exceptionsCount.removeClass) {
                                    _this.ui.exceptionsCount.removeClass("hidden-element");
                                    _this.ui.exceptionsCount.html(count > 99 ? "99+" : count);
                                    _this.ui.exceptionsCount.toggleClass("success", count === 0);
                                }
                                _this.hasExceptions = count > 0;
                                _this._updatingExceptionIconInProgress = false;
                                resolve();
                            })
                            .catch(() => {
                                _this._updatingExceptionIconInProgress = false;
                                reject();
                            });
                    } else {
                        if (_this.ui.exceptionsCount.addClass) {
                            _this.ui.exceptionsCount.addClass("hidden-element");
                        }
                        _this._updatingExceptionIconInProgress = false;
                        resolve();
                    }
                })
                .catch(() => {
                    _this._updatingExceptionIconInProgress = false;
                    reject();
                });
        });
    },

    _initializeExceptions: function() {
        const _this = this;

        this._updateExceptionIcon();

        function refreshExceptionIconInTimeout() {
            if (_exceptionsTimeout) {
                clearTimeout(_exceptionsTimeout);
            }
            _exceptionsTimeout = setTimeout(() => {
                _this._updateExceptionIcon().finally(refreshExceptionIconInTimeout);
            }, 30000); // refresh every 30 seconds
        }

        _initialLoadTimeout = setTimeout(() => {
            if (!_this.isDestroyed) {
                refreshExceptionIconInTimeout();
            }
        }, 3000); // call
    },

    _addAIInsightsIcon: function() {
        const _this = this;
        const appKey = this.model.app.nsName + "." + this.model.app.name + "_pii";
        const AIInsights = MarionetteWrapper(AIInsightsIcon, {
            appKey,
            onDiscoverClick: _this.onDiscoverClick.bind(_this),
            onSentinelLiveMonitorClick: _this.onSentinelLiveMonitorClick.bind(_this)
        });
        const parent = this.options.parentView;
        parent.$el.find(".toolbar-graph-menu .menu-icons").append("<li id='ai-insights'></li>");
        parent.addRegion("aiInsights", "#ai-insights");
        parent["aiInsights"].show(AIInsights);
    }
});

export default ToolbarGraphActions.View;
