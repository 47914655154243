import React, { useEffect } from "react";
import { useObserver } from "mobx-react";
import { TemplateGroup } from "./components/template-group/template-group";
import { classes } from "./create-app-content.styles";
import QuickStartGroup from "./components/quick-start-group";
import NoResultsTemplates from "./components/templates-no-results";
import useCreateAppsStore from "src/stores/create-apps/useCreateAppsStore";
import { StriimTypography } from "@striim/striim-ui";
import { Box } from "@mui/material";

// eslint-disable-next-line react/display-name
const CreateAppHome = React.memo(({ defaultTemplateGroups }) => {
    const createAppStore = useCreateAppsStore();
    useEffect(() => {
        createAppStore.clearStore();
    }, []);
    return (
        <>
            <StriimTypography variant={"h2"} sx={classes.heading}>
                Build using Flow Designer
            </StriimTypography>

            <Box sx={classes.heading2Container}>
                <StriimTypography variant={"body4"} sx={classes.heading2}>
                    Flow designer gives the power to create complex flows by dragging in all supported components.
                </StriimTypography>
            </Box>
            <QuickStartGroup />
            <Box sx={classes.headingContainer}>
                <StriimTypography variant={"h2"} sx={classes.heading}>
                    Build using Wizards
                </StriimTypography>
            </Box>
            <Box sx={classes.heading2Container}>
                <StriimTypography variant={"body4"} sx={classes.heading2}>
                    Wizards help you quickly create pipelines using pre-defined source-target combinations with default
                    configurations
                </StriimTypography>
            </Box>
            {defaultTemplateGroups.map(({ campaignDesc, templates, campaignKey, icon, generic = false }) => {
                let hidden = templates.filter(temp => temp.enabledByLicense).length === 0;
                let licensedTemplates = templates.filter(temp => temp.enabledByLicense === true);
                // for Automated groups the topologies are already sorted in getCampaigns.jhs
                if (campaignKey !== "Automated Groups") {
                    licensedTemplates.sort((t1, t2) => t1.title.localeCompare(t2.title));
                }

                return (
                    <TemplateGroup
                        key={campaignKey}
                        title={campaignDesc}
                        templates={licensedTemplates}
                        icon={icon}
                        hidden={hidden}
                        generic={generic}
                    />
                );
            })}
        </>
    );
});
//sorting templates so that IL/CDC templates are at the front
const sortTemplates = (a, b) => {
    if (a.isAutomatedILCDC && !b.isAutomatedILCDC) {
        return -1;
    }
    if (!a.isAutomatedILCDC && b.isAutomatedILCDC) {
        return 1;
    }
    const titleA = a.title.toUpperCase();
    const titleB = b.title.toUpperCase();

    if (titleA < titleB) {
        return -1;
    } else if (titleA > titleB) {
        return 1;
    } else {
        return 0;
    }
};

const CreateAppPage = () => {
    const store = useCreateAppsStore();
    const shouldRenderSearchResults = useObserver(() => store.shouldRenderSearchResults);
    const filteredTemplates = useObserver(() => store.filteredTemplates);
    const defaultTemplateGroups = useObserver(() => store.defaultTemplateGroups);

    return (
        <div data-test-id={"create-app-home"}>
            <div hidden={!shouldRenderSearchResults} data-test-id={"search-results-container"}>
                <>
                    <StriimTypography variant="h2" sx={classes.searchResults}>
                        Search results
                    </StriimTypography>
                    {!filteredTemplates.length && <NoResultsTemplates />}
                    {filteredTemplates.length !== 0 && (
                        <TemplateGroup
                            templates={filteredTemplates.sort(sortTemplates)}
                            expandable={false}
                            isSearchResults={true}
                        />
                    )}
                </>
            </div>

            <div hidden={shouldRenderSearchResults}>
                <>
                    <CreateAppHome defaultTemplateGroups={defaultTemplateGroups} />
                </>
            </div>
        </div>
    );
};

export default CreateAppPage;
