import React, { useEffect, useState } from "react";
import AppProgressErrorBox from "../../../../../../table-progress/Components/schema-creation-errors/schema-creation-errors";
import { Grid } from "@mui/material";
import automatedGroupStore from "../automated-app-group-details.store";
import statusManagement from "../../../../../../../status-management";
import { AutomatedAppGroupProps } from "../automated-app-group-details";

const AutomatedAppGroupErrorsComponent: React.FunctionComponent<AutomatedAppGroupProps> = ({ selectedGroup }) => {
    const [errorAppId, setErrorAppId] = useState<string>(null);
    const statuses = automatedGroupStore?.appStatuses;

    useEffect(() => {
        function checkForAppErrors() {
            if (statuses) {
                for (const appId in statuses) {
                    if (statusManagement.isApplicationCrash(statuses[appId])) {
                        setErrorAppId(appId);
                        return;
                    }
                }
                setErrorAppId(null);
            } else {
                setErrorAppId(null);
            }
        }
        checkForAppErrors();
    }, [statuses, selectedGroup.id]);

    if (!errorAppId) {
        return true;
    }
    return (
        <Grid item sx={{ mt: 2 }}>
            <AppProgressErrorBox appId={errorAppId} />
        </Grid>
    );
};

export default AutomatedAppGroupErrorsComponent;
