import { computed, observable } from "mobx";
import statusManagement from "../status-management";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";

export default class ApplicationModel {
    @observable id;
    @observable nsName;
    @observable name;
    @observable flowStatus;
    @observable metaInfoStatus;
    @observable isEditable;
    @observable cpu;
    @observable rate;
    @observable wactions;
    @observable sources = [];
    @observable targets = [];
    @observable metrics = [];
    @observable isSelected = false;
    @observable dashboardId;
    @observable exceptionstoreName;
    @observable isFetching;
    @observable mostRecentData;

    @computed get shortName() {
        return metaObjectConverter.getShortId(this.nsName, this.name);
    }

    @computed get statusTransitions() {
        let appActions = statusManagement.getStatus(this.flowStatus);

        if (!this.dashboardId) {
            if (appActions && appActions.actions) {
                const actions = appActions.actions.filter(x => x !== "DASHBOARD");
                appActions.actions = actions;
            }
        }

        return appActions;
    }
}
