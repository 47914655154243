import React, { useState } from "react";
import PropTypes from "prop-types";
import utils from "core/utils";
import resources from "../system_health_monitor_resources.json";
import { makeStyles } from "@material-ui/styles";

import {
    StriimDropdown,
    StriimIconButton,
    StriimTypography,
    StriimList,
    StriimListItem,
    StriimChip
} from "@striim/striim-ui";
import { ListSubheader, Grid } from "@mui/material";
import { ExpandMoreRounded, ExpandLessRounded } from "@mui/icons-material";
import MetricCard from "src/modules/homepage/common/metric_card.jsx";
import { StateToChipVariant } from "../../../../../status-management";
import OthersIcon from "src/generic/icon/customIcons/others-state.svg";

const useStyles = makeStyles(() => ({
    distributionBox: {
        paddingLeft: 24,
        minWidth: 150
    },
    statusAvatar: {
        height: 12,
        width: 12
    },
    statusText: {
        fontSize: 12,
        height: 12
    },
    othersDropDown: {
        cursor: "pointer"
    },
    dropDownButton: {
        height: 12,
        width: 12
    },
    statusDropdownAvatar: {
        height: 20,
        width: 20
    },
    customCardStyles: {
        border: "1px solid #D8E4F0",
        borderRadius: "8px 0px 0px 8px"
    }
}));

const AppsMonitorTile = ({ total, appsData }) => {
    const appStatusColor = resources.appStatusColor;
    const appStatusDistributionShown = resources.appStatusDistributionShown;
    const classes = useStyles();
    const [showOthers, setShowOthers] = useState(false);

    const getPieChartData = () => {
        let pieChartData = [];
        appStatusDistributionShown.map(status => {
            pieChartData.push({
                name: utils.capitalize(status),
                color: appStatusColor[status.toLowerCase()]?.color,
                value: appsData[status]
            });
        });
        return pieChartData;
    };

    const AppDistributionByStatus = () => {
        return (
            <Grid container className={classes.distributionBox}>
                {appStatusDistributionShown
                    .filter(status => appsData[status])
                    .map(status => (
                        <Grid
                            item
                            container
                            key={status + appsData[status]}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{ gap: 1 }}
                        >
                            <StriimChip
                                icon={status === "others" && <OthersIcon />}
                                variant={StateToChipVariant[status]}
                                label={status + " (" + appsData[status] + ")"}
                                sx={{ textTransform: "capitalize", color: "greyscale.700" }}
                            />
                            {status == "others" && appsData[status] && (
                                <StriimDropdown
                                    extendOnClick
                                    controlState={[showOthers, setShowOthers]}
                                    openDelay={400}
                                    closeDelay={5}
                                    content={<DropDownList />}
                                >
                                    <div className={classes.othersDropDown}>
                                        <StriimIconButton className={classes.dropDownButton}>
                                            {showOthers ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                                        </StriimIconButton>
                                    </div>
                                </StriimDropdown>
                            )}
                        </Grid>
                    ))}
            </Grid>
        );
    };

    const DropDownList = () => {
        return (
            <StriimList sx={{ minWidth: "320px" }} subheader={<ListSubheader disableSticky>Others</ListSubheader>}>
                {appsData?.otherStatusList?.map(otherStatus => {
                    const status = otherStatus.status;
                    const count = otherStatus.count;
                    return (
                        <StriimListItem
                            key={status + count}
                            text={<StriimTypography variant="caption1">{utils.capitalize(status)}</StriimTypography>}
                            secondaryAction={
                                <StriimTypography variant="caption1">
                                    {count} / {total}
                                </StriimTypography>
                            }
                        />
                    );
                })}
            </StriimList>
        );
    };

    return (
        <MetricCard
            title={`Apps (${total})`}
            pieChartData={getPieChartData()}
            CustomComponent={AppDistributionByStatus}
            customCardStyles={classes.customCardStyles}
        />
    );
};

export default AppsMonitorTile;

AppsMonitorTile.propTypes = {
    total: PropTypes.number.isRequired,
    appsData: PropTypes.object.isRequired
};
