import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var module = {};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "SYSALERTRULE",
        objectName: null,
        eventType: null,
        comparator: null,
        alertValue: null,
        alertValueUnit: null,
        alertType: null,
        alertInterval: null,
        isEnabled: null,
        isSysDefined: null,
        alertMessage: null,
        toAddress: null
    }
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model
});

export default module;
