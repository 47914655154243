import ArrayDataSource from "app/components/common/editor/control/select/array-datasource";
import SingleColumnDropdown from "app/components/common/editor/control/specific-meta-filter/single-column-dropdown-list";

var Transformation = {};

Transformation.OnlyDbSources = true;

Transformation.createColumnControl = function () {
    return SingleColumnDropdown.extend({
        hideLabel: true,
        hidden: false,
        transformationConfig: {
            preFillColumns: false,
            addAvailable: true,
            addLabel: "ADD CONDITION",
            columnsSource: getOperations,
            labels: {
                column1: "Operation type",
            },
        },
    }).create();
};

function getOperations() {
    return ArrayDataSource(["INSERT", "UPDATE", "DELETE"]);
}

Transformation.buildTql = function (tqlBuilder, columns) {
    tqlBuilder.addWAEventWildcard();

    columns.forEach(function (column) {
        if (!column.column) {
            return;
        }

        tqlBuilder.addMetaFilteringStatement("OperationName", "=", column.column);
    });
};

export default Transformation;
