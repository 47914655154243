import React, { useState, useMemo } from "react";
import { Grid, SvgIcon } from "@mui/material";
import {
    StriimButton,
    StriimChip,
    StriimIconButton,
    StriimTypography,
    StriimDropdown,
    StriimInputField,
    StriimTooltip
} from "@striim/striim-ui";

import { styles } from "./job-details-header.styles";
import { ChevronLeft, Download, InfoCircle, Refresh, Delete } from "../../../../../generic/icon/customIcons";
import dateLib from "core/utils/date-utils";
import StriimCircularProgress from "../../../../apps/pages/manage-striim/connection-profiles/components/circular-progress/circular-progress";
import { JobStatuses } from "../../guardian-job.types";
import { getRelativeTime } from "../job-apps-table/job-apps-table-filter";
import Printable from "../../../reports/printable";
import SherlockGlobalReport from "../../../reports/sherlock-report/sherlock-global-report";

//TODO: Refactor
const StateToChipVariant = {
    completed: "success",
    running: "secondary",
    created: "secondary",
    error: "error"
};

interface JobDetailsHeaderProps {
    name: string;
    description?: string;
    onBack: Function;
    onDownload: Function;
    status: JobStatuses;
    showRetry?: boolean;
    generationTime: number;
    onRetry?: Function;
    completedTime: number;
    simpleHeading?: boolean;
    subtitle?: string;
    updateDescription?: (description: string) => void;
    fetchReportData: any;
    onDelete?: Function;
    onCancel?: Function;
    customHeading?: JSX.Element;
}

const JobDetailsHeader = ({
    name,
    description,
    onBack,
    onDownload,
    onRetry,
    status,
    showRetry = false,
    generationTime,
    completedTime,
    simpleHeading = false,
    customHeading = null,
    subtitle,
    updateDescription,
    fetchReportData,
    onDelete,
    onCancel
}: JobDetailsHeaderProps) => {
    const [updatedDescription, setUpdatedDescription] = useState<string | null>(null);
    const controlState = useState<boolean>(false);

    const relativeTime = useMemo(() => {
        return getRelativeTime(completedTime);
    }, [completedTime]);

    const formattedGenerationTime = (
        <>
            {generationTime
                ? `${dateLib.unix(generationTime).format("MMM DD, YYYY")} at ${dateLib
                    .unix(generationTime)
                    .format("hh:mm A")}`
                : "-"}
        </>
    );
    const formattedCompletedTime = !!completedTime ? (
        <>
            {dateLib.unix(completedTime).format("MMM DD, YYYY")} at {dateLib.unix(completedTime).format("hh:mm A")}
        </>
    ) : (
        "-"
    );

    const DownloadButton = (
        <StriimButton
            variant={showRetry ? "text" : "primary"}
            startIcon={
                <SvgIcon component={Download} sx={showRetry ? styles.secondaryDownloadIcon : styles.downloadIcon} />
            }
            disabled={[JobStatuses.CREATED, JobStatuses.RUNNING].includes(status)}
            onClick={onDownload}
        >
            Report
        </StriimButton>
    );

    return (
        <Grid container alignItems={"center"} mb={3}>
            <Grid item mr={2}>
                <StriimIconButton
                    variant="secondary"
                    sx={styles.backButton}
                    data-testid="job-details-back-button"
                    onClick={onBack}
                >
                    <SvgIcon component={ChevronLeft} sx={styles.backIcon} />
                </StriimIconButton>
            </Grid>
            <Grid item display={"flex"} alignItems={"center"} gap={1} mr={3}>
                {customHeading
                    ? customHeading
                    : simpleHeading ? (
                        <Grid sx={styles.simpleHeading}>
                            <StriimTypography
                                variant="h1"
                                sx={{
                                    ...styles.heading,
                                    maxWidth: simpleHeading ? "500px" : styles.heading.maxWidth
                                }}
                            >
                                {name}
                            </StriimTypography>
                            <StriimTypography variant="caption4" color="greyscale.700">
                                {subtitle}
                            </StriimTypography>
                        </Grid>
                    ) : (
                        <>
                            <StriimTooltip title={name} placement="top">
                                <StriimTypography variant="h1" sx={styles.heading}>
                                    {name}
                                </StriimTypography>
                            </StriimTooltip>
                            <StriimDropdown
                                hover
                                PopperProps={{
                                    disablePortal: true
                                }}
                                onClose={() => setUpdatedDescription(null)}
                                controlState={controlState}
                                placement="bottom-start"
                                content={
                                    <Grid container flexDirection={"column"} sx={styles.descriptionWrapper} gap={1}>
                                        <StriimInputField
                                            label="Description"
                                            value={updatedDescription ?? description}
                                            multiline={true}
                                            rows={5}
                                            onChange={value => setUpdatedDescription(value)}
                                        />
                                        <Grid item display={"flex"} justifyContent={"flex-end"} gap={0.5}>
                                            <StriimButton
                                                variant="text"
                                                onClick={() => {
                                                    controlState[1](false);
                                                }}
                                            >
                                                Cancel
                                            </StriimButton>
                                            <StriimButton
                                                variant="primary"
                                                disabled={updatedDescription === null || updatedDescription === description}
                                                onClick={() => {
                                                    updateDescription(updatedDescription);
                                                    controlState[1](false);
                                                }}
                                            >
                                                Save
                                            </StriimButton>
                                        </Grid>
                                    </Grid>
                                }
                            >
                                <Grid display={"flex"} alignItems={"center"}>
                                    <SvgIcon component={InfoCircle} sx={styles.infoIcon} />
                                </Grid>
                            </StriimDropdown>
                        </>
                    )}
            </Grid>

            {simpleHeading && !!relativeTime ? (
                <StriimTypography variant="caption3" sx={styles.simpleName}>
                    {`UPDATED ${relativeTime.toUpperCase()}`}
                </StriimTypography>
            ) : (
                <>
                    <Grid item display="flex" gap={3}>
                        {status && (
                            <Grid item display="flex" flexDirection={"column"}>
                                <StriimTypography variant="caption3" sx={styles.caption}>
                                    Status
                                </StriimTypography>
                                <StriimChip
                                    variant={StateToChipVariant[status.toLowerCase()] || "default"}
                                    label={([JobStatuses.CREATED, JobStatuses.RUNNING].includes(status)
                                        ? "Running"
                                        : status
                                    ).toUpperCase()}
                                    data-testid={`job-${status.toLowerCase()}`}
                                    icon={
                                        [JobStatuses.CREATED, JobStatuses.RUNNING].includes(status) ? (
                                            <StriimCircularProgress size={12} />
                                        ) : null
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item display="flex" flexDirection={"column"}>
                            <StriimTypography variant="caption3" sx={styles.caption}>
                                Started
                            </StriimTypography>
                            <StriimTypography variant="caption3" sx={styles.captionDate}>
                                {formattedGenerationTime}
                            </StriimTypography>
                        </Grid>
                        <Grid item display="flex" flexDirection={"column"}>
                            <StriimTypography variant="caption3" sx={styles.caption}>
                                Completed
                            </StriimTypography>
                            <StriimTypography variant="caption3" sx={styles.captionDate}>
                                {formattedCompletedTime}
                            </StriimTypography>
                        </Grid>
                    </Grid>
                    <Grid item display={"flex"} ml={"auto"} gap={1}>
                        {status !== JobStatuses.RUNNING ? (
                            <StriimButton
                                variant="text"
                                startIcon={<SvgIcon component={Delete} sx={{ fill: "none" }} />}
                                onClick={onDelete}
                            >
                                Delete
                            </StriimButton>
                        ) : (
                            <StriimButton
                                variant={"text"}
                                onClick={onCancel}
                            >
                                Cancel
                            </StriimButton>
                        )}

                        <Printable
                            name={`Sherlock_Global_report`}
                            component={SherlockGlobalReport}
                            fetchReportData={fetchReportData}
                            buttonComponent={DownloadButton}
                            appendToBody={true}
                        />

                        {showRetry && (
                            <StriimButton
                                variant={"primary"}
                                startIcon={<SvgIcon component={Refresh} sx={styles.refreshIcon} />}
                                onClick={onRetry}
                            >
                                Retry
                            </StriimButton>
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default JobDetailsHeader;
