import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./date-picker.html";
import dateLib from "core/utils/date-utils";

var Model = BaseControl.Model.extend({
    defaults: {
        value: null
    }
});

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "date-picker-control",

    valueFormat: "YYYY-MM-DD", // https://day.js.org/docs/en/display/format
    valueFormatPicker: "yy-mm-dd", // https://api.jqueryui.com/datepicker/#utility-formatDate
    minDate: null,
    maxDate: null,

    getViewValue: function() {
        var val = this.$("input").val();

        if (!val) return null;

        return dateLib(val);
    },

    setViewValue: function() {
        var value = this.getValue();

        this.$("input").val(value ? value.format(this.valueFormat) : null);
    },

    datePickerDisplay: function(inputField, datePickerInstance){
        var appendToElement = $(inputField).parent();
        datePickerInstance.dpDiv.appendTo(appendToElement);
    },

    onRender: function() {
        this.$("input").datepicker({
            dateFormat: this.valueFormatPicker,
            dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
            minDate: this.minDate,
            maxDate: this.maxDate,
            beforeShow: this.datePickerDisplay
          });

        BaseControl.View.prototype.onRender.apply(this, arguments);
    }
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View
});
