export const styles = {
    infoText: {
        color: ({ palette }) => palette.greyscale[700]
    },
    menuHeadingContainer: {
        margin: ({ spacing }) => spacing(1.5, 1.5, 1, 1.5)
    },
    messageBoxText: {
        color: theme => theme.palette.greyscale[900]
    },
    menuHeading: {
        color: ({ palette }) => palette.greyscale[700]
    },
    noOptionsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: ({ spacing }) => spacing(2.5)
    },
    loaderContainer: {
        display: "flex",
        width: "fit-content",
        gap: 1
    },
    errorBox: {
        marginTop: ({ spacing }) => spacing(1),
        "& .MuiCard-root": {
            backgroundColor: theme => theme.palette.critical[50],
            border: theme => `1px solid ${theme.palette.critical[100]}`,
            boxShadow: "unset"
        },
        "& .MuiCardHeader-avatar": {
            "& svg": {
                fill: theme => theme.palette.critical[500],
                color: theme => theme.palette.critical[500]
            }
        }
    }
};
