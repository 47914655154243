import BaseTable from "./basetable";
import formatter from "app/components/monitor/common/valueFormatter";

var ExternalCachesTable = {};

ExternalCachesTable.View = BaseTable.View.extend({
    columns: [
        {
            label: "Name",
            srcfield: "label",
        },
        {
            label: "CPU",
            srcfield: "cpu-per-node",
            dataName: "cpu",
        },
        {
            label: "Nodes",
            srcfield: "nodes",
        },
        {
            label: "Cache size",
            srcfield: "cacheSize",
        },
        {
            label: "Lookups/s",
            srcfield: "lookukpsPerSecond",
        },
        {
            label: "Lookups/s",
            srcfield: "lookukpsPerSecondGraph",
            view: BaseTable.CellItemSparklineView,
        },
    ],

    dataMapper: function (server) {
        return {
            label: server["full-name"],
            cpu: formatter.toPercentageString(server["most-recent-data"]["cpu-per-node"]),
            nodes: server["num-servers"],
            cacheSize: server["most-recent-data"]["cache-size"],
            lookukpsPerSecond: formatter.formatRate(server["most-recent-data"].rate),
            lookukpsPerSecondGraph: server["time-series-data"].rate,
        };
    },
});

export default ExternalCachesTable;
