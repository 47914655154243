import React from "react";
import { StriimChip } from "@striim/striim-ui";
import useStyles from "./status-chip.styles";
import classNames from "classnames";
import { Error, CheckCircle, Warning, FiberManualRecord } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import proptypes from "prop-types";
import { FlowStatus } from "src/status-management";

const chipIcons = {
    running: <CheckCircle />,
    created: <FiberManualRecord />,
    deploying: <FiberManualRecord />,
    deployed: <FiberManualRecord />,
    starting: <FiberManualRecord />,
    stopping: <FiberManualRecord />,
    quiescing: <FiberManualRecord />,
    completed: <FiberManualRecord />,
    stopped: <FiberManualRecord />,
    quiesced: <FiberManualRecord />,
    crash: <Error />,
    terminated: <Error />,
    invalid: <Error />,
    deploy_failed: <Error />,
    not_enough_servers: <Error />,
    halted: <Warning />,
    progress: <CircularProgress size={14} thickness={6} data-test-id="app-progress-indicator" />
};

function formatLabel(label) {
    let appStatusArr = Object.keys(FlowStatus).map(status => status.toLowerCase());
    let formattedLabel = label
        ?.split(" ")
        ?.join("_")
        ?.toLowerCase();
    if (appStatusArr.includes(formattedLabel)) {
        return `appStatus-${formattedLabel}`;
    }
    return formattedLabel;
}

const StatusChip = ({ label, isLoading, capitalize }) => {
    const classes = useStyles();
    const formattedLabel = formatLabel(label);
    return (
        <StriimChip
            variant="default"
            hideAvatar
            label={capitalize ? label.toUpperCase() : label}
            icon={isLoading ? chipIcons.progress : chipIcons[formattedLabel]}
            className={"status-" + (label != undefined ? label.toUpperCase() : "unknown") + "-indicator"}
            classes={{
                label: classNames(classes.chipLabel),
                root: classNames(classes.chipRoot, formattedLabel),
                icon: classNames(classes.chipIcon, formattedLabel, isLoading ? classes.progressIcon : "")
            }}
        />
    );
};

StatusChip.proptypes = {
    label: proptypes.string.isRequired,
    isLoading: proptypes.bool,
    capitalize: proptypes.bool
};

StatusChip.defaultProps = {
    isLoading: false,
    capitalize: false
};

export default StatusChip;
