import Backbone from "backbone";
import { View } from "../database-reader/base-database-reader";
import { DB_PROVIDERS } from "../database-reader/configs/db-providers";

class MSJETCDCReader extends View {
    setup() {
        this.model.get("connectionParams").set("type", DB_PROVIDERS.MSJET.id);
        this.model.set("isCDC", true);
    }
}

export default {
    View: MSJETCDCReader,
    vent: new Backbone.Model()
};
