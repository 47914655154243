const sxStyles = {
    "& .MuiCard-root": {
        backgroundColor: theme => theme.palette.greyscale[100],
        border: theme => `1px solid ${theme.palette.greyscale[200]}`,
        boxShadow: "unset"
    },
    "& .MuiTypography-root": {
        color: theme => theme.palette.greyscale[800]
    },
    "& .MuiCardHeader-root": {
        padding: theme => theme.spacing(1, 1.5)
    },
    "& .MuiCardHeader-avatar": {
        "& svg": {
            fill: theme => theme.palette.greyscale[800]
        }
    }
};
export default sxStyles;
