import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        columnGap: theme.spacing(4),
        maxHeight: "245px", /*fit 7 items in column */
        flexWrap: "wrap",
        paddingTop: theme.spacing(2),
        boxSizing: "content-box"
    },
    item: {
        display: "flex",
        marginBottom: theme.spacing(1),
        alignItems: "center",
        "& svg": {
            marginRight: theme.spacing(1),
            width: theme.spacing(2)
        }
    }
}));
