import React from "react";
import PropTypes from "prop-types";
import { StriimButton, StriimTypography } from "@striim/striim-ui";
import { Grid } from "@material-ui/core";
import TemplateCard from "./template_card";
import { useNavigate } from "react-router";
import templates from "app/components/appwizard/resources/generated/templates.json";
import { getTemplates } from "../../apps/pages/create-app-page/utils/get-campaigns";
import securityService from "core/services/securityService/securityService";

const getPopularTemplatesData = () => {
    const popularTemplatesTitles = [
        "Oracle to BigQuery",
        "Oracle to Databricks",
        "Oracle to Snowflake",
        "PostgreSQL to BigQuery",
        "PostgreSQL to Databricks",
        "PostgreSQL to Snowflake"
    ];
    const popularTemplates = templates.filter(template => {
        const isILCDCTemplate = template.isAutomatedILCDC;
        return isILCDCTemplate && popularTemplatesTitles.includes(template.title);
    });
    popularTemplates.sort((a, b) => {
        let indexA = popularTemplatesTitles.indexOf(a.title);
        let indexB = popularTemplatesTitles.indexOf(b.title);
        return indexA - indexB;
    });
    return popularTemplates;
};
const PopularTemplates = () => {
    const navigate = useNavigate();
    let popularTemplates = getPopularTemplatesData();
    const isAppAdapterLicense = securityService.isAppAdapterLicense();
    if (isAppAdapterLicense) {
        const templatesList = getTemplates();
        popularTemplates = templatesList
            .filter(
                template =>
                    template.enabledByLicense &&
                    ["HubSpotReader", "ZendeskReader", "StripeReader"].includes(template.uses[0]) &&
                    ["GCSWriter", "BigQueryWriter", "FileWriter"].includes(template.uses[1])
            )
            .slice(0, 3);
    }

    return (
        <>
            <Grid container alignItems="center" style={{ marginBottom: "24px", justifyContent: "space-between" }}>
                <Grid item>
                    <StriimTypography variant="h3">Popular Templates</StriimTypography>
                </Grid>
                <Grid item>
                    <StriimButton
                        variant="text"
                        onClick={() => {
                            navigate("/create-app");
                        }}
                    >
                        View all wizards
                    </StriimButton>
                </Grid>
            </Grid>
            {popularTemplates.map(template => (
                <div style={{ marginTop: "10px" }} key={template.id}>
                    <TemplateCard
                        title={template.title}
                        source={template.fromIcon}
                        target={template.toIcon}
                        url={template.url}
                    />
                </div>
            ))}
        </>
    );
};

export default PopularTemplates;

PopularTemplates.propTypes = {
    templates: PropTypes.array.isRequired
};
