import React from "react";
import { StriimControlLabel, StriimCheckbox, StriimTypography, StriimAsterisk } from "@striim/striim-ui";
import Dictionary from "../../../../../../app/components/common/helpable/online-help-dictionary";
import { Grid } from "@mui/material";
import NewTab from "app/images/New Tab.svg";
import Tracker from "../../../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../../core/services/tracker/constants";

const sxStyles = {
    linkText: {
        color: "secondary.500",
        display: "inline-flex",
        alignItems: "center"
    },
    linkIcon: {
        fill: "none",
        marginLeft: 4
    },
    messageText: {
        color: "greyscale.900"
    },
    description: {
        color: "greyscale.800",
        marginLeft: "30px",
        fontFamily: "Inter",
        display: "flex",
        marginTop: 1 / 2
    },
    learnMore: {
        color: "secondary.500",
        display: "flex",
        alignItems: "center",
        marginLeft: 1 / 2,
        gap: 1 / 2
    },
    tabIcon: {
        fill: "transparent",
        width: "18px",
        height: "18px"
    }
};

const LearnMoreButton: React.FunctionComponent<{ link: string; variant: string }> = ({ link, variant = "body4" }) => {
    return (
        <StriimTypography
            variant={variant}
            component="a"
            href={link}
            sx={sxStyles.linkText}
            target="_blank"
            onClick={() => {
                Tracker.getInstance().track(TRACKER_STRINGS.schema.learnMore, {
                    event: TRACKER_STRINGS.eventClicked.learnMore
                });
                return true;
            }}
        >
            Learn more
        </StriimTypography>
    );
};

interface TextWithLinkProps {
    data: { label: string; linkID: string };
    variant?: string;
}

const TextWithLink: React.FunctionComponent<TextWithLinkProps> = ({ data, variant = "body4" }) => {
    return (
        <StriimTypography variant={variant} sx={sxStyles.messageText}>
            {data.label + " "}
            <LearnMoreButton link={Dictionary.get()[data.linkID].href} variant={variant} />
        </StriimTypography>
    );
};

export const TrackingTableDescription = (
    <TextWithLink
        variant="caption3"
        data={{
            label: "To capture schema changes from Postgres, a tracking table is required to be created in source.",
            linkID: "ADVANCED_SETTINGS_POSTGRES_TRACKINGTABLE"
        }}
    />
);

interface ConfirmFieldProps {
    title?: string;
    label: string;
    description: string;
    linkID: string;
    formRef: React.RefObject<HTMLFormElement>;
    fieldName: string;
}

export const ConfirmField: React.FunctionComponent<ConfirmFieldProps> = ({
    title = null,
    label,
    description,
    linkID,
    formRef,
    fieldName
}) => {
    const fieldValue = formRef?.current?.values?.[fieldName];

    const LearnLink = () => {
        return (
            <StriimTypography
                variant={"caption3"}
                component="a"
                href={Dictionary.get()[linkID].href}
                sx={sxStyles.learnMore}
                target="_blank"
                onClick={() => { 
                    Tracker.getInstance().track(TRACKER_STRINGS.schema.learnMore, {
                        event: TRACKER_STRINGS.eventClicked.learnMore
                    });
                    return true;
                }}
            >
                Learn more
                <NewTab style={sxStyles.tabIcon} />
            </StriimTypography>
        );
    };
    return (
        <Grid>
            {title && (
                <StriimTypography variant="body3" sx={{ color: "greyscale.900" }}>
                    {title}
                    <StriimAsterisk />
                </StriimTypography>
            )}
            <Grid>
                <StriimControlLabel
                    style={{ display: "flex", marginTop: title ? 8 : 0 }}
                    control={
                        <StriimCheckbox
                            onChange={() => formRef?.current?.setFieldValue(fieldName, !fieldValue)}
                            checked={fieldValue}
                        />
                    }
                    label={
                        <StriimTypography variant="body4" sx={{ color: "greyscale.800" }}>
                            {label}
                        </StriimTypography>
                    }
                />
                <StriimTypography variant="caption3" sx={sxStyles.description}>
                    {description + " "} <LearnLink />
                </StriimTypography>
            </Grid>
        </Grid>
    );
};
