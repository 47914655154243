/**
 *  Just passes the tracking info to the chameleon library.
 */
/* global chameleon */
import trackerInterface from "./base-subscriber";

var Chameleon_library = {};

Chameleon_library.initialize = function() {
    try {
        var chameleonScript = document.createElement("script");
        chameleonScript.src = "/lib/vendor/chameleon.js";
        chameleonScript.async = false;
        document.head.appendChild(chameleonScript);
    } catch (error) {
        console.log(error);
    }
};

Chameleon_library.identify = function (params) {
    if (typeof chmln !== "undefined") chmln.identify(params)
};

Chameleon_library = Object.assign({}, trackerInterface, Chameleon_library);

export default Chameleon_library;