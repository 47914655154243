import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { observer } from "mobx-react-lite";

import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { StriimTypography, StriimCheckbox, StriimTooltip } from "@striim/striim-ui";
import Menu from "../app-list/menu-dropdown";
import { AppAction } from "src/status-management";
import { AppStatusChip } from "./components/app-status-indicators";
import SourceTargetList from "src/source-target-list/source-target-list";
import { lagFormatter, rateFormatter } from "../app-list/helpers/app-list-helpers";

import statusManagement from "src/status-management";
import useStores from "src/utils/use-stores";
import useStyles from "./new-app-tile.styles";

const AppTile = observer(
    ({ app, selectAll, setSelectAll, setSelectedApps, clearSelections, setClearSelections, dropApplication }) => {
        const { store } = useStores();
        const classes = useStyles();

        const appActionRef = useRef();
        const ref = useRef();

        const [selected, setSelected] = useState(false);

        const previousStatus = app.flowStatus;

        useEffect(() => {
            if (selectAll) setSelected(true);
        }, [selectAll]);

        useEffect(() => {
            if (clearSelections) setSelected(false);
        }, [clearSelections]);

        const handleAppChecked = useCallback(() => {
            setSelectedApps(selectedApps => {
                const newSelectedApps = [...selectedApps];
                const index = newSelectedApps.indexOf(app);
                if (index !== -1) {
                    newSelectedApps.splice(index, 1);
                } else {
                    newSelectedApps.push(app);
                }
                return newSelectedApps;
            });
            // reset states - needed to trigger changes
            setSelectAll(false);
            setClearSelections(false);

            setSelected(selected => !selected);
        }, [app]);

        const appStatus = useMemo(
            () => (
                <AppStatusChip
                    label={statusManagement.getStatusChipDisplayName(
                        app.flowStatus,
                        appActionRef.current,
                        previousStatus
                    )}
                />
            ),
            [app, previousStatus]
        );

        const appSelector = useMemo(
            () => <StriimCheckbox className={classes.appTileCheckbox} checked={selected} onClick={handleAppChecked} />,
            [classes, selected, handleAppChecked]
        );

        const sourceTargetList = useMemo(
            () => (
                <SourceTargetList
                    sources={app.sources}
                    targets={app.targets}
                    className={classes.icons}
                    appId={app.id}
                    maxSources={1}
                    maxTargets={1}
                />
            ),
            [app, classes]
        );

        const appActionsButton = useMemo(
            () => (
                <Menu
                    app={app}
                    onAppAction={action => {
                        appActionRef.current = action;
                    }}
                    applicationGroupsDisabled={store.applicationGroupsDisabled}
                    dropApplication={dropApplication}
                />
            ),
            [app, store, dropApplication]
        );

        const getAppInfoMetric = useCallback(
            (metricName, metricValue, alignment) => (
                <Grid container>
                    <Grid item xs={alignment === "bottom" ? 12 : 6}>
                        <StriimTypography variant="caption3" color="greyscale.600" className={classes.tableMetricCell}>
                            {metricName}
                        </StriimTypography>
                    </Grid>
                    <Grid item xs={alignment === "bottom" ? 12 : 6} className={classes.appInfoValues}>
                        <StriimTooltip title={metricValue}>
                            <StriimTypography
                                className={clsx(classes.tableMetricCell)}
                                variant="caption3"
                                color="greyscale.900"
                            >
                                {metricValue ?? 0}
                            </StriimTypography>
                        </StriimTooltip>
                    </Grid>
                </Grid>
            ),
            [classes]
        );

        const [appNamespace, appWriteRate, appReadRate, appLag] = useMemo(
            () => [
                getAppInfoMetric("Namespace", app?.nsName, "bottom"),
                getAppInfoMetric("Write Rate", rateFormatter(app?.mostRecentData?.["target-rate"], app.flowStatus)),
                getAppInfoMetric("Read Rate", rateFormatter(app?.mostRecentData?.["source-rate"], app.flowStatus)),
                getAppInfoMetric("Lag", lagFormatter(app?.mostRecentData?.["lag-rate"]))
            ],

            [app, getAppInfoMetric]
        );

        const appName = useMemo(
            () => (
                <StriimTypography
                    variant="body3"
                    color="greyscale.900"
                    data-test-id={"apptitle--" + app.nsName + "." + app.name}
                    className={classes.cardHeaderTitle}
                    onClick={() => {
                        store.onAppAction(app, AppAction.VIEW);
                    }}
                >
                    {app.name}
                </StriimTypography>
            ),
            [app, classes, store]
        );

        return (
            <Card
                classes={{ root: classes.cardRoot }}
                id={"apptile--" + app.nsName + "." + app.name}
                data-test-id={"apptile--" + app.nsName + "." + app.name}
                ref={ref}
                elevation={0}
            >
                <CardHeader
                    classes={{
                        root: classes.cardHeaderRoot,
                        content: classes.cardHeaderContent
                    }}
                    avatar={appSelector}
                    action={
                        <Grid container direction="row" alignItems="center">
                            <Grid item>{sourceTargetList}</Grid>
                            <Grid item>{appActionsButton}</Grid>
                        </Grid>
                    }
                    disableTypography
                    title={<StriimTooltip title={app?.name?.length > 15 ? app.name : ""}>{appName}</StriimTooltip>}
                />
                <CardContent classes={{ root: classes.cardContentRoot }}>
                    <Grid container className={classes.cardAppInfo}>
                        <Grid container direction="column" item xs={6}>
                            {appNamespace}
                        </Grid>
                        <Grid container direction="column" item xs={6}>
                            {appLag}
                            {appReadRate}
                            {appWriteRate}
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item xs={4}>
                            {/* {appUptime} */}
                        </Grid>
                        <Grid item className={classes.appStatus}>
                            {appStatus}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
);

AppTile.propTypes = {
    app: PropTypes.object.isRequired,
    selectAll: PropTypes.bool,
    setSelectAll: PropTypes.func,
    setSelectedApps: PropTypes.func,
    clearSelections: PropTypes.bool,
    setClearSelections: PropTypes.func
};

export default AppTile;
