import templates from "app/components/appwizard/templates";
import securityCheck from "../../../../../../core/services/securityService/adapter-security-check";
import licenseSecurityCheck from "../../../../../../core/services/securityService/adapter-license-check";
import newReleases from "./new-releases.json";
import securityService from "../../../../../../core/services/securityService/securityService";
let getTemplateGroupsResult = null;
let options = {};

const getTemplates = () => {
    const isFabricSolution = securityService.isFabricSolution();

    templates.templatesList.forEach(template => {
        let enabledByLicense = securityCheck.checkIfEnabledSync(template.uses);

        if (isFabricSolution && enabledByLicense) {
            const sourceType = template.tags[0].split(": ")[1];
            const sourceAdapter = template.sourceAdapter;
            enabledByLicense = licenseSecurityCheck.checkWithLicenseValidation(sourceAdapter, sourceType);
            // MSJET wizard should not be shown in wizards for fabric solution
            if (sourceAdapter === "MSJet") {
                enabledByLicense = false;
            }
        }

        template.enabledByLicense = enabledByLicense;

        template.tags.forEach(tag => {
            if (enabledByLicense) {
                options[tag] = true;
            }
        });
    });
    return templates.templatesList;
};

const getAutomatedTemplatesGroup = enabledTemplates => {
    if (!enabledTemplates || enabledTemplates.length === 0) {
        return null;
    }

    //Oracle, OJet, SQL Server, MSJet, PostgreSQL, SalesforceCDC, Salesforce, MySQL, MariaDB XPand, MariaDB
    //Snowflake, BigQuery, Databricks, Fabrics DWH,, Oracle, SQL Server, PostgreSQL, MySQL, MariaDB

    const firstPreferredSourcesOrder = [
        "OracleDB",
        "OJet",
        "MSSQLDB",
        "MSJet",
        "PostgreSQLDB",
        "SalesforceCDCReader",
        "MySQLDB",
        "MariaDBXpandDB",
        "MariaDB"
    ];
    const firstPreferredTargetsOrder = [
        "SnowflakeWriter",
        "BigQuery",
        "DeltaLakeWriter",
        "FabricDataWarehouseWriter",
        "OutputOracleDatabase",
        "OutputSQLServerDatabase",
        "OutputPostgresDatabase",
        "OutputMySQLDatabase",
        "MariaDBDatabase"
    ];

    let automatedTemplates = enabledTemplates
        .filter(template => template?.isAutomatedILCDC)
        .sort((a, b) => {
            const sourceA = a.steps[0];
            const targetA = a.steps[1];
            const sourceB = b.steps[0];
            const targetB = b.steps[1];
            function getPreferredIndex(elem, order) {
                const preferredIndex = order.indexOf(elem);
                return preferredIndex === -1 ? order.length : preferredIndex;
            }

            const sourceAIndex = getPreferredIndex(sourceA, firstPreferredSourcesOrder);
            const sourceBIndex = getPreferredIndex(sourceB, firstPreferredSourcesOrder);
            const targetAIndex = getPreferredIndex(targetA, firstPreferredTargetsOrder);
            const targetBIndex = getPreferredIndex(targetB, firstPreferredTargetsOrder);
            return sourceAIndex - sourceBIndex || targetAIndex - targetBIndex;
        });
    const firstPreferredTopologies = [];
    const firstPreferredTopologiesCount = 4;
    automatedTemplates = automatedTemplates.filter(elem => {
        const currTarget = elem.steps[1];
        const currSource = elem.steps[0];
        const preferredTargetOrderIndex = firstPreferredTargetsOrder.indexOf(currTarget);
        const preferredSourceOrderIndex = firstPreferredSourcesOrder.indexOf(currSource);
        if (preferredTargetOrderIndex > -1 && preferredTargetOrderIndex < firstPreferredTopologiesCount) {
            if (preferredSourceOrderIndex > -1 && preferredSourceOrderIndex < firstPreferredSourcesOrder.length) {
                firstPreferredTopologies.push(elem);
                return false;
            }
        }
        return true;
    });
    automatedTemplates = [...firstPreferredTopologies, ...automatedTemplates];
    return automatedTemplates.length > 0
        ? {
              campaignKey: "Automated Groups",
              campaignDesc: "Automated Pipelines",
              icon: "AutomatedCampaign",
              templates: automatedTemplates,
              generic: true
          }
        : null;
};

const getMirrorTemplatesGroup = templates => {
    // Show Mirror template group only for Fabric License
    if (!securityService.isFabricSolution()) return null;
    const mirrorTemplates = templates.filter(
        template => template?.uses.indexOf("FabricMirrorWriter") > 0 && template.isAutomatedILCDC
    );

    return mirrorTemplates.length > 0
        ? {
              campaignKey: "Mirror to Fabric",
              campaignDesc: "Mirror to Fabric",
              icon: "MirrorCampaign",
              templates: mirrorTemplates,
              generic: true
          }
        : null;
};

const getNewlyAddedTemplatesGroup = enabledTemplates => {
    if (!enabledTemplates || enabledTemplates.length === 0) {
        return null;
    }
    const newReleasedSources = newReleases.sources;
    const newReleasedTargets = newReleases.targets;
    const newReleasedTemplates = enabledTemplates.filter(template => {
        const source = template.uses[0];
        const target = template.uses[1];
        return newReleasedSources.includes(source) || newReleasedTargets.includes(target);
    });

    return newReleasedTemplates.length > 0
        ? {
              campaignKey: "Newly Added Groups",
              campaignDesc: "New Releases",
              icon: "NewReleasesCampaign",
              templates: newReleasedTemplates,
              generic: true
          }
        : null;
};

const getTemplateGroups = () => {
    if (getTemplateGroupsResult) {
        return getTemplateGroupsResult;
    }

    const templatesList = templates.templatesList;
    const templateGroups = [];

    templates.templatesList = getTemplates();

    for (let campaignID in templates.campaigns) {
        let campaign = templates.campaigns[campaignID];
        if (campaign.filter) {
            let filtered = templatesList.filter(campaign.filter);
            templateGroups.push({
                campaignKey: campaignID,
                campaignDesc: campaign.title,
                icon: campaign.icon,
                templates: filtered,
                generic: false
            });
        }
    }
    // add automated templates group for all licences

    const mirrorTemplateGroup = getMirrorTemplatesGroup(templatesList);
    const automatedTemplateGroup = getAutomatedTemplatesGroup(templatesList);
    const newlyAddedTemplatesGroup = getNewlyAddedTemplatesGroup(templatesList);

    getTemplateGroupsResult = {
        templateGroups,
        templatesList,
        mirrorTemplateGroup,
        automatedTemplateGroup,
        newlyAddedTemplatesGroup,
        options: Object.keys(options)
            .sort()
            .map(option => {
                return { label: option, value: option };
            })
    };

    return getTemplateGroupsResult;
};

export default getTemplateGroups;
export { getTemplates };
