import React, { useEffect, useMemo, useState } from "react";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { StriimInputField, StriimRadio, StriimTypography, StriimDataTable } from "@striim/striim-ui-v2";
import { components, SingleValueProps } from "react-select";
import { AppDeployTableProps, FlowType, GroupType } from "./app-deploy-table";
import { DeploymentInfoType } from "../automated-group-deploy-modal";

const deployOnOptions = [
    {
        label: "On any one node",
        value: "any"
    },
    {
        label: "On all nodes",
        value: "all"
    }
];

const SingleValue = ({ children, ...props }: SingleValueProps) => (
    <components.SingleValue {...props}>
        <StriimTypography sx={{ color: "greyscale.800" }} variant="body4">
            {children}
        </StriimTypography>
    </components.SingleValue>
);

const columns = [
    {
        headerName: "Flow",
        field: "flow",
        flex: 1,
        resizable: true,
        cellRenderer: ({ value, data: { flow } }) => {
            return (
                <StriimTypography color="greyscale.900" variant="body4" data-test-id={flow.name}>
                    {flow.name}
                </StriimTypography>
            );
        }
    },
    {
        headerName: "Deployment Group",
        field: "group",
        flex: 1,
        resizable: true,
        minWidth: 250,
        cellClass: "cell-left",
        cellRenderer: ({ value, data: { flow, deploymentGroupOptions, handleFormChange } }) => {
            return (
                <Grid>
                    <StriimInputField
                        id={`data-test-id-deployment-group-select-${flow?.name}`}
                        SelectProps={{
                            isClearable: false,
                            options: deploymentGroupOptions,
                            components: {
                                IndicatorSeparator: () => null,
                                CrossIcon: () => null,
                                SingleValue: SingleValue
                            },

                            menuPosition: "absolute",
                            menuPortalTarget: document.body,
                            zIndex: 1502
                        }}
                        onChange={(val: { value: string; label: string }) => {
                            handleFormChange(flow, "group", val.label);
                        }}
                        defaultValue={{
                            label: "default",
                            value: "Global.DG.default"
                        }}
                        select
                    />
                </Grid>
            );
        }
    },
    {
        headerName: "Deploy on",
        field: "strategy",
        flex: 1,
        minWidth: 250,
        resizable: true,
        cellClass: "cell-left",
        cellRenderer: ({ value, data: { flow, deploymentGroupOptions, handleFormChange } }) => {
            return (
                <StriimInputField
                    id={`data-test-id-deployment-strategy-select-${flow?.name}`}
                    SelectProps={{
                        isClearable: false,
                        options: deployOnOptions,
                        components: {
                            IndicatorSeparator: () => null,
                            CrossIcon: () => null,
                            SingleValue: SingleValue
                        },
                        zIndex: 1502,
                        menuPosition: "absolute",
                        menuPortalTarget: document.body
                    }}
                    onChange={(val: { value: string; label: string }) => handleFormChange(flow, "strategy", val.value)}
                    defaultValue={{
                        label: "On any one node",
                        value: "any"
                    }}
                    select
                />
            );
        }
    }
];
interface AppDeployTableComponentType {
    deploymentGroups: GroupType[];
    appFlows: FlowType[];
    handleFormChange: (flow: FlowType, key: string, value: string) => void;
    formValues: DeploymentInfoType;
}
const AppDeployTableComponent: React.FunctionComponent<AppDeployTableComponentType> = ({
    deploymentGroups,
    appFlows,
    handleFormChange,
    formValues
}) => {
    const [tableData, setTableData] = useState([]);
    const deploymentGroupOptions = useMemo(
        () => deploymentGroups?.map(dgGroup => ({ label: dgGroup.name, value: dgGroup.id })),
        [deploymentGroups]
    );

    useEffect(() => {
        const data = appFlows?.map(flow => ({
            flow: flow,
            handleFormChange: handleFormChange,
            deploymentGroupOptions: deploymentGroupOptions
        }));
        setTableData(data);
    }, []);
    return <StriimDataTable rowHeight={60} data={tableData} columns={columns} />;
};
export default AppDeployTableComponent;
