export const styles = {
    playerBox: {
        display: "flex",
        columnGap: 1.25
    },
    contentGrid: {
        gap: ({ spacing }) => spacing(3)
    },
    deleteButton: {
        border: 1,
        borderColor: "greyscale.300",
        borderRadius: ({ spacing }) => spacing(1),
        minWidth: "fit-content",
        padding: ({ spacing }) => spacing(1, 2)
    },
    deleteSVG: {
        fontSize: 20,
        "& > g": {
            fill: "none"
        }
    },
    descriptionGrid: {
        pb: 3,
        borderBottom: "1px solid",
        borderColor: "greyscale.200"
    },
    iconGrid: {
        pt: 0.27,
        textAlign: "center"
    },
    infoIcon: {
        fontSize: 20
    },
    wrapperGrid: {
        padding: ({ spacing }) => spacing(4, 5.5, 5.5, 2),
        columnGap: 1
    },
    wrapperContainer: {
        mt: 3,
        borderRadius: ({ spacing }) => spacing(1),
        overflow: "hidden",
        border: "1px solid",
        borderColor: "greyscale.200",
        backgroundColor: "greyscale.50"
    },
    progressContainer: {
        mt: 2,
        ml: "22px"
    }
};
