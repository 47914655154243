import api from "../../../../core/api/api";

import metaObjectHelper from "../../../../app/components/common/editor/meta-object-editors/metaObjectHelper";
import metaStoreService from "../../../../core/services/metaStoreService/meta-store-service";
import metaobjectConverter from "../../../../core/services/metaStoreService/metaobject-converter";

import { appSort } from "../guardian/components/job-list-insights-view/job-list-insights-view";
import getSentinelMonitorService from "../guardian/sentinel-monitor/service/sentinel-moniter-service";
import {
    getStartAndEndTimestamps,
    getStartAndEndTimestampsForChart,
    getTimeFormat
} from "../sentinel-live-monitor/utils";
import ManageStriimService from "../../apps/pages/manage-striim/manage-striim-service";
import growl from "../../../../app/components/common/growl";

const getSelectedRange = selectedDateRange => ({ fromTime: selectedDateRange.from, toTime: selectedDateRange.to });

// Sentinel App Report Data
export const fetchSentinelAppReportData = async (
    fullAppName,
    namespace,
    appName,
    selectedDateRange,
    isOneHourTimeFrame
) => {
    try {
        const sentinelMonitorService = getSentinelMonitorService();
        const timeFormat = getTimeFormat(isOneHourTimeFrame);
        const { fromTime, toTime } = getStartAndEndTimestamps(isOneHourTimeFrame, selectedDateRange);
        const { fromTime: chartFromTime, toTime: chartToTime } = selectedDateRange
            ? getSelectedRange(selectedDateRange)
            : getStartAndEndTimestampsForChart();
        const timeRange = { from: fromTime, to: toTime };
        const entityType = metaStoreService.entities.APPLICATION;

        const [
            adapterIcons,
            sensitiveDataOccurrencesChartData,
            sensitiveDataOccurrences,
            eventsProcessedChartData,
            previewData
        ] = await Promise.all([
            getAdapterIcons(fullAppName),
            sentinelMonitorService.getSensitiveDataOccurrencesChartData(
                entityType,
                namespace,
                appName,
                chartFromTime,
                chartToTime,
                timeFormat
            ),
            sentinelMonitorService.getSensitiveDataOccurrences(
                entityType,
                namespace,
                appName,
                fullAppName,
                fromTime,
                toTime
            ),
            sentinelMonitorService.getAppSentinelsEventsProcessedChartData(
                namespace,
                appName,
                chartFromTime,
                chartToTime,
                timeFormat
            ),
            sentinelMonitorService.getFlowDesignerPreview(entityType, namespace, appName, fromTime, toTime)
        ]);

        return {
            selectedApps: [appName],
            sourceIcons: adapterIcons.sourceIcons,
            targetIcons: adapterIcons.targetIcons,
            timeRange,
            sensitiveDataOccurrencesChartData,
            sensitiveDataOccurrences,
            eventsProcessedChartData,
            previewData
        };
    } catch (error) {
        growl.error(error, "Error fetching report data");
    }
};

// Sherlock Global Report
export const fetchSherlockGlobalReportData = async (jobs, apps, jobName) => {
    try {
        downloadCSVReport(jobName);
        const updatedData = jobs.reduce((acc, curr) => {
            let result = {};
            let piiData = {};
            let metrics = { sensitiveEntities: 0, totalEntities: 0, sensitiveIdentifiers: [] };
            try {
                piiData = JSON.parse(curr.piiData);
                metrics = !!curr.metrics ? JSON.parse(curr.metrics) : metrics;
            } catch (error) {
                console.log(error);
            }
            const appName = curr.appName;
            result["jobStatus"] = {
                status: curr.status,
                generationTime: Number(curr?.generationTime ?? 0),
                completedTime: Number(curr?.completedTime || 0),
                errorMessage: curr.otherDetails
            };
            result["entitiesWithSensitiveData"] = {
                completed: metrics?.sensitiveEntities,
                total: metrics?.totalEntities
            };
            result["piiData"] = curr.piiData;
            acc[appName] = result;
            return acc;
        }, {});
        const appNames = Object.keys(updatedData);

        const appsList: AppProps[] = appNames.map(app => {
            let [appNamespace, appName] = app.split(".");
            const item = updatedData[app];
            const appDetails = apps?.find(item => `${item.nsName}.${item.name}` === app);
            return {
                ...(appDetails ? appDetails : { name: appName, nsName: appNamespace }),
                jobStatus: item.jobStatus,
                status: item.status,
                entitiesWithSensitiveData: item.entitiesWithSensitiveData,
                piiData: item.piiData,
                isAppAvailable: !!appDetails
            };
        });

        const topSensitiveDataIdentifiers = appsList?.reduce((acc, curr) => {
            try {
                if (!curr.piiData || curr.piiData.trim().length === 0) return acc;
                const appName = `${curr.nsName}.${curr.name}`;
                const piiData = JSON.parse(curr.piiData);
                Object.values(piiData).map(value => {
                    let piiFields = [];
                    try {
                        piiFields = JSON.parse(value)["PII fields"];
                    } catch (error) {
                        console.error(error);
                    }
                    piiFields.map(field => {
                        const identifierTypes = Object.keys(field.identifierType);
                        identifierTypes.forEach(type => {
                            acc[type] = acc[type] ?? [];
                            !acc[type].includes(appName) && acc[type].push(appName);
                        });
                    });
                });
                return acc;
            } catch (error) {
                console.log(error);
            }
        }, {});

        const chartData = Object.keys(topSensitiveDataIdentifiers || {})
            .map(key => ({
                name: key,
                count: topSensitiveDataIdentifiers[key].length,
                items: topSensitiveDataIdentifiers[key]
            }))
            .sort((x, y) => y.count - x.count)
            .slice(0, 10);

        const identifiersString = await api.getTopAppsWithGivenImp(jobName, 10, "HIGH");
        const identifiers = JSON.parse(identifiersString);

        return {
            topSensitiveDataIdentifiers: topSensitiveDataIdentifiers,
            chartData: chartData,
            appsList: appsList.sort(appSort),
            identifiers: identifiers
        };
    } catch (error) {
        growl.error(error, "Error fetching report data");
    }
};

export const fetchSherlockAppReportData = async (sources, appName, jobName, timeRange) => {
    try {
        downloadCSVReport(jobName);
        const [adapterIcons, identifiersString] = await Promise.all([
            getAdapterIcons(appName),
            api.getTopEntitiesWithGivenImp(jobName, 10, "HIGH")
        ]);

        const identifiers = JSON.parse(identifiersString);

        return {
            sourceData: sources,
            appName: appName,
            timeRange,
            sourceIcons: adapterIcons.sourceIcons,
            targetIcons: adapterIcons.targetIcons,
            identifiers
        };
    } catch (error) {
        growl.error(error, "Error fetching report data");
    }
};

const getAdapterType = adapterProps => adapterProps?.DatabaseProviderType ?? adapterProps?.adapterName ?? "";

export const getAdapterIcons = async shortAppName => {
    const appID = metaobjectConverter.convertShortNameToFullName(shortAppName, metaStoreService.entities.APPLICATION);

    const appModel = await metaStoreService.findById(appID);

    const [sourceObjects, targetObjects] = await Promise.all([
        appModel.getApplicationComponentsByType(metaStoreService.entities.SOURCE),
        appModel.getApplicationComponentsByType(metaStoreService.entities.TARGET)
    ]);

    const sourceIcons = sourceObjects.map(source => ({
        name: getAdapterType(source.get("adapter").properties),
        className: metaObjectHelper.getIconClassByMetaObject(source)
    }));
    const targetIcons = targetObjects.map(target => ({
        name: getAdapterType(target.get("adapter").properties),
        className: metaObjectHelper.getIconClassByMetaObject(target)
    }));

    return { sourceIcons, targetIcons };
};

export const downloadCSVReport = async jobName => {
    try {
        const csvReport = await api.generateSherlockCSVReport(jobName);
        ManageStriimService.downloadFile(csvReport);
    } catch (err) {
        growl.error(err, "Error downloading CSV report");
    }
};

export const downloadSentinelCSVReport = async (listOfFQNs, entityType, fromTime, toTime) => {
    try {
        const csvReport = await api.generateSentinelCSVReport(listOfFQNs, entityType, fromTime, toTime);
        ManageStriimService.downloadFile(csvReport);
    } catch (err) {
        growl.error(err, "Error downloading CSV report");
    }
};
