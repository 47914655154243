/*
 Highlight node connection
 */
import $ from "jquery";
import { flowStriimline } from "../../../../styles/materialize/components-striimline/routes/flow";

var GraphNodesHighlighting = function(jsPlumbInstance) {
    var self = this;

    self.defaultConnectionPaintStyle = null;
    self.defaultEndpointPaintStyle = null;

    var selectedColor = flowStriimline.nodeConnectionBorderActive;
    var defaultConnectionsColor = flowStriimline.nodeConnectionBorder;

    function setOutputsPaintingStyle(graphNodeModel, connectionStyle, endpointStyle, zIndex, options) {
        setConnectionPaintingStyle(
            connectionStyle,
            endpointStyle,
            zIndex,
            {
                source: graphNodeModel.elementId
            },
            false,
            options
        );
    }

    function setInputsPaintingStyle(graphNodeModel, connectionStyle, endpointStyle, zIndex, options) {
        setConnectionPaintingStyle(
            connectionStyle,
            endpointStyle,
            zIndex,
            {
                target: graphNodeModel.elementId
            },
            true,
            options
        );
    }

    function setConnectionPaintingStyle(connectionStyle, endpointStyle, zIndex, connectionSelector, endpointIsTarget, options) {
        var connections = jsPlumbInstance.getConnections(connectionSelector);
        _.each(connections, function(connection) {
            var connectionPaintStyle = connection.getPaintStyle();

            connection.setPaintStyle({
                lineWidth: connectionPaintStyle.lineWidth,
                strokeStyle: connectionStyle.strokeStyle
            });

            $(connection.canvas).css("z-index", connectionStyle?.zIndex || zIndex);

            connection.endpoints.forEach(endpoint => {
                var endpointPaintStyle = endpoint.getPaintStyle();
                endpoint.setPaintStyle({
                    fillStyle: endpointStyle.fillStyle,
                    radius: endpointPaintStyle.radius
                });

                const EPElement = $(endpoint.canvas)
                if (options?.highlightEP)
                    EPElement.addClass("selected");
                else
                    // Reset
                    EPElement.removeClass("selected");
            });
        });
    }

    function ensureDefaultPaintStyle() {
        // first assign default connection and endpoint styles
        // it will be used to unselect nodes
        if (self.defaultConnectionPaintStyle === null) {
            var connections = jsPlumbInstance.getConnections();
            if (connections.length > 0) {
                self.defaultConnectionPaintStyle = connections[0].getPaintStyle();
            } else {
                // the default style should be calculated
                // this style is a fallback
                self.defaultConnectionPaintStyle = {
                    lineWidth: 2,
                    strokeStyle: defaultConnectionsColor
                };
            }
        }

        // same for endpoint
        if (self.defaultEndpointPaintStyle === null) {
            var tmpConnections = jsPlumbInstance.getConnections();
            var mappedConnections = _.map(tmpConnections, function(c) {
                return c.endpoints;
            });
            var allEndpoints = _.flatten(mappedConnections, true);
            var allSources = _.where(allEndpoints, {
                isTarget: false
            });
            if (allSources.length === 0) {
                self.defaultEndpointPaintStyle = {
                    fillStyle: "white",
                    outlineColor: defaultConnectionsColor,
                    lineWidth: 1,
                    radius: 6
                };
            } else {
                self.defaultEndpointPaintStyle = allSources[0].getPaintStyle();
            }
        }
    }

    self.highlightConnection = function(graphNodeModel) {
        ensureDefaultPaintStyle();

        var connectionPaintingStyle = {
            lineWidth: 1,
            strokeStyle: selectedColor,
            zIndex: "2"
        };

        var endpointPaintingStyle = {
            fillStyle: selectedColor,
            radius: 4
        };

        setOutputsPaintingStyle(
            graphNodeModel,
            connectionPaintingStyle,
            endpointPaintingStyle,
            "1",
            {
                highlightEP: true,
            }
        );
        setInputsPaintingStyle(
            graphNodeModel,
            connectionPaintingStyle,
            endpointPaintingStyle,
            "1",
            {
                highlightEP: true,
            }
        );
    };

    self.resetConnectionPainting = function(graphNodeModel) {
        ensureDefaultPaintStyle();
        setOutputsPaintingStyle(
            graphNodeModel,
            self.defaultConnectionPaintStyle,
            self.defaultEndpointPaintStyle,
            "auto",
            {
                highlightEP: false,
            }
        );
        setInputsPaintingStyle(
            graphNodeModel,
            self.defaultConnectionPaintStyle,
            self.defaultEndpointPaintStyle,
            "auto",
            {
                highlightEP: false,
            }
        );
    };
};

export default GraphNodesHighlighting;
