import React, { SetStateAction, useState } from "react";
import numeral from "numeral";
import { StriimTypography, StriimTab, StriimTabs } from "@striim/striim-ui-v2";
import { Box, SvgIcon } from "@mui/material";

import { styles } from "./sentinel-tabs.styles";
import StriimCircularProgress from "../../../../apps/pages/manage-striim/connection-profiles/components/circular-progress/circular-progress";
import { Colors } from "../sentinel-table/sentinel-table-utils";
import WithTooltip from "../../../../../generic/tooltip/tooltip";
import { InfoCircle } from "../../../../../generic/icon/customIcons";
import { formatNumber } from "../../utils";

export const FIELDS = selectedSDI => {
    return {
        sensitiveData: {
            label: "W/ sensitive data",
            color: "#7E7ED6"
        },
        nonSensitiveData: {
            label: "W/O sensitive data",
            color: "#49C7BB"
        },
        sensitiveDataWithSDI: {
            label: `W/ Sensitive data (excl. ${selectedSDI?.name})`,
            color: "#7E7ED6"
        },
        encrypted: {
            label: "Encrypted",
            color: "#356BC0",
            showUnderline: true
        },
        masked: {
            label: "Masked",
            color: "#ED88BA",
            showUnderline: true
        },
        noAction: {
            label: "No Action",
            color: "#D8E4F0",
            showUnderline: true
        },
        actions: {
            label: "POLICY ACTIONS",
            value: "",
            color: ""
        }
    };
};

export const prepareTabsData = (tabs, selectedSDI = null) => {
    const fieldsData = FIELDS(selectedSDI);

    const updatedTabs = tabs.map(tab => {
        const totalAmount = tab.data.reduce((acc, curr) => {
            return acc + (curr?.value || 0);
        }, 0);

        return {
            ...tab,
            data: tab.data.map(dataObject => {
                // If valuePercentage is present, display it. Else compute the percentage from the value
                const valueData =
                    dataObject.valuePercentage ||
                    (dataObject.value ? `${Math.round((dataObject.value / totalAmount) * 100)}%` : "0%");
                return {
                    label: fieldsData[dataObject.field].label,
                    amount: formatNumber(dataObject.value),
                    value: valueData,
                    color: fieldsData[dataObject.field].color,
                    showUnderline: fieldsData[dataObject.field].showUnderline
                };
            })
        };
    });

    updatedTabs[1].data.unshift(fieldsData.actions);

    return updatedTabs;
};

interface ActionInterface {
    field: string;
    value?: string | number;
    color?: string;
    showUnderline?: boolean;
}

interface SelectedSDI {
    name: string;
    value: string;
    importanceLevel: string;
}
interface SentinelTabInterface {
    label: string;
    subLabel?: string;
    amount?: string | number;
    data: ActionInterface[];
    isLoading?: boolean;
    active?: boolean;
    selectedSDI?: SelectedSDI;
    sdiLabel: string;
    sdiPercentage: number;
}

interface SentinelTabsInterface {
    currentTabIndex: number;
    setCurrentTabIndex: React.Dispatch<SetStateAction<number>>;
    tabs: SentinelTabInterface[];
    firstTabContent: JSX.Element;
    secondTabContent: JSX.Element;
    isLoading?: boolean;
    showLoaderOnCharts?: boolean;
    selectedSDI: SelectedSDI;
}

export const SentinelTab = ({
    label,
    subLabel,
    amount,
    data,
    isLoading = false,
    active,
    selectedSDI,
    sdiLabel,
    sdiPercentage
}: SentinelTabInterface) => {
    const showSubLabel = !isLoading;
    const prepareEventsTabLabel = (label, selectedSDI) => {
        return selectedSDI ? `${sdiLabel} ${selectedSDI.name}` : label;
    };
    const eventsTabLabel = prepareEventsTabLabel(label, selectedSDI);
    const isSDIEventsTab = selectedSDI && label === "Events Processed";

    return (
        <Box sx={styles.tabsWrapper}>
            <Box sx={styles.tabLabels}>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                    <StriimTypography variant="caption" sx={styles.tabLabel}>
                        {eventsTabLabel}
                    </StriimTypography>
                    {label === "Occurrences of Sensitive Data" &&
                        WithTooltip(
                            <SvgIcon sx={styles.iconInfo}>
                                <InfoCircle />
                            </SvgIcon>,
                            "Count of sensitive data detected across all fields of all events scanned by Sentinel. An event may contain multiple occurrences of sensitive data. Therefore, the occurrences of sensitive data detected may exceed the number of events that contain sensitive data."
                        )}
                </Box>
                {showSubLabel && (
                    <StriimTypography variant="caption4" alignSelf={"center"}>
                        {subLabel}
                    </StriimTypography>
                )}
            </Box>

            <Box sx={styles.tabBottomContainer}>
                {isLoading ? (
                    <StriimCircularProgress size={24} />
                ) : (
                    <>
                        <Box display="inline">
                            {isSDIEventsTab && (
                                <span
                                    style={{
                                        ...styles.actionSquare,
                                        backgroundColor: Colors.Identifier,
                                        paddingRight: "11px"
                                    }}
                                ></span>
                            )}

                            <StriimTypography variant="body" sx={styles.tabAmount}>
                                {numeral(amount).format("0.00a")}
                            </StriimTypography>
                            {isSDIEventsTab && (
                                <StriimTypography variant="body">{` (${sdiPercentage}%)`}</StriimTypography>
                            )}
                        </Box>

                        {!active && !isSDIEventsTab && (
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                {data.map(el => (
                                    <Box key={el.value} component="span" sx={styles.tabBottomContainerAction}>
                                        <StriimTypography
                                            variant="caption3"
                                            color="greyscale.700"
                                            fontFamily="Inter"
                                            display="inline"
                                        >
                                            {el.field}
                                        </StriimTypography>
                                        <StriimTypography
                                            variant="caption3"
                                            fontFamily="Inter"
                                            display="inline"
                                            color="greyscale.700"
                                            sx={el.showUnderline && styles.firstLetterUnderline}
                                        >
                                            {el.label}
                                        </StriimTypography>
                                        <StriimTypography
                                            variant="caption3"
                                            fontFamily="Inter"
                                            display="inline"
                                            sx={styles.actionValue}
                                        >
                                            {el.value}
                                        </StriimTypography>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};

const SentinelTabs = ({
    currentTabIndex,
    setCurrentTabIndex,
    tabs,
    firstTabContent,
    secondTabContent,
    isLoading = false,
    showLoaderOnCharts = false,
    selectedSDI
}: SentinelTabsInterface) => {
    const handleChange = (_event, newValue) => {
        setCurrentTabIndex(newValue);
    };

    const updatedTabs = prepareTabsData(tabs, selectedSDI);

    const getContent = (currentIndex, isLoading) =>
        isLoading ? <StriimCircularProgress size={24} /> : currentIndex === 0 ? firstTabContent : secondTabContent;

    return (
        <StriimTabs
            sx={styles.tabs}
            value={currentTabIndex}
            onChange={handleChange}
            content={getContent(currentTabIndex, showLoaderOnCharts ? isLoading : false)}
            variant="fullWidth"
            tabsStyles={styles.tabsStyles}
            contentStyles={styles.contentStyles}
        >
            {updatedTabs.map((tab, index) => (
                <StriimTab
                    key={tab.label + tab.amount}
                    label={
                        <SentinelTab
                            label={tab.label}
                            subLabel={tab.subLabel}
                            amount={tab.amount}
                            data={tab.data}
                            isLoading={tab.isLoading || isLoading}
                            active={index === currentTabIndex}
                            selectedSDI={selectedSDI}
                            sdiLabel={tab.sdiLabel}
                            sdiPercentage={tab.sdiPercentage}
                        />
                    }
                    sx={styles.tab}
                    key={index}
                />
            ))}
        </StriimTabs>
    );
};

export default SentinelTabs;
