export const styles = {
    iconBase: {
        fill: "none"
    },
    downloadIcon: {
        fontSize: 20,
        display: "flex"
    },
    downloadToolTipIcon: {
        textAlign: "center",
        alignItems: "center",
        maxHeight: "fit-content"
    }
};
