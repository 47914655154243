import { getTemplateGroups } from "../create-app-page/utils";
import predefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import { getAdapterData, getSpriteData } from "../../../../generic/icon/component-icon/adapter-native-icon-utils";
import securityCheck from "../../../../../core/services/securityService/adapter-license-check";
import securityService from "../../../../../core/services/securityService/securityService";

const targetsMap = {};
const sourceMap = {};
const adaptersMap = {};

export const createAppUtils = {
    getCategoriesForFilters: function() {
        const sourceCategories = new Set();
        const targetCategories = new Set();
        const adaptersData = getSpriteData();
        adaptersData.forEach(adapter => {
            if (!adapter.Category) {
                return;
            }
            if (adapter.Type === "TARGET") {
                targetCategories.add(adapter.Category);
            } else {
                sourceCategories.add(adapter.Category);
            }
        });
        const sourceCategoriesUnSorted = Array.from(sourceCategories).map(category => ({
            label: category,
            value: category
        }));
        const targetCategoriesUnSorted = Array.from(targetCategories).map(category => ({
            label: category,
            value: category
        }));

        const sourceCategoriesSorted = createAppUtils.sortCategories(sourceCategoriesUnSorted, "Source");
        const targetCategoriesSorted = createAppUtils.sortCategories(targetCategoriesUnSorted, "Target");

        return {
            sourceCategories: sourceCategoriesSorted,
            targetCategories: targetCategoriesSorted
        };
    },

    sortCategories: function(categories, type) {
        const sourceOrder = ["DATABASES", "APPLICATION", "CLOUD STORAGE", "DATA WAREHOUSE", "MISCELLANEOUS"];
        const targetOrder = [
            "DATA WAREHOUSE",
            "DATABASES",
            "APPLICATION",
            "EVENT BUS",
            "CLOUD STORAGE",
            "MISCELLANEOUS"
        ];
        const requiredOrder = type === "Source" ? sourceOrder : targetOrder;

        const sortedCategories = categories.sort((a, b) => {
            return requiredOrder.indexOf(a.value) - requiredOrder.indexOf(b.value);
        });

        return sortedCategories;
    },

    /**
     * TODO - Refactor this code to be more maintainable.
     */
    getSources: function() {
        let sources = [];
        if (Object.keys(targetsMap).length > 0) {
            sources = Object.keys(targetsMap);
        } else {
            const { templatesList } = getTemplateGroups();
            templatesList.forEach(template => {
                // console.log(template);
                // if (!template.enabledByLicense) return;
                const source = template.tags[0].split(": ")[1];
                if (!sourceMap[source]) sourceMap[source] = new Set();
                adaptersMap[source] = template.sourceAdapter;
                sourceMap[source].add(template.steps[0]);

                if (!targetsMap[source]) targetsMap[source] = new Set();
                adaptersMap[template.steps[1]] = template.targetAdapter;
                if (template.steps[1]) targetsMap[source].add(template.steps[1]);
                else if (template.tags[1].includes("Striim")) {
                    targetsMap[source].add("Striim");
                }
            });
            sources = Object.keys(targetsMap);
        }
        sources = sources.sort();

        const isFabricSolution = securityService.isFabricSolution();

        function checkisAdapterEnabled(adapter, source) {
            if (isFabricSolution && adapter === "MSJet") {
                return false;
            }
            return securityCheck.checkWithLicenseValidation(adapter, source);
        }
        let sourcesList = sources.map(source => {
            const sourceType = [...sourceMap[source]][0];
            const sourceDetails = getAdapterData()[sourceType];
            const details = predefinedComponentNames[sourceType];

            return {
                id: source,
                value: source,
                type: "SOURCE",
                icon: details.className,
                spriteId: sourceDetails["Sprite ID"],
                category: sourceDetails["Category"],
                newlyReleased: sourceDetails["Newly Released"] === "Yes",
                adapter: adaptersMap[source],
                isEnabled: checkisAdapterEnabled(adaptersMap[source], source)
            };
        });
        sourcesList.sort((a, b) => (a.id > b.id ? 1 : -1));
        return sourcesList.sort((a, b) => (a.isEnabled ? 1 : -1));
    },
    getTargets: function(source) {
        if (!source) return [];
        let sourceName = source.value;
        let targets = targetsMap[sourceName];
        let targetsList = [];

        targets.forEach(target => {
            const details = predefinedComponentNames[target];
            const sourceDetails = getAdapterData()?.[target] ?? {};

            let targetName = details.name;
            if (targetName.includes("Database") && targetName !== "Database") {
                targetName = details.name.replace("Database", "").trim();
            }
            targetsList.push({
                id: targetName,
                value: targetName,
                icon: details.className,
                type: "TARGET",
                spriteId: sourceDetails["Sprite ID"],
                category: sourceDetails["Category"],
                newlyReleased: sourceDetails["Newly Released"] === "Yes",
                adapter: adaptersMap[target],
                isEnabled: securityCheck.checkWithLicenseValidation(adaptersMap[target], targetName)
            });
        });
        targetsList = targetsList.sort((a, b) => (a.isEnabled ? 1 : -1));
        targetsList = targetsList.sort((a, b) => (a.id > b.id ? 1 : -1));
        return targetsList;
    }
};
