import React, { MutableRefObject, useMemo, useState } from "react";
// @ts-ignore
import { StriimFormBuilder } from "@striim/striim-ui";
import { Box } from "@mui/material";
import { getAdvancedFields } from "../../../services/target";

interface propTypes {
    formRef: MutableRefObject<any>;
    target: string;
    source: string;
    cdcSource: string;
    initialValues: Object;
    getCustomFields: (fields: Array<any>) => Array<Object>;
}

const sxStyles = {
    container: {
        "& .MuiFormLabel-root": {
            whiteSpace: "wrap"
        }
    }
};
const AdvancedSettingsForm = ({
    formRef,
    target,
    source,
    cdcSource,
    initialValues,
    getCustomFields
}: propTypes): React.ReactNode => {
    const fields = useMemo(() => getCustomFields(getAdvancedFields(target)), []);

    if (fields.length) {
        return (
            <Box sx={sxStyles.container}>
                <StriimFormBuilder
                    formProps={{ source, cdcSource }}
                    initialFieldValues={initialValues}
                    data={fields}
                    validateOnChange={true}
                    formRef={formRef}
                    setFieldToDefaultOnHidden={true}
                />
            </Box>
        );
    } else return null;
};

export default AdvancedSettingsForm;
