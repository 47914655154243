import React, { useMemo, useState } from "react";
import { StriimButton, StriimTypography } from "@striim/striim-ui";
import { Box, Grid, CircularProgress } from "@material-ui/core";
import useStyles from "./your_recent_dashboards.styles";
import { getSortText } from "../common";
import headerStore from "../../../stores/header";
import { useNavigate } from "react-router";
import { useObserver } from "mobx-react";
import useDashboardsStore from "../../../stores/dashboards/useDashboardsStore";
import DashboardsList from "../../dashboards/pages/dashboard-list/components/dashboards-list";
import useInitializedEffect from "../../../hooks/useInitializedEffect";
import AddDashboardModal from "../../dashboards/pages/dashboard-list/components/add-dashboard-dialog/add-dashboard-dialog";
import EmptyDashboardList from "../empty_dashboard_list/empty_dashboard_list";

const tabMap = new Map();
tabMap.set(0, "MostRecent");

const YourRecentDashboards = () => {
    const navigate = useNavigate();
    const classes = useStyles();

    const store = useDashboardsStore();
    const homepageDashboards = useObserver(() => store.filteredDashboards);
    const isLoading = useObserver(() => store.dashboardsLoading);

    const [sortToggled, setSortToggled] = useState(false);
    const [tabIndex] = useState(0);
    const [isAddDashboardDialogVisible, setIsAddDashboardDialogVisible] = useState(false);

    const filteredDashboards = useObserver(() => headerStore.latestDashboards.slice()?.slice(0, 5));

    useInitializedEffect(() => {
        store.dashboardsFetch();
    }, [store]);

    const matchVisibleDashboards = useMemo(() => {
        return filteredDashboards?.map(item => homepageDashboards?.find(t2 => t2?.id === item?.id));
    }, [filteredDashboards, homepageDashboards]);

    return (
        <Grid>
            <Grid container className={classes.dashboardsTitleWrapper}>
                <StriimTypography variant="h3" data-test-id="header--homepage-dashboards">
                    Your Recent Dashboards{" "}
                </StriimTypography>

                <StriimButton
                    onClick={() => navigate("/dashboards-list")}
                    variant={"text"}
                    data-test-id="button--homepage-dashboards-view-all"
                >
                    View all Dashboards
                </StriimButton>
            </Grid>

            {isLoading ? (
                <Box className={classes.dashboardLoaderContainer}>
                    <CircularProgress />
                </Box>
            ) : matchVisibleDashboards?.length === 0 || !matchVisibleDashboards || !homepageDashboards ? (
                <EmptyDashboardList openAddDashboardDialog={setIsAddDashboardDialogVisible} />
            ) : (
                <Grid>
                    <Grid
                        item
                        onClick={() => {
                            setSortToggled(!sortToggled);
                        }}
                        className={classes.dashboardsSortContainer}
                    >
                        <StriimTypography
                            className={classes.dashboardsUserSelect}
                            data-test-id="button--homepage-dashboards-sort"
                        >
                            {getSortText(sortToggled, filteredDashboards?.length, tabMap.get(tabIndex))}
                        </StriimTypography>
                    </Grid>

                    <Dashboards filteredDashboards={matchVisibleDashboards} />
                </Grid>
            )}

            <AddDashboardModal
                isVisible={isAddDashboardDialogVisible}
                setIsAddDashboardDialogVisible={setIsAddDashboardDialogVisible}
            />
        </Grid>
    );
};

const Dashboards = ({ filteredDashboards }) => {
    return <DashboardsList mode={"list"} dashboards={filteredDashboards} homepage />;
};

export default YourRecentDashboards;
