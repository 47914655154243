import _ from "underscore";
import growl from "app/components/common/growl";
import utils from "core/utils";
import bowser from "bowser";
import { isSafari } from "core/utils/browser-check";
import EventLogEntry from "./eventLogEntry";
import eventsFormatter from "./eventsFormatter";
import EventsDataSource from "./eventsDataSource";
import EventsLogSearchField from "./eventsLogSearchField";
import EventsLogListView from "./eventsLogListView";
import eventsLogTypes from "./eventsLogTypes";
import dropdownHide from "./dropdownHide";
import predefinedTimeOptions from "./predefinedTimeOptions";
import DateRangePicker from "app/components/common/editor/control/date-range-picker/date-range-picker";
import SearchableMultiSelectView from "app/components/common/searchable-lists/SearchableMultiSelect";
import FileSaver from "file-saver";
import eventsLogTemplate from "./templates/eventsLogTemplate.html";
import { LayoutView } from "marionette";
import { template, className, ui, regions, on } from "core/decorators";

@template(eventsLogTemplate)
@className("sidebar wrapper z-depth-2")
@ui({
    timeFilter: ".time-filter",
    timeDropdown: ".time-dropdown",
    timeInfo: ".timeInfo",
    timeInfoText: ".timeInfoText",

    nodeFilter: ".node-filter",
    nodeDropdown: ".node-dropdown",
    nodesCount: ".nodesCount",

    typeFilter: ".type-filter",
    typeDropdown: ".type-dropdown",
    typesCount: ".typesCount",

    downloadButton: ".actions button.download",
    fullWidthButton: ".actions button.fullscreen",
    closeButton: ".actions button.close"
})
@regions({
    searchRegion: ".search",
    listRegion: ".list",
    timeFilterRegion: ".time-dropdown",
    nodeFilterRegion: ".node-dropdown",
    typeFilterRegion: ".type-dropdown"
})
export default class EventsLogView extends LayoutView {
    @on("click @ui.timeFilter")
    timeFilterClicked() {
        this.ui.timeDropdown.toggle();
        this.ui.typeDropdown.hide();
        this.ui.nodeDropdown.hide();
    }

    @on("click @ui.nodeFilter")
    nodeFilterClicked() {
        this.ui.timeDropdown.hide();
        this.ui.nodeDropdown.toggle();
        this.ui.typeDropdown.hide();
    }

    @on("click @ui.typeFilter")
    typeFilterClicked() {
        this.ui.timeDropdown.hide();
        this.ui.nodeDropdown.hide();
        this.ui.typeDropdown.toggle();
    }

    initialize() {
        this.eventsDataSource = new EventsDataSource();
    }

    refresh(selectedNodes) {
        const newEvents = this.eventsDataSource.filter();
        const newEventsJson = JSON.stringify(newEvents);
        const oldEventsJson = JSON.stringify(this.eventsLogListView.collection);

        let normalizedNewEvents = newEventsJson.replace(/\\n/g, "").replace(/[^a-zA-Z0-9]/g, "");
        let normalizedOldEvents = oldEventsJson.replace(/<br \/>/g, "").replace(/[^a-zA-Z0-9]/g, "");

        if (normalizedNewEvents === normalizedOldEvents) {
            return;
        }
        this.eventsLogListView.collection.reset(newEvents);
        if (selectedNodes) {
            const availableNodes = this.eventsDataSource.getAvailableNodes();
            this.nodesDropdownView.setDataSource(availableNodes, selectedNodes);
        }
        this.eventsLogListView.updateLayout();
    }

    reloadEvents() {
        if (this.isDestroyed || !this._isSidebarActive) {
            return;
        }

        this.eventsDataSource.load(this.dateFilterValue.from, this.dateFilterValue.to).then(() => {
            this.refresh();
        });
    }

    _isSidebarActive = false;

    setSidebarActive(enabled) {
        this._isSidebarActive = enabled;
    }

    onRender() {
        dropdownHide.attach();

        this.eventsLogListView = new EventsLogListView({
            model: this.model.logEntries,
            collection: new EventLogEntry.Collection()
        });

        const eventsLogSearchFieldModel = new EventsLogSearchField.Model({
            value: this.model.searchPhrase
        });
        this.listenTo(eventsLogSearchFieldModel, "change:value", () => {
            this.eventsDataSource.search(eventsLogSearchFieldModel.value);
            this.refresh();
        });
        const eventsLogSearchFieldView = new EventsLogSearchField.View({
            model: eventsLogSearchFieldModel
        });
        this.searchRegion.show(eventsLogSearchFieldView);

        this.dateRangePicker = DateRangePicker(predefinedTimeOptions).create();
        this.timeFilterRegion.show(this.dateRangePicker);

        this.dateFilterValue = this.dateRangePicker.getViewValue();
        this.listenTo(this.dateRangePicker.model, "change:value", viewModel => {
            this.dateFilterValue = viewModel.value;
            this.ui.timeDropdown.hide();
            this.ui.timeInfoText.html(viewModel.value.displayType);
            this.reloadEvents();
        });

        this.eventsDataSource.initialize().then(() => {
            if (this.isDestroyed) {
                return;
            }

            this.interval = setInterval(() => {
                this.reloadEvents();
            }, 2000);
            this.reloadEvents();

            this.typesDropdownView = new SearchableMultiSelectView({
                dataArray: [eventsLogTypes.issues, eventsLogTypes.statusChanges]
            });

            this.typeFilterRegion.show(this.typesDropdownView);
            this.listenTo(this.typesDropdownView, "selectedListItems", selectedValues => {
                this.ui.typeDropdown.hide();
                this.ui.typesCount.html(selectedValues.length > 0 ? "(" + selectedValues.length + ")" : "");
                this.eventsDataSource.types = selectedValues;
                this.refresh();
            });

            const availableNodes = this.eventsDataSource.getAvailableNodes();
            this.nodesDropdownView = new SearchableMultiSelectView({
                dataArray: availableNodes
            });

            this.nodeFilterRegion.show(this.nodesDropdownView);
            this.listenTo(this.nodesDropdownView, "selectedListItems", selectedValues => {
                this.ui.nodeDropdown.hide();
                this.ui.nodesCount.html(selectedValues.length > 0 ? "(" + selectedValues.length + ")" : "");
                this.eventsDataSource.nodes = selectedValues;
                this.refresh(selectedValues);
            });

            // When in the context of an app (app monitor), Filter by that app
            const appName = this.model.get("appName");
            if (appName && availableNodes.includes(appName)) {
                const selectedApp = [appName];
                this.ui.nodesCount.html("(1)");
                this.eventsDataSource.nodes = selectedApp;
                this.refresh(selectedApp);
            }
        });

        this.listRegion.show(this.eventsLogListView);
    }

    onDestroy() {
        dropdownHide.detach();
        clearInterval(this.interval);
    }

    @on("click @ui.closeButton")
    closeView() {
        if (this.isFullWidth()) {
            this.toggleFullWidth();
        }
        this.trigger("sidebar:close");
    }

    @on("click @ui.fullWidthButton")
    toggleFullWidth() {
        this.$el.toggleClass("full-width");

        if (this.isFullWidth()) {
            this.ui.fullWidthButton.children().html("fullscreen_exit");
        } else {
            this.ui.fullWidthButton.children().html("fullscreen");
        }
        this.trigger("sidebar:toggleFullWidth");
    }

    isFullWidth() {
        return this.$el.hasClass("full-width");
    }

    @on("click @ui.downloadButton")
    downloadLog() {
        if (isSafari()) {
            growl.warning(
                "Please press cmd+s in the new tab opened to manually save the Event Logs. ",
                "Safari Warning"
            );
        }
        const filename = "EventLogs_" + _.now() + ".txt";
        FileSaver.saveAs(
            new Blob([eventsFormatter.format(this.eventsDataSource.filter())], {
                type: "text/plain"
            }),
            filename
        );
    }
}
