import React, { SetStateAction, useMemo } from "react";
import { Divider, Grid, SvgIcon } from "@mui/material";
import {
    StriimTypography,
    StriimTooltip,
    StriimButton,
    StriimButtonGroupSimple,
    StriimInputField
} from "@striim/striim-ui";

import {
    ArrowBack,
    Close,
    Download,
    LastRefresh,
    LivePhoto,
    Lock,
    Sentinel,
    SidebarCollapse
} from "../../../../../generic/icon/customIcons";
import { styles } from "./panel-header-controls.styles";
import { ImportanceLevelIconPaths, ImportanceLevels } from "../../utils";
import { SentinelState } from "../../../guardian/sentinel-monitor/service/sentinel-monitor.types";
import { formatTimestampRanges } from "../timeline-view-chart/utils";
import { TABS } from "../../sentinel-live-monitor";
import InfoIcon from "app/images/striimline/info-icon.svg";
import WithTooltip from "../../../../../generic/tooltip/tooltip";
import Printable from "../../../reports/printable";
import AppReport from "../../../reports/sentinel-reports/app-report/sentinel-app-report";

type SDI = { name: string; importanceLevel: ImportanceLevels };

export const DownloadButton = () => {
    return (
        <StriimButton
            variant="text"
            startIcon={
                <SvgIcon sx={styles.buttonIcon}>
                    <Download />
                </SvgIcon>
            }
        >
            Report
        </StriimButton>
    );
};

interface PanelHeaderControlsProps {
    showBottomBorder?: boolean;
    selectedSDI: SDI | null;
    onBackClick: Function;
    selectedSentinel: { label: string; value: string };
    setSelectedSentinel: React.Dispatch<SetStateAction<{ label: string; value: string }>>;
    selectedTab: string;
    selectedDateRange: { from: number; to: number } | null;
    handleTabChange: (value: string) => void;
    sentinels: SentinelState[];
    isPanelExpanded: boolean;
    onToggleFullScreen: Function;
    onClose: Function;
    fetchReportData: Function;
    appName: string;
}

const PanelHeaderControls: React.FC<PanelHeaderControlsProps> = ({
    showBottomBorder = true,
    selectedSDI,
    onBackClick,
    selectedSentinel,
    setSelectedSentinel,
    selectedTab,
    selectedDateRange,
    handleTabChange,
    sentinels,
    isPanelExpanded,
    onToggleFullScreen,
    onClose,
    fetchReportData,
    appName
}) => {
    const sentinelOptions = useMemo(() => {
        let options = sentinels.map(item => ({
            label: item.isDiscoveryDisabled ? `${item.name} (Detection Disabled)` : item.name,
            value: item.name
        }));
        options.unshift({ label: `All Sentinels (${sentinels.length})`, value: "All" });
        return options;
    }, [sentinels]);

    return (
        <>
            <Grid
                container
                alignItems={"center"}
                px={isPanelExpanded ? 3 : 2}
                py={isPanelExpanded ? (!!selectedSDI ? 1.5 : 2) : 2}
            >
                {!!selectedSDI && isPanelExpanded && (
                    <Grid container mb={0.5} gap={0.5} alignItems={"center"}>
                        <SvgIcon sx={styles.sentinelIcon}>
                            <Sentinel />
                        </SvgIcon>
                        <StriimTypography variant="caption3" color="greyscale.700" fontFamily="Inter">
                            Sentinel AI Activity
                        </StriimTypography>
                    </Grid>
                )}
                <Grid
                    container
                    flexDirection={isPanelExpanded ? "row" : "column"}
                    alignItems={isPanelExpanded ? "center" : "flex-start"}
                    gap={2}
                >
                    {isPanelExpanded && (
                        <Grid display={"flex"} item alignItems={"center"} gap={1}>
                            {!!selectedSDI ? (
                                <>
                                    <StriimButton
                                        variant="text"
                                        startIcon={
                                            <SvgIcon sx={styles.backIcon}>
                                                <ArrowBack />
                                            </SvgIcon>
                                        }
                                        sx={styles.iconButton}
                                        onClick={onBackClick}
                                    />
                                    <Grid display={"flex"} alignItems={"center"} gap={0.5}>
                                        <img
                                            src={ImportanceLevelIconPaths[selectedSDI.importanceLevel]}
                                            alt={`${selectedSDI.importanceLevel} Importance`}
                                            width={"20px"}
                                            height={"20px"}
                                        />
                                        <StriimTypography variant="h3" color="primary.700">
                                            {selectedSDI.name} Activity for
                                        </StriimTypography>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <SvgIcon sx={styles.sentinelIcon}>
                                        <Sentinel />
                                    </SvgIcon>
                                    <StriimTypography variant="h3" color="primary.700">
                                        Sentinel AI Activity for
                                    </StriimTypography>
                                </>
                            )}
                        </Grid>
                    )}

                    {/* <StriimTooltip title={selectedSentinel?.label} placement="top"> */}
                    <Grid item width={!isPanelExpanded ? "100%" : "auto"}>
                        <StriimInputField
                            SelectProps={{
                                options: sentinelOptions,
                                isClearable: false
                            }}
                            onChange={item => {
                                setSelectedSentinel(item);
                            }}
                            value={selectedSentinel ?? sentinelOptions[0]}
                            select
                        />
                    </Grid>
                    {isPanelExpanded && (
                        <Grid item>
                            {WithTooltip(
                                <SvgIcon component={InfoIcon} sx={styles.iconInfo} />,
                                <>Sentinel uses AI engines that may sometimes misclassify the information.</>
                            )}
                        </Grid>
                    )}
                    {/* </StriimTooltip> */}

                    {isPanelExpanded && selectedSentinel && selectedSentinel.value !== "All" ? (
                        <Grid item>
                            <StriimButton
                                variant="text"
                                startIcon={
                                    <SvgIcon sx={{ width: 16, height: 16, ...styles.clearIcon }}>
                                        <Close />
                                    </SvgIcon>
                                }
                                onClick={() => {
                                    setSelectedSentinel(sentinelOptions[0]);
                                }}
                            >
                                Filters
                            </StriimButton>
                        </Grid>
                    ) : null}
                    <Grid
                        item
                        display={"flex"}
                        width={!isPanelExpanded ? "100%" : "auto"}
                        justifyContent={"space-between"}
                        ml={isPanelExpanded ? "auto" : 0}
                        gap={isPanelExpanded ? 2 : 0}
                        alignItems={"center"}
                    >
                        <Grid item display={"flex"} alignItems={"center"} gap={0.5}>
                            {!isPanelExpanded ||
                            !selectedDateRange ||
                            (isPanelExpanded && selectedDateRange && selectedTab !== TABS[0]) ? (
                                <StriimTooltip title={"Live, every min"}>
                                    <SvgIcon sx={{ width: 16, height: 16 }}>
                                        <LivePhoto />
                                    </SvgIcon>
                                </StriimTooltip>
                            ) : null}

                            <StriimTypography variant="caption3" color="greyscale.700" fontFamily="Inter">
                                {selectedTab === TABS[0]
                                    ? isPanelExpanded && !!selectedDateRange
                                        ? formatTimestampRanges(selectedDateRange.from, selectedDateRange.to)
                                        : "Last 1 Hour"
                                    : "Last 24 Hours"}
                                {isPanelExpanded && !selectedDateRange ? " (Live)" : null}
                            </StriimTypography>
                            {isPanelExpanded && (
                                <Grid item display={"flex"} alignItems={"center"} gap={0.5}>
                                    , <LastRefresh style={{ fill: "none" }} />
                                    <StriimTypography variant="caption3" color="greyscale.700" fontFamily="Inter">
                                        every min
                                    </StriimTypography>
                                </Grid>
                            )}
                        </Grid>

                        <Grid item display={"flex"} alignItems={"center"}>
                            <StriimButtonGroupSimple
                                handleChange={handleTabChange}
                                tabs={TABS}
                                selectedTab={selectedTab}
                            />
                        </Grid>
                        {isPanelExpanded && !selectedSDI && (!selectedSentinel || selectedSentinel?.value === "All") ? (
                            <>
                                <Divider orientation="vertical" sx={{ borderColor: "greyscale.200", height: 24 }} />
                                <Grid item display={"flex"}>
                                    <Printable
                                        name={`${appName}_Sentinel_report`}
                                        component={AppReport}
                                        appendToBody={true}
                                        fetchReportData={fetchReportData}
                                        buttonComponent={<DownloadButton />}
                                    />
                                </Grid>
                            </>
                        ) : null}
                        {isPanelExpanded && (
                            <Grid display={"flex"} item gap={1}>
                                {!selectedSDI && (
                                    <StriimButton
                                        variant="text"
                                        startIcon={
                                            <SvgIcon sx={styles.startIcon}>
                                                <SidebarCollapse />
                                            </SvgIcon>
                                        }
                                        sx={{ ...styles.iconButton, ...(isPanelExpanded && styles.expanded) }}
                                        onClick={() => onToggleFullScreen(false)}
                                    />
                                )}
                                <StriimButton
                                    variant="text"
                                    startIcon={
                                        <SvgIcon sx={[styles.startIcon, styles.closeIcon]}>
                                            <Close />
                                        </SvgIcon>
                                    }
                                    sx={styles.iconButton}
                                    onClick={onClose}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {(!isPanelExpanded || showBottomBorder) && (
                <Divider variant="fullWidth" sx={{ borderColor: "greyscale.200", width: "100%" }} />
            )}
        </>
    );
};

export default PanelHeaderControls;
