import { makeStyles } from "@material-ui/core/styles";

const consts = {
    outputMinHeight: 300
};

export default makeStyles(({ spacing, palette }) => ({
    output: {
        marginTop: spacing(9),
        height: "calc(100vh - 308px)",
        minHeight: consts.outputMinHeight
    },
    codeMirrorContainer: {
        height: "calc(100vh - 260px)",

        "& .CodeMirror": {
            height: "calc(100vh - 324px)",
            minHeight: consts.outputMinHeight,
            borderRadius: 12,
            border: "1px solid " + palette.paper.border,
            padding: spacing(2)
        }
    },
    actionsContainer: {
        flexDirection: "column",
        marginTop: spacing(2)
    },
    input: {
        marginBottom: spacing(2)
    },
    execButton: {
        paddingLeft: spacing(4),
        paddingRight: spacing(4),
        marginRight: spacing(1)
    },
    historyList: {
        color: themeV5 => themeV5?.palette?.greyscale[900],
        lineHeight: "24px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
    }
}));
