import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { icons } from "../flow-designer-icon";
import { Box } from "@material-ui/core";
import { StriimIconWrapperBase, StriimTypography } from "@striim/striim-ui";

import useToolTipStyles from "./component-icon-tooltip.styles";
import useStyles from "./component-icon.styles";
import metaObjectHelper from "app/components/common/editor/meta-object-editors/metaObjectHelper";
import ComponentIconTooltip from "./component-icon-tooltip";
import WithTooltip from "./../../tooltip/tooltip";
import AdapterNativeIcon from "./adapter-native-icon";

const colors = {
    source: "#346CC2",
    target: "#2E8071"
};

const OutlinedIcon = ({ name, type, size }) => {
    const Icon = icons?.[name];
    if (!Icon) return null;
    const color = type === "source" ? colors.source : colors.target;
    return <Icon style={{ width: size, height: size, fill: color }} />;
};

const getIconName = (iconName, isMirror) => {
    if (isMirror) iconName = iconName.replace(/-mirror/i, "");
    return iconName;
};

export const AdapterIcon = ({
    name,
    type,
    tooltip = null,
    useNative = false,
    size,
    spriteId,
    newlyReleased = false,
    showBorder = true,
    outlined = false
}) => {
    const classes = useStyles({ iconSize: size });
    const isMirror = name.endsWith("-mirror");
    let iconName = getIconName(name, isMirror);
    if (useNative) {
        return (
            <AdapterNativeIcon
                name={name}
                type={type}
                tooltip={tooltip}
                size={size}
                spriteId={spriteId}
                newlyReleased={newlyReleased}
                showBorder={showBorder}
            />
        );
    }
    if (outlined) {
        return <OutlinedIcon name={name} type={type} size={size} />;
    }
    return WithTooltip(
        <>
            <div className={classes.iconContainer}>
                <StriimIconWrapperBase Icon={icons[iconName]} className={classes.componentIcon} type={type} />
            </div>
        </>,
        tooltip
    );
};

const ComponentIcon = observer(({ component, componentType, text, appId, extraComponents, iconSize = 24 }) => {
    const classes = useStyles({ iconSize });
    if (component) {
        const className = metaObjectHelper.getIconClassByMetaObject(component);
        return (
            <AdapterIcon
                name={className}
                type={componentType}
                tooltip={<ComponentIconTooltip appId={appId} component={component} />}
                size={iconSize}
            />
        );
    }

    const ComponentContent = () => (
        <StriimTypography className={classes.componentIconText} variant="bodyBold">
            {text}
        </StriimTypography>
    );
    const toolTipClasses = useToolTipStyles();
    const ToolTipContent = extraComponents.map(component => (
        <Box className={toolTipClasses.tooltipText} key={`${appId}-${component.id}`}>
            <StriimTypography className={toolTipClasses.name} variant="body">
                {component.name}
            </StriimTypography>
            <StriimTypography className={toolTipClasses.desc} variant="caption">
                {component.type}
            </StriimTypography>
        </Box>
    ));
    return WithTooltip(
        <StriimIconWrapperBase
            Icon={ComponentContent}
            className={classes.componentIcon}
            type={componentType}
            classNameContainer={classes.iconWrapper}
        />,
        ToolTipContent
    );
});

ComponentIcon.propTypes = {
    component: PropTypes.object,
    componentType: PropTypes.string.isRequired,
    text: PropTypes.string
};

export default ComponentIcon;
