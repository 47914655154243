import React, { useMemo, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import numeral from "numeral";
import { Grid, Box, SvgIcon } from "@mui/material";
import { StriimTypography, StriimLink, StriimChip, StriimTooltip } from "@striim/striim-ui-v2";

import SentinelTableCommon from "./sentinel-table-common";
import { InfoCircle, ArrowUpward } from "../../../../../generic/icon/customIcons";
import { styles } from "./sentinel-table.styles";
import { Colors, EmptyBar, sortTableData } from "./sentinel-table-utils";
import WithTooltip from "../../../../../generic/tooltip/tooltip";

const SentinelTableBreakdown = ({
    title,
    tableData,
    filteredTableData,
    setFilteredTableData,
    timeRange,
    showProgress,
    setSelectedSentinel
}) => {
    const [sortOrder, setSortOrder] = useState(true);

    const totalAmount = tableData.reduce(
        (acc, curr) => {
            return {
                identifiers: acc.identifiers + (curr.disabled ? 0 : curr.eventsWithIdentifier),
                occurrences: acc.occurrences + (curr.disabled ? 0 : curr.occurrences)
            };
        },
        { identifiers: 0, occurrences: 0 }
    );
    const sortedFilteredData = sortTableData(filteredTableData, sortOrder);

    // update the calculation with getTwoPointPrecision method
    const getTwoPointPrecision = num => (num ? (num % 1 ? num.toFixed(2) : num) : 0);

    const tableColumns = useMemo(
        () => [
            {
                headerName: "Sentinel Name",
                headerClass: "dataTableHeader",
                field: "name",
                initialWidth: 370,
                flex: 0.3,
                suppressMovable: true,
                resizable: true,
                cellRenderer: ({ value, data }) => WithTooltip(
                    <StriimTypography color="greyscale.900" variant="body4" sx={styles.nameRowLabel}>
                        <StriimLink href="" onClick={() => setSelectedSentinel({ label: value, value })}>
                            <Box component="span" sx={styles.longValueWrap}>
                                {value}
                            </Box>
                        </StriimLink>

                        {data.disabled && (
                            <StriimChip hideAvatar type="tag-chip" variant="default" label="DETECTION DISABLED" />
                        )}
                    </StriimTypography>,
                    value
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: "Events W/ Identifiers",
                headerClass: "dataTableHeader",
                initialWidth: 300,
                suppressMovable: true,
                resizable: true,
                headerComponent: () => (
                    <Box sx={styles.customHeader}>
                        <Box display="flex" alignItems="center">
                            <span style={{ ...styles.sensitiveDataSquare, backgroundColor: Colors.Identifier, paddingRight: "11px" }}></span>
                            <StriimTypography variant="body3" color="greyscale.600" display="inline" mr={1}>
                                Events W/ Identifiers
                            </StriimTypography>
                            <StriimTypography
                                variant="caption3"
                                fontFamily="Inter"
                                color="greyscale.600"
                                display="inline"
                            >
                                {`of (${numeral(totalAmount.identifiers).format("0.00a").toUpperCase()})`}
                            </StriimTypography>
                            <SvgIcon component={InfoCircle} sx={styles.infoCircle} />
                            <Box
                                component="span"
                                onClick={() => setSortOrder(prevVal => !prevVal)}
                                sx={sortOrder ? null : styles.downArrow}
                            >
                                <ArrowUpward fontSize="16"/>
                            </Box>
                        </Box>
                    </Box>
                ),
                cellRenderer: ({ data }) => {
                    if (!(data.eventsWithIdentifier + data.occurrences > 0)) return <EmptyBar />;

                    return (
                        <Grid container sx={styles.chartWrapper}>
                            <ResponsiveContainer width="100%" height={20}>
                                <BarChart
                                    height={20}
                                    data={[
                                        {
                                            identifiersAmount: data.eventsWithIdentifier,
                                            otherIdentifiersAmount: totalAmount.identifiers - data.eventsWithIdentifier
                                        }
                                    ]}
                                    layout="vertical"
                                >
                                    <XAxis hide type="number" domain={[0, 300]} />
                                    <YAxis hide type="category" />

                                    <Bar
                                        dataKey="identifiersAmount"
                                        stackId="single-stack"
                                        animationDuration={0}
                                        fill={Colors.Identifier}
                                    />
                                    <Bar
                                        dataKey="otherIdentifiersAmount"
                                        stackId="single-stack"
                                        animationDuration={0}
                                        fill={Colors.Rest}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                            <StriimTypography
                                variant="caption4"
                                fontFamily="Inter"
                                color="greyscale.700"
                                paddingLeft={0.6}
                            >
                                {`${data.eventsWithIdentifier} (${Math.round(
                                    (100 * data.eventsWithIdentifier) / totalAmount.identifiers
                                )}%)`}
                            </StriimTypography>
                        </Grid>
                    );
                },
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: "Occurrences",
                headerClass: "dataTableHeader",
                field: "occurrences",
                flex: 0.2,
                suppressMovable: true,
                resizable: true,
                sortable: true,
                headerComponent: () => (
                    <Box sx={styles.customHeader}>
                        <Box display="flex" alignItems="flex-end" justifyContent="flex-end" pr={3}>
                            <StriimTypography variant="body3" color="greyscale.600" display="inline" mr={1}>
                                Occurrences
                            </StriimTypography>
                            <StriimTypography
                                variant="caption3"
                                fontFamily="Inter"
                                color="greyscale.600"
                                display="inline"
                            >
                                {`(${numeral(totalAmount.occurrences).format("0.00a").toUpperCase()})`}
                            </StriimTypography>
                        </Box>
                    </Box>
                ),
                cellClass: "dataCellTextEnd",
                cellRenderer: ({ value }) => (
                    <StriimTypography color="greyscale.900" variant="body4">
                        {value}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },

            {
                headerName: "Policy Actions",
                headerClass: "dataTableHeader",
                // field: "actions",
                flex: 0.3,
                suppressMovable: true,
                resizable: true,
                cellRenderer: ({ data }) => {
                    if (!(data.eventsWithIdentifier + data.occurrences > 0)) return <EmptyBar />;

                    const masked = data.actions?.MASK || 0;
                    const encrypted = data.actions?.ENCRYPT || 0;
                    const noaction = data.actions?.NO_ACTION || 0;
                    const totalAmountActions = masked + encrypted + noaction;

                    const maskedPercentage = getTwoPointPrecision((100 * masked) / totalAmountActions);
                    const encryptedPercentage = getTwoPointPrecision((100 * encrypted) / totalAmountActions);
                    const noactionPercentage = getTwoPointPrecision((100 * noaction) / totalAmountActions);

                    return (
                        <StriimTooltip
                            slotProps={{ tooltip: { sx: styles.tooltip } }}
                            title={
                                <Box sx={styles.tooltipWrapper}>
                                    <StriimTypography variant="caption4" fontFamily="Inter" sx={styles.tooltipHeading}>
                                        {`LAST ${timeRange?.toUpperCase()}`}
                                    </StriimTypography>

                                    <StriimTypography variant="caption4" fontFamily="Inter" p={1}>
                                        Policy Actions
                                    </StriimTypography>

                                    {!!encrypted && (
                                        <Box sx={styles.tooltipLabel}>
                                            <Box component="span" sx={styles.tooltipLabelTitle}>
                                                <span
                                                    style={{
                                                        ...styles.tooltipSquare,
                                                        backgroundColor: Colors.Encrypted
                                                    }}
                                                ></span>
                                                <StriimTypography
                                                    variant="caption3"
                                                    fontFamily="Inter"
                                                    color="greyscale.700"
                                                >
                                                    Encrypted
                                                </StriimTypography>
                                            </Box>

                                            <Box component="span">
                                                <StriimTypography variant="caption3" fontFamily="Inter" color="#1E1E5C">
                                                    {`${encrypted} (${encryptedPercentage}%)`}
                                                </StriimTypography>
                                            </Box>
                                        </Box>
                                    )}

                                    {!!masked && (
                                        <Box sx={styles.tooltipLabel}>
                                            <Box component="span" sx={styles.tooltipLabelTitle}>
                                                <span
                                                    style={{ ...styles.tooltipSquare, backgroundColor: Colors.Masked }}
                                                ></span>
                                                <StriimTypography
                                                    variant="caption3"
                                                    fontFamily="Inter"
                                                    color="greyscale.700"
                                                >
                                                    Masked
                                                </StriimTypography>
                                            </Box>

                                            <Box component="span">
                                                <StriimTypography variant="caption3" fontFamily="Inter" color="#1E1E5C">
                                                    {`${masked} (${maskedPercentage}%)`}
                                                </StriimTypography>
                                            </Box>
                                        </Box>
                                    )}

                                    {!!noaction && (
                                        <Box sx={styles.tooltipLabel}>
                                            <Box component="span" sx={styles.tooltipLabelTitle}>
                                                <span
                                                    style={{
                                                        ...styles.tooltipSquare,
                                                        backgroundColor: Colors.NoAction
                                                    }}
                                                ></span>
                                                <StriimTypography
                                                    variant="caption3"
                                                    fontFamily="Inter"
                                                    color="greyscale.700"
                                                >
                                                    No Action
                                                </StriimTypography>
                                            </Box>

                                            <Box component="span">
                                                <StriimTypography variant="caption3" fontFamily="Inter" color="#1E1E5C">
                                                    {`${noaction} (${noactionPercentage}%)`}
                                                </StriimTypography>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            }
                        >
                            <Grid container sx={styles.chartWrapper}>
                                <ResponsiveContainer width="100%" height={20}>
                                    <BarChart
                                        height={20}
                                        data={[
                                            {
                                                masked: data.actions?.MASK || 0,
                                                encrypted: data.actions?.ENCRYPT || 0,
                                                noaction: data.actions?.NO_ACTION || 0
                                            }
                                        ]}
                                        layout="vertical"
                                    >
                                        <XAxis hide type="number" domain={[0, 300]} />
                                        <YAxis hide type="category" />

                                        <Bar
                                            dataKey="encrypted"
                                            stackId="single-stack"
                                            animationDuration={0}
                                            fill={Colors.Encrypted}
                                        />
                                        <Bar
                                            dataKey="masked"
                                            stackId="single-stack"
                                            animationDuration={0}
                                            fill={Colors.Masked}
                                        />
                                        <Bar dataKey="noaction" stackId="single-stack" fill={Colors.NoAction} />
                                    </BarChart>
                                </ResponsiveContainer>
                                <Grid display="inline-flex" gap={0.5}>
                                    <StriimTypography
                                        variant="caption4"
                                        fontFamily="Inter"
                                        color="greyscale.700"
                                        paddingLeft={0.6}
                                    >
                                        {!!encrypted && `E: (${encryptedPercentage}%)`}
                                    </StriimTypography>
                                    <StriimTypography
                                        variant="caption4"
                                        fontFamily="Inter"
                                        color="greyscale.700"
                                        paddingLeft={0.6}
                                    >
                                        {!!masked && `M: (${maskedPercentage}%)`}
                                    </StriimTypography>
                                    <StriimTypography
                                        variant="caption4"
                                        fontFamily="Inter"
                                        color="greyscale.700"
                                        paddingLeft={0.6}
                                    >
                                        {!!noaction && `N: (${noactionPercentage}%)`}
                                    </StriimTypography>
                                </Grid>
                            </Grid>
                        </StriimTooltip>
                    );
                },
                headerCheckboxSelection: false,
                checkboxSelection: false
            }
        ],
        [tableData, filteredTableData, sortOrder]
    );

    return (
        <SentinelTableCommon
            tableColumns={tableColumns}
            title={title}
            tableData={tableData}
            filteredTableData={sortedFilteredData}
            setFilteredTableData={setFilteredTableData}
            showActionsFilter
            showProgress={showProgress}
        />
    );
};

export default SentinelTableBreakdown;
