import React, { useEffect, createContext } from "react";
import { HashRouter, Route, Routes, Navigate, useLocation } from "react-router-dom";
import NavBar from "./navbar/nav-bar.jsx";
import securityService from "../../core/services/securityService/securityService";
import App from "../../app";

import APPS_ROUTES from "./apps/routes.json";
import HomePage from "./apps/pages/homepage/homepage";
import AppListPage from "./apps/pages/app-list/app-list-page";
import CreateAppPage from "./apps/pages/create-app-page";
import CreateAppPageNameApp from "./apps/pages/create-app-name-app-page";
import CreateAppPageUsingTemplates from "./apps/pages/create-app-page-templates";

import FLOW_DESIGNER_ROUTES from "./flow-designer/routes.json";
import FlowDesigner from "./flow-designer/flow-designer";

import ALERT_MANAGER_ROUTES from "./alert-manager/routes.json";
import AlertManagerDetails from "./alert-manager/components/alert-details";
import AddNewAlert from "./alert-manager/components/add-new-alert";
import SMTPSetup from "./alert-manager/components/smtp-setup";
import SlackSetup from "./alert-manager/components/slack-setup";
import TeamsSetup from "./alert-manager/components/teams-setup";
import AlertManager from "./alert-manager/alert-manager";

import WIZARD_ROUTES from "./wizards/routes.json";
import AppWizards from "./wizards/app-wizards";

import USER_PLAN_ROUTES from "./user-plan/routes.json";
import UserPlanPage from "./user-plan/pages/user-plan";
import UpgradeToCloudPage from "./apps/pages/unauthorized-pages/upgrade-to-cloud";

import USERS_ROUTES from "./apps/pages/user-management/routes.json";
import UsersPage from "./apps/pages/user-management/users/users-page";
import UsersUserDetailsPage from "./apps/pages/user-management/users-user";
import RoleDetailsPage from "./apps/pages/user-management/users-role";
import UserManagementUnauthorizedPage from "./apps/pages/user-management/user-management-unauthorized/user-management-unauthorized";

import MANAGE_STRIIM_ROUTES from "./apps/pages/manage-striim/routes.json";
import ManageStriimPage from "./apps/pages/manage-striim";

import DASHBOARDS_ROUTES from "./dashboards/routes.json";
import DashboardListPage from "./dashboards/pages/dashboard-list";

import DEBUG_ROUTES from "./debug/routes.json";
import Debug from "./debug/debug";
import TestMetaObjectTQL from "./debug/test-metaObject-tql";
import TestIframe from "./debug/test-iframe";
import TestStyleView from "./debug/test-style-view";
import TestApi from "./debug/test-api";
import TestCrud from "./debug/test-crud";
import TestDetails from "./debug/test-details";

import AI_ROUTES from "./ai-insights/routes.json";
import { GuardianHome } from "./ai-insights/guardian/index";
import GuardianSelectApps from "./ai-insights/guardian/guardian-select-apps";
import GuardianViewApp from "./ai-insights/guardian/pages/guardian-view-app";

import CONSOLE_ROUTES from "./console/routes.json";
import ConsoleLayout from "./console/console";

import SOURCE_PREVIEW_ROUTES from "../modules/preview/routes.json";
import Preview from "../modules/preview/preview";
import PreviewFile from "../modules/preview/preview-file";

import DASHBOARD_ROUTES from "./dashboard/routes.json";
import ReadonlyDashboard from "./dashboard/readonly-dashboard";
import EditableDashboard from "./dashboard/editable-dashboard";
import EmbedDashboard from "./dashboard/embed-dashboard";
import Monitor from "./monitor/monitor";
import MonitorAppDetails from "./monitor/monitor-app-details";
import MONITOR_ROUTES from "./monitor/routes.json";
import AlertManagerNotAvailable from "./alert-manager/components/alert-manager-not-available";
import { useNavigationProgressBar } from "../hooks/useNavigationProgressBar";
import { useNavigationSecurity } from "../hooks/useNavigationSecurity";
import NotFound from "./login/not-found";
import Message from "./login/message";
import AuthrPlayGround from "./debug/authr-play-ground";
import { StriimBreadcrumbsProvider } from "@striim/striim-ui";
import TopBar from "./navbar/top-bar.jsx";
import PageFrame from "./frame";
import {
    ConnectionProfilesPage,
    CreateEditConnectionProfile,
    ViewConnectionProfile
} from "./apps/pages/manage-striim/connection-profiles";
import { EmbeddingGeneratorWrapper } from "./apps/pages/manage-striim/embedding-generator/embedding-generator-page";

import Tracker from "core/services/tracker/tracker";
import GuardianUnauthorized from "./ai-insights/guardian/pages/guardian-unauthorized";
import Guardian from "./ai-insights/guardian/guardian-root";

export const ReactContext = createContext(null);

const BACKBONE_HEADER_HEIGHT = "48px";

const ReactPageFrame = ({ children }) => {
    const loading = useNavigationSecurity();
    const location = useLocation();
    useEffect(() => {
        App.content.empty();
    });
    useEffect(() => {
        Tracker.getInstance().pageView();
    }, [location]);
    return <ReactContext.Provider value={{ loading }}>{children}</ReactContext.Provider>;
};

const BackboneFrame = ({ children }) => {
    const location = useLocation();

    useNavigationProgressBar();
    useNavigationSecurity();

    useEffect(() => {
        document.getElementById("content").style.marginTop = BACKBONE_HEADER_HEIGHT;
        App.reactContent.empty();
        return () => {
            document.getElementById("content").style.marginTop = `0px`;
        };
    });

    useEffect(() => {
        Tracker.getInstance().pageView();
    }, [location]);

    return children;
};

export const RootRouter = () => {
    return (
        <HashRouter>
            <PageFrame
                Navbar={NavBar}
                Topbar={
                    <StriimBreadcrumbsProvider>
                        <TopBar />
                    </StriimBreadcrumbsProvider>
                }
            >
                <Routes>
                    <Route
                        path={FLOW_DESIGNER_ROUTES.showApp}
                        element={
                            <BackboneFrame>
                                <FlowDesigner />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={FLOW_DESIGNER_ROUTES.showFlow}
                        element={
                            <BackboneFrame>
                                <FlowDesigner />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={FLOW_DESIGNER_ROUTES.showFlowEditNode}
                        element={
                            <BackboneFrame>
                                <FlowDesigner />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={FLOW_DESIGNER_ROUTES.showEditNode}
                        element={
                            <BackboneFrame>
                                <FlowDesigner />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={FLOW_DESIGNER_ROUTES.showAppView}
                        element={
                            <BackboneFrame>
                                <FlowDesigner />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={FLOW_DESIGNER_ROUTES.showSentinelAIActivity}
                        element={
                            <BackboneFrame>
                                <FlowDesigner />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={FLOW_DESIGNER_ROUTES.showSherlock}
                        element={
                            <BackboneFrame>
                                <FlowDesigner />
                            </BackboneFrame>
                        }
                    />

                    <Route
                        path={ALERT_MANAGER_ROUTES.addAlert}
                        element={
                            <BackboneFrame>
                                <AddNewAlert />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={ALERT_MANAGER_ROUTES.alertDetailTab}
                        element={
                            <BackboneFrame>
                                <AlertManagerDetails />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={ALERT_MANAGER_ROUTES.alertDetails}
                        element={
                            <BackboneFrame>
                                <AlertManagerDetails />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={ALERT_MANAGER_ROUTES.setupSMTP}
                        element={
                            <BackboneFrame>
                                <SMTPSetup />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={ALERT_MANAGER_ROUTES.setupSlack}
                        element={
                            <BackboneFrame>
                                <SlackSetup />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={ALERT_MANAGER_ROUTES.setupTeams}
                        element={
                            <BackboneFrame>
                                <TeamsSetup />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={ALERT_MANAGER_ROUTES.alertmanager}
                        element={
                            <BackboneFrame>
                                <AlertManager />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={ALERT_MANAGER_ROUTES.unauthorized}
                        element={
                            <BackboneFrame>
                                <AlertManagerNotAvailable />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={APPS_ROUTES.homepage}
                        element={
                            <ReactPageFrame>
                                <HomePage />
                            </ReactPageFrame>
                        }
                    />
                    <Route path={"/"} element={<Navigate to={APPS_ROUTES.homepage} />} />
                    <Route
                        path={APPS_ROUTES.appList}
                        element={
                            <ReactPageFrame>
                                <AppListPage />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={WIZARD_ROUTES.createAppWizard}
                        element={
                            <ReactPageFrame>
                                <CreateAppPageNameApp />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={APPS_ROUTES.appCreateNameApp}
                        element={
                            <ReactPageFrame>
                                <CreateAppPageNameApp />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={APPS_ROUTES.appCreate}
                        element={
                            <ReactPageFrame>
                                <CreateAppPageUsingTemplates />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={APPS_ROUTES.appCreateTemplates}
                        element={
                            <ReactPageFrame>
                                <CreateAppPage />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={USERS_ROUTES.users}
                        element={
                            <ReactPageFrame>
                                <UsersPage />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={USERS_ROUTES.usersUserDetailsWithId}
                        element={
                            <ReactPageFrame>
                                <UsersUserDetailsPage />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={USERS_ROUTES.usersUserEdit}
                        element={
                            <ReactPageFrame>
                                <UsersUserDetailsPage editing={true} />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={USERS_ROUTES.usersUserCreate}
                        element={
                            <ReactPageFrame>
                                <UsersUserDetailsPage />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={USERS_ROUTES.usersRoleCreate}
                        element={
                            <ReactPageFrame>
                                <RoleDetailsPage />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={USERS_ROUTES.usersRoleDetailsWithId}
                        element={
                            <ReactPageFrame>
                                <RoleDetailsPage />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={USERS_ROUTES.usersUnauthorized}
                        element={
                            securityService.isDeveloperLicense() ? (
                                <ReactPageFrame>
                                    <UpgradeToCloudPage />
                                </ReactPageFrame>
                            ) : (
                                <ReactPageFrame>
                                    <UserManagementUnauthorizedPage />
                                </ReactPageFrame>
                            )
                        }
                    />
                    <Route
                        path={MANAGE_STRIIM_ROUTES.manageStriim}
                        element={
                            <ReactPageFrame>
                                <ManageStriimPage />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={MANAGE_STRIIM_ROUTES.connectionProfiles}
                        element={
                            <ReactPageFrame>
                                <ConnectionProfilesPage />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={MANAGE_STRIIM_ROUTES.createConnectionProfile}
                        element={
                            <ReactPageFrame>
                                <CreateEditConnectionProfile />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={MANAGE_STRIIM_ROUTES.editConnectionProfile}
                        element={
                            <ReactPageFrame>
                                <CreateEditConnectionProfile isEdit={true} />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={MANAGE_STRIIM_ROUTES.viewConnectionProfile}
                        element={
                            <ReactPageFrame>
                                <ViewConnectionProfile />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={USER_PLAN_ROUTES.planUnauthorized}
                        element={
                            <ReactPageFrame>
                                <UpgradeToCloudPage />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={DASHBOARDS_ROUTES.dashboardList}
                        element={
                            <ReactPageFrame>
                                <DashboardListPage />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={USER_PLAN_ROUTES.plan}
                        element={
                            <ReactPageFrame>
                                <UserPlanPage />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={SOURCE_PREVIEW_ROUTES.previewFile}
                        element={
                            <BackboneFrame>
                                <PreviewFile />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={SOURCE_PREVIEW_ROUTES.preview}
                        element={
                            <BackboneFrame>
                                <Preview />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={MONITOR_ROUTES.monitor}
                        element={
                            <BackboneFrame>
                                <Monitor />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={MONITOR_ROUTES.appData}
                        element={
                            <BackboneFrame>
                                <MonitorAppDetails />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={MONITOR_ROUTES.app}
                        element={
                            <BackboneFrame>
                                <MonitorAppDetails />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={WIZARD_ROUTES.appWizard}
                        element={
                            <BackboneFrame>
                                <AppWizards />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={AI_ROUTES.sherlock}
                        element={
                            <ReactPageFrame>
                                <Guardian>
                                    <GuardianHome />
                                </Guardian>
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={AI_ROUTES.discoverApps}
                        element={
                            <ReactPageFrame>
                                <Guardian>
                                    <GuardianSelectApps selectApps={false} />
                                </Guardian>
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={AI_ROUTES.selectApps}
                        element={
                            <ReactPageFrame>
                                <Guardian>
                                    <GuardianSelectApps selectApps={true} />
                                </Guardian>
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={AI_ROUTES.editApp}
                        element={
                            <ReactPageFrame>
                                <Guardian>
                                    <GuardianSelectApps selectApps={true} editJob={true} />
                                </Guardian>
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={AI_ROUTES.viewApp}
                        element={
                            <ReactPageFrame>
                                <Guardian>
                                    <GuardianViewApp />
                                </Guardian>
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={AI_ROUTES.embeddingGenerator}
                        element={
                            <ReactPageFrame>
                                <EmbeddingGeneratorWrapper />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={AI_ROUTES.unauthorized}
                        element={
                            <ReactPageFrame>
                                <GuardianUnauthorized />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={CONSOLE_ROUTES.console}
                        element={
                            <ReactPageFrame>
                                <ConsoleLayout />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path={DEBUG_ROUTES.authr}
                        element={
                            <BackboneFrame>
                                <AuthrPlayGround />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={DEBUG_ROUTES.debug}
                        element={
                            <BackboneFrame>
                                <Debug />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={DEBUG_ROUTES.testMetaObjectTQL}
                        element={
                            <BackboneFrame>
                                <TestMetaObjectTQL />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={DEBUG_ROUTES.testIframe}
                        element={
                            <BackboneFrame>
                                <TestIframe />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={DEBUG_ROUTES.testStyles}
                        element={
                            <BackboneFrame>
                                <TestStyleView />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={DEBUG_ROUTES.testDetails}
                        element={
                            <BackboneFrame>
                                <TestDetails />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={DEBUG_ROUTES.testCrud}
                        element={
                            <BackboneFrame>
                                <TestCrud />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={DEBUG_ROUTES.testApi}
                        element={
                            <BackboneFrame>
                                <TestApi />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={DASHBOARD_ROUTES.begin_embed_dashboard}
                        element={
                            <BackboneFrame>
                                <EmbedDashboard />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={DASHBOARD_ROUTES.begin_edit_dashboard_page}
                        element={
                            <BackboneFrame>
                                <EditableDashboard />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path={DASHBOARD_ROUTES.begin_show_dashboard_page}
                        element={
                            <BackboneFrame>
                                <ReadonlyDashboard />
                            </BackboneFrame>
                        }
                    />
                    <Route
                        path="/unauthorized"
                        element={
                            <ReactPageFrame>
                                <Message text="You do not have access to this feature." />
                            </ReactPageFrame>
                        }
                    />
                    <Route
                        path="/ended"
                        element={
                            <ReactPageFrame>
                                <Message text="Your session has ended. Please use Striim Cloud to log back in." />
                            </ReactPageFrame>
                        }
                    />
                    <Route path="*" Component={NotFound} />
                </Routes>
            </PageFrame>
        </HashRouter>
    );
};

export default RootRouter;
