import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { Box, Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfiniteScroll from "react-infinite-scroll-component";

import useStores from "src/utils/use-stores";
import NewAppTile from "../app-tile/new-app-tile";
import classNames from "classnames";
import DisplayMode from "../display-mode/display-mode";
import AppGroupSelect from "../app-group-select/app-group-select";
import AppControl from "app/components/common/app-control/app-control";
import { formatGroupError } from "src/utils/error-formatter";
import colors from "src/colors";
import growl from "app/components/common/growl";

import {
    StriimCheckbox,
    StriimButton,
    StriimDropdown,
    StriimTypography,
    StriimIconButton,
    StriimControlLabel
} from "@striim/striim-ui";
import EditColumnsDialog from "src/modules/apps/pages/app-list/components/app-list/data-table/edit-columns-modal";
import { EditColumns } from "src/generic/icon/customIcons";
import config from "../../../../../../config";
import AppListService from "src/modules/apps/pages/app-list/app-list-service.js";

const useStyles = makeStyles(({ spacing, shadows }) => ({
    selectedAppsBox: {
        backgroundColor: colors.box,
        borderRadius: spacing(1)
    },
    tooltipWrapper: {
        boxShadow: shadows[24],
        "& > div": {
            overflow: "hidden"
        }
    },
    header: {
        marginRight: 15
    },
    caption: {
        color: "rgba(13, 20, 23, 0.45)"
    },
    headerBox: {
        height: 44
    },
    editColumnsIcon: {
        display: "flex",
        alignSelf: "center"
    },
    headerBoxChild: {
        padding: `${spacing(1)}px ${spacing(3)}px ${spacing(1)}px ${spacing(3)}px`,
        marginBottom: spacing(2),
        display: "flex",
        alignItems: "center"
    },
    defaultHeaderBox: {
        display: "flex",
        padding: `${spacing(0.5)}px 0 ${spacing(0.5)}px 0`,
        marginBottom: spacing(2)
    },
    defaultHeaderBoxChild: {
        display: "flex",
        alignItems: "center",
        flexGrow: 1
    },
    selectionText: {
        display: "flex",
        alignItems: "center",
        flexGrow: 1
    },
    flex: {
        display: "flex"
    },
    selectAllButton: {
        display: "flex",
        alignItems: "center",
        flexGrow: 1
    }
}));

const APPLIST_BUFFER = 12;

const AppList = observer(
    ({ apps, selectAll, setSelectAll, setSelectedApps, clearSelections, setClearSelections, dropApplication }) => {
        const [loadedApps, setLoadedApps] = useState(apps?.slice(0, APPLIST_BUFFER));
        const [hasMore, setHasMore] = useState(true);

        const loadMoreApps = () => {
            const loadedAppsLength = loadedApps?.length ?? 0;
            if (loadedAppsLength >= apps?.length) {
                setHasMore(false);
                return;
            }
            setLoadedApps(apps?.slice(0, loadedAppsLength + APPLIST_BUFFER));
        };

        useEffect(() => {
            setLoadedApps(apps?.slice(0, APPLIST_BUFFER));
            setHasMore(true);
        }, [apps]);

        return !apps?.length ? (
            <div id="applist-tile-view" style={{ paddingTop: 300, height: 600, textAlign: "center" }}>
                <StriimTypography variant="body">No apps to show</StriimTypography>
            </div>
        ) : (
            <div
                id="applist-tile-view"
                style={{
                    marginTop: 16,
                    marginLeft: -config.commonStyles.allAppsCardsMargin,
                    marginRight: -config.commonStyles.allAppsCardsMargin,
                    maxHeight: 900,
                    overflow: "auto"
                }}
            >
                <InfiniteScroll
                    dataLength={loadedApps?.length}
                    next={loadMoreApps}
                    hasMore={hasMore && apps?.length > loadedApps?.length}
                    loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
                    scrollableTarget={"applist-tile-view"}
                >
                    <Grid container data-test-id="grid-apps-container">
                        {loadedApps?.map(app => (
                            <NewAppTile
                                key={app.id}
                                app={app}
                                selectAll={selectAll}
                                setSelectAll={setSelectAll}
                                setSelectedApps={setSelectedApps}
                                clearSelections={clearSelections}
                                setClearSelections={setClearSelections}
                                dropApplication={dropApplication}
                            />
                        ))}
                    </Grid>
                </InfiniteScroll>
            </div>
        );
    }
);

export const SubNav = observer(
    ({ mode, onModeChange, apps, selectedApps, selectAllTiles, clearAppSelections, setEditColumnsModalSubmitted }) => {
        const classes = useStyles({});
        const { store, groupsStore } = useStores();
        const isGroupSelectState = useState(false);
        const [isEditColumnsDialogVisible, setIsEditColumnsDialogVisible] = useState(false);
        const getAppPlural = number => {
            return number ? number.toString() + " App" + (number === 1 ? "" : "s") : "";
        };
        const numberOfApps = getAppPlural(apps?.length);
        const currentGroup = AppListService.getSelectedGroup();

        const getSelectionText = selectedAppsCount => {
            if (selectedAppsCount === 0) {
                return numberOfApps;
            }
            return getAppPlural(selectedAppsCount) + " selected";
        };

        async function onSelectClose(selectedGroup) {
            if (!selectedGroup) {
                isGroupSelectState[1](false);
                return;
            }
            try {
                await groupsStore.assignToGroup(selectedApps, selectedGroup.name, selectedGroup.description);
                clearAppSelections();
                isGroupSelectState[1](false);
            } catch (error) {
                const message = formatGroupError(error, selectedGroup.name);
                growl.error(message, "Error creating group");
            }
        }

        const onExportClick = async () => {
            let appIDs = [];
            selectedApps.forEach(app => {
                appIDs.push(app.id);
            });

            const appControl = new AppControl({
                appIdentifier: selectedApps[0] //backward compat
            });
            appControl.exportMultiple(appIDs);

            store.clearSelection();
        };

        const handleSelectAll = () => {
            if (selectedApps?.length == apps?.length) {
                clearAppSelections();
            } else {
                selectAllTiles();
            }
        };

        const SelectAllButton = () => {
            const noApps = !(apps?.length > 0);
            return (
                <Box display="flex" alignItems="center" flexGrow={1}>
                    <StriimControlLabel
                        control={
                            <StriimCheckbox
                                checked={!noApps && selectedApps?.length == apps?.length}
                                onChange={handleSelectAll}
                                disabled={noApps}
                            />
                        }
                        label="Select All"
                    />
                </Box>
            );
        };

        return (
            <Box className={classes.headerBox}>
                {selectedApps.length > 0 ? (
                    <Box
                        mb={2}
                        py={1}
                        pl={3}
                        pr={3}
                        display="flex"
                        alignItems="center"
                        className={classNames(classes.headerBoxChild, {
                            [classes.selectedAppsBox]: selectedApps.length > 0
                        })}
                    >
                        <Box className={classes.selectionText}>
                            <StriimTypography mr="auto">{getSelectionText(selectedApps.length)}</StriimTypography>
                        </Box>
                        {mode == "grid" && <SelectAllButton />}
                        <Box className={classes.flex}>
                            <StriimButton variant="primaryText" onClick={onExportClick}>
                                Export
                            </StriimButton>
                            <StriimDropdown
                                placement="bottom-end"
                                extendOnClick
                                controlState={isGroupSelectState}
                                content={
                                    <AppGroupSelect
                                        onClose={onSelectClose}
                                        groups={groupsStore.groups}
                                        selectedApps={selectedApps}
                                    />
                                }
                            >
                                <Box>
                                    <Box
                                        component={StriimButton}
                                        hidden={currentGroup.groupType === "ILCDC"}
                                        variant="primaryText"
                                        mr={-2}
                                    >
                                        Move to group
                                    </Box>
                                </Box>
                            </StriimDropdown>
                        </Box>
                    </Box>
                ) : (
                    <Box className={classes.defaultHeaderBox}>
                        <Box className={classes.defaultHeaderBoxChild}>
                            <StriimTypography className={classes.header} color="primary.700" variant="h2">
                                {currentGroup?.name !== "All" ? currentGroup?.name : "All Apps"}
                            </StriimTypography>
                            <StriimTypography className={classes.caption} variant="caption">
                                Refreshed every 15s
                            </StriimTypography>
                        </Box>
                        {mode == "grid" && <SelectAllButton />}
                        <DisplayMode mode={mode} onModeChange={onModeChange} />
                        <Divider orientation="vertical" flexItem style={{ margin: "0px 10px" }} />
                        <StriimIconButton
                            data-test-id="edit-columns-button"
                            onClick={() => setIsEditColumnsDialogVisible(true)}
                        >
                            <EditColumns className={classes.editColumnsIcon} />
                        </StriimIconButton>
                    </Box>
                )}
                <EditColumnsDialog
                    isVisible={isEditColumnsDialogVisible}
                    setIsEditColumnsDialogVisible={setIsEditColumnsDialogVisible}
                    setEditColumnsModalSubmitted={setEditColumnsModalSubmitted}
                />
            </Box>
        );
    }
);

AppList.propTypes = {
    apps: PropTypes.array,
    selectAll: PropTypes.bool,
    setSelectAll: PropTypes.func,
    setSelectedApps: PropTypes.func,
    clearSelections: PropTypes.bool,
    setClearSelections: PropTypes.func
};

export default AppList;
