import React, { useContext, useEffect, useState } from "react";
import { Box, CardContent, Grid } from "@mui/material";

import { styles } from "./sentinel-monitor.styles";
import SentinelMonitorSidebar from "./components/sentinel-monitor-sidebar";
import { SidebarStates, SidebarWidth } from "./components/sidebar.consts";
import { MonitorContent, SidebarContent, SidebarExpandedContent } from "./components/sentinel-monitor-components";
import { SentinelMonitorContext } from "./sentinel-monitor-context";
import getSentinelMonitorService from "./service/sentinel-moniter-service";
import SentinelAIEmpty from "./components/sentinel-ai-empty-component/sentinel-ai-empty-component";
import growl from "../../../../../app/components/common/growl";
import LoadingIndicator from "../../../../generic/loading-indicator";
import SentinelAppsDropdown from "./components/sentinel-dropdown/sentinel-apps-dropdown";
import useStriimAIEngine from "../../hooks/useStriimAIEngine";

const POLL_INTERVAL = 60_000;

const SentinelMonitor = () => {
    const {
        sidebarState,
        formatSentinelDetails,
        setRunningAppDetails,
        RunningAppDetails,
        selectedApps,
        setSelectedApps,
        appSentinelMapping,
        isSelectedAppWithoutSentinel
    } = useContext(SentinelMonitorContext);
    const expanded = sidebarState === SidebarStates.EXPANDED;

    const [chartData, setChartData] = useState(null);
    const striimAIEngine = useStriimAIEngine();

    const fetchSidebarData = async () => {
        try {
            const runningApps = await getSentinelMonitorService().getRunningAppsWithSentinels();
            const chartsInfo = await getSentinelMonitorService().getRunningAppsWithSentinelsDashboard();
            const sentinelApps = await getSentinelMonitorService().getRunningAppsWithSentinelsDetail();
            setRunningAppDetails(runningApps);
            setChartData(chartsInfo);
            formatSentinelDetails(sentinelApps);
        } catch (error) {
            growl.error(error);
        }
    };

    useEffect(() => {
        fetchSidebarData();
        const intervalId = setInterval(async () => {
            fetchSidebarData();
        }, POLL_INTERVAL);
        return () => clearInterval(intervalId);
    }, [striimAIEngine]);

    useEffect(() => {
        if (!selectedApps && RunningAppDetails) {
            setSelectedApps(RunningAppDetails.runningApps);
        }
    }, [RunningAppDetails, selectedApps]);

    return !RunningAppDetails ? (
        <LoadingIndicator isGlobal={false} />
    ) : !RunningAppDetails?.runningApps?.length && striimAIEngine.isConfigured && !striimAIEngine.isConnected ? (
        <SentinelAIEmpty striimAIEngine={striimAIEngine} />
    ) : (
        <CardContent sx={styles.cardContent}>
            <Grid sx={styles.monitorContainer}>
                {!RunningAppDetails?.runningApps?.length ? (
                    <SentinelAIEmpty />
                ) : isSelectedAppWithoutSentinel ? (
                    <Grid container pt={3} pl={3} gap={3}>
                        <SentinelAppsDropdown
                            apps={RunningAppDetails.runningApps}
                            sentinelsCount={RunningAppDetails.sentinels}
                            selectedApps={selectedApps}
                            setSelectedApps={setSelectedApps}
                            appSentinelMapping={appSentinelMapping}
                        />
                        <SentinelAIEmpty isSelectedAppWithoutSentinel={isSelectedAppWithoutSentinel} />
                    </Grid>
                ) : (
                    <>
                        <Box
                            sx={{
                                ...(expanded ? styles.expandedContent : styles.collapsedContent),
                                width: `calc(100% - ${SidebarWidth[sidebarState]})`
                            }}
                        >
                            <MonitorContent />
                        </Box>
                        <Box
                            sx={{
                                ...styles.sidebar,
                                width: SidebarWidth[sidebarState]
                            }}
                        >
                            <SentinelMonitorSidebar
                                content={<SidebarContent chartData={chartData} />}
                                expandedContent={<SidebarExpandedContent />}
                            />
                        </Box>
                    </>
                )}
            </Grid>
        </CardContent>
    );
};

export default SentinelMonitor;
