import { makeStyles } from "@material-ui/core/styles";
import config from "../../../../../../config";

export default makeStyles(({ spacing, palette }) => ({
    cardRoot: {
        margin: config.commonStyles.allAppsCardsMargin,
        width: 375,
        height: "auto",
        boxSizing: "border-box",
        background: "#FFFFFF",
        border: "1px solid #D8E4F0",
        borderRadius: 8
    },
    appTileCheckbox: {
        height: 18,
        width: 18,
        marginLeft: 2,
        marginTop: -4,
        marginRight: -6
    },
    cardHeaderRoot: {
        flexDirection: "row",
        alignItems: "flex-start",
        padding: spacing(1.25, 1.25, 0, 1.25)
    },
    cardHeaderTitle: {
        cursor: "pointer",

        marginTop: 1,

        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",

        "&:hover": {
            color: palette.primary.main
        }
    },
    cardContentRoot: {
        padding: 0,

        "&:last-child": {
            paddingBottom: 0
        }
    },
    cardHeaderContent: {
        flex: "unset",
        marginRight: "auto",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    cardAppInfo: {
        direction: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        padding: spacing(1, 0.5),
        margin: 0,
        borderTop: "1px solid #EBEBEB",
        borderBottom: "1px solid #EBEBEB"
    },
    appInfoValues: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    icons: {
        color: palette.gray3,
        fontSize: 24
    },
    appStatus: {
        margin: spacing(0.5)
    },
    tableWrapper: {
        display: "block"
    },
    tableMetricCell: {
        padding: "0 10px"
    }
}));
