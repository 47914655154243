export const styles = {
    tabContainer: {
        background: "white",
        borderRadius: "10px",
        border: ({ palette }) => `1px solid ${palette.greyscale["200"]}`,
        width: "500px"
    },
    adapterTabBox: {
        display: "flex",
        alignItems: "center",
        gap: 2
    },
    selectLabel: {
        display: "flex",
        alignItems: "center",
        gap: 1 / 2
    },
    adapterTabLabel: {
        display: "flex",
        flexDirection: "column"
    },
    selectedAdapterLabel: {
        fontWeight: 400,
        lineHeight: ({ spacing }) => spacing(3)
    }
};
