import React, { useState } from "react";
import { StriimMenuList, StriimMenuItem, StriimInputField } from "@striim/striim-ui";
import { Box, InputAdornment, SvgIcon } from "@mui/material";

import { Close } from "src/generic/icon/customIcons";
import SearchIcon from "app/images/Search.svg";
import { Entity, Source } from "../../pii.types";

const styles = {
    searchInput: {
        p: 1
    },
    searchIcon: {
        fontSize: 20,
        fill: "none",
        "& > path": {
            stroke: ({ palette }) => palette.greyscale[800]
        }
    },
    chevronIcon: {
        fontSize: "10px !important",
        fill: "none",
        "& > path": {
            fill: ({ palette }) => palette.greyscale[800]
        }
    },

    closeIcon: {
        fontSize: 20,
        fill: "none",
        cursor: "pointer"
    },
    menuList: {
        minWidth: 350
    }
};

interface SearchableListProps {
    type: string;
    list: Entity[] | Source[];
    onListItemClick: (value: Entity | Source) => void;
}

const SearchableList: React.FC<SearchableListProps> = ({ type, list, onListItemClick }) => {
    const [searchInput, setSearchInput] = useState("");

    const filteredList = list.filter(item => item.name.toLowerCase().includes(searchInput.toLowerCase()));

    return (
        <>
            <StriimInputField
                value={searchInput}
                onChange={setSearchInput}
                sx={styles.searchInput}
                placeholder={`Search by ${type} Name`}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SvgIcon component={SearchIcon} sx={styles.searchIcon} />
                        </InputAdornment>
                    ),
                    endAdornment: searchInput ? (
                        <InputAdornment position="end" onClick={() => setSearchInput("")}>
                            <SvgIcon component={Close} sx={styles.closeIcon} />
                        </InputAdornment>
                    ) : (
                        undefined
                    )
                }}
            />
            <StriimMenuList sx={styles.menuList}>
                {filteredList.length ? (
                    filteredList.map(item => {
                        return (
                            <StriimMenuItem
                                key={item.name}
                                data-test-id={`${type}-list-${item.name}`}
                                onClick={() => onListItemClick(item)}
                            >
                                {item.name}
                            </StriimMenuItem>
                        );
                    })
                ) : (
                    <Box p={2}>No {type} found</Box>
                )}
            </StriimMenuList>
        </>
    );
};

export default SearchableList;
