const levels = {
    "metadata-manager": "Metadata",
    "property-sets": "Property Sets",
    "property-variables": "Property Variables",
    "embedding-generator": "Vector Embeddings Generator",
    "connection-profiles": "Connection Profiles",
    discover: "Discover Sensitive Data",
    select: "Discover Sensitive Data"
};

const getLabel = text => levels[text] || text;

const makeLevel = (...args) =>
    args
        .filter(item => item)
        .map(item => {
            return { label: item };
        });

export const getLevels = path => {
    const topLevel = path.split("/")[1];

    if (topLevel === "landing") return makeLevel("Home");
    if (topLevel === "create-app") return makeLevel("Apps", "Create App");
    if (topLevel === "create-app-templates") return makeLevel("Apps", "Create App");
    if (topLevel === "preview") return makeLevel("Apps", "Source Preview");
    if (topLevel === "applications") return makeLevel("Apps");
    if (topLevel === "appwizard") return makeLevel("Apps", "Create App", "Wizards");
    if (topLevel === "flow") {
        const appName = path.split("/")[2];
        return makeLevel("Apps", appName);
    }
    if (topLevel === "monitor") {
        const appName = path.split("/")[2];
        return makeLevel("Monitor", appName);
    }
    if (topLevel === "manage-striim") {
        const tab = path.split("/")[2];
        return makeLevel("Manage Striim", getLabel(tab));
    }
    if (topLevel === "alertmanager") {
        let setup = null;

        if (path.includes("setup-teams")) setup = "Configure Teams";
        else if (path.includes("setup-slack")) setup = "Configure Slack";
        else if (path.includes("setup")) setup = "Configure Email";

        return makeLevel("Manage Striim", "Alert Manager ", setup);
    }
    if (topLevel === "users") return makeLevel("Manage Striim", "Users + Roles");
    if (topLevel === "dashboards-list") return makeLevel("Dashboards");
    if (topLevel === "dashboard") {
        const dashName = path.split("/")[3];
        return makeLevel("Dashboards", dashName);
    }
    if (topLevel === "sherlock-ai") {
        const tab = path.split("/").at(-1);
        return makeLevel("Sensitive Data Governance", "Sherlock AI Reports", getLabel(tab));
    }
    if (topLevel === "embedding-generator") {
        return makeLevel("Embeddings Generator");
    }
    if (topLevel === "console") return makeLevel("Console");
    if (topLevel === "account-and-billing") return makeLevel("Account and Billing");
    return makeLevel(topLevel);
};
