import React, { useState } from "react";
import { Card, CardContent, CardActions } from "@mui/material";
import { Grid, Box } from "@mui/material";
import PropTypes from "prop-types";
import { StriimTypography, StriimIconWrapperBase, StriimButton, StriimMessageBox } from "@striim/striim-ui";
import { icons } from "src/generic/icon/flow-designer-icon";
import classnames from "classnames";

import useStyles, { sxStyles } from "./select-api.styles";
import Footer from "../footer/footer";
import { useTheme } from "@mui/material/styles";
import Tracker from "../../../../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../../../core/services/tracker/constants";
const ApiTile = ({ store, api, onApiSelect, isSelected = true }) => {
    const classes = useStyles();

    return (
        <Card
            sx={{
                ...sxStyles.appTile,
                ...(isSelected ? sxStyles.apiTileSelected : {})
            }}
            variant="outlined"
            onClick={() => onApiSelect(api)}
            data-test-id={api.id}
        >
            <CardContent classes={{ root: classes.cardContentRoot }}>
                <Grid container justifyContent="flex-start" alignItems="center">
                    <Grid item className={classes.icons}>
                        <StriimIconWrapperBase
                            classNameContainer={classes.iconBase}
                            Icon={icons[api.icon]}
                            type="target"
                        />
                    </Grid>
                    <Grid item className={classes.titleContainer}>
                        <StriimTypography sx={sxStyles.title} variant="body1" className={classes.title}>
                            {api.title}
                        </StriimTypography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const SelectApi = ({ store, header, description, selected, targetApis, onApiSelect }) => {
    const [selectedApi, setSelectedApi] = useState(selected || null);
    const classes = useStyles();

    const updateSelection = api => {
        setSelectedApi(api);
        onApiSelect(api);
    };

    return (
        <Grid container direction="column">
            <Grid container direction="row" sx={sxStyles.header}>
                <Grid item xs={8}>
                    <StriimTypography sx={sxStyles.cardTitle} variant="h2">
                        {header}
                    </StriimTypography>
                    <StriimTypography variant="body4" sx={sxStyles.description}>
                        {description}
                    </StriimTypography>
                </Grid>
                <Grid item className={classes.learnMorePadding} xs={4}>
                    <StriimButton
                        variant="text"
                        onClick={() => {
                            Tracker.getInstance().track(TRACKER_STRINGS.schema.learnMore, {
                                event: TRACKER_STRINGS.eventClicked.learnMore
                            });
                            window.open("https://docs.microsoft.com/en-us/azure/cosmos-db/choose-api", "_blank");
                        }}
                    >
                        {" "}
                        Learn More about the APIs
                    </StriimButton>
                </Grid>
            </Grid>
            <Grid p={4} container direction="row">
                <Grid
                    container
                    direction="column"
                    wrap="wrap"
                    alignContent="space-between"
                    className={classes.apiTilesWrapper}
                    xs={6}
                >
                    {targetApis?.map(api => (
                        <ApiTile
                            key={api.id}
                            api={api}
                            onApiSelect={updateSelection}
                            isSelected={api.id === selectedApi?.id}
                        />
                    ))}
                </Grid>
                <Grid item xs={6} px={3}>
                    <Box sx={sxStyles.infoBoxContainer}>
                        <StriimMessageBox
                            type="INFO"
                            text={
                                <Grid>
                                    <StriimTypography variant="body4" sx={sxStyles.infoMessage}>
                                        Please make sure the Partition (shard) keys and data models are configured
                                        before migration. Please use the Cosmos DB Capacity Calculator to estimate the
                                        Total Cost of Ownership before the migration.
                                    </StriimTypography>
                                    <Grid sx={sxStyles.buttonsContainer} container mt={3}>
                                        <StriimButton
                                            variant="text"
                                            onClick={() => {
                                                window.open(
                                                    "https://docs.microsoft.com/en-us/azure/cosmos-db/partitioning-overview#choose-partitionkey",
                                                    "_blank"
                                                );
                                            }}
                                        >
                                            Partition Keys
                                        </StriimButton>
                                        <StriimButton
                                            variant="text"
                                            onClick={() => {
                                                window.open("https://cosmos.azure.com/capacitycalculator/", "_blank");
                                            }}
                                        >
                                            Capacity Calculator
                                        </StriimButton>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </Box>
                </Grid>
            </Grid>
            <Footer backDisabled={true} nextDisabled={!selectedApi} onNext={() => store.goToNextStep()} store={store} />
        </Grid>
    );
};

SelectApi.propTypes = {
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    selected: PropTypes.object,
    targetApis: PropTypes.array.isRequired,
    onApiSelect: PropTypes.func.isRequired
};

export default SelectApi;
