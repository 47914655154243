import App from "app";
import NestedTypes from "backbone.nestedtypes";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import axis_config_template from "./axis.html";
import axis_names_template from "./axis-names.html";
import axis_types_template from "./axis-types.html";
import axis_type_datetime_formatters from "./axis-type-datetime-formatters.html";
import "./field";
import "./editor";
import "app/components/common/_deprecated/form/form";

App.module("Dashboard.VisEditor", function (VisEditor, App, Backbone, Marionette, $, _) {
    var AxisNameEditorModel = NestedTypes.Model.extend({
        defaults: {
            current: String,
            axes: null,
        },
    });
    var AxisTypeEditorModel = NestedTypes.Model.extend({
        defaults: {
            current: String,
            axes: null,
            dropoptions: Array,
        },
    });
    var AxisNameEditor = Marionette.LayoutView.extend({
        className: "axes-names-editor",
        template: _.template(axis_names_template),
        initialize: function () {
            this.listenTo(this.model, "change:current", this.render);
            this.listenTo(this.model.axes, "add remove", this.render); // Axes editor will add/remove axes
        },
        onRender: function () {
            var _this = this;

            this.$el.off("change.custom").on("change.custom", "input", function () {
                var axis = _this.model.axes.get(_this.model.current);
                axis.set("title", $(this).val());
            });
        },
    });

    var AxisTypeEditor = Marionette.LayoutView.extend({
        template: _.template(axis_types_template),
        initialize: function () {
            this.listenTo(this.model, "change:current", this.render);
            this.listenTo(this.model.axes, "add remove", this.render); // Axes editor will add/remove axes
        },
        onRender: function () {
            var axis = this.model.axes.get(this.model.current);
            if (this.model.current === "" || typeof axis === "undefined") {
                return;
            }

            this.$("select")
                .val(axis.type)
                .on("change", function () {
                    axis.set("type", $(this).val());
                });
            var _this = this;
            _(function () {
                _this.$("select").select2({
                    dropdownCssClass: "light",
                });
            }).defer();
        },
    });

    var AxisDateTimeFormatEditor = Marionette.LayoutView.extend({
        template: _.template(axis_type_datetime_formatters),
        initialize: function () {
            this.listenTo(this.model, "change:current", this.render);
            this.listenTo(this.model.axes, "add remove", this.render); // Axes editor will add/remove axes
        },
        onRender: function () {
            var axis = this.model.axes.get(this.model.current);
            if (this.model.current === "" || typeof axis === "undefined") {
                return;
            }
            var selectedFormat = axis.get("dateTimeFormat") ? axis.get("dateTimeFormat") : "";
            if (!selectedFormat || typeof selectedFormat === "function") {
                selectedFormat = "dateTime";
            }

            this.$("select")
                .val(selectedFormat)
                .on("change", function () {
                    axis.set("dateTimeFormat", $(this).val());
                });
            var _this = this;
            _(function () {
                _this.$("select").select2({
                    dropdownCssClass: "light",
                });
            }).defer();
        },
    });

    VisEditor[dataVisualizations.EDITOR.AXIS] = VisEditor.Editor.LayoutView.extend({
        className: "axis-editor",
        regions: {
            field: ".axis-field-container",
            names: ".axis-names",
            types: ".axis-types",
            datetimeFormat: ".datetime-format",
        },
        templateHTML: axis_config_template,
        initialize: function () {
            var className = this.model.editor.options.axis.toLowerCase() + "Axis-config";
            this.$el.addClass(className);
            VisEditor.Editor.LayoutView.prototype.initialize.apply(this, arguments);
        },
        /**
         *
         * @param axis_type
         * @private
         */
        _showDateTimeFormatter: function (axis_type) {
            if (axis_type.model.axes.length === 0) {
                return;
            }
            var type = axis_type.model.axes.at(0).get("type");
            if (type === "datetime") {
                this.getRegion("datetimeFormat")?.currentView?.$el.show();
            } else {
                this.getRegion("datetimeFormat")?.currentView?.$el.hide();
            }
        },
        onRender: function () {
            var axis_attribute = this.model.editor.attribute;

            // The field editor model is a partial clone of ours
            var fieldmodel = this.model.deepClone();
            // Options is a plain object, therefore it does not get deepcloned.
            fieldmodel.editor.options = $.extend(true, {}, fieldmodel.editor.options);

            fieldmodel.set({
                config: this.model.config,
                _queryvisualization: this.model._queryvisualization,
                _datavisualization: this.model._datavisualization,
                _theme: this.model._theme,
            });
            fieldmodel.editor.options.label = null;

            var FieldEditor = VisEditor[dataVisualizations.EDITOR.FIELD];
            var axis_field = new FieldEditor({
                model: fieldmodel,
            });
            this.getRegion("field").show(axis_field);

            var axis_names = new AxisNameEditor({
                model: new AxisNameEditorModel({
                    axes: this.model._parent._parent.config[axis_attribute],
                    current: this.model.config[axis_attribute],
                }),
            });

            var nameEditorVisible = ShouldShowAxisNameEditor(this.model);
            if (nameEditorVisible) {
                this.getRegion("names").show(axis_names);
            }

            var axis_type = new AxisTypeEditor({
                className: "axis-type-editor",
                model: new AxisTypeEditorModel({
                    axes: this.model._parent._parent.config[axis_attribute],
                    current: this.model.config[axis_attribute],
                    dropoptions: [
                        {
                            id: "linear",
                            name: "Linear",
                        },
                        {
                            id: "category",
                            name: "Category",
                        },
                        {
                            id: "logarithmic",
                            name: "Logarithmic",
                        },
                        {
                            id: "datetime",
                            name: "Date/Time",
                        },
                    ],
                }),
            });

            var axis_datetime_formatter = new AxisDateTimeFormatEditor({
                model: new AxisTypeEditorModel({
                    axes: this.model._parent._parent.config[axis_attribute],
                    current: this.model.config[axis_attribute],
                    dropoptions: [
                        {
                            id: "time",
                            name: "Time",
                        },
                        {
                            id: "date",
                            name: "Date",
                        },
                        {
                            id: "dateTime",
                            name: "DateTime",
                        },
                    ],
                }),
                initialize: function () {
                    this.render();
                },
            });
            var that = this;
            axis_type.model.axes.on("change", function () {
                that._showDateTimeFormatter(axis_type);
            });

            that.getRegion("datetimeFormat").show(axis_datetime_formatter);
            that._showDateTimeFormatter(axis_type);

            this.getRegion("types").show(axis_type);

            if (nameEditorVisible) {
                this.getRegion("types").$el.addClass("with-names");
                this.getRegion("datetimeFormat").$el.addClass("with-names");
            }

            this.listenTo(axis_type.model.axes, "add remove", function () {
                that._showDateTimeFormatter(axis_type);
            });

            this.listenTo(this.model.config, "change:" + axis_attribute, function () {
                // Update the currently-selected axis in the axis name config
                axis_names.model.set({
                    current: this.model.config[axis_attribute],
                });
                axis_type.model.set({
                    current: this.model.config[axis_attribute],
                });
                axis_datetime_formatter.model.set({
                    current: this.model.config[axis_attribute],
                });
            });

            function ShouldShowAxisNameEditor(_model) {
                var chartsWithoutAxisEditor = ["donutchart", "piechart"];
                var chartType = _model._parent._parent._parent["visualizationType"].toLowerCase();

                return chartsWithoutAxisEditor.indexOf(chartType) === -1;
            }
        },
    });
});
