import React, { useMemo, useState } from "react";
import { LinearProgress as MuiLinearProgress, styled, Box, Grid } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import sxStyles from "./stacked-linear-progress.styles";
import useProgressStore from "../../../../stores/table-progress/useProgressStore";
import { Theme } from "@mui/material";
import { GreenCheck } from "../../../../generic/icon/customIcons";
import statusManagement from "../../../../status-management";

interface StackedLinearProgressProps {
    error?: boolean;
    success: number;
    total: number;
    text?: React.ReactElement;
    stopped?: boolean;
    timeElapsed?: string;
    progressText?: string;
}
type tablesInfoData = {
    totalTablesCount: number;
    tablesWrittenCount: number;
};
type IlTotalProgressProps = {
    progressText?: string;
    appStatus: string;
};

const LinearProgress = styled(MuiLinearProgress)(
    ({ theme, error, stopped }: { error: boolean; stopped: boolean; theme: Theme }) => ({
        height: theme.spacing(4.5),
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.greyscale[100],
        overflow: "hidden",
        "& .MuiLinearProgress-bar": {
            backgroundColor: error
                ? theme.palette.critical[500]
                : stopped
                ? theme.palette.greyscale[300]
                : theme.palette.success[200]
        }
    })
);

const IlTotalProgress: React.FunctionComponent<IlTotalProgressProps> = ({ appStatus, progressText }) => {
    const store = useProgressStore();

    const tablesInfo: tablesInfoData = useMemo(() => {
        let data = store?.ilInfo?.tablesWrittenInfo;
        if (data && data?.totalTablesCount > 0) {
            return data;
        }
        return null;
    }, [store?.ilInfo]);

    return tablesInfo ? (
        <StackedLinearProgress
            progressText={progressText}
            total={tablesInfo?.totalTablesCount}
            success={tablesInfo?.tablesWrittenCount}
            error={statusManagement.isApplicationCrash(appStatus)}
            stopped={statusManagement.isStoppedStatus(appStatus)}
            timeElapsed={store?.elapsedTime}
        />
    ) : null;
};
const StackedLinearProgress: React.FunctionComponent<StackedLinearProgressProps> = ({
    error,
    total,
    success,
    stopped,
    text,
    timeElapsed,
    progressText
}) => {
    var successPercent = success === 0 ? 0 : (success * 100) / total;
    return (
        <>
            <Box sx={sxStyles.container}>
                {progressText && (
                    <Grid container sx={sxStyles.textContainer}>
                        <StriimTypography sx={sxStyles.inlineText} variant="caption2">
                            {progressText}
                        </StriimTypography>
                    </Grid>
                )}
                {timeElapsed ? (
                    <Grid container sx={{ ...sxStyles.textContainer, ...sxStyles.alignRight }}>
                        <StriimTypography sx={sxStyles.inlineText} variant="caption3">
                            Elapsed time:<Box sx={sxStyles.timeElapsed}> {timeElapsed}</Box>
                        </StriimTypography>
                    </Grid>
                ) : (
                    <></>
                )}

                <LinearProgress stopped={stopped} error={error} variant="determinate" value={successPercent} />
                <Grid
                    container
                    sx={{ ...sxStyles.textContainer, ...sxStyles.alignRight, ...sxStyles.relativeContainer }}
                >
                    <Grid container>
                        <StriimTypography sx={sxStyles.inlineText} variant="caption3">
                            {text ? (
                                text
                            ) : success !== 0 && success === total ? (
                                <Box>
                                    <GreenCheck style={{ marginBottom: "-2px" }} />
                                    <Box component="span" ml={2}>
                                        {success}/{total} tables synced successfully
                                    </Box>
                                </Box>
                            ) : (
                                <>
                                    Tables Synced:
                                    <Box sx={sxStyles.timeElapsed}>
                                        {success} of {total}
                                    </Box>
                                </>
                            )}
                        </StriimTypography>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default IlTotalProgress;
export { StackedLinearProgress };
