import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import UIControl from "../../../editor/control/ui-control";
import fieldDataSource from "app/components/common/editor/control/select/field-datasource";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import { ITransformationConfiguration } from "../cq";

const Transformation: ITransformationConfiguration = {
    OnlyDbSources: false,
    allowChangeSource: true
};

const supportedFieldTypes = [
    "java.lang.string",
    "java.lang.double",
    "java.lang.float",
    "java.lang.integer",
    "java.lang.long",
    "java.lang.short",
    "java.lang.boolean",
    "java.lang.byte",
    "java.lang.byte[]",
    "org.joda.time.datetime"
];

Transformation.createColumnControl = function() {
    const self = this;
    return UIControl.ColumnsPicker.extend({
        hideLabel: true,
        hidden: false,
        transformationConfig: {
            preFillColumns: false,
            addAvailable: true,
            autoFillAlias: true,
            hideRemoveIcon: false,
            allowClearColumns: true,
            columnsSource: function(_: any, fieldName: string) {
                return new Promise(resolve => {
                    fieldDataSource(fieldName).then(fields => {
                        self.allFields = fields;
                        // not all field types are supported, allow to select only supported types
                        const filteredTypes = fields.filter(
                            x => supportedFieldTypes.indexOf(x.description?.toLowerCase()) !== -1
                        );
                        resolve(filteredTypes);
                    });
                });
            },
            labels: {
                column1: "Field",
                column2: "Alias"
            }
        }
    }).create();
};

const getShieldTqlBuilder = (stream: string, columns: any[], options: any, transformation: any) => ({
    build() {
        const shieldProfile = options.StaticControls.shieldProfile.getValue();
        if (!shieldProfile) {
            return "";
        }

        const shieldProfileId = metaObjectConverter.parse(shieldProfile);

        let tql = "SELECT ";

        const mode = options.StaticControls.mode.getValue();
        const refreshThreshold = options.StaticControls.refreshThreshold.getValue() || "0";
        const refreshType = options.StaticControls.refreshType.getValue();

        const columnTemplateDecrypt =
            'shieldDecrypt("' + shieldProfileId.nsName + '","' + shieldProfileId.name + '",' + "{0}" + ") AS {1}";

        const columnTemplateEncrypt =
            'shieldEncrypt("' +
            shieldProfileId.nsName +
            '","' +
            shieldProfileId.name +
            '",' +
            "{0}" +
            ",'" +
            refreshType +
            "'," +
            refreshThreshold +
            ") AS {1}";

        const columnTemplate = mode.toUpperCase() == "DECRYPT" ? columnTemplateDecrypt : columnTemplateEncrypt;

        const transfromedTemplateCols = {};
        columns.forEach(function(column) {
            if (!column.column) {
                return;
            }

            transfromedTemplateCols[column.column] = columnTemplate
                .replace("{0}", column.column)
                .replace("{1}", column.alias || column.column);
        });

        const updatedCols = []; // holds the same order , replacing them in the array with transformations
        if (transformation.allFields) {
            const colNames = columns.map(x => x.column);
            transformation.allFields.forEach(field => {
                if (colNames.indexOf(field.id) === -1) {
                    updatedCols.push(field.id);
                } else {
                    updatedCols.push(transfromedTemplateCols[field.id]);
                }
            });
        }

        tql += updatedCols.join(", ");
        tql += " FROM " + stream;

        return tql;
    }
});
Transformation.createRqlBuilder = getShieldTqlBuilder;

function getControlRow(control) {
    return control.$el.closest(".field-row");
}
Transformation.convertToCQ = function() {
    getControlRow(this.options.StaticControls.shieldProfile).hide();
    getControlRow(this.options.StaticControls.mode).hide();
    getControlRow(this.options.StaticControls.refreshThreshold).hide();
    getControlRow(this.options.StaticControls.refreshType).hide();
};

Transformation.buildTql = function() {};

Transformation.postProcessing = function(cqControl: any, options: any, metadata_object: any) {
    // load filtered list of fields
    const inputValue = cqControl.input.getValue();
    if (inputValue) {
        metaStoreService.findById(inputValue).then(function(componentModel) {
            if (!componentModel) {
                return;
            }
            var fieldName =
                componentModel.type === metaStoreService.entities.CACHE
                    ? componentModel.typename
                    : componentModel.dataType;
            fieldDataSource(fieldName).then(result => {
                cqControl.columns.gridView.setColumnsDataSource();
            });
        });
    }

    // add additional fields
    options.displayFields.splice(0, 0, "shieldProfile");

    const index = options.displayFields.indexOf("convertToCQ");
    options.displayFields.splice(index, 0, "mode");
    options.displayFields.splice(index + 1, 0, "refreshType");
    options.displayFields.splice(index + 2, 0, "refreshThreshold");

    // create controls
    options.StaticControls.shieldProfile = UIControl.ConnectionProfileSelectField({
        name: "shieldProfile",
        label: "Shield Profile",
        required: true,
        enabled: true,
        supportedEndpoints: ["GoogleKMS"],
        onChange: (selectedConnectionProfile: string) => {
            metadata_object.uiconfig.config.shieldProfile = selectedConnectionProfile;
            cqControl.refreshTql();
        }
    }).create();

    const modeDataSource = [
        { id: "ENCRYPT", text: "encrypt" },
        { id: "DECRYPT", text: "decrypt" }
    ];
    options.StaticControls.mode = UIControl.Select(modeDataSource, { hideSearch: true, allowClear: false }).create();

    const defaultRefreshThreshold = "1";

    options.StaticControls.refreshThreshold = UIControl.PositiveNumberField.extend({
        defaultValue: defaultRefreshThreshold
    }).create();
    options.StaticControls.refreshThreshold.setValue(defaultRefreshThreshold);
    const refreshTypeSource = [
        { id: "time", text: "Time (Days)" },
        { id: "count", text: "Event (Count)" }
    ];
    options.StaticControls.refreshType = UIControl.Select(refreshTypeSource, {
        hideSearch: true,
        allowClear: false
    }).create();
    const defaultRefreshType = "time";
    options.StaticControls.refreshType.setValue(defaultRefreshType);
    // initialize control values
    options.StaticControls.mode.setValue("encrypt");
    if (metadata_object?.uiconfig?.config) {
        if (metadata_object?.uiconfig?.config.shieldProfile) {
            options.StaticControls.shieldProfile.setValue(metadata_object?.uiconfig?.config.shieldProfile);
        }
        if (metadata_object?.uiconfig?.config?.refreshType) {
            options.StaticControls.refreshType.setValue(metadata_object?.uiconfig?.config?.refreshType);
        }
        if (metadata_object?.uiconfig?.config.mode) {
            options.StaticControls.mode.setValue(metadata_object?.uiconfig?.config.mode);
        }
        if (metadata_object?.uiconfig?.config.refreshThreshold) {
            options.StaticControls.refreshThreshold.setValue(
                metadata_object?.uiconfig?.config.refreshThreshold < 0
                    ? 0
                    : metadata_object?.uiconfig?.config.refreshThreshold
            );
        }
    }

    options.StaticControls.mode.on(UIControl.EVENT.VALUE_CHANGED, value => {
        metadata_object.uiconfig.config.mode = value;
        cqControl.refreshTql();
        if (value === "DECRYPT") {
            getControlRow(options.StaticControls.refreshType).hide();
            getControlRow(options.StaticControls.refreshThreshold).hide();
        } else {
            getControlRow(options.StaticControls.refreshType).show();
            getControlRow(options.StaticControls.refreshThreshold).show();
        }
    });
    options.StaticControls.refreshType.on(UIControl.EVENT.VALUE_CHANGED, value => {
        metadata_object.uiconfig.config.refreshType = value;
        cqControl.refreshTql();
    });
    options.StaticControls.refreshThreshold.on(UIControl.EVENT.VALUE_CHANGED, value => {
        if (value < 0) {
            value = 0;
            options.StaticControls.refreshThreshold.setValue(0);
        }

        metadata_object.uiconfig.config.refreshThreshold = value;
        cqControl.refreshTql();
    });
    function toggleRefreshThresholdAndType() {
        const mode = metadata_object?.uiconfig?.config.mode;
        if (mode === "DECRYPT") {
            getControlRow(options.StaticControls.refreshType).hide();
            getControlRow(options.StaticControls.refreshThreshold).hide();
        } else {
            getControlRow(options.StaticControls.refreshType).show();
            getControlRow(options.StaticControls.refreshThreshold).show();
        }
    }
    setTimeout(function() {
        cqControl.refreshTql();
        toggleRefreshThresholdAndType();
    }, 500);
};
export default Transformation;
