import {useState, useEffect, useCallback} from "react";
import api from "core/api/api";
import securityService from "core/services/securityService/securityService";
import {SegmentationFeaturesStates} from "./components";
import { addonFeaturesKeys } from "./components/add-on-features/add-on-features-utils";
import growl from "app/components/common/growl";
import config from "../../../../../../config";

const initialState = {
    addonList: [],
    defaultList: []
};

export const useUserPlanState = () => {
    const [userPlanContext, setUserPlanContext] = useState(initialState);

    const updateUserPlanContext = useCallback(newContext => {
        setUserPlanContext({ ...userPlanContext, ...newContext });
    }, []);

    const getFeatures = useCallback(async () => {
        return await api.getFeatureStates();
    }, []);

    const updateFeature = useCallback(async (key, type) => {
        if (type === "ENABLEFEATURE") {
            return await api.enablePlanFeature(key);
        }
        if (type === "DISABLEFEATURE") {
            return await api.disablePlanFeature(key);
        }
    }, []);


    const updateContext = useCallback(() => {
        securityService.updateSegmentationFeatureStates();

        getFeatures()
            .then(item => {
                api.getFeatureRates().then(rates => {
                    const features = Object.entries(item)
                        .map(values => {
                            if (values[1] !== SegmentationFeaturesStates.NOTAPPLICABLE) {
                                return {addonKey: values[0], status: values[1]};
                            }
                        })
                        .filter(notUndefined => !!notUndefined);

                    const showAllDefaultFeatures = (features || []).some(
                        item => item.addonKey === "*" && item.status === SegmentationFeaturesStates.DEFAULT
                    );

                    const mapAddonsList = addon => {
                        let rate = rates.filter(r => r.featureName.toLowerCase() === addon.addonKey.toLowerCase());

                        //Backend returns different keys for rates and features so we need this ugly hack here
                        if (rate.length === 0 && addon.addonKey === addonFeaturesKeys.DASHBOARDS) {
                            rate = rates.filter(r => r.featureName.toLowerCase() === "dashboards");
                        }

                        if (rate.length === 0) {
                            console.log(`Rate not found for ${addon.addonKey}`);
                            return {
                                item: addon,
                                rate: {
                                    rate: "",
                                    unit: ""
                                }
                            };
                        }
                        if (rate.length > 1) {
                            console.log(`There is more than one rate for ${addon.addonKey}`, rate);
                        }
                        return {
                            item: addon,
                            rate: rate[0]
                        };
                    };

                    const nonDefaultFeatures = features.filter(
                        item => item.status !== SegmentationFeaturesStates.DEFAULT
                    );

                    updateUserPlanContext({
                        addonList: nonDefaultFeatures.map(mapAddonsList),
                        defaultList: showAllDefaultFeatures
                            ? Object.values(addonFeaturesKeys)
                                .filter(key => !nonDefaultFeatures.some(item => item.addonKey === key))
                                .map(key => ({
                                    addonKey: key,
                                    status: SegmentationFeaturesStates.DEFAULT
                                }))
                            : features.filter(item => item.status === SegmentationFeaturesStates.DEFAULT)
                    });
                });
            })
            .catch(e => growl.error(e.message, "Cannot fetch user plan details"));
    }, [getFeatures, updateUserPlanContext]);

    const enableFeature = useCallback(key => {
        updateFeature(key, "ENABLEFEATURE")
            .then(() => {
                updateContext();
            })
            .catch(e => growl.error(e.message, "Cannot enable feature"));
    }, [updateContext]);

    const disableFeature = useCallback(key => {
        updateFeature(key, "DISABLEFEATURE")
            .then(() => {
                updateContext();
            })
            .catch(e => growl.error(e.message, "Cannot disable feature"));
    }, [updateContext]);

    useEffect(() => {
        updateContext();

        const interval = setInterval(
            () => updateContext(),
            config.time.silentBillingPageDataUpdateInterval
        );
        return () => {
            if (interval)
                clearInterval(interval);
        }
    }, []);

    return {
        updateUserPlanContext,
        userPlanContext,
        handlers: {
            enableFeature,
            disableFeature
        }
    };
};
