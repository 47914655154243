import _ from "underscore";
import BaseControl from "./../base-control";
import React from "react";
import ReactDOM from "react-dom/client";
import FileBrowser from "../../../../../../src/generic/filebrowser/filebrowser";

var Model = BaseControl.Model.extend({
    defaults: {
        value: null
    }
});

var View = BaseControl.View.extend({
    template: _.template(`<div>   </div>`),

    getViewValue: function() {
        return this.model.get("value"); // No op, we're doing it in react.
    },

    setViewValue: function() {
        // No op, we're doing it in react.
    },

    valueChanged: function(value) {
        this.setValue(value);
        this.forceChanged();
    },

    onShow: function() {
        try {
            const root = ReactDOM.createRoot(this.$el[0]);
            root.render(
                <FileBrowser
                    enabled={this.model.get("enabled")}
                    value={this.model.get("value")}
                    directoryOnly={false}
                    onChange={value => {
                        this.valueChanged(value);
                    }}
                />
            );
        } catch (e) {
            console.error("Error rendering Filebrowser", e);
        }
    }
});

function buildTextField() {
    return _.extend({}, BaseControl, {
        View: View,
        Model: Model
    });
}

export default buildTextField();
