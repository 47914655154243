import React, { useState } from "react";
import { useReactHeader } from "src/hooks/useReactHeader";
import { Container, Grid } from "@material-ui/core";

import useStyles from "./homepage.styles";
import useStores from "../../../../utils/use-stores";
import useEffect from "../../../../hooks/useInitializedEffect";

import LoadingIndicator from "../../../../generic/loading-indicator";
import NewUserScreen from "./new_user";
import ExpUserScreen from "./exp_user";
import securityService from "core/services/securityService/securityService";
import homepageService from "src/modules/homepage/homepage_service";
import { observer } from "mobx-react-lite";
import { useTheme } from "@mui/material/styles";

const HomePage = observer(() => {
    const v5theme = useTheme();
    const [username, setUsername] = useState("");
    const [isloading, setIsLoading] = useState(true);
    const classes = useStyles(v5theme);
    const { store, groupsStore } = useStores(); // store === apps list
    const hasApps = () => {
        return store.apps.length > 0;
    };
    useEffect(() => {
        store.resetFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try{
                await store.fetchApps();
                await groupsStore.fetch();
                await groupsStore.handleNonAssignedApps(store.apps);
                store.listenToAppStatusChange();
                const user = await securityService.getUser();
                setUsername(user["displayname"]);
                setIsLoading(false);
            }
            catch(e){
                console.error(e);
            }
        };
        fetchData();
    }, [store, groupsStore]);

    useReactHeader({ title: "Home" });

    if(isloading){
        return <LoadingIndicator />;
    }
    return (
        <Grid
            container={true}
            className={classes.homepageContainer}
        >
            <Grid item xs={12} hidden={isloading}>
                <Container xs={12} className={classes.container} maxWidth={false}>
                    {username !== "" ? (
                        hasApps() ? (
                            // Returning user screen
                            <ExpUserScreen
                                username={username}
                                apps={[...store.apps]}
                                video={homepageService.getVideoData()}
                                visibleApps={store.visibleApps}
                            />
                        ) : (
                            // New user screen
                            <NewUserScreen username={username} video={homepageService.getVideoData()} />
                        )
                    ) : (
                        <LoadingIndicator />
                    )}
                </Container>
            </Grid>
        </Grid>
    );
});


export default HomePage;
