import Backbone from "backbone";
import App from "app";
import $ from "jquery";
import api from "core/api/api";
import "perfectscrollbar";
import ReactDOM from "react-dom/client";
import React from "react";
import MessageLog from "../../../src/modules/message-log/message-log";
import { StriimTheme } from "@striim/striim-ui";
import localStore, { SOURCE_LOCAL, SOURCE_MESSAGE_LOG } from "../../../src/modules/message-log/message-log.store";
import growl from "../common/growl";
import utils from "../../../core/utils";
let errorConsole = {};

const LAST_LOGIN_TIME = localStorage.getItem("STRIIM_ONLINE_TIME");
export const HAS_ALREADY_WELCOMED = "HAS_ALREADY_WELCOMED";
let QUERY_GET_UNREAD_MESSAGES = null;
const NOTIFICATION_QUEUE_NAME = "USER_NOTIFICATION_QUEUE";
if (LAST_LOGIN_TIME) {
    QUERY_GET_UNREAD_MESSAGES = `SELECT  count(*) as cnt, first(s.componentName) as componentName, first(s.componentType)  as componentType  FROM System$Notification.notificationStore S WHERE time > ${LAST_LOGIN_TIME}L GROUP BY s.componentName ; `;
}

/**
 * main function to add new notifications.
 * @param model
 * @return null
 */
errorConsole.notify = function(model) {
    errorConsole.source = SOURCE_LOCAL;
    localStore.addLocalEvent(model);
};

const MessageLogContainer = Backbone.Marionette.ItemView.extend({
    template: "<div></div>",
    onRender: function() {
        try {
            const root = ReactDOM.createRoot(this.$el[0]);
            root.render(
                <StriimTheme preselector=".striim.materialize.light:not(#\20)">
                    <MessageLog />
                </StriimTheme>
            );
        } catch (e) {
            console.error("Error rendering Error Console", e);
        }
    }
});

errorConsole.initialize = function() {
    App.on("app:authenticated", function() {
        const errorConsoleRoot = document.getElementById("wa-errorconsole");
        const root = ReactDOM.createRoot(errorConsoleRoot);
        root.render(
            <StriimTheme preselector=".striim.materialize.light:not(#\20)">
                <MessageLog />
            </StriimTheme>
        );

        api.subscribe(NOTIFICATION_QUEUE_NAME)
            .then(data => {
                api.getDispatcher().on(NOTIFICATION_QUEUE_NAME + ":message", event => {
                    event.shouldGrowl = true;
                    event.source = SOURCE_MESSAGE_LOG;
                    event.hasGrowled = false;
                    event.severity = event.messageType;
                    localStore.addVolatileGrowl(event);
                });
            })
            .fail(e => {
                console.error(e);
            });

        setTimeout(() => {
            if (!QUERY_GET_UNREAD_MESSAGES) return;
            api.getHistoricalMessageData(QUERY_GET_UNREAD_MESSAGES).done(data => {
                let totalCount = 0;
                if (data) {
                    for (let i = 0; i < data.length; i++) {
                        totalCount += data[i].cnt;
                    }
                }
                if (totalCount && !sessionStorage.getItem(HAS_ALREADY_WELCOMED)) {
                    sessionStorage.setItem(HAS_ALREADY_WELCOMED, true);
                    growl.info(
                        `You have ${totalCount} ${
                            totalCount == 1 ? "message" : "messages"
                        } since you last logged in. They can be found in the message log at the bottom of the screen. `,
                        `Welcome back ${utils.capitalize(App.user.displayname)}!`
                    );
                }
            });
        }, 5000); // wait until the rest of the important calls are done.
    });
};

export default errorConsole;
