import React from "react";
import { StriimChip, StriimTypography } from "@striim/striim-ui";
import { wizardDetails } from "./helper";
import { sxStyles } from "./wizard-tag-chip.styles";
import { Box } from "@mui/material";
import withTheme from "../../../../generic/theme/theme";

interface ValidateTargetProps {
    wizardType: string;
}

const WizardTagChip = ({ wizardType }: ValidateTargetProps) => {
    const wizard = wizardDetails[wizardType];

    if (!wizard) {
        return;
    }

    return (
        <Box mt={-0.25}>
            <StriimChip
                sx={sxStyles[wizard.className]}
                variant={wizard.variant}
                label={
                    <StriimTypography variant="caption2" sx={sxStyles.typography} color={wizard.typographyColor}>
                        {wizard.name}
                    </StriimTypography>
                }
            />
        </Box>
    );
};

export default withTheme(WizardTagChip);
