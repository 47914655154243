import DataModifier from "app/components/common/editor/control/wae-data-modifier/wae-data-modifier";
import types from "app/components/common/editor/control/select/types-datasource.json";

var Transformation = {};

Transformation.OnlyDbSources = true;

Transformation.createColumnControl = function () {
    return DataModifier.extend({
        hideLabel: true,
        hidden: false,
    }).create();
};

Transformation.buildTql = function (tqlBuilder, columns) {
    columns.forEach(function (column) {
        if (!column.index || !column.column) {
            return;
        }

        let index = column.index.toString().replace("column_", "");

        if (!column.metadataName || !column.metadataValue) {
            return;
        }

        if (column.tqlFunction) {
            const f = types.find((t) => t.id === column.tqlFunction);
            if (f) {
                column.transformationFunction = f.convertFunction;
            }
        }

        let replaceDataExpression = BuildReplaceDataExpression(
            tqlBuilder.streamAlias,
            index,
            tqlBuilder.getColumnStatement({
                transformationFunction: column.transformationFunction,
                name: column.column,
            })
        );

        let condition = GetMetaCondition(tqlBuilder, column.metadataName, column.metadataValue);
        let elseStatement = tqlBuilder.streamAlias;

        tqlBuilder.addCaseStatement(condition, replaceDataExpression, elseStatement);
    });
};

function GetMetaCondition(tqlBuilder, metadataName, metadataValue) {
    return "TO_STRING(" + tqlBuilder.getMetaDataValueStatement(metadataName) + ") = '" + metadataValue + "'";
}

function BuildReplaceDataExpression(streamAlias, index, expression) {
    return "replaceData(" + streamAlias + ", " + index + ", " + expression + ")";
}

export default Transformation;
