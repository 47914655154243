import App from "app";
import Backbone from "backbone";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import template from "./page.html";
import "./editor";

App.module("Dashboard.VisEditor", function (VisEditor, App, Backbone, Marionette, $, _) {
    VisEditor[dataVisualizations.EDITOR.PAGE] = VisEditor.Editor.LayoutView.extend({
        className: "field-editor",
        template: _.template(template),
        onBeforeDestroy: function () {
            this.$("select").select2("destroy");
        },
        onRender: function () {
            var _this = this;
            var key = this.model.editor.attribute;

            this.$("select")
                .val(this.model.config[key])
                .on("change", function () {
                    _this.model.config.set(key, $(this).val());
                });
            _(function () {
                _this.$("select").select2({
                    dropdownCssClass: "light",
                    allowClear: true,
                });
            }).defer();
        },
        serializeData: function () {
            var data = {};
            data.pages = [];
            this.collection.each(function (object) {
                data.pages.push({
                    id: object.name,
                    name: object.title || object.name,
                });
            });
            data.label = this.model.editor.options.label || "Page";
            return data;
        },
        initialize: function () {
            this.collection = App.reqres.request("dashboard:current:get_pages");
            if (this.collection == undefined) {
                this.collection = new Backbone.Collection();
            }
            var future_promises = [];
            this.collection.each(function (page) {
                future_promises.push(page.fetch());
            });
            $.when.apply($, future_promises).then(this.render);
            this.addDisplayClasses();
        },
    });
});
