var LocalErrorsStorage = {};

function _getKey(app) {
    return "exc_" + app.uuid;
}

LocalErrorsStorage.save = function (app, errors) {
    localStorage.setItem(_getKey(app), JSON.stringify(errors));
};

LocalErrorsStorage.load = function (app) {
    var errors = localStorage.getItem(_getKey(app));

    if (errors) {
        errors = JSON.parse(errors);
    }
    if (!errors) {
        errors = [];
    }

    return errors;
};

LocalErrorsStorage.clear = function (app) {
    localStorage.removeItem(_getKey(app));
};

LocalErrorsStorage.isEmpty = function (appName) {
    var result = localStorage.getItem(_getKey(appName));
    if (result) {
        return false;
    }

    return true;
};

export default LocalErrorsStorage;
