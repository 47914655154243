import api from "core/api/api";

let copilotService = {};
copilotService = {
    isCopilotEnabled: false
};

copilotService.init = function() {
    let deferred = $.Deferred();

    (async () => {
        let isCopilotEnabled;
        try {
            isCopilotEnabled = await api.shouldEnableStriimCopilot();
            copilotService.isCopilotEnabled = isCopilotEnabled;
        } catch (e) {
            console.error(e);
        }
        deferred.resolve();
    })();

    return deferred.promise();
};

export default copilotService;
