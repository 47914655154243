import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";
import namespace from "core/services/metaStoreService/metaObjects/namespace";
import dashboard from "core/services/metaStoreService/metaObjects/dashboard";
import page from "core/services/metaStoreService/metaObjects/page";
import wactionstore from "core/services/metaStoreService/metaObjects/wactionstore";
import flow from "core/services/metaStoreService/metaObjects/flow";
import application from "core/services/metaStoreService/metaObjects/application";
import cache from "core/services/metaStoreService/metaObjects/cache";
import stream from "core/services/metaStoreService/metaObjects/stream";
import window from "core/services/metaStoreService/metaObjects/window";
import cq from "core/services/metaStoreService/metaObjects/cq";
import target from "core/services/metaStoreService/metaObjects/target";
import group from "core/services/metaStoreService/metaObjects/group";
import type from "core/services/metaStoreService/metaObjects/type";
import propertytemplate from "core/services/metaStoreService/metaObjects/propertytemplate";
import dg from "core/services/metaStoreService/metaObjects/dg";
import visualization from "core/services/metaStoreService/metaObjects/visualization";
import source from "core/services/metaStoreService/metaObjects/source";
import externalsource from "core/services/metaStoreService/metaObjects/externalsource";
import server from "core/services/metaStoreService/metaObjects/server";
import queryvisualization from "core/services/metaStoreService/metaObjects/queryvisualization";
import propertyset from "core/services/metaStoreService/metaObjects/propertyset";
import propertyvariable from "core/services/metaStoreService/metaObjects/propertyvariable";
import openProcessor from "core/services/metaStoreService/metaObjects/pc";
import sysalertRule from "core/services/metaStoreService/metaObjects/sysalertrule";
import externalcache from "core/services/metaStoreService/metaObjects/externalcache";
import router from "core/services/metaStoreService/metaObjects/router";
import vault from "core/services/metaStoreService/metaObjects/vault";
import shield from "core/services/metaStoreService/metaObjects/shield";
import user from "core/services/metaStoreService/metaObjects/user";
import embeddinggenerator from "core/services/metaStoreService/metaObjects/embeddinggenerator";
import connectionProfile from "core/services/metaStoreService/metaObjects/connectionProfile";
import aiagent from "core/services/metaStoreService/metaObjects/aiagent";
import sentinel from "core/services/metaStoreService/metaObjects/sentinel";

var module = {
    baseMetaObject: baseMetaObject,
    NAMESPACE: namespace,
    DASHBOARD: dashboard,
    PAGE: page,
    WACTIONSTORE: wactionstore,
    FLOW: flow,
    APPLICATION: application,
    STREAM: stream,
    WINDOW: window,
    CACHE: cache,
    TARGET: target,
    TYPE: type,
    CQ: cq,
    GROUP: group,
    PROPERTYTEMPLATE: propertytemplate,
    DG: dg,
    VISUALIZATION: visualization,
    SOURCE: source,
    EXTERNALSOURCE: externalsource,
    SERVER: server,
    QUERYVISUALIZATION: queryvisualization,
    PROPERTYSET: propertyset,
    PROPERTYVARIABLE: propertyvariable,
    OPENPROCESSOR: openProcessor,
    SYSALERTRULE: sysalertRule,
    EXTERNALCACHE: externalcache,
    ROUTER: router,
    SHIELDPROFILE: shield,
    VAULT: vault,
    USER: user,
    EMBEDDINGGENERATOR: embeddinggenerator,
    CONNECTIONPROFILE: connectionProfile,
    AIAGENT: aiagent,
    SENTINEL: sentinel
};

export default module;
