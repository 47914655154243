import React from "react";
import { StriimButton } from "@striim/striim-ui";
import { ArrowForward } from "../../../../../generic/icon/customIcons";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { optionsType } from "./create-app-page-app-type-dropdown";
import { adapterType } from "../create-app-page-templates";
import { recentlyUsedAdaptersService } from "../services";

interface propsTypes {
    templateInfo: optionsType;
    sourceAdapter: adapterType;
    targetAdapter: adapterType;
}

const CreateAppPageGetStartedButton: React.FunctionComponent<propsTypes> = ({
    templateInfo,
    sourceAdapter,
    targetAdapter
}) => {
    const navigate = useNavigate();

    function handleSubmit(): void {
        recentlyUsedAdaptersService.add({
            sourceAdapter: sourceAdapter.value,
            targetAdapter: targetAdapter.value
        });
        templateInfo?.templateUrl && navigate(templateInfo?.templateUrl);
    }
    return (
        <Grid item alignSelf="flex-start">
            <StriimButton
                variant="primary"
                disabled={!templateInfo?.templateUrl}
                data-test-id="create-app-get-started-button"
                onClick={handleSubmit}
                endIcon={<ArrowForward />}
            >
                Get Started
            </StriimButton>
        </Grid>
    );
};

export default CreateAppPageGetStartedButton;
