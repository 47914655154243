import React, { useMemo } from "react";
import { components } from "react-select";
import { uniq } from "underscore";
import { Grid } from "@mui/material";
import { StriimInputField } from "@striim/striim-ui-v2";

import { styles } from "./sentinel-table.styles";

const allValue = "all";

export const SentinelsFilter = ({ tableData, customName = "Sentinel", updateFilter }) => {
    const SentinelsOptions = useMemo(
        () => [
            { label: `All ${customName}s (${tableData.length})`, value: allValue },
            ...tableData.map(tab => ({ label: tab.name, value: tab.name }))
        ],
        [tableData]
    );

    const handleFilterBySentinel = option => {
        const value = option.value === allValue
            ? ""
            : option.label

        updateFilter("sentinels", value)
    };

    return (
        <StriimInputField
            id="data-test-id-sentinel-table-filter"
            name="sentinelTableFilter"
            select
            placeholder={"Filter by " + customName}
            sx={styles.filter}
            onChange={option => handleFilterBySentinel(option)}
            SelectProps={{
                options: SentinelsOptions,
                components: {
                    Option: componentProps => (
                        <components.Option {...componentProps} sx={styles.filterOptions}>
                            <Grid display="flex" alignItems="center" gap={1}>
                                <div>{componentProps.data.label}</div>
                            </Grid>
                        </components.Option>
                    ),
                    ClearIndicator: () => null
                }
            }}
        />
    );
};

const options = [
    { label: "All Actions", value: allValue },
    { label: "Encrypted", value: "encrypted" },
    { label: "Masked", value: "masked" },
    { label: "No Action", value: "noAction" }
];

export const ActionsFilter = ({ updateFilter }) => {
    const handleFilterByAction = option => {
        const value = option.value === allValue
            ? ""
            : option.value

        updateFilter("actions", value);
    };

    return (
        <StriimInputField
            id="data-test-id-actions-table-filter"
            name="actionsTableFilter"
            select
            placeholder="Filter by Action"
            sx={styles.filter}
            onChange={option => {
                handleFilterByAction(option);
            }}
            SelectProps={{
                options: options,
                components: {
                    Option: componentProps => (
                        <components.Option {...componentProps} sx={styles.filterOptions}>
                            <Grid display="flex" alignItems="center" gap={1}>
                                <div>{componentProps.data.label}</div>
                            </Grid>
                        </components.Option>
                    ),
                    ClearIndicator: () => null
                }
            }}
        />
    );
};

export const IdentifiersFilter = ({ tableData, updateFilter }) => {
    const IdentifiersOptions = useMemo(() => {
        let identifierOptions = [];

        tableData.forEach(tab => (identifierOptions = [...identifierOptions, tab.label]));

        const options = identifierOptions.map(id => ({ label: id, value: id?.toLowerCase() }));

        options.unshift({
            label: `All Identifiers (${tableData.length})`,
            value: allValue
        });

        return options;
    }, [tableData]);

    const handleFilterByIdentifier = option => {
        const value = option.value === allValue
            ? ""
            : option.label

        updateFilter("identifiers", value);
    };

    return (
        <StriimInputField
            id="data-test-id-identifiers-table-filter"
            name="identifiersTableFilter"
            select
            placeholder="Filter by Identifier"
            sx={styles.filter}
            onChange={option => {
                handleFilterByIdentifier(option);
            }}
            SelectProps={{
                options: IdentifiersOptions,
                components: {
                    Option: componentProps => (
                        <components.Option {...componentProps} sx={styles.filterOptions}>
                            <Grid display="flex" alignItems="center" gap={1}>
                                <div>{componentProps.data.label}</div>
                            </Grid>
                        </components.Option>
                    ),
                    ClearIndicator: () => null
                }
            }}
        />
    );
};

export const ImportanceFilter = ({ updateFilter }) => {
    const ImportanceOptions = [
        { label: "All Importance Levels", value: allValue },
        { label: "High", value: "high" },
        { label: "Medium", value: "medium" },
        { label: "Low", value: "low" }
    ];

    const handleFilterByIdentifier = option => {
        const value = option.value === allValue
            ? ""
            : option.label

        updateFilter("importance", value);
    };

    return (
        <StriimInputField
            id="data-test-id-importance-table-filter"
            name="importanceTableFilter"
            select
            placeholder="Filter by Importance"
            sx={styles.filter}
            onChange={option => {
                handleFilterByIdentifier(option);
            }}
            SelectProps={{
                options: ImportanceOptions,
                components: {
                    Option: componentProps => (
                        <components.Option {...componentProps} sx={styles.filterOptions}>
                            <Grid display="flex" alignItems="center" gap={1}>
                                <div>{componentProps.data.label}</div>
                            </Grid>
                        </components.Option>
                    ),
                    ClearIndicator: () => null
                }
            }}
        />
    );
};
