const classes = {
    infoBg: {
        backgroundColor: ({ palette }) => palette.greyscale[100],
        borderColor: ({ palette }) => palette.greyscale[100]
    },
    successBg: {
        backgroundColor: ({ palette }) => palette.success[50],
        borderColor: ({ palette }) => palette.success[50]
    },
    errorBg: {
        backgroundColor: ({ palette }) => palette.critical[50],
        borderColor: ({ palette }) => palette.critical[50]
    },
    testConnectionBtn: {
        width: "fit-content"
    },
    agentMsgBox: {
        "& .MuiCardHeader-content": {
            wordBreak: "break-word",
            "& > span": {
                color: ({ palette }) => palette.greyscale[900]
            }
        }
    },
    accordionMsgBox: {
        "& .MuiCardHeader-content": {
            wordBreak: "break-word",
            "& > span": {
                color: ({ palette }) => palette.greyscale[900],
                paddingBottom: ({ spacing }) => spacing(2)
            }
        }
    },
    iconBase: {
        right: 0,
        position: "absolute",
        width: 100
    },
    circularProgress: {
        color: ({ palette }) => palette.greyscale[300]
    },
    iconFrame: {
        width: ({ spacing }) => spacing(3),
        height: ({ spacing }) => spacing(3)
    },
    progressWrapper: {
        position: "relative",
        right: ({ spacing }) => spacing(0.5),
        "& svg": {
            width: ({ spacing }) => spacing(2.625),
            height: ({ spacing }) => spacing(2.625)
        }
    },
    header: {
        color: ({ palette }) => palette.greyscale[900],
        letterSpacing: "0.14px"
    },
    iconStyles: {
        "& svg": {
            fill: "transparent",
            width: ({ spacing }) => spacing(2.5),
            height: ({ spacing }) => spacing(2.5)
        }
    },
    missingDriver: {
        paddingTop: ({ spacing }) => spacing(1)
    },
    row: {
        display: "flex",
        marginBottom: 1 / 2,
        wordBreak: "break-word"
    },
    title: {
        whiteSpace: "nowrap",
        color: "greyscale.700",
        paddingRight: 3
    },
    loader: {
        margin: ({ spacing }) => `0 ${spacing(-3)}`
    }
};

export default classes;
