import _ from "underscore";
import base from "./base";

var All = function () {};

All.prototype = _.extend(new base(), {
    _addItems: function (xadded, data, fieldList) {
        // Create a new objects to pass to the data visualization
        // date comes back as two arrays [fields], [datapoints]
        // The visualizations require data as property:value
        for (var i = 0; i < xadded.length; i++) {
            var newObject = this._transformData(xadded[i].data, fieldList);
            data.push(newObject);
        }

        return data;
    },

    _evictItems: function (data) {
        // Trim the data to the maximum size
        if (data.length > this.maxValues) {
            var numToSlice = data.length - this.maxValues;
            data = data.slice(numToSlice);
        }

        return data;
    },
});

export default All;
