import App from "app";
import Backbone from "backbone";
import NestedTypes from "backbone.nestedtypes";
import _ from "underscore";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import dataVisualizationsEditors from "core/services/metadataService/dataVisualizationsEditors";
import "app/components/dashboard/editors/propertieseditor";

var EditPageDialog = {};

EditPageDialog.Model = NestedTypes.Model.extend({
    defaults: {
        title: "",
        background: "",
    },
});

EditPageDialog.Dialog = App.FormMixinDialog.View.extend({
    initialize: function (options) {
        options.bind_submit = true;
        options.submit_on_enter = true;
        options.autosave = false;
        return App.FormMixinDialog.View.prototype.initialize.call(this, options);
    },

    onRender: function () {
        this.$("form").on("submit", function (e) {
            e.preventDefault();
            return false;
        });
    },

    serialize: function () {
        return this.serialize_form(this.$("form"));
    },

    update: function () {
        var pageEditModel = this.model.get("editModel");

        var page = this.model.get("page");
        page.title = pageEditModel.title;
        page.settings.background = pageEditModel.background;
    },

    validate: function (data) {
        var errorList = [];
        if (!data.title) {
            errorList.push({
                field: "title",
                message: "Please enter page title.",
            });
        }
        return errorList;
    },
});

EditPageDialog.DialogBody = Backbone.Marionette.LayoutView.extend({
    tagName: "form",
    template: _.template('<div class="edit-page-dialog"></div>'),
    regions: {
        "form-container": ".edit-page-dialog",
    },
    onRender: function () {
        this.$("form").on("submit", function (e) {
            e.preventDefault();
            return false;
        });

        var ActionsView = new App.Dashboard.VisEditor[dataVisualizations.EDITOR.PROPERTIES]({
            model: new dataVisualizationsEditors.EditorViewModel({
                _theme: App.Metadata.Themes.WebActionDark,
                editor: new dataVisualizationsEditors.PropertyEditor({
                    propertyeditors: [
                        {
                            attribute: "title",
                            type: dataVisualizations.EDITOR.STRING,
                            options: {
                                label: "Title:",
                                label_style: "inline",
                            },
                        },
                        {
                            attribute: "background",
                            type: dataVisualizations.EDITOR.COLOR,
                            options: {
                                label: "Background:",
                                label_style: "inline",
                            },
                        },
                    ],
                }),
                config: this.model,
            }),
        });
        this.getRegion("form-container").show(ActionsView);
    },
});

export default EditPageDialog;
