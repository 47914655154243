export type Job = {
    jobName: string;
    description: string;
    generationTime: string;
    completedTime: string;
    appName: string;
    sourceName: string;
    status: JobStatus;
    piiData: string;
    metrics: string;
    otherDetails: string;
};

export type JobMetrics = {
    sensitiveEntities: number;
    totalEntities: number;
    sensitiveIdentifiers: string[];
    otherDetails: any;
};

export enum JobStatuses {
    COMPLETED = "COMPLETED",
    ERROR = "ERROR",
    CREATED = "CREATED",
    RUNNING = "RUNNING"
}

export type Version = {
    name: string;
    updatedTime: number;
};

type JobStatus = JobStatuses;

export type PIISettings = {
    aiEngine: string;
    sdiConfig: SdiConfig;
};

export type SdiConfig = {
    importance: "HIGH" | "MEDIUM" | "LOW";
    action: "string";
};

export type PIISettingsOptions = {
    piiDefaultActions: string[];
    piiActions: object;
    sensitiveDataIdentifiers: object;
    importanceLevels: object;
};

export type JobVersion = {
    name: string;
    nsName: string;
    description: string;
    startTime?: string;
    completedTime: string;
    status: string;
    applications: string[];
    type: string;
};
