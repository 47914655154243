import React from "react";
import PropTypes from "prop-types";
import useStyles from "./striim-units-tile.styles";
import { StriimUnitisTileDate } from "./striim-units-tile-date";
import clsx from "clsx";
import {StriimTypography} from "../../../user-plan/components/StriimTypographyCustom";
import {NoBillingData} from "../NoBillingData";

export const striimUnitsTileVariants = {
    currentMonth: "current-month",
    lastMonth: "last-month"
};

export const typeStriimUnitsTile = {
    data: PropTypes.shape({}),
    todayLabel: PropTypes.bool,
    type: PropTypes.oneOf([striimUnitsTileVariants.currentMonth, striimUnitsTileVariants.lastMonth])
};

const messages = {
    [striimUnitsTileVariants.currentMonth]: "Striim Unit usage this month",
    [striimUnitsTileVariants.lastMonth]: "Striim Unit usage last month"
};

const StriimUnitsTile = ({ data, type, todayLabel }) => {
    const classes = useStyles();
    const title = messages[type];

    if (!data) {
        return (
            <div className={clsx(classes.wrapper, classes.noData)}>
                <StriimTypography variant="h3">{title}</StriimTypography>
                <NoBillingData text="There is no data to show at this time"/>
            </div>
        );
    }

    const creditClassname = (data.credit !== 0 && data.credit > 0 ? "positive" : "negative") || "";

    return (
        <div className={classes.wrapper}>
            <StriimTypography variant="h3">{title}</StriimTypography>
            <StriimUnitisTileDate
                startTime={data.startTime}
                endTime={data.endTime}
                todayLabel={todayLabel}
                type={type}
            />
            <StriimTypography variant="h2" className={classes.unitsAmount}>
                {data.balance}
            </StriimTypography>
            <StriimTypography variant="caption3" className={classes.credits}>
                <span className={"usage"}>
                    Total usage&nbsp;
                    <StriimTypography
                        variant="caption2"
                        data-test-id={
                            todayLabel ? "account-and-billing__usageThisMonth" : "account-and-billing__usageLastMonth"
                        }
                    >
                        {data.total}
                    </StriimTypography>
                </span>
                <span>
                    Credits:&nbsp;
                    <StriimTypography variant="caption2" className={creditClassname}>
                        {data.credit}
                    </StriimTypography>
                </span>
            </StriimTypography>
        </div>
    );
};

StriimUnitsTile.propTypes = typeStriimUnitsTile;

export default StriimUnitsTile;
