import _ from "underscore";
import BaseControl from "./../base-control";
import ReactDOM from "react-dom/client";
import React from "react";
import MapForAdapterProperties from "../../../../../../src/generic/map-for-adapter-properties/map-for-adapter-properties";

let Model = BaseControl.Model.extend({
    defaults: {
        value: null
    }
});

let View = BaseControl.View.extend({
    template: _.template("<div></div>"),

    getViewValue: function() {
        return this.model.get("value"); // No op, we're doing it in react.
    },

    setViewValue: function() {
        // No op, we're doing it in react.
    },

    valueChanged: function(value) {
        this.setValue(value);
        this.forceChanged();
    },

    onShow: function() {
        this.listenTo(this.model, "change:enabled", () => {
            this.renderForm();
        });
        this.renderForm();
    },

    renderForm() {
        try {
            const root = ReactDOM.createRoot(this.$el[0]);
            root.render(
                <MapForAdapterProperties
                    enabled={this.model.get("enabled")}
                    value={this.model.get("value")}
                    UIConfig={this.options.UIConfig}
                    onChange={value => {
                        this.valueChanged(value);
                    }}
                />
            );
        } catch (e) {
            console.error("Error rendering Map", e);
        }
    }
});

function buildTextField() {
    return _.extend({}, BaseControl, {
        View: View,
        Model: Model,
        withUIConfig: function(UIConfig, addTextLabel) {
            return buildTextField().extend({
                UIConfig: UIConfig,
                addTextLabel: addTextLabel
            });
        }
    });
}
export default buildTextField();
