import App from "app";
import NodeViewBase from "./nodeViewBase";
import emptyDesignerTemplate from "./templates/emptyDesignerTemplate.html";
import autoGenSourceModalTemplate from "./templates/autoGenSourceModalTemplate.html";
import ModalManager from "app/components/common/modal/ModalManager";
import growl from "app/components/common/growl";
import createContinuousApp from "./createContinuousApp";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";

var modalManager = new ModalManager({
    container: "body"
});

var AutoGenModalView = App.FormMixinDialog.View.extend({
    template: _.template(autoGenSourceModalTemplate),
    seriesToRenderInThisAdapter: null,
    regions: {
        content: ".modal-body .inner"
    },
    triggers: {
        "click .controls .button.submit": "modal:close",
        "click .create-low": "modal:create-low",
        "click .create-high": "modal:create-high"
    },
    options: {
        classes: "large"
    }
});

var EmptyDesignerView = NodeViewBase.extend({
    template: _.template(emptyDesignerTemplate),
    tagName: "div",
    hasPosApp: false,
    isNewUser: true,
    isInSubFlow: false,
    ui: {
        messageLabel: "#app-name",
        addAutoGenSource: "#addAutoGenSource"
    },
    events: {
        "click #addAutoGenSource": "showAutoGenDialog"
    },
    initialize: function() {
        this.listenTo(this, "do:render", function() {
            // to avoid race conditions where views are rendered after destroyed
            this.render();
        });
        this.setNewUserFlags();
    },
    onRender: function() {
        var designerModel = this.options.designerModel;
        this.ui.messageLabel.text(designerModel.currentFlowId != designerModel.app.id ? "flow" : "app");
    },
    serializeData: function() {
        var data = [];
        data.hasPosApp = this.hasPosApp;
        data.isNewUser = this.isNewUser;
        data.isInSubFlow = this.isInSubFlow;
        return data;
    },
    setNewUserFlags: function() {
        var that = this;
        var flowID = this.options.designerModel.get("currentFlowId");
        that.isInSubFlow = metaObjectConverter.getType(flowID) === metaStoreService.entities.FLOW;
        metaStoreService.fetchIdentifiersByType(metaStoreService.entities.APPLICATION).then(function(apps) {
            apps.each(function(app) {
                if (app.get("nsName").toLowerCase() === "samples" && app.get("name").toLowerCase() === "posapp") {
                    that.hasPosApp = true;
                }
                if (app.get("nsName").toLowerCase() !== "samples" && app.get("id") !== flowID) {
                    that.isNewUser = false;
                }
            });
            that.trigger("do:render");
        });
    },
    showAutoGenDialog: function(event) {
        var that = this;
        event.preventDefault();
        var dialog = new AutoGenModalView();
        modalManager.add(dialog);
        new App.FormMixinDialog.Controller({
            view: dialog
        });

        dialog.on("modal:close", function() {
            dialog.destroy();
        });
        dialog.on("modal:create-low", function() {
            that.addAutoGenSourceLow();
            dialog.destroy();
        });
        dialog.on("modal:create-high", function() {
            that.addAutoGenSourceHigh();
            dialog.destroy();
        });
    },
    addAutoGenSourceLow: function() {
        var flowID = this.options.designerModel.get("currentFlowId");
        createContinuousApp
            .createAdvancedSource(flowID)
            .then(function() {
                App.vent.trigger("compilation-needed");
            })
            .fail(function(e) {
                growl.error(e);
            });
    },
    addAutoGenSourceHigh: function() {
        var flowID = this.options.designerModel.get("currentFlowId");
        createContinuousApp
            .createSimpleSource(flowID)
            .then(function() {
                App.vent.trigger("compilation-needed");
            })
            .fail(function(e) {
                growl.error(e);
            });
    }
});

export default EmptyDesignerView;
