import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// @ts-ignore
import { StriimTypography, StriimButton } from "@striim/striim-ui";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";
import EmbeddingGeneratorDocumentationLink from "./embedding-generator-documentation-link";

const styles = {
    wrapper: {
        gap: 20,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        height: "100%",
        flexDirection: "column"
    }
};

export default function EmbeddingGeneratorEmpty({ onAdded }) {
    return (
        <>
            <Box sx={styles.wrapper} data-test-id="embedding-generator-empty">
                <Box
                    component="img"
                    src="src/generic/icon/manage-striim/01-ManageStriim-Files-Empty.svg"
                    alt="Person with monitor"
                />
                <StriimTypography variant="h1" color="primary.700">
                    Add your first Vector Embeddings Generator
                </StriimTypography>
                <StriimTypography variant="body4" color="greyscale.700" sx={{ width: "27%" }}>
                    You can create a Vector Embeddings Generator object which can be invoked within a built-in function
                    inside a CQ
                </StriimTypography>
                <Box>
                    <EmbeddingGeneratorDocumentationLink />
                </Box>
                <Box>
                    <StriimButton
                        startIcon={<AddIcon />}
                        variant="primary"
                        onClick={onAdded}
                        data-test-id="embedding-generator-list-add"
                    >
                        Vector Embeddings Generator
                    </StriimButton>
                </Box>
            </Box>
        </>
    );
}
