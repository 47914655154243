import React, { useState } from "react";
import { StriimButton, StriimTable, StriimTypography } from "@striim/striim-ui";
import { Rule } from "src/generic/icon/customIcons";
import {
    AddPermissionsModal,
    applyCellRendererToColumns,
    EmptyTableInfo
} from "src/modules/apps/pages/user-management/common";
import { permissionTableColumns } from "../../constants";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";
import { Grid, useTheme } from "@mui/material";
import styles from "../../../common/table-element.styles";

const getActionsCell = (permission, revokedPermissions, setRevokedPermissions) => {
    if (permission.disabled) {
        return (
            <Grid container justifyContent="flex-start">
                <StriimButton variant="text" disabled sx={styles.buttonWrapper}>
                    Revoked
                </StriimButton>
            </Grid>
        );
    }

    const revoked = revokedPermissions.map(r => r.name).indexOf(permission.name) !== -1;

    return (
        <Grid container justifyContent="space-between">
            <StriimButton
                variant="text"
                onClick={() => {
                    setRevokedPermissions([...revokedPermissions, permission]);
                }}
                disabled={revoked}
                data-testid={`${permission.name}-revoke-button`}
                sx={styles.buttonWrapper}
            >
                {revoked ? "Revoked" : "Revoke"}
            </StriimButton>{" "}
            <StriimButton
                variant="text"
                onClick={() => {
                    setRevokedPermissions(revokedPermissions.filter(r => r.name !== permission.name));
                }}
                style={revoked ? {} : { display: `none` }}
                data-testid={`${permission.name}-undo-button`}
                sx={styles.buttonWrapper}
            >
                Undo
            </StriimButton>
        </Grid>
    );
};

const PermissionsTable = ({
    permissions,
    editable,
    addedPermissions = [],
    setAddedPermissions,
    revokedPermissions = [],
    setRevokedPermissions
}) => {
    const { palette } = useTheme();

    const [addPermissionsModalVisible, setAddPermissionsModalVisible] = useState(false);

    const columnsWithRenderer = applyCellRendererToColumns(
        permissionTableColumns,
        revokedPermissions.map(x => x.id)
    );
    const columns = editable ? columnsWithRenderer : columnsWithRenderer.slice(0, 4);
    let permissionsToShow = [...permissions.map(toPermissionsRow).concat(addedPermissions)];

    if (editable) {
        permissionsToShow = permissionsToShow.map(permission => {
            const actions = getActionsCell(permission, revokedPermissions, setRevokedPermissions);
            return { ...permission, actions };
        });
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid container item alignItems="center" justifyContent="space-between">
                    <StriimTypography variant="h3" color="primary.900">
                        Permissions
                    </StriimTypography>
                    {editable && (
                        <StriimButton
                            variant="text"
                            onClick={() => setAddPermissionsModalVisible(true)}
                            data-testid="add-permission-button"
                            startIcon={<AddIcon />}
                        >
                            Add permission
                        </StriimButton>
                    )}
                </Grid>
                <Grid item md={12}>
                    <StriimTable
                        data={permissionsToShow}
                        columns={columns}
                        TableProps={{ "data-testid": "permissions-table" }}
                        TableHeaderCellProps={{
                            style: {
                                backgroundColor: palette.greyscale[50]
                            }
                        }}
                        NoRowsComponent={
                            <EmptyTableInfo
                                Icon={Rule}
                                text={`You can start adding permissions using the 'Add permission' button`}
                            />
                        }
                        indexedRow
                        TableBodyProps={{ seperated: true }}
                    />
                </Grid>
            </Grid>
            {editable && (
                <AddPermissionsModal
                    visible={addPermissionsModalVisible}
                    onConfirm={newPermission => {
                        setAddedPermissions([...addedPermissions, toPermissionsRow(newPermission)]);
                        setAddPermissionsModalVisible(false);
                    }}
                    onCancel={() => {
                        setAddPermissionsModalVisible(false);
                    }}
                />
            )}
        </>
    );
};

export default PermissionsTable;

const toPermissionsRow = v => ({
    id: v.text,
    name: v.text,
    action: v.actions.join(", "),
    namespace: v.domains[0],
    objectName: v.objectNames[0],
    objectType: v.objectTypes.join(", "),
    ...v
});
