import React from "react";
import { StriimTypography, StriimModal, StriimButton } from "@striim/striim-ui";
import { Action, ObjectType } from "core/user-management/userManagementApi";
import { generatePermissionString } from "core/user-management/permissions-parser";
import NamespaceSelect from "src/modules/dashboards/pages/dashboard-list/components/add-dashboard-dialog/components/namespace-select/namespace-select";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikField, { FormikMultiSelectField } from "src/generic/formik-field";
import { GoToConsoleInfoBox } from "./";
import { Divider, Grid } from "@mui/material";

const validationSchema = Yup.object({
    namespace: Yup.string().required("Required"),
    actions: Yup.array().required("Required"),
    objectName: Yup.string().required("Required"),
    objectTypes: Yup.array().required("Required")
});

const styles = {
    dialog: {
        ".MuiDialogActions-root": { display: "none" }, // Hide bottom section of modal
        ".MuiDialogContent-root": { overflow: "visible" },
        ".MuiDialog-paper": { overflow: "visible", width: "780px" }
    },
    divider: {
        position: "relative",
        right: "24px",
        width: "calc(100% + 48px)",
        marginTop: 4
    }
};

const AddPermissionsModal = ({ visible, onConfirm, onCancel }) => {
    const permissionActionsOptions = Object.values(Action)
        .map(v => v.toUpperCase())
        .map(v => ({ label: v, value: v }));
    const permissionObjectTypesOptions = Object.values(ObjectType)
        .map(v => v.toUpperCase())
        .map(v => ({ label: v, value: v }));

    return (
        <StriimModal
            size="medium"
            autoHeight
            isVisible={visible}
            confirmContent={null}
            cancelContent={null}
            dialogProps={{ sx: styles.dialog }}
            titleContent={
                <StriimTypography variant="h2" color="primary.700">
                    Add a Permission
                </StriimTypography>
            }
        >
            <Grid container spacing={3} pb={3}>
                <Grid item xs={12}>
                    <GoToConsoleInfoBox objectType="permissions" />
                </Grid>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{
                            namespace: "",
                            actions: [],
                            objectName: "",
                            objectTypes: []
                        }}
                        validationSchema={validationSchema}
                        validateOnChange
                        onSubmit={({ namespace, actions, objectName, objectTypes }) => {
                            const result = {
                                actions: actions.map(x => x.value),
                                objectNames: [objectName],
                                domains: [namespace],
                                objectTypes: objectTypes.map(x => x.value),
                                type: "GRANT"
                            };
                            result.text = generatePermissionString(result);
                            onConfirm(result);
                        }}
                    >
                        <Grid component={Form} noValidate spacing={2} container alignItems="center">
                            <Grid item xs={12} justifyContent="flex-start">
                                <FormikMultiSelectField
                                    required
                                    name="actions"
                                    label="Action"
                                    availableOptions={permissionActionsOptions}
                                />
                            </Grid>
                            <Grid item xs={12} justifyContent="flex-start">
                                <FormikField
                                    required
                                    name="objectName"
                                    label="Object name"
                                    placeholder=""
                                    id={"object-name"}
                                />
                            </Grid>
                            <Grid item xs={12} justifyContent="flex-start">
                                <FormikMultiSelectField
                                    required
                                    name="objectTypes"
                                    label="Object type"
                                    availableOptions={permissionObjectTypesOptions}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NamespaceSelect required name="namespace" label="Namespace" doNotCreateNamespaces />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider variant="fullWidth" orientation="horizontal" sx={styles.divider} />
                            </Grid>
                            <Grid item xs={12} justifyContent="flex-end" display="flex" mt={2}>
                                <StriimButton
                                    variant="text"
                                    onClick={onCancel}
                                    data-testid={"cancel-user-permissions-button"}
                                    sx={{ marginRight: 1 }}
                                >
                                    Cancel
                                </StriimButton>
                                <StriimButton
                                    variant="primary"
                                    type="submit"
                                    data-testid={"confirm-user-permissions-button"}
                                >
                                    Save
                                </StriimButton>
                            </Grid>
                        </Grid>
                    </Formik>
                </Grid>
            </Grid>
        </StriimModal>
    );
};

export default AddPermissionsModal;
