import React, { useContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import SemiPieChart from "../components/semi-pieChart/semi-pieChart";
import { StriimTypography } from "@striim/striim-ui-v2";
import { ChartColors } from "../components/chart.consts";
import SentinelAppsTable from "./sentinel-apps-table";
import SentinelTable from "./sentinel-table/sentinel-table";
import { SentinelMonitorContext } from "../sentinel-monitor-context";
import SentinelMonitorContent from "./sentinel-monitor-content";

const MonitorContent = () => {
    return <SentinelMonitorContent />;
};

const SidebarExpandedContent = () => {
    const { sentinalByAction } = useContext(SentinelMonitorContext);

    if (sentinalByAction.action || sentinalByAction.importance)
        return <SentinelTable defaultFilter={sentinalByAction} />;
    return <SentinelAppsTable />;
};

const SidebarContent = ({ chartData }) => {
    const { setSentinalByAction } = useContext(SentinelMonitorContext);

    const LegendItem = ({ color, label }) => {
        return (
            <Grid container gap={1} alignItems={"center"}>
                <Box borderRadius={"2px"} sx={{ width: "12px", height: "12px", backgroundColor: color }}></Box>
                <StriimTypography variant="caption3" color="greyscale.700">
                    {label}
                </StriimTypography>
            </Grid>
        );
    };
    const getChartData = (data: object) => {
        const dataObj = { ...data };
        delete dataObj?.sentinelCount;
        return Object.entries(dataObj).map(item => {
            const { label, color } = ChartColors[item[0]];
            return { name: label, value: item[1], color: color, type: item[0].toLowerCase() };
        });
    };

    const ChartItem = ({ data, title, sentinelCount }) => {
        const formattedData = sentinelCount ? getChartData(data) : null;
        return (
            <Box textAlign={"center"} display={"flex"} flexDirection={"column"} gap={1}>
                <StriimTypography variant="caption3">{title}</StriimTypography>
                {sentinelCount === 0 ? (
                    <StriimTypography variant="caption3" textAlign={"center"} fontSize={"20px"} color={"greyscale.900"}>
                        0
                    </StriimTypography>
                ) : (
                    <SemiPieChart
                        data={formattedData}
                        tooltipTitle={"#Sentinel"}
                        label={sentinelCount}
                        handleClick={e => {
                            setSentinalByAction({
                                action: e.type,
                                importance: title.toLowerCase(),
                                apps: null
                            });
                        }}
                    />
                )}
            </Box>
        );
    };

    const getImportanceCount = importanceData => {
        return {
            highCount: importanceData["HIGH"]?.sentinelCount || 0,
            mediumCount: importanceData["MEDIUM"]?.sentinelCount || 0,
            lowCount: importanceData["LOW"]?.sentinelCount || 0
        };
    };

    const ChartsList = ({ chartData }) => {
        if (chartData) {
            const { highCount, mediumCount, lowCount } = getImportanceCount(chartData.importance);
            return (
                <>
                    <ChartItem data={chartData.importance["HIGH"]} title={"High"} sentinelCount={highCount} />
                    <ChartItem data={chartData.importance["MEDIUM"]} title={"Medium"} sentinelCount={mediumCount} />
                    <ChartItem data={chartData.importance["LOW"]} title={"Low"} sentinelCount={lowCount} />
                </>
            );
        }
        return null;
    };

    if (!chartData) return null;
    return (
        <Grid>
            <Grid container gap={1} p={2} flexDirection={"column"}>
                <StriimTypography variant="body4" color="greyscale.700">
                    # Sentinels
                </StriimTypography>
                <StriimTypography>{chartData.sentinelCount}</StriimTypography>
                <Grid container>
                    <StriimTypography variant="caption3" color="greyscale.700">
                        W/ Discovery {chartData.appsWithDiscovery}
                    </StriimTypography>
                    <StriimTypography variant="caption3" color="greyscale.700">
                        In {chartData.applications} Running Apps
                    </StriimTypography>
                </Grid>
            </Grid>
            <Grid p={2} borderTop={"1px solid #D8E4F0"}>
                <StriimTypography variant="body4" color="greyscale.700">
                    # Sentinels by Configured Policy Actions
                </StriimTypography>
                <Grid container gap={4} justifyContent={"center"} mt={2} flexDirection={"column"}>
                    <ChartsList chartData={chartData} />
                </Grid>
                <Grid mt={3}>
                    <Grid container flexDirection={"column"} gap={1} p={2}>
                        <LegendItem color={ChartColors["ENCRYPT"].color} label="Encrypt" />
                        <LegendItem color={ChartColors["MASK"].color} label="Mask" />
                        <LegendItem color={ChartColors["NO_ACTION"].color} label="No Action" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export { MonitorContent, SidebarContent, SidebarExpandedContent };
