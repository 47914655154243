import App from "app";
import Backbone from "backbone";
import _ from "underscore";
import $ from "jquery";
import statusManagement from "src/status-management";
import NewNodeBuilder from "./newNodeBuilder";
import GraphNodesDragDropHandler from "./graphNodesDragDropHandler";
import GraphInitializer from "../graphInitializer";
import dialog from "app/components/common/dialogs/dialogWindow";
import GraphNodeModel from "./graphNodeModel";
import FlowGraphNode from "./flowGraphNode";
import GraphNodeView from "./graphNodeView";
import StreamNodeView from "./streamNodeView";
import EmptyDesignerView from "./emptyDesignerView";
import GraphNodesHighlighting from "./graphNodesHighlighting";
import { savePosition } from "../nodesPositionStorage";
import graphNodesTemplate from "./templates/graphNodesTemplate.html";
import nameFormatter from "app/components/common/editor/name-formatter";
import growl from "app/components/common/growl";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import lastConnectionTestRepository
    from "app/components/common/editor/control/connection-text-field/last-connection-test-repository";

var GraphNodesView;
GraphNodesView = Backbone.Marionette.CompositeView.extend({
    template: _.template(graphNodesTemplate),
    tagName: "div",
    className: "flow-designer-container",

    newNodeBuilder: null,

    childViewContainer: "div.nodes-content",

    emptyView: EmptyDesignerView,

    emptyViewOptions: {
        className: "empty-view",
        model: new GraphNodeModel({
            id: "empty-node",
            metaObject: {
                type: "empty-node-model"
            }
        })
    },

    childEvents: {
        "connect-new-node": "onConnectNewNode",
        "preview-on-run": "onPreviewOnRun"
    },

    childViewOptions: function() {
        return {
            appModel: this.model.app
        };
    },

    onConnectNewNode: function(view) {
        this.trigger("connect-new-node", view.model);
    },

    onPreviewOnRun: function(view) {
        this.trigger("preview-on-run", view.model);
    },

    initialize: function() {
        var _this = this;

        this.emptyViewOptions.designerModel = this.model;

        this.dragDropHandler = new GraphNodesDragDropHandler(this, this._onSelectionEnd.bind(this));

        this.on("childview:select-single-node", this._onSelectSingleNode.bind(this));

        this.listenTo(this.model, "change:isEditing", this.setEditingMode.bind(this));

        this.on("childview:flow-node-click", function(view, model) {
            _this.trigger("flow-node-click", model);
        });

        this.on("childview:layout-position-changed", function(view, position) {
            savePosition(_this.model.app.id, view.model.id, position);
        });

        function refreshClipboardNodes(elements, isCut) {
            _this.collection.models.forEach(function(node) {
                node.isCut = isCut;
                node.isInClipboard = elements && _.contains(elements, node.id);
            });
        }
        refreshClipboardNodes(this.model.nodesClipboard.elements, !this.model.nodesClipboard.copied);

        this.model.nodesClipboard.on("change:elements", function(model, elements) {
            refreshClipboardNodes(elements, !_this.model.nodesClipboard.copied);
        });

        this.listenTo(App.vent, "designer:show", function() {
            _this.graphInitializer.$containerEl = _this.$el;
            _this.jsPlumb = _this.graphInitializer.setGraph(_this.collection);
            _this.graphNodesHighlighting = new GraphNodesHighlighting(this.jsPlumb);
            _this.newNodeBuilder.jsPlumb = _this.jsPlumb;

            _this.setEditingMode();
            _this.model.nodesClipboard.load();
        });
        this.listenTo(App.vent, "graph-width:change", function({ height, width }) {
            const isEmptyView = $(this.childViewContainer).find(".empty-view").length === 1;

            // WE should have full width and height to show the Empty illustration in the middle of childViewContainer
            if (isEmptyView) return;

            if (width > 0) $(".nodes-content").css("width", width + 50 + "px");
            if (height > 0) $(".nodes-content").css("height", height + 100 + "px");
        });

        this.newNodeBuilder = new NewNodeBuilder(this.collection);
        this.graphInitializer = new GraphInitializer(_this.model.app);

        this.listenTo(App.vent, "copy-to-app", function(id) {
            metaStoreService.findById(id).then(copiedItem => {
                const copiedNode = this._createNewNode(
                    null,
                    copiedItem.type,
                    { top: 100, left: 100 },
                    nameFormatter.format(copiedItem.type),
                    copiedItem
                );
                this.selectNode(copiedNode);
                this.graphInitializer.initAutoLayout();
                this.runAutoLayout();
                App.vent.trigger("metadata-browser:close");
            });
        });
    },

    onShow: function() {
        this.dragDropHandler.setup();
    },

    isTempNodeAdded: function() {
        return this.model.nodes.byId(GraphNodeModel.prototype.tempNodeId);
    },

    onDestroy: function() {
        this.dragDropHandler.dispose();
    },

    getChildView: function(item) {
        if (item.metaObject.type === "STREAM") {
            return StreamNodeView;
        }

        if (item.metaObject.type === "FLOW" || item.metaObject.type === "APPLICATION") {
            return FlowGraphNode.View;
        }

        return GraphNodeView;
    },

    _onSelectionEnd: function() {
        this.unselectAllNodes();
    },

    _createNewNode: function(parentModel, componentType, newNodePosition, predefinedComponentName, initialProperties) {
        var node;
        if (typeof newNodePosition === "undefined") {
            node = this.newNodeBuilder.placeOnGraph(parentModel, componentType, predefinedComponentName);
            this.graphInitializer.connectNode(this.collection, parentModel.id, node.id);
        } else {
            node = this.newNodeBuilder.addToGraph(
                componentType,
                newNodePosition,
                parentModel,
                predefinedComponentName,
                initialProperties
            );
        }

        setTimeout(() => {
            var nodeView = this.children.findByModel(node);
            if (!nodeView) {
                return;
            }
            nodeView.setConnectNewNodeVisibile(false);

            this.graphInitializer.initAutoLayout();

            this.trigger("new-node-created");
        });
        return node;
    },

    pasteNode: function(clipboard) {
        metaStoreService.findById(clipboard.elements[0]).then(copiedItem => {
            const copiedNode = this._createNewNode(
                null,
                copiedItem.type,
                { top: 5, left: 5 },
                nameFormatter.format(copiedItem.type),
                copiedItem
            );
            copiedNode.isSelected = true;
            this.selectNode(copiedNode);
            this.runAutoLayout();
            clipboard.clear();
            growl.success("Component pasted successfully. Please fill in the missing data.");
        });
    },

    runAutoLayout: function() {
        return this.graphInitializer.runAutoLayout();
    },

    addNodeToSelectedNode: function(componentType) {
        var selectedNode = _.find(this.collection.models, function(m) {
            return m.isSelected;
        });
        if (!selectedNode) {
            return;
        }
        return this._createNewNode(selectedNode, componentType);
    },

    setEditingMode: function() {
        var nodeSelector = jsPlumb.getSelector(".flow-designer-container .node");
        if (this.model.isEditing) {
            this.jsPlumb.draggable(nodeSelector, {
                grid: [20, 20],
                constrain: position => {
                    return [Math.max(position[0], 0), Math.max(position[1], 0)];
                }
            });
            jsPlumb.setDraggable(nodeSelector, true);
        } else {
            jsPlumb.setDraggable(nodeSelector, false);
        }
    },

    unselectAllNodes: function(exceptNodeId) {
        var _this = this;
        this.collection.models.forEach(function(m) {
            if (m.id !== exceptNodeId) {
                m.isSelected = false;
                _this.graphNodesHighlighting.resetConnectionPainting(m);
            }
        });
    },

    _onSelectSingleNode: function(view, model) {
        if (this.isTempNodeAdded()) {
            return;
        }

        this.selectNode(model);
    },

    selectNode: function(model) {
        this.trigger("node-selected", model);
    },

    getSelectedNodes: function() {
        return this.collection.reject(function(m) {
            return !m.isSelected;
        });
    },

    getNodesThatNeedDestroy: function() {
        return this.getSelectedNodes().filter(function(node) {
            return node.metaObject.destroy && !node.isExternal;
        });
    },

    deleteSelectedNodes: function() {
        var _this = this;
        var deferred = $.Deferred();

        var selectedNodes = this.getSelectedNodes();
        if (selectedNodes.length === 0) {
            deferred.reject();
            return deferred;
        }

        var message = "Are you sure you want to drop the selected components?";
        dialog
            .confirm(message, App.Dashboards.ModalManager, "delete-component deleteModal", null, "Delete")
            .then(function(result) {
                // Confirm cancel click
                if (!result) {
                    deferred.reject();
                    return;
                }

                _this.model.nodesClipboard.removeNodes(_this.getSelectedNodes());

                var promises = _this.getNodesThatNeedDestroy().map(function(node) {
                    if (node.metaObject.type === "STREAM") {
                        return node.metaObject.destroy();
                    }
                });

                // we need to wait for all elements to be deleted
                // first we delete all STREAMs
                $.when.apply($, promises).then(
                    function() {
                        var nonStreamPromises = _this.getNodesThatNeedDestroy().map(function(node) {
                            if (node.metaObject.type !== "STREAM") {
                                // remove last connection validation result
                                if (
                                    node.metaObject.type === "SOURCE" &&
                                    node.metaObject.adapter &&
                                    node.metaObject.adapter.handler
                                ) {
                                    const d = $.Deferred();
                                    lastConnectionTestRepository
                                        .save(node.metaObject.id, node.metaObject.adapter.handler, null)
                                        .then(() => {
                                            node.metaObject.destroy().then(() => {
                                                d.resolve();
                                            });
                                        });
                                    return d.promise();
                                }

                                return node.metaObject.destroy();
                            }
                        });

                        nonStreamPromises = nonStreamPromises.filter(x => x);

                        // then we delete all other elements
                        $.when.apply($, nonStreamPromises).then(
                            function() {
                                deferred.resolve();
                            },
                            function(e) {
                                deferred.reject(e);
                            }
                        );
                    },
                    function(e) {
                        deferred.reject(e);
                    }
                );
            });

        return deferred.promise();
    },

    toggleRecentDataVisibility: function(status) {
        this.children.forEach(function(childView) {
            childView.toggleRecentDataVisibility(statusManagement.isApplicationRunning(status));
        });
    },

    containsNewComponent: function() {
        return !!this.collection.find(function(component) {
            return component.id === GraphNodeModel.prototype.tempNodeId;
        });
    }
});

export default GraphNodesView;
