import App from "app";
import api from "core/api/api";
import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import newSubflowDialogTemplate from "./templates/newSubflowDialogTemplate.html";
import "app/components/common/_deprecated/form-mixin-dialog/dialog";

const reservedIdentifiers = ["flow"];

var NewSubflowDialog = App.FormMixinDialog.View.extend({
    text: null,

    template: _.template(newSubflowDialogTemplate),
    regions: {
        nameInput: ".name-input",
    },

    triggers: {
        "click .cancel": "cancel-custom",
    },

    ui: {
        submitButton: ".btn.submit",
        cancelIcon: ".btn.cancel",
        cancelButton: ".btn-flat.cancel",
    },

    events: {
        "click @ui.submitButton": "onSubmit",
    },

    initialize: function (options) {
        options.bind_submit = false;
        options.submit_on_enter = true;
        options.autosave = false;
        App.FormMixinDialog.View.prototype.initialize.call(this, options);

        this.text = UIControl.MetaObjectTextField(this.model.get("namespace"), [
            metaStoreService.entities.APPLICATION,
            metaStoreService.entities.FLOW,
        ]).create();


        this.listenTo(
            this.text.model,
            "change:value",
            function () {
                this.ui.submitButton.addClass("disabled");
            }.bind(this)
        );

        this.listenTo(
            this.text,
            "value:set",
            function (value) {
                const lowerCaseName = (value || "").toLowerCase();
                if (reservedIdentifiers.indexOf(lowerCaseName) !== -1) {
                    this.show_errors({
                        message: `Flow name '${value}' cannot be used`,
                    });
                    return;
                }
                this.ui.submitButton.removeClass("disabled");
                this.lastError = null;
                this.show_errors(null);
            }.bind(this)
        );

        this.listenTo(
            this.text,
            "value:invalid",
            function (error) {
                this.lastError = error;
                this.show_errors([
                    {
                        message: error,
                    },
                ]);
            }.bind(this)
        );
    },
    onRender: function () {
        // This editor provides .modal-inner inside the modal, to help with padding
        this.$el.removeClass("modal-inner");
        this.$el.addClass("new-flow-dialog");

        this.nameInput.show(this.text);
        this.nameInput.$el.children().addClass("input-field");

        this.nameInput.$el.find("input._data").attr({"id": "name", "placeholder": "Name"});
    },

    onSubmit: function () {
        if (this.ui.submitButton.hasClass("disabled")) {
            return;
        }
        this.startProgress();
        this.submit();
    },

    startProgress: function (){
        this.trigger("progress:start");
        this.ui.cancelButton.addClass("disabled");
        this.ui.cancelIcon.addClass("disabled");
        this.ui.submitButton.addClass("disabled");
    },
    stopProgress: function (){
        this.trigger("progress:end");
        this.ui.cancelButton.removeClass("disabled");
        this.ui.cancelIcon.removeClass("disabled");
    },

    setError: function (error){
        this.stopProgress();
        this.show_errors({
            message: error,
        });
    },

    validate: function () {
        var errors = [];

        if (this.lastError) {
            errors.push({
                message: this.lastError,
            });
        }

        return errors;
    },
    serialize: function () {
        return {
            title: this.text.getValue(),
        };
    },
    update: function () {
        this.model.set({
            title: this.text.getValue(),
        });
    },
});

NewSubflowDialog.Controller = App.FormMixinDialog.Controller;

export default NewSubflowDialog;
