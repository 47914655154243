import React, { useMemo, useContext } from "react";
import { Box, Grid } from "@mui/material";
import { Step, Stepper, Theme } from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import { StriimTypography } from "@striim/striim-ui-v2";
import SuccessIcon from "./Images/success.svg";
import { WizardContext } from "../../wizard-context";

const styles = {
    container: {
        margin: "0px auto 0px auto",
        "& .MuiStep-root ": {
            padding: 0
        },
        "& .MuiStepConnector-root": {
            maxWidth: ({ spacing }) => spacing(4),
            margin: ({ spacing }) => spacing(1.5)
        },
        "& .Mui-completed.MuiStepConnector-root ": {
            "& .MuiStepConnector-line ": {
                borderColor: (theme: Theme) => theme.palette.primary[700]
            }
        },
        "& .Mui-disabled.MuiStepConnector-root ": {
            "& .MuiStepConnector-line ": {
                borderColor: (theme: Theme) => theme.palette.greyscale[400]
            }
        },
        "& .Mui-active.MuiStepConnector-root ": {
            "& .MuiStepConnector-line ": {
                borderColor: (theme: Theme) => theme.palette.greyscale[400]
            }
        },
        "& .MuiStepLabel-label.Mui-active": {
            "& .MuiTypography-root": {
                fontFamily: '"Inter","Helvetica","Arial",sans-serif',
                color: (theme: Theme) => theme.palette.primary[700]
            }
        },
        "& .MuiStepLabel-label.Mui-disabled": {
            "& .MuiTypography-root": {
                color: (theme: Theme) => theme.palette.greyscale[400]
            }
        }
    }
};
interface stepsObject {
    id: string;
    stepName: string;
    transient?: boolean;
}

interface stepperProps {
    steps: string[] | stepsObject;
    activeStep: number | string;
    width?: number;
}

export default function HorizontalLinearStepper({ steps, activeStep, width }: stepperProps) {
    const filteredSteps = useMemo(() => {
        if (Array.isArray(steps)) {
            return steps;
        }
        return Object.keys(steps)
            .filter(key => !steps[key].transient)
            .map(key => steps[key].id);
    }, [steps]);

    const getActiveStep = () => {
        if (typeof activeStep === "number") return activeStep;
        let index: string = activeStep;

        if (!filteredSteps.includes(activeStep)) {
            const transicentSteps = Object.keys(steps)
                .filter(key => steps[key].transient)
                .map(key => steps[key].id);

            if (transicentSteps.includes(activeStep)) {
                for (const key in steps) {
                    if (steps[key].id === activeStep) {
                        break;
                    }
                    if (!steps[key].transient) {
                        index = steps[key].id;
                    }
                }
            } else {
                console.error("Target wizard step not found");
            }
        }
        return Object.values(filteredSteps).indexOf(index);
    };
    return (
        <Box sx={{ ...styles.container, maxWidth: width }}>
            <Stepper activeStep={getActiveStep()}>
                {filteredSteps.map((label: string) => {
                    return (
                        <Step key={label}>
                            <StepLabel StepIconComponent={StepIcon}>
                                <StriimTypography variant="h3">{label}</StriimTypography>
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
}

const commonContainerStyles = {
    height: (theme: Theme) => theme.spacing(2.5),
    width: (theme: Theme) => theme.spacing(2.5),
    borderRadius: "50%"
};

const iconStyles = {
    successContainer: {
        ...commonContainerStyles,
        "& svg": {
            width: "100%",
            height: "100%",
            fill: "transparent"
        }
    },
    activeContainer: {
        ...commonContainerStyles,
        backgroundColor: (theme: Theme) => theme.palette.secondary.main,
        "& .MuiTypography-root": {
            color: (theme: Theme) => theme.palette.greyscale[50]
        }
    },
    stepContainer: {
        ...commonContainerStyles,
        border: (theme: Theme) => `1px solid ${theme.palette.greyscale[400]}`,
        "& .MuiTypography-root": {
            color: (theme: Theme) => theme.palette.greyscale[400],
            marginTop: "-1px"
        }
    }
};

interface stepIconProps {
    active: boolean;
    completed: boolean;
    icon: number;
}

function StepIcon(props: stepIconProps) {
    const { active, completed, icon } = props;
    if (completed) {
        return (
            <Box sx={iconStyles.successContainer}>
                <SuccessIcon />
            </Box>
        );
    }
    return (
        <Grid container justifyContent="center" sx={active ? iconStyles.activeContainer : iconStyles.stepContainer}>
            <StriimTypography variant="caption">{icon}</StriimTypography>
        </Grid>
    );
}
