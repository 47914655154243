import App from "app";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import "./editor";
import "select2";

App.module("Dashboard.VisEditor", function (VisEditor, App, Backbone, Marionette, $, _) {
    VisEditor[dataVisualizations.EDITOR.ENUM] = VisEditor.Editor.LayoutView.extend({
        templateHTML:
            '<select name="{{ editor.attribute }}"><% _(editor.options.values).each(function(value) { %><option value="{{ value.id }}">{{ value.name }}</option><% }) %></select>',
        onBeforeDestroy: function () {
            this.$("select").select2("destroy");
        },
        onRender: function () {
            let _this = this;
            let key = this.model.editor.attribute;

            this.$("select")
                .val(this.model.config[key])
                .on("change", function () {
                    _this.model.config.set(key, $(this).val());
                });

            _(function () {
                let selectSettings = {
                    dropdownCssClass: "light",
                };
                if (_this.model.editor.options.showIcon) {
                    let values = {};
                    _this.model.editor.options.values.forEach((value) => {
                        values[value.id] = value;
                    });
                    selectSettings.formatResult = function (item) {
                        const data = values[item.id];
                        if (!data.icon) {
                            return data.name;
                        }
                        return `<div><span class="icon mr-8px fa ${data.icon}"></span>${data.name}</div>`;
                    };
                    selectSettings.formatResultCssClass = () => "item-with-icon";
                }
                _this.$("select").select2(selectSettings);
            }).defer();
        },
    });
});
