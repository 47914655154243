export default {
    guardianIcon: {
        height: "20px",
        width: "20px",
        my: "auto",
        mr: 1,
        "& path": {
            fill: "none"
        }
    },
    resetIcon: {
        "& > path": {
            fill: "inherit"
        }
    },
    newTabIcon: {
        fill: "transparent",
        width: "18px",
        height: "18px"
    },
    learnLink: {
        px: 0.5,
        height: "18px",
        ".MuiButton-endIcon": { ml: 0.5 }
    },
    descriptionbox: { mb: 2 },
    headerContainer: { display: "flex", alignItems: "center" }
};
