import App from "app";
import "app/components/dashboard/visualization/charts/chart";

App.module("Chart.Value", function (Chart, App, Backbone, Marionette, $, _) {
    Chart.View = App.Chart.Default.ChartView.extend({
        className: "chart value",
        initialize: function () {
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
        },
        setConfiguration: function (config) {
            this.chartConfig = config;
        },
        serializeData: function () {
            return this.chartConfig;
        },
        render: function () {
            if (!this.chartConfig) {
                return;
            }

            var matched_template = this.model.config.templates.findWhere({
                matched: true,
            });
            var templateStr = "";
            if (matched_template) {
                templateStr = matched_template.userTemplate;
            }

            _(this.model.config.last_data).each(function (val, key) {
                templateStr = templateStr.replace(
                    new RegExp("{{ *" + key + " *}}", "g"),
                    "{{config.last_data." + key + "}}"
                );
            });

            this.template = _.template('<div class="value_container">' + templateStr + "</div>");

            try {
                var _this = this;
                Backbone.Marionette.ItemView.prototype.render.apply(this, arguments);
                if (this.chartConfig.config.drilldown.show_drilldown) {
                    _this.$(".value_container").on("click", function () {
                        _this.trigger("datapoint:click", _this.model.config.last_data);
                    });
                    _this.$(".value_container").addClass("vis-link");
                } else if (this.chartConfig.config.clear_filters_button) {
                    _this.$(".value_container").addClass("vis-link");
                    _this.$(".value_container").on("click", function () {
                        App.vent.trigger("dashboard:page:clearparams");
                    });
                }
            } catch (ex) {
                console.error(ex);
                this.template = _.template("<div>Waiting for data...</div>");
                Backbone.Marionette.ItemView.prototype.render.apply(this, arguments);
            }

            if (matched_template) {
                var text_color = matched_template.style.color;
                this.$(".value_container").css("color", text_color);
                this.$("div").css("background-color", matched_template.style.background);
            }
            this.$el.toggleClass("vertical-centered", this.chartConfig.config.verticallyCentered);

            this.model.config.lastRendered = new Date().getTime();
        },
        onDestroy: function () {
            this.$(".value_container").off("click");
        },
    });
});
