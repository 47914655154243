import React, { useEffect, useRef } from "react";
import { useObserver } from "mobx-react-lite";
import { StriimTheme } from "@striim/striim-ui";
import { CentralContainer } from "../common/central-container/central-container";
import SelectApi from "../common/select-api/select-api";
import { ValidateConnection } from "../common/validate-connection/validate-connection";
import LoadingIndicator from "src/generic/loading-indicator";
import { COSMOSDB_CONFIG } from "./config";
import Stepper from "../../../common/stepper/stepper";

function getDetailView(stepID, store, validateCxnStore) {
    switch (stepID) {
        case 0: {
            const targetApis = store.showOnlyMongoAPI ? [COSMOSDB_CONFIG["mongo"]] : Object.values(COSMOSDB_CONFIG);
            return (
                <SelectApi
                    header={"Select your Cosmos DB API"}
                    description={"Select one API below to write to your Azure Cosmos DB collection"}
                    selected={store.targetApi}
                    targetApis={targetApis}
                    onApiSelect={api => store.setTargetApi(api)}
                    store={store}
                />
            );
        }
        case 1: {
            const TargetApiComponent = COSMOSDB_CONFIG[store.targetApi.id].targetComponent;
            return (
                <TargetApiComponent
                    nsName={store.appName.split(".")[0] || ""}
                    sourceDatabases={store.sourceDatabases}
                    targetApi={store.targetApi}
                    formRef={store.formRef}
                    targetConfig={store.targetConfig}
                    store={store}
                />
            );
        }
        case 2: {
            return <ValidateConnection store={store} validateCxnStore={validateCxnStore} componentStore={store} />;
        }
        case 3:
            return <h1> </h1>;
    }
}

function CosmosDBTarget({ store, validateCxnStore }) {
    const formRef = useRef();
    useEffect(() => {
        store.setFormRef(formRef);
        store.disableStepNavigation();
        store.getSourceAdapter();
    }, []);
    return useObserver(() => (
        <StriimTheme preselector=".striim.materialize.light:not(#\20)">
            <div>
                {store.isLoading && <LoadingIndicator />}
                <Stepper steps={store.steps} activeStep={store.step} width={630} />
                <CentralContainer width={store.step === 0 ? 960 : 700}>
                    {getDetailView(store.step, store, validateCxnStore)}
                </CentralContainer>
            </div>
        </StriimTheme>
    ));
}

export default CosmosDBTarget;
