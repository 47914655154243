import App from "app";
import $ from "jquery";
import statusManagement, { AppAction } from "src/status-management";
import menu from "app/components/common/menu/menu";
import menuOption from "app/components/common/menu/menu-option";
import PageBlocker from "app/components/flow/designer/page-blocker";
import dropApplication from "app/components/flow/designer/dropApplication";
import "app/components/common/dialogs/dialogWindow";
import ModalManager from "app/components/common/modal/ModalManager";
import _ from "underscore";
import Backbone from "backbone";
import Marionette from "marionette";
import api from "core/api/api";
import UIControl from "app/components/common/editor/control/ui-control";
import Dropdown from "app/components/common/dropdown";
import "app/components/common/_deprecated/form-mixin-dialog/dialog";
import appStatusSynchronizer from "core/appStatusSynchronizer";
import deploy_template_html from "app/components/common/app-control/deploy.html";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import dataTypeValidation from "app/components/flow/designer/data-type-validation/data-type-validation";
import Tracker from "core/services/tracker/tracker";
import "select2";
import "tooltips";
import ExportDialog from "./exportApp";
import growl from "../growl";
import NProgress from "nprogress";
import exceptionsStoreApi from "app/components/flow/designer/app-exceptions/exception-store-api";
import ensureRegions from "../_ensureRegions";
import { removeHtmlResponse } from "app/components/flow/designer/appErrors/exception-helper.js";
import { TRACKER_STRINGS } from "../../../../core/services/tracker/constants";

// This creates an app-level modal manager, not bound to any specific module
var modalManager = new ModalManager();
NProgress.configure({
    minimum: 0.25,
    trickleRate: 0.1,
    trickleSpeed: 1500
});

let ChooseNamespaceDialogBody = Marionette.LayoutView.extend({
    template: _.template(deploy_template_html),

    regions: {
        validationCheckbox: ".validate-connection>div"
    },

    ui: {
        validationContainer: ".validate-connection"
    },

    onRender: function() {
        this.$("select").select2();

        if (this.model.get("validateConnection")) {
            this.validateConnectionToggle = UIControl.Toggle.create({ labelClass: "small flat-cta" });
            this.validateConnectionToggle.model.value = this.model.get("validateConnection");
            this.listenTo(
                this.validateConnectionToggle.model,
                "change:value",
                function() {
                    this.model.set("validateConnection", this.validateConnectionToggle.model.value);
                }.bind(this)
            );
            this.validationCheckbox.show(this.validateConnectionToggle);
        } else {
            this.ui.validationContainer.addClass("hidden-element");
        }
    },

    onBeforeDestroy: function() {
        this.$("select").select2("destroy");
    }
});

var DeployDialog = App.FormMixinDialog.View.extend({
    regions: {
        content: "#dialog-body"
    },
    triggers: {
        "click submit": "form:submit"
    },
    initialize: function(options) {
        options.submit_on_enter = true;
        options.bind_submit = true;
        options.classes = "deploy-dialog";
        options.scrollable = false;
        App.FormMixinDialog.View.prototype.initialize.apply(this, [options]);
    },
    onRender: function() {
        ensureRegions(this);
        let that = this;

        let content = new ChooseNamespaceDialogBody({
            model: this.model
        });
        this.getRegion("content").show(content);
        this.listenTo(App.vent, "do:deploy", function() {
            that.trigger("form:submit");
        });
    },
    serialize: function() {
        return this.serialize_form(this.$("form"));
    },
    update: function(data) {
        var deploy_data = {};
        _(data).each(function(val, key) {
            var property = key.match(/_(group|strategy)$/g);
            if (property) {
                property = property[0];
            } else {
                throw Error("Error parsing deploy data");
            }
            var name = key.slice(0, property.length * -1);
            property = property.replace("_", "");
            if (!deploy_data[name]) {
                deploy_data[name] = {
                    flow: name
                };
            }
            deploy_data[name][property] = val;
        });

        this.model.set("config", deploy_data);
    }
});

export default Marionette.ItemView.extend({
    do_deploy: function(args) {
        var _this = this;
        const id = metaObjectConverter.convertShortNameToFullName(this.current_app_full, "APPLICATION");
        App.vent.trigger("progress:start", id);
        var args2 = {
            namespace: _this.current_ns,
            appName: _this.current_app
        };
        api.deploy(args, args2)
            .fail(function(e) {
                _this.onStatusChangedFailure("deploy", e);
            })
            .always(function() {
                App.vent.trigger("progress:end", id);
            });
    },
    deploy: function() {
        var that = this;
        this.appIdentifier.fetchMetaObject().then(function(appModel) {
            let appFullId = metaObjectConverter.convertShortNameToFullName(that.current_app_full, "APPLICATION");

            var flows_p = appModel.getApplicationComponentsByType(metaStoreService.entities.FLOW);
            var dg_p = metaStoreService.fetchIdentifiersByType(metaStoreService.entities.DG);
            let validationEnabledPromise = that._checkIsValidationEnabled(appFullId);

            modalManager._show_bg();
            $.when(flows_p, dg_p, validationEnabledPromise).then(
                function(flows, groups, isValidationEnabled) {
                    flows.add(appModel);
                    groups = groups.toJSON();
                    const specialGroups = ["default", "Agents"];
                    groups = groups
                        .sort((a, b) => {
                            if (specialGroups.includes(a.name) && specialGroups.includes(b.name)) {
                                return -a.name.localeCompare(b.name);
                            } else if (specialGroups.includes(a.name)) {
                                return -1;
                            } else if (specialGroups.includes(b.name)) {
                                return 1;
                            } else {
                                return a.name.localeCompare(b.name);
                            }
                        })
                        .filter(group => {
                            return group.name !== "DefaultAgentMonitoring";
                        });
                    var deploy_dialog = new DeployDialog({
                        model: new Backbone.Model({
                            title: "Deploy " + this.current_app,
                            submit_text: "Deploy",
                            cancel_text: "Cancel",
                            appname: this.current_app,
                            groups: groups,
                            flows: flows.toArray(),
                            config: {}, // to be set on save,
                            validateConnection: isValidationEnabled
                        })
                    });

                    new App.FormMixinDialog.Controller({
                        view: deploy_dialog
                    });

                    var DialogView = modalManager.add(deploy_dialog);
                    this.listenTo(DialogView, "before:destroy", function({ isCancelEvent = false }) {
                        if (isCancelEvent) DialogView.trigger("cancel");
                    });
                    this.listenTo(DialogView, "destroy", function() {
                        this.stopListening(DialogView);
                        deploy_dialog = null;
                        modalManager._hide_bg();
                    });

                    this.listenTo(DialogView, "cancel", function() {
                        App.vent.trigger("cancel:deployment");
                    });

                    this.listenTo(DialogView, "form:submit", function(dialog) {
                        this.listenToOnce(dialog, "modal:hide", function() {
                            function convertConfigToHashMap() {
                                let resultHashMap = {};
                                let config = dialog.model.get("config");
                                for (let key in config) {
                                    let item = config[key];
                                    resultHashMap[item.flow] = item.group;
                                }
                                return resultHashMap;
                            }

                            Tracker.getInstance().track(TRACKER_STRINGS.schema.appControl, {
                                id: dialog.model.get("appname"),
                                event: TRACKER_STRINGS.eventClicked.appControl,
                                buttonClicked: "Deploy"
                            });

                            let deploymentInfo = convertConfigToHashMap();
                            let validateConnection = dialog.model.get("validateConnection");
                            if (validateConnection !== false) {
                                App.vent.trigger("start:data-type-validation");
                            }
                            dataTypeValidation(appFullId, deploymentInfo, validateConnection).then(result => {
                                if (result) {
                                    this.do_deploy(dialog.model.get("config"));
                                } else {
                                    App.vent.trigger("cancel:deployment");
                                }
                            });
                        });
                        dialog.destroy();
                    });
                }.bind(that)
            );
        });
    },

    _checkIsValidationEnabled: function(applicationId) {
        let $deferred = new $.Deferred();

        if (typeof window.fakeAllowAppValidation !== "undefined") {
            // simplify UI testing
            $deferred.resolve(!!window.fakeAllowAppValidation);
            return $deferred.promise();
        }

        api.isValidationEnabled(applicationId)
            .then(result => {
                $deferred.resolve(result);
            })
            .fail(e => {
                console.error("validation method failed", e);
                $deferred.resolve(false);
            });

        return $deferred.promise();
    },

    undeploy: function() {
        var _this = this;
        App.vent.trigger("progress:start", _this.current_app_full);
        api.undeploy(this.current_app_full)
            .fail(function(e) {
                _this.onStatusChangedFailure("undeploy", e);
            })
            .always(function(/*d*/) {
                App.vent.trigger("progress:end", _this.current_app_full);
            });
    },
    start: function() {
        var _this = this;
        Tracker.getInstance().track(TRACKER_STRINGS.schema.appControl, {
            id: _this.current_app,
            event: TRACKER_STRINGS.eventClicked.appControl,
            buttonClicked: "Start"
        });
        App.vent.trigger("progress:start", _this.current_app_full);
        api.startApp(this.current_app_full)
            .fail(function(e) {
                _this.onStatusChangedFailure("start", e);
            })
            .always(function() {
                App.vent.trigger("progress:end", _this.current_app_full);
            });
    },
    stop: function() {
        var _this = this;
        App.vent.trigger("progress:start", _this.current_app_full);
        api.stopApp(this.current_app_full)
            .fail(function(e) {
                _this.onStatusChangedFailure("stop", e);
            })
            .always(function() {
                App.vent.trigger("progress:end", _this.current_app_full);
            });
    },
    quiesce: function() {
        var _this = this;
        App.vent.trigger("progress:start", _this.current_app_full);
        api.quiesceApp(this.current_app_full)
            .fail(function(e) {
                _this.onStatusChangedFailure("stop", e);
            })
            .always(function() {
                App.vent.trigger("progress:end", _this.current_app_full);
            });
    },
    drop: function(data) {
        if (!data) {
            data = {};
        }
        var _this = this;

        const appId = metaObjectConverter.convertShortNameToFullName(this.current_app_full, "APPLICATION");

        function dropApp() {
            _this.pageBlocker.block();
            api.dropObject({
                type: "application",
                name: _this.current_app_full
            })
                .then(function() {
                    growl.success("", "Application dropped successfully.");
                    App.vent.trigger("app:deleted", _this.current_app_full);
                })
                .fail(function(e) {
                    _this.onStatusChangedFailure("drop", e);
                })
                .always(function() {
                    _this.pageBlocker.unblock();
                });
        }

        if (data.quiet) {
            dropApp();
            return false;
        }

        dropApplication(modalManager, _this.current_app_full).then(function(result) {
            if (!result) {
                return;
            }
            metaStoreService.findById(appId).then(application => {
                if (application.get("exceptionstoreName") !== null && !result.doNotDeleteExceptions) {
                    // delete exceptionstore and then delete the app
                    return exceptionsStoreApi
                        .deleteLogs(appId)
                        .then(
                            result => {
                                if (result !== true) {
                                    console.warn("cannot delete logs for " + appId, result);
                                }
                                growl.success("", "ExceptionStore deleted successfully.");
                            },
                            () => {
                                growl.error("", "There was a problem deleting the Exception Store.");
                            }
                        )
                        .finally(() => {
                            dropApp();
                        });
                } else {
                    dropApp();
                }
            });
        });

        return false;
    },
    export: function() {
        var _this = this;
        const id = metaObjectConverter.convertShortNameToFullName(this.current_app_full, "APPLICATION");
        let exportDialog = new ExportDialog({
            model: new Backbone.Model({ apps: [_this.current_app_full] })
        });

        modalManager.add(exportDialog);
        new App.FormMixinDialog.Controller({
            view: exportDialog
        });

        exportDialog.on("export:done", () => {
            App.vent.trigger("progress:end", id);
            exportDialog.destroy();
        });
        exportDialog.on("export:fail", e => {
            _this.onStatusChangedFailure("export", e);
            App.vent.trigger("progress:end", id);
            exportDialog.destroy();
        });
    },

    exportMultiple: function(apps) {
        var _this = this;
        let exportDialog = new ExportDialog({
            model: new Backbone.Model({ apps: apps })
        });

        modalManager.add(exportDialog);
        new App.FormMixinDialog.Controller({
            view: exportDialog
        });

        exportDialog.on("export:done", () => {
            exportDialog.destroy();
        });
        exportDialog.on("export:fail", e => {
            _this.onStatusChangedFailure("export", e);
            exportDialog.destroy();
        });
    },
    view: function() {
        App.navigate("/flow/" + this.current_app_full, {
            trigger: true
        });
        return false;
    },
    monitor: function() {
        var orig = window.location.origin;
        var monUrl = "/#/monitor/" + this.current_app_full;
        if (typeof window.event !== "undefined") {
            window.event.returnValue = false;
        }
        //FIXME: what is this....?
        window.location = orig + monUrl;
        return false;
    },
    resume: function() {
        var _this = this;
        App.vent.trigger("progress:start", _this.current_app_full, "resume");
        api.resumeApp(this.current_app_full)
            .fail(function(e) {
                _this.onStatusChangedFailure("resume", e);
            })
            .always(function() {
                App.vent.trigger("progress:end", _this.current_app_full, "resume");
            });
        return;
    },
    show_errors: function() {
        App.navigate("/flow/" + this.current_app_full + "/view/exceptions", {
            trigger: true
        });
        return false;
    },

    dashboard: function() {
        if (this.options.dashboardID !== null) {
            App.vent.trigger("dashboard:navigate", this.options.dashboardID, undefined, {});
        }
    },
    className: "app-control",
    template: _.template('<div class="app-control-content"><div class="options"></div></div>'),
    initialize: function(params) {
        this.options = params;
        _(params).defaults({
            dropdown: {}
        });
        this.pageBlocker = new PageBlocker("#top-bar");

        this.actions = new menuOption.Model.Collection(statusManagement.appActions);

        if (params.align) {
            this.align = params.align;
        }

        params.appIdentifier = params.appIdentifier || params.app;

        if (params.appIdentifier || params.app) {
            if (_(params.appIdentifier).isString()) {
                params.appIdentifier = {
                    name: params.appIdentifier.split(".")[1],
                    nsName: params.appIdentifier.split(".")[0]
                };
            }
            this.appIdentifier = params.appIdentifier;
            this.current_app = this.appIdentifier.name;
            this.current_ns = this.appIdentifier.nsName;
            this.current_app_full = this.current_ns + "." + this.current_app;
        }

        if (params.trigger) {
            $(params.trigger).addClass("app-control-trigger");
        }

        this.$trigger = $(params.trigger) || this.$el;

        if (this.appIdentifier) {
            this.listenTo(
                appStatusSynchronizer,
                this.appIdentifier.id,
                function(app_status) {
                    this.set_status(app_status);
                }.bind(this)
            );

            this.listenTo(App.vent, "progress:start", function(app) {
                if (!window.location.href.includes("applications")) {
                    return;
                }
                const id = this.appIdentifier.nsName + "." + this.appIdentifier.name;
                if (app !== id) {
                    return;
                }
                NProgress.configure({
                    parent: "#apptile--" + id.replace(".", "\\.")
                });
                NProgress.start();
            });

            this.listenTo(App.vent, "progress:end", function(app) {
                if (!window.location.href.includes("applications")) {
                    return;
                }
                const id = this.appIdentifier.nsName + "." + this.appIdentifier.name;
                if (app !== id) {
                    return;
                }
                NProgress.done();
            });
        }
        if (this.options.dashboardID === null) {
            this.actions.remove({
                id: AppAction.DASHBOARD
            });
        }
    },

    onStatusChangedFailure: function(action, e) {
        if (!e) {
            e = {};
        }
        action = action.toUpperCase();
        growl.error(this.actions.get(action).text + " failed! " + removeHtmlResponse(e.message) + "", "Error");
    },

    onRender: function() {
        var permissionsPromise = App.reqres.request("user:permissions");
        var that = this;
        permissionsPromise.then(function(permissions) {
            var not_allowed_when_apps_edit = [
                AppAction.START,
                AppAction.STOP,
                AppAction.UNDEPLOY,
                AppAction.DEPLOY,
                AppAction.DROP,
                AppAction.EXPORT,
                AppAction.RESUME
            ];
            that.actions.each(function(model) {
                if (
                    permissions["*:*:apps_ui_edit:*"] === false &&
                    not_allowed_when_apps_edit.indexOf(model.get("id")) !== -1
                ) {
                    model.set("enabled", false);
                }
                if (permissions["*:*:monitor_ui:*"] === false && model.get("id") === AppAction.MONITOR) {
                    model.set("enabled", false);
                }
                if (permissions["*:*:apps_ui:*"] === false && model.get("id") === AppAction.VIEW) {
                    model.set("enabled", false);
                }
            });

            var menu_model = new menu.Model({
                options: that.actions
            });

            var menuView = new menu.View({
                model: menu_model
            });
            that.listenTo(menuView, "option:click", function(optionID) {
                that[optionID.toLowerCase()]();
                menuView.trigger("dropdown:hide");
            });

            var dropdown_config = {
                parentView: that,
                content: menuView,
                trigger: that.$trigger
            };
            dropdown_config = _(dropdown_config).extend(that.options.dropdown);
            new Dropdown(dropdown_config);

            that.set_status(appStatusSynchronizer.getStatus(that.appIdentifier.id));
        });
    },
    set_status: function(new_status) {
        if (!new_status) {
            return;
        }
        this.status = new_status;
        this.appIdentifier.flowStatus = new_status;
        var pretty_status = new_status.toLowerCase();
        var status_data = statusManagement.getStatus(pretty_status);

        if (!status_data) {
            return;
        }
        this.actions.each(function(option) {
            if (!_(status_data.actions).contains(option.id) || _(this.options.disabled).contains(option.id)) {
                option.visible = false;
                return;
            }
            option.visible = true;
        }, this);
    }
});
