import React from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import WelcomeMessage from "../../../homepage/welcome_message/welcome_message";
import { StriimButton, StriimTypography } from "@striim/striim-ui";
import { TemplateItem } from "../create-app-page/components/create-app-content/components/template-item/template-item";
import TemplateItemIcons from "../create-app-page/components/create-app-content/components/template-item-icons";
import { getTemplates } from "../create-app-page/utils/get-campaigns";
import { useNavigate } from "react-router";
import { styles } from "./new_user.styles";
import Explore from "../../../homepage/explore/explore";

const AppAdapterHomePage = ({ username, video }) => {
    const navigate = useNavigate();
    const templatesList = getTemplates();
    let homePageTemplates = templatesList
        .filter(
            template =>
                ["HubSpotReader", "ZendeskReader", "StripeReader"].includes(template.uses[0]) &&
                template.uses[1] === "BigQueryWriter"
        )
        .sort((first, second) => {
            return first.enabledByLicense ? -1 : 0;
        });
    const constructSubtitle = (sourceTitle, targetTitle) =>
        sourceTitle && targetTitle ? `${sourceTitle} to ${targetTitle}` : sourceTitle || targetTitle;

    return (
        <Grid container direction="row" data-test-id="new-user" sx={styles.appAdapterContainer}>
            <Grid sx={styles.appAdapterTopContainer} item xs={12}>
                <Grid item xs={4}>
                    <div style={styles.info} data-test-id="paragraph--homepage-recent-apps-empty">
                        <WelcomeMessage username={username} isAppAdapterLicense={true} />
                        <StriimTypography variant="body">
                            Striim Apps are data pipelines that can capture, process, and deliver data in real-time. You
                            can create a Striim App quickly with the help of a Striim Wizard or build a custom Striim
                            App with transformations in Flow Designer. Click ‘Create an App’ to get started.
                        </StriimTypography>
                    </div>
                    <div style={styles.button}>
                        <StriimButton
                            variant="primary"
                            data-test-id="create-an-app--button"
                            onClick={() => navigate("/create-app")}
                        >
                            Create an App
                        </StriimButton>
                    </div>
                </Grid>

                <Grid sx={styles.templateItem} item xs={8}>
                    {homePageTemplates.map(
                        ({
                            id,
                            fromIcon,
                            toIcon,
                            title,
                            url,
                            onClick,
                            isAutomatedILCDC,
                            enabledByLicense,
                            sourceAdapter
                        }) => (
                            <Grid
                                item
                                key={id}
                                sx={styles.itemGridWrapper}
                                id={("createapp--" + title).replace(/ /g, "_")}
                            >
                                <TemplateItem
                                    onHomePage={true}
                                    isCardDisabled={!enabledByLicense}
                                    onClick={() => {
                                        onClick && onClick();
                                        url && navigate("" + url);
                                    }}
                                    content={<TemplateItemIcons {...{ fromIcon, toIcon }} />}
                                    subtitle={constructSubtitle(title)}
                                    url={url}
                                    isAutomatedILCDC={isAutomatedILCDC}
                                    sourceAdapter={sourceAdapter}
                                />
                            </Grid>
                        )
                    )}
                </Grid>
            </Grid>
            <Grid item sx={styles.bottomContainer} xs>
                <Explore data-test-id="explore" video={video} />
            </Grid>
        </Grid>
    );
};

export default AppAdapterHomePage;

AppAdapterHomePage.propTypes = {
    username: PropTypes.string.isRequired
};
