import _ from "underscore";
import TextField from "./../text-field/text-field";
import defaultTextFieldTemplate from "./../text-field/text-field.html";

const View = TextField.View.extend({
    template: _.template(
        '<div class="connection-text-field">' +
            defaultTextFieldTemplate +
            '<a>Test connection<i class="fa fa-check pl-8px hidden-element"></i>' +
            '<img class="loader ml-8px hidden-element" src="/webui/app/images/ring.gif"/></a></div>'
    ),

    ui: _.extend(
        {
            link: "a",
            status: "a i",
            loader: ".loader",
        },
        TextField.View.prototype.ui
    ),

    events: _.extend(
        {
            "click @ui.link": "testConnection",
        },
        TextField.View.prototype.events
    ),

    testConnection: function (e) {
        e.preventDefault();
        if (!this.getViewValue()) {
            return;
        }
        if (this._isTesting) {
            return;
        }
        this._setTestingState(true);
        this.trigger("connection-test", this._setConnectionStatus.bind(this));
    },

    _setTestingState: function (isTesting) {
        this.ui.link.toggleClass("action", !isTesting);
        this._isTesting = isTesting;
        this.ui.loader.toggleClass("hidden-element", !isTesting);
    },

    _setConnectionStatus: function (status) {
        this.ui.status.removeClass("hidden-element");
        this._setTestingState(false);
        if (status) {
            this.ui.status.html("");
            this.ui.status.addClass("fa fa-check");
            this.ui.status.removeClass("validation-error");
        } else {
            this.ui.status.html("×");
            this.ui.status.removeClass("fa fa-check");
            this.ui.status.addClass("validation-error");
        }
    },

    initialize: function () {
        TextField.View.prototype.initialize.apply(this, arguments);
        this.listenTo(
            this.model,
            "change:value",
            function (model, value) {
                if (this.ui.link.toggleClass) {
                    this.ui.link.toggleClass("action", !!value);
                    this.ui.status.addClass("hidden-element");
                }
            }.bind(this)
        );
    },

    onRender: function () {
        TextField.View.prototype.onRender.apply(this, arguments);
        this.ui.link.toggleClass("action", this.model.value);
    },
});

function buildTextField() {
    return _.extend({}, TextField, {
        View: View,
    });
}

export default buildTextField();
