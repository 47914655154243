const USER_MANAGEMENT_STRINGS = {
    label: {
        basicInfo: "Basic Info",
        namespace: "Namespace",
        roleName: "Role Name",
        role: "role",
        roles: "ROLES",
        permissions: "PERMISSIONS",
        contactInfo: "Contact info",
        username: "Striim username",
        password: "Password",
        propertyset: "Property set",
        alias: "Alias for",
        firstName: "First Name",
        lastName: "Last Name",
        emailAddress: "Email address",
        sms: "SMS",
        phone: "Phone number",
        timezone: "Timezone",
        ldapInfo: "Users using OpenLDAP or Microsoft Active Directory server to authenticate Striim users.",
        ldapIdEqualsUsername: "Set a Striim username based on the LDAP user ID"
    },
    inputLabel: {
        username: "Striim username",
        password: "Password",
        firstName: "First name (optional)",
        lastName: "Last name (optional)",
        email: "Email address (optional)",
        phone: "Phone number (optional)",
        timezone: "Timezone (optional)",
        ldapUserId: "LDAP user ID",
        propertyset: "Property set"
    },
    button: {
        back: "Back to User and role management",
        addRole: "Add role",
        addPermission: "Add permission",
        learnMoreLDAP: "Learn more "
    },
    helperText: {
        username: [
            "User can log into Striim using this username.",
            "When editing, use only letters, numbers or underscores.",
            "This cannot start with a number."
        ],
        usernameLDAP: [
            "User can log into Striim using this username. By default it will be the based on the LDAP user ID specified above. ",
            "When editing, use only letters, numbers or underscores.",
            "This cannot start with a number."
        ],
        generatedUsername: "User can log into Striim using this username.",
        password: "Invited user can change after activation",
        propertySet:
            "Please select an existing property set from the list above. Use the Tungsten console to create a new property set.",
        ldapUserId:
            "This is the value for the LDAP property specified by USER_USERID in the property set. Striim combines it with USER_BASE_DN to look up users in LDAP. "
    }
};

const roleColumnKey = {
    name: "name",
    permissionsText: "permissionsText",
    actions: "actions"
};

const roleTableColumns = [
    {
        Header: "Name",
        accessor: roleColumnKey.name,
        width: 180
    },
    {
        Header: "Permissions",
        accessor: roleColumnKey.permissionsText,
        minWidth: 550
    },
    {
        Header: "",
        accessor: roleColumnKey.actions,
        width: 110
    }
];

const addRoleTableColumns = [
    {
        Header: "Name",
        accessor: roleColumnKey.name,
        width: 180
    },
    {
        Header: "Permissions",
        accessor: roleColumnKey.permissionsText,
        width: 550
    },
    {
        Header: "",
        accessor: roleColumnKey.actions,
        width: 110
    }
];

const permissionColumnKey = {
    name: "name",
    permissions: "permissions",
    actions: "actions"
};

const permissionTableColumns = [
    {
        Header: "Permissions",
        accessor: permissionColumnKey.permissions
    },
    {
        Header: "",
        accessor: permissionColumnKey.actions,
        width: 180
    }
];

const permissionEditTableColumns = [
    {
        Header: "Action",
        accessor: "action"
    },
    {
        Header: "(In) Object Name",
        accessor: "objectName"
    },
    {
        Header: "Object Type",
        accessor: "objectType"
    },
    {
        Header: "(In) Namespace",
        accessor: "namespace"
    },
    {
        Header: "",
        accessor: permissionColumnKey.actions,
        width: 90
    }
];

export {
    USER_MANAGEMENT_STRINGS,
    roleColumnKey,
    roleTableColumns,
    addRoleTableColumns,
    permissionColumnKey,
    permissionTableColumns,
    permissionEditTableColumns
};
