var formatter = {};

formatter.toPercentageString = function(value) {
    if (value === null || value === undefined || value === "") {
        return;
    }

    return Math.round(value * 1000) / 10 + "%";
};

formatter.formatRate = function(value) {
    if (!value || value === null) {
        return value;
    }

    return Math.ceil(value);
};

formatter.formatStatus = status => {
    switch (status) {
        case "CRASH":
            return "TERMINATED";
        case "HALT":
            return "HALTED";
        default:
            return status;
    }
};

export default formatter;
