import EmptyControl from "app/components/common/editor/control/empty-transformation/empty-transformation";

var Transformation = {};

Transformation.OnlyDbSources = true;

Transformation.createColumnControl = function () {
    return EmptyControl.extend({
        hideLabel: true,
        hidden: true,
        transformationConfig: {},
    }).create();
};

Transformation.buildTql = function (tqlBuilder, columns) {
    var metadataValue = tqlBuilder.getMetaDataValueStatement("OperationName");
    var putUserData = tqlBuilder.getPutUserDataStatement([{ key: "OrigOperationName", value: metadataValue }]);
    var changeStatement = tqlBuilder.getChangeOperationToInsertStatement(putUserData);

    tqlBuilder.addColumn(changeStatement);
};

export default Transformation;
