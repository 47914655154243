export default {
    save: async function(queryVisualization, draft_model) {
        var json = draft_model.toJSON().config;

        // Note: because we no longer use datavis-type-specific models, we must take care not to overwrite the config attribute as part of a higher-level JSON, and set its model directly. (TODO: can we think of a way to bring that back w/o bringing back type-specific models?)
        queryVisualization.config.set(json);
        var data = queryVisualization.config.get("data");
        var last_data = queryVisualization.config.get("last_data");
        queryVisualization.config.unset("data");
        queryVisualization.config.unset("last_data");
        await queryVisualization.save(null, {
            parse: false
        });
        queryVisualization.config.set("data", data);
        queryVisualization.config.set("last_data", last_data);
    }
};
