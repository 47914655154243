import App from "app";
import Backbone from "backbone";
import Marionette from "marionette";
import template from "./base-simple-source-template.html";
import formBuilderUtils from "app/components/common/editor/meta-object-editors";
import metaObjects from "core/services/metaStoreService/meta-objects";
import utils from "core/utils";
import "app/components/common/editor/control/ui-control";
import "app/components/common/editor/form-builder";

import NProgress from "nprogress";
import templates from "../../../../templates";
import ReactDOM from "react-dom/client";
import { StriimMessageBox, StriimTheme } from "@striim/striim-ui";
import React from "react";
import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import ensureRegions from "../../../../../common/_ensureRegions";
import getControlResource from "../../../../../common/editor/control-resource-resolver";

NProgress.configure({
    minimum: 0.25,
    trickleRate: 0.1,
    trickleSpeed: 800
});

var module = {};

/**
 * View to be extended by all simple source wizards.. simple source wizards are straight up wizards without any steps.
 */
module.View = Marionette.LayoutView.extend({
    template: _.template(template),
    className: "file-wizard",
    regions: {
        formContainer: "#source-form-container"
    },
    serializeData: function() {
        var data = this.model.toJSON();
        data.label = this.label;
        data.displayName = this.displayName;
        return data;
    },
    initialize: function(options) {
        this.model = new metaObjects.SOURCE.Model();

        var appName = options.appName ? options.appName.split(".")[1] : null;
        this.appName = appName;

        var nsName = options.appName ? options.appName.split(".")[0] : null;

        this.model.set("name", appName + "_Source");
        this.model.set("nsName", nsName);

        this.setAdapterProperties();

        var flow = new metaObjects.FLOW.Model();
        flow.nsName = nsName;
        flow.name = appName + "_SourceFlow";
        flow.save({
            flow: appName
        });
    },
    showError: function(err) {
        let domErrorContainer = $(".error-box-container");
        let domErrorMessage = $(".error-message");
        if (!err) {
            domErrorContainer.hide();
        } else {
            domErrorMessage.html(err);
            domErrorContainer.show();
            // this.$el.find(".error.message .msg").html(err);
            // errors.show();
            // let offsetTop = $("#appwizard-componentwizard").offset().top;
            // let offsetBottom = $("#appwizard-footer").outerHeight();
            // if (!utils.isElementInViewport(errors[0], offsetTop, offsetBottom)) {
            //     errors[0].scrollIntoView({ behavior: "smooth" });
            // }
        }
    },
    async validateControls() {
        const controls = this.form.options.model.controls;
        let formDirty = false;
        let errorFields = [];
        for (const control of controls) {
            try {
                if (["parser", "adapter"].includes(control.propertyName)) {
                    const res = await control.view.validate(control?.resourceLabel);
                    if (Array.isArray(res) && res.length > 0) {
                        errorFields = errorFields.concat(res);
                        formDirty = true;
                    }
                } else {
                    try {
                        await control.view.validate(control?.resourceLabel);
                    } catch (error) {
                        formDirty = true;
                        errorFields.push(control.propertyName);
                    }
                }
            } catch (error) {
                console.log("Error while validating controls", error);
            }
        }
        return errorFields;
    },

    onRender: function() {
        ensureRegions(this);
        var form = formBuilderUtils.createForMetadataObject(this.model, {
            autoSave: true,
            displayFields: ["name", "adapter", "parser"]
        });
        this.form = form;
        this.getRegion("formContainer").show(form);
        var that = this;

        this.on("do:next", async function() {
            try {
                let errorFields = await that.validateControls();
                if (errorFields.length > 0) {
                    errorFields = errorFields.map(field => getControlResource(field)?.name ?? field);
                    that.showError(
                        "Fix the following errors in the source configuration<br/>Provide the missing inputs: " +
                            errorFields.join(", ")
                    );
                    return;
                } else {
                    that.showError(null);
                }
            } catch (error) {
                console.log("Error while validation", error);
                $(".error-box-container").hide();
            }

            NProgress.start();
            var streamName = that.model.get("nsName") + ".STREAM." + that.model.get("name") + "_Stream";
            that.model.set("outputStream", streamName);
            that.model.set("outputclause", [
                {
                    outputStream: streamName
                }
            ]);
            that.showError(null);

            that.model
                .save({
                    flow: that.appName + "_SourceFlow"
                })
                .done(function() {
                    App.vent.trigger("appwizard:next:step", null, "configure-target");
                    NProgress.done();
                })
                .fail(function(err) {
                    NProgress.done();
                    that.showError("Error creating component: " + err.message);
                });
        });
    },
    onShow: function() {
        NProgress.configure({
            parent: ".file-wizard"
        });
        App.vent.trigger("appwizard:back:hide");
        setTimeout(function() {
            $(".property-template .from-control-container-Adapter .select2-container").select2("enable", false);
        }, 200); // so the select2 is rendered.
        this.showCustomMessageIfRequired();
    },

    showCustomMessageIfRequired() {
        let template = templates.getByURL(this.options.templateID);
        const SOURCES = ["S3Reader", "HDFSReader", "GCSReader", "ADLSReader"];
        const TARGETS = [
            "BigQueryWriter",
            "SnowflakeWriter",
            "DatabaseWriter",
            "AzureSQLDWHWriter",
            "DeltaLakeWriter",
            "SpannerWriter",
            "SalesforceWriter",
            "ServiceNowWriter",
            "FabricDataWarehouseWriter",
            "SalesforceMarketingCloudWriter",
            "MicrosoftDataverseWriter"
        ];

        if (SOURCES.indexOf(template.uses[0]) !== -1 && TARGETS.indexOf(template.uses[1]) !== -1) {
            const targetTypeKey = template.steps[1];
            const targetTypeName = PredefinedComponentNames[targetTypeKey].name;
            const sourceTypeKey = template.steps[0];
            const sourceTypeName = PredefinedComponentNames[sourceTypeKey].name;
            const message = `Events produced by ${sourceTypeName} Source can not be consumed directly by the ${targetTypeName} Target.
                        Please use Striim Continuous Query (CQ) component in the flow designer to process these events before sending into the ${targetTypeName} Target.
                        Please refer to the Striim documentation on processing such events produced by the ${sourceTypeName} Source and its associated parsers`;
            const root = ReactDOM.createRoot(this.$el.find("#cq-required-message")[0]);
            root.render(
                <StriimTheme>
                    <StriimMessageBox
                        text={message}
                        type="WARNING"
                        heading={"This topology requires further customization"}
                        sx={{ backgroundColor: "#FFA60033" }}
                        className={"warning-messagebox-simplesource"}
                    />
                </StriimTheme>
            );
        }
    },

    setAdapterProperties: function() {
        throw new Error("setAdapterProperties should be implemented. base-simple-source should not be directly used.");
    }
});

module.vent = new Backbone.Model();

export default module;
