import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Box } from "@material-ui/core";
import { ChevronRight, MoreHoriz } from "@material-ui/icons";
import { StriimDropdown, StriimIconButton, StriimTypography, StriimListItem, StriimModal } from "@striim/striim-ui";
import statusManagement, { AppAction } from "src/status-management";
import exceptionStoreApi from "app/components/flow/designer/app-exceptions/exception-store-api";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import AppControl from "app/components/common/app-control/app-control";
import useStores from "src/utils/use-stores";
import useStyles from "../app-tile/app-tile.styles";
import AppGroupSelect from "../app-group-select/app-group-select";
import { formatGroupError } from "src/utils/error-formatter";
import { observer, useObservable } from "mobx-react-lite";
import growl from "app/components/common/growl";
import { SegmentationChip } from "src/modules/common/segmentation";
import DropAppModal from "../../../../../../generic/drop-app-modal/drop-app-modal";
import TableProgressModal from "../../../../../table-progress/table-progress-modal/table-progress-modal";
import DeployModalWrapper from "../../../../../../generic/deploy-modal/deploy-modal-wrapper";
import { Grid, SvgIcon } from "@mui/material";
import { Sherlock } from "../../../../../../generic/icon/customIcons";
import { styles } from "./menu-dropdown.styles";
import { isUserAdmin } from "core/user-management/update.control";
import licenseService from "core/services/metaStoreService/license-service";

const Menu = observer(({ app, onAppAction, applicationGroupsDisabled, dropApplication }) => {
    const classes = useStyles(app);
    const { store, groupsStore } = useStores();
    const groupSelectState = useState(false);
    const menuState = useState(false);
    const [applicationGroupsDisabledModal, setApplicationGroupsDisabledModal] = useState(false);
    const [exceptionsStoreEnabled, setExceptionsStoreEnabled] = useState(false);
    const [doNotDeleteExceptionStore, setDoNotDeleteExceptionStore] = useState(false);
    const [dropConfirmVisible, setDropConfirmVisible] = useState(false);
    const [appDeployVisible, setAppDeployVisible] = useState(false);
    let isILCDC = useMemo(() => groupsStore.getGroupByAppId(app.id)?.groupType === "ILCDC", [app]);
    const [isTableProgressVisible, setIsTableProgressVisible] = useState(false);
    const [appModel, setAppModel] = useState(null);
    const isAdmin = isUserAdmin();
    //TODO: Update and add data-test-ids

    const openTablePropgress = async () => {
        const app_model = await metaStoreService.findById(app?.id);
        setAppModel(app_model);
        setIsTableProgressVisible(true);
    };
    async function handleClose(event, action) {
        const appId = app.id;
        onAppAction(action);
        if (action === AppAction.DROP) {
            exceptionStoreApi.checkExceptionsEnabled(appId).then(isEnabled => {
                setExceptionsStoreEnabled(isEnabled);
                setDoNotDeleteExceptionStore(false);
                setDropConfirmVisible(true);
            });
        } else if (action === AppAction.DEPLOY) {
            // deploy will handled entirely by the deploy modal
            setAppDeployVisible(true);
        } else if (action === AppAction.CHANGE_GROUP) {
            if (applicationGroupsDisabled) {
                setApplicationGroupsDisabledModal(true);
                return;
            }
            groupSelectState[1](false);
            menuState[1](false);
        } else if (action === AppAction.EXPORT) {
            metaStoreService.findById(appId).then(app => {
                const appControl = new AppControl({
                    appIdentifier: app
                });
                appControl.export();
            });
        } else if (typeof action === "string") {
            await store.onAppAction(app, action);
        }
    }

    async function onDrop(app, doNotDeleteExceptionStore) {
        setDropConfirmVisible(false);
        dropApplication(app, doNotDeleteExceptionStore);
    }

    async function onSelectClose(selectedGroup) {
        if (!selectedGroup) {
            groupSelectState[1](false);
            return;
        }
        try {
            await groupsStore.assignToGroup([app], selectedGroup.name, selectedGroup.description);
            groupSelectState[1](false);
            menuState[1](false);
        } catch (error) {
            const message = formatGroupError(error, selectedGroup.name);
            growl.error(message, "Error creating group");
        }
    }

    const exportApp = () => {
        setDropConfirmVisible(false);
        handleClose(null, AppAction.EXPORT);
    };

    const getMenuVisibility = (status, isILCDC) => app.statusTransitions?.actions.includes(status) && !isILCDC;

    const menu = (
        <div data-test-id={app.nsName + "-" + app.name + "-menu"} style={{ width: "230px" }}>
            <StriimListItem
                onClick={e => {
                    handleClose(e, AppAction.VIEW);
                }}
                text="Manage flow"
                divider={false}
            />
            <StriimListItem
                onClick={e => {
                    handleClose(e, AppAction.MONITOR);
                }}
                text="Monitor App"
                divider={!isILCDC}
            />
            {isILCDC && <StriimListItem text="View Table progress" onClick={openTablePropgress} />}
            {isAdmin && !licenseService?.isAIInsightsDisabled && (
                <StriimListItem
                    onClick={e => {
                        handleClose(e, AppAction.SHERLOCK);
                    }}
                    text={
                        <Grid display={"flex"} flexDirection={"column"} gap={1 / 2}>
                            <StriimTypography variant="caption" color="greyscale.700">
                                Discover Sensitive Data
                            </StriimTypography>
                            <Grid display={"flex"} alignItems={"center"} gap={1 / 2}>
                                <SvgIcon component={Sherlock} sx={styles.aiIcon} />
                                <StriimTypography variant="caption4" sx={styles.aiText}>
                                    Sherlock AI
                                </StriimTypography>
                            </Grid>
                        </Grid>
                    }
                    divider={true}
                />
            )}

            {getMenuVisibility(AppAction.DEPLOY, isILCDC) && (
                <StriimListItem
                    onClick={e => {
                        handleClose(e, AppAction.DEPLOY);
                    }}
                    text="Deploy"
                />
            )}
            {getMenuVisibility(AppAction.UNDEPLOY, isILCDC) && (
                <>
                    {getMenuVisibility(AppAction.RESUME, isILCDC) ? (
                        <StriimListItem
                            onClick={e => {
                                handleClose(e, AppAction.RESUME);
                            }}
                            text="Resume"
                            divider={false}
                        />
                    ) : (
                        <StriimListItem
                            onClick={e => {
                                handleClose(e, AppAction.START);
                            }}
                            text="Start"
                            divider={false}
                        />
                    )}
                </>
            )}
            {app.statusTransitions?.actions.includes(AppAction.SHOW_ERRORS) && (
                <StriimListItem
                    onClick={e => {
                        handleClose(e, AppAction.SHOW_ERRORS);
                    }}
                    text="Show Errors"
                    divider={false}
                />
            )}
            {getMenuVisibility(AppAction.UNDEPLOY, isILCDC) && (
                <StriimListItem
                    onClick={e => {
                        handleClose(e, AppAction.UNDEPLOY);
                    }}
                    text="Undeploy"
                />
            )}
            {getMenuVisibility(AppAction.QUIESCE, isILCDC) && (
                <StriimListItem
                    onClick={e => {
                        handleClose(e, AppAction.QUIESCE);
                    }}
                    text="Quiesce"
                    divider={false}
                />
            )}
            {getMenuVisibility(AppAction.STOP, isILCDC) && (
                <StriimListItem
                    onClick={e => {
                        handleClose(e, AppAction.STOP);
                    }}
                    text="Stop"
                />
            )}
            {!isILCDC && (
                <>
                    {applicationGroupsDisabled ? (
                        <StriimListItem
                            onClick={e => {
                                handleClose(e, AppAction.CHANGE_GROUP);
                            }}
                            text={"Move to a group"}
                            divider={false}
                            secondaryAction={
                                <Box display="flex">
                                    <SegmentationChip variant="addOn" />
                                    <ChevronRight />
                                </Box>
                            }
                        />
                    ) : (
                        <div
                            onClick={e => e.stopPropagation()} //to prevent exec handleClose handler
                            onMouseDown={e => e.stopPropagation()} //to prevent focus animation on menuitem
                        >
                            <StriimDropdown
                                TooltipProps={{ classes: { tooltip: classes.tooltipWrapper } }}
                                controlState={groupSelectState}
                                content={
                                    <AppGroupSelect
                                        onClose={onSelectClose}
                                        groups={groupsStore.groups}
                                        applicationId={app.id}
                                    />
                                }
                                ContainerProps={{
                                    style: { width: "auto" }
                                }}
                            >
                                <StriimListItem
                                    onClick={e => {
                                        handleClose(e, AppAction.CHANGE_GROUP);
                                    }}
                                    text={"Move to a group"}
                                    divider={false}
                                    secondaryAction={
                                        <Box display="flex" justifyContent="flex-end">
                                            <ChevronRight />
                                        </Box>
                                    }
                                />
                            </StriimDropdown>
                        </div>
                    )}
                </>
            )}
            <StriimListItem
                onClick={e => {
                    handleClose(e, AppAction.EXPORT);
                }}
                text="Export to TQL"
            />
            {!isILCDC && (
                <StriimListItem
                    onClick={e => {
                        handleClose(e, AppAction.DROP);
                    }}
                    text="Drop"
                    disabled={!statusManagement.isApplicationCreated(app.flowStatus)}
                    divider={false}
                />
            )}
        </div>
    );

    return (
        <div
            onClick={e => e.stopPropagation()} //to prevent app selection
            className={app.isFetching ? classes.appActionDisabled : null}
            data-test-id={app.nsName + "-" + app.name + "-open-menu"}
        >
            {appModel && isTableProgressVisible && (
                <TableProgressModal
                    appModel={appModel}
                    setTableProgressVisible={setIsTableProgressVisible}
                    isTableProgressVisible={isTableProgressVisible}
                />
            )}
            <StriimDropdown
                extendOnClick
                closeOnSelect
                controlState={menuState}
                onClose={() => {
                    menuState[1](false);
                    groupSelectState[1](false);
                }}
                content={menu}
            >
                <StriimIconButton disabled={app.isFetching}>
                    <Box component={MoreHoriz} color="text.secondary" />
                </StriimIconButton>
            </StriimDropdown>
            <DropAppModal
                store={store}
                app={app}
                dropModalVisible={dropConfirmVisible}
                setDropModalVisible={setDropConfirmVisible}
                onConfirmDrop={onDrop}
            />
            <DeployModalWrapper appId={app.id} isVisible={appDeployVisible} setIsVisible={setAppDeployVisible} />
        </div>
    );
});

Menu.propTypes = {
    app: PropTypes.object,
    applicationGroupsDisabled: PropTypes.bool
};

export default Menu;
