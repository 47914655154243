import eventsLogSearchFieldTemplate from "./templates/eventsLogSearchFieldTemplate.html";
import { ItemView } from "marionette";
import { Model } from "backbone.nestedtypes";
import { className, template, on, ui, modelEvent } from "core/decorators";

@ui({
    input: "._searchContent",
    clear: "._closeIcon"
})
@className("search-field")
@template(eventsLogSearchFieldTemplate)
class View extends ItemView {
    @modelEvent("change:value")
    refreshClear() {
        if (this.model.value) {
            this.ui.clear.show();
            this.ui.input.val(this.model.value);
        } else {
            this.ui.clear.hide();
            this.ui.input.val("");
        }
    }

    @on("click @ui.clear")
    onClear() {
        this.model.value = null;
    }

    @on("keyup @ui.input")
    onInputKeyup(e) {
        const ESC_KEY = 27;

        if (e.which === ESC_KEY) {
            this.onClear();
        }
    }

    @on("change @ui.input")
    onInputChange() {
        this.model.value = this.ui.input.val().trim();
    }
}

export default {
    Model: Model.extend({
        defaults: {
            value: String,
        },
    }),
    View: View,
};
