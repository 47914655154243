import React, { useEffect, useCallback, useState } from "react";
import { Box, CardHeader, CardContent, SvgIcon } from "@mui/material";
import { AddCircleOutlineRounded } from "@material-ui/icons";
import { Documentation } from "../../../../../generic/icon/customIcons";
import {
    PropertyVariablesEmpty,
    PropertyVariablesPresent,
    PropertyVariablesService,
    AddPropertyVariableModal
} from "../property-variables";
import growl from "app/components/common/growl";
import LoadingIndicator from "../../../../../generic/loading-indicator/loading-indicator";
import { StriimTypography, StriimButton } from "@striim/striim-ui-v2";
import Dictionary from "app/components/common/helpable/online-help-dictionary";
import { Card } from "../components";

const styles = {
    cardHeaderStyles: {
        p: 0
    },
    contentStyles: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        pt: 0
    },
    iconStyles: {
        "& svg": {
            fill: "none"
        }
    },
    titleStyles: { display: "flex", alignItems: "center", gap: 1 }
};

const PropertyVariablesPage = () => {
    const [isModalShown, setIsModalShown] = useState(false);
    const [propertyVariables, setPropertyVariables] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const onRemoved = propertyVariable => {
        PropertyVariablesService.deletePropertyVariable(propertyVariable)
            .then(() => {
                growl.success("Property variable removed successfully");
                refresh();
            })
            .catch(e => {
                growl.error("Error removing property variable: " + e);
            });
    };

    const getComponent = useCallback(() => {
        if (isLoading) {
            return <LoadingIndicator isGlobal={false} />;
        } else if (propertyVariables?.length) {
            return (
                <PropertyVariablesPresent
                    propertyVariables={propertyVariables}
                    onAdded={refresh}
                    onRemoved={onRemoved}
                />
            );
        } else {
            return <PropertyVariablesEmpty onAdded={refresh} />;
        }
    }, [isLoading, propertyVariables]);

    const refresh = () => {
        setIsLoading(true);
        return PropertyVariablesService.getPropertyVariables()
            .then(propertyVariables => {
                setPropertyVariables(propertyVariables);
            })
            .finally(() => {
                setIsLoading(false);
            })
            .catch(e => {
                growl.error("Error fetching property variables: " + e);
            });
    };

    useEffect(() => {
        refresh();
    }, []);

    return (
        <Card>
            <CardHeader
                sx={styles.cardHeaderStyles}
                disableTypography
                title={
                    <Box sx={styles.titleStyles}>
                        <StriimTypography variant="h2" color="primary.700">
                            Property Variables
                        </StriimTypography>
                        <SvgIcon
                            sx={styles.iconStyles}
                            component={"a"}
                            href={Dictionary.get()["MANAGE_STRIIM_PROPERTY_VARIABLES"].href}
                            target="_blank"
                        >
                            <Documentation />
                        </SvgIcon>
                    </Box>
                }
                subheader={
                    propertyVariables?.length ? (
                        <div style={{ maxWidth: "800px", fontSize: 12 }}>
                            <StriimTypography variant="body4" color="greyscale.600">
                                Property variables allow you to store values for adapter properties in an encrypted
                                form, so they may be used as passwords and tokens applications without sharing the clear
                                text with users.
                            </StriimTypography>
                        </div>
                    ) : null
                }
                action={
                    propertyVariables?.length ? (
                        <StriimButton
                            variant="primary"
                            startIcon={<AddCircleOutlineRounded />}
                            onClick={() => setIsModalShown(true)}
                            data-test-id="add-property-variable-button"
                        >
                            New Property Variable
                        </StriimButton>
                    ) : null
                }
            />
            <CardContent sx={styles.contentStyles}>{getComponent()}</CardContent>
            {isModalShown && <AddPropertyVariableModal setIsModalShown={setIsModalShown} onAdded={refresh} />}
        </Card>
    );
};

export default PropertyVariablesPage;
