import React, { ReactElement } from "react";
import { StriimForm, StriimInputField, StriimTypography, StriimButton } from "@striim/striim-ui";
import { Grid } from "@mui/material";
import NamespaceSelect from "../../../../dashboards/pages/dashboard-list/components/add-dashboard-dialog/components/namespace-select/namespace-select";
import { getValidationSchema } from "../../create-app-name-app-page/create-app-name-app-page";
export interface AppTypeInfoProps {
    title: string;
    content: ReactElement;
    img_src: string;
    preview: string;
}

interface PropTypes {
    createAppStore: any;
    currentAppTypeInfo: AppTypeInfoProps;
    currentAdapter: string;
    handleSubmit: () => void;
    handleCancel: () => void;
    trackEventClicked: () => void;
}

const styles = {
    actionButtonContainer: {
        display: "flex"
    },
    nameField: {
        pb: 2
    },
    passwordField: {
        pt: 2
    }
};

// create app page only for IL/CDC
const CreateAppNameAppForm: React.FunctionComponent<PropTypes> = ({
    createAppStore,
    currentAppTypeInfo,
    currentAdapter,
    handleSubmit,
    handleCancel,
    trackEventClicked
}) => {
    const entityType = "APPLICATION";

    const initialValues = {
        name: createAppStore.initialName,
        nsName: createAppStore.namespaceFromAppName || currentAdapter || createAppStore.initialNamespace,
        password: ""
    };

    const namespaceOptions = createAppStore.namespaceFromTql
        ? [
              {
                  label: createAppStore.namespaceFromTql,
                  value: createAppStore.namespaceFromTql
              },
              ...(currentAdapter ? [{ label: currentAdapter, value: currentAdapter }] : [])
          ]
        : createAppStore.namespaceFromAppName
        ? [
              {
                  label: createAppStore.namespaceFromAppName,
                  value: createAppStore.namespaceFromAppName
              },
              ...(currentAdapter ? [{ label: currentAdapter, value: currentAdapter }] : [])
          ]
        : [...(currentAdapter ? [{ label: currentAdapter, value: currentAdapter }] : [])];

    return (
        <StriimForm
            values={initialValues}
            validateOnMount={true}
            validationSchema={getValidationSchema(createAppStore.createFromScratch, entityType, true)}
            onSubmit={handleSubmit}
            initialTouched={{
                name: true
            }}
            validateOnChange
        >
            {() => {
                return (
                    <Grid container gap={3} flexDirection={"column"}>
                        <StriimTypography variant="h2" color="primary.700" data-test-id="create-app-header">
                            {currentAppTypeInfo.title}
                        </StriimTypography>
                        {currentAppTypeInfo.content}
                        <Grid item>
                            {createAppStore.createFromScratch && (
                                <StriimInputField
                                    required
                                    name="name"
                                    label="Name"
                                    isFormElement
                                    disabled={createAppStore.inProgress}
                                    sx={styles.nameField}
                                    id="data-test-id-name-app"
                                />
                            )}
                            <NamespaceSelect
                                required
                                name="nsName"
                                label="Namespace"
                                disabled={
                                    createAppStore.inProgress ||
                                    (!createAppStore.createFromScratch &&
                                        (!!createAppStore.namespaceFromTql || !!createAppStore.namespaceFromAppName))
                                }
                                doNotCreateNamespaces
                                namespaceOptions={namespaceOptions}
                            />
                            {!createAppStore.createFromScratch && (
                                <StriimInputField
                                    name="password"
                                    label="Passphrase for passwords"
                                    helperText="All passwords are encrypted using a passphrase during export. If this application does not have any encrypted passwords, leave this value empty."
                                    isFormElement
                                    disabled={createAppStore.inProgress}
                                    sx={styles.passwordField}
                                />
                            )}
                        </Grid>
                        <Grid item ml="auto" gap={1} sx={styles.actionButtonContainer}>
                            <StriimButton
                                variant="secondary"
                                data-test-id="cancel-app-button"
                                disabled={createAppStore.inProgress}
                                onClick={handleCancel}
                            >
                                Cancel
                            </StriimButton>
                            <StriimButton
                                type="submit"
                                variant="primary"
                                data-test-id={
                                    createAppStore.createFromScratch ? "submit-app-button" : "import-app-button"
                                }
                                disabled={createAppStore.inProgress}
                                onClick={trackEventClicked}
                            >
                                {createAppStore.createFromScratch
                                    ? createAppStore.createFromWizard
                                        ? "Next"
                                        : "Save"
                                    : "Import"}
                            </StriimButton>
                        </Grid>
                    </Grid>
                );
            }}
        </StriimForm>
    );
};

export default CreateAppNameAppForm;
