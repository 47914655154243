import React from "react";
import { Divider, Grid, SvgIcon } from "@mui/material";
import { StriimTypography, StriimButton } from "@striim/striim-ui";
import InfoIcon from "app/images/striimline/info-icon.svg";
import { Close, Sentinel, SidebarCollapse } from "../../../../../generic/icon/customIcons";
import { styles } from "./panel-header.styles";
import WithTooltip from "../../../../../generic/tooltip/tooltip";

interface PanelHeaderProps {
    isPanelExpanded: boolean;
    onToggleFullScreen: Function;
    onClose: Function;
    isEmptyView?: boolean;
}

const PanelHeader: React.FC<PanelHeaderProps> = ({
    isPanelExpanded,
    onToggleFullScreen,
    onClose,
    isEmptyView = false
}) => {
    return (
        <>
            <Grid container justifyContent={"space-between"} alignItems={"center"} p={2}>
                <Grid display={"flex"} item alignItems={"center"} gap={1}>
                    <SvgIcon sx={styles.sentinelIcon}>
                        <Sentinel />
                    </SvgIcon>
                    <StriimTypography variant="h3" color="primary.700">
                        Sentinel AI Activity
                    </StriimTypography>
                    {WithTooltip(
                        <SvgIcon component={InfoIcon} sx={styles.iconInfo} />,
                        <>Sentinel uses AI engines that may sometimes misclassify the information.</>
                    )}
                </Grid>
                <Grid display={"flex"} item gap={1}>
                    {!isEmptyView && (
                        <StriimButton
                            variant="text"
                            startIcon={
                                <SvgIcon sx={styles.startIcon}>
                                    <SidebarCollapse />
                                </SvgIcon>
                            }
                            sx={{ ...styles.iconButton, ...(isPanelExpanded && styles.expanded) }}
                            onClick={() => onToggleFullScreen(true)}
                        />
                    )}
                    <StriimButton
                        variant="text"
                        startIcon={
                            <SvgIcon sx={[styles.startIcon, styles.closeIcon]}>
                                <Close />
                            </SvgIcon>
                        }
                        sx={styles.iconButton}
                        onClick={onClose}
                    />
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ width: "100%", borderColor: "greyscale.200" }} />
        </>
    );
};

export default PanelHeader;
