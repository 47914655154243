import App from "app";
import $ from "jquery";
import _ from "underscore";
import growl from "app/components/common/growl";
import dialog_template_html from "./vis-editor-dialog.html";
import vis_template_html from "./vis-editor.html";
import helpableMixin from "app/components/common/helpable/helpable";
import queryService from "core/services/dataService/queryService";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import dataVisualizationsEditors from "core/services/metadataService/dataVisualizationsEditors";
import "app/components/dashboard/editors/propertieseditor";
import "app/components/common/_deprecated/form-mixin-dialog/dialog";
import "lib/vendor/jquery.event.drag-drop";
import "select2";
import { mousePos } from "../../common/drag-and-drop";
import ensureRegions from "../../common/_ensureRegions";

// Select2 customized to work via drag-and-drop.
// If needed globally, can be moved to its own file
$.fn.droppableSelect = function(opts) {
    opts || (opts = {});
    _(opts).defaults({
        allow_blank: true
    });
    return $(this).each(function() {
        var $orig = $(this);
        $orig.select2({
            allowClear: opts.allow_blank,
            containerCssClass: "droppable-field",
            dropdownCssClass: "droppable-field light"
        });
        var $custom = $orig.select2("container");

        $custom.find(".select2-choice").append(
            $("<div />", {
                class: "droppable-placeholder",
                "data-test-id": "drag-or-select-field",
                html: opts.placeholder
            })
        );
        $custom
            .drop("start", function(ev, dd) {
                $(dd.proxy).addClass("dropstart " + opts.id);
                $(this).addClass("active drop dropstart " + opts.id);
            })
            .drop("drop", function(ev, dd) {
                $(this).removeClass("drop");
                $orig.select2("val", dd.value);
                $orig.trigger("change");
            })
            .drop("end", function(ev, dd) {
                var _this = this;
                $(this).removeClass("active dropstart " + opts.id);
                $(dd.proxy).removeClass("dropstart " + opts.id);
                // Wait for animation to complete
                _(function() {
                    $(_this).removeClass("drop");
                }).delay(200);
            });
    });
};

App.module("Dashboard.VisEditor", function(VisEditor, App, Backbone, Marionette, $, _) {
    var FieldTile = Marionette.ItemView.extend({
        className: "field-tile",
        template: _.template('<div class="inner"><span class="field-name">{{ field }}</span></div>'),
        initialize: function() {
            this.$el.data("id", this.model.field);
        },
        onRender: function() {
            var _this = this;
            this.$el
                .drag("init", function(ev, dd) {
                    dd.value = $(this).data("id");
                })
                .drag("start", function() {
                    var $proxy = $(this)
                        .clone()
                        .css({
                            width: $(this).outerWidth(),
                            height: $(this).outerHeight()
                        })
                        .addClass("proxy")
                        .appendTo(_this.options.proxy_container);

                    $(this).addClass("lifted");
                    return $proxy;
                })
                .drag("drag", function(ev, dd) {
                    $(dd.proxy).css({
                        top: mousePos.clientY - $(this).outerHeight() * 0.5,
                        left: mousePos.clientX - $(this).outerWidth() * 0.5 //dd.offsetX
                    });
                })
                .drag("end", function(ev, dd) {
                    $(this).removeClass("lifted");
                    $(dd.proxy).remove();
                });
        }
    });

    var VisEditorSidebar = Marionette.CollectionView.extend({
        className: "fields-sidebar-list",
        childView: FieldTile
    });

    var VisEditorBody = Marionette.LayoutView.extend({
        className: "editor-body",
        template: _.template(vis_template_html),
        regions: {
            fields: ".fields-sidebar",
            body: ".editor-content",
            content: "#vis-form"
        },
        onRender: function() {
            ensureRegions(this);
            var p;
            var query = new queryService.WAQueryModel();
            if (this.model.visualization.query.id !== "") {
                query = this.model.visualization.query;
                p = query.fetch();
            } else {
                p = $.Deferred();
                p.resolve();
            }

            p.then(
                function() {
                    // Render the configuration form inputs themselves
                    var vismodel = this.model.visualization;

                    if (this.isDestroyed) {
                        return;
                    }

                    this.getRegion("fields").show(
                        new VisEditorSidebar({
                            collection: query.fields,
                            childViewOptions: {
                                proxy_container: this.el
                            }
                        })
                    );

                    // Keeping editor orthogonal for now
                    var editor_json = dataVisualizations[vismodel.visualizationType].editor;

                    // Kick off rendering the editors for this DataVisualization
                    var EditorsView = new App.Dashboard.VisEditor[dataVisualizations.EDITOR.PROPERTIES]({
                        model: new dataVisualizationsEditors.EditorViewModel({
                            _queryvisualization: this.model.visualization,
                            _datavisualization: vismodel,
                            _parent: vismodel,
                            _theme: vismodel.theme,
                            editor: new dataVisualizationsEditors.PropertyEditor(editor_json),
                            config: vismodel.config
                        })
                    });

                    this.getRegion("content").show(EditorsView);
                }.bind(this)
            );
        },
        set_height: function() {
            var bodyheight = Math.max(100, $(window).height() - 200);
            this.$el.css("height", bodyheight);
        },
        onShow: function() {
            this.set_height();
            this.listenTo(App.vent, "window:resize", this.set_height);
        }
    });

    // The VisEditor extends Dialog, which is a combination of a Modal and a Form.
    VisEditor.View = App.FormMixinDialog.View.extend(helpableMixin).extend({
        template: _.template(dialog_template_html),
        regions: {
            content: ".modal-body .inner"
        },

        getHelpID: function() {
            var visualizationType = this.model.get("visualization").get("visualizationType");
            return "DASHBOARD-" + visualizationType + "";
        },
        getHelperContentElement: function() {
            return this.$el.find(".modalTitle");
        },
        initialize: function(options) {
            options.autosave = false;
            options.classes = "large dashboard-edit-visualization";
            options.origin = false;

            // Because we are directly extending, rather than mixing in, we need to explicitly call super
            App.FormMixinDialog.View.prototype.initialize.call(this, options);

            // Hook up the submit button
            this.triggers["click .submit"] = "submit";
            this.delegateEvents();

            var that = this;
            this.listenTo(this, "submit", function() {
                var validationFunction = dataVisualizations[this.model.visualization.visualizationType].editor.validate;
                if (validationFunction) {
                    var validationResult = validationFunction(that.model.visualization.config);
                    if (validationResult) {
                        growl.error(validationResult, "Validation error");
                        return;
                    }
                }

                that.submit();
            });
            helpableMixin.initialize.apply(this, arguments);
        },

        onRender: function() {
            ensureRegions(this);
            // This editor provides .modal-inner inside the modal, to help with padding
            this.$el.removeClass("modal-inner");

            this.listenTo(this, "show", function() {
                var type = this.model.visualization.visualizationType;
                this.$el.addClass(type.toLowerCase());
                var modal_body = new VisEditorBody({
                    model: this.model
                });
                this.getRegion("content").show(modal_body);
            });
        },
        serialize: function() {
            // Do nothing. Title and query are no longer edited here.
            return {};
        },
        update: function(formdata) {
            // Subviews maintain the model's vis configuration parts, except the title and query
            this.model.visualization.set(formdata);
        }
    });
    // Extend Dialog controller
    VisEditor.Controller = App.FormMixinDialog.Controller;
});
