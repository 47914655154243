import React, { memo, useEffect, useMemo, useState } from "react";
import { Grid, Box, SvgIcon, ListItemText } from "@mui/material";
import {
    StriimTypography,
    StriimButton,
    StriimMessageBox,
    StriimModal,
    StriimList,
    StriimListItem
} from "@striim/striim-ui";
import numeral from "numeral";
import xss from "xss";

import useStores from "../../../../../utils/use-stores";
import JobAppsTableFilter from "../job-apps-table/job-apps-table-filter";
import JobAppsTable from "../job-apps-table/job-apps-table";
import JobInsightsChart from "../job-insights-chart/job-insights-chart";
import { Job, JobMetrics, JobStatuses } from "../../guardian-job.types";
import InsightsInfoBox from "../insights-info-box/insights-info-box";
import {
    App,
    ArrowBack,
    ChevronRight,
    HelpCircle,
    LockOpen,
    TransitionBottom
} from "../../../../../generic/icon/customIcons";
import SensitiveDataIdentifierChart from "../sensitive-data-identifier-chart/sensitive-data-identifier-chart";
import { AppDataStatus, ViewType } from "../../pages/guardian-view-app";
import navigateTo from "../../../../../navigate-to";
import SourceSensitiveDataTable from "../source-sensitive-data-table/source-sensitive-data-table";
import { styles } from "../../guardian.styles";
import { viewAllTypes } from "../view-all-page/view-all-content";
import api from "../../../../../../core/api/api";
import dictionary from "../../../../../../app/components/common/helpable/online-help-dictionary";
import { StriimLink } from "@striim/striim-ui-v2";
import { formatMessage } from "../../../../../../app/components/flow/designer/appErrors/appExceptions";

export const TABS = {
    LIST: "List",
    INSIGHTS: "Insights"
};

export const appSort = (a, b) => (a.id?.toLowerCase() > b.id?.toLowerCase() ? 1 : -1);

export const getTopAppsData = (tableData: AppProps[]) => {
    const data = tableData
        .sort((x, y) => y.entitiesWithSensitiveData?.completed - x.entitiesWithSensitiveData?.completed)
        .map(item => {
            return {
                name: `${item.nsName}.${item.name}`,
                count: item.entitiesWithSensitiveData?.completed
            };
        });
    return data;
};

export const isApplicationAvailable = async app => {
    try {
        const appFQN = app.split(".").join(".APPLICATION.");
        const result = await api.getApplicationStatus([appFQN]);
        return !!result;
    } catch (error) {
        return false;
    }
};

interface JobListInsightsViewProps {
    jobName: string;
    jobs: Job[];
    appDataStatus: AppDataStatus;
    status: JobStatuses;
    setViewAllView?: React.Dispatch<React.SetStateAction<boolean>>;
    setViewType?: React.Dispatch<React.SetStateAction<ViewType>>;
    appsDetails?: {
        source: {
            name: string;
            className: string;
        };
        sensitiveApps: number;
        sensitiveEntities: number;
    }[];
    setAllTableData?: React.Dispatch<React.SetStateAction<AppProps[]>>;
    setAppsWithSDI?: React.Dispatch<React.SetStateAction<AppProps[]>>;
    completedTime?: number;
    defaultSelectedTab?: string;
    errorMessages?: string[];
    isJobPending: boolean;
    handleRetry: Function;
}

export const ErrorBox = ({ messages, setShowErrorsModal, setShowSelectedErrorModal, setSelectedError }) => {
    return (
        <Box sx={styles.errorBox}>
            <StriimMessageBox
                text={
                    <StriimTypography variant="body4" color="greyscale.900">
                        {messages?.length === 1
                            ? `Sherlock AI encountered an error during the run. Address the error and retry
                        running the report.`
                            : `Sherlock AI encountered ${messages?.length} errors during the run. Address these errors and retry
                        running the report.`}
                        {/*TODO: Use only when we have the troubleshooting guide.
                        <StriimLink endIcon={<NewTab style={styles.newTabIcon} />} sx={styles.learnLink}>
                            <StriimTypography color="secondary.500" variant="caption3">
                                Troubleshooting guide
                            </StriimTypography>
                        </StriimLink> */}
                    </StriimTypography>
                }
                type="ERROR"
                actions={
                    <StriimButton
                        variant="secondary"
                        onClick={() => {
                            if (messages.length > 1) {
                                setShowErrorsModal(true);
                            } else {
                                setShowSelectedErrorModal(true);
                                setSelectedError({ index: "", description: messages[0] });
                            }
                        }}
                        sx={{ ml: "35px" }}
                    >
                        Details
                    </StriimButton>
                }
            />
        </Box>
    );
};

export const ErrorMessagesModal = ({
    jobName,
    messages,
    showErrorsModal,
    setShowErrorsModal,
    setShowSelectedErrorModal,
    setSelectedError,
    handleRetry,
    isDiscoverPIIPanel = false
}) => {
    return (
        <StriimModal
            autoHeight
            disableMaxHeight
            isVisible={showErrorsModal}
            size="medium"
            footerBorder
            titleContent={
                <>
                    <StriimTypography
                        variant="h2"
                        color="primary.700"
                    >{`Errors in ${jobName} (${messages.length})`}</StriimTypography>
                </>
            }
            footerContent={
                <>
                    {!isDiscoverPIIPanel && (
                        <StriimButton onClick={handleRetry} variant="text">
                            Retry
                        </StriimButton>
                    )}
                    <StriimButton
                        variant="primary"
                        onClick={() => {
                            setShowErrorsModal(false);
                        }}
                    >
                        Cancel
                    </StriimButton>
                </>
            }
        >
            <StriimList>
                {messages.map((message, index) => (
                    <StriimListItem
                        key={index}
                        onClick={() => {
                            setSelectedError({ index: index, description: message });
                            setShowErrorsModal(false);
                            setShowSelectedErrorModal(true);
                        }}
                        sx={styles.errorsList}
                    >
                        <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                            <ListItemText>
                                <StriimTypography variant="body4" color="greyscale.900">
                                    Error{index + 1}
                                </StriimTypography>
                            </ListItemText>
                        </Box>
                        <ChevronRight />
                    </StriimListItem>
                ))}
            </StriimList>
        </StriimModal>
    );
};

export const SelectedErrorModal = ({
    selectedError,
    showSelectedErrorModal,
    setShowSelectedErrorModal,
    setShowErrorsModal,
    messages,
    handleRetry,
    isDiscoverPIIPanel = false
}) => {
    let parsedText,
        formattedMessage = "";
    try {
        parsedText = JSON.parse(selectedError?.description)?.[0];
    } catch (error) {
        console.log(error);
    }
    try {
        if (!parsedText) {
            const startIndex = selectedError?.description?.indexOf("{");
            if (startIndex !== -1) {
                parsedText = JSON.parse(selectedError?.description.substring(startIndex));
            }
        }
        formattedMessage = formatMessage(parsedText?.message);
    } catch (error) {
        console.log(error);
        formattedMessage = selectedError?.description;
    }

    return (
        <StriimModal
            autoHeight
            disableMaxHeight
            isVisible={showSelectedErrorModal}
            size="medium"
            footerBorder
            dialogContentProps={{
                style: {
                    overflow: "hidden"
                }
            }}
            titleContent={
                <Grid container flexDirection={"row"} gap={1} mb={2}>
                    {messages.length > 1 && (
                        <SvgIcon
                            component={ArrowBack}
                            onClick={() => {
                                setShowSelectedErrorModal(false);
                                setShowErrorsModal(true);
                            }}
                        />
                    )}
                    <StriimTypography variant="h2" color="primary.700">
                        Error{selectedError?.index !== "" ? selectedError?.index + 1 : ""}
                    </StriimTypography>
                </Grid>
            }
            footerContent={
                <>
                    <StriimButton onClick={handleRetry} variant="text">
                        Retry
                    </StriimButton>

                    <StriimButton
                        variant="primary"
                        onClick={() => {
                            setShowSelectedErrorModal(false);
                        }}
                    >
                        Cancel
                    </StriimButton>
                </>
            }
        >
            <Grid container flexDirection={"column"} gap={3}>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <StriimTypography variant="h4">Details</StriimTypography>
                    <div
                        style={{ wordBreak: "break-word" }}
                        dangerouslySetInnerHTML={{ __html: xss(formattedMessage) }}
                    />
                </Box>
            </Grid>
        </StriimModal>
    );
};

const JobListInsightsView = memo(
    ({
        jobName,
        jobs,
        appDataStatus,
        status,
        setViewAllView,
        setViewType,
        appsDetails,
        setAllTableData,
        completedTime,
        defaultSelectedTab,
        errorMessages,
        setAppsWithSDI,
        isJobPending,
        handleRetry
    }: JobListInsightsViewProps) => {
        const { store } = useStores();
        const [apps, setApps] = useState<AppProps[]>(store.apps);
        const [tableData, setTableData] = useState<AppProps[]>(apps);
        const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);
        const [showAppUnavailableModal, setShowAppUnavailableModal] = useState<boolean>(false);
        const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);
        const [showSelectedErrorModal, setShowSelectedErrorModal] = useState<boolean>(false);
        const [selectedError, setSelectedError] = useState<{ index: number; description: string } | null>(null);

        const updatedData = jobs.reduce((acc, curr) => {
            let result = {};
            let piiData = {};
            let metrics: JobMetrics = { sensitiveEntities: 0, totalEntities: 0, sensitiveIdentifiers: [] };
            try {
                piiData = JSON.parse(curr.piiData);
                metrics = !!curr.metrics ? JSON.parse(curr.metrics) : metrics;
            } catch (error) {
                console.log(error);
            }
            const appName = curr.appName;
            result["jobStatus"] = {
                status: curr.status,
                generationTime: Number(curr.generationTime),
                completedTime: Number(curr?.completedTime || 0),
                errorMessage: curr.otherDetails
            };
            result["entitiesWithSensitiveData"] = {
                completed: metrics?.sensitiveEntities,
                total: metrics?.totalEntities
            };
            result["piiData"] = curr.piiData;
            acc[appName] = result;
            return acc;
        }, {});
        const appNames = Object.keys(updatedData);

        const appsList: AppProps[] = useMemo(() => {
            return appNames.map(app => {
                let [appNamespace, appName] = app.split(".");
                const item = updatedData[app];
                const appDetails = apps?.find(item => `${item.nsName}.${item.name}` === app);
                return {
                    ...(appDetails ? appDetails : { name: appName, nsName: appNamespace }),
                    jobStatus: item.jobStatus,
                    status: item.status,
                    entitiesWithSensitiveData: item.entitiesWithSensitiveData,
                    piiData: item.piiData,
                    isAppAvailable: !!appDetails
                };
            });
        }, [apps, updatedData, status]);

        useEffect(() => {
            const fetchData = async () => {
                await store.fetchApps();
                await store.getAppListTableStats();
                setApps(store.apps.sort(appSort));
            };
            fetchData();
        }, [store]);

        useEffect(() => {
            setTableData(appsList.sort(appSort));
            setAllTableData(appsList.sort(appSort));
        }, [apps, status, jobs]);

        const topSensitiveDataIdentifiers = appsList?.reduce((acc, curr) => {
            try {
                if (!curr.piiData || curr.piiData.trim().length === 0) return acc;
                const appName = `${curr.nsName}.${curr.name}`;
                const piiData = JSON.parse(curr.piiData);
                Object.values(piiData).map(value => {
                    let piiFields = [];
                    try {
                        piiFields = JSON.parse(value)["PII fields"];
                    } catch (error) {
                        console.error(error);
                    }
                    piiFields.map(field => {
                        const identifierTypes = Object.keys(field.identifierType);
                        identifierTypes.forEach(type => {
                            acc[type] = acc[type] ?? [];
                            !acc[type].includes(appName) && acc[type].push(appName);
                        });
                    });
                });
                return acc;
            } catch (error) {
                console.log(error);
            }
        }, {});

        const chartData = Object.keys(topSensitiveDataIdentifiers || {})
            .map(key => {
                return {
                    name: key,
                    count: topSensitiveDataIdentifiers[key].length,
                    items: topSensitiveDataIdentifiers[key]
                };
            })
            .sort((x, y) => y.count - x.count)
            .slice(0, 10);

        return (
            <>
                {status === JobStatuses.ERROR && (
                    <Grid display={"flex"} flex={1} item xs={6} mb={3}>
                        <ErrorBox
                            messages={errorMessages}
                            setShowErrorsModal={setShowErrorsModal}
                            setShowSelectedErrorModal={setShowSelectedErrorModal}
                            setSelectedError={setSelectedError}
                        />
                    </Grid>
                )}

                {!isJobPending && (
                    <JobAppsTableFilter
                        sourceApps={appsList}
                        setTableData={setTableData}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        completedTime={completedTime}
                    />
                )}

                {selectedTab === TABS.LIST ? (
                    <>
                        <Grid container item gap={1} mb={1}>
                            <StriimTypography variant="h3" color="greyscale.700">
                                Apps with Sensitive Data
                            </StriimTypography>
                            <JobInsightsChart
                                data={{
                                    sensitiveData: appDataStatus.sensitiveData,
                                    nonSensitiveData: appDataStatus.nonSensitiveData,
                                    errors: appDataStatus.errors,
                                    totalApps: appDataStatus.totalApps,
                                    totalSources: appDataStatus.totalSources
                                }}
                                status={status}
                            />
                        </Grid>
                        <Grid display="grid" width={"100%"}>
                            <JobAppsTable
                                tableData={tableData}
                                jobName={jobName}
                                setShowSelectedErrorModal={setShowSelectedErrorModal}
                                setSelectedError={setSelectedError}
                            />
                        </Grid>
                        <ErrorMessagesModal
                            jobName={jobName}
                            messages={errorMessages}
                            showErrorsModal={showErrorsModal}
                            setShowErrorsModal={setShowErrorsModal}
                            setShowSelectedErrorModal={setShowSelectedErrorModal}
                            setSelectedError={setSelectedError}
                            handleRetry={handleRetry}
                        />
                        <SelectedErrorModal
                            selectedError={selectedError}
                            showSelectedErrorModal={showSelectedErrorModal}
                            setShowSelectedErrorModal={setShowSelectedErrorModal}
                            setShowErrorsModal={setShowErrorsModal}
                            messages={errorMessages}
                            handleRetry={handleRetry}
                        />
                    </>
                ) : (
                    <>
                        <Grid
                            container
                            mb={2}
                            sx={{
                                display: "flex",
                                backgroundColor: "#fff",
                                justifyContent: "space-between"
                            }}
                            gap={2}
                        >
                            <Grid display={"flex"} flex={1} item gap={2}>
                                <Grid item flex={1}>
                                    <InsightsInfoBox
                                        label="Sensitive Data Identifiers"
                                        Icon={LockOpen}
                                        value={Object.keys(topSensitiveDataIdentifiers || {})?.length}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <InsightsInfoBox
                                        label="Sources w/ sensitive data"
                                        Icon={TransitionBottom}
                                        value={`${appDataStatus.sourcesWithPII}/${appDataStatus.totalSources}`}
                                        subValue={`(${numeral(
                                            (appDataStatus.sourcesWithPII / appDataStatus.totalSources) * 100
                                        ).format("0.[00]")}%)`}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item flex={1}>
                                <InsightsInfoBox
                                    label="Apps w/ sensitive data"
                                    Icon={App}
                                    value={`${appDataStatus.sensitiveData}/${tableData.length}`}
                                    subValue={`(${numeral(
                                        (appDataStatus.sensitiveData / tableData.length) * 100
                                    ).format("0.[00]")}%)`}
                                    chartData={{
                                        sensitiveData: appDataStatus.sensitiveData,
                                        nonSensitiveData: appDataStatus.nonSensitiveData,
                                        errors: appDataStatus.errors,
                                        totalApps: appDataStatus.totalApps,
                                        totalSources: appDataStatus.totalSources
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            mb={2}
                            sx={{
                                display: "flex",
                                backgroundColor: "#fff",
                                justifyContent: "space-between"
                            }}
                            gap={2}
                        >
                            <Grid display={"flex"} flex={1} item gap={2} sx={{ flexDirection: "column" }}>
                                <Grid>
                                    <SensitiveDataIdentifierChart
                                        title="Top Sensitive Data Identifiers"
                                        data={chartData}
                                        chartTitles={["IDENTIFIER", "APPS"]}
                                        onLabelClick={value => {
                                            const sensitiveDataIdentifier = value.replace(/(^\w|\s\w)/g, m =>
                                                m.toUpperCase()
                                            );
                                            const associatedApps = appsList.filter(app =>
                                                topSensitiveDataIdentifiers[value].includes(`${app.nsName}.${app.name}`)
                                            );
                                            setAppsWithSDI(associatedApps);
                                            setViewType({
                                                type: viewAllTypes.sensitiveDataIdentifier,
                                                sensitiveDataIdentifier
                                            });
                                            setViewAllView(true);
                                        }}
                                        labelStyle={{
                                            textTransform: "capitalize"
                                        }}
                                        groupLargeDataset={true}
                                    />
                                </Grid>

                                <Grid container gap={2}>
                                    <Grid container justifyContent={"space-between"}>
                                        <Grid display={"flex"} alignItems={"center"} item gap={1}>
                                            <StriimTypography variant="h3" color="primary.700">
                                                Top 5 sources w/ Sensitive Data
                                            </StriimTypography>
                                            <StriimLink
                                                href={dictionary.get()["AI_INSIGHTS_SHERLOCK"].href}
                                                target="_blank"
                                            >
                                                <SvgIcon
                                                    component={HelpCircle}
                                                    sx={{ width: "16px", height: "16px", fill: "none" }}
                                                />
                                            </StriimLink>
                                        </Grid>
                                        <Grid item>
                                            <StriimButton
                                                variant="text"
                                                onClick={() => {
                                                    setViewType({ type: viewAllTypes.topSources });
                                                    setViewAllView(true);
                                                }}
                                            >
                                                View all
                                            </StriimButton>
                                        </Grid>
                                    </Grid>
                                    <Grid display="grid" width={"100%"}>
                                        <SourceSensitiveDataTable data={appsDetails.slice(0, 5)} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item flex={1}>
                                <SensitiveDataIdentifierChart
                                    title="Top 15 Apps w/ Sensitive Data"
                                    rightActionButton={
                                        <StriimButton
                                            variant="text"
                                            onClick={() => {
                                                setViewType({ type: viewAllTypes.topApps });
                                                setViewAllView(true);
                                            }}
                                        >
                                            View all
                                        </StriimButton>
                                    }
                                    data={getTopAppsData(tableData)}
                                    chartTitles={["APP NAME", "ENTITIES"]}
                                    onLabelClick={async appName => {
                                        const isAppAvailable = await isApplicationAvailable(appName);
                                        if (isAppAvailable) {
                                            navigateTo.AppSherlock(appName, jobName, true);
                                        } else {
                                            setShowAppUnavailableModal(true);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
                <StriimModal
                    size="small"
                    isVisible={showAppUnavailableModal}
                    autoHeight
                    onConfirm={() => {
                        setShowAppUnavailableModal(false);
                    }}
                    cancelContent={null}
                    confirmContent={"Done"}
                    titleContent={null}
                    confirmButtonProps={{
                        "data-test-id": "app-unavailable-done-btn"
                    }}
                >
                    <StriimTypography variant="body4">
                        There is no data to display since the selected App was deleted.
                    </StriimTypography>
                </StriimModal>
            </>
        );
    }
);

export default JobListInsightsView;
