import React, { useMemo } from "react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList, Tooltip } from "recharts";
import { StriimTypography } from "@striim/striim-ui";
import { Grid } from "@mui/material";

import { styles } from "./discovery-report-chart.styles";
import { getFormattedChartData, getPluralizedEntityName } from "../../utils";

const renderCustomizedNameLabel = props => {
    const { x, y, value } = props;

    return (
        <g>
            <text
                x={x}
                y={y - 9}
                fill="#48525C"
                fontFamily="Inter"
                fontSize={12}
                fontWeight={400}
                dominantBaseline="middle"
                textAnchor="start"
                letterSpacing={"0.24px"}
            >
                {value}
            </text>
        </g>
    );
};

const renderCustomizedValueLabel = (props, width) => {
    const { y, value } = props;

    return (
        <g>
            <text
                x={width}
                y={y - 9}
                fill="#48525C"
                fontFamily="Inter"
                fontSize={12}
                fontWeight={400}
                dominantBaseline="middle"
                textAnchor="end"
                letterSpacing={"0.24px"}
            >
                {value}
            </text>
        </g>
    );
};

export const CustomTooltip = ({ active, payload }) => {
    if (active && payload?.length) {
        if (payload[0].payload?.name === "Others") {
            return (
                <Grid container flexDirection={"column"} gap={1.25} sx={styles.tooltipWrapper}>
                    <StriimTypography variant="caption4" sx={styles.tooltipText}>
                        OTHERS
                    </StriimTypography>
                    {payload[0].payload.items.map(item => (
                        <Grid key={item.name} display={"flex"} justifyContent={"space-between"} gap={1} item>
                            <StriimTypography variant="caption3" sx={styles.tooltipText}>
                                {item.name}
                            </StriimTypography>
                            <StriimTypography variant="caption3" sx={styles.tooltipText} color="primary.700">
                                {item.count}
                            </StriimTypography>
                        </Grid>
                    ))}
                </Grid>
            );
        }
    }
};

interface DiscoveryReportChartProps {
    entity: string;
    sensitiveDataCount: number;
    data: {
        name: string;
        count: number;
        items: string[];
    }[];
    showBottomBorder?: boolean;
    width?: number;
}

const DiscoveryReportChart = ({
    entity,
    sensitiveDataCount,
    data,
    showBottomBorder = true,
    width = 367
}: DiscoveryReportChartProps) => {
    const entityName = getPluralizedEntityName(entity);
    const chartHeight = 48 * (data.length > 5 ? 5 : data.length);

    const updatedData = useMemo(() => {
        return getFormattedChartData(data);
    }, [data]);

    return (
        <Grid container p={2} gap={0.5} flexDirection={"column"} sx={[showBottomBorder && styles.bottomBorder]}>
            <Grid container>
                <StriimTypography variant="caption1" color="greyscale.900" fontSize={14} lineHeight={"20px"}>
                    {entityName} w/ Sensitive Data
                </StriimTypography>
            </Grid>
            <Grid container pb={2}>
                <StriimTypography
                    variant="caption1"
                    fontSize={"22px"}
                    lineHeight={"32px"}
                    sx={styles.sensitiveDataCount}
                >
                    {sensitiveDataCount}
                </StriimTypography>
            </Grid>
            <Grid container justifyContent={"space-between"} pb={2}>
                <StriimTypography variant="caption3" sx={styles.chartLegend}>
                    DATA IDENTIFER
                </StriimTypography>
                <StriimTypography variant="caption3" sx={styles.chartLegend}>
                    {entityName.toUpperCase()} (OF {sensitiveDataCount})
                </StriimTypography>
            </Grid>
            <Grid container height={chartHeight}>
                <ResponsiveContainer width="100%">
                    <BarChart
                        data={updatedData}
                        layout="vertical"
                        margin={{
                            left: -0.5
                        }}
                    >
                        <XAxis hide type="number" />
                        <YAxis hide type="category" />
                        <Tooltip content={<CustomTooltip />} cursor={false} />
                        <Bar
                            dataKey="count"
                            fill="#7E7ED6"
                            background={{ fill: "#EBF2FA", radius: 2 }}
                            barSize={8}
                            radius={[2, 0, 0, 2]}
                        >
                            <LabelList dataKey="name" content={renderCustomizedNameLabel} />
                            <LabelList dataKey="count" content={props => renderCustomizedValueLabel(props, width)} />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
    );
};

export default DiscoveryReportChart;
