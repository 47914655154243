import { sourceFormConfig } from "../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/configs/source-form-info.js";
import api from "../../../../core/api/api";
import growl from "../../../../app/components/common/growl";
import TARGET_FORM_INFO from "../target/target-form-info";
import { validationUtils } from "../utils";
import { v4 } from "uuid";
import { targetInfoType } from "../target/Types/index.js";

/**
 * @param obj
 * Checks whether the given arg is a backbone model
 * if not assign the get method to it
 */
const addMethods = (obj: any) => {
    if (!obj.cid) {
        obj.get = function(propertyName: string): void {
            return obj?.[propertyName];
        };
        obj.set = function(propertyName: string, data: any): void {
            obj[propertyName] = data;
            return;
        };
        obj.cid = v4();
    }
};

function computeSSLConfig(adapterName: string, connectionParams, isTarget: boolean): string {
    addMethods(connectionParams);
    let fieldsToAdd;
    if (isTarget) {
        fieldsToAdd = TARGET_FORM_INFO[adapterName].form_fields.filter(
            field => field.addToTQL && connectionParams.get(field.name) != ""
        );
    } else {
        fieldsToAdd = sourceFormConfig[adapterName].ilcdc_controls.form_fields.filter(
            field => field.addToTQL && connectionParams.get(field.name) != ""
        );
    }
    return fieldsToAdd.map(field => `javax.net.ssl.${field.name}=${connectionParams.get(field.name)}`).join(";");
}

function getSSlParams(adapter: string, connectionParams, commonParams) {
    const useSSL = connectionParams.get("useSSL");
    adapter = adapter.toUpperCase();
    switch (adapter) {
        case "ORACLE":
            return {
                ...commonParams,
                ...(useSSL && {
                    sslConfigMap: {
                        trustStore: connectionParams.get("trustStore"),
                        trustStoreType: connectionParams.get("trustStoreType"),
                        trustStorePassword: connectionParams.get("trustStorePassword"),
                        keyStore: connectionParams.get("keyStore") || undefined,
                        keyStoreType: connectionParams.get("keyStoreType") || undefined,
                        keyStorePassword: connectionParams.get("keyStorePassword") || undefined
                    }
                })
            };
        case "OJET":
        case "OJETCDC":
            return {
                ...commonParams,
                ...(useSSL && {
                    sslConfigMap: {
                        serverCertificateDomain: connectionParams.get("serverCertificateDomain"),
                        walletLocation: connectionParams.get("walletLocation")
                    }
                })
            };
        case "POSTGRES":
        case "YUGABYTE":
            return {
                ...commonParams,
                ...(useSSL && {
                    sslConfigMap: {
                        useSSL: connectionParams.get("useSSL"),
                        sslmode: connectionParams.get("sslmode"),
                        sslcert: connectionParams.get("sslcert"),
                        sslkeypk: connectionParams.get("sslkeypk"),
                        sslrootcert: connectionParams.get("sslrootcert")
                    }
                })
            };
        case "MSSQL":
        case "MSJET":
        case "SQLSERVER":
            return {
                ...commonParams,
                ...(useSSL && {
                    sslConfigMap: {
                        encrypt: true,
                        trustServerCertificate: connectionParams.get("trustServerCertificate") || false,
                        integratedSecurity: connectionParams.get("integratedSecurity") || false,
                        trustStore: connectionParams.get("trustStore"),
                        trustStorePassword: connectionParams.get("trustStorePassword"),
                        hostNameInCertificate: connectionParams.get("hostNameInCertificate")
                    }
                })
            };
        case "MYSQL":
        case "MARIADB":
        case "MARIADBXPAND":
            return {
                ...commonParams,
                ...(useSSL && {
                    sslConfigMap: {
                        useSSL: connectionParams.get("useSSL"),
                        requireSSL: connectionParams.get("requireSSL") || false,
                        verifyServerCertificate: connectionParams.get("verifyServerCertificate") || false,
                        trustCertificateKeyStoreUrl: connectionParams.get("trustCertificateKeyStoreUrl"),
                        trustCertificateKeyStoreType: connectionParams.get("trustCertificateKeyStoreType"),
                        trustCertificateKeyStorePassword: connectionParams.get("trustCertificateKeyStorePassword"),
                        clientCertificateKeyStoreUrl: connectionParams.get("clientCertificateKeyStoreUrl") || undefined,
                        clientCertificateKeyStoreType:
                            connectionParams.get("clientCertificateKeyStoreType") || undefined,
                        clientCertificateKeyStorePassword:
                            connectionParams.get("clientCertificateKeyStorePassword") || undefined
                    }
                })
            };
        default:
            return null;
    }
}
async function getParams(
    adapter: string,
    connectionParams: any,
    getPrimaryParams: boolean = false,
    isTarget: boolean = false
): Promise<Object> {
    addMethods(connectionParams);
    // For Non ILCDC Source types
    if (!validationUtils.isAutomatedILCDCSource(adapter.toUpperCase()) && !isTarget) {
        return {
            UserName: connectionParams.get("username"),
            Password: connectionParams.get("password"),
            ConnectionURL: connectionParams.getJDBCURL()
        };
    }
    if (adapter === "SALESFORCE") {
        return {
            username: connectionParams.get("username"),
            password: connectionParams.get("password"),
            securityToken: connectionParams.get("securityToken"),
            consumerKey: connectionParams.get("consumerKey"),
            consumerSecret: connectionParams.get("consumerSecret"),
            accesstoken: connectionParams.get("authToken"),
            apiEndPoint: connectionParams.get("host")
        };
    } else if (adapter === "OJET") {
        let connURL;
        if (connectionParams.get("supportDownStreamCapture") && getPrimaryParams) {
            connURL =
                connectionParams.get("downstreamConnectionURL") ?? (await getDownStreamConnectionURL(connectionParams));
            connectionParams.set("downstreamConnectionURL", connURL);
            const commonParams = {
                UserName: connectionParams.get("primaryDatabaseUsername"),
                Password: connectionParams.get("primaryDatabasePassword"),
                ConnectionURL: connURL,
                encrypted: false,
                containerName: connectionParams.get("containerName") || undefined,
                sid: connectionParams.get("primaryDatabaseServiceName"),
                service: connectionParams.get("service")
            };
            return getSSlParams(adapter, connectionParams, commonParams);
        } else {
            connURL = connectionParams.get("connectionUrl") ?? (await getConnectionUrl(adapter, connectionParams));
            connectionParams.set("connectionUrl", connURL);
            const commonParams = {
                UserName: connectionParams.get("username"),
                Password: connectionParams.get("password"),
                ConnectionURL: connURL,
                encrypted: false,
                containerName: connectionParams.get("containerName") || undefined,
                service: connectionParams.get("service"),
                sid: connectionParams.get("sid")
            };
            return getSSlParams(adapter, connectionParams, commonParams);
        }
    } else {
        const connURL = connectionParams.get("connectionUrl") ?? (await getConnectionUrl(adapter, connectionParams));
        connectionParams.set("connectionUrl", connURL);
        const commonParams = {
            UserName: connectionParams.get("username"),
            Password: connectionParams.get("password"),
            ConnectionURL: connURL,
            encrypted: false,
            containerName: connectionParams.get("containerName") || undefined,
            service: connectionParams.get("service")
        };

        const useConnectionProfile = connectionParams.get("useConnectionProfile");
        const connectionProfileName = connectionParams.get("connectionProfileName");
        typeof useConnectionProfile == "boolean" && (commonParams["useConnectionProfile"] = useConnectionProfile);
        connectionProfileName && (commonParams["connectionProfileName"] = useConnectionProfile);

        return getSSlParams(adapter, connectionParams, commonParams);
    }
}

async function getDownStreamConnectionURL(connectionParams) {
    addMethods(connectionParams);
    let commonParams;
    if (connectionParams.get("supportDownStreamCapture")) {
        commonParams = {
            hostName: connectionParams.get("primaryDatabaseHostname"),
            port: connectionParams.get("primaryDatabasePort"),
            sid: connectionParams.get("primaryDatabaseServiceName"),
            containerName: connectionParams.get("containerName")
        };
    } else {
        commonParams = {
            service: connectionParams.get("service"),
            containerName: connectionParams.get("containerName"),
            hostName: connectionParams.get("host"),
            port: connectionParams.get("port"),
            sid: connectionParams.get("sid")
        };
    }
    const APIParams = getSSlParams("OJET", connectionParams, commonParams);
    return await api.OracleGetConnectionURL(APIParams);
}
async function getConnectionUrl(adapter: string, connectionParams: any) {
    addMethods(connectionParams);

    const commonParams = {
        streamingUpload: null,
        catalog: null,
        service: connectionParams.get("service"),
        containerName: connectionParams.get("containerName"),
        hostName: connectionParams.get("host"),
        port: connectionParams.get("port"),
        dbName: connectionParams.get("dbName"),
        sid: connectionParams.get("sid")
    };
    const APIParams = getSSlParams(adapter, connectionParams, commonParams);
    adapter = adapter.toUpperCase();
    switch (adapter) {
        case "ORACLE":
        case "OJET":
            return await api.OracleGetConnectionURL(APIParams);
        case "OJETCDC":
            return await api.OjetGetConnectionURL(APIParams);
        case "POSTGRES":
            return await api.PostgreSQLGetConnectionURL(APIParams);
        case "MSSQL":
        case "MSJET":
        case "SQLSERVER":
            return await api.MSSQLGetConnectionURL(APIParams);
        case "MYSQL":
            return await api.MySQLGetConnectionURL(APIParams);
        case "MARIADB":
            return await api.MariaDBGetConnectionURL(APIParams);
        case "MARIADBXPAND":
            return await api.MariaDBXpandGetConnectionURL(APIParams);
        default:
            growl.error(`API method (getConnectionUrl) for ${adapter} is not configured`);
            return null;
    }
}

async function getEncryptedUrl(adapter: string, connectionParams: any, appName: string, agentName: string = undefined) {
    addMethods(connectionParams);
    const commonParams = {
        streamingUpload: null,
        catalog: null,
        service: connectionParams.get("service"),
        containerName: connectionParams.get("containerName"),
        hostName: connectionParams.get("host"),
        port: connectionParams.get("port"),
        dbName: connectionParams.get("dbName"),
        sid: connectionParams.get("sid")
    };
    const APIParams = getSSlParams(adapter, connectionParams, commonParams);
    adapter = adapter.toUpperCase();
    switch (adapter) {
        case "ORACLE":
        case "OJET":
            return await api.OracleGetEncryptedURL(APIParams, appName, agentName);
        case "OJETCDC":
            return await api.OjetGetEncryptedURL(APIParams, appName, agentName);
        case "POSTGRES":
            return await api.PostgreSQLGetEncryptedURL(APIParams, appName, agentName);
        case "MSSQL":
        case "MSJET":
        case "SQLSERVER":
            return await api.MSSQLGetEncryptedURL(APIParams, appName, agentName);
        case "MYSQL":
            return await api.MySQLGetEncryptedURL(APIParams, appName, agentName);
        case "MARIADB":
            return await api.MariaDBGetEncryptedURL(APIParams, appName, agentName);
        case "MARIADBXPAND":
            return await api.MariaDBXpandGetEncryptedURL(APIParams, appName, agentName);
        default:
            console.error(`API method (getEncryptedUrl) for ${adapter} is not configured`);
            return null;
    }
}

function setParalleThreadsForSource(sourceModel, targetInfo: targetInfoType): void {
    try {
        const parallelThreads = targetInfo.targetFormValues.ParallelThreads;
        if (parallelThreads) {
            sourceModel.adapter.properties.ParallelThreads = parallelThreads;
        }
    } catch (error) {
        console.error("Coudn't set parallel threads property for source model", error);
    }
}

export {
    computeSSLConfig,
    getParams,
    getConnectionUrl,
    getEncryptedUrl,
    getDownStreamConnectionURL,
    setParalleThreadsForSource
};
