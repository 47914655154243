import React, { useState } from "react";
import { StriimInputField } from "@striim/striim-ui";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import PropTypes from "prop-types";
import { PropertySetEditor } from "./";

export default function PropertySetsPresent({ propertySets, deletePropertySet, onSave }) {
    const [searchQuery, setSearchQuery] = useState();
    let filteredPropertySets = propertySets.sort((a, b) => (a.id > b.id ? 1 : -1));
    if (searchQuery) {
        filteredPropertySets = filteredPropertySets?.filter(propertySet => {
            return (
                propertySet
                    ?.get("nsName")
                    ?.toLowerCase()
                    .includes(searchQuery?.toLowerCase()) ||
                propertySet
                    ?.get("name")
                    ?.toLowerCase()
                    ?.includes(searchQuery?.toLowerCase())
            );
        });
    }

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <div style={{ width: "400px", marginBlock: "16px" }}>
                <StriimInputField
                    id="data-test-id-property-set-filter-input"
                    onChange={string => {
                        if (string.length) {
                            setSearchQuery(string);
                        } else {
                            setSearchQuery();
                        }
                    }}
                    placeholder="Search by property set name or namespace"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{ mr: 0 }}>
                                <Search opacity={0.45} />
                            </InputAdornment>
                        )
                    }}
                />
            </div>

            {filteredPropertySets?.length ? (
                filteredPropertySets.map(propertySet => {
                    return (
                        <PropertySetEditor
                            uuid={propertySet?.attributes?.uuid}
                            key={propertySet.get("id")}
                            onSave={newProperties => onSave(propertySet.get("id"), newProperties)}
                            propertySetName={propertySet.get("name")}
                            propertySetNsName={propertySet.get("nsName")}
                            propertySetProperties={propertySet.get("properties")}
                            deletePropertySet={() => deletePropertySet(propertySet.get("id"))}
                        />
                    );
                })
            ) : (
                <div data-test-id="no-property-sets">Nothing matches your search criteria</div>
            )}
        </div>
    );
}

PropertySetsPresent.propTypes = {
    propertySets: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            nsName: PropTypes.string.isRequired,
            properties: PropTypes.object.isRequired
        }).isRequired
    ).isRequired,
    deletePropertySet: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};
