export const styles = {
    tabsStyles: {
        width: "100%"
    },
    contentStyles: {
        width: "100%",
        borderRadius: "0px 0px 8px 8px"
    },
    tabs: {
        width: "100%",
        "& .MuiTab-root": {
            padding: "16px 24px"
        },
        "& .MuiTab-root > .MuiBox-root": {
            width: "100%"
        },
        "& .contentContainer": {
            borderRadius: 0
        }
    },
    tab: {
        display: "flex",
        width: "100%"
    },
    tabsWrapper: {
        flexDirection: "column",
        width: "100%"
    },
    tabLabels: {
        display: "flex",
        width: "100%",
        marginBottom: 2,
        justifyContent: "space-between"
    },
    tabLabel: {
        textTransform: "none"
    },
    tabSubLabel: {},
    tabAmount: {
        fontFamily: "Nunito",
        fontSize: "22px"
    },
    tabBottomContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    tabBottomContainerAction: {
        marginRight: "20px",
        textTransform: "none",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "&:last-child": {
            marginRight: 0
        }
    },
    firstLetterUnderline: {
        "&:first-letter": {
            textDecoration: "underline"
        }
    },
    actionSquare: {
        width: "11px",
        height: "11px",
        marginRight: "8px",
        display: "inline-block",
        borderRadius: "2px"
    },
    actionLabel: {
        color: ({ palette }) => `${palette.greyscale[700]} !important`
    },
    actionValue: {
        marginLeft: 0.5,
        color: ({ palette }) => `${palette.greyscale[700]} !important`
    },
    iconInfo: {
        height: "16px",
        width: "16px",
        fill: "transparent",
        color: "transparent",
        "& path": {
            stroke: theme => theme.palette.greyscale[900]
        },
        marginTop: "7px"
    },
    reportActionValue: {
        marginLeft: 0.5,
        color: ({ palette }) => `${palette.greyscale[900]} !important`
    }
};
