import { StriimTypography } from "@striim/striim-ui";
import React, { useEffect } from "react";
import { Box } from "@mui/material";
import useStyles from "./validate-connection.styles";
import { Error, CheckCircle } from "@material-ui/icons";
import CircularProgress from "@mui/material/CircularProgress";
import { Observer } from "mobx-react";
import { sxStyles } from "./validate-connection.styles";
import PropTypes from "prop-types";
import SuccessIcon from "../../images/success.svg";
import FailureIcon from "../../images/failure.svg";
import Footer from "../footer/footer";

const CustomCircularProgress = () => (
    <Box mr={2}>
        <CircularProgress
            variant="determinate"
            sx={{ ...sxStyles.iconBaseStyle, ...sxStyles.circularProgress }}
            size={20}
            thickness={8}
            value={100}
        />
        <CircularProgress color="secondary" sx={sxStyles.iconBaseStyle} size={20} thickness={8} />
    </Box>
);

const Operation = ({ text, status, errorMsg }) => {
    const classes = useStyles();
    const operationStatusIcon = (
        <>
            {status === "todo" ? (
                <CustomCircularProgress sx={sxStyles.icon} />
            ) : status === "done" ? (
                <SuccessIcon style={sxStyles.icon} />
            ) : (
                <FailureIcon style={sxStyles.icon} />
            )}
        </>
    );

    return (
        <Box className={classes.operationWrapper}>
            <Box className={classes.operationContainer}>
                <Box mr={2}>{operationStatusIcon}</Box>
                <StriimTypography
                    id="operation-name"
                    sx={{ color: ({ palette }) => palette.greyscale[900] }}
                    className={classes.flexOne}
                    variant="body4"
                >
                    {text}
                </StriimTypography>
            </Box>
            {status === "fail" && errorMsg && (
                <StriimTypography
                    id="operation-error"
                    sx={{ color: ({ palette }) => palette.greyscale[900] }}
                    className={classes.errorMsg}
                    variant="body4"
                >
                    {errorMsg}
                </StriimTypography>
            )}
        </Box>
    );
};

export const ValidateConnection = ({ store, validateCxnStore, componentStore }) => {
    const classes = useStyles();
    const subText = `
    Before we can proceed to the next steps, we'll need to make sure Striim is able to access your database.
    Striim will fetch additional information about your database to help you in the next steps.
    Depending on the size of your database and the network latency, this can take upto few minutes.`;

    useEffect(() => {
        validateCxnStore.resetStatus();
        validateCxnStore.performOperations(componentStore);
    }, []);

    return (
        <Observer
            render={() => (
                <Box>
                    <Box sx={sxStyles.header} p={3}>
                        <StriimTypography variant="h2" sx={sxStyles.title} mb={1} className={classes.header}>
                            Validate Connection
                        </StriimTypography>
                        <StriimTypography mb={2} variant="body4" sx={sxStyles.description}>
                            {subText}
                        </StriimTypography>
                    </Box>
                    <Box sx={sxStyles.container}>
                        {validateCxnStore.operations?.map(operation => (
                            <Operation
                                key={operation.text}
                                text={operation.text}
                                status={operation.status}
                                errorMsg={operation.remedy}
                            ></Operation>
                        ))}
                    </Box>
                    <Footer
                        next="save"
                        onBack={() => store.goToPreviousStep()}
                        onNext={() => store.goToNextStep()}
                        store={store}
                        nextDisabled={!store.validationPassed}
                    />
                </Box>
            )}
        ></Observer>
    );
};

ValidateConnection.propTypes = {
    componentStore: PropTypes.object.isRequired,
    validateCxnStore: PropTypes.object.isRequired
};

export default ValidateConnection;
