import { VaultTypes } from "./constants";

const unitMultiplier = {
    ms: 0.001,
    s: 1,
    m: 60,
    h: 3600,
    d: 86400
};
const unitOptions = [
    {
        label: "milliseconds",
        value: "ms"
    },
    {
        label: "seconds",
        value: "s"
    },
    {
        label: "minutes",
        value: "m"
    },
    {
        label: "hours",
        value: "h"
    },
    {
        label: "days",
        value: "d"
    }
];
const formatStriimVaultData = formValues => {
    return {
        ...formValues,
        nsName: formValues.nsName.value,
        properties: {
            ...formValues?.properties,
            VaultType: VaultTypes.STRIIM_VAULT
        }
    };
};

const formatHashicorpVaultData = formValues => {
    const autoRenewCheckPeriod =
        formValues?.properties?.AutoRenewCheckPeriod?.toString().length > 0
            ? formValues.properties.AutoRenewCheckPeriod
            : null;
    const autoRenewCheckPeriodUnit = formValues?.properties?.AutoRenewCheckPeriodUnit?.value;
    const autoRenewIncrement =
        formValues?.properties?.AutoRenewIncrement?.toString().length > 0
            ? formValues.properties.AutoRenewIncrement
            : null;
    const autoRenewIncrementUnit = formValues?.properties?.AutoRenewIncrementUnit?.value;
    let retObj = {
        ...formValues,
        nsName: formValues.nsName.value,
        properties: {
            ...formValues?.properties,
            VaultType: VaultTypes.HASHICORP_VAULT,
            AutoRenewCheckPeriod: autoRenewCheckPeriod + autoRenewCheckPeriodUnit ?? null,
            AutoRenewIncrement: autoRenewIncrement + autoRenewIncrementUnit ?? null
        }
    };
    if (!retObj.properties.AutoRenew) {
        delete retObj.properties.AutoRenewCheckPeriod;
        delete retObj.properties.AutoRenewIncrement;
    }
    delete retObj.properties.AutoRenewCheckPeriodUnit;
    delete retObj.properties.AutoRenewIncrementUnit;
    return retObj;
};

const formatAzureVaultData = formValues => {
    return {
        ...formValues,
        nsName: formValues.nsName.value,
        properties: {
            ...formValues?.properties,
            VaultType: VaultTypes.AZURE_VAULT
        }
    };
};

const formatGoogleSecretManagerData = formValues => {
    return {
        ...formValues,
        nsName: formValues.nsName.value,
        properties: {
            ...formValues?.properties,
            VaultType: VaultTypes.GOOGLE_SECRET_MANAGER
        }
    };
};

const formatPropertyKey = propertiesData => {
    //Vault could have been created with their properties without any casing
    const properties = Object.entries(propertiesData).reduce((accumulated, current) => {
        return { ...accumulated, [current[0].toLowerCase()]: current[1] };
    }, {});
    return properties;
};

//Splitting the AutoRenewData into 2, since the value is a combination of number and char
const splitRenewData = data => {
    let retVal = [];
    if (!(data === "undefined" || data === null || data === "")) {
        retVal = data.match(/^(\d+)(\D+)/).slice(1);
        const result = unitOptions.find(data => retVal[1] === data.value);
        retVal[1] = { ...result };
    }
    return retVal;
};

const formatHashicorpVaultDataForEdit = formValues => {
    const properties = formatPropertyKey(formValues?.properties);

    let AutoRenewCheckPeriodData = "";
    let AutoRenewCheckPeriodUnitData = "";

    let AutoRenewIncrementData = "";
    let AutoRenewIncrementUnitData = "";

    if (!properties.autorenew) {
        // set default if not enabled
        AutoRenewCheckPeriodData = 12;
        AutoRenewCheckPeriodUnitData = unitOptions[3];
    } else {
        // fetch current vals
        const checkPeriodData = splitRenewData(properties.autorenewcheckperiod);
        AutoRenewCheckPeriodData = checkPeriodData[0];
        AutoRenewCheckPeriodUnitData = checkPeriodData[1];

        const incrementData = splitRenewData(properties.autorenewincrement);
        AutoRenewIncrementData = incrementData[0];
        AutoRenewIncrementUnitData = incrementData[1];
    }

    return {
        name: formValues.name,
        nsName: { label: formValues.nsName, value: formValues.nsName },
        properties: {
            VaultType: VaultTypes.HASHICORP_VAULT,
            ConnectionURL: properties.connectionurl,
            Port: properties.port,
            EngineName: properties.enginename,
            PathToSecret: properties.pathtosecret,
            AccessToken: null,
            AutoRenew: properties.autorenew,
            AutoRenewCheckPeriod: AutoRenewCheckPeriodData,
            AutoRenewCheckPeriodUnit: AutoRenewCheckPeriodUnitData,
            AutoRenewIncrement: AutoRenewIncrementData,
            AutoRenewIncrementUnit: AutoRenewIncrementUnitData,
            salt: properties.salt
        }
    };
};

const formatAzureVaultDataForEdit = formValues => {
    const properties = formatPropertyKey(formValues?.properties);
    return {
        name: formValues.name,
        nsName: { label: formValues.nsName, value: formValues.nsName },
        properties: {
            VaultType: VaultTypes.AZURE_VAULT,
            ConnectionURL: properties.connectionurl,
            ClientID: properties.clientid,
            ClientSecret: null,
            TenantID: properties.tenantid,
            salt: properties.salt
        }
    };
};

const formatGoogleSecretManagerDataForEdit = formValues => {
    const properties = formatPropertyKey(formValues?.properties);
    return {
        name: formValues.name,
        nsName: { label: formValues.nsName, value: formValues.nsName },
        properties: {
            vaultType: VaultTypes.GOOGLE_SECRET_MANAGER,
            projectID: properties.projectid,
            serviceAccountKeyPath: properties.serviceaccountkeypath
        }
    };
};

export {
    unitOptions,
    unitMultiplier,
    formatStriimVaultData,
    formatHashicorpVaultData,
    formatAzureVaultData,
    formatHashicorpVaultDataForEdit,
    formatAzureVaultDataForEdit,
    formatGoogleSecretManagerData,
    formatGoogleSecretManagerDataForEdit
};
