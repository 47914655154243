import api from "core/api/api";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import metaobjectConverter from "core/services/metaStoreService/metaobject-converter";
import EmbeddingGeneratorModel from "core/services/metaStoreService/metaObjects/embeddinggenerator";
import metaobjectUtilities from "core/services/metadataService/metaobject-utilities";

export const getEmptyDetails = (): EmbeddingGeneratorModel => new EmbeddingGeneratorModel.Model();

function saveEmbeddingGenerator(id: string, embeddingGenerator: EmbeddingGeneratorModel): Promise<string> {
    return new Promise(async (resolve, reject) => {
        if (!id) {
            const list = await loadAll();
            if (list.some(x => x.name === embeddingGenerator.name)) {
                reject("Cannot add a duplicate vector embeddings generator");
                return;
            }
        }

        embeddingGenerator
            .save()
            .then(function(result) {
                resolve(result.id);
            })
            .fail(function(error) {
                console.error(error);
                reject("error saving vector embeddings generator");
            });
    });
}

function loadAll(): Promise<EmbeddingGeneratorModel[]> {
    return new Promise((resolve, reject) => {
        metaStoreService
            .fetchCollection(metaStoreService.entities.EMBEDDINGGENERATOR)
            .then(embeddingGenerators => {
                Promise.all(embeddingGenerators.models.map(m => metaStoreService.findById(m.id))).then(collection => {
                    resolve(collection.filter(x => !!x));
                });
            })
            .fail(() => {
                reject();
            });
    });
}

function deleteEmbeddingGenerator(id: string): Promise<boolean | string> {
    return new Promise<boolean | string>(resolve => {
        api.dropObject({
            type: metaStoreService.entities.EMBEDDINGGENERATOR,
            name: metaobjectConverter.convertFullNameToShortName(id)
        })
            .then(function() {
                resolve(true);
            })
            .fail(function(e) {
                resolve(e.message);
            });
    });
}

export default {
    load: loadAll,
    add: (embeddingGenerator: any) => saveEmbeddingGenerator(undefined, embeddingGenerator),
    deleteEmbeddingGenerator: deleteEmbeddingGenerator,
    loadById: (id: string): Promise<any> => metaStoreService.findById(id),
    update: (id: string, embeddingGenerator: any) => saveEmbeddingGenerator(id, embeddingGenerator),
    getNamespaces: () => metaobjectUtilities.getFilteredNamespaces()
};
