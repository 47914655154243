import React, { useEffect, useState } from "react";
import { Grid, SvgIcon } from "@mui/material";
import { StriimTypography, StriimButton, StriimDropdown, StriimInputField } from "@striim/striim-ui";

import { styles } from "./panel-header.styles";
import dateLib from "core/utils/date-utils";
import dictionary from "../../../../../../app/components/common/helpable/online-help-dictionary";
import VersionSelector from "../version-selector/version-selector";
import { SidebarCollapse, Close, InfoCircle, Sherlock } from "../../../../../generic/icon/customIcons";
import { JobVersion, Version } from "../../../guardian/guardian-job.types";
import WithTooltip from "../../../../../generic/tooltip/tooltip";

interface PanelHeaderProps {
    name?: string;
    selectedVersion?: JobVersion;
    description?: string;
    isSidebarExpanded: boolean;
    showToggleIcon?: boolean;
    updatedTime?: number;
    versions?: Version[];
    onToggle: Function;
    onClose: Function;
    updateDescription?: Function;
    handleVersionChange: (value: Version) => void;
}

const PanelHeader = ({
    name,
    description,
    selectedVersion,
    isSidebarExpanded = false,
    showToggleIcon = false,
    versions,
    onToggle,
    updatedTime,
    onClose,
    updateDescription,
    handleVersionChange
}: PanelHeaderProps) => {
    const [updatedDescription, setUpdatedDescription] = useState<string | null>(null);

    const controlState = useState<boolean>(false);
    const formattedUpdateTime = updatedTime ? (
        <>
            {dateLib.unix(updatedTime / 1000).format("MMM DD, YYYY")} at{" "}
            {dateLib.unix(updatedTime / 1000).format("hh:mm a")}
        </>
    ) : null;
    return (
        <>
            <Grid container p={2} gap={1} sx={styles.container}>
                <Grid container justifyContent={"space-between"}>
                    <Grid display={"flex"} item gap={0.5} alignItems={"center"}>
                        <SvgIcon component={Sherlock} sx={styles.aiIcon} />
                        <StriimTypography variant="caption4" sx={styles.aiText} fontFamily="Inter">
                            Sherlock AI
                        </StriimTypography>
                    </Grid>
                    <Grid display={"flex"} item alignItems={"center"} gap={1}>
                        {showToggleIcon && (
                            <StriimButton
                                variant="text"
                                startIcon={<SvgIcon component={SidebarCollapse} sx={styles.startIcon} />}
                                sx={{ ...styles.iconButton, ...(isSidebarExpanded && styles.expanded) }}
                                onClick={() => onToggle()}
                            />
                        )}
                        <StriimButton
                            variant="text"
                            startIcon={<SvgIcon component={Close} sx={styles.startIcon} />}
                            sx={styles.iconButton}
                            onClick={() => onClose()}
                        />
                    </Grid>
                </Grid>
                <Grid container flexDirection={"column"} gap={"5px"}>
                    {versions?.length ? (
                        <VersionSelector
                            selectedVersion={selectedVersion}
                            setSelectedVersion={(value: Version) => {
                                if (value.name !== selectedVersion.name) {
                                    handleVersionChange(value);
                                }
                            }}
                            versions={versions}
                        />
                    ) : (
                        <Grid maxWidth="100%">
                            {WithTooltip(
                                <StriimTypography variant="h3" color="primary.800" sx={styles.reportName}>
                                    {name ?? "Create a Sensitive Data Discovery Report"}
                                </StriimTypography>,
                                name && name.length > 40 ? name : null
                            )}
                        </Grid>
                    )}
                    <Grid item display={"flex"} gap={1}>
                        {formattedUpdateTime ? (
                            <>
                                <StriimTypography variant="caption3" color="greyscale.700">
                                    Updated {formattedUpdateTime}
                                </StriimTypography>
                                <StriimDropdown
                                    hover
                                    PopperProps={{
                                        disablePortal: true
                                    }}
                                    onClose={() => setUpdatedDescription(null)}
                                    controlState={controlState}
                                    placement="bottom-start"
                                    content={
                                        <Grid container flexDirection={"column"} sx={styles.descriptionWrapper} gap={1}>
                                            <StriimInputField
                                                label="Description"
                                                value={updatedDescription ?? description}
                                                multiline={true}
                                                rows={5}
                                                onChange={value => setUpdatedDescription(value)}
                                            />
                                            <Grid item display={"flex"} justifyContent={"flex-end"} gap={0.5}>
                                                <StriimButton
                                                    variant="text"
                                                    onClick={() => {
                                                        controlState[1](false);
                                                    }}
                                                >
                                                    Cancel
                                                </StriimButton>
                                                <StriimButton
                                                    variant="primary"
                                                    disabled={
                                                        updatedDescription === null ||
                                                        updatedDescription === description
                                                    }
                                                    onClick={() => {
                                                        updateDescription(updatedDescription);
                                                        controlState[1](false);
                                                    }}
                                                >
                                                    Save
                                                </StriimButton>
                                            </Grid>
                                        </Grid>
                                    }
                                >
                                    <Grid display={"flex"} alignItems={"center"}>
                                        <SvgIcon component={InfoCircle} sx={styles.infoIcon} />
                                    </Grid>
                                </StriimDropdown>
                            </>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PanelHeader;
