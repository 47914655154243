import Backbone from "backbone";
import { CompositeView } from "marionette";
import App from "app";
import ModalManager from "app/components/common/modal/ModalManager";
import Modal from "app/components/common/modal/modal";
import dialog from "app/components/common/dialogs/dialogWindow";
import dataTypeValidationLoader from "./data-type-validation-loader";
import dataTypeValidationTemplate from "./data-type-validation.html";
import errorItemTemplate from "./error-item.html";
import { childView, childViewContainer, on, onChild, tagName, template, ui } from "core/decorators";
import fileSaver from "file-saver";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import Papa from "papaparse";
import "perfectscrollbar";

const editComponentEventName = "edit:component";
@template(errorItemTemplate)
@tagName("tr")
@ui({
    srcComponent: ".src-item",
    targetComponent: ".target-item",
})
class ErrorRowView extends CompositeView {
    @on("click @ui.srcComponent")
    gotToSrcComponent() {
        App.vent.trigger(editComponentEventName, this.model.get("srcComponentName"));
        this.trigger(editComponentEventName);
    }

    @on("click @ui.targetComponent")
    gotToTargetComponent() {
        App.vent.trigger(editComponentEventName, this.model.get("tarComponentName"));
        this.trigger(editComponentEventName);
    }
}

@template(dataTypeValidationTemplate)
@childViewContainer("tbody")
@childView(ErrorRowView)
class DataTypeValidationContentView extends CompositeView {
    @onChild("edit:component")
    editComponent() {
        this.trigger(editComponentEventName);
    }

    onRender() {
/*
        setTimeout(() => {
            this.$el.find("tbody").perfectScrollbar();
        }, 1000);
*/
    }
}

const DataTypeValidation = App.FormMixinDialog.View.extend({
    initialize: function (options) {
        const errors = new Backbone.Collection(options.errors);

        options.contentview = new DataTypeValidationContentView({
            collection: errors,
        });
        this.listenToOnce(
            options.contentview,
            editComponentEventName,
            function (url) {
                this.trigger(editComponentEventName);
            }.bind(this)
        );
        options.bind_submit = true;
        options.modalSettings = new Modal.Model({
            animation: {
                hide: "",
            },
            classes: "data-type-validation-dialog",
        });
        App.FormMixinDialog.View.prototype.initialize.apply(this, arguments);
    },
});

DataTypeValidation.Controller = App.FormMixinDialog.Controller;

function exportAsCSV(errors, applicationId) {
    const errorsWithDisplayNames = errors.map((error) => {
        return {
            "Source component name": error.srcShortName,
            "Source table": error.srcTable,
            "Source column": error.srcCol,
            "Target component name": error.targetShortName,
            "Target table": error.tarTable,
            "Target column": error.tarCol,
            "Error messsage": error.message,
        };
    });

    const csvString = Papa.unparse(errorsWithDisplayNames);

    const filename = 'Table mapping validation ' + metaObjectConverter.convertFullNameToShortName(applicationId) + '.csv';
    let blob = new Blob([csvString], {type: 'text/plain;charset=utf-8'});

    fileSaver.saveAs(blob, filename);
}

function showValidationDialog(errors, applicationId) {
    return new Promise((resolve) => {
        let dialog = new DataTypeValidation({
            errors: errors,
            model: new Backbone.Model({
                title: "Table mapping validation",
                showSubmitButton: true,
                submit_text: "Ignore",
                cancel_text: "Cancel",
                actions: [
                    {
                        id: "export",
                        label: "Export",
                        click: function () {
                            exportAsCSV(errors, applicationId);
                        },
                    },
                ],
            }),
        });

        let modalManager = new ModalManager({ container: "body" });
        modalManager.add(dialog);
        new DataTypeValidation.Controller({
            view: dialog,
        });

        let dialogResult = false;
        dialog.on("cancel", () => {
            dialogResult = false;
            dialog.destroy();
        });

        dialog.on("submit", () => {
            dialogResult = true;
            dialog.destroy();
        });

        dialog.on(editComponentEventName, function () {
            dialogResult = false;
            dialog.destroy();
        });

        dialog.on("modal:hide", () => {
            resolve(dialogResult);
        });

        dialog.on("before:destroy", () => {
            modalManager.remove_all_modals();
        });
    });
}

/** @function validate
 * validates application. When application contains errors, then dialog is displayed
 *  @param {string} applicationId - application full Id
 */
function validate(applicationId, deploymentInfo, validateConnection) {
    return new Promise((resolve) => {
        if (validateConnection === false) {
            resolve(true);
            return;
        }

        dataTypeValidationLoader(applicationId, deploymentInfo).then((errors) => {
            if (errors === null) {
                resolve(true);
                return;
            }

            if (errors.length === 0) {
                let modalManager = new ModalManager({ container: "body" });

                dialog
                    .confirm(
                        "There was an unhandled data type validation exception. Would you like to continue?",
                        modalManager
                    )
                    .then(function (result) {
                        modalManager.remove_all_modals();
                        resolve(result);
                    });
                return;
            }

            showValidationDialog(errors, applicationId).then((dialogResult) => {
                resolve(dialogResult);
            });
        });
    });
}

export default validate;
