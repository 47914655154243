export const styles = {
    ssoWrapperContainer: {
        paddingLeft: ({ spacing }) => spacing(12.5),
        paddingRight: ({ spacing }) => spacing(12.5)
    },
    wrapperRoot: {},

    // Empty SSO Config
    emptySSOHeader: {
        marginBottom: ({ spacing }) => `${spacing(2)} !important`
    },
    noSsoWrapper: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "center",
        background: ({ palette }) => palette.additional.white,
        height: 136,
        border: "1px solid",
        borderColor: ({ palette }) => palette.greyscale[200],
        borderRadius: ({ spacing }) => spacing(1),
        fontSize: 14,
        lineHeight: 20
    },
    emptyWrapperItemTop: {
        display: "flex",
        alignItems: "flex-end",
        "& h4": {
            color: ({ palette }) => palette.greyscale[700],
            fontWeight: 400
        }
    },
    emptyWrapperItemBottom: {
        display: "flex",
        alignItems: "flex-start",
        marginTop: ({ spacing }) => spacing(2),
        "& button": {
            color: ({ palette }) => palette.secondary[500],
            fontWeight: 500
        }
    },

    // Add SSO Config
    addSsoWrapper: {},
    addSSOHeader: {
        marginBottom: ({ spacing }) => spacing(2)
    },
    addSsoCaption: {
        fontFamily: "Inter",
        color: ({ palette }) => palette.greyscale[800],
        marginTop: ({ spacing }) => spacing(1),
        marginBottom: ({ spacing }) => spacing(1)
    },
    addSsoRadioGroup: {
        display: "flex",
        flexDirection: "column",
        "& label": {
            "& > *:last-child": {
                fontSize: 14,
                fontWeight: 400,
                color: ({ palette }) => palette.greyscale[700]
            }
        },
        "& > *:first-child": {
            marginBottom: ({ spacing }) => spacing(1)
        }
    },
    addSsoButtonsSection: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: ({ spacing }) => spacing(2),
        "& > *:first-child": {
            color: ({ palette }) => palette.secondary[500],
            marginRight: ({ spacing }) => spacing(1)
        }
    },
    labelWrap: {
        "& label": {
            whiteSpace: "break-spaces"
        }
    },
    addSsoContentBox: {
        width: ({ spacing }) => spacing(50),
    },

    // SSO User Config
    ssoConfigHeading: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: ({ spacing }) => spacing(2)
    },
    configEditButton: {
        color: ({ palette }) => palette.secondary[500],
        "& svg": {
            fill: "white"
        }
    },
    configDeleteButton: {
        color: ({ palette }) => palette.critical[500],
        "& svg": {
            fill: "white"
        }
    },
    configListingItem: {
        display: "flex",
        marginBottom: ({ spacing }) => spacing(2)
    },
    configListingName: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "20px",
        color: ({ palette }) => palette.greyscale[800]
    },
    configListingValue: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "20px",
        color: ({ palette }) => palette.greyscale[700]
    },
    modalFooterText: {
        fontFamily: "Inter"
    },
    selectedFileName: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        maxWidth: "70%"
    },
    closeModalContainer: {
        textAlign: "right",
        "& button": {
            padding: 0
        }
    },
    fileUploadSelect: {
        paddingBottom: ({ spacing }) => spacing(1),
        marginBottom: ({ spacing }) => spacing(2)
    },
    fileBrowserContainer: {
        width: "100%",
        maxHeight: 300,
        overflow: "scroll"
    }
};
