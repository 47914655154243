import Backbone from "backbone";
import _ from "underscore";
import AppOverviewTable from "./tables/appoverviewtable";
import NodeOverviewTable from "./tables/nodeoverviewtable";
import template from "./templates/clustertable.html";

var ClusterTable = {};

ClusterTable.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(template),

    regions: {
        appOverview: ".appOverviewTable",
        nodeOverview: ".nodeOverviewTable",
    },

    initialize: function () {},

    onRender: function () {
        this.appOverview.show(
            new AppOverviewTable.View({
                model: this.model,
            })
        );
        this.nodeOverview.show(
            new NodeOverviewTable.View({
                model: this.model,
            })
        );
    },
});

export default ClusterTable;
