import React, { useState, useEffect } from "react";
import { StriimTypography } from "@striim/striim-ui";
import useStyles from "./about-modal-content.styles";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";

const AboutContent = () => {
    const [version, setVersion] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [clusterName, setClusterName] = useState("");
    const classes = useStyles();

    useEffect(() => {
        let { version, clusterName, companyName } = propertyTemplateService.getServerInfo();
        setVersion(version);
        setCompanyName(companyName);
        setClusterName(clusterName);
    }, []);
    return (
        <div id={"modal--about-striim"}>
            <StriimTypography variant={"h3"}>Version: {version}</StriimTypography>
            <ul>
                <li>
                    {companyName ? (
                        <StriimTypography>Registered to: {companyName}</StriimTypography>
                    ) : (
                        <StriimTypography>Registered to: N/a </StriimTypography>
                    )}
                </li>
                <li>
                    {" "}
                    <StriimTypography>Cluster Name: {clusterName}</StriimTypography>
                </li>
            </ul>
            <StriimTypography>
                The trademarks and logos on this page are held by their respective owners. Striim, Inc. is an
                independent company and nothing on this page is intended to suggest or imply a relationship with the
                third party vendors on this page.
            </StriimTypography>
            <p>
                <StriimTypography className={classes.specialCharacters}>&reg;</StriimTypography> and{" "}
                <StriimTypography className={classes.specialCharacters}>&copy;</StriimTypography> 2012-
                {new Date().getFullYear()} Striim
                <StriimTypography> Inc. All Rights Reserved.</StriimTypography>
            </p>
        </div>
    );
};

export default AboutContent;
