import Backbone from "backbone";
import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import BaseEditor from "app/components/common/editor/meta-object-editors/base";

var Module = function(metadata_object, options) {
    options = options || {};
    options.displayFields = options.displayFields || [
        "contextType",
        "_headerEventTypes",
        "eventTypes",
        "_headerPersistence",
        "persistence"
    ];

    options.StaticControls = {
        _headerEventTypes: UIControl.Header("EVENT TYPE")
            .extend({
                tag: "h3",
                classList: ["head", "header", "wactionheader"]
            })
            .create(),
        _headerPersistence: UIControl.Header("PERSISTENCE")
            .extend({
                tag: "h3",
                classList: ["head", "header", "persistenceheader"]
            })
            .create()
    };

    BaseEditor.apply(this, arguments);
};

Module.prototype = _.extend({}, BaseEditor.prototype, {
    addCustomFields: function() {
        this.fieldMapper.contextType = UIControl.Type.extend({
            hideLabel: true,
            collapsed: true
        }).create();
        this.fieldMapper.contextType.on(
            "type-saved",
            function() {
                this.fieldMapper.eventTypes.refreshItems();
                this.Form.view.clearDirty(this.fieldMapper.contextType.model);
            }.bind(this)
        );

        this.fieldMapper.eventTypes = UIControl.TypeAndFieldList("Add Event Type")
            .extend({
                hideLabel: true
            })
            .create();

        this.fieldMapper.persistence = UIControl.WactionstorePersistence.extend({
            subControl: true,
            hideLabel: true
        }).create();
    },
    postRender: function() {
        this.fieldMapper.contextType.setValue(this.MetaObjectModel.contextType).then(
            function() {
                this.Form.view.clearDirty(this.fieldMapper.eventTypes.model);
                this.Form.view.clearDirty(this.fieldMapper.contextType.model);
            }.bind(this)
        );
    }
});

export default Module;
