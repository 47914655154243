import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { GridView, ListView } from "src/generic/icon/customIcons";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
/**
 * Component that lets the user to switch between 'grid' and 'list' views
 */

const useStyles = makeStyles(theme => ({
    root: {
        overflow: "hidden",
        borderRadius: "2px",
        display: "inline-flex",
        margin: "-1px 0"
    },
    option: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "24px",
        padding: theme.spacing(1),
        borderRadius: "5px"
    },
    optionSelected: {
        backgroundColor: themeV2 => themeV2.palette.greyscale[300]
    }
}));

const DisplayMode = ({ className, mode, onModeChange }) => {
    const themeV2 = useTheme();
    const classes = useStyles(themeV2);

    const rootClassName = classNames(classes.root, className);

    return (
        <div className={rootClassName}>
            <span
                className={classNames({
                    [classes.option]: true,
                    [classes.optionSelected]: mode === "list"
                })}
                onClick={mode === "grid" ? onModeChange : () => {}}
                data-test-id={"list-view-button"}
            >
                <ListView />
            </span>
            <span
                className={classNames({
                    [classes.option]: true,
                    [classes.optionSelected]: mode === "grid"
                })}
                onClick={mode === "list" ? onModeChange : () => {}}
                data-test-id={"grid-view-button"}
            >
                <GridView />
            </span>
        </div>
    );
};

DisplayMode.propTypes = {
    className: PropTypes.string,
    mode: PropTypes.oneOf(["grid", "list"]),
    onModeChange: PropTypes.func
};

DisplayMode.defaultProps = {
    mode: "list",
    onModeChange: () => {}
};

export default DisplayMode;
