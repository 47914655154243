import api from "core/api/api";
import { runTql } from "core/services/queryService/queryService";
import stringUtils from "core/utils/string-utils";

function getDateValue(date) {
    return `TO_DATE(TO_LONG('${date.toDate().getTime()}'))`;
}

function getRealApi() {
    return {
        loadExceptions: function(appModel, storeName, searchTerm, dateFrom, dateTo, components) {
            return new Promise((resolve, reject) => {
                const clauses = [];
                let nsName = appModel.get("nsName");
                //TODO: verify
                if (searchTerm) {
                    clauses.push(`anyattrlike(e,"${stringUtils.ensureEndsWith(searchTerm, "%")}")`);
                }

                if (dateFrom) {
                    clauses.push(`exceptionTime > ${getDateValue(dateFrom)}`);
                }

                if (dateTo) {
                    clauses.push(`exceptionTime < ${getDateValue(dateTo)}`);
                }

                if (components && components.length > 0) {
                    const componentsClauses = components.map(x => `entityName = "${x}"`);
                    clauses.push(`(${componentsClauses.join(" or ")})`);
                }

                let whereClauseString = "";
                if (clauses.length > 0) {
                    whereClauseString = `where ${clauses.join(" and ")}`;
                }

                const query = `select * from ${nsName}.${storeName} e ${whereClauseString} order by ExceptionTime desc LIMIT 100;`;
                runTql(query, storeName, appModel.get("nsName"))
                    .then(
                        result => {
                            const exceptions = result;
                            resolve(exceptions);
                        },
                        error => {
                            resolve([]);
                        }
                    )
                    .catch(e => {
                        reject(e);
                        console.error("EXCEPTION STORE: Can't load exceptions", e);
                    });
            });
        },

        turnOn: function(appId) {
            return new Promise((resolve, reject) => {
                api.createOrUpdateExceptionstore(appId)
                    .then(result => {
                        resolve(result === "Completed.");
                    })
                    .fail(err => {
                        reject(err);
                    });
            });
        },

        turnOff: function(appId) {
            return new Promise(resolve => {
                api.dropExceptionStore(appId).then(result => {
                    resolve(result === "Completed.");
                });
            });
        },

        checkExceptionsEnabled: function(appId) {
            return new Promise(resolve => {
                api.exceptionStoreExists(appId).then(response => {
                    resolve(response);
                });
            });
        },

        countExceptions: function(nsName, storeName, applicationUUID) {
            const query = `SELECT count(*) as exceptionsCount FROM ${nsName}.${storeName} WHERE appid = "${applicationUUID}";`;
            return new Promise(async (resolve, reject) => {
                try {
                    let data = await runTql(query, storeName, nsName);
                    data = data[0];
                    resolve(data["exceptionsCount"]);
                } catch (e) {
                    reject(e);
                }
            });
        },

        loadComponentsList: function(storeName, nsName) {
            return new Promise((resolve, reject) => {
                const query = `select distinct entityName from ${nsName}.${storeName} e order by ExceptionTime desc LIMIT 100;`;
                runTql(query, storeName, nsName)
                    .then(result => {
                        const components = result.map(x => x.entityName);
                        resolve(components);
                    })
                    .catch(e => {
                        reject(e);
                        console.error("Can't load components list", e);
                    });
            });
        },

        manageHistory: function(appId, interval) {
            return new Promise(resolve => {
                api.createOrUpdateExceptionstore(appId, interval).then(result => {
                    resolve(result === "Completed.");
                });
            });
        },

        getManageHistory: function(appId) {
            return new Promise(resolve => {
                api.getExceptionStoreTTL(appId).then(timeToLive => {
                    resolve(timeToLive);
                });
            });
        },

        getAppVersions: function(appId) {
            return new Promise(resolve => {
                api.getVersionIDs(appId).then(result => {
                    console.log("RESSSS", result);
                    resolve(result);
                });
            });
        }
    };
}

export default getRealApi;
