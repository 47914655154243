import React, { useEffect, useRef } from "react";
import loadBackboneApp from "../../../app/load-backbone-app";
import { showFloatingView, ParentFrame } from "../../../app/components/debug/debug";
import { DebugNetworkView } from "../../../app/components/debug/debug-network";

export default function Debug() {
    const initializedRef = useRef(false);

    useEffect(() => {
        if (initializedRef.current) {
            return;
        }
        var parentFrame = new ParentFrame();
        loadBackboneApp(parentFrame);
        const debugNetworkView = new DebugNetworkView();
        parentFrame.getRegion("frameContent").show(debugNetworkView);
        showFloatingView();
        initializedRef.current = true;
    }, []);

    return <></>;
}
