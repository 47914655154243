export const styles = {
    iconWrapper: {
        cursor: "pointer",
        padding: 1 / 4,
        "& svg": {
            width: "20px",
            height: "20px",
            fill: "white"
        },
        height: "20px"
    },
    text: {
        letterSpacing: "0.14px"
    },
    securityImage: {
        width: "100px",
        height: "71.3px"
    },
    newTabIcon: {
        fill: ({ palette }) => palette.additional.white
    },
    infoNewTabIcon: {
        fill: ({ palette }) => palette.greyscale[100]
    },
    link: {
        paddingX: 0.5,
        paddingY: 0,
        height: "18px",
        fontSize: "12px",
        fontWeight: 400
    },
    infoBox: {
        ".MuiCardHeader-root": {
            padding: 1.25
        }
    },
    infoIcon: {
        width: 16,
        height: 16,
        marginTop: 1 / 4,
        fill: ({ palette }) => palette.greyscale[100]
    },
    infoText: {
        letterSpacing: "0.24px"
    },
    closeIcon: {
        fontSize: 20,
        fill: 'none',
        cursor: 'pointer'
    },
    iconButton: {
        minWidth: 'unset',
        px: 1,
        py: 1 / 2,
        "& .MuiButton-startIcon": {
            mx: 0,
        }
    },
};
