import App from "app";
import Backbone from "backbone";
import _ from "underscore";
import api from "core/api/api";
import queryService from "core/services/queryService/queryService";

const MAX_NUMBER_OF_EVENTS = 100;

export default function (streamModel) {
    _.extend(this, Backbone.Events);
    var isRunning = false;
    var nsName = streamModel.get("nsName").toUpperCase();
    var name = streamModel.get("name").toUpperCase();
    var eventID = nsName + "." + name + ":data";
    var streamName = nsName + "." + name + "";
    var that = this;
    App.vent.on(eventID, function (event) {
        that.numberOfEvents++;
        if (isRunning) {
            if (that.numberOfEvents <= MAX_NUMBER_OF_EVENTS) {
                that.trigger("onDataLoaded", event.event.data);
            }
        }
    });

    this.numberOfEvents = 0;

    this.start = function () {
        isRunning = true;
        that.numberOfEvents = 0;
        api.showStream(streamName, MAX_NUMBER_OF_EVENTS)
            .done(function () {
                isRunning = true;
            })
            .fail(function (e) {
                isRunning = false;
            });
    };

    this.stop = function () {
        isRunning = false;
        App.vent.off(eventID);
    };
}
