import App from "app";
import Backbone from "backbone";
import _ from "underscore";
import utils from "core/utils";
import "app/components/dashboard/visualization/charts/table/table";
import NestedTypes from "backbone.nestedtypes";
import $ from "jquery";
import { showAlertsFor } from "app/components/alertmanager/alertmanager";
import dateLib from "core/utils/date-utils";

var NodeOverviewTable = {};

var Table = App.module("Chart.Table");

NodeOverviewTable.View = Backbone.Marionette.LayoutView.extend({
    template: _.template('<div class="table no-vertical-borders"></div>'),

    regions: {
        table: ".table"
    },

    modelEvents: {
        change: "update"
    },
    events: {
        "click .alertButton": "showAlerts"
    },
    columns: [],

    initialize: function() {
        this.setColumns([
            {
                label: "Name",
                srcfield: "label"
            },
            {
                label: "Uptime",
                srcfield: "uptime"
            },
            {
                label: "Used Memory",
                srcfield: "memoryUsed"
            },
            {
                label: "Total Memory",
                srcfield: "memoryTotal"
            },
            {
                label: "Errors",
                srcfield: "errorCnt"
            }
        ]);
    },

    setColumns: function(columns) {
        this.columns = columns;
        this.columns.forEach(function(column) {
            column.drilldown = {
                show_drilldown: false
            };
        });
    },

    onRender: function() {
        var TableModel = NestedTypes.Model.extend({
            defaults: {
                config: {}
            }
        });
        var tableView = new Table.View({
            model: new TableModel()
        });
        tableView.chartConfig = {
            config: {
                show_headers: true,
                pagesize: Number.POSITIVE_INFINITY,
                columns: this.columns,
                data: this.model.nodeOverview.map(this.dataMapper)
            }
        };
        this.table.show(tableView);
    },

    cleanEmptyCells: list =>
        JSON.parse(JSON.stringify(list, (key, value) => (value === undefined ? "Fetching…" : value))),

    update: function() {
        var tableView = this.table.currentView;
        tableView.chartConfig.config.data = this.cleanEmptyCells(this.model.nodeOverview.map(this.dataMapper));
        this.setParentHeight(this.model.nodeOverview.length);
        tableView.render();
    },

    dataMapper: function(server) {
        return {
            label: server["full-name"],
            uptime: dateLib.duration(server["most-recent-data"].uptime).humanize(),
            memoryUsed: utils.bytesFormatter(
                server["most-recent-data"]["memory-max"] - server["most-recent-data"]["memory-free"]
            ),
            memoryTotal: utils.bytesFormatter(server["most-recent-data"]["memory-max"]),
            errorCnt: server["most-recent-data"]["remote-misses"] || "No Errors"
        };
    },
    setParentHeight: function(recordLength) {
        if (recordLength > 0) {
            var parentHeight = 35 * recordLength + 45;
            this.$el.parent().css("min-height", parentHeight);
        }
    },
    showAlerts: function(e) {
        var id = $(e.target).attr("data-id");
        showAlertsFor("Global.SERVER." + id);
    }
});

export default NodeOverviewTable;
