import { FlowStatus } from "src/status-management";

const appFilterMap = () => {
    const filterMap = new Map();
    filterMap.set("Created", app => app.flowStatus === FlowStatus.CREATED);
    filterMap.set("Deployed", app => app.flowStatus === FlowStatus.DEPLOYED);
    filterMap.set("Halted", app => app.flowStatus === FlowStatus.HALT);
    filterMap.set("Quiesced", app => app.flowStatus === FlowStatus.QUIESCED);
    filterMap.set("Running", app => app.flowStatus === FlowStatus.RUNNING);
    filterMap.set("Stopped", app => app.flowStatus === FlowStatus.STOPPED);
    filterMap.set("Terminated", app => {
        return app.flowStatus === FlowStatus.CRASH || app.flowStatus === FlowStatus.TERMINATED;
    });
    return filterMap;
};

export default appFilterMap;
