import App from "app";
import NestedTypes from "backbone.nestedtypes";
import ApplicationController from "lib/controllers/application_controller";
import dataVisualization from "core/services/metadataService/dataVisualizations";
import side_menu_template from "./sidemenu.html";
import menu_item_template from "./sidemenu_item.html";
import pages_composite_template from "./sidemenu_pages_page.html";
import pages_item_template from "./sidemenu_page_item_view.html";
import visualizations_composite_template from "./sidemenu_visualizations_page.html";
import visualizations_item_template from "./sidemenu_visualization_item.html";
import parameters_composite_template from "./sidemenu_parameters_page.html";
import parameters_item_template from "./sidemenu_parameters_item.html";
import "perfectscrollbar";
import _ from "underscore";
import ensureRegions from "../../common/_ensureRegions";
import { mousePos } from "../../common/drag-and-drop";
import Dropdown from "../../common/dropdown";
import menuOption from "../../common/menu/menu-option";
import menu from "../../common/menu/menu";

/****************************************************************************
 * Dashboard.SideMenu Module
 * This module defines the side menu used with the modular dashboard
 *
 */
App.module("Dashboard.SideMenu", function(SideMenu, App, Backbone, Marionette, $, _) {
    SideMenu.Model = NestedTypes.Model.extend({
        defaults: {
            dashboard: null,
            pages: null,
            buttons: null,
            selected: null
        }
    });

    /****************************************************************************
     * SideMenu.View
     * Renders the View for the sideMenu
     * Adds region: buttonRegion, contentRegion
     *
     */
    SideMenu.View = Backbone.Marionette.LayoutView.extend({
        template: _.template(side_menu_template),
        className: "side-menu-content",
        regions: {
            buttonRegion: ".side-menu-buttons-content",
            contentRegion: ".side-menu-page-content"
        },
        // The buttons view will let us know when its currently-selected button has changed.
        // We listen to this event in onRender, set our own selected, and show/hide our menu in response.
        showOrHideMenu: function() {
            ensureRegions(this);
            var id = this.model.get("selected");
            if (id != null) this.showMenu(id);
            else this.hideMenu();
        },
        showMenu: function(id) {
            ensureRegions(this);
            this.$el.addClass("menu-open");
            // Get the View associated with this page id
            var pageview;
            switch (id) {
                case "pages-button":
                    pageview = new SideMenu.PagesView({
                        model: new SideMenu.PagesModel({
                            pages: this.model.get("pages")
                        })
                    });
                    break;
                case "visualizations-button":
                    pageview = new SideMenu.VisualizationsLayoutView();
                    break;
                case "parameters-button":
                    var page = this.model.dashboard.pages.findWhere({
                        name: this.model.dashboard.get("current_page")
                    });
                    pageview = new SideMenu.ParametersCompositeView({
                        page_parameters: page.get("params"),
                        page: page
                    });
                    break;
            }

            // Render the page view
            this.getRegion("contentRegion").show(pageview);

            // No view should be directly accessing or modifying DOM elements outside itself, so firing an event instead and letting the appropriate view take care of setting its state/CSS class (in this case the page, which owns the .visualizations div)
            App.vent.trigger("dashboard:menu:open");
        },
        hideMenu: function() {
            ensureRegions(this);
            this.$el.removeClass("menu-open");
            this.getRegion("contentRegion").empty();
            App.vent.trigger("dashboard:menu:close");
        },
        onRender: function() {
            ensureRegions(this);
            // Show or hide menu initially, based on the initial selected (in our currently implementation it will just be null, but the view can be instantiated with a certain selected already set, in which case we want to show that flyout view right off the bat)
            this.showOrHideMenu();

            // Subsequently, if the selected changes (in handleSelectedButtonChange), show or hide the menu.
            this.listenTo(this.model, "change:selected", this.showOrHideMenu);

            var collectionView = new SideMenu.CollectionView({
                model: this.model,
                collection: this.model.buttons
            });

            // Render the buttons
            this.getRegion("buttonRegion").show(collectionView);

            // The buttons view will trigger a "menu:button:click" event, with the ID of the button that was clicked
            // We handle this in handleSelectedButtonChange and set our own selected, which will determine whether to show or close the flyout menu (showOrHideMenu, which will fire when our selected changes)
            this.listenTo(collectionView, "menu:button:click", this.handleSelectedButtonChange);
        },
        // When the buttons view notifies us that the user has clicked/unclicked a button, we set our own selected
        handleSelectedButtonChange: function(selected_button_id) {
            this.model.set("selected", selected_button_id);
        }
    });

    /****************************************************************************
     * SideMenu.ItemView
     * Renders a single button used in SideMenu.CollectionView
     *
     */
    SideMenu.ItemView = Backbone.Marionette.ItemView.extend({
        template: _.template(menu_item_template),
        tagName: "div",
        className: "side-menu-button",
        modelEvents: {
            //'change': 'refreshView'
            "change:selected": "renderSelectedState",
            "change:enabled": "renderSelectedState"
        },
        // Let the button handle its own click event
        events: {
            click: "handleButtonClick"
        },
        /**
         * this function gets the ID of the view and returns a tooltip text;
         * @returns {string}
         * @private
         */
        _getToolTipText: function() {
            var id = this.model.get("id");
            var tool_tip_text = null;
            switch (id) {
                case "pages-button":
                    tool_tip_text = "Pages";
                    break;
                case "visualizations-button":
                    tool_tip_text = "Visualizations";
                    break;
                case "parameters-button":
                    tool_tip_text = "Drill-down parameters";
                    break;
            }
            return tool_tip_text;
        },
        onRender: function() {
            var that = this;
            this.$el.tooltipster({
                debug: false,
                theme: "tooltipster-dashboard",
                autoClose: true,
                interactive: false,
                position: "right",
                content: that._getToolTipText(),
                offsetY: -4
            });
        },
        initialize: function() {
            this.renderSelectedState();
        },
        renderSelectedState: function() {
            if (!this.model.enabled) this.$el.hide();
            else this.$el.show();

            if (this.model.get("selected")) this.$el.addClass("active-button");
            else this.$el.removeClass("active-button");
        },
        handleButtonClick: function() {
            // Let the buttons view know the button has been clicked
            // The buttons view will do the work of setting its buttons active or inactive.
            this.trigger("click");
        }
    });

    /****************************************************************************
     * SideMenu.CollectionView
     * Renders a collection of menu items and adds event handlers
     *
     */
    SideMenu.CollectionView = Backbone.Marionette.CollectionView.extend({
        childView: SideMenu.ItemView,
        className: "side-menu-buttons-collection",
        initialize: function() {
            // No need to do this manually, collection is automatically set
            // this.collection = options.collection;
            this.listenTo(this, "childview:click", this.handleButtonClick);
            this.setButtonStates();

            // Go through our buttons and set each one's active state accordingly
            this.listenTo(this.model, "change:selected", this.setButtonStates);
        },
        handleButtonClick: function(childview) {
            var id = childview.model.id;

            // If we clicked the same button as is already selected, clear the currently-selected button.
            if (this.model.get("selected") == id) this.model.set("selected", null);
            else this.model.set("selected", id);

            // Let the side menu know that our currently-selected button has been updated
            this.trigger("menu:button:click", this.model.get("selected"));
        },
        setButtonStates: function() {
            var selected = this.model.get("selected");
            this.collection.each(function(button) {
                var isSelected = selected == button.get("id");
                button.set("selected", isSelected);
            });
        },
        onAddChild: function(childView) {
            var id = childView.model.get("id");
            var data_id = childView.model.get("data_id");
            childView.$el.attr("id", id);
            childView.$el.attr("data-test-id", data_id);
        }
    });

    /****************************************************************************
     * SideMenu.PagesItemView
     * Renders a single Page Item
     *
     */
    // jxnote: single page item
    SideMenu.PagesItemView = Backbone.Marionette.ItemView.extend({
        template: _.template(pages_item_template),
        tagName: "li",
        triggers: {
            "click .collection-item": "click",
            "click .delete-button": "delete",
            "click .edit-button": "edit"
        },
        ui: {
            dropdown: ".dropdown-button"
        },
        events: {
            "click @ui.dropdown": "onPreventDefault"
        },
        modelEvents: {
            "change:_is_current": "render",
            "change:title": "render",
            "change:enabled": "render"
        },

        onPreventDefault: function(e) {
            e.stopPropagation();
            e.preventDefault();
        },

        createMenu: function(menuOptions) {
            var _this = this;
            this.actions = new menuOption.Model.Collection();

            menuOptions.forEach(function(mi) {
                var miModel = new menuOption.Model(mi);
                miModel.id = mi.id;
                _this.actions.add(miModel);
            });

            var menuView = new menu.View({
                model: new menu.Model({
                    options: _this.actions
                })
            });

            _this.listenTo(menuView, "option:click", function(optionId) {
                menuView.trigger("dropdown:hide");
                var menuAction = _this["execute_" + optionId + "Action"];
                if (menuAction) {
                    menuAction.bind(this)();
                } else {
                    alert(optionId);
                }
            });

            return menuView;
        },

        execute_editButtonAction: function() {
            App.vent.trigger("dashboard:edit_page", this.model.id);
        },

        execute_deleteButtonAction: function() {
            App.vent.trigger("dashboard:delete_page", this.model.name);
        },

        onRender: function() {
            var _this = this;

            _this.menu = this.createMenu([
                {
                    id: "editButton",
                    text: "Edit"
                },
                {
                    id: "deleteButton",
                    text: "Delete"
                }
            ]);

            this.itemDropdown = new Dropdown({
                parentView: _this,
                content: _this.menu,
                trigger: _this.ui.dropdown,
                position: "bottom"
            });
        },

        onShow: function() {
            var _this = this;

            this.ui.dropdown.tooltipster({
                position: "bottom",
                functionReady: function(_origin, _tooltip) {
                    _this.itemDropdown.hide();
                }
            });
        },

        onBeforeDestroy: function() {
            this.itemDropdown.destroy();
        }
    });

    /****************************************************************************
     * SideMenu.PagesView
     * Composite View to display page
     *
     */
    SideMenu.PagesModel = NestedTypes.Model.extend({
        defaults: {
            pages: null
        }
    });
    SideMenu.PagesView = Backbone.Marionette.CompositeView.extend({
        tagName: "div",
        className: "side-menu-page",
        template: _.template(pages_composite_template),
        childView: SideMenu.PagesItemView,
        childViewContainer: ".pages",
        events: {
            "click #new_page": "handleNewPage"
        },
        initialize: function() {
            // Fetch pages first, and add them to the View collection once they are ready
            this.collection = this.model.get("pages");
            this.model.get("pages").each(function(page) {
                page.fetch();
            });

            this.listenTo(this, "childview:click", function(childview) {
                this.handleChangePage(childview.model.name);
            });
        },
        onRender: function() {
            var _this = this;
            setTimeout(function() {
                _this.$el.perfectScrollbar();
            }, 0);
        },
        handleNewPage: function() {
            App.vent.trigger("dashboard:add_page");
        },
        handleChangePage: function(pageId) {
            App.vent.trigger("dashboard:show_page", pageId);
        }
    });

    /****************************************************************************
     * SideMenu.VisualizationsItemView
     * Renders a single Page Item
     *
     */
    SideMenu.VisualizationsItemView = Backbone.Marionette.ItemView.extend({
        template: _.template(visualizations_item_template),
        className: "vis-element-wrapper",
        tagName: "div",
        onRender: function() {
            this.setAllowedVisuzations();
            var _this = this;
            this.$(".vis-swatch.active")
                .drag("init", function(ev, dd) {
                    dd.event = "new";
                    dd.itemtype = _this.model.get("data_type");
                    dd.item = _this.model.get("model");
                    var dvtype = dataVisualization[dd.itemtype];
                    var ratio =
                        dvtype && dvtype.default_dimensions
                            ? dvtype.default_dimensions
                            : {
                                  width: 6,
                                  height: 4
                              };
                    dd.preferred_component_dimensions = ratio;
                    dd.component_dimensions = $.extend(true, {}, dd.preferred_component_dimensions);
                    App.vent.trigger("palette:draginit");
                })
                .drag("start", function() {
                    var $proxy = $("<div/>", {
                        class: "proxy swatch new",
                        html: '<i class="icon fa fa-plus"></i>'
                    }).appendTo("#dashboard"); // TODO: parameterize proxy container
                    $(this).addClass("lifted");
                    return $proxy;
                })
                .drag("drag", function(ev, dd) {
                    dd.snap = !ev.shiftKey;
                    $(dd.proxy).css({
                        top: mousePos.clientY - $(dd.proxy).outerHeight() * 0.5,
                        left: mousePos.clientX - $(dd.proxy).outerWidth() * 0.5
                    });
                    App.vent.trigger("palette:drag", null, ev, dd);
                })
                .drag("end", function(ev, dd) {
                    $(this).removeClass("lifted");
                    $(dd.proxy).remove();
                    if (dd.$placeholder) dd.$placeholder.remove();
                    App.vent.trigger("palette:dragend");
                    delete dd.$placeholder;
                    delete dd.proxy;
                });
        },
        setAllowedVisuzations: function() {
            var advancedVisualizations = ["ThreatMap", "ImageMap"];
            var $content;
            if (
                !App.request("security:isAdvancedVisualizationsLicense") &&
                advancedVisualizations.indexOf(this.model.get("data_type")) !== -1
            ) {
                this.$el.find("div").addClass("disabled");
                $content = $(
                    '<div class="tooltip-text">' +
                        this.model.get("name") +
                        ": Disabled due to license restrictions.</div>"
                );
                this.$el.tooltipster({
                    debug: false,
                    theme: "tooltipster-dashboard",
                    autoClose: true,
                    interactive: false,
                    position: "bottom",
                    content: $content,
                    offsetY: -4
                });
            } else {
                this.$el.find("div").addClass("active");
            }
        },
        serializeData: function() {
            var data = this.model.toJSON();
            data["icon"] = data["icon"].replace("_THEME_", App.Metadata.Themes.WebActionLight._themename);
            return data;
        }
    });

    /****************************************************************************
     * SideMenu.VisualizationsView
     * Composite View to display page
     *
     */
    SideMenu.VisualizationsView = Backbone.Marionette.CollectionView.extend({
        tagName: "div",
        className: "swatches sidebar-cell-container",
        childView: SideMenu.VisualizationsItemView,
        childViewContainer: ".swatches",
        initialize: function() {
            this.collection = new Backbone.Collection(this.model.get("items"));
        }
    });
    SideMenu.QueriesView = SideMenu.VisualizationsView.extend({
        getChildView: function(model) {
            var template = model.get("model")
                ? '<div class="vis-swatch">{{ name }}</div>'
                : '<div class="vis-swatch new"><span class="fa fa-plus icon"></span>{{ name }}</div>';
            return SideMenu.VisualizationsItemView.extend({
                className: "query",
                template: _.template(template)
            });
        }
    });

    /****************************************************************************
     * SideMenu.LayoutView
     * Layout View to display page sections
     *
     */
    SideMenu.VisualizationsLayoutView = Backbone.Marionette.LayoutView.extend({
        template: _.template(visualizations_composite_template),
        className: "side-menu-page",
        regions: {
            visualizationsRegion: ".visualizations_list_container"
        },
        events: {
            "click .accordion-trigger": "toggleAccordion"
        },
        initialize: function() {
            this.listenTo(App.vent, "queries-change", this.renderQueries);
        },
        toggleAccordion: function(e) {
            var $section = $(e.target).closest(".section");
            $section.toggleClass("closed");

            if ($section.is(".intro")) {
                sessionStorage.setItem("webaction-dashboard-intro", $section.is(".closed") ? "closed" : "open");
            }
        },
        renderVisualizations: function() {
            var visualizationsView = new SideMenu.VisualizationsView({
                model: new Backbone.Model({
                    items: visualItems
                })
            });

            this.getRegion("visualizationsRegion").show(visualizationsView);
        },
        onRender: function() {
            this.renderVisualizations();

            // TODO: move sessionStorage reading to Model
            var intro_status = sessionStorage.getItem("webaction-dashboard-intro");
            if (intro_status == "closed") {
                this.$(".section.intro").addClass("closed");
            }

            var _this = this;
            setTimeout(function() {
                _this.$el.perfectScrollbar();
            }, 0);
        }
    });

    /****************************************************************************
     * SideMenu.QueriesCompositeItemView
     * Renders a single query item
     *
     */
    SideMenu.QueriesCompositeItemView = Backbone.Marionette.ItemView.extend({
        template: _.template(visualizations_item_template),
        tagName: "div",
        className: "dashboard-draggable-visualization",
        modelEvents: {
            change: "refreshView"
        },
        refreshView: function() {
            this.render();
        }
    });

    SideMenu.ParametersItemView = Backbone.Marionette.ItemView.extend({
        template: _.template(parameters_item_template),
        tagName: "span"
    });

    /*SideMenu.ParametersCollectionView = Backbone.Marionette.CollectionView.extend({
        childView: SideMenu.ParametersItemView,
        tagName: 'div'

    });*/

    /****************************************************************************
     * SideMenu.ParametersView
     * Composite View to show and clear page parameters
     *
     */
    SideMenu.ParametersCompositeView = Backbone.Marionette.CompositeView.extend({
        tagName: "div",
        className: "side-menu-page",
        template: _.template(parameters_composite_template),
        childView: SideMenu.ParametersItemView,
        childViewContainer: ".page-parameters",
        initialize: function(options) {
            this.page = options.page;

            this.buildCollection();

            var _this = this;
            this.listenTo(this.page, "change:params", function() {
                _this.buildCollection();
                _this.render();
            });

            this.listenTo(App.vent, "change:dashboard:current_page", function(page) {
                _this.page = page;

                _this.buildCollection();
                _this.render();
            });
        },
        onRender: function() {
            var _this = this;
            setTimeout(function() {
                _this.$el.perfectScrollbar();
            }, 0);

            this._refreshClearAll();
        },
        ui: {
            clearAll: "#js-clear-all"
        },
        events: {
            "click #js-clear-all": "handleClearAll"
        },

        _refreshClearAll: function() {
            if (this.ui.clearAll.toggleClass) {
                const hasParameters = this.collection.length > 0;
                this.ui.clearAll.toggleClass("hidden-element", !hasParameters);
            }
        },

        buildCollection: function() {
            this.collection = new Backbone.Collection();
            var page_parameters = this.page.get("params");
            for (var key in page_parameters) {
                if (page_parameters.hasOwnProperty(key)) {
                    this.collection.push({
                        key: key,
                        value: page_parameters[key]
                    });
                }
            }

            this._refreshClearAll();
        },
        handleClearAll: function(evt) {
            evt.preventDefault();
            App.vent.trigger("dashboard:page:clearparams");
            this.buildCollection();
            this.render();
        }
    });

    /****************************************************************************
     * SideMenu.Controller
     * Controller for this module
     *
     */
    SideMenu.Controller = ApplicationController.extend({
        initialize: function() {}
    });
});

const visualItems = _.sortBy(
    [
        {
            data_type: "LineChart",
            name: "Line"
        },
        {
            data_type: "AreaChart",
            name: "Area"
        },
        {
            data_type: "BarChart",
            name: "Bar"
        },
        {
            data_type: "ScatterPlot",
            name: "Scatter"
        },
        {
            data_type: "Icon",
            name: "Icon"
        },
        {
            data_type: "Value",
            name: "Value"
        },
        {
            data_type: "Label",
            name: "Label"
        },
        {
            data_type: "PieChart",
            name: "Pie"
        },
        {
            data_type: "ColumnRangeChart",
            name: "Column Range"
        },
        {
            data_type: "DonutChart",
            name: "Donut"
        },
        {
            data_type: "VectorMap",
            name: "Vector Map"
        },
        {
            data_type: "LeafletMap",
            name: "Leaflet Map"
        },
        {
            data_type: "Table",
            name: "Table"
        },
        {
            data_type: "Gauge",
            name: "Gauge"
        },
        {
            data_type: "Bubble",
            name: "Bubble"
        },
        {
            data_type: "HeatMap",
            name: "HeatMap"
        },
        //DEV-12920: hide Predictive Chart icon
        // {
        //     icon: '/app/components/dashboard/images/_THEME_/icon_linechart.png',
        //     data_type: 'PredictiveChart',
        //     name: 'Predictive Chart'
        // },
        {
            data_type: "WordCloud",
            name: "Word Cloud"
        },
        {
            data_type: "Choropleth",
            name: "Choropleth"
        },
        {
            data_type: "ImageMap",
            name: "Image Map"
        },
        {
            data_type: "ThreatMap",
            name: "Threat Map"
        },
        {
            data_type: "SelectVisualization",
            name: "Select"
        }
    ].map(el => ({
        ...el,
        icon: `/app/images/striimline/dashboard-vis/${el.name.replace(" ", "-").toLowerCase()}.svg`
    })),
    "name"
);
