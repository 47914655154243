import React, { useEffect, useState, useCallback } from "react";
import growl from "../../../../../../app/components/common/growl";
import { VaultsEmpty, VaultsPresent } from ".";
import VaultsService from "./vaults-service";
import { Card } from "../components";
import LoadingIndicator from "../../../../../generic/loading-indicator/loading-indicator";

const VaultsPage = () => {
    const [vaults, setVaults] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getComponent = useCallback(() => {
        if (isLoading) {
            return <LoadingIndicator isGlobal={false} />;
        } else if (vaults?.length) {
            return <VaultsPresent vaults={vaults} onAdded={refreshVaults} />;
        } else {
            return <VaultsEmpty onAdded={refreshVaults} />;
        }
    }, [isLoading, vaults]);

    const refreshVaults = () => {
        setIsLoading(true);
        return VaultsService.getVaults()
            .then(vaults => {
                setVaults(vaults);
            })
            .finally(() => {
                setIsLoading(false);
            })
            .catch(() => {
                growl.error("Oops, we can't seem to find the vaults you requested");
            });
    };

    useEffect(() => {
        refreshVaults();
    }, []);

    return <Card>{getComponent()}</Card>;
};

export default VaultsPage
