export const styles = {
    container: {
        border: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
        borderRadius: '8px'
    },
    barColor: '#7E7ED6',
    caption: {
        color: "greyscale.700",
        fontFamily: 'Inter',
        letterSpacing: '0.2px'
    }
}