import { action, observable } from "mobx";

class LocalStore {
    @observable selected = null;
    @observable fileToUpload = null;
    @observable directoryToUpload = null;
    @observable defaultPath = null;

    constructor() {}

    @action setSelected(value) {
        this.selected = value;
    }

    @action setFileToUpload(value) {
        this.fileToUpload = value;
    }

    @action setDirectoryToUpload(value) {
        this.directoryToUpload = value;
    }

    @action setDefaultPath(value) {
        this.defaultPath = value;
    }
}

export default LocalStore;
