export default function (value) {
    if (!value) {
        return false;
    }
    // in a single node clusters the value is not an object but a primitive bool
    if (typeof value === "boolean") {
        return value;
    }
    if (typeof value === "string") {
        try {
            const parsed = JSON.parse(value.toLowerCase());
            if (typeof parsed === "object") {
                // streamFull contains data like "S192_168_23_136: true"
                // when at least one cluster node has true then stream should be marked as back-pressured
                for (const key in parsed) {
                    if (parsed[key]) {
                        return true;
                    }
                }
            } else {
                return !!parsed;
            }
        } catch (e) {
            console.error(e);
            return false;
        }
    }
    return false;
}
