import React from "react";
import { StriimTypography } from "@striim/striim-ui";

const formatText = text => (
    <StriimTypography key={text} variant="bodySemibold" data-test-id="semibold-tesult">
        {text}
    </StriimTypography>
);

const filterUserId = (dataToFilter, filterValue) => {
    return { ...dataToFilter.find(data => data["User Id"] === filterValue) };
};

const filterRoleName = (dataToFilter, filterValue) => {
    return { ...dataToFilter.find(data => data.name === filterValue) };
};

const escapeRegExp = string => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const filterAllRoles = (dataToFilter, filterValue) => {
    return dataToFilter.reduce((results, data) => {
        const matchIndex = data.roles?.findIndex(v => v.name === filterValue);
        if (matchIndex !== -1) {
            let allRoles = [...data.roles];
            //reorders the list, to place the matched item at index 0
            allRoles.sort((x, y) => {
                if (x.name === filterValue) {
                    return -1;
                }
            });
            const roleFound = { ...allRoles[0] };
            roleFound.formattedName = formatText(roleFound.name);
            allRoles[0] = roleFound;
            results.push({ ...data, roles: allRoles });
        }
        return results;
    }, []);
};

const filterAllPermissions = (dataToFilter, filterValue) => {
    return dataToFilter.reduce((results, data) => {
        const matchIndex = data.permissionsText?.findIndex(v => v.name === filterValue);
        if (matchIndex !== -1) {
            let allPermissions = [...data.permissionsText];
            allPermissions.sort((x, y) => {
                if (x.name === filterValue) {
                    return -1;
                }
            });
            const permissionMatch = { ...allPermissions[0] };
            permissionMatch.formattedName = formatText(permissionMatch.name);
            allPermissions[0] = permissionMatch;
            results.push({ ...data, permissionsText: allPermissions });
        }
        return results;
    }, []);
};

const getFilterMessage = (filterValues, filteredData, rolesMatch, permissionsMatch, isFilterForUserTable) => {
    let filterCount = { user: 0, role: 0, permission: 0 };
    filterValues.reduce((filterCount, v) => {
        if (v.filter?.toLowerCase().startsWith("user:")) {
            filterCount.user++;
        } else if (v.filter?.toLowerCase().startsWith("role:")) {
            filterCount.role++;
        } else if (v.filter?.toLowerCase().startsWith("permission:")) {
            filterCount.permission++;
        }
        return filterCount;
    }, filterCount);

    let filterMessage =
        ((isFilterForUserTable && filterCount.user === 0) || !isFilterForUserTable) &&
        (filterCount.role > 0 || filterCount.permission > 0)
            ? rolesMatch.length > 0
                ? ` associated with ${
                      rolesMatch.length > 1 && permissionsMatch.length === 0
                          ? `'${rolesMatch.join("' and '")}'`
                          : `'${rolesMatch.join("', '")}'`
                  } ${permissionsMatch.length > 0 ? ` and permission '${permissionsMatch.join("', '")}'` : ``}`
                : permissionsMatch.length > 0
                ? ` associated with permission '${permissionsMatch.join("', '")}'`
                : ``
            : ``;
    filterMessage = `${filteredData.length} ${isFilterForUserTable ? "user" : "role"}${
        filteredData.length > 1 ? "s" : ""
    }${filterMessage} found`;
    return filterMessage;
};

export { formatText, filterUserId, filterRoleName, filterAllRoles, filterAllPermissions, getFilterMessage };
