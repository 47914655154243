import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import { StriimDropdown, StriimIconButton, StriimMenuList, StriimMenuItem } from "@striim/striim-ui";
import { MoreHoriz } from "@mui/icons-material";

import TestConnectionModal from "./test-connection-modal";
import { styles } from "../connection-profile-list.styles";
import connectionProfileService from "../../connection-profile-service";
import growl from "../../../../../../../../app/components/common/growl";
import DeleteConnectionProfileModal from "./delete-connection-profile-modal";
import api from "../../../../../../../../core/api/api";

interface DropDownModalProps {
    connectionProfile: ConnectionProfile;
    refreshConnectionProfiles: () => void;
    setShowDeleteWarning: (value: boolean) => void;
    setCurrentCpName: (value: string) => void;
}

const DropDownModal = ({
    connectionProfile,
    refreshConnectionProfiles,
    setShowDeleteWarning,
    setCurrentCpName
}: DropDownModalProps) => {
    const name = connectionProfile.nsName + "." + connectionProfile.name;
    const menuState = useState(false);
    const navigate = useNavigate();
    const [showTestConnectionModal, setShowTestConnectionModal] = useState<boolean>(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
    const [hasUpdatePermission, setHasUpdatePermission] = useState<boolean>(false);
    const [hasDropPermission, setHasDropPermission] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                const isUpdateAllowedForCP = await api.checkPermission(connectionProfile.uuid, "update");
                const isDropAllowed = await api.checkPermission(connectionProfile.uuid, "drop");
                setHasUpdatePermission(isUpdateAllowedForCP);
                setHasDropPermission(isDropAllowed);
            } catch (error) {
                growl.error(error);
            }
        })();
    }, []);

    const handleView = () => {
        navigate(`/manage-striim/connection-profiles/view/${name}`);
    };

    const handleEdit = () => {
        const name = connectionProfile.nsName + "." + connectionProfile.name;
        navigate(`/manage-striim/connection-profiles/edit/${name}`);
    };

    const handleDelete = async () => {
        try {
            await connectionProfileService.deleteConnectionProfile(name);
            //@ts-ignore
            growl.success(`Connection profile ${connectionProfile.name} deleted successfully`);
            refreshConnectionProfiles();
        } catch (error) {
            //@ts-ignore
            growl.error(error);
            setShowDeleteConfirmationModal(false);
            setCurrentCpName(connectionProfile.nsName + "." + connectionProfile.name);
            setShowDeleteWarning(true);
        }
    };

    const menu = (
        <StriimMenuList>
            <StriimMenuItem data-test-id={`view-menu-${name}`} onClick={handleView}>
                View
            </StriimMenuItem>
            {hasUpdatePermission && (
                <StriimMenuItem data-test-id={`edit-menu-${name}`} onClick={handleEdit}>
                    Edit
                </StriimMenuItem>
            )}
            <StriimMenuItem
                data-test-id={`test-connection-menu-${name}`}
                onClick={() => {
                    setShowTestConnectionModal(true);
                }}
            >
                Test Connection
            </StriimMenuItem>
            {hasDropPermission && (
                <StriimMenuItem
                    data-test-id={`delete-menu-${name}`}
                    sx={styles.deleteLink}
                    onClick={() => setShowDeleteConfirmationModal(true)}
                >
                    Delete
                </StriimMenuItem>
            )}
        </StriimMenuList>
    );

    return (
        <Grid container>
            <div onClick={e => {}}>
                <StriimDropdown extendOnClick closeOnSelect content={menu} controlState={menuState}>
                    <StriimIconButton data-testid={`menu-${name}`}>
                        <Box component={MoreHoriz} />
                    </StriimIconButton>
                </StriimDropdown>
            </div>
            {showTestConnectionModal && (
                <TestConnectionModal
                    cpName={name}
                    testCPModalVisible={showTestConnectionModal}
                    setTestCPModalVisible={setShowTestConnectionModal}
                />
            )}
            {showDeleteConfirmationModal && (
                <DeleteConnectionProfileModal
                    isVisible={showDeleteConfirmationModal}
                    setShowDeleteConfirmationModal={setShowDeleteConfirmationModal}
                    onDeleteClick={handleDelete}
                />
            )}
        </Grid>
    );
};

export default DropDownModal;
