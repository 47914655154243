import React, { useEffect, useMemo, useState } from "react";
import { TableCell, TableRow, Collapse, Grid, Box, IconButton } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import styles from "../../table-progress.styles";
import { ChevronDown, ChevronUp } from "../../../../generic/icon/customIcons";
import { Writer, Writers, tableProgress } from "../../table-progress-types";
import { formatDate } from "../../table-progress.store";

type Props = {
    expandAllRows: boolean;
    row: tableProgress;
};
const defaultObj = { 1: "1" };

const RowCDC: React.FunctionComponent<Props> = ({ row, expandAllRows }) => {
    const { tableName, writers, sourceComponentName } = row;
    const [open, setOpen] = useState<boolean>(expandAllRows);

    useEffect(() => {
        setOpen(expandAllRows);
    }, [expandAllRows]);
    const writersInfo = useMemo(() => Object.values(writers ?? defaultObj), [writers]);
    return (
        <React.Fragment>
            <TableRow sx={styles.rowStyles}>
                <TableCell>
                    <Grid>
                        {writersInfo?.map(
                            (_writerInfo, idx: number): React.ReactElement => {
                                return (
                                    <div key={idx}>
                                        <Box className="rowVisibleContent">
                                            {idx === 0 ? (
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => setOpen(!open)}
                                                >
                                                    {open ? <ChevronUp /> : <ChevronDown />}
                                                </IconButton>
                                            ) : null}
                                        </Box>
                                        <div>
                                            <Collapse in={open} timeout="auto" unmountOnExit></Collapse>
                                        </div>
                                    </div>
                                );
                            }
                        )}
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid>
                        {writersInfo?.map((writerInfo, idx) => {
                            return (
                                <div key={idx}>
                                    <Box className="rowVisibleContent">
                                        {idx === 0 ? (
                                            <>
                                                <div>
                                                    <StriimTypography variant="body4">{tableName}</StriimTypography>
                                                </div>
                                                <div>
                                                    <StriimTypography variant="caption3">
                                                        {sourceComponentName}
                                                    </StriimTypography>
                                                </div>
                                            </>
                                        ) : null}
                                    </Box>
                                    <Collapse in={open} timeout="auto" unmountOnExit></Collapse>
                                </div>
                            );
                        })}
                    </Grid>
                </TableCell>
                <TargetTableNameComponentCDC row={row} open={open} />
                <RecordsReadComponentCDC row={row} open={open} />
                <RecordsWrittenComponentCDC row={row} open={open} />
                <LastWriteTimeCDC row={row} open={open} />
            </TableRow>
        </React.Fragment>
    );
};
type CellProps = {
    row: tableProgress;
    open: boolean;
};

const LastWriteTimeCDC: React.FunctionComponent<CellProps> = ({ row, open }) => {
    const { writers } = row;
    return (
        <TableCell>
            <Grid>
                {Object.values(writers ?? defaultObj)?.map((writerInfo, idx) => {
                    return (
                        <div key={idx}>
                            <Box className="rowVisibleContent">
                                <StriimTypography variant="body4">
                                    {formatDate(writerInfo?.lastWriteTime)}
                                </StriimTypography>
                            </Box>
                            <Collapse in={open} timeout="auto" unmountOnExit></Collapse>
                        </div>
                    );
                })}
            </Grid>
        </TableCell>
    );
};
const RecordsWrittenComponentCDC: React.FunctionComponent<CellProps> = ({ row, open }) => {
    const { writers } = row;
    return (
        <TableCell>
            <Grid>
                {Object.values(writers ?? defaultObj)?.map(
                    (writerInfo, idx): React.ReactElement => {
                        return (
                            <div key={idx}>
                                <Box className="rowVisibleContent">
                                    <StriimTypography variant="body4">{writerInfo?.eventsWritten}</StriimTypography>
                                </Box>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <Box sx={styles.column}>
                                        <Box className="rowelem">
                                            <StriimTypography variant="caption3" sx={{ color: "greyscale.700" }}>
                                                Inserts
                                            </StriimTypography>
                                            <StriimTypography variant="caption3" sx={{ color: "primary.700" }}>
                                                {writerInfo?.["No of Inserts"] ?? writerInfo?.["No of inserts"]}
                                            </StriimTypography>
                                        </Box>

                                        <Box className="rowelem">
                                            <StriimTypography variant="caption3" sx={{ color: "greyscale.700" }}>
                                                Updates
                                            </StriimTypography>
                                            <StriimTypography variant="caption3" sx={{ color: "primary.700" }}>
                                                {writerInfo?.["No of Updates"] ?? writerInfo?.["No of updates"]}
                                            </StriimTypography>
                                        </Box>

                                        <Box className="rowelem">
                                            <StriimTypography variant="caption3" sx={{ color: "greyscale.700" }}>
                                                Deletes
                                            </StriimTypography>
                                            <StriimTypography variant="caption3" sx={{ color: "primary.700" }}>
                                                {writerInfo?.["No of Deletes"] ?? writerInfo?.["No of deletes"]}
                                            </StriimTypography>
                                        </Box>

                                        <Box className="rowelem">
                                            <StriimTypography variant="caption3" sx={{ color: "greyscale.700" }}>
                                                PKUpdates
                                            </StriimTypography>
                                            <StriimTypography variant="caption3" sx={{ color: "primary.700" }}>
                                                {writerInfo?.["No of PKUpdates"] ?? writerInfo?.["No of pkupdates"]}
                                            </StriimTypography>
                                        </Box>

                                        <Box className="rowelem">
                                            <StriimTypography variant="caption3" sx={{ color: "greyscale.700" }}>
                                                DDLs
                                            </StriimTypography>
                                            <StriimTypography variant="caption3" sx={{ color: "primary.700" }}>
                                                {writerInfo?.["No of DDLs"]}
                                            </StriimTypography>
                                        </Box>
                                    </Box>
                                </Collapse>
                            </div>
                        );
                    }
                )}
            </Grid>
        </TableCell>
    );
};
const RecordsReadComponentCDC: React.FunctionComponent<CellProps> = ({ row, open }) => {
    const { writers, ...data } = row;
    return (
        <TableCell>
            <Grid>
                {Object.values(writers ?? { 1: "1" })?.map((_writerInfo, idx) => {
                    return (
                        <div key={idx}>
                            <Box className="rowVisibleContent">
                                <StriimTypography variant="body4">{data?.eventsRead ?? 0}</StriimTypography>
                            </Box>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box sx={styles.column}>
                                    <Box className="rowelem">
                                        <StriimTypography variant="caption3" sx={{ color: "greyscale.700" }}>
                                            Inserts
                                        </StriimTypography>
                                        <StriimTypography variant="caption3" sx={{ color: "primary.700" }}>
                                            {data?.["No of Inserts"] ?? data?.["No of inserts"]}
                                        </StriimTypography>
                                    </Box>

                                    <Box className="rowelem">
                                        <StriimTypography variant="caption3" sx={{ color: "greyscale.700" }}>
                                            Updates
                                        </StriimTypography>
                                        <StriimTypography variant="caption3" sx={{ color: "primary.700" }}>
                                            {data?.["No of Updates"] ?? data?.["No of updates"]}
                                        </StriimTypography>
                                    </Box>

                                    <Box className="rowelem">
                                        <StriimTypography variant="caption3" sx={{ color: "greyscale.700" }}>
                                            Deletes
                                        </StriimTypography>
                                        <StriimTypography variant="caption3" sx={{ color: "primary.700" }}>
                                            {data?.["No of Deletes"] ?? data?.["No of deletes"]}
                                        </StriimTypography>
                                    </Box>
                                    {data?.["No of PKUpdates"] !== undefined ||
                                    data?.["No of pkupdates"] !== undefined ? (
                                        <Box className="rowelem">
                                            <StriimTypography variant="caption3" sx={{ color: "greyscale.700" }}>
                                                PKUpdates
                                            </StriimTypography>
                                            <StriimTypography variant="caption3" sx={{ color: "primary.700" }}>
                                                {data?.["No of PKUpdates"] ?? data?.["No of pkupdates"]}
                                            </StriimTypography>
                                        </Box>
                                    ) : null}
                                    {data?.["No of DDLs"] !== undefined ? (
                                        <Box className="rowelem">
                                            <StriimTypography variant="caption3" sx={{ color: "greyscale.700" }}>
                                                DDLs
                                            </StriimTypography>
                                            <StriimTypography variant="caption3" sx={{ color: "primary.700" }}>
                                                {data?.["No of DDLs"]}
                                            </StriimTypography>
                                        </Box>
                                    ) : null}
                                </Box>
                            </Collapse>
                        </div>
                    );
                })}
            </Grid>
        </TableCell>
    );
};
const TargetTableNameComponentCDC: React.FunctionComponent<CellProps> = ({ row, open }) => {
    const { writers } = row;
    return (
        <TableCell>
            <Grid>
                {Object.values(writers ?? { 1: "1" })?.map((writerInfo, idx) => {
                    return (
                        <div key={idx}>
                            <Box className="rowVisibleContent">
                                <Box>
                                    <StriimTypography variant="body4">{writerInfo?.tableName}</StriimTypography>
                                </Box>
                                <Box>
                                    <StriimTypography variant="caption3">
                                        {writerInfo?.targetComponentName}
                                    </StriimTypography>
                                </Box>
                            </Box>
                            <Collapse in={open} timeout="auto" unmountOnExit></Collapse>
                        </div>
                    );
                })}
            </Grid>
        </TableCell>
    );
};
export { LastWriteTimeCDC, RecordsWrittenComponentCDC, RecordsReadComponentCDC, TargetTableNameComponentCDC, RowCDC };
