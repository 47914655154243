import Backbone from "backbone";
import _ from "underscore";
import templateList from "./conditions-list.html";
import Condition from "./condition";

export default Backbone.Marionette.CompositeView.extend({
    childView: Condition.View,
    childViewContainer: ".conditions-list",
    template: _.template(templateList),
    ui: {
        addCondition: ".addCondition a"
    },
    events: {
        "click @ui.addCondition": "addCondition"
    },
    childEvents: {
        removeCondition: "removeCondition",
        groupCollapsed: "groupCollapsed",
        modified: "modified"
    },
    setType: function(type, changedType) {
        this.type = type;
        if (changedType) {
            this.collection.remove(this.collection.models);
        } else {
            this.collection.each(item => {
                item.set("type", type);
            });
        }
        if (this.collection.length === 0) {
            this.collection.add(
                new Condition.Model({
                    isExpanded: true,
                    type: type
                })
            );
        }
    },
    setGroupBy: function(groupBy) {
        this.groupBy = groupBy;
        // this.collection.sort();
        this.collection.each(model => {
            model.set("renderHeader", false);
            model.set("groupCollapsed", false);
            model.set("groupBy", groupBy);
        });
        if (groupBy) {
            const groupedStreams = this.collection.groupBy("outputStream");
            Object.keys(groupedStreams).forEach(streamId => {
                const list = groupedStreams[streamId];
                list.forEach((model, indexInGroup) => {
                    model.set("renderHeader", indexInGroup === 0);
                });
            });
        }
    },
    setTQLMode: function(tqlMode) {
        this.tqlMode = tqlMode;
        this.collection.each(item => {
            item.set("tqlMode", tqlMode);
        });
    },
    addCondition: function() {
        this.collection.each(item => {
            item.set("isExpanded", false);
        });

        const model = new Condition.Model({
            type: this.type,
            tqlMode: this.tqlMode
        });
        this.collection.add(model);
        model.set("isExpanded", true);
        this.modified();
    },
    removeCondition: function(childView) {
        this.collection.remove(childView.model);
        this.modified();
    },
    modified: function() {
        this.trigger("modified");
        this.setGroupBy(this.groupBy);
        this.refreshDeleteStatus();
    },
    setEnabled: function(enabled) {
        this.ui.addCondition.toggle(enabled);
        this.children.each(childView => {
            childView.setEnabled(enabled);
        });
    },
    groupCollapsed: function(childView) {
        const outputStream = childView.model.get("outputStream");
        this.collection
            .filter(x => x.get("outputStream") === outputStream)
            .forEach(item => {
                item.set("groupCollapsed", !item.get("groupCollapsed"));
            });
    },
    refreshDeleteStatus: function() {
        this.children.each(view => {
            view.model.set("canRemove", this.children.length > 1);
        });
    },
    onRender: function() {
        this.refreshDeleteStatus();
    }
});
