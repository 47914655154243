import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var module = {};

export const ModelProviderType = {
    OpenAI: "OpenAI",
    VertexAI: "VertexAI"
};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "EMBEDDINGGENERATOR",
        modelProvider: ModelProviderType.OpenAI,
        properties: Object,
        nsName: String,
        name: String
    }
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model
});

export default module;
