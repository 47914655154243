import _ from "underscore";
import Checkbox from "./../checkbox/checkbox";
import template from "./toggle.html";

var View = Checkbox.View.extend({
    template: _.template(template),

    ui: _.extend(
        {
            label: "label.toggle",
        },
        Checkbox.View.prototype.ui
    ),

    initialize: function (opts) {
        var _this = this;
        this.listenTo(this.model, "change:value", function () {
            _this.refresh();
        });

        if (!this.model.get("description")) {
            this.model.set("description", opts.description || "");
        }

        //FIXME must be active on start in #metadatamanager in Persist. upd: this should be fixed in #metadatamanager view
    },
    onRender: function () {
        if (this.options && this.options.labelClass) {
            this.ui.label.addClass(this.options.labelClass);
        }
        this.refresh();
    },
    refresh: function () {
        Checkbox.View.prototype.onRender.call(this);
        this.$(".toggle").toggleClass("active", this.getValue());
        this.$(".toggle").toggleClass("disabled", !this.getEnabled());
    },
});

export default _.extend({}, Checkbox, {
    View: View,
});
