import _ from "underscore";
import numeral from "numeral";
import utils from "core/utils";
import statusManagement, { FlowStatus } from "src/status-management";
import formatter from "app/components/monitor/common/valueFormatter";
import isBackpressured from "app/components/flow/designer/is-backpressured";
import dateLib from "core/utils/date-utils";
var calculator = {};

calculator.calculateServerStats = function(serverStats, previousModel) {
    // START calculating server CPU
    var total_cpu = 0;
    var total_cores = 0;
    let all_clusters_total_cores = 0;
    var cpu_rate = 0;
    var server_map = {};
    let totalServers = 0;
    _.each(serverStats, function(serverStat) {
        if (serverStat["most-recent-data"]) {
            server_map[serverStat["most-recent-data"]["full-name"]] = serverStat;
            totalServers++;
        }
    });

    // START calculating server CPU
    _.each(server_map, function(serverStat) {
        total_cpu += serverStat["most-recent-data"]["cpu-rate"] ? serverStat["most-recent-data"]["cpu-rate"] : 0;
        all_clusters_total_cores += serverStat["most-recent-data"]["cores"]
            ? serverStat["most-recent-data"]["cores"]
            : 0;
    });

    if (all_clusters_total_cores === 0) {
        // Happens when we get historic report. It does not have cores, so get from previous.
        all_clusters_total_cores = previousModel.infopanel.cpuCores;
    }
    cpu_rate = formatter.toPercentageString(total_cpu / all_clusters_total_cores);

    if (all_clusters_total_cores === 0) {
        cpu_rate = formatter.toPercentageString(0);
    } else {
        cpu_rate = Math.round((total_cpu / all_clusters_total_cores) * 1000) / 10;
    }
    // END calculating server CPU

    // START calculating server memory
    var memory_max = 0;
    var memory_free = 0;
    _.each(server_map, function(serverStat) {
        if (
            serverStat["most-recent-data"] &&
            serverStat["most-recent-data"]["memory-max"] &&
            serverStat["most-recent-data"]["memory-free"]
        ) {
            memory_max += serverStat["most-recent-data"]["memory-max"];
            memory_free += serverStat["most-recent-data"]["memory-free"];
        }
    });
    // END calculating server memory

    var nodesDown = serverStats.filter(function(app) {
        return app.status === FlowStatus.NOT_ENOUGH_SERVERS || statusManagement.isApplicationCrash(app.status);
    }).length;

    var nodesRunning = serverStats.filter(function(app) {
        return app.status === FlowStatus.RUNNING;
    }).length;

    return {
        total_cores: all_clusters_total_cores,
        cpu_rate: cpu_rate,
        memory_max: memory_max,
        memory_free: memory_free,
        nodesRunning: nodesRunning,
        nodesDown: nodesDown,
        cssClass: nodesDown === 0 ? "safe" : "danger",
        nodesTotal: serverStats.length,
        serverCount: serverStats.length,
        memoryUsed: utils.bytesFormatter(memory_max - memory_free),
        memoryUsedInt: memory_max - memory_free,
        memoryTotal: utils.bytesFormatter(memory_max)
    };
};

calculator.calculateAppStats = function(appStats) {
    var appsDown = appStats.filter(function(app) {
        return (
            app.status === FlowStatus.NOT_ENOUGH_SERVERS ||
            statusManagement.isApplicationCrash(app.status) ||
            !app["is-valid"]
        );
    }).length;
    var appsRunning = appStats.filter(function(app) {
        return app["is-valid"] && app.status === "RUNNING";
    }).length;
    var appsNotRunning = appStats.filter(function(app) {
        return (
            app["is-valid"] &&
            app.status !== FlowStatus.DEPLOY_FAILED &&
            (statusManagement.isDeployedState(app.status) || statusManagement.isApplicationCreated(app.status))
        );
    }).length;
    var appsTotal = appStats.length;
    return {
        appsDown: appsDown,
        appsRunning: appsRunning,
        appsNotRunning: appsNotRunning,
        appsTotal: appsTotal,
        cssClass: appsDown === 0 ? "text-green" : "text-red"
    };
};

calculator.calculateAgentStats = function(agentStats) {
    var agentsDown = agentStats.filter(function(app) {
        return app.status === FlowStatus.NOT_ENOUGH_SERVERS || statusManagement.isApplicationCrash(app.status);
    }).length;

    var agentsRunning = agentStats.filter(function(app) {
        return app.status === FlowStatus.RUNNING;
    }).length;
    var cssClass = agentsDown === 0 ? "safe" : "danger";

    var agentsTotal = agentStats.length;
    return {
        agentsDown: agentsDown,
        agentsRunning: agentsRunning,
        cssClass: cssClass,
        agentsTotal: agentsTotal
    };
};

calculator.calculateMostRecentData = function(mostRecentData, mostRecentESData, mostRecentKafkaData) {
    var uptime = dateLib.duration(mostRecentData.uptime).humanize();
    var throughput = numeral(mostRecentData["source-rate"]).format("0 a"); // DEV-7967  - II
    var wactionsCnt = mostRecentData["wactions-created"];

    var isStreamFull = isBackpressured(mostRecentData["stream-full"]);

    var wactionsPerSecond = mostRecentData["wactions-created-rate"];
    var elasticMemoryFree = mostRecentData["elasticsearch-free"];
    var backpressuredImgClass = isStreamFull ? "color-err" : "color-ok";
    var backPresssuredText = isStreamFull ? "Backpressured" : "Not Backpressured";
    var elasticMemoryThroughput = mostRecentESData !== null ? numeral(mostRecentESData).format("0 a") : "Fetching…";
    var kafkaMemoryThroughput = mostRecentKafkaData !== null ? numeral(mostRecentKafkaData).format("0 a") : "Fetching…";
    return {
        uptime: uptime,
        throughput: throughput,
        wactionsCnt: wactionsCnt,
        wactionsPerSecond: wactionsPerSecond,
        elasticMemoryFree: elasticMemoryFree,
        backpressuredImgClass: backpressuredImgClass,
        backPresssuredText: backPresssuredText,
        elasticMemoryThroughput: elasticMemoryThroughput,
        kafkaMemoryThroughput: kafkaMemoryThroughput
    };
};

export default calculator;
