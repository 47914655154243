var GlobalNamespace = "Global";

export default {
    getName: function (metaObjectId) {
        return this.parse(metaObjectId).name;
    },
    getType: function (metaObjectId) {
        return this.parse(metaObjectId).type;
    },
    getNamespace: function (metaObjectId) {
        return this.parse(metaObjectId).nsName;
    },
    getId: function (namespace, type, name) {
        return namespace + "." + type + "." + name;
    },
    getGlobalId: function (type, name) {
        return this.getId(GlobalNamespace, type, name);
    },
    isGlobal: function (metaObjectId) {
        return this.getNamespace(metaObjectId) === GlobalNamespace;
    },
    isValidId: function (id) {
        if (!id) {
            return false;
        }

        return this.getName(id) && this.getType(id) && this.getNamespace(id);
    },
    toTypedId: function (id, type) {
        var splits = id.split(".");
        return this.getId(splits[0], type, splits[1]);
    },
    isVersioned: function (metaObjectId) {
        return metaObjectId.indexOf("_") !== -1;
    },
    getVersion: function (metaObjectId) {
        return metaObjectId.split("_")[1];
    },
    getWithoutVersion: function (metaObjectId) {
        return metaObjectId.split("_")[0];
    },
    getShortId: function (namespace, name) {
        return namespace + "." + name;
    },
    ensureShortId: function (id, nsName) {
        return id.indexOf(".") !== -1 ? id : this.getShortId(nsName, id);
    },
    ensureFullId: function (id, type) {
        var parsed = this.parse(id);
        if (parsed.type) {
            return id;
        }

        return this.getId(parsed.nsName, type, parsed.name);
    },
    getNameWithoutVersion: function (metaObjectId) {
        return this.getWithoutVersion(this.getName(metaObjectId));
    },

    /**
     *
     * @param {string} metaObjectId - Id of metaobject in short format (nsName.name)
     * @returns {object} parsed metaobject name
     * @private
     */
    _parseShortName: function (metaObjectId) {
        var shortIdRegex = /([a-zA-Z0-9_]+)[.]{1}([a-zA-Z_0-9.]+)/g;
        var val = shortIdRegex.exec(metaObjectId);
        if (val) {
            return {
                nsName: val[1],
                name: val[2],
            };
        }
        return null;
    },

    /**
     * Convert short object name (nsName.name) to full name (nsName.type.name)
     * @param {string} shortMetaObjectId - Id of metaobject in short format (nsName.name)
     * @param {utils.ENTITIES} type - metaobject type
     * @returns {string} - metaobject name in full format {nsName.type.name}
     */
    convertShortNameToFullName: function (shortMetaObjectId, type) {
        var shortName = this._parseShortName(shortMetaObjectId);
        if (!shortName) {
            throw new Error("Wrong shortname format: " + shortMetaObjectId);
        }
        return this.getId(shortName.nsName, type, shortName.name);
    },

    /**
     * Convert short object name (nsName.APPLICATION.name) to short name (nsName.name)
     * @param {string} fullMetaObjectId - Id of metaobject in full format (nsName.TYPE.name)
     * @returns {string} - metaobject name in short format {nsName.name}
     */
    convertFullNameToShortName: function (fullMetaObjectId) {
        var metaobjectInfo = this.parse(fullMetaObjectId);
        if (!metaobjectInfo.nsName || !metaobjectInfo.name) {
            throw new Error("Wrong fullname format: " + fullMetaObjectId);
        }
        return this.getShortId(metaobjectInfo.nsName, metaobjectInfo.name);
    },

    parse: function (metaObjectId) {
        var regex = /([a-zA-Z0-9_]+)[.]{1}([A-Z_]+)[.]{1}([a-zA-Z_0-9.]+)/g;

        var val = regex.exec(metaObjectId);
        if (val) {
            return {
                nsName: val[1],
                type: val[2],
                name: val[3],
            };
        }

        var shortName = this._parseShortName(metaObjectId);
        if (shortName) {
            return shortName;
        }

        return {
            name: metaObjectId,
        };
    },
};
