import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing }) => ({
    wrapper: {
        height: `calc(100vh - 160px)`
    },
    icon: {
        height: 120,
        width: 120,
        marginBottom: spacing(1),
        color: palette.gray3
    },
    title: {
        marginLeft: spacing(6),
        marginBottom: spacing(2)
    },
    description: {
        marginLeft: spacing(6),
        marginBottom: spacing(3)
    }
}));
