import App from "app";
import api from "core/api/api";
import Backbone from "backbone";
import NestedTypes from "backbone.nestedtypes";
import _ from "underscore";
import $ from "jquery";
import growl from "app/components/common/growl";
import DashboardClipboard from "./../DashboardClipboard";
import FiltersBar from "./filtersBar";
import toolbar_template from "./toolbar.html";
import embedDialogTemplate from "./embedDialogTemplate.html";
import ModalManager from "app/components/common/modal/ModalManager";
import UIControl from "app/components/common/editor/control/ui-control";
import FormBuilder from "app/components/common/editor/form-builder";
import { isEmbedDashboard } from "../embedDashboard";
import { isUserAdmin } from "core/user-management/update.control";
import ensureRegions from "../../common/_ensureRegions";

var modalManager = new ModalManager({
    container: "body"
});

var BreadcrumbsItemModel = NestedTypes.Model.extend({
    defaults: {
        pagemodel: null,
        is_current: false,
        className: "",
        permissions: {}
    }
});

var BreadcrumbsView = Backbone.Marionette.CollectionView.extend({
    childView: Backbone.Marionette.ItemView.extend({
        tagName: "span",
        className: "breadcrumb link",
        template: _.template("{{pagemodel.page_title}}"),
        events: {
            click: "navigate"
        },
        initialize: function() {
            this.listenTo(this.model.pagemodel.page, "change:name change:title", this.render);
            this.listenTo(
                App.vent,
                "dashboard:side-menu:page-name-changed",
                function(changedPage) {
                    if (changedPage.id === this.model.pagemodel.page.id) {
                        this.model.pagemodel.page_title = changedPage.title;
                    }
                }.bind(this)
            );

            if (!this.model.is_current) {
                this.$el.addClass("route");
            }
        },
        navigate: function() {
            App.vent.trigger("breadcrumbs:click", this.model.pagemodel.dashboard_url, this.model.pagemodel.page_url);
        }
    }),
    // Wrap the model passed to the child view into another one, to also include a "is_current" property
    buildChildView: function(childModel, ChildViewClass, childViewOptions) {
        var is_current = this.collection.indexOf(childModel) === this.collection.length - 1;
        var options = {
            model: new BreadcrumbsItemModel({
                pagemodel: childModel, // The original child model
                is_current: is_current,
                className: is_current ? "current" : ""
            })
        };
        options = _(options).extend(childViewOptions);
        return new ChildViewClass(options);
    }
});

var BreadcrumbItem = NestedTypes.Model.extend({
    defaults: {
        dashboard_url: null,
        page_url: null,
        page: null,
        page_title: String
    }
});

var BreadcrumbCollection = NestedTypes.Collection.extend({
    model: BreadcrumbItem
});

var Toolbar = {};

Toolbar.Model = NestedTypes.Model.extend({
    defaults: {
        pages: BreadcrumbCollection,
        permissions: {},
        isPlaying: true,
        editable: false,
        isPrebuilt: false,
        hasEditAccessRights: false,
        embeddable: String,
        selectedVisualizations: [],
        clipboard: DashboardClipboard,
        dashboardID: String,
        isEmbedDashboard: false
    }
});

/**
 * The dialog to embed a dashboard into a visualization.
 */
var EmbedDialog = App.FormMixinDialog.View.extend({
    template: _.template(embedDialogTemplate),
    regions: {
        content: ".modal-body .inner",
        embedProperties: ".modal-body .inner #embed-properties-form"
    },
    modelEvents: {
        change: "_generateAndSetEmbedCode"
    },
    ui: {
        copyCodeTextArea: ".modal-body .inner #dashboard-copy-code",
        permissionsList: ".modal-body .inner #permissions-display"
    },
    initialize: function(options) {
        options.bind_submit = true;
        options.submit_on_enter = true;
        options.bind_cancel = true;
        this.dashboardID = options.dashboardID;
        this.model = new Backbone.Model({
            width: 300,
            height: 400,
            border: 0
        });
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
    },
    onRender: function() {
        var that = this;
        var form = new FormBuilder({
            model: this.model,
            readOnly: false,
            mapper: {
                width: UIControl.NumberField.extend({
                    allowNegative: false
                }),
                height: UIControl.NumberField.extend({
                    allowNegative: false
                }),
                border: UIControl.NumberField.extend({
                    allowNegative: false
                })
            },
            labels: {
                width: {
                    name: "Width"
                },
                height: {
                    name: "Height"
                },
                border: {
                    name: "Border"
                }
            },
            autoSave: true
        });
        this.getRegion("embedProperties").show(form.create());

        this.$el.find(".agree").click(function(e) {
            e.preventDefault();
            that.$el.find(".user-details").hide();
            that.$el.find(".embed-code").show();
            that.$el.find(".agree").hide();
            that.$el.find(".copy").show();
            that._generateAndSetEmbedCode();
            that.ui.copyCodeTextArea
                .focus(function() {
                    $(that).select();
                })
                .click(function() {
                    $(that).select();
                });
        });

        this.$el.find(".copy").click(function() {
            that.trigger("close:modal", that);
        });
        this._getDashboardPermissions();
    },
    _makeZeros: function() {
        var keys = ["width", "height", "border"];
        var that = this;
        _.each(keys, function(key) {
            if (that.model.get(key) === "" || _.isNaN(that.model.get(key)) || that.model.get(key) === null) {
                that.model.set(key, 0);
            }
        });
    },
    _generateAndSetEmbedCode: function() {
        var that = this;
        api.createDashboardEmbedUser(this.dashboardID).then(function(token) {
            console.log(token);
            that._makeZeros();
            var embedURL = window.location.href
                .replace("#/dashboard/edit/", "#embed/")
                .replace("#/dashboard/view/", "#embed/");
            var embedCode =
                '<iframe src="' +
                embedURL +
                "/" +
                btoa(token) +
                '" ' +
                'style="width:' +
                that.model.get("width") +
                "px;" +
                "height:" +
                that.model.get("height") +
                "px;" +
                "border:" +
                that.model.get("border") +
                'px"></iframe>';

            that.ui.copyCodeTextArea.html(embedCode);
        });
    },
    _getDashboardPermissions: function() {
        var that = this;
        api.getPermissionsForDashboard(this.dashboardID).then(function(permissions) {
            permissions = _.sortBy(permissions, function(val) {
                return val;
            });
            permissions = permissions.join("\n");
            that.ui.permissionsList.html(permissions);
        });
    }
});

Toolbar.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(toolbar_template),

    initialize: function() {
        var _this = this;

        this.model.isEmbedDashboard = isEmbedDashboard();

        this.listenTo(App.vent, "visualization:toggle:selection", function(selectedVisualizations) {
            _this.model.selectedVisualizations = selectedVisualizations;
        });

        this.listenTo(App.vent, "visualization:deleted", function(visualization) {
            var selectedIndex = _this.model.selectedVisualizations.indexOf(visualization.model);
            if (selectedIndex !== -1) {
                _this.model.selectedVisualizations.splice(selectedIndex, 1);
            }

            _this.model.clipboard.removeItem(visualization.model);
        });

        this.listenTo(App.vent, "dashboard:show_page", function() {
            _this._hideFilters();
        });
        this.model.clipboard.initialize();
    },

    modelEvents: {
        change: "render"
    },

    events: {
        "click .controls .play-pause-button": "_togglePlay",
        "click .controls .export-button": "_export",
        "click .controls .embed-button": "_embed",
        "click .controls .mode-button": "_toggleMode",
        "click .controls .copy-button": "_copy",
        "click .controls .cut-button": "_cut",
        "click .controls .paste-button": "_paste"
    },

    regions: {
        breadcrumbsRegion: ".breadcrumbs",
        filtersBarRegion: ".filters-bar"
    },

    _copy: function() {
        this.model.clipboard.addItems(this.model.selectedVisualizations);
    },

    _cut: function() {
        this.model.clipboard.addItems(this.model.selectedVisualizations);
        App.vent.trigger("dashboard:cut:selection", this.model.selectedVisualizations);
        this.model.selectedVisualizations = [];
    },

    _paste: function() {
        App.vent.trigger("dashboard:paste:selection", this.model.clipboard.getAllItems());
        this.model.clipboard.deactivate();
        this.model.clipboard.clear();
    },

    _togglePlay: function() {
        this.model.isPlaying = !this.model.isPlaying;
        App.vent.trigger(this.model.isPlaying ? "global:play" : "global:pause");
    },

    _toggleMode: function() {
        this.model.editable = !this.model.editable;
        this.model.isPlaying = true;
        if (!this.model.editable) {
            this.model.clipboard.clear();
            this.model.selectedVisualizations = [];
        }
        App.vent.trigger("dashboard:mode:toggle", this.model.editable);

        if (this.model.editable) {
            this._hideFilters();
        } else {
            this._showFilters();
        }
    },

    _export: function() {
        App.vent.trigger("dashboard:export");
    },

    _embed: function() {
        var dialog = new EmbedDialog({
            dashboardID: this.model.get("dashboardID")
        });
        modalManager.add(dialog);
        new App.FormMixinDialog.Controller({
            view: dialog
        });

        dialog.on("close:modal", function(dialog) {
            var $el = dialog.$el.find("#dashboard-copy-code");
            $el.prop("disabled", false);
            $el.select();
            var exec = document.execCommand("copy");
            if (exec) {
                growl.success("The Embeddable dashboard code has been copied to your clipboard.");
            }
            dialog.destroy();
        });
    },

    _hideFilters: function() {
        ensureRegions(this);
        this.filtersBarRegion.empty();
        App.vent.trigger("dashboard:toolbar:hide-filters");
    },

    toggleTimeFilter: function(enabled) {
        ensureRegions(this);

        if (this.isDestroyed || !this.filtersBarModel) {
            return;
        }

        this.filtersBarModel.timeFilterEnabled = enabled;
    },

    toggleFullTextSearchFilter: function(enabled) {
        ensureRegions(this);

        if (this.isDestroyed || !this.filtersBarModel) {
            return;
        }

        this.filtersBarModel.fullTextSearchFilterEnabled = enabled;
    },

    _showFilters: function() {
        ensureRegions(this);

        this.filtersBarModel = new FiltersBar.Model();
        var filtersBarView = new FiltersBar.View({
            model: this.filtersBarModel,
            className: "filter-bar-component"
        });

        this.filtersBarRegion.show(filtersBarView);
    },

    onRender: function() {
        ensureRegions(this);

        var breadcrumbs = new BreadcrumbsView({
            collection: this.model.pages
        });

        this.breadcrumbsRegion.show(breadcrumbs);

        if (!this.model.editable) {
            this._showFilters();
        }

        const isAdmin = isUserAdmin();
        if (App.user.name !== "admin" && !isAdmin) {
            this.$el.find(".embed-button").hide();
        }
    }
});

export default Toolbar;
