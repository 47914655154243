import React, { ReactElement, useRef } from "react";
import numeral from "numeral";
import {
    AppsWithIdentifiers,
    EventsProcessedTable,
    IdentifiersTable,
    SDITable
} from "./report-tables/sentinel-report-tables";
import { StriimTypography } from "@striim/striim-ui-v2";
import { Box, Grid, SvgIcon } from "@mui/material";
import {
    formatIdentifiersTableData,
    formatSDIApplications,
    getGeneratedTime,
    getReadableTime,
    getTopTenElements,
    sortData
} from "./report-tables/report-tables.helper";
import {
    getAllAppsTableData,
    updateIdentifiersData
} from "../../guardian/sentinel-monitor/service/sentinel-monitor-utils";
import InfoIcon from "app/images/striimline/info-fill.svg";
import StriimLogo from "app/images/striim-logo-black.svg";
import { styles } from "../sentinel-reports/global-report/sentinel-global-report.styles";
import { ArrowDownward, ChevronRight } from "../../../../generic/icon/customIcons";
import { AdapterIcon } from "../../../../generic/icon/component-icon/component-icon";
import { AppSentinelCount } from "./report-charts/sentinel-report-charts";
import { nFormatter } from "../../sentinel-live-monitor/components/sentinel-table/utils";
import { GlobalSentinelReportData } from "../../guardian/sentinel-monitor/service/sentinel-monitor.types";

export const pluralize = (word, num = 0) => (num !== 1 ? `${num} ${word}s` : `${num} ${word}`);

interface SectionProps {
    showborderBottom?: boolean;
    children?: ReactElement;
}

export const Section: React.FC<SectionProps> = ({ showborderBottom = true, children }) => {
    return (
        <Grid
            container
            justifyContent={"space-between"}
            py={2}
            {...(showborderBottom ? { borderBottom: "1px solid #BCC9D6" } : {})}
            sx={{ pageBreakInside: "avoid" }}
        >
            {children}
        </Grid>
    );
};

export const SectionTitle = ({ rightContent = null, size = 18, children }) => (
    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "24px 0 24px 0" }}>
        <StriimTypography sx={{ fontFamily: "inter", fontWeight: 400, fontSize: `${size}px !important` }}>
            {children}
        </StriimTypography>
        {rightContent}
    </Box>
);

export const LogoSection = () => {
    return (
        <>
            <Box px={5} py={3}>
                <StriimLogo style={{ width: "200px", height: "100px" }} />
            </Box>
            <div
                style={{
                    background:
                        "linear-gradient(90deg, rgb(229, 14, 92) 0%, rgb(153, 77, 189) 53.12%, rgb(37, 192, 230) 100%)",
                    height: "3px"
                }}
            ></div>
        </>
    );
};

export const DisclaimerSection = ({ type }) => {
    return (
        <Section>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 / 2 }}>
                <SvgIcon component={InfoIcon} sx={styles.infoIcon} />
                <StriimTypography sx={styles.smallFont}>
                    {type} uses AI engines that may sometimes misclassify the information.
                </StriimTypography>
            </Box>
        </Section>
    );
};

export const AppFilterSection = ({
    apps,
    sourceCount,
    sentinelCount,
    username,
    sourceIcons = [],
    targetIcons = []
}) => {
    const appsText = apps.length === 1 ? apps[0] : `${pluralize("App", apps.length)}`;
    return (
        <Section>
            <Box>
                <StriimTypography variant="h1">Sentinel AI Report for {appsText}</StriimTypography>
                {sourceIcons.length ? (
                    <SourceTargetIcons sourceIcons={sourceIcons} targetIcons={targetIcons} />
                ) : (
                    <StriimTypography variant="body" sx={styles.smallFont}>
                        {sourceCount} Sources
                    </StriimTypography>
                )}
            </Box>
            <Box sx={{ textAlign: "end" }}>
                <StriimTypography variant="h2">{pluralize("Sentinel", sentinelCount)}</StriimTypography>
                <StriimTypography variant="body" sx={styles.smallFont}>
                    Report generated by {username}
                </StriimTypography>
            </Box>
        </Section>
    );
};
export const TimeFilterSection = ({ selectedRange, hideFilterTime = false }) => {
    const readableFrom = getReadableTime(selectedRange.from);
    const readableTo = getReadableTime(selectedRange.to);
    const currentTime = Date.now();

    if (hideFilterTime) {
        return (
            <Section>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <StriimTypography variant="body" sx={styles.smallFont}>
                        Generated on
                    </StriimTypography>
                    <StriimTypography variant="h2" sx={styles.timeContainer}>
                        {getGeneratedTime(currentTime)}
                    </StriimTypography>
                </Box>
            </Section>
        );
    }
    return (
        <Section>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <StriimTypography variant="body" sx={styles.smallFont}>
                    Generated For
                </StriimTypography>
                <StriimTypography variant="h2" sx={styles.timeContainer}>
                    {readableFrom} <ArrowDownward style={{ transform: "rotate(-90deg)" }} /> {readableTo}
                </StriimTypography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", textAlign: "end" }}>
                <StriimTypography variant="body" sx={styles.smallFont}>
                    Generated On
                </StriimTypography>
                <StriimTypography variant="body" sx={styles.smallFont}>
                    {getGeneratedTime(currentTime)}
                </StriimTypography>
            </Box>
        </Section>
    );
};

type SummaryProps = {
    totalEvents: number;
    sensitiveEvents: number;
    occurences: number;
};

export const SummarySection = ({ totalEvents, sensitiveEvents, occurences }: SummaryProps) => {
    return (
        <Section>
            <StriimTypography variant="h1">Summary</StriimTypography>
            <Grid container gap={5} my={2}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <StriimTypography>Events W/ Sensitive Data Processed</StriimTypography>
                    <StriimTypography variant="h2">
                        {sensitiveEvents}/ {totalEvents}
                    </StriimTypography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <StriimTypography>Occurrences of Sensitive Data¹</StriimTypography>
                    <StriimTypography variant="h2">{nFormatter(occurences, 2)}</StriimTypography>
                </Box>
            </Grid>
        </Section>
    );
};

const OccurencesHint = () => {
    return (
        <Grid mt={5}>
            <StriimTypography variant="caption" color="rgba(32, 32, 32, 0.50);">
                <span style={{ color: "#161616" }}>1. Occurrences of Sensitive Data:</span> Count of sensitive data
                detected across all fields of all events scanned by Sentinel. An event may contain multiple occurrences
                of sensitive data. Therefore, the occurrences of sensitive data detected may exceed the number of events
                that contain sensitive data.
            </StriimTypography>
        </Grid>
    );
};

export const IdentifiersSection = ({ sensitiveDataIdentifierOccurrences, appLevel = false }) => {
    const [totalEvents, formattedData] = formatIdentifiersTableData(sensitiveDataIdentifierOccurrences, appLevel);
    return (
        <Section>
            <SectionTitle>Top 10 High {appLevel && "& Medium"} Importance Identifiers</SectionTitle>
            <IdentifiersTable data={formattedData} totalEvents={totalEvents} />
            <OccurencesHint />
        </Section>
    );
};

export const EventsProcessedSection = ({ eventsProcessedDataForApps }) => {
    const formattedData = getTopTenElements(getAllAppsTableData(eventsProcessedDataForApps), "sensitiveData");
    const totalEvents = formattedData.reduce((sum, item) => sum + item.events, 0);
    const totalSensitiveDataEvents = formattedData.reduce((sum, item) => sum + item.sensitiveData, 0);

    return (
        <Section>
            <Grid width={"100%"}>
                <SectionTitle>
                    Top {formattedData?.length > 10 ? "10 " : ""}Apps by Events Processed W/ Sensitive Data
                </SectionTitle>
                <EventsProcessedTable
                    data={formattedData}
                    totalEvents={totalEvents}
                    totalSensitiveDataEvents={totalSensitiveDataEvents}
                />
            </Grid>
        </Section>
    );
};

const calculateTotals = data => {
    return Object.values(data).reduce(
        (acc, curr) => {
            return {
                eventsWithIdentifier: acc.eventsWithIdentifier + curr.eventsWithIdentifier,
                occurrences: acc.occurrences + curr.occurrences
            };
        },
        { eventsWithIdentifier: 0, occurrences: 0 }
    );
};

export const SDISection = ({
    sensitiveDataIdentifierOccurrences,
    sensitiveEvents,
    occurences,
    appsCount = 0,
    sentinelsCount = 0,
    isGlobalReport = false
}) => {
    const formattedData = sortData(updateIdentifiersData(sensitiveDataIdentifierOccurrences), "eventsWithIdentifier");
    const { eventsWithIdentifier, occurrences } = calculateTotals(sensitiveDataIdentifierOccurrences);

    return (
        <Section>
            <Grid width={"100%"}>
                <SectionTitle
                    rightContent={
                        isGlobalReport ? (
                            <AppSentinelCount appsCount={appsCount} sentinelsCount={sentinelsCount} />
                        ) : null
                    }
                >
                    Sensitive Data Identifiers Detected
                </SectionTitle>
                <SDITable
                    data={formattedData}
                    totalEvents={numeral(eventsWithIdentifier)
                        .format("0.00a")
                        .toUpperCase()}
                    totalOccurences={numeral(occurrences)
                        .format("0.00a")
                        .toUpperCase()}
                    isGlobalReport={isGlobalReport}
                />
            </Grid>
        </Section>
    );
};

export const SDIAppsSection: React.FC<{
    globalSentinelReportData: GlobalSentinelReportData[];
    appsCount: number;
    sentinelsCount: number;
}> = ({ globalSentinelReportData, appsCount, sentinelsCount }) => {
    let HighSDIs = [],
        MediumSDIs = [];

    globalSentinelReportData.forEach(item => {
        if (item.importanceLevel === "High") HighSDIs.push(item);
        else if (item.importanceLevel === "Medium") MediumSDIs.push(item);
    });

    return (
        <Grid width={"100%"}>
            {HighSDIs?.length ? (
                <Box mt={4}>
                    <SectionTitle
                        rightContent={
                            <StriimTypography sx={styles.smallFont}>
                                {pluralize("App", appsCount)} ({pluralize("Sentinel", sentinelsCount)})
                            </StriimTypography>
                        }
                    >
                        Top Apps with High Importance Identifiers
                    </SectionTitle>
                    {HighSDIs.map(sdi => {
                        const { formattedData, totalEventsWithIdentifiers, totalOccurrences } = formatSDIApplications(
                            sdi.applications
                        );
                        return (
                            <Section key={sdi.sensitiveDataIdentifier}>
                                <Grid width={"100%"} mt={2}>
                                    <SectionTitle size={16}>
                                        Top {HighSDIs.length > 10 ? "10 " : ""}Apps W/ {sdi.sensitiveDataIdentifier}
                                    </SectionTitle>
                                    <AppsWithIdentifiers
                                        data={formattedData}
                                        totalEventsWithIdentifiers={totalEventsWithIdentifiers}
                                        totalOccurrences={totalOccurrences}
                                    />
                                </Grid>
                            </Section>
                        );
                    })}
                </Box>
            ) : null}

            {MediumSDIs?.length ? (
                <Box mt={4}>
                    <SectionTitle
                        rightContent={
                            <StriimTypography sx={styles.smallFont}>
                                {pluralize("App", appsCount)} ({pluralize("Sentinel", sentinelsCount)})
                            </StriimTypography>
                        }
                    >
                        Top Apps with Medium Importance Identifiers
                    </SectionTitle>
                    {MediumSDIs.map(sdi => {
                        const { formattedData, totalEventsWithIdentifiers, totalOccurrences } = formatSDIApplications(
                            sdi.applications
                        );
                        return (
                            <Section key={sdi.sensitiveDataIdentifier}>
                                <Grid width={"100%"} mt={2}>
                                    <SectionTitle size={16}>
                                        Top {MediumSDIs.length > 10 ? "10 " : ""} Apps W/ {sdi.sensitiveDataIdentifier}
                                    </SectionTitle>
                                    <AppsWithIdentifiers
                                        data={formattedData}
                                        totalEventsWithIdentifiers={totalEventsWithIdentifiers}
                                        totalOccurrences={totalOccurrences}
                                    />
                                </Grid>
                            </Section>
                        );
                    })}
                </Box>
            ) : null}
        </Grid>
    );
};

export const DetailedSDI = () => {
    return (
        <Section>
            <StriimTypography variant="h1">Summary</StriimTypography>
            <Grid container gap={5} my={2}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <StriimTypography>Events W/ Sensitive Data Processed</StriimTypography>
                    <StriimTypography variant="h2">
                        {sensitiveEvents}/ {totalEvents}
                    </StriimTypography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <StriimTypography>Occurrences of Sensitive Data¹</StriimTypography>
                    <StriimTypography variant="h2">{occurences}</StriimTypography>
                </Box>
            </Grid>
        </Section>
    );
};

export const SherlockAppReportTitle = ({ appText, username, sourceIcons = [], targetIcons = [] }) => {
    return (
        <Section>
            <Box>
                <StriimTypography variant="h1" style={styles.titleFont}>
                    Sherlock AI Report for {appText}
                </StriimTypography>

                <Grid container gap={1} my={2}>
                    <SourceTargetIcons sourceIcons={sourceIcons} targetIcons={targetIcons} />
                </Grid>
            </Box>
            <Box sx={{ textAlign: "end" }}>
                <StriimTypography variant="body" sx={styles.smallFont}>
                    Report generated by {username}
                </StriimTypography>
            </Box>
        </Section>
    );
};

export const SherlockGlobalReportTitle = ({ appCount = 0, username, sourceCount = 0 }) => {
    return (
        <Section>
            <Box>
                <StriimTypography variant="h1" style={styles.titleFont}>
                    Sherlock AI Report for {pluralize("App", appCount)}
                </StriimTypography>

                <Grid container gap={1} my={2}>
                    <StriimTypography variant="h1" style={styles.subTitleFont}>
                        {pluralize("Source", sourceCount)}
                    </StriimTypography>
                </Grid>
            </Box>
            <Box sx={{ textAlign: "end" }}>
                <StriimTypography variant="body" sx={styles.smallFont}>
                    <Grid container flexDirection={"column"}>
                        <label>Report generated</label>
                        <label>by {username}</label>
                    </Grid>
                </StriimTypography>
            </Box>
        </Section>
    );
};

export const SourceTargetIcons = ({ sourceIcons, targetIcons }) => {
    return (
        <Grid container gap={1} my={2} alignItems={"center"}>
            {sourceIcons.map(source => {
                return (
                    <Box key={source.name} display={"flex"} gap={1} alignItems={"center"}>
                        <AdapterIcon name={source.className} type={"source"} size={32} outlined={true} />
                        <StriimTypography>{source.name}</StriimTypography>
                    </Box>
                );
            })}
            {targetIcons.map((target, index) => {
                return (
                    <Box key={target.name} display={"flex"} gap={1} alignItems={"center"}>
                        {index === 0 && <ChevronRight />}
                        <AdapterIcon name={target.className} type={"target"} size={32} outlined={true} />
                        <StriimTypography>{target.name}</StriimTypography>
                    </Box>
                );
            })}
        </Grid>
    );
};
