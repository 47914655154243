import $ from "jquery";
import _ from "underscore";
import Column from "./column";
import Select from "./../select/select";
import Autocomplete from "./../autocomplete/autocomplete";
import gridTemplate from "./grid-with-function.html";
import rowTemplate from "./column-enricher.html";
import typesDataSource from "./../select/types-datasource.json";
import Backbone from "backbone";
import TqlBuilder from "app/components/common/tqlBuilder";

const ColumnEnricher = {};

ColumnEnricher.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(rowTemplate),
    className: "column-enricher",
    tagName: "tr",

    modelEvents: {
        "change:column": "modified",
        "change:tqlFunction": "modified",
        "change:alias": "modified",
    },

    ui: {
        column: ".column",
        types: ".types",
        expression: ".expression",
        controls: ".action-controls",
        iconClose: ".icon-close",
    },

    regions: {
        typesRegion: ".types",
        expressionRegion: ".expression",
    },

    triggers: {
        "click .icon-close": {
            event: "removeColumn",
            preventDefault: true, // this param is optional and will default to true
            stopPropagation: true,
        },
    },

    events: {
        "keyup @ui.column": "changeColumn",
    },

    initialize: function () {
        this.types = Select(this.options.config.columnsDataSource || typesDataSource, {
            placeholder: "select",
            hideSearch: true,
        }).create();
        this.listenTo(this.types.model, "change:value", (viewModel) => {
            this.model.tqlFunction = viewModel.value;
        });
        this.expression = Autocomplete.extend().create();
        this.listenTo(this.expression.model, "change:value", (viewModel) => {
            this.model.column = TqlBuilder.ensureValidTql(viewModel.value);
        });
    },

    modified: function () {
        this.trigger("modified");
    },

    setColumnsDataSource: function (dataSource) {
        $.when(dataSource).then((columns) => {
            if (_.isArray(columns)) {
                this.expression.setColumns(columns);
            }
        });
    },

    changeColumn: function () {
        this.model.alias = this.ui.column.val();
    },

    setEnabled: function (enabled) {
        enabled = !!enabled;
        this.model.enabled = enabled;
        this.ui.controls.toggle(enabled);
        this.ui.column.prop("disabled", !enabled);
        this.types.setEnabled(enabled);
        this.expression.setEnabled(enabled);
    },

    onRender: function () {
        this.$el.attr("data-backbone-cid", this.model.cid); //TODO: is it really needed?
        this.typesRegion.show(this.types);
        this.types.setValue(this.model.tqlFunction);
        this.expressionRegion.show(this.expression);
        this.expression.setValue(this.model.column);
    },

    onShow: function () {
        this.setColumnsDataSource(this.model.columns);
    },
});

ColumnEnricher.CollectionView = Column.CollectionView.extend({
    template: _.template(gridTemplate),
    childView: ColumnEnricher.View,
    onRender: function () {
        Column.CollectionView.prototype.onRender.apply(this);
        this.$el.find("#column3header").text(this.options.config.labels.column3);
    }
});

export default ColumnEnricher;
