import React from "react";
import { Box, Grid, InputAdornment } from "@material-ui/core";
import { AddCircleOutline as AddIcon, Clear } from "@mui/icons-material";
import { StriimButton, StriimIconButton, StriimInputField, StriimTypography } from "@striim/striim-ui";
import { makeStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";

const useStyles = makeStyles(({ spacing }) => ({
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: spacing(2.5)
    },
    typography: {
        marginBottom: 23
    },
    addButton: {
        display: "flex",
        justifyContent: "flex-end",
        minWidth: "fit-content"
    }
}));

interface EmbeddingGeneratorHeader {
    onAdd: () => void;
    searchTerm: string;
    onSearch: (value: string) => void;
}

export default function EmbeddingGeneratorHeader({ searchTerm, onAdd, onSearch }: EmbeddingGeneratorHeader) {
    const classes = useStyles();

    return (
        <>
            <Grid container className={classes.headerContainer}>
                <Grid item xs={9}>
                    <StriimTypography variant="h2" style={{ marginBottom: 8 }}>
                        Vector Embeddings Generator
                    </StriimTypography>
                    <div className={classes.typography}>
                        <Box>
                            <StriimTypography variant="body">
                                You can create a Vector Embeddings Generator object which can be invoked within a
                                built-in function inside a CQ
                            </StriimTypography>
                        </Box>
                    </div>
                </Grid>
                <Grid item xs={3} className={classes.addButton}>
                    <StriimButton
                        startIcon={<AddIcon />}
                        variant="primary"
                        onClick={onAdd}
                        data-test-id="embedding-generator-list-add"
                    >
                        Vector Embeddings Generator
                    </StriimButton>
                </Grid>
            </Grid>
            <Grid
                container
                style={{
                    gap: "16px",
                    justifyContent: "space-between",
                    marginBottom: 30
                }}
            >
                <Grid item xs={4}>
                    <StriimInputField
                        id="data-test-id-embedding-generator-search"
                        value={searchTerm}
                        onChange={value => {
                            onSearch(value);
                            if (value.length) {
                                onSearch(value);
                            } else {
                                onSearch("");
                            }
                        }}
                        placeholder="Search by profile name or AI model provider"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search opacity={0.45} />
                                </InputAdornment>
                            ),
                            ...(searchTerm && {
                                endAdornment: (
                                    <StriimIconButton
                                        variant="secondaryText"
                                        onClick={() => {
                                            onSearch("");
                                        }}
                                        data-test-id="embedding-generator-clear-search"
                                    >
                                        <Clear />
                                    </StriimIconButton>
                                )
                            })
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}
