import App from "app";
import d3 from "d3";
import "d3cloud";
import "app/components/dashboard/visualization/charts/chart";

App.module("Chart.WordCloud", function (Chart, App, Backbone, Marionette, $, _) {
    Chart.View = App.Chart.Default.ChartView.extend({
        className: "chart icon",
        initialize: function () {
            this.d3svgID = arguments[0].model.attributes.id.replace(/\./g, "-") + "-D3SVG";
            this.template = _.template('<svg id="' + this.d3svgID + '" width="100%" height="100%"></svg>');
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
            Backbone.Marionette.ItemView.prototype.render.apply(this, arguments);
        },
        setConfiguration: function (config) {
            this.chartConfig = config;
        },
        render: function () {
            var _this = this;

            if (!this.chartConfig) {
                return;
            }

            Math.seed = function (s) {
                return function () {
                    s = Math.sin(s) * 10000;
                    return s - Math.floor(s);
                };
            };

            var random1 = Math.seed(42);
            var random2 = Math.seed(random1());
            Math.random = Math.seed(random2());

            var config = this.chartConfig.config;
            var groups = _.groupBy(config.data, config.wordText);

            // 'items' will hold a list of values from groupped items
            var groupped = _.map(groups, function (group) {
                return {
                    key: group[0][config.wordText],
                    items: _.map(group, function (i) {
                        return i[config.wordSize];
                    }),
                    value: group.length,
                };
            });

            // if wordsize was set then include this in calculation
            if (config.wordSize) {
                _.each(groupped, function (group) {
                    group.value = _.reduce(group.items, function (memo, item) {
                        return memo + item;
                    });
                });
            }

            // get max number of words to be displayed from configuration and splice 'data'
            // the default is 50 words per chart
            var data = _.chain(groupped)
                .sortBy(function (item) {
                    return -item.value;
                })
                .first(config.maxWords || 50)
                .value();

            var min = _.min(data, function (o) {
                return o.value;
            });
            var max = _.max(data, function (o) {
                return o.value;
            });

            var fontSizeRange = d3.scale.log();
            fontSizeRange.domain([min.value, max.value]).range([24, 48]);

            var words = [];
            _.each(data, function (item) {
                var obj = {};
                obj[config.wordText] = item.key;
                obj[config.wordSize] = item.items;

                words.push({
                    text: item.key,
                    size: ~~fontSizeRange(item.value),
                    rotate: ~~(Math.random() * 2) * 90,
                    data: obj,
                });
            });

            var width = this.$el.width();
            var height = this.$el.height();

            var svgElement = d3.select("#" + this.d3svgID);

            if (!this.d3SVDGEl) {
                this.d3SVDGEl = svgElement
                    .attr("width", width)
                    .attr("height", height)
                    .append("g")
                    .attr("width", width)
                    .attr("height", height)
                    .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
            } else {
                this.d3SVDGEl = svgElement
                    .attr("width", width)
                    .attr("height", height)
                    .select("g")
                    .attr("width", width)
                    .attr("height", height)
                    .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
            }

            const _showDrilldown = _this.chartConfig.config.drilldown.show_drilldown;

            d3.layout
                .cloud()
                .size([width, height])
                .words(words)
                .padding(5)
                .rotate(function (d) {
                    return d.rotate;
                })
                .font("Impact")
                .fontSize(function (d) {
                    return d.size;
                })
                .on("end", d3SVGdraw)
                .start();

            function d3SVGdraw() {
                var fill = d3.scale.category20();

                _this.d3SVDGEl
                    .selectAll("text")
                    .data(words, function (d) {
                        return d.text;
                    })
                    .enter()
                    .append("text")
                    .style("font-size", function (d) {
                        return d.size + "px";
                    })
                    .style("font-family", "Impact")
                    .style("fill", function (d, i) {
                        return fill(i);
                    })
                    .style("cursor", function () {
                        if (_showDrilldown) {
                            return "pointer";
                        }
                        return "default";
                    })
                    .attr("text-anchor", "middle")
                    .attr("transform", function (d) {
                        return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
                    })
                    .text(function (d) {
                        return d.text;
                    })
                    .on("click", function (d) {
                        if (_showDrilldown) {
                            _this.trigger("datapoint:click", d.data);
                        }
                    });

                _this.d3SVDGEl
                    .selectAll("text")
                    .data(words, function (d) {
                        return d.text;
                    })
                    .attr("transform", function (d) {
                        return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
                    })
                    .text(function (d) {
                        return d.text;
                    })
                    .style("font-size", function (d) {
                        return d.size + "px";
                    });

                _this.d3SVDGEl
                    .selectAll("text")
                    .data(words, function (d) {
                        return d.text;
                    })
                    .exit()
                    .remove();
            }

            if (!this._inDOM()) {
                this.$("svg").hide();
            } else {
                this.$("svg").show();
            }
        },
    });
});
