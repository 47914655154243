export default {
    useNextVariants: true,
    fontFamily: ["Inter", '"Source Sans Pro"', "Helvetica", "Arial", "sans-serif"].join(","),
    h1: {
        color: "#0D1417",
        fontWeight: "500",
        fontSize: "40px",
        letterSpacing: "-0.24px",
        lineHeight: "48px"
    },
    h2: {
        color: "#0D1417",
        fontWeight: "500",
        fontSize: "32px",
        letterSpacing: "-0.24px",
        lineHeight: "40px"
    },
    h3: {
        color: "#0D1417",
        fontWeight: "500",
        fontSize: "24px",
        letterSpacing: "-0.06px",
        lineHeight: "32px"
    },
    h4: {
        /**This is our H1**/
        color: "#0D1417",
        fontWeight: "700",
        fontSize: "20px",
        letterSpacing: "-0.06px",
        lineHeight: "32px",
        marginBottom: 20
    },
    h5: {
        /**This is our H2 or section header **/
        color: "#4E5C62",
        fontWeight: "600",
        fontSize: "16px",
        letterSpacing: "-0.05px",
        lineHeight: "24px",
        marginBottom: 16
    },
    h6: {
        color: "#4E5C62",
        fontWeight: "bold",
        fontSize: "14px",
        letterSpacing: "-0.05px",
        lineHeight: "24px",
        marginBottom: 14
    },
    subtitle1: {
        color: "#4E5C62",
        fontSize: "14px",
        letterSpacing: "-0.05px",
        lineHeight: "24px"
    },
    subtitle2: {
        color: "#4E5C62",
        fontSize: "14px",
        letterSpacing: 0,
        lineHeight: "24px"
    },
    body1: {
        /**This is our body**/
        color: "#4E5C62",
        fontSize: "14px",
        letterSpacing: "-0.05px",
        lineHeight: "24px"
    },
    body2: {
        color: "#4E5C62",
        fontSize: "12px",
        letterSpacing: "-0.04px",
        lineHeight: "20px"
    },
    button: {
        fontSize: "14px"
    },
    caption: {
        /** This is our subtitles/ helper text etc. **/
        color: "#4E5C62",
        fontSize: "14px",
        letterSpacing: "0.3px",
        lineHeight: "20px"
    }
};
