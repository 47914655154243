import api from "core/api/api";
import _ from "underscore";
import { template, className, childView, childViewContainer, modelEvent } from "core/decorators";
import { LayoutView, CompositeView } from "marionette";
import App from "app";
import SchemaSelectionTemplate from "./templates/schema-selection.template.html";
import "datatables.net-dt";
import stringUtils from "core/utils/string-utils";
import InlineHelpMarionetteContainer from "../inline-help-container";

@className("card schema-selection-view")
@template(SchemaSelectionTemplate)
export default class SchemaSelectionView extends InlineHelpMarionetteContainer {
    initialize() {
        let model = App.reqres.request("get:wizard:model");
        this.model = model;
        //        let pluralize = this.model.get('whatsInside')+"s"
        super.initialize("select-schemas");
    }

    delayedUpdate() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            App.vent.trigger("appwizard:next:disable");
        }, 100);
    }

    onShow() {
        const isFooterAvailable = $("#appwizard-footer");
        if (isFooterAvailable.length) {
            App.vent.trigger("appwizard:next:disable");
        } else {
            this.delayedUpdate();
        }

        this.setNextEnabled();
        let data = [];
        let that = this;
        let schemas = this.model.get("schemas");

        // Convert list of error messages to display in HTML
        const getErrorsList = errors =>
            errors?.length ? `<div>${errors.map((msg, index) => `${index + 1}. ${msg}</br>`).join("")}</div>` : null;

        schemas.each(function(schema) {
            // If disabled schema show warning icon, else show checkbox
            const Icon = schema.get("isDisabled")
                ? `<div style="float:right;"><label><img src="app/images/Block.svg"><span></span></label></div>`
                : `<div><label><input type='checkbox' class='filled-in'><span></span></label></div>`;
            const Errors = getErrorsList(schema.get("errors"));

            data.push({
                icon: Icon,
                error: Errors,
                name: schema.get("schemaName"),
                displayName: stringUtils.encodeHtml(schema.get("schemaName")),
                DT_RowId: schema.get("id")
            });
        });

        var dataTable = this.$el.find("#schema-selection").DataTable({
            data: data,
            defaultContent: "",
            scrollCollapse: true,
            paging: false,
            stripeClasses: [],
            autoWidth: false,
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            info: false,
            columns: [
                {
                    title: `<div><label><input id='select-all-schema' type='checkbox' class='filled-in'><span></span></label></div>`,
                    data: "icon",
                    sortable: false
                },
                { title: "Name", data: "displayName", sortable: false }
            ],
            columnDefs: [
                {
                    className: "select-checkbox",
                    targets: 0,
                    width: 30
                }
            ],
            createdRow: function(row, data, dataIndex) {
                // Adds Error messages in tooltips
                $(row).tooltipster({
                    interactive: true,
                    content: data.error,
                    contentAsHTML: true,
                    animation: "grow",
                    position: "right",
                    delay: 200
                });
            }
        });

        this.$el.find("#schema-selection").on("click", "tr", function() {
            let $schemaNameTd = $(this).find("td:nth-child(2)");
            if ($schemaNameTd.length === 0) {
                return;
            }

            const tableRowData = dataTable.row(this).data();
            if (tableRowData.error) return;
            let schemaName = tableRowData.DT_RowId;

            $(this).toggleClass("selected");
            let selected = false;
            if ($(this).hasClass("selected")) {
                $(this).find("input[type=checkbox]")[0].checked = true;
                selected = true;
            } else {
                $(this).find("input[type=checkbox]")[0].checked = false;
                selected = false;
            }
            that.setEnabledSchemas(schemaName, selected);
            that.setNextEnabled();
            refreshAllCheckbox();
        });

        const getSelectedSchemas = () => {
            let schemas = this.model.get("schemas");
            return schemas.filter(schema => schema.get("isEnabled")).length;
        };

        this.$el.find("#select-all-schema").on("click", function(event) {
            let selected = this.checked;
            let arr = $("#schema-selection tr").filter(idx => idx != 0);
            arr.toArray().forEach(elem => {
                const schemaData = dataTable.row(elem).data();
                if (schemaData.error) return;
                if (!selected) {
                    $(elem).find("input[type=checkbox]")[0].checked = false;
                    $(elem).removeClass("selected");
                } else {
                    $(elem).find("input[type=checkbox]")[0].checked = true;
                    $(elem).addClass("selected");
                }
            });
            const selectedSchemasLength = getSelectedSchemas().length;
            if (selectedSchemasLength < schemas.length) {
                that.$el.find("#select-all-schema")[0].indeterminate = selectedSchemasLength > 0;
            } else {
                that.$el.find("#select-all-schema")[0].indeterminate = arr.length > 0 && arr.length < schemas.length;
            }
            that.setAllSchemasEnabled(selected);
            that.setNextEnabled();
        });

        setTimeout(function() {
            schemas.each(function(schema) {
                if (schema.get("isEnabled")) {
                    that.$el.find("#" + $.escapeSelector(schema.get("id"))).trigger("click");
                }
            });
        }, 100);

        const refreshAllCheckbox = () => {
            const selectedSchemasLength = getSelectedSchemas();
            const selectAllElement = this.$el.find("#select-all-schema")[0];
            selectAllElement.indeterminate = selectedSchemasLength > 0 && selectedSchemasLength < schemas.length;
            if (schemas.some(schema => !schema.get("isEnabled"))) {
                this.$el.find("#select-all-schema")[0].checked = false;
            } else if (schemas.length === selectedSchemasLength) {
                that.$el.find("#select-all-schema")[0].checked = true;
            }
        };

        super.onShow();
    }

    setEnabledSchemas(schemaName, enabled) {
        let schemas = this.model.get("schemas");
        let schemaID = schemaName;
        let schema = schemas.findWhere({ id: schemaID });
        schema.set("isEnabled", enabled);
    }

    setAllSchemasEnabled(enabled) {
        let schemas = this.model.get("schemas");
        schemas.forEach(schema => {
            const elem = this.$el.find("#" + $.escapeSelector(schema.get("id")));
            if (schema.get("isDisabled") || !elem.length) return;
            schema.set("isEnabled", enabled);
        });
    }

    setNextEnabled() {
        let schemas = this.model.get("schemas");
        const selectedSchemas = schemas.filter(schema => schema.get("isEnabled"));
        if (selectedSchemas.length > 0) {
            App.vent.trigger("appwizard:next:enable");
        } else {
            App.vent.trigger("appwizard:next:disable");
        }
    }

    canMoveForward() {
        return new Promise(function(resolve, reject) {
            resolve();
        });
    }

    onBeforeDestroy() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }
}
