import React, { useState } from "react";
import PropTypes from "prop-types";
import { StriimButton, StriimTheme, StriimTypography, StriimList, StriimListItem } from "@striim/striim-ui";
import { KeySelector, DeleteButton, ValueEntry } from "./map-for-adapter-row";
import classes from "./map-for-adapter-properties.styles";
import LocalStore from "./map-for-adapter.store";
import { useObserver } from "mobx-react-lite";
import { v4 } from "uuid";
import { Card, ClickAwayListener } from "@material-ui/core";

import { Grid, ListItemText } from "@mui/material";

export const MapForAdapterContext = React.createContext(null);

const Collection = ({ store }) => {
    let showValues = store.values.filter(element => {
        return !element.isDeleted;
    });

    return (
        <StriimList>
            {showValues.length === 0 && (
                <StriimListItem
                    sx={classes.noItemsMessage}
                    divider
                    data-test-id={"property-list-item-no-values"}
                    text='Please click the "Add Property" button to start adding new Properties'
                />
            )}
            {showValues.map(value => {
                return (
                    <StriimListItem
                        divider
                        data-test-id={"property-list-item-" + value.Property}
                        key={value.uuid}
                        secondaryAction={<DeleteButton sx={classes.righSideButton} store={store} uuid={value.uuid} selectedKey={value.Property} />}
                    >
                        <ListItemText sx={{ marginRight: 5 }}>
                            <Grid container alignItems={"center"}>
                                <Grid item xs={3}>
                                    <StriimTypography variant={"h5"} sx={classes.uncapitalize}>
                                        Property
                                    </StriimTypography>
                                </Grid>
                                <Grid item xs={9} sx={classes.addSmallMargin}>
                                    <KeySelector store={store} selectedKey={value.Property} uuid={value.uuid} />
                                </Grid>
                                <Grid item xs={3} sx={classes.addSmallMargin}>
                                    <StriimTypography variant={"h5"} sx={classes.uncapitalize}>
                                        Value
                                    </StriimTypography>
                                </Grid>
                                <Grid item xs={9} sx={classes.addSmallMargin}>
                                    <ValueEntry
                                        store={store}
                                        selectedKey={value?.Property?.trim()}
                                        selectedValue={value.Value}
                                        uuid={value.uuid}
                                    />
                                </Grid>
                            </Grid>
                        </ListItemText>
                    </StriimListItem>
                );
            })}
        </StriimList>
    );
};

const Table = ({ store }) => {
    // eslint-disable-next-line no-unused-vars
    const [ttl, setTTL] = useState(new Date());

    const shouldDisableAdd = function () {
        let addNew = store.UIConfig.allowCustom;
        if (addNew) return false;

        let currentLength = store.values.filter(entry => {
            return !entry.isDeleted;
        }).length;
        return currentLength === Object.keys(store.UIConfig.options).length;
    };

    const addRow = function () {
        store.values.push({ Property: null, Value: null, uuid: v4(), isDeleted: false });
        setTTL(new Date());
    };

    return useObserver(() => (
        <Card data-test-id="adapter-map-value" data-value={store.getStringifiedValue()}>
            <Collection store={store} dt={store.ttl}></Collection>
            <StriimButton
                variant="primaryText"
                disabled={shouldDisableAdd() || !store.enabled}
                onClick={() => {
                    addRow();
                }}
                data-test-id={"add-custom-property"}
            >
                Add Property
            </StriimButton>
        </Card>
    ));
};

const getStore = function ({ UIConfig, enabled, value, onChange }) {
    const store = new LocalStore();
    store.setUIConfig(UIConfig);
    store.setEnabled(enabled);
    store.setValues(value);
    store.setOnChange(onChange);
    return store;
};

const MapForAdapterProperties = ({ enabled, value, onChange, UIConfig, onBlur }) => {
    const store = getStore({ enabled, value, onChange, UIConfig });
    return (
        <Table store={store} />
    );
};
MapForAdapterProperties.propTypes = {
    enabled: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    UIConfig: PropTypes.object.isRequired,
    onBlur: PropTypes.func
};

export const MapForAdapterPropertiesForStorybook = ({ enabled, value, onChange, UIConfig }) => {
    const store = getStore({ enabled, value, onChange, UIConfig });
    return <Table store={store} />;
};

export default MapForAdapterProperties;
