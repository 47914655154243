import { template, className } from "core/decorators";
import { LayoutView } from "marionette";
import iconTemplate from "./icon.template.html";
import getSvgIcon from "src/generic/icon/flow-designer-icon-legacy";

@template(iconTemplate)
class BaseIcon extends LayoutView {
    getIcon(iconName, isMirror) {
        if (isMirror) iconName = iconName.replace(/-mirror/i, "");
        return getSvgIcon(iconName);
    }
    serializeData() {
        const allowedTypes = [
            "cache",
            "cq",
            "openprocessor",
            "router",
            "source",
            "target",
            "wactionstore",
            "window",
            "stream"
        ];
        let type = this.options.type.toLowerCase();

        if (type === "externalcache") {
            type = "cache";
        }

        if (type === "externalsource") {
            type = "source";
        }

        if (type === "sentinel") {
            type = "cq";
        }
        let data = {};
        data.isMirror = this.options.icon.endsWith("-mirror");
        data.icon = this.getIcon(this.options.icon, data.isMirror);
        data.type = type;
        data.withBorder = allowedTypes.includes(type);
        data.withBorderClass = allowedTypes.includes(type) ? "with-border" : "";
        data.segmentationIndicator = this.options.segmentationIndicator;
        return data;
    }
}

@className("icon-maker component-icon")
class ComponentIcon extends BaseIcon {}

@className("icon-maker graphnode-icon")
class GraphNodeIcon extends BaseIcon {}

export let iconMaker = {
    createComponentIcon(type, icon, segmentationIndicator) {
        return new ComponentIcon({ type: type, icon: icon, segmentationIndicator: segmentationIndicator });
    },
    createGraphNodeIcon(type, icon) {
        return new GraphNodeIcon({ type: type, icon: icon });
    }
};
