import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./checkbox.html";

var Model = BaseControl.Model.extend({
    defaults: {
        value: Boolean,
    },
});

var View = BaseControl.View.extend({
    template: _.template(template),

    initialize: function () {
        this.listenTo(this, "value:set", this._setViewValue);
    },

    getViewValue: function () {
        return this.$("input").is(":checked");
    },

    setViewValue: function () {
        this.$("input").prop("checked", this.getValue());
    },
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
