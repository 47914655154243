// Base class for all other editors.
import App from "app";
import "core/services/metadataService/dataVisualizations";

let _currentEditorId = 1;

App.module("Dashboard.VisEditor", function (VisEditor, App, Backbone, Marionette, $, _) {
    var Mixin = {
        initialize: function () {
            this._editorID = "visualization-editor-icon-" + _currentEditorId++;

            _(this).bindAll("get_value");

            // Implementations of this view can provide their template HTML in this.templateHTML, and this base view
            // will take care of wrapping that template HTML with HTML that surfaces the label and renders this
            // editor in a standardized block field row format.
            this.templateHTML || (this.templateHTML = "");

            if (this.model.editor.options.label) {
                this.template = _.template(
                    '<div class="field-row custom-layout"><div class="head"><label>{{editor.options.label}}<span id="' +
                        this._editorID +
                        '" style="display:none;" class="icon icon-tooltip"></label></div><div class="body">' +
                        this.templateHTML +
                        "</div></div>"
                );
                if (this.getChildView)
                    // If this is a Composite/Collection View...
                    this.childViewContainer = ".field-row .body";
            } else this.template = _.template(this.templateHTML);

            this.addDisplayClasses();
        },
        addDisplayClasses: function () {
            if ((this.model.editor.options.flow || "").toUpperCase() === "INLINE") {
                this.$el.addClass("flow-inline");
            }
            if ((this.model.editor.options.label_style || "").toUpperCase() === "HEADING") {
                this.$el.addClass("label-heading");
            }
        },
        on_change: function (val) {
            var key = this.model.editor.attribute;

            var this_model = this.model;
            var top_model = this.model;
            while (top_model._parent) {
                top_model = top_model._parent;
            }

            // Default on_change implementation is just to update the attribute in the immediate model
            // An editor def can override on_change to modify other values in the entire DV
            var set_value =
                this.model.editor.set_value ||
                function (val, dvmodel, model) {
                    model.config[key] = val;
                };

            set_value.apply(top_model, [val, top_model, this_model]);

            if (this.model.editor.on_change) this.model.editor.on_change.apply(top_model, [val, top_model, this_model]);
        },
        // TODO: add support for custom get_value, too.
        get_value: function () {
            var key = this.model.editor.attribute;
            return this.model.config[key];
        },
        onShow: function () {
            if (this.model.editor.options.description) {
                let $el = this.$("#" + this._editorID);
                $el.css("display", "");
                $el.tooltipster({
                    interactive: true,
                    content: this.model.editor.options.description,
                    maxWidth: 408,
                    offsetY: -10,
                    speed: 0,
                    delay: 200,
                    contentAsHTML: true,
                });
            }
        },
    };

    // The implementation of this base editor differs based on the type of Marionette view used. Both are provided.
    VisEditor.Editor = {
        LayoutView: Marionette.LayoutView.extend(Mixin),
        CompositeView: Marionette.CompositeView.extend(Mixin),
    };
});
