import $ from "jquery";
import _ from "underscore";
import App from "app";
import api from "core/api/api";
import growl from "app/components/common/growl";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import BaseControl from "./../base-control";
import Select from "./../select/select";
import FieldsList from "./../fields-list/fields-list";
import MetaObjectDataSource from "app/components/common/editor/control/select/metaobject-datasource";
import MetaObjects from "core/services/metaStoreService/meta-objects";
import template from "./type.html";

const rootClassesWhenChildrenExists = {
    implicit: "with-info-implicit",
    global: "with-info-global",
    saveActions: "with-save-actions"
}

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "typeEditor",
    events: {
        "click @ui.toggleDetails": "toggleDetails",
        "click @ui.save": "onSave",
        "click @ui.cancel": "onCancel"
    },
    modelEvents: {
        "change:value": "_setViewValue",
        "change:enabled": "setViewEnabled",
        "change:isDirty": "onDirtyChange"
    },
    regions: {
        fieldsListRegion: ".fields-list-container",
        typeSelectorRegion: ".type-name"
    },
    ui: {
        typeName: ".type-name",
        fieldsList: ".field-detail-container",
        toggleDetails: ".toggleDetails",
        typePreview: ".type-preview",
        saveActions: ".save-actions",
        cancel: ".cancel",
        save: ".save",
        global: ".global",
        implicit: ".implicit"
    },

    onDirtyChange: function() {
        if (this.model.isDirty) {
            this.ui.cancel.addClass("active");
            this.ui.save.addClass("active");
        } else {
            this.ui.cancel.removeClass("active");
            this.ui.save.removeClass("active");
        }

        this.trigger("modified");
    },

    onSave: function(event, hideMessage) {
        var _this = this;
        var def = $.Deferred();

        if (!this.model.isDirty || this.readonly) {
            return;
        }

        if (_this.fieldsListControl.hasErrors()) {
            growl.warning("Possible duplicates. Please check the fields list.", "The type was not saved.");
            return;
        }

        if (this.keyRequired && !this.fieldsListControl.hasKey()) {
            growl.warning("Please add a primary key.");
            return;
        }

        metaStoreService.findById(this.model.value).then(function(type) {
            if (!type) {
                var name = MetaObjectConverter.getName(_this.model.value);
                var namespace = MetaObjectConverter.getNamespace(_this.model.value);
                type = new MetaObjects[metaStoreService.entities.TYPE].Model();
                type.set("nsName", namespace);
                type.set("name", name);
            }

            type.set("fields", _this.fieldsListControl.getValue());
            var appId = api.getCurrentAppId();
            type.save({
                flow: appId
            })
                .then(function() {
                    var types = new MetaObjectDataSource([metaStoreService.entities.TYPE]);
                    _this.typeSelector.setDataSource(types).then(function() {
                        if (!hideMessage) {
                            growl.success("", "The type was saved successfully.");
                        }

                        _this.model.isDirty = false;
                        _this.model.originalFieldsList = _this.fieldsListControl.getValue();
                        _this.model.trigger("change:value", _this.model, _this.model.value);
                        App.vent.trigger("compilation-needed");
                        _this.trigger("type-saved");
                    });
                })
                .fail(function(error) {
                    growl.error(error ? error.message : "", "Failed to save type.");
                })
                .always(function() {
                    def.resolve(true);
                    App.vent.trigger("forms:clear-dirty", _this.model);
                });
        });

        return def.promise();
    },

    deferredSave: function() {
        if (!this.readonly && this.model.isDirty && this.model.valueNotGlobal) {
            return this.onSave(undefined, true);
        } else {
            return true;
        }
    },

    onCancel: function() {
        if (!this.model.isDirty) {
            return;
        }

        this.fieldsListControl.setFields(this.model.originalFieldsList);
        this.model.isDirty = false;

        App.vent.trigger("forms:clear-dirty", this.model);
    },

    initialize: function() {
        this.bindUIElements();

        this.fieldsListControl = FieldsList.create();
        var types = new MetaObjectDataSource([metaStoreService.entities.TYPE]);

        this.typeSelector = Select(types, {
            addNew: true,
            type: metaStoreService.entities.TYPE,
            customValidateFn: function(value) {
                const nonAlphanumericOrUnderscore = /\W/g;
                if (value.indexOf(" ") !== -1) {
                    return "Please enter without spaces.";
                } else if (nonAlphanumericOrUnderscore.test(value)) {
                    return "Please enter only alphanumeric or underscore characters.";
                } else if (!isNaN(parseInt(value))) {
                    return "Value cannot start with a number";
                } else {
                    return "";
                }
            }
        }).create();
    },

    toggleDetails: function() {
        this._toggleDetails();
    },

    _toggleDetails: function(forceVisible) {
        if (!forceVisible && !this.model.value) {
            return;
        }

        var target = this.ui.toggleDetails;
        if (forceVisible || target.text().toLowerCase() === "view") {
            target.text("Hide");
            this.collapsed = false;
            this.ui.fieldsList.slideDown("fast");
        } else {
            target.text("View");
            this.collapsed = true;
            this.ui.fieldsList.slideUp("fast");
        }

        if (this.fieldsListVisible() && this.model.valueNotGlobal && this.model.enabled) {
            this.ui.saveActions.show();
            this.ui.fieldsList.addClass(rootClassesWhenChildrenExists.saveActions);
        } else {
            this.ui.saveActions.hide();
            this.ui.fieldsList.removeClass(rootClassesWhenChildrenExists.saveActions);
        }
        this.setViewEnabled();
    },

    getViewValue: function() {
        return this.typeSelector.getValue();
    },

    getType: function() {
        return this.typeSelector.model.selectedModel;
    },

    setViewValue: function() {
        return this.typeSelector.setValue(this.getValue());
    },

    setViewEnabled: function() {
        if (!this.model.value) {
            return;
        }

        var isEnabled = this.getEnabled() && this.model.valueNotGlobal && !this.readonly;
        this.fieldsListControl.setEnabled(isEnabled);
        if (isEnabled && this.fieldsListVisible()) {
            this.ui.saveActions.show();
        } else {
            this.ui.saveActions.hide();
        }

        var shouldPossiblyShowInfoAboutReadonlyType = !this.collapsed && this.getEnabled();
        var showGlobalMessage = !this.model.valueNotGlobal && shouldPossiblyShowInfoAboutReadonlyType;
        if (showGlobalMessage) {
            this.ui.global.show();
            this.ui.fieldsList.addClass(rootClassesWhenChildrenExists.global);
        } else {
            this.ui.global.hide();
            this.ui.fieldsList.removeClass(rootClassesWhenChildrenExists.global);
        }
        this.$el.toggleClass("global-message", showGlobalMessage);

        var showImplicitMessage = this.readonly && shouldPossiblyShowInfoAboutReadonlyType;
        if (showImplicitMessage) {
            this.ui.implicit.show();
            this.ui.fieldsList.addClass(rootClassesWhenChildrenExists.implicit);
        } else {
            this.ui.implicit.hide();
            this.ui.fieldsList.removeClass(rootClassesWhenChildrenExists.implicit);
        }
        this.$el.toggleClass("implicit-message", showImplicitMessage);

        var isTypeChangeable = this.getEnabled() && !this.readonly;
        if (this.typeSelector) {
            this.typeSelector.setEnabled(isTypeChangeable);
        }
    },

    fieldsListVisible: function() {
        return this.ui.fieldsList.css("display") === "block";
    },

    setValue: function(value) {
        this.model.value = value;

        var deferred = $.Deferred();
        if (!this.ui.toggleDetails.prop) {
            return deferred.resolve().promise();
        }

        this.model.isDirty = false;

        if (value) {
            metaStoreService.findById(value).then(
                function(type) {
                    if (this.isDestroyed) {
                        deferred.resolve();
                        return;
                    }

                    var originalFieldsList = type ? type.fields : [];
                    this.model.originalFieldsList = originalFieldsList;
                    this.fieldsListControl.setValue(originalFieldsList);
                    this.ui.toggleDetails.show();
                    this.typeSelector.setValue(value);

                    if (this.fieldsListVisible() || !this.collapsed) {
                        this.ui.toggleDetails.text("Hide");
                        this.ui.fieldsList.show();
                        if (this.model.valueNotGlobal) {
                            this.ui.saveActions.show();
                        }
                    }
                    this.ui.toggleDetails.show();
                    this.setViewEnabled();

                    deferred.resolve();
                }.bind(this)
            );
        } else {
            this.fieldsListControl.setValue([]);
            this.ui.fieldsList.hide();
            this.ui.toggleDetails.hide();
            this.ui.saveActions.hide();
            this.ui.global.hide();
            this.ui.implicit.hide();
            deferred.resolve();
        }

        return deferred.promise();
    },

    onRender: function() {
        var _this = this;
        this.typeSelectorRegion.show(this.typeSelector);
        this.fieldsListRegion.show(this.fieldsListControl);

        this.listenTo(this.typeSelector.model, "change:value", function(viewModel) {
            if (viewModel.isNewElement) {
                _this._toggleDetails(true);
            }

            if (_this.model.enabled) {
                _this.setValue(viewModel.value);
                this.trigger("value:set", this.getValue());
                _this.hideError();
            }
        });

        this.listenTo(this.fieldsListControl, "value:set", function() {
            _this.model.isDirty = true;
            _this.model.trigger("change:value", _this.model, _this.model.value);
        });

        BaseControl.View.prototype.onRender.call(this);
    }
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: String,
        isDirty: Boolean,
        originalFieldsList: Array
    },
    properties: {
        valueNotGlobal: function() {
            return this.value ? this.value.toLowerCase().indexOf("global.") === -1 : true;
        }
    }
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View
});
