export const styles = {
    tableData: {
        fontSize: "14px",
        color: "#161616",
        fontWeight: 400,
        display: "inline-flex",
        alignItems: "center"
    },
    tableTitle: {
        fontSize: "14px",
        color: "#161616",
        fontWeight: 500
    },
    lightText: {
        color: "rgba(32, 32, 32, 0.50)"
    }
};
