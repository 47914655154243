import api from "core/api/api";
import Backbone from "backbone";
import _ from "underscore";
import FormBuilder from "app/components/common/editor/form-builder";
import UIControl from "app/components/common/editor/control/ui-control";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import metaObjectHelper from "./metaObjectHelper";
import NamespaceDataSource from "app/components/common/editor/control/select/namespace-datasource";
import dateLib from "core/utils/date-utils";
import { isUserAdmin } from "core/user-management/update.control";
import securityService from "core/services/securityService/securityService";
import AlertResources from "app/components/alertmanager/alert-resources";
import App from "app";

/**
 *
 * @param metadata_object
 * @param options
 * @constructor
 */
var BaseFormGenerator = function(metadata_object, options) {
    _.extend(this, Backbone.Events);
    this.MetaObjectModel = metadata_object;
    this.options = options || {};
    this.options.submitText = this.options.submitText || "Save";
    this.options.cancelText = this.options.cancelText || "Cancel";
    this.options.readOnly = this.options.readOnly || false;
    this.options.labels = this.options.labels || {};
    this.options.hideTitle = this.options.hideTitle || false;
    this.licenseCheck = {};

    var containerClass =
        "component-" +
        metadata_object.type
            .replace(" ", "_")
            .replace(":", "_")
            .toLowerCase();

    this.Form = new FormBuilder({
        model: metadata_object,
        submitText: this.options.submitText,
        cancelText: this.options.cancelText,
        readOnly: this.options.readOnly,
        autoSave: this.options.autoSave,
        labels: this.options.labels,
        viewOptions: this.options.viewOptions,
        containerClass: containerClass
    });
    this.fieldMapper = {};
};

var requiredFields = ["name", "mode", "stream", "size", "input", "inputStream", "inStream", "from", "output"];

BaseFormGenerator.prototype = {
    create: function() {
        var _this = this;
        this.addBaseFields();
        if (typeof this.addPreTitleFields === "function") {
            this.addPreTitleFields();
        }
        if (typeof this.addCustomFields === "function") {
            this.addCustomFields();
        }
        var display_fields = this.getDisplayFields();
        this.createFormForDisplayFields(display_fields);
        var view = this.Form.create();

        this.Form.on("form:rendered", function() {
            if (typeof _this.postRender === "function") {
                _this.postRender();
            }
        });

        this.Form.on("form-builder:save", function(changedControls) {
            view.trigger("save", changedControls);
        });
        this.Form.on("form-builder:cancel", function() {
            view.trigger("cancel");
        });
        //hide the validation box on form submission
        this.Form.on("form-builder:save-click", () => {
            _this?.validationContainer.setHide(true);
            App.vent.trigger("component:form-save", {
                modelId: this.MetaObjectModel.id
            });
        });
        this.Form.on("form-builder:validation-errors", dirtyFields => {
            view.trigger("validation-errors");
            if (Array.isArray(dirtyFields) && dirtyFields.length) {
                this.validationContainer.setMessage(
                    `${dirtyFields.length} error${dirtyFields.length > 1 ? "s" : ""} found in the configuration`
                );
                this.validationContainer.setHide(false);
                App.vent.trigger("component:form-validation", {
                    modelId: this.MetaObjectModel.id,
                    errorsCount: dirtyFields.length
                });
            } else {
                this.validationContainer.setHide(true);
            }
        });

        return view;
    },

    createFormForDisplayFields: function(display_fields) {
        var that = this;
        _.each(display_fields, function(display_field) {
            var control = that.fieldMapper[display_field];
            if (typeof control === "undefined" && that.options.StaticControls) {
                control = that.options.StaticControls[display_field];
                requiredFields.includes(display_field)
                    ? that.Form.addRequiredStaticControl(control, display_field)
                    : that.Form.addStaticControl(control, display_field);
            } else {
                requiredFields.includes(display_field)
                    ? that.Form.addRequiredControl(control, display_field, undefined, that.licenseCheck[display_field])
                    : that.Form.addControl(control, display_field, undefined, that.licenseCheck[display_field]);
            }
        });
    },

    getDisplayFields: function() {
        var all_fields = [];
        if (this.options.ReadOnly) {
            var defaults = this.MetaObjectModel.defaults();
            all_fields = _.keys(defaults);
            all_fields = _.without(all_fields, "_actions");
        } else {
            var actions = propertyTemplateService.getActions(this.MetaObjectModel.get("type"));
            all_fields = _.keys(actions);
        }
        if (this.options.StaticControls) {
            _.each(this.options.StaticControls, function(control, key) {
                all_fields.push(key);
            });
        }
        if (this.options.displayFields) {
            all_fields = _.intersection(this.options.displayFields, all_fields);
        }
        this._configureNewMetaObjectFields(all_fields);

        return _.uniq(all_fields);
    },

    isNewMetaObject: function() {
        return !this.MetaObjectModel.id;
    },

    _configureNewMetaObjectFields: function(all_fields) {
        var title;
        if (!this.options.hideTitle) {
            const iconName = metaObjectHelper.getIconClassByMetaObject(this.MetaObjectModel);

            if (this.isNewMetaObject()) {
                title = "New " + this.MetaObjectModel.header;
                if (this.options.title) {
                    title = this.options.title;
                }
                this.options.StaticControls.title = UIControl.HeaderWithIcon.extend({
                    model: new UIControl.HeaderWithIcon.Model({
                        tag: "h2",
                        text: title,
                        iconName: iconName,
                        iconType: this.MetaObjectModel.type,
                        title: title,
                        showTooltip: this.options.showTooltip
                    })
                }).create();
                if (!_.contains(all_fields, "name")) {
                    all_fields.unshift("name");
                }
            } else {
                title = this.options.title || this.MetaObjectModel.name;
                var titleAttr =
                    `<h4>${title}</h4><p>This ${this.MetaObjectModel.header} was created on ` +
                    `${dateLib(this.MetaObjectModel.ctime).format("MM/DD/YYYY")}</p>`;

                this.options.StaticControls.title = UIControl.HeaderWithIcon.extend({
                    model: new UIControl.HeaderWithIcon.Model({
                        tag: "h2",
                        text: title,
                        iconName: iconName,
                        iconType: this.MetaObjectModel.type,
                        title: titleAttr,
                        showTooltip: this.options.showTooltip
                    })
                }).create();
            }
            //validation error message container
            if (!this.validationContainer) {
                const validationContainer = UIControl.ErrorBox().create();
                this.validationContainer = validationContainer;
                this.options.StaticControls.validationContainer = validationContainer;
            }

            this.validationContainer.setHide(true);
            all_fields.unshift("validationContainer");
            // Will be shown only when the component is already created and the alertresources contains the componentType
            //id will be generated from the backend after successful creation of the component
            let location = window.location.href;
            const isAdmin = isUserAdmin();
            if (
                isAdmin &&
                !securityService.isDeveloperLicense() &&
                AlertResources.hasOwnProperty(this.MetaObjectModel.type) &&
                location.includes("#/flow")
            ) {
                if (this.MetaObjectModel.id) {
                    this.options.StaticControls.configureAlerts = UIControl.AlertEnabledInfoBox({
                        hide: false,
                        componentType: this.MetaObjectModel.type,
                        componentTitle:
                            this.MetaObjectModel.name || this.MetaObjectModel.header || this.options.title || "title",
                        componentId: this.MetaObjectModel.id
                    }).create();

                    all_fields.unshift("configureAlerts");
                }
            }
            all_fields.unshift("title");
        }
    },

    addBaseFields: async function() {
        this.options.StaticControls = this.options.StaticControls || {};
        this.fieldMapper.id = UIControl.TextField.create();
        this.fieldMapper.type = UIControl.TextField.create();
        this.fieldMapper.name = UIControl.MetaObjectTextField(
            this.MetaObjectModel.nsName || api.getCurrentNamespace(),
            this.MetaObjectModel.type
        ).create();
        this.fieldMapper.nsName = UIControl.TextField.create();

        if (
            this.MetaObjectModel.type === metaStoreService.entities.APPLICATION ||
            this.MetaObjectModel.type === metaStoreService.entities.TYPE
        ) {
            var dataSource = await new NamespaceDataSource();
            this.fieldMapper.nsName = UIControl.Select(dataSource, {
                addNew: true,
                addNewText: "New Namespace: ",
                nameOnly: true,
                type: metaStoreService.entities.NAMESPACE,
                multiple: false,
                customCssClass: this.options.theme
            }).create();
        }

        this.fieldMapper.namespaceId = UIControl.TextField.create();
        this.fieldMapper.metaObjectClass = UIControl.TextField.create();
        this.fieldMapper.description = UIControl.TextField.create();
        this.fieldMapper.uri = UIControl.TextField.create();
        this.fieldMapper.uuid = UIControl.TextField.create();
        this.fieldMapper.version = UIControl.TextField.create();
        this.fieldMapper.metaInfoStatus = UIControl.MetaInfoStatus.create();
    }
};

export default BaseFormGenerator;
