import CosmosDBCassandraApi, { cassandraValidationSchema } from "../cosmosdb-cassandra-api/cosmosdb-cassandra-api";
import CosmosDBMongoApi, { mongoValidationSchema } from "../cosmosdb-mongo-api/cosmosdb-mongo-api";
import CosmosDBSqlApi, { cosmosDBValidationSchema } from "../cosmosdb-sql-api/cosmosdb-sql-api";
import HelpResources from "app/components/common/helpable/online-help-dictionary";
export const COSMOSDB_CONFIG = {
    cosmos: {
        id: "cosmos",
        title: "Cosmos DB SQL API",
        targetName: "Cosmos DB SQL API",
        icon: "cosmosdb",
        defaultValues: {
            targetName: "",
            serviceEndpoint: "",
            port: "",
            accessKey: ""
        },
        targetComponent: CosmosDBSqlApi,
        validationSchema: cosmosDBValidationSchema,
        docsLink: HelpResources.get().PROPERTYTEMPLATE_CosmosDBWriter.href,
        predefinedComponent: "CosmosDBWriter"
    },
    cassandra: {
        id: "cassandra",
        title: "Cosmos DB Cassandra API",
        targetName: "Cassandra API",
        icon: "cassandra",
        defaultValues: {
            targetName: "",
            accountEndpoint: "",
            port: "",
            accountKey: "",
            keySpace: ""
        },
        targetComponent: CosmosDBCassandraApi,
        validationSchema: cassandraValidationSchema,
        docsLink: HelpResources.get().PROPERTYTEMPLATE_CassandraCosmosDBWriter.href,
        predefinedComponent: "CassandraCosmosDBWriter"
    },
    mongo: {
        id: "mongo",
        title: "Cosmos DB MongoDB API",
        targetName: "MongoDB API",
        icon: "mongodb",
        defaultValues: {
            targetName: "",
            host: "",
            port: "",
            username: "",
            password: ""
        },
        targetComponent: CosmosDBMongoApi,
        validationSchema: mongoValidationSchema,
        docsLink: HelpResources.get().PROPERTYTEMPLATE_MongoCosmosDBWriter.href,
        predefinedComponent: "MongoCosmosDBWriter"
    }
};
