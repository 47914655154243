import App from "app";
import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import browser_template from "./filebrowser.html";
import leafTemplate from "./leaf_template.html";
import fileform from "./file_form.html";
import file_input_template from "./file_input.html";
import dialog from "app/components/common/dialogs/dialogWindow";
import "app/components/common/_deprecated/form-mixin-dialog/dialog";
import "core/services/dataService/filebrowserservice";

var fileBrowser = {};

fileBrowser.localFileBrowserView = Backbone.Marionette.ItemView.extend({
    id: "file-browser",
    template: _.template(fileform),
    events: {
        "click #submit": "formSubmit",
    },
    /* Handles file upload */
    formSubmit: function (e) {
        //TODO add indicator for user to know that file is being uploaded to server
        e.preventDefault();
        var data = new FormData();
        var file = $("#input")[0].files[0];
        if (!file) {
            dialog.alert("Please upload a local file");
            return;
        }
        var fileName = file.name;

        data.append("file", file);

        var loc = window.location.origin + "/upload";

        $.ajax({
            url: loc,
            data: data,
            processData: false,
            type: "POST",
            contentType: false,
            headers: {
                "X-fileName": fileName,
            },
            success: function (data) {
                App.vent.trigger("uploadComplete", data);
                App.vent.trigger("filePathChange", data, true);
                dialog.alert("File successfully uploaded to Striim Server, click 'OK' to resume");
            },
            error: function () {
                dialog.alert("Please upload a local file");
            },
        });
    },
});

fileBrowser.inputView = Backbone.Marionette.ItemView.extend({
    id: "file-input",
    template: _.template(file_input_template),
    modelEvents: {
        change: "updateExisting",
    },
    initialize: function (options) {
        this.model = options.model;
    },
    updateExisting: function () {
        if (this.$("#inputFile")) {
            this.render();
        }
    },
    onRender: function () {
        //catch submit event on the form not the input
        this.$("#fileInputForm").submit(function (event) {
            console.log("FORM SUBMIT");
            event.preventDefault();
            var newFile = this.fileName.value;
            App.vent.trigger("filePathChange", newFile);
            return false;
        });

        this.$("#fileSubmitButton").click(function (event) {
            console.log("button input submit");
            event.preventDefault();
            var thing = document.getElementById("fileInputForm");
            var val = thing.fileName.value;
            App.vent.trigger("filePathChange", val);
            return false;
        });
    },
});

fileBrowser.FileView = Backbone.Marionette.CompositeView.extend({
    template: _.template(leafTemplate),
    tagName: "li",
    itemViewOptions: function () {
        return this.options;
    },
    childViewContainer: "ul",
    events: {
        "click li": "openFile",
    },
    initialize: function (options) {
        this.model = options.model;
        this.collection = options.model.get("children");
        this.parentView = options.parentView;
    },
    openFile: function (msg) {
        if (!this.parentView) {
            return;
        }
        var self = this;
        msg.preventDefault();
        if (msg.target === msg.currentTarget) {
            var fullPath = $(msg.currentTarget).attr("full-path");
            var isDir = $(msg.currentTarget).attr("isdir");
            if (isDir === "true") {
                self.parentView.trigger("browseDirByIdClick", this.collection, fullPath);
            } else {
                $(".chosen").removeClass("chosen");
                $(msg.currentTarget).addClass("chosen");
                self.parentView.trigger("previewByIdClick", fullPath);
            }
        }
    },
});
fileBrowser.TreeRoot = Backbone.Marionette.CollectionView.extend({});
fileBrowser.View = App.FormMixinDialog.View.extend({
    regions: {
        inputRegion: "#input-bar",
        localRegion: "#local-files",
        browserRegion: "#file-tree",
    },
    ui: {
        previewButton: "#preview-button",
    },
    template: _.template(browser_template),
    initialize: function (options) {
        var _this = this;

        options.classes = "large file-browser";
        this.model = options.model;
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
        // Define what to do once the dialog form has successfully been saved.
        // In this case, update the vismodel, and close the dialog
        this.listenTo(this, "dialog-save", function (context) {
            this.options.vismodel.set(context);
            this.destroy();
        });

        // Hook up the submit button
        this.triggers["click .submit"] = "submit";
        this.delegateEvents();
        this.listenTo(this, "submit", this.submit);

        var togglePreviewBtnState = function (path) {
            if (path) {
                _this.ui.previewButton.removeClass("preview-disabled-button");
            } else {
                _this.ui.previewButton.addClass("preview-disabled-button");
            }
        };

        this.listenTo(this, "previewByIdClick", function (path) {
            togglePreviewBtnState(path);
        });

        this.listenTo(App.vent, "filePathChange", togglePreviewBtnState, this);
    },
    onRender: function () {
        var localFileBrowser = new fileBrowser.localFileBrowserView();
        localFileBrowser.render();
        this.localRegion.show(localFileBrowser);
    },

    /* Render file browser for webaction server */
    renderTree: function (model, collection, filePath) {
        var self = this;
        this.options.fileCollection = collection;

        var options = {
            model: model,
            parentView: self,
        };
        var fileView = fileBrowser.FileView;
        this.treeView = new fileBrowser.TreeRoot({
            collection: collection,
            tagName: "ul",
            childView: fileView,
            childViewOptions: options,
        });

        this.treeView.render();
        this.browserRegion.show(this.treeView);

        if (filePath) {
            var lis = this.$el.find(".file-li");
            _.each(lis, function (li) {
                if ($(li).attr("full-path") === filePath) {
                    $(li).addClass("chosen");
                }
            });
        }
    },
    destroyTree: function () {
        this.treeView.destroy();
    },
    renderInput: function (model) {
        var input = new fileBrowser.inputView({
            model: model,
        });
        input.render();
        this.inputRegion.show(input);
    },
    updateTree: function () {
        console.log("updating info for preview", this.model);
        this.treeView.render();
        this.browserRegion.show(this.treeView);
    },
    validate: function () {
        var errors = [];
        if (this.ui.previewButton.hasClass("preview-disabled-button")) {
            return ["please select a file"]; //the error is not displayed, used just to to stop the dialog
        }
        return errors;
    },
});
fileBrowser.Controller = App.FormMixinDialog.Controller.extend({
    initialize: function (options) {
        var self = this;
        this.view = options.view;
        this.fileModel = options.view.model;
        App.FormMixinDialog.Controller.prototype.initialize.apply(this, arguments);
        var filePromise = App.request("browse:initial");

        /* Inital file browsing gets a model and collection from the file browser
          service. The model represents the top level directory/file rendered in the tree.
          The collection represents the entire directory rendered in UI.
          All subsequent API calls will use fileModel and fileCollection
         */
        filePromise.then(function(data) {
            self.fileModel = data;
            self.fileCollection = data.collection;
            self.view.renderTree(self.fileModel, self.fileCollection);
            self.view.renderInput(self.fileModel);
        });
        self.listenTo(this.view, "browseDirByIdClick", self.browse);
        self.listenTo(this.view, "previewByIdClick", self.previewFile);
        self.listenTo(App.vent, "filePathChange", self.browseByTextInput, this);
        self.listenTo(App.vent, "uploadComplete", self.setUploadPath, this);

        //listen to the cancel event and destroy the controller
        self.listenTo(this.view, "destroy", function () {
            self.destroy();
        });
    },
    /*
        This browse should only be called for files currently rendered in the file browser.
        Browse dir by ID will recursively iterate through the existing collection. All files/dirs are rendered using
        models in the existing file collection, so the recrusive 'browseDirById' is guarenteed to terminate because the model exists.
     */
    browse: function (collection, fullPath) {
        console.log("Browsing directory", fullPath);
        var self = this;
        this.view.model.set("currentPath", fullPath);
        this.fileModel.set("currentPath", fullPath);
        var filePromise = App.request("browseDirById", collection, fullPath);
        filePromise.then(function() {
            self.view.updateTree();
        });
    },

    /*
        This method will completely refresh the existing collection of files and render a 1 level tree of files using the text input file's
        parent as the root
     */

    browseByTextInput: function (fullPath, isFileUploaded) {
        var self = this;
        var filePath;

        if (isFileUploaded) {
            filePath = fullPath.substring(0, fullPath.lastIndexOf("/") + 1);
        } else {
            filePath = fullPath;
        }
        this.view.model.set("previewPath", fullPath);
        this.view.model.set("fileType", "FileReader");
        this.fileModel.set("currentPath", fullPath);
        var filePromise = App.request("browse:dir", filePath);
        filePromise.then(function(data) {
            self.view.destroyTree();
            self.view.renderTree(data, data.collection, fullPath);
        });
    },
    /* When file is uploaded, server returns path to file on server. The returned value is set to
       the preview path
     */
    setUploadPath: function (path) {
        this.fileModel.set("currentPath", path);
        this.view.model.set("previewPath", path);
        this.view.model.set("fileType", "FileReader");
    },
    previewFile: function (id) {
        console.log("Previewing File at this path ", id);
        var previewModel = this.fileModel;
        this.fileModel.set("currentPath", id);
        this.view.model.set("directory", previewModel.get("currentPath"));
        this.view.model.set("fileName", previewModel.get("name"));
        this.view.model.set("previewPath", id);
        this.view.model.set("fileType", "FileReader");
    },
});

export default fileBrowser;
