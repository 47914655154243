import App from "app";
import WAHighChartsMixin from "app/components/dashboard/visualization/charts/bubble/mixins/bubble-highcharts";
import "app/components/dashboard/visualization/charts/chart";

App.module("Chart.Bubble", function (Bubble, App, Backbone, Marionette, $, _) {
    Bubble.View = App.Chart.Default.ChartView.extend({
        initialize: function () {
            _(this).extend(WAHighChartsMixin);
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
        },
    });
});
