import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router";

import ManageStriimPageWrapper from "../../manage-striim-wrapper";
import { ConnectionProfileFooter, ConnectionProfileItemHeader, ConnectionProfileFields } from "..";
import connectionProfileService from "../connection-profile-service";
import growl from "../../../../../../../app/components/common/growl";
import MANAGE_STRIIM_ROUTES from "../../routes.json";
import { getCPValues, getEndpointNameWithIcon } from "../utils";
import InlineDocDictionary from "src/generic/inline-doc/inline-doc-dictionary";

interface CreateEditConnectionProfileProps {
    isEdit?: boolean;
}

export interface Endpoint {
    label: string;
    value: string;
    icon: string;
}
export const CPInlineDocContext = React.createContext({
    setEndPoint: (endPoint: string) => {}
});

const CreateEditConnectionProfile = ({ isEdit = false }: CreateEditConnectionProfileProps) => {
    const navigate = useNavigate();
    const [isValid, setIsValid] = useState(false);
    const [endPoints, setEndPoints] = useState<Endpoint[]>([]);
    const [connectionProfile, setConnectionProfile] = useState<ConnectionProfile>();
    const [loading, setLoading] = useState<boolean>(true);
    const [disableActionButtons, setDisableActionButton] = useState<boolean>(false);
    const [dataForEdit, setDataForEdit] = useState(null);
    const [endpoint, setEndPoint] = useState<string>(null);

    const formRef = useRef({
        connectionRef: useRef(),
        endPointRef: useRef()
    });
    const oauthRef = useRef();
    let { name: cpName } = useParams();

    //If there is an endpoint then its FD/EDIT CP page else CREATE CP PAGE
    let fdEndpoint = null;

    useEffect(() => {
        const getEndPoints = async () => {
            try {
                setLoading(true);
                const result = await connectionProfileService.getConnectionProfileEndpoints();
                const formattedEndpointsList = result?.sort()?.map(getEndpointNameWithIcon);
                setEndPoints(formattedEndpointsList);
            } catch (error) {
                //@ts-ignore
                growl.error(error);
            } finally {
                setLoading(false);
            }
        };

        const getConnectionProfile = async () => {
            try {
                setLoading(true);
                const result = await connectionProfileService.getConnectionProfile(cpName);
                const values = getCPValues(result);
                setDataForEdit({
                    ...values,
                    namespace: { label: result.nsName, value: result.nsName },
                    connectionProfileName: result.name,
                    endpointName: { label: result.endPoint, value: result.endPoint }
                });
                setConnectionProfile(result);
            } catch (error) {
                //@ts-ignore
                growl.error(error);
                navigate(MANAGE_STRIIM_ROUTES.connectionProfiles);
            } finally {
                setLoading(false);
            }
        };
        if (isEdit) {
            getConnectionProfile();
        } else {
            getEndPoints();
        }
    }, []);

    //  "Databricks" // Change to some value "Databricks"

    const updateConnectionProfile = async () => {
        try {
            setDisableActionButton(true);
            const endPoint = formRef?.current?.endPointRef?.current?.values?.endpointName?.value;
            const values = { ...formRef?.current?.connectionRef?.current?.values };
            const properties = {
                ...values,
                authenticationType:
                    values.authenticationType && typeof values?.authenticationType !== "object"
                        ? values.authenticationType
                        : values.authenticationType?.value,
                ...oauthRef?.current
            };
            const result = await connectionProfileService.updateConnectionProfile(
                connectionProfile,
                endPoint,
                properties
            );
            //@ts-ignore
            growl.success("Connection profile edited successfully");
            navigate(MANAGE_STRIIM_ROUTES.connectionProfiles);
        } catch (error) {
            //@ts-ignore
            growl.error(error);
        } finally {
            setDisableActionButton(false);
        }
    };

    const createNewConnectionProfile = async () => {
        try {
            setDisableActionButton(true);
            const name = formRef?.current?.endPointRef?.current?.values?.connectionProfileName?.trim();
            const namespace = formRef?.current?.endPointRef?.current?.values?.namespace?.value;
            const endPoint = formRef?.current?.endPointRef?.current?.values?.endpointName?.value;
            const configValues = { ...formRef?.current?.connectionRef?.current?.values, ...oauthRef?.current };
            const result = await connectionProfileService.createConnectionProfile(
                name,
                namespace,
                endPoint,
                configValues
            );
            //@ts-ignore
            growl.success("Connection profile saved successfully");
            navigate(MANAGE_STRIIM_ROUTES.connectionProfiles);
        } catch (error) {
            //@ts-ignore
            growl.error(error);
        } finally {
            setDisableActionButton(false);
        }
    };

    const handleCancel = () => {
        navigate(MANAGE_STRIIM_ROUTES.connectionProfiles);
    };

    const handleSave = () => {
        if (dataForEdit) {
            updateConnectionProfile();
        } else {
            createNewConnectionProfile();
        }
    };

    return (
        <CPInlineDocContext.Provider
            value={{
                setEndPoint,
                connectionProfile
            }}
        >
            <ManageStriimPageWrapper
                header={
                    <ConnectionProfileItemHeader
                        title={
                            isEdit
                                ? `Edit ${
                                      dataForEdit
                                          ? dataForEdit.namespace?.value + "." + dataForEdit.connectionProfileName
                                          : ""
                                  }`
                                : "Create connection profile"
                        }
                        showBackButton={true}
                    />
                }
                footer={
                    <ConnectionProfileFooter
                        cancelDisabled={disableActionButtons}
                        onCancel={handleCancel}
                        onSave={handleSave}
                        saveDisabled={!isValid || disableActionButtons}
                    />
                }
                showHelpGuide={false}
                loading={loading}
                data-endpoint-selected={endpoint}
                inlineHelpDetails={
                    InlineDocDictionary[endpoint] || {
                        path: endpoint ? endpoint + "-configure-connection-profile.html" : undefined
                    }
                }
            >
                <ConnectionProfileFields
                    setIsValid={setIsValid}
                    dataForEdit={dataForEdit}
                    formRef={formRef}
                    endPoints={endPoints}
                    fdEndpoint={fdEndpoint}
                    oauthRef={oauthRef}
                />
            </ManageStriimPageWrapper>
        </CPInlineDocContext.Provider>
    );
};

export default CreateEditConnectionProfile;
