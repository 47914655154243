import React from "react";
import PropTypes from "prop-types";
import DashboardTile from "../dashboard-tile";
import useStyles from "./dashboard-list.style";
import { StriimMessageBox } from "@striim/striim-ui";
import useDashboardsStore from "src/stores/dashboards/useDashboardsStore";
import { observer } from "mobx-react";

const ShowFilters = observer(({ store }) => {
    const getMessageFromFilters = function(filters) {
        let message = `You are searching for Dashboards `;
        if (filters.textFilter) {
            message += `with name "${filters.textFilter}" `;
        }
        if (filters.nameSpaceFilter) {
            message += `in namespace "${filters.nameSpaceFilter}" `;
        }
        return message;
    };
    return (
        <>
            {store.filters.textFilter || store.filters.nameSpaceFilter ? (
                <StriimMessageBox
                    heading={"Filters Applied"}
                    text={getMessageFromFilters(store.filters)}
                    type={"INFO"}
                ></StriimMessageBox>
            ) : (
                <></>
            )}
        </>
    );
});
const DashboardsList = ({ mode, dashboards, homepage, sortToggled }) => {
    const classes = useStyles();
    const store = useDashboardsStore();
    const wide = mode === "list";
    const dashboardSort = (a, b) => {
        let aCompareValue = a.title ? a.title.toLowerCase() : a.name.toLowerCase();
        let bCompareValue = b.title ? b.title.toLowerCase() : b.name.toLowerCase();
        return !sortToggled ? (aCompareValue > bCompareValue ? 1 : -1) : aCompareValue < bCompareValue ? 1 : -1;
    };
    const listWrapperClass = wide ? classes.listWrapperWide : classes.listWrapper;

    return (
        <div className={!homepage ? classes.content : ""}>
            <ShowFilters store={store}></ShowFilters>
            <div className={listWrapperClass}>
                {(!homepage ? dashboards?.sort(dashboardSort) : dashboards).map(
                    dashboard =>
                        dashboard && (
                            <DashboardTile key={dashboard.id} wide={wide} {...{ dashboard }} homepage={homepage} />
                        )
                )}
            </div>
        </div>
    );
};

DashboardsList.propTypes = {
    mode: PropTypes.oneOf(["grid", "list"]),
    dashboards: PropTypes.array,
    sortToggled: PropTypes.bool
};

export default DashboardsList;
