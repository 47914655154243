import React from "react";
import { StriimTypography } from "@striim/striim-ui";
import { Grid, SvgIcon } from "@mui/material";

const styles = {
    container: {
        width: "100%",
        padding: 5
    },
    icon: {
        height: 40,
        width: 40,
        color: "greyscale.900"
    }
};

const EmptyTableInfo = ({ text, Icon }) => {
    return (
        <Grid container sx={styles.container} direction="column" alignItems="center">
            {Icon && (
                <Grid item xs={12} justifyContent="space-around">
                    <SvgIcon component={Icon} sx={styles.icon} />
                </Grid>
            )}
            <Grid item xs={12} justifyContent="space-around">
                <StriimTypography variant="body4" color="greyscale.900">
                    {text || `No data to display`}
                </StriimTypography>
            </Grid>
        </Grid>
    );
};

export default EmptyTableInfo;
