import React, { useState } from "react";
import PropTypes from "prop-types";
import { PropTypes as MobxPropTypes } from "mobx-react";

import AppGroupSelectList from "./app-group-select-list";
import AppGroupRecord from "./app-group-record";

const AppGroupSelect = ({ groups, applicationId, onClose, selectedApps }) => {
    const [isInCreateGroupMode, setCreateGroupMode] = useState(false);
    return (
        <div
            onMouseDown={e => {
                e.stopPropagation();
            }}
            onKeyDown={e => {
                e.stopPropagation();
            }}
        >
            {isInCreateGroupMode ? (
                <AppGroupRecord {...{ groups, onClose, setCreateGroupMode }} />
            ) : (
                <AppGroupSelectList {...{ groups, applicationId, onClose, setCreateGroupMode, selectedApps }} />
            )}
        </div>
    );
};

AppGroupSelect.propTypes = {
    groups: MobxPropTypes.observableArray,
    applicationId: PropTypes.string,
    onClose: PropTypes.func
};

export default AppGroupSelect;
