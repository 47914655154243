import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@material-ui/core";
import {
    StriimDropdown,
    StriimIconButton,
    StriimTypography,
    StriimModal,
    StriimMenuList,
    StriimMenuItem
} from "@striim/striim-ui";
import { makeStyles } from "@material-ui/core/styles";
import { MoreHoriz } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    collapsibleContent: {
        alignItems: "center",
        justifyContent: "space-between"
    },
    link: {
        color: "#00A7E5",
        marginRight: 4
    },
    inline: {
        display: "inline-block"
    }
}));

const styles = {
    deleteLink: {
        color: "critical.500"
    }
};

export default function PropertySetCollapsibleContent({
    nsName,
    propertySetName,
    isEditAllowed,
    addPropertySetValue,
    isDeleteAllowed,
    deletePropertySet
}) {
    const classes = useStyles();
    const [dropConfirmVisible, setDropConfirmVisible] = useState(false);

    return (
        <Grid container className={classes.collapsibleContent}>
            <Grid item xs={2}>
                <p>{nsName}</p>
            </Grid>
            <div
                onClick={e => {
                    e.stopPropagation();
                }}
                className={classes.inline}
            >
                {(isEditAllowed || isDeleteAllowed) && (
                    <StriimDropdown
                        extendOnClick
                        content={
                            <StriimMenuList>
                                {isEditAllowed && (
                                    <StriimMenuItem
                                        data-test-id="add-property-set-value-link"
                                        onClick={addPropertySetValue}
                                    >
                                        Add property set value
                                    </StriimMenuItem>
                                )}
                                {isDeleteAllowed && (
                                    <StriimMenuItem
                                        data-test-id="property-set-delete-button"
                                        sx={styles.deleteLink}
                                        onClick={() => {
                                            setDropConfirmVisible(true);
                                        }}
                                    >
                                        Delete
                                    </StriimMenuItem>
                                )}
                            </StriimMenuList>
                        }
                        closeOnSelect
                    >
                        <StriimIconButton
                            classes={{ root: classes.cardActionsButton }}
                            data-testid="property-set-options-button"
                        >
                            <Box component={MoreHoriz} color="text.secondary" />
                        </StriimIconButton>
                    </StriimDropdown>
                )}
            </div>
            <StriimModal
                size="medium"
                variant="error"
                isVisible={dropConfirmVisible}
                centerContent
                autoHeight
                onCancel={state => setDropConfirmVisible(state)}
                onConfirm={() => deletePropertySet()}
                titleContent={<StriimTypography variant="h2">Delete Property Set</StriimTypography>}
                confirmContent="Delete"
                cancelButtonProps={{ "data-test-id": "delete-property-set-cancel-btn" }}
                confirmButtonProps={{ "data-test-id": "delete-property-set-modal-btn" }}
                dialogProps={{ "data-test-id": "delete-property-set-modal" }}
            >
                {/** TODO: add property details */}
                <StriimTypography variant="body4">
                    Are you sure you want to delete {propertySetName}? It might affect your existing services. This
                    action cannot be undone.
                </StriimTypography>
            </StriimModal>
        </Grid>
    );
}

PropertySetCollapsibleContent.propTypes = {
    nsName: PropTypes.string.isRequired,
    addPropertySetValue: PropTypes.func.isRequired,
    deletePropertySet: PropTypes.func.isRequired,
    propertySetName: PropTypes.string.isRequired
};
