import * as Models from "./models";
import stringUtils from "../../../../../../../core/utils/string-utils";
const setItem = (storageName, model) => {
    if (model === undefined) return;
    localStorage.setItem(storageName, JSON.stringify(model));
};

const getItem = storageName => {
    try {
        return JSON.parse(localStorage.getItem(storageName));
    } catch (e) {
        localStorage.removeItem(storageName);
        return null;
    }
};

const getTargetKey = appName => {
    return `${appName}-target`;
};

const getHideSourceURLKey = appName => {
    return `${appName}-hideSourceURL`;
};

const removeItem = storageName => {
    localStorage.removeItem(storageName);
};

const isKeyPresent = storageName => {
    if (getItem(storageName)) return true;
    return false;
};

const cleanUp = name => {
    localStorage.removeItem(name);
    localStorage.removeItem(getTargetKey(name));
    localStorage.removeItem(getHideSourceURLKey(name));
};

const prepareSchemasCollection = schemas => {
    let schemasCollection = new Models.Schemas();
    _.each(schemas, function(schema) {
        schemasCollection.add(
            new Models.Schema().set({
                id: schema.id,
                isEnabled: schema.isEnabled,
                schemaName: schema.schemaName,
                schemaDisplayName: stringUtils.encodeHtml(schema.schemaDisplayName),
                tables: prepareTablesCollection(schema.tables)
            })
        );
    });
    return schemasCollection;
};

const prepareTablesCollection = tables => {
    let tableCollection = new Models.Tables();
    _.each(tables, function(table) {
        tableCollection.add(new Models.Table(table));
    });
    return tableCollection;
};

const updateModel = (storageName, model) => {
    const parsedEntireData = getItem(storageName);
    const updatedConnectionParams = new Models.ConnectionParams(parsedEntireData.connectionParams);
    const updatedSchemas = prepareSchemasCollection(parsedEntireData.schemas);
    model.set(parsedEntireData);
    model.set("connectionParams", updatedConnectionParams);
    model.set("schemas", updatedSchemas);
};

const module = {
    getItem: getItem,
    getTargetKey: getTargetKey,
    setItem: setItem,
    removeItem: removeItem,
    isKeyPresent: isKeyPresent,
    updateModel: updateModel,
    cleanUp: cleanUp
};

export default module;
