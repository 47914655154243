import _ from "underscore";
import { saveAs as saveFileAs } from "file-saver";
import Papa from "papaparse";

var chartDataExportManager = {};

chartDataExportManager.downloadFile = function (dataSource, visualizationType) {
    let csvValue = generateCsv(dataSource);

    returnFileToUser(csvValue, visualizationType);
};

function generateCsv(dataSource) {
    let data;

    if (dataIsGrouped(dataSource.attributes.data)) {
        data = _.flatten(_.map(dataSource.attributes.data, (x) => Object.values(x)));
    } else {
        data = dataSource.attributes.data;
    }

    var csvString = Papa.unparse(data);
    return csvString;
}

function dataIsGrouped(dataArray) {
    if (!dataArray || !dataArray[0]) {
        return false;
    }

    var data = dataArray[0];
    var result = Object.keys(data).every((x) => data[x] && typeof data[x] === "object");

    return result;
}

function returnFileToUser(csvValue, visualizationType) {
    saveFileAs(
        new Blob([csvValue], {
            type: "text/csv",
        }),
        visualizationType + "_" + _.now() + ".csv"
    );
}

export default chartDataExportManager;
