import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, SvgIcon } from "@mui/material";
import { StriimDataTable, StriimTypography, StriimModal } from "@striim/striim-ui";

import { AppNameContainer, SourceTarget } from "../../../components/select-tables-component/select-tables-component";
import { ErrorCross, WarningOutline } from "../../../../../generic/icon/customIcons";
import SuccessIcon from "src/generic/icon/wizard-icon/successOutlined.svg";
import { styles } from "./job-apps-table.styles";
import StriimCircularProgress from "../../../../apps/pages/manage-striim/connection-profiles/components/circular-progress/circular-progress";
import dateLib, { timezone } from "core/utils/date-utils";
import WithTooltip from "../../../../../generic/tooltip/tooltip";
import InfoIcon from "app/images/striimline/info-icon.svg";
import ProgressBar from "../progress-bar";
import navigateTo from "../../../../../navigate-to";
import { SearchBar } from "../../discovery/components/jobs-list-table";
import { FilterType } from "../../discovery/components/jobs-list-filters";
import useDebounce from "../../../../../hooks/useDebounce";
import { JobStatuses } from "../../guardian-job.types";
import { isApplicationAvailable } from "../job-list-insights-view/job-list-insights-view";
import dictionary from "../../../../../../app/components/common/helpable/online-help-dictionary";

const Colors = {
    error: "critical.500",
    warning: "warning.700",
    completed: "greyscale.700",
    pending: "greyscale.700"
};

const Icons = {
    completed: SuccessIcon,
    error: ErrorCross,
    warning: WarningOutline
};

export const formatUnixTimestamp = (timestamp: number) => {
    const now = new Date();

    //Convert to milliseconds if its seconds
    if (timestamp.toString().length === 10 || timestamp.toString().includes(".")) {
        timestamp *= 1000;
    }

    const timeDiff = now.getTime() - timestamp;
    const oneMinute = 60 * 1000;
    const oneHour = 60 * oneMinute;
    const oneDay = 24 * oneHour;
    const twoDays = 2 * oneDay;

    const defaultDateTimeConfig = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZoneName: "short"
    };

    if (timeDiff < oneHour) {
        const minutesAgo = Math.floor(timeDiff / oneMinute);
        return `${minutesAgo} minutes ago`;
    } else if (timeDiff < oneDay) {
        // @ts-ignore
        const time = new Date(timestamp).toLocaleString("en-US", defaultDateTimeConfig);
        return `Today, ${time}`;
    } else if (timeDiff < twoDays) {
        // @ts-ignore
        const time = new Date(timestamp).toLocaleString("en-US", defaultDateTimeConfig);
        return `Yesterday, ${time}`;
    } else {
        // @ts-ignore
        return new Date(timestamp).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
            ...defaultDateTimeConfig
        });
    }
};

const CustomHeader = props => {
    const { columnHeader } = props;
    return (
        <Grid container gap={0.5}>
            {columnHeader}
            {WithTooltip(
                <SvgIcon component={InfoIcon} sx={styles.iconInfo} />,
                <>
                    Entities discovered with sensitive data out of the total number of entities sampled. An entity is a
                    logical collection of data objects that share a common structure such as a table in a relational
                    database, a collection in a NoSQL database, a directory in a file system or a cloud object storage
                    system.{" "}
                    <StriimTypography
                        variant="caption3"
                        component="a"
                        href={dictionary.get()["AI_INSIGHTS_SHERLOCK"].href}
                        color="secondary.500"
                        target="_blank"
                    >
                        Learn more
                    </StriimTypography>
                </>
            )}
        </Grid>
    );
};

const JobStatusContainer = ({ jobStatus, isAppAvailable = true, setShowSelectedErrorModal, setSelectedError }) => {
    const status = jobStatus?.status?.toLowerCase();
    const errorMessage = jobStatus?.errorMessage;
    const isRunning = status === JobStatuses.RUNNING.toLowerCase();
    const timestamp = isRunning ? jobStatus?.generationTime : jobStatus?.completedTime;
    const Icon = Icons[status];
    const color = Colors[status];
    const [relativeTime, setRelativeTime] = useState("");

    useEffect(() => {
        if (!timestamp) return;

        const formattedDate = formatUnixTimestamp(timestamp);
        setRelativeTime(formattedDate);
    }, [timestamp]);

    return (
        <Grid container alignItems="center" gap={0.5} padding="10px">
            {!isAppAvailable ? (
                <StriimTypography variant="body4" color="greyscale.800">
                    App unavailable
                </StriimTypography>
            ) : isRunning ? (
                <>
                    <StriimCircularProgress size={16} />
                    <StriimTypography variant="body4" color={Colors.completed}>
                        Started {relativeTime}
                    </StriimTypography>
                </>
            ) : status === "error" ? (
                <StriimTypography
                    onClick={() => {
                        setSelectedError({ index: "", description: errorMessage });
                        setShowSelectedErrorModal(true);
                    }}
                    sx={styles.jobStatus}
                >
                    <SvgIcon component={Icon} sx={styles.icon} />
                    <StriimTypography variant="body4" color={color}>
                        {relativeTime}
                    </StriimTypography>
                </StriimTypography>
            ) : (
                <>
                    <SvgIcon component={Icon} sx={styles.icon} />
                    <StriimTypography variant="body4" color={color}>
                        {relativeTime}
                    </StriimTypography>
                </>
            )}
        </Grid>
    );
};

const JobAppsTable = ({
    isJobAppsPage = false,
    tableData,
    customMaxHeight = "100%",
    jobName = "",
    setShowSelectedErrorModal,
    setSelectedError,
    tabs
}) => {
    const [userSearchInput, setUserSearchInput] = useState("");
    const [filteredResults, setFilteredResults] = useState(tableData);
    const [showAppUnavailableModal, setShowAppUnavailableModal] = useState<boolean>(false);

    useEffect(() => {
        setFilteredResults(tableData);
    }, [tableData]);

    const tableColumns = useMemo(
        () => [
            {
                headerName: `App Name (${tableData.length})`,
                field: "name",
                flex: 1,
                suppressMovable: true,
                resizable: true,
                cellRenderer: params => (
                    <AppNameContainer
                        name={params.data.name}
                        status={params.data.flowStatus}
                        nsName={params.data.nsName}
                        onClick={async appFQN => {
                            const isAppAvailable = await isApplicationAvailable(appFQN);
                            if (isAppAvailable) {
                                navigateTo.AppSherlock(appFQN, jobName, true);
                            } else {
                                setShowAppUnavailableModal(true);
                            }
                        }}
                        isAppAvailable={params.data.isAppAvailable}
                    />
                ),
                cellClass: "appNamecellClass",
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: "Namespace",
                field: "nsName",
                suppressMovable: true,
                resizable: true,
                cellRenderer: ({ value }) => (
                    <StriimTypography variant="body4" color="greyscale.700">
                        {value ?? "-"}
                    </StriimTypography>
                )
            },
            {
                headerName: "Source-Target",
                field: "sourcetarget",
                suppressMovable: true,
                resizable: true,
                headerClass: "header-center no-hover",
                cellClass: "cell-center",
                cellRenderer: SourceTarget
            },
            {
                headerName: "Report Status",
                field: "jobStatus",
                flex: 1,
                suppressMovable: true,
                resizable: true,
                cellRenderer: params => {
                    return (
                        <JobStatusContainer
                            jobStatus={params.data.jobStatus}
                            isAppAvailable={params.data.isAppAvailable}
                            setShowSelectedErrorModal={setShowSelectedErrorModal}
                            setSelectedError={setSelectedError}
                        />
                    );
                }
            },
            {
                headerComponent: CustomHeader,
                field: "entitiesWithSensitiveData",
                headerComponentParams: {
                    columnHeader: "Entities w/ Sensitive Data "
                },
                flex: 1,
                suppressMovable: true,
                resizable: true,
                cellRenderer: params => {
                    const status = params.data?.jobStatus?.status?.toLowerCase();
                    const isRunning = status === JobStatuses.RUNNING.toLowerCase();

                    return params.data?.jobStatus?.status?.toLowerCase() === "error" ? (
                        <StriimTypography variant="body4" color={Colors["error"]}>
                            n/a
                        </StriimTypography>
                    ) : isRunning ? (
                        "-"
                    ) : (
                        <ProgressBar
                            completed={params.value?.completed}
                            total={params.value?.total}
                            status={params.jobStatus?.status?.toLowerCase()}
                        />
                    );
                }
            }
        ],
        [tableData]
    );

    const handleFilteredResults = useCallback(() => {
        if (userSearchInput.length) {
            const userInputFilter = FilterType.UserInput(userSearchInput);
            setFilteredResults(tableData.filter(userInputFilter.callbackFunction));
        } else {
            setFilteredResults(tableData);
        }
    }, [userSearchInput, tableData]);

    const setFiltersDebounced = useDebounce(handleFilteredResults, 300);

    //TODO: Replace the learn more link.
    return (
        <>
            {isJobAppsPage && (
                <Grid container justifyContent={"space-between"} alignItems={"center"} mb={2}>
                    {tabs}
                    <Box width={356}>
                        <SearchBar
                            isGroupTab={false}
                            userSearchInput={userSearchInput}
                            setUserSearchInput={setUserSearchInput}
                            setFiltersDebounced={setFiltersDebounced}
                        />
                    </Box>
                </Grid>
            )}
            <Box sx={styles.dataTableWrapper}>
                <StriimDataTable data={filteredResults} columns={tableColumns} maxHeight={customMaxHeight} />
            </Box>
            <StriimModal
                size="small"
                isVisible={showAppUnavailableModal}
                autoHeight
                onConfirm={() => {
                    setShowAppUnavailableModal(false);
                }}
                cancelContent={null}
                confirmContent={"Done"}
                titleContent={null}
                confirmButtonProps={{
                    "data-test-id": "app-unavailable-done-btn"
                }}
            >
                <StriimTypography variant="body4">
                    There is no data to display since the selected App was deleted.
                </StriimTypography>
            </StriimModal>
        </>
    );
};

export default JobAppsTable;
