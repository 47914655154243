import App from "app";
import _ from "underscore";
import Marionette from "marionette";
import BaseLayout from "app/components/common/wizardmaker/baseLayout";
import slidingItemTemplate from "./templates/slidingLayout.html";
import summaryTemplate from "./templates/summaryLayout.html";

// sliding layout
var SummaryLayout = Marionette.ItemView.extend({
    template: _.template(summaryTemplate),
    className: "title-container",
    initialize: function(options) {
        this.vent = options.vent;
        this.itemIndex = options.itemIndex;

        switch (
            this.model.get("status") //make the first step active
        ) {
            case "QUEUED":
                this.$el.addClass("queued-step");
                break;
            case "ACTIVE":
                this.$el.addClass("active-step");
                break;
            case "COMPLETE":
                this.$el.addClass("complete-step");
                break;
        }

        this.listenTo(this.model, "change", function() {
            //update the views as the steps are completed
            // queued-> active -> complete
            switch (this.model.get("status")) {
                case "QUEUED": {
                    this.$el.addClass("queued-step");
                    this.$el.removeClass("active-step");
                    break;
                }
                case "ACTIVE": {
                    this.$el.addClass("active-step");
                    this.$el.removeClass("queued-step");
                    this.$el.removeClass("complete-step");
                    break;
                }
                case "COMPLETE": {
                    this.$el.addClass("complete-step");
                    this.$el.removeClass("active-step");
                }
            }
        });
    },
    onRender: function() {
        this.$el.find(".step-num").html(this.itemIndex + 1);
    }
});

var SummaryCollection = Marionette.CollectionView.extend({
    childView: SummaryLayout,
    className: "summary-container",
    childViewOptions: function(model, index) {
        var _this = this;
        return {
            vent: _this.vent,
            itemIndex: index
        };
    },
    initialize: function(options) {
        this.currentStepIndex = options.currentStepIndex;
        this.vent = options.vent;
    }
});

var SlidingLayout = BaseLayout.extend({
    type: "sliding",
    template: _.template(slidingItemTemplate),
    regions: {
        summary: ".list-summary",
        "active-step": ".sliding-item-container"
    },
    events: {
        "click .slide-forward": "doNextHandler",
        "click .slide-back": "doPrevHandler"
    },
    onRender: function() {
        var _this = this;
        const navSteps = this.collection.filter(function(model) {
            return model.get("transient") !== true;
        });
        const navStepsCollection = new Backbone.Collection(navSteps);
        var summaryView = new SummaryCollection({
            collection: navStepsCollection,
            currentStepIndex: _this.currentStepIndex,
            vent: _this.vent
        });
        this.getRegion("summary").show(summaryView);
        //view for the current step
        var currentStepView = this.getCurrentView(this.currentStepIndex);
        this.getRegion("active-step").show(currentStepView);
    },
    doNextHandler: function() {
        this.currentStepIndex = this.collection.next();
        var currentStepView = this.getCurrentView(this.currentStepIndex);
        this.setURL(this.currentStepIndex);
        this.getRegion("active-step").show(currentStepView);
    },
    doPrevHandler: function() {
        const getPreviousNonTransientStep = () => {
            let prevIndex = this.collection.prev();
            while (this.collection.models[prevIndex].get("transient") === true) prevIndex = this.collection.prev();
            return prevIndex;
        };
        this.currentStepIndex = getPreviousNonTransientStep();
        var currentStepView = this.getCurrentView(this.currentStepIndex);
        this.setURL(this.currentStepIndex);
        this.getRegion("active-step").show(currentStepView);
    },
    gotoView: function(step, action) {
        let currentStep = this.collection.findWhere({ stepName: step });
        this.currentStepIndex = this.collection.indexOf(currentStep);
        this.collection.setActive(this.currentStepIndex, action);
        var currentStepView = this.getCurrentView(this.currentStepIndex);
        this.setURL(this.currentStepIndex);
        this.getRegion("active-step").show(currentStepView);
    }
});

export default SlidingLayout;
