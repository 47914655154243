import _ from "underscore";
import { observable, action, computed, toJS } from "mobx";
import statusManagement from "../../../../../../status-management";
import { getStatusInfo } from "./automated-app-group-stage-helper";
import metaStoreService from "../../../../../../../core/services/metaStoreService/meta-store-service";
import { sortAutomatedApps } from "../automated-app-group/utils/progress-details.helper";
import { getStartStopTime } from "./services";

class AutomatedGroupDetails {
    @observable groupName = "";
    @observable ilAppModel = "";
    @observable cdcAppModel = "";
    @observable currentFocusedApp = "IL";
    @observable appIds = [];
    @observable appStatuses = {};
    @observable isdeployModalVisible = false;
    @observable isdeployAndRunModalVisible = false;
    @observable isConfirmationModalVisible = false;
    @observable isAppTransitioning = false;

    @observable isSchemaReportReady = false;
    @observable isILReportReady = false;
    @observable isSchemaCreationRunning = null;

    @observable statusInfo = {};
    @observable startStopTime = "";

    showToolTipforReport = false;
    stopType = "default";

    constructor() {}

    async fetch(group) {
        if (!group) return;
        this.setisAppTransitioning(true);
        const appIds = sortAutomatedApps(group?.applications);
        const ilAppModel = await metaStoreService.findById(appIds[0]);
        const cdcAppModel = await metaStoreService.findById(appIds[1]);
        this.setDetails({
            appIds: appIds,
            ilAppModel: ilAppModel,
            cdcAppModel: cdcAppModel,
            groupName: group
        });
        this.setisAppTransitioning(false);
        this.updateStartStopTime(this.currentFocusedApp, this.currentFocusedAppStatus);
    }
    @computed get ilAppStatus() {
        const ilAppId = this.appIds[0];
        return this.appStatuses[ilAppId];
    }
    @computed get cdcAppStatus() {
        const cdcAppId = this.appIds[1];
        return this.appStatuses[cdcAppId];
    }
    @computed get currentFocusedAppStatus() {
        if (this.currentFocusedApp === "IL") {
            return this.ilAppStatus;
        } else {
            return this.cdcAppStatus;
        }
    }

    @action reset() {
        this.groupName = "";
        this.cdcAppModel = "";
        this.currentFocusedApp = "IL";
        this.ilAppModel = "";
        this.appIds = [];
        this.appStatuses = {};
        this.statusInfo = {};
        this.isdeployAndRunModalVisible = false;
        this.isdeployModalVisible = false;
        this.isConfirmationModalVisible = false;
        this.isSchemaReportReady = false;
        this.isILReportReady = false;
        this.showToolTipforReport = false;
        this.isSchemaCreationRunning = null;
        this.isAppTransitioning = false;
        this.startStopTime = "";
    }
    @action setDetails({ groupName, ilAppModel, cdcAppModel, appIds }) {
        this.groupName = groupName;
        this.ilAppModel = ilAppModel;
        this.cdcAppModel = cdcAppModel;
        this.appIds = appIds;
    }
    @action setCurrentFocusedApp(currentFocusedApp) {
        this.currentFocusedApp = currentFocusedApp;
    }
    @action setAppStatuses(appStatuses, isSchemaCreationRunning = null) {
        this.appStatuses = appStatuses;
        let statusInfo = getStatusInfo(appStatuses, isSchemaCreationRunning);
        if (statusInfo.stageInfo === "IL_RUNNING") {
            if (this.isSchemaCreationRunning === null || this.isSchemaCreationRunning === true) {
                this.statusInfo = getStatusInfo(appStatuses, true);
                return;
            }
        }
        this.statusInfo = statusInfo;
    }
    @action setIsDeployModalVisible(visibility) {
        this.isdeployModalVisible = visibility;
    }
    @action setIsDeployAndRunModalVisible(visibility) {
        this.isdeployAndRunModalVisible = visibility;
    }
    @action setIsConfirmationModalVisible(visibility, content) {
        this.isConfirmationModalVisible = visibility;
        this.stopType = content ? content : "default";
    }
    @action setIsSchemaReportReady(value) {
        this.isSchemaReportReady = value;
        this.showToolTipforReport = value;
    }
    @action setIsILReportReady(value) {
        this.isILReportReady = value;
        this.showToolTipforReport = value;
    }
    @action setShowToolTipforReport(value) {
        this.showToolTipforReport = value;
    }
    @action isDeletable() {
        if (_.isEmpty(this.appStatuses)) return false;
        const statuses = Object.values(this.appStatuses);
        return statusManagement.isApplicationCreated(statuses[0]) && statusManagement.isApplicationCreated(statuses[1]);
    }
    @action setisAppTransitioning(value) {
        this.isAppTransitioning = value;
    }
    @action setisSchemeCreationRunning(ilTableProgressInfo) {
        if (!ilTableProgressInfo) return;
        const schemaCreationDetails = ilTableProgressInfo.schemaCreationDetails;
        if (schemaCreationDetails.status === "Pending") {
            this.isSchemaCreationRunning = true;
        } else {
            this.isSchemaCreationRunning = false;
        }
        // set the appstatus with schemaCreationStatus taken from the table progress store (mon metrics)
        // if the schemaCreationRunning is true, then the IL stage  will be modified to
        // Il_RUNNING -> SCHEMA_CREATION_RUNNING
        // IL_STOPPED -> SCHEMA_CREATION_STOPPED
        // IL_TERMINATED -> SCHEMA_CREATION_TERMINATED
        this.setAppStatuses(this.appStatuses, this.isSchemaCreationRunning);
    }
    async updateStartStopTime(focusedApp, focusedAppStatus) {
        try {
            const startStopTime = await getStartStopTime(
                this.ilAppModel.uuid,
                this.cdcAppModel.uuid,
                focusedApp,
                focusedAppStatus
            );
            this.setStartStopTime(startStopTime);
        } catch (error) {
            console.error("Error displaying start/stop time", error);
        }
    }
    @action setStartStopTime(startStopTime) {
        this.startStopTime = startStopTime;
    }
}

const automatedGroupStore = new AutomatedGroupDetails();
export default automatedGroupStore;
