import React, { useEffect } from "react";
import { useFormikContext } from "formik";

const FormikErrorFocus = () => {
    const { isSubmitting, isValidating, errors } = useFormikContext();
    useEffect(() => {
        const keys = Object.keys(errors);

        if (keys.length > 0 && isSubmitting && !isValidating) {
            let selector = "";
            if (Array.isArray(errors[keys[0]])) {
                const index = errors[keys[0]].findIndex(v => !!v);
                const key = Object.keys(errors[keys[0]][index])[0];
                selector = `[name="${keys[0]}.${index}.${key}"]`;
            } else {
                selector = `[name="${keys[0]}"]`;
            }
            const errorElement = document.querySelector(selector);

            if (errorElement) {
                errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
                errorElement.focus();
            }
        }
    }, [isSubmitting, isValidating, errors]);

    return null;
};

export default FormikErrorFocus;
