const APP_ADAPTERS = [
    "ServiceNowReader",
    "ServiceNowWriter",
    "SalesForceReader",
    "SalesforcePardotReader",
    "SalesForcePlatformEventReader",
    "SalesForcePushTopicReader",
    "SalesforceWriter",
    "SalesforceCDCReader",
    "SalesforceMarketingCloudWriter",
    "MicrosoftDataverseWriter",
    "HubSpotReader",
    "ZendeskReader",
    "StripeReader",
    "JiraReader",
    "GoogleAdsReader",
    "IntercomReader",
    "GoogleSheetsReader",
    "PaymoReader",
    "BambooHrReader",
    "LinkedInAdsReader",
    "GoogleAnalyticsReader"
];
export default APP_ADAPTERS;
