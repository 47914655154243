import Backbone from "backbone";
import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import MetaObjectDataSource from "app/components/common/editor/control/select/metaobject-datasource";
import BaseEditor from "app/components/common/editor/meta-object-editors/base";

var Module = function (metadata_object, options) {
    options = options || {};
    options.displayFields = options.displayFields || ["typename", "queryProperties", "adapter", "parser"];
    options.subType = "Event Table";

    BaseEditor.apply(this, arguments);

    if (this.isNewMetaObject()) {
        options.title = "New Event Table";
    }
};

Module.prototype = _.extend({}, BaseEditor.prototype, {
    addCustomFields: function () {
        var _this = this;

        var streamValueConverter = function (handlerConst) {
            return {
                in: function (val) {
                    if (!val || !val.properties) {
                        return null;
                    }
                    return val.properties.name;
                },
                out: function (val) {
                    if (!val) {
                        return null;
                    }
                    return {
                        handler: handlerConst,
                        properties: {
                            name: val,
                        },
                    };
                },
            };
        };

        this.fieldMapper.adapter = UIControl.StreamSelector.extend({
            hidden: false,
            resources: {
                title: "Input stream",
                newStreamTitle: "New stream",
                existingStreamTitle: "Existing stream",
            },
            valueConverter: streamValueConverter("STREAM"),
            initialValueGetter: function (model) {
                return model.value;
            },
        }).create();
        this.fieldMapper.adapter.model.radioButtonName = "stream-selector-adapter";

        this.fieldMapper.parser = UIControl.StreamSelector.extend({
            hidden: false,
            resources: {
                title: "Delete stream",
                newStreamTitle: "New stream",
                existingStreamTitle: "Existing stream",
            },
            valueConverter: streamValueConverter("DSTREAM"),
            initialValueGetter: function (model) {
                return model.value;
            },
            required: false
        }).create();

        this.fieldMapper.queryProperties = UIControl.CacheQueryProperties.extend({
            subControl: true,
            showSkipInvalid: false,
            showRefreshType: false,
            showReplicas: true,
            showPersistPolicy: true,
        }).create();

        this.fieldMapper.typename = UIControl.Type.extend({
            hideLabel: true,
            editableType: true,
            collapsed: true,
            keyRequired: true,
        }).create();

        this.fieldMapper.typename.on(
            "type-saved",
            function () {
                this.Form.view.clearDirty(this.fieldMapper.typename.model);
            }.bind(this)
        );

        this.listenTo(this.fieldMapper.typename.model, "change:value", function (viewModel) {
            _this.fieldMapper.queryProperties.trigger("change:type:projections", viewModel.value);
        });
    },

    postRender: function () {
        var _this = this;

        this.fieldMapper.typename.setValue(this.MetaObjectModel.typename);
        this.fieldMapper.typename.on("modified", function () {
            _this.fieldMapper.queryProperties.trigger(
                "change:type:projections",
                _this.fieldMapper.typename.model.value
            );
        });
    },
});

export default Module;
