import React from "react";
import PropTypes from "prop-types";
import ChevronRight from "../../../../images/Chevron_Right.svg";
import { StriimIconWrapperBase } from "@striim/striim-ui";
import { icons } from "src/generic/icon/flow-designer-icon";
import useStyles from "./template-item-icons.styles";

const TemplateItemIcons = ({ fromIcon, toIcon }) => {
    const classes = useStyles();
    const SourceIcon = typeof fromIcon === "string" ? null : fromIcon;
    const TargetIcon = typeof toIcon === "string" ? null : toIcon;
    if (!icons[fromIcon] && !SourceIcon) console.log("Source Icon for  " + fromIcon + " not found");
    if (!icons[toIcon] && !TargetIcon) console.log("Target Icon for " + toIcon + " not found");

    const getTargetIcon = toIcon => {
        return TargetIcon ? (
            <TargetIcon className={classes.elementIcon} />
        ) : (
            <StriimIconWrapperBase Icon={icons[toIcon]} type="target" classNameContainer={classes.elementIcon} />
        );
    };

    return (
        <>
            {fromIcon &&
                (SourceIcon ? (
                    <SourceIcon className={classes.elementIcon} />
                ) : (
                    <StriimIconWrapperBase
                        Icon={icons[fromIcon]}
                        type="source"
                        classNameContainer={classes.elementIcon}
                    />
                ))}
            {fromIcon && toIcon && <ChevronRight className={classes.forwardIcon} />}
            {toIcon && getTargetIcon(toIcon)}
        </>
    );
};

TemplateItemIcons.propTypes = {
    fromIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
    toIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
};

export default TemplateItemIcons;
