import React from "react";
import { Grid, SvgIcon } from "@mui/material";
import { ChevronRight } from "src/generic/icon/customIcons";
import dateLib from "core/utils/date-utils";
import { dateFormat } from "../../../utils";
import { StriimIconButton, StriimTypography } from "@striim/striim-ui";

const Header = ({ componentName, startTime, endTime, todayLabel, onClick }) => {
    return (
        <Grid container gap={2}>
            <Grid item>
                <StriimIconButton
                    variant="secondary"
                    size="small"
                    sx={{ height: "36px", width: "36px" }}
                    onClick={onClick}
                >
                    <SvgIcon
                        sx={{
                            height: "20px",
                            width: "20px",
                            padding: 0.5,
                            rotate: "180deg"
                        }}
                    >
                        <ChevronRight width={"5px"} />
                    </SvgIcon>
                </StriimIconButton>
            </Grid>
            <Grid item sx={{ marginBlock: "auto" }}>
                <StriimTypography variant="h2" color="primary.700">
                    {`${componentName} | ${dateLib(startTime).format(dateFormat)} - `}
                    {todayLabel ? "Today" : dateLib(endTime).format(dateFormat)}
                </StriimTypography>
            </Grid>
        </Grid>
    );
};

export default Header;
