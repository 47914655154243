import $ from "jquery";
import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./password-field.html";
import TextField from "./text-field";

var Model = BaseControl.Model.extend({
    defaults: {
        value: null,
        encryptedValue: null,
    },
});

var View = TextField.View.extend({
    template: _.template(template),

    events: {
        "keyup input": "setValueFromView",
        "change input": "setValueFromView",
        "keydown input": "resetPassword",
    },

    resetPassword: function () {
        this.model.encryptedValue = null;
    },

    getViewValue: function () {
        return this.model.encryptedValue || this.$("input").val();
    },

    setViewValue: function () {
        var pwd = this.getValue();
        if (pwd) {
            if ($.isPlainObject(pwd)) {
                this.model.encryptedValue = pwd;
            } else {
                this.model.encryptedValue = null;
            }
            this.$("input").attr("placeholder", "*".repeat(pwd.length || 10));
        } else {
            this.$("input").val(null);
            this.$("input").attr("placeholder", "");
        }
    },

    onRender: function () {
        this.setViewValue();
    },
});

function buildPasswordField() {
    return _.extend({}, BaseControl, {
        View: View,
        Model: Model,
        WithPlaceholder: function (placeholder) {
            return buildPasswordField().extend({
                placeholder: placeholder,
            });
        },
    });
}

export default buildPasswordField();
