import React, { useCallback, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import { StriimControlLabel, StriimRadioGroup, StriimRadio, StriimTypography } from "@striim/striim-ui";
import { makeStyles } from "@material-ui/core/styles";
import { HashicorpVault, StriimVault, AzureVault } from "./";
import { VaultTypes } from "./constants";
import {
    formatHashicorpVaultDataForEdit,
    formatAzureVaultDataForEdit,
    formatGoogleSecretManagerDataForEdit
} from "./utils";
import GoogleSecretManager from "./google-secret-manager";
import { Grid, Box } from "@mui/material";

const useStyles = makeStyles(({ spacing, palette }) => ({
    fieldSet: {
        display: "flex",
        margin: "0 -13px 12px -13px"
    },
    card: {
        cursor: "default"
    }
}));

const sxStyles = {
    typography: {
        color: "greyscale.700"
    },
    radioGroupLabel: {
        color: "greyscale.800"
    },
    radioGroup: {
        rowGap: 0.5
    },
    border: {
        mt: 0,
        mx: -3,
        mb: 1.25,
        borderTop: "1px solid",
        borderColor: "greyscale.200"
    }
};

const VaultModalContent = React.memo(
    ({ setFormValues, setIsValid, selectedVaultType, setSelectedVaultType, dataForEdit }) => {
        const classes = useStyles();

        const getComponent = useCallback(
            vaultType => {
                let prefillData;
                switch (vaultType) {
                    case VaultTypes.STRIIM_VAULT:
                        return <StriimVault setFormValues={setFormValues} setIsValid={setIsValid} />;
                    case VaultTypes.HASHICORP_VAULT:
                        prefillData = dataForEdit ? formatHashicorpVaultDataForEdit(dataForEdit) : null;
                        return (
                            <HashicorpVault
                                setFormValues={setFormValues}
                                setIsValid={setIsValid}
                                dataForEdit={prefillData}
                            />
                        );
                    case VaultTypes.AZURE_VAULT:
                        prefillData = dataForEdit ? formatAzureVaultDataForEdit(dataForEdit) : null;
                        return (
                            <AzureVault
                                setFormValues={setFormValues}
                                setIsValid={setIsValid}
                                dataForEdit={prefillData}
                            />
                        );
                    case VaultTypes.GOOGLE_SECRET_MANAGER:
                        prefillData = dataForEdit ? formatGoogleSecretManagerDataForEdit(dataForEdit) : null;
                        return (
                            <GoogleSecretManager
                                setFormValues={setFormValues}
                                setIsValid={setIsValid}
                                prefillData={prefillData}
                            />
                        );
                }
            },
            [setFormValues, setIsValid, dataForEdit]
        );

        useEffect(() => {
            if (dataForEdit) {
                setSelectedVaultType(dataForEdit.vaultType);
            }
        }, [setSelectedVaultType, dataForEdit]);

        return (
            <>
                <StriimTypography sx={sxStyles.radioGroupLabel} variant="caption3">
                    Select the vault type:
                </StriimTypography>
                <FormControl component="fieldset" value={selectedVaultType} className={classes.fieldSet}>
                    <StriimRadioGroup
                        aria-label="Vault Types"
                        sx={sxStyles.radioGroup}
                        name="vault-types"
                        row={false}
                        value={selectedVaultType}
                        onChange={e => {
                            setSelectedVaultType(e.currentTarget.value);
                        }}
                    >
                        <StriimControlLabel
                            value={VaultTypes.STRIIM_VAULT}
                            control={<StriimRadio />}
                            className={classes.card}
                            label={
                                <StriimTypography sx={sxStyles.typography} variant="body4">
                                    Striim Vault
                                </StriimTypography>
                            }
                            disabled={dataForEdit ? true : false}
                        />
                        <StriimControlLabel
                            value={VaultTypes.HASHICORP_VAULT}
                            control={<StriimRadio />}
                            sx={sxStyles.card}
                            label={
                                <StriimTypography sx={sxStyles.typography} variant="body4">
                                    HashiCorp Vault
                                </StriimTypography>
                            }
                            disabled={dataForEdit ? true : false}
                        />
                        <StriimControlLabel
                            value={VaultTypes.AZURE_VAULT}
                            control={<StriimRadio />}
                            sx={sxStyles.card}
                            label={
                                <StriimTypography sx={sxStyles.typography} variant="body4">
                                    Azure Key Vault
                                </StriimTypography>
                            }
                            disabled={dataForEdit ? true : false}
                        />
                        <StriimControlLabel
                            value={VaultTypes.GOOGLE_SECRET_MANAGER}
                            control={<StriimRadio />}
                            sx={sxStyles.card}
                            label={
                                <StriimTypography sx={sxStyles.typography} variant="body4">
                                    Google Secret Manager
                                </StriimTypography>
                            }
                            disabled={dataForEdit ? true : false}
                            data-test-id="google-secret-manager-input"
                        />
                    </StriimRadioGroup>
                </FormControl>
                <Box sx={sxStyles.border} />
                {getComponent(selectedVaultType)}
            </>
        );
    }
);

VaultModalContent.displayName = "VaultModalContent";

export default VaultModalContent;
