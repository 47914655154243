import React from "react";
import {
    StriimSimpleTableCell,
    StriimInputField,
    StriimButton,
    StriimDropdown,
    StriimMenuList,
    StriimMenuItem
} from "@striim/striim-ui";
import { makeStyles } from "@material-ui/core/styles";
import VaultsService from "./vaults-service";
import { VaultTypes } from "./constants";
import { MoreHorizontal } from "src/generic/icon/customIcons";

const useStyles = makeStyles(({ palette }) => ({
    deleteButton: {
        color: palette.danger.main
    },
    tableCell: {
        verticalAlign: "middle"
    }
}));


const sxStyles = {
    typographyProps: {
        variant: "body4",
        color: "greyscale.900"
    }
};

const VaultValueRowReadOnly = ({ data, isEditAllowed, isDeleteAllowed, deleteRow, editExistingVaultValue }) => {
    const classes = useStyles();
    const isFile = data?.data?.valueType === "FILE";
    const isStriimVault = data?.vault?.vaultType === VaultTypes.STRIIM_VAULT;
    //if file content is too large, it affects position of nearby fields. hence value is truncated since its masked.
    const fileContentTruncateLength = 80;
    const vaultValue = data?.data?.value;

    const menu = (
        <StriimMenuList>
            {isEditAllowed && (
                <StriimMenuItem
                    data-test-id="edit-vault-value-button"
                    id={`${data?.dataKey}-edit-button`}
                    variant="primaryText"
                    onClick={() => editExistingVaultValue(data)}
                >
                    Edit
                </StriimMenuItem>
            )}
            {isDeleteAllowed && (
                <StriimMenuItem
                    data-test-id="vaults-delete-vault-value-button"
                    variant="errorText"
                    onClick={() => deleteRow && deleteRow(data.vault.id, data.dataKey)}
                    className={classes.deleteButton}
                >
                    Delete
                </StriimMenuItem>
            )}
        </StriimMenuList>
    );

    return (
        <>
           <StriimSimpleTableCell
                value={data?.dataKey}
                typographyProps={sxStyles.typographyProps}
                classes={classes.tableCell}
                tableCellProps={{ "data-test-id": `vault-value-key-${data?.dataKey}` }}
                id="data-key"
            />
            {/* Don't show Vault value type for HashiCorp and Azurekey Vault */}
            {isStriimVault && (
                <StriimSimpleTableCell
                    value={data?.data?.valueType}
                    id="vault-value-type"
                    typographyProps={sxStyles.typographyProps}
                    classes={classes.tableCell}
                />
            )}
            <StriimSimpleTableCell value={vaultValue} classes={classes.tableCell}>
                <StriimInputField
                    value={
                        isFile && vaultValue?.length > fileContentTruncateLength
                            ? vaultValue.substring(0, fileContentTruncateLength)
                            : vaultValue
                    }
                    type={"password"}
                    disabled
                    typographyProps={sxStyles.typographyProps}
                />
            </StriimSimpleTableCell>
            <StriimSimpleTableCell
                classes={classes.tableCell}
                value={VaultsService.getVaultUsage(data?.vault, data?.dataKey)}
                id="usage"
                tableCellProps={{ "data-test-id": `vault-value-usage-${data?.dataKey}` }}
                typographyProps={sxStyles.typographyProps}
            />
            {isStriimVault && ( isEditAllowed || isDeleteAllowed ) && (
                <StriimSimpleTableCell classes={classes.tableCell}>
                    <StriimDropdown extendOnClick closeOnSelect content={menu} placement="bottom">
                        <StriimButton data-testid={`vault-value-menu-${data?.dataKey}`}>
                            <MoreHorizontal fontSize={20} />
                        </StriimButton>
                    </StriimDropdown>
                </StriimSimpleTableCell>
            )}
        </>
    );
};
export default VaultValueRowReadOnly;
