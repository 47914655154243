import componentTemplate from "./templates/componentTemplate.html";
import predefinedComponentProcessor from "../nodeProcessing/predefinedComponentProcessor";
import securityCheck from "core/services/securityService/adapter-security-check";
import { iconMaker } from "app/components/common/icon-maker/icon-maker";
import utils from "core/utils";
import securityService from "core/services/securityService/securityService";
import { segmentationTooltip, segmentationIndicator } from "app/components/common/segmentationComponents";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import StriimChip from "src/generic/chip/chip";
import MarionetteWrapper from "app/components/marionette-wrapper-for-react";

var ComponentView = Backbone.Marionette.LayoutView.extend({
    template: _.template(componentTemplate),
    tagName: "div",
    className: "draggable-sidebar-component component",
    regions: {
        iconRegion: ".icon-container"
    },
    isDragDropEnabled: true,
    currentAppStatus: null,

    ui: {
        icon: ".icon",
        name: ".component-name"
    },

    modelEvents: {
        "change:isVisible": "_onVisibleChanged"
    },

    onRender: function() {
        this.$el.attr("data-type", this.model.componentType);
        this.$el.attr("data-name", this.model.name);
        this.$el.attr("data-id", this.model.id);
        var that = this;
        that.$el.addClass("enabled");
        const licenseData = this._getLicenseData(this.model.componentType, this.model.id);
        let licenseIndicator;
        if (
            (this.model.componentType === "SOURCE" || this.model.componentType === "TARGET") &&
            this.model.id.indexOf("base-") === -1
        ) {
            if (!this.model.get("isEnabled")) {
                that.$el.addClass("disabled").removeClass("enabled");
                that.$el.attr(
                    "title",
                    "Enabling the selected adapter requires additional license, please contact support@striim.com"
                );
                that.$el.tooltipster({
                    contentAsHTML: true,
                    animation: "grow",
                    delay: 500
                });
            }
        } else {
            this._handleNotAvailableFeatures(licenseData);
            licenseIndicator = segmentationIndicator(licenseData.variant, "seg-indicator-component-view").outerHTML;
        }
        if (this.model.version) {
            this._appendVersionToName();
        }

        let icon = iconMaker.createComponentIcon(this.model.componentType, this.model.iconClass, licenseIndicator);
        this.getRegion("iconRegion").show(icon);
    },

    _appendVersionToName: function() {
        this.ui.name.append('<span class="component-version"></span>');
        const region = this.addRegion("versionRegion", ".component-version");
        const versionChip = MarionetteWrapper(StriimChip, {
            label: this.model.version.toUpperCase(),
            variant: "intresting"
        });
        region.show(versionChip);
    },

    _getLicenseData: function(componentType, id) {
        const data = { licenseType: "", availableInLicense: true, variant: undefined };

        if (id === "base-Event-Table") {
            data.licenseType = addonFeaturesKeys.EVENT_TABLE;
        } else {
            switch (componentType) {
                case utils.ENTITIES.CACHE:
                    data.licenseType = addonFeaturesKeys.CACHE;
                    break;
                case utils.ENTITIES.EXTERNALCACHE:
                    data.licenseType = addonFeaturesKeys.EXTERNAL_CACHE;
                    break;
                case utils.ENTITIES.OPENPROCESSOR:
                    data.licenseType = addonFeaturesKeys.OPENPROCESSOR;
                    break;
                case utils.ENTITIES.ROUTER:
                    data.licenseType = addonFeaturesKeys.ROUTER_COMP;
                    break;
                case utils.ENTITIES.WACTIONSTORE:
                    data.licenseType = addonFeaturesKeys.WACTION_STORE;
                    break;
                case utils.ENTITIES.WINDOW:
                    data.licenseType = addonFeaturesKeys.WINDOW;
                    break;
                case utils.ENTITIES.EVENT_TABLE:
                    data.licenseType = addonFeaturesKeys.EVENT_TABLE;
                    break;
            }
        }

        if (data.licenseType) {
            data.availableInLicense = securityService.isSegmentationFeatureEnabled(data.licenseType);
            data.variant = securityService.getSegmentationVariant(data.licenseType);
        }

        return data;
    },

    _handleNotAvailableFeatures: function({ variant, licenseType, availableInLicense }) {
        if (availableInLicense) {
            return;
        }

        this.$el.removeClass("enabled");
        this.$el.tooltipster(
            segmentationTooltip(variant, licenseType, this.$el, (origin, tooltip) => {
                var tooltipOffset = tooltip.offset();
                if (tooltipOffset.left <= 50) {
                    tooltip.offset({
                        top: tooltipOffset.top,
                        left: 50
                    });
                }
            })
        );

        return;
    },

    _onVisibleChanged: function(view, value, model) {
        this.$el.toggleClass("hidden", !value);
    }
});

export default ComponentView;
