import React from "react";
import { Grid } from "@mui/material";
import { StriimModal, StriimTypography, StriimButton } from "@striim/striim-ui";

import ConnectionProfileFields from "../../create-edit-connection-profile/connection-profile-fields";
import HelpGuide from "../help-guide/help-guide";
import { styles } from "./connection-profiles-modal.styles";
import CloseIcon from "src/generic/icon/manage-striim/close.svg";
import { getEndpointNameWithIcon } from "../../utils";
import InlineDocPanel from "../../../../../../../generic/inline-doc/inline-doc-panel";
import InlineDocContainer from "../../../../../../../generic/inline-doc/inline-doc-container";
import InlineDocDictionary from "../../../../../../../generic/inline-doc/inline-doc-dictionary";

interface ConnectionProfilesModalProps {
    isValid: boolean;
    setIsValid: () => void;
    formRef: any;
    oauthRef: any;
    endPoints: string[];
    isVisible: boolean;
    isSaveDisabled: boolean;
    setShowModal: (boolean) => void;
    onCancel: () => void;
    onSave: () => void;
}

const ConnectionProfilesModal = ({
    isValid,
    setIsValid,
    formRef,
    oauthRef,
    endPoints,
    isVisible,
    isSaveDisabled,
    setShowModal,
    onCancel,
    onSave
}: ConnectionProfilesModalProps) => {
    const handleClose = () => {
        onCancel();
        setShowModal(false);
    };

    const formattedEndpointsList = endPoints.map(getEndpointNameWithIcon);
    const endpoint = endPoints?.length === 1 ? endPoints[0] : null;
    const fdEndpoint = endpoint?.replace(/\s+/g, "-").toLowerCase();
    const docData = InlineDocDictionary[fdEndpoint] || {
        path: `${fdEndpoint}-configure-connection-profile.html`,
        cssSelector: "section"
    };
    return (
        <StriimModal
            autoHeight
            isVisible={isVisible}
            size="medium"
            dialogProps={{
                sx: {
                    ".MuiDialog-paper": {
                        width: "fit-content"
                    }
                }
            }}
            dialogTitleProps={{
                display: "none"
            }}
            dialogActionProps={{
                style: { display: "none" }
            }}
            dialogContentProps={{
                style: {
                    padding: 0
                }
            }}
            titleContent={<></>}
            footerContent={<></>}
        >
            <InlineDocPanel
                path={docData.path}
                inlineDocContent={<InlineDocContainer path={docData.path} className={docData.className} />}
            >
                <Grid
                    container
                    width={"100%"}
                    height={"100%"}
                    display="flex"
                    data-test-id="create-connection-profile-modal"
                    sx={styles.modalContainer}
                >
                    <Grid container item flex={1} flexDirection={"column"} sx={styles.leftContainer}>
                        <Grid
                            item
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={styles.titleContainer}
                        >
                            <StriimTypography variant={"h2"}>
                                New {endPoints?.length === 1 ? endPoints[0] : ""} Connection Profile
                            </StriimTypography>
                            <CloseIcon onClick={() => handleClose()} />
                        </Grid>
                        <Grid item flex={1} sx={styles.formContainer}>
                            <ConnectionProfileFields
                                setIsValid={setIsValid}
                                dataForEdit={null}
                                formRef={formRef}
                                endPoints={formattedEndpointsList}
                                fdEndpoint={endpoint}
                                oauthRef={oauthRef}
                                isCurrentViewFD={true}
                            />
                        </Grid>
                        <Grid container display="flex" justifyContent="flex-end" sx={styles.footerContainer}>
                            <Grid item>
                                <Grid container gap={1}>
                                    <StriimButton
                                        variant="text"
                                        onClick={() => {
                                            handleClose();
                                        }}
                                        data-test-id={"add-connection-profile-cancel-button"}
                                    >
                                        Cancel
                                    </StriimButton>
                                    <StriimButton
                                        onClick={() => {
                                            onSave();
                                        }}
                                        variant="primary"
                                        disabled={!isValid || isSaveDisabled}
                                        data-test-id={"add-connection-profile-save-button"}
                                    >
                                        Save
                                    </StriimButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </InlineDocPanel>
        </StriimModal>
    );
};

export default ConnectionProfilesModal;
