import React, { useEffect, useMemo, useRef, useState } from "react";
import { useUserManagement } from "core/user-management/user-management-context";
import RoleTable from "../common/role-table";
import PermissionTable from "../common/permissions-table";
import { ButtonsBar, CancelEditModal, DeleteModal } from "src/modules/apps/pages/user-management/common";
import BasicInfo, { validationSchema } from "./components/basic-info";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styles from "../../../common/users-management.styles";

import {
    canActivateDeactivateUser,
    isSaaSUser,
    isUserDeletable,
    isUserEditable
} from "core/user-management/update.control";
import { Grid, LinearProgress } from "@mui/material";

const UserEdit = ({ id }) => {
    const navigate = useNavigate();
    const formRef = useRef();
    const [passwordValue, setPasswordValue] = useState("");

    const {
        userManagementProviderBusy,
        userDetails,
        getUserDetailsById,
        grantPermissionsToUser,
        revokePermissionsFromUser,
        grantRolesToUser,
        revokeRolesFromUser,
        updateUser,
        deleteUser,
        activateUser,
        deactivateUser
    } = useUserManagement();

    const [addedRoles, setAddedRoles] = useState([]);
    const [revokedRoles, setRevokedRoles] = useState([]);
    const [addedPermissions, setAddedPermissions] = useState([]);
    const [revokedPermissions, setRevokedPermissions] = useState([]);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [cancelDialogVisible, setCancelDialogVisible] = useState(false);

    useEffect(() => {
        getUserDetailsById(id);
        setPasswordValue(uuidv4().substring(0, 12));
    }, []);

    useEffect(() => {
        if (userDetails && id === userDetails["User Id"] && !isUserEditable(userDetails)) {
            navigate("/users");
        }
    }, [userDetails]);

    const userRoles = useMemo(
        () =>
            (userDetails?.roles || []).map(v => ({
                id: v.name,
                ...v
            })),
        [userDetails]
    );

    const userPermissions = useMemo(() => userDetails?.permissions || [], [userDetails]);

    const handleSave = async () => {
        if (!isSaaSUser(userDetails)) {
            const { values: basicInfoValues } = formRef.current;
            var isValid = validationSchema.isValidSync(basicInfoValues);
            if (!isValid) {
                formRef && formRef.current && formRef.current.handleSubmit();
                return;
            }
            const updatedPassword = basicInfoValues.password === passwordValue ? "" : basicInfoValues.password;

            await updateUser(
                basicInfoValues.username,
                updatedPassword,
                basicInfoValues.ldapuser,
                basicInfoValues.email,
                basicInfoValues.firstName,
                basicInfoValues.lastName,
                basicInfoValues.phone,
                basicInfoValues.timezone,
                basicInfoValues.uiconfig
            );
        }

        const rolesToAdd = addedRoles.filter(x => revokedRoles.indexOf(x.name) === -1);
        if (rolesToAdd.length > 0) {
            await grantRolesToUser(
                id,
                rolesToAdd.map(x => x.name)
            );
        }

        const rolesToRevoke = revokedRoles.filter(x => addedRoles.map(p => p.name).indexOf(x) === -1);
        if (rolesToRevoke.length > 0) {
            await revokeRolesFromUser(id, rolesToRevoke);
        }

        const permissionsToGrant = addedPermissions.filter(x => revokedPermissions.map(p => p.id).indexOf(x.id) === -1);
        for (let i = 0; i < permissionsToGrant.length; i++) {
            const permissionToAdd = permissionsToGrant[i];
            await grantPermissionsToUser(
                id,
                `${permissionToAdd.namespace}.${permissionToAdd.objectName}`,
                permissionToAdd.actions,
                permissionToAdd.objectTypes
            );
        }

        const permissionsToRevoke = revokedPermissions.filter(
            x => addedPermissions.map(p => p.id).indexOf(x.id) === -1
        );
        for (let i = 0; i < permissionsToRevoke.length; i++) {
            const permissionToRevoke = permissionsToRevoke[i];
            await revokePermissionsFromUser(
                id,
                `${permissionToRevoke.namespace}.${permissionToRevoke.objectName}`,
                permissionToRevoke.actions,
                permissionToRevoke.objectTypes
            );
        }

        navigate("/users");
    };

    const handleDelete = () => {
        setDeleteDialogVisible(true);
    };

    const toggleActivation = () => {
        if (userDetails.isActive) {
            deactivateUser(id);
        } else {
            activateUser(id);
        }
        navigate("/users");
    };
    const onDeleteConfirm = () => {
        deleteUser(id);
        setDeleteDialogVisible(false);
        navigate("/users");
    };

    return userManagementProviderBusy ? (
        <Grid item xs={12}>
            <LinearProgress color="secondary" />
        </Grid>
    ) : (
        <>
            <Grid item xs={12} sx={styles.contentContainer}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <BasicInfo
                            userDetails={userDetails}
                            formRef={formRef}
                            passwordValue={passwordValue}
                            isEditable={!isSaaSUser(userDetails)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RoleTable
                            userRoles={userRoles}
                            addedRoles={addedRoles}
                            setAddedRoles={setAddedRoles}
                            revokedRoles={revokedRoles}
                            setRevokedRoles={setRevokedRoles}
                            isEditing
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PermissionTable
                            userPermissions={userPermissions}
                            addedPermissions={addedPermissions}
                            setAddedPermissions={setAddedPermissions}
                            revokedPermissions={revokedPermissions}
                            setRevokedPermissions={setRevokedPermissions}
                            isEditing
                            isEditable={!isSaaSUser(userDetails)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} mt={3}>
                <ButtonsBar
                    isDeletable={userDetails && isUserDeletable(userDetails)}
                    onDeleteClick={handleDelete}
                    onCancelClick={() => setCancelDialogVisible(true)}
                    onSaveClick={handleSave}
                    showActivateDeactivateBtn={userDetails && canActivateDeactivateUser(userDetails)}
                    isUserActive={userDetails?.isActive}
                    onActivationToggle={toggleActivation}
                />
            </Grid>
            <CancelEditModal
                title={`Are you sure you want to discard editing user ${id}?`}
                dialogVisible={cancelDialogVisible}
                setDialogVisible={setCancelDialogVisible}
                onConfirm={() => navigate("/users")}
            />
            <DeleteModal
                id={id}
                type={"user"}
                deleteDialogVisible={deleteDialogVisible}
                setDeleteDialogVisible={setDeleteDialogVisible}
                onDeleteConfirm={onDeleteConfirm}
            />
        </>
    );
};

export default UserEdit;
