import App from "../../../app";
import React, {useEffect, useRef} from "react";
import { Controller } from "../../../app/components/preview/preview";
import { useReactHeader } from "../../hooks/useReactHeader";

const Preview = () => {
    useReactHeader({title: "Source Preview"})
    const useInitializedRef = useRef(false);

    useEffect(() => {
        if(useInitializedRef.current){
            return;
        }

        const controller = new Controller();
        App.vent.trigger("killModals");
        controller.showPage();
        useInitializedRef.current = true;
    },[])

    return <></>


}

export default Preview;