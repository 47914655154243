export default {
    process: function(newNodeType, nodeModel, parentNode) {
        if (newNodeType === "ROUTER" && parentNode) {
            nodeModel.metaObject.autoProperties = nodeModel.metaObject.autoProperties || {};

            if (parentNode.metaObject.type === "STREAM") {
                nodeModel.metaObject.autoProperties.inStream = parentNode.metaObject.id;
            }
        }
    }
};
