import $ from "jquery";
import _ from "underscore";
import flowUtils from "./flowUtils";

var keyCodes = {
    ARROW_LEFT: 37,
    ARROW_UP: 38,
    ARROW_RIGHT: 39,
    ARROW_DOWN: 40,
};

// ************************************************************
//
// Helper methods
//
// ************************************************************
function _findParentNodes(selectedNode, model) {
    var result = [];
    for (var nodeIdx = 0; nodeIdx < model.nodes.length; nodeIdx++) {
        var node = model.nodes.models[nodeIdx];
        for (var targetIdx = 0; targetIdx < node.targets.length; targetIdx++) {
            if (node.targets[targetIdx] === selectedNode.id) {
                result.push(node);
            }
        }
    }
    return result;
}

function _findNextHorizontalNode(event, selectedNode, model, getComponentsDistance) {
    // find parent node
    var parentNodes = _findParentNodes(selectedNode, model);
    var targets = [];
    if (parentNodes.length === 0) {
        targets = _.map(model.nodes.filterRootNodes(), function (component) {
            return component.id;
        });
    } else {
        targets = parentNodes[0].targets;
    }
    var $selectedNodeEl = $("#" + selectedNode.elementId);
    if ($selectedNodeEl.length === 0) {
        return null;
    }
    var selectedElLeft = $selectedNodeEl.offset().left;

    // iterate through all components on same hierarchy level
    var minDistance = 1000000000;
    var nextComponent = null;

    for (var targetIdx = 0; targetIdx < targets.length; targetIdx++) {
        var targetId = targets[targetIdx];

        // skip self reference
        if (targetId === selectedNode.id) {
            continue;
        }

        // skip flow nodes
        var possibleNextNode = model.nodes.byId(targetId);
        if (possibleNextNode.isFlowNode) {
            continue;
        }

        // get component position
        var nextElId = flowUtils.sanitizeElementId(targetId);
        var $nextEl = $("#" + nextElId);
        if ($nextEl.length === 0) {
            return null;
        }

        // if element position is closer then update returned component
        var distance = getComponentsDistance($nextEl.offset().left, selectedElLeft);
        if (distance < 0) {
            //el is on wrong side
            continue;
        }
        if (distance < minDistance) {
            nextComponent = possibleNextNode;
            minDistance = distance;
        }
    }

    if (nextComponent === null) {
        return null;
    }

    return nextComponent;
}

// ************************************************************
//
// Finding next node by specified direction: up, down left, right
//
// ************************************************************

function _findNextDownNode(event, selectedNode, model) {
    if (event.keyCode !== keyCodes.ARROW_DOWN) {
        return null;
    }

    for (var i = 0; i < selectedNode.targets.length; i++) {
        var possibleNextNode = model.nodes.byId(selectedNode.targets[i]);
        if (!possibleNextNode || possibleNextNode.isFlowNode) {
            continue;
        }
        return possibleNextNode;
    }
    return null;
}

function _findNextUpNode(event, selectedNode, model) {
    if (event.keyCode !== keyCodes.ARROW_UP) {
        return null;
    }
    var possibleNodes = _findParentNodes(selectedNode, model);
    for (var i = 0; i < possibleNodes.length; i++) {
        if (possibleNodes[i].isFlowNode === false) {
            return possibleNodes[i];
        }
    }
    return null;
}

function _findNextRightNode(event, selectedNode, model) {
    if (event.keyCode !== keyCodes.ARROW_RIGHT) {
        return null;
    }

    return _findNextHorizontalNode(event, selectedNode, model, function (nextNodeLeft, selectedNodeLeft) {
        return nextNodeLeft - selectedNodeLeft;
    });
}

function _findNextLeftNode(event, selectedNode, model) {
    if (event.keyCode !== keyCodes.ARROW_LEFT) {
        return null;
    }

    return _findNextHorizontalNode(event, selectedNode, model, function (nextNodeLeft, selectedNodeLeft) {
        return selectedNodeLeft - nextNodeLeft;
    });
}

// ************************************************************
//
// Public methods
//
// ************************************************************

function initialize(model, graphNodesView) {
    var $content = $(".right-content");
    $content.attr("tabindex", "1");
    $content.off("keydown.test");
    $content.on("keydown.test", function (event) {
        var nodes = model.nodes.getSelectedNodes();
        if (nodes.length !== 1) {
            return;
        }
        var selectedNode = nodes[0];

        var nextNode = _findNextDownNode(event, selectedNode, model);

        if (nextNode === null) {
            nextNode = _findNextUpNode(event, selectedNode, model);
        }

        if (nextNode === null) {
            nextNode = _findNextRightNode(event, selectedNode, model);
        }

        if (nextNode === null) {
            nextNode = _findNextLeftNode(event, selectedNode, model);
        }

        if (nextNode === null) {
            return;
        }

        graphNodesView.unselectAllNodes();
        graphNodesView.selectNode(nextNode);
    });
}

function focus() {
    $(".right-content").focus();
}

export default {
    initialize: initialize,
    focus: focus,
};
