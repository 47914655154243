import _ from "underscore";
import BaseControl from "./../base-control";
import React from "react";
import ReactDOM from "react-dom/client";
import FlowAlertComponentAlertmanager from "src/modules/apps/pages/alert-manager/components/flow-alert-component-alertmanager/flow-alert-component-alertmanager";
import { StriimTheme } from "@striim/striim-ui";

/*class AlertEnabledInfoBoxStore {
    @observable hide = true;
    @observable properties = null;

    constructor() {}

    @action setHide(value) {
        this.hide = value;
    }

    @action setProperties(value) {
        this.properties = value;
    }
}*/

var Model = BaseControl.Model.extend({
    defaults: {
        value: null
    }
});

var View = BaseControl.View.extend({
    template: _.template(`<div>   </div>`),

    getViewValue: function() {
        return this.model.get("value"); // No op, we're doing it in react.
    },

    setViewValue: function() {
        // No op, we're doing it in react.
    },
    valueChanged: function(value) {
        this.setValue(value);
        this.forceChanged();
    },

    setHide(hide) {
        if (hide) {
            this.$el.slideUp("fast");
        } else {
            this.$el.slideDown("fast");
        }
    },

    onShow: function() {
        try {
            if (this.hide) {
                this.$el.hide();
            }
            const root = ReactDOM.createRoot(this.$el[0]);
            root.render(
                <StriimTheme>
                    <FlowAlertComponentAlertmanager
                        hide={this.hide}
                        componentTitle={this.componentTitle}
                        componentId={this.componentId}
                        componentType={this.componentType}
                    />
                </StriimTheme>
            );
        } catch (e) {
            console.error("Error rendering Info Box", e);
        }
    }
});

function buildTextField() {
    return function({ hide, componentId, componentType, componentTitle }) {
        return _.extend({}, BaseControl, {
            View: View.extend({
                hide,
                componentType,
                componentTitle,
                componentId,
                hideLabel: true
            }),
            Model: Model
        });
    };
}

export default buildTextField();
