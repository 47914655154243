export const styles = {
    messageBox: {
        maxWidth: "832px",
        marginBottom: ({ spacing }) => spacing(1),
        backgroundColor: ({ palette }) => palette.critical[50],
        borderColor: ({ palette }) => palette.critical[100],
        wordBreak: "break-word",
        "& .MuiCardHeader-content": {
            fontWeight: 400,
            fontSize: 14,
            fontFamily: "Inter",
            color: ({ palette }) => palette.greyscale[800],
            lineHeight: "20px",
            letterSpacing: "0.14px"
        },
        "& svg": {
            fill: ({ palette }) => palette.critical[500]
        },
        "& a": {
            padding: 0
        }
    }
};
