import App from "app";
import api from "core/api/api";
import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var module = {};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "TARGET",
        adapter: Object,
        formatter: Object,
        inputStream: String,
    },

    save: function () {
        // for WebAlertAdapter and EmailAdapter we set isSubscription flag
        // to create a subscription instead of target
        if (this.adapter) {
            if (
                this.adapter.handler === "Global.PROPERTYTEMPLATE.WebAlertAdapter" ||
                this.adapter.handler === "Global.PROPERTYTEMPLATE.EmailAdapter"
            ) {
                this.adapter.properties.isSubscription = true;
            } else {
                if (this.adapter.properties) {
                    delete this.adapter.properties.isSubscription;
                }
            }
        }

        return baseMetaObject.Model.prototype.save.apply(this, arguments);
    },
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model,
});

export default module;
