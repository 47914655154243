import App from "app";
import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";

function sortFolderStructure(items) {
    var dirs = _.sortBy(
        _.filter(items, {
            isDirectory: true,
        }),
        function (i) {
            return i.name.toLowerCase();
        }
    );
    var files = _.sortBy(
        _.filter(items, {
            isDirectory: false,
        }),
        function (i) {
            return i.name.toLowerCase();
        }
    );
    return [].concat(dirs, files);
}

var FileTrees = {};
App.addInitializer(function () {
    App.reqres.setHandler("browse:initial", FileTrees.browseInitial);
    App.reqres.setHandler("browse:dir", FileTrees.browseDir);
    App.reqres.setHandler("browseDirById", FileTrees.browseDirById);
});
FileTrees.File = Backbone.Model.extend({
    url: function () {
        var base = "/file_browser";
        var url = base;
        if (this.get("id")) {
            url = url + "?path=" + this.get("id") + "&children=true";
        } else {
            url = url + "?children=true";
        }
        return url;
    },
    initialize: function () {
        var id = this.get("id");
        if (typeof id === "undefined") {
            var path = this.get("currentPath");
            if (path) {
                var name = this.get("name");
                this.id = path + "/" + name;
                var tmpId = path + "/" + name;
                this.set("id", tmpId);
            }
        }
        var children = this.get("children");
        if (children) {
            children = new FileTrees.FileCollection(children);
            this.set("hasChildren", true);
        } else {
            this.set("hasChildren", false);
        }
    },
});
FileTrees.FileCollection = Backbone.Collection.extend({
    model: FileTrees.File,
});

FileTrees.browseInitial = function () {
    var col = new FileTrees.FileCollection();
    var file = new FileTrees.File({
        id: "",
    });
    var deferred = $.Deferred();
    file.fetch()
        .then(function(data, textStatus, jqXHR) {
            file.set("isDirectory", data.isDirectory);
            file.set("name", data.name);
            file.set("children", new FileTrees.FileCollection(sortFolderStructure(data.children)));
            file.set("hasChildren", true);
            file.set("currentPath", data.currentPath + "/" + data.name);
            file.set("id", data.currentPath + "/" + data.name);
            col.add(file);
            deferred.resolve(file, textStatus, jqXHR);
        })
        .fail(deferred.reject);
    return deferred.promise();
};

FileTrees.browseDir = function (path) {
    var deferred = $.Deferred();
    var col = new FileTrees.FileCollection();

    var file = new FileTrees.File({
        id: path,
    });
    file.fetch()
        .then(function(data, textStatus, jqXHR) {
            file.set("isDirectory", data.isDirectory);
            file.set("name", data.name);
            file.set("children", new FileTrees.FileCollection(sortFolderStructure(data.children)));
            file.set("hasChildren", true);
            col.add(file);
            deferred.resolve(file, textStatus, jqXHR);
        })
        .fail(function (data) {
            if (data.status !== 200) {
                alert("File or directory not found");
            }
        });
    return deferred.promise();
};
/*Recursively iterate through top level nested directory collection, find model with id, and return a deferred object */
FileTrees.browseDirById = function (collection, id) {
    /* See if model exists in collection*/
    var model = collection.get(id);

    var deferred;

    /* Collections are nested. If model doesn't exist in current collection, recursively iterate
     through each model in collection to see if child exists in one of the models' nested collections
     */
    if (model) {
        deferred = $.Deferred();
        /* Create deferred object only when model corresponding to chosen file/dir is found */
        var children = model.get("children");
        if (children && children.length > 0) {
            model.set("children", new FileTrees.FileCollection([]));
            model.set("hasChildren", false);
            deferred.resolve(model);
        } else {
            model.fetch().then(function(data) {
                model.set("children", new FileTrees.FileCollection(sortFolderStructure(data.children)));
                model.set("hasChildren", true);
                deferred.resolve(model);
            });
        }
    } else {
        _.each(collection.models, function (child) {
            if (child.get("isDirectory") && child.get("children")) {
                /* Recursive call */
                var ret = FileTrees.browseDirById(child.get("children"), id);
                if (ret) {
                    deferred = ret;
                }
            }
        });
    }
    if (deferred) {
        return deferred;
    } else {
        return $.Deferred();
    }
};

export default FileTrees;
