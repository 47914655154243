import React, { useMemo } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Grid, Box, SvgIcon } from "@mui/material";
import { StriimTypography, StriimLink, StriimChip, StriimTooltip } from "@striim/striim-ui-v2";

import SentinelTableCommon from "./sentinel-table-common";
import { InfoCircle } from "../../../../../generic/icon/customIcons";
import { styles } from "./sentinel-table.styles";
import { EmptyBar, Colors } from "./sentinel-table-utils";
import { getHighestAmount } from "./utils";
import WithTooltip from "../../../../../generic/tooltip/tooltip";

const SentinelTable = ({
    title,
    tableData,
    filteredTableData,
    setFilteredTableData,
    timeRange,
    setSelectedSentinel,
    showProgress
}) => {
    const highestAmount = useMemo(() => getHighestAmount(filteredTableData, false), [filteredTableData]);

    const tableColumns = useMemo(
        () => [
            {
                headerName: "Sentinel Name",
                headerClass: "dataTableHeader",
                field: "name",
                initialWidth: 370,
                flex: 0.2,
                suppressMovable: true,
                resizable: true,
                cellRenderer: ({ value, data }) => WithTooltip(
                    <StriimTypography color="greyscale.900" variant="body4" sx={styles.nameRowLabel}>
                        <StriimLink href="" onClick={() => setSelectedSentinel({ label: value, value })}>
                            <Box component="span" sx={styles.longValueWrap}>
                                {value}
                            </Box>
                        </StriimLink>

                        {data.disabled && (
                            <StriimChip hideAvatar type="tag-chip" variant="default" label="DETECTION DISABLED" />
                        )}
                    </StriimTypography>,
                    value
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: "Events Processed",
                headerClass: "dataTableHeader",
                field: "processedEvents",
                flex: 0.2,
                suppressMovable: true,
                resizable: true,
                sortable: true,
                cellRenderer: ({ value }) => (
                    <StriimTypography color="greyscale.700" variant="body4">
                        {value}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerComponent: () => (
                    <Box sx={styles.customHeader2Rows}>
                        <Box sx={styles.sensitiveDataLabelTop} justifyContent="flex-end" mr="24px">
                            Events Tagged
                            {/* <SvgIcon component={InfoCircle} sx={styles.infoCircle} /> */}
                        </Box>
                    </Box>
                ),
                headerClass: "dataTableHeader",
                field: "dataTagged",
                initialWidth: 270,
                flex: 0.2,
                suppressMovable: true,
                resizable: true,
                cellClass: "dataCellTextEnd",
                cellRenderer: ({ value, data }) => (
                    <StriimTypography color="greyscale.700" variant="body4">
                        {data.disabled
                            ? "-"
                            : value === 0
                            ? value
                            : `${value} (${Math.round((100 * value) / data.sensitiveData)}%)`}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: "Events W/ Sensitive Data",
                headerClass: "dataTableHeader",
                initialWidth: 250,
                suppressMovable: true,
                resizable: true,
                headerComponent: () => (
                    <Box sx={styles.customHeader2Rows}>
                        <Box sx={styles.sensitiveDataLabelTop}>
                            <span style={{ ...styles.sensitiveDataSquare, backgroundColor: Colors.Sensitive }}></span>
                            Events W/ Sensitive Data
                        </Box>
                        <Box sx={styles.sensitiveDataLabelBottom}>
                            <StriimTypography color="greyscale.900" variant="caption4">
                                0
                            </StriimTypography>
                            <StriimTypography color="greyscale.900" variant="caption4">
                                {highestAmount / 2}
                            </StriimTypography>
                            <StriimTypography
                                color="greyscale.900"
                                variant="caption4"
                                sx={styles.sensitiveDataLastValue}
                            >
                                {highestAmount}
                            </StriimTypography>
                        </Box>
                        <Box sx={styles.lineSpanWrapper}>
                            <Box component="span" sx={styles.lineSpan} />
                            <Box component="span" sx={styles.lineSpan} />
                            <Box component="span" sx={styles.lineSpan} />
                        </Box>
                    </Box>
                ),
                cellRenderer: ({ data }) => {
                    if (!(data.sensitiveData + data.nonSensitiveData > 0)) return <EmptyBar />;

                    return (
                        <StriimTooltip
                            slotProps={{ tooltip: { sx: styles.tooltip } }}
                            title={
                                <Box sx={styles.tooltipWrapper}>
                                    <StriimTypography variant="caption4" fontFamily="Inter" sx={styles.tooltipHeading}>
                                        {`LAST ${timeRange?.toUpperCase()}`}
                                    </StriimTypography>
                                    <Box sx={styles.tooltipLabel}>
                                        <Box component="span" sx={styles.tooltipLabelTitle}>
                                            <span
                                                style={{ ...styles.tooltipSquare, backgroundColor: Colors.Sensitive }}
                                            ></span>
                                            <StriimTypography
                                                variant="caption3"
                                                fontFamily="Inter"
                                                color="greyscale.700"
                                            >
                                                Events W/ Sensitive Data
                                            </StriimTypography>
                                        </Box>

                                        <Box component="span">
                                            <StriimTypography variant="caption3" fontFamily="Inter" color="#1E1E5C">
                                                {data.sensitiveData}
                                            </StriimTypography>
                                        </Box>
                                    </Box>

                                    <Box sx={styles.tooltipLabel}>
                                        <Box component="span" sx={styles.tooltipLabelTitle}>
                                            <span
                                                style={{
                                                    ...styles.tooltipSquare,
                                                    backgroundColor: Colors.NonSensitive
                                                }}
                                            ></span>
                                            <StriimTypography
                                                variant="caption3"
                                                fontFamily="Inter"
                                                color="greyscale.700"
                                            >
                                                Events W/O Sensitive Data
                                            </StriimTypography>
                                        </Box>

                                        <Box component="span">
                                            <StriimTypography variant="caption3" fontFamily="Inter" color="#1E1E5C">
                                                {data.nonSensitiveData}
                                            </StriimTypography>
                                        </Box>
                                    </Box>
                                </Box>
                            }
                        >
                            <Grid container sx={styles.chartWrapper}>
                                <ResponsiveContainer width="100%" height={20}>
                                    <BarChart
                                        // width={barWidth}
                                        height={20}
                                        data={[
                                            {
                                                sensitiveData: data.sensitiveData,
                                                nonSensitiveData: data.nonSensitiveData,
                                                totalApps: data.nonSensitiveData - data.sensitiveData
                                            }
                                        ]}
                                        layout="vertical"
                                    >
                                        <XAxis hide type="number" domain={[0, 300]} />
                                        <YAxis hide type="category" />

                                        <Bar
                                            dataKey="sensitiveData"
                                            stackId="single-stack"
                                            animationDuration={0}
                                            fill={Colors.Sensitive}
                                        />
                                        <Bar
                                            dataKey="nonSensitiveData"
                                            stackId="single-stack"
                                            animationDuration={0}
                                            fill={Colors.NonSensitive}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                                <StriimTypography
                                    variant="caption4"
                                    fontFamily="Inter"
                                    color="greyscale.700"
                                    paddingLeft={0.6}
                                >
                                    {data.sensitiveData}
                                </StriimTypography>
                            </Grid>
                        </StriimTooltip>
                    );
                },
                headerCheckboxSelection: false,
                checkboxSelection: false
            }
        ],
        [tableData, filteredTableData]
    );

    return (
        <SentinelTableCommon
            tableColumns={tableColumns}
            title={title}
            tableData={tableData}
            filteredTableData={filteredTableData}
            setFilteredTableData={setFilteredTableData}
            headerHeight={65}
            manyRowsHeader
            showProgress={showProgress}
            showSentinelsFilter={true}
        />
    );
};

export default SentinelTable;
