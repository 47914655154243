// Data format:
// eventcount:1000,interval:5s

/**
 * Converts transaction policy string value to object
 * @param value - transaction policy string
 * @returns object
 */
function parseFromString(value) {
    if (!value) {
        return null;
    }
    try {
        const regex =
            "(messagecount(\\s)*:(\\s)*(?<eventsCount>(\\d*)))?(,)?(interval(\\s)*:(\\s)*(?<interval>(\\d)*)(\\s)*(?<intervalUnit>(\\D)))?";
        let groups = value.match(regex).groups;
        return groups;
    } catch (ex) {
        console.warn(`Could not parse transaction policy [${value}]`);
        return null;
    }
}

/**
 * Converts eventsCount and interval pair to string
 * @param eventsCount - number of events
 * @param interval - interval formatted as number and short unit for example "5 s"
 * @returns string
 */
function convertToString(eventsCount, interval) {
    const result = [];

    if (!!eventsCount) {
        result.push("messagecount:" + eventsCount);
    }
    if (!!interval) {
        result.push("interval:" + interval);
    }

    if (result.length === 0) {
        return null;
    }

    return result.join(",");
}

export const TransactionPolicyParser = {
    parseFromString: parseFromString,
    convertToString: convertToString,
};
