import React from "react";
import { StriimMessageBox, StriimTypography, StriimLink } from "@striim/striim-ui-v2";
import { Box, Grid } from "@mui/material";
import { OpenLink } from "../../../../generic/icon/customIcons";
import dictionary from "../../../../../app/components/common/helpable/online-help-dictionary";

const styles = {
    link: {
        margin: ({ spacing }) => spacing(-1, -1, -2, -2)
    },
    iconStyles: {
        "& svg": {
            fill: "none"
        }
    }
};

const getSourceMessages = () => {
    return {
        SALESFORCE: {
            message:
                "Ensure that the user has the required permissions to avoid errors when running the Application group as Striim does not validate users' permissions to access objects in Salesforce.",
            link: "Salesforce Documentation",
            href: dictionary.get()["PROPERTYTEMPLATE_SalesforceCDCReader"].href
        },
        POSTGRES: {
            message:
                "Ensure that the user has the required permissions to avoid errors when running the Application group as Striim does not validate users' permissions to access tables in Postgres.",
            href: dictionary.get()["PROPERTYTEMPLATE_PostgreSQLReader"].href,
            link: "Postgres Documentation"
        }
    };
};
const SourceInfoComponent = ({ sourceID }) => {
    const { message, href, link } = getSourceMessages()?.[sourceID] ?? {};
    if (message) {
        return (
            <Box mb={2} mt={1}>
                <StriimMessageBox
                    type="NOTIFICATION"
                    text={
                        <StriimTypography variant="body4" color="greyscale.900">
                            {message}
                            {link && (
                                <Grid container mt={0.25}>
                                    <StriimLink
                                        endIcon={
                                            <Box sx={styles.iconStyles}>
                                                <OpenLink />
                                            </Box>
                                        }
                                        target="_blank"
                                        href={href}
                                        sx={styles.link}
                                    >
                                        {link}
                                    </StriimLink>
                                </Grid>
                            )}
                        </StriimTypography>
                    }
                />
            </Box>
        );
    }
    return true;
};
export default SourceInfoComponent;
