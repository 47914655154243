import React, { useState, useEffect } from "react";
import { Divider, Grid, SvgIcon } from "@mui/material";
import {
    StriimDropdown,
    StriimMenuList,
    StriimMenuItem,
    StriimTypography,
    StriimGradientChip
} from "@striim/striim-ui";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import App from "app";

import AIInsightsPopover from "../ai-insights-popover/ai-insights-popover";
import withTheme from "../../../../../generic/theme/theme";
import { Sentinel, Sherlock, ShieldLockWhite, SpecialChevronDown } from "../../../../../generic/icon/customIcons";
import { styles } from "./ai-insights-icon.styles";
import { isUserAdmin } from "core/user-management/update.control";
import licenseService from "core/services/metaStoreService/license-service";

interface AIInsightsIconProps {
    appKey: string;
    onDiscoverClick: Function;
    showSeparator?: boolean;
    onSentinelLiveMonitorClick: Function;
}

const AIInsightsIcon = ({
    appKey,
    onDiscoverClick,
    onSentinelLiveMonitorClick,
    showSeparator = true
}: AIInsightsIconProps) => {
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const controlState = useState(false);

    const open = Boolean(anchor);
    const id = open ? "simple-popper" : undefined;

    useEffect(() => {
        App.vent.on("showPIIPopover", () => {
            const el = document.querySelector("#ai-insights");
            setAnchor(el);
        });
    }, []);

    return (
        isUserAdmin() &&
        !licenseService?.isAIInsightsDisabled && (
            <>
                {showSeparator && <Grid sx={styles.verticalDivider} mr={2}></Grid>}
                <BasePopup
                    id={id}
                    open={open}
                    anchor={anchor}
                    placement={"bottom"}
                    offset={24}
                    slotProps={{
                        root: {
                            style: { zIndex: 99999 }
                        }
                    }}
                >
                    <AIInsightsPopover
                        onDiscoverSensitiveData={() => {
                            setAnchor(null);
                            onDiscoverClick();
                        }}
                        onClose={() => {
                            setAnchor(null);
                        }}
                    />
                </BasePopup>

                <Grid
                    onClick={() => {
                        setAnchor(null);
                    }}
                >
                    <StriimDropdown
                        controlState={controlState}
                        extendOnClick
                        placement="bottom-start"
                        PopperProps={{
                            sx: styles.dropdownList
                        }}
                        content={
                            <StriimMenuList>
                                <Grid
                                    container
                                    py={2}
                                    px={1.5}
                                    gap={1}
                                    flexDirection={"column"}
                                    alignItems={"flex-start"}
                                >
                                    <StriimGradientChip version="ai" />
                                    <StriimTypography variant="h4" color="primary.700">
                                        Sensitive Data Governance
                                    </StriimTypography>
                                </Grid>
                                <Divider orientation="horizontal" sx={{ borderColor: "greyscale.200" }} />
                                <StriimMenuItem
                                    data-test-id={`ai-insights-discover`}
                                    onClick={onDiscoverClick}
                                    sx={{
                                        px: 1.5,
                                        py: 1
                                    }}
                                >
                                    <Grid container gap={1}>
                                        <Grid item>
                                            <SvgIcon component={Sherlock} sx={styles.icon} />
                                        </Grid>
                                        <Grid item display={"flex"} flexDirection={"column"} gap={0.5}>
                                            <StriimTypography variant="body4" color="greyscale.900">
                                                Sherlock AI
                                            </StriimTypography>
                                            <StriimTypography variant="caption3" color="greyscale.700">
                                                Discover sensitive data or view previous reports
                                            </StriimTypography>
                                        </Grid>
                                    </Grid>
                                </StriimMenuItem>
                                <StriimMenuItem
                                    data-test-id={`ai-insights-sentinel-monitor`}
                                    onClick={() => {
                                        onSentinelLiveMonitorClick();
                                    }}
                                    sx={{
                                        px: 1.5,
                                        py: 1
                                    }}
                                >
                                    <Grid container gap={1}>
                                        <Grid item>
                                            <SvgIcon component={Sentinel} sx={styles.sentinelIcon} />
                                        </Grid>
                                        <Grid item display={"flex"} flexDirection={"column"} flex={1} gap={0.5}>
                                            <StriimTypography variant="body4" color="greyscale.900">
                                                Sentinel AI Activity
                                            </StriimTypography>
                                            <StriimTypography
                                                variant="caption3"
                                                color="greyscale.700"
                                                whiteSpace={"break-spaces"}
                                            >
                                                Monitor the sensitive data discovered by Sentinels in the App
                                            </StriimTypography>
                                        </Grid>
                                    </Grid>
                                </StriimMenuItem>
                            </StriimMenuList>
                        }
                        closeOnSelect
                    >
                        <Grid
                            display={"flex"}
                            sx={styles.insightsIcon}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={0.5}
                        >
                            <SvgIcon component={ShieldLockWhite} sx={styles.guardianIcon} />
                            <SvgIcon component={SpecialChevronDown} sx={styles.chevronIcon} />
                        </Grid>
                    </StriimDropdown>
                </Grid>
            </>
        )
    );
};

export default withTheme(AIInsightsIcon);
