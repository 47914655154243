import App from "app";
import NestedTypes from "backbone.nestedtypes";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import Dropdown from "app/components/common/dropdown";
import "lib/vendor/jquery.colorpicker";
import "./editor";

App.module("Dashboard.VisEditor", function(VisEditor, App, Backbone, Marionette, $, _) {
    var Color = NestedTypes.Model.extend({
        defaults: {
            name: String,
            color: String,
            current: false
        }
    });

    var ColorSwatch = Marionette.ItemView.extend({
        template: _.template(""),
        modelEvents: {
            change: "render"
        },
        onRender: function() {
            this.$el
                .removeClass()
                .addClass("color-swatch " + this.model.color)
                .css({
                    "background-color": this.model.color
                });

            if (this.model.current) this.$el.addClass("active");
        }
    });

    var ColorSwatches = Marionette.CompositeView.extend({
        className: "color-swatches",
        template: _.template(""),
        childView: ColorSwatch.extend({
            triggers: {
                click: "click"
            }
        }),
        initialize: function() {
            this.listenTo(this, "childview:click", function(swatch) {
                this.trigger("swatches:choose", swatch.model);
            });
        }
    });

    var ColorPicker = Marionette.LayoutView.extend({
        className: "color-chooser",
        template: _.template(
            '<div class="color-swatches-container"></div><div class="options-divider"><span>Or</span></div><div class="custom-color-container"><input type="text" placeholder="Enter color"></div>'
        ),
        regions: {
            //current: '.current-color-container',
            swatches: ".color-swatches-container"
            //custom: '.custom-color-container'
        },
        initialize: function() {
            this.listenTo(this.model, "change:current", function() {
                this.render();
            });
        },
        onRender: function() {
            this.swatches.el = this.regions.swatches;
            console.log("Rendering color picker...");
            var _this = this;

            // Set all colors inactive
            this.model.colors.each(function(c) {
                c.current = false;
            });

            var swatchesview = new ColorSwatches({
                collection: this.model.colors
            });

            var theme_color = this.model.colors.findWhere({
                color: this.model.current.color
            });
            if (theme_color) {
                theme_color.current = true;
            }

            this.$(".custom-color-container input").val(!theme_color ? this.model.current.color : "");

            this.listenTo(swatchesview, "swatches:choose", function(swatch) {
                this.model.current.set(swatch.toJSON());
                this.trigger("swatches:choose");
            });

            this.$("input").on("change", function() {
                _this.model.current.color = $(this).val();
                _this.trigger("swatches:choose");
            });

            this.getRegion("swatches").show(swatchesview);
        }
    });

    VisEditor[dataVisualizations.EDITOR.COLOR] = VisEditor.Editor.LayoutView.extend({
        className: "color-input",
        templateHTML: '<div class="color-display-container"></div><div class="color-picker-container"></div>',
        regions: {
            display: ".color-display-container",
            picker: ".color-picker-container"
        },
        onRender: function() {
            var key = this.model.editor.attribute;

            // A color model holding the current color from our config
            // When it changes, update our actual config
            var current_color = new Color({
                color: this.model.config[key]
            });
            this.listenTo(current_color, "change", function(model) {
                this.model.config.set(key, model.color);
            });

            // Color display and color picker views
            var ColorPickerModel = NestedTypes.Model.extend({
                defaults: {
                    colors: Color.Collection,
                    current: Color
                }
            });
            var all_theme_colors = this.model._theme.colors.text
                .concat(this.model._theme.colors.background)
                .concat(this.model._theme.colors.hues);

            var colorpicker = new ColorPicker({
                model: new ColorPickerModel({
                    current: current_color,
                    colors: [
                        {
                            color: "transparent"
                        }
                    ].concat(
                        _(all_theme_colors).map(function(color) {
                            return {
                                color: color
                            };
                        })
                    )
                })
            });
            var colordisplay = new ColorSwatch({
                model: current_color
            });

            // Hide the dropdown anytime a selection is made
            this.listenTo(colorpicker, "swatches:choose", function() {
                this._dropdown.hide();
            });

            // Show the views
            this.getRegion("picker").show(colorpicker);
            this.getRegion("display").show(colordisplay);
        },
        onShow: function() {
            //this.getRegion("display").$el.css("background-color", "black");

            // When the color picker view is rendered, set up the dropdown
            var $c = this.getRegion("picker").currentView.$el;
            this._dropdown = new Dropdown({
                trigger: $(this.getRegion("display").el),
                classes: "color-picker-dropdown",
                content: $c,
                dynamic: true,
                position: "bottom-left",
                on_show: function() {},
                on_hide: function() {}
            });
        }
    });
});
