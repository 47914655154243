import $ from "jquery";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import hierarchicalFormatter from "./hierarchicalFormatter";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";

export default function(types, groupBy, skipGlobal, componentIds = null) {
    const deferred = $.Deferred();
    metaStoreService.fetchIdentifiersByTypes(Array.isArray(types) ? types : [types]).then(function(results) {
        deferred.resolve(
            hierarchicalFormatter(
                componentIds
                    ? results.filter(r => componentIds.includes(r.id))
                    : results.filter(r => (skipGlobal ? !metaObjectConverter.isGlobal(r.id) : true)),
                false,
                groupBy
            )
        );
    });

    return deferred.promise();
}
