import React, { Dispatch, SetStateAction, useEffect, useState, useMemo, useRef } from "react";
import { StriimTypography, StriimMessageBox, StriimToggleButton, StriimInputField } from "@striim/striim-ui";
import { Box, Grid, SvgIcon, InputAdornment, Divider } from "@mui/material";
import { Search, Clear } from "@mui/icons-material";
import { components } from "react-select";
import { Documentation, Reset, Add } from "../../../../../generic/icon/customIcons";
import styles from "./data-identifier-actions.styles";
import IdentifierActionsTable, {
    TableData,
    MaskingOption
} from "../../../components/identifier-actions-table/identifier-actions-table";
import { taggingOptionsWithIcons } from "../../panel/sentinel-panel-container";
import { TaggingLevel } from "../../panel/sentinel-panel-container";
import { StriimLink } from "@striim/striim-ui-v2";
import dictionary from "../../../../../../app/components/common/helpable/online-help-dictionary";

interface DataIdentifierActionsProps {
    tablesData: TableData[];
    rowData: TableData[];
    setRowData: Dispatch<SetStateAction<TableData[]>>;
    maskingOptions: MaskingOption[];
    isExpanded: boolean;
    isDisabled: boolean;
    actionToggle: boolean;
    setActionToggle: Dispatch<SetStateAction<boolean>>;
    tagEventsToggle: boolean;
    setTagEventsToggle: Dispatch<SetStateAction<boolean>>;
    taggingLevels: TaggingLevel[];
    setTaggingLevels: Dispatch<SetStateAction<TaggingLevel[]>>;
    partialMaskingSDIOptions: string[];
    setIsDirty: Dispatch<SetStateAction<boolean>>;
}

const CustomShortenedLabelWithIcon = props => {
    return (
        <components.MultiValue {...props}>
            <Box display="flex" alignItems="center">
                {props.data.icon && (
                    <Box mr={1} display="flex" alignItems="center">
                        {React.cloneElement(props.data.icon, { style: { width: 14, height: 14 } })}
                    </Box>
                )}
                <span style={{ textTransform: "none" }}>{props.data.shortenedLabel}</span>
            </Box>
        </components.MultiValue>
    );
};

const DataIdentifierActions: React.FC<DataIdentifierActionsProps> = ({
    tablesData = [],
    rowData = [],
    setRowData = () => {},
    maskingOptions = [],
    isExpanded = false,
    isDisabled = false,
    actionToggle = false,
    tagEventsToggle = false,
    setTagEventsToggle = () => {},
    taggingLevels = [],
    setTaggingLevels = () => {},
    setActionToggle = () => {},
    partialMaskingSDIOptions = [],
    setIsDirty = () => {}
}) => {
    const [searchMode, setSearchMode] = useState<Boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const searchInputRef = useRef(null);

    const [pinnedBottomRowData, setPinnedBottomRowData] = useState<TableData[]>(
        isDisabled ? [] : [{ name: "Add Identifier", action: { label: "", value: "" } }]
    );

    const filteredMaskingOptions = useMemo(() => maskingOptions.filter(option => option.value !== "NO_ACTION"), [
        maskingOptions
    ]);

    useEffect(() => {
        if (searchInputRef.current && searchMode) {
            searchInputRef.current.focus();
        }
    }, [searchInputRef, searchMode]);

    useEffect(() => {
        if (!searchMode) {
            setSearchQuery("");
        }
    }, [searchMode, setSearchQuery]);

    const handleReset = () => {
        !isDisabled && setRowData([...tablesData.filter(id => id?.action?.value !== "no-action")]);
    };

    const handleAdd = () => {
        !isDisabled && setPinnedBottomRowData([{ name: "Adding", action: { label: "", value: "" } }]);
    };

    const handleSearch = () => {
        setSearchMode(true);
    };

    const AddIcon = <SvgIcon component={Add} sx={styles.actionIcons} />;
    const ResetIcon = <SvgIcon component={Reset} sx={styles.actionIcons} />;
    const SearchIcon = <SvgIcon component={Search} sx={styles.actionIcons} onClick={handleSearch} />;

    return (
        <Box sx={styles.baseContainerStyles(isExpanded)}>
            <Box sx={styles.horizontalPadding(isExpanded)}>
                <StriimMessageBox
                    customCardStyles={styles.messageBoxHeader}
                    text={
                        <StriimTypography variant="caption3" color="greyscale.800">
                            Sentinel uses AI engines that may sometimes misclassify the information.
                        </StriimTypography>
                    }
                    type="NOTIFICATION"
                />
                <Grid container direction="row" wrap={false} gap={1} mt={1}>
                    <Grid item>
                        <StriimToggleButton
                            name={"ActionToggle"}
                            value={actionToggle}
                            checked={actionToggle}
                            disabled={isDisabled}
                            onChange={() => {
                                setActionToggle(actionToggle => !actionToggle);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <StriimTypography variant="body4" color="greyscale.800">
                            Detect and take actions on sensitive data in real-time anywhere in the stream
                        </StriimTypography>
                    </Grid>
                    <Grid item>
                        <StriimLink href={dictionary.get()["AI_INSIGHTS_SENTINEL"].href}>
                            <SvgIcon component={Documentation} sx={styles.docIcon} />
                        </StriimLink>
                    </Grid>
                </Grid>
            </Box>
            {actionToggle && (
                <>
                    <Box sx={styles.horizontalPadding(isExpanded)}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                            <StriimTypography variant="h5" color="primary.700">
                                TAG EVENTS
                            </StriimTypography>
                            <StriimLink href={dictionary.get()["AI_INSIGHTS_SENTINEL"].href}>
                                <SvgIcon component={Documentation} sx={styles.docIcon} />
                            </StriimLink>
                        </Box>
                        <StriimTypography variant="caption3" color="greyscale.700" fontFamily="Inter">
                            Sentinel can tag events with the number of sensitive data identifiers detected (default) and
                            where they are located (based on the importance level)
                        </StriimTypography>
                        <Box display="flex" alignItems="center" my={2}>
                            <StriimToggleButton
                                name={"TagEventsToggle"}
                                value={tagEventsToggle}
                                checked={tagEventsToggle}
                                disabled={isDisabled}
                                onChange={() => {
                                    setTagEventsToggle(tagEventsToggle => !tagEventsToggle);
                                }}
                            />
                            <StriimTypography variant="body4" color="greyscale.800" ml={1}>
                                Tag events identified as sensitive
                            </StriimTypography>
                        </Box>
                        {tagEventsToggle && (
                            <>
                                <StriimTypography variant="caption3" color="greyscale.700" fontFamily="Inter">
                                    Select the Importance of Identifiers to tag
                                </StriimTypography>
                                <StriimInputField
                                    dataTestId="importance-select"
                                    SelectProps={{
                                        options: taggingOptionsWithIcons,
                                        isMulti: true,
                                        components: { MultiValue: CustomShortenedLabelWithIcon }
                                    }}
                                    select
                                    onChange={value => {
                                        setTaggingLevels(value);
                                        setIsDirty(true);
                                    }}
                                    value={taggingLevels}
                                />
                            </>
                        )}
                    </Box>
                    <Divider my={2} />
                    <Box sx={{ ...styles.actionsBox, ...styles.horizontalPadding(isExpanded) }}>
                        <Grid
                            container
                            direction="row"
                            wrap={false}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            maxHeight={"24px"}
                        >
                            {searchMode && !isExpanded ? (
                                <StriimInputField
                                    placeholder="Search by Data Identifier"
                                    id="search-data-id-input"
                                    value={searchQuery}
                                    onChange={setSearchQuery}
                                    inputRef={searchInputRef}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search opacity={0.45} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Clear cursor="pointer" onClick={() => setSearchMode(false)} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            ) : (
                                <>
                                    <Grid item>
                                        <StriimTypography variant="h5" width={200} color="primary.700">
                                            POLICY ACTIONS
                                        </StriimTypography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        gap={1}
                                        direction={"row-reverse"}
                                        ml={2}
                                        alignItems="center"
                                        id="search-container"
                                    >
                                        {searchMode ? (
                                            <StriimInputField
                                                placeholder="Search by Data Identifier"
                                                id="search-data-id-input"
                                                value={searchQuery}
                                                inputRef={searchInputRef}
                                                onChange={setSearchQuery}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Search opacity={0.45} />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Clear
                                                                cursor="pointer"
                                                                onClick={() => setSearchMode(false)}
                                                            />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        ) : isDisabled ? (
                                            SearchIcon
                                        ) : (
                                            <>
                                                <Box sx={styles.iconContainer} onClick={handleReset}>
                                                    {ResetIcon}
                                                    {isExpanded && <StriimTypography> Reset</StriimTypography>}
                                                </Box>
                                                <Box sx={styles.iconContainer} onClick={handleAdd}>
                                                    {AddIcon}{" "}
                                                    {isExpanded && (
                                                        <StriimTypography> Data Identifier</StriimTypography>
                                                    )}
                                                </Box>{" "}
                                                {SearchIcon}
                                            </>
                                        )}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <span>
                            <StriimTypography variant="caption3" color="greyscale.700" fontFamily="Inter">
                                {
                                    "Refer to your organization's policies on handling sensitive data to define the actions below. Defaults are set based on your"
                                }
                                <StriimTypography
                                    variant="caption3"
                                    color="secondary.500"
                                    component="a"
                                    href="#"
                                    sx={{ textDecoration: "none" }}
                                >
                                    {` Sensitive Data Governance settings`}
                                </StriimTypography>
                            </StriimTypography>
                        </span>
                        <IdentifierActionsTable
                            tablesData={tablesData}
                            rowData={rowData}
                            setRowData={setRowData}
                            pinnedBottomRowData={pinnedBottomRowData}
                            setPinnedBottomRowData={setPinnedBottomRowData}
                            maskingOptions={filteredMaskingOptions}
                            isExpanded={isExpanded}
                            isSelectable={isExpanded && !isDisabled}
                            isSortable={isExpanded}
                            searchQuery={searchQuery}
                            isEditable={!isDisabled}
                            hideDoNothing
                            customHeight={isExpanded ? undefined : 280}
                            partialMaskingSDIOptions={partialMaskingSDIOptions}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default DataIdentifierActions;
