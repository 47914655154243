import BaseTable from "./basetable";
import formatter from "app/components/monitor/common/valueFormatter";

var QueriesTable = {};

QueriesTable.View = BaseTable.View.extend({
    columns: [
        {
            label: "Name",
            srcfield: "label",
        },
        {
            label: "CPU",
            srcfield: "cpu-per-node",
            dataName: "cpu",
        },
        {
            label: "Nodes",
            srcfield: "nodes",
        },
        {
            label: "Events in",
            srcfield: "eventsIn",
        },
        {
            label: "Events out",
            srcfield: "eventsOut",
        },
        {
            label: "Queries/s",
            srcfield: "queriesPerSecond",
        },
        {
            label: "Queries/s",
            srcfield: "queriesPerSecondGraph",
            view: BaseTable.CellItemSparklineView,
        },
    ],

    dataMapper: function (server) {
        return {
            label: server["full-name"],
            cpu: formatter.toPercentageString(server["most-recent-data"]["cpu-per-node"]),
            nodes: server["num-servers"],
            eventsIn: server["most-recent-data"]["input"],
            eventsOut: server["most-recent-data"]["output"],
            queriesPerSecond: formatter.formatRate(server["most-recent-data"]["rate"]),
            queriesPerSecondGraph: server["time-series-data"]["rate"],
        };
    },
});

export default QueriesTable;
