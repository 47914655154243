import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Box, Grid } from "@mui/material";
import HighCharts from "highcharts/react";
import HighchartsReact from "highcharts-react-official";
import statusManagement from "../../../../status-management";
import dateLib from "../../../../../core/utils/date-utils";
import { StriimTypography } from "@striim/striim-ui-v2";
import GraphLegend from "../graph-legend/graph-legend";
import { Store } from "../../table-progress-types";
import numeral from "numeral";

const flowStriimline = {
    nodeConnectionBorder: "#BCC9D6", // @greyscale_300
    nodeConnectionBorderActive: "#8091A3", // @greyscale_600 (.jsplumb-connector)
    lineOutColor: "#2E8071", // @flow_in_color
    lineInColor: "#346CC2", // @flow_out_color
    lineCrashColor: "#DB2A4A" // @critical_500
};
// https://api.highcharts.com/highcharts/tooltip
const tooltipStriimlineHighcharts = {
    backgroundColor: "#48525C",
    style: {
        color: "#fff",
        cursor: "auto"
    },
    borderWidth: 0,
    borderRadius: 8,
    shadow: false
};

let graphDataDefault = {
    inData: [],
    outData: []
};

export type graphColors = {
    inColor: string;
    outColor: string;
    outDashStyle: string;
};
export const getGraphColors = (appStatus: string): graphColors => {
    let appCrash = statusManagement.isApplicationCrash(appStatus);
    let inColor = appCrash ? flowStriimline.lineCrashColor : flowStriimline.lineInColor;
    let outColor = appCrash ? flowStriimline.lineCrashColor : flowStriimline.lineOutColor;
    let outDashStyle = appCrash ? "dash" : "solid";
    return {
        inColor,
        outColor,
        outDashStyle
    };
};
type AppRateGraphProps = {
    store: Store;
    appStatus: string;
};

const AppRateGraph = observer(({ store, appStatus }: AppRateGraphProps) => {
    const stats = store.stats;
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [chartOptions, setChartOptions] = useState(null);
    const [graphData, setGraphData] = useState(graphDataDefault);

    useEffect(() => {
        const uuid = store?.appModel?.["uuid"];
        let appStats = stats?.[uuid];
        if (appStats && appStats["most-recent-data"]) {
            let inRate = appStats["most-recent-data"]["source-rate"];
            let outRate = appStats["most-recent-data"]["target-rate"];
            let time = appStats["most-recent-data"]["timestamp"];
            //To Do get status from appSynchronizer
            const graphLineStyles = getGraphColors(appStatus);
            const inTime = time;
            const outTime = time + 200;
            outRate = outRate || 0;
            inRate = inRate || 0;
            const prevValue = [inTime - 1000, 0];
            if (chartComponentRef.current.chart) {
                let inRateNextPoint = [inTime, inRate];
                let outRateNextPoint = [outTime, outRate];
                let graphDataCloned = { ...graphData };
                graphDataCloned.inData.push(inRateNextPoint);
                graphDataCloned.outData.push(outRateNextPoint);
                setGraphData(graphDataCloned);
                setChartOptions({
                    series: [
                        {
                            data: graphDataCloned.inData,
                            color: graphLineStyles.inColor
                        },
                        {
                            data: graphDataCloned.outData,
                            color: graphLineStyles.outColor,
                            dashStyle: graphLineStyles.outDashStyle
                        }
                    ]
                });

                return;
            }
            const options = {
                chart: {
                    backgroundColor: "transparent",
                    animation: false
                },
                title: {
                    text: ""
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    labels: {
                        enabled: true,
                        style: {
                            color: "#6E7D8C"
                        },
                        formatter: function() {
                            return dateLib(this.value).format("hh:mm:ss a ");
                        }
                    },
                    tickColor: "#6E7D8C",
                    lineColor: "#6E7D8C"
                },
                yAxis: {
                    maxPadding: 0,
                    minPadding: 0,
                    gridLineWidth: 1,
                    endOnTick: false,
                    lineColor: "#6E7D8C",
                    tickColor: "#6E7D8C",
                    min: 0,
                    labels: {
                        enabled: true,
                        style: {
                            color: "#6E7D8C"
                        }
                    },
                    title: {
                        text: "",
                        style: {
                            fontWeight: 900
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    pointFormat: "",
                    headerFormat: "",
                    pointFormatter: function() {
                        return `${this.series.name}: ${numeral(this.y ?? 0).format(
                            "0.[00] a"
                        )} records/s <br /> at ${dateLib(this.x).format("hh:mm:ss a ")}`;
                    },
                    ...tooltipStriimlineHighcharts
                },
                plotOptions: {
                    series: {
                        enableMouseTracking: true,
                        lineWidth: 4,
                        shadow: false,
                        states: {
                            hover: {
                                lineWidth: 6
                            }
                        },
                        marker: {
                            enabled: false,
                            radius: 0,
                            states: {
                                hover: {
                                    radius: 2
                                }
                            }
                        }
                    }
                },
                series: [
                    {
                        type: "line",
                        color: graphLineStyles.inColor,
                        name: "In",
                        data: [prevValue, [inTime, inRate]]
                    },
                    {
                        type: "line",
                        color: graphLineStyles.outColor,
                        dashStyle: graphLineStyles.outDashStyle,
                        name: "Out",
                        data: [prevValue, [outTime, outRate]]
                    }
                ]
            };
            graphData.inData = [prevValue, [inTime, inRate]];
            graphData.outData = [prevValue, [outTime, outRate]];
            setChartOptions(options);
        }
    }, [stats, appStatus]);

    return (
        <div>
            <Grid container justifyContent="space-between">
                <Grid>
                    <StriimTypography variant="h3" sx={{ color: "primary.700" }}>
                        App Rate
                    </StriimTypography>
                </Grid>
                <Grid item>
                    <GraphLegend appStatus={appStatus} />
                </Grid>
            </Grid>

            <HighchartsReact
                containerProps={{ style: { height: "200px" } }}
                ref={chartComponentRef}
                highcharts={HighCharts}
                options={chartOptions}
            />
        </div>
    );
});

export default AppRateGraph;
