import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing, palette, typography, shadows, breakpoints }) => ({
    cardRoot: {
        minHeight: 160,
        flexBasis: "48%",
        cursor: "pointer",
        margin: "1%",
        "& .appTileCheckbox": {
            marginTop: spacing(-1),
            marginLeft: spacing(-1)
        },
        [breakpoints.up(1040)]: {
            flexBasis: "31.3%",
            minWidth: 310
        },
        [breakpoints.up(1660)]: {
            flexBasis: "23%",
            minWidth: "unset"
        }
    },
    cardHeaderRoot: {
        flexDirection: "column",
        alignItems: "flex-start",
        padding: spacing(1.5, 2, 0, 2)
    },
    cardHeaderTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    cardHeaderContent: {
        width: "100%"
    },
    cardHeaderAction: {
        margin: 0
    },
    cardHeaderSubHeader: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    cardHeaderAvatar: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    cardContentRoot: {
        padding: spacing(0, 2, 2, 2)
    },
    cardAppInfo: {
        display: "flex",
        alignItems: "flex-start",
        marginTop: spacing(1),
        flexDirection: "column"
    },
    cardAppInfoSeparator: {
        width: 1,
        height: 14,
        backgroundColor: palette.gray5,
        margin: `0 ${spacing(1)}px`
    },
    cardContentSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#E1E1E1"
    },
    cardSourcesTargetList: {
        marginTop: spacing(2),
        marginLeft: spacing(0.25)
    },
    cardActionsRoot: {
        padding: spacing(2),
        justifyContent: "space-between"
    },
    cardActionsButton: {
        padding: spacing(0.5, 1)
    },
    appTile: {
        borderTop: "none !important",
        "& .appTileCheckbox": {
            marginLeft: spacing(-1)
        },
        "&:hover": {
            boxShadow: shadows[8]
        }
    },
    //~~~~~~~~~~~~~~~~~~~~~~~~

    appNames: {
        flexBasis: "25.33%",
        maxWidth: "25.33%",
        flexDirection: "column",
        [breakpoints.down("sm")]: {
            flexBasis: "20%",
            maxWidth: "20%"
        },
        [breakpoints.between("md", 1660)]: {
            flexBasis: "22%",
            maxWidth: "22%"
        }
    },
    appValues: {
        [breakpoints.down(1660)]: {
            flexBasis: "13.66%",
            maxWidth: "13.66%"
        }
    },
    appStatus: {
        maxWidth: "16.66%",
        flexBasis: "16.66%"
    },
    rowRoot: {
        alignItems: "center",
        backgroundColor: palette.paper.background,
        border: `1px solid ${palette.paper.border}`,
        borderRadius: 8,
        minHeight: 80,
        padding: spacing(2),
        margin: spacing(2),
        flexWrap: "wrap",
        cursor: "pointer",
        "&:not(:last-child)": {
            marginBottom: 0
        },
        overflow: "hidden",
        [breakpoints.down(1000)]: {
            minWidth: 1000
        }
    },
    gridSourceTargetList: {
        flex: "auto"
    },
    gridAppActionButton: {
        width: 40
    },
    //~~~~~~~~~~~~~~~~~~~~~~~~
    tooltipWrapper: {
        // "& > div": { overflow: "hidden" },
        boxShadow: shadows[24],
        zIndex: "99999"
    },
    moveToGroupActionContainer: {
        "& > *:first-child": {
            marginRight: spacing(2)
        }
    },
    //~~~~~~~~~~~~~~~~~~~~~~~~
    appInfo: {
        ...typography.body1
    },
    appInfoNumbers: {
        fontWeight: 600
    },
    //~~~~~~~~~~~~~~~~~~~~~~~~
    title: {
        [breakpoints.down("xs")]: {
            marginRight: spacing(0.5)
        },
        ...typography.body2,
        color: palette.text.primary,
        fontSize: 14,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "100%"
    },
    subTitle: {
        ...typography.body2,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "100%"
    },
    dialogContent: {
        overflowWrap: "anywhere"
    },
    //~~~~~~~~~~~~~~~~~~~~~~~~
    icons: {
        color: palette.gray3,
        fontSize: 24
    },
    selection: {
        padding: 2,
        color: "white"
    },
    learnLink: {
        height: "40px",
        display: "inline-block",
        marginLeft: `${spacing(1)}px`,
        lineHeight: "40px"
    },
    appActionDisabled: {
        pointerEvents: "none"
    },
    checkboxContainer: {
        display: "flex"
    },
    divider: { width: "1px", height: spacing(2), backgroundColor: "#90999D", marginRight: spacing(2) }
}));
