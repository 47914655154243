export default {
    appCreatedTitle: "Application created successfully.",
    importFailed: "Import Failed.",
    importSuccess: "Import Success.",
    namespaceInTqlTitle: "Namespace already defined in the tql file",
    nameYourApp: "Name Your App",
    nameYourAppGroup: "Name Your Automated Pipeline",
    noApplicationWarning:
        "This tql file does not contain an application. All components in this file will be created, but not accessible through apps.",
    oops: "Oops!",
    pleaseDrop: ". Please delete it to create an application with the same name or choose a different namespace.",
    alreadyExists: " already exists",
    topicAlreadyExists: "could not create topic ",

    appCreated: function(name) {
        return `Application ${name} created successfully.`;
    },
    errorWhileParsing: function(filename) {
        return "There was an error parsing " + filename + ". Please make sure the file contains valid TQL.";
    },
    namespaceInTql: function(namespace) {
        return (
            "This file contains a namespace definition and the application will be created in namespace '" +
            namespace +
            "'"
        );
    },
    fileImportSuccess: function(filename) {
        return `Imported ${filename} successfully.`;
    },
    onlyFilesWithExtensions: function(allowedFilesToImport) {
        return "Only files with " + allowedFilesToImport.join(", ") + " extension are supported.";
    },
    pleaseDropExistingApp: function(name) {
        return "APPLICATION " + name + this.alreadyExists + this.pleaseDrop;
    },
    reading: function(filename) {
        return "Reading " + filename + "...";
    }
};
