import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { StriimTypography } from "@striim/striim-ui";
import App from "app";
import { useReactHeader } from "src/hooks/useReactHeader";
import { SECTIONS } from "./constants";
import { FilesPage } from "./files";
import { VaultsPage } from "./vaults";
import { PropertySetsPage } from "./property-sets";
import { PropertyVariablesPage } from "./property-variables";
import EmbeddingGeneratorPage from "./embedding-generator/embedding-generator-page";
import MetadataManager from "./metadata-manager/metadata-manager";
import { useStyles } from "./manage-striim-page.styles";
import clsx from "clsx";

export const MetadataManagerRegionName = "manage-striim-section-container";

const getComponent = section => {
    switch (section) {
        case SECTIONS.files:
            return {
                withTitle: true,
                component: <FilesPage />
            };
        case SECTIONS.vaults:
            return {
                withTitle: true,
                component: <VaultsPage />
            };
        case SECTIONS.propertysets:
            return {
                withTitle: true,
                component: <PropertySetsPage />
            };
        case SECTIONS.propertyvariables:
            return {
                withTitle: true,
                component: <PropertyVariablesPage />
            };
        case SECTIONS.metadatamanager:
            return {
                component: <MetadataManager />
            };
        case SECTIONS.embeddinggenerator:
            return {
                withTitle: true,
                component: <EmbeddingGeneratorPage />
            };
        default:
            console.error("Wrong component in ManageStriimPageWrapper");
            return null;
    }
};

const ManageStriimPageWrapper = () => {
    useReactHeader({ title: "Manage Striim" });
    const { section } = useParams();
    const classes = useStyles();

    const { component, withTitle } = useMemo(() => getComponent(section), [section]);

    useEffect(() => {
        if (section !== "metadata-manager") {
            const region = App.getRegion(MetadataManagerRegionName);
            if (region) {
                region.reset();
            }
        }
    }, [section]);

    return (
        <Grid container className={clsx(classes.gridContainer, "manage-striim-container-height")} direction="column">
            {withTitle ? (
                <Grid item xs>
                    <StriimTypography variant="h1">Manage Striim</StriimTypography>
                </Grid>
            ) : null}
            <Grid xs={12} item id={MetadataManagerRegionName}>
                {component}
            </Grid>
        </Grid>
    );
};

export default ManageStriimPageWrapper;
