import Backbone from "backbone";
import { View } from "../database-reader/base-database-reader";
import { DB_PROVIDERS } from "../database-reader/configs/db-providers";
const module = {};
class PaymoReaderView extends View {
    setup() {
        this.label = "Paymo";
        this.model.get("connectionParams").set("type", DB_PROVIDERS.PAYMO.id);
        this.model.set("isCDC", false);
        this.model.set("hasAutoSchemaConversion", window.location.href.indexOf("-to-create-schema") !== -1);
    }
}

module.View = PaymoReaderView;
module.vent = new Backbone.Model();
export default module;