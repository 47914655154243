import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing }) => ({
    addonTile: {
        borderRadius: 8,
        backgroundColor: palette.common.white,
        width: 380,
        border: `1px solid ${palette.paper.border}`,
        display: "flex",
        flexDirection: "column"
    },
    largeIcon: {
        width: 20,
        height: 20
    },
    smallIcon: {
        width: 20,
        height: 20,
        marginRight: spacing(2)
    },
    addonHeader: {
        display: "flex",
        padding: 16,
        flexDirection: "row",
        alignItems: "center",
        gap: 16,
        alignSelf: "stretch",
        borderBottom: `1px solid ${palette.paper.border}`,
    },
    addonBodyWrapper: {
        borderBottom: `1px solid ${palette.paper.border}`,
        flex: 1
    },
    addonBody: {
        padding: spacing(2),
    },
    addonPricing: {
        marginTop: spacing(2),
        display: "flex",
    },
    addonStatusWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: spacing(2),
    },
    addonStatus: {
        display: "flex",
    },
    success: {
        width: 20,
        height: 20,
        marginRight: spacing(1)
    },
    learnMoreButton: {
        cursor: "pointer",
        color: palette.text.secondary
    },
    cancelButton: {
        color: palette.primary.main,
        padding: "5px 0",
    },
    added: {
        color: "#2AAC5E", // Success 500
        fontSize: "14px",
        lineHeight: "21px"
    }
}));
