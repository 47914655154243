import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing }) => ({
    wrapper: {
        height: `calc(100vh - 160px)`,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    emptyDashboardImage: {
        width: 320,
        margin: spacing(3, 0)
    },
    emptyDashboardMsg: {
        marginLeft: spacing(6),
        marginBottom: spacing(5)
    }
}));
