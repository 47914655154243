import $ from "jquery";
import _ from "underscore";
import api from "core/api/api";
import TqlDataSource from "./tql-autocomplete-datasource";
import BaseControl from "./../base-control";
import template from "./tql.html";
import CodeMirror from "cm/lib/codemirror";
import "cm/mode/sql/sql";
import "cm/addon/edit/matchbrackets";
import "cm/addon/edit/closebrackets";
import "cm/addon/scroll/simplescrollbars";
import "cm/addon/comment/comment";
import "cm/addon/comment/continuecomment";
import "cm/addon/hint/show-hint";
import "cm/addon/hint/sql-hint";
import securityService from "core/services/securityService/securityService";
import {segmentationTooltip} from "app/components/common/segmentationComponents";
import {segmentationVariants} from "src/modules/common/segmentation/segmentation.consts";
import {
    addonFeaturesKeys
} from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import toggleFullScreen from "./../../../../flow/designer/toggleFullScreen";

CodeMirror.extendMode("css", {
    commentStart: "/*",
    commentEnd: "*/",
    newlineAfterToken: function (type, content) {
        return /^[;{}]$/.test(content);
    }
});

CodeMirror.extendMode("javascript", {
    commentStart: "/*",
    commentEnd: "*/",
    newlineAfterToken: function (type, content, textAfter, state) {
        if (this.jsonMode) {
            return /^[\[,{]$/.test(content) || /^}/.test(textAfter);
        } else {
            if (content === ";" && state.lexical && state.lexical.type === ")") {
                return false;
            }
            return /^[;{}]$/.test(content) && !/^;/.test(textAfter);
        }
    }
});

CodeMirror.extendMode("xml", {
    commentStart: "<!--",
    commentEnd: "-->",
    newlineAfterToken: function (type, content, textAfter) {
        return (type === "tag" && />$/.test(content)) || /^</.test(textAfter);
    }
});

// Comment/uncomment the specified range
CodeMirror.defineExtension("commentRange", function (isComment, from, to) {
    var cm = this,
        curMode = CodeMirror.innerMode(cm.getMode(), cm.getTokenAt(from).state).mode;
    cm.operation(function () {
        if (isComment) {
            // Comment range
            cm.replaceRange(curMode.commentEnd, to);
            cm.replaceRange(curMode.commentStart, from);
            if (from.line === to.line && from.ch === to.ch) {
                cm.setCursor(from.line, from.ch + curMode.commentStart.length);
            }
        } else {
            // Uncomment range
            var selText = cm.getRange(from, to);
            var startIndex = selText.indexOf(curMode.commentStart);
            var endIndex = selText.lastIndexOf(curMode.commentEnd);
            if (startIndex > -1 && endIndex > -1 && endIndex > startIndex) {
                // Take string till comment start
                selText =
                    selText.substr(0, startIndex) +
                    // From comment start till comment end
                    selText.substring(startIndex + curMode.commentStart.length, endIndex) +
                    // From comment end till string end
                    selText.substr(endIndex + curMode.commentEnd.length);
            }
            cm.replaceRange(selText, from, to);
        }
    });
});

// Applies automatic mode-aware indentation to the specified range
CodeMirror.defineExtension("autoIndentRange", function (from, to) {
    var cmInstance = this;
    this.operation(function () {
        for (var i = from.line; i <= to.line; i++) {
            cmInstance.indentLine(i, "smart");
        }
    });
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: "SELECT \
                    \
                    FROM",
        hints: [
            `Press Ctrl-space for a pop-up with functions and cache, stream and WActionStore names.
             <span style="display: none;" class="license-info">
                Machine learning functions are an Enterprise plus feature.
             </span>`
        ],
        showHeader: true,
        fullScreen: false
    }
});

const rootClassWithHeader = "with-header";
const rootClassWithInfo = "with-info";

var View = BaseControl.View.extend({
    className: "tql-editor",
    template: _.template(template),
    ui: {
        close: ".close",
        info: ".info",
        toggleHelp: ".toggle-help",
        toggleFullScreen: ".toggle-full-screen",
        licenseInfo: ".license-info"
    },
    events: {
        "click @ui.toggleFullScreen": "toggleFullScreen",
        "click @ui.toggleHelp": "onToggleHelp",
        "click @ui.close": "onCloseInfo",
        click: "activate"
    },

    onToggleHelp: function () {
        this.ui.info.show();
        this.$el.addClass(rootClassWithInfo);
        this.ui.toggleHelp.hide();
    },

    onCloseInfo: function () {
        this.ui.info.hide();
        this.$el.removeClass(rootClassWithInfo);
        this.ui.toggleHelp.show();
    },

    activate: function (e) {
        e.stopPropagation(true);
    },

    initialize: function (options) {
        // set model based on options
        if (typeof options.showHeader !== "undefined") {
            this.model.showHeader = options.showHeader;
        }
    },

    setDefault: function () {
        if (this.model.fullScreen) {
            toggleFullScreen(this);
        }
    },

    toggleFullScreen: function (e) {
        toggleFullScreen(this);
        e.stopPropagation(true);
    },

    getViewValue: function () {
        return $.trim(this.cm.getValue());
    },

    setViewValue: function () {
        if (this.cm) {
            this.cm.setValue($.trim(this.getValue()));
        }
    },

    setViewEnabled: function () {
        BaseControl.View.prototype.setViewEnabled.apply(this);

        if (this.cm && this.ui.info) {
            var enabled = this.getEnabled() && !this.readOnly;
            this.cm.setOption("readOnly", !enabled);

            // the this.ui elements can be not initiaized
            // and contains keys instead of jQuery objects
            if (typeof this.ui.info === "object") {
                this.ui.info.toggle(enabled);
                if (enabled) {
                    this.$el.addClass(rootClassWithInfo);
                } else {
                    this.$el.removeClass(rootClassWithInfo);
                }
            }
            if (typeof this.ui.toggleHelp === "object") {
                this.ui.toggleHelp.hide();
            }

            if (enabled) {
                this.$(".CodeMirror-scroll").removeClass("disabled");
            } else {
                this.$(".CodeMirror-scroll").addClass("disabled");
            }
        }
    },

    onShow: function () {
        this.ui.toggleHelp.hide();

        if (this.model.fullScreen) {
            this.$el.addClass("full-screen");
            this.cm.setSize("100%", null);
            this.cm.refresh();
        }

        if (this.model.showHeader === true) {
            this.$el.addClass(rootClassWithHeader);
        }
    },

    onRender: function () {
        var _this = this;

        var area = this.$("textarea")[0];
        this.cm = CodeMirror.fromTextArea(area, {
            mode: "text/x-tql",
            matchBrackets: true,
            autoCloseBrackets: true,
            indentWithTabs: true,
            indentAuto: true,
            smartIndent: true,
            continueComments: true,
            lineWrapping: true,
            scrollbarStyle: "overlay",
            extraKeys: {
                "Cmd-/": "toggleComment",
                "Ctrl-/": "toggleComment",
                "Ctrl-Space": "autocomplete",
                '"."': function () {
                    setTimeout(function () {
                        _this.cm.execCommand("autocomplete");
                    }, 0);
                    return CodeMirror.Pass;
                }
            },
            hint: CodeMirror.hint.sql,
            readOnly: this.readOnly
        });

        this.cm.on("change", function () {
            _this.setValueFromView();
        });

        this.cm.setCursor(this.cm.lineCount(), 0);
        setTimeout(function () {
            _this.cm.refresh();
        }, 1);

        TqlDataSource.get().then(function (dataSource) {
            _this.cm.options.hintOptions = {
                tables: dataSource ? dataSource : {},
                ns: api.getCurrentNamespace()
            };
        });

        if (!securityService.isSegmentationFeatureEnabled(addonFeaturesKeys.MLFUNCTIONS)) {
            this.ui.licenseInfo.show();
            this.ui.licenseInfo.tooltipster(
                segmentationTooltip(
                    segmentationVariants.enterprisePlus,
                    addonFeaturesKeys.MLFUNCTIONS,
                    this.ui.licenseInfo,
                    (origin, tooltip) => {
                        var tooltipOffset = tooltip.offset();
                        tooltip.offset({
                            top: tooltipOffset.top,
                            left: tooltipOffset.left - 50
                        });
                    }
                )
            );
        }

        BaseControl.View.prototype.onRender.apply(this, arguments);
    }
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View
});
