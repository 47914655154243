import NestedTypes from "backbone.nestedtypes";

export default NestedTypes.Model.extend({
    defaults: {
        id: String,
        name: String,
        group: String, // input, output, processing, enrichment
        iconClass: String,
        componentType: String, //source, target, window...
        isVisible: true,
        isEnabled: false,
        tooltip: String,
        version: String
    }
});
