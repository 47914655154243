import React from "react";
import { Navigate } from "react-router-dom";
import AI_ROUTES from "../../ai-insights/routes.json";
import licenseService from "core/services/metaStoreService/license-service";
import { isUserAdmin } from "../../../../core/user-management/update.control";

export default function Guardian({ children }) {
    const isAdmin = isUserAdmin();

    if (!isAdmin || licenseService?.isAIInsightsDisabled) {
        return <Navigate to={{ pathname: AI_ROUTES.unauthorized }} />;
    }

    return <>{children}</>;
}
