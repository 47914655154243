import React from "react";
import { StriimTypography, StriimLink } from "@striim/striim-ui";
import Dictionary from "app/components/common/helpable/online-help-dictionary";

export default function PropertySetDocumentationLink() {
    return (
        <>
            <StriimTypography variant="body4" color="greyscale.700" sx={{ maxWidth: "400px", textAlign: "center" }}>
                You can create and use property sets for applications, data streams and LDAP users.
            </StriimTypography>
            <div>
                <StriimLink
                    href={Dictionary.get()["MANAGE_STRIIM_PROPERTY_SETS"].href}
                    target="_blank"
                >
                    View Documentation
                </StriimLink>
            </div>
        </>
    );
}
