import React from "react";
import { FIELD_TYPES } from "@striim/striim-ui";
import {
    sourceFormConfig,
    additionalSelectInputProps
} from "app/components/appwizard/component-wizards/wizards/source/database-reader/configs/source-form-info.js";
import { sxStyles } from "./components/target-form-components/target-form-components.styles";
import { FieldLabel, FieldValue, ToggleFieldLabel } from "./components/target-form-components/target-form-components";
import { StriimTypography, StriimControlLabel, StriimRadio } from "@striim/striim-ui-v2";
import { ConfirmField, TrackingTableDescription } from "./components/advanced-settings/advanced-settings-helper";
import { Box } from "@mui/material";
import * as Yup from "yup";

const ADVANCED_SETTINGS_SEPARATOR = (title = "Advanced Settings") => [
    {
        name: "Separator line",
        type: FIELD_TYPES.COMPONENT,
        component: <Box sx={sxStyles.lineSeparator}></Box>
    },
    {
        name: "Advanced Settings Title",
        type: FIELD_TYPES.COMPONENT,
        component: (
            <StriimTypography variant="h3" color="primary.700">
                {title}
            </StriimTypography>
        )
    }
];
const RECOVERY_CHECK_POINT_TABLE = [
    ...ADVANCED_SETTINGS_SEPARATOR("Recovery settings"),
    {
        name: "CheckPointTable",
        label: "Checkpoint Table Name",
        type: FIELD_TYPES.STRING,
        addToModel: true,
        required: true,
        description:
            'Specify the name in the format "DatabaseName.TableName". Ensure that the database already exists. Striim will automatically create the checkpoint table with this name in the existing database.'
    }
];

const HEADER_FIELDS = adapter => {
    return {
        StreamingUpload: {
            name: "Streaming Upload Title",
            type: FIELD_TYPES.COMPONENT,
            component: (
                <FieldLabel>Which mode would you like to use to write continuous changes to {adapter}?</FieldLabel>
            )
        }
    };
};

const COMMON_ADVANCED_FIELDS = (target = "target") => {
    return {
        // DEV-48843
        ParallelThreadsDWH: {
            name: "ParallelThreads",
            type: FIELD_TYPES.SELECT,
            label: <FieldLabel>Select the number of parallel threads to use during initial load</FieldLabel>,
            defaultValue: { label: "4 (Default)", value: "4" },
            visibility: "'{{properties.source}}'!=='SALESFORCEREADER'",
            options: [
                { label: "1 (Do not use parallelism)", value: "" },
                { label: "2", value: "2" },
                { label: "3", value: "3" },
                { label: "4 (Default)", value: "4" },
                { label: "5", value: "5" },
                { label: "6", value: "6" },
                { label: "7", value: "7" },
                { label: "8", value: "8" },
                { label: "9", value: "9" },
                { label: "10", value: "10" }
            ],
            required: true,
            addToModel: true,
            additionalInputProps: additionalSelectInputProps
        },
        ParallelThreads: {
            name: "ParallelThreads",
            type: FIELD_TYPES.SELECT,
            label: <FieldLabel>Select the number of parallel threads to use during initial load</FieldLabel>,
            defaultValue: { label: "4 (Default)", value: "4" },
            options: [
                { label: "1 (Do not use parallelism)", value: "" },
                { label: "2", value: "2" },
                { label: "3", value: "3" },
                { label: "4 (Default)", value: "4" },
                { label: "5", value: "5" },
                { label: "6", value: "6" },
                { label: "7", value: "7" },
                { label: "8", value: "8" },
                { label: "9", value: "9" },
                { label: "10", value: "10" }
            ],
            required: true,
            addToModel: true,
            additionalInputProps: additionalSelectInputProps
        },
        CDDLAction: [
            {
                name: "CDDLAction",
                type: FIELD_TYPES.SELECT,
                label: (
                    <FieldLabel>How do you want Striim to respond to schema changes in the source database?</FieldLabel>
                ),
                defaultValue: {
                    label: "Automatically propagate schema changes to the target (Default)",
                    value: "Process"
                },
                visibility: `!'["SQLSERVER","MSSQLREADER", "MARIADB", "MARIADBREADER"]'.includes('{{properties.source}}') || '{{properties.cdcSource}}' === 'MSJET'`,
                options: [
                    {
                        label: "Automatically propagate schema changes to the target (Default)",
                        value: "Process"
                    },
                    {
                        label: "Do not propagate schema changes to the target and keep the pipeline running",
                        value: "Ignore"
                    },
                    {
                        label: "Pause the pipeline when the schema changes",
                        value: "Halt"
                    }
                ],
                additionalInputProps: additionalSelectInputProps
            },
            {
                name: "dictionaryDump",
                type: FIELD_TYPES.COMPONENT,
                label: "Confirm dictionary file",
                component: props => {
                    return (
                        <ConfirmField
                            title="Confirm the creation of the following in the source schema"
                            label="Dictionary file"
                            description="Required to capture schema changes. "
                            linkID="ADVANCED_SETTINGS_ORACLE_PROCESS"
                            fieldName="dictionaryDump"
                            formRef={props.formRef}
                        />
                    );
                },
                visibility: `'["ORACLE","ORACLEREADER"]'.includes('{{properties.source}}') && '["Process","Halt"]'.includes('{{properties.CDDLAction}}')`,
                required: true
            },
            {
                name: "OracleQuiescemarkerTable",
                type: FIELD_TYPES.COMPONENT,
                label: "Confirm Quiescemarker table",
                component: props => {
                    return (
                        <ConfirmField
                            label="Quiescemarker table in the source schema"
                            description="Required to pause operations when there are schema changes. "
                            linkID="ADVANCED_SETTINGS_ORACLE_HALT"
                            fieldName="OracleQuiescemarkerTable"
                            formRef={props.formRef}
                        />
                    );
                },
                visibility: `'["ORACLE","ORACLEREADER"]'.includes('{{properties.source}}') && '{{properties.CDDLAction}}' === 'Halt'`,
                required: true
            },
            {
                name: "MSJetQuiescemarkerTable",
                type: FIELD_TYPES.COMPONENT,
                label: "Confirm Quiescemarker table",
                component: props => {
                    return (
                        <ConfirmField
                            title="Confirm the creation of the quiescemarker table in the default user schema"
                            label="Quiescemarker table in the default user schema"
                            description="Required to pause operations when there are schema changes. "
                            linkID="ADVANCED_SETTINGS_MSJET_HALT"
                            fieldName="MSJetQuiescemarkerTable"
                            formRef={props.formRef}
                        />
                    );
                },
                visibility: `('{{properties.source}}' === 'MSJET' || '{{properties.cdcSource}}' === 'MSJET') && '{{properties.CDDLAction}}' === 'Halt'`,
                required: true
            }
        ],
        Mode: {
            name: "Mode",
            type: FIELD_TYPES.RADIO,
            label: <FieldLabel>How would you like to write continuous changes to {target}?</FieldLabel>,
            defaultValue: "MERGE",
            options: [
                {
                    label: <FieldValue>Write continuous changes directly (default)</FieldValue>,
                    value: "MERGE"
                },
                {
                    label: <FieldValue>Write continuous changes as audit records</FieldValue>,
                    value: "APPENDONLY"
                }
            ],
            addToModel: true
        },
        CDDLTrackingTable: {
            name: "CDDLTrackingTable",
            type: FIELD_TYPES.STRING,
            visibility: `'["POSTGRES","POSTGRESQLREADER"]'.includes('{{properties.source}}') && '["Process", "Halt"]'.includes('{{properties.CDDLAction}}')`,
            required: true,
            addToModel: false,
            description: TrackingTableDescription,
            validationSchema: Yup.string()
                .trim()
                .matches(/^\w+\.\w+$/, "Please specify the table name with only two parts")
        }
    };
};
const SHOW_ON_CP_TRUE = "'{{properties.useConnectionProfile}}'==='true'";
const SHOW_ON_CP_FALSE = "'{{properties.useConnectionProfile}}'==='false'";

const getConnectionProfileFields = (adapter: string) => {
    return [
        {
            type: FIELD_TYPES.BOOLEAN,
            name: "useConnectionProfile",
            defaultValue: false,
            addToModel: true
        },
        {
            required: true,
            type: "java.lang.String",
            defaultValue: "",
            uiconfig: '{"type":"CONNECTIONPROFILE"}',
            visibility: SHOW_ON_CP_TRUE,
            supportingConnectionProfiles: `[${adapter}]`,
            name: "connectionProfileName",
            description:
                adapter === "Snowflake"
                    ? "For Password Authentication Type, Snowpipe Streaming is not applicable"
                    : null,
            addToModel: true
        }
    ];
};

const TARGET_FORM_INFO = {
    SNOWFLAKEWRITER: {
        form_fields: [
            ...getConnectionProfileFields("Snowflake"),
            {
                required: true,
                type: FIELD_TYPES.SELECT,
                name: "authenticationType",
                defaultValue: "Password",
                visibility: SHOW_ON_CP_FALSE,
                options: [
                    { value: "ManualOAuth", label: "Manual OAuth" },
                    { value: "Password", label: "Password" },
                    { value: "KeyPair", label: "KeyPair" }
                ],
                description: "For Password Authentication Type, Snowpipe Streaming is not applicable",
                addToModel: true,
                additionalInputProps: additionalSelectInputProps
            },
            {
                name: "ClientID",
                type: FIELD_TYPES.STRING,
                required: true,
                addToModel: true,
                visibility: "'{{properties.authenticationType}}' === 'ManualOAuth' && " + SHOW_ON_CP_FALSE
            },
            {
                name: "ClientSecret",
                type: FIELD_TYPES.PASSWORD,
                required: true,
                addToModel: true,
                visibility: "'{{properties.authenticationType}}' === 'ManualOAuth' && " + SHOW_ON_CP_FALSE
            },
            {
                name: "RefreshToken",
                type: FIELD_TYPES.PASSWORD,
                required: true,
                addToModel: true,
                visibility: "'{{properties.authenticationType}}' === 'ManualOAuth' && " + SHOW_ON_CP_FALSE
            },
            {
                name: "privateKey",
                type: FIELD_TYPES.PASSWORD,
                required: true,
                addToModel: true,
                visibility: "'{{properties.authenticationType}}' === 'KeyPair' && " + SHOW_ON_CP_FALSE
            },
            {
                name: "privateKeyPassphrase",
                type: FIELD_TYPES.PASSWORD,
                required: false,
                addToModel: true,
                visibility: "'{{properties.authenticationType}}' === 'KeyPair' && " + SHOW_ON_CP_FALSE
            },
            {
                required: true,
                type: "java.lang.String",
                name: "connectionUrl",
                addToModel: true,
                visibility: SHOW_ON_CP_FALSE,
                description:
                    "Please specify the jdbc connection url for snowflake including the database name in the format jdbc:snowflake://<snowflakendpoint>/?db=<DB Name>"
            },
            {
                required: true,
                type: "java.lang.String",
                defaultValue: "",
                name: "username",
                addToModel: true,
                visibility: SHOW_ON_CP_FALSE
            },
            {
                name: "password",
                label: "Password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: p@ssword1234",
                required: true,
                addToModel: true,
                defaultValue: "",
                visibility: "'{{properties.authenticationType}}' === 'Password' && " + SHOW_ON_CP_FALSE
            },
            {
                name: "dbName",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: Employee",
                label: "Database Name",
                required: true
            },
            ...ADVANCED_SETTINGS_SEPARATOR(),

            ...COMMON_ADVANCED_FIELDS("Snowflake").CDDLAction,
            COMMON_ADVANCED_FIELDS().CDDLTrackingTable,
            COMMON_ADVANCED_FIELDS("Snowflake").Mode,
            HEADER_FIELDS("Snowflake").StreamingUpload,
            {
                name: "CPAuthenticationType",
                type: FIELD_TYPES.COMPONENT,
                defaultValue: "",
                component: <></>
            },
            {
                name: "snowpipeStreaming",
                type: FIELD_TYPES.COMPONENT,
                visibility:
                    "( '{{properties.useConnectionProfile}}' === 'false' && '{{properties.authenticationType}}' !== 'Password' ) ||  ('{{properties.useConnectionProfile}}' === 'true' && '{{properties.CPAuthenticationType}}' !== 'Password')",
                component: props => {
                    return (
                        <Box
                            onClick={() => {
                                props.formRef.current?.setFieldValue("streamingUpload", true);
                            }}
                        >
                            <StriimControlLabel
                                label={
                                    <FieldValue
                                        subtitle={
                                            "Incoming data is buffered locally as one memory buffer per target table. Recommended for low latency."
                                        }
                                    >
                                        Snowpipe Streaming (default)
                                    </FieldValue>
                                }
                                control={
                                    <StriimRadio
                                        name="authenticationType"
                                        value="KeyPair"
                                        checked={props.formRef.current?.values?.streamingUpload === true}
                                    />
                                }
                            />
                        </Box>
                    );
                }
            },
            // Fields for Streaming upload
            {
                required: false,
                type: FIELD_TYPES.STRING,
                defaultValue: "MaxParallelRequests=10, MaxRequestSizeInMB=6, MaxRecordsPerRequest=100000",
                style: sxStyles.marginStyle,
                name: "StreamingConfiguration",
                addToModel: true,
                visibility:
                    "'{{properties.streamingUpload}}' === 'true' && (('{{properties.useConnectionProfile}}' === 'true' && '{{properties.CPAuthenticationType}}'  !== 'Password') || ('{{properties.useConnectionProfile}}' === 'false' && '{{properties.authenticationType}}' !== 'Password' )) "
            },
            {
                required: false,
                type: FIELD_TYPES.STRING,
                defaultValue: "",
                visibility:
                    "'{{properties.streamingUpload}}' === 'true' && (('{{properties.useConnectionProfile}}' === 'true' && '{{properties.CPAuthenticationType}}'  !== 'Password') || ('{{properties.useConnectionProfile}}' === 'false' && '{{properties.authenticationType}}' !== 'Password' )) ",
                style: sxStyles.marginStyle,
                name: "userRole",
                addToModel: true
            },
            {
                name: "fileUpload",
                type: FIELD_TYPES.COMPONENT,
                component: props => {
                    return (
                        <Box
                            onClick={() => {
                                props.formRef.current.setFieldValue("streamingUpload", false);
                            }}
                        >
                            <StriimControlLabel
                                label={<FieldValue>File upload</FieldValue>}
                                control={
                                    <StriimRadio checked={props.formRef.current?.values?.streamingUpload === false} />
                                }
                            />
                        </Box>
                    );
                }
            },
            // Fields for File upload
            {
                name: "externalStageType",
                type: FIELD_TYPES.SELECT,
                label: "External Stage Type",
                defaultValue: { label: "Local", value: "Local" },
                options: [
                    { label: "Local", value: "Local" },
                    { label: "S3", value: "S3" },
                    { label: "ADLS Gen2", value: "ADLSGen2" }
                ],
                visibility: "'{{properties.streamingUpload}}' === 'false'",
                style: sxStyles.marginStyle,
                required: true,
                addToModel: true,
                additionalInputProps: additionalSelectInputProps
            },
            {
                required: true,
                type: FIELD_TYPES.STRING,
                defaultValue: "",
                uiconfig: '{"type":"CONNECTIONPROFILE"}',
                visibility:
                    "'{{properties.streamingUpload}}' === 'false' && '{{properties.useConnectionProfile}}'==='true'&&('{{properties.externalstagetype}}'==='S3'||'{{properties.externalstagetype}}'==='ADLSGen2')",
                viewWeight: 0,
                supportingConnectionProfiles: "[S3, ADLSGen2]",
                name: "externalStageConnectionProfileName",
                style: sxStyles.marginStyle,
                addToModel: true
            },
            // Fields for Amazon S3 staging
            {
                name: "s3Region",
                type: FIELD_TYPES.STRING,
                label: "S3 Region",
                required: true,
                defaultValue: "us-west-1",
                visibility:
                    "'{{properties.streamingUpload}}' === 'false' && '{{properties.useConnectionProfile}}'==='false' && '{{properties.externalStageType}}' === 'S3'",
                style: sxStyles.marginStyle,
                addToModel: true
            },
            {
                name: "s3AccessKey",
                type: FIELD_TYPES.STRING,
                label: "S3 Acess Key",
                visibility:
                    "'{{properties.streamingUpload}}' === 'false' && '{{properties.useConnectionProfile}}'==='false' && '{{properties.externalStageType}}' === 'S3'",
                style: sxStyles.marginStyle,
                addToModel: true
            },
            {
                name: "s3SecretAccessKey",
                type: FIELD_TYPES.PASSWORD,
                label: "S3 Secret Access Key",
                visibility:
                    "'{{properties.streamingUpload}}' === 'false' && '{{properties.useConnectionProfile}}'==='false' && '{{properties.externalStageType}}' === 'S3'",
                style: sxStyles.marginStyle,
                addToModel: true
            },
            {
                name: "s3BucketName",
                type: FIELD_TYPES.STRING,
                label: "S3 Bucket Name",
                required: true,
                visibility:
                    "'{{properties.streamingUpload}}' === 'false' && '{{properties.useConnectionProfile}}'==='false' && '{{properties.externalStageType}}' === 'S3'",
                style: sxStyles.marginStyle,
                addToModel: true
            },
            // Fields for Azure staging
            {
                name: "azureAccountAccessKey",
                type: FIELD_TYPES.PASSWORD,
                label: "Azure Access Key",
                required: true,
                visibility:
                    "'{{properties.streamingUpload}}' === 'false' && '{{properties.useConnectionProfile}}'==='false' && '{{properties.externalStageType}}' === 'ADLSGen2'",
                style: sxStyles.marginStyle,
                addToModel: true
            },
            {
                name: "azureAccountName",
                type: FIELD_TYPES.STRING,
                label: "Azure Account Name",
                required: true,
                visibility:
                    "'{{properties.streamingUpload}}' === 'false' && '{{properties.useConnectionProfile}}'==='false' && '{{properties.externalStageType}}' === 'ADLSGen2'",
                style: sxStyles.marginStyle,
                addToModel: true
            },
            {
                name: "azureContainerName",
                type: FIELD_TYPES.STRING,
                label: "Azure Container Name",
                required: true,
                visibility:
                    "'{{properties.streamingUpload}}' === 'false' && '{{properties.useConnectionProfile}}'==='false' && '{{properties.externalStageType}}' === 'ADLSGen2'",
                style: sxStyles.marginStyle,
                addToModel: true
            },
            {
                name: "clientConfiguration",
                type: FIELD_TYPES.STRING,
                required: false,
                visibility:
                    "'{{properties.streamingUpload}}' === 'false' && ( '{{properties.externalStageType}}' === 'ADLSGen2' ||  '{{properties.externalStageType}}' === 'S3')",
                style: sxStyles.marginStyle,
                addToModel: true
            }
        ]
    },
    DELTALAKEWRITER: {
        form_fields: [
            ...getConnectionProfileFields("Databricks"),
            {
                name: "connectionUrl",
                type: FIELD_TYPES.STRING,
                label: "Connection URL",
                placeholder: "",
                addToModel: true,
                required: true,
                visibility: SHOW_ON_CP_FALSE
            },
            {
                type: FIELD_TYPES.SELECT,
                defaultValue: "PersonalAccessToken",
                visibility: "false",
                values: ["AzureAD", "PersonalAccessToken"],
                name: "authenticationType",
                addToModel: true,
                additionalInputProps: additionalSelectInputProps
            },
            {
                type: FIELD_TYPES.PASSWORD,
                visibility:
                    "'{{properties.authenticationtype}}'==='PersonalAccessToken' && '{{properties.useConnectionProfile}}'==='false'",
                name: "personalAccessToken",
                addToModel: true,
                required: true
            },
            {
                name: "catalog",
                type: FIELD_TYPES.STRING,
                label: "Catalog Name",
                addToModel: true,
                description: "If using unity catalog specify the name of catalog to write to",
                style: { "& .form-control-description-container": { marginLeft: "0px" } }
            },
            ...ADVANCED_SETTINGS_SEPARATOR(),
            ...COMMON_ADVANCED_FIELDS("Databricks").CDDLAction,
            COMMON_ADVANCED_FIELDS().CDDLTrackingTable,
            COMMON_ADVANCED_FIELDS("Databricks").Mode,
            {
                name: "externalStageType",
                type: FIELD_TYPES.SELECT,
                label: "External Stage Type",
                defaultValue: {
                    label: "DBFSROOT",
                    value: "DBFSROOT"
                },
                options: [
                    { label: "S3", value: "S3" },
                    { label: "ADLS Gen2", value: "ADLSGen2" },
                    { label: "DBFSROOT", value: "DBFSROOT" },
                    { label: "Personal Staging Location", value: "PersonalStagingLocation" }
                ],
                required: true,
                addToModel: true,
                additionalInputProps: additionalSelectInputProps
            },
            {
                required: true,
                type: FIELD_TYPES.STRING,
                defaultValue: "",
                uiconfig: '{"type":"CONNECTIONPROFILE"}',
                visibility:
                    "'{{properties.useConnectionProfile}}'==='true'&&('{{properties.externalstagetype}}'==='S3'||'{{properties.externalstagetype}}'==='ADLSGen2')",
                viewWeight: 0,
                supportingConnectionProfiles: "[S3, ADLSGen2]",
                name: "externalStageConnectionProfileName",
                addToModel: true
            },
            // Fields for AWS S3
            {
                required: true,
                type: FIELD_TYPES.STRING,
                defaultValue: "striim-deltalake-bucket",
                visibility:
                    "'{{properties.useConnectionProfile}}'==='false' && '{{properties.externalStageType}}'==='S3'",
                name: "s3BucketName",
                addToModel: true
            },
            {
                required: true,
                type: FIELD_TYPES.STRING,
                defaultValue: "us-west-1",
                visibility:
                    " '{{properties.useConnectionProfile}}'==='false' && '{{properties.externalStageType}}'==='S3'",
                name: "s3Region",
                addToModel: true
            },
            {
                required: false,
                type: FIELD_TYPES.PASSWORD,
                defaultValue: "",
                visibility:
                    " '{{properties.useConnectionProfile}}'==='false' && '{{properties.externalStageType}}'==='S3'",
                name: "s3AccessKey",
                addToModel: true
            },
            {
                required: false,
                type: FIELD_TYPES.PASSWORD,
                defaultValue: "",
                visibility:
                    "'{{properties.useConnectionProfile}}'==='false' && '{{properties.externalStageType}}'==='S3'",
                name: "s3SecretAccessKey",
                addToModel: true
            },
            //Fields for ADLSGen2
            {
                required: true,
                type: "java.lang.String",
                defaultValue: "",
                visibility:
                    "'{{properties.useConnectionProfile}}'==='false' && '{{properties.externalStageType}}'==='ADLSGen2'",
                addToModel: true,
                name: "azureAccountName"
            },
            {
                required: true,
                type: FIELD_TYPES.PASSWORD,
                defaultValue: "",
                visibility:
                    "'{{properties.useConnectionProfile}}'==='false' && '{{properties.externalStageType}}'==='ADLSGen2'",
                addToModel: true,
                name: "azureAccountAccessKey"
            },
            {
                required: true,
                type: FIELD_TYPES.STRING,
                defaultValue: "striim-deltalakewriter-container",
                visibility:
                    "'{{properties.useConnectionProfile}}'==='false' && '{{properties.externalStageType}}'==='ADLSGen2'",
                addToModel: true,
                name: "azureContainerName"
            },
            //Fields for Personal Staging Location
            {
                name: "personalStagingUsername",
                type: FIELD_TYPES.STRING,
                label: "Personal Staging Username",
                required: true,
                visibility: "'{{properties.externalStageType}}'==='PersonalStagingLocation'",
                addToModel: true
            }
        ]
    },
    BIGQUERY: {
        form_fields: [
            ...getConnectionProfileFields("BigQuery"),
            {
                required: true,
                type: FIELD_TYPES.STRING,
                name: "projectId",
                addToModel: true,
                visibility: SHOW_ON_CP_FALSE,
                defaultValue: ""
            },
            {
                type: FIELD_TYPES.STRING,
                name: "BillingProjectId",
                addToModel: true,
                visibility: SHOW_ON_CP_FALSE,
                defaultValue: ""
            },
            {
                type: FIELD_TYPES.FILE,
                uiconfig: '{"type":"FILEBROWSER"}',
                name: "ServiceAccountKey",
                addToModel: true,
                visibility: SHOW_ON_CP_FALSE,
                defaultValue: ""
            },
            {
                type: FIELD_TYPES.STRING,
                name: "PrivateServiceConnectEndpoint",
                addToModel: true,
                visibility: SHOW_ON_CP_FALSE,
                defaultValue: ""
            }
        ],
        advanced_fields: [
            ...ADVANCED_SETTINGS_SEPARATOR(),
            ...COMMON_ADVANCED_FIELDS("BigQuery").CDDLAction,
            COMMON_ADVANCED_FIELDS().CDDLTrackingTable,
            COMMON_ADVANCED_FIELDS("BigQuery").Mode,
            HEADER_FIELDS("BigQuery").StreamingUpload,
            {
                name: "streamingUpload",
                type: FIELD_TYPES.BOOLEAN,
                defaultValue: true,
                label: (
                    <ToggleFieldLabel
                        label={"Streaming mode"}
                        subtitle={
                            "Incoming data is buffered locally as one memory buffer per target table. Recommended for low latency."
                        }
                    />
                ),
                addToModel: true
            },
            {
                name: "StreamingConfiguration",
                required: false,
                type: FIELD_TYPES.STRING,
                defaultValue:
                    "MaxRequestSizeInMB=5, MaxParallelRequests=10, ApplicationCreatedStreamMode=None, UseLegacyStreamingAPI=false",
                style: sxStyles.toggleMarginStyle,
                addToModel: true,
                visibility: "'{{properties.streamingUpload}}' === 'true'"
            }
        ]
    },
    FABRICDATAWAREHOUSEWRITER: {
        form_fields: [
            {
                required: true,
                type: "java.lang.String",
                name: "connectionUrl",
                addToModel: true
            },
            {
                required: true,
                type: "java.lang.String",
                defaultValue: "",
                name: "username",
                addToModel: true
            },
            {
                name: "password",
                label: "Password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: p@ssword1234",
                required: true,
                defaultValue: "",
                addToModel: true
            },
            {
                name: "accountName",
                label: "Account Name",
                type: FIELD_TYPES.STRING,
                required: true,
                defaultValue: "",
                addToModel: true
            },
            {
                name: "accountAccessKey",
                label: "Account Access Key",
                type: FIELD_TYPES.PASSWORD,
                required: true,
                defaultValue: "",
                addToModel: true
            },
            {
                name: "warehouse",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: Employee",
                label: "Warehouse",
                required: true
            }
        ],
        advanced_fields: [
            ...ADVANCED_SETTINGS_SEPARATOR(),
            ...COMMON_ADVANCED_FIELDS("Fabric Data Warehouse").CDDLAction,
            COMMON_ADVANCED_FIELDS().CDDLTrackingTable,
            COMMON_ADVANCED_FIELDS("Fabric Data Warehouse").Mode
        ]
    },
    FABRICMIRRORWRITER: {
        form_fields: [
            {
                name: "MirroredDatabaseName",
                type: FIELD_TYPES.STRING,
                placeholder: "Enter mirror database Name",
                label: "Mirror Database Name",
                required: true,
                addToModel: true
            },
            {
                required: true,
                type: "java.lang.String",
                defaultValue: "",
                uiconfig: '{"type":"CONNECTIONPROFILE"}',
                supportingConnectionProfiles: `[FabricMirror]`,
                name: "connectionProfileName",
                label: "Please choose the flow to create a new connection profile to Fabric Mirror",
                addToModel: true
            }
        ],
        advanced_fields: [
            ...ADVANCED_SETTINGS_SEPARATOR(),
            {
                name: "batchPolicy",
                type: FIELD_TYPES.STRING,
                defaultValue: "eventcount:10000,interval:5m",
                addToModel: true
            },
            ...COMMON_ADVANCED_FIELDS("Fabric Mirror").CDDLAction,
            COMMON_ADVANCED_FIELDS().CDDLTrackingTable,
            COMMON_ADVANCED_FIELDS("Fabric Mirror Writer").ParallelThreadsDWH
        ]
    },
    MARIADB: {
        form_fields: [
            ...sourceFormConfig.MARIADB.form_fields,
            ...sourceFormConfig.MARIADB.ilcdc_controls.form_fields,
            ...RECOVERY_CHECK_POINT_TABLE
        ],
        advanced_fields: [
            ...ADVANCED_SETTINGS_SEPARATOR(),
            ...COMMON_ADVANCED_FIELDS("MariaDB").CDDLAction,
            COMMON_ADVANCED_FIELDS().CDDLTrackingTable,
            COMMON_ADVANCED_FIELDS("MariaDB").ParallelThreads
        ]
    },
    MYSQL: {
        form_fields: [
            ...sourceFormConfig.MYSQL.form_fields,
            ...sourceFormConfig.MYSQL.ilcdc_controls.form_fields,
            ...RECOVERY_CHECK_POINT_TABLE
        ],
        advanced_fields: [
            ...ADVANCED_SETTINGS_SEPARATOR(),
            ...COMMON_ADVANCED_FIELDS("MySQL").CDDLAction,
            COMMON_ADVANCED_FIELDS().CDDLTrackingTable,
            COMMON_ADVANCED_FIELDS("MySQL").ParallelThreads
        ]
    },
    MSSQL: {
        form_fields: [
            ...sourceFormConfig.SQLSERVER.form_fields,
            ...sourceFormConfig.SQLSERVER.ilcdc_controls.form_fields
        ],
        advanced_fields: [
            ...ADVANCED_SETTINGS_SEPARATOR(),
            ...COMMON_ADVANCED_FIELDS("SQL Server").CDDLAction,
            COMMON_ADVANCED_FIELDS().CDDLTrackingTable,
            COMMON_ADVANCED_FIELDS("SQL Server").ParallelThreads
        ]
    },
    POSTGRES: {
        form_fields: [
            ...sourceFormConfig.POSTGRES.form_fields,
            ...sourceFormConfig.POSTGRES.ilcdc_controls.form_fields
        ],
        advanced_fields: [
            ...ADVANCED_SETTINGS_SEPARATOR(),
            ...COMMON_ADVANCED_FIELDS("PostgreSQL").CDDLAction,
            COMMON_ADVANCED_FIELDS().CDDLTrackingTable,
            COMMON_ADVANCED_FIELDS("PostgreSQL").ParallelThreads
        ]
    },
    ORACLE: {
        form_fields: [...sourceFormConfig.ORACLE.form_fields, ...sourceFormConfig.ORACLE.ilcdc_controls.form_fields],
        advanced_fields: [
            ...ADVANCED_SETTINGS_SEPARATOR(),
            ...COMMON_ADVANCED_FIELDS("Oracle").CDDLAction,
            COMMON_ADVANCED_FIELDS().CDDLTrackingTable,
            COMMON_ADVANCED_FIELDS("Oracle").ParallelThreads
        ]
    }
};
export default TARGET_FORM_INFO;
