import BaseTable from "./basetable";

var WindowsTable = {};

WindowsTable.View = BaseTable.View.extend({
    columns: [
        {
            label: "Name",
            srcfield: "label",
        },
        {
            label: "Nodes",
            srcfield: "nodes",
        },
        {
            label: "Window size",
            srcfield: "windowSize",
        },
    ],

    dataMapper: function (server) {
        return {
            label: server["full-name"],
            nodes: server["num-servers"],
            windowSize: server["most-recent-data"]["window-size"],
        };
    },
});

export default WindowsTable;
