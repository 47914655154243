import App from "app";
import WAHighChartsMixin from "app/components/dashboard/visualization/charts/linechart/mixins/linechart-highcharts";
import "app/components/dashboard/visualization/charts/chart";

App.module("Chart.LineChart", function (LineChart, App, Backbone, Marionette, $, _) {
    LineChart.View = App.Chart.Default.ChartView.extend({
        initialize: function () {
            // Mixin the Highcharts line chart
            _(this).extend(WAHighChartsMixin);

            // Call super initialize
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
        },
    });
});
