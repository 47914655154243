import App from "app";
import Backbone from "backbone";
import _ from "underscore";
import "app/components/dashboard/visualization/charts/table/table";
import formatter from "app/components/monitor/common/valueFormatter";
import NestedTypes from "backbone.nestedtypes";
import { showAlertsFor } from "app/components/alertmanager/alertmanager";
import $ from "jquery";
import BaseTable from "../../../appdetails/appdata/tables/basetable";

var AppOverviewTable = {};

var Table = App.module("Chart.Table");

//TODO disable column resizable feature
AppOverviewTable.View = Backbone.Marionette.LayoutView.extend({
    template: _.template('<div class="table no-vertical-borders"></div>'),

    regions: {
        table: ".table"
    },

    modelEvents: {
        change: "update"
    },

    columns: [],
    events: {
        "click .alertButton": "showAlerts"
    },
    initialize: function() {
        this.setColumns([
            {
                label: "Name",
                srcfield: "label",
                view: BaseTable.CellPseudoLink
            },
            {
                label: "Status",
                srcfield: "status"
            },
            {
                label: "Rate",
                srcfield: "rate"
            },
            {
                label: "CPU",
                srcfield: "cpu-per-node"
            },
            {
                label: "Nodes",
                srcfield: "nodes"
            }
        ]);
    },

    setColumns: function(columns) {
        this.columns = columns;
        this.columns.forEach(function(column) {
            column.drilldown = {
                show_drilldown: true
            };
        });
    },

    onRender: function() {
        var TableModel = NestedTypes.Model.extend({
            defaults: {
                config: {}
            }
        });
        var tableView = new Table.View({
            model: new TableModel()
        });
        tableView.chartConfig = {
            config: {
                show_headers: true,
                pagesize: Number.POSITIVE_INFINITY,
                columns: this.columns,
                data: this.model.appOverview.map(this.dataMapper)
            }
        };
        tableView.stopListening(tableView, "datapoint:click");
        tableView.listenTo(tableView, "datapoint:click", function(element) {
            // TODO what do we want to do with this?
            var appName = element.get("data").get("label");
            App.navigate("#/monitor/" + appName, {
                trigger: true
            });
            window.scrollTo(0, 0);
        });
        this.table.show(tableView);
    },

    cleanEmptyCells: list =>
        JSON.parse(JSON.stringify(list, (key, value) => (value === undefined ? "Fetching…" : value))),

    update: function() {
        var tableView = this.table.currentView;
        tableView.chartConfig.config.data = this.cleanEmptyCells(this.model.appOverview.map(this.dataMapper));
        this.setParentHeight(this.model.appOverview.length);
        tableView.render();
    },

    dataMapper: function(app) {
        //var nsName = utils.getNamespace(app["full-name"]);
        //var name = utils.getName(app["full-name"]);
        //var fqn = nsName + ".APPLICATION." + name;
        return {
            label: app["full-name"],
            status: formatter.formatStatus(app["status"]),
            rate: app["most-recent-data"]["source-rate"],
            "cpu-per-node": formatter.toPercentageString(app["most-recent-data"]["cpu-per-node"]),
            nodes: app["num-servers"]
        };
    },
    setParentHeight: function(recordLength) {
        if (recordLength > 0) {
            var parentHeight = 35 * recordLength + 45;
            this.$el.parent().css("min-height", parentHeight);
        }
    },
    showAlerts: function(e) {
        var id = $(e.target).attr("data-id");
        showAlertsFor(id);
    }
});

export default AppOverviewTable;
