//https://www.cssscript.com/material-design-tab-
//https://www.cssscript.com/demo/material-design-tab-bar/
export function initMaterialDesignTabs(tabsContainer: HTMLElement) {
    if (!tabsContainer) {
        throw Error("Cannot initialize tabs");
    }

    let bottomBorder = tabsContainer.querySelector(".bottom-border");
    if (!bottomBorder) {
        bottomBorder = document.createElement("div");
        bottomBorder.classList.add("bottom-border");
        tabsContainer.appendChild(bottomBorder);
    }

    const ACTIVE_CLASS = "active";
    const tabItems: NodeListOf<HTMLElement> = tabsContainer.querySelectorAll(".js-tab-button");
    //const indicator: HTMLElement = tabsContainer.querySelector(".js-tab-indicator");
    //const tabsMenu = tabsContainer.querySelector(".js-tabs-menu");
    //indicator.style.width = `${tabItems[0].clientWidth}px`;
    tabItems.forEach(tab => {
        tab.setAttribute("aria-selected", "false");
        tab.addEventListener("click", e => {
            if ((e.target as HTMLElement).classList.contains("disabled")) {
                return;
            }
            handleTabSelection(e);
            const current = tabsContainer.querySelector(`.focus`);
            if (current) {
                current.className = current.className.replace(` focus`, "");
            }
        });
        tab.addEventListener("mousedown", handleRippleEffect);
        tab.addEventListener("keydown", handleArrowKeysFocus);
    });
    function handleTabSelection(e) {
        toggleActiveClass(e);
        //moveTabIndicator(e);
        handleA11y(e);
    }
    function toggleActiveClass(e) {
        const current = tabsContainer.querySelector(`.${ACTIVE_CLASS}`);
        if (current) {
            current.className = current.className.replace(` ${ACTIVE_CLASS}`, "");
        }
        e.target.className += ` ${ACTIVE_CLASS}`;
    }
    function toggleFocusClass(e) {
        const current = tabsContainer.querySelector(`.focus`);
        if (current) {
            current.className = current.className.replace(` focus`, "");
        }
        e.currentTarget.className += ` focus`;
    }
    /* function moveTabIndicator(e) {
        moveTabIndicatorForElement(e.target);
    }*/

    /*function moveTabIndicatorForElement(el: Element) {
        const indicatorPosition = el.getBoundingClientRect().left - tabsMenu.getBoundingClientRect().left;
        indicator.style.width = `${el.clientWidth}px`;
        indicator.style.left = `${indicatorPosition}px`;
    }*/

    function handleRippleEffect(e) {
        const posX = e.target.offsetLeft;
        const posY = e.target.offsetTop;
        const span = document.createElement("span");
        const x = e.pageX - e.target.getBoundingClientRect().left;
        const y = e.pageY - e.target.getBoundingClientRect().top;
        span.classList.add("tab-button__ripple");
        e.target.appendChild(span);
        span.style.left = `${x}px`;
        span.style.top = `${y}px`;
        setTimeout(() => {
            span.remove();
        }, 1000);
    }
    function handleA11y(e) {
        tabItems.forEach(tab => {
            const addActiveFocus = () => {
                e.target.setAttribute("aria-selected", "true");
                e.target.setAttribute("tabindex", "0");
            };
            const removeActiveFocus = () => {
                tab.setAttribute("aria-selected", "false");
                tab.setAttribute("tabindex", "-1");
            };
            tab === e.target ? addActiveFocus() : removeActiveFocus();
        });
    }
    function handleArrowKeysFocus(e) {
        const totalTabItems = tabItems.length - 1;
        const leftArrowKey = e.which === 37;
        const rightArrowKey = e.which === 39;
        let index = Array.prototype.indexOf.call(tabItems, e.currentTarget);
        let newIndex;
        const decrementIndex = () => {
            newIndex = index - 1;
            if (newIndex < 0) {
                newIndex = totalTabItems;
            }
        };
        const incrementIndex = () => {
            newIndex = index + 1;
            if (newIndex > totalTabItems) {
                newIndex = 0;
            }
        };
        if (leftArrowKey) {
            decrementIndex();
            toggleFocusClass(e);
        }
        if (rightArrowKey) {
            incrementIndex();
            toggleFocusClass(e);
        }
        const current = tabsContainer.querySelector(`.focus`);
        if (current) {
            current.className = current.className.replace(` focus`, "");
        }
        if (tabItems[newIndex]) {
            tabItems[newIndex].className += ` focus`;
            tabItems[newIndex].focus();
        }
    }
    // Initially activate the first tab
    tabItems[0].setAttribute("tabindex", "0");
    tabItems[0].setAttribute("aria-selected", "true");

    /*function updateIndicatorPosition() {
        const activeButton = tabsMenu.querySelector("button.active");
        if (activeButton) {
            moveTabIndicatorForElement(activeButton);
        }
    }*/

    /*let updateHandle: any;
    function updateIndicatorPositionThrottled() {
        if (updateHandle) {
            clearTimeout(updateHandle);
            updateHandle = null;
        }

        updateHandle = setTimeout(updateIndicatorPosition, 100);
    }*/

    /*let resizeObserver = new ResizeObserver(entries => {
        updateIndicatorPositionThrottled();
    });
    resizeObserver.observe(tabsContainer);*/

    //setTimeout(updateIndicatorPosition, 0);

    return () => {
        /*resizeObserver.unobserve(tabsContainer);
        resizeObserver.disconnect();
        resizeObserver = null;*/
    };
}
