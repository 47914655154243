import React, { useEffect, useState } from "react";
import { StriimTypography, StriimTable, StriimModal } from "@striim/striim-ui";
import { addRoleTableColumns } from "../users-user/constants";
import { EmptyTableInfo, ModalSearchBox, GoToConsoleInfoBox } from "./";
import { useUserManagement } from "core/user-management/user-management-context";
import LoadingIndicator from "../../../../../generic/loading-indicator/loading-indicator";
import { useTheme } from "@mui/material";

const styles = {
    ".MuiDialog-paper": { width: "950px" },
    ".MuiDialogContent-root": { paddingBottom: 2 },
    ".MuiDialogActions-root": {
        borderTop: "1px solid",
        borderColor: "greyscale.200"
    }
};
const AddRolesModal = ({ visible, onConfirm, onCancel, rolesToExclude = [] }) => {
    const { palette } = useTheme();

    const {
        rolesForModal: availableRoles,
        getRolesForModal: loadAvailableRoles,
        rolesFetched: loading
    } = useUserManagement();
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [filteredRoles, setFilteredRoles] = useState([]);
    const [manualState, setManualState] = useState({
        filters: { role: "" }
    });

    const idsToExclude = rolesToExclude.map(x => x.name);
    const nonAssignedRoles = (availableRoles || [])
        .map(x => ({ ...x, id: x.name }))
        .filter(x => idsToExclude.indexOf(x.id) == -1);

    useEffect(() => {
        if (!availableRoles.length) {
            loadAvailableRoles();
        }
    }, []);

    const clearFilter = () => {
        if (manualState.filters.role) {
            setManualState({ filters: { role: "" } });
        }
    };

    const filterRoles = ({ filters }) => {
        const filtered = nonAssignedRoles.filter(v => {
            return v.name.toLowerCase().includes(filters.role.toLowerCase());
        });
        setFilteredRoles(filtered);
    };

    return (
        <StriimModal
            size="medium"
            dialogProps={{ sx: styles }}
            autoHeight
            isVisible={visible}
            onConfirm={() => onConfirm(selectedRoles)}
            onCancel={() => {
                clearFilter();
                onCancel();
            }}
            confirmContent={"Save"}
            confirmButtonProps={{ "data-test-id": "confirm-user-roles-button" }}
            cancelButtonProps={{ "data-test-id": "cancel-user-roles-button" }}
            titleContent={
                <StriimTypography variant="h2" color="primary.700">
                    Add Roles
                </StriimTypography>
            }
        >
            {loading && <LoadingIndicator isGlobal={false} />}
            <GoToConsoleInfoBox objectType="roles" />
            <ModalSearchBox
                field="role"
                manualState={manualState}
                setManualState={setManualState}
                inputFieldValue={manualState.filters.role}
            />

            {!loading && (
                <>
                    <StriimTable
                        TableProps={{ "data-testid": "roles-table" }}
                        TableHeaderCellProps={{
                            style: {
                                background: palette.greyscale[50]
                            }
                        }}
                        data={filteredRoles}
                        columns={addRoleTableColumns.slice(0, 2)}
                        onStateChange={filterRoles}
                        manualState={manualState}
                        selectable
                        onRowsSelect={rows => {
                            const tmp = rows.map(x => x.values);

                            if (selectedRoles.length !== tmp.length) {
                                setSelectedRoles(tmp);
                            }
                        }}
                        NoRowsComponent={<EmptyTableInfo />}
                        indexedRow
                        TableBodyProps={{ seperated: true }}
                    />
                </>
            )}
        </StriimModal>
    );
};

export default AddRolesModal;
