const sxStyles = {
    modalHeader: {
        zIndex: 9999999999,
        ".MuiDialogTitle-root": {
            padding: ({ spacing }) => spacing(2, 3)
        }
    },
    separator: {
        width: "1px",
        height: ({ spacing }) => spacing(3),
        backgroundColor: "greyscale.200"
    },
    icon: {
        "& svg": {
            fill: "transparent"
        }
    },
    legend: {
        width: ({ spacing }) => spacing(1.5),
        height: ({ spacing }) => spacing(0.5),
        borderRadius: ({ spacing }) => spacing(0.5)
    },
    dashedLegendContainer: {
        display: "flex",
        alignItems: "center",
        gap: "1px"
    },
    dashedLegend: {
        width: ({ spacing }) => spacing(0.75),
        height: ({ spacing }) => spacing(0.5),
        borderRadius: ({ spacing }) => spacing(0.3)
    }
};
export default sxStyles;
