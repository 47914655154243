import cqTransformationProvider from "app/components/common/editor/meta-object-editors/cqTransformations/transformationProvider";

export default {
    process: function (newNodeType, nodeModel, parentNode, predefinedComponentName) {
        if (newNodeType === "CQ") {
            nodeModel.metaObject.autoProperties = nodeModel.metaObject.autoProperties || {};

            let cqTransformation = cqTransformationProvider.getTransformationByName(predefinedComponentName);
            if (cqTransformation) {
                nodeModel.metaObject.autoProperties.uiconfig = {
                    subType: predefinedComponentName,
                    config: {},
                };
            }

            if (
                parentNode &&
                (parentNode.metaObject.type === "STREAM" ||
                    parentNode.metaObject.type === "CACHE" ||
                    parentNode.metaObject.type === "WINDOW" ||
                    parentNode.metaObject.type === "WACTIONSTORE")
            ) {
                if (cqTransformation) {
                    nodeModel.metaObject.autoProperties.uiconfig.config.stream = parentNode.metaObject.id;
                } else {
                    var name = parentNode.metaObject.name;
                    var alias = name.charAt(0).toLowerCase();
                    nodeModel.metaObject.autoProperties.select = "SELECT * FROM " + name + " " + alias + ";";
                }
            }
        }
    },
};
