import Backbone from "backbone";
let TemplateModel = Backbone.Model.extend({
    defaults: {
        id: String,
        description: String,
        url: String,
        iconTo: String,
        iconFrom: String,
        tags: [],
        campaigns: [],
        uses: [],
    },
    idAttribute: "url",
});

let TemplateCollection = Backbone.Collection.extend({
    model: TemplateModel,
    idAttribute: "url",
});


export default {
    Collection: TemplateCollection
};
