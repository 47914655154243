import Backbone from "backbone";
import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import BaseEditor from "app/components/common/editor/meta-object-editors/base";
import MetaObjectDataSource from "app/components/common/editor/control/select/metaobject-datasource";
import metaStoreService from "core/services/metaStoreService/meta-store-service";

var Module = function(metadata_object, options) {
    options = options || {};
    options.displayFields = options.displayFields || ["inputStream", "typeViewer", "adapter", "formatter"];
    options.sourceAdapters = options.sourceAdapters || [];

    this.typeViewer = UIControl.TypeViewer.extend({
        hideLabel: true
    }).create();

    options.StaticControls = {
        typeViewer: this.typeViewer
    };

    BaseEditor.apply(this, arguments);
};

Module.prototype = _.extend({}, BaseEditor.prototype, {
    addCustomFields: function() {
        var _this = this;

        let metaObjectId = this.MetaObjectModel && this.MetaObjectModel.id;

        this.fieldMapper.adapter = UIControl.PropertyTemplate("target")
            .extend({
                subControl: true,
                hideLabel: true,
                metaObjectId: metaObjectId,
                sourceAdapters: this.options.sourceAdapters,
                disableAdapterSelect: this.options.disableAdapterSelect
            })
            .create();
        this.fieldMapper.adapter.on(UIControl.EVENT.VALUE_CHANGED, function(value) {
            var closestEditor = _this.fieldMapper.formatter.$el.closest(".field-row");
            if (value.handler && this.requiresFormatter) {
                if (!_this.MetaObjectModel.get("formatter")) {
                    _this.MetaObjectModel.set("formatter", _this.fieldMapper.formatter.model.value);
                }
                _this.fieldMapper.formatter.hidden = false;
                closestEditor.show(value.handler);
            } else {
                _this.fieldMapper.formatter.hidden = true;
                _this.MetaObjectModel.unset("formatter");
                closestEditor.hide(value.handler);
            }
        });

        this.fieldMapper.formatter = UIControl.PropertyTemplate("formatter")
            .extend({
                subControl: true,
                hidden: true,
                hideLabel: true,
                metaObjectId: metaObjectId
            })
            .create();
        var streams = new MetaObjectDataSource([metaStoreService.entities.STREAM]);
        this.fieldMapper.inputStream = UIControl.Select(streams, {
            addNew: true,
            type: metaStoreService.entities.STREAM
        }).create();
        this.fieldMapper.inputStream.on(UIControl.EVENT.VALUE_CHANGED, function(stream_value) {
            _this.typeViewer.viewTypeOf(stream_value);
        });
    },

    postRender: function() {
        var _this = this;
        _this.typeViewer.hideDetails();
        if (!this.MetaObjectModel.inputStream) {
            return;
        }
        metaStoreService.findById(this.MetaObjectModel.inputStream).then(function() {
            _this.typeViewer.viewTypeOf(_this.MetaObjectModel.inputStream).always(function() {
                _this.Form.view.clearDirty(_this.typeViewer.model);
            });
        });
    }
});

export default Module;
