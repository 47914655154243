import React from "react";
import { StriimTypography } from "@striim/striim-ui";
import { Box } from "@mui/material";
import classes from "./test-connection.styles";

interface MissingDriverProps {
    databaseType: string;
}

const MissingDriver = ({ databaseType }: MissingDriverProps) => {
    const resolveDriversMissingText = () => {
        switch (databaseType) {
            case "oracle":
                return (
                    <>
                        <StriimTypography sx={classes.header} variant="body4">
                            <div>Driver Unavailable</div>
                            <Box sx={classes.missingDriver}>
                                <ul>
                                    <li>
                                        1. Download driver from{" "}
                                        <a
                                            href="https://www.oracle.com/database/technologies/appdev/jdbc-downloads.html"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            oracle.com
                                        </a>
                                    </li>
                                    <li>2. Upload the driver</li>
                                </ul>
                            </Box>
                        </StriimTypography>
                    </>
                );
        }
        return (
            <StriimTypography sx={classes.header} variant="body4">
                Drivers are missing. Please contact with system administrator.
            </StriimTypography>
        );
    };
    return <>{resolveDriversMissingText()}</>;
};

export default MissingDriver;
