import React from "react";
import { StriimButton } from "@striim/striim-ui";
import { Grid } from "@mui/material";

const ButtonsBar = ({
    isDeletable,
    onDeleteClick,
    onCancelClick,
    onSaveClick,
    showActivateDeactivateBtn,
    isUserActive,
    onActivationToggle
}) => {
    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
                {showActivateDeactivateBtn && (
                    <StriimButton
                        variant="secondary"
                        onClick={onActivationToggle}
                        data-testid={`${isUserActive ? "deactivate" : "activate"}-button`}
                        sx={{ marginRight: 1 }}
                    >
                        {isUserActive ? "Deactivate" : "Activate"}
                    </StriimButton>
                )}
                {isDeletable && (
                    <StriimButton variant="secondary" onClick={onDeleteClick} data-testid="delete-button">
                        Delete
                    </StriimButton>
                )}
            </Grid>
            <Grid item>
                <StriimButton
                    variant="text"
                    onClick={onCancelClick}
                    data-testid="cancel-button"
                    sx={{ marginRight: 1 }}
                >
                    Cancel
                </StriimButton>
                <StriimButton variant="primary" onClick={onSaveClick} data-testid="save-button">
                    Save
                </StriimButton>
            </Grid>
        </Grid>
    );
};

export default ButtonsBar;
