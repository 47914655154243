import App from "app";
import tooltipyo from "app/components/common/tooltipyo/tooltipyo";
import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import NestedTypes from "backbone.nestedtypes";
import utils from "core/utils";
import template from "./templates/overview.html";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import EventsLogModel from "./../eventsLog/eventsLogModel";
import EventsLogView from "./../eventsLog/eventsLogView";
import ReportType from "./reportType/reportType";
import Sidebar from "app/components/common/sidebar/commonSidebar";
import InfoPanel from "app/components/monitor/overview/infopanel/infopanel";
import DataModule from "app/components/monitor/overview/appdata/data-module";
import ClusterTable from "app/components/monitor/overview/clustertable/clustertable";
import numeral from "numeral";
import helpableMixin from "app/components/common/helpable/helpable";
import overviewCalculator from "./overviewCalculator";
import "highcharts";
import securityService from "core/services/securityService/securityService";
import {
    addonFeaturesKeys,
} from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import monitorService, { TYPES, MonitorService } from "core/services/monitorService";
import NProgress from "nprogress";
import propertyTemplateService from "../../../../core/services/metaStoreService/property-template-service";

NProgress.configure({
    minimum: 0.25,
    trickleRate: 0.1,
    trickleSpeed: 2000
});

const Overview = {};
let API_PROGRESS = {
    getHealthObject: false
};

const globalApps = ["Global.MonitoringProcessApp", "Global.MonitoringSourceApp", "System$Alerts.AlertingApp"];

Overview.Model = NestedTypes.Model.extend({
    defaults: {
        report: {
            type: ReportType.Options.RealTime,
            selectedDate: null,
            selectedTime: null,
            startTime: null,
            endTime: null,
            value: null,
            title: "Select report type"
        },
        infopanel: NestedTypes.Model.extend({
            defaults: {
                serverCount: null,
                clusterName: null,
                version: null,
                uptime: null,
                memoryUsed: null,
                memoryUsedInt: null,
                memoryTotal: null,
                memoryTotalInt: null,

                throughput: 0,
                wactionsCnt: null,
                wactionsPerSecond: 0,

                appsCreated: null,
                appsDeployed: null,

                appsRunning: null,
                appsNotRunning: null,
                appsDown: null,
                appsTotal: null,

                nodesRunning: null,
                nodesDown: null,
                nodesTotal: null,

                agentsRunning: null,
                agentsDown: null,
                agentsTotal: null,

                classAlertApps: null,
                classAlertNodes: null,
                classAlertAgents: null,

                backpressuredImgClass: null,
                backPresssuredText: null,

                cpuRate: null,
                cpuCores: null,
                elasticMemoryFree: null,
                elasticMemoryThroughput: "Fetching…",
                kafkaMemoryThroughput: "Fetching…",
                totalInput: "",
                metadataHealthy: null
            }
        }),
        metrics: NestedTypes.Model.extend({
            defaults: {
                apps: [],
                servers: [],
                serie: [],
                selectedMetric: null,
                selectedItem: null,
                availableMetrics: null
            }
        }),
        clustertable: NestedTypes.Model.extend({
            defaults: {
                appOverview: [],
                nodeOverview: []
            }
        })
    }
});

Overview.View = Backbone.Marionette.LayoutView.extend(helpableMixin).extend({
    template: _.template(template),
    monitorSubscriber: null,
    idStrings: [],
    regions: {
        infopanel: ".infopanel",
        metrics: ".metrics",
        clustertable: ".clustertable"
    },

    ui: {
        eventLogButton: ".event-log",
        timeSelector: ".range-selection",
        timeRange: ".timeRange",
        monitorApiErrors: ".monitor-api-errors",
        monitorErrorNameRow: "#monitor-error-name",
        monitorErrorName: "#monitor-error-name span",
        monitorErrorMessage: "#monitor-error-message span"
    },

    events: {
        "click @ui.eventLogButton": "showEventsLog",
        "click @ui.timeSelector": "showTimeSelector"
    },
    getHelpID: function() {
        return "MONITOR";
    },
    getHelperContentElement: function() {
        return this.$el.find(".link");
    },
    initialize: function() {
        helpableMixin.initialize.apply(this, arguments);
        this.model = new Overview.Model();
        this.subscribeToMonitoring();
        this._getHealthObject();

        this.interval = setInterval(() => {
            if (this.model.report.type === ReportType.Options.TimeRange) {
                return;
            }
            if (!API_PROGRESS.getHealthObject) {
                this._getHealthObject();
            }

            metaStoreService.fetchIdentifiersByTypes(["SERVER", "APPLICATION"]).then(result => {
                if (result.length !== this.idStrings.length - 2) {
                    this.subscribeToMonitoring();
                }
            });
        }, 10000);

        this.listenTo(this, "before:destroy", function() {
            $("html").removeClass("monitor");
        });
        this.listenTo(this, "render", function() {
            $("html").addClass("monitor");
        });

        this.listenTo(
            this.model,
            "change:report",
            function() {
                if (this.model.report.type === ReportType.Options.RealTime) {
                    this.subscribeToMonitoring();
                } else {
                    monitorService.unSubscribe(this.monitorSubscriber);
                    var startTime = this.model.report.startTime.valueOf();
                    var endTime = this.model.report.endTime.valueOf();
                    let historicMonitorSubscriber = new MonitorService(TYPES.FULL, false);
                    historicMonitorSubscriber.setStart(startTime);
                    historicMonitorSubscriber.setEnd(endTime);
                    historicMonitorSubscriber.subscribe(this.idStrings, stats => {
                        this.setClusterStats(stats);
                    });
                    historicMonitorSubscriber.execute();
                }

                this.ui.timeRange.html(this.model.report.value);
            }.bind(this)
        );
    },
    _getHealthObject: function() {
        let _this = this;
        API_PROGRESS.getHealthObject = true;
        $.get("/health/healthRecords?size=1&from=0")
            .done(function(d) {
                var derby_alive;
                if (d && d.healthRecords) {
                    var healthRecord = d.healthRecords[0];
                    derby_alive = healthRecord?.derbyAlive; // healthRecord can be empty on first 2 minuts of Product run on empty Derby
                }
                _this.model.infopanel.set("metadataHealthy", derby_alive);
            })
            .always(() => {
                API_PROGRESS.getHealthObject = false;
            });
    },

    subscribeToMonitoring: async function() {
        if (this.monitorSubscriber) {
            monitorService.unSubscribe(this.monitorSubscriber);
        }
        let objectIdentifiers = await metaStoreService.fetchIdentifiersByTypes(["SERVER", "APPLICATION"]);
        this.idStrings = ["kafka", "es"];
        objectIdentifiers.forEach(objectIdentifier => {
            this.idStrings.push(objectIdentifier.get("id"));
        });
        monitorService.setType(TYPES.FULL);
        this.monitorSubscriber = monitorService.subscribe(this.idStrings, data => {
            this.onMonitorPayload(data);
        });
        monitorService.setShouldLoop(true);
        monitorService.execute();
    },

    onMonitorPayload(stats) {
        this.setClusterStats(stats);
    },

    onDestroy: function() {
        clearInterval(this.interval);
        monitorService.unSubscribe(this.monitorSubscriber);
        monitorService.setShouldLoop(false);
    },

    showTimeSelector: function() {
        const realTimeLicense = {
            variant: securityService.getSegmentationVariant(addonFeaturesKeys.MON_TIMESERIES_REPORT),
            licenseKey: addonFeaturesKeys.MON_TIMESERIES_REPORT,
            isFeatureEnabled: securityService.isSegmentationFeatureEnabled(addonFeaturesKeys.MON_TIMESERIES_REPORT)
        };

        const dataSource = [
            {
                id: "report_type_realTime",
                name: ReportType.Options.RealTime,
                value: ReportType.Options.RealTime
            },
            {
                id: "report_type_timeRange",
                name: ReportType.Options.TimeRange,
                value: ReportType.Options.TimeRange,
                customLicense: realTimeLicense
            }
        ];

        this.tooltipContent = new ReportType.View({
            model: this.model,
            pickers: dataSource,
            itemsAsObjects: true
        });

        this.tooltipContent.render();
        tooltipyo.show(this.tooltipContent.$el, this.$(".range-selection"), "bottom", {
            customClass: "monitor",
            hideCloseButton: true,
            onBeforeClose: utils.isDatePickerOpened
        });

        this.listenTo(this.tooltipContent, "timeRange:close", function() {
            tooltipyo.hide();
        });
    },

    _setErrorMessageVisible: function (visible) {
        try {
            this.ui.monitorApiErrors.css("display", visible ? "table" : "none");

            var display = visible ? "none" : "block";
            this.infopanel.$el.css("display", display);
            this.metrics.$el.css("display", display);
            this.clustertable.$el.css("display", display);
        } catch (error) { }
    },

    onRender: function() {
        let _this = this;
        _this.infopanel.show(
            new InfoPanel.View({
                model: _this.model.infopanel
            })
        );

        _this.metrics.show(
            new DataModule.View({
                model: _this.model.metrics
            })
        );

        _this.clustertable.show(
            new ClusterTable.View({
                model: _this.model.clustertable
            })
        );
        const {version, waclusterName } = propertyTemplateService.getServerInfo();
        _this.model.infopanel.set({
            clusterName: waclusterName,
            version: version
        });

        var eventsLogModel = new EventsLogModel();
        var eventsLogView = new EventsLogView({
            model: eventsLogModel
        });

        this.eventLogSidebar = new Sidebar.View({
            contentView: eventsLogView,
            parentView: this,
            customCssClass: "common-sidebar-event-log"
        });
    },

    setClusterStats(stats) {
        let _this = this;
        _this._setErrorMessageVisible(false);

        var appStats = _.filter(stats, function(item) {
            return (
                item &&
                item.type === "APPLICATION" &&
                //TODO implement it properly instead of "namedQueryeffb438"
                item.name.indexOf("namedQueryeffb438") === -1 &&
                //TODO is there any other way to filter out adhocqueries ?
                item.name.indexOf("adhocquery") === -1 &&
                globalApps.indexOf(item["full-name"]) === -1
            );
        });

        var serverStats = _.filter(stats, function(item) {
            return item && item.type === "SERVER";
        });

        var agentStats = _.filter(stats, function(item) {
            return item && item.type === "AGENT";
        });

        var kafkaStats = _.filter(stats, function(item) {
            return item && item.type === "kafka";
        });

        var esStats = _.filter(stats, function(item) {
            return item && item.type === "es";
        });

        var mostRecentKafkaData =
            kafkaStats && kafkaStats[0] ? kafkaStats[0]["most-recent-data"]["kafka-bytes-rate-long"] : 0;
        var mostRecentESData = esStats && esStats[0] ? esStats[0]["most-recent-data"]["es-size-bytes-rate"] : 0;
        var mostRecentData = serverStats[0]["most-recent-data"];

        var serverResult = overviewCalculator.calculateServerStats(serverStats, this.model);

        var appResult = overviewCalculator.calculateAppStats(appStats);
        var agentResult = overviewCalculator.calculateAgentStats(agentStats);
        var mostRecentDataResult = overviewCalculator.calculateMostRecentData(
            mostRecentData,
            mostRecentESData,
            mostRecentKafkaData
        );

        _this.model.infopanel.set({
            uptime: mostRecentDataResult.uptime,
            throughput: mostRecentDataResult.throughput,
            wactionsCnt: mostRecentDataResult.wactionsCnt,
            wactionsPerSecond: mostRecentDataResult.wactionsPerSecond,
            elasticMemoryFree: mostRecentDataResult.elasticMemoryFree,
            backpressuredImgClass: mostRecentDataResult.backpressuredImgClass,
            backPresssuredText: mostRecentDataResult.backPresssuredText,

            elasticMemoryThroughput: mostRecentDataResult.elasticMemoryThroughput,
            kafkaMemoryThroughput: mostRecentDataResult.kafkaMemoryThroughput,

            serverCount: serverResult.serverCount,
            memoryUsed: serverResult.memoryUsed,
            memoryUsedInt: serverResult.memoryUsedInt,
            memoryTotal: serverResult.memoryTotal,
            memoryTotalInt: serverResult.memory_max,
            nodesRunning: serverResult.nodesRunning,
            nodesDown: serverResult.nodesDown,
            classAlertNodes: serverResult.cssClass,
            nodesTotal: serverResult.nodesTotal,
            cpuRate: serverResult.cpu_rate,
            cpuCores: serverResult.total_cores,

            appsRunning: appResult.appsRunning,
            appsDown: appResult.appsDown,
            appsNotRunning: appResult.appsNotRunning,
            classAlertApps: appResult.cssClass,
            appsTotal: appResult.appsTotal,

            agentsRunning: agentResult.agentsRunning,
            agentsDown: agentResult.agentsDown,
            classAlertAgents: agentResult.cssClass,
            agentsTotal: agentResult.agentsTotal
        });

        if (agentStats.length > 0) {
            serverStats = serverStats.concat(agentStats);
        }
        var newApps = _.sortBy(_.clone(appStats), function(item) {
            return item["full-name"].toLowerCase();
        });
        var combinedObject = _this.prepareCombinedData(appStats);
        newApps.unshift(combinedObject);
        _this.model.metrics.set({
            apps: newApps,
            servers: _.sortBy(serverStats, function(item) {
                return item["full-name"].toLowerCase();
            })
        });

        _this.model.clustertable.set({
            appOverview: appStats,
            nodeOverview: serverStats
        });

        App.reqres.setHandler("monitor:max:memory", function() {
            return mostRecentData["memory-max"];
        });

        App.reqres.setHandler("monitor:total-cores", function() {
            return mostRecentData["cores"];
        });

        let total = 0;
        _.each(stats, function(stat) {
            try {
                if (!stat) return;
                let inputRate = 0;
                if (stat.type === "APPLICATION") {
                    inputRate = stat.stats["source-input"].diff;
                    if (inputRate) {
                        total += inputRate;
                    }
                }
            } catch (e) {}
        });
        _this.model.infopanel.set("totalInput", numeral(total).format("0 a"));
        NProgress.done();
    },

    prepareCombinedData: function(apps) {
        var obj = {
            "full-name": "All Applications",
            "time-series-data": {}
        };
        var timeSeriesAlldata = {};
        _.each(apps, function(appModel) {
            _.each(appModel["time-series-data"], function(value, key) {
                if (!timeSeriesAlldata[key]) {
                    timeSeriesAlldata[key] = [];
                }
                var seriesData = {
                    "full-name": appModel["full-name"],
                    data: appModel["time-series-data"][key]
                };
                timeSeriesAlldata[key].push(seriesData);
            });
        });
        obj["time-series-data"] = timeSeriesAlldata;
        return obj;
    },

    showEventsLog: function() {
        this.eventLogSidebar.toggle();
    }
});

export default Overview;
