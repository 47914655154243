export const styles = {
    heading: {
        color: ({ palette }) => `${palette.primary[700]} !important`,
        marginTop: "20px !important",
        marginBottom: "20px !important"
    },
    text: {
        color: "greyscale.700"
    },
    spacing: {
        marginBottom: "20px"
    },
    image: {
        width: "134px",
        height: "112px"
    }
};
