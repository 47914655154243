/**
 * For simple sources without any validation stuff. Straight up config wizards.
 */

import Backbone from 'backbone';
import baseSimpleSource from 'app/components/appwizard/component-wizards/wizards/source/base-simple-source/base-simple-source';


var module = {};
module.View = baseSimpleSource.View.extend({
    label: "S3 Reader",
    setAdapterProperties: function() {
        this.model.set('adapter', {
            handler: "Global.PROPERTYTEMPLATE.S3Reader",
            properties: {}
        });
    }
});
module.vent = new Backbone.Model();

export default module;
