import App from "app";
import ApplicationController from "lib/controllers/application_controller";
import api from "core/api/api";

var service = App.module("ConsoleQService", function (Service, App, Backbone, Marionette, $, _) {
    Service.Service = ApplicationController.extend({
        CONSOLE_PREFIX: "consoleQueue",
        initialize: function () {
            // If the API does not have a dispatcher yet, configure with a new one
            if (this.instance_id) {
                return;
            }

            if (!api.getDispatcher()) {
                api.config({
                    dispatcher: _.extend({}, Backbone.Events),
                });
            }
            this.setup();
        },
        setup: function () {
            var _this = this;
            var consoleId = this.CONSOLE_PREFIX + api.token;
            var triggerQueryData = function (event) {
                var uuid = event.queryID.uuidstring;
                // Normally we would pass only the fields we need from the event, but in this case
                // the event is the data object
                var eventId = uuid + ":data";
                App.vent.trigger(eventId, event);
            };

            var triggerStreamPreviewData = function (event) {
                var key = event.streamName;
                var nsName = key.split(":")[0];
                var name = key.split(":")[2];
                var eventID = nsName + "." + name + ":data";
                App.vent.trigger(eventID, event);
            };
            api.subscribe(consoleId).then(function(data) {
                _this.instance_id = data;

                _this.listenTo(api.getDispatcher(), consoleId + ":message", function (event) {
                    if (typeof event.queryID !== "undefined") {
                        triggerQueryData(event);
                    } else if (event.streamName !== undefined && typeof event.streamName === "string") {
                        triggerStreamPreviewData(event);
                    }
                });
            });
        },
        unsubscribe: function () {
            var _this = this;
            var consoleId = this.CONSOLE_PREFIX + api.token;

            var promise = api.unsubscribe(consoleId, this.instance_id);
            promise
                .then(function() {
                    _this.stopListening(api.getDispatcher(), consoleId + ":message");
                    _this.instance_id = "";
                })
                .fail(function () {
                    console.log("Failed to unsubsubscribe to console queue: ", consoleId);
                });
        },
    });
});

export default service;
