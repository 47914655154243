import React from "react";
import api from "core/api/api";
import { action, observable } from "mobx";
import GroupModel from "./group-model";
import SampleGroupsPropertiesProvider from "./sample-groups-properties-provider";
import growl from "app/components/common/growl";
import { StriimButton } from "@striim/striim-ui";
const Default_Group = "Default_Group";
const DefaultValidationState = "progress";

export default class AppGroupsStore {
    @observable groups = [];

    constructor(appGroupService) {
        this._appGroupService = appGroupService;
    }

    @action
    async fetch() {
        let groupModels = await this._appGroupService.list();
        groupModels = SampleGroupsPropertiesProvider.applySampleGroupsProperties(groupModels);

        const groups = groupModels.map(groupModel => {
            const group = new GroupModel();
            group.id = groupModel.id;
            group.name = groupModel.name;
            group.description = groupModel.description;
            group.collapsed = groupModel.collapsed;
            group.orderNumber = groupModel.orderNumber;
            group.groupType = groupModel.groupType;
            group.isDefaultGroup = groupModel.name === Default_Group;
            group.applications = groupModel.applications;
            group.validations = (groupModel.validations || []).map(validation => {
                return { name: validation, state: DefaultValidationState };
            });
            return group;
        });

        // ensure default group
        let defaultGroup = groupModels.filter(x => x.name === Default_Group);
        if (defaultGroup.length === 0) {
            const group = new GroupModel();
            group.name = Default_Group;
            group.description = "";
            group.collapsed = false;
            group.orderNumber = 100000;
            group.isDefaultGroup = true;
            group.applications = [];
            group.groupType = "INTERNAL";
            groups.push(group);
        }
        this.groups = groups;
    }

    @action getOrderedGroups() {
        function compare(a, b) {
            if (a.orderNumber < b.orderNumber) {
                return -1;
            }
            if (a.orderNumber > b.orderNumber) {
                return 1;
            }
            return 0;
        }

        if (!this.groups) {
            return [];
        }

        return this.groups.sort(compare);
    }

    @action
    edit(oldName, newName, newDescription) {
        return this._appGroupService
            .updateGroup(oldName, newName, newDescription)
            .then(updatedGroup => {
                const group = this.groups.find(x => x.name === oldName);
                group.name = updatedGroup.name;
                group.description = updatedGroup.description;
                this.groups = [...this.groups];
            })
            .catch(error => {
                throw error;
            });
    }

    @action
    create = async (name, description) => {
        const group = await this._appGroupService.createGroup(name, description);
        const theGroup = { ...group.attributes };
        this.groups = [...this.groups, theGroup];
        this.groups = this.getOrderedGroups();
    };

    @action
    moveUp = async name => {
        await this._appGroupService.moveUp(name);
        this._changeOrder(name, -1);
    };

    @action
    moveDown = async name => {
        await this._appGroupService.moveDown(name);
        this._changeOrder(name, 1);
    };

    @action
    _changeOrder = (name, moveStep) => {
        var orderedGroups = this.getOrderedGroups();

        const group = orderedGroups.filter(group => group.name === name)[0];
        if (!group) {
            return;
        }

        const groupIndex = orderedGroups.indexOf(group);
        const secondIndex = groupIndex + moveStep;
        if (secondIndex < 0 || secondIndex >= this.groups.length) {
            return;
        }

        const groupOrderNumber = group.orderNumber;
        orderedGroups[groupIndex].orderNumber = orderedGroups[secondIndex].orderNumber;
        orderedGroups[secondIndex].orderNumber = groupOrderNumber;
        this.groups = this.getOrderedGroups();
    };

    @action
    toggleCollapsed = async (name, collapsed) => {
        await this._appGroupService.toggleExpand(name, collapsed);
    };

    getAssignedAppIds = () => {
        return this.groups
            .map(x => {
                return x.applications ? x.applications.map(a => a.toString()) : [];
            })
            .reduce((a, b) => a.concat(b));
    };

    assignAppIdsToGroup = async (appIds, group) => {
        for (let i = 0; i < appIds.length; i++) {
            await this._appGroupService.assign(appIds[i], group.name, group.description);
        }
    };

    @action
    handleNonAssignedApps = async apps => {
        const defaultGroup = this.groups.find(group => group.isDefaultGroup);
        if (defaultGroup) {
            const assignedAppIds = this.getAssignedAppIds();
            const notAssignedAppIds = apps.filter(app => assignedAppIds.indexOf(app.id) === -1).map(x => x.id);
            if (notAssignedAppIds && notAssignedAppIds.length) {
                const updatedAppIds = [...defaultGroup.applications, ...notAssignedAppIds];
                await this.assignAppIdsToGroup(notAssignedAppIds, defaultGroup);
                defaultGroup.applications.push(...updatedAppIds);
            }
        }
    };

    filterAppsByGroup = (group, apps) => {
        if (!group) {
            return apps;
        }
        let result = apps.filter(app => group.applications.indexOf(app.id) !== -1);
        if (group.isDefaultGroup) {
            let assignedAppIds = this.getAssignedAppIds();
            // get all applications not assigned to groups
            let notAssignedAppIds = apps.filter(app => assignedAppIds.indexOf(app.id) === -1).map(x => x.id);
            if (notAssignedAppIds && notAssignedAppIds.length) {
                const defaultGroup = this.groups.find(group => group.isDefaultGroup);
                if (defaultGroup) {
                    const updatedAppIds = [...group.applications, ...notAssignedAppIds];
                    this.assignAppIdsToGroup(notAssignedAppIds, defaultGroup);
                    defaultGroup.applications.push(...updatedAppIds);
                    const assignedApps = apps.filter(app => updatedAppIds.indexOf(app.id) !== -1);
                    return assignedApps;
                }
            }
        }
        return result;
    };

    @action
    delete = async groupName => {
        await this._appGroupService.remove(groupName);
        const group = this.groups.filter(group => group.name === groupName)[0];
        if (!group) {
            return;
        }

        const groupIndex = this.groups.indexOf(group);
        this.groups.splice(groupIndex, 1);
        // set groups to refresh... splice should be enough
        this.groups = [...this.groups];
    };

    @action
    validateGroup = async groupName => {
        const group = this.groups.filter(group => group.name === groupName)[0];
        group.validations.forEach(validation => {
            validation.state = "intresting";
        });

        this.validationApiCall(groupName);
    };

    @action
    validationApiCall = async groupName => {
        const validations = this.groups.filter(group => group.name === groupName)[0].validations;
        const kafkaValidation = validations.filter(validation => validation.name === "kafka")[0];
        const postgresValidation = validations.filter(validation => validation.name === "postgres")[0];

        if (kafkaValidation) {
            api.isKafkaInstalled()
                .then(function(isInstalled) {
                    kafkaValidation.state = isInstalled ? "success" : "error";
                })
                .fail(function() {
                    kafkaValidation.state = "error";
                });
        }

        if (postgresValidation) {
            api.isPostgresInstalled()
                .then(function(isInstalled) {
                    postgresValidation.state = isInstalled ? "success" : "error";
                })
                .fail(function() {
                    postgresValidation.state = "error";
                });
        }
    };

    getGroupNameByAppId = id => {
        return this.getGroupByAppId(id).name;
    };

    getGroupByAppId = id => {
        return this.groups.filter(g => g.applications.indexOf(id) !== -1)[0];
    };

    @action
    assignToGroup = async (apps, groupName, groupDescription) => {
        if (apps.length === 0) {
            return;
        }
        // fetches the app's current group name
        let prevGroups = [];
        apps.map(app => {
            prevGroups.push({ app: app, from: this.getGroupNameByAppId(app.id) });
        });

        let assignToGroupError = null;
        for (let i = 0; i < apps.length; i++) {
            const app = apps[i];
            try {
                app.isFetching = true;
                await this._appGroupService.assign(app.id, groupName, groupDescription);
            } catch (error) {
                assignToGroupError = error;
            } finally {
                app.isFetching = false;
            }
        }
        if (assignToGroupError) {
            throw assignToGroupError;
        }
        const undoButton = (
            <StriimButton
                variant="text"
                data-test-id="undo-button"
                onClick={() => {
                    this.moveBackToGroups(prevGroups);
                }}
            >
                Undo
            </StriimButton>
        );

        growl.info(
            `Moved ${apps.length} ${apps.length > 1 ? "Apps" : "App"} to ${groupName}`,
            null,
            null,
            null,
            undoButton,
            true
        );
        await this.fetch();
    };

    @action
    moveBackToGroups = async apps => {
        let assignToGroupError = null;
        for (let i = 0; i < apps.length; i++) {
            const app = apps[i];
            try {
                app.app.isFetching = true;
                await this._appGroupService.assign(app.app.id, app.from);
            } catch (error) {
                assignToGroupError = error;
            } finally {
                app.app.isFetching = false;
            }
        }
        if (assignToGroupError) {
            throw assignToGroupError;
        }
        growl.info(`Moving ${apps.length > 1 ? "Apps" : "App"} undone`, null, null, null);
        await this.fetch();
    };
}
