import React, { useMemo } from "react";
import { getIconSpritePosition, position } from "./adapter-native-icon-utils";
import { Box } from "@mui/material";
import { NewRelease } from "../customIcons";

interface AdapterNativeIconProps {
    name: string;
    type: string;
    tooltip: string;
    size: number;
    spriteId?: string;
    newlyReleased: boolean;
    showBorder?: boolean;
}

const styles = {
    iconContainer: (size, position, showBorder) => ({
        width: size === 56 ? "54px" : `${size}px`,
        height: size === 56 ? "54px" : `${size}px`,
        border: showBorder ? ({ palette }) => `1px solid ${palette.greyscale[200]}` : "",
        borderRadius: `calc(14 * (${size} / 56) * 1px)`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: position ? `url('/src/generic/icon/component-icon/images/adapters-native-icons.png')` : "",
        backgroundSize: `calc(560 * (${size} / 56) * 1px)`,
        backgroundPosition: position
            ? `calc((${position.x} + 2) * (${size} / 56) * -1px) calc((${position.y} + 2) * (${size} / 56) * -1px)`
            : "",
        position: "relative"
    }),
    newlyReleaseMarker: size => ({
        position: "absolute",
        width: size === 56 ? "19px" : `calc(19 * (${size} / 56) * 1px)`,
        height: size === 56 ? "19px" : `calc(19 * (${size} / 56) * 1px)`,
        borderRadius: "50%",
        top: size === 56 ? "-6px" : `calc(6 * (${size} / 56) * -1px)`,
        right: size === 56 ? "-6px" : `calc(6 * (${size} / 56) * -1px)`,
        backgroundColor: "primary.100",
        border: ({ palette }) => `1px solid ${palette.primary[200]}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    })
};

const AdapterNativeIcon: React.FunctionComponent<AdapterNativeIconProps> = ({
    name,
    type,
    tooltip,
    size = 56,
    spriteId,
    newlyReleased = false,
    showBorder = true
}) => {
    const position: position = useMemo(() => getIconSpritePosition(name, spriteId), [name, spriteId]);
    return (
        <Box sx={styles.iconContainer(size, position, showBorder)}>
            {newlyReleased && (
                <Box sx={styles.newlyReleaseMarker(size)}>
                    <NewRelease />
                </Box>
            )}
        </Box>
    );
};

export default AdapterNativeIcon;
