import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { StriimTypography, StriimFormBuilder, FIELD_TYPES, StriimModal, StriimMessageBox } from "@striim/striim-ui";
import Grid from "@mui/material/Grid";
import { Box, InputAdornment } from "@mui/material";
import _ from "underscore";
import { CPInlineDocContext } from "./create-edit-connection-profile";
import KeyIcon from "src/generic/icon/manage-striim/key.svg";
import WarningIcon from "src/generic/icon/manage-striim/warning-triangle.svg";
import ProgressWait from "src/generic/icon/manage-striim/progress-wait.svg";
import DataSource from "../../../../../../../app/components/common/editor/control/property-template/property-template-datasource";
import { styles } from "./create-edit-connection-profile.styles";
import {
    endpointFields,
    getOAuthResponseFormatted,
    getOAuthURL,
    getOAuthFieldValues,
    setButtonVisibility
} from "../utils";
import connectionProfileService from "../connection-profile-service";
import { mapOptions } from "../../utils";
import growl from "../../../../../../../app/components/common/growl";
import Password from "../../../../../../generic/fd-password/fd-password";
import TestConnnectionInForm from "../components/test-connection-in-form";
import { OAUTH_NAMES, OPTIONAL_OAUTH_FIELDS } from "../constants";
import { PRESERVED_NAMES } from "core/constants";
import propertyTemplateService from "../../../../../../../core/services/metaStoreService/property-template-service";
import getControlResource from "../../../../../../../app/components/common/editor/control-resource-resolver.js";
import FormMessageBox from "../components/form-message-box/form-message-box";
import CPActionButton from "../components/cp-action-button/cp-action-button";
import FileBrowser from "../../../../../../generic/filebrowser-v2/filebrowser";
import JsonInput from "../../../../../../generic/json-input/json-input";
import { Endpoint } from "../create-edit-connection-profile/create-edit-connection-profile";
import SelectWorkspaceInputField from "../../../../../../generic/form-builder-custom-fields/select-workspace/select-workspace";

interface IEndpointChangeParams {
    connectionProfileName: string;
    namespace: string;
    endpointName: Endpoint;
}

interface ConnectionProfileFieldsProps {
    setIsValid: (boolean) => void;
    dataForEdit?: any;
    formRef: React.RefObject<HTMLFormElement>;
    oauthRef: any;
    endPoints: {
        label: string;
        value: string;
        icon: string;
    }[];
    fdEndpoint?: string;
    isCurrentViewFD?: boolean;
}

interface TestConnectionResponseType {
    result: boolean;
    message: string;
    jsonErrorDescription?: {
        "Root Cause"?: string;
        "Suggested Action"?: string;
        Summary?: string;
        "Documentation Link"?: string;
    };
}

const ConnectionProfileFields = ({
    setIsValid,
    dataForEdit = null,
    formRef,
    oauthRef,
    endPoints,
    fdEndpoint,
    isCurrentViewFD = false
}: ConnectionProfileFieldsProps) => {
    let oldData;
    const isEditMode = !!dataForEdit;

    const initialValues = dataForEdit ? dataForEdit : null;
    const OauthbuttonRef = useRef(null);
    const signedInTextRef = useRef(null);
    const signInFailedTextRef = useRef(null);
    const notSignedInTextRef = useRef(null);
    const errorRef = useRef();

    const [connectionFieldsState, setConnectionFieldsState] = useState([]);
    const OAuthFieldsRef = useRef([]);
    const [isEndpointConfigurationsValid, setIsEndpointConfigurationsValid] = useState(!!dataForEdit);
    const [isConfigurationsValid, setIsConfigurationsValid] = useState<boolean>(false);
    const [isOAuthValid, setIsOAuthValid] = useState<boolean | null>(null);
    const [isAssociatedAppDeployed, setIsAssociatedAppDeployed] = useState<boolean>(false);
    const [namespace, setNamespace] = useState([]);
    const [isOAuthSelected, setIsOAuthSelected] = useState<boolean>(false);
    const [isTestInProgress, setIsTestInProgress] = useState<boolean>(false);
    const [testConnectionResponse, setTestConnectionResponse] = useState<TestConnectionResponseType[] | []>([]);
    const [authrResponse, setAuthrResponse] = useState<string>();
    const [showOAuthInProgressModal, setShowOAuthInProgressModal] = useState<boolean>(false);
    const previousOAuthValues = useRef({});
    const passwordFieldsRef = useRef({});
    const prevValuesRef = useRef();

    setButtonVisibility(signedInTextRef, !!isOAuthValid);
    setButtonVisibility(signInFailedTextRef, isOAuthValid === false);
    const { setEndPoint } = useContext(CPInlineDocContext);

    useEffect(() => {
        const checkIfCpAppsDeployed = async () => {
            try {
                const result = await connectionProfileService.checkCpsAppsAreDeployed(
                    dataForEdit.namespace?.value + "." + dataForEdit.connectionProfileName
                );
                setIsAssociatedAppDeployed(result);
                loadCPFormFields(dataForEdit.endpointName?.value, result);
                const OAuthValues = OAuthFieldsRef.current?.reduce((previous, current) => {
                    previous[current] = dataForEdit[current];
                    return previous;
                }, {});
                previousOAuthValues.current = OAuthValues;
            } catch (error) {
                //@ts-ignore
                growl.error(error);
            }
        };

        if (fdEndpoint) {
            loadCPFormFields(fdEndpoint);
        } else if (isEditMode && dataForEdit) {
            checkIfCpAppsDeployed();
        }
        if (initialValues) {
            oauthRef.current = getOAuthFieldValues(initialValues);
        }
        setButtonVisibility(notSignedInTextRef, false);
    }, []);

    useEffect(() => {
        const isFormValid =
            isEndpointConfigurationsValid && isConfigurationsValid && (isOAuthSelected ? isOAuthValid : true);
        setIsValid(isFormValid);
    }, [isEndpointConfigurationsValid, isConfigurationsValid, isOAuthSelected, isOAuthValid]);

    useEffect(() => {
        async function fetchData() {
            const nsData = await connectionProfileService.getNamespaces();
            setNamespace(mapOptions(nsData));
        }

        if (!namespace.length) {
            fetchData();
        }
    }, [namespace]);

    useEffect(() => {
        if (isOAuthSelected && oauthRef?.current?.access_token) {
            setIsOAuthValid(true);
            setButtonVisibility(signedInTextRef, true);
            setButtonVisibility(notSignedInTextRef, false);
        }
    }, [isOAuthSelected, oauthRef?.current]);

    const handleSignin = async () => {
        let values;
        try {
            oauthRef.current = {};
            values = formRef.current.connectionRef.current?.values;
            const idp = values?.idp;
            const authrHostURL = await connectionProfileService.getAuthrHostURL();
            const url = getOAuthURL(
                authrHostURL,
                OAuthFieldsRef.current,
                idp,
                values,
                passwordFieldsRef.current?.values
            );
            const response = await connectionProfileService.signIn(idp, url, authrHostURL, setShowOAuthInProgressModal);

            if (response?.AUTHR_ERROR_CODE) {
                const endpoint = response?.IS_IDP_ERROR ? idp : "authr_error";
                const errorResponse = await connectionProfileService.handleErrorMessagesForCP(
                    endpoint,
                    JSON.stringify(response)
                );
                setAuthrResponse(JSON.parse(errorResponse));
                setIsOAuthValid(false);
                setButtonVisibility(notSignedInTextRef, false);
                previousOAuthValues.current = {};
                errorRef?.current?.scrollIntoView({ behavior: "smooth" });
            } else {
                const formattedProperties = getOAuthResponseFormatted(response);
                oauthRef.current = { ...formattedProperties };
                setIsOAuthValid(true);
                setAuthrResponse(null);
                setButtonVisibility(notSignedInTextRef, false);
                const OAuthValues = OAuthFieldsRef.current?.reduce((previous, current) => {
                    previous[current] = values[current];
                    return previous;
                }, {});
                previousOAuthValues.current = OAuthValues;
            }
        } catch (error) {
            //@ts-ignore
            growl.error(error);
            setIsOAuthValid(false);
            setButtonVisibility(notSignedInTextRef, false);
            previousOAuthValues.current = {};
        }
    };

    const disableImmutableFields = (CPFormFields, isAssociatedAppsDeployed) => {
        CPFormFields.forEach(item => {
            item.disabled = isAssociatedAppsDeployed ? !item.alterable : false;
        });
    };

    const loadCPFormFields = (currentEndpoint, isAssociatedAppDeployed = false) => {
        let CPFormFields = [];
        let propertyTemplatesStore = DataSource("connectionprofile");
        const endpointFields = propertyTemplatesStore.find(
            data => data.id === `Global.PROPERTYTEMPLATE.${currentEndpoint}`
        );
        if (endpointFields) {
            CPFormFields = [...endpointFields?.refModel.attributes.propertyMap];
            CPFormFields = JSON.parse(JSON.stringify(endpointFields?.refModel.attributes.propertyMap));
            const passwordFields = CPFormFields.filter(
                item =>
                    [
                        "com.webaction.security.Password",
                        FIELD_TYPES.PASSWORD,
                        FIELD_TYPES.PROPERTYTEMPLATE_PASSWORD
                    ].includes(item.type) && item.visibility !== "false"
            ).map(item => item.name);
            passwordFieldsRef.current["values"] = passwordFields;
            CPFormFields.forEach(property => {
                if (!property.required && property.conditionalRequired) {
                    property.required = property.conditionalRequired;
                }
                if (property.type === "com.webaction.security.Password" || property.type === FIELD_TYPES.PASSWORD) {
                    property.type = FIELD_TYPES.PROPERTYTEMPLATE_PASSWORD;
                }
            });

            if (isEditMode) {
                disableImmutableFields(CPFormFields, isAssociatedAppDeployed);
            }
            let buttons = _.filter(propertyTemplateService.propertyActionButtons, function(item) {
                return item.adapterName === currentEndpoint;
            });
            setOAuthRelatedFields(buttons);
            addOAuthButton(CPFormFields, buttons);
        }
    };

    const setOAuthRelatedFields = buttons => {
        const signInButton = buttons.find(button => button.name === "SIGNIN_WITH_OAUTH");
        if (signInButton) {
            OAuthFieldsRef.current = signInButton.parameters;
        }
    };

    const addOAuthButton = (CPFormFields, buttons) => {
        CPFormFields = CPFormFields.map(field => {
            let controlResource = getControlResource(field?.name);
            return {
                ...field,
                label: controlResource?.name
            };
        });
        const authenticationObj = CPFormFields.find(obj => {
            if (obj.name === "authenticationType") {
                if (obj.values && typeof obj.values === "object") {
                    return obj.values.some(value => {
                        OAUTH_NAMES.includes(value);
                        return value;
                    });
                } else {
                    //single authentication type
                    return OAUTH_NAMES.includes(obj.defaultValue);
                }
            }
        });

        if (authenticationObj) {
            const buttonText = authenticationObj.values?.length
                ? authenticationObj.values
                      .filter(value => typeof value === "string" && OAUTH_NAMES.includes(value))
                      .toString()
                : authenticationObj?.defaultValue.toString();

            buttons.forEach(function(button) {
                const { disabled, group, viewWeight, visibility } = button;
                CPFormFields.push({
                    disabled,
                    group,
                    viewWeight,
                    visibility,
                    required: true,
                    type: "component",
                    component: (
                        <CPActionButton
                            button={button}
                            OauthbuttonRef={OauthbuttonRef}
                            signedInTextRef={signedInTextRef}
                            signInFailedTextRef={signInFailedTextRef}
                            notSignedInTextRef={notSignedInTextRef}
                            handleSignin={handleSignin}
                            buttonText={buttonText}
                            formRef={formRef}
                        />
                    ),
                    isActionBtn: true
                });
            });

            if (isEditMode) {
                const passwordFields = CPFormFields.filter(
                    item =>
                        OAuthFieldsRef.current?.includes(item.name) &&
                        item.type === FIELD_TYPES.PROPERTYTEMPLATE_PASSWORD &&
                        item.visibility !== "false"
                ).map(item => item.name?.replace(/([a-z])([A-Z])/g, "$1 $2")?.toLowerCase());
                if (passwordFields.length) {
                    const passwordFieldNames = passwordFields.join(", ");
                    CPFormFields.splice(1, 0, {
                        name: "InfoMessageBox",
                        type: FIELD_TYPES.COMPONENT,
                        component: (
                            <StriimMessageBox
                                type="NOTIFICATION"
                                text={`To re-authenticate without changes, or when editing ${buttonText} related fields, re-enter the ${passwordFieldNames}.`}
                            />
                        ),
                        viewWeight: 0,
                        visibility: `'{{properties.authenticationtype}}'==='${buttonText}'`
                    });
                }
            }
        }
        setConnectionFieldsState(CPFormFields);
    };

    const handleEndPointChange = (values: IEndpointChangeParams) => {
        const endpoint: string = values?.endpointName?.value;
        setEndPoint(endpoint ? endpoint.replace(/\s+/g, "-").toLowerCase() : "");
        if (oldData != endpoint) {
            oldData = endpoint;
            loadCPFormFields(endpoint);
            //reset oauth state
            oauthRef.current = {};
            setIsOAuthValid(null);
            setAuthrResponse(null);
            previousOAuthValues.current = {};
            setTimeout(() => setButtonVisibility(signInFailedTextRef, false), 0);
        }
        setTestConnectionResponse([]);
    };

    //disable signin when any oauth values is empty, clear tokens when oauth values changes
    const checkForChangeInOAuthValues = values => {
        const OAuthProperties = Object.keys(previousOAuthValues?.current) || [];
        if (previousOAuthValues?.current && OAuthProperties.length) {
            const hasAnyOAuthValueChanged = OAuthProperties.some(
                key => values.hasOwnProperty(key) && previousOAuthValues.current[key] !== values[key]
            );
            if (hasAnyOAuthValueChanged) {
                previousOAuthValues.current = {};
                oauthRef.current = {};
                setIsOAuthValid(null);
                setButtonVisibility(signedInTextRef, false);
                setButtonVisibility(notSignedInTextRef, false);
            }
        }
    };

    const handleConfigChange = values => {
        const selectedEndpoint = isCurrentViewFD
            ? fdEndpoint ?? formRef?.current?.endPointRef?.current?.values?.endpointName?.value
            : formRef?.current?.endPointRef?.current?.values?.endpointName?.value;
        const optionalOAuthFields = OPTIONAL_OAUTH_FIELDS[selectedEndpoint];
        const areAllValuesFilled = OAuthFieldsRef.current
            ?.filter(key => !optionalOAuthFields || !optionalOAuthFields.includes(key))
            .every(key => typeof values[key] === "string" && values[key]?.length > 0);
        checkForChangeInOAuthValues(values);
        if (OauthbuttonRef && OauthbuttonRef.current) {
            setButtonVisibility(
                notSignedInTextRef,
                areAllValuesFilled &&
                    !oauthRef?.current?.access_token &&
                    signInFailedTextRef?.current?.style?.display === "none"
            );

            OauthbuttonRef.current.disabled = !areAllValuesFilled;
        }
        if (values?.authenticationType) {
            const currentAuthenticationType =
                typeof values?.authenticationType !== "object"
                    ? values?.authenticationType
                    : values?.authenticationType?.value;
            if (!OAUTH_NAMES.includes(currentAuthenticationType)) {
                oauthRef.current = {};
                setIsOAuthValid(null);
                setIsOAuthSelected(false);
                previousOAuthValues.current = {};
            } else {
                setIsOAuthSelected(true);
                setButtonVisibility(signedInTextRef, !!oauthRef?.current?.access_token);
            }
        } else {
            setIsOAuthSelected(false);
        }

        
        if ((endPoints?.length == 1 && endPoints[0]?.value === "FabricMirror") || formRef?.current?.endPointRef?.current?.values?.endpointName?.value === "FabricMirror") {
            const connectionRef = formRef?.current?.connectionRef;
            const connectionData = connectionRef?.current?.values;

            function setWorkSpaceEmpty(): void {
                if (!!connectionData?.workspaceEntity) {
                    connectionRef.current.setFieldValue("workspaceEntity", null);
                }
            }
            function isEmpty(value) {
                return value === undefined || value === null || value === "";
            }
            if (
                (!isEmpty(prevValuesRef?.current?.Username) &&
                    prevValuesRef?.current?.Username !== connectionData?.Username) ||
                (!isEmpty(prevValuesRef?.current?.Password) &&
                    prevValuesRef?.current?.Password !== connectionData?.Password)
            ) {
                setWorkSpaceEmpty();
            }
            prevValuesRef.current = connectionData;
        }
        setAuthrResponse(null);
        setTestConnectionResponse([]);
    };

    const handleTestClick = async () => {
        setIsTestInProgress(true);
        try {
            const properties = {
                ...formRef?.current?.connectionRef?.current?.values,
                authenticationType:
                    values.authenticationType && typeof values.authenticationType !== "object"
                        ? values.authenticationType
                        : values.authenticationType?.value,
                ...oauthRef?.current
            };
            for (const key in properties) {
                properties[key] =
                    typeof properties[key] === "string" && !passwordFieldsRef?.current?.values?.includes(key)
                        ? properties[key].trim()
                        : properties[key];
            }
            const endpoint = isCurrentViewFD
                ? fdEndpoint ?? formRef?.current?.endPointRef?.current?.values?.endpointName?.value
                : formRef?.current?.endPointRef?.current?.values?.endpointName?.value;
            let result;
            if (isEditMode) {
                result = await connectionProfileService.testExistingConnectionProfileWithEncryptedProperties(
                    properties,
                    endpoint,
                    dataForEdit.namespace?.value + "." + dataForEdit.connectionProfileName
                );
            } else {
                result = await connectionProfileService.testNewConnectionProfile(properties, endpoint);
            }

            setTestConnectionResponse(result && JSON.parse(result));
        } catch (error) {
            setTestConnectionResponse(error);
        } finally {
            errorRef?.current?.scrollIntoView({ behavior: "smooth" });
            setIsTestInProgress(false);
        }
    };

    const values = { ...formRef?.current?.connectionRef?.current?.values };

    const isTestButtonEnabled =
        isEndpointConfigurationsValid &&
        isConfigurationsValid &&
        (isOAuthSelected ? isOAuthValid : true) &&
        !isTestInProgress;

    const hasAnyVisibleNonAlterableField = connectionFieldsState.some(
        item => item.alterable === false && item.visibility !== "false"
    );

    const endpointConfigurationFields = useMemo(() => endpointFields(fdEndpoint, namespace, endPoints, isEditMode), [
        namespace
    ]);

    return (
        <>
            <Grid data-test-id="create-connection-profile-container">
                <Grid item xs={isCurrentViewFD ? 12 : 9} mb={2} ref={errorRef}>
                    {Array.isArray(testConnectionResponse) &&
                        testConnectionResponse?.map(
                            (response, currentIndex) =>
                                !response.result &&
                                !response.hide && (
                                    <FormMessageBox
                                        isAuthrResponse={false}
                                        text={`${response.apiName} Failed`}
                                        description={JSON.parse(response.jsonErrorDescription)}
                                        options={{
                                            warning: response.warning,
                                            onClose: () => {
                                                setTestConnectionResponse(current => [
                                                    ...current.slice(0, currentIndex),
                                                    {
                                                        ...response,
                                                        hide: true
                                                    },
                                                    ...current.slice(currentIndex + 1)
                                                ]);
                                            }
                                        }}
                                    />
                                )
                        )}
                    {authrResponse && (
                        <FormMessageBox
                            isAuthrResponse={true}
                            text={authrResponse}
                            options={{
                                onClose: () => setAuthrResponse(null)
                            }}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    xs={10}
                    md={8}
                    lg={6}
                    sx={[styles.grid, isCurrentViewFD && styles.fullWidth, isCurrentViewFD && styles.subForm]}
                >
                    <StriimTypography variant="h3" display={isCurrentViewFD ? "none" : "block"}>
                        1. Endpoint Configurations
                    </StriimTypography>

                    <Box sx={[!isCurrentViewFD && styles.box]}>
                        <StriimFormBuilder
                            initialFieldValues={initialValues}
                            onChange={handleEndPointChange}
                            data={endpointConfigurationFields}
                            formRef={formRef.current.endPointRef}
                            setFormValid={setIsEndpointConfigurationsValid}
                            validateOnChange={true}
                        />
                    </Box>
                </Grid>

                <Grid item xs={4} sx={[isCurrentViewFD && styles.fullWidth]} mb={1}>
                    {isAssociatedAppDeployed ? (
                        <Box sx={[!isCurrentViewFD && styles.box]}>
                            <StriimMessageBox
                                type="WARNING"
                                text={`This connection profile has an active associated app(s). ${
                                    hasAnyVisibleNonAlterableField ? "Some fields are unavailable for editing. " : ""
                                }${
                                    isOAuthSelected
                                        ? "If you change any OAuth related field, it removes the current authentication."
                                        : ""
                                }`}
                                customCardStyles={styles.messageBox}
                                customIcon={<WarningIcon />}
                            />
                        </Box>
                    ) : null}
                    <StriimTypography
                        variant="h3"
                        display={isCurrentViewFD || connectionFieldsState.length < 1 ? "none" : "block"}
                    >
                        2. Connection Properties
                    </StriimTypography>
                    <Box sx={[!isCurrentViewFD && styles.box]}>
                        <StriimFormBuilder
                            initialFieldValues={initialValues}
                            onChange={handleConfigChange}
                            data={connectionFieldsState}
                            formRef={formRef.current.connectionRef}
                            setFormValid={setIsConfigurationsValid}
                            validateOnChange={true}
                            customFields={{
                                [FIELD_TYPES.PROPERTYTEMPLATE_PASSWORD]: props => (
                                    <Password
                                        previewPassword={false}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment>
                                                    <KeyIcon style={{ fontSize: 20, fill: "none" }} />
                                                </InputAdornment>
                                            )
                                        }}
                                        {...props}
                                    />
                                ),
                                [FIELD_TYPES.FILE]: props => <FileBrowser directoryOnly={false} {...props} />,
                                [FIELD_TYPES.JSON]: props => <JsonInput {...props} />,
                                [FIELD_TYPES.WORKSPACESELECT]: props => <SelectWorkspaceInputField {...props} />
                            }}
                            preservedNames={PRESERVED_NAMES}
                        />
                    </Box>
                </Grid>
                {connectionFieldsState.length > 0 ? (
                    <TestConnnectionInForm
                        isCurrentViewFD={isCurrentViewFD}
                        isTestButtonEnabled={isTestButtonEnabled}
                        isTestInProgress={isTestInProgress}
                        onTestClick={handleTestClick}
                        testConnectionResponse={testConnectionResponse}
                    />
                ) : null}
            </Grid>
            <StriimModal
                isVisible={showOAuthInProgressModal}
                autoHeight
                confirmContent={false}
                cancelContent={false}
                titleContent={<StriimTypography variant="h2">OAuth Launched in next tab</StriimTypography>}
                footerContent={<></>}
                sx={styles.modal}
            >
                <Grid container gap={2.5}>
                    <Grid container item gap={1} mt={1} alignItems={"center"}>
                        <ProgressWait style={{ fontSize: 20, fill: "none" }} />
                        <StriimTypography variant="h3">Authentication in Progress</StriimTypography>
                    </Grid>
                    <StriimTypography variant="body" sx={styles.modalContent} color="greyscale.800">
                        Please close the next tab once authentication completed
                    </StriimTypography>
                </Grid>
            </StriimModal>
        </>
    );
};

export default ConnectionProfileFields;
