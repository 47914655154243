import NamespaceValidation from "src/modules/dashboards/pages/dashboard-list/components/add-dashboard-dialog/components/namespace-select/namespace.validation";
import * as Yup from "yup";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import CONSTANTS from "../app-list/resources";

// App Name validation
const nameSyncValidation = Yup.string()
    .required("Value for name is required")
    .test("noSpaces", "Please enter a name without spaces.", haveNoSpacesValidation)
    .test(
        "onlyAlphanumeric",
        "Please enter a name with only alphanumeric or underscore characters.",
        onlyAlphanumericValidation
    )
    .test("cannotStartWithaNumber", "Name cannot start with a number", doesNotStartWithaNumberValidation)
    .test(
        "cannotBeLongerThan200Chars",
        "Name cannot be longer than 200 characters",
        notLongerThan200CharactersValidation
    );

const nameFullValidation = entityType =>
    nameSyncValidation.test("nameAlreadyExistsAsync", "This name already exists", async function(name) {
        return await nameAlreadyExistsValidation(name, entityType, this.parent);
    });

// Group Name validation
const groupNameSyncValidation = Yup.string()
    .required("Value for name is required")
    .test("noSpaces", CONSTANTS.VALIDATION_MSG.NO_SPACES_ALLOWED, haveNoSpacesValidation)
    .test("onlyAlphanumeric", CONSTANTS.VALIDATION_MSG.NO_SPL_CHARS_ALLOWED, onlyAlphanumericValidation)
    .test(
        "cannotStartWithaNumber",
        CONSTANTS.VALIDATION_MSG.NO_NUMBER_FIRST_CHARACTER_ALLOWED,
        doesNotStartWithaNumberValidation
    )
    .test(
        "cannotBeLongerThan200Chars",
        CONSTANTS.VALIDATION_MSG.NO_LONGER_STRINGS_ALLOWED,
        notLongerThan200CharactersValidation
    );

const groupNameValidation = entityType =>
    groupNameSyncValidation
        .test("groupNameExists", CONSTANTS.VALIDATION_MSG.NO_DUPLICATES_ALLOWED, async function(name) {
            return await groupAlreadyExists(name, this.parent.nsName);
        })
        .test("AppsExist", async function(name) {
            return nameAlreadyExistsValidation(name, entityType, this.parent, true).then(response =>
                typeof response === "string" ? this.createError({ message: response }) : response
            );
        });

const getNameValidation = (createFromScratch, entityType, isILCDC = false) => {
    if (isILCDC) return groupNameValidation(entityType);
    if (createFromScratch) {
        return nameFullValidation(entityType);
    }

    return nameSyncValidation;
};

export default nameFullValidation;

export { getNameValidation };

function notLongerThan200CharactersValidation(value) {
    return value && value.length <= 200;
}

function haveNoSpacesValidation(value) {
    return value && value.indexOf(" ") === -1;
}

function onlyAlphanumericValidation(value) {
    const nonAlphanumericOrUnderscore = /\W/;
    return !nonAlphanumericOrUnderscore.test(value);
}

function doesNotStartWithaNumberValidation(value) {
    return isNaN(parseInt(value));
}

const hasElement = (array, name, nsName) =>
    array.some(
        item =>
            item.get("name").toLowerCase() === name.toLowerCase() &&
            item.get("nsName").toLowerCase() === nsName.toLowerCase()
    );

async function nameAlreadyExistsValidation(name, entityType, parent, isILCDC = false) {
    if (!nameSyncValidation.isValidSync(name)) {
        return false;
    }

    const nsName = parent.nsName;
    if (!NamespaceValidation.isValidSync(nsName)) {
        return true;
    }
    const AppsList = await metaStoreService.fetchCollection(metaStoreService.entities[entityType]);
    if (isILCDC) {
        if (hasElement(AppsList, name + "_IL", nsName)) return "IL App already exists.";
        if (hasElement(AppsList, name + "_CDC", nsName)) return "CDC App already exists.";
        return true;
    }
    return !hasElement(AppsList, name, nsName);
}

function groupAlreadyExists(groupName, nsName) {
    return new Promise(resolve => {
        metaStoreService
            .findById(nsName + "." + metaStoreService.entities.GROUP + "." + groupName)
            .then(item => {
                resolve(item ? false : true);
            })
            .fail(() => {
                resolve(false);
            });
    });
}
