import template from "./toggle-content.html";
import Checkbox from "../checkbox/checkbox";

export default function({ label, toggleContent, description }) {
    return _.extend({}, Checkbox, {
        View: Checkbox.View.extend({
            template: _.template(template),

            ui: _.extend({}, Checkbox.View.prototype.ui),

            initialize: function() {
                var _this = this;
                this.listenTo(this.model, "change:value", function() {
                    _this.showHideContent();
                });
            },

            showHideContent: function() {
                toggleContent.slideToggle("fast");
            },

            onRender: function() {
                Checkbox.View.prototype.onRender.call(this);
                this.$("#checkbox-label").text(label);
                this.$("#description").text(description);
                if (this.getValue()) {
                    toggleContent.show();
                } else {
                    toggleContent.hide();
                }
            }
        })
    });
}
