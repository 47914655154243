import React, { useMemo } from "react";
import { StriimDataTable, StriimTypography } from "@striim/striim-ui";
import { observer } from "mobx-react-lite";

import useProgressStore from "src/stores/table-progress/useProgressStore";
import dateLib, { timezone } from "core/utils/date-utils";

import TableDetailsMui from "./Components/table-summary/table-summary";

const columns = [
    {
        field: "name",
        headerName: "Adapter Name",
        flex: 1,
        resizable: true,
        sortable: true
    },
    {
        headerName: `Latest IO Time (${timezone})`,
        valueGetter: function({ data: entry }) {
            return dateLib(entry.time).format("MMM DD, YYYY h:mm a ");
        },
        flex: 1,
        resizable: true
    }
];

const AdapterIOTimesTable = observer(({ tableProgressStore }) => {
    return (
        <div>
            <StriimDataTable
                data={Object.values(tableProgressStore.IOTimes)}
                columns={columns}
                sortable={true}
                filterable={true}
                animateRows="true"
            />
        </div>
    );
});

const TableProgressTable = function({ appType }) {
    const tableProgressStore = useProgressStore();
    return (
        <div data-test-id={"table-progress-container"}>
            <TableDetailsMui store={tableProgressStore} appType={appType} />
        </div>
    );
};

export const AdapterIOTimes = function() {
    const tableProgressStore = useProgressStore();
    return (
        <>
            <div data-test-id={"adapter-io-times"}>
                <StriimTypography variant="h4" sx={{ color: "primary.700", mb: "16px !important" }}>
                    Latest I/O time
                </StriimTypography>
                <AdapterIOTimesTable tableProgressStore={tableProgressStore} />
            </div>
        </>
    );
};

export default TableProgressTable;
