import { validationUtils } from "../validation-utils";
import { commonDWHUtils } from "./common-utils";

export class BigQueryUtils {
    adapterProperties: any;
    formData: any;
    sourceType: string;
    isAutomatedILCDC: boolean;
    isCDC: boolean;
    columnMappingSuffix: string;

    constructor(adapterProperties: any, formData: any, sourceType: string, isAutomatedILCDC: boolean, isCDC: boolean) {
        this.adapterProperties = adapterProperties;
        this.formData = formData;
        this.sourceType = sourceType;
        this.isAutomatedILCDC = isAutomatedILCDC;
        this.isCDC = isCDC;
        this.columnMappingSuffix = commonDWHUtils.getColumnMappingSuffix(sourceType, isCDC);
    }
    computeMode() {
        this.adapterProperties["Mode"] = this.isCDC ? this.formData["Mode"] : "APPENDONLY";
        // For all App adapter source -> DWH target, Mode should be merge
        if (!this.isAutomatedILCDC && validationUtils.isAppAdapter(this.sourceType)) {
            this.adapterProperties["Mode"] = commonDWHUtils.getModeForAppAdapters();
        }
    }
    addColumnMapping(tables: string, dataset: string = null) {
        const parts = tables.split(";");

        if (commonDWHUtils.isSourceSalesforce(this.sourceType)) {
            return `%,${dataset}.% ${this.columnMappingSuffix}`;
        }

        if (!dataset) {
            return parts.map(part => (part.trim() ? `${part} ${this.columnMappingSuffix}` : "")).join(";");
        } else {
            return parts
                .map(part => {
                    let schemaName = part.split(".")[0];
                    return `${schemaName}.%,${dataset}.% ${this.columnMappingSuffix}`;
                })
                .join(";");
        }
    }
    computeTables() {
        const dataset = this.formData["dataset"];
        // ILCDC & AppendOnly
        if (this.isAutomatedILCDC && commonDWHUtils.isAppendOnly(this.formData["Mode"])) {
            this.adapterProperties["Tables"] = this.addColumnMapping(this.adapterProperties["Tables"], dataset);
        } else {
            this.adapterProperties["Tables"] = dataset ? `%,${dataset}.%` : this.adapterProperties["Tables"];
            // For Salesforce Automated Group - CDC App add COLUMNMAP()
            if (this.isAutomatedILCDC && commonDWHUtils.isSourceSalesforce(this.sourceType) && this.isCDC) {
                this.adapterProperties["Tables"] += " COLUMNMAP()";
            }
        }
    }
    computeCDDLOptions() {
        // ILCDC & AppendOnly
        if (this.isAutomatedILCDC && commonDWHUtils.isAppendOnly(this.formData["Mode"])) {
            this.adapterProperties["CDDLOptions"] = commonDWHUtils.getCDDLOptions("DATETIME", "STRING");
        }
    }
    populateProperties() {
        this.computeMode();
        this.computeTables();
        this.computeCDDLOptions();
    }
}
