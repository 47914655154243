import React, { useCallback, useEffect, useState, useMemo, SetStateAction, useRef } from "react";
import EventsProcessedTimelineChart from "../timeline-view-chart/events-processed-timeline-chart";
import _ from "underscore";
import { EventsProcessedData, SelectedSensitiveDataIdentifier } from "../../sentinel-live-monitor";
import { Grid, Divider } from "@mui/material";
import SentinelTable from "../sentinel-table/sentinel-table";
import SentinelTableDynamic from "../sentinel-table/sentinel-table-dynamic";
import {
    AllSentinelsSDIDrillDownEvents,
    AllSentinelsSDIDrillDownEventsChartData,
    EventsProcessedChartData,
    SentinelsSDIDrillDownEvents,
    SentinelState
} from "../../../guardian/sentinel-monitor/service/sentinel-monitor.types";
import { TABS } from "../panel-header-controls/panel-header-controls.stories";
import SDIEventsChart from "../timeline-view-chart/sdi-events-chart";
import StriimCircularProgress from "../../../../apps/pages/manage-striim/connection-profiles/components/circular-progress/circular-progress";
import { getTimestampsAsOneHourWindow } from "../../utils";

interface EventsProcessedContentProps {
    selectedSentinel: { label: string; value: string } | null;
    eventsProcessedData: EventsProcessedData;
    chartData: EventsProcessedChartData | AllSentinelsSDIDrillDownEventsChartData | null;
    showTaggedData: boolean;
    setShowTaggedData: React.Dispatch<SetStateAction<boolean>>;
    selectedTab: string;
    selectedDateRange: { from: number; to: number } | null;
    setSelectedDateRange: React.Dispatch<SetStateAction<{ from: number; to: number } | null>>;
    setSelectedSentinel: React.Dispatch<SetStateAction<{ label: string; value: string }>>;
    allSentinelSDIDrillDownEvents: AllSentinelsSDIDrillDownEvents;
    selectedSDI: SelectedSensitiveDataIdentifier | null;
    sentinelsSDIDrillDownEvents: SentinelsSDIDrillDownEvents;
    showProgress: boolean;
    showChartLoader: boolean;
    hasAppStopped?: boolean;
    onLiveViewClick: Function;
    isTaggingEnabled?: boolean;
}

const EventsProcessedContent: React.FC<EventsProcessedContentProps> = ({
    selectedSentinel,
    eventsProcessedData,
    chartData,
    showTaggedData,
    setShowTaggedData,
    selectedTab,
    selectedDateRange,
    setSelectedDateRange,
    setSelectedSentinel,
    allSentinelSDIDrillDownEvents,
    selectedSDI,
    sentinelsSDIDrillDownEvents,
    showProgress,
    showChartLoader,
    hasAppStopped = false,
    legendData,
    onLiveViewClick,
    isTaggingEnabled = true
}) => {
    const [isDiscoveryDisabled, setIsDiscoveryDisabled] = useState<boolean>(false);
    const [dataUnavailableTimestamp, setDataUnavailableTimestamp] = useState<number | null>();
    const [filteredTableData, setFilteredTableData] = useState<SentinelState[]>([]);
    const [sentinelTableData, setSentinelTableData] = useState<SentinelState[]>([]);
    const chartRef = useRef(null);

    const loadData = useCallback(
        _.debounce((event, dateRange) => {
            const updatedDateRange = getTimestampsAsOneHourWindow(dateRange?.from, dateRange?.to);
            setSelectedDateRange(updatedDateRange);
        }, 300),
        []
    );

    const prepareSentinelData = (sentinels, parentData) => {
        const disabledSentinel = {
            name: "",
            processedEvents: "-",
            dataTagged: "-",
            disabled: true,
            sensitiveData: 0,
            nonSensitiveData: 0,
            eventsWithSDI: {
                total: 0,
                eventsWithSensitiveData: 0,
                eventsWithoutSensitiveData: 0
            }
        };

        const sentinelsArray = [];

        for (const key in sentinels) {
            if (sentinels.hasOwnProperty(key)) {
                const currentSentinel = sentinels[key];
                const sentinelDisabled = !currentSentinel.discoveryEnabled;

                const sentinel = sentinelDisabled
                    ? {
                          ...disabledSentinel,
                          name: key
                      }
                    : {
                          name: key,
                          processedEvents: currentSentinel.eventsProcessed,
                          dataTagged: currentSentinel.eventsWithSensitiveDataTagged,
                          disabled: false,
                          sensitiveData: currentSentinel.eventsWithSensitiveData,
                          nonSensitiveData: currentSentinel.eventsWithoutSensitiveData,
                          eventsWithSDI: currentSentinel?.eventsWithSDI || {},
                          sdi: parentData?.sdi || ""
                      };

                sentinelsArray.push(sentinel);
            }
        }

        return sentinelsArray;
    };

    const updateTableData = (sentinels, eventsProcessedData) => {
        if (!sentinels) {
            setSentinelTableData([]);
            setFilteredTableData([]);
        }

        const updatedSentinelsData = prepareSentinelData(sentinels, eventsProcessedData);

        setSentinelTableData(updatedSentinelsData);
        setFilteredTableData(updatedSentinelsData);
    };

    const isIndividualSentinelSelected = !(!selectedSentinel || selectedSentinel.value == "All");

    const sensitiveDataTaggedPercentage = useMemo(() => {
        const activeSentinelData = !(!selectedSentinel || selectedSentinel.value == "All")
            ? eventsProcessedData.activeSentinel
            : eventsProcessedData.allSentinel;

        if (!activeSentinelData) return;
        return (activeSentinelData.eventsTaggedAsSensitive / activeSentinelData.eventsWithSensitiveData) * 100 || 0;
    }, [eventsProcessedData, selectedSentinel]);

    useMemo(() => {
        if (!isIndividualSentinelSelected) {
            !selectedSDI
                ? updateTableData(eventsProcessedData?.allSentinel?.sentinels, eventsProcessedData)
                : updateTableData(allSentinelSDIDrillDownEvents?.sentinels, allSentinelSDIDrillDownEvents);
        }
    }, [eventsProcessedData, allSentinelSDIDrillDownEvents]);

    const showDivider = !isIndividualSentinelSelected;
    const timeRange = selectedDateRange || selectedTab === TABS[0] ? "1 HOUR" : "24 HOURS";

    return (
        <div>
            <div style={{ position: "relative" }}>
                {showChartLoader ? (
                    <Grid container justifyContent={"center"} alignItems={"center"} minHeight={325}>
                        <StriimCircularProgress size={24} />
                    </Grid>
                ) : !chartData?.identifierSensitiveData ? (
                    <EventsProcessedTimelineChart
                        chartRef={chartRef}
                        sensitiveDataTaggedPercentage={sensitiveDataTaggedPercentage}
                        chartData={chartData}
                        selectedDateRange={selectedDateRange}
                        onScroll={loadData}
                        isDiscoveryDisabled={isDiscoveryDisabled}
                        hasAppStopped={hasAppStopped}
                        dataUnavailableTimestamp={dataUnavailableTimestamp}
                        showTaggedData={showTaggedData && !selectedSDI}
                        setShowTaggedData={setShowTaggedData}
                        hideNavigator={selectedTab === TABS[1]}
                        isTaggedDataOptionAvailable={isTaggingEnabled && !selectedSDI}
                        legendData={legendData}
                        onLiveViewClick={onLiveViewClick}
                    />
                ) : (
                    <SDIEventsChart
                        chartRef={chartRef}
                        onScroll={loadData}
                        chartData={chartData}
                        selectedDateRange={selectedDateRange}
                        isDiscoveryDisabled={isDiscoveryDisabled}
                        hasAppStopped={hasAppStopped}
                        dataUnavailableTimestamp={dataUnavailableTimestamp}
                        hideNavigator={selectedTab === TABS[1]}
                        legendData={legendData}
                        onLiveViewClick={onLiveViewClick}
                    />
                )}
            </div>

            {showDivider && (
                <Divider
                    sx={{
                        width: "calc(100% + 48px)",
                        marginLeft: -3,
                        marginTop: 3,
                        marginBottom: 3
                    }}
                />
            )}

            <Grid container width="100%">
                {!isIndividualSentinelSelected && !selectedSDI ? (
                    <SentinelTable
                        title="Events Processed by Sentinels"
                        tableData={sentinelTableData}
                        filteredTableData={filteredTableData}
                        setFilteredTableData={setFilteredTableData}
                        timeRange={timeRange}
                        setSelectedSentinel={value => {
                            setSelectedSentinel(value);
                            setShowTaggedData(false);
                        }}
                        showProgress={showProgress}
                    />
                ) : !isIndividualSentinelSelected && selectedSDI ? (
                    <SentinelTableDynamic
                        title="Breakdown by Sentinels"
                        tableData={sentinelTableData}
                        filteredTableData={filteredTableData}
                        setFilteredTableData={setFilteredTableData}
                        timeRange={timeRange}
                        showProgress={showProgress}
                        setSelectedSentinel={value => setSelectedSentinel(value)}
                    />
                ) : null}
            </Grid>
        </div>
    );
};

export default EventsProcessedContent;
