import _ from "underscore";
import BaseControl from "./../base-control";
import UIControl from "app/components/common/editor/control/ui-control";
import Autocomplete from "app/components/common/editor/control/autocomplete/autocomplete";
import TqlBuilder from "app/components/common/tqlBuilder";
import FieldDataSource from "./../select/field-datasource";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import template from "./field-selector.html";

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "columns-splitter",

    regions: {
        fieldSelectorRegion: ".field-selector",
        expressionRegion: ".expression-input",
    },

    setValue: function (value) {
        this.fieldSelector.setValue(value.index);
        this.expression.setValue(value.column);
        this.model.trigger("change:value", this.getViewValue());
    },

    setViewValue: function () {},

    getViewValue: function () {
        var index = this.fieldSelector.getValue();

        if ((index !== 0 && !index) || !this.expression.getValue()) {
            return null;
        }

        return {
            index: index,
            column: this.expression.getValue(),
        };
    },

    clear: function () {
        this.fieldSelector.setValue("");
        this.expression.setValue("");
    },

    setEnabled: function (enabled) {
        BaseControl.View.prototype.setEnabled.apply(this, [enabled]);
        this.fieldSelector.setEnabled(enabled);
        this.expression.setEnabled(enabled);
    },

    initialize: function (options) {
        this.fieldSelector = UIControl.Select([])
            .extend({
                placeholder: "select",
            })
            .create();

        this.expression = Autocomplete.extend({
            width: 240,
        }).create();
    },

    onRender: function () {
        this.expressionRegion.show(this.expression);
        this.fieldSelectorRegion.show(this.fieldSelector);

        this.listenTo(this.fieldSelector.model, "change:value", (viewModel) => {
            this.model.trigger("change:value", this.getViewValue());
        });

        this.listenTo(this.expression.model, "change:value", (viewModel) => {
            if (viewModel.value) {
                this.model.column = TqlBuilder.ensureValidTql(viewModel.value);
            }

            this.model.trigger("change:value", this.getViewValue());
        });
    },

    setFieldsSource: function (source) {
        this.fieldSelector.setDataSource(source);
        this.fieldSelector.setViewValue();
    },

    updateExpressionFieldsSource: function (componentId) {
        if (!componentId) {
            return;
        }

        metaStoreService.findById(componentId).then(componentModel => {
            if (!componentModel) {
                return;
            }

            var expressionFieldsSource = new FieldDataSource(componentModel.dataType);
            expressionFieldsSource.then((fields) => {
                if (_.isArray(fields)) {
                    this.expression.setColumns(fields);
                }

                this.expression.refresh();
            });
        });
    },
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: Array,
    },
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
