import { getTemplateGroups, filterTemplates } from "../../create-app-page/utils";
import { adapterType } from "../create-app-page-templates";
import { optionsType } from "../Components/create-app-page-app-type-dropdown";
import APPTYPE from "../services/create-app-page-app-types.const.json";
import { DB_PROVIDERS } from "../../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/configs/db-providers";
import licenseService from "../../../../../../core/services/metaStoreService/license-service";
import { fetchLabelBasedOnLicense } from "../Components/adapter-selection/app-types-info";

export const getSupportedAppType = (sourceAdapter: adapterType, targetAdapter: adapterType): optionsType[] => {
    let supportedAppType: optionsType[] = [];
    const label = fetchLabelBasedOnLicense(licenseService?.nonSegmentedlicenseName);

    const selectedAdapters = [`Source: ${sourceAdapter.id}`, `Target: ${targetAdapter.id}`];
    const { templatesList } = getTemplateGroups();
    const filteredTemplates = filterTemplates(selectedAdapters, templatesList);
    filteredTemplates.map(template => {
        if (template.isAutomatedILCDC) {
            supportedAppType.push({
                label: label[APPTYPE.AUTOMATEDILCDC],
                value: APPTYPE.AUTOMATEDILCDC,
                templateUrl: template.url
            });
        } else if (["CDC", "MSJet", "OJet"].some(keyword => template.title.indexOf(keyword) !== -1)) {
            supportedAppType.push({ label: label[APPTYPE.CDC], value: APPTYPE.CDC, templateUrl: template.url });
        } else if (DB_PROVIDERS[sourceAdapter.value.replace(" ", "").toUpperCase()]?.isAppAdapter) {
            supportedAppType.push({
                label: label[APPTYPE.AUTOMATEDMODE],
                value: APPTYPE.AUTOMATEDMODE,
                templateUrl: filteredTemplates[0].url
            });
        } else {
            supportedAppType.push({ label: label[APPTYPE.IL], value: APPTYPE.IL, templateUrl: template.url });
        }
    });

    return supportedAppType;
};
