export const createProgressStore = () => {
    const IOTimes = {
        pgschema: {
            name: "pgschema",
            time: 1711696351595
        },
        mysqlta: {
            name: "mysqlta",
            time: 1711696351612
        }
    };
    const tableProgress = {
        "public.users": {
            TotalRows: 135908982,
            ILStatus: "InProgress",
            RowsRead: 36838534,
            eventsRead: 36838534,
            sourceComponentName: "mysql",
            tableName: "public.users",
            targetAdapters: { posgtes: "posgtes", oracle: "oracle" },
            writerse: {
                "public.users": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 04:02:09",
                    "No of DDLs": 0,
                    "LAST APPLIED DDL": [],
                    Sources: ["public.users"],
                    "Last Batch Execution Time": "2024-03-29 04:02:09",

                    targetComponentName: "posgtes",
                    tableName: "public.users"
                },
                "qatest.users": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 04:02:09",
                    "No of DDLs": 0,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "LAST APPLIED DDL": [],
                    Sources: ["public.users"],
                    "Last Batch Execution Time": "2024-03-29 04:02:09",
                    lastWriteTime: "2024-03-29 04:02:09",
                    targetComponentName: "oracle",
                    tableName: "qatest.users"
                }
            }
        },
        "public.main": {
            TotalRows: 1011,
            ILStatus: "Completed",
            RowsRead: 1011,
            eventsRead: 1011,
            sourceComponentName: "mysql",
            tableName: "public.main",
            wsriters: {
                "public.main": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 04:00:43",
                    "No of DDLs": 0,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 1011,
                    "LAST APPLIED DDL": [],
                    Sources: ["public.main"],
                    "Last Batch Execution Time": "2024-03-29 04:00:43",
                    eventsWritten: 1011,
                    lastWriteTime: "2024-03-29 04:00:43",
                    targetComponentName: "posgtes",
                    tableName: "public.main"
                },
                "qatest.main": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 04:00:42",
                    "No of DDLs": 0,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 1011,
                    "LAST APPLIED DDL": [],
                    Sources: ["public.main"],
                    "Last Batch Execution Time": "2024-03-29 04:00:42",
                    eventsWritten: 1011,
                    lastWriteTime: "2024-03-29 04:00:42",
                    targetComponentName: "oracle",
                    tableName: "qatest.main"
                }
            }
        },
        "main.table_62": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_62",
            writers: {
                "main1.table_62": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.702535",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_62` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_62"],
                    "Last Batch Execution Time": "2024-03-29 00:12:26",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_62"
                }
            }
        },
        "main.table_63": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_63",
            writers: {
                "main1.table_63": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.749379",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_63` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_63"],
                    "Last Batch Execution Time": "2024-03-29 00:12:26",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_63"
                }
            }
        },
        "main.table_60": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_60",
            writers: {
                "main1.table_60": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.601017",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_60` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_60"],
                    "Last Batch Execution Time": "2024-03-29 00:12:26",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_60"
                }
            }
        },
        "main.table_61": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_61",
            writers: {
                "main1.table_61": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.654088",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_61` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_61"],
                    "Last Batch Execution Time": "2024-03-29 00:12:26",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_61"
                }
            }
        },
        "main.table_66": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_66",
            writers: {
                "main1.table_66": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:18.031164",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_66` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_66"],
                    "Last Batch Execution Time": "2024-03-29 00:12:26",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_66"
                }
            }
        },
        "main.table_67": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_67",
            writers: {
                "main1.table_67": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:18.095276",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_67` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_67"],
                    "Last Batch Execution Time": "2024-03-29 00:12:26",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_67"
                }
            }
        },
        "main.table_64": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_64",
            writers: {
                "main1.table_64": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.879796",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_64` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_64"],
                    "Last Batch Execution Time": "2024-03-29 00:12:26",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_64"
                }
            }
        },
        "main.table_65": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_65",
            writers: {
                "main1.table_65": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.971377",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_65` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_65"],
                    "Last Batch Execution Time": "2024-03-29 00:12:26",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_65"
                }
            }
        },
        "main.table_68": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_68",
            writers: {
                "main1.table_68": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:18.159581",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_68` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_68"],
                    "Last Batch Execution Time": "2024-03-29 00:12:27",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_68"
                }
            }
        },
        "main.table_69": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_69",
            writers: {
                "main1.table_69": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:18.240206",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_69` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_69"],
                    "Last Batch Execution Time": "2024-03-29 00:12:28",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_69"
                }
            }
        },
        "main.table_70": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_70",
            writers: {
                "main1.table_70": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:18.385680",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_70` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_70"],
                    "Last Batch Execution Time": "2024-03-29 00:12:29",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_70"
                }
            }
        },
        "main.table_51": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_51",
            writers: {
                "main1.table_51": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:16.699524",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_51` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_51"],
                    "Last Batch Execution Time": "2024-03-29 00:12:25",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_51"
                }
            }
        },
        "main.table_52": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_52",
            writers: {
                "main1.table_52": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.002002",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_52` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_52"],
                    "Last Batch Execution Time": "2024-03-29 00:12:25",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_52"
                }
            }
        },
        "main.table_50": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_50",
            writers: {
                "main1.table_50": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:15.605607",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_50` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_50"],
                    "Last Batch Execution Time": "2024-03-29 00:12:25",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_50"
                }
            }
        },
        "main.table_55": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_55",
            writers: {
                "main1.table_55": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.225441",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_55` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_55"],
                    "Last Batch Execution Time": "2024-03-29 00:12:25",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_55"
                }
            }
        },
        "main.table_56": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_56",
            writers: {
                "main1.table_56": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.283800",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_56` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_56"],
                    "Last Batch Execution Time": "2024-03-29 00:12:25",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_56"
                }
            }
        },
        "main.table_53": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_53",
            writers: {
                "main1.table_53": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.086901",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_53` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_53"],
                    "Last Batch Execution Time": "2024-03-29 00:12:25",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_53"
                }
            }
        },
        "main.table_54": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_54",
            writers: {
                "main1.table_54": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.146400",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_54` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_54"],
                    "Last Batch Execution Time": "2024-03-29 00:12:25",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_54"
                }
            }
        },
        "main.table_59": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_59",
            writers: {
                "main1.table_59": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.478508",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_59` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_59"],
                    "Last Batch Execution Time": "2024-03-29 00:12:25",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_59"
                }
            }
        },
        "main.table_57": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_57",
            writers: {
                "main1.table_57": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.343324",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_57` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_57"],
                    "Last Batch Execution Time": "2024-03-29 00:12:25",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_57"
                }
            }
        },
        "main.table_58": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_58",
            writers: {
                "main1.table_58": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.399922",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_58` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_58"],
                    "Last Batch Execution Time": "2024-03-29 00:12:25",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_58"
                }
            }
        },
        "main.table_40": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_40",
            writers: {
                "main1.table_40": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.639615",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_40` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_40"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_40"
                }
            }
        },
        "main.table_41": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_41",
            writers: {
                "main1.table_41": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.682577",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_41` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_41"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_41"
                }
            }
        },
        "main.table_44": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_44",
            writers: {
                "main1.table_44": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.862099",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_44` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_44"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_44"
                }
            }
        },
        "main.table_45": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_45",
            writers: {
                "main1.table_45": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.961327",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_45` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_45"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_45"
                }
            }
        },
        "main.table_42": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_42",
            writers: {
                "main1.table_42": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.726158",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_42` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_42"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_42"
                }
            }
        },
        "main.table_43": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_43",
            writers: {
                "main1.table_43": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.769762",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_43` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_43"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_43"
                }
            }
        },
        "main.table_48": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_48",
            writers: {
                "main1.table_48": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:15.230585",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_48` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_48"],
                    "Last Batch Execution Time": "2024-03-29 00:12:25",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_48"
                }
            }
        },
        "main.table_49": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_49",
            writers: {
                "main1.table_49": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:15.447645",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_49` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_49"],
                    "Last Batch Execution Time": "2024-03-29 00:12:25",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_49"
                }
            }
        },
        "main.table_46": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_46",
            writers: {
                "main1.table_46": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:15.007469",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_46` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_46"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_46"
                }
            }
        },
        "main.table_47": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_47",
            writers: {
                "main1.table_47": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:15.142467",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_47` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_47"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_47"
                }
            }
        },
        "main.table_30": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_30",
            writers: {
                "main1.table_30": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.074191",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_30` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_30"],
                    "Last Batch Execution Time": "2024-03-29 00:12:23",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_30"
                }
            }
        },
        "main.table_33": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_33",
            writers: {
                "main1.table_33": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.230650",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_33` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_33"],
                    "Last Batch Execution Time": "2024-03-29 00:12:23",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_33"
                }
            }
        },
        "main.table_34": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_34",
            writers: {
                "main1.table_34": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.278529",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_34` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_34"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_34"
                }
            }
        },
        "main.table_31": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_31",
            writers: {
                "main1.table_31": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.131658",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_31` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_31"],
                    "Last Batch Execution Time": "2024-03-29 00:12:23",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_31"
                }
            }
        },
        "main.table_32": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_32",
            writers: {
                "main1.table_32": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.177810",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_32` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_32"],
                    "Last Batch Execution Time": "2024-03-29 00:12:23",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_32"
                }
            }
        },
        "main.table_6": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_6",
            writers: {
                "main1.table_6": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:17.543878",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_6` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_6"],
                    "Last Batch Execution Time": "2024-03-29 00:12:25",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_6"
                }
            }
        },
        "main.table_37": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_37",
            writers: {
                "main1.table_37": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.444858",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_37` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_37"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_37"
                }
            }
        },
        "main.table_7": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_7",
            writers: {
                "main1.table_7": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:18.304659",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_7` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_7"],
                    "Last Batch Execution Time": "2024-03-29 00:12:29",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_7"
                }
            }
        },
        "main.table_38": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_38",
            writers: {
                "main1.table_38": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.497028",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_38` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_38"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_38"
                }
            }
        },
        "main.table_8": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_8",
            writers: {
                "main1.table_8": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:19.136801",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_8` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_8"],
                    "Last Batch Execution Time": "2024-03-29 00:12:30",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_8"
                }
            }
        },
        "main.table_35": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_35",
            writers: {
                "main1.table_35": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.338236",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_35` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_35"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_35"
                }
            }
        },
        "main.table_9": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_9",
            writers: {
                "main1.table_9": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:20.524815",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_9` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_9"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_9"
                }
            }
        },
        "main.table_36": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_36",
            writers: {
                "main1.table_36": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.383627",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_36` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_36"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_36"
                }
            }
        },
        "main.table_39": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_39",
            writers: {
                "main1.table_39": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.544132",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_39` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_39"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_39"
                }
            }
        },
        "main.table_1": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_1",
            writers: {
                "main1.table_1": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 2,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:12.837632",
                    "LAST APPLIED DDL": [
                        "CREATE DATABASE IF NOT EXISTS `main1`",
                        "CREATE TABLE `main1`.`table_1` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_1"],
                    "Last Batch Execution Time": "2024-03-29 00:12:21",
                    eventsWritten: 52,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_1"
                }
            }
        },
        "main.table_2": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_2",
            writers: {
                "main1.table_2": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.368049",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_2` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_2"],
                    "Last Batch Execution Time": "2024-03-29 00:12:22",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_2"
                }
            }
        },
        "main.table_3": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_3",
            writers: {
                "main1.table_3": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.020543",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_3` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_3"],
                    "Last Batch Execution Time": "2024-03-29 00:12:23",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_3"
                }
            }
        },
        "main.table_4": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_4",
            writers: {
                "main1.table_4": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:14.596296",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_4` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_4"],
                    "Last Batch Execution Time": "2024-03-29 00:12:24",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_4"
                }
            }
        },
        "main.table_5": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_5",
            writers: {
                "main1.table_5": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:26",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:15.527639",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_5` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_5"],
                    "Last Batch Execution Time": "2024-03-29 00:12:25",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:26",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_5"
                }
            }
        },
        "main.table_22": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_22",
            writers: {
                "main1.table_22": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.558902",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_22` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_22"],
                    "Last Batch Execution Time": "2024-03-29 00:12:22",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_22"
                }
            }
        },
        "main.table_23": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_23",
            writers: {
                "main1.table_23": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.649659",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_23` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_23"],
                    "Last Batch Execution Time": "2024-03-29 00:12:22",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_23"
                }
            }
        },
        "main.table_20": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_20",
            writers: {
                "main1.table_20": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.449633",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_20` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_20"],
                    "Last Batch Execution Time": "2024-03-29 00:12:22",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_20"
                }
            }
        },
        "main.table_21": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_21",
            writers: {
                "main1.table_21": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.501672",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_21` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_21"],
                    "Last Batch Execution Time": "2024-03-29 00:12:22",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_21"
                }
            }
        },
        "main.table_26": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_26",
            writers: {
                "main1.table_26": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.818443",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_26` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_26"],
                    "Last Batch Execution Time": "2024-03-29 00:12:23",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_26"
                }
            }
        },
        "main.table_27": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_27",
            writers: {
                "main1.table_27": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.866729",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_27` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_27"],
                    "Last Batch Execution Time": "2024-03-29 00:12:23",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_27"
                }
            }
        },
        "main.table_24": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_24",
            writers: {
                "main1.table_24": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.709428",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_24` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_24"],
                    "Last Batch Execution Time": "2024-03-29 00:12:23",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_24"
                }
            }
        },
        "main.table_25": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_25",
            writers: {
                "main1.table_25": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.764831",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_25` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_25"],
                    "Last Batch Execution Time": "2024-03-29 00:12:23",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_25"
                }
            }
        },
        "main.table_28": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_28",
            writers: {
                "main1.table_28": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.914315",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_28` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_28"],
                    "Last Batch Execution Time": "2024-03-29 00:12:23",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_28"
                }
            }
        },
        "main.table_29": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_29",
            writers: {
                "main1.table_29": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:24",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.977804",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_29` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_29"],
                    "Last Batch Execution Time": "2024-03-29 00:12:23",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:24",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_29"
                }
            }
        },
        "main.table_95": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_95",
            writers: {
                "main1.table_95": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:21.097690",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_95` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_95"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_95"
                }
            }
        },
        "main.table_96": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_96",
            writers: {
                "main1.table_96": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:21.208924",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_96` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_96"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_96"
                }
            }
        },
        "main.table_93": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_93",
            writers: {
                "main1.table_93": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:20.924319",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_93` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_93"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_93"
                }
            }
        },
        "main.table_94": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_94",
            writers: {
                "main1.table_94": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:20.991670",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_94` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_94"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_94"
                }
            }
        },
        "main.table_99": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_99",
            writers: {
                "main1.table_99": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:21.564993",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_99` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_99"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_99"
                }
            }
        },
        "main.table_11": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_11",
            writers: {
                "main1.table_11": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:12.914622",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_11` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_11"],
                    "Last Batch Execution Time": "2024-03-29 00:12:21",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_11"
                }
            }
        },
        "main.table_12": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_12",
            writers: {
                "main1.table_12": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:12.965021",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_12` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_12"],
                    "Last Batch Execution Time": "2024-03-29 00:12:21",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_12"
                }
            }
        },
        "main.table_97": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_97",
            writers: {
                "main1.table_97": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:21.280920",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_97` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_97"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_97"
                }
            }
        },
        "main.table_98": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_98",
            writers: {
                "main1.table_98": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:21.404032",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_98` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_98"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_98"
                }
            }
        },
        "main.table_10": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_10",
            writers: {
                "main1.table_10": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:12.872459",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_10` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_10"],
                    "Last Batch Execution Time": "2024-03-29 00:12:21",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_10"
                }
            }
        },
        "main.table_15": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_15",
            writers: {
                "main1.table_15": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.122315",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_15` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_15"],
                    "Last Batch Execution Time": "2024-03-29 00:12:22",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_15"
                }
            }
        },
        "main.table_16": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_16",
            writers: {
                "main1.table_16": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.180130",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_16` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_16"],
                    "Last Batch Execution Time": "2024-03-29 00:12:22",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_16"
                }
            }
        },
        "main.table_13": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_13",
            writers: {
                "main1.table_13": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.006439",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_13` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_13"],
                    "Last Batch Execution Time": "2024-03-29 00:12:21",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_13"
                }
            }
        },
        "main.table_14": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_14",
            writers: {
                "main1.table_14": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.050442",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_14` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_14"],
                    "Last Batch Execution Time": "2024-03-29 00:12:22",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_14"
                }
            }
        },
        "main.table_19": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_19",
            writers: {
                "main1.table_19": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.324537",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_19` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_19"],
                    "Last Batch Execution Time": "2024-03-29 00:12:22",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_19"
                }
            }
        },
        "main.table_17": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_17",
            writers: {
                "main1.table_17": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.225859",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_17` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_17"],
                    "Last Batch Execution Time": "2024-03-29 00:12:22",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_17"
                }
            }
        },
        "main.table_18": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_18",
            writers: {
                "main1.table_18": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:13.268083",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_18` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_18"],
                    "Last Batch Execution Time": "2024-03-29 00:12:22",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_18"
                }
            }
        },
        "main.table_84": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_84",
            writers: {
                "main1.table_84": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:19.539572",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_84` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_84"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_84"
                }
            }
        },
        "main.table_85": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_85",
            writers: {
                "main1.table_85": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:19.607704",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_85` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_85"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_85"
                }
            }
        },
        "main.table_82": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_82",
            writers: {
                "main1.table_82": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:19.396936",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_82` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_82"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_82"
                }
            }
        },
        "main.table_83": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_83",
            writers: {
                "main1.table_83": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:19.463312",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_83` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_83"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_83"
                }
            }
        },
        "main.table_88": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_88",
            writers: {
                "main1.table_88": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:20.221561",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_88` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_88"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_88"
                }
            }
        },
        "main.table_89": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_89",
            writers: {
                "main1.table_89": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:20.413174",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_89` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_89"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_89"
                }
            }
        },
        "main.table_86": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_86",
            writers: {
                "main1.table_86": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:19.689400",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_86` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_86"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_86"
                }
            }
        },
        "main.table_87": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_87",
            writers: {
                "main1.table_87": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:20.039943",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_87` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_87"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_87"
                }
            }
        },
        "main.table_91": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_91",
            writers: {
                "main1.table_91": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:20.753099",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_91` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_91"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_91"
                }
            }
        },
        "main.table_92": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_92",
            writers: {
                "main1.table_92": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:20.822830",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_92` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_92"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_92"
                }
            }
        },
        "main.table_90": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_90",
            writers: {
                "main1.table_90": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:20.690076",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_90` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_90"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_90"
                }
            }
        },
        "main.table_73": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_73",
            writers: {
                "main1.table_73": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:18.581319",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_73` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_73"],
                    "Last Batch Execution Time": "2024-03-29 00:12:29",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_73"
                }
            }
        },
        "main.table_74": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_74",
            writers: {
                "main1.table_74": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:18.660184",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_74` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_74"],
                    "Last Batch Execution Time": "2024-03-29 00:12:29",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_74"
                }
            }
        },
        "main.table_71": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_71",
            writers: {
                "main1.table_71": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:18.445647",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_71` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_71"],
                    "Last Batch Execution Time": "2024-03-29 00:12:29",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_71"
                }
            }
        },
        "main.table_72": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_72",
            writers: {
                "main1.table_72": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:18.527533",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_72` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_72"],
                    "Last Batch Execution Time": "2024-03-29 00:12:29",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_72"
                }
            }
        },
        "main.table_77": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_77",
            writers: {
                "main1.table_77": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:18.913029",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_77` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_77"],
                    "Last Batch Execution Time": "2024-03-29 00:12:29",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_77"
                }
            }
        },
        "main.table_78": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_78",
            writers: {
                "main1.table_78": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:18.987397",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_78` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_78"],
                    "Last Batch Execution Time": "2024-03-29 00:12:29",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_78"
                }
            }
        },
        "main.table_75": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_75",
            writers: {
                "main1.table_75": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:18.767011",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_75` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_75"],
                    "Last Batch Execution Time": "2024-03-29 00:12:29",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_75"
                }
            }
        },
        "main.table_76": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_76",
            writers: {
                "main1.table_76": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:18.829134",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_76` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_76"],
                    "Last Batch Execution Time": "2024-03-29 00:12:29",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_76"
                }
            }
        },
        "main.table_79": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_79",
            writers: {
                "main1.table_79": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:19.062859",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_79` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_79"],
                    "Last Batch Execution Time": "2024-03-29 00:12:29",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_79"
                }
            }
        },
        "main.table_100": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_100",
            writers: {
                "main1.table_100": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:23",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:12.895780",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_100` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_100"],
                    "Last Batch Execution Time": "2024-03-29 00:12:21",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:23",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_100"
                }
            }
        },
        "main.table_80": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_80",
            writers: {
                "main1.table_80": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "Last Commit Execution Time": "2024-03-29 00:12:30",
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 50,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:19.239068",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_80` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_80"],
                    "Last Batch Execution Time": "2024-03-29 00:12:30",
                    eventsWritten: 51,
                    lastWriteTime: "2024-03-29 00:12:30",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_80"
                }
            }
        },
        "main.table_81": {
            SchemaCreationStatus: "Success",
            TotalRows: 50,
            ILStatus: "Completed",
            RowsRead: 50,
            eventsRead: 50,
            sourceComponentName: "pgschema",
            tableName: "main.table_81",
            writers: {
                "main1.table_81": {
                    "NO-OP": {
                        PKUPDATE: 0,
                        DELETE: 0,
                        UPDATE: 0
                    },
                    "No of Deletes": 0,
                    "No of DDLs": 1,
                    "No of PKUpdates": 0,
                    "No of Updates": 0,
                    "No of Inserts": 0,
                    "LAST APPLIED DDL TIME": "2024-03-29T00:12:19.314779",
                    "LAST APPLIED DDL": [
                        "CREATE TABLE `main1`.`table_81` (`id` integer, `name` varchar(100), `col1` varchar(20), PRIMARY KEY (`id`))"
                    ],
                    Sources: ["main.table_81"],
                    eventsWritten: 1,
                    lastWriteTime: "Fetching…",
                    targetComponentName: "mysqlta",
                    tableName: "main1.table_81"
                }
            }
        }
    };

    return {
        IOTimes,
        tableProgress
    };
};
