export const styles = {
    gridContainer: {
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        height: "inherit"
    },
    itemGridView: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: 2
    },
    itemListView: {
        display: "grid",
        gap: 2
    },
    itemContainer: {
        alignItems: "center",
        overflow: "visible",
        gap: 2,
        cursor: "pointer"
    },
    itemText: {
        width: "calc(100% - 70px)",
        "& .MuiTypography-root": {
            fontFamily: "Inter"
        }
    },
    scrollableContainer: {
        overflow: "scroll",
        padding: 3,
        borderRight: ({ palette }) => `1px solid ${palette.greyscale["200"]}`
    },
    recentlyUsedText: {
        fontFamily: "Inter"
    },
    recentlyUsedContainer: {
        backgroundColor: "greyscale.50",
        height: "inherit",
        overflow: "scroll",
        flexWrap: "nowrap"
    }
};
