import React, { useEffect } from "react";
import Backbone from "backbone";
import { useParams } from "react-router";
import loadBackboneApp from "../../../app/load-backbone-app";
import api from "../../../core/api/api";

export default function TestIframe() {
    const { dashboard, page } = useParams();

    useEffect(() => {
        api.setCurrentNamespace("admin");

        var view = new Backbone.View();
        loadBackboneApp(view);
        var url = "http://localhost:9080/#/embed/" + dashboard;
        if (page) {
            url = url + "/" + page;
        }
        url = url + "/" + api.token;
        $(view.$el).html('<iframe style="width:800px;height: 600px;" src="' + url + '" />');
    }, []);

    return <></>;
}
