import api from "core/api/api";
import { template, className, childView, childViewContainer, modelEvent } from "core/decorators";
import { LayoutView, CompositeView } from "marionette";
import App from "app";
import SchemaSelectionTemplate from "./templates/schema-selection.template.html";
import "datatables.net-dt";
import stringUtils from "core/utils/string-utils";
import Backbone from "backbone";
import growl from "../../../../../common/growl";
import _ from "underscore";
import salesforceTemplate from "text-loader!./templates/salesforce.template.tql";
import serviceNowTemplate from "text-loader!./templates/servicenow.template.tql";
import salesforceCDCTemplate from "text-loader!./templates/salesforcecdc.template.tql";
import salesforcePardotTemplate from "text-loader!./templates/salesforce_pardot.template.tql";
import hubspotReaderTemplate from "text-loader!./templates/hubspot.template.tql";
import googleAdsTemplate from "text-loader!./templates/googleads.template.tql";
//DO-NOT-REMOVE-BELOW-COMMENT
//add-new-adapter
import paymoTemplate from "text-loader!./templates/paymo.template.tql";
import linkedInAdsTemplate from "text-loader!./templates/linkedInAds.template.tql";
import googleAnalyticsTemplate from "text-loader!./templates/googleAnalytics.template.tql";
import jiraTemplate from "text-loader!./templates/jira.template.tql";
import stripeTemplate from "text-loader!./templates/stripe.template.tql";
import zendeskTemplate from "text-loader!./templates/zendesk.template.tql";
import intercomTemplate from "text-loader!./templates/intercom.template.tql";
import bambooHrTemplate from "text-loader!./templates/bamboo-hr.template.tql";
import getTableNameWithCorrectDots from "./table-name-validator";
import { DB_PROVIDERS } from "./configs/db-providers";
import NProgress from "nprogress";
import utils from "core/utils";
import MetaObjects from "core/services/metaStoreService/meta-objects";
import { getCDCName, isAutomatedWizard, moveToNewGroup } from "./app-services";
import { getParams } from "../../../../../../../src/modules/wizards/services";
import metaStoreService from "../../../../../../../core/services/metaStoreService/meta-store-service";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";

NProgress.configure({
    minimum: 0.25,
    trickleRate: 0.1,
    trickleSpeed: 800
});

@className("card schema-selection-view")
@template(SchemaSelectionTemplate)
export default class SchemaSelectionView extends LayoutView {
    initialize() {
        let model = App.reqres.request("get:wizard:model");
        this.model = model;
        // let pluralize = this.model.get("whatsInside") + "s";
    }

    delayedUpdate() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            App.vent.trigger("appwizard:next:disable");
        }, 100);
    }

    onShow() {
        const isFooterAvailable = $("#appwizard-footer");
        if (isFooterAvailable.length) {
            App.vent.trigger("appwizard:next:disable");
        } else {
            this.delayedUpdate();
        }

        let data = [];
        let that = this;
        let schemas = this.model.get("schemas");
        schemas.each(function(schema) {
            data.push({
                icon: `<div><label><input type='checkbox' class='filled-in'><span></span></label></div>`,
                name: schema.get("schemaName"),
                displayName: stringUtils.encodeHtml(schema.get("schemaName")),
                DT_RowId: schema.get("id")
            });
        });

        var dataTable = this.$el.find("#schema-selection").DataTable({
            data: data,
            defaultContent: "",
            scrollCollapse: true,
            paging: false,
            stripeClasses: [],
            autoWidth: false,
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            info: false,
            columns: [
                {
                    title: `<div><label><input id='select-all-schema' type='checkbox' class='filled-in'><span></span></label></div>`,
                    data: "icon",
                    sortable: false
                },
                { title: "Name", data: "displayName", sortable: false }
            ],
            columnDefs: [
                {
                    className: "select-checkbox",
                    targets: 0,
                    width: 30
                }
            ]
        });

        this.$el.find("#schema-selection").on("click", "tr", function() {
            let $schemaNameTd = $(this).find("td:nth-child(2)");
            if ($schemaNameTd.length === 0) {
                return;
            }

            const tableRowData = dataTable.row(this).data();

            let schemaName = tableRowData.DT_RowId;

            $(this).toggleClass("selected");
            let selected = false;
            if ($(this).hasClass("selected")) {
                $(this).find("input[type=checkbox]")[0].checked = true;
                selected = true;
            } else {
                $(this).find("input[type=checkbox]")[0].checked = false;
                selected = false;
            }
            that.setEnabledSchemas(schemaName, selected);
            that.setNextEnabled();
        });
        this.$el.find("#select-all-schema").on("click", function(event) {
            let selected = this.checked;
            let arr = $("#schema-selection tr").filter(idx => idx != 0);
            arr.toArray().forEach(elem => {
                if (!selected) {
                    $(elem).find("input[type=checkbox]")[0].checked = false;
                    $(elem).removeClass("selected");
                } else {
                    $(elem).find("input[type=checkbox]")[0].checked = true;
                    $(elem).addClass("selected");
                }
            });
            const selectedSchemasLength = that.getSelectedSchemas();
            if (selectedSchemasLength < schemas.length) {
                that.$el.find("#select-all-schema")[0].indeterminate = selectedSchemasLength > 0;
            } else {
                that.$el.find("#select-all-schema")[0].indeterminate = arr.length > 0 && arr.length < schemas.length;
            }
            that.setAllSchemasEnabled(selected);
            that.setNextEnabled();
        });

        setTimeout(function() {
            schemas.each(function(schema) {
                if (schema.get("isEnabled")) {
                    that.$el.find("#" + schema.get("id")).trigger("click");
                }
            });
        }, 100);
    }
    getSelectedSchemas() {
        let schemas = this.model.get("schemas");
        return schemas.filter(schema => schema.get("isEnabled")).length;
    }
    checkSelectedAllSchemas() {
        let schemas = this.model.get("schemas");
        let selectedAll = schemas.every(function(schema) {
            return schema.get("isEnabled");
        });
        if (selectedAll) {
            this.$el.find("#select-all-schema")[0].checked = true;
        } else {
            this.$el.find("#select-all-schema")[0].checked = false;
            const selectedSchemasLength = this.getSelectedSchemas();
            this.$el.find("#select-all-schema")[0].indeterminate = selectedSchemasLength > 0;
        }
    }
    setEnabledSchemas(schemaName, enabled) {
        let schemas = this.model.get("schemas");
        let schemaID = schemaName;
        let schema = schemas.findWhere({ id: schemaID });
        schema.set("isEnabled", enabled);
        this.checkSelectedAllSchemas();
    }
    setAllSchemasEnabled(enabled) {
        let schemas = this.model.get("schemas");
        schemas.forEach(schema => {
            const elem = this.$el.find("#" + schema.get("id"));
            if (schema.get("isDisabled") || !elem.length) return;
            schema.set("isEnabled", enabled);
        });
    }

    setNextEnabled() {
        let schemas = this.model.get("schemas");
        const selectedSchemas = schemas.filter(schema => schema.get("isEnabled"));
        if (selectedSchemas.length > 0) {
            App.vent.trigger("appwizard:next:enable");
        } else {
            App.vent.trigger("appwizard:next:disable");
        }
    }

    async canMoveForward() {
        return new Promise(async (resolve, reject) => {
            App.vent.trigger("appwizard:next:disable");
            NProgress.start();
            try {
                await this.createApp();
                // For Automated wizards
                if (isAutomatedWizard()) {
                    // Create or Fetch the CDC app
                    let CDCApp;
                    const name = this.model.get("appName");
                    const namespace = utils.getNamespace(name);
                    const CDCName = getCDCName(utils.getName(name));
                    const appId = MetaObjectConverter.getId(namespace, "APPLICATION", CDCName);
                    CDCApp = await metaStoreService.findById(appId);
                    if (!CDCApp) {
                        CDCApp = new MetaObjects.APPLICATION.Model({ name: CDCName, nsName: namespace });
                    }
                    await CDCApp.save();
                    await this.createApp(CDCName, true);
                    // Move IL and CDC apps to a new group
                    await moveToNewGroup(name, CDCName);
                }
                resolve();
            } catch (e) {
                growl.error(e, "Error creating Source");
                reject(e);
            } finally {
                App.vent.trigger("appwizard:next:enable");
            }
        });
    }
    async setStartPosition(model) {
        return new Promise(async (resolve, reject) => {
            try {
                const databaseType = model.get("type");
                const agentName = model.get("agentName");
                let res;
                const params = await getParams(databaseType, model);
                if (databaseType === DB_PROVIDERS.SALESFORCE.id) {
                    res = await api.salesforceCDCgetStartPosition(params, agentName);
                } else {
                    console.error("Unknown adapter type");
                    reject("Error on setting start position: Unknown adapter type");
                }
                const startPosition = await JSON.parse(res);
                startPosition && model.set(startPosition);
                resolve();
            } catch (error) {
                console.log(error);
                growl.error("Error on setting start position: ", error);
                reject(error);
            }
        });
    }
    async createApp(forceAppName, forceCDC = false) {
        let that = this;
        const isILCDC = isAutomatedWizard();
        return new Promise(async function(resolve, reject) {
            try {
                let model = new Backbone.Model(that.model.get("connectionParams").toJSON());
                const type = model.get("type");
                const appName = forceAppName || that.model.get("appName");
                let isCDC = forceCDC || that.model.get("isCDC");
                model.set("appName", appName);

                if (type === DB_PROVIDERS.SALESFORCE.id) {
                    model.set("autoAuthTokenRenewal", model.get("username") ? true : false);
                } else {
                    model.set(
                        "autoAuthTokenRenewal",
                        that.model.get("username") === null || that.model.get("username") === ""
                    );
                }
                if (isILCDC && isCDC) {
                    await that.setStartPosition(model);
                }

                let objects = [];
                that.model.get("schemas").each(schema => {
                    if (!schema.get("isEnabled")) {
                        return;
                    }
                    objects.push(schema.get("id"));
                });
                objects = objects.join(";");
                model.set("objects", objects);

                let templateFunction;
                switch (type) {
                    case DB_PROVIDERS.SALESFORCE.id:
                        // For Salesforce create Initial load App from CDC App
                        model.set("mode", "Automated");
                        if (isAutomatedWizard() && !forceCDC) {
                            model.set("mode", "InitialLoad");
                            model.set("migrateSchema", true);
                            isCDC = false;
                        }
                        if (isCDC) {
                            let channel =
                                that.model.get("selectedChannel") === "ChangeEvents"
                                    ? null
                                    : that.model.get("selectedChannel");
                            model.set("channel", channel);
                            templateFunction = _.template(salesforceCDCTemplate);
                        } else {
                            templateFunction = _.template(salesforceTemplate);
                        }
                        break;
                    case DB_PROVIDERS.SERVICENOW.id:
                        templateFunction = _.template(serviceNowTemplate);
                        break;
                    case DB_PROVIDERS.SALESFORCEPARDOT.id:
                        templateFunction = _.template(salesforcePardotTemplate);
                        break;
                    case DB_PROVIDERS.ZENDESK.id:
                        templateFunction = _.template(zendeskTemplate);
                        break;
                    case DB_PROVIDERS.HUBSPOT.id:
                        templateFunction = _.template(hubspotReaderTemplate);
                        break;
                    case DB_PROVIDERS.STRIPE.id:
                        templateFunction = _.template(stripeTemplate);
                        break;
                    case DB_PROVIDERS.INTERCOM.id:
                        templateFunction = _.template(intercomTemplate);
                        break;
                    case DB_PROVIDERS.GOOGLEADS.id:
                        templateFunction = _.template(googleAdsTemplate);
                        break;
                    case DB_PROVIDERS.GOOGLEANALYTICS.id:
                        templateFunction = _.template(googleAnalyticsTemplate);
                        break;
                    case DB_PROVIDERS.JIRA.id:
                        templateFunction = _.template(jiraTemplate);
                        break;
                    //DO-NOT-REMOVE-BELOW-COMMENT
                    //add-new-case
                    case DB_PROVIDERS.LINKEDINADS.id:
                        templateFunction = _.template(linkedInAdsTemplate);
                        break;
                    case DB_PROVIDERS.PAYMO.id:
                        templateFunction = _.template(paymoTemplate);
                        break;
                    case DB_PROVIDERS.BAMBOOHR.id:
                        templateFunction = _.template(bambooHrTemplate);
                        break;
                    default:
                        console.error("Invalid Template found (120) ");
                }

                const str = templateFunction(model.toJSON());
                api.compileTQL(str)
                    .done(function() {
                        NProgress.done();
                        resolve();
                    })
                    .fail(function(error) {
                        NProgress.done();
                        reject(error.message);
                    });
            } catch (e) {
                reject(e);
            }
        });
    }

    onBeforeDestroy() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }
}
