import React from "react";
import { Box } from "@material-ui/core";
import { StriimDropdown, StriimIconButton, StriimMenuList, StriimMenuItem } from "@striim/striim-ui";
import MoreHoriz from "../../../images/more-horiz.svg";
import WithTooltip from "../../../../../../../generic/tooltip/tooltip";

const iconStyles = {
    icon: {
        color: "transparent"
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
        paddingRight: "16px"
    }
};

const AppGroupDropdown = ({
    showExport,
    showEdit,
    showDelete,
    onExportClick,
    setDeleteDialogDisplayed,
    onNewGroupClicked,
    onEditGroupClicked,
    isDeletable
}) => {
    return (
        <div
            onClick={e => e.stopPropagation()} //to prevent app selection
            style={iconStyles.iconContainer}
        >
            <StriimDropdown
                extendOnClick
                closeOnSelect
                content={
                    <StriimMenuList>
                        <StriimMenuItem
                            onClick={() => {
                                onNewGroupClicked();
                            }}
                            data-test-id="app-metrics-show-flow--menu"
                            divider
                        >
                            New Group
                        </StriimMenuItem>
                        {showEdit && (
                            <StriimMenuItem
                                onClick={() => {
                                    onEditGroupClicked();
                                }}
                                data-test-id="app-metrics-show-flow--menu"
                            >
                                Edit Group
                            </StriimMenuItem>
                        )}
                        {showExport && (
                            <StriimMenuItem
                                onClick={() => {
                                    onExportClick();
                                }}
                                divider={showDelete}
                                data-test-id="app-metrics-show-flow--menu"
                            >
                                Export Content to TQL
                            </StriimMenuItem>
                        )}
                        {showDelete &&
                            WithTooltip(
                                <StriimMenuItem
                                    onClick={() => {
                                        setDeleteDialogDisplayed(true);
                                    }}
                                    disabled={!isDeletable}
                                    data-test-id="app-metrics-show-flow--menu"
                                >
                                    Delete Group
                                </StriimMenuItem>,
                                isDeletable ? null : "Stop the automated pipeline to delete it"
                            )}
                    </StriimMenuList>
                }
            >
                <StriimIconButton data-testid="app-group-menu">
                    <Box component={MoreHoriz} sx={iconStyles.icon} color="text.secondary" />
                </StriimIconButton>
            </StriimDropdown>
        </div>
    );
};

export default AppGroupDropdown;
