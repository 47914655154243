export const styles = {
    iconButton: {
        minWidth: "unset",
        px: 1,
        py: 1 / 2,
        "& .MuiButton-startIcon": {
            mx: 0
        }
    },
    icon: {
        fontSize: 16,
        fill: "none",
        "& > path": {
            stroke: ({ palette }) => palette.secondary[500]
        }
    },
    expanded: {
        transform: "rotate(180deg)"
    },
    leftPanel: {
        border: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
        borderTop: "none"
    },
    bottomBorder: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    panelContent: {
        height: `calc(100% - 69px)`
    },
    piiInfoContainer: {
        height: `calc(100% - 81px)`,
        overflowY: "auto"
    },
    piiInfoBackground: {
        background: ({ palette }) => palette.greyscale[50]
    },
    infoContainer: {
        flex: 1,
        backgroundColor: "greyscale.50",
        overflowY: "auto",
        maxHeight: "calc(100% - 324px)"
    },
    footer: {
        borderTop: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    downloadIcon: {
        fill: "none",
        "& > path": {
            stroke: "#F7FBFF"
        }
    },
    refreshButton: {
        minWidth: "20px",
        "& > span": {
            marginRight: 0,
            marginLeft: 0
        }
    },
    refreshIcon: {
        width: "20px",
        height: "20px"
    },
    retry: {
        "& > path": {
            fill: "#FFF"
        },
        marginRight: 0.5
    },
    buttonIcon: {
        fontSize: 22,
        fill: "none",
        "& path": {
            stroke: "#fff"
        }
    }
};
