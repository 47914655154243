export const styles = {
    container: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    descriptionWrapper: {
        width: 400,
        p: 2
    },
    caption: {
        fontFamily: "Inter",
        color: "greyscale.900"
    },
    iconWrapper: {
        cursor: "pointer",
        padding: 1 / 4,
        "& svg": {
            width: "20px",
            height: "20px",
            fill: "white"
        }
    },
    icon: {
        width: 14,
        height: 14,
        fill: ({ palette }) => palette.additional.white
    },
    expanded: {
        transform: "rotate(180deg)"
    },
    iconButton: {
        minWidth: "unset",
        px: 1,
        py: 1 / 2,
        "& .MuiButton-startIcon": {
            mx: 0
        }
    },
    startIcon: {
        fontSize: 16,
        fill: "none",
    },
    infoIcon: {
        width: 16,
        height: 16,
        fill: 'none'
    },
    aiText: {
        background: "linear-gradient(90deg, #F31779 0.1%, #7930D9 52.98%, #0C6FE8 99.87%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
    },
    aiIcon: {
        height: "16px",
        width: "auto"
    },
    reportName: {
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    }
};
