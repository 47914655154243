import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing }) => {
    return {
        importDashboardButton: {
            marginTop: spacing(4),
        },
        importDashboardCheckBox: {
            marginRight: "auto",
            marginTop: spacing(2)
        },
        importDashboardFileInput: {
            display: "flex",
            alignItems: "center",
            marginRight: "auto",
            marginBottom: spacing(2)
        },
        importDashboardFileHelperText: {
            marginLeft: spacing(1)
        }
    };
});
