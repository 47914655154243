export default {
    infoCircleIcon: {
        height: "20px",
        width: "20px",
        ml: 0.5,
        "& path": {
            fill: "none",
            stroke: ({ palette }) => palette.greyscale[600]
        }
    },
    multiselectContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#E8F7FF",
        border: ({ palette }) => `1px solid ${palette.secondary[50]}`,
        borderRadius: 2,
        height: "60px",
        p: 2
    },
    infoIcon: {
        "& path": {
            fill: "none",
            stroke: ({ palette }) => palette.greyscale[600]
        }
    },
    deleteIcon: {
        "& path": {
            fill: "none"
        }
    }
};
