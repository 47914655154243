import NestedTypes from "backbone.nestedtypes";
import Backbone from "backbone";
import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import FieldDetails from "./fieldDetails";
import fieldFilterHelper from "./fieldFilterHelper";
import template from "./fieldFilter.html";
import rowTemplate from "./fieldFilterRow.html";

var FieldFilter = {};

FieldFilter.FieldModel = NestedTypes.Model.extend({
    defaults: {
        name: String,
        type: String,
        currentValue: null,
    },
});

FieldFilter.FieldsCollection = Backbone.Collection.extend({
    model: FieldFilter.FieldModel,

    filterByName: function (text) {
        var filtered = this.filter(function (item) {
            if (!text) {
                return true;
            }

            return item.name.toUpperCase().indexOf(text.toUpperCase()) !== -1;
        });
        return new FieldFilter.FieldsCollection(filtered);
    },

    setActiveFlag: function (currentFilterValue) {
        for (var i = 0; i < this.length; i++) {
            if (currentFilterValue[this.models[i].name]) {
                this.models[i].currentValue = currentFilterValue[this.models[i].name];
            }
        }
        return this;
    },

    getSelectedFilters: function () {
        var result = [];
        for (var i = 0; i < this.length; i++) {
            if (this.models[i].currentValue) {
                result.push(this.models[i]);
            }
        }
        return result;
    },
});

FieldFilter.SearchResultItemView = Backbone.Marionette.ItemView.extend({
    template: _.template(rowTemplate),
    events: {
        "click .search-field": "searchFieldSelected",
    },
    ui: {
        fieldName: ".field-name",
    },
    initialize: function (options) {
        this.model = options.model;
    },
    onRender: function () {
        if (this.model.get("currentValue")) {
            this.ui.fieldName.addClass("active");
        }
    },
    searchFieldSelected: function () {
        this.model.trigger("field:selected", this.model);
    },
});

FieldFilter.SearchResultsView = Backbone.Marionette.CompositeView.extend({
    template: _.template("<div class='results'></div>"),
    childViewContainer: ".results",
    childView: FieldFilter.SearchResultItemView,
});

FieldFilter.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(template),

    className: "search-view",
    regions: {
        searchBoxRegion: ".search-box",
        searchResultsRegion: ".search-results",
        fieldSearchDetailsRegion: ".field-search-details",
    },
    ui: {
        searchBox: ".search-box",
        searchResults: ".search-results",
        fieldSearchDetails: ".field-search-details",
    },
    initialize: function (options) {
        this.text = UIControl.TextField.Search().create();

        this.collection = new FieldFilter.FieldsCollection(this.model.attributes.filterAttributes);
        this.collection.setActiveFlag(options.initialParameters || {});
        this.searchResultsView = new FieldFilter.SearchResultsView({
            collection: this.collection,
        });

        this.listenTo(
            this.text.model,
            "change:value",
            function (textControl) {
                var filtered = this.collection.filterByName(textControl.value);

                this.searchResultsRegion.currentView.collection = filtered;
                this.searchResultsRegion.currentView.render();
            }.bind(this)
        );

        for (var i = 0; i < this.collection.length; i++) {
            this.listenTo(
                this.collection.models[i],
                "field:selected",
                function (fieldModel) {
                    this._showDetails(fieldModel);
                }.bind(this)
            );

            this.listenTo(
                this.collection.models[i],
                "field-details:cancel",
                function () {
                    this.ui.fieldSearchDetails.hide();
                    this.ui.searchBox.show();
                    this.ui.searchResults.show();
                }.bind(this)
            );

            this.listenTo(
                this.collection.models[i],
                "field-details:apply",
                function (value) {
                    this.model.title = fieldFilterHelper.FormatTitle(this.collection.models, value);
                    this.model.trigger("field-filter:selected", value);
                }.bind(this)
            );
        }

        this.model.title = fieldFilterHelper.FormatTitle(this.collection.models, options.initialParameters);
    },
    focus: function () {
        this.text.focus();
    },
    onRender: function () {
        this.searchBoxRegion.show(this.text);
        this.searchResultsRegion.show(this.searchResultsView);

        var selectedFilters = this.collection.getSelectedFilters();
        if (selectedFilters.length > 0) {
            this._showDetails(selectedFilters[0]);
        }
    },
    _showDetails: function (fieldModel) {
        this.fieldSearchDetailsRegion.show(
            new FieldDetails.View({
                model: fieldModel,
            })
        );
        this.ui.fieldSearchDetails.show();
        this.ui.searchBox.hide();
        this.ui.searchResults.hide();
    },
});

export default FieldFilter;
