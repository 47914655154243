import api from "core/api/api";
import "app/components/common/editor/control/ui-control";
import _ from "underscore";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import Application from "core/services/metaStoreService/metaObjects/application";

export default function getCustomAdapterProperties(targetAdapter) {
    let that = this;

    const getSource = async app => {
        const appName = MetaObjectConverter.getName(app);
        const nsName = MetaObjectConverter.getNamespace(app);
        const appID = nsName + ".APPLICATION." + appName;
        const appModel = new Application.Model({ id: appID });
        const sourceObjects = await appModel.getApplicationComponentsByType("SOURCE");
        return sourceObjects.at(0);
    };

    const isCDCSource = sourceObject => {
        const adapterProps = sourceObject.get("adapter");
        if (adapterProps && adapterProps.handler) {
            return (
                adapterProps.handler.toLowerCase().indexOf("oraclereader") !== -1 ||
                adapterProps.handler.toLowerCase().indexOf("mysqlreader") !== -1 ||
                adapterProps.handler.toLowerCase().indexOf("postgresqlreader") !== -1 ||
                adapterProps.handler.toLowerCase().indexOf("mssqlreader") !== -1
            );
        }
        return false;
    };

    return new Promise((resolve, reject) => {
        (async () => {
            const existingProps = await api.getAdapterProperties(targetAdapter);
            const firstSource = await getSource(that.options.appName);
            const isCDC = isCDCSource(firstSource);
            let adapterProps = {};
            adapterProps = _.extend(adapterProps, existingProps);

            switch (targetAdapter) {
                case "SnowflakeWriter": {
                    adapterProps.appendOnly = !isCDC;
                    resolve(adapterProps);
                    if (existingProps["username"]) {
                        setTimeout(() => {
                            this.$el.find(".from-control-container-Tables input").prop("disabled", true);
                            this.$el
                                .find(".from-control-container-Tables input")
                                .prop(
                                    "title",
                                    "Sorry, you cannot edit the properties automatically filled by the wizard"
                                );
                        }, 1000);
                    }
                    break;
                }
                case "AzureSQLDWHWriter":
                case "RedSh": {
                    if (!isCDC) {
                        adapterProps.uploadPolicy = "eventcount:10000,interval:60s";
                    }
                    resolve(adapterProps);
                    break;
                }
                case "BigQuery": {
                    resolve(adapterProps);
                    break;
                }
                default:
                    resolve(adapterProps);
            }
        })();
    });
}
