const defaultMarker = 50;

/**
 * Calculates the marker size based on the chart configuration and a given range with invariant unit.
 * @param {number} value - current value
 * @param {number} minValue - minimum value that is present in the current dataset
 * @param {number} maxValue - maximum value that is present in the current dataset
 * @param {number} minBubbleSize - minimum size of the circle marker (unit invariant)
 * @param {number} maxBubbleSize - maximum size of the circle marker (unit invariant)
 * @returns {number} - Calculated radius of the circle marker in the new scale (between minBubbleSize & maxBubbleSize)
 */
export default function (value, minValue, maxValue, minBubbleSize, maxBubbleSize) {
    // handle invalid configuration and return a default marker with radius of 1000 m
    if (minBubbleSize < 0 || maxBubbleSize < 0 || minBubbleSize > maxBubbleSize) {
        return defaultMarker;
    }

    // return an average radius
    if (minValue === maxValue || minBubbleSize === maxBubbleSize) {
        return (minBubbleSize + maxBubbleSize) / 2;
    }

    if (value === minValue) {
        return minBubbleSize;
    }

    if (value === maxValue) {
        return maxBubbleSize;
    }

    // The value is calculated by scaling the value from one scale to another using this formula:
    // (value - minValue)/(maxValue-minValue)=(markerSize-minBubbleSize)/(maxBubbleSize-minBubbleSize).
    //
    // https://gamedev.stackexchange.com/questions/33441/how-to-convert-a-number-from-one-min-max-set-to-another-min-max-set

    const circleSize = ((value - minValue) * (maxBubbleSize - minBubbleSize)) / (maxValue - minValue) + minBubbleSize;

    return Math.floor(circleSize);
}
