import NestedTypes from "backbone.nestedtypes";
import PredefinedComponent from "./predefinedComponent";

var predefinedComponentSortedCollection = PredefinedComponent.Collection.extend({
    comparator: function(group) {
        return group.get("name");
    }
});

var PredefinedComponentGroup = NestedTypes.Model.extend({
    defaults: {
        id: String,
        name: String,
        isExpanded: false,
        components: predefinedComponentSortedCollection
    },

    properties: {
        hasVisibleComponents: function() {
            return (
                this.components.reject(function(componentModel) {
                    return componentModel.isVisible === false;
                }).length > 0
            );
        }
    }
});

export default PredefinedComponentGroup;
