import "select2";
import _ from "underscore";
import BaseControl from "../base-control";
import controlTemplate from "app/components/common/editor/control/connection-text-field/smtp-connection-template.html";
import { template, ui, on } from "core/decorators";
import api from "core/api/api";
import UIControl from "app/components/common/editor/control/ui-control";
import xss from "xss";

var Model = BaseControl.Model.extend({});

@template(controlTemplate)
@ui({
    loading: ".message.info",
    error: ".message.email-error",
    errorMessage: ".message.email-error .error-message",
    success: ".message.success",
    testEmail: "button.send-test-email"
})
class View extends BaseControl.View {
    toggleSMTPAuthFields(isSMTPAuthSelected) {
        if (!isSMTPAuthSelected) {
            $(".smtp-auth-field").hide();
            this.$("#username").val("");
            this.$("#password").val("");
        } else {
            $(".smtp-auth-field").show();
        }
    }

    onShow() {
        let smtpAuth = UIControl.Toggle.create({ labelClass: "smtp-auth-toggle" });
        this.$(".smtp-auth").append(smtpAuth.$el);
        smtpAuth.render();
        this.listenTo(
            smtpAuth.model,
            "change:value",
            function() {
                this.formModel.set(this.smtpAuthToggle, smtpAuth.model.value);
                this.toggleSMTPAuthFields(smtpAuth.model.value);
            }.bind(this)
        );
        smtpAuth.setValue(this.formModel.get(this.smtpAuthToggle) === "true");

        let startTLS = UIControl.Toggle.create();
        this.$(".checker").append(startTLS.$el);
        startTLS.render();
        this.listenTo(
            startTLS.model,
            "change:value",
            function() {
                this.formModel.set(this.starttlsField, startTLS.model.value);
            }.bind(this)
        );
        let that = this;
        this.ui.testEmail.prop("disabled", true);
        this.$("#to-address").change(function() {
            if ($(this).val()) {
                that.ui.testEmail.prop("disabled", false);
            } else {
                that.ui.testEmail.prop("disabled", true);
            }
        });
        startTLS.setValue(this.formModel.get(this.starttlsField) === "true");
    }

    @on("click button.send-test-email")
    onClickSendEmail() {
        let smtpUrl = xss(this.formModel.get(this.urlField));
        let smtpPort = this.formModel.get(this.portField);
        let smtpUser = xss(this.formModel.get(this.usernameField));
        let smtpPassword = xss(this.formModel.get(this.passwordField));
        let start_tls = this.formModel.get(this.starttlsField);
        let smtp_auth = this.formModel.get(this.smtpAuthToggle) === true;
        let toAddress = this.$("#to-address").val();
        let fromAddress = this.formModel.get(this.fromField);

        this.ui.error.hide();
        this.ui.success.hide();
        this.ui.loading.slideDown(
            {
                start: function() {
                    $(this).css({
                        display: "flex",
                        "align-items": "center"
                    });
                }
            },
            200
        );
        this.ui.testEmail.prop("disabled", true);
        api.sendTestEmail(smtpUrl, smtpPort, start_tls, smtp_auth, smtpUser, smtpPassword, fromAddress, toAddress)
            .then(() => {
                this.ui.loading.hide();
                this.ui.success.show(200);
            })
            .fail(e => {
                this.ui.loading.hide();
                this.ui.errorMessage.html(e.message);
                this.ui.error.show(200);
            })
            .always(() => {
                this.ui.testEmail.prop("disabled", false);
            });
    }

    getViewValue() {
        this.formModel.set(this.urlField, this.$("#url").val());
        this.formModel.set(this.portField, this.$("#port").val());
        this.formModel.set(this.usernameField, this.$("#username").val());
        this.formModel.set(this.passwordField, this.$("#password").val());
        this.formModel.set(this.fromField, this.$("#from-address").val());
    }

    setViewValue() {
        let model = this.formModel;
        this.$("#url").val(xss(model.get(this.urlField)));
        this.$("#port").val(xss(model.get(this.portField)));
        this.$("#username").val(xss(model.get(this.usernameField)));
        this.$("#password").val(xss(model.get(this.passwordField)));
        this.$("#from-address").val(xss(model.get(this.fromField)));
    }
}

export default function(
    formModel,
    urlField,
    portField,
    usernameField,
    passwordField,
    fromField,
    starttlsField,
    smtpAuthToggle
) {
    return _.extend({}, BaseControl, {
        Model: Model,
        View: View.extend({
            formModel: formModel,
            urlField: urlField,
            portField: portField,
            usernameField: usernameField,
            passwordField: passwordField,
            fromField: fromField,
            starttlsField: starttlsField,
            smtpAuthToggle: smtpAuthToggle,
            hideLabel: true
        })
    });
}
