export const styles = {
    endAdornmentContainer: {
        backgroundColor: "transparent",
        color: "greyscale.800",
        border: "none",
        padding: 0.5,
        "&:hover": {
            backgroundColor: "transparent",
            border: "none"
        },
        "&:active": {
            backgroundColor: "transparent",
            border: "none"
        }
    },
    iconButton: {
        width: 20,
        height: 20
    },
    inputField: {
        minWidth: "40px"
    }
};
