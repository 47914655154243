import api from "core/api/api";

const meteringApi = {
    meteringGetItemizedUsage: function(billingId) {
        return api.meteringGetItemizedUsage(billingId);
    },
    meteringGetConsumptions: function(billingId, itemId) {
        return api.meteringGetConsumptions(billingId, itemId);
    },
    meteringGetAggregatedConsumptions: function(billingId, itemId) {
        return api.meteringGetAggregatedConsumptions(billingId, itemId);
    },
    meteringGetRemainingCredits: function() {
        return api.meteringGetRemainingCredits();
    },
    meteringGetUsageSummaries: function() {
        return api.meteringGetUsageSummaries();
    },
    meteringGetAdapterRates: function() {
        return api.meteringGetAdapterRates();
    },
    meteringGetItemRate: function(itemId) {
        return api.meteringGetItemRate(itemId);
    },
    meteringGetInitialLoadConsumption: function(billingId, componentId) {
        return api.meteringGetInitialLoadConsumption(billingId, componentId);
    }
};

export default meteringApi;
