import {useMemo, useState} from "react";
import React from "react";
import {Box} from "@material-ui/core";
import { StriimButton, StriimMessageBox } from "@striim/striim-ui";
import SmartAlertsModal from "../smart-alerts-modal/smart-alerts-modal";
import PropTypes from "prop-types";
import { BellIcon } from "../../../../../../generic/icon/customIcons";
import {useTheme} from "@mui/material";


const getMessageBoxContent = (enabledAlertsCount, alertsCount, componentType) => {
    if (alertsCount === 0) {
        return "No Alerts are configured";
    }
    let countRep = enabledAlertsCount !== 0 ? enabledAlertsCount : "No";
    let alertsRep = enabledAlertsCount > 1 ? "Alerts" : "Alert";

    return `${countRep} ${alertsRep} enabled for this ${componentType.toLowerCase()}`;
};

const FlowAlertEnabledInfoBox = ({
    componentId,
    componentTitle,
    componentType,
    alertsCount,
    alertsCollection,
    toggleAlertStatus,
    addNewAlert,
    formLoading,
    enabledAlertsCount,
    tableRef
}) => {
    const theme = useTheme();

    const [showModal, setShowModal] = useState(false);

    // Emulate MessageBox type=NOTIFICATION
    const sxClasses = useMemo(() => ({
        "& .MuiCard-root": {
            backgroundColor: theme.palette.greyscale[100],
            border: `1px solid ${theme.palette.greyscale[200]}`,
            boxShadow: "unset",
            marginBottom: theme.spacing(1),
            display: "flex"
        },
        "& .MuiCardHeader-root": {
            padding: theme.spacing(1.5, 1)
        },
        "& .MuiCardHeader-avatar": {
            "& svg": {
                fill: theme.palette.greyscale[800]
            }
        },
        "& .MuiTypography-root": {
            color: theme.palette.greyscale[800]
        },
        "& .MuiCardActions-root": {
            marginLeft: "auto",
            padding: 0
        },
        "& .MuiButtonBase-root": {
            color: theme.palette.secondary[500],
            "&:hover,&:focus": {color: theme.palette.secondary[700]},
            "&:active": {color: theme.palette.secondary[900]},
        }
    }), [theme]);

    return (
        <Box sx={sxClasses}>
            <StriimMessageBox
                customIcon={<BellIcon fontSize={20} data-testid="my-custom-icon" />}
                text={getMessageBoxContent(enabledAlertsCount, alertsCount, componentType)}
                actions={
                    <StriimButton
                        variant="link"
                        sx={{ color: "black" }}
                        onClick={() => {
                            setShowModal(true);
                        }}
                        data-test-id="designer-alert-infoBox-configure-btn"
                    >
                        Configure
                    </StriimButton>
                }
                type="NOTIFICATION"
            />

            <SmartAlertsModal
                {...{
                    componentId,
                    componentTitle,
                    componentType,
                    alertsCollection,
                    toggleAlertStatus,
                    addNewAlert,
                    formLoading,
                    tableRef,
                    showModal,
                    setShowModal
                }}
            />
        </Box>
    );
};

FlowAlertEnabledInfoBox.propTypes = {
    componentId: PropTypes.string.isRequired,
    componentTitle: PropTypes.string.isRequired,
    componentType: PropTypes.string.isRequired,
    alertsCount: PropTypes.number.isRequired,
    alertsCollection: PropTypes.array.isRequired,
    toggleAlertStatus: PropTypes.func.isRequired,
    addNewAlert: PropTypes.func.isRequired,
    formLoading: PropTypes.bool.isRequired,
    enabledAlertsCount: PropTypes.number.isRequired,
    tableRef: PropTypes.shape({ current: PropTypes.object })
};

export default FlowAlertEnabledInfoBox;
