import _ from "underscore";
import { template, className, childView, childViewContainer, modelEvent } from "core/decorators";
import { LayoutView, CompositeView } from "marionette";
import App from "app";
import Backbone from "backbone";
import ConnectionValidationTemplate from "./templates/schema-validation-template.template.html";
import ConnectionValidationEntryTemplate from "./templates/connection-test-entry-template.html";
import * as Models from "./models";
import stringUtils from "core/utils/string-utils";

@template(ConnectionValidationEntryTemplate)
class ConnectionValidationEntry extends LayoutView {
    @modelEvent("change")
    modelChanged() {
        this.render();
    }

    onRender() {
        if (this.model.get("status") === "fail") {
            this.$el.find(".connection-remedy").slideDown("fast");
        }
        if (this.model.get("status") === "done") App.vent.trigger("appwizard:goForward", "validate-channel");
    }
}

@className("connection-test-view card")
@template(ConnectionValidationTemplate)
@childViewContainer("#connection-test-container")
@childView(ConnectionValidationEntry)
export default class ChannelValidation extends CompositeView {
    initialize() {
        let model = App.reqres.request("get:wizard:model");
        App.vent.trigger("appwizard:next:disable");
        App.vent.trigger("appwizard:back:enable");
        this.model = model;
        this.model.set("whatsInside", "Channel");
        let selectedSchema = this.model.get("selectedChannel");
        this.collection = new Backbone.Collection();
        this.collection.add({
            id: selectedSchema,
            status: "todo",
            text: "Fetching Metadata about " + selectedSchema,
            remedy: null,
            method: this.fetchObjects
        });
    }
    onRender() {
        this.collection.each(function(model) {
            model.set("status", "todo");
        });

        (async () => {
            let hasFailed = false;
            for (let i = 0; i < this.collection.length; i++) {
                let model = this.collection.at(i);
                if (hasFailed) {
                    model.set("status", "fail");
                    continue;
                }
                try {
                    await model.get("method").call(this, model);
                    model.set("status", "done");
                } catch (e) {
                    model.set("status", "fail");
                    model.set("remedy", e);
                    hasFailed = true;
                }
            }
        })();
    }

    async fetchObjects() {
        let data = await this.model
            .get("connectionParams")
            .fetchObjects(this.model.get("selectedChannel"), this.model.get("isCDC"));
        try {
            data = JSON.parse(data);
            if (data.result) {
                let tables = JSON.parse(data.message);
                let schemasCollection = this.prepareSchemasCollection(tables);
                this.model.set("schemas", schemasCollection);
                App.vent.trigger("appwizard:retry:hide");
            } else {
                throw new Error(data.message);
            }
        } catch (e) {
            throw new Error(e);
        }
    }
    prepareSchemasCollection(schemas) {
        let schemasCollection = new Models.Schemas();
        _.each(schemas, function(schema) {
            schemasCollection.add({
                id: schema,
                isEnabled: false,
                schemaName: schema,
                schemaDisplayName: stringUtils.encodeHtml(schema),
                tables: new Models.Tables()
            });
        });
        return schemasCollection;
    }

    canMoveForward() {
        let remainders = this.collection.where({ status: "todo" });
        this.model.set("whatsInside", "Objects");
        return new Promise(function(resolve, reject) {
            if (remainders.length === 0) {
                resolve();
            } else reject();
        });
    }

    retry() {
        this.render();
    }
}
