import React, { useEffect } from "react";
import api from "../../../../../../../../core/api/api";
import { TYPES } from "../../../../../../../../core/services/monitorService";
import useProgressStore from "../../../../../../../stores/table-progress/useProgressStore";
import automatedGroupStore from "../automated-app-group-details.store";

const INTERVAL = 15_000;

const useTableProgressStoreData = (appId: string, poll: boolean) => {
    const tableProgressStore = useProgressStore();

    const getStats = async () => {
        try {
            const stats = await api.getMonitoringStatsForApp(appId, TYPES.FULL);
            //@ts-ignore
            await tableProgressStore.setTableProgress(stats);
            automatedGroupStore.setisSchemeCreationRunning(tableProgressStore.ilInfo);
        } catch (error) {
            console.log("Error while fetching monitoring stats", error);
        }
    };

    useEffect(() => {
        //@ts-ignore
        if (!poll) {
            return;
        }
        tableProgressStore.reset();
        const intervalID = setInterval(() => {
            getStats();
        }, INTERVAL);
        getStats();
        return () => {
            clearInterval(intervalID);
        };
    }, [poll, appId]);
};

export default useTableProgressStoreData;
