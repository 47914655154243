import Backbone from "backbone";
import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var module = {};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "OPENPROCESSOR",
        adapter: Object,
        from: String,
        output: String,
        enrichment: Array,
    },
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model,
});

export default module;
