import Marionette from "marionette";
import _ from "underscore";
import TextField from "./../text-field/text-field";
import NumberField from "./../text-field/number-field";
import ShortTimeFormat from "./../interval/shortTimeFormat";
import PasswordField from "./../text-field/password-field";
import wactionStoreTemplates from "./wactionstore-templates.json";
import FormBuilder from "app/components/common/editor/form-builder";
import Consts from "./wactionstore-persistence-consts";

export default Marionette.LayoutView.extend({
    template: _.template("<div class='properties'></div>"),
    regions: {
        properties: ".properties",
    },

    setEnabled: function (enabled) {
        this.form.view.setReadOnly(!enabled);
    },

    onRender: function () {
        var _this = this;

        this.form = new FormBuilder({
            model: this.model,
            // readOnly: this.options.enabled, //TODO: how to pass enabled/disabled ?
            autoSave: true,
        });

        var storageProvider = this.model.get(Consts.PropertyNames.StorageProvider);
        var templates = wactionStoreTemplates[storageProvider];
        _.each(templates, function (template) {
            var field = null;
            switch (template.type) {
                case "password":
                    field = PasswordField.create();
                    break;
                case "integer":
                    field = NumberField.create();
                    break;
                case "interval":
                    field = ShortTimeFormat.create();
                    break;
                default:
                    field = TextField.create();
            }

            _this.form.addControl(field, template.fieldname);
        });
        var view = this.form.create();
        this.properties.show(view);

        view.startTracking();
    },
});
