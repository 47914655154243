import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./radiobutton-list.html";

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "",

    getViewValue: function () {
        return this.$("input:checked").val();
    },

    setViewValue: function () {
        this.$('input[name="' + this.model.name + '"]').val([this.getValue()]);
    },
});

export default function (dataSource, standardStyling) {
    var name = Math.random().toString(36).substring(7);
    var Model = BaseControl.Model.extend({
        defaults: {
            dataSource: dataSource,
            value: String,
            standardStyling: standardStyling || String,
            name: name,
        },
    });

    return _.extend({}, BaseControl, {
        Model: Model,
        View: View,
    });
}
