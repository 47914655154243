import api from "core/api/api";
import stringUtils from "core/utils/string-utils";

let monitorEventTypesCache = null;

export default function ensureMonitorEventTypesCache() {
    if (monitorEventTypesCache) {
        return new Promise((resolve) => {
            resolve(monitorEventTypesCache);
        });
    }
    return new Promise((resolve) => {
        api.getMonitorEventTypeValues().then((result) => {
            monitorEventTypesCache = {};
            result.forEach((item) => {
                // on the server underscores are replaced with dashes, so the key has to be transformed
                const key = stringUtils.replaceAll(item.name.toLowerCase(), "_", "-");
                monitorEventTypesCache[key] = item;
            });
            resolve(monitorEventTypesCache);
        });
    });
}
