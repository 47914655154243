import App from "app";
import "app/components/dashboard/visualization/charts/chart";

App.module("Chart.Label", function (Chart, App, Backbone, Marionette, $, _) {
    Chart.View = App.Chart.Default.ChartView.extend({
        className: "chart value",
        initialize: function () {
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
        },
        setConfiguration: function (config) {
            this.chartConfig = config;
        },
        serializeData: function () {
            return this.chartConfig;
        },
        render: function () {
            if (!this.chartConfig) {
                return console.warn("Render: Config malformed.");
            }

            var templateStr = "<div class='value_container'><div>" + this.chartConfig.config.label + "</div></div>";

            this.template = _.template(templateStr);
            Backbone.Marionette.ItemView.prototype.render.apply(this, arguments);

            this.$(".value_container div").addClass(this.chartConfig.config.labelHead);
            this.$(".value_container").css("color", this.chartConfig.config.color);
            this.$("div").css("background-color", this.chartConfig.config.background);
            this.$el.toggleClass("vertical-centered", this.chartConfig.config.verticallyCentered);

            this.model.config.lastRendered = new Date().getTime();
        },
    });
});
