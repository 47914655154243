import React, { useState } from "react";
import { StriimButton, StriimTable, StriimTypography } from "@striim/striim-ui";
import { ManageAccounts } from "src/generic/icon/customIcons";
import {
    AddUsersModal,
    applyCellRendererToColumns,
    EmptyTableInfo
} from "src/modules/apps/pages/user-management/common";
import { userColumnKey, userTableColumns } from "../../constants";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";
import { Grid, useTheme } from "@mui/material";
import styles from "../../../common/table-element.styles";

const getActionsCell = (user, revokedUsers, setRevokedUsers) => {
    const revoked = revokedUsers.map(r => r["User Id"]).indexOf(user["User Id"]) !== -1;

    return (
        <Grid container justifyContent="space-between">
            <StriimButton
                variant="text"
                onClick={() => {
                    setRevokedUsers([...revokedUsers, user]);
                }}
                disabled={revoked}
                data-testid={`${user["User Id"]}-revoke-button`}
                sx={styles.buttonWrapper}
            >
                {revoked ? "Revoked" : "Revoke"}
            </StriimButton>
            <StriimButton
                variant="text"
                onClick={() => {
                    setRevokedUsers(revokedUsers.filter(r => r["User Id"] !== user["User Id"]));
                }}
                data-testid={`${user["User Id"]}-undo-button`}
                style={revoked ? {} : { display: `none` }}
                sx={styles.buttonWrapper}
            >
                Undo
            </StriimButton>
        </Grid>
    );
};

const UsersTable = ({
    users,
    editable,
    setAddedUsers,
    addedUsers = [],
    revokedUsers = [],
    setRevokedUsers,
    prefetch = false
}) => {
    const { palette } = useTheme();

    const [addModalVisible, setAddModalVisible] = useState(false);

    const columnsWithRenderer = applyCellRendererToColumns(userTableColumns, revokedUsers);

    const columns = editable ? columnsWithRenderer : columnsWithRenderer.slice(0, 2);

    let usersToShow = [...users.map(toUsersRow).concat(addedUsers)];

    if (editable) {
        usersToShow = usersToShow.map(user => {
            const actions = getActionsCell(user, revokedUsers, setRevokedUsers);
            return { ...user, actions };
        });
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid container item alignItems="center" justifyContent="space-between">
                    <StriimTypography variant="h3" color="primary.900">
                        Users
                    </StriimTypography>
                    {editable && (
                        <StriimButton
                            variant="text"
                            onClick={() => setAddModalVisible(true)}
                            data-testid="add-user-button"
                            startIcon={<AddIcon />}
                        >
                            Add user
                        </StriimButton>
                    )}
                </Grid>
                <Grid item md={12}>
                    <StriimTable
                        data={usersToShow}
                        columns={columns}
                        TableProps={{ "data-testid": "users-table" }}
                        TableHeaderCellProps={{
                            style: {
                                backgroundColor: palette.greyscale[50]
                            }
                        }}
                        NoRowsComponent={
                            <EmptyTableInfo
                                Icon={ManageAccounts}
                                text={`You can start adding users using the 'Add user' button`}
                            />
                        }
                        indexedRow
                        TableBodyProps={{ seperated: true }}
                    />
                </Grid>
            </Grid>
            {editable && (
                <AddUsersModal
                    usersToExclude={usersToShow}
                    visible={addModalVisible}
                    onConfirm={selectedValues => {
                        selectedValues = selectedValues || [];
                        setAddedUsers([...addedUsers, ...selectedValues]);
                        setAddModalVisible(false);
                    }}
                    onCancel={() => {
                        setAddModalVisible(false);
                    }}
                    prefetch={prefetch}
                />
            )}
        </>
    );
};

const toUsersRow = (v, i) => ({
    id: i,
    [userColumnKey["User Id"]]: v["User Id"],
    contactInfo: v.contactInfo
});

export default UsersTable;
