import _ from "underscore";
import BaseControl from "./../base-control";
import DeploymentList from "./deployment-plan-form/deployment-plan-form";
import template from "./deployment-plan-list.html";

export default function () {
    var View = BaseControl.View.extend({
        template: _.template(template),

        initialize: function () {
            var _this = this;

            this.listModel = new DeploymentList.Model();
            _this.model.on("change", function (model) {
                _this.listModel.value = model.value;
            });
            this.listModel.on("change", function (model) {
                //prevents of getting empty value
                var values = _.filter(model.value, function (item) {
                    return item !== "undefined" && item !== null;
                });

                _this.setValue(
                    _.map(values, function (item) {
                        return item;
                    })
                );
            });

            this.listView = new DeploymentList.View({
                model: this.listModel,
            });
        },

        onRender: function () {
            this.$el.append(this.listView.render().$el);
        },

        getViewValue: function () {
            //prevents of getting empty value
            var values = _.filter(this.listView.model.value, function (item) {
                return item !== "undefined" && item !== null;
            });

            return _.map(values, function (value) {
                return value;
            });
        },

        setViewValue: function () {},
        setViewEnabled: function () {
            BaseControl.View.prototype.setViewEnabled.apply(this);
            this.listView.setEnabled(this.getEnabled());
        },
    });

    var Model = BaseControl.Model.extend({
        defaults: {
            value: Array,
        },
    });

    return _.extend({}, BaseControl, {
        Model: Model,
        View: View,
    });
}
