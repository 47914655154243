import statusManagement, { FlowStatus } from "../../../../../../../status-management";
import api from "../../../../../../../../core/api/api";
import dateLib, { timezone } from "../../../../../../../../core/utils/date-utils";

const verifyStatusToFetchTime = (appStatus: string): boolean => {
    if (!appStatus) return false;
    return (
        statusManagement.isApplicationRunning(appStatus) ||
        statusManagement.isStoppedStatus(appStatus) ||
        statusManagement.isApplicationCrash(appStatus) ||
        statusManagement.isApplicationInvalid(appStatus) ||
        appStatus === FlowStatus.QUIESCED
    );
};

const getRequiredTime = (focusedApp: string, focusedAppStatus: string): string => {
    if (statusManagement.isRunningState(focusedAppStatus)) {
        return focusedApp + "STARTTIME";
    } else if (
        statusManagement.isStoppedStatus(focusedAppStatus) ||
        statusManagement.isApplicationCrash(focusedAppStatus) ||
        statusManagement.isApplicationInvalid(focusedAppStatus) ||
        focusedAppStatus === FlowStatus.QUIESCED
    ) {
        return focusedApp + "STOPTIME";
    }
};

const formatTime = (time: number | string): string => {
    if (!time) {
        return "-";
    }
    const date = new Date(time);
    return dateLib(date).format(`MMM D, hh:mm A ${timezone}`);
};

const getTimeLabel = (status: string): string => {
    if (status.includes("START")) {
        return "Started";
    } else if (status.includes("STOP")) {
        return "Stopped";
    }
};

const getStartStopTime = async (
    ILAppId: string,
    CDCAppId: string,
    focusedApp: string,
    focusedAppStatus: string
): Promise<string> => {
    const startnStopTime = await api.getILCDCStartAndStopTime({ ILAppId, CDCAppId });

    const requiredTime = getRequiredTime(focusedApp, focusedAppStatus);
    const label = getTimeLabel(requiredTime);
    const formatedTimeWithDate = formatTime(startnStopTime[requiredTime]);

    return label + ": " + formatedTimeWithDate;
};

export { verifyStatusToFetchTime, getStartStopTime };
