import React, { useMemo } from "react";
import { Grid, Box } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import { observer } from "mobx-react";
import statusManagement from "../../../status-management";
import { AppStatusChip } from "../../apps/pages/app-list/components/app-tile/components/app-status-indicators";
import sxStyles from "./table-progress-modal.styles";
import dateLib, { timezone } from "core/utils/date-utils";
import useProgressStore from "../../../stores/table-progress/useProgressStore";

interface ModalHeaderProps {
    appStatus: string;
}

const ModalHeader = observer(({ appStatus }: ModalHeaderProps) => {
    const AppStatusChipMemoized = useMemo(() => {
        const appStatusLabel = statusManagement.getStatusDisplayName(appStatus);
        return <AppStatusChip label={appStatusLabel} hideLabel={false} />;
    }, [appStatus]);
    const progressStore = useProgressStore();
    const formatDate = useMemo(() => {
        const dateTime = progressStore.lastFetchedTime;
        if (!dateTime || !dateLib(dateTime).isValid()) {
            return "";
        }
        return (
            dateLib(dateTime)
                .format("MMM DD, YYYY hh:mm:ss a ")
                .toString() + timezone
        );
    }, [progressStore.lastFetchedTime]);
    return (
        <Grid container justifyContent="space-between">
            <Box sx={{ mt: 0.5 }}>
                <Grid container gap={1}>
                    <StriimTypography variant="h2">Application Progress</StriimTypography>
                    <Box>{AppStatusChipMemoized}</Box>
                </Grid>
            </Box>
            <div>
                <Grid container gap={2} alignItems="center">
                    <StriimTypography variant="caption" color="greyscale.500">
                        Updates every 5 secs
                    </StriimTypography>
                    <Box sx={sxStyles.separator}></Box>
                    <StriimTypography variant="caption" color="greyscale.500">
                        Last Updated {formatDate}
                    </StriimTypography>
                </Grid>
            </div>
        </Grid>
    );
});

export default ModalHeader;
