import { DB_PROVIDERS } from "../source/database-reader/configs/db-providers";

export const getDatabaseType = adapter => {
    switch (adapter) {
        case "SnowflakeILReader":
            return DB_PROVIDERS.SNOWFLAKE.id;
        case "OracleDB":
        case "OutputOracleDatabase":
        case "OracleReader":
            return DB_PROVIDERS.ORACLE.id;
        case "AzurePostgreSqlWriter":
        case "CloudSqlPostgreSqlWriter":
        case "OutputPostgresDatabase":
        case "PostgreSQLDB":
        case "PostgreSQLReader":
            return DB_PROVIDERS.POSTGRES.id;
        case "CloudSqlMySqlWriter":
        case "OutputMySQLDatabase":
        case "MySQLDB":
        case "MysqlReader":
            return DB_PROVIDERS.MYSQL.id;
        case "CloudSqlSQLServerWriter":
        case "OutputSQLServerDatabase":
        case "OutputAzureSQLServerDatabase":
        case "MSSQLDB":
        case "MSSqlReader":
            return DB_PROVIDERS.SQLSERVER.id;
        case "MariaDBDatabase":
        case "MariaDB":
            return DB_PROVIDERS.MARIADB.id;
        case "MariaDBXpandReader":
            return DB_PROVIDERS.MARIADBXPAND.id;
        case "MSJet":
            return DB_PROVIDERS.MSJET.id;
        case "OJet":
            return DB_PROVIDERS.OJET.id;
        case "YugabyteDBReader":
            return DB_PROVIDERS.YUGABYTE.id;
        case "BigQuery":
        case "BigQueryWriter":
            return "BIGQUERY";
        case "SnowflakeWriter":
            return "SNOWFLAKE";
        case "DeltaLakeWriter":
            return "DELTALAKE";
        case "AzureSQLDWHWriter":
            return "AZURESYNAPSE";
        case "SalesForceReader":
        case "SalesforceCDCReader":
        case "Salesforce":
            return DB_PROVIDERS.SALESFORCE.id;
        case "ServiceNowReader":
            return DB_PROVIDERS.SERVICENOW.id;
        case "SalesforcePardotReader":
            return DB_PROVIDERS.SALESFORCEPARDOT.id;
        case "HubSpotReader":
            return DB_PROVIDERS.HUBSPOT.id;
        case "ZendeskReader":
            return DB_PROVIDERS.ZENDESK.id;
        case "StripeReader":
            return DB_PROVIDERS.STRIPE.id;
        case "FabricDataWarehouseWriter":
            return "FABRIC";
        case "JiraReader":
            return DB_PROVIDERS.JIRA.id;
        case "IntercomReader":
            return DB_PROVIDERS.INTERCOM.id;
        case "GoogleAdsReader":
            return DB_PROVIDERS.GOOGLEADS.id;
        default:
            return adapter?.toUpperCase() ?? null;
    }
};
