import api from "core/api/api";
import $ from "jquery";
import _ from "underscore";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import BaseControl from "./../base-control";
import Select from "./../select/select";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import MetaObjectDataSource from "./../select/metaobject-datasource";
import template from "./stream-selector.html";

var View = BaseControl.View.extend({
    template: _.template(template),
    ui: {
        newObject: ".newObject",
        existingObject: ".existingObject",
        options: "input[type=radio]",
        groupControls: ".group-control",
        newRadio: ".new-radio",
        existingRadio: ".existing-radio",
        title: "h3",
        newStreamTitle: ".new-stream-text",
        existingStreamTitle: ".existing-stream-text"
    },
    regions: {
        existingObject: ".existingObject"
    },
    className: "new-or-existing-selector",
    events: {
        "click @ui.options": "onChangedType"
    },

    /**
     * When set then used to get initial value for model
     * @type {function}
     * @public
     */
    initialValueGetter: null,

    initialize: function(options) {
        options = options || {};
        this.hideLabel = true;
        var typesToShow = options.typesToShow || metaStoreService.entities.STREAM;
        let externalComponentIDs = null;
        if (options?.externalStreams) {
            externalComponentIDs = [];
            api.read(undefined, { type: "STREAM" }).then(results => {
                results.forEach(result => {
                    if(result?.isExternal){
                        externalComponentIDs.push(result.id);
                    }
                });
            });
        }
        var streams = new MetaObjectDataSource(typesToShow, null, null, externalComponentIDs);
        this.typeSelector = Select(streams, {
            type: metaStoreService.entities.STREAM
        }).create();
        this._resources = options.resources;
        if (options.initialValueGetter) {
            this.initialValueGetter = options.initialValueGetter;
        }
    },
    onChangedType: function(e) {
        if (!this.model.enabled) {
            return;
        }

        var target = e.target;
        this.mode = target.value;

        var selectedType = $(target).parents(".group");
        this.ui.groupControls.hide();
        selectedType.find(".group-control").show();
        this.$(".group").removeClass("active");
        selectedType.addClass("active");

        this.typeSelector.setValue(null);
        this.ui.newObject.val("");
        this.ui.newObject.removeClass("existingError");
        this.ui.newObject.prop("title", "");
        this.alreadyExistsError = false;
        this.setValueFromView();
        this.trigger("mode:set", this.mode);
    },
    getViewValue: function() {
        if (this.alreadyExistsError) {
            return null;
        }

        var name = this.ui.newObject.val();
        if (name) {
            var namespace = api.getCurrentNamespace();
            return MetaObjectConverter.getId(namespace, metaStoreService.entities.STREAM, name);
        } else {
            return this.typeSelector.getValue();
        }
    },

    setViewEnabled: function() {
        if (!this.ui.newObject.prop) {
            return;
        }

        var enabled = this.getEnabled();
        this.model.enabled = enabled;
        this.typeSelector.setEnabled(enabled);
        this.ui.newObject.prop("disabled", !enabled);
        this.ui.options.prop("disabled", !enabled);
    },

    validate: function(propertyName) {
        var name = this.ui.newObject.val();

        if (this.options.required === false) {
            return false;
        }

        if (!name && !this.typeSelector.getValue()) {
            this.showError(`Value for ${propertyName} is required`);
            return true;
        }
        return false;
    },
    setViewValue: function() {},

    onRender: function() {
        var _this = this;
        this.existingObject.show(this.typeSelector);

        this.ui.newObject.on("keyup", function() {
            var name = _this.ui.newObject.val();
            var namespace = api.getCurrentNamespace();
            var id = MetaObjectConverter.getId(namespace, metaStoreService.entities.STREAM, name);
            metaStoreService.findById(id).then(function(stream) {
                var errorMessage = "That stream already exists. Please choose a different name.";
                if (stream) {
                    _this.ui.newObject.addClass("existingError");
                    _this.ui.newObject.prop("title", errorMessage);
                } else {
                    _this.ui.newObject.removeClass("existingError");
                    _this.ui.newObject.prop("title", "");
                }
                _this.alreadyExistsError = !!stream;
                _this.setValueFromView();
                if (_this.alreadyExistsError) {
                    _this.trigger("value:invalid", errorMessage, name);
                }
            });
        });

        this.typeSelector.on("value:set", function() {
            _this.setValueFromView();
        });

        var value;
        if (typeof this.initialValueGetter === "function") {
            value = this.initialValueGetter(this.model);
        } else {
            value = this.getValue();
        }
        if (value) {
            this.ui.existingObject.show();
            this.ui.newObject.hide();
            this.ui.existingObject.parent().addClass("active");
            this.typeSelector.setValue(value);
            this.ui.existingRadio.prop("checked", true);
        } else {
            this.ui.existingObject.hide();
            this.ui.newObject.show();
            this.ui.newObject.parent().addClass("active");
            this.ui.newRadio.prop("checked", true);
        }

        if (this._resources) {
            this.ui.title.text(this._resources.title || "OUTPUT TO");
            this.ui.newStreamTitle.text(this._resources.newStreamTitle || "New Output");
            this.ui.existingStreamTitle.text(this._resources.existingStreamTitle || "Existing Output");
        }
    }
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: String,
        radioButtonName: "stream-selector"
    }
});

export default _.extend({}, BaseControl, {
    View: View,
    Model: Model
});
