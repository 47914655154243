import UIControl from "app/components/common/editor/control/ui-control";

var Transformation = {};

Transformation.OnlyDbSources = false;

Transformation.createColumnControl = function() {
    return UIControl.ColumnJoin.extend({
        hideLabel: true,
        hidden: false
    }).create();
};

Transformation.buildTql = function(tqlBuilder, values) {
    if (!values || values.length !== 1) {
        return;
    }

    const value = values[0];
    if (!value.outputFields || value.outputFields.length === 0) {
        tqlBuilder.addWildcard();
    } else {
        value.outputFields.forEach(field => {
            tqlBuilder.addColumn(field.column, null, null, field.alias);
        });
    }
    tqlBuilder.addJoinClause(value.column, value.target, value.targetColumn);
};

export default Transformation;
