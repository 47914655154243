import App from "app";
import api from "core/api/api";
import $ from "jquery";
import _ from "underscore";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import GrowlFrom from "app/components/common/GrowlFrom";
import "../metadataService/defaultmetaobject";
import NestedTypes from "backbone.nestedtypes";

var growl = new GrowlFrom("queryService");

const FULL_TEXT_SEARCH_QUERY_PARAM = ":search IS NULL or AnyAttrLike".toLowerCase();

var Queries = {};

Queries.Field = NestedTypes.Model.extend({
    defaults: {
        field: String,
        type: String,
    },
});
Queries.FieldsCollection = NestedTypes.Collection.extend({
    model: Queries.Field,
});

Queries.ProjectionField = NestedTypes.Model.extend({
    defaults: {
        name: String,
        type: String,
    },
});
Queries.ProjectionFieldCollection = NestedTypes.Collection.extend({
    model: Queries.ProjectionField,
});

Queries.WAQueryModel = App.Metadata.DefaultMetaObject.Model.extend({
    MAX_DATA_SIZE: 100,
    defaults: {
        fields: Queries.FieldsCollection,
        queryDefinition: "",
        typeInfo: Object,
        data: Array,
        params: Object,
        instance_uuid: String,
        queryParameters: Array,
        projectionFields: Array,
        canBeFiltered: Boolean,
        filterAttributes: Array,
        subscriberCount: 0,
        queryString: String,
        ctime: null,
        isEditable: null,
    },
    properties: {
        canPoll: function () {
            return (
                this.queryDefinition.toLowerCase().indexOf("and push") === -1 &&
                this.queryDefinition.toLowerCase().indexOf("heartbeat(") === -1
            );
        },
        hasFullTextSearchQueryParam: function () {
            return this.queryDefinition.toLowerCase().indexOf(FULL_TEXT_SEARCH_QUERY_PARAM) !== -1;
        },
    },
    sync: function (method) {
        var $deferred = new $.Deferred();
        var that = this;

        var full_id = metaObjectConverter.getId(this.nsName, metaStoreService.entities.QUERY, this.name);
        var api_method = method.toUpperCase();
        var json_data = this.toJSON();
        json_data.id = full_id;
        json_data.type = metaStoreService.entities.QUERY;
        if (!this.name) {
            $deferred.resolve(this);
        } else {
            var api_promise = api._CRUDHandler(undefined, api_method, json_data);
            api_promise
                .then(function(data) {
                    data = data[0];
                    data = that.parse(data);
                    that.set(data);
                    $deferred.resolve(that);
                })
                .fail(function (e) {
                    $deferred.reject(e);
                });
        }

        return $deferred;
    },
    url: function () {
        var base = "queries";

        if (this.get("id")) {
            base += "/" + this.get("id");
        }
        base += "?token=" + api.token;
        return base;
    },
    initialize: function () {
        this.dataSize = this.MAX_DATA_SIZE;

        this.listenTo(this, "change:projectionFields", this.generateFields);
        App.Metadata.DefaultMetaObject.Model.prototype.initialize.apply(this, arguments);
    },
    generateFields: function () {
        var _this = this;
        this.get("fields").reset();
        var projectionFields = this.get("projectionFields");
        _(projectionFields).each(function (f) {
            _this.get("fields").add({
                field: f.name,
                type: f.type,
            });
        });
    },
    prepare: function () {
        var _this = this;
        var d = $.Deferred();
        var promise = Queries.prepareQuery(this.get("uuid"), this.get("params"));
        promise.then(function(data) {
            _this.set("instance_uuid", data.uuid.uuidstring);
            d.resolve(_this);
        });

        promise.fail(function () {
            d.reject();
        });
        return d;
    },
    startInstance: function () {
        if (this.get("instance_uuid") === "") {
            return;
        }

        var d = $.Deferred();
        this.startListeningForQueryData();
        this.trigger("query:start");
        Queries.startQuery(this.get("instance_uuid"), this.get("name")).then(function() {
            d.resolve();
        });
        return d;
    },

    stop: function () {
        var _this = this;
        var instance_id = this.get("instance_uuid");
        if (typeof instance_id === "undefined" || instance_id === null || instance_id === "") {
            return;
        }
        Queries.stopQuery(instance_id, this.get("name")).then(function() {
            Queries.deleteQuery(instance_id, _this.get("name"));
            _this.stopListeningForQueryData();
            _this.set("instance_uuid", undefined);
        });
    },
    incrementSubscribers: function () {
        this.subscriberCount++;
    },
    decrementSubscribers: function () {
        this.subscriberCount--;
        if (this.subscriberCount === 0) {
            this.stop();
        }
    },
    startListeningForQueryData: function () {
        this.listenTo(App.vent, this.instance_uuid + ":data", this.handleIncomingData);
    },
    stopListeningForQueryData: function () {
        this.stopListening(App.vent, this.instance_uuid + ":data", this.handleIncomingData);
    },
    handleIncomingData: function (data) {
        this.trigger("incoming:data", data);
    },
    clearParameters: function () {
        this.params = {};
    },
    updateParameters: function (parameters) {
        var didWeUpdateExisting = this.updateOrAddExistingParameters(parameters);
        var didWeRemoveStale = this.removeStaleParameters(parameters);
        return didWeUpdateExisting || didWeRemoveStale;
    },
    updateOrAddExistingParameters: function (parameters) {
        var didWeUpdateParameters = false;
        var updatedParams = {};
        for (var key in parameters) {
            if (this.hasParameter(key)) {
                updatedParams[key] = parameters[key];
                didWeUpdateParameters = true;
            }
        }
        if (didWeUpdateParameters) {
            this.params = updatedParams;
        }
        return didWeUpdateParameters;
    },
    hasParameter: function (name) {
        for (var i = 0; i < this.queryParameters.length; i++) {
            var currentParameter = this.queryParameters[i];
            if (currentParameter === name) {
                return true;
            }
        }
        return false;
    },
    removeStaleParameters: function (parameters) {
        var didWeUpdateParameters = false;
        var updatedParams = _.extend({}, this.params);
        for (var key in this.params) {
            if (!parameters.hasOwnProperty(key)) {
                delete updatedParams[key];
                didWeUpdateParameters = true;
            }
        }
        if (didWeUpdateParameters) {
            this.params = updatedParams;
        }
        return didWeUpdateParameters;
    },
});

Queries.QueryCollection = NestedTypes.Collection.extend({
    model: Queries.WAQueryModel,
    url: "/metadata/queries",
});

// TODO: pass these parameters in a better format
Queries.createParameterizedQuery = function (name, queryString) {
    var d = new $.Deferred();
    var promise = api.createParameterizedQuery(queryString, name);
    promise.then(function (data) {
        d.resolve(data);
        data.status = "success";
        // TODO: this is currently the only place queries can get created. When they are created elsewhere, let"s find a better way to notify the side menu to re-render itself
        App.vent.trigger("queries-change");
    });
    promise.fail(function (data) {
        data.status = "error";
        d.reject(data);
        growl.error('"' + name + '" -  ' + data.message, "Failed creating query");
    });

    return d;
};

Queries.prepareQuery = function (uuid, params) {
    var d = new $.Deferred();
    var promise = api.prepareParameterizedQuery(uuid, params);
    promise.then(function (data) {
        d.resolve(data);
    });
    promise.fail(function (data) {
        d.reject(data);
        growl.error(data.message, "Failed preparing query");
    });

    return d;
};

Queries.startQuery = function (uuid, name) {
    var d = new $.Deferred();
    var promise = api.startAHQuery(uuid);
    promise.then(function (data) {
        d.resolve(data);
    });
    promise.fail(function (data) {
        d.reject(data);
        growl.error('"' + name + '" -  ' + data.message, "Failed starting query");
    });

    return d;
};

Queries.stopQuery = function (uuid, name) {
    var d = new $.Deferred();
    var promise = api.stopAHQuery(uuid);
    promise.then(function (data) {
        d.resolve(data);
    });
    promise.fail(function (data) {
        growl.error('"' + name + '" -  ' + data.message, "Failed stopping query");
    });

    return d;
};

Queries.deleteQuery = function (uuid, name) {
    var d = new $.Deferred();
    var promise = api.deleteAHQuery(uuid);
    promise.then(function (data) {
        d.resolve(data);
    });
    promise.fail(function (data) {
        d.reject(data);
        growl.error('"' + name + '" -  ' + data.message, "Failed deleting query");
    });

    return d;
};

Queries.allQueriesCollection = NestedTypes.Collection.extend({
    model: Queries.WAQueryModel,
    url: "queries",
    sync: function (method) {
        var $deferred = new $.Deferred();
        var that = this;

        var api_method = method.toUpperCase();
        var json_data = {};
        json_data.type = "QUERY";
        var api_promise = api._CRUDHandler(undefined, api_method, json_data);
        api_promise
            .then(function(data) {
                _.each(data, function (query) {
                    var model = new Queries.WAQueryModel(query);
                    that.add(model);
                });
                $deferred.resolve(that);
            })
            .fail(function (e) {
                $deferred.reject(e);
            });

        return $deferred;
    },
});

/**
 * Gets the list of named queries from the RMI Over Websocket magic.
 * @TODO ideally should be called getNamedQueries
 * @returns {jQuery.Deferred}
 */
Queries.readAllQueries = function () {
    var queries = new Queries.allQueriesCollection();

    var deferred = $.Deferred();
    queries
        .fetch()
        .then(function() {
            deferred.resolve(queries);
        })
        .fail(function () {
            deferred.reject();
        });
    return deferred.promise();
};

export default Queries;
