import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette }) => ({
    name: {
        display: "block",
        color: palette.common.white
    },
    desc: {
        color: palette.gray
    },
    tooltipText: {
        whiteSpace: "wrap",
        textOverflow: "ellipsis"
    }
}));
