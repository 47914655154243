import React from "react";
import {
    StriimIconWrapperBase,
    StriimTypography,
    StriimModal,
    StriimButton,
    StriimMessageBox
} from "@striim/striim-ui";
import { Box, SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FileImport, NewTab } from "../../../../generic/icon/customIcons";
import { icons } from "../../../../generic/icon/flow-designer-icon";
import metaObjectHelper from "../../../../../app/components/common/editor/meta-object-editors/metaObjectHelper";

const styles = {
    customIcon: {
        "& svg": {
            fill: "transparent"
        },
        height: 30,
        width: "auto"
    }
};

const useStyles = makeStyles(() => ({
    iconWrapperBase: {
        borderRadius: "10px !important",
        width: "30px !important",
        height: "30px !important"
    }
}));

interface AutoFillEntitiesModalProps {
    setIsModalShown: (value: boolean) => void;
    sources: any;
    onContinue?: () => void;
}

const AutoFillEntitiesModal = ({ sources, setIsModalShown, onContinue }: AutoFillEntitiesModalProps) => {
    const sourceObject = metaObjectHelper.getIconClassByMetaObject(sources[0], true);
    const classes = useStyles();

    return (
        <StriimModal
            isVisible={true}
            size="small"
            autoHeight
            onCancel={() => setIsModalShown(false)}
            titleContent={
                <Box display="flex" justifyContent="space-between">
                    <SvgIcon sx={styles.customIcon} onClick={() => setIsModalShown(true)}>
                        <FileImport />
                    </SvgIcon>
                    <StriimTypography sx={{ ml: "8px !important" }} variant={"h2"} color="primary.700">
                        Auto-fill entities using the latest sensitive data discovery results.
                    </StriimTypography>
                </Box>
            }
            footerContent={
                <div>
                    <StriimButton
                        variant="secondaryText"
                        data-test-id="cancel-button"
                        onClick={() => setIsModalShown(false)}
                    >
                        Cancel
                    </StriimButton>

                    <StriimButton
                        data-test-id="continue-button"
                        variant="primary"
                        onClick={() => {
                            setIsModalShown(false);
                            if (onContinue) onContinue();
                        }}
                        ml={1}
                    >
                        Continue
                    </StriimButton>
                </div>
            }
        >
            <StriimTypography mt={3} variant="body4" color="greyscale.800">
                Auto-fill the fields w/ sensitive data as discovered by the Sherlock report in the connected Source. The
                default action assigned is “No Action” and can be customized for each field.
            </StriimTypography>
            <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
                <Box display="flex" alignItems="center">
                    <StriimTypography variant="h4">Source:</StriimTypography>
                    <Box sx={{ fontSize: 30 }} mx={1}>
                        <StriimIconWrapperBase
                            Icon={icons[sourceObject.className]}
                            classNameContainer={classes.iconWrapperBase}
                            type={"source"}
                        />
                    </Box>
                    <StriimTypography variant="caption3">{sourceObject.name}</StriimTypography>
                </Box>
                <StriimButton variant="text" endIcon={<NewTab />}>
                    Report
                </StriimButton>
            </Box>
            <StriimMessageBox
                heading={
                    <StriimTypography color="greyscale.900" variant="caption3">
                        This will override all actions currently specified on entities, and cannot be undone.
                    </StriimTypography>
                }
                type="NOTIFICATION"
            />
        </StriimModal>
    );
};

export default AutoFillEntitiesModal;
