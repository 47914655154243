import { jsPDF } from "jspdf";
import utils from "../../../../../../../../core/utils";
import metaobjectConverter from "../../../../../../../../core/services/metaStoreService/metaobject-converter";
import { getILName } from "../../../../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";
import { TableProgressStore } from "../../../../../../table-progress/table-progress.store";
import api from "../../../../../../../../core/api/api";
import { TYPES } from "../../../../../../../../core/services/monitorService";
import growl from "../../../../../../../../app/components/common/growl";
import Papa from "papaparse";
import { saveAs as saveFileAs } from "file-saver";
import _ from "underscore";
import Worker from "../../../../../../../../workers/mon-metrics-download.worker";
import * as Comlink from "comlink";
import { StriimToast } from "@striim/striim-ui-v2";
import ManageStriimService from "../../../../manage-striim/manage-striim-service";
import { DB_PROVIDERS } from "../../../../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/configs/db-providers";
import { getDatabaseType } from "../../../../../../../../app/components/appwizard/component-wizards/wizards/common/databaseTypes";
import dateLib from "core/utils/date-utils";

const IlReportColumnHeaders = [
    "Source",
    "Target",
    "Source Schema Name",
    "Source Table Name",
    "Target Schema Name",
    "Target Table Name",
    "Rows Read",
    "Rows Written",
    "Last Write Timestamp",
    "Schema Creation Status"
];

const getFilePath = async () => {
    const theFiles = await (await fetch("/file_browser")).json();
    const defaultPath = theFiles?.currentPath || "";
    return defaultPath;
};

function getIlAppId(groupId: string): string {
    const namespace = utils.getNamespace(groupId);
    const groupName = utils.getName(groupId);

    const appName = getILName(groupName);
    return metaobjectConverter.getId(namespace, "APPLICATION", appName);
}
async function downloadReport(groupId: string, cb: Function): Promise<void> {
    if (!groupId) {
        console.log("Group Id is required");
        return;
    }
    const appID: string = getIlAppId(groupId);
    await cb(appID);
}

async function downloadAllReports(groupId: string, ilAppID: string): Promise<void> {
    if (!groupId) {
        console.log("Group Id is required");
        return;
    }
    const groupName: string = utils.getName(groupId);
    await downloadILReport(ilAppID);
    // await downloadTablesReport(groupName);
    await downloadAutomatedReport(groupName);
}

function getNameWithCurrentTime(fileName: string, extension: string): string {
    const currentTime = dateLib(Date.now())
        .format("YYYYMMDD_hhmm")
        .toString();

    if (fileName.includes(".")) {
        const parts = fileName.split(".");
        fileName = parts[0];
    }
    return `${fileName}_${currentTime}.${extension}`;
}

async function downloadILReport(appID: string) {
    if (!appID) {
        console.log("App Id is required");
        return;
    }
    try {
        const tableProgressStore = new TableProgressStore();
        const appName = utils.getName(appID);
        const stats = await api.getMonitoringStatsForApp(appID, TYPES.FULL);
        await tableProgressStore.setTableProgress(stats);
        const data = await callWebWorkerIlProcessData(tableProgressStore.tableProgress);
        const contentVal = Papa.unparse(data, {
            header: false
        });

        const headersVal = Papa.unparse({ fields: IlReportColumnHeaders, data: [] });
        const csvData = headersVal + contentVal;
        const fileName = getNameWithCurrentTime(`${appName.replace("_", " ")} Report`, "csv");
        saveFileAs(
            new Blob([csvData], {
                type: "text/csv"
            }),
            fileName
        );
    } catch (e) {
        console.log(e);
        growl.error("Failed to download IL report");
    }
}
const blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File(
        [theBlob as any], // cast as any
        fileName,
        {
            lastModified: new Date().getTime(),
            type: theBlob.type
        }
    );
};

export async function uploadFileToFiles(blob: Blob, name: string) {
    // Delete File if exists
    const filesPath = await getFilePath();
    const path = `${filesPath}${name}`;
    await ManageStriimService.deleteFile(path);
    // Upload files
    const file: File = blobToFile(blob, name);
    await onFileUpload(file, filesPath);
}
// returns a singleton object for worker class
let tableProgressWorkerInstance = null;
export async function getWorker() {
    if (!tableProgressWorkerInstance) {
        const worker = new Worker();
        const TableProgressServiceClass = Comlink.wrap(worker);
        return await new TableProgressServiceClass();
    }
    return tableProgressWorkerInstance;
}

async function callWebWorkerIlProcessData(tableProgressData) {
    const worker = await getWorker();
    return await worker.processData(tableProgressData);
}

const onFileUpload = (file: File, path: string) => {
    if (file.name[0] === ".") {
        StriimToast({
            title: "Cannot upload hidden files",
            severity: "error"
        });
        return;
    }
    const formData = new FormData();
    formData.append("file", file);
    const xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
        xhr.open("POST", `/upload?path=${path}`);
        xhr.onload = () => {
            if (xhr.readyState !== 4) return;
            if (xhr.status !== 200) {
                if (xhr.status !== 0) {
                    reject("File upload failed");
                }
            }
            const fileWithUpdatedPath = { path: xhr?.responseText || file.path, name: file.name };
            resolve("File uploaded successfully");
        };
        xhr.send(formData);
    });
};

const getGroupReportName = (groupName: string) => `${groupName} - Automated Pipeline Configuration.pdf`;

const getTablesReportName = (groupName: string) => `${groupName} - Source Table Selection.csv`;

const generateAutomatedReport = (groupName: string, shouldUpload: boolean) => {
    const doc = new jsPDF({ orientation: "p", unit: "pt", format: "a4" });
    const fileName = getGroupReportName(groupName);

    return new Promise(resolve => {
        doc.html(document.getElementById("section-to-print"), {
            x: 24,
            y: 45,
            html2canvas: { scale: 0.75 },
            callback: async function(doc) {
                const pdfOutput = doc.output("blob");
                if (shouldUpload) await uploadFileToFiles(pdfOutput, fileName);
                else doc.save(groupName);
                resolve(true);
            }
        });
    });
};

export const getSourceObjects = adapterProps => {
    if (adapterProps.properties.ZendeskObjects) return adapterProps.properties.ZendeskObjects.split(";");
    if (adapterProps.properties.sObjects) return adapterProps.properties.sObjects.split(";");
    else
        return adapterProps.properties.Tables
            ? adapterProps.properties.Tables.split(";")
            : adapterProps.properties.tables.split(";");
};

const generateTablesReport = async (sourceAdapter, groupName: string) => {
    const sourceDB =
        sourceAdapter.properties.DatabaseProviderType ?? getDatabaseType(sourceAdapter.properties.adapterName);
    const isObject = DB_PROVIDERS[sourceDB.toUpperCase()]?.whatIsTable === "Object" ?? false;
    let Columns, data;

    if (isObject) {
        Columns = ["Objects"];
        const Objects = getSourceObjects(sourceAdapter);
        data = Objects.map(obj => [obj]);
    } else {
        Columns = ["Schema Name", "Table Name"];
        const Tables = sourceAdapter.properties["Tables"].split(";");
        const removeQuotes = str => str.split('"').join("");

        data = Tables.map(table => {
            const parts = table.split(".");
            return [removeQuotes(parts[0]), removeQuotes(parts[1])];
        });
    }

    const csvString = Papa.unparse({ fields: Columns, data: data });
    return new Blob([csvString], { type: "text/csv" });
};

const downloadFile = (filePath: string, customFileName: string) => {
    ManageStriimService.downloadFile(filePath, customFileName);
};

const downloadAutomatedReport = async (groupName: string) => {
    const fileName = getGroupReportName(groupName);
    const filePath = await getFilePath();
    const path = `${filePath}${fileName}`;
    await downloadFile(path, fileName);
};

const downloadTablesReport = async (groupName: string, csvBlob: Blob) => {
    const name = getTablesReportName(groupName);
    const fileName = getNameWithCurrentTime(name, "csv");
    await saveFileAs(csvBlob, fileName);
};

export {
    downloadReport,
    downloadILReport,
    generateAutomatedReport,
    generateTablesReport,
    downloadAutomatedReport,
    downloadTablesReport,
    downloadAllReports
};
