import Backbone from "backbone";
import { View } from "./base-database-reader";
import { DB_PROVIDERS } from "./configs/db-providers";

class MariaDBXpandDB extends View {
    setup() {
        this.model.get("connectionParams").set("type", DB_PROVIDERS.MARIADBXPAND.id);
    }
}

export default {
    View: MariaDBXpandDB,
    vent: new Backbone.Model(),
};