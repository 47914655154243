import appWizardTemplateModel from "app/components/appwizard/appWizardTemplateModel";
import allTemplates from "./resources/generated/templates.json";
import predefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";

var module = {};

module.licensingOptions = {
    default: [
        "oracleDB",
        "oracleCDC",
        "sqlServerDB",
        "sqlServerCDC",
        "MySqlDB",
        "MySqlCDC",
        "MSJet",
        "OJet",
        "PostgresCDC",
        "PostgresDB",
        "IncrementalBatch",
        "SnowflakePartnerConnectCDC",
        "SnowflakePartnerConnectIL",
        "GoogleBigQuery",
        "DeltaLake"
    ],
    AzureSQLServer: ["AzureSQLServer", "AzureHDInsight", "AzureStorage"],
    AzureHDInsight: ["AzureHDInsight", "AzureSQLServer", "AzureStorage"],
    AzureStorage: ["AzureStorage", "AzureHDInsight", "AzureSQLServer"],
    AzureDMS: [
        "oracleDB",
        "oracleCDC",
        "sqlServerDB",
        "sqlServerCDC",
        "MSJet",
        "OJet",
        "MySqlDB",
        "MySqlCDC",
        "PostgresDB"
    ],
    AzureSqlDW: ["AzureSQLDWH", "IncrementalBatch", "sqlServerCDC", "MSJet", "OJet", "oracleCDC", "MySqlCDC"],
    AzureSqlDwIncrementalSource: [
        "AzureSQLDWH",
        "IncrementalBatch",
        "sqlServerCDC",
        "MSJet",
        "OJet",
        "oracleCDC",
        "MySqlCDC"
    ],
    AzureCosmosDB: [
        "AzureCosmosCassandraDB",
        "AzureCosmosDB",
        "AzureSQLDWH",
        "AzureHDInsight",
        "sqlServerCDC",
        "MSJet",
        "AzureStorage",
        "AzureSQLServer",
        "AzureCosmosMongoDB"
    ],
    GooglePostgresSql: ["GooglePostgresSql", "GoogleMySql", "GoogleGCS", "GoogleBigQuery", "GoogleSpanner"],
    GoogleMySql: ["GoogleMySql", "GooglePostgresSql", "GoogleGCS", "GoogleBigQuery", "GoogleSpanner"],
    GoogleGCS: ["GoogleGCS", "GooglePostgresSql", "GoogleMySql", "GoogleBigQuery", "GoogleSpanner"],
    GoogleBigQuery: ["GoogleBigQuery", "GooglePostgresSql", "GoogleMySql", "GoogleGCS", "GoogleSpanner"],
    AzurePostgreSQL: ["AzurePostgreSQL", "AzureHDInsight", "AzureStorage", "AzureSQLDWH"],
    AwsRedShift: ["AwsRedShift", "oracleCDC", "sqlServerCDC", "MSJet", "OJet", "MySqlCDC"],
    GoogleSpanner: ["GoogleSpanner", "GoogleGCS", "GoogleBigQuery", "GooglePostgresSql", "GoogleMySql"],
    Snowflake: ["Snowflake", "oracleCDC", "sqlServerCDC", "MSJet", "OJet", "MySqlCDC"],
    DeltaLake: ["DeltaLake", "oracleCDC", "sqlServerCDC", "MSJet", "OJet", "MySqlCDC"],
    SnowflakePartnerConnect: ["SnowflakePartnerConnectIL", "SnowflakePartnerConnectCDC"],
    AppAdaptersStripe: ["AppAdaptersStripe"],
    AppAdaptersZendesk: ["AppAdaptersZendesk"],
    AppAdaptersHubspot: ["AppAdaptersHubspot"]
};
const CDCSources = ["OracleCDC", "PostgreSQLReader", "MSSQLCDC", "MSJet", "OJet", "MySQLCDC", "MariaDBReader"];
const DBSources = ["OracleDB", "", "MSSQLDB", "MySQLDB", "PostgreSQLDB", "MariaDB", "MariaDBXpandDB"];

const hasCDCSources = function(steps) {
    let intersection = CDCSources.filter(x => steps.includes(x));
    return intersection.length >= 1;
};

const hasDBSources = function(steps) {
    let intersection = DBSources.filter(x => steps.includes(x));
    return intersection.length >= 1;
};

module.campaigns = {
    defaultHero: {
        title: "Integrate into Snowflake",
        helpText: "Snowflake",
        filter: template => hasDBSources(template.steps) && template.uses.indexOf("DatabaseWriter") !== -1,
        icon: predefinedComponentNames["SnowflakeWriter"].className
    },
    oracleCDC: {
        title: "Streaming Integration",
        helpText: "Oracle CDC",
        filter: template => template.steps.indexOf("OracleCDC") !== -1,
        icon: predefinedComponentNames["OracleCDC"].className
    },
    OJet: {
        title: "Streaming Integration",
        filter: template => template.steps.indexOf("OJet") !== -1,
        icon: predefinedComponentNames["OJet"].className
    },
    oracleDB: {
        title: "Initial Load",
        helpText: "Oracle",
        filter: template => template.steps.indexOf("OracleDB") !== -1,
        icon: predefinedComponentNames["OracleDB"].className
    },
    sqlServerCDC: {
        title: "Streaming Integration",
        helpText: "SQL Server CDC",
        filter: template => template.steps.indexOf("MSSQLCDC") !== -1,
        icon: predefinedComponentNames["MSSQLCDC"].className
    },
    sqlServerDB: {
        title: "Initial Load",
        helpText: "SQL Server",
        filter: template => template.steps.indexOf("MSSQLDB") !== -1,
        icon: predefinedComponentNames["MSSQLDB"].className
    },
    MSJet: {
        title: "Streaming Integration",
        helpText: "MSJet",
        filter: template => template.steps.indexOf("MSJet") !== -1,
        icon: predefinedComponentNames["MSJet"].className
    },

    MySqlCDC: {
        title: "Streaming Integration",
        helpText: "MySql CDC",
        filter: template => template.steps.indexOf("MySQLCDC") !== -1,
        icon: predefinedComponentNames["MySQLCDC"].className
    },
    MySqlDB: {
        title: "Initial Load",
        helpText: "MySql",
        filter: template => template.steps.indexOf("MySQLDB") !== -1,
        icon: predefinedComponentNames["MySQLDB"].className
    },
    PostgresCDC: {
        title: "Streaming Integration",
        helpText: "Postgres CDC",
        filter: template => template.steps.indexOf("PostgreSQLReader") !== -1,
        icon: predefinedComponentNames["PostgreSQLReader"].className
    },
    PostgresDB: {
        title: "Initial Load",
        helpText: "Postgres DB",
        filter: template => template.steps.indexOf("PostgreSQLDB") !== -1,
        icon: predefinedComponentNames["PostgreSQLDB"].className
    },
    AzureSQLServer: {
        title: "Streaming Integration",
        helpText: "Azure SQLServer",
        filter: template => template.steps.indexOf("OutputSQLServerDatabase") !== -1,
        icon: predefinedComponentNames["OutputSQLServerDatabase"].className
    },
    AzureHDInsight: {
        title: "Streaming Integration",
        helpText: "Azure HD Insight",
        filter: template =>
            template.steps.indexOf("HDInsightHDFSWriter") !== -1 ||
            template.steps.indexOf("HDInsightKafkaWriter") !== -1,
        icon: predefinedComponentNames["HDInsightHDFSWriter"].className
    },
    AzureStorage: {
        title: "Streaming Integration",
        helpText: "Azure Storage",
        filter: template => template.steps.indexOf("AzureDataLakeStoreGen2") !== -1,
        icon: predefinedComponentNames["AzureDataLakeStoreGen2"].className
    },
    AzureEventHub: {
        title: "Streaming Integration",
        helpText: "Azure Event Hub",
        filter: template => template.steps.indexOf("AzureEventHubWriter") !== -1,
        icon: predefinedComponentNames["AzureEventHubWriter"].className
    },
    AzureSQLDWH: {
        title: "Streaming Integration",
        helpText: "Azure SQL DWH",
        filter: template => template.steps.indexOf("AzureSQLDWHWriter") !== -1,
        icon: predefinedComponentNames["AzureSQLDWHWriter"].className
    },
    IncrementalBatch: {
        title: "Streaming Integration",
        helpText: "Incremental Batch",
        filter: template => template.steps.indexOf("IncrementalBatchReader") !== -1,
        icon: predefinedComponentNames["IncrementalBatchReader"].className
    },
    GooglePostgresSql: {
        title: "Streaming Integration",
        helpText: "Google Postgres Sql",
        filter: template => template.steps.indexOf("CloudSqlPostgreSqlWriter") !== -1,
        icon: predefinedComponentNames["CloudSqlPostgreSqlWriter"].className
    },
    GoogleMySql: {
        title: "Streaming Integration",
        helpText: "Google MySql",
        filter: template => template.steps.indexOf("CloudSqlMySqlWriter") !== -1,
        icon: predefinedComponentNames["CloudSqlMySqlWriter"].className
    },
    GoogleGCS: {
        title: "Streaming Integration",
        helpText: "Google GCS",
        filter: template => template.steps.indexOf("GCSWriter") !== -1,
        icon: predefinedComponentNames["GCSWriter"].className
    },
    GoogleBigQuery: {
        title: "Streaming Integration",
        helpText: "Google Big Query",
        filter: template => template.steps.indexOf("BigQuery") !== -1,
        icon: predefinedComponentNames["BigQuery"].className
    },
    AzureCosmosCassandraDB: {
        title: "Streaming Integration",
        helpText: "Azure Cosmos Cassandra DB",
        filter: template => template.steps.indexOf("CassandraCosmosDBWriter") !== -1,
        icon: predefinedComponentNames["CassandraCosmosDBWriter"].className
    },
    AzureCosmosDB: {
        title: "Streaming Integration",
        helpText: "Azure Cosmos DB",
        filter: template => template.steps.indexOf("CosmosDBWriter") !== -1,
        icon: predefinedComponentNames["CosmosDBWriter"].className
    },
    AzureCosmosMongoDB: {
        title: "Streaming Integration",
        helpText: "Azure Cosmos MongoDB",
        filter: template => template.steps.indexOf("MongoCosmosDBWriter") !== -1,
        icon: predefinedComponentNames["MongoCosmosDBWriter"].className
    },
    AzurePostgreSQL: {
        title: "Streaming Integration",
        helpText: "Azure Postgres SQL",
        filter: template => template.steps.indexOf("AzurePostgreSqlWriter") !== -1,
        icon: predefinedComponentNames["AzurePostgreSqlWriter"].className
    },
    AwsRedShift: {
        title: "Streaming Integration",
        helpText: "Amazon RedShift",
        filter: template => template.steps.indexOf("RedSh") !== -1,
        icon: predefinedComponentNames["RedSh"].className
    },
    GoogleSpanner: {
        title: "Streaming Integration",
        helpText: "Google Spanner",
        filter: template => template.steps.indexOf("SpannerWriter") !== -1,
        icon: predefinedComponentNames["SpannerWriter"].className
    },
    Snowflake: {
        title: "Snowflake",
        helpText: "Snowflake",
        filter: template => template.steps.indexOf("SnowflakeWriter") !== -1,
        icon: predefinedComponentNames["SnowflakeWriter"].className
    },
    SnowflakePartnerConnectIL: {
        title: "Initial Load",
        helpText: "Snowflake Partner Connect IL",
        filter: template => template.steps.indexOf("SnowflakeWriter") !== -1 && hasDBSources(template.steps),
        icon: predefinedComponentNames["SnowflakeWriter"].className
    },
    SnowflakePartnerConnectCDC: {
        title: "Streaming Integration",
        helpText: "Snowflake Partner Connect CDC",
        filter: template => template.steps.indexOf("SnowflakeWriter") !== -1 && hasCDCSources(template.steps),
        icon: predefinedComponentNames["SnowflakeWriter"].className
    },
    Salesforce: {
        title: "Salesforce",
        helpText: "Salesforce",
        filter: template => template.steps.indexOf("Salesforce") !== -1 && hasCDCSources(template.steps),
        icon: predefinedComponentNames["Salesforce"].className
    },
    File: {
        title: "File",
        helpText: "File",
        filter: template => template.steps.indexOf("File") !== -1 && hasCDCSources(template.steps),
        icon: predefinedComponentNames["File"].className
    },
    DeltaLake: {
        title: "Streaming Integration to Databricks",
        filter: template => template.steps.indexOf("DeltaLakeWriter") !== -1,
        icon: predefinedComponentNames["DeltaLakeWriter"].className
    },
    AppAdaptersStripe: {
        title: "Stripe Apps (Pipelines)",
        filter: template => template.steps.indexOf("StripeReader") !== -1,
        icon: predefinedComponentNames["StripeReader"].className
    },
    AppAdaptersZendesk: {
        title: "Zendesk Apps (Pipelines)",
        filter: template => template.steps.indexOf("ZendeskReader") !== -1,
        icon: predefinedComponentNames["ZendeskReader"].className
    },
    AppAdaptersHubspot: {
        title: "HubSpot Apps (Pipelines)",
        filter: template => template.steps.indexOf("HubSpotReader") !== -1,
        icon: predefinedComponentNames["HubSpotReader"].className
    },
    MariaDBXpandDB: {
        title: "Initial Load",
        helpText: "MariaDB Xpand",
        filter: template => template.steps.indexOf("MariaDBXpandDB") !== -1,
        icon: predefinedComponentNames["MariaDBXpandDB"].className
    }
};

module.adapterToCampaign = {
    "Global.PROPERTYTEMPLATE.OracleReader": "oracleCDC",
    "Global.PROPERTYTEMPLATE.MysqlReader": "MySqlCDC",
    "Global.PROPERTYTEMPLATE.MSSqlReader": "sqlServerCDC",
    "Global.PROPERTYTEMPLATE.PostgreSQLReader": "PostgreSQLCDC"
};

module.templatesList = allTemplates;

module.getByURL = function(url) {
    for (let i = 0; i < module.templatesList.length; i++) {
        if (module.templatesList[i].url.indexOf(url) !== -1) {
            return module.templatesList[i];
        }
    }
};

export default module;
