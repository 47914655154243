import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import queryParameters from "core/services/queryService/queryParameters.json";

var globalParameters = {};
var parameters = {};
var timeFields = {};

var filter = {
    getSavedQueryParameters: function (queryUUID) {
        if (queryUUID && parameters[queryUUID]) {
            return parameters[queryUUID];
        } else {
            return {};
        }
    },
    setTimeField: function (queryUUID, timeField) {
        timeFields[queryUUID] = timeField;
    },
    getParameters: function (queryUUID) {
        var savedParameters = filter.getSavedQueryParameters(queryUUID);
        return _.extend({}, globalParameters, savedParameters);
    },
    prepareParametersForQuery: function (queryUUID, parameters) {
        var specificTimeField = timeFields[queryUUID];
        var timeFilterValue = parameters[queryParameters.Time];
        if (specificTimeField && timeFilterValue) {
            delete parameters[queryParameters.Time];
            parameters[specificTimeField] = timeFilterValue;
        }
        return parameters;
    },
    setParameters: function (params, queryUUID) {
        if (queryUUID && !parameters[queryUUID]) {
            parameters[queryUUID] = {};
        }
        for (var p in params) {
            if (params.hasOwnProperty(p)) {
                var value = params[p];
                if (value) {
                    if (queryUUID) {
                        parameters[queryUUID][p] = value;
                    } else {
                        globalParameters[p] = value;
                    }
                } else {
                    if (queryUUID) {
                        delete parameters[queryUUID][p];
                    } else {
                        delete globalParameters[p];
                    }
                }
            }
        }

        var queryId = queryUUID || "global";
        this.trigger(queryId, this.getParameters(queryUUID));
        this.trigger("params-changed", queryId);
    },
    clearParameters: function (queryUUID, exceptFields) {
        if (!queryUUID) {
            globalParameters = {};
            parameters = {};
            return;
        }

        if (!parameters[queryUUID]) {
            return;
        }

        var cleared = {};
        if (exceptFields && exceptFields.length > 0) {
            for (var i = 0; i < exceptFields.length; i++) {
                var fieldName = exceptFields[i];
                if (parameters[queryUUID][fieldName]) {
                    cleared[fieldName] = parameters[queryUUID][fieldName];
                }
            }
        }

        parameters[queryUUID] = cleared;
    },
    isEmpty: function (queryUUID) {
        var parameter = parameters[queryUUID];
        if (!parameter) {
            return true;
        }

        for (var prop in parameter) {
            if (parameter.hasOwnProperty(prop)) {
                return false;
            }
        }

        return true;
    },
};

_.extend(filter, Backbone.Events);

export default filter;
