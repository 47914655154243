import React, { useContext, useEffect, useRef, useState } from "react";
import WizardLayout from "../../../common/wizard-layout/wizard-layout";
import Footer from "../../../common/footer/footer";
import { Steps, getCurrentStepHeader, stepDetails } from "../../target-steps";
import { WizardContext } from "../../../wizard-context";
import Header from "../../../common/header/header";
import Stepper from "../../../common/stepper/stepper";
import { Review } from "../../../common/review/review";
import AppsCreation from "../apps-creation/apps-creation";

import useStores from "src/utils/use-stores";
import utils from "../../../../../../core/utils";
import AppListService from "../../../../apps/pages/app-list/app-list-service";

import growl from "../../../../../../app/components/common/growl";
import sanitizer from "../../../../../../app/components/appwizard/component-wizards/wizards/target/base-simple-target/server-error-sanitizer.js";
import headerStore from "../../../../../stores/header";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import Printable from "../../../common/printable/printable-component";
import localStorageUtils from "../../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/local-storage-utils";
import { targetUtils } from "../../../utils";
import NProgress from "nprogress";

import _ from "underscore";
import { getTargetTables, saveTargetWithTable } from "../../../services/target-tables-helper";
import { getTruncatedName } from "../../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";
import {
    generateAutomatedReport,
    generateTablesReport
} from "../../../../apps/pages/app-list/components/automated-app-group-detail/services/download-reports.service";

import Tracker from "../../../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../../core/services/tracker/constants";
NProgress.configure({
    minimum: 0.25,
    trickleRate: 0.1,
    trickleSpeed: 2000
});

const APP_NAME = {
    InitialLoad: "IL App Configuration",
    CDC: "CDC App Configuration",
    Automated: "Automated Pipeline Configuration",
    Automated_Mode_App_Adapter: "App Configuration"
};
interface SummaryProps {
    target: string;
}

const ReviewWrapper = ({ target }: SummaryProps) => {
    const {
        wizardAppName,
        setCurrentStep,
        sourceInfo,
        targetInfo,
        cdcSourceInfo,
        cdcTargetInfo,
        isAutomatedILCDC,
        wizardType
    } = useContext(WizardContext);
    const { groupsStore } = useStores();
    const ref = useRef(null);
    const [navigateToAppsCreation, setNavigateToAppsCreation] = useState<string>(null);
    const heading = getCurrentStepHeader(Steps.Review);
    const stepper = <Stepper steps={stepDetails} activeStep={stepDetails.Review.id} />;
    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);
    const [ilTablesProperty, setIlTablesProperty] = useState({
        value: "",
        changed: false
    });
    const [cdcTablesProperty, setCdcTablesProperty] = useState({
        value: "",
        changed: false
    });
    const groupName = getTruncatedName(utils.getName(sourceInfo.appId));
    const reportName = `${isAutomatedILCDC ? groupName : utils.getName(sourceInfo.appId)} - ${APP_NAME[wizardType]}`;

    const moveToNewGroup = async () => {
        await groupsStore.fetch();
        const group = groupsStore.groups.find(g => g.name === groupName);
        AppListService.setSelectedGroup(group);
    };

    const handleSaveAndRun = async () => {
        try {
            setNavigateToAppsCreation("START");
            const appId = sourceInfo.appId;
            if (isAutomatedILCDC) {
                let title = "App Metrics - " + `${utils.getNamespace(appId)}.${utils.getName(appId)}`;
                let item = { attr: false, title: title, comp: "App", appId: appId };
                const list = JSON.parse(localStorage.getItem("seenWidgets")) || [];
                list.splice(1, 0, item);
                localStorage.setItem("seenWidgets", JSON.stringify(list));
            }
            let appModel = await metaStoreService.findById(appId);
            headerStore.addToLatestApps(appModel.toJSON());
        } catch (e) {
            console.error(e);
        } finally {
            if (isAutomatedILCDC) moveToNewGroup();
        }
    };

    const handleSaveAndExit = () => {
        setNavigateToAppsCreation("EXIT");
        if (isAutomatedILCDC) moveToNewGroup();
        clearLocalStorageItems();
    };
    const clearLocalStorageItems = () => {
        localStorageUtils.cleanUp(wizardAppName);
    };

    const generateReports = async () => {
        const groupName = getTruncatedName(utils.getName(sourceInfo.appId));
        try {
            await generateAutomatedReport(groupName, true);
            // await generateTablesReport(sourceInfo.sourceAdapter, groupName);
        } catch (err) {
            console.error("Report generation failed: " + err);
        }
    };

    const saveTargetandProceed = async (toProceedNext: boolean) => {
        try {
            if (isAutomatedILCDC) await generateReports();
            setIsBtnDisabled(true);
            NProgress.start();
            const prevIlTables = await getTargetTables(sourceInfo?.appId);
            const prevCDCTables = await getTargetTables(cdcSourceInfo?.appId);
            if (ilTablesProperty.changed) {
                if (ilTablesProperty.value === "") {
                    growl.error("IL table mapping cannot be empty.Please enter a value");
                    return;
                }
                await saveTargetWithTable(sourceInfo.appId, ilTablesProperty.value);
            }
            // if Il and cdc table mapping are same, only the il table mapping will be shown
            // save the cdc target with il table mapping
            if (isAutomatedILCDC && prevCDCTables === prevIlTables && ilTablesProperty.changed) {
                await saveTargetWithTable(cdcSourceInfo.appId, ilTablesProperty.value);
            }
            if (cdcTablesProperty.changed) {
                if (cdcTablesProperty.value === "") {
                    growl.error("CDC table mapping cannot be empty.Please enter a value");
                    return;
                }
                await saveTargetWithTable(cdcSourceInfo.appId, cdcTablesProperty.value);
            }
            toProceedNext ? handleSaveAndRun() : handleSaveAndExit();
        } catch (e) {
            let msg = "Please correct the errors and try again.";
            if (Array.isArray(e)) {
                msg += e.join(", ");
            } else {
                msg += sanitizer.sanitize(e.message);
            }
            growl.error(msg, "Error saving your target.");
        } finally {
            NProgress.done();
            setIsBtnDisabled(false);
        }
    };

    const trackEvent = buttonClicked => {
        const id = isAutomatedILCDC ? cdcSourceInfo.appId : sourceInfo.appId;
        const stepName = stepDetails.Review.stepName;
        const sourceAdapter = isAutomatedILCDC ? cdcSourceInfo.adapterName : sourceInfo.adapterName;

        Tracker.getInstance().track(
            TRACKER_STRINGS.schema.wizardsSummary,
            {
                event: TRACKER_STRINGS.eventClicked.wizards,
                buttonClicked: buttonClicked,
                id: id,
                isAutomatedWizard: isAutomatedILCDC,
                step: stepName,
                source: sourceAdapter,
                target: target
            },
            TRACKER_STRINGS.version.withModification
        );
    };

    return (
        <>
            {navigateToAppsCreation ? (
                <AppsCreation target={target} startApp={navigateToAppsCreation.toUpperCase() === "START"} />
            ) : (
                <WizardLayout
                    Header={
                        <Header
                            heading={heading}
                            rightContent={
                                <Printable
                                    name={reportName}
                                    component={
                                        <Review
                                            sourceInfo={sourceInfo}
                                            targetInfo={targetInfo}
                                            cdcSourceInfo={cdcSourceInfo}
                                            target={target}
                                            isPDFReport={true}
                                            ilTablesProperty={ilTablesProperty}
                                            setIlTablesProperty={setIlTablesProperty}
                                            cdcTablesProperty={cdcTablesProperty}
                                            setCdcTablesProperty={setCdcTablesProperty}
                                        />
                                    }
                                    buttonLabel={APP_NAME[wizardType]}
                                />
                            }
                        />
                    }
                    Stepper={stepper}
                    Footer={
                        <Footer
                            onNext={() => {
                                // On Save and Run
                                saveTargetandProceed(true);
                                trackEvent("Save and Run");
                            }}
                            onBack={() => {
                                if (targetUtils.skipTargetValidation(target)) setCurrentStep(Steps.ConfigureTarget);
                                else setCurrentStep(Steps.ValidateTarget);
                                setNavigateToAppsCreation(false);
                                trackEvent("Back");
                            }}
                            next="Save & Start"
                            nextTooltip={
                                isAutomatedILCDC
                                    ? "Deploy and start the automated pipeline"
                                    : "Deploy and start the App"
                            }
                            hasSave={true}
                            hasBack={false}
                            backDisabled={isBtnDisabled}
                            nextDisabled={isBtnDisabled}
                            saveDisabled={isBtnDisabled}
                            onSave={() => {
                                // On Save and Exit
                                saveTargetandProceed(false);
                                trackEvent("Save and Exit");
                            }}
                        />
                    }
                >
                    <Review
                        sourceInfo={sourceInfo}
                        targetInfo={targetInfo}
                        cdcSourceInfo={cdcSourceInfo}
                        target={target}
                        ilTablesProperty={ilTablesProperty}
                        setIlTablesProperty={setIlTablesProperty}
                        cdcTablesProperty={cdcTablesProperty}
                        setCdcTablesProperty={setCdcTablesProperty}
                    />
                </WizardLayout>
            )}
        </>
    );
};

export default ReviewWrapper;
