import React, { useCallback, useState, useEffect, useRef } from "react";
import CollapsibleSection from "src/generic/collapsible-section/collapsible-section";
import { StriimSimpleTable, StriimButton, StriimTypography } from "@striim/striim-ui-v2";
import VaultModal from "./vault-modal";
import VaultValueRow from "./vault-value-row";
import VaultsService from "./vaults-service";
import VaultCollapsibleContent from "./vault-collapsible-content";
import VaultsHeader from "./vaults-header";
import _ from "lodash";
import growl from "app/components/common/growl";
import { VaultTypes } from "./constants";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "app/images/striimline/add.svg";
import api from "../../../../../../core/api/api";

const useStyles = makeStyles(() => ({
    collapsibleSection: {
        marginBottom: 34,

        "& .MuiTableContainer-root": {
            overflowX: "visible"
        }
    }
}));

const sxStyles = {
    div: {
        padding: 24,
        textAlign: "center"
    },
    vaultValueButton: {
        mb: 0.25
    }
};

const VaultData = ({
    vault,
    addVaultValue,
    onAdded,
    collapsibleRef,
    mapVaultValues,
    isModalShown,
    setIsModalShown,
    rowStyles
}) => {
    const classes = useStyles();
    const [allowedActions, setAllowedActions] = useState({
        edit: false,
        delete: false
    });

    useEffect(() => {
        (async function() {
            try {
                const uuid = vault?.attributes?.uuid;
                const hasUpdatePermission = await api.checkPermission(uuid, "update");
                const hasDropPermission = await api.checkPermission(uuid, "drop");
                setAllowedActions({
                    edit: hasUpdatePermission,
                    delete: hasDropPermission
                });
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const isEmptyVault = vault => {
        const values = mapVaultValues(vault, allowedActions.edit, allowedActions.delete);
        return !values || !values?.length;
    };

    // Have "ACTION" header only for Striim Vault
    const head =
        vault?.vaultType === VaultTypes.STRIIM_VAULT
            ? [
                  "Vault Key",
                  "Vault Value Type",
                  "Vault Value",
                  "Vault Usage",
                  (allowedActions.edit || allowedActions.delete) && ""
              ]
            : ["Vault Key", "Vault Value", "Vault Usage"];
    return (
        <div key={vault?.attributes?.name} className={classes.collapsibleSection} data-test-id="vault">
            <CollapsibleSection
                label={vault?.attributes?.name}
                content={
                    <VaultCollapsibleContent
                        vault={vault}
                        isEditAllowed={allowedActions.edit}
                        isDeleteAllowed={allowedActions.delete}
                        addVaultValue={addVaultValue}
                        data-test-id={"vault-values"}
                        refresh={onAdded}
                        collapsibleRef={collapsibleRef}
                    />
                }
                color="greyscale.900"
                fullWidth
                isExpanded
                ref={collapsibleRef}
            >
                {isEmptyVault(vault) ? (
                    <div style={sxStyles.div}>
                        <StriimTypography variant="body4" color="greyscale.700">
                            {vault.hasErrors
                                ? "Error loading vault"
                                : vault.isFetching
                                ? "Loading..."
                                : "You haven't added any values yet."}
                        </StriimTypography>
                        <br />
                        {vault.vaultType === VaultTypes.STRIIM_VAULT && (
                            <StriimButton
                                variant="text"
                                onClick={() => {
                                    addVaultValue(vault);
                                }}
                                sx={sxStyles.vaultValueButton}
                                data-test-id={`${vault.name}-add`}
                                startIcon={<AddIcon color="transparent" />}
                            >
                                Add Vault Value
                            </StriimButton>
                        )}
                    </div>
                ) : (
                    <StriimSimpleTable
                        headerBorder={true}
                        CustomRowComponent={VaultValueRow}
                        head={head}
                        data={mapVaultValues(vault, allowedActions.edit, allowedActions.delete)}
                        rowStyles={rowStyles}
                    />
                )}
            </CollapsibleSection>
            {isModalShown && <VaultModal setIsModalShown={setIsModalShown} onAdded={onAdded} />}
        </div>
    );
};

const VaultsPresent = ({ vaults, onAdded }) => {
    const classes = useStyles();

    const [isModalShown, setIsModalShown] = useState(false);
    const [vaultValues, setVaultValues] = useState();
    const [activeFilter, setActiveFilter] = useState();
    const [searchQuery, setSearchQuery] = useState();
    const [editingData, setEditingData] = useState(null);
    const collapsibleRef = useRef();

    const fetchData = useCallback(async () => {
        vaults.forEach(vault => {
            vault.isFetching = true;
            vault.hasErrors = false;
            setVaultValues(values => (values ? [...values, vault] : [vault]));
            VaultsService.getVaultValues(vault.id)
                .then(data => {
                    vault.vault = vault;
                    vault.data = data;
                })
                .catch(e => {
                    growl.error(`There was an error fetching vault ${vault.id}: ${e}`);
                    vault.vault = vault;
                    vault.hasErrors = true;
                    vault.data = {};
                })
                .finally(() => {
                    vault.isFetching = false;
                    setVaultValues(values => (values ? [...values, vault] : [vault]));
                });
        });
    }, [vaults]);

    const removeEditingData = editingData => {
        if (editingData?.vault && editingData?.data) {
            removeEmptyVaultValue(editingData.data.dataKey, editingData.vault);
        } else if (editingData?.data) {
            cancelVaultValue(editingData.data);
        }
    };

    const editExistingVaultValue = data => {
        removeEditingData(editingData);
        setEditingData({ data: data });
        data.data.isTemp = true;
        data.data.isSaved = true;
        setVaultValues([...vaultValues]);
    };

    const cancelVaultValue = data => {
        data.data.isTemp = false;
        setEditingData(null);
        setVaultValues([...vaultValues]);
    };
    const addVaultValue = addVault => {
        if (editingData?.vault) {
            return;
        }
        const data = getVaultValues(addVault) ?? {};
        removeEditingData(editingData);

        data.data = {
            ...data.data,
            "": {
                isTemp: true
            }
        };
        setEditingData({
            data: {
                dataKey: "",
                data: {
                    isTemp: true
                }
            },
            vault: addVault
        });
        setVaultValues([...vaultValues]);
    };

    const removeVaultValue = (key, vault) => {
        const data = getVaultValues(vault);
        delete data.data[key];
    };

    const removeEmptyVaultValue = (key, vault) => {
        setEditingData(null);
        removeVaultValue(key, vault);
        setVaultValues([...vaultValues]);
    };

    const isKeyUnique = (key, vault) => {
        if (key === undefined || vault === undefined) {
            return false;
        }
        const values = mapVaultValues(vault);
        const value = values.find(theValue => theValue.dataKey === key);
        if (value === undefined) {
            return true;
        }
        const isUnique = value?.data?.isSaved
            ? value.dataKey === key && values.filter(theValue => theValue.dataKey === key).length === 1
            : key?.length >= 0 && !values.filter(theValue => theValue.dataKey === key).length >= 1;
        return isUnique;
    };

    const getVaultValues = useCallback(
        vault => {
            const vaultValue =
                vaultValues &&
                _.find(vaultValues, value => {
                    return value?.vault?.id === vault.id;
                });
            return vaultValue;
        },
        [vaultValues]
    );

    const mapVaultValues = useCallback(
        (vault, isEditAllowed, isDeleteAllowed) => {
            const theVaultValues = getVaultValues(vault);
            return theVaultValues
                ? Object.keys(theVaultValues.data)
                      .sort()
                      .map(key => {
                          return {
                              dataKey: key,
                              data: theVaultValues.data[key],
                              vault: vault,
                              removeEmptyVaultValue,
                              fetchData,
                              isKeyUnique,
                              isEditAllowed,
                              isDeleteAllowed,
                              editExistingVaultValue,
                              cancelVaultValue,
                              setEditingData
                          };
                      })
                : [];
        },
        [vaults, vaultValues]
    );

    useEffect(() => {
        if (!vaultValues) {
            fetchData();
        }
    }, [vaultValues]);

    const rowStyles = editingData
        ? {
              verticalAlign: "top"
          }
        : {};

    const getComponents = () => {
        let filteredVaults = vaults?.sort((a, b) => (a.id > b.id ? 1 : -1));
        if (activeFilter) {
            filteredVaults = filteredVaults?.filter(vault => {
                return vault?.get("vaultType").toLowerCase() === activeFilter.toLowerCase();
            });
        }
        if (searchQuery) {
            filteredVaults = filteredVaults?.filter(vault => {
                return (
                    vault
                        ?.get("nsName")
                        ?.toLowerCase()
                        .includes(searchQuery?.toLowerCase()) ||
                    vault
                        ?.get("name")
                        ?.toLowerCase()
                        ?.includes(searchQuery?.toLowerCase())
                );
            });
        }

        return filteredVaults?.length ? (
            filteredVaults.map(vault => {
                return (
                    <VaultData
                        key={vault?.attributes?.name}
                        vault={vault}
                        addVaultValue={addVaultValue}
                        onAdded={onAdded}
                        collapsibleRef={collapsibleRef}
                        mapVaultValues={mapVaultValues}
                        isModalShown={isModalShown}
                        setIsModalShown={setIsModalShown}
                        rowStyles={rowStyles}
                    />
                );
            })
        ) : (
            <div data-test-id="no-vaults">Nothing matches your search criteria</div>
        );
    };
    return (
        <>
            <VaultsHeader
                setIsModalShown={setIsModalShown}
                setActiveFilter={setActiveFilter}
                setSearchQuery={setSearchQuery}
                vaults={vaults}
            />
            {getComponents()}
        </>
    );
};

export default VaultsPresent;