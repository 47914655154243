import _ from "underscore";
import api from "core/api/api";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import growl from "app/components/common/growl";
import securityCheck from "core/services/securityService/adapter-security-check";
import $ from "jquery";

var propertyTemplateService = {};
propertyTemplateService.propertyTemplates = [];
propertyTemplateService.servers = [];
propertyTemplateService.deploymentGroups = [];
propertyTemplateService.functions = [];
propertyTemplateService.typeChangeFunctions = [];
propertyTemplateService._actions = {};
propertyTemplateService.propertyActionButtons = [];

propertyTemplateService.getActions = function(type) {
    return this._actions[type];
};

propertyTemplateService.getPropertyTemplateById = function(id) {
    if (propertyTemplateService.propertyTemplates.length === 0) {
        return null;
    }

    return propertyTemplateService.propertyTemplates.where({
        id: id
    })[0];
};

propertyTemplateService.getDeploymentGroupById = function(id) {
    return propertyTemplateService.deploymentGroups.where({
        id: id
    })[0];
};

propertyTemplateService.getMaskingFunctionById = function(id) {
    var length = propertyTemplateService.functions.length;
    for (var i = 0; i < length; i++) {
        var maskingFunction = propertyTemplateService.functions[i];
        var result = _.where(maskingFunction.children, {
            id: id
        });
        if (result.length > 0) {
            return result[0];
        }
    }
    return null;
};

propertyTemplateService.getServerInfo = () => {
    let serverModel = null;
    propertyTemplateService.servers.each(model => {
        if (model.isAgent === false) {
            serverModel = model;
        }
    });
    return {
        ...serverModel.get("initializer"),
        version: serverModel.get("version")
    };
};

var getActions = function(type, action_data) {
    propertyTemplateService._actions[type] = action_data;
};

propertyTemplateService.loadPropertyTemplate = function() {
    return metaStoreService
        .fetchCollections([
            metaStoreService.entities.PROPERTYTEMPLATE,
            metaStoreService.entities.SERVER,
            metaStoreService.entities.DG
        ])
        .then(function(templates, servers, deploymentGroups) {
            propertyTemplateService.propertyTemplates = templates;
            propertyTemplateService.servers = servers;
            propertyTemplateService.deploymentGroups = deploymentGroups;
            propertyTemplateService.deploymentGroups.forEach(function(dg) {
                dg._servers = [];
                servers.forEach(function(server) {
                    _.forEach(server.deploymentGroupsIDs, function(dgID) {
                        if (dg.get("uuid") === dgID) {
                            dg._servers.push(dgID);
                        }
                    });
                });
            });
        })
        .fail(e => {
            growl.error(e.message, "System Error");
        });
};

propertyTemplateService.getActionButtons = async function() {
    try {
        let actionResponse = await api.getPropertyActions();
        _.each(actionResponse, function(val) {
            val = val["h"];
            val = val["memberValues"];
            propertyTemplateService.propertyActionButtons.push(val);
        });
    } catch (e) {
        console.error("Warning! Problem grabbing Property Action Buttons.");
    }
};

propertyTemplateService.init = function() {
    let d = $.Deferred();

    var types = _.keys(metaStoreService.entities);
    api.getActionList(types).then(
        function(data) {
            for (var type in data) {
                if (data.hasOwnProperty(type)) {
                    getActions(type, data[type]);
                }
            }

            //TODO: PC: should be implemented on SERVER DEV-13983
            getActions("OPENPROCESSOR", {
                adapter: {
                    actionAbleFieldType: "OBJECT",
                    isRequired: true
                },
                name: {
                    actionAbleFieldType: "TEXT",
                    isRequired: true
                },
                nsName: {
                    actionAbleFieldType: "TEXT",
                    isRequired: true
                },
                from: {
                    actionAbleFieldType: "METAOBJECT",
                    isRequired: false
                },
                output: {
                    actionAbleFieldType: "METAOBJECT",
                    isRequired: false
                },
                enrichment: {
                    actionAbleFieldType: "METAOBJECT",
                    isRequired: false
                }
            });
        },
        function(exception) {
            throw new Error("Fatal error. Cannot read property etmplates: " + exception.message);
        }
    );

    (async () => {
        let maskingTemplates = await api.getMaskingTemplate();
        propertyTemplateService.functions = maskingTemplates.map(function(category) {
            category.children = category.children.map(function(func) {
                func.selectedText = category.text + " (" + func.text[0] + ")";
                return func;
            });
            return category;
        });

        try {
            await propertyTemplateService.loadPropertyTemplate();
            await securityCheck.loadAll();
            await propertyTemplateService.getActionButtons();
            d.resolve();
        } catch (e) {
            d.reject();
        }
    })();

    return d.promise();
};

export default propertyTemplateService;
