/***
 * refer to https://github.com/markmarkoh/datamaps/blob/master/README.md#getting-started
 * ***/
import "app/components/dashboard/visualization/charts/chart";
import App from "app";
import d3 from "d3";
import "topojson";
import Datamap from "lib/vendor/mapdata/datamaps.all";
import _chartRendererMixin from "./../mixins/_chartRendererMixin";
import evaluator from "app/components/dashboard/visualization/charts/gauge/expressionEvaluator";

App.module("Chart.ThreatMap", function (Chart, App, Backbone, Marionette, $, _) {
    Chart.View = App.Chart.Default.ChartView.extend({
        className: "threatmap",
        template: _.template('<div class="threatmap-container"></div>'),
        ui: {
            container: ".threatmap-container",
        },
        initialize: function () {
            _(this).extend(_chartRendererMixin);
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
            Backbone.Marionette.ItemView.prototype.render.apply(this, arguments);
            this.colorGenerator = d3.scale.category20();
        },
        setConfiguration: function (config) {
            this.chartConfig = config;
        },
        renderChart: function () {
            this.renderIfNeeded(this.map, this.renderMap, this.updateMap);
        },
        updateMap: function () {
            //generate the fill keys for conditional colors if any, we need this as bubbles
            //only accept fillKeys which are basically keys from the 'fills' object defined for the map
            this.setConditionalColorsFillKeys();

            //data for arcs
            this.generateArcs(this.model.dataSource.data);
            this.generateLegend(this.chartConfig.config.showLegend);
        },
        renderMap: function () {
            var container = this.$el.parent();
            var config = this.chartConfig.config;

            //config constants
            var ARC_STROKEWIDTH = 2,
                ARC_SHARPNESS = 1,
                ARC_ANIMATIONSPEED = 600, // Milliseconds
                BUBBLE_BORDERWIDH = 0,
                LEGEND_TITLE = "LEGEND",
                ASPECT_RATIO = 0.5625,
                WIDTH = container.width * 0.5,
                HEIGHT = WIDTH / ASPECT_RATIO;

            var dataMapConfig = {
                scope: "world",
                element: container[0],
                projection: "mercator",
                fills: {
                    defaultFill: config.mapColor,
                    defaultBubble: config.arcColor,
                },
                height: HEIGHT,
                width: WIDTH,
                responsive: true,
                geographyConfig: {
                    popupTemplate: function (geography) {
                        var popupTemplate =
                            '<div class="hoverinfo threatmap-hover "><strong>' +
                            geography.properties.name +
                            "</strong></div>";
                        return popupTemplate;
                    },
                },
                arcConfig: {
                    strokeColor: config.arcColor,
                    strokeWidth: ARC_STROKEWIDTH,
                    arcSharpness: ARC_SHARPNESS,
                    animationSpeed: ARC_ANIMATIONSPEED,
                    popupOnHover: true,
                    popupTemplate: function (geography, data) {
                        // This function should just return a string

                        var valueInfo = "<span>Value:<strong>" + data.value + "</strong></span>",
                            originInfo = data.origin.locationName
                                ? "<span>Origin:<strong>" + data.origin.locationName + "</strong></span>"
                                : "",
                            destinationInfo = data.destination.locationName
                                ? "<span>Destination:<strong>" + data.destination.locationName + "</strong></span>"
                                : "";

                        var popupTemplate =
                            '<div class="hoverinfo threatmap-hover ">' +
                            valueInfo +
                            "<br/>" +
                            originInfo +
                            "<br/>" +
                            destinationInfo +
                            "</div>";

                        return popupTemplate;
                    },
                },
                bubblesConfig: {
                    borderWidth: BUBBLE_BORDERWIDH,
                    popupOnHover: true,
                    animate: true,
                    popupTemplate: function (geography, data) {
                        var valueInfo = "<span>Value:<strong>" + data.radius + "</strong></span>",
                            destinationInfo = data.locationName
                                ? "<span>Destination:<strong>" + data.locationName + "</strong></span>"
                                : "";

                        var popupTemplate =
                            '<div class="hoverinfo threatmap-hover">' +
                            valueInfo +
                            "<br/>" +
                            destinationInfo +
                            "</div>";
                        return popupTemplate;
                    },
                },
                legend: {
                    legendTitle: LEGEND_TITLE,
                },
            };

            //As of now datamaps does not  have the ability to update an existing map so we remove it
            this.cleanUpMap();
            this.map = new Datamap(dataMapConfig);
            this.updateMap();
        },
        resize: function () {
            if (this.map) {
                this.map.resize();
            }
        },
        generateArcs: function (data) {
            var arcData = [],
                bubbleData = [];

            var config = this.chartConfig.config;

            var that = this;
            var strokeColor, originLocationName, destinationLocationName;

            var shouldGenerateArcs =
                this.map &&
                config.originLatitude &&
                config.originLongitude &&
                config.destinationLatitude &&
                config.destinationLongitude;

            if (shouldGenerateArcs) {
                _.forEach(data, function (dataPoint) {
                    strokeColor = that.setStrokeColor(dataPoint);

                    //Since bubble needs a FillKey, just get any key with that color value
                    var strokeKey = _.invert(that.map.options.fills)[strokeColor];
                    strokeKey = strokeKey ? strokeKey : "defaultBubble";

                    //optional origin and destination location name
                    originLocationName = config.originLocationName ? dataPoint[config.originLocationName] : "";
                    destinationLocationName = config.destinationLocationName
                        ? dataPoint[config.destinationLocationName]
                        : "";
                    arcData.push({
                        origin: {
                            latitude: dataPoint[config.originLatitude],
                            longitude: dataPoint[config.originLongitude],
                            locationName: originLocationName,
                        },
                        destination: {
                            latitude: dataPoint[config.destinationLatitude],
                            longitude: dataPoint[config.destinationLongitude],
                            locationName: destinationLocationName,
                        },
                        value: dataPoint[config.value],
                        strokeColor: strokeColor,
                    });
                    bubbleData.push({
                        latitude: dataPoint[config.destinationLatitude],
                        longitude: dataPoint[config.destinationLongitude],
                        radius: that.calculateRadius(dataPoint[config.value]),
                        fillKey: strokeKey,
                        locationName: destinationLocationName,
                    });
                });
                this.map.arc(arcData);
                this.map.bubbles(bubbleData);
            }
        },
        calculateRadius: function (value) {
            var normalizedRadius = 0;
            //if negative or not a number, value is 0
            if (!value || !_.isNumber(value) || (_.isNumber(value) && value < 0)) {
                return normalizedRadius;
            }
            //else normalize between [0 and 10]
            normalizedRadius = value % 11;
            return normalizedRadius;
        },
        setConditionalColorsFillKeys: function () {
            var config = this.chartConfig.config;
            var condition, legendValue;

            for (var i = 0; i < config.conditionalColor.length; i++) {
                condition = config.conditionalColor[i];
                legendValue = condition.style.alias ? condition.style.alias : condition.expression;
                this.map.options.fills[legendValue] = condition.style.color;
            }
        },
        setStrokeColor: function (dataPoint) {
            var config = this.chartConfig.config;
            var strokeColor = config.arcColor, //default color
                conditionalColor;

            if (config.arcColorBy) {
                strokeColor = this.setCategoryColor(dataPoint[config.arcColorBy]);
            }

            //conditional colors
            if (config.conditionalColor) {
                conditionalColor = this.setConditionalColor(dataPoint);
                strokeColor = conditionalColor ? conditionalColor : strokeColor;
            }

            return strokeColor;
        },
        setCategoryColor: function (dataCategory) {
            //search if a color has been set for the given category

            for (var category in this.map.options.fills) {
                if (category === dataCategory) {
                    return this.map.options.fills[category];
                }
            }

            //find the next color generated by d3 (at max 20)
            var newColorIndex = this.colorGenerator.domain().length;
            this.map.options.fills[dataCategory] = this.colorGenerator(newColorIndex);

            return this.map.options.fills[dataCategory];
        },
        setConditionalColor: function (dataPoint) {
            var condition;
            var config = this.chartConfig.config;

            for (var i = 0; i < config.conditionalColor.length; i++) {
                condition = config.conditionalColor[i];
                //if condition matches return the corresponding color
                var computed = evaluator.evaluate(condition.expression, dataPoint);
                if (computed === true) {
                    return condition.style.color;
                }
            }
        },
        generateLegend: function (showLegend) {
            //remove old legends
            this.$el.parent().find(".datamaps-legend").remove();
            if (showLegend) {
                this.map.legend();
            }
        },
        cleanUpMap: function () {
            var parentContainer = this.$el.parent();
            parentContainer.find("*").not(".threatmap").remove();
            //reset the counter on the color generator
            this.colorGenerator = d3.scale.category20();
        },
    });
});
