import React, { useState } from "react";
import AddOnChip from "../../modules/common/segmentation/segmentationChip";
import { StriimTooltip, StriimModal, StriimTypography } from "@striim/striim-ui";
import SegmentationModal from "./segmentation-modal";
import sxClasses from "./segmentation.styles";
import App from "app";
import securityService from "core/services/securityService/securityService";

function SegmentationLabel({ label, variant, licenseKey }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleClose = () => {
        setTooltipOpen(false);
    };
    const handleOpen = () => {
        setTooltipOpen(true);
    };

    return (
        <div>
            {label}
            <StriimTooltip
                open={tooltipOpen}
                onOpen={handleOpen}
                onClose={handleClose}
                placement="left"
                  componentsProps={{
                    tooltip: {
                      sx: sxClasses.tooltip,
                    },
                  }}
                title={<SegmentationModal setModalOpen={setIsModalVisible} closeTooltip={handleClose} variant={variant} licenseKey={licenseKey} />}>
                <span onClick={() => setTooltipOpen(!tooltipOpen)}>
                    <AddOnChip variant={variant} />
                </span>
            </StriimTooltip>
            <StriimModal
                onCancel={() => setIsModalVisible(false)}
                onConfirm={() => {
                    setIsModalVisible(false);
                    App.navigate(securityService.addonNotAvailableLink, { trigger: true });
                }}
                isVisible={isModalVisible}
                size="xSmall"
                titleContent={<StriimTypography variant="h2">Confirm</StriimTypography>}
            >
                You have unsaved changes. Do you want to proceed?
            </StriimModal>
        </div>
    );
}

export default SegmentationLabel;
