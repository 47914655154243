import _ from "underscore";
import HighChartsMixin from "app/components/dashboard/visualization/charts/mixins/highcharts";

export default _({}).extend(HighChartsMixin, {
    // Note: be sure to pass in a deep-cloned config object!
    setConfiguration: function (config) {
        var theme = config.theme;
        var config = config.config;
        if (!theme) return console.warn("No theme provided.");

        // Translate our configuration to Highcharts configuration
        this.chartConfig = {
            chart: {
                type: "pie",
                backgroundColor: theme.chart.background,
            },
            credits: {
                enabled: false,
            },
            tooltip: this.generateToolTipConfig(),
            plotOptions: {
                pie: {
                    animation: false,
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                },
                series: {
                    cursor: "pointer",
                    point: {
                        events: {
                            click: function (e) {
                                var element = e.currentTarget.options.rowData;
                                this.trigger("datapoint:click", element);
                            }.bind(this),
                        },
                    },
                },
            },
            legend: {
                enabled: false,
            },
            title: {
                text: config.title,
                style: {
                    display: "none",
                },
            },
            xAxis: this._formatXAxis(config.xAxis, theme),
            yAxis: this._formatYAxis(config.yAxis, theme),
        };

        config.includeCategoryWithDataPoint = true;
        config.includePointColor = true;

        var seriesCategoriesAndLegendData = this.generateSeriesCategoriesAndLegendData(
            0,
            config,
            this.chartConfig,
            this.model.dataSource.fieldList
        );
        this.setChartConfigWithSeriesCategoryAndChartData(0, config, seriesCategoriesAndLegendData);

        if (!config.show_legend) {
            this.chartConfig.plotOptions.pie.dataLabels = {
                enabled: true,
                format: "<b>{point.x}</b>",
                color: theme.axis.labels.color,
            };
        }

        if (typeof this.chartConfig.series !== "undefined" && typeof this.chartConfig.series[0] !== "undefined") {
            this.chartConfig.series[0].innerSize = "40%";
        }
    },
});
