import App from "app";
import NestedTypes from "backbone.nestedtypes";
import growl from "app/components/common/growl";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import visualizationPanelPersister from "./visualization-panel-persister";
import visualizationCapabilities from "./visualizationCapabilities";
import dialog from "app/components/common/dialogs/dialogWindow";
import queryService from "core/services/dataService/queryService";
import iframeDetector from "core/iframeDetector";
import filterModel from "core/filterModel";
import ViewModeControls from "./viewModeControls";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import qvis_template from "./visualization-panel.html";
import component_header_template from "./visualization-panel-header.html";
import component_filter_template from "./visualization-panel-filter.html";
import component_filter_item_template from "./visualization-panel-filter-item.html";
import QueryConfig from "../query-config/queryconfig";
import queryParameters from "core/services/queryService/queryParameters.json";
import "../style-config/style-config";
import "app/components/dashboard/grid/component";
import "../visualization-config/visualization-config";
import "./visualization-view";
import stringUtils from "core/utils/string-utils";
import Tracker from "core/services/tracker/tracker";
import ensureRegions from "../../common/_ensureRegions";
import { TRACKER_STRINGS } from "../../../../core/services/tracker/constants";

var timeout = 3000;

App.module("Dashboard", function (Dashboard, App, Backbone, Marionette, $, _) {
    var VisualizationPanelToolbar = Marionette.ItemView.extend({
        className: "component-header",
        template: _.template(component_header_template),

        ui: {
            queryButton: ".query-button",
        },

        triggers: {
            "click .edit-button": "edit",
            "click .query-button": "edit-query",
            "click .style-button": "edit-styles",
            "click .delete-button": "delete",
        },
        events: {
            "click .forward-button": "bringForward",
            "click .backward-button": "sendBackward",
        },

        onRender: function () {
            if (!this._supportsQuery()) {
                this.ui.queryButton.hide();
            }
        },

        _supportsQuery: function () {
            var component = this.model.get("component");
            return component && component.content && visualizationCapabilities.supportsQuery(component.content);
        },

        bringForward: function (e) {
            e.stopPropagation();

            if (e.ctrlKey) {
                this.trigger("show-zIndex", this);
            } else {
                this.trigger("bring-forward", this);
            }
        },

        sendBackward: function (e) {
            e.stopPropagation();

            if (e.ctrlKey) {
                this.trigger("show-zIndex", this);
            } else {
                this.trigger("send-backward", this);
            }
        },
        onShow: function () {
            setTimeout(() => {
                this.$el.find('.button').tooltipster();
            }, 1000)
        }
    });

    var FilterItemView = Marionette.ItemView.extend({
        tagName: "tr",
        template: _.template(component_filter_item_template),
    });
    var FilterView = Marionette.CompositeView.extend({
        className: "filter-sign",
        template: _.template(component_filter_template),
        childView: FilterItemView,
        childViewContainer: ".filter-list",

        detailsStates: {
            COLLAPSED: "collapsed",
            EXPANDED: "expanded",
            STICK: "stick",
        },

        events: {
            "click .fa-eye": "toggleDetails",
            "mouseover .fa-eye": "expandDetails",
            "mouseout .fa-eye": "collapseDetails",
        },

        modelEvents: {
            "change:filters": "render",
            "change:detailsState": "renderDetails",
        },

        initialize: function () {
            this.FADE_OUT_TIMEOUT = 2000;

            this.collection = new Backbone.Collection();
            this.collection.reset(this.model.get("filters"));

            this.model.set("detailsState", this.detailsStates.COLLAPSED);
        },

        renderDetails: function () {
            this.$el.toggleClass("stick", this.model.get("detailsState") === this.detailsStates.STICK);
            this.$el.toggleClass("expanded", this.model.get("detailsState") !== this.detailsStates.COLLAPSED);
        },

        toggleDetails: function () {
            if (this.model.get("detailsState") === this.detailsStates.STICK) {
                this.model.set("detailsState", this.detailsStates.COLLAPSED);
            } else {
                this.model.set("detailsState", this.detailsStates.STICK);
            }
        },

        expandDetails: function () {
            if (this.model.get("detailsState") === this.detailsStates.COLLAPSED) {
                this.model.set("detailsState", this.detailsStates.EXPANDED);
            }
        },

        collapseDetails: function () {
            if (this.model.get("detailsState") === this.detailsStates.EXPANDED) {
                this.model.set("detailsState", this.detailsStates.COLLAPSED);
            }
        },

        updateBorder: function () {
            var active = this.model.get("filters").length > 0;
            var panel = this.model.get("panel");
            panel.$el.toggleClass("filter-active", active);
        },
        onRender: function () {
            this.collection.reset(this.model.get("filters"));
            this.updateBorder();

            this.expandDetails();
            setTimeout(this.collapseDetails.bind(this), this.FADE_OUT_TIMEOUT);
        },
    });

    // A grid component.
    // Backed by a grid component from the Page"s components collection, whose `content` property is a QueryVisualization
    var VisualizationViewProto = _({}).extend(App.Dashboard.VisualizationViewMixin);
    Dashboard.VisualizationPanel = App.Dashboard.Grid.Component.View.extend(
        _(VisualizationViewProto).extend({
            className: "grid-component visualization-panel",
            template: _.template(qvis_template),
            events: {
                click: "handleClick",
                dblclick: "handleDoubleClick",
            },
            regions: {
                filter: ".filter",
                controls: ".controls",
                content: ".content",
                viewModeControlsRegion: ".view-mode-controls",
            },

            ui: {
                indexPreview: ".index-preview",
                indexPreviewContent: ".index-preview .index-preview-content",
                overlay: ".overlay",
                overlayText: ".overlay span",
            },

            getVisualizationModel: function () {
                // get the visualizastionModel from the content model.
                return this.model.content;
            },
            handleClick: function (e) {
                // In edit mode, click events are used for active/selected state. This will stop event propagation!
                if (this.model._editable) {
                    this.trigger(e.shiftKey ? "shift-click" : "click", this);
                    return false;
                }
                return true;
            },
            handleDoubleClick: function () {
                if (this.model._editable) {
                    this._configureVisualization();
                }
            },
            initialize: function () {
                ensureRegions(this);
                // MODEL = COMPONENT
                this.listenTo(this, "component:resizeend", function () {
                    var chartView = this.getRegion("content").currentView;
                    chartView.trigger("resize");
                });

                if (this.model._editable) {
                    var lastRenderredZIndex = !!this.model.content.config ? this.model.content.config.zIndex : 0;

                    this.listenTo(
                        this.model.content,
                        "change",
                        function (model) {
                            if (model.config.zIndex !== lastRenderredZIndex) {
                                lastRenderredZIndex = model.config.zIndex;
                                this.setZIndex(lastRenderredZIndex);
                            }
                        }.bind(this)
                    );
                }
                this.initializeDataSource();

                App.Dashboard.Grid.Component.View.prototype.initialize.apply(this, arguments);
            },
            // Override initializeDrag to prevent visPanel from resizing or moving if in view mode
            initializeDrag: function () {
                if (!this.model._editable) {
                    return false;
                }
                App.Dashboard.Grid.Component.View.prototype.initializeDrag.apply(this, arguments);
            },
            // Render the component header (title bar) and hint
            renderToolbar: function () {
                var _this = this;

                var qvheader = new VisualizationPanelToolbar({
                    model: new Backbone.Model({
                        title: "",
                        component: this.model,
                    }),
                });
                this.getRegion("controls").currentView.getRegion("header").show(qvheader);

                qvheader.on("edit", this._configureVisualization.bind(this));
                qvheader.on("edit-query", function () {
                    _this.trigger("configure-query", this);
                    Tracker.getInstance().track(TRACKER_STRINGS.schema.dashboard, {
                        id: window.location.hash.split("/")[2],
                        event: TRACKER_STRINGS.eventClicked.dashboard,
                        buttonClicked:"Edit Query"
                    });
                });
                qvheader.on("edit-styles", function () {
                    _this.trigger("configure-styles", this);
                });
                qvheader.on("delete", function () {
                    var message = "Are you sure you want to delete this Visualization?";
                    dialog.confirm(message, App.Dashboards.ModalManager, "medium no-borders critical", "Confirm", "Delete")
                        .then(function (result) {
                            if (result === false) {
                                return;
                            }
                            _this.trigger("component:delete", this);
                        });
                });

                qvheader.on(
                    "bring-forward",
                    function () {
                        this.trigger("component:bringForward", this);
                    }.bind(this)
                );
                qvheader.on(
                    "send-backward",
                    function () {
                        this.trigger("component:sendBackward", this);
                    }.bind(this)
                );
                qvheader.on(
                    "show-zIndex",
                    function () {
                        this.trigger("component:show-zIndex", this);
                    }.bind(this)
                );
            },
            renderViewModeControls: function (hasDrillDownFiltersApplied) {
                if (this.model._editable || iframeDetector.isInIframe()) {
                    return false;
                }

                var _this = this;

                let content = this.model.get("content");
                if (content && content.visualizationType) {
                    this.$el.addClass(content.visualizationType.toLowerCase() + "-view");
                }

                _this.model.content.query.fetch().then(function (query) {
                    var model = new ViewModeControls.Model({
                        visualizationModel: _this.model,
                        hasDrillDownFiltersApplied: hasDrillDownFiltersApplied,
                        canBeFiltered: query.canBeFiltered,
                    });
                    if (_this.viewModeControls) {
                        _this.viewModeControls.destroy();
                    }
                    _this.viewModeControls = new ViewModeControls.View({
                        model: model,
                    });

                    if (
                        _this.viewModeControlsRegion &&
                        visualizationCapabilities.supportsViewModeControls(_this.model.get("content"))
                    ) {
                        _this.viewModeControlsRegion.show(_this.viewModeControls);
                    }

                    _this.listenTo(model, "dashboard:visualizations:clear-filters", function () {
                        _this.$el.removeClass("selected");
                    });

                    _this.listenTo(model, "dashboard:visualizations:active-filters", function () {
                        _this.$el.addClass("selected");
                    });

                    filterModel.setTimeField(query.uuid, _this.getVisualizationModel().config.get("timeField"));

                    _this.viewModeControls.listenTo(filterModel, query.uuid, function () {
                        var currentTime = new Date().getTime();
                        _this.showOverlay("Applying filters", timeout, function () {
                            _this.getVisualizationModel().dataSource.tryClearData(currentTime);
                            _this.onDataPoll();
                        });
                        _this.getVisualizationModel().dataSource.pollingQueryDataLoader.stopPolling();
                    });

                    _this.viewModeControls.listenTo(filterModel, "global", function (filters) {
                        if (query.canBeFiltered && filters[queryParameters.Time] !== undefined) {
                            var currentTime = new Date().getTime();
                            _this.showOverlay("Applying filters", timeout, function () {
                                _this.getVisualizationModel().dataSource.tryClearData(currentTime);
                                _this.onDataPoll();
                            });
                            if (_this.getVisualizationModel().dataSource.pollingQueryDataLoader) {
                                _this.getVisualizationModel().dataSource.pollingQueryDataLoader.stopPolling();
                            }
                        } else if (query.hasFullTextSearchQueryParam) {
                            _this.showOverlay("Applying filters", timeout, () => {
                                const pageParams = App.request("QueryManager:getParameters");
                                const fullTextSearch = filters[queryParameters.Search];
                                if (fullTextSearch) {
                                    pageParams.search = stringUtils.ensureEndsWith(fullTextSearch, "%");
                                } else {
                                    delete pageParams.search;
                                }
                                App.request("QueryManager:updateParameters", pageParams);
                            });
                        } else {
                            if ($.isEmptyObject(filters) || !_this.model?.content?.query?.queryString) {
                                _this.hideOverlay();
                            } else {
                                _this.showOverlay("This chart cannot be filtered");
                            }
                        }
                    });
                });
            },
            onRender: function () {
                ensureRegions(this);
                this.renderVisualization();
                App.Dashboard.Grid.Component.View.prototype.onRender.apply(this, arguments);

                this.listenTo(
                    this.model.collection,
                    "toogle-zIndex-visibility",
                    function (showZIndex) {
                        this.ui.indexPreview.toggleClass("visible", showZIndex);
                    }.bind(this)
                );

                //set the z-index here
                var zIndex = this.model.get("content").get("config").get("zIndex");
                this.setZIndex(zIndex);

                this._trySetChartTitle();

                // set id of the visualization in data attribute
                this.$el.attr("data-content-id", this.model.get("content_id"));

                if (!iframeDetector.isInIframe()) {
                    // Grid component onRender must be called before rendering the header, because the header
                    // is rendered inside the controls
                    this.renderToolbar();

                    // additionally we need to wait until query has started, so we have up to date query information like queryParamters
                    this.listenTo(this.model.content.dataSource.query, "query:start", function () {
                        this.renderFilter();
                        this.togglePolling();
                    });
                    // and for reset:datasource in case of updating editor settings
                    this.listenTo(this, "reset:datasource", this.renderFilter);
                }

                // render filter icon and params
                this.renderFilter();
            },

            _trySetChartTitle: function () {
                const content = this.model.content;
                if (!content || !content.config || !this.model._editable) {
                    return null;
                }

                const title = `${content.visualizationType} ${content.config.type || ""}`;
                this.$el.prop("title", title);
            },

            _configureVisualization: function () {
                this.trigger("configure-visualization", this);
            },

            renderFilter: function () {
                ensureRegions(this);
                var query = this.model.content.query;
                var pageparams = App.request("QueryManager:getParameters");
                var paramNames = _.keys(pageparams);

                var panelFilters = _.filter(paramNames, function (param) {
                    return query.hasParameter(param);
                }).map(function (filterName) {
                    var pageparam = pageparams[filterName];
                    return {
                        label: filterName.toUpperCase(),
                        value: pageparam,
                    };
                });

                var config = this.getVisualizationModel().get("config");
                var filterVisible = config.get("filter") ? config.get("filter").get("visible") : true;

                if (filterVisible) {
                    if (this.filterView) {
                        this.filterView.destroy();
                    }
                    this.filterView = new FilterView({
                        model: new Backbone.Model({
                            panel: this,
                            filters: panelFilters,
                        }),
                    });
                    this.getRegion("filter").show(this.filterView);
                    this.renderViewModeControls(panelFilters.length > 0);
                } else {
                    this.getRegion("filter").empty();
                    this.renderViewModeControls(false);
                }
            },
            togglePolling: function () {
                var content = this.model.content;
                if (!content.dataSource.pollingQueryDataLoader) {
                    return;
                }

                if (content.config.pollingQuerySettings && content.config.pollingQuerySettings.pollingEnabled) {
                    content.dataSource.pollingQueryDataLoader.resume();
                } else {
                    content.dataSource.pollingQueryDataLoader.pause();
                }
            },
            onShow: function () {
                ensureRegions(this);
                App.Dashboard.Grid.Component.View.prototype.onShow.apply(this, arguments);

                // From previous controller
                this.listenTo(this, "configure-visualization", _.debounce(this.configure, 400));
                this.listenTo(this, "configure-query", _.debounce(this.configQuery, 400));
                this.listenTo(this, "configure-styles", this.configureStyles);
            },
            save: function (draft_model) {
                var queryVisualization = this.model.content;

                // Time Series save
                // Set all series to be visible
                // Each series" data should be null, if draft_model is deepClone (e.g. from editor)
                if (queryVisualization.config.series) {
                    draft_model.config.series.each(function (s) {
                        s.set("visible", null);
                    });
                }

                return visualizationPanelPersister.save(queryVisualization, draft_model);
            },

            configureStyles: function () {
                const style_modal = App.Dashboard.ModalManager.add(
                    new App.Dashboard.StyleModal({
                        model: this.model,
                        id: "style",
                    })
                );

                /*new App.Dashboard.VisEditor.Controller({
                    view: style_modal,
                });*/

                this.listenTo(
                    style_modal,
                    "form-submit-success",
                    function () {
                        style_modal.destroy();
                    }.bind(this)
                );
            },
            configQuery: function () {
                var _this = this;
                queryService.readAllQueries().then(function (queries) {
                    var model = queries.get(_this.model.content.query.id);
                    if (!model) {
                        model = _this.model.content.query;
                    }
                    var dashboardNSName = _this.model.content.nsName;

                    var queryConfigModel = new QueryConfig.Model({
                        id: model.nsName
                            ? metaObjectConverter.getId(model.nsName, metaStoreService.entities.QUERY, model.name)
                            : null,
                        queryString: model.queryDefinition,
                        queries: queries,
                        dashboardNSName: dashboardNSName,
                    });
                    var queryConfigView = new QueryConfig.View({
                        model: queryConfigModel,
                    });
                    new App.FormMixinDialog.Controller({
                        view: queryConfigView,
                    });
                    var queryConfigModal = App.Dashboard.ModalManager.add(queryConfigView);

                    _this.listenTo(
                        queryConfigModal,
                        "form-submit-success",
                        function (queryConfigView) {
                            if (!queryConfigModel.id || !queryConfigModel.queryString) {
                                return;
                            }

                            var currentQuery = _this.model.content.query;

                            // Check if the id changed or the query changed, if there were no changes leave things alone
                            if (
                                currentQuery.id !== queryConfigView.model.id ||
                                currentQuery.queryDefinition !== queryConfigView.model.queryString
                            ) {
                                currentQuery.trigger("about:to:fetchnew");
                                var parsed = metaObjectConverter.parse(queryConfigView.model.id);
                                currentQuery.nsName = parsed.nsName || _this.model.nsName;
                                currentQuery.name = parsed.name;

                                var shortId = metaObjectConverter.ensureShortId(
                                    queryConfigView.model.id,
                                    _this.model.nsName
                                );
                                var promise = App.request(
                                    "QueryManager:fetchOrCreateQuery",
                                    shortId,
                                    queryConfigView.model.queryString
                                );
                                promise
                                    .then(function (queryData) {
                                        currentQuery.set(queryData);

                                        var queryVisualization = _this.model.content;
                                        queryVisualization.save();

                                        _this.render();
                                        growl.success("Query saved!");
                                        Tracker.getInstance().track(TRACKER_STRINGS.schema.dashboard, {
                                            id: window.location.hash.split("/")[2],
                                            event: TRACKER_STRINGS.eventClicked.dashboard,
                                            buttonClicked:"Query Saved"
                                        });
                                        queryConfigView.destroy();
                                        currentQuery.trigger("after:fetch");
                                    })
                                    .fail(function (error) {
                                        console.log("Query failed to compile.", error);
                                    });
                            } else {
                                growl.success("Query saved!");
                                queryConfigView.destroy();
                            }
                        }.bind(this)
                    );
                });
            },
            configure: function () {
                var view = this;

                _(
                    function () {
                        var EditorModel = NestedTypes.Model.extend({
                            defaults: {
                                visualization: App.Metadata.Queryvisualizations.Model,
                                actions: null
                            },
                        });
                        // Note: deepClone reinitializes all nested models, so Query data and series data will be cleared
                        var vismodel_copy = view.model.content.deepClone();

                        var editor = new App.Dashboard.VisEditor.View({
                            id: view.model.content.get("id"),
                            model: new EditorModel({
                                visualization: vismodel_copy,
                            }),
                            origin: view.$el,
                        });
                        new App.Dashboard.VisEditor.Controller({
                            view: editor,
                        });

                        var DialogView = App.Dashboard.ModalManager.add(editor);

                        // This will be called when the user has successfully submitted the form.
                        // We can update the visualization at this point.
                        this.listenTo(DialogView, "form-submit-success", function (editor) {
                            DialogView.destroy();
                            view.save(editor.model.visualization).then(() => {
                                view.trigger("reset:datasource");
                                Tracker.getInstance().track(TRACKER_STRINGS.schema.dashboard, {
                                    id: window.location.hash.split("/")[2],
                                    event: TRACKER_STRINGS.eventClicked.dashboard,
                                    buttonClicked:"Visualization Saved"
                                });
                                growl.success("Visualization saved!");
                            });
                        });
                    }.bind(this)
                ).delay(100);
            },

            setZIndex: function (zIndex) {
                this.$el.css("z-index", zIndex);

                if (this.model._editable) {
                    this.ui.indexPreviewContent.html(zIndex);

                    this.$el.find(".backward-button").toggleClass("disabled", zIndex <= 1);
                    this.$el
                        .find(".forward-button")
                        .toggleClass("disabled", zIndex >= this.model.collection.models.length);
                }
            },

            hideOverlay: function () {
                this.ui.overlay?.fadeOut?.();
            },

            showOverlay: function (text, hideAfter, callback) {
                if (!this.isRendered) {
                    return;
                }

                this.ui.overlayText.text(text);
                this.ui.overlay.fadeIn();

                if (hideAfter) {
                    setTimeout(
                        function () {
                            if (!this.isRendered) {
                                return;
                            }

                            this.ui.overlay.fadeOut();
                            if (typeof callback === "function") {
                                callback();
                            }
                        }.bind(this),
                        hideAfter
                    );
                }
            },
        })
    );
});
