import Backbone from "backbone";
import NestedTypes from "backbone.nestedtypes";
import Marionette from "marionette";
import $ from "jquery";
import _ from "underscore";
import App from "app";
import dialog from "app/components/common/dialogs/dialogWindow";
import ActionsView from "./actionsView";
import Controls from "./controls";
import template from "./form-builder.html";
import dbConnectionTest from "./control/connection-text-field/connection-test-view";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import { convertNameToHumanRedable } from "./control-resource-resolver";

var Model = NestedTypes.Model.extend({
    defaults: {
        data: {},
        controls: [],
        isDirty: Boolean,
        dirtyControls: Array,
        trackingEnabled: Boolean,
        submitText: String,
        cancelText: String,
        autoSave: Boolean,
        twoWayDataBinding: Boolean,
        readOnly: Boolean,
        labels: {},
        viewOptions: {},
        containerClass: String
    },
    validate: function() {
        //TODO: this assumes all added controls are required, and it will
        //TODO: return propertyNames of all controls that have a falsy value
        var emptyControls = _.filter(this.controls, function(control) {
            if (typeof control.view.getValue() === "boolean") {
                return false;
            }
            return !control.view.getValue();
        });

        if (emptyControls.length > 0) {
            return _.pluck(emptyControls, "propertyName");
        } else {
            return undefined;
        }
    },
    refreshDirty: function() {
        this.isDirty = this.dirtyControls.length > 0;
    },
    findControlByName: function(name) {
        return this.controls.find(c => {
            return c.propertyName.toLowerCase() === name.toLowerCase();
        });
    },
    getDataCaseInsensitive: function(name) {
        return Object.entries(this.data.attributes).find(c => c[0].toLowerCase() === name.toLowerCase())?.[1];
    }
});

var View = Marionette.LayoutView.extend({
    template: _.template(template),

    _actionButtons: {},

    className: "meta-object-editor",

    regions: {
        controls: ".controls",
        actions: "> .actions"
    },

    onRender: function() {
        if (this.model.containerClass) {
            this.$el.addClass(this.model.containerClass);
        }
    },

    startTracking: function() {
        if (this.model.trackingEnabled) {
            return;
        }

        var _this = this;
        _this.model.trackingEnabled = true;

        _.each(_this.model.controls, function(control) {
            control.view.model.on("change:value", function(model) {
                var cid = model.cid;
                var index = _this.model.dirtyControls.indexOf(cid);
                if (index === -1) {
                    _this.model.dirtyControls.push(cid);
                }
                _this.model.refreshDirty();
            });
        });
    },

    isSecurityKeyDirty(propertyObject) {
        // if password/access key is modified by user, old value will no longer be an object but a string
        const isPasswordDirty = typeof propertyObject.properties["Password"] === "string";
        const isAccessKeyDirty = typeof propertyObject.properties["AccessKey"] === "string";
        return isPasswordDirty || isAccessKeyDirty;
    },

    clearDirty: function(editorModel) {
        var index = this.model.dirtyControls.indexOf(editorModel.cid);
        if (index > -1) {
            this.model.dirtyControls.splice(index, 1);
        }

        this.model.refreshDirty();
    },

    setControlsReadOnly: function(readOnly) {
        this.model.controls.forEach(function(control) {
            control.view?.setEnabled(!readOnly);
        });
        this.model.readOnly = readOnly;
    },

    setReadOnly: function(readonly) {
        this.setControlsReadOnly(readonly);
        this.$el.find(".actions").toggle(!readonly);
    },

    setDefault: function() {
        this.model.controls.forEach(function(control) {
            control.view.setDefault();
        });
    },

    addCustomActionDelete: function(clickEvent, options) {
        const key = "delete";
        var _this = this;

        let actionButton;
        if (options.useLinkButton) {
            actionButton = $('<a class="delete-button">Delete</a>');
        } else {
            actionButton = $('<a class="custom-action"></a>');
            actionButton.attr({
                title: "Delete",
                class: "btn icon striimline-icon trash"
            });
        }

        //actionButton.html("delete");
        actionButton.bind("click", function() {
            if (_this._actionButtons[key].isEnabled === false) {
                return;
            }
            clickEvent();
        });
        this.$el.find(".actions>div").prepend(actionButton);

        this._actionButtons[key] = {
            $el: actionButton,
            isEnabled: true
        };
    },

    setActionButtonEnabled: function(key, isEnabled) {
        var actionButton = this._actionButtons[key];
        if (!actionButton) {
            throw new Error("Action not found " + key);
        }
        actionButton.$el.toggleClass("disabled", !isEnabled);
        actionButton.isEnabled = isEnabled;
    },

    getCurrentValue: function(propertyName) {
        var controlsLength = this.model.controls.length;
        for (var i = 0; i < controlsLength; i++) {
            var control = this.model.controls[i];
            if (control.propertyName === propertyName) {
                return control.view.getValue();
            }
        }
        throw new Error('Property "' + propertyName + '" not found');
    }
});

var FormBuilder = function(options) {
    _.extend(this, Backbone.Events);
    this.options = options || {};
    this.model = new Model();

    this.model.data = options.model;
    this.model.submitText = this.options.submitText || "Save";
    this.model.cancelText = this.options.cancelText || "Cancel";
    this.model.autoSave = this.options.autoSave || false;
    this.model.twoWayDataBinding = this.options.twoWayDataBinding || false;
    this.model.readOnly = this.options.readOnly || false;
    this.model.labels = this.options.labels || {};
    this.model.viewOptions = this.options.viewOptions || {};
    if (this.options.containerClass) {
        this.model.containerClass = this.options.containerClass;
    }

    if (this.options.mapper) {
        this.mapper();
    }
};

FormBuilder.prototype.addRequiredControl = function(control, propertyName, resourceNamespace, segmentationInfo) {
    this._bindProperty(control, propertyName);
    this.model.controls.push({
        view: control,
        propertyName: propertyName,
        resourceNamespace: resourceNamespace,
        bound: true,
        segmentationInfo: segmentationInfo,
        isRequired: true
    });
};

FormBuilder.prototype.addRequiredStaticControl = function(control, propertyName, resourceNamespace) {
    this.model.controls.push({
        view: control,
        propertyName: propertyName,
        resourceNamespace: resourceNamespace,
        bound: false,
        isRequired: true
    });
};

FormBuilder.prototype.addControl = function(control, propertyName, resourceNamespace, segmentationInfo) {
    this._bindProperty(control, propertyName);
    this.model.controls.push({
        view: control,
        propertyName: propertyName,
        resourceNamespace: resourceNamespace,
        bound: true,
        segmentationInfo: segmentationInfo
    });
};

FormBuilder.prototype.addStaticControl = function(control, propertyName, resourceNamespace) {
    this.model.controls.push({
        view: control,
        propertyName: propertyName,
        resourceNamespace: resourceNamespace,
        bound: false
    });
};

FormBuilder.prototype.sortControls = function() {
    this.model.controls = this.model.controls.sort(function(a, b) {
        return a.propertyName.localeCompare(b.propertyName);
    });
};

FormBuilder.prototype.toggleControlVisibility = function(control, visible) {
    control.$el.toggleClass("hidden-element", !visible);
    //const editorRow = control.$el.closest('li');
    //editorRow.toggleClass('hidden-element', !visible);
};

FormBuilder.prototype.mapper = function() {
    var control;
    for (var key in this.options.mapper) {
        if (this.options.mapper.hasOwnProperty(key)) {
            // try use mapperControlSettings to set View options
            if (this.options.mapperControlSettings && this.options.mapperControlSettings[key]) {
                control = this.options.mapper[key].create(this.options.mapperControlSettings[key]);
            } else {
                control = this.options.mapper[key].create();
            }
            this.addControl(control, key);
        }
    }
};

FormBuilder.prototype._bindProperty = function(control, propertyName) {
    if (propertyName) {
        control.setValue(this.options.model.get(propertyName));
        this._bindAutoSave(control, propertyName);
        this._bindReadOnly(control);
        this._bindErrorHandler(control, propertyName);
        this._bindValidationHandler(control, propertyName);
        this._bindChangeListener(control, propertyName);
        this._bindConnectionValidation(control);
    }
};

FormBuilder.prototype._bindConnectionValidation = function(control) {
    control.on(
        "connection-test",
        function(callback) {
            dbConnectionTest(this.model, true, control).then(callback);
        }.bind(this)
    );
};

FormBuilder.prototype._bindAutoSave = function(control, propertyName) {
    if (this.model.autoSave && !this.model.readOnly) {
        control.on(
            "value:set",
            function(value) {
                this.options.model.set(propertyName, value);
            }.bind(this)
        );

        control.on("value:invalid", function() {}.bind(this));
    }
};

FormBuilder.prototype._bindReadOnly = function(control) {
    control.setEnabled(!this.model.readOnly);
};

FormBuilder.prototype._bindErrorHandler = function(control, propertyName) {
    control.listenTo(
        this.options.model,
        "invalid",
        function(model) {
            if (model.validationError[propertyName]) {
                control.showError(model.validationError[propertyName]);
            }
        }.bind(this)
    );
};

FormBuilder.prototype._bindValidationHandler = function(control, propertyName) {
    control.on(
        "value:set",
        function() {
            this.options.model.validationError = undefined;
            control.showError(null);
        }.bind(this)
    );

    control.on(
        "value:invalid",
        function(error) {
            this.options.model.validationError = {};
            this.options.model.validationError[propertyName] = error;
            this.options.model.trigger("invalid", this.options.model);
        }.bind(this)
    );
};

/** DEV-12555 listen to changes to another control **/
FormBuilder.prototype._bindChangeListener = function(control, propertyName) {
    var _this = this;

    control.on(
        "value:set",
        function(value) {
            _.each(_this.model.controls, function(control) {
                control.view.trigger("value:update:" + propertyName, value);
            });
        }.bind(this)
    );
};

FormBuilder.prototype.create = function() {
    var _this = this;
    this.view = new View({
        model: this.model
    });

    this.view.on("show", function() {
        this.getRegion("controls").show(
            new Controls.View({
                model: _this.model
            })
        );

        _this.trigger("form:rendered");
        if (!_this.model.autoSave) {
            this.actionsView = new ActionsView({
                model: _this.model
            });
            this.getRegion("actions").show(this.actionsView);

            _this.view.listenTo(this.actionsView, "submit", _this.onSave.bind(_this));
            _this.view.listenTo(this.actionsView, "cancel", _this.onCancel.bind(_this));

            _this.view.listenTo(
                _this.view,
                "disableSubmit",
                function() {
                    this.actionsView.disableSubmit();
                }.bind(this)
            );
            _this.view.listenTo(
                _this.view,
                "enableSubmit",
                function() {
                    this.actionsView.enableSubmit();
                }.bind(this)
            );

            _this.view.setReadOnly(_this.model.readOnly);
        } else {
            this.$el.find("> .actions").hide();
        }

        if (_this.model.twoWayDataBinding) {
            _this.model.data.on("change", function(model) {
                for (var changedProperty in model.changed) {
                    if (model.changed.hasOwnProperty(changedProperty)) {
                        var control = _.findWhere(_this.model.controls, {
                            propertyName: changedProperty
                        });
                        control.view.setValue(model.changed[changedProperty]);
                    }
                }
            });
        }
    });

    return this.view;
};

FormBuilder.prototype.onSave = async function() {
    var _this = this;
    var deferredActions = [];
    _this.trigger("form-builder:save-click");
    let formDirty = false;
    function _retrieveMainKey(key) {
        return key.split(".")[0];
    }
    function _retrieveValueByKey(metaObjectData, key) {
        var parts = key.split(".");
        var result = metaObjectData.toJSON();
        parts.forEach(function(part) {
            result = result?.[part];
        });
        return result;
    }
    async function validateControls() {
        const controls = _this.model.controls;
        const propertyTemplateErrors = {};

        let errorFields = [];
        try {
            const metaObjectData = _this.model.get("data");
            const metaObjectType = metaObjectData?.get("type");
            if (metaObjectType) {
                var actions = propertyTemplateService.getActions(metaObjectType);
                actions = actions ?? {};

                _.each(actions, function(value, key) {
                    var keyValue = _retrieveValueByKey(metaObjectData, key);
                    var isObject = $.isPlainObject(keyValue);
                    var isArray = Array.isArray(keyValue);
                    if (value.isRequired) {
                        var isEmptyValue = isArray
                            ? $.isEmptyObject(keyValue)
                            : keyValue === "" || keyValue === null || keyValue === undefined;
                        if (isObject ? $.isEmptyObject(keyValue) : isEmptyValue) {
                            propertyTemplateErrors[_retrieveMainKey(key)] =
                                "Value for " + convertNameToHumanRedable(key) + " is required";
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }

        for (const control of controls) {
            try {
                if (["parser", "adapter", "formatter"].includes(control.propertyName)) {
                    const res = await control.view.validate(control?.resourceLabel);
                    if (Array.isArray(res) && res.length > 0) {
                        errorFields = errorFields.concat(res);
                        formDirty = true;
                    }
                } else {
                    if (control.propertyName !== "outputclause") {
                        try {
                            const res = await control.view.validate(control?.resourceLabel);
                            if ((typeof res === "boolean") & res) {
                                errorFields.push(control.propertyName);
                                formDirty = true;
                            }
                            if (propertyTemplateErrors?.[control.propertyName]) {
                                formDirty = true;
                                errorFields.push(control.propertyName);
                                control.view.showError(propertyTemplateErrors?.[control.propertyName]);
                            }
                        } catch (error) {
                            errorFields.push(control.propertyName);
                        }
                    }
                }
            } catch (error) {
                console.log("Error while validating controls", error);
            }
        }
        return _.unique(errorFields);
    }
    this.model.controls.forEach(function(control) {
        if (control.bound && !control.view.hidden) {
            _this.model.data.set(control.propertyName, control.view.getValue());
        }

        if (control.view.deferredSave) {
            deferredActions.push(control.view.deferredSave());
        }
    });
    const dirtyFields = await validateControls();
    if (dirtyFields.length > 0) {
        _this.trigger("form-builder:validation-errors", dirtyFields);
        return;
    }

    $.when.apply($, deferredActions).then(function() {
        var changedProperties = _this.model.dirtyControls;
        _this.model.dirtyControls = [];
        _this.model.refreshDirty();
        _this.view.trigger("form-builder:save", changedProperties);
        _this.trigger("form-builder:save", changedProperties);
    });
};

FormBuilder.prototype.reset = function() {
    this.model.controls.forEach(function(control) {
        control.view.setValue(undefined);
        control.view.hideError();
    });
};

FormBuilder.prototype.onCancel = function() {
    var _this = this;

    function proceedUnsavedChanges() {
        _this.model.controls.forEach(function(control) {
            control.view.setValue(_this.model.data.get(control.propertyName));
        });
        _this.model.dirtyControls = [];
        _this.model.refreshDirty();

        _this.view.trigger("form-builder:cancel");
        _this.trigger("form-builder:cancel");
    }

    var isNotDirty = _this.model.dirtyControls.length === 0;
    if (isNotDirty) {
        proceedUnsavedChanges();
        return;
    }

    var submitText, additionalClass;
    var message = "You have unsaved changes. Do you want to proceed?";

    if (!this.model.data.id) {
        message = "You have unsaved changes. Do you want to proceed and delete the component?";
        submitText = "Delete";
        additionalClass = "deleteModal";
    }
    dialog.confirm(message, App.Dashboards.ModalManager, additionalClass, null, submitText).then(function(result) {
        if (!result) {
            return;
        }
        proceedUnsavedChanges();
    });
};

export default FormBuilder;
