import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { StriimTypography } from "@striim/striim-ui-v2";
import { styles } from "./report-table.styles";
import { TableRow } from "@mui/material";

export const TitleTypography = ({ children }) => (
    <StriimTypography sx={styles.tableTitle} variant="body">
        {children}
    </StriimTypography>
);

export const DataTypography = ({ children }) => (
    <StriimTypography variant="body" sx={styles.tableData}>
        {children}
    </StriimTypography>
);

export const ReportTable = ({ header, content, legend = null }) => {
    return (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead
                sx={{
                    ".MuiTableCell-head": {
                        background: "#F1F2F2 !important",
                        borderRadius: "0px !important"
                    }
                }}
            >
                <TableRow>
                    {legend && (
                        <th colSpan={7} style={{ backgroundColor: "#fff" }}>
                            {legend}
                        </th>
                    )}
                </TableRow>
                {header}
            </TableHead>
            <TableBody
                sx={{
                    ".MuiTableCell-root": {
                        borderBottom: "0.5px solid rgba(22, 22, 22, 0.25) !important"
                    }
                }}
            >
                {content}
            </TableBody>
        </Table>
    );
};

export const ImportanceLabelWithIcon = ({ label, icon }) => (
    <StriimTypography variant="caption" sx={{ ...styles.lightText, display: "flex", gap: 1 / 2, alignItems: "center" }}>
        {icon}
        <span>{label}</span>
    </StriimTypography>
);
