import Dictionary from "app/components/common/helpable/online-help-dictionary";

const DOC_INDEX_PATH = Dictionary.getIndexHTMLPath();
const sampleGroupsDictionary = {
    Samples: {
        description:
            "These sample applications show continuous Real Time data collection, data correlation across multiple sources, data enrichment, and data visualization using Striim. ",
        validations: []
    },
    SamplesDB: {
        description: `These sample applications show continuous data movement using Initial Load, and continuous CDC (Change Data Capture) from one PostgreSQL DB to another. <a href="${DOC_INDEX_PATH}?contextId=running_the_cdc_sample_apps" target="_blank">Read the docs</a> or <a class="samples-demo-video">Watch a Demo Video</a>`,
        validations: ["postgres"]
    },
    SamplesDB2Kafka: {
        description:
            `These sample applications show continuous data movement from a PostgreSQL Database using Initial Load and CDC to Kafka. <a href="${DOC_INDEX_PATH}?contextId=running_the_cdc_sample_apps" target="_blank">Read the docs</a> or <a class="samples-demo-video">Watch a Demo Video</a>`,
        validations: ["postgres", "kafka"]
    },
    SamplesDB2File: {
        description:
            `These sample aplications show continuous data movement from a PostgreSQL Database using Initial Load and CDC to local files. <a href="${DOC_INDEX_PATH}?contextId=running_the_cdc_sample_apps" target="_blank">Read the docs</a> or <a class="samples-demo-video">Watch a Demo Video</a>`,
        validations: ["postgres"]
    }
};

export default class SampleGreoupsPropertiesProvider {
    static applySampleGroupsProperties(groups) {
        if (!groups || !groups.length) {
            return groups;
        }

        for (let i = 0; i < groups.length; i++) {
            let group = groups[i];
            var sampleGroupProperties = sampleGroupsDictionary[group.name];
            if (!sampleGroupProperties) {
                continue;
            }

            group.description = sampleGroupProperties.description;
            group.validations = sampleGroupProperties.validations;
        }

        return groups;
    }
}
