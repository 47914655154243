import React from "react";
import { Container, Grid, Box } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Tracker from "core/services/tracker/tracker";
import useStyles from './upgrade-to-cloud.styles'

const UpgradeToCloudTemplate = () => {
    const classes = useStyles();
    return (
        <Container direction="row" className={classes.container}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.wrapper}
            >
                <Box className={classes.img} component="img" src="app/images/cloud-arrow-up.svg" />
                <span className={classes.header}>Upgrade to Striim Cloud</span><br></br>
                <span className={classes.paragraph}>This feature is not accessible to users on Striim Developer. Upgrade to Striim Cloud for access to this feature and many others.</span>

                <Box style={{ margin: "32px 0" }}>
                    <Grid
                        item
                        container
                        xs={12}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item container xs={12}>
                            <Box className={classes.boxContainer}>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    wrap="nowrap"
                                >
                                    <Box className={classes.img1} component="img" src="app/images/cloud-compute.svg" />
                                    <div className={classes.desc}>
                                        <span className={classes.h2}>Dedicated Compute</span><br></br>
                                        <span className={classes.subtitle}>Resources that you can auto-scale with a click of a button</span>
                                    </div>
                                </Grid>
                            </Box>
                            <Box className={classes.boxContainer}>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    wrap="nowrap"
                                >
                                    <Box className={classes.img1} component="img" src="app/images/cloud-security.svg" />
                                    <div className={classes.desc}>
                                        <span className={classes.h2}>Security</span><br></br>
                                        <span className={classes.subtitle}>SAML-based user federation, support for encrypted connectivity via ssh tunnels, Agents, and PrivateLink</span>
                                    </div>
                                </Grid>
                            </Box>
                            <Box className={classes.boxContainer}>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    wrap="nowrap"
                                >
                                    <Box className={classes.img1} component="img" src="app/images/cloud-customer-service.svg" />
                                    <div className={classes.desc}>
                                        <span className={classes.h2}>Customer Success and Support</span><br></br>
                                        <span className={classes.subtitle}>Work with Striim experts to keep your data streaming to meet critical business SLAs</span>
                                    </div>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <a href="https://developer-upgrade.striim.com/" target="_blank" rel="noopener noreferrer">
                    <div
                        className={classes.btnContainer}
                        onClick={() => {
                            Tracker.getInstance().track('developer-upgrade', {
                                id: window.location.hash.split("/")[2],
                                event: "Developer:Clicked",
                                buttonClicked:"Upgrade Now"
                            });
                        }}
                    >
                        <span className={classes.btnLabel} >Upgrade Now</span>
                        <ArrowForwardIcon className={classes.arrowForwardIcon} />
                    </div>
                </a>
            </Grid>
        </Container>
    );
}

export default UpgradeToCloudTemplate;