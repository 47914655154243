import React from "react";
import { StriimTypography, StriimButton } from "@striim/striim-ui";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";

import { styles } from "./sso-container.styles";

const SsoEmpty = ({ toggleAddSso }) => {
    return (
        <Grid container sx={styles.wrapperRoot} data-test-id="sso-empty-container">
            <StriimTypography variant="h3" sx={styles.emptySSOHeader}>
                Single Sign On
            </StriimTypography>
            <Grid container sx={styles.noSsoWrapper}>
                <Grid item md={12} sx={styles.emptyWrapperItemTop}>
                    <StriimTypography variant="h4" style={{ fontWeight: 400 }}>
                        There is no Single Sign On added. You can add using below option.
                    </StriimTypography>
                </Grid>
                <Grid item md={12} sx={styles.emptyWrapperItemBottom}>
                    <StriimButton
                        variant="primaryText"
                        startIcon={<AddIcon />}
                        onClick={toggleAddSso}
                        data-test-id="sso-add-button"
                    >
                        Add SSO
                    </StriimButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SsoEmpty;
