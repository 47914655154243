export default {
    wrapper: {
        height: `calc(100vh - 250px)`,
        marginTop: theme => theme.spacing(5)
    },
    imgContainer: {
        width: 200,
        margin: theme => theme.spacing(4, 0)
    },
    link: {
        textDecoration: "underline",
        color: theme => theme.palette.greyscale[700]
    },
    imgDescriptionContainer: {
        textAlign: "center",
        width: 400
    },
    imgDescription: {
        color: theme => theme.palette.greyscale[700]
    },
    subtitleContainer: {
        marginTop: theme => theme.spacing(2)
    },
    noResultsText: {
        color: theme => theme.palette.primary[700]
    }
};
