import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, SvgIcon } from "@mui/material";
import { StriimDataTable, StriimTypography } from "@striim/striim-ui";

import { AppNameContainer, SourceTarget } from "../../../components/select-tables-component/select-tables-component";
import { styles } from "./selected-source-apps.styles.js";
import WithTooltip from "../../../../../generic/tooltip/tooltip";
import InfoIcon from "app/images/striimline/info-icon.svg";
import ProgressBar from "../progress-bar";
import { JobStatuses } from "../../guardian-job.types";
import dictionary from "../../../../../../app/components/common/helpable/online-help-dictionary";

interface SelectedSourceAppsProps {
    tableData?: Array<{ name: string; }>;
    customMaxHeight?: string;
    jobName: string;
    selectedSource: string;
    allTableData: Array<{ name: string; }>;
    appsInfoData: Array<
        {
            sourceType: string;
            appInfo: Array<{ appName: string; }>;
        }
    >;
}

const CustomHeader = props => {
    const { columnHeader } = props;
    return (
        <Grid container gap={0.5}>
            {columnHeader}
            {WithTooltip(
                <SvgIcon component={InfoIcon} sx={styles.iconInfo} />,
                <>
                    Entities discovered with sensitive data out of the total number of entities sampled. An entity is a
                    logical collection of data objects that share a common structure such as a table in a relational
                    database, a collection in a NoSQL database, a directory in a file system or a cloud object storage
                    system.{" "}
                    <StriimTypography
                        variant="caption3"
                        component="a"
                        href={dictionary.get()["AI_INSIGHTS_SHERLOCK"].href}
                        color="secondary.500"
                        target="_blank"
                    >
                        Learn more
                    </StriimTypography>
                </>
            )}
        </Grid>
    );
};

const SelectedSourceApps = ({
    tableData,
    customMaxHeight = "100%",
    jobName = "",
    selectedSource = "",
    allTableData,
    appsInfoData
}: SelectedSourceAppsProps) => {
    const [appsList, setAppsList] = useState([]);

    const getAppsListData = async jobName => {
        try {
            const selectedAppsList = appsInfoData.find(data => data.sourceType === selectedSource);
            const list = selectedAppsList ? selectedAppsList.appInfo : appsInfoData[0].appInfo;
            const updatedList = list.map(el => {
                const sourceTarget = allTableData.find(data => data.name === el.appName);
                return ({
                    ...el,
                    ...sourceTarget
                })
            })

            setAppsList(updatedList);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getAppsListData(jobName);
    }, [selectedSource]);

    const tableColumns = useMemo(
        () => [
            {
                headerName: `App Name (${appsList?.length || 0})`,
                field: "appName",
                flex: 1,
                suppressMovable: true,
                resizable: true,
                cellRenderer: ({ value, data }) => (
                    <AppNameContainer
                        name={value}
                        status={data.status}
                        nsName={data.appNamespace}
                    />
                ),
                cellClass: "appNamecellClass",
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: "Namespace",
                field: "appNamespace",
                suppressMovable: true,
                resizable: true,
                cellRenderer: ({ value }) => (
                    <StriimTypography variant="body4" color="greyscale.700">
                        {value ?? "-"}
                    </StriimTypography>
                )
            },
            {
                headerName: "Source-Target",
                field: "sourcetarget",
                suppressMovable: true,
                resizable: true,
                headerClass: "header-center no-hover",
                cellClass: "cell-center",
                cellRenderer: SourceTarget
            },
            {
                headerComponent: CustomHeader,
                field: "numSensitiveEntities",
                headerComponentParams: {
                    columnHeader: "Entities w/ Sensitive Data "
                },
                flex: 1,
                suppressMovable: true,
                resizable: true,
                cellRenderer: ({ value, data }) => {
                    const status = data?.status?.toLowerCase();
                    const isRunning = status === JobStatuses.RUNNING.toLowerCase();

                    return status === "error" ? (
                        <StriimTypography variant="body4" color="critical.500">
                            n/a
                        </StriimTypography>
                    ) : isRunning ? (
                        "-"
                    ) : (
                        <ProgressBar
                            completed={value}
                            total={data?.numTotalEntities || 0}
                            status={status}
                        />
                    );
                }
            }
        ],
        [tableData]
    );

    return (
        <Box sx={styles.dataTableWrapper}>
            <StriimDataTable data={appsList} columns={tableColumns} maxHeight={customMaxHeight} />
        </Box>
    );
};

export default SelectedSourceApps;
