import { Model } from "backbone.nestedtypes";

export default Model.extend({
    defaults: {
        searchPhrase: String,
        from: String,
        to: String,
        nodes: Array,
        types: Array,

        logEntries: Array,
    },
});
