import App from "app";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import "./editor";

import xss from 'xss';

const options = {
    whiteList: {
        a: ['href', 'title', 'target', 'style', 'class'],
        img: ['style' ,'src', 'alt', 'title', 'width', 'height', 'class'],
        div: ['class', 'style'],
        span: ['class', 'style'],
    }
};

App.module("Dashboard.VisEditor", function (VisEditor, App, Backbone, Marionette, $, _) {
    VisEditor[dataVisualizations.EDITOR.TEXT] = VisEditor.Editor.LayoutView.extend({
        templateHTML:
            '<textarea rows="2" cols="30" name="{{ editor.attribute }}" placeholder="{{ editor.options ? editor.options.placeholder : ""}}"></textarea>',
        events: {
            "change textarea": "valueChanged",
        },
        valueChanged: function (e) {
            this.on_change(xss(e.target.value, options));
        },
        onRender: function () {
            if (this.model.editor.options.length) {
                this.$("input").css("width", this.model.editor.options.length * 15 + 30);
            }
            this.$("textarea").val(xss(this.get_value(), options));
        },
    });
});
