const options = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
};

const formatFirstAndLastTimestamp = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', options).replace(/(\s)(AM|PM)/i, (match, space, value) => value.toLowerCase());
}

const formatXAxisLabel = (timestamp: number) => {
    const date = new Date(timestamp);
    const timeString = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    })
    return timeString.replace(' ', '').toLowerCase();
}

const formatNavigatorFirstAndLastTimestamp = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
    }).replace(',', '');
}

const formatNavigatorXAxisLabel = (timestamp: number) => {
    const date = new Date(timestamp);
    const timeString = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    })
    return timeString.toLowerCase();
}

const formatTimestampForTooltip = (timestamp: number) => {
    const date = new Date(timestamp);

    const options = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    return date.toLocaleString('en-US', options);
}

const generateNavigatorSeries = () => {
    const result = [];
    const currentTime = Date.now() / 1000;
    for (let i = (24 * 60) - 1; i >= 0; i--) {
        const timestamp = (currentTime - (i * 60));
        result.push([timestamp, Math.floor(Math.random() * 10) + 1])
    }
    return result;
}


const formatTimestampRanges = (startTimestamp, endTimestamp) => {
    const formattedStart = formatFirstAndLastTimestamp(startTimestamp);
    const formattedEnd = formatFirstAndLastTimestamp(endTimestamp);
    return `${formattedStart} - ${formattedEnd}`;
}

// If Whole number, returns a whole number without decimals
// If decimal, returns number with 2 digit precison
const getTwoPointPrecision = num => (num ? (num % 1 ? num.toFixed(2) : num) : 0);


export { formatFirstAndLastTimestamp, formatXAxisLabel, formatNavigatorFirstAndLastTimestamp, formatNavigatorXAxisLabel, formatTimestampForTooltip, formatTimestampRanges, generateNavigatorSeries, getTwoPointPrecision }