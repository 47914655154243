const styles = {
    addSmallMargin: {
        marginTop: "4px !important"
    },
    rightSideButton: {
        color: ({ palette }) => palette.greyscale[800],
        minWidth: "0px !important"
    },
    noItemsMessage: {
        textAlign: "center"
    },
    inputLabel: {},
    disabledLabel: {
        color: ({ palette }) => palette.greyscale[400]
    },
    formErrorText: {
        marginTop: ({ spacing }) => spacing(1 / 2),
        color: ({ palette }) => `${palette.critical.main} !important`
    }
};
export default styles;
