import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./text-field.html";
import xss from "xss";

var Model = BaseControl.Model.extend({
    defaults: {
        value: null
    }
});

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "close-input",
    ui: {
        data: "input._data",
        closeBtn: "._closeIcon",
        unit: "._unit",
        helperText: "._helper-text"
    },
    //fixme events probably cannot work
    events: {
        "keyup input": "setValueFromView",
        "change input": "setValueFromView"
    },

    filterText: function() {
        var _this = this;
        if (!_this.ui.closeBtn.show) {
            // control not initialized
            return;
        }
        var enteredData = _this.ui.data.val && _this.ui.data.val() ? _this.ui.data.val().trim() : "";
        if (enteredData.length > 0) {
            _this.ui.closeBtn.show();
        } else {
            _this.ui.closeBtn.hide();
        }
    },

    getViewValue: function() {
        var value = this.$(this.ui.data).val();
        if (value === "") {
            return null;
        }
        return value;
    },

    setViewValue: function() {
        this.$(this.ui.data).val(xss(this.getValue()));
        this.filterText();
    },

    onRender: function() {
        var _this = this;
        let $input = this.$(_this.ui.data);

        // value can't be set directly in the template, because it can contains
        // special characters like '"' which can break the template
        let value = this.model.value;
        value = xss(value);

        // if(value){
        //     console.log("old: ", value, "   new:   " ,value.replace(/\\/ig,"\\\\"))
        //     value = value.replace(/\\/ig,"\\\\")
        // }
        $input.val(value);

        var placeholder = this.placeholder;
        if (!placeholder) {
            if (this.customViewOptions) {
                placeholder = this.customViewOptions.placeholder;
            }
        }
        if (placeholder) {
            $input.attr("placeholder", placeholder);
        }

        var unit = this.unit;
        if (unit) {
            _this.ui.unit.html(unit);
            _this.ui.unit.show();
        }

        if (this.className && this.className.includes("close-input")) {
            // _debounce will execute the function after 500ms.
            // for consecutive calls.. last function call will execute
            this.ui.data.keyup(
                _.debounce(function() {
                    _this.filterText();
                }, 500)
            );

            this.ui.closeBtn.click(function() {
                _this.$(_this.ui.data).val("");
                _this.ui.closeBtn.hide();
                _this.setValueFromView();
            });
        }
    },

    setPlaceholder(placeholder) {
        this.$(this.ui.data).attr("placeholder", placeholder);
    },

    focus: function() {
        this.$(this.ui.data).focus();
        this.$(this.ui.data).select();
    }
});

function buildTextField() {
    return _.extend({}, BaseControl, {
        View: View,
        Model: Model,
        WithPlaceholder: function(placeholder) {
            return buildTextField().extend({
                placeholder: placeholder
            });
        },
        Search: function() {
            return this.extend({
                placeholder: "Search...",
                className: "search-input close-input"
            });
        },
        WithUnit: function(unit) {
            return buildTextField().extend({
                unit: unit
            });
        },
        WithHelperText: function(unit = null, placeholder = null) {
            return buildTextField().extend({
                unit: unit,
                placeholder: placeholder,
                helperText: true,
                hideError: function() {
                    this.ui.helperText.hide();
                    this.ui.closeBtn.css("bottom", "12px");
                },
                showError: function(message) {
                    this.ui.helperText.show();
                    this.ui.helperText.html(message);
                    this.ui.closeBtn.css("bottom", "34px");
                }
            });
        }
    });
}

export default buildTextField();
