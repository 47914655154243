import { useEffect, useMemo } from "react";
import { AutomatedAppGroupStatusSynchronizer } from "../automated-app-group-synchronizer";
import automatedGroupStore from "../automated-app-group-details.store";
import { toJS } from "mobx";
import { sortAutomatedApps } from "../../automated-app-group/utils/progress-details.helper";

type selectedGroup = {
    groupType: "ILCDC" | "INTERNAL";
    name: string;
    applications: Array<string>;
};

const useAppGroupStatusSynchronizer = (appGroup: selectedGroup) => {
    const appStatusSynchronizer = useMemo(() => {
        const appIds = sortAutomatedApps(appGroup?.applications);
        return new AutomatedAppGroupStatusSynchronizer(appIds);
    }, [appGroup]);

    const setValuesToStore = (statuses: Record<string, string>) => {
        automatedGroupStore.setAppStatuses(statuses);
        const focusedApp = appStatusSynchronizer.getCurrentFocusedApp(statuses);
        automatedGroupStore.setCurrentFocusedApp(focusedApp);
    };
    useEffect(() => {
        (async () => {
            if (!automatedGroupStore.isAppTransitioning) {
                const statuses = await appStatusSynchronizer.fetchStatuses();
                if (JSON.stringify(statuses) === JSON.stringify(toJS(automatedGroupStore.appStatuses))) {
                    return;
                }
                setValuesToStore(statuses);
                const focusedApp = appStatusSynchronizer.getCurrentFocusedApp(statuses);
                automatedGroupStore.setCurrentFocusedApp(focusedApp);
            }
        })();
    }, [automatedGroupStore.isAppTransitioning]);
    useEffect(() => {
        (async () => {
            const statuses = await appStatusSynchronizer.fetchStatuses();
            setValuesToStore(statuses);
            appStatusSynchronizer.listenToAppsStatusChange((statuses: Record<string, string>) => {
                setValuesToStore(statuses);
            });
        })();
        return () => {
            appStatusSynchronizer.clearStatusChangeListener();
        };
    }, [appGroup]);
};

export default useAppGroupStatusSynchronizer;
