import NestedTypes from "backbone.nestedtypes";
import $ from "jquery";
import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./window-size.html";
import resources from "app/components/common/editor/resources_en.json";
import FieldDataSource from "app/components/common/editor/control/select/field-datasource";
import Adapters from "app/components/common/editor/control/select/adapters";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import Compactor from "./compactor";
import Consts from "./window-size-consts";
import "lib/vendor/jquery.clearsearch";
import $__app_components_common_editor_control_ui_control from "app/components/common/editor/control/ui-control";
import $__app_components_common_editor_form_builder from "app/components/common/editor/form-builder";
import ensureRegions from "../../../_ensureRegions";

var Model = NestedTypes.Model.extend({
    defaults: {
        type: null,
        time: null,
        count: null,
        onField: null,
        timeout: null,
        outputInterval: null,
        isSession: false
    }
});

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "window-size",
    regions: {
        timeRegion: ".time-form",
        countRegion: ".count-form",
        hybridRegion: ".hybrid-form"
    },
    ui: {
        groups: ".group",
        options: "input[type=radio]",
        timeForm: ".time-form",
        countForm: ".count-form",
        hybridForm: ".hybrid-form",

        timeRadio: ".time-radio",
        countRadio: ".count-radio",
        hybridRadio: ".hybrid-radio",

        description: ".description"
    },
    events: {
        "click @ui.options": "onChangedType"
    },
    onChangedType: function(e) {
        var target = e.target;
        if (this.sizeModel.type === target.value) {
            return;
        }

        this.ui.groups.hide();
        this.sizeModel.set({
            type: target.value,
            time: undefined,
            count: undefined,
            onField: undefined,
            timeout: undefined,
            outputInterval: undefined
        });
        this.timeForm.reset();
        this.countForm.reset();
        this.hybridForm.reset();
        this.timeTypePicker.setValue(Consts.SystemTime);

        this.$(".size-group").removeClass("active");
        this.hybridTime.setEnabled(true);
        this.hybridCount.setEnabled(true);

        this.timeFieldSelect.$el.closest(".field-row").hide();

        var selectedType = $(target).parents(".size-group");
        selectedType.find(".group").show();
        selectedType.addClass("active");
    },
    initialize: function() {
        this._UIControl = $__app_components_common_editor_control_ui_control;
        this._FormBuilder = $__app_components_common_editor_form_builder;
        BaseControl.View.prototype.initialize.apply(this, arguments);
        var _this = this;
        this.on("change:stream:projections", function(stream_id) {
            _this.streamId = stream_id;
            _this.render();
        });
        this.on("change:mode", function(mode) {
            _this.isSliding = mode === "sliding";
            if (!_this.isSliding) {
                _this.sizeModel.outputInterval = null;
            }

            var isSession = mode === "session";
            if (isSession) {
                _this.sizeModel.set({
                    type: "hybrid",
                    count: -1,
                    time: null,
                    isSession: true
                });
                if (_this.sizeModel.get("timeout") === null) {
                    _this.sizeModel.set("timeout", 30000000);
                }
            } else {
                if (_this.sizeModel.get("count") === -1) {
                    _this.sizeModel.set("count", null);
                }
                _this.sizeModel.set("isSession", false);
            }

            _this.render();
        });
    },
    onRender: function() {
        ensureRegions(this);
        var _this = this;
        if (!this.sizeModel) {
            this.sizeModel = new Model(this.getValue());
            if (this.outputInterval) {
                this.sizeModel.outputInterval = this.outputInterval;
            }
        }
        if (this.sizeModel.get("count") === -1) {
            this.sizeModel.set("isSession", true);
        }
        this.timeForm = new this._FormBuilder({
            model: this.sizeModel,
            ReadOnly: this.options.enabled,
            autoSave: true
        });

        this.countForm = new this._FormBuilder({
            model: this.sizeModel,
            ReadOnly: this.options.enabled,
            autoSave: true
        });

        this.hybridForm = new this._FormBuilder({
            model: this.sizeModel,
            ReadOnly: this.options.enabled,
            autoSave: true
        });

        this.refreshHybrid = function() {
            if (_this.sizeModel.type === Consts.Type.Hybrid) {
                var hybridCountRow = _this.hybridOutputIntervalCount.$el.closest(".field-row");
                var hybridTimeRow = _this.hybridOutputIntervalTime.$el.closest(".field-row");
                var hybridOnRow = _this.hybridFieldSelect.$el.closest(".field-row");
                if (_this.sizeModel.count) {
                    hybridCountRow.show();
                    hybridTimeRow.hide();
                    hybridOnRow.hide();
                    this.hybridTime.setEnabled(false);
                    this.hybridCount.$el.find("input").addClass("x");

                    _this.sizeModel.set(
                        {
                            time: null
                        },
                        {
                            silent: true
                        }
                    );
                } else if (_this.sizeModel.time) {
                    hybridCountRow.hide();
                    hybridTimeRow.show();
                    hybridOnRow.show();
                    this.hybridCount.setEnabled(false);
                    this.hybridTime.$el.find("input").addClass("x");

                    _this.sizeModel.set(
                        {
                            count: null
                        },
                        {
                            silent: true
                        }
                    );
                } else {
                    _this.sizeModel.outputInterval = null;
                    _this.sizeModel.onField = null;
                    hybridCountRow.hide();
                    hybridTimeRow.hide();
                    hybridOnRow.hide();

                    _this.sizeModel.set(
                        {
                            count: null
                        },
                        {
                            silent: true
                        }
                    );
                    _this.sizeModel.set(
                        {
                            time: null
                        },
                        {
                            silent: true
                        }
                    );
                }
            }
        };

        this.listenTo(this.sizeModel, "change", function() {
            _this.setValueFromView();
            _this.refreshHybrid();
        });

        this.timeControl = this._UIControl.Interval.create();
        this.timeForm.addControl(this.timeControl, Consts.Fields.Time);

        this.hybridTime = this._UIControl.Interval.create();
        this.hybridTime.on(_this._UIControl.EVENT.VALUE_CHANGED, function(val) {
            _this.hybridCount.setValue(null);
            _this.hybridCount.setEnabled(!val);
        });
        this.hybridTime.setEnabled(false);
        this.hybridForm.addControl(this.hybridTime, Consts.Fields.Time);

        this.timeTypePicker = _this._UIControl
            .Select(Consts.TimeOptions, {
                allowClear: false,
                hideSearch: true
            })
            .create();
        _this.timeForm.addStaticControl(this.timeTypePicker, "");
        this.timeTypePicker.setValue(_this.sizeModel.onField ? Consts.EventTime : Consts.SystemTime);
        this.timeTypePicker.on(_this._UIControl.EVENT.VALUE_CHANGED, function(option) {
            if (_this.timeFieldSelect) {
                _this.timeFieldSelect.$el.closest(".field-row").toggle(option === Consts.EventTime);
                _this.timeFieldSelect.setValue(null);
                _this.sizeModel.onField = null;
            }
        });

        var dataSource = new FieldDataSource();
        _this.timeFieldSelect = _this._UIControl.Select(dataSource).create();
        _this.timeForm.addControl(_this.timeFieldSelect, Consts.Fields.OnField);

        if (this.isSliding) {
            this.timeOutputInderval = this._UIControl.MicroSeconds.create();
            _this.timeForm.addControl(this.timeOutputInderval, Consts.Fields.OutputInterval);
        }

        if (!this.sizeModel.onField && _this.timeFieldSelect) {
            _this.timeFieldSelect.elementAdapter = Adapters.rowHider;
        }

        this.countForm.addControl(this._UIControl.NumberField.create(), Consts.Fields.Count);
        this.hybridCount = this._UIControl.NumberField.create();
        this.hybridCount.on(_this._UIControl.EVENT.VALUE_CHANGED, function(val) {
            _this.hybridTime.setEnabled(!val);
            _this.hybridTime.setValue(null);
        });
        this.hybridForm.addControl(this.hybridCount, Consts.Fields.Count);

        if (this.isSliding) {
            _this.countForm.addControl(this._UIControl.NumberField.create(), Consts.Fields.OutputInterval);
        }

        this.hybridTimeout = this._UIControl.MicroSeconds.create();
        this.hybridForm.addControl(this.hybridTimeout, Consts.Fields.Timeout);
        this.hybridFieldSelect = this._UIControl
            .Select(dataSource)
            .extend({
                hidden: true
            })
            .create();
        this.hybridForm.addControl(this.hybridFieldSelect, Consts.Fields.OnField);

        this.hybridOutputIntervalCount = this._UIControl.NumberField.extend({
            hidden: true
        }).create();
        this.hybridOutputIntervalTime = this._UIControl.MicroSeconds.extend({
            hidden: true
        }).create();

        if (this.isSliding) {
            _this.hybridForm.addControl(this.hybridOutputIntervalCount, Consts.Fields.OutputInterval);
            _this.hybridForm.addControl(this.hybridOutputIntervalTime, Consts.Fields.OutputInterval);
        }

        this.timeRegion.show(this.timeForm.create());
        this.countRegion.show(this.countForm.create());
        this.hybridRegion.show(this.hybridForm.create());

        var contents = resources["sizeadvanced"];
        this.ui.description.tooltipster({
            interactive: true,
            content: contents.description,
            maxWidth: 408,
            theme: "control-label",
            offsetY: -10,
            speed: 0,
            delay: 200,
            contentAsHTML: true
        });

        if (this.sizeModel.type === Consts.Type.Hybrid) {
            this.ui.hybridForm.show();
            this.ui.hybridForm.parent().addClass("active");
            this.ui.hybridRadio.prop("checked", true);
        } else if (this.sizeModel.type === Consts.Type.CountBased) {
            this.ui.countForm.show();
            this.ui.countForm.parent().addClass("active");
            this.ui.countRadio.prop("checked", true);
        } else {
            this.ui.timeForm.show();
            this.ui.timeForm.parent().addClass("active");
            this.ui.timeRadio.prop("checked", true);
        }

        if (this.streamId) {
            metaStoreService.findById(this.streamId).then(function(streamModel) {
                if (!streamModel) {
                    return;
                }
                dataSource = new FieldDataSource(streamModel.dataType);
                _this.timeFieldSelect.setDataSource(dataSource);
                if (!_this.hybridFieldSelect.isDestroyed) {
                    _this.hybridFieldSelect.setDataSource(dataSource);
                }
            });
        }

        setTimeout(function() {
            if (_this.isDestroyed || !_this.getEnabled()) {
                return;
            }
            if (_this.sizeModel.get("isSession")) {
                _this.ui.countRadio.prop("disabled", true);
                _this.ui.timeRadio.prop("disabled", true);
                _this.$el.find(".from-control-container-Time").hide();
                _this.$el.find(".from-control-container-Events").hide();
            } else {
                _this.ui.countRadio.prop("disabled", false);
                _this.ui.timeRadio.prop("disabled", false);
                _this.$el.find(".from-control-container-Time").show();
                _this.$el.find(".from-control-container-Events").show();
            }
            var enabled = _this.getEnabled();
            if (_this.sizeModel.get("isSession")) {
                _this.hybridCount.setEnabled(false);
            } else {
                _this.hybridCount.setEnabled(enabled);
            }
        }, 200); // instead of propagating this call, just wait until the above views are rendered
        BaseControl.View.prototype.onRender.apply(this, arguments);
    },

    onShow: function() {
        //this.$("input[type=text]").addClear();
        this.refreshHybrid();
    },

    getViewValue: function() {
        var json = this.sizeModel.toJSON();
        var compacted = Compactor.compactObject(json);
        return compacted;
    },

    setViewEnabled: function() {
        if (!this.$el.html()) {
            return;
        }

        BaseControl.View.prototype.setViewEnabled.call(this);

        var enabled = this.getEnabled();
        this.$("input").prop("disabled", !enabled);
        this.$("select").prop("disabled", !enabled);
        this.timeControl.setEnabled(enabled);
        this.timeFieldSelect.setEnabled(enabled);
        this.timeTypePicker.setEnabled(enabled);
        if (this.hybridTimeout && !this.hybridTimeout.isDestroyed) {
            this.hybridTimeout.setEnabled(enabled);
        }
        if (!this.hybridFieldSelect.isDestroyed) {
            this.hybridFieldSelect.setEnabled(enabled);
        }
        if (this.timeOutputInderval && !this.timeOutputInderval.isDestroyed) {
            this.timeOutputInderval.setEnabled(enabled);
        }
        if (this.hybridOutputIntervalTime && !this.hybridOutputIntervalTime.isDestroyed) {
            this.hybridOutputIntervalTime.setEnabled(enabled);
        }
        this.refreshHybrid();
    },

    setViewValue: function() {}
});

export default _.extend({}, BaseControl, {
    View: View
});
