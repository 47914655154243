import React, { useRef, useState } from "react";
import { StriimTypography, StriimModal, StriimButton } from "@striim/striim-ui";
import growl from "app/components/common/growl";
import { VaultModalContent } from "./";
import VaultsService from "./vaults-service";
import { VaultTypes } from "./constants";
import {
    formatStriimVaultData,
    formatHashicorpVaultData,
    formatAzureVaultData,
    formatGoogleSecretManagerData
} from "./utils";
import { Box } from "@mui/material";
import { Close } from "src/generic/icon/customIcons";

const styles = {
    modalHeader: {
        display: "flex",
        justifyContent: "space-between"
    },
    dialog: {
        ".MuiDialog-paper": { width: "900px" },
        ".MuiDialogActions-root": {
            borderTop: "1px solid",
            borderColor: "greyscale.200"
        }
    }
};
const VaultModal = ({ setIsModalShown, onAdded, dataForEdit, isEdit = false }) => {
    const [isValid, setIsValid] = useState(false);
    const formValues = useRef();
    const [selectedVaultType, setSelectedVaultType] = useState(VaultTypes.STRIIM_VAULT);

    const onSave = async () => {
        let submitValue;
        // in case we need to do marshalling on save
        switch (selectedVaultType) {
            case VaultTypes.STRIIM_VAULT: {
                submitValue = formatStriimVaultData(formValues.current);
                break;
            }
            case VaultTypes.HASHICORP_VAULT: {
                submitValue = formatHashicorpVaultData(formValues.current);
                if (
                    isEdit &&
                    (submitValue.properties.AccessToken === null || submitValue.properties.AccessToken === "")
                ) {
                    delete submitValue.properties.AccessToken;
                }

                break;
            }
            case VaultTypes.AZURE_VAULT: {
                submitValue = formatAzureVaultData(formValues.current);
                if (
                    isEdit &&
                    (submitValue.properties.ClientSecret === null || submitValue.properties.ClientSecret === "")
                ) {
                    delete submitValue.properties.ClientSecret;
                }
                break;
            }
            case VaultTypes.GOOGLE_SECRET_MANAGER: {
                submitValue = formatGoogleSecretManagerData(formValues.current);
            }
        }

        try {
            if (isEdit) {
                await VaultsService.alterVault(dataForEdit.get("id"), submitValue.properties);
                setIsModalShown(false);
                growl.success("Vault edited successfully");
            } else {
                await VaultsService.saveVault(submitValue);
                setIsModalShown(false);
                growl.success("Vault saved successfully");
            }
            onAdded && onAdded();
        } catch (e) {
            console.error(e);
            growl.error("Error, please try again: " + e);
        }
    };

    const setFormValues = values => (formValues.current = values);

    return (
        <StriimModal
            onCancel={() => setIsModalShown(false)}
            onConfirm={onSave}
            isVisible={true}
            confirmContent="Save"
            size="large"
            dialogProps={{
                sx: styles.dialog
            }}
            autoHeight
            titleContent={
                <div style={styles.modalHeader}>
                    <StriimTypography variant={"h2"}>{isEdit ? "Edit Vault" : "New Vault"}</StriimTypography>
                    <StriimButton size="small" onClick={() => setIsModalShown(false)}>
                        <Close fontSize={24} />
                    </StriimButton>
                </div>
            }
            confirmButtonProps={{
                disabled: !isValid,
                "data-test-id": "vaults-add-vault-confirm-button"
            }}
        >
            <Box mb={3}>
                <VaultModalContent
                    setIsValid={setIsValid}
                    setFormValues={setFormValues}
                    setSelectedVaultType={setSelectedVaultType}
                    selectedVaultType={selectedVaultType}
                    dataForEdit={dataForEdit}
                />
            </Box>
        </StriimModal>
    );
};

export default VaultModal;
