import NProgress from "nprogress";

export default {
    start: function () {
        NProgress.configure({
            parent: "body",
        });
        NProgress.start();
    },

    stop: function () {
        NProgress.done();
    },
};
