import React, { useEffect, useState, useRef } from "react";
import { StriimModal, StriimTypography } from "@striim/striim-ui-v2";
import ModalHeader from "./modal-header";
import useProgressStore from "../../../stores/table-progress/useProgressStore";
import TableProgressContent from "./table-progress-content";
import { Box } from "@mui/material";
import sxStyles from "./table-progress-modal.styles";
import appStatusSynchronizer from "../../../../core/appStatusSynchronizer";
import api from "../../../../core/api/api";
import { getAppType } from "./utils/table-progress-utils";
import { TYPES } from "../../../../core/services/monitorService";
import growl from "../../../../app/components/common/growl";

const INTERVAL = 5000;
interface TableProgressModalProps {
    appModel: any;
    isTableProgressVisible: boolean;
    setTableProgressVisible?: (value: boolean) => void;
    destroyModal?: () => void;
}

const TableProgressModal: React.FunctionComponent<TableProgressModalProps> = ({
    appModel,
    destroyModal,
    isTableProgressVisible,
    setTableProgressVisible
}) => {
    const [isVisible, setIsVisible] = useState<boolean>(isTableProgressVisible ?? true);
    const [appType, setAppType] = useState<"IL" | "CDC" | null>(null);
    const tableProgressStore = useProgressStore();
    const [appStatus, setAppStatus] = useState<string>("");
    const apiTimer = useRef(null);

    function pullLatestMetrics() {
        apiTimer.current = setInterval(() => {
            getStats();
        }, INTERVAL);
        getStats();
    }
    useEffect(() => {
        typeof isTableProgressVisible === "boolean" && setIsVisible(isTableProgressVisible);
    }, [isTableProgressVisible]);

    async function getStats() {
        try {
            const stats = await api.getMonitoringStatsForApp(appModel.id, TYPES.FULL);
            tableProgressStore.setTableProgress(stats);
        } catch (err) {
            growl.error("Error while fetching monitoring stats" + JSON.stringify(err));
        }
    }

    const updateAppStatus = (appStatus: string) => {
        setAppStatus(appStatus);
    };

    useEffect(() => {
        tableProgressStore.reset();
        pullLatestMetrics();
        tableProgressStore.setAppModel(appModel);
        let appStatus = appStatusSynchronizer.getStatus(appModel.id);
        setAppStatus(appStatus);
        appStatusSynchronizer.on(appModel.id, updateAppStatus);
        return () => {
            appStatusSynchronizer.off(appModel.id, updateAppStatus);
            clearInterval(apiTimer.current);
        };
    }, []);
    useEffect(() => {
        if (!isVisible) {
            clearInterval(apiTimer.current);
        }
    }, [isVisible]);

    useEffect(() => {
        //find whether the app is Il/CDC
        (async () => {
            try {
                const sources = await tableProgressStore.appModel.getApplicationComponentsByTypes(
                    ["SOURCE", "EXTERNALSOURCE"],
                    true
                );
                const sourceIds = sources.map(sourceAdapter => sourceAdapter.get("id"));
                const sourcesWithTableMonitoring = await api.getAdaptersWithTableMonitoring(sourceIds);
                const appType = getAppType(sourcesWithTableMonitoring);
                setAppType(appType);
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);
    const closeModal = () => {
        setIsVisible(false);
        destroyModal && destroyModal();
        setTableProgressVisible && setTableProgressVisible(false);
    };
    return (
        <Box sx={sxStyles.modalHeader}>
            <StriimModal
                footerBorder
                dialogProps={{
                    sx: { ...sxStyles.modalHeader }
                }}
                size="large"
                titleContent={<ModalHeader appStatus={appStatus} />}
                onConfirm={closeModal}
                confirmContent="Done"
                boxShadowVisible
                confirmButtonProps={{
                    variant: "primary"
                }}
                cancelContent={null}
                isVisible={isVisible}
                dialogContentProps={{ sx: { padding: "0px" } }}
            >
                <TableProgressContent
                    appStatus={appStatus}
                    appId={appModel?.id}
                    store={tableProgressStore}
                    appType={appType}
                    closeModal={closeModal}
                />
            </StriimModal>
        </Box>
    );
};

export default TableProgressModal;
