import React from "react";
import { Grid } from "@mui/material";
import { StriimButton, StriimTypography } from "@striim/striim-ui";

import StriimCircularProgress from "./circular-progress/circular-progress";

interface TestConnnectionInFormProps {
    isTestInProgress: boolean;
    onTestClick: () => void;
    testConnectionResponse: {
        result: boolean;
        message: string;
        hide?: boolean;
    }[];
    isTestButtonEnabled: boolean;
    isCurrentViewFD: boolean;
}

const TestConnnectionInForm = ({
    isTestInProgress,
    testConnectionResponse,
    onTestClick,
    isTestButtonEnabled,
    isCurrentViewFD
}: TestConnnectionInFormProps) => {
    const hasError = testConnectionResponse.length && testConnectionResponse.some(response => !response.result);
    const testCPProgress = isTestInProgress || !testConnectionResponse.length ? "" : !hasError ? "success" : "error";

    return (
        <Grid container item gap={1} ml={isCurrentViewFD ? 0 : 3}>
            <Grid item>
                <StriimButton variant="secondary" disabled={!isTestButtonEnabled} onClick={onTestClick}>
                    {!testConnectionResponse.length ? "Test" : "Retest"}
                </StriimButton>
            </Grid>
            {isTestInProgress || testConnectionResponse.length ? (
                <Grid item display={"flex"} alignItems={"center"} gap={1}>
                    <StriimCircularProgress size={24} status={testCPProgress} />
                    {testCPProgress === "success" && <StriimTypography variant="h4">Test Success</StriimTypography>}
                    {testCPProgress === "error" && <StriimTypography variant="h4">Test Failed</StriimTypography>}
                    {isTestInProgress && <StriimTypography variant="h4">Test in Progress</StriimTypography>}
                </Grid>
            ) : null}
        </Grid>
    );
};

export default TestConnnectionInForm;
