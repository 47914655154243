export const styles = {
    container: {
        marginBottom: 2
    },
    heading: {
        color: ({ palette }) => `${palette.primary[700]} !important`
    },
    backButton: {
        marginRight: 2
    },
    icon: {
        fontSize: "20px",
        margin: 1
    },

    actionButton: {
        fontSize: "14px",
        svg: {
            fontSize: "20px",
            marginRight: "8px",
            color: "#ECF2FA"
        }
    },
    actionIcon: {
        fontSize: "20px",
        svg: {
            fontSize: "20px",
            marginRight: "8px",
            color: "#ECF2FA"
        }
    },
    deleteButton: {
        color: "critical.500"
    },
    deleteIcon: {
        fontSize: "15px",
        color: "critical.500",
        svg: {
            fontSize: "20px",
            marginRight: "8px",
            color: "#ECF2FA"
        }
    }
};
