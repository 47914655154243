import React from "react";
import { Box, Divider, SvgIcon } from "@mui/material";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { components } from "react-select";
import { StriimTypography, StriimInputField } from "@striim/striim-ui";
import { SENTINEL_ACTIONS } from "../../sentinel/panel/sentinel-panel-consts";
import { MaskingOption } from "./identifier-actions-table";

interface ActionsDropdownProps {
    value?: MaskingOption;
    options: MaskingOption[];
    isEditable?: boolean;
    maskingString?: string;
    isSettingsPage?: boolean;
    onChange: (option: MaskingOption) => void;
    error?: boolean;
    helperText?: string;
    isEntitiesPage?: boolean;
}

const ACTION_DISPLAY_TEXT = {
    [SENTINEL_ACTIONS.MASK_COMPLETELY]: "Complete",
    [SENTINEL_ACTIONS.MASK_PARTIALLY]: "Partial",
    [SENTINEL_ACTIONS.ENCRYPT]: "Encrypt",
    [SENTINEL_ACTIONS.NO_ACTION]: "No Action"
};

const MASKING_PATTERNS = {
    [SENTINEL_ACTIONS.MASK_COMPLETELY]: "xxxxxxxxx",
    [SENTINEL_ACTIONS.MASK_PARTIALLY]: "xxxxx1234"
};

const MaskingPreview: React.FC<{
    action: string;
    maskingString?: string;
    isSelected?: boolean;
}> = ({ action, maskingString, isSelected }) => {
    if (action === SENTINEL_ACTIONS.CUSTOM_MASK && !maskingString) return null;
    if (!MASKING_PATTERNS[action] && !maskingString) return null;

    const preview = action === SENTINEL_ACTIONS.CUSTOM_MASK ? maskingString : MASKING_PATTERNS[action];

    return (
        <StriimTypography variant="caption3" color={isSelected ? "white" : "greyscale.700"}>
            {preview}
        </StriimTypography>
    );
};

const ActionsDropdown: React.FC<ActionsDropdownProps> = ({
    value,
    options,
    isEditable = true,
    maskingString,
    isSettingsPage = false,
    onChange,
    error = false,
    helperText = "",
    isEntitiesPage = false
}) => {
    if (!isEditable) {
        return (
            <StriimTypography variant="body4" color="greyscale.900">
                {value?.label}
            </StriimTypography>
        );
    }

    const displayValue =
        value?.value === SENTINEL_ACTIONS.MASK_M_N || value?.value === SENTINEL_ACTIONS.MASK_BY_REGEX
            ? { label: "Custom Mask", value: SENTINEL_ACTIONS.CUSTOM_MASK }
            : value;
    const filteredOptions = isEntitiesPage
        ? options.filter(option => option.value !== SENTINEL_ACTIONS.MASK_PARTIALLY)
        : options;

    return (
        <StriimInputField
            SelectProps={{
                options: filteredOptions,
                components: {
                    Option: componentProps => (
                        <>
                            <>
                                {componentProps?.data?.value === SENTINEL_ACTIONS.MASK_COMPLETELY && (
                                    <Box my={1} ml={1.5}>
                                        <StriimTypography variant="caption4" color="greyscale.700">
                                            MASK
                                        </StriimTypography>
                                    </Box>
                                )}
                                <components.Option {...componentProps}>
                                    <Box display="flex" flexDirection="column">
                                        {componentProps?.data?.value === SENTINEL_ACTIONS.CUSTOM_MASK ? (
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <StriimTypography
                                                    variant="body4"
                                                    color={componentProps.isSelected ? "white" : "greyscale.900"}
                                                >
                                                    Custom
                                                </StriimTypography>
                                                <SvgIcon
                                                    component={ArrowRightRoundedIcon}
                                                    sx={{
                                                        height: "8px",
                                                        width: "auto",
                                                        fill: "none",
                                                        "& > path": { stroke: "greyscale.800" }
                                                    }}
                                                />
                                            </Box>
                                        ) : (
                                            <StriimTypography
                                                variant="body4"
                                                color={componentProps.isSelected ? "white" : "greyscale.900"}
                                            >
                                                {ACTION_DISPLAY_TEXT[componentProps?.data?.value]}
                                            </StriimTypography>
                                        )}
                                        <MaskingPreview
                                            action={componentProps?.data?.value}
                                            maskingString={maskingString}
                                            isSelected={componentProps.isSelected}
                                        />
                                    </Box>
                                </components.Option>
                                {(componentProps?.data?.value === SENTINEL_ACTIONS.ENCRYPT ||
                                    (isEntitiesPage &&
                                        componentProps?.data?.value === SENTINEL_ACTIONS.CUSTOM_MASK)) && <Divider />}
                            </>
                        </>
                    )
                },
                menuShouldScrollIntoView: false,
                menuPosition: "absolute",
                menuPortalTarget: document.body,
                zIndex: 1502,
                isClearable: false,
                isSearchable: false
            }}
            onChange={onChange}
            value={displayValue?.value ? displayValue : undefined}
            select
            error={error}
            helperText={helperText}
            id="action-select"
        />
    );
};

export default ActionsDropdown;
