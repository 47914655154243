import React, { useContext, useMemo, useState } from "react";
import { StriimDataTable, StriimInputField, StriimToggleButton, StriimTypography } from "@striim/striim-ui";
import { Box, Grid, InputAdornment } from "@mui/material";
import { Search } from "@material-ui/icons";

import { SentinelMonitorContext } from "../sentinel-monitor-context";
import { getAppName } from "../service/sentinel-monitor-utils";
import navigateTo from "../../../../../navigate-to";

type Props = {};

const styles = {
    appLink: {
        cursor: "pointer",
        color: theme => theme.palette.secondary[500]
    }
};

const SentinelAppsTable = ({}: Props) => {
    const [filterState, setFilterState] = useState({
        search: null,
        withoutDiscovery: false,
        withoutTagging: false
    });

    const { SentinelAppTable } = useContext(SentinelMonitorContext);

    const AppLink = ({ name }) => {
        return (
            <StriimTypography
                sx={styles.appLink}
                variant="body4"
                onClick={() => navigateTo.AppSentinelLiveMonitor(name, null, true)}
            >
                {getAppName(name)}
            </StriimTypography>
        );
    };

    const CellItem = ({ value }) => {
        return (
            <StriimTypography variant="body4" color={"greyscale.900"}>
                {value}
            </StriimTypography>
        );
    };

    const exampleColumns = [
        {
            headerName: `App Name (${SentinelAppTable?.length ?? 0})`,
            field: "name",
            flex: 1,
            sortable: true,
            resizable: true,
            headerCheckboxSelection: false,
            cellRenderer: ({ value }) => <AppLink name={value} />
        },
        {
            headerName: "Namespace",
            field: "namespace",
            flex: 1,
            sortable: true,
            resizable: true,
            cellRenderer: ({ value }) => <CellItem value={value} />
        },
        {
            headerName: "Sentinels",
            field: "sentinels",
            sortable: true,
            resizable: true,
            cellRenderer: ({ value }) => <CellItem value={value ?? 0} />,
            cellClass: "ag-right-aligned-cell"
        },
        {
            headerName: "Sentinels w/ Discovery",
            field: "discovery",
            sortable: true,
            resizable: true,
            cellRenderer: ({ value }) => <CellItem value={value ?? 0} />,
            cellClass: "ag-right-aligned-cell"
        },
        {
            headerName: "Sentinels w/ Tagging",
            field: "tagging",
            sortable: true,
            resizable: true,
            cellRenderer: ({ value }) => <CellItem value={value ?? 0} />,
            cellClass: "ag-right-aligned-cell"
        }
    ];

    const updateFilterState = (key: string, value: string | boolean) => {
        setFilterState(filterState => ({ ...filterState, [key]: value }));
    };

    const searchSubstring = (mainString: string, subString: string) =>
        mainString.toLowerCase().includes(subString.toLowerCase());

    const filteredData = useMemo(() => {
        return SentinelAppTable.filter(item => {
            const { search, withoutDiscovery, withoutTagging } = filterState;
            return (
                (!search || searchSubstring(item.name, search) || searchSubstring(item.namespace, search)) &&
                (!withoutDiscovery || !item.discovery) &&
                (!withoutTagging || !item.tagging)
            );
        });
    }, [filterState, SentinelAppTable]);

    return (
        <Grid p={3}>
            <Box mb={2}>
                <StriimTypography variant="h3" mb={2}>
                    Running Apps with Sentinels
                </StriimTypography>
            </Box>
            <Grid container justifyContent={"space-between"} mb={2}>
                <Box sx={{ width: "365px" }}>
                    <StriimInputField
                        placeholder="Search for an App or namespace"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search opacity={0.45} />
                                </InputAdornment>
                            )
                        }}
                        value={filterState.search}
                        onChange={e => updateFilterState("search", e)}
                    />
                </Box>
                <Grid sx={{ display: "flex" }} alignItems={"center"} gap={2}>
                    <Box>
                        <StriimTypography variant="caption3" mr={1}>
                            W/O Discovery
                        </StriimTypography>
                        <StriimToggleButton
                            checked={filterState.withoutDiscovery}
                            onClick={() => updateFilterState("withoutDiscovery", !filterState.withoutDiscovery)}
                        />
                    </Box>
                    <Box>
                        <StriimTypography variant="caption3" mr={1}>
                            W/O Tagging
                        </StriimTypography>
                        <StriimToggleButton
                            checked={filterState.withoutTagging}
                            onClick={() => updateFilterState("withoutTagging", !filterState.withoutTagging)}
                        />
                    </Box>
                </Grid>
            </Grid>
            <StriimDataTable data={filteredData} columns={exampleColumns} maxHeight={600} />
        </Grid>
    );
};

export default SentinelAppsTable;
