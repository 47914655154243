import _ from "underscore";
import TextField from "./text-field";
import browserCheck from "core/utils/browser-check";

var View = TextField.View.extend({
    events: {
        "change input": "setValueFromView",
    },

    changeOnEnter: function (evt) {
        // IE doesn't trigger change event for input on Enter
        // we have to trigger setValueFromView manually
        if (evt.keyCode === 13) {
            console.log("enter");
            this.setValueFromView();
            // IE lost focus on enter
            this.$el.find("input").focus();
        }
    },
});

if (browserCheck.isIE11()) {
    console.log("IE initialized");
    View.prototype.events["keyup input"] = "changeOnEnter";
}

function buildTextField() {
    return _.extend({}, TextField, {
        View: View,
        WithPlaceholder: function (placeholder) {
            return buildTextField().extend({
                placeholder: placeholder,
            });
        },
    });
}

export default buildTextField();
