import utils from "core/utils";
import TimeParser from "app/components/common/editor/control/interval/time-parser";
import converter from "core/services/metaStoreService/metaobject-converter";
import statusManagement from "src/status-management";
import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import wactionStorePersistenceTypes from "../control/wactionstore-persistence/wactionstore-persistence-types.json";
import { loadAscendantsDbSources } from "app/components/flow/designer/component-acendants-loader";
import appStatusSynchronizer from "core/appStatusSynchronizer";
import lastConnectionTestRepository, {
    connectionStatuses,
    getStatusDisplayName
} from "app/components/common/editor/control/connection-text-field/last-connection-test-repository";
import { getDatabaseType } from "app/components/common/editor/control/connection-text-field/connection-test-view";

var metaObjectHelper = {};

export const resolveSourceValidationDscription = validationResultStatus => {
    const title = getStatusDisplayName(validationResultStatus);
    const statusClass = validationResultStatus === connectionStatuses.ok ? "validation-success" : "validation-failed";
    const statusIconClass = validationResultStatus === connectionStatuses.ok ? "icon icon-check" : "status-icon-failed";
    const titleHtml = `<span class="validation-status ${statusClass}"><span class="status-text">${title}</span><span class="status-icon ${statusIconClass}"></span></span>`;
    return titleHtml;
};

metaObjectHelper.descriptionResolver = function(metaObject, applicationId) {
    var nodeType = metaObject.type.toUpperCase();

    return new Promise(resolve => {
        // Event Table: Input stream, Delete stream
        if (nodeType === utils.ENTITIES.CACHE && metaObject.isEventTable === true) {
            var result = [];
            if (metaObject.adapter && metaObject.adapter.properties && metaObject.adapter.properties.name) {
                result.push("Input: " + converter.getName(metaObject.adapter.properties.name));
            }
            if (metaObject.parser && metaObject.parser.properties && metaObject.parser.properties.name) {
                result.push("Delete: " + converter.getNameWithoutVersion(metaObject.parser.properties.name));
            }
            resolve(result.join(", "));
            return;
        }

        // Source: only when state is created
        if (nodeType === utils.ENTITIES.SOURCE && metaObject.adapter) {
            const handlerName = converter.getName(metaObject.adapter.handler);
            if (getDatabaseType(handlerName)) {
                const status = appStatusSynchronizer.getStatus(applicationId);
                if (statusManagement.isApplicationCreated(status)) {
                    if (metaObject.adapter && metaObject.adapter.handler) {
                        const result = lastConnectionTestRepository.load(metaObject.id, metaObject.adapter.handler);
                        if (!result) {
                            resolve("Not validated");
                            return;
                        }
                        const titleHtml = resolveSourceValidationDscription(result.status);
                        resolve({ title: titleHtml, description: result.message });
                        return;
                    } else {
                        resolve("Not validated");
                        return;
                    }
                }
            }
        }

        // Source: Reader, Parser
        // Cache: Reader, Parser
        if (nodeType === utils.ENTITIES.SOURCE || nodeType === utils.ENTITIES.CACHE) {
            var result = [];
            if (metaObject.adapter && metaObject.adapter.handler) {
                result.push(converter.getNameWithoutVersion(metaObject.adapter.handler));
            }
            if (metaObject.parser && metaObject.parser.handler) {
                result.push(converter.getNameWithoutVersion(metaObject.parser.handler));
            }
            resolve(result.join(", "));
            return;
        }

        //Window
        // Sliding:
        // <time or count> Sliding
        // Example “5-minute Sliding” or “1000 Events Sliding”

        // Jumping
        // <time or count> Jumping
        // Example “5-minute Jumping” or “1000 Events Jumping”

        // Partition
        if (nodeType === utils.ENTITIES.WINDOW) {
            var windowDescription = "";

            if (metaObject.size && metaObject.size.count && metaObject.size.count !== -1) {
                windowDescription = metaObject.size.count + " Events ";
            } else if (metaObject.size && metaObject.size.time) {
                windowDescription = TimeParser.toString(metaObject.size.time) + " ";
            }

            if (typeof metaObject.mode !== "undefined") {
                windowDescription += metaObject.mode;
            }

            if (metaObject.partitioningFields && metaObject.partitioningFields.length > 0) {
                windowDescription += ", " + metaObject.partitioningFields.join(", ");
            }
            resolve(windowDescription);
            return;
        }

        //CQ
        // Specify if any exists in the query: Group, Order, Sort, Limit
        if (nodeType === utils.ENTITIES.CQ) {
            if (metaObject.uiconfig && metaObject.uiconfig.subType) {
                resolve(metaObject.uiconfig.subType);
                return;
            }

            if (metaObject.output) {
                resolve("List all fields in " + converter.getNameWithoutVersion(metaObject.output));
                return;
            }

            resolve("");
            return;
        }

        // StriimStore
        // Type
        // Persistence: In-Memory(or DB type ie. “ElasticSearch”)
        if (nodeType === utils.ENTITIES.WACTIONSTORE) {
            var storeDescription = [];
            if (metaObject.contextType) {
                storeDescription.push(converter.getNameWithoutVersion(metaObject.contextType));
            }

            if (
                metaObject.persistence &&
                metaObject.persistence.properties &&
                metaObject.persistence.properties.storageProvider
            ) {
                var storageProvider = metaObject.persistence.properties.storageProvider;
                var selectedPersistence = wactionStorePersistenceTypes.filter(function(el) {
                    return el.id === storageProvider;
                });
                if (selectedPersistence && selectedPersistence.length > 0) {
                    selectedPersistence = selectedPersistence[0];
                    storeDescription.push(selectedPersistence.text);
                } else {
                    storeDescription.push(storageProvider);
                }
            } else {
                storeDescription.push("Elasticsearch");
            }
            resolve(storeDescription.join(", "));
            return;
        }

        //Target
        if (nodeType === utils.ENTITIES.TARGET) {
            var targetResult = [];
            if (metaObject.adapter && metaObject.adapter.handler) {
                targetResult.push(converter.getNameWithoutVersion(metaObject.adapter.handler));
            }
            if (metaObject.formatter && metaObject.formatter.handler) {
                targetResult.push(converter.getNameWithoutVersion(metaObject.formatter.handler));
            }
            if (metaObject.adapter && metaObject.adapter.properties) {
                var databaseProviderType = metaObject.adapter.properties.DatabaseProviderType;
                if (databaseProviderType && databaseProviderType.toLowerCase() !== "default") {
                    targetResult.push(databaseProviderType);
                }
            }
            resolve(targetResult.join(", "));
            return;
        }

        // Process component
        if (nodeType === utils.ENTITIES.OPENPROCESSOR) {
            var pcDescription = [];
            if (metaObject.adapter && metaObject.adapter.handler) {
                pcDescription.push(converter.getNameWithoutVersion(metaObject.adapter.handler));
            }
            resolve(pcDescription.join(", "));
            return;
        }

        if (nodeType === utils.ENTITIES.EXTERNALCACHE) {
            if (
                metaObject.externalCacheProperties &&
                metaObject.externalCacheProperties.properties &&
                metaObject.externalCacheProperties.properties.AdapterName
            ) {
                resolve(converter.getNameWithoutVersion(metaObject.externalCacheProperties.properties.AdapterName));
                return;
            }
        }

        if (nodeType === utils.ENTITIES.ROUTER) {
            if (metaObject.forwardingRules) {
                resolve(metaObject.forwardingRules.length + " forwarding rules");
                return;
            } else {
                resolve("");
            }
        }
        resolve(nodeType);
    });
};

metaObjectHelper.getIconClassByMetaObject = function(metaObject, getObject = false) {
    if (!metaObject?.type) {
        return "";
    }

    const type = metaObject.type.toLowerCase();
    var defaultClass = type;
    var defaultObject = { name: type, className: type };

    function getDBProviderType() {
        if (metaObject?.["database-provider-type"]) {
            return metaObject["database-provider-type"];
        }
        if (!metaObject.adapter || !metaObject.adapter.properties) {
            return null;
        }
        return metaObject.adapter.properties.DatabaseProviderType;
    }

    function checkAdapterByHandler(name) {
        return (
            (metaObject?.adapter?.handler && converter.getNameWithoutVersion(metaObject.adapter.handler) === name) ||
            metaObject["adapter-name"] === name
        );
    }

    function checkParser(name) {
        return metaObject?.parser?.handler?.includes(name);
    }

    function getObjectOrIcon(component) {
        return getObject ? component : component.className;
    }

    if (metaObject.type === utils.ENTITIES.SOURCE) {
        // handlers
        if (checkAdapterByHandler("DatabaseReader")) {
            var dbReader = getDBProviderType();

            switch (dbReader) {
                case "MySQL":
                    return getObjectOrIcon(PredefinedComponentNames.MySQLDB);
                case "MariaDB":
                    return getObjectOrIcon(PredefinedComponentNames.MariaDB);
                case "Oracle":
                    return getObjectOrIcon(PredefinedComponentNames.OracleDB);
                case "Postgres":
                    return getObjectOrIcon(PredefinedComponentNames.PostgreSQLDB);
                case "SQLServer":
                    return getObjectOrIcon(PredefinedComponentNames.MSSQLDB);
                case "MariaDBXpand":
                    return getObjectOrIcon(PredefinedComponentNames.MariaDBXpandDB);
                case "Snowflake":
                    return getObjectOrIcon(PredefinedComponentNames.SnowflakeILReader);
                case "BigQuery":
                    return getObjectOrIcon(PredefinedComponentNames.BigQueryILReader);
                default:
                    return getObjectOrIcon(PredefinedComponentNames.DatabaseSource);
            }
        }
        if (checkAdapterByHandler("HTTPReader")) {
            return getObjectOrIcon(PredefinedComponentNames.Http);
        }
        if (checkAdapterByHandler("JMSReader")) {
            return getObjectOrIcon(PredefinedComponentNames.JMS);
        }
        if (checkAdapterByHandler("KafkaReader")) {
            return getObjectOrIcon(PredefinedComponentNames.Kafka);
        }
        if (checkAdapterByHandler("HDFSReader")) {
            return getObjectOrIcon(PredefinedComponentNames.HDFS);
        }
        if (checkAdapterByHandler("MQTTReader")) {
            return getObjectOrIcon(PredefinedComponentNames.MQTT);
        }
        if (checkAdapterByHandler("TCPReader")) {
            return getObjectOrIcon(PredefinedComponentNames.TCP);
        }
        if (checkAdapterByHandler("MapRFSReader")) {
            return getObjectOrIcon(PredefinedComponentNames.MapRFSReader);
        }
        // UDP
        if (checkAdapterByHandler("UDPReader")) {
            if (checkParser("CollectdParser")) {
                return getObjectOrIcon(PredefinedComponentNames.CollectD);
            }
            if (checkParser("NetflowParser")) {
                return getObjectOrIcon(PredefinedComponentNames.NetFlow);
            }
            if (checkParser("SNMPParser")) {
                return getObjectOrIcon(PredefinedComponentNames.SNMP);
            }
            return getObjectOrIcon(PredefinedComponentNames.UDP);
        }
        if (checkAdapterByHandler("SalesForceReader")) {
            return getObjectOrIcon(PredefinedComponentNames.Salesforce);
        }
        if (checkAdapterByHandler("OracleReader")) {
            return getObjectOrIcon(PredefinedComponentNames.OracleCDC);
        }
        if (checkAdapterByHandler("OJet")) {
            return getObjectOrIcon(PredefinedComponentNames.OJet);
        }
        if (checkAdapterByHandler("MSSqlReader")) {
            return getObjectOrIcon(PredefinedComponentNames.MSSQLCDC);
        }
        if (checkAdapterByHandler("CosmosDBReader")) {
            return getObjectOrIcon(PredefinedComponentNames.CosmosDBReader);
        }
        if (checkAdapterByHandler("MongoCosmosDBReader")) {
            return getObjectOrIcon(PredefinedComponentNames.MongoCosmosDBReader);
        }
        if (checkAdapterByHandler("MSJet")) {
            return getObjectOrIcon(PredefinedComponentNames.MSJet);
        }
        if (checkAdapterByHandler("MysqlReader")) {
            return getObjectOrIcon(PredefinedComponentNames.MySQLCDC);
        }
        if (checkAdapterByHandler("PostgreSQLReader")) {
            return getObjectOrIcon(PredefinedComponentNames.PostgreSQLReader);
        }
        if (checkAdapterByHandler("MongoDBReader")) {
            return getObjectOrIcon(PredefinedComponentNames.MongoDBReader);
        }
        if (checkAdapterByHandler("HPNonStopEnscribeReader")) {
            return getObjectOrIcon(PredefinedComponentNames.HPNonStopCDC);
        }
        if (checkAdapterByHandler("HPNonStopSQLMPReader")) {
            return getObjectOrIcon(PredefinedComponentNames.HPNonStopSQLMP);
        }
        if (checkAdapterByHandler("HPNonStopSQLMXReader")) {
            return getObjectOrIcon(PredefinedComponentNames.HPNonStopSQLMX);
        }
        if (checkAdapterByHandler("GGTrailParser")) {
            return getObjectOrIcon(PredefinedComponentNames.GoldenGate);
        }
        if (checkAdapterByHandler("GGTrailReader")) {
            return getObjectOrIcon(PredefinedComponentNames.GGTrail);
        }
        if (checkAdapterByHandler("OPCUAReader")) {
            return getObjectOrIcon(PredefinedComponentNames.OPCUAReader);
        }
        if (checkAdapterByHandler("IncrementalBatchReader")) {
            return getObjectOrIcon(PredefinedComponentNames.IncrementalBatchReader);
        }
        if (checkAdapterByHandler("SpannerBatchReader")) {
            return getObjectOrIcon(PredefinedComponentNames.SpannerBatchReader);
        }
        if (checkAdapterByHandler("S3Reader")) {
            return getObjectOrIcon(PredefinedComponentNames.S3Reader);
        }
        if (checkAdapterByHandler("JMXReader")) {
            return getObjectOrIcon(PredefinedComponentNames.JMXReader);
        }
        if (checkAdapterByHandler("MariaDBReader")) {
            return getObjectOrIcon(PredefinedComponentNames.MariaDBReader);
        }
        if (checkAdapterByHandler("MariaDBXpandReader")) {
            return getObjectOrIcon(PredefinedComponentNames.MariaDBXpandReader);
        }
        if (checkAdapterByHandler("GCSReader")) {
            return getObjectOrIcon(PredefinedComponentNames.GCSReader);
        }
        if (checkAdapterByHandler("SalesForcePushTopicReader")) {
            return getObjectOrIcon(PredefinedComponentNames.SalesforcePushTopic);
        }
        if (checkAdapterByHandler("ADLSReader")) {
            return getObjectOrIcon(PredefinedComponentNames.ADLSReader);
        }
        // parsers
        if (checkParser("GGTrailParser")) {
            return getObjectOrIcon(PredefinedComponentNames.GoldenGate);
        }
        if (checkParser("XMLParser")) {
            return getObjectOrIcon(PredefinedComponentNames.XML);
        }
        if (checkParser("JSONParser")) {
            return getObjectOrIcon(PredefinedComponentNames.JSON);
        }
        if (checkParser("AvroParser") && checkAdapterByHandler("FileReader")) {
            return getObjectOrIcon(PredefinedComponentNames.Avro);
        }
        if (checkParser("AALParser")) {
            return getObjectOrIcon(PredefinedComponentNames.ApacheAccessLog);
        }
        if (checkParser("NVPParser")) {
            return getObjectOrIcon(PredefinedComponentNames.NameValuePair);
        }
        if (checkParser("FreeFormTextParser")) {
            return getObjectOrIcon(PredefinedComponentNames.FreeFormText);
        }
        if (checkParser("BinaryParser")) {
            return getObjectOrIcon(PredefinedComponentNames.Binary);
        }
        if (checkParser("CobolCopybookParser")) {
            return getObjectOrIcon(PredefinedComponentNames.CobolCopybookParser);
        }
        if (checkParser("DSVParser")) {
            return getObjectOrIcon(PredefinedComponentNames.DelimiterSeparatedValues);
        }
        if (checkAdapterByHandler("FileReader")) {
            return getObjectOrIcon(PredefinedComponentNames.File);
        }
        if (checkAdapterByHandler("MultiFileReader")) {
            return getObjectOrIcon(PredefinedComponentNames.MultiFileReader);
        }
        if (checkAdapterByHandler("WindowsEventLogReader")) {
            return getObjectOrIcon(PredefinedComponentNames.WindowsEventLog);
        }
        if (checkAdapterByHandler("DatabaseReader")) {
            return getObjectOrIcon(PredefinedComponentNames.DatabaseSource);
        }

        if (checkAdapterByHandler("ServiceNowReader")) {
            return getObjectOrIcon(PredefinedComponentNames.ServiceNowReader);
        }
        if (checkAdapterByHandler("SalesforcePardotReader")) {
            return getObjectOrIcon(PredefinedComponentNames.SalesforcePardotReader);
        }
        if (checkAdapterByHandler("HubSpotReader")) {
            return getObjectOrIcon(PredefinedComponentNames.HubSpotReader);
        }
        if (checkAdapterByHandler("SalesforceCDCReader")) {
            return getObjectOrIcon(PredefinedComponentNames.SalesforceCDCReader);
        }
        if (checkAdapterByHandler("SalesForcePlatformEventReader")) {
            return getObjectOrIcon(PredefinedComponentNames.SalesforcePlatformEvent);
        }
        if (checkAdapterByHandler("SnowflakeReader")) {
            return PredefinedComponentNames.SnowflakeCDCReader.className;
        }
        if (checkAdapterByHandler("GoogleAdsReader")) {
            return getObjectOrIcon(PredefinedComponentNames.GoogleAdsReader);
        }
        //DO-NOT-REMOVE-BELOW-COMMENT
        //add-new-adapter
        if (checkAdapterByHandler("BambooHrReader")) {
            return getObjectOrIcon(PredefinedComponentNames.BambooHrReader);
        }
        if (checkAdapterByHandler("YugabyteReader")) {
            return PredefinedComponentNames.YugabyteCDC.className;
        }
        if (checkAdapterByHandler("YugabyteDBReader")) {
            return PredefinedComponentNames.YugabyteDBReader.className;
        }
        if (checkAdapterByHandler("JiraReader")) {
            return getObjectOrIcon(PredefinedComponentNames.JiraReader);
        }
        if (checkAdapterByHandler("LinkedInAdsReader")) {
            return getObjectOrIcon(PredefinedComponentNames.LinkedInAdsReader);
        }
        if (checkAdapterByHandler("StripeReader")) {
            return getObjectOrIcon(PredefinedComponentNames.StripeReader);
        }
        if (checkAdapterByHandler("ZendeskReader")) {
            return getObjectOrIcon(PredefinedComponentNames.ZendeskReader);
        }
        if (checkAdapterByHandler("IntercomReader")) {
            return getObjectOrIcon(PredefinedComponentNames.IntercomReader);
        }
        if (checkAdapterByHandler("GoogleSheetsReader")) {
            return getObjectOrIcon(PredefinedComponentNames.GoogleSheetsReader);
        }
        if (checkAdapterByHandler("GoogleAnalyticsReader")) {
            return getObjectOrIcon(PredefinedComponentNames.GoogleAnalyticsReader);
        }
        if (checkAdapterByHandler("PaymoReader")) {
            return getObjectOrIcon(PredefinedComponentNames.PaymoReader);
        }
        return getObjectOrIcon(defaultObject);
    }

    if (metaObject.type === utils.ENTITIES.CQ && metaObject.uiconfig) {
        if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationMasking.name) {
            return PredefinedComponentNames.TransformationMasking.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationFieldRemover.name) {
            return PredefinedComponentNames.TransformationFieldRemover.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationFieldRenamer.name) {
            return PredefinedComponentNames.TransformationFieldRenamer.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationFieldTypeModifier.name) {
            return PredefinedComponentNames.TransformationFieldTypeModifier.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationFieldFilter.name) {
            return PredefinedComponentNames.TransformationFieldFilter.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationFieldSplitter.name) {
            return PredefinedComponentNames.TransformationFieldSplitter.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationFieldEnricher.name) {
            return PredefinedComponentNames.TransformationFieldEnricher.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationJsonReader.name) {
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationJsonReader.name) {
            return PredefinedComponentNames.TransformationJsonReader.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationTypedEventToWaDbEvent.name) {
            return PredefinedComponentNames.TransformationTypedEventToWaDbEvent.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationFieldAdder.name) {
            return PredefinedComponentNames.TransformationFieldAdder.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationWaeMetaFilter.name) {
            return PredefinedComponentNames.TransformationWaeMetaFilter.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationWaeDbTableFilter.name) {
            return PredefinedComponentNames.TransformationWaeDbTableFilter.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationWaeOperatorFilter.name) {
            return PredefinedComponentNames.TransformationWaeOperatorFilter.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationWaeAddUserData.name) {
            return PredefinedComponentNames.TransformationWaeAddUserData.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationWaeToTypedEvent.name) {
            return PredefinedComponentNames.TransformationWaeToTypedEvent.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationWaeToDwStage.name) {
            return PredefinedComponentNames.TransformationWaeToDwStage.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationWaeDataModifier.name) {
            return PredefinedComponentNames.TransformationWaeDataModifier.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationShield.name) {
            return PredefinedComponentNames.TransformationShield.className;
        } else if (metaObject.uiconfig.subType === PredefinedComponentNames.TransformationSentinel.name) {
            return PredefinedComponentNames.TransformationSentinel.className;
        }
    }

    if (metaObject.type === utils.ENTITIES.CACHE) {
        if (checkAdapterByHandler("FileReader")) {
            return PredefinedComponentNames.FileCache.className;
        } else if (checkAdapterByHandler("DatabaseReader")) {
            return PredefinedComponentNames.DBCache.className;
        } else if (checkAdapterByHandler("HDFSReader")) {
            return PredefinedComponentNames.HDFSCache.className;
        } else if (metaObject.isEventTable === true) {
            return PredefinedComponentNames.BaseEventTable.className;
        }

        return defaultClass;
    }

    if (metaObject.type === utils.ENTITIES.TARGET) {
        if (checkAdapterByHandler("DatabaseWriter")) {
            var dbWriter = getDBProviderType();
            switch (dbWriter) {
                case "Oracle":
                    return getObjectOrIcon(PredefinedComponentNames.OutputOracleDatabase);
                case "SQLServer":
                    return getObjectOrIcon(PredefinedComponentNames.OutputSQLServerDatabase);
                case "MySQL":
                    return getObjectOrIcon(PredefinedComponentNames.OutputMySQLDatabase);
                case "MemSQL":
                    return getObjectOrIcon(PredefinedComponentNames.OutputMemSQLDatabase);
                case "Cassandra":
                    return getObjectOrIcon(PredefinedComponentNames.CassandraWriter);
                case "SQLMX":
                    return getObjectOrIcon(PredefinedComponentNames.OutputHPSQLMXDatabase);
                case "SAPHANA":
                    return getObjectOrIcon(PredefinedComponentNames.SAPHANAWriter);
                case "MariaDB":
                    return getObjectOrIcon(PredefinedComponentNames.MariaDBDatabase);
                case "Postgres":
                    return getObjectOrIcon(PredefinedComponentNames.OutputPostgresDatabase);
                case "AzurePostgres":
                    return getObjectOrIcon(PredefinedComponentNames.AzurePostgreSqlWriter);
                case "CloudSqlPostgres":
                    return getObjectOrIcon(PredefinedComponentNames.CloudSqlPostgreSqlWriter);
                case "CloudSqlMySQL":
                    return getObjectOrIcon(PredefinedComponentNames.CloudSqlMySqlWriter);
                case "CloudSqlSQLServer":
                    return getObjectOrIcon(PredefinedComponentNames.CloudSqlSQLServerWriter);
                case "Yellowbrick":
                    return getObjectOrIcon(PredefinedComponentNames.Yellowbrick);
                case "Db2Zos":
                    return getObjectOrIcon(PredefinedComponentNames.Db2Zos);
            }
            return getObjectOrIcon(PredefinedComponentNames.Database);
        } else if (checkAdapterByHandler("HBaseWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.HBase);
        } else if (checkAdapterByHandler("HDFSWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.OutputHDFS);
        } else if (checkAdapterByHandler("GCSWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.GCSWriter);
        } else if (checkAdapterByHandler("ClouderaHiveWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.ClouderaHiveWriter);
        } else if (checkAdapterByHandler("HortonworksHiveWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.HortonWorksHiveWriter);
        } else if (checkAdapterByHandler("JMSWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.OutputJMS);
        } else if (checkAdapterByHandler("SpannerWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.SpannerWriter);
        } else if (checkAdapterByHandler("KafkaWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.OutputKafka);
        } else if (checkAdapterByHandler("HTTPWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.HTTPWriter);
        } else if (checkAdapterByHandler("FileWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.OutputFile);
        } else if (checkAdapterByHandler("MQTTWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.OutputMQTT);
        } else if (checkAdapterByHandler("S3Writer")) {
            return getObjectOrIcon(PredefinedComponentNames.S3);
        } else if (checkAdapterByHandler("RedshiftWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.RedSh);
        } else if (checkAdapterByHandler("SysOut")) {
            return getObjectOrIcon(PredefinedComponentNames.SysOut);
        } else if (checkAdapterByHandler("ClickatellSMSAdapter")) {
            return getObjectOrIcon(PredefinedComponentNames.SMS);
        } else if (checkAdapterByHandler("EmailAdapter")) {
            return getObjectOrIcon(PredefinedComponentNames.Email);
        } else if (checkAdapterByHandler("WebAlertAdapter")) {
            return getObjectOrIcon(PredefinedComponentNames.WebAlertUI);
        } else if (checkAdapterByHandler("BigQueryWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.BigQuery);
        } else if (checkAdapterByHandler("MapRDBWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.MapRDBWriter);
        } else if (checkAdapterByHandler("MapRStreamWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.MapRStreamWriter);
        } else if (checkAdapterByHandler("HazelcastWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.HazelcastWriter);
        } else if (checkAdapterByHandler("AzureBlobWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.AzureBlobWriter);
        } else if (checkAdapterByHandler("AzureEventHubWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.AzureEventHubWriter);
        } else if (checkAdapterByHandler("KuduWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.KuduWriter);
        } else if (checkAdapterByHandler("JPAWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.JPAWriter);
        } else if (checkAdapterByHandler("AzureSQLDWHWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.AzureSQLDWHWriter);
        } else if (checkAdapterByHandler("HiveWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.HiveWriter);
        } else if (checkAdapterByHandler("SnowflakeWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.SnowflakeWriter);
        } else if (checkAdapterByHandler("DeltaLakeWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.DeltaLakeWriter);
        } else if (checkAdapterByHandler("KinesisWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.KinesisWriter);
        } else if (checkAdapterByHandler("CosmosDBWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.CosmosDBWriter);
        } else if (checkAdapterByHandler("ADLSGen2Writer")) {
            return getObjectOrIcon(PredefinedComponentNames.AzureDataLakeStoreGen2);
        } else if (checkAdapterByHandler("AzureDataLakeStoreGen2")) {
            return getObjectOrIcon(PredefinedComponentNames.AzureDataLakeStoreGen2);
        } else if (checkAdapterByHandler("GooglePubSubWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.GooglePubSubWriter);
        } else if (checkAdapterByHandler("CassandraCosmosDBWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.CassandraCosmosDBWriter);
        } else if (checkAdapterByHandler("CassandraWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.CassandraWriter);
        } else if (checkAdapterByHandler("MongoCosmosDBWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.MongoCosmosDBWriter);
        } else if (checkAdapterByHandler("MongoDBWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.MongoDbWriter);
        } else if (checkAdapterByHandler("SlackAlertAdapter")) {
            return getObjectOrIcon(PredefinedComponentNames.Slack);
        } else if (checkAdapterByHandler("SalesforceWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.SalesforceWriter);
        } else if (checkAdapterByHandler("TeamsAlertAdapter")) {
            return getObjectOrIcon(PredefinedComponentNames.TeamsAlertAdapter);
        } else if (checkAdapterByHandler("MapRFSWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.MapRFSWriter);
        } else if (checkAdapterByHandler("ServiceNowWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.ServiceNowWriter);
        } else if (checkAdapterByHandler("HDInsightKafkaWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.HDInsightKafkaWriter);
        } else if (checkAdapterByHandler("HDInsightHDFSWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.HDInsightHDFSWriter);
        } else if (checkAdapterByHandler("FabricDataWarehouseWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.FabricDataWarehouseWriter);
        } else if (checkAdapterByHandler("FabricLakehouseFileWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.FabricLakehouseFileWriter);
        } else if (checkAdapterByHandler("SalesforceMarketingCloudWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.SalesforceMarketingCloudWriter);
        } else if (checkAdapterByHandler("MicrosoftDataverseWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.MicrosoftDataverseWriter);
        } else if (checkAdapterByHandler("FabricMirrorWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.FabricMirrorWriter);
        }else if (checkAdapterByHandler("SpannerPGDialectWriter")) {
            return getObjectOrIcon(PredefinedComponentNames.SpannerPGDialectWriter);
        }

        return getObjectOrIcon(defaultObject);
    }

    if (metaObject.type === "WACTIONSTORE") {
        return "striim";
    }

    if (metaObject.type === "STREAM") {
        return "stream";
    }

    if (metaObject.type === utils.ENTITIES.OPENPROCESSOR) {
        return PredefinedComponentNames.BaseProcessComponent.className;
    }

    return defaultClass;
};

metaObjectHelper.isWAEventDataType = function(dataType) {
    return dataType === "Global.TYPE.WAEvent";
};

metaObjectHelper.hasValidDbSource = function(streamId, forceRefresh) {
    let key = "isdb_" + streamId;

    return new Promise(resolve => {
        if (forceRefresh) {
            localStorage.removeItem(key);
        }

        let result = localStorage.getItem(key);
        if (result != null) {
            resolve(result === "true");
            return;
        }

        loadAscendantsDbSources(streamId).then(result => {
            var resultFromPromise = result.length === 1;
            localStorage.setItem(key, resultFromPromise);
            resolve(resultFromPromise);
        });
    });
};

export default metaObjectHelper;
