import React from "react";
import PropTypes from "prop-types";
import { StriimButton, StriimTypography, StriimIconWrapperBase } from "@striim/striim-ui";
import { Grid, Box, Card } from "@material-ui/core";
import { icons } from "src/generic/icon/flow-designer-icon";
import App from "app";
import { ChevronRightRounded } from "@mui/icons-material";

const TemplateCard = ({ title, source, target, url }) => {
    const isILCDC = url.indexOf("automated-ILCDC") !== -1;
    return (
        <>
            <Box border={0} borderColor="silver">
                <Card style={{ padding: "10px" }} elevation={0}>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={3}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <div style={{ fontSize: 30, marginLeft: 10, marginRight: 10 }}>
                                        <StriimIconWrapperBase Icon={icons[source]} type="source" />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <ChevronRightRounded sx={{ color: "greyscale.600", height: "14px" }} />
                                </Grid>
                                <Grid item>
                                    <div style={{ fontSize: 30, marginLeft: 10, marginRight: 10 }}>
                                        <StriimIconWrapperBase Icon={icons[target]} type="target" />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <StriimTypography variant="body4" color="greyscale.700">
                                {isILCDC
                                    ? `Realtime Streaming Integration of ${title.replace(" to ", " → ")}`
                                    : `Migrate your data from ${title}`}
                            </StriimTypography>
                        </Grid>
                        <Grid item xs>
                            <Grid container direction="row-reverse">
                                <Grid item>
                                    <StriimButton
                                        data-test-id={title}
                                        variant="secondary"
                                        onClick={() => {
                                            App.navigate("#" + url, {
                                                trigger: true
                                            });
                                        }}
                                    >
                                        Create
                                    </StriimButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </>
    );
};

export default TemplateCard;

TemplateCard.propTypes = {
    title: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};
