import React, { SetStateAction, useCallback, useRef, useState } from "react";
import { LegendData, SensitiveDataOccurrencesChartData } from "../../service/sentinel-monitor.types";
import SensitiveDataOccurrencesChart from "../../../../sentinel-live-monitor/components/timeline-view-chart/sensitive-data-occurrences-chart";
import _ from "underscore";
import { SelectedSensitiveDataIdentifier, TABS } from "../../../../sentinel-live-monitor/sentinel-live-monitor";
import { getTimestampsAsOneHourWindow } from "../../../../sentinel-live-monitor/utils";

interface SensitiveDataChartProps {
    sensitiveData: SensitiveDataOccurrencesChartData;
    sdiOccurrences: SensitiveDataOccurrencesChartData;
    chartType: string;
    selectedSDI: SelectedSensitiveDataIdentifier | null;
    selectedDateRange: { from: number; to: number } | null;
    setSelectedDateRange: React.Dispatch<SetStateAction<{ from: number; to: number } | null>>;
    onLiveViewClick: Function;
    legendData: LegendData;
}

export const getSensitiveDataTab = data => {
    return {
        label: "Occurrences of Sensitive Data",
        sdiLabel: "Occurrences of",
        subLabel: `${data?.sensitiveIdentifiers || 0} IDENTIFIERS`,
        amount: data?.occurrencesOfSensitiveData,
        data: [
            { field: "encrypted", value: data?.sensitiveDataActions?.ENCRYPTED },
            { field: "masked", value: data?.sensitiveDataActions?.MASKED },
            { field: "noAction", value: data?.sensitiveDataActions?.NO_ACTION }
        ],
        isLoading: !data
    };
};

const SensitiveDataChart = ({
    sensitiveData,
    sdiOccurrences,
    chartType,
    selectedSDI,
    selectedDateRange,
    setSelectedDateRange,
    onLiveViewClick,
    legendData
}: SensitiveDataChartProps) => {
    const [isDiscoveryDisabled, setIsDiscoveryDisabled] = useState<boolean>(false);
    const [dataUnavailableTimestamp, setDataUnavailableTimestamp] = useState<number | null>();
    const chartRef = useRef(null);

    const loadData = useCallback(
        _.debounce((event, dateRange) => {
            const updatedDateRange = getTimestampsAsOneHourWindow(dateRange?.from, dateRange?.to);
            setSelectedDateRange(updatedDateRange);
        }, 300),
        []
    );

    const graphData = sdiOccurrences ?? sensitiveData;

    return (
        <div style={{ position: "relative" }}>
            <SensitiveDataOccurrencesChart
                chartRef={chartRef}
                hideNavigator={chartType === TABS[1]}
                chartData={{
                    encrypted: graphData?.encrypted,
                    masked: graphData?.masked,
                    noAction: graphData?.noAction,
                    occurrencesOfSensitiveData: graphData?.occurrencesOfSensitiveData
                }}
                onScroll={loadData}
                selectedDateRange={selectedDateRange}
                isDiscoveryDisabled={isDiscoveryDisabled}
                hasAppStopped={false}
                dataUnavailableTimestamp={dataUnavailableTimestamp}
                onLiveViewClick={onLiveViewClick}
                legendData={legendData}
            />
        </div>
    );
};

export default SensitiveDataChart;
