import React from "react";
import { Grid, Box } from "@material-ui/core";
import { ManageAccounts } from "src/generic/icon/customIcons";
import { StriimTypography, StriimButton } from "@striim/striim-ui";
import { useStyles } from "./user-management-unauthorized.styles";
import { useNavigate } from "react-router";

const UserManagementUnauthorizedPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.wrapper}>
            <Grid item container justifyContent="space-around">
                <ManageAccounts className={classes.icon} />
            </Grid>

            <Box component={StriimTypography} variant="h2" className={classes.title}>
                Striim users and roles can only be managed by administrators
            </Box>
            <Box component={StriimTypography} variant="body" className={classes.description}>
                Please contact your Striim administrator to get the required permissions.
            </Box>
            <StriimButton variant="primaryText" onClick={() => navigate("/landing")}>
                Back to home
            </StriimButton>
        </Grid>
    );
};

export default UserManagementUnauthorizedPage;
