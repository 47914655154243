import App from "app";
import visualizationPanelPersister from "../visualization/visualization-panel-persister";
import "lib/vendor/jquery.event.drag-drop";
import "./component";
import NestedTypes from "backbone.nestedtypes";
import { mousePos } from "../../common/drag-and-drop";

var GRID_EVENTS = [
    "component:updatePosition",
    "component:add",
    "component:render",
    "component:remove",
    "component:deleted",
    "component:movestart",
    "component:moveinit",
    "component:moveend",
    "component:move",
    "component:resizestart",
    "component:resizeend",
    "component:resizeinit",
    "component:resize",
    "component:style",
];

App.module("Dashboard.Grid", function (Grid, App, Backbone, Marionette, $, _, _template) {
    Grid.GRID_EVENTS = GRID_EVENTS;

    Grid.ComponentCollection = NestedTypes.Collection.extend({
        model: function (attrs, options) {
            // Determine model at runtime, component is not defined at this point
            return new App.Dashboard.Grid.Component.Model(attrs, options);
        },
    });

    Grid.Model = App.Dashboard.Grid.Component.Model.extend({
        defaults: {
            _guides: false, // guides visible?
            components: Grid.ComponentCollection, // components in this grid
            innerheight: NestedTypes.options({
                // the bounding box of all the components inside this grid
                type: Number,
                value: null,
                get: function () {
                    var minheight = 0;
                    this.attributes.components.each(function (component) {
                        minheight = Math.max(minheight, component.y + component.height);
                    });
                    return minheight;
                },
            }),
        },
        initialize: function () {
            _(this).bindAll("setComponentData");

            this.components.each(this.setComponentData);
            this.listenTo(this.components, "add", function (c) {
                this.setComponentData(c);
                this.trigger("component:add", c, this);
            });
            this.listenTo(this.components, "remove", function (c) {
                this.trigger("component:remove", c, this);
            });
            this.listenTo(this, "change:_editable", function () {
                // FIXME if a page has not yet been loaded, this.components is undefined and event is triggered for all the 'pages'
                // TODO only the current page should have this event handler
                if (typeof this.components === "undefined") {
                    return;
                }
                this.components.each(this.setComponentData);
            });

            App.Metadata.DefaultMetaObject.Model.prototype.initialize.apply(this, arguments);
        },
        setComponentData: function (componentModel) {
            componentModel.set({
                _editable: this._editable,
                settings: this.settings,
            });
        },
        // TODO: calling this toJSON causes some errors with template-rendering, but if that can be resolved,
        // this function should be toJSON.
        serialize: function () {
            var json = {
                components: this.components.map(function (c) {
                    return c.serialize();
                }),
            };
            if (this.settings) json.settings = this.settings.toJSON();
            if (this.id) json.id = this.id;
            return json;
        },
        set_ids: function () {
            // Because of other objects like QV and page that extend this, we need to preserve the DefaultMetaObject ID assign method
            App.Metadata.DefaultMetaObject.Model.prototype.set_ids.apply(this, arguments);

            if (!this.id)
                this.set("id", _.uniqueId("grid"), {
                    silent: true,
                });
        },
        validateLayout: function (newLayout) {
            // Note: page view will enforce a height of at least the full window size in renderPosition
            return this.validateComponentLayout(this, newLayout, {
                minHeight: Math.max(this.innerheight, 0.5),
                maxBottomEdge: null,
            });
        },
        validateComponentLayout: function (component, newLayout, limits) {
            _(newLayout).defaults({
                x: component.x,
                y: component.y,
                width: component.width,
                height: component.height,
            });

            limits || (limits = {});
            _(limits).defaults({
                minLeftEdge: 0,
                minTopEdge: 0,
                minWidth: 0.5,
                minHeight: 0.5,
                maxRightEdge: this.settings.cols,
                maxBottomEdge: this.height,
            });

            if (newLayout.height < limits.minHeight) {
                delete newLayout.y;
                delete newLayout.height;
            } else if (newLayout.y < limits.minTopEdge) {
                newLayout.y = limits.minTopEdge;
                delete newLayout.height;
            } else if (limits.maxBottomEdge != null && newLayout.y + newLayout.height > limits.maxBottomEdge) {
                newLayout.y = limits.maxBottomEdge - newLayout.height;
                delete newLayout.height;
            }

            if (newLayout.width < limits.minWidth) {
                delete newLayout.x;
                delete newLayout.width;
            } else if (newLayout.x < limits.minLeftEdge) {
                newLayout.x = limits.minLeftEdge;
                delete newLayout.width;
            } else if (newLayout.x + newLayout.width > limits.maxRightEdge) {
                newLayout.x = limits.maxRightEdge - newLayout.width;
                delete newLayout.width;
            }

            return newLayout;
        },
        moveComponent: function (component, delta) {
            var newLayout = {
                x: component.x + delta[0],
                y: component.y + delta[1],
            };
            component.set(this.validateComponentLayout(component, newLayout));
        },
    });

    var GridComponentsView = Marionette.CollectionView.extend({
        className: "grid-components-container",
    });

    // A Grid (container with many Grid Components)
    Grid.View = App.Dashboard.Grid.Component.View.extend({
        className: "grid",

        _showZIndex: false,

        initialize: function () {
            // If a component inside this grid is being resized, activate the grid and
            // set the appropriate cursor within the grid
            this.listenTo(this, "child:component:resizestart", function (component, dd) {
                switch (dd.dir) {
                    case "E":
                    case "W":
                        this.$el.addClass("resizing-h");
                        break;
                    case "S":
                    case "N":
                        this.$el.addClass("resizing-v");
                        break;
                    default:
                        this.$el.addClass("resizing-" + dd.dir.toLowerCase());
                        break;
                }
            });
            this.listenTo(this, "child:component:resizeend", function() {
                this.$el.removeClass("resizing-h resizing-v resizing-ne resizing-nw resizing-se resizing-sw");
                //this.model.set('_active', false);
            });
            // Re-render guides when the grid itself is resized
            this.listenTo(this, "component:resizeend", function() {
                this.renderGuides();
            });

            // Keep track of whether there is a modal open or not
            this.listenTo(App.Dashboard.ModalManager, "modal:add", function () {
                this._modalOpen = true;
            });
            this.listenTo(App.Dashboard.ModalManager, "modal:remove", function () {
                this._modalOpen = false;
            });

            this.initializeGridDrop();
            this.initializeKeyboardShortcuts();

            this.on("validateLayout", function (layout) {
                this.model.validateLayout(layout);
            });

            this.on("child:component:render", this.initializeComponentResizeAndMove);
            //this.on('child:component:move', this.renderDropTarget);
            this.listenTo(App.vent, "palette:drag", this.renderDropTarget);

            this.listenTo(
                App.vent,
                "dashboard:cut:selection",
                function (components) {
                    components.forEach(
                        function (component) {
                            this.model.components.remove(component.id);
                            this.getRegion("content").currentView.collection.remove(component.id);
                            this.model.save();
                        }.bind(this)
                    );
                }.bind(this)
            );

            this.listenTo(
                App.vent,
                "dashboard:paste:selection",
                function (components) {
                    var gridComponents = components.map(
                        function (component) {
                            return {
                                component: component,
                                grid: this.model,
                            };
                        }.bind(this)
                    );

                    return App.vent.trigger("grid:paste:selection", gridComponents);
                }.bind(this)
            );

            // "Activate" the grid when a component is about to be dropped into it.
            // TODO: transfer to use grid-specific dropstarts. Not working cleanly
            this.listenTo(this, "child:component:dropstart", function (e, dd) {
                // Take over the placeholder
                dd.$placeholder.detach().appendTo(this.$el);
            });

            return App.Dashboard.Grid.Component.View.prototype.initialize.apply(this, arguments);
        },
        initializeGridDrop: function () {
            var _this = this;
            this.$el
                .drop("init", function (e, dd) {
                    // Do not permit any interaction if this grid is not editable
                    if (!_this.model._editable) return false;
                    if (!dd.event) return false; // Dragging around a style modal, etc. can also trigger a drop
                    if (dd.event === "resize")
                        // Resize also fires drops, however this is not what we need here
                        return false;
                })
                .drop("start", function (e, dd) {
                    dd.grid = _this;

                    if (!dd.$placeholder) {
                        dd.$placeholder = $('<div class="grid-component-placeholder"><div class="inner"></div></div>');
                    }
                    dd.$placeholder.hide(); // Hide until it is positioned

                    App.vent.trigger("component:dropstart", e, dd);
                    _this.trigger("child:component:dropstart", e, dd);
                })
                .drop("drop", function (e, dd) {
                    if (!dd.droplayout) {
                        console.warn("No component move event has been fired yet, no droplayout");
                        return false;
                    }

                    //var id = $(this).data("id");
                    App.vent.trigger("component:drop", {
                        grid: _this.model,
                        item: dd.item,
                        itemtype: dd.itemtype,
                        dd: dd,
                    });
                    //gridview.trigger('component:drop', { grid: gridview.model, item: dd.item, dd: dd });
                    _this.trigger("child:component:drop", {
                        grid: _this.model,
                        item: dd.item,
                        itemtype: dd.itemtype,
                        dd: dd,
                    });

                    if (dd.$placeholder) dd.$placeholder.remove();
                    if (dd.event === "new") $(dd.proxy).remove();
                })
                .drop("end", function (e, dd) {
                    if (dd.drop.length === 0) dd.$placeholder.remove();
                    App.vent.trigger("component:dropend", $(this).data("id"), e, dd);
                });
        },
        initializeKeyboardShortcuts: function () {
            $(document).on(
                "keydown.grid",
                function (e) {
                    if (this._modalOpen) {
                        console.log("Modal is open; passing arrow keys through");
                        return true;
                    }

                    var onePxOnGridX = 1 / (this.$el.width() / this.model.settings.cols);
                    var oneStepOnGridX = Math.max(onePxOnGridX, 1 / 50); // Horizontally, step 1/50 of a grid cell at a time, but no less than what would visibly be 1 pixel on the screen
                    var oneStepOnGridY = 1 / this.model.settings.cellheight;

                    switch (e.keyCode) {
                        case 39: // Right arrow
                            this.moveSelected(e.shiftKey ? [oneStepOnGridX, 0] : [1, 0]);
                            return false;
                        case 37: // Left arrow
                            this.moveSelected(e.shiftKey ? [oneStepOnGridX * -1, 0] : [-1, 0]);
                            return false;
                        case 38: // Up arrow
                            this.moveSelected(e.shiftKey ? [0, oneStepOnGridY * -1] : [0, -1]);
                            return false;
                        case 40: // Down arrow
                            this.moveSelected(e.shiftKey ? [0, oneStepOnGridY] : [0, 1]);
                            return false;
                    }
                }.bind(this)
            );
        },
        initializeComponentResizeAndMove: function (component) {
            component.$el
                .drag("init", component.initializeDrag)
                .drag("start", component.startDrag)
                .drag(
                    "drag",
                    function (e, dd) {
                        dd.gridX = mousePos.pageX - this.$el.offset().left;
                        dd.gridY = mousePos.pageY - this.$el.offset().top;
                        dd.maxWidth = component.model.settings.cols - component.model.x;
                        dd.minWidth = 0.5; // < 1 to allow small rounding errors
                        dd.maxHeight = this.model.height - component.model.y;
                        dd.minHeight = 0.5;

                        component.performDrag(e, dd);
                    }.bind(this),
                    {
                        distance: 5,
                    }
                )
                .drag("end", component.finishDrag);
        },
        onRemoveChild: function (component) {
            this.stopListening(component);
        },
        // Fired after a grid component inside this grid is rendered
        // A component has its own onRender procedure, and this follows immediately; the work here has to do
        // with the Grid's post-rendering concerns, such as positioning the component and removing it.
        onAddChild: function (component) {
            // VisualizationPanel will emit this on the component when its containing vis is deleted, and a grid
            // will trigger this on the component if it has been destroyed
            this.listenTo(component, "component:delete", function () {
                // This must be done before removing from collection, because remove from collection will trigger
                // component:remove. That will save the page before we've removed the component from the grid def
                this.model.components.remove(component.model.get("id"));
                this.getRegion("content").currentView.collection.remove(component.model.get("id"));
                this.trigger("child:component:deleted", component);
                App.vent.trigger("visualization:deleted", component);

                this._resetZIndex();
            });

            this.listenTo(component, "validateLayout", function (layout) {
                this.model.validateComponentLayout(component.model, layout);
            });

            this.listenTo(
                component,
                "component:bringForward",
                function (visualization) {
                    if (visualization.model.content.config.zIndex < this.model.components.models.length) {
                        this._setZIndex(visualization, 1);
                    }
                }.bind(this)
            );

            this.listenTo(
                component,
                "component:sendBackward",
                function (visualization) {
                    if (visualization.model.content.config.zIndex > 1) {
                        this._setZIndex(visualization, -1);
                    }
                }.bind(this)
            );

            this.listenTo(
                component,
                "component:show-zIndex",
                function() {
                    this._showZIndex = !this._showZIndex;
                    this.model.components.trigger("toogle-zIndex-visibility", this._showZIndex);
                }.bind(this)
            );

            this.model.components.trigger("toogle-zIndex-visibility", this._showZIndex);

            // Propagate component add and remove events from inside nested grids
            _(GRID_EVENTS).each(
                function (e) {
                    this.stopListening(component, e);
                    this.listenTo(component, e, function (c) {
                        this.trigger.apply(this, ["child:" + e].concat(_(arguments).values()));
                    });
                }.bind(this)
            );

            // Trigger component render, show
            //this.trigger('child:component:render', component, this);
            component.trigger("component:render", component, this);
        },

        _setZIndex: function (visualizationView, /* 1 | -1 */ incrementValue) {
            var currentZIndex = visualizationView.model.content.config.zIndex;

            var visualizationToOverride = _(this.model.components.models).find(function (v) {
                return v.content.config.zIndex === currentZIndex + incrementValue;
            });
            if (visualizationToOverride) {
                visualizationToOverride.content.config.set("zIndex", currentZIndex);
                visualizationPanelPersister.save(
                    visualizationToOverride.content,
                    visualizationToOverride.get("content")
                );
            }
            visualizationView.model.content.config.set("zIndex", currentZIndex + incrementValue);
            visualizationPanelPersister.save(visualizationView.model.content, visualizationView.model.get("content"));

            this._resetZIndex();
        },

        _resetZIndex: function () {
            var sortedComponents = _(this.model.components.models).sortBy(function (c) {
                return c.content.config.zIndex;
            });

            for (var i = 0; i < sortedComponents.length; i++) {
                var zIndex = sortedComponents[i].content.config.zIndex;
                if (zIndex !== i + 1) {
                    sortedComponents[i].content.config.set("zIndex", i + 1);
                    visualizationPanelPersister.save(sortedComponents[i].content, sortedComponents[i].get("content"));
                }
            }
        },

        moveSelected: function (delta) {
            return this.moveComponents(
                this.model.components.chain().filter(function (c) {
                    return c._active;
                }),
                delta
            );
        },
        moveComponents: function (components, delta) {
            components.each(function (c) {
                this.model.moveComponent(c, delta);
            }, this);
            this.trigger("components:nudge"); // TODO: use a better event name to keep with other child:component event name convention
        },
        // Vis Panel overrides this to allow drop target to fill its entire grid's area
        getDropTargetDimensions: function (dd, dimensions) {
            var $grid = this.$el;

            let height;
            // Brand new component
            if (dd.event === "new") {
                var cell_width = $grid.width() / this.model.settings.cols;
                var px_height = cell_width * dd.preferred_component_dimensions.height;
                height = Math.round(px_height / this.model.settings.cellheight);
            }
            // Moving existing component (don't change height)
            else {
                height = dimensions.height;
            }
            // If the grid is shorter than the desired dimensions, shrink the drop target to fit
            dimensions.height = Math.min(height, this.model.height);

            return dimensions;
        },
        // Draw the drop placeholder in this grid
        renderDropTarget: function (component, e, dd) {
            var $grid = this.$el;

            // Desired initial dimensions
            var dimensions = this.getDropTargetDimensions(dd, $.extend(true, {}, dd.component_dimensions));

            // Convert the placeholder position to a position within the grid we are over and
            // center it on the cursor
            var x_within_grid = mousePos.clientX - $grid.offset().left;
            var y_within_grid = mousePos.clientY - $grid.offset().top;

            var dimensions_css = this.model.settings.getCSS(dimensions);

            var left = (x_within_grid / $grid.width()) * 100 - parseInt(dimensions_css.width) / 2; // percent
            var top = $(document).scrollTop() + y_within_grid - parseInt(dimensions_css.height) / 2; // pixels

            // Convert the within-grid CSS position to grid layout coordinates and validate
            var oldLayout = _(dd.droplayout || {}).clone();
            var newLayout = this.model.settings.getLayout({
                top: top,
                left: left,
            });

            if (!dd.droplayout) dd.droplayout = newLayout;

            if (dd.snap) {
                newLayout.y = Math.round(newLayout.y);
                newLayout.x = Math.round(newLayout.x);
            }

            this.model.validateComponentLayout(oldLayout, newLayout);
            // TODO: vCL does not current allow validating only x and y without also validating width and height
            _(newLayout).extend(dimensions);
            _(dd.droplayout).extend(newLayout);

            // Update the placeholder position (snap it to the grid coordinates)
            var css = this.model.settings.getCSS(dd.droplayout);

            if (!dd.$placeholder) {
                //return console.warn("No target yet");
            } else {
                dd.$placeholder.css(css).show();
            }
        },
        onRender: function () {
            // Add component ID
            // Unfortunately this is needed for drag/drop to work properly.
            var _this = this;
            this.$el.data("id", this.model.get("id"));

            var ComponentsView = GridComponentsView.extend({
                getChildView: function (componentmodel) {
                    return _this.getComponentView(componentmodel);
                },
            });

            var renderedCollection = new App.Dashboard.Grid.ComponentCollection(this.model.components.models);
            var componentsView = new ComponentsView({
                collection: renderedCollection,
            });

            this.listenTo(componentsView, "before:add:child", this.beforeAddChild);
            this.listenTo(componentsView, "add:child", this.onAddChild);
            this.listenTo(componentsView, "remove:child", this.onRemoveChild);

            this.getRegion("content").show(componentsView);

            App.Dashboard.Grid.Component.View.prototype.onRender.apply(this, arguments);
        },
        onShow: function () {
            // When the grid's guides are turned on/off, turn on/off any subgrids' guides
            _(
                function () {
                    this.renderGuides();
                }.bind(this)
            ).defer();
            this.listenTo(this.model, "change:_guides", this.renderGuides);

            App.Dashboard.Grid.Component.View.prototype.onShow.apply(this, arguments);
        },
        renderGuides: function () {
            //console.log('Rendering guides for:', this.model.id)
            if (this.model.get("_guides")) this.showGuides();
            else this.hideGuides();
        },
        showGuides: function () {
            // Topmost grid has no parent component, therefore it has no height
            if (this.model.height == null) return;

            var cells = [];

            var unit_css = this.model.settings.getCSS({
                width: 1,
                height: 1,
            });

            var px_height =
                this.model.height === 0 ? this.$el.height() : this.model.height * this.model.settings.cellheight;
            var num_rows = Math.ceil(px_height / this.model.settings.cellheight);
            this.model.settings.rows = num_rows;

            for (var i = 0; i < this.model.settings.cols * num_rows; i++) {
                var css = _({}).extend(unit_css);
                var classnames = "guide";

                if (i < this.model.settings.cols) classnames += " js-top-row";

                const isLastRowFirstIndex = (num_rows - 1) * this.model.settings.cols;
                if (i >= isLastRowFirstIndex) {
                    classnames += " js-bottom-row";
                    if (i === isLastRowFirstIndex) classnames += " left-corner";
                }

                var row_end = ((this.model.settings.cols + i + 1) % this.model.settings.cols) === 0;
                // Ensure each row fills up 100% of the row. (Last cell in each row compensates for rounding errors)
                if (row_end) {
                    var width = Number(css.width.replace(/[^0-9.]/g, ""));
                    //var orig = css.width;
                    css.width = 100 - width * (this.model.settings.cols - 1) + "%";
                    classnames += " js-row-end";
                }

                var $guide = $("<div/>", {
                    class: classnames,
                }).css(css);
                $guide.html('<div class="vertex"></div>');

                cells.push($guide);
            }
            var $grid_outline = $("<div/>", {
                class: "grid-outline",
            });
            this.$el.children(".guides").children(".grid-outline").remove();
            this.$el.children(".guides").append($grid_outline);
            this.$el.children(".guides").children(".guides-container").html(cells);
            this.$el.children(".guides").show();
        },
        hideGuides: function () {
            this.$el.children(".guides").children(".grid-outline").remove();
            this.$el.children(".guides").children(".guides-container").html("");
            this.$el.children(".guides").hide();
        },
        onBeforeDestroy: function () {
            $(document).off("keydown.grid");
        },
    });
});
