import App from "app";
import WAHighMapsMixin from "app/components/dashboard/visualization/charts/choropleth/mixins/choropleth-highmaps";
import "app/components/dashboard/visualization/charts/chart";

App.module("Chart.Choropleth", function (Chart, App, Backbone, Marionette, $, _) {
    Chart.View = App.Chart.Default.ChartView.extend({
        initialize: function () {
            _(this).extend(WAHighMapsMixin);
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
        },
    });
});
