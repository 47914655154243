import React, { useEffect } from "react";
import App from "app";
import FeatureNotAvailableHeader, {
    featureNoAvailableSize
} from "../../../../common/segmentation/feature-not-available-header";
import { Grid } from "@material-ui/core";
import {
    FeaturesDivider,
    FeatureLeft,
    FeatureRight,
    featureSize
} from "src/modules/common/segmentation/features-description";
import securityService from "core/services/securityService/securityService";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";

const AlertManagerNotAvailable = () => {
    useEffect(() => {
        const isEnabled = securityService.isSegmentationFeatureEnabled(addonFeaturesKeys.METADATAMANAGER);
        if (isEnabled) {
            App.navigate("/manage-striim/metadata-manager", { trigger: true });
        }
    }, []);

    const variant = securityService.getSegmentationVariant(addonFeaturesKeys.METADATAMANAGER);

    return (
        <>
            <Grid item xs={12}>
                <FeatureNotAvailableHeader
                    title="Metadata manager is an Enterprise feature"
                    variant={variant}
                    description="Metadata manager enables you to browse all metadata objects and schemas within the Striim Platform. It can be also accessessed from the Flow Designer to copy settings from components in other applications and flows."
                    imgComponent={
                        <img
                            src="app/images/segmentation/matadata-manager/Metadatamanager.png"
                            style={featureNoAvailableSize}
                        />
                    }
                />
                <FeaturesDivider />
                <FeatureLeft
                    title="Search and filter"
                    description="Search for any type of metadata in the platform"
                    imgComponent={
                        <img src="app/images/segmentation/matadata-manager/searchFilter.png" style={featureSize} />
                    }
                />
                <FeatureRight
                    title="View objects in context"
                    description="Navigate to your object to its direct use in any application"
                    imgComponent={
                        <img src="app/images/segmentation/matadata-manager/navigate.png" style={featureSize} />
                    }
                />
            </Grid>
        </>
    );
};

export default AlertManagerNotAvailable;
