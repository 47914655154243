export const styles = {
    collapsibleSection: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
        backgroundColor: "greyscale.50",
        p: 2
    },
    sourcesContainer: {
        height: `calc(100% - 140px)`,
        overflowY: `scroll`
    },
    evenSection: {
        backgroundColor: "greyscale.50"
    },
    messageBox: {
        backgroundColor: ({ palette }) => palette.critical[50],
        borderColor: ({ palette }) => palette.critical[100],
        wordBreak: "break-word",
        "& .MuiCardHeader-content": {
            fontWeight: 400,
            fontSize: 14,
            fontFamily: "Inter",
            color: ({ palette }) => palette.greyscale[800],
            lineHeight: "20px",
            letterSpacing: "0.14px"
        },
        "& span": {
            color: ({ palette }) => palette.greyscale[800]
        },
        "& svg": {
            fill: ({ palette }) => palette.critical[500]
        },
    },
    bottomBorder: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`,

    },
    warningMessageBox: {
        p: 0,
        backgroundColor: "warning.100",
        borderColor: "warning.200",
        "& .MuiCardHeader-avatar > svg": {
            fontSize: "20px",
            color: "warning.500"
        }
    },
    warningIcon: {
        fill: 'none'
    },
    createReportIcon: {
        fill: "primary.main",
        width: 20,
        height: 20,
        pr: 0.5
    }
};