export const VaultTypes = {
    AZURE_VAULT: "AZUREKEYVAULT",
    HASHICORP_VAULT: "HASHICORPVAULT",
    STRIIM_VAULT: "STRIIMDEFAULTVAULT",
    GOOGLE_SECRET_MANAGER: "GOOGLESECRETMANAGER"
};

export const httpMatch = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const numberMatch = /^\d+$/;
