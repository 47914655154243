const styles = {
    wrapper: {
        flexDirection: "column"
    },
    frameWrapper: {
        margin: "0 auto"
    }
};

export default styles;
