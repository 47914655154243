import React from "react";

import CreateAppHeader from "./components/create-app-header";
import CreateAppPage from "./components/create-app-content/create-app-content";
import { useReactHeader } from "src/hooks/useReactHeader";
import { Box } from "@mui/material";

const styles = {
    pageWrapper: {
        margin: theme => theme.spacing(10, 4, 4, 4)
    }
};
export const CreateAppPageWrapper = () => {
    useReactHeader({ title: "Apps" });

    return (
        <Box sx={styles.pageWrapper}>
            <CreateAppHeader />
            <CreateAppPage />
        </Box>
    );
};

export default CreateAppPageWrapper;
