import React, { useEffect, useState } from "react";
import { StriimTheme } from "@striim/striim-ui";
import RootRouter from "./modules/rootRouter";
import EventAggregator from "./event-aggregator";
import api from "../core/api/api";

interface ReactAppProps {
    dispatcher: object;
}

export default function ReactApp({ dispatcher }: ReactAppProps) {
    //TODO: pass dispatcher to a context, to avoid using App.Vent inside `src` folder
    return (
        <StriimTheme preselector=".striim.materialize.light:not(#\20)">
            <RootRouter />
        </StriimTheme>
    );
}
