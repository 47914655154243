import _ from "underscore";
import BaseControl from "./../base-control";
import UIControl from "app/components/common/editor/control/ui-control";
import { filterComponentsByAdapters } from "app/components/flow/designer/component-acendants-loader";
import { loadAscendantsDbSources } from "app/components/flow/designer/component-acendants-loader";
import { loadDGsForAgents, getAgentForDeploymentGroup } from "app/components/flow/designer/agents-loader";
import TableSelector from "./table-selector";
import template from "./wae-to-typed-event.html";

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "columns-splitter",

    regions: {
        tableSelectorControlRegion: ".table-selector-control",
    },

    setViewValue: function () {},

    getViewValue: function () {
        return this.tableSelectorControl.getViewValue();
    },

    setEnabled: function (enabled) {
        BaseControl.View.prototype.setEnabled.apply(this, [enabled]);
        this.tableSelectorControl.setEnabled(enabled);
    },

    initialize: function (options) {
        this.tableSelectorControl = TableSelector.create();

        this.tableSelectorControl.on(
            "show-error-msg",
            function (message) {
                this.trigger("show-error-msg", message);
            }.bind(this)
        );
    },

    onRender: function () {
        if (this.model.value && this.model.value.length > 0) {
            this.tableSelectorControl.model.value = this.model.value;
        }

        this.tableSelectorControlRegion.show(this.tableSelectorControl);

        this.listenTo(
            this.tableSelectorControl,
            UIControl.EVENT.VALUE_CHANGED,
            function (value) {
                this.trigger(UIControl.EVENT.VALUE_CHANGED, this.getViewValue());
            }.bind(this)
        );
    },

    setStream: function (componentId) {
        this.tableSelectorControl.setStream(componentId);
    },
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: Array,
    },
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
