import React from "react";
import { Grid, Divider } from "@mui/material";
import { styles } from "../guardian.styles";

interface GuardianFrameInterface {
    children: React.ReactNode;
    Header?: React.ReactNode;
    Footer?: React.ReactNode;
    showHeaderBorder?: Boolean;
    showFooterBorder?: Boolean;
}

const GuardianFrame = ({
    Header = null,
    Footer = null,
    showHeaderBorder = false,
    showFooterBorder = false,
    children
}: GuardianFrameInterface) => {
    const hasHeader = !!Header;
    const hasFooter = !!Footer;

    return (
        <Grid container sx={styles.frameContainer}>
            <Grid container>
                {hasHeader && (
                    <Grid container item p={3}>
                        {Header}
                    </Grid>
                )}

                {showHeaderBorder && <Divider variant="fullWidth" orientation="horizontal" sx={styles.divider} />}
            </Grid>

            <Grid container sx={styles.frameChildrenContainer}>
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>

            <Grid container>
                {showFooterBorder && <Divider variant="fullWidth" orientation="horizontal" sx={styles.divider} />}

                {hasFooter && (
                    <Grid container item xs={12} p={3} sx={styles.frameFooter}>
                        {Footer}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default GuardianFrame;
