const OAUTH_NAMES = ["EntraID", "OAuth"];

const OAUTH_FIELDS_KEYS = {
    AccessToken: "access_token",
    RefreshToken: "refresh_token",
    TokenType: "token_type",
    ExpiresAt: "expires_at",
    ExpiresIn: "expires_in",
    RefreshTokenExpiresIn: "refresh_token_expires_in",
    RefreshTokenExpiresAt: "refresh_token_expires_at",
    OAuthResponse: "oauth_response"
};

const OAUTH_URL_PROPERTIES = {
    clientid: "CLIENT_ID",
    clientsecret: "CLIENT_SECRET",
    host: "HOST",
    scope: "SCOPE"
};

const ENDPOINTS = {
    snowflake: "Snowflake",
    databricks: "Databricks",
    adlsgen2: "ADLSGEN2",
    s3: "S3",
    salesforce: "Salesforce",
    servicenow: "ServiceNow"
};

// Maintaining this ugly list in the UI for 4.3.0 due to backend limitations.
// List of optional fields under the OAuth group
const OPTIONAL_OAUTH_FIELDS = {
    Snowflake: ["scope"],
    Salesforce: ["host"]
};

const OAUTH_FIELDS = Object.values(OAUTH_FIELDS_KEYS);

export { OAUTH_NAMES, OAUTH_FIELDS_KEYS, OAUTH_FIELDS, OAUTH_URL_PROPERTIES, ENDPOINTS, OPTIONAL_OAUTH_FIELDS };
