import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ shadows, spacing }) => ({
    iconWhite: {
        fill: "none",
        borderRadius: 4,
        width: "20px"
    },
    tooltipWrapper: {
        boxShadow: shadows[24],
        "& > div": {
            overflow: "hidden"
        }
    },
    whiteButtonVariant: {
        color: ({ paletteV2 }) => paletteV2?.greyscale[700],
        fontSize: "12px",
        marginRight: spacing(1),
        height: 40,
        "&:hover": {
            backgroundColor: "rgba(254, 254, 255,  0.28)"
        }
    },
    heightAuto: {
        height: "auto"
    },
    flex: {
        display: "flex",
        alignItems: "center"
    },
    IconContainer: {
        display: "flex",
        marginLeft: spacing(2)
    },
    divider: {
        height: spacing(6),
        borderRight: ({ paletteV2 }) => `1px solid ${paletteV2?.greyscale[200]}`
    },
    badge: {
        fontSize: "10px",
        height: "auto",
        padding: "2px 4px",
        top: "6px"
    },
    striimGPTTopBar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: spacing(1, 3)
    },
    coPilotHeader: {
        display: "flex",
        alignItems: "center"
    },
    striimGPTMover: {
        display: "flex",
        alignItems: "center"
    },
    striimGPTMoveOptions: { maxWidth: "180px" },
    striimGPTTopBarControls: { display: "flex" },
    tooltipIcon: {
        color: "primary.700",
        width: "16px",
        height: "16px",
        color: "transparent",
        display: "flex",
        marginLeft: spacing(0.5)
    }
}));
