import App from "app";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import dataVisualizationsEditors from "core/services/metadataService/dataVisualizationsEditors";
import "./editor";
import "app/components/dashboard/editors/color";
import "app/components/dashboard/editors/string";
import "app/components/dashboard/editors/text";
import "app/components/dashboard/editors/number";
import "app/components/dashboard/editors/collection";
import "app/components/dashboard/editors/enum";
import "app/components/dashboard/editors/boolean";
import "app/components/dashboard/editors/field";
import "app/components/dashboard/editors/page";
import "app/components/dashboard/editors/heading";
import "app/components/dashboard/editors/headingSelector";
import "app/components/common/_deprecated/form/form";
import "select2";

App.module("Dashboard.VisEditor", function (VisEditor, App, Backbone, Marionette, $, _) {
    VisEditor[dataVisualizations.EDITOR.PROPERTIES] = VisEditor.Editor.CompositeView.extend({
        className: "properties-editor",
        initialize: function () {
            if (!this.model.config) {
                console.error(
                    "Error: cannot render properties editor, because the config property of the model is undefined. The model received by PropertiesEditor:",
                    this.model
                );
            } else {
                this.collection = new dataVisualizationsEditors.EditorViewModel.Collection(
                    this.model.editor.propertyeditors.map(
                        function (editor) {
                            return {
                                _queryvisualization: this.model._queryvisualization,
                                _datavisualization: this.model._datavisualization,
                                _parent: this.model,
                                _theme: this.model._theme,
                                editor: editor,
                                config: this.model.config,
                            };
                        }.bind(this)
                    )
                );
            }

            return VisEditor.Editor.CompositeView.prototype.initialize.apply(this, arguments);
        },
        getChildView: function (item) {
            var editor = App.Dashboard.VisEditor[item.editor.type];
            return editor || App.Dashboard.VisEditor[dataVisualizations.EDITOR.STRING];
        },
        buildChildView: function (child, ChildViewClass, childViewOptions) {
            var options = {
                model: child,
            };

            //var editor_type = child.editor.editor.length > 0 ? dataVisualizations.EDITOR.PROPERTIES : child.editor.type;
            var editor_type = child.editor.type;

            switch (editor_type) {
                case dataVisualizations.EDITOR.COLLECTION:
                    // The Collection for which to generate the Collection editor
                    var collection_to_edit = this.model.config[child.editor.attribute];
                    var _parent_editor_id = this.model.editor.propertyeditors.indexOf(child.editor);

                    if (!collection_to_edit) {
                        if (!attribute_to_edit) {
                            console.error(
                                'Warning: the value of the property "' +
                                    child.editor.attribute +
                                    '" is not defined in model:',
                                this.model.config
                            );
                        }
                    } else {
                        options.model.set({
                            _parent_editor_id: _parent_editor_id,
                            config: this.model.config,
                        });
                    }

                    break;

                // If an attribute is itself an object with attributes, use the Properties editor again.
                case dataVisualizations.EDITOR.PROPERTIES:
                    // The object to edit
                    var attribute_to_edit = this.model.config[child.editor.attribute];
                    if (!attribute_to_edit) {
                        console.error(
                            'Warning: the value of the property "' +
                                child.editor.attribute +
                                '" is not defined in model:',
                            this.model.config
                        );
                    } else {
                        // child.editor is the input editor for this attribute, which contains another array of editors.
                        // child.editor.editor is the array of input editors that define how to edit the attributes
                        // in the above object.
                        options.model.set({
                            editor: child.editor,
                            config: attribute_to_edit,
                        });
                    }

                    break;
            }

            // build the final list of options for the childView class
            var options = _(options).extend(childViewOptions);

            // create the child view instance
            var view = new ChildViewClass(options);

            // return it
            return view;
        },
    });
});
