import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import growl from "app/components/common/growl";
import { Grid } from "@material-ui/core";

import TopBanner from "src/modules/homepage/top_banner/top_banner";
import SystemHealthOverview from "src/modules/homepage/systeam_health_overview/system_health_overview";
import YourApps from "src/modules/homepage/your_apps/your_apps";
import PopularTemplates from "src/modules/homepage/popular_templates/popular_templates";
import DocumentationLinks from "src/modules/homepage/documentation_links/documentation_links";
import AppMetrics from "../../../homepage/app_metrics/app_metrics";
import templates from "app/components/appwizard/resources/generated/templates.json";
import useStyles from "./exp_user.styles";
import YourRecentDashboards from "../../../homepage/your_recent_dashboards/your_recent_dashboards";
import widgetResources from "./resources/widgetResources";
import userPreferences from "core/services/userPreferences/user-preferences";

const seenWidgetsKey = "seenWidgets";

/**
 * Experienced User Screen
 * @param {array} apps - list of user apps
 * @param {string} username - user display name
 * @param {object} video
 * @returns Components selected by the user or default components
 */
const ExpUserScreen = ({ apps, username, visibleApps }) => {
    const classes = useStyles();
    // List of default components shown
    const defaultSeenWidgets = widgetResources.defaultSeenWidgets;

    // fetch seen widgetList
    const [widgets, setWidgets] = useState(
        userPreferences.get(seenWidgetsKey) ? userPreferences.get(seenWidgetsKey) : defaultSeenWidgets
    );

    // to update the widget list based on user preference
    const onChange = seenWidgets => {
        setWidgets(seenWidgets);
        userPreferences.put(seenWidgetsKey, seenWidgets);
    };

    // maps each widget to the respective component
    function getComponent(item, index) {
        switch (item.comp) {
            case "Monitor":
                return <SystemHealthOverview />;
            case "Apps":
                return <YourApps apps={apps} visibleApps={visibleApps} />;
            case "Temps":
                return <PopularTemplates templates={templates} />;
            case "Dashboards":
                return <YourRecentDashboards />;
            case "App":
                const getAppById = id => apps?.find(app => app?.id === id);
                const app = getAppById(item.appId);
                if (app) {
                    return <AppMetrics appId={item.appId} app={app} />;
                } else {
                    // remove app metric from widgets
                    let list = Array.from(widgets);
                    list.splice(index, 1);
                    setWidgets(list);
                    // show error
                    growl.error(
                        "Can't diplay app metrics for " +
                            item.appId +
                            " as information for this app is no longer available.",
                        "Removing metrics for " + item.appId
                    );
                    return;
                }
            default:
                throw new Error("component not found for: " + item.comp);
        }
    }

    /**
     * View
     */
    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                data-test-id="experienced-user"
                className={classes.container}
            >
                <Grid className={classes.banner}>
                    {/* Main component allows user to customize widgets */}
                    <TopBanner
                        username={username}
                        apps={apps}
                        widgets={widgets}
                        onChange={onChange}
                        defaultSeenWidgets={defaultSeenWidgets}
                        allWidgets={[...defaultSeenWidgets, ...widgetResources.defaultHiddenWidgets]}
                    />
                </Grid>
                {/* Render widgets here */}
                {widgets.map((item, index) => (
                    <Grid
                        key={item.title}
                        data-test-id={`widget-${item.title}`}
                        test-id="homepage-widgets"
                        className={classes.widget}
                    >
                        <div>{getComponent(item, index)}</div>
                    </Grid>
                ))}
                <DocumentationLinks />
            </Grid>
        </>
    );
};

export default ExpUserScreen;

ExpUserScreen.propTypes = {
    username: PropTypes.string.isRequired,
    apps: PropTypes.array.isRequired,
    video: PropTypes.object.isRequired
};
