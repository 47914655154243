import React, { useState } from "react";
import {
    StriimMessageBox,
    StriimModal,
    StriimToggleButton,
    StriimTypography,
    StriimInputField
} from "@striim/striim-ui";
import { Grid, Box } from "@mui/material";
import classes from "./test-connection.styles";
import Error from "../../generic/icon/wizard-icon/errorFilled.svg";
import LoadingIndicator from "../loading-indicator/loading-indicator";
import Success from "../../generic/icon/wizard-icon/successFilled.svg";
import MissingDriver from "./missing-driver";
import { ValidationDtls } from "./test-connection";
import { getAgentForDeploymentGroup } from "../../../app/components/flow/designer/agents-loader";

interface TestConnectionForAgentProps {
    validationDtls: ValidationDtls;
    setShowAgentModal: React.Dispatch<React.SetStateAction<boolean>>;
    showAgentModal: boolean;
    loading: boolean;
    testConnection: (string?) => void;
    connectionSuccess?: boolean;
    errorMsg?: string;
    unavailableDriverDBType?: string;
}

const TestConnectionForAgent = ({
    validationDtls,
    setShowAgentModal,
    showAgentModal,
    testConnection,
    loading,
    errorMsg,
    connectionSuccess,
    unavailableDriverDBType
}: TestConnectionForAgentProps) => {
    const [deploySourceOnAgent, setDeploySourceOnAgent] = useState(false);
    const [selectedAgentDeploymentGroup, setSelectedAgentDeploymentGroup] = useState(null);
    const { Username, Password, ConnectionURL, agentDeploymentGroups, encrypted, SSLConfig } = validationDtls;

    return (
        <StriimModal
            size="medium"
            autoHeight
            titleContent={<StriimTypography variant="h2">Test Connection</StriimTypography>}
            isVisible={showAgentModal}
            cancelContent={"Close"}
            confirmContent={"Test"}
            onCancel={() => {
                setShowAgentModal(!showAgentModal);
            }}
            confirmButtonProps={{
                "data-test-id": "confirm-test-connection",
                disabled: loading
            }}
            onConfirm={() => {
                let agentDeploymentGroupId;
                if (agentDeploymentGroups.length === 1) {
                    agentDeploymentGroupId = deploySourceOnAgent ? agentDeploymentGroups[0]?.value : "";
                } else {
                    agentDeploymentGroupId = selectedAgentDeploymentGroup?.value;
                }
                const agentDeploymentGroup = getAgentForDeploymentGroup(agentDeploymentGroupId);
                testConnection(agentDeploymentGroup);
            }}
        >
            <Grid>
                {loading && (
                    <Box sx={classes.loader} mb={1}>
                        <LoadingIndicator isGlobal={false} />
                    </Box>
                )}
                <Grid mt={2} mb={2}>
                    <Grid sx={classes.row}>
                        <Box sx={classes.title}>Connection Url</Box>
                        <Box>
                            <StriimTypography variant={"body3"}>{ConnectionURL}</StriimTypography>
                        </Box>
                    </Grid>
                    <Grid sx={classes.row}>
                        <Box sx={classes.title}>Username</Box>
                        <Box>
                            <StriimTypography variant={"body3"}>{Username}</StriimTypography>
                        </Box>
                    </Grid>
                    <Grid sx={classes.row}>
                        <Box sx={classes.title}>Password</Box>
                        <Box>
                            <StriimTypography variant={"body3"}>
                                {encrypted ? "[Encrypted]" : "*".repeat(Password?.length)}
                            </StriimTypography>
                        </Box>
                    </Grid>
                    {SSLConfig && (
                        <Grid sx={classes.row}>
                            <Box sx={classes.title}>SSL Config</Box>
                            <Box>
                                <StriimTypography variant={"body3"}>
                                    <StriimTypography variant={"body3"}>{SSLConfig}</StriimTypography>
                                </StriimTypography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
                {agentDeploymentGroups.length === 1 && (
                    <Grid sx={classes.row}>
                        <Box sx={classes.title}>Deploy source on Agent</Box>
                        <Box>
                            <StriimToggleButton
                                onChange={() => {
                                    setDeploySourceOnAgent(!deploySourceOnAgent);
                                }}
                                name={"deploySourceOnAgent"}
                                value={deploySourceOnAgent}
                            />
                        </Box>
                    </Grid>
                )}
                {agentDeploymentGroups.length > 1 && (
                    <Grid sx={classes.row}>
                        <Box sx={classes.title}>Deployment group for agent</Box>
                        <Box
                            className={`${classes.searchByStatus} ${classes.column}`}
                            data-test-id="select-deployment-group"
                        >
                            <StriimInputField
                                select
                                SelectProps={{
                                    options: agentDeploymentGroups,
                                    components: {
                                        IndicatorSeparator: () => null
                                    },
                                    menuPosition: "fixed"
                                }}
                                placeholder="Select"
                                onChange={group => {
                                    setSelectedAgentDeploymentGroup(group);
                                }}
                                value={selectedAgentDeploymentGroup}
                            />
                        </Box>
                    </Grid>
                )}
                {errorMsg && (
                    <StriimMessageBox
                        customCardStyles={{
                            ...classes.errorBg,
                            ...classes.agentMsgBox
                        }}
                        heading={
                            <StriimTypography sx={classes.header} variant={"body4"}>
                                Testing Failed : {errorMsg}
                            </StriimTypography>
                        }
                        type="WARNING"
                        customIcon={
                            <Box
                                sx={{
                                    ...classes.iconStyles,
                                    ...classes.iconFrame
                                }}
                            >
                                <Error />
                            </Box>
                        }
                    ></StriimMessageBox>
                )}
                {connectionSuccess && (
                    <StriimMessageBox
                        customCardStyles={{
                            ...classes.successBg,
                            ...classes.agentMsgBox
                        }}
                        heading={
                            <StriimTypography sx={classes.header} variant={"body4"}>
                                Connection Successful
                            </StriimTypography>
                        }
                        type="SUCCESS"
                        customIcon={
                            <Box
                                sx={{
                                    ...classes.iconStyles,
                                    ...classes.iconFrame
                                }}
                            >
                                <Success />
                            </Box>
                        }
                    ></StriimMessageBox>
                )}
                {unavailableDriverDBType && (
                    <StriimMessageBox
                        customCardStyles={{
                            ...classes.errorBg,
                            ...classes.agentMsgBox
                        }}
                        heading={
                            <StriimTypography sx={classes.header} variant={"body4"}>
                                <MissingDriver databaseType={unavailableDriverDBType} />
                            </StriimTypography>
                        }
                        type="WARNING"
                        customIcon={
                            <Box
                                sx={{
                                    ...classes.iconStyles,
                                    ...classes.iconFrame
                                }}
                            >
                                <Error />
                            </Box>
                        }
                    ></StriimMessageBox>
                )}
            </Grid>
        </StriimModal>
    );
};

export default TestConnectionForAgent;