import $ from "jquery";
import _ from "underscore";
import api from "core/api/api";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import metaObjects from "core/services/metaStoreService/meta-objects";
import metaObjectIdentifier from "core/services/metaStoreService/meta-object-identifier";
import ignoredPatternsFilter from "app/components/common/ignoredPatternsFilter";

var metaStoreService = {};

metaStoreService.entities = {
    STREAM: "STREAM",
    SOURCE: "SOURCE",
    EXTERNALSOURCE: "EXTERNALSOURCE",
    CACHE: "CACHE",
    WINDOW: "WINDOW",
    CQ: "CQ",
    TARGET: "TARGET",
    WACTIONSTORE: "WACTIONSTORE",
    TYPE: "TYPE",
    FLOW: "FLOW",
    GROUP: "GROUP",
    PROPERTYTEMPLATE: "PROPERTYTEMPLATE",
    DG: "DG",
    APPLICATION: "APPLICATION",
    NAMESPACE: "NAMESPACE",
    VAULT: "VAULT",
    VISUALIZATION: "VISUALIZATION",
    SERVER: "SERVER",
    DASHBOARD: "DASHBOARD",
    PROPERTYSET: "PROPERTYSET",
    PROPERTYVARIABLE: "PROPERTYVARIABLE",
    EMBEDDINGGENERATOR: "EMBEDDINGGENERATOR",
    QUERY: "QUERY",
    OPENPROCESSOR: "OPENPROCESSOR",
    SYSALERTRULE: "SYSALERTRULE",
    EXTERNALCACHE: "EXTERNALCACHE",
    ROUTER: "ROUTER",
    USER: "USER",
    CONNECTIONPROFILE: "CONNECTIONPROFILE",
    AIAGENT: "AIAGENT",
    SENTINEL: "SENTINEL"
};

metaStoreService.fetchIdentifiersByType = function(type) {
    var deferred = new $.Deferred();
    api.getMetaObjectIdentifiersByType(type)
        .then(function(data) {
            deferred.resolve(new metaObjectIdentifier.Collection(data.filter(ignoredPatternsFilter)));
        })
        .fail(function(e) {
            deferred.reject(e);
        });

    return deferred.promise();
};

metaStoreService.fetchIdentifiersByTypes = function(types) {
    var deferred = new $.Deferred();

    var promises = [];
    _.each(types, function(type) {
        promises.push(metaStoreService.fetchIdentifiersByType(type));
    });

    $.when.apply($, promises).then(function() {
        deferred.resolve(
            [].concat.apply(
                [],
                Array.prototype.slice.call(arguments).map(function(item) {
                    return item.models;
                })
            )
        );
    });

    return deferred.promise();
};

metaStoreService.findById = function(id) {
    if (!_.isString(id)) {
        throw new Error(id + " not a string.");
    }

    var type = metaObjectConverter.getType(id);

    return new metaObjectIdentifier.Model({
        id: id,
        type: type
    }).fetchMetaObject();
};

metaStoreService.fetchCollection = function(type) {
    type = this.entities[type];

    if (type === undefined) {
        throw new Error(type + " Not registered in MetaStoreService.entities");
    }

    return new metaObjects[type].Collection().fetch();
};

metaStoreService.create = function(type) {
    return new metaObjects[type].Model();
};

metaStoreService.fetchCollections = function(types) {
    var deferred = new $.Deferred();

    api.getMetaObjectsByType(types)
        .then(function(data) {
            var returns = [];
            _.each(types, function(type) {
                var typeValues = data[type];
                var collection = new metaObjects[type].Collection(typeValues);
                returns.push(collection);
            });
            deferred.resolve.apply(this, returns);
        })
        .fail(function(e) {
            deferred.reject(e);
        });

    return deferred.promise();
};

export default metaStoreService;
