import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Box, Grid, InputAdornment } from "@mui/material";
import { Clear, Search } from "@mui/icons-material";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";

import { StriimButton, StriimIconButton, StriimInputField, StriimTypography } from "@striim/striim-ui";

import { FilterType } from "./app-list-filter-types";
import useStyles, { styles } from "./app-list-filters.styles";
import useDebounce from "../../../../../../hooks/useDebounce";
import StatusManagement from "src/status-management";
import appListService from "../../app-list-service";
import { getDefaultGroup } from "../app-group/app-groups";

/**
 * Component that displays filtering options along with 'selecting' info
 */
const AppListFilters = (
    { sourceApps, setFilteredApps, selectedGroup, filteredApps, setSelectedGroup, setIsFiltering },
    ref
) => {
    const navigate = useNavigate();
    const searchInputRef = useRef();
    const classes = useStyles();
    const [statusValues, setStatusValues] = useState([]);
    const [namespaceValues, setNamespaceValues] = useState([]);
    const [sourceValues, setSourceValues] = useState([]);
    const [targetValues, setTargetValues] = useState([]);
    const [userSearchInput, setUserSearchInput] = useState("");
    const [availableAppStatuses, setAvailableAppStatuses] = useState();
    const [availableNamespaces, setAvailableNamespaces] = useState();
    const [availableAppSources, setAvailableAppSources] = useState();
    const [availableAppTargets, setAvailableAppTargets] = useState();
    const [showMoreToggled, setShowMoreToggled] = useState(false);
    const selectedFilters = new Map();
    useImperativeHandle(ref, () => ({
        clearFilters() {
            clearFilters();
        },
        filter() {
            setFilters();
        }
    }));

    const clearFilters = () => {
        searchInputRef.current.value = "";
        setUserSearchInput("");
        setStatusValues([]);
        setNamespaceValues([]);
        setSourceValues([]);
        setTargetValues([]);
        setFiltersDebounced();
    };

    const getField = useCallback((apps, fieldName, useDisplayName = true) => {
        const values = apps.map(app => app[fieldName]).sort();
        const options = values.map(value => {
            return { label: useDisplayName ? StatusManagement.getStatusDisplayName(value) : value, value: value };
        });
        return _.uniqBy(options, "value");
    }, []);

    const setOrDeleteFilter = (condition, filter) => {
        if (!condition && !filter) {
            return;
        }
        condition
            ? selectedFilters.set(filter.toString(), filter.callbackFunction)
            : selectedFilters.delete(filter.toString());
    };

    const sourceTargetSort = (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

    useEffect(() => {
        setAvailableAppStatuses(getField(sourceApps, "flowStatus"));
        setAvailableNamespaces(getField(sourceApps, "nsName", false));
    }, [filteredApps, sourceApps]);

    useEffect(() => {
        if (!sourceApps) {
            return;
        }

        const sourceMetrics = [];
        const targetMetrics = [];
        sourceApps.map(app => app?.sources).map(metrics => metrics.forEach(metric => sourceMetrics.push(metric)));
        sourceApps.map(app => app?.targets).map(metrics => metrics.forEach(metric => targetMetrics.push(metric)));

        const getUniqueSortedCollection = collection =>
            _.uniqBy(collection, "adapter-name")
                .sort(sourceTargetSort)
                .map(component => {
                    return { label: component["adapter-name"], value: component["adapter-name"] };
                });

        setAvailableAppSources(() => getUniqueSortedCollection(sourceMetrics));
        setAvailableAppTargets(() => getUniqueSortedCollection(targetMetrics));
    }, [sourceApps, filteredApps]);

    const setFilters = () => {
        const groupFilter = FilterType.Group(selectedGroup);
        const userInputFilter = FilterType.UserInput(userSearchInput);
        const statusesFilter = FilterType.Statuses(statusValues.map(statusValue => statusValue.value));
        const namespacesFilter = FilterType.Namespaces(namespaceValues.map(namespace => namespace.value));
        const sourcesFilter = FilterType.Sources(sourceValues.map(source => source.value));
        const targetsFilter = FilterType.Targets(targetValues.map(target => target.value));
        let theFilteredApps = Array.from(sourceApps);

        setOrDeleteFilter(selectedGroup, groupFilter);
        setOrDeleteFilter(statusValues.length, statusesFilter);
        setOrDeleteFilter(userSearchInput.length, userInputFilter);
        setOrDeleteFilter(namespaceValues.length, namespacesFilter);
        setOrDeleteFilter(sourceValues.length, sourcesFilter);
        setOrDeleteFilter(targetValues.length, targetsFilter);

        appListService.setAppListFilters({
            userSearchInput,
            statusValues,
            namespaceValues,
            sourceValues,
            targetValues
        });

        // if we're just filtering by group (by default) it's not considered filtering
        if (selectedFilters.size <= 1) {
            setIsFiltering(false);
            setFilteredApps(theFilteredApps.filter(groupFilter.callbackFunction));
            return;
        }
        for (const filterEntry of selectedFilters) {
            setIsFiltering(true);
            // filter on filterEntry's value as it's returned as key/value
            theFilteredApps = theFilteredApps.filter(filterEntry[1]);
        }
        setFilteredApps(theFilteredApps);
    };

    const setFiltersDebounced = useDebounce(setFilters, 300);

    useEffect(() => {
        setFiltersDebounced();
    }, [sourceApps, selectedGroup]);

    useEffect(() => {
        const filters = appListService.getAppListFilters();
        setUserSearchInput(filters?.userSearchInput ?? "");
        setStatusValues(filters?.statusValues || []);
        setNamespaceValues(filters?.namespaceValues || []);
        setSourceValues(filters?.sourceValues || []);
        setTargetValues(filters?.targetValues || []);
        // if hidden filter has values, show them
        if (filters?.sourceValues.length || filters?.targetValues.length) {
            setShowMoreToggled(true);
        }
    }, []);

    useEffect(() => {
        if (
            selectedGroup?.name !== "All" &&
            (userSearchInput ||
                namespaceValues.length > 0 ||
                sourceValues.length > 0 ||
                targetValues.length > 0 ||
                statusValues.length > 0)
        ) {
            setSelectedGroup(getDefaultGroup(sourceApps));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSearchInput, namespaceValues, sourceValues, targetValues, statusValues]);

    const onCreateAppClick = () => {
        navigate("/create-app");
    };

    return (
        <>
            <Box className={classes.wrapper}>
                <Grid container item xs={12} className={classes.container}>
                    <Grid item xs={3} className={`${classes.appsHeader} ${classes.column} ${classes.heightFitContent}`}>
                        <Box component={StriimTypography} variant="h1" style={{ margin: 0 }}>
                            Apps
                        </Box>
                        <StriimButton
                            variant="text"
                            onClick={() => {
                                setShowMoreToggled(!showMoreToggled);
                            }}
                            data-test-id="show-more-toggle"
                        >
                            {showMoreToggled ? "Show less" : "Show more"}
                        </StriimButton>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid container item xs={4}>
                            <Box
                                className={`${classes.searchByAppNameContainer} ${classes.column}`}
                                data-test-id="search-app-by-name"
                            >
                                <StriimInputField
                                    placeholder="Search By App name"
                                    inputRef={searchInputRef}
                                    value={userSearchInput}
                                    onChange={searchValue => {
                                        searchInputRef.current.value = searchValue;
                                        setUserSearchInput(searchValue);
                                        setFiltersDebounced();
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search opacity={0.45} />
                                            </InputAdornment>
                                        ),
                                        ...(searchInputRef.current?.value?.length && {
                                            endAdornment: (
                                                <StriimIconButton
                                                    variant="primary"
                                                    sx={styles.endAdornmentContainer}
                                                    onClick={() => {
                                                        searchInputRef.current.value = "";
                                                        setUserSearchInput("");
                                                        setFiltersDebounced();
                                                    }}
                                                    data-test-id="search-app-name-clear-button"
                                                >
                                                    <Clear sx={styles.iconButton} />
                                                </StriimIconButton>
                                            )
                                        })
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid container item xs={4}>
                            <Box
                                className={`${classes.searchByStatus} ${classes.column}`}
                                data-test-id="search-app-by-status"
                            >
                                <StriimInputField
                                    select
                                    SelectProps={{
                                        options: availableAppStatuses,
                                        maxMenuHeight: "2000px",
                                        isMulti: true,
                                        components: {
                                            IndicatorSeparator: () => null
                                        }
                                    }}
                                    placeholder="Status"
                                    onChange={statuses => {
                                        setStatusValues(statuses);
                                        setFiltersDebounced();
                                    }}
                                    value={statusValues}
                                />
                            </Box>
                        </Grid>
                        <Grid container item xs={4}>
                            <Box
                                className={`${classes.column} ${classes.searchByNamespace}`}
                                data-test-id="search-app-by-namespace"
                            >
                                <StriimInputField
                                    select
                                    SelectProps={{
                                        options: availableNamespaces,
                                        isMulti: true,
                                        components: {
                                            IndicatorSeparator: () => null
                                        }
                                    }}
                                    placeholder="Namespace"
                                    onChange={namespaces => {
                                        setNamespaceValues(namespaces);
                                        setFiltersDebounced();
                                    }}
                                    value={namespaceValues}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={3}
                        justifyContent="space-between"
                        alignItems="center"
                        className={`${classes.nowrap} ${classes.heightFitContent}`}
                    >
                        <Grid item container alignItems="center">
                            <StriimButton
                                variant="text"
                                onClick={clearFilters}
                                className={classes.nowrap}
                                data-test-id="search-app-clear-button"
                            >
                                Clear all
                            </StriimButton>
                        </Grid>
                        <Grid item>
                            <StriimButton
                                className={classes.button}
                                onClick={onCreateAppClick}
                                variant="primary"
                                startIcon={<AddIcon />}
                            >
                                Create App
                            </StriimButton>
                        </Grid>
                    </Grid>
                </Grid>
                {showMoreToggled && (
                    <Grid container item xs={12} className={`${classes.lowerContainer} ${classes.column}`}>
                        <Grid item xs={3} className={classes.column}></Grid>
                        <Grid container item xs={12} style={{ flexWrap: "nowrap" }}>
                            <Grid item xs={4} className={classes.column}>
                                <Box className={`${classes.searchByAppNameContainer} ${classes.column}`}></Box>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={4}
                                className={`${classes.column} ${classes.searchBySource}`}
                                data-test-id="search-app-by-source"
                            >
                                <StriimInputField
                                    sx={styles.inputField}
                                    select
                                    SelectProps={{
                                        options: availableAppSources,
                                        maxMenuHeight: "2000px",
                                        isMulti: true,
                                        components: {
                                            IndicatorSeparator: () => null
                                        }
                                    }}
                                    placeholder="Sources"
                                    onChange={sources => {
                                        setSourceValues(sources);
                                        setFiltersDebounced();
                                    }}
                                    value={sourceValues}
                                />
                            </Grid>
                            <Grid container item xs={4} className={classes.column} data-test-id="search-app-by-target">
                                <StriimInputField
                                    sx={styles.inputField}
                                    select
                                    SelectProps={{
                                        options: availableAppTargets,
                                        maxMenuHeight: "2000px",
                                        isMulti: true,
                                        components: {
                                            IndicatorSeparator: () => null
                                        }
                                    }}
                                    placeholder="Targets"
                                    onChange={targets => {
                                        setTargetValues(targets);
                                        setFiltersDebounced();
                                    }}
                                    value={targetValues}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={3}></Grid>
                    </Grid>
                )}
            </Box>
        </>
    );
};

export default forwardRef(AppListFilters);
