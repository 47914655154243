import React, { useState, useEffect, useMemo } from "react";
import { Clear, Search } from "@mui/icons-material";
import { Box, Grid, InputAdornment } from "@mui/material";
import { StriimButton, StriimChip, StriimIconButton, StriimInputField, StriimTypography } from "@striim/striim-ui-v2";
import { components } from "react-select";
import { styles } from "./table-filters.styles";
import useDebounce from "../../../../hooks/useDebounce";
import _ from "lodash";
import getAvailableSources from "../../source-list-utils";

interface Filters {
    searchQuery: string;
    selectedSource: string | null;
    selectedNamespace: string | null;
    selectedStatus: string | null;
}

interface TableFiltersProps {
    sourceApps: AppProps[];
    setTableData: React.Dispatch<React.SetStateAction<AppProps[]>>;
    selectedRows: AppProps[];
    onFilterChange: () => void;
    filters: Filters;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

const TableFilters: React.FC<TableFiltersProps> = ({
    sourceApps,
    setTableData,
    selectedRows,
    onFilterChange,
    filters,
    setFilters
}) => {
    const hasFilters = useMemo(
        () => filters.searchQuery || filters.selectedSource || filters.selectedNamespace || filters.selectedStatus,
        [filters]
    );
    const [availableAppSources, setAvailableAppSources] = useState<{ label: string; value: string }[]>([]);

    useEffect(() => {
        if (!sourceApps) {
            return;
        }

        setAvailableAppSources(getAvailableSources(sourceApps));
    }, [sourceApps]);

    const namespaceList = [...new Set(sourceApps.map(app => app.nsName))].map(nsName => ({
        label: nsName,
        value: nsName
    }));

    const statusList = [...new Set(sourceApps.map(app => app.flowStatus))].map(status => ({
        label: status,
        value: status
    }));

    const filterApps = () => {
        setTableData(
            sourceApps.filter(app => {
                const matchesSearch = app.name.toLowerCase().includes(filters.searchQuery?.toLowerCase());
                const matchesSource =
                    !filters.selectedSource ||
                    app.sources.some(source => source["adapter-name"] === filters.selectedSource);
                const matchesNamespace = !filters.selectedNamespace || app.nsName === filters.selectedNamespace;
                const matchesStatus = !filters.selectedStatus || app.flowStatus === filters.selectedStatus;
                return matchesSearch && matchesSource && matchesNamespace && matchesStatus;
            })
        );
    };

    const setFiltersDebounced = useDebounce(filterApps, 500);

    const handleFilterChange = (field: string, value: any) => {
        onFilterChange();
        setFilters(prevFilters => ({ ...prevFilters, [field]: value }));
    };

    const clearSearch = () => {
        if (filters.searchQuery) {
            onFilterChange();
            setFilters(prevFilters => ({ ...prevFilters, searchQuery: "" }));
            setFiltersDebounced();
        }
    };

    const handleClearFilters = () => {
        onFilterChange();
        setFilters({
            searchQuery: "",
            selectedSource: null,
            selectedNamespace: null,
            selectedStatus: null
        });
        setTableData(sourceApps);
    };

    return (
        <Grid container height={80} gap={1}>
            <Grid container height={36} justifyContent="space-between" alignItems="center">
                <Box sx={styles.titleContainer}>
                    <StriimTypography variant="h2" color="primary.900">
                        Select the Apps
                    </StriimTypography>
                    {selectedRows.length > 0 && (
                        <StriimChip
                            type="tag-chip"
                            variant="default"
                            label={`${selectedRows.length} selected`}
                            hideAvatar
                        />
                    )}
                </Box>
                <StriimInputField
                    sx={{ width: 356 }}
                    placeholder="Search by App name"
                    value={filters.searchQuery}
                    onChange={searchValue => {
                        handleFilterChange("searchQuery", searchValue);
                        setFiltersDebounced();
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search opacity={0.45} />
                            </InputAdornment>
                        ),
                        endAdornment: filters.searchQuery ? (
                            <StriimIconButton
                                variant="primary"
                                sx={styles.endAdornmentContainer}
                                onClick={() => {
                                    clearSearch();
                                }}
                            >
                                <Clear sx={styles.iconButton} />
                            </StriimIconButton>
                        ) : null
                    }}
                />
            </Grid>
            <Grid container height={36} justifyContent="space-between">
                <Grid container width={710} gap={2}>
                    <StriimInputField
                        id="data-test-id-all-sources"
                        name="sources"
                        select
                        placeholder="All Sources"
                        sx={styles.autoWidth}
                        value={availableAppSources.find(option => option.value === filters.selectedSource) || ""}
                        onChange={option => {
                            handleFilterChange("selectedSource", option?.value);
                            setFiltersDebounced();
                        }}
                        SelectProps={{
                            options: availableAppSources,
                            components: {
                                Option: componentProps => (
                                    <components.Option {...componentProps}>
                                        <Grid display="flex" alignItems="center" gap={1}>
                                            <div>{componentProps.data.label}</div>
                                        </Grid>
                                    </components.Option>
                                )
                            }
                        }}
                    />
                    <StriimInputField
                        id="data-test-id-namespace"
                        name="namespace"
                        select
                        placeholder="All Namespaces"
                        sx={styles.autoWidth}
                        value={namespaceList.find(option => option.value === filters.selectedNamespace) || ""}
                        onChange={option => {
                            handleFilterChange("selectedNamespace", option?.value);
                            setFiltersDebounced();
                        }}
                        SelectProps={{
                            options: namespaceList,
                            components: {
                                Option: componentProps => (
                                    <components.Option {...componentProps}>
                                        <Grid display="flex" alignItems="center" gap={1}>
                                            <div>{componentProps.data.label}</div>
                                        </Grid>
                                    </components.Option>
                                )
                            }
                        }}
                    />
                    <StriimInputField
                        id="data-test-id-status"
                        name="status"
                        select
                        placeholder="All Statuses"
                        sx={styles.autoWidth}
                        value={statusList.find(option => option.value === filters.selectedStatus) || ""}
                        onChange={option => {
                            handleFilterChange("selectedStatus", option?.value);
                            setFiltersDebounced();
                        }}
                        SelectProps={{
                            options: statusList,
                            components: {
                                Option: componentProps => (
                                    <components.Option {...componentProps}>
                                        <Grid display="flex" alignItems="center" gap={1}>
                                            <div>{componentProps.data.label}</div>
                                        </Grid>
                                    </components.Option>
                                )
                            }
                        }}
                    />
                </Grid>
                <StriimButton
                    variant="text"
                    data-test-id="clear-filters"
                    onClick={handleClearFilters}
                    disabled={!hasFilters}
                >
                    Clear filters
                </StriimButton>
            </Grid>
        </Grid>
    );
};

export default TableFilters;
