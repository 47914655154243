import React, { createContext, useContext, useState, Dispatch, SetStateAction, useEffect } from "react";
import { StriimTheme } from "@striim/striim-ui";
import { sourceInfoType, targetInfoType } from "./target/Types";
import localStorageUtils from "../../../app/components/appwizard/component-wizards/wizards/source/database-reader/local-storage-utils";

type WizardContextProps = {
    currentStep: string;
    setCurrentStep?: Dispatch<SetStateAction<string>>;
    wizardAppName?: string;
    setWizardAppName?: Dispatch<SetStateAction<{}>>;
    sourceInfo?: sourceInfoType;
    setSourceInfo?: Dispatch<SetStateAction<{}>>;
    cdcSourceInfo?: sourceInfoType;
    setCdcSourceInfo?: Dispatch<SetStateAction<{}>>;
    isAutomatedILCDC?: boolean;
    setIsAutomatedILCDC?: Dispatch<SetStateAction<boolean>>;
    targetInfo?: targetInfoType;
    setTargetInfo?: Dispatch<SetStateAction<{}>>;
    cdcTargetInfo?: targetInfoType;
    setCdcTargetInfo?: Dispatch<SetStateAction<{}>>;
    wizardType?: string;
    setWizardType?: Dispatch<SetStateAction<{}>>;
    templateID?: string;
    setTemplateID?: Dispatch<SetStateAction<string>>;
};

export const WizardContext = createContext<WizardContextProps>({ currentStep: "" });

export const useTarget = () => useContext(WizardContext);

type WizardProviderProps = {
    children: JSX.Element;
};

const WizardProvider = ({ children }: WizardProviderProps) => {
    const [currentStep, setCurrentStep] = useState<string>();
    const [wizardAppName, setWizardAppName] = useState<string>();
    const [sourceInfo, setSourceInfo] = useState<sourceInfoType>();
    const [cdcSourceInfo, setCdcSourceInfo] = useState<sourceInfoType>();
    const [targetInfo, setTargetInfo] = useState<targetInfoType>();
    const [cdcTargetInfo, setCdcTargetInfo] = useState<targetInfoType>();
    const [isAutomatedILCDC, setIsAutomatedILCDC] = useState<boolean>();
    const [wizardType, setWizardType] = useState<string>();
    const [templateID, setTemplateID] = useState<string>();

    useEffect(() => {
        const targetDetails = { targetInfo, cdcTargetInfo, sourceInfo, cdcSourceInfo };
        localStorageUtils.setItem(localStorageUtils.getTargetKey(wizardAppName), targetDetails);
    }, [targetInfo, cdcTargetInfo, sourceInfo, cdcSourceInfo]);

    return (
        <WizardContext.Provider
            value={{
                currentStep,
                setCurrentStep,
                wizardAppName,
                setWizardAppName,
                sourceInfo,
                setSourceInfo,
                cdcSourceInfo,
                setCdcSourceInfo,
                isAutomatedILCDC,
                setIsAutomatedILCDC,
                cdcTargetInfo,
                setCdcTargetInfo,
                targetInfo,
                setTargetInfo,
                wizardType,
                setWizardType,
                templateID,
                setTemplateID
            }}
        >
            <StriimTheme preselector="">{children}</StriimTheme>
        </WizardContext.Provider>
    );
};

export default WizardProvider;
