import React, { useEffect, useState } from "react";
import metaObjectHelper from "app/components/common/editor/meta-object-editors/metaObjectHelper";
import { Box } from "@material-ui/core";
import { StriimTypography } from "@striim/striim-ui";
import useStyles from "./component-icon-tooltip.styles";
const ComponentIconTooltip = ({ component, appId }) => {
    const [desc, setState] = useState();
    useEffect(() => {
        const getDesc = () => {
            metaObjectHelper.descriptionResolver(component, appId).then(desc => {
                setState(desc);
            });
        };
        if (component) getDesc();
    }, []);

    const componentName = component.name;
    const classes = useStyles();
    return (
        <Box className={classes.tooltipText}>
            <StriimTypography className={classes.name} variant="body">
                {componentName}
            </StriimTypography>
            <StriimTypography className={classes.desc} variant="caption">
                {desc}
            </StriimTypography>
        </Box>
    );
};

export default ComponentIconTooltip;
