import dbTableMetaFilterControl from "app/components/common/editor/control/specific-meta-filter/db-table-meta-filter";

var Transformation = {};

Transformation.OnlyDbSources = true;

Transformation.createColumnControl = function () {
    return dbTableMetaFilterControl
        .extend({
            hideLabel: true,
            hidden: false,
        })
        .create();
};

Transformation.buildTql = function (tqlBuilder, columns) {
    tqlBuilder.addWAEventWildcard();

    columns.forEach(function (column) {
        if (!column.column) {
            return;
        }

        tqlBuilder.addMetaFilteringStatement("TableName", "=", column.column);
    });
};

export default Transformation;
