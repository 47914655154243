import { observer } from "mobx-react-lite";
import useStyles from "./filebrowser.styles";
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { StriimButton, StriimInputField, StriimTypography } from "@striim/striim-ui";
import growl from "app/components/common/growl.js";

const FileUploadBox = observer(({ localStore }) => {
    const classes = useStyles();

    const [selectedFile, setSelectedFile] = useState("No File Selected");

    function setFile(file) {
        // Don't allow uploading hidden files
        if (file.name[0] === ".") {
            growl.error("Cannot upload hidden files");
            return;
        }
        setSelectedFile(file.name);
        localStore.setFileToUpload(file);
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    {localStore.directoryToUpload ? (
                        <StriimTypography variant={"subtitle2"}>
                            Uploading to: {localStore.directoryToUpload}
                        </StriimTypography>
                    ) : (
                        <StriimTypography variant={"subtitle2"}>Please select the location to upload.</StriimTypography>
                    )}
                </Grid>

                <Grid item xs={8}>
                    <StriimInputField value={selectedFile} disabled={true} />
                </Grid>
                <Grid item xs={4}>
                    <StriimButton
                        variant="secondary"
                        component="label"
                        className={classes.chooseButton}
                        fullWidth={true}
                    >
                        Select File
                        <input
                            type="file"
                            hidden
                            value=""
                            onChange={e => {
                                e.target?.files?.length ? setFile(e.target.files[0]) : null;
                            }}
                        />
                    </StriimButton>
                </Grid>
            </Grid>
        </div>
    );
});

export default FileUploadBox;
