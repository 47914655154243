import _ from "underscore";
import HighChartsMixin from "app/components/dashboard/visualization/charts/mixins/highcharts";

export default _({}).extend(HighChartsMixin, {
    // Note: be sure to pass in a deep-cloned config object!
    setConfiguration: function (_config) {
        var theme = _config.theme;
        var config = _config.config;
        if (!theme) {
            return console.warn("No theme provided.");
        }

        var type = config.type || "column";
        // Translate our configuration to Highcharts configuration
        this.chartConfig = {
            chart: {
                type: type,
                backgroundColor: theme.chart.background,
                animation: false,
            },
            credits: {
                enabled: false,
            },
            tooltip: this.setToolTipConfig(),
            plotOptions: {
                column: {
                    stacking: "normal",
                },
                series: {
                    dataLabels: {
                        enabled: config.show_data_labels,
                        verticalAlign: config.type === "column" ? "top" : "middle",
                        allowOverlap: config.show_all_labels,
                    },
                    borderWidth: 0,
                    animation: false,
                    cursor: "pointer",
                    events: {
                        hide: function (e) {
                            this.trigger("series-hidden", e);
                        }.bind(this),
                        show: function (e) {
                            this.trigger("series-shown", e);
                        }.bind(this),
                    },
                    point: {
                        events: {
                            click: function (e) {
                                var element = e.currentTarget.options.rowData;
                                this.trigger("datapoint:click", element);
                            }.bind(this),
                        },
                    },
                },
            },
            legend: {
                enabled: false,
            },
            title: {
                text: config.title,
                style: {
                    display: "none",
                },
            },
            xAxis: this._formatXAxis(config.xAxis, theme),
            yAxis: this._formatYAxis(config.yAxis, theme),
        };

        /* var seriesCategoriesAndLegendData = this.generateSeriesCategoriesAndLegendData(config, this.chartConfig, this.model.dataSource.fieldList);
         this.setChartConfigWithSeriesCategoryAndChartData(config, seriesCategoriesAndLegendData);*/
        for (var i = 0; i < config.series.length; i++) {
            var seriesCategoriesAndLegendData = this.generateSeriesCategoriesAndLegendData(
                i,
                config,
                this.chartConfig,
                this.model.dataSource.fieldList
            );
            this.setChartConfigWithSeriesCategoryAndChartData(i, config, seriesCategoriesAndLegendData);
        }

        if (this.chartConfig.legend.enabled === true) {
            this.chartConfig.xAxis[0].labels.enabled = false;
        }
    },
    setToolTipConfig: function () {
        var generateToolTipConfig = this.generateToolTipConfig();
        generateToolTipConfig.followPointer = true;
        generateToolTipConfig.distance = 32;
        return generateToolTipConfig;
    },
});
