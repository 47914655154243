export const styles = {
    title: {
        display: "flex",
        alignItems: "center",
        color: "primary.700",
        "&:hover": {
            cursor: "pointer"
        },
        "& svg": {
            color: "greyscale.900",
        },
    },
    documentationIcon: {
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: "20px",
            height: "20px",
            fill: "none",
            marginLeft: "8px"
        }
    },
    content: {
        marginTop: 1,
        display: 'inline-block'
    },
    text: {
        overflowWrap: 'break-word',
        width: '100%',
        letterSpacing: '0.24px',
        fontFamily: 'Inter'

    }
};
