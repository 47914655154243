import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TYPES } from "core/services/monitorService.js";
import metaStoreService from "core/services/metaStoreService/meta-store-service.js";
import api from "core/api/api";

import { Grid, CircularProgress } from "@material-ui/core";
import AppsMonitorTile from "./monitor_tiles/apps_monitor_tile";
import CPUMonitorTile from "./monitor_tiles/cpu_monitor_tile";
import MemMonitorTile from "./monitor_tiles/mem_monitor_tile";
import NodesMonitorTile from "./monitor_tiles/nodes_monitor_tile";
import getMonitorData from "./system_health_monitor_service";
import config from "../../../../config";
import useStyles from "./system_health_monitor.styles";

const SystemHealthMonitor = ({ setUpdatedTime }) => {
    const classes = useStyles();
    // declare all states
    const [loaded, setLoaded] = useState(false);
    const [cpuRate, setCpuRate] = useState(0);
    const [memRate, setMemRate] = useState(0);
    const [numRunningNodes, setNumRunningNodes] = useState(0);
    const [ttlNodes, setTtlNodes] = useState(0);
    const [nodeData, setNodeData] = useState([]);
    const [ttlApps, setTtlApps] = useState(0);
    const [appsData, setAppsData] = useState({});

    // set monitoring data and update states
    const setMonitorData = model => {
        setCpuRate(model?.cpuRate);
        setMemRate(model?.memRate);
        setNumRunningNodes(model?.numRunningNodes);
        setTtlNodes(model?.ttlNodes);
        setNodeData(model?.nodeData);
        setTtlApps(model?.ttlApps);
        setAppsData({
            terminated: model?.numTerminatedApps,
            running: model?.numRunningApps,
            halted: model?.numHaltedApps,
            others: model?.numOthersApps,
            otherStatusList: model?.otherAppsList
        });
        setUpdatedTime(Date.now());
    };

    const fetchResponse = async () => {
        // get id Strings for nodes, apps
        let objectIdentifiers = await metaStoreService.fetchIdentifiersByTypes(["SERVER", "APPLICATION"]);
        let idStrings = [];
        objectIdentifiers.forEach(objectIdentifier => {
            idStrings.push(objectIdentifier.get("id"));
        });
        // get data for ids
        return await api.getMonitoringStatsForComponentsWithoutTableInfo(idStrings, TYPES.FULL);
    };

    useEffect(() => {
        const updateData = async () => {
            const apiResponse = await fetchResponse();
            setMonitorData(getMonitorData(apiResponse));
            // set loaded to true to display data
            if (!loaded) {
                setLoaded(true);
            }
            // update data every 5 mins
            return setInterval(async () => {
                const apiResponse = await fetchResponse();
                setMonitorData(getMonitorData(apiResponse));
            }, config.time.homepageIntervalMontoringData);
        };
        const interval = updateData();
        // cleanUp fn to remove interval callback
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * View
     */
    if (loaded) {
        return (
            <Grid item container className={classes.monitorContainer}>
                <Grid item xs>
                    <AppsMonitorTile total={ttlApps} appsData={appsData} />
                </Grid>
                <Grid item xs>
                    <CPUMonitorTile value={cpuRate} data={nodeData} />
                </Grid>
                <Grid item xs>
                    <MemMonitorTile value={memRate} data={nodeData} />
                </Grid>
                <Grid item xs>
                    <NodesMonitorTile running={numRunningNodes} total={ttlNodes} data={nodeData} />
                </Grid>
            </Grid>
        );
    }
    return <CircularProgress />;
};
export default SystemHealthMonitor;

SystemHealthMonitor.propTypes = {
    setUpdatedTime: PropTypes.func.isRequired
};
