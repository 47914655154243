import _ from "underscore";
import BaseControl from "../base-control";
import React, { createRef } from "react";
import ReactDOM from "react-dom/client";
import { StriimTheme, StriimFormBuilder, FIELD_TYPES } from "@striim/striim-ui";
import App from "app";
import FileBrowser from "../../../../../../src/generic/filebrowser-v2/filebrowser";
import { convertNameToHumanRedable } from "app/components/common/editor/control-resource-resolver";
import ConnectionProfileSelector from "../../../../../../src/generic/connection-profile-selector/connection-profile-selector";
import SourceInfoComponent from "src/modules/wizards/common/source-info-message-box/source-info-box.jsx";
import { PRESERVED_NAMES } from "core/constants";

var Model = BaseControl.Model.extend({
    defaults: {
        value: null
    }
});

var View = BaseControl.View.extend({
    template: _.template(`<div>   </div>`),

    getViewValue: function() {
        return this.model.get("value"); // No op, we're doing it in react.
    },

    setViewValue: function() {
        // No op, we're doing it in react.
    },
    showError: function(errors) {
        const errorBox = $("#dirty-fields");
        const errorBoxContainer = $("#error-box");
        if (!errors || _.isEmpty(errors)) {
            errorBoxContainer.hide();
        } else {
            errorBoxContainer.show();
            const getErrorFieldLabels = errorFields => {
                let labels = errorFields.map(field => this.labelMap[field]);
                return labels.join(", ");
            };
            errorBox.html(getErrorFieldLabels(Object.keys(errors)));
        }
    },
    valueChanged: function(value) {
        Object.keys(value)?.forEach(val => {
            if (typeof value[val] === "object") {
                this.options.populateModel.set(val, value[val].value);
            } else {
                this.options.populateModel.set(val, value[val]);
            }
        });
        this.forceChanged();
    },

    initialize: function(options) {
        this.value = options.populateModel.previousAttributes();
        App.vent.trigger("appwizard:back:hide");
        App.vent.trigger("appwizard:next:enable");
        this.labelMap = {};
        options.formFields.forEach(field => {
            this.labelMap[field.name] = field?.label ?? convertNameToHumanRedable(field.name);
        });
    },
    validate: async function() {
        this?.formRef?.current.submitForm();
        const errors = await this?.formRef?.current.validateForm();
        this.showError(errors);
        return _.isEmpty(errors);
    },
    onShow: function() {
        try {
            const root = ReactDOM.createRoot(this.$el[0]);
            const formRef = createRef();
            this.formRef = formRef;
            root.render(
                <FormComponent
                    enabled={this.model.get("enabled")}
                    formFields={this.options.formFields}
                    hasAutoSchemaConversion={this.options.hasAutoSchemaConversion}
                    isAutomatedWizard={this.options.isAutomatedILCDC}
                    value={this.value}
                    onChange={value => this.valueChanged(value)}
                    formRef={formRef}
                    sourceID={this.options.sourceID}
                />
            );
        } catch (e) {
            console.error("Error rendering SalesforceReaderWizardEditor", e);
        }
    }
});

function buildTextField() {
    return _.extend({}, BaseControl, {
        View: View,
        Model: Model
    });
}

export default buildTextField();

const FormComponent = props => {
    const { hasAutoSchemaConversion, isAutomatedWizard, value, onChange, formFields, formRef, sourceID } = props;
    return (
        <StriimTheme>
            <SourceInfoComponent sourceID={sourceID} />
            <StriimFormBuilder
                formProps={{ hasAutoSchemaConversion, isAutomatedWizard }}
                onChange={onChange}
                initialFieldValues={value}
                data={formFields}
                validateOnChange={true}
                formRef={formRef}
                customFields={{
                    [FIELD_TYPES.FILE]: props => <FileBrowser directoryOnly={false} {...props} />,
                    [FIELD_TYPES.CONNECTIONPROFILE]: props => (
                        <ConnectionProfileSelector useMenuPortalTarget={true} {...props} />
                    )
                }}
                preservedNames={PRESERVED_NAMES}
            />
        </StriimTheme>
    );
};
