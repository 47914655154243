interface Steps {
    [key: string]: any;
}
interface stepDetails {
    [key: string]: any;
}

export const stepDetails: stepDetails = {
    ConfigureTarget: { id: "Configure Target", stepName: "configure-target" },
    ValidateTarget: { id: "Validate Target", stepName: "validate-target", transient: true },
    Review: { id: "Review", stepName: "review" }
};

export const Steps: Steps = {
    ConfigureTarget: stepDetails.ConfigureTarget.id,
    ValidateTarget: stepDetails.ValidateTarget.id,
    Review: stepDetails.Review.id
};

export const getStepID = urlstepName => {
    const requiredKey = Object.keys(stepDetails).filter(key => {
        return stepDetails[key]["stepName"] === urlstepName;
    });
    return Steps[requiredKey];
};

export const getCurrentStepHeader = (step: string, target?: string) => {
    switch (step) {
        case Steps.ConfigureTarget:
            return target ? `Configure ${target || ""} as Target` : `Configure Target`;
        case Steps.ValidateTarget:
            return `Validating Target and Checking Environment`;
        case Steps.Review:
            return Steps.Review;
        default:
            return step;
    }
};

export const getCurrentStepSubHeader = (step: string, target?: string) => {
    switch (step) {
        case Steps.ConfigureTarget:
            return `Please fill in the connection details so we can check if Striim is able to reach your ${target ||
                ""} target`;
        case Steps.ValidateTarget:
            return `Before we can proceed to the next steps, we'll need to make sure Striim is able to access your target.
            Striim will fetch additional information about your target to help you in the next steps.
            Depending on the size of your target and the network latency, this can take upto few minutes.`;
        case Steps.Review:
        default:
            return "";
    }
};
