export const styles = {
    chartLegend: {
        color: "greyscale.900",
        fontFamily: 'Inter',
        fontSize: 10,
        fontWeight: 500,
        letterSpacing: '0.24px',
        lineHeight: '14px'
    },
    bottomBorder: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    sensitiveDataCount: {
        color: '#5D54AE'
    },
    tooltipWrapper: {
        borderRadius: "4px",
        border: "1px solid",
        borderColor: "greyscale.200",
        backgroundColor: "additional.white",
        boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.05)",
        padding: 1,
        minWidth: '150px'
    },
    tooltipText: {
        color: "greyscale.700",
        fontWeight: 500,
        fontFamily: 'Inter',
        letterSpacing: '0.24px',
        textTransform: 'capitalize'
    }
}