import React, { useRef, useState } from "react";
import { appendComponentToBody } from "./printable-helper";
import growl from "../../../../app/components/common/growl";

const Footer = () => (
    <div className="divFooter" style={{ display: "none" }}>
        <img src={"app/images/logo-collapsed.svg"} alt="striim-logo" />
    </div>
);

const Printable = ({
    component: Component,
    name,
    id = "section-to-print",
    fetchReportData,
    appendToBody = false,
    buttonComponent,
    additionalProps
}) => {
    const styleRef = useRef(null);

    // Styles to be applies while print
    const printStyles = `@media print {
        @page {
            size: A4;
            margin: 0px 0px 75px 0px;
            @bottom-right{
                margin-right: 30px;
                font-family: Inter;
                font-size: 10px;
                content: "Page " counter(page) "/" counter(pages); 
            }
            @bottom-left {
                margin-left: 30px;
                content:url(app/images/logo-collapsed.svg);
            }
        }
        body, * :not(#${id} *){
            visibility: hidden;
            margin: 0px !important;
            background: white;
            overflow: visible !important;
        }
        #${id} {
            visibility: visible;
            display: block !important;
            width: 100% !important;
            visibility: visible;
            position: absolute;
            left: 0;
            top: 0;
        }
        .pagebreak { page-break-before: always; }
    }`;

    const [reportData, setReportData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        if (isLoading) return;
        setIsLoading(true);
        try {
            const data = await fetchReportData();
            if (appendToBody) {
                const componentToPrint = (
                    <div id={id} style={{ display: "none" }}>
                        <Component reportData={data} triggerDownload={onDownloadClick} />
                        <Footer />
                    </div>
                );
                appendComponentToBody(data, componentToPrint);
            }
            setReportData(data);
        } catch (err) {
            growl.error(err, "Error generating report");
            setIsLoading(false);
        }
    };

    const onDownloadClick = async () => {
        // Adds the print styles to the DOM
        const prevTitle = document.title;
        const beforePrint = () => {
            // The title of the page will be taken as the name of the orinted document.
            document.title = name;
            styleRef.current.textContent = printStyles;
        };
        // Removes the print styles to the DOM
        const afterprint = () => {
            document.title = prevTitle;
            styleRef.current.textContent = null;
            window.removeEventListener("beforeprint", beforePrint);
            window.removeEventListener("afterprint", afterprint);
            if (appendToBody) {
                let divElement = document.getElementById("printable-container");
                document.body.removeChild(divElement);
            } else {
                setReportData(null);
            }
            setIsLoading(false);
        };
        window.addEventListener("beforeprint", beforePrint);
        window.addEventListener("afterprint", afterprint);
        window.print();
    };

    return (
        <div style={{ display: "inline" }}>
            <style ref={styleRef} />
            <div
                onClick={() => {
                    fetchData();
                }}
            >
                {buttonComponent}
            </div>
            {!appendToBody && (
                <div id={id} style={{ display: "none" }}>
                    {reportData && (
                        <Component reportData={reportData} triggerDownload={onDownloadClick} {...additionalProps} />
                    )}
                </div>
            )}
        </div>
    );
};

export default Printable;
