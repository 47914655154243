import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { StriimTypography, StriimList, StriimListItem, StriimTooltip } from "@striim/striim-ui";
import { ListItemText, Box } from "@mui/material";
import securityService from "core/services/securityService/securityService";
import SegmentationChip from "src/modules/common/segmentation/segmentationChip";
import { getVariantForLicense } from "src/modules/common/segmentation/segmentation.consts";
import { useStyles, sxStyles } from "./dropdown-account-content.styles";
import App from "app";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import USER_PLAN_ROUTES from "src/modules/user-plan/routes.json";

const DropdownAccountContent = ({ accountState }) => {
    const classes = useStyles();
    // @TODO remove and insert this data in backboneStore
    const userData = App.user;
    const [user, setUser] = useState(userData);
    const [version, setVersion] = useState("");
    const [isBidirectional, setIsBirectional] = useState(false);
    const timer = useRef();
    const showBilling = !securityService.isDeveloperLicense();

    const delayedClose = () => {
        timer.current = setTimeout(() => {
            accountState[1](false);
        }, 300);
    };

    const hasOpened = () => {
        if (timer.current) {
            clearTimeout(timer);
        }
    };
    useEffect(() => {
        securityService.isBidirectional().done(isBirectional => {
            setIsBirectional(isBirectional);
        });
    }, []);

    useEffect(() => {
        setUser(App.user);
        let server = propertyTemplateService.servers.first();
        setVersion(server.get("version"));
    }, [userData]);
    // @TODO remove and insert this data in backboneStore

    return (
        <StriimList
            sx={{
                minWidth: "152px",
                maxWidth: "192px"
            }}
            onMouseLeave={() => {
                delayedClose();
            }}
            onMouseEnter={() => {
                hasOpened();
            }}
        >
            <StriimListItem>
                <ListItemText
                    primary={
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            <StriimTooltip title={user?.name}>
                                <StriimTypography sx={sxStyles.userNameListItem} variant="h3">
                                    {user?.name}
                                </StriimTypography>
                            </StriimTooltip>
                            {isBidirectional ? (
                                <StriimTypography sx={{ margin: 0 }} variant="body">
                                    Bi-Directional Server
                                </StriimTypography>
                            ) : (
                                <></>
                            )}
                            <SegmentationLicenseChip />
                        </Box>
                    }
                />
            </StriimListItem>
            {showBilling && (
                <StriimListItem component={Link} to={USER_PLAN_ROUTES.plan}>
                    <ListItemText primary="Account and Billing" />
                </StriimListItem>
            )}
            <StriimListItem
                onClick={() => {
                    window.location.hash = "#/manage-striim/files";
                }}
            >
                <ListItemText primary="My Files" />
            </StriimListItem>
            <StriimListItem
                id={"nav--logout"}
                onClick={() => {
                    securityService.logout();
                    if (user.originType === "SAAS") {
                        window.location.hash = "#ended";
                    } else {
                        window.location.hash = "#";
                    }
                }}
            >
                <ListItemText primary="Logout" />
            </StriimListItem>
        </StriimList>
    );
};

export default DropdownAccountContent;

const SegmentationLicenseChip = () => {
    const variant = getVariantForLicense(securityService.currentSegmentationLicense);

    return <SegmentationChip variant={variant} style={{ width: "fit-content" }} />;
};
