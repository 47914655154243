import useStyles from "./striim-units-tile/striim-units-tile.styles";
import NoData from "./striim-unit-usage-breakdown/components/no-data-component";
import NoDataIllustration from "../../../../../../../../app/images/no-data-illustration.svg";
import React from "react";

export const NoBillingData = ({text}) => {
    const classes = useStyles();

    return (
        <NoData
            text={text}
            Icon={NoDataIllustration}
            classNameIcon={classes.noDataIllustration}
            typographyProps={{
                variant: "body4",
                color: "greyscale.700"
            }}
        />
    );
}
