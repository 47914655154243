import dateLib from "core/utils/date-utils";
import numeral from "numeral";

const numberFormat = "0.[00]";

const loadInitialData = async api => {
    // in previous implementation event rates was used to get "baseRate"
    // and then it was used on Event Volume component
    // There is no more event volume so we don't need to call this
    const eventRates = null; //await api.meteringGetAdapterRates();

    const allUsageSummary = await api.meteringGetUsageSummaries();
    const striimCredits = await api.meteringGetRemainingCredits();
    let usageSummaries = await api.meteringGetUsageSummaries();
    usageSummaries = usageSummaries || [null, null];
    usageSummaries = usageSummaries.length > 1 ? usageSummaries : [null, usageSummaries[0]];

    const result = {
        allUsageSummary: allUsageSummary,
        awardedCredits: formatStriimCredits(striimCredits),
        thisMonthSummary: formatMonthSummary(usageSummaries[usageSummaries.length - 1]),
        lastMonthSummary: formatMonthSummary(usageSummaries[usageSummaries.length - 2]),
        basicMinEventUsage: getBasicMinEventUsage(eventRates)
    };

    return result;
};

const getBasicMinEventUsage = eventRates => {
    if (!eventRates || eventRates.length < 1) {
        return 0;
    }

    return eventRates[0].baseRate;
};

const formatMonthSummary = usageSummary => {
    if (!usageSummary) {
        return null;
    }

    return {
        startTime: usageSummary.startTime,
        endTime: usageSummary.endTime,
        balance: numeral(usageSummary.usageBalance.toFixed(2)).format(numberFormat),
        credit: numeral(usageSummary.usageCredit.toFixed(2)).format(numberFormat),
        total: numeral(usageSummary.usageTotal.toFixed(2)).format(numberFormat),
        unit: usageSummary.usageUnit,
        productTier: usageSummary.productTier
    };
};

const formatStriimCredits = awardedCredits => {
    if (!awardedCredits) {
        return [];
    }

    const dataFromServer = [...(awardedCredits.usageCredits || []), ...(awardedCredits.consumptionCredits || [])];
    const result = dataFromServer.map(x => ({
        name: x.adapter || "Usage credits",
        amount: `${x.creditRemaining} ${x.unit}`,
        dates: `${formatDate(x.beginAt)} - ${formatDate(x.expiryAt)}`
    }));

    return result;
};

const formatDate = dateString => {
    return dateLib(dateString).format("MM/DD/YYYY");
};

export default loadInitialData;
