import $ from "jquery";
import utils from "core/utils";
import flowUtils from "../flowUtils";
import allowedTargetNodeTypesResolver from "../allowedTargetNodeTypesResolver";
import predefinedTransformationComponents from "app/components/flow/designer/predefinedComponents/predefinedTransformationComponents";
import { mousePos, onDrop } from "app/components/common/drag-and-drop";

var DesignerSelectionHandler = function(graphNodes, onSelectionEnd) {
    var _this = this;

    this.isSelecting = false;

    var onDropElement = function(context, data) {
        if (graphNodes.containsNewComponent()) {
            return;
        }
        var allowedTypes = allowedTargetNodeTypesResolver.getAllowedParents(data.type, data.name);
        predefinedTransformationComponents.isAvailable(data.name, data.model.metaObject).then(available => {
            if (allowedTypes.indexOf(data.model.metaObject.type) < 0 || !available) {
                return;
            }

            var newNode = graphNodes._createNewNode(data.model, data.type, undefined, data.name);
            newNode.isSelected = true;
            graphNodes.selectNode(newNode);
        });
    };

    this.activateDragDrop = function(dndType, dndComponentName) {
        var allowedTypes = allowedTargetNodeTypesResolver.getAllowedParents(dndType, dndComponentName);

        //TODO: should we use same logic for WactionStore inside getAllowedParents?
        if (dndType === utils.ENTITIES.WACTIONSTORE) {
            allowedTypes = [];
        }

        graphNodes.children.each(function(view) {
            predefinedTransformationComponents.isAvailable(dndComponentName, view.model.metaObject).then(available => {
                if (
                    view.model.metaObject.type === "empty-node-model" ||
                    (allowedTypes.indexOf(view.model.metaObject.type) >= 0 && available)
                ) {
                    view.setDragDropState(flowUtils.DRAG_DROP_STATE.ACTIVE);
                } else {
                    view.setDragDropState(flowUtils.DRAG_DROP_STATE.NOT_ALLOWED);
                }
            });
        });
    };

    this.deactivateDragDrop = function() {
        graphNodes.children.each(function(view) {
            view.setDragDropState(view.setDragDropState(flowUtils.DRAG_DROP_STATE.INACTIVE));
        });
    };

    $.drop({
        multi: true
    }); // we can drop on designer or on specific node

    graphNodes.on("childview:on-drag-drop", onDropElement);

    this.setup = function() {
        //
        //  selecting multiple nodes
        //
        const flowDesignerCanvasElement = graphNodes.$el.closest("div.right-content");
        $(flowDesignerCanvasElement)
            .drag("start", function() {
                // when new node is added then cannot do multiselect
                if (graphNodes.isTempNodeAdded()) {
                    return false;
                }

                $("div.selection").remove();
                graphNodes.$el.addClass("selection-mode");
                _this.isSelecting = true;
                return $('<div class="selection" data-type="selection" />')
                    .css("opacity", 0.65)
                    .appendTo(document.body);
            })
            .drag(function(ev, dd) {
                $(dd.proxy).css({
                    top: Math.min(mousePos.pageY, dd.startY),
                    left: Math.min(mousePos.pageX, dd.startX),
                    height: Math.abs(mousePos.pageY - dd.startY),
                    width: Math.abs(mousePos.pageX - dd.startX)
                });
            })
            .drag("end", function(ev, dd) {
                // jquery.event-drag-drop should preventDefault when a drag&drop operation is in progress so
                // that the 'click' event is not triggered on the designer.
                // this is all caused by L200 in the jquery.event-drag-drop.js, touchmove event
                // does not seem to work fine in Internet Explorer
                setTimeout(function() {
                    _this.isSelecting = false;
                }, 0);
                $(dd.proxy).remove();
            });

        onDrop(
            "predefined-component",
            (droppedData, droppedRect) => {
                const newNodePosition = {
                    top: droppedRect.top - flowDesignerCanvasElement.offset().top,
                    left: droppedRect.left - flowDesignerCanvasElement.offset().left
                };

                // skip if dropped outside jsplumb canvas
                if (newNodePosition.top < 0 || newNodePosition.left < 0) {
                    return;
                }

                const newNode = graphNodes._createNewNode(
                    null,
                    droppedData.componentType,
                    newNodePosition,
                    droppedData.name
                );
                newNode.isSelected = true;
                graphNodes.selectNode(newNode);
            },
            flowDesignerCanvasElement[0]
        );
    };

    this.dispose = function() {
        graphNodes.$el.closest("div.right-content").off("drop");
        graphNodes.$el.closest("div.right-content").off("drag");
    };
};

export default DesignerSelectionHandler;
