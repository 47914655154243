/**
 * List of missing drivers messages
 */

const oracleMessage = `1. Download driver from <a href="https://www.oracle.com/database/technologies/appdev/jdbc-downloads.html" rel="noopener noreferrer" target="_blank">oracle.com</a>
2. Upload the driver`;

const generalMessage = `Drivers are missing. Please contact with system administrator.`;

export default {
    Oracle: oracleMessage,
    General: generalMessage
};
