import React from "react";
import { SvgIcon } from "@mui/material";
import { SidebarStates } from "./sidebar.consts";
import { StriimButton, StriimTypography } from "@striim/striim-ui-v2";
import { styles } from "../sentinel-monitor.styles";
import { LastRefresh, Expand, SidebarCollapse, Minimize } from "../../../../../generic/icon/customIcons";
import WithTooltip from "../../../../../generic/tooltip/tooltip";

interface SidebarProps {
    sidebarState: string;
    setSidebarState: React.Dispatch<React.SetStateAction<string>>;
}

const OpenSidebar: React.FC<SidebarProps> = ({ sidebarState, setSidebarState }) => {
    const Action = sidebarState === SidebarStates.CLOSED ? SidebarStates.OPENED : SidebarStates.CLOSED;
    const rotateStyles = sidebarState === SidebarStates.CLOSED ? "rotate(0deg)" : "rotate(180deg)";

    const OpenButton = (
        <StriimButton
            startIcon={<SvgIcon component={SidebarCollapse} sx={styles.icon} />}
            sx={{
                ...styles.iconButton,
                transform: rotateStyles
            }}
            onClick={() => setSidebarState(Action)}
        />
    );

    if (sidebarState === SidebarStates.CLOSED) return WithTooltip(OpenButton, "Insights about Sentinel Configurations");
    return OpenButton;
};

const ExpandSidebar: React.FC<SidebarProps> = ({ sidebarState, setSidebarState }) => {
    const Icon = sidebarState === SidebarStates.EXPANDED ? Minimize : Expand;
    const Action = sidebarState === SidebarStates.EXPANDED ? SidebarStates.OPENED : SidebarStates.EXPANDED;
    return (
        <StriimButton
            startIcon={<SvgIcon component={Icon} sx={styles.icon} />}
            sx={styles.iconButton}
            onClick={() => setSidebarState(Action)}
        />
    );
};

const RefreshedStats: React.FC = () => {
    return (
        <StriimTypography variant="caption3" sx={styles.refreshedTime}>
            <LastRefresh /> every min
        </StriimTypography>
    );
};

export { SidebarProps, OpenSidebar, ExpandSidebar, RefreshedStats };
