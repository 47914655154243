import getControlResource, { convertNameToHumanRedable } from "./control-resource-resolver";
import Marionette from "marionette";
import FormField from "./form-field";

var Controls = {};

Controls.View = Marionette.CollectionView.extend({
    childView: FormField.View,
    childViewContainer: "ul",

    initialize: function() {
        var formFields = new FormField.Collection();
        var labels = this.model.labels;
        var viewOptions = this.model.viewOptions;
        let databaseType = this.model.data.get("DatabaseProviderType");

        this.model.controls.forEach(function(control) {
            if (!control?.view) {
                return;
            }
            let key = (control.propertyName || "").toLocaleLowerCase();
            const resource = getControlResource(
                control.propertyName,
                control.resourceNamespace,
                control.view.sourceAdapters,
                databaseType
            );

            var label = "";
            let cssClassName = "";
            var description = "";
            var defaultHelpLinkKey = undefined;
            if (labels[key] !== undefined) {
                var custom_label = labels[key];
                label = custom_label.name || "";
                cssClassName = label;
                description = custom_label.description || "";
            } else if (resource !== undefined) {
                label = resource.name || "";
                cssClassName = label;
                description = resource.description || "";
                defaultHelpLinkKey = resource.defaultHelpLinkKey || undefined;
            } else {
                label = convertNameToHumanRedable(control.propertyName);

                // there are styles based on propertyName
                cssClassName = control.propertyName;
                description = null;
            }

            if (viewOptions) {
                if (!control.view) {
                    throw new Error("View is not defined for: " + control.propertyName);
                }
                control.view.customViewOptions = viewOptions[key];
            }

            if (defaultHelpLinkKey) {
                control.view.customViewOptions = control.view.customViewOptions || {};
                control.view.customViewOptions.defaultHelpLinkKey = defaultHelpLinkKey;
            }

            control.resourceLabel = label;

            var formFieldModel = new FormField.Model({
                propertyName: control.propertyName,
                controlView: control.view,
                label: label,
                description: description,
                cssClassName: cssClassName,
                segmentationInfo: control.segmentationInfo,
                isRequired: control.isRequired
            });
            formFields.push(formFieldModel);
        });

        this.collection = formFields;
    }
});

export default Controls;
