import _ from "underscore";
import { useEffect, useRef, useMemo } from "react";

const useDebounce = (callback, interval) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        const func = () => {
            ref.current?.();
        };

        return _.debounce(func, interval);
    }, []);

    return debouncedCallback;
};

export default useDebounce;
