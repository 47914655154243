import { makeStyles } from "@material-ui/core/styles";


export default makeStyles(({ spacing, typography }) => ({
    appTileBorder: {
        height: "4px",
        "&.appStatus-terminated, &.appStatus-invalid": {
            backgroundColor: (theme5) => theme5.palette.critical.main,
            opacity: 1
        },
        "&.appStatus-deploy_failed, &.appStatus-not_enough_servers": {
            backgroundColor: (theme5) => theme5.palette.critical.main,
            opacity: 1
        },
        "&.appStatus-created, &.appStatus-deploying": {
            backgroundColor: (theme5) => theme5.palette.greyscale.main,
            opacity: 1
        },
        "&.appStatus-deployed, &.appStatus-starting, &.appStatus-stopped": {
            backgroundColor: (theme5) => theme5.palette.secondary.main,
            opacity: 1
        },
        "&.appStatus-quiesced, &.appStatus-completed": {
            backgroundColor: (theme5) => theme5.palette.secondary.main,
            opacity: 1
        },
        "&.appStatus-halted": {
            backgroundColor: (theme5) => theme5.palette.warning.main,
            opacity: 1
        },
        "&.appStatus-running, &.appStatus-stopping": {
            backgroundColor: (theme5) => theme5.palette.success.main,
            opacity: 1
        }
    },
    progressIcon: {
        color: (theme5) => theme5.palette.secondary.main
    },
    appTileWideStatus: {
        height: "100%",
        "&.appStatus-terminated, &.appStatus-invalid": {
            backgroundColor: (theme5) => theme5.palette.critical.main,
            opacity: 1
        },
        "&.appStatus-deploy_failed, &.appStatus-not_enough_servers": {
            backgroundColor: (theme5) => theme5.palette.critical.main,
            opacity: 1
        },
        "&.appStatus-created, &.appStatus-deploying": {
            backgroundColor: (theme5) => theme5.palette.greyscale.main,
            opacity: 1
        },
        "&.appStatus-deployed, &.appStatus-starting, &.appStatus-stopped": {
            backgroundColor: (theme5) => theme5.palette.secondary.main,
            opacity: 1
        },
        "&.appStatus-quiesced, &.appStatus-completed": {
            backgroundColor: (theme5) => theme5.palette.secondary.main,
            opacity: 1
        },
        "&.appStatus-halted": {
            backgroundColor: (theme5) => theme5.palette.warning.main,
            opacity: 1
        },
        "&.appStatus-running, &.appStatus-stopping": {
            backgroundColor: (theme5) => theme5.palette.success.main,
            opacity: 1
        }
    },
    wideTileStatus: {
        position: "absolute",
        top: 0,
        left: 0,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        // overflow: "hidden",
        width: "10px",
        height: "100%"
    },
    wideModeBorder: {
        margin: spacing(-2, -3),
        overflow: "hidden"
    },

    chipRoot: {
        padding: spacing(0, 1, 0, 0.5),
        height: 20,
        marginTop: spacing(0.25),
        backgroundColor: "transparent",
        "&.appStatus-created, &.appStatus-deploying": {
            color: (theme5) => theme5.palette.greyscale[600],
        },
        "&.appStatus-deployed, &.appStatus-starting, &.appStatus-stopped": {
            color: (theme5) => theme5.palette.secondary.main,
        },
        "&.appStatus-quiesced, &.appStatus-completed": {
            color: (theme5) => theme5.palette.secondary.main,
        },
        "&.appStatus-terminated, &.appStatus-invalid": {
            color: (theme5) => theme5.palette.critical.main,
        },
        "&.appStatus-deploy_failed, &.appStatus-not_enough_servers": {
            color: (theme5) => theme5.palette.critical.main,
        },
        "&.appStatus-halted": {
            color: (theme5) => theme5.palette.greyscale.main_dark,
        },
        "&.appStatus-running, &.appStatus-stopping, &.appStatus-quiescing": {
            color: (theme5) => theme5.palette.success.main,
        }
    },
    chipLabel: {
        ...typography.caption,
        color: "inherit",
        fontWeight: 600
    },
    chipIcon: {
        width: 14,
        height: 14,
        "&.appStatus-created, &.appStatus-deploying": {
            fill: (theme5) => theme5.palette.greyscale.main
        },
        "&.appStatus-deployed, &.appStatus-starting, &.appStatus-stopped": {
            fill: (theme5) => theme5.palette.secondary.main
        },
        "&.appStatus-quiesced, &.appStatus-completed": {
            fill: (theme5) => theme5.palette.secondary.main
        },
        "&.appStatus-terminated, &.appStatus-invalid": {
            fill: (theme5) => theme5.palette.critical.main
        },
        "&.appStatus-deploy_failed, &.appStatus-not_enough_servers": {
            fill: (theme5) => theme5.palette.critical.main
        },
        "&.appStatus-halted": {
            fill: (theme5) => theme5.palette.warning.main
        },
        "&.appStatus-running, &.appStatus-stopping, &.appStatus-quiescing": {
            fill: (theme5) => theme5.palette.success.main
        }
    }
}));
