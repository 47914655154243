import App from "app";
import React, { useEffect, useMemo, useRef } from "react";
import {
    EventsProcessedDataForApps,
    SDIDrillDownOccurrenceProps,
    SensitiveDataOccurrences
} from "../../../guardian/sentinel-monitor/service/sentinel-monitor.types";
import { Box, Grid } from "@mui/material";
import {
    AppFilterSection,
    DisclaimerSection,
    IdentifiersSection,
    LogoSection,
    SDISection,
    Section,
    SummarySection,
    TimeFilterSection
} from "../../components/report-components";
import { StriimTypography } from "@striim/striim-ui-v2";
import { EventsProcessedChart, SDIChart } from "../../components/report-charts/sentinel-report-charts";
import { formatNumber } from "../../../../user-plan/pages/user-plan/tabs/striim-units-usage/components/striim-unit-usage-breakdown/components/utils";
import { updateIdentifiersData } from "../../../guardian/sentinel-monitor/service/sentinel-monitor-utils";

type GlobalReportProps = {
    reportData?: {
        selectedApps: string[];
        sentinelsCount: Object[];
        timeRange: { from: string; to: string };
        sourceIcons: Object[];
        targetIcons: Object[];
        previewData: any;
        eventsProcessedChartData: any;
        sensitiveDataOccurrencesChartData: any;
        sensitiveDataOccurrences: SensitiveDataOccurrences;
        eventsProcessedDataForApps: EventsProcessedDataForApps;
        SDIDrillDownOccurrences: SDIDrillDownOccurrenceProps;
        SDIWithApplicationDetails: any;
        legendData: object[];
        isOneHourTimeFrame: boolean;
    };
    triggerDownload?: () => {};
};

const AppReport = ({ reportData, triggerDownload }: GlobalReportProps) => {
    const chartRef = useRef();

    const {
        selectedApps,
        sentinelsCount,
        sourceIcons,
        targetIcons,
        timeRange,
        previewData,
        eventsProcessedChartData,
        sensitiveDataOccurrencesChartData,
        sensitiveDataOccurrences,
        legendData,
        isOneHourTimeFrame
    } = reportData;

    useEffect(() => {
        setTimeout(() => triggerDownload(), 500);
    }, [reportData]);

    const identifiersData = useMemo(
        () => updateIdentifiersData(sensitiveDataOccurrences.sensitiveDataIdentifierOccurrences),
        [sensitiveDataOccurrences]
    );
    const totalEventsWithIdentifier = identifiersData.reduce((acc, curr) => {
        return curr.eventsWithIdentifier ? acc + curr.eventsWithIdentifier : acc;
    }, 0);

    return (
        <Grid>
            <LogoSection />
            <Grid m={5}>
                <AppFilterSection
                    apps={selectedApps}
                    sourceIcons={sourceIcons}
                    targetIcons={targetIcons}
                    sentinelCount={sentinelsCount}
                    username={App?.user?.name ?? ""}
                />
                <TimeFilterSection selectedRange={timeRange} />
                <DisclaimerSection type={"Sentinel"} />
                <SummarySection
                    totalEvents={previewData.eventsProcessed}
                    sensitiveEvents={previewData.eventsWithSensitiveData}
                    occurences={previewData.occurrencesOfSensitiveData}
                />
                <IdentifiersSection
                    sensitiveDataIdentifierOccurrences={sensitiveDataOccurrences.sensitiveDataIdentifierOccurrences}
                    appLevel={true}
                />
                <Section>
                    <Box mt={3} mb={0}>
                        <StriimTypography variant="h2">Detailed Breakdown of Sensitive Data Processed</StriimTypography>
                    </Box>
                    <EventsProcessedChart
                        eventsProcessedChartData={eventsProcessedChartData}
                        eventsProcessed={previewData.eventsProcessed}
                        eventsWithSensitiveData={previewData.eventsWithSensitiveData}
                        eventsWithoutSensitiveData={previewData.eventsWithoutSensitiveData}
                        timeRange={timeRange}
                        isOneHourTimeFrame={isOneHourTimeFrame}
                        legendData={legendData[0].data}
                    />
                </Section>
                <SDIChart
                    sensitiveDataOccurrences={sensitiveDataOccurrences}
                    sensitiveDataOccurrencesChartData={sensitiveDataOccurrencesChartData}
                    timeRange={timeRange}
                    isOneHourTimeFrame={isOneHourTimeFrame}
                    legendData={legendData[1].data}
                />
                <SDISection
                    sensitiveDataIdentifierOccurrences={sensitiveDataOccurrences.sensitiveDataIdentifierOccurrences}
                    sensitiveEvents={previewData.eventsWithSensitiveData}
                    occurences={previewData.occurrencesOfSensitiveData}
                />
            </Grid>
        </Grid>
    );
};

export default AppReport;
