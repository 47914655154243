import metaStoreService from "../../../../../../core/services/metaStoreService/meta-store-service";
import { SentinelMonitorServiceProps } from "./sentinel-moniter-service";
import {
    allSentinelsEventsProcessed,
    flowDesignerPreviewData,
    AppsWithSentinelsDashboard,
    SentinelsDetails,
    RunningAppsWithSentinels,
    eventsProcessedDataForApps,
    flowDesignerPreviewDataForIndividualSentinel,
    individualSentinelEventsProcessed,
    SDIDrillDownOccurrences,
    sensitiveDataOccurrences,
    allSentinelsSDIDrillDownEvents,
    sentinelsSDIDrillDownEvents,
    sdiDrilldownFindingsChart,
    SDIDrillDownEventsProcessedForApps,
    SDIDrillDownOccurrencesForApps,
    appSentinelsEventsProcessedChartData,
    SDIWithApplicationDetails
} from "./test-data";

function delayPromise<Type>(result: any): Promise<Type> {
    return new Promise(resolve => {
        setTimeout(() => resolve(result), 500);
    });
}

export const generateOneHourData = (from, to) => {
    console.log("from", from);
    console.log("from >", new Date(from));
    console.log("to", new Date(to));
    const data = [];
    //convert to seconds
    for (let timestamp = from / 1000; timestamp <= to / 1000; timestamp += 60) {
        const randomNumber = Math.floor(Math.random() * 100) + 1;
        data.push([timestamp, randomNumber]);
    }
    return data;
};

const generateOneDayData = (from, to) => {
    const data = [];
    //convert to seconds
    for (let timestamp = from / 1000; timestamp <= to / 1000; timestamp += 3600) {
        const randomNumber = Math.floor(Math.random() * 100) + 1;
        data.push([timestamp, randomNumber]);
    }
    return data;
};
export function getFakeSentinelMonitorService(): SentinelMonitorServiceProps {
    return {
        getRunningAppsWithSentinelsDashboard: () => {
            return delayPromise(AppsWithSentinelsDashboard);
        },
        getFlowDesignerPreview: async (entityType, namespace, name, fromTime, toTime) => {
            return delayPromise(
                entityType === metaStoreService.entities.APPLICATION
                    ? flowDesignerPreviewData
                    : flowDesignerPreviewDataForIndividualSentinel
            );
        },
        getAppSentinelsAIActivityEventsProcessed: async (namespace, appName, fromTime, toTime) => {
            return delayPromise(allSentinelsEventsProcessed);
        },
        getAppSentinelsEventsProcessedChartData: async (namespace, sentinelName, fromTime, toTime, isOneHour) => {
            const chartData = isOneHour ? generateOneHourData(fromTime, toTime) : generateOneDayData(fromTime, toTime);
            return delayPromise(appSentinelsEventsProcessedChartData(chartData));
        },
        // getSentinelEventsProcessed: async (namespace, sentinelName, fromTime, toTime, isOneHour) => {
        getSentinelEventsProcessed: async (namespace, sentinelName, fromTime, toTime) => {
            return delayPromise(individualSentinelEventsProcessed);
        },
        getRunningAppsSentinelsDetail: () => {
            return delayPromise(SentinelsDetails);
        },
        getRunningAppsWithSentinelsDetail: () => {
            return delayPromise(SentinelsDetails);
        },
        getRunningAppsWithSentinels: () => {
            // return delayPromise({}); //Uncomment this for empty state view
            return delayPromise(RunningAppsWithSentinels);
        },
        getAppsEventsProcessedData: async (appNames, fromTime, toTime, isOneHour = true) => {
            const chartData = isOneHour ? generateOneHourData(fromTime, toTime) : generateOneDayData(fromTime, toTime);
            return delayPromise(eventsProcessedDataForApps(chartData));
        },
        getSDIDrillDownEventsProcessedForApps: async (appNames, fromTime, toTime, sdi, isOneHour = true) => {
            const chartData = isOneHour ? generateOneHourData(fromTime, toTime) : generateOneDayData(fromTime, toTime);
            return delayPromise(SDIDrillDownEventsProcessedForApps(chartData));
        },
        getSDIDrillDownOccurrences: async (applications, fromTime, toTime, isOneHour = true) => {
            const chartData = isOneHour ? generateOneHourData(fromTime, toTime) : generateOneDayData(fromTime, toTime);
            return delayPromise(SDIDrillDownOccurrences(chartData));
        },
        getSDIDrillDownOccurrencesForApps: async (applications, fromTime, toTime, sdi, isOneHour = true) => {
            const chartData = isOneHour ? generateOneHourData(fromTime, toTime) : generateOneDayData(fromTime, toTime);
            return delayPromise(SDIDrillDownOccurrencesForApps(chartData));
        },
        getSensitiveDataOccurrencesForApps: async (applications, fromTime, toTime, isOneHour = true) => {
            const chartData = isOneHour ? generateOneHourData(fromTime, toTime) : generateOneDayData(fromTime, toTime);
            return delayPromise(sensitiveDataOccurrences(chartData));
        },

        getSensitiveDataOccurrences: async (entity, namespace, name, fromTime, toTime, isOneHour = true) => {
            const chartData = isOneHour ? generateOneHourData(fromTime, toTime) : generateOneDayData(fromTime, toTime);
            return delayPromise(sensitiveDataOccurrences(chartData));
        },
        getAppSentinelsSDIEventsProcessed: async (namespace, appName, fromTime, toTime, sdi, isOneHour = true) => {
            const chartData = isOneHour ? generateOneHourData(fromTime, toTime) : generateOneDayData(fromTime, toTime);
            return delayPromise(allSentinelsSDIDrillDownEvents(sdi, chartData));
        },
        getSentinelSDIDrillDownEvents: async (namespace, sentinelName, fromTime, toTime, sdi, isOneHour = true) => {
            const chartData = isOneHour ? generateOneHourData(fromTime, toTime) : generateOneDayData(fromTime, toTime);
            return delayPromise(sentinelsSDIDrillDownEvents(sdi, chartData));
        },
        getSDIDrillDownFindingsChartData: async (
            entityType,
            namespace,
            name,
            fromTime,
            toTime,
            sdi,
            isOneHour = true
        ) => {
            const chartData = isOneHour ? generateOneHourData(fromTime, toTime) : generateOneDayData(fromTime, toTime);
            return delayPromise(sdiDrilldownFindingsChart(chartData));
        },
        getSDIWithApplicationDetails: async (applications, fromTime, toTime) => {
            return delayPromise(SDIWithApplicationDetails());
        }
    };
}
