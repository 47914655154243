import React, { useRef, useEffect, useState, useCallback } from "react";
import {
    StriimInputField,
    StriimForm,
    StriimCheckbox,
    StriimControlLabel,
    StriimTheme,
    StriimTypography
} from "@striim/striim-ui";
import * as Yup from "yup";
import { Grid, Box } from "@mui/material";
import VaultsService from "./vaults-service";
import { mapOptions } from "../utils";
import { unitOptions, unitMultiplier } from "./utils";
import CollapsibleSection from "src/generic/collapsible-section/collapsible-section";
import { VaultTypes, httpMatch, numberMatch } from "./constants";

const sxStyles = {
    grid: {
        marginBottom: 2,
        justifyContent: "space-between"
    },
    container: {
        marginBottom: 2
    },
    advancedSettingGrid: {
        justifyContent: "space-around"
    }
};

const HashicorpVault = ({ setFormValues, setIsValid, dataForEdit }) => {
    const [options, setOptions] = useState([]);

    const [accessTokenInput, setAccessTokenInput] = useState(false);

    const values = dataForEdit || {
        name: "",
        nsName: "",
        properties: {
            VaultType: VaultTypes.HASHICORP_VAULT,
            ConnectionURL: "",
            Port: null,
            EngineName: "",
            PathToSecret: "",
            AccessToken: "",
            AutoRenew: false,
            AutoRenewCheckPeriod: 12,
            AutoRenewCheckPeriodUnit: { label: "hours", value: "h" },
            AutoRenewIncrement: "",
            AutoRenewIncrementUnit: null
        }
    };

    // TODO: optimization: find out why this is getting called multiple times per render
    const getOptions = useCallback(options => mapOptions(options), []);
    const nsOptions = getOptions(options);
    const formRef = useRef();
    const accessTokenRef = useRef(null);
    const [AutoRenewLocal, setAutoRenewLocal] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const theData = await VaultsService.getNamespaces();
            setOptions(theData?.models);
        }

        if (!options.length) {
            fetchData();
        }
    }, [options]);

    useEffect(() => {
        if (dataForEdit && dataForEdit.properties.AutoRenew) {
            setAutoRenewLocal(true);
        }
    }, [dataForEdit]);

    useEffect(() => {
        if (accessTokenInput) {
            accessTokenRef.current.focus();
        }
    }, [accessTokenInput]);

    const handleAcessTokenField = event => {
        const value = event.target.value;
        if (dataForEdit && value.length <= 0) {
            accessTokenRef.current.blur();
            setAccessTokenInput(false);
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        nsName: Yup.string().required("Required"),
        properties: Yup.object({
            ConnectionURL: Yup.string()
                .required("Required")
                .matches(httpMatch, "Please enter a valid URL"),
            Port: Yup.string()
                .required("Required")
                .matches(numberMatch, "Please use only numbers")
                .nullable(),
            EngineName: Yup.string().required("Required"),
            PathToSecret: Yup.string().required("Required"),
            AccessToken: dataForEdit ? null : Yup.string().required("Required"),
            AutoRenew: Yup.boolean(),
            AutoRenewIncrement: Yup.number()
                .positive("Must be a positive number")
                .when("AutoRenew", {
                    is: true,
                    then: Yup.number()
                        .required("Required")
                        .test("greater-than-checkPeriod", "Increment should be higher than check period.", function(
                            value
                        ) {
                            const {
                                AutoRenewCheckPeriod,
                                AutoRenewCheckPeriodUnit,
                                AutoRenewIncrementUnit
                            } = this.parent;

                            if (
                                AutoRenewCheckPeriod == null ||
                                AutoRenewCheckPeriodUnit == null ||
                                AutoRenewIncrementUnit == null
                            ) {
                                // If any of the properties are null, return true to bypass the test
                                return true;
                            }
                            return (
                                value * unitMultiplier[AutoRenewIncrementUnit.value] >=
                                AutoRenewCheckPeriod * unitMultiplier[AutoRenewCheckPeriodUnit.value]
                            );
                        })
                }),
            AutoRenewIncrementUnit: Yup.object()
                .nullable()
                .shape({
                    label: Yup.string().when("AutoRenewIncrement", {
                        is: val => val,
                        then: Yup.string().required()
                    }),
                    value: Yup.string().when("AutoRenewIncrement", {
                        is: val => val,
                        then: Yup.string().required()
                    })
                })
                .when("AutoRenewIncrement", {
                    is: val => val,
                    then: Yup.object().required("Required when value specified")
                })
                .default(null),
            AutoRenewCheckPeriod: Yup.number()
                .positive("Must be a positive number")
                .test("value-unit-pair", "Either clear the unit or provide a check period value", function(value) {
                    const { AutoRenewCheckPeriodUnit } = this.parent;
                    if (AutoRenewCheckPeriodUnit != null) {
                        return value != null;
                    }
                    return true;
                }),
            AutoRenewCheckPeriodUnit: Yup.object()
                .nullable()
                .shape({
                    label: Yup.string().when("AutoRenewCheckPeriod", {
                        is: val => val,
                        then: Yup.string().required()
                    }),
                    value: Yup.string().when("AutoRenewCheckPeriod", {
                        is: val => val,
                        then: Yup.string().required()
                    })
                })
                .when("AutoRenewCheckPeriod", {
                    is: val => val,
                    then: Yup.object().required("Required when value specified")
                })
                .default(null)
        })
    });

    return (
        <StriimTheme>
            <StriimForm
                values={values}
                validationSchema={validationSchema}
                formRef={formRef}
                validateOnMount={true}
                validateOnChange
            >
                {({ values, isValid, handleChange }) => {
                    setFormValues(values);
                    setIsValid(isValid);
                    return (
                        <>
                            <StriimInputField
                                required
                                name="name"
                                label="Name"
                                placeholder="Enter Vault name"
                                isFormElement
                                sx={sxStyles.container}
                                disabled={dataForEdit ? true : false}
                            />
                            <StriimInputField
                                required
                                name="nsName"
                                label="Namespace"
                                select
                                id="data-test-id-namespace-select"
                                placeholder="Namespace"
                                SelectProps={{ options: nsOptions, menuPosition: "fixed" }}
                                isFormElement
                                sx={sxStyles.container}
                                disabled={dataForEdit ? true : false}
                            />
                            <Grid container sx={{ ...sxStyles.grid, columnGap: 3 }}>
                                <Grid item xs={7}>
                                    <StriimInputField
                                        required
                                        name="properties.ConnectionURL"
                                        label="Connection URL"
                                        helperText='For example: "https://127.0.0.1"'
                                        isFormElement
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <StriimInputField
                                        required
                                        name="properties.Port"
                                        label="Port"
                                        isFormElement
                                        helperText="For example : 8200"
                                        onChange={event => {
                                            const value = event.target.value;

                                            if (!value) {
                                                values.properties.Port = "0";
                                            }
                                            setFormValues(values);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <StriimInputField
                                required
                                name="properties.EngineName"
                                label="Engine Name"
                                isFormElement
                                helperText='For example: "kv"'
                                sx={sxStyles.container}
                            />
                            <Grid container sx={sxStyles.grid}>
                                <Grid item xs={5}>
                                    <StriimInputField
                                        required
                                        name="properties.PathToSecret"
                                        label="Path to Secret"
                                        helperText='For example: "usinghttp"'
                                        isFormElement
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <div
                                        data-test-id="vaults-encrypted-data-field"
                                        onClick={() => {
                                            if (dataForEdit) {
                                                setAccessTokenInput(true);
                                            }
                                        }}
                                    >
                                        <StriimInputField
                                            required
                                            name="properties.AccessToken"
                                            label="Access Token"
                                            inputRef={accessTokenRef}
                                            isFormElement
                                            type="password"
                                            placeholder={dataForEdit ? "(encrypted)" : null}
                                            disabled={dataForEdit ? !accessTokenInput : false}
                                            previewPassword={!accessTokenRef?.current?.disabled}
                                            helperText={
                                                dataForEdit
                                                    ? "Click on the input field, to enter a new access token"
                                                    : 'For example : "abc123"'
                                            }
                                            onChange={event => {
                                                handleAcessTokenField(event);
                                            }}
                                            onBlur={event => {
                                                handleAcessTokenField(event);
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <CollapsibleSection
                                label={"Advanced configuration"}
                                data-test-id={"hashicorp-vault-advanced-configuration-section"}
                            >
                                <Box sx={sxStyles.container}>
                                    <StriimControlLabel
                                        label={
                                            <StriimTypography color="greyscale.800" variant="body4">
                                                Enable auto-renew
                                            </StriimTypography>
                                        }
                                        onChange={event => {
                                            const value = event.target.checked;
                                            if (!value) {
                                                values.properties.AutoRenewCheckPeriod = "12";
                                                values.properties.AutoRenewCheckPeriodUnit = {
                                                    label: "hours",
                                                    value: "h"
                                                };
                                                values.properties.AutoRenewIncrement = "";
                                                values.properties.AutoRenewIncrementUnit = null;
                                            }
                                            handleChange(event);
                                            setAutoRenewLocal(value);
                                        }}
                                        control={
                                            <StriimCheckbox
                                                name="properties.AutoRenew"
                                                isFormElement
                                                checked={values.properties.AutoRenew}
                                            />
                                        }
                                    />
                                    <br />
                                    <StriimTypography color="greyscale.700" variant="body4">
                                        Auto-renewal should be preconfigured in HashiCorp vault to renew your existing
                                        token in Striim
                                    </StriimTypography>
                                </Box>
                                <Grid container sx={sxStyles.grid}>
                                    <Grid item container sx={sxStyles.advancedSettingGrid} xs={6}>
                                        <Grid item xs={6}>
                                            <StriimInputField
                                                required
                                                name="properties.AutoRenewIncrement"
                                                label="Auto-Renew Increment"
                                                id="data-test-id-renew-increment"
                                                isFormElement
                                                helperText="Enter the auto-renewal duration"
                                                placeholder="Enter Increment Duration"
                                                disabled={!AutoRenewLocal}
                                                type={"number"}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <StriimInputField
                                                required
                                                name="properties.AutoRenewIncrementUnit"
                                                id="data-test-id-renew-increment-unit"
                                                select
                                                label="Unit"
                                                disabled={!AutoRenewLocal}
                                                SelectProps={{
                                                    options: unitOptions,
                                                    menuPosition: "fixed"
                                                }}
                                                isFormElement
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container sx={sxStyles.advancedSettingGrid} xs={6}>
                                        <Grid item xs={6}>
                                            <StriimInputField
                                                name="properties.AutoRenewCheckPeriod"
                                                id="data-test-id-renew-check-period"
                                                label="Auto-Renew Check Period"
                                                isFormElement
                                                helperText="Enter the check period(optional)"
                                                disabled={!AutoRenewLocal}
                                                type={"number"}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <StriimInputField
                                                name="properties.AutoRenewCheckPeriodUnit"
                                                id="data-test-id-renew-check-period-unit"
                                                label="Unit"
                                                select
                                                disabled={!AutoRenewLocal}
                                                SelectProps={{
                                                    options: unitOptions,
                                                    menuPosition: "fixed"
                                                }}
                                                isFormElement
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CollapsibleSection>
                        </>
                    );
                }}
            </StriimForm>
        </StriimTheme>
    );
};

export default HashicorpVault;
