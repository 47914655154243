import App from "app";
import api from "core/api/api";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import appExporter from "./appExporter";
import confirmationTemplate from "./templates/dropApplication.html";
import "app/components/common/_deprecated/form-mixin-dialog/dialog";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";

import exceptionsStoreApi from "app/components/flow/designer/app-exceptions/exception-store-api";

const ConfirmDropDialog = App.FormMixinDialog.View.extend({
    template: _.template(confirmationTemplate),

    ui: {
        exportButton: "#export-button",
        deleteExceptionsContainer: ".delete-exceptions-container",
        deleteExceptions: ".delete-exceptions",
    },

    events: {
        "click @ui.exportButton": "exportApplication",
        "change @ui.deleteExceptions": "onDeleteExceptionsChanged",
    },

    initialize: function (options) {
        options.bind_submit = true;
        options.submit_on_enter = true;
        options.bind_cancel = true;
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
    },

    onRender: function () {
        // This editor provides .modal-inner inside the modal, to help with padding
        this.$el.removeClass("modal-inner");
        this.$el.addClass("delete-application-dialog");

        if (this.model.get("showDeleteLogCheckbox")) {
            this.ui.deleteExceptionsContainer.removeClass("hidden-element");
        }
    },

    exportApplication: function () {
        appExporter.exportApplication(this.model.get("applicationName"));
    },

    serialize: function () {
        return {};
    },

    onDeleteExceptionsChanged: function (value) {
        this.model.set("doNotDeleteExceptions", this.ui.deleteExceptions.is(":checked"));
    },
});

ConfirmDropDialog.Controller = App.FormMixinDialog.Controller;

function showDialog(modalManager, applicationName) {
    const deferred = $.Deferred();

    exceptionsStoreApi
        .checkExceptionsEnabled(metaObjectConverter.convertShortNameToFullName(applicationName, "APPLICATION"))
        .then((isEnabled) => {
            const dialog = new ConfirmDropDialog({
                id: 1,
                model: new Backbone.Model({
                    applicationName: applicationName,
                    displayName: metaObjectConverter.getName(applicationName),
                    doNotDeleteExceptions: false,
                    showDeleteLogCheckbox: isEnabled,
                }),
            });

            const dialogView = modalManager.add(dialog);
            /*var controller =*/
            new ConfirmDropDialog.Controller({
                view: dialog,
            });

            dialog.on("form:submit", function (dialog) {
                dialogView.destroy();
                dialog.destroy();
                deferred.resolve({ doNotDeleteExceptions: this.model.get("doNotDeleteExceptions") });
            });

            dialog.on("cancel", function () {
                dialogView.destroy();
                dialog.destroy();
                deferred.resolve(false);
            });

            dialog.on("before:destroy", function () {
                modalManager.remove_all_modals();
            });
        });

    return deferred.promise();
}

export default showDialog;
