import React, { useState } from "react";
import TimeAgo from "react-timeago";
import { StriimButton, StriimTypography } from "@striim/striim-ui";
import { timeagoFormatter } from "../common";
import { useNavigate } from "react-router";

import HomepageCard from "src/modules/homepage/common/homepage_card";
import SystemHealthMonitor from "src/modules/homepage/systeam_health_overview/monitor/system_health_monitor";

const SystemHealthOverview = () => {
    const navigate = useNavigate();
    const [updatedTime, setUpdatedTime] = useState();

    const SystemHealthHeader = {
        title: (
            <>
                <StriimTypography display="inline" variant="h2">
                    {"System Health"}
                </StriimTypography>
                {updatedTime && (
                    <StriimTypography
                        display="inline"
                        variant="caption1"
                        color="greyscale.700"
                        sx={{ fontSize: "14px" }}
                    >
                        {" Updated "}
                        <TimeAgo date={updatedTime} formatter={timeagoFormatter} minPeriod={60} />
                    </StriimTypography>
                )}
            </>
        ),
        action: (
            <StriimButton
                variant="text"
                onClick={() => {
                    navigate("/monitor");
                }}
            >
                View Monitor
            </StriimButton>
        )
    };

    return (
        <HomepageCard header={SystemHealthHeader} content={<SystemHealthMonitor setUpdatedTime={setUpdatedTime} />} />
    );
};
export default SystemHealthOverview;
