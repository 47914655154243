import _ from "underscore";

/**
 *
 * For DEV-9549, it expects the Password_encrypted flag be set to true or false to notify the server if the Password field is already encrypted or not.
 * If it's already encrypted, the Password_encrypted field is set to true, so the back end does not encrypt it again.
 *
 */
export default class PasswordEncryptor {
    _propertyTemplateProperties = ["adapter", "parser", "formatter"];

    constructor(propertyTemplateService) {
        this._propertyTemplateService = propertyTemplateService;
    }

    ensurePasswordsEncrypted(metaObject) {
        this._propertyTemplateProperties.forEach((property) => {
            const propertyObject = metaObject.get(property);
            if (propertyObject && propertyObject.handler) {
                const properties = propertyObject.properties || {};
                const propertyTemplate = this._propertyTemplateService.getPropertyTemplateById(propertyObject.handler);
                if (propertyTemplate) {
                    Object.keys(properties).forEach((propertyName) => {
                        const passwordField = _(propertyTemplate.propertyMap).find(
                            (item) =>
                                item.type === "com.webaction.security.Password" &&
                                item.name.toLowerCase() === propertyName.toLowerCase()
                        );
                        if (passwordField) {
                            const passwordValue = properties[propertyName];
                            const type = typeof passwordValue;
                            let encryptedPropertyName = `${propertyName}_encrypted`;
                            if (type === "object") {
                                properties[encryptedPropertyName] = true;
                                metaObject.set(property, propertyObject);
                            } else if (type === "string") {
                                properties[encryptedPropertyName] = false;
                                metaObject.set(property, propertyObject);
                            }
                        }
                    });
                }
            }
        });
    }
}
