import $ from "jquery";
import _ from "underscore";
import BaseControl from "./../base-control";
import dateLib from "core/utils/date-utils";
import template from "./date-range-picker.html";
import $__app_components_common_editor_control_ui_control from "app/components/common/editor/control/ui-control";

function applyTime(date, timeString) {
    if (!date || !timeString) {
        return;
    }

    const time = dateLib(timeString, "HH:mm:ss");
    date = date.set("hour", time.hour()).set("minute", time.minute());
    return date;
}

var activeClass = "active";

export default function(predefinedOptions) {
    var defaultPredefinedOption = predefinedOptions[0];

    var Model = BaseControl.Model.extend({
        defaults: {
            value: null,
            predefinedOptions: predefinedOptions
        }
    });

    var View = BaseControl.View.extend({
        template: _.template(template),
        className: "date-range-picker",

        regions: {
            startDateRegion: ".startDate",
            startTimeRegion: ".startTime",
            endTimeRegion: ".endTime",
            endDateRegion: ".endDate"
        },

        ui: {
            applyButton: ".apply",
            clearButton: ".clear",
            link: "ul li a",
            customRangePicker: ".custom-range-picker",
            startDatePicker: ".startDate .datePicker",
            endDatePicker: ".endDate .datePicker",
            startTimePicker: ".startTime ._timePicker",
            endTimePicker: ".endTime ._timePicker"
        },
        events: {
            "click @ui.applyButton": "setValueFromView",
            click: function(evt) {
                evt.stopPropagation();
            },
            "click @ui.clearButton": function() {
                if (defaultPredefinedOption) {
                    this.refreshActiveItem();
                    this.setValueFromView();
                }
            },
            "click @ui.link": function(evt) {
                this.refreshActiveItem($(evt.target));
            },
            "change @ui.startDatePicker": function() {
                this.setDateRange(0);
            },
            "change @ui.endDatePicker": function() {
                this.setDateRange(1);
            },
            "change @ui.startTimePicker": function() {
                this.setTimeRange(0);
            },
            "change @ui.endTimePicker": function() {
                this.setTimeRange(1);
            }
        },
        setTimeRange: function(typeofTime) {
            //typeofTime = 0 it indicates the startTimePicker change event
            //typeofTime = 1 it indicates the endTimePicker change event

            var maxEndTime, minEndTime;

            //If typeofTime is 0 then endTime min value is changed
            //If typeofTime is 1 then startTime max value is changed
            var element = typeofTime === 0 ? ".endTime ._timePicker" : ".startTime ._timePicker";

            //If both selected dates(start and end date) are equal then we should have the end time greater than start time
            if (this.endDate.getValue()?.format("YYYY-MM-D") === this.startDate.getValue()?.format("YYYY-MM-D")) {
                if (
                    this.startTime.getValue() === this.endTime.getValue() ||
                    this.startTime.getValue() >= this.endTime.getValue()
                ) {
                    this.startTime.setValue("00:00");
                    this.endTime.setValue("23:45");
                }

                minEndTime =
                    typeofTime === 0
                        ? dateLib(this.startTime.getValue(), "HH:mm ")
                              .add(15, "minutes")
                              .format("HH:mm")
                        : null;
                maxEndTime =
                    typeofTime === 0
                        ? "11:45pm"
                        : dateLib(this.endTime.getValue(), "HH:mm ")
                              .subtract(15, "minutes")
                              .format("HH:mm");

                $(element).timepicker("option", {
                    minTime: minEndTime,
                    maxTime: maxEndTime,
                    step: 15
                });
            }
            //else if both selected dates(start and end date)) are not on the same day then there is not restriction on time range.
            else {
                minEndTime = " ";
                maxEndTime = " ";
                $(".endTime ._timePicker").timepicker("option", {
                    minTime: minEndTime,
                    maxTime: maxEndTime,
                    step: 15
                });
                $(".startTime ._timePicker").timepicker("option", {
                    minTime: minEndTime,
                    maxTime: maxEndTime,
                    step: 15
                });
            }
        },
        setDateRange: function(number) {
            const element = number === 0 ? ".endDate .datePicker" : ".startDate .datePicker";
            let datevariable = number === 0 ? this.startDate.getValue() : this.endDate.getValue();

            if (!datevariable)
                datevariable = dateLib();

            $(element).datepicker("option", {
                minDate: number === 0 ? datevariable.format("YYYY-MM-D") : null,
                maxDate: number === 1 ? datevariable.format("YYYY-MM-D") : null
            });
            this.setTimeRange(0);
            this.setTimeRange(1);
        },

        clearValue: function() {
            this.refreshActiveItem();
            this.setValueFromView();
        },

        refreshActiveItem: function($link) {
            $link = $link || this.ui.link.first();
            this.ui.customRangePicker.hide();
            this.ui.link.removeClass(activeClass);
            $link.addClass(activeClass);
            this.currentType = $link.attr("title");
            if (this.currentType === "custom") {
                this.ui.customRangePicker.show();
                this.setDateRange(0);
                this.setDateRange(1);
            }
        },

        initialize: function() {
            this._UIControl = $__app_components_common_editor_control_ui_control;

            BaseControl.View.prototype.initialize.apply(this, arguments);
        },

        getViewValue: function() {
            if (this.currentType === "custom") {
                var from = this.startDate.getValue();
                var to = this.endDate.getValue();
                from = applyTime(from, this.startTime.getValue());
                to = applyTime(to, this.endTime.getValue());

                return {
                    from: from,
                    to: to,
                    displayType: "Custom"
                };
            } else {
                var selectedRange =
                    _.findWhere(predefinedOptions, {
                        key: this.currentType
                    }) || defaultPredefinedOption;
                return {
                    from: selectedRange.from(),
                    to: selectedRange.to(),
                    displayType: selectedRange.name
                };
            }
        },

        setViewValue: function() {},

        onRender: function() {
            var from = defaultPredefinedOption.from();
            var to = defaultPredefinedOption.to();

            this.startDate = this._UIControl.DatePicker.create();
            this.startDateRegion.show(this.startDate);
            this.startDate.setValue(from);

            this.startTime = this._UIControl.TimePicker({ customClassName: "event-log" });
            this.startTimeRegion.show(this.startTime);
            this.startTime.setValue(from);

            this.endTime = this._UIControl.TimePicker({ customClassName: "event-log" });
            this.endTimeRegion.show(this.endTime);
            this.endTime.setValue(to);

            this.endDate = this._UIControl.DatePicker.create();
            this.endDateRegion.show(this.endDate);
            this.endDate.setValue(to);

            this.refreshActiveItem();

            BaseControl.View.prototype.onRender.apply(this, arguments);
        }
    });

    return _.extend({}, BaseControl, {
        Model: Model,
        View: View
    });
}
