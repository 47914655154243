export const roundUp = (value: number) => {
    const strValue = "" + Math.ceil(value);
    const thousandsPow = Math.floor(strValue.length / 3);
    const thousandsPowRest = strValue.slice(0, strValue.length - thousandsPow * 3);

    return Number("" + (+thousandsPowRest + 1) + "e" + thousandsPow * 3);
};

// todo use roundUp instead
export const getHighestAmount = (filteredTableData, calculateDynamicValue = false) => {
    let highestAmount = 0;

    filteredTableData.forEach(el => {
        const sensitiveData = el?.sensitiveData || 0;
        const nonSensitiveData = el?.nonSensitiveData || 0;

        let totalData = sensitiveData + nonSensitiveData;

        if (calculateDynamicValue) {
            const dynamicData = el?.dynamicData || 0;

            totalData = totalData + dynamicData;
        }

        if (totalData > highestAmount) {
            highestAmount = totalData < 1000 ? Math.ceil(totalData / 50) * 50 : Math.ceil(totalData / 1000) * 1000;
        }
    });

    return highestAmount;
};

const nFormatterLookup = [
    { value: 1e15, symbol: "P" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "G" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" },
    { value: 1, symbol: "" }
];
const nFormatterRegexp = /\.0+$|(?<=\.\d*[1-9])0+$/;

export const nFormatter = (num: number, digits: number): string => {
    const item = nFormatterLookup.find(item => num >= item.value);

    return item
        ? (num / item.value)
              .toFixed(digits)
              .replace(nFormatterRegexp, "")
              .concat(item.symbol)
        : "0";
};

export const commaThousands = (num: number | string): string => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
