import customIcons from "src/generic/icon/customIcons-legacy";
import {getChipData, segmentationVariants} from "src/modules/common/segmentation/segmentation.consts";
import securityService from "core/services/securityService/securityService";
import getAddonIconKey from "src/modules/user-plan/pages/user-plan/tabs/shared-compoenets/addon-icons";
import {
    addonFeaturesMessages,
    addonFeaturesTitles
} from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";

const emptyElement = document.createElement("span");

const segmentationChip = variant => {
    if (!variant || variant === segmentationVariants.none) {
        return emptyElement;
    }

    const chipData = getChipData(variant);

    const resultEl = document.createElement("div");
    resultEl.className = `seg-chip ${variant}-chip`;
    resultEl.innerHTML = customIcons(chipData.icon);

    const textEl = document.createTextNode(chipData.label);
    resultEl.appendChild(textEl);

    return resultEl;
};

const segmentationCircle = (variant, licenseKey) => {
    if (!variant || variant === segmentationVariants.none) {
        return emptyElement;
    }

    let key = getAddonIconKey(securityService.currentSegmentationLicense, licenseKey);
    if (!key) {
        key = "AccountTreeOutlined";
    }

    const resultEl = document.createElement("div");
    resultEl.className = `seg-circle ${variant}-circle ${licenseKey}`;
    resultEl.innerHTML = customIcons(key);

    return resultEl;
};

const segmentationIndicator = (variant, customClass) => {
    if (!variant || variant === segmentationVariants.none) {
        return emptyElement;
    }

    const chipData = getChipData(variant);

    const resultEl = document.createElement("span");
    resultEl.className = `seg-indicator seg-indicator-${variant} ${customClass}`;
    resultEl.innerHTML = customIcons(chipData.icon);

    return resultEl;
};

const segmentationTooltip = (variant, licenseKey, $el, functionReady, onClose) => {
    if (!variant || variant === segmentationVariants.none) {
        return {};
    }

    const title = addonFeaturesTitles[licenseKey];
    const description = addonFeaturesMessages[licenseKey];

    const addonContent = `<div><h2>${segmentationCircle(variant, licenseKey).outerHTML} ${title} ${
        segmentationChip(variant).outerHTML
    }</h2>
            <div>${description}</div>
            <div class="footer">
                <a class="cancel waves-effect btn-flat dark">Cancel</a>
                <a class="submit btn waves-effect waves-light" href="${
                    securityService.addonNotAvailableLink
                }">Learn more</a>
            </div>
        </div>`;

    return {
        content: addonContent,
        trigger: "click",
        contentAsHTML: true,
        autoClose: true,
        theme: "segmentation-tooltip",
        interactive: true,
        functionReady: (origin, tooltip) => {
            tooltip.find(".cancel").click(() => {
                $el.tooltipster("hide");
                if (onClose) {
                    onClose();
                }
            });
            tooltip.find(".submit").click(() => {
                $el.tooltipster("hide");
                if (onClose) {
                    onClose();
                }
            });

            if (functionReady) {
                functionReady(origin, tooltip);
            }
        }
    };
};

const licenseResourceResolver = licenseKey => {
    return {
        title: addonFeaturesTitles[licenseKey],
        description: addonFeaturesMessages[licenseKey]
    };
};

export { licenseResourceResolver, segmentationIndicator, segmentationChip, segmentationTooltip };
