import React from "react";
import { useField } from "formik";
import { StriimInputField } from "@striim/striim-ui";

const FormikField = ({ helperText, dataTestId, required = false, ...props }) => {
    const [{ onChange, ...field }, meta] = useField(props.name);
    return (
        <StriimInputField
            required={required}
            {...field}
            {...props}
            id={dataTestId || props.id}
            error={!!meta.error && meta.touched}
            helperText={(meta.touched && meta.error) || helperText}
            inputProps={{
                autoComplete: "new-password"
            }}
            InputProps={{
                autoComplete: "new-password",
                onChange
            }}
        />
    );
};

export default FormikField;
