import App from "app";
import ApplicationController from "lib/controllers/application_controller";
import ModalManager from "app/components/common/modal/ModalManager";

App.module(
    "Dashboards",
    function(Dashboards) {
        Dashboards.Controller = ApplicationController.extend({
            initialize: function() {
                Dashboards.ModalManager = new ModalManager({
                    container: this.$el,
                    freeze: false
                });
            }
        });
    },
    null
);
