import React, { useCallback } from "react";
import { VaultValueRowReadOnly, VaultValueRowEdit } from ".";
import VaultsService from "./vaults-service";

const VaultValueRow = ({ data }) => {
    const {
        dataKey,
        removeEmptyVaultValue,
        vault,
        fetchData,
        isEditAllowed,
        isDeleteAllowed,
        editExistingVaultValue,
        cancelVaultValue
    } = data;

    const setEditMode = edit => {
        if (!edit && data?.data?.isTemp && !data.data.isSaved) {
            removeEmptyVaultValue(dataKey, vault);
        } else if (!edit && data.data.isSaved) {
            cancelVaultValue(data);
        }
    };

    const deleteRow = async (vaultId, valueKey) => {
        await VaultsService.dropVaultValue(vaultId, valueKey);
        await fetchData();
    };

    const getComponent = useCallback(() => {
        if (data?.data?.isTemp) {
            return <VaultValueRowEdit data={data} setEditMode={setEditMode} fetchData={fetchData} />;
        } else {
            return (
                <VaultValueRowReadOnly
                    editExistingVaultValue={editExistingVaultValue}
                    data={data}
                    isEditAllowed={isEditAllowed}
                    isDeleteAllowed={isDeleteAllowed}
                    deleteRow={deleteRow}
                />
            );
        }
    }, [data, dataKey]);

    return <>{getComponent()}</>;
};

export default VaultValueRow;
