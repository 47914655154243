import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
// @ts-ignore
import {
    StriimInputField,
    StriimTypography,
    StriimCircularLoader,
    StriimFormBuilder,
    FIELD_TYPES
} from "@striim/striim-ui";
import api from "../../../../../../core/api/api";
import { styles } from "./adapter-specific-form.styles";
import growl from "../../../../../../app/components/common/growl";
import { components, MenuListProps } from "react-select";
import connectionProfileService from "../../../../apps/pages/manage-striim/connection-profiles/connection-profile-service";
import { sourceInfoType } from "../../Types";
import { validationUtils } from "../../../utils";
interface FormProps {
    adapterName: string;
    formRef: any;
    enableNext: boolean;
    valid: boolean;
    initialValues: object;
    sourceInfo: sourceInfoType;
}
interface optionsType {
    label: string;
    value: string;
}
const AdapterSpecificForm = forwardRef((props: FormProps, ref) => {
    const { adapterName, sourceInfo } = props;
    const sourceData = sourceInfo?.sourceAdapter?.properties;
    let sourceDB =
        sourceData?.adapterName === "DatabaseReader" ? sourceData?.DatabaseProviderType : sourceData?.adapterName;
    switch (adapterName.toUpperCase()) {
        case "BIGQUERY":
            if (validationUtils.isAppAdapter(sourceDB)) {
                return <BigQueryAdditionalForm ref={ref} {...props} />;
            }
            return <></>;
        default:
            return <></>;
    }
});

export default AdapterSpecificForm;

const BigQueryAdditionalForm = forwardRef((props: FormProps, ref) => {
    const { formRef, initialValues } = props;
    const [selectedDataset, setSelectedDataset] = useState(initialValues?.dataset ?? null);
    const [errorMessage, setErrorMessage] = useState<string>(null);
    const [apiResponse, setApiResponse] = useState({
        status: "not started",
        options: [],
        error: null,
        loading: false,
        fetched: false
    });
    const prevValuesRef = useRef();
    const datasetValidationRegex = /^[a-zA-Z0-9_]+$/;
    useEffect(() => {
        if (selectedDataset) {
            formRef?.current?.setFieldValue("dataset", selectedDataset);
            validate(selectedDataset);
        }
    }, []);

    const fetchDatasets = async () => {
        let connectionProfileProperties;
        if (formRef?.current?.values?.connectionProfileName) {
            const connectionProfileDetails = await connectionProfileService.getConnectionProfile(
                formRef?.current?.values?.connectionProfileName
            );
            connectionProfileProperties = connectionProfileDetails.get("properties");
        }
        const values = { ...formRef?.current.values, ...connectionProfileProperties };
        prevValuesRef.current = values;
        try {
            if (!values.ServiceAccountKey || !values.projectId) {
                return;
            }
            setApiResponse(prev => ({
                ...prev,
                loading: true
            }));
            const response = JSON.parse(
                await api.BigQueryList({
                    projectId: values.projectId,
                    ServiceAccountKey: values.ServiceAccountKey,
                    query: "Datasets"
                })
            );
            if (!response.result) {
                setApiResponse(prev => ({
                    ...prev,
                    error: response.message,
                    options: [],
                    status: "failed"
                }));
                // @ts-ignore
                growl.error(response.message, "Problem fetching datasets");
            } else {
                let options: optionsType[];
                if (!response.message) {
                    options = [];
                    // @ts-ignore
                    growl.info(
                        "We could not find any datasets for this service key. You can create a new one by entering a new value in the dropdown.",
                        "No Datasets found"
                    );
                } else {
                    options = response?.message
                        ?.split(",")
                        ?.map((option: string) => ({ label: option, value: option }));
                    options = Array.isArray(options) && options.length > 0 ? options : [];
                }
                setApiResponse(prev => ({
                    ...prev,
                    error: null,
                    options: options,
                    status: "success"
                }));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setApiResponse(prev => ({
                ...prev,
                loading: false,
                fetched: true
            }));
        }
    };

    const CustomMenuList = (props: MenuListProps) => {
        return (
            <components.MenuList {...props}>
                {apiResponse.fetched && !apiResponse.loading && (
                    <Box sx={styles.menuHeadingContainer}>
                        <StriimTypography variant="body4" sx={styles.menuHeading}>
                            Provide a name to create a new dataset or select from the list below
                        </StriimTypography>
                    </Box>
                )}
                {props.children}
            </components.MenuList>
        );
    };
    const CustomNoOptionsMessage = () => {
        return (
            <Box sx={styles.noOptionsContainer}>
                {apiResponse.loading ? (
                    <Grid sx={styles.loaderContainer}>
                        <StriimCircularLoader size={20} thickness={8} />
                        <StriimTypography variant="body4" sx={styles.infoText}>
                            Retrieving datasets
                        </StriimTypography>
                    </Grid>
                ) : (
                    apiResponse.fetched && (
                        <Box>
                            <StriimTypography variant="body4" sx={styles.infoText}>
                                No datasets available
                            </StriimTypography>
                        </Box>
                    )
                )}
            </Box>
        );
    };
    function handleDatasetsFetch() {
        const formValues = formRef?.current?.values;
        const valueChanged =
            prevValuesRef?.current?.useConnectionProfile !== formValues?.useConnectionProfile ||
            (formValues?.useConnectionProfile &&
                prevValuesRef?.current?.connectionProfileName !== formValues?.connectionProfileName) ||
            (!formValues?.useConnectionProfile &&
                (prevValuesRef?.current?.ServiceAccountKey !== formValues?.ServiceAccountKey ||
                    prevValuesRef?.current?.projectId !== formValues?.projectId));
        if ((!apiResponse.fetched && !apiResponse.loading) || valueChanged) {
            //Clearing the options before fetching since API call may take some time.
            setApiResponse(prevState => ({
                ...prevState,
                options: []
            }));
            fetchDatasets();
        }
    }
    function validate(value = null) {
        const datasetValue = value ?? selectedDataset;
        if (!datasetValue) {
            const errorMessage = "Value for Dataset is required";
            setErrorMessage(errorMessage);
            return {
                Dataset: errorMessage
            };
        }
        if (!datasetValidationRegex.test(datasetValue)) {
            const errorMessage = "Value for Dataset is not valid";
            setErrorMessage(errorMessage);
            return {
                Dataset: errorMessage
            };
        }
        setErrorMessage(null);
        return {};
    }
    useImperativeHandle(ref, () => ({
        validateForm: validate
    }));
    return (
        <Box mt={2}>
            <Box mt={1.25} onClick={handleDatasetsFetch}>
                <StriimInputField
                    name="dataset"
                    label="Dataset"
                    required
                    error={!!errorMessage}
                    placeholder="Select a Dataset or create a new one."
                    helperText={errorMessage}
                    SelectProps={{
                        options: apiResponse.options,
                        isClearable: false,
                        menuPosition: "absolute",
                        components: {
                            IndicatorSeparator: () => null,
                            CrossIcon: () => null,
                            MenuList: CustomMenuList,
                            NoOptionsMessage: CustomNoOptionsMessage
                        },
                        selectVariant: "creatable",
                        menuShouldScrollIntoView: false,
                        menuPortalTarget: document.body,
                        zIndex: 1502
                    }}
                    value={selectedDataset ? { value: selectedDataset, label: selectedDataset } : null}
                    select
                    onChange={(val: optionsType) => {
                        setSelectedDataset(val.value);
                        formRef?.current?.setFieldValue("dataset", val.value);
                        validate(val?.value);
                    }}
                />
            </Box>
        </Box>
    );
});
