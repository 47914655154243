export const styles = {
    container: {
        marginTop: 2,
        marginBottom: 2,
        paddingLeft: 1,
        paddingRight: 3,
        position: "absolute",
        bottom: 0,
        width: "-webkit-fill-available",
        "@supports (-moz-appearance:none)": {
            width: "-moz-available"
        }
    },
    cancelButton: {
        marginRight: 2
    }
};
