import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import template from "./templates/filters.html";
import MetricTypes from "app/components/monitor/overview/appdata/metrictypes";
import { initMaterialDesignTabs } from "../../../../../core/utils/material-design/tabs/material-design-style-tabs";

var Filter = {};

Filter.View = Backbone.Marionette.ItemView.extend({
    template: _.template(template),

    events: {
        "click button": "changeActiveMetric",
    },

    changeActiveMetric: function (e) {
        var id = $(e.currentTarget).data("id");
        this.model.set("selectedMetric", id);
    },

    initialize: function () {
        this.model.availableMetrics = MetricTypes;
    },
    onShow: function() {
        const ctx = this;
        setTimeout(() => {
            ctx.tabsRemoveCallback = initMaterialDesignTabs(document.querySelector("#monitor-filters"));
        }, 0);
    },

    destroy() {
        Backbone.Marionette.ItemView.prototype.destroy.apply(this, arguments);
        if (this.tabsRemoveCallback) {
            this.tabsRemoveCallback();
        }
    }
});

export default Filter;
