import React from "react";
import PropTypes from "prop-types";
import resources from "../system_health_monitor_resources.json";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";

import { StriimTypography, StriimChip, StriimList, StriimListItem } from "@striim/striim-ui";
import { ListSubheader } from "@mui/material";
import MetricCard from "src/modules/homepage/common/metric_card.jsx";

const useStyles = makeStyles(() => ({
    customCardStyles: {
        border: "1px solid #D8E4F0",
        borderRadius: "0px 8px 8px 0px"
    }
}));

const NodesMonitorTile = ({ running, total, data }) => {
    const classes = useStyles();
    const theme = useTheme();

    const getPieChartData = () => [
        { name: "Running", color: getDisplayColor((total - running) / total), value: running },
        { name: "Not running", color: resources.emptyColor, value: total - running }
    ];

    const getDisplayColor = value =>
        value > 0.9
            ? theme.palette.critical.main
            : value < 0.5
            ? theme.palette.success.main
            : theme.palette.warning.main;

    const getDropDownList = () => {
        return (
            <StriimList sx={{ minWidth: "320px" }} subheader={<ListSubheader disableSticky>Nodes</ListSubheader>}>
                {data.map(nodeData => (
                    <StriimListItem
                        key={nodeData.name + nodeData.status}
                        text={<StriimTypography variant="body">{nodeData.name}</StriimTypography>}
                        secondaryAction={
                            <StriimChip
                                type="tag-chip"
                                variant={nodeData.status === "Running" ? "success" : "error"}
                                label={nodeData.status.toUpperCase()}
                            />
                        }
                    />
                ))}
            </StriimList>
        );
    };

    return (
        <MetricCard
            title="Nodes"
            pieChartData={getPieChartData()}
            displayText={`${running} / ${total}`}
            displayColor={getDisplayColor((total - running) / total)}
            dropdownList={getDropDownList()}
            customCardStyles={classes.customCardStyles}
        />
    );
};

export default NodesMonitorTile;

NodesMonitorTile.proptypes = {
    running: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired
};
