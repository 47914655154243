import React, { useRef } from "react";
import { StriimList, StriimListItem } from "@striim/striim-ui";
import Dictionary from "app/components/common/helpable/online-help-dictionary";

const DropdownDocumentationContent = ({ openDocumentationModal }) => {
    const timer = useRef();

    const delayedClose = () => {
        timer.current = setTimeout(() => {
            openDocumentationModal[1](false);
        }, 300);
    };

    const hasOpened = () => {
        if (timer.current) {
            clearTimeout(timer);
        }
    };

    return (
        <StriimList
            onMouseLeave={() => {
                delayedClose();
            }}
            onMouseEnter={() => {
                hasOpened();
            }}
        >
            <StriimListItem button component="a" href={Dictionary.getRootPath()} target="_blank" text="Documentation" />
            <StriimListItem button component="a" href={Dictionary.getPDFPath()} target="_blank" text="Documentation (PDF)" />
        </StriimList>
    );
};

export default DropdownDocumentationContent;
