import {useEffect} from "react";
import { useLocation } from "react-router-dom";
import NProgress from "nprogress";

export const useNavigationProgressBar = () => {
    const location = useLocation();

    const getProgressBarContainer = () => {
        let progressBarContainer = document.querySelector("#app-progressbar-container") as HTMLElement;
        if (progressBarContainer) {
            progressBarContainer.style.top = document.querySelector("header") ? "49px" : "0px";
            progressBarContainer.style.height = "2px";
            return "#app-progressbar-container";
        } else {
            return "body";
        }
    };

    useEffect(() => {
        NProgress.configure({
            parent: getProgressBarContainer()
        });
        NProgress.start();
        NProgress.done();
    }, [location]);
};
