import App from "app";
import NestedTypes from "backbone.nestedtypes";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import dataVisualizationsEditors from "core/services/metadataService/dataVisualizationsEditors";
import default_collection_template from "./collection.html";
import carousel_template from "./collection-carousel.html";
import "./editor";
import "app/components/dashboard/editors/axes";
import "app/components/common/_deprecated/form/form";
import growl from "../../common/growl";

App.module("Dashboard.VisEditor", function(VisEditor, App, Backbone, Marionette, $, _) {
    // Using Numeric version for now. Original is above.
    const NumericSeriesIndicator = Marionette.ItemView.extend({
        className: "series-indicator numeric",
        template: _.template("{{ index }}"),
        modelEvents: {
            change: "render" // Re-render when index changes
        },
        events: {
            click: "on_click"
        },
        on_click: function() {
            this.trigger("click", this.model.id);
        }
    });

    const SeriesNav = Marionette.CompositeView.extend({
        className: "series-nav-container",
        template: _.template(
            '<div class="description">{{ num }} {{ label }}</div><div class="indicators"></div><div class="add-item"><span class="icon fa fa-plus"></span></div>'
        ),
        childView: NumericSeriesIndicator, // Using Numeric version for now
        childViewContainer: ".indicators",
        onRender: function() {
            this.set_current();
            this.listenTo(this.model, "change:current_series", this.set_current);
        },
        set_current: function() {
            const _this = this;
            this.children.each(function(v) {
                v.$el.removeClass("active");
            });
            const current_series = this.collection.find(function(model) {
                return model.id === _this.model.get("current_series");
            });
            if (!current_series) {
                return;
            }
            const view = this.children.findByModel(current_series);
            view.$el.addClass("active");
        }
    });

    const CollectionCompositeView = Marionette.CompositeView.extend({
        template: _.template(""),
        className: "collection",
        getChildView: function() {
            // TODO: factor out this map. It's essentially the same one as in Properties Editor.
            // The need to convert a editor type to an actual view is very common.
            const ItemView = Marionette.LayoutView.extend({
                className: "collection-item",
                template: _.template('<div class="collection-item-body"></div><a href="#" class="remove"></a>'),
                regions: {
                    body: ".collection-item-body"
                },
                triggers: {
                    "click .remove": "remove-item"
                },
                onRender: function() {
                    this.getRegion("body").show(
                        new App.Dashboard.VisEditor[dataVisualizations.EDITOR.PROPERTIES]({
                            model: this.model
                        })
                    );
                }
            });
            // TODO: allow more than just AXES...
            if (this.model.editor.itemeditor.type === dataVisualizations.EDITOR.AXES) {
                return App.Dashboard.VisEditor[this.model.editor.itemeditor.type];
            }
            return ItemView;
        },
        // For each item in this collection, set the collection to be the requested input editors, and supplement each with the model that contains the attribute that input editor will directly modify
        buildChildView: function(child, ChildViewClass, childViewOptions) {
            const editors = this.model.editor.itemeditor.propertyeditors.map(
                function(editor) {
                    return new dataVisualizationsEditors.EditorViewModel({
                        _parent: child,
                        config: child.config,
                        editor: editor
                    });
                }.bind(this)
            );

            // build the final list of options for the childView class
            const options = _({
                model: child,
                collection: new dataVisualizationsEditors.EditorViewModel.Collection(editors)
            }).extend(childViewOptions);

            return new ChildViewClass(options);
        }
    });

    VisEditor[dataVisualizations.EDITOR.COLLECTION] = VisEditor.Editor.LayoutView.extend({
        className: "collection-editor-container",
        regions: {
            collection: ".items"
        },
        events: {
            "click .add-item": "add_item"
        },
        initialize: function() {
            _(this.model.editor.options).defaults({
                add_text: "Add another",
                multiple: true
            });

            if (!this.model.editor.options.multiple) {
                this.$el.addClass("single");
            }

            // The template to use
            switch (this.model.editor.style) {
                case dataVisualizations.EDITOR_STYLE.CAROUSEL:
                    this.templateHTML = carousel_template;
                    this.$el.addClass("carousel");
                    this.template = _.template(this.templateHTML);
                    break;
                default:
                    this.templateHTML = default_collection_template;
                    this.template = _.template(this.templateHTML);
                    return VisEditor.Editor.LayoutView.prototype.initialize.apply(this, arguments);
            }
        },
        add_item: function(e) {
            const closestContainer = $(e.currentTarget).closest(`.${this.className}`)[0];
            let isEventFromDescendant = closestContainer !== this.$el[0];
            if (isEventFromDescendant) {
                return;
            }

            const items = this.model._parent.config[this.model.editor.attribute];
            const new_item = items.add({
                id: _.uniqueId(this.model.editor.attribute)
            });
            const last_index = items.length - 1;
            const itemEditor = this.model._parent.editor.propertyeditors.at(this.model._parent_editor_id).itemeditor;

            const collection = this.getRegion("collection").currentView.collection;
            const new_item_view_model = {
                _queryvisualization: this.model._queryvisualization,
                _datavisualization: this.model._datavisualization,
                _theme: this.model._theme,
                _parent: this.model._parent,
                _parent_editor_id: this.model._parent_editor_id,
                _parent_config_id: last_index + 1,
                editor: itemEditor.toJSON(), // see comment in collection creation process
                config: new_item
            };

            if (this.model.editor.style === dataVisualizations.EDITOR_STYLE.CAROUSEL) {
                this.model.editor.set("_current_item_id", new_item.id);
            } else {
                collection.add(new_item_view_model);
            }
            return false;
        },
        render_nav: function() {
            const _this = this;

            const cur = this.model.editor._current_item_id;

            const SeriesNavModel = NestedTypes.Model.extend({
                defaults: {
                    editor: null,
                    current_series: null,
                    num: Number,
                    label: ""
                }
            });
            const nav = new SeriesNav({
                model: new SeriesNavModel({
                    editor: this.model.editor,
                    current_series: cur,
                    num: this.model.config[this.model.editor.attribute].length,
                    label: this.model.editor.attribute
                }),
                collection: new Backbone.Collection(
                    this.model.config[this.model.editor.attribute].map(function(series, i) {
                        return new Backbone.Model({
                            id: series.id,
                            series: series,
                            index: i + 1
                        });
                    })
                )
            });

            this.getRegion("nav").show(nav);

            nav.listenTo(nav, "childview:click", function(view, series) {
                _this.trigger("nav:change", series);
            });
        },
        render_collection: function() {
            const _this = this;
            // The collection whose items are being edited
            let collection_to_edit = this.model._parent.config[this.model.editor.attribute].models;

            // If a current_item_id is set, display only that item (e.g. only that series in the carousel)
            if (this.model.editor._current_item_id) {
                this.render_nav();
                //this.getRegion('nav').currentView.model.set('current_series', this.model.editor._current_item_id);
                collection_to_edit = _(collection_to_edit).filter(
                    function(model) {
                        return model.id === this.model.editor._current_item_id;
                    }.bind(this)
                );
            }

            // The editor for each of the Collection's items
            const itemEditor = this.model._parent.editor.propertyeditors.at(this.model._parent_editor_id).itemeditor;

            const editors = _(collection_to_edit).map(
                function(itemModel, i) {
                    return new dataVisualizationsEditors.EditorViewModel({
                        _queryvisualization: this.model._queryvisualization,
                        _datavisualization: this.model._datavisualization,
                        _theme: this.model._theme,
                        _parent: this.model._parent,
                        _parent_editor_id: this.model._parent_editor_id,
                        _parent_config_id: i,
                        config: itemModel,
                        // Note: the below "editor" property is of type PropertyEditor. However, itemEditor is of type CollectionItemEditor, which is a reduced version of PropertyEditor. Due to some circular relationships, it was impossible to have one extend the other. By reducing itemEditor to its JSON, we can construct PropertyEditor out of CollectionItemEditor.
                        // Corollary: since we are blatantly disregarding the type spec for this property, perhaps let's not have it at all.
                        editor: itemEditor.toJSON()
                    });
                }.bind(this)
            );

            const collection = new dataVisualizationsEditors.EditorViewModel.Collection(editors);

            // Render the collection editor
            const CollectionView = new CollectionCompositeView({
                model: this.model,
                collection: collection
            });
            this.getRegion("collection").show(CollectionView);

            CollectionView.listenTo(CollectionView, "childview:remove-item", function(view) {
                const that = this;

                if (
                    that.model.editor?.options?.atLeastOne &&
                    that.model.config?.[that.model.editor.attribute]?.length === 1
                ) {
                    growl.error(
                        "You cannot delete this item because it is the only item in this Visualization. Please add another item and then delete this one."
                    );
                    return;
                }

                _this.trigger("collection:remove", view);
            });
        },
        onRender: function() {
            const is_carousel = this.model.editor.style === dataVisualizations.EDITOR_STYLE.CAROUSEL;

            if (is_carousel) {
                const collection_to_edit = this.model._parent.config[this.model.editor.attribute];
                if (collection_to_edit.length === 0) {
                    collection_to_edit.add({
                        id: _.uniqueId(this.model.editor.attribute)
                    });
                }
                this.model.editor.set("_current_item_id", collection_to_edit.at(0).id);

                this.addRegion("nav", ".series-nav");

                // As the user flips between series, rerender
                this.listenTo(this.model.editor, "change:_current_item_id", function() {
                    this.render_collection();
                });

                this.listenTo(this, "nav:change", function(series) {
                    this.model.editor.set("_current_item_id", series);
                });
            }

            this.listenTo(this, "collection:remove", function(view) {
                const items = this.model._parent.config[
                    this.model._parent.editor.propertyeditors.at(this.model._parent_editor_id).attribute
                ];

                items.remove(view.model.config);
                this.getRegion("collection").currentView.collection.remove(view.model);
                if (is_carousel) {
                    if (items.length > 0) {
                        this.model.editor.set("_current_item_id", items.at(0).id);
                    } else {
                        this.render();
                    }
                }
            });

            this.render_collection();
        }
    });
});
