import TypedEventToWaEventControl from "app/components/common/editor/control/typedevent-to-waevent/typedevent-to-waevent";

var Transformation = {};

Transformation.createColumnControl = function () {
    return TypedEventToWaEventControl.extend({
        hideLabel: true,
        hidden: false,
    }).create();
};

Transformation.buildTql = function (tqlBuilder, columns) {
    let column = columns[0];

    if (!column.column) {
        return;
    }
    let params = [column.column];
    if (column.tableName) {
        params = [column.column, column.tableName];
    }
    tqlBuilder.addColumn(tqlBuilder.streamAlias, "convertTypedeventToWAevent", params, null);
};

export default Transformation;
