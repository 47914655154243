import Backbone from "backbone";
import NestedTypes from "backbone.nestedtypes";
import _ from "underscore";
import "tooltips";

const menuOption = {};

menuOption.Model = NestedTypes.Model.extend({
    defaults: {
        visible: true,
        enabled: true,
        title: null,
        icon: "",
        text: "",
        description: "",
        value: "", // optional. If not specified, ID will be used
    },
});

menuOption.View = Backbone.Marionette.ItemView.extend({
    tagName: "li",
    template: function (data) {
        if (!data.title) {
            data.title = "You are not allowed to perform this operation.";
        }
        if (data.enabled) {
            return _.template(
                '<div class="option-content" data-test-id="option-{{text}}"><span class="icon-container"><span class="icon fa fa-{{ icon }}"></span></span><span class="value">{{text}}</span></div>'
            )(data);
        } else {
            return _.template(
                '<div class="option-content" title="{{title}}" data-test-id="option-{{text}}"><span class="icon-container"><span class="icon fa fa-{{ icon }}"></span></span><span class="value">{{text}}</span></div>'
            )(data);
        }
    },
    onRender: function () {
        var classNames = [];
        if (!this.model.visible) {
            classNames.push("hidden");
        }
        if (!this.model.enabled) {
            classNames.push("disabled");
        }
        if (!this.model.icon) {
            classNames.push("no-icon");
        }
        this.$el.removeClass().addClass(classNames.join(" "));
        if (!this.model.enabled && this.model.title) {
            this.$el.find("div").tooltipster({
                animation: "grow",
                position: "left",
                offsetX: 8,
                contentAsHTML: true,
            });
        }
    },
    modelEvents: {
        change: "render",
    },
    triggers: {
        click: "click",
    },
});

export default menuOption;
