import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./button.html";

export default function (text) {
    return _.extend({}, BaseControl, {
        View: BaseControl.View.extend({
            template: _.template(template),

            getViewValue: function () {},

            setViewValue: function () {},

            events: {
                "click a": function (e) {
                    if (this.getEnabled()) {
                        this.trigger("clicked");
                    }
                    e.preventDefault();
                },
            },

            onRender: function () {
                this.$("a").text(text);
                BaseControl.View.prototype.onRender.apply(this, arguments);
            },

            setViewEnabled: function () {
                this.$("a").toggleClass("disabled", !this.getEnabled());
            },
        }),
    });
}
