import $ from "jquery";
import utils from "core/utils";
import api from "core/api/api";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import { saveValidationErrors } from "./validation-errors-storage";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import growl from "app/components/common/growl";

/*

To test validation using fake data use:
- fakeAllowAppValidation - this will enable fake validation
- useFakeValidationErrors - this will generate validation data

*/


function convertDeploymentInfo(deploymentInfo, namespace, appName) {
    let result = {};
    for (let key in deploymentInfo) {
        if (!deploymentInfo.hasOwnProperty(key)) {
            continue;
        }

        if (key === appName) {
            result[namespace + ".APPLICATION." + key] = deploymentInfo[key];
        } else {
            result[namespace + ".FLOW." + key] = deploymentInfo[key];
        }
    }
    return result;
}

function getApplicationErrors(applicationId, deploymentInfo) {
    return new Promise((resolve) => {
        var namespace = metaObjectConverter.getNamespace(applicationId);
        var appName = metaObjectConverter.getName(applicationId);
        deploymentInfo = convertDeploymentInfo(deploymentInfo, namespace, appName);

        callAPI(applicationId, deploymentInfo)
            .then((errors) => {
                errors.forEach(_processError);
                if (errors && errors.length === 0) {
                    // no errors return null
                    resolve(null);
                    return;
                }
                resolve(errors);
            })
            .fail((error) => {
                if (error && error.message && error.message.indexOf("Unsupported operation:") >= 0) {
                    // expected exception return null
                    resolve(null);
                    return;
                }
                let message = (error || {}).message || "Data type validation errors occured";
                if (message === "connect timed out") {
                    message = "Connection timed out";
                }
                growl.error(message, "Data validation unexpected exceptions");
                resolve([]);
            });
    });
}

function callAPI(applicationId, deploymentInfo) {
    if (window.useFakeValidationErrors) {
        return _getFakeData(applicationId);
    }

    return api.getValidationErrors(applicationId, deploymentInfo);
}

function _processError(error) {
    error.srcShortName = metaObjectConverter.getName(error.srcComponentName);
    error.targetShortName = metaObjectConverter.getName(error.tarComponentName);
    error.message = utils.htmlAttributeEscapeQuotes(error.message);
}

function _getFakeData(applicationId) {
    if (window.dataValidationSilentException) {
        let deferred = new $.Deferred();
        deferred.reject({
            message: "Application " + applicationId + " not found for validation.",
        });
        return deferred.promise();
    }

    const errors = [];
    const componentsNumber = window.componentsNumber || 3;

    for (let componentIdx = 1; componentIdx <= componentsNumber; componentIdx++) {
        for (let tableIdx = 1; tableIdx <= 3; tableIdx++) {
            for (let colIdx = 1; colIdx <= 5; colIdx++) {
                const error = {
                    srcComponentName: "admin.SOURCE.DatabaseReaderSource" + componentIdx,
                    srcTable: "tableSrc" + tableIdx * 5,
                    srcCol: "colSrc" + colIdx * 10,
                    tarComponentName: "admin.TARGET.DatabaseWriterTarget" + componentIdx,
                    tarTable: "tableTarget" + tableIdx,
                    tarCol: "colTarget" + colIdx,
                    message: 'The source column type "Integer" cannot be mapped to target type of "TEXT"',
                };

                errors.push(error);
            }
        }
    }
    let deferred = new $.Deferred();
    setTimeout(() => {
        deferred.resolve(errors);
    }, 3000);
    return deferred.promise();
}

function saveLastErrors(applicationId, errors, deploymentInfo) {
    return new Promise((resolve) => {
        metaStoreService.findById(applicationId).then((app) => {
            saveValidationErrors(app, errors, deploymentInfo).then(() => {
                resolve();
            });
        });
    });
}

/** @function validate
 * validate application data types and store result in {@link validation-errors-storage}
 *  @param {string} applicationId - application full Id
 *  @param {object} deploymentInfo - hashmap with {flow,deployment group}
 *  @returns {array} - it can return 1) null - no errors, 2) empty array - there was unhanded exception, 3) list of errors
 */
function validate(applicationId, deploymentInfo) {
    return new Promise((resolve) => {
        getApplicationErrors(applicationId, deploymentInfo)
            .then((errors) => {
                saveLastErrors(applicationId, errors, deploymentInfo).then(() => {
                    resolve(errors);
                });
            })
            .catch(() => {
                saveLastErrors(applicationId, null).then(() => {
                    resolve(null);
                });
            });
    });
}

export default validate;
