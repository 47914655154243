import _ from "underscore";
import template from "./condition.html";
import Backbone from "backbone";
import UIControl from "../ui-control";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import MetaObjectDataSource from "../select/metaobject-datasource";
import conditions from "./conditions.json";
import dataTypes from "./dataTypes.json";
import metaObjectHelper from "app/components/common/editor/meta-object-editors/metaObjectHelper";
import FieldDataSource from "../select/field-datasource";

const Condition = {};

Condition.Model = Backbone.Model.extend({
    defaults: {
        attribute: null,
        condition: null,
        value: "",
        attributeDataType: null,
        dataTypeFromAttribute: null,
        outputStream: null,
        isExpanded: false,
        renderHeader: false,
        canRemove: false,
        groupBy: false,
        type: null,
        tqlMode: false,
        groupCollapsed: false,
        forwardingExpr: null
    }
});

Condition.Collection = Backbone.Collection.extend({
    model: Condition.Model,
    comparator: function(item) {
        return item.get("outputStream");
    }
});

Condition.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(template),
    className: "condition",
    tagName: "div",

    events: {
        "click @ui.expander": "toggle"
    },

    ui: {
        brief: ".brief",
        conditionRoot: ".condition-root",
        expander: ".expander",
        expanderIcon: ".expander material-icons",
        briefConditions: ".brief-conditions",
        briefOutput: ".brief-output",
        streamName: ".stream-name",
        attributeDataTypeField: ".attributeDataTypeField",
        ruleBuilder: ".rule-builder",
        tqlBuilder: ".tql-builder",
        groupHeader: ".group-header",
        groupHeaderText: ".group-name",
        deleteCondition: ".deleteCondition"
    },

    triggers: {
        "click .deleteCondition": {
            event: "removeCondition",
            stopPropagation: true
        },
        "click .groupExpander": {
            event: "groupCollapsed",
            stopPropagation: true
        }
    },

    modelEvents: {
        "change:isExpanded": "renderExpanded",
        "change:tqlMode": "renderTQLMode",
        "change:type": "renderType",
        "change:forwardingExpr": "renderForwardingExpr",
        "change:attribute": "renderAttribute",
        "change:value": "renderValue",
        "change:condition": "renderCondition",
        "change:attributeDataType": "renderAttributeDataType",
        "change:renderHeader": "renderHeader",
        "change:groupBy": "renderGroupBy",
        "change:groupCollapsed": "renderGroupCollapsed",
        "change:canRemove": "renderCanRemove"
    },

    regions: {
        attributeRegion: ".attribute",
        conditionTypeRegion: ".conditionType",
        valueRegion: ".value",
        attributeDataTypeRegion: ".attributeDataType",
        outputStreamRegion: ".outputStream",
        ruleBuilderRegion: ".rule-builder",
        tqlRegion: ".tql"
    },

    initialize: function() {
        this.value = UIControl.TextField.create();
    },

    renderExpanded: function() {
        if (this.model.get("isExpanded")) {
            this.ui.conditionRoot.addClass("expanded");
        } else {
            this.ui.conditionRoot.removeClass("expanded");
        }
        const outputStream = this.model.get("outputStream");
        if (outputStream) {
            this.ui.streamName.html(outputStream);
            this.ui.briefOutput.html("Output to ");
        }
        let formattedCondition = "";
        const attribute = this.model.get("attribute");
        const condition = this.model.get("condition");
        const value = this.model.get("value");
        if (attribute && condition && value && outputStream) {
            const conditionHelpText = conditions.find(c => c.id === condition)?.text || condition;
            formattedCondition = `${attribute} ${conditionHelpText} ${value}`;
            this.ui.brief.addClass("configured");
        } else {
            const forwardingExpr = _.unescape(this.model.get("forwardingExpr"));
            if (forwardingExpr && outputStream) {
                formattedCondition = forwardingExpr;
                this.ui.brief.addClass("configured");
            } else {
                this.ui.brief.removeClass("configured");
            }
        }

        this.ui.briefConditions.html(formattedCondition);
        this.ui.briefConditions.prop("title", formattedCondition);
    },

    renderType: async function() {
        const type = this.model.get("type");
        const showBasicAttributePicker = !type || metaObjectHelper.isWAEventDataType(type);
        if (showBasicAttributePicker) {
            this.ui.attributeDataTypeField.show();
        } else {
            this.ui.attributeDataTypeField.hide();
        }
        if (showBasicAttributePicker) {
            this.attribute = UIControl.TextField.create();
        } else {
            this.attribute = UIControl.Select(new FieldDataSource(type), {
                allowClear: true,
                hideSearch: true,
                customCssClass: "light"
            }).create();
        }
        const attributesSrc = await new FieldDataSource(type);
        this.attribute.model.set("value", this.model.get("attribute"));
        let dataType = attributesSrc.find((attr)=>attr.id===this.model.get("attribute"));
        if (dataType) {
            this.model.set("dataTypeFromAttribute", dataType?.description);
        }
        this.listenTo(
            this.attribute.model,
            "change:value",
            function(item) {
                this.model.set("attribute", item.value);
                let dataType = attributesSrc.find((attr)=>attr.id===item.value);
                if (dataType) {
                    this.model.set("dataTypeFromAttribute", dataType?.description);
                }
                this.trigger("modified");
            }.bind(this)
        );

        if (typeof this.model.enabled == "boolean") {
            this.setEnabled(this.model.enabled);
        }
        this.attributeRegion.show(this.attribute);
    },

    renderTQLMode: function() {
        const tqlMode = this.model.get("tqlMode");
        if (tqlMode) {
            this.ui.ruleBuilder.hide();
            this.ui.tqlBuilder.show();
        } else {
            this.ui.ruleBuilder.show();
            this.ui.tqlBuilder.hide();
        }
        this.renderForwardingExpr();
    },

    renderForwardingExpr: function() {
        if (!this.tql) {
            return;
        }

        this.tql.model.set("value", _.unescape(this.model.get("forwardingExpr")));
    },

    toggle: function() {
        this.model.set("isExpanded", !this.model.get("isExpanded"));
    },

    renderAttribute: function() {
        if (!this.attribute) {
            return;
        }

        this.attribute.model.set("value", this.model.get("attribute"));
        this.renderExpanded();
    },

    renderCondition: function() {
        if (!this.conditionType) {
            return;
        }

        this.conditionType.model.set("value", this.model.get("condition"));
        this.renderExpanded();
    },

    renderAttributeDataType: function() {
        if (!this.attributeDataType) {
            return;
        }

        this.attributeDataType.model.set("value", this.model.get("attributeDataType"));
        this.renderExpanded();
    },

    renderHeader: function() {
        if (this.model.get("renderHeader")) {
            this.ui.groupHeader.show();
            this.ui.groupHeaderText.html(`Output stream: ${this.model.get("outputStream") || "Not selected"}`);
        } else {
            this.ui.groupHeader.hide();
        }
    },

    renderCanRemove: function() {
        if (this.model.get("canRemove")) {
            this.ui.deleteCondition.show();
        } else {
            this.ui.deleteCondition.hide();
        }
    },

    renderGroupBy: function() {
        if (this.model.get("groupBy")) {
            this.ui.conditionRoot.addClass("grouped");
        } else {
            this.ui.conditionRoot.removeClass("grouped");
        }
    },

    renderGroupCollapsed: function() {
        if (this.model.get("groupCollapsed")) {
            this.$el.addClass("groupCollapsed");
        } else {
            this.$el.removeClass("groupCollapsed");
        }
    },

    renderValue: function() {
        this.value.model.set("value", this.model.get("value"));
        this.renderExpanded();
    },

    setEnabled: function(enabled) {
        this.model.enabled = enabled;
        this.setCanDelete();
        this.conditionType.setEnabled(enabled);
        this.attributeDataType.setEnabled(enabled);
        this.attribute.setEnabled(enabled);
        this.value.setEnabled(enabled);
        this.value.setViewEnabled();
        this.tql.setEnabled(enabled);
        this.outputStream.setEnabled(enabled);
    },

    setCanDelete: function() {
        this.ui.deleteCondition.toggle(this.model.enabled && this.model.get("canRemove"));
    },

    onRender: function() {
        this.conditionType = UIControl.Select(conditions, {
            allowClear: false,
            hideSearch: true,
            customCssClass: "light"
        }).create();
        this.conditionType.model.set("value", this.model.get("condition"));
        this.listenTo(
            this.conditionType.model,
            "change:value",
            function(item) {
                this.model.set("condition", item.value);
                this.trigger("modified");
            }.bind(this)
        );
        this.conditionTypeRegion.show(this.conditionType);

        this.attributeDataType = UIControl.Select(dataTypes, {
            allowClear: false,
            hideSearch: true,
            customCssClass: "light"
        }).create();
        this.attributeDataType.model.set("value", this.model.get("attributeDataType"));
        this.listenTo(
            this.attributeDataType.model,
            "change:value",
            function(item) {
                this.model.set("attributeDataType", item.value);
                this.trigger("modified");
            }.bind(this)
        );
        this.attributeDataTypeRegion.show(this.attributeDataType);

        this.value.model.set("value", this.model.get("value") ?? "");
        this.listenTo(
            this.value.model,
            "change:value",
            function(item) {
                this.model.set("value", item.value ?? "");
                this.trigger("modified");
            }.bind(this)
        );
        this.valueRegion.show(this.value);

        var streams = new MetaObjectDataSource([metaStoreService.entities.STREAM]);
        this.outputStream = UIControl.Select(streams, {
            addNew: true,
            type: metaStoreService.entities.STREAM
        }).create();
        this.outputStream.model.set("value", this.model.get("outputStream"));
        this.listenTo(
            this.outputStream.model,
            "change:value",
            function(item) {
                this.model.set("outputStream", item.value);
                this.trigger("modified");
                this.renderType();
            }.bind(this)
        );
        this.outputStreamRegion.show(this.outputStream);

        this.tql = UIControl.TextArea.extend({
            skipXss: true
        }).create();
        this.listenTo(
            this.tql.model,
            "change:value",
            function(item) {
                this.model.set("forwardingExpr", item.value);
                this.trigger("modified");
            }.bind(this)
        );

        this.tqlRegion.show(this.tql);

        this.renderExpanded();
        this.renderType();
        this.renderTQLMode();
        this.renderForwardingExpr();
        this.renderCondition();
        this.renderAttribute();
        this.renderValue();
        this.renderAttributeDataType();
        this.renderHeader();
        this.renderGroupBy();
        this.renderGroupCollapsed();
    }
});

export default Condition;
