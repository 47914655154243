const colors = {
    colorWhite: "#FEFEFF",
    colorRed: "#ed4a12",
    backgroundRed: "rgba(237,74,18,0.15)",
    colorGreen: "#0d874c",
    backgroundGreen: "rgba(147,192,22,0.15)",
    textLight: "#4E5C62",
    backgroundLight: "rgba(78,92,98,0.25)",
    created: "#4e5c62",
    deployed: "#00a7e5",
    running: "#0d874c",
    invalid: "#dd3711",
    halted: "#F9A825",
    box: "#D8F1FC"
};

export default colors;
