export default {
    /**
     * Check if the visualization supports queries
     * @param {object}
     * @returns {boolean}
     */
    supportsQuery: function (visualization) {
        return (
            visualization &&
            visualization.visualizationType &&
            visualization.visualizationType.toLowerCase() !== "label"
        );
    },

    supportsViewModeControls: function (visualization) {
        return (
            visualization &&
            visualization.visualizationType &&
            visualization.visualizationType.toLowerCase() !== "selectvisualization"
        );
    },
};
