/**
 * For simple sources without any validation stuff. Straight up config wizards.
 */

import Backbone from "backbone";
import baseSimpleSource from "app/components/appwizard/component-wizards/wizards/source/base-simple-source/base-simple-source";

var module = {};
module.View = baseSimpleSource.View.extend({
    label: "Incremental Batch",
    displayName: "Source Database",
    setAdapterProperties: function() {
        this.model.set("adapter", {
            handler: "Global.PROPERTYTEMPLATE.IncrementalBatchReader",
            properties: {}
        });
    }
});
module.vent = new Backbone.Model();

export default module;
