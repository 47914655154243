import _ from "underscore";
import BaseControl from "./../base-control";
import ControlLabel from "./../../control-label";
import FormField from "./../../form-field";
import template from "./cache-query-properties.html";
import FieldDataSource from "app/components/common/editor/control/select/field-datasource";
import $__app_components_common_editor_control_ui_control from "app/components/common/editor/control/ui-control";
import ensureRegions from "../../../_ensureRegions";

var View;
var refreshTypes = {
    no: "no",
    firstLoad: "firstLoad",
    startTime: "startTime"
};

View = BaseControl.View.extend({
    template: _.template(template),
    className: "cache-query-properties",
    regions: {
        lookupKeyLabelRegion: ".lookupKeyLabel",
        lookupKeyRegion: ".lookupKey",

        skipInvalidLabelRegion: ".skipInvalidLabel",
        skipInvalidRegion: ".skipInvalid",

        refreshTypeLabelRegion: ".refreshTypeLabel",
        refreshTypeRegion: ".refreshType",

        refreshIntervalLabelRegion: ".refreshIntervalLabel",
        refreshIntervalRegion: ".refreshInterval",

        refreshStartTimeLabelRegion: ".refreshStartTimeLabel",
        refreshStartTimeRegion: ".refreshStartTime",

        replicasLabelRegion: ".replicasLabel",
        replicasRegion: ".replicas",

        persistPolicyLabelRegion: ".persistPoilcyLabel",
        persistPolicyRegion: ".persistPolicy"
    },

    ui: {
        refreshIntervalRow: ".refresh-interval-row",
        refreshStartTimeRow: ".refresh-start-time-row",
        persistPolicyRow: ".persist-policy-row",
        skipInvalidRow: ".skipInvalid-row",
        replicasRow: ".replicas-row",
        refreshTypeRow: ".refreshType-row"
    },

    initialize: function(options) {
        this._showSkipInvalid = typeof options.showSkipInvalid === "undefined" ? true : options.showSkipInvalid;
        this._showReplicas = typeof options.showReplicas === "undefined" ? true : options.showReplicas;
        this._showRefreshType = typeof options.showRefreshType === "undefined" ? true : options.showRefreshType;
        this._showPersistPolicy = options.showPersistPolicy === true;

        var _this = this;
        BaseControl.View.prototype.initialize.apply(this, arguments);
        var uiControl = $__app_components_common_editor_control_ui_control;

        this.initialLookupKeyValueSet = false;

        this.on("change:type:projections", function(type_id) {
            _this.typeId = type_id;
            if (_this.lookupKey) {
                _this.lookupKey.setDataSource(new FieldDataSource(_this.typeId));

                if (_this.initialLookupKeyValueSet) {
                    _this.lookupKey.model.value = null;
                }
            }
        });

        this.lookupKeyLabel = new ControlLabel({
            model: new FormField.Model({
                label: "Key to map",
                description: "Caches are indexed by their key to map",
                isSubform: true,
                isRequired: true
            })
        });
        this.lookupKey = uiControl.Select(new FieldDataSource(this.typeId)).create();
        this.listenTo(this.lookupKey.model, "change:value", function() {
            _this.setValueFromView();
        });

        this.skipInvalidLabel = new ControlLabel({
            model: new FormField.Model({
                label: "Skip invalid",
                description: "Skip invalid records",
                isSubform: true
            })
        });
        this.skipInvalid = uiControl.Toggle.create();
        this.listenTo(this.skipInvalid.model, "change:value", function() {
            _this.setValueFromView();
        });

        this.replicasLabel = new ControlLabel({
            model: new FormField.Model({
                label: "Replicas",
                description: "",
                isSubform: true
            })
        });

        var replicasDs = [
            {
                id: "all",
                text: "All"
            }
        ];
        this.replicas = uiControl
            .Select(replicasDs, {
                addNew: true,
                addNewText: "",
                placeholder: "Type replicas count or select 'All'"
            })
            .create();
        this.listenTo(this.replicas.model, "change:value", function() {
            _this.setValueFromView();
        });

        this.refreshTypeLabel = new ControlLabel({
            model: new FormField.Model({
                label: "Cache Refresh",
                description: "",
                isSubform: true,
                isRequired: true
            })
        });

        var refreshDs = [
            {
                id: refreshTypes.no,
                text: "No refresh"
            },
            {
                id: refreshTypes.firstLoad,
                text: "Refresh After First Load"
            },
            {
                id: refreshTypes.startTime,
                text: "Refresh At Start Time"
            }
        ];
        this.refreshType = uiControl
            .Select(refreshDs, {
                allowClear: false,
                hideSearch: true
            })
            .create();
        this.listenTo(this.refreshType.model, "change:value", function(viewModel) {
            if (viewModel.value === refreshTypes.firstLoad) {
                _this.ui.refreshIntervalRow.show();
                _this.ui.refreshStartTimeRow.hide();
                _this.refreshStartTime.setValue(null);
            } else if (viewModel.value === refreshTypes.startTime) {
                _this.ui.refreshIntervalRow.show();
                _this.ui.refreshStartTimeRow.show();
            } else {
                _this.ui.refreshIntervalRow.hide();
                _this.ui.refreshStartTimeRow.hide();
                _this.refreshInterval.setValue(null);
                _this.refreshStartTime.setValue(null);
            }

            _this.setValueFromView();
        });

        this.refreshIntervalLabel = new ControlLabel({
            model: new FormField.Model({
                label: "Refresh Interval",
                description: "",
                isSubform: true
            })
        });
        this.refreshInterval = uiControl.Interval.create();
        this.listenTo(this.refreshInterval.model, "change:value", function() {
            _this.setValueFromView();
        });

        this.refreshStartTimeLabel = new ControlLabel({
            model: new FormField.Model({
                label: "Refresh Start Time",
                description: "",
                isSubform: true
            })
        });
        this.refreshStartTime = uiControl.TimePicker();
        this.listenTo(this.refreshStartTime.model, "change:value", function() {
            _this.setValueFromView();
        });

        if (this._showPersistPolicy) {
            this.persistPolicyLabel = new ControlLabel({
                model: new FormField.Model({
                    label: "Persist policy", //,
                    //description: "Persist policy"
                    isSubform: true
                })
            });
            this.persistPolicy = uiControl.Toggle.create();
            this.listenTo(this.persistPolicy.model, "change:value", function() {
                _this.setValueFromView();
            });
        }
    },
    onRender: function() {
        ensureRegions(this);
        var value = this.getValue();

        this.lookupKey.setValue(value.keytomap);
        this.initialLookupKeyValueSet = true;

        this.lookupKeyLabelRegion.show(this.lookupKeyLabel);
        this.lookupKeyRegion.show(this.lookupKey);

        if (typeof value.skipinvalid === "undefined") {
            this.skipInvalid.setValue(false);
        } else {
            this.skipInvalid.setValue(JSON.parse(value.skipinvalid));
        }
        if (this._showSkipInvalid) {
            this.skipInvalidLabelRegion.show(this.skipInvalidLabel);
            this.skipInvalidRegion.show(this.skipInvalid);
        } else {
            this.ui.skipInvalidRow.hide();
        }

        if (this._showReplicas) {
            this.replicasLabelRegion.show(this.replicasLabel);
            this.replicasRegion.show(this.replicas);
        } else {
            this.ui.replicasRow.hide();
        }
        this.replicas.setValue(value.replicas);

        var initialType = refreshTypes.no;
        if (value.refreshstarttime) {
            initialType = refreshTypes.startTime;
        }
        if (value.refreshinterval && !value.refreshstarttime) {
            initialType = value.refreshinterval ? refreshTypes.firstLoad : refreshTypes.no;
        }
        if (this._showRefreshType) {
            this.refreshTypeLabelRegion.show(this.refreshTypeLabel);
            this.refreshTypeRegion.show(this.refreshType);
        } else {
            this.ui.refreshTypeRow.hide();
        }

        this.refreshType.setValue(initialType);

        this.refreshInterval.setValue(value.refreshinterval);
        this.refreshIntervalLabelRegion.show(this.refreshIntervalLabel);
        this.refreshIntervalRegion.show(this.refreshInterval);

        this.refreshStartTime.setValue(value.refreshstarttime);
        this.refreshStartTimeLabelRegion.show(this.refreshStartTimeLabel);
        this.refreshStartTimeRegion.show(this.refreshStartTime);

        if (this._showPersistPolicy) {
            this.persistPolicyLabelRegion.show(this.persistPolicyLabel);
            this.persistPolicyRegion.show(this.persistPolicy);

            if (typeof value.persistPolicy === "undefined") {
                this.persistPolicy.setValue(true);
            } else {
                this.persistPolicy.setValue(JSON.parse(value.persistPolicy));
            }
        } else {
            this.ui.persistPolicyRow.hide();
        }

        BaseControl.View.prototype.onRender.apply(this, arguments);
    },

    setViewEnabled: function() {
        BaseControl.View.prototype.setViewEnabled.apply(this);
        var enabled = this.getEnabled();
        this.lookupKey.setEnabled(enabled);
        if (this._showSkipInvalid) {
            this.skipInvalid.setEnabled(enabled);
        }
        if (this._showReplicas) {
            this.replicas.setEnabled(enabled);
        }
        if (this._showRefreshType) {
            this.refreshType.setEnabled(enabled);
        }
        this.refreshInterval.setEnabled(enabled);
        this.refreshStartTime.setEnabled(enabled);
        if (this._showPersistPolicy) {
            this.persistPolicy.setEnabled(enabled);
        }
    },

    getViewValue: function() {
        var val = {};

        var lookupValue = this.lookupKey.getValue();
        if (lookupValue) {
            val.keytomap = lookupValue;
        }

        if (this._showSkipInvalid) {
            val.skipinvalid = this.skipInvalid.getValue();
        }

        var intervalValue = this.refreshInterval.getValue();
        if (intervalValue) {
            val.refreshinterval = intervalValue;
        }

        var startTimeValue = this.refreshStartTime.getValue();
        if (startTimeValue) {
            val.refreshstarttime = startTimeValue;
        }

        if (this._showReplicas) {
            var replicasValue = this.replicas.getValue();
            if (replicasValue) {
                val.replicas = replicasValue;
            }
        }

        if (this._showPersistPolicy) {
            val.persistPolicy = this.persistPolicy.getValue();
        }

        return val;
    },

    setViewValue: function() {}
});

export default _.extend({}, BaseControl, {
    View: View
});
