import securityService from "core/services/securityService/securityService";
import templates from "app/components/appwizard/templates";

const combinedLicences = () =>{
    let licenseOptions = securityService.licensingOptions;
    if(licenseOptions.length === 0){
        licenseOptions = ["default"];
    }

    return licenseOptions
        .map((license) => {
            if(templates.licensingOptions[license]){
                return templates.licensingOptions[license];
            }
            else{
                return  templates.licensingOptions["default"]
            }

        })
        .flat();
}

export default combinedLicences;
