import App from "app";
import ComponentController from "lib/controllers/component-controller";
import api from "core/api/api";
import growl from "app/components/common/growl";
import FileSaver from "file-saver";
import filterModel from "core/filterModel";
import iframeDetector from "core/iframeDetector";
import ModalManager from "app/components/common/modal/ModalManager";
import UrlBuilder from "app/components/dashboard/url/url-builder";
import Toolbar from "./toolbar/toolbar";
import template from "./dashboard.html";
import utils from "core/utils";
import EditPageDialog from "./EditPageDialog";
import "app/components/dashboard/sidemenu/sidemenu";
import "app/components/dashboard/toolbar/toolbar";
import "app/components/dashboard/grid/component";
import "app/components/dashboard/page";
import headerStore from "../../../src/stores/header";
import { isEmbedDashboard } from "./embedDashboard";
import Tracker from "core/services/tracker/tracker";
import { toJS } from "mobx";
import ensureRegions from "../common/_ensureRegions";
import { mousePos } from "../common/drag-and-drop";
import { TRACKER_STRINGS } from "../../../core/services/tracker/constants";

App.module(
    "Dashboard",
    function(Dashboard, App, Backbone, Marionette, $, _) {
        // Dashboard level event - refresh when dashboard is renamed on listing page
        this.listenTo(App.vent, "dashboard:rename", function() {
            Dashboard.controller.destroy_existing_dashboard();
        });

        // Dashboard level event, navigate but also include the header
        this.listenTo(App.vent, "dashboard:navigate", function(dashboard_id, page_id, options) {
            var nsName = utils.getNamespace(dashboard_id);
            var name = utils.getName(dashboard_id);
            var short_dashboard_id = nsName + "." + name;
            options = options || {};

            var urlBuilder = new UrlBuilder();
            urlBuilder.setFragment("dashboard");

            var editable = false;
            if (options.editable === true) {
                urlBuilder.setPath("edit");
                editable = true;
            }

            var queryString = "";
            if (page_id) {
                App.navigate(
                    urlBuilder
                        .setPath(short_dashboard_id)
                        .setPath(page_id)
                        .setQueryParam("pageparams", options.pageparams)
                        .build()
                );
                queryString = urlBuilder.getQueryString();
            }

            Dashboard.controller.begin_show_dashboard(dashboard_id, page_id, queryString, editable);
        });

        // Dashboard event: Navigate to a page
        this.listenTo(App.vent, "dashboard:navigate:page", function(dashboard_id, page_id, options) {
            options = options || {};
            var nsName = utils.getNamespace(dashboard_id);
            var name = utils.getName(dashboard_id);
            var short_dashboard_id = nsName + "." + name;

            var urlBuilder = new UrlBuilder();
            urlBuilder.setFragment("dashboard");

            var editable = false;
            if (options.editable === true) {
                urlBuilder.setPath("edit");
                editable = true;
            } else {
                urlBuilder.setPath("view");
            }

            urlBuilder.setPath(short_dashboard_id);
            if (page_id) {
                urlBuilder.setPath(page_id);
            }

            App.navigate(urlBuilder.setQueryParam("pageparams", options.pageparams).build());
            Dashboard.controller.show_dashboard_page(page_id, editable, options);
        });

        // Dashboard event: Update Page Params
        this.listenTo(App.vent, "dashboard:page:updateparams", function(options) {
            options = options || {};
            var pageparams = options.pageparams;
            var silent = options.silent || false;
            Dashboard.controller.update_page_params(pageparams, silent);
        });

        // Dashboard event: Update Page Params
        this.listenTo(App.vent, "dashboard:page:clearparams", function(/*options*/) {
            Dashboard.controller.clear_page_params();
            App.execute("QueryManager:clearParameters");
        });

        /****************************************************************************
         * Dashboard.View
         * Renders the layout for the dashboard
         * Adds Regions:
         *      toolbar: ".toolbar .breadcrumbs",
         *      page: ".visualizations",
         *      sideMenu: ".side-menu"
         *
         */
        Dashboard.View = Backbone.Marionette.LayoutView.extend({
            id: "dashboard",
            template: _.template(template),
            regionTemplate: _.template('<div class="visualization-region"></div>'),
            layoutTemplate: _.template('<div class="layout-region"></div>'),
            regions: {
                toolbar: ".toolbar",
                page: ".visualizations",
                sideMenu: ".side-menu"
            },
            ui: {
                toolbar: ".toolbar",
                page: ".visualizations",
                sideMenu: ".side-menu"
            },
            initialize: function() {
                // TODO: ideally we shouldn"t be touching the HTML tag...
                this.listenTo(this, "before:destroy", function() {
                    /**close all open modals**/
                    Dashboard.ModalManager.remove_all_modals();
                    const $html = $("html");
                    $html.removeClass("dashboard");
                    $html.removeClass("embed");
                });
                this.listenTo(this, "render", function() {
                    const $htmlEl = $("html");
                    $htmlEl.addClass("dashboard");
                    if (isEmbedDashboard()) {
                        $htmlEl.addClass("embed");
                    }
                });

                this.listenTo(App.vent, "dashboard:mode:toggle", this._toggleMode);
                this.listenTo(App.vent, "dashboard:export", this.export);

                // Autoscroll when a component is resized or moved
                this.listenTo(App.vent, "component:resize component:move", function(component, e, dd) {
                    this.autoscroll(e, dd);
                });
                // Switch pages on drilldown event
                this.listenTo(App.vent, "dashboard:drilldown", function(page, pageparams) {
                    let params = pageparams ? pageparams.toJSON() : {};
                    if (typeof page !== "undefined" && page !== "") {
                        App.vent.trigger("dashboard:navigate:page", this.model.id, page, {
                            editable: this.model.get("_editable"),
                            pageparams: params
                        });
                    } else {
                        App.vent.trigger("dashboard:page:updateparams", {
                            pageparams: params
                        });
                    }
                });

                // Change modes between view and edit
                this.listenTo(this.model, "change:_editable", function() {
                    /***Destroy all modals before changing the mode*/
                    Dashboard.ModalManager.remove_all_modals();
                    this._renderMode();
                });
                this.pageParameters = {};
            },
            autoscroll: function(ev, dd) {
                var tolerance = 50;
                var scroll_top = $(document).scrollTop();

                var page_pos_y = mousePos.pageY - scroll_top;
                var going_down = dd.offsetY > dd.lastY;
                var going_up = dd.offsetY < dd.lastY;

                var scroll_down = page_pos_y > $(window).height() - tolerance;
                var scroll_up = page_pos_y < this.$el.offset().top; // TODO: cache the "top" value

                var scroll_abs = Math.abs(dd.offsetY - dd.lastY);
                var scroll = going_up && scroll_up ? scroll_abs * -1 : going_down && scroll_down ? scroll_abs : 0;

                if (scroll !== 0) {
                    window.scrollTo(0, scroll_top + scroll);
                }
                dd.lastY = dd.offsetY;
            },
            set_sidebar_state: function() {
                if (iframeDetector.isInIframe()) {
                    return;
                }

                if (this.model._editable) {
                    this.model._menuopen = true;
                }

                var m = this.getRegion("sideMenu").currentView.model;

                if (!this.model._editable && m.selected === "visualizations-button") {
                    $(this.getRegion("page").el).removeClass("visualizations-menu-open");
                    m.selected = null;
                } else {
                    m.buttons.get("visualizations-button").enabled = this.model._editable;

                    var open = this.model._menuopen;
                    if (open) {
                        if (m.selected === null) {
                            m.selected = "visualizations-button";
                        }
                    } else {
                        m.selected = null;
                    }
                }
            },
            render_content_width: function() {
                var open = this.model._menuopen;
                if (open) {
                    $(this.getRegion("page").el).addClass("visualizations-menu-open");
                } else {
                    $(this.getRegion("page").el).removeClass("visualizations-menu-open");
                }
            },
            onRender: function() {
                ensureRegions(this);
                var _this = this;

                // Set up a modal manager for the page
                Dashboard.ModalManager = new ModalManager({
                    container: this.$el,
                    freeze: this.$(".visualizations")
                });

                // Show the sidebar
                var sidebarmodel = new App.Dashboard.SideMenu.Model({
                    dashboard: this.model, // TODO: remove
                    pages: this.model.pages,
                    buttons: this.model.sections
                });
                this.listenTo(App.vent, "dashboard:menu:open", function() {
                    this.model._menuopen = true;
                });
                this.listenTo(App.vent, "dashboard:menu:close", function() {
                    this.model._menuopen = false;
                });

                if (!iframeDetector.isInIframe()) {
                    this.getRegion("sideMenu").show(
                        new App.Dashboard.SideMenu.View({
                            model: sidebarmodel
                        })
                    );
                } else {
                    this.$(".toolbar").remove();
                    this.$(".side-menu").remove();
                }

                // Handle sidebar open/closed states
                // TODO: add a menuopen state to Dashboard model, work sidebar off of it as well
                // TODO: Ensure this works as expected
                this.render_content_width(this.model._menuopen);

                this.listenTo(this.model, "change:_editable", function() {
                    this.set_sidebar_state();
                });

                this.listenTo(this.model, "change:_menuopen", this.render_content_width);

                this._renderMode();

                if (iframeDetector.isInIframe()) {
                    return;
                }
                var toolbarModel = new Toolbar.Model({
                    embeddable: iframeDetector.getIframe(),
                    editable: this.model._editable,
                    dashboardID: this.model.get("id"),
                    isPrebuilt: this.model.isPrebuilt,
                    hasEditAccessRights: App.userPermissions["*:*:dashboard_ui_edit:*"]
                });
                this.toolbarView = new Toolbar.View({
                    model: toolbarModel
                });
                this.getRegion("toolbar").show(this.toolbarView);

                // render a handler to get all the pages list for editor.
                App.reqres.setHandler("dashboard:current:get_pages", function() {
                    return _this.model.get("pages");
                });
            },
            export: function() {
                var _this = this;
                api.exportDashboard(this.model.id)
                    .then(function(json) {
                        if (json) {
                            json = JSON.stringify(JSON.parse(json), null, 2);
                        }

                        FileSaver.saveAs(
                            new Blob([json], {
                                type: "text/plain"
                            }),
                            _this.model.name + "_" + _.now() + ".json"
                        );
                    })
                    .fail(function(e) {
                        growl.error("Error exporting Dashboard: " + e.message);
                    });
            },

            // toggle "edit" or "view" mode of dashboard
            _toggleMode: function(editable) {
                var page = this.model.pages.findWhere({
                    name: this.model.current_page
                });
                page.components.each(function(c) {
                    c._active = false;
                });

                var pageparams = $.isEmptyObject(page.get("params")) ? "" : page.get("params");
                App.vent.trigger("dashboard:navigate:page", this.model.id, this.model.current_page, {
                    editable: editable,
                    pageparams: pageparams
                });

                if (editable) {
                    Tracker.getInstance().track(TRACKER_STRINGS.schema.dashboard,{
                        id: `${this.model.nsName}.${this.model.name}`,
                        event: TRACKER_STRINGS.eventClicked.dashboard,
                        buttonClicked:"Edit",
                    });
                }
            },
            _renderMode: function() {
                // Enable edit or view mode
                this.$el.removeClass("edit-mode view-mode");
                if (this.model._editable) {
                    this.$el.addClass("edit-mode");
                } else {
                    this.$el.addClass("view-mode");
                }
            },
            show_page: function(id, options) {
                // TODO: id is actually page name (need to change)
                var _this = this;
                /*var show_breadcrumbs = function() {
                    if (!iframeDetector.isInIframe()) {
                        this.getRegion("toolbar").currentView.model.pages.reset([
                            {
                                dashboard_url: this.model.id,
                                page: this.model,
                                page_title: this.model.nsName + "." + (this.model.title || this.model.name)
                            },
                            {
                                dashboard_url: this.model.id,
                                page_url: pagemodel.id,
                                page: pagemodel,
                                page_title: "Dashboard: " + pagemodel.title || pagemodel.name
                            }
                        ]);
                    }
                }.bind(this);*/
                var show_page = function() {
                    // After page has shown, open or close the sidebar as needed
                    this.set_sidebar_state();

                    var page = new App.Dashboard.Page({
                        model: pagemodel
                    });

                    this.getRegion("page").show(page);
                }.bind(this);

                // The page to show
                var pagemodel =
                    this.model.get("pages").findWhere({
                        name: id
                    }) || this.model.get("pages").get(id);

                if (!pagemodel) {
                    return console.warn("Cannot get requested page", id, " from dashboard pages", this.model.pages);
                }

                this.getRegion("page").empty();
                pagemodel.fetch().then(function() {
                    var currentPageParams = _this.getPageParametersForPage(pagemodel.get("id"));
                    if (_.isObject(options) && _.isObject(options.pageparams)) {
                        currentPageParams = options.pageparams;
                    } else {
                        if (typeof currentPageParams === "undefined") {
                            currentPageParams = pagemodel.defaults().params;
                        }
                    }

                    var queryVisualizations = [];
                    for (var i = 0; i < pagemodel.queryVisualizations.length; i++) {
                        var qv = pagemodel.queryVisualizations.at(i);
                        queryVisualizations.push(qv);
                    }
                    var complete = _.invoke(queryVisualizations, "fetch");
                    $.when.apply($, complete).then(function() {
                        var queryFetches = pagemodel.queryVisualizations
                            .chain()
                            .filter(function(qv) {
                                return qv.query.id;
                            })
                            .map(function(qv) {
                                return qv.query.fetch();
                            })
                            .value();
                        $.when.apply($, queryFetches).then(function() {
                            pagemodel.set("params", currentPageParams);
                            _this.setPageParametersForPage(pagemodel.get("id"), currentPageParams);
                            pagemodel.trigger("query:filter", currentPageParams);

                            //show_breadcrumbs();
                            show_page();

                            var hasAnyTimeFilterableChart = Array.prototype.slice.call(arguments).some(function(item) {
                                return item.canBeFiltered;
                            });
                            var hasAnyFullTextSearchFilterableChart = Array.prototype.slice
                                .call(arguments)
                                .some(function(item) {
                                    return item.canBeFiltered || item.hasFullTextSearchQueryParam;
                                });
                            if (_this.toolbarView) {
                                _this.toolbarView.toggleTimeFilter(hasAnyTimeFilterableChart);
                                _this.toolbarView.toggleFullTextSearchFilter(hasAnyFullTextSearchFilterableChart);
                            }
                            App.vent.trigger("change:dashboard:current_page", pagemodel);
                        });
                    });
                });
            },
            getPageParametersForPage: function(pageId) {
                return this.pageParameters[pageId];
            },
            setPageParametersForPage: function(pageId, params) {
                this.pageParameters[pageId] = params;
            }
        });

        Dashboard.Controller = ComponentController.extend({
            initialize: function() {
                this.listenTo(App.vent, "breadcrumbs:click", this.request_navigation);

                var _this = this;
                this.listenTo(App.vent, "dashboard:show_page", function(page_id) {
                    // Pass the dashboard id, page id
                    var dashboard_id = this.model.id;
                    var options = {
                        editable: _this.model._editable
                    };
                    this.request_navigation(dashboard_id, page_id, options);
                });

                this.listenTo(App.vent, "dashboard:add_page", function() {
                    var newPage = App.request("new:metadata:page");
                    var n = _.uniqueId();
                    newPage.set({
                        title: "New Page " + n,
                        nsName: this.model.nsName,
                        name: this.model.name + "Page" + n
                    });
                    newPage.save().then(
                        function() {
                            this.model.get("pages").add(newPage);
                            this.model.save().then(
                                function() {
                                    this.view.sideMenu.currentView.model.pages = this.model.pages;
                                    this.view.sideMenu.currentView.render();

                                    this.request_navigation(this.model.id, newPage.name, {
                                        editable: true
                                    });
                                    growl.success("New page added", "Success", this.model.id);
                                }.bind(this)
                            );
                        }.bind(this)
                    );
                });

                this.listenTo(App.vent, "dashboard:edit_page", function(id) {
                    var page = this.model.pages.findWhere({
                        id: id
                    }); // Using ID, rather than name, here

                    var editPageModel = new EditPageDialog.Model();
                    editPageModel.title = page.title;
                    editPageModel.background = page.settings.background || "transparent";

                    var dialog = new EditPageDialog.Dialog({
                        contentview: new EditPageDialog.DialogBody({
                            model: editPageModel
                        }),
                        model: new Backbone.Model({
                            title: "Edit page",
                            page: page,
                            editModel: editPageModel
                        })
                    });
                    new App.FormMixinDialog.Controller({
                        view: dialog
                    });

                    dialog.on("form:submit", function(dialog) {
                        page.save();
                        App.vent.trigger("dashboard:side-menu:page-name-changed", page);
                        dialog.destroy();
                    });

                    Dashboard.ModalManager.add(dialog);
                });

                this.listenTo(App.vent, "dashboard:delete_page", async function(name) {
                    if (this.model.pages.length === 1) {
                        growl.error(
                            "You cannot delete this page because it is the only page in this Dashboard. Please add another page and then delete this one."
                        );
                        return;
                    }


                    // If this is the current page, first navigate away to another page
                    var another_page = this.model.pages.find(function(page) {
                        return page.name !== name;
                    });
                    this.model.defaultLandingPage = another_page.name;


                    // Then, remove this page from the Dashboard"s pages collection,
                    // and destroy the page metaobject itself.
                    var page = this.model.pages.findWhere({
                        name: name
                    });
                    this.model.pages.remove(page.id);
                    let pageid = this.model.id;
                    this.model.save().then(function() {
                        growl.success(null, "Page " + page.title + " was deleted", pageid);
                        // TODO: destroying the page synchronously after saving the Dash causes server errors.
                        // This does not.
                        page.destroy();
                    });

                    if (another_page) {
                        this.request_navigation(this.model.id, another_page.name, {
                            editable: this.model._editable
                        }); //navigate finally.
                    }
                    try {
                        //set the default page to another page in dashboardStore
                        let storeDashboard = toJS(
                            await contextStore.dashboardsStore.getDashbordByUUID(this.model.uuid)
                        );
                        storeDashboard = {
                            ...storeDashboard,
                            defaultLandingPage: this.model.defaultLandingPage,
                            pages:this.model.pages
                        };
                        await contextStore.dashboardsStore.dashboardUpdate(storeDashboard);
                    } catch (error) {
                        console.error(error);
                    }
                });
            },
            // The dashboard may request to navigate to the Dashboard home page, or to another page.
            // If we navigate to a new Dashboard or page, clear existing current_page pointers, etc.
            // The Dashboard router listens to the dashboard:navigate event.
            request_navigation: function(dashboard_url, page_url, options) {
                // If we"re navigating within the Dashboard, just change the page without reloading entire controller

                if (page_url) {
                    var page = this.model.pages.findWhere({
                        name: page_url
                    });
                    if (!page) {
                        return;
                    }
                    var pageparams = $.isEmptyObject(page.get("params")) ? "" : page.get("params");

                    if (pageparams) {
                        _.extend(options, {
                            pageparams: pageparams
                        });
                    }
                }

                filterModel.clearParameters();

                if (this.model && this.model.id === dashboard_url) {
                    App.vent.trigger("dashboard:navigate:page", dashboard_url, page_url, options);
                } else {
                    App.vent.trigger("dashboard:navigate", dashboard_url, page_url, options);
                }
            },
            // If a dashboard was already being displayed, destroy the view (and model)
            destroy_existing_dashboard: function() {
                if (!this.model) {
                    return false;
                }
                this.model = null;
                if (this.getView()) {
                    this.getView().destroy();
                }
            },
            // Fetch the Dashboard, and set up event listeners -- change page, add new page, etc.
            // and set the current schema (namespace)
            fetch_dashboard: function(id) {
                var _this = this;
                this.model = new App.Metadata.Dashboards.DashboardModel({
                    id: id
                });
                var deferred = new $.Deferred();
                this.model
                    .fetch()
                    .then(function() {
                        api.setCurrentNamespace(_this.model.nsName);
                        deferred.resolve();
                    })
                    .fail(function() {
                        deferred.reject();
                    });

                return deferred.promise();
            },
            // Once the Dashboard has been fetched completely
            show_dashboard_page: function(page, editable, options) {
                if (this.model.isPrebuilt && editable) {
                    return;
                }

                this.model.set("_editable", editable);
                var do_show = function() {
                    var view = this.getView();
                    view.show_page(page, options);

                    this.model.set({
                        current_page: page // page name
                    });
                }.bind(this);
                headerStore.addToLatestDashboards({
                    id: this.model.id,
                    nsName: this.model.nsName,
                    name: this.model.name,
                    title: this.model.attributes?.title || this.model.name,
                    page: page,
                    editable: editable
                });
                // If we specified a page in the URL, e.g. FooDashboard/FooPage, load that page
                if (page) {
                    if (!this.getView()) {
                        var view = new Dashboard.View({
                            model: this.model
                        });
                        this.listenTo(view, "show", function() {
                            do_show();
                        });
                        this.setView(view);
                        this.show(App.getRegion("content"));
                    } else {
                        do_show();
                    }
                } else {
                    // If the user typed in a URL like FooDashboard/, get the default page of the dashboard,
                    // and hard-redirect the user to that page.
                    // This is so the router can update the URL in the address bar.
                    // The router will re-initialize this controller, which will then have the page specified.
                    var default_page = this.model.defaultLandingPage || this.model.pages[this.model.pages.length - 1];
                    this.request_navigation(this.model.id, default_page, {
                        replace: true,
                        trigger: true,
                        editable: this.model._editable
                    });
                }
            },
            begin_show_dashboard: function(id, page, editable, options) {
                var new_dashboard = !this.model || this.model.id !== id;
                if (new_dashboard) {
                    this.destroy_existing_dashboard();
                    this.fetch_dashboard(id).then(
                        function() {
                            // When the model has fully synced, try to load the requested page, if there is one.
                            // Otherwise, re-route to the default page
                            this.show_dashboard_page(page, editable, options);
                        }.bind(this)
                    );
                } else {
                    // Else, just load the requested page
                    this.show_dashboard_page(page, editable, options);
                }
            },
            persistInUrl: function(pageparams) {
                var urlBuilder = new UrlBuilder();
                urlBuilder.setFragment("dashboard");
                urlBuilder.setPath(this.model.get("id"));
                if (this.model.get("current_page")) {
                    urlBuilder.setPath(this.model.get("current_page"));
                }
                if (pageparams) {
                    urlBuilder.setQueryParam("pageparams", pageparams);
                }
                App.navigate(urlBuilder.build());
            },
            update_page_params: function(newPageParameters /*, silent*/) {
                var page = this.model.pages.findWhere({
                    name: this.model.get("current_page")
                });
                var currentPageParameters = page.get("params");
                for (var key in newPageParameters) {
                    if (newPageParameters.hasOwnProperty(key)) {
                        if (newPageParameters[key] === null) {
                            delete currentPageParameters[key];
                        } else {
                            currentPageParameters[key] = newPageParameters[key];
                        }
                    }
                }
                App.request("QueryManager:updateParameters", currentPageParameters);
                page.trigger("change:params");
                this.persistInUrl(currentPageParameters);
            },
            clear_page_params: function() {
                var page = this.model.pages.findWhere({
                    name: this.model.get("current_page")
                });
                var emptyParameters = {};
                page.set("params", emptyParameters);
                this.getView().setPageParametersForPage(page.get("id"), emptyParameters);
                this.persistInUrl();
            }
        });
    },
    template
);

const controller = new App.Dashboard.Controller();
App.Dashboard.controller = controller;
export default App.Dashboard;
