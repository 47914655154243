import theme from "../../../theme";
import { ProductTiers } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features";
const purpleColor = "#a61fe0";
import securityService from "core/services/securityService/securityService";

const segmentationVariants = {
    none: "none",
    added: "added",
    addOn: "addOn",
    enterprise: "enterprise",
    enterprisePlus: "enterprisePlus",
    userStandardTier: "userStandardTier",
    developer: "developer"
};

const getChipData = variant => {
    switch (variant) {
        case segmentationVariants.added:
            return { label: "Added", icon: "CheckCircle", color: theme.palette.success.main };

        case segmentationVariants.enterprisePlus: {
            let labelContent = securityService.isSaaSEnvironment() ? "Cloud Mission Critical" : "Enterprise plus";
            return { label: labelContent, icon: "seg_enterpriseplus_tier", color: purpleColor };
        }
        case segmentationVariants.enterprise:
            return { label: "Enterprise", icon: "seg_enterprise_tier", color: theme.palette.primary.main };

        case segmentationVariants.addOn:
            return { label: "Add-on", icon: "seg_add_on", color: theme.palette.warning.main };

        case segmentationVariants.userStandardTier:
            return { label: "Standard", icon: "standard_tier", color: theme.palette.warning.main };

        case segmentationVariants.developer:
            return { label: "Developer", icon: "seg_enterprise_tier", color: theme.palette.primary.main };

        case segmentationVariants.none:
        default:
            return { label: "", icon: "", color: "" };
    }
};

const getVariants = () => {
    return Object.values(segmentationVariants).map(id => {
        return { id, ...getChipData(id) };
    });
};

const getVariantForLicense = license => {
    let variant = "";

    switch (license) {
        case ProductTiers.STANDARD:
            variant = segmentationVariants.userStandardTier;
            break;

        case ProductTiers.ENTERPRISE:
            variant = segmentationVariants.enterprise;
            break;

        case ProductTiers.ENTERPRISEPLUS:
            variant = segmentationVariants.enterprisePlus;
            break;

        case ProductTiers.DEVELOPER:
            variant = segmentationVariants.developer;
            break;

        default:
            break;
    }

    return variant;
};

export { segmentationVariants, getChipData, purpleColor, getVariants, getVariantForLicense };
