import { useNavigate } from "react-router";

export const homePageInfo = (sourceName, isAppAdapterLicense) => {
    const navigate = useNavigate();

    return {
        SQL2FABRICSOLUTION: {
            imgsrc: "src/modules/apps/pages/homepage/resources/images/fabriconelake.svg",
            imgstyle: {
                width: "-webkit-fill-available"
            },
            button: "Create Pipeline",
            buttonOnClick: function() {
                navigate("/create-app/sql2Fabric");
            },
            welcomeMsgInfo: {
                desc: "Mirror your SQL Server database to Fabric ",
                titleVariant: "h3"
            },
            exploreInfo: {
                title: "Learn more about mirroring your SQL Server data to Fabric",
                videoContainerStyle: {
                    display: "flex"
                }
            }
        },
        DEFAULT: {
            description:
                "Apps are data pipelines that help you stream data from various sources to targets. Get started by building an App using one of our pre-configured wizards, by importing a TQL or start from scratch using the flow designer.",
            imgsrc: "src/modules/apps/pages/homepage/resources/images/homepage-woman.svg",
            button: "Create an App",
            buttonOnClick: function() {
                navigate("/create-app");
            },
            welcomeMsgInfo: {
                desc: `Create your first ${sourceName} App ${isAppAdapterLicense ? "(Pipeline)" : ""}`
            },
            exploreInfo: {
                title: "Learn more about the Striim Platform"
            }
        }
    };
};
