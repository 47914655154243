import React, { useEffect, useRef } from "react";
import { useParams } from "react-router";
import loadBackboneApp from "../../../app/load-backbone-app";
import { TestModel, TestView } from "../../../app/components/debug/tql-test";
import api from "../../../core/api/api";

export default function TestMetaObjectTQL() {
    const initializedRef = useRef(false);
    const { id } = useParams();

    useEffect(() => {
        if (initializedRef.current) {
            return;
        }

        api.setCurrentNamespace("admin");

        const model = new TestModel({ id: id });
        const view = new TestView({ model: model });
        loadBackboneApp(view);
        initializedRef.current = true;
    }, []);

    return <></>;
}
