import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing, palette, breakpoints, shadows }) => ({
    wrapper: {
        cursor: "pointer",
        "&>*": {
            minHeight: 124,
            minWidth: 248
        },
        margin: spacing(2)
    },
    content: {
        paddingTop: 0,
        paddingBottom: "16px !important"
    },
    cardRoot: {
        minHeight: 124,
        flexBasis: "31.3%",
        cursor: "pointer",
        margin: "1%",
        "&:hover": {
            boxShadow: shadows[8]
        },
        [breakpoints.up("sm")]: {
            flexBasis: "31.3%"
        },
        [breakpoints.up("md")]: {
            flexBasis: "23%"
        },
        [breakpoints.up("xl")]: {
            flexBasis: "18%"
        }
    },
    cardHeaderRoot: {
        alignItems: "flex-start",
        padding: spacing(3),
        paddingBottom: 0
    },
    cardHeaderTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    cardHeaderSubHeader: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    cardHeaderContent: {
        overflow: "hidden"
    },
    rowRoot: {
        alignItems: "center",
        backgroundColor: palette.paper.background,
        minHeight: 80,
        padding: `${spacing(2)}px ${spacing(3)}px`,
        margin: spacing(2),
        cursor: "pointer",
        "&:not(:last-child)": {
            marginBottom: 0
        },
        "&:hover": {
            boxShadow: shadows[8]
        }
    },
    rowRootHomepage: {
        justifyContent: "space-between"
    },
    dashboardNames: {
        [breakpoints.up("sm")]: {
            flexDirection: "column"
        },
        [breakpoints.down("xs")]: {
            flexDirection: "row"
        }
    },
    dashboardNumbers: {
        fontWeight: 700
    },
    cardDashboardInfo: {
        display: "flex",
        alignItems: "center"
    },
    dashboardText: {
        width: "inherit",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
}));

export const styles = {
    card: { border: 1, borderColor: "greyscale.200", boxShadow: "none", borderRadius: "8px" },
    divider: { mt: 6, mb: 2, borderColor: "greyscale.200" }
};
