import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box, Grid, Divider } from "@mui/material";
import getApiSentinelMonitorService from "../service/sentinel-moniter-service";
import SentinelMonitorHeader from "./sentinel-monitor-header";
import SentinelTabs, { prepareTabsData } from "../../../sentinel-live-monitor/components/sentinel-tabs/sentinel-tabs";
import dateLib from "core/utils/date-utils";
import {
    AllSentinelsSDIDrillDownEventsChartData,
    AppSDIOccurrencesDetailed,
    EventsProcessedChartData,
    EventsProcessedTableData,
    SDIDrillDownEventsProcessedTableData,
    SDIDrillDownOccurrencesTableData,
    SensitiveDataOccurrencesChartData,
    SensitiveDataOccurrencesForApps
} from "../service/sentinel-monitor.types";
import growl from "../../../../../../app/components/common/growl";
import EventsProcessedChart, { getEventsProcessedTab } from "./sentinel-monitor-tabs/events-processed-tab";
import SensitiveDataChart, { getSensitiveDataTab } from "./sentinel-monitor-tabs/sensitive-data-tab";
import {
    getStartAndEndTimestamps,
    getStartAndEndTimestampsForChart,
    getTimeFormat,
    pollingFn
} from "../../../sentinel-live-monitor/utils";
import SentinelOrAppsTableSDI, {
    SDIDrillDownOccurrencesRow
} from "../../../sentinel-live-monitor/components/sentinel-table/sentinel-table-sdi";

import SentinelTableIdentifierType, {
    SentinelTableTypeRow
} from "../../../sentinel-live-monitor/components/sentinel-table/sentinel-table-identifier-type";
import AllAppsTable, {
    AllAppsTableTypeRow
} from "../../../sentinel-live-monitor/components/sentinel-table/all-apps-table";
import { SelectedSensitiveDataIdentifier } from "../../../sentinel-live-monitor/sentinel-live-monitor";
import { StriimTypography } from "@striim/striim-ui-v2";
import StriimCircularProgress from "../../../../apps/pages/manage-striim/connection-profiles/components/circular-progress/circular-progress";
import { SentinelMonitorContext } from "../sentinel-monitor-context";

export const TitleWithLoader = ({ title }) => {
    return (
        <Box display="flex" height={"36px"} alignItems={"center"}>
            <StriimTypography variant="h4" display="inline" sx={{ marginRight: "8px !important" }}>
                {title}
            </StriimTypography>
            <StriimCircularProgress size={24} sx={{ marginLeft: 1 }} />
        </Box>
    );
};

const getAllAppsTableData = (data: EventsProcessedTableData): AllAppsTableTypeRow[] => {
    const applications = data.applications;
    return Object.keys(applications).map(app => {
        const appNameParts = app.split(".");
        return {
            name: appNameParts[1],
            namespace: appNameParts[0],
            eventsProcessed: applications[app].eventsProcessed,
            eventsWithSensitiveDataTagged: applications[app].eventsWithSensitiveDataTagged,
            eventsWithSensitiveData: applications[app].eventsWithSensitiveData,
            eventsWithoutSensitiveData: applications[app].eventsWithoutSensitiveData
        };
    });
};

const getSDIAllAppsTableData = (data: SDIDrillDownEventsProcessedTableData): SentinelTableTypeRow[] => {
    const applications = data.applications;
    return Object.keys(applications).map(app => {
        const appNameParts = app.split(".");
        return {
            name: appNameParts[1],
            namespace: appNameParts[0],
            events: applications[app].eventsProcessed,
            sentinels: applications[app].sentinels,
            sensitiveData: applications[app].eventsWithSensitiveData,
            nonSensitiveData: applications[app].eventsWithoutSensitiveData,
            sdiSensitiveData: applications[app].eventsWithSDI,
            sdiTaggedSensitiveData: applications[app].eventsTagged
        };
    });
};

const getSDIAllAppsOccurencesTableData = (data: SDIDrillDownOccurrencesTableData): SDIDrillDownOccurrencesRow[] => {
    const applications = data.applications;
    return Object.keys(applications).map(app => {
        const appNameParts = app.split(".");
        return {
            name: appNameParts[1],
            namespace: appNameParts[0],
            label: data.sdi,
            eventsWithIdentifier: applications[app].eventsWithIdentifier,
            occurrences: applications[app].occurrences,
            ENCRYPT: applications[app].ENCRYPT,
            MASK: applications[app].MASK,
            NO_ACTION: applications[app].NO_ACTION,
            sentinelCount: applications[app].sentinels
        };
    });
};

const useLoader = (component, hideLoader, title) => {
    if (!hideLoader) return <TitleWithLoader title={title} />;
    return component;
};

const POLL_INTERVAL = 60_000;
export const TABS = ["1 HOUR", "24 HOURS"];

const SentinelMonitorContent = () => {
    const toTime = dateLib();
    const fromTime = toTime.subtract(24, "hour");
    const formattedToTime = toTime.valueOf();
    const formattedFromTime = fromTime.valueOf();
    const showSensitiveDataTagged = true; //TODO: Change post v1
    const [eventsProcessedDataForApps, setEventsProcessedDataForApps] = useState<EventsProcessedTableData>();
    const [eventsProcessedChartDataForApps, setEventsProcessedChartDataForApps] = useState<EventsProcessedChartData>();
    const [sdiEventsProcessedDataForApps, setSDIEventsProcessedDataForApps] = useState<
        SDIDrillDownEventsProcessedTableData
    >();
    const [sdiEventsProcessedChartDataForApps, setSDIEventsProcessedChartDataForApps] = useState<
        AllSentinelsSDIDrillDownEventsChartData
    >();
    const [sensitiveData, setSensitiveData] = useState<SensitiveDataOccurrencesForApps>();
    const [sensitiveChartData, setSensitiveChartData] = useState<SensitiveDataOccurrencesChartData>();
    const [selectedSDI, setSelectedSDI] = useState<SelectedSensitiveDataIdentifier | null>(null);
    const [liveDataToggled, setLiveDataToggled] = useState<boolean>(true);
    const [appsSDITableData, setAppsSDITableData] = useState<AppSDIOccurrencesDetailed>();
    const [allAppsTableData, setAllAppsTableData] = useState<AllAppsTableTypeRow[]>();
    const [allAppsTableDataFiltered, setAllAppsTableDataFiltered] = useState<AllAppsTableTypeRow[]>();
    const [sdiAllAppsTableData, setSDIAllAppsTableData] = useState<SentinelTableTypeRow[]>();
    const [sdiAllAppsTableDataFiltered, setSDIAllAppsTableDataFiltered] = useState<SentinelTableTypeRow[]>();
    const [sdiOccurrences, setSDIOccurrences] = useState<SDIDrillDownOccurrencesTableData>();
    const [sdiOccurrencesChartData, setSDIOccurrencesChartData] = useState<SensitiveDataOccurrencesChartData>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sdiTableData, setSDITableData] = useState<SDIDrillDownOccurrencesRow[]>();
    const [sdiTableDataFiltered, setSDITableDataFiltered] = useState<SDIDrillDownOccurrencesRow[]>();

    const [appsInfo, setAppsInfo] = useState(null);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const [chartType, setChartType] = useState(TABS[0]);
    const { selectedApps, setSelectedApps, selectedDateRange, setSelectedDateRange, appSentinelMapping } = useContext(
        SentinelMonitorContext
    );

    // Data for Apps dropdown
    const getAppsDropdownData = async () => {
        return await getApiSentinelMonitorService().getRunningAppsWithSentinels();
    };

    const getTabAndTableData = async (currentTabIndex, selectedSDI, selectedDateRange, isOneHourTimeFrame) => {
        try {
            const appsInfo = await getAppsDropdownData();
            setAppsInfo(appsInfo);
            const filteredApps = selectedApps || appsInfo.runningApps;
            const { fromTime, toTime } = getStartAndEndTimestamps(isOneHourTimeFrame, selectedDateRange);

            if (currentTabIndex === 0) {
                if (!!selectedSDI) {
                    // SDI Drill-down for Events Processed
                    const result = await getApiSentinelMonitorService().getSDIDrillDownEventsProcessedForApps(
                        filteredApps,
                        fromTime,
                        toTime,
                        selectedSDI.value
                    );
                    const sdiAllAppsDataForTable = getSDIAllAppsTableData(result);
                    setAllAppsTableData(null);
                    setAllAppsTableDataFiltered(null);
                    setEventsProcessedDataForApps(null);
                    setSensitiveData(null);
                    setSDIOccurrences(null);
                    setSDIAllAppsTableData(sdiAllAppsDataForTable);
                    setSDIAllAppsTableDataFiltered(sdiAllAppsDataForTable);
                    setSDIEventsProcessedDataForApps(result);
                } else {
                    // Events Processed Tab
                    const result = await getApiSentinelMonitorService().getAppsEventsProcessedData(
                        filteredApps,
                        fromTime,
                        toTime
                    );
                    const allAppsDataForTable = getAllAppsTableData(result);
                    setSensitiveData(null);
                    setSDIOccurrences(null);
                    setSDIEventsProcessedDataForApps(null);
                    setAllAppsTableData(allAppsDataForTable);
                    setAllAppsTableDataFiltered(allAppsDataForTable);
                    setEventsProcessedDataForApps(result);
                }
            } else {
                if (!!selectedSDI) {
                    // SDI Drill-down for Sensitive Data Occurrences
                    const result = await getApiSentinelMonitorService().getSDIDrillDownOccurrencesForApps(
                        filteredApps,
                        fromTime,
                        toTime,
                        selectedSDI.value
                    );
                    const sdiOccurrencesTableData = getSDIAllAppsOccurencesTableData(result);
                    setAppsSDITableData(null);
                    setSensitiveData(null);
                    setEventsProcessedDataForApps(null);
                    setSDIEventsProcessedDataForApps(null);
                    setSDITableData(sdiOccurrencesTableData);
                    setSDITableDataFiltered(sdiOccurrencesTableData);
                    setSDIOccurrences(result);
                } else {
                    // Sensitive Data Occurrences Tab
                    const result = await getApiSentinelMonitorService().getSensitiveDataOccurrencesForApps(
                        filteredApps,
                        fromTime,
                        toTime
                    );
                    setSDIOccurrences(null);
                    setSDITableData(null);
                    setSDITableDataFiltered(null);
                    setEventsProcessedDataForApps(null);
                    setSDIEventsProcessedDataForApps(null);
                    setAppsSDITableData(result.sensitiveDataIdentifierOccurrences);
                    setSensitiveData(result);
                }
            }
        } catch (e) {
            growl.error(e);
        }
    };

    const getChartData = async (currentTabIndex, selectedSDI, isOneHourTimeFrame) => {
        try {
            const appsInfo = await getAppsDropdownData();
            setAppsInfo(appsInfo);
            const filteredApps = selectedApps || appsInfo.runningApps;
            const { fromTime, toTime } = getStartAndEndTimestampsForChart();
            const timeFormat = getTimeFormat(isOneHourTimeFrame);

            if (currentTabIndex === 0) {
                if (!!selectedSDI) {
                    // SDI Drill-down for Events Processed
                    const chartDataResult = await getApiSentinelMonitorService().getSDIDrillDownEventsProcessedForAppsChartData(
                        filteredApps,
                        fromTime,
                        toTime,
                        selectedSDI.value,
                        timeFormat
                    );
                    setEventsProcessedChartDataForApps(null);
                    setSDIEventsProcessedChartDataForApps(chartDataResult);
                } else {
                    // Events Processed Tab
                    const chartDataResult = await getApiSentinelMonitorService().getAppsEventsProcessedChartData(
                        filteredApps,
                        fromTime,
                        toTime,
                        timeFormat
                    );
                    setSDIEventsProcessedChartDataForApps(null);
                    setEventsProcessedChartDataForApps(chartDataResult);
                }
            } else {
                if (!!selectedSDI) {
                    // SDI Drill-down for Sensitive Data Occurrences
                    const chartDataResult = await getApiSentinelMonitorService().getSDIDrillDownOccurrencesForAppsChartData(
                        filteredApps,
                        fromTime,
                        toTime,
                        selectedSDI.value,
                        timeFormat
                    );
                    setSensitiveChartData(null);
                    setSDIOccurrencesChartData(chartDataResult);
                } else {
                    // Sensitive Data Occurrences Tab
                    const chartDataResult = await getApiSentinelMonitorService().getSensitiveDataOccurrencesForAppsChartData(
                        filteredApps,
                        fromTime,
                        toTime,
                        timeFormat
                    );
                    setSDIOccurrencesChartData(null);
                    setSensitiveChartData(chartDataResult);
                }
            }
        } catch (e) {
            growl.error(e);
        }
    };

    const tabAndTableIntervalID = useRef();
    const chartIntervalID = useRef();

    const clearPollingIntervals = () => {
        tabAndTableIntervalID?.current && clearInterval(tabAndTableIntervalID.current);
        chartIntervalID?.current && clearInterval(chartIntervalID.current);
    };

    const onLiveViewClick = () => {
        clearPollingIntervals();
        setLiveDataToggled(value => !value);
        setSelectedDateRange(null);
    };

    const fetchTabAndTableData = async (currentTabIndex, selectedSDI, selectedDateRange, isOneHourTimeFrame) => {
        try {
            await getTabAndTableData(currentTabIndex, selectedSDI, selectedDateRange, isOneHourTimeFrame);
        } catch (error) {
            console.error("Error fetching tab and table data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchChartData = async (currentTabIndex, selectedSDI, isOneHourTimeFrame) => {
        try {
            await getChartData(currentTabIndex, selectedSDI, isOneHourTimeFrame);
        } catch (error) {
            console.error("Error fetching chart data:", error);
        }
    };

    // Polling for Chart Data
    useEffect(() => {
        const pollChartData = () => {
            const isOneHourTimeFrame = chartType === TABS[0];
            pollingFn(fetchChartData, chartIntervalID, POLL_INTERVAL, currentTabIndex, selectedSDI, isOneHourTimeFrame);
        };

        pollChartData();

        // Cleanup chart data polling
        return () => {
            if (chartIntervalID?.current) {
                clearInterval(chartIntervalID.current);
                chartIntervalID.current = null;
            }
        };
    }, [currentTabIndex, selectedSDI, liveDataToggled, chartType, selectedApps]);

    // Polling for Tab and Table Data
    useEffect(() => {
        const fetchAndPollTabAndTableData = async () => {
            setIsLoading(true);
            const isOneHourTimeFrame = chartType === TABS[0];
            if (selectedDateRange === null) {
                await fetchTabAndTableData(currentTabIndex, selectedSDI, selectedDateRange, isOneHourTimeFrame);
                pollingFn(
                    fetchTabAndTableData,
                    tabAndTableIntervalID,
                    POLL_INTERVAL,
                    currentTabIndex,
                    selectedSDI,
                    selectedDateRange,
                    isOneHourTimeFrame
                );
            } else {
                clearInterval(tabAndTableIntervalID.current); // Stop polling for tab and table data
                await fetchTabAndTableData(currentTabIndex, selectedSDI, selectedDateRange, isOneHourTimeFrame);
            }
        };

        fetchAndPollTabAndTableData();

        // Cleanup tab and table data polling
        return () => {
            if (tabAndTableIntervalID?.current) {
                clearInterval(tabAndTableIntervalID.current);
                tabAndTableIntervalID.current = null;
            }
        };
    }, [selectedDateRange, currentTabIndex, selectedSDI, chartType, selectedApps]);

    const showDivider = !selectedSDI || !!selectedSDI;

    const eventsProcessedTab = getEventsProcessedTab(
        eventsProcessedDataForApps ?? sdiEventsProcessedDataForApps ?? sensitiveData ?? sdiOccurrences,
        selectedSDI
    );
    const occurrencesTab = getSensitiveDataTab(
        eventsProcessedDataForApps ?? sdiEventsProcessedDataForApps ?? sensitiveData ?? sdiOccurrences
    );
    const tabs = [eventsProcessedTab, occurrencesTab];
    const convertedTabsData = useMemo(() => prepareTabsData(tabs, selectedSDI), [
        eventsProcessedDataForApps,
        sdiEventsProcessedDataForApps,
        sensitiveData,
        sdiOccurrences,
        selectedSDI
    ]);

    return (
        <Grid container py={3} pl={3} gap={2} height={"100%"} flexWrap={"nowrap"} flexDirection={"column"}>
            <SentinelMonitorHeader
                selectedSDI={selectedSDI}
                setSelectedSDI={setSelectedSDI}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
                currentTabIndex={currentTabIndex}
                setCurrentTabIndex={value => {
                    setSelectedDateRange(null);
                    setCurrentTabIndex(value);
                }}
                appsInfo={appsInfo}
                setSelectedApps={setSelectedApps}
                chartType={chartType}
                setChartType={value => {
                    setSelectedDateRange(null);
                    setChartType(value);
                }}
                tabs={TABS}
                selectedApps={selectedApps}
                selectedRange={selectedDateRange ?? { from: formattedFromTime, to: formattedToTime }}
                onBackClick={() => {
                    setSelectedSDI(null);
                    onLiveViewClick();
                }}
                timeFormat={getTimeFormat(chartType === TABS[0])}
                appSentinelMapping={appSentinelMapping}
            />
            <Grid container pr={3} sx={{ overflowY: "auto", overflowX: "hidden" }}>
                <SentinelTabs
                    currentTabIndex={currentTabIndex}
                    setCurrentTabIndex={value => {
                        setSelectedDateRange(null);
                        setCurrentTabIndex(value);
                    }}
                    tabs={tabs}
                    firstTabContent={
                        <EventsProcessedChart
                            eventsProcessedDataForApps={eventsProcessedDataForApps}
                            eventsProcessedChartDataForApps={eventsProcessedChartDataForApps}
                            sdiEventsProcessedChartDataForApps={sdiEventsProcessedChartDataForApps}
                            chartType={chartType}
                            selectedSDI={selectedSDI}
                            selectedDateRange={selectedDateRange}
                            setSelectedDateRange={setSelectedDateRange}
                            onLiveViewClick={onLiveViewClick}
                            legendData={convertedTabsData[0].data}
                        />
                    }
                    secondTabContent={
                        <SensitiveDataChart
                            sensitiveData={sensitiveChartData}
                            sdiOccurrences={sdiOccurrencesChartData}
                            chartType={chartType}
                            selectedSDI={selectedSDI}
                            selectedDateRange={selectedDateRange}
                            setSelectedDateRange={setSelectedDateRange}
                            onLiveViewClick={onLiveViewClick}
                            legendData={convertedTabsData[1].data}
                        />
                    }
                    isLoading={
                        isLoading ||
                        (currentTabIndex === 0 && !eventsProcessedDataForApps && !sdiEventsProcessedDataForApps) ||
                        (currentTabIndex === 1 && !sensitiveData && !sdiOccurrences)
                    }
                    selectedSDI={selectedSDI}
                />

                {showDivider && (
                    <Divider
                        sx={{
                            width: "calc(100% + 48px)",
                            marginLeft: -3,
                            marginTop: 3,
                            marginBottom: 3
                        }}
                    />
                )}

                {currentTabIndex === 0 && !selectedSDI && (
                    <Grid container width="100%">
                        {useLoader(
                            <AllAppsTable
                                title="Breakdown By Apps"
                                tableData={allAppsTableData}
                                filteredTableData={allAppsTableDataFiltered}
                            />,
                            !!allAppsTableData && !isLoading,
                            "Breakdown By Apps"
                        )}
                    </Grid>
                )}

                {currentTabIndex === 0 && !!selectedSDI && (
                    <Grid container width="100%">
                        {useLoader(
                            <SentinelTableIdentifierType
                                title="Breakdown By Apps"
                                type={selectedSDI.name}
                                tableData={sdiAllAppsTableData}
                                filteredTableData={sdiAllAppsTableDataFiltered}
                            />,
                            !!sdiAllAppsTableData && !isLoading,
                            "Breakdown By Apps"
                        )}
                    </Grid>
                )}

                {currentTabIndex === 1 && !selectedSDI && (
                    <Grid container width="100%">
                        {useLoader(
                            <SentinelOrAppsTableSDI
                                setSelectedSDI={setSelectedSDI}
                                title="Sensitive Data Identifiers Detected"
                                tableData={appsSDITableData}
                                timeRange={chartType}
                                isSentinelLevel={false}
                                setCurrentTabIndex={setCurrentTabIndex}
                                showProgress={isLoading}
                                setSelectedDateRange={setSelectedDateRange}
                            />,
                            !!appsSDITableData,
                            "Sensitive Data Identifiers Detected"
                        )}
                    </Grid>
                )}

                {currentTabIndex === 1 && !!selectedSDI && (
                    <Grid container width="100%">
                        {useLoader(
                            <SentinelOrAppsTableSDI
                                setSelectedSDI={setSelectedSDI}
                                title="Breakdown By Apps"
                                tableData={sdiTableData}
                                timeRange={chartType}
                                isSentinelLevel={true}
                                setCurrentTabIndex={setCurrentTabIndex}
                                isSDIOccurrencesTableForApps={true}
                            />,
                            !!sdiTableData,
                            "Breakdown By Apps"
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
export default SentinelMonitorContent;
