import React, { useRef, useCallback } from "react";
import * as Yup from "yup";
import { Formik, Form, useField } from "formik";
import { Grid, Box } from "@material-ui/core";

import NamespaceSelect from "../namespace-select/namespace-select";
import NamespaceValidation from "../namespace-select/namespace.validation";
import { StriimTypography, StriimButton, StriimControlLabel, StriimCheckbox } from "@striim/striim-ui";
import nsCleaner from "./import_into_same_ns_cleaner";
import useDashboardsStore from "src/stores/dashboards/useDashboardsStore";
import useNamespaceStore from "src/stores/namespaces/useNamespaceStore";
import namespaceFactory from "app/components/createapp/namespace-factory";
import navigateTo from "src/navigate-to";
import Tracker from "core/services/tracker/tracker";
import useStyles from "./import-dashboard.styles";
import { TRACKER_STRINGS } from "../../../../../../../../../core/services/tracker/constants";

export const validationSchema = Yup.object({
    nsName: NamespaceValidation,
    file: Yup.mixed().required("File is required")
});

const toFilenameWithoutExtension = filename => {
    if (!filename) {
        return filename;
    }
    if (filename === true) {
        return filename;
    }
    return filename
        .split(".")
        .slice(0, -1)
        .join(".");
};

const ImportDashboardForm = ({ setFieldValue, setFieldTouched, values, errors, touched }) => {
    const fileInputRef = useRef(null);
    const classes = useStyles();
    const [allIntoNsfield] = useField("importAllIntoNS");
    return (
        <Form>
            <Grid container item xs={12} justifyContent="center" direction="column">
                <input
                    ref={fileInputRef}
                    type="file"
                    data-id="file-path"
                    hidden
                    accept=".json"
                    onChange={e => {
                        setFieldValue("file", e.currentTarget.files[0]);
                    }}
                />
                <Box className={classes.importDashboardFileInput}>
                    <StriimButton
                        onClick={() => {
                            fileInputRef.current.click();
                        }}
                        variant="secondary"
                    >
                        Choose dashboard
                    </StriimButton>
                    <Box
                        className={classes.importDashboardFileHelperText}
                        component={StriimTypography}
                        variant="body"
                        id="dashlist--imported-dash-name"
                    >
                        <Box color={errors.file ? "input.error" : undefined}>
                            {toFilenameWithoutExtension(values.file?.name) ||
                                (toFilenameWithoutExtension(touched.file) && errors.file) ||
                                ""}
                        </Box>
                    </Box>
                </Box>
                <Box minHeight={10}></Box>
                <NamespaceSelect required name="nsName" doNotCreateNamespaces />
                <Box
                    className={classes.importDashboardCheckBox}
                    component={StriimControlLabel}
                    label="Import all queries into this namespace"
                    control={<StriimCheckbox name="importAllIntoNS" {...allIntoNsfield} />}
                />
                <Grid container justifyContent="flex-end">
                    <Box
                        className={classes.importDashboardButton}
                        component={StriimButton}
                        type="submit"
                        variant="primary"
                        data-test-id="submit-dashboard-button"
                        onClick={() => {
                            setFieldTouched("file", true);
                        }}
                    >
                        Import Dashboard
                    </Box>
                </Grid>
            </Grid>
        </Form>
    );
};

const ImportDashboard = () => {
    const dashboardStore = useDashboardsStore();
    const namespaceStore = useNamespaceStore();
    const handleSubmit = useCallback(
        async ({ file, nsName, importAllIntoNS }) => {
            try {
                var reader = new FileReader();
                reader.readAsText(file, "utf-8");
                reader.onload = function(event) {
                    var decoded = event.target.result;

                    if (importAllIntoNS) {
                        decoded = nsCleaner.clean(decoded);
                    }

                    namespaceFactory.create(nsName).then(() => {
                        namespaceStore.namespaceSet(nsName).then(() => {
                            dashboardStore.dashboardImport(decoded, nsName).then(imported => {
                                dashboardStore.getDashbordByUUID(imported.uuidstring).then(newDashboard => {
                                    navigateTo.DashboardEdit(newDashboard);
                                });
                            });
                        });
                    });

                    Tracker.getInstance().track(TRACKER_STRINGS.schema.dashboard, {
                        id: `${nsName}.${toFilenameWithoutExtension(file?.name)}`,
                        event: TRACKER_STRINGS.eventClicked.dashboard,
                        buttonClicked:"Import Dashboard"
                    });
                };
            } catch (e) {
                console.error(e);
            }
        },
        [namespaceStore, dashboardStore]
    );
    return (
        <Grid container item xs={12} justifyContent="space-between" direction="column">
            <Formik
                initialValues={{
                    nsName: "admin",
                    importAllIntoNS: false,
                    file: null
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                component={ImportDashboardForm}
            ></Formik>
        </Grid>
    );
};

ImportDashboard.propTypes = {};

export default ImportDashboard;
