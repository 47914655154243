import React, { Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from "react";
import { StriimInputField, StriimTypography } from "@striim/striim-ui";
import { AppTypeInfo } from "./adapter-selection/app-types-info";
import { components } from "react-select";
import { Grid, useTheme } from "@mui/material";
import { getSupportedAppType } from "../services";

import { adapterType } from "../create-app-page-templates";
import styles from "../create-app-page-styles";
import useCreateAppsStore from "../../../../../stores/create-apps/useCreateAppsStore";
import APPTYPE from "../services/create-app-page-app-types.const.json";
import licenseService from "../../../../../../core/services/metaStoreService/license-service";

interface CreateAppTypeDropdownComponentProps {
    sourceAdapter: adapterType;
    targetAdapter: adapterType;
    templateInfo: optionsType;
    setTemplateInfo: Dispatch<SetStateAction<optionsType>>;
    handleChange: (value: optionsType, cb: Dispatch<SetStateAction<optionsType>>, type: string) => void;
}

interface CustomSelectOptionProps {
    data: { label: string; value: string };
    innerProps: object;
    isSelected: boolean;
}

interface appTypeInfoType {
    title: string;
    content: ReactElement;
    icon: ReactElement;
    additionalComponent?: ReactElement;
}

export interface optionsType {
    label: string;
    value: string;
    templateUrl: string;
}

const CustomSelectOption: React.FunctionComponent<CustomSelectOptionProps> = ({
    data,
    innerProps,
    isSelected,
    ...props
}) => {
    const optionInfo: appTypeInfoType = AppTypeInfo()[data?.value];
    const theme = useTheme();

    const dropdownStyles = {
        dropdownOption: {
            borderBottom: `1px solid ${theme.palette.greyscale[200]}`,
            padding: theme.spacing(2, 1.5)
        },
        selectedOption: {
            backgroundColor: theme.palette.greyscale[200]
        }
    };

    return (
        <components.Option
            {...props}
            innerProps={{
                ...innerProps,
                style: {
                    ...innerProps.style,
                    ...dropdownStyles.dropdownOption,
                    ...(isSelected && dropdownStyles.selectedOption)
                }
            }}
        >
            <Grid container sx={styles.optionGrid} data-test-id={`option-${optionInfo.title}`}>
                <Grid item sx={{ position: "relative" }}>
                    {optionInfo?.icon}
                </Grid>
                <Grid item sx={styles.optionContentGird}>
                    <Grid item>
                        <StriimTypography variant="body4" color={isSelected ? "primary.800" : "greyscale.700"}>
                            {optionInfo?.title}
                        </StriimTypography>
                    </Grid>
                    <Grid item>
                        <StriimTypography variant="caption3" color="greyscale.700">
                            {optionInfo?.content}
                        </StriimTypography>
                    </Grid>
                    {optionInfo?.additionalComponent && <Grid item>{optionInfo?.additionalComponent}</Grid>}
                </Grid>
            </Grid>
        </components.Option>
    );
};

const desiredOrder = ["AUTOMATEDILCDC", "IL", "CDC"];

const fetchDropdownHeading = license => {
    const dropdownHeading = {
        SQL2FABRICSOLUTION: "Mirroring Options",
        DEFAULT: "What type of App would you like to create?"
    };
    return dropdownHeading[license] || dropdownHeading.DEFAULT;
};

const CreateAppTypeDropdownComponent: React.FunctionComponent<CreateAppTypeDropdownComponentProps> = ({
    sourceAdapter,
    targetAdapter,
    templateInfo,
    setTemplateInfo,
    handleChange
}) => {
    const [supportedFlows, setSupportedFlows] = useState<optionsType[]>([]);

    useEffect(() => {
        const supportedAppType = getSupportedAppType(sourceAdapter, targetAdapter);
        if (!templateInfo) {
            if (supportedAppType.length === 1) {
                handleChange(supportedAppType[0], setTemplateInfo, "template");
            } else {
                const containsAutomatedPipeline = supportedAppType?.filter(flow => {
                    return flow.value === APPTYPE.AUTOMATEDILCDC;
                });
                containsAutomatedPipeline && handleChange(containsAutomatedPipeline[0], setTemplateInfo, "template");
            }
        }
        const orderedSelectOptions = supportedAppType.sort((a, b) => {
            return desiredOrder.indexOf(a.value) - desiredOrder.indexOf(b.value);
        });

        setSupportedFlows(orderedSelectOptions);
    }, [targetAdapter]);

    return (
        <>
            {supportedFlows && (
                <StriimInputField
                    label={fetchDropdownHeading(licenseService?.nonSegmentedlicenseName)}
                    placeholder={"Choose"}
                    id="data-test-id-type-of-app"
                    required
                    select
                    value={templateInfo}
                    SelectProps={{
                        options: supportedFlows,
                        components: {
                            Option: props => <CustomSelectOption {...props} />
                        },
                        isClearable: false
                    }}
                    onChange={templateInfo => {
                        handleChange(templateInfo, setTemplateInfo, "template");
                    }}
                    disabled={supportedFlows.length === 1}
                    sx={{
                        width: "500px",
                        mb: 2
                    }}
                ></StriimInputField>
            )}
        </>
    );
};

export default CreateAppTypeDropdownComponent;
