import React, { useMemo } from "react";
import { StriimTypography, StriimLink } from "@striim/striim-ui";
import { Grid } from "@mui/material";

import { USER_MANAGEMENT_STRINGS } from "../../../constants";
import Dictionary from "app/components/common/helpable/online-help-dictionary";

const BasicInfo = ({ userDetails, isEditable = true }) => {
    const basicInfo = useMemo(
        () =>
            userDetails
                ? {
                      name: `${
                          userDetails.firstName || userDetails.lastName
                              ? `${userDetails.firstName || ""} ${userDetails.lastName || ""}`
                              : userDetails["User Id"]
                      } ${userDetails.originType?.toLowerCase() === "ldap" ? ` (LDAP user)` : ``}`,
                      username: userDetails["User Id"],
                      id: userDetails["User Id"],
                      isLdapUser: userDetails.originType?.toLowerCase() === "ldap",
                      propertyset: userDetails.ldap_propertyset || "-",
                      alias: userDetails.aliasFor || "-",
                      firstName: userDetails.firstName || "-",
                      lastName: userDetails.lastName || "-",
                      emailAddress:
                          (userDetails.contactMechanisms || []).find(x => x.contactType === "email")?.data || "-",
                      phone: (userDetails.contactMechanisms || []).find(x => x.contactType === "phone")?.data || "-",
                      timezone: userDetails.timezone || "-"
                  }
                : { name: "-", namespace: "-", roleName: "-" },
        [userDetails]
    );

    return (
        <>
            <Grid container>
                {basicInfo.isLdapUser && (
                    <Grid item display="flex" alignItems="center">
                        <StriimTypography variant="caption">{USER_MANAGEMENT_STRINGS.label.ldapInfo}</StriimTypography>
                        <StriimLink
                            href={Dictionary.get()["USERS_LDAP_AUTHENTICATION"].href}
                            target="_blank"
                            sx={{
                                color: ({ palette }) => `${palette.secondary[500]} !important`
                            }}
                        >
                            {USER_MANAGEMENT_STRINGS.button.learnMoreLDAP}
                        </StriimLink>
                    </Grid>
                )}
            </Grid>

            <Grid container direction="column" spacing={2}>
                <Grid item xs={12} marginBottom={1}>
                    <StriimTypography variant="h3" color="primary.900">
                        {USER_MANAGEMENT_STRINGS.label.basicInfo}
                    </StriimTypography>
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item xs={12}>
                            <StriimTypography variant="h5" color="greyscale.800">
                                {USER_MANAGEMENT_STRINGS.label.username}
                            </StriimTypography>
                        </Grid>
                        <Grid item xs={12}>
                            <StriimTypography data-test-id={"username"} variant="body4" color="greyscale.700">
                                {basicInfo.username}
                            </StriimTypography>
                        </Grid>
                    </Grid>
                </Grid>
                {basicInfo.isLdapUser ? (
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item>
                                <StriimTypography variant="h5" color="greyscale.800">
                                    {USER_MANAGEMENT_STRINGS.label.propertyset}
                                </StriimTypography>
                            </Grid>
                            <Grid item>
                                <StriimTypography variant="body4" color="greyscale.700">
                                    {basicInfo.propertyset}
                                </StriimTypography>
                            </Grid>
                        </Grid>
                        <Grid container direction="column">
                            <Grid item>
                                <StriimTypography variant="h5" color="greyscale.800">
                                    {USER_MANAGEMENT_STRINGS.label.alias}
                                </StriimTypography>
                            </Grid>
                            <Grid item>
                                <StriimTypography variant="body4" color="greyscale.700">
                                    {basicInfo.alias}
                                </StriimTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item>
                                <StriimTypography variant="h5" color="greyscale.800">
                                    {USER_MANAGEMENT_STRINGS.label.password}
                                </StriimTypography>
                            </Grid>
                            <Grid item>
                                <StriimTypography variant="body4" color="greyscale.700">
                                    *****
                                </StriimTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <StriimTypography variant="h5" color="greyscale.800">
                                {USER_MANAGEMENT_STRINGS.label.firstName}
                            </StriimTypography>
                        </Grid>
                        <Grid item>
                            <StriimTypography variant="body4" color="greyscale.700" data-test-id={"firstname"}>
                                {basicInfo.firstName}
                            </StriimTypography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <StriimTypography variant="h5" color="greyscale.800">
                                {USER_MANAGEMENT_STRINGS.label.lastName}
                            </StriimTypography>
                        </Grid>
                        <Grid item>
                            <StriimTypography variant="body4" color="greyscale.700" data-test-id={"lastname"}>
                                {basicInfo.lastName}
                            </StriimTypography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <StriimTypography variant="h5" color="greyscale.800">
                                {USER_MANAGEMENT_STRINGS.label.emailAddress}
                            </StriimTypography>
                        </Grid>
                        <Grid item>
                            <StriimTypography variant="body4" color="greyscale.700" data-test-id={"email"}>
                                {basicInfo.emailAddress}
                            </StriimTypography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <StriimTypography variant="h5" color="greyscale.800">
                                {USER_MANAGEMENT_STRINGS.label.phone}
                            </StriimTypography>
                        </Grid>
                        <Grid item>
                            <StriimTypography variant="body4" color="greyscale.700" data-test-id={"phone"}>
                                {basicInfo.phone}
                            </StriimTypography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <StriimTypography variant="h5" color="greyscale.800">
                                {USER_MANAGEMENT_STRINGS.label.timezone}
                            </StriimTypography>
                        </Grid>
                        <Grid item>
                            <StriimTypography variant="body4" color="greyscale.700" data-test-id={"timezone"}>
                                {basicInfo.timezone}
                            </StriimTypography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default BasicInfo;
