import React, { useState, useEffect } from "react";
import {
    StriimTypography,
    StriimModal,
    StriimRadioGroup,
    StriimRadio,
    StriimInputField,
    StriimControlLabel
} from "@striim/striim-ui";
import { Box } from "@mui/material";
import { SENTINEL_ACTIONS } from "../../sentinel/panel/sentinel-panel-consts";

interface ActionsCustomMaskingModalProps {
    isVisible: boolean;
    setCustomMaskingModalVisible: (isVisible: boolean) => void;
    onConfirm: (maskingConfig: { maskingType: string; maskingString: string; m?: number; n?: number }) => void;
    initialMaskingConfig?: { maskingType: string; maskingString: string };
}

const ActionsCustomMaskingModal: React.FC<ActionsCustomMaskingModalProps> = ({
    isVisible,
    setCustomMaskingModalVisible,
    onConfirm,
    initialMaskingConfig
}) => {
    const [maskingType, setMaskingType] = useState(SENTINEL_ACTIONS.MASK_M_N);
    const [firstChars, setFirstChars] = useState("");
    const [lastChars, setLastChars] = useState("");
    const [regex, setRegex] = useState("");

    const [firstCharsError, setFirstCharsError] = useState(false);
    const [firstCharsErrorText, setFirstCharsErrorText] = useState("");
    const [lastCharsError, setLastCharsError] = useState(false);
    const [lastCharsErrorText, setLastCharsErrorText] = useState("");

    useEffect(() => {
        if (isVisible && initialMaskingConfig) {
            setMaskingType(initialMaskingConfig.maskingType);
            if (initialMaskingConfig.maskingType === SENTINEL_ACTIONS.MASK_M_N) {
                const match = initialMaskingConfig.maskingString.match(/first (\d+) and the last (\d+) characters/);
                if (match) {
                    setFirstChars(match[1]);
                    setLastChars(match[2]);
                } else {
                    setFirstChars("");
                    setLastChars("");
                }
                setRegex("");
            } else if (initialMaskingConfig.maskingType === SENTINEL_ACTIONS.MASK_BY_REGEX) {
                setRegex(initialMaskingConfig.maskingString);
                setFirstChars("");
                setLastChars("");
            }
        }
    }, [isVisible, initialMaskingConfig]);

    const isNumeric = (value: string) => Number.isInteger(Number(value));
    const numericErrorText = "Must be a number";

    const handleConfirm = () => {
        let hasError = false;

        if (maskingType === SENTINEL_ACTIONS.MASK_M_N) {
            if (!isNumeric(firstChars)) {
                setFirstCharsError(true);
                setFirstCharsErrorText(numericErrorText);
                hasError = true;
            } else {
                setFirstCharsError(false);
                setFirstCharsErrorText("");
            }

            if (!isNumeric(lastChars)) {
                setLastCharsError(true);
                setLastCharsErrorText(numericErrorText);
                hasError = true;
            } else {
                setLastCharsError(false);
                setLastCharsErrorText("");
            }
        }

        if (hasError) {
            return;
        }

        let finalMaskingType = maskingType;
        let finalMaskingString = "";
        let m;
        let n;

        if (maskingType === SENTINEL_ACTIONS.MASK_M_N) {
            finalMaskingString = `Show the first ${firstChars || "4"} and the last ${lastChars || "4"} characters`;
            finalMaskingType = SENTINEL_ACTIONS.MASK_M_N;
            m = firstChars ? firstChars : 4;
            n = lastChars ? lastChars : 4;
        } else if (maskingType === SENTINEL_ACTIONS.MASK_BY_REGEX) {
            finalMaskingString = regex || "^.*?(?=@)";
            finalMaskingType = SENTINEL_ACTIONS.MASK_BY_REGEX;
        }

        onConfirm({ maskingType: finalMaskingType, maskingString: finalMaskingString, m, n });
        setMaskingType(SENTINEL_ACTIONS.MASK_M_N);
        setFirstChars("");
        setLastChars("");
        setRegex("");
        setCustomMaskingModalVisible(false);
    };

    const handleMaskingTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMaskingType(event.target.value);
        setFirstCharsError(false);
        setFirstCharsErrorText("");
        setLastCharsError(false);
        setLastCharsErrorText("");
    };

    return (
        <StriimModal
            size="medium"
            isVisible={isVisible}
            centerContent
            autoHeight
            onCancel={() => setCustomMaskingModalVisible(false)}
            onConfirm={handleConfirm}
            titleContent={<StriimTypography variant="h2">Create a Custom Masking Function</StriimTypography>}
            confirmContent="Done"
            cancelContent="Cancel"
        >
            <Box mt={2}>
                <StriimRadioGroup
                    value={maskingType}
                    onChange={handleMaskingTypeChange}
                    style={{ gap: 20 }}
                    sx={{
                        "& > label": {
                            alignItems: "start"
                        }
                    }}
                >
                    <StriimControlLabel
                        value={SENTINEL_ACTIONS.MASK_M_N}
                        control={<StriimRadio />}
                        label={
                            <>
                                <StriimTypography variant="body4" color="greyscale.800">
                                    Redaction
                                </StriimTypography>
                                {maskingType === SENTINEL_ACTIONS.MASK_M_N && (
                                    <Box display="flex" alignItems="center" flexWrap="wrap">
                                        <StriimTypography variant="body4" color="greyscale.800">
                                            Show the first
                                        </StriimTypography>
                                        <StriimInputField
                                            sx={{ mx: 1 }}
                                            value={firstChars}
                                            onChange={e => setFirstChars(e)}
                                            placeholder="4"
                                            InputProps={{
                                                sx: {
                                                    width: 110
                                                }
                                            }}
                                            disabled={maskingType !== SENTINEL_ACTIONS.MASK_M_N}
                                            fullWidth={false}
                                            error={firstCharsError}
                                            helperText={firstCharsError ? firstCharsErrorText : ""}
                                        />
                                        <StriimTypography variant="body4" color="greyscale.800">
                                            and the last
                                        </StriimTypography>
                                        <StriimInputField
                                            sx={{ mx: 1 }}
                                            value={lastChars}
                                            onChange={e => setLastChars(e)}
                                            placeholder="4"
                                            InputProps={{
                                                sx: {
                                                    width: 110
                                                }
                                            }}
                                            disabled={maskingType !== SENTINEL_ACTIONS.MASK_M_N}
                                            fullWidth={false}
                                            error={lastCharsError}
                                            helperText={lastCharsError ? lastCharsErrorText : ""}
                                        />
                                        <StriimTypography variant="body4" color="greyscale.800">
                                            characters
                                        </StriimTypography>
                                    </Box>
                                )}
                            </>
                        }
                    />
                    <StriimControlLabel
                        value={SENTINEL_ACTIONS.MASK_BY_REGEX}
                        control={<StriimRadio />}
                        label={
                            <Box
                                display="flex"
                                justifyContent={"center"}
                                flexWrap="wrap"
                                gap={1}
                                flexDirection="column"
                            >
                                <StriimTypography variant="body4" color="greyscale.800">
                                    Regular Expression
                                </StriimTypography>
                                {maskingType === SENTINEL_ACTIONS.MASK_BY_REGEX && (
                                    <StriimInputField
                                        value={regex}
                                        onChange={e => setRegex(e)}
                                        placeholder="^.*?(?=@)"
                                        disabled={maskingType !== SENTINEL_ACTIONS.MASK_BY_REGEX}
                                        InputProps={{
                                            sx: {
                                                width: 530
                                            }
                                        }}
                                        fullWidth={true}
                                    />
                                )}
                            </Box>
                        }
                    />
                </StriimRadioGroup>
            </Box>
        </StriimModal>
    );
};

export default ActionsCustomMaskingModal;
