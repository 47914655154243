import agentUtils from "app/components/appwizard/component-wizards/wizards/source/base-cdc/agent-utils";
import Backbone from "backbone";

window.useFakeDeploymentGroups = function (numberOfGroups) {
    if (numberOfGroups <= 0) {
        delete window.fakeDeploymentGroups;
        return;
    }

    window.fakeDeploymentGroups = [];
    for (let i = 0; i < numberOfGroups; i++) {
        window.fakeDeploymentGroups.push(
            new Backbone.Model({
                id: "dg1",
                name: "Deployment group " + (i + 1),
            })
        );
    }
};

function loadDGsForAgents() {
    let agentDeploymentGroups = window.fakeDeploymentGroups || agentUtils.getDGsForAgents();
    return agentDeploymentGroups;
}

function getAgentForDeploymentGroup(agentDeploymentGroup) {
    if (window.fakeDeploymentGroups) {
        return agentDeploymentGroup.replace("dg", "agent");
    }
    return agentUtils.getAgentName(agentDeploymentGroup);
}

export { loadDGsForAgents, getAgentForDeploymentGroup };
