import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Grid, Box } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import { useTheme } from "@mui/material";
import { StriimTypography, StriimButton } from "@striim/striim-ui";

import { icons } from "src/generic/icon/flow-designer-icon";

import useStylesGroup from "./template-group.styles";
import { TemplateItem } from "../template-item/template-item";
import TemplateItemIcons from "../template-item-icons";
import { useTemplateGroup } from "./useTemplateGroup";
import {
    AutomatedCampaign,
    NewReleasesCampaign,
    FabricMirrorCampaign
} from "../../../../../../../../generic/icon/customIcons";
import classNames from "classnames";
import Tracker from "../../../../../../../../../core/services/tracker/tracker";

const stateString = {
    true: "Show less",
    false: "Show more"
};

const constructSubtitle = (sourceTitle, targetTitle) =>
    sourceTitle && targetTitle ? `${sourceTitle} to ${targetTitle}` : sourceTitle || targetTitle;
const getIcons = () => {
    const flowDesignerIcons = icons;
    const customIcons = {
        AutomatedCampaign: AutomatedCampaign,
        NewReleasesCampaign: NewReleasesCampaign,
        MirrorCampaign: FabricMirrorCampaign
    };
    return {
        ...flowDesignerIcons,
        ...customIcons
    };
};
export const TemplateGroup = React.memo(
    ({ title = "", expandable = true, templates = [], isSearchResults = false, icon, hidden, generic }) => {
        const classes = useStylesGroup(useTheme);
        const { expandAll, setExpandAll, filteredTemplates } = useTemplateGroup(templates, isSearchResults);
        const shouldRenderGroupIcon =
            (filteredTemplates[0] && typeof filteredTemplates[0].sourceIcon === "string") || icon;

        const navigate = useNavigate();
        const getTemplateId = useCallback((title, isAutomatedILCDC) => {
            if (isAutomatedILCDC) {
                return ("createapp--" + title).replace(/ /g, "_") + "_automated";
            }
            return ("createapp--" + title).replace(/ /g, "_");
        }, []);

        return (
            <Box className={classes.wrapper} hidden={hidden} data-test-id={"template-group"}>
                <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    justifyContent="space-between"
                    id="template-group-title"
                    className={classes.templateGroupTitle}
                >
                    {!isSearchResults && (
                        <Grid item container xs={12} sm={9} alignItems="center">
                            {shouldRenderGroupIcon && (
                                <Box
                                    className={classNames(classes.groupIcon, generic ? classes.genericIcon : "")}
                                    component={getIcons()[icon]}
                                />
                            )}
                            {title !== "" ? <StriimTypography variant="h3">{title}</StriimTypography> : <></>}
                        </Grid>
                    )}
                    {expandable && !isSearchResults && templates.length > 4 && (
                        <Grid
                            item
                            container
                            xs={12}
                            sm={3}
                            justifyContent="flex-end"
                            className={classes.expandableContainer}
                        >
                            <StriimButton
                                variant="text"
                                data-testid={`toggle-show-all-${title}`}
                                data-test-id="toggle-view-all-templates"
                                onClick={() => {
                                    setExpandAll(!expandAll);
                                }}
                            >
                                {stateString[expandAll]}
                            </StriimButton>
                        </Grid>
                    )}
                </Grid>
                <Collapse in={expandAll} collapsedSize={"170px"}>
                    <Grid container item xs={12} className={classes.templatesWrapper} wrap="wrap">
                        {filteredTemplates.map(
                            ({ id, fromIcon, toIcon, title, url, onClick, isAutomatedILCDC, sourceAdapter }) => (
                                <Grid
                                    item
                                    key={id}
                                    className={classes.itemGridWrapper}
                                    id={getTemplateId(title, isAutomatedILCDC)}
                                >
                                    <TemplateItem
                                        onClick={() => {
                                            onClick && onClick();
                                            url && navigate("" + url);
                                            Tracker.getInstance().track("create-app-template", {
                                                event: "Create App:Template:Clicked",
                                                template: constructSubtitle(title),
                                                isAutomatedILCDC: isAutomatedILCDC || false
                                            });
                                        }}
                                        content={<TemplateItemIcons {...{ fromIcon, toIcon }} />}
                                        subtitle={constructSubtitle(title)}
                                        url={url}
                                        isAutomatedILCDC={isAutomatedILCDC}
                                        sourceAdapter={sourceAdapter}
                                    />
                                </Grid>
                            )
                        )}
                    </Grid>
                </Collapse>
            </Box>
        );
    }
);
TemplateGroup.displayName = "TemplateGroup";

TemplateGroup.propTypes = {
    title: PropTypes.node,
    icon: PropTypes.string,
    expandable: PropTypes.bool,
    templates: PropTypes.array,
    isSearchResults: PropTypes.bool
};
