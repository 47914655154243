import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { ArrowDownward, ArrowRight } from "../../../../../generic/icon/customIcons";
import { StriimTypography } from "@striim/striim-ui-v2";
import { getPriorityImage } from "../../../sentinel-live-monitor/components/sentinel-table/sentinel-table-sdi";
import { Box, Grid } from "@mui/material";
import PredefinedComponentNames from "../../../../../../app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import { AdapterIcon } from "../../../../../generic/icon/component-icon/component-icon";
import { getPercentage } from "./report-tables.helper";
import { DataTypography, ImportanceLabelWithIcon, ReportTable, TitleTypography } from "./report-table-componets";
import metaObjectHelper from "../../../../../../app/components/common/editor/meta-object-editors/metaObjectHelper";
import metaStoreService from "../../../../../../core/services/metaStoreService/meta-store-service";

const SourceAndTargetIcons = ({ sourceNames, targetNames }) => {
    return (
        <Grid container alignItems={"center"}>
            <Box>
                {sourceNames.map(source => {
                    const sourceObject = getAdapterObject(metaStoreService.entities.SOURCE, source);
                    const sourceIcon = metaObjectHelper.getIconClassByMetaObject(sourceObject);
                    return (
                        <Box display={"flex"} mx={1 / 4} key={source} my={1 / 2}>
                            <AdapterIcon name={sourceIcon} type={"source"} outlined={true} size={24} />
                        </Box>
                    );
                })}
            </Box>
            <ArrowRight />
            <Box>
                {targetNames.map(target => {
                    const targetObject = getAdapterObject(metaStoreService.entities.TARGET, target);
                    const targetIcon = metaObjectHelper.getIconClassByMetaObject(targetObject);
                    return (
                        <Box display={"flex"} mx={1 / 4} key={target} my={1 / 2}>
                            <AdapterIcon name={targetIcon} type={"target"} outlined={true} size={24} />
                        </Box>
                    );
                })}
            </Box>
        </Grid>
    );
};

export const SherlockIdentifiersTable = ({ data, totalEvents }) => {
    return (
        <ReportTable
            header={
                <TableRow>
                    <TableCell align="left">
                        <TitleTypography>Sensitive Data Identifier</TitleTypography>
                    </TableCell>
                    <TableCell align="right">
                        <TitleTypography>Entities W/ Identifier </TitleTypography>
                        <DataTypography>
                            (of {totalEvents}) <ArrowDownward />
                        </DataTypography>
                    </TableCell>
                </TableRow>
            }
            content={
                <>
                    {data.map(row => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                <DataTypography>{row.name}</DataTypography>
                            </TableCell>
                            <TableCell align="right">
                                <DataTypography>{`${row.count}  (${getPercentage(
                                    row.count,
                                    totalEvents
                                )})%`}</DataTypography>
                            </TableCell>
                        </TableRow>
                    ))}
                </>
            }
        />
    );
};

export const SherlockAppSDI = ({ data, totalTables }) => {
    return (
        <ReportTable
            header={
                <TableRow>
                    <TableCell align="left">
                        <TitleTypography>Sensitive Data Identifier</TitleTypography>
                    </TableCell>
                    <TableCell align="right">
                        <TitleTypography>Tables W/ Identifier </TitleTypography>
                        <DataTypography>
                            (of {totalTables}) <ArrowDownward />
                        </DataTypography>
                    </TableCell>
                </TableRow>
            }
            content={
                <>
                    {data.map(row => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                <DataTypography>{row.name}</DataTypography>
                            </TableCell>
                            <TableCell align="right">
                                <DataTypography>{`${row.count}  (${getPercentage(
                                    row.count,
                                    totalTables
                                )})%`}</DataTypography>
                            </TableCell>
                        </TableRow>
                    ))}
                </>
            }
        />
    );
};

export const TopHighSdiTable = ({ data }) => {
    return (
        <ReportTable
            header={
                <TableRow>
                    <TableCell>
                        <TitleTypography>Column Name</TitleTypography>
                    </TableCell>
                    <TableCell>
                        <TitleTypography>Data Identifier</TitleTypography>
                    </TableCell>
                </TableRow>
            }
            content={
                <>
                    {data.map(row => {
                        const name = row.columnName;
                        const identifiers = row.sensitiveDataIdentifiers;

                        return (
                            <TableRow key={name}>
                                <TableCell component="th" scope="row" style={{ width: "400px" }}>
                                    <DataTypography>{name}</DataTypography>
                                </TableCell>
                                <TableCell>
                                    <Grid container flexDirection={"row"} gap={1}>
                                        {identifiers.map(item => (
                                            <ImportanceLabelWithIcon
                                                key={item.identifierType}
                                                label={item.identifierType}
                                                icon={getPriorityImage(item.importanceLevel)}
                                            />
                                        ))}
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </>
            }
        />
    );
};

export const TopSDIWithApps = ({ data, totalApps }) => {
    return (
        <ReportTable
            header={
                <TableRow>
                    <TableCell align="left">
                        <TitleTypography>Sensitive Data Identifier</TitleTypography>
                    </TableCell>
                    <TableCell align="right">
                        <TitleTypography>Apps W/ Identifier </TitleTypography>
                        <DataTypography>
                            (of {totalApps}) <ArrowDownward />
                        </DataTypography>
                    </TableCell>
                </TableRow>
            }
            content={
                <>
                    {data.map(row => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                <DataTypography>{row.name}</DataTypography>
                            </TableCell>
                            <TableCell align="right">
                                <DataTypography>{`${row.count}  (${getPercentage(
                                    row.count,
                                    totalApps
                                )})%`}</DataTypography>
                            </TableCell>
                        </TableRow>
                    ))}
                </>
            }
        />
    );
};

export const SourcesWithSensitiveDataTable = ({ data }) => {
    const sortedData = data.sort((x, y) => y.sensitiveEntities - x.sensitiveEntities);
    return (
        <ReportTable
            header={
                <TableRow>
                    <TableCell align="left" width={"44px"}></TableCell>
                    <TableCell align="left">
                        <TitleTypography>Source Type</TitleTypography>
                    </TableCell>
                    <TableCell align="right">
                        <TitleTypography>Sensitive Apps</TitleTypography>
                    </TableCell>
                    <TableCell align="right">
                        <TitleTypography>
                            Sensitive Entities <ArrowDownward />
                        </TitleTypography>
                    </TableCell>
                </TableRow>
            }
            content={
                <>
                    {sortedData.map(row => (
                        <TableRow key={row.source.name}>
                            <TableCell component="th" scope="row" sx={{ display: "inline-flex" }}>
                                <DataTypography>
                                    <AdapterIcon
                                        name={row.source.className}
                                        type={"source"}
                                        outlined={true}
                                        size={24}
                                    />
                                </DataTypography>
                            </TableCell>
                            <TableCell align="left">
                                <DataTypography>{row.source.name}</DataTypography>
                            </TableCell>
                            <TableCell align="right">
                                <DataTypography>{row.sensitiveApps}</DataTypography>
                            </TableCell>
                            <TableCell align="right">
                                <DataTypography>{row.sensitiveEntities}</DataTypography>
                            </TableCell>
                        </TableRow>
                    ))}
                </>
            }
        />
    );
};

export const TopSDIWithEventsAndApps = ({ data, totalApps, totalEntities }) => {
    return (
        <ReportTable
            header={
                <TableRow>
                    <TableCell align="left">
                        <TitleTypography>Sensitive Data Identifier</TitleTypography>
                    </TableCell>
                    <TableCell align="right">
                        <TitleTypography>
                            Entities W/ Identifier <ArrowDownward />
                        </TitleTypography>
                    </TableCell>
                    <TableCell align="right">
                        <TitleTypography>Apps</TitleTypography>
                    </TableCell>
                </TableRow>
            }
            content={
                <>
                    {data.slice(0, 5).map(row => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                <DataTypography>{row.name}</DataTypography>
                            </TableCell>
                            <TableCell align="right">
                                <DataTypography>{`${row.count}  (${getPercentage(
                                    row.count,
                                    totalApps
                                )})%`}</DataTypography>
                            </TableCell>
                            <TableCell align="right">
                                <DataTypography>{`${row.items.length}  (${getPercentage(
                                    row.items.length,
                                    totalEntities
                                )})%`}</DataTypography>
                            </TableCell>
                        </TableRow>
                    ))}
                </>
            }
        />
    );
};

export const getAdapterObject = (type, adapter) => {
    return {
        type: type,
        "database-provider-type": adapter.DatabaseProviderType,
        "adapter-name": adapter.adapterName
    };
};

export const TopHighSdiApps = ({ data, appInfo }) => {
    return (
        <ReportTable
            header={
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                        <TitleTypography>App Name</TitleTypography>
                    </TableCell>
                    <TableCell>
                        <TitleTypography>NsName</TitleTypography>
                    </TableCell>
                    <TableCell align="right">
                        <TitleTypography>
                            Entities W/ Identifier <ArrowDownward />
                        </TitleTypography>
                    </TableCell>
                </TableRow>
            }
            content={
                <>
                    {data.map(row => {
                        const [nsName, appName] = row.appName.split(".");
                        const { sourceNames, targetNames } = appInfo[row.appName];

                        return (
                            <TableRow key={row.appName} sx={{ verticalAlign: "baseline" }}>
                                <TableCell width={"90px"} sx={{ alignContent: "center" }}>
                                    <DataTypography>
                                        <SourceAndTargetIcons sourceNames={sourceNames} targetNames={targetNames} />
                                    </DataTypography>
                                </TableCell>
                                <TableCell component="th" scope="row" sx={{ lineHeight: "35px" }}>
                                    <DataTypography>{appName}</DataTypography>
                                </TableCell>
                                <TableCell component="th" scope="row" sx={{ lineHeight: "35px" }}>
                                    <DataTypography>{nsName}</DataTypography>
                                </TableCell>
                                <TableCell sx={{ lineHeight: "35px" }} align="right">
                                    <DataTypography>
                                        {row.numOfEntities} (
                                        {getPercentage(row.numOfEntities, row.totalNumberOfEntities)}%)
                                    </DataTypography>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </>
            }
        />
    );
};
