import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { StriimInputField, StriimTypography, StriimDropdown, StriimMenuList, StriimMenuItem } from "@striim/striim-ui";
import { Grid, InputAdornment, SvgIcon } from "@mui/material";

import { SpecialChevronDown } from "../../../../../generic/icon/customIcons";
import { styles } from "./version-selector.styles";
import dateLib from "core/utils/date-utils";
import { JobVersion, Version } from "../../../guardian/guardian-job.types";
import WithTooltip from "../../../../../generic/tooltip/tooltip";

interface VersionItemProps {
    version: Version;
    title?: string;
    showDivider?: boolean;
    onClick: Dispatch<SetStateAction<Version>>;
}

const VersionItem = ({ version, title, showDivider = false, onClick }: VersionItemProps) => {
    const [updatedDay, updatedTime] = useMemo(() => {
        const date = dateLib.unix(version.updatedTime / 1000);
        return [date.format("MMM DD, YYYY"), date.format("hh:mm A")];
    }, [version.updatedTime]);
    return (
        <StriimMenuItem
            onClick={() => {
                onClick(version);
            }}
            sx={styles.menuItem}
            divider={showDivider}
        >
            <Grid container flexDirection={"column"}>
                {title ? (
                    <Grid item px={"12px"} pt={2} pb={1}>
                        <StriimTypography variant="caption4" color="greyscale.700">
                            {title}
                        </StriimTypography>
                    </Grid>
                ) : null}
                <Grid display="flex" flexDirection={"column"} item px={"12px"} pt={1} pb={2} gap={0.5} maxWidth="100%">
                    <Grid item>
                        {WithTooltip(
                            <StriimTypography variant="body4" color="greyscale.900" sx={styles.versionName}>
                                {version.name}
                            </StriimTypography>,
                            version.name && version.name.length > 55 ? version.name : null
                        )}
                    </Grid>
                    <Grid item>
                        <StriimTypography variant="body4" color="greyscale.700">
                            {updatedDay} at {updatedTime}
                        </StriimTypography>
                    </Grid>
                </Grid>
            </Grid>
        </StriimMenuItem>
    );
};

interface VersionSelectorProps {
    selectedVersion?: JobVersion;
    versions: Version[];
    setSelectedVersion: Dispatch<SetStateAction<Version>>;
}

const VersionSelector = ({ versions, selectedVersion, setSelectedVersion }: VersionSelectorProps) => {
    const controlState = useState(false);

    return (
        <StriimDropdown
            extendOnClick
            controlState={controlState}
            closeOnSelect
            onClose={() => {
                controlState[1](false);
            }}
            placement="bottom-start"
            PopperProps={{
                disablePortal: true
            }}
            ContainerProps={{
                style: { width: "100%" }
            }}
            content={
                <StriimMenuList sx={styles.menuList}>
                    {versions.map((version, index) => {
                        return (
                            <VersionItem
                                key={version.name}
                                version={version}
                                title={index === 0 ? "MOST RECENT" : index === 1 ? "PREVIOUS" : null}
                                showDivider={index === 0 && versions.length > 1}
                                onClick={setSelectedVersion}
                            />
                        );
                    })}
                </StriimMenuList>
            }
            closeOnSelect
        >
            <StriimInputField
                sx={styles.input}
                InputProps={{
                    onClick: event => {
                        event.preventDefault();
                        controlState[1](true);
                    },
                    endAdornment: (
                        <InputAdornment position="end" sx={styles.inputAdornment}>
                            <SvgIcon component={SpecialChevronDown} sx={styles.chevronIcon} />
                        </InputAdornment>
                    ),
                    inputComponent: () => (
                        <Grid px={1.5} py={1} display={"flex"} alignItems={"center"} gap={0.5} maxWidth="95%">
                            <StriimTypography variant="body4" color="greyscale.700">
                                Version:
                            </StriimTypography>
                            <Grid item maxWidth="90%">
                                {WithTooltip(
                                    <StriimTypography variant="body4" color="primary.800" sx={styles.versionName}>
                                        {selectedVersion?.name}
                                    </StriimTypography>,
                                    selectedVersion?.name && selectedVersion.name.length > 40
                                        ? selectedVersion?.name
                                        : null
                                )}
                            </Grid>
                        </Grid>
                    )
                }}
            />
        </StriimDropdown>
    );
};

export default VersionSelector;
