import App from "app";
import _ from "underscore";
import LeafletMapMixin from "app/components/dashboard/visualization/charts/mixins/leafletmap";
import "app/components/dashboard/visualization/charts/seriesgenerators/leaflet/generator";

export default _({}).extend(LeafletMapMixin, {
    // Note: be sure to pass in a deep-cloned config object!
    setConfiguration: function (config) {
        var theme = config.theme;
        var config = config.config;
        if (!theme) return console.warn("No theme provided.");

        var seriesGenerator = this.getSeriesGenerator(config);
        var data = seriesGenerator.generate();
        this.chartConfig = {
            tilesUrl: config.tilesUrl,
            areas: config.areas,
            viewZoom: config.viewZoom,
            viewXOffset: Number(config.viewCenterLongitude),
            viewYOffset: Number(config.viewCenterLatitude),
            markersLayer: data.markersLayer,
            areaColors: data.areaColors,
            legendData: seriesGenerator.legendData,
        };
    },
    getSeriesGenerator: function (datavisualizationConfig) {
        var seriesConfigurationOptions = {
            latitudeField: datavisualizationConfig.latitudeField,
            longitudeField: datavisualizationConfig.longitudeField,
            categoryField: datavisualizationConfig.categoryField,
            areaIDField: datavisualizationConfig.areaIDField,
            timeField: datavisualizationConfig.timeField,
            colorField: datavisualizationConfig.colorField,
            tooltipConfig: datavisualizationConfig.tooltips,
            fieldList: this.model.dataSource.fieldList,
            areas: datavisualizationConfig.areas,
            conditionalColorRules: datavisualizationConfig.conditionalColor,
            valueField: datavisualizationConfig.valueField,
            data: this.model.dataSource.data,
            markerTypes: datavisualizationConfig.markerTypes,
            maxBubbleSize: datavisualizationConfig.maxBubbleSize,
            minBubbleSize: datavisualizationConfig.minBubbleSize,
            retentionStrategyType: datavisualizationConfig.retentionStrategyType,
            viewZoom: datavisualizationConfig.viewZoom,
            clickHandler: function (element) {
                this.trigger("datapoint:click", element);
            }.bind(this),
        };

        const seriesGeneratorFactory = new App.Chart.SeriesGenerators.Leaflet.SeriesGeneratorFactory();
        return seriesGeneratorFactory.create(seriesConfigurationOptions);
    },
});
