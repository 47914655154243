export const styles = {
    container: {
        height: "100%",
        borderRadius: "8px",
        backgroundColor: "additional.white"
    },
    filterContainer: {
        width: "70%"
    },
    endpointContainer: {
        display: "flex",
        alignItems: "center",
        gap: "4px"
    },
    deleteLink: {
        color: "critical.main"
    },
    icon: {
        width: 24,
        height: 24
    },
    signInStates: {
        color: "greyscale.900",
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px"
    },
    responseTextValue: {
        color: "greyscale.900"
    },
    closeIcon: {
        width: 16,
        height: 16,
        cursor: "pointer"
    }
};
