import React from "react";
import ReactDOM from "react-dom/client";
import _ from "underscore";
import Backbone from "backbone";
import { StriimTheme } from "@striim/striim-ui";

import SentinelLiveMonitor from "src/modules/ai-insights/sentinel-live-monitor/sentinel-live-monitor.tsx";
import metaStoreService from "../../../../../core/services/metaStoreService/meta-store-service";

const SentinelLiveMonitorView = Backbone.Marionette.LayoutView.extend({
    template: _.template(`<div>   </div>`),

    getViewValue: function() {},

    setViewValue: function() {},

    initialize: async function(options) {
        this.value = options;
    },

    onShow: async function() {
        try {
            const sentinels = await this.model.app.getApplicationComponentsByType(metaStoreService.entities.SENTINEL);
            const flowStatus = this.options.appModel.get("flowStatus");
            const onViewSettingsClick = this.options.onViewSettingsClick;

            if (!this.root) this.root = ReactDOM.createRoot(this.$el[0]);

            this.root.render(
                <StriimTheme preselector=".striim.materialize.light:not(#\20)">
                    <SentinelLiveMonitor
                        defaultSentinelSelected={this.value.sentinelName}
                        flowStatus={flowStatus}
                        sentinelsInApp={sentinels}
                        appName={this.model.app.name}
                        namespace={this.model.app.nsName}
                        onClose={() => {
                            this.trigger("sentinel-live-monitor-close");
                        }}
                        onToggleFullScreen={() => {
                            this.trigger("sentinel-live-monitor-toggle");
                        }}
                        onViewSettingsClick={onViewSettingsClick}
                    />
                </StriimTheme>
            );
        } catch (e) {
            console.error("Error rendering", e);
        }
    },

    onBeforeDestroy() {
        if (this.root) {
            this.root.unmount();
        }
    }
});

export default SentinelLiveMonitorView;
