import React from "react";
import { StriimTypography } from "@striim/striim-ui";
import { Grid } from "@mui/material";

import SourceDetailsTableFilter from "../source-details-table/source-details-table-filter";
import SourceDetailsTable from "../source-details-table/source-details-table";
import SourceSummary from "../summary-section/source-summary";
import EntityTableFilter from "../entity-table/entity-table-filter";
import { CONFIDENCE_SCORES, TableData, getConfidenceScoreIcon } from "../entity-table/utils";
import EntityTable from "../entity-table/entity-table";
import EntitySummary from "../summary-section/entity-summary";
import { Entity, Source } from "../../pii.types";

interface SourceDetailsProps {
    activeEntity: Entity;
    activeSource: Source;
    tableData: TableData[];
    filteredTableData: TableData[];
    setFilteredTableData: React.Dispatch<React.SetStateAction<TableData[]>>;
    setActiveEntity: (value: any) => void;
    sensitiveDataIdentifiers: { label: string; value: string }[];
}

const SourceDetails = ({
    tableData,
    filteredTableData,
    setFilteredTableData,
    sensitiveDataIdentifiers,
    activeSource,
    activeEntity,
    setActiveEntity
}: SourceDetailsProps) => {
    return (
        <Grid display={"flex"} gap={3} px={4} py={3} width={"100%"} height={"calc(100% - 83px)"}>
            <Grid display={"flex"} flexDirection={"column"} item gap={!activeEntity ? 2 : 0} flex={1}>
                {activeEntity ? (
                    <>
                        <EntityTableFilter
                            entityName={activeEntity.type}
                            fieldType={activeEntity.fieldType}
                            tableData={tableData}
                            filteredTableData={filteredTableData}
                            setFilteredTableData={setFilteredTableData}
                            sensitiveDataIdentifiers={sensitiveDataIdentifiers}
                            confidenceScores={Object.entries(CONFIDENCE_SCORES)}
                        />
                        <Grid container alignItems={"center"} mb={2}>
                            <StriimTypography variant="caption3">Confidence score : </StriimTypography>
                            <Grid display={"flex"} gap={2} ml={1}>
                                {Object.entries(CONFIDENCE_SCORES).map(value => {
                                    return (
                                        <Grid display="flex" gap={0.5} alignItems={"center"} item key={value[0]}>
                                            {getConfidenceScoreIcon(value[1])}
                                            <StriimTypography variant="caption3" color="greyscale.700">
                                                {value[0]}
                                            </StriimTypography>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                        <EntityTable
                            eventType={activeEntity.eventType}
                            fieldType={activeEntity.fieldType}
                            tableData={filteredTableData}
                        />
                    </>
                ) : (
                    <>
                        <SourceDetailsTableFilter
                            entityName={activeSource.type}
                            tableData={tableData}
                            setFilteredTableData={setFilteredTableData}
                            sensitiveDataIdentifiers={sensitiveDataIdentifiers}
                        />
                        <SourceDetailsTable
                            entityName={activeSource.type}
                            eventType={activeSource.eventType}
                            fieldType={activeSource.fieldType}
                            tableData={filteredTableData}
                            onClick={setActiveEntity}
                        />
                    </>
                )}
            </Grid>
            {activeEntity ? (
                <EntitySummary
                    sensitiveColumns={activeEntity.columnsWithSensitiveData}
                    totalColumns={activeEntity.totalColumns}
                    rowsSampled={activeEntity.rowsSampled}
                    identifiers={activeEntity.sensitiveDataIdentifiers}
                    eventType={activeEntity.eventType}
                    fieldType={activeEntity.fieldType}
                />
            ) : (
                <SourceSummary activeSource={activeSource} />
            )}
        </Grid>
    );
};

export default SourceDetails;
