import api from "core/api/api";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import Designer from "./designer";
import GraphModel from "./graphModel";
import FlowDatasource from "./CachedFlowDatasource";
import navigateTo from "src/navigate-to";
import growl from "../../common/growl";
import AppGroupsStore from "../../../../src/stores/app-groups-store";
import appGroupService from "app/components/common/app-control/app-groups-service";

function _initialize(mainView, options) {
    var applicationName = options.appName;
    var flowName = options.flowName;
    var nodeName = options.nodeName;
    var deploySettings = options.deploySettings;
    var appSettings = options.appSettings;
    var appErrors = options.appErrors;
    let progress = options.progress;
    let sentinelLiveMonitor = options.sentinelLiveMonitor;
    let sentinelName = options.sentinelName;
    let sherlock = options.sherlock;
    let jobName = options.jobName;

    var flowDatasource = new FlowDatasource({
        appName: applicationName
    });
    flowDatasource.invalidateCache();
    flowDatasource.load().then(async () => {
        if (!flowDatasource.app) {
            growl.error(`The application ${applicationName} was not found`, "App not found");
            navigateTo.Apps();
            return;
        }

        //required by TQL editor to filter out stuff from current namespace
        api.setCurrentNamespace(flowDatasource.app.nsName);
        var graphModel = new GraphModel();

        graphModel.app = flowDatasource.app;
        //TODO: handle EventTables
        // if (!graphModel.app.isFlowDesignerCompatible) {
        //     dialog.alert(Resources.FlowNotSupported, null);
        //     return false;
        // }

        graphModel.currentFlowId = flowName ? flowDatasource.app.nsName + ".FLOW." + flowName : flowDatasource.app.id;
        api.setCurrentAppId(flowDatasource.app.name);

        graphModel.datasource = flowDatasource;

        graphModel.nodes = flowDatasource.getObjectsForFlowId(graphModel.currentFlowId);

        // DEV-7572
        if (graphModel.nodes.length > 0) {
            var nonFlowNodes = graphModel.nodes.filter(function(node) {
                return node.metaObject.type !== "FLOW";
            });

            //DEV-7849, DEV-7872
            // if the main flow does not have any regular nodes (not subflows) then redirect to the first subflow (
            // it should happen for MultiLogApp for instance where 'Monitor Logs' should be opened by default
            if (nonFlowNodes.length === 0) {
                var mainFlow = graphModel.nodes.models[0].metaObject;
                flowName = MetaObjectConverter.getName(mainFlow.id);
                navigateTo.AppsSilently();
                navigateTo.Subflow(graphModel.current_app_full, flowName);
                graphModel.currentFlowId = flowName
                    ? flowDatasource.app.nsName + ".FLOW." + flowName
                    : flowDatasource.app.id;
                graphModel.nodes = flowDatasource.getObjectsForFlowId(mainFlow.id);

                //DEV-7849, DEV-7872
                // if the main flow does not have any regular nodes (not subflows) then redirect to the first subflow (
                // it should happen for MultiLogApp for instance where 'Monitor Logs' should be opened by default
                /* if (nonFlowNodes.length === 0) {
                    var mainFlow = graphModel.nodes.models[0].metaObject;
                    flowName = MetaObjectConverter.getName(mainFlow.id);
                    navigateTo.AppsSilently();
                    navigateTo.Subflow(graphModel.current_app_full, flowName);
                    graphModel.currentFlowId = flowName
                        ? flowDatasource.app.nsName + ".FLOW." + flowName
                        : flowDatasource.app.id;
                    graphModel.nodes = flowDatasource.getObjectsForFlowId(mainFlow.id);
                }*/
            }
        }

        var rootNode = flowDatasource.findNodeById(graphModel.currentFlowId);
        if (rootNode) {
            graphModel.currentFlowName = rootNode.metaObject.name;
        }

        graphModel.isEditing = true;

        // Fetch the group name of the current app
        const fetchGroups = async () => {
            const appId = MetaObjectConverter.convertShortNameToFullName(applicationName, "APPLICATION");
            const groupStore = new AppGroupsStore(appGroupService);
            await groupStore.fetch();
            return groupStore.getGroupByAppId(appId);
        };
        const group = await fetchGroups();

        var designerView = new Designer.View({
            model: graphModel,
            group: group
        });
        mainView.show(designerView);

        if (appErrors) {
            designerView.loadAppErrorsFromServer(graphModel);
        } else if (appSettings) {
            designerView.showAppSettings(graphModel, false);
        } else if (progress) {
            if (flowDatasource.app.flowStatus !== "CREATED") designerView.showProgress();
        } else if (deploySettings) {
            designerView.showAppSettings(graphModel, true);
        } else if (nodeName) {
            var selectedNode = $.grep(graphModel.nodes.models, function(node) {
                return MetaObjectConverter.getName(node.id) === nodeName;
            });
            if (selectedNode && selectedNode[0]) {
                designerView.showSidebar(selectedNode[0]);
                selectedNode[0].isSelected = true;
            }
        } else if (sherlock) {
            designerView.showDiscoverPII(graphModel, jobName);
        } else if (sentinelLiveMonitor) {
            designerView.showSentinelLiveMonitor(graphModel, sentinelName);
        } else {
            console.error("Unexpected flow type");
        }
    });
}

export default {
    initialize: _initialize
};
