import App from "app";
import api from "core/api/api";
import _ from "underscore";
import { template, className, regions, ui, triggers, on } from "core/decorators";
import ModalManager from "app/components/common/modal/ModalManager";
import NProgress from "nprogress";
import templateFile from "./exportApp.template.html";
import FileSaver from "file-saver";
import { isSafari } from "core/utils/browser-check";
import utils from "core/utils";
import growl from "../growl";
import JSZip from "jszip";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import "app/components/common/_deprecated/form-mixin-dialog/dialog";

NProgress.configure({
    minimum: 0.25,
    trickleRate: 0.1,
    trickleSpeed: 2000
});

let modalManager = new ModalManager({
    container: "body"
});

@template(templateFile)
@triggers({
    "click .submit": "form:submit"
})
export default class ExportDialog extends App.FormMixinDialog.View {
    initialize(options) {
        this.options = options;
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
    }

    @on("click #export-without-passphrase")
    exportWithoutPassphrase() {
        this.doExport(null);
    }

    @on("click #export-with-passphrase")
    exportWithoutPassphrase() {
        let val = this.$("#passphrase-input").val();
        this.doExport(val);
    }

    disableExportBtn() {
        this.$("#export-with-passphrase").attr("disabled", true);
    }

    enableExportBtn() {
        this.$("#export-with-passphrase").removeAttr("disabled");
    }

    showProgressIndicator(apps) {
        if (apps.length === 1) {
            const id = metaObjectConverter.convertShortNameToFullName(apps[0], "APPLICATION");
            App.vent.trigger("progress:start", id);
        }
    }

    doExport(passphrase) {
        let apps = this.model.get("apps");
        let that = this;
        let nsName = App.user.defaultNamespace;
        if (apps.length === 1) {
            this.showProgressIndicator(apps);
            nsName = utils.getNamespace(apps[0]);
        }

        api.setCurrentNamespace(nsName).then(() => {
            this.disableExportBtn();
            if (apps.length === 1) {
                api.createTQL(apps[0], passphrase)
                    .done(function(response) {
                        //fix for issues with filesaver.js and safari
                        if (isSafari()) {
                            growl.warning(
                                "Please press cmd+s in the new tab opened to manually save the TQl. ",
                                "Safari Warning"
                            );
                        }
                        FileSaver.saveAs(
                            new Blob([response], {
                                type: "text/plain"
                            }),
                            apps[0] + "_" + _.now() + ".tql"
                        );
                        that.enableExportBtn();
                        that.trigger("export:done");
                    })
                    .fail(function(e) {
                        that.enableExportBtn();
                        that.trigger("export:fail", e);
                    });
            } else {
                api.getAllApplicationsTQL(apps, passphrase)
                    .done(function(response) {
                        console.log(response);
                        //fix for issues with filesaver.js and safari
                        if (isSafari()) {
                            growl.warning(
                                "Please press cmd+s in the new tab opened to manually save the TQl. ",
                                "Safari Warning"
                            );
                        }
                        let zip = new JSZip();
                        _.each(response, (content, fileName) => {
                            zip.file(fileName, content);
                        });

                        zip.generateAsync({ type: "blob" }).then(function(content) {
                            saveAs(content, "Striim_Export_" + _.now() + ".zip");
                        });
                        that.trigger("export:done");
                    })
                    .fail(function(e) {
                        that.trigger("export:fail", e);
                    });
            }
        });
    }
}
