/**
 * For simple sources without any validation stuff. Straight up config wizards.
 */

import Backbone from "backbone";
import { DB_PROVIDERS } from "../database-reader/configs/db-providers";
import { View } from "../database-reader/base-database-reader";

class OJetReader extends View {
    setup() {
        this.model.get("connectionParams").set("type", DB_PROVIDERS.OJET.id);
        this.model.set("isCDC", true);
    }
}

export default {
    View: OJetReader,
    vent: new Backbone.Model()
};
