/*
 *  A small graphic that illustrates the current status of the app:
 *  Created, Deployed, Stopped, Running, or Invalid
 */
import App from "app";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import appStatusSynchronizer from "core/appStatusSynchronizer";
import statusManagement, { FlowStatus } from "src/status-management";
import template from "./app-indicator.html";

var AppIndicator = Backbone.View.extend({
    template: _.template(template),
    initialize: function (opts) {
        this.position = opts.position || "top";
        this.show_tooltip = opts.show_tooltip;
        this.appIdentifier = opts.identifier;
        this.appFullName = this.appIdentifier.id;
        this.status = opts.status || "";
        this.setElement(this.template({}));
        this.listenTo(
            appStatusSynchronizer,
            this.appFullName,
            function (app_status) {
                if (!app_status) {
                    return;
                }
                this.status = app_status.toLowerCase();
                this.render();
            }.bind(this)
        );
        //console.log("now, lets do this");
        var status = appStatusSynchronizer.getStatus(this.appFullName);
        if (status) {
            this.status = status.toLowerCase();
        }
    },
    render: function () {
        // The bulk of rendering is to set the data-status attribute. CSS does the rest
        this.$el.attr("data-status", this.status);

        // TODO not sure if this is the right place to bind indicator click action
        this.$el.on(
            "click",
            function () {
                if (statusManagement.isApplicationCrash(this.status)) {
                    App.navigate("/flow/" + this.appIdentifier.nsName + "." + this.appIdentifier.name + "/view/exceptions", {
                        trigger: true,
                    });
                }
            }.bind(this)
        );
        this.$el.toggleClass("clickable", statusManagement.isApplicationCrash(this.status));

        // If we want to show a tooltip, add it now
        if (this.show_tooltip) {
            if (this.$el.is(".tooltipstered")) {
                this.$el.tooltipster("destroy");
            }

            var tooltip_content = "This app is " + statusManagement.getStatusDisplayName(this.status) + ".";

            if (this.status.toUpperCase() === FlowStatus.INVALID) {
                //tooltip_content += ' <a href="#flow/edit/'+this.appname+'">Fix errors to revalidate.</a>';
                tooltip_content = "This app is invalid. Please fix errors to revalidate.";
            }
            if (this.status.toUpperCase() === FlowStatus.DEPLOY_FAILED) {
                tooltip_content = "This app has failed during deployment. Please fix errors to revalidate.";
            }
            if (statusManagement.isApplicationCrash(this.status)) {
                tooltip_content = `This app has ${
                    this.status.toUpperCase() === FlowStatus.HALT ? "halted" : "terminated"
                }. Please fix the errors to resume.`;
            }
            if (this.status.toUpperCase() === FlowStatus.NOT_ENOUGH_SERVERS) {
                tooltip_content = "This app does not have enough servers. Please start enough servers to revalidate";
            }

            var $content = $('<div class="app-status-description">' + tooltip_content + "</div>");
            this.$el.tooltipster({
                position: this.position,
                interactive: true,
                content: $content,
                functionReady: function (origin, tooltip) {
                    var tooltipOffset = tooltip.offset();
                    if (tooltipOffset.left < 0) {
                        tooltip.offset({
                            top: tooltipOffset.top,
                            left: 0,
                        });
                    }
                },
            });
        }
    },
    destroy: function () {
        this.unbind();
        this.stopListening();
        if (this.show_tooltip) {
            this.$el.tooltipster("destroy");
        }
        this.$el.remove();
        this.remove();
        delete this.options;
        delete this.$el;
        delete this.el;
    },
});

export default AppIndicator;
