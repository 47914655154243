import React, { Dispatch, SetStateAction, forwardRef, useCallback, useEffect, useRef, useState, useMemo } from "react";
import { Box, Divider, SvgIcon } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { RowNode, GetRowIdParams, ICellRendererParams } from "ag-grid-community";
import { components } from "react-select";
import { StriimTypography, StriimDataTable, StriimInputField, StriimLink, StriimIconButton } from "@striim/striim-ui";

import { InfoCircle, Add, DeleteIcon } from "../../../../generic/icon/customIcons";
import HighPriorityIcon from "../../../../generic/icon/customIcons/SDI-Importance.svg";
import MediumPriorityIcon from "../../../../generic/icon/customIcons/Severity-Events.svg";
import LowPriorityIcon from "src/generic/icon/customIcons/square-arrow-down.svg";
import { SDI_IMPORTANCE } from "../../consts";
import styles from "./identifier-actions-table.styles";
import columns from "./columns.json";
import expandedColumns from "./columns-expanded.json";
import ActionsCustomMaskingModal from "./actions-custom-masking-modal";
import utils from "../../../../../core/utils";
import { SENTINEL_ACTIONS } from "../../sentinel/panel/sentinel-panel-consts";
import ActionsDropdown from "./actions-dropdown";

export interface MaskingOption {
    label: string;
    value: string;
}

export interface ImportanceOption {
    label: string;
    value: string;
    icon: React.Component;
}

export interface TableData {
    name: string;
    action: MaskingOption;
    importance: ImportanceOption;
    maskingString?: string;
    maskingType?: string;
}

export interface IdentifierActionsTableProps {
    isExpanded: boolean;
    tablesData: TableData[];
    rowData: TableData[];
    setRowData: Dispatch<SetStateAction<TableData[]>>;
    maskingOptions: MaskingOption[];
    importanceOptions: ImportanceOption[];
    pinnedBottomRowData?: TableData[];
    setPinnedBottomRowData?: Dispatch<SetStateAction<TableData[]>>;
    isEditable?: boolean;
    isSelectable?: boolean;
    searchQuery?: string;
    hideDoNothing?: boolean;
    customHeight?: number | string;
    sdiConfig: object;
    isSettingsPage?: boolean;
    partialMaskingSDIOptions?: string[];
}

const priorityIconMap = {
    [SDI_IMPORTANCE.HIGH]: HighPriorityIcon,
    [SDI_IMPORTANCE.MEDIUM]: MediumPriorityIcon,
    [SDI_IMPORTANCE.LOW]: LowPriorityIcon
};

const IdentifierActionsTable: React.ForwardRefRenderFunction<HTMLDivElement, IdentifierActionsTableProps> = (
    {
        isExpanded = false,
        tablesData = [],
        rowData = [],
        setRowData = () => {},
        pinnedBottomRowData = [],
        setPinnedBottomRowData = () => {},
        maskingOptions = [],
        importanceOptions = [],
        isEditable = false,
        isSelectable = false,
        searchQuery = "",
        hideDoNothing = false,
        customHeight = undefined,
        isSettingsPage = false,
        partialMaskingSDIOptions = []
    },
    ref
) => {
    const [selectedRows, setSelectedRows] = useState<TableData[]>([]);
    const [selectedBulkAction, setSelectedBulkAction] = useState<MaskingOption>();
    const gridRef = useRef<AgGridReact>(null);
    const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
    const [currentEditingRow, setCurrentEditingRow] = useState<TableData | null>(null);

    const iconColumnWidth = 40;

    const getRowId = useCallback((params: GetRowIdParams) => String(params.data.name), []);
    const isAddRow = useCallback(rowProp => rowProp?.node?.rowPinned === "bottom", []);
    const isRowSelectable = useCallback((rowNode: RowNode) => rowNode?.rowPinned !== "bottom", []);

    const getTableColumns = useCallback(() => {
        const columnsData = !isSelectable ? columns : expandedColumns;
        let firstColumn = { ...columnsData[0] };
        let secondColumn = { ...columnsData[1] };
        let thirdColumn = { ...columnsData[2] };

        firstColumn["headerCheckboxSelection"] = () => isSelectable;
        firstColumn["checkboxSelection"] = () => isSelectable;
        firstColumn["headerCheckboxSelectionFilteredOnly"] = true;
        firstColumn["cellRendererSelector"] = params => {
            if (isAddRow(params)) {
                const cell = params.eGridCell;
                const cellValue = cell.querySelector(".ag-cell-value");
                if (cellValue) {
                    cellValue.style.overflow = "visible";
                }
                return {
                    component: "addRenderer",
                    params: { isEditable, tablesData, cellStyle: { overflow: "visible" } },
                    cellStyle: { overflow: "visible" }
                };
            }
            return undefined;
        };
        firstColumn["colSpan"] = params => {
            return isAddRow(params) ? 2 : 1;
        };
        secondColumn["cellRendererParams"] = {
            isEditable,
            maskingOptions
        };

        if (isExpanded) {
            const fourthColumn = { ...columnsData[3] };

            return hideDoNothing && isEditable
                ? [firstColumn, secondColumn, thirdColumn, fourthColumn]
                : [firstColumn, secondColumn];
        }

        let fourthColumn = { ...columnsData[4] };
        fourthColumn["cellRendererParams"] = {
            isEditable,
            importanceOptions
        };

        return hideDoNothing && isEditable
            ? [firstColumn, secondColumn, thirdColumn]
            : [firstColumn, secondColumn, fourthColumn];
    }, [columns, isSelectable, isEditable, hideDoNothing, isAddRow, tablesData, maskingOptions]);

    const columnDefs = useMemo(() => getTableColumns(), [isExpanded, getTableColumns]);

    useEffect(() => {
        if (hideDoNothing) {
            setPinnedBottomRowData(
                isEditable
                    ? [
                          {
                              name: "Add Identifier",
                              action: { label: "", value: "" },
                              importance: { label: "", value: "", icon: undefined }
                          }
                      ]
                    : []
            );
        }
    }, [rowData]);

    useEffect(() => {
        if (selectedRows.length > 0) {
            const bulkAction = maskingOptions.find(maskingOption =>
                selectedRows.every(row => row.action.value === maskingOption.value)
            );
            setSelectedBulkAction(bulkAction ?? { value: "mixed", label: "Mixed" });
        }
    }, [selectedRows, maskingOptions]);

    const RemoveRowButton: React.FC<ICellRendererParams> = props => {
        if (isAddRow(props)) {
            return undefined;
        }

        const onClick = useCallback(() => {
            if (!isEditable) {
                return;
            }
            let rowData = props.data;
            setRowData(data => data.filter(entry => entry.name !== rowData.name));
        }, [props.data, setRowData, isEditable]);

        return (
            <div
                style={{
                    cursor: isEditable ? "pointer" : "auto",
                    width: iconColumnWidth
                }}
            >
                <StriimIconButton size="small" disabled={!isEditable} sx={{ width: iconColumnWidth }}>
                    <SvgIcon
                        component={DeleteIcon}
                        onClick={onClick}
                        sx={{
                            width: iconColumnWidth,
                            ...styles.deleteIcon
                        }}
                        data-testid="DeleteIcon"
                    />
                </StriimIconButton>
            </div>
        );
    };

    const ImportanceRemoveOnHoverButton: React.FC<ICellRendererParams> = props => {
        if (isAddRow(props)) {
            return undefined;
        }

        const { action, importance } = props.data;

        const [showRemoveButton, setShowRemoveButton] = useState(false);

        const onClick = useCallback(() => {
            if (!isEditable) {
                return;
            }
            let rowData = props.data;
            setRowData(data => data.filter(entry => entry.name !== rowData.name));
        }, [props.data, setRowData, isEditable]);

        return (
            <div
                onMouseEnter={
                    isEditable
                        ? () => {
                              setShowRemoveButton(true);
                          }
                        : undefined
                }
                onMouseLeave={() => {
                    setShowRemoveButton(false);
                }}
                style={{
                    cursor: isEditable ? "pointer" : "auto",
                    width: iconColumnWidth
                }}
            >
                <StriimIconButton size="small" disabled={!isEditable} sx={{ width: iconColumnWidth }}>
                    {!showRemoveButton ? (
                        <SvgIcon component={priorityIconMap[importance]} sx={{ width: iconColumnWidth }} />
                    ) : (
                        <SvgIcon
                            component={DeleteIcon}
                            onClick={onClick}
                            sx={{
                                width: iconColumnWidth,
                                ...styles.deleteIcon
                            }}
                            data-testid="DeleteIcon"
                        />
                    )}
                </StriimIconButton>
            </div>
        );
    };

    const ImportanceRenderer: React.FC<ICellRendererParams> = props => {
        return (
            <div
                style={{
                    width: iconColumnWidth,
                    display: "flex",
                    alignItems: "center"
                }}
            >
                {props?.data?.importance && typeof props?.data?.importance === "string" && (
                    <>
                        <SvgIcon component={priorityIconMap[props.data.importance]} sx={{ width: iconColumnWidth }} />
                        <StriimTypography variant="body4">{utils.capitalize(props?.data?.importance)}</StriimTypography>
                    </>
                )}
            </div>
        );
    };

    const AddIdentifierBtn: React.FC<ICellRendererParams> = props => {
        const divRef = useRef(null);
        const cell = props.eGridCell.querySelector(".ag-cell-value");
        if (cell) {
            cell.style.overflow = "visible";
        }
        const [addMode, setAddMode] = useState(props.value === "Adding");
        const [doNothingIds, setDoNothingIds]: [TableData[], Dispatch<SetStateAction<TableData[]>>] = useState(() =>
            props.tablesData.filter(id => !rowData.some((row: { name: string }) => row?.name === id?.name))
        );

        const handleClickOutside = (event: MouseEvent) => {
            if (divRef.current && !divRef.current.contains(event.target as Node)) {
                setAddMode(false);
            }
        };

        useEffect(() => {
            setDoNothingIds(
                props.tablesData.filter(id => !rowData.some((row: { name: string }) => row?.name === id?.name))
            );
        }, [props.tablesData]);

        useEffect(() => {
            let hiddenIds = [...props.tablesData];
            gridRef.current?.api?.forEachNode(node => {
                const index = hiddenIds.findIndex(item => item?.name === node?.data?.name);
                if (index !== -1) {
                    hiddenIds.splice(index, 1);
                }
            });
            setDoNothingIds(hiddenIds);
        }, [pinnedBottomRowData]);

        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, []);

        return (
            <div ref={divRef}>
                {!addMode ? (
                    <StriimLink
                        sx={{ left: !isExpanded ? 15 : -23 }}
                        disabled={!props.isEditable}
                        onClick={() => {
                            setAddMode(true);
                        }}
                        startIcon={<SvgIcon component={Add} />}
                    >
                        Data Identifier
                    </StriimLink>
                ) : (
                    <StriimInputField
                        sx={{ maxWidth: 150, left: !isExpanded ? 25 : -10 }}
                        dataTestId="select-additional-identifier"
                        SelectProps={{
                            options: doNothingIds.map((id, index) => ({ label: id?.name, value: index })),
                            zIndex: 1502,
                            menuShouldScrollIntoView: false,
                            menuPosition: "absolute",
                            menuPortalTarget: document.body
                        }}
                        onChange={option => setRowData(data => [...data, doNothingIds[option?.value]])}
                        select
                    />
                )}
            </div>
        );
    };

    const ImportanceInput: React.FC<ICellRendererParams> = props => {
        if (isAddRow(props)) {
            return undefined;
        }

        const handleChange = useCallback(
            (option: ImportanceOption) => {
                setRowData(data =>
                    data.map(entry => (entry.name === props.data.name ? { ...entry, importance: option } : entry))
                );
            },
            [setRowData, props.data]
        );

        if (props.isEditable) {
            return (
                <StriimInputField
                    SelectProps={{
                        options: props.importanceOptions,
                        menuShouldScrollIntoView: false,
                        menuPosition: "absolute",
                        menuPortalTarget: document.body,
                        zIndex: 1502,
                        isClearable: false,
                        isSearchable: false,
                        components: {
                            SingleValue: props => {
                                return (
                                    <components.SingleValue {...props}>
                                        <Box display="flex" alignItems="center">
                                            {props.data.icon && (
                                                <Box mr={1} display="flex" alignItems="center">
                                                    {React.cloneElement(props.data.icon, {
                                                        style: { width: 18, height: 18 }
                                                    })}
                                                </Box>
                                            )}

                                            <StriimTypography variant="body4" color="primary.800">
                                                {props.data.label}
                                            </StriimTypography>
                                        </Box>
                                    </components.SingleValue>
                                );
                            }
                        }
                    }}
                    onChange={handleChange}
                    value={props.value.value ? props.value : undefined}
                    select
                />
            );
        }
        return (
            <Box display="flex" alignItems="center" m={1}>
                <Box mr={1} display="flex" alignItems="center">
                    {React.cloneElement(props.value.icon, { style: { width: 18, height: 18 } })}
                </Box>
                <StriimTypography variant="body4" color="primary.800">
                    {props.value?.label}
                </StriimTypography>
            </Box>
        );
    };

    const ActionsInput: React.FC<ICellRendererParams> = props => {
        const { partialMaskingSDIOptions } = props.context;

        if (isAddRow(props)) {
            return undefined;
        }

        const isExcluded = !partialMaskingSDIOptions?.includes(props.data.name);

        const filteredMaskingOptions = useMemo(() => {
            if (isExcluded) {
                return props.maskingOptions.filter(option => option.value !== SENTINEL_ACTIONS.MASK_PARTIALLY);
            }
            return props.maskingOptions;
        }, [isExcluded, props.maskingOptions]);

        const handleChange = useCallback(
            (option: MaskingOption) => {
                if (option.value === SENTINEL_ACTIONS.CUSTOM_MASK) {
                    setCurrentEditingRow(props.node.data);
                    setIsCustomModalOpen(true);
                    return;
                }

                if (option.value === props.value?.value && option.value !== SENTINEL_ACTIONS.CUSTOM_MASK) {
                    return;
                }

                setRowData(data =>
                    data.map(entry =>
                        entry.name === props.data.name
                            ? { name: entry.name, action: option, importance: entry?.importance }
                            : entry
                    )
                );
            },
            [setRowData, props.data?.name, props.value?.value, props.node.data]
        );

        return (
            <ActionsDropdown
                value={props.value}
                options={filteredMaskingOptions}
                isEditable={props.isEditable}
                maskingString={props.data.maskingString}
                onChange={handleChange}
                isSettingsPage={isSettingsPage}
            />
        );
    };

    const DataIdentifierTitle: React.FC = () => (
        <>
            <StriimTypography variant="body3" color="greyscale.600">
                {"Data Identifier "}
            </StriimTypography>
            <SvgIcon component={InfoCircle} sx={styles.infoCircleIcon} color="white" />
        </>
    );

    const handleBulkActionChange = useCallback(
        (option: MaskingOption) => {
            const selectedIds: string[] = selectedRows.map(entry => entry?.name);
            setRowData(data =>
                data.map(entry =>
                    selectedIds.includes(entry?.name)
                        ? { name: entry?.name, action: option, importance: entry?.importance }
                        : entry
                )
            );
            setSelectedBulkAction(option);
            gridRef.current?.api?.deselectAll();
        },
        [maskingOptions, selectedRows, setRowData, setSelectedBulkAction, gridRef]
    );

    const onSelectionChanged = useCallback(() => {
        setSelectedRows(gridRef.current?.api?.getSelectedRows() as TableData[]);
    }, [setSelectedRows, gridRef]);

    const handleCustomMaskingConfirm = useCallback(
        (maskingConfig: { maskingType: string; maskingString: string; m?: number; n?: number }) => {
            if (currentEditingRow) {
                const customValue =
                    maskingConfig.maskingType === SENTINEL_ACTIONS.MASK_M_N
                        ? SENTINEL_ACTIONS.MASK_M_N
                        : SENTINEL_ACTIONS.MASK_BY_REGEX;

                const customOption = maskingOptions.find(opt => opt.value === customValue);

                setRowData(data =>
                    data.map(entry =>
                        entry.name === currentEditingRow.name
                            ? {
                                  ...entry,
                                  action: customOption || { label: "Custom Mask", value: SENTINEL_ACTIONS.CUSTOM_MASK },
                                  maskingString: maskingConfig.maskingString,
                                  maskingType: maskingConfig.maskingType,
                                  m: maskingConfig.m,
                                  n: maskingConfig.n
                              }
                            : entry
                    )
                );
                setCurrentEditingRow(null);
            }
            setIsCustomModalOpen(false);
        },
        [currentEditingRow, maskingOptions, setRowData]
    );

    const onHeaderCheckboxSelection = useCallback(
        (params: { selected: boolean }) => {
            const api = gridRef.current?.api;
            if (!api) return;

            const shouldSelect = params.selected;
            api.forEachNodeAfterFilterAndSort((node: RowNode) => {
                if (isRowSelectable(node)) {
                    node.setSelected(shouldSelect);
                }
            });
            return false;
        },
        [isRowSelectable]
    );

    const handleDeleteSelectedRows = useCallback(() => {
        if (!isEditable || selectedRows.length === 0) return;

        const selectedIds = selectedRows.map(row => row.name);
        setRowData(data => data.filter(entry => !selectedIds.includes(entry.name)));
        gridRef.current?.api?.deselectAll();
    }, [selectedRows, isEditable]);

    return (
        <Box display={"grid"} gap={1}>
            {selectedRows.length > 0 && (
                <Box sx={styles.multiselectContainer}>
                    <StriimTypography variant="body4" color="greyscale.900">
                        {selectedRows.length} Selected
                    </StriimTypography>
                    <Box display="flex" alignItems="center">
                        <StriimTypography mr={2} color="greyscale.700" variant="body4">
                            Recommended Actions
                        </StriimTypography>
                        <StriimInputField
                            dataTestId="common-action-select"
                            SelectProps={{
                                options: maskingOptions,
                                menuShouldScrollIntoView: false,
                                menuPosition: "absolute",
                                zIndex: 1502,
                                isClearable: false
                            }}
                            onChange={value => handleBulkActionChange(value)}
                            value={selectedBulkAction}
                            select
                        />
                        <StriimIconButton size="small" onClick={handleDeleteSelectedRows} sx={{ ml: 2 }}>
                            <SvgIcon
                                component={DeleteIcon}
                                sx={{
                                    width: iconColumnWidth,
                                    ...styles.deleteIcon
                                }}
                                data-testid="DeleteIcon"
                            />
                        </StriimIconButton>
                    </Box>
                </Box>
            )}
            <Box maxHeight={customHeight}>
                <StriimDataTable
                    components={{
                        actionRenderer: ActionsInput,
                        titleRenderer: DataIdentifierTitle,
                        addRenderer: AddIdentifierBtn,
                        importanceRenderer: ImportanceRenderer,
                        importanceInputRenderer: ImportanceInput,
                        removeRenderer: RemoveRowButton,
                        importanceRemoveRenderer: ImportanceRemoveOnHoverButton
                    }}
                    context={{ partialMaskingSDIOptions }}
                    onSelectionChanged={onSelectionChanged}
                    data={rowData}
                    columns={columnDefs}
                    enableCellTextSelection
                    rowSelection="multiple"
                    ref={gridRef}
                    getRowId={getRowId}
                    quickFilterText={searchQuery}
                    isRowSelectable={isRowSelectable}
                    pinnedBottomRowData={pinnedBottomRowData}
                    onHeaderCheckboxSelection={onHeaderCheckboxSelection}
                    data-testid="data-identifiers"
                />
            </Box>
            <ActionsCustomMaskingModal
                isVisible={isCustomModalOpen}
                setCustomMaskingModalVisible={setIsCustomModalOpen}
                onConfirm={handleCustomMaskingConfirm}
                initialMaskingConfig={
                    currentEditingRow
                        ? {
                              maskingType: currentEditingRow.maskingType || SENTINEL_ACTIONS.MASK_M_N,
                              maskingString: currentEditingRow.maskingString || ""
                          }
                        : undefined
                }
            />
        </Box>
    );
};

export default forwardRef<HTMLDivElement, IdentifierActionsTableProps>(IdentifierActionsTable);
