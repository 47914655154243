import React from "react";
import { SvgIcon } from "@mui/material";

import { styles } from "./entity-table.styles";
import { SquareArrowUp, SeverityRounded, ConfidenceLow } from "../../../../../generic/icon/customIcons";

const CONFIDENCE_LEVELS = {
    HIGH: "High",
    MEDIUM: "Medium",
    LOW: "Low"
};

// High: [81, 100]
// Medium: [51, 80]
// Low: [0, 50]

const CONFIDENCE_SCORES = {
    [CONFIDENCE_LEVELS.HIGH]: 81,
    [CONFIDENCE_LEVELS.MEDIUM]: 51,
    [CONFIDENCE_LEVELS.LOW]: 0
};

const ConfidenceScoreIcons = {
    [CONFIDENCE_LEVELS.HIGH]: <SvgIcon component={SquareArrowUp} sx={styles.chipIcon} />,
    [CONFIDENCE_LEVELS.MEDIUM]: <SvgIcon component={SeverityRounded} sx={styles.chipIcon} />,
    [CONFIDENCE_LEVELS.LOW]: <SvgIcon component={ConfidenceLow} sx={styles.chipIcon} />
};

type TableData = {
    name: string;
    sensitiveDataIdentifiers: {
        name: string;
        confidenceScore: number;
    }[];
};

const getConfidenceScoreIcon = (score: number) => {
    return score >= CONFIDENCE_SCORES[CONFIDENCE_LEVELS.HIGH]
        ? ConfidenceScoreIcons[CONFIDENCE_LEVELS.HIGH]
        : score >= CONFIDENCE_SCORES[CONFIDENCE_LEVELS.MEDIUM]
        ? ConfidenceScoreIcons[CONFIDENCE_LEVELS.MEDIUM]
        : ConfidenceScoreIcons[CONFIDENCE_LEVELS.LOW];
};

export { CONFIDENCE_LEVELS, CONFIDENCE_SCORES, TableData, getConfidenceScoreIcon };
