export default {
    baseContainerStyles: isExpanded => ({
        width: isExpanded ? "-webkit-fill-available" : "100%",
        display: "grid",
        backgroundColor: "greyscale.50",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "greyscale.200",
        py: 2,
        gap: 2
    }),
    horizontalPadding: isExpanded => ({
        pl: isExpanded ? 3 : 2,
        pr: isExpanded ? "300px" : 2
    }),
    docIcon: {
        height: "14px",
        width: "14px",
        "& path": {
            fill: "none"
        }
    },
    actionIcons: {
        width: "24px",
        height: "20px",
        gap: 1,
        "& > path": {
            fill: "inherit"
        },
        cursor: "pointer"
    },
    newTabIcon: {
        fill: "transparent",
        width: "18px",
        height: "18px"
    },
    learnLink: {
        px: 0.5,
        height: "18px",
        ".MuiButton-endIcon": { ml: 0.5 }
    },
    actionsBox: { gap: 1, display: "grid" },
    headerContainer: { display: "flex", alignItems: "center" },
    messageBoxHeader: {
        "& .MuiCardHeader-root": {
            padding: "8px"
        }
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    }
};
