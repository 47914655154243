export const styles = {
    backgroundSelected: {
        backgroundColor: "#ffff",
        "user-select": "none",
        borderTop: ({ palette }) => "1px solid " + palette.greyscale[200],
        borderBottom: ({ palette }) => "1px solid " + palette.greyscale[200],
        marginInline: "-20px"
    },
    background: {
        cursor: "pointer",
        marginInline: "-20px"
    },
    container: {
        justifyContent: "space-between",
        display: "flex"
    },
    groupName: {
        color: "greyscale.700",
        fontWeight: "400"
    },
    groupNameSelected: {
        textDecoration: "uppercase",
        color: "greyscale.900",
        fontWeight: "500"
    },
    text: {
        textOverflow: "ellipsis",
        width: 115,
    },
    groupContainer: {
        width: '100%',
        overflow: 'hidden',
        padding: "10px 16px",
        display: "flex",
        alignItems: "center"
    },
    icon: {
        marginRight: 1,
        minWidth: 20,
        minHeight: 20,
        color: "transparent"
    },
};


