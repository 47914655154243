import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import withTheme from "../theme/theme";
import $ from "jquery";
import { LinearProgress } from "@mui/material";
import { InlineDocContext } from "./inline-doc-panel";
import "prismjs"; // Import the core library
import "prismjs/components/prism-javascript.min"; // Import specific language (JavaScript, in this case)
import "prismjs/components/prism-bash.min"; // Import specific language (JavaScript, in this case)
import "prismjs/components/prism-sql.min"; // Import specific language (JavaScript, in this case)
import "prismjs/components/prism-batch.min";
// import 'prismjs/themes/prism-okaidia.min.css'; // Import the default Prism CSS theme
import xss from "xss";

import securityService from "../../../core/services/securityService/securityService"; // Import specific language (JavaScript, in this case)
// import 'prismjs/plugins/autoloader/prism-autoloader'; // Import the autoloader plugin

function echo(message) {
    console.info("%c INLINE DOC ", "background: #333; color: rgb(8, 155, 238)", message);
}

function isInlineDocDebugModeEnabled() {
    return sessionStorage.getItem("disableInlineDocCache") === "true";
}

/**
 * Fetches the content from the proxy
 * @param url
 * @returns {Promise<string>}
 */
export async function fetchHTML(root, path, cssSelector) {
    root = root || window.location.origin;
    root = root + "/inlinedocs/";
    const url = root + path;

    if (path === undefined || path === "undefined") {
        if (isInlineDocDebugModeEnabled()) {
            echo(`Ignoring this request since path is undefined: ${url}`);
        }
        return null;
    }

    const key = "inlinedoc::" + btoa(root + path + cssSelector);
    const cachedData = sessionStorage.getItem(key);

    if (isInlineDocDebugModeEnabled()) {
        echo(`Requested the following url: root: ${root}, Path:${path}, cssSelector: ${cssSelector}, url: ${url}`);
    }

    if (cachedData) {
        if (isInlineDocDebugModeEnabled()) {
            echo(`Data returned from browser cache: ${url}`);
        }
        return cachedData;
    }

    try {
        const request = await fetch(url);
        const response = await request.text();
        if (
            response.indexOf("Page not found - Striim") === -1 &&
            response.indexOf("Error 404") === -1 &&
            response.indexOf("we lost that page") === -1
        ) {
            if (!isInlineDocDebugModeEnabled()) {
                sessionStorage.setItem(key, response);
            } else {
                echo(`URL Fetched and cached ${url}`);
            }
            return response;
        } else {
            if (isInlineDocDebugModeEnabled()) {
                echo(`This URL threw 404: ${url}`);
            }
        }
    } catch (e) {
        console.error(`Error fetching HTML : ${e}`);
    }
    return null;
}

/**
 * Applies default preformat for the code received, like changing relative URLs to absolute etc
 * @param $payload
 */
function defaultPreformat($payload) {
    $payload
        .find(".titlepage")
        .first()
        .attr("id", "main-title");
    $payload.find("a").each(function() {
        $(this).attr("target", "_blank");
        if (
            $(this)
                .attr("href")
                .indexOf("http") !== 0
        ) {
            $(this).attr("href", securityService.inlineDocRoot + "/" + $(this).attr("href"));
        }
    });

    $payload.find("pre").each(function() {
        const currHTML = $(this).html();
        $(this).addClass("language-bash");
        const formattedHTML = $('<code class="language-bash"> ' + currHTML + "</code>");
        $(this).empty();
        $(this).html(formattedHTML);
    });

    $payload.find("tr > td:nth-child(1) > p").each(function() {
        let html = $(this).html();
        let id = html
            .toLowerCase()
            .replaceAll(" ", "")
            .replaceAll(",", "");
        $(this)
            .closest("tr")
            .prop("id", "doc-prop-" + id);
    });
}

/**
 * Given the HTML and a CSS selector, gets back the content as html
 * @param html
 * @param cssSelector
 * @returns {any}
 */
function getSelectorContent(html, cssSelector) {
    const $response = $(html);
    cssSelector = cssSelector || "section.section";
    let $payload = $response.find(cssSelector).first();
    defaultPreformat($payload);
    const output = $payload.prop("outerHTML");
    if (!output && isInlineDocDebugModeEnabled()) {
        echo(`404 - CSS Selector not found - ${cssSelector}`);
    }
    if (!output) {
        throw new Error("404 - CSS Selector not found");
    }
    return output;
}

/**
 *
 * @param root
 * @param path
 * @param cssSelector
 * @param preformatOutput
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const InlineDocContainer = function({ root, path, cssSelector, preformatOutput, className }) {
    root = root || window.location.origin;
    const [html, setHTML] = useState("");
    const key = "inlinedoc::" + btoa(root + path + cssSelector);
    let has404, setHas404;
    try {
        [has404, setHas404] = useContext(InlineDocContext);
    } catch (e) {
        console.log("Using outside of container");
    }
    async function doCall() {
        if (!setHas404) setHas404 = () => {};
        if (!path) setHas404(true);
        setHTML("");
        const cachedData = sessionStorage.getItem(key);
        if (cachedData) {
            let output = getSelectorContent(cachedData, cssSelector);
            if (preformatOutput) {
                output = preformatOutput(output);
            }
            setHTML(output);
            setHas404(false);
        } else {
            try {
                const html = await fetchHTML(root, path, cssSelector);
                if (!html) {
                    throw new Error("Inline Content does not exist for this page");
                }
                let output = getSelectorContent(html, cssSelector);
                if (preformatOutput) {
                    output = preformatOutput(output);
                }
                setHTML(output);
                setHas404(false);
            } catch (e) {
                setHas404(true);
                const errorMessage = `<h3>The content you are trying to access does not exist.</h3>`;
                setHTML(errorMessage);
            }
        }
    }

    useEffect(
        function() {
            doCall();
            setTimeout(function() {
                Prism.highlightAll();
            }, 500);
        },
        [path]
    );

    const whiteList = xss.getDefaultWhiteList();
    // allow class attribute for pre/code tag
    whiteList.pre.push("class");
    whiteList.code.push("class");
    const docXSS = new xss.FilterXSS({
        whiteList,
        onTagAttr: function(tag, name, value) {
            //hides the title of the inline doc
            if (name === "id" && value === "main-title" && tag === "div") {
                return `class = 'hide'`;
            }
        }
    });
    return (
        <div
            data-content-path={root + "/inlinedocs/" + path}
            data-test-id="inline-doc-container"
            data-selector={cssSelector}
            className={className}
            style={{
                padding: "0px 8px"
            }}
        >
            {html ? (
                <div
                    dangerouslySetInnerHTML={{
                        __html: docXSS.process(html)
                    }}
                />
            ) : (
                <LinearProgress color={"secondary"} />
            )}
        </div>
    );
};

InlineDocContainer.propTypes = {
    // The path from the root of the documentation root. It's the relative path
    path: PropTypes.string.isRequired,
    // retrieves a specific portion of the html
    cssSelector: PropTypes.string,
    // base url
    root: PropTypes.string,
    preformatOutput: PropTypes.func,
    className: PropTypes.string
};

export default withTheme(InlineDocContainer);
