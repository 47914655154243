import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { StriimButton, StriimTypography } from "@striim/striim-ui";
import { SegmentationChip } from ".";
import securityService from "core/services/securityService/securityService";
import Tracker from "../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../core/services/tracker/constants";

const useStyles = makeStyles(({ spacing }) => ({
    wrapper: {
        marginTop: spacing(5),
        "& > .MuiGrid-item": {
            padding: spacing(1)
        }
    },
    leftArea: {
        paddingRight: spacing(2),
        "& h1": {
            marginBottom: spacing(5)
        },
        "& p": { marginBottom: spacing(3) }
    }
}));

const FeatureNotAvailableHeader = ({ title, description, variant, imgComponent }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.wrapper} alignItems="flex-start">
            <Grid item xs={7} className={classes.leftArea}>
                <StriimTypography variant="h1">
                    {title} <SegmentationChip variant={variant} />
                </StriimTypography>
                <StriimTypography variant="body" paragraph>
                    {description}
                </StriimTypography>
                <StriimButton
                    variant="primary"
                    component="a"
                    href={securityService.addonNotAvailableLink}
                    onClick={() => {
                        Tracker.getInstance().track(TRACKER_STRINGS.schema.learnMore, {
                            event: TRACKER_STRINGS.eventClicked.learnMore
                        });
                        return true;
                    }}
                >
                    Learn More
                </StriimButton>
            </Grid>
            <Grid item xs={5} container alignItems="center">
                <Box width={"100%"}>
                    {imgComponent}
                </Box>
            </Grid>
        </Grid>
    );
};

export const featureNoAvailableSize = {
    width: 360,
    height: 240
}

export default FeatureNotAvailableHeader;
