import _ from "underscore";
import BaseControl from "./../base-control";
import Select from "./../select/select";
import Type from "./../type/type";
import FieldDataSource from "./../select/field-datasource";
import template from "./type-and-field.html";

var View = BaseControl.View.extend({
    template: _.template(template),
    regions: {
        typeRegion: ".type",
        typePreviewRegion: ".type-preview",
        fieldRegion: ".field",
    },
    className: "type-and-field",
    initialize: function () {
        var _this = this;
        this.typeSelector = Type.extend({
            collapsed: true,
        }).create();

        var dataSource = new FieldDataSource();
        this.fieldSelector = Select(dataSource).create();

        this.listenTo(this.typeSelector.model, "change:value", function (viewModel) {
            dataSource = new FieldDataSource(viewModel.value);
            _this.fieldSelector.setDataSource(dataSource).then(function() {
                var value = _this.getValue();
                if (value) {
                    _this.fieldSelector.setValue(value.keyField);
                }
                _this.setValueFromView();
            });
        });

        this.listenTo(this.fieldSelector.model, "change:value", function () {
            _this.setValueFromView();
        });
    },
    getViewValue: function () {
        var selectedTypeId = this.typeSelector.getValue();
        if (!selectedTypeId) {
            return null;
        }

        var type = this.typeSelector.getType();
        var field = this.fieldSelector.getValue();
        return {
            id: (type && type.uuid) ? type.uuid : this.id,
            typename: selectedTypeId,
            keyField: field,
        };
    },
    setViewEnabled: function () {
        this.typeSelector.setEnabled(this.getEnabled());
        this.fieldSelector.setEnabled(this.getEnabled());
    },
    setViewValue: function () {
        var value = this.getValue();
        if (!value) {
            return;
        }

        this.id = value.id;
        this.typeSelector.setValue(value.typename);
        this.fieldSelector.setValue(value.keyField);
    },
    onRender: function () {
        this.typeRegion.show(this.typeSelector);
        this.fieldRegion.show(this.fieldSelector);
        this.setViewValue();
    },
});

export default _.extend({}, BaseControl, {
    View: View,
});
