import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StriimTypography, StriimModal } from "@striim/striim-ui";

const useStyles = makeStyles(() => ({
    heightAuto: {
        height: "auto"
    }
}));

const CancelEditModal = ({ title, dialogVisible, setDialogVisible, onConfirm }) => {
    const classes = useStyles();
    return (
        <StriimModal
            size="small"
            isVisible={dialogVisible}
            onConfirm={onConfirm}
            onCancel={setDialogVisible}
            variant="error"
            cancelContent={"Continue Editing"}
            confirmContent={"Discard and leave"}
            titleContent={
                <StriimTypography variant="h2" color="primary.700">
                    {title}
                </StriimTypography>
            }
            dialogProps={{
                classes: { paper: classes.heightAuto }
            }}
        >
            <StriimTypography variant="body4">
                You will lose all the information you have entered so far if you leave without saving your changes.
            </StriimTypography>
        </StriimModal>
    );
};

export default CancelEditModal;
