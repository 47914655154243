import $ from "jquery";
import _ from "underscore";
import numeral from "numeral";

var utils = {};

utils.DEFAULT_UNIT = "MICROSECOND";

/**
 * @enum {string}
 *
 * All available object types
 *
 * */
utils.ENTITIES = {
    STREAM: "STREAM",
    SOURCE: "SOURCE",
    CACHE: "CACHE",
    WINDOW: "WINDOW",
    CQ: "CQ",
    TARGET: "TARGET",
    WACTIONSTORE: "WACTIONSTORE",
    TYPE: "TYPE",
    TEMPLATE: "TEMPLATE",
    APPLICATION: "APPLICATION",
    NAMESPACE: "NAMESPACE",
    EXTERNALCACHE: "EXTERNALCACHE",
    EXTERNALSOURCE: "EXTERNALSOURCE",
    EVENT_TABLE: "EVENT_TABLE",

    /** Open Processor */
    OPENPROCESSOR: "OPENPROCESSOR",
    ROUTER: "ROUTER",
    SENTINEL: "SENTINEL"
};

utils.NATIVE_TYPES = {
    String: {
        name: "String",
        classname: "java.lang.String"
    },
    StringArray: {
        name: "String[]",
        classname: "[Ljava.lang.String"
    }, // java.lang.String[]
    Byte: {
        name: "Byte",
        classname: "java.lang.Byte"
    },
    ByteArray: {
        name: "Byte[]",
        classname: "[B"
    }, // byte[]
    Short: {
        name: "Short",
        classname: "java.lang.Short"
    },
    Integer: {
        name: "Integer",
        classname: "java.lang.Integer"
    },
    int: {
        name: "int (primitive)",
        classname: "int"
    },
    Long: {
        name: "Long",
        classname: "java.lang.Long"
    },
    Float: {
        name: "Float",
        classname: "java.lang.Float"
    },
    Double: {
        name: "Double",
        classname: "java.lang.Double"
    },
    double: {
        name: "double (primitive)",
        classname: "double"
    },
    Boolean: {
        name: "Boolean",
        classname: "java.lang.Boolean"
    },
    ObjectArray: {
        name: "Object[]",
        classname: "[Ljava.lang.Object"
    }, // java.lang.Object[]
    Date: {
        name: "Date",
        classname: "java.util.Date"
    },
    HashMap: {
        name: "HashMap",
        classname: "java.util.HashMap"
    },
    DateTime: {
        name: "DateTime",
        classname: "org.joda.time.DateTime"
    }
};

utils.parse_url = function(segment_num) {
    // 1-indexed
    var hash = window.location.hash.replace("#", "");
    if (hash.length == 0) return false;
    var segments = hash.split("/");
    if (!segment_num) return segments;
    else {
        if (segment_num == "application" || segment_num == "ns") {
            var name = segments[0];
            if (!name) return false;
            var name_segments = name.split(".");
            if (segment_num == "application") {
                if (name_segments.length == 1) return name_segments[0];
                return name_segments[1];
            }
            if (segment_num == "ns") return name_segments[0];
        } else if (segment_num == "flow" || segment_num == "view") {
            return segments[1] || false;
        } else if (segment_num == "subview") {
            return segments[2] || false;
        } else if (segment_num == "flow-mode") {
            var loc = window.location.href;
            if (loc.indexOf("#") != -1) loc = loc.substring(0, loc.indexOf("#"));
            if (loc.slice(-1) == "/") loc = loc.slice(0, loc.length - 1);
            var segments = loc.split("/");
            var page_with_string =
                segments.indexOf("flow") != -1 ? segments[segments.indexOf("flow") + 1] : segments[segments.length - 1];

            return page_with_string == "edit" ? "edit" : "view";
        } else if (segment_num > segments.length) return false;
        else return segments[segment_num];
    }
};

// Temporary: REST-style param extraction
utils.get_qs_parameter = function getParameter(qs, name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(qs);
    return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

utils.make_uuid = function(uuidstring) {
    if (!uuidstring) return null;
    return {
        uuidstring: uuidstring
    };
};

// Get object property, case-insensitively
utils.get_prop = function(object, prop) {
    if (!_(object).isObject()) return false;
    if (_(object).has(prop)) return object[prop];
    var prop_lc = prop.toLowerCase();
    for (var ownprop in object) {
        if (ownprop.toLowerCase() == prop_lc) return object[ownprop];
    }
    return false;
};

utils.get_bool_value = function(s) {
    s = String(s).toLowerCase();
    var truthy = ["yes", "true"];
    var falsy = ["no", "false"];
    if (_(truthy).contains(s)) return true;
    return false;
};

// Convert control characters to strings
utils.stringify = function(s) {
    if (!s) return "";
    if (_.isNumber(s)) return s;
    if (s == '"') return "\u0022";
    var new_s = JSON.stringify(s);
    if ((new_s[0] == '"' || new_s[0] == "'") && (new_s[new_s.length - 1] == '"' || new_s[new_s.length - 1] == "'"))
        new_s = new_s.slice(1, new_s.length - 1);
    return new_s;
};

// Convert control characters from strings to the characters
utils.unescape = function(s) {
    if (!s || !s.replace) return s;
    return s
        .replace(/\\r/g, "\r")
        .replace(/\\n/g, "\n")
        .replace(/\\t/g, "\t")
        .replace(/\\"/g, '"');
};

// Return the current time as an integer
// TODO: replace with _.now()
utils.timestamp = function() {
    var seconds = new Date().getTime() / 1000;
    return seconds.toFixed(0);
};

//escape special characters
utils.escape_chars = function(s) {
    return s.replace(/[{}()*?,.^]/g, "\\$&");
};

utils.htmlAttributeEscapeQuotes = function(text) {
    if (!text) {
        return "";
    }

    text = text.replace(/"/g, "&quot;");
    text = text.replace(/'/g, "&apos;");

    return text;
};

utils.bytesFormatter = function(bytes, precision) {
    if (isNaN(parseFloat(bytes)) || !isFinite(bytes) || typeof bytes === "undefined" || parseFloat(bytes) == 0)
        return 0;
    if (typeof precision === "undefined") precision = 2;
    var units = ["bytes", "KB", "MB", "GB", "TB", "PB"],
        number = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + " " + units[number];
};

utils.isDatePickerOpened = function() {
    return !$(".ui-datepicker")
        .datepicker("widget")
        .is(":visible");
};

utils.isElementInViewport = function(el, offsetTop = 0, offsetBottom = 0) {
    if (typeof jQuery === "function" && el instanceof $) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();
    if (rect.width === 0 || rect.height === 0) {
        return false;
    }

    return (
        rect.top >= offsetTop &&
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) - offsetBottom /*or $(window).height() */ &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
};

utils.clearIntervals = function() {
    var interval_id = setInterval(function() {}, 9999); // Get a reference to the last interval id
    for (var i = 1; i < interval_id; i++) {
        // Clear all other intervals lower than the 'fake' one
        clearInterval(i);
    }
};

/*utils.getStackTrace = function() {
    var stack;

    try {
        throw new Error("");
    } catch (error) {
        stack = error.stack || "";
    }

    return stack;
};*/

utils.getNamespace = function(str) {
    if (!str) throw new Error("Error getting namespace of object.");

    var chunks = str.split(".");
    if (chunks.length === 1) {
        return str;
    } else {
        return chunks[0];
    }
};

utils.getName = function(str) {
    if (!str) throw new Error("Error getting name of object.");
    var chunks = str.split(".");
    if (chunks.length === 1) {
        return str;
    } else if (chunks.length === 2) {
        return chunks[1];
    } else if (chunks.length === 3) {
        return chunks[2];
    }
};

utils.getType = function(str) {
    if (!str) throw new Error("Error getting name of object.");
    let chunks = str.split(".");
    if (chunks.length === 3) {
        return chunks[1];
    } else {
        return null;
    }
};

utils.getFQN = function(model) {
    return model.get("nsName") + "." + model.get("type") + "." + model.get("name");
};

// 1000 => 1k
utils.formatBigNumber = function(number) {
    if (!number) return "0";

    return numeral(number).format("0a");
};

utils.isVisible = function($element) {
    var win = $(window);

    var viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    var bounds = $element.offset();
    if (!bounds) {
        return false;
    }
    bounds.right = bounds.left + $element.outerWidth();
    bounds.bottom = bounds.top + $element.outerHeight();

    return !(
        viewport.right < bounds.left ||
        viewport.left > bounds.right ||
        viewport.bottom < bounds.top ||
        viewport.top > bounds.bottom
    );
};

utils.capitalize = function(string) {
    return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
};

utils.group = function(object, group_if) {
    var new_object = $.extend(true, {}, object);
    _.each(object, function(value, key) {
        var groupname = group_if.apply(this, [value, key]);
        if (!groupname) return false;
        if (!new_object[groupname]) new_object[groupname] = {};
        new_object[groupname][key] = value;
        delete new_object[key];
    });
    return new_object;
};

export default utils;
