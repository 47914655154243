import React, { SetStateAction, useEffect, useState } from "react";
import { Divider, Grid, SvgIcon } from "@mui/material";

import SentinelAppsDropdown from "./sentinel-dropdown/sentinel-apps-dropdown";

import { StriimTypography, StriimTooltip, StriimButton, StriimButtonGroupSimple } from "@striim/striim-ui";
import { ArrowBack, InfoCircle, LastRefresh, LivePhoto } from "../../../../../generic/icon/customIcons";
import { styles } from "../../../sentinel-live-monitor/components/panel-header-controls/panel-header-controls.styles";
import {
    getStartAndEndTimestamps,
    ImportanceLevelIconPaths,
    ImportanceLevels
} from "../../../sentinel-live-monitor/utils";
import { RunningAppsWithSentinelsProps, TimeFormat } from "../service/sentinel-monitor.types";
import { formatTimestampRanges } from "../../../sentinel-live-monitor/components/timeline-view-chart/utils";
// import { RunningAppsWithSentinelsProps } from "../service/sentinel-monitor.types";
import GlobalReport from "../../../reports/sentinel-reports/global-report/sentinel-global-report";
import Printable from "../../../reports/printable";
import { fetchReportData } from "../../../reports/components/report-tables/report-tables.helper";
import WithTooltip from "../../../../../generic/tooltip/tooltip";
import { DownloadButton } from "../../../sentinel-live-monitor/components/panel-header-controls/panel-header-controls";
import { downloadSentinelCSVReport } from "../../../reports/reports.helper";
import metaStoreService from "../../../../../../core/services/metaStoreService/meta-store-service";
import { TABS } from "./sentinel-monitor-content";

type SDI = { name: string; importanceLevel: ImportanceLevels };

interface SentinelMonitorHeaderProps {
    //RunningAppDetails: RunningAppsWithSentinelsProps;
    selectedSDI: SDI | null;
    setSelectedSDI: React.Dispatch<SetStateAction<SDI | null>>;
    currentTabIndex: number;
    setCurrentTabIndex: React.Dispatch<SetStateAction<number>>;
    appsInfo: RunningAppsWithSentinelsProps;
    selectedApps: string[];
    setSelectedApps: React.Dispatch<SetStateAction<string[]>>;
    chartType: string;
    setChartType: React.Dispatch<SetStateAction<string>>;
    tabs: string[];
    selectedDateRange: { from: number; to: number } | null;
    setSelectedDateRange: React.Dispatch<SetStateAction<{ from: number; to: number } | null>>;
    selectedRange: { from: number; to: number };
    onBackClick: Function;
    timeFormat: TimeFormat;
    appSentinelMapping: Array<any>;
}

const ReportDownloadButton = ({ selectedApps, selectedRange, timeFormat, isOneHourTimeFrame, selectedDateRange }) => {
    if (!selectedApps?.length && !selectedRange) return null;
    const { fromTime, toTime } = getStartAndEndTimestamps(isOneHourTimeFrame, selectedDateRange);
    const timeRange = { from: fromTime, to: toTime };
    return (
        <Grid>
            <Printable
                name={`Sentinel_Global_Report`}
                component={GlobalReport}
                fetchReportData={async () => {
                    await downloadSentinelCSVReport(
                        selectedApps,
                        metaStoreService.entities.APPLICATION,
                        fromTime,
                        toTime
                    );
                    return fetchReportData(selectedApps, selectedRange, timeFormat, fromTime, toTime);
                }}
                buttonComponent={<DownloadButton />}
                additionalProps={{
                    selectedApps,
                    selectedRange,
                    isOneHourTimeFrame,
                    timeRange
                }}
            />
        </Grid>
    );
};

const SentinelMonitorHeader = ({
    selectedSDI,
    setSelectedSDI,
    currentTabIndex,
    setCurrentTabIndex,
    appsInfo,
    selectedApps,
    setSelectedApps,
    chartType,
    setChartType,
    tabs,
    selectedDateRange,
    setSelectedDateRange,
    selectedRange,
    onBackClick,
    timeFormat,
    appSentinelMapping
}: SentinelMonitorHeaderProps) => {
    const handleTabChange = value => setChartType(value);

    if (!appsInfo) return;
    return (
        <Grid container justifyContent={"flex-start"} gap={"12px"} pr={3}>
            {!!selectedSDI && (
                <>
                    <StriimButton
                        variant="text"
                        startIcon={
                            <SvgIcon sx={styles.backIcon}>
                                <ArrowBack />
                            </SvgIcon>
                        }
                        sx={styles.iconButton}
                        onClick={onBackClick}
                    />
                    <Grid display={"flex"} alignItems={"center"} gap={0.5} mr={1.5}>
                        <img
                            src={ImportanceLevelIconPaths[selectedSDI.importanceLevel]}
                            alt={`${selectedSDI.importanceLevel} Importance`}
                            width={"20px"}
                            height={"20px"}
                        />
                        <StriimTypography variant="h3" color="primary.700">
                            {selectedSDI.name} Activity for
                        </StriimTypography>
                    </Grid>
                </>
            )}
            <Grid container gap={2} width={"auto"}>
                <SentinelAppsDropdown
                    apps={appsInfo.runningApps}
                    sentinelsCount={appsInfo.sentinels}
                    selectedApps={selectedApps}
                    setSelectedApps={setSelectedApps}
                    appSentinelMapping={appSentinelMapping}
                />
                {WithTooltip(
                    <SvgIcon sx={styles.iconInfo}>
                        <InfoCircle />
                    </SvgIcon>,
                    "Sentinel uses AI engines that may sometimes misclassify the information."
                )}
            </Grid>
            <Grid
                item
                display={"flex"}
                width={"auto"}
                justifyContent={"space-between"}
                ml={"auto"}
                gap={2}
                alignItems={"center"}
            >
                <Grid item display={"flex"} alignItems={"center"} gap={0.5}>
                    {!selectedDateRange || (selectedDateRange && chartType !== tabs[0]) ? (
                        <StriimTooltip title={"Live, every min"}>
                            <SvgIcon sx={{ width: 16, height: 16 }}>
                                <LivePhoto />
                            </SvgIcon>
                        </StriimTooltip>
                    ) : null}

                    <StriimTypography variant="caption3" color="greyscale.700" fontFamily="Inter">
                        {chartType === tabs[0]
                            ? !!selectedDateRange
                                ? formatTimestampRanges(selectedDateRange.from, selectedDateRange.to)
                                : "Last 1 Hour"
                            : "Last 24 Hours"}
                        {!selectedDateRange ? " (Live)" : null}
                    </StriimTypography>
                    <Grid item display={"flex"} alignItems={"center"} gap={0.5}>
                        , <LastRefresh style={{ fill: "none" }} />
                        <StriimTypography variant="caption3" color="greyscale.700" fontFamily="Inter">
                            every min
                        </StriimTypography>
                    </Grid>
                </Grid>

                <Grid item display={"flex"} alignItems={"center"}>
                    <StriimButtonGroupSimple handleChange={handleTabChange} tabs={tabs} selectedTab={chartType} />
                </Grid>

                <>
                    <Divider orientation="vertical" sx={{ borderColor: "greyscale.200", height: 24 }} />
                    <Grid item display={"flex"}>
                        <ReportDownloadButton
                            selectedApps={selectedApps ?? appsInfo.runningApps}
                            selectedRange={selectedRange}
                            timeFormat={timeFormat}
                            isOneHourTimeFrame={chartType === TABS[0]}
                            selectedDateRange={selectedDateRange}
                        />
                    </Grid>
                </>
            </Grid>
        </Grid>
    );
};

export default SentinelMonitorHeader;
