import $ from "jquery";
import api from "core/api/api";
import Backbone from "backbone";
import _ from "underscore";
import growl from "app/components/common/growl";
import AppComponentsDataSource from "app/components/common/editor/control/select/app-components-data-source";
import template from "./monitorReport.html";
import predefinedTimeOptions from "app/components/monitor/eventsLog/predefinedTimeOptions";
import DateRange from "app/components/common/editor/control/date-range-picker/custom-date-range";
import UIControl from "app/components/common/editor/control/ui-control";
import fileSaver from "file-saver";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import reportParser from "./reportParser";
import { formatTableValue, getHumanReadableString } from "../tables/tableFormatters";
import BaseTable from "./../tables/basetable";
import NoDataTemplate from "../templates/noDataTemplate";
import { initMaterialDesignTabs } from "../../../../../../core/utils/material-design/tabs/material-design-style-tabs";

const rollupIntervalOptions = [
    {
        id: "15",
        text: "15 min"
    },
    {
        id: "30",
        text: "30 min"
    },
    {
        id: "60",
        text: "1 hour"
    }
];

let reportTabs = null;

export default Backbone.Marionette.LayoutView.extend({
    template: _.template(template),

    regions: {
        dateRangeRegion: ".uiDateRange",
        rollupIntervalRegion: ".uiRollupInterval",
        componentRegion: ".uiComponent",
        graphsRegion: ".visualization-result .graphs"
    },

    ui: {
        runButton: ".uiRunButton",
        downloadButton: ".uiDownloadButton",
        dataResult: ".data-result",
        noResultContainer: ".data-result .no-result",
        contentResult: ".data-result .content-result",
        jsonResult: ".data-result .json",
        monitorReportTable: ".monitorReportTable",
        showEmptyTableValues: "#showEmptyTableValues"
    },

    events: {
        "click @ui.runButton": "runReport",
        "click @ui.downloadButton": "downloadReport",
        "click @ui.showEmptyTableValues": "showEmptyTableValues"
    },

    onRender: function() {
        this._initTabs();

        this.dateRange = DateRange(predefinedTimeOptions).create();
        this.dateRangeRegion.show(this.dateRange);

        // setup rollup interval select
        this.rollupInterval = UIControl.Select(rollupIntervalOptions, {
            allowClear: false,
            hideSearch: true,
            width: "104px"
        }).create();
        this.rollupInterval.$el.addClass("interval-select");
        this.rollupIntervalRegion.show(this.rollupInterval);
        this.rollupInterval.setValue(rollupIntervalOptions[0].id);

        // setup component select
        let applicationId = metaObjectConverter.toTypedId(this.options.appName, metaStoreService.entities.APPLICATION);
        let dataSource = new AppComponentsDataSource(applicationId, [
            metaStoreService.entities.TYPE,
            metaStoreService.entities.APPLICATION
        ]);

        this.component = UIControl.Select(dataSource, {
            allowClear: false,
            dropdownAutoWidth: true,
            width: "212px"
        }).create();
        this.componentRegion.show(this.component);

        this.ui.downloadButton.tooltipster({
            position: "top",
            content: "First, press the Start button"
        });

        //this.ui.jsonResult.perfectScrollbar();
    },

    runReport: function() {
        if (this._running) {
            return;
        }

        if (!this.component.model.selectedModel) {
            alert("Please select a component before starting the report");
            return;
        }

        this._running = true;
        this._lastResult = null;

        let rollupInterval = this._getRollupInterval();
        let dateRange = this.dateRange.getViewValue();

        let startDate = dateRange.from.valueOf();
        let endDate = dateRange.to.valueOf();

        this.ui.runButton.addClass("disabled");
        this.ui.dataResult.addClass("hidden-element");
        this.ui.noResultContainer.addClass("hidden-element");
        this.ui.contentResult.addClass("hidden-element");

        function parseResult(data) {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    return data[key];
                }
            }
            return null;
        }

        let componentId = this.component.model.selectedModel.id;
        metaStoreService.findById(componentId).then(
            function(component) {
                let componentUUID = component.uuid;
                api.getWactionStatistics(startDate, endDate, rollupInterval, componentUUID)
                    .then(
                        function(result) {
                            this.ui.dataResult.removeClass("hidden-element");

                            if (this.ui.downloadButton.hasClass("tooltipstered")) {
                                this.ui.downloadButton.tooltipster("destroy");
                            }

                            if (_.isEmpty(result)) {
                                this._lastResult = null;
                                this.ui.noResultContainer.removeClass("hidden-element");
                                this.ui.noResultContainer.html("No data");
                            } else {
                                this._setActiveTab("visualization");
                                this.ui.contentResult.removeClass("hidden-element");
                                this.ui.downloadButton.removeClass("disabled");

                                this._lastResult = parseResult(result);
                                this.ui.jsonResult.html(JSON.stringify(this._lastResult, null, 4));
                                this._renderTable();
                                this._renderGraphs();
                            }

                            if (reportTabs) reportTabs.destroy();
                            if (!this.tabsRemoveCallback) {
                                this.tabsRemoveCallback = initMaterialDesignTabs(
                                    document.querySelector("#monitor-report-result")
                                );
                            }
                        }.bind(this)
                    )
                    .fail(
                        function(error) {
                            this.ui.downloadButton.addClass("disabled");
                            growl.error(error.message);
                        }.bind(this)
                    )
                    .always(
                        function() {
                            this._running = false;
                            this.ui.runButton.removeClass("disabled");
                        }.bind(this)
                    );
            }.bind(this)
        );
    },

    destroy() {
        Backbone.Marionette.LayoutView.prototype.destroy.apply(this, arguments);
        if (this.tabsRemoveCallback) {
            this.tabsRemoveCallback();
        }
    },

    _getRollupInterval: function() {
        // convert minutes to milliseconds
        let value = parseInt(this.rollupInterval.getValue());
        return value * 60 * 1000;
    },

    downloadReport: function() {
        let showEmptyRows = this.ui.showEmptyTableValues.is(":checked");
        let report = reportParser(this._lastResult, showEmptyRows);
        if (!report) {
            return;
        }

        let reportStr = [];
        reportStr.push(
            report.columns
                .map(c => {
                    if (c === "time-serie") {
                        return "Time series";
                    }
                    return getHumanReadableString(c);
                })
                .join(";")
        );

        function clearTableValue(tableValue) {
            tableValue = tableValue || "";

            if (tableValue.replace) {
                tableValue = tableValue.replace(/;/g, ",");
                tableValue = tableValue.replace(/\r/g, "");
                tableValue = tableValue.replace(/\n/g, "");
            }
            return tableValue;
        }

        report.data.forEach(row => {
            let rowStr = [];
            report.columns.forEach(c => {
                rowStr.push(clearTableValue(formatTableValue(row[c])));
            });
            reportStr.push(rowStr.join(";"));
        });

        let content = reportStr.join("\r\n");

        const filename = "Monitor Report for " + this.options.appName + ".csv";
        let blob = new Blob([content], { type: "text/plain;charset=utf-8" });
        fileSaver.saveAs(blob, filename);
    },

    _renderTable: function() {
        let showEmptyRows = this.ui.showEmptyTableValues.is(":checked");
        if (Object.keys(this._lastResult["time-series-data"]).length === 0) {
            this.ui.showEmptyTableValues.parent().hide();
        } else {
            this.ui.showEmptyTableValues.parent().show();
        }
        let report = reportParser(this._lastResult, showEmptyRows);
        if (!report.data.length) {
            var placeholder = new NoDataTemplate({
                text: "No data available."
            });
            this.ui.monitorReportTable.html(placeholder.el);
            return;
        }
        if (!report) {
            return;
        }

        let data = report.data.map(r => {
            for (let key in r) {
                if (r.hasOwnProperty(key)) {
                    r[key] = formatTableValue(r[key]);
                }
            }
            return r;
        });

        if (this._istableInitialized) {
            $("#monitorReportTableEl").jqGrid("GridDestroy");
            this.ui.monitorReportTable.html("");
        }

        this._istableInitialized = true;
        let colModel = report.columns.map(c => {
            return {
                name: c,
                index: c,
                key: c === "time-serie",
                label: c === "time-serie" ? "Time series" : getHumanReadableString(c),
                width: 180,
                sortable: true
            };
        });
        let settings = {
            datatype: "local",
            headertitles: true,
            width: null,
            rowNum: -1, // important: default size is only 20
            height: 393,
            sortable: true,
            shrinkToFit: false,
            show_lines: true,
            show_headers: true,
            data: data,
            colModel: colModel
        };
        let el = this.ui.monitorReportTable
            .html("")
            .append('<table id="monitorReportTableEl" style="width:100%"></table>')
            .children()
            .first();
        el.jqGrid(settings);

        //this.ui.monitorReportTable.find(".ui-jqgrid-bdiv").perfectScrollbar();
    },

    _renderGraphs: function() {
        let result = _.clone(this._lastResult);

        // filter empty values
        let timeSerie = result["time-series-data"];
        let filteredSerie = {};
        for (let key in timeSerie) {
            if (timeSerie.hasOwnProperty(key)) {
                let array = timeSerie[key];
                if (array && _.isArray(array)) {
                    filteredSerie[key] = array.filter(el => {
                        return el && el.second;
                    });
                }
            }
        }

        let model = new Backbone.Model({
            allValues: {
                "time-series-data": filteredSerie
            }
        });
        this.graphs = new BaseTable.GraphsView({
            model: model
        });
        this.graphsRegion.show(this.graphs);
        setTimeout(
            function() {
                this.graphs.drawgraphs();
            }.bind(this),
            300
        );
    },

    showEmptyTableValues: function() {
        this._renderTable();
    },

    _initTabs: function() {
        this.$el.find(".tabs button.tab-button").click(
            function(e) {
                this._setActiveTab(e.target.dataset.tabName);
            }.bind(this)
        );
    },

    _setActiveTab: function(tabName) {
        this.ui.contentResult.find(".tab-content").addClass("hidden-element");
        this.ui.contentResult.find(".tabs button.tab-button").removeClass("active");

        let tab = this.ui.contentResult.find("." + tabName + "-result");
        let tabLink = this.ui.contentResult.find('[data-tab-name="' + tabName + '"]');
        tabLink.addClass("active");
        tab.removeClass("hidden-element");

        //this.ui.jsonResult.perfectScrollbar("update");
        //this.ui.monitorReportTable.find(".ui-jqgrid-bdiv").perfectScrollbar("update");
    }
});
