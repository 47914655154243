import React from "react";
import PropTypes from "prop-types";

import { convertNameToHumanRedable } from "app/components/common/editor/control-resource-resolver";

import useStyles from "./add-on-features.styles";
import AddonTile from "./addon-tile";
import { SegmentationFeaturesStates, addonsSorter } from "./add-on-features-utils";
import {StriimTypography} from "../StriimTypographyCustom";
import Tracker from "../../../../../../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../../../../../core/services/tracker/constants";

const AddOnFeatures = ({ userPlanContext: { addonList }, handlers, planType }) => {
    const classes = useStyles();

    addonList.sort(addonsSorter);

    const handlerAction = (status, key) => () => {
        if (status === SegmentationFeaturesStates.ADDON_DISABLED) {
            handlers.enableFeature(key);
        }
        if (status === SegmentationFeaturesStates.ADDON_ENABLED) {
            handlers.disableFeature(key);
        }
    };

    return (
        <div>
            <StriimTypography variant="h3">Add-on features</StriimTypography>
            <div className={classes.descriptionTile}>
                <img className={classes.icon} src="webui/app/images/add-on-features.png" height={96} />
                <div className={classes.flex}>
                    <StriimTypography variant="body">
                        Scale to hundreds of nodes with zero downtime switchover and 24*7 support.— Add some benefits of
                        upgrading , could even be customized based on their add-ons.
                    </StriimTypography>
                    <StriimTypography variant={"textButton"} className={classes.learnMoreButton}>
                        <a
                            href="http://striim.com/pricing"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                Tracker.getInstance().track(TRACKER_STRINGS.schema.learnMore, { event: TRACKER_STRINGS.event.learnMore });
                                return true;
                            }}
                        >
                            Learn more
                        </a>
                    </StriimTypography>
                </div>
            </div>
            <div className={classes.addonsWrapper}>
                {addonList?.map(addon => (
                    <AddonTile
                        key={addon.item?.addonKey}
                        addonKey={addon.item?.addonKey}
                        status={addon.item?.status}
                        added={addon.item?.added}
                        planType={planType}
                        price={`${addon.rate?.accelerator} ${convertNameToHumanRedable(addon.rate?.unit)}`}
                        onClick={handlerAction(addon.item?.status, addon.item?.addonKey)}
                    />
                ))}
            </div>
        </div>
    );
};

export const typeAddonItem = {
    item: {
        status: PropTypes.string.isRequired,
        addonKey: PropTypes.string.isRequired,
        added: PropTypes.string
    },
    rate: {
        featureName: PropTypes.string,
        itemId: PropTypes.number,
        rate: PropTypes.number,
        unit: PropTypes.string
    }
};

AddOnFeatures.propTypes = {
    addOnList: PropTypes.arrayOf(PropTypes.shape(typeAddonItem))
};

export default AddOnFeatures;
