import React from "react";
import { StriimButton, StriimTooltip } from "@striim/striim-ui";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const ActionBtnsContainer = ({ handleConfigureClick, alertName, handleToggleEnableClick, enabled, isSmartAlert }) => {
    return (
        <Grid>
            <StriimTooltip
                title={isSmartAlert ? `Please go to Alert Manager to ${enabled ? "disable" : "enable"} it` : ""}
                placement="top"
                arrow
            >
                <span>
                    <StriimButton
                        data-test-id={alertName + "-modal-toggle-btn"}
                        onClick={handleToggleEnableClick}
                        variant="primaryText"
                        disabled={isSmartAlert}
                    >
                        {enabled ? "Disable" : "Enable"}
                    </StriimButton>
                </span>
            </StriimTooltip>
            <StriimButton
                data-test-id={alertName + "-modal-configure-btn"}
                onClick={handleConfigureClick}
                variant="primaryText"
            >
                Configure
            </StriimButton>
        </Grid>
    );
};

ActionBtnsContainer.propTypes = {
    alertName: PropTypes.string.isRequired,
    isSmartAlert: PropTypes.bool.isRequired,
    handleConfigureClick: PropTypes.func.isRequired,
    handleToggleEnableClick: PropTypes.func.isRequired,
    enabled: PropTypes.bool.isRequired
};
export default ActionBtnsContainer;
