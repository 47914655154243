import React from "react";
import Tile from "./tile/tile";
import { Box, Divider } from "@mui/material";

const Dashboard = ({ data }) => {
    const getUniqueKey = (item, index) => {
        let key = "";
        if (item.title) {
            key += item.title;
        } else {
            // only buffer has no title
            return "bufferTile";
        }
        // Since last tiles are different
        if (index === data.length - 1) {
            key += "last";
        }
        return key;
    };

    return (
        <Box
            sx={{
                display: "flex",
                borderWidth: "1px",
                borderRadius: 2,
                borderStyle: "solid",
                borderColor: "greyscale.200",
                boxShadow: 0
            }}
        >
            {data.map((item, index) => (
                <React.Fragment key={getUniqueKey(item, index)}>
                    <Tile {...item} />
                    {index !== data.length - 1 && <Divider orientation="vertical" flexItem />}
                </React.Fragment>
            ))}
        </Box>
    );
};

export default Dashboard;
