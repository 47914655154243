import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import {StriimButton} from "@striim/striim-ui";
import { convertNameToHumanRedable } from "app/components/common/editor/control-resource-resolver";
import useStyles from "./current-plan-section.styles";
import {StriimTypography} from "../StriimTypographyCustom";

const CurrentPlanSection = ({planType, unitAmount, unit, onDetailOpen}) => {
    const classes = useStyles();

    return (
        <Grid container alignItems="center">
            <Grid item xs={6}>
                <StriimTypography variant="caption3">Your current plan</StriimTypography>
                <StriimTypography variant="h2">{planType}</StriimTypography>
            </Grid>
            <Grid item xs={6}>
                <Grid className={classes.details} container alignItems="center" justifyContent="flex-end">
                    <Grid item>
                        <StriimTypography variant="body4">
                            {convertNameToHumanRedable(unit)} used this month
                        </StriimTypography>
                    </Grid>
                    <Grid item>
                        <StriimTypography variant="body3">{unitAmount}</StriimTypography>
                    </Grid>
                    <Grid item>
                        <StriimButton variant="primaryText" className={classes.link} onClick={onDetailOpen}>
                            Details
                        </StriimButton>
                    </Grid>
                </Grid>
                {/* Hiding because not yet supported in backend
            <StriimButton onClick={onCancel} variant="secondaryText" className={classes.cancelButton}>
                Cancel plan
            </StriimButton> */}
            </Grid>
        </Grid>
    );
};

CurrentPlanSection.propTypes = {
    onCancel: PropTypes.func,
    onDetailOpen: PropTypes.func,
    planType: PropTypes.string,
    unitAmount: PropTypes.string
};

export default CurrentPlanSection;
