export const styles = {
    
    grid: {
        display: "flex",
        justifyContent: "space-between",
        padding: 1
    },
    heading: {
        paddingBottom: 2
    },
    div: {
        marginLeft: 10
    }
  
};