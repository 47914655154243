import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkIfUserLoggedIn, initializeUser } from "../../init";
import api from "../../core/api/api";
import PermissionsManager from "../../core/permissions-manager";
import App from "../../app";
import securityService from "../../core/services/securityService/securityService";
import { contextStore } from "../stores";
import _ from "underscore";
import { trimStart } from "lodash";
import statusManagement from "../status-management";

interface BackboneNavigateOptions {
    trigger: boolean;
}

function trimStartUrl(url) {
    url = trimStart(url, "/");
    url = trimStart(url, "#");
    url = trimStart(url, "/#");
    return url;
}

export const useNavigationSecurity = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const currentlySubscribingRef = useRef<boolean>();
    const alertQueueUUIDRef = useRef<boolean>();
    const latestAppStatusesRef = useRef<Record<string, unknown>>({});
    const [loading, setLoading] = useState<boolean>(true);

    const sessionEnded = () => {
        if (securityService.isSaaSEnvironment()) {
            App.navigate("#/ended", { trigger: true });
        } else {
            navigate(0);
        }
    };

    const getModuleFromUrl = () => {
        const url = trimStartUrl(window.location.hash);
        return url.split("/")[0];
    };

    useEffect(() => {
        const check = async () => {
            let isLoggedIn, userAuthenticated;
            if (App.user) {
                try {
                    isLoggedIn = await checkIfUserLoggedIn();
                } catch (error) {
                    sessionEnded();
                }
            } else {
                userAuthenticated = await initializeUser();
            }
            api.call("SETUPDATEMODE", [true]);

            if (isLoggedIn || userAuthenticated) {
                const permissions = await securityService.fetchPermissions();
                contextStore.backboneStore.setPermissionsChecked();
                const permissionsManager = new PermissionsManager();
                const module = getModuleFromUrl();
                const urlParams = window.location.hash.replace("#", "").split("/");
                //TODO: Refactor
                const permissionName = module !== "users" && permissionsManager.getPermissionName(module, urlParams);
                if (permissionName && !permissions[permissionName]) {
                    App.navigate("#/unauthorized", { trigger: true });
                    return;
                }
            } else {
                sessionEnded();
            }
        };

        check();
    }, [location]);

    useEffect(() => {
        setLoading(true);
        const needToResubscribe = applications => {
            let result = false;
            _.each(applications, function(statusObject) {
                if (
                    statusManagement.isApplicationRunning(statusObject.currentStatus) ||
                    statusManagement.isDeployedState(statusObject.currentStatus)
                ) {
                    if (latestAppStatusesRef.current[statusObject.id] !== statusObject.currentStatus) {
                        result = true;
                    }
                }
                latestAppStatusesRef.current[statusObject.id] = statusObject.currentStatus;
            });

            return result;
        };

        const resubscribe = () => {
            if (currentlySubscribingRef.current) {
                return;
            }
            currentlySubscribingRef.current = true;

            var subscribe = function() {
                // @ts-ignore: api is untyped
                api.subscribe("alertQueue").then(function(uuid) {
                    currentlySubscribingRef.current = false;
                    alertQueueUUIDRef.current = uuid;
                    setLoading(false);
                });
            };

            if (alertQueueUUIDRef.current) {
                api.unsubscribe("alertQueue", alertQueueUUIDRef.current)
                    // @ts-ignore: api is untyped
                    .then(subscribe)
                    .fail(() => {
                        currentlySubscribingRef.current = false;
                        subscribe();
                    });
            } else {
                subscribe();
            }
        };

        const callback = applications => {
            if (needToResubscribe(applications)) {
                resubscribe();
            }
        };

        resubscribe();

        App.vent.on("api:status_change", callback);
        App.navigate = (route: string, options: BackboneNavigateOptions) => {
            if (options?.trigger) {
                navigate(`/${trimStartUrl(route)}`);
            } else {
                window.history.replaceState(null, null, `/#/${trimStartUrl(route)}`);
            }
        };

        App.reloadPage = () => {
            // @ts-ignore
            navigate();
        };

        return () => {
            App.vent.off("api:status_change", callback);
        };
    }, []);
    return loading;
};
