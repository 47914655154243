import React, { useRef, useState, useEffect } from "react";
import FlowAlertEnabledInfoBox from "../flow-alert-enabled-info-box/flow-alert-enabled-info-box";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import modifyAlert from "../../modify-alert";
import _ from "underscore";
import { getTab } from "app/components/alertmanager/alert-utils";
import alertResources from "app/components/alertmanager/alert-resources.json";
import converter from "core/services/metaStoreService/metaobject-converter";
import growl from "app/components/common/growl";
import App from "app";
import utils from "core/utils";
import PropTypes from "prop-types";

function FlowAlertComponentAlertmanager({ componentId, componentType, componentTitle }) {
    const [alertsCollection, setAlertsCollection] = useState([]);
    const [alertsCount, setAlertsCount] = useState(0);
    const [enabledAlertsCount, setEnabledAlertsCount] = useState(0);
    const [formLoading, setformLoading] = useState(false);
    const tableRef = useRef();
    const filterAlertsForSpecificComponent = (alertCollection, componentId, componentType) => {
        let enabledCount = 0;
        let newarr = alertCollection.filter(alert => {
            if (
                alert["objectName"] === componentId ||
                (getTab(alert) === "smart_alerts" && alert["objectName"].includes(componentType))
            ) {
                if (alert["isEnabled"]) {
                    enabledCount++;
                }
                return true;
            }
            return false;
        });
        return [newarr, enabledCount];
    };
    useEffect(() => {
        metaStoreService.fetchCollection(metaStoreService.entities.SYSALERTRULE).then(alertsCollection => {
            if (alertsCollection) {
                let [temp, enabledCount] = filterAlertsForSpecificComponent(
                    alertsCollection.toJSON(),
                    componentId,
                    componentType
                );
                setAlertsCollection(temp);
                setAlertsCount(temp.length);
                setEnabledAlertsCount(enabledCount);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentId]);
    const toggleAlertStatus = alertData => {
        setformLoading(true);
        modifyAlert({
            ...alertData,
            isEnabled: !alertData.isEnabled
        })
            .then(alertData => {
                const newState = [...alertsCollection].map(alert =>
                    alert.id === alertData.id ? { ...alertData } : alert
                );
                setAlertsCollection(newState);
                if (alertData.isEnabled) {
                    setEnabledAlertsCount(prev => prev + 1);
                } else {
                    setEnabledAlertsCount(prev => prev - 1);
                }
                setformLoading(false);
                let enabledText = alertData.isEnabled ? "enabled" : "disabled";
                growl.success(`Alert ${enabledText} successfully`);
            })
            .catch(err => {
                setformLoading(false);
                growl.error(err);
            });
    };
    const addNewAlert = (alertData, conditionKey) =>
        new Promise((resolve, reject) => {
            setformLoading(true);

            //getting alertdata from Resource.JSON

            let type = converter.getType(componentId);
            if (!type) {
                type = componentId;
            }
            let resource = _.findWhere(alertResources[type], { label: conditionKey });

            //Alert message content
            let compiled_template = null;
            if (resource.message) {
                let template = _.template(resource.message);
                let objectFQN = componentId;
                let objectName = utils.getName(objectFQN);
                let objectNS = utils.getNamespace(objectFQN);
                let alertValue = resource.alertValue;
                compiled_template = template({
                    name: objectName,
                    nsName: objectNS,
                    alertValue: alertValue,
                    comparator: resource.comparator
                });
            }
            // eslint-disable-next-line no-unused-vars
            const { label, message, alertInterval, ...alertResource } = resource;
            alertData = {
                ...alertData,
                ...alertResource,
                objectName: componentId,
                nsName: App.user.defaultNamespace,
                id: "",
                type: "SYSALERTRULE",
                alertMessage: compiled_template
            };
            modifyAlert(alertData)
                .then(newAlertData => {
                    growl.success("Alert Saved.");
                    setAlertsCollection(prev => [newAlertData, ...prev]);
                    setEnabledAlertsCount(prev => prev + 1);
                    setAlertsCount(prev => prev + 1);
                    setformLoading(false);
                    resolve();
                    tableRef.current.scrollIntoView({});
                })
                .catch(err => {
                    setformLoading(false);
                    reject(err);
                });
        });
    return (
        <FlowAlertEnabledInfoBox
            {...{
                componentId,
                componentTitle,
                componentType,
                alertsCount,
                alertsCollection,
                toggleAlertStatus,
                addNewAlert,
                formLoading,
                enabledAlertsCount,
                tableRef
            }}
        />
    );
}

FlowAlertComponentAlertmanager.propTypes = {
    componentId: PropTypes.string.isRequired,
    componentTitle: PropTypes.string.isRequired,
    componentType: PropTypes.string.isRequired
};

export default FlowAlertComponentAlertmanager;
