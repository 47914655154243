import React, { useRef, useState } from "react";
import {
    StriimTypography,
    StriimForm,
    StriimRadioGroup,
    StriimControlLabel,
    StriimRadio,
    StriimInputField,
    StriimButton,
    StriimModal,
    StriimToast,
    StriimTooltip,
    ClearMemory
} from "@striim/striim-ui";
import * as Yup from "yup";
import { Box, Grid, InputAdornment } from "@mui/material";

import { ssoLabels } from "./sso.common";
import { styles } from "./sso-container.styles";
import api from "../../../../../../../../core/api/api";
import { getSSOProperties } from "../../users-page";
import FileSelectModalContent from "./file-select-modal-content";
import FolderIcon from "src/generic/icon/manage-striim/folder.svg";

const SsoAddConfiguration = ({ setAddSso, setSsoProperties, setEdit, edit, editValues }) => {
    const formRef = useRef();
    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState("");

    const values =
        edit && editValues
            ? { ...editValues }
            : {
                  idp: "Microsoft Entra",
                  applicationID: "",
                  certPath: "",
                  loginURL: ""
              };
    const validationSchema = Yup.object().shape({
        idp: Yup.string().required("An option must be selected"),
        applicationID: Yup.string().required("Required"),
        certPath: Yup.string()
            .required("Certificate Path is required")
            .test(
                "only-pem-file",
                "This file format is not supported. Supported format *.pem",
                value => value && value.endsWith(".pem")
            ),
        loginURL: Yup.string().required("Required")
    });

    const handleSubmit = () => {
        const { idp, certPath, applicationID, loginURL } = formRef.current.values;

        createSSO(idp, certPath, applicationID, loginURL, setSsoProperties);
    };
    const handleCancel = () => {
        setEdit(false);
        setAddSso(false);
    };
    const createSSO = async (idp, certPath, applicationID, loginURL, setSsoProperties) => {
        try {
            const response = await api.createAdminSSOProperties(idp, certPath, applicationID, loginURL);

            if (response) {
                StriimToast({
                    message: "Single Sign On created",
                    severity: "success"
                });
                getSSOProperties(setSsoProperties, handleCancel);
            }
        } catch (error) {
            console.error(error);
            StriimToast({
                message: error,
                severity: "error"
            });
        }
    };

    return (
        <StriimForm
            values={values}
            formRef={formRef}
            validationSchema={validationSchema}
            validateOnMount
            validateOnChange
            onSubmit={() => {
                handleSubmit();
            }}
        >
            {({ values, handleChange, setFieldValue, isValid }) => {
                const selectedSsoOption = ssoLabels.find(option => option.idp === values.idp);

                return (
                    <>
                        <Grid container direction={"column"} data-test-id="sso-form-container" gap={2}>
                            <Grid item sx={styles.addSSOHeader}>
                                <StriimTypography variant="h3" sx={styles.heading}>
                                    Add Single Sign On
                                </StriimTypography>
                            </Grid>

                            <Grid item xs={4} sx={styles.addSsoContent}>
                                <StriimTypography variant="caption3" sx={styles.addSsoCaption}>
                                    Identity Provider (IDP)
                                </StriimTypography>

                                <StriimRadioGroup
                                    required
                                    value={values.idp}
                                    onChange={async event => {
                                        await setFieldValue("idp", event.target.value);
                                        await setFieldValue("certPath", "");
                                        await setFieldValue("applicationID", "");
                                        await setFieldValue("loginURL", "");
                                        handleChange(event);
                                    }}
                                    name="idp"
                                    isFormElement
                                    id="sso-idp"
                                    sx={styles.addSsoRadioGroup}
                                >
                                    {ssoLabels.map((item, key) => (
                                        <StriimControlLabel
                                            key={key}
                                            value={item.idp}
                                            label={item.idp}
                                            isFormElement
                                            control={<StriimRadio checked={values.idp === item.idp} />}
                                            data-test-id={`idp-option-${item.idp}`}
                                        />
                                    ))}
                                </StriimRadioGroup>
                            </Grid>

                            <Grid item xs={4} sx={styles.addSsoContent}>
                                <Box sx={styles.addSsoContentBox}>
                                    <StriimInputField
                                        id="sso-form-app-id"
                                        xs={3}
                                        isFormElement
                                        name="applicationID"
                                        label={selectedSsoOption.applicationID}
                                        placeholder={selectedSsoOption.appIdPlaceholder}
                                    ></StriimInputField>
                                </Box>
                            </Grid>

                            <Grid item xs={4} sx={styles.addSsoContent}>
                                <Box sx={[styles.labelWrap, styles.addSsoContentBox]}>
                                    <StriimInputField
                                        id="sso-form-certificate-path"
                                        xs={3}
                                        isFormElement
                                        name="certPath"
                                        label={selectedSsoOption.certPathLabel}
                                        placeholder={selectedSsoOption.certPathPlaceholder}
                                        InputProps={{
                                            onClick: () => setShowModal(true),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FolderIcon style={{ fontSize: "20px", fill: "none" }} />
                                                </InputAdornment>
                                            )
                                        }}
                                        helperText={selectedSsoOption.certPathHelper}
                                    ></StriimInputField>
                                </Box>
                            </Grid>

                            <Grid item xs={4} sx={styles.addSsoContent}>
                                <Box sx={styles.addSsoContentBox}>
                                    <StriimInputField
                                        id="sso-form-url"
                                        xs={3}
                                        isFormElement
                                        name="loginURL"
                                        label={selectedSsoOption.loginURL}
                                        placeholder={selectedSsoOption.urlPlaceholder}
                                    ></StriimInputField>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={styles.addSsoButtonsSection}>
                            <StriimButton onClick={handleCancel} variant="primaryText">
                                Cancel
                            </StriimButton>
                            <StriimButton
                                onClick={handleSubmit}
                                variant="primary"
                                disabled={!isValid}
                                data-test-id="sso-form-save"
                            >
                                Save
                            </StriimButton>
                        </Grid>
                        {showModal && (
                            <StriimModal
                                size="medium"
                                autoHeight
                                isVisible={showModal}
                                footerBorder
                                footerContent={
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item sx={styles.selectedFileName}>
                                            {selectedFile && (
                                                <>
                                                    <StriimTypography variant="caption3" sx={styles.modalFooterText}>
                                                        Selected File{" "}
                                                    </StriimTypography>
                                                    <StriimTooltip title={selectedFile.name}>
                                                        <StriimTypography
                                                            variant="caption3"
                                                            color="secondary.500"
                                                            sx={styles.modalFooterText}
                                                        >
                                                            {selectedFile.name}
                                                        </StriimTypography>
                                                    </StriimTooltip>
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <StriimButton
                                                variant="text"
                                                onClick={() => {
                                                    setSelectedFile("");
                                                    ClearMemory();
                                                    setShowModal(false);
                                                }}
                                            >
                                                Cancel
                                            </StriimButton>
                                            <StriimButton
                                                disabled={!selectedFile}
                                                data-test-id={"confirm-select-file"}
                                                onClick={() => {
                                                    ClearMemory();
                                                    setShowModal(false);
                                                    setFieldValue(
                                                        "certPath",
                                                        selectedFile?.currentPath || selectedFile?.path
                                                    );
                                                }}
                                                variant="primary"
                                            >
                                                Done
                                            </StriimButton>
                                        </Grid>
                                    </Grid>
                                }
                            >
                                <FileSelectModalContent
                                    selectedFile={selectedFile}
                                    onCancel={() => setShowModal(false)}
                                    onSelected={file => {
                                        setSelectedFile(file);
                                    }}
                                    allowedExtensions={[".pem"]}
                                />
                            </StriimModal>
                        )}
                    </>
                );
            }}
        </StriimForm>
    );
};

export default SsoAddConfiguration;
