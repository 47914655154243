import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui";
import { useReactHeader } from "src/hooks/useReactHeader";

import { styles } from "./manage-striim-wrapper.styles";
import LoadingIndicator from "../../../../generic/loading-indicator";
import { ContentFrame } from "./connection-profiles";
import InlineDocPanel from "../../../../generic/inline-doc/inline-doc-panel";
import InlineDocContainer from "../../../../generic/inline-doc/inline-doc-container";


interface ManageStriimPageWrapperProps {
    loading?: boolean;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    showHelpGuide?: boolean;
    children: React.ReactNode;
    inlineHelpDetails?: object;
}

const ManageStriimPageWrapper = ({
    header,
    footer,
    children,
    inlineHelpDetails,
    loading = false,
    showHelpGuide = false
}: ManageStriimPageWrapperProps) => {
    useReactHeader({ title: "Manage Striim" });
    if (!inlineHelpDetails) inlineHelpDetails = {};
    const [date, setDate] = useState(null);
    useEffect(()=>{
      setDate(new Date().toString());

    }, [inlineHelpDetails])
    return (
        <div style={{ width: "-webkit-fill-available" }}>
            <Grid container sx={[styles.wrapper, footer && styles.wrapperWithFooter]}>
                {header ?? (
                    <StriimTypography sx={styles.header} variant="h1">
                        Manage Striim
                    </StriimTypography>
                )}
                {loading ? (
                    <Grid xs={12} item sx={styles.childContainer}>
                        <LoadingIndicator isGlobal={false} />
                    </Grid>
                ) : showHelpGuide ? (
                    <ContentFrame helpUrl="" content={<div>Help Guide</div>}>
                        <Grid xs={12} item sx={[styles.childContainer, showHelpGuide ? styles.withRightSidebar : {}]}>
                            {children}
                        </Grid>
                    </ContentFrame>
                ) : (
                    <Grid xs={12} item sx={styles.childContainer}>
                        <InlineDocPanel
                            data-date={date}
                            path={inlineHelpDetails.path}
                            inlineDocContent={
                                <InlineDocContainer
                                    path={inlineHelpDetails.path}
                                    className={inlineHelpDetails.className}
                                />
                            }
                        >
                           <Box sx={styles.contentContainer}>
                               {children}
                           </Box>
                        </InlineDocPanel>
                    </Grid>
                )}
            </Grid>
            {footer && <Grid container>{footer}</Grid>}
        </div>
    );
};

export default ManageStriimPageWrapper;
