import App from "app";
import tooltipyo from "app/components/common/tooltipyo/tooltipyo";
import Backbone from "backbone";
import _ from "underscore";
import NestedTypes from "backbone.nestedtypes";
import utils from "core/utils";
import filterModel from "core/filterModel";
import queryParameters from "core/services/queryService/queryParameters.json";
import ReportType from "../../monitor/overview/reportType/reportType";
import UIControl from "app/components/common/editor/control/ui-control";
import FieldFilter from "../filters/fieldFilter";
import template from "./viewModeControls.html";
import "lib/vendor/jquery.clearsearch";
import chartDataExportManager from "./chartDataExportManager";
import $ from "jquery";

var ViewModeControls = {};

ViewModeControls.Model = NestedTypes.Model.extend({
    defaults: {
        contentId: null,
        filteredQueryUUID: null,
        title: "Global Filters",
        visualizationModel: null,
        hasDrillDownFiltersApplied: false,
        canBeFiltered: false,
        filterAttributes: Array,
        searchQuery: "",
        report: {
            type: ReportType.Options.RealTime,
            selectedDate: null,
            selectedTime: null,
            startTime: null,
            endTime: null,
            value: null,
            title: ""
        }
    }
});

ViewModeControls.View = Backbone.Marionette.ItemView.extend({
    className: "component-header",
    template: _.template(template),
    ui: {
        searchButton: ".search-button",
        attributesButton: ".attributes-button",
        timeButton: ".time-button",
        dataDownloadButton: ".data-download-button"
    },
    events: {
        "click @ui.searchButton": "searchClick",
        "click @ui.attributesButton": "attributesClick",
        "click @ui.timeButton": "timeClick",
        "click @ui.dataDownloadButton": "downloadClick"
    },
    initialize: function() {
        this.getInitialParameters();

        if (this.model.visualizationModel) {
            this.model.set("filterAttributes", this.model.visualizationModel.content.query.filterAttributes);
            this.model.set("title", this.model.visualizationModel.content.visualizationType);
            this.model.set("filteredQueryUUID", this.model.visualizationModel.content.query.uuid);
        }

        this.listenTo(this.model, "field-filter:selected", function(selectedFilter) {
            filterModel.clearParameters(this.model.filteredQueryUUID, [queryParameters.Search, queryParameters.Time]);
            filterModel.setParameters(selectedFilter, this.model.filteredQueryUUID);
            tooltipyo.hide();
            this.getInitialParameters();

            this.evaluateIconsStatus();
        });

        this.listenTo(
            this.model,
            "change:report",
            function() {
                var obj = {};
                if (this.model.report.startTime && this.model.report.endTime) {
                    obj[queryParameters.Time] = {
                        from: this.model.report.startTime.valueOf().toString(),
                        to: this.model.report.endTime.valueOf().toString()
                    };
                } else {
                    obj[queryParameters.Time] = null;
                }

                filterModel.setParameters(obj, this.model.filteredQueryUUID);
                this.evaluateIconsStatus();
            }.bind(this)
        );

        this.listenTo(
            App.vent,
            "dashboard:filters-bar:clear-all-filters",
            function() {
                this.evaluateIconsStatus();
                this.evaluateVisStatus();
            }.bind(this)
        );
    },
    getInitialParameters: function() {
        this.initialParameters = filterModel.getSavedQueryParameters(this.model.filteredQueryUUID);
    },
    searchClick: function() {
        this.model.trigger("dashboard:visualizations:active-filters");
        this.getInitialParameters();

        this.text = UIControl.ChangeableTextField.Search().create();
        this.text.setValue(this.initialParameters[queryParameters.Search]);

        this.listenTo(
            this.text.model,
            "change:value",
            function() {
                var obj = {};
                var value = this.text.model.value;
                obj[queryParameters.Search] = value;
                filterModel.setParameters(obj, this.model.filteredQueryUUID);
                this.evaluateIconsStatus();
            }.bind(this)
        );

        this.text.render();

        this.$wrap = $("<div class='p-16px'></div>");
        this.$wrap.append(this.text.$el);

        tooltipyo.show(this.$wrap, this.$(".search-button"), "bottomRight", {
            customClass: "dashboard visualization search-box",
            hideCloseButton: true,
            onClosed: this.evaluateVisStatus.bind(this)
        });
        //this.text.$el.find("input").addClear();
        this.text.focus();
    },
    attributesClick: function() {
        this.model.trigger("dashboard:visualizations:active-filters");
        this.getInitialParameters();

        this.fieldFilterTooltipContent = new FieldFilter.View({
            model: this.model,
            initialParameters: this.initialParameters
        });

        this.fieldFilterTooltipContent.render();
        tooltipyo.show(this.fieldFilterTooltipContent.$el, this.$(".attributes-button"), "bottomRight", {
            customClass: "dashboard visualization field-filter",
            hideCloseButton: true,
            onBeforeClose: utils.isDatePickerOpened,
            onClosed: this.evaluateVisStatus.bind(this)
        });
        this.fieldFilterTooltipContent.focus();
    },
    timeClick: function() {
        this.model.trigger("dashboard:visualizations:active-filters");
        this.getInitialParameters();

        this.rangeSelectorTooltipContent = new ReportType.View({
            model: this.model,
            pickers: [ReportType.Options.RealTime, ReportType.Options.CustomRange]
        });

        this.rangeSelectorTooltipContent.render();
        tooltipyo.show(this.rangeSelectorTooltipContent.$el, this.$(".time-button"), "bottomRight", {
            customClass: "dashboard visualization time-range",
            hideCloseButton: true,
            onBeforeClose: utils.isDatePickerOpened,
            onClosed: this.evaluateVisStatus.bind(this)
        });

        this.listenTo(this.rangeSelectorTooltipContent, "timeRange:close", function() {
            tooltipyo.hide();
        });
    },
    downloadClick: function() {
        chartDataExportManager.downloadFile(
            this.model.visualizationModel.attributes.content.attributes.dataSource,
            this.model.visualizationModel.content.visualizationType
        );
    },
    evaluateVisStatus: function() {
        if (filterModel.isEmpty(this.model.filteredQueryUUID)) {
            this.model.trigger("dashboard:visualizations:clear-filters");
        }
    },
    evaluateIconsStatus: function() {
        this.getInitialParameters();

        if (this.initialParameters[queryParameters.Search]) {
            this.ui.searchButton.addClass("active");
        } else {
            this.ui.searchButton.removeClass("active");
        }

        if (this.initialParameters[queryParameters.Time]) {
            this.ui.timeButton.addClass("active");
        } else {
            this.ui.timeButton.removeClass("active");
        }

        var otherProperties = Object.getOwnPropertyNames(this.initialParameters).filter(function(name) {
            return name !== queryParameters.Time && name !== queryParameters.Search;
        });

        if (otherProperties.length > 0) {
            this.ui.attributesButton.addClass("active");
        } else {
            this.ui.attributesButton.removeClass("active");
        }
    },
    onRender: function() {
        if (this.model.hasDrillDownFiltersApplied) {
            this.$el.addClass("with-drilldown");
        }
        if (!this.model.get("canPoll")) {
            this.$el.find(".cant-filter").tooltipster({
                contentAsHTML: true,
                animation: "grow",
                delay: 500
            });
        }
    },
    onShow: function() {
        setTimeout(() => {
            this.$el.find(".button").tooltipster();
        }, 1000);
    }
});

export default ViewModeControls;
