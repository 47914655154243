import { useEffect } from "react";
import useNavBarStore from "src/stores/nav-bar/useNavBarStore";

export const useReactHeader = ({ title = "" }) => {
    const store = useNavBarStore();
    useEffect(() => {
        store.setPageTitle(title);
        document.title = title;
    }, [title, store]);
};
