import React, { useState } from "react";
import { StriimInputField, useStriimForm } from "@striim/striim-ui";

function Password(props) {
    const [isFieldDirty, setIsFieldDirty] = useState(false);
    const { errors, setFieldTouched } = useStriimForm();
    const {
        enabled,
        onChange,
        value,
        error,
        placeholder,
        errorText,
        label,
        required,
        previewPassword = true,
        InputProps,
        name,
        labelAddon,
        ...otherProps
    } = props;

    let passwordValue = value;
    let passwordPlaceholder = placeholder;
    if (typeof value === "object" && value.hasOwnProperty("encrypted")) {
        passwordPlaceholder = "*".repeat(value.length || 10);
        passwordValue = null;
    }
    const hasError = isFieldDirty && errors && errors[name];
    const helperText = errorText || (isFieldDirty && errors && errors[name]);

    return (
        <StriimInputField
            name={name}
            type="password"
            label={label}
            disabled={!enabled}
            onChange={value => {
                setFieldTouched(name, true);
                onChange(value);
            }}
            labelAddon={labelAddon}
            value={passwordValue}
            error={error || hasError}
            helperText={helperText}
            placeholder={passwordPlaceholder}
            previewPassword={previewPassword}
            InputProps={{ ...(InputProps && InputProps) }}
            required={required}
            onBlur={() => {
                setIsFieldDirty(true);
            }}
            {...otherProps}
        />
    );
}

export default Password;
