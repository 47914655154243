import React from "react";
import { StriimTheme } from "@striim/striim-ui";
import PropTypes from "prop-types";
import App from "app";

const FileBrowserDirectSelector = () => {
    return (
        <StriimTheme>
            <div
                id="filebutton"
                className="waves-effect btn primary nav_button"
                onClick={() => {
                    App.navigate("#/manage-striim/files", { trigger: true });
                }}
            >
                Browse
            </div>
        </StriimTheme>
    );
};
FileBrowserDirectSelector.propTypes = {
    onChange: PropTypes.func
};

export default FileBrowserDirectSelector;
