import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { segmentationVariants } from "../../../../../common/segmentation/segmentation.consts";
import FeatureNotAvailableHeader, {
    featureNoAvailableSize
} from "../../../../../common/segmentation/feature-not-available-header";
import { Grid } from "@material-ui/core";
import {
    FeaturesDivider,
    FeatureLeft,
    FeatureRight,
    featureSize
} from "../../../../../common/segmentation/features-description";
import {
    addonFeaturesKeys,
    addonFeaturesMessages
} from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import securityService from "core/services/securityService/securityService";
import { ProductTiers } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    wrapper: {
        overflow: "hidden",
        marginTop: spacing(12),
        [breakpoints.up("lg")]: {
            paddingLeft: 176,
            paddingRight: 176
        },
        [breakpoints.down("md")]: {
            paddingLeft: spacing(4),
            paddingRight: spacing(4)
        }
    }
}));

const DashboardsNotAvailable = () => {
    const classes = useStyles();

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                {securityService.currentSegmentationLicense === ProductTiers.STANDARD ? (
                    <FeatureNotAvailableHeader
                        title="Dashboards are an Enterprise add-on feature"
                        variant={segmentationVariants.enterprise}
                        description={addonFeaturesMessages[addonFeaturesKeys.DASHBOARDS]}
                        imgComponent={
                            <img src="app/images/segmentation/dashboard/Dashboard.png" style={featureNoAvailableSize} />
                        }
                    />
                ) : (
                    <FeatureNotAvailableHeader
                        title="Dashboards are an add-on feature"
                        variant={segmentationVariants.addOn}
                        description={addonFeaturesMessages[addonFeaturesKeys.DASHBOARDS]}
                        imgComponent={
                            <img src="app/images/segmentation/dashboard/Dashboard.png" style={featureNoAvailableSize} />
                        }
                    />
                )}
            </Grid>
            <FeaturesDivider />
            <FeatureLeft
                title={"Visualize your data in real-time"}
                description="Visualize your most critical metrics with a product that's already streaming your data in real-time"
                imgComponent={<img src="app/images/segmentation/dashboard/Filter.png" style={featureSize} />}
            />
            <FeatureRight
                title={"Query your data"}
                description="Query streaming data with SQL"
                imgComponent={<img src="app/images/segmentation/dashboard/Query.png" style={featureSize} />}
            />
            <FeaturesDivider />
            <FeatureLeft
                title={"Customize your visualizations"}
                description="Dozens of data visualization options optimized for real-time data"
                imgComponent={<img src="app/images/segmentation/dashboard/Visualization.png" style={featureSize} />}
            />
        </Grid>
    );
};

export default DashboardsNotAvailable;
