var isDatasetTooBig = function (data) {
    return data.xadded.length > 500;
};

export default function () {
    var disabled = true;
    var tooBigDataSet = false;
    var paused = true;
    var queryParamsChanged = false;

    this.disable = function () {
        disabled = true;
    };

    this.enable = function () {
        disabled = false;
    };

    this.isDisabled = function () {
        return disabled;
    };

    this.pause = function () {
        paused = true;
    };

    this.resume = function () {
        paused = false;
    };

    this.onDataReceived = function (data) {
        tooBigDataSet = isDatasetTooBig(data);
        if (queryParamsChanged) {
            paused = true;
            queryParamsChanged = false;
        }
    };

    this.onQueryCreated = function () {
        tooBigDataSet = false;
        if (paused) {
            paused = false;
            queryParamsChanged = true;
        }
    };

    this.shouldSkipQuery = function () {
        return tooBigDataSet || paused || disabled;
    };
}
