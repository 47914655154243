import { action, observable } from "mobx";
import dateLib from "../../../core/utils/date-utils";
import api from "../../../core/api/api";
import metaobjectConverter from "../../../core/services/metaStoreService/metaobject-converter";
import utils from "../../../core/utils";
import { getDatabaseType as getDBName } from "../wizards/services";
import { getDatabaseType } from "../../../app/components/appwizard/component-wizards/wizards/common/databaseTypes";
import metaStoreService from "../../../core/services/metaStoreService/meta-store-service";
import { getWorker } from "../apps/pages/app-list/components/automated-app-group-detail/services/download-reports.service";

export function formatDate(dateAndTime) {
    if (dateAndTime === "Fetching…" || dateAndTime === "") {
        return "Fetching…";
    }
    if (!dateAndTime || !dateLib(dateAndTime).isValid()) {
        return "-";
    }
    return dateLib(dateAndTime).format("MMM DD, YYYY h:mm a ");
}
function convertMinutesToHoursAndMinutes(minutes) {
    if (typeof minutes !== "number") {
        return "";
    }
    if (minutes < 60) {
        return minutes + "m";
    }
    var hours = Math.floor(minutes / 60);
    var remainingMinutes = minutes % 60;
    if (remainingMinutes > 0) {
        return hours + "h " + remainingMinutes + "m";
    } else {
        return hours + "h";
    }
}
class TableProgressStore {
    @observable tableProgress = {};
    @observable stats = null;
    @observable filteredOutput = null;
    @observable IOTimes = {};
    @observable appId = null;
    @observable appModel = null;
    @observable lastFetchedTime = Date.now();
    @observable ilInfo = {
        schemaCreationDetails: {
            status: "Pending"
        },
        tablesWrittenInfo: {
            totalTablesCount: 0,
            tablesWrittenCount: 0
        }
    };
    @observable isLoading = true;
    @observable elapsedTime = null;
    @observable sourceAdapters = [];
    @observable targetAdapters = [];

    constructor() {}

    @action reset() {
        this.tableProgress = {};
        this.IOTimes = {};
        this.appModel = null;
        this.stats = null;
        this.schemaCreationCompleted = false;
        this.lastFetchedTime = Date.now();
        this.isLoading = true;
        this.ilInfo = {
            schemaCreationDetails: {
                status: "Pending"
            },
            tablesWrittenInfo: {
                totalTablesCount: 0,
                tablesWrittenCount: 0
            }
        };
        this.elapsedTime = null;
        this.sourceAdapters = [];
        this.targetAdapters = [];
    }
    @action setAppId(appId) {
        this.appId = appId;
    }
    @action setAppModel(appModel) {
        this.appModel = appModel;
    }
    @action setElapsedTime(elapsedTime) {
        this.elapsedTime = convertMinutesToHoursAndMinutes(elapsedTime);
    }
    async getElapsedTime() {
        if (!this.appModel) return;
        try {
            const appUuid = this.appModel?.uuid;
            const elapsedTime = await api.getILCDCAppElapsedTime(appUuid);
            this.setElapsedTime(elapsedTime);
        } catch (error) {
            console.error("Error while fetching elapsed time ", error);
            this.setElapsedTime(null);
        }
    }

    async getType(name, type) {
        const fullName = metaobjectConverter.convertShortNameToFullName(name, type);
        const getCapitalizedDBName = adapter =>
            utils.capitalize(getDatabaseType(getDBName(adapter.get("adapter").properties, false)));

        return await metaStoreService.findById(fullName).then(adapter => getCapitalizedDBName(adapter));
    }
    async getAvailableAdapters() {
        const adapters = Object.entries(this?.stats ?? {});
        const sourceAdapters = [];
        const targetAdapters = [];
        for (let data of adapters) {
            const [id, adapter] = data;
            if (adapter?.type === "SOURCE") {
                const sourceType = await this.getType(adapter["full-name"], "SOURCE");
                sourceAdapters.push({
                    id: id,
                    label: adapter?.name,
                    value: adapter?.name,
                    fullName: adapter["full-name"],
                    type: sourceType
                });
            }
            if (adapter?.type === "TARGET") {
                const targetType = await this.getType(adapter["full-name"], "TARGET");
                targetAdapters.push({
                    id: id,
                    label: adapter?.name,
                    value: adapter?.name,
                    fullName: adapter["full-name"],
                    type: targetType
                });
            }
        }
        this.sourceAdapters = sourceAdapters;
        this.targetAdapters = targetAdapters;
        return {
            sourceAdapters,
            targetAdapters
        };
    }

    @action async setTableProgress(stats) {
        if (!stats) return;
        this.stats = stats;
        this.lastFetchedTime = Date.now();
        await this.getElapsedTime();
        const worker = await getWorker();
        const { tableProgress, IOTimes, ilInfo } = await worker.getTableProgress(
            stats,
            { ...this.tableProgress },
            { ...this.IOTimes }
        );
        this.tableProgress = tableProgress;
        this.ilInfo = ilInfo;
        this.IOTimes = IOTimes;
        this.isLoading = false;
    }
}

const tableProgressStore = new TableProgressStore();
export default tableProgressStore;
export { TableProgressStore };
