import React from "react";
import { Grid } from "@material-ui/core";
import BarChartRoundedIcon from "@material-ui/icons/BarChartRounded";
import { makeStyles } from "@material-ui/core/styles";
import {StriimTypography} from "../../../../user-plan/components/StriimTypographyCustom";
import clsx from "clsx";

const useStyles = makeStyles(({ palette, spacing }) => ({
    container: { height: "100%" },
    icon: {
        color: palette.text.secondary,
        opacity: 0.45
    },
    smallIcon: {
        height: 40,
        width: 40
    },
    largeIcon: {
        height: 56,
        width: 56
    },
    image: {
        marginTop: spacing(2),
        marginBottom: spacing(2),
        width: 125
    },
    caption: {
        color: "#515C61",
        marginBottom: spacing(1)
    },
    text: {
        whiteSpace: "pre-line",
        textAlign: "center"
    }
}));

const NoData = ({text, Icon, image, large, classNameIcon, typographyProps}) => {
    const classes = useStyles();

    return (
        <Grid container alignItems="center" justifyContent="space-around" className={classes.container}>
            <Grid container direction="column" alignItems="center">
                {Icon ? (
                    <Icon className={clsx(classes.icon, large ? classes.largeIcon : classes.smallIcon, classNameIcon)}/>
                ) : image ? (
                    <>
                        <img src="src/generic/icon/manage-striim/03-Apps-CreateApps.svg" className={classes.image}
                             alt="No data"/>
                        <StriimTypography
                            variant="h3"
                            {...typographyProps}
                            className={clsx(classes.caption, typographyProps?.className)}
                        >
                            No data to display
                        </StriimTypography>
                    </>
                ) : (
                    <BarChartRoundedIcon
                        className={`${classes.icon} ${large ? classes.largeIcon : classes.smallIcon}`}
                    />
                )}
                <StriimTypography {...typographyProps} className={clsx(classes.text, typographyProps?.className)}>
                    {text ? text : "No data"}
                </StriimTypography>
            </Grid>
        </Grid>
    );
};

export default NoData;
