import { StriimTypography } from "@striim/striim-ui-v2";
import dateLib from "core/utils/date-utils";
import React from "react";
import { dateFormat } from "../../utils";
import useStyles from "./striim-units-tile.styles";

export const StriimUnitisTileDate = ({ todayLabel, startTime, endTime, noMargin, type = "" }) => {
    const classes = useStyles();
    const hasBillingStartedToday = dateLib(startTime).isSame(dateLib(), "day");

    return (
        <StriimTypography variant="caption3" className={classes.subtitle} style={noMargin && { marginBottom: 0 }}>
            {type === "current-month" && hasBillingStartedToday ? (
                <>
                    Billing cycle started{" "}
                    <StriimTypography color="greyscale.900" variant="body3">
                        TODAY
                    </StriimTypography>
                </>
            ) : (
                <>
                    <StriimTypography color="greyscale.700" variant="body4">
                        From
                    </StriimTypography>{" "}
                    <StriimTypography color="greyscale.900" variant="body3">
                        {dateLib(startTime).format(dateFormat)}
                    </StriimTypography>{" "}
                    <StriimTypography color="greyscale.700" variant="body4">
                        till
                    </StriimTypography>{" "}
                    <StriimTypography color="greyscale.900" variant="body3">
                        {todayLabel ? "today" : dateLib(endTime).format(dateFormat)}
                    </StriimTypography>
                </>
            )}
        </StriimTypography>
    );
};
