import { Box, Grid } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import React from "react";
import { sxStyles } from "./target-form-components.styles";

interface ToggleFieldLabelProps {
    title?: string;
    label: string;
    subtitle: string;
}

const FieldLabel = ({ children }) => {
    return (
        <Box sx={sxStyles.fieldLabelContainer}>
            <StriimTypography variant="body3" sx={sxStyles.fieldLabel}>
                {children}
            </StriimTypography>
        </Box>
    );
};

const FieldValue = ({ subtitle = null, sx = null, children }) => {
    return (
        <StriimTypography variant="body4" sx={{ ...sxStyles.radioTitle, ...sx }}>
            {children}
            <Box sx={sxStyles.radioSubtitle}>{subtitle}</Box>
        </StriimTypography>
    );
};

const ToggleFieldLabel = ({ title, label, subtitle }: ToggleFieldLabelProps) => {
    return (
        <Grid sx={sxStyles.toggleContainer}>
            {title && <FieldLabel>{title}</FieldLabel>}
            <FieldValue sx={sxStyles.toggleLabel} subtitle={subtitle}>
                {label}
            </FieldValue>
        </Grid>
    );
};

export { FieldLabel, FieldValue, ToggleFieldLabel };
