import _ from "underscore";
import App from "app";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import Group, { Default_Group } from "core/services/metaStoreService/metaObjects/group";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";

function convertNameToTitle(title) {
    return title.replace(new RegExp("_", "g"), " ");
}

const serviceApi = {
    appGroupList: async function() {
        let collection = await metaStoreService.fetchCollection(metaStoreService.entities.GROUP);
        let userGroupsCollection = new Backbone.Collection();
        collection.forEach(model => {
            if (model && model.nsName === App.user.defaultNamespace) {
                model.title = convertNameToTitle(model.name);
                model.isDefaultGroup = model.name === Default_Group;
                userGroupsCollection.add(model);
            }
        });
        return userGroupsCollection;
    },
    appGroupRemove: function(groupId) {
        return new Promise((resolve, reject) => {
            this.appGroupById(groupId)
                .then(group => {
                    group
                        .destroy()
                        .then(() => {
                            resolve();
                        })
                        .fail(e => {
                            reject(
                                `Failed to remove group ${groupId} with NAME: '${e || {}}.name', MESSAGE: '${
                                    (e || {}).message
                                }'`
                            );
                        });
                })
                .fail(() => {
                    reject(`Group ${groupId} not found`);
                });
        });
    },

    getDefaultGroup: function() {
        const defaultGroupId = App.user.defaultNamespace + ".GROUP." + Default_Group;

        return new Promise((resolve, reject) => {
            this.appGroupById(defaultGroupId).then(defaultGroup => {
                if (defaultGroup) {
                    resolve(defaultGroup);
                    return;
                }

                const newGroup = {
                    name: Default_Group,
                    nsName: App.user.defaultNamespace,
                    orderNumber: 1000,
                    collapsed: false,
                    applications: []
                };
                this.createOrUpdate(newGroup)
                    .then(group => {
                        resolve(group);
                    })
                    .catch(error => {
                        console.log("error while creating default group");
                        reject(error);
                    });
            });
        });
    },

    appGroupById: function(groupId) {
        return metaStoreService.findById(groupId);
    },
    createOrUpdate: function(groupDto) {
        return new Promise((resolve, reject) => {
            return this.appGroupById(getFullId(groupDto.name)).then(group => {
                if (!group) {
                    group = new Group.Model();
                }

                if (!groupDto.name) {
                    groupDto.name = Default_Group;
                }

                group.set({
                    nsName: App.user.defaultNamespace,
                    name: convertTitleToName(groupDto.name),
                    collapsed: groupDto.collapsed,
                    orderNumber: groupDto.orderNumber,
                    applications: groupDto.applications,
                    description: groupDto.description,
                    groupType: groupDto.groupType
                });

                group
                    .save()
                    .then(group => {
                        resolve(group);
                    })
                    .fail(e => {
                        console.warn("cant save group", group);
                        reject(e);
                    });
            });
        });
    }
};

function convertTitleToName(title) {
    let result = title.replace(new RegExp(" ", "g"), "_");
    result = result.replace(/\W/g, "");
    return result;
}

function getFullId(groupName) {
    return metaObjectConverter.getId(
        App.user.defaultNamespace,
        metaStoreService.entities.GROUP,
        convertTitleToName(groupName)
    );
}

export default {
    list: function() {
        return new Promise(resolve => {
            serviceApi.appGroupList().then(collection => {
                let models = collection.sortBy("orderNumber");
                resolve(models);
            });
        });
    },

    remove: function(groupName) {
        return serviceApi.appGroupRemove(getFullId(groupName));
    },

    updateGroup: async function(existingGroupName, newGroupName, groupDescription) {
        if (!groupDescription) {
            // use whitespace for empty description because backbone 'sync' method won't pass empty group to the server
            groupDescription = " ";
        }
        if (!newGroupName || !existingGroupName) {
            console.warn("new group name not set for: " + newGroupName);
            return new Promise(resolve => {
                resolve();
            });
        }

        if (!convertTitleToName(newGroupName)) {
            return new Promise(resolve => {
                resolve();
            });
        }

        if (existingGroupName.toLowerCase() !== newGroupName.toLowerCase()) {
            const currentGroup = await serviceApi.appGroupById(getFullId(existingGroupName));

            const newGroup = {
                name: newGroupName,
                description: groupDescription,
                orderNumber: currentGroup.orderNumber,
                collapsed: currentGroup.collapsed,
                applications: currentGroup.applications,
                groupType: currentGroup.groupType
            };
            return new Promise((resolve, reject) => {
                serviceApi
                    .createOrUpdate(newGroup)
                    .then(updatedGroup => {
                        serviceApi
                            .appGroupRemove(getFullId(existingGroupName))
                            .then(() => resolve(updatedGroup))
                            .catch(reject);
                    })
                    .catch(reject);
            });
        }

        return new Promise((resolve, reject) => {
            serviceApi.appGroupById(getFullId(existingGroupName)).then(group => {
                group.description = groupDescription;
                serviceApi.createOrUpdate(group).then(resolve, reject);
            });
        });
    },

    createGroup: async function(name, description) {
        return new Promise((resolve, reject) => {
            serviceApi
                .createOrUpdate({
                    name,
                    description,
                    applications: []
                })
                .then(data => resolve(data))
                .catch(reject);
        });
    },

    getGroupOrDefaultGroup: async function(groupName) {
        const resolveWithDefaultGroup =
            !groupName || // the default group was not created
            groupName === Default_Group || //the default group was created and page was not refreshed
            groupName === getFullId(Default_Group); // the default group was created before

        try {
            const defaultGroup = await serviceApi.getDefaultGroup();

            if (resolveWithDefaultGroup) {
                return Promise.resolve(defaultGroup);
            } else {
                try {
                    const appGroupById = await serviceApi.appGroupById(getFullId(groupName));
                    return Promise.resolve(appGroupById);
                } catch (e) {
                    console.log("Load group error", e);
                    return Promise.resolve(null);
                }
            }
        } catch (e) {
            console.log("Load default group error: ", e);
            return Promise.resolve(null);
        }
    },

    toggleExpand: async function(groupName, collapsed) {
        return new Promise(resolve => {
            this.getGroupOrDefaultGroup(groupName).then(group => {
                if (!group) {
                    console.warn("Group not found [Toggle]: " + groupName);
                    resolve();
                    return;
                }
                group.collapsed = collapsed;
                serviceApi
                    .createOrUpdate(group)
                    .then(() => {
                        resolve();
                    })
                    .catch(e => {
                        console.log("Cannot save group [Toggle]", e);
                        resolve();
                    });
            });
        });
    },

    moveUp: function(groupName) {
        /**
         * 1) Load list and find current and previous group
         * 2) switch order and save both groups
         */

        return new Promise((resolve, reject) => {
            this.getGroupOrDefaultGroup(groupName)
                .then(group => {
                    if (!group) {
                        console.log("Cant resolve group");
                        reject(e);
                        return;
                    }

                    this.list().then(list => {
                        // in the old designs empty groups were not displayed
                        // this code should be uncommented only when empty groups are hidden in UI
                        /*list = list.filter(
                            g => (g.applications && g.applications.length > 0) || g.name === Default_Group
                        );*/

                        group = list.filter(x => x.id === group.id)[0]; //find group in collection

                        const groupIndex = list.indexOf(group);
                        if (groupIndex === 0) {
                            resolve();
                            return;
                        }

                        let previousGroup = list[groupIndex - 1];

                        const previousGroupOrderNumber = previousGroup.orderNumber;
                        previousGroup.orderNumber = group.orderNumber;
                        group.orderNumber = previousGroupOrderNumber;

                        serviceApi.createOrUpdate(previousGroup).then(() => {
                            serviceApi
                                .createOrUpdate(group)
                                .then(resolve)
                                .catch(reject);
                        });
                    });
                })
                .catch(e => {
                    console.log("Cant resolve group: " + groupName);
                    reject(e);
                });
        });
    },

    moveDown: async function(groupName) {
        /**
         * 1) Load list and find current and next group
         * 2) Switch order and save both groups
         */

        return new Promise((resolve, reject) => {
            this.getGroupOrDefaultGroup(groupName)
                .then(group => {
                    if (!group) {
                        console.log("Cant resolve  Move Down1");
                        reject(e);
                        return;
                    }

                    this.list().then(list => {
                        // in the old designs empty groups were not displayed
                        // this code should be uncommented only when empty groups are hidden in UI
                        /*list = list.filter(
                            g => (g.applications && g.applications.length > 0) || g.name === Default_Group
                        );*/

                        group = list.filter(x => x.id === group.id)[0]; //find group in collection

                        const groupIndex = list.indexOf(group);
                        if (groupIndex === list.length - 1) {
                            resolve();
                            return;
                        }

                        let nextGroup = list[groupIndex + 1];

                        const previousGroupOrderNumber = nextGroup.orderNumber;
                        nextGroup.orderNumber = group.orderNumber;
                        group.orderNumber = previousGroupOrderNumber;

                        serviceApi.createOrUpdate(nextGroup).then(() => {
                            serviceApi
                                .createOrUpdate(group)
                                .then(resolve)
                                .catch(reject);
                        });
                    });
                })
                .catch(e => {
                    console.log("Cant resolve group: " + groupName);
                    reject(e);
                });
        });
    },

    assign: async function(appId, groupName, groupDescription, groupType) {
        /**
         * 1) Find groups with assigned application
         * 2) Remove application from this group and save
         * 3) GroupName is a new group?
         *    - yes: create new group and assign application
         *    - no:  assign application
         */

        const list = await this.list();
        //appId = metaObjectConverter.convertFullNameToShortName(appId);

        // remove app from current group
        let groupsContaingApp = list.filter(g => g.applications.indexOf(appId) !== -1);

        // Unassign. expect that it's just one group
        let defs = [];
        groupsContaingApp.forEach(g => {
            g.applications.splice(g.applications.indexOf(appId), 1);
            defs.push(serviceApi.createOrUpdate(g));
        });

        return new Promise((resolve, reject) => {
            Promise.all(defs).then(() => {
                // if group is not set then don't add to new group
                if (!groupName) {
                    resolve();
                    return;
                }

                let targetGroup = list.filter(g => g.name === groupName);
                if (targetGroup.length > 0) {
                    targetGroup = targetGroup[0];
                    targetGroup.applications.push(appId);
                } else {
                    let nextHighestOrder = 1;
                    if (list.length > 0) {
                        nextHighestOrder =
                            _.max(list, g => {
                                return g.orderNumber;
                            }).orderNumber + 1;
                    }
                    //TODO: #group craete model

                    targetGroup = {
                        name: groupName,
                        description: groupDescription || "",
                        orderNumber: nextHighestOrder,
                        collapsed: false,
                        applications: [appId],
                        groupType: groupType
                    };
                }
                serviceApi
                    .createOrUpdate(targetGroup)
                    .then(() => {
                        resolve();
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        });
    },

    getByName: async function(groupName) {
        return new Promise(resolve => {
            serviceApi.appGroupById(getFullId(groupName)).then(result => {
                resolve(result ? result : null);
            });
        });
    },

    getIdFromName: function(name) {
        return name.replace(/\W/g, "");
    }
};
