import React from "react";
import { StriimLink, StriimTypography } from "@striim/striim-ui";
import YoutubeVideo from "src/modules/homepage/common/youtube_video";
import ExploreListItem from "./explore_list_item";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import sxStyles from "./explore.styles";
import homepageService from "src/modules/homepage/homepage_service";
import Dictionary from "/app/components/common/helpable/online-help-dictionary";
import Tracker from "../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../core/services/tracker/constants";

const Explore = ({ video, ...rest }) => {
    const { url, width, height, thumbnail, style } = video;
    const copy = homepageService.getExploreCopy();
    const docURL = Dictionary.getRootPath();
    const exploreInfo = homepageService.getExploreInfo();
    return (
        <Grid container {...rest}>
            <Grid item sx={sxStyles.container} xs={12}>
                <StriimTypography sx={sxStyles.header} variant="h4" color="greyscale.900">
                    {exploreInfo.title}
                </StriimTypography>
            </Grid>
            {copy && (
                <Grid item xs={6}>
                    <ul style={sxStyles.list}>
                        {copy.map(item => {
                            return <ExploreListItem key={item.title} item={item} />;
                        })}
                    </ul>
                    <StriimLink
                        underline="always"
                        href={docURL}
                        variant="body4"
                        target="_blank"
                        sx={sxStyles.linkItem}
                        onClick={() => {
                            Tracker.getInstance().track(TRACKER_STRINGS.schema.learnMore, {
                                event: TRACKER_STRINGS.eventClicked.learnMore
                            });
                            return true;
                        }}
                    >
                        Read documentation
                    </StriimLink>
                </Grid>
            )}
            <Grid item xs={6} sx={exploreInfo.videoContainerStyle || sxStyles.videoContainer}>
                <YoutubeVideo url={url} width={width} height={height} thumbnail={thumbnail} style={style} />
            </Grid>
        </Grid>
    );
};

export default Explore;

Explore.propTypes = {
    video: PropTypes.object.isRequired
};
