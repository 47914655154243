import React from "react";
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer } from "recharts";
import { StriimTypography } from "@striim/striim-ui";
import { Grid } from "@mui/material";

import { styles } from "./discovery-summary-chart.styles";
import { getPluralizedEntityName } from "../../utils";

interface DiscoverySummaryChartProps {
    entity: string;
    entitiesScanned: number;
    summaryData: {
        sensitiveData: number;
        nonSensitiveData: number;
    };
    showBottomBorder?: boolean;
    isReport: boolean;
}

const DiscoverySummaryChart = ({
    entity,
    entitiesScanned,
    summaryData,
    showBottomBorder = true,
    isReport = false
}: DiscoverySummaryChartProps) => {
    const entityName = getPluralizedEntityName(entity);
    const sensitiveDataBarName = `${entityName} w/ sensitive data (${summaryData.sensitiveData})`;
    const nonSensitiveDataBarName = `${entityName} w/o sensitive data (${summaryData.nonSensitiveData})`;

    const containerProps = isReport ? { width: 800, height: 40 } : { width: "100%", height: 70 };
    const legendProps = isReport ? { align: "left", layout: "horizontal" } : { align: "center", layout: "vertical" };

    return (
        <Grid container p={2} gap={1} flexDirection={"column"} sx={[showBottomBorder && styles.bottomBorder]}>
            <Grid container>
                <StriimTypography variant="caption1" color="greyscale.900" fontSize={14} lineHeight={"20px"}>
                    {entityName} Scanned
                </StriimTypography>
            </Grid>
            <Grid container>
                <StriimTypography variant="caption1" color="primary.700" fontSize={"22px"} lineHeight={"32px"}>
                    {entitiesScanned}
                </StriimTypography>
            </Grid>
            <Grid container>
                <ResponsiveContainer {...containerProps}>
                    <BarChart
                        width={408}
                        height={20}
                        data={[summaryData]}
                        layout="vertical"
                        margin={styles.barChartPosition}
                        barSize={8}
                        style={styles.barChart}
                    >
                        <XAxis hide type="number" />
                        <YAxis hide type="category" />

                        <Bar
                            name={sensitiveDataBarName}
                            dataKey="sensitiveData"
                            stackId="single-stack"
                            fill="#7E7ED6"
                            radius={[2, 0, 0, 2]}
                            isAnimationActive={!isReport}
                        />
                        <Bar
                            name={nonSensitiveDataBarName}
                            dataKey="nonSensitiveData"
                            stackId="single-stack"
                            fill="#26E9BA"
                            radius={[0, 2, 2, 0]}
                            isAnimationActive={!isReport}
                        />
                        <Legend
                            verticalAlign="bottom"
                            height={36}
                            iconSize={11}
                            iconType="square"
                            wrapperStyle={styles.legendWrapper}
                            formatter={value => <span style={styles.legendText}>{value}</span>}
                            {...legendProps}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
    );
};

export default DiscoverySummaryChart;
