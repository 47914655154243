import axios from "axios";
import api from "core/api/api";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import { OriginType } from "./";

const provider = axios.create({
    baseURL: "/api/v2"
});

provider.interceptors.request.use(request => {
    request.headers["Authorization"] = "token " + api.token;

    return request;
});

class UserManagementApi {
    static async getUsers() {
        try {
            const { data } = await provider.get("user");
            const usersCollection = await metaStoreService.fetchCollection(metaStoreService.entities.USER);
            const metaObjectUsers = usersCollection.toJSON();
            const users = data.map(user => {
                const target = metaObjectUsers.find(u => u.name === user["User Id"]);
                return {
                    ...user,
                    isActive: target ? target.isActive : null
                };
            });
            return users;
        } catch (error) {
            return error;
        }
    }

    static async getUser(username) {
        try {
            const result = await provider.get(`user/${username}`);
            return result.data[0];
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static async createUser(
        username,
        password,
        defaultNamespace,
        roles,
        isLdapUser,
        alias,
        propertyset,
        email,
        firstname,
        lastname,
        phone,
        timezone
    ) {
        const postData = {
            password: password,
            origin_type: isLdapUser ? OriginType.Ldap : OriginType.Internal,
            roles: roles || [],
            ldap_propertyset: propertyset,
            properties: {
                email: email,
                firstname,
                lastname,
                phone,
                ...(timezone && { timezone })
            },
            ...(alias !== "" && alias !== undefined && { alias: alias })
        };

        const { data } = await provider.post(`user/${username}`, postData);
        return data;
    }

    static async updateUser(username, password, isLdapUser, email, firstname, lastname, phone, timezone, uiconfig) {
        const putData = {
            ...(password && { password }),
            email: email,
            firstname,
            lastname,
            phone,
            ...(timezone && { timezone }),
            uiconfig
        };
        const { data } = await provider.put(`user/${username}`, putData);
        return data;
    }

    static async deleteUser(username) {
        const { data } = await provider.delete(`user/${username}`);
        return data;
    }

    static async createRole(rolename, originType) {
        const postData = { origin_type: originType || OriginType.Internal };
        const { data } = await provider.post(`role/${rolename}`, postData);
        return data;
    }

    static async deleteRole(rolename) {
        const { data } = await provider.delete(`role/${rolename}`);
        return data;
    }

    static async getRole(rolename) {
        const { data } = await provider.get(`role/${rolename}`);
        return data;
    }

    static async getRoles() {
        const { data } = await provider.get(`role`);
        return data;
    }

    static async grantRolesToUser(username, roles) {
        const { data } = await provider.post(`user/permission/grant/roles/user/${username}`, {
            roles: roles
        });
        return data;
    }

    static async grantPermissionsToUser(username, objectname, privileges, objectTypes) {
        const { data } = await provider.post(
            `user/permission/grant/permissions/user/${username}/object/${objectname || "*.*"}`,
            { object_types: objectTypes, privileges: privileges }
        );
        return data;
    }

    static async revokeRolesFromUser(username, roles) {
        const { data } = await provider.post(`user/permission/revoke/roles/user/${username}`, { roles: roles });
        return data;
    }

    static async revokePermissionsFromUser(username, objectname, privileges, objectTypes) {
        const { data } = await provider.post(
            `user/permission/revoke/permissions/user/${username}/object/${objectname}`,
            { object_types: objectTypes.map(x => x.toLowerCase()), privileges: privileges.map(x => x.toLowerCase()) }
        );
        return data;
    }

    static async grantRolesToRole(name, roles) {
        const { data } = await provider.post(`user/permission/grant/roles/role/${name}`, {
            roles
        });
        return data;
    }

    static async grantPermissionsToRole(name, permission) {
        const { data } = await provider.post(
            `user/permission/grant/permissions/role/${name}/object/${permission.objectName || "*.*"}`,
            {
                object_types: permission.objectTypes.map(x => x.toLowerCase()),
                privileges: permission.actions.map(x => x.toLowerCase())
            }
        );
        return data;
    }

    static async revokeRolesFromRole(name, roles) {
        const { data } = await provider.post(`user/permission/revoke/roles/role/${name}`, {
            roles
        });
        return data;
    }

    static async revokePermissionsFromRole(name, permission) {
        const { data } = await provider.post(
            `user/permission/revoke/permissions/role/${name}/object/${permission.objectName}`,
            {
                object_types: permission.objectTypes.map(x => x.toLowerCase()),
                privileges: permission.actions.map(x => x.toLowerCase())
            }
        );
        return data;
    }

    static async activateUser(username) {
        const { data } = await provider.put(`user/activation/${username}`, { activate: true });
        return data;
    }

    static async deactivateUser(username) {
        const { data } = await provider.put(`user/activation/${username}`, { activate: false });
        return data;
    }
}

export default UserManagementApi;
