import Backbone from "backbone";
import InMemoryModel from "core/InMemoryModel";
import App from "app";
import _ from "underscore";
import template from "./query_config.html";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import hierarchicalFormatter from "app/components/common/editor/control/select/hierarchicalFormatter";
import FormBuilder from "app/components/common/editor/form-builder";
import UIControl from "app/components/common/editor/control/ui-control";
import "app/components/common/_deprecated/form-mixin-dialog/dialog";

var QueryConfig = {};

QueryConfig.Model = InMemoryModel.extend({
    defaults: {
        id: String,
        queryString: String,
        queries: Backbone.Collection,
        dashboardNSName: String,
        actions: null,
    },
    initialize: function () {
        this.on(
            "change:id",
            function (model, id) {
                var query = this.queries.get(id);
                this.id = id;
                this.queryString = query ? query.queryDefinition : null;
                if (!query && id) {
                    this.id = metaObjectConverter.getId(
                        this.dashboardNSName,
                        metaStoreService.entities.QUERY,
                        metaObjectConverter.getName(id)
                    );
                    this.queryString = "SELECT \n \t \nFROM";
                }
            },
            this
        );
    },
    validate: function () {
        var validationError = {};
        if (!this.id) {
            validationError.id = "Please enter the query name";
        }
        if (!this.queryString) {
            validationError.queryString = "Please enter the query string";
        }
        return _.isEmpty(validationError) ? undefined : validationError;
    },
});

QueryConfig.View = App.FormMixinDialog.View.extend({
    template: _.template(template),
    initialize: function (options) {
        this.formBuilder = new FormBuilder({
            model: this.model,
            autoSave: true,
            twoWayDataBinding: true,
            labels: {
                id: {
                    name: "Query name",
                },
            },
            mapper: {
                id: UIControl.Select(hierarchicalFormatter(this.model.queries, true), {
                    addNew: true,
                    addNewText: "New query name: ",
                    nameOnly: true,
                    placeholder: "Enter new name or choose an existing query:",
                    customCssClass: "light",
                    doNotFilterCharacters: true,
                }),
                queryString: UIControl.Tql.extend({
                    showHeader: false,
                    hideLabel: true,
                }),
            },
        });
        options.bind_submit = true;
        options.classes = "medium-query";
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
    },
    regions: {
        queryFormRegion: ".query-form",
    },
    onRender: function () {
        this.queryFormRegion.show(this.formBuilder.create());
    },
    submit: function () {
        this.model.save();
        this.trigger("form-submit-success", this);
    }
});

export default QueryConfig;
