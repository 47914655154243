import Backbone from "backbone";
import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./meta-info-status.html";
import $__app_components_common_editor_form_builder from "app/components/common/editor/form-builder";
import $__app_components_common_editor_control_ui_control from "app/components/common/editor/control/ui-control";

var View = BaseControl.View.extend({
    template: _.template(template),
    regions: {
        body: ".meta-info-status-body",
    },
    onRender: function () {
        var FormBuilder = $__app_components_common_editor_form_builder;
        var UIControl = $__app_components_common_editor_control_ui_control;
        var form_model = new Backbone.Model(this.getValue());
        var form = new FormBuilder({
            model: form_model,
            SubmitText: "Save",
            CancelText: "Cancel",
            ReadOnly: false,
        });

        var isAdHoc = UIControl.Toggle.create();
        form.addControl(isAdHoc, "isAdHoc");

        var isAnonymous = UIControl.Toggle.create();
        form.addControl(isAnonymous, "isAnonymous");

        var isDropped = UIControl.Toggle.create();
        form.addControl(isDropped, "isDropped");

        var isValid = UIControl.Toggle.create();
        form.addControl(isValid, "isValid");

        var view = form.create();
        this.getRegion("body").show(view);

        BaseControl.View.prototype.onRender.apply(this, arguments);
    },

    getViewValue: function () {
        return this.$("input").val();
    },

    setViewValue: function () {
        this.$("input").val(this.getValue());
    },
});

export default _.extend({}, BaseControl, {
    View: View,
});
