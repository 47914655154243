// A base HighCharts mixin
import App from "app";
import evaluator from "app/components/dashboard/visualization/charts/gauge/expressionEvaluator";
import "jquery";
import "underscore";
import "backbone";

App.module("Chart.SeriesGenerators.Default", function (Generator, App, Backbone, Marionette, $, _) {
    Generator.SeriesGenerator = function (/*options*/) {};

    /*******************************************************************
     *
     * @param options
     *  - isXAxisTypeCategory: Is the XAxis type set to Category
     *  - isYAxisTypeCategory: Is the YAxis type set to Category
     *  - data: data to be formatted
     *  - includeCategoryWithDataPoint: point.name is set to the X Category, useful for pie charts etc
     *  - xFieldIndex: Integer index of the xField in row
     *  - yFieldIndex: Integer index of the yField in row
     *  - zFieldIndex: Integer index of the yField in row (Optional, if no zindex do not define)
     *  - includePointColor: Boolean, should we set the point color
     *  - categoryField: Field we will generate series from
     *  - includeCategoryWithDataPoint: Include category with data point.name will be set to the category
     *  - tooltipConfig: Tooltip configuration to include with each point (Optional)
     *  - fieldList: List of fields that map to the row data
     *  - retentionStrategyType: Data retention type, 'all', 'current'
     *  - conditionalColorRules: Rules set to evaluate for assigning conditional colors to the series
     *
     * @returns {{}}
     */
    Generator.SeriesGenerator.prototype.setOptions = function (options) {
        this.isXAxisTypeCategory = options.isXAxisTypeCategory;
        this.isYAxisTypeCategory = options.isYAxisTypeCategory;
        this.data = options.data;
        this.xField = options.xField;
        this.yField = options.yField;
        this.zField = options.zField;
        this.includeCategoryWithDataPoint = options.includeCategoryWithDataPoint;
        this.categoryField = options.categoryField;
        this.colorField = options.colorField;
        this.includeCategoryWithDataPoint = options.includeCategoryWithDataPoint;
        this.tooltipConfig = options.tooltipConfig;
        this.fieldList = options.fieldList.toJSON();
        this.conditionalColorRules = options.conditionalColorRules;
        this.includePointColor = options.includePointColor;
        this.seriesAlias = options.seriesAlias;

        this.initialize();
    };

    Generator.SeriesGenerator.prototype.initialize = function () {
        this.xCategoryMap = {};
        this.yCategoryMap = {};

        this.categories = [];
        this.categories.push([]);
        this.categories.push([]);

        this.legendData = {};

        this.xTick = 0;
        this.xTickMap = {};
        this.yTick = 0;
        this.yTickMap = {};
    };

    Generator.SeriesGenerator.prototype.XCATEGORY_INDEX = 0;
    Generator.SeriesGenerator.prototype.YCATEGORY_INDEX = 1;
    Generator.SeriesGenerator.prototype.generateSeries = function (data) {
        var dataAndCategories = this.formatDataAndGenerateCategories(data);

        // Highcharts expects data to be in ascending X order, highcharts documentation
        // says the presorting the responsibility of the implementor
        dataAndCategories.data = dataAndCategories.data.sort(function (obj1, obj2) {
            return obj1.x - obj2.x;
        });
        var s = {
            animation: false,
            data: dataAndCategories.data,
            turboThreshold: Number.MAX_VALUE,
        };

        return s;
    };

    /*******************************************************************
     * Formats the data points and generates categories
     * Combined these two separate functions to avoid iterating through
     * the data set more than once
     *
     * @returns {{data: Array, categories: *}}
     */
    Generator.SeriesGenerator.prototype.formatDataAndGenerateCategories = function (data) {
        // function(options, categories, xCategoryMap, yCategoryMap, tooltipConfig, fieldList) {
        var dataset = [];
        var category;
        if (this.isXAxisTypeCategory && this.categoryField === "STRIIM_VALIDATION_STATS") {
            data = _.sortBy(data, this.xField);
        }

        _(data).each(
            function (row /*, i*/) {
                if (this.isXAxisTypeCategory) {
                    category = row[this.xField];
                    if (!this.existsInXCategories(category)) {
                        this.addXCategory(category);
                    }
                }

                if (this.isYAxisTypeCategory) {
                    category = row[this.yField];
                    if (!this.existsInYCategories(category)) {
                        this.addYCategory(category);
                    }
                }

                dataset.push(this.generatePoint(row));
            }.bind(this)
        );

        var dataAndCategories = {
            data: dataset,
            categories: this.categories,
        };

        return dataAndCategories;
    };

    Generator.SeriesGenerator.prototype.generatePoint = function (row) {
        var point = {};
        point.x = row[this.xField];
        if (this.isXAxisTypeCategory) {
            if (this.includeCategoryWithDataPoint) {
                point.name = row[this.xField];
            } else {
                point.x = this.xCategoryMap[row[this.xField]];
            }
        } else {
            if (isNaN(point.x)) {
                if (this.existsInXTickMap(point.x)) {
                    point.x = this.getValueFromXTickMap(point.x);
                } else {
                    this.xTick++;
                    this.addValueToXTickMap(point.x, this.xTick);
                    point.x = this.xTick;
                }
            }
        }

        point.y = row[this.yField];
        if (this.isYAxisTypeCategory) {
            point.y = this.yCategoryMap[row[this.yField]];
        } else {
            if (isNaN(point.y)) {
                if (this.existsInYTickMap(point.y)) {
                    point.y = this.getValueFromYTickMap(point.y);
                } else {
                    this.yTick++;
                    this.addValueToYTickMap(point.y, this.yTick);
                    point.y = this.yTick;
                }
            }
        }

        if (this.zField) {
            point.z = row[this.zField];
            point.value = row[this.zField];
        }

        if (this.includePointColor) {
            var colorAliasValue = this.categoryField;
            if (this.colorField !== "") {
                colorAliasValue = this.colorField;
            }
            var colorAndAlias = this.getPointColorAndAlias(row, colorAliasValue);
            point.color = colorAndAlias.color;
            if (!this.isKeyInLegendData(colorAndAlias.alias)) {
                this.addKeyAndColorToLegendData(colorAndAlias.alias, point.color);
            }
        } else {
            for (var i = 0; i < this.conditionalColorRules.length; i++) {
                if (
                    this.conditionalColorRules[i].style.alias !== "" &&
                    this.conditionalColorRules[i].style.alias !== this.seriesAlias
                ) {
                    continue;
                }

                var computed = evaluator.evaluate(this.conditionalColorRules[i].expression, row);

                if (computed === true) {
                    var customColor = this.conditionalColorRules[i].style.color;

                    point.color = customColor;
                    point.marker = {
                        fillColor: customColor,
                        states: {
                            hover: {
                                fillColor: customColor,
                            },
                        },
                    };
                }
            }
        }

        point.rowData = row;
        point.tooltipConfig = this.tooltipConfig;
        point.fieldList = this.fieldList;

        return point;
    };

    Generator.SeriesGenerator.prototype.isKeyInLegendData = function (key) {
        return this.legendData.hasOwnProperty(key);
    };

    Generator.SeriesGenerator.prototype.addKeyAndColorToLegendData = function (key, color) {
        this.legendData[key] = color;
    };

    Generator.SeriesGenerator.prototype.getLegendData = function () {
        return this.legendData;
    };

    Generator.SeriesGenerator.prototype.existsInXCategories = function (category) {
        return this.xCategoryMap.hasOwnProperty(category);
    };

    Generator.SeriesGenerator.prototype.existsInYCategories = function (category) {
        return this.yCategoryMap.hasOwnProperty(category);
    };

    Generator.SeriesGenerator.prototype.getXCategories = function () {
        return this.categories[this.XCATEGORY_INDEX];
    };

    Generator.SeriesGenerator.prototype.getYCategories = function () {
        return this.categories[this.YCATEGORY_INDEX];
    };

    Generator.SeriesGenerator.prototype.existsInXTickMap = function (value) {
        return this.xTickMap.hasOwnProperty(value);
    };

    Generator.SeriesGenerator.prototype.addValueToXTickMap = function (value, index) {
        this.xTickMap[value] = index;
    };

    Generator.SeriesGenerator.prototype.getValueFromXTickMap = function (value) {
        return this.xTickMap[value];
    };

    Generator.SeriesGenerator.prototype.existsInYTickMap = function (value) {
        return this.yTickMap.hasOwnProperty(value);
    };

    Generator.SeriesGenerator.prototype.addValueToYTickMap = function (value, index) {
        this.yTickMap[value] = index;
    };

    Generator.SeriesGenerator.prototype.getValueFromYTickMap = function (value) {
        return this.yTickMap[value];
    };

    Generator.SeriesGenerator.prototype.addXCategory = function (category) {
        this.categories[this.XCATEGORY_INDEX].push(category);

        // Keep track of the categories we have already added
        var index = this.categories[this.XCATEGORY_INDEX].length - 1;
        this.xCategoryMap[category] = index;
    };

    Generator.SeriesGenerator.prototype.addYCategory = function (category) {
        this.categories[this.YCATEGORY_INDEX].push(category);

        // Keep track of the categories we have already added
        var index = this.categories[this.YCATEGORY_INDEX].length - 1;
        this.yCategoryMap[category] = index;
    };

    Generator.SeriesGenerator.prototype.getPointColorAndAlias = function (row, colorAliasValue) {
        var colorAndAlias = {};
        var conditional = this.findConditionalMatch(row, this.conditionalColorRules);
        if (conditional) {
            colorAndAlias.color = conditional.style.color;
            //var alias = conditional.expression;
            if (conditional.style.alias !== "") {
                colorAndAlias.alias = conditional.style.alias;
            }
        } else {
            if (colorAliasValue) {
                colorAndAlias.color = this.getSeriesColor(row[colorAliasValue]);
                colorAndAlias.alias = row[colorAliasValue];
            } else {
                colorAndAlias.color = this.getSeriesColor("");
                colorAndAlias.alias = "Series";
            }
        }

        return colorAndAlias;
    };

    Generator.SeriesGenerator.prototype.findConditionalMatch = function (data, conditionals) {
        var matched = false;
        var match;
        if (!conditionals) {
            return match;
        }

        if (conditionals.length > 0) {
            conditionals.map(function (condition) {
                if (matched) {
                    return false;
                }
                var exp = condition.expression;

                _(data).each(function (value, field) {
                    exp = exp.replace(new RegExp(field, "g"), _(value).isNumber() ? value : '"' + value + '"');
                });
                try {
                    matched = eval(exp); // jshint ignore:line
                } catch (e) {
                    console.warn("Warning: Could not evaluate expression: ", exp, "\nError:", e);
                }
                if (matched) {
                    match = condition;
                    return false;
                }
            });
        }
        return match;
    };

    Generator.SeriesGenerator.prototype.getSeriesColor = function (key) {
        return App.request("get:color:byalias", key);
    };

    Generator.SeriesGenerator.prototype.getFlatData = function () {
        var sourceData;
        if (this.categoryField !== "") {
            sourceData = this.flattenData();
        } else {
            sourceData = this.data;
        }

        return sourceData;
    };

    Generator.SeriesGenerator.prototype.flattenData = function () {
        var flatData = [];
        var tmpData = this.data[0];
        for (var key in tmpData) {
            if (tmpData.hasOwnProperty(key)) {
                var dataRows = tmpData[key];
                for (var i = 0; i < dataRows.length; i++) {
                    flatData.push(tmpData[key][i]);
                }
            }
        }
        return flatData;
    };

    Generator.SeriesGeneratorFactory = function () {};

    Generator.SeriesGeneratorFactory.prototype.create = function (options) {
        if (
            (options.retentionStrategyType === "all" && options.categoryField === "") ||
            (options.retentionStrategyType === "current" && options.categoryField === "")
        ) {
            return new RetentionTypeAllNoCategoryGenerator(options);
        } else if (options.retentionStrategyType === "all" && options.categoryField !== "") {
            return new RetentionTypeAllWithCategoryGenerator(options);
        } else if (options.retentionStrategyType === "current" && options.categoryField !== "") {
            return new RetentionTypeCurrentWithCategoryGenerator(options);
        }
    };

    function RetentionTypeAllNoCategoryGenerator(options) {
        this.setOptions(options);
    }

    RetentionTypeAllNoCategoryGenerator.prototype = new Generator.SeriesGenerator();
    RetentionTypeAllNoCategoryGenerator.prototype.generate = function () {
        var series = [];
        var newSeries = this.generateSeries(this.data);
        if (!this.includePointColor) {
            var row = {};
            var field = "";

            row = {
                seriesAlias: this.seriesAlias,
            };
            field = "seriesAlias";

            var colorAndAlias = this.getPointColorAndAlias(row, field);
            newSeries.color = colorAndAlias.color;
            var legendKey = "Series";
            if (this.seriesAlias) {
                legendKey = this.seriesAlias;
            }
            if (!this.isKeyInLegendData(legendKey)) {
                this.addKeyAndColorToLegendData(legendKey, newSeries.color);
            }
        }
        series.push(newSeries);
        return series;
    };

    function RetentionTypeAllWithCategoryGenerator(options) {
        this.setOptions(options);
    }

    RetentionTypeAllWithCategoryGenerator.prototype = new Generator.SeriesGenerator();
    RetentionTypeAllWithCategoryGenerator.prototype.generate = function () {
        var series = [];

        for (var key in this.data) {
            if (this.data.hasOwnProperty(key)) {
                var seriesData = this.data[key];
                var newSeries = this.generateSeries(seriesData);

                var colorAliasValue = this.categoryField;
                if (this.colorField !== "") {
                    colorAliasValue = this.colorField;
                }
                var colorAndAlias = this.getPointColorAndAlias(seriesData[seriesData.length - 1], colorAliasValue);
                newSeries.color = colorAndAlias.color;

                if (colorAndAlias.alias) {
                    newSeries.name = colorAndAlias.alias;
                } else if (this.categoryField) {
                    newSeries.name = seriesData[seriesData.length - 1][this.categoryField];
                } else {
                    newSeries.name = seriesData[seriesData.length - 1][this.xField];
                }

                if (!this.isKeyInLegendData(newSeries.name) && !this.includePointColor) {
                    this.addKeyAndColorToLegendData(newSeries.name, newSeries.color);
                }
                series.push(newSeries);
            }
        }
        return series;
    };

    function RetentionTypeCurrentWithCategoryGenerator(options) {
        this.setOptions(options);
    }

    RetentionTypeCurrentWithCategoryGenerator.prototype = new Generator.SeriesGenerator();
    RetentionTypeCurrentWithCategoryGenerator.prototype.generate = function () {
        var flatData = this.flattenData();
        var newSeries = this.generateSeries(flatData);
        newSeries.color = this.getSeriesColor(this.yField);
        if (this.seriesAlias) {
            this.addKeyAndColorToLegendData(this.seriesAlias, newSeries.color);
        }
        var series = [];
        series.push(newSeries);
        return series;
    };

    RetentionTypeCurrentWithCategoryGenerator.prototype.flattenData = function () {
        var flatData = [];
        var tmpData = this.data[0];
        for (var key in tmpData) {
            if (tmpData.hasOwnProperty(key)) {
                flatData.push(tmpData[key][0]);
            }
        }

        return flatData;
    };
});
