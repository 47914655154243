import React from "react";
import AutomatedGroupDeployModal from "../../automated-app-group/group-deploy-modal/automated-group-deploy-modal";
import automatedGroupStore from "../automated-app-group-details.store";
import { observer } from "mobx-react-lite";

const AutomatedAppGroupDeployComponent: React.FunctionComponent = observer(() => {
    function closeDeployAndRunAppsModal() {
        automatedGroupStore.setIsDeployAndRunModalVisible(false);
    }
    function closeDeployAppsModal() {
        automatedGroupStore.setIsDeployModalVisible(false);
    }
    return (
        <React.Fragment>
            <AutomatedGroupDeployModal
                isVisible={automatedGroupStore.isdeployModalVisible}
                setisVisible={closeDeployAppsModal}
                appIds={automatedGroupStore.appIds}
            />
            <AutomatedGroupDeployModal
                isVisible={automatedGroupStore.isdeployAndRunModalVisible}
                setisVisible={closeDeployAndRunAppsModal}
                appIds={automatedGroupStore.appIds}
                runApps
            />
        </React.Fragment>
    );
});

export default AutomatedAppGroupDeployComponent;
