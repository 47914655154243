import React from "react";
import {StriimTable, StriimTooltip} from "@striim/striim-ui";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles } from "@material-ui/core/styles";
import {StriimTypography} from "../../../user-plan/components/StriimTypographyCustom";
import {useTheme} from "@mui/material/styles";
import {NoBillingData} from "../NoBillingData";

const useStyles = makeStyles(({spacing}) => ({
    tableWrapper: {
        padding: spacing(2),
        height: "100%"
    },
    tableTitle: {
        display: "flex",
        "& h3": {
            marginRight: 5
        }
    },
    titleSpacing: {
        marginBottom: 10
    },
    tableContainerCustom: {
        height: 160,
        overflowY: "auto"
    },
    infoIcon: {
        width: 18,
        color: themeV2 => themeV2.palette.secondary[500]
    }
}));

const YourCredits = ({ awardedCredits }) => {
    const themeV2 = useTheme();
    const classes = useStyles(themeV2);

    const tableData =
        (awardedCredits || []).map((row, index) => ({
            id: index,
            [columnKey.striimCredits]: row.name,
            [columnKey.amount]: row.amount,
            [columnKey.beginExpiryDates]: row.dates
        })) || [];

    return (
        <div className={classes.tableWrapper}>
            <div className={`${classes.tableTitle} ${tableData.length > 0 ? classes.titleSpacing : ""}`}>
                <StriimTypography variant="h3">Capacity Credits Remaining</StriimTypography>
                <StriimTooltip
                    title="Credits were purchased with your subscription plan. Once exhausted, usage will be metered at an On-Demand rate of 100 Credits Per Million Events."
                    placement="top"
                    arrow
                >
                    <InfoOutlinedIcon className={classes.infoIcon}/>
                </StriimTooltip>
            </div>
            {tableData.length > 0 ? (
                <StriimTable
                    data={tableData}
                    columns={tableColumns}
                    TableContainerProps={{
                        classes: { root: classes.tableContainerCustom }
                    }}
                />
            ) : (
                <NoBillingData text="There are no credits to show at this time"/>
            )}
        </div>
    );
};

export default YourCredits;

const columnKey = {
    amount: "amount",
    beginExpiryDates: "beginExpiryDates",
    striimCredits: "striimCredits"
};

const tableColumns = [
    {
        Header: "Striim Credits",
        accessor: columnKey.striimCredits
    },
    {
        Header: "Amount",
        accessor: columnKey.amount
    },
    {
        Header: "BEGIN DATE - EXPIRY DATE",
        accessor: columnKey.beginExpiryDates
    }
];
