import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { StriimModal, StriimTypography } from "@striim/striim-ui-v2";
import DeployModalContent from "./deploy-modal-content/deploy-modal-content";
import { Close } from "../../../../../../../generic/icon/customIcons";
import { styles } from "./automated-group-deploy-modal.styles";
import utils from "../../../../../../../../core/utils";
import api from "../../../../../../../../core/api/api";
import growl from "../../../../../../../../app/components/common/growl";
import LoadingIndicator from "../../../../../../../generic/loading-indicator";
import automatedGroupStore from "../../automated-app-group-detail/automated-app-group-details.store";
import { sortAutomatedApps } from "../utils/progress-details.helper";
import Tracker from "../../../../../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../../../../core/services/tracker/constants";

export interface FlowDeploymentInfoType {
    group: string;
    strategy: "any" | "flow";
    flow: string;
}
export interface DeploymentInfoType {
    [key: string]: FlowDeploymentInfoType;
}

interface ModalProps {
    appIds: string[];
    isVisible: boolean;
    setisVisible: (value: boolean) => void;
    runApps?: boolean;
}
const AutomatedGroupDeployModal: React.FunctionComponent<ModalProps> = ({
    appIds,
    isVisible,
    setisVisible,
    runApps
}) => {
    const [ilDeploymentInfo, setIlDeploymentInfo] = useState<DeploymentInfoType | null>(null);
    const [cdcDeploymentInfo, setCdcDeploymentInfo] = useState<DeploymentInfoType | null>(null);
    const [isLoading, setisLoading] = useState<boolean>(false);
    const getFlows = (flows, appName) => {
        const flowsArr = [];
        let app_desc;
        flows?.forEach(flow => {
            if (flow.flow === appName) {
                app_desc = flow;
            } else {
                flowsArr.push(flow);
            }
        });
        return {
            app_desc,
            flowsArr
        };
    };
    const onConfirm = async () => {
        setisVisible(false);
        automatedGroupStore.setisAppTransitioning(true);
        const appGroupFQN = automatedGroupStore.groupName.id;

        const ILAppDesc = Object.values(ilDeploymentInfo ?? {});
        const CDCAppDesc = Object.values(cdcDeploymentInfo ?? {});

        const ILAppName = utils.getName(appIds[0]);
        const cdcAppName = utils.getName(appIds[1]);

        const ilAppFlowData = getFlows(ILAppDesc, ILAppName);
        const cdcAppFlowData = getFlows(CDCAppDesc, cdcAppName);

        try {
            if (runApps) {
                await api.deployILCDC(
                    appGroupFQN,
                    ilAppFlowData.app_desc,
                    ilAppFlowData.flowsArr,
                    cdcAppFlowData.app_desc,
                    cdcAppFlowData.flowsArr
                );
                await api.runILCDC(appGroupFQN);
                growl.success("Automated pipeline started successfully");
            } else {
                await api.deployILCDC(
                    appGroupFQN,
                    ilAppFlowData.app_desc,
                    ilAppFlowData.flowsArr,
                    cdcAppFlowData.app_desc,
                    cdcAppFlowData.flowsArr
                );
                growl.success("Apps deployed successfully");
            }
        } catch (error) {
            growl.error(error);
            setisLoading(false);
        } finally {
            automatedGroupStore.setisAppTransitioning(false);
            Tracker.getInstance().track(TRACKER_STRINGS.schema.automatedAppGroup, {
                event: TRACKER_STRINGS.eventClicked.automatedAppGroup,
                buttonClicked: runApps ? "Deploy & Run" : " Deploy",
                appType: "IL & CDC"
            });
        }
    };

    return (
        <div>
            <StriimModal
                dialogProps={{
                    sx: { ".MuiDialog-paper": { width: "auto", minWidth: "700px" } }
                }}
                confirmContent={runApps ? "Deploy & Run" : " Deploy"}
                confirmButtonProps={{
                    "data-test-id": "drop-app-modal-ilcdc-confirm-btn",
                    disabled: isLoading
                }}
                cancelButtonProps={{
                    "data-test-id": "drop-app-modal-ilcdc-cancel-btn",
                    disabled: isLoading
                }}
                autoHeight
                isVisible={isVisible}
                footerBorder
                titleContent={
                    <div>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <StriimTypography variant="h2" color="primary.700">
                                Deploy App
                            </StriimTypography>
                            <Box onClick={() => setisVisible(false)} sx={styles.closeIcon}>
                                <Close />
                            </Box>
                        </Grid>

                        <StriimTypography variant="body4" color="greyscale.700">
                            {runApps
                                ? "Specify the nodes where you want to deploy the automated pipeline and start it."
                                : "Specify and dedicate resources for your automated pipeline. You can start the automated pipeline at a later time."}
                        </StriimTypography>
                    </div>
                }
                onConfirm={onConfirm}
                onCancel={() => setisVisible(false)}
            >
                {isLoading && <LoadingIndicator isGlobal={false} />}
                <DeployModalContent
                    {...{
                        ilDeploymentInfo,
                        setIlDeploymentInfo,
                        cdcDeploymentInfo,
                        setCdcDeploymentInfo,
                        appStatuses: automatedGroupStore.appStatuses
                    }}
                    appIds={sortAutomatedApps(appIds)}
                />
            </StriimModal>
        </div>
    );
};

AutomatedGroupDeployModal.defaultProps = {
    isVisible: true,
    setisVisible: () => {},
    runApps: false
};
export default AutomatedGroupDeployModal;
