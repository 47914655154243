import React, { useContext, useEffect, useMemo, useState } from "react";
import { WizardContext } from "../../wizard-context";
import { StriimButton, StriimInputField, StriimTypography, StriimTooltip } from "@striim/striim-ui";
import { styles } from "./review.styles";
import { isAutomatedWizard } from "../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";

import { Box, Grid, SvgIcon } from "@mui/material";
import { InfoEdit } from "../../../../generic/icon/customIcons";
import { getTargetTables } from "../../services/target-tables-helper";

interface TablesState {
    value: string;
    changed: boolean;
}
const TableEditComponent: React.FC = ({ isPDFReport, ...props }: { isPDFReport: false }, ref) => {
    const { sourceInfo, cdcSourceInfo } = useContext(WizardContext);
    const { ilTablesProperty, setIlTablesProperty, cdcTablesProperty, setCdcTablesProperty } = props;
    const [showSeparateTables, setShowSeparateTables] = useState<boolean>(false);

    async function fetchTablesProperty() {
        const tables = await getTargetTables(sourceInfo.appId);
        setIlTablesProperty({
            value: tables,
            changed: false
        });
        if (isAutomatedWizard()) {
            const cdcTables = await getTargetTables(cdcSourceInfo.appId);
            setCdcTablesProperty({
                value: cdcTables,
                changed: false
            });
            if (tables !== cdcTables) {
                setShowSeparateTables(true);
            }
        }
    }
    useEffect(() => {
        fetchTablesProperty();
    }, []);

    return (
        <React.Fragment>
            <Box>
                <Grid>
                    <StriimTypography variant="body4" sx={styles.targetTableTitle}>
                        {showSeparateTables ? "IL Table mapping" : "Table mapping"}
                        {isPDFReport && (
                            <StriimTypography sx={{ ...styles.record, ml: 2 }} variant="body3">
                                {ilTablesProperty.value}
                            </StriimTypography>
                        )}
                        <StriimTooltip title="Please use caution when changing this property. Wrong input might result in unexpected behaviour of the apps">
                            <Box
                                hidden={isPDFReport}
                                ml={1}
                                sx={{ cursor: "pointer" }}
                                onClick={() => setIlTablesProperty(prev => ({ ...prev, changed: true }))}
                            >
                                <SvgIcon component={InfoEdit} sx={styles.editIconSmall} />
                            </Box>
                        </StriimTooltip>
                    </StriimTypography>
                </Grid>
                {!isPDFReport && (
                    <StriimInputField
                        disabled={!ilTablesProperty.changed}
                        onChange={value =>
                            setIlTablesProperty({
                                changed: true,
                                value: value
                            })
                        }
                        value={ilTablesProperty.value}
                        rows={3}
                        multiline
                        type="text"
                    />
                )}
            </Box>
            {showSeparateTables && (
                <Box>
                    <Grid>
                        <StriimTypography variant="body4" sx={styles.targetTableTitle}>
                            CDC Table mapping
                            {isPDFReport && (
                                <StriimTypography sx={{ ...styles.record, ml: 2 }} variant="body3">
                                    {cdcTablesProperty.value}
                                </StriimTypography>
                            )}
                            <StriimTooltip title="Please use caution when changing this property. Wrong input might result in unexpected behaviour of the apps">
                                <Box
                                    hidden={isPDFReport}
                                    ml={1}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => setCdcTablesProperty(prev => ({ ...prev, changed: true }))}
                                >
                                    <SvgIcon component={InfoEdit} sx={styles.editIconSmall} />
                                </Box>
                            </StriimTooltip>
                        </StriimTypography>
                    </Grid>
                    {!isPDFReport && (
                        <StriimInputField
                            disabled={!cdcTablesProperty.changed}
                            onChange={value =>
                                setCdcTablesProperty({
                                    changed: true,
                                    value: value
                                })
                            }
                            value={cdcTablesProperty.value}
                            rows={3}
                            multiline
                            type="text"
                        />
                    )}
                </Box>
            )}
        </React.Fragment>
    );
};

export default TableEditComponent;
