const sxClasses = {
    actionsContainer: {
        justifyContent: "end",
        padding: 1,
        gap: 1
    },
    tooltip: {
        bgcolor: "transparent",
        minWidth: "600px"
    },
    tooltipContainer: {
        alignItems: "center"
    },
    modalText: {
        color: "white"
    },
    fdTooltip: {
        maxHeight: "400px",
        overflow: "scroll"
    }
};
export default sxClasses;
