import App from "app";
import ComponentsPositionUpdater from "app/components/dashboard/grid/componentsPositionUpdater";
import "app/components/dashboard/visualization/visualization-panel";
import "./grid/grid";
import Tracker from "core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../core/services/tracker/constants";

App.module("Dashboard", function (Dashboard, App, Backbone, Marionette, $, _) {
    // A Dashboard Page.
    // Extends Dashboard Grid. Contains Visualization Panels (which extend GridComponent).
    Dashboard.Page = App.Dashboard.Grid.View.extend({
        className: "page grid-component",
        getComponentView: function () {
            return Dashboard.VisualizationPanel;
        },
        initialize: function () {
            var that = this;

            this.listenTo(this.model.settings, "change", this._updateBackground.bind(this));

            this.listenTo(this, "child:component:drop", function (data) {
                var layout = data.dd.droplayout;
                if (data.dd.event === "move") {
                    data.item.model.set(layout);
                } else {
                    this.addVisualizationPanel(data.grid, data.itemtype, data.item, data.dd.droplayout).then(
                        function () {
                            that.trigger("child:components:added");
                        }
                    );
                }
            });

            this.listenTo(this, "child:component:deleted", function (component) {
                this.model.queryVisualizations.remove(component.model.content.id);
                console.log("QVs are now:", this.model.queryVisualizations.pluck("id"));
            });

            this.$el.off("click." + this.model.id).on("click." + this.model.id, function (e) {
                App.vent.trigger("page:click", e);
            });

            this.listenTo(
                this,
                "child:component:moveend child:component:resizeend child:component:add child:component:deleted child:components:added child:component:style component:resizeend components:nudge",
                this.save
            );

            // The page manages palette drop targets -- the QVs do not manage their own.
            this.listenTo(App.vent, "palette:draginit", function () {
                // All QVs must initialize first
                App.vent.trigger("qv:palette:draginit");
                this.pause_page();
            });
            this.listenTo(App.vent, "palette:dragend", function () {
                this.restart_page();
            });

            this.listenTo(
                App.vent,
                "grid:paste:selection",
                function (gridComponents) {
                    var def = $.Deferred();
                    var operations = [];
                    gridComponents.forEach(
                        function (gridComponent) {
                            // paste the visualization in the same place, only moved down-right a little bit
                            var droplayout = {
                                x: gridComponent.component.x + 1,
                                y: gridComponent.component.y + 1,
                                height: gridComponent.component.height,
                                width: gridComponent.component.width,
                            };

                            // clone component and set new Id
                            var gridComponentJson =
                                typeof gridComponent.component.toJSON === "function"
                                    ? gridComponent.component.toJSON()
                                    : gridComponent.component;
                            var component = new App.Dashboard.Grid.Component.Model(gridComponentJson);
                            var componentContent = gridComponent.component.content;
                            component.id = _.uniqueId("component-");
                            var componentJson =
                                typeof componentContent.toJSON === "function"
                                    ? componentContent.toJSON()
                                    : componentContent;
                            component.content = App.request("clone:metadata:queryvisualization", componentJson);
                            component.content.id = _.uniqueId("QUERYVISUALIZATION-");

                            var addVisualizationAction = this.addVisualizationPanel(
                                gridComponent.grid,
                                gridComponent.component.content.visualizationType,
                                component,
                                droplayout
                            );
                            operations.push(addVisualizationAction);
                        }.bind(this)
                    );

                    $.when.apply($, operations).then(
                        function () {
                            this.trigger("child:components:added");
                            this._toggleVisualizationSelection();
                        }.bind(this)
                    );

                    return def;
                }.bind(this)
            );

            //jx: for play/pause button in toolbar
            this.listenTo(App.vent, "global:pause", this.pause_page);
            this.listenTo(App.vent, "global:play", this.restart_page);

            // Disable handles (except S)
            this.model.controls.set("snap", false);
            this.model.controls.handles.each(function (handle) {
                if (handle.id === "S") {
                    return;
                }
                handle.set("enabled", false);
            });

            this.listenTo(
                this,
                "child:component:updatePosition",
                function (component, e, dd, newLayout) {
                    var componentsPositionUpdater = new ComponentsPositionUpdater(
                        this.model.components.models,
                        this.model.settings.rows,
                        this.model.settings.cols
                    );
                    if (componentsPositionUpdater.canUpdate(component.model, newLayout)) {
                        componentsPositionUpdater.update(component.model, newLayout);
                        component.model.set(newLayout);
                    } else {
                        // resizing single component
                        if (newLayout.hasOwnProperty("height")) {
                            component.model.set({
                                height: newLayout.height,
                            });
                        }
                        if (newLayout.hasOwnProperty("width")) {
                            component.model.set({
                                width: newLayout.width,
                            });
                        }
                    }
                    App.vent.trigger("component:move", component, e, dd);
                }.bind(this)
            );

            return App.Dashboard.Grid.View.prototype.initialize.apply(this, arguments);
        },
        initializeDrag: function (e) {
            // TODO: wrap this into a dragType() method, else we duplicate this logic
            var $handle = $(e.target).closest(".handle");
            if ($handle.length === 0) {
                return false;
            } // Only resize allowed
            return App.Dashboard.Grid.View.prototype.initializeDrag.apply(this, arguments);
        },
        renderPosition: function () {
            this.model.height = Math.floor(
                Math.max(($(window).height() - 200) / this.model.settings.cellheight, this.model.height)
            );
            return App.Dashboard.Grid.View.prototype.renderPosition.apply(this, arguments);
        },
        // Because the page is not inside of a grid, set up its own resize and moving
        initializePageResize: function () {
            this.$el
                .drag("init", this.initializeDrag)
                .drag("start", this.startDrag)
                .drag("drag", this.performDrag)
                .drag("end", this.finishDrag);
        },
        _getSelectedVisualizations: function () {
            return this.model.components.filter(function (component) {
                return component._active;
            });
        },
        _toggleVisualizationSelection: function () {
            App.vent.trigger("visualization:toggle:selection", this._getSelectedVisualizations());
        },
        onAddChild: function (view) {
            this.listenTo(view, "click", function (view) {
                this.model.components.each(function (c) {
                    if (c === view.model) {
                        return;
                    }
                    c._active = false;
                });
                view.model._active = !view.model._active;
                this._toggleVisualizationSelection();
            });

            // For multiple select:
            this.listenTo(view, "shift-click", function (view) {
                view.model._active = !view.model._active;
                this._toggleVisualizationSelection();
            });

            App.Dashboard.Grid.View.prototype.onAddChild.apply(this, arguments);
        },
        onShow: function () {
            this.initializePageResize();
            this._updateBackground();
            App.Dashboard.Grid.View.prototype.onShow.apply(this, arguments);
        },
        save: function () {
            console.log("Page saved!");
            this.model.save();
        },

        _updateBackground: function () {
            if (this.model.settings.background) {
                this.$el.css("background-color", this.model.settings.background);
            }
        },

        pause_page: function () {
            console.log("Page paused");
            this.model.components.each(function (c) {
                if (c.content !== null) {
                    c.content.set("_polling", false);
                }
            });
        },
        restart_page: function () {
            console.log("Page restarted");
            this.model.components.each(function (c) {
                if (c.content !== null) {
                    c.content.set("_polling", true);
                }
            });
        },
        addVisualizationPanel: function (id, itemtype, item, layout) {
            var vismodel;
            this.model.components.each(function (c) {
                c._active = false;
            });

            if (item instanceof App.Dashboard.Grid.Component.View) {
                item = item.model;
            }

            var vismodel_config = {
                name: _.uniqueId("Visualization"),
                nsName: this.model.nsName,
                title: "Untitled " + itemtype,
                query: {
                    id: "",
                },
                visualizationType: itemtype,
                config: {},
            };

            Tracker.getInstance().track(TRACKER_STRINGS.schema.dashboard, {
                id: window.location.hash.split("/")[2],
                event: TRACKER_STRINGS.eventClicked.dashboard,
                buttonClicked:"Add Visualization Panel"
            });

            // Moving an existing component so we have to use the same query & config but create a new meta object
            if (item && item.content) {
                if (item.content.query) {
                    vismodel_config.query = item.content.query;
                }
                if (item.content.config) {
                    vismodel_config.config = item.content.config;
                }
            }

            var def = $.Deferred();
            vismodel = App.request("new:metadata:queryvisualization").set(vismodel_config);

            vismodel.save().then(
                function () {
                    // Once the QueryVis has saved, go ahead and add it to the page's visualizations
                    this.model.queryVisualizations.add(vismodel);

                    // Create a grid component
                    var component = new App.Dashboard.Grid.Component.Model(
                        _({
                            id: _.uniqueId("component-"),
                            _active: true,
                            content: vismodel,
                        }).extend(layout)
                    );

                    // new visualization should be placed on top
                    component.content.config.set("zIndex", this.model.components.length + 1);

                    this.model.components.add(component);
                    this.getRegion("content").currentView.collection.add(component);
                    component._active = true;

                    this._toggleVisualizationSelection();

                    def.resolve(component);
                }.bind(this)
            );

            return def.promise();
        },
    });
});
