import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing, palette }) => ({
    addButton: {
        color: "#000"
    },
    removeButton: {
        color: v5theme => v5theme.palette.greyscale[700]
    },
    disabledButton: {
        color: palette.gray
    },
    warningText: {
        color: palette.danger.main
    },
    dragButton: {
        width: 24,
        height: 24,
        transform: "rotate(45deg)",
        color: v5theme => v5theme.palette.greyscale[700]
    },
    dragButtonContainer: {
        marginRight: spacing(2),
        marginLeft: spacing(1)
    },
    appMetricsAddContainer: {
        justifyContent: "flex-start",
        alignItems: "center",
        gap: spacing(1)
    },
    appMetricsAddLabel: {
        marginTop: spacing(1),
        marginRight: spacing(2)
    },
    appMetricsAddInputField: {
        width: "350px"
    },
    appMetricsAddContent: {
        padding: spacing(2, 0)
    },
    actionButtonContainer: {
        margin: 0
    },
    cardHeader: {
        padding: spacing(1, 0)
    },
    draggableContainer: {
        marginBottom: spacing(1)
    },
    draggableDiv: {
        padding: spacing(1, 0)
    },
    draggableCardHeader: {
        padding: 0,
        border: v5theme => `1px solid ${v5theme.palette.greyscale[200]}`,
        borderRadius: "6px"
    },
    overflowVisible: {
        overflow: "visible"
    },
    dialog: {
        overflow: "visible"
    },
    avatarStyles: {
        margin: 0
    }
}));
