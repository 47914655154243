import React, { useState, useEffect, useRef } from "react";
import {
    StriimTypography,
    StriimButton,
    StriimMessageBox,
    StriimLink,
    StriimInputField,
    StriimForm
} from "@striim/striim-ui";
import { Box, Grid, Fade } from "@mui/material";
import $ from "jquery";

import securityService from "../../../core/services/securityService/securityService";
import App from "../../../app";
import { useReactHeader } from "../../hooks/useReactHeader";
import classes from "./login.styles";
import { authStatus } from "/init";

const DATE = new Date().getFullYear();

function isRedirectInProgress(hash) {
    const initialUrls = ["#", "#/", "#/login", "#/login/"];
    return initialUrls.some(x => x === hash);
}

const LoginComponent = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [ssoErrorMessage, setSSOErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef();
    const values = { username: "", password: "" };
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.hash.split("?error=")[1]);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params) {
            const errorResponse = Object.keys(params)[0];
            errorResponse && setSSOErrorMessage(errorResponse);
        }

        async function effect() {
            const imagesPromiseList: Promise<any>[] = [];
            imagesPromiseList.push(preloadImage("/app/images/logo-collapsed.svg"));
            imagesPromiseList.push(preloadImage("/app/images/illustration.png"));
            imagesPromiseList.push(preloadImage("/app/images/striim-logo.svg"));
            await Promise.all(imagesPromiseList);
            setImagesLoaded(true);
        }

        effect();
    }, []);

    function preloadImage(src: string) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function() {
                resolve(img);
            };
            img.onerror = img.onabort = function() {
                reject(src);
            };
            img.src = src;
        });
    }

    const handleSubmit = () => {
        setSSOErrorMessage("");
        const { values } = formRef.current;
        const { username, password } = values;
        if (password.length === 0 || username.length === 0) {
            setErrorMessage("Please make sure you have filled out all the fields.");
            return;
        }
        setIsLoading(true);

        const navigateBasedOnCloudLicense = () => {
            securityService.isCloudLicense().done(isCloudLicense => {
                if (isCloudLicense) {
                    App.navigate("/create-app", { trigger: true });
                } else {
                    App.navigate("#/landing", { trigger: true });
                }
            });
        };

        securityService
            .login(values)
            .then(function() {
                $("#wa-errorconsole").show();
                authStatus.setIsLoggedIn(true);

                const urlSearchParams = new URLSearchParams(window.location.hash.split("?error=")[1]);
                if (urlSearchParams?.size) {
                    securityService.isCloudLicense().done(isCloudLicense => {
                        if (!isCloudLicense) {
                            window.history.replaceState(null, null, `#/landing`);
                        }
                    });
                }

                if (isRedirectInProgress(window.location.hash)) {
                    navigateBasedOnCloudLicense();
                }
            })
            .catch(error => {
                setErrorMessage(error);
            })
            .finally(() => {
                setIsLoading(false);
                formRef.current.setFieldValue("password", "");
            });
    };

    const handleLoginWithSSO = () => {
        window.location.assign(`/saml/callback`);
    };

    return (
        <Fade in={imagesLoaded} timeout={500}>
            <Grid container display={"flex"} width={"100%"} height={"100vh"}>
                <Grid container item xs={6} sx={classes.leftContainer}>
                    <Grid container sx={classes.formWrapper}>
                        <Grid container item display={"flex"} gap={1} mb={5}>
                            <img alt="Striim Logo" src="/app/images/logo-collapsed.svg" height="34" />
                            <StriimTypography variant="h1" sx={{ color: "primary.700" }}>
                            Sign in to Striim
                            </StriimTypography>
                        </Grid>
                        <StriimForm values={values} formRef={formRef} onSubmit={handleSubmit}>
                            <Grid container display={"flex"} flexDirection={"column"} gap={2} mb={2}>
                                <StriimInputField name="username" label="Username" autoFocus isFormElement />
                                <StriimInputField
                                    name="password"
                                    label="Password"
                                    type="password"
                                    previewPassword
                                    isFormElement
                                />
                            </Grid>
                            {errorMessage?.length ? (
                                <StriimMessageBox
                                    type="error"
                                    data-test-id={"login-error-message"}
                                    text={errorMessage}
                                    customCardStyles={classes.messageBox}
                                />
                            ) : null}
                            <Grid container display={"flex"} flexDirection={"column"} gap={2} mt={5} mb={2.75}>
                                <StriimButton
                                    type="submit"
                                    variant="primary"
                                    sx={classes.button}
                                    data-test-id="signin-button"
                                    onClick={handleSubmit}
                                    disabled={isLoading}
                                >
                                    Sign In
                                </StriimButton>
                                <Grid sx={classes.separatorContainer}>
                                    <StriimTypography variant="caption" sx={classes.separator}>
                                        or
                                    </StriimTypography>
                                </Grid>
                                <StriimButton
                                    variant="secondary"
                                    sx={classes.button}
                                    onClick={handleLoginWithSSO}
                                    disabled={isLoading}
                                >
                                    Use SSO
                                </StriimButton>
                            </Grid>
                        </StriimForm>

                        {ssoErrorMessage?.length ? (
                            <StriimMessageBox
                                type="error"
                                data-test-id={"sso-error-message"}
                                text={ssoErrorMessage}
                                customCardStyles={{ ...classes.messageBox }}
                            />
                        ) : null}
                    </Grid>
                </Grid>
                <Grid container item xs={6} sx={classes.logoContainer}>
                    <Box sx={classes.logoBackground} />
                    <Grid sx={classes.footer}>
                        <StriimTypography variant="caption3" sx={{ color: "primary.300" }}>
                            Copyright © 2012-{DATE} Striim, Inc. |{" "}
                        </StriimTypography>
                        <StriimTypography variant={"caption3"} sx={{ color: "primary.300" }}>
                            <StriimLink href="https://www.striim.com/privacy-policy/" target="_blank" sx={classes.link}>
                                Privacy policy
                            </StriimLink>
                        </StriimTypography>
                    </Grid>
                </Grid>
            </Grid>
        </Fade>
    );
};

const Login = () => {
    const useInitializedRef = useRef(false);
    useReactHeader({ title: "Login" });

    useEffect(() => {
        if (useInitializedRef.current) {
            return;
        }
        App.getRegion("header").empty();
        useInitializedRef.current = true;
    }, []);

    return <LoginComponent />;
};

export default Login;
