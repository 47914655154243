const styles = {
    container: {
        padding: ({ spacing }) => spacing(3),
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale["200"]}`
    },
    subHeading: {
        paddingTop: ({ spacing }) => spacing(1),
        color: ({ palette }) => `${palette.greyscale["700"]} !important`,
        display: "block"
    },
    titleContainer:{
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center"
    }
};

export default styles;
