import React from "react";
// @ts-ignore
import { StriimModal, StriimTypography } from "@striim/striim-ui";

interface EmbeddingGeneratorDeleteModal {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

export default function EmbeddingGeneratorDeleteModal({ open, onCancel, onConfirm }: EmbeddingGeneratorDeleteModal) {
    return (
        <StriimModal
            size="small"
            variant="error"
            isVisible={open}
            centerContent
            autoHeight
            onCancel={onCancel}
            onConfirm={onConfirm}
            titleContent={
                <StriimTypography variant="h2">Are you sure want to delete vector embeddings generator?</StriimTypography>
            }
            confirmContent="Delete"
            confirmButtonProps={{ "data-test-id": "delete-embedding-generator-confirm-button" }}
            dialogProps={{ "data-test-id": "delete-embedding-generator-modal" }}
        >
            <StriimTypography variant="body4">
                Please proceed to delete your selected vector embeddings generator. This action cannot be undone.
            </StriimTypography>
        </StriimModal>
    );
}
