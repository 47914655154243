import React, { useMemo } from "react";
import { FieldArray } from "formik";
import { FormHelperText, Grid, InputLabel, ListItemText } from "@mui/material";
import { StriimButton, StriimInputField, StriimToggleButton, StriimListItem, StriimList } from "@striim/striim-ui";
import FileBrowser from "../filebrowser/filebrowser";
import styles from "./json-input.styles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { StriimIconButton } from "@striim/striim-ui-v2";

const GetInputControl = props => {
    const { type, name, elem, setFieldValue, enabled, error } = props;
    let value = elem?.value;
    const [formattedOptions, endType] = useMemo(() => {
        let formattedOptions,
            endType = type;
        if (Array.isArray(type)) {
            formattedOptions = type.map(option => ({ label: option, value: option }));
            endType = "ARRAY";
        }
        return [formattedOptions, endType];
    }, [type]);
    switch (endType) {
        case "TEXT":
            return <StriimInputField error={error} disabled={!enabled} isFormElement name={name} />;
        case "PASSWORD":
            return <StriimInputField error={error} disabled={!enabled} isFormElement name={name} type="password" />;
        case "TOGGLE":
            if (!value || typeof value === "number") {
                value = false;
            }
            if (typeof value === "string") {
                if (!["true", "false"].includes(value)) {
                    value = false;
                } else {
                    value = value === "true";
                }
            }
            return (
                <StriimToggleButton
                    disabled={!enabled}
                    checked={value}
                    onChange={e => {
                        setFieldValue(name, e.target.checked);
                    }}
                    name={name}
                />
            );
        case "FILE":
            return (
                <FileBrowser
                    value={value}
                    error={error}
                    enabled={enabled}
                    onChange={value => {
                        setFieldValue(name, value);
                    }}
                />
            );
        case "ARRAY":
            return (
                <StriimInputField
                    select
                    onChange={val => {
                        setFieldValue(name, val.value);
                    }}
                    error={error}
                    value={{ label: value, value: value }}
                    SelectProps={{
                        options: formattedOptions,
                        isClearable: false,
                        menuPosition: "fixed",
                        components: {
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            CrossIcon: () => null
                        }
                    }}
                />
            );
        default:
            return <StriimInputField disabled={!enabled} isFormElement name={name} />;
    }
};

function JsonInput(props) {
    const { name, values, UIConfig, setFieldValue, enabled, error } = props;
    const { options, allowCustom, useTextInputForPropertyField, propertyLabel, valueLabel } = UIConfig;
    const addFieldHidden = useMemo(() => {
        return values[name] && values[name]?.length === Object.keys(options).length && !allowCustom;
    }, [allowCustom, name, values, options]);

    return (
        <FieldArray
            name={name}
            render={arrayHelpers => {
                return (
                    <StriimList>
                        {(!values[name] || values[name]?.length === 0) && (
                            <StriimListItem
                                sx={styles.noItemsMessage}
                                divider
                                data-test-id={"property-list-item-no-values"}
                                text='Please click the "Add Property" button to start adding new Properties'
                            />
                        )}
                        {values[name] &&
                            values[name].length > 0 &&
                            values[name]?.map((elem, index) => (
                                <Row
                                    values={values}
                                    name={name}
                                    key={index}
                                    enabled={enabled}
                                    arrayHelpers={arrayHelpers}
                                    options={options}
                                    elem={elem}
                                    allowCustom={allowCustom}
                                    index={index}
                                    setFieldValue={setFieldValue}
                                    useTextInputForPropertyField={useTextInputForPropertyField}
                                    propertyLabel={propertyLabel}
                                    valueLabel={valueLabel}
                                    error={error}
                                />
                            ))}

                        <StriimButton
                            disabled={!enabled}
                            hidden={addFieldHidden}
                            onClick={() => {
                                if (
                                    values[name] &&
                                    values[name].length === Object.keys(options).length &&
                                    !allowCustom
                                ) {
                                    return;
                                }
                                arrayHelpers.push({ key: "", value: "" });
                            }}
                        >
                            Add Property
                        </StriimButton>
                    </StriimList>
                );
            }}
        ></FieldArray>
    );
}
function Row({
    values,
    name,
    elem,
    index,
    options,
    enabled,
    arrayHelpers,
    allowCustom,
    setFieldValue,
    useTextInputForPropertyField,
    propertyLabel,
    valueLabel,
    error
}) {
    const type = options[elem.key];
    const selectedOptions = useMemo(() => {
        return values[name]?.map(val => val?.key);
    }, [values, name]);

    const availableOptions = useMemo(() => {
        return Object.keys(options)
            ?.filter(option => !selectedOptions?.includes(option))
            ?.map(option => ({ label: option, value: option }));
    }, [options, selectedOptions]);

    return (
        <StriimListItem
            data-test-id={elem?.key}
            key={index}
            secondaryAction={
                <StriimIconButton disabled={!enabled} sx={styles.rightSideButton}>
                    <DeleteOutlineOutlinedIcon onClick={() => arrayHelpers.remove(index)} />
                </StriimIconButton>
            }
        >
            <ListItemText sx={{ marginRight: 5 }}>
                <Grid container alignItems={"center"}>
                    <Grid item xs={3} sx={styles.addSmallMargin}>
                        <InputLabel sx={!enabled && styles.disabledLabel}>
                            {propertyLabel ? propertyLabel : "Property"}
                        </InputLabel>
                    </Grid>
                    <Grid item xs={9} sx={styles.addSmallMargin}>
                        {useTextInputForPropertyField ? (
                            <StriimInputField
                                name={`${name}.${index}.key`}
                                id={`data-test-id-${name}`}
                                value={elem.key ? elem.key : null}
                                onChange={val => {
                                    setFieldValue(`${name}.${index}.key`, val);
                                }}
                                disabled={!enabled}
                                data-test-id={`${elem?.key}-key`}
                                error={!!error?.[index]?.key}
                            />
                        ) : (
                            <StriimInputField
                                name={`${name}.${index}.key`}
                                id={`data-test-id-${name}`}
                                value={elem.key ? { label: elem.key, value: elem.key } : null}
                                select
                                error={!!error?.[index]?.key}
                                disabled={!enabled}
                                data-test-id={`${elem?.key}-key`}
                                SelectProps={{
                                    options: availableOptions,
                                    isClearable: false,
                                    components: {
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                        CrossIcon: () => null
                                    },
                                    selectVariant: allowCustom ? "creatable" : "select",
                                    formatCreateLabel: val => {
                                        return <>New: {val} </>;
                                    }
                                }}
                                onChange={val => {
                                    setFieldValue(`${name}.${index}.key`, val.value);
                                    setFieldValue(
                                        `${name}.${index}.value`,
                                        options[val.value] === "TOGGLE" ? "false" : ""
                                    );
                                }}
                            />
                        )}
                        <FormHelperText className="Mui-error" sx={styles.formErrorText}>
                            {error?.[index]?.key}
                        </FormHelperText>
                    </Grid>
                </Grid>

                <Grid container alignItems="center">
                    <Grid item xs={3} sx={styles.addSmallMargin}>
                        <InputLabel sx={!enabled && styles.disabledLabel}>
                            {valueLabel ? valueLabel : "Value"}
                        </InputLabel>
                    </Grid>
                    <Grid xs={9} item sx={styles.addSmallMargin} data-test-id={`${elem?.key}-value`}>
                        <GetInputControl
                            error={!!error?.[index]?.value}
                            {...{
                                type: type,
                                name: `${name}.${index}.value`,
                                elem,
                                setFieldValue,
                                enabled
                            }}
                        />
                    </Grid>
                    <FormHelperText className="Mui-error" sx={styles.formErrorText}>
                        {error?.[index]?.value}
                    </FormHelperText>
                </Grid>
            </ListItemText>
        </StriimListItem>
    );
}

export default JsonInput;
