import React, { useMemo } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Grid, Box, SvgIcon } from "@mui/material";
import { StriimTypography, StriimLink, StriimChip, StriimTooltip } from "@striim/striim-ui-v2";

import SentinelTableCommon from "./sentinel-table-common";
import { InfoCircle } from "../../../../../generic/icon/customIcons";
import { styles } from "./sentinel-table.styles";
import { Colors, EmptyBar } from "./sentinel-table-utils";
import { getHighestAmount } from "./utils";
import WithTooltip from "../../../../../generic/tooltip/tooltip";

const SentinelTableDynamic = ({ title, tableData, filteredTableData, setFilteredTableData, showProgress, timeRange, setSelectedSentinel }) => {
    const highestAmount = useMemo(() => getHighestAmount(filteredTableData, true), [filteredTableData]);
    const sdiInfo = tableData.find(tab => tab.sdi);
    const sdiLabel = sdiInfo?.sdi || "";

    const tableColumns = useMemo(
        () => [
            {
                headerName: "Sentinel Name",
                headerClass: "dataTableHeader",
                field: "name",
                initialWidth: 370,
                flex: 0.3,
                suppressMovable: true,
                resizable: true,
                cellRenderer: ({ value, data }) => WithTooltip(
                    <StriimTypography color="greyscale.900" variant="body4" sx={styles.nameRowLabel}>
                        <StriimLink href="" onClick={() => setSelectedSentinel({ label: value, value })}>
                            <Box component="span" sx={styles.longValueWrap}>
                                {value}
                            </Box>
                        </StriimLink>

                        {data.disabled && (
                            <StriimChip hideAvatar type="tag-chip" variant="default" label="DETECTION DISABLED" />
                        )}
                    </StriimTypography>,
                    value
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: "Events Processed",
                headerClass: "dataTableHeader",
                field: "processedEvents",
                flex: 0.2,
                suppressMovable: true,
                resizable: true,
                sortable: true,
                headerComponent: () => (
                    <Box sx={styles.customHeader2Rows}>
                        <Box sx={styles.sensitiveDataLabelTop}>
                            Events Processed
                        </Box>
                    </Box>
                ),
                cellRenderer: ({ value }) => (
                    <StriimTypography color="greyscale.700" variant="body4">
                        {value}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: "Events W/ Sensitive Data",
                headerClass: "dataTableHeader",
                field: "sensitiveData",
                initialWidth: 250,
                flex: 0.2,
                suppressMovable: true,
                resizable: true,
                headerComponent: () => (
                    <Box sx={styles.customHeader2Rows}>
                        <Box sx={styles.sensitiveDataLabelTop}>
                            Events W/ Sensitive Data
                        </Box>
                    </Box>
                ),
                cellRenderer: ({ value }) => (
                    <StriimTypography color="greyscale.700" variant="body4">
                        {value}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: sdiLabel,
                headerClass: "dataTableHeader",
                field: "eventsWithSDI",
                initialWidth: 300,
                suppressMovable: true,
                resizable: true,
                headerComponent: () => (
                    <Box sx={styles.customHeader2Rows}>
                        <Box sx={styles.sensitiveDataLabelTop}>
                            <span style={{ ...styles.sensitiveDataSquare, backgroundColor: Colors.Identifier, paddingRight: "11px" }}></span>
                            {`Events W/ ${sdiLabel}`}
                            <SvgIcon component={InfoCircle} sx={styles.infoCircle} />
                        </Box>
                        {highestAmount !== 0 && (
                            <>
                                <Box sx={styles.sensitiveDataLabelBottom}>
                                    <StriimTypography color="greyscale.900" variant="caption4">
                                        0
                                    </StriimTypography>
                                    <StriimTypography color="greyscale.900" variant="caption4">
                                        {highestAmount / 2}
                                    </StriimTypography>
                                    <StriimTypography
                                        color="greyscale.900"
                                        variant="caption4"
                                        sx={styles.sensitiveDataLastValue}
                                    >
                                        {highestAmount}
                                    </StriimTypography>
                                </Box>
                                <Box sx={styles.lineSpanWrapper}>
                                    <Box component="span" sx={styles.lineSpan} />
                                    <Box component="span" sx={styles.lineSpan} />
                                    <Box component="span" sx={styles.lineSpan} />
                                </Box>
                            </>
                        )}
                    </Box>
                ),
                cellRenderer: ({ value, data }) => {
                    const SDIValue = value?.eventsWithSDI || 0;

                    if (SDIValue === 0) return <EmptyBar />;

                    return (
                        <StriimTooltip
                            slotProps={{ tooltip: { sx: styles.tooltip } }}
                            title={
                                <Box sx={styles.tooltipWrapper}>
                                    <StriimTypography variant="caption4" fontFamily="Inter" sx={styles.tooltipHeading}>
                                        {`LAST ${timeRange}`}
                                    </StriimTypography>
                                    <Box sx={styles.tooltipLabel}>
                                        <Box component="span" sx={styles.tooltipLabelTitle}>
                                            <span
                                                style={{ ...styles.tooltipSquare, backgroundColor: Colors.Identifier }}
                                            ></span>
                                            <StriimTypography
                                                variant="caption3"
                                                fontFamily="Inter"
                                                color="greyscale.700"
                                            >
                                                Sensitive Data W/ Identifier
                                            </StriimTypography>
                                        </Box>

                                        <Box component="span">
                                            <StriimTypography variant="caption3" fontFamily="Inter" color="#1E1E5C">
                                                {SDIValue}
                                            </StriimTypography>
                                        </Box>
                                    </Box>

                                    <Box sx={styles.tooltipLabel}>
                                        <Box component="span" sx={styles.tooltipLabelTitle}>
                                            <span
                                                style={{ ...styles.tooltipSquare, backgroundColor: Colors.Sensitive }}
                                            ></span>
                                            <StriimTypography
                                                variant="caption3"
                                                fontFamily="Inter"
                                                color="greyscale.700"
                                            >
                                                Events W/ Sensitive Data
                                            </StriimTypography>
                                        </Box>

                                        <Box component="span">
                                            <StriimTypography variant="caption3" fontFamily="Inter" color="#1E1E5C">
                                                {value?.eventsWithSensitiveData}
                                            </StriimTypography>
                                        </Box>
                                    </Box>

                                    <Box sx={styles.tooltipLabel}>
                                        <Box component="span" sx={styles.tooltipLabelTitle}>
                                            <span
                                                style={{
                                                    ...styles.tooltipSquare,
                                                    backgroundColor: Colors.NonSensitive
                                                }}
                                            ></span>
                                            <StriimTypography
                                                variant="caption3"
                                                fontFamily="Inter"
                                                color="greyscale.700"
                                            >
                                                Events W/O Sensitive Data
                                            </StriimTypography>
                                        </Box>

                                        <Box component="span">
                                            <StriimTypography variant="caption3" fontFamily="Inter" color="#1E1E5C">
                                                {value?.eventsWithoutSensitiveData}
                                            </StriimTypography>
                                        </Box>
                                    </Box>
                                </Box>
                            }
                        >
                            <Grid container sx={styles.chartWrapper}>
                                <ResponsiveContainer width="100%" height={20}>
                                    <BarChart
                                        height={20}
                                        data={[
                                            {
                                                dynamicData: SDIValue,
                                                sensitiveData: value?.eventsWithSensitiveData,
                                                nonSensitiveData: value?.eventsWithoutSensitiveData
                                            }
                                        ]}
                                        layout="vertical"
                                    >
                                        <XAxis hide type="number" domain={[0, 300]} />
                                        <YAxis hide type="category" />

                                        <Bar
                                            dataKey="dynamicData"
                                            stackId="single-stack"
                                            animationDuration={0}
                                            fill={Colors.Identifier}
                                        />
                                        <Bar
                                            dataKey="sensitiveData"
                                            stackId="single-stack"
                                            animationDuration={0}
                                            fill={Colors.Sensitive}
                                        />
                                        <Bar
                                            dataKey="nonSensitiveData"
                                            stackId="single-stack"
                                            animationDuration={0}
                                            fill={Colors.NonSensitive}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                                <StriimTypography
                                    variant="caption4"
                                    fontFamily="Inter"
                                    color="greyscale.700"
                                    paddingLeft={0.6}
                                >
                                    {SDIValue || data.sensitiveData}
                                </StriimTypography>
                            </Grid>
                        </StriimTooltip>
                    );
                },
                headerCheckboxSelection: false,
                checkboxSelection: false
            }
        ],
        [tableData, filteredTableData]
    );

    return (
        <SentinelTableCommon
            tableColumns={tableColumns}
            title={title}
            tableData={tableData}
            filteredTableData={filteredTableData}
            setFilteredTableData={setFilteredTableData}
            showSentinelsFilter
            headerHeight={65}
            manyRowsHeader
            showProgress={showProgress}
        />
    );
};

export default SentinelTableDynamic;
