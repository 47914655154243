import _ from "underscore";
import $ from "jquery";
import Backbone from "backbone";
import App from "app";
import api from "core/api/api";
import queryParameters from "./queryParameters.json";
import stringUtils from "core/utils/string-utils";

const queriesQueue = [];
let lock = false;

//throttle starts, so we dont flood the consoleQueue -> websocket
setInterval(function() {
    if (lock === false) {
        lock = true;
        var queryToStart = queriesQueue.shift();
        if (!queryToStart) {
            lock = false;
        }
        if (queryToStart) {
            if (!queryToStart.started || (queryToStart.state && !queryToStart.state.isDisabled())) {
                queryToStart.start().always(function() {
                    lock = false;
                });
            }
        } else {
            lock = false;
        }
    }
}, 100);

function getDataPoints(data) {
    if (!data || !data.xadded) {
        return "";
    }

    return data.xadded.map(function(item) {
        return item.data.dataPoints;
    });
}

const QueryInstance = function(query) {
    this.uuid = query.uuid.uuidstring || query.uuid;
    this.projectionFields = query.projectionFields;
    this.key = this.uuid + ":data";
    this.name = query.name;
    this.started = false;
    this.cache = {};
    this._isDestroyed = false;

    App.vent.on(
        this.key,
        function(data) {
            this.cleanupAndStop();
            if (_.isEqual(getDataPoints(data), getDataPoints(this.previousData)) || this._isDestroyed) {
                return;
            }
            this.previousData = data;
            this.trigger("onDataLoaded", data);
        }.bind(this)
    );
};

QueryInstance.prototype = {
    start: function() {
        return api.startAHQuery(this.uuid);
    },
    cleanupAndStop: function() {
        if (this.state && this.state.isDisabled()) {
            this.pollDeferred.resolve();
        } else {
            this.stop().then(
                function() {
                    this.pollDeferred.resolve();
                }.bind(this)
            );
        }
    },
    stop: function() {
        return api.stopNamedQuery(this.uuid);
    },
    destroy: function() {
        this._isDestroyed = true;
        return api.stopAHQuery(this.uuid).always(
            function() {
                App.vent.off(this.key);
            }.bind(this)
        );
    },
    execute: function(timeout, state) {
        var deferred = $.Deferred();
        this.pollDeferred = deferred;
        this.state = state;
        queriesQueue.push(this);
        return deferred.promise();
    }
};

_.extend(QueryInstance.prototype, Backbone.Events);

/**
 * Execute a tql query and return a promise with the query result
 * @param tql
 * @param timeoutInterval
 * @returns {*|{then, fail, end}}
 */
export function runTql(tql, storeName, nsName) {
    return new Promise(async (resolve, reject) => {
        try {
            let networkResponse = await fetch(`/wactions/search?name=${nsName}.${storeName}&query=` + encodeURIComponent(tql));
            let data = await networkResponse.json();
            resolve(data);
        } catch (e) {
            reject(e);
            throw e;
        }
    });
}


/**
 * Execute a named query
 * @param uuid
 * @param params
 * @returns {*|{then, fail, end}}
 */
export function getNamedQuery(uuid, params) {
    params = params || {};
    var $def = new $.Deferred();
    api.prepareParameterizedQuery(uuid, params)
        .then(function(instance) {
            setTimeout(()=>{
                $def.resolve(new QueryInstance(instance));
            }, 300)
        })
        .fail(function(e) {
            $def.reject(e);
        });
    return $def.promise();
}

/**
 *
 * @param uuid
 * @param filterModel
 * @returns {*|{then, fail, end}}
 */
export function getFilteredQuery(uuid, filterModel) {
    var $def = new $.Deferred();

    var filters = [];
    if (!filterModel) {
        filterModel = {};
    }

    for (var filter in filterModel) {
        if (filterModel.hasOwnProperty(filter)) {
            if (filter === queryParameters.Search) {
                var search = filterModel[queryParameters.Search];
                filters.push(["anyattrlike", stringUtils.ensureEndsWith(search, "%")]);
            } else {
                var filterValue = filterModel[filter];
                if ($.isPlainObject(filterValue)) {
                    filters.push(["rangefilter", filter, filterValue.from.toString(), filterValue.to.toString()]);
                } else {
                    filters.push(["attrfilter", filter, filterValue.toString()]);
                }
            }
        }
    }

    api.prepareFilteredQuery(uuid, filters)
        .then(function(instance) {
            $def.resolve(new QueryInstance(instance));
        })
        .fail(function(e) {
            $def.reject(e);
        });
    return $def.promise();
}
