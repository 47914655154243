export const styles = {
    sidebarContainer: {
        height: "100%",
        transition: "width 0.4s ease",
        backgroundColor: "greyscale.50",
        padding: ({ spacing }) => spacing(3),
        borderTopRightRadius: ({ spacing }) => spacing(1),
        borderBottomRightRadius: ({ spacing }) => spacing(1),
        overflowX: "hidden",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "greyscale.200",
        borderLeft: "none"
    },
    icon: {
        fontSize: 20,
        marginBottom: 1
    },
    content: {
        wordBreak: "break-all"
    }
};
