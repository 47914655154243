import React, { createContext, useState, Dispatch, SetStateAction, useEffect } from "react";
import { SidebarStates, SidebarType } from "./components/sidebar.consts";
import { getSentinelAppDetails, getSentinelDetails } from "./service/sentinel-monitor-utils";
import { RunningAppsWithSentinelsProps } from "./service/sentinel-monitor.types";

type SentinalByActionTypes = {
    action: string;
    importance: string;
    apps: string;
};

type SentinelMonitorContextProps = {
    sidebarState: string;
    setSidebarState: Dispatch<SetStateAction<string>>;
    sentinalByAction: SentinalByActionTypes;
    setSentinalByAction: Dispatch<SetStateAction<SentinalByActionTypes>>;
    formatSentinelDetails: (data) => void;
    SentinelAppTable: Array<any>;
    SensitiveDataTable: Array<any>;
    RunningAppDetails: RunningAppsWithSentinelsProps;
    setRunningAppDetails: Dispatch<SetStateAction<RunningAppsWithSentinelsProps>>;
    appSentinelMapping: Array<any>;
    selectedApps: string[];
    setSelectedApps: React.Dispatch<SetStateAction<string[]>>;
    selectedDateRange: { from: number; to: number } | null;
    setSelectedDateRange: React.Dispatch<SetStateAction<{ from: number; to: number } | null>>;
    isSelectedAppWithoutSentinel: boolean;
    setIsSelectedAppWithoutSentinel: Dispatch<SetStateAction<boolean>>;
};

export const SentinelMonitorContext = createContext<SentinelMonitorContextProps>(null);

type SentinelMonitorProps = {
    children: JSX.Element;
};

const SentinelMonitor = ({ children }: SentinelMonitorProps) => {
    const [sidebarState, setSidebarState] = useState<SidebarType>(SidebarStates.OPENED);
    const [sentinalByAction, setSentinalByAction] = useState<SentinalByActionTypes>({
        action: null,
        importance: null,
        apps: null
    });

    const [SentinelAppTable, setSentinelAppTable] = useState([]);
    const [SensitiveDataTable, setSensitiveDataTable] = useState([]);
    const [RunningAppDetails, setRunningAppDetails] = useState();
    const [appSentinelMapping, setAppSentinelMapping] = useState([]);
    const [selectedApps, setSelectedApps] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState<{ from: number; to: number } | null>(null);
    const [isSelectedAppWithoutSentinel, setIsSelectedAppWithoutSentinel] = useState<boolean>(false);

    const formatSentinelDetails = data => {
        const result = data.sentinels.reduce((acc, sentinel) => {
            const { appName, name } = sentinel;
            if (!acc[appName]) {
                acc[appName] = [];
            }
            acc[appName].push(name);
            return acc;
        }, {});

        const AppsTable = getSentinelDetails(data);
        const SensitiveDataTable = getSentinelAppDetails(data);
        setSentinelAppTable(AppsTable);
        setSensitiveDataTable(SensitiveDataTable);
        setAppSentinelMapping(result);
    };

    useEffect(() => {
        setSelectedDateRange(null);
        // Check if at least one selected app exists in appSentinelMapping
        if (!selectedApps) return;
        const hasMapping = selectedApps.some(app => app in appSentinelMapping);
        setIsSelectedAppWithoutSentinel(!hasMapping);
    }, [selectedApps]);

    return (
        <SentinelMonitorContext.Provider
            value={{
                sidebarState,
                setSidebarState,
                sentinalByAction,
                setSentinalByAction,
                formatSentinelDetails,
                SentinelAppTable,
                SensitiveDataTable,
                RunningAppDetails,
                setRunningAppDetails,
                appSentinelMapping,
                isSelectedAppWithoutSentinel,
                setIsSelectedAppWithoutSentinel,
                selectedApps,
                setSelectedApps,
                selectedDateRange,
                setSelectedDateRange
            }}
        >
            {children}
        </SentinelMonitorContext.Provider>
    );
};

export default SentinelMonitor;
