import React, { useEffect, useRef, useState } from "react";
// @ts-ignore
import { StriimTypography, StriimModal } from "@striim/striim-ui";
import EmbeddingGeneratorDetailsForm from "./embedding-generator-details-form";

interface EmbeddingGeneratorDetailsModal {
    open: boolean;
    initialValues: any;
    onSave: (formValues: any) => void;
    onCancel: () => void;
}

const styles = {
    dialog: {
        ".MuiDialogActions-root": {
            borderTop: "1px solid",
            borderColor: "greyscale.200"
        }
    }
};

export default function EmbeddingGeneratorDetailsModal({
    open,
    initialValues,
    onSave,
    onCancel
}: EmbeddingGeneratorDetailsModal) {
    const [isValid, setIsValid] = useState(false);
    const formValues = useRef();
    const [savingInProgress, setSavingInProgress] = useState(false);

    useEffect(() => {
        setSavingInProgress(false);
    }, [open]);

    const getTitle = (): string =>
        initialValues?.name ? "Edit Vector Embeddings Generator" : "New Vector Embeddings Generator";

    return (
        <StriimModal
            isVisible={open}
            size="medium"
            autoHeight
            titleContent={<StriimTypography variant="h3">{getTitle()}</StriimTypography>}
            dialogProps={{
                "data-test-id": "manage-embedding-generator-modal",
                sx: styles.dialog
            }}
            confirmContent="Save"
            confirmButtonProps={{
                "data-test-id": "manage-embedding-generator-modal-save",
                disabled: !isValid || savingInProgress
            }}
            onConfirm={() => {
                onSave(initialValues);
                setSavingInProgress(true);
            }}
            onCancel={onCancel}
            cancelButtonProps={{
                "data-test-id": "manage-embedding-generator-modal-cancel",
                disabled: savingInProgress
            }}
        >
            <EmbeddingGeneratorDetailsForm
                setIsValid={setIsValid}
                initialValues={initialValues}
                formValues={formValues}
            />
        </StriimModal>
    );
}
