import React, { useState } from "react";
import { Grid } from "@mui/material";
import { styles } from "../guardian.styles";

import AIInfoSection from "./../components/ai-info-section/ai-info-section";
import SelectTables from "../../components/select-tables-component/select-tables-component";

interface GuardianReviewStepProps {
    apps: AppProps[];
    tableData: AppProps[];
    selectedRows: AppProps[];
    setSelectedRows: React.Dispatch<React.SetStateAction<AppProps[]>>;
    goToEdit: () => void;
    selectApps: boolean;
}

const GuardianReviewStep = ({
    apps,
    tableData,
    selectedRows,
    setSelectedRows,
    goToEdit,
    selectApps
}: GuardianReviewStepProps) => {
    const hideAIInfo = localStorage.getItem("hideAIInfo") === "true";
    const [showAIInfo, setShowAIInfo] = useState<boolean>(!hideAIInfo);

    return (
        <Grid container sx={styles.guardianContentContainer}>
            {showAIInfo && <AIInfoSection setShowAIInfo={setShowAIInfo} showTopBorder={false} />}

            <Grid container sx={styles.guardianContentContainer} p={2}>
                <SelectTables
                    selectable={false}
                    apps={apps}
                    tableData={tableData}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    goToEdit={goToEdit}
                    showEditAppsIcon={!!selectApps}
                />
            </Grid>
        </Grid>
    );
};

export default GuardianReviewStep;
