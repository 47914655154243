import api from "core/api/api";

function getDatabaseType(adapterName) {
    switch (adapterName) {
        case "MysqlReader":
            return "mysql";
        case "OracleReader":
            return "oracle";
        case "MSSqlReader":
            return "mssql";
        default: {
            return null;
        }
    }
}

function loadColumns(
    databaseType,
    adapterName,
    username,
    password,
    tables,
    connectionURL,
    database,
    agentName,
    objectfqn
) {
    return new Promise((resolve, reject) => {
        switch (adapterName) {
            case "MysqlReader":
                break;
            case "OracleReader":
                break;
            case "MSSqlReader":
                tables = tables.map(t => {
                    t = database + "." + t;
                });
                if (database) {
                    connectionURL = connectionURL + ";" + database;
                }
                break;
            default: {
                reject(`Can't find method name for {properties.adapterName} adapter`);
                return;
            }
        }
        tables =
            "[" +
            tables
                .map(t => {
                    return '"' + t + '"';
                })
                .join(",") +
            "]";

        let apidef = api.getDatabaseTableColumns(
            databaseType,
            username,
            password,
            connectionURL,
            tables,
            agentName,
            true,
            objectfqn
        );
        apidef.then(function(data) {
            try {
                data = JSON.parse(data);
                if (data.result === true) {
                    let result = JSON.parse(data.message);
                    resolve(result);
                } else {
                    reject(data.message);
                }
            } catch (ex) {
                console.error(ex);
                reject(ex.message);
            }
        });
        apidef.fail(function(e) {
            reject(e.message);
        });
    });
}

/**
 *
 * @param databaseType
 * @param adapterName
 * @param username
 * @param password
 * @param tables
 * @param connectionURL
 * @param database
 * @param agentName
 * @param allowFallbackToTablesField - when true then when API method failed, the tables are returned from tables field
 * @returns {Promise<any>}
 */
function loadTables(
    databaseType,
    adapterName,
    username,
    password,
    tables,
    connectionURL,
    database,
    agentName,
    allowFallbackToTablesField,
    metaobjectID
) {
    return new Promise((resolve, reject) => {
        // split tables by coma, semicolon or new line
        let tableNames = (tables || "").split(/,|;|\n/).map(t => {
            return t.trim();
        });

        switch (adapterName) {
            case "MysqlReader":
                if (database) {
                    tableNames = tableNames.map(t => {
                        return database + "." + t;
                    });
                }
                break;
            case "OracleReader":
                break;
            case "MSSqlReader":
                if (database) {
                    tableNames = tableNames.map(t => {
                        t = database + "." + t;
                    });
                    connectionURL = connectionURL + ";" + database;
                }
                break;
            default: {
                reject(`Can't find method name for {properties.adapterName} adapter`);
                return;
            }
        }
        let tableNamesStr = tableNames.join(";");
        const connectionParams = {
            UserName: username,
            Password: password,
            ConnectionURL: connectionURL
        };
        let apidef = api.getDatabaseTables(
            databaseType,
            connectionParams,
            tableNamesStr,
            agentName,
            true,
            metaobjectID
        );
        apidef.then(function(data) {
            try {
                data = JSON.parse(data);
                if (data.result === true) {
                    let result = JSON.parse(data.message);
                    if (!result || result.length === 0) {
                        if (allowFallbackToTablesField) {
                            resolve(tableNames);
                        } else {
                            reject("No tables found. Please note that table name mask is case sensitive.");
                        }
                    } else {
                        resolve(result);
                    }
                } else {
                    reject(data.message);
                }
            } catch (ex) {
                console.error(ex);
                reject(ex.message);
            }
        });
        apidef.fail(function(e) {
            reject(e.message);
        });
    });
}

/** @function loadTablesForMetaObject
 * load list
 *  @param {object} metaobject - metaobject with databse adapter
 *  @returns {array} - list of tables
 */
export default function loadTablesForMetaObject(metaobject, agentName, includeColumns) {
    return new Promise((resolve, reject) => {
        if (!metaobject) {
            reject("Metaobject is required");
            return;
        }

        let adapter = metaobject.adapter;
        if (!adapter) {
            reject("Adapter not set");
            return;
        }

        let properties = adapter.properties;
        if (!properties || !properties.adapterName) {
            reject("Adaptername is required");
            return;
        }

        let adapterName = properties.adapterName;
        let password = properties.Password && properties.Password.encrypted;

        let databaseType = getDatabaseType(adapterName);
        if (!databaseType) {
            reject("Can't map adapter type");
            return;
        }

        const allowFallbackToTablesField = false;

        loadTables(
            databaseType,
            adapterName,
            properties.Username,
            password,
            properties.Tables,
            properties.ConnectionURL,
            properties.Database,
            agentName,
            allowFallbackToTablesField,
            metaobject.id
        )
            .then(tablesList => {
                if (includeColumns && tablesList.length > 0) {
                    loadColumns(
                        databaseType,
                        adapterName,
                        properties.Username,
                        password,
                        tablesList,
                        properties.ConnectionURL,
                        properties.Database,
                        agentName,
                        metaobject.id
                    )
                        .then(columnsList => {
                            resolve(columnsList);
                        })
                        .catch(colMessage => {
                            reject(colMessage);
                        });
                } else {
                    resolve(tablesList);
                }
            })
            .catch(message => {
                reject(message);
            });
    });
}
