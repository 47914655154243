import Backbone from "backbone";
import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import BaseEditor from "app/components/common/editor/meta-object-editors/base";

var Module = function (metadata_object, options) {
    options = options || {};
    options.displayFields = options.displayFields || ["nsName", "fields"];
    BaseEditor.apply(this, arguments);
};

Module.prototype = _.extend({}, BaseEditor.prototype, {
    addCustomFields: function () {
        this.fieldMapper.generated = UIControl.Toggle.create();
        this.fieldMapper.name = UIControl.TextField.create();
        this.fieldMapper.fields = UIControl.FieldsList.extend({
            hideLabel: true,
        }).create();
    },
});

export default Module;
