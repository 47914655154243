import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing, palette, shadows }) => {
    return {
        wrapper: {
            marginInline: 20,
            marginTop: 80
        },
        wrapperInner: {
            marginBottom: spacing(1)
        },
        tooltipWrapper: {
            boxShadow: shadows[24],
            "& > div": {
                overflow: "hidden"
            }
        },
        button: {
            height: 40,
            whiteSpace: "nowrap"
        },
        separator: {
            width: 1,
            height: 24,
            marginLeft: spacing(3),
            marginRight: spacing(2),
            backgroundColor: palette.gray2
        },
        searchByAppNameContainer: {
            flexGrow: 1
        },
        searchByStatus: {
            minWidth: 120,
            flexGrow: 1
        },
        searchByNamespace: {
            minWidth: 160,
            flexGrow: 1
        },
        searchBySource: {
            minWidth: 120,
            flexGrow: 1
        },
        iconButtonRoot: {
            width: 24,
            height: 24
        },
        container: {
            marginBottom: 32,
            flexWrap: "nowrap",
            justifyContent: "left"
        },
        lowerContainer: {
            alignItems: "center",
            flexWrap: "nowrap",
            justifyContent: "left"
        },
        appsHeader: {
            display: "flex",
            justifyContent: "space-between"
        },
        showMoreButton: {
            color: "#00A7E5"
        },
        column: {
            marginRight: 20
        },
        nowrap: {
            flexWrap: "nowrap"
        },
        heightFitContent: {
            height: "fit-content"
        }
    };
});

export const styles = {
    endAdornmentContainer: {
        backgroundColor: 'transparent',
        color: 'greyscale.800',
        border: 'none',
        padding: 0.5,
        "&:hover": {
            backgroundColor: 'transparent',
            border: 'none'
        },
        "&:active": {
            backgroundColor: 'transparent',
            border: 'none',
        }
    },
    iconButton: {
        width: 20,
        height: 20
    },
    inputField: {
        minWidth: '40px'
    }
}
