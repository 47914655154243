import $ from "jquery";
import _ from "underscore";
import BaseControl from "./../base-control";
import CodeMirror from "cm/lib/codemirror";
import "cm/addon/hint/show-hint";

const Model = BaseControl.Model.extend({
    defaults: {
        value: String
    }
});

const View = BaseControl.View.extend({
    className: "autocomplete",
    template: _.template("<textarea>{{value}}</textarea>"),
    events: {
        click: "activate"
    },

    activate: function(e) {
        e.stopPropagation(true);
    },

    getViewValue: function() {
        return $.trim(this.cm.getValue());
    },

    setViewValue: function() {
        if (this.cm) {
            this.cm.setValue($.trim(this.getValue()));
        }
    },

    setViewEnabled: function() {
        BaseControl.View.prototype.setViewEnabled.apply(this);
        if (this.cm) {
            const enabled = this.getEnabled() && !this.readOnly;
            this.cm.setOption("readOnly", !enabled);
            this.$(".CodeMirror-scroll").toggleClass("disabled", !enabled);
        }
    },

    setColumns: function(columns) {
        this.columns = columns.map(c => (typeof c === "string" ? c : c.id));
    },

    initialize: function(options) {
        this.inputWidth = options.width || 150;
    },

    onRender: function() {
        var _this = this;
        this.cm = CodeMirror.fromTextArea(this.$("textarea")[0], {
            extraKeys: {
                "Ctrl-Space": cm => {
                    cm.showHint({
                        hint: editor => {
                            const word = editor.findWordAt(editor.getCursor());
                            const currentWord = editor.getRange(word.anchor, word.head);
                            var a = _this.model.columns;
                            return {
                                list: this.columns.filter(w => w.indexOf(currentWord.trim()) !== -1),
                                from: word.anchor,
                                to: word.head
                            };
                        }
                    });
                }
            },
            readOnly: this.readOnly,
            scrollbarStyle: "null"
        });

        this.cm.setSize(this.inputWidth, 42); // new size of input striimline (5*spacing + 2px borders
        this.cm.on("beforeChange", function(instance, change) {
            // disallow adding newlines in the following simple way
            const newtext = change.text.join("").replace(/\n/g, ""); // remove ALL \n !
            if ("update" in change)
                // change.origin === "undo" have no update
                change.update(change.from, change.to, [newtext]);
            return true;
        });

        this.cm.on("change", () => {
            this.setValueFromView();
        });

        this.cm.setCursor(this.cm.lineCount(), 0);
        setTimeout(() => {
            this.cm.refresh();
        }, 1);

        BaseControl.View.prototype.onRender.apply(this, arguments);
    },

    refresh: function() {
        this.cm.refresh();
    }
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View
});
