import { action, observable, toJS } from "mobx";
import App from "app";
import api from "core/api/api";
import { COSMOSDB_CONFIG } from "./config";
import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import { saveTarget } from "./modelOperations";
import sanitizer from "app/components/appwizard/component-wizards/wizards/target/base-simple-target/server-error-sanitizer.js";
import growl from "app/components/common/growl";
import utils from "core/utils";
class CosmosdbTargetStore {
    @observable step = 0;
    @observable formRef;
    @observable targetApi;
    @observable targetConfig;
    @observable sourceDatabases = [];
    @observable showOnlyMongoAPI = false;
    @observable isLoading = false;
    @observable validationPassed = false;

    inputStream = null;
    targetModel = null;
    sourceAdapter = null;
    steps = ["Select Cosmos DB API", "Configure Target", "Validate Connection"];
    constructor(appName, templateID) {
        this.appName = appName;
        this.templateID = templateID;
    }

    @action
    getSourceAdapter() {
        let fetch = async () => {
            this.isLoading = true;
            let nsName = this.appName ? this.appName.split(".")[0] : null;
            let appName = this.appName ? this.appName.split(".")[1] : null;
            let appId = metaObjectConverter.getId(nsName, "APPLICATION", appName);
            let appModel = await metaStoreService.findById(appId);
            const sourceObjects = await appModel.getApplicationComponentsByType("SOURCE");
            let streamObjects = await appModel.getApplicationComponentsByType("STREAM");
            let firstStream = streamObjects.at(0);
            this.inputStream = firstStream.get("id");
            const firstSource = sourceObjects.at(0);
            this.sourceAdapter = firstSource.get("adapter");
            const adapterName = this.sourceAdapter.properties?.adapterName;
            if (
                (adapterName === "CosmosDBReader" || adapterName === "MongoCosmosDBReader") &&
                this.sourceAdapter.properties?.Mode === "Incremental"
            ) {
                this.showOnlyMongoAPI = true;
            }
            this.isLoading = false;
        };
        fetch();
    }

    @action
    getSourceTables() {
        const adapterProps = this.sourceAdapter;
        this.sourceDatabases = [];
        let sourceDatabases = new Set();
        if (
            adapterProps &&
            adapterProps.properties &&
            (adapterProps.properties.Tables ||
                adapterProps.properties.collections ||
                adapterProps.properties.Containers)
        ) {
            const tables = adapterProps.properties.Tables
                ? adapterProps.properties.Tables.split(";")
                : adapterProps.properties.collections
                ? adapterProps.properties.collections.split(",")
                : adapterProps.properties.Containers.split(",");
            if (tables) {
                tables.forEach(table => {
                    let chunks = table.split(".");
                    let sourceSchema = null;
                    let targetSchema = null;
                    if (this.targetApi.id === "cassandra") {
                        if (chunks.length === 3) {
                            chunks.shift();
                        }
                        sourceSchema = chunks.join(".");
                        sourceDatabases.add(sourceSchema);
                    } else {
                        if (chunks.length === 3) {
                            chunks.pop();
                            sourceSchema = chunks.join(".");
                        } else {
                            targetSchema = chunks.shift();
                            sourceSchema = targetSchema;
                        }
                        sourceDatabases.add(sourceSchema + ".%");
                    }
                });
            }
            this.sourceDatabases = [...sourceDatabases];
        }
    }

    getPredefinedComponentName() {
        const predefinedComponent = COSMOSDB_CONFIG[this.targetApi.id]["predefinedComponent"];
        const { name } = PredefinedComponentNames[predefinedComponent];
        return name;
    }

    getDefaultTargetName() {
        let componentName = this.getPredefinedComponentName();
        let name = componentName.replace(/[^A-Z0-9]+/gi, "_");
        let shortAppName = utils.getName(this.appName);
        const targetName = `${name}_${shortAppName}_Target`;
        return targetName;
    }

    @action async goToNextStep() {
        if (this.step >= this.steps.length - 1) {
            App.vent.trigger("appwizard:next:disable");
            // Now do all API stuff to create the target

            this.isLoading = true;
            const name = this.getPredefinedComponentName();
            saveTarget(name, this.targetConfig, this.appName, this.inputStream, this.targetApi.id).then(
                () => {
                    this.isLoading = false;
                    App.vent.trigger("appwizard:next:step");
                },
                e => {
                    this.isLoading = false;
                    let msg = "Error saving your target.<br />Please correct the below errors and try again.";
                    if (Array.isArray(e)) {
                        msg += e.join(" <br />");
                    } else {
                        msg += sanitizer.sanitize(e.message);
                    }
                    growl.error(msg);
                }
            );

            return;
        }
        if (this.step + 1 === 2) {
            const { values } = this.formRef.current;
            let isValid = false;
            try {
                const result = await COSMOSDB_CONFIG[this.targetApi.id].validationSchema.validate(toJS(values));
                isValid = true;
            } catch (e) {
                isValid = e.errors ? false : true;
            }
            if (!isValid) {
                this.formRef && this.formRef.current && this.formRef.current.handleSubmit();
                return;
            }
            this.targetConfig = values;
        }
        if (this.step + 1 === 1) {
            COSMOSDB_CONFIG[this.targetApi.id].defaultValues.targetName = this.getDefaultTargetName();
        }
        App.vent.trigger("appwizard:next:enable");
        App.vent.trigger("appwizard:back:enable");
        this.step++;
    }
    @action goToPreviousStep() {
        if (this.step === 0) {
            App.vent.trigger("appwizard:back:disable");
            return;
        }
        App.vent.trigger("appwizard:back:enable");
        App.vent.trigger("appwizard:next:enable");
        if (this.step - 1 === 0) {
            this.targetConfig = null;
            this._disableBack();
        }
        this.step--;
    }

    @action setTargetApi(api) {
        this.targetApi = api;
        this._enableNext();
        this.getSourceTables();
    }

    @action disableStepNavigation() {
        App.vent.trigger("appwizard:back:disable");
        App.vent.trigger("appwizard:next:disable");
    }

    @action setFormRef(formRef) {
        this.formRef = formRef;
    }

    @action _enableNext() {
        App.vent.trigger("appwizard:next:enable");
    }

    @action _disableBack() {
        App.vent.trigger("appwizard:back:disable");
    }

    @action _disableNext() {
        App.vent.trigger("appwizard:next:disable");
    }

    getConnectionApiParams = () => {
        let params = [];
        if (this.targetApi.id === COSMOSDB_CONFIG.cosmos.id) {
            let { serviceEndpoint, port, accessKey } = this.targetConfig;
            let endpoint = `${serviceEndpoint}:${port}`;
            params = [COSMOSDB_CONFIG.cosmos.id, null, accessKey, endpoint, null, false, null];
        }
        if (this.targetApi.id === COSMOSDB_CONFIG.cassandra.id) {
            let { accountEndpoint, keySpace, accountKey, port } = this.targetConfig;
            let username = accountEndpoint.substring(0, accountEndpoint.indexOf("."));
            let url = "jdbc:cassandra://" + accountEndpoint + ":" + port + "/" + keySpace + "?ssl=true";
            params = [COSMOSDB_CONFIG.cassandra.id, username, accountKey, url, null, false, null];
        }
        if (this.targetApi.id === COSMOSDB_CONFIG.mongo.id) {
            let { host, port, username, password } = this.targetConfig;
            let url = `${host}:${port}`;
            params = ["mongocosmos", username, password, url, null, false, null];
        }
        return params;
    };
    checkConnection() {
        this._disableNext();
        this.validating = true;
        let params = this.getConnectionApiParams();
        let ctx = this;
        return new Promise(function(resolve, reject) {
            api.validateConnection(...params).then(
                function(data) {
                    data = JSON.parse(data);
                    let message = data.message;
                    if (data.result === true) {
                        ctx._enableNext();
                        ctx.validationPassed = true;
                        resolve();
                    } else {
                        reject(message);
                    }
                },
                function() {
                    reject("Problem Validating Connection. Please check the logs for detailed information.");
                }
            );
        });
    }
}

export default CosmosdbTargetStore;
