import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing, palette }) => ({
    forwardIcon: {
        marginLeft: spacing(2),
        marginRight: spacing(2),
        color: palette.common.black,
        color: "transparent",
        width: spacing(2),
        height: spacing(2)
    },
    elementIcon: {
        color: "transparent",
        width: spacing(5),
        height: spacing(5),
        borderRadius: spacing(0.75)
    },
    mirrorContainer: {
        position: "relative"
    },
    mirror: {
        position: "absolute",
        width: "10px",
        height: "12px",
        bottom: 0,
        right: 0
    }
}));
