import $ from "jquery";
import _ from "underscore";
import metaStoreService from "core/services/metaStoreService/meta-store-service";

const fieldDataSource = function (type_id, prefix) {
    const deferred = $.Deferred();
    const return_val = [];

    if (!type_id) {
        deferred.resolve(return_val);
        return deferred.promise();
    }

    metaStoreService.findById(type_id).then(function(type) {
        if (!type) {
            deferred.resolve(return_val);
        } else {
            _.each(type.get("fields"), function (field) {
                const id = prefix ? `${prefix}.${field.name}` : field.name;
                const text = prefix ? `${prefix}_${field.name}` : field.name;
                return_val.push({
                    id: id,
                    text: text,
                    description: field.type,
                });
            });
        }

        deferred.resolve(return_val);
    });

    return deferred.promise();
};

const CompositeFieldDataSource = function (metaTypes) {
    const promises = metaTypes.map((metaType) => new fieldDataSource(metaType.type, metaType.prefix));

    return $.when.apply($, promises).then((...args) => {
        const mergedResults = [].concat.apply([], args);
        return _.sortBy(
            _.uniq(mergedResults, (item) => item.text),
            (item) => item.text
        );
    });
};

export { CompositeFieldDataSource };

export default fieldDataSource;
