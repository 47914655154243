export default ({spacing, palette}) => ({
    container: {
        display: "flex",
        flexDirection: "column"
    },
    msgsContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: spacing(2),
        margin: spacing(2),
        whiteSpace: "pre-line",
        overflow: "auto"
    },
    response: {
        width: "100%"
    },
    answer: {
        marginLeft: spacing(2),
        border: `1px solid ${palette.greyscale[200]}`,
        borderRadius: spacing(1),
        padding: spacing(1,2),
        marginBlock: spacing(1),
        wordBreak: "break-word",
        backgroundColor: "white"
    },
    question: {
        marginRight: spacing(2),
        wordBreak: "break-word"
    },
    controlsContainer: {
        display: "flex",
        alignItems: "center",
        gap: spacing(1),
        margin: spacing(1),
        marginBottom: "50px"
    }
});

export const sxStyles = {
    addMsgIconBtn: { height: 40, width: 50, color: "secondary.main" },
    deleteSvg: {
        ".MuiButton-endIcon": {
            "& > svg": {
                fill: "none"
            }
        },
        ".MuiSvgIcon-root": {
            "& > svg": {
                fill: "none"
            }
        }
    }
}