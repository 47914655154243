import Backbone from "backbone";
import Marionette from "marionette";
import $ from "jquery";
import _ from "underscore";
import template from "./master-detail-view.html";

var module = {};
/**
 *
 *
 * To operate, start by pasting the below code and tweaking to your need..
 *



const MasterListItemView = masterDetailView.MasterListItemView.extend({
    template: _.template("<i class=\"fa fa-caret-right\"></i> {{name}}"),
    onRender: function() {
        masterDetailView.MasterListItemView.prototype.onRender.apply(this, arguments);
    }

});
const MasterCollectionView = masterDetailView.MasterCollectionView.extend({
    childView: MasterListItemView
});

const MasterLayoutView = masterDetailView.MasterLayoutView.extend({
    className: "stream-list-master-view",
    template: _.template("<div class=\"master-collection-view\"></div>"),
    regions: {
        'masterCollectionView': '.master-collection-view'
    },
    initialize: function() {},
    onRender: function() {
        this.getRegion('masterCollectionView').show(new MasterCollectionView({
            collection: new Backbone.Collection({name: "source1"}) //replace with your collection.
        }));
        masterDetailView.MasterLayoutView.prototype.onRender.apply(this);
    }
});


const DetailLayoutView = masterDetailView.DetailLayoutView.extend({
    template: _.template("HELLO WORLD"),
    regions: {

    },
    onRender: function() {

    }
});


 ...ANd call by
 var mater_detail_view = masterDetailView.create({
            MasterLayoutView: MasterLayoutView,
            DetailLayoutView:DetailLayoutView,
            emptyDetailMessage: "Select a stream from the left panel to start mapping"
        });






 *
 *
 *
 */
module.MasterLayoutView = Marionette.LayoutView.extend({
    className: "card",

    getMasterCollectionView: function () {
        return this.getRegion("masterCollectionView").currentView;
    },
    onRender: function () {
        var collectionView = this.getMasterCollectionView();
        var that = this;
        collectionView.on("show:detail", function (model) {
            that.trigger("show:detail", model);
        });
        this.$el.css("height", "100%");
        this.$el
            .mouseover(function () {
                $(this).css("overflow", "auto");
            })
            .mouseout(function () {
                $(this).css("overflow", "hidden");
            });

        collectionView.on("item:removed", function (model) {
            that.trigger("item:removed", model);
        });
    },
});
module.MasterCollectionView = Marionette.CollectionView.extend({
    onAddChild: function (view) {
        view.trigger("listItemClicked");
    },
    childEvents: {
        listItemClicked: function (view) {
            this.children.each(function (childView) {
                childView._selected = false;
                childView.render();
            });
            view._selected = true;
            view.render();
            this.trigger("show:detail", view.model);
        },
        itemRemoved: function (view) {
            this.trigger("item:removed", view.model);
        },
    },
    onShow: function () {
        var first_child = this.children.first();
        if (first_child) {
            setTimeout(function () {
                first_child.$el.click();
            }, 200);
        }
    },
});
module.MasterListItemView = Marionette.ItemView.extend({
    triggers: {
        click: "listItemClicked",
    },
    className: "master-list-item-view collection-item narrow",
    template: _.template('<i class="fa fa-caret-right"></i> {{name}} '),
    initialize: function (options) {
        var that = this;

        this.model.on("change", function () {
            that.render();
        });
        this.model.on("remove", function () {
            that.trigger("itemRemoved");
        });
    },
    onRender: function () {
        if (this._selected === true) {
            this.$el.addClass("active");
        } else {
            this.$el.removeClass("active");
        }
    },
});

module.DetailLayoutView = Marionette.LayoutView.extend({
    className: "detail-view-container card",
    initialize: function (options) {
        this.options = options.options;
    },
});

module.EmptyDetailLayoutView = Marionette.LayoutView.extend({
    template: _.template('<div class="empty-detail-view">{{message}}</div>'),
    className: "empty-detail-view-container",
});

module.View = Marionette.LayoutView.extend({
    template: _.template(template),
    className: "master-detail-layout row",
    regions: {
        master: ".master",
        detail: ".detail",
    },
    detailViewCache: {},
    initialize: function (options) {
        this.options = options;
        this.empty_detail_message =
            this.options.emptyDetailMessage || "Please click the list on the left to get started.";
    },
    onBeforeDestroy: function () {},
    onShow: function () {
        var masterLayoutView = new this.options.MasterLayoutView(this.options.options);
        this.getRegion("master").show(masterLayoutView);
        var that = this;

        //show the first model
        this.refreshDetailView();

        masterLayoutView.on("show:detail", function (model) {
            that.showDetailView(model);
        });

        masterLayoutView.on("item:removed", function (model) {
            //if the detail view is currently showing the deleted model, refresh the detail view
            if (model.get("id") === that.getRegion("detail").currentView.model.get("id")) {
                that.refreshDetailView();
            }
        });
    },
    showEmptyDetailView: function () {
        var empty_detail_message =
            this.getRegion("master").currentView.getMasterCollectionView().collection.length == 0
                ? "Start creating new objects"
                : this.empty_detail_message;
        var empty_model = new Backbone.Model({
            message: empty_detail_message,
        });
        var detailLayoutView = new module.EmptyDetailLayoutView({
            model: empty_model,
            options: this.options.options,
        });
        this.getRegion("detail").show(detailLayoutView);
    },
    showDetailView: function (model) {
        var that = this;

        if (this.getRegion("detail").currentView && this.getRegion("detail").currentView.model === model) {
            return; // Dont render if we are rendeirng the same view again;; .. important for performance of large objects
        }

        this.getRegion("detail").empty();
        var detailLayoutView = new this.options.DetailLayoutView({
            model: model,
            options: this.options.options,
        });
        that.getRegion("detail").show(detailLayoutView);
    },
    refreshDetailView: function () {
        var collection = this.getRegion("master").currentView.getMasterCollectionView().collection;
        var childrenView = this.getRegion("master").currentView.getMasterCollectionView().children;
        //if empty show the empty view or show the model at the top
        if (collection.length == 0) {
            this.showEmptyDetailView();
            return;
        }

        //trigger the listItemView for the 0th child
        var view = childrenView.findByModel(collection.at(0));
        view.trigger("listItemClicked");
        this.showDetailView(collection.at(0));

        return;
    },
});

module.create = function (options) {
    if (options === undefined || options.MasterLayoutView === undefined || options.DetailLayoutView === undefined) {
        throw new Error("masterView and detailView should be passed in options for master-detail view");
    }

    return new module.View({
        MasterLayoutView: options.MasterLayoutView,
        DetailLayoutView: options.DetailLayoutView,
        emptyDetailMessage: options.emptyDetailMessage,
        options: options.options,
    });
};

export default module;
