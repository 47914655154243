import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utcFormat from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment-timezone";

/* dayjs plugins */

dayjs.extend(utcFormat);

/* to format types "LLL", "LL" , "LT" etc */
dayjs.extend(localizedFormat);

/* to humanize time */
dayjs.extend(duration);
dayjs.extend(relativeTime);

/* used for : dayjs(<string>,<format>).format() */
dayjs.extend(customParseFormat);

dayjs.extend(advancedFormat);

function getTimezoneAbbreviation() {
    try {
        const timezone = moment.tz(moment.tz.guess()).format("z");
        return timezone;
    } catch (err) {
        console.error("Error setting timezone:", err.message);
        return "";
    }
}
export const timezone = getTimezoneAbbreviation();

export default dayjs;
