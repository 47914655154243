import $ from "jquery";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import hierarchicalFormatter from "./hierarchicalFormatter";
import metaObjectHelper from "app/components/common/editor/meta-object-editors/metaObjectHelper";
import utils from "core/utils";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";

export default function (waeOnly) {
    const deferred = $.Deferred();

    metaStoreService.fetchCollection(utils.ENTITIES.STREAM).then(results => {
        deferred.resolve(
            hierarchicalFormatter(
                results.filter(
                    (r) =>
                        metaObjectHelper.isWAEventDataType(r.dataType) === !!waeOnly &&
                        !metaObjectConverter.isGlobal(r.id)
                )
            )
        );
    });

    return deferred.promise();
}
