import Backbone from "backbone";
import { View } from "./base-database-reader";
import { DB_PROVIDERS } from "./configs/db-providers";
class MariaDBXpandReader extends View {
    setup() {
        this.model.get("connectionParams").set("type", DB_PROVIDERS.MARIADBXPAND.id);
        this.model.set("isCDC", true);
    }
}

export default {
    View: MariaDBXpandReader,
    vent: new Backbone.Model()
};
