import _ from "underscore";
import base from "./base";

var Dummy = function () {};

Dummy.prototype = _.extend(new base(), {
    _addItems: function () {},
    _evictItems: function () {},
});

export default Dummy;
