import React from "react";
import { Divider, Grid, SvgIcon } from "@mui/material";
import { StriimTypography, StriimList, StriimListItem, StriimTooltip } from "@striim/striim-ui";
import { Link } from "react-router-dom";

import { ArrowUpRightCircle, ShieldLockWhite } from "../../../generic/icon/customIcons";
import Chatbot from "src/generic/icon/topbar/chatbot.svg";
import { useNavbarToggle } from "../../../hooks/useNavbarToggle";
import AI_ROUTES from "../../ai-insights/routes.json";
import { isUserAdmin } from "core/user-management/update.control";
import licenseService from "core/services/metaStoreService/license-service";

const striimGPTID = "striimGPT";
const striimSensitiveDataGovernance = "striimSensitiveDataGovernance";
const VECTOR_EMBEDDINGS = "VECTOR_EMBEDDINGS";
const styles = {
    list: {
        minWidth: 272
    },
    divider: {
        backgroundColor: "primary.500"
    },
    menuItem: {
        alignItems: "baseline",
        "&.Mui-selected": {
            backgroundColor: "primary.main",
            "&:hover": {
                backgroundColor: "primary.main"
            }
        },
        "&:hover": {
            backgroundColor: "additional.darkSlateBlue"
        },
        "&:active": {
            backgroundColor: "primary.main"
        }
    },
    icon: {
        fill: "none",
        "& > path": {
            stroke: ({ palette }) => palette.additional.white
        }
    }
};

const getSubmenuItems = enableCopilot => {
    const menuItems = [
        {
            id: striimSensitiveDataGovernance,
            title: "Sensitive Data Governance",
            caption: "Discover & protect sensitive data in your Apps",
            icon: ShieldLockWhite,
            showSeparator: true,
            route: AI_ROUTES.sherlockAI
        },
        {
            id: VECTOR_EMBEDDINGS,
            title: "Vector Embeddings Generator",
            caption:
                "Use selected models from either OpenAI or Vertex AI to generate embeddings that can be stored in a downstream vector column in supported databases.",
            icon: ArrowUpRightCircle,
            route: AI_ROUTES.embeddingGenerator
        }
    ];

    if (enableCopilot) {
        menuItems.push({
            id: striimGPTID,
            title: "Striim Copilot",
            caption:
                "Get solutions to your questions based on Striim documentation and public Striim knowledge base articles.",
            icon: Chatbot
        });
    }
    return menuItems;
};

interface SubmenuItemWrapperProps {
    isDisabled?: boolean;
    children: JSX.Element;
    disabledMesage: string;
}

const SubmenuItemWrapper: React.FC<SubmenuItemWrapperProps> = ({ isDisabled, children, disabledMesage }) => {
    return isDisabled ? (
        <StriimTooltip
            title={disabledMesage}
            arrow
            placement="right"
            slotProps={{
                tooltip: {
                    sx: {
                        fontFamily: "Inter"
                    }
                }
            }}
        >
            {children}
        </StriimTooltip>
    ) : (
        children
    );
};
interface SubmenuItemProps {
    title: string;
    caption: string;
    icon?: React.ReactNode;
    showSeparator?: boolean;
    selected: boolean;
    disabled?: boolean;
}

const SubmenuItem: React.FC<SubmenuItemProps> = ({
    title,
    caption,
    icon,
    showSeparator = false,
    selected,
    disabled = false
}) => {
    return (
        <>
            <StriimListItem sx={styles.menuItem} selected={selected} divider={false} disabled={disabled}>
                <Grid container gap={1.5} pb={showSeparator ? 1.5 : 1}>
                    {icon ? <SvgIcon component={icon} sx={styles.icon} /> : null}
                    <Grid flexDirection={"column"} display={"flex"} gap={0.5} flex={1}>
                        <StriimTypography variant="body4" color="greyscale.50">
                            {title}
                        </StriimTypography>
                        <StriimTypography variant="caption3" color="greyscale.300" letterSpacing="0.24px">
                            {caption}
                        </StriimTypography>
                    </Grid>
                </Grid>
            </StriimListItem>
            {showSeparator && <Divider variant="fullWidth" sx={styles.divider} />}
        </>
    );
};

const route = "/striim-ai";

const StriimAISubmenu = ({ navbarStateManager, enableCopilot }) => {
    const { setChatbotOpen } = useNavbarToggle();
    const isAdmin = isUserAdmin();
    const isAIInsightsDisabled = licenseService?.isAIInsightsDisabled;

    return (
        <StriimList sx={styles.list}>
            {getSubmenuItems(enableCopilot).map(item => {
                // AI is enabled by license
                //  1. Admin     ==> Show all tabs
                //  2. Non admin ==> Hide striimSensitiveDataGovernance tab
                if (!isAdmin ? item.id === striimSensitiveDataGovernance : false) return null;
                // AI is disabled by license,
                //  Show disabled striimSensitiveDataGovernance and VECTOR_EMBEDDINGS tabs
                const isDisabled =
                    isAIInsightsDisabled &&
                    (item.id === striimSensitiveDataGovernance || item.id === VECTOR_EMBEDDINGS);
                const disabledMesage = isAIInsightsDisabled
                    ? `This feature requires additional licensing permissions. For assistance, please contact support@striim.com.`
                    : `This feature can only be managed by administrators. Please contact your Striim administrator to get the required permissions.`;
                return (
                    <SubmenuItemWrapper isDisabled={isDisabled} key={item.route} disabledMesage={disabledMesage}>
                        <Link
                            to={!isDisabled ? item.route : null}
                            data-testid={`nav--app-${item.route}`}
                            id={"nav--app-" + item.route}
                            onClick={e => {
                                if (isDisabled) {
                                    e.stopPropagation();
                                    return;
                                }
                                if (item.id === striimGPTID) {
                                    setChatbotOpen(true);
                                } else {
                                    navbarStateManager.updateSelection({ route, subRoute: item.route });
                                    navbarStateManager.setActiveMenu(current => {
                                        return {
                                            ...current,
                                            open: false
                                        };
                                    });
                                }
                            }}
                            style={isDisabled ? { cursor: "not-allowed" } : null}
                        >
                            <SubmenuItem
                                title={item.title}
                                caption={item.caption}
                                icon={item.icon}
                                showSeparator={item.showSeparator}
                                selected={item.route && item.route === navbarStateManager.currentSelection?.subRoute}
                                disabled={isDisabled}
                            />
                        </Link>
                    </SubmenuItemWrapper>
                );
            })}
        </StriimList>
    );
};

export default StriimAISubmenu;
