/**
 *
 * @param {metaStoreService.entities} newNodeType
 * @param {String} predefinedComponentName
 * @param {JSON} PredefinedComponentNames
 * @param {Object} objectToBeFilled
 * @returns objectToBeFilled
 * */
import _ from "underscore";

export default function(newNodeType, predefinedComponentName, PredefinedComponentNames, objectToBeFilled) {
    switch (newNodeType) {
        case "SOURCE": {
            switch (predefinedComponentName) {
                case PredefinedComponentNames.File.name:
                    objectToBeFilled.adapter = {
                        handler: "FileReader"
                    };
                    break;
                case PredefinedComponentNames.Http.name:
                    objectToBeFilled.adapter = {
                        handler: "HTTPReader"
                    };
                    break;
                case PredefinedComponentNames.JMS.name:
                    objectToBeFilled.adapter = {
                        handler: "JMSReader"
                    };
                    break;
                case PredefinedComponentNames.Kafka.name:
                    objectToBeFilled.adapter = {
                        handler: "KafkaReader_2.1.0"
                    };
                    break;
                case PredefinedComponentNames.HDFS.name:
                    objectToBeFilled.adapter = {
                        handler: "HDFSReader"
                    };
                    break;
                case PredefinedComponentNames.MultiFileReader.name:
                    objectToBeFilled.adapter = {
                        handler: "MultiFileReader"
                    };
                    break;
                case PredefinedComponentNames.WindowsEventLog.name:
                    objectToBeFilled.adapter = {
                        handler: "WindowsEventLogReader"
                    };
                    break;
                case PredefinedComponentNames.MongoDBReader.name:
                    objectToBeFilled.adapter = {
                        handler: "MongoDBReader",
                        properties: {
                            mode: "Incremental"
                        }
                    };
                    break;
                case PredefinedComponentNames.MongoDBReaderIL.name:
                    objectToBeFilled.adapter = {
                        handler: "MongoDBReader",
                        properties: {
                            mode: "InitialLoad"
                        }
                    };
                    break;
                case PredefinedComponentNames.CosmosDBReader.name:
                    objectToBeFilled.adapter = {
                        handler: "CosmosDBReader",
                        properties: {
                            mode: "InitialLoad"
                        }
                    };
                    break;
                case PredefinedComponentNames.CosmosDBReaderCDC.name:
                    objectToBeFilled.adapter = {
                        handler: "CosmosDBReader",
                        properties: {
                            mode: "Incremental"
                        }
                    };
                    break;
                case PredefinedComponentNames.CosmosDBReaderIL.name:
                    objectToBeFilled.adapter = {
                        handler: "CosmosDBReader",
                        properties: {
                            mode: "InitialLoad"
                        }
                    };
                    break;
                case PredefinedComponentNames.MongoCosmosDBReader.name:
                    objectToBeFilled.adapter = {
                        handler: "MongoCosmosDBReader",
                        properties: {
                            mode: "InitialLoad"
                        }
                    };
                    break;
                case PredefinedComponentNames.MongoCosmosDBReaderIL.name:
                    objectToBeFilled.adapter = {
                        handler: "MongoCosmosDBReader",
                        properties: {
                            mode: "InitialLoad"
                        }
                    };
                    break;
                case PredefinedComponentNames.MongoCosmosDBReaderCDC.name:
                    objectToBeFilled.adapter = {
                        handler: "MongoCosmosDBReader",
                        properties: {
                            mode: "Incremental"
                        }
                    };
                    break;
                case PredefinedComponentNames.MQTT.name:
                    objectToBeFilled.adapter = {
                        handler: "MQTTReader"
                    };
                    break;
                case PredefinedComponentNames.TCP.name:
                    objectToBeFilled.adapter = {
                        handler: "TCPReader"
                    };
                    break;
                case PredefinedComponentNames.UDP.name:
                    objectToBeFilled.adapter = {
                        handler: "UDPReader"
                    };
                    break;
                case PredefinedComponentNames.Salesforce.name:
                    objectToBeFilled.adapter = {
                        handler: "SalesForceReader"
                    };
                    break;
                case PredefinedComponentNames.SalesforcePushTopic.name:
                    objectToBeFilled.adapter = {
                        handler: "SalesForcePushTopicReader"
                    };
                    break;
                case PredefinedComponentNames.SalesforcePlatformEvent.name:
                    objectToBeFilled.adapter = {
                        handler: "SalesForcePlatformEventReader"
                    };
                    break;
                case PredefinedComponentNames.CosmosDBReader.name:
                    objectToBeFilled.adapter = {
                        handler: "CosmosDBReader"
                    };
                    break;
                case PredefinedComponentNames.MongoCosmosDBReader.name:
                    objectToBeFilled.adapter = {
                        handler: "MongoCosmosDBReader"
                    };
                    break;
                case PredefinedComponentNames.OracleCDC.name:
                    objectToBeFilled.adapter = {
                        handler: "OracleReader"
                    };
                    break;
                case PredefinedComponentNames.YugabyteCDC.name:
                    objectToBeFilled.adapter = {
                        handler: "YugabyteReader"
                    };
                    break;
                case PredefinedComponentNames.OJet.name:
                    objectToBeFilled.adapter = {
                        handler: "OJet"
                    };
                    break;
                case PredefinedComponentNames.MSSQLCDC.name:
                    objectToBeFilled.adapter = {
                        handler: "MSSqlReader"
                    };
                    break;
                case PredefinedComponentNames.MSJet.name:
                    objectToBeFilled.adapter = {
                        handler: "MSJet"
                    };
                    break;
                case PredefinedComponentNames.MySQLCDC.name:
                    objectToBeFilled.adapter = {
                        handler: "MysqlReader"
                    };
                    break;
                case PredefinedComponentNames.HPNonStopCDC.name:
                    objectToBeFilled.adapter = {
                        handler: "HPNonStopEnscribeReader"
                    };
                    break;
                case PredefinedComponentNames.HPNonStopSQLMP.name:
                    objectToBeFilled.adapter = {
                        handler: "HPNonStopSQLMPReader"
                    };
                    break;
                case PredefinedComponentNames.HPNonStopSQLMX.name:
                    objectToBeFilled.adapter = {
                        handler: "HPNonStopSQLMXReader"
                    };
                    break;
                case PredefinedComponentNames.GoldenGate.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "FileReader"
                        },
                        parser: {
                            handler: "GGTrailParser"
                        }
                    };
                    break;
                case PredefinedComponentNames.GGTrail.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "GGTrailReader"
                        }
                    };
                    break;
                case PredefinedComponentNames.XML.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "FileReader"
                        },
                        parser: {
                            handler: "XMLParser"
                        }
                    };
                    break;
                case PredefinedComponentNames.XMLParserV2.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "FileReader"
                        },
                        parser: {
                            handler: "XMLParserV2"
                        }
                    };
                    break;
                case PredefinedComponentNames.JSON.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "FileReader"
                        },
                        parser: {
                            handler: "JSONParser"
                        }
                    };
                    break;
                case PredefinedComponentNames.Avro.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "FileReader"
                        },
                        parser: {
                            handler: "AvroParser"
                        }
                    };
                    break;
                case PredefinedComponentNames.ApacheAccessLog.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "FileReader"
                        },
                        parser: {
                            handler: "AALParser"
                        }
                    };
                    break;
                case PredefinedComponentNames.NameValuePair.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "FileReader"
                        },
                        parser: {
                            handler: "NVPParser"
                        }
                    };
                    break;
                case PredefinedComponentNames.FreeFormText.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "FileReader"
                        },
                        parser: {
                            handler: "FreeFormTextParser"
                        }
                    };
                    break;
                case PredefinedComponentNames.Binary.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "FileReader"
                        },
                        parser: {
                            handler: "BinaryParser"
                        }
                    };
                    break;
                case PredefinedComponentNames.CobolCopybookParser.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "FileReader"
                        },
                        parser: {
                            handler: "CobolCopybookParser"
                        }
                    };
                    break;
                case PredefinedComponentNames.CollectD.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "UDPReader"
                        },
                        parser: {
                            handler: "CollectdParser"
                        }
                    };
                    break;
                case PredefinedComponentNames.DelimiterSeparatedValues.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "FileReader"
                        },
                        parser: {
                            handler: "DSVParser"
                        }
                    };
                    break;
                case PredefinedComponentNames.NetFlow.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "UDPReader"
                        },
                        parser: {
                            handler: "NetflowParser"
                        }
                    };
                    break;
                case PredefinedComponentNames.SNMP.name:
                    objectToBeFilled = {
                        adapter: {
                            handler: "UDPReader"
                        },
                        parser: {
                            handler: "SNMPParser"
                        }
                    };
                    break;
                case PredefinedComponentNames.DatabaseSource.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseReader"
                    };
                    break;
                case PredefinedComponentNames.OracleDB.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseReader",
                        properties: {
                            DatabaseProviderType: "Oracle"
                        }
                    };
                    break;
                case PredefinedComponentNames.YugabyteDBReader.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseReader",
                        properties: {
                            DatabaseProviderType: "Yugabyte"
                        }
                    };
                    break;
                case PredefinedComponentNames.MSSQLDB.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseReader",
                        properties: {
                            DatabaseProviderType: "SQLServer"
                        }
                    };
                    break;
                case PredefinedComponentNames.MySQLDB.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseReader",
                        properties: {
                            DatabaseProviderType: "MySQL"
                        }
                    };
                    break;
                case PredefinedComponentNames.PostgreSQLDB.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseReader",
                        properties: {
                            DatabaseProviderType: "Postgres"
                        }
                    };
                    break;
                case PredefinedComponentNames.MariaDB.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseReader",
                        properties: {
                            DatabaseProviderType: "MariaDB"
                        }
                    };
                    break;
                case PredefinedComponentNames.MariaDBXpandDB.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseReader",
                        properties: {
                            DatabaseProviderType: "MariaDBXpand"
                        }
                    };
                    break;
                case PredefinedComponentNames.OPCUAReader.name:
                    objectToBeFilled.adapter = {
                        handler: "OPCUAReader"
                    };
                    break;
                case PredefinedComponentNames.IncrementalBatchReader.name:
                    objectToBeFilled.adapter = {
                        handler: "IncrementalBatchReader"
                    };
                    break;
                case PredefinedComponentNames.SpannerBatchReader.name:
                    objectToBeFilled.adapter = {
                        handler: "SpannerBatchReader"
                    };
                    break;
                case PredefinedComponentNames.S3Reader.name:
                    objectToBeFilled.adapter = {
                        handler: "S3Reader"
                    };
                    break;
                case PredefinedComponentNames.PostgreSQLReader.name:
                    objectToBeFilled.adapter = {
                        handler: "PostgreSQLReader"
                    };
                    break;
                case PredefinedComponentNames.JMXReader.name:
                    objectToBeFilled.adapter = {
                        handler: "JMXReader"
                    };
                    break;
                case PredefinedComponentNames.MariaDBReader.name:
                    objectToBeFilled.adapter = {
                        handler: "MariaDBReader"
                    };
                    break;
                case PredefinedComponentNames.MariaDBXpandReader.name:
                    objectToBeFilled.adapter = {
                        handler: "MariaDBXpandReader",
                        properties: {
                            DatabaseProviderType: "MariaDBXpand"
                        }
                    };
                    break;
                case PredefinedComponentNames.ServiceNowReader.name:
                    objectToBeFilled.adapter = {
                        handler: "ServiceNowReader"
                    };
                    break;
                case PredefinedComponentNames.SalesforcePardotReader.name:
                    objectToBeFilled.adapter = {
                        handler: "SalesforcePardotReader"
                    };
                    break;

                case PredefinedComponentNames.MapRFSReader.name:
                    objectToBeFilled.adapter = {
                        handler: "MapRFSReader"
                    };
                    break;
                case PredefinedComponentNames.GCSReader.name:
                    objectToBeFilled.adapter = {
                        handler: "GCSReader"
                    };
                    break;
                case PredefinedComponentNames.ZendeskReader.name:
                    objectToBeFilled.adapter = {
                        handler: "ZendeskReader"
                    };
                    break;
                case PredefinedComponentNames.HubSpotReader.name:
                    objectToBeFilled.adapter = {
                        handler: "HubSpotReader"
                    };
                    break;
                case PredefinedComponentNames.GoogleAdsReader.name:
                    objectToBeFilled.adapter = {
                        handler: "GoogleAdsReader"
                    };
                    break;
                case PredefinedComponentNames.SalesforceCDCReader.name:
                    objectToBeFilled.adapter = {
                        handler: "SalesforceCDCReader"
                    };
                    break;
                case PredefinedComponentNames.SnowflakeILReader.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseReader",
                        properties: {
                            DatabaseProviderType: "Snowflake"
                        }
                    };
                    break;
                case PredefinedComponentNames.BigQueryILReader.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseReader",
                        properties: {
                            DatabaseProviderType: "BigQuery"
                        }
                    };
                    break;
                case PredefinedComponentNames.SnowflakeCDCReader.name:
                    objectToBeFilled.adapter = {
                        handler: "SnowflakeReader"
                    };
                    break;
                case PredefinedComponentNames.JiraReader.name:
                    objectToBeFilled.adapter = {
                        handler: "JiraReader"
                    };
                    break;
                case PredefinedComponentNames.BaseSource.name:
                    break;
                default:
                    _.each(PredefinedComponentNames, function(nameObj, name) {
                        if (nameObj.name === predefinedComponentName) {
                            return (objectToBeFilled.adapter = {
                                handler: name
                            });
                        }
                    });
                    break;
            }
            break;
        }
        case "EXTERNALCACHE": {
            objectToBeFilled.externalCacheProperties = {
                handler: "Global.PROPERTYTEMPLATE.ExternalCache",
                properties: {}
            };
        }
        case "CACHE": {
            switch (predefinedComponentName) {
                case PredefinedComponentNames.FileCache.name:
                    objectToBeFilled.adapter = {
                        handler: "FileReader"
                    };
                    break;
                case PredefinedComponentNames.DBCache.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseReader"
                    };
                    break;
                case PredefinedComponentNames.HDFSCache.name:
                    objectToBeFilled.adapter = {
                        handler: "HDFSReader"
                    };
                    break;
                case PredefinedComponentNames.BaseEventTable.name: {
                    objectToBeFilled.isEventTable = true;
                    break;
                }
            }
            break;
        }
        case "EXTERNALSOURCE": {
            objectToBeFilled.properties = {
                handler: "Global.PROPERTYTEMPLATE.ExternalSource",
                properties: {}
            };
        }
        case "TARGET": {
            switch (predefinedComponentName) {
                case PredefinedComponentNames.Database.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter"
                    };
                    break;
                case PredefinedComponentNames.HBase.name:
                    objectToBeFilled.adapter = {
                        handler: "HBaseWriter"
                    };
                    break;
                case PredefinedComponentNames.HDFS.name:
                    objectToBeFilled.adapter = {
                        handler: "HDFSWriter"
                    };
                    break;
                case PredefinedComponentNames.JMS.name:
                    objectToBeFilled.adapter = {
                        handler: "JMSWriter"
                    };
                    break;
                case PredefinedComponentNames.Kafka.name:
                    objectToBeFilled.adapter = {
                        handler: "KafkaWriter_2.1.0"
                    };
                    break;
                case PredefinedComponentNames["OutputKafka2_1_0"].name:
                    objectToBeFilled.adapter = {
                        handler: "KafkaWriter_2.1.0"
                    };
                    break;
                case PredefinedComponentNames.File.name:
                    objectToBeFilled.adapter = {
                        handler: "FileWriter"
                    };
                    break;
                case PredefinedComponentNames.MQTT.name:
                    objectToBeFilled.adapter = {
                        handler: "MQTTWriter"
                    };
                    break;
                case PredefinedComponentNames.S3.name:
                    objectToBeFilled.adapter = {
                        handler: "S3Writer"
                    };
                    break;
                case PredefinedComponentNames.RedSh.name:
                    objectToBeFilled.adapter = {
                        handler: "RedshiftWriter"
                    };
                    break;
                case PredefinedComponentNames.SysOut.name:
                    objectToBeFilled.adapter = {
                        handler: "SysOut"
                    };
                    break;
                case PredefinedComponentNames.SMS.name:
                    objectToBeFilled.adapter = {
                        handler: "ClickatellSMSAdapter"
                    };
                    break;
                case PredefinedComponentNames.Email.name:
                    objectToBeFilled.adapter = {
                        handler: "EmailAdapter"
                    };
                    break;
                case PredefinedComponentNames.WebAlertUI.name:
                    objectToBeFilled.adapter = {
                        handler: "WebAlertAdapter"
                    };
                    break;
                case PredefinedComponentNames.BigQuery.name:
                    objectToBeFilled.adapter = {
                        handler: "BigQueryWriter"
                    };
                    break;
                case PredefinedComponentNames.MapRDBWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "MapRDBWriter"
                    };
                    break;
                case PredefinedComponentNames.MapRStreamWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "MapRStreamWriter"
                    };
                    break;
                case PredefinedComponentNames.HazelcastWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "HazelcastWriter"
                    };
                    break;
                case PredefinedComponentNames.AzureBlobWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "AzureBlobWriter"
                    };
                    break;
                case PredefinedComponentNames.AzureEventHubWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "AzureEventHubWriter"
                    };
                    break;

                case PredefinedComponentNames.GCSWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "GCSWriter"
                    };
                    break;

                case PredefinedComponentNames.CloudSqlPostgreSqlWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "CloudSqlPostgres"
                        }
                    };
                    break;

                case PredefinedComponentNames.CloudSqlMySqlWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "CloudSqlMySQL"
                        }
                    };
                    break;
                case PredefinedComponentNames.CloudSqlSQLServerWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "CloudSqlSQLServer"
                        }
                    };
                    break;

                case PredefinedComponentNames.CosmosDBWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "CosmosDBWriter",
                        properties: {}
                    };
                    break;

                case PredefinedComponentNames.CosmosDB.name: // Used only in templates - for license check.
                    objectToBeFilled.adapter = {
                        handler: "CosmosDBWriter"
                    };
                    break;
                case PredefinedComponentNames.AzurePostgreSqlWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "AzurePostgres"
                        }
                    };
                    break;

                case PredefinedComponentNames.CouchDBWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "CouchDBWriter"
                    };
                    break;

                case PredefinedComponentNames.HortonWorksHiveWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "HortonworksHiveWriter"
                    };
                    break;

                case PredefinedComponentNames.ClouderaHiveWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "ClouderaHiveWriter"
                    };
                    break;

                case PredefinedComponentNames.MongoDbWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "MongoDBWriter"
                    };
                    break;

                case PredefinedComponentNames.SpannerWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "SpannerWriter"
                    };
                    break;

                case PredefinedComponentNames.SpannerPGDialectWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "SpannerPGDialectWriter"
                    };
                    break;

                case PredefinedComponentNames.HDInsightKafkaWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "HDInsightKafkaWriter"
                    };
                    break;
                case PredefinedComponentNames.HDInsightHDFSWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "HDInsightHDFSWriter"
                    };
                    break;
                case PredefinedComponentNames.JPAWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "JPAWriter"
                    };
                    break;
                case PredefinedComponentNames.AzureSQLDWHWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "AzureSQLDWHWriter"
                    };
                    break;
                case PredefinedComponentNames.HiveWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "HiveWriter"
                    };
                    break;

                /* Output Databases */
                case PredefinedComponentNames.OutputOracleDatabase.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "Oracle"
                        }
                    };
                    break;
                case PredefinedComponentNames.OutputSQLServerDatabase.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "SQLServer"
                        }
                    };
                    break;
                case PredefinedComponentNames.OutputAzureSQLServerDatabase.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "SQLServer"
                        }
                    };
                    break;
                case PredefinedComponentNames.OutputMySQLDatabase.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "MySQL"
                        }
                    };
                    break;
                case PredefinedComponentNames.OutputMemSQLDatabase.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "MemSQL"
                        }
                    };
                    break;
                case PredefinedComponentNames.OutputPostgresDatabase.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "Postgres"
                        }
                    };
                    break;
                case PredefinedComponentNames.OutputHPSQLMXDatabase.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "SQLMX"
                        }
                    };
                    break;
                case PredefinedComponentNames.Yellowbrick.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "Yellowbrick"
                        }
                    };
                    break;
                case PredefinedComponentNames.Db2Zos.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "Db2Zos"
                        }
                    };
                    break;
                case PredefinedComponentNames.CassandraWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "CassandraWriter"
                    };
                    break;
                case PredefinedComponentNames.CassandraCosmosDBWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "CassandraCosmosDBWriter",
                        properties: {}
                    };
                    break;
                case PredefinedComponentNames.MongoCosmosDBWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "MongoCosmosDBWriter",
                        properties: {}
                    };
                    break;
                case PredefinedComponentNames.SAPHANAWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "SAPHANA"
                        }
                    };
                    break;
                case PredefinedComponentNames.KinesisWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "KinesisWriter"
                    };
                    break;
                case PredefinedComponentNames.KuduWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "KuduWriter"
                    };
                    break;
                case PredefinedComponentNames.HTTPWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "HTTPWriter"
                    };
                    break;
                case PredefinedComponentNames.SnowflakeWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "SnowflakeWriter"
                    };
                    break;
                case PredefinedComponentNames.DeltaLakeWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "DeltaLakeWriter"
                    };
                    break;
                case PredefinedComponentNames.AzureDataLakeStoreGen2.name:
                    objectToBeFilled.adapter = {
                        handler: "ADLSGen2Writer"
                    };

                    break;
                case PredefinedComponentNames.GooglePubSubWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "GooglePubSubWriter"
                    };
                    break;

                case PredefinedComponentNames.MariaDBDatabase.name:
                    objectToBeFilled.adapter = {
                        handler: "DatabaseWriter",
                        properties: {
                            DatabaseProviderType: "MariaDB"
                        }
                    };
                    break;
                case PredefinedComponentNames.Slack.name:
                    objectToBeFilled.adapter = {
                        handler: "SlackAlertAdapter",
                        properties: {}
                    };
                    break;

                case PredefinedComponentNames.SalesforceWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "SalesforceWriter",
                        properties: {}
                    };
                    break;

                case PredefinedComponentNames.TeamsAlertAdapter.name:
                    objectToBeFilled.adapter = {
                        handler: "TeamsAlertAdapter",
                        properties: {}
                    };
                    break;
                case PredefinedComponentNames.MapRFSWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "MapRFSWriter"
                    };
                    break;

                case PredefinedComponentNames.ServiceNowWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "ServiceNowWriter",
                        properties: {}
                    };
                    break;
                case PredefinedComponentNames.FabricLakehouseFileWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "FabricLakehouseFileWriter",
                        properties: {}
                    };
                    break;
                case PredefinedComponentNames.FabricDataWarehouseWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "FabricDataWarehouseWriter",
                        properties: {}
                    };
                    break;
                //DO-NOT-REMOVE-BELOW-COMMENT
                //add-new-case
                case PredefinedComponentNames.BambooHrReader.name:
                    objectToBeFilled.adapter = {
                        handler: "BambooHrReader",
                        properties: {}
                    };
                    break;
                case PredefinedComponentNames.StripeReader.name:
                    objectToBeFilled.adapter = {
                        handler: "StripeReader",
                        properties: {}
                    };
                    break;
                case PredefinedComponentNames.ADLSReader.name:
                    objectToBeFilled.adapter = {
                        handler: "ADLSReader",
                        properties: {}
                    };
                    break;
                case PredefinedComponentNames.SalesforceMarketingCloudWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "SalesforceMarketingCloudWriter"
                    };
                    break;
                case PredefinedComponentNames.MicrosoftDataverseWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "MicrosoftDataverseWriter",
                        properties: {}
                    };
                    break;
                case PredefinedComponentNames.IntercomReader.name:
                    objectToBeFilled.adapter = {
                        handler: "IntercomReader"
                    };
                    break;
                case PredefinedComponentNames.FabricMirrorWriter.name:
                    objectToBeFilled.adapter = {
                        handler: "FabricMirrorWriter"
                    };
                    break;
                case PredefinedComponentNames.GoogleAnalyticsReader.name:
                    objectToBeFilled.adapter = {
                        handler: "GoogleAnalyticsReader",
                        properties: {}
                    };
                    break;
                case PredefinedComponentNames.LinkedInAdsReader.name:
                    objectToBeFilled.adapter = {
                        handler: "LinkedInAdsReader",
                        properties: {}
                    };
                    break;
                case PredefinedComponentNames.GoogleSheetsReader.name:
                    objectToBeFilled.adapter = {
                        handler: "GoogleSheetsReader",
                        properties: {}
                    };
                    break;
                case PredefinedComponentNames.PaymoReader.name:
                    objectToBeFilled.adapter = {
                        handler: "PaymoReader",
                        properties: {}
                    };
                    break;
            }
            break;
        }
    }
    return objectToBeFilled;
}
