import { action, computed, observable } from "mobx";
import { v4 } from "uuid";

class LocalStore {
    UIConfig = null;
    @observable values = null;
    @observable ttl = null;
    enabled = null;
    onChange = null;

    constructor() {}

    @action setUIConfig(value) {
        this.UIConfig = value;
    }
    @action setValues(stringValue) {
        let existingProps = [];
        if (stringValue) {
            if(this.UIConfig.type === "MAP") {
                let rows = stringValue.split(this.UIConfig.rowDelimiter);
                rows.forEach(row => {
                    let kv = row.split(this.UIConfig.columnDelimiter);
                    existingProps.push({ Property: kv[0], Value: kv[1], uuid: v4(), isDeleted: false });
                });
            }
            if(this.UIConfig.type === "JSON") {
                let JSONValue = JSON.parse(stringValue);
                for (let key in JSONValue) {
                    existingProps.push({ Property: key, Value: JSONValue[key], uuid: v4(), isDeleted: false });
                }
            }
        }
        this.values = existingProps;
    }
    @action setTTL() {
        this.ttl = new Date();
    }
    @action setEnabled(value) {
        this.enabled = value;
    }
    @action setOnChange(value) {
        this.onChange = value;
    }
    @action deleteRow(uuid) {
        let id = null;
        this.values.forEach(entry => {
            if (entry["uuid"] === uuid) {
                entry.isDeleted = true;
            }
        });
        this.setTTL();
        this.triggerOnChange();
    }

    @action updateKey(uuid, key) {
        this.values.forEach(entry => {
            if (entry["uuid"] === uuid) {
                entry["Property"] = key;
            }
        });
        this.setTTL();
        this.triggerOnChange();
    }

    @action
    updateValue(uuid, value) {
        this.values.forEach(entry => {
            if (entry["uuid"] === uuid) {
                entry["Value"] = value;
            }
        });
        this.setTTL();
        this.triggerOnChange();
    }
    getStringifiedValue() {
        if (this.UIConfig.type === "MAP") {
            let strings = [];
            this.values.forEach(entry => {
                if (entry && !entry.isDeleted && entry["Value"] !== null && entry["Value"] !== "") {
                    strings.push([entry["Property"], entry["Value"]].join(this.UIConfig.columnDelimiter));
                }
            });
            let value = strings.join(this.UIConfig.rowDelimiter);
            return value;
        }
        if (this.UIConfig.type === "JSON") {
            let storedValue = {};
            this.values.forEach(entry => {
                if (entry && !entry.isDeleted && entry["Value"] !== null && entry["Value"] !== "") {
                    storedValue[entry["Property"]] = entry["Value"];
                }
            });
            return JSON.stringify(storedValue);
        }
    }
    triggerOnChange() {
        let value = this.getStringifiedValue();
        if (this.onChange) {
            this.onChange(value);
        }
    }
}

export default LocalStore;
