import React, { useRef, useEffect, useState, useCallback } from "react";
import { StriimInputField, StriimForm } from "@striim/striim-ui";
import * as Yup from "yup";
import VaultsService from "./vaults-service";
import { mapOptions } from "../utils";
import { VaultTypes } from "./constants";

const sxStyles = {
    container: {
        marginBottom: 2
    }
};
const StriimVault = ({ setFormValues, setIsValid }) => {
    const [options, setOptions] = useState([]);
    const values = {
        name: "",
        nsName: "",
        properties: {
            vaultType: VaultTypes.STRIIM_VAULT
        }
    };

    useEffect(() => {
        async function fetchData() {
            const theData = await VaultsService.getNamespaces();
            setOptions(theData?.models);
        }

        if (!options.length) {
            fetchData();
        }
    }, [options]);

    // TODO: optimization: find out why this is getting called multiple times per render
    const getOptions = useCallback(options => mapOptions(options), [options]);

    const formRef = useRef();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        nsName: Yup.string().required("Required")
    });

    return (
        <StriimForm values={values} validationSchema={validationSchema} formRef={formRef} validateOnMount={true}>
            {({ values, isValid }) => {
                setFormValues(values);
                setIsValid(isValid);
                return (
                    <>
                        <StriimInputField
                            required
                            name="name"
                            label="Name"
                            placeholder="Enter Vault name"
                            isFormElement={true}
                            sx={sxStyles.container}
                        />
                        <StriimInputField
                            required
                            name="nsName"
                            label="Namespace"
                            select
                            id="data-test-id-namespace-select"
                            placeholder="Namespace"
                            SelectProps={{ options: getOptions(options), menuPosition: "fixed" }}
                            isFormElement={true}
                            sx={sxStyles.container}
                        />
                    </>
                );
            }}
        </StriimForm>
    );
};

export default StriimVault;
