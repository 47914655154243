//
//   Copyright 2012 David Ciarletta
//
//   Licensed under the Apache License, Version 2.0 (the "License");
//   you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.apache.org/licenses/LICENSE-2.0
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//

d3.floorplan.pathplot = function(layername) {
    var x = d3.scale.linear(),
        y = d3.scale.linear(),
        line = d3.svg.line()
            .x(function(d) { return x(d.x); })
            .y(function(d) { return y(d.y); }),
        id = "fp-pathplot-" + new Date().valueOf(),
        name = layername,
        pointFilter = function(d) { return d.points; };

    function pathplot(g) {
        g.each(function(data,j) {
            if (!data) return;

            var g = d3.select(this);

            gs = g.selectAll("g").data(data, function(d) { return d.id; });

            gs.exit().remove();
            gs.enter().append("g")
                .attr("fill", function(d) { return d.color; })
                .attr("stroke", function(d) { return d.color; });

            gs
                .attr("fill", function(d) { return d.color; })
                .attr("stroke", function(d) { return d.color; });

            lines = gs.selectAll("line").data(function(cd,i) {
                var res = [];
                for (ii = 0; ii < cd.points.length; ii++) {
                    res.push({"len":cd.points.length, "x1":cd.points[ii].x, "y1": cd.points[ii].y,
                        "x2": (ii == 0 ? cd.points[ii].x : cd.points[ii-1].x),
                        "y2": (ii == 0 ? cd.points[ii].y : cd.points[ii-1].y),
                        "headSize": cd.headSize, "animSize": cd.animSize, "animTimes" : (cd.animTimes == -1 ? "indefinite" : cd.animTimes)})
                }
                return res;
            });

            lines.exit().remove();
            lines.enter().append("line")
                .attr("x1", function (d) { return x(d.x1); })
                .attr("y1", function (d) { return y(d.y1); })
                .attr("x2", function (d) { return x(d.x2); })
                .attr("y2", function (d) { return y(d.y2); })
                .attr("opacity", function (d,i) {
                    return ((d.len - i)/d.len);
                })
                .attr("stroke-width", function (d,i) {
                    return d.headSize * ((d.len - i)/d.len);
                });

            lines
                .attr("x1", function (d) { return x(d.x1); })
                .attr("y1", function (d) { return y(d.y1); })
                .attr("x2", function (d) { return x(d.x2); })
                .attr("y2", function (d) { return y(d.y2); })
                .attr("opacity", function (d,i) {
                    return ((d.len - i)/d.len);
                })
                .attr("stroke-width", function (d,i) {
                    return d.headSize * ((d.len - i)/d.len);
                });

            circles = gs.selectAll("circle").data(function(cd,i) {
                var res = [];
                for (ii = 0; ii < cd.points.length; ii++) {
                    res.push({"len":cd.points.length, "x":cd.points[ii].x, "y": cd.points[ii].y,
                        "headSize": cd.headSize, "animSize": cd.animSize, "animTimes" : (cd.animTimes == -1 ? "indefinite" : cd.animTimes)})
                }
                return res;
            });

            circles.exit().remove();
            circles.enter().append("circle")
                .attr("stroke-width", 3)
                .attr("cx", function (d) { return x(d.x); })
                .attr("cy", function (d) { return y(d.y); })
                .attr("opacity", function (d,i) {
                    return ((d.len - i)/d.len);
                })
                .attr("r", function (d,i) {
                    return (d.headSize/2) * ((d.len - i)/d.len);
                })
                .each(function(d, i) {
                    var circle = d3.select(this);
                    if (i == 0) {
                        circle
                            .attr("fill", "none")
                            .append("animate")
                            .attr("attributeName", "r")
                            .attr("from", d.animSize/2)
                            .attr("to", d.headSize/2)
                            .attr("dur", "2s")
                            .attr("repeatCount", d.animTimes);
                    }
                });

            circles
                .attr("stroke-width", 3)
                .attr("cx", function (d) { return x(d.x); })
                .attr("cy", function (d) { return y(d.y); })
                .attr("opacity", function (d,i) {
                    return ((d.len - i)/d.len);
                })
                .attr("r", function (d, i) {
                    return (d.headSize/2) * ((d.len - i)/d.len);
                })
                .each(function(d, i) {
                    var circle = d3.select(this);
                    if (i == 0) {
                        var animate = circle.select("animate");
                        if (!animate) {
                            animate = circle.append("animate")
                                .attr("attributeName", "r")
                                .attr("from", d.animSize/2)
                                .attr("to", d.headSize/2)
                                .attr("dur", "2s")
                                .attr("repeatCount", d.animTimes);
                        }
                    }
                });

        });
    }

    pathplot.xScale = function(scale) {
        if (! arguments.length) return x;
        x = scale;
        return pathplot;
    };

    pathplot.yScale = function(scale) {
        if (! arguments.length) return y;
        y = scale;
        return pathplot;
    };

    pathplot.id = function() {
        return id;
    };

    pathplot.title = function(n) {
        if (! arguments.length) return name;
        name = n;
        return pathplot;
    };

    pathplot.pointFilter = function(fn) {
        if (! arguments.length) return pointFilter;
        pointFilter = fn;
        return pathplot;
    };

    return pathplot;
};
