import _ from "underscore";
import BaseControl from "./../base-control";
import React from "react";
import ReactDOM from "react-dom/client";
import { StriimMessageBox, StriimTypography } from "@striim/striim-ui";
import { Grid, Box } from "@mui/material";
import { StriimTheme } from "@striim/striim-ui";

var Model = BaseControl.Model.extend({
    defaults: {
        value: null,
        message: "",
        type: "ERROR"
    }
});

var View = BaseControl.View.extend({
    template: _.template(`<div> </div>`),

    getViewValue: function() {
        return this.model.get("value");
    },
    setMessage: function(value) {
        this.model.set("message", value);
        this.render();
    },
    setType: function(type) {
        this.model.set("type", type);
    },
    setHide(hide) {
        hide ? this.$el.slideUp("fast") : this.$el.slideDown("fast");
    },

    onRender: function() {
        try {
            const root = ReactDOM.createRoot(this.$el[0]);
            const sxClasses = {
                "& .MuiCard-root": {
                    backgroundColor: theme => theme.palette.critical[50],
                    border: theme => `1px solid ${theme.palette.critical[100]}`,
                    boxShadow: "unset",
                    "& .MuiTypography-root": {
                        color: theme => theme.palette.greyscale[900]
                    }
                },
                "& .MuiCardHeader-avatar": {
                    "& svg": {
                        fill: theme => theme.palette.critical[500],
                        color: theme => theme.palette.critical[500]
                    }
                }
            };
            root.render(
                <StriimTheme preselector=".striim.materialize.light:not(#\20)">
                    <Box sx={this.model.get("type") === "ERROR" ? sxClasses : {}}>
                        <StriimMessageBox
                            text={
                                <Grid>
                                    <StriimTypography
                                        sx={{
                                            color: this.model.get("type") === "SUCCESS" ? "white" : "inherit",
                                            wordBreak: "break-word"
                                        }}
                                        variant="body4"
                                        data-test-id="error-box-message"
                                    >
                                        {this.model.get("message")}
                                    </StriimTypography>
                                </Grid>
                            }
                            type={this.model.get("type")}
                        />
                    </Box>
                </StriimTheme>
            );
        } catch (e) {
            console.error("Error rendering Error Info Box", e);
        }
        this.$el.hide();
    }
});

function buildTextField() {
    return function() {
        return _.extend({}, BaseControl, {
            View: View.extend({
                hideLabel: true
            }),
            Model: Model
        });
    };
}

export default buildTextField();
