import _ from "underscore";
import App from "app";
import api from "core/api/api";
import $ from "jquery";
import appStatusSynchronizer from "core/appStatusSynchronizer";
import NodeViewBase from "./nodeViewBase";
import streamNodeTemplate from "./templates/streamNodeTemplate.html";
import metaObjectHelper from "app/components/common/editor/meta-object-editors/metaObjectHelper";

var StreamNodeView = NodeViewBase.extend({
    template: _.template(streamNodeTemplate),
    tagName: "div",
    className: "node stream-node jsplumb-draggable",
    attributes: function() {
        const modelId = this.model.id;
        return {
            "data-test-id": modelId ? modelId.split("_")[2] : null
        };
    },

    ui: {
        node: "", // whole $el
        nodeContainer: ".node-container",
        connectNewNode: ".connect-new-node",
        previewOnRun: ".preview-on-run",
        counter: ".counter"
    },

    events: {
        "mousedown @ui.node": "onNodeMouseDown",
        "mousemove @ui.node": "onNodeMouseMove",
        "mouseup @ui.node": "onNodeMouseUp",
        "click @ui.node": "onNodeClick",
        "click @ui.connectNewNode": "onConnectNewNode",
        "click @ui.previewOnRun": "onPreviewOnRun"
    },

    initialize: function() {
        this.model.on(
            "change:isSelected",
            function() {
                this.$el.toggleClass("selected", this.model.isSelected);
            }.bind(this)
        );

        this.model.on("change:recentDataRate", this._setStreamNodeRecentDataRate.bind(this));

        this.listenTo(
            appStatusSynchronizer,
            this.model.applicationId,
            function(app_status) {
                this.$el.removeClass("back-pressured");
            }.bind(this)
        );
    },

    onRender: function() {
        NodeViewBase.prototype.onRender.call(this);
        this.addClipboardListener();
        metaObjectHelper.hasValidDbSource(this.model.id, true);
    },

    onShow: function() {
        this.setupDnd();
    },

    _setStreamNodeRecentDataRate: function() {
        this.setRecentDataRate();
        this.$el.toggleClass("back-pressured", this.model.streamFull);
    },

    toggleRecentDataVisibility: function(isVisible) {
        this.ui.counter.toggleClass("counter-visible", isVisible);
    },

    onPreviewOnRun: function(e) {
        e.stopPropagation();
        e.preventDefault();

        this.trigger("preview-on-run");
    }
});

export default StreamNodeView;
