import $ from "jquery";
import "lib/vendor/jquery.blockUI";

export default function (selector) {
    var requiresBlocking = false;

    return {
        block: function (delayBeforeShowingTheLoader) {
            var delay = delayBeforeShowingTheLoader || 500;
            $(selector).block({
                message: null,
            });
            requiresBlocking = true;

            setTimeout(function () {
                if (requiresBlocking) {
                    $.blockUI({
                        css: {
                            border: "none",
                            padding: "15px",
                            backgroundColor: "#000",
                            "-webkit-border-radius": "10px",
                            "-moz-border-radius": "10px",
                            opacity: 0.5,
                            color: "#fff",
                        },
                    });
                }
            }, delay);
        },

        unblock: function () {
            requiresBlocking = false;
            $(selector).unblock();
            $.unblockUI();
        },
    };
}
