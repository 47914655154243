import React, { useState } from "react";
import { StriimIconButton, StriimInputField, StriimTypography, StriimButton } from "@striim/striim-ui";
import { Drawer } from "@mui/material";
import NewWindow from "react-new-window";
import Chatbot from "src/generic/icon/topbar/chatbot.svg";
import { CloseRounded, OpenInNewRounded } from "@material-ui/icons";

import StriimGPT from "./striim-gpt";
import WithTooltip from "src/generic/tooltip/tooltip";
import { InfoCircle } from "src/generic/icon/customIcons";
import { useNavbarToggle } from "src/hooks/useNavbarToggle";

const EXPANDED_NAVBAR_WIDTH = 174;
const COLLAPSED_NAVBAR_WIDTH = 72;

const StriimGPTWrapper = ({ classes }) => {
    const { isNavbarToggled, chatbotOpen, setChatbotOpen } = useNavbarToggle();
    const [externalWindows, setExternalWindows] = useState(null);
    const [chatbotPosition, setChatbotPosition] = useState("right");
    const [positionInput, setPositionInput] = useState("");

    const handleChatbotClick = () => {
        if (externalWindows) {
            setExternalWindows(null);
        }
        setChatbotOpen(currState => !currState);
    };

    const handlePositionChange = () => {
        const positionValue = positionInput?.value;
        if (positionValue?.toLowerCase() === chatbotPosition?.toLowerCase()) return;
        setChatbotPosition(positionValue);
    };

    const positionOptions = [
        { value: "right", label: "Right" },
        { value: "left", label: "Left" },
        { value: "top", label: "Top" },
        { value: "bottom", label: "Bottom" }
    ];

    return (
        <>
            <StriimIconButton onClick={handleChatbotClick}>
                <Chatbot id={"nav--chatbot"} className={classes.iconWhite} />
            </StriimIconButton>
            <Drawer
                variant="persistent"
                anchor={chatbotPosition}
                open={chatbotOpen}
                hideBackdrop
                sx={{
                    ".MuiDrawer-paper": {
                        marginLeft: isNavbarToggled ? `${EXPANDED_NAVBAR_WIDTH}px` : `${COLLAPSED_NAVBAR_WIDTH}px`,
                        transition: "margin-left 0.5s ease 0s",
                        marginTop: "49px",
                        backgroundColor: "greyscale.50",
                        border: "1px solid",
                        borderColor: "greyscale.200"
                    }
                }}
            >
                <div className={classes.striimGPTTopBar}>
                    <div className={classes.coPilotHeader}>
                        <StriimIconButton onClick={handleChatbotClick}>
                            <Chatbot className={classes.iconWhite} />
                        </StriimIconButton>
                        <StriimTypography variant="h3" color="greyscale.900">
                            Striim Copilot
                        </StriimTypography>
                        {WithTooltip(
                            <InfoCircle className={classes.tooltipIcon} />,
                            "Striim Copilot uses AI, which may sometimes lead to inaccurate results."
                        )}
                    </div>
                    <div className={classes.striimGPTMover}>
                        <div className={classes.striimGPTMoveOptions}>
                            <StriimInputField
                                select
                                SelectProps={{
                                    options: positionOptions
                                }}
                                value={positionInput}
                                onChange={val => setPositionInput(val)}
                                placeholder="Choose Position"
                            />
                        </div>
                        <StriimButton variant="text" onClick={handlePositionChange} disabled={!positionInput}>
                            Move
                        </StriimButton>
                    </div>
                    <div className={classes.striimGPTTopBarControls}>
                        <StriimIconButton
                            color="greyscale.900"
                            onClick={() => {
                                setChatbotOpen(false);
                                if (externalWindows) {
                                    growl.error("External StriimGPT window is already open");
                                } else {
                                    setExternalWindows(
                                        <NewWindow
                                            name="StriimGPT-popup"
                                            title="StriimGPT"
                                            features={{ height: "100%", width: "100%", popup: true }}
                                            onUnload={() => setExternalWindows(null)}
                                        >
                                            <StriimGPT external />
                                        </NewWindow>
                                    );
                                }
                            }}
                        >
                            <OpenInNewRounded />
                        </StriimIconButton>
                        <StriimIconButton color="greyscale.900" onClick={() => setChatbotOpen(false)}>
                            <CloseRounded />
                        </StriimIconButton>
                    </div>
                </div>
                {!externalWindows && (
                    <StriimGPT
                        vertical={
                            chatbotPosition?.toLowerCase() === "right" || chatbotPosition?.toLowerCase() === "left"
                        }
                    />
                )}
            </Drawer>
            {externalWindows}
        </>
    );
};

export default StriimGPTWrapper;
