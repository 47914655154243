import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
// @ts-ignore
import { StriimFormBuilder, FIELD_TYPES } from "@striim/striim-ui";
import { Box } from "@mui/material";
import TARGET_FORM_INFO from "../../target-form-info";
import { WizardContext } from "../../../wizard-context";
import { targetUtils as utils, validationUtils } from "../../../utils";
import FileBrowser from "../../../../../generic/filebrowser-v2/filebrowser";
import manageStriimService from "../../../../apps/pages/manage-striim/manage-striim-service";
import connectionProfileService from "../../../../apps/pages/manage-striim/connection-profiles/connection-profile-service";
import ConnectionProfileSelector from "../../../../../generic/connection-profile-selector/connection-profile-selector";
import { StriimTypography } from "@striim/striim-ui-v2";
interface TargetFormBuilderProps {
    initialValues: Object;
    adapterName: string;
    enableNext: boolean;
    formRef: any;
    setEnableNext: (enableNext: boolean) => void;
    getCustomFields: (formData: Array<any>) => Array<Object>;
    source: string;
    cdcSource: string;
}
const styles = {
    container: {},
    titleBox: {
        mb: 2
    },
    titleText: {
        color: "primary.700"
    }
};

export const TitleBox = ({ title }) => {
    return (
        <Box sx={styles.titleBox}>
            <StriimTypography variant="h3" sx={styles.titleText}>
                {title}
            </StriimTypography>
        </Box>
    );
};
const TargetFormBuilder = ({
    initialValues,
    adapterName,
    formRef,
    enableNext,
    setEnableNext,
    getCustomFields,
    source,
    cdcSource
}: TargetFormBuilderProps) => {
    const { sourceInfo } = useContext(WizardContext);
    let { isSourceModeAutomated } = sourceInfo;

    const formData = useMemo(
        () => getCustomFields(TARGET_FORM_INFO[validationUtils.getTargetType(adapterName).toUpperCase()]?.form_fields),
        []
    );

    const prevValuesRef = useRef();

    async function handleChange(values: Object) {
        if (adapterName === "BigQuery") {
            let serviceAccountKey = values?.ServiceAccountKey;
            if (values?.ServiceAccountKey && prevValuesRef.current?.ServiceAccountKey !== serviceAccountKey) {
                const jsonData = await manageStriimService.readJSONFile(serviceAccountKey);
                if (jsonData?.project_id) {
                    formRef.current.setFieldValue("projectId", jsonData?.project_id);
                }
            }
            prevValuesRef.current = values;
        }
        if (adapterName === "SnowflakeWriter") {
            if (
                values.useConnectionProfile === true &&
                (prevValuesRef.current?.connectionProfileName !== values.connectionProfileName ||
                    (prevValuesRef.current?.useConnectionProfile !== values.useConnectionProfile &&
                        values.connectionProfileName === ""))
            ) {
                let connectionProfileAuthType = "";
                formRef.current?.setFieldValue("streamingUpload", true);
                formRef.current?.setFieldValue("CPAuthenticationType", "");
                if (values.connectionProfileName) {
                    const connectionProfileDetails = await connectionProfileService.getConnectionProfile(
                        values.connectionProfileName
                    );
                    connectionProfileAuthType = connectionProfileDetails.get("properties")["authenticationType"];
                }
                const streamingUploadValue = connectionProfileAuthType === "Password" ? false : true;
                formRef.current?.setFieldValue("streamingUpload", streamingUploadValue);
                formRef.current?.setFieldValue("CPAuthenticationType", connectionProfileAuthType);
            } else if (
                prevValuesRef.current?.authenticationType.value !== values.authenticationType?.value ||
                (prevValuesRef.current?.useConnectionProfile !== values.useConnectionProfile &&
                    values.useConnectionProfile === false)
            ) {
                formRef.current?.setFieldValue(
                    "streamingUpload",
                    values.authenticationType?.value === "Password" ? false : true
                );
            }
            prevValuesRef.current = values;
        }
    }

    return (
        <Box sx={styles.container}>
            <TitleBox title={"Connection details"} />
            {formData && (
                <StriimFormBuilder
                    formProps={{ isSourceModeAutomated, source, cdcSource }}
                    initialFieldValues={initialValues}
                    data={formData}
                    onChange={handleChange}
                    validateOnChange={true}
                    setFormValid={setEnableNext}
                    formRef={formRef}
                    setFieldToDefaultOnHidden={true}
                    customFields={{
                        [FIELD_TYPES.FILE]: props => <FileBrowser directoryOnly={false} {...props} />,
                        [FIELD_TYPES.CONNECTIONPROFILE]: props => <ConnectionProfileSelector {...props} />
                    }}
                />
            )}
        </Box>
    );
};

export default TargetFormBuilder;
