import { timezone } from "../../../../../core/utils/date-utils";

const getSuffix = (adapters, str) => {
    if (adapters?.length === 1 && adapters?.[0]?.type) return ` ${str} ${adapters?.[0]?.type}`;
    return "";
};
const getColumns = (sourceAdapters = null, targetAdapters = null) => {
    const sourceSuffix = getSuffix(sourceAdapters, "from");
    const targetSuffix = getSuffix(targetAdapters, "to");
    return [
        {
            field: "SourceTableName",
            headerName: "Source Table Name",
            width: "200px"
        },
        {
            field: "TargtTabelName",
            headerName: "Target Table Name",
            width: "200px"
        },
        {
            field: "RecordsRead",
            headerName: "Records Read" + sourceSuffix,
            width: "200px"
        },
        {
            field: "RecordsWritten",
            headerName: "Records Written" + targetSuffix,
            width: "200px"
        },
        {
            field: "LastWriteTime",
            headerName: `Last Write Time (${timezone})`,
            width: "200px"
        }
    ];
};
export default getColumns;
