import Backbone from "backbone";
import { View } from "./base-database-reader";
import { DB_PROVIDERS } from "./configs/db-providers";

class MariadbDatabaseReader extends View {
    setup() {
        this.model.get("connectionParams").set("type", DB_PROVIDERS.MARIADB.id);
    }
}

export default {
    View: MariadbDatabaseReader,
    vent: new Backbone.Model()
};
