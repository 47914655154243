import React, { useState, useEffect, useRef } from "react";
import {
    StriimIconButton,
    StriimInputField,
    StriimMessageBox,
    StriimButton,
    StriimTypography
} from "@striim/striim-ui";
import { CircularProgress, InputAdornment, SvgIcon } from "@mui/material";
import { Send } from "@mui/icons-material";
import { Delete } from "../../../../generic/icon/customIcons";
import growl from "../../../../../app/components/common/growl";
import getStyles, { sxStyles } from "./striim-gpt.styles";
import { useTheme } from "@mui/material/styles";
import api from "core/api/api";
import { getBodyParams } from "../../../../generic/oauth-sign-in/utils";


const FAIL_ERR = "Failed to fetch";
const STORAGE_KEY = "striimGPTchat";
const HF_ENDPOINT = "/do/huggingface/credentials";
const HALF_SECOND = 30000;

const StriimGPT = ({ external = false, vertical = true }) => {
    const theme = useTheme();
    const styles = getStyles(theme);
    const divRef = useRef(null);
    const [chat, setChat] = useState(
        localStorage.getItem(STORAGE_KEY) ? JSON.parse(localStorage.getItem(STORAGE_KEY)) : {}
    );
    const [query, setQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [hfAuthToken, setHfAuthToken] = useState("");
    const [hfEndpoint, setHfEndpoint] = useState("");
    const [userId, setUserId] = useState(null); // Added to store userId

    useEffect(() => {
        divRef.current.scrollIntoView({ behavior: "smooth" });
    });

    const fetchHuggingFaceDetails = async () => {
        try {
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), HALF_SECOND);
            const authrHostURL = await api.getAuthrHostURL();

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: getBodyParams(),
                redirect: "follow"
            };

            const response = await fetch(authrHostURL + HF_ENDPOINT, requestOptions);
            clearTimeout(timeoutId);
            if (response?.ok) {
                const result = await response.json();
                setHfEndpoint(result.endpoint);
                setHfAuthToken(result.token);
            }
            else if (response?.status === 401) { // handle 401
                throw new Error("Credential verification failed.");
            } else {
                throw new Error("Credentials Network response was not ok.");
            }
        } catch (error) {
            growl.error(
                "Striim Copilot error: " + error,
                "There was an error validating the connection, please try again."
            );
            setIsLoading(true);
        }
    };

    useEffect(() => {
        fetchHuggingFaceDetails();
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            const userMetaData = await api.getUserMetaData();
            const userId = userMetaData?.id; // Retrieve userId
            setUserId(userId);

            // Retrieve chat history for the specific userId
            const storedChat = localStorage.getItem(STORAGE_KEY);
            if (storedChat) {
                const chatData = JSON.parse(storedChat);
                if (chatData[userId]) {
                    setChat(chatData);  // Load only the specific user's chat
                }
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        if (userId) {
            localStorage.setItem(STORAGE_KEY, JSON.stringify(chat));
        }
    }, [chat, userId]);

    const fetchResponse = async () => {
        setIsLoading(true);

        try {
            const userMetaData = await api.getUserMetaData();
            const userId = userMetaData?.id;  // Accessing id from metadata
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), HALF_SECOND);

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + hfAuthToken
                },
                body: JSON.stringify({
                    query: query,
                    userInfo: userMetaData
                }),
                signal: controller.signal
            };
            // adding retry mechanism for credentials
            let retryCount = 0;
            while (
                (hfEndpoint === null || hfEndpoint === "") &&
                (hfAuthToken === null || hfAuthToken === "") &&
                retryCount < 3
            ) {
                await fetchHuggingFaceDetails();
                retryCount += 1;
            }
            if (retryCount >= 3) {
                throw new Error("Max tries reached, can't connect to authr");
            }

            const response = await fetch(hfEndpoint, requestOptions);

            if (response?.ok) {
                const result = await response.text();
                const resultObj = JSON.parse(result);
                const newMessage = [resultObj?.query, resultObj?.answer, resultObj?.timestamp];
                // Update the chat based on userId
                setChat(prevChat => ({
                    ...prevChat,
                    [userId]: prevChat[userId] ? [...prevChat[userId], newMessage] : [newMessage]
                }));

                setIsLoading(false);
                clearTimeout(timeoutId);
            } else {
                throw new Error("Network response was not ok.");
            }
        } catch (error) {
            growl.error(
                "Striim Copilot error:" + error.message === FAIL_ERR
                    ? "The server is currently down or undergoing maintenance"
                    : error,
                "There was an error getting a response, please try again."
            );
            setIsLoading(false);
        }
        setQuery("");
    };

    const noQuestion = isLoading || !query || query.trim() === "";

    return (
        <div style={{ width: !external && vertical ? "30vw" : "100%", ...styles.container }}>
            <div style={{ height: external || vertical ? "80vh" : "30vh", ...styles.msgsContainer }}>
                {userId && chat[userId] && chat[userId].map((response, index) => {
                    return (
                        <div key={index} style={styles.response}>
                            <div key={`${index}-question`} style={styles.question}>
                                <StriimMessageBox type="NOTIFICATION" text={response[0]} />
                            </div>
                            <div key={`${index}-answer`} style={styles.answer}>
                                <span>{response[1]}</span>
                            </div>
                        </div>
                    );
                })}
                <div ref={divRef} />
            </div>
            <div style={styles.controlsContainer}>
                <StriimInputField
                    disabled={isLoading}
                    fullWidth
                    placeholder="Ask Striim Copilot"
                    value={query}
                    onChange={val => {
                        setQuery(val);
                    }}
                    InputProps={{
                        onKeyDown: e => {
                            if (e.keyCode === 13 && !e.shiftKey && !noQuestion) {
                                fetchResponse();
                            }
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                <StriimIconButton
                                    onClick={fetchResponse}
                                    variant="text"
                                    disabled={noQuestion}
                                    sx={sxStyles.addMsgIconBtn}
                                >
                                    {isLoading ? <CircularProgress size={20} /> : <Send />}
                                </StriimIconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <StriimButton
                    variant="secondary"
                    endIcon={!vertical && <Delete />}
                    onClick={() => {
                        setChat(prevChat => ({
                            ...prevChat,
                            [userId]: []
                        }));
                    }}
                    sx={sxStyles.deleteSvg}
                >
                    {vertical ? (
                        <SvgIcon>
                            <Delete />
                        </SvgIcon>
                    ) : (
                        <StriimTypography color="greyscale.900" variant="body3">
                            Clear
                        </StriimTypography>
                    )}
                </StriimButton>
            </div>
        </div>
    );
};

export default StriimGPT;
