import React, { useState, useEffect } from "react";
import { Paper, Drawer } from "@mui/material";
import styles from "./message-log.styles";
import MessageLogContents from "./message-log-contents";
import localStore from "./message-log.store";
import Toasts from "../toasts/toasts";
import api from "../../../core/api/api";
import { StriimTypography, StriimToaster } from "@striim/striim-ui-v2";

export const QUERY_GET_ALL_COMPONENTS =
    "SELECT Distinct(componentName) as componentName,  componentType, applicationName  FROM System$Notification.notificationStore;";

const MessageLog = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        api.getHistoricalMessageData(QUERY_GET_ALL_COMPONENTS).done(data => {
            data.forEach(entry => {
                localStore.addComponentAvailable(entry.componentName);
                localStore.addComponentAvailable(entry.applicationName);
            });
        });
    }, []);

    useEffect(() => {
        if (open) {
            localStore.resetTime();
        }
    }, [open]);

    return (
        <div data-test-id={"errorconsole-messagelog-container"}>
            <StriimToaster data-test-id="toast-provider" />
            <Toasts store={localStore} />
            <StriimTypography
                variant="caption2"
                sx={styles.openButton}
                onClick={() => {
                    setOpen(true);
                }}
                data-test-id={"errorconsole-messagelog-open"}
            >
                MESSAGE LOG
            </StriimTypography>
            <Drawer
                open={open}
                anchor={"bottom"}
                onClose={() => {
                    setOpen(false);
                }}
                PaperProps={{ sx: styles.slider }}
            >
                <div>
                    <StriimTypography
                        variant="caption2"
                        sx={styles.openButtonClosed}
                        onClick={() => {
                            setOpen(false);
                        }}
                        data-test-id={"errorconsole-messagelog-close"}
                    >
                        MESSAGE LOG
                    </StriimTypography>
                    <Paper sx={styles.messagesContainer}>
                        <MessageLogContents store={localStore} />
                    </Paper>
                </div>
            </Drawer>
        </div>
    );
};

export default MessageLog;
