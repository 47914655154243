import _ from "underscore";
import api from "core/api/api";
import growl from "app/components/common/growl";
import metaobjectConverter from "core/services/metaStoreService/metaobject-converter";
import BaseControl from "./../../base-control";
import securityCheck from "core/services/securityService/adapter-security-check";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import DataSource from "./../../property-template/property-template-datasource";
import template from "./process-component-loader.html";

export default function () {
    var View = BaseControl.View.extend({
        template: _.template(template),

        ui: {
            buttonLoad: "button.load",
            buttonUnload: "button.unload",
            inputfilename: "input[type=text]",
            openProcesslist: "#open-process-list",
        },

        events: {
            "click @ui.buttonLoad": "onLoadClick",
            "click @ui.buttonUnload": "onUnloadClick",
        },

        onRender: function () {
            this._reloadList();
        },

        _reloadList: function () {
            var list = new DataSource("process");
            if (list) {
                list = list.map(function (item) {
                    return metaobjectConverter.getName(item.id);
                });
            }
            this.ui.openProcesslist.html(list.join(", "));
        },

        onLoadClick: function () {
            this.runOpenProcessorAction(
                api.loadOpenProcessor,
                "Open Processor loaded",
                "Loading Open Processor failed!"
            );
        },

        onUnloadClick: function () {
            this.runOpenProcessorAction(
                api.unloadOpenProcessor,
                "Open Processor unloaded",
                "Unloading Open Processor failed!"
            );
        },

        runOpenProcessorAction: function (apiAction, successMessage, failMessage) {
            var _this = this;

            if (!this.getEnabled()) {
                return;
            }

            if (!this.ui.inputfilename.val().trim()) {
                return;
            }

            if (this._isRunning) {
                return;
            }
            this._isRunning = true;
            this.setEnabled(false);

            apiAction
                .call(api, this.ui.inputfilename.val())
                .then(
                    function () {
                        growl.success("", successMessage);
                    },
                    function (e) {
                        growl.error(e.message, failMessage);
                    }
                )
                .always(function () {
                    propertyTemplateService.loadPropertyTemplate().always(function () {
                        securityCheck.clearCache();
                        securityCheck.loadAll().then(function () {
                            _this.ui.inputfilename.val("");
                            _this._isRunning = false;
                            _this.setEnabled(true);
                            _this._reloadList();
                        });
                    });
                });
        },

        getViewValue: function () {
            return "";
        },

        setViewValue: function () {},
    });

    var Model = BaseControl.Model.extend({
        defaults: {
            value: "",
        },
    });

    return _.extend({}, BaseControl, {
        Model: Model,
        View: View,
    });
}
