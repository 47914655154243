export const classes = {
    wrapper: {
        "& div": {
            display: "flex"
        }
    },
    sourcesList: {
        display: "flex",
        "& div": {
            mr: 1
        }
    },
    targetsList: {
        display: "flex",
        "& div": {
            ml: 1
        }
    },
    arrowRightIcon: {
        display: "flex",
        alignItems: "center",
        height: 7,
        width: 7
    }
};
