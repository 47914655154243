import _ from "underscore";
import App from "app";
import Backbone from "backbone";
import UIControl from "app/components/common/editor/control/ui-control";
import BaseEditor from "app/components/common/editor/meta-object-editors/base";
import FieldDataSource from "app/components/common/editor/control/select/field-datasource";
import PropertyTemplateDataSource from "app/components/common/editor/control/select/propertytemplate-datasource";
import templates from "app/components/appwizard/templates";
import metaStoreService from "core/services/metaStoreService/meta-store-service";

var Module = function(metadata_object, options) {
    options = options || {};
    options.displayFields = options.displayFields || [
        "validationOutput",
        "configureUsingWizard",
        "adapter",
        "lb1",
        "parser",
        "lb2",
        "outputStream",
        "outputclause",
        "destinationFields"
    ];
    BaseEditor.apply(this, arguments);
};

let shouldShowOutputClause = function(model) {
    if (model.outputclause && model.outputclause.length > 1) {
        return true;
    } else if (
        model.outputclause &&
        model.outputclause.length === 1 &&
        model.outputclause[0]["map"] &&
        model.outputclause[0]["map"]["streamName"] !== model.outputStream
    ) {
        return true;
    }
    return false;
};

Module.prototype = _.extend({}, BaseEditor.prototype, {
    create: function() {
        const result = BaseEditor.prototype.create.apply(this, arguments);
        return result;
    },

    _configureNewMetaObjectFields: function(all_fields) {
        var _this = this;
        this.options.StaticControls.configureUsingWizard = UIControl.InfoBox({
            hide: true,
            message: "This adapter can be configured using a Wizard.",
            actionTitle: "Click here to configure using Wizard",
            actionClickHandler: function() {
                let url = _this.getWizardURL();
                if (url) {
                    App.navigate(url, { trigger: true });
                }
            }
        }).create();
        all_fields.unshift("configureUsingWizard");
        BaseEditor.prototype._configureNewMetaObjectFields.apply(this, arguments);
    },

    addCustomFields: function() {
        var _this = this;
        let metaObjectId = this.MetaObjectModel && this.MetaObjectModel.id;

        // const propertyTemplate = UIControl.PropertyTemplate("source");
        // this.fieldMapper.adapter = new MarionetteComponent({
        //     model: new propertyTemplate.Model()
        // });
        this.fieldMapper.adapter = UIControl.PropertyTemplate("source")
            .extend({
                subControl: true,
                hideLabel: true,
                metaObjectId: metaObjectId
            })
            .create();

        this.fieldMapper.adapter.on(UIControl.EVENT.VALUE_CHANGED, function(value) {
            // check if source can be configured in wizard
            function getCdcAdapter() {
                if (!value || !value.handler) {
                    return null;
                }
                return templates.adapterToCampaign[value.handler];
            }
            if (!this.options.hideTitle) {
                _this._selectedCdcAdapter = getCdcAdapter();
                _this._setWizardLinkVisibility();
            }

            var closestEditor = _this.fieldMapper.parser.$el.closest(".field-row");
            if (value.handler && this.requiresParser) {
                _this.fieldMapper.parser.hidden = false;
                closestEditor.show(value.handler);
                _this.Form.toggleControlVisibility(_this.options.StaticControls.lb1, true);
            } else {
                _this.fieldMapper.parser.hidden = true;
                _this.MetaObjectModel.unset("parser");
                closestEditor.hide(value.handler);
                _this.Form.toggleControlVisibility(_this.options.StaticControls.lb1, false);
            }

            _this.fieldMapper.parser.setDataSource(
                new PropertyTemplateDataSource(metaStoreService.entities.SOURCE, _this.fieldMapper.adapter.getValue())
            );
        });
        this.options.StaticControls.lb1 = new UIControl.LineBreak().create();

        // this.options.StaticControls.configureUsingWizard = UIControl.InfoBox.create();

        this.fieldMapper.parser = UIControl.PropertyTemplate("parser")
            .extend({
                subControl: true,
                hidden: true,
                hideLabel: true,
                metaObjectId: metaObjectId
            })
            .create();
        this.fieldMapper.parser.on(UIControl.EVENT.VALUE_CHANGED, function() {
            //TODO: removed unnecessary code from propertytemplate-datasource.js
            if (!_this.fieldMapper.adapter.getValue()) {
                _this.fieldMapper.adapter.setDataSource(
                    new PropertyTemplateDataSource(
                        metaStoreService.entities.SOURCE,
                        _this.fieldMapper.parser.getValue()
                    )
                );
            }
        });

        this.options.StaticControls.lb2 = new UIControl.LineBreak().create();

        let showOutputClause = shouldShowOutputClause(this.MetaObjectModel);

        /*
         *
         * Because there are some backend problem
         * we need to turn off this functionality
         *
         *
         *
         * */

        this.fieldMapper.outputStream = UIControl.StreamSelector.extend({
            hidden: showOutputClause
        }).create();
        //TODO: outputclause control is hidden by default, if the outputclause.length > 1 it should
        //TODO: be shown and the outputStream should be hidden
        //TODO: you can follow the same pattern as with .parser property which is also hidden by default
        //TODO: and it's shown once an adapter is chosen

        this.fieldMapper.outputclause = UIControl.MultipleStreamSelector.extend({
            hidden: !showOutputClause
        }).create();

        var dataSource = new FieldDataSource();
        var destinationFields = UIControl.Select(dataSource, {
            multiple: true
        }).create();
        this.fieldMapper.destinationFields = destinationFields;

        this.fieldMapper.outputStream.on(UIControl.EVENT.VALUE_CHANGED, function(stream_value) {
            dataSource = new FieldDataSource(stream_value);
            destinationFields.setDataSource(dataSource);
        });
    },

    _setWizardLinkVisibility: function() {
        let url = this.getWizardURL();
        if (url && !this.MetaObjectModel.get("id")) {
            this.options.StaticControls.configureUsingWizard.setHide(false);
        } else {
            this.options.StaticControls.configureUsingWizard.setHide(true);
        }
    },

    getWizardURL: function() {
        let properties = this.fieldMapper.adapter.model.value;
        let app = this.options.app;
        let key = null;
        if (properties?.handler === "Global.PROPERTYTEMPLATE.OracleReader") {
            key = "OracleCDC";
        } else if (properties?.handler === "Global.PROPERTYTEMPLATE.MysqlReader") {
            key = "MySQLCDC";
        } else if (properties?.handler === "Global.PROPERTYTEMPLATE.SnowflakeReader") {
            key = "SnowflakeCDCReader";
        } else if (properties?.handler === "Global.PROPERTYTEMPLATE.MSSqlReader") {
            key = "MSSQLCDC";
        } else if (properties?.handler === "Global.PROPERTYTEMPLATE.MSJet") {
            key = "MSJet";
        } else if (properties?.handler === "Global.PROPERTYTEMPLATE.PostgreSQLReader") {
            key = "PostgreSQLReader";
        } else if (properties?.handler === "Global.PROPERTYTEMPLATE.DatabaseReader") {
            if (properties.properties?.DatabaseProviderType === "Oracle") key = "OracleDB";
            else if (properties.properties?.DatabaseProviderType === "Oracle") key = "OracleDB";
            else if (properties.properties?.DatabaseProviderType === "SQLServer") key = "MSSQLDB";
            else if (properties.properties?.DatabaseProviderType === "MySQL") key = "MySQLDB";
            else if (properties.properties?.DatabaseProviderType === "Postgres") key = "PostgreSQLDB";
            else if (properties.properties?.DatabaseProviderType === "MariaDB") key = "MariaDB";
            else if (properties.properties?.DatabaseProviderType === "MariaDBXpandDB") key = "MariaDBXpand";
        }

        if (key && app) {
            let url = `#/appwizard/${app.nsName}.${app.name}/${key}-to-Striim/0/source-info`;
            return url;
        }
    },

    _lastSelectedAdapter: null,

    postRender: function() {
        this.fieldMapper.parser.setDataSource(
            new PropertyTemplateDataSource(metaStoreService.entities.SOURCE, this.fieldMapper.adapter.getValue())
        );
        this._setWizardLinkVisibility();
    }
});

export default Module;
