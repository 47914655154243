import PredefinedComponent from "./predefinedComponent";
import PredefinedComponentGroup from "./predefinedComponentGroup";
import PredefinedComponentNames from "./predefinedComponentNames.json";
import securityService from "core/services/securityService/securityService";

let isOdd = false;

const PredefinedComponentsDataSource = {
    load: function() {
        var collection = new PredefinedComponent.Collection();

        var inputGroup = collection.add(
            new PredefinedComponentGroup({
                id: "inputGroup",
                name: "Sources"
            })
        );
        let licensingOptions = securityService.licensingOptions;
        let isCosmosLicense = licensingOptions.indexOf("AzureCosmosDB") !== -1;

        inputGroup.components.add(createSourceModel(PredefinedComponentNames.ApacheAccessLog));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.Avro));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.Binary));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.CobolCopybookParser));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.CosmosDBReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.CollectD));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.DatabaseSource));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.DelimiterSeparatedValues));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.File));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.FreeFormText));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.GCSReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.GGTrail));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.GoldenGate));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.GoogleAdsReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.HDFS));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.HPNonStopCDC));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.HPNonStopSQLMP));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.HPNonStopSQLMX));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.Http));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.HubSpotReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.JiraReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.JMS));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.JMXReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.MariaDBReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.MariaDBXpandReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.MariaDB));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.JSON));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.Kafka));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.MapRFSReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.MongoDBReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.MongoCosmosDBReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.MQTT));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.MSSQLCDC));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.MSSQLDB));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.MSJet));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.MultiFileReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.MySQLCDC));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.MySQLDB));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.NameValuePair));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.NetFlow));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.OPCUAReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.IncrementalBatchReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.SpannerBatchReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.OracleCDC));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.OJet));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.OracleDB));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.MariaDBXpandDB));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.PostgreSQLReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.PostgreSQLDB));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.S3Reader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.Salesforce));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.SalesforcePushTopic));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.SalesforcePlatformEvent));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.ServiceNowReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.SalesforcePardotReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.SnowflakeCDCReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.SNMP));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.TCP));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.UDP));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.WindowsEventLog));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.XML));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.XMLParserV2));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.SalesforceCDCReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.ZendeskReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.SnowflakeILReader));
        //DO-NOT-REMOVE-BELOW-COMMENT
        //add-new-reader
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.YugabyteCDC));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.YugabyteDBReader));
        //        inputGroup.components.add(createSourceModel(PredefinedComponentNames.BambooHrReader));
        //        inputGroup.components.add(createSourceModel(PredefinedComponentNames.GoogleSheetsReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.BigQueryILReader));
        //        inputGroup.components.add(createSourceModel(PredefinedComponentNames.LinkedInAdsReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.StripeReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.ADLSReader));
        inputGroup.components.add(createSourceModel(PredefinedComponentNames.IntercomReader));
        //        inputGroup.components.add(createSourceModel(PredefinedComponentNames.GoogleAnalyticsReader));
        //        inputGroup.components.add(createSourceModel(PredefinedComponentNames.PaymoReader));

        inputGroup.isExpanded = true;

        var enrichmentGroup = collection.add(
            new PredefinedComponentGroup({
                id: "enrichmentGroup",
                name: "Enrichers"
            })
        );
        enrichmentGroup.components.add(createEnrichmentModel(PredefinedComponentNames.DBCache));
        enrichmentGroup.components.add(createEnrichmentModel(PredefinedComponentNames.FileCache));
        enrichmentGroup.components.add(createEnrichmentModel(PredefinedComponentNames.HDFSCache));
        enrichmentGroup.components.add(createExternalEnrichmentModel(PredefinedComponentNames.DBExternalCache));

        var processingGroup = collection.add(
            new PredefinedComponentGroup({
                id: "processingGroup",
                name: "Processors"
            })
        );
        processingGroup.components.add(createProcessingModel(PredefinedComponentNames.ContinuousQuery, "CQ"));
        processingGroup.components.add(createProcessingModel(PredefinedComponentNames.BaseRouter, "ROUTER"));
        processingGroup.components.add(createProcessingModel(PredefinedComponentNames.Window, "WINDOW"));

        // Transformation
        var transformationGroup = collection.add(
            new PredefinedComponentGroup({
                id: "transformationGroup",
                name: "Event Transformers"
            })
        );
        transformationGroup.components.add(
            createProcessingModel(PredefinedComponentNames.TransformationFieldAdder, "CQ")
        );
        transformationGroup.components.add(createProcessingModel(PredefinedComponentNames.TransformationMasking, "CQ"));
        transformationGroup.components.add(
            createProcessingModel(PredefinedComponentNames.TransformationFieldRemover, "CQ")
        );
        transformationGroup.components.add(
            createProcessingModel(PredefinedComponentNames.TransformationFieldRenamer, "CQ")
        );
        transformationGroup.components.add(
            createProcessingModel(PredefinedComponentNames.TransformationFieldSplitter, "CQ")
        );
        transformationGroup.components.add(
            createProcessingModel(PredefinedComponentNames.TransformationFieldEnricher, "CQ")
        );
        transformationGroup.components.add(
            createProcessingModel(PredefinedComponentNames.TransformationFieldTypeModifier, "CQ")
        );
        transformationGroup.components.add(
            createProcessingModel(PredefinedComponentNames.TransformationFieldFilter, "CQ")
        );
        transformationGroup.components.add(
            createProcessingModel(PredefinedComponentNames.TransformationJsonReader, "CQ")
        );
        transformationGroup.components.add(createProcessingModel(PredefinedComponentNames.TransformationShield, "CQ"));
        transformationGroup.components.add(
            createProcessingModel(PredefinedComponentNames.TransformationSentinel, "SENTINEL")
        );
        transformationGroup.components.add(
            createProcessingModel(PredefinedComponentNames.TransformationTypedEventToWaDbEvent, "CQ")
        );

        const isAppAdapter = securityService.isAppAdapterLicense();

        if (!isAppAdapter) {
            var dbTransformationGroup = collection.add(
                new PredefinedComponentGroup({
                    id: "dbTransformationGroup",
                    name: "DB Event Transformers"
                })
            );

            dbTransformationGroup.components.add(
                createProcessingModel(PredefinedComponentNames.TransformationWaeAddUserData, "CQ")
            );
            dbTransformationGroup.components.add(
                createProcessingModel(PredefinedComponentNames.TransformationWaeDataModifier, "CQ")
            );
            dbTransformationGroup.components.add(
                createProcessingModel(PredefinedComponentNames.TransformationWaeMetaFilter, "CQ")
            );
            dbTransformationGroup.components.add(
                createProcessingModel(PredefinedComponentNames.TransformationWaeOperatorFilter, "CQ")
            );
            dbTransformationGroup.components.add(
                createProcessingModel(PredefinedComponentNames.TransformationWaeDbTableFilter, "CQ")
            );
            dbTransformationGroup.components.add(
                createProcessingModel(PredefinedComponentNames.TransformationWaeToTypedEvent, "CQ")
            );
            dbTransformationGroup.components.add(
                createProcessingModel(PredefinedComponentNames.TransformationWaeToDwStage, "CQ")
            );
        }

        var outputGroup = collection.add(
            new PredefinedComponentGroup({
                id: "outputGroup",
                name: "Targets"
            })
        );
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.AzureBlobWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.AzureDataLakeStoreGen2));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.AzureEventHubWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.AzurePostgreSqlWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.AzureSQLDWHWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.BigQuery));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.CassandraWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.ClouderaHiveWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.CloudSqlPostgreSqlWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.CloudSqlMySqlWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.CloudSqlSQLServerWriter));
        outputGroup.components.add(
            createOutputModel(PredefinedComponentNames.CosmosDBWriter),
            isCosmosLicense ? { at: 0 } : undefined
        );
        outputGroup.components.add(
            createOutputModel(PredefinedComponentNames.CassandraCosmosDBWriter),
            isCosmosLicense ? { at: 0 } : undefined
        );
        outputGroup.components.add(
            createOutputModel(PredefinedComponentNames.MongoCosmosDBWriter),
            isCosmosLicense ? { at: 0 } : undefined
        );
        // outputGroup.components.add(createOutputModel(PredefinedComponentNames.CouchDBWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.Database));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.Email));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.FabricDataWarehouseWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.FabricLakehouseFileWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.OutputFile));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.GCSWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.GooglePubSubWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.HazelcastWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.HBase));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.OutputHDFS));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.HDInsightHDFSWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.HDInsightKafkaWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.HiveWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.HTTPWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.HortonWorksHiveWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.OutputJMS));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.JPAWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.OutputKafka));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.KinesisWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.KuduWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.MapRDBWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.MapRStreamWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.MapRFSWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.MariaDBDatabase));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.MongoDbWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.OutputMemSQLDatabase));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.Yellowbrick));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.Db2Zos));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.OutputMQTT));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.OutputMySQLDatabase));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.OutputOracleDatabase));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.OutputPostgresDatabase));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.OutputHPSQLMXDatabase));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.RedSh));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.SAPHANAWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.Slack));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.SalesforceWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.TeamsAlertAdapter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.SnowflakeWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.DeltaLakeWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.SpannerWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.SpannerPGDialectWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.S3));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.OutputSQLServerDatabase));
        // outputGroup.components.add(createOutputModel(PredefinedComponentNames.SMS));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.SysOut));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.WActionStore, "WACTIONSTORE"));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.WebAlertUI));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.ServiceNowWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.MicrosoftDataverseWriter));
        //DO-NOT-REMOVE-BELOW-COMMENT
        //add-new-writer
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.SalesforceMarketingCloudWriter));
        outputGroup.components.add(createOutputModel(PredefinedComponentNames.FabricMirrorWriter));

        var baseGroup = collection.add(
            new PredefinedComponentGroup({
                id: "baseGroup",
                name: "Base Components"
            })
        );
        baseGroup.components.add(createBaseModel(PredefinedComponentNames.BaseSource, "SOURCE"));
        baseGroup.components.add(createBaseModel(PredefinedComponentNames.BaseExternalSource, "EXTERNALSOURCE"));
        baseGroup.components.add(createBaseModel(PredefinedComponentNames.BaseCache, "CACHE"));
        //TODO: EventTable - icon should be updated
        baseGroup.components.add(createBaseModel(PredefinedComponentNames.BaseEventTable, "CACHE"));
        baseGroup.components.add(createBaseModel(PredefinedComponentNames.BaseWindow, "WINDOW"));
        baseGroup.components.add(createBaseModel(PredefinedComponentNames.BaseRouter, "ROUTER"));

        baseGroup.components.add(createBaseModel(PredefinedComponentNames.BaseProcessComponent, "OPENPROCESSOR"));
        baseGroup.components.add(createBaseModel(PredefinedComponentNames.BaseStream, "STREAM"));
        baseGroup.components.add(createBaseModel(PredefinedComponentNames.BaseCQ, "CQ"));
        baseGroup.components.add(createBaseModel(PredefinedComponentNames.BaseTarget, "TARGET"));
        baseGroup.components.add(createBaseModel(PredefinedComponentNames.BaseWActionStore, "WACTIONSTORE"));
        return collection;
    }
};

function createSourceModel(element) {
    return createElement("input", element, "SOURCE");
}

function createEnrichmentModel(element) {
    return createElement("enrichment", element, "CACHE");
}

function createExternalEnrichmentModel(element) {
    return createElement("enrichment", element, "EXTERNALCACHE");
}

function createProcessingModel(element, componentType) {
    return createElement("processing", element, componentType);
}

function createOutputModel(element, componentType) {
    return createElement("output", element, componentType || "TARGET");
}

function createBaseModel(element, componentType) {
    return createElement("base", element, componentType, "");
}

function createElement(type, element, componentType) {
    return new PredefinedComponent({
        id: type + "-" + element.name.replace(new RegExp(" ", "g"), "-"),
        name: element.name,
        componentType: componentType,
        iconClass: element.className,
        tooltip: element.tooltip,
        version: element.version
    });
}

export default PredefinedComponentsDataSource;
