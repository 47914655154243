import React, { useState } from "react";
import PropTypes, { InferProps } from "prop-types";
import { Card } from "@mui/material";

// @ts-ignore
import { StriimTab, StriimTabs } from "@striim/striim-ui";

import classes from "./guardian-tabs.styles";
import { GuardianTabsContext } from "./";
import { useNavigate } from "react-router";

const GuardianTabs = (props: InferProps<typeof GuardianTabs.propTypes>) => {
    const [currentIndex, setCurrentIndex] = useState(props.defaultOptionKey || props.options[0].id);
    const navigate = useNavigate();

    const handleChange = (_event: React.SyntheticEvent, newValue: String) => {
        if (newValue === currentIndex) {
            return;
        }
        setCurrentIndex(newValue);
    };

    return (
        <GuardianTabsContext.Provider value={props}>
            <StriimTabs {...props.tabsProps} value={currentIndex} onChange={handleChange}>
                {props.options.map((item, index) => (
                    <StriimTab
                        key={item.key}
                        label={item.label}
                        value={item.id}
                        icon={item.icon}
                        iconPosition="start"
                        sx={classes.tab}
                        onClick={() => {
                            navigate(`/sherlock-ai/${item.key}`);
                        }}
                        {...props.tabProps}
                    />
                ))}
            </StriimTabs>
            <Card sx={classes.card}>{props.options.find(item => item.id === currentIndex).component}</Card>
        </GuardianTabsContext.Provider>
    );
};

GuardianTabs.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    /**
     * Array of options
     */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            /**
             * uniq Key of option
             */
            key: PropTypes.string.isRequired,
            /**
             * Label of the tab
             */
            label: PropTypes.string.isRequired,
            /**
             * Component to render
             */
            component: PropTypes.node.isRequired
        })
    ),
    /**
     * Option key of default option.
     */
    defaultOptionKey: PropTypes.string,
    /**
     * Material-ui Tab props
     */
    tabProps: PropTypes.object,
    /**
     * Material-ui Tabs props
     */
    tabsProps: PropTypes.object,
    maskingOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ),
    tablesData: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            action: PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired
            })
        })
    ),
    // TODO: mock with actual contract
    jobsListData: PropTypes.object
};

export default GuardianTabs;
