import { GenericStore } from "../storeUtils";
import { observable, action } from "mobx";

export default class DashboardsStore extends GenericStore {
    @observable pageTitle = "";
    @observable activePageTitle = "Striim";
    @observable leftMenuOpen = false;

    @action setLeftMenuOpen(state = false) {
        this.leftMenuOpen = !!state;
    }

    @action setPageTitle(title) {
        this.pageTitle = title;
    }
}
