export const flowInputColor = "#346CC2";
export const flowOutputColor = "#2E8071";

const styles = {
    tableContainer: {
        "& .MuiTableContainer-root": {
            overflow: "visible"
        }
    },
    resizeDiv: {
        position: "absolute",
        borderRight: ({ palette }) => `2px solid ${palette.greyscale[200]}`,
        borderRadius: 1,
        height: "20px",
        right: "0px",
        top: "10px",
        cursor: "ew-resize"
    },
    rowStyles: {
        "& .MuiTableCell-root": {
            verticalAlign: "top",
            backgroundColor: "greyscale.50",
            height: "fit-content",
            padding: 0
        },
        ".MuiTableCell-head:first-child": {
            width: "20px"
        },
        "& .MuiCollapse-wrapper": {
            height: "140px",
            padding: "16px 10px"
        },
        "& .rowVisibleContent": {
            height: ({ spacing }) => spacing(8),
            backgroundColor: "#fff !important",
            padding: ({ spacing }) => spacing(1.25),
            borderBottom: "1px solid",
            borderColor: "greyscale.200",
            "& svg": {
                fill: "transparent"
            }
        },
        "& .MuiTypography-body4": { color: "greyscale.900" },
        "& .MuiTypography-body3": { color: "greyscale.700" },
        "& .MuiTypography-caption3": { color: "greyscale.500" }
    },
    rowStylesIl: {
        "& .MuiTableCell-root": {
            verticalAlign: "top",
            backgroundColor: "#fff",
            height: "fit-content",
            padding: 0,
            borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]} !important`
        },
        "& .rowVisibleContent": {
            padding: ({ spacing }) => spacing(1.25)
        },
        "& .MuiTypography-body4": { color: "greyscale.900" },
        "& .MuiTypography-caption3": { color: "greyscale.500" }
    },
    downloadBtn: {
        "& .MuiButton-startIcon": {
            "& svg": {
                fill: "transparent"
            }
        }
    },
    //

    summaryHeaderContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 1,
        "& .MuiTypography-root": {
            color: ({ palette }) => palette.primary[700]
        }
    },
    searchInput: {
        // width: "350px"
    },
    icon: {
        lineHeight: 0,
        width: "100%",
        display: "flex",
        gap: "2px",
        alignItems: "center",
        "& svg": {
            fill: "transparent"
        },
        "& .MuiLinearProgress-root": {
            width: "90%",
            marginTop: "0px"
        }
    },
    progress: {
        "& .MuiLinearProgress-root": {
            backgroundColor: " #EFEFEF",
            borderRadius: ({ spacing }) => spacing(0.25, 0, 0, 0.25),
            height: ({ spacing }) => spacing(1)
        }
    },
    linearInProgress: {
        "& .MuiLinearProgress-bar": {
            backgroundColor: flowInputColor
        }
    },
    linearOutProgress: {
        "& .MuiLinearProgress-bar": {
            backgroundColor: flowOutputColor
        }
    },
    tableSummary: {
        "& .table-styles": {
            height: "900px !important"
        }
    },
    column: {
        "& .rowelem": {
            display: "flex",
            alignItems: "center",
            margin: ({ spacing }) => spacing(0.5, 0),
            "& > div": {
                flex: 1
            },
            "& > span:first-child": {
                color: ({ palette }) => palette.greyscale[700],
                width: "120px"
            },
            "& >span:last-child": {
                color: ({ palette }) => palette.primary[700]
            }
        }
    }
};

export default styles;
