import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(({spacing}) => ({
    gridContainer: {
        padding: spacing(3),
        gap: 12,
        flexWrap: "nowrap",
        top: spacing(6),
        position: "relative",
        bottom: 0,
        height: "calc(100vh - 48px)"
    },
    gridItem: {width: 316, minWidth: 316},
    mainCard: {
        overflow: "auto",
        width: "inherit"
    },
    header: {marginTop: 0, marginBottom: spacing(2)}
}));
