import React, { useMemo, useRef, useState } from "react";
import { StriimBadge, StriimTable, StriimTypography } from "@striim/striim-ui";
import { ROLE_TABLE_STRINGS, roleColumnKey, roleTableColumns } from "../../constants";
import { useUserManagement } from "core/user-management/user-management-context";
import { isRoleDeletable, isRoleEditable } from "core/user-management/update.control";
import { DeleteModal } from "src/modules/apps/pages/user-management/common";
import USERS_ROUTES from "../../../routes.json";
import { Grid, useTheme } from "@mui/material";
import { DeleteIcon, EditIcon, EyeIcon } from "../../../../../../../generic/icon/customIcons";
import { useNavigate } from "react-router-dom";
import TableIconButton from "../../../common/table-icon-button";

const RoleTable = ({ roles }) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const { deleteRole } = useUserManagement();
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const roleTableRef = useRef();

    const roleTableColumnHeaders = roleTableColumns.map(v => {
        return v.accessor === roleColumnKey.role
            ? {
                  ...v,
                  Header: (
                      <StriimBadge badgeContent={roles.length} inline>
                          <StriimTypography variant="body3" color="greyscale.600">
                              {v.Header}
                          </StriimTypography>
                      </StriimBadge>
                  )
              }
            : v;
    });

    const handleDelete = roleName => {
        roleTableRef.current = roleName;
        setDeleteDialogVisible(true);
    };

    const onDeleteConfirm = () => {
        const roleName = roleTableRef.current;
        deleteRole(roleName);
        setDeleteDialogVisible(false);
    };

    const getFormattedList = (list, type) => {
        const seperator = type === ROLE_TABLE_STRINGS.label.role ? ";" : ",";
        const listItems = list?.map((v, i) => {
            return i !== 0 ? (
                <React.Fragment key={`${v.name}${i}`}>
                    {seperator} {v.formattedName || v.name}
                </React.Fragment>
            ) : (
                v.formattedName || v.name
            );
        });
        const listCountText = `${list.length.toString()} ${type}${list.length > 1 ? `s` : ``}`;
        const countFormat = <StriimTypography variant="bodySemibold">{listCountText}</StriimTypography>;
        return (
            list.length >= 1 && (
                <>
                    {countFormat} ({listItems})
                </>
            )
        );
    };

    const roleTableData = useMemo(
        () =>
            roles?.map(v => {
                const rolesFormatted = getFormattedList(v.roles, ROLE_TABLE_STRINGS.label.role);
                const permissionsFormatted = getFormattedList(v.permissionsText, ROLE_TABLE_STRINGS.label.permission);
                const permissions = (
                    <>
                        {rolesFormatted} {permissionsFormatted}
                    </>
                );
                const actions = (
                    <Grid container alignItems="center" justifyContent="flex-end">
                        <Grid item>
                            <TableIconButton
                                navigate={() =>
                                    navigate(`${USERS_ROUTES.usersRoleDetails}/${v[ROLE_TABLE_STRINGS.label.name]}`)
                                }
                                tooltipTitle="View"
                                Icon={EyeIcon}
                                dataTestId="view-details-button"
                            />
                        </Grid>
                        {isRoleEditable(v) && (
                            <Grid item>
                                <TableIconButton
                                    navigate={() =>
                                        navigate(
                                            `${USERS_ROUTES.usersRoleDetails}/${v[ROLE_TABLE_STRINGS.label.name]}`,
                                            {
                                                state: { canEdit: true }
                                            }
                                        )
                                    }
                                    tooltipTitle="Edit"
                                    Icon={EditIcon}
                                    dataTestId="edit-button"
                                />
                            </Grid>
                        )}
                        {isRoleDeletable(v) && (
                            <Grid item>
                                <TableIconButton
                                    navigate={() => handleDelete(v[ROLE_TABLE_STRINGS.label.name])}
                                    tooltipTitle="Delete"
                                    Icon={DeleteIcon}
                                    dataTestId="delete-button"
                                />
                            </Grid>
                        )}
                    </Grid>
                );
                return {
                    [roleColumnKey.role]: v.name,
                    [roleColumnKey.permissions]: permissions,
                    [roleColumnKey.actions]: actions
                };
            }),
        [roles]
    );

    return (
        <>
            <Grid container>
                <Grid item md={12}>
                    <StriimTable
                        columns={roleTableColumnHeaders}
                        data={roleTableData}
                        TableProps={{ "data-testid": "role-management-table" }}
                        TableHeaderCellProps={{
                            style: {
                                background: palette.greyscale[50]
                            }
                        }}
                        indexedRow
                        TableBodyProps={{ seperated: true }}
                    />
                </Grid>
            </Grid>
            <DeleteModal
                id={roleTableRef?.current}
                type={"role"}
                deleteDialogVisible={deleteDialogVisible}
                setDeleteDialogVisible={setDeleteDialogVisible}
                onDeleteConfirm={onDeleteConfirm}
            />
        </>
    );
};

export default RoleTable;
