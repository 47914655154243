export const wizardDetails = {
    AUTOMATED: {
        name: "AUTOMATED",
        className: "automated",
        variant: "intresting",
        typographyColor: "primary.500"
    },
    IL_WITH_SCHEMA_CONVERSION: {
        name: "INITIAL LOAD (AUTO-SCHEMA CONVERSION)",
        className: "schemaConversion",
        variant: "default",
        typographyColor: "greyscale.700"
    },
    AUTOMATED_MODE_APP_ADAPTER: {
        name: "AUTOMATED MODE",
        className: "automated",
        variant: "intresting",
        typographyColor: "primary.500"
    }
};
