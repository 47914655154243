import { action, computed, observable } from "mobx";
import ApplicationModel from "./application-model";
import { AppAction } from "../status-management";
import navigateTo from "src/navigate-to";
import api from "../../core/api/api";
import Bowser from "bowser";
import FileSaver from "file-saver";
import _ from "underscore";
import growl from "app/components/common/growl";
import Tracker from "core/services/tracker/tracker";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import metaStoreService from "../../core/services/metaStoreService/meta-store-service";
import App from "../../app";
import exceptionsStoreApi from "app/components/flow/designer/app-exceptions/exception-store-api";
import securityService from "../../core/services/securityService/securityService";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import statusManagement from "src/status-management";
import menuOption from "app/components/common/menu/menu-option";
import { TRACKER_STRINGS } from "../../core/services/tracker/constants";

export default class AppListStore {
    @observable fullTextSearchFilter = null;
    @observable statusFilter = null;
    @observable statusFilters = null;
    @observable namespaceFilter = null;
    @observable namespaceFilters = null;
    @observable apps = [];
    @observable appsListStatsFetchedToggle = false;
    api;
    metaStoreService;
    appStatusSynchronizer;
    actions;

    constructor(api, metaStoreService, appStatusSynchronizer) {
        this.api = api;
        this.metaStoreService = metaStoreService;
        this.appStatusSynchronizer = appStatusSynchronizer;
        this.actions = new menuOption.Model.Collection(statusManagement.appActions);
    }

    notifyFailure(action, error) {
        growl.error(this.actions.get(action.toUpperCase()).text + " failed! " + error.message + "", "Error");
    }

    disposeListeners() {
        //TODO: handle dispose, when navigating away from app list page
        // appStatusSynchronizer.off("status_change");
        App.vent.off("progress:start");
        App.vent.off("progress:end");
    }

    // Update progress bar for deploy and export functionality from backbone.

    @action listenToProgressBarChange() {
        App.vent.on("progress:start", appId => {
            const app = this.apps.find(appl => appl.id === appId);
            if (app) {
                app.isFetching = true;
            }
        });

        App.vent.on("progress:end", appId => {
            const app = this.apps.find(appl => appl.id === appId);
            if (app) {
                app.isFetching = false;
            }
        });
    }

    @action listenToAppStatusChange(callback) {
        this.appStatusSynchronizer.on("status_change", ({ appId, newStatus, isTranscientStatus }) => {
            const app = this.apps.find(app => app.id === appId);
            if (app) {
                app.flowStatus = newStatus;
                app.isFetching = isTranscientStatus;
                callback && callback();
            }
        });
    }

    @action
    async refreshAllStatuses(callback) {
        const statuses = await api.getAllApplicationStatuses();
        if (!statuses) {
            return;
        }
        Object.keys(statuses).forEach(appId => {
            const app = this.apps.find(app => app.id === appId);
            if (app) {
                const status = statuses[appId];
                app.flowStatus = status;
                app.isFetching = statusManagement.isTransientStatus(status);
            }
        });

        callback && callback();
    }

    @action
    async onAppAction(app, action, extraParameters) {
        app.isFetching = true;
        switch (action) {
            case AppAction.VIEW:
                app.isFetching = false;
                navigateTo.AppRedirect(app.shortName);
                break;
            case AppAction.MONITOR:
                app.isFetching = false;
                navigateTo.Monitor(app.shortName);
                break;
            case AppAction.EXPORT:
                if (Bowser.safari) {
                    growl.warning("Please press cmd+s in the new tab opened to manually save the TQl.");
                }
                FileSaver.saveAs(
                    new Blob([await api.createTQL(app.shortName)], {
                        type: "text/plain"
                    }),
                    app.shortName + "_" + _.now() + ".tql"
                );
                app.isFetching = false;
                break;
            case AppAction.START:
                Tracker.getInstance().track(TRACKER_STRINGS.schema.appControl, {
                    id: app.name,
                    event: TRACKER_STRINGS.eventClicked.appControl,
                    buttonClicked: "Start"
                });
                try {
                    await api.startApp(app.shortName);
                } catch (error) {
                    this.notifyFailure("start", error);
                } finally {
                    app.isFetching = false;
                }
                break;
            case AppAction.STOP:
                try {
                    await api.stopApp(app.shortName);
                } catch (error) {
                    this.notifyFailure("stop", error);
                } finally {
                    app.isFetching = false;
                }
                break;
            case AppAction.QUIESCE:
                try {
                    await api.quiesceApp(app.shortName);
                } catch (error) {
                    this.notifyFailure("stop", error);
                } finally {
                    app.isFetching = false;
                }
                break;
            case AppAction.UNDEPLOY:
                try {
                    await api.undeploy(app.shortName);
                } catch (error) {
                    this.notifyFailure("undeploy", error);
                } finally {
                    app.isFetching = false;
                }
                break;
            case AppAction.DEPLOY:
                try {
                    await api.deploy(extraParameters, {
                        namespace: app.nsName,
                        appName: app.name
                    });
                } catch (error) {
                    this.notifyFailure("deploy", error);
                } finally {
                    app.isFetching = false;
                }
                break;
            case AppAction.RESUME:
                try {
                    await api.resumeApp(app.shortName);
                } catch (error) {
                    this.notifyFailure("resume", error);
                } finally {
                    app.isFetching = false;
                }
                break;
            case AppAction.DROP:
                return new Promise((resolve, reject) => {
                    function dropApp() {
                        return api
                            .dropObject({
                                type: "application",
                                name: app.shortName
                            })
                            .then(
                                () => {
                                    //We need to wait a moment after app delete so server will not return it in fetch apps
                                    setTimeout(() => {
                                        growl.success("", "Application dropped successfully.");
                                        app.isFetching = false;
                                        resolve();
                                    }, 500);
                                },
                                error => {
                                    app.isFetching = false;
                                    reject(error);
                                }
                            );
                    }

                    return metaStoreService.findById(app.id).then(application => {
                        if (
                            application.get("exceptionstoreName") !== null &&
                            !extraParameters.doNotDeleteExceptionStore
                        ) {
                            // delete exceptionstore and then delete the app
                            return exceptionsStoreApi
                                .deleteLogs(app.id)
                                .then(
                                    result => {
                                        if (result !== true) {
                                            console.warn("cannot delete logs for " + app.id, result);
                                        }
                                        growl.success("", "ExceptionStore deleted successfully.");
                                    },
                                    () => {
                                        growl.error("", "There was a problem deleting the Exception Store.");
                                    }
                                )
                                .finally(() => {
                                    dropApp();
                                });
                        } else {
                            dropApp();
                        }
                    });
                });
            case AppAction.DASHBOARD:
                app.isFetching = false;
                App.vent.trigger("dashboard:navigate", app.dashboardId, undefined, {});
                break;
            case AppAction.SHOW_ERRORS:
                app.isFetching = false;
                navigateTo.AppErrors(app.shortName, true);
                break;
            case AppAction.CHANGE_GROUP:
                app.isFetching = false;
                break;
            case AppAction.SHERLOCK:
                navigateTo.AppSherlock(app.shortName, null, true);
                break;
        }
    }

    @action
    async fetchApps() {
        const identifiers = await this.metaStoreService.fetchIdentifiersByType(
            this.metaStoreService.entities.APPLICATION
        );
        this.apps = identifiers.models.map(app => {
            const applicationModel = new ApplicationModel();
            applicationModel.id = app.id;
            applicationModel.nsName = app.nsName;
            applicationModel.name = app.name;
            applicationModel.metaInfoStatus = app.metaInfoStatus;
            applicationModel.isEditable = app.isEditable;
            applicationModel.flowStatus = this.appStatusSynchronizer.getStatus(app.id);
            applicationModel.isFetching = this.appStatusSynchronizer.isTransientStatus(app.id);
            applicationModel.ctime = app.ctime;
            return applicationModel;
        });

        try {
            const appIds = this.apps.map(x => x.id);
            await api.findAttachedDashboardsForApps(appIds).then(data => {
                _.each(data, (dashboardList, appID) => {
                    const app = this.apps.filter(x => x.id === appID)[0];
                    if (!app) {
                        return;
                    }

                    if (dashboardList && dashboardList.length > 0) {
                        app.dashboardId = dashboardList[0];
                    }
                });
            });
        } catch (e) {
            console.error(e);
        }
    }

    @action
    async getAppListTableStats() {
        const getComponents = (metric, componentType) => {
            const theComponents = [];
            _.map(metric, theData => {
                if (theData.type === componentType) {
                    theComponents.push(theData);
                }
            });
            return _.uniq(theComponents, component => component.name);
        };
        try {
            const data = await api.getAppListTableStats(
                this.apps.map(app => app.id),
                ["SOURCE", "TARGET", "EXTERNALSOURCE"]
            );
            this.appsListStatsFetchedToggle = !this.appsListStatsFetchedToggle;
            this.apps.map(app => {
                app.sources = [
                    ...getComponents(data[app.id], "SOURCE"),
                    ...getComponents(data[app.id], "EXTERNALSOURCE")
                ];
                app.targets = getComponents(data[app.id], "TARGET");
                // Not always exists
                app.mostRecentData = getComponents(data[app.id], "APPLICATION")[0]?.["most-recent-data"];
            });
        } catch (e) {
            console.error(e);
        }
    }

    @computed get isFilterApplied() {
        return (
            this.fullTextSearchFilter ||
            this.namespaceFilter ||
            this.statusFilter ||
            this.namespaceFilters?.length ||
            this.statusFilters?.length
        );
    }

    // TODO: clean this up to map over an array of filter consts
    @computed get visibleApps() {
        let visibleApps = [...this.apps];
        if (this.fullTextSearchFilter) {
            visibleApps = visibleApps.filter(
                x => x.name.toLowerCase().indexOf(this.fullTextSearchFilter.toLowerCase()) !== -1
            );
        }
        if (this.namespaceFilters) {
            // visibleApps = visibleApps.filter(x => x.nsName.toLowerCase() === this.namespaceFilter.toLowerCase());
            visibleApps = visibleApps.filter(x =>
                this.namespaceFilters.some(filter => filter.value.toLowerCase() === x.nsName.toLowerCase())
            );
        }
        if (this.statusFilters) {
            visibleApps = visibleApps.filter(x =>
                this.statusFilters.some(filter => filter.value.toLowerCase() === x.flowStatus.toLowerCase())
            );
        }
        return visibleApps;
    }

    @computed get applicationGroupsDisabled() {
        return !securityService.isSegmentationFeatureEnabled(addonFeaturesKeys.APPLICATIONGROUPS);
    }

    @computed get namespaces() {
        return Array.from(new Set(this.apps.map(x => x.nsName))).sort();
    }

    @action getDeploymentGroups() {
        return propertyTemplateService.deploymentGroups.models
            .map(g => g.name)
            .sort(g => (g === "default" ? -1 : 1))
            .filter(g => g !== "DefaultAgentMonitoring");
    }

    @action async applyFullTextSearchFilter(search) {
        this.fullTextSearchFilter = search;
        this.clearSelection();
    }

    @action async applyStatusFilters(status) {
        this.statusFilters = status;
        this.clearSelection();
    }

    @action async applyNamespaceFilter(namespaces) {
        this.namespaceFilters = namespaces;
        this.clearSelection();
    }

    @action resetFilters() {
        this.fullTextSearchFilter = null;
        this.statusFilter = null;
        this.statusFilters = null;
        this.namespaceFilter = null;
        this.namespaceFilters = null;
    }

    @action async getFlows(app) {
        const flows = [app.name];
        let applicationComponentsByType = await api.getApplicationComponentsByType(
            app.id,
            metaStoreService.entities.FLOW,
            true
        );
        flows.push(...applicationComponentsByType.map(x => x.name));
        return flows;
    }

    @action clearSelection() {
        this.apps.forEach(x => (x.isSelected = false));
    }

    @computed get selectedApps() {
        return this.apps.filter(x => x.isSelected);
    }

    @action selectAll() {
        this.visibleApps.forEach(x => (x.isSelected = true));
    }

    @computed get numberOfSelectedApps() {
        if (!this.apps) {
            return 0;
        }
        return this.selectedApps.length;
    }
}
