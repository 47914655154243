const tabButton = {
    height: "20px",
    color: "greyscale.100",
    "& svg": {
        width: "16px",
        height: "16px",
        fill: "none"
    }
};

export const styles = {
    icon: {
        fontSize: 16,
        fill: "none"
    },
    iconInfo: {
        height: 16,
        width: 16,
        fill: "transparent",
        "& path": {
            stroke: theme => theme.palette.greyscale[600]
        }
    },
    endAdornmentContainer: {
        backgroundColor: "transparent",
        color: "greyscale.800",
        border: "none",
        padding: 0.5,
        "&:hover, &:active": {
            backgroundColor: "transparent",
            border: "none"
        }
    },
    iconButton: {
        width: 20,
        height: 20
    },
    autoWidth: {
        width: "auto"
    },
    titleContainer: {
        height: 36
    },
    tabButton: {
        ...tabButton
    },
    activeButton: {
        ...tabButton,
        backgroundColor: "#B2E4FF",
        color: "#050529"
    },
    headingContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between"
    },
    simpleName: {
        flex: 1,
        textAlign: "right",
        color: "greyscale.500",
        fontSize: "10px"
    },
    jobStatus: {
        display: "flex",
        alignItems: "center",
        gap: 0.5,
        cursor: "pointer"
    },
    dataTableWrapper: {
        "& .appNamecellClass": {
            width: "inherit",
            display: "flex",
            "& .ag-cell-wrapper": {
                width: "inherit"
            },
            "& .ag-cell-wrapper > span > div": {
                display: "flex",
                flexWrap: "nowrap"
            }
        }
    }
};
