export const styles = {
    containerStyles: {
        border: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
        borderRadius: ({ spacing }) => spacing(1),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
        background: "#fff"
    },
    iconStyles: {
        width: "8px",
        height: "8px",
        marginRight: "8px"
    },
    divider: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    controlLabel: { 
        textOverflow: "ellipsis", 
        overflow: "hidden" 
    }
};
