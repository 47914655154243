import App from "app";
import appStatusSynchronizer from "core/appStatusSynchronizer";
import dashboardSynchronizer from "core/dashboardSynchronizer";
import securityService from "core/services/securityService/securityService";
import colorService from "core/services/appservice/colorService";
import "core/services/metadataService/dashboards";
import "core/services/metadataService/pages";
import "core/services/dataService/queryService";
import "core/services/appservice/query_manager/query_manager";
import "core/services/consoleQService/consoleQService";
import "./constants";

App.addInitializer(function() {
    securityService.start();
    colorService.start();
    appStatusSynchronizer.start();
    dashboardSynchronizer.start();
    App.on(
        "app:authenticated",
        function() {
            this.consoleQService = new App.ConsoleQService.Service();
        }.bind(this)
    );

    window.addEventListener(
        "beforeunload",
        function() {
            if (this.consoleQService) {
                this.consoleQService.unsubscribe();
            }
        }.bind(this)
    );
});
