export const styles = {
    wrapper: {
        position: "relative",
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "column",
        height: "calc(100vh - 48px)",
        top: 48,
        padding: ({ spacing }) => spacing(4)
    },
    wrapperWithFooter: {
        height: "calc(100vh - 100px)"
    },
    childContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        // padding: ({ spacing }) => spacing(2, 3),
        // backgroundColor: "#F7FBFF",
        position: "relative",
        bottom: 0,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "greyscale.200",
        borderRadius: ({ spacing }) => spacing(1),
        overflowY: "auto"
    },
    contentContainer: {
        padding: ({ spacing }) => spacing(2, 3),
        backgroundColor: "additional.white",
        height: "calc(100vh - 220px)",
        overflowY: "scroll"
    },
    withRightSidebar: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
    },
    gridContainer: {
        padding: ({ spacing }) => spacing(3),
        gap: 12,
        flexWrap: "nowrap",
        top: ({ spacing }) => spacing(6),
        position: "relative",
        bottom: 0,
        width: "calc(100vh - 48px)"
    },
    gridItem: { width: 316, minWidth: 316 },
    header: { marginTop: 0, marginBottom: ({ spacing }) => `${spacing(2)} !important` }
};
