import { Model } from "backbone.nestedtypes";

export default Model.extend({
    defaults: {
        title: String,
        status: String,
        message: String,
        date: Date,
        address: String,
        fullAddress: String,
        server: String,
        formattedDate: String,
    },
});
