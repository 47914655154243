import _ from "underscore";
import base from "./base";

var Current = function () {};

Current.prototype = _.extend(new base(), {
    _addItems: function (xadded, data, fieldList) {
        var newObject = this._transformData(xadded[xadded.length - 1].data, fieldList);
        data.push(newObject);
        return data;
    },

    _evictItems: function (data) {
        // Trim the data to the maximum size
        if (data.length > 1) {
            data = data.slice(data.length - 1);
        }
        return data;
    },
});

export default Current;
