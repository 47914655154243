// https://api.highcharts.com/highcharts/tooltip
export const tooltipStriimlineHighcharts = {
  backgroundColor: "#48525C",
  style: {
      color: "#fff",
    cursor: "auto"
  },
  borderWidth: 0,
  borderRadius: 8,
  shadow: false
}

export const tooltipStriimlineMakeStyles = {
  backgroundColor: "#48525C",
  color: "#ffffff",
  padding: "4px 8px"
}
