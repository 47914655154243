import Backbone from "backbone";
import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var module = {};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "CQ",
        select: String,
        output: null,
        inputs: Array,
        uiconfig: Object,
    },
    properties: {
        header: function () {
            if (this.uiconfig && this.uiconfig.subType) {
                return this.uiconfig.subType;
            } else {
                return this.defaultHeader;
            }
        },
    },
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model,
});

export default module;
