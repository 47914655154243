import { ItemView } from "marionette";
import eventsEntryTemplate from "./templates/eventsEntryTemplate.html";
import { className, template, on, ui } from "core/decorators";

@className("log-entry")
@template(eventsEntryTemplate)
@ui({
    message: ".entry-message",
    showMoreButton: "a.show-more",
})
export default class EventsEntryView extends ItemView {
    initialize() {
        if (this.model.get("title")) {
            let nl2brTitle = this.model.get("title").replace(/\\n/g, "<br />");
            this.model.set("title", nl2brTitle);
        }
        if (this.model.get("message")) {
            let nl2brMessage = this.model.get("message").replace(/\\n/g, "<br />");
            this.model.set("message", nl2brMessage);
        }
    }

    @on("click @ui.showMoreButton")
    onShowMore() {
        this.ui.message.toggleClass("show-all");
        if (this.ui.message.hasClass("show-all")) {
            this.ui.showMoreButton.text("See less");
        } else {
            this.ui.showMoreButton.text("... See more");
        }
    }
}
