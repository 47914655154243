const recentlyUsedAdaptersService = {
    localStorageKey: "create-app-page-recently-used-adapters",
    size: 5,
    //FIFO
    push: function(arr: string[], elem: string): void {
        if (arr.includes(elem)) {
            arr.splice(arr.indexOf(elem), 1);
        }
        if (arr.length >= this.size) {
            arr.pop();
        }
        arr.unshift(elem);
    },
    add: function({ sourceAdapter, targetAdapter }: { sourceAdapter?: string; targetAdapter?: string }): void {
        const recentlyUsedSources = this.getSources();
        const recentlyUsedTargets = this.getTargets();
        if (sourceAdapter) {
            this.push(recentlyUsedSources, sourceAdapter);
        }
        if (targetAdapter) {
            this.push(recentlyUsedTargets, targetAdapter);
        }
        const adapters = {
            sources: recentlyUsedSources,
            targets: recentlyUsedTargets
        };
        localStorage.setItem(this.localStorageKey, JSON.stringify(adapters));
    },
    get: function(): { sources: string[]; targets: string[] } {
        try {
            const allAdaptersString = localStorage.getItem(this.localStorageKey);
            if (!allAdaptersString) {
                return {
                    sources: [],
                    targets: []
                };
            }
            return JSON.parse(allAdaptersString);
        } catch (e) {
            console.error("Error occurred while fetching recently-used-adapters");
            return {
                sources: [],
                targets: []
            };
        }
    },
    getSources: function(): string[] {
        const allAdapters = this.get();
        return allAdapters.sources;
    },
    getTargets: function(): string[] {
        const allAdapters = this.get();
        return allAdapters.targets;
    }
};
export default recentlyUsedAdaptersService;
