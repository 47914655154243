import App from "app";
import WAHighChartsMixin from "app/components/dashboard/visualization/charts/gauge/mixins/gauge-highcharts";
import "app/components/dashboard/visualization/charts/chart";

//
App.module("Chart.Gauge", function (Gauge, App, Backbone, Marionette, $, _) {
    Gauge.View = App.Chart.Default.ChartView.extend({
        initialize: function () {
            // Mixin the Highcharts line chart
            _(this).extend(WAHighChartsMixin);

            // Call super initialize
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
        },
    });
});
