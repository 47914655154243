// https://codepen.io/frontendpens/pen/Xxrrpb
// https://codepen.io/devsamc/pen/eZGvPe

export function initMaterialDesignDropdown(targetEl) {
    // reference to last opened menu
    var lastOpened: HTMLElement | undefined = undefined;

    // simply close the last opened menu on document click
    document.addEventListener("click", function() {
        if (lastOpened) {
            lastOpened.classList.remove("open");
        }
    });

    targetEl.addEventListener("click", function(event: any) {
        // jquery wrap the el
        const el = this;

        // prevent this from propagating up
        event.preventDefault();
        event.stopPropagation();

        // check for open state
        if (el.classList.contains("open")) {
            el.classList.remove("open");
        } else {
            if (lastOpened) {
                lastOpened.classList.remove("open");
            }
            el.classList.add("open");
            lastOpened = el;
        }
    });
}
