import App from "app";
import _ from "underscore";
import "./defaultmetaobject";

const Datavisualizations = {};

Datavisualizations.EDITOR = {
    AXES: "AXES",
    AXIS: "AXIS",
    EXPRESSION_ID: "EXPRESSION_ID",
    PROPERTIES: "PROPERTIES",
    COLLECTION: "COLLECTION",
    CONDITION: "CONDITION",
    STRING: "STRING",
    TEXT: "TEXT",
    NUMBER: "NUMBER",
    ICON: "ICON",
    COLOR: "COLOR",
    ENUM: "ENUM",
    FORMATTER_FUNCTION: "STRING", // TODO: for now, just a String
    BOOLEAN: "BOOLEAN",
    FIELD: "FIELD", // TODO: this is the Axis select from the current data series editor
    PAGE: "PAGE",
    HEADING: "HEADING",
    HEADING_SELECTOR: "HEADING_SELECTOR"
};
Datavisualizations.EDITOR_STYLE = {
    CAROUSEL: "CAROUSEL"
};

Datavisualizations.WEBACTION_TYPES = {};

Datavisualizations.WEBACTION_TYPES.PAGE_PARAM_FILTER = {
    name: "PageParamFilter",
    properties: [
        {
            attribute: "visible",
            value: true
        }
    ]
};

Datavisualizations.WEBACTION_TYPES.POLLING_QUERY_SETTINGS = {
    name: "PollingQuerySettings",
    properties: [
        {
            attribute: "pollingEnabled",
            value: false
        },
        {
            attribute: "pollingInterval",
            value: 5
        }
    ]
};

Datavisualizations.WEBACTION_TYPES.HEADING_TYPES1 = {
    name: "Heading",
    properties: [
        {
            attribute: "Dashboard Title",
            value: "1"
        },
        {
            attribute: "Dashboard Subtitle",
            value: "2"
        },
        {
            attribute: "Heading",
            value: "3"
        },
        {
            attribute: "Label",
            value: "4"
        }
    ]
};

// drilldown
Datavisualizations.WEBACTION_TYPES.DRILLDOWN = {
    name: "DrillDown",
    properties: [
        {
            attribute: "show_drilldown",
            value: false
        },
        {
            attribute: "page",
            type: String
        },
        {
            attribute: "pageparams",
            type: {
                itemtype: {
                    properties: [
                        {
                            attribute: "pageparam",
                            type: String
                        },
                        {
                            attribute: "srcfield",
                            type: String
                        }
                    ]
                }
            }
        }
    ]
};

Datavisualizations.WEBACTION_TYPES.YAXISLIMITS = {
    name: "YAxisLimits",
    properties: [
        {
            attribute: "upperBound",
            type: String
        },
        {
            attribute: "lowerBound",
            type: String
        }
    ]
};

// tooltips
Datavisualizations.WEBACTION_TYPES.TOOLTIPS = {
    name: "Tooltips",
    properties: [
        {
            attribute: "show_all",
            value: true
        },
        {
            attribute: "tooltip_values",
            type: {
                itemtype: {
                    properties: [
                        {
                            attribute: "label",
                            type: String
                        },
                        {
                            attribute: "srcfield",
                            type: String
                        }
                    ]
                }
            }
        }
    ]
};

// areas
Datavisualizations.WEBACTION_TYPES.AREAS = {
    name: "Areas",
    itemtype: {
        properties: [
            {
                attribute: "label",
                type: String
            },
            {
                attribute: "ID",
                type: String
            },
            {
                attribute: "defaultColor",
                type: String
            },
            {
                attribute: "colors",
                type: {
                    itemtype: {
                        properties: [
                            {
                                attribute: "color",
                                value: "#000"
                            },
                            {
                                attribute: "expression",
                                type: String
                            }
                        ]
                    }
                }
            },
            {
                attribute: "coordinates",
                type: {
                    itemtype: {
                        properties: [
                            {
                                attribute: "coordinate"
                            }
                        ]
                    }
                }
            },
            {
                attribute: "drillDownPage",
                type: String
            }
        ]
    }
};

// condition
Datavisualizations.WEBACTION_TYPES.CONDITION = {
    name: "WebActionCondition",
    properties: [
        {
            attribute: "expression",
            type: String
        }, // Expression string (eval()-able)
        {
            attribute: "matched",
            value: false
        } // Does this expression eval to true given current data?
    ]
};

// conditions
Datavisualizations.WEBACTION_TYPES.CONDITIONS = {
    name: "WebActionConditions",
    itemtype: Datavisualizations.WEBACTION_TYPES.CONDITION
};

// color
Datavisualizations.WEBACTION_TYPES.CONDITIONAL_COLORS = {
    name: "ConditionalColors",
    itemtype: {
        extends: Datavisualizations.WEBACTION_TYPES.CONDITION,
        properties: [
            {
                attribute: "style",
                type: {
                    properties: [
                        {
                            attribute: "color",
                            value: "#000"
                        },
                        {
                            attribute: "alias",
                            value: ""
                        }
                    ]
                }
            }
        ]
    }
};

Datavisualizations.WEBACTION_TYPES.MARKER_TYPES = {
    name: "MarkerTypes",
    itemtype: {
        extends: Datavisualizations.WEBACTION_TYPES.CONDITION,
        properties: [
            {
                attribute: "markerType",
                value: "Circle"
            }
        ]
    }
};

// graph
Datavisualizations.WEBACTION_TYPES.GRAPH = {
    name: "WebActionGraph",
    initialize: function() {
        this.set_colors();
        this.listenTo(this, "change:colors", this.set_colors);
        this.listenTo(this.series, "add remove", this.set_colors);
        this.listenTo(this.series, "change:alias", this.set_color);
    },
    // TODO: this method determines what color is assigned to a series.
    // Currently, it simply returns a random color from the theme colors.
    get_color: function(series) {
        return App.request("get:color:byalias", series.alias);
    },
    set_color: function(series) {
        series.color = this.get_color(series);
    },
    set_colors: function() {
        var colors = this.colors || [];
        if (colors.length === 0) {
            return;
        }
        this.series.each(
            function(series /*, i*/) {
                if (series.color) {
                    return; // Keep saved colors as they are.
                }
                series.color = this.get_color(series.alias);
            }.bind(this)
        );
    },
    properties: [
        {
            attribute: "retentionStrategyType",
            type: String,
            value: "all"
        },

        {
            attribute: "type",
            type: String,
            value: null
        },
        {
            attribute: "categoryField",
            type: String,
            value: ""
        },
        {
            attribute: "timeField",
            type: String,
            value: ""
        },
        {
            attribute: "colorField",
            type: String,
            value: ""
        },
        {
            attribute: "xAxis",
            type: {
                itemtype: {
                    name: "Axis",
                    properties: [
                        {
                            attribute: "title",
                            type: String
                        },
                        {
                            attribute: "field",
                            type: String
                        },
                        {
                            attribute: "type",
                            value: "linear"
                        },
                        {
                            attribute: "dateTimeFormat",
                            value: String
                        }
                    ]
                }
            }
        },
        {
            attribute: "yAxis",
            type: {
                itemtype: {
                    name: "Axis",
                    properties: [
                        {
                            attribute: "title",
                            type: String
                        },
                        {
                            attribute: "field",
                            type: String
                        },
                        {
                            attribute: "type",
                            value: "linear"
                        }
                    ]
                }
            }
        },
        {
            attribute: "zAxis",
            type: {
                itemtype: {
                    name: "Axis",
                    properties: [
                        {
                            attribute: "title",
                            type: String
                        },
                        {
                            attribute: "field",
                            type: String
                        },
                        {
                            attribute: "type",
                            value: "linear"
                        }
                    ]
                }
            }
        },
        {
            attribute: "series",
            type: {
                itemtype: {
                    name: "AxesSeries",
                    properties: [
                        {
                            attribute: "name",
                            type: String
                        },
                        {
                            attribute: "visible",
                            value: null
                        },
                        {
                            attribute: "alias",
                            type: String
                        },
                        // TODO: set this explicitly, even if we pull from a library of colors
                        {
                            attribute: "color",
                            value: null,
                            type: String
                        },
                        {
                            attribute: "yAxis",
                            type: String
                        },
                        {
                            attribute: "xAxis",
                            type: String
                        },
                        {
                            attribute: "zAxis",
                            type: String
                        },
                        {
                            attribute: "categories",
                            type: String
                        },
                        {
                            attribute: "data"
                        }
                    ]
                }
            }
        },
        {
            attribute: "filter",
            type: {
                extends: Datavisualizations.WEBACTION_TYPES.PAGE_PARAM_FILTER
            }
        },
        {
            attribute: "pollingQuerySettings",
            type: {
                extends: Datavisualizations.WEBACTION_TYPES.POLLING_QUERY_SETTINGS
            }
        },
        {
            attribute: "drilldown",
            type: {
                extends: Datavisualizations.WEBACTION_TYPES.DRILLDOWN
            }
        },
        {
            attribute: "tooltips",
            type: {
                extends: Datavisualizations.WEBACTION_TYPES.TOOLTIPS
            }
        },
        {
            attribute: "conditionalColor",
            type: {
                extends: Datavisualizations.WEBACTION_TYPES.CONDITIONAL_COLORS
            }
        },
        {
            attribute: "show_legend",
            value: false
        },
        {
            attribute: "show_all_labels",
            value: false
        },
        {
            attribute: "show_data_labels",
            value: false
        },
        {
            attribute: "yAxisLimits",
            type: {
                extends: Datavisualizations.WEBACTION_TYPES.YAXISLIMITS
            }
        },
        {
            attribute: "maxValues",
            type: String,
            value: "100"
        },
        {
            attribute: "maxPoints",
            type: String,
            value: "500"
        },
        {
            attribute: "maxSeries",
            type: String,
            value: "20"
        },
        {
            attribute: "zIndex",
            type: Number,
            value: "1"
        }
    ]
};

// map
Datavisualizations.WEBACTION_TYPES.MAP = {
    name: "Map",
    properties: [
        {
            attribute: "locationType",
            value: "LONGITUDE"
        },
        {
            attribute: "latitudeField",
            value: ""
        },
        {
            attribute: "longitudeField",
            value: ""
        },
        {
            attribute: "countryCode",
            type: String
        },
        {
            attribute: "valueField",
            value: ""
        },
        {
            attribute: "viewCenterLatitude",
            value: -50
        },
        {
            attribute: "viewCenterLongitude",
            value: 36
        },
        {
            attribute: "viewXOffset",
            value: null
        },
        {
            attribute: "viewYOffset",
            value: null
        },
        {
            attribute: "viewZoom",
            value: null
        },
        {
            attribute: "data",
            type: Array
        },
        {
            attribute: "tilesUrl",
            value: "http://{s}.tile.osm.org/{z}/{x}/{y}.png"
        },
        {
            attribute: "minBubbleSize",
            value: 10
        },
        {
            attribute: "maxBubbleSize",
            value: 10
        },
        {
            attribute: "retentionStrategyType",
            type: String,
            value: "all"
        },
        {
            attribute: "categoryField",
            type: String,
            value: ""
        },
        {
            attribute: "timeField",
            type: String,
            value: ""
        },
        {
            attribute: "colorField",
            type: String,
            value: ""
        },
        {
            attribute: "filter",
            type: {
                extends: Datavisualizations.WEBACTION_TYPES.PAGE_PARAM_FILTER
            }
        },
        {
            attribute: "pollingQuerySettings",
            type: {
                extends: Datavisualizations.WEBACTION_TYPES.POLLING_QUERY_SETTINGS
            }
        },
        {
            attribute: "drilldown",
            type: {
                extends: Datavisualizations.WEBACTION_TYPES.DRILLDOWN
            }
        },
        {
            attribute: "tooltips",
            type: {
                extends: Datavisualizations.WEBACTION_TYPES.TOOLTIPS
            }
        },
        {
            attribute: "conditionalColor",
            type: {
                extends: Datavisualizations.WEBACTION_TYPES.CONDITIONAL_COLORS
            }
        },
        {
            attribute: "show_legend",
            value: false
        },
        {
            attribute: "maxValues",
            type: String,
            value: "100"
        },
        {
            attribute: "maxPoints",
            type: String,
            value: "500"
        },
        {
            attribute: "maxSeries",
            type: String,
            value: "20"
        },
        {
            attribute: "zIndex",
            type: Number,
            value: 1
        }
    ]
};

//========================================================================
//
// WEBACTION_EDITORS

// jx: common confiugrations to all chart types here
Datavisualizations.WEBACTION_EDITORS = {};

Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER = {
    attribute: "filter",
    options: {
        label: "Filter settings",
        label_style: "heading"
    },
    type: Datavisualizations.EDITOR.PROPERTIES,
    propertyeditors: [
        {
            attribute: "visible",
            options: {
                label: "Visible"
            },
            type: Datavisualizations.EDITOR.BOOLEAN
        }
    ]
};

Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS = {
    attribute: "pollingQuerySettings",
    options: {
        label: "Polling query settings",
        label_style: "heading"
    },
    type: Datavisualizations.EDITOR.PROPERTIES,
    propertyeditors: [
        {
            attribute: "pollingEnabled",
            options: {
                label: "Enabled"
            },
            type: Datavisualizations.EDITOR.BOOLEAN
        }
    ]
};

Datavisualizations.WEBACTION_EDITORS.TOOLTIPS = {
    attribute: "tooltips",
    options: {
        label: "Tooltip Configuration",
        label_style: "heading"
    },
    type: Datavisualizations.EDITOR.PROPERTIES,
    propertyeditors: [
        {
            attribute: "show_all",
            options: {
                label: "Show all fields"
            },
            type: Datavisualizations.EDITOR.BOOLEAN
        },
        {
            attribute: "tooltip_values",
            type: Datavisualizations.EDITOR.COLLECTION,
            options: {
                label: "Custom fields",
                add_text: "Add a custom field"
            },
            itemeditor: {
                propertyeditors: [
                    {
                        attribute: "label",
                        options: {
                            flow: "inline",
                            label: "Label"
                        },
                        type: Datavisualizations.EDITOR.STRING
                    },
                    {
                        attribute: "srcfield",
                        options: {
                            flow: "inline",
                            label: "Source Field"
                        },
                        type: Datavisualizations.EDITOR.FIELD
                    }
                ]
            }
        }
    ]
};

Datavisualizations.WEBACTION_EDITORS.AREAS = {
    attribute: "areas",
    options: {
        add_text: "Add an area",
        label: "Areas"
    },
    type: Datavisualizations.EDITOR.COLLECTION,
    itemeditor: {
        propertyeditors: [
            {
                attribute: "label",
                options: {
                    label: "Label",
                    placeholder: "Enter label"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "ID",
                options: {
                    label: "ID",
                    placeholder: "Enter area ID"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "defaultColor",
                type: Datavisualizations.EDITOR.COLOR,
                options: {
                    flow: "inline",
                    label: "Default color"
                }
            },
            {
                attribute: "colors",
                type: Datavisualizations.EDITOR.COLLECTION,
                options: {
                    label: "Area colors",
                    add_text: "Add a conditional area color"
                },
                itemeditor: {
                    propertyeditors: [
                        {
                            // The input of this expression is actually something that is added to the conditions collection, and just the ID stored here
                            attribute: "expression",
                            type: Datavisualizations.EDITOR.STRING,
                            options: {
                                placeholder: "Enter expression",
                                flow: "inline"
                            }
                        },
                        {
                            attribute: "color",
                            type: Datavisualizations.EDITOR.COLOR,
                            options: {
                                flow: "inline"
                            }
                        }
                    ]
                }
            },
            {
                attribute: "drillDownPage",
                type: Datavisualizations.EDITOR.PAGE,
                options: {
                    label: "Navigate to this page on click"
                }
            },
            {
                attribute: "coordinates",
                type: Datavisualizations.EDITOR.COLLECTION,
                options: {
                    description:
                        "Please provide a list of coordinates (lat,long).<br/>For example:<br/>37.44998,-122.15559<br/>37.450564,-122.154967<br/>37.451352,-122.156196<br/>37.449979,-122.155587<br/>",
                    label: "Coordinates",
                    add_text: "Add area coordinates"
                },
                itemeditor: {
                    propertyeditors: [
                        {
                            attribute: "coordinate",
                            type: Datavisualizations.EDITOR.TEXT,
                            options: {
                                flow: "inline",
                                placeholder: "Enter coordinates"
                            }
                        }
                    ]
                }
            }
        ]
    }
};

Datavisualizations.WEBACTION_EDITORS.CONDITIONAL_COLORS = {
    // Editing the conditions property means editing each condition individually. we use the COLLECTION editor for this purpose, and provide an item editor array.
    attribute: "conditionalColor",
    type: Datavisualizations.EDITOR.COLLECTION,
    options: {
        label: "Conditional colors",
        add_text: "Add a conditional color"
    },
    // For each item in the array (e.g. condition), use the following editor
    itemeditor: {
        // For the attribute in the condition, use the following editors
        // type: Datavisualizations.EDITOR.PROPERTIES is assumed.
        //options: { label: 'Condition' },
        propertyeditors: [
            {
                // The input of this expression is actually something that is added to the conditions collection, and just the ID stored here
                attribute: "expression",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Expression",
                    flow: "inline",
                    label_style: "heading"
                }
            },
            // Wrapping color and background_color into a style attribute,
            // for consistency with grid style properties
            {
                attribute: "style",
                options: {
                    flow: "inline"
                },
                type: Datavisualizations.EDITOR.PROPERTIES,
                propertyeditors: [
                    {
                        attribute: "color",
                        type: Datavisualizations.EDITOR.COLOR,
                        options: {
                            label: "Color",
                            flow: "inline",
                            label_style: "heading"
                        }
                    },
                    {
                        attribute: "alias",
                        type: Datavisualizations.EDITOR.STRING,
                        options: {
                            label: "Alias",
                            flow: "inline",
                            label_style: "heading"
                        }
                    }
                ]
            }
        ]
    }
};

Datavisualizations.WEBACTION_EDITORS.MARKER_TYPES = {
    attribute: "markerTypes",
    type: Datavisualizations.EDITOR.COLLECTION,
    options: {
        description: "You can specify different marker types. Circles are used by default.",
        label: "Marker types",
        add_text: "Add a custom marker type"
    },
    itemeditor: {
        propertyeditors: [
            {
                attribute: "expression",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    placeholder: "Enter expression",
                    flow: "inline"
                }
            },
            {
                attribute: "markerType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    flow: "inline",
                    values: [
                        {
                            id: "circle",
                            name: "Circle"
                        },
                        {
                            id: "triangle-up",
                            name: "Triangle Up"
                        },
                        {
                            id: "triangle-down",
                            name: "Triangle Down"
                        },
                        {
                            id: "square",
                            name: "Square"
                        },
                        {
                            id: "diamond",
                            name: "Diamond"
                        },
                        {
                            id: "x",
                            name: "X"
                        }
                    ]
                }
            }
        ]
    }
};

Datavisualizations.WEBACTION_EDITORS.DRILLDOWN = {
    attribute: "drilldown",
    options: {
        label: "Drill Down Configuration",
        label_style: "heading"
    },
    type: Datavisualizations.EDITOR.PROPERTIES,
    propertyeditors: [
        {
            attribute: "show_drilldown",
            options: {
                label: "Enable"
            },
            type: Datavisualizations.EDITOR.BOOLEAN
        },
        {
            attribute: "page",
            options: {
                label: "Page"
            },
            type: Datavisualizations.EDITOR.PAGE
        },
        {
            attribute: "pageparams",
            type: Datavisualizations.EDITOR.COLLECTION,
            options: {
                label: "Page Filters",
                add_text: "Add a page filter"
            },
            itemeditor: {
                propertyeditors: [
                    {
                        attribute: "pageparam",
                        options: {
                            flow: "inline",
                            label: "Id"
                        },
                        type: Datavisualizations.EDITOR.STRING
                    },
                    {
                        attribute: "srcfield",
                        options: {
                            flow: "inline",
                            label: "Source Field"
                        },
                        type: Datavisualizations.EDITOR.FIELD
                    }
                ]
            }
        }
    ]
};

Datavisualizations.WEBACTION_EDITORS.YAXISLIMITS = {
    attribute: "yAxisLimits",
    options: {
        label: "Axis Limits",
        label_style: "heading"
    },
    type: Datavisualizations.EDITOR.PROPERTIES,
    propertyeditors: [
        {
            options: {
                label: "Upper Bound"
            },
            attribute: "upperBound",
            type: Datavisualizations.EDITOR.STRING
        },
        {
            options: {
                label: "Lower Bound"
            },
            attribute: "lowerBound",
            type: Datavisualizations.EDITOR.STRING
        }
    ]
};

Datavisualizations.WEBACTION_EDITORS.MAXSERIES = {
    attribute: "maxSeries",
    options: {
        label: "Maximum number of series to show"
    },
    type: Datavisualizations.EDITOR.STRING
};

Datavisualizations.WEBACTION_EDITORS.MAXVALUES = {
    attribute: "maxValues",
    options: {
        label: "Maximum number of values to show"
    },
    type: Datavisualizations.EDITOR.STRING
};

Datavisualizations.WEBACTION_EDITORS.MAXPOINTS = {
    attribute: "maxPoints",
    options: {
        label: "Maximum number of query results"
    },
    type: Datavisualizations.EDITOR.STRING
};

Datavisualizations.BarChart = {
    default_dimensions: {
        width: 6,
        height: 4
    },

    typespec: {
        extends: Datavisualizations.WEBACTION_TYPES.GRAPH,
        properties: [
            {
                attribute: "type",
                type: String,
                value: "column"
            }
        ]
    },
    editor: {
        // type: Datavisualizations.EDITOR.PROPERTIES, // assumed
        propertyeditors: [
            {
                attribute: "type",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Type",
                    values: [
                        {
                            id: "column",
                            name: "Vertical"
                        },
                        {
                            id: "bar",
                            name: "Horizontal"
                        }
                    ]
                }
            },
            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "categoryField",
                options: {
                    label: "Group By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "colorField",
                options: {
                    label: "Color By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "series",
                type: Datavisualizations.EDITOR.COLLECTION,
                style: Datavisualizations.EDITOR_STYLE.CAROUSEL,
                options: {
                    atLeastOne: true,
                    label: "Data Series"
                },
                itemeditor: {
                    type: Datavisualizations.EDITOR.AXES,
                    propertyeditors: [
                        {
                            attribute: "xAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "X"
                            }
                        },
                        {
                            attribute: "yAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "Y"
                            }
                        }
                    ]
                }
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN,
            Datavisualizations.WEBACTION_EDITORS.TOOLTIPS,
            Datavisualizations.WEBACTION_EDITORS.CONDITIONAL_COLORS,
            {
                // jx
                attribute: "show_legend",
                options: {
                    label: "Show Legend"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            {
                // jx
                attribute: "show_data_labels",
                options: {
                    label: "Show data labels"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            {
                // jx
                attribute: "show_all_labels",
                options: {
                    label: "Show all labels"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            Datavisualizations.WEBACTION_EDITORS.MAXVALUES,
            Datavisualizations.WEBACTION_EDITORS.MAXPOINTS,
            Datavisualizations.WEBACTION_EDITORS.MAXSERIES
        ]
    }
};

export function getIcons() {
    let icons = [
        {
            id: "CIRCLE",
            name: "Circle",
            icon: "fa-circle"
        },
        {
            id: "TIME",
            name: "Time",
            icon: "fa-time"
        },
        {
            id: "HOME",
            name: "Home",
            icon: "fa-home"
        },
        {
            id: "LOCK",
            name: "Lock",
            icon: "fa-lock"
        },
        {
            id: "PLUS",
            name: "Plus",
            icon: "fa-plus"
        },
        {
            id: "MINUS",
            name: "Minus",
            icon: "fa-minus"
        },
        {
            id: "ADJUST",
            name: "Adjust",
            icon: "fa-adjust"
        },
        {
            id: "ANCHOR",
            name: "Anchor",
            icon: "fa-anchor"
        },
        {
            id: "ARCHIVE",
            name: "Archive",
            icon: "fa-archive"
        },
        {
            id: "AREA_CHART",
            name: "Area-chart",
            icon: "fa-area-chart"
        },
        {
            id: "ARROWS",
            name: "Arrows",
            icon: "fa-arrows"
        },
        {
            id: "ARROWS_H",
            name: "Arrows-h",
            icon: "fa-arrows-h"
        },
        {
            id: "ARROWS_V",
            name: "Arrows-v",
            icon: "fa-arrows-v"
        },
        {
            id: "ASTERISK",
            name: "Asterisk",
            icon: "fa-asterisk"
        },
        {
            id: "AT",
            name: "At",
            icon: "fa-at"
        },
        {
            id: "AUTOMOBILE",
            name: "Automobile",
            icon: "fa-automobile"
        },
        {
            id: "BAN",
            name: "Ban",
            icon: "fa-ban"
        },
        {
            id: "BANK",
            name: "Bank",
            icon: "fa-bank"
        },
        {
            id: "BAR_CHART",
            name: "Bar-chart",
            icon: "fa-bar-chart"
        },
        {
            id: "BAR_CHART_O",
            name: "Bar-chart-o",
            icon: "fa-bar-chart-o"
        },
        {
            id: "BARCODE",
            name: "Barcode",
            icon: "fa-barcode"
        },
        {
            id: "BARS",
            name: "Bars",
            icon: "fa-bars"
        },
        {
            id: "BED",
            name: "Bed",
            icon: "fa-bed"
        },
        {
            id: "BEER",
            name: "Beer",
            icon: "fa-beer"
        },
        {
            id: "BELL",
            name: "Bell",
            icon: "fa-bell"
        },
        {
            id: "BELL_O",
            name: "Bell-o",
            icon: "fa-bell-o"
        },
        {
            id: "BELL_SLASH",
            name: "Bell-slash",
            icon: "fa-bell-slash"
        },
        {
            id: "BELL_SLASH_O",
            name: "Bell-slash-o",
            icon: "fa-bell-slash-o"
        },
        {
            id: "BICYCLE",
            name: "Bicycle",
            icon: "fa-bicycle"
        },
        {
            id: "BINOCULARS",
            name: "Binoculars",
            icon: "fa-binoculars"
        },
        {
            id: "BIRTHDAY_CAKE",
            name: "Birthday-cake",
            icon: "fa-birthday-cake"
        },
        {
            id: "BOLT",
            name: "Bolt",
            icon: "fa-bolt"
        },
        {
            id: "BOMB",
            name: "Bomb",
            icon: "fa-bomb"
        },
        {
            id: "BOOK",
            name: "Book",
            icon: "fa-book"
        },
        {
            id: "BOOKMARK",
            name: "Bookmark",
            icon: "fa-bookmark"
        },
        {
            id: "BOOKMARK_O",
            name: "Bookmark-o",
            icon: "fa-bookmark-o"
        },
        {
            id: "BRIEFCASE",
            name: "Briefcase",
            icon: "fa-briefcase"
        },
        {
            id: "BUG",
            name: "Bug",
            icon: "fa-bug"
        },
        {
            id: "BUILDING",
            name: "Building",
            icon: "fa-building"
        },
        {
            id: "BUILDING_O",
            name: "Building-o",
            icon: "fa-building-o"
        },
        {
            id: "BULLHORN",
            name: "Bullhorn",
            icon: "fa-bullhorn"
        },
        {
            id: "BULLSEYE",
            name: "Bullseye",
            icon: "fa-bullseye"
        },
        {
            id: "BUS",
            name: "Bus",
            icon: "fa-bus"
        },
        {
            id: "CAB",
            name: "Cab",
            icon: "fa-cab"
        },
        {
            id: "CALCULATOR",
            name: "Calculator",
            icon: "fa-calculator"
        },
        {
            id: "CALENDAR",
            name: "Calendar",
            icon: "fa-calendar"
        },
        {
            id: "CALENDAR_O",
            name: "Calendar-o",
            icon: "fa-calendar-o"
        },
        {
            id: "CAMERA",
            name: "Camera",
            icon: "fa-camera"
        },
        {
            id: "CAMERA_RETRO",
            name: "Camera-retro",
            icon: "fa-camera-retro"
        },
        {
            id: "CAR",
            name: "Car",
            icon: "fa-car"
        },
        {
            id: "CARET_SQUARE_O_DOWN",
            name: "Caret-square-o-down",
            icon: "fa-caret-square-o-down"
        },
        {
            id: "CARET_SQUARE_O_LEFT",
            name: "Caret-square-o-left",
            icon: "fa-caret-square-o-left"
        },
        {
            id: "CARET_SQUARE_O_RIGHT",
            name: "Caret-square-o-right",
            icon: "fa-caret-square-o-right"
        },
        {
            id: "CARET_SQUARE_O_UP",
            name: "Caret-square-o-up",
            icon: "fa-caret-square-o-up"
        },
        {
            id: "CART_ARROW_DOWN",
            name: "Cart-arrow-down",
            icon: "fa-cart-arrow-down"
        },
        {
            id: "CART_PLUS",
            name: "Cart-plus",
            icon: "fa-cart-plus"
        },
        {
            id: "CC",
            name: "Cc",
            icon: "fa-cc"
        },
        {
            id: "CERTIFICATE",
            name: "Certificate",
            icon: "fa-certificate"
        },
        {
            id: "CHECK",
            name: "Check",
            icon: "fa-check"
        },
        {
            id: "CHECK_CIRCLE",
            name: "Check-circle",
            icon: "fa-check-circle"
        },
        {
            id: "CHECK_CIRCLE_O",
            name: "Check-circle-o",
            icon: "fa-check-circle-o"
        },
        {
            id: "CHECK_SQUARE",
            name: "Check-square",
            icon: "fa-check-square"
        },
        {
            id: "CHECK_SQUARE_O",
            name: "Check-square-o",
            icon: "fa-check-square-o"
        },
        {
            id: "CHILD",
            name: "Child",
            icon: "fa-child"
        },
        {
            id: "CIRCLE_O",
            name: "Circle-o",
            icon: "fa-circle-o"
        },
        {
            id: "CIRCLE_O_NOTCH",
            name: "Circle-o-notch",
            icon: "fa-circle-o-notch"
        },
        {
            id: "CIRCLE_THIN",
            name: "Circle-thin",
            icon: "fa-circle-thin"
        },
        {
            id: "CLOCK_O",
            name: "Clock-o",
            icon: "fa-clock-o"
        },
        {
            id: "CLOSE",
            name: "Close",
            icon: "fa-close"
        },
        {
            id: "CLOUD",
            name: "Cloud",
            icon: "fa-cloud"
        },
        {
            id: "CLOUD_DOWNLOAD",
            name: "Cloud-download",
            icon: "fa-cloud-download"
        },
        {
            id: "CLOUD_UPLOAD",
            name: "Cloud-upload",
            icon: "fa-cloud-upload"
        },
        {
            id: "CODE",
            name: "Code",
            icon: "fa-code"
        },
        {
            id: "CODE_FORK",
            name: "Code-fork",
            icon: "fa-code-fork"
        },
        {
            id: "COFFEE",
            name: "Coffee",
            icon: "fa-coffee"
        },
        {
            id: "COG",
            name: "Cog",
            icon: "fa-cog"
        },
        {
            id: "COGS",
            name: "Cogs",
            icon: "fa-cogs"
        },
        {
            id: "COMMENT",
            name: "Comment",
            icon: "fa-comment"
        },
        {
            id: "COMMENT_O",
            name: "Comment-o",
            icon: "fa-comment-o"
        },
        {
            id: "COMMENTS",
            name: "Comments",
            icon: "fa-comments"
        },
        {
            id: "COMMENTS_O",
            name: "Comments-o",
            icon: "fa-comments-o"
        },
        {
            id: "COMPASS",
            name: "Compass",
            icon: "fa-compass"
        },
        {
            id: "COPYRIGHT",
            name: "Copyright",
            icon: "fa-copyright"
        },
        {
            id: "CREDIT_CARD",
            name: "Credit-card",
            icon: "fa-credit-card"
        },
        {
            id: "CROP",
            name: "Crop",
            icon: "fa-crop"
        },
        {
            id: "CROSSHAIRS",
            name: "Crosshairs",
            icon: "fa-crosshairs"
        },
        {
            id: "CUBE",
            name: "Cube",
            icon: "fa-cube"
        },
        {
            id: "CUBES",
            name: "Cubes",
            icon: "fa-cubes"
        },
        {
            id: "CUTLERY",
            name: "Cutlery",
            icon: "fa-cutlery"
        },
        {
            id: "DASHBOARD",
            name: "Dashboard",
            icon: "fa-dashboard"
        },
        {
            id: "DATABASE",
            name: "Database",
            icon: "fa-database"
        },
        {
            id: "DESKTOP",
            name: "Desktop",
            icon: "fa-desktop"
        },
        {
            id: "DIAMOND",
            name: "Diamond",
            icon: "fa-diamond"
        },
        {
            id: "DOT_CIRCLE_O",
            name: "Dot-circle-o",
            icon: "fa-dot-circle-o"
        },
        {
            id: "DOWNLOAD",
            name: "Download",
            icon: "fa-download"
        },
        {
            id: "EDIT",
            name: "Edit",
            icon: "fa-edit"
        },
        {
            id: "ELLIPSIS_H",
            name: "Ellipsis-h",
            icon: "fa-ellipsis-h"
        },
        {
            id: "ELLIPSIS_V",
            name: "Ellipsis-v",
            icon: "fa-ellipsis-v"
        },
        {
            id: "ENVELOPE",
            name: "Envelope",
            icon: "fa-envelope"
        },
        {
            id: "ENVELOPE_O",
            name: "Envelope-o",
            icon: "fa-envelope-o"
        },
        {
            id: "ENVELOPE_SQUARE",
            name: "Envelope-square",
            icon: "fa-envelope-square"
        },
        {
            id: "ERASER",
            name: "Eraser",
            icon: "fa-eraser"
        },
        {
            id: "EXCHANGE",
            name: "Exchange",
            icon: "fa-exchange"
        },
        {
            id: "EXCLAMATION",
            name: "Exclamation",
            icon: "fa-exclamation"
        },
        {
            id: "EXCLAMATION_CIRCLE",
            name: "Exclamation-circle",
            icon: "fa-exclamation-circle"
        },
        {
            id: "EXCLAMATION_TRIANGLE",
            name: "Exclamation-triangle",
            icon: "fa-exclamation-triangle"
        },
        {
            id: "EXTERNAL_LINK",
            name: "External-link",
            icon: "fa-external-link"
        },
        {
            id: "EXTERNAL_LINK_SQUARE",
            name: "External-link-square",
            icon: "fa-external-link-square"
        },
        {
            id: "EYE",
            name: "Eye",
            icon: "fa-eye"
        },
        {
            id: "EYE_SLASH",
            name: "Eye-slash",
            icon: "fa-eye-slash"
        },
        {
            id: "EYEDROPPER",
            name: "Eyedropper",
            icon: "fa-eyedropper"
        },
        {
            id: "FAX",
            name: "Fax",
            icon: "fa-fax"
        },
        {
            id: "FEMALE",
            name: "Female",
            icon: "fa-female"
        },
        {
            id: "FIGHTER_JET",
            name: "Fighter-jet",
            icon: "fa-fighter-jet"
        },
        {
            id: "FILE_ARCHIVE_O",
            name: "File-archive-o",
            icon: "fa-file-archive-o"
        },
        {
            id: "FILE_AUDIO_O",
            name: "File-audio-o",
            icon: "fa-file-audio-o"
        },
        {
            id: "FILE_CODE_O",
            name: "File-code-o",
            icon: "fa-file-code-o"
        },
        {
            id: "FILE_EXCEL_O",
            name: "File-excel-o",
            icon: "fa-file-excel-o"
        },
        {
            id: "FILE_IMAGE_O",
            name: "File-image-o",
            icon: "fa-file-image-o"
        },
        {
            id: "FILE_MOVIE_O",
            name: "File-movie-o",
            icon: "fa-file-movie-o"
        },
        {
            id: "FILE_PDF_O",
            name: "File-pdf-o",
            icon: "fa-file-pdf-o"
        },
        {
            id: "FILE_PHOTO_O",
            name: "File-photo-o",
            icon: "fa-file-photo-o"
        },
        {
            id: "FILE_PICTURE_O",
            name: "File-picture-o",
            icon: "fa-file-picture-o"
        },
        {
            id: "FILE_POWERPOINT_O",
            name: "File-powerpoint-o",
            icon: "fa-file-powerpoint-o"
        },
        {
            id: "FILE_SOUND_O",
            name: "File-sound-o",
            icon: "fa-file-sound-o"
        },
        {
            id: "FILE_VIDEO_O",
            name: "File-video-o",
            icon: "fa-file-video-o"
        },
        {
            id: "FILE_WORD_O",
            name: "File-word-o",
            icon: "fa-file-word-o"
        },
        {
            id: "FILE_ZIP_O",
            name: "File-zip-o",
            icon: "fa-file-zip-o"
        },
        {
            id: "FILM",
            name: "Film",
            icon: "fa-film"
        },
        {
            id: "FILTER",
            name: "Filter",
            icon: "fa-filter"
        },
        {
            id: "FIRE",
            name: "Fire",
            icon: "fa-fire"
        },
        {
            id: "FIRE_EXTINGUISHER",
            name: "Fire-extinguisher",
            icon: "fa-fire-extinguisher"
        },
        {
            id: "FLAG",
            name: "Flag",
            icon: "fa-flag"
        },
        {
            id: "FLAG_CHECKERED",
            name: "Flag-checkered",
            icon: "fa-flag-checkered"
        },
        {
            id: "FLAG_O",
            name: "Flag-o",
            icon: "fa-flag-o"
        },
        {
            id: "FLASH",
            name: "Flash",
            icon: "fa-flash"
        },
        {
            id: "FLASK",
            name: "Flask",
            icon: "fa-flask"
        },
        {
            id: "FOLDER",
            name: "Folder",
            icon: "fa-folder"
        },
        {
            id: "FOLDER_O",
            name: "Folder-o",
            icon: "fa-folder-o"
        },
        {
            id: "FOLDER_OPEN",
            name: "Folder-open",
            icon: "fa-folder-open"
        },
        {
            id: "FOLDER_OPEN_O",
            name: "Folder-open-o",
            icon: "fa-folder-open-o"
        },
        {
            id: "FROWN_O",
            name: "Frown-o",
            icon: "fa-frown-o"
        },
        {
            id: "FUTBOL_O",
            name: "Futbol-o",
            icon: "fa-futbol-o"
        },
        {
            id: "GAMEPAD",
            name: "Gamepad",
            icon: "fa-gamepad"
        },
        {
            id: "GAVEL",
            name: "Gavel",
            icon: "fa-gavel"
        },
        {
            id: "GEAR",
            name: "Gear",
            icon: "fa-gear"
        },
        {
            id: "GEARS",
            name: "Gears",
            icon: "fa-gears"
        },
        {
            id: "GENDERLESS",
            name: "Genderless",
            icon: "fa-genderless"
        },
        {
            id: "GIFT",
            name: "Gift",
            icon: "fa-gift"
        },
        {
            id: "GLASS",
            name: "Glass",
            icon: "fa-glass"
        },
        {
            id: "GLOBE",
            name: "Globe",
            icon: "fa-globe"
        },
        {
            id: "GRADUATION_CAP",
            name: "Graduation-cap",
            icon: "fa-graduation-cap"
        },
        {
            id: "GROUP",
            name: "Group",
            icon: "fa-group"
        },
        {
            id: "HDD_O",
            name: "Hdd-o",
            icon: "fa-hdd-o"
        },
        {
            id: "HEADPHONES",
            name: "Headphones",
            icon: "fa-headphones"
        },
        {
            id: "HEART",
            name: "Heart",
            icon: "fa-heart"
        },
        {
            id: "HEART_O",
            name: "Heart-o",
            icon: "fa-heart-o"
        },
        {
            id: "HEARTBEAT",
            name: "Heartbeat",
            icon: "fa-heartbeat"
        },
        {
            id: "HISTORY",
            name: "History",
            icon: "fa-history"
        },
        {
            id: "HOTEL",
            name: "Hotel",
            icon: "fa-hotel"
        },
        {
            id: "IMAGE",
            name: "Image",
            icon: "fa-image"
        },
        {
            id: "INBOX",
            name: "Inbox",
            icon: "fa-inbox"
        },
        {
            id: "INFO",
            name: "Info",
            icon: "fa-info"
        },
        {
            id: "INFO_CIRCLE",
            name: "Info-circle",
            icon: "fa-info-circle"
        },
        {
            id: "INSTITUTION",
            name: "Institution",
            icon: "fa-institution"
        },
        {
            id: "KEY",
            name: "Key",
            icon: "fa-key"
        },
        {
            id: "KEYBOARD_O",
            name: "Keyboard-o",
            icon: "fa-keyboard-o"
        },
        {
            id: "LANGUAGE",
            name: "Language",
            icon: "fa-language"
        },
        {
            id: "LAPTOP",
            name: "Laptop",
            icon: "fa-laptop"
        },
        {
            id: "LEAF",
            name: "Leaf",
            icon: "fa-leaf"
        },
        {
            id: "LEGAL",
            name: "Legal",
            icon: "fa-legal"
        },
        {
            id: "LEMON_O",
            name: "Lemon-o",
            icon: "fa-lemon-o"
        },
        {
            id: "LEVEL_DOWN",
            name: "Level-down",
            icon: "fa-level-down"
        },
        {
            id: "LEVEL_UP",
            name: "Level-up",
            icon: "fa-level-up"
        },
        {
            id: "LIFE_BOUY",
            name: "Life-bouy",
            icon: "fa-life-bouy"
        },
        {
            id: "LIFE_BUOY",
            name: "Life-buoy",
            icon: "fa-life-buoy"
        },
        {
            id: "LIFE_RING",
            name: "Life-ring",
            icon: "fa-life-ring"
        },
        {
            id: "LIFE_SAVER",
            name: "Life-saver",
            icon: "fa-life-saver"
        },
        {
            id: "LIGHTBULB_O",
            name: "Lightbulb-o",
            icon: "fa-lightbulb-o"
        },
        {
            id: "LINE_CHART",
            name: "Line-chart",
            icon: "fa-line-chart"
        },
        {
            id: "LOCATION_ARROW",
            name: "Location-arrow",
            icon: "fa-location-arrow"
        },
        {
            id: "MAGIC",
            name: "Magic",
            icon: "fa-magic"
        },
        {
            id: "MAGNET",
            name: "Magnet",
            icon: "fa-magnet"
        },
        {
            id: "MAIL_FORWARD",
            name: "Mail-forward",
            icon: "fa-mail-forward"
        },
        {
            id: "MAIL_REPLY",
            name: "Mail-reply",
            icon: "fa-mail-reply"
        },
        {
            id: "MAIL_REPLY_ALL",
            name: "Mail-reply-all",
            icon: "fa-mail-reply-all"
        },
        {
            id: "MALE",
            name: "Male",
            icon: "fa-male"
        },
        {
            id: "MAP_MARKER",
            name: "Map-marker",
            icon: "fa-map-marker"
        },
        {
            id: "MEH_O",
            name: "Meh-o",
            icon: "fa-meh-o"
        },
        {
            id: "MICROPHONE",
            name: "Microphone",
            icon: "fa-microphone"
        },
        {
            id: "MICROPHONE_SLASH",
            name: "Microphone-slash",
            icon: "fa-microphone-slash"
        },
        {
            id: "MINUS_CIRCLE",
            name: "Minus-circle",
            icon: "fa-minus-circle"
        },
        {
            id: "MINUS_SQUARE",
            name: "Minus-square",
            icon: "fa-minus-square"
        },
        {
            id: "MINUS_SQUARE_O",
            name: "Minus-square-o",
            icon: "fa-minus-square-o"
        },
        {
            id: "MOBILE",
            name: "Mobile",
            icon: "fa-mobile"
        },
        {
            id: "MOBILE_PHONE",
            name: "Mobile-phone",
            icon: "fa-mobile-phone"
        },
        {
            id: "MONEY",
            name: "Money",
            icon: "fa-money"
        },
        {
            id: "MOON_O",
            name: "Moon-o",
            icon: "fa-moon-o"
        },
        {
            id: "MORTAR_BOARD",
            name: "Mortar-board",
            icon: "fa-mortar-board"
        },
        {
            id: "MOTORCYCLE",
            name: "Motorcycle",
            icon: "fa-motorcycle"
        },
        {
            id: "MUSIC",
            name: "Music",
            icon: "fa-music"
        },
        {
            id: "NAVICON",
            name: "Navicon",
            icon: "fa-navicon"
        },
        {
            id: "NEWSPAPER_O",
            name: "Newspaper-o",
            icon: "fa-newspaper-o"
        },
        {
            id: "PAINT_BRUSH",
            name: "Paint-brush",
            icon: "fa-paint-brush"
        },
        {
            id: "PAPER_PLANE",
            name: "Paper-plane",
            icon: "fa-paper-plane"
        },
        {
            id: "PAPER_PLANE_O",
            name: "Paper-plane-o",
            icon: "fa-paper-plane-o"
        },
        {
            id: "PAW",
            name: "Paw",
            icon: "fa-paw"
        },
        {
            id: "PENCIL",
            name: "Pencil",
            icon: "fa-pencil"
        },
        {
            id: "PENCIL_SQUARE",
            name: "Pencil-square",
            icon: "fa-pencil-square"
        },
        {
            id: "PENCIL_SQUARE_O",
            name: "Pencil-square-o",
            icon: "fa-pencil-square-o"
        },
        {
            id: "PHONE",
            name: "Phone",
            icon: "fa-phone"
        },
        {
            id: "PHONE_SQUARE",
            name: "Phone-square",
            icon: "fa-phone-square"
        },
        {
            id: "PHOTO",
            name: "Photo",
            icon: "fa-photo"
        },
        {
            id: "PICTURE_O",
            name: "Picture-o",
            icon: "fa-picture-o"
        },
        {
            id: "PIE_CHART",
            name: "Pie-chart",
            icon: "fa-pie-chart"
        },
        {
            id: "PLANE",
            name: "Plane",
            icon: "fa-plane"
        },
        {
            id: "PLUG",
            name: "Plug",
            icon: "fa-plug"
        },
        {
            id: "PLUS_CIRCLE",
            name: "Plus-circle",
            icon: "fa-plus-circle"
        },
        {
            id: "PLUS_SQUARE",
            name: "Plus-square",
            icon: "fa-plus-square"
        },
        {
            id: "PLUS_SQUARE_O",
            name: "Plus-square-o",
            icon: "fa-plus-square-o"
        },
        {
            id: "POWER_OFF",
            name: "Power-off",
            icon: "fa-power-off"
        },
        {
            id: "PRINT",
            name: "Print",
            icon: "fa-print"
        },
        {
            id: "PUZZLE_PIECE",
            name: "Puzzle-piece",
            icon: "fa-puzzle-piece"
        },
        {
            id: "QRCODE",
            name: "Qrcode",
            icon: "fa-qrcode"
        },
        {
            id: "QUESTION",
            name: "Question",
            icon: "fa-question"
        },
        {
            id: "QUESTION_CIRCLE",
            name: "Question-circle",
            icon: "fa-question-circle"
        },
        {
            id: "QUOTE_LEFT",
            name: "Quote-left",
            icon: "fa-quote-left"
        },
        {
            id: "QUOTE_RIGHT",
            name: "Quote-right",
            icon: "fa-quote-right"
        },
        {
            id: "RANDOM",
            name: "Random",
            icon: "fa-random"
        },
        {
            id: "RECYCLE",
            name: "Recycle",
            icon: "fa-recycle"
        },
        {
            id: "REFRESH",
            name: "Refresh",
            icon: "fa-refresh"
        },
        {
            id: "REMOVE",
            name: "Remove",
            icon: "fa-remove"
        },
        {
            id: "REORDER",
            name: "Reorder",
            icon: "fa-reorder"
        },
        {
            id: "REPLY",
            name: "Reply",
            icon: "fa-reply"
        },
        {
            id: "REPLY_ALL",
            name: "Reply-all",
            icon: "fa-reply-all"
        },
        {
            id: "RETWEET",
            name: "Retweet",
            icon: "fa-retweet"
        },
        {
            id: "ROAD",
            name: "Road",
            icon: "fa-road"
        },
        {
            id: "ROCKET",
            name: "Rocket",
            icon: "fa-rocket"
        },
        {
            id: "RSS",
            name: "Rss",
            icon: "fa-rss"
        },
        {
            id: "RSS_SQUARE",
            name: "Rss-square",
            icon: "fa-rss-square"
        },
        {
            id: "SEARCH",
            name: "Search",
            icon: "fa-search"
        },
        {
            id: "SEARCH_MINUS",
            name: "Search-minus",
            icon: "fa-search-minus"
        },
        {
            id: "SEARCH_PLUS",
            name: "Search-plus",
            icon: "fa-search-plus"
        },
        {
            id: "SEND",
            name: "Send",
            icon: "fa-send"
        },
        {
            id: "SEND_O",
            name: "Send-o",
            icon: "fa-send-o"
        },
        {
            id: "SERVER",
            name: "Server",
            icon: "fa-server"
        },
        {
            id: "SHARE",
            name: "Share",
            icon: "fa-share"
        },
        {
            id: "SHARE_ALT",
            name: "Share-alt",
            icon: "fa-share-alt"
        },
        {
            id: "SHARE_ALT_SQUARE",
            name: "Share-alt-square",
            icon: "fa-share-alt-square"
        },
        {
            id: "SHARE_SQUARE",
            name: "Share-square",
            icon: "fa-share-square"
        },
        {
            id: "SHARE_SQUARE_O",
            name: "Share-square-o",
            icon: "fa-share-square-o"
        },
        {
            id: "SHIELD",
            name: "Shield",
            icon: "fa-shield"
        },
        {
            id: "SHIP",
            name: "Ship",
            icon: "fa-ship"
        },
        {
            id: "SHOPPING_CART",
            name: "Shopping-cart",
            icon: "fa-shopping-cart"
        },
        {
            id: "SIGN_IN",
            name: "Sign-in",
            icon: "fa-sign-in"
        },
        {
            id: "SIGN_OUT",
            name: "Sign-out",
            icon: "fa-sign-out"
        },
        {
            id: "SIGNAL",
            name: "Signal",
            icon: "fa-signal"
        },
        {
            id: "SITEMAP",
            name: "Sitemap",
            icon: "fa-sitemap"
        },
        {
            id: "SLIDERS",
            name: "Sliders",
            icon: "fa-sliders"
        },
        {
            id: "SMILE_O",
            name: "Smile-o",
            icon: "fa-smile-o"
        },
        {
            id: "SOCCER_BALL_O",
            name: "Soccer-ball-o",
            icon: "fa-soccer-ball-o"
        },
        {
            id: "SORT",
            name: "Sort",
            icon: "fa-sort"
        },
        {
            id: "SORT_ALPHA_ASC",
            name: "Sort-alpha-asc",
            icon: "fa-sort-alpha-asc"
        },
        {
            id: "SORT_ALPHA_DESC",
            name: "Sort-alpha-desc",
            icon: "fa-sort-alpha-desc"
        },
        {
            id: "SORT_AMOUNT_ASC",
            name: "Sort-amount-asc",
            icon: "fa-sort-amount-asc"
        },
        {
            id: "SORT_AMOUNT_DESC",
            name: "Sort-amount-desc",
            icon: "fa-sort-amount-desc"
        },
        {
            id: "SORT_ASC",
            name: "Sort-asc",
            icon: "fa-sort-asc"
        },
        {
            id: "SORT_DESC",
            name: "Sort-desc",
            icon: "fa-sort-desc"
        },
        {
            id: "SORT_DOWN",
            name: "Sort-down",
            icon: "fa-sort-down"
        },
        {
            id: "SORT_NUMERIC_ASC",
            name: "Sort-numeric-asc",
            icon: "fa-sort-numeric-asc"
        },
        {
            id: "SORT_NUMERIC_DESC",
            name: "Sort-numeric-desc",
            icon: "fa-sort-numeric-desc"
        },
        {
            id: "SORT_UP",
            name: "Sort-up",
            icon: "fa-sort-up"
        },
        {
            id: "SPACE_SHUTTLE",
            name: "Space-shuttle",
            icon: "fa-space-shuttle"
        },
        {
            id: "SPINNER",
            name: "Spinner",
            icon: "fa-spinner"
        },
        {
            id: "SPOON",
            name: "Spoon",
            icon: "fa-spoon"
        },
        {
            id: "SQUARE",
            name: "Square",
            icon: "fa-square"
        },
        {
            id: "SQUARE_O",
            name: "Square-o",
            icon: "fa-square-o"
        },
        {
            id: "STAR",
            name: "Star",
            icon: "fa-star"
        },
        {
            id: "STAR_HALF",
            name: "Star-half",
            icon: "fa-star-half"
        },
        {
            id: "STAR_HALF_EMPTY",
            name: "Star-half-empty",
            icon: "fa-star-half-empty"
        },
        {
            id: "STAR_HALF_FULL",
            name: "Star-half-full",
            icon: "fa-star-half-full"
        },
        {
            id: "STAR_HALF_O",
            name: "Star-half-o",
            icon: "fa-star-half-o"
        },
        {
            id: "STAR_O",
            name: "Star-o",
            icon: "fa-star-o"
        },
        {
            id: "STREET_VIEW",
            name: "Street-view",
            icon: "fa-street-view"
        },
        {
            id: "SUITCASE",
            name: "Suitcase",
            icon: "fa-suitcase"
        },
        {
            id: "SUN_O",
            name: "Sun-o",
            icon: "fa-sun-o"
        },
        {
            id: "SUPPORT",
            name: "Support",
            icon: "fa-support"
        },
        {
            id: "TABLET",
            name: "Tablet",
            icon: "fa-tablet"
        },
        {
            id: "TACHOMETER",
            name: "Tachometer",
            icon: "fa-tachometer"
        },
        {
            id: "TAG",
            name: "Tag",
            icon: "fa-tag"
        },
        {
            id: "TAGS",
            name: "Tags",
            icon: "fa-tags"
        },
        {
            id: "TASKS",
            name: "Tasks",
            icon: "fa-tasks"
        },
        {
            id: "TAXI",
            name: "Taxi",
            icon: "fa-taxi"
        },
        {
            id: "TERMINAL",
            name: "Terminal",
            icon: "fa-terminal"
        },
        {
            id: "THUMB_TACK",
            name: "Thumb-tack",
            icon: "fa-thumb-tack"
        },
        {
            id: "THUMBS_DOWN",
            name: "Thumbs-down",
            icon: "fa-thumbs-down"
        },
        {
            id: "THUMBS_O_DOWN",
            name: "Thumbs-o-down",
            icon: "fa-thumbs-o-down"
        },
        {
            id: "THUMBS_O_UP",
            name: "Thumbs-o-up",
            icon: "fa-thumbs-o-up"
        },
        {
            id: "THUMBS_UP",
            name: "Thumbs-up",
            icon: "fa-thumbs-up"
        },
        {
            id: "TICKET",
            name: "Ticket",
            icon: "fa-ticket"
        },
        {
            id: "TIMES",
            name: "Times",
            icon: "fa-times"
        },
        {
            id: "TIMES_CIRCLE",
            name: "Times-circle",
            icon: "fa-times-circle"
        },
        {
            id: "TIMES_CIRCLE_O",
            name: "Times-circle-o",
            icon: "fa-times-circle-o"
        },
        {
            id: "TINT",
            name: "Tint",
            icon: "fa-tint"
        },
        {
            id: "TOGGLE_DOWN",
            name: "Toggle-down",
            icon: "fa-toggle-down"
        },
        {
            id: "TOGGLE_LEFT",
            name: "Toggle-left",
            icon: "fa-toggle-left"
        },
        {
            id: "TOGGLE_OFF",
            name: "Toggle-off",
            icon: "fa-toggle-off"
        },
        {
            id: "TOGGLE_ON",
            name: "Toggle-on",
            icon: "fa-toggle-on"
        },
        {
            id: "TOGGLE_RIGHT",
            name: "Toggle-right",
            icon: "fa-toggle-right"
        },
        {
            id: "TOGGLE_UP",
            name: "Toggle-up",
            icon: "fa-toggle-up"
        },
        {
            id: "TRASH",
            name: "Trash",
            icon: "fa-trash"
        },
        {
            id: "TRASH_O",
            name: "Trash-o",
            icon: "fa-trash-o"
        },
        {
            id: "TREE",
            name: "Tree",
            icon: "fa-tree"
        },
        {
            id: "TROPHY",
            name: "Trophy",
            icon: "fa-trophy"
        },
        {
            id: "TRUCK",
            name: "Truck",
            icon: "fa-truck"
        },
        {
            id: "TTY",
            name: "Tty",
            icon: "fa-tty"
        },
        {
            id: "UMBRELLA",
            name: "Umbrella",
            icon: "fa-umbrella"
        },
        {
            id: "UNIVERSITY",
            name: "University",
            icon: "fa-university"
        },
        {
            id: "UNLOCK",
            name: "Unlock",
            icon: "fa-unlock"
        },
        {
            id: "UNLOCK_ALT",
            name: "Unlock-alt",
            icon: "fa-unlock-alt"
        },
        {
            id: "UNSORTED",
            name: "Unsorted",
            icon: "fa-unsorted"
        },
        {
            id: "UPLOAD",
            name: "Upload",
            icon: "fa-upload"
        },
        {
            id: "USER",
            name: "User",
            icon: "fa-user"
        },
        {
            id: "USER_PLUS",
            name: "User-plus",
            icon: "fa-user-plus"
        },
        {
            id: "USER_SECRET",
            name: "User-secret",
            icon: "fa-user-secret"
        },
        {
            id: "USER_TIMES",
            name: "User-times",
            icon: "fa-user-times"
        },
        {
            id: "USERS",
            name: "Users",
            icon: "fa-users"
        },
        {
            id: "VIDEO_CAMERA",
            name: "Video-camera",
            icon: "fa-video-camera"
        },
        {
            id: "VOLUME_DOWN",
            name: "Volume-down",
            icon: "fa-volume-down"
        },
        {
            id: "VOLUME_OFF",
            name: "Volume-off",
            icon: "fa-volume-off"
        },
        {
            id: "VOLUME_UP",
            name: "Volume-up",
            icon: "fa-volume-up"
        },
        {
            id: "WARNING",
            name: "Warning",
            icon: "fa-warning"
        },
        {
            id: "WHEELCHAIR",
            name: "Wheelchair",
            icon: "fa-wheelchair"
        },
        {
            id: "WIFI",
            name: "Wifi",
            icon: "fa-wifi"
        },
        {
            id: "WRENCH",
            name: "Wrench",
            icon: "fa-wrench"
        },
        {
            id: "CC_AMEX",
            name: "CC-amex",
            icon: "fa-cc-amex"
        },
        {
            id: "CC_DISCOVER",
            name: "CC-discover",
            icon: "fa-cc-discover"
        },
        {
            id: "CC_MASTERCARD",
            name: "CC-mastercard",
            icon: "fa-cc-mastercard"
        },
        {
            id: "CC_PAYPAL",
            name: "CC-paypal",
            icon: "fa-cc-paypal"
        },
        {
            id: "CC_STRIPE",
            name: "CC-stripe",
            icon: "fa-cc-stripe"
        },
        {
            id: "CC_VISA",
            name: "CC-visa",
            icon: "fa-cc-visa"
        },
        {
            id: "GOOGLE_WALLET",
            name: "Google-wallet",
            icon: "fa-google-wallet"
        },
        {
            id: "PAYPAL",
            name: "Paypal",
            icon: "fa-paypal"
        },
        {
            id: "BITCOIN",
            name: "Bitcoin",
            icon: "fa-bitcoin"
        },
        {
            id: "BTC",
            name: "BTC",
            icon: "fa-btc"
        },
        {
            id: "CNY",
            name: "CNY",
            icon: "fa-cny"
        },
        {
            id: "DOLLAR",
            name: "Dollar",
            icon: "fa-dollar"
        },
        {
            id: "EUR",
            name: "EUR",
            icon: "fa-eur"
        },
        {
            id: "EURO",
            name: "Euro",
            icon: "fa-euro"
        },
        {
            id: "GBP",
            name: "GBP",
            icon: "fa-gbp"
        },
        {
            id: "ILS",
            name: "ILS",
            icon: "fa-ils"
        },
        {
            id: "INR",
            name: "INR",
            icon: "fa-inr"
        },
        {
            id: "JPY",
            name: "JPY",
            icon: "fa-jpy"
        },
        {
            id: "KRW",
            name: "KRW",
            icon: "fa-krw"
        },
        {
            id: "RMB",
            name: "RMB",
            icon: "fa-rmb"
        },
        {
            id: "ROUBLE",
            name: "Rouble",
            icon: "fa-rouble"
        },
        {
            id: "RUB",
            name: "RUB",
            icon: "fa-rub"
        },
        {
            id: "RUBLE",
            name: "Ruble",
            icon: "fa-ruble"
        },
        {
            id: "RUPEE",
            name: "Rupee",
            icon: "fa-rupee"
        },
        {
            id: "SHEKEL",
            name: "Shekel",
            icon: "fa-shekel"
        },
        {
            id: "SHEQEL",
            name: "Sheqel",
            icon: "fa-sheqel"
        },
        {
            id: "TRY",
            name: "TRY",
            icon: "fa-try"
        },
        {
            id: "TURKISH_LIRA",
            name: "Turkish-lira",
            icon: "fa-turkish-lira"
        },
        {
            id: "USD",
            name: "USD",
            icon: "fa-usd"
        },
        {
            id: "WON",
            name: "Won",
            icon: "fa-won"
        },
        {
            id: "YEN",
            name: "Yen",
            icon: "fa-yen"
        }
    ];

    let result = _.sortBy(icons, "name");

    result.unshift({
        id: "none",
        name: "None"
    });

    return result;
}

Datavisualizations.Icon = {
    default_dimensions: {
        width: 1,
        height: 1
    },
    typespec: {
        properties: [
            {
                attribute: "retentionStrategyType",
                type: String,
                value: "current"
            },
            {
                attribute: "categoryField",
                type: String,
                value: ""
            },
            {
                attribute: "timeField",
                type: String,
                value: ""
            },
            {
                attribute: "conditions",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.CONDITIONS,
                    itemtype: {
                        properties: [
                            /*
                            {
                                attribute: 'expression',

                                editor: {
                                    // Automatically assumed, if left unspecified, because prop type is String
                                    type: Datavisualizations.EDITOR.STRING,
                                    options: { flow: 'inline' }
                                }
                            },
                            */
                            {
                                attribute: "icon",
                                type: String
                                /*
                                editor: {
                                    // TODO: the concept of an enum should be a Type concept, not an editor concept
                                    type: Datavisualizations.EDITOR.ENUM,
                                    options: {
                                        values: [{ id: 'WARNING', name: 'Warning sign' }, { id: 'CIRCLE', name: 'Circle' }],
                                        flow: 'inline'
                                    }
                                }
                                */
                            },
                            {
                                attribute: "color",
                                value: "#ff8800"
                                /*
                                editor: {
                                    type: Datavisualizations.EDITOR.COLOR,
                                    options: { flow: 'inline' }
                                }
                                */
                            }
                        ]
                    }
                },
                editor: {
                    options: {
                        add_text: "Add a condition"
                    }
                }
            },
            {
                attribute: "filter",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.PAGE_PARAM_FILTER
                }
            },
            {
                attribute: "pollingQuerySettings",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.POLLING_QUERY_SETTINGS
                }
            },
            {
                attribute: "drilldown",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.DRILLDOWN
                }
            },
            {},
            {
                attribute: "zIndex",
                type: Number,
                value: "1"
            }
        ]
    },
    editor: {
        // type: Datavisualizations.EDITOR.PROPERTIES, // assumed
        propertyeditors: [
            {
                // Editing the conditions property means editing each condition individually. we use the COLLECTION editor for this purpose, and provide an item editor array.
                attribute: "conditions",
                type: Datavisualizations.EDITOR.COLLECTION,
                options: {
                    add_text: "Add a condition"
                },
                // For each item in the array (e.g. condition), use the following editor
                itemeditor: {
                    // For the attribute in the condition, use the following editors
                    // type: Datavisualizations.EDITOR.PROPERTIES is assumed.
                    propertyeditors: [
                        {
                            attribute: "expression",
                            type: Datavisualizations.EDITOR.STRING,
                            options: {
                                flow: "inline"
                            }
                        },
                        {
                            attribute: "icon",
                            type: Datavisualizations.EDITOR.ENUM,
                            // TODO: the 'none' option is added solely so that if no icon is selected, "None" is selected. This, however, is not the same thing as having no value. Move enum to type spec.
                            options: {
                                values: getIcons(),
                                flow: "inline",
                                showIcon: true
                            }
                        },
                        {
                            attribute: "color",
                            type: Datavisualizations.EDITOR.COLOR,
                            options: {
                                flow: "inline"
                            }
                        }
                    ]
                }
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN
        ]
    }
};

Datavisualizations.LineChart = {
    default_dimensions: {
        width: 6,
        height: 4
    },
    typespec: {
        extends: Datavisualizations.WEBACTION_TYPES.GRAPH,
        properties: [
            {
                attribute: "stack",
                type: String,
                value: "none"
            }
        ]
    },
    editor: {
        propertyeditors: [
            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "categoryField",
                options: {
                    label: "Group By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "colorField",
                options: {
                    label: "Color By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "series",
                type: Datavisualizations.EDITOR.COLLECTION,
                style: Datavisualizations.EDITOR_STYLE.CAROUSEL,
                options: {
                    atLeastOne: true,
                    label: "Data Series"
                },
                itemeditor: {
                    type: Datavisualizations.EDITOR.AXES,
                    propertyeditors: [
                        {
                            attribute: "xAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "X"
                            }
                        },
                        {
                            attribute: "yAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "Y"
                            }
                        }
                    ]
                }
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN,
            Datavisualizations.WEBACTION_EDITORS.TOOLTIPS,
            Datavisualizations.WEBACTION_EDITORS.CONDITIONAL_COLORS,
            {
                // jx
                attribute: "show_legend",
                options: {
                    label: "Show Legend"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            Datavisualizations.WEBACTION_EDITORS.YAXISLIMITS,
            Datavisualizations.WEBACTION_EDITORS.MAXVALUES,
            Datavisualizations.WEBACTION_EDITORS.MAXPOINTS,
            Datavisualizations.WEBACTION_EDITORS.MAXSERIES
        ]
    }
};

Datavisualizations.AreaChart = {
    default_dimensions: {
        width: 6,
        height: 4
    },
    typespec: {
        extends: Datavisualizations.WEBACTION_TYPES.GRAPH,
        properties: [
            {
                attribute: "stack",
                type: String,
                value: "none"
            }
        ]
    },
    editor: {
        propertyeditors: [
            {
                attribute: "stack",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Stacking",
                    values: [
                        {
                            id: "none",
                            name: "None"
                        },
                        {
                            id: "normal",
                            name: "Stacked"
                        },
                        {
                            id: "percent",
                            name: "Percent Stacked"
                        }
                    ]
                }
            },

            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "categoryField",
                options: {
                    label: "Group By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "colorField",
                options: {
                    label: "Color By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "series",
                type: Datavisualizations.EDITOR.COLLECTION,
                style: Datavisualizations.EDITOR_STYLE.CAROUSEL,
                options: {
                    atLeastOne: true,
                    label: "Data Series"
                },
                itemeditor: {
                    type: Datavisualizations.EDITOR.AXES,
                    propertyeditors: [
                        {
                            attribute: "xAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "X"
                            }
                        },
                        {
                            attribute: "yAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "Y"
                            }
                        }
                    ]
                }
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN,
            Datavisualizations.WEBACTION_EDITORS.TOOLTIPS,
            Datavisualizations.WEBACTION_EDITORS.CONDITIONAL_COLORS,
            {
                // jx
                attribute: "show_legend",
                options: {
                    label: "Show Legend"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            Datavisualizations.WEBACTION_EDITORS.YAXISLIMITS,
            Datavisualizations.WEBACTION_EDITORS.MAXVALUES,
            Datavisualizations.WEBACTION_EDITORS.MAXPOINTS,
            Datavisualizations.WEBACTION_EDITORS.MAXSERIES
        ]
    }
};

Datavisualizations.ColumnRangeChart = {
    default_dimensions: {
        width: 6,
        height: 4
    },
    typespec: {
        extends: Datavisualizations.WEBACTION_TYPES.GRAPH,
        properties: [
            {
                attribute: "retentionStrategyType",
                value: "current"
            },
            {
                attribute: "lowValueField",
                type: String
            },
            {
                attribute: "highValueField",
                type: String
            },
            {
                attribute: "valueLabel",
                type: String
            },
            {
                attribute: "categoryField",
                type: String
            },
            {
                attribute: "categoryLabel",
                type: String
            },
            {
                attribute: "chartValueType",
                type: String
            },
            {
                attribute: "layout",
                type: String,
                value: "horizontal"
            }
        ]
    },
    editor: {
        propertyeditors: [
            {
                attribute: "layout",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Layout",
                    values: [
                        {
                            id: "horizontal",
                            name: "Horizontal"
                        },
                        {
                            id: "vertical",
                            name: "Vertical"
                        }
                    ]
                }
            },
            {
                attribute: "categoryField",
                options: {
                    label: "Group By"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "categoryLabel",
                options: {
                    label: "Category Label"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "chartValueType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Value Type",
                    values: [
                        {
                            id: "scalar",
                            name: "Scalar"
                        },
                        {
                            id: "date",
                            name: "Date"
                        },
                        {
                            id: "time",
                            name: "Time"
                        },
                        {
                            id: "dateTime",
                            name: "Date & Time"
                        }
                    ]
                }
            },
            {
                attribute: "colorField",
                options: {
                    label: "Color By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "lowValueField",
                options: {
                    label: "Low Value Field"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "highValueField",
                options: {
                    label: "High Value Field"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "valueLabel",
                options: {
                    label: "Value Label"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN,
            Datavisualizations.WEBACTION_EDITORS.TOOLTIPS,
            Datavisualizations.WEBACTION_EDITORS.CONDITIONAL_COLORS
        ]
    }
};

Datavisualizations.PredictiveChart = {
    default_dimensions: {
        width: 6,
        height: 4
    },
    typespec: {
        extends: Datavisualizations.WEBACTION_TYPES.GRAPH,
        properties: [
            {
                attribute: "realTimeHistorySize",
                value: "20"
            },
            {
                attribute: "diagnosticsHistorySize",
                value: "20"
            },
            {
                attribute: "realTimeHistoryResolution",
                value: "Minutes"
            },
            {
                attribute: "diagnosticsHistoryResolution",
                value: "Minutes"
            },
            {
                attribute: "resultField",
                value: null
            },
            {
                attribute: "trainingWindowLength",
                value: "20"
            },
            {
                attribute: "trainingWindowResolution",
                value: "Minutes"
            },
            {
                attribute: "observedValueField",
                value: null
            },
            {
                attribute: "observedTimeField",
                value: null
            }
        ]
    },
    editor: {
        // type: Datavisualizations.EDITOR.PROPERTIES, // assumed     flow: 'inline',
        propertyeditors: [
            {
                attribute: "realTimeHistorySize",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Real Time History Length"
                }
            },
            {
                attribute: "realTimeHistoryResolution",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Real Time History Resolution ",
                    values: [
                        {
                            id: "Minutes",
                            name: "Minutes"
                        },
                        {
                            id: "Hours",
                            name: "Hours"
                        }
                    ]
                }
            },
            {
                attribute: "diagnosticsHistorySize",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Diagnostics History Length"
                }
            },
            {
                attribute: "diagnosticsHistoryResolution",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Diagnostics History Resolution ",
                    values: [
                        {
                            id: "Minutes",
                            name: "Minutes"
                        },
                        {
                            id: "Hours",
                            name: "Hours"
                        }
                    ]
                }
            },
            {
                attribute: "resultField",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Result Field"
                }
            },
            {
                attribute: "observedValueField",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Observed Value Field"
                }
            },
            {
                attribute: "observedTimeField",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Observed Time Field"
                }
            },
            {
                attribute: "series",
                type: Datavisualizations.EDITOR.COLLECTION,
                style: Datavisualizations.EDITOR_STYLE.CAROUSEL,
                options: {
                    atLeastOne: true,
                    label: "Data Series"
                },
                itemeditor: {
                    type: Datavisualizations.EDITOR.AXES, // Data series with 2-3 dimensions
                    propertyeditors: [
                        {
                            attribute: "xAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "X"
                            }
                        },
                        {
                            attribute: "yAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "Y"
                            }
                        }
                    ]
                }
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            {
                // jx
                attribute: "show_legend",
                options: {
                    label: "Show Legend"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            Datavisualizations.WEBACTION_EDITORS.YAXISLIMITS,
            Datavisualizations.WEBACTION_EDITORS.MAXVALUES,
            Datavisualizations.WEBACTION_EDITORS.MAXPOINTS,
            Datavisualizations.WEBACTION_EDITORS.MAXSERIES
        ]
    }
};

Datavisualizations.ScatterPlot = {
    default_dimensions: {
        width: 6,
        height: 4
    },
    typespec: {
        extends: Datavisualizations.WEBACTION_TYPES.GRAPH
    },
    editor: {
        // type: Datavisualizations.EDITOR.PROPERTIES, // assumed
        propertyeditors: [
            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "categoryField",
                options: {
                    label: "Group By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "colorField",
                options: {
                    label: "Color By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "series",
                type: Datavisualizations.EDITOR.COLLECTION,
                style: Datavisualizations.EDITOR_STYLE.CAROUSEL,
                options: {
                    atLeastOne: true,
                    label: "Data Series"
                },
                itemeditor: {
                    type: Datavisualizations.EDITOR.AXES, // Data series with 2-3 dimensions
                    propertyeditors: [
                        {
                            attribute: "xAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "X"
                            }
                        },
                        {
                            attribute: "yAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "Y"
                            }
                        }
                    ]
                }
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN,
            Datavisualizations.WEBACTION_EDITORS.TOOLTIPS,
            Datavisualizations.WEBACTION_EDITORS.CONDITIONAL_COLORS,
            {
                // jx
                attribute: "show_legend",
                options: {
                    label: "Show Legend"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            Datavisualizations.WEBACTION_EDITORS.MAXVALUES,
            Datavisualizations.WEBACTION_EDITORS.MAXPOINTS,
            Datavisualizations.WEBACTION_EDITORS.MAXSERIES
        ]
    }
};

Datavisualizations.Bubble = {
    default_dimensions: {
        width: 6,
        height: 4
    },
    typespec: {
        extends: Datavisualizations.WEBACTION_TYPES.GRAPH
    },
    editor: {
        // type: Datavisualizations.EDITOR.PROPERTIES, // assumed
        propertyeditors: [
            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "categoryField",
                options: {
                    label: "Group By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "colorField",
                options: {
                    label: "Color By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "series",
                type: Datavisualizations.EDITOR.COLLECTION,
                style: Datavisualizations.EDITOR_STYLE.CAROUSEL,
                options: {
                    atLeastOne: true,
                    label: "Data Series"
                },
                itemeditor: {
                    type: Datavisualizations.EDITOR.AXES, // Data series with 2-3 dimensions
                    propertyeditors: [
                        {
                            attribute: "xAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "X"
                            }
                        },
                        {
                            attribute: "yAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "Y"
                            }
                        },
                        {
                            attribute: "zAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "Z",
                                label: "Z Axis"
                            }
                        }
                    ]
                }
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN,
            Datavisualizations.WEBACTION_EDITORS.TOOLTIPS,
            Datavisualizations.WEBACTION_EDITORS.CONDITIONAL_COLORS,
            {
                // jx
                attribute: "show_legend",
                options: {
                    label: "Show Legend"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            Datavisualizations.WEBACTION_EDITORS.MAXVALUES,
            Datavisualizations.WEBACTION_EDITORS.MAXPOINTS,
            Datavisualizations.WEBACTION_EDITORS.MAXSERIES
        ]
    }
};

Datavisualizations.HeatMap = {
    default_dimensions: {
        width: 5,
        height: 4
    },
    typespec: {
        extends: Datavisualizations.WEBACTION_TYPES.GRAPH,
        properties: [
            {
                attribute: "label_cells",
                type: Boolean,
                value: false
            }
        ]
    },
    editor: {
        // type: Datavisualizations.EDITOR.PROPERTIES, // assumed
        propertyeditors: [
            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "label_cells",
                options: {
                    label: "Label Cells"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            {
                attribute: "series",
                type: Datavisualizations.EDITOR.COLLECTION,
                style: Datavisualizations.EDITOR_STYLE.CAROUSEL,
                options: {
                    atLeastOne: true,
                    label: "Data Series"
                },
                itemeditor: {
                    type: Datavisualizations.EDITOR.AXES, // Data series with 2-3 dimensions
                    propertyeditors: [
                        {
                            attribute: "xAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "X"
                            }
                        },
                        {
                            attribute: "yAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "Y"
                            }
                        },
                        {
                            attribute: "zAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "Z",
                                label: "Z Axis"
                            }
                        },
                        {
                            attribute: "categories",
                            type: Datavisualizations.EDITOR.FIELD,
                            options: {
                                label: "Generate categories from"
                            }
                        }
                    ]
                }
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN,
            Datavisualizations.WEBACTION_EDITORS.TOOLTIPS,
            Datavisualizations.WEBACTION_EDITORS.MAXVALUES,
            Datavisualizations.WEBACTION_EDITORS.MAXPOINTS,
            Datavisualizations.WEBACTION_EDITORS.MAXSERIES
        ]
    }
};

Datavisualizations.PieChart = {
    default_dimensions: {
        width: 6,
        height: 4
    },
    typespec: {
        extends: Datavisualizations.WEBACTION_TYPES.GRAPH,
        properties: [
            {
                attribute: "retentionStrategyType",
                value: "current"
            }
        ]
    },

    editor: {
        // type: Datavisualizations.EDITOR.PROPERTIES, // assumed
        propertyeditors: [
            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "categoryField",
                options: {
                    label: "Group By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "colorField",
                options: {
                    label: "Color By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "series",
                type: Datavisualizations.EDITOR.COLLECTION,
                style: Datavisualizations.EDITOR_STYLE.CAROUSEL,
                options: {
                    atLeastOne: true,
                    multiple: false,
                    label: "Data Series"
                },
                itemeditor: {
                    type: Datavisualizations.EDITOR.AXES,
                    propertyeditors: [
                        {
                            attribute: "xAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "X"
                            }
                        },
                        {
                            attribute: "yAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "Y"
                            }
                        }
                    ]
                }
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN,
            Datavisualizations.WEBACTION_EDITORS.TOOLTIPS,
            Datavisualizations.WEBACTION_EDITORS.CONDITIONAL_COLORS,
            {
                // jx
                attribute: "show_legend",
                options: {
                    label: "Show Legend"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            {
                // jx
                attribute: "show_data_labels",
                options: {
                    label: "Show data labels"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            Datavisualizations.WEBACTION_EDITORS.MAXVALUES,
            Datavisualizations.WEBACTION_EDITORS.MAXPOINTS,
            Datavisualizations.WEBACTION_EDITORS.MAXSERIES
        ]
    }
};

Datavisualizations.DonutChart = {
    default_dimensions: {
        width: 6,
        height: 4
    },
    typespec: {
        extends: Datavisualizations.WEBACTION_TYPES.GRAPH,
        properties: [
            {
                attribute: "retentionStrategyType",
                value: "current"
            }
        ]
    },
    editor: {
        // type: Datavisualizations.EDITOR.PROPERTIES, // assumed
        propertyeditors: [
            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "categoryField",
                options: {
                    label: "Group By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "colorField",
                options: {
                    label: "Color By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "series",
                type: Datavisualizations.EDITOR.COLLECTION,
                style: Datavisualizations.EDITOR_STYLE.CAROUSEL,
                options: {
                    atLeastOne: true,
                    label: "Data Series"
                },
                itemeditor: {
                    type: Datavisualizations.EDITOR.AXES,
                    propertyeditors: [
                        {
                            attribute: "xAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "X"
                            }
                        },
                        {
                            attribute: "yAxis",
                            //type: Datavisualizations.EDITOR.AXIS, // assumed
                            options: {
                                axis: "Y"
                            }
                        }
                    ]
                }
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN,
            Datavisualizations.WEBACTION_EDITORS.TOOLTIPS,
            Datavisualizations.WEBACTION_EDITORS.CONDITIONAL_COLORS,
            {
                // jx
                attribute: "show_legend",
                options: {
                    label: "Show Legend"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            Datavisualizations.WEBACTION_EDITORS.MAXVALUES,
            Datavisualizations.WEBACTION_EDITORS.MAXPOINTS,
            Datavisualizations.WEBACTION_EDITORS.MAXSERIES
        ]
    }
};

Datavisualizations.LeafletMap = {
    default_dimensions: {
        width: 6,
        height: 4
    },
    typespec: {
        extends: Datavisualizations.WEBACTION_TYPES.MAP,
        properties: [
            {
                attribute: "viewXOffset",
                value: -50
            },
            {
                attribute: "viewYOffset",
                value: 36
            },
            {
                attribute: "viewZoom",
                value: 2
            },
            {
                attribute: "minBubbleSize",
                value: 100
            },
            {
                attribute: "maxBubbleSize",
                value: 100
            },
            {
                attribute: "markerTypes",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.MARKER_TYPES
                }
            },
            {
                attribute: "areaIDField",
                value: ""
            },
            {
                attribute: "areas",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.AREAS
                }
            }
        ]
    },
    editor: {
        // type: Datavisualizations.EDITOR.PROPERTIES, // assumed
        propertyeditors: [
            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    description:
                        "If the Data Retention Type is All, you will get a set of values, while Current means you will only get the most recent value.",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "categoryField",
                options: {
                    label: "Group By"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "colorField",
                options: {
                    label: "Color By"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "latitudeField",
                options: {
                    label: "Latitude"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "longitudeField",
                options: {
                    label: "Longitude"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "areaIDField",
                options: {
                    label: "Area ID Field"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "valueField",
                options: {
                    label: "Value"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "minBubbleSize",
                options: {
                    description: "The minimum size of the bubble. In Pixels.",
                    label: "Min Bubble Size"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "maxBubbleSize",
                options: {
                    description: "The maximum size of the bubble. In Pixels.",
                    label: "Max Bubble Size"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "viewCenterLatitude",
                options: {
                    label: "View Center Latitude"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "viewCenterLongitude",
                options: {
                    label: "View Center Longitude"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "viewZoom",
                options: {
                    label: "View Zoom"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "tilesUrl",
                options: {
                    label: "Tiles URL"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            Datavisualizations.WEBACTION_EDITORS.MARKER_TYPES,
            Datavisualizations.WEBACTION_EDITORS.AREAS,
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN,
            Datavisualizations.WEBACTION_EDITORS.TOOLTIPS,
            Datavisualizations.WEBACTION_EDITORS.CONDITIONAL_COLORS,
            {
                // jx
                attribute: "show_legend",
                options: {
                    label: "Show Legend"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            Datavisualizations.WEBACTION_EDITORS.MAXVALUES,
            Datavisualizations.WEBACTION_EDITORS.MAXPOINTS,
            Datavisualizations.WEBACTION_EDITORS.MAXSERIES
        ]
    }
};

Datavisualizations.VectorMap = {
    default_dimensions: {
        width: 6,
        height: 4
    },
    typespec: {
        extends: Datavisualizations.WEBACTION_TYPES.MAP
    },
    editor: {
        propertyeditors: [
            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "categoryField",
                options: {
                    label: "Group By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "colorField",
                options: {
                    label: "Color By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "latitudeField",
                options: {
                    label: "Latitude"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "longitudeField",
                options: {
                    label: "Longitude"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "valueField",
                options: {
                    label: "Value"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "minBubbleSize",
                options: {
                    label: "Min Bubble Size"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "maxBubbleSize",
                options: {
                    label: "Max Bubble Size"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "viewZoom",
                options: {
                    label: "View Zoom"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "viewXOffset",
                options: {
                    label: "X Offset"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "viewYOffset",
                options: {
                    label: "Y Offset"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN,
            Datavisualizations.WEBACTION_EDITORS.TOOLTIPS,
            Datavisualizations.WEBACTION_EDITORS.CONDITIONAL_COLORS,
            {
                // jx
                attribute: "show_legend",
                options: {
                    label: "Show Legend"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            Datavisualizations.WEBACTION_EDITORS.MAXVALUES,
            Datavisualizations.WEBACTION_EDITORS.MAXPOINTS,
            Datavisualizations.WEBACTION_EDITORS.MAXSERIES
        ]
    }
};

Datavisualizations.Table = {
    default_dimensions: {
        width: 8,
        height: 4
    },
    typespec: {
        properties: [
            {
                attribute: "retentionStrategyType",
                type: String,
                value: "all"
            },
            {
                attribute: "categoryField",
                type: String,
                value: ""
            },
            {
                attribute: "timeField",
                type: String,
                value: ""
            },
            {
                attribute: "show_headers",
                type: Boolean,
                value: true
            },
            {
                attribute: "show_lines",
                type: Boolean,
                value: true
            },
            {
                attribute: "pagesize",
                type: String,
                value: "5"
            },
            {
                attribute: "searchParam",
                type: String,
                value: null
            },
            {
                attribute: "enableSearch",
                type: Boolean,
                value: false
            },
            {
                attribute: "filter",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.PAGE_PARAM_FILTER
                }
            },
            {
                attribute: "pollingQuerySettings",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.POLLING_QUERY_SETTINGS
                }
            },
            {
                attribute: "enablePercentageColumnsWidth",
                type: Boolean,
                value: false
            },
            {
                attribute: "columns",
                type: {
                    itemtype: {
                        properties: [
                            {
                                attribute: "label",
                                type: String
                            },
                            {
                                attribute: "srcfield",
                                type: String
                            },
                            {
                                attribute: "sortOrder",
                                type: Number
                            },
                            {
                                attribute: "widthPercentage",
                                type: Number
                            },
                            {
                                attribute: "drilldown",
                                type: {
                                    extends: Datavisualizations.WEBACTION_TYPES.DRILLDOWN
                                }
                            },
                            {
                                attribute: "width",
                                type: Number
                            },
                            {
                                attribute: "iconConditions",
                                type: {
                                    extends: Datavisualizations.WEBACTION_TYPES.CONDITIONS,
                                    itemtype: {
                                        properties: [
                                            {
                                                attribute: "expression",
                                                type: String
                                            },
                                            {
                                                attribute: "icon",
                                                type: String
                                            },
                                            {
                                                attribute: "color",
                                                type: String
                                            }
                                        ]
                                    }
                                },
                                editor: {
                                    options: {
                                        add_text: "Add a condition"
                                    }
                                }
                            },
                            {
                                attribute: "conditionalColors",
                                type: {
                                    extends: Datavisualizations.WEBACTION_TYPES.CONDITIONS,
                                    itemtype: {
                                        properties: [
                                            {
                                                attribute: "expression",
                                                type: String
                                            },
                                            {
                                                attribute: "color",
                                                type: String
                                            }
                                        ]
                                    }
                                },
                                editor: {
                                    options: {
                                        add_text: "Add a condition"
                                    }
                                }
                            }
                        ]
                    }
                }
            },
            {
                attribute: "data",
                type: Array
            },
            {
                attribute: "maxValues",
                type: String,
                value: "100"
            },
            {
                attribute: "maxPoints",
                type: String,
                value: "500"
            },
            {
                attribute: "maxSeries",
                type: String,
                value: "20"
            },
            {
                attribute: "defaultSortField",
                type: String,
                value: ""
            },
            {
                attribute: "defaultSortOrder",
                type: String,
                value: "asc"
            },
            {
                attribute: "zIndex",
                type: Number,
                value: "1"
            }
        ]
    },
    editor: {
        // type: Datavisualizations.EDITOR.PROPERTIES, // assumed

        validate: function(config) {
            if (!config.enablePercentageColumnsWidth) {
                return;
            }

            if (!config.columns) {
                return;
            }

            if (!config.columns.models) {
                return;
            }

            var total = 0;
            _.each(config.columns.models, function(columnModel) {
                total += parseInt(columnModel.widthPercentage, 10);
            });

            if (total !== 100) {
                return "Sum of all column widths must be equal to 100% while you are trying to save " + total + "%.";
            }
        },
        propertyeditors: [
            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "categoryField",
                options: {
                    label: "Group By"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "show_headers",
                options: {
                    label: "Show Headers"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            {
                attribute: "enablePercentageColumnsWidth",
                options: {
                    label: "Set columns width manually"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            {
                attribute: "show_lines",
                options: {
                    label: "Show Lines"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            {
                attribute: "pagesize",
                options: {
                    label: "Rows per page"
                }
            },
            {
                attribute: "columns",
                options: {
                    label: "Column Configuration",
                    add_text: "Add a column"
                },
                type: Datavisualizations.EDITOR.COLLECTION,
                itemeditor: {
                    propertyeditors: [
                        {
                            attribute: "label",
                            type: Datavisualizations.EDITOR.STRING,
                            options: {
                                flow: "inline",
                                label: "Label",
                                label_style: "heading"
                            }
                        },
                        {
                            attribute: "srcfield",
                            type: Datavisualizations.EDITOR.FIELD,
                            options: {
                                flow: "inline",
                                label: "Source Field",
                                label_style: "heading"
                            }
                        },

                        {
                            attribute: "sortOrder",
                            type: Datavisualizations.EDITOR.NUMBER,
                            options: {
                                label: "Sort Order",
                                length: 3
                            }
                        },

                        {
                            attribute: "widthPercentage",
                            type: Datavisualizations.EDITOR.NUMBER,
                            options: {
                                label: "Width (%)",
                                description: "Sum of all column widths must be equal to 100",
                                length: 3
                            }
                        },

                        {
                            attribute: "drilldown",
                            options: {
                                label: "Drill Down Configuration",
                                label_style: "heading"
                            },
                            type: Datavisualizations.EDITOR.PROPERTIES,
                            propertyeditors: [
                                {
                                    attribute: "show_drilldown",
                                    options: {
                                        label: "Enable"
                                    },
                                    type: Datavisualizations.EDITOR.BOOLEAN
                                },
                                {
                                    attribute: "page",
                                    options: {
                                        label: "Page"
                                    },
                                    type: Datavisualizations.EDITOR.PAGE
                                },
                                {
                                    attribute: "pageparams",
                                    type: Datavisualizations.EDITOR.COLLECTION,
                                    options: {
                                        label: "Page Filters",
                                        add_text: "Add a page filter"
                                    },
                                    itemeditor: {
                                        propertyeditors: [
                                            {
                                                attribute: "pageparam",
                                                options: {
                                                    flow: "inline",
                                                    label: "Id"
                                                },
                                                type: Datavisualizations.EDITOR.STRING
                                            },
                                            {
                                                attribute: "srcfield",
                                                options: {
                                                    flow: "inline",
                                                    label: "Source Field"
                                                },
                                                type: Datavisualizations.EDITOR.FIELD
                                            }
                                        ]
                                    }
                                }
                            ]
                        },

                        {
                            attribute: "iconConditions",
                            type: Datavisualizations.EDITOR.COLLECTION,
                            options: {
                                label: "Icons configuration",
                                label_style: "heading",
                                add_text: "Add icon"
                            },
                            itemeditor: {
                                propertyeditors: [
                                    {
                                        attribute: "expression",
                                        type: Datavisualizations.EDITOR.STRING,
                                        options: {
                                            label: "Expression",
                                            flow: "inline"
                                        }
                                    },
                                    {
                                        attribute: "icon",
                                        type: Datavisualizations.EDITOR.ENUM,
                                        options: {
                                            label: "Icon",
                                            flow: "inline",
                                            values: getIcons(),
                                            showIcon: true
                                        }
                                    },
                                    {
                                        attribute: "color",
                                        type: Datavisualizations.EDITOR.COLOR,
                                        options: {
                                            label: "Color",
                                            flow: "inline"
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            attribute: "conditionalColors",
                            type: Datavisualizations.EDITOR.COLLECTION,
                            options: {
                                label: "Conditional colors",
                                label_style: "heading",
                                description:
                                    "Allows to change cell font color based on condition<br>Icons configuration has a priority.",
                                add_text: "Add conditional color"
                            },
                            itemeditor: {
                                propertyeditors: [
                                    {
                                        attribute: "expression",
                                        type: Datavisualizations.EDITOR.STRING,
                                        options: {
                                            label: "Expression",
                                            flow: "inline",
                                            description: ""
                                        }
                                    },
                                    {
                                        attribute: "color",
                                        type: Datavisualizations.EDITOR.COLOR,
                                        options: {
                                            label: "Color",
                                            flow: "inline",
                                            description: ""
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            Datavisualizations.WEBACTION_EDITORS.MAXVALUES,
            Datavisualizations.WEBACTION_EDITORS.MAXPOINTS,
            Datavisualizations.WEBACTION_EDITORS.MAXSERIES,
            {
                attribute: "defaultSortField",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Default sort field"
                }
            },
            {
                attribute: "defaultSortOrder",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Default sort order",
                    values: [
                        {
                            id: "asc",
                            name: "Ascending"
                        },
                        {
                            id: "desc",
                            name: "Descending"
                        }
                    ]
                }
            }
        ]
    }
};

Datavisualizations.Gauge = {
    default_dimensions: {
        width: 3,
        height: 3
    },

    typespec: {
        properties: [
            {
                attribute: "retentionStrategyType",
                type: String,
                value: "current"
            },
            {
                attribute: "categoryField",
                type: String,
                value: ""
            },
            {
                attribute: "timeField",
                type: String,
                value: ""
            },
            {
                attribute: "current_value",
                value: 0
            },
            {
                attribute: "field",
                type: String
            },
            {
                attribute: "data",
                type: Array
            },
            {
                attribute: "unit",
                value: "Units"
            },
            {
                attribute: "min",
                value: 0
            },
            {
                attribute: "max",
                value: 100
            },
            {
                attribute: "thresholds",
                type: {
                    itemtype: {
                        properties: [
                            {
                                attribute: "ceiling",
                                type: String
                            },
                            {
                                attribute: "color",
                                type: String
                            }
                        ]
                    }
                }
            },
            {
                attribute: "filter",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.PAGE_PARAM_FILTER
                }
            },
            {
                attribute: "pollingQuerySettings",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.POLLING_QUERY_SETTINGS
                }
            },
            {
                attribute: "zIndex",
                type: Number,
                value: "1"
            }
        ]
    },

    editor: {
        // type: Datavisualizations.EDITOR.PROPERTIES, // assumed
        propertyeditors: [
            {
                attribute: "field",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Field"
                }
            },
            {
                attribute: "unit",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Unit"
                }
            },
            {
                attribute: "min",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Minimum"
                }
            },
            {
                attribute: "max",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Maximum"
                }
            },
            {
                attribute: "thresholds",
                type: Datavisualizations.EDITOR.COLLECTION,
                options: {
                    label: "Thresholds"
                },
                itemeditor: {
                    propertyeditors: [
                        {
                            attribute: "ceiling",
                            options: {
                                label: "Ceiling"
                            },
                            type: Datavisualizations.EDITOR.STRING
                        },
                        {
                            attribute: "color",
                            options: {
                                label: "Color"
                            },
                            type: Datavisualizations.EDITOR.COLOR
                        }
                    ]
                }
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS
        ]
    }
};

Datavisualizations.Value = {
    default_dimensions: {
        width: 3,
        height: 1
    },

    typespec: {
        properties: [
            {
                attribute: "retentionStrategyType",
                type: String,
                value: "current"
            },
            {
                attribute: "categoryField",
                type: String,
                value: ""
            },
            {
                attribute: "timeField",
                type: String,
                value: ""
            },
            {
                attribute: "verticallyCentered",
                type: Boolean
            },
            {
                attribute: "clear_filters_button",
                type: Boolean,
                value: false
            },
            {
                attribute: "templates",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.CONDITIONS,
                    itemtype: {
                        properties: [
                            // Wrapping color and background_color into a style attribute,
                            // for consistency with grid style properties
                            /*
                            { attribute: 'background_color', value: 'transparent' },
                            { attribute: 'color', value: '#000' },
                            */
                            {
                                attribute: "style",
                                type: {
                                    properties: [
                                        {
                                            attribute: "background",
                                            value: "transparent"
                                        },
                                        {
                                            attribute: "color",
                                            value: "#000"
                                        }
                                    ]
                                }
                            },
                            {
                                attribute: "userTemplate",
                                type: String
                            }
                        ]
                    }
                }
            },
            {
                attribute: "filter",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.PAGE_PARAM_FILTER
                }
            },
            {
                attribute: "pollingQuerySettings",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.POLLING_QUERY_SETTINGS
                }
            },
            {
                attribute: "drilldown",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.DRILLDOWN
                }
            },
            {
                attribute: "zIndex",
                type: Number,
                value: "1"
            }
        ]
    },
    editor: {
        propertyeditors: [
            {
                // Editing the conditions property means editing each condition individually. we use the COLLECTION editor for this purpose, and provide an item editor array.
                attribute: "templates",
                type: Datavisualizations.EDITOR.COLLECTION,
                options: {
                    add_text: "Add a conditional template"
                },
                // For each item in the array (e.g. condition), use the following editor
                itemeditor: {
                    // For the attribute in the condition, use the following editors
                    // type: Datavisualizations.EDITOR.PROPERTIES is assumed.
                    //options: { label: 'Condition' },
                    propertyeditors: [
                        {
                            // The input of this expression is actually something that is added to the conditions collection, and just the ID stored here
                            attribute: "expression",
                            type: Datavisualizations.EDITOR.STRING,
                            options: {
                                label: "Expression",
                                flow: "inline",
                                label_style: "heading"
                            }
                        },
                        // Wrapping color and background_color into a style attribute,
                        // for consistency with grid style properties
                        {
                            attribute: "style",
                            options: {
                                flow: "inline"
                            },
                            type: Datavisualizations.EDITOR.PROPERTIES,
                            propertyeditors: [
                                {
                                    attribute: "color",
                                    type: Datavisualizations.EDITOR.COLOR,
                                    options: {
                                        label: "Text",
                                        flow: "inline",
                                        label_style: "heading"
                                    }
                                },
                                {
                                    attribute: "background",
                                    type: Datavisualizations.EDITOR.COLOR,
                                    options: {
                                        label: "Background",
                                        flow: "inline",
                                        label_style: "heading"
                                    }
                                }
                            ]
                        },
                        /* {
                            attribute: 'userTemplate',
                            type: Datavisualizations.EDITOR.TEXT,
                            options: {
                                label: 'Template',
                                flow: 'inline',
                                label_style: 'heading'
                            }
                        },*/
                        {
                            attribute: "labelHead",
                            options: {
                                label: "Heading Type",
                                flow: "inline",
                                label_style: "heading"
                            },
                            type: Datavisualizations.EDITOR.HEADING
                        }
                        /*,
                                                    {
                                                        attribute: 'userTemplate',
                                                        type: Datavisualizations.EDITOR.STRING,
                                                        options: {
                                                            label: 'Heading Text',
                                                            flow: 'inline',
                                                            label_style: 'heading'
                                                        }
                                                    }*/
                    ]
                }
            },
            {
                attribute: "verticallyCentered",
                type: Datavisualizations.EDITOR.BOOLEAN,
                options: {
                    label: "Vertically center",
                    value: false
                }
            },
            {
                attribute: "clear_filters_button",
                type: Datavisualizations.EDITOR.BOOLEAN,
                options: {
                    label: "Clear All Filters On Click"
                }
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN
        ]
    }
};

Datavisualizations.Label = {
    default_dimensions: {
        width: 3,
        height: 1
    },

    typespec: {
        properties: [
            {
                attribute: "label",
                type: String,
                value: ""
            },

            {
                attribute: "color",
                type: String,
                value: "#000"
            },

            {
                attribute: "background",
                type: String,
                value: "transparent"
            },

            {
                attribute: "labelHead",
                type: String,
                value: ""
            },

            {
                attribute: "visualizationType",
                type: String,
                value: "LABEL"
            },
            {
                attribute: "zIndex",
                type: Number,
                value: "1"
            },
            {
                attribute: "userTemplate",
                type: String
            },
            {
                attribute: "verticallyCentered",
                type: Boolean
            }
        ]
    },
    editor: {
        propertyeditors: [
            {
                attribute: "label",
                options: {
                    label: "Label"
                }
            },

            {
                attribute: "color",
                type: Datavisualizations.EDITOR.COLOR,
                options: {
                    label: "Text"
                }
            },

            {
                attribute: "background",
                type: Datavisualizations.EDITOR.COLOR,
                options: {
                    label: "Background"
                }
            },

            {
                attribute: "labelHead",
                options: {
                    label: "Heading Type"
                },
                type: Datavisualizations.EDITOR.HEADING_SELECTOR
            },
            {
                attribute: "verticallyCentered",
                type: Datavisualizations.EDITOR.BOOLEAN,
                options: {
                    label: "Vertically center",
                    value: false
                }
            }
        ]
    }
};

Datavisualizations.SelectVisualization = {
    default_dimensions: {
        width: 2,
        height: 1
    },

    typespec: {
        properties: [
            {
                attribute: "pollingQuerySettings",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.POLLING_QUERY_SETTINGS
                }
            },
            {
                attribute: "retentionStrategyType",
                type: String,
                value: "all"
            },
            {
                attribute: "valueField",
                value: ""
            },
            {
                attribute: "textField",
                value: ""
            },
            {
                attribute: "placeholder",
                value: "Select..."
            },
            {
                attribute: "drilldown",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.DRILLDOWN
                }
            },
            {
                attribute: "zIndex",
                type: Number,
                value: "1"
            }
        ]
    },
    editor: {
        propertyeditors: [
            {
                attribute: "valueField",
                options: {
                    label: "Value field"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "textField",
                options: {
                    label: "Text field",
                    description: "When empty, then Value field is used as text"
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "placeholder",
                options: {
                    label: "Placeholder"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN,
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS
        ]
    }
};

Datavisualizations.WordCloud = {
    default_dimensions: {
        width: 3,
        height: 3
    },

    typespec: {
        properties: [
            {
                attribute: "retentionStrategyType",
                type: String,
                value: "all"
            },
            {
                attribute: "categoryField",
                type: String,
                value: ""
            },
            {
                attribute: "timeField",
                type: String,
                value: ""
            },
            {
                attribute: "wordText",
                type: String
            },
            {
                attribute: "wordSize",
                type: String
            },
            {
                attribute: "data",
                type: Array
            },
            {
                attribute: "filter",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.PAGE_PARAM_FILTER
                }
            },
            {
                attribute: "pollingQuerySettings",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.POLLING_QUERY_SETTINGS
                }
            },
            {
                attribute: "drilldown",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.DRILLDOWN
                }
            },
            {
                attribute: "tooltips",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.TOOLTIPS
                }
            },
            {
                attribute: "conditionalColor",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.CONDITIONAL_COLORS
                }
            },
            {
                attribute: "maxValues",
                type: String,
                value: "100"
            },
            {
                attribute: "maxPoints",
                type: String,
                value: "500"
            },
            {
                attribute: "maxWords",
                type: Number,
                value: "50"
            },
            {
                attribute: "zIndex",
                type: Number,
                value: "1"
            }
        ]
    },
    editor: {
        propertyeditors: [
            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "wordText",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Word Text"
                }
            },
            {
                attribute: "wordSize",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Word Size"
                }
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN,
            Datavisualizations.WEBACTION_EDITORS.TOOLTIPS,
            Datavisualizations.WEBACTION_EDITORS.CONDITIONAL_COLORS,
            Datavisualizations.WEBACTION_EDITORS.MAXVALUES,
            Datavisualizations.WEBACTION_EDITORS.MAXPOINTS,
            {
                attribute: "maxWords",
                type: Datavisualizations.EDITOR.NUMBER,
                options: {
                    label: "Maximum number of words to show"
                }
            }
        ]
    }
};

Datavisualizations.ImageMap = {
    default_dimensions: {
        width: 5,
        height: 5
    },

    typespec: {
        properties: [
            {
                attribute: "retentionStrategyType",
                type: String,
                value: "current"
            },
            {
                attribute: "categoryField",
                type: String,
                value: ""
            },
            {
                attribute: "timeField",
                type: String,
                value: ""
            },
            {
                attribute: "images",
                type: {
                    itemtype: {
                        properties: [
                            {
                                attribute: "label",
                                type: String
                            },
                            {
                                attribute: "imageSrc",
                                type: String
                            },
                            {
                                attribute: "opacity",
                                type: Number,
                                value: 0.3
                            },
                            {
                                attribute: "display",
                                type: String,
                                value: "none"
                            }
                        ]
                    }
                }
            },
            {
                attribute: "maxValues",
                type: String,
                value: "100000"
            },
            {
                attribute: "maxPoints",
                type: String,
                value: "500"
            },
            {
                attribute: "maxSeries",
                type: String,
                value: "10000"
            },
            {
                attribute: "zoneNameField",
                value: ""
            },
            {
                attribute: "zoneGeomField",
                value: ""
            },
            {
                attribute: "zoneColorField",
                value: ""
            },
            {
                attribute: "pathNameField",
                value: ""
            },
            {
                attribute: "pathGeomField",
                value: ""
            },
            {
                attribute: "pathColorField",
                value: ""
            },
            {
                attribute: "pathHeadSize",
                type: Number,
                value: 10
            },
            {
                attribute: "pathAnimSize",
                type: Number,
                value: 20
            },
            {
                attribute: "pathAnimTimes",
                type: Number,
                value: -1
            },
            {
                attribute: "pathTimeOut",
                type: Number,
                value: -1
            },
            {
                attribute: "pathTimeOutField",
                value: ""
            },
            {
                attribute: "minX",
                type: Number,
                value: "0.0"
            },
            {
                attribute: "maxX",
                type: Number,
                value: "5.0"
            },
            {
                attribute: "orientX",
                type: String,
                value: "left"
            },
            {
                attribute: "minY",
                type: Number,
                value: "0.0"
            },
            {
                attribute: "maxY",
                type: Number,
                value: "5.0"
            },
            {
                attribute: "orientY",
                type: String,
                value: "top"
            },
            {
                attribute: "hmBoxes",
                type: Number,
                value: "20"
            },

            {
                attribute: "data",
                type: Array
            },
            {
                attribute: "filter",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.PAGE_PARAM_FILTER
                }
            },
            {
                attribute: "pollingQuerySettings",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.POLLING_QUERY_SETTINGS
                }
            },
            {
                attribute: "drilldown",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.DRILLDOWN
                }
            },
            {
                attribute: "tooltips",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.TOOLTIPS
                }
            },
            {
                attribute: "conditionalColor",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.CONDITIONAL_COLORS
                }
            },
            {
                attribute: "maxZones",
                type: String,
                value: "100"
            },
            {
                attribute: "maxPaths",
                type: Number,
                value: "20"
            },
            {
                attribute: "maxPathEntries",
                type: Number,
                value: "20"
            },
            {
                attribute: "zIndex",
                type: Number,
                value: "1"
            }
        ]
    },
    editor: {
        propertyeditors: [
            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "categoryField",
                options: {
                    label: "Group By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "images",
                type: Datavisualizations.EDITOR.COLLECTION,
                options: {
                    label: "Images To Show",
                    label_style: "heading"
                },
                itemeditor: {
                    propertyeditors: [
                        {
                            attribute: "label",
                            options: {
                                label: "Layer Name"
                            },
                            type: Datavisualizations.EDITOR.STRING
                        },
                        {
                            attribute: "imageSrc",
                            options: {
                                label: "Image Source Location"
                            },
                            type: Datavisualizations.EDITOR.STRING
                        },
                        {
                            attribute: "opacity",
                            options: {
                                label: "Image Opacity"
                            },
                            type: Datavisualizations.EDITOR.Number
                        },
                        {
                            attribute: "display",
                            type: Datavisualizations.EDITOR.ENUM,
                            options: {
                                label: "Initial Display",
                                values: [
                                    {
                                        id: "none",
                                        name: "Hide Initially"
                                    },
                                    {
                                        id: "inherit",
                                        name: "Show Initially"
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                attribute: "zoneNameField",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Zone Name"
                }
            },
            {
                attribute: "zoneGeomField",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Zone Geometry"
                }
            },
            {
                attribute: "zoneColorField",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Zone Color"
                }
            },
            {
                attribute: "pathNameField",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Path Name"
                }
            },
            {
                attribute: "pathGeomField",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Path Point Geometry"
                }
            },
            {
                attribute: "pathColorField",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Path Color"
                }
            },
            {
                attribute: "pathHeadSize",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Diameter of Path Head"
                }
            },
            {
                attribute: "pathAnimSize",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Diameter of Path Head Animation"
                }
            },
            {
                attribute: "pathTimeOut",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "TimeOut Fade on Inactivity (-1 = indefinite)"
                }
            },
            {
                attribute: "pathTimeOutField",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Path TimeOut DateTime Field"
                }
            },
            {
                attribute: "pathAnimTimes",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Times Animation Repeats (-1 = indefinite)"
                }
            },
            {
                attribute: "minX",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Min X"
                }
            },
            {
                attribute: "maxX",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Max X"
                }
            },
            {
                attribute: "orientX",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "X Direction",
                    values: [
                        {
                            id: "left",
                            name: "Values Increase Left to Right"
                        },
                        {
                            id: "right",
                            name: "Values Increase Right to Left"
                        }
                    ]
                }
            },
            {
                attribute: "minY",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Min Y"
                }
            },
            {
                attribute: "maxY",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Max Y"
                }
            },
            {
                attribute: "orientY",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Y Direction",
                    values: [
                        {
                            id: "top",
                            name: "Values Increase Top to Bottom"
                        },
                        {
                            id: "bottom",
                            name: "Values Increase Bottom to Top"
                        }
                    ]
                }
            },
            {
                attribute: "hmBoxes",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Number of HeatMap Boxes per Row / Column"
                }
            },
            Datavisualizations.WEBACTION_EDITORS.PAGE_PARAM_FILTER,
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN,
            Datavisualizations.WEBACTION_EDITORS.TOOLTIPS,
            Datavisualizations.WEBACTION_EDITORS.CONDITIONAL_COLORS,
            {
                attribute: "maxZones",
                type: Datavisualizations.EDITOR.NUMBER,
                options: {
                    label: "Maximum number of zones to show"
                }
            },
            {
                attribute: "maxPaths",
                type: Datavisualizations.EDITOR.NUMBER,
                options: {
                    label: "Maximum number of paths to show"
                }
            },
            {
                attribute: "maxPathEntries",
                type: Datavisualizations.EDITOR.NUMBER,
                options: {
                    label: "Maximum number of points for each path to show"
                }
            },
            Datavisualizations.WEBACTION_EDITORS.MAXVALUES,
            Datavisualizations.WEBACTION_EDITORS.MAXPOINTS,
            Datavisualizations.WEBACTION_EDITORS.MAXSERIES
        ]
    }
};

Datavisualizations.Choropleth = {
    default_dimensions: {
        width: 6,
        height: 4
    },
    typespec: {
        properties: [
            {
                attribute: "retentionStrategyType",
                type: String,
                value: "all"
            },
            {
                attribute: "title",
                type: String,
                value: null
            },
            {
                attribute: "map",
                type: String,
                value: "US"
            },
            {
                attribute: "timeField",
                type: String,
                value: ""
            },
            {
                attribute: "field",
                type: String
            },
            {
                attribute: "value",
                type: String
            },
            {
                attribute: "joinBy",
                type: String,
                value: "postal-code"
            },
            {
                attribute: "unit",
                type: String,
                value: ""
            },
            {
                attribute: "viewXOffset",
                value: null
            },
            {
                attribute: "viewYOffset",
                value: null
            },
            {
                attribute: "viewZoom",
                value: null
            },
            {
                attribute: "minColor",
                value: "#0000ff"
            },
            {
                attribute: "maxColor",
                value: "#ff0000"
            },
            {
                attribute: "hoverColor",
                value: "#BADA55"
            },
            {
                attribute: "labelColor",
                value: "#ffffff"
            },
            {
                attribute: "pollingQuerySettings",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.POLLING_QUERY_SETTINGS
                }
            },
            {
                attribute: "drilldown",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.DRILLDOWN
                }
            },
            {
                attribute: "zIndex",
                type: Number,
                value: "1"
            }
        ]
    },
    editor: {
        propertyeditors: [
            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "title",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Title",
                    allow_blank: true
                }
            },
            {
                attribute: "map",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Map",
                    values: [
                        {
                            id: "WORLD_HIGHRES",
                            name: "World"
                        },
                        {
                            id: "US",
                            name: "USA"
                        },
                        {
                            id: "US_AL",
                            name: "Alabama"
                        },
                        {
                            id: "US_AK",
                            name: "Arkansas"
                        },
                        {
                            id: "US_AR",
                            name: "Arizona"
                        },
                        {
                            id: "US_AZ",
                            name: "Arizona"
                        },
                        {
                            id: "US_CA",
                            name: "California"
                        },
                        {
                            id: "US_CO",
                            name: "Colorado"
                        },
                        {
                            id: "US_CT",
                            name: "Connecticut "
                        },
                        {
                            id: "US_DC",
                            name: "Washington DC"
                        },
                        {
                            id: "US_DE",
                            name: "Delaware"
                        },
                        {
                            id: "US_FL",
                            name: "Florida"
                        },
                        {
                            id: "US_GA",
                            name: "Georgia"
                        },
                        {
                            id: "US_HI",
                            name: "Hawaii"
                        },
                        {
                            id: "US_IA",
                            name: "Iowa"
                        },
                        {
                            id: "US_ID",
                            name: "Idaho"
                        },
                        {
                            id: "US_IL",
                            name: "Illinois"
                        },
                        {
                            id: "US_IN",
                            name: "Indiana"
                        },
                        {
                            id: "US_KS",
                            name: "Kansas"
                        },
                        {
                            id: "US_KY",
                            name: "Kentucky"
                        },
                        {
                            id: "US_LA",
                            name: "Louisiana"
                        },
                        {
                            id: "US_ME",
                            name: "Maine"
                        },
                        {
                            id: "US_MD",
                            name: "Maryland"
                        },
                        {
                            id: "US_MA",
                            name: "Massachusetts"
                        },
                        {
                            id: "US_MI",
                            name: "Michigan"
                        },
                        {
                            id: "US_MN",
                            name: "Minnesota"
                        },
                        {
                            id: "US_MS",
                            name: "Mississippi"
                        },
                        {
                            id: "US_MO",
                            name: "Missouri"
                        },
                        {
                            id: "US_MT",
                            name: "Montana"
                        },
                        {
                            id: "US_NE",
                            name: "Nebraska"
                        },
                        {
                            id: "US_NV",
                            name: "Nevada"
                        },
                        {
                            id: "US_NH",
                            name: "New Hampshire"
                        },
                        {
                            id: "US_NJ",
                            name: "New Jersey"
                        },
                        {
                            id: "US_NM",
                            name: "New Mexico"
                        },
                        {
                            id: "US_NY",
                            name: "New York"
                        },
                        {
                            id: "US_NC",
                            name: "North Carolina"
                        },
                        {
                            id: "US_ND",
                            name: "North Dakota"
                        },
                        {
                            id: "US_OH",
                            name: "Ohio"
                        },
                        {
                            id: "US_OK",
                            name: "Oklahoma"
                        },
                        {
                            id: "US_OR",
                            name: "Oregon"
                        },
                        {
                            id: "US_PA",
                            name: "Pennsylvania"
                        },
                        {
                            id: "US_RI",
                            name: "Rhode Island"
                        },
                        {
                            id: "US_SC",
                            name: "South Carolina"
                        },
                        {
                            id: "US_SD",
                            name: "South Dakota"
                        },
                        {
                            id: "US_TN",
                            name: "Tennessee"
                        },
                        {
                            id: "US_TX",
                            name: "Texas"
                        },
                        {
                            id: "US_UT",
                            name: "Utah"
                        },
                        {
                            id: "US_VT",
                            name: "Vermont"
                        },
                        {
                            id: "US_VA",
                            name: "Virginia"
                        },
                        {
                            id: "US_WA",
                            name: "Washington"
                        },
                        {
                            id: "US_WV",
                            name: "West Virginia"
                        },
                        {
                            id: "US_WI",
                            name: "Wisconsin"
                        },
                        {
                            id: "US_WY",
                            name: "Wyoming"
                        },
                        {
                            id: "US_WY",
                            name: "Wyoming"
                        },
                        {
                            id: "AD",
                            name: "Andorra"
                        },
                        {
                            id: "AE",
                            name: "United Arab Emirates"
                        },
                        {
                            id: "AF",
                            name: "Afghanistan"
                        },
                        {
                            id: "AG",
                            name: "Antigua and Barbuda"
                        },
                        {
                            id: "AL",
                            name: "Albania"
                        },
                        {
                            id: "AM",
                            name: "Armenia"
                        },
                        {
                            id: "AO",
                            name: "Angola"
                        },
                        {
                            id: "AR",
                            name: "Argentina"
                        },
                        {
                            id: "AS",
                            name: "American Samoa"
                        },
                        {
                            id: "AU",
                            name: "Australia"
                        },
                        {
                            id: "AZ",
                            name: "Azerbaijan"
                        },
                        {
                            id: "BA",
                            name: "Bosnia and Herzegovina"
                        },
                        {
                            id: "BB",
                            name: "Barbados"
                        },
                        {
                            id: "BD",
                            name: "Bangladesh"
                        },
                        {
                            id: "BE",
                            name: "Belgium"
                        },
                        {
                            id: "BF",
                            name: "Burkina Faso"
                        },
                        {
                            id: "BG",
                            name: "Bulgaria"
                        },
                        {
                            id: "BH",
                            name: "Bahrain"
                        },
                        {
                            id: "BI",
                            name: "Burundi"
                        },
                        {
                            id: "BJ",
                            name: "Benin"
                        },
                        {
                            id: "BN",
                            name: "Brunei"
                        },
                        {
                            id: "BO",
                            name: "Bolivia"
                        },
                        {
                            id: "BR",
                            name: "Brazil"
                        },
                        {
                            id: "BS",
                            name: "The Bahamas"
                        },
                        {
                            id: "BT",
                            name: "Bhutan"
                        },
                        {
                            id: "BW",
                            name: "Botswana"
                        },
                        {
                            id: "BY",
                            name: "Belarus"
                        },
                        {
                            id: "BZ",
                            name: "Belize"
                        },
                        {
                            id: "CA",
                            name: "Canada"
                        },
                        {
                            id: "CD",
                            name: "Democratic Republic of the Congo"
                        },
                        {
                            id: "CF",
                            name: "Central African Republic"
                        },
                        {
                            id: "CH",
                            name: "Switzerland"
                        },
                        {
                            id: "CI",
                            name: "Ivory Coast"
                        },
                        {
                            id: "CK",
                            name: "Cook Islands"
                        },
                        {
                            id: "CL",
                            name: "Chile"
                        },
                        {
                            id: "CM",
                            name: "Cameroon"
                        },
                        {
                            id: "CN",
                            name: "China"
                        },
                        {
                            id: "CO",
                            name: "Colombia"
                        },
                        {
                            id: "CR",
                            name: "Costa Rica"
                        },
                        {
                            id: "CU",
                            name: "Cuba"
                        },
                        {
                            id: "CV",
                            name: "Cape Verde"
                        },
                        {
                            id: "CY",
                            name: "Cyprus"
                        },
                        {
                            id: "CZ",
                            name: "Czech Republic"
                        },
                        {
                            id: "DE",
                            name: "Germany"
                        },
                        {
                            id: "DJ",
                            name: "Djibouti"
                        },
                        {
                            id: "DK",
                            name: "Denmark"
                        },
                        {
                            id: "DM",
                            name: "Dominica"
                        },
                        {
                            id: "DO",
                            name: "Dominican Republic"
                        },
                        {
                            id: "DZ",
                            name: "Algeria"
                        },
                        {
                            id: "EC",
                            name: "Ecuador"
                        },
                        {
                            id: "EE",
                            name: "Estonia"
                        },
                        {
                            id: "EG",
                            name: "Egypt"
                        },
                        {
                            id: "EH",
                            name: "Western Sahara"
                        },
                        {
                            id: "ER",
                            name: "Eritrea"
                        },
                        {
                            id: "ES",
                            name: "Spain"
                        },
                        {
                            id: "ET",
                            name: "Ethiopia"
                        },
                        {
                            id: "FI",
                            name: "Finland"
                        },
                        {
                            id: "FJ",
                            name: "Fiji"
                        },
                        {
                            id: "FO",
                            name: "Faroe Islands"
                        },
                        {
                            id: "FR",
                            name: "France"
                        },
                        {
                            id: "GA",
                            name: "Gabon"
                        },
                        {
                            id: "GB",
                            name: "United Kingdom"
                        },
                        {
                            id: "GD",
                            name: "Grenada"
                        },
                        {
                            id: "GE",
                            name: "Georgia"
                        },
                        {
                            id: "GH",
                            name: "Ghana"
                        },
                        {
                            id: "GL",
                            name: "Greenland"
                        },
                        {
                            id: "GM",
                            name: "Gambia"
                        },
                        {
                            id: "GN",
                            name: "Guinea"
                        },
                        {
                            id: "GQ",
                            name: "Equatorial Guinea"
                        },
                        {
                            id: "GR",
                            name: "Greece"
                        },
                        {
                            id: "GT",
                            name: "Guatemala"
                        },
                        {
                            id: "GU",
                            name: "Guam"
                        },
                        {
                            id: "GW",
                            name: "Guinea Bissau"
                        },
                        {
                            id: "GY",
                            name: "Guyana"
                        },
                        {
                            id: "HN",
                            name: "Honduras"
                        },
                        {
                            id: "HR",
                            name: "Croatia"
                        },
                        {
                            id: "HT",
                            name: "Haiti"
                        },
                        {
                            id: "HU",
                            name: "Hungary"
                        },
                        {
                            id: "ID",
                            name: "Indonesia"
                        },
                        {
                            id: "IE",
                            name: "Ireland"
                        },
                        {
                            id: "IL",
                            name: "Israel"
                        },
                        {
                            id: "IN",
                            name: "India with Andaman and Nicobar"
                        },
                        {
                            id: "IQ",
                            name: "Iraq"
                        },
                        {
                            id: "IR",
                            name: "Iran"
                        },
                        {
                            id: "IS",
                            name: "Iceland"
                        },
                        {
                            id: "IT",
                            name: "Italy"
                        },
                        {
                            id: "JM",
                            name: "Jamaica"
                        },
                        {
                            id: "JO",
                            name: "Jordan"
                        },
                        {
                            id: "JP",
                            name: "Japan"
                        },
                        {
                            id: "KE",
                            name: "Kenya"
                        },
                        {
                            id: "KG",
                            name: "Kyrgyzstan"
                        },
                        {
                            id: "KH",
                            name: "Cambodia"
                        },
                        {
                            id: "KM",
                            name: "Comoros"
                        },
                        {
                            id: "KN",
                            name: "Saint Kitts and Nevis"
                        },
                        {
                            id: "KP",
                            name: "North Korea"
                        },
                        {
                            id: "KR",
                            name: "South Korea"
                        },
                        {
                            id: "KV",
                            name: "Kosovo"
                        },
                        {
                            id: "KW",
                            name: "Kuwait"
                        },
                        {
                            id: "KZ",
                            name: "Kazakhstan"
                        },
                        {
                            id: "LA",
                            name: "Laos"
                        },
                        {
                            id: "LB",
                            name: "Lebanon"
                        },
                        {
                            id: "LC",
                            name: "Saint Lucia"
                        },
                        {
                            id: "LI",
                            name: "Liechtenstein"
                        },
                        {
                            id: "LK",
                            name: "Sri Lanka"
                        },
                        {
                            id: "LR",
                            name: "Liberia"
                        },
                        {
                            id: "LS",
                            name: "Lesotho"
                        },
                        {
                            id: "LT",
                            name: "Lithuania"
                        },
                        {
                            id: "LU",
                            name: "Luxembourg"
                        },
                        {
                            id: "LV",
                            name: "Latvia"
                        },
                        {
                            id: "LY",
                            name: "Libya"
                        },
                        {
                            id: "MA",
                            name: "Morocco"
                        },
                        {
                            id: "MC",
                            name: "Monaco"
                        },
                        {
                            id: "MD",
                            name: "Moldova"
                        },
                        {
                            id: "ME",
                            name: "Montenegro"
                        },
                        {
                            id: "MG",
                            name: "Madagascar"
                        },
                        {
                            id: "MK",
                            name: "Macedonia"
                        },
                        {
                            id: "ML",
                            name: "Mali"
                        },
                        {
                            id: "MM",
                            name: "Myanmar"
                        },
                        {
                            id: "MN",
                            name: "Mongolia"
                        },
                        {
                            id: "MP",
                            name: "Northern Mariana Islands"
                        },
                        {
                            id: "MR",
                            name: "Mauritania"
                        },
                        {
                            id: "MT",
                            name: "Malta"
                        },
                        {
                            id: "MU",
                            name: "Mauritius"
                        },
                        {
                            id: "MW",
                            name: "Malawi"
                        },
                        {
                            id: "MX",
                            name: "Mexico"
                        },
                        {
                            id: "MY",
                            name: "Malaysia"
                        },
                        {
                            id: "MZ",
                            name: "Mozambique"
                        },
                        {
                            id: "NA",
                            name: "Namibia"
                        },
                        {
                            id: "NC",
                            name: "New Caledonia"
                        },
                        {
                            id: "NE",
                            name: "Niger"
                        },
                        {
                            id: "NG",
                            name: "Nigeria"
                        },
                        {
                            id: "NI",
                            name: "Nicaragua"
                        },
                        {
                            id: "NL",
                            name: "The Netherlands"
                        },
                        {
                            id: "NO",
                            name: "Norway"
                        },
                        {
                            id: "NP",
                            name: "Nepal"
                        },
                        {
                            id: "NR",
                            name: "Nauru"
                        },
                        {
                            id: "NZ",
                            name: "New Zealand"
                        },
                        {
                            id: "OM",
                            name: "Oman"
                        },
                        {
                            id: "PA",
                            name: "Panama"
                        },
                        {
                            id: "PE",
                            name: "Peru"
                        },
                        {
                            id: "PG",
                            name: "Papua New Guinea"
                        },
                        {
                            id: "PH",
                            name: "Philippines"
                        },
                        {
                            id: "PK",
                            name: "Pakistan"
                        },
                        {
                            id: "PL",
                            name: "Poland"
                        },
                        {
                            id: "PR",
                            name: "Puerto Rico"
                        },
                        {
                            id: "PT",
                            name: "Portugal"
                        },
                        {
                            id: "PW",
                            name: "Palau"
                        },
                        {
                            id: "PY",
                            name: "Paraguay"
                        },
                        {
                            id: "QA",
                            name: "Qatar"
                        },
                        {
                            id: "RO",
                            name: "Romania"
                        },
                        {
                            id: "RS",
                            name: "Republic of Serbia"
                        },
                        {
                            id: "RU",
                            name: "Russia"
                        },
                        {
                            id: "RW",
                            name: "Rwanda"
                        },
                        {
                            id: "SA",
                            name: "Saudi Arabia"
                        },
                        {
                            id: "SB",
                            name: "Solomon Islands"
                        },
                        {
                            id: "SC",
                            name: "Seychelles"
                        },
                        {
                            id: "SD",
                            name: "Sudan"
                        },
                        {
                            id: "SE",
                            name: "Sweden"
                        },
                        {
                            id: "SG",
                            name: "Singapore"
                        },
                        {
                            id: "SI",
                            name: "Slovenia"
                        },
                        {
                            id: "SK",
                            name: "Slovakia"
                        },
                        {
                            id: "SL",
                            name: "Sierra Leone"
                        },
                        {
                            id: "SM",
                            name: "San Marino"
                        },
                        {
                            id: "SN",
                            name: "Senegal"
                        },
                        {
                            id: "SO",
                            name: "Somalia"
                        },
                        {
                            id: "SR",
                            name: "Suriname"
                        },
                        {
                            id: "SS",
                            name: "South Sudan"
                        },
                        {
                            id: "ST",
                            name: "Sao Tome and Principe"
                        },
                        {
                            id: "SV",
                            name: "El Salvador"
                        },
                        {
                            id: "SX",
                            name: "Somaliland"
                        },
                        {
                            id: "SY",
                            name: "Syria"
                        },
                        {
                            id: "SZ",
                            name: "Swaziland"
                        },
                        {
                            id: "TD",
                            name: "Chad"
                        },
                        {
                            id: "TG",
                            name: "Togo"
                        },
                        {
                            id: "TH",
                            name: "Thailand"
                        },
                        {
                            id: "TJ",
                            name: "Tajikistan"
                        },
                        {
                            id: "TL",
                            name: "East Timor"
                        },
                        {
                            id: "TM",
                            name: "Turkmenistan"
                        },
                        {
                            id: "TN",
                            name: "Tunisia"
                        },
                        {
                            id: "TR",
                            name: "Turkey"
                        },
                        {
                            id: "TT",
                            name: "Trinidad and Tobago"
                        },
                        {
                            id: "TW",
                            name: "Taiwan"
                        },
                        {
                            id: "TZ",
                            name: "United Republic of Tanzania"
                        },
                        {
                            id: "UA",
                            name: "Ukraine"
                        },
                        {
                            id: "UG",
                            name: "Uganda"
                        },
                        {
                            id: "UY",
                            name: "Uruguay"
                        },
                        {
                            id: "UZ",
                            name: "Uzbekistan"
                        },
                        {
                            id: "VC",
                            name: "Saint Vincent and the Grenadines"
                        },
                        {
                            id: "VE",
                            name: "Venezuela"
                        },
                        {
                            id: "VI",
                            name: "United States Virgin Islands"
                        },
                        {
                            id: "VN",
                            name: "Vietnam"
                        },
                        {
                            id: "VU",
                            name: "Vanuatu"
                        },
                        {
                            id: "WF",
                            name: "Wallis and Futuna"
                        },
                        {
                            id: "WO",
                            name: "World, Miller projection, high resolution"
                        },
                        {
                            id: "WS",
                            name: "Samoa"
                        },
                        {
                            id: "YE",
                            name: "Yemen"
                        },
                        {
                            id: "ZA",
                            name: "South Africa"
                        },
                        {
                            id: "ZM",
                            name: "Zambia"
                        },
                        {
                            id: "ZW",
                            name: "Zimbabwe"
                        }
                    ]
                }
            },
            {
                attribute: "field",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Field"
                }
            },
            {
                attribute: "value",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Value"
                }
            },
            {
                attribute: "joinBy",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Join By",
                    values: [
                        {
                            id: "postal-code",
                            name: "Postal Code"
                        },
                        {
                            id: "name",
                            name: "Name"
                        }
                    ]
                }
            },
            {
                attribute: "unit",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Unit",
                    allow_blank: true
                }
            },
            {
                attribute: "viewXOffset",
                options: {
                    label: "X Offset"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "viewYOffset",
                options: {
                    label: "Y Offset"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "viewZoom",
                options: {
                    label: "View Zoom"
                },
                type: Datavisualizations.EDITOR.STRING
            },
            {
                attribute: "minColor",
                options: {
                    label: "Min Color"
                },
                type: Datavisualizations.EDITOR.COLOR
            },
            {
                attribute: "maxColor",
                options: {
                    label: "Max Color"
                },
                type: Datavisualizations.EDITOR.COLOR
            },
            {
                attribute: "hoverColor",
                options: {
                    label: "Color on Hover"
                },
                type: Datavisualizations.EDITOR.COLOR
            },
            {
                attribute: "labelColor",
                options: {
                    label: "Label Color"
                },
                type: Datavisualizations.EDITOR.COLOR
            },
            {
                attribute: "timeField",
                options: {
                    label: "Time Field",
                    description:
                        "Choose a DateTime field that is used as the primary timestamp for dashboard-wide time controls. If left empty, time controls will use WAction creation time by default.",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            Datavisualizations.WEBACTION_EDITORS.POLLING_QUERY_SETTINGS,
            Datavisualizations.WEBACTION_EDITORS.DRILLDOWN
        ]
    }
};

Datavisualizations.ThreatMap = {
    default_dimensions: {
        width: 6,
        height: 4
    },
    typespec: {
        properties: [
            {
                attribute: "retentionStrategyType",
                type: String,
                value: "all"
            },
            {
                attribute: "title",
                type: String,
                value: null
            },
            {
                attribute: "map",
                type: String,
                value: "US"
            },
            {
                attribute: "originLocationName",
                type: String,
                value: null
            },
            {
                attribute: "originLatitude",
                type: String,
                value: null
            },
            {
                attribute: "originLongitude",
                type: String,
                value: null
            },
            {
                attribute: "destinationLocationName",
                type: String,
                value: null
            },
            {
                attribute: "destinationLatitude",
                type: String,
                value: null
            },
            {
                attribute: "destinationLongitude",
                type: String,
                value: null
            },
            {
                attribute: "value",
                type: String,
                value: null
            },
            {
                attribute: "mapColor",
                value: "#D3D3D3"
            },
            {
                attribute: "arcColor",
                value: "#DD1C77"
            },
            {
                attribute: "arcColorBy",
                value: null
            },
            {
                attribute: "showLegend",
                type: Boolean,
                value: false
            },
            {
                attribute: "conditionalColor",
                type: {
                    extends: Datavisualizations.WEBACTION_TYPES.CONDITIONAL_COLORS
                }
            }
        ]
    },
    editor: {
        propertyeditors: [
            {
                attribute: "retentionStrategyType",
                type: Datavisualizations.EDITOR.ENUM,
                options: {
                    label: "Data Retention Type",
                    values: [
                        {
                            id: "all",
                            name: "All"
                        },
                        {
                            id: "current",
                            name: "Current"
                        }
                    ]
                }
            },
            {
                attribute: "title",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Title",
                    allow_blank: true
                }
            },
            {
                attribute: "mapColor",
                options: {
                    label: "Map Color",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.COLOR
            },
            {
                attribute: "arcColor",
                options: {
                    label: "Arc Color",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.COLOR
            },
            {
                attribute: "arcColorBy",
                options: {
                    label: "Color Arc By",
                    allow_blank: true
                },
                type: Datavisualizations.EDITOR.FIELD
            },
            {
                attribute: "originLocationName",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Origin Location Name",
                    allow_blank: true
                }
            },
            {
                attribute: "originLatitude",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Origin Latitude"
                }
            },
            {
                attribute: "originLongitude",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Origin Longitude"
                }
            },
            {
                attribute: "destinationLocationName",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Destination Location Name",
                    allow_blank: true
                }
            },
            {
                attribute: "destinationLatitude",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Destination Latitude"
                }
            },
            {
                attribute: "destinationLongitude",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Destination Longitude"
                }
            },
            {
                attribute: "value",
                type: Datavisualizations.EDITOR.FIELD,
                options: {
                    label: "Value",
                    allow_blank: true
                }
            },
            Datavisualizations.WEBACTION_EDITORS.CONDITIONAL_COLORS,
            {
                attribute: "showLegend",
                options: {
                    label: "Show Legend"
                },
                type: Datavisualizations.EDITOR.BOOLEAN
            }
        ]
    }
};

Datavisualizations.TimeSlider = {
    default_dimensions: {
        width: 3,
        height: 1
    },

    typespec: {
        properties: [
            {
                attribute: "start_time",
                type: String,
                value: String(new Date().getTime() - 604800000)
            },
            {
                attribute: "end_time",
                type: String,
                value: new Date().getTime().toString()
            },
            {
                attribute: "zIndex",
                type: Number,
                value: "1"
            }
        ]
    },

    editor: {
        propertyeditors: [
            {
                attribute: "start_time",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "Start Time"
                }
            },
            {
                attribute: "end_time",
                type: Datavisualizations.EDITOR.STRING,
                options: {
                    label: "End Time"
                }
            }
        ]
    }
};

export default Datavisualizations;
