export const styles = {
    topContainer: {
        margin: 4,
        alignItems: "center",
        display: "flex",
        alignSelf: "stretch",
        borderRadius: "8px",
        border: "1px solid",
        borderColor: "greyscale.200",
        height: 740,
        overflow: "hidden"
    },
    welcomeMessageContainer: {
        alignItems: "flex-start",
        backgroundColor: "additional.white",
        alignContent: "center",
        alignSelf: "stretch",
        padding: 4
    },
    imageContainer: {
        backgroundColor: "primary.50",
        padding: "24px 40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "stretch"
    },
    container: {
        marginTop: 6,
        marginLeft: 0,
        padding: 0,
        alignItems: "center",
        justifyContent: "space-between"
    },
    appAdapterContainer: {
        width: "100%",
        marginTop: 21.25,
        padding: 0,
        alignItems: "center",
        justifyContent: "space-between"
    },
    appAdapterTopContainer: {
        paddingInline: 24,
        paddingBottom: 29.62,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    message: {
        marginBottom: 15
    },
    info: { width: "75%" },
    button: {
        marginTop: 2
    },
    image: {
        width: "100%",
        height: "100%",
        backgroundColor: "primary.50"
    },
    bottomContainer: {
        width: "100%",
        padding: 4,
        marginInline: 0
    },
    explore: {
        marginBottom: 3.75,
        paddingTop: 5
    },
    templateItem: {
        display: "flex",
        gap: 1,
        marginLeft: 4
    }
};
