import Backbone from "backbone";
import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import MetaObjectDataSource from "app/components/common/editor/control/select/metaobject-datasource";
import BaseEditor from "app/components/common/editor/meta-object-editors/base";

var Module = function(metadata_object, options) {
    options = options || {};
    options.displayFields = options.displayFields || [
        "typename",
        "lb3",
        "queryProperties",
        "lb1",
        "adapter",
        "lb2",
        "parser"
    ];

    BaseEditor.apply(this, arguments);
};

Module.prototype = _.extend({}, BaseEditor.prototype, {
    addCustomFields: function() {
        var _this = this;

        let metaObjectId = this.MetaObjectModel && this.MetaObjectModel.id;

        this.fieldMapper.adapter = UIControl.PropertyTemplate("source")
            .extend({
                subControl: true,
                hideLabel: true,
                metaObjectId: metaObjectId
            })
            .create();
        this.fieldMapper.adapter.on(UIControl.EVENT.VALUE_CHANGED, function(value) {
            var closestEditor = _this.fieldMapper.parser.$el.closest(".field-row");
            if (value.handler && this.requiresParser) {
                _this.fieldMapper.parser.hidden = false;
                closestEditor.show(value.handler);
                _this.Form.toggleControlVisibility(_this.options.StaticControls.lb2, true);
            } else {
                _this.fieldMapper.parser.hidden = true;
                _this.MetaObjectModel.unset("parser");
                closestEditor.hide(value.handler);
                _this.Form.toggleControlVisibility(_this.options.StaticControls.lb2, false);
            }
        });

        this.fieldMapper.parser = UIControl.PropertyTemplate("parser")
            .extend({
                subControl: true,
                hidden: true,
                hideLabel: true,
                metaObjectId: metaObjectId
            })
            .create();
        this.fieldMapper.queryProperties = UIControl.CacheQueryProperties.extend({
            subControl: true
        }).create();

        this.fieldMapper.typename = UIControl.Type.extend({
            hideLabel: true,
            collapsed: true,
            keyRequired: true
        }).create();

        this.fieldMapper.typename.on(
            "type-saved",
            function() {
                this.Form.view.clearDirty(this.fieldMapper.typename.model);
            }.bind(this)
        );

        this.listenTo(this.fieldMapper.typename.model, "change:value", function(viewModel) {
            _this.fieldMapper.queryProperties.trigger("change:type:projections", viewModel.value);
        });

        this.options.StaticControls.lb2 = new UIControl.LineBreak().create();
        this.options.StaticControls.lb3 = new UIControl.LineBreak().create();
    },

    postRender: function() {
        var _this = this;

        this.fieldMapper.typename.setValue(this.MetaObjectModel.typename);
        this.fieldMapper.typename.on("modified", function() {
            _this.fieldMapper.queryProperties.trigger(
                "change:type:projections",
                _this.fieldMapper.typename.model.value
            );
        });
    }
});

export default Module;
