import App from "app";

App.module("Metadata.Themes", function (Themes) {
    var dark_theme_hues = [
        "#448AFF",
        "#40C4FF",
        "#84FFFF",
        "#4DB6AC",
        "#69F0AE",
        "#64DD17",
        "#EEFF41",
        "#FBC02D",
        "#F57C00",
        "#FF6E40",
        "#8D6E63",
        "#F44336",
        "#FF80AB",
        "#D500F9",
        "#7E57C2",
    ];
    var light_theme_hues = [
        "#448AFF",
        "#01579B",
        "#00B8D4",
        "#4DB6AC",
        "#00C853",
        "#558B2F",
        "#9E9D24",
        "#FBC02D",
        "#FF6E40",
        "#8D6E63",
        "#F44336",
        "#FF80AB",
        "#D500F9",
        "#7E57C2",
    ];

    Themes.WebActionDark = {
        _themename: "WebActionDark",
        colors: {
            text: ["#EEF1F2", "#c5cfd3", "#819197", "#425358"],
            background: ["#293439", "#222c30", "#1d2326"],
            hues: dark_theme_hues,
        },
        vectormap: {
            background: "#001B2D",
        },
        legend: {
            item: {
                color: "#ccc",
            },
            item_hover: {
                color: "#fff",
            },
            item_hidden: {
                color: "#555",
            },
        },
        chart: {
            background: "transparent",
            line: {
                color: "#425257",
            },
            grid: {
                color: "#425257",
            },
        },
        axis: {
            title: {
                color: "#808A97",
            },
            labels: {
                color: "#c5cfd3",
            },
        },
    };

    Themes.WebActionLight = {
        _themename: "light",
        colors: {
            text: ["#293439"],
            background: ["#fff"],
            hues: light_theme_hues,
        },
        vectormap: {
            background: "#FDFDFD",
        },
        legend: {
            item: {
                color: "#666",
            },
            item_hover: {
                color: "#444",
            },
            item_hidden: {
                color: "#777",
            },
        },
        chart: {
            background: "transparent",
            line: {
                color: "#ddd",
            },
            grid: {
                color: "#ddd",
            },
        },
        axis: {
            title: {
                color: "#293439",
            },
            labels: {
                color: "#293439",
            },
        },
    };
});
