import React, { useState, Dispatch, SetStateAction } from "react";
import { Grid, SvgIcon } from "@mui/material";
import {
    StriimButton,
    StriimTypography,
    StriimDropdown,
    StriimMenuList,
    StriimMenuItem
} from "@striim/striim-ui";

import { styles } from "./selected-source-apps.styles";
import { SpecialChevronDown } from "../../../../../generic/icon/customIcons";

interface SelectedSourceAppsHeadingProps {
    name: string;
    subtitle: string;
    options: string[];
    onItemClick: Dispatch<SetStateAction<string>>;
}

const SelectedSourceAppsHeading = ({
    name,
    options = [],
    onItemClick,
    subtitle
}: SelectedSourceAppsHeadingProps) => {
    const sourcesListOpened = useState<boolean>(false);

    return (
        <Grid sx={styles.simpleHeading}>
            <Grid display="flex" alignItems="center">
                <StriimTypography
                    variant="h1"
                    sx={{
                        ...styles.heading,
                        maxWidth: "500px"
                    }}
                >
                    {name}
                </StriimTypography>
                <StriimDropdown
                    extendOnClick
                    controlState={sourcesListOpened}
                    placement="bottom-end"
                    content={
                        <StriimMenuList>
                            {options.map(item => {
                                return (
                                    <StriimMenuItem
                                        key={item}
                                        data-test-id={`sources-list-${item}`}
                                        onClick={() => onItemClick(item)}
                                    >
                                        {item}
                                    </StriimMenuItem>
                                );
                            })}
                        </StriimMenuList>
                    }
                >
                    <StriimButton
                        variant="text"
                        startIcon={
                            <SvgIcon component={SpecialChevronDown} sx={styles.specialChevronDown} />
                        }
                        sx={styles.openIcon}
                    />
                </StriimDropdown>
            </Grid>
            <StriimTypography variant="caption4" color="greyscale.700">
                {subtitle}
            </StriimTypography>
        </Grid>
    )
};

export default SelectedSourceAppsHeading;
