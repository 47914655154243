import _ from "lodash";

type CallbackFunction = (app: any) => boolean;

class FilterTypeBase {
    type: string;
    label: string;
    callbackFunction: CallbackFunction;

    constructor(type: string, label: string, callbackFunction: CallbackFunction) {
        this.type = type;
        this.label = label;
        this.callbackFunction = callbackFunction;
    }

    toString() {
        return this.type;
    }

    static FilterCategory = {
        UserInput: "UserInput"
    };

    static UserInput = input =>
        new FilterType(this.FilterCategory.UserInput, "Search", appOrJob => {
            return (
                !_.isNil(input) &&
                !_.isEmpty(input) &&
                _.join([appOrJob.name], " ")
                    .toLowerCase()
                    .includes(input.toLowerCase())
            );
        });
}

export class FilterType extends FilterTypeBase {}
