import api from "../../../../core/api/api";
import metaStoreService from "../../../../core/services/metaStoreService/meta-store-service";
import { SentinelObject } from "./sentinel.types";
import securityService from "../../../../core/services/securityService/securityService";
import { SENTINEL_ACTIONS } from "./panel/sentinel-panel-consts";

const fakeSDIOptions = {
    EMAIL: { importance: "LOW", action: "DO_NOTHING" },
    SSN: { importance: "MEDIUM", action: "ENCRYPT" },
    NAME: { importance: "HIGH", action: "DO_NOTHING" },
    UNKNOWN: { importance: "LOW", action: "DO_NOTHING" },
    CREDIT_CARD_NUMBER: { importance: "MEDIUM", action: "DO_NOTHING" },
    AGE: { importance: "HIGH", action: "DO_NOTHING" },
    EMAIL_ADDRESS: { importance: "LOW", action: "DO_NOTHING" },
    ADDRESS: { importance: "MEDIUM", action: "MASK_COMPLETELY" },
    TELEPHONE_NUMBER: { importance: "HIGH", action: "DO_NOTHING" }
};

const getFakeLabelMaps = async () => {
    return Promise.resolve(
        JSON.stringify({
            sensitiveDataIdentifiers: {
                CREDIT_CARD_NUMBER: "CREDIT CARD NUMBER",
                EMAIL_ADDRESS: "EMAIL ADDRESS",
                ADDRESS: "ADDRESS",
                TELEPHONE_NUMBER: "TELEPHONE NUMBER",
                EMAIL: "EMAIL",
                AGE: "AGE",
                NAME: "NAME",
                SSN: "SOCIAL SECURITY NUMBER"
            },
            piiDefaultActions: "",
            piiActions: {
                ENCRYPT: "Encrypt",
                MASK_COMPLETELY: "Mask (Complete)",
                MASK_PARTIALLY: "Mask (Partial)",
                CUSTOM_MASK: "Mask (Custom)",
                NO_ACTION: "No Action"
            },
            sdiConfig: fakeSDIOptions
        })
    );
};
interface SentinelService {
    createUpdateSentinel: (
        id: string,
        appName: string,
        name: string,
        nsName: string,
        properties: object,
        inputStream: string,
        outputStream: string | number
    ) => Promise<SentinelObject[]>;
    getPIISettings: Function;
    convertPIIActionsToTableData: Function;
    convertEntityActionsToTableData: Function;
    reverseTableData: Function;
    reverseSettingsTableData: Function;
    getLabelMaps: () => Promise<any>;
    getPartialMaskingSDIOptions: () => Promise<any>;
    mapPartialMaskingSDIToString: (
        partialMaskingSDIOptions: string[] | undefined,
        identifiersMap: Record<string, string> | undefined
    ) => string[];
    formatActionOnEntitiesData: Function;
    parseEntitiesData: Function;
    hasRowOrEntityDataChanged: Function;
    isEntityDataValid: Function;
    getShowFeaturePopover: Function;
    closeFeaturePopover: Function;
    transformEntities: Function;
}

const sentinelService: SentinelService = {
    getShowFeaturePopover: async () => {
        if (window?.useFakeManagementService) {
            return true;
        }
        const user = await securityService.getUser;
        return Boolean(localStorage.getItem("showFeaturePopoverSentinelSidepanel" + user.name));
    },
    closeFeaturePopover: async () => {
        if (window?.useFakeManagementService) {
            return;
        }
        const user = await securityService.getUser;
        try {
            localStorage.setItem("showFeaturePopoverSentinelSidepanel" + user.name, "closed");
        } catch (e) {
            console.error(e);
        }
    },
    createUpdateSentinel: async (id, appName, name, nsName, properties, inputStream, outputStream) => {
        return api._CRUDHandler(appName, !id ? "CREATE" : "UPDATE", {
            name,
            nsName,
            type: metaStoreService.entities.SENTINEL,
            properties: properties,
            inputStream,
            outputStream
        });
    },
    getPIISettings: async () => {
        if (window?.useFakeManagementService) {
            return JSON.stringify({
                aiEngine: "some",
                piiActions: {
                    CREDIT_CARD_NUMBER: SENTINEL_ACTIONS.MASK_COMPLETELY,
                    EMAIL_ADDRESS: SENTINEL_ACTIONS.MASK_COMPLETELY,
                    ADDRESS: SENTINEL_ACTIONS.NO_ACTION,
                    TELEPHONE_NUMBER: SENTINEL_ACTIONS.NO_ACTION,
                    EMAIL: SENTINEL_ACTIONS.NO_ACTION,
                    AGE: SENTINEL_ACTIONS.MASK_COMPLETELY,
                    NAME: SENTINEL_ACTIONS.NO_ACTION,
                    SSN: SENTINEL_ACTIONS.NO_ACTION
                },
                sdiConfig: fakeSDIOptions
            });
        }
        return api.getPIISettings();
    },

    convertPIIActionsToTableData: (identifiersMap, actionsMap, sdiConfig, importanceIconsMap, importanceLevels) => {
        return Object.entries(sdiConfig).map(([key, value]: [string, any]) => {
            const { action, props } = value?.actionConfig || {};
            const piiImportance = value?.importance;
            const importance = importanceIconsMap ? {
                label: [importanceLevels[piiImportance]],
                value: piiImportance,
                icon: importanceIconsMap[piiImportance.toLowerCase()]
            } : sdiConfig[key].importance.toUpperCase()

            if (action === SENTINEL_ACTIONS.MASK_M_N) {
                return {
                    name: identifiersMap[key] || key,
                    action: {
                        label: "Custom Mask",
                        value: SENTINEL_ACTIONS.CUSTOM_MASK
                    },
                    maskingString: `Show the first ${value.m || props.m} and the last ${value.n || props.n} characters`,
                    maskingType: SENTINEL_ACTIONS.MASK_M_N,
                    m: value?.m || props?.m,
                    n: value?.n || props?.n,
                    importance: importance
                };
            } else if (action === SENTINEL_ACTIONS.MASK_BY_REGEX) {
                return {
                    name: identifiersMap[key] || key,
                    action: {
                        label: "Custom Mask",
                        value: SENTINEL_ACTIONS.CUSTOM_MASK
                    },
                    maskingString: value.regex || props.regex || "",
                    maskingType: SENTINEL_ACTIONS.MASK_BY_REGEX,
                    importance: importance
                };
            } else {
                return {
                    name: identifiersMap[key] || key,
                    action: { label: actionsMap[action], value: action },
                    importance: importance
                };
            }
        });
    },

    convertEntityActionsToTableData: (entitiesData: string, actionsMap: Record<string, string>) => {
        try {
            const parsedData = JSON.parse(entitiesData);
            const result = [];

            Object.entries(parsedData).forEach(([entityName, fields], index) => {
                const entityFields = [];

                for (const [fieldName, config] of Object.entries(fields as any)) {
                    const { action, m, n, regex } = config as any;

                    let fieldData: any = {
                        name: fieldName
                    };

                    if (action === SENTINEL_ACTIONS.MASK_M_N) {
                        fieldData = {
                            ...fieldData,
                            action: {
                                label: "Custom Mask",
                                value: SENTINEL_ACTIONS.MASK_M_N
                            },
                            maskingString: `Show the first ${m} and the last ${n} characters`,
                            maskingType: SENTINEL_ACTIONS.MASK_M_N,
                            m,
                            n
                        };
                    } else if (action === SENTINEL_ACTIONS.MASK_BY_REGEX) {
                        fieldData = {
                            ...fieldData,
                            action: {
                                label: "Custom Mask",
                                value: SENTINEL_ACTIONS.MASK_BY_REGEX
                            },
                            maskingString: regex || "",
                            maskingType: SENTINEL_ACTIONS.MASK_BY_REGEX
                        };
                    } else {
                        fieldData.action = {
                            label: actionsMap[action],
                            value: action
                        };
                    }

                    entityFields.push(fieldData);
                }

                result.push({
                    name: entityName,
                    fields: entityFields,
                    id: `entity-${index}`
                });
            });

            return result;
        } catch (error) {
            console.error('Error parsing entities data:', error);
            return [];
        }
    },

    reverseTableData: (array, identifiersMap) => {
        const reverseIdentifiersMap = Object.fromEntries(
            Object.entries(identifiersMap).map(([key, value]) => [value, key])
        );
        const result = {};
        array.forEach(item => {
            const internalKey = reverseIdentifiersMap[item.name];
            if (internalKey) {
                if (item.maskingType == SENTINEL_ACTIONS.MASK_BY_REGEX) {
                    result[internalKey] = { action: SENTINEL_ACTIONS.MASK_BY_REGEX, regex: item.maskingString || "" };
                } else if (item.maskingType == SENTINEL_ACTIONS.MASK_M_N) {
                    result[internalKey] = { action: SENTINEL_ACTIONS.MASK_M_N, m: item.m || "", n: item.n || "" };
                } else {
                    result[internalKey] = { action: item.action.value };
                }
                result[internalKey].importance = item?.importance?.value;
            }
        });
        return result;
    },

    reverseSettingsTableData: (array, identifiersMap) => {
        const reverseIdentifiersMap = Object.fromEntries(
            Object.entries(identifiersMap).map(([key, value]) => [value, key])
        );
        const result = {};
        array.forEach(item => {
            const internalKey = reverseIdentifiersMap[item.name];
            if (internalKey) {
                if (item.maskingType === SENTINEL_ACTIONS.MASK_BY_REGEX) {
                    result[internalKey] = {
                        actionConfig: {
                            action: SENTINEL_ACTIONS.MASK_BY_REGEX,
                            regex: item.maskingString || ""
                        },
                        importance: item.importance.value
                    };
                } else if (item.maskingType === SENTINEL_ACTIONS.MASK_M_N) {
                    result[internalKey] = {
                        actionConfig: {
                            action: SENTINEL_ACTIONS.MASK_M_N,
                            m: item.m || "",
                            n: item.n || ""
                        },
                        importance: item.importance.value
                    };
                } else {
                    result[internalKey] = {
                        actionConfig: {
                            action: item.action.value
                        },
                        importance: item.importance.value
                    };
                }
            }
        });
        return result;
    },

    getLabelMaps: async () => {
        if (window?.useFakeManagementService) {
            return getFakeLabelMaps();
        }

        const sentinelOptions = await api.listSentinelOptions();
        const parsedSentinelOptions = JSON.parse(sentinelOptions);
        const piiSettings = await api.getPIISettings();
        const parsedPiiSettings = JSON.parse(piiSettings);

        return {
            ...parsedSentinelOptions,
            sdiConfig: parsedPiiSettings?.sdiConfig
        };
    },

    formatActionOnEntitiesData: (
        entitiesData: Array<{
            name: string;
            fields: Array<{
                name: string;
                action: { label: string; value: string };
                maskingString?: string;
                maskingType?: string;
                m?: string;
                n?: string;
            }>;
            id?: string;
        }>
    ) => (
        entitiesData.reduce((acc, entity) => {
            const currentEntity: Record<string, any> = {};
            entity.fields.forEach(field => {
                let actionValue = field.action?.value || SENTINEL_ACTIONS.NO_ACTION;

                if (field.maskingType === SENTINEL_ACTIONS.MASK_M_N) {
                    currentEntity[field.name] = {
                        action: SENTINEL_ACTIONS.MASK_M_N,
                        m: field.m ?? "",
                        n: field.n ?? ""
                    };
                } else if (field.maskingType === SENTINEL_ACTIONS.MASK_BY_REGEX) {
                    currentEntity[field.name] = {
                        action: SENTINEL_ACTIONS.MASK_BY_REGEX,
                        regex: field.maskingString ?? ""
                    };
                } else {
                    currentEntity[field.name] = { action: actionValue };
                }
            });
            acc[entity.name] = currentEntity;
            return acc;
        }, {} as Record<string, Record<string, any>>)
    ),

    getPartialMaskingSDIOptions: async () => {
        return api.getListOfSDIApplicableToPartialMasking();
    },

    mapPartialMaskingSDIToString: (
        partialMaskingSDIOptions: string[] | undefined,
        identifiersMap: Record<string, string> | undefined
    ): string[] => {
        if (!partialMaskingSDIOptions || !identifiersMap) {
            return [];
        }
        return partialMaskingSDIOptions
            .map(option => identifiersMap[option])
            .filter((mapped): mapped is string => mapped !== undefined);
    },

    parseEntitiesData: (data, actionsMap) => {
        let parsedEntitiesData = [];
        try {
            const parsedData = JSON.parse(data);
            Object.entries(parsedData).forEach(entity => {
                const entityName = entity[0];
                const entityData = entity[1];
                let entityFields = Object.keys(entityData);
                let fields = entityFields.map(field => ({
                    name: field,
                    action: {
                        label: actionsMap[entityData[field].action],
                        value: entityData[field].action
                    }
                }));
                parsedEntitiesData.push({ name: entityName, fields });
            });
        } catch (error) {
            console.error('Error parsing entities data:', error);
        }
        return parsedEntitiesData;
    },

    hasRowOrEntityDataChanged: (rowData, entityData, initialChanges) => {
        const rowDataChanges = [];
        const entitiesDataChanges = [];

        rowData.forEach(el => {
            const initEl = initialChanges.rowData.find(iEl => iEl.name === el.name);

            if (!initEl) {
                rowDataChanges.push(true);
            } else {
                if (initEl.action.value !== el.action.value) {
                    rowDataChanges.push(true);
                }
            }
        });

        const rowDataChanged = rowDataChanges.some(el => el === true);

        entityData.forEach(el => {
            const initEl = initialChanges.entitiesData.find(iEl => iEl.name === el.name);

            if (!initEl) {
                entitiesDataChanges.push(true);
            } else {
                if (initEl.name !== el.name) {
                    entitiesDataChanges.push(true);
                }

                if (el?.fields?.length !== initEl?.fields?.length) {
                    entitiesDataChanges.push(true);
                }

                el.fields.forEach(fieldEl => {
                    const initFieldEl = initEl.fields.find(e => e.name === fieldEl.name);

                    if (!initFieldEl) {
                        entitiesDataChanges.push(true);
                    } else {
                        if (initFieldEl.action.value !== fieldEl.action.value) {
                            entitiesDataChanges.push(true);
                        }
                    }
                });
            }
        });

        const entityChanged = entitiesDataChanges.some(el => el === true);
        return [entityChanged, rowDataChanged];
    },

    isEntityDataValid: entityData => {
        let hasError = false;
        entityData.forEach(el => {
            if (!el.name) {
                hasError = true;
                return;
            }
            hasError = el.fields.some(fieldEl => {
                return fieldEl.actionError || fieldEl.nameError;
            });
            if (hasError) return;
        });
        return !hasError;
    },

    transformEntities: entities => {
        return entities.map((entity, entityIndex) => {
            return {
                name: entity.name,
                fields: entity.columns.map(column => {
                    return {
                        name: column.name,
                        action: {
                            label: "NO ACTION",
                            value: SENTINEL_ACTIONS.NO_ACTION
                        }
                    };
                }),
                id: `entity-${entityIndex}`
            };
        });
    }
};

export default sentinelService;
