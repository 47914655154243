import _ from "underscore";
import api from "core/api/api";
import Backbone from "backbone";
import NestedTypes from "backbone.nestedtypes";
import App from "app";
import ModalManager from "app/components/common/modal/ModalManager";
import Modal from "app/components/common/modal/modal";
import dialog from "app/components/common/dialogs/dialogWindow";
import UIControl from "app/components/common/editor/control/ui-control";
import { loadDGsForAgents, getAgentForDeploymentGroup } from "app/components/flow/designer/agents-loader";
import template from "./connection-test-template.html";
import driversInfoTemplate from "./drivers-installation-template.html";
import missingDriversMessages from "./missing-drivers-messages";
import { connectionStatuses } from "app/components/common/editor/control/connection-text-field/last-connection-test-repository";
import NProgress from "nprogress";

NProgress.configure({
    minimum: 0.25,
    trickleRate: 0.1,
    trickleSpeed: 800
});

/**
 * To verify UI connection test scenarios set '__fakeconnectiontest' window object to 'true'
 * Then __fakeconnectiontestresult:
 * - ok - connection success
 * - user - cannot connect, incorrect user
 * - drivers - cannot connect drivers
 *
 * __fakeconnectiontesttimeout - delay when testing connection
 */
function fakeConnectionTest() {
    return new Promise(resolve => {
        setTimeout(() => {
            if (window.__fakeconnectiontestresult === "user") {
                resolve({
                    valid: false,
                    message: "Cannot connect to the databse. Wrong user name or password.",
                    status: connectionStatuses.connectionFailed
                });
            } else {
                resolve({
                    valid: true,
                    message: null,
                    status: connectionStatuses.ok
                });
            }
        }, window.__fakeconnectiontesttimeout || 2000);
    });
}

/**
 * To verify UI connection test scenarios set '__fakeconnectiontest' window object to 'true'
 * Then __fakeconnectiontestresult:
 * - ok - connection success
 * - user - cannot connect, incorrect user
 * - drivers - cannot connect drivers
 */
function fakeDriversTest() {
    return new Promise(resolve => {
        if (window.__fakeconnectiontestresult === "drivers") {
            resolve(false);
        } else {
            resolve(true);
        }
    });
}

function isNoSQLAdapter(adapter){
    return ["MongoCosmosDBReader","MongoDBReader","CosmosDBWriter","MongoDBWriter","CosmosDBReader","MongoCosmosDBWriter"].includes(adapter)

}

function getDatabaseType(adapterName, databaseProviderType) {
    // for targets, the adapter is always set to DatabaseWriter
    // we need to check in databaseProviderType
    if (databaseProviderType) {
        databaseProviderType = databaseProviderType.toLowerCase();
        if (
            databaseProviderType === "mysql" ||
            databaseProviderType === "oracle" ||
            databaseProviderType === "sqlserver"
        ) {
            return databaseProviderType;
        }
    }

    switch (adapterName) {
    	case "OJet":
    		return "ojet";
        case "MysqlReader":
            return "mysql";
        case "OracleReader":
            return "oracle";
        case "MSSqlReader":
            return "mssql";
        case "MSJet":
            return "msjet"; // return "msjet";
    }
    return "";
}

let ValidationModel = NestedTypes.Model.extend({
    defaults: {
        connectionUrl: "",
        user: "",
        password: "",
        passwordEncrypted: false,
        adapterName: "",
        databaseProviderType: "",
        metaObjectId: null,

        // selected agent deployment group
        agentDeploymentGroup: "",

        // list of agent deployment groups
        agentDeploymentGroups: [],

        // true when testing in progress [readonly]
        testing: false,

        // last connection test result
        connectionTestResult: false,

        // last connection test message
        connectionTestResultMsg: "",

        // last validation status
        status: Number
    },
    properties: {
        databaseType: function() {
            return getDatabaseType(this.adapterName, this.databaseProviderType);
        }
    },

    /**
     * @function _testDriversInstalled
     * tests if drivers are installed
     * @private
     */
    _testDriversInstalled: function(databaseType) {
        function resolveDriversMissingText() {
            switch (databaseType) {
                case "oracle":
                    return missingDriversMessages.Oracle;
            }
            return missingDriversMessages.General;
        }

        if (window.__fakeconnectiontest === true) {
            return fakeDriversTest().then(result => {
                if (!result) {
                    return resolveDriversMissingText();
                }
                return true;
            });
        }

        return api.checkDrivers(databaseType).then(result => {
            if (!result) {
                return resolveDriversMissingText();
            }
            return true;
        });
    },

    testConnection: function() {
        this.testing = true;
        const databaseType = this.databaseType;
        if (!databaseType) {
            this.testing = false;
            console.warn("Validation method not defined for: " + this.model.propertyTemplateType);
            return;
        }

        return new Promise(
            function(resolve) {
                let _this = this;

                function resolveState(result, message, status) {
                    _this.connectionTestResult = result;
                    _this.connectionTestResultMsg = message;
                    _this.testing = false;
                    _this.status = status;
                    resolve();
                }

                // check if drivers are installed
                this._testDriversInstalled(databaseType).then(driversResultMessage => {
                    if (driversResultMessage !== true) {
                        _this.status = connectionStatuses.driversNotFound;
                        resolveState(false, driversResultMessage, connectionStatuses.driversNotFound);
                        return;
                    }

                    if (window.__fakeconnectiontest === true) {
                        return fakeConnectionTest().then(result => {
                            resolveState(result.valid, result.message, result.status);
                        });
                    }

                    // check database connection
                    let apidef = api.validateConnection(
                        databaseType,
                        this.user,
                        this.password,
                        this.connectionUrl,
                        this.agentDeploymentGroup,
                        this.passwordEncrypted,
                        this.metaObjectId
                    );

                    apidef.then(function(data) {
                        try {
                            data = JSON.parse(data);
                            if (data.result === true) {
                                resolveState(true, "Connected successfully", connectionStatuses.ok);
                            } else {
                                resolveState(false, data.message, connectionStatuses.connectionFailed);
                            }
                        } catch (error) {
                            console.error(error);
                            resolveState(false, error.message, connectionStatuses.connectionFailed);
                        }
                    });
                    apidef.fail(function(e) {
                        resolveState(false, e.message, connectionStatuses.connectionFailed);
                    });
                });
            }.bind(this)
        );
    }
});

/**
 * Dialog content view
 */
let ContentView = Backbone.Marionette.LayoutView.extend({
    ui: {
        testResult: ".test-result",
        agentRow: "#sourceonagent",
        agentDeploymentGroupRow: "#agentdeploymentgroup"
    },

    regions: {
        agentSwitchRegion: "#sourceonagent .control",
        agentDeploymentGroupRegion: "#agentdeploymentgroup .control"
    },

    // deploy on agent radiobutton
    agentSwitch: null,

    // agent deployment group
    agentDeploymentGroup: null,

    template: _.template("<div>" + template + driversInfoTemplate + "</div>"),

    initialize: function() {
        this.listenTo(
            this.model,
            "change:connectionTestResult",
            function() {
                this.ui.testResult.toggleClass("error", !this.model.connectionTestResult);
            }.bind(this)
        );

        this.listenTo(
            this.model,
            "change:connectionTestResultMsg",
            function() {
                this.ui.testResult.html(this.model.connectionTestResultMsg);
            }.bind(this)
        );
    },

    onRender: function() {
        this._initializeAgent();
    },

    _initializeAgent: function() {
        let agentDeploymentGroups = this.model.agentDeploymentGroups;
        if (agentDeploymentGroups.length > 0) {
            if (agentDeploymentGroups.length === 1) {
                this.agentSwitch = UIControl.Toggle.create();
                this.agentSwitchRegion.show(this.agentSwitch);
                this.ui.agentRow.removeClass("hidden-element");

                let agentDeploymentGroup = agentDeploymentGroups[0].get("id");

                this.listenTo(
                    this.agentSwitch.model,
                    "change:value",
                    function(model, value) {
                        this.model.agentDeploymentGroup = value ? getAgentForDeploymentGroup(agentDeploymentGroup) : "";
                    }.bind(this)
                );
            } else {
                let selectOptions = [];
                _.each(agentDeploymentGroups, function(agentDeploymentGroup) {
                    selectOptions.push({
                        id: agentDeploymentGroup.get("id"),
                        text: agentDeploymentGroup.get("name"),
                        description: "Deployment Group"
                    });
                });
                this.agentDeploymentGroup = UIControl.Select(selectOptions, { dropdownAutoWidth: true }).create();
                this.listenTo(
                    this.agentDeploymentGroup.model,
                    "change:value",
                    function(selected) {
                        this.model.agentDeploymentGroup = getAgentForDeploymentGroup(selected.value);
                    }.bind(this)
                );
                this.agentDeploymentGroupRegion.show(this.agentDeploymentGroup);
                this.ui.agentDeploymentGroupRow.removeClass("hidden-element");
            }
        }
    }
});

const dialogClassName = "connection-test-dialog";

/**
 * Dialog mixin
 */
let ConnectionTestMixin = App.FormMixinDialog.View.extend({
    initialize: function(options) {
        options.contentview = new ContentView({ model: options.dialogModel });
        options.bind_submit = true;
        options.modalSettings = new Modal.Model({
            animation: {
                hide: "morph"
            },
            classes: dialogClassName
        });
        App.FormMixinDialog.View.prototype.initialize.apply(this, arguments);
    }
});

/**
 * Show confirmation dialog
 * @returns {Promise<any>}
 */
function showDialog(model) {
    let dialog = new ConnectionTestMixin({
        model: new Backbone.Model({
            title: "Test connection",
            showSubmitButton: true,
            cancel_text: "Close",
            submit_text: "Test"
        }),
        dialogModel: model
    });

    let modalManager = new ModalManager({
        container: "body"
    });
    modalManager.add(dialog);
    new ConnectionTestMixin.Controller({
        view: dialog
    });

    dialog.on("cancel", () => {
        dialog.destroy();
    });

    dialog.on("submit", dlg => {
        if (model.testing) {
            return;
        }

        dlg.view.ui.submitButton.addClass("disabled", true);
        dialog.trigger("progress:start");
        model.testConnection().then(() => {
            if (dialog.is_open) {
                dialog.trigger("progress:end");
                dlg.view.ui.submitButton.removeClass("disabled");
            }
        });
    });

    return new Promise(resolve => {
        dialog.on("before:destroy", () => {
            modalManager.remove_all_modals();
            resolve();
        });
    });
}

ConnectionTestMixin.Controller = App.FormMixinDialog.Controller;

function createModel(form, metaObjectId, resourceNamespace) {
    function getAdapterName() {
        let adapterName = caseInsensitiveModel.adaptername;
        if (adapterName) {
            return adapterName;
        }

        // for new components the adaptername is not provided
        // we parse this from resourceNamespace which contains adapter name
        if (!resourceNamespace) {
            return null;
        }
        let nameParts = resourceNamespace.split(".");
        if (nameParts.length !== 3) {
            return null;
        }
        return nameParts[2];
    }
    function getPassword() {
        let password = caseInsensitiveModel.password;
        if (password && password.encrypted) {
            return password.encrypted;
        }
        return password;
    }
    let caseInsensitiveModel = {};
    Object.keys(form).forEach(propertyName => {
        caseInsensitiveModel[propertyName.toLowerCase()] = form[propertyName];
    });

    let agentDeploymentGroups = loadDGsForAgents();
    let model = new ValidationModel();
    model.agentDeploymentGroups = agentDeploymentGroups;
    model.connectionUrl = caseInsensitiveModel.connectionurl;
    model.user = caseInsensitiveModel.username;
    model.password = getPassword();
    model.passwordEncrypted = !!caseInsensitiveModel.password_encrypted;
    model.databaseProviderType = caseInsensitiveModel.databaseProviderType;
    model.adapterName = getAdapterName();
    if (metaObjectId) {
        model.metaObjectId = metaObjectId;
    }
    return model;
}

function showProgressBar() {
    NProgress.configure({
        parent: ".right-sidebar",
        minimum: 0.25,
        trickleRate: 0.1,
        trickleSpeed: 800
    });
    NProgress.start();
}

function hideProgressBar() {
    NProgress.done();
}

function dbConnectionTest(form, showErrorDialog, metaObjectId, resourceNamespace) {
    console.log("Called with args: ", arguments);

    return new Promise(resolve => {
        const model = createModel(form, metaObjectId, resourceNamespace);
        if (!model || !model.adapterName) {
            console.warn("can't resolve adapter name");
            resolve();
            return;
        }

        if (model.agentDeploymentGroups.length === 0) {
            // there is no need to display the dialog, just start testing
            showProgressBar();
            model.testConnection().then(() => {
                hideProgressBar();
                if (model.connectionTestResult) {
                    resolve(true);
                    return;
                }

                if (showErrorDialog === false) {
                    resolve({ status: model.status, message: model.connectionTestResultMsg });
                    return;
                }

                dialog
                    .alert(
                        '<div><div class="test-result error with-padding">' + model.connectionTestResultMsg,
                        undefined,
                        dialogClassName,
                        "Error"
                    )
                    .then(() => resolve(false));
            });
            return;
        }

        showDialog(model).then(() => {
            resolve(model.connectionTestResult);
        });
    });
}

export { getDatabaseType, isNoSQLAdapter };
export default dbConnectionTest;
