import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { StriimTypography } from "@striim/striim-ui-v2";
import { Box, Collapse, SvgIcon } from "@mui/material";
import { ExpandMoreRounded, ExpandLessRounded } from "@mui/icons-material";
import { styles } from "./collapsible-heading.styles";
import { Documentation } from "../../../../../generic/icon/customIcons";

interface CollapsibleHeading {
    title: string;
    children: React.ReactNode;
    defaultOpen?: boolean;
    url: string;
    externalTrigger?: boolean;
}

const CollapsibleHeading = ({ title, children, defaultOpen = true, url, externalTrigger }: CollapsibleHeading) => {
    const [open, setOpen] = useState(defaultOpen);

    useEffect(() => {
        externalTrigger === open && handleChange();
    }, [externalTrigger]);

    const handleChange = () => {
        setOpen(prev => !prev);
    };

    return (
        <Box>
            <StriimTypography variant="h5" onClick={handleChange} sx={styles.title}>
                {open ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                {title}
                <SvgIcon sx={styles.documentationIcon} component={"a"} href={url} target="_blank">
                    <Documentation />
                </SvgIcon>
            </StriimTypography>
            <Collapse in={open}>
                <div style={styles.content}>
                    <StriimTypography variant="caption3" color="greyscale.700" maxWidth={600} sx={styles.text}>
                        {children}
                    </StriimTypography>
                </div>
            </Collapse>
        </Box>
    );
};

export default CollapsibleHeading;
