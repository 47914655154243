import React, { useState } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import useStores from "src/utils/use-stores";
import AppGroupEdit from "../app-group-edit/app-group-edit";
import AppGroupDropdown from "./app-group-dropdown";
import growl from "app/components/common/growl";
import { formatGroupError } from "../../../../../../utils/error-formatter";
import { Box, SvgIcon } from "@mui/material";
import AppControl from "app/components/common/app-control/app-control";
import { StriimTypography, StriimTooltip } from "@striim/striim-ui";
import FolderOutlined from "../../images/app-folder-outlined.svg";
import AutomatedWizardOutlined from "../../images/app-folder-cog-outlined.svg";
import FolderFilled from "../../images/app-folder-filled.svg";
import AutomatedWizardFilled from "../../images/app-folder-cog-filled.svg";
import { styles } from "./app-group.styles";
import DeleteGroupModal from "./delete-group-modal";
import { deleteAutomatedGroup } from "../automated-app-group-detail/services";
import statusManagement from "../../../../../../status-management";

const AppGroup = props => {
    const {
        name,
        description,
        appsInGroup,
        setSelectedGroup,
        group,
        groups,
        isDeleteAvailable = true,
        groupType
    } = props;

    const { store, groupsStore } = useStores();
    const [deleteDialogDisplayed, setDeleteDialogDisplayed] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [isNewGroup, setIsNewGroup] = useState(false);
    const onDeleteConfirm = async () => {
        setDeleteDialogDisplayed(false);
        if (group?.groupType === "ILCDC") await deleteAutomatedGroup(appsInGroup, name, store, groupsStore);
        else groupsStore.delete(name);
    };

    const onEditClose = async action => {
        if (!action) {
            setEditDialogOpen(false);
            return;
        }

        try {
            if (isNewGroup) {
                await groupsStore.create(action.name, action.description);
                setSelectedGroup({
                    name: action.name,
                    description: action.description,
                    applications: [],
                    numberOfApps: group.numApps
                });
            } else {
                await groupsStore.edit(name, action.name, action.description);
            }
            setIsNewGroup(false);
            setEditDialogOpen(false);
        } catch (error) {
            const message = formatGroupError(error, action.name, "edit");
            growl.error(message, "Error editing group");
        }
    };

    const handleClick = () => setSelectedGroup(group);

    // const formatGroupName = (appName, numApps) => (numApps && isSelected ? `${appName} (${numApps})` : appName);

    const onExportClick = async () => {
        const appIds = appsInGroup.map(app => app.id);
        const appControl = new AppControl({
            appIdentifier: appsInGroup[0] //backward compat
        });
        appControl.exportMultiple(appIds);
    };

    const isGroupAll = group => group?.name === "All";

    const isGroupDefaultOrAll = group => group?.name === "All" || group?.isDefaultGroup;

    const getTooltipTitle = () => {
        let title;
        if (isGroupAll(group)) {
            title = "All Applications";
        } else {
            title = (
                <React.Fragment>
                    <div>{group?.isDefaultGroup ? "Default Group" : name}</div>
                    {description && <div>{description}</div>}
                </React.Fragment>
            );
        }

        return title;
    };

    const isDeletable = (group, appsInGroup) => {
        if (group.groupType === "INTERNAL") return true;
        if (appsInGroup[0] && appsInGroup[1]) {
            return (
                statusManagement.isApplicationCreated(appsInGroup[0].flowStatus) &&
                statusManagement.isApplicationCreated(appsInGroup[1].flowStatus)
            );
        }
    };
    const isGroupDeletable = isDeletable(group, appsInGroup);

    return (
        <>
            <Box
                sx={{
                    ...(props.isSelected ? styles.backgroundSelected : styles.background),
                    ...styles.container
                }}
                onClick={handleClick}
                data-test-id="app-group"
            >
                <StriimTooltip title={getTooltipTitle()} placement="left">
                    <Box sx={styles.groupContainer}>
                        {props.isSelected ? (
                            <SvgIcon
                                component={groupType === "ILCDC" ? AutomatedWizardFilled : FolderFilled}
                                sx={styles.icon}
                            />
                        ) : (
                            <SvgIcon
                                component={groupType == "ILCDC" ? AutomatedWizardOutlined : FolderOutlined}
                                sx={styles.icon}
                            />
                        )}
                        <StriimTypography
                            noWrap
                            variant="caption"
                            sx={{ ...styles.text, ...(props.isSelected ? styles.groupNameSelected : styles.groupName) }}
                        >
                            {name}
                        </StriimTypography>
                    </Box>
                </StriimTooltip>
                <AppGroupDropdown
                    name={name}
                    description={description}
                    showExport={Boolean(appsInGroup.length)}
                    showEdit={!isGroupAll(group)}
                    showDelete={!isGroupDefaultOrAll(group)}
                    onExportClick={onExportClick}
                    setDeleteDialogDisplayed={setDeleteDialogDisplayed}
                    onNewGroupClicked={() => {
                        setIsNewGroup(true);
                        setEditDialogOpen(true);
                    }}
                    onEditGroupClicked={() => {
                        setIsNewGroup(false);
                        setEditDialogOpen(true);
                    }}
                    setEditDialogOpen={setEditDialogOpen}
                    isDeletable={isGroupDeletable}
                />
            </Box>
            <AppGroupEdit
                name={name}
                groupType={groupType}
                description={description}
                open={editDialogOpen}
                onClose={onEditClose}
                isNameEditable={isDeleteAvailable || isNewGroup}
                onDeleteConfirm={onDeleteConfirm}
                deleteDialogDisplayed={deleteDialogDisplayed}
                setDeleteDialogDisplayed={setDeleteDialogDisplayed}
                groups={groups}
                isNewGroup={isNewGroup}
                isDeletable={isGroupDeletable}
            />
            <DeleteGroupModal
                group={group}
                isVisible={deleteDialogDisplayed}
                onConfirm={onDeleteConfirm}
                onCancel={setDeleteDialogDisplayed}
            />
        </>
    );
};

AppGroup.propTypes = {
    numberOfApps: PropTypes.number,
    isDeleteAvailable: PropTypes.bool,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    appsInGroup: PropTypes.array,
    isSelected: PropTypes.bool,
    setSelectedGroup: PropTypes.func,
    group: PropTypes.object,
    groups: PropTypes.array
};

export default AppGroup;
