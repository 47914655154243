import metaStoreService from "core/services/metaStoreService/meta-store-service";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import api from "core/api/api";
import App from "app";
import { EMBEDDINGS_GENERATOR_NAMESPACE } from "core/services/metadataService/metaobject-utilities";

const GlobalNamespace = "Global";
const PIISystemNamespace = "System$PIIGlobal";

const PropertySetsService = {
    createPropertySet: (name, nsName) => {
        return new Promise((resolve, reject) => {
            const newPropertySet = new metaStoreService.create(metaStoreService.entities.PROPERTYSET);
            newPropertySet.set("name", name);
            newPropertySet.set("nsName", nsName || App.user.defaultNamespace);
            newPropertySet
                .save()
                .then(function() {
                    resolve();
                })
                .fail(function() {
                    reject("error saving propertySetProperties");
                });
        });
    },
    getPropertySets: function() {
        return new Promise((resolve, reject) => {
            metaStoreService
                .fetchCollection(metaStoreService.entities.PROPERTYSET)
                .then(propertySets => {
                    resolve(
                        propertySets.models.filter(model => {
                            return ![PIISystemNamespace, GlobalNamespace, EMBEDDINGS_GENERATOR_NAMESPACE].includes(
                                model.get("nsName")
                            );
                        })
                    );
                })
                .fail(() => {
                    reject();
                });
        });
    },
    savePropertySet: async function(propertySetId, properties) {
        let propertySet = await metaStoreService.findById(propertySetId);
        propertySet.set("properties", properties);
        await propertySet.save();
    },
    deletePropertySet: function(propertySetId) {
        return new Promise((resolve, reject) => {
            const name = metaObjectConverter.getName(propertySetId);
            const nsName = metaObjectConverter.getNamespace(propertySetId);
            const fullName = metaObjectConverter.getShortId(nsName, name);
            api.dropObject(
                {
                    type: metaStoreService.entities.PROPERTYSET,
                    name: fullName
                },
                true
            )
                .then(() => {
                    resolve();
                })
                .fail(e => {
                    reject(e);
                });
        });
    }
};
export default PropertySetsService;
