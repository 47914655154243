import React, { useEffect, useCallback, useState } from "react";
import { Box, CardHeader, CardContent, SvgIcon } from "@mui/material";
import { AddCircleOutlineRounded } from "@material-ui/icons";
import { Documentation } from "../../../../../generic/icon/customIcons";
import { AddPropertySetModal, PropertySetsEmpty, PropertySetsPresent, PropertySetsService } from "../property-sets";
import growl from "app/components/common/growl";
import LoadingIndicator from "../../../../../generic/loading-indicator/loading-indicator";
import { StriimTypography, StriimButton } from "@striim/striim-ui-v2";
import Dictionary from "app/components/common/helpable/online-help-dictionary";
import { Card } from "../components";

const styles = {
    cardHeaderStyles: {
        p: 0
    },
    contentStyles: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        pt: 0
    },
    iconStyles: {
        "& svg": {
            fill: "none"
        }
    },
    titleStyles: { display: "flex", alignItems: "center", gap: 1 }
};

export default function PropertySetsPage() {
    const [isModalShown, setIsModalShown] = useState(false);
    const [propertySets, setPropertySets] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const onSave = async (id, properties) => {
        try {
            await PropertySetsService.savePropertySet(id, properties);
        } catch (e) {
            throw new Error(e?.message);
        }
    };

    const deletePropertySet = id => {
        PropertySetsService.deletePropertySet(id).then(
            () => {
                growl.success("Property set deleted successfully");
                refresh();
            },
            e => {
                growl.error(`Error deleting property set: ${e.message}`);
                refresh();
            }
        );
    };

    const getComponent = useCallback(() => {
        if (isLoading) {
            return <LoadingIndicator isGlobal={false} />;
        } else if (propertySets?.length) {
            return (
                <PropertySetsPresent
                    propertySets={propertySets}
                    onAdded={refresh}
                    onSave={onSave}
                    deletePropertySet={deletePropertySet}
                />
            );
        } else {
            return <PropertySetsEmpty onAdded={refresh} />;
        }
    }, [isLoading, propertySets]);

    const refresh = () => {
        setIsLoading(true);
        return PropertySetsService.getPropertySets()
            .then(propertySets => {
                setPropertySets(propertySets);
            })
            .finally(() => {
                setIsLoading(false);
            })
            .catch(() => {
                growl.error("Oops, we can't seem to find the propertySets you requested");
            });
    };

    useEffect(() => {
        refresh();
    }, []);

    return (
        <Card>
            <CardHeader
                sx={styles.cardHeaderStyles}
                disableTypography
                title={
                    <Box sx={styles.titleStyles}>
                        <StriimTypography variant="h2" color="primary.700">
                            Property Sets
                        </StriimTypography>
                        <SvgIcon
                            sx={styles.iconStyles}
                            component={"a"}
                            href={Dictionary.get()["MANAGE_STRIIM_PROPERTY_SETS"].href}
                            target="_blank"
                        >
                            <Documentation />
                        </SvgIcon>
                    </Box>
                }
                subheader={
                    propertySets?.length ? (
                        <div style={{ maxWidth: "800px" }}>
                            <StriimTypography variant="body4" color="greyscale.600">
                                You can create and use property sets for applications, data streams and LDAP users.
                            </StriimTypography>
                        </div>
                    ) : null
                }
                action={
                    propertySets?.length ? (
                        <StriimButton
                            variant="primary"
                            startIcon={<AddCircleOutlineRounded />}
                            onClick={() => setIsModalShown(true)}
                            data-test-id="add-property-set-button"
                        >
                            New Property Set
                        </StriimButton>
                    ) : null
                }
            />
            <CardContent sx={styles.contentStyles}>{getComponent()}</CardContent>
            {isModalShown && <AddPropertySetModal setIsModalShown={setIsModalShown} onAdded={refresh} />}
        </Card>
    );
}
