import _ from "underscore";
import BaseControl from "./../base-control";
import TypeField from "./field";
import template from "./fields-list.html";


const rootClassesWhenChildrenExists = {
    addRow: "with-add-row"
}

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "fields-list",
    events: {
        "click @ui.add": "newFieldAdded",
    },
    regions: {
        fields: ".fields",
    },
    ui: {
        addRow: ".addField",
        add: ".addField a"
    },

    initialize: function () {
        this.bindUIElements();

        var fields = new TypeField.Collection([]);

        this.gridView = new TypeField.CollectionView({
            collection: fields,
            model: this.model,
        });

        this.listenTo(this.gridView, "remove", function (childView) {
            this.gridView.collection.remove(childView.model);
        });

        this.listenTo(this.gridView, "modified", function () {
            this.setValueFromView();
        });
    },

    newFieldAdded: function () {
        this.gridView.collection.push({
            isKey: false,
            name: "",
            alias: "",
            type: "java.lang.String",
        });
        this.gridView.scrollToBottom();
    },

    getViewValue: function () {
        return this.gridView.collection.toJSON();
    },

    hasErrors: function () {
        return this.gridView.collection.hasErrors;
    },

    hasKey: function () {
        return this.gridView.collection.hasKey;
    },

    setViewValue: function () {
        this.setFields(this.getValue());
    },

    setFields: function (fields) {
        this.gridView.collection.reset(fields);
        this.setViewEnabled();
    },

    setViewEnabled: function () {
        BaseControl.View.prototype.setViewEnabled.apply(this);
        var enabled = this.getEnabled();
        this.ui.addRow.toggle(enabled);
        if(!enabled){
            this.$el.find(".grid-action").remove();
            this.$el.find("tr").css("border-color","white");
            this.$el.removeClass(rootClassesWhenChildrenExists.addRow);
        } else {
            this.$el.addClass(rootClassesWhenChildrenExists.addRow);
        }
        this.gridView.setEnabled(enabled);
    },

    onRender: function () {
        this.gridView.collection.reset(this.getValue());
        this.fields.show(this.gridView);
        BaseControl.View.prototype.onRender.call(this);
    },
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: Array,
    },
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
