import _ from "lodash";
import { ALL_GROUP_NAME } from "../../consts";

class FilterTypeBase {
    constructor(type, label, callbackFunction) {
        this.type = type;
        this.label = label;
        this.callbackFunction = callbackFunction;
        this.isSearchFilter = false;
    }

    toString() {
        return this.type;
    }

    static FilterCategory = {
        UserInput: "UserInput",
        Group: "Group",
        Statuses: "Statuses",
        Namespaces: "Namespaces",
        Sources: "Sources",
        Targets: "Targets"
    };

    static UserInput = input =>
        new FilterType(this.FilterCategory.UserInput, "Search", app => {
            return (
                !_.isNil(input) &&
                !_.isEmpty(input) &&
                _.join([app.name], " ")
                    .toLowerCase()
                    .includes(input.toLowerCase())
            );
        });

    static Group = group =>
        new FilterType(this.FilterCategory.Group, "Group", app => {
            if (!group) {
                return false;
            }
            if (group.name === ALL_GROUP_NAME) {
                return true;
            }
            if (group.applications.includes(app.id)) {
                return true;
            }
            return false;
        });

    static Statuses = statuses =>
        new FilterType(this.FilterCategory.Statuses, "Status", app => {
            return statuses.includes(app.flowStatus);
        });

    static Namespaces = namespaces =>
        new FilterType(this.FilterCategory.Namespaces, "Namespaces", app => {
            return namespaces.includes(app.nsName);
        });

    static Sources = sources =>
        new FilterType(this.FilterCategory.Sources, "Sources", app => {
            const appSourceNames = app.sources.map(source => source["adapter-name"]);
            return sources.some(sourceName => appSourceNames.includes(sourceName));
        });

    static Targets = targets =>
        new FilterType(this.FilterCategory.Targets, "Targets", app => {
            const appTargetNames = app.targets.map(target => target["adapter-name"]);
            return targets.some(targetName => appTargetNames.includes(targetName));
        });
}

export class FilterType extends FilterTypeBase {}
