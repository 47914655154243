import _ from "underscore";
import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var module = {};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        headerInputFrom: null,

        type: "WINDOW",
        stream: String,
        mode: "jumping",
        partitionFields: Array,
        size: Object,
    },
    validate: function () {
        var size = this.get("size");
        var errors = baseMetaObject.Model.prototype.validate.apply(this, arguments) || {};
        if (size.on && !size.time) {
            errors["size.on"] = 'Value for "Time" needs to be specified when "On" field is set';
        }

        return _.isEmpty(errors) ? undefined : errors;
    },
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model,
});

export default module;
