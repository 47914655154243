import App from "app";
import _ from "underscore";
import HighMapsMixin from "app/components/dashboard/visualization/charts/mixins/highmaps";
import "app/components/dashboard/visualization/charts/seriesgenerators/latlong/generator";
import proj4 from "proj4";
import mapData from "lib/vendor/mapdata/geojson/countries/world-highres.geo.json";

window.proj4 = proj4; // vectormaps needs proj4 for latlong stuf..
export default _({}).extend(HighMapsMixin, {
    // Note: be sure to pass in a deep-cloned config object!
    setConfiguration: function (config) {
        const theme = config.theme;
        config = config.config;
        if (!theme) {
            return console.warn("No theme provided.");
        }

        this.chartConfig = {
            chart: {
                borderWidth: 0,
                backgroundColor: theme.vectormap.background,
            },
            title: {
                text: config.title,
            },
            legend: {
                enabled: false,
            },
            mapNavigation: {
                enabled: true,
                buttonOptions: {
                    verticalAlign: "bottom",
                },
            },
            // tooltip: this.generateToolTipConfig(config.tooltipConfig, this.model.config.fieldList),
            tooltip: this.generateToolTipConfig(),
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                    },
                    enableMouseTracking: false,
                },
                series: {
                    cursor: "pointer",
                    animation: false,
                    events: {
                        hide: function (e) {
                            this.trigger("series-hidden", e);
                        }.bind(this),
                        show: function (e) {
                            this.trigger("series-shown", e);
                        }.bind(this),
                    },
                    tooltip: {
                        hideDelay: 0,
                    },
                    point: {
                        events: {
                            click: function (e) {
                                this.trigger("datapoint:click", e.point.rowData);
                            }.bind(this),
                            mouseOver: function (e) {
                                // this allows to hide tooltip instantly after moving mouse of of the point instead of the graph.
                                e.target.graphic.element.onmouseout = function () {
                                    e.target.series.chart.tooltip.hide();
                                    e.target.series.onMouseOut();
                                };
                            }.bind(this),
                        },
                    },
                },
            },
            viewZoom: config.viewZoom,
            viewXOffset: config.viewXOffset,
            viewYOffset: config.viewYOffset,
        };

        const series = [];

        const mapSeries = {
            name: "World Map",
            mapData: mapData,
            nullColor: "#22292c",
            enableMouseTracking: false,
        };
        series.push(mapSeries);

        const seriesConfigurationOptions = {
            latitudeField: config.latitudeField,
            longitudeField: config.longitudeField,
            categoryField: config.categoryField,
            colorField: config.colorField,
            tooltipConfig: config.tooltips,
            fieldList: this.model.dataSource.fieldList,
            conditionalColorRules: config.conditionalColor,
            valueField: config.valueField,
            data: this.model.dataSource.data,
        };

        const seriesGeneratorFactory = new App.Chart.SeriesGenerators.LatLong.SeriesGeneratorFactory();

        const seriesGenerator = seriesGeneratorFactory.create(seriesConfigurationOptions);

        const seriesData = seriesGenerator.generate();
        const newSeries = {
            type: "mapbubble",
            data: seriesData,
            animation: false,
            minSize: config.minBubbleSize,
            maxSize: config.maxBubbleSize,
        };

        series.push(newSeries);

        this.chartConfig.series = series;
        this.chartConfig.legendData = seriesGenerator.getLegendData();
    },
});
