import { makeStyles } from "@material-ui/core/styles";

const addonDescriptionTileColor = "#EDE6F2";

export default makeStyles(({ spacing }) => ({
    addonsWrapper: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "start",
        marginTop: spacing(2),
        gap: 20
    },
    descriptionTile: {
        display: "none", //TODO: for beta it's hidden
        //display: "flex",
        borderRadius: 4,
        backgroundColor: addonDescriptionTileColor,
        padding: "30px 25px",
        width: "100%",
        marginBottom: 25,
    },
    icon: {
        width: 100,
        height: 100,
        marginRight: 5
    },
    flex: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingRight: 20,
        minHeight: 100
    },
    text: {
        display: "flex",
        lineHeight: 24
    }
}));
