import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import DragAndDrop from "../common/dropdown_customizer/drag_n_drop";
import { StriimTypography, StriimModal, StriimButton } from "@striim/striim-ui";
import useStyles from "../common/dropdown_customizer/drag_n_drop.styles.jsx";
import { useTheme } from "@mui/material/styles";

const CustomizeHomepageModal = ({ widgets, apps, show, defaultSeenWidgets, allWidgets, onChange }) => {
    const [tempSelected, setTempSelected] = useState(widgets || []);
    const unusedWidgets = widgets
        ? allWidgets.filter(widget => widgets.map(w => w.comp).indexOf(widget.comp) === -1)
        : allWidgets;
    const [tempRemoved, setTempRemoved] = useState(unusedWidgets);
    const v5theme = useTheme();
    const classes = useStyles(v5theme);
    const modalRef = useRef();
    const scrollToBottom = () => {
        const elem = modalRef?.current?.parentElement;
        if (elem) {
            setTimeout(() => (elem.scrollTop = elem.clientHeight), 100);
        }
    };

    const handleCancel = () => {
        setTempRemoved(unusedWidgets);
        setTempSelected(widgets);
        show[1](false);
    };
    const handleConfirm = () => {
        onChange(tempSelected, tempRemoved);
        show[1](false);
    };

    return (
        <StriimModal
            titleContent={
                <>
                    <StriimTypography variant="h2" color="primary.700">
                        Customize Your Homepage
                    </StriimTypography>
                    <StriimTypography variant="body4" color="greyscale.700">
                        Select the widgets you would like to see on your homepage or reorder the list to suit your
                        needs.
                    </StriimTypography>
                </>
            }
            autoHeight
            isVisible={show[0]}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            confirmContent="Confirm"
            size="medium"
            confirmButtonProps={{ "data-test-id": "confirm-customise-button" }}
            dialogContentProps={{
                classes: { root: classes.overflowVisible }
            }}
            dialogProps={{
                classes: { paper: classes.dialog }
            }}
        >
            <div ref={modalRef} data-test-id="customize-homepage-modal-content">
                <DragAndDrop
                    selected={[tempSelected, setTempSelected]}
                    removed={[tempRemoved, setTempRemoved]}
                    metrics={true}
                    apps={apps}
                    scrollToBottom={scrollToBottom}
                />
                <StriimButton
                    onClick={() => {
                        setTempSelected(defaultSeenWidgets);
                        setTempRemoved([]);
                    }}
                    variant="text"
                    style={{ position: "absolute", bottom: 20, left: 20 }}
                    data-testid="restore-defaults--button"
                >
                    Restore Defaults
                </StriimButton>
            </div>
        </StriimModal>
    );
};

export default CustomizeHomepageModal;

CustomizeHomepageModal.propTypes = {
    apps: PropTypes.array.isRequired,
    widgets: PropTypes.array.isRequired,
    show: PropTypes.array.isRequired, // Show modal usestate, 0-> bool; 1-> bool setter method
    defaultSeenWidgets: PropTypes.array.isRequired,
    allWidgets: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};
