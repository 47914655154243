import { action, observable } from "mobx";
class ValidateConnectionStore {
    @observable operations = [];

    constructor() {
        this.operations.push({
            id: 0,
            status: "todo",
            text: "Trying to reach your Database",
            remedy: null,
            method: this.checkConnection
        });
    }

    @action resetStatus() {
        this.operations.forEach(function(operation) {
            operation.status = "todo";
        });
    }

    @action async performOperations(componentStore) {
        let hasFailed = false;
        for (let i = 0; i < this.operations.length; i++) {
            let operation = this.operations[i];
            if (hasFailed) {
                operation.status = "fail";
                continue;
            }
            try {
                await operation.method.call(this, componentStore);
                operation.status = "done";
            } catch (e) {
                operation.status = "fail";
                operation.remedy = e;
                hasFailed = true;
            }
        }
    }

    @action checkConnection(componentStore) {
        return componentStore.checkConnection();
    }
}

export default ValidateConnectionStore;
