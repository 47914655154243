export const DB_PROVIDERS = {
    //DO-NOT-REMOVE-BELOW-COMMENT
    //add-new-adapter
    YUGABYTE: {
        id: "YUGABYTE",
        name: "Yugabyte",
        tableWrapper: "Schema",
        rootElement: "Database",
        whatIsTable: "Table",
        isAppAdapter: false
    },
    PAYMO: {
        id: "PAYMO",
        name: "Paymo",
        tableWrapper: "Object",
        rootElement: "Environment",
        whatIsTable: "Object",
        isAppAdapter: true
    },
    LINKEDINADS: {
        id: "LINKEDINADS",
        name: "LinkedInAds",
        tableWrapper: "Table",
        rootElement: "Environment",
        whatIsTable: "Table",
        isAppAdapter: true
    },
    GOOGLEANALYTICS: {
        id: "GOOGLEANALYTICS",
        name: "GoogleAnalytics",
        tableWrapper: "Table",
        rootElement: "Environment",
        whatIsTable: "Table",
        isAppAdapter: true
    },
    JIRA: {
        id: "JIRA",
        name: "Jira",
        tableWrapper: "Object",
        rootElement: "Environment",
        whatIsTable: "Object",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column",
        isAppAdapter: true
    },
    STRIPE: {
        id: "STRIPE",
        name: "Stripe",
        tableWrapper: "Object",
        rootElement: "Environment",
        whatIsTable: "Object",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column",
        isAppAdapter: true
    },
    ZENDESK: {
        id: "ZENDESK",
        name: "Zendesk",
        tableWrapper: "Object",
        rootElement: "Environment",
        whatIsTable: "Object",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column",
        isAppAdapter: true
    },
    GOOGLEADS: {
        id: "GOOGLEADS",
        name: "Google Ads",
        tableWrapper: "Object",
        rootElement: "Environment",
        whatIsTable: "Object",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column",
        isAppAdapter: true
    },
    HUBSPOT: {
        id: "HUBSPOT",
        name: "HubSpot",
        tableWrapper: "Object",
        rootElement: "Environment",
        whatIsTable: "Object",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column",
        isAppAdapter: true
    },
    BAMBOOHR: {
        id: "BAMBOOHR",
        name: "BambooHr",
        tableWrapper: "Object",
        rootElement: "Environment",
        whatIsTable: "Object",
        isAppAdapter: true
    },
    ORACLE: {
        id: "ORACLE",
        name: "Oracle",
        tableWrapper: "Schema",
        rootElement: "Database",
        whatIsTable: "Table",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column"
    },
    POSTGRES: {
        id: "POSTGRES",
        name: "Postgres",
        tableWrapper: "Schema",
        rootElement: "Database",
        whatIsTable: "Table",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column"
    },
    SQLSERVER: {
        id: "SQLSERVER",
        name: "SQLServer",
        tableWrapper: "Schema",
        rootElement: "Database",
        whatIsTable: "Table",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column"
    },
    MYSQL: {
        id: "MYSQL",
        name: "MySQL",
        tableWrapper: "Database",
        rootElement: "Database",
        whatIsTable: "Table",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column"
    },
    MARIADB: {
        id: "MARIADB",
        name: "MariaDB",
        tableWrapper: "Database",
        rootElement: "Database",
        whatIsTable: "Table",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column"
    },
    MARIADBXPAND: {
        id: "MARIADBXPAND",
        name: "MariaDBXpand",
        tableWrapper: "Database",
        rootElement: "Database",
        whatIsTable: "Table",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column"
    },
    MSJET: {
        id: "MSJET",
        name: "MSJet",
        tableWrapper: "Schema",
        rootElement: "Database",
        whatIsTable: "Table",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column"
    },
    OJET: {
        id: "OJET",
        name: "OJet",
        tableWrapper: "Schema",
        rootElement: "Database",
        whatIsTable: "Table",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column"
    },
    MONGODB: {
        id: "MONGODB",
        name: "MongoDB",
        tableWrapper: "Database",
        rootElement: "Environment",
        whatIsTable: "Collection",
        whatIsEvent: "Document",
        whatIsField: "Field"
    },
    COSMOSDB: {
        id: "COSMOSDB",
        name: "CosmosDB",
        tableWrapper: "Database",
        rootElement: "Environment",
        whatIsTable: "Container",
        whatIsEvent: "Document",
        whatIsField: "Field"
    },
    MONGO_COSMOSDB: {
        id: "MONGO_COSMOSDB",
        name: "CosmosDB",
        tableWrapper: "Database",
        rootElement: "Environment",
        whatIsTable: "Collection",
        whatIsEvent: "Document",
        whatIsField: "Field"
    },
    SALESFORCE: {
        id: "SALESFORCE",
        name: "Salesforce",
        tableWrapper: "Object",
        rootElement: "Environment",
        whatIsTable: "Object",
        whatIsEntity: "Object",
        whatIsEvent: "Row",
        whatIsField: "Field",
        isAppAdapter: true
    },
    "SALESFORCE CDC": {
        id: "SALESFORCECDC",
        name: "SalesforceCDCReader",
        tableWrapper: "Object",
        rootElement: "Environment",
        whatIsTable: "Object",
        isAppAdapter: true
    },
    SALESFORCEPARDOT: {
        id: "SALESFORCEPARDOT",
        name: "Salesforce Pardot",
        tableWrapper: "Object",
        rootElement: "Environment",
        whatIsTable: "Object",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column",
        isAppAdapter: true
    },
    SERVICENOW: {
        id: "SERVICENOW",
        name: "ServiceNow",
        tableWrapper: "Object",
        rootElement: "Environment",
        whatIsTable: "Object",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column",
        isAppAdapter: true
    },
    SNOWFLAKE: {
        id: "SNOWFLAKE",
        name: "Snowflake",
        tableWrapper: "Schema",
        rootElement: "Database",
        whatIsTable: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column"
    },
    INTERCOM: {
        id: "INTERCOM",
        name: "Intercom",
        tableWrapper: "Object",
        rootElement: "Environment",
        whatIsTable: "Object",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column",
        isAppAdapter: true
    },
    MICROSOFTDATAVERSE: {
        id: "MICROSOFTDATAVERSE",
        name: "Microsoft Dataverse",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column",
        isAppAdapter: true
    },
    SALESFORCEMARKETINGCLOUD: {
        id: "SALESFORCEMARKETINGCLOUD",
        name: "Salesforce Marketing Cloud",
        whatIsEntity: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column",
        isAppAdapter: true
    },
    BIGQUERY: {
        id: "BIGQUERY",
        name: "BigQuery",
        tableWrapper: "Dataset",
        rootElement: "Dataset",
        whatIsTable: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column"
    },
    SNOWFLAKE: {
        id: "SNOWFLAKE",
        name: "Snowflake",
        tableWrapper: "Schema",
        rootElement: "Database",
        whatIsTable: "Table",
        whatIsEvent: "Row",
        whatIsField: "Column"
    }
};
