import { AppAction } from "../../../../../../../status-management";
import appListService from "../../../app-list-service";

export const deleteAutomatedGroup = async (AppsToDelete, name, store, groupsStore) => {
    // Move To all group
    const group = groupsStore.groups.find(g => g.name === "All");
    appListService.setSelectedGroup(group);
    // Delete IL and CDC Apps
    await store.onAppAction(AppsToDelete[0], AppAction.DROP, { doNotDeleteExceptionStore: false });
    await store.onAppAction(AppsToDelete[1], AppAction.DROP, { doNotDeleteExceptionStore: false });
    // Delete group
    groupsStore.delete(name);
    // Refresh Applist and groups
    await store.fetchApps();
    await groupsStore.fetch();
};
