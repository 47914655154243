import Backbone from "backbone";
import _ from "underscore";
import dateLib from "core/utils/date-utils";
import template from "./templates/appinfopanel.html";

var AppInfoPanel = {};

function formatTime(time) {
    if (time === -2) {
        return "Fetching…";
    }
    if (time === -1) {
        return "Not Ready";
    }
    if (!time) {
        return "";
    }
    return dateLib(time).format("hh:mm:ss");
}

function formatMilliseconds(time) {
    if (!time) {
        return "";
    }
    return dateLib(time).valueOf() + " ms";
}

const iconTypes = {
    checkpointInfoNotAvailable: "checkpoint-info_not-available",
    checkpointInfoNotProgressing: "checkpoint-info_not-progressing",
    checkpointInfoProgressing: "checkpoint-info_progressing",
    checkpointInfoTrailing: "checkpoint-info_trailing",
}

AppInfoPanel.View = Backbone.Marionette.ItemView.extend({
    template: _.template(template),
    className: "panels",
    ui: {
        checkpointDataContainer: ".checkpoint-data-container",
        checkpointTitle: ".app-details-checkpoint-title",
        targetAckedLabelIcon: ".target-acked-label-icon",
        appDetailsCheckpointIcon: ".app-details-checkpoint-icon"
    },

    modelEvents: {
        change: "render"
    },

    onRender: function() {
        this.ui.targetAckedLabelIcon.tooltipster({
            interactive: false,
            content: "Events successfully written to the target",
            delay: 200,
            contentAsHTML: false,
            animation: "grow",
            position: "top"
        });
        this._renderCheckpointData();
    },

    _renderCheckpointData: function() {
        const values = [];

        const stats = this.model.get("appStatsMostRecentData");

        if (!stats) {
            console.error("Cannot get appStatsMostRecentData")
            return;
        }

        let checkpointStatus = stats["checkpoint-status"];

        const setIcon = iconType => {
            this.ui.appDetailsCheckpointIcon.removeClass("hidden-element");
            Object.values(iconTypes).forEach(
                removeIcon => this.ui.appDetailsCheckpointIcon.removeClass(removeIcon)
            );
            this.ui.appDetailsCheckpointIcon.addClass(iconType);
        }

        if (!checkpointStatus) {
            const emptyMessage =
                "Checkpoint data is not available at this time. This can be because Recovery was not turned on or no checkpoints were recorded yet.";
            this.ui.checkpointDataContainer.parent().addClass("max-height");
            this.ui.checkpointDataContainer
                .parent()
                .parent()
                .addClass("max-height");
            this.ui.checkpointDataContainer.html(`<tr><td>${emptyMessage}</td></tr>`);
            setIcon(iconTypes.checkpointInfoNotAvailable);
            this.ui.checkpointTitle.html("Not available");
            return;
        }

        switch (checkpointStatus) {
            case "PROGRESSING": {
                setIcon(iconTypes.checkpointInfoProgressing);
                this.ui.checkpointTitle.html("Progressing");
                values.push({
                    name: "Last checkpoint time",
                    value: formatTime(stats["last-checkpoint-time"])
                });
                values.push({
                    name: "Time elapsed since the last checkpoint",
                    value: formatMilliseconds(Date.now() - stats["last-checkpoint-time"])
                });
                values.push({
                    name: "Num of checkpoints recorded",
                    value: stats["num-of-checkpoints-recorded"]
                });
                break;
            }
            case "TRAILING": {
                setIcon(iconTypes.checkpointInfoTrailing);
                this.ui.checkpointTitle.html("Trailing");
                values.push({
                    name: "Last checkpoint time",
                    value: formatTime(stats["last-checkpoint-time"])
                });
                values.push({
                    name: "Last late checkpoint time",
                    value: formatTime(stats["last-late-checkpoint-time"]) || "None"
                });
                values.push({
                    name: "Time taken for last late checkpoint",
                    value: formatTime(stats["time-taken-for-last-late-checkpoint"])
                });
                break;
            }
            case "NOT_PROGRESSING": {
                setIcon(iconTypes.checkpointInfoNotProgressing);
                this.ui.checkpointTitle.html("Not progressing");
                values.push({
                    name: "Last checkpoint time",
                    value: formatTime(stats["last-checkpoint-time"]) || "None"
                });
                values.push({
                    name: "Num of late checkpoints injected",
                    value: stats["num-of-late-checkpoints-injected"] || "None"
                });
                values.push({
                    name: "Num of late checkpoints recorded",
                    value: stats["num-of-late-checkpoints-recorded"] || "None"
                });
                break;
            }
        }
        const content = values.map(item => {
            return `<td class="desc">${item.name}</td><td class="val">${item.value}</td>`;
        });
        this.ui.checkpointDataContainer.html(`<tr>${content.join("</tr><tr>")}</tr>`);
    }
});

export default AppInfoPanel;
