import CodeMirror from "cm/lib/codemirror";
import "cm/mode/sql/sql";

export default {
    isReservedKeyword: function (keyword) {
        if (!keyword) {
            return false;
        }

        return CodeMirror.mimeModes["text/x-tql"].keywords[keyword.toLowerCase()];
    },
};
