import React, { useMemo, useRef, useState } from "react";
import {
    StriimIconButton,
    StriimTable,
    StriimTooltip,
    StriimTypography,
    StriimBadge,
    StriimChip
} from "@striim/striim-ui";
import { USER_TABLE_STRINGS, userColumnKey, userTableColumns } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useUserManagement } from "core/user-management/user-management-context";
import { canActivateDeactivateUser, isUserDeletable, isUserEditable } from "core/user-management/update.control";
import { DeleteModal } from "src/modules/apps/pages/user-management/common";
import { Grid, SvgIcon, useTheme } from "@mui/material";

import { DeleteIcon, EditIcon, EyeIcon, ToggledOn, ToggledOff } from "../../../../../../../generic/icon/customIcons";
import { useStyles } from "./user-table.styles";
import TableIconButton from "../../../common/table-icon-button";

const UserTable = ({ users }) => {
    const themeV5 = useTheme();
    const classes = useStyles(themeV5);
    const navigate = useNavigate();

    const { deleteUser, deactivateUser, activateUser } = useUserManagement();
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const userTableRef = useRef();

    const userTableColumnHeaders = userTableColumns.map(v => {
        return v.accessor === userColumnKey.name
            ? {
                  ...v,
                  Header: (
                      <StriimBadge badgeContent={users.length} inline>
                          <StriimTypography variant="body3" color="greyscale.600">
                              {v.Header}
                          </StriimTypography>
                      </StriimBadge>
                  )
              }
            : v;
    });

    const handleDelete = userId => {
        userTableRef.current = userId;
        setDeleteDialogVisible(true);
    };

    const toggleActivation = user => {
        const userId = user[USER_TABLE_STRINGS.label.userId];
        if (user.isActive) {
            deactivateUser(userId);
        } else {
            activateUser(userId);
        }
    };

    const onDeleteConfirm = () => {
        const userId = userTableRef.current;
        deleteUser(userId);
        setDeleteDialogVisible(false);
    };

    const getFormattedList = (list, type) => {
        const seperator = type === USER_TABLE_STRINGS.label.role ? ";" : ",";
        const listItems = list?.map((v, i) => {
            return i !== 0 ? (
                <React.Fragment key={`${v.name}${i}`}>
                    {seperator} {v.formattedName || v.name}
                </React.Fragment>
            ) : (
                v.formattedName || v.name
            );
        });

        const listCountText = `${list.length.toString()} ${type}${list.length > 1 ? `s` : ``}`;
        const countFormat = <StriimTypography variant="bodySemibold">{listCountText}</StriimTypography>;
        return (
            list.length >= 1 && (
                <>
                    {countFormat} ({listItems})
                </>
            )
        );
    };

    const getOriginType = originType => {
        const ssoChip = (
            <StriimChip type="tag-chip" variant="default" label="SSO" className={classes.ssoChip} hideAvatar />
        );
        const striimChip = (
            <StriimChip type="tag-chip" variant="default" label="STRIIM" className={classes.striimChip} hideAvatar />
        );

        return originType.toLowerCase() === "sso" ? ssoChip : striimChip;
    };

    const userTableData = useMemo(
        () =>
            users?.map(v => {
                const rolesFormatted = getFormattedList(v.roles, USER_TABLE_STRINGS.label.role);
                const permissionsFormatted = getFormattedList(v.permissionsText, USER_TABLE_STRINGS.label.permission);
                const originType = getOriginType(v.originType);
                const rolesPermissions = (
                    <>
                        {rolesFormatted} {permissionsFormatted}
                    </>
                );
                const actions = (
                    <Grid container alignItems="center" justifyContent="flex-end">
                        <Grid item>
                            <TableIconButton
                                navigate={() => navigate(`/users/user/${v[USER_TABLE_STRINGS.label.userId]}`)}
                                tooltipTitle="View"
                                Icon={EyeIcon}
                                dataTestId={`${v[USER_TABLE_STRINGS.label.userId]}-view`}
                            />
                        </Grid>
                        {isUserEditable(v) && (
                            <Grid item>
                                <TableIconButton
                                    navigate={() => navigate(`/users/user/${v[USER_TABLE_STRINGS.label.userId]}/edit`)}
                                    tooltipTitle="Edit"
                                    Icon={EditIcon}
                                    dataTestId={`${v[USER_TABLE_STRINGS.label.userId]}-edit`}
                                />
                            </Grid>
                        )}
                        {isUserDeletable(v) && (
                            <Grid item>
                                <TableIconButton
                                    navigate={() => handleDelete(v[USER_TABLE_STRINGS.label.userId])}
                                    tooltipTitle="Delete"
                                    Icon={DeleteIcon}
                                    dataTestId={`${v[USER_TABLE_STRINGS.label.userId]}-delete`}
                                />
                            </Grid>
                        )}
                        {canActivateDeactivateUser(v) && (
                            <Grid item>
                                <TableIconButton
                                    navigate={() => toggleActivation(v)}
                                    tooltipTitle={v.isActive ? "Deactivate User" : "Activate User"}
                                    Icon={v.isActive ? ToggledOn : ToggledOff}
                                    dataTestId={`${v[USER_TABLE_STRINGS.label.userId]}-${
                                        v.isActive ? "deactivate" : "activate"
                                    }`}
                                />
                            </Grid>
                        )}
                    </Grid>
                );
                return {
                    [userColumnKey.name]: v[USER_TABLE_STRINGS.label.userId],
                    [userColumnKey.rolesPermissions]: rolesPermissions,
                    [userColumnKey.originType]: originType,
                    [userColumnKey.actions]: actions
                };
            }),
        [users]
    );

    return (
        <>
            <Grid container>
                <Grid item md={12}>
                    <StriimTable
                        columns={userTableColumnHeaders}
                        data={userTableData}
                        TableProps={{ "data-testid": "users-table" }}
                        indexedRow
                        TableBodyProps={{ seperated: true }}
                        TableHeaderCellProps={{
                            style: {
                                background: themeV5?.palette.greyscale[50]
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <DeleteModal
                id={userTableRef?.current}
                type={"user"}
                deleteDialogVisible={deleteDialogVisible}
                setDeleteDialogVisible={setDeleteDialogVisible}
                onDeleteConfirm={onDeleteConfirm}
            />
        </>
    );
};

export default UserTable;
