import utils from "core/utils";
import dateLib from "core/utils/date-utils";

var FieldFilterHelper = {};

FieldFilterHelper.filterTypes = {
    Equal: {
        id: "equal",
        text: "is equal to"
    },
    Range: {
        id: "range",
        text: "is between"
    },
    Greater: {
        id: "greater",
        text: "is greater than"
    },
    Less: {
        id: "less",
        text: "is less than"
    }
};

FieldFilterHelper.fieldValues = {
    MaxNumber: 1000000,
    MinNumber: -1000000
};

FieldFilterHelper.GetAvailableFilterTypes = function(type) {
    switch (type) {
        case utils.NATIVE_TYPES.Short.classname:
        case utils.NATIVE_TYPES.Integer.classname:
        case utils.NATIVE_TYPES.Long.classname:
        case utils.NATIVE_TYPES.Float.classname:
        case utils.NATIVE_TYPES.Double.classname:
            return [
                FieldFilterHelper.filterTypes.Equal,
                FieldFilterHelper.filterTypes.Range,
                FieldFilterHelper.filterTypes.Less,
                FieldFilterHelper.filterTypes.Greater
            ];

        case utils.NATIVE_TYPES.String.classname:
            return [FieldFilterHelper.filterTypes.Equal];

        case utils.NATIVE_TYPES.DateTime.classname:
            return [FieldFilterHelper.filterTypes.Equal, FieldFilterHelper.filterTypes.Range];
    }
};

FieldFilterHelper.GetFilterTypeFromCurrentValue = function(currentValue) {
    if (!currentValue) {
        return FieldFilterHelper.filterTypes.Equal.id;
    }

    if (
        currentValue.from &&
        currentValue.from !== FieldFilterHelper.fieldValues.MinNumber &&
        currentValue.to &&
        currentValue.to !== FieldFilterHelper.fieldValues.MaxNumber
    ) {
        return FieldFilterHelper.filterTypes.Range.id;
    }

    if (currentValue.from && currentValue.from === FieldFilterHelper.fieldValues.MinNumber) {
        return FieldFilterHelper.filterTypes.Less.id;
    }

    if (currentValue.to && currentValue.to === FieldFilterHelper.fieldValues.MaxNumber) {
        return FieldFilterHelper.filterTypes.Greater.id;
    }

    return FieldFilterHelper.filterTypes.Equal.id;
};

FieldFilterHelper.FormatTitle = function(fields, selectedParam) {
    function formatDateTime(value_param) {
        return dateLib(value_param).format("MMM D") + ", " + dateLib(value_param).format("h:mma");
    }

    var fieldValue, fieldType, fieldName;

    for (var i = 0; i < fields.length; i++) {
        if (selectedParam[fields[i].name]) {
            fieldType = fields[i].type;
            fieldName = fields[i].name;
            fieldValue = selectedParam[fields[i].name];
            break;
        }
    }

    if (!fieldType || !fieldName || !fieldValue) {
        return "Select field";
    }

    var filterType = FieldFilterHelper.GetFilterTypeFromCurrentValue(fieldValue);

    switch (filterType) {
        case FieldFilterHelper.filterTypes.Equal.id:
            if (fieldType === utils.NATIVE_TYPES.DateTime.classname) {
                return fieldName + " : " + formatDateTime(fieldValue);
            }
            return fieldName + " : " + fieldValue;

        case FieldFilterHelper.filterTypes.Range.id:
            if (fieldType === utils.NATIVE_TYPES.DateTime.classname) {
                return (
                    fieldName +
                    " " +
                    FieldFilterHelper.filterTypes.Range.text +
                    " : " +
                    formatDateTime(fieldValue.from) +
                    " and " +
                    formatDateTime(fieldValue.to)
                );
            } else {
                return (
                    fieldName +
                    " " +
                    FieldFilterHelper.filterTypes.Range.text +
                    " : " +
                    fieldValue.from +
                    " and " +
                    fieldValue.to
                );
            }
            break;
        case FieldFilterHelper.filterTypes.Less.id:
            if (fieldType === utils.NATIVE_TYPES.DateTime.classname) {
                return (
                    fieldName + " " + FieldFilterHelper.filterTypes.Less.text + " : " + formatDateTime(fieldValue.to)
                );
            } else {
                return fieldName + " " + FieldFilterHelper.filterTypes.Less.text + " : " + fieldValue.to;
            }
            break;

        case FieldFilterHelper.filterTypes.Greater.id:
            if (fieldType === utils.NATIVE_TYPES.DateTime.classname) {
                return (
                    fieldName +
                    " " +
                    FieldFilterHelper.filterTypes.Greater.text +
                    " : " +
                    formatDateTime(fieldValue.from)
                );
            } else {
                return fieldName + " " + FieldFilterHelper.filterTypes.Greater.text + " : " + fieldValue.from;
            }
            break;
    }

    return "Select field";
};

export default FieldFilterHelper;
