import { FIELD_TYPES } from "@striim/striim-ui";

const marginStyle = { marginLeft: "30px" };

const getSSLVisibility = (service = []) => {
    if (service.length) {
        return `'{{properties.useSSL}}' === 'true' && '${service}'.includes('{{properties.service}}')`;
    } else {
        return `'{{properties.useSSL}}' === 'true'`;
    }
};

const getCreateSchemaVisibility = isAppAdapter => {
    if (isAppAdapter) {
        return `'{{properties.hasAutoSchemaConversion}}'==='true'`;
    } else {
        return `'{{properties.hasAutoSchemaConversion}}'==='true' && '{{properties.isAutomatedWizard}}'==='false'`;
    }
};
export const additionalSelectInputProps = {
    isClearable: false,
    scrollIntoView: true
};

const isFileBrowser = config => {
    try {
        return JSON.parse(config)?.type === "FILEBROWSER";
    } catch (err) {
        console.error(err);
        return false;
    }
};

export const sourceFormConfig = {
    //DO-NOT-REMOVE-BELOW-COMMENT
    //add-new-adapter

    YUGABYTE: {
        form_fields: [
            {
                name: "host",
                type: FIELD_TYPES.STRING,
                placeholder: "IP Address or Hostname",
                label: "Hostname",
                required: true
            },
            {
                name: "port",
                type: FIELD_TYPES.NUMBER,
                placeholder: "Example: 5433",
                required: true
            },
            {
                name: "username",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: scott",
                required: true,
                addToModel: true
            },
            {
                name: "password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: P@$$w0rd",
                required: true,
                addToModel: true
            },
            {
                name: "dbName",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: Employee",
                label: "Database Name",
                required: true,
                addToModel: true
            },
            {
                name: "createSchema",
                label: "Create schemas on the target before moving the data",
                type: FIELD_TYPES.CHECKBOX,
                description: `Before proceeding ensure Striim has the appropriate permission the target to required to create the schema, tables and objects`,
                defaultValue: false,
                disabled: false,
                visibility: getCreateSchemaVisibility()
            }
        ],
        cdc_controls: {
            form_fields: [
                {
                    name: "replicationSlot",
                    type: FIELD_TYPES.STRING,
                    placeholder: "Example: test_slot",
                    required: true
                }
            ]
        }
    },
    PAYMO: {
        form_fields: [
            {
                name: "mode",
                label: "Mode",
                type: FIELD_TYPES.SELECT,
                required: true,
                options: [
                    { label: "InitialLoad", value: "InitialLoad" },
                    { label: "IncrementalLoad", value: "IncrementalLoad" },
                    { label: "Automated", value: "Automated" }
                ],
                additionalInputProps: additionalSelectInputProps
            },
            {
                name: "apiKey",
                label: "API key",
                placeholder: "",
                defaultValue: "",
                type: FIELD_TYPES.PASSWORD,
                required: true
            },
            {
                name: "migrateSchema",
                label: "Migrate schema",
                type: FIELD_TYPES.CHECKBOX,
                defaultValue: false,
                visibility: "'{{properties.hasAutoSchemaConversion}}'==='true' && '{{properties.mode}}'==='InitialLoad'"
            }
        ]
    },
    GOOGLEANALYTICS: {
        form_fields: [
            {
                type: "select",
                name: "mode",
                label: "Mode",
                options: [
                    { label: "InitialLoad", value: "InitialLoad" },
                    { label: "IncrementalLoad", value: "IncrementalLoad" },
                    { label: "Automated", value: "Automated" }
                ],
                required: true
            },
            {
                name: "migrateSchema",
                label: "Migrate schema",
                placeholder: "",
                defaultValue: false,
                type: "boolean",
                required: true,
                visibility: "'{{properties.mode}}'==='InitialLoad'"
            },
            {
                name: "autoTokenRenewal",
                label: "Auto Token Renewal",
                placeholder: "",
                defaultValue: true,
                type: "boolean",
                required: true
            },
            {
                type: "string",
                name: "clientId",
                label: "Client ID",
                required: true,
                visibility: "'{{properties.autotokenrenewal}}'==='true'"
            },
            {
                type: "password",
                name: "clientSecret",
                label: "Client Secret",
                required: true,
                visibility: "'{{properties.autotokenrenewal}}'==='true'"
            },
            {
                name: "refreshToken",
                label: "Refresh Token",
                placeholder: "",
                defaultValue: "",
                type: "password",
                required: true,
                visibility: "'{{properties.autotokenrenewal}}'==='true'"
            },
            {
                name: "accessToken",
                label: "Access Token",
                placeholder: "",
                defaultValue: "",
                type: "password",
                required: true,
                visibility: "'{{properties.autotokenrenewal}}'==='false'"
            },
            {
                name: "propertyId",
                label: "Property ID",
                placeholder: "",
                defaultValue: "",
                type: "string",
                required: true
            }
        ]
    },
    LINKEDINADS: {
        form_fields: [
            {
                type: "select",
                name: "mode",
                label: "Mode",
                options: [
                    { label: "InitialLoad", value: "InitialLoad" },
                    { label: "IncrementalLoad", value: "IncrementalLoad" },
                    { label: "Automated", value: "Automated" }
                ],
                required: true
            },
            {
                type: "string",
                name: "clientId",
                label: "Client ID",
                required: true,
                visibility: "'{{properties.autotokenrenewal}}'==='true'"
            },
            {
                type: "password",
                name: "clientSecret",
                label: "Client Secret",
                required: true,
                visibility: "'{{properties.autotokenrenewal}}'==='true'"
            },
            {
                name: "migrateSchema",
                label: "Migrate schema",
                placeholder: "",
                defaultValue: false,
                type: "boolean",
                required: true,
                visibility: "'{{properties.mode}}'==='InitialLoad'"
            },
            {
                name: "accessToken",
                label: "Access Token",
                placeholder: "",
                defaultValue: "",
                type: "password",
                required: true,
                visibility: "'{{properties.autotokenrenewal}}'==='false'"
            },
            {
                name: "refreshToken",
                label: "Refresh Token",
                placeholder: "",
                defaultValue: "",
                type: "password",
                required: true,
                visibility: "'{{properties.autotokenrenewal}}'==='true'"
            },
            {
                name: "autoTokenRenewal",
                label: "Auto Token Renewal",
                placeholder: "",
                defaultValue: true,
                type: "boolean",
                required: true
            }
        ]
    },
    BAMBOOHR: {
        form_fields: [
            {
                name: "mode",
                label: "Mode",
                type: FIELD_TYPES.SELECT,
                required: true,
                options: [
                    { label: "InitialLoad", value: "InitialLoad" },
                    { label: "IncrementalLoad", value: "IncrementalLoad" },
                    { label: "Automated", value: "Automated" }
                ]
            },
            {
                name: "domain",
                label: "Domain",
                placeholder: "Domain",
                defaultValue: "",
                type: "string",
                required: true
            },
            {
                name: "apiKey",
                label: "API key",
                placeholder: "",
                defaultValue: "",
                type: FIELD_TYPES.PASSWORD,
                required: true
            },
            {
                name: "migrateSchema",
                label: "Migrate schema",
                type: FIELD_TYPES.CHECKBOX,
                defaultValue: false,
                visibility: "'{{properties.hasAutoSchemaConversion}}'==='true' && '{{properties.mode}}'==='InitialLoad'"
            }
        ]
    },
    JIRA: {
        form_fields: [
            {
                name: "authMode",
                label: "Auth mode",
                placeholder: "",
                defaultValue: "APIToken",
                type: FIELD_TYPES.RADIO,
                options: [
                    { label: "Authenticate using API Token", value: "APIToken" },
                    { label: "Authenticate using OAuth Token", value: "OAuth" }
                ],
                required: true
            },
            {
                name: "endpointUrl",
                label: "Endpoint URL",
                placeholder: "",
                defaultValue: "",
                type: "string",
                required: true
            },
            {
                name: "userName",
                label: "Username",
                placeholder: "",
                defaultValue: "",
                type: "string",
                required: true,
                visibility: "'{{properties.authMode}}' === 'APIToken'"
            },
            {
                name: "apiToken",
                label: "API Token",
                placeholder: "",
                defaultValue: "",
                type: FIELD_TYPES.PASSWORD,
                required: true,
                visibility: "'{{properties.authMode}}' === 'APIToken'"
            },
            {
                name: "clientId",
                label: "Client ID",
                defaultValue: "",
                required: true,
                visibility: "'{{properties.authMode}}' === 'OAuth'"
            },
            {
                name: "clientSecret",
                label: "Client Secret",
                type: FIELD_TYPES.PASSWORD,
                defaultValue: "",
                required: true,
                visibility: "'{{properties.authMode}}' === 'OAuth'"
            },
            {
                name: "refreshToken",
                label: "Refresh Token",
                type: FIELD_TYPES.PASSWORD,
                defaultValue: "",
                required: true,
                visibility: "'{{properties.authMode}}' === 'OAuth'"
            },
            {
                name: "migrateSchema",
                type: FIELD_TYPES.CHECKBOX,
                label: "Create schemas on the target before moving the data",
                description:
                    "Before proceeding ensure Striim has the appropriate permissions on the target to required to create the schema, tables and objects",
                defaultValue: true,
                disabled: true
            }
        ]
    },
    STRIPE: {
        form_fields: [
            // {
            //     type: "select",
            //     name: "mode",
            //     label: "Mode",
            //     options: [
            //         { label: "InitialLoad", value: "InitialLoad" },
            //         { label: "IncrementalLoad", value: "IncrementalLoad" }
            //     ],
            //     required: true
            // },
            {
                name: "useConnectionProfile",
                type: FIELD_TYPES.BOOLEAN,
                defaultValue: false
            },
            {
                name: "connectionProfileName",
                label: "Select Stripe Connection Profile",
                type: FIELD_TYPES.STRING,
                defaultValue: "",
                uiconfig: '{"type":"CONNECTIONPROFILE"}',
                alterable: false,
                supportingConnectionProfiles: "[Stripe]",
                required: true,
                visibility: "'{{properties.useConnectionProfile}}' !== 'false'"
            },
            {
                name: "authMode",
                defaultValue: "OAuth",
                type: FIELD_TYPES.RADIO,
                required: true,
                options: [
                    {
                        label: "Authenticate using OAuth Token",
                        value: "OAuth"
                    },
                    {
                        label: "Authenticate using API Key",
                        value: "ApiKey"
                    }
                ],
                visibility: "'{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "clientId",
                label: "Client ID",
                defaultValue: "",
                required: true,
                visibility: "'{{properties.authMode}}' === 'OAuth' && '{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "clientSecret",
                type: FIELD_TYPES.PASSWORD,
                label: "Client Secret",
                defaultValue: "",
                required: true,
                visibility: "'{{properties.authMode}}' === 'OAuth' && '{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "refreshToken",
                type: FIELD_TYPES.PASSWORD,
                label: "Refresh Token",
                defaultValue: "",
                required: true,
                visibility: "'{{properties.authMode}}' === 'OAuth' && '{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "apiKey",
                type: FIELD_TYPES.PASSWORD,
                label: "API Key",
                defaultValue: "",
                required: true,
                visibility:
                    "'{{properties.authMode}}' === 'ApiKey' && '{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                required: false,
                type: "java.lang.Boolean",
                defaultValue: false,
                name: "connectedAccount",
                visibility:
                    "'{{properties.useConnectionProfile}}' === 'false' && '{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                required: true,
                type: "java.lang.String",
                defaultValue: "",
                visibility:
                    "'{{properties.connectedAccount}}'==='true' && '{{properties.useConnectionProfile}}' === 'false' ",
                name: "accountId"
            },
            {
                name: "migrateSchema",
                type: FIELD_TYPES.CHECKBOX,
                label: "Create schemas on the target before moving the data",
                description:
                    "Before proceeding ensure Striim has the appropriate permissions on the target to required to create the schema, tables and objects",
                defaultValue: true,
                disabled: true
            }
        ]
    },
    INTERCOM: {
        form_fields: [
            {
                name: "authMode",
                defaultValue: "OAuth",
                type: FIELD_TYPES.RADIO,
                required: true,
                options: [
                    {
                        label: "Authenticate using OAuth Token",
                        value: "OAuth"
                    },
                    {
                        label: "Authenticate using API Key",
                        value: "APIKey"
                    }
                ]
            },
            {
                name: "apiKey",
                label: "API Key",
                type: FIELD_TYPES.PASSWORD,
                defaultValue: "",
                required: true,
                visibility: "'{{properties.authMode}}' !== 'OAuth'"
            },
            {
                name: "accessToken",
                label: "Access Token",
                type: FIELD_TYPES.PASSWORD,
                defaultValue: "",
                required: true,
                visibility: "'{{properties.authMode}}' === 'OAuth'"
            },
            {
                name: "migrateSchema",
                type: FIELD_TYPES.CHECKBOX,
                label: "Create schemas on the target before moving the data",
                description:
                    "Before proceeding ensure Striim has the appropriate permissions on the target to required to create the schema, tables and objects",
                defaultValue: true,
                disabled: true
            }
        ]
    },
    GOOGLEADS: {
        form_fields: [
            {
                name: "clientCustomerId",
                label: "Client Customer ID",
                type: FIELD_TYPES.STRING,
                defaultValue: "",
                required: true
            },
            {
                name: "managerId",
                label: "Manager ID",
                type: FIELD_TYPES.STRING,
                defaultValue: ""
            },
            {
                name: "isAutoTokenRenewal",
                label: "Auto Token Renewal",
                type: FIELD_TYPES.CHECKBOX,
                defaultValue: true
            },
            {
                name: "clientId",
                label: "Client ID",
                type: FIELD_TYPES.STRING,
                required: true,
                visibility: "'{{properties.isAutoTokenRenewal}}' === 'true'"
            },
            {
                name: "clientSecret",
                label: "Client Secret",
                type: FIELD_TYPES.PASSWORD,
                required: true,
                visibility: "'{{properties.isAutoTokenRenewal}}' === 'true'"
            },
            {
                name: "refreshToken",
                label: "Refresh Token",
                type: FIELD_TYPES.PASSWORD,
                required: true,
                visibility: "'{{properties.isAutoTokenRenewal}}' === 'true'"
            },
            {
                name: "accessToken",
                label: "Access Token",
                type: FIELD_TYPES.PASSWORD,
                required: true,
                visibility: "'{{properties.isAutoTokenRenewal}}' === 'false'"
            },
            {
                name: "migrateSchema",
                type: FIELD_TYPES.CHECKBOX,
                label: "Create schemas on the target before moving the data",
                description:
                    "Before proceeding ensure Striim has the appropriate permissions on the target to required to create the schema, tables and objects",
                defaultValue: true,
                disabled: true
            }
        ]
    },
    ZENDESK: {
        form_fields: [
            {
                name: "useConnectionProfile",
                type: FIELD_TYPES.BOOLEAN,
                defaultValue: false
            },
            {
                name: "connectionProfileName",
                label: "Select Zendesk Connection Profile",
                type: FIELD_TYPES.STRING,
                defaultValue: "",
                uiconfig: '{"type":"CONNECTIONPROFILE"}',
                alterable: false,
                supportingConnectionProfiles: "[Zendesk]",
                required: true,
                visibility: "'{{properties.useConnectionProfile}}' !== 'false'"
            },
            {
                name: "connectionUrl",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: https://subdomain.zendesk.com/",
                label: "Connection URL",
                required: true,
                visibility: "'{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "authMode",
                defaultValue: "OAuth",
                type: FIELD_TYPES.RADIO,
                required: true,
                options: [
                    {
                        label: "Authenticate using Auth Token",
                        value: "OAuth"
                    },
                    {
                        label: "Authenticate using User Credentials",
                        value: "ZendeskCreds"
                    },
                    {
                        label: "Authenticate using Api Token",
                        value: "ApiToken"
                    }
                ],
                visibility: "'{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "access_token",
                type: FIELD_TYPES.PASSWORD,
                label: "Auth Token",
                visibility:
                    "'{{properties.authMode}}' === 'OAuth' && '{{properties.useConnectionProfile}}' === 'false'",
                defaultValue: "",
                required: true
            },
            {
                name: "username",
                type: FIELD_TYPES.STRING,
                label: "Username",
                placeholder: "Example: scott",
                required: true,
                visibility:
                    "'{{properties.authMode}}' !== 'OAuth' && '{{properties.useConnectionProfile}}' === 'false'",
                defaultValue: ""
            },
            {
                name: "password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: p@ssword1234",
                label: "Password",
                required: true,
                visibility:
                    "'{{properties.authMode}}' === 'ZendeskCreds' && '{{properties.useConnectionProfile}}' === 'false'",
                defaultValue: ""
            },
            {
                name: "apiToken",
                type: FIELD_TYPES.PASSWORD,
                label: "Api Token",
                required: true,
                visibility:
                    "'{{properties.authMode}}' === 'ApiToken' && '{{properties.useConnectionProfile}}' === 'false'",
                defaultValue: ""
            },
            {
                name: "migrateSchema",
                type: FIELD_TYPES.CHECKBOX,
                label: "Create schemas on the target before moving the data",
                description:
                    "Before proceeding ensure Striim has the appropriate permissions on the target to required to create the schema, tables and objects",
                defaultValue: true,
                disabled: true
            }
        ]
    },
    HUBSPOT: {
        form_fields: [
            {
                name: "useConnectionProfile",
                type: FIELD_TYPES.BOOLEAN,
                defaultValue: false
            },
            {
                name: "connectionProfileName",
                label: "Select HubSpot Connection Profile",
                type: FIELD_TYPES.STRING,
                defaultValue: "",
                uiconfig: '{"type":"CONNECTIONPROFILE"}',
                alterable: false,
                supportingConnectionProfiles: "[HubSpot]",
                required: true,
                visibility: "'{{properties.useConnectionProfile}}' !== 'false'"
            },
            {
                name: "authMode",
                defaultValue: "OAuth",
                type: FIELD_TYPES.RADIO,
                required: true,
                options: [
                    {
                        label: "Authenticate using OAuth Token",
                        value: "OAuth"
                    },
                    {
                        label: "Authenticate using Private App Token",
                        value: "PrivateAppToken"
                    }
                ],
                visibility: "'{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "clientId",
                label: "Client ID",
                defaultValue: "",
                required: true,
                visibility: "'{{properties.authMode}}' === 'OAuth' && '{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "clientSecret",
                type: FIELD_TYPES.PASSWORD,
                label: "Client Secret",
                defaultValue: "",
                required: true,
                visibility: "'{{properties.authMode}}' === 'OAuth' && '{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "refreshToken",
                type: FIELD_TYPES.PASSWORD,
                label: "Refresh Token",
                defaultValue: "",
                required: true,
                visibility: "'{{properties.authMode}}' === 'OAuth' && '{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "privateAppToken",
                type: FIELD_TYPES.PASSWORD,
                label: "Private App Token",
                defaultValue: "",
                required: true,
                visibility:
                    "'{{properties.authMode}}' === 'PrivateAppToken' && '{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "migrateSchema",
                type: FIELD_TYPES.CHECKBOX,
                label: "Create schemas on the target before moving the data",
                description:
                    "Before proceeding ensure Striim has the appropriate permissions on the target to required to create the schema, tables and objects",
                defaultValue: true,
                disabled: true
            }
        ]
    },
    ORACLE: {
        form_fields: [
            {
                name: "host",
                type: FIELD_TYPES.STRING,
                placeholder: "IP Address or Hostname",
                label: "Hostname",
                required: true
            },
            {
                name: "port",
                type: FIELD_TYPES.NUMBER,
                placeholder: "Example: 1521",
                required: true
            },
            {
                name: "username",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: scott",
                required: true,
                addToModel: true
            },
            {
                name: "password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: P@$$w0rd",
                required: true,
                addToModel: true
            },
            {
                name: "sid",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: ORCL",
                label: "SID",
                required: true,
                addToModel: true
            },
            {
                name: "createSchema",
                label: "Create schemas on the target before moving the data",
                type: FIELD_TYPES.CHECKBOX,
                description: `Before proceeding ensure Striim has the appropriate permission the target to required to create the schema, tables and objects`,
                defaultValue: false,
                disabled: false,
                visibility: getCreateSchemaVisibility()
            }
        ],
        ilcdc_controls: {
            form_fields: [
                {
                    name: "service",
                    type: FIELD_TYPES.SELECT,
                    label: "Where is the database located?",
                    defaultValue: "OnPrem",
                    options: [
                        { label: "OnPrem", value: "OnPrem" },
                        { label: "Amazon RDS", value: "AWS" }
                    ],
                    required: true,
                    addToReview: true,
                    reviewLabel: "Service Type",
                    additionalInputProps: additionalSelectInputProps
                },
                {
                    name: "useSSL",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Connect using SSL",
                    description: `Use SSL to connect`,
                    addToReview: true,
                    reviewLabel: "Use SSL"
                },
                {
                    name: "trustStore",
                    label: "Trust Store",
                    type: "java.lang.String",
                    uiconfig: '{"type":"FILEBROWSER"}',
                    visibility: getSSLVisibility(),
                    required: true,
                    addToTQL: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "trustStoreType",
                    type: FIELD_TYPES.STRING,
                    label: "Trust store type",
                    visibility: getSSLVisibility(),
                    required: true,
                    addToTQL: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "trustStorePassword",
                    type: FIELD_TYPES.PASSWORD,
                    placeholder: "Example: P@$$w0rd",
                    visibility: getSSLVisibility(),
                    required: true,
                    addToTQL: true,
                    style: marginStyle,
                    defaultValue: ""
                },
                {
                    name: "keyStore",
                    label: "Key store",
                    type: "java.lang.String",
                    defaultValue: "",
                    uiconfig: '{"type":"FILEBROWSER"}',
                    visibility: getSSLVisibility(["OnPrem"]),
                    required: true,
                    addToTQL: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "keyStoreType",
                    type: FIELD_TYPES.STRING,
                    label: "Key store type",
                    visibility: getSSLVisibility(["OnPrem"]),
                    required: true,
                    addToTQL: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "keyStorePassword",
                    type: FIELD_TYPES.PASSWORD,
                    placeholder: "Example: P@$$w0rd",
                    visibility: getSSLVisibility(["OnPrem"]),
                    required: true,
                    addToTQL: true,
                    style: marginStyle,
                    defaultValue: ""
                }
            ]
        }
    },
    POSTGRES: {
        form_fields: [
            {
                name: "host",
                type: FIELD_TYPES.STRING,
                placeholder: "IP Address or Hostname",
                label: "Hostname",
                required: true
            },
            {
                name: "port",
                type: FIELD_TYPES.NUMBER,
                placeholder: "Example: 5432",
                required: true
            },
            {
                name: "username",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: scott",
                required: true,
                addToModel: true
            },
            {
                name: "password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: P@$$w0rd",
                required: true,
                addToModel: true
            },
            {
                name: "dbName",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: Employee",
                label: "Database Name",
                required: true,
                addToModel: true
            },
            {
                name: "createSchema",
                label: "Create schemas on the target before moving the data",
                type: FIELD_TYPES.CHECKBOX,
                description: `Before proceeding ensure Striim has the appropriate permission the target to required to create the schema, tables and objects`,
                defaultValue: false,
                disabled: false,
                visibility: getCreateSchemaVisibility()
            }
        ],
        ilcdc_controls: {
            form_fields: [
                {
                    name: "service",
                    type: FIELD_TYPES.SELECT,
                    label: "Where is the database located?",
                    defaultValue: "OnPrem",
                    options: [
                        { label: "GCP Instance", value: "GCP" },
                        { label: "RDS Instance", value: "RDS" },
                        { label: "MS Azure", value: "Azure" },
                        { label: "OnPrem", value: "OnPrem" }
                    ],
                    required: true,
                    addToReview: true,
                    reviewLabel: "Service Type",
                    additionalInputProps: additionalSelectInputProps
                },
                {
                    name: "useSSL",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Connect using SSL",
                    description: `Use SSL to connect`,
                    addToReview: true
                },
                {
                    name: "sslmode",
                    type: FIELD_TYPES.STRING,
                    label: "SSL Mode",
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "sslcert",
                    label: "SSL Certificate",
                    type: "java.lang.String",
                    defaultValue: "",
                    uiconfig: '{"type":"FILEBROWSER"}',
                    visibility: getSSLVisibility(["GCP", "OnPrem"]),
                    required: true,
                    style: marginStyle,
                    addToReview: true
                },
                {
                    name: "sslkeypk",
                    label: "SSL Certificate Key (in .pk8 format)",
                    type: "java.lang.String",
                    uiconfig: '{"type":"FILEBROWSER"}',
                    visibility: getSSLVisibility(["GCP", "OnPrem"]),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    reviewLabel: "SSL Certificate Key",
                    defaultValue: ""
                },
                {
                    name: "sslrootcert",
                    label: "SSL Root Certificate",
                    type: "java.lang.String",
                    defaultValue: "",
                    uiconfig: '{"type":"FILEBROWSER"}',
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                }
            ]
        },
        cdc_controls: {
            form_fields: [
                {
                    name: "replicationSlot",
                    type: FIELD_TYPES.STRING,
                    placeholder: "Example: test_slot",
                    required: true
                }
            ]
        }
    },
    OJET: {
        form_fields: [
            {
                name: "host",
                type: FIELD_TYPES.STRING,
                label: "Hostname",
                placeholder: "IP Address or Hostname",
                required: true
            },
            {
                name: "port",
                type: FIELD_TYPES.NUMBER,
                label: "Port",
                placeholder: "Example: 1521",
                required: true
            },
            {
                name: "username",
                type: FIELD_TYPES.STRING,
                label: "Username",
                placeholder: "Example: scott",
                required: true,
                addToModel: true
            },
            {
                name: "password",
                type: FIELD_TYPES.PASSWORD,
                label: "Password",
                placeholder: "Example: P@$$w0rd",
                required: true,
                addToModel: true
            },
            {
                name: "sid",
                type: FIELD_TYPES.STRING,
                label: "SID",
                placeholder: "Example: ORCL",
                required: true,
                addToModel: true
            },
            {
                name: "service",
                type: FIELD_TYPES.SELECT,
                label: "Where is the database located?",
                defaultValue: "OnPrem",
                options: [
                    { label: "OnPrem", value: "OnPrem" },
                    { label: "Amazon RDS", value: "AWS" }
                ],
                required: true,
                addToReview: true,
                reviewLabel: "Service Type",
                additionalInputProps: additionalSelectInputProps
            },
            {
                name: "supportDownStreamCapture",
                type: FIELD_TYPES.CHECKBOX,
                label: "Downstream Capture",
                description: `If enabled, OJet will capture changes from primary source database. Please specify
                the required connection details for the primary source database.`,
                required: false,
                addToModel: true
            },
            {
                name: "primaryDatabaseHostname",
                type: FIELD_TYPES.STRING,
                label: "Primary Database Hostname",
                placeholder: "IP Address or Hostname",
                required: true,
                visibility: "'{{properties.supportDownStreamCapture}}' === 'true'",
                defaultValue: "",
                addToModel: true
            },
            {
                name: "primaryDatabasePort",
                type: FIELD_TYPES.NUMBER,
                label: "Primary Database Port",
                placeholder: "Example: 1521",
                required: true,
                visibility: "'{{properties.supportDownStreamCapture}}' === 'true'",
                defaultValue: "",
                addToModel: true
            },
            {
                name: "primaryDatabaseUsername",
                type: FIELD_TYPES.STRING,
                label: "Primary Database Username",
                placeholder: "Example: scott",
                required: true,
                visibility: "'{{properties.supportDownStreamCapture}}' === 'true'",
                defaultValue: "",
                addToModel: true
            },
            {
                name: "primaryDatabasePassword",
                type: FIELD_TYPES.PASSWORD,
                label: "Primary Database Password",
                placeholder: "Example: P@$$w0rd",
                required: true,
                visibility: "'{{properties.supportDownStreamCapture}}' === 'true'",
                defaultValue: "",
                addToModel: true
            },
            {
                name: "primaryDatabaseServiceName",
                type: FIELD_TYPES.STRING,
                label: "Primary Database Service Name",
                placeholder: "Example: ORCL",
                required: true,
                visibility: "'{{properties.supportDownStreamCapture}}' === 'true'",
                defaultValue: "",
                addToModel: true
            },
            {
                name: "downstreamCaptureMode",
                type: FIELD_TYPES.SELECT,
                label: "Downstream Capture Mode",
                options: [
                    { label: "REAL_TIME", value: "REAL_TIME" },
                    { label: "ARCHIVED_LOG", value: "ARCHIVED_LOG" }
                ],
                visibility: "'{{properties.supportDownStreamCapture}}' === 'true'",
                addToModel: true,
                required: true,
                additionalInputProps: additionalSelectInputProps
            }
        ],
        ilcdc_controls: {
            form_fields: [
                {
                    name: "useSSL",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Connect using SSL",
                    description: `Use SSL to connect`
                },
                {
                    name: "serverCertificateDomain",
                    type: FIELD_TYPES.STRING,
                    placeholder: "Server Certificate Domain",
                    label: "Server Certificate Domain",
                    visibility: getSSLVisibility(),
                    required: true,
                    addToTQL: true,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "walletLocation",
                    type: FIELD_TYPES.FILE,
                    label: "Wallet Location",
                    visibility: getSSLVisibility(),
                    required: true,
                    addToTQL: true,
                    addToReview: true,
                    defaultValue: ""
                }
            ]
        }
    },
    SQLSERVER: {
        form_fields: [
            {
                name: "host",
                type: FIELD_TYPES.STRING,
                placeholder: "IP Address or Hostname",
                label: "Hostname",
                required: true
            },
            {
                name: "port",
                type: FIELD_TYPES.NUMBER,
                placeholder: "Example: 1433",
                required: true
            },
            {
                name: "username",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: scott",
                required: true,
                addToModel: true
            },
            {
                name: "password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: P@$$w0rd",
                required: true,
                addToModel: true
            },
            {
                name: "dbName",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: Employee",
                label: "Database Name",
                required: true,
                addToModel: true
            },
            {
                name: "createSchema",
                label: "Create schemas on the target before moving the data",
                type: FIELD_TYPES.CHECKBOX,
                description: `Before proceeding ensure Striim has the appropriate permission the target to required to create the schema, tables and objects`,
                defaultValue: false,
                disabled: false,
                visibility: getCreateSchemaVisibility()
            }
        ],
        ilcdc_controls: {
            form_fields: [
                {
                    name: "service",
                    type: FIELD_TYPES.SELECT,
                    label: "Where is the database located?",
                    defaultValue: "OnPrem",
                    options: [
                        { label: "RDS Instance", value: "RDS" },
                        { label: "MS Azure", value: "Azure" },
                        { label: "OnPrem", value: "OnPrem" }
                    ],
                    required: true,
                    addToReview: true,
                    reviewLabel: "Service Type",
                    additionalInputProps: additionalSelectInputProps
                },
                {
                    name: "useSSL",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Connect using SSL",
                    description: `Use SSL to connect`
                },
                {
                    name: "trustServerCertificate",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Trust Server Certificate",
                    visibility: getSSLVisibility(),
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: false
                },
                {
                    name: "integratedSecurity",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Integrated Security",
                    visibility: getSSLVisibility(),
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: false
                },
                {
                    name: "trustStore",
                    label: "Trust Store",
                    type: "java.lang.String",
                    defaultValue: "",
                    uiconfig: '{"type":"FILEBROWSER"}',
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    addToReview: true
                },
                {
                    name: "trustStorePassword",
                    type: FIELD_TYPES.PASSWORD,
                    label: "Trust Store Password",
                    placeholder: "Example: P@$$w0rd",
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    defaultValue: ""
                },
                {
                    name: "hostNameInCertificate",
                    type: FIELD_TYPES.STRING,
                    label: "Host Name in Certificate",
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                }
            ]
        }
    },
    MYSQL: {
        form_fields: [
            {
                name: "host",
                type: FIELD_TYPES.STRING,
                placeholder: "IP Address or Hostname",
                label: "Hostname",
                required: true
            },
            {
                name: "port",
                type: FIELD_TYPES.NUMBER,
                placeholder: "Example: 3306",
                required: true
            },
            {
                name: "username",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: scott",
                required: true,
                addToModel: true
            },
            {
                name: "password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: P@$$w0rd",
                required: true,
                addToModel: true
            },
            {
                name: "createSchema",
                label: "Create schemas on the target before moving the data",
                type: FIELD_TYPES.CHECKBOX,
                description: `Before proceeding ensure Striim has the appropriate permission the target to required to create the schema, tables and objects`,
                defaultValue: false,
                disabled: false,
                visibility: getCreateSchemaVisibility()
            }
        ],
        ilcdc_controls: {
            form_fields: [
                {
                    name: "service",
                    type: FIELD_TYPES.SELECT,
                    label: "Where is the database located?",
                    defaultValue: "OnPrem",
                    options: [
                        { label: "GCP Instance", value: "GCP" },
                        { label: "RDS Instance", value: "RDS" },
                        { label: "MS Azure", value: "Azure" },
                        { label: "OnPrem", value: "OnPrem" }
                    ],
                    required: true,
                    addToReview: true,
                    reviewLabel: "Service Type",
                    additionalInputProps: additionalSelectInputProps
                },
                {
                    name: "useSSL",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Connect using SSL",
                    description: `Use SSL to connect`
                },
                {
                    name: "requireSSL",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Require SSL",
                    visibility: getSSLVisibility(),
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: false
                },
                {
                    name: "verifyServerCertificate",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Verify Server Certificate",
                    visibility: getSSLVisibility(),
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: false
                },
                {
                    name: "trustCertificateKeyStoreUrl",
                    label: "Trust Certificate Key Store Url",
                    type: "java.lang.String",
                    uiconfig: '{"type":"FILEBROWSER"}',
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "trustCertificateKeyStoreType",
                    type: FIELD_TYPES.STRING,
                    label: "Trust Certificate Key Store Type",
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "trustCertificateKeyStorePassword",
                    type: FIELD_TYPES.PASSWORD,
                    label: "Trust Certificate Key Store Password",
                    placeholder: "Example: P@$$w0rd",
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    defaultValue: ""
                },
                {
                    name: "clientCertificateKeyStoreUrl",
                    label: "Client Certificate Key Store Url",
                    type: "java.lang.String",
                    uiconfig: '{"type":"FILEBROWSER"}',
                    visibility: getSSLVisibility(["GCP", "OnPrem"]),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "clientCertificateKeyStoreType",
                    type: FIELD_TYPES.STRING,
                    label: "Client Certificate Key Store Type",
                    visibility: getSSLVisibility(["GCP", "OnPrem"]),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "clientCertificateKeyStorePassword",
                    type: FIELD_TYPES.PASSWORD,
                    label: "Client Certificate Key Store Password",
                    placeholder: "Example: P@$$w0rd",
                    visibility: getSSLVisibility(["GCP", "OnPrem"]),
                    required: true,
                    style: marginStyle,
                    defaultValue: ""
                }
            ]
        }
    },
    MARIADB: {
        form_fields: [
            {
                name: "host",
                type: FIELD_TYPES.STRING,
                placeholder: "IP Address or Hostname",
                label: "Hostname",
                required: true
            },
            {
                name: "port",
                type: FIELD_TYPES.NUMBER,
                placeholder: "Example: 3306 ",
                required: true
            },
            {
                name: "username",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: scott",
                required: true,
                addToModel: true
            },
            {
                name: "password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: P@$$w0rd",
                required: true,
                addToModel: true
            },
            {
                name: "createSchema",
                label: "Create schemas on the target before moving the data",
                type: FIELD_TYPES.CHECKBOX,
                description: `Before proceeding ensure Striim has the appropriate permission the target to required to create the schema, tables and objects`,
                defaultValue: false,
                disabled: false,
                visibility: getCreateSchemaVisibility()
            }
        ],
        ilcdc_controls: {
            form_fields: [
                {
                    name: "service",
                    type: FIELD_TYPES.SELECT,
                    label: "Where is the database located?",
                    defaultValue: "OnPrem",
                    options: [
                        { label: "GCP Instance", value: "GCP" },
                        { label: "RDS Instance", value: "RDS" },
                        { label: "MS Azure", value: "Azure" },
                        { label: "OnPrem", value: "OnPrem" }
                    ],
                    required: true,
                    addToReview: true,
                    reviewLabel: "Service Type",
                    additionalInputProps: additionalSelectInputProps
                }
            ]
        }
    },
    MARIADBXPAND: {
        form_fields: [
            {
                name: "host",
                type: FIELD_TYPES.STRING,
                placeholder: "IP Address or Hostname",
                label: "Hostname",
                required: true
            },
            {
                name: "port",
                type: FIELD_TYPES.NUMBER,
                placeholder: "Example: 3306 ",
                required: true
            },
            {
                name: "username",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: scott",
                required: true,
                addToModel: true
            },
            {
                name: "password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: P@$$w0rd",
                required: true,
                addToModel: true
            },
            {
                name: "createSchema",
                label: "Create schemas on the target before moving the data",
                type: FIELD_TYPES.CHECKBOX,
                description: `Before proceeding ensure Striim has the appropriate permission the target to required to create the schema, tables and objects`,
                defaultValue: false,
                disabled: false,
                visibility: getCreateSchemaVisibility()
            }
        ],
        ilcdc_controls: {
            form_fields: [
                {
                    name: "service",
                    type: FIELD_TYPES.SELECT,
                    label: "Where is the database located?",
                    defaultValue: "OnPrem",
                    options: [
                        { label: "GCP Instance", value: "GCP" },
                        { label: "RDS Instance", value: "RDS" },
                        { label: "MS Azure", value: "Azure" },
                        { label: "OnPrem", value: "OnPrem" }
                    ],
                    required: true,
                    addToReview: true,
                    reviewLabel: "Service Type",
                    additionalInputProps: additionalSelectInputProps
                },
                {
                    name: "useSSL",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Connect using SSL",
                    description: `Use SSL to connect`
                },
                {
                    name: "requireSSL",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Require SSL",
                    visibility: getSSLVisibility(),
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: false
                },
                {
                    name: "verifyServerCertificate",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Verify Server Certificate",
                    visibility: getSSLVisibility(),
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: false
                },
                {
                    name: "trustCertificateKeyStoreUrl",
                    label: "Trust Certificate Key Store Url",
                    type: "java.lang.String",
                    uiconfig: '{"type":"FILEBROWSER"}',
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "trustCertificateKeyStoreType",
                    type: FIELD_TYPES.STRING,
                    label: "Trust Certificate Key Store Type",
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "trustCertificateKeyStorePassword",
                    type: FIELD_TYPES.PASSWORD,
                    label: "Trust Certificate Key Store Password",
                    placeholder: "Example: P@$$w0rd",
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    defaultValue: ""
                },
                {
                    name: "clientCertificateKeyStoreUrl",
                    label: "Client Certificate Key Store Url",
                    type: "java.lang.String",
                    uiconfig: '{"type":"FILEBROWSER"}',
                    visibility: getSSLVisibility(["GCP", "OnPrem"]),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "clientCertificateKeyStoreType",
                    type: FIELD_TYPES.STRING,
                    label: "Client Certificate Key Store Type",
                    visibility: getSSLVisibility(["GCP", "OnPrem"]),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "clientCertificateKeyStorePassword",
                    type: FIELD_TYPES.PASSWORD,
                    label: "Client Certificate Key Store Password",
                    placeholder: "Example: P@$$w0rd",
                    visibility: getSSLVisibility(["GCP", "OnPrem"]),
                    required: true,
                    style: marginStyle,
                    defaultValue: ""
                }
            ]
        }
    },
    MSJET: {
        form_fields: [
            {
                name: "host",
                type: FIELD_TYPES.STRING,
                placeholder: "IP Address or Hostname",
                label: "Hostname",
                required: true
            },
            {
                name: "port",
                type: FIELD_TYPES.NUMBER,
                placeholder: "Example: 1433",
                required: true
            },
            {
                name: "username",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: scott",
                required: true,
                addToModel: true
            },
            {
                name: "password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: P@$$w0rd",
                required: true,
                addToModel: true
            },
            {
                name: "dbName",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: Employee",
                label: "Database Name",
                required: true,
                addToModel: true
            }
        ],
        ilcdc_controls: {
            form_fields: [
                {
                    name: "useSSL",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Connect using SSL",
                    description: `Use SSL to connect`
                },
                {
                    name: "trustServerCertificate",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Trust Server Certificate",
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: false
                },
                {
                    name: "integratedSecurity",
                    type: FIELD_TYPES.CHECKBOX,
                    label: "Integrated Security",
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: false
                },
                {
                    name: "trustStore",
                    label: "Trust Store",
                    type: "java.lang.String",
                    uiconfig: '{"type":"FILEBROWSER"}',
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                },
                {
                    name: "trustStorePassword",
                    type: FIELD_TYPES.PASSWORD,
                    label: "Trust Store Password",
                    placeholder: "Example: P@$$w0rd",
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    defaultValue: ""
                },
                {
                    name: "hostNameInCertificate",
                    type: FIELD_TYPES.STRING,
                    label: "Host Name Certificate",
                    visibility: getSSLVisibility(),
                    required: true,
                    style: marginStyle,
                    addToReview: true,
                    defaultValue: ""
                }
            ]
        }
    },
    MONGODB: {
        form_fields: [
            {
                name: "host",
                type: FIELD_TYPES.STRING,
                placeholder: "IP Address or Hostname",
                label: "Hostname",
                required: true
            },
            {
                name: "port",
                type: FIELD_TYPES.NUMBER,
                placeholder: "Example: 27017 ",
                visibility: "!'{{properties.host}}'.includes('mongodb+srv://')",
                required: true
            },
            {
                name: "username",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: scott",
                required: true
            },
            {
                name: "password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: P@$$w0rd",
                required: true
            }
        ]
    },
    MONGO_COSMOSDB: {
        form_fields: [
            {
                name: "host",
                type: FIELD_TYPES.STRING,
                placeholder: "IP Address or Hostname",
                label: "Hostname",
                required: true
            },
            {
                name: "port",
                type: FIELD_TYPES.NUMBER,
                placeholder: "Example: 1234",
                required: true
            },
            {
                name: "username",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: scott",
                required: true
            },
            {
                name: "password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: P@$$w0rd",
                required: true
            }
        ]
    },
    //TO-DO check cosomos fields
    COSMOSDB: {
        form_fields: [
            {
                name: "serviceEndpoint",
                type: FIELD_TYPES.STRING,
                placeholder: "Service Endpoint",
                label: "Service Endpoint",
                required: true
            },
            {
                name: "accessKey",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: P@$$w0rd",
                required: true
            }
        ]
    },
    SALESFORCE: {
        form_fields: [
            {
                name: "useConnectionProfile",
                type: FIELD_TYPES.BOOLEAN,
                defaultValue: false
            },
            {
                name: "connectionProfileName",
                label: "Select Salesforce Connection Profile",
                type: FIELD_TYPES.STRING,
                defaultValue: "",
                uiconfig: '{"type":"CONNECTIONPROFILE"}',
                alterable: false,
                supportingConnectionProfiles: "[Salesforce]",
                required: true,
                visibility: "'{{properties.useConnectionProfile}}' !== 'false'"
            },
            {
                name: "host",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: https://ap2.salesforce.com",
                label: "API Endpoint",
                required: true,
                visibility: "'{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "authMethod",
                defaultValue: "authToken",
                type: FIELD_TYPES.RADIO,
                required: true,
                options: [
                    {
                        label: "Authenticate using Auth Token",
                        value: "authToken"
                    },
                    {
                        label: "Authenticate using User Credentials",
                        value: "userpass"
                    }
                ],
                visibility: "'{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "authToken",
                label: "Auth Token",
                type: FIELD_TYPES.PASSWORD,
                required: true,
                visibility:
                    "'{{properties.authMethod}}'==='authToken' && '{{properties.useConnectionProfile}}' === 'false'",
                defaultValue: ""
            },
            {
                name: "username",
                label: "Username",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: scott",
                required: true,
                visibility:
                    "'{{properties.authMethod}}'!=='authToken' && '{{properties.useConnectionProfile}}' === 'false'",
                defaultValue: ""
            },
            {
                name: "password",
                label: "Password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: p@ssword1234",
                required: true,
                visibility:
                    "'{{properties.authMethod}}'!=='authToken' && '{{properties.useConnectionProfile}}' === 'false'",
                defaultValue: ""
            },
            {
                name: "consumerKey",
                label: "Consumer Key",
                type: FIELD_TYPES.STRING,
                required: true,
                visibility:
                    "'{{properties.authMethod}}'!=='authToken' && '{{properties.useConnectionProfile}}' === 'false'",
                defaultValue: ""
            },
            {
                name: "consumerSecret",
                label: "Consumer Secret",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: scott",
                required: true,
                visibility:
                    "'{{properties.authMethod}}'!=='authToken' && '{{properties.useConnectionProfile}}' === 'false'",
                defaultValue: ""
            },
            {
                name: "securityToken",
                label: "Security Token",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: scott",
                required: true,
                visibility:
                    "'{{properties.authMethod}}'!=='authToken' && '{{properties.useConnectionProfile}}' === 'false'",
                defaultValue: ""
            },
            {
                name: "migrateSchema",
                type: FIELD_TYPES.CHECKBOX,
                label: "Create schemas on the target before moving the data",
                description:
                    "Before proceeding ensure Striim has the appropriate permissions on the target to required to create the schema, tables and objects",
                defaultValue: true,
                disabled: true
            }
        ]
    },
    SALESFORCEPARDOT: {
        form_fields: [
            {
                name: "consumerKey",
                label: "Consumer Key",
                type: FIELD_TYPES.STRING,
                required: true,
                defaultValue: ""
            },
            {
                name: "consumerSecret",
                label: "Consumer Secret",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: scott",
                required: true,
                defaultValue: ""
            },
            {
                name: "username",
                label: "Username",
                type: FIELD_TYPES.STRING,
                placeholder: "Example: scott",
                required: true,
                defaultValue: ""
            },
            {
                name: "password",
                label: "Password",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: p@ssword1234",
                required: true,
                defaultValue: ""
            },
            {
                name: "securityToken",
                label: "Security Token",
                type: FIELD_TYPES.PASSWORD,
                placeholder: "Example: scott",
                required: true,
                defaultValue: ""
            },
            {
                name: "businessUnitId",
                label: "Business Unit Id",
                type: FIELD_TYPES.STRING,
                required: true,
                defaultValue: ""
            },
            {
                name: "migrateSchema",
                type: FIELD_TYPES.CHECKBOX,
                label: "Create schemas on the target before moving the data",
                description:
                    "Before proceeding ensure Striim has the appropriate permissions on the target to required to create the schema, tables and objects",
                defaultValue: true,
                disabled: true
            }
        ]
    },
    SERVICENOW: {
        form_fields: [
            {
                name: "useConnectionProfile",
                type: FIELD_TYPES.BOOLEAN,
                defaultValue: false
            },
            {
                name: "connectionProfileName",
                label: "Select ServiceNow Connection Profile",
                type: FIELD_TYPES.STRING,
                defaultValue: "",
                uiconfig: '{"type":"CONNECTIONPROFILE"}',
                alterable: false,
                supportingConnectionProfiles: "[ServiceNow]",
                required: true,
                visibility: "'{{properties.useConnectionProfile}}' !== 'false'"
            },
            {
                name: "connectionUrl",
                type: FIELD_TYPES.STRING,
                label: "Connection URL",
                placeholder: "Example:  https://xxxxx.service-now.com",
                required: true,
                visibility: "'{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "username",
                type: FIELD_TYPES.STRING,
                label: "Username",
                placeholder: "Example: scott",
                required: true,
                visibility: "'{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "password",
                type: FIELD_TYPES.PASSWORD,
                label: "Password",
                placeholder: "Example: p@ssword",
                required: true,
                visibility: "'{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "clientId",
                type: FIELD_TYPES.STRING,
                label: "Client ID",
                required: true,
                visibility: "'{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "clientSecret",
                type: FIELD_TYPES.PASSWORD,
                label: "Client Secret",
                required: true,
                visibility: "'{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "migrateSchema",
                type: FIELD_TYPES.CHECKBOX,
                label: "Create schemas on the target before moving the data",
                description:
                    "Before proceeding ensure Striim has the appropriate permissions on the target to required to create the schema, tables and objects",
                defaultValue: true,
                disabled: true
            }
        ]
    },
    BIGQUERY: {
        form_fields: [
            {
                name: "useConnectionProfile",
                type: FIELD_TYPES.BOOLEAN,
                defaultValue: false
            },
            {
                name: "connectionProfileName",
                label: "Select BigQuery Connection Profile",
                type: FIELD_TYPES.STRING,
                defaultValue: "",
                uiconfig: '{"type":"CONNECTIONPROFILE"}',
                alterable: false,
                supportingConnectionProfiles: "[BigQuery]",
                required: true,
                visibility: "'{{properties.useConnectionProfile}}' !== 'false'"
            },
            {
                name: "connectionUrl",
                type: FIELD_TYPES.STRING,
                label: "Connection URL",
                required: true,
                visibility: "'{{properties.useConnectionProfile}}' === 'false'"
            },
            {
                name: "createSchema",
                label: "Create schemas on the target before moving the data",
                type: FIELD_TYPES.CHECKBOX,
                description: `Before proceeding ensure Striim has the appropriate permission the target to required to create the schema, tables and objects`,
                defaultValue: false,
                visibility: "'{{properties.hasAutoSchemaConversion}}'==='true'"
            }
        ]
    },
    SNOWFLAKE: {
        form_fields: [
            {
                name: "connectionProfileName",
                label: "Select Snowflake Connection Profile",
                type: FIELD_TYPES.STRING,
                defaultValue: "",
                uiconfig: '{"type":"CONNECTIONPROFILE"}',
                supportingConnectionProfiles: "[Snowflake]",
                required: true
            },
            {
                name: "createSchema",
                label: "Create schemas on the target before moving the data",
                type: FIELD_TYPES.CHECKBOX,
                description: `Before proceeding ensure Striim has the appropriate permission the target to required to create the schema, tables and objects`,
                defaultValue: false,
                visibility: "'{{properties.hasAutoSchemaConversion}}'==='true'"
            }
        ]
    },
    getFormFields(adapterID, isCDC, isILCDC) {
        let formFields = this[adapterID]?.form_fields || [];

        if (isCDC) {
            let cdc_form_fields = this[adapterID]?.cdc_controls?.form_fields || [];
            formFields = formFields.filter(field => !field?.hideForCDC);
            formFields = [...formFields, ...cdc_form_fields];
        }
        if (isILCDC) {
            let cdc_form_fields = this[adapterID]?.cdc_controls?.form_fields || [];
            let ilcdc_form_fields = this[adapterID]?.ilcdc_controls?.form_fields || [];
            formFields = [...formFields, ...cdc_form_fields, ...ilcdc_form_fields];
        }
        return formFields;
    },
    getReviewFields(adapter) {
        return this[adapter]?.ilcdc_controls?.form_fields
            .filter(field => field?.addToReview)
            .map(({ name, label, reviewLabel, uiconfig, options }) => {
                return {
                    name: name,
                    label: reviewLabel ?? label,
                    isFile: uiconfig && isFileBrowser(uiconfig),
                    options: options
                };
            });
    }
};
