import React from "react";
import Grid from "@mui/material/Grid";
import { StriimTypography, StriimLink, StriimButton } from "@striim/striim-ui";

import MANAGE_STRIIM_ROUTES from "../../../routes.json";
import { ConnectionProfilesListHeader } from "../../";
import { useNavigate } from "react-router";
import { styles } from "./connection-profiles-empty.styles";
import Dictionary from "app/components/common/helpable/online-help-dictionary";

const ConnectionProfilesEmpty = () => {
    const navigate = useNavigate();
    const handleAddConnectionProfileClick = () => {
        navigate(MANAGE_STRIIM_ROUTES.createConnectionProfile);
    };
    return (
        <>
            <ConnectionProfilesListHeader showAddButton={false} />
            <Grid
                container
                display="flex"
                alignItems="center"
                height="100%"
                flex="1"
                data-test-id="connection-profiles-empty"
            >
                <Grid container display="flex" flexDirection="column" alignItems="center">
                    <img
                        src="src/generic/icon/manage-striim/connection-profiles-empty.png"
                        alt="Person next to an empty cart"
                        style={{ ...styles.image }}
                    />
                    <StriimTypography variant="h1" sx={styles.heading}>
                        Add your first connection profile
                    </StriimTypography>
                    <StriimTypography variant="body4" sx={[styles.text, styles.spacing]}>
                        Users can configure reusable connection profiles here.
                    </StriimTypography>
                    <StriimLink
                        href={Dictionary.get()["MANAGE_STRIIM_CONNECTION_PROFILE"].href}
                        target="_blank"
                        sx={styles.spacing}
                    >
                        View Documentation
                    </StriimLink>
                    <StriimButton
                        variant="primary"
                        onClick={handleAddConnectionProfileClick}
                        data-test-id="add-connection-profile-button"
                    >
                        Add Connection Profile
                    </StriimButton>
                </Grid>
            </Grid>
        </>
    );
};

export default ConnectionProfilesEmpty;
