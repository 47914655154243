import React, { useState, useCallback, forwardRef, useImperativeHandle } from "react";
import { Grid, Box, InputAdornment } from "@mui/material";
import { StriimTypography, StriimInputField, StriimButton } from "@striim/striim-ui-v2";
import SearchIcon from "@mui/icons-material/Search";
import styles from "../../table-progress.styles";
import { CollapseAll, DownloadOutlinedBlue } from "../../../../generic/icon/customIcons";
import _ from "underscore";
import useDebounce from "../../../../hooks/useDebounce";
import Papa from "papaparse";
import { saveAs as saveFileAs } from "file-saver";
import { ExpandAll } from "../../../../generic/icon/customIcons";
import { tableProgress } from "../../table-progress-types";
import dateLib from "core/utils/date-utils";
import growl from "../../../../../app/components/common/growl";

const SummaryHeaderContainer = (
    { expandAllRows, sourceAdapters, targetAdapters, store, appType, setExpandAllRows, setRows },
    ref
) => {
    const [selectedSourceAdapters, setSelectedSourceAdapters] = useState<string[]>([]);
    const [selectedTargetAdapters, setSelectedTargetAdapters] = useState<string[]>([]);
    const [searchString, setSearchString] = useState<string>("");

    const handleDownloadCsv = () => {
        try {
            const data = Object.values(store?.tableProgress ?? {});
            const result = [];
            const appName = store?.appModel?.name ?? "";
            const time = dateLib(Date.now()).format("YYYYMMDD_hhmm");
            data.forEach(sourceInfo => {
                const writersInfo = sourceInfo?.writers;
                let sourceData = {
                    "Source Table Name": sourceInfo?.tableName,
                    "Source Component Name": sourceInfo?.sourceComponentName,
                    "Source Events": sourceInfo?.eventsRead ?? 0,
                    "Source Inserts": sourceInfo?.["No of Inserts"] ?? sourceInfo?.["No of inserts"],
                    "Source Updates": sourceInfo?.["No of Updates"] ?? sourceInfo?.["No of updates"],
                    "Source Deletes": sourceInfo?.["No of Deletes"] ?? sourceInfo?.["No of deletes"],
                    "Source PKUpdates": sourceInfo?.["No of PKUpdates"] ?? sourceInfo?.["No of pkupdates"],
                    "Source DDLs": sourceInfo?.["No of DDLs"]
                };
                const writersArray = Object.values(writersInfo ?? {});
                if (writersArray.length > 0) {
                    writersArray.forEach(targetInfo => {
                        const targetData = {
                            "Target Table Name": targetInfo?.tableName,
                            "Target Component Name": targetInfo?.targetComponentName,
                            "Target Events": targetInfo?.eventsWritten ?? 0,
                            "Target Inserts": targetInfo?.["No of Inserts"] ?? targetInfo?.["No of inserts"],
                            "Target Updates": targetInfo?.["No of Updates"] ?? targetInfo?.["No of updates"],
                            "Target Deletes": targetInfo?.["No of Deletes"] ?? targetInfo?.["No of deletes"],
                            "Target PKUpdates": targetInfo?.["No of PKUpdates"] ?? targetInfo?.["No of pkupdates"],
                            "Target DDLs": targetInfo?.["No of DDLs"],
                            "Last Write Time": targetInfo?.["lastWriteTime"]
                        };
                        const rowData = {
                            ...sourceData,
                            ...targetData
                        };
                        result.push(rowData);
                    });
                } else {
                    const rowData = {
                        ...sourceData
                    };
                    result.push(rowData);
                }
            });
            var csv = Papa.unparse(result);
            saveFileAs(
                new Blob([csv], {
                    type: "text/csv"
                }),
                `${appName} Table Progress ${time}.csv`
            );
        } catch (e) {
            console.error("Failed to download CSV file", e);
            growl.error("Failed to download CSV file");
        }
    };
    const filterRows = useCallback(() => {
        const filteredRows = Object.values(store?.tableProgress ?? {}).filter((row: tableProgress) => {
            const sourceAdapterName = row?.sourceComponentName ?? "";
            const targetAdapters = Object.keys(row?.targetAdapters ?? {});
            const tableName = row?.tableName;
            if (
                sourceAdapterName &&
                !_.isEmpty(selectedSourceAdapters) &&
                !selectedSourceAdapters.includes(sourceAdapterName)
            ) {
                return false;
            }
            if (
                !_.isEmpty(selectedTargetAdapters) &&
                _.isEmpty(_.intersection(targetAdapters, selectedTargetAdapters))
            ) {
                return false;
            }
            if (tableName?.toUpperCase().indexOf(searchString?.toUpperCase()) == -1) {
                return false;
            }
            return true;
        });
        setRows(filteredRows);
    }, [selectedSourceAdapters, selectedTargetAdapters, searchString]);
    const setFiltersDebounced = useDebounce(filterRows, 500);

    useImperativeHandle(ref, () => ({
        filterRows
    }));
    return (
        <Grid sx={styles.summaryHeaderContainer}>
            <StriimTypography variant="h4">Table Summary</StriimTypography>
            <Box sx={styles.searchInput}>
                <Grid container gap={1}>
                    {appType === "CDC" && (
                        <>
                            <StriimButton
                                variant="text"
                                onClick={() => setExpandAllRows(prev => !prev)}
                                sx={styles.downloadBtn}
                                startIcon={expandAllRows ? <CollapseAll /> : <ExpandAll />}
                            >
                                {expandAllRows ? "Collapse" : "Expand"} all
                            </StriimButton>
                            <StriimButton
                                variant="text"
                                onClick={handleDownloadCsv}
                                sx={styles.downloadBtn}
                                startIcon={<DownloadOutlinedBlue />}
                            >
                                Download csv
                            </StriimButton>
                        </>
                    )}

                    {Array.isArray(sourceAdapters) && sourceAdapters.length > 1 ? (
                        <Box sx={{ width: "150px" }}>
                            <StriimInputField
                                select
                                SelectProps={{
                                    options: sourceAdapters,
                                    maxMenuHeight: "2000px",
                                    isMulti: true,
                                    components: {
                                        IndicatorSeparator: () => null
                                    }
                                }}
                                placeholder="Source: All"
                                onChange={adapters => {
                                    const adapterList = adapters.map(adapter => adapter.value);
                                    setSelectedSourceAdapters(adapterList);
                                    setFiltersDebounced();
                                }}
                            />
                        </Box>
                    ) : null}
                    {Array.isArray(targetAdapters) && targetAdapters.length > 1 ? (
                        <Box sx={{ width: "150px" }}>
                            <StriimInputField
                                select
                                SelectProps={{
                                    options: targetAdapters,
                                    maxMenuHeight: "2000px",
                                    isMulti: true,
                                    components: {
                                        IndicatorSeparator: () => null
                                    }
                                }}
                                placeholder="Target: All"
                                onChange={adapters => {
                                    const adapterList = adapters.map(adapter => adapter.value);
                                    setSelectedTargetAdapters(adapterList);
                                    setFiltersDebounced();
                                }}
                            />
                        </Box>
                    ) : null}
                    <Box>
                        <StriimInputField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" sx={{ width: "8px" }}>
                                        <SearchIcon opacity={0.45} />
                                    </InputAdornment>
                                )
                            }}
                            placeholder={"Search By Table name"}
                            onChange={tableName => {
                                setSearchString(tableName);
                                setFiltersDebounced();
                            }}
                        />
                    </Box>
                </Grid>
            </Box>
        </Grid>
    );
};

export default forwardRef(SummaryHeaderContainer);
