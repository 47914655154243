export default {
    cardContent: {
        height: "100%",
        border: ({ palette }) => `1px solid ${palette.greyscale["200"]}`,
        borderRadius: 2,
        borderTopLeftRadius: 0,
        padding: 0,
        "&:last-child": {
            padding: 0
        }
    },
    settingsContent: {
        height: 'calc(100% - 85px)',
        overflowY: 'auto',
    }
};
