export const styles = {
    aiText: {
        background: "linear-gradient(90deg, #F31779 0.1%, #7930D9 52.98%, #0C6FE8 99.87%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
    },
    aiIcon: {
        height: "16px",
        width: "auto"
    }
};
