import React, { useState, useCallback } from "react";
import { StriimTypography, StriimModal, StriimButton } from "@striim/striim-ui";
import { UploadDropzone } from "./";
import growl from "../../../../../../app/components/common/growl";


const styles = {
    dialogFooter: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    }
}

const UploadModal = ({
    fetchData,
    setIsModalShown,
    onFileDone,
    directories,
    userDirectory,
    setFileSelectionModalShown
}) => {
    const [filePath, setFilePath] = useState();
    const [selectedDirectory, setSelectedDirectory] = useState(directories[0]);
    const [xhrRequest, setXhrRequest] = useState(null);
    const deleteFile = useCallback(
        async (path, doRedirect = true) => {
            try {
                await fetch("/upload?path=" + encodeURIComponent(path), {
                    method: "DELETE"
                });
                if (doRedirect) {
                    fetchData && (await fetchData());
                }
            } catch (e) {
                fetchData && (await fetchData());
                growl.error("Error deleting file");
            } finally {
                setFilePath(null);
            }
        },
        [fetchData, selectedDirectory]
    );
    const cancelFile = async () => {
        if (xhrRequest && xhrRequest.readyState !== 4) {
            xhrRequest.abort();
        }
        if (filePath) {
            const directoryPath = selectedDirectory?.value?.length ? selectedDirectory?.value : userDirectory;
            await deleteFile(`${directoryPath}/${filePath}`);
            setIsModalShown(false);
        } else {
            setFilePath(null);
            setIsModalShown(false);
        }
    };
    return (
        <StriimModal
            isVisible={true}
            size="medium"
            autoHeight
            onCancel={() => setIsModalShown(false)}
            titleContent={<StriimTypography variant={"h2"}>Upload File</StriimTypography>}
            footerContent={
                <div sx={styles.dialogFooter}>
                    <div>
                        {setFileSelectionModalShown && (
                            <StriimButton variant="secondaryText" data-test-id="back-button" onClick={cancelFile}>
                                Back
                            </StriimButton>
                        )}
                    </div>
                    <div>
                        <StriimButton
                            variant="secondaryText"
                            data-test-id="cancel-button"
                            onClick={async () => {
                                await cancelFile();
                                setFileSelectionModalShown && setFileSelectionModalShown(false);
                            }}
                        >
                            Cancel
                        </StriimButton>

                        <StriimButton
                            data-test-id="done-button"
                            disabled={!filePath}
                            variant="primary"
                            onClick={async () => {
                                fetchData && (await fetchData());
                                setIsModalShown(false);
                            }}
                            sx={{
                                ml: 1
                            }}
                        >
                            Done
                        </StriimButton>
                    </div>
                </div>
            }
        >
            <UploadDropzone
                deleteFile={deleteFile}
                setFilePath={setFilePath}
                onFileDone={onFileDone}
                directories={directories}
                selectedDirectory={selectedDirectory}
                setSelectedDirectory={setSelectedDirectory}
                userDirectory={userDirectory}
                xhrRequest={xhrRequest}
                setXhrRequest={setXhrRequest}
            />
        </StriimModal>
    );
};

export default UploadModal;
