import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import NestedTypes from "backbone.nestedtypes";

var baseUrl = "https://go2.striim.com/";

// server may return data like this: 3.8.2-postfix, however we only need the major version
function getMajorVersion() {
    var currentServer = propertyTemplateService.servers.first();
    return currentServer.get("version").split("-")[0];
}

function getVersionUrl() {
    var currentMajorVersion = getMajorVersion();
    return currentMajorVersion.replace(/\./g, "-");
}

function getUrl(path) {
    return baseUrl + path + "#" + getVersionUrl();
}

export default NestedTypes.Model.extend({
    defaults: {
        version: String,
        agentDownloadUrl: String,
        azureSupportUrl: String,
        smsgc: Boolean
    },
    constructor: function() {
        NestedTypes.Model.apply(this, arguments);
        this.version = getMajorVersion();
        this.agentDownloadUrl = getUrl("download-page");
        this.azureSupportUrl = getUrl("request-support-azure");
    }
});
