import $ from "jquery";
import _ from "underscore";
import Marionette from "marionette";
import api from "core/api/api";
import MarionetteWrapper from "app/components/marionette-wrapper-for-react";
import FileUploadWrapper from "./file-upload-wrapper";

const template = `<div class="drivers-check">
    <h3>Install drivers</h3>
    <div>Download the driver from <a href="https://www.oracle.com/database/technologies/appdev/jdbc-downloads.html" class="download-link"  rel="noopener noreferrer" target="_blank">oracle.com</a></div>
    <form id='my-form' enctype="multipart/form-data"></form>
</div>`;

const DbTypeMySQL = "MySQL";
const DbTypeMariaDBXpand = "MariaDBXpand";
const DbTypeMariaDB = "MariaDB";

export const getDbType = moduleData => {
    switch (moduleData?.customOptions?.dbType) {
        case "Oracle":
            return "Oracle";
        case "OJet":
            return "OJet";
        case "MySQL":
            return DbTypeMySQL;
        case "MariaDBXpand":
            return DbTypeMariaDBXpand;
        case "MariaDB":
            return DbTypeMariaDB;
        default:
            return null;
    }
};

const getCheckDriverApi = dbType => {
    switch (dbType) {
        case "Oracle":
        case "OJet":
            return api.OracleCheckDriver;
        case "MySQL":
            return api.MYSQLCheckDriver;
        case "MariaDBXpand":
            return api.MariaDBXpandCheckDriver;
        case DbTypeMariaDB:
            return api.MariaDBCheckDriver;
        default:
            return null;
    }
};

export const getLoadDriverApi = dbType => {
    switch (dbType) {
        case "Oracle":
        case "OJet":
            return api.OracleLoadDriver;
        case "MySQL":
            return api.MYSQLLoadDriver;
        case "MariaDBXpand":
            return api.MariaDBXpandLoadDriver;
        default:
        case DbTypeMariaDB:
            return api.MariaDBLoadDriver;
    }
};

export const checkDrivers = moduleData => {
    // test UI with fake flag
    if (window._fakeWizardDriversCheck) {
        return new Promise(resolve => {
            resolve(false);
        });
    }

    const dbType = getDbType(moduleData);
    if (!dbType) {
        return new Promise(resolve => {
            resolve(true);
        });
    }

    const checkDriverApi = getCheckDriverApi(dbType);
    if (checkDriverApi) {
        try {
            return new Promise(resolve => {
                checkDriverApi
                    .bind(api)("")
                    .then(result => {
                        resolve(result === "true");
                    })
                    .fail(e => {
                        console.log(e);
                        resolve(false);
                    });
            });
        } catch (e) {
            resolve(false);
            return;
        }
    }

    //TODO: CALL API WITH DRIVERS CHECK METHOD
    return new Promise(resolve => {
        resolve(true);
    });
};

const view = Marionette.LayoutView.extend({
    template: _.template(template),
    className: "hidden-element",

    ui: {
        downloadLink: ".download-link"
    },

    regions: {
        uploadZone: "#my-form"
    },

    uploadBox: function() {
        const DropZone = MarionetteWrapper(FileUploadWrapper, {
            moduleData: this.options.moduleData
        });
        this.getRegion("uploadZone").show(DropZone);
    },

    onRender: function() {
        const _this = this;
        this.uploadBox();
        checkDrivers(this.options.moduleData).then(result => {
            if (!result) {
                const dbType = getDbType(this.options.moduleData);
                if (dbType === DbTypeMySQL) {
                    this.ui.downloadLink.attr("href", "https://dev.mysql.com/downloads/connector/j/5.1.html");
                    this.ui.downloadLink.html("dev.mysql.com");
                } else if (dbType === DbTypeMariaDBXpand) {
                    this.ui.downloadLink.attr("href", "https://mvnrepository.com");
                    this.ui.downloadLink.html("mvnrepository.com");
                } else if (dbType === DbTypeMariaDB) {
                    this.ui.downloadLink.attr("href", "https://mvnrepository.com");
                    this.ui.downloadLink.html("mvnrepository.com");
                }
                _this.$el.removeClass("hidden-element");
            }
        });
    }
});

export default view;
