import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var module = {};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "USER",
        name: String,
        isActive: null
    }
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model
});

export default module;
