import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./text-area.html";
import xss from 'xss';

const Model = BaseControl.Model.extend({
    defaults: {
        value: String,
    },
});

const View = BaseControl.View.extend({
    template: _.template(template),
    events: {
        "keyup textarea": "setValueFromView",
        "change textarea": "setValueFromView",
    },

    getViewValue: function () {
        return this.$("textarea").val();
    },

    setViewValue: function () {
        let val = this.getValue();
        if (!this.skipXss) {
            val = xss(val);
        }
        this.$("textarea").val(val);
    },

    setViewEnabled: function () {
        BaseControl.View.prototype.setViewEnabled.apply(this);
        this.$el.find("textarea").prop("disabled", !this.getEnabled());
    },
    setPlaceholder(placeholder) {
        this.$el.find("textarea").attr("placeholder", placeholder);
    },
    onRender: function () {
        let $input = this.$("textarea");

        // value can't be set directly in the template, because it can contains
        // special characters like '"' which can break the template
        let value = this.model.value;
        if (!this.skipXss) {
            value = xss(value);
        }
        $input.val(value);
        let placeholder = this.placeholder;
        if (!placeholder) {
            if (this.customViewOptions) {
                placeholder = this.customViewOptions.placeholder;
            }
        }

        if (placeholder) {
            $input.attr("placeholder", placeholder);
        }
    },
});

function buildTextField() {
    return _.extend({}, BaseControl, {
        View: View,
        Model: Model,
        WithPlaceholder: function (placeholder) {
            return buildTextField().extend({
                placeholder: placeholder,
            });
        },
    });
}

export default buildTextField();
