import { makeStyles } from "@material-ui/core/styles";

export const useAppContentStyles = makeStyles(() => ({
    pathInput: {
        display: "none"
    }
}));

export const styles = {
    quickGroupContainer: ({ palette, spacing }) => ({
        "& #template-item-subtitle": {
            fontSize: spacing(2) + " !important",
            lineHeight: spacing(3) + " !important",
            fontWeight: "400 !important",
            fontFamily: '"Inter","Helvetica","Arial",sans-serif !important',
            color: palette.secondary.main + " !important"
        },
        "& #template-card": {
            width: "auto !important",
            height: spacing(15.5) + " !important"
        },
        "& #template-item-content": {
            gap: spacing(1) + " !important"
        },
        "& hr": {
            display: "none !important"
        },
        "& #template-group-title": {
            display: "none !important"
        },
        "& #chip-container": {
            display: "none !important"
        }
    })
};
