import App from "app";
import {
    template,
    ui
} from "core/decorators";
import navigateTo from "src/navigate-to";
import MetaObjects from "core/services/metaStoreService/meta-objects";
import { ItemView } from "marionette";
import topSectionContainer from "./templates/top-section-template.html";

@template(topSectionContainer)
@ui({
    addButton: ".add-alert"
})
export class TopSection extends ItemView {
    onRender() {
        const that = this;
        this.ui.addButton.click(function() {
            let model = new MetaObjects.SYSALERTRULE.Model();
            App.vent.trigger("alertmanager:show:detail", model);
            if (!that.model.get("isLiteMode")) {
                navigateTo.AddNewAlert();
            }
        });
    };
}
