import { alpha } from "@material-ui/core/styles/colorManipulator";

const black = "#0D1417";
const white = "#FEFEFF";

/**
 * @todo
 *  This file and palette is never actually used. Should we just deprecate this?
 *  The actual theme applied is the striim theme from striim-ui library
 *
 */

export default {
    common: {
        black,
        white
    },
    primary: {
        /**Buttons etc. **/
        contrastText: white,
        main: "#00A7E5"
    },
    secondary: {
        /** Used for selection controls in material design and FAB (which we don't have) **/
        contrastText: white,
        main: "#00a7e5"
    },
    success: {
        contrastText: white,
        main: "#0D874C"
    },
    info: {
        /**At the moment we don't have info bars, but can be used in the future**/
        contrastText: "#22343B",
        main: white
    },
    warning: {
        main: "#F9A825",
        light: "#FEF2DF"
    },
    danger: {
        contrastText: white,
        main: "#DD3711"
    },
    text: {
        primary: "#0D1417" /**Text on primary color**/,
        secondary: "#4E5C62" /**Text on secondary color**/,
        disabled: alpha("#0D1417", 0.45)
    },
    background: {
        default: "#FAFBFF",
        dark: "#010A30",
        paper: white /**Surfaces like cards etc. **/,
        lightGrey: "#F5F6FC" /** Surface/Canvas **/,
        grey: "#F7F8F8"
    },
    border: "#E1E1E1",
    divider: "#E1E1E1",
    gray: "#BCC2C4",
    purple: "#541388"
};
