import FlowDatasource from "./CachedFlowDatasource";
import api from "core/api/api";
import _ from "underscore";

function buildGraph(componentId, componentsList) {
    function findParents(id, current) {
        let result = [];
        let i = 0;
        while (i < componentsList.length) {
            let c = componentsList[i];

            if (!c.targets || c.targets.length === 0) {
                componentsList.splice(i, 1);
                continue;
            }

            if (
                c.targets
                    .map((t) => {
                        return t.id;
                    })
                    .indexOf(id) !== -1
            ) {
                let parent = {
                    id: c.id,
                    parents: [],
                };
                current.parents.push(parent);
                componentsList.splice(i, 1);
                findParents(c.id, parent);
            } else {
                i++;
            }
        }
        return result;
    }

    let resultGraph = {
        parents: [],
    };
    findParents(componentId, resultGraph);
    return resultGraph;
}

/**
 *  find graph parents
 * @param parentsTree - graph { id: string, parents: [] }
 * @returns {Array} - root nodes
 */
function getRoots(parentsTree) {
    let result = [];

    function findRoot(parents) {
        parents.forEach((p) => {
            if (p.parents.length === 0) {
                result.push(p);
            } else {
                findRoot(p.parents);
            }
        });
    }

    findRoot(parentsTree.parents);

    return result;
}

function filterComponentsByAdapters(metaobjectList, adapterTypes) {
    return metaobjectList.filter((c) => {
        return (
            c.adapter &&
            adapterTypes.some((a) => {
                return c.adapter.handler.indexOf(a) !== -1;
            })
        );
    });
}

const DB_READERS = ["MysqlReader", "OracleReader", "MSSqlReader"];

function filterDbSources(metaobjectList) {
    return filterComponentsByAdapters(metaobjectList, DB_READERS);
}

/** @function loadAscendantsSources
 * load list of ascendants components of type source
 * @param componentId
 */
function loadAscendantsSources(componentId) {
    return new Promise((resolve) => {
        api.getObjectParentApplications(componentId).then(function (applications) {
            let apps = _.keys(applications);
            if (apps.length === 0) {
                return;
            }
            let applicationId = apps[0];
            loadComponentAscendants(componentId, applicationId, "SOURCE").then((result) => {
                resolve(result);
            });
        });
    });
}

/** @function loadAscendantsSources
 * load list of ascendants components of type source,
 * where source adapter is one of the DB type
 * @param componentId
 */
function loadAscendantsDbSources(componentId) {
    return new Promise((resolve) => {
        loadAscendantsSources(componentId).then((result) => {
            let dbReaders = filterDbSources(result, DB_READERS);
            resolve(dbReaders);
        });
    });
}

export { filterComponentsByAdapters, filterDbSources, loadAscendantsSources, loadAscendantsDbSources };

function filterComponentsByType(rootComponents, targetComponentType) {
    let filderedComponents = rootComponents.filter((c) => {
        return c.type === targetComponentType;
    });
    return filderedComponents;
}

/** @function loadComponentAscendants
 * load list of component ascendants
 *  @param {string} componentId - Id of the component for which we are looking for ascendants
 *  @param {string} applicationName - Id of an application with namespace.applicationName format
 *  @param {string} targetComponentType - type of the target component to search
 *  @returns {array} - list of components
 */
export default function loadComponentAscendants(componentId, applicationId, targetComponentType) {
    let flowDatasource = new FlowDatasource({
        appName: applicationId,
    });

    return new Promise((resolve) => {
        flowDatasource.load().then(function () {
            let componentsList = flowDatasource.graphModel;
            let graph = buildGraph(componentId, componentsList.slice());
            let roots = getRoots(graph);
            let rootGraphNodes = roots.map((c) => {
                return flowDatasource.findNodeById(c.id);
            });

            // get and load external nodes
            let rootComponents = rootGraphNodes.map((c) => {
                return c.metaObject;
            });

            let externalComponents = rootGraphNodes.filter((c) => {
                return c.isExternal && !c.isMissing;
            });

            if (externalComponents.length === 0) {
                let filderedComponents = filterComponentsByType(rootComponents, targetComponentType);
                resolve(filderedComponents);
                return;
            }

            let promises = [];
            externalComponents.forEach((externalComponent) => {
                let promise = new Promise((externalResolve) => {
                    api.getObjectParentApplications(externalComponent.id).then(function (applications) {
                        applications = _.keys(applications);
                        if (applications.length === 0) {
                            externalResolve();
                            return;
                        }

                        let app = applications[0];
                        loadComponentAscendants(externalComponent.id, app, targetComponentType).then(
                            (externalRoots) => {
                                rootComponents = [...rootComponents, ...externalRoots];
                                externalResolve();
                            }
                        );
                    });
                });
                promises.push(promise);
            });

            Promise.all(promises).then(function () {
                let filderedComponents = filterComponentsByType(rootComponents, targetComponentType);
                resolve(filderedComponents);
            });
        });
    });
}
