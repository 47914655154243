interface CollapsibleOptions {
    accordion?: boolean;
}
export function initMaterialDesignCollapsible(containers: NodeListOf<HTMLElement>, options?: CollapsibleOptions) {
    if (!options) {
        options = {
            accordion: true
        };
    }

    const result: any[] = [];

    containers.forEach(container => {
        const groupHandler = {
            open: (index: number) => {
                const groupEl = container.querySelectorAll(":scope>li")[index];

                if (!groupEl) return;

                const isCurrentGroupActive = groupEl.classList.contains("active");

                groupEl.classList.toggle("active");
                const groupContent = groupEl.querySelector(".collapsible-body") as HTMLElement;

                if (groupContent) {
                    if (groupEl.classList.contains("active")) {
                        groupContent.style.display = "block";
                    } else {
                        groupContent.style.display = "none";
                    }
                }

                if (options.accordion) {
                    // close other groups only when current group is not active
                    if (!isCurrentGroupActive) {
                        container.querySelectorAll(":scope>li").forEach(otherGroup => {
                            if (otherGroup !== groupEl) {
                                otherGroup.classList.remove("active");
                                const otherGroupContent = otherGroup.querySelector(".collapsible-body") as HTMLElement;
                                if (otherGroupContent) {
                                    otherGroupContent.style.display = "none";
                                }
                            }
                        });
                    }
                }
            }
        };
        result.push(groupHandler);

        container.querySelectorAll(":scope>li .group-header").forEach((groupEl, index) => {
            function onClick(evt: Event) {
                groupHandler.open(index);
                evt.stopPropagation();
                evt.preventDefault();
                evt.stopImmediatePropagation();
            }
            groupEl.removeEventListener("click", onClick);
            groupEl.addEventListener("click", onClick);
        });
    });

    return result;
}
