import React from "react";
import ReactDOM from "react-dom/client";
import _ from "underscore";
import Backbone from "backbone";
import { StriimTheme } from "@striim/striim-ui";
import SentinelPanel from "../../../../../src/modules/ai-insights/sentinel/panel/sentinel-panel-container.tsx";
import api from "../../../../../core/api/api.js";
import metaStoreService from "../../../../../core/services/metaStoreService/meta-store-service.js";
import App from "app";
import { isUserAdmin } from "../../../../../core/user-management/update.control.js";

const SentinelSidebarView = Backbone.Marionette.LayoutView.extend({
    template: _.template(`<div>   </div>`),

    initialize: function() {},

    getViewValue: function() {},

    setViewValue: function() {},

    onShow: async function() {
        const appModel = this.options.appModel;
        const nodeModel = this.options.nodeModel;
        const onViewActivityClick = this.options.onViewActivityClick;
        const flowStatus = appModel.get("flowStatus");
        const entityDataFromSource = this.options.nodeModel.entityDataFromSource;
        try {
            if (!this.root) {
                this.root = ReactDOM.createRoot(this.$el[0]);
            }
            const streams = await appModel.getApplicationComponentsByType("STREAM");
            const sources = await appModel.getApplicationComponentsByType("SOURCE");
            const streamOptions = streams.map(item => ({
                label: item.name,
                value: item.id
            }));
            let currentNodeResponse;
            if (nodeModel.id !== "temp-node") {
                currentNodeResponse = await api._CRUDHandler("", "READ", {
                    id: nodeModel.id,
                    type: metaStoreService.entities.SENTINEL
                });
            }

            this.root.render(
                <StriimTheme preselector=".striim.materialize.light:not(#\20)">
                    <SentinelPanel
                        toggleFullScreen={() => {
                            this.trigger("full-screen-sentinel");
                        }}
                        onCancel={() => {
                            this.trigger("cancel-sentinel");
                        }}
                        onDelete={() => {
                            this.trigger("delete-sentinel");
                        }}
                        setSentinelFormDirty={isDirty => {
                            this.trigger("isDirty", isDirty);
                        }}
                        onSave={(id, outputStream) => {
                            this.trigger("sentinel-save", {
                                id: id,
                                nodeTargets: _.map(appModel?.targets, _.clone),
                                outputStream: outputStream
                            });
                        }}
                        validateOnSave={(id, errorsCount) => {
                            App.vent.trigger("component:form-validation", {
                                modelId: id ?? "temp-node",
                                errorsCount: errorsCount
                            });
                        }}
                        onViewActivityClick={onViewActivityClick}
                        streams={streamOptions}
                        sources={sources}
                        appName={appModel?.name}
                        nsName={appModel?.nsName}
                        metaObjectType={nodeModel?.metaObject?.type}
                        parentStream={nodeModel?.parentNode?.id ?? ""}
                        savedSentinelObject={currentNodeResponse?.[0]}
                        flowStatus={flowStatus}
                        isAdmin={isUserAdmin()}
                        entityDataFromSource={entityDataFromSource}
                        isSaved={!!currentNodeResponse?.[0]}
                    />
                </StriimTheme>
            );
        } catch (e) {
            console.error("Error rendering", e);
        }
    },

    onBeforeDestroy() {
        if (this.root) {
            this.root.unmount();
        }
    }
});

export default SentinelSidebarView;
