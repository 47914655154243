import App from "app";
import api from "core/api/api";
import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import navigateTo from "src/navigate-to";
import ignoredPatternsFilter from "app/components/common/ignoredPatternsFilter";
import metaObjectEditors from "app/components/common/editor/meta-object-editors";
import "perfectscrollbar";
import dropdownHide from "./dropdownHide";
import meta_browser_template from "./templates/metadatabrowser.html";
import meta_list_template from "./templates/meta-list.html";
import meta_header_template from "./templates/meta-header.html";
import meta_filter_template from "./templates/meta-filter.html";
import meta_input_search_template from "./templates/meta-input-search.html";
import meta_object_search_template from "./templates/meta-object-search.html";
import meta_detail_template from "./templates/meta-detail.html";
import meta_object_table_template from "./templates/meta-object-type.html";
import meta_object_type_result_list_template from "./templates/meta-object-type-result-list.html";
import meta_object_result_item_template from "./templates/meta-object-result-item.html";
import not_selected_item_template from "./templates/not-selected-item.html";
import SearchableMultiSelectView from "../common/searchable-lists/SearchableMultiSelect";
import SearchableSingleSelectView from "../common/searchable-lists/SearchableSingleSelect";
import helpableMixin from "app/components/common/helpable/helpable";
import securityService from "core/services/securityService/securityService";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";

var MetaDataBrowser = {};

var localCache = {};

var GLOBAL_NAMESPACE_NAME = "Global";
var OBJ_TYPES = [
    "CACHE",
    "CQ",
    "SOURCE",
    "STREAM",
    "TARGET",
    "TYPE",
    "WACTIONSTORE",
    "WINDOW",
    "GROUP",
    "EXTERNALCACHE",
    "EXTERNALSOURCE"
];
var OBJ_ICONS = {
    STREAM: "stream",
    CQ: "cq",
    CACHE: "cache",
    SOURCE: "source",
    TYPE: "type",
    WACTIONSTORE: "waction-store",
    WINDOW: "window",
    TARGET: "target",
    GROUP: "app-group",
    EXTERNALCACHE: "icon-btn-externalcache"
};
var OBJ_NAMES = {
    STREAM: "Stream",
    CQ: "CQ",
    CACHE: "Cache",
    SOURCE: "Source",
    TYPE: "Type",
    WACTIONSTORE: "WActionStore",
    WINDOW: "Window",
    TARGET: "Target",
    GROUP: "Application Group",
    EXTERNALCACHE: "External Cache"
};

/****************************************************************************
 * Main Layout view with 2 regions for the metabrowser.
 *
 * 1) Left panel (Object names list)
 * 2) Right panel (Object detail view)
 */

MetaDataBrowser.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(meta_browser_template),
    className: "metadatabrowser-container large",
    regions: {
        objectHeaderRegion: "#mb-object-header",
        objectListRegion: "#mb-object-list",
        objectDetailRegion: "#mb-object-details"
    },
    defaults: {
        isSmallView: false
    },

    viewEnabled: true,

    initialize: function(options) {
        if (!securityService.isSegmentationFeatureEnabled(addonFeaturesKeys.METADATAMANAGER)) {
            this.viewEnabled = false;
            App.navigate("metadata-manager", { trigger: true });
            return;
        }

        this.model = new Backbone.Model(this.defaults);

        this.currentAppName = options.currentAppName || "";
        this.isEditable = options.isEditable;
        if (options.showDetailOfId) {
            this.showDetailOfId = options.showDetailOfId;
        }

        if (options.isSmallView) {
            this.model.set("isSmallView", true);
        }

        var that = this;
        MetaDataBrowser.vent = new Backbone.Model();
        MetaDataBrowser.vent.on("reset:metadatabrowser", function() {
            that.render();
        });
    },
    onRender: function() {
        if (!this.viewEnabled) {
            return;
        }

        $.when
            .apply(
                $,
                OBJ_TYPES.concat(metaStoreService.entities.APPLICATION).map(function(objType) {
                    return metaStoreService.fetchIdentifiersByType(objType).then(function(identifiers) {
                        localCache[objType] = identifiers;
                    });
                })
            )
            .then(
                function() {
                    if (this.$el.hasClass("large")) {
                        this.objectDetailRegion.show(new MetaDataBrowser.MetaDefaultDetailView());
                    } else {
                        this.objectDetailRegion.empty();
                    }

                    const isSmallView = this.$el.hasClass("small");

                    var headerView = new MetaDataBrowser.MetaHeaderView({
                        isSmallView: isSmallView,
                        currentAppName: this.currentAppName
                    });
                    this.objectHeaderRegion.show(headerView);

                    var metaListView = new MetaDataBrowser.MetaListView({
                        model: new Backbone.Model({
                            isSmallView: isSmallView,
                            isEditable: this.isEditable,
                            showDetailofId: this.showDetailOfId,
                            currentAppName: this.currentAppName
                        })
                    });

                    this.listenTo(metaListView, "showDetails", function(model) {
                        $(".hide-dropdown").hide();
                        this.$el.find(".obj-list-info").removeClass("active");
                        this.$el.find('[id="list_' + model.get("id") + '"]').addClass("active");
                        updateActiveMbHeader();
                        this.showDetailsView(model);
                    });

                    this.listenTo(metaListView, "showDefaultDetailView", function() {
                        if (this.$el.hasClass("large")) {
                            this.objectDetailRegion.show(new MetaDataBrowser.MetaDefaultDetailView());
                        } else {
                            this.objectDetailRegion.empty();
                        }
                    });

                    this.objectListRegion.show(metaListView);
                }.bind(this)
            );
    },
    showDetailsView: function(model) {
        var that = this;
        setTimeout(function() {
            model.fetchMetaObject().then(function(model) {
                var isSmallView = false;
                if (that.$el.hasClass("small")) {
                    isSmallView = true;
                }
                that.detailView = new MetaDataBrowser.DetailView({
                    model: model,
                    isSmallView: isSmallView
                });
                that.listenTo(that.detailView, "goBackToSmallListView", function() {
                    if (that.$el.hasClass("small")) {
                        that.$el.find(".mb-obj-details").removeClass("mb-sm-obj-details");
                        that.$el.find(".obj-list-info").removeClass("active"); // deactivate list item
                        updateActiveMbHeader();
                    }
                });
                that.objectDetailRegion.show(that.detailView);
                if (that.$el.hasClass("small")) {
                    that.slideDetailView();
                } else {
                    that.showDetailInRightPanel();
                }
            });
        }, 0);
    },
    slideDetailView: function() {
        this.$el.find(".mb-obj-details").addClass("mb-sm-obj-details");
        this.$el.find("#mb-object-type-table").hide();
    },
    showDetailInRightPanel: function() {
        this.$el.find(".mb-obj-details").removeClass("mb-sm-obj-details");
        this.$el.find("#mb-object-type-table").show();
    }
    // onShow: function () {
    // const loadedInIframe = isLoadedInIframe();
    // if (loadedInIframe) {
    //     window.document.body.classList.add("embed");
    // }
    // }
});

MetaDataBrowser.MetaDefaultDetailView = Backbone.Marionette.LayoutView.extend({
    template: _.template(not_selected_item_template),
    className: "mb-right-panel-height card"
});

/**
 * For left panel(Object detail view)
 *
 *
 */
MetaDataBrowser.MetaListView = Backbone.Marionette.LayoutView.extend({
    template: _.template(meta_list_template),
    regions: {
        objectFilterRegion: "#mb-object-filter",
        objectResultRegion: "#mb-object-result"
    },
    ui: {
        perfectscroll: "#perfectscroll"
    },
    getHelpID: function() {
        return "METADATAMANAGER";
    },
    onRender: function() {
        this.ui.perfectscroll.perfectScrollbar();

        var filterView = new MetaDataBrowser.MetaFilterView({
            isSmallView: this.model.get("isSmallView"),
            currentAppName: this.model.get("currentAppName")
        });
        this.objectFilterRegion.show(filterView);

        this.listenTo(filterView, "filterObjectCollection", function(types) {
            this.showListBasedOnType(types);
        });

        var filterObj = {};
        filterObj.text = "";
        filterObj.objectType = OBJ_TYPES;
        filterObj.isFiltered = false;
        if (this.model.get("isSmallView") && this.model.get("currentAppName").length > 0) {
            filterObj.objectType = OBJ_TYPES;
        } else {
            filterObj.objectType = OBJ_TYPES;
        }
        this.showListBasedOnType(filterObj);
    },
    showListBasedOnType: function(filterObj) {
        if (!filterObj.objectType || filterObj.objectType.length === 0) {
            filterObj.objectType = OBJ_TYPES;
        }
        var objectList = [];
        var userEnteredText = filterObj.text.trim().toUpperCase();
        var userSelectedObjTypes = filterObj.objectType;
        var userSelectedNamespace = filterObj.nameSpace;
        var userSelectedAppName = filterObj.appName;

        if (!userSelectedAppName) {
            _.each(
                filterObj.objectType,
                function(type) {
                    var obj = {};

                    var originalCollectionData = localCache[type];
                    var collectionData = originalCollectionData.clone();
                    if (type === "SOURCE") {
                        var externalSourceCollectionData = localCache["EXTERNALSOURCE"];
                        if (externalSourceCollectionData?.models?.length) {
                            collectionData = [...collectionData.models, ...externalSourceCollectionData.models];
                        }
                    } else if (type === "EXTERNALSOURCE") {
                        return;
                    }
                    var updatedCollection = collectionData
                        .filter(function(data) {
                            var namespace = userSelectedNamespace
                                ? data.get("nsName").toUpperCase() === userSelectedNamespace.toUpperCase()
                                : true;
                            var searchText =
                                data
                                    .get("name")
                                    .toUpperCase()
                                    .indexOf(userEnteredText) !== -1;
                            var globalNamespace = data.get("nsName") === GLOBAL_NAMESPACE_NAME; // DEV-7984 - we don't want to show Global namespace objects
                            return searchText && namespace && !globalNamespace;
                        })
                        .filter(ignoredPatternsFilter);

                    obj[type] = updatedCollection;
                    if (updatedCollection.length) {
                        objectList.push(obj);
                    }
                }.bind(this)
            );
            this.resultView(
                objectList.sort(function(a, b) {
                    return Object.keys(a)[0] > Object.keys(b)[0];
                }),
                filterObj.isFiltered
            );
        } else {
            var appId = metaObjectConverter.getId(
                userSelectedNamespace,
                metaStoreService.entities.APPLICATION,
                userSelectedAppName
            );
            metaStoreService.findById(appId).then(
                function(app) {
                    app.fetchObjects(true).then(
                        function(all_objects) {
                            var map = {};
                            all_objects.each(function(model) {
                                var type = model.get("type");
                                if (!map[type]) {
                                    map[type] = [];
                                }
                                var namespace = userSelectedNamespace
                                    ? model.get("nsName").toUpperCase() === userSelectedNamespace.toUpperCase()
                                    : true;
                                var searchText =
                                    model
                                        .get("name")
                                        .toUpperCase()
                                        .indexOf(userEnteredText) !== -1;
                                var metaInfoStatus = model.get("metaInfoStatus");

                                if (!metaInfoStatus.isAdhoc) {
                                    if (searchText && namespace && userSelectedObjTypes.indexOf(type) !== -1) {
                                        map[type].push(model);
                                    }
                                }
                            });
                            _.each(map, function(datum, key) {
                                var obj = {};
                                obj[key] = datum;
                                if (datum.length) {
                                    objectList.push(obj);
                                }
                            });

                            this.resultView(
                                objectList.sort(function(a, b) {
                                    return Object.keys(a)[0] > Object.keys(b)[0];
                                }),
                                filterObj.isFiltered
                            );
                        }.bind(this)
                    );
                }.bind(this)
            );
        }
    },
    resultView: function(objectList, isFiltered) {
        var objectListCollection = new Backbone.Collection(objectList);

        this.trigger("showDefaultDetailView");

        var resultView = new MetaDataBrowser.ObjectListCollectionView({
            model: new Backbone.Model({
                isSmallView: this.model.get("isSmallView"),
                isEditable: this.model.get("isEditable"),
                showDetailofId: this.model.get("showDetailofId"),
                currentAppName: this.model.get("currentAppName"),
                openFirstDetails: isFiltered
            }),
            className: "mb-result-list",
            tagName: "ul",
            collection: objectListCollection
        });
        this.listenTo(resultView, "showObjectDetails", function(currentModel) {
            this.trigger("showDetails", currentModel);
        });

        this.objectResultRegion.show(resultView);
        this.ui.perfectscroll.perfectScrollbar("update");
    }
});

/**
 * For right panel(Object names list)
 */

MetaDataBrowser.DetailView = Backbone.Marionette.LayoutView.extend({
    template: _.template(meta_detail_template),
    tagName: "div",
    className: "mb-right-panel-height card",
    regions: {
        formRegion: "#mb-object-type-form"
    },
    ui: {
        backButton: "#back-button",
        goToComponent: ".mb-go-to-component"
    },
    triggers: {
        "click @ui.backButton": "goBackToSmallListView"
    },
    events: {
        mouseover: function() {
            this.$el.find(".form-group-container").perfectScrollbar("update");
        },
        "click button": function() {
            api.getObjectParentApplications(this.model.id).then(
                function(applications) {
                    var apps = _.keys(applications);
                    if (apps.length === 0) {
                        return;
                    }

                    var appFullName = apps[0];
                    var flowName = applications[appFullName];
                    if (flowName) {
                        navigateTo.EditFlowNode(appFullName, flowName, this.model.name, true);
                    } else {
                        navigateTo.EditNode(appFullName, this.model.name, true);
                    }
                    App.vent.trigger("metadata-browser:close");
                }.bind(this)
            );
        }
    },
    initialize: function(options) {
        var that = this;
        that.isSmallView = options.isSmallView;
    },

    onRender: function() {
        var that = this;
        var editable = false;
        this.form = metaObjectEditors.createForMetadataObject(this.model, {
            readOnly: !editable,
            hideWarning: true
        });

        this.form.on("form-builder:cancel", function() {
            MetaDataBrowser.vent.trigger("reset:metadatabrowser");
        });

        this.formRegion.show(this.form);
        if (!this.model.get("isSmallView")) {
            this.$el.find(".toggle-full-screen").hide();
        }
        this.$el.find(".form-group-container").perfectScrollbar();

        const iconClass = this.options.isSmallView ? "icon-forward" : "icon-back";

        //need to prepend back button because form is generated dynamically without back button.
        this.$el
            .find(".from-control-container-title")
            .prepend(
                '<span id="back-button" style="display: none;" class="back-arrow icon ' + iconClass + '"> </span>'
            );
        this.$el.find(".icon.icon-container").css({ display: "none" });

        setTimeout(function() {
            var stopOuterscroll = function(ev) {
                var $this = $(this),
                    scrollTop = this.scrollTop,
                    scrollHeight = this.scrollHeight,
                    height = $this.height(),
                    delta = ev.type === "DOMMouseScroll" ? ev.originalEvent.detail * -40 : ev.originalEvent.wheelDelta,
                    moveUp = delta > 0;

                if (moveUp && scrollTop === 0) {
                    //if (moveUp && delta > scrollTop) { // default
                    //console.log("top");
                    ev.preventDefault();
                } else if (!moveUp && scrollHeight - height - scrollTop < 1) {
                    //} else if (!moveUp && -delta > scrollHeight - height - scrollTop) { // default
                    //} else if (!moveUp && height < scrollTop) {
                    //console.log("bottom")
                    ev.preventDefault();
                } else {
                    //console.log("middle");
                    ev.stopPropagation();
                }
            };
            $(that.el)
                .find(".slimScrollDiv .ui-sortable")
                .bind("mousewheel DOMMouseScroll", stopOuterscroll);
            $(that.el)
                .find(".CodeMirror-overlayscroll")
                .bind("mousewheel DOMMouseScroll", stopOuterscroll);

            if (that.model.get("type") === "TYPE") {
                api.getObjectParentApplications(that.model.id).then(applications => {
                    const apps = _.keys(applications);
                    if (!apps.length) {
                        that.ui.goToComponent.hide();
                    }
                });
            } else if (that.model.get("type") === "GROUP") {
                that.ui.goToComponent.hide();
            }
        }, 0);
    }
});

MetaDataBrowser.ObjectTypeTableView = Backbone.Marionette.ItemView.extend({
    template: _.template(meta_object_table_template)
});

MetaDataBrowser.MetaHeaderView = Backbone.Marionette.LayoutView.extend(helpableMixin).extend({
    template: _.template(meta_header_template),
    events: {
        "click .close-metadatabrowser": "close"
    },
    close: function() {
        App.vent.trigger("metadata-browser:close");
    },
    initialize: function(options) {
        helpableMixin.initialize.apply(this, arguments);

        this.model = new Backbone.Model({});
        if (options.isSmallView && options.currentAppName) {
            this.model.set("editorName", "Metadata Browser");
        } else {
            this.model.set("editorName", "Metadata Manager");
        }
    },

    getHelpID: function() {
        return "METADATAMANAGER";
    },
    getHelperContentElement: function() {
        return this.$el.find(".title h3");
    }
});

/**
 * Responsible for filter the fetched collection, based on user entered text and object name selection.
 *
 * filterObjectCollection -> Trigger to pass filter object values(text, objectType, app, namespace).
 *
 *
 */

MetaDataBrowser.MetaFilterView = Backbone.Marionette.LayoutView.extend({
    template: _.template(meta_filter_template),
    regions: {
        textSearchRegion: "#mb-search-input",
        objectSearchRegion: "#mb-object-search"
    },
    initialize: function(options) {
        this.isSmallView = options.isSmallView;
        this.currentAppName = options.currentAppName;
    },
    onRender: function() {
        this.selectedValues = OBJ_TYPES;
        $("#search-content").on("keypress", function(e) {
            if (e.which === 32 && !this.value.length) {
                e.preventDefault();
            }
        });
        var metaTextSearchView = new MetaDataBrowser.MetaTextSearchView();
        this.textSearchRegion.show(metaTextSearchView);
        // MetaTextSearchView --> MetaFilterView
        this.listenTo(metaTextSearchView, "text-filter", function(value) {
            this.enteredValue = value;
            this.prepareFilterObject();
        });
        var metaObjSearchView = new MetaDataBrowser.MetaObjectSearchView({
            model: new Backbone.Model({}),
            isSmallView: this.isSmallView,
            isEditable: this.isEditable,
            currentAppName: this.currentAppName
        });
        this.objectSearchRegion.show(metaObjSearchView);
        // MetaObjectSearchView --> MetaFilterView
        this.listenTo(metaObjSearchView, "dropdownObjectCollection", function(
            objectTypeValues,
            nameSpace,
            appName,
            isSmallView,
            currentAppName
        ) {
            this.selectedValues = objectTypeValues;
            this.nameSpace = nameSpace;
            this.appName = appName;
            this.isSmallView = isSmallView;
            this.currentAppName = currentAppName;
            this.prepareFilterObject();
        });
    },
    /**
     * The purpose of this function is to create filter object with values (text, objectType, appname and namespace).
     */
    prepareFilterObject: function() {
        var filterObj = {};
        filterObj.text = this.enteredValue || "";
        filterObj.objectType = this.selectedValues || [];
        filterObj.nameSpace = this.nameSpace;
        filterObj.appName = this.appName;
        filterObj.isFiltered = true;
        filterObj.isSmallView = this.isSmallView;
        filterObj.currentAppName = this.currentAppName;
        this.trigger("filterObjectCollection", filterObj);
    }
});

/**
 * Responsible for filter the fetched collection, based on user input text.
 *
 * "text-filter" - Trigger to pass user entered text.
 * We need to listen 'text-filter' event to get user entered text for filtering purpose.
 *
 * Example : text-filter we are listening in MetaFilterView.
 */

MetaDataBrowser.MetaTextSearchView = Backbone.Marionette.LayoutView.extend({
    template: _.template(meta_input_search_template),
    ui: {
        filterTextSubmit: "#text-search-submit",
        filterText: "#search-content",
        clearFilterText: ".close-icon"
    },
    events: {
        "click @ui.filterTextSubmit": "filterText",
        "click @ui.clearFilterText": function() {
            this.trigger("text-filter", "");
            this.ui.filterText.val("");
            this.ui.clearFilterText.hide();
        }
    },
    filterText: function() {
        var _this = this;
        var enteredData = _this.ui.filterText.val().trim();
        if (enteredData.length > 0) {
            _this.ui.clearFilterText.show();
        } else {
            _this.ui.clearFilterText.hide();
        }
        _this.trigger("text-filter", enteredData);
    },
    onRender: function() {
        var _this = this;
        // _debounce will execute the function after 500ms.
        // for consecutive calls.. last function call will execute
        this.ui.filterText.keyup(
            _.debounce(function() {
                _this.filterText();
            }, 500)
        );
    }
});

/**
 * Responsible for filter the fetched collection, based on user object selection.
 */

MetaDataBrowser.MetaObjectSearchView = Backbone.Marionette.LayoutView.extend({
    template: _.template(meta_object_search_template),
    initialize: function(options) {
        options.model.set("selectedCount", 0);
        this.isSmallView = options.isSmallView;
        this.currentAppName = options.currentAppName;
    },
    ui: {
        objectTypeComponent: "#metaObjectType",
        objectDropdown: "#objectDropdown",
        appDropdown: "#appDropdown",
        applicationTypeComponent: "#applicationType",
        namespaceDropdown: "#namespaceDropdown",
        nameSpaceComponent: "#namespaceType"
    },
    events: {
        "click @ui.objectTypeComponent": function() {
            this.ui.appDropdown.hide();
            this.ui.namespaceDropdown.hide();
            this.ui.objectDropdown.toggle();
            this.ui.objectDropdown.find("#text-search").focus();
        },
        "click @ui.applicationTypeComponent": function() {
            this.ui.objectDropdown.hide();
            this.ui.namespaceDropdown.hide();
            this.ui.appDropdown.toggle();
            this.ui.appDropdown.find("#text-search").focus();
        },
        "click @ui.nameSpaceComponent": function() {
            this.ui.objectDropdown.hide();
            this.ui.appDropdown.hide();
            this.ui.namespaceDropdown.toggle();
            this.ui.namespaceDropdown.find("#text-search").focus();
        }
    },
    regions: {
        objectDropdownRegion: "#objectDropdown",
        namespaceDropdownRegion: "#namespaceDropdown",
        appDropdownRegion: "#appDropdown"
    },

    onRender: function() {
        dropdownHide.attach();

        // remove "EXTERNALSOURCE" from OBJ_TYPES so that it isn't included in the object type dropdown.
        const filteredObjTypes = OBJ_TYPES.filter(type => type !== "EXTERNALSOURCE");
        var objDropdownView = new SearchableMultiSelectView({
            dataArray: filteredObjTypes,
            nameMap: OBJ_NAMES,
            isSmallView: this.isSmallView,
            currentAppName: this.currentAppName,
            dropDownPosition: "right"
        });

        this.objectDropdownRegion.show(objDropdownView);

        this.listenTo(objDropdownView, "selectedListItems", function(selectedValues, isSmallView, currentAppName) {
            this.selectedValues = selectedValues;
            this.isSmallView = isSmallView || false;
            this.currentAppName = currentAppName || "";
            this.filterDropdownBasedOnValues();
            this.ui.objectDropdown.hide();
            this.$el.find("#objectCount").html(selectedValues.length > 0 ? "(" + selectedValues.length + ")" : "");
        });

        // Namespace related dropdown code.y
        var self = this;
        metaStoreService
            .fetchIdentifiersByType(metaStoreService.entities.NAMESPACE)
            .then(function(namespace_collection) {
                var nameSpaceArray = _.chain(namespace_collection.models)
                    .pluck("name")
                    .without(GLOBAL_NAMESPACE_NAME) // DEV-7984 - we don't want to show Global namespace objects
                    .value();

                self.namespaceDropdownView = new SearchableSingleSelectView({
                    dataArray: nameSpaceArray
                });
                self.namespaceDropdownRegion.show(self.namespaceDropdownView);

                self.listenTo(self.namespaceDropdownView, "selectedValues", function(selectedValue) {
                    self.nameSpace = selectedValue;
                    self.showAppDropdown(selectedValue);
                    self.filterDropdownBasedOnValues();
                    self.ui.namespaceDropdown.hide();
                    if (!selectedValue) {
                        self.showAllAppDropdown();
                    }
                });
            });
        this.showAllAppDropdown();
    },

    destroy: function() {
        dropdownHide.detach();
    },

    showAllAppDropdown: function() {
        var self = this;

        metaStoreService.fetchIdentifiersByType(metaStoreService.entities.APPLICATION).then(function(app_collection) {
            var appArray = [];
            var extraInfoMap = {};
            app_collection.forEach(function(model) {
                if (model.nsName !== GLOBAL_NAMESPACE_NAME) {
                    appArray.push(model.name);
                    extraInfoMap[appArray.length - 1] = {
                        shortInfo: model.nsName
                    };
                }
            });
            var appDropdownView = new SearchableSingleSelectView({
                dataArray: appArray,
                extraInfoMap: extraInfoMap
            });
            self.appDropdownRegion.show(appDropdownView);

            self.listenTo(appDropdownView, "selectedValues", function(selectedValue, shortInfo) {
                self.appName = selectedValue;
                if (shortInfo) {
                    self.nameSpace = shortInfo;
                    self.namespaceDropdownView.trigger("setValue", self.nameSpace);
                }
                self.filterDropdownBasedOnValues();
                self.ui.appDropdown.hide();
            });
        });
    },

    showAppDropdown: function(namespaceValue) {
        var self = this;
        //clearing previous app dropdown fields
        self.ui.applicationTypeComponent.find(".mb-ellipses").html(self.ui.applicationTypeComponent.attr("data"));
        self.ui.applicationTypeComponent
            .find(".mb-ellipses")
            .attr("title", self.ui.applicationTypeComponent.attr("data"));
        self.appName = "";
        // App related dropdown code.
        metaStoreService.fetchIdentifiersByType(metaStoreService.entities.APPLICATION).then(function(app_collection) {
            var appArray = [];
            var extraInfoMap = {};
            app_collection.models.forEach(function(model) {
                if (model.nsName === namespaceValue && model.nsName !== GLOBAL_NAMESPACE_NAME) {
                    appArray.push(model.name);
                    extraInfoMap[appArray.length - 1] = {
                        shortInfo: model.nsName
                    };
                }
            });
            var appDropdownView = new SearchableSingleSelectView({
                dataArray: appArray,
                extraInfoMap: extraInfoMap
            });
            self.appDropdownRegion.show(appDropdownView);

            self.listenTo(appDropdownView, "selectedValues", function(selectedValue) {
                self.appName = selectedValue;
                self.filterDropdownBasedOnValues();
                self.ui.appDropdown.hide();
            });
        });
    },
    filterDropdownBasedOnValues: function() {
        this.trigger(
            "dropdownObjectCollection",
            this.selectedValues,
            this.nameSpace,
            this.appName,
            this.isSmallView,
            this.currentAppName
        );
    }
});

/**
 * Responsible to show object data along with object name.
 */

MetaDataBrowser.ObjectListCollectionView = Backbone.Marionette.CollectionView.extend({
    getChildView: function() {
        return MetaDataBrowser.ObjectListItemView;
    },
    getEmptyView: function() {
        return MetaDataBrowser.ObjectListEmptyView;
    },
    initialize: function(options) {
        this.executionCount = 0;
        this.isSmallView = options.model.get("isSmallView") || false;
        this.currentAppName = options.model.get("currentAppName") || "";
    },
    buildChildView: function(child, ChildViewClass) {
        var objName = child.keys().length ? child.keys()[0] : "";
        var objCollection = new Backbone.Collection(child.get(child.keys()[0]));
        objCollection.comparator = "name";
        this.executionCount++;
        return new ChildViewClass({
            model: new Backbone.Model({
                objIcon: OBJ_ICONS[objName],
                objName: OBJ_NAMES[objName] || "", //functionality to convert object name to camel case.
                collection: objCollection.sort(),
                showDetailofId: this.model.get("showDetailofId"),
                currentAppName: this.model.get("currentAppName"),
                isSmallView: this.isSmallView,
                isEditable: this.model.get("isEditable"),
                executionCount: this.executionCount
            })
        });
    },
    childEvents: {
        showObjectDetails: function(child, listElement) {
            this.showDetailOfDiv = listElement.el.firstChild;
            this.trigger("showObjectDetails", listElement.model);
        }
    },
    onRender: function() {
        this.$el.find(".obj-list-info").removeClass("active");
        if (this.model.get("showDetailofId")) {
            this.$el.find(this.showDetailOfDiv).addClass("active");
        } else {
            if (!this.model.get("isSmallView") && this.model.get("openFirstDetails")) {
                // to show by default first item selected based on user entered text in searchbox.
                // finding matched first element from collection data via loop for this we need to check it on all the collection models data.
                for (var i = 0; i < this.children.length; i++) {
                    if (this.children.findByIndex(i).model.get("count")) {
                        var firstChild = this.children.findByIndex(i);
                        this.activeFirstObject(firstChild);
                        break; // Breaking loop once we got required data.
                    }
                }
                this.openAllAccordion();
            }
            if (this.model.get("openFirstDetails")) {
                this.openAllAccordion();
            }
        }
        updateActiveMbHeader();
        var _this = this;
        this.targetNumber = -1;
        $(this.el).bind("keydown", function(event) {
            //_this.openAllAccordion();

            function openEditor() {
                if (_this.targetNumber !== -1) {
                    _this.target.click();
                    setTimeout(function() {
                        _this.$el.find(_this.target).focus();
                    }, 1000);
                }
            }

            var item;
            var parentTarget;
            var activeMenu;
            var offset;
            var scroll;
            var elementHeight;
            var itemHeight;
            if (event.keyCode === 32 || event.keyCode === 13) {
                openEditor();
            } else if (event.keyCode === 40) {
                _this.dropDownCount = _this.$el.find(".obj-list-info").length - 1;

                if (_this.targetNumber < _this.dropDownCount) {
                    _this.prevTarget = _this.$el.find(".obj-list-info")[_this.targetNumber];
                    _this.target = _this.$el.find(".obj-list-info")[++_this.targetNumber];

                    _this.$el.find(_this.prevTarget).removeClass("item-selected");
                    _this.$el.find(_this.target).addClass("item-selected");

                    item = _this.$(_this.target);
                    parentTarget = item.parent().parent();
                    //expand current view
                    _this.expandSingleAccordion(parentTarget.parent());

                    /**
                     * scroll has been added to parent to parent div.
                     **/
                    activeMenu = _this.$el.parent().parent();

                    offset = item.offset().top - activeMenu.offset().top;
                    scroll = activeMenu.scrollTop();
                    elementHeight = activeMenu.height();
                    itemHeight = item.outerHeight();

                    if (offset < 0) {
                        activeMenu.scrollTop(scroll + offset);
                    } else if (offset + itemHeight > elementHeight) {
                        activeMenu.scrollTop(scroll + offset - elementHeight + itemHeight);
                    }
                    openEditor();
                }
            } else if (event.keyCode === 38) {
                //keycode for uparrow
                if (_this.targetNumber > 0) {
                    _this.prevTarget = _this.$el.find(".obj-list-info")[_this.targetNumber];
                    _this.target = _this.$el.find(".obj-list-info")[--_this.targetNumber];

                    _this.$el.find(_this.prevTarget).removeClass("item-selected");
                    _this.$el.find(_this.target).addClass("item-selected");

                    item = _this.$(_this.target);
                    parentTarget = item.parent().parent();
                    _this.expandSingleAccordion(parentTarget.parent());
                    activeMenu = _this.$el.parent().parent();

                    offset = item.offset().top - activeMenu.offset().top;
                    scroll = activeMenu.scrollTop();
                    elementHeight = activeMenu.height();
                    itemHeight = item.outerHeight();

                    if (offset < 0) {
                        activeMenu.scrollTop(scroll + offset);
                    } else if (offset + itemHeight > elementHeight) {
                        activeMenu.scrollTop(scroll + offset - elementHeight + itemHeight);
                    }
                    openEditor();
                }
            }
        });
    },
    expandSingleAccordion: function(accord) {
        accord.find(".accordion-data").removeClass("collapse");
        accord.find(".accordion-data").addClass("expand");
        accord.find("#arrow").removeClass("forward");
        accord.find("#arrow").addClass("arrow-expanded");
    },
    openAllAccordion: function() {
        this.$el.find(".accordion-data").removeClass("collapse");
        this.$el.find(".accordion-data").addClass("expand");
        this.$el.find("#arrow").removeClass("forward");
        this.$el.find("#arrow").addClass("arrow-expanded");
    },
    activeFirstObject: function(firstChild) {
        //to show by default first item selected
        if (firstChild && firstChild.children.findByIndex(0)) {
            // to show selected item in active state
            firstChild.$el.find("#accordion-data").removeClass("collapse");
            firstChild.$el.find("#accordion-data").addClass("expand");
            firstChild.$el.find("#arrow").removeClass("forward");
            firstChild.$el.find("#arrow").addClass("arrow-expanded");

            // el.firstChild - to getting obj-list-info class of first child
            this.$el.find(firstChild.children.findByIndex(0).el.firstChild).addClass("active");
            updateActiveMbHeader();

            this.trigger("showObjectDetails", firstChild.children.findByIndex(0).model);
        }
    },
    events: {}
});

MetaDataBrowser.ObjectListItemView = Backbone.Marionette.CompositeView.extend({
    template: _.template(meta_object_type_result_list_template),
    childViewContainer: "@ui.accordionData",
    tagName: "li",
    ui: {
        headerRow: "#accordion-header",
        accordionData: "#accordion-data"
    },
    events: {
        "click @ui.headerRow": function() {
            this.ui.accordionData.toggleClass("expand collapse");
            this.ui.headerRow.find("#arrow").toggleClass("arrow-expanded forward");
            this.ui.headerRow.toggleClass("active-header");
            setTimeout(function() {
                $(".obj-list-data").perfectScrollbar("update");
            }, 500);
        }
    },
    getChildView: function() {
        return MetaDataBrowser.ObjectListCollectionItem;
    },
    initialize: function(options) {
        this.model.set("count", options.model.get("collection").length);
        // console.log(options.model.get('objName'));
        this.collection = options.model.get("collection");
    },
    buildChildView: function(child, ChildViewClass) {
        var showDetailFlag = false;
        var showDetailId = this.model.get("showDetailofId");
        var currentAppName = this.model.get("currentAppName");
        if (showDetailId) {
            if (child.get("id") === showDetailId) {
                showDetailFlag = true;
            }
        }
        child.set("isEditable", this.model.get("isEditable"));
        return new ChildViewClass({
            model: child,
            showDetailFlag: showDetailFlag,
            currentAppName: currentAppName
        });
    },
    childEvents: {
        showObjectDetails: function(child) {
            this.trigger("showObjectDetails", child);
            this.ui.accordionData.removeClass("collapse");
            this.ui.accordionData.addClass("expand");
            this.ui.headerRow.find("#arrow").removeClass("forward");
            this.ui.headerRow.find("#arrow").addClass("arrow-expanded");
        }
    },
    onRender: function() {
        this.$el.attr("id", this.model.get("objName"));
        this.ui.accordionData.attr("data", this.model.get("objName"));
    }
});

MetaDataBrowser.ObjectListCollectionItem = Backbone.Marionette.ItemView.extend({
    template: _.template(meta_object_result_item_template),
    triggers: {
        click: "showObjectDetails"
    },
    events: {
        "click .copy-to-app": "copyToApp"
    },
    initialize: function(options) {
        this.showDetailFlag = options.showDetailFlag;
        this.currentAppName = options.currentAppName;
    },
    onRender: function() {
        if (this.showDetailFlag) {
            this.trigger("showObjectDetails");
        }
    },
    copyToApp: function(e) {
        e.stopPropagation();
        App.vent.trigger("copy-to-app", this.model.id);
    }
});

MetaDataBrowser.ObjectListEmptyView = Backbone.Marionette.ItemView.extend({
    template: _.template('<h3 class="obj-list-info" id="message">{{message}}</h3>'),
    tagName: "div",

    initialize: function(options) {
        this.currentAppName = options.model.get("currentAppName");
        this.isSmallView = options.model.get("isSmallView");
    },

    serializeData: function() {
        var data = [];
        data.message = "No records found for search criteria.";
        return data;
    }
});

// App.addInitializer(function () {
//     MetaDataBrowser.Router = new MetaDataBrowser.Router();
// });

function updateActiveMbHeader() {
    $(".lHeader").removeClass("active-header");

    var parent = $(".obj-list-info.active").parent();
    while (parent && parent.length !== 0) {
        var header = parent.find(".lHeader");
        if (header.length > 0) {
            header.addClass("active-header");
            return;
        }
        parent = parent.parent();
    }
}

export default MetaDataBrowser;
