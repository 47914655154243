import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
// @ts-ignore
import { StriimDropdown, StriimIconButton, StriimTable, StriimMenuList, StriimMenuItem } from "@striim/striim-ui";
import EmbeddingGeneratorDeleteModal from "./embedding-generator-delete-modal";

const useStyles = makeStyles(() => ({
    collapsibleSection: {
        marginBottom: 34
    },
    actionColumn: {
        display: "flex",
        justifyContent: "end"
    }
}));

const styles = {
    deleteLink: {
        color: theme => theme.palette.critical[500]
    }
};


interface EmbeddingGeneratorList {
    list: any[];
    onEdit: (embeddingGenerator: any) => void;
    onDelete: (embeddingGenerator: any) => void;
}

export default function EmbeddingGeneratorList({ list, onEdit, onDelete }: EmbeddingGeneratorList) {
    const classes = useStyles();

    const [itemToDelete, setItemToDelete] = useState<any | undefined>();

    const columns = [
        {
            Header: "Namespace",
            accessor: "nsName",
            Cell: (item: any) => {
                return <span data-test-id="embedding-generator-namespace">{item.value || "(default)"}</span>;
            }
        },
        {
            Header: "Name",
            accessor: "name",
            Cell: (item: any) => {
                return <span data-test-id="embedding-generator-name">{item.value || "(default)"}</span>;
            }
        },
        {
            Header: "AI Model Provider",
            accessor: "modelProvider"
        },
        {
            Header: "Model Name",
            accessor: "properties.modelName",
            Cell: (item: any) => {
                return item.value || "(default)";
            }
        },
        {
            Header: "",
            width: 50,
            accessor: "id",
            Cell: (item: any) => {
                const data: any = item.data.find(x => {
                    return x.get("id") === item.value;
                });
                const menu = (
                    <StriimMenuList>
                        <StriimMenuItem
                            data-test-id={"embedding-generator-edit-" + data.get("name")}
                            onClick={() => onEdit(data)}
                        >
                            Edit
                        </StriimMenuItem>
                        <StriimMenuItem
                            data-test-id={"embedding-generator-delete-" + data.get("name")}
                            sx={styles.deleteLink}
                            onClick={() => setItemToDelete(data)}
                        >
                            Delete
                        </StriimMenuItem>
                    </StriimMenuList>
                );

                return (
                    <div className={classes.actionColumn}>
                        <StriimDropdown extendOnClick content={menu} closeOnSelect>
                            <StriimIconButton data-testid={"embedding-generator-menu-" + data.get("name")}>
                                <Box component={MoreHoriz} color="text.secondary" />
                            </StriimIconButton>
                        </StriimDropdown>
                    </div>
                );
            }
        }
    ];

    return (
        <>
            <StriimTable data={list} columns={columns} TableProps={{ "data-testid": "embedding-generator-table" }} />
            <EmbeddingGeneratorDeleteModal
                open={!!itemToDelete}
                onCancel={() => setItemToDelete(undefined)}
                onConfirm={() => {
                    onDelete(itemToDelete);
                    setItemToDelete(undefined);
                }}
            />
        </>
    );
}
