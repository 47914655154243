export const styles = {
    table: {
        "& .MuiTableCell-root": {
            maxWidth: "400px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        },
        "& .MuiTableHead-root": {
            "& .MuiTypography-root": {
                color: "greyscale.600"
            }
        }
    },
    closeIcon: { width: "20px", height: "20px", cursor: "pointer" }
};
