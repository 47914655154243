import $ from "jquery";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import hierarchicalFormatter from "./hierarchicalFormatter";
import api from "core/api/api";
import converter from "core/services/metaStoreService/metaobject-converter";

/**
 *
 * @param appName - application id
 * @param {string[]} [skipTypes] - list of types that should be skipped from result
 */
export default function (appName, skipTypes) {
    var deferred = $.Deferred();

    api.getApplicationComponentIdentifiers(appName).then(function(results) {
        if (skipTypes) {
            skipTypes = skipTypes.map((x) => {
                return x.toLowerCase();
            });
            results = results.filter((x) => {
                return skipTypes.indexOf(x.type.toLowerCase()) === -1;
            });
        }

        if (!skipTypes || skipTypes.indexOf(metaStoreService.entities.APPLICATION.toLowerCase()) === -1) {
            results.unshift({
                name: converter.getName(appName),
                namespace: converter.getNamespace(appName),
                type: converter.getType(appName),
            });
        }
        deferred.resolve(hierarchicalFormatter(results, false, "type"));
    });

    return deferred.promise();
}
