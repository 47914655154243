import Marionette from "marionette";
import _ from "underscore";
import $ from "jquery";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import statusManagement, { FlowStatus } from "src/status-management";
import appStatusSynchronizer from "core/appStatusSynchronizer";
import AppExceptions from "./appExceptions";
import template from "./templates/appErrors.html";
import AppControl from "app/components/common/app-control/app-control";

var AppErrors = {};

AppErrors.View = Marionette.CompositeView.extend({
    template: _.template(template),

    className: "app-errors",

    childView: AppExceptions.ExceptionView,

    childViewContainer: ".app-exceptions",

    childEvents: {
        "node-link-clicked": "showEditNode"
    },

    ui: {
        subheader: ".sub-header",
        clearButton: ".clear-btn",
        resume: "#resume",
        undeploy: "#undeploy",
        deploy: "#deploy",
        start: "#start",
        moveToAppGroups: "#goToAutomatedGroupBtn"
    },

    events: {
        "click @ui.clearButton": "clearErrors",
        "click @ui.resume": "resumeApp",
        "click @ui.undeploy": "undeployApp",
        "click @ui.deploy": "deployApp",
        "click @ui.start": "startApp",
        "click @ui.moveToAppGroups": "moveToAppGroups"
    },

    initSideBarResizer: function() {
        var _this = this;

        // the editors containers height has to be calculated in javascript
        function onSidebarResize() {
            var newHeight = _this.$el.parent().outerHeight() - _this.$el.find("header").outerHeight(); //height of sidebar

            _this.$el.find(".app-exceptions").css("height", newHeight + "px");
        }

        setTimeout(onSidebarResize, 100);
        $(window).resize(onSidebarResize);
    },

    initialize: function(options) {
        var _this = this;
        this.options = options;
        this.initSideBarResizer();

        this._appStatus = this.options.appStatus;
        if (this.options.appStatus === null) {
            metaStoreService.findById(this.model.app.id).then(function(app) {
                if (!app) {
                    return;
                }
                _this._appStatus = app.metaInfoStatus.isValid ? app.flowStatus : FlowStatus.INVALID;
                _this._updateHeader();
            });
        }

        this.appControl = new AppControl({
            appIdentifier: this.model.app
        });

        this.listenTo(
            appStatusSynchronizer,
            this.model.app.id,
            function(status) {
                _this._appStatus = status;
                this._updateHeader();
            }.bind(this)
        );
    },

    resumeApp: function() {
        this.appControl.resume();
    },

    startApp: function() {
        this.appControl.start();
    },

    undeployApp: function() {
        this.appControl.undeploy();
    },

    deployApp: function() {
        this.appControl.deploy();
    },

    moveToAppGroups: function() {
        this?.options?.moveToAppGroups();
    },

    onRender: function() {
        this._updateHeader();
    },

    _updateHeader: function() {
        if (!this.ui.subheader || !this.ui.subheader.text || this._appStatus === null) {
            return;
        }
        const deployBtn = `<button data-test-id="deploy" id="deploy" class="text-button">deploy</button>`;
        const goToAutomatedGroupBtn = `<button id="goToAutomatedGroupBtn" class="text-button">Go to the Automated Pipeline</button>`;

        if (this.options.isAutomatedGroup) {
            this.ui.subheader.html(
                `The application has been ${this._appStatus.toLowerCase()} due to exceptions. Please fix them before resuming the automated pipeline. ${goToAutomatedGroupBtn}`
            );
            return;
        }
        if (statusManagement.isApplicationInvalid(this._appStatus)) {
            this.ui.subheader.text("If all errors have been fixed, the application will be recompiled automatically.");
        } else if (statusManagement.isApplicationFailedOnDeployment(this._appStatus)) {
            this.ui.subheader.html(`If all errors have been fixed, ${deployBtn} the application again.`);
        } else if (statusManagement.isApplicationCreated(this._appStatus)) {
            this.ui.subheader.html(`Please fix the errors, then ${deployBtn} and restart the app.`);
        } else {
            const startBtn = `<button  data-test-id="start" id="start" class="text-button">start</button>`;
            let errorMessage = `If all errors have been fixed, ${startBtn} the app.`;

            if (statusManagement.isApplicationCrash(this._appStatus)) {
                const resumeBtn = `<button  data-test-id="resume" id="resume" class="text-button">resume</button>`;
                const undeployBtn = `<button id="undeploy"  data-test-id="undeploy" class="text-button">undeploy</button>`;
                errorMessage = `To edit the app, please ${undeployBtn} it. If all errors have been fixed, ${resumeBtn} the app.`;
            }
            this.ui.subheader.html(errorMessage);
        }
    },

    showEditNode: function(childView) {
        var nodeId = childView.model.nsName + "." + childView.model.componentType + "." + childView.model.componentName;
        this.trigger("show-node", nodeId);
    },

    clearErrors: function() {
        this.trigger("clear-and-reload");
        this.ui.clearButton.addClass("disabled");
    }
});

export default AppErrors;
