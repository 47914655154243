import Backbone from "backbone";
import _ from "underscore";
import noDataTemplate from "./no-data-template.html";

export default Backbone.Marionette.LayoutView.extend({
    template: _.template(noDataTemplate),
    className: "content-center-wrap noDataTemplateContainer",
    initialize: function(data) {
        this.text = data.text;
        this.render();
    },
    render: function() {
        this.$el.html(
            this.template({
                text: this.text
            })
        );
    }
});
