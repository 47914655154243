import App from "app";
import api from "core/api/api";
import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var module = {};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "STREAM",
        partitionExprs: Array,
        dataType: null,
        partitioned: false,
        persist: false,
        propertySet: null,
        gracePeriodInterval: String,
        gracePeriodField: String,
    },
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model,
});

export default module;
