import _ from "underscore";
import BaseControl from "./../base-control";
import UIControl from "app/components/common/editor/control/ui-control";
import SingleColumn from "./single-column-dropdown-list";
import { loadAscendantsDbSources } from "app/components/flow/designer/component-acendants-loader";
import loadTables from "app/components/flow/designer/tables-loader.js";
import { loadDGsForAgents, getAgentForDeploymentGroup } from "app/components/flow/designer/agents-loader";
import ArrayDataSource from "./../select/array-datasource";
import template from "./db-table-meta-filter.html";

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "columns-splitter",

    // parent control should clear columns when
    // stream changed
    clearColumnsOnStreamChanged: true,

    regions: {
        agentSelectorRegion: ".agent-selector",
        tableSelectorRegion: ".table-selector",
    },

    ui: {
        agentSelectorContainer: ".agent-selector-container",
    },

    setViewValue: function () {},

    getViewValue: function () {
        let list = this.tableSelector.getValue();

        var viewValue = [];

        for (let i = 0; i < list.length; i++) {
            viewValue.push({
                column: list[i].column,
                tqlFunction: this.agentSelector.getValue(),
            });
        }
        return viewValue;
    },

    setEnabled: function (enabled) {
        BaseControl.View.prototype.setEnabled.apply(this, [enabled]);

        this.agentSelector.setEnabled(enabled);
        this.tableSelector.setEnabled(enabled);
    },

    initialize: function (options) {
        this.streamId = "";

        this.agentSelector = UIControl.Select([])
            .extend({
                placeholder: "select",
            })
            .create();

        this.tableSelector = SingleColumn.extend({
            hideLabel: true,
            hidden: false,
            transformationConfig: {
                preFillColumns: false,
                addAvailable: true,
                addLabel: "ADD CONDITION",
                addNew: true,
                labels: {
                    column1: "Table Name",
                },
            },
        }).create();
    },

    onRender: function () {
        this.agentSelectorRegion.show(this.agentSelector);
        this.tableSelectorRegion.show(this.tableSelector);

        this.toggleAgentsSelector();

        if (this.model.value && this.model.value[0] && this.model.value[0].tqlFunction) {
            this.agentSelector.setValue(this.model.value[0].tqlFunction);
            this.tableSelector.model.value = this.model.value;
            this.toggleTableSelector(this.model.value);
        }

        this.listenTo(
            this.tableSelector.model,
            "change:value",
            function () {
                this.setValueFromView();
            }.bind(this)
        );

        this.listenTo(
            this.agentSelector.model,
            "change:value",
            function (viewModel) {
                this.trigger(UIControl.EVENT.VALUE_CHANGED, this.getViewValue());
                let agent = getAgentForDeploymentGroup(viewModel.value);
                this.toggleTableSelector(agent);
            }.bind(this)
        );
    },

    clearColumnsDataSource: function () {
        this.tableSelector.clearColumnsDataSource();
    },

    setStream: function (componentId) {
        this.streamId = componentId;
        this.toggleAgentsSelector();
    },

    toggleAgentsSelector: function () {
        let agents = loadDGsForAgents();
        this.agentSelector.setDataSource(this.toAgentsSelectSource(agents));

        if (agents.length < 2) {
            this.ui.agentSelectorContainer.hide();
            this.agentSelector.model.value = "server";
        } else {
            this.ui.agentSelectorContainer.show();
        }

        this.toggleTableSelector(this.agentSelector.model.value);
    },

    toAgentsSelectSource: function (agents) {
        let agentsSource = [{ id: "server", text: "Server" }];

        _.each(agents, function (elem) {
            agentsSource.push({
                id: elem.attributes.id,
                text: elem.attributes.name,
            });
        });

        return agentsSource;
    },

    toggleTableSelector(agentValue) {
        var _that = this;

        // should not load columns when settings didn't change
        if (this._lastSelectedAgent === agentValue && this._lastStreamId === this.streamId) {
            return;
        } else {
            this._lastSelectedAgent = agentValue;
            this._lastStreamId = this.streamId;
        }

        if (!agentValue) {
            this.tableSelector.$el.hide();
            this.tableSelector.config.columnsSource = [];
        } else {
            this.tableSelector.$el.show();

            if (!this.streamId) {
                this.tableSelector.config.columnsSource = [];
                return;
            }

            if (agentValue === "server") {
                agentValue = "";
            }

            this.tableSelector.toggleLoader(true);
            loadAscendantsDbSources(_that.streamId).then((sources) => {
                loadTables(sources[0], agentValue)
                    .then((tables) => {
                        _that.tableSelector.toggleLoader(false);

                        let tablesSourceFunction = ArrayDataSource(tables);
                        this.tableSelector.config.columnsSource = tablesSourceFunction;
                        this.tableSelector.setStream(_that.streamId);
                    })
                    .catch((error) => {
                        _that.tableSelector.toggleLoader(false);
                        _that.tableSelector.config.columnsSource = [];
                        this.trigger("show-error-msg", error);
                    });
            });
        }
    },
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: Array,
    },
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
