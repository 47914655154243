import _ from "underscore";
import HighChartsMixin from "app/components/dashboard/visualization/charts/mixins/highcharts";

export default _({}).extend(HighChartsMixin, {
    // Note: be sure to pass in a deep-cloned config object!
    setConfiguration: function (_config) {
        var theme = _config.theme;
        var config = _config.config;
        if (!theme) {
            return console.warn("No theme provided.");
        }

        var yAxisConfig = this._formatYAxis(config.yAxis, theme);
        if (
            config.yAxisLimits !== undefined &&
            (config.yAxisLimits.lowerBound !== "" || config.yAxisLimits.upperBound !== "")
        ) {
            if (yAxisConfig.length > 0) {
                var yAxis = yAxisConfig[0];
                if (config.yAxisLimits.lowerBound) {
                    yAxis.min = parseInt(config.yAxisLimits.lowerBound, 10);
                }
                if (config.yAxisLimits.upperBound) {
                    yAxis.max = parseInt(config.yAxisLimits.upperBound, 10);
                }
            }
        }

        var baseOptions = {
            dataLabels: {
                enabled: false,
            },
            enableMouseTracking: true,
        };
        var lineMarker = {
            enabled: "true",
            symbol: "circle",
            radius: 4,
        };
        var areaMarker = {
            enabled: false,
            symbol: "circle",
            radius: 4,
            states: {
                hover: {
                    enabled: true,
                },
            },
        };

        // Translate our configuration to Highcharts configuration
        // TODO: rather than separate the translation of each item into methods, have a "Time Series" chartconfig
        // generator that will translate some of the properties (e.g. xAxis, series, target, etc.) and the chartConfig here can be what is only specific to the LineChart
        this.chartConfig = {
            chart: {
                type: "area",
                backgroundColor: theme.chart.background,
                animation: false,
                zoomType: "x",
            },
            credits: {
                enabled: false,
            },
            tooltip: this.generateToolTipConfig(),
            plotOptions: {
                series: {
                    cursor: "pointer",
                    animation: false,
                    events: {
                        hide: function (e) {
                            this.trigger("series-hidden", e);
                        }.bind(this),
                        show: function (e) {
                            this.trigger("series-shown", e);
                        }.bind(this),
                    },
                    point: {
                        events: {
                            click: function (e) {
                                //var index = e.point.index;
                                //var element = this.model.get('query').get('data')[index];
                                var element = e.currentTarget.options.rowData;
                                this.trigger("datapoint:click", element);
                            }.bind(this),
                        },
                    },
                },
            },
            legend: {
                enabled: false,
            },
            title: {
                text: config.title,
                style: {
                    display: "none",
                },
            },
            xAxis: this._formatXAxis(config.xAxis, theme),
            yAxis: yAxisConfig,
        };

        function isBlank(str) {
            return !str || /^\s*$/.test(str);
        }

        var useStacking = !isBlank(config.stack) && config.stack !== "none";

        this.chartConfig.plotOptions.area = baseOptions;
        this.chartConfig.plotOptions.area.marker = areaMarker;
        this.chartConfig.plotOptions.series.marker = areaMarker;
        if (useStacking) {
            this.chartConfig.plotOptions.area.stacking = config.stack;
        }

        for (var i = 0; i < config.series.length; i++) {
            var seriesCategoriesAndLegendData = this.generateSeriesCategoriesAndLegendData(
                i,
                config,
                this.chartConfig,
                this.model.dataSource.fieldList
            );
            this.setChartConfigWithSeriesCategoryAndChartData(i, config, seriesCategoriesAndLegendData);
        }
    },
});
