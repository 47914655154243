export const styles = {
    container: {
        height: 'fit-content',
        backgroundColor: 'greyscale.50',
        borderRadius: '8px',
        border: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    overviewWrapper: {
        maxHeight: `calc(100% - 80px)`,
        overflow: 'auto'
    },
    expanded: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    icon: {
        width: '20px',
        height: '20px'
    },
    title: {
        display: "flex",
        alignItems: "center",
        color: "primary.700",
        "&:hover": {
            cursor: "pointer"
        },
        "& svg": {
            color: "greyscale.900",
        },
    },
    bar: {
        height: 8,
        width: '95%',
        borderRadius: '2px',
        backgroundColor: "greyscale.200",
        "& .MuiLinearProgress-bar": {
            backgroundColor: "#7E7ED6"
        }
    },
    chip: {
        color: '#7675DF',
        backgroundColor: "#F0F3FD"
    },
    borderTop: {
        borderTop: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
    }
}