import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
    footerContainer: {
        border: "2px solid #e1e1e1",
        borderRadius: "0px 0px 4px 4px",
        borderStyle: "none solid solid solid"
    },
    footer: {
        paddingBottom: 0,
        paddingTop: 0
    },
    cardContent: {
        paddingInline: 0,
        paddingTop: spacing(1)
    },
    action: {
        marginRight: 0
    },
    baseCard: {
        backgroundColor: "inherit"
    }
}));
const HomepageCard = ({ header, content, footer }) => {
    const classes = useStyles();
    return (
        <Card className={classes.baseCard} elevation={0}>
            <CardHeader
                disableTypography
                title={header?.title ?? null}
                action={header?.action ?? null}
                style={{ paddingInline: 0 }}
                classes={{ action: classes.action }}
            />
            <CardContent className={classes.cardContent}>
                <Grid container direction="column">
                    {content}
                    {footer && (
                        <Grid className={classes.footerContainer}>
                            <CardHeader
                                className={classes.footer}
                                disableTypography
                                title={footer?.title ?? null}
                                avatar={footer?.avatar ?? null}
                            />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default HomepageCard;

HomepageCard.propTypes = {
    header: PropTypes.object.isRequired,
    content: PropTypes.node.isRequired,
    footer: PropTypes.object
};
