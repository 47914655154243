import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./number-field.html";
import TextField from "./text-field";

var keyCodes = {
    DELETE: 46,
    BACKSPACE: 8,
    TAB: 9,
    CHAR_C: 67,
    CHAR_X: 88,
    CHAR_V: 86,
    CHAR_A: 65
};

var View = TextField.View.extend({
    template: _.template(template),

    events: {
        "keyup input": "setValueFromView",
        "change input": "setValueFromView",
        "keydown input": "keyDown"
    },

    keyDown: function(event) {
        if (this.allowNegative) {
            return;
        }

        // allow arrow keys, do not trigger the change event is those are pressed
        if (event.keyCode >= 37 && event.keyCode <= 40) {
            return;
        }

        if (event.keyCode === keyCodes.TAB) {
            return;
        }

        //Allow Key combinations for select all copy ,cut and paste
        if (
            event.metaKey &&
            (event.keyCode === keyCodes.CHAR_A ||
                event.keyCode === keyCodes.CHAR_C ||
                event.keyCode === keyCodes.CHAR_X ||
                event.keyCode === keyCodes.CHAR_V)
        ) {
            return;
        }
        // if not DELETE or BACKSPACE
        if (event.keyCode !== keyCodes.DELETE && event.keyCode !== keyCodes.BACKSPACE) {
            if (event.keyCode < 95) {
                // 0-9 keys case
                if (event.keyCode < 48 || event.keyCode > 57) {
                    event.preventDefault();
                }
            } else {
                // 0-9 from numpad case
                if (event.keyCode < 96 || event.keyCode > 105) {
                    event.preventDefault();
                }
            }
        }

        this.setValueFromView();
    },

    getValue: function() {
        var value = this.model.get("value");
        if (_.isNumber(value)) {
            return value;
        }

        if (_.isString(value)) {
            return value.length ? parseInt(value, 10) : null;
        }

        return null;
    },

    setViewValue: function() {
        this.$("input").val(this.getValue());
    },

    onRender: function() {
        // if the initial value is negative then disregard the "unsigned validation"
        if (this.model.value < 0) {
            this.allowNegative = true;
        }
        BaseControl.View.prototype.onRender.apply(this, arguments);
        
        this.$("input").val(this.model.value);

        if (this.allowNegative === false) {
            this.$("input").attr({
                min: 0
            });
        }
    },

    onShow: function() {
        this.$("input").bind("mousewheel", function() {
            this.blur();
        });
    }
});

export default _.extend({}, BaseControl, {
    View: View
});
