import { makeStyles } from "@material-ui/core/styles";

const colors = {
    grey: "#4E5C62",
    blue: "#00A7E5",
    green: "#0D874C",
    orange: "#F9A825",
    grey_dark: "#57676E",
    red: "#DD3711",
    purple: "#541388"
};

export default makeStyles(({ spacing, typography }) => ({
    chipRoot: {
        padding: spacing(0, 1, 0, 0.5),
        height: 20,
        marginTop: spacing(0.25),
        "&.appStatus-created, &.appStatus-deploying": {
            color: colors.grey,
            backgroundColor: colors.grey + "1A !important"
        },
        "&.appStatus-deployed, &.appStatus-starting, &.appStatus-stopped": {
            color: colors.blue,
            backgroundColor: colors.blue + "1A !important"
        },
        "&.appStatus-quiesced, &.appStatus-completed": {
            color: colors.blue,
            backgroundColor: colors.blue + "1A !important"
        },
        "&.appStatus-terminated, &.appStatus-invalid": {
            color: colors.red,
            backgroundColor: colors.red + "1A !important"
        },
        "&.appStatus-deploy_failed, &.appStatus-not_enough_servers": {
            color: colors.red,
            backgroundColor: colors.red + "1A !important"
        },
        "&.appStatus-halted": {
            color: colors.grey_dark,
            backgroundColor: colors.orange + "1A !important"
        },
        "&.appStatus-running, &.appStatus-stopping, &.appStatus-quiescing": {
            color: colors.green,
            backgroundColor: colors.green + "1A !important"
        },
        "&.enabled": {
            color: "white",
            backgroundColor: colors.green + "!important",
            padding: spacing(0, 1, 0, 1) + "!important"
        },
        "&.disabled": {
            color: "white",
            backgroundColor: colors.grey_dark + "!important",
            padding: spacing(0, 1, 0, 1) + "!important"
        },
        "&.smart_alert": {
            color: "white",
            backgroundColor: colors.purple + "!important",
            padding: spacing(0, 1, 0, 1) + "!important"
        }
    },
    chipLabel: {
        ...typography.caption,
        color: "inherit",
        fontWeight: 600
    },
    chipIcon: {
        width: 14,
        height: 14,
        "&.appStatus-created, &.appStatus-deploying": {
            fill: colors.grey
        },
        "&.appStatus-deployed, &.appStatus-starting, &.appStatus-stopped": {
            fill: colors.blue
        },
        "&.appStatus-quiesced, &.appStatus-completed": {
            fill: colors.blue
        },
        "&.appStatus-terminated, &.appStatus-invalid": {
            fill: colors.red
        },
        "&.appStatus-deploy_failed, &.appStatus-not_enough_servers": {
            fill: colors.red
        },
        "&.appStatus-halted": {
            fill: colors.orange
        },
        "&.appStatus-running, &.appStatus-stopping, &.appStatus-quiescing": {
            fill: colors.green
        }
    }
}));
