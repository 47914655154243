import csv from "text-loader!./inline-doc-dictionary.csv";
import Papa from "papaparse";
const csvData = Papa.parse(csv, { header: true });
const output = {};
csvData.data.forEach(entry => {
    if (entry.key !== "") {
        output[entry.key] = entry;
    }
  
});

export default output;
