import App from "app";
import ApplicationController from "lib/controllers/application_controller";
import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import landingtemplate from "./preview_landing.html";
import ModalManager from "app/components/common/modal/ModalManager";
import fileBrowser from "./fileBrowser";
import previewPage from "./previewPage";
import hdfsBrowser from "./hdfsBrowser";
import sampleBrowser from "./sampleBrowser";
import ReactDOM from "react-dom/client";
import React from "react";
import FileBrowserDirectSelector from "src/generic/filebrowser/filebrowser_direct_select";

var modalManager = new ModalManager({
    container: "#preview"
});
var Preview = {};

export const Controller = ApplicationController.extend({
    initialize: function() {
        var self = this;
        this.mainview = new mainView();
        this.show(this.mainview);
        this.landing = new landingView();

        /**Close all modals before the view is destroyed**/
        this.listenTo(this.mainview, "before:destroy", function() {
            modalManager.remove_all_modals();
        });

        this.listenTo(App.vent, "preview:file", function(model) {
            self.renderPreviewFile(model, true);
        });
    },
    showPage: function() {
        this.mainview.mainRegion.show(this.landing);
    },
    /***
     This is the method that fires fires off the preview page
     The attributes it needs from model param is 'previewPath' which contains the path of file to preview
     and 'fileType' which tells the server which type of Reader it should use. Current options for fileType are 'FileReader'
     and 'HDFSReader'
     */
    renderPreviewFile: function(model, clearLanding) {
        /* If we have a URL from Hadoop, we preprend it to the path */
        if (model.get("url")) {
            var url = model.get("url");
            var str = (url += model.get("previewPath"));
            model.set("previewPath", str);
        }

        if (!model.get("fileType") && !model.get("previewPath")) {
            return;
        }

        if (clearLanding) {
            this.landing.destroy();
        }

        var route = "/preview" + "/" + model.get("fileType") + "?" + encodeURIComponent(model.get("previewPath"));

        // Navigate can be not exist when it's a first opened page
        App.navigate?.(route, {
            trigger: false
        });
        var options = {
            model: model,
            mainView: this.mainview
        };
        new previewPage.Controller(options);
    }
});

var mainView = Backbone.Marionette.LayoutView.extend({
    id: "preview",
    template: _.template("<div id='mainregion'></div>"),
    regions: {
        mainRegion: "#mainregion"
    }
});

var landingView = Backbone.Marionette.ItemView.extend({
    id: "landing",
    template: _.template(landingtemplate),
    events: {
        "click #filebutton": "browseFiles",
        "click #connectbutton": "browseConnect",
        "click #samplebutton": "browseSamples"
    },
    on_click: function(e) {
        if (e.target.name === "file") {
            App.vent.trigger("file-upload");
        }
    },
    onShow: function() {
        let node = this.$el.find("#fileSelectUplodBox")[0];

        try {
            const root = ReactDOM.createRoot(node);
            this.container = root.render(<FileBrowserDirectSelector />);
        } catch (e) {
            console.error("Error rendering Filebrowser", e);
        }
    },
    browseSamples: function() {
        var model = new Backbone.Model({});
        var $origin = $("#samplebutton");
        var sampleBrowserView = new sampleBrowser.View({
            id: "sampleDialog",
            model: model,
            origin: $origin,
            container: "#preview"
        });
        new sampleBrowser.Controller({
            view: sampleBrowserView,
            model: model
        });
        var sampleBrowserModal = modalManager.add(sampleBrowserView);
        this.listenTo(sampleBrowserModal, "form-submit-success", function(sampleBrowserModal) {
            sampleBrowserView.save(sampleBrowserView.model);
            sampleBrowserModal.destroy();
            App.vent.trigger("preview:file", sampleBrowserView.model);
        });
    },

    /* Handles when user clicks 'connect' button */
    browseConnect: function() {
        var $origin = $("#connectbutton");
        var id = "connectDialog";
        var model = new Backbone.Model({});
        var fileBrowserView = new hdfsBrowser.View({
            id: id,
            model: model,
            container: "#preview",
            origin: $origin
        });
        new hdfsBrowser.Controller({
            view: fileBrowserView,
            model: model
        });
        var fbModalView = modalManager.add(fileBrowserView);

        this.listenTo(fbModalView, "form-submit-success", function(fileBrowserView) {
            fileBrowserView.prepareModel(fileBrowserView.model);
            fileBrowserView.save(fileBrowserView.model);
            fbModalView.destroy();
            App.vent.trigger("preview:file", fileBrowserView.model);
        });
    },
    browseFiles: function() {
        return;

        var $origin = $("#filebutton");
        var id = "browser-dialog";
        var server = window.location.hostname;
        // Dialog change: the dialog no longer needs a specific model.
        // Static dialog-specific settings, e.g. submit_text, go in the template.
        // The model passed in is the dynamic data that you're working with, that could potentially need saving.
        // e.g. the server name
        var model = new Backbone.Model({
            server: server
        });

        /* User input in file browser view will populate filebrowser's model */
        var fileBrowserView = new fileBrowser.View({
            id: id,
            model: model,
            container: "#preview",
            origin: $origin
        });
        new fileBrowser.Controller({
            view: fileBrowserView
        });
        var fbModalView = modalManager.add(fileBrowserView);

        this.listenTo(fbModalView, "form-submit-success", function(fileBrowserView) {
            if (!fileBrowserView.model.get("fileType")) {
                //can't do anything as no file selected
                return;
            }
            fileBrowserView.save(fileBrowserView.model);
            fbModalView.destroy();
            App.vent.trigger("preview:file", fileBrowserView.model);
        });
    }
});

export default Preview;
