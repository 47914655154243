import React from "react";
import { StriimTypography } from "@striim/striim-ui";

const CellRenderer = ({ cellInfo, idsToDisable }) => {
    const revoked = cellInfo.row.original.disabled || idsToDisable.indexOf(cellInfo.row.original.id) !== -1;

    return (
        <StriimTypography variant="body4" color={revoked ? "greyscale.400" : "greyscale.900"}>
            {cellInfo.cell.value}
        </StriimTypography>
    );
};

const applyCellRendererToColumns = (columns, idsToDisable) => {
    return columns.map(c => {
        if (c.accessor === "actions") {
            return c;
        }

        return {
            ...c,
            // eslint-disable-next-line react/display-name
            Cell: cellInfo => <CellRenderer cellInfo={cellInfo} idsToDisable={idsToDisable} />
        };
    });
};

export { CellRenderer, applyCellRendererToColumns };
