import React, { useEffect } from "react";
import { Grid, Box } from "@mui/material";
import PropTypes from "prop-types";
import WelcomeMessage from "../../../homepage/welcome_message/welcome_message";
import Explore from "src/modules/homepage/explore/explore";
import { StriimButton, StriimTypography } from "@striim/striim-ui";
import widgetResources from "./resources/widgetResources";
import { styles } from "./new_user.styles";
import AppAdapterHomePage from "./app_adapter_homepage";
import securityService from "../../../../../core/services/securityService/securityService";
import userPreferences from "core/services/userPreferences/user-preferences";
import AddCircledIcon from "../../../../../app/images/striimline/add-circled.svg";
import homepageService from "../../../homepage/homepage_service";
/*
 * New user page
 * Renders the default basic widgets for new users
 */

const NewUserScreen = ({ username, video }) => {
    const pageInfo = homepageService.getCurrentHomePageInfo();

    const isAppAdapterLicense = securityService.isAppAdapterLicense();

    useEffect(() => {
        // reset widget customization when all apps are removed
        userPreferences.put("seenWidgets", widgetResources.defaultSeenWidgets);
    }, []);

    return (
        <>
            {isAppAdapterLicense ? (
                <AppAdapterHomePage username={username} video={video} />
            ) : (
                <DefaultHomePage username={username} video={video} pageInfo={pageInfo} />
            )}
        </>
    );
};

const DefaultHomePage = ({ username, video, pageInfo }) => {
    return (
        <Grid container direction="row" data-test-id="new-user" sx={styles.container}>
            <Grid container sx={styles.topContainer} flexDirection={"row"} item>
                <Grid item sx={styles.welcomeMessageContainer} xs={6}>
                    <div data-test-id="header--homepage-recent-apps-empty" style={styles.message}>
                        <WelcomeMessage username={username} />
                    </div>
                    {pageInfo?.description && (
                        <div style={styles.info} data-test-id="paragraph--homepage-recent-apps-empty">
                            <StriimTypography
                                variant="body4"
                                color="greyscale.700"
                                data-test-id="paragraph--homepage-recent-apps-empty"
                            >
                                {pageInfo.description}
                            </StriimTypography>
                        </div>
                    )}
                    <StriimButton
                        variant="primary"
                        data-test-id="create-an-app--button"
                        onClick={pageInfo.buttonOnClick}
                        startIcon={<AddCircledIcon color="transparent" />}
                        sx={styles.button}
                    >
                        {pageInfo.button}
                    </StriimButton>
                </Grid>
                <Grid item xs={6} sx={styles.imageContainer} data-test-id="image--homepage-recent-apps-empty">
                    <img alt="homepage image" src={pageInfo.imgsrc} style={pageInfo.imgstyle} />
                </Grid>
            </Grid>
            <Grid item sx={styles.bottomContainer} xs>
                <Explore data-test-id="explore" video={video} />
            </Grid>
        </Grid>
    );
};

export default NewUserScreen;

NewUserScreen.propTypes = {
    username: PropTypes.string.isRequired,
    video: PropTypes.object.isRequired
};
