import React from "react";
import PropTypes from "prop-types";
import { StriimTypography } from "@striim/striim-ui";
import securityService from "../../../../core/services/securityService/securityService";
import homepageService from "../homepage_service";

const WelcomeMessage = ({ username, isAppAdapterLicense = false }) => {
    /**
     * View
     */
    const sourceName = isAppAdapterLicense ? securityService.getCurrentAppAdapterLicenseName() : "";

    const messageInfo = homepageService.getWelcomeMessageInfo(sourceName, isAppAdapterLicense);

    return (
        <>
            <StriimTypography
                variant={messageInfo.titleVariant || "h1"}
                style={{ marginBottom: isAppAdapterLicense ? 20 : 2 }}
                color="primary.700"
            >
                Hi <span style={{ wordBreak: "break-all" }}>{username}</span>, Welcome to Striim!
            </StriimTypography>
            <StriimTypography variant="h1" style={{ marginBottom: 2, marginTop: 2 }} color="primary.700">
                {messageInfo.desc}
            </StriimTypography>
        </>
    );
};

export default WelcomeMessage;

WelcomeMessage.propTypes = {
    username: PropTypes.string.isRequired
};
