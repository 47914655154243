import $ from "jquery";

var namespace = "click.metadatabrowserHideDropdown";

export default {
    attach: function () {
        $(document).bind(namespace, function (evt) {
            if ($(evt.target).closest(".mb-filter-obj").length === 0) {
                // click happened outside of dropdown filter mb-filter-obj, hide any visible dropdown items
                $(".hide-dropdown").hide();
                $(".dropdown-text").val("");
                $(".dropdown-text").trigger("keyup", {
                    isOutsideClick: true,
                });
            }
        });
    },

    detach: function () {
        $(document).unbind(namespace);
    },
};
