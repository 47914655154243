import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OpenSidebar from "app/images/striimline/sidebar-collapse.svg";
import OpenInNewTab from "app/images/striimline/open-in-new-tab.svg";
import IconButton from "@mui/material/IconButton";
import InlineDocContainer from "./inline-doc-container";
import { useEffect, useRef, useState } from "react";
import Fade from "@mui/material/Fade";
import WithTooltip from "../../generic/tooltip/tooltip";

export const InlineDocContext = React.createContext([]);

/**
 * Creates a panel with documentation on the right
 * @param path path of content from https://striim.com/docs/
 * @param children content of the left sidebar
 * @param drawerWidth default width of the container. Default 350px
 * @param inlineDocContent The content need to show into the right sidebar
 * @returns {JSX.Element}
 * @constructor
 */

const getStyles = ({ theme, drawerWidth, drawerWidthClosed, contentPadding, open, contentHeight, has404 }) => ({
    contentSX: { flexGrow: 1, flexShrink: 1, display: "block" },
    drawerSX: {
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.standard
        }),
        width: open ? drawerWidth : drawerWidthClosed,
        padding: open ? `4px 0px ${contentPadding}px 4px` : "4px 4px",
        height: contentHeight + "px",
        backgroundColor: "#F7FBFF",
        overflow: "hidden",
        borderLeft: "1px solid #D8E4F0",
        display: has404 ? "none !important" : "block",
        // global/reset.scss files override the inline css styles of documentation links, this would revert the override and retain the original styles of inline html
        ul: {
            padding: "revert !important",
            listStyle: "revert !important",
            li: {
                listStyleType: "revert !important"
            }
        }
    },
    wrapperSX: {
        display: "flex",
        displayDirection: "row",
        displayWrap: "noWrap",
        justifyContent: "normal",
        minHeight: "100px",
        flexGrow: 1,
        flexShrink: 1,
        position: "relative"
    }
});

const drawerWidthClosed = "50px";
const contentPadding = 8;

export default function InlineDocPanel({ closed = false, path, children, drawerWidth = "40vw", inlineDocContent }) {
    const [contentHeight, setContentHeight] = useState(0);
    const [open, setOpen] = useState(!closed);
    const [has404, setHas404] = useState(true);
    const theme = useTheme();
    const ref = useRef(null);

    useEffect(() => {
        if (!ref.current) return;
        const resizeObserver = new ResizeObserver(() => {
            setContentHeight(ref.current.clientHeight);
        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect(); // clean up
    }, []);

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const styles = getStyles({
        theme,
        drawerWidth,
        drawerWidthClosed,
        contentPadding,
        open,
        contentHeight,
        has404
    });

    return (
        <InlineDocContext.Provider value={[has404, setHas404]}>
            <Box sx={styles.wrapperSX} data-content-path={path}>
                <Box sx={styles.contentSX}>
                    <Box ref={ref}>{children}</Box>
                </Box>
                <Box sx={styles.drawerSX} hidden={has404}>
                    <div
                        style={{
                            display: "inline-flex"
                        }}
                    >
                        {WithTooltip(
                            <IconButton onClick={handleDrawerOpen}>
                                <OpenSidebar />
                            </IconButton>,
                            "View or hide Documentation for this screen."
                        )}
                    </div>
                    <Fade in={open} timeout={500}>
                        <Box
                            sx={{
                                overflow: "auto",
                                height: "calc(100% - 50px)",
                                width: drawerWidth - 2 * contentPadding + "px",
                                marginRight: 0
                            }}
                        >
                            {!React.isValidElement(inlineDocContent) ? (
                                <InlineDocContainer
                                    root={inlineDocContent.root}
                                    path={inlineDocContent.path}
                                    className={inlineDocContent.className}
                                    preformatOutput={inlineDocContent.preformatOutput}
                                    cssSelector={inlineDocContent.cssSelector}
                                />
                            ) : (
                                inlineDocContent
                            )}
                        </Box>
                    </Fade>
                </Box>
            </Box>
        </InlineDocContext.Provider>
    );
}
