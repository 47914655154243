import React, { useState } from "react";
import { StriimModal, StriimTypography } from "@striim/striim-ui";
import { Grid } from "@mui/material";
import { StriimInputField } from "@striim/striim-ui-v2";

interface DeleteConnectionProfileModalProps {
    isVisible: boolean;
    onDeleteClick: () => void;
    setShowDeleteConfirmationModal: (value: boolean) => void;
}

const DeleteConnectionProfileModal = ({
    isVisible,
    onDeleteClick,
    setShowDeleteConfirmationModal
}: DeleteConnectionProfileModalProps) => {
    const [confirmInputValue, setConfirmInputValue] = useState<string>("");
    return (
        <StriimModal
            size="small"
            isVisible={isVisible}
            variant="critical"
            autoHeight
            onCancel={() => {
                setConfirmInputValue("");
                setShowDeleteConfirmationModal(false);
            }}
            onConfirm={() => {
                setConfirmInputValue("");
                onDeleteClick();
            }}
            cancelContent={"Cancel"}
            confirmButtonV
            confirmContent={"Delete"}
            titleContent={
                <StriimTypography variant="h2">Are you sure want to delete connection profile?</StriimTypography>
            }
            cancelButtonProps={{ "data-test-id": "delete-connection-profile-cancel-btn" }}
            confirmButtonProps={{
                "data-test-id": "delete-connection-profile-delete-btn",
                disabled: confirmInputValue !== "YES"
            }}
            dialogProps={{ "data-test-id": "delete-connection-profile-modal" }}
        >
            <Grid display="flex" flexDirection="column" gap={1.25}>
                <StriimTypography variant="body4">
                    Please proceed to delete your selected connection profile.
                    <br /> This action cannot be undone.
                </StriimTypography>
                <StriimTypography variant="caption4">Type YES to confirm</StriimTypography>
                <StriimInputField
                    name="delete-input-field"
                    value={confirmInputValue}
                    onChange={value => setConfirmInputValue(value)}
                    helperText={confirmInputValue === "yes" ? "Type YES in uppercase to confirm" : ""}
                    error={confirmInputValue === "yes"}
                />
            </Grid>
        </StriimModal>
    );
};

export default DeleteConnectionProfileModal;
