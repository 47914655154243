import React, { Dispatch, SetStateAction } from "react";
import { StriimMessageBox, StriimLink, StriimTypography, StriimControlLabel, StriimCheckbox } from "@striim/striim-ui";
import { Grid, SvgIcon } from "@mui/material";

import Bulb from "app/images/striimline/bulb.svg";
import { Lock, Settings, InfoCircle } from "src/generic/icon/customIcons";
import { styles } from "./ai-info-section.styles";
import { Documentation } from "../../../../../generic/icon/customIcons";
import dictionary from "../../../../../../app/components/common/helpable/online-help-dictionary";

interface AIInfoSectionProps {
    setShowAIInfo?: Dispatch<SetStateAction<boolean>>;
    showTopBorder?: boolean;
    showBottomBorder?: boolean;
    containerProps?: any;
}

const AIInfoSection = ({
    setShowAIInfo,
    showTopBorder = true,
    showBottomBorder = true,
    containerProps
}: AIInfoSectionProps) => {
    const SherlockHelpLink = dictionary.get()["AI_INSIGHTS_SHERLOCK"].href;
    return (
        <Grid
            container
            py={3}
            px={4}
            flexDirection={"column"}
            sx={[styles.container, showTopBorder && styles.topBorder, showBottomBorder && styles.bottomBorder]}
            maxHeight={390}
            {...containerProps}
        >
            <Grid container width={"100%"} item maxWidth={630} gap={2}>
                <Grid container>
                    <StriimMessageBox
                        type="NOTIFICATION"
                        customIcon={<SvgIcon component={InfoCircle} sx={styles.infoIcon} />}
                        heading={
                            <>
                                <StriimTypography variant="caption3" color="greyscale.900" sx={styles.infoText}>
                                    Sherlock uses AI engines that may sometimes misclassify the information.
                                </StriimTypography>
                                <StriimLink
                                    endIcon={<SvgIcon component={Documentation} sx={styles.docIcon} />}
                                    href={SherlockHelpLink}
                                    target="_blank"
                                    sx={styles.link}
                                ></StriimLink>
                            </>
                        }
                        customCardStyles={styles.infoBox}
                    />
                </Grid>
                <Grid container flexDirection={"column"} gap={1} width={"100"}>
                    <Grid item display={"flex"} alignItems={"center"} gap={0.5}>
                        <SvgIcon component={Bulb} sx={styles.icon} />
                        <StriimTypography variant="h4" color="greyscale.900">
                            Discovery Process
                        </StriimTypography>
                    </Grid>
                    <Grid item>
                        <StriimTypography variant="body4" color="greyscale.800">
                            Sherlock samples your sources to identify sensitive data using classification algorithms.
                            <StriimLink
                                endIcon={<SvgIcon component={Documentation} sx={styles.docIcon} />}
                                href={SherlockHelpLink}
                                target="_blank"
                                sx={styles.link}
                            ></StriimLink>
                        </StriimTypography>
                    </Grid>
                </Grid>
                <Grid container flexDirection={"column"} gap={1} width={"100"}>
                    <Grid item display={"flex"} alignItems={"center"} gap={0.5}>
                        <SvgIcon component={Lock} sx={styles.icon} />
                        <StriimTypography variant="h4" color="greyscale.900">
                            Privacy
                        </StriimTypography>
                    </Grid>
                    <Grid item>
                        <StriimTypography variant="body4" color="greyscale.800">
                            Privacy of your data is determined by the AI engine.
                            <StriimLink
                                endIcon={<SvgIcon component={Documentation} sx={styles.docIcon} />}
                                href={SherlockHelpLink}
                                target="_blank"
                                sx={styles.link}
                            ></StriimLink>
                        </StriimTypography>
                    </Grid>
                </Grid>
                <Grid container>
                    <StriimControlLabel
                        label={
                            <StriimTypography variant="body4" color="greyscale.800">
                                Do not show again
                            </StriimTypography>
                        }
                        control={
                            <StriimCheckbox
                                onChange={event => {
                                    setShowAIInfo && setShowAIInfo(event.target.checked);
                                    localStorage.setItem("hideAIInfo", JSON.stringify(event.target.checked));
                                }}
                            />
                        }
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AIInfoSection;
