import React from "react";
import { StriimTypography } from "@striim/striim-ui";

import { styles } from "./sentinel-table.styles";
import { useNavigate } from "react-router";
import { getAppName } from "../../service/sentinel-monitor-utils";
import navigateTo from "../../../../../../navigate-to";

const CellItem = ({ value }) => (
    <StriimTypography variant="body4" color={"greyscale.900"}>
        {value}
    </StriimTypography>
);

const Columns = [
    {
        headerName: `Sensitive Data Identifier`,
        field: "dataIdentifier",
        flex: 1,
        sortable: true,
        resizable: true,
        headerCheckboxSelection: false,
        cellRenderer: ({ value }) => <CellItem value={value} />
    },
    {
        headerName: "Sentinel Name",
        field: "sentinelName",
        flex: 1,
        sortable: true,
        resizable: true,
        cellRenderer: ({ value, data }) => (
            <StriimTypography
                sx={styles.appLink}
                variant="body4"
                onClick={() => navigateTo.AppSentinelView(data.appName, data.sentinelName, true)}
            >
                {value}
            </StriimTypography>
        )
    },
    {
        headerName: "App Name",
        field: "appName",
        sortable: true,
        resizable: true,
        cellRenderer: ({ value, data }) => (
            <StriimTypography
                sx={styles.appLink}
                variant="body4"
                onClick={() => navigateTo.AppSentinelLiveMonitor(data.appName, null, true)}
            >
                {getAppName(value)}
            </StriimTypography>
        )
    },
    {
        headerName: "Namespace",
        field: "namespace",
        sortable: true,
        resizable: true,
        cellRenderer: ({ value }) => <CellItem value={value} />
    }
];

// Dummy Data
const SentinelData = [
    {
        dataIdentifier: "Credit Card Number",
        sentinelName: "Sentinel1",
        appName: "App1",
        namespace: "admin",
        action: "encrypt",
        importance: "high"
    },
    {
        dataIdentifier: "Social Security Number",
        sentinelName: "Sentinel2",
        appName: "App1",
        namespace: "admin",
        action: "encrypt",
        importance: "high"
    },
    {
        dataIdentifier: "Social Security Number",
        sentinelName: "Sentinel1",
        appName: "App3",
        namespace: "msd",
        action: "no_action",
        importance: "low"
    },
    {
        dataIdentifier: "Credit Card Number",
        sentinelName: "Sentinel3",
        appName: "App2",
        namespace: "admin",
        action: "mask",
        importance: "low"
    },
    {
        dataIdentifier: "National Health Identification Number",
        sentinelName: "Sentinel4",
        appName: "App2",
        namespace: "admin",
        action: "no_action",
        importance: "medium"
    }
];

export { SentinelData, Columns };
