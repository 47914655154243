import React, { useState } from "react";
import {
    StriimModal,
    StriimSimpleTable,
    StriimSimpleTableCell,
    StriimDropdown,
    StriimMenuItem,
    StriimMenuList,
    StriimTypography,
    StriimButton,
    StriimInputField
} from "@striim/striim-ui";
import { InputAdornment } from "@mui/material";
import { ContentCopyRounded, Search, MoreHorizRounded } from "@mui/icons-material";
import growl from "app/components/common/growl";

const PropertyVariablesPresent = ({ propertyVariables, onRemoved }) => {
    const [searchQuery, setSearchQuery] = useState();

    const deleteRow = data => {
        onRemoved(data);
    };

    const copyRow = data => {
        //(https://stackoverflow.com/questions/51805395/navigator-clipboard-is-undefined)
        function copyToClipboard(textToCopy) {
            // navigator clipboard api needs a secure context (https)
            if (navigator.clipboard && window.isSecureContext) {
                // navigator clipboard api method'
                return navigator.clipboard.writeText(textToCopy);
            } else {
                // text area method
                let textArea = document.createElement("textarea");
                textArea.value = textToCopy;
                // make the textarea out of viewport
                textArea.style.position = "fixed";
                textArea.style.left = "-999999px";
                textArea.style.top = "-999999px";
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                return new Promise((res, rej) => {
                    // here the magic happens
                    document.execCommand("copy") ? res() : rej();
                    textArea.remove();
                });
            }
        }
        copyToClipboard("$" + data.name)
            .then(() => growl.success("Property variable successfully copied to clipboard"))
            .catch(() => growl.error("Error copying " + "$" + data.name));
    };

    const PropertyVariablesRow = ({ data }) => {
        const [dropConfirmVisible, setDropConfirmVisible] = useState(false);

        const styles = {
            deleteLink: {
                color: "critical.500"
            }
        };

        const menu = (
            <StriimMenuList>
                <StriimMenuItem
                    data-test-id="property-variable-delete-button"
                    sx={styles.deleteLink}
                    onClick={() => setDropConfirmVisible(true)}
                >
                    Delete
                </StriimMenuItem>
            </StriimMenuList>
        );

        return (
            <>
                <StriimSimpleTableCell
                    value={`${data.nsName}.${data.name}`}
                    textVariant="body4"
                    tableCellProps={{ "data-test-id": "property-variable-name" }}
                />
                <StriimSimpleTableCell
                    tableCellProps={{
                        "data-test-id": "property-variable-name-usage"
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <StriimTypography variant="body4">${data.name}</StriimTypography>
                        <StriimButton variant="primaryText" onClick={() => copyRow(data)}>
                            <ContentCopyRounded />
                        </StriimButton>
                    </div>
                </StriimSimpleTableCell>
                <StriimSimpleTableCell
                    tableCellProps={{
                        "data-test-id": "menu-property-variable",
                        sx: { width: "50px" }
                    }}
                >
                    <StriimDropdown extendOnClick content={menu} closeOnSelect>
                        <StriimButton variant="errorText">
                            <MoreHorizRounded />
                        </StriimButton>
                    </StriimDropdown>
                </StriimSimpleTableCell>
                <StriimModal
                    size="small"
                    variant="error"
                    isVisible={dropConfirmVisible}
                    centerContent
                    autoHeight
                    onCancel={state => setDropConfirmVisible(state)}
                    onConfirm={() => deleteRow(data)}
                    titleContent={<StriimTypography variant="h2">Drop property variable</StriimTypography>}
                    confirmContent="Drop"
                    confirmButtonProps={{ "data-test-id": "delete-property-variable-confirm-button" }}
                    dialogProps={{ "data-test-id": "delete-property-variable-modal" }}
                >
                    <StriimTypography variant="body4">
                        Are you sure you want to delete ${data.name}? It might affect your existing services. This
                        action cannot be undone.
                    </StriimTypography>
                </StriimModal>
            </>
        );
    };

    const head = ["Property Variable", "Property Variable Usage", "|"];
    const getComponents = () => {
        let filteredValues = propertyVariables?.sort((a, b) => (a.id > b.id ? 1 : -1));
        if (searchQuery) {
            filteredValues = filteredValues?.filter(propertyVariable => {
                return (
                    propertyVariable
                        ?.get("nsName")
                        ?.toLowerCase()
                        .includes(searchQuery?.toLowerCase()) ||
                    propertyVariable
                        ?.get("name")
                        ?.toLowerCase()
                        ?.includes(searchQuery?.toLowerCase())
                );
            });
        }
        return filteredValues?.length ? (
            <>
                <StriimSimpleTable CustomRowComponent={PropertyVariablesRow} head={head} data={filteredValues} />
            </>
        ) : (
            <div data-test-id="no-property-variables">Nothing matches your search criteria</div>
        );
    };
    return (
        <div style={{ height: "100%", width: "100%" }}>
            <div style={{ width: "400px", marginBlock: "16px" }}>
                <StriimInputField
                    id="data-test-id-property-variable-filter-input"
                    onChange={string => {
                        if (string.length) {
                            setSearchQuery(string);
                        } else {
                            setSearchQuery();
                        }
                    }}
                    placeholder="Search by property variable name or namespace"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{ mr: 0 }}>
                                <Search opacity={0.45} />
                            </InputAdornment>
                        )
                    }}
                />
            </div>
            {getComponents()}
        </div>
    );
};

export default PropertyVariablesPresent;
