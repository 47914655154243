import React, { useState } from "react";
import { StriimButton } from "@striim/striim-ui";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router";

import AI_ROUTES from "../../../routes.json";

interface GuardianFooterProps {
    handleNextClick: () => void;
    handleBackClick: () => void;
    stepId: number;
    StepNames: string[];
    selectedRowsCount: number;
    formValues: object;
    hideBackButton: boolean;
    runButtonClicked: boolean;
    editJob: boolean;
    handleSaveAndExit: (job: any) => void;
    isValid: boolean;
}

const GuardianFooter = ({
    handleNextClick,
    handleBackClick,
    stepId,
    StepNames,
    selectedRowsCount,
    formValues,
    hideBackButton = false,
    runButtonClicked = false,
    editJob,
    handleSaveAndExit,
    isValid
}) => {
    const [jobStarted, setJobStarted] = useState(false);
    const navigate = useNavigate();
    const footerStyles = {
        display: "flex",
        "flex-direction": "row",
        "justify-content": "flex-end",
        "& > *": {
            marginRight: "8px"
        }
    };
    const buttonStyles = {
        marginRight: "8px"
    };
    const checkIfCanGoNext = () => {
        // last step as default enabled
        if (stepId === StepNames.length - 1) return selectedRowsCount !== 0;

        // enable if form values, steps IDs based on steps length
        if (StepNames.length === 2 && stepId === 0) {
            return isValid;
        }

        if (StepNames.length === 3 && stepId === 1) {
            return isValid;
        }

        // can't go next without selected rows
        return selectedRowsCount !== 0;
    };
    const nextButtonLabel = stepId === StepNames.length - 1 ? "Run" : "Next";
    const canGoNext = checkIfCanGoNext();

    const hideSaveAndExit = !editJob && StepNames[stepId] === "Select Apps";

    return (
        <Grid container>
            <Grid item xs={6}>
                <StriimButton
                    variant="text"
                    onClick={() => navigate(AI_ROUTES.sherlockAI)}
                    disabled={jobStarted || runButtonClicked}
                >
                    Cancel
                </StriimButton>
            </Grid>

            <Grid item xs={6} sx={footerStyles}>
                {!hideSaveAndExit && (
                    <StriimButton
                        variant="text"
                        onClick={() => handleSaveAndExit(editJob)}
                        sx={buttonStyles}
                        disabled={runButtonClicked || !isValid}
                    >
                        Save and Exit
                    </StriimButton>
                )}

                {!hideBackButton && (
                    <StriimButton variant="secondary" onClick={handleBackClick} sx={buttonStyles}>
                        Back
                    </StriimButton>
                )}

                <StriimButton
                    variant="primary"
                    onClick={() => {
                        setJobStarted(runButtonClicked);
                        handleNextClick();
                    }}
                    disabled={!canGoNext || runButtonClicked}
                >
                    {nextButtonLabel}
                </StriimButton>
            </Grid>
        </Grid>
    );
};

export default GuardianFooter;
