import Backbone from "backbone";
import SlidingLayout from "app/components/common/wizardmaker/slidingLayout";
import FlatLayout from "app/components/common/wizardmaker/flatLayout";
import TabLayout from "app/components/common/wizardmaker/tabLayout";
/****
FOR: Creating wizards
HOW: Create an array of steps that need to be completed using the wizard. Each step will have a title and a view.
Ex: var stepsArray=[
				{title:'Connect to Oracle', view:step1View},
				{title:'Configure CDC Reader', view:step2View},
				{title:'Select Tables', view:step3View},
				{title:'Configure Logging', view:step3View}
			];
Now create a new wizard
	var cdcWizard = new WizardMaker();
And pass in the steps as well as the layout you want into the layout factory method.
var myView = cdcWizard.layoutFactory({collection:stepsArray,layout:'tab'});

Possible Layouts: tab, sliding, flat(default).
***/

var WizMaker = function() {};

var statusArray = {
    QUEUED: "QUEUED",
    ACTIVE: "ACTIVE",
    COMPLETE: "COMPLETE"
};

var Model = Backbone.Model.extend({
    title: String,
    view: Object,
    isActive: Boolean,
    status: String,
    defaults: {
        isActive: false,
        status: statusArray.QUEUED
    }
});

var Collection = Backbone.Collection.extend({
    model: Model,
    initialize: function() {
        //this.setActive(0);
    },
    actions: {
        NEXT: "NEXT",
        PREV: "PREV"
    },
    getPreviousNonTransientIndex: function(currIndex) {
        let nonTransientIndex = 0;
        for (let i = currIndex - 1; i >= 0; i--) {
            if (!this.at(i).get("transient")) {
                nonTransientIndex = i;
                break;
            }
        }
        return nonTransientIndex;
    },
    setActive: function(index, action) {
        var that = this;
        //if any element is active currently, make it normal
        var currActiveIndex = this.getActive();
        var prevNonTransientStep;
        if (!this.activeElement) {
            if (this.at(index).get("transient")) {
                prevNonTransientStep = that.getPreviousNonTransientIndex(index);
                this.at(prevNonTransientStep).set("status", statusArray.ACTIVE);
                for(let modelIndex = 0; modelIndex < prevNonTransientStep; modelIndex++){
                    this.at(modelIndex).set("status", statusArray.COMPLETE);
                } 
            }
            else {
                for(let modelIndex = 0; modelIndex <= index; modelIndex++){
                    this.at(modelIndex).set("status", statusArray.COMPLETE);
                } 
            }
        }
        if (currActiveIndex != null) {
            switch (action) {
                case that.actions.NEXT: {
                    //if current step to be set is transient -set the non-transient previous step to be active
                    if (this.at(index).get("transient")) {
                        //find previous non-transient step
                        prevNonTransientStep = that.getPreviousNonTransientIndex(index);
                        this.at(prevNonTransientStep).set("status", statusArray.ACTIVE);
                        break;
                    }
                    // if current step is transient - -set the non-transient previous step to be complete
                    if (this.at(currActiveIndex).get("transient")) {
                        //find previous non-transient step
                        prevNonTransientStep = that.getPreviousNonTransientIndex(currActiveIndex);
                        this.at(prevNonTransientStep).set("status", statusArray.COMPLETE);
                        break;
                    }
                    this.at(currActiveIndex).set("status", statusArray.COMPLETE);
                    break;
                }
                case that.actions.PREV: {
                    //if current step to be set is transient -set the non-transient previous step to be active
                    if (this.at(index).get("transient")) {
                        //find previous non-transient step
                        prevNonTransientStep = that.getPreviousNonTransientIndex(index);
                        this.at(currActiveIndex).set("status", statusArray.QUEUED);
                        this.at(prevNonTransientStep).set("status", statusArray.ACTIVE);
                        break;
                    }
                    // if current step is transient - -set the non-transient previous step to be active
                    if (this.at(currActiveIndex).get("transient")) {
                        //find previous non-transient step
                        prevNonTransientStep = that.getPreviousNonTransientIndex(currActiveIndex);
                        this.at(prevNonTransientStep).set("status", statusArray.ACTIVE);
                        break;
                    }
                    this.at(currActiveIndex).set("status", statusArray.QUEUED);
                    break;
                }
                default: {
                }
            }
        }
        //set the new active step
        this.activeElement = index;
        if (this.at(index)) {
            this.at(index).set("status", statusArray.ACTIVE);
        }
        return this;
    },
    getActive: function() {
        if (this.activeElement != null) {
            return this.activeElement;
        }
        return null;
    },
    next: function() {
        if (this.getActive() == this.length - 1) {
            return this.getActive();
        }
        this.setActive(this.getActive() + 1, this.actions.NEXT);
        return this.getActive();
    },
    prev: function() {
        if (this.getActive() == 0) {
            return this.getActive();
        }
        this.setActive(this.getActive() - 1, this.actions.PREV);
        return this.getActive();
    }
});

WizMaker.prototype.layoutFactory = function(options) {
    //make sure we have the collection and the type of layout
    if (!options || !options.collection) throw new Error("Please specify the collection of steps to create a wizard");
    var newCollection = new Collection(options.collection);
    // console.log(newCollection.at(0));
    //optionally specify the classname for the wizard
    var className = options.className ? "wizard " + options.className : "wizard";

    //the default is flat layout
    switch (options.layout) {
        case "flat": {
            return new FlatLayout({
                collection: newCollection,
                className: className
            });
        }
        case "sliding": {
            return new SlidingLayout({
                collection: newCollection,
                className: className,
                currentStepName : options.currentStepName
            });
        }
        case "tab": {
            return new TabLayout({
                collection: newCollection,
                className: className
            });
        }
        default: {
            return new FlatLayout({
                collection: newCollection,
                className: className
            });
        }
    }
};

export default WizMaker;
