export default {
    // used temporarily to display server errors in the UI
    // Error invoking method CRUDHandler: com.webaction.exception.CompilationException: property brokerAddress is required, but is not specified
    // should be displayed as
    // property brokerAddress is required, but is not specified
    sanitize: function(error) {
        error = error.replace("Error invoking method CRUDHandler: com.webaction.exception.CompilationException: ", "");
        error = error.replace("com.webaction.exception.CompilationException: ", "");
        return error;
    }
};
