const ROLE_MANAGEMENT_STRINGS = {
    label: {
        basicInfo: "Basic Info",
        namespace: "Namespace",
        roleName: "Role Name",
        role: "role",
        roles: "ROLES",
        permissions: "PERMISSIONS",
        users: "USERS"
    },
    button: {
        back: "Back to User and role management",
        addRole: "Add role",
        addPermission: "Add permission",
        addUser: "Add user",
        revoke: "Revoke"
    }
};

const roleColumnKey = {
    name: "name",
    permissions: "permissionsText",
    actions: "actions"
};

const roleTableColumns = [
    {
        Header: "Role Name",
        accessor: roleColumnKey.name,
        width: 180
    },
    {
        Header: "Permissions",
        accessor: roleColumnKey.permissions,
        width: 550
    },
    {
        Header: "",
        accessor: roleColumnKey.actions,
        width: 110
    }
];

const permissionColumnKey = {
    action: "action",
    permissions: "permissions",
    objectNames: "objectNames",
    objectTypes: "objectTypes",
    domains: "domains",
    actions: "actions"
};

const permissionTableColumns = [
    {
        Header: "Action",
        accessor: "action"
    },
    {
        Header: "(In) Object Name",
        accessor: "objectName"
    },
    {
        Header: "Object Type",
        accessor: "objectType"
    },
    {
        Header: "(In) Namespace",
        accessor: "namespace"
    },
    {
        Header: "",
        accessor: permissionColumnKey.actions,
        width: 90
    }
];

const usersColumnKey = {
    name: "name",
    actions: "actions"
};

const usersTableColumns = [
    {
        Header: "Name",
        accessor: usersColumnKey.name
    },
    {
        Header: "",
        accessor: usersColumnKey.actions
    }
];

const addRoleColumnKey = {
    name: "name",
    permissions: "permissions"
};

const addRoleTableColumns = [
    {
        Header: "Name",
        accessor: addRoleColumnKey.name
    },
    {
        Header: "Permissions",
        accessor: addRoleColumnKey.permissions,
        width: 100
    }
];

const addPermissionColumnKey = {
    name: "name",
    permissions: "permissions"
};

const addPermissionTableColumns = [
    {
        Header: "Name",
        accessor: addPermissionColumnKey.name
    },
    {
        Header: "Permissions",
        accessor: addPermissionColumnKey.permissions,
        minWidth: 300
    }
];

const userColumnKey = {
    ["User Id"]: "User Id",
    contactInfo: "contactInfo",
    actions: "actions"
};

const userTableColumns = [
    {
        Header: "User Name",
        accessor: userColumnKey["User Id"],
        width: 150
    },
    {
        Header: "Contact Info",
        accessor: userColumnKey.contactInfo,
        minWidth: 450
    },
    {
        Header: "",
        accessor: userColumnKey.actions,
        width: 90
    }
];

const addUserColumnKey = {
    ["User Id"]: "User Id"
};

const addUserTableColumns = [
    {
        Header: "Name",
        accessor: addPermissionColumnKey["User Id"]
    }
];

export {
    ROLE_MANAGEMENT_STRINGS,
    roleColumnKey,
    roleTableColumns,
    permissionColumnKey,
    permissionTableColumns,
    usersColumnKey,
    usersTableColumns,
    addRoleColumnKey,
    addRoleTableColumns,
    addPermissionColumnKey,
    addPermissionTableColumns,
    userColumnKey,
    userTableColumns,
    addUserColumnKey,
    addUserTableColumns
};
