import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import SegmentationChip from "./segmentationChip";
import { Grid } from "@material-ui/core";
import { StriimTypography, StriimModal } from "@striim/striim-ui";
import { AccountTreeOutlined } from "@material-ui/icons";
import { segmentationVariants } from "./segmentation.consts";
import classNames from "classnames";
import securityService from "core/services/securityService/securityService";
import Tracker from "../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../core/services/tracker/constants";

const useStyles = makeStyles(({ spacing, palette }) => ({
    root: {
        padding: spacing(0, 1, 0, 0.5)
    },
    title: {
        "& > div": { marginRight: spacing(1) }
    },
    circle: {
        height: 32,
        width: 32,
        borderRadius: 16,
        "& > div": { height: "100%" },
        "& svg": {
            height: 20,
            width: 20
        }
    },
    [`circle_${segmentationVariants.addOn}`]: {
        backgroundColor: `${palette.warning.main}1a`,
        "& svg": {
            fill: palette.warning.main
        }
    }
}));

const SegmentationModal = ({ isVisible, onCancel, title, children, variant }) => {
    const classes = useStyles();
    return (
        <StriimModal
            classes={{ root: classes.root }}
            size="medium"
            isVisible={isVisible}
            titleContent={
                <Grid container alignItems="center" className={classes.title}>
                    <Grid item className={classNames(classes.circle, classes[`circle_${variant}`])}>
                        <Grid container justifyContent="space-around" alignItems="center">
                            <AccountTreeOutlined />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <StriimTypography variant="h2">{title}</StriimTypography>
                    </Grid>
                    <Grid item>
                        <SegmentationChip variant={variant} />
                    </Grid>
                </Grid>
            }
            onCancel={onCancel}
            confirmContent="Learn more"
            confirmButtonProps={{ component: "a", href: securityService.addonNotAvailableLink }}
            onConfirm={() => {
                Tracker.getInstance().track(TRACKER_STRINGS.schema.learnMore, {
                    event: TRACKER_STRINGS.eventClicked.learnMore
                });
            }}
        >
            {children}
        </StriimModal>
    );
};

PropTypes.AddOnChip = {
    variant: PropTypes.oneOf(Object.values(segmentationVariants))
};

export default SegmentationModal;
