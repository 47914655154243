import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import utils from "core/utils";
import ComparisonOperators from "./comparisonOperators.json";
import TqlBuilder from "app/components/common/tqlBuilder";

var Transformation = {};

Transformation.OnlyDbSources = false;

Transformation.createColumnControl = function () {
    return UIControl.ColumnsWithFunctionPicker.extend({
        hideLabel: true,
        hidden: false,
        transformationConfig: {
            preFillColumns: false,
            addAvailable: true,
            addLabel: "ADD CONDITION",
            functionsSource: getFunctionsListPerType,
            labels: {
                column2: "Operator",
                column3: "Value",
            },
        },
    }).create();
};

Transformation.buildTql = function (tqlBuilder, columns) {
    tqlBuilder.addWildcard();

    columns.forEach(function (column) {
        if (!column.column || !column.tqlFunction || !column.alias) {
            return;
        }
        let params = column.alias;
        if (column.columnType === utils.NATIVE_TYPES.String.classname) {
            params = TqlBuilder.wrapInQuotes(column.alias);
        }
        tqlBuilder.addFilteringColumn(column.column, column.tqlFunction, params);
    });
};

function getFunctionsListPerType(columnType) {
    var operatorsList = filterByType(ComparisonOperators, columnType);

    return toSourceList(operatorsList);
}

function filterByType(list, type) {
    switch (type) {
        case utils.NATIVE_TYPES.Short.classname:
        case utils.NATIVE_TYPES.Integer.classname:
        case utils.NATIVE_TYPES.Long.classname:
        case utils.NATIVE_TYPES.Float.classname:
        case utils.NATIVE_TYPES.Double.classname:
            return _.filter(list, (operator) => {
                return operator.allowNumeric;
            });

        case utils.NATIVE_TYPES.String.classname:
            return _.filter(list, (operator) => {
                return operator.allowString;
            });

        case utils.NATIVE_TYPES.DateTime.classname:
            return _.filter(list, (operator) => {
                return operator.allowDateTime;
            });

        default:
            return [];
    }
}

function toSourceList(operators) {
    return operators.map((operator) => {
        return {
            id: operator.operator,
            text: operator.displayAs,
            description: operator.operator,
            function: operator.operator,
            selectedText: operator.displayAs,
        };
    });
}

export default Transformation;
