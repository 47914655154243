export const styles = {
    tooltipContainer: {
        background: "white",
        borderRadius: "4px",
        border: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    tooltipColorBox: {
        width: "8px",
        height: "8px",
        borderRadius: "2px"
    },
    chartStyles: {
        "& .recharts-sector": {
            outline: "none"
        },
        alignSelf: "center"
    }
};
