import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import MetaObjects from "core/services/metaStoreService/meta-objects";
import DataBuffer from "./dataBuffer";
import PreviewStreamDataLoader from "./previewStreamDataLoader";
import template from "./templates/previewDataTemplate.html";

export default Backbone.Marionette.ItemView.extend({
    template: _.template(template),

    ui: {
        closeButton: "#closeButton",
        fullScreen: "#fullScreen",
        refresh: "#refresh",
        table: "table",
        header: ".header",
        tableWrapper: ".preview-table",
        loader: "div.loader",
        loaderIcon: "div.loader .icon",
    },

    events: {
        "click @ui.closeButton": "onCloseClick",
        "click @ui.refresh": "onRefreshClick",
        "click @ui.fullScreen": "onFullScreenClick",
    },

    _bufferSize: 100,
    _isInFullscreenMode: false,
    _fields: [],
    initialize: function () {
        var that = this;
        this.streamDataLoader = new PreviewStreamDataLoader(this.model.metaObject);

        this.listenTo(this.streamDataLoader, "onDataLoaded", function (data) {
            that.renderRow(data);
        });
    },

    onRender: function () {
        var _this = this;
        _this._fields = [];
        metaStoreService.findById(this.model.id).then(function (stream) {
            if (!stream.dataType) {
                return;
            }
            metaStoreService.findById(stream.dataType).then(function (type) {
                if (!type || !type.fields) {
                    return;
                }
                _this.dataType = type;
                var $headerRow = _this.ui.table.find("thead>tr");
                $headerRow.append(
                    $("<th>&nbsp;</th>", {
                        class: "header-cell",
                    })
                );
                _.each(type.fields, function (field) {
                    $headerRow.append($("<th></th>").text(field.name));
                    _this._fields.push(field.name);
                });
                _this.startQueryingForData();
            });
        });
    },

    startQueryingForData: function () {
        this.streamDataLoader.start();
    },

    renderRow: function (data) {
        var $tbody = this.$el.find("tbody");
        var $tr = $("<tr>");
        var id = $tbody.children().length + 1;
        $tr.append("<td>" + id + "</td>");
        _.each(this._fields, function (field) {
            if (_.isObject(data[field]) || _.isArray(data[field])) {
                data[field] = JSON.stringify(data[field]);
            }
            $tr.append("<td>" + data[field] + "</td>");
        });

        $tbody.prepend($tr);
    },

    onBeforeDestroy: function () {
        this.streamDataLoader.stop();
    },

    onCloseClick: function () {
        this._setFullscreenMode(false);
        this.trigger("preview-data-close");
    },

    onRefreshClick: function () {
        var $tbody = this.$el.find("tbody");
        $tbody.empty();
        this.startQueryingForData();
    },

    _setFullscreenMode: function (isInFullscreenMode) {
        this._isInFullscreenMode = isInFullscreenMode;

        this.ui.fullScreen.toggleClass("icon-fullscreen", !this._isInFullscreenMode);
        this.ui.fullScreen.toggleClass("icon-exit-fullscreen", this._isInFullscreenMode);
        var $parentEl = this.$el.parent();
        $parentEl.toggleClass("full-screen", this._isInFullscreenMode);

        if (this._isInFullscreenMode) {
            var height = $parentEl.outerHeight() - this.ui.header.outerHeight() - 16;
            this.ui.tableWrapper.css({
                height: height,
                "max-height": height,
                "min-height": height,
            });
        } else {
            this.ui.tableWrapper.removeAttr("style");
        }

        this.trigger("fullscreen-mode", this._isInFullscreenMode);
    },

    onFullScreenClick: function () {
        this._setFullscreenMode(!this._isInFullscreenMode);
    },
});
