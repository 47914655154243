function parsePermissionsToObject(tql) {
    const result = { privileges: [], object_types: [], objectName: "" };

    if (!tql) {
        return result;
    }

    const splitted = tql.split(" ");
    const privileges = splitted[1].split(",");

    const object_types = splitted[3].split(",");
    const objectName = splitted[4];

    return { privileges, object_types, objectName, tql };
}

function generatePermissionString(permissionsObject) {
    return `${permissionsObject.type} ${permissionsObject.actions.join(", ")} ON ${permissionsObject.objectTypes.join(
        ", "
    )} ${permissionsObject.domains[0]}.${permissionsObject.objectNames[0]}`;
}

function parsePermissions(fromServer) {
    const actions = Array.isArray(fromServer.actions) ? fromServer.actions : [fromServer.actions];
    const domains = Array.isArray(fromServer.domains) ? fromServer.domains : [fromServer.domains];
    const objectNames = Array.isArray(fromServer.objectNames) ? fromServer.objectNames : [fromServer.objectNames];
    const objectTypes = Array.isArray(fromServer.objectTypes) ? fromServer.objectTypes : [fromServer.objectTypes];
    const type = fromServer.type;

    const result = {
        actions: actions.map(x => x.toUpperCase()),
        domains,
        objectNames,
        objectTypes: objectTypes.map(x => x.toUpperCase()),
        type
    };

    result.text = generatePermissionString(result);

    return result;
}

export { parsePermissionsToObject, generatePermissionString, parsePermissions };
