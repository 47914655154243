import React, { useRef } from "react";
import { StriimTypography } from "@striim/striim-ui-v2";
import { Box, Grid } from "@mui/material";
import { styles } from "../../sentinel-reports/global-report/sentinel-global-report.styles";
import EventsProcessedTimelineChart from "../../../sentinel-live-monitor/components/timeline-view-chart/events-processed-timeline-chart";
import SensitiveDataOccurrencesChart from "../../../sentinel-live-monitor/components/timeline-view-chart/sensitive-data-occurrences-chart";
import { pluralize, Section, SectionTitle } from "../report-components";
import { getPercentage } from "../report-tables/report-tables.helper";

const LegendItem = ({ label, color }) => {
    return (
        <Box display={"flex"} alignItems={"center"} gap={1}>
            <div
                style={{
                    background: color,
                    width: "11px",
                    height: "11px",
                    borderRadius: "2px"
                }}
            ></div>
            <StriimTypography variant="caption">{label}</StriimTypography>
        </Box>
    );
};

const LegendLabel = ({ label, data }) => {
    return (
        <>
            <label style={{ color: "rgba(32, 32, 32, 0.50)" }}>{label} </label>
            {data}
        </>
    );
};

export const ChartLegend = ({ data }) => {
    return (
        <Grid display={"flex"} gap={2} my={2}>
            {data.map(item => (
                <LegendItem key={item.label} color={item.color} label={<LegendLabel label={item.label} data="" />} />
            ))}
        </Grid>
    );
};

export const AppSentinelCount: React.FC<{ appsCount: number; sentinelsCount: number }> = ({
    appsCount,
    sentinelsCount
}) => {
    return (
        <Grid display="flex" item gap={0.5}>
            {appsCount && <StriimTypography sx={styles.smallFont}>{pluralize("App", appsCount)}</StriimTypography>}
            {sentinelsCount && (
                <StriimTypography sx={styles.smallFont}>({pluralize("Sentinel", sentinelsCount)})</StriimTypography>
            )}
        </Grid>
    );
};

export const EventsProcessedChart = ({
    eventsProcessedChartData,
    eventsProcessed,
    eventsWithSensitiveData,
    eventsWithoutSensitiveData,
    appsCount,
    sentinelsCount,
    timeRange,
    isOneHourTimeFrame,
    legendData
}) => {
    const chartRef = useRef();

    const sensitivePercentage = getPercentage(
        eventsWithSensitiveData,
        eventsWithSensitiveData + eventsWithoutSensitiveData
    );

    return (
        <Section showborderBottom={false}>
            <Grid width={"100%"} borderTop={"1px solid #BCC9D6"}>
                <Grid>
                    <Box>
                        <SectionTitle
                            rightContent={
                                <Grid
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    textAlign={"end"}
                                    flexDirection={"column"}
                                >
                                    <StriimTypography sx={styles.smallFont}>
                                        Total Events Processed: {eventsProcessed}
                                    </StriimTypography>
                                    <AppSentinelCount appsCount={appsCount} sentinelsCount={sentinelsCount} />
                                </Grid>
                            }
                        >
                            Events W/ Sensitive Data Processed
                            <Box>
                                <StriimTypography>{eventsWithSensitiveData}</StriimTypography>
                            </Box>
                        </SectionTitle>
                    </Box>
                </Grid>
                <EventsProcessedTimelineChart
                    hideNavigator={!isOneHourTimeFrame}
                    isTaggedDataOptionAvailable={false}
                    sensitiveDataTaggedPercentage={24}
                    chartData={{
                        sensitiveData: eventsProcessedChartData.sensitiveData,
                        nonSensitiveData: eventsProcessedChartData.nonSensitiveData,
                        taggedSensitiveData: eventsProcessedChartData.taggedSensitiveData
                    }}
                    showTaggedData={false}
                    chartRef={chartRef}
                    isDiscoveryDisabled={true}
                    isReport={true}
                    selectedDateRange={timeRange}
                    legendData={legendData}
                    // setSelectedDateRange={setSelectedDateRange}
                    // onScroll={loadData}
                    // hasAppStopped={hasAppStopped}
                    // dataUnavailableTimestamp={dataUnavailableTimestamp}
                    // setShowTaggedData={false}
                />
            </Grid>
        </Section>
    );
};

export const SDIChart = ({
    sensitiveDataOccurrences,
    sensitiveDataOccurrencesChartData,
    timeRange,
    isOneHourTimeFrame,
    legendData,
    appsCount,
    sentinelsCount
}) => {
    const chartRef = useRef();
    const { occurrencesOfSensitiveData } = sensitiveDataOccurrences;

    return (
        <Section
            rightContent={
                <Grid display={"flex"} justifyContent={"space-between"} textAlign={"end"} flexDirection={"column"}>
                    <AppSentinelCount appsCount={appsCount} sentinelsCount={sentinelsCount} />
                </Grid>
            }
        >
            <Grid width={"100%"}>
                <SectionTitle>
                    Occurrences of Sensitive Events Processed
                    <Box>
                        <StriimTypography>{occurrencesOfSensitiveData}</StriimTypography>
                    </Box>
                </SectionTitle>
                <SensitiveDataOccurrencesChart
                    legendData={legendData}
                    chartRef={chartRef}
                    hideNavigator={!isOneHourTimeFrame}
                    chartData={sensitiveDataOccurrencesChartData}
                    onScroll={() => {}}
                    isReport={true}
                    selectedDateRange={timeRange}
                    // isDiscoveryDisabled={true}
                    // hasAppStopped={hasAppStopped}
                    // dataUnavailableTimestamp={dataUnavailableTimestamp}
                    // setSelectedDateRange={setSelectedDateRange}
                    // dataUnavailableTimestamp={1726734540}
                />
            </Grid>
        </Section>
    );
};
