import App from "app";

var colorService = {};

colorService.start = function () {
    colorService.theme = App.Metadata.Themes.WebActionLight;
    colorService.colorMap = {};
    colorService.colorIndex = 0;

    App.reqres.setHandler("get:color:byalias", colorService.getColorByAlias);
};

colorService.reset = function () {
    colorService.colorIndex = 1;
};

colorService.getColorByAlias = function (alias) {
    var color = colorService.colorMap[alias];
    if (typeof color !== "undefined") {
        return color;
    }
    var id = colorService.colorIndex % colorService.theme.colors.hues.length;
    color = colorService.theme.colors.hues[id];
    colorService.colorIndex++;

    colorService.colorMap[alias] = color;

    return color;
};

colorService.getRandomColor = function () {
    var letters = "0123456EF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 9)];
    }
    return color;
};

export default colorService;
