import React, { useState, useRef, useEffect } from "react";
import { StriimInputField, useStriimForm } from "@striim/striim-ui";
import Grid from "@material-ui/core/Grid";

import connectionProfileService from "../../modules/apps/pages/manage-striim/connection-profiles/connection-profile-service";
import ConnectionProfilesModal from "../../modules/apps/pages/manage-striim/connection-profiles/components/connection-profiles-modal/connection-profiles-modal";
import growl from "../../../app/components/common/growl";

const NEW_CONNECTION_PROFILE_IDENTIFIER = "new";
const parseList = list => {
    if (Array.isArray(list)) {
        return list;
    }
    return list.replace(/\[|\]|\s/g, "").split(",");
};

const ConnectionProfileSelector = ({ useMenuPortalTarget = false, supportingConnectionProfiles, value, ...props }) => {
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [showModal, setShowModal] = useState(false);
    let supportingConnectionProfilesList = parseList(supportingConnectionProfiles);
    const [isValid, setIsValid] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const { values } = useStriimForm() ?? {};
    const [externalStageType, setExternalStageType] = useState(values?.externalStageType?.value);

    if (props.name === "externalStageConnectionProfileName") {
        const newExternalStageType = values?.externalStageType?.value;
        supportingConnectionProfilesList =
            newExternalStageType && ["S3", "ADLSGen2"].includes(newExternalStageType)
                ? [newExternalStageType]
                : supportingConnectionProfilesList;
    }

    const formRef = useRef({
        connectionRef: useRef(),
        endPointRef: useRef()
    });
    const oauthRef = useRef();

    const getConnectionProfiles = async () => {
        let options = [];
        try {
            const connectionProfiles = await connectionProfileService.getCpsByEndpoints(
                supportingConnectionProfilesList
            );
            options = connectionProfiles.map(entry => ({
                label: entry,
                value: entry
            }));
        } catch (error) {
            growl.error(error);
        } finally {
            options.unshift({ label: "+ New Connection Profile", value: NEW_CONNECTION_PROFILE_IDENTIFIER });
            setSelectOptions(options);
        }
    };

    useEffect(() => {
        getConnectionProfiles();
    }, []);

    useEffect(() => {
        if (
            props.name === "externalStageConnectionProfileName" &&
            values?.externalStageType?.value !== externalStageType
        ) {
            handleExternalStageTypeChange(values?.externalStageType?.value);
        }
    }, [props.name, externalStageType, values]);

    useEffect(() => {
        setSelectedOption(value ? { label: value, value } : "");
    }, [value]);

    const handleExternalStageTypeChange = newValue => {
        props.onChange("");
        getConnectionProfiles();
        setExternalStageType(newValue);
    };

    const handleChange = currentSelection => {
        if (currentSelection?.value === NEW_CONNECTION_PROFILE_IDENTIFIER) {
            setShowModal(true);
        } else {
            props.onChange(currentSelection?.value || "");
            setSelectedOption(currentSelection || "");
        }
    };

    const cancelCreatingCP = previousSelection => {
        if (previousSelection) {
            props.onChange(previousSelection.value);
            setSelectedOption(previousSelection);
        }
    };

    const saveConnectionProfile = async () => {
        try {
            setIsSaveDisabled(true);
            const endPointValues = formRef?.current?.endPointRef?.current?.values;
            const connectionValues = { ...formRef?.current?.connectionRef?.current?.values, ...oauthRef?.current };
            const name = endPointValues?.connectionProfileName?.trim();
            const namespace = endPointValues?.namespace?.value;
            const endPoint = endPointValues?.endpointName?.value ?? supportingConnectionProfilesList[0];
            const result = await connectionProfileService.createConnectionProfile(
                name,
                namespace,
                endPoint,
                connectionValues
            );
            await getConnectionProfiles();
            props.onChange(`${namespace}.${name}`);
            setSelectedOption({ label: `${namespace}.${name}`, value: `${namespace}.${name}` });
            setShowModal(false);
            growl.success("Connection profile saved successfully");
        } catch (error) {
            //@ts-ignore
            growl.error(error);
        } finally {
            setIsSaveDisabled(false);
        }
    };

    return (
        <Grid container>
            <StriimInputField
                name={props.name}
                label={props.label}
                value={selectedOption}
                required={props.required}
                select
                id="data-test-id-connection-profile-select"
                SelectProps={{
                    options: selectOptions,
                    ...(useMenuPortalTarget && { menuPortalTarget: document.body }),
                    components: {
                        IndicatorSeparator: () => null
                    }
                }}
                onChange={value => {
                    handleChange(value);
                }}
                placeholder={"Select a connection profile"}
                disabled={!props.enabled}
                error={props.error}
                helperText={props.error ? props.errorText : ""}
            />
            <ConnectionProfilesModal
                isValid={isValid}
                setIsValid={setIsValid}
                formRef={formRef}
                oauthRef={oauthRef}
                endPoints={supportingConnectionProfilesList}
                isVisible={showModal}
                isSaveDisabled={isSaveDisabled}
                setShowModal={setShowModal}
                onCancel={() => cancelCreatingCP(selectedOption)}
                onSave={saveConnectionProfile}
            />
        </Grid>
    );
};

export default ConnectionProfileSelector;
