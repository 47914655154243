const styles = {
    caption4Color: {
        color: "primary.300"
    },
    namespace: {
        textTransform: "none",
        paddingTop: "4px",
        color: "primary.200"
    },
    recentItem: {
        alignItems: "baseline",
        "&.Mui-selected": {
            backgroundColor: "primary.main",
            "&:hover": {
                backgroundColor: "primary.main"
            }
        },
        "&:hover": {
            backgroundColor: "additional.darkSlateBlue"
        },
        "&:active": {
            backgroundColor: "primary.main"
        }
    },
    statusIcon: {
        marginRight: "12px"
    },
    dashboardIcon: {
        position: "relative",
        top: 8,
        color: "greyscale.50"
    },
    iconSize: {
        width: 18,
        height: 18
    },
    appNameColor: {
        color: "greyscale.50"
    },
    captionPadding: {
        padding: "8px 12px 8px 12px"
    },
    overflowEllipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    latestItemDtls: {
        display: "flex",
        flexDirection: "column"
    }
};

export default styles;
