import AccountTreeOutlined from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/AccountTreeOutlined.svg";
import AlertManagerStandardEnterprise from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/AlertManager_standard_enterprise.svg";
import BidirectionalEnterpriseEnterpriseplus from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/Bidirectional_enterprise_enterpriseplus.svg";
import BidirectionalStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/Bidirectional_standard.svg";
import CheckCircle from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/CheckCircle.svg";
import DashboardsStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/Dashboards_standard.svg";
import DashboardsEnterprise from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/Dashboards_enterprise.svg";
import EveenttableStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/Eveenttable_standard.svg";
import ExceptionStoreStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/ExceptionStore_standard.svg";
import ExternalCacheStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/ExternalCache_standard.svg";
import FlmStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/FLM_standard.svg";
import MLfunctionsStandardEnterprise from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/MLfunctions_standard_enterprise.svg";
import MetadatamanagerStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/Metadatamanager_standard.svg";
import MonitorTimeSeriesReportStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/MonitorTimeSeriesReport_standard.svg";
import MultinodeSupportStandardEnterprise from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/MultinodeSupport_standard_enterprise.svg";
import OpenProcessorStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/OpenProcessor_standard.svg";
import P2DataMaskingStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/P2DataMasking_standard.svg";
import PartitionedStreamStandardEnterprise from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/PartitionedStream_standard_enterprise.svg";
import PartitionedWindowEnterprise from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/PartitionedWindow_enterprise.svg";
import PartitionedWindowStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/PartitionedWindow_standard.svg";
import PeopleAlt from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/people_alt.svg";
import Rule from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/rule.svg";
import ManageAccounts from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/manage_accounts.svg";
import PersistentStreamStandardEnterprise from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/PersistentStream_standard_enterprise.svg";
import RestapiStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/Restapi_standard.svg";
import RouterStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/Router_standard.svg";
import ValidationStandardEnterprise from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/Validation_standard_enterprise.svg";
import WactionStoreStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/WactionStore_standard.svg";
import WactionStoreEnterprise from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/WactionStore_enterprise.svg";
import ApplicationProgress from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/application_progress.svg";
import ExceptionsStore from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/exceptions-store.svg";
import IconGroup from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/icon-group.svg";
import SegAddOn from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/seg_add_on.svg";
import SegEnterpriseTier from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/seg_enterprise_tier.svg";
import SegEnterpriseplusTier from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/seg_enterpriseplus_tier.svg";
import SegStandardTier from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/seg_standard_tier.svg";
import StandardTier from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/standard_tier.svg";
import UseraacountEnterprise from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/useraacount_enterprise.svg";
import UseraccountEnterpriseplus from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/useraccount_enterpriseplus.svg";
import UseraccountStandard from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./customIcons/useraccount_standard.svg";

let icons = {};
icons["AccountTreeOutlined"] = AccountTreeOutlined;
icons["AlertManager_standard_enterprise"] = AlertManagerStandardEnterprise;
icons["Bidirectional_enterprise_enterpriseplus"] = BidirectionalEnterpriseEnterpriseplus;
icons["Bidirectional_standard"] = BidirectionalStandard;
icons["CheckCircle"] = CheckCircle;
icons["Dashboards_standard"] = DashboardsStandard;
icons["Dashboards_enterprise"] = DashboardsEnterprise;
icons["Eveenttable_standard"] = EveenttableStandard;
icons["ExceptionStore_standard"] = ExceptionStoreStandard;
icons["ExternalCache_standard"] = ExternalCacheStandard;
icons["FLM_standard"] = FlmStandard;
icons["MLfunctions_standard_enterprise"] = MLfunctionsStandardEnterprise;
icons["Metadatamanager_standard"] = MetadatamanagerStandard;
icons["MonitorTimeSeriesReport_standard"] = MonitorTimeSeriesReportStandard;
icons["MultinodeSupport_standard_enterprise"] = MultinodeSupportStandardEnterprise;
icons["OpenProcessor_standard"] = OpenProcessorStandard;
icons["P2DataMasking_standard"] = P2DataMaskingStandard;
icons["PartitionedStream_standard_enterprise"] = PartitionedStreamStandardEnterprise;
icons["PartitionedWindow_enterprise"] = PartitionedWindowEnterprise;
icons["PartitionedWindow_standard"] = PartitionedWindowStandard;
icons["people_alt"] = PeopleAlt;
icons["rule"] = Rule;
icons["manage_accounts"] = ManageAccounts;
icons["PersistentStream_standard_enterprise"] = PersistentStreamStandardEnterprise;
icons["Restapi_standard"] = RestapiStandard;
icons["Router_standard"] = RouterStandard;
icons["Validation_standard_enterprise"] = ValidationStandardEnterprise;
icons["WactionStore_standard"] = WactionStoreStandard;
icons["WactionStore_enterprise"] = WactionStoreEnterprise;
icons["application_progress"] = ApplicationProgress;
icons["exceptions-store"] = ExceptionsStore;
icons["icon-group"] = IconGroup;
icons["seg_add_on"] = SegAddOn;
icons["seg_enterprise_tier"] = SegEnterpriseTier;
icons["seg_enterpriseplus_tier"] = SegEnterpriseplusTier;
icons["seg_standard_tier"] = SegStandardTier;
icons["standard_tier"] = StandardTier;
icons["useraacount_enterprise"] = UseraacountEnterprise;
icons["useraccount_enterpriseplus"] = UseraccountEnterpriseplus;
icons["useraccount_standard"] = UseraccountStandard;

export default function(icon) {
    if (!icons[icon]) {
        // eslint-disable-next-line no-console
        console.error("Icon not found:" + icon);
        return icons[0];
    }
    return icons[icon];
}
