import { AllAppsTableTypeRow } from "../../../sentinel-live-monitor/components/sentinel-table/all-apps-table";
import { EventsProcessedDataForApps } from "./sentinel-monitor.types";

const getAppName = name => name.split(".")[1];

const getDiscoveryTaggingLabel = (count: number, total: number) =>
    count ? `${Math.round((count / total) * 100)}%(${count})` : null;

const formatSentinelDetails = data => {
    const AppsTable = getSentinelDetails(data);
    const SensitiveDataTable = getSentinelAppDetails(data);

    return { AppsTable: AppsTable, SensitiveDataTable: SensitiveDataTable };
};

const getSentinelDetails = data => {
    const apps = {};

    data.sentinels.forEach(sentinel => {
        const key = sentinel.appName;
        const appName = key;
        const namespace = sentinel.namespace;

        if (!apps[key]) {
            apps[key] = {
                name: appName,
                namespace: namespace,
                sentinels: 0,
                discoveryEnabledCount: 0,
                taggingEnabledCount: 0
            };
        }
        apps[key].sentinels += 1;

        if (sentinel.discoveryEnabled) apps[key].discoveryEnabledCount += 1;
        if (sentinel.taggingEnabled) apps[key].taggingEnabledCount += 1;
    });

    const formattedData = Object.keys(apps).map(key => {
        const app = apps[key];
        return {
            name: app.name,
            namespace: app.namespace,
            sentinels: app.sentinels,
            discovery: getDiscoveryTaggingLabel(app.discoveryEnabledCount, app.sentinels),
            tagging: getDiscoveryTaggingLabel(app.taggingEnabledCount, app.sentinels)
        };
    });

    return formattedData;
};

const getSentinelAppDetails = data => {
    const formattedData = data.sentinels.flatMap(sentinel =>
        sentinel.sensitiveIdentifiers.map(identifier => ({
            dataIdentifier: identifier.name,
            sentinelName: sentinel.name,
            appName: sentinel.appName,
            fullAppName: sentinel.appName,
            namespace: sentinel.namespace,
            action: identifier.action.toLowerCase(),
            importance: identifier.importance.toLowerCase()
        }))
    );
    return formattedData;
};

const getSentinelList = data => {
    return data?.length
        ? data.map(data => ({
            sentinelName: data.name,
            appName: data.appName,
            namespace: data.namespace
        }))
        : [];
};

const getAllAppsTableData = (data: EventsProcessedDataForApps): AllAppsTableTypeRow[] => {
    const applications = data.applications;
    return Object.keys(applications).map(app => {
        const appNameParts = app.split(".");
        return {
            name: appNameParts[1],
            namespace: appNameParts[0],
            events: applications[app].eventsProcessed,
            eventsTagged: applications[app].eventsWithSensitiveDataTagged,
            sensitiveData: applications[app].eventsWithSensitiveData,
            nonSensitiveData: applications[app].eventsProcessed - applications[app].eventsWithSensitiveData
        };
    });
};

const updateIdentifiersData = sensitiveDataIdentifierOccurrences => {
    const updated = [];
    const isArray = Array.isArray(sensitiveDataIdentifierOccurrences);

    if (!isArray) {
        for (const identifier in sensitiveDataIdentifierOccurrences) {
            if (sensitiveDataIdentifierOccurrences.hasOwnProperty(identifier)) {
                sensitiveDataIdentifierOccurrences[identifier].value = identifier;
                updated.push(sensitiveDataIdentifierOccurrences[identifier]);
            }
        }

        return updated;
    }
    return sensitiveDataIdentifierOccurrences;
};

export {
    getSentinelDetails,
    getSentinelAppDetails,
    formatSentinelDetails,
    getAppName,
    getSentinelList,
    getAllAppsTableData,
    updateIdentifiersData
};
