import React from "react";
import FeatureNotAvailableHeader, {
    featureNoAvailableSize
} from "../../common/segmentation/feature-not-available-header";
import { Grid } from "@material-ui/core";
import {
    FeaturesDivider,
    FeatureLeft,
    FeatureRight,
    featureSize
} from "../../common/segmentation/features-description";
import securityService from "core/services/securityService/securityService";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import { useStyles } from "./alert-manager-page.styles";

const AlertManagerNotAvailable = () => {
    const classes = useStyles();

    const variant = securityService.getSegmentationVariant(addonFeaturesKeys.ALERTMANAGER);

    return (
        <div className={classes.wrapper}>
            <Grid item xs={12}>
                <FeatureNotAvailableHeader
                    title="Alert Manager is an add-on feature"
                    variant={variant}
                    description="Alerts can help you get notified about the health of your Striim nodes and components. The Striim Alert manager provides out-of-the-box configurable alerts for common scenarios that can help you maximize operational uptime. You can manage all your alerts here or access them in context of your apps."
                    imgComponent={
                        <img
                            src="app/images/segmentation/alert-manager/AlertManager.png"
                            style={featureNoAvailableSize}
                        />
                    }
                />
            </Grid>
            <FeaturesDivider />
            <FeatureLeft
                title="Out of the box conditions"
                description="Configure alerts with a simple user interface and multiple out of the box conditions"
                imgComponent={<img src="app/images/segmentation/alert-manager/SimpleUI.png" style={featureSize} />}
            />
            <FeatureRight
                title="Snooze alerts"
                description="Set 'snooze' thresholds to prevent alert fatigue"
                imgComponent={<img src="app/images/segmentation/alert-manager/SnoozeAlerts.png" style={featureSize} />}
            />
            <FeaturesDivider />
            <FeatureLeft
                title="Customize your alerts"
                description="Create granular, custom conditions to alert on exactly what is important to your team"
                imgComponent={
                    <img src="app/images/segmentation/alert-manager/CreateCustomAlerts.png" style={featureSize} />
                }
            />
        </div>
    );
};

export default AlertManagerNotAvailable;
