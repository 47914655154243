import App from "app";
import { template, className, regions } from "core/decorators";
import { LayoutView } from "marionette";
import WizardMaker from "app/components/common/wizardmaker/wizMaker";
import ConfigView from "./config-view";
import { ValidationReactView } from "./connection-validation";
import TableSelectionView from "./table-selection";
import SchemaSelectionView from "./schema-selection";
import SetupValidationView from "./setup-validation";
import SchemaMigrationValidation from "./schema-migration-validation";
import ChannelValidation from "./channel-validation";
import AppAdaptersObjectSelection from "./app-adapters-object-selection";
import AppAdaptersChannelSelection from "./app-adapters-channel-selection";
import { WizardModel, ConnectionParams, Schemas } from "./models";
import { DB_PROVIDERS } from "./configs/db-providers";
import moduleDataUtils from "app/components/appwizard/component-wizards/wizards/source/base-cdc/module-data-utils";
import Tracker from "core/services/tracker/tracker";
import Templates from "../../../../templates";
import ensureRegions from "../../../../../common/_ensureRegions";
import { isAutomatedWizard } from "./app-services";
import localStorageUtils from "./local-storage-utils";
import { getConnectionUrl } from "../../../../../../../src/modules/wizards/services";
import navigateTo from "src/navigate-to";
import OracleGetPdbView from "./pdb-selection";
import growl from "../../../../../common/growl";
import { TRACKER_STRINGS } from "../../../../../../../core/services/tracker/constants";

@template('<div id="component-wizard-container"></div>')
@className("cdc-wizard")
@regions({
    componentWizardContainer: "#component-wizard-container"
})
export class View extends LayoutView {
    initialize(options) {
        this.options = options;
        this.model = new WizardModel();
        this.model.set("connectionParams", new ConnectionParams());
        this.model.set("schemas", new Schemas());
        this.model.set("appName", options.appName);
        this.model.set("templateID", options.templateID);
        this.model.set("template", Templates.getByURL(options.templateID));
        this.storageName = options.appName;
        if (localStorageUtils.isKeyPresent(this.storageName)) {
            localStorageUtils.updateModel(this.storageName, this.model);
        }
        let that = this;
        this.setup();
        App.reqres.setHandler("get:wizard:model", function() {
            return that.model;
        });
        this.model.set("type", this.model.get("connectionParams").get("type"));
        let DBProviderData = DB_PROVIDERS[this.model.get("connectionParams").get("type")];
        let isCDC = this.model.get("isCDC");
        this.model.set(
            "whatsInside",
            DBProviderData.id === DB_PROVIDERS.SALESFORCE.id && isCDC ? "Channel" : DBProviderData.tableWrapper + "s"
        );
        this.model.set("whatIsTable", DBProviderData.whatIsTable);
        this.model.set("isILCDC", isAutomatedWizard());
        this.model.set("hasAutoSchemaConversion", window.location.href.indexOf("-to-create-schema") !== -1);
    }

    onBeforeDestroy() {
        App.reqres.removeHandler("get:wizard:model");
    }

    onRender() {
        this.createWizard();
    }

    /**
     * Creates the wizard for the connection check, validation, table selection etc. Real Source is created at the last step.
     */
    createWizard() {
        ensureRegions(this);
        let tableWrapper = DB_PROVIDERS[this.model.get("connectionParams").get("type")].tableWrapper;
        let pluralize = tableWrapper + "s";
        let stepsArray = [];
        let context = this;
        function navigateBase(stepName) {
            const navigateParams = {
                appName: context.options.appName,
                templateId: context.options.templateID,
                force: false
            };
            navigateTo.AppWizardTemplate({ ...navigateParams, stepName });
        }
        if (this.model.get("connectionParams").isAppAdapter()) {
            stepsArray = [
                {
                    title: "Configure Source",
                    view: ConfigView,
                    stepName: "source-info",
                    navigateToStep: () => navigateBase("source-info")
                },
                {
                    title: "Validate Source",
                    view: ValidationReactView,
                    stepName: "validate-source",
                    navigateToStep: () => navigateBase("validate-source"),
                    transient: true
                },
                {
                    title: "Select " + pluralize,
                    view: AppAdaptersObjectSelection,
                    stepName: "select-" + pluralize,
                    navigateToStep: () => navigateBase("select-" + pluralize)
                }
            ];
            if (
                this.model.get("connectionParams").get("type") === DB_PROVIDERS.SALESFORCE.id &&
                this.model.get("isCDC")
            ) {
                stepsArray.splice(
                    2,
                    0,
                    {
                        title: "Select Channel",
                        view: AppAdaptersChannelSelection,
                        stepName: "select-channel",
                        navigateToStep: () => navigateBase("select-channel")
                    },
                    {
                        title: "Validate Channel",
                        view: ChannelValidation,
                        stepName: "validate-channel",
                        navigateToStep: () => navigateBase("validate-channel"),
                        transient: true
                    }
                );
            }
        } else {
            stepsArray = [
                {
                    title: "Configure Source",
                    view: ConfigView,
                    stepName: "source-info",
                    navigateToStep: () => navigateBase("source-info")
                },

                {
                    title: "Validate Source",
                    view: ValidationReactView,
                    stepName: "validate-source",
                    navigateToStep: () => navigateBase("validate-source"),
                    transient: true
                },
                {
                    title: "Select " + pluralize,
                    view: SchemaSelectionView,
                    stepName: "select-" + pluralize,
                    navigateToStep: () => navigateBase("select-" + pluralize)
                },
                {
                    title: "Validate " + pluralize,
                    view: SchemaMigrationValidation,
                    stepName: "validate-" + pluralize,
                    navigateToStep: () => navigateBase("validate-" + pluralize),
                    transient: true
                },
                {
                    title:
                        this.model.get("connectionParams").get("type") === DB_PROVIDERS.MONGODB.id
                            ? "Select Collections"
                            : "Select Tables",
                    view: TableSelectionView,
                    stepName:
                        this.model.get("connectionParams").get("type") === DB_PROVIDERS.MONGODB.id
                            ? "select-collections"
                            : "select-tables",
                    navigateToStep: () => {
                        const urlStepName =
                            this.model.get("connectionParams").get("type") === DB_PROVIDERS.MONGODB.id
                                ? "select-collections"
                                : "select-tables";
                        navigateBase(urlStepName);
                    }
                }
            ];
            if (
                this.model.get("connectionParams").get("type") === DB_PROVIDERS.MSJET.id ||
                this.model.get("connectionParams").get("type") === DB_PROVIDERS.OJET.id
            ) {
                stepsArray.splice(2, 0, {
                    title: "Validate Setup",
                    view: SetupValidationView,
                    stepName: "validate-setup",
                    navigateToStep: () => navigateBase("validate-setup")
                });
            }
            if (
                [DB_PROVIDERS.ORACLE.id, DB_PROVIDERS.OJET.id].includes(this.model.get("connectionParams").get("type"))
            ) {
                stepsArray.splice(1, 0, {
                    title: "Select Pluggable Database",
                    view: OracleGetPdbView,
                    stepName: "select-pluggable-database",
                    navigateToStep: () => navigateBase("select-pluggable-database"),
                    transient: true
                });
            }
        }
        let cdcWizard = new WizardMaker();
        let wizardView = cdcWizard.layoutFactory({
            collection: stepsArray,
            layout: "sliding",
            currentStepName: this.options.stepName
        });
        this.on("do:next", async function() {
            localStorageUtils.setItem(this.storageName, this.model);
            let moduleData = moduleDataUtils.get();
            let connectionParams = this.model.get("connectionParams");
            if (connectionParams) {
                moduleData.databaseConnectionParams = {
                    dbName: connectionParams.get("dbName")
                };
            }
            let view = wizardView.getRegion("active-step").currentView;
            if (wizardView.currentStepIndex === 0) {
                const isValid = await view.reactForm.validate();
                if (!isValid) {
                    return;
                }
            }
            const adapterType = _this.model.get("type");
            const stepId = view.options.model.get("stepName");
            Tracker.getInstance().track(
                TRACKER_STRINGS.schema.wizards,
                {
                    id: view.model.get("appName"),
                    event: TRACKER_STRINGS.eventClicked.wizards,
                    step: stepId,
                    adapterType: adapterType,
                    buttonClicked: "Next",
                    isAutomatedILCDC: isAutomatedWizard()
                },
                TRACKER_STRINGS.version.withModification
            );
            view.canMoveForward().then(
                () => {
                    if (wizardView.currentStepIndex < wizardView.collection.length - 1) {
                        wizardView.doNextHandler();
                    } else {
                        App.vent.trigger("appwizard:next:step", null, "configure-target");
                    }
                },
                err => {
                    console.error(err);
                }
            );
        });
        const _this = this;
        this.on("do:back", function() {
            var view = wizardView.getRegion("active-step").currentView;
            const stepId = view.options.model.get("stepName");
            const adapterType = _this.model.get("type");
            Tracker.getInstance().track("wizards", {
                id: view.model.get("appName"),
                event: "Wizards:Clicked",
                step: view.options.model.get("title"),
                buttonClicked: "Back"
            });
            if (["validate-source", "select-Schemas"].includes(stepId) && ["ORACLE", "OJET"].includes(adapterType)) {
                const isPDB = _this.model.get("isPDB");
                _this.model.set("containerName", "");
                if (isPDB) {
                    wizardView.gotoView("select-pluggable-database", "PREV");
                } else {
                    wizardView.gotoView("source-info", "PREV");
                }
                return;
            }

            Tracker.getInstance().track(
                TRACKER_STRINGS.schema.wizards,
                {
                    id: view.model.get("appName"),
                    event: TRACKER_STRINGS.eventClicked.wizards,
                    step: stepId,
                    adapterType: adapterType,
                    buttonClicked: "Back",
                    isAutomatedILCDC: isAutomatedWizard()
                },
                TRACKER_STRINGS.version.withModification
            );

            wizardView.doPrevHandler();
        });

        this.on("do:retry", function() {
            let view = wizardView.getRegion("active-step").currentView;
            view.retry(wizardView);
        });

        try {
            this.getRegion("componentWizardContainer").show(wizardView);
        } catch (e) {
            console.error(e);
        }
    }
}
