import { GenericStore } from "../storeUtils";
import { computed, observable, action } from "mobx";
import api from "core/api/api";
import utils from "core/utils";
import App from "app";
import growl from "../../../app/components/common/growl";

export default class DashboardsStore extends GenericStore {
    @observable filters = { textFilter: null, nameSpaceFilter: null };

    enrichDashboardsPages = async dashboards => {
        dashboards = await Promise.all(
            dashboards.map(async dashboard => {
                dashboard.pages = [
                    ...(await Promise.all(
                        dashboard.pages.map(page => {
                            const pageMetaObject = {
                                id: `${dashboard.nsName}.PAGE.${utils.getName(page)}`,
                                name: utils.getName(page),
                                nsName: dashboard.nsName,
                                type: "PAGE"
                            };
                            return api.read(undefined, pageMetaObject);
                        })
                    ))
                ].flat();
                return dashboard;
            })
        );
    };

    constructor() {
        super();
        this.addCreateDashboardAction();
        this.addImportDashboardAction();

        this.actionCreator(
            async () => {
                let dashboards = await api.read(undefined, { type: "DASHBOARD" });
                dashboards = dashboards.length === 1 && dashboards[0].hasOwnProperty("noField") ? [] : dashboards;
                dashboards = dashboards.filter(dashboard => !dashboard.isPrebuilt);
                await this.enrichDashboardsPages(dashboards);
                return dashboards;
            },
            {
                actionType: "Fetch",
                name: "dashboards"
            }
        );

        this.actionCreator(
            async updatedDashboard => {
                await api.update(undefined, updatedDashboard);
                this.dashboardsFetch();
            },
            {
                actionType: "Update",
                name: "dashboard"
            }
        );

        this.actionCreator(
            async deletedDashboard => {
                await api.delete(undefined, deletedDashboard);
                App.vent.trigger("dashboard:deleted", deletedDashboard.id);
                this.dashboardsFetch();
            },
            {
                actionType: "Delete",
                name: "dashboard"
            }
        );
    }

    @action getDashbordByUUID = async dashboardUUID => {
        const dashboards = await this.dashboardsFetch();
        return (dashboards || []).find(({ uuid }) => dashboardUUID == uuid);
    };

    @computed get isFiltersEnabled() {
        return this.filters.nameSpaceFilter || this.filters.textFilter;
    }

    @action setFilters(obj) {
        this.filters = { ...this.filters, ...obj };
    }

    @action resetFilters() {
        this.filters = { textFilter: null, nameSpaceFilter: null };
    }

    @computed get namespaceOptions() {
        return [...new Set((this.dashboards || []).map(({ nsName }) => nsName))].map(namespace => ({
            label: namespace,
            value: namespace
        }));
    }

    @computed get filteredDashboards() {
        const { nameSpaceFilter, textFilter } = this.filters;
        return (this.dashboards || []).filter(
            ({ name, nsName: namespace }) =>
                (!textFilter || name.toLowerCase().includes(textFilter.toLowerCase())) &&
                (!nameSpaceFilter || namespace.toLowerCase() === nameSpaceFilter.toLowerCase())
        );
    }
    addImportDashboardAction() {
        this.actionCreator(
            async (json, namespace) => {
                try {
                    return await api.importDashboard(json, namespace);
                } catch (e) {
                    growl.error(e.message, "There was an error importing this Dashboard");
                    throw Error("Api error");
                }
            },
            {
                actionType: "Import",
                name: "dashboard"
            }
        );
    }
    addCreateDashboardAction() {
        this.actionCreator(
            async ({ name, nsName }, { id, name: pageName }) => {
                try {
                    return await api.create(undefined, {
                        nsName,
                        name,
                        type: "DASHBOARD",
                        title: name,
                        pages: [{ id, name: pageName }],
                        defaultLandingPage: pageName,
                        _editable: true,
                        _menuopen: false,
                        isEditable: false,
                        ctime: null,
                        isPrebuilt: false,
                        current_page: null,
                        sections: [
                            {
                                id: "pages-button",
                                data_id: "side-menu-page-pages",
                                fa_class: "description"
                            },
                            {
                                id: "visualizations-button",
                                data_id: "side-menu-page-visualizations",
                                fa_class: "insert_chart"
                            },
                            {
                                id: "parameters-button",
                                data_id: "side-menu-page-parameters",
                                fa_class: "visibility"
                            }
                        ]
                    });
                } catch (e) {
                    growl.error(e.message, "There was an error creating this Dashboard");
                    throw Error("Api error");
                }
            },
            {
                actionType: "Create",
                name: "dashboard"
            }
        );
    }
}
