import metaStoreService from "core/services/metaStoreService/meta-store-service";
import * as Yup from "yup";

const nameSyncValidation = Yup.string()
    .required("Value for name is required")
    .test("noSpaces", "Please enter a name without spaces.", haveNoSpacesValidation);

function haveNoSpacesValidation(value) {
    return value && value.indexOf(" ") === -1;
}
const namespaceValidation = Yup.string().required("Value for namespace is required");

const nameFullValidation = () =>
    nameSyncValidation.test("nameAlreadyExistsAsync", "This name already exists", async function(name) {
        return await nameAlreadyExistsValidation(name, "CONNECTIONPROFILE", this.options?.context);
    });

function nameAlreadyExistsValidation(name, entityType, context) {
    if (!nameSyncValidation.isValidSync(name)) {
        return false;
    }

    const nsName = context.namespace?.value;
    if (!namespaceValidation.isValidSync(nsName)) {
        return true;
    }
    return new Promise(resolve => {
        metaStoreService
            .findById(nsName + "." + metaStoreService.entities[entityType] + "." + name)
            .then(item => {
                resolve(item ? false : true);
            })
            .fail(() => {
                resolve(false);
            });
    });
}

const getCPNameValidation = isEditMode => {
    if (!isEditMode) {
        return nameFullValidation();
    }
    return nameSyncValidation;
};

export { namespaceValidation, getCPNameValidation };
