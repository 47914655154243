import { useEffect, useState } from "react";
import api from "../../../../../../../../../core/api/api";
import { TYPES } from "../../../../../../../../../core/services/monitorService";

const INTERVAL = 15_000;

interface mostRecentDataType {
    readerData: Object;
    writerData: Object;
}

const useMostRecentData = (appID: string, stage: "IL" | "CDC"): mostRecentDataType => {
    const [mostRecentData, setMostRecentData] = useState<mostRecentDataType>({
        readerData: {},
        writerData: {}
    });
    async function fetchData() {
        try {
            const appData = await api.getApplicationComponents(appID, true);
            const adapterIDs = [];
            appData.forEach(adapterData => {
                if (["SOURCE", "TARGET"].includes(adapterData.type)) {
                    adapterIDs.push(adapterData.id);
                }
            });

            const monData = await api.getMonitoringStatsForComponentsWithoutTableInfo(
                adapterIDs,
                TYPES.MOST_RECENT_DATA
            );
            const mostRecentData = {} as mostRecentDataType;
            for (const adapterID in monData) {
                const adapterMon = monData[adapterID];
                if (adapterMon.type === "SOURCE") {
                    mostRecentData.readerData = adapterMon["most-recent-data"];
                }
                if (adapterMon.type === "TARGET") {
                    mostRecentData.writerData = adapterMon["most-recent-data"];
                }
            }
            setMostRecentData(mostRecentData);
        } catch (e) {
            console.log("Couldn't fetch data", e);
        }
    }
    useEffect(() => {
        const interval = setInterval(fetchData, INTERVAL);
        fetchData();
        return () => {
            clearInterval(interval);
        };
    }, [stage]);
    return mostRecentData;
};

export default useMostRecentData;
