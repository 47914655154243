import dateLib from "core/utils/date-utils";

/**
 * Constructor.
 * @param argument - can take any argument dayjs takes ( https://day.js.org/docs/en/parse/parse )
 */
var dateTime = function(argument) {
    this.formatString = "MMM DD YYYY, h:mm:ss a";
    this.dateObj = new dateLib(argument);
};
/**
 * rest of object definitions.
 * @type {{toString: Function}}
 */
dateTime.prototype = {
    getDateObject: function() {
        this._overrideDateStringFormat();
        return this.date;
    },

    /**
     * main function to override the date object's toString() to make it more readable than the standard format.
     * To change the format, use this.setFormatString();
     * @private
     */
    _overrideDateStringFormat: function() {
        var that = this;
        this.date = this.dateObj.toDate();
        this.date.toString = function() {
            return that.toString();
        };
    },

    /**
     * read https://day.js.org/docs/en/display/format for format strings.
     * @param format_string
     */
    setFormatString: function(format_string) {
        this.formatString = format_string;
    },

    toString: function() {
        return this.dateObj.format(this.formatString);
    }
};

export default dateTime;
