import React, { useRef, useState } from "react";
import { Box, Divider, Grid, InputAdornment, SvgIcon } from "@mui/material";
import * as Yup from "yup";
import {
    StriimLink,
    StriimTypography,
    StriimRadioGroup,
    StriimRadio,
    StriimControlLabel,
    StriimMessageBox,
    StriimInputField,
    StriimModal,
    StriimButton,
    StriimForm,
    ClearMemory,
    StriimTooltip
} from "@striim/striim-ui";

import { Close, NewTab, OpenAI, SettingsBlue, VertexAI, StriimAI } from "../../../../../../generic/icon/customIcons";
import { AIEngines, modelProviders, NEW_AI_ENGINE_IDENTIFIER } from "../../settings-tab";
import { styles } from "./ai-engine.styles";
import { styles as fileStyles } from "src/modules/apps/pages/user-management/users/components/sso/sso-container.styles.js";
import connectionProfileService from "../../../../../apps/pages/manage-striim/connection-profiles/connection-profile-service";
import growl from "../../../../../../../app/components/common/growl";
import FolderIcon from "src/generic/icon/manage-striim/folder.svg";
import DocumentationIcon from "src/generic/icon/manage-striim/documentation.svg";
import FileSelectModalContent from "../../../../../apps/pages/user-management/users/components/sso/file-select-modal-content";
import dictionary from "../../../../../../../app/components/common/helpable/online-help-dictionary";
import AIEngineTestConnection from "./ai-engine-test-connection";

const validationSchema = Yup.object().shape({
    objectName: Yup.string().required("Required"),
    modelName: Yup.string().required("Required"),
    apiKey: Yup.string().when("modelProvider", {
        is: value => value === modelProviders.openAI,
        then: Yup.string().required("Required")
    }),
    project: Yup.string().when("modelProvider", {
        is: value => value === modelProviders.vertexAI,
        then: Yup.string().required("Required")
    }),
    serviceAccountKey: Yup.string().when("modelProvider", {
        is: value => value === modelProviders.vertexAI,
        then: Yup.string().required("Required")
    }),
    location: Yup.string().when("modelProvider", {
        is: value => value === modelProviders.vertexAI,
        then: Yup.string().required("Required")
    }),
    publisher: Yup.string().when("modelProvider", {
        is: value => value === modelProviders.vertexAI,
        then: Yup.string().required("Required")
    })
});

export enum TestConnectionState {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    INPROGRESS = "INPROGRESS"
}

export type TestConnection = {
    value: TestConnectionState | null;
    errorMessage?: {
        result: boolean;
        message: string;
        jsonErrorDescription?: {
            Summary?: string;
        };
    }
    | string
    | null;
};

interface AIEngineProps {
    isAdmin: boolean;
    selectedEngine: string;
    setSelectedEngine: React.Dispatch<React.SetStateAction<string>>;
    customAIEngines: string[];
    setCustomAIEngines: React.Dispatch<React.SetStateAction<any[]>>;
    selectedCustomAIEngine: object;
    setSelectedCustomAIEngine: React.Dispatch<React.SetStateAction<object>>;
}

const AIEngine = ({
    isAdmin = false,
    setSelectedEngine,
    selectedEngine,
    customAIEngines,
    setCustomAIEngines,
    selectedCustomAIEngine,
    setSelectedCustomAIEngine
}: AIEngineProps) => {
    const [showCreateAIEngineModal, setShowCreateAIEngineModal] = useState<boolean>(false);
    const [showCustomAIEngineError, setShowCustomAIEngineError] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState("");
    const [testConnectionState, setTestConnectionState] = useState<TestConnection>({
        value: null,
        errorMessage: null
    });
    const formRef = useRef();

    const handleChange = (event: { target: { value: React.SetStateAction<string> } }) => {
        const value = event?.target?.value || event.value;
        if (value === NEW_AI_ENGINE_IDENTIFIER) {
            setShowCreateAIEngineModal(true);
        } else {
            setSelectedEngine(value);
        }
    };

    const handleCustomAIEngineChange = option => {
        if (option?.value === NEW_AI_ENGINE_IDENTIFIER) {
            setShowCreateAIEngineModal(true);
        } else {
            setSelectedCustomAIEngine(option);
        }
    };

    const handleTestAndSave = async () => {
        setTestConnectionState({ value: TestConnectionState.INPROGRESS });
        try {
            const values = formRef.current.values;
            const endpoint = values.modelProvider;
            const result = await connectionProfileService.testNewConnectionProfile(values, endpoint);
            const parsedResponse = result && JSON.parse(result);
            if (parsedResponse?.[0]?.result) {
                await handleSave();
                setTestConnectionState({ value: TestConnectionState.SUCCESS });
            } else {
                setTestConnectionState({ value: TestConnectionState.ERROR, errorMessage: parsedResponse });
            }
        } catch (error) {
            setTestConnectionState({ value: TestConnectionState.ERROR, errorMessage: error });
        }
    };

    const handleSave = async () => {
        try {
            const values = formRef.current.values;
            const name = values.objectName;
            const namespace = "admin";
            await connectionProfileService.createConnectionProfile(name, namespace, values.modelProvider, values);
            const engineName = `${namespace}.${name}`;
            const newOption = { label: engineName, value: engineName };
            setSelectedCustomAIEngine(newOption);
            setCustomAIEngines(prev => [newOption, ...prev]);
            setShowCreateAIEngineModal(false);
            setShowCustomAIEngineError(false);
            formRef.current?.resetForm();
            growl.success(`Successfully added the AI Engine : ${name}`);
        } catch (error) {
            growl.error(error);
        }
    };

    return (
        <Box display="flex" flexDirection={"column"} px={4} pt={4} pb={2}>
            {!isAdmin && (
                <StriimMessageBox
                    type="NOTIFICATION"
                    customCardStyles={{ width: "600px", mb: 2 }}
                    text={
                        <StriimTypography variant="body4" color="greyscale.800">
                            Contact your Striim admin to edit the settings
                        </StriimTypography>
                    }
                />
            )}
            <Box display="flex" alignItems="center" mb={2}>
                <SettingsBlue style={styles.settingsIcon} />
                <StriimTypography variant="h3" lineHeight={20} color={"primary.700"}>
                    AI Engine
                </StriimTypography>
                <a
                    href={dictionary.get()["AI_INSIGHTS_ENGINE"].href}
                    target="_blank"
                    style={{ ...styles.documentationIcon }}
                >
                    <SvgIcon component={DocumentationIcon} sx={styles.documentationIcon} />
                </a>
            </Box>
            <StriimTypography variant="body4" color="greyscale.700">
                {isAdmin
                    ? "Which AI engine would you like to use for sensitive data discovery reports in Striim?"
                    : "Sensitive data discovery is setup to use the Striim AI engine. "}
            </StriimTypography>
            {isAdmin && (
                <>
                    <Box>
                        <StriimLink
                            endIcon={<NewTab style={styles.icon} />}
                            sx={{ ml: -2 }}
                            href={dictionary.get()["AI_INSIGHTS_ENGINE"].href}
                            target="_blank"
                        >
                            <StriimTypography color="inherit" variant="caption3">
                                Learn about the different Engines
                            </StriimTypography>
                        </StriimLink>
                    </Box>
                    <StriimRadioGroup
                        row={false}
                        aria-labelledby="ai-engine-radio-buttons-group"
                        name="ai-engine-radio-group"
                        value={selectedEngine}
                        onChange={handleChange}
                    >
                        <StriimControlLabel
                            sx={styles.noSpaceLabel}
                            color="greyscale.700"
                            value={AIEngines.striim}
                            control={<StriimRadio />}
                            label={
                                <StriimTypography color="greyscale.700" variant="body4">
                                    Striim AI engine on my Striim server
                                </StriimTypography>
                            }
                            data-testid="ai-engine-striim"
                        />
                        <StriimLink
                            sx={styles.installationGuideLink}
                            endIcon={<NewTab style={styles.icon} />}
                            href={dictionary.get()["AI_INSIGHTS_ENGINE"].href}
                            target="_blank"
                        >
                            <StriimTypography color="inherit" variant="caption3">
                                Installation Guide
                            </StriimTypography>
                        </StriimLink>
                        <StriimControlLabel
                            value="custom"
                            control={<StriimRadio />}
                            color="greyscale.700"
                            label={
                                <StriimTypography color="greyscale.700" variant="body4">
                                    Custom AI engine
                                </StriimTypography>
                            }
                            data-testid="ai-engine-custom"
                        />
                        {selectedEngine == AIEngines.custom &&
                        customAIEngines.length === 1 &&
                        showCustomAIEngineError ? (
                            <StriimMessageBox
                                type="ERROR"
                                customCardStyles={{ width: "400px", ...styles.messageBox }}
                                text={
                                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                        <StriimTypography variant="body4" color="greyscale.800">
                                            Configure an AI Engine to proceed.
                                        </StriimTypography>
                                        <StriimButton variant="text" onClick={() => setShowCreateAIEngineModal(true)}>
                                            Add
                                        </StriimButton>
                                    </Grid>
                                }
                            />
                        ) : selectedEngine === "custom" ? (
                            <StriimInputField
                                id="data-test-id-custom-ai-engine"
                                name="customAIEngine"
                                select
                                placeholder="Select"
                                sx={styles.customSelector}
                                required={true}
                                value={selectedCustomAIEngine}
                                SelectProps={{
                                    isClearable: false,
                                    options: customAIEngines,
                                    components: {
                                        IndicatorSeparator: () => null
                                    }
                                }}
                                onChange={handleCustomAIEngineChange}
                            />
                        ) : null}
                    </StriimRadioGroup>
                    <StriimForm
                        values={{
                            modelProvider: modelProviders.openAI,
                            publisher: "google"
                        }}
                        validationSchema={validationSchema}
                        formRef={formRef}
                        validateOnMount={true}
                        validateOnChange={true}
                    >
                        {({ values, handleChange, setFieldValue, isValid }) => {
                            return (
                                <>
                                    <StriimModal
                                        autoHeight
                                        disableMaxHeight
                                        isVisible={showCreateAIEngineModal}
                                        size="medium"
                                        footerBorder
                                        dialogTitleProps={{
                                            display: "none"
                                        }}
                                        dialogContentProps={{
                                            style: {
                                                padding: 0
                                            }
                                        }}
                                        titleContent={<></>}
                                        footerContent={
                                            <>
                                                <StriimButton
                                                    variant="text"
                                                    onClick={() => {
                                                        setShowCustomAIEngineError(true);
                                                        setShowCreateAIEngineModal(false);
                                                    }}
                                                    data-test-id={"add-ai-engine-cancel-button"}
                                                >
                                                    Cancel
                                                </StriimButton>
                                                <StriimButton
                                                    onClick={handleTestAndSave}
                                                    variant="primary"
                                                    disabled={
                                                        !isValid ||
                                                        testConnectionState.value === TestConnectionState.INPROGRESS
                                                    }
                                                    data-test-id={"add-ai-engine-save-button"}
                                                >
                                                    Test & Save
                                                </StriimButton>
                                            </>
                                        }
                                    >
                                        <Grid
                                            container
                                            width={"100%"}
                                            height={"100%"}
                                            display="flex"
                                            data-test-id="create-ai-engine-modal"
                                        >
                                            <Grid container item flex={1} flexDirection={"column"}>
                                                <Grid
                                                    container
                                                    p={3}
                                                    pb={0}
                                                    justifyContent={"space-between"}
                                                    alignItems={"center"}
                                                >
                                                    <StriimTypography variant={"h2"} color="primary.700">
                                                        New Custom AI Engine
                                                    </StriimTypography>
                                                    <SvgIcon
                                                        component={Close}
                                                        sx={styles.closeIcon}
                                                        onClick={() => {
                                                            setShowCustomAIEngineError(true);
                                                            setShowCreateAIEngineModal(false);
                                                        }}
                                                    />
                                                </Grid>
                                                {[TestConnectionState.INPROGRESS, TestConnectionState.ERROR].includes(
                                                    testConnectionState.value
                                                ) && (
                                                    <AIEngineTestConnection testConnectionState={testConnectionState} />
                                                )}
                                                {testConnectionState.value !== TestConnectionState.INPROGRESS && (
                                                    <>
                                                        <Grid item p={3} pt={2}>
                                                            <StriimTypography variant="caption3" color="greyscale.800">
                                                                AI Model Provider
                                                            </StriimTypography>
                                                            <StriimRadioGroup
                                                                row={false}
                                                                aria-labelledby="ai-engine-model-provider"
                                                                name="modelProvider"
                                                                value={values.modelProvider}
                                                                onChange={event => {
                                                                    setFieldValue("modelProvider", event.target.value);
                                                                    handleChange(event);
                                                                }}
                                                                isFormElement
                                                            >
                                                                <StriimControlLabel
                                                                    value={modelProviders.openAI}
                                                                    control={<StriimRadio />}
                                                                    label={
                                                                        <Grid container gap={1} alignItems={"center"}>
                                                                            <SvgIcon
                                                                                component={OpenAI}
                                                                                sx={styles.logoIcon}
                                                                            />
                                                                            <StriimTypography
                                                                                color="greyscale.800"
                                                                                variant="body4"
                                                                            >
                                                                                Open AI
                                                                            </StriimTypography>
                                                                        </Grid>
                                                                    }
                                                                    isFormElement
                                                                />
                                                                <StriimControlLabel
                                                                    sx={styles.noSpaceLabel}
                                                                    color="greyscale.700"
                                                                    value={modelProviders.vertexAI}
                                                                    control={<StriimRadio />}
                                                                    label={
                                                                        <Grid container gap={1} alignItems={"center"}>
                                                                            <SvgIcon
                                                                                component={VertexAI}
                                                                                sx={styles.logoIcon}
                                                                            />
                                                                            <StriimTypography
                                                                                color="greyscale.800"
                                                                                variant="body4"
                                                                            >
                                                                                Vertex AI
                                                                            </StriimTypography>
                                                                        </Grid>
                                                                    }
                                                                    isFormElement
                                                                />
                                                            </StriimRadioGroup>
                                                        </Grid>
                                                        <Divider
                                                            variant="fullWidth"
                                                            sx={{ borderColor: "greyscale.200" }}
                                                        />
                                                        <Grid item flex={1} width={"100%"} p={3}>
                                                            <StriimInputField
                                                                isFormElement
                                                                name="objectName"
                                                                label="Object Name"
                                                                placeholder="Enter Object Name"
                                                                required
                                                                sx={styles.input}
                                                            />
                                                            <StriimInputField
                                                                isFormElement
                                                                name="modelName"
                                                                label="Model Name"
                                                                required
                                                                sx={styles.input}
                                                            />
                                                            {values.modelProvider === modelProviders.openAI ? (
                                                                <StriimInputField
                                                                    isFormElement
                                                                    name="apiKey"
                                                                    label="API Key"
                                                                    placeholder="Enter your API Key"
                                                                    type="password"
                                                                    required
                                                                />
                                                            ) : (
                                                                <>
                                                                    <StriimInputField
                                                                        isFormElement
                                                                        name="project"
                                                                        label="Project ID"
                                                                        placeholder="Enter your Project ID"
                                                                        required
                                                                        sx={styles.input}
                                                                    />
                                                                    <Grid
                                                                        container
                                                                        justifyContent={"center"}
                                                                        alignItems={"center"}
                                                                        gap={2}
                                                                    >
                                                                        <StriimInputField
                                                                            isFormElement
                                                                            name="serviceAccountKey"
                                                                            label="Service Account Key"
                                                                            required
                                                                            sx={styles.input}
                                                                            InputProps={{
                                                                                onClick: () => setShowModal(true),
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        {values.serviceAccountKey && (
                                                                                            <Close
                                                                                                onClick={(
                                                                                                    e: React.MouseEvent<
                                                                                                        HTMLElement
                                                                                                    >
                                                                                                ) => {
                                                                                                    setFieldValue(
                                                                                                        "serviceAccountKey",
                                                                                                        ""
                                                                                                    );
                                                                                                    e.stopPropagation();
                                                                                                }}
                                                                                                style={{
                                                                                                    fontSize: "20px",
                                                                                                    fill: "none",
                                                                                                    cursor: "pointer"
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                        <FolderIcon
                                                                                            style={{
                                                                                                fontSize: "20px",
                                                                                                fill: "none"
                                                                                            }}
                                                                                        />
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                        />
                                                                    </Grid>

                                                                    <StriimInputField
                                                                        isFormElement
                                                                        name="location"
                                                                        label="Region"
                                                                        placeholder="Enter your region"
                                                                        required
                                                                        sx={styles.input}
                                                                    />
                                                                    <StriimInputField
                                                                        isFormElement
                                                                        name="publisher"
                                                                        label="Publisher"
                                                                        placeholder="Enter your Publisher"
                                                                        required
                                                                    />
                                                                    {/* //TODO: Replace below with FileBrowserV2 : DEV-45229 */}
                                                                    {showModal && (
                                                                        <StriimModal
                                                                            size="medium"
                                                                            autoHeight
                                                                            isVisible={showModal}
                                                                            footerBorder
                                                                            footerContent={
                                                                                <Grid
                                                                                    container
                                                                                    justifyContent="space-between"
                                                                                    alignItems="center"
                                                                                >
                                                                                    <Grid
                                                                                        item
                                                                                        sx={fileStyles.selectedFileName}
                                                                                    >
                                                                                        {selectedFile && (
                                                                                            <>
                                                                                                <StriimTypography
                                                                                                    variant="caption3"
                                                                                                    sx={
                                                                                                        fileStyles.modalFooterText
                                                                                                    }
                                                                                                >
                                                                                                    Selected File{" "}
                                                                                                </StriimTypography>
                                                                                                <StriimTooltip
                                                                                                    title={
                                                                                                        selectedFile.name
                                                                                                    }
                                                                                                >
                                                                                                    <StriimTypography
                                                                                                        variant="caption3"
                                                                                                        color="secondary.500"
                                                                                                        sx={
                                                                                                            fileStyles.modalFooterText
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            selectedFile.name
                                                                                                        }
                                                                                                    </StriimTypography>
                                                                                                </StriimTooltip>
                                                                                            </>
                                                                                        )}
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <StriimButton
                                                                                            variant="text"
                                                                                            onClick={() => {
                                                                                                setSelectedFile("");
                                                                                                ClearMemory();
                                                                                                setShowModal(false);
                                                                                            }}
                                                                                        >
                                                                                            Cancel
                                                                                        </StriimButton>
                                                                                        <StriimButton
                                                                                            disabled={!selectedFile}
                                                                                            data-test-id={
                                                                                                "confirm-select-file"
                                                                                            }
                                                                                            onClick={() => {
                                                                                                ClearMemory();
                                                                                                setShowModal(false);
                                                                                                setFieldValue(
                                                                                                    "serviceAccountKey",
                                                                                                    selectedFile?.currentPath ||
                                                                                                        selectedFile?.path
                                                                                                );
                                                                                            }}
                                                                                            variant="primary"
                                                                                        >
                                                                                            Done
                                                                                        </StriimButton>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            }
                                                                        >
                                                                            <FileSelectModalContent
                                                                                selectedFile={selectedFile}
                                                                                onCancel={() => setShowModal(false)}
                                                                                onSelected={file => {
                                                                                    setSelectedFile(file);
                                                                                }}
                                                                                allowedExtensions={[]}
                                                                            />
                                                                        </StriimModal>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </StriimModal>
                                </>
                            );
                        }}
                    </StriimForm>
                </>
            )}
        </Box>
    );
};

export default AIEngine;
