import _ from "underscore";
import $ from "jquery";
import "jquery-slimscroll/jquery.slimscroll";
import utils from "core/utils";
import specialCharactersEncodingFixModule from "core/api/specialCharactersEncodingFixModule";
import RMIFramework from "core/api/RMIFramework";
import methods_json from "core/api/webaction-api-methods.json";
import { TYPES } from "../services/monitorService";
import { Mutex } from "async-mutex";
import axios from "axios";
const CRUDMutex = new Mutex();
import Logger from "../logger";
/*
 * API module
 * Requires RMIFramework.js and webaction-api-methods.json
 *
 * Configuration options
 *      wsurl: WebSocket URL (If you want this API to use its own WebSocket, you can provide this as the URL)
 *      token: the current auth token (Required)
 */
var API = function(opts) {
    var _this = this;
    const debugApiCalls = false;

    // Validate opts
    opts = opts || {};

    this.token = opts.token;
    this.start = function(customOpts) {
        if (this.rmifw) {
            return;
        }

        // Set up internal properties
        const options = Object.assign({}, opts, customOpts || {});
        this.rmifw = new RMIFramework(options);
    }.bind(this);

    // Surface other utility methods
    this.config = function(opts) {
        opts = opts || {};
        if (opts.token) {
            this.token = opts.token;
        }
        if (opts.dispatcher) {
            this.rmifw.dispatcher = opts.dispatcher;
        }
    };
    this.connect = function(opts) {
        this.rmifw.connect(opts);
    };
    this.disconnect = function(callback) {
        this.rmifw.disconnect(callback);
    };
    this.getDispatcher = function() {
        return this.rmifw.dispatcher;
    };
    this.getWebSocket = function() {
        return this.rmifw.ws;
    };

    this.error = function error(msg) {
        throw new Error("WebAction RMI API error: " + msg);
    };

    // Build API methods lookup table
    var methods = {};
    var methodnames = [];
    for (var classname in methods_json) {
        if (methods_json.hasOwnProperty(classname)) {
            var classmethods = methods_json[classname];
            for (var m_index in classmethods) {
                if (classmethods.hasOwnProperty(m_index)) {
                    var methoddata = classmethods[m_index];
                    methodnames.push(methoddata.id);
                    methods[methoddata.id] = {
                        method: methoddata.name,
                        class: classname
                    };
                }
            }
        }
    }

    // Internal getter for remote method name and class
    var method = function(methodID) {
        if (_(methodID).isObject()) {
            return methodID;
        }
        return methods[methodID];
    };

    var expensiveCallTimeout = 2000;

    // The cornerstone call method which interacts with RMIFramework and returns a promise.
    // Every API method must return this.call();
    this.parseConnectivityParams = function(connectivityParams) {
        const { userName, password, URL, passwordEncrypted, sslConfigMap } = connectivityParams;
        const params = { UserName: userName, Password: password, ConnectionURL: URL };
        passwordEncrypted && (params["encrypted"] = passwordEncrypted);
        sslConfigMap && (params["sslConfigMap"] = sslConfigMap);
        return params;
    };

    this.parseParamsForEncryptedURL = function(properties, appName, agentName) {
        if (agentName === undefined) return [properties, appName];
        return [properties, appName, agentName];
    };

    this.call = function(methodID, args) {
        const methodArgs = arguments;
        //console.log( "\ncalling: ", methodID, " args: ", args );

        if (arguments.length === 0) {
            return false;
        }
        if (!methodID) {
            this.error(
                "No method name specified in args " +
                    JSON.stringify(args) +
                    ".\nAvailable methods are:" +
                    JSON.stringify(methodnames)
            );
        }
        var methoddata = method(methodID);
        if (!methoddata) {
            this.error(
                "The API method " + methodID + " does not exist.\nAvailable methods are:" + JSON.stringify(methodnames)
            );
        }

        const debugMethodLabel = `#${methoddata.class}.${methoddata.method} --> ${new Date().toLocaleTimeString()}`;

        var begin = Date.now();
        var deferred = $.Deferred();
        this.rmifw
            .call(methoddata, args)
            .then(function(e) {
                var timeSpent = Date.now() - begin;
                if (_this.env === "dev" && timeSpent > expensiveCallTimeout) {
                    Logger.log(
                        "EXPENSIVE CALL",
                        Logger.COLORS.NETWORK_RES_WARN,
                        `${methoddata.method} took ${timeSpent} ms`,
                        { Method: methoddata, Request: args, Response: e }
                    );
                }

                if (_this.env === "dev" && debugApiCalls) {
                    console.groupCollapsed(debugMethodLabel);
                    console.group("Request args");
                    console.log(args);
                    console.groupEnd();
                    console.group("Response");
                    console.log(e);
                    console.groupEnd();
                    console.log(`Time ${timeSpent} ms`);
                    console.groupEnd();
                }

                deferred.resolve.apply(deferred, arguments);
            })
            .catch(function(e) {
                if (_this.env === "dev") {
                    var timeSpent = Date.now() - begin;
                    console.error(
                        "Error API call failed! Call took " +
                            timeSpent +
                            " milliseconds\nStacktrace: " +
                            e +
                            "\n\nCall result: " +
                            JSON.stringify(methodArgs)
                    );
                }
                deferred.reject.apply(deferred, arguments);
            });

        return deferred.promise();
    };

    this.call_direct = function(className, methodName, args) {
        //console.log( "\ncalling: ", methodID, " args: ", args );

        return this.rmifw.call(
            {
                class: className,
                method: methodName
            },
            _.union([], args)
        );
    };

    // Surface the function which allows adding methods to the API
    this.extend = function(methods) {
        for (var methodname in methods) {
            if (methods.hasOwnProperty(methodname)) {
                var method = methods[methodname];
                if (_(method).isFunction()) {
                    this[methodname] = method.bind(this);
                }
            }
        }
    };
};

// Export the module
// var api;
//
// if (window.WebActionAPI instanceof API) {
//     // If an instance of the API already exists in the global scope, just return it
//     console.log("API instance already exists.");
//     api = window.WebActionAPI;
// }
// else {
//     // Instantiate. Note that token must be set later.
//     var api_instance = new API();
//
//     // Transport the API module by returning it here, but also add it to the global context
//     window.WebActionAPI = api_instance;
//     api = api_instance;
// }

/*
 * API Methods
 * To add your own API methods, add them to the below collection.
 * See https://webaction.atlassian.net/wiki/display/DEV/RMIWS+Javascript+API for more information
 */

API.prototype = {
    /* Security
     ---------------------------------------------------------*/

    // result: true|false
    isAuthenticated: function(token) {
        var p = this.call("ISAUTHENTICATED", [token]);
        return p;
    },
    authenticate: function(args) {
        args = args || {};
        return this.call("AUTHENTICATE", [args.username, args.password]);
    },
    setToken: function(token) {
        this.token = token;
    },

    getAuthenticatedUser: function() {
        return this.call("GETAUTHENTICATEDUSER", [this.token]);
    },

    // result: "Completed."
    createContext: function() {
        return this.call("CREATECONTEXT", [this.token]);
    },

    // Alias
    setCurrentNamespace: function(namespace) {
        this.currentNamespace = namespace;
        return this.call("USESCHEMA", [this.token, namespace]);
    },
    getCurrentNamespace: function() {
        return this.currentNamespace;
    },
    setCurrentAppId: function(appname) {
        this.currentApp = appname;
    },
    getCurrentAppId: function() {
        return this.currentApp;
    },

    /* App control
     ---------------------------------------------------------*/

    /**
     *  Start app/flow
     *  Usage:
     *      startApp("SaasMonitorApp.SaasMonitorApp");
     */
    startApp: function(name) {
        var args = [this.token, name, "APPLICATION"];
        return this.call("START", args);
    },

    /**
     *  Stop app/flow
     *  Usage:
     *      stopApp("SaasMonitorApp.SaasMonitorApp");
     */
    stopApp: function(name) {
        var args = [this.token, name, "APPLICATION"];
        return this.call("STOP", args);
    },

    /**
     *  Quiesce app/flow
     *  Usage:
     *      quiesceApp("SaasMonitorApp.SaasMonitorApp");
     */
    quiesceApp: function(name) {
        var args = [this.token, name, "APPLICATION"];
        return this.call("QUIESCE", args);
    },

    /**
     *  Deploy app
     *  Acceptable argument examples:
     *  deploy([
     *      { name: "SaasMonitorApp", strategy: "all", group: "DG1" },
     *      { name: "SourceFlow", strategy: "any", group: "DG2" },
     *      { name: "ApiFlow", strategy: "all", group: "DG3" }
     *  ], { namespace: "SaasMonitorApp" });
     *  Specifying namespace is optional.
     *  deploy({
     *      "SaasMonitorApp": { strategy: "all", group: "DG1" },
     *      "SourceFlow": { strategy: "any", group: "DG2" },
     *      "ApiFlow": { strategy: "all", group: "DG3" }
     *  }, { namespace: "SaasMonitorApp"});
     */
    deploy: function(flows, where) {
        where = where || {};
        const { namespace, appName } = where;
        var deferred = $.Deferred();
        var app_desc = [],
            flows_desc = [];
        var i = 0;

        // Build out the deploy args
        _(flows).each(function(depl_data, key) {
            // If the arguments were passed as an assoc array, "key" will be the name
            var desc = {
                strategy: depl_data.strategy || "all",
                flow: depl_data.name || key,
                group: depl_data.group || "default"
            };

            console.log("Deploying:", desc);
            if (depl_data.flow === appName) {
                desc.flow = namespace ? `${namespace}.${appName}` : appName;
                app_desc = desc;
            } else {
                flows_desc.push(desc);
            }
            i++;
        });

        var args = [this.token, "APPLICATION", app_desc, flows_desc];

        // If namespace is specified, set current namespace first
        if (where) {
            api.setCurrentNamespace(namespace).always(() => {
                api.call("DEPLOY", args)
                    .then(result => {
                        deferred.resolve(result);
                    })
                    .fail(e => {
                        deferred.reject(e);
                    });
            });
        } else {
            api.call("DEPLOY", args)
                .then(result => {
                    deferred.resolve(result);
                })
                .fail(e => {
                    deferred.reject(e);
                });
        }

        return deferred.promise();
    },

    /**
     *  Undeploy app
     *  Usage:
     *      undeploy("SaasMonitorApp.SaasMonitorApp");
     */
    undeploy: function(name) {
        var args = [this.token, name, "APPLICATION"];
        return this.call("UNDEPLOY", args);
    },

    resumeApp: function(name) {
        var args = [this.token, name, "APPLICATION"];
        return this.call("RESUME", args);
    },

    /**
     *  Drop an object
     *  obj: either the metaobject or { name: objectname, type: objecttype }
     */
    dropObject: function(obj, cascading) {
        if (typeof cascading === "undefined") {
            cascading = true;
        }
        return this.call("DROP", [this.token, obj.type.toUpperCase(), obj.name, cascading ? "true" : "false", "false"]);
    },

    compile: function(name) {
        // TODO: name is just app or flow name, not namespace.appname
        var deferred = $.Deferred();
        (async () => {
            await CRUDMutex.runExclusive(async () => {
                try {
                    let d = await this.call("ALTERRECOMPILE", [this.token, "APPLICATION", name]);
                    deferred.resolve(d);
                } catch (e) {
                    deferred.reject(e);
                }
            });
        })();

        return deferred.promise();
    },

    createTQL: function(appFullName, passphrase) {
        let nsname = utils.getNamespace(appFullName);
        let appname = utils.getName(appFullName);

        var args = [this.token, nsname, appname, true, passphrase];
        return this.call("GETTQL", args);
    },

    getFlowErrors: function(appName) {
        var args = [this.token, appName, "APPLICATION"];
        return this.call("GETFLOWERRORS", args);
    },

    compileTQL: function(text, passphrase) {
        if (!passphrase) passphrase = null;
        var args = [this.token, text, passphrase];
        return this.call("COMPILETEXT", args);
    },

    importDashboard: function(json, namespace) {
        var args = namespace ? [this.token, namespace, true, null, json] : [this.token, true, null, json];
        return this.call("IMPORTDASHBOARD", args);
    },

    importPartialDashboard: function(appId, templateName, templateData) {
        var args = [this.token, appId, templateName, templateData];
        return this.call("IMPORTPARTIALDASHBOARD", args);
    },

    exportDashboard: function(name) {
        var args = [this.token, name];
        return this.call("EXPORTDASHBOARD", args);
    },

    startReport: function(appId) {
        var args = [this.token, appId];
        return this.call("CREATEREPORTSTARTSTMT", args);
    },

    stopReport: function(appId) {
        var args = [this.token, appId];
        return this.call("CREATEREPORTENDSTMT", args);
    },

    isReportRunning: function(appId) {
        var args = [this.token, appId];
        return this.call("ISREPORTRUNNING", args);
    },

    /* Data
     ---------------------------------------------------------*/

    subscribe: function(queuename) {
        return this.call("SUBSCRIBE", [queuename, this.token]);
    },
    unsubscribe: function(queuename, uuid) {
        return this.call("UNSUBSCRIBE", [queuename, uuid, this.token]);
    },

    /*
     * 1. getWactions(storename, opts)
     *    storename: WActionStore name
     *    opts: { fields: [], filter: {}, sortBy: "fieldname", limit: 4, etc. }
     * 2. getWactions(opts)
     */
    getWactions: function() {
        var storename, opts;

        if (_(arguments[0]).isObject()) {
            opts = arguments[0];
        } else {
            storename = arguments[0];
            opts = arguments[1];
        }

        _(opts || {}).defaults({
            fields: null,
            filter: null
        });

        var map_opts = _(opts).omit("fields", "filter");
        var map = _({
            context: opts.filter
        }).extend(map_opts);

        var args = storename ? [storename, opts.fields, map, this.token] : [opts.fields, map, this.token];
        console.error("AVOID CALL 1: GETCLUSTERSTATS");
        return this.call("GETCLUSTERSTATS", args);
    },
    /* Get cluster level monitor stats. Includes summary of throughput, CPU, and memory usage of servers and apps */
    getClusterStats: function(fields, filter) {
        var filterArgs;
        if (!filter) {
            filterArgs = "null";
        } else {
            filterArgs = filter;
        }
        var callArgs = ["Striim_Monitor", fields, filterArgs, this.token];
        console.error("AVOID CALL 2: GETCLUSTERSTATS");
        return this.call("GETCLUSTERSTATS", callArgs);
    },

    getWactionStatistics: function(startTime, endTime, rollupinterval, componentUUID) {
        const storeName = "Striim_Monitor";
        let wactionKey = {
            id: componentUUID,
            key: componentUUID //"01e7d9fc-8c86-a5f2-819d-000ec6fd4a80"
        };
        const fields = ["time-series"];

        let filter = {
            entityID: componentUUID,
            "start-time": startTime,
            "end-time": endTime,
            rollupinterval: rollupinterval,
            "do-rollups": true
        };

        let args = [storeName, wactionKey, fields, filter, this.token];

        return this.call("GETWACTION", args);
    },

    createNamedQuery: function(definition) {
        return this.call("CREATENAMEDQUERY", [this.token, definition]);
    },
    startNamedQuery: function(id) {
        return this.call("STARTNAMEDQUERY", [this.token, id]);
    },
    stopNamedQuery: function(id) {
        return this.call("STOPNAMEDQUERY", [this.token, id]);
    },
    createParameterizedQuery: function(queryString, name) {
        return this.call("CREATEPARAMETERIZEDQUERY", [true, this.token, queryString, name]);
    },
    prepareParameterizedQuery: function(id, params) {
        return this.call("PREPAREPARAMETERIZEDQUERY", [this.token, id, params]);
    },
    prepareFilteredQuery: function(queryUUID, attributes) {
        return this.call("PREPAREFILTEREDQUERY", [this.token, queryUUID, attributes]);
    },
    createAHQuery: function(definition) {
        return this.call("CREATENAMEDQUERY", [this.token, definition]);
    },
    startAHQuery: function(id) {
        return this.call("STARTADHOCQUERY", [this.token, id]);
    },
    stopAHQuery: function(id) {
        return this.call("STOPADHOCQUERY", [this.token, id]);
    },
    deleteAHQuery: function(id) {
        return this.call("DELETEADHOCQUERY", [this.token, id]);
    },
    /**
     *
     * @param id string
     * @param permissions array
     * @returns {*}
     */
    getAllowedPermissions: function(id, permissions) {
        return this.call("GETALLOWEDPERMISSIONS", [this.token, id, permissions]);
    },

    _CRUDHandler: function(appOrFlowName, operation, metaObjectJSON) {
        let call;

        if (operation === "UPDATE" || operation === "CREATE") {
            specialCharactersEncodingFixModule.escapeMetaObject(metaObjectJSON);
            let deferred = $.Deferred();
            (async () => {
                await CRUDMutex.runExclusive(async () => {
                    try {
                        let d = await this.call("CRUDHANDLER", [this.token, appOrFlowName, operation, metaObjectJSON]);
                        deferred.resolve(d);
                    } catch (e) {
                        deferred.reject(e);
                    }
                });
            })();
            call = deferred.promise();
        } else {
            call = this.call("CRUDHANDLER", [this.token, appOrFlowName, operation, metaObjectJSON]);
        }

        if (operation === "READ") {
            return call.then(
                function(result) {
                    if ($.isArray(result)) {
                        result.forEach(function(item) {
                            specialCharactersEncodingFixModule.unescapeMetaObject(item);
                        }, this);
                    }

                    return result;
                }.bind(this)
            );
        }

        return call;
    },
    create: function(appOrFlowName, metaObjectJSON) {
        return this._CRUDHandler(appOrFlowName, "CREATE", metaObjectJSON);
    },
    read: function(appOrFlowName, metaObjectJSON) {
        return this._CRUDHandler(appOrFlowName, "READ", metaObjectJSON);
    },
    update: function(appOrFlowName, metaObjectJSON) {
        return this._CRUDHandler(appOrFlowName, "UPDATE", metaObjectJSON);
    },
    delete: function(appOrFlowName, metaObjectJSON) {
        return this._CRUDHandler(appOrFlowName, "DELETE", metaObjectJSON);
    },
    updateApplicationSettings: function(metaObjectJSON) {
        return this.call("UPDATEAPPLICATIONSETTINGS", [this.token, metaObjectJSON.uuid, metaObjectJSON]);
    },
    getActionList: function(type) {
        return this.call("ACTIONHANDLER", [type, this.token]);
    },
    getMaskingTemplate: function() {
        return this.call("GETMASKINGTEMPLATE");
    },
    areTemplatesAllowed: function(templates) {
        return this.call("ARETEMPLATESALLOWED", [templates]);
    },
    getCurrentLicensingOption: function() {
        return this.call("GETCURRENTLICNSINGOPTION", []);
    },
    isTrialLicense: function() {
        return this.call("ISTRIALLICENSE", []);
    },
    shouldTrackUI: function() {
        return this.call("SHOULDTRACKUI", []);
    },
    shouldEnableStriimCopilot: function() {
        return this.call("SHOULDENABLESTRIIMCOPILOT", []);
    },

    getFeatureStates: function() {
        return this.call("GETFEATURESTATES", []);
    },

    getCurrentLicense: function() {
        return this.call("GETCURRENTLICENSE", []);
    },

    enablePlanFeature: function(key) {
        return this.call("ENABLEFEATURE", [key]);
    },

    disablePlanFeature: function(key) {
        return this.call("DISABLEFEATURE", [key]);
    },

    isFeatureEnabled: function(key) {
        return this.call("ISFEATUREENABLED", [key]);
    },

    getDemoFileTargetProperties: function() {
        return this.call("GETDEMOFILETARGETPROPERTIES", [this.token]);
    },

    getDemoKafkaTargetProperties: function() {
        return this.call("GETDEMOKAFKATARGETPROPERTIES", [this.token]);
    },

    getMetaObjectIdentifiersByType: function(type) {
        return this.call("GETIDSBYTYPE", [this.token, type]);
    },

    getMetaObjectsByType: function(types) {
        return this.call("GETMETADATABYTYPE", [this.token, types]);
    },
    getApplicationStatus: function(app) {
        return this.call("GETAPPLICATIONSSTATUS", [this.token, app]);
    },
    getAllApplicationStatuses: function() {
        return this.call("GETALLAPPLICATIONSTATUSES", [this.token]);
    },
    getApplicationComponents: function(app, deep) {
        return this.call("GETAPPLICATIONCOMPONENTS", [this.token, app, deep || false]);
    },
    getApplicationComponentIdentifiers: function(app) {
        return this.call("GETAPPLICATIONCOMPONENTSIDS", [this.token, app]);
    },
    getApplicationComponentsByType: function(app, type, deep) {
        return this.call("GETAPPLICATIONCOMPONENTSBYTYPE", [this.token, app, type, deep || true]);
    },
    getApplicationComponentsByTypes: function(app, types, deep) {
        return this.call("GETAPPLICATIONCOMPONENTSBYTYPES", [this.token, app, types, deep || true]);
    },
    getApplicationComponentsByTypesForMultipleApps: function(apps, types, deep) {
        return this.call("GETAPPLICATIONCOMPONENTSBYTYPESFORMULTIMPLEAPPS", [this.token, apps, types, deep || true]);
    },
    getApplicationRollup: function(app) {
        return this.call("GETAPPLICATIONROLLUP", [this.token, app]);
    },
    getApplicationRollups: function(apps) {
        return this.call("GETAPPLICATIONROLLUPS", [this.token, apps]);
    },
    getObjectParentApplications: function(objectId) {
        return this.call("GETOBJECTPARENTAPPLICATIONS", [this.token, objectId]);
    },

    /**
     * Used to escape array of table names
     * @param fullyQualifiedNames - array of table names
     * @returns {Promise<string>} Promise resolved with escaped table names
     */
    getEscapedNames: function(fullyQualifiedNames) {
        return this.call("GETESCAPEDNAMES", [fullyQualifiedNames]);
    },
    getValidationErrors: function(APPFQN, deploymentInfo) {
        return this.call("GETVALIDATIONERRORS", [this.token, APPFQN, deploymentInfo]);
    },
    isValidationEnabled: function(APPFQN) {
        return this.call("ISVALIDATIONENABLED", [this.token, APPFQN, /* constant parameter */ null]);
    },
    attachDashboardToApplication: function(dashboardFQN, APPFQN) {
        return this.call("ATTACHDASHBOARDTOAPPLICATION", [this.token, dashboardFQN, APPFQN]);
    },
    findAttachedDashboards: function(APPFQN) {
        return this.call("FINDATTACHEDDASHBOARDS", [this.token, APPFQN]);
    },
    findAttachedDashboardsForApps: function(APPFQNS) {
        return this.call("FINDATTACHEDDASHBOARDSFORAPPS", [this.token, APPFQNS]);
    },
    isEligibleForDataValidation: function(APPFQN) {
        return this.call("ISELIGIBLEFORDATAVALIDATION", [this.token, APPFQN]);
    },
    createDataValidationDashboard: function(APPFQN, DashboardName) {
        return this.call("CREATEDATAVALIDATIONDASHBOARD", [this.token, APPFQN, DashboardName]);
    },
    setEnableDataValidation: function(APPFQN, setEnabled) {
        return this.call("SETENABLEDATAVALIDATION", [this.token, APPFQN, setEnabled]);
    },
    getFileMetadata: function(appID, type) {
        return this.call("GETFILEMETADATA", [this.token, appID, type]);
    },
    isFileMetadataEnabled: function() {
        return this.call("ISFILEMETADATAENABLED", []);
    },
    getMetadataEnabledTempaltes: function() {
        return this.call("GETMETADATAENABLEDTEMPLATES", []);
    },
    getOracleMetadata: function(appID, type) {
        return this.call("GETORACLEMETADATA", [this.token, appID, type]);
    },
    isOracleMetadataEnabled: function() {
        return this.call("ISORACLEMETADATAENABLED", []);
    },
    getOracleMetadataEnabledTempaltes: function() {
        return this.call("GETORACLEMETADATAENABLEDTEMPLATES", []);
    },
    validateConnection: function(
        databaseType,
        userName,
        password,
        URL,
        agentName,
        passwordEncrypted,
        objectID,
        sslConfigMap
    ) {
        const params = this.parseConnectivityParams({ userName, password, URL, passwordEncrypted, sslConfigMap });
        return this.call("com.webaction.web.api.ApplicationHelper.validateConnection", [
            this.token,
            databaseType,
            params,
            agentName,
            objectID
        ]);
    },

    checkDrivers: function(databaseType) {
        //TODO: method not implemented
        //return this.call("CHECKDRIVERS", [databaseType]);

        // method to check all drivers was not available,
        // for now using workaround with other methods
        if (databaseType === "mysql") {
            return new Promise(resolve => {
                api.MYSQLCheckDriver("")
                    .then(result => {
                        resolve(result === "true");
                    })
                    .fail(e => {
                        console.log(e);
                        resolve(false);
                    });
            });
        }

        if (databaseType === "oracle" || databaseType === "ojet") {
            return new Promise(resolve => {
                api.OracleCheckDriver("")
                    .then(result => {
                        resolve(result === "true");
                    })
                    .fail(e => {
                        console.log(e);
                        resolve(false);
                    });
            });
        }

        return new Promise(resolve => {
            resolve(true);
        });
    },
    getDatabaseTables: function(databaseType, params, fullTableName, agentName, passwordEncrypted, objectID) {
        return this.call("GETDATABASETABLES", [
            this.token,
            databaseType,
            { ...params, encrypted: passwordEncrypted },
            fullTableName,
            agentName,
            objectID
        ]);
    },
    getDBs: function(databaseType, userName, password, url, agentName, passwordEncrypted, objectID) {
        return this.call("GETDBS", [
            this.token,
            databaseType,
            userName,
            password,
            url,
            agentName,
            passwordEncrypted,
            objectID
        ]);
    },
    getDatabaseTableColumns: function(
        databaseType,
        userName,
        password,
        URL,
        tableNames,
        agentName,
        passwordEncrypted,
        objectID
    ) {
        const params = this.parseConnectivityParams({ userName, password, URL, passwordEncrypted });

        return this.call("GETDATABASETABLECOLUMNS", [
            this.token,
            databaseType,
            params,
            tableNames,
            agentName,
            objectID
        ]);
    },
    // API method that returns all Monitor Events field names
    getMonitorEventTypeValues: function() {
        return this.call("GETMONITOREVENTTYPEVALUES", []);
    },

    //OjetCDCWizard
    OjetcheckCDCConfigurations: function(params, agentName) {
        return this.call("com.webaction.source.ojetwizard.OjetCDCWizard#checkCDCConfigurations", [params, agentName]);
    },
    OjetGetSchemas: function(params, agentName) {
        return this.call("com.webaction.source.ojetwizard.OjetCDCWizard#getDBs", [params, agentName]);
    },
    OjetgetStartPosition: function(params, agentName) {
        const { userName, password, URL, passwordEncrypted, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("OJETGETSTARTPOSITION", [formattedParams, agentName]);
    },
    OracleGetSchemas: function(params, agentName) {
        return this.call("ORACLEGETDBS", [params, agentName]);
    },
    OraclecheckVersion: function(userName, password, URL, version, agentName) {
        return this.call("ORACLECHECKVERSION", [userName, password, URL, version, agentName]);
    },
    OraclecheckCDCConfigurations: function(params, agentName) {
        return this.call("ORACLECHECKCDCCONFIG", [params, agentName]);
    },
    OraclegetTables: function(userName, password, URL, fulltableName, agentName) {
        return this.call("ORACLEGETTABLES", [userName, password, URL, fulltableName, agentName]);
    },
    OraclegetTableColumns: function(userName, password, URL, fulltableName, agentName) {
        return this.call("ORACLEGETTABLECOLUMNS", [userName, password, URL, fulltableName, agentName]);
    },
    OracleisCDB: function(params) {
        const { userName, password, URL, passwordEncrypted, agentName, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("ORACLEISCDB", [formattedParams, agentName]);
    },
    OraclegetPDBs: function(params) {
        const { userName, password, URL, passwordEncrypted, agentName, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("ORACLEGETPDBS", [formattedParams, agentName]);
    },
    OracleCheckDriver: function(agentName) {
        return this.call("ORACLECHECKDRIVER", [agentName]);
    },
    OracleLoadDriver: function(pathToJar, agentName) {
        return this.call("ORACLELOADDRIVER", [pathToJar, agentName]);
    },
    OraclegetStartPosition: function(params, agentName) {
        const { userName, password, URL, passwordEncrypted, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("ORACLEGETSTARTPOSITION", [formattedParams, agentName]);
    },
    MSSQLcheckVersion: function(userName, password, URL, version, agentName) {
        return this.call("MSSQLCHECKVERSION", [userName, password, URL, version, agentName]);
    },
    MSSQLgetTables: function(userName, password, URL, fulltableName, agentName) {
        return this.call("MSSQLGETTABLES", [userName, password, URL, fulltableName, agentName]);
    },
    MSSQLgetTableColumns: function(userName, password, URL, fulltableName, agentName) {
        return this.call("MSSQLGETTABLECOLUMNS", [userName, password, URL, fulltableName, agentName]);
    },
    MSSQLcheckCDCEnabled: function(params, agentName) {
        // const params = this.parseConnectivityParams({ userName, password, URL });
        return this.call("MSSQLCHECKCDCENABLED", [params, agentName]);
    },
    MSSQLgetSchemas: function(params, agentName) {
        return this.call("com.webaction.source.mssqlwizard.MSSqlWizard#getSchemas", [params, agentName]);
    },
    MSSQLallTableColumnCheck: function(userName, password, URL, tableName, agentName) {
        return this.call("MSSQLISARCHIVELOGMODEENABLED", [userName, password, URL, agentName]);
    },
    MySQLgetStartPosition: function(params, agentName) {
        const { userName, password, URL, passwordEncrypted, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("MYSQLGETSTARTPOSITION", [formattedParams, agentName]);
    },
    MSSQLgetStartPosition: function(params, agentName) {
        const { userName, password, URL, passwordEncrypted, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("MSSQLGETSTARTPOSITION", [formattedParams, agentName]);
    },
    MYSQLcheckPrivs: function(userName, password, URL, agentName) {
        const params = this.parseConnectivityParams({ userName, password, URL });
        return this.call("MYSQLCHECKPRIVS", [params, agentName]);
    },
    MYSQLcheckVersion: function(userName, password, URL, version, agentName) {
        return this.call("MYSQLCHECKVERSION", [userName, password, URL, version, agentName]);
    },
    MYSQLcheckCDCConfig: function(params, agentName) {
        return this.call("MYSQLCHECKCDCCONFIG", [params, agentName]);
    },
    MYSQLgetTables: function(userName, password, URL, fulltableName, agentName) {
        return this.call("MYSQLGETTABLES", [userName, password, URL, fulltableName, agentName]);
    },
    MYSQLgetTableColumns: function(userName, password, URL, fulltableName, agentName) {
        return this.call("MYSQLGETTABLECOLUMNS", [userName, password, URL, fulltableName, agentName]);
    },
    MYSQLGetDBs: function(params, agentName) {
        return this.call("MYSQLGETDBS", [params, agentName]);
    },
    MYSQLCheckDriver: function(agentName) {
        return this.call("MYSQLCHECKDRIVER", [agentName]);
    },
    MYSQLLoadDriver: function(pathToJar, agentName) {
        return this.call("MYSQLLOADDRIVER", [pathToJar, agentName]);
    },
    MYSQLisArchiveLogModeEnabled: function(userName, password, URL, agentName) {
        return this.call("MSSQLALLTABLECOLUMNCHECK", [userName, password, URL, agentName]);
    },
    MSJETcheckCDCConfigurations: function(params, agentName) {
        return this.call("com.webaction.source.mssqlwizard.MSJetWizard#checkCDCConfigurations", [params, agentName]);
    },

    PostgresGetSchemas: function(params, agentName) {
        return this.call("com.striim.postgresreader.common.PostgresWizard#getSchemas", [params, agentName]);
    },
    PostgresgetStartPosition: function(params, agentName) {
        const { userName, password, URL, passwordEncrypted, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("POSTGRESGETSTARTPOSITION", [formattedParams, agentName]);
    },

    PostgresCheckTargetPrivileges: function(params) {
        return this.call("POSTGRESCHECKTARGETPRIVILEGES", [params]);
    },

    postgresCheckCDCConfigurations: function(params, agentName) {
        return this.call("com.striim.postgresreader.common.PostgresWizard#checkCDCConfigurations", [params, agentName]);
    },
    MariaDBXpandValidateConnection: function(params) {
        const { userName, password, URL, passwordEncrypted, agentName, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("MARIADBXPANDVALIDATECONNECTION", [formattedParams, agentName]);
    },
    MariaDBXpandgetStartPosition: function(params, agentName) {
        const { userName, password, URL, passwordEncrypted, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("MARIADBXPANDGETSTARTPOSITION", [formattedParams, agentName]);
    },
    MariaDBXpandValidateConnection: function(userName, password, URL, agentName) {
        const params = this.parseConnectivityParams({ userName, password, URL });
        return this.call("MARIADBXPANDVALIDATECONNECTION", [params, agentName]);
    },
    MariaDBXpandCheckTargetPrivileges: function(params) {
        return this.call("MARIADBXPANDCHECKTARGETPRIVILEGES", [params]);
    },
    MariaDBXpandGetDBs: function(params, agentName) {
        return this.call("MARIADBXPANDGETDBS", [params, agentName]);
    },
    MariaDBXpandCheckCDCConfigurations: function(params, agentName) {
        return this.call("MARIADBXPANDCHECKCDCCONFIG", [params, agentName]);
    },
    MariaDBXpandCheckPrivs: function(userName, password, URL, agentName) {
        const params = this.parseConnectivityParams({ userName, password, URL });
        return this.call("MARIADBXPANDCHECKPRIVS", [params, agentName]);
    },
    MariaDBXpandCheckDriver: function(agentName) {
        return this.call("MARIADBXPANDCHECKDRIVER", [agentName]);
    },
    MariaDBXpandLoadDriver: function(pathToJar, agentName) {
        return this.call("MARIADBXPANDLOADDRIVER", [pathToJar, agentName]);
    },
    MariaDBgetStartPosition: function(params, agentName) {
        const { userName, password, URL, passwordEncrypted, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("MARIADBGETSTARTPOSITION", [formattedParams, agentName]);
    },
    MariaDBCheckTargetPrivileges: function(params) {
        return this.call("MARIADBCHECKTARGETPRIVILEGES", [params]);
    },
    MariaDBGetDBs: function(params, agentName) {
        return this.call("MARIADBGETDBS", [params, agentName]);
    },
    MariaDBCheckCDCConfigurations: function(params, agentName) {
        return this.call("MARIADBCHECKCDCCONFIG", [params, agentName]);
    },
    MariaDBCheckPrivs: function(userName, password, URL, agentName) {
        const params = this.parseConnectivityParams({ userName, password, URL });
        return this.call("MARIADBCHECKPRIVS", [params, agentName]);
    },
    MariaDBCheckDriver: function(agentName) {
        return this.call("MARIADBCHECKDRIVER", [agentName]);
    },
    MariaDBLoadDriver: function(pathToJar, agentName) {
        return this.call("MARIADBLOADDRIVER", [pathToJar, agentName]);
    },
    checkHazelcastORMConfigurations: function(path) {
        return this.call("CHECKHAZELCASTORMCONFIGURATIONS", [path]);
    },
    getHazelcastMapNames: function(name, password, url) {
        return this.call("HAZELCASTGETCONFIGS", [name, password, url]);
    },
    databaseWriterValidateConnection: function(params) {
        return this.call("DATABASEWRITERVALIDATECONNETION", [params]);
    },
    azureBlobWriterValidateConnection: function(params) {
        return this.call("AZUREBLOBWRITERVALIDATECONNECTION", [params]);
    },
    BigQueryList: function(params) {
        return this.call("BIGQUERYLIST", [params]);
    },
    HDFSWriterWizardValidateConnection: function(params) {
        return this.call("HDFSWRITERVALIDATECONNECTION", [params]);
    },
    KafkaWriter8WizardValidateConnection: function(params) {
        return this.call("KAFKAWRITER8VALIDATECONNETION", [params]);
    },
    KafkaWriter9WizardValidateConnection: function(params) {
        return this.call("KAFKAWRITER9VALIDATECONNETION", [params]);
    },
    createDashboardEmbedUser: function(dashboardFQN) {
        return this.call("CREATEDASHBOARDUSER", [this.token, dashboardFQN]);
    },
    getPermissionsForDashboard: function(dashboardFQN) {
        return this.call("GETPERMISSIONSFORDASHBOARD", [this.token, dashboardFQN]);
    },
    authenticateDashboardEmbedUser: function(token) {
        return this.call("AUTHDASHBOARDUSER", [token]);
    },
    KafkaWriter10WizardValidateConnection: function(params) {
        return this.call("KAFKAWRITER10VALIDATECONNECTION", [params]);
    },
    KafkaWriter11WizardValidateConnection: function(params) {
        return this.call("KAFKAWRITER11VALIDATECONNECTION", [params]);
    },
    showStream: function(streamName, lines) {
        return this.call("SHOWSTREAM", [this.token, streamName, lines]);
    },
    loadOpenProcessor: function(pcName) {
        return this.call("LOADOPENPROCESSOR", [this.token, pcName]);
    },
    unloadOpenProcessor: function(pcName) {
        return this.call("UNLOADOPENPROCESSOR", [this.token, pcName]);
    },
    getAllApplicationsTQL: function(applications, passphrase) {
        const deepTraverse = true;
        return this.call("GETALLAPPLICATIONSTQL", [applications, deepTraverse, passphrase, this.token]);
    },
    getTableCountForApp: function(applicationFQN, EntityTypeList) {
        return this.call("GETTABLECOUNTFORAPP", [this.token, applicationFQN, EntityTypeList]);
    },
    getMonitoringStats: function(componentName) {
        return this.call("GETMONITORINGSTATS", [componentName, this.token]);
    },
    getMonitoringStatsWithoutTableInfo: function(componentName) {
        return this.call("GETMONITORINGSTATSWITHOUTTABLEINFO", [componentName, this.token]);
    },
    getMonitoringStatsForComponents: function(componentNames, type, start, end) {
        return this.call("GETMONITORINGSTATFORCOMPONENTS", [componentNames, type, start, end, this.token]);
    },
    getMonitoringStatsForComponentsWithoutTableInfo: function(componentNames, type, start, end) {
        return this.call("GETMONITORINGSTATFORCOMPONENTSWITHOUTTABLEINFO", [
            componentNames,
            type,
            start,
            end,
            this.token
        ]);
    },
    getMonitoringStatsForApp: function(appName, type, types = null) {
        if (!type) type = TYPES.MOST_RECENT_DATA;
        return this.call("GETMONITORINGSTATSFORAPP", [appName, type, this.token, types]);
    },
    getMonitoringStatsForAppWithoutTableInfo: function(appName, type, types = null) {
        if (!type) type = TYPES.MOST_RECENT_DATA;
        return this.call("GETMONITORINGSTATSFORAPPWITHOUTTABLEINFO", [appName, type, this.token, types]);
    },
    getTableInfo: function(componentName) {
        return this.call("GETTABLEINFO", [componentName, this.token]);
    },
    getAdaptersWithTableMonitoring: function(sourceIdList) {
        return this.call("com.webaction.web.api.MonitoringAPI.getAdaptersWithTableMonitoring", [
            this.token,
            sourceIdList
        ]);
    },
    isAllowedToModifyAlertApp: function() {
        return this.call("ISALLOWEDTOMODIFYALERTAPP", [this.token]);
    },
    getEmailConfigStatus: function() {
        return this.call("com.webaction.web.api.AlertAppHelper#getEmailConfigStatus", [this.token]);
    },
    getSlackConfigStatus: function() {
        return this.call("com.webaction.web.api.AlertAppHelper#getSlackConfigStatus", [this.token]);
    },
    getTeamsConfigStatus: function() {
        return this.call("com.webaction.web.api.AlertAppHelper#getTeamsConfigStatus", [this.token]);
    },
    doAlertAppCleanup: function() {
        return this.call("doAlertAppCleanup", [this.token]);
    },
    createAndRunAlertApp: function() {
        return this.call("createAndRunAlertApp", [this.token]);
    },
    hasAlertAppBeenCreated: function() {
        return this.call("HASALERTAPPBEENCREATED", [this.token]);
    },
    updateConfigAndReRunApp: function(properties) {
        return this.call("com.webaction.web.api.AlertAppHelper#updateConfigAndReRunApp", [this.token, properties]);
    },
    updateConfigAndReRunSlackApp: function(properties) {
        return this.call("com.webaction.web.api.AlertAppHelper#updateConfigAndReRunSlackApp", [this.token, properties]);
    },
    updateConfigAndReRunTeamsApp: function(properties) {
        return this.call("com.webaction.web.api.AlertAppHelper#updateConfigAndReRunTeamsApp", [this.token, properties]);
    },
    getSlackConfig: function() {
        return this.call("com.webaction.web.api.AlertAppHelper#getSlackConfig", [this.token]);
    },
    getSMTPConfig: function() {
        return this.call("com.webaction.web.api.AlertAppHelper#getSMTPConfig", [this.token]);
    },
    getTeamsConfig: function() {
        return this.call("com.webaction.web.api.AlertAppHelper#getTeamsConfig", [this.token]);
    },
    stopAndDropAlertApp: function() {
        return this.call("STOPANDDROPALERTAPP", [this.token]);
    },
    createDefaultAlerts: function() {
        return this.call("CREATEDEFAULTALERTS", [this.token]);
    },
    sendTestEmail: function(smtpUrl, smtpPort, starttls_enable, smtp_auth, smtpUser, smtpPassword, fromAddress, to) {
        return this.call("SENDTESTEMAIL", [
            smtpUrl,
            smtpPort,
            starttls_enable,
            smtp_auth,
            smtpUser,
            smtpPassword,
            fromAddress,
            to
        ]);
    },
    sendTestSlack: function(slackToken, channelName) {
        return this.call("com.webaction.web.api.AlertAppHelper#sendTestSlack", [slackToken, channelName]);
    },
    sendTestToTeams: function(clientId, clientSecret, refreshToken, channelURL) {
        return this.call("com.webaction.web.api.AlertAppHelper#sendTestToTeams", [
            clientId,
            clientSecret,
            refreshToken,
            channelURL
        ]);
    },
    getDatabaseMetadata: function(adapterName) {
        return this.call("TOJSON", [adapterName]);
    },
    isPostgresInstalled: function() {
        return this.call("ISPOSTGRESINSTALLED", []);
    },
    isKafkaInstalled: function() {
        return this.call("ISKAFKAINSTALLED", []);
    },
    getAdapterProperties: function(adapterName) {
        return this.call("GETADAPTERPROPERTIES", [this.token, adapterName]);
    },
    getToken: function() {
        return this.call("GETTOKEN", []);
    },
    exceptionStoreExists: function(appId) {
        return this.call("EXCEPTIONSTOREEXISTS", [this.token, appId]);
    },
    createOrUpdateExceptionstore: function(appId, interval) {
        return this.call("CREATEORUPDATEEXCEPTIONSTORE", [this.token, appId, interval || ""]);
    },
    dropExceptionStore: function(appId) {
        return this.call("DROPEXCEPTIONSTORE", [this.token, appId]);
    },
    getExceptionStoreTTL: function(appId) {
        return this.call("GETEXCEPTIONSTORETTL", [this.token, appId]);
    },
    getVersionIDs: function(appId) {
        return this.call("GETVERSIONIDS", [this.token, appId]);
    },
    getAvailableFeatures: function() {
        return this.call("com.webaction.web.api.LicenseHelper#getAvailableFeatures", []);
    },
    getLicensedFeatures: function() {
        return this.call("com.webaction.web.api.LicenseHelper#getLicensedFeatures", []);
    },
    enableFeature: function(key) {
        return this.call("com.webaction.web.api.LicenseHelper#enableFeature", [key]);
    },
    disableFeature: function(key) {
        return this.call("com.webaction.web.api.LicenseHelper#disableFeature", [key]);
    },
    meteringIsEnabled: function() {
        return this.call("com.webaction.metering.api.MeteringWebApi#isEnabled", [this.token]);
    },
    meteringGetBillingCycle: function(billingId) {
        return this.call("com.webaction.metering.api.MeteringWebApi#getBillingCycle", [billingId, this.token]);
    },
    meteringGetAllBillingCycle: function() {
        return this.call("com.webaction.metering.api.MeteringWebApi#getAllBillingCycle", [this.token]);
    },
    meteringGetUsageSummaries: function() {
        return this.call("com.webaction.metering.api.MeteringWebApi#getUsageSummaries", [this.token]);
    },
    getFeatureRates: function() {
        return this.call("com.webaction.metering.api.MeteringWebApi#getFeatureRates", [this.token]);
    },
    meteringGetCurrentUsage: function() {
        return this.call("com.webaction.metering.api.MeteringWebApi#getCurrentUsage", [this.token]);
    },
    meteringGetConsumptionDrilled: function(billingId, itemId) {
        return this.call("com.webaction.metering.api.MeteringWebApi#getConsumptionDrilled", [
            billingId,
            itemId,
            this.token
        ]);
    },
    meteringGetRemainingCredits: function() {
        return this.call("com.webaction.metering.api.MeteringWebApi#getRemainingCredits", [this.token]);
    },
    meteringGetAppliedCredits: function(billingId) {
        return this.call("com.webaction.metering.api.MeteringWebApi#getAppliedCredits", [billingId, this.token]);
    },
    meteringGetAwardedCredits: function() {
        return this.call("com.webaction.metering.api.MeteringWebApi#getAwardedCredits", [this.token]);
    },
    meteringGetItemizedUsage: function(billingId) {
        return this.call("com.webaction.metering.api.MeteringWebApi#getItemizedUsage", [billingId, this.token]);
    },
    meteringGetConsumptions: function(billingId, itemId) {
        return this.call("com.webaction.metering.api.MeteringWebApi#getConsumptions", [billingId, itemId, this.token]);
    },
    meteringGetAggregatedConsumptions: function(billingId, itemId) {
        return this.call("com.webaction.metering.api.MeteringWebApi#getAggregatedConsumption", [
            billingId,
            itemId,
            this.token
        ]);
    },
    meteringGetAdapterRates: function() {
        return this.call("com.webaction.metering.api.MeteringWebApi#getAdapterRates", [this.token]);
    },
    meteringGetItemRate: function(itemId) {
        return this.call("com.webaction.metering.api.MeteringWebApi#getItemRate", [itemId, this.token]);
    },
    meteringGetInitialLoadConsumption: function(billingId, componentId) {
        return this.call("com.webaction.metering.api.MeteringWebApi#getInitialLoadConsumption", [
            billingId,
            componentId,
            this.token
        ]);
    },
    execute: function(stmt) {
        return this.call("EXECUTETQL", [stmt]);
    },
    getConsoleNamespace: function() {
        return this.call("GETCONSOLENAMESPACE", []);
    },
    validateConvertSchema: function(sourceType, params, targetType, wildcard) {
        return this.call("com.striim.schema.api.SchemaConversionHelper.validateSchema", [
            sourceType,
            params,
            targetType,
            wildcard,
            ""
        ]);
    },
    validateSchemaName: function(source, target, schemas) {
        return this.call("validateSchemaName", [source, target, schemas]);
    },
    isSchemaValidationSupported: function(source, target, createSchema, migrationType) {
        return this.call("ISSCHEMAVALIDATIONSUPPORTED", [source, target, createSchema, migrationType]);
    },
    createSchemaMigrationApp: function(appFQN) {
        return this.call("CREATEMIGRATIONAPP", [this.token, appFQN]);
    },
    getLeeStatsReport: function(sourceName, targetName, start, end, rollupInterval) {
        return this.call("com.webaction.web.api.MonitoringAPI#getLeeStatsReport", [
            this.token,
            sourceName,
            targetName,
            start,
            end,
            rollupInterval
        ]);
    },
    getHistoricalMessageData: function(query) {
        return this.call("com.webaction.web.api.NotificationStoreQueryAPI#getQueriedNotificationData", [
            this.token,
            query
        ]);
    },
    mongodbCheckPrivs: function(userName, password, URL, agentName) {
        const params = this.parseConnectivityParams({ userName, password, URL });
        return this.call("com.striim.mongodbreader.utils.MongoWizard#checkPrivileges", [params, agentName]);
    },
    mongoDBCheckCDC: function(userName, password, URL, agentName) {
        const params = this.parseConnectivityParams({ userName, password, URL });
        return this.call("com.striim.mongodbreader.utils.MongoWizard#checkCDCConfigurations", [params, agentName]);
    },
    mongodbGetDBs: function(userName, password, URL, agentName) {
        const params = this.parseConnectivityParams({ userName, password, URL });
        return this.call("com.striim.mongodbreader.utils.MongoWizard#getDBs", [params, agentName]);
    },
    cosmosdbGetDBs: function(userName, password, URL, agentName) {
        const params = this.parseConnectivityParams({ userName, password, URL });
        return this.call("com.striim.ui.wizard.CosmosDBWriterWizard#getDBs", [params, agentName]);
    },
    mongoCosmosdbGetDBs: function(userName, password, URL, agentName) {
        const params = this.parseConnectivityParams({ userName, password, URL });
        return this.call("com.striim.mongodbreader.utils.MongoCosmosWizard#getDBs", [params, agentName]);
    },

    cosmosdbGetTables: function(userName, password, URL, fullTableName, agentName) {
        const params = this.parseConnectivityParams({ userName, password, URL });
        return this.call("com.striim.ui.wizard.CosmosDBWriterWizard#getTables", [params, fullTableName, agentName]);
    },
    salesforceValidateConnection: function(
        userName,
        password,
        securityToken,
        consumerKey,
        consumerSecret,
        authToken,
        apiEndPoint,
        salesforceConnectionProfile,
        agentName
    ) {
        return this.call("com.striim.ui.wizard.SalesforceReaderWizard#validateConnection", [
            userName,
            password,
            securityToken,
            consumerKey,
            consumerSecret,
            authToken,
            apiEndPoint,
            salesforceConnectionProfile,
            agentName
        ]);
    },
    salesforceGetObjects: function(authToken, apiEndPoint, customObjects, snowflakeConnectionProfile, agentName) {
        return this.call("com.striim.ui.wizard.SalesforceReaderWizard#getObjects", [
            authToken,
            apiEndPoint,
            customObjects,
            snowflakeConnectionProfile,
            agentName
        ]);
    },
    salesforceCDCValidateConnection: function(salesforceCDCConnectionDetails, agentName) {
        return this.call("com.striim.proc.wizard.SalesforceCdcWizard#validateConnection", [
            salesforceCDCConnectionDetails,
            agentName
        ]);
    },

    salesforceCDCGetChannels: function(salesforcePardotConnectionDetails, agentName) {
        return this.call("com.striim.proc.wizard.SalesforceCdcWizard#getChannels", [
            salesforcePardotConnectionDetails,
            agentName
        ]);
    },

    salesforceCDCGetObjects: function(salesforcePardotConnectionDetails, channel, agentName) {
        return this.call("com.striim.proc.wizard.SalesforceCdcWizard#getObjects", [
            salesforcePardotConnectionDetails,
            channel,
            agentName
        ]);
    },
    salesforceCDCgetStartPosition: function(params, agentName) {
        return this.call("com.striim.proc.wizard.SalesforceCdcWizard#getStartPosition", [params, agentName]);
    },
    salesforcePardotValidateConnection: function(salesforcePardotConnectionDetails, agentName) {
        return this.call("com.striim.proc.wizard.SalesforcePardotWizard#validateConnection", [
            salesforcePardotConnectionDetails,
            agentName
        ]);
    },

    salesforcePardotGetObjects: function(salesforcePardotConnectionDetails, agentName) {
        return this.call("com.striim.proc.wizard.SalesforcePardotWizard#getObjects", [
            salesforcePardotConnectionDetails,
            agentName
        ]);
    },

    serviceNowValidateConnection: function(serviceNowConnectionDetails, agentName) {
        return this.call("com.striim.proc.wizard.ServiceNowWizard#validateConnection", [
            serviceNowConnectionDetails,
            agentName
        ]);
    },
    serviceNowGetObjects: function(serviceNowConnectionDetails, agentName) {
        return this.call("com.striim.proc.wizard.ServiceNowWizard#getObjects", [
            serviceNowConnectionDetails,
            agentName
        ]);
    },
    snowflakeReaderValidateConnection: function(snowflakeReaderConnectionDetails, agentName) {
        return this.call("SNOWFLAKEREADERVALDIATECONNECTION", [snowflakeReaderConnectionDetails, agentName]);
    },
    snowflakeReaderGetDBs: function(snowflakeReaderConnectionDetails, agentName) {
        return this.call("SNOWFLAKEREADERGETDBS", [snowflakeReaderConnectionDetails, agentName]);
    },
    snowflakeReaderGetSchemas: function(snowflakeReaderConnectionDetails, dbNames, agentName) {
        return this.call("SNOWFLAKEREADERGETSCHEMAS", [snowflakeReaderConnectionDetails, dbNames, agentName]);
    },
    snowflakeReaderGetTables: function(snowflakeReaderConnectionDetails, schemaName, agentName) {
        return this.call("SNOWFLAKEREADERGETTABLES", [snowflakeReaderConnectionDetails, schemaName, agentName]);
    },
    snowflakeReaderCheckCDCConfigurations: function(snowflakeReaderConnectionDetails, agentName) {
        return this.call("SNOWFLAKEREADERCHECKCDCCONFIG", [snowflakeReaderConnectionDetails, agentName]);
    },
    snowflakeReaderCheckPrivs: function(snowflakeReaderConnectionDetails, agentName) {
        return this.call("SNOWFLAKEREADERCHECKPRIVS", [snowflakeReaderConnectionDetails, agentName]);
    },
    yugabyteValidateConnection: function(yugabyteConnectionDetails, agentName) {
        return this.call("YUGABYTEVALIDATECONNECTION", [yugabyteConnectionDetails, agentName]);
    },
    yugabyteGetSchemas: function(yugabyteConnectionDetails, agentName) {
        return this.call("YUGABYTEGETSCHEMAS", [yugabyteConnectionDetails, agentName]);
    },
    yugabytecheckCDCConfigurations: function(yugabyteConnectionDetails, agentName) {
        return this.call("YUGABYTECHECKCDCCONFIGURATIONS", [yugabyteConnectionDetails, agentName]);
    },
    yugabyteGetTables: function(yugabyteConnectionDetails, agentName) {
        return this.call("YUGABYTEGETTABLES", [yugabyteConnectionDetails, agentName]);
    },
    hubSpotValidateConnection: function(hubspotConnectionDetails, agentName) {
        return this.call("com.striim.app.proc.wizard.HubspotReaderWizard#validateConnection", [
            hubspotConnectionDetails,
            agentName
        ]);
    },
    hubSpotGetObjects: function(hubspotConnectionDetails, agentName) {
        return this.call("com.striim.app.proc.wizard.HubspotReaderWizard#getObjects", [
            hubspotConnectionDetails,
            agentName
        ]);
    },

    googleAdsValidateConnection: function(googleAdsConnectionDetails, agentName) {
        return this.call("com.striim.wizard.GoogleAdsReaderWizard#validateConnection", [
            googleAdsConnectionDetails,
            agentName
        ]);
    },
    googleAdsGetObjects: function(googleAdsConnectionDetails, agentName) {
        return this.call("com.striim.wizard.GoogleAdsReaderWizard#getObjects", [googleAdsConnectionDetails, agentName]);
    },

    //DO-NOT-REMOVE-BELOW-COMMENT
    //add-new-method
    paymoValidateConnection: function(paymoConnectionDetails, agentName) {
        return this.call("com.striim.app.paymo.proc.wizard.PaymoReaderWizard#validateConnection", [
            paymoConnectionDetails,
            agentName
        ]);
    },

    paymoGetObjects: function(paymoConnectionDetails, agentName) {
        return this.call("com.striim.app.paymo.proc.wizard.PaymoReaderWizard#getObjects", [
            paymoConnectionDetails,
            agentName
        ]);
    },
    bambooHrValidateConnection: function(bambooHrConnectionDetails, agentName) {
        return this.call("com.striim.app.bamboohr.proc.wizard.BambooHrReaderWizard#validateConnection", [
            bambooHrConnectionDetails,
            agentName
        ]);
    },
    bambooHrGetObjects: function(bambooHrConnectionDetails, agentName) {
        return this.call("com.striim.app.bamboohr.proc.wizard.BambooHrReaderWizard#getObjects", [
            bambooHrConnectionDetails,
            agentName
        ]);
    },
    linkedInAdsValidateConnection: function(linkedInAdsConnectionDetails, agentName) {
        return this.call("com.striim.app.linkedinads.wizard.LinkedInAdsReaderWizard#validateConnection", [
            linkedInAdsConnectionDetails,
            agentName
        ]);
    },

    linkedInAdsGetObjects: function(linkedInAdsConnectionDetails, agentName) {
        return this.call("com.striim.app.linkedinads.wizard.LinkedInAdsReaderWizard#getObjects", [
            linkedInAdsConnectionDetails,
            agentName
        ]);
    },
    googleAnalyticsValidateConnection: function(googleAnalyticsConnectionDetails, agentName) {
        return this.call("com.striim.app.googleanalytics.wizard.GoogleAnalyticsReaderWizard#validateConnection", [
            googleAnalyticsConnectionDetails,
            agentName
        ]);
    },

    googleAnalyticsGetObjects: function(googleAnalyticsConnectionDetails, agentName) {
        return this.call("com.striim.app.googleanalytics.wizard.GoogleAnalyticsReaderWizard#getObjects", [
            googleAnalyticsConnectionDetails,
            agentName
        ]);
    },
    jiraValidateConnection: function(jiraConnectionDetails, agentName) {
        return this.call("com.striim.app.proc.wizard.JiraReaderWizard#validateConnection", [
            jiraConnectionDetails,
            agentName
        ]);
    },
    jiraGetObjects: function(jiraConnectionDetails, agentName) {
        return this.call("com.striim.app.proc.wizard.JiraReaderWizard#getObjects", [jiraConnectionDetails, agentName]);
    },
    stripeValidateConnection: function(stripeConnectionDetails, agentName) {
        return this.call("com.striim.app.proc.wizard.StripeReaderWizard#validateConnection", [
            stripeConnectionDetails,
            agentName
        ]);
    },

    stripeGetObjects: function(stripeConnectionDetails, agentName) {
        return this.call("com.striim.app.proc.wizard.StripeReaderWizard#getObjects", [
            stripeConnectionDetails,
            agentName
        ]);
    },
    zendeskValidateConnection: function(zendeskConnectionDetails, agentName) {
        return this.call("com.striim.proc.wizard.ZendeskWizard#validateConnection", [
            zendeskConnectionDetails,
            agentName
        ]);
    },
    zendeskGetObjects: function(zendeskConnectionDetails, agentName) {
        return this.call("com.striim.proc.wizard.ZendeskWizard#getObjects", [zendeskConnectionDetails, agentName]);
    },
    bigQueryValidateConnection: function(bigQueryConnectionDetails, agentName) {
        return this.call("BIGQUERYVALIDATECONNECTION", [bigQueryConnectionDetails, agentName]);
    },
    bigQuerygetDatasets: function(bigQueryConnectionDetails, agentName) {
        return this.call("BIGQUERYGETDATASETS", [bigQueryConnectionDetails, agentName]);
    },
    bigQuerygetDatasetTables: function(bigQueryConnectionDetails, schemaName, agentName) {
        return this.call("BIGQUERYGETDATASETTABLES", [bigQueryConnectionDetails, schemaName, agentName]);
    },
    intercomValidateConnection: function(intercomConnectionDetails, agentName) {
        return this.call("com.striim.app.proc.wizard.IntercomReaderWizard#validateConnection", [
            intercomConnectionDetails,
            agentName
        ]);
    },
    intercomGetObjects: function(intercomConnectionDetails, agentName) {
        return this.call("com.striim.app.proc.wizard.IntercomReaderWizard#getObjects", [
            intercomConnectionDetails,
            agentName
        ]);
    },
    addVaultValue: function(vaultId, type, key, value) {
        return this.call("com.webaction.web.api.VaultAPI.addValue", [this.token, vaultId, type, key, value]);
    },
    getVaultAllValues: function(vaultId) {
        return this.call("com.webaction.web.api.VaultAPI.getAllValues", [this.token, vaultId]);
    },
    getVaultValue: function(vaultId, key) {
        return this.call("com.webaction.web.api.VaultAPI.getValue", [this.token, vaultId, key]);
    },
    alterVaultValue: function(vaultValueType, vaultId, currKey, newValue) {
        return this.call("com.webaction.web.api.VaultAPI.alterVaultValue", [
            this.token,
            vaultValueType,
            vaultId,
            currKey,
            newValue
        ]);
    },
    dropVaultValue: function(vaultId, key) {
        return this.call("com.webaction.web.api.VaultAPI.dropValue", [this.token, vaultId, key]);
    },
    getAppListTableStats: function(appFqns, entityTypes) {
        return this.call("com.webaction.web.api.MonitoringAPI.getAppListTableStats", [
            this.token,
            appFqns,
            entityTypes
        ]);
    },
    getDocumentationRoot: function() {
        return this.call("com.webaction.web.api.LicenseHelper#getDocumentationRoot", []);
    },
    getInlineDocRoot: function() {
        return this.call("com.webaction.web.api.LicenseHelper#getInlineDocRoot", []);
    },
    getAuthrHostURL: function() {
        return this.call("GETAUTHRHOSTURL", []);
    },
    alterVault: function(vaultId, newVaultProps) {
        return this.call("com.webaction.web.api.VaultAPI.alterValue", [this.token, vaultId, newVaultProps]);
    },
    disableResourceLimit: async function() {
        const statement = `ALTER CLUSTER DISABLE RESOURCE_LIMIT_POLICY;`;
        try {
            await axios.post("/api/v2/tungsten", statement, {
                headers: { "Content-Type": "text/plain", Authorization: "STRIIM-TOKEN " + this.token }
            });
        } catch (e) {
            console.log(e.response?.data?.message);
        }
    },
    getCpsByEndpoints: async function(endpoints) {
        return this.call("GETCPSBYENDPOINTS", [endpoints, this.token]);
    },
    getCpEndpoints: async function() {
        return this.call("GETCPENDPOINTS");
    },
    getCpApps: async function(id) {
        return this.call("GETCPAPPS", [id, this.token]);
    },
    testConnectionProfile: async function(
        existing,
        data,
        endpoint,
        connectionProfileName,
        hasEncryptedProperties = false
    ) {
        if (existing) {
            if (hasEncryptedProperties) {
                return this.call("TESTCONNECTIONPROFILE", [data, endpoint, connectionProfileName, this.token]);
            } else {
                return this.call("TESTCONNECTIONPROFILE", [data, this.token]);
            }
        } else {
            return this.call("TESTCONNECTIONPROFILE", [data, endpoint, this.token]);
        }
    },
    checkCpsAppsAreDeployed: async function(name) {
        return this.call("CHECKCPSAPPSAREDEPLOYED", [name, this.token]);
    },
    handleErrorMessagesForCP: async function(endpoint, uiPayload) {
        return this.call("HANDLEERRORMESSAGESFORCP", [endpoint, uiPayload]);
    },
    checkPermission: async function(uuid, action) {
        return this.call("CHECKPERMISSION", [uuid, action, this.token]);
    },
    // ConnectionURL with SSL
    OracleGetConnectionURL: function(properties) {
        return this.call("ORACLEGETCONNECTIONURL", [properties]);
    },
    OjetGetConnectionURL: function(properties, agentName) {
        return this.call("OJETGETCONNECTIONURL", [properties, agentName]);
    },
    MariaDBGetConnectionURL: function(properties) {
        return this.call("MARIADBGETCONNECTIONURL", [properties]);
    },
    MariaDBXpandGetConnectionURL: function(properties) {
        return this.call("MARIADBXPANDGETCONNECTIONURL", [properties]);
    },
    MSSQLGetConnectionURL: function(properties) {
        return this.call("MSSQLGETCONNECTIONURL", [properties]);
    },
    MySQLGetConnectionURL: function(properties) {
        return this.call("MYSQLGETCONNECTIONURL", [properties]);
    },
    PostgreSQLGetConnectionURL: function(properties) {
        return this.call("POSTGRESGETCONNECTIONURL", [properties]);
    },
    FabricMirrorGetWorkspaces: function(properties) {
        return this.call("FABRICMIRRORGETWORKSPACE", [this.token, properties]);
    },
    FabricDataWarehouseWriterGetConnectionURL: function(params) {
        const { username, password, connectionUrl, passwordEncrypted, agentName, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName: username, password, URL: connectionUrl, passwordEncrypted }),
            ...remaining
        };
        return this.call("FABRICGETCONNECTIONURL", [formattedParams]);
    },
    // Encrypted ConnectionURL
    OracleGetEncryptedURL: function(properties, appName, agentName) {
        const Params = this.parseParamsForEncryptedURL(properties, appName, agentName);
        return this.call("ORACLEGETENCRYPTEDURL", [this.token, ...Params]);
    },
    OjetGetEncryptedURL: function(properties, appName, agentName) {
        const Params = this.parseParamsForEncryptedURL(properties, appName, agentName);
        return this.call("OJETGETENCRYPTEDURL", [this.token, ...Params]);
    },
    MariaDBGetEncryptedURL: function(properties, appName, agentName) {
        const Params = this.parseParamsForEncryptedURL(properties, appName, agentName);
        return this.call("MARIADBGETENCRYPTEDURL", [this.token, ...Params]);
    },
    MariaDBXpandGetEncryptedURL: function(properties, appName, agentName) {
        const Params = this.parseParamsForEncryptedURL(properties, appName, agentName);
        return this.call("MARIADBXPANDGETENCRYPTEDURL", [this.token, ...Params]);
    },
    MSSQLGetEncryptedURL: function(properties, appName, agentName) {
        const Params = this.parseParamsForEncryptedURL(properties, appName, agentName);
        return this.call("MSSQLGETENCRYPTEDURL", [this.token, ...Params]);
    },
    MySQLGetEncryptedURL: function(properties, appName, agentName) {
        const Params = this.parseParamsForEncryptedURL(properties, appName, agentName);
        return this.call("MYSQLGETENCRYPTEDURL", [this.token, ...Params]);
    },
    PostgreSQLGetEncryptedURL: function(properties, appName, agentName) {
        const Params = this.parseParamsForEncryptedURL(properties, appName, agentName);
        return this.call("POSTGRESGETENCRYPTEDURL", [this.token, ...Params]);
    },

    //Validate Connection
    OracleValidateConnection: function(params) {
        const { userName, password, URL, passwordEncrypted, agentName, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("ORACLEVALIDATECONNECTION", [formattedParams, agentName]);
    },
    MSSQLValidateConnection: function(params) {
        const { userName, password, URL, passwordEncrypted, agentName, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("MSSQLVALIDATECONNECTION", [formattedParams, agentName]);
    },
    MySQLValidateConnection: function(params) {
        const { userName, password, URL, passwordEncrypted, agentName, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("MYSQLVALIDATECONNECTION", [formattedParams, agentName]);
    },
    PostgresValidateConnection: function(params) {
        const { userName, password, URL, passwordEncrypted, agentName, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("POSTGRESVALIDATECONNECTION", [formattedParams, agentName]);
    },
    MariaDBValidateConnection: function(params) {
        const { userName, password, URL, passwordEncrypted, agentName, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("MARIADBXPANDVALIDATECONNECTION", [formattedParams, agentName]);
    },
    BigQueryValidateConnection: function(params) {
        return this.call("BIGQUERYVALIDATECONNETION", [params]);
    },
    DeltaLakeWriterValidateConnection: function(params) {
        const { URL, ...remaining } = params;
        const formattedParams = { ConnectionURL: URL, ...remaining };
        return this.call("DELTALAKEVALIDATECONNECTION", [formattedParams]);
    },
    SnowflakeWriterValidateConnection: function(params) {
        const { userName, password, URL, passwordEncrypted, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName: userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("SNOWFLAKEWRITERVALIDATECONNECTION", [formattedParams]);
    },
    FabricDataWarehouseWriterValidateConnection: function(params) {
        const { userName, password, URL, passwordEncrypted, ...remaining } = params;
        const formattedParams = {
            ...this.parseConnectivityParams({ userName: userName, password, URL, passwordEncrypted }),
            ...remaining
        };
        return this.call("FABRICVALIDATECONNECTION", [formattedParams]);
    },

    //Target Privilege check
    OracleCheckTargetPrivileges: function(params) {
        return this.call("ORACLECHECKTARGETPRIVILEGES", [params]);
    },
    MSSQLCheckTargetPrivileges: function(params) {
        return this.call("MSSQLCHECKTARGETPRIVILEGES", [params]);
    },
    MySQLCheckTargetPrivileges: function(params) {
        return this.call("MYSQLCHECKTARGETPRIVILEGES", [params]);
    },
    PostgresCheckTargetPrivileges: function(params) {
        return this.call("POSTGRESCHECKTARGETPRIVILEGES", [params]);
    },

    BigQueryCheckTargetPrivileges: function(params) {
        return this.call("BIGQUERYCHECKTARGETPRIVILEGES", [params]);
    },
    DeltaLakeWriterCheckTargetPrivileges: function(params) {
        return this.call("DELTALAKECHECKTARGETPRIVILEGES", [params]);
    },
    SnowflakeWriterCheckTargetPrivileges: function(params) {
        return this.call("SNOWFLAKEWRITERCHECKTARGETPRIVILEGES", [params]);
    },
    FabricDataWarehouseWriterCheckTargetPrivileges: function(params) {
        return this.call("FABRICCHECKTARGETPRIVILEGES", [params]);
    },

    // Source Privilege check with SSL
    ORACLECheckPrivileges: function(params, agentName) {
        return this.call("ORACLECHECKPRIVILEGES", [params, agentName]);
    },
    MSJETCheckPrivileges: function(params, agentName) {
        return this.call("com.webaction.source.mssqlwizard.MSJetWizard#checkPrivileges", [params, agentName]);
    },
    OJETCheckPrivileges: function(params, agentName) {
        return this.call("OJETCHECKPRIVILEGES", [params, agentName]);
    },
    MARIADBCheckPrivileges: function(params, agentName) {
        return this.call("MARIADBCHECKPRIVILEGES", [params, agentName]);
    },
    MARIADBXPANDCheckPrivileges: function(params, agentName) {
        return this.call("MARIADBXPANDCHECKPRIVILEGES", [params, agentName]);
    },
    SQLSERVERCheckPrivileges: function(properties, agentName = null) {
        return this.call("MSSQLCHECKPRIVILEGES", [properties, agentName]);
    },
    MYSQLCheckPrivileges: function(params, agentName) {
        return this.call("MYSQLCHECKPRIVILEGES", [params, agentName]);
    },
    POSTGRESCheckPrivileges: function(params, agentName) {
        return this.call("POSTGRESCHECKPRIVILEGES", [params, agentName]);
    },
    SALESFORCECheckPrivileges: function(params, agentName) {
        return this.call("SALESFORCECHECKPRIVILEGES", [params, agentName]);
    },
    // CDC Configuration check
    SALESFORCECheckCDCConfigurations: function(params, agentName) {
        return this.call("SALESFORCECHECKCDCCONFIG", [params, agentName]);
    },

    getUserProfile: function(userId) {
        return this.call("GETUSERPROFILE", [userId]);
    },
    putUserProfile: function(userId, uiConfig) {
        return this.call("PUTUSERPROFILE", [this.token, userId, uiConfig ? JSON.stringify(uiConfig) : uiConfig]);
    },
    // SSO Endpoints =>
    isSAMLConfigured: function() {
        return this.call("ISSAMLCONFIGURED");
    },
    createAdminSSOProperties: function(idp, certPath, applicationID, loginURL) {
        return this.call("CREATEADMINSSOPROPERTIES", [this.token, idp, certPath, applicationID, loginURL]);
    },
    getSSOProperties: function() {
        return this.call("GETSSOPROPERTIES", [this.token]);
    },
    editSSOProperties: function(certPath, applicationID, loginURL) {
        return this.call("EDITSSOPROPERTIES", [this.token, certPath, applicationID, loginURL]);
    },
    deleteSSO: function() {
        return this.call("DELETESSO", [this.token]);
    },
    // <= SSO Endpoints
    getPropertyActions: function() {
        return this.call("com.webaction.web.api.MetadataHelper#getPropertyActions", [this.token]);
    },
    startAutomated: function(statement) {
        return this.call("STARTAUTOMATED", [this.token, statement]);
    },
    deployILCDC: function(appGroupFQN, ILAppDesc, ILAppFlowDesc, CDCAppDesc, CDCAppFlowDesc) {
        return this.call("com.webaction.web.api.ILCDCHelper#deploy", [
            this.token,
            appGroupFQN,
            ILAppDesc,
            ILAppFlowDesc,
            CDCAppDesc,
            CDCAppFlowDesc
        ]);
    },
    runILCDC: function(appGroupFQN) {
        return this.call("com.webaction.web.api.ILCDCHelper#run", [this.token, appGroupFQN]);
    },
    stopILCDC: function(appGroupFQN) {
        return this.call("com.webaction.web.api.ILCDCHelper#stop", [this.token, appGroupFQN]);
    },
    resumeILCDC: function(appGroupFQN) {
        return this.call("com.webaction.web.api.ILCDCHelper#resume", [this.token, appGroupFQN]);
    },
    undeployILCDC: function(appGroupFQN) {
        return this.call("com.webaction.web.api.ILCDCHelper#undeploy", [this.token, appGroupFQN]);
    },
    quiesceILCDC: function(appGroupFQN) {
        return this.call("com.webaction.web.api.ILCDCHelper#quiesce", [this.token, appGroupFQN]);
    },
    resetILCDC: function(appGroupFQN) {
        return this.call("com.webaction.web.api.ILCDCHelper#reset", [this.token, appGroupFQN]);
    },
    getUserMetaData: function() {
        return this.call("GETUSERMETADATA", [this.token]);
    },
    getILCDCAppElapsedTime: function(appId) {
        return this.call("GETILCDCAPPELAPSEDTIME", [this.token, appId]);
    },
    getILCDCStartAndStopTime: function(params) {
        const { ILAppId, CDCAppId } = params;
        return this.call("GETILCDCAPPSTARTANDSTOPTIME", [this.token, ILAppId, CDCAppId]);
    },
    isCDDLSupported: function(params, agentName) {
        return this.call("ISCDDLSUPPORTED", [params, agentName]);
    },
    executePropertyAction: function(objFQN, adapterName, actionMethodPath, params, agentName) {
        return this.call("EXECUTEPROPERTYACTION", [
            this.token,
            objFQN,
            adapterName,
            actionMethodPath,
            params,
            agentName
        ]);
    },
    startPIIJob: function(name) {
        return this.call("com.webaction.web.api.PIIHelper#startPIIJob", [this.token, name]);
    },
    retryPiiJob: function(name) {
        return this.call("com.webaction.web.api.PIIHelper#retryPiiJob", [this.token, name]);
    },
    cancelPIIJob: function(name) {
        return this.call("com.webaction.web.api.PIIHelper#cancelPIIJob", [this.token, name]);
    },
    getReportForJob: function(name) {
        return this.call("com.webaction.web.api.PIIHelper#getReportForJob", [this.token, name]);
    },
    getPIIJobsForApp: function(appFQN) {
        return this.call("com.webaction.web.api.PIIHelper#getPIIJobsForApp", [this.token, appFQN]);
    },
    getPIISettings: function() {
        return this.call("com.webaction.web.api.PIIHelper#getPIISettings", [this.token]);
    },
    setPIISettings: function(settings) {
        return this.call("com.webaction.web.api.PIIHelper#setPIISettings", [this.token, settings]);
    },
    getReportsOfAllAppsForCurrentUser: function() {
        return this.call("com.webaction.web.api.PIIHelper#getReportsOfAllAppsForCurrentUser", [this.token]);
    },
    listPIISettingsOptions: function() {
        return this.call("com.webaction.web.api.PIIHelper#listPIISettingsOptions", [this.token]);
    },
    listSentinelOptions: function() {
        return this.call("com.webaction.web.api.PIIHelper#listSentinelOptions", [this.token]);
    },
    getSourceTypeInfo: function(fqn) {
        return this.call("com.webaction.web.api.PIIHelper#getSourceTypeInfo", [this.token, fqn]);
    },
    getAppInfoBySourceType: function(fqn) {
        return this.call("com.webaction.web.api.PIIHelper#getAppInfoBySourceType", [this.token, fqn]);
    },
    getFlowDesignerPreview: function(entityType, namespace, name, fromTime, toTime) {
        return this.call("com.webaction.web.api.SentinelLiveMon#flowDesignerPreview", [
            this.token,
            entityType,
            namespace,
            name,
            fromTime,
            toTime
        ]);
    },
    getAppSentinelsAIActivityEventsProcessed: function(namespace, appName, fromTime, toTime) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getAppSentinelsAIActivityEventsProcessed", [
            this.token,
            namespace,
            appName,
            fromTime,
            toTime
        ]);
    },
    getAppSentinelsEventsProcessedChartData: function(namespace, appName, fromTime, toTime, timeFormat) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getAppSentinelsEventsProcessedChartData", [
            this.token,
            namespace,
            appName,
            fromTime,
            toTime,
            timeFormat
        ]);
    },
    getSentinelEventsProcessed: function(namespace, sentinelName, fromTime, toTime) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSentinelAIActivityEventsProcessed", [
            this.token,
            namespace,
            sentinelName,
            fromTime,
            toTime
        ]);
    },
    getSentinelEventsProcessedChartData: function(namespace, sentinelName, fromTime, toTime, timeFormat) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSentinelEventsProcessedChartData", [
            this.token,
            namespace,
            sentinelName,
            fromTime,
            toTime,
            timeFormat
        ]);
    },
    getRunningAppsWithSentinelsDashboard: function() {
        return this.call("com.webaction.web.api.SentinelLiveMon#getRunningAppsWithSentinelsDashboard", [this.token]);
    },
    getRunningAppsSentinelsDetail: function() {
        return this.call("com.webaction.web.api.SentinelLiveMon#getRunningAppsSentinelsDetail", [this.token]);
    },
    getRunningAppsWithSentinelsDetail: function() {
        return this.call("com.webaction.web.api.SentinelLiveMon#getRunningAppsWithSentinelsDetail", [this.token]);
    },
    getRunningAppsWithSentinels: function() {
        return this.call("com.webaction.web.api.SentinelLiveMon#getRunningAppsWithSentinels", [this.token]);
    },
    getAppsEventsProcessedData: function(appNames, fromTime, toTime) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getAppsEventsProcessedData", [
            this.token,
            appNames,
            fromTime,
            toTime
        ]);
    },
    getAppsEventsProcessedChartData: function(appNames, fromTime, toTime, timeFormat) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getAppsEventsProcessedChartData", [
            this.token,
            appNames,
            fromTime,
            toTime,
            timeFormat
        ]);
    },
    getSensitiveDataOccurrencesForAppsChartData: function(appNames, fromTime, toTime, timeFormat) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSensitiveDataOccurrencesForAppsChartData", [
            this.token,
            appNames,
            fromTime,
            toTime,
            timeFormat
        ]);
    },
    getSDIDrillDownEventsProcessedForApps: function(appNames, fromTime, toTime, sdi) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSDIDrillDownEventsProcessedForApps", [
            this.token,
            appNames,
            fromTime,
            toTime,
            sdi
        ]);
    },
    getSDIDrillDownEventsProcessedForAppsChartData: function(appNames, fromTime, toTime, sdi, timeFormat) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSDIDrillDownEventsProcessedForAppsChartData", [
            this.token,
            appNames,
            fromTime,
            toTime,
            sdi,
            timeFormat
        ]);
    },
    getSensitiveDataOccurrences: function(entity, namespace, name, appName, fromTime, toTime) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSensitiveDataOccurrences", [
            this.token,
            entity,
            namespace,
            name,
            appName,
            fromTime,
            toTime
        ]);
    },
    getSensitiveDataOccurrencesChartData: function(entity, namespace, name, fromTime, toTime, timeFormat) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSensitiveDataOccurrencesChartData", [
            this.token,
            entity,
            namespace,
            name,
            fromTime,
            toTime,
            timeFormat
        ]);
    },
    getAppSentinelsSDIEventsProcessed: function(namespace, appName, fromTime, toTime, sdi) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getAppSentinelsSDIEventsProcessed", [
            this.token,
            namespace,
            appName,
            fromTime,
            toTime,
            sdi
        ]);
    },
    getAppSentinelsSDIDrillDownEventsChartData: function(namespace, appName, fromTime, toTime, sdi, timeFormat) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getAppSentinelsSDIDrillDownEventsChartData", [
            this.token,
            namespace,
            appName,
            fromTime,
            toTime,
            sdi,
            timeFormat
        ]);
    },
    getSentinelSDIDrillDownEventsChartData: function(namespace, sentinelName, fromTime, toTime, sdi, timeFormat) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSentinelSDIDrillDownEventsChartData", [
            this.token,
            namespace,
            sentinelName,
            fromTime,
            toTime,
            sdi,
            timeFormat
        ]);
    },
    getSentinelSDIDrillDownEvents: function(namespace, sentinelName, fromTime, toTime, sdi) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSentinelSDIDrillDownEvents", [
            this.token,
            namespace,
            sentinelName,
            fromTime,
            toTime,
            sdi
        ]);
    },
    getSDIDrillDownFindingsChartData: function(entityType, namespace, name, fromTime, toTime, sdi, timeFormat) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSDIDrillDownFindingsChartData", [
            this.token,
            entityType,
            namespace,
            name,
            fromTime,
            toTime,
            sdi,
            timeFormat
        ]);
    },
    getAppSDIDrillDownFindings: function(namespace, appName, fromTime, toTime, sdi) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getAppSDIDrillDownFindings", [
            this.token,
            namespace,
            appName,
            fromTime,
            toTime,
            sdi
        ]);
    },
    getSentinelSDIDrillDownFindings: function(namespace, name, fromTime, toTime, sdi) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSentinelSDIDrillDownFindings", [
            this.token,
            namespace,
            name,
            fromTime,
            toTime,
            sdi
        ]);
    },
    getSDIDrillDownOccurrences: function(applications, fromTime, toTime) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSDIDrillDownOccurrences", [
            this.token,
            applications,
            fromTime,
            toTime
        ]);
    },
    getSDIDrillDownOccurrencesForApps: function(applications, fromTime, toTime, sdi) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSDIDrillDownOccurrencesForApps", [
            this.token,
            applications,
            fromTime,
            toTime,
            sdi
        ]);
    },
    getSDIDrillDownOccurrencesForAppsChartData: function(applications, fromTime, toTime, sdi, timeFormat) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSDIDrillDownOccurrencesForAppsChartData", [
            this.token,
            applications,
            fromTime,
            toTime,
            sdi,
            timeFormat
        ]);
    },
    getListOfSDIApplicableToPartialMasking: function() {
        return this.call("com.webaction.web.api.PIIHelper#getListOfSDIApplicableToPartialMasking");
    },
    getSensitiveDataOccurrencesForApps: function(applications, fromTime, toTime) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getSensitiveDataOccurrencesForApps", [
            this.token,
            applications,
            fromTime,
            toTime
        ]);
    },
    getGlobalSentinelReportData: function(applications, fromTime, toTime) {
        return this.call("com.webaction.web.api.SentinelLiveMon#getGlobalSentinelReportData", [
            this.token,
            applications,
            fromTime,
            toTime
        ]);
    },
    isLicenseOptionDisableAIInsights: function() {
        return this.call("isLicenseOptionDisableAIInsights", [this.token]);
    },
    getTopEntitiesWithGivenImp: function(sherlockName, count, importanceLevel) {
        return this.call("GETTOPENTITIESWITHGIVENIMP", [this.token, sherlockName, count, importanceLevel]);
    },
    getTopAppsWithGivenImp: function(sherlockName, count, importanceLevel) {
        return this.call("GETTOPAPPSWITHGIVENIMP", [this.token, sherlockName, count, importanceLevel]);
    },
    generateSherlockCSVReport: function(sherlockName) {
        return this.call("GENERATESHERLOCKCSVREPORT", [this.token, sherlockName]);
    },
    generateSentinelCSVReport: function(listOfFQNs, entityType, fromTime, toTime) {
        return this.call("GENERATESENTINELCSVREPORT", [this.token, listOfFQNs, entityType, fromTime, toTime]);
    },
    isLicenseOptionSQL2FabricSolution: function() {
        return this.call("isLicenseOptionSQL2FabricSolution", [this.token]);
    }
};

var api = new API();
export default api;
