import Backbone from "backbone";
import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import BaseEditor from "app/components/common/editor/meta-object-editors/base";

var Module = function () {
    BaseEditor.apply(this, arguments);
};

Module.prototype = _.extend({}, BaseEditor.prototype, {
    addCustomFields: function () {
        this.fieldMapper.strategy = UIControl.TextField.create();
        this.fieldMapper.deploymentGroup = UIControl.TextField.create();
        this.fieldMapper.flow = UIControl.TextField.create();
        this.fieldMapper.FailOverRule = UIControl.TextField.create();
        this.fieldMapper.importStatements = UIControl.TextField.create();
        this.fieldMapper.deploymentPlan = UIControl.TextField.create();
        this.fieldMapper.recoveryType = UIControl.TextField.create();
        this.fieldMapper.recoveryPeriod = UIControl.TextField.create();
        this.fieldMapper.encrypted = UIControl.TextField.create();
        this.fieldMapper.flowStatus = UIControl.TextField.create();
    },
});

export default Module;
