import React, { useState } from "react";
import { Tabs, Theme, SxProps, Box, Grid } from "@mui/material";
import { StriimTab } from "@striim/striim-ui";
import { StriimTypography } from "@striim/striim-ui-v2";
import { styles } from "../review/review.styles";

const tableStyles = {
    container: {
        flexGrow: 1,
        display: "flex",
        border: (theme: Theme) => `1px solid ${theme.palette.greyscale[200]}`,
        borderRadius: "10px",
        height: "300px",
        "& .MuiTabs-root": {
            borderRadius: "10px 0px 0px 10px",
            borderRight: (theme: Theme) => `1px solid ${theme.palette.greyscale[200]}`
        },
        overflow: "scroll"
    },
    tabContainer: {
        borderColor: "divider",
        "& .MuiTab-root:first-of-type": {
            borderRadius: 0
        },
        "& .MuiTabs-scroller": {
            overflow: "scroll !important"
        },
        "& .MuiTabs-scroller::-webkit-scrollbar ": {
            display: "none"
        },
        "& .MuiTab-root": {
            borderRadius: 0,
            border: "1px solid transparent",
            borderRight: "none",
            borderWidth: "1px 1px 1px 0px",
            marginLeft: 0 + "! important",
            padding: (theme: Theme) => theme.spacing(2, 2, 2, 3),
            height: (theme: Theme) => theme.spacing(5.5),
            width: (theme: Theme) => theme.spacing(21),
            alignItems: "flex-start",
            background: "transparent",
            "&:hover": {
                background: (theme: Theme) => theme.palette.greyscale[100]
            },
            "&:first-child": {
                borderTopColor: "transparent"
            }
        },
        "& .Mui-selected": {
            background: (theme: Theme) => theme.palette.greyscale[100],
            borderColor: (theme: Theme) => theme.palette.greyscale[200]
        }
    },
    tableContainer: {
        overflow: "scroll",
        flexGrow: 1
    }
};
interface TableSummaryProps {
    tableData: [];
    containerStyles?: SxProps;
    isObject: boolean;
    channel: string;
}

const ObjectTable = ({ objects, channel }) => {
    return (
        <Grid sx={styles.tableWrapper}>
            <Grid sx={styles.tableTitle}>
                <StriimTypography variant="body4" sx={{ color: "greyscale.900" }}>
                    List of Objects to load {channel ? `from ${channel}` : ""}: {objects.length}
                </StriimTypography>
            </Grid>
            <Grid sx={styles.tablesList}>
                {objects?.map(item => (
                    <StriimTypography variant="body4" sx={{ color: "greyscale.700" }}>
                        {item}
                    </StriimTypography>
                ))}
            </Grid>
        </Grid>
    );
};

export default function TableSummary({ tableData, containerStyles, isObject = false, channel }: TableSummaryProps) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleChange = (_event: React.SyntheticEvent, newIndex: number) => {
        setCurrentIndex(newIndex);
    };

    return (
        <Box sx={{ ...containerStyles, ...tableStyles.container }}>
            {isObject ? (
                <ObjectTable objects={tableData} channel={channel} />
            ) : (
                <>
                    <Tabs
                        orientation="vertical"
                        visibleScrollbar={true}
                        value={currentIndex}
                        onChange={handleChange}
                        sx={tableStyles.tabContainer}
                    >
                        {tableData?.map(({ label }) => {
                            return <StriimTab key="label" label={label} />;
                        })}
                    </Tabs>
                    <Box sx={tableStyles.tableContainer}>{tableData?.[currentIndex]?.component}</Box>
                </>
            )}
        </Box>
    );
}
