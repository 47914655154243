import { GenericStore } from "../storeUtils";
import { computed, observable, action } from "mobx";
import {
    getTemplateGroups,
    getCombinedLicenses,
    filterTemplates
} from "../../modules/apps/pages/create-app-page/utils";
import api from "core/api/api";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import growl from "app/components/common/growl";
import namespaceFactory from "app/components/createapp/namespace-factory";
import resources from "app/components/createapp/resources";
import navigateTo from "src/navigate-to";
import exceptionsStoreApi from "app/components/flow/designer/app-exceptions/exception-store-api";
import TqlRegex from "app/components/common/app-control/tql-regex";
import App from "app";
import $ from "jquery";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import securityCheck from "core/services/securityService/adapter-security-check";
import { isAutomatedWizard } from "../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";

export default class CreateAppsStore extends GenericStore {
    constructor(backboneStore) {
        super(backboneStore);
        this.backboneStore = backboneStore;
    }
    @observable selectedSearchOptions = [];
    @observable inProgress = false;
    @observable fileName = null;
    @observable tql = null;
    @observable selectedAppType = null;

    @action setSearchOptions(options) {
        this.selectedSearchOptions = options;
    }

    @action setSelectedAppType(appType) {
        this.selectedAppType = appType;
    }

    @computed get filteredTemplates() {
        const { selectedSearchOptions } = this;
        const { templatesList } = getTemplateGroups();
        return selectedSearchOptions.length ? filterTemplates(selectedSearchOptions, templatesList) : [];
    }

    @computed get searchOptions() {
        return getTemplateGroups().options;
    }

    @computed get shouldRenderSearchResults() {
        return !!this.selectedSearchOptions.length;
    }
    @computed get enabled() {
        return this.backboneStore.initialized;
    }
    @computed get defaultTemplateGroups() {
        const combinedLicences = this.enabled ? getCombinedLicenses() : [];
        const {
            templateGroups,
            automatedTemplateGroup,
            newlyAddedTemplatesGroup,
            mirrorTemplateGroup
        } = getTemplateGroups();
        const licenseEnabledTemplateGroups = templateGroups.filter(({ campaignKey }) =>
            combinedLicences.includes(campaignKey)
        );
        // add automated templates and newly group irrespective of the campaign license
        // the templates in automated group and  will contain only the license enabled ones.
        newlyAddedTemplatesGroup && licenseEnabledTemplateGroups.unshift(newlyAddedTemplatesGroup);
        automatedTemplateGroup && licenseEnabledTemplateGroups.unshift(automatedTemplateGroup);
        mirrorTemplateGroup && licenseEnabledTemplateGroups.unshift(mirrorTemplateGroup);

        return licenseEnabledTemplateGroups;
    }

    @computed get createFromScratch() {
        return !this.tql;
    }

    @computed get createFromWizard() {
        return window.location.href.includes("appwizard/createapp");
    }

    @computed get initialName() {
        return this.createFromScratch ? "" : this.nameFromTql;
    }

    @computed get initialNamespace() {
        return this.createFromScratch ? App.user.defaultNamespace : this.namespaceFromTql || App.user.defaultNamespace;
    }

    @computed get nameFromTql() {
        return TqlRegex.extractApplicationName(this.tql);
    }

    @computed get namespaceFromTql() {
        return TqlRegex.extractNamespace(this.tql);
    }

    @computed get namespaceFromAppName() {
        return TqlRegex.extractNamespaceFromAppName(this.tql);
    }

    @action createNewApp(name, nsName, templateId) {
        if (this.inProgress) {
            return;
        }

        this.inProgress = true;
        const model = this.getEmptyAppModel(name, nsName);
        const _this = this;

        const createDependentObjects = function() {
            return new Promise(async (resolve, reject) => {
                try {
                    const namespace = await namespaceFactory.create(nsName);
                    const app = await metaStoreService.findById(
                        metaObjectConverter.getId(nsName, metaStoreService.entities.APPLICATION, name)
                    );
                    if (app) {
                        growl.error("", resources.pleaseDropExistingApp(app.name));
                        reject();
                        navigateTo.Apps();
                        _this.inProgress = false;
                    } else {
                        const newAppAPICall = await api.create(undefined, model);
                        const newApp = newAppAPICall[0];
                        if (templateId) {
                            await _this.enableExceptionsStore(model.nsName, model.name);
                        }
                        if (!isAutomatedWizard()) {
                            growl.success(
                                resources.appCreated(newApp.name),
                                resources.appCreatedTitle,
                                newApp.id,
                                newApp.id
                            );
                        }

                        const appName = metaObjectConverter.getShortId(newApp.nsName, newApp.name);
                        resolve();
                        if (templateId) {
                            const navigateParams = {
                                appName,
                                templateId,
                                stepName: "source-info",
                                force: true
                            };
                            navigateTo.AppWizardTemplate(navigateParams);
                        } else {
                            navigateTo.AppRedirect(appName);
                        }
                    }
                } catch (e) {
                    growl.error(e);
                    reject(e);
                } finally {
                    _this.inProgress = false;
                }
            });
        };
        createDependentObjects();
    }

    @action import_app(nsName, passphrase) {
        var _this = this;

        if (this.inProgress) {
            return;
        }

        this.inProgress = true;

        let context;
        if (this.namespaceFromTql) {
            context = namespaceFactory.setNamespaceContext(App.user.defaultNamespace);
        } else {
            context = namespaceFactory.setNamespaceContext(nsName);
        }

        $.when(context)
            .then(function() {
                growl.info("", resources.reading(_this.fileName));
                try {
                    // eslint-disable-next-line no-inner-declarations
                    function reloadOpenProcessorPropertyTemplate() {
                        return new Promise(resolve => {
                            propertyTemplateService.loadPropertyTemplate().always(function() {
                                securityCheck.clearCache();
                                securityCheck.loadAll().then(function() {
                                    resolve();
                                });
                            });
                        });
                    }
                    api.compileTQL(_this.tql, passphrase)
                        .done(function() {
                            reloadOpenProcessorPropertyTemplate()
                                .then(() => {
                                    _this.inProgress = false;
                                    growl.success("", resources.fileImportSuccess(_this.fileName));
                                    if (!_this.nameFromTql) {
                                        navigateTo.Apps();
                                    } else {
                                        navigateTo.AppRedirect(
                                            metaObjectConverter.getShortId(
                                                _this.namespaceFromTql || _this.namespaceFromAppName || nsName,
                                                _this.nameFromTql
                                            )
                                        );
                                    }
                                })
                                .catch(() => {
                                    _this.inProgress = false;
                                });
                        })
                        .fail(function(e) {
                            // All errors regarding dashboards are not errors but warnings, the app gets created anyway,
                            // so the user should be redirected to the app and a warning message should be presented.
                            if (e.message.toUpperCase().indexOf(metaStoreService.entities.DASHBOARD) !== -1) {
                                reloadOpenProcessorPropertyTemplate()
                                    .then(() => {
                                        _this.inProgress = false;

                                        growl.warning(e.message, resources.ImportSuccess);
                                        if (_this.nameFromTql) {
                                            navigateTo.AppRedirect(
                                                metaObjectConverter.getShortId(
                                                    _this.namespaceFromTql || _this.namespaceFromAppName || nsName,
                                                    _this.nameFromTql
                                                )
                                            );
                                        }
                                    })
                                    .catch(() => {
                                        _this.inProgress = false;
                                    });
                            } else if (e.message.toLowerCase().indexOf(resources.topicAlreadyExists) !== -1) {
                                _this.inProgress = false;
                                growl.error("", e.message);
                                navigateTo.Apps();
                            } else if (e.message.toLowerCase().indexOf(resources.alreadyExists) !== -1) {
                                _this.inProgress = false;
                                growl.error("", e.message);
                                navigateTo.Apps();
                            } else {
                                _this.inProgress = false;
                                growl.error(e.message, resources.importFailed);
                                navigateTo.Apps();
                            }
                        });
                } catch (e) {
                    console.error(e);
                    growl.error(resources.errorWhileParsing(_this.data.fileName));
                    _this.inProgress = false;
                }
            })
            .fail(function(error) {
                growl.error(error);
                _this.inProgress = false;
            });
    }

    @action clearStore() {
        this.inProgress = false;
        this.fileName = null;
        this.tql = null;
    }

    async enableExceptionsStore(namespace, appName) {
        const appId = metaObjectConverter.getId(namespace, metaStoreService.entities.APPLICATION, appName);
        try {
            await exceptionsStoreApi.turnOn(appId);
        } catch (err) {
            // no need to inform the user about it because it's not an error but a consequence of the current license
            console.log(err);
        }
    }

    getEmptyAppModel(name, nsName) {
        return {
            type: "APPLICATION",
            description: "",
            uuid: null,
            isEditable: true,
            strategy: "",
            deploymentGroup: "",
            flow: "",
            FailOverRule: "",
            importStatements: [],
            objects: {},
            deploymentPlan: [],
            recoveryType: 0,
            recoveryPeriod: 0,
            encrypted: false,
            flowStatus: "",
            eHandlers: {},
            notifyEmail: [],
            owner: "",
            eventsToPreview: 0,
            deploymentInfo: [],
            isFlowDesignerCompatible: false,
            isDataValidationEnabled: false,
            exceptionstoreName: "",
            enableAutoResume: false,
            autoResumeRetryInterval: 0,
            autoResumeRetryCount: 0,
            id: "",
            name: name,
            nsName: nsName,
            namespaceId: "",
            metaObjectClass: "",
            metaInfoStatus: {},
            uri: "",
            version: 0,
            ctime: 0,
            statusInfo: {}
        };
    }
}
