import _ from "underscore";
import evaluator from "app/components/dashboard/visualization/charts/gauge/expressionEvaluator";
import HighChartsMixin from "app/components/dashboard/visualization/charts/mixins/highcharts";
import { tooltipStriimlineHighcharts } from "../../../../../../styles/materialize/components-striimline/tooltip.export";

export default _({}).extend(HighChartsMixin, {
    // Note: be sure to pass in a deep-cloned config object!
    setConfiguration: function(config) {
        var theme = config.theme;
        config = config.config;
        if (!theme) {
            return console.warn("No theme provided.");
        }

        var data = config.data[0];

        var minExpression = config.min;
        var minValue = evaluator.evaluate(minExpression, data);

        var maxExpression = config.max;
        var maxValue = evaluator.evaluate(maxExpression, data);

        this.stops = _getStops(minValue, maxValue, config.thresholds, data);
        this.chartConfig = {
            chart: {
                type: "solidgauge",
                backgroundColor: theme.chart.background
            },

            title: null,

            pane: {
                center: ["50%", "85%"],
                size: "100%",
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: (window.Highcharts.theme && window.Highcharts.theme.background2) || "#555",
                    innerRadius: "60%",
                    outerRadius: "100%",
                    shape: "arc"
                }
            },

            tooltip: {
                enabled: false,
                ...tooltipStriimlineHighcharts
            },

            // the value axis
            yAxis: {
                stops: this.stops,
                lineWidth: 0,
                minorTickInterval: null,
                //tickPixelInterval: 400,
                tickWidth: 0,
                title: {
                    y: -70
                },
                labels: {
                    y: 16
                },
                min: minValue,
                max: maxValue,
                tickPositions: [0, +config.max]
            },

            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            credits: {
                enabled: false
            },

            series: this.generateSeries(config)
        };
    },
    generateSeries: function(config) {
        if (typeof config.data === "undefined" || !config.data[0]) {
            return [];
        }

        var value = +[config.data[0][config.field]];
        var max = +config.max;

        // if current value is greater than max value, we feed in fake value to the chart
        var theValue = value > max ? [max] : [value];

        // but feed the real current value to the label to be displayed
        var formatString =
            '<div style="text-align:center"><span style="font-size:25px;color:' +
            ((window.Highcharts.theme && window.Highcharts.theme.contrastTextColor) || "#555") +
            '">{y}</span><br/>' +
            '<span style="font-size:12px;color:silver">' +
            config.unit +
            "</span></div>";
        value = Math.round(value * 10000) / 10000;
        var vs = "" + value;
        if (value > 1000000000000) {
            vs = Number(value / 1000000000000).toFixed(2) + "T";
        } else if (value > 1000000000) {
            vs = Number(value / 1000000000).toFixed(2) + "G";
        } else if (value > 1000000) {
            vs = Number(value / 1000000).toFixed(2) + "M";
        } else if (value > 1000) {
            vs = Number(value / 1000).toFixed(2) + "K";
        }

        formatString = formatString.replace("{y}", vs);

        return [
            {
                // data: [config.data[0][config.field]],

                data: theValue,

                dataLabels: {
                    format: formatString
                },

                tooltip: {
                    valueSuffix: config.unit
                }
            }
        ];
    }
});

function _getStops(min, max, thresholds, data) {
    var stops = [];

    if (typeof thresholds === "undefined" || thresholds.length === 0) {
        stops.push([0.1, "#55BF3B"]);
        stops.push([0.5, "#DDDF0D"]);
        stops.push([0.9, "#DF5353"]);

        return stops;
    }

    thresholds.forEach(function(node) {
        var ceilingExpression = node.ceiling;
        var ceilingValue = evaluator.evaluate(ceilingExpression, data);

        var stop = (ceilingValue - min) / (max - min);
        stops.push([stop, node.color]);
    });

    return stops;
}
