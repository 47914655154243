import React, { useEffect } from "react";
import { useParams } from "react-router";
import loadBackboneApp from "../../../app/load-backbone-app";
import { APIView } from "../../../app/components/debug/editors-test";

export default function TestApi() {
    const { params } = useParams();
    useEffect(() => {
        let progressBar = document.querySelector('#app-progressbar-container');
        if (progressBar) {
          progressBar.style.display = 'none'; // Hide the progress bar
        }
        const viewParams = params || "";
        const view = new APIView({
            params: viewParams
        });
        loadBackboneApp(view);
        return () => {
            let progressBar = document.querySelector('#app-progressbar-container');
            if (progressBar) {
              progressBar.style.display = ''; // Hide the progress bar
            }
        }
    }, []);

    return <></>;
}
