import React, { useState } from "react";
import { Collapse, Grid, SvgIcon } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui";
import { ExpandLessRounded, ExpandMoreRounded } from "@material-ui/icons";

import { styles } from "./entity-summary.styles";

interface CollapsibleSummaryProps {
    title: string;
    children: React.ReactNode;
    defaultOpen?: boolean;
}

const CollapsibleSummary = ({ title, children, defaultOpen = true }: CollapsibleSummaryProps) => {
    const [open, setOpen] = useState(defaultOpen);

    const handleChange = () => {
        setOpen(prev => !prev);
    };

    return (
        <Grid container alignItems={"center"}>
            <Grid
                display={"flex"}
                width={"100%"}
                item
                gap={1}
                p={2}
                sx={open && styles.expanded}
                onClick={handleChange}
            >
                <SvgIcon sx={styles.icon} component={open ? ExpandLessRounded : ExpandMoreRounded} />
                <StriimTypography variant="h4" color="primary.700" width={600} sx={styles.title}>
                    {title}
                </StriimTypography>
            </Grid>

            <Collapse in={open} sx={{ width: "100%" }}>
                <Grid container mb={2} width={"100%"}>
                    {children}
                </Grid>
            </Collapse>
        </Grid>
    );
};

export default CollapsibleSummary;
