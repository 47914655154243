import App from "app";
import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import utils from "core/utils";
import template from "./templates/metrics.html";
import MetricTypes from "./metrictypes";
import ChartOptions from "app/components/monitor/common/chartoptions";
import serverMemoryScale from "./server-memory-scale";
import Filter from "./filter";
import List from "./list";
import noDataTemplate from "../../appdetails/appdata/templates/noDataTemplate";

var Metrics = {};

Metrics.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(template),

    regions: {
        list: ".list",
        filter: ".filter",
        chartBox: "#chartBox"
    },

    modelEvents: {
        change: "update",
        "change:selectedMetric": "selectedMetricChanged"
    },

    initialize: function() {
        this.filterView = new Filter.View({
            model: this.model
        });

        //TODO 1: add unique id to each item
        var items = _.union(this.model.apps, this.model.servers);
        _.each(items, function(app) {
            var fullName = app["full-name"];
            app.id = fullName;
            app.color = App.request("get:color:byalias", fullName);
        });

        this.appCollection = new Backbone.Collection(this.model.apps);
        this.serverCollection = new Backbone.Collection(this.model.servers);

        this.listView = new List.View({
            model: this.model,
            collection: this.appCollection,
            preserveScroll: true
        });

        this.visiblePointsCount = 120;
        this.model.selectedItem = this.model.apps.length ? this.model.apps[0].id : "";

        //DEV-8437  fix is - To unset the UTC timezone use useUTC:false
        Highcharts.setOptions({
            global: {
                useUTC: false
            }
        });
        this.multiGraphRendered = false;
    },

    selectedMetricChanged: function(event) {
        if (!this.model.selectedMetric) {
            this.model.selectedMetric = MetricTypes.ServerRate.name;
        }

        var isNewValueServerRelated = MetricTypes.IsServerRelated(this.model.selectedMetric);
        var metricTypeChanged =
            isNewValueServerRelated !== MetricTypes.IsServerRelated(event._previousAttributes.selectedMetric);

        if (isNewValueServerRelated) {
            this.listView.collection = this.serverCollection;
            if (this.model.servers.length > 0) {
                if (metricTypeChanged) {
                    this.model.selectedItem = this.model.servers[0]["full-name"];
                }
            }
        } else {
            this.listView.collection = this.appCollection;
            if (this.model.apps.length > 0) {
                if (metricTypeChanged) {
                    this.model.selectedItem = this.model.apps[0]["full-name"];
                }
            }
        }
    },

    prepareData: function(serie, additionalData) {
        additionalData = additionalData || {};
        var selectedMetric = this.model.get("selectedMetric");
        var memory_max = additionalData.maxMemory;

        return _.map(serie.slice(0, this.visiblePointsCount), function(item) {
            if (selectedMetric === "Server memory") {
                return {
                    x: new Date(item.first),
                    y: item.second ? memory_max - parseFloat(item.second) : 0
                };
            } else if (selectedMetric === "Server CPU %") {
                if (_.isString(item.second)) item.second.replace("%", "");
                let rate = parseFloat(item.second) * 100;
                let roundedRate = rate / additionalData.totalCores;

                return {
                    x: new Date(item.first),
                    y: roundedRate //provided value is sum of all cores. We need to calculate average per core.
                };
            } else {
                return {
                    x: new Date(item.first),
                    y: item.second ? parseFloat(item.second) : 0
                };
            }
        });
    },

    prepareDataForChart: function(series, additionalData) {
        if ("All Applications" === this.model.selectedItem) {
            //series will contain all apps data
            var _this = this;
            const seriesClone = JSON.parse(JSON.stringify(series));
            _.each(seriesClone, function(singleAppSeries) {
                singleAppSeries.data = _this.prepareData(singleAppSeries.data);
            });
            return seriesClone;
        }
        return this.prepareData(series, additionalData);
    },

    update: function() {
        var _this = this;
        var app = _.filter(
            MetricTypes.IsServerRelated(this.model.selectedMetric) ? this.model.servers : this.model.apps,
            function(a) {
                return a["full-name"] === _this.model.selectedItem;
            }
        )[0];
        _this.updateListView(app);
        if (!app) {
            if (!MetricTypes.IsServerRelated(this.model.selectedMetric)) {
                var chartMsgElement = this.$el.find(".msgBox");
                if (this.model.apps.length === 0 && chartMsgElement) {
                    this.$el.find(".chart").hide();
                    chartMsgElement.show();
                    chartMsgElement.html(new Metrics.EmptyAppsView().render().el);
                }
            }
            return;
        } else {
            this.$el.find(".msgBox").hide();
            this.$el.find(".chart").show();
        }
        var timeSeries = app["time-series-data"];
        var mostRecentData = app["most-recent-data"] || {};
        var additionalDataForChart = {
            maxMemory: mostRecentData["memory-max"] || 0,
            totalCores: mostRecentData["cores"] || App.reqres.request("monitor:total-cores")
        };
        var selectedMetric = MetricTypes.GetByName(this.model.selectedMetric);

        if (!selectedMetric) {
            return;
        }

        // different y-axis settings based on selected metric
        if (this.chart) {
            if (MetricTypes.IsServerMemory(this.model.selectedMetric)) {
                var memory_max = additionalDataForChart.maxMemory;
                var memoryScale = serverMemoryScale.calculate(memory_max);
                this.chart.yAxis[0].update({
                    max: memoryScale,
                    tickInterval: memoryScale / 4,
                    labels: {
                        formatter: function() {
                            return utils.bytesFormatter(this.value);
                        }
                    }
                });
            } else if (MetricTypes.IsRateRelated(this.model.selectedMetric)) {
                this.chart.yAxis[0].update({
                    max: 100,
                    tickInterval: undefined,
                    labels: {
                        formatter: function() {
                            return this.value + "%";
                        }
                    }
                });
            } else {
                this.chart.yAxis[0].update({
                    max: undefined,
                    tickInterval: undefined,
                    labels: {
                        formatter: function() {
                            return this.value;
                        }
                    }
                });
            }
        }

        var timeSerie = timeSeries[selectedMetric.sourceName];
        if (!timeSerie) {
            if (this.chart) {
                this.chartBox.show(
                    new noDataTemplate({
                        text: `View ${selectedMetric.name} here once your app starts running`
                    })
                );
            } else {
                var chartElement = this.$el.find(".chart")[0];
                if (!chartElement) {
                    return;
                }
                this.chart = new Highcharts.Chart(
                    $.extend(
                        true,
                        {
                            series: [
                                {
                                    data: []
                                }
                            ]
                        },
                        {
                            chart: {
                                renderTo: chartElement
                            }
                        },
                        ChartOptions.getColoredTime(_this.model.selectedItem)
                    )
                );

                setTimeout(function() {
                    _this.chart.redraw();
                }, 0);
            }

            return;
        } else {
            this.chartBox.$el.find(".noDataTemplateContainer").hide();
        }
        var data = this.prepareDataForChart(timeSerie, additionalDataForChart);
        if (data.length === 0) {
            return;
        }
        function formatAppServerName(chartData) {
            return '<br/><span style="font-size: 10px;">' + chartData.series.name + "</span>";
        }

        if (this.chart) {
            if (MetricTypes.IsServerMemory(this.model.selectedMetric)) {
                this.chart.tooltip.options.formatter = this.chart.tooltip.options.formatter = function() {
                    return `<b>Memory used</b><br/>${utils.bytesFormatter(this.y)}${formatAppServerName(this)}`;
                };
            } else if (MetricTypes.IsRateRelated(this.model.selectedMetric)) {
                this.chart.tooltip.options.formatter = function() {
                    return `<b>CPU Per Node</b><br/>${this.y.toFixed(2)} %${formatAppServerName(this)}`;
                };
            } else if (MetricTypes.IsEventRate(this.model.selectedMetric)) {
                this.chart.tooltip.options.formatter = function() {
                    return `<b>Source rate</b><br/>${this.y} events/sec${formatAppServerName(this)}`;
                };
            } else if (MetricTypes.IsEventsProcessed(this.model.selectedMetric)) {
                this.chart.tooltip.options.formatter = function() {
                    return `<b>Total events processed</b><br/>${this.y} events${formatAppServerName(this)}`;
                };
            } else {
                this.chart.tooltip.options.formatter = function() {
                    return `<b>${selectedMetric.sourceName}</b><br/>${this.y}${formatAppServerName(this)}`;
                };
            }
            this.updateChartSeries(data);
        } else {
            var getNewSeriesDataForChart = function(data) {
                var seriesArray = [];
                if ("All Applications" === _this.model.selectedItem) {
                    _.each(data, function(ele) {
                        var seriesObj = {};
                        seriesObj.data = ele.data;
                        seriesObj.name = ele["full-name"];
                        seriesObj.color = App.request("get:color:byalias", ele["full-name"]);
                        seriesArray.push(seriesObj);
                    });
                } else {
                    seriesArray.push({
                        data: data
                    });
                }
                return seriesArray;
            };
            var extend = $.extend(
                true,
                {
                    series: getNewSeriesDataForChart(data)
                },
                {
                    chart: {
                        renderTo: this.$el.find(".chart")[0]
                    }
                },
                ChartOptions.getColoredTime(_this.model.selectedItem, selectedMetric.sourceName)
            );
            this.chart = new Highcharts.Chart(extend);
            this.chart.options.plotOptions.area.fillColor =
                "All Applications" === this.model.selectedItem ? "transparent" : null;
        }

        setTimeout(function() {
            _this.chart.redraw();
        }, 0);
    },

    updateChartSeries: function(data) {
        var _this = this;
        while (this.chart.series.length > 0) {
            this.chart.series[0].remove(false);
        }
        if ("All Applications" === this.model.selectedItem) {
            this.chart.options.plotOptions.area.fillColor = "transparent";
            _.each(data, function(singleApp) {
                _this.chart.addSeries(
                    {
                        name: singleApp["full-name"],
                        data: singleApp.data,
                        color: App.request("get:color:byalias", singleApp["full-name"])
                    },
                    true
                );
            });
        } else {
            this.chart.options.plotOptions.area.fillColor = null;
            this.chart.addSeries(
                {
                    name: this.model.selectedItem,
                    data: data,
                    color: App.request("get:color:byalias", this.model.selectedItem)
                },
                true
            );
        }
        /*  setTimeout(function () {
         _this.chart.redraw();
         }, 0);*/
    },

    onRender: function() {
        this.model.selectedMetric = MetricTypes.ServerRate.name;

        this.list.show(this.listView);
        this.filter.show(this.filterView);
        this.update();
    },
    updateListView: function(app) {
        if (!app) {
            var isDataAvailable = MetricTypes.IsServerRelated(this.model.selectedMetric)
                ? this.model.servers.length
                : this.model.apps.length;
            if (isDataAvailable) {
                this.model.selectedItem = MetricTypes.IsServerRelated(this.model.selectedMetric)
                    ? this.model.servers[0].id
                    : this.model.apps[0].id;
            }
        }
        var items = _.union(this.model.apps, this.model.servers);
        _.each(items, function(app) {
            var fullName = app["full-name"];
            app.id = fullName;
            app.color = App.request("get:color:byalias", fullName);
        });
        this.appCollection = new Backbone.Collection(this.model.apps);
        this.serverCollection = new Backbone.Collection(this.model.servers);
        this.visiblePointsCount = 180;
        if (this.listView) {
            this.listView.collection.set(
                MetricTypes.IsServerRelated(this.model.selectedMetric)
                    ? this.serverCollection.models
                    : this.appCollection.models
            );
            this.listView.render();
        }
    }
});

Metrics.EmptyAppsView = Backbone.Marionette.ItemView.extend({
    className: "appsMsgBlock",
    template: _.template('<div class="appsMsg"><h1>No Apps are loaded currently</h1></div>')
});

export default Metrics;
