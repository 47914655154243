import React from "react";
import { StriimMessageBox, StriimButton, StriimTypography } from "@striim/striim-ui-v2";
import { Box, SvgIcon } from "@mui/material";
import { styles } from "./insights-info-box.styles";
import JobInsightsChart from "../job-insights-chart/job-insights-chart";

interface InsightsInfoBoxInterface {
    label: string;
    value: string | number;
    subValue?: string;
    Icon: React.ReactNode;
    chartData?: {
        sensitiveData: number;
        nonSensitiveData: number;
        errors?: number;
        totalApps?: number;
        totalSources?: number;
    };
}

const InsightsInfoBox = ({ label, value, subValue, Icon, chartData }: InsightsInfoBoxInterface) => {
    return (
        <Box sx={styles.infoBox} p={2}>
            <Box sx={styles.leftColumn} data-testid={label.toLowerCase().replaceAll(" ", "")}>
                <StriimTypography variant="caption" color="greyscale.800">
                    {label}
                </StriimTypography>

                <Box sx={styles.valueContainer}>
                    <SvgIcon sx={styles.icon}>
                        <Icon />
                    </SvgIcon>

                    <Box sx={styles.values} data-testid={`value-${label.toLowerCase().replaceAll(" ", "")}`}>
                        <StriimTypography variant="body2" sx={styles.value}>
                            {value}
                        </StriimTypography>

                        {subValue && (
                            <StriimTypography variant="body3" sx={styles.subValue}>
                                {subValue}
                            </StriimTypography>
                        )}
                    </Box>
                </Box>
            </Box>

            {chartData && (
                <Box sx={styles.rightColumn}>
                    <JobInsightsChart
                        data={chartData}
                        shortNames={true}
                        barSize={10}
                        barWidth={290}
                        legendAlignment="top"
                        customLegendStyles={{ left: 40, top: 0 }}
                        customBarChartMargins={{ left: 40, top: 0 }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default InsightsInfoBox;
