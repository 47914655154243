import React from "react";
import { StriimTypography, StriimLink, StriimGradientChip, StriimMessageBox } from "@striim/striim-ui";
import { Box, Grid } from "@mui/material";

import { styles } from "../../../guardian.styles";
import SecurityOn from "/app/images/security_on.svg";
import NewTab from "app/images/New Tab.svg";
import dictionary from "../../../../../../../app/components/common/helpable/online-help-dictionary";
import { StriimAIEngine } from "../../../../hooks/useStriimAIEngine";

interface SentinelAIEmptyProps {
    isGlobalView?: boolean;
    isSelectedAppWithoutSentinel?: boolean;
    striimAIEngine?: StriimAIEngine;
}

const SentinelAIEmpty = ({
    isGlobalView = true,
    isSelectedAppWithoutSentinel = false,
    striimAIEngine
}: SentinelAIEmptyProps) => {
    return (
        <>
            {striimAIEngine && striimAIEngine.isConfigured && !striimAIEngine.isConnected && (
                <StriimMessageBox
                    type="ERROR"
                    heading="Could not connect to Striim AI Service"
                    text={
                        <p>
                            AI Insights could not connect to Striim AI service. Please ensure that the AI service is up
                            and running. Click{" "}
                            <a
                                href={dictionary.get()["AI_INSIGHTS_ENGINE"].href}
                                target="_blank"
                                style={{ color: "white", textDecoration: "underline", fontWeight: "bold" }}
                            >
                                here
                            </a>{" "}
                            for simple steps for an initial easy configuration.
                            <pre>{striimAIEngine.connectivityErrorMessage}</pre>
                        </p>
                    }
                    sx={{ width: "50%" }}
                ></StriimMessageBox>
            )}
            <Grid container sx={{ ...styles.wrapper, ...(isGlobalView ? { pt: 0 } : {}) }} p={!isGlobalView ? 2 : 0}>
                <>
                    <SecurityOn style={{ ...styles.securityImage }} />

                    <Grid container width={isGlobalView ? "min-content" : "100%"}>
                        <Grid container sx={{ ...styles.wrapperEmpty }}>
                            <StriimGradientChip version="ai" style={{ marginRight: "auto" }} />

                            <StriimTypography
                                variant="body"
                                paragraph={true}
                                sx={{ ...styles.description, textAlign: "unset", mt: 1, mb: 3 }}
                            >
                                Add Sentinels in your {isGlobalView && !isSelectedAppWithoutSentinel ? "Apps" : "App"}{" "}
                                to detect and protect sensitive data in real-time.
                                <StriimLink
                                    href={dictionary.get()["AI_INSIGHTS_SENTINEL"].href}
                                    target="_blank"
                                    endIcon={<NewTab />}
                                    sx={styles.notConfiguredMessageLink}
                                >
                                    Learn more
                                </StriimLink>
                            </StriimTypography>
                        </Grid>
                        <Grid container flexDirection={"column"} gap={3} mb={3}>
                            <StriimTypography variant="caption1" color="greyscale.700" sx={styles.stepsContent}>
                                <Box bgcolor="greyscale.700" color="greyscale.50" sx={styles.stepCount}>
                                    1
                                </Box>
                                {isGlobalView
                                    ? isSelectedAppWithoutSentinel
                                        ? "Stop and add Sentinel components to your App"
                                        : "Add Sentinel components to your Apps"
                                    : "Add a Sentinel component to the App"}
                            </StriimTypography>
                            <StriimTypography variant="caption1" color="greyscale.700" sx={styles.stepsContent}>
                                <Box bgcolor="greyscale.700" color="greyscale.50" sx={styles.stepCount}>
                                    2
                                </Box>
                                Run the {isGlobalView && !isSelectedAppWithoutSentinel ? "Apps" : "App"}
                            </StriimTypography>
                            <StriimTypography variant="caption1" color="greyscale.700" sx={styles.stepsContent}>
                                <Box bgcolor="greyscale.700" color="greyscale.50" sx={styles.stepCount}>
                                    3
                                </Box>
                                Monitor sensitive data flowing through your{" "}
                                {isGlobalView && !isSelectedAppWithoutSentinel ? "apps" : "app"} and Sentinels’
                                real-time actions
                            </StriimTypography>
                        </Grid>
                        <StriimMessageBox
                            text={
                                <StriimTypography variant="caption3" color="greyscale.900">
                                    Sentinel uses AI engines that may sometimes misclassify the information.
                                </StriimTypography>
                            }
                            type="NOTIFICATION"
                        />
                    </Grid>
                </>
            </Grid>
        </>
    );
};

export default SentinelAIEmpty;
