import Backbone from "backbone";
import $ from "jquery";
import flowUtils from "../flowUtils";
import App from "app";
import { onDrop } from "app/components/common/drag-and-drop";

export default Backbone.Marionette.LayoutView.extend({
    setDragDropState: function(dragDropState) {
        this.$el.data("dndState", dragDropState);

        if (dragDropState === flowUtils.DRAG_DROP_STATE.INACTIVE) {
            this.$el.removeClass("dnd-active");
            this.$el.removeClass("dnd-not-allowed");
            return;
        }

        if (dragDropState === flowUtils.DRAG_DROP_STATE.ACTIVE) {
            this.$el.addClass("dnd-active");
            return;
        }

        if (dragDropState === flowUtils.DRAG_DROP_STATE.NOT_ALLOWED) {
            this.$el.addClass("dnd-not-allowed");
        }
    },

    addClipboardListener: function() {
        this.listenTo(this.model, "change:isInClipboard", function() {
            this.$el.toggleClass("component-cut", this.model.isInClipboard === true && this.model.isCut === true);
        });
        this.$el.toggleClass("component-cut", this.model.isInClipboard === true && this.model.isCut === true);
    },

    setupDnd: function() {
        var _this = this;

        onDrop(
            "predefined-component",
            droppedElement => {
                _this.trigger("on-drag-drop", {
                    type: droppedElement.componentType,
                    name: droppedElement.name,
                    model: _this.model
                });
            },
            _this.$el[0]
        );

        this.$el
            .drop("start", function() {
                $(this).addClass("active");
            })
            .drop("end", function() {
                $(this).removeClass("active");
            })
            .drop(function(source, context) {
                // empty view has special empty node
                if (_this.model.metaObject.type === "empty-node-model") {
                    return;
                }

                // selection is also a Drag-and-drop functionality
                var isSelection = $(context.proxy).data("type") === "selection";
                if (isSelection) {
                    _this.model.isSelected = true;
                    return;
                }
            });
    },

    onNodeClick: function(e) {
        e.stopPropagation();
        e.preventDefault();
        App.vent.trigger("metadata-browser:close");

        if (this._isMoving || this.model.isMissing) {
            return;
        }

        this.trigger("select-single-node", this.model);
    },

    onConnectNewNode: function(e) {
        e.stopPropagation();
        e.preventDefault();

        this.trigger("connect-new-node");
    },

    setRecentDataRate: function() {
        if (!this.ui.counter.html) {
            this.$el.find(this.ui.counter).html("---");
            return;
        }

        // statistics not assigned
        if (this.model.recentDataRate === -1) {
            this.ui.counter.html("");
            this.ui.counter.attr("title", "");
            return;
        }

        // server returned empty value for statistics
        if (typeof this.model.recentDataRate === "undefined" || this.model.recentDataRate === null) {
            this.ui.counter.html("Fetching…");
            this.ui.counter.attr("title", "Server statistics not available yet");
            return;
        }

        var message = this.model.recentDataRate + " records/s";

        if (this.model.additionalStatisticsInfo) {
            message = this.model.additionalStatisticsInfo + ", " + message;
        }

        this.ui.counter.html(message);
        this.ui.counter.attr("title", "");
    },

    toggleRecentDataVisibility: function() {},

    setConnectNewNodeVisibile: function() {},

    setNodeStates: function() {
        if (this.model.metaObject.metaInfoStatus) {
            this.$el.toggleClass("invalid", !this.model.metaObject.metaInfoStatus.isValid);
        }
        this.$el.toggleClass("external", this.model.isExternal);
        this.$el.toggleClass("missing", this.model.isMissing);
    },

    // true when move event was executed after mouseDown event
    _isMoving: false,

    // element position saved when mouseDown event was executed
    _savedPosition: null,

    onNodeMouseDown: function() {
        this._isMoving = false;
        this._savedPosition = this.$el.position();
    },

    onNodeMouseUp: function() {
        if (!this._isMoving) {
            return;
        }
        var position = this.$el.position();

        if (!this._isNodePositionChanged(position)) {
            return;
        }

        position.left = position.left - this._savedPosition.left;
        position.top = position.top - this._savedPosition.top;
        this.trigger("layout-position-changed", position);
    },

    _isNodePositionChanged: function(currentPosition) {
        if (!currentPosition || !this._savedPosition) {
            return;
        }
        if (currentPosition.top !== this._savedPosition.top || currentPosition.left !== this._savedPosition.left) {
            return true;
        }

        return false;
    },

    onNodeMouseMove: function() {
        if (this._isMoving) {
            return;
        }

        var position = this.$el.position();

        if (this._isNodePositionChanged(position)) {
            this._isMoving = true;
        }
    },

    onRender: function() {
        this.$el.attr("id", this.model.elementId);
        this.setNodeStates();
    }
});
