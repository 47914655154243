import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, SvgIcon } from "@mui/material";
import { StriimTypography, StriimInputField, StriimTooltip } from "@striim/striim-ui";
import ActionsOnEntitiesTable, { MaskingOption, TableData } from "./actions-on-entities-table";
import { OutputShieldEntityValidType } from "../../../panel/sentinel-panel-container";
import { InfoCircle } from "../../../../../../generic/icon/customIcons";
import styles from "./entity.styles";

export type Entity = {
    name: string;
    id?: string;
    fields: TableData[];
};

type EntityComponentProps = {
    entity: Entity;
    isDisabled: boolean;
    onNameChange: (newName: string) => void;
    updateFields: Function;
    allEntityNames: string[];
    isSidebarExpanded: boolean;
    maskingOptions: MaskingOption[];
    setIsOutputShieldOrEntityValid: Dispatch<SetStateAction<OutputShieldEntityValidType>>;
    hasError: boolean;
    setHasError: (hasError: boolean) => void;
    savedEntity: Entity;
};

const EntityComponent: React.FC<EntityComponentProps> = ({
    entity,
    isDisabled = false,
    onNameChange,
    updateFields,
    allEntityNames,
    isSidebarExpanded = false,
    maskingOptions,
    setIsOutputShieldOrEntityValid,
    hasError,
    setHasError,
    savedEntity
}) => {
    const [entityName, setEntityName] = useState(entity.name);
    const [errorMessage, setErrorMessage] = useState("");
    const uniqueErrorMessage = "Entity name must be unique";
    const emptyErrorMessage = "This is a required field";

    useEffect(() => {
        setEntityName(entity.name);
    }, [entity]);

    useEffect(() => {
        if (hasError) {
            setErrorMessage(entityName.trim() ? uniqueErrorMessage : emptyErrorMessage);
        } else {
            setErrorMessage("");
        }
    }, [hasError, entityName]);

    const handleNameChange = (val: string) => {
        const isEmpty = !val.trim().length;
        const isDuplicate = allEntityNames.includes(val) && val !== entity.name;

        setHasError(isEmpty || isDuplicate);
        setErrorMessage(isEmpty ? emptyErrorMessage : isDuplicate ? uniqueErrorMessage : "");
        setIsOutputShieldOrEntityValid(prev => ({ ...prev, isEntityNameValid: !isEmpty && !isDuplicate }));

        setEntityName(val);
        onNameChange(val);
    };

    return (
        <Box>
            <Box display={"flex"} alignItems={"center"}>
                <StriimTypography variant="caption3" color="greyscale.600">
                    Entity Name
                </StriimTypography>
                <StriimTypography variant="caption3" color="critical.500">
                    *
                </StriimTypography>
                <StriimTooltip
                    title={
                        'Provide the fully qualified name of the entity in the source. For example, "SchemaName.TableName" for a relational database, "DatabaseName.CollectionName” for a NoSQL database, "Directory” for a cloud object storage service, or "Topic-Partition” for Kafka events.'
                    }
                    placement="top"
                >
                    <Box pl="2px">
                        <SvgIcon component={InfoCircle} sx={styles.infoIcon} />
                    </Box>
                </StriimTooltip>
            </Box>
            <StriimInputField
                id="entity-name-input"
                value={entityName}
                disabled={isDisabled}
                onChange={handleNameChange}
                onBlur={() => {
                    if (!entityName.trim()) {
                        setHasError(true);
                        setErrorMessage("This is a required field");
                    }
                }}
                error={hasError}
                helperText={errorMessage}
            />
            <ActionsOnEntitiesTable
                data={entity.fields}
                isDisabled={isDisabled}
                updateFields={updateFields}
                isSelectable={isSidebarExpanded}
                maskingOptions={maskingOptions}
                savedEntityFields={savedEntity?.fields || []}
            />
        </Box>
    );
};

export default EntityComponent;
