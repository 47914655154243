import _ from "underscore";
import NestedTypes from "backbone.nestedtypes";

const utils = {};

utils.DRAG_DROP_STATE = {
    INACTIVE: 1,
    ACTIVE: 2,
    NOT_ALLOWED: 3,
};

// when id contains dot charaters,
// then it's difficult to find element byId
utils.sanitizeElementId = function (id) {
    var result = id.replace(/\./g, "_");
    result = result.replace(/ /g, "_");
    return result;
};

utils.serializeModelToJson = function (model) {
    var json = NestedTypes.Model.prototype.toJSON.apply(model, arguments);

    _.each(
        model.properties,
        function (dontUse, name) {
            var value = model[name];
            json[name] = value && value.toJSON ? value.toJSON() : value;
        },
        this
    );

    return json;
};

export default utils;
