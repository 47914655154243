import React from "react";
import { StriimChip } from "@striim/striim-ui";
import useStyles from "./app-status-indicators.styles";
import classNames from "classnames";
import { Box, LinearProgress } from "@material-ui/core";
import { StateToChipVariant } from "../../../../../../../../status-management";
import { useTheme } from "@mui/material";

function formatLabel(label) {
    return label
        ?.split(" ")
        ?.join("_")
        ?.toLowerCase();
}

const AppStatusChip = ({ label, hideLabel }) => {
    const formattedLabel = formatLabel(label);
    return (
        <StriimChip
            variant={StateToChipVariant[formattedLabel] || "default"}
            label={label}
            hideLabel={hideLabel}
            data-testid={"apptile--status-" + (label != undefined ? label.toUpperCase() : "unknown") + "-indicator"}
        />
    );
};

const TileStatusIndicator = ({ label }) => {
    const theme5 = useTheme();
    const classes = useStyles(theme5);
    const formattedLabel = formatLabel(label);
    return <Box className={`${classes.appTileBorder} appStatus-${formattedLabel}`}></Box>;
};

const GridStatusIndicator = ({ label, isLoading }) => {
    const theme5 = useTheme();
    const classes = useStyles(theme5);
    const formattedLabel = formatLabel(label);
    return isLoading ? (
        <Box className={classes.wideModeBorder}>
            <LinearProgress />
        </Box>
    ) : (
        <Box className={classNames(classes.appTileBorder, classes.wideModeBorder, `appStatus-${formattedLabel}`)}></Box>
    );
};

const AppTileWideStatusIndicator = ({ label }) => {
    const theme5 = useTheme();
    const classes = useStyles(theme5);
    const formattedLabel = formatLabel(label);
    return (
        <Box className={classNames(classes.appTileWideStatus, classes.wideTileStatus, `appStatus-${formattedLabel}`)} />
    );
};

export { AppStatusChip, TileStatusIndicator, GridStatusIndicator, AppTileWideStatusIndicator };
