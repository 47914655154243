import React, { useState } from "react";
import { StriimButton, StriimModal, StriimTypography } from "@striim/striim-ui";
import WithTooltip from "src/generic/tooltip/tooltip";
import Dictionary from "app/components/common/helpable/online-help-dictionary";
import { makeStyles } from "@material-ui/core/styles";
import ManageStriimService from "../manage-striim-service";
import { Eye, Download, Delete } from "src/generic/icon/customIcons";
import CSVIcon from "src/generic/icon/manage-striim/CSV.svg";
import XLSIcon from "src/generic/icon/manage-striim/XLS.svg";
import LOGIcon from "src/generic/icon/manage-striim/LOG.svg";
import TQLIcon from "src/generic/icon/manage-striim/TQL.svg";
import JARIcon from "src/generic/icon/manage-striim/JAR.svg";
import SRMIcon from "src/generic/icon/manage-striim/SRM.svg";
import JSONIcon from "src/generic/icon/manage-striim/JSON.svg";
import DefaultFileIcon from "src/generic/icon/manage-striim/OTHER.svg";
import App from "app";
import { SvgIcon, Grid } from "@mui/material";
import { StriimIconButton } from "@striim/striim-ui-v2";

const useStyles = makeStyles(({ spacing }) => ({
    card: {
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "default"
    },
    buttons: {
        display: "flex",
        gap: "5px"
    },
    fileName: {
        display: "flex",
        alignItems: "center",
        fontWeight: 600
    },
    fileIcon: {
        marginRight: spacing(3),
        height: 32,
        width: 32,
        fill: "none"
    },
    defaultFileIcon: {
        marginRight: spacing(2.5),
        height: 32,
        width: 41.33,
        fill: "none"
    },
    tooltipLink: {
        marginInline: 4
    }
}));

const styles = {
    card: {
        px: 3,
        py: 1
    },
    fileIcon: {
        "& > path": {
            stroke: ({ palette }) => palette.secondary[500]
        }
    }
};

const NotSupportedTooltip = ({ classes }) => {
    return (
        <div>
            Striim does not support web-based preview for JSON or XML files. Please refer to the documentation for
            examples on using the{" "}
            <a className={classes.tooltipLink} href={Dictionary.get()["PROPERTYTEMPLATE_JSONParser"].href}>
                JSONParser
            </a>
            {` and `}
            <a className={classes.tooltipLink} href={Dictionary.get()["PROPERTYTEMPLATE_XMLParser"].href}>
                XMLParser
            </a>{" "}
            in TQL
        </div>
    );
};

const FileSection = ({ file, fetchData, selectFile }) => {
    const classes = useStyles();
    const [showConfirm, setShowConfirm] = useState(false);

    const getIcon = (name = "") => {
        const extension = name
            .toLowerCase()
            .split(".")
            .pop();
        let IconComponent;
        let applyDefaultClass = true;
        switch (extension) {
            case "csv":
                IconComponent = CSVIcon;
                break;
            case "xls":
                IconComponent = XLSIcon;
                break;
            case "log":
                IconComponent = LOGIcon;
                break;
            case "tql":
                IconComponent = TQLIcon;
                break;
            case "jar":
                IconComponent = JARIcon;
                break;
            case "srm":
                IconComponent = SRMIcon;
                break;
            case "json":
                IconComponent = JSONIcon;
                break;
            default:
                IconComponent = DefaultFileIcon;
                applyDefaultClass = false;
        }

        return (
            <SvgIcon
                component={IconComponent}
                className={applyDefaultClass ? classes.fileIcon : classes.defaultFileIcon}
                sx={styles.fileIcon}
            />
        );
    };

    const isSupported = name => {
        const type = name
            .toLowerCase()
            .split(".")
            .pop();

        switch (type) {
            case "json":
            case "xml":
                return false;
            default:
                return true;
        }
    };

    const notSupported = !isSupported(file.name);

    return (
        <Grid container sx={styles.card} className={classes.card} data-test-id={`file-section-${file.name}`}>
            <Grid item className={classes.fileName} data-test-id="file-icon">
                {getIcon(file.name)}
                <StriimTypography color="greyscale.900" variant="body4" data-test-id="file-name">
                    {file.name}
                </StriimTypography>
            </Grid>
            <Grid item className={classes.buttons}>
                {!selectFile && (
                    <>
                        {WithTooltip(
                            <StriimIconButton
                                onClick={() => {
                                    App.navigate("#/preview/FileReader?" + encodeURIComponent(file.currentPath), {
                                        trigger: true
                                    });
                                }}
                                data-test-id="preview-file"
                                disabled={notSupported}
                            >
                                <SvgIcon
                                    component={Eye}
                                    sx={({ palette }) => ({
                                        fill: "none",
                                        "& > path": {
                                            stroke: palette.greyscale[900]
                                        }
                                    })}
                                />
                            </StriimIconButton>,
                            notSupported ? <NotSupportedTooltip classes={classes} /> : "",
                            true
                        )}
                        <StriimIconButton
                            onClick={() => ManageStriimService.downloadFile(file.currentPath)}
                            data-test-id="download-file"
                        >
                            <SvgIcon
                                component={Download}
                                sx={({ palette }) => ({
                                    fill: "none",
                                    "& > path": {
                                        stroke: palette.greyscale[900]
                                    }
                                })}
                            />
                        </StriimIconButton>
                        <StriimIconButton
                            onClick={() => {
                                setShowConfirm(true);
                            }}
                            data-test-id="delete-file"
                        >
                            <SvgIcon
                                component={Delete}
                                sx={({ palette }) => ({
                                    fill: "none",
                                    "& > g": {
                                        stroke: palette.critical[500]
                                    }
                                })}
                            />
                        </StriimIconButton>
                    </>
                )}

                {selectFile && (
                    <StriimButton
                        variant="secondaryText"
                        onClick={() => {
                            selectFile(file);
                        }}
                        data-test-id="select-file"
                    >
                        Select File
                    </StriimButton>
                )}

                <StriimModal
                    size="small"
                    autoHeight
                    isVisible={showConfirm}
                    onConfirm={async () => {
                        setShowConfirm(false);
                        await ManageStriimService.deleteFile(file.currentPath);
                        await fetchData();
                    }}
                    onCancel={() => {
                        setShowConfirm(false);
                    }}
                    variant="error"
                    confirmContent={"Delete"}
                    titleContent={<StriimTypography variant={"h3"}>Please confirm this operation</StriimTypography>}
                    confirmButtonProps={{ "data-test-id": "delete-file-button-modal" }}
                >
                    <StriimTypography>
                        Are you sure you want to delete <b>{file.name}</b> ?
                    </StriimTypography>
                </StriimModal>
            </Grid>
        </Grid>
    );
};

export default FileSection;
