import React, { useRef, useCallback, useMemo, useEffect } from "react";
import { StriimDataTable } from "@striim/striim-ui";
import { Box, Grid, SvgIcon } from "@mui/material";
import { StriimChip, StriimTypography } from "@striim/striim-ui-v2";
import { StateToChipVariant } from "../../../../status-management";
import SourceTargetList from "../../../../source-target-list/source-target-list";
import { observer } from "mobx-react";
import { AppIcon, InfoEdit } from "../../../../generic/icon/customIcons";
import { styles } from "./table-filters.styles";
import WithTooltip from "../../../../generic/tooltip/tooltip";
import utils from "core/utils";

interface AppNameContainerProps {
    name: string;
    status: string | null;
    nsName?: string;
    onClick?: (appFQN: string) => void;
    isAppAvailable?: boolean;
}

interface SourceTargetProps {
    data: AppProps;
}

interface SelectTablesInterface {
    selectable: boolean;
    apps: AppProps[];
    selectedRows: AppProps[];
    tableData: AppProps[];
    setSelectedRows: React.Dispatch<React.SetStateAction<AppProps[]>>;
    goToEdit: () => void;
    showEditAppsIcon?: boolean;
}

export const AppNameContainer: React.FC<AppNameContainerProps> = ({
    name,
    status,
    nsName,
    onClick,
    isAppAvailable = true
}) => {
    status = status === "HALT" ? "HALTED" : status;
    return (
        <Grid container sx={styles.appNameContainer} gap={0.5}>
            {WithTooltip(
                <StriimChip variant={StateToChipVariant[status?.toLowerCase()] || "default"} />,
                status ? `App status: ${utils.capitalize(status)}` : ""
            )}
            <Box sx={styles.appNameWrapper}>
                {WithTooltip(
                    <StriimTypography
                        variant="body4"
                        color={isAppAvailable ? `secondary.700` : "greyscale.700"}
                        onClick={() => {
                            isAppAvailable && onClick && onClick(`${nsName}.${name}`);
                        }}
                        sx={styles.appName}
                    >
                        {name}
                    </StriimTypography>,
                    !isAppAvailable ? "No data available to display since the App was deleted" : name
                )}
            </Box>
        </Grid>
    );
};

// Remove Apps with 0 sources
export const filterApps = apps => apps.filter(app => app.sources.length);

export const SourceTarget: React.FC<SourceTargetProps> = observer(({ data: app }) => (
    <SourceTargetList
        sources={app?.sources}
        targets={app?.targets}
        appId={app?.id}
        maxSources={1}
        maxTargets={1}
        iconSize={24}
    />
));

const SelectTables = ({
    selectable,
    apps,
    tableData,
    selectedRows,
    setSelectedRows,
    goToEdit,
    showEditAppsIcon = true
}: SelectTablesInterface) => {
    const gridRef = useRef<any>(null);

    useEffect(() => {
        if (gridRef.current && gridRef.current.api && initialState.length > 0) {
            gridRef.current.api.forEachNode(node => {
                if (initialState.includes(node.data.id)) {
                    node.setSelected(true);
                }
            });
        }
    }, [selectedRows]);

    const initialState = useMemo(() => selectedRows.map(row => row.id), [selectedRows]);

    const onGridReady = useCallback(
        params => {
            if (initialState.length > 0) {
                params.api.forEachNode(node => {
                    if (initialState.includes(node.data.id)) {
                        node.setSelected(true);
                    }
                });
            }
        },
        [initialState]
    );

    const onSelectionChanged = useCallback(() => {
        const selectedRows = gridRef.current?.api?.getSelectedRows() as AppProps[];
        let filteredRows = filterApps(selectedRows);
        setSelectedRows(filteredRows);
    }, [setSelectedRows]);

    const tableColumns = useMemo(
        () => [
            {
                headerName: selectable
                    ? `App Name (${selectedRows.length}/${tableData.length} selected)`
                    : `App Name (${tableData.length})`,
                field: "name",
                flex: 1,
                suppressMovable: true,
                resizable: true,
                cellRenderer: value => <AppNameContainer name={value.data.name} status={value.data.flowStatus} />,
                cellClass: "appNamecellClass",
                headerCheckboxSelection: selectable,
                checkboxSelection: selectable ? value => value.data.sources.length : false
            },
            {
                headerName: "Namespace",
                field: "nsName",
                suppressMovable: true,
                resizable: true,
                cellRenderer: ({ value }) => (
                    <StriimTypography variant="body4" color="greyscale.700">
                        {value}
                    </StriimTypography>
                )
            },
            {
                headerName: "Source-Target",
                field: "sourcetarget",
                suppressMovable: true,
                headerClass: "header-center no-hover",
                cellClass: "cell-center",
                resizable: true,
                cellRenderer: SourceTarget
            }
        ],
        [selectedRows, apps, tableData]
    );

    return (
        <Grid gap={1} display="grid">
            {!selectable && (
                <Grid container alignItems="center" gap="10px" mb={0.5}>
                    <Box sx={styles.reviewHeader}>
                        <SvgIcon component={AppIcon} sx={styles.actionIcon} />
                        <StriimTypography variant="h4">List Of Apps Selected</StriimTypography>
                    </Box>
                    {showEditAppsIcon && <SvgIcon component={InfoEdit} sx={styles.editIcon} onClick={goToEdit} />}
                </Grid>
            )}

            <Box sx={styles.dataTableWrapper}>
                <StriimDataTable
                    ref={gridRef}
                    data={tableData}
                    columns={tableColumns}
                    onSelectionChanged={onSelectionChanged}
                    onGridReady={onGridReady}
                    maxHeight="100%"
                    defaultColDef={{
                        tooltipValueGetter: value =>
                            value.data.sources.length
                                ? null
                                : "Add at least one Source to your App to discover sensitive data."
                    }}
                />
            </Box>
        </Grid>
    );
};

export default SelectTables;
