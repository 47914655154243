import React, { useEffect, useState } from "react";
import connectionProfileService from "../../../apps/pages/manage-striim/connection-profiles/connection-profile-service";
import growl from "../../../../../app/components/common/growl";

type formDataType = Record<string, any>;

const useConnectionProfileData = (formdata: formDataType): formDataType => {
    const [connectionProfileData, setConnectionProfileData] = useState<Record<string, any>>();
    let { connectionProfileName, externalStageConnectionProfileName } = formdata;

    useEffect(() => {
        (async () => {
            try {
                if (!connectionProfileName) {
                    setConnectionProfileData({});
                    return;
                }
                const connectionProfileDetails = await connectionProfileService.getConnectionProfile(
                    connectionProfileName
                );
                let externalStageDetails = null;
                if (externalStageConnectionProfileName && externalStageConnectionProfileName !== "") {
                    externalStageDetails = await connectionProfileService.getConnectionProfile(
                        externalStageConnectionProfileName
                    );
                }

                setConnectionProfileData({
                    connectionDetails: connectionProfileDetails.get("properties"),
                    externalStageDetails: externalStageDetails?.get("properties")
                });
            } catch (error) {
                growl.error("Error while fetching connection profile details", JSON.stringify(error));
            }
        })();
    }, []);

    return connectionProfileData;
};

export default useConnectionProfileData;
