import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { CardContent, Grid, SvgIcon } from "@mui/material";
import { StriimButton, StriimTypography, StriimButtonGroupSimple } from "@striim/striim-ui";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";
import { useNavigate } from "react-router";

import { styles } from "./sensitive-data-discovery-tab.styles";
import { JobsListTable } from "./components";
import useStores from "../../../../utils/use-stores";
import { Job, JobMetrics, JobStatuses } from "../guardian-job.types";
import guardianService from "../guardian-service";
import growl from "../../../../../app/components/common/growl";
import LoadingIndicator from "../../../../generic/loading-indicator";
import GuardianEmpty from "../components/guardian-empty";
import JobAppsTable from "../components/job-apps-table/job-apps-table";
import GuardianTabsContext from "../guardian-tabs-context";
import AI_ROUTES from "../../routes.json";
import { StriimLink } from "@striim/striim-ui-v2";
import { Documentation } from "../../../../generic/icon/customIcons";
import dictionary from "../../../../../app/components/common/helpable/online-help-dictionary";
import useStriimAIEngine from "../../hooks/useStriimAIEngine";

const TABS = {
    GROUPS: "Groups",
    APPS: "Apps"
};

type JobsData =
    | (Job & {
          name: string;
          apps: [];
          totalApps: number;
      })[]
    | [];

const appSort = (a, b) => (a.id.toLowerCase() > b.id.toLowerCase() ? 1 : -1);

const DiscoveryTab = _props => {
    const [jobs, setJobs] = useState<JobsData>([]);
    const [appsJobList, setAppsJobList] = useState<JobsData>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedTab, setSelectedTab] = useState(Object.values(TABS)[0]);
    const [apps, setApps] = useState<AppProps[]>([]);
    const [tableData, setTableData] = useState<AppProps[]>(apps);
    const striimAIEngine = useStriimAIEngine();
    const [tabHeight, setTabHeight] = useState<number | string>(0);

    const parentRef = useRef(null);
    const intervalIDRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const { store } = useStores();
    const navigate = useNavigate();
    const context = useContext(GuardianTabsContext);
    const { isAdmin, tablesData, maskingOptions } = context;

    useEffect(() => {
        let hasError = false,
            isPending = true,
            hasCompleted = false;

        (async () => {
            const getJobsDetails = async () => {
                try {
                    const completedJobsResponse = await guardianService.getReportsOfAllAppsForCurrentUser();
                    let completedJobs = [];
                    try {
                        completedJobs = JSON.parse(completedJobsResponse);
                    } catch (error) {
                        console.error(error);
                    }
                    const AIAgents = await guardianService.getAIAgentsList();
                    const updatedAIAgents = [];
                    AIAgents.forEach(agent => {
                        if (agent.hasOwnProperty("noField")) return null;

                        let appsWithPII = new Set();
                        let updatedAgent = { ...agent, appsWithPII: 0 };

                        const completedJobInfo = completedJobs.find(
                            job => job.jobName === `${agent.nsName}.${agent.name}`
                        );
                        const completedJobsList = completedJobs.filter(
                            job => job.jobName === `${agent.nsName}.${agent.name}`
                        );
                        if (completedJobsList?.length) {
                            appsWithPII = completedJobsList.reduce((accumulated, current) => {
                                let piiData = {};
                                try {
                                    piiData = JSON.parse(current.piiData);
                                } catch (error) {
                                    console.error(error);
                                }
                                if (Object.keys(piiData)?.length) {
                                    const entities = Object.keys(piiData);
                                    const hasPIIData = entities.some(entity => {
                                        if (piiData[entity]) {
                                            let entityPIIData = {};
                                            try {
                                                entityPIIData = JSON.parse(piiData[entity]);
                                            } catch (error) {
                                                console.error(error);
                                            }
                                            return entityPIIData["Number Of PII Fields"];
                                        }
                                        return false;
                                    });
                                    if (hasPIIData) {
                                        accumulated.add(current.appName);
                                    }
                                }
                                return accumulated;
                            }, new Set());
                        }

                        if (agent.status === JobStatuses.COMPLETED) {
                            updatedAgent = { ...updatedAgent, ...completedJobInfo };
                        } else if (completedJobInfo?.generationTime) {
                            updatedAgent.generationTime = completedJobInfo.generationTime;
                        }

                        if (completedJobInfo?.appName) {
                            updatedAgent.appName = completedJobInfo.appName;
                        }

                        updatedAgent.status = agent.status;
                        updatedAgent.jobName = `${agent.nsName}.${agent.name}`;
                        updatedAgent.appsWithPII = appsWithPII.size;

                        updatedAIAgents.push(updatedAgent);
                    });

                    const formattedResult: JobsData = updatedAIAgents.reduce((accumulated, current: Job) => {
                        let piiData = {};
                        let metrics: JobMetrics = { sensitiveEntities: 0, totalEntities: 0, sensitiveIdentifiers: [] };

                        try {
                            piiData = JSON.parse(current.piiData);
                            metrics = JSON.parse(current.metrics);
                        } catch (error) {
                            console.log(error);
                        }

                        const currentStatus = [JobStatuses.ERROR, JobStatuses.CREATED].includes(
                            accumulated[current.jobName]?.status
                        )
                            ? accumulated[current.jobName]?.status
                            : current.status;

                        accumulated[current.jobName] = {
                            name: current.jobName,
                            appName: current.appName,
                            decription: current.description,
                            sourceName: current.sourceName,
                            piiData: current.piiData,
                            metrics: current.metrics,
                            status: currentStatus,
                            generationTime: Number(current.generationTime) / 1000,
                            completedTime: Number(current.completedTime) / 1000,
                            apps: current.appsWithPII,
                            totalApps:
                                (accumulated[current.jobName]?.totalApps || 0) + (current?.applications?.length || 0)
                        };
                        return accumulated;
                    }, []);

                    const formattedAppReportResult: JobsData = completedJobs.reduce((accumulated, current: Job) => {
                        let piiData = {};
                        let metrics: JobMetrics = { sensitiveEntities: 0, totalEntities: 0, sensitiveIdentifiers: [] };

                        try {
                            piiData = JSON.parse(current.piiData);
                            metrics = JSON.parse(current.metrics);
                        } catch (error) {
                            console.log(error);
                        }

                        if (
                            accumulated[current.appName] &&
                            accumulated[current.appName].generationTime > Number(current.generationTime) / 1000
                        )
                            return accumulated;

                        accumulated[current.appName] = {
                            name: current.jobName,
                            appName: current.appName,
                            decription: current.description,
                            sourceName: current.sourceName,
                            piiData: current.piiData,
                            metrics: current.metrics,
                            status: current.status,
                            generationTime: Number(current.generationTime) / 1000,
                            completedTime: Number(current.completedTime) / 1000,
                            apps: current.appsWithPII,
                            totalApps:
                                (accumulated[current.jobName]?.totalApps || 0) + (current?.applications?.length || 0)
                        };
                        return accumulated;
                    }, []);

                    const jobsList = Object.values(formattedResult);
                    const appsList = Object.values(formattedAppReportResult || {});

                    hasError = jobsList.some(job => job.status === JobStatuses.ERROR);
                    isPending = jobsList.some(job => [JobStatuses.RUNNING].includes(job.status));
                    hasCompleted = !hasError && !isPending;

                    setJobs(jobsList);
                    setAppsJobList(appsList);
                } catch (error) {
                    growl.error(error);
                } finally {
                    setLoading(false);
                }
            };

            getJobsDetails();

            intervalIDRef.current = setInterval(() => {
                isPending ? getJobsDetails() : intervalIDRef?.current && clearInterval(intervalIDRef.current);
            }, 5000);
        })();

        return () => {
            intervalIDRef?.current && clearInterval(intervalIDRef.current);
        };
    }, [striimAIEngine]);

    const appsData = useMemo(
        () =>
            appsJobList.reduce((acc, curr) => {
                let result = {};
                let piiData = {};
                let metrics: JobMetrics = { sensitiveEntities: 0, totalEntities: 0, sensitiveIdentifiers: [] };
                try {
                    piiData = JSON.parse(curr.piiData);
                    metrics = !!curr.metrics ? JSON.parse(curr.metrics) : metrics;
                } catch (error) {
                    console.log(error);
                }
                const appName = curr.appName;
                result.appName = appName;
                result["jobStatus"] = {
                    status: curr.status,
                    generationTime: Number(curr.generationTime),
                    completedTime: Number(curr?.completedTime || 0),
                    errorMessage: curr.otherDetails
                };
                result["entitiesWithSensitiveData"] = {
                    completed: metrics?.sensitiveEntities,
                    total: metrics?.totalEntities
                };
                result["piiData"] = curr.piiData;
                acc[appName] = result;
                return acc;
            }, {}),
        [appsJobList]
    );

    useEffect(() => {
        const fetchData = async () => {
            await store.fetchApps();
            await store.getAppListTableStats();
            setApps(store.apps.sort(appSort));
        };
        fetchData();
    }, [store]);

    useEffect(() => {
        const appNames = Object.keys(appsData);
        const appsList = appNames.map(app => {
            let [appNamespace, appName] = app.split(".");
            const item = appsData[app];
            const appDetails = apps?.find(item => `${item.nsName}.${item.name}` === app);

            return {
                ...(appDetails ? appDetails : { name: appName, nsName: appNamespace }),
                id: item.appName,
                jobStatus: item.jobStatus,
                status: item.status,
                entitiesWithSensitiveData: item.entitiesWithSensitiveData,
                piiData: item.piiData,
                isAppAvailable: !!appDetails
            };
        });

        setTableData(appsList.sort(appSort));
    }, [apps, appsData]);

    useEffect(() => {
        if (parentRef?.current) {
            setTabHeight(parentRef.current?.offsetHeight - 180 || "100%");
        }
    }, [parentRef?.current]);

    const SDITabs = () => {
        return (
            <StriimButtonGroupSimple
                handleChange={handleChange}
                tabs={Object.values(TABS)}
                selectedTab={selectedTab}
                activeBtnStyles={styles.buttonGroupButton}
                inactiveBtnStyles={styles.buttonGroupButton}
            />
        );
    };

    const handleChange = value => {
        setSelectedTab(value);
    };

    const navigateToSelectApps = () => navigate(AI_ROUTES.selectApps);

    return loading ? (
        <LoadingIndicator isGlobal={false} />
    ) : jobs.length ? (
        <CardContent sx={styles.cardContent} ref={parentRef}>
            <Grid sx={styles.jobsList}>
                <Grid item display="flex" sx={styles.jobsListHeading}>
                    <StriimTypography variant="body3" color="greyscale.700">
                        Discover sensitive data identifiers that can flow through your apps by examining the sources.
                        <StriimLink
                            endIcon={<SvgIcon component={Documentation} sx={styles.docIcon} />}
                            href={dictionary.get()["AI_INSIGHTS_SHERLOCK"].href}
                            target="_blank"
                            sx={styles.link}
                        ></StriimLink>
                    </StriimTypography>

                    <StriimButton variant="primary" startIcon={<AddIcon />} onClick={navigateToSelectApps}>
                        Report
                    </StriimButton>
                </Grid>

                {selectedTab == TABS.GROUPS ? (
                    <JobsListTable data={jobs} customMaxHeight={tabHeight} tabs={<SDITabs />} />
                ) : (
                    <JobAppsTable
                        isJobAppsPage={true}
                        tableData={tableData}
                        customMaxHeight={tabHeight}
                        setShowSelectedErrorModal={() => {}}
                        setSelectedError={() => {}}
                        tabs={<SDITabs />}
                    />
                )}
            </Grid>
        </CardContent>
    ) : (
        <GuardianEmpty striimAIEngine={striimAIEngine} isAdmin={isAdmin} />
    );
};

export default DiscoveryTab;
