const setSessionStorageItem = (storageKey: string, storageValue: object | string) => {
    if (!storageValue || !storageKey) return;
    sessionStorage.setItem(storageKey, JSON.stringify(storageValue));
};

const getSessionStorageItem = (storageKey: string) => {
    try {
        return JSON.parse(sessionStorage.getItem(storageKey));
    } catch (e) {
        console.error("Unable to retrieve session storage item, Error : " + e);
        return null;
    }
};

const removeSessionStorageItem = (storageKey: string) => {
    sessionStorage.removeItem(storageKey);
};

const sessionStorageItemExists = (storageKey: string) => {
    return !!sessionStorage[storageKey];
};

export { setSessionStorageItem, getSessionStorageItem, removeSessionStorageItem, sessionStorageItemExists };
