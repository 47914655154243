export const HTMLregex = /<html>[\s\S]*?<\/html>/g;

// Remove <meta/> tag to avoid page refresh
const removeTags = res => {
    return res.replace(/(<(meta)[^>]*>)/gim, "");
};

// Remove HTML from the responce
export const removeHtmlResponse = res => {
    return res && res.replaceAll(/Response :<html>[\s\S]*?<\/html>/g, "Response : Please check exceptions for response");
};

// Converts html response string to html object
const string2Html = res => {
    const replacedText = removeTags(res);

    var responseObj = document.createElement("div");
    responseObj.setAttribute("class", "responseHtml");
    responseObj.innerHTML = replacedText;

    return responseObj.outerHTML;
};

// Conoverts response to a readable format
export function parseHtml(message, html = null) {
    message = message
        .replaceAll("More Details :", "<h4>More Details:</h4>")
        .replaceAll("Status :", "<h4>Status:</h4>")
        .replaceAll("Reason :", "<h4>Reason:</h4>");

    // Show HTML only on Exceptions tab
    if (html) {
        return message
            .replaceAll("Response :", "<h4>Response:</h4>")
            .replaceAll(HTMLregex, "<div class='htmlDiv'>" + string2Html(html) + "</div>");
    } else {
        return removeHtmlResponse(message).replaceAll("Response :", "<h4>Response:</h4>");
    }
}
