const styles = {
    footer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: ({ spacing }) => spacing(3),
        borderTop: ({ palette }) => `1px solid ${palette.greyscale["200"]}`
    },
    buttonContainer:{
        display:'flex'
    }
};

export default styles;
