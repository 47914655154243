import _ from "underscore";
import _chartCommonMixin from "./_chartCommonMixin";
import _chartRendererMixin from "./_chartRendererMixin";
import * as L from "leaflet";
import App from "app";

const leafletMixin = {};
_(leafletMixin).extend(_chartCommonMixin);
_(leafletMixin).extend(_chartRendererMixin);
_(leafletMixin).extend({
    renderChart: function () {
        this.renderIfNeeded(this.map, this.createNewMap, this.updateMap);
    },
    createNewMap: function () {
        this.destroyMap();

        this.map = this.initializeNewMap(
            this.$el.get(0),
            this.chartConfig.viewYOffset,
            this.chartConfig.viewXOffset,
            this.chartConfig.viewZoom
        );
        this.addTileLayer(this.map);
        this.addAreasLayers(this.map, this.chartConfig.areas);
        this.map.on("zoomend", () => {
            try {
                this.renderChart();
            } catch (error) {
                console.warn("Error rendering chart:", error);
            }
        });
        this.updateMap();
    },
    updateMap: function () {
        this.removePreviousMarkersLayerFromMap();
        this.addMarkersLayerFromChartConfig();

        if (this.chartConfig.areaColors) {
            this.removeAreaLayer();
            this.addAreasLayers(this.map, this.chartConfig.areas);
        }
    },
    destroyMap: function () {
        if (!this.chartConfig) {
            return console.warn("Destroy: Config malformed.");
        }
        if (this.map) {
            this.map.remove();
            this.map = null;
        }
    },
    initializeNewMap: function (target, latCenter, longCenter, zoom) {
        return L.map(target).setView(L.latLng(latCenter, longCenter), zoom, {
            zoomSnap: 0.25,
            zoomDelta: 0.1,
        });
    },
    addTileLayer: function (map) {
        L.tileLayer(this.chartConfig.tilesUrl, {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map);
    },
    addAreasLayers: function (map, areas) {
        if (areas.length === 0) {
            return;
        }

        const getAreaColor = (area) => {
            if (!area.ID || !this.chartConfig.areaColors) {
                return area.defaultColor;
            }

            return this.chartConfig.areaColors[area.ID] || area.defaultColor;
        };

        this.overlays = {};
        areas.forEach((area) => {
            const layerGroup = new L.LayerGroup();
            const areaColor = getAreaColor(area);
            area.coordinates.forEach((polyline) => {
                const coordinates = polyline.coordinate
                    .trim()
                    .split("\n")
                    .map((coordinate) => L.latLng(coordinate.trim().split(",")));
                let shape = L.polygon(coordinates, {
                    color: areaColor,
                });
                if (area.drillDownPage) {
                    shape.on("click", () => {
                        App.vent.trigger("dashboard:drilldown", area.drillDownPage);
                    });
                }
                shape.addTo(layerGroup);
            });
            const label = `<span style='color:${areaColor}'>${area.label}</span>`;
            this.overlays[label] = layerGroup;
            map.addLayer(layerGroup);
        });

        this.areaLayersOverview = L.control.layers(null, this.overlays, { position: "topleft", collapsed: false });
        this.areaLayersOverview.addTo(map);
    },
    removeAreaLayer: function () {
        if (typeof this.overlays !== "undefined") {
            Object.values(this.overlays).forEach((overlayLayer) => {
                this.map.removeLayer(overlayLayer);
            });
            this.map.removeControl(this.areaLayersOverview);
        }
    },
    removePreviousMarkersLayerFromMap: function () {
        // Remove the old layer
        if (typeof this.markersLayer !== "undefined" && this.markersLayer.getLayers().length > 0) {
            //console.log('******* Removing markers');
            this.map.removeLayer(this.markersLayer);
        }
    },
    addMarkersLayerFromChartConfig: function () {
        if (this.chartConfig.markersLayer.getLayers().length > 0) {
            this.markersLayer = this.chartConfig.markersLayer;
            this.markersLayer.addTo(this.map);
        }
    },
    updateAreaColors: function () {
        if (!this.chartConfig.areaColors) {
            return;
        }

        //TODO: find area and change its color
    },
    resize: function () {
        if (this.map) {
            setTimeout(() => this.map.invalidateSize(), 400);
        }
    },
});

export default leafletMixin;
