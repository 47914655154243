import { action, observable } from "mobx";
import appStatusSynchronizer from "../../core/appStatusSynchronizer";
import App from "app";
import { removeHtmlResponse } from "app/components/flow/designer/appErrors/exception-helper.js";

const LATEST_APPS = "latestApps";
const LATEST_DASHBOARDS = "latestDashboards";

class HeaderStore {
    @observable latestApps = [];
    @observable latestDashboards = [];
    @observable alerts = [];

    constructor() {
        this.loadFromStore();
        App.vent.on("app:deleted", appID => {
            this.removeFromLatestApp(appID);
        });

        App.vent.on("dashboard:deleted", dashboardId => {
            this.removeFromLatestDashboards(dashboardId);
        });

        App.vent.on("api:status_change", app => {
            if (!app || !app.length) {
                return;
            }

            this.updateStatusInLatestApp(app[0].id, app[0].currentStatus);
        });
        this.listenAndAddToAlerts();
    }

    @action.bound
    listenAndAddToAlerts() {
        App.vent.on("alertQueue:message", alert => {
            alert.message = removeHtmlResponse(alert.message)
            this.alerts.unshift(alert);
        });
    }

    @action.bound
    addToLatestApps(appToBeAdded) {
        const app = {
            ...appToBeAdded,
            flowStatus: appStatusSynchronizer.getStatus(appToBeAdded.id),
            accessTime: new Date().getTime()
        };
        this.latestApps.forEach((latestApp, i) => {
            if (app.id === latestApp.id) {
                this.latestApps.splice(i, 1);
            }
        });

        this.latestApps.unshift(app);
        appStatusSynchronizer.on(app.id, status => {
            this.changeStatus(app.id, status);
        });

        this.persist();
    }

    @action.bound
    removeFromLatestApp(appId) {
        this.latestApps.forEach((latestApp, i) => {
            if (appId === latestApp.id) {
                this.latestApps.splice(i, 1);
            }
        });

        this.persist();
    }

    @action.bound
    updateStatusInLatestApp(appId, currentStatus) {
        this.latestApps.forEach((latestApp, i) => {
            if (appId === latestApp.id) {
                this.latestApps[i].flowStatus = currentStatus;
            }
        });

        this.persist();
    }

    @action.bound
    evictAndUpdateDashboards(dashboardsMap) {
        this.latestDashboards.forEach((latestDashboard, i) => {
            if (!dashboardsMap[latestDashboard.id]) {
                this.latestDashboards.splice(i, 1);
            }
        });

        this.persist();
    }

    @action.bound
    evictAndUpdateApps(apps) {
        this.latestApps.forEach((latestApp, i) => {
            if (!apps[latestApp.id]) {
                this.latestApps.splice(i, 1);
            }
        })

        this.persist();
    }

    @action.bound
    addToLatestDashboards(dashboard) {
        this.latestDashboards.forEach((latestDashboard, i) => {
            if (dashboard.id === latestDashboard.id) {
                this.latestDashboards.splice(i, 1);
            }
        });
        this.latestDashboards.unshift(dashboard);
        this.persist();
    }

    @action.bound
    updateLatestDashboards(dashboard) {
        this.latestDashboards.forEach((latestDashboard, i) => {
            if (latestDashboard.id === dashboard.id) {
                this.latestDashboards[i] = {
                    ...latestDashboard,
                    title: dashboard.title
                };
            }
        });
        this.persist();
    }

    @action.bound
    removeFromLatestDashboards(dashboardId) {
        this.latestDashboards.forEach((latestDashboard, i) => {
            if (dashboardId === latestDashboard.id) {
                this.latestDashboards.splice(i, 1);
            }
        });

        this.persist();
    }

    @action.bound
    changeStatus(appID, status) {
        this.latestApps.forEach((latestApp, i) => {
            if (latestApp.id === appID) {
                this.latestApps[i].flowStatus = status;
            }
        });
        this.persist();
    }

    loadFromStore() {
        try {
            let apps = localStorage.getItem(LATEST_APPS);
            apps = JSON.parse(apps);
            this.latestApps = apps || [];
        } catch (e) {
            console.log("error restoring stored apps");
        }
        try {
            let dashboards = localStorage.getItem(LATEST_DASHBOARDS);
            dashboards = JSON.parse(dashboards);
            this.latestDashboards = dashboards || [];
        } catch (e) {
            console.log("error restoring stored dashboards");
        }
    }

    persist() {
        localStorage.setItem(LATEST_APPS, JSON.stringify(this.latestApps));
        localStorage.setItem(LATEST_DASHBOARDS, JSON.stringify(this.latestDashboards));
    }
}

let headerStore = new HeaderStore();

export default headerStore;
