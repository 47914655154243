import { GenericStore } from "../storeUtils";
import api from "core/api/api";
import _ from "underscore";
import App from "app";
import growl from "../../../app/components/common/growl";

export default class PagesStore extends GenericStore {
    constructor() {
        super();
        this.addCreatePageAction();
    }

    addCreatePageAction() {
        this.actionCreator(
            async page => {
                try {
                    return api.create(undefined, page);
                } catch (e) {
                    growl.error(e.message, "There was an error creating Dashboard Page");
                    throw Error("Api error");
                }
            },
            {
                actionType: "Create",
                name: "page"
            }
        );
    }
    createPageObject({ nsName }) {
        const settings = { cols: 12, cellheight: 20, rows: 0, background: "" };
        const name = _.uniqueId("Page");
        const components = [];
        const id = `${nsName}.PAGE.${name}`;
        return {
            type: "PAGE",
            isEditable: null,
            ctime: null,
            _is_current: false,
            x: 0,
            y: 0,
            width: 12,
            height: 12,
            params: {},
            title: "Default Dashboard Page",
            queryVisualizations: [],
            style: { margin: 0, background: { color: "transparent" }, border: { color: "transparent", size: 0 } },
            name,
            id,
            settings,
            gridJSON: JSON.stringify({ id, components, settings }),
            nsName
        };
    }
}
