import Backbone from "backbone";
import { View } from "../database-reader/base-database-reader";
import { DB_PROVIDERS } from "../database-reader/configs/db-providers";
const module = {};
class PostgressView extends View {
    setup() {
        this.label = "PostgreSQL CDC";
        this.model.get("connectionParams").set("type", DB_PROVIDERS.POSTGRES.id);
        this.model.set("isCDC", true);
    }
}
/*
module.View = baseSimpleSource.View.extend({
    label: "PostgreSQL CDC",
    setAdapterProperties: function () {
        this.model.set("adapter", {
            handler: "Global.PROPERTYTEMPLATE.PostgreSQLReader",
            properties: {},
        });
    },
});*/
module.View = PostgressView;
module.vent = new Backbone.Model();
export default module;
