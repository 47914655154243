import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { StriimInputField, StriimButton } from "@striim/striim-ui";
import { StriimTheme } from "@striim/striim-ui";
import Grid from "@material-ui/core/Grid";
import useStyles from "./filebrowser.styles";
import LocalStore from "./file_browser.store";
import UploadModal from "./file_upload_modal";

const FileBrowser = ({ ...props }) => {
    const { enabled, value, onChange, error = false, label, placeholder, errorText } = props;
    const classes = useStyles();
    const modalState = useState(false);
    const [selectedValue, setSelectedValue] = useState(value);
    let localStore = new LocalStore();

    useEffect(() => {
        if (value && value !== selectedValue) {
            setSelectedValue(value);
            onChange & onChange(value);
        }
    }, [onChange, value]);

    let setValue = function(value) {
        onChange(value);
        setSelectedValue(value);
    };

    return (
        <StriimTheme preselector=".striim.materialize.light:not(#\20)">
            <Grid container>
                <Grid item xs={8}>
                    <StriimInputField
                        label={label}
                        value={selectedValue}
                        placeholder={placeholder}
                        disabled={!enabled}
                        onChange={val => {
                            setValue(val);
                        }}
                        error={error}
                        helperText={error ? errorText : ""}
                    />
                </Grid>
                <Grid item xs={4}>
                    <StriimButton
                        variant={error ? "error" : "secondary"}
                        fullWidth={true}
                        disabled={!enabled}
                        onClick={() => {
                            modalState[1](true);
                        }}
                        style={{ marginTop: label ? 28 : "" }}
                    >
                        Choose
                    </StriimButton>
                </Grid>
            </Grid>
            <UploadModal localStore={localStore} modalState={modalState} onSelect={setValue} />
        </StriimTheme>
    );
};
FileBrowser.propTypes = {
    enabled: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    directoryOnly: PropTypes.bool.isRequired
};

export default FileBrowser;
