import App from "app";
import api from "core/api/api";
import metaobjectSanitizer from "./metaobjectSanitizer";
import utils from "core/utils";
import "app/components/dashboard/grid/grid";
import "./defaultmetaobject";
import "./queryvisualization";
import NestedTypes from "backbone.nestedtypes";

var metadataService = App.module("Metadata.Pages", function (Pages, App, Backbone, Marionette, $, _) {
    Pages.addInitializer(function () {
        App.reqres.setHandler("new:metadata:page", Pages.create);
        App.reqres.setHandler("metadata:page", Pages.read);
        App.reqres.setHandler("metadata:pages", Pages.readAll);
    });
    // A single dashboard page, which contains a collection of the visualizations that are in it, as well as its
    // layout, subcomponents, etc. (A page is actually just a GridComponent)
    // Each dashboard has a collection of these.
    var QVCollection = App.Metadata.Queryvisualizations.QueryvisualizationsCollection;
    Pages.PageModel = App.Dashboard.Grid.Model.extend({
        defaults: {
            type: "PAGE",
            isEditable: null,
            ctime: null,
            _is_current: false, // Is this the current page being viewed
            title: String,
            x: 0,
            y: 0,
            width: 12,
            height: 12,
            params: Object,
            // TODO Metadata Service: this will be a MetaObject Collection Attribute
            queryVisualizations: NestedTypes.options({
                type: QVCollection,
                parse: function (val) {
                    var that = this;
                    return _(val).map(function (name) {
                        if (_(name).isObject()) return name; // Page may be being parsed with existing objects, if page is just being updated with serverAttrs in BB save success
                        var id = that.get("nsName") + ".QUERYVISUALIZATION." + name;
                        return {
                            id: id,
                            nsName: that.get("nsName"),
                            name: name,
                        };
                    }, this);
                },
            }),
        },
        url: function () {
            return "/metadata/pages/" + this.get("id");
        },

        sync: function (method) {
            var $deferred = new $.Deferred();

            var that = this;
            var full_id = this.get("nsName") + ".PAGE." + this.get("name");
            var api_method = method.toUpperCase();

            var options;
            if (method !== "read") {
                options = {
                    full: false,
                    success: true,
                };
            }
            var json_data = metaobjectSanitizer.sanitize(this.toJSON(options));
            json_data.id = full_id;
            json_data.type = "PAGE";

            var api_promise = api._CRUDHandler(undefined, api_method, json_data);
            api_promise
                .then(function(data) {
                    data = data[0];
                    data = that.parse(data);
                    that.set(data);

                    that.onFetch();

                    $deferred.resolve(that);
                })
                .fail(function (e) {
                    $deferred.reject(e);
                });

            return $deferred;
        },

        initialize: function (options) {
            _(this).bindAll("onFetch", "setVisEditModes");
            // TODO: figure out if we really need to listen on every change event, or just on initialize or parse
            this.setVisEditModes();
            this.setEditableState();
            this.listenTo(this.queryVisualizations, "change", this.setVisEditModes);
            this.listenTo(this, "change:_editable", function () {
                this.setEditableState();
                this.setVisEditModes();
            });
            App.Dashboard.Grid.Model.prototype.initialize.apply(this, arguments);
            this.set("id", utils.getFQN(this));
            this.listenTo(this, "query:filter", this.applyQueryFilter);
        },
        applyQueryFilter: function (params) {
            App.request("QueryManager:updateParameters", params);
        },
        setEditableState: function () {
            this.set("_guides", this._editable);
            this.set("_active", this._editable);
        },
        setVisEditModes: function (qv) {
            this.queryVisualizations.each(function (qv) {
                qv._editable = this._editable; // is the QV editable as far as a grid component is concerned
            }, this);
        },
        parse: function (r, options) {
            var json = JSON.parse(r.gridJSON || "{}");
            r.components = json.components;
            r.settings = json.settings;
            _(_(r.components).clone()).each(function (component, i) {
                if (component.grid) {
                    console.warn(
                        "(Temp) Warning while parsing page",
                        r.id,
                        ": Page component",
                        component.id,
                        "was a nested layout. It will be excluded from the page, along with any visualizations inside."
                    );
                    r.components.splice(i, 1);
                }
            });

            if (r.settings) delete r.settings.type;
            delete r.gridJSON;
            var parsed = App.Dashboard.Grid.Model.prototype.parse.apply(this, [r, options]);

            // Ensure a minimum height for a page (in case it's not set correctly)
            // TODO: if JSON is saved properly, this should not be necessary
            var minheight = 0;
            _(parsed.components).each(function (component) {
                minheight = Math.max(minheight, component.y + component.height);
            });
            parsed.height = Math.max(this.height || 0, minheight);
            return parsed;
        },
        fetch: function (options) {
            options || (options = {});
            options.success = this.onFetch;
            return App.Dashboard.Grid.Model.prototype.fetch.call(this, options);
        },
        onFetch: function () {
            this.sideloadVisualizations();
        },
        sideloadVisualizations: function () {
            this.components.each(function (c) {
                if (!c.content_id) return;
                var vismodel =
                    this.queryVisualizations.findWhere({
                        name: c.content_id,
                    }) || this.queryVisualizations.get(c.content_id);
                if (vismodel) c.set("content", vismodel);
            }, this);
        },
        toJSON: function (options) {
            options || (options = {});
            var is_sync = _(options).has("success");
            var json = App.Dashboard.Grid.Model.prototype.toJSON.apply(this, arguments);
            if (!is_sync || options.full) return json;

            var grid_json = this.serialize();
            var stringified_grid = JSON.stringify(grid_json);
            json.gridJSON = stringified_grid;

            json.queryVisualizations = _(json.queryVisualizations).map(function (qv) {
                return qv.name;
            });

            // TODO: add all private attributes
            var reduced_json = _(json).omit(["controls", "grid", "_gridsettings", "components"]);

            return reduced_json;
        },
    });

    Pages.PagesCollection = Backbone.Collection.extend({
        model: Pages.PageModel,
        url: "/metadata/pages",

        sync: function (method) {
            var $deferred = new $.Deferred();

            var that = this;

            var api_method = method.toUpperCase();
            var json_data = {};
            json_data.type = "PAGE";

            var api_promise = api._CRUDHandler(undefined, api_method, json_data);
            api_promise
                .then(function(data) {
                    _.each(data, function (page) {
                        var model = new Dashboards.DashboardModel(page);
                        that.add(model);
                    });
                    $deferred.resolve(that);
                })
                .fail(function (e) {
                    $deferred.reject(e);
                });

            return $deferred;
        },
    });

    Pages.create = function () {
        return new Pages.PageModel();
    };

    Pages.read = function (pageid) {
        var page = new Pages.PageModel({
            id: pageid,
        });

        var deferred = $.Deferred();
        page.fetch()
            .then(function(data, textStatus, jqXHR) {
                deferred.resolve(page, data, textStatus, jqXHR);
            })
            .fail(deferred.reject);
        return deferred.promise();
    };

    Pages.readAll = function () {
        var pages = new Pages.PagesCollection();

        var deferred = $.Deferred();
        pages
            .fetch()
            .then(function(data, textStatus, jqXHR) {
                deferred.resolve(pages, data, textStatus, jqXHR);
            })
            .fail(deferred.reject);
        return deferred.promise();
    };
});

export default metadataService;
