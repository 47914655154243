export const AlertManagerIntegrationStatuses = Object.freeze({
    Email: {
        NotConfigured: "EMAIL_NOT_CONFIGURED"
    },
    Slack: {
        NotConfigured: "SLACK_NOT_CONFIGURED"
    },
    Teams: {
        NotConfigured: "TEAMS_NOT_CONFIGURED"
    }
});
