import _ from "underscore";
import TypeModifier from "./type-modifier";
import ColumnsList from "../columns-picker/columns-with-function-list";

var View = ColumnsList.View.extend({
    initialize: function (options) {
        ColumnsList.View.prototype.initialize.apply(this, [options]);

        this.gridView = new TypeModifier.CollectionView({
            collection: new TypeModifier.Collection([]),
            model: this.model,
            config: options.transformationConfig,
        });

        ColumnsList.View.prototype.attachListeners.apply(this);
    },

    onRender: function () {
        ColumnsList.View.prototype.onRender.apply(this, []);

        this.$(".with-function").removeClass("with-function");
    },

    fillAllColumns: function () {
        this.columnsDataSource.then(fields => {
            fields.forEach(field => {
                this.gridView.collection.push({
                    column: field.id,
                    tqlFunction: field.description,
                    alias: field.id,
                    columns: fields,
                    columnType: field.description,
                });
            });

            this.gridView.scrollToBottom();
            this.trigger("value:set", this.getViewValue());
        });
    },
});

var Model = ColumnsList.Model.extend({});

export default _.extend({}, ColumnsList, {
    Model: Model,
    View: View,
});
