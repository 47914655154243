import _ from "underscore";
import BaseControl from "./../base-control";
import TextField from "./../text-field/text-field";
import List from "./../list/list";
import template from "./string-list.html";

export default function (addText, emptyMessage) {
    var View = BaseControl.View.extend({
        template: _.template(template),

        initialize: function () {
            var _this = this;

            this.listModel = new List.Model({
                editor: TextField,
                initial: false,
                addText: addText,
                emptyMessage: emptyMessage
            });
            _this.model.on("change", function (model) {
                _this.listModel.value = model.value;
            });
            this.listModel.on("change", function (model) {
                //prevents of getting empty value
                var values = _.filter(model.value, function (item) {
                    return item !== "undefined" && item !== null && item.trim() !== "";
                });

                _this.setValue(
                    _.map(values, function (item) {
                        return item.trim();
                    })
                );
            });

            this.listView = new List.View({
                model: this.listModel
            });
            this.listView.on("added-new-editor", function (element) {
                element.find("input").removeClass("full");
            });
        },

        setViewEnabled: function () {
            if (!this.$el.html()) {
                return;
            }

            this.listView.setEnabled(this.getEnabled());
        },

        onRender: function () {
            this.$el.append(this.listView.render().$el);
        },

        getViewValue: function () {
            //prevents of getting empty value
            var values = _.filter(this.listView.model.value, function (item) {
                return item !== "undefined" && item !== null && item.trim() !== "";
            });

            return _.map(values, function (value) {
                return value.trim();
            });
        },

        setViewValue: function () {},
    });

    var Model = BaseControl.Model.extend({
        defaults: {
            value: Array,
            addText: String,
            emptyMessage: String,
        },
    });

    return _.extend({}, BaseControl, {
        Model: Model,
        View: View,
    });
}
