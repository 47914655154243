import ColumnsEnricherList from "app/components/common/editor/control/columns-picker/columns-enricher-list";
import types from "app/components/common/editor/control/select/types-datasource.json";

const Transformation = {};

Transformation.OnlyDbSources = true;

Transformation.createColumnControl = function () {
    return ColumnsEnricherList.extend({
        hideLabel: true,
        hidden: false,
        transformationConfig: {
            addAvailable: true,
            labels: {
                column2: "Type",
                column3: "Expression",
            },
        },
    }).create();
};

Transformation.buildTql = function (tqlBuilder, columns) {
    let parameters = columns
        .filter((column) => {
            return column.column && column.alias;
        })
        .map((column) => {
            let convertFunction = "";
            const f = types.find((t) => t.id === column.tqlFunction);
            if (f) {
                convertFunction = f.convertFunction;
            }

            let value = "";
            if (convertFunction) {
                value = convertFunction + "(" + column.column + ")";
            } else {
                value = column.column;
            }

            return {
                key: column.alias,
                value: value,
            };
        });

    var putUserData = tqlBuilder.getPutUserDataStatement(parameters);
    tqlBuilder.addColumn(putUserData);
};

export default Transformation;
