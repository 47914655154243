import growl from "../../../../app/components/common/growl";
import api from "../../../../core/api/api";
import utils from "../../../../core/utils";
import App from "app";
import { DeploymentInfoType, ErrorsArrayType } from "../types";
import Papa from "papaparse";
import fileSaver from "file-saver";
import metaobjectConverter from "../../../../core/services/metaStoreService/metaobject-converter";
import { removeHtmlResponse } from "../../../../app/components/flow/designer/appErrors/exception-helper";
import statusManagement from "../../../status-management";

function deployApp(deploymentInfo: DeploymentInfoType, appId: String): Promise<void> {
    return new Promise(async (resolve, reject) => {
        if (!deploymentInfo || !appId) {
            console.log("Please provide deployment info and AppId");
            reject();
        }
        const appInfo = {
            namespace: utils.getNamespace(appId),
            appName: utils.getName(appId)
        };
        try {
            await api.deploy(deploymentInfo, appInfo);
            resolve();
        } catch (err) {
            onStatusChangedFailure("deploy", err);
            reject();
        } finally {
            App.vent.trigger("progress:end", appId);
        }
    });
}
function convertConfigToHashMap(deploymentInfo: DeploymentInfoType): { [key: string]: string } {
    let resultHashMap = {};
    let config = deploymentInfo;
    for (let key in config) {
        let item = config[key];
        resultHashMap[item.flow] = item.group;
    }
    return resultHashMap;
}
function exportAsCSV(errors: ErrorsArrayType, applicationId: string) {
    const errorsWithDisplayNames = errors.map(error => {
        return {
            "Source component name": error.srcShortName,
            "Source table": error.srcTable,
            "Source column": error.srcCol,
            "Target component name": error.targetShortName,
            "Target table": error.tarTable,
            "Target column": error.tarCol,
            "Error messsage": error.message
        };
    });

    const csvString = Papa.unparse(errorsWithDisplayNames);

    const filename =
        "Table mapping validation " + metaobjectConverter.convertFullNameToShortName(applicationId) + ".csv";
    let blob = new Blob([csvString], { type: "text/plain;charset=utf-8" });

    fileSaver.saveAs(blob, filename);
}

export { deployApp, convertConfigToHashMap, exportAsCSV };

function onStatusChangedFailure(action: string, e: { message?: string } | null) {
    if (!e) {
        e = {};
    }
    action = action.toUpperCase();
    growl.error(
        statusManagement.appActions.find(elem => elem.id === action).text +
            " failed! " +
            removeHtmlResponse(e?.message) +
            "",
        "Error"
    );
}
