import React, { useEffect, useState, useContext } from "react";
import { Grid, Box, LinearProgress } from "@mui/material";
import WizardLayout from "../../../common/wizard-layout/wizard-layout";
import Stepper from "../../../common/stepper/stepper";
import { Steps, stepDetails } from "../../target-steps";
import { StriimButton, StriimTypography } from "@striim/striim-ui-v2";
import { styles } from "./apps-creation.styles";
import { WizardContext } from "../../../wizard-context";
import growl from "../../../../../../app/components/common/growl";
import App from "app";
import utils from "../../../../../../core/utils";
import { deployAndStartApplication } from "../../../services";
import LoadingIndicator from "../../../../../generic/loading-indicator/loading-indicator";
import ErrorIcon from "../../../../../generic/icon/wizard-icon/errorFilled.svg";
import { isAutomatedWizard } from "../../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";
import { targetUtils } from "../../../utils";

const TYPE_LABELS = {
    InitialLoad: {
        title: "Setting up your IL App!",
        subtitle: "Striim is creating your IL app. This may take upto few minutes.",
        message: "Your IL App has been created."
    },
    CDC: {
        title: "Setting up your CDC App!",
        subtitle: "Striim is creating your CDC app. This may take upto few minutes.",
        message: "Your CDC App has been created."
    },
    Automated: {
        title: "Setting up your Automated Pipeline!",
        subtitle: "Striim is creating your automated apps. This may take upto few minutes.",
        message:
            "Your automated pipeline has been created.  The IL App will run first and synchronize the existing data.  Once the IL phase has completed, the CDC App will start running and synchronize any table updates."
    },
    Automated_Mode_App_Adapter: {
        title: "Setting up your app on Automated Mode!",
        subtitle: "Striim is creating your app on automated mode. This may take a few minutes.",
        message: "Your app has been created in Automated Mode."
    }
};

interface AppsCreationProps {
    target: string;
    startApp: boolean;
}
const AppsCreation = ({ target, startApp }: AppsCreationProps) => {
    const { sourceInfo, cdcSourceInfo, wizardAppName, wizardType } = useContext(WizardContext);

    const stepper = <Stepper steps={stepDetails} activeStep={Object.values(Steps).length} />;
    const [apiStatus, setApiStatus] = useState<Object>({
        result: "todo"
    });

    useEffect(() => {
        const startAPI = async () => {
            let errorMsg = null;
            let parsedResponse;
            try {
                var response = await deployAndStartApplication(sourceInfo.appId, cdcSourceInfo?.appId, wizardAppName);
                parsedResponse = typeof response === "string" ? JSON.parse(response) : response;
                if (!parsedResponse.result) {
                    errorMsg = parsedResponse.message;
                }
            } catch (err) {
                errorMsg = err;
            } finally {
                parsedResponse.result ? growl.success(TYPE_LABELS[wizardType].message) : growl.error(errorMsg);
                targetUtils.navigateNext(isAutomatedWizard(), wizardAppName);
            }
        };
        const exitOnly = () => {
            setTimeout(() => {
                targetUtils.navigateNext(isAutomatedWizard(), wizardAppName);
                growl.success(TYPE_LABELS[wizardType].message);
            }, 3000);
        };
        startApp ? startAPI() : exitOnly();
    }, []);

    const navigateToFlowDesigner = () => {
        let shortAppName = utils.getName(sourceInfo.appId);
        let nsName = utils.getNamespace(sourceInfo.appId);
        App.navigate("/#/flow/" + nsName + "." + shortAppName, { trigger: true });
    };

    const renderStatusElement = () => {
        const result = apiStatus.result;
        switch (result) {
            case "todo":
                return (
                    <Grid item sx={styles.loadingIndicatorWrapper} mt={2} px={1}>
                        <LoadingIndicator isGlobal={false} sx={styles.loadingIndicator} />
                    </Grid>
                );
            case "fail":
            case "failed":
            case false:
                return (
                    <Grid container sx={styles.errorGridContainer} px={2.25}>
                        <Grid item container sx={styles.errorItemContainer} my={2}>
                            <Grid item>
                                {" "}
                                <ErrorIcon style={styles.svgIcon} />{" "}
                            </Grid>
                            <Grid item>
                                <StriimTypography variant="body4" color="greyscale.700">
                                    {apiStatus.message}
                                </StriimTypography>
                            </Grid>
                        </Grid>
                        <Grid item sx={styles.flowDesignerButton} pt={1}>
                            <StriimButton variant="primary" onClick={navigateToFlowDesigner}>
                                {" "}
                                Take me to Flow Designer{" "}
                            </StriimButton>
                        </Grid>
                    </Grid>
                );
        }
    };

    return (
        <WizardLayout Stepper={stepper}>
            <Grid container sx={styles.container}>
                <Grid item>
                    <img src="src/generic/icon/wizard-icon/summaryCreation.svg"></img>
                </Grid>
                <Grid item>
                    <StriimTypography sx={styles.header} variant="h2">
                        {TYPE_LABELS[wizardType].title}
                    </StriimTypography>
                    <StriimTypography sx={styles.content} color="greyscale.700" variant="body4">
                        {TYPE_LABELS[wizardType].subtitle}
                    </StriimTypography>
                    {renderStatusElement()}
                </Grid>
            </Grid>
        </WizardLayout>
    );
};

export default AppsCreation;
