import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { generatePermissionString, parsePermissions, parsePermissionsToObject } from "./permissions-parser";
import { filterUserId, filterRoleName, filterAllRoles, filterAllPermissions, getFilterMessage } from "./table-filter";
import api from "core/user-management/userManagementApi/userManagementApi";
import growl from "app/components/common/growl";
import namespaceFactory from "app/components/createapp/namespace-factory";

const UserManagementContext = createContext({
    roles: [],
    roleDetails: []
});

const useUserManagement = () => useContext(UserManagementContext);

const raiseAlert = ({ type, text }) => {
    if (type === "SUCCESS") {
        growl.success(text);
    } else {
        growl.error(text);
    }
};

const UserManagementProvider = ({ children }) => {
    const [userManagementProviderBusy, setUserManagementProviderBusy] = useState(false);
    const [roles, setRoles] = useState([]);
    const [rolesForModal, setRolesForModal] = useState([]);
    const [users, setUsers] = useState([]);
    const [roleDetails, setRoleDetails] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [filter, setFilter] = useState({
        isUserFilterActive: false,
        isRoleFilterActive: false,
        isResultEmpty: true,
        filterMessage: ""
    });
    const [filteredRoles, setFilteredRoles] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [rolesFetched, setRolesFetched] = useState(true);

    const getRoleDetailsById = useCallback(async roleId => {
        if (!roleId) {
            return;
        }

        try {
            setUserManagementProviderBusy(true);

            const resp = await api.getRole(roleId);
            const details = resp[0];
            details.permissions = details.permissions.map(parsePermissions);
            details.permissionsText = details.permissions.map(x => x.text).join(", ");

            for (let i = 0; i < details.roles?.length || 0; i++) {
                const roleDetailsResult = await api.getRole(details.roles[i].name);
                if (!roleDetailsResult || roleDetailsResult.length < 1) {
                    continue;
                }

                details.roles[i] = roleDetailsResult[0];
                details.roles[i].permissions = details.roles[i].permissions.map(parsePermissions);
                details.roles[i].permissionsText = details.roles[i].permissions.map(x => x.text).join(", ");
            }

            setRoleDetails([details]);
        } catch {
            console.warn("Error fetching role details");
        } finally {
            setUserManagementProviderBusy(false);
        }
    }, []);

    const getRoles = useCallback(async () => {
        try {
            setUserManagementProviderBusy(true);

            const resp = await api.getRoles();

            const result = resp.map(r => {
                const permissions = r.permissions.map(parsePermissions);
                const permissionsText = permissions.map(p => ({ name: p.text }));
                return { ...r, permissions, permissionsText };
            });
            setRoles(result);
        } catch {
            console.warn("Error fetching roles");
        } finally {
            setUserManagementProviderBusy(false);
        }
    }, []);

    const getRolesForModal = useCallback(async () => {
        try {
            const resp = await api.getRoles();

            const result = resp.map(r => {
                const permissions = r.permissions.map(parsePermissions);
                const permissionsText = permissions.map(p => p.text).join(", ");
                return { ...r, permissions, permissionsText };
            });

            setRolesForModal(result);
        } catch {
            console.warn("Error fetching roles");
        } finally {
            setRolesFetched(false);
        }
    }, []);

    const getUsers = useCallback(async () => {
        try {
            setUserManagementProviderBusy(true);

            const resp = await api.getUsers();

            const result = resp.map(r => {
                const permissions = r.permissions.map(parsePermissions);
                const permissionsText = permissions.map(p => ({ name: p.text }));
                return { ...r, permissions, permissionsText };
            });
            setUsers(result);
        } catch (e) {
            console.warn("Error fetching users :" + e);
        } finally {
            setUserManagementProviderBusy(false);
        }
    }, []);

    const getUserDetailsById = useCallback(async id => {
        if (!id) {
            return;
        }

        try {
            setUserManagementProviderBusy(true);
            const details = await api.getUser(id);
            details.permissions = details.permissions.map(parsePermissions);
            details.permissionsText = details.permissions.map(x => x.text).join(", ");

            for (let i = 0; i < details.roles.length || 0; i++) {
                const roleDetailsResult = await api.getRole(details.roles[i].name);
                if (!roleDetailsResult || roleDetailsResult.length < 1) {
                    continue;
                }

                details.roles[i] = roleDetailsResult[0];
                details.roles[i].permissions = details.roles[i].permissions.map(parsePermissions);
                details.roles[i].permissionsText = details.roles[i].permissions.map(x => x.text).join(", ");
            }

            setUserDetails(details);
        } catch (e) {
            console.warn("Error fetching user details :" + e);
        } finally {
            setUserManagementProviderBusy(false);
        }
    }, []);

    const handleRevokeRoleByName = useCallback(async name => {
        try {
            setUserManagementProviderBusy(true);

            const resp = await api.revokeRolesFromRole(name);
        } catch {
            console.warn("Error revoking role");
        } finally {
            setUserManagementProviderBusy(false);
        }
    });

    const createUser = useCallback(
        async (
            username,
            password,
            defaultNamespace,
            roles,
            isLdapUser,
            alias,
            propertyset,
            email,
            firstName,
            lastName,
            phone,
            timezone
        ) => {
            try {
                const result = await api.createUser(
                    username,
                    password,
                    defaultNamespace,
                    roles,
                    isLdapUser,
                    alias,
                    propertyset,
                    email,
                    firstName,
                    lastName,
                    phone,
                    timezone
                );

                raiseAlert({
                    text: `You just created user ${username}`,
                    type: "SUCCESS"
                });
                getUsers();
            } catch (error) {
                raiseAlert({
                    text: `Error creating user ${username}. ${error.response.data}`,
                    type: "ERROR"
                });
            }
        }
    );

    const clearFilter = useCallback(() => {
        setFilter({ isUserFilterActive: false, isRoleFilterActive: false, isResultEmpty: true, filterMessage: "" });
    });

    const filterTable = useCallback((filters, activeTab) => {
        if (!filters || filters.length === 0) {
            clearFilter();
            return;
        }
        const isFilterForUserTable = activeTab === 0;
        let dataToFilter = isFilterForUserTable ? [...users] : [...roles];
        const rolesMatch = [];
        const permissionsMatch = [];
        const filterValues = filters?.map(v => ({ filter: v.value, searchAll: v.searchAll }));
        let filteredData = [];
        const searchCount = filterValues.length >= 2 ? 2 : 1;
        //1: search with AND filter on narrowed data, 2:search with OR filter on all data
        for (let i = 1; i <= searchCount; i++) {
            filterValues.forEach(({ filter }) => {
                let result;
                dataToFilter =
                    i === 2
                        ? isFilterForUserTable
                            ? [...users].filter(x => !filteredData.find(v => v["User Id"] === x["User Id"]))
                            : [...roles].filter(x => !filteredData.find(v => v.name === x.name))
                        : dataToFilter;
                filteredData = searchCount === 2 ? filteredData : [];
                let filterValue;
                if (filter.toLowerCase().startsWith("user:") && filter.length > 5) {
                    filterValue = filter.split(":")[1].trim();
                    result = filterUserId(dataToFilter, filterValue);
                } else if (filter.toLowerCase().startsWith("role:")) {
                    filterValue = filter.split(":")[1].trim();
                    if (!isFilterForUserTable) {
                        result = filterRoleName(dataToFilter, filterValue);
                        if (result.name || result.length > 0) {
                            filteredData.push(result);
                            !rolesMatch.includes(filterValue) && rolesMatch.push(filterValue);
                        }
                    }
                    result = filterAllRoles(dataToFilter, filterValue);
                    if (result != undefined && Object.keys(result).length !== 0 && !rolesMatch.includes(filterValue)) {
                        rolesMatch.push(filterValue);
                    }
                } else if (filter.toLowerCase().startsWith("permission:")) {
                    filterValue = filter.split(":")[1].trim();
                    result = filterAllPermissions(dataToFilter, filterValue);

                    if (
                        result != undefined &&
                        Object.keys(result).length !== 0 &&
                        !permissionsMatch.includes(filterValue)
                    ) {
                        permissionsMatch.push(filterValue);
                    }
                }
                if (result != undefined && Object.keys(result).length !== 0) {
                    filteredData.push(result);
                }
                //Extracts unique entries, keeping the last occurance found.
                filteredData = Object.values(
                    filteredData.flat().reduce((result, v) => {
                        isFilterForUserTable ? (result[v["User Id"]] = v) : (result[v.name] = v);
                        return result;
                    }, {})
                );
                dataToFilter = filteredData;
            });
        }

        const filterMessage = getFilterMessage(
            filterValues,
            filteredData,
            rolesMatch,
            permissionsMatch,
            isFilterForUserTable
        );
        const isResultEmpty = filteredData.length === 0 ? true : false;
        isFilterForUserTable ? setFilteredUsers(filteredData) : setFilteredRoles(filteredData);
        isFilterForUserTable
            ? setFilter({ isUserFilterActive: true, isRoleFilterActive: false, isResultEmpty, filterMessage })
            : setFilter({ isUserFilterActive: false, isRoleFilterActive: true, isResultEmpty, filterMessage });
    });

    const deleteUser = useCallback(async id => {
        try {
            const result = await api.deleteUser(id);
            raiseAlert({
                text: `You just deleted user ${id}`,
                type: "SUCCESS"
            });
            getUsers();
        } catch {
            raiseAlert({
                text: `Error deleting user ${id}`,
                type: "ERROR"
            });
        }
    }, []);

    const deactivateUser = useCallback(async id => {
        try {
            const result = await api.deactivateUser(id);
            raiseAlert({
                text: `You just deactivated user ${id}`,
                type: "SUCCESS"
            });
            getUsers();
        } catch (e) {
            raiseAlert({
                text: e.response?.data,
                type: "ERROR"
            });
        }
    }, []);

    const activateUser = useCallback(async id => {
        try {
            const result = await api.activateUser(id);
            raiseAlert({
                text: `You just activated user ${id}`,
                type: "SUCCESS"
            });
            getUsers();
        } catch (e) {
            raiseAlert({
                text: e.response?.data,
                type: "ERROR"
            });
        }
    }, []);

    const deleteRole = useCallback(async id => {
        try {
            const result = await api.deleteRole(id);
            raiseAlert({
                text: `You just deleted role ${id}`,
                type: "SUCCESS"
            });
            getRoles();
        } catch {
            raiseAlert({
                text: `Error deleting role ${id}`,
                type: "ERROR"
            });
        }
    }, []);

    const addPermission = useCallback(
        permission => {
            setRoleDetails([{ ...roleDetails[0], permissions: [...roleDetails[0].permissions, permission] }]);
        },
        [roleDetails]
    );

    const grantPermissionsToUser = useCallback(async (username, objectname, privileges, objectTypes) => {
        try {
            const result = await api.grantPermissionsToUser(
                username,
                objectname,
                privileges.map(x => x.toLowerCase()),
                objectTypes.map(x => x.toLowerCase())
            );
            raiseAlert({
                text: `Successfully granted permissions for user ${username}`,
                type: "SUCCESS"
            });
            getUsers();
        } catch (error) {
            raiseAlert({
                text: error.response.data,
                type: "ERROR"
            });
        }
    }, []);

    const revokePermissionsFromUser = useCallback(async (username, objectname, privileges, objectTypes) => {
        try {
            const result = await api.revokePermissionsFromUser(
                username,
                objectname,
                privileges.map(x => x.toLowerCase()),
                objectTypes.map(x => x.toLowerCase())
            );
            raiseAlert({
                text: `Successfully revoked permissions for user ${username}`,
                type: "SUCCESS"
            });
            getUsers();
        } catch (error) {
            raiseAlert({
                text: error.response.data,
                type: "ERROR"
            });
        }
    }, []);

    const grantRolesToUser = useCallback(async (username, roles) => {
        try {
            const result = await api.grantRolesToUser(username, roles);
            raiseAlert({
                text: `Successfully granted roles for user ${username}`,
                type: "SUCCESS"
            });
            getUsers();
        } catch (error) {
            raiseAlert({
                text: error.response.data,
                type: "ERROR"
            });
        }
    }, []);

    const revokeRolesFromUser = useCallback(async (username, roles) => {
        try {
            const result = await api.revokeRolesFromUser(username, roles);
            raiseAlert({
                text: `Successfully revoked roles for user ${username}`,
                type: "SUCCESS"
            });
            getUsers();
        } catch (error) {
            raiseAlert({
                text: error.response.data,
                type: "ERROR"
            });
        }
    }, []);

    const addRoles = useCallback(
        roles => {
            setRoleDetails([...roleDetails, ...roles]);
        },
        [roleDetails]
    );

    const createRole = useCallback(async (namespace, rolename, createEntries) => {
        try {
            await namespaceFactory.create(namespace);
            await api.createRole(rolename, "INTERNAL");

            raiseAlert({
                text: `You just created role ${rolename}`,
                type: "SUCCESS"
            });

            await updateRole(rolename, true, {
                ...createEntries
            });
        } catch (error) {
            raiseAlert({
                text: error.response.data,
                type: "ERROR"
            });
        }
    });

    const updateUser = useCallback(
        async (username, password, isLdapUser, email, firstName, lastName, phone, timezone, uiconfig) => {
            try {
                const result = await api.updateUser(
                    username,
                    password,
                    isLdapUser,
                    email,
                    firstName,
                    lastName,
                    phone,
                    timezone,
                    uiconfig
                );

                raiseAlert({
                    text: `You just successfully updated user ${username}`,
                    type: "SUCCESS"
                });
                getUsers();
            } catch (error) {
                raiseAlert({
                    text: `Error updating user ${username}. ${error.response.data}`,
                    type: "ERROR"
                });
            }
        }
    );

    const updateRole = useCallback(async (rolename, created, updateEntries) => {
        const {
            addedRoles,
            addedPermissions,
            addedUsers,
            revokedRoles,
            revokedPermissions,
            revokedUsers
        } = updateEntries;

        const rolesToAdd = addedRoles.filter(x => revokedRoles.map(v => v.name).indexOf(x.name) === -1);
        const rolesToRevoke = revokedRoles.filter(x => addedRoles.map(p => p.name).indexOf(x) === -1);
        const permissionsToGrant = addedPermissions.filter(x => revokedPermissions.map(p => p.id).indexOf(x.id) === -1);
        const permissionsToRevoke = revokedPermissions.filter(
            x => addedPermissions.map(p => p.id).indexOf(x.id) === -1
        );
        const usersToAdd = addedUsers.filter(x => revokedUsers.map(v => v["User Id"]).indexOf(x["User Id"]) === -1);
        const usersToRevoke = revokedUsers.filter(x => addedUsers.map(p => p.name).indexOf(x) === -1);

        try {
            if (rolesToAdd.length) {
                await api.grantRolesToRole(
                    rolename,
                    rolesToAdd.map(x => x.name)
                );
            }

            if (rolesToRevoke.length) {
                await api.revokeRolesFromRole(
                    rolename,
                    rolesToRevoke.map(x => x.name)
                );
            }

            for (let i = 0; i < permissionsToGrant.length; i++) {
                await api.grantPermissionsToRole(rolename, permissionsToGrant[i]);
            }

            for (let i = 0; i < permissionsToRevoke.length; i++) {
                await api.revokePermissionsFromRole(rolename, permissionsToRevoke[i]);
            }

            for (let i = 0; i < usersToAdd.length; i++) {
                await api.grantRolesToUser(usersToAdd[i]["User Id"], [rolename]);
            }

            for (let i = 0; i < usersToRevoke.length; i++) {
                await api.revokeRolesFromUser(usersToRevoke[i]["User Id"], [rolename]);
            }

            if (!created) {
                raiseAlert({
                    text: `You just updated role details for role ${rolename}`,
                    type: "SUCCESS"
                });
            }
            getUsers();
            getRoles();
        } catch (error) {
            raiseAlert({
                text: error.response.data,
                type: "ERROR"
            });
        }
    });

    useEffect(() => {
        return () => {
            setUserManagementProviderBusy(false);
            setUsers([]);
            setRoles([]);
            setRoleDetails([]);
            setUserDetails([]);
        };
    }, []);

    const addUsersToRole = useCallback(
        data => {
            setUsers(
                users.map(u =>
                    data.map(o => o.name).includes(u["User Id"])
                        ? { ...u, roles: [...u.roles, roleDetails[0].name] }
                        : u
                )
            );
        },
        [users]
    );

    return (
        <UserManagementContext.Provider
            value={{
                userManagementProviderBusy,
                users,
                roles,
                rolesForModal,
                roleDetails,
                userDetails,
                filter,
                filterTable,
                filteredUsers,
                filteredRoles,
                clearFilter,
                getUsers,
                getRoles,
                getRolesForModal,
                getRoleDetailsById,
                getUserDetailsById,
                handleRevokeRoleByName,
                createUser,
                deleteUser,
                deleteRole,
                addPermission,
                grantPermissionsToUser,
                revokePermissionsFromUser,
                grantRolesToUser,
                revokeRolesFromUser,
                createRole,
                updateRole,
                updateUser,
                addRoles,
                addUsersToRole,
                deactivateUser,
                activateUser,
                rolesFetched
            }}
        >
            {children}
        </UserManagementContext.Provider>
    );
};

export { UserManagementProvider, useUserManagement };
