import metaObjectSanitizer from "./metaobjectSanitizer";
import DataVisualizationsTypeSpecParser from "./DataVisualizationsTypeSpecParser";

function parseConfig(config, visualizationType) {
    var parser = new DataVisualizationsTypeSpecParser();
    var ConfigModel = parser.create_model(visualizationType);
    var result = JSON.parse(config || "{}");
    return new ConfigModel(metaObjectSanitizer.sanitize(result), {
        parse: true,
    });
}

export default {
    parse: function (data) {
        if (data.config && typeof data.config === "string") {
            data.config = parseConfig(data.config, data.visualizationType);
        }
        if (data.query && data.query.indexOf(".") === -1) {
            data.query = data.nsName + "." + data.query;
        }
        if (typeof data.query !== "object") {
            data.query = {
                id: data.query,
            };
        }

        return data;
    },
};
