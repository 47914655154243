import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ spacing }) => ({
    icons: {
        fontSize: 40,
        lineHeight: 0
    },
    title: {
        marginLeft: spacing(1.5)
    },
    helpBtn: {
        marginLeft: "auto"
    },
    fieldsContainer: {
        marginTop: spacing(3)
    },
    formInput: {
        marginTop: spacing(2)
    },
    inputGroup: {
        marginTop: spacing(3.5)
    },
    columnSpacing: {
        paddingLeft: spacing(2)
    },
    tableInputError: {
        "& .MuiTableCell-root": {
            backgroundColor: "rgba(221,55,17,0.08)"
        }
    }
}));

export const sxStyles = {
    headerContainer: {
        padding: ({ spacing }) => spacing(3),
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    fieldsContainer: {
        padding: ({ spacing }) => spacing(3)
    },
    tableMappingTitle: {
        color: ({ palette }) => palette.primary[700]
    },
    caption: {
        color: ({ palette }) => palette.greyscale[700]
    }
};
