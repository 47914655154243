import React from "react";
import { TableCell, TableRow, Grid, Box, LinearProgress } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import styles from "../../table-progress.styles";
import { FillCheck } from "../../../../generic/icon/customIcons";
import { Writer, Writers, tableProgress } from "../../table-progress-types";
import { formatDate } from "../../table-progress.store";

const RowIL: React.FunctionComponent<{ row: tableProgress }> = ({ row }) => {
    const { writers, tableName, sourceComponentName } = row;
    return (
        <React.Fragment>
            <TableRow sx={styles.rowStylesIl}>
                <TableCell>
                    <Grid>
                        <Box>
                            <Box className="rowVisibleContent">
                                <Box>
                                    <StriimTypography variant="body4">{tableName}</StriimTypography>
                                </Box>
                                <Box>
                                    <StriimTypography variant="caption3">{sourceComponentName}</StriimTypography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid>
                        {Object.values(writers ?? {})?.map((writerInfo, idx) => {
                            return (
                                <Box sx={{ height: "64px" }} key={idx}>
                                    <Box className="rowVisibleContent">
                                        <Box>
                                            <StriimTypography variant="body4">{writerInfo?.tableName}</StriimTypography>
                                        </Box>
                                        <Box>
                                            <StriimTypography variant="caption3">
                                                {writerInfo?.targetComponentName}
                                            </StriimTypography>
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Grid>
                </TableCell>

                <RecordsReadComponentIL row={row} />
                <RecordsWrittenComponentIL row={row} />
                <LastWriteTimeComponent row={row} />
            </TableRow>
        </React.Fragment>
    );
};

const RecordsWrittenComponentIL: React.FunctionComponent<{ row: tableProgress }> = ({ row: data }) => {
    const rowsWritten = Object.values(data?.["writers"] || {});
    const TotalRows = data?.TotalRows ?? 0;
    return (
        <TableCell>
            <Box>
                {rowsWritten.map((row, idx) => {
                    const specificRowsWritten = row?.["No of Inserts"] ?? row?.["No of inserts"];
                    const progress = specificRowsWritten === 0 ? 0 : (specificRowsWritten / TotalRows) * 100;
                    return (
                        <Box
                            key={idx}
                            className="rowVisibleContent"
                            sx={{ ...styles.linearOutProgress, ...styles.progress }}
                        >
                            {progress === 100 ? (
                                <Box sx={styles.icon}>
                                    <LinearProgress sx={{ mt: 1 }} variant="determinate" value={progress} />
                                    <FillCheck />
                                </Box>
                            ) : (
                                <LinearProgress sx={{ mt: 1 }} variant="determinate" value={progress} />
                            )}
                            <StriimTypography variant="body4">
                                {specificRowsWritten}/{TotalRows}
                            </StriimTypography>
                        </Box>
                    );
                })}
            </Box>
        </TableCell>
    );
};
const RecordsReadComponentIL: React.FunctionComponent<{ row: tableProgress }> = ({ row: data }) => {
    const rowsRead = data?.RowsRead ?? 0;
    const TotalRows = data?.TotalRows ?? 0;
    const progress: number = rowsRead === 0 ? 0 : (rowsRead / TotalRows) * 100;
    return (
        <TableCell>
            <Box className="rowVisibleContent" sx={{ ...styles.linearInProgress, ...styles.progress }}>
                <Box>
                    {progress === 100 ? (
                        <>
                            <Box sx={styles.icon}>
                                <LinearProgress sx={{ mt: 1 }} variant="determinate" value={progress} />
                                <FillCheck />
                            </Box>
                        </>
                    ) : (
                        <LinearProgress variant="determinate" value={progress} sx={{ mt: 1 }} />
                    )}
                    <StriimTypography variant="body4">
                        {rowsRead}/{TotalRows}
                    </StriimTypography>
                </Box>
            </Box>
        </TableCell>
    );
};

const LastWriteTimeComponent: React.FunctionComponent<{ row: tableProgress }> = ({ row: data }) => {
    const { writers } = data;
    return (
        <TableCell>
            <Grid>
                {Object.values(writers ?? {})?.map((writerInfo, idx) => {
                    return (
                        <Box className="rowVisibleContent" sx={{ height: "60px" }} key={idx}>
                            <StriimTypography variant="body4">{formatDate(writerInfo?.lastWriteTime)}</StriimTypography>
                        </Box>
                    );
                })}
            </Grid>
        </TableCell>
    );
};
export { RowIL };
