import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    monitorContainer: {
        marginBottom: 0,
        paddingBottom: 0,
        minHeight: 148,
        minWidth: 1008
    },
    tile:{
        minWidth: 320
    },
    divider: {
        color: "#D8E4F0"
    }
}));
