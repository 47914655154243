import utils from "core/utils.js";
import appGroupService from "app/components/common/app-control/app-groups-service";

// Checks if the wizard is ILCDC
const isAutomatedWizard = () => window.location.href.indexOf("automated-ILCDC") !== -1;

const IL_SUFFIX = "_IL";
const CDC_SUFFIX = "_CDC";

// Removes the IL or CDC suffix
const getTruncatedName = name => {
    const pattern = new RegExp(`(${IL_SUFFIX}|${CDC_SUFFIX})$`);
    return name.replace(pattern, "");
};

// Appends "_IL" to the app's name
const getILName = name => name + IL_SUFFIX;

// Appends "_CDC" to the app's name
const getCDCName = name => getTruncatedName(name) + CDC_SUFFIX;

// Create a new group and add IL and CDC Apps
const moveToNewGroup = async (name, CDCName) => {
    try {
        const namePrefix = utils.getNamespace(name) + ".APPLICATION.";
        const ILAppFQN = namePrefix + utils.getName(name);
        const CDCAppFQN = namePrefix + CDCName;
        const groupName = getTruncatedName(utils.getName(name));
        const groupType = "ILCDC";
        await appGroupService.assign(ILAppFQN, groupName, null, groupType);
        await appGroupService.assign(CDCAppFQN, groupName, null, groupType);
    } catch (e) {
        console.error(e);
    }
};

export { isAutomatedWizard, getILName, getCDCName, getTruncatedName, moveToNewGroup, IL_SUFFIX };
