import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StriimTheme, StriimTypography } from "@striim/striim-ui";
import { Box } from "@material-ui/core";
import ProgressIndicator from "src/modules/apps/pages/manage-striim/components/progress-indicator";

const useStyles = makeStyles(({ spacing }) => ({
    wrapper: {
        backgroundColor: "#fff",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        display: "flex",
        zIndex: 1,
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    },
    flex: {
        display: "flex",
        flexDirection: "column",
        width: "350px"
    },
    marginTop: {
        marginTop: spacing(2)
    }
}));

const ConfigValidation = () => {
    const classes = useStyles();

    return (
        <StriimTheme>
            <div className={classes.wrapper}>
                <Box className={classes.flex}>
                    <StriimTypography variant={"h2"}>Validating your connection</StriimTypography>
                    <StriimTypography variant={"body"}>This may take a few seconds</StriimTypography>
                    <div className={classes.marginTop}>
                        <ProgressIndicator variant={"indeterminate"} percentage={100} />
                    </div>
                </Box>
            </div>
        </StriimTheme>
    );
};

export default ConfigValidation;
