import _ from "underscore";
import TimeParser from "./time-parser";
import Interval from "./interval";

function parseShortFormat(value) {
    var s = value.toString();
    var unit = "s";
    var val = 0;

    if (s.match("[smhdw]+")) {
        unit = s.match("[smhdw]+")[0];
    }
    if (s.match("\\d+")) {
        val = s.match("\\d+")[0];
    }
    return {
        unit: unit,
        value: val,
    };
}

function toShortString(value) {
    var d = TimeParser.inDuration(value);
    return d.value + d.unit[0].toLowerCase();
}

var View = Interval.View.extend({
    getValueInMicroseconds: function (value) {
        var obj = parseShortFormat(value);
        return TimeParser.toMicroseconds(obj.value, obj.unit);
    },

    getViewValue: function () {
        var microseconds = TimeParser.toMicroseconds(this.value.getValue(), this.unit.getValue());
        return toShortString(microseconds);
    },

    isEmpty: function () {
        return this.model.value === "0s";
    },
});

export default _.extend({}, Interval, {
    Model: Interval.Model,
    View: View,
});
