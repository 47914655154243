import React from "react";
import { Box, Divider, Grid } from "@mui/material";
import {
    formatFirstAndLastTimestamp,
    formatNavigatorFirstAndLastTimestamp,
    formatNavigatorXAxisLabel,
    formatTimestampForTooltip,
    formatXAxisLabel
} from "./utils";
import { StriimTypography } from "@striim/striim-ui-v2";

import { styles as legendStyles } from "../sentinel-tabs/sentinel-tabs.styles";

export const eventsChartColors = {
    sensitiveBarColor: "#7E7ED6",
    nonSensitiveBarColor: "#49C7BB",
    sdiBarColor: "#B6BEEB",
    sensitiveTaggedLineColor: "#B6BEEB"
};

export const actionsChartColors = {
    maskedBarColor: "#ED88BA",
    encryptedBarColor: "#356BC0",
    noActionBarColor: "#D8E4F0",
    navigatorLineColor: "#30A9EE"
};

export const commonColors = {
    axisLabel: "#6E7D8C"
};

export const tooltipStriimlineHighcharts = {
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderColor: "#D8E4F0",
    borderRadius: 4,
    shadow: false,
    boxShadow: "0px 6px 15px 0px rgba(72, 82, 92, 0.12)"
};

export const reportOptions = {
    chart: {
        type: "column",
        panning: {
            enabled: false
        },
        zooming: {
            mouseWheel: {
                enabled: false
            },
            resetButton: { theme: { style: { display: "none" } } }
        },
        height: 325,
        width: 1050
    },
    plotOptions: {
        series: {
            pointPadding: 0,
            groupPadding: 0.3,
            animation: false
        },
        column: {
            pointWidth: 8,
            stacking: "normal",
            borderWidth: 1,
            borderColor: "#FFF"
        }
    }
};

export const commonOptions = {
    chart: {
        type: "column",
        panning: {
            enabled: false
        },
        zooming: {
            mouseWheel: {
                enabled: false
            },
            resetButton: { theme: { style: { display: "none" } } }
        },
        height: 325
    },
    plotOptions: {
        series: {
            pointPadding: 0,
            groupPadding: 0.3,
            states: {
                hover: { enabled: false }
            }
        },
        column: {
            pointWidth: 8,
            stacking: "normal",
            borderWidth: 1,
            borderColor: "#FFF"
        }
    },
    xAxis: {
        lineWidth: 1,
        lineColor: "#D8E4F0",
        tickLength: 0,
        labels: {
            style: {
                color: commonColors.axisLabel,
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px"
            },
            step: 3,
            formatter: function() {
                if (this.isFirst) {
                    return formatFirstAndLastTimestamp(this.value);
                }
                return formatXAxisLabel(this.value);
            }
        }
    },
    yAxis: {
        gridLineDashStyle: "ShortDash",
        gridLineColor: "#D8E4F0",
        opposite: false,
        labels: {
            style: {
                color: commonColors.axisLabel,
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px"
            }
        }
    },
    navigator: {
        adaptToUpdatedData: true,
        handles: {
            enabled: false,
            backgroundColor: "#D8E4F0"
        },
        outlineColor: "#D8E4F0",
        outlineWidth: 1,
        maskFill: "rgba(216, 228, 240, 0.6)",
        xAxis: {
            labels: {
                formatter: function() {
                    if (this.isFirst || this.isLast) {
                        return formatNavigatorFirstAndLastTimestamp(this.value);
                    }
                    return formatNavigatorXAxisLabel(this.value);
                },
                reserveSpace: true
            }
        }
    },
    rangeSelector: {
        buttons: [],
        inputEnabled: false
    },
    tooltip: {
        shared: true,
        split: false,
        useHTML: true,
        ...tooltipStriimlineHighcharts
    },
    credits: {
        enabled: false
    }
};

export const CustomLegend = ({ color }) => {
    return <Box component={"span"} width={7} height={7} borderRadius={0.5} sx={{ backgroundColor: color }} />;
};

export const CustomPlotbandTooltip = ({ x, isDiscoveryDisabled = false, hasAppStopped = false }) => {
    return (
        <Grid
            container
            width={220}
            sx={{
                backgroundColor: "additional.white",
                borderRadius: 1,
                border: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
                boxShadow: tooltipStriimlineHighcharts.boxShadow
            }}
        >
            <StriimTypography variant="caption4" color="greyscale.700" fontFamily={"Inter"} p={1}>
                {formatTimestampForTooltip(x)}
            </StriimTypography>
            <Divider variant="fullWidth" sx={{ width: "100%", borderColor: "greyscale.200", width: "100%" }} />
            <Grid container p={1}>
                <StriimTypography variant="caption3" color="greyscale.700" fontFamily={"Inter"}>
                    {isDiscoveryDisabled
                        ? "Data is currently unavailable because discovery is disabled. Enable it to view the activity."
                        : hasAppStopped
                        ? "Data is currently unavailable because the App has stopped. Restart it to view the activity."
                        : "Data is currently unavailable "}
                </StriimTypography>
            </Grid>
        </Grid>
    );
};

export const DataLegend = ({ data, isReport = false, showAmount = false }) => {
    return (
        <Grid item display="flex" justifyContent="flex-end">
            {data?.map(el => (
                <Box key={el.label + el.value} component="span" sx={legendStyles.tabBottomContainerAction}>
                    <span style={{ ...legendStyles.actionSquare, backgroundColor: el.color }}></span>
                    <StriimTypography variant="caption3" color="greyscale.700" fontFamily="Inter" display="inline">
                        {el.field}
                    </StriimTypography>
                    <StriimTypography
                        variant="caption3"
                        fontFamily="Inter"
                        display="inline"
                        color="greyscale.700"
                        sx={el.showUnderline && legendStyles.firstLetterUnderline}
                    >
                        {el.label}
                    </StriimTypography>
                    {isReport ? (
                        <StriimTypography
                            variant="caption3"
                            fontFamily="Inter"
                            display="inline"
                            sx={legendStyles.reportActionValue}
                        >
                            {el.amount ? `${el.amount} (${el.value})` : el.value}
                        </StriimTypography>
                    ) : (
                        <StriimTypography
                            variant="caption3"
                            fontFamily="Inter"
                            display="inline"
                            sx={legendStyles.actionValue}
                        >
                            {showAmount && el.amount ? `${el.amount} (${el.value})` : el.value}
                        </StriimTypography>
                    )}
                </Box>
            ))}
        </Grid>
    );
};
