import _ from "underscore";
import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var externalSourceModule = {};

externalSourceModule.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "EXTERNALSOURCE",
        properties: Object,
        outputStream: null,
        propertySet: null
    },
    validate: function() {
        var errors = baseMetaObject.Model.prototype.validate.apply(this, arguments) || {};

        var outputStream = this.get("outputStream");
        var stream_regex = /[a-zA-Z0-9]+.(STREAM|WACTIONSTORE).[a-zA-Z0-9]+/;
        if (outputStream && !outputStream.match(stream_regex)) {
            errors["outputStream"] = "Please enter a name with only alphanumeric or underscore characters.";
        }

        return _.isEmpty(errors) ? undefined : errors;
    }
});

externalSourceModule.Collection = baseMetaObject.Collection.extend({
    model: externalSourceModule.Model
});

export default externalSourceModule;
