import React from "react";
import { StriimTypography, StriimChip } from "@striim/striim-ui";
import { Grid } from "@mui/material";

import { styles } from "../../../guardian/guardian.styles";
import ConnectionLost from "/app/images/connection_lost.svg";

const AppStopped = () => {
    return (
        <Grid container sx={styles.wrapper}>
            <ConnectionLost style={{ ...styles.securityImage }} />

            <Grid container sx={{ ...styles.wrapperEmpty }}>
                <StriimChip type="tag-chip" size="large" label="APP STOPPED" hideAvatar />

                {/* //TODO: Change draft text*/}
                <StriimTypography variant="body" paragraph={true} sx={{ ...styles.description }}>
                    App is in halted / completed state. Restart the App to view new activity
                </StriimTypography>
            </Grid>
        </Grid>
    );
};

export default AppStopped;
