import React from "react";

const YoutubeVideo = ({ url, width, height, thumbnail, style }) => {
    const youtubeRegex = /^(https?:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/;
    const vimeoRegex = /^(https?:\/\/)?(player\.vimeo\.com)\/.+$/;

    if (url.match(youtubeRegex) || url.match(vimeoRegex)) {
        return (
            <iframe
                width={width}
                height={height}
                src={url}
                title={url.match(youtubeRegex) ? "YouTube video player" : "Vimeo video player"}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        );
    } else {
        return (
            <video width={width} height={height} controls poster={thumbnail} style={style}>
                <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        );
    }
};
export default YoutubeVideo;
