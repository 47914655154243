import $ from "jquery";

// http://stackoverflow.com/questions/15859113/focus-not-working
var timeout = 500;
var firstVisibleInputSelector = "*:input[type!=hidden]:first";

export default {
    focus: function (selector) {
        setTimeout(function () {
            $(selector).focus();
        }, timeout);
    },
    focusFirstInputIn: function (selector) {
        this.focus(selector + " " + firstVisibleInputSelector);
    },
};
