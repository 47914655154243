/*
 * Deprecated in 3.1.2.
 */
import App from "app";
import utils from "core/utils";

App.module("Form", function (Form, App, Backbone, Marionette, $, _) {
    Form.Mixin = {
        initialize: function () {
            this.listenTo(this, "before:destroy", function () {
                this._destroy_dropdowns();
            });
        },
        /*
         * Utility functions for properly cleaning up custom selects
         */
        _get_dropdowns: function () {
            return this.$("select, input").filter(".select2-offscreen");
        },
        _close_open_dropdowns: function () {
            this._get_dropdowns().each(function () {
                $(this).select2("close");
            });
        },
        _destroy_dropdowns: function () {
            this._get_dropdowns().each(function () {
                $(this).select2("destroy");
            });
        },
        /*
         * Given an array of errors (see comments in dialog for allowed formats), displays them within the form.
         * Note: this method makes some assumptions about how your view is constructed.
         * e.g. it has a '.general-errors' div and the like.
         * Consider improving this method to make it more general, or move it to a more specific implementation
         */
        show_errors: function (errors) {
            var view = this;
            var errors = errors || this.model.get("_errors") || []; // Provide model that holds errors

            view.$(".message").hide();

            var general_errors = [];

            _(errors).each(function (error) {
                if (_(error).isString()) {
                    error = {
                        message: error,
                    };
                }
                var message = error.message; //_(error.message).escape();
                message = message.replace(/Error invoking method [a-zA-Z]*:/g, "").trim();
                if (error.field) {
                    var $field = view.$('[name="' + error.field + '"]');
                    var $element = $field.data("select2") ? $field.select2("container") : $field;
                    var content = error.type == "syntax" ? $("<pre>" + message + "</pre>") : message;

                    var $message = view.$('.message[data-field="' + error.field + '"]').addClass("show");
                    if ($message.length === 0) {
                        $message = $("<div/>", {
                            class: "message error has-arrow show",
                            "data-field": error.field,
                        }).insertBefore($element);
                    }
                    $message.html(content).show();
                } else {
                    general_errors.push(error);
                }
            });

            if (general_errors.length === 0) {
                return;
            }
            var msg = _(_(general_errors).pluck("message").join(" ")).escape();
            let $message = $("<div/>", {
                class: "message error show",
            }).html(msg);
            view.$(".general-errors").html("").append($message);
        },
        /*
         * This method should be hooked up to be called as the starting point to the user submitting your form.
         * It will call save (which will serialize your form, validate, and try to update the model)
         * and then show errors if any.
         * Feel free to override.
         * If there is a .submit button in your dialog, the dialog will fire a "submit" event when it is clicked
         * to which you can listen and call this.submit().
         * Alternatively, if you have set up a form, the above will be done automatically.
         */
        submit: function () {
            /*var data = */ this.save();
            if (!this.errors || this.errors.length === 0) {
                this.trigger("form-submit-success", this); // TODO: deprecate
                this.trigger("form:submit", this);
            } else {
                this.trigger("form-submit-error", this, this.errors); // TODO: deprecate
                this.trigger("form:error", this, this.errors);
                //this.show_errors(this.errors);
            }
            // #DEV-6546 Remove previous error messages
            // This method will clear all previous errors and will show new errors if any
            this.show_errors(this.errors);
        },
        /*
         * Serializes and validates the form's data, logs any errors, and tries to update the context
         * validate() and update() must be implemented.
         * Pass silent: true to proceed with the save process even if errors are encountered.
         * This can be useful in auto-save situations where you do not want to display errors to the user
         */
        save: function (opts) {
            opts || (opts = {});

            // Serialize
            //var data = _(this.serialize_forms()).extend(this.serialize());
            var data = this.serialize();

            // Clear errors
            this.errors = [];

            // Validate
            this.errors = this.validate(data) || [];

            // If we encountered errors, stop here and set this.errors to the errors. Form will display them.
            if (!opts.silent && this.errors.length > 0) {
                return;
            }

            // Otherwise, try to save.
            this.update(data);
        },
        serialize_forms: function () {
            var data = {};
            _(this.get_forms()).each(function (view, id) {
                data[id] = view.model.toJSON();
            });
            return data;
        },
        /*
         * Utility function for serializing a form in the simplest way possible.
         * You can use this inside your serialize() implementation.
         */
        serialize_form: function (form, doNotUnescape) {
            var view = this;
            // A form element can be either passed in, stored in this.options.form, or can simply exist somewhere in the $el
            var $form = $(form);
            var form = $form[0];
            if (!form) {
                console.warn("No form to serialize!");
                return {};
            }
            var o = {};

            var add = function (name, value) {
                if (o[name] !== undefined) {
                    if (!o[name].push) {
                        // make existing value into array
                        o[name] = [o[name]];
                    }
                    o[name].push(value);
                } else {
                    o[name] = value;
                }
            };

            // First, do the default serialize (except checkboxes)
            var a = $(form).serializeArray();
            $.each(a, function () {
                var $el = $form.find('[name="' + this.name + '"]');
                if ($el.attr("type") == "checkbox") return; // We handle checkboxes later
                add(this.name, doNotUnescape ? this.value : unescape(this.value));
            });

            // Account for checkboxes
            $form.find("input[type=checkbox]").each(function () {
                add(this.name, String(this.checked));
            });

            // Group any properties in a fieldset together (such as in a properties table)
            var $fieldsets = $form.find("[data-fieldset]");
            $fieldsets.each(function () {
                o[$(this).attr("data-fieldset")] = {};
            });
            o = utils.group(o, function(value, field) {
                var $input = $form.find("[name=" + field + "]");
                var $f = $input.closest("[data-fieldset]");
                if ($f.length > 0) {
                    return $f.data("fieldset");
                }
                return false;
            });
            return o;
        },
        /*
         * Assemble your UI's form values into a JSON representation, and return it.
         * For most simple cases, you can just use serialize_form() above
         */
        serialize: function () {
            // Implement
        },
        /*
         * Given your form's serialized data, validate it and return any errors.
         */
        validate: function (data) {
            var errors = [];
            // Add any errors to array. Allowed formats:
            // 1. 'There was a general error.'
            // 2. { message: 'There was a general error' }
            // 3. { field: 'some-input-name', message: 'Please fill out this field' }
            return errors;
        },
        /*
         * This method will receive the serialized data.
         * Inside this method, you should update your view's model given the form data
         */
        update: function (/*data*/) {
            // Implement
        },
    };
});
