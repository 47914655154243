function getNext2Pow(number) {
    var acc = 1;
    while (acc < number) {
        acc *= 2;
    }
    return acc;
}

export default {
    calculate: function (max) {
        if (max <= 0) {
            return 0;
        }

        var gigabyte = 1024 * 1024 * 1024;
        var maxGigabytes = max / gigabyte;

        var max2Pow = getNext2Pow(maxGigabytes);
        var calculatedMax = max2Pow * gigabyte;
        return calculatedMax;
    },
};
