import React, { useEffect } from "react";
import loadBackboneApp from "../../../../app/load-backbone-app";
import { AlertMainLayoutView } from "../../../../app/components/alertmanager/alertmanager";
import api from "../../../..//core/api/api";
import metaStoreService from "../../../../core/services/metaStoreService/meta-store-service";
import Backbone from "backbone";
import AlertManagerRoot from "../alert-manager-root";

function AddNewAlert() {
    useEffect(() => {
        const promises = [
            metaStoreService.fetchCollection(metaStoreService.entities.SYSALERTRULE),
            api.getEmailConfigStatus(),
            api.getSlackConfigStatus(),
            api.getTeamsConfigStatus()
        ];

        Promise.all(promises).then(results => {
            const [alertCollection, emailConfigStatus, slackConfigStatus, teamsConfigStatus] = results;
            const pageModel = new Backbone.Model({
                tab: "smart_alerts",
                showNew: true,
                category: null,
                item: null,
                isLiteMode: false,
                alertCollection: alertCollection,
                emailConfigStatus: emailConfigStatus,
                slackConfigStatus: slackConfigStatus,
                teamsConfigStatus: teamsConfigStatus
            });
            // @ts-ignore
            loadBackboneApp(new AlertMainLayoutView({ model: pageModel }));
        });
    }, []);

    return <></>;
}

export default function() {
    return (
        <AlertManagerRoot>
            <AddNewAlert />
        </AlertManagerRoot>
    );
}
