import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useReactHeader } from "../../hooks/useReactHeader";
import api from "../../../core/api/api";
import ALERT_MANAGER_ROUTES from "./routes.json";
import AlertManagerRoot from "./alert-manager-root";
import LoadingIndicator from "../../generic/loading-indicator/loading-indicator";

function AlertManager() {
    useReactHeader({ title: "Alert Manager" });
    const navigate = useNavigate();

    useEffect(() => {
        api.hasAlertAppBeenCreated()
            .then(hasAlertAppBeenCreated => {
                if (!hasAlertAppBeenCreated) {
                    navigate(ALERT_MANAGER_ROUTES.setupSMTP);
                } else {
                    navigate(`${ALERT_MANAGER_ROUTES.alertmanager}/view/smart_alerts`);
                }
            })
            .fail(() => navigate(ALERT_MANAGER_ROUTES.unauthorized));
    }, []);

    return <LoadingIndicator />;
}

export default function() {
    return (
        <AlertManagerRoot>
            <AlertManager />
        </AlertManagerRoot>
    );
}
