import React from "react";
import { Grid } from "@mui/material";
import { StriimButton, StriimChip } from "@striim/striim-ui";

import { ALL_SENTINELS_LABEL, SentinelMenuItem } from "../../sentinel-live-monitor";

interface ViewSettingsSectionProps {
    isDiscoveryDisabled?: boolean;
    isTaggingEnabled?: boolean;
    isAppStopped: boolean;
    appStatus: string;
    viewSettings: Function;
    selectedSentinel: SentinelMenuItem | null;
}

const ViewSettingsSection = ({
    isDiscoveryDisabled = false,
    isTaggingEnabled = false,
    isAppStopped,
    appStatus,
    viewSettings,
    selectedSentinel
}: ViewSettingsSectionProps) => {
    const isAllSentinelOptionSelected =
        !selectedSentinel || (selectedSentinel && selectedSentinel.value === ALL_SENTINELS_LABEL);
    return (
        <Grid container justifyContent={"space-between"}>
            <Grid item display="flex" alignItems={"center"} gap={1}>
                {isAppStopped ? (
                    <StriimChip type="tag-chip" size="large" hideAvatar label={`App ${appStatus}`} />
                ) : (
                    <>
                        {!isAllSentinelOptionSelected && (
                            <StriimChip
                                type="tag-chip"
                                size="large"
                                hideAvatar
                                variant={isTaggingEnabled && "success"}
                                label={isTaggingEnabled ? "Tagging Enabled" : "Tagging Disabled"}
                            />
                        )}
                        {isDiscoveryDisabled && !isAllSentinelOptionSelected && (
                            <StriimChip type="tag-chip" size="large" hideAvatar label={"Discovery Disabled"} />
                        )}
                    </>
                )}
            </Grid>
            {!isAllSentinelOptionSelected ? (
                <StriimButton variant="text" onClick={viewSettings}>
                    View Settings
                </StriimButton>
            ) : null}
        </Grid>
    );
};

export default ViewSettingsSection;
