import { makeStyles } from "@material-ui/core/styles";

const colors = {
    grey: "#4E5C62",
    blue: "#00A7E5",
    green: "#0D874C",
    orange: "#F9A825",
    grey_dark: "#57676E",
    interesting: "#541833",
    red: "#DD3711"
};

export const useStylesItem = makeStyles(({ spacing, palette }) => ({
    wrapper: {
        marginTop: 0,
        backgroundColor: palette.background.paper
    },
    templateItem: {
        boxShadow: "unset",
        position: "relative",
        height: "auto",
        width: "auto",
        border: themeV5 => `1px solid ${themeV5.palette.greyscale[200]}`,
        borderRadius: spacing(1),
        cursor: "pointer"
    },
    templateItemContent: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: spacing(1),
        padding: "0px !important"
    },
    icons: {
        fontSize: spacing(5)
    },
    chipContainer: {
        width: "auto"
    },
    ChipBoxContainer: {
        padding: spacing(1),
        borderBottom: themeV5 => `1px solid ${themeV5.palette.greyscale[200]}`,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%"
    },
    chipRoot: {
        padding: spacing(0, 0.5, 0, 0.5),
        borderRadius: spacing(0.25),
        marginLeft: spacing(0.5),
        color: themeV5 => themeV5.palette.greyscale[700],
        backgroundColor: themeV5 => themeV5.palette.greyscale[100],
        "&.IL": {
            color: "#0088D4",
            backgroundColor: "#DAF4FF !important"
        },
        "&.CDC": {
            color: "#2E8071",
            backgroundColor: "#D9F4EF !important"
        },
        "&.schema_conversion": {
            color: colors.orange,
            backgroundColor: colors.orange + "1A !important",
            marginLeft: "4px"
        },
        "&.Automated_IL_CDC": {
            color: themeV5 => themeV5.palette.primary[500],
            backgroundColor: themeV5 => themeV5.palette.primary[100] + "!important",
            marginLeft: "4px"
        }
    },
    chipLabel: themeV5 => ({
        ...themeV5.typography.caption2,
        color: "inherit",
        fontFamily: '"Inter","Helvetica","Arial",sans-serif'
    }),
    separator: {
        borderTop: themeV5 => `1px solid ${themeV5.palette.greyscale[200]}`,
        borderWidth: "0px",
        width: "100%"
    },
    subtitle: {
        color: themeV5 => themeV5.palette.greyscale[700],
        texAlign: "center",
        fontFamily: '"Inter","Helvetica","Arial",sans-serif',
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "12px",
        letterSpacing: "0.24px"
    },
    disabledTemplate: {
        opacity: 0.5,
        cursor: "not-allowed"
    }
}));

export const styles = {
    icon: {
        color: "transparent",
        width: "10px",
        height: "10px"
    },
    targetTitle: {
        marginTop: "8px"
    },
    sourceTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    subtitleContainer: {
        paddingBottom: ({ spacing }) => spacing(2)
    },
    tooltip: {
        color: ({ palette }) => palette.greyscale[50]
    }
};
