import App from "app";
import growl from "app/components/common/growl";
import { template, className, regions, ui, attributes } from "core/decorators";
import { LayoutView } from "marionette";
import FormBuilder from "app/components/common/editor/form-builder";
import UIControl from "app/components/common/editor/control/ui-control";
import dialog from "app/components/common/dialogs/dialogWindow";
import Backbone from "backbone";
import alertDetailTemplate from "./templates/alert-detail-template.html";
import utils from "core/utils";
import _ from "underscore";
import alertResources from "./alert-resources";
import converter from "core/services/metaStoreService/metaobject-converter";
import MetaObjectDataSource from "app/components/common/editor/control/select/metaobject-datasource";
import AppComponentsDataSource from "app/components/common/editor/control/select/app-components-data-source";
import api from "core/api/api";
import { ALERT_TYPE, emailValidation, getSysAlertComponentType } from "./alert-utils";

let AlertDetailViewModel = Backbone.Model.extend({
    defaults: {
        objectName: "",
        name: "",
        alertName: "",
        condition: "",
        comparator: null,
        alertValue: null,
        alertType: ALERT_TYPE.WEB,
        toAddress: "",
        channelName: "",
        channelURL: "",
        alertMessage: null,
        isEnabled: false,
        alertInterval: "0 SECOND",
        isSysDefined: false
    },
    validate: async function(isAlertEdit = false) {
        let validationError = {};
        const specifyValueMsg = "Please specify a valid value";
        if (!isAlertEdit && !this.get("objectName")) {
            validationError["objectName"] = "Create Alert On has to be selected";
        }
        if (!isAlertEdit) {
            const name = this.get("alertName") && this.get("alertName").trim();
            if (!name) {
                validationError["alertName"] = "Value for Alert Name is required";
            } else {
                const validateName = name => {
                    const nonAlphanumericOrUnderscore = /\W/g;
                    if (name.indexOf(" ") !== -1) {
                        return "Please enter without spaces.";
                    } else if (nonAlphanumericOrUnderscore.test(name)) {
                        return "Please enter only alphanumeric or underscore characters.";
                    } else if (!isNaN(parseInt(name))) {
                        return "Name cannot start with a number";
                    } else if (name.length < 2) {
                        return "Alert Name must be at least 2 characters";
                    } else {
                        return "";
                    }
                };

                const nameValidationError = validateName(name);

                if (nameValidationError) {
                    validationError["alertName"] = nameValidationError;
                }
            }
        }
        if (!this.get("condition")) {
            validationError["condition"] = "Alert Condition has to be selected";
        }
        if (!this.get("comparator")) {
            validationError["comparator"] = "Please fill in the comparator";
        }
        if (!this.get("alertValue")) {
            validationError["alertValue"] = specifyValueMsg;
        }
        const addressExists = this.get("toAddress") && this.get("toAddress").trim();
        const channelExists = this.get("channelName") && this.get("channelName").trim();
        const channelURL = this.get("channelURL") && this.get("channelURL").trim();
        const alertType = this.get("alertType");
        if (alertType === ALERT_TYPE.EMAIL && this.get("isEnabled")) {
            if (addressExists) {
                try {
                    await emailValidation.validate(addressExists);
                } catch (error) {
                    validationError["toAddress"] = error.message;
                }
            } else validationError["toAddress"] = specifyValueMsg;
        }
        if (alertType === ALERT_TYPE.SLACK && this.get("isEnabled")) {
            if (!channelExists) {
                validationError["channelName"] = specifyValueMsg;
            } else if (channelExists?.length < 2) {
                validationError["channelName"] = "Slack channel name must be at least 2 characters";
            }
        }
        if (alertType === ALERT_TYPE.TEAMS && this.get("isEnabled")) {
            if (!channelURL) {
                validationError["channelURL"] = specifyValueMsg;
            } else if (channelURL?.length < 2) {
                validationError["channelURL"] = "Teams ID must be at least 2 characters";
            }
        }
        return _.isEmpty(validationError) ? undefined : validationError;
    }
});

@template(alertDetailTemplate)
@className("alert-detail-container card")
@attributes({ "data-test-id": "alert-detail-container" })
@regions({
    form: ".form-container"
})
@ui({
    header: ".header",
    alertName: ".alert-name"
})
export class AlertManagerDetailView extends LayoutView {
    onRender() {
        let that = this;
        this.renderTitle();
        this.viewModel = this.getViewModelFromAlertModel(); //
        let form = new FormBuilder({
            model: this.viewModel,
            submitText: "Save",
            readOnly: false,
            labels: {
                objectname: {
                    name: "Create Alert On"
                },
                alertinterval: {
                    name: "Snooze After Alert",
                    description: "This setting will snooze alerts until the interval expires."
                },
                channelname: {
                    name: "Recipient Slack Channel Name"
                }
            }
        });

        var errorMessageBox = UIControl.ErrorBox().create();
        form.addStaticControl(errorMessageBox, "errorMessageBox");

        var dataSource;
        let objectNameControl;
        if (!this.model.get("id")) {
            if (this.options.reference && this.options.reference.indexOf("APPLICATION") !== -1) {
                dataSource = new AppComponentsDataSource(this.options.reference);
            } else if (this.options.reference && this.options.reference.indexOf("APPLICATION") === -1) {
                dataSource = [{ id: this.options.reference, text: utils.getName(this.options.reference) }];
            } else {
                dataSource = new MetaObjectDataSource(
                    [
                        "SERVER",
                        "CQ",
                        "STREAM",
                        "SOURCE",
                        "TARGET",
                        "APPLICATION",
                        "OPENPROCESSOR",
                        "WINDOW",
                        "CACHE",
                        "CONNECTIONPROFILE",
                        "WACTIONSTORE"
                    ],
                    "type"
                );
            }
            objectNameControl = UIControl.Select(dataSource, {
                customCssClass: "light",
                // [DEV-39248] Search starts from text beginning
                customFilter: (input, row) => row.toLowerCase().indexOf(input.toLowerCase()) === 0
            }).create();
            this.viewModel.set("objectName", this.options.reference);
            this.model.set("objectName", this.options.reference);

            form.addRequiredControl(objectNameControl, "objectName");
            objectNameControl.model.on("change:value", () => {
                objectNameControl.clearError();
            });
            form.addRequiredControl(UIControl.TextField.WithHelperText().create(), "alertName");
        }

        if (!this.model.get("isSysDefined")) {
            var alertConditionHeader = UIControl.HeaderWithIcon.extend({
                model: new UIControl.HeaderWithIcon.Model({
                    tag: "h3",
                    text: "Set Condition for Alerting",
                    title: "",
                    showTooltip: false
                })
            });
            form.addStaticControl(alertConditionHeader.create(), "alertConditionTitle");

            var alertConditionText = UIControl.HeaderWithIcon.extend({
                model: new UIControl.HeaderWithIcon.Model({
                    tag: "h5",
                    text: "Select the condition to trigger the alert",
                    title: "",
                    showTooltip: false
                })
            });
            form.addStaticControl(alertConditionText.create(), "alertConditionText");
        }

        var conditions = UIControl.Select(this.getConditionsList(this.model.get("objectName")), {
            allowClear: false,
            hideSearch: false,
            customCssClass: "light"
        }).create();
        this.conditionsSelect = conditions;
        form.addRequiredControl(conditions, "condition");

        let comparatorControl = UIControl.Select(this.getComparatorsList(), {
            allowClear: false,
            hideSearch: true,
            customCssClass: "light"
        }).create();
        form.addRequiredControl(comparatorControl, "comparator");

        let alertValueUnit = this.viewModel.get("alertValueUnit");
        let valueControl = UIControl.TextField.WithHelperText(alertValueUnit).create();
        form.addRequiredControl(valueControl, "alertValue");

        let snooozeFor = UIControl.Interval.create();
        form.addControl(snooozeFor, "alertInterval");

        var alertingMethodTitle = UIControl.HeaderWithIcon.extend({
            model: new UIControl.HeaderWithIcon.Model({
                tag: "h3",
                text: "Alerting Method",
                title: "",
                showTooltip: false
            })
        });
        form.addStaticControl(alertingMethodTitle.create(), "alertingMethodTitle");

        var alertingMethodText = UIControl.HeaderWithIcon.extend({
            model: new UIControl.HeaderWithIcon.Model({
                tag: "h5",
                text: "Select how you would like to receive the alert. ",
                title: "",
                showTooltip: false
            })
        });
        form.addStaticControl(alertingMethodText.create(), "alertingMethodText");

        var transportOptions = UIControl.RadioButtonList([
            { value: ALERT_TYPE.EMAIL, name: " Email" },
            {
                value: ALERT_TYPE.WEB,
                name: " In App"
            },
            {
                value: ALERT_TYPE.SLACK,
                name: " Slack"
            },
            {
                value: ALERT_TYPE.TEAMS,
                name: " Teams"
            }
        ]).create();
        form.addRequiredControl(transportOptions, "alertType");

        let channelControl = UIControl.TextField.WithHelperText(null, "Channel Name in Slack Workspace").create();
        form.addControl(channelControl, "channelName");

        let toAddressControl = UIControl.TextField.WithHelperText(
            null,
            "List of email addresses comma separated"
        ).create();
        form.addControl(toAddressControl, "toAddress");

        let teamsChannelControl = UIControl.TextField.WithHelperText(
            null,
            "Link to the Teams Channel from Teams App"
        ).create();
        form.addControl(teamsChannelControl, "channelURL");

        let setEnabledControl = UIControl.Toggle.create();
        form.addControl(setEnabledControl, "isEnabled");
        setEnabledControl.model.on("change:value", () => {
            toAddressControl.hideError();
            channelControl.hideError();
            teamsChannelControl.hideError();
        });

        let formView = form.create();
        this.getRegion("form").show(formView);
        showHideAlertControl();

        form.on("form-builder:save", async () => {
            this.viewModel.validationError = await this.viewModel.validate(!!that.model.get("id"));
            if (this.viewModel.validationError === undefined) {
                errorMessageBox.setHide(true);
                this.populateAlertModelFromViewModel();
                this.saveAlert();
            } else {
                console.error("validation error(-s)", this.viewModel.validationError);
                this.viewModel.trigger("invalid", this.viewModel);
                errorMessageBox.setMessage("Please fix the errors before submitting the form");
                errorMessageBox.setHide(false);
            }
        });

        if (!this.viewModel.get("isSysDefined")) {
            formView.addCustomActionDelete(
                () => {
                    var message = !this.model.get("id")
                        ? "You have unsaved changes. Do you want to proceed and delete this alert?"
                        : "Are you sure you want to delete this alert ? ";
                    var submitText = "Delete";
                    var title = "Delete Alert";
                    var additionalClass = "deleteModal";

                    dialog.confirm(message, null, additionalClass, title, submitText).then(result => {
                        if (!result) {
                            return;
                        }
                        this.handleDeleteAlert();
                    });
                },
                {
                    classes: "critical",
                    useLinkButton: true
                }
            );
        }

        this.setComparatorAndValue(conditions.model.get("value"), comparatorControl, valueControl);
        if (
            this.viewModel &&
            !this.viewModel.alertValue &&
            conditions.model.dataSource &&
            conditions.model.dataSource.length === 0
        ) {
            this.$el.find(".from-control-container-Alert_Comparator").hide();
            this.$el.find(".from-control-container-Alert_Value").hide();
        }
        conditions.model.on("change:value", (_model, newValue) => {
            comparatorControl.setValue(null);
            valueControl.setValue(null);
            if (newValue) {
                conditions.clearError();
                this.setComparatorAndValue(newValue, comparatorControl, valueControl);
            }
        });
        comparatorControl.model.on("change:value", () => {
            comparatorControl.clearError();
        });

        if (objectNameControl) {
            this.conditionsSelect.setDataSource(this.getConditionsList(objectNameControl.model.get("value")));
            objectNameControl.model.on("change:value", (model, newValue) => {
                this.model.set("objectName", newValue);
                this.conditionsSelect.setDataSource(this.getConditionsList(newValue));
                this.conditionsSelect.setValue(null);
                this.$el.find(".from-control-container-Alert_Comparator").hide();
                this.$el.find(".from-control-container-Alert_Value").hide();
            });
        }

        transportOptions.model.on("change:value", showHideAlertControl);
        function showHideAlertControl() {
            let currentTransport = transportOptions.model.value;
            that.$(".from-control-container-Recipient_Email").hide();
            that.$(".from-control-container-Recipient_Slack").hide();
            that.$(".from-control-container-channelURL").hide();

            if (currentTransport === ALERT_TYPE.EMAIL) {
                that.$(".from-control-container-Recipient_Email").show();
            } else if (currentTransport === ALERT_TYPE.SLACK) {
                that.$(".from-control-container-Recipient_Slack").show();
            } else if (currentTransport === ALERT_TYPE.TEAMS) {
                that.$(".from-control-container-channelURL").show();
            }
        }

        if (this.model.get("isSysDefined")) {
            conditions.model.set("enabled", false);
            comparatorControl.model.set("enabled", false);
        }
    }

    handleDeleteAlert() {
        try {
            if (!this.model.get("id")) {
                App.vent.trigger("alertmanager:show:detail", null);
                return;
            }
            this.model
                .destroy()
                .then(() => {
                    growl.success(null, "Deleted Alert Successfully", this.model.get("id"));
                    App.vent.trigger("alertmanager:delete:alert", this.model);
                })
                .fail(e => {
                    growl.error("Problem deleting Alert:" + e.message, "Error", this.model.get("id"));
                });
        } catch (e) {
            console.log("Problem deleting Alert: ", e);
        }
    }

    saveAlert() {
        let that = this;
        let saveAlertCRUD = function(model) {
            model
                .save()
                .done(() => {
                    growl.success("Alert Saved.", "Success", model.get("id"));
                    App.vent.trigger("alertmanager:add:alert", model);
                    App.vent.trigger("alertmanager:show:detail", model);
                    model.fetch();
                })
                .fail(e => {
                    growl.error("Problem saving Alert:" + e.message, "Error");
                });
        };

        //Node CPU Greater than 90%
        if (
            this.model.get("eventType") === "CPU_RATE" &&
            this.viewModel.get("condition").indexOf("CPU Rate is greater than 90%") !== -1
        ) {
            api.getMonitoringStatsWithoutTableInfo(this.model.get("objectName")).done(monitorData => {
                if (monitorData["most-recent-data"] && monitorData["most-recent-data"]["cores"]) {
                    let effective90PercentCPU = monitorData["most-recent-data"]["cores"] * 0.9; // 0.9 * num_cores
                    that.model.set("alertValue", effective90PercentCPU);
                    saveAlertCRUD(that.model);
                } else {
                    growl.error(
                        "Could not get Monitoring data for the selected Server. Please create the alert manually."
                    );
                }
            });
        } else {
            saveAlertCRUD(this.model);
        }
    }

    populateAlertModelFromViewModel() {
        const conditionKey = this.viewModel.get("condition");
        let type = converter.getType(this.viewModel.get("objectName"));
        if (!type) {
            type = this.viewModel.get("objectName");
        }
        let resource = _.findWhere(alertResources[type], { label: conditionKey });
        if (!this.model.get("id")) {
            let name = null;
            if (this.viewModel.get("alertName")) {
                name = this.viewModel.get("alertName");
            }
            this.model.set("name", name);
            this.model.set("nsName", App.user.defaultNamespace);
            this.model.set("objectName", this.viewModel.get("objectName"));
        }

        this.model.set({
            comparator: this.viewModel.get("comparator"),
            eventType: resource.eventType,
            alertValue: this.viewModel.get("alertValue"),
            isEnabled: this.viewModel.get("isEnabled"),
            alertType: this.viewModel.get("alertType"),
            alertInterval: this.viewModel.get("alertInterval")
        });

        if (this.viewModel.get("alertType") === ALERT_TYPE.EMAIL) {
            this.model.set("toAddress", this.viewModel.get("toAddress"));
        }
        if (this.viewModel.get("alertType") === ALERT_TYPE.SLACK) {
            this.model.set("toAddress", this.viewModel.get("channelName"));
        }
        if (this.viewModel.get("alertType") === ALERT_TYPE.TEAMS) {
            this.model.set("toAddress", this.viewModel.get("channelURL"));
        }
        if (this.viewModel.get("alertType") === ALERT_TYPE.WEB) {
            this.model.set("toAddress", null);
        }

        if (this.model.get("eventType") === "CPU_RATE") {
            this.model.set("alertValue", this.viewModel.get("alertValue") / 100);
        }

        if (resource.message) {
            if (this.model.get("isSysDefined")) {
                this.model.set("alertMessage", resource.message);
            } else {
                let template = _.template(resource.message);
                let objectFQN = this.viewModel.get("objectName");
                let objectName = utils.getName(objectFQN);
                let objectNS = utils.getNamespace(objectFQN);
                let alertValue = this.viewModel.get("alertValue");
                let compiled_template = template({
                    name: objectName,
                    nsName: objectNS,
                    alertValue: alertValue,
                    comparator: this.viewModel.get("comparator")
                });

                this.model.set("alertMessage", compiled_template);
            }
        }
    }

    getViewModelFromAlertModel() {
        let model = new AlertDetailViewModel();
        let that = this;
        if (this.model.get("id")) {
            let type = converter.getType(this.model.get("objectName"));
            if (!type) type = this.model.get("objectName");
            let resource = _.findWhere(alertResources[type], {
                eventType: this.model.get("eventType"),
                comparator: this.model.get("comparator"),
                alertValue: this.model.get("alertValue")
            });

            if (!resource) {
                alertResources[type].forEach(function(alertResource) {
                    if (
                        that.model.get("alertMessage").indexOf("Node CPU Greater than 90%:") !== -1 &&
                        alertResource.message.indexOf("Node CPU Greater than 90%:") !== -1
                    ) {
                        resource = alertResource;
                    }
                    if (
                        that.model.get("alertMessage").indexOf("Node memory less than 1GB") !== -1 &&
                        alertResource.message.indexOf("Node memory less than 1GB") !== -1
                    ) {
                        resource = alertResource;
                    }
                    if (
                        that.model.get("alertMessage").indexOf("Input Rate is Zero") !== -1 &&
                        alertResource.message.indexOf("Input Rate is Zero") !== -1
                    ) {
                        resource = alertResource;
                    }
                    if (
                        that.model.get("alertMessage").indexOf("Output Rate is Zero") !== -1 &&
                        alertResource.message.indexOf("Output Rate is Zero") !== -1
                    ) {
                        resource = alertResource;
                    }
                    if (
                        that.model.get("alertMessage").indexOf("Window size is zero") !== -1 &&
                        alertResource.message.indexOf("Window size is zero") !== -1
                    ) {
                        resource = alertResource;
                    }
                    if (
                        that.model.get("alertMessage").indexOf("Event Rate is Zero") !== -1 &&
                        alertResource.message.indexOf("Event Rate is Zero") !== -1
                    ) {
                        resource = alertResource;
                    }
                });
            }

            if (resource) {
                model.set("condition", resource.label);
            } else {
                resource = _.findWhere(alertResources[type], {
                    eventType: this.model.get("eventType"),
                    comparator: null,
                    alertValue: null
                });
                if (resource) {
                    model.set("condition", resource.label);
                } else {
                    // plain bulletproofing
                    model.set("condition", null);
                }
            }

            model.set({
                objectName: this.model.get("objectName"),
                alertType: this.model.get("alertType"),
                comparator: this.model.get("comparator"),
                alertValue: this.model.get("alertValue"),
                alertValueUnit: this.model.get("alertValueUnit"),
                isEnabled: this.model.get("isEnabled"),
                alertMessage: this.model.get("alertMessage"),
                alertInterval: this.model.get("alertInterval"),
                isSysDefined: this.model.get("isSysDefined")
            });
            if (this.model.get("alertType") === ALERT_TYPE.EMAIL) {
                model.set("toAddress", this.model.get("toAddress"));
            }
            if (this.model.get("alertType") === ALERT_TYPE.SLACK) {
                model.set("channelName", this.model.get("toAddress"));
            }
            if (this.model.get("alertType") === ALERT_TYPE.TEAMS) {
                model.set("channelURL", this.model.get("toAddress"));
            }
        } else {
            model.set("objectName", this.options.reference);
        }

        if (this.model.get("eventType") === "CPU_RATE") {
            model.set("alertValue", this.model.get("alertValue") * 100);
        }
        return model;
    }

    serializeData() {
        let data = this.model.toJSON();
        data["displayName"] = this.getDisplayName();
        return data;
    }

    getDisplayName() {
        if (!this.model.get("id")) {
            return "New Alert";
        } else {
            return this.model.get("name");
        }
    }

    getConditionsList(objectName) {
        var conditions = [];
        var type = converter.getType(objectName);
        var objectTypeContions = alertResources[type];
        if (!objectTypeContions) {
            objectTypeContions = alertResources[objectName];
        }
        _.each(objectTypeContions, function(condition) {
            conditions.push({
                text: condition.label,
                id: condition.label
            });
        });
        return conditions;
    }

    getComparatorsList(conditionDataType = null) {
        var conditions = [];

        conditions.push({
            text: "Less Than",
            id: "LT"
        });
        conditions.push({
            text: "Greater Than",
            id: "GT"
        });
        conditions.push({
            text: "Equal to",
            id: "EQ"
        });
        if (conditionDataType !== "number")
            conditions.push({
                text: "Like",
                id: "LIKE"
            });
        return conditions;
    }

    async setComparatorAndValue(id, comparatorControl, valueControl) {
        const objectName = this.model.get("objectName");
        let type = this.model.get("isSysDefined") ? objectName : converter.getType(objectName);
        let resource = _.find(alertResources[type], { label: id });
        if (resource !== undefined && resource.alertValue !== null) {
            valueControl.$el.find("input").attr("type", "text"); // let values decide the type.
            this.$el.find(".from-control-container-Alert_Comparator").hide();
            this.$el.find(".from-control-container-Alert_Value").hide();
            comparatorControl.setValue(resource.comparator);
            valueControl.setValue(resource.alertValue);
        } else {
            this.$el.find(".from-control-container-Alert_Comparator").show();
            this.$el.find(".from-control-container-Alert_Value").show();
            if (resource && resource.placeholder) {
                valueControl.setPlaceholder(resource.placeholder);
            }
            if (resource && resource.datatype) {
                valueControl.$el.find("input").attr("type", resource.datatype);
                const comparatorValue = comparatorControl.getValue();
                await comparatorControl.setValue(null);
                await comparatorControl.setDataSource(this.getComparatorsList(resource.datatype));
                await comparatorControl.setValue(comparatorValue);
            } else {
                valueControl.$el.find("input").attr("type", "text");
            }
        }
    }

    renderTitle() {
        const objName = this.model.get("objectName");
        if (objName) {
            const namespace = utils.getNamespace(objName);
            const name = utils.getName(objName);
            let headingText =
                `Alert on ${namespace ? namespace + "." : "all " + getSysAlertComponentType(objName)}` +
                `${name || " in all namespaces"}`;

            if (converter.getType(objName) === "APPLICATION") {
                let link = "#/flow/" + namespace + "." + name;

                headingText += `<a target="_blank" href="${link}" class="go-to-app-link" style="margin:0 12px">Go to App</a>`;
            }

            this.ui.header.html(headingText);
            this.ui.alertName.html(this.getDisplayName());
        } else {
            this.ui.alertName.html("New Alert");
        }
    }
}
