import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import _ from "underscore";
import api from "core/api/api";
import TimeAgo from "react-timeago";
import { timeagoFormatter } from "../common";
import { TYPES } from "../../../../core/services/monitorService";
import statusManagement from "src/status-management";
import config from "../../../config";

import { StriimButton, StriimTypography } from "@striim/striim-ui";
import { Grid, CircularProgress, Box } from "@material-ui/core";
import { AppStatusChip } from "src/modules/apps/pages/app-list/components/app-tile/components/app-status-indicators/app-status-indicators.jsx";
import HomepageCard from "../common/homepage_card";
import AppMetricsActions from "./app_metrics_actions/app_metrics_actions";
import AppMetricsComponent from "./app_metrics_component/app_metrics_component";
import navigateTo from "../../../navigate-to";
import useStyles from "./app_metrics.styles";

const AppMetrics = ({ appId, app }) => {
    const [loaded, setLoaded] = useState(false);
    const [updatedTime, setUpdatedTime] = useState();
    const [appData, setAppData] = useState(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const classes = useStyles();

    const setData = useCallback(data => {
        // validate data
        if (data !== null && !_.isEmpty(data)) {
            // update Data
            setAppData(data);
            setUpdatedTime(Date.now());
            // for init data load
            !loaded && setLoaded(true);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            // init app Data
            let data = await api.getMonitoringStatsForComponentsWithoutTableInfo([appId], TYPES.FULL);
            setData(data[appId]);
            // update data every 5 mins
            return setInterval(async () => {
                let data = await api.getMonitoringStatsForComponentsWithoutTableInfo([appId], TYPES.FULL);
                setData(data[appId]);
            }, config.time.homepageIntervalMontoringData);
        };
        const interval = fetchData();
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const AppMetricsHeader =
        // is loaded or in an empty state, don't show CircularProgress
        loaded || !appData
            ? {
                title: (
                    <Grid container alignItems="center">
                        <StriimTypography display="inline" variant="h2">
                            {`${appData?.name ?? ((app && app?.name) || appId)}`}
                        </StriimTypography>
                        {appData && (
                            <Box className={classes.appMetricTimeContainer}>
                                <StriimTypography display="inline" variant="h3">
                                    {" last updated "}
                                    <TimeAgo date={updatedTime} formatter={timeagoFormatter} minPeriod={60} />
                                </StriimTypography>
                            </Box>
                        )}
                        <Box className={classes.appMetricStatusContainer}>
                            <AppStatusChip
                                label={statusManagement.getStatusChipDisplayName(
                                    appData?.status ?? (app?.flowStatus || "UNKNOWN")
                                )}
                            />
                        </Box>
                    </Grid>
                ),
                action: (
                    <Grid container alignItems="center">
                        <StriimButton
                            variant="text"
                            onClick={() => {
                                navigateTo.Monitor(`${app?.nsName}.${app?.name}`);
                            }}
                        >
                            Monitor App
                        </StriimButton>
                        <AppMetricsActions setMenuVisible={setMenuVisible} appData={appData} />
                    </Grid>
                )
            }
            : {
                title: (
                    <>
                        <StriimTypography variant="h2">Trying to load app data for {app?.name}</StriimTypography>
                        <CircularProgress />
                    </>
                )
            };

    /**
     * View
     */
    return (
        <HomepageCard
            header={AppMetricsHeader}
            content={<AppMetricsComponent appData={appData} loaded={loaded} app={app} />}
        />
    );
};
export default AppMetrics;

AppMetrics.propTypes = {
    appId: PropTypes.string.isRequired
};
