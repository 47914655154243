import React from "react";
import { Box } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui";
import styles from "../create-app-page-styles";
interface CreateAppPageTemplateHeaderProps {
    hideImage: "source" | "target" | boolean;
    headerInfo: {
        title: string;
        description: string;
    };
}

const CreateAppPageTemplateHeader: React.FunctionComponent<CreateAppPageTemplateHeaderProps> = ({
    hideImage,
    headerInfo
}) => {
    return (
        <div style={styles.headerContainer}>
            <Box mb={2}>
                <StriimTypography variant="h1" color="primary.700">
                    {headerInfo.title}
                </StriimTypography>
                <Box mt={0.5} sx={styles.headerDescription}>
                    <StriimTypography variant="body4" color="greyscale.700">
                        {headerInfo.description}
                    </StriimTypography>
                </Box>
            </Box>
        </div>
    );
};

export default CreateAppPageTemplateHeader;
