import * as Yup from "yup";

export default Yup.string()
    .required("Value for namespace is required")
    .matches(/^\S+$/, "Please enter a namespace without spaces.")
    .matches(/^[a-zA-Z0-9_ ]*$/, "Please enter a namespace with only alphanumeric characters or underscore.")
    .test(
        "is-global",
        "Global is reserved for system use. Please use another namespace.",
        value => value && value.toUpperCase() !== "GLOBAL"
    );
