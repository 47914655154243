import Bowser from "bowser";

export default {
    isIE11: function () {
        return !!window.MSInputMethodContext && !!document.documentMode;
    },
};

export function isSafari() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    if (!browser || !browser.parsedResult) {
        return false;
    }

    return browser.parsedResult.browser.name.toLowerCase() === "safari";
}
