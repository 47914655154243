import { findKeyStringInsensitive } from "src/generic/icon/customIcons";

const getAddonIconKey = (planType, licenseType) => {
    planType = planType || "Standard";
    licenseType = licenseType || "Dashboards";

    const key = findKeyStringInsensitive(licenseType + "_" + planType);
    if (key) {
        return key;
    }

    planType = planType.toLowerCase();
    licenseType = licenseType.toLowerCase();

    if (planType === "standard") {
        switch (licenseType) {
            case "persistentstreams": {
                return "PersistentStream_standard_enterprise";
            }
            case "exception_store": {
                return "ExceptionStore_standard";
            }
            case "router_comp": {
                return "Router_standard";
            }
            case "external_cache": {
                return "ExternalCache_standard";
            }
            case "alertmanager": {
                return "AlertManager_standard_enterprise";
            }
            case "restapifunctions": {
                return "Restapi_standard";
            }
            case "event_table": {
                return "Eveenttable_standard";
            }
            case "mon_timeseries_report": {
                return "MonitorTimeSeriesReport_standard";
            }
            case "waction_store": {
                return "WactionStore_standard";
            }
            case "partitionedstreams": {
                return "PartitionedStream_standard_enterprise";
            }
            case "partitionedwindows": {
                return "PartitionedWindow_standard";
            }
            case "mlfunctions": {
                return "MLfunctions_standard_enterprise";
            }
            case "datavalidation": {
                return "Validation_standard_enterprise";
            }
        }
    }

    if (planType === "enterprise") {
        switch (licenseType) {
            case "bidirectional": {
                return "Bidirectional_enterprise_enterpriseplus";
            }
            case "persistentstreams": {
                return "PersistentStream_standard_enterprise";
            }
            case "alertmanager": {
                return "AlertManager_standard_enterprise";
            }
            case "partitionedwindows": {
                return "PartitionedWindow_enterprise";
            }
            case "waction_store": {
                return "WactionStore_enterprise";
            }
            case "partitionedstreams": {
                return "PartitionedStream_standard_enterprise";
            }
            case "mlfunctions": {
                return "MLfunctions_standard_enterprise";
            }
            case "datavalidation": {
                return "Validation_standard_enterprise";
            }
        }
    }

    if (planType === "enterpriseplus") {
        switch (licenseType) {
            case "bidirectional": {
                return "Bidirectional_enterprise_enterpriseplus";
            }
        }
    }

    return "Dashboards_enterprise";
};

export default getAddonIconKey;
