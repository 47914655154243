import React, { useEffect, useState } from "react";
//@ts-ignore
import { StriimButtonGroup, StriimButton } from "@striim/striim-ui-v2";
import { SvgIcon } from "@mui/material";
import { Delete } from "../../../../../../../generic/icon/customIcons";
import automatedGroupStore from "../automated-app-group-details.store";
import { useGetButtonInfo } from "../buttongroup/buttongroup-helper";
import WithTooltip from "../../../../../../../generic/tooltip/tooltip";
import { styles } from "../automated-app-group-details.styles";
import useStores from "../../../../../../../utils/use-stores";
import { deleteAutomatedGroup } from "../services";
import DeleteGroupModal from "../../app-group/delete-group-modal";
import { observer } from "mobx-react-lite";
import growl from "../../../../../../../../app/components/common/growl";

interface AutomatedAppGroupPlayerProps {
    selectedGroup: { groupType: string; name: string; applications: Array<string> };
}

const AutomatedAppGroupPlayer: React.FunctionComponent<AutomatedAppGroupPlayerProps> = ({ selectedGroup }) => {
    const { store, groupsStore } = useStores();
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const onDeleteConfirm = async () => {
        try {
            setDeleteVisible(false);
            const AppsToDelete = groupsStore.filterAppsByGroup(selectedGroup, store.apps);
            await deleteAutomatedGroup(AppsToDelete, selectedGroup.name, store, groupsStore);
        } catch (e) {
            growl.error(
                "Unable to drop pipeline! " +
                    (e?.message || "") +
                    ". Resolve the errors before retry or force drop pipeline. Contact Striim support if problem persists.",
                "Error"
            );
        }
    };

    const Player = observer(() => {
        const playerStatuses = automatedGroupStore?.statusInfo?.playerStatus;

        // To let know MObX that comoponent needs to be updated on values change
        const isSchemaReady = automatedGroupStore.isSchemaReportReady;
        const isIlReportReady = automatedGroupStore.isILReportReady;
        let stateButtonInfoProps = automatedGroupStore;
        let downloadButtonInfoProps = automatedGroupStore;
        if (automatedGroupStore.isAppTransitioning) return true;
        return (
            <React.Fragment>
                {typeof playerStatuses === "string" ? (
                    <StriimButtonGroup
                        label={playerStatuses}
                        buttonHelper={useGetButtonInfo}
                        buttonHelperProps={stateButtonInfoProps}
                    ></StriimButtonGroup>
                ) : (
                    playerStatuses?.map(status => (
                        <StriimButtonGroup
                            label={status}
                            buttonHelper={useGetButtonInfo}
                            buttonHelperProps={stateButtonInfoProps}
                        ></StriimButtonGroup>
                    ))
                )}
                <StriimButtonGroup
                    label="download"
                    buttonHelper={useGetButtonInfo}
                    buttonHelperProps={downloadButtonInfoProps}
                ></StriimButtonGroup>
                <DeleteButton />
            </React.Fragment>
        );
    });
    const DeleteButton = () => {
        let isDisabled = !automatedGroupStore.isDeletable();
        return (
            <>
                {WithTooltip(
                    <StriimButton disabled={isDisabled} variant="secondary" onClick={() => setDeleteVisible(true)}>
                        <SvgIcon component={Delete} sx={styles.deleteSVG} />
                    </StriimButton>,
                    isDisabled ? "Stop the automated pipeline to delete it" : null
                )}
            </>
        );
    };
    return (
        <React.Fragment>
            <Player />
            <DeleteGroupModal
                group={selectedGroup}
                isVisible={deleteVisible}
                onConfirm={() => onDeleteConfirm()}
                onCancel={() => setDeleteVisible(false)}
            />
        </React.Fragment>
    );
};
export default AutomatedAppGroupPlayer;
