import App from "app";
import api from "core/api/api";
import metaobjectSanitizer from "./metaobjectSanitizer";
import utils from "core/utils";
import NestedTypes from "backbone.nestedtypes";
import "./defaultmetaobject";
import "./pages";

const metadataService = App.module("Metadata.Dashboards", function (Dashboards, App, Backbone, Marionette, $, _) {
    Dashboards.addInitializer(function () {
        App.reqres.setHandler("new:metadata:dashboard", Dashboards.create);
        App.reqres.setHandler("metadata:dashboard", Dashboards.read);
        App.reqres.setHandler("metadata:dashboards", Dashboards.readAll);
    });

    // The model for a single Sidebar section
    var Button = NestedTypes.Model.extend({
        defaults: {
            enabled: true,
            data_id: String,
            fa_class: String,
            sl_icon: String, // redesign fa_class to striimline
            selected: false, // Is the button active or not?
        },
    });

    Dashboards.DashboardModel = App.Metadata.DefaultMetaObject.Model.extend({
        defaults: {
            _editable: true,
            _menuopen: false,
            isEditable: false,
            type: "DASHBOARD",
            ctime: null,
            defaultLandingPage: null,
            isPrebuilt: false,
            title: String,
            name: String, // TODO: do we need this?
            current_page: NestedTypes.options({
                value: null,
                toJSON: false,
            }),
            // pages: App.Metadata.Pages.PageModel.Collection,
            pages: NestedTypes.options({
                type: App.Metadata.Pages.PagesCollection,
                parse: function (val) {
                    var that = this;
                    return _(val).map(function (name) {
                        if (_(name).isObject()) return name; // Page may be being parsed with existing objects, if page is just being updated with serverAttrs in BB save success
                        var id = that.get("nsName") + ".PAGE." + name;
                        return {
                            id: id,
                            nsName: that.get("nsName"),
                            name: utils.getName(name),
                        };
                    }, this);
                },
            }),

            // Dashboard owns the sidebar sections' models because it needs to update their state
            sections: NestedTypes.options({
                type: Button.Collection,
                value: [
                    {
                        id: "pages-button",
                        data_id: "side-menu-page-pages",
                        fa_class: "description",
                        sl_icon: "cache2"
                    },
                    {
                        id: "visualizations-button",
                        data_id: "side-menu-page-visualizations",
                        fa_class: "insert_chart",
                        sl_icon: "chart"
                    },
                    {
                        id: "parameters-button",
                        data_id: "side-menu-page-parameters",
                        fa_class: "visibility",
                        sl_icon: "eye2"
                    },
                ],
            }),
        },
        initialize: function () {
            this.set_pages_data();

            // TODO: below uses 'change' instead of 'add' because we use SubsetOf above. Ditch SubsetOf.

            this.listenTo(this.pages, "change", this.set_pages_data);
            this.listenTo(this, "change:_editable", function () {
                this.set_pages_data();
            });

            this.set_current_page();
            this.listenTo(this, "change:current_page", this.set_current_page);

            App.Metadata.DefaultMetaObject.Model.prototype.initialize.apply(this, arguments);
        },

        sync: function (method) {
            var $deferred = new $.Deferred();

            var that = this;

            var full_id = utils.getFQN(this);
            var api_method = method.toUpperCase();

            var json_data = metaobjectSanitizer.sanitize(this.toJSON(method !== "update"));
            json_data.id = full_id;
            json_data.type = "DASHBOARD";
            var api_promise = api._CRUDHandler(undefined, api_method, json_data);
            api_promise
                .then(function(data) {
                    data = data[0];
                    data = that.parse(data);
                    that.set(data);
                    $deferred.resolve(that);
                })
                .fail(function (e) {
                    $deferred.reject(e);
                });

            return $deferred;
        },

        _getDefaultLandingPage: function (data) {
            if (!data.pages || data.pages.length === 0) {
                return null;
            }
            var defaultLandingPageName = utils.getName(data.defaultLandingPage);
            if (!defaultLandingPageName || !this._pageExists(data.defaultLandingPage, data)) {
                defaultLandingPageName = data.pages[0].name || utils.getName(data.pages[0]); // if object, use the name property, else the string.
            }
            return defaultLandingPageName;
        },
        _pageExists: function (page, data) {
            var pageExists = false;
            _.each(data.pages, function (entry) {
                if (entry.name === page) {
                    pageExists = true;
                }
            });
            return pageExists;
        },
        _getPages: function (data) {
            var _this = this;
            return _(data.pages).map(function (page) {
                if (_(page).isObject()) {
                    if (_this.pages.get(page.id)) {
                        return _this.pages.get(page.id);
                    }
                    return page;
                } else {
                    var name = utils.getName(page);
                    var id = _this.get("nsName") + ".PAGE." + name;
                    var existingmodel = _this.pages ? _this.pages.get(id) : null;
                    return existingmodel
                        ? existingmodel.toJSON()
                        : {
                              id: id,
                              name: name,
                              nsName: _this.get("nsName"),
                          };
                }
            });
        },

        parse: function (data) {
            data = App.Metadata.DefaultMetaObject.Model.prototype.parse.apply(this, [data]);
            data.pages = this._getPages(data);
            data.defaultLandingPage = this._getDefaultLandingPage(data);

            return data;
        },
        set_pages_data: function () {
            this.pages.each(
                function (page) {
                    page._editable = this._editable;
                }.bind(this)
            );
        },
        set_current_page: function () {
            this.pages.each(
                function (page) {
                    page.set("_is_current", page.name === this.current_page);
                }.bind(this)
            );
        },
        toJSON: function (useSimplePagesJson) {
            var json = App.Metadata.DefaultMetaObject.Model.prototype.toJSON.apply(this, arguments);
            if (useSimplePagesJson !== false) {
                json.pages = _(json.pages).map(function (page) {
                    return page.name;
                });
            }
            return json;
        },
    });

    Dashboards.DashboardsCollection = Backbone.Collection.extend({
        model: Dashboards.DashboardModel,

        sync: function (method) {
            var $deferred = new $.Deferred();

            var that = this;

            var api_method = method.toUpperCase();
            var json_data = {};
            json_data.type = "DASHBOARD";

            var api_promise = api._CRUDHandler(undefined, api_method, json_data);
            api_promise
                .then(function(data) {
                    _.each(data, function (dashboard) {
                        var pages = [];
                        _.each(dashboard.pages, function (page) {
                            pages.push({
                                name: utils.getName(page),
                                nsName: dashboard.nsName,
                            });
                        });
                        dashboard.pages = pages;
                        var model = new Dashboards.DashboardModel(dashboard);
                        if (!model.isPrebuilt) {
                            that.add(model);
                        }
                    });
                    $deferred.resolve(that);
                })
                .fail(function (e) {
                    $deferred.reject(e);
                });

            return $deferred;
        },
    });

    Dashboards.create = function () {
        return new Dashboards.DashboardModel();
    };

    Dashboards.read = function (dashboardid) {
        return Dashboards.readAll().then(function (dashboards) {
            return _.findWhere(dashboards.models, {
                uuid: dashboardid,
            });
        });
    };

    Dashboards.readAll = function () {
        var dashboards = new Dashboards.DashboardsCollection();

        var deferred = $.Deferred();
        dashboards
            .fetch()
            .then(function(data, textStatus, jqXHR) {
                deferred.resolve(dashboards, data, textStatus, jqXHR);
            })
            .fail(deferred.reject);
        return deferred.promise();
    };

    Dashboards.save = function () {};
});

export default metadataService;
