import React from "react";
import Grid from "@mui/material/Grid";
import { StriimTypography, StriimButton } from "@striim/striim-ui";
import { useNavigate } from "react-router";

import DocumentationIcon from "src/generic/icon/manage-striim/documentation.svg";
import theme from "../../../../../../theme";
import MANAGE_STRIIM_ROUTES from "../../routes.json";
import Dictionary from "app/components/common/helpable/online-help-dictionary";

const styles = {
    container: {
        height: "36px"
    },
    documentationIcon: {
        color: "#FFF",
        fontSize: "20px",
        display: "flex",
        marginLeft: theme.spacing(1)
    }
};

interface ConnectionProfilesListHeaderProps {
    showAddButton: boolean;
}

const ConnectionProfilesListHeader = ({ showAddButton = false }: ConnectionProfilesListHeaderProps) => {
    const navigate = useNavigate();

    const handleAddClick = () => {
        navigate(MANAGE_STRIIM_ROUTES.createConnectionProfile);
    };

    return (
        <Grid container display="flex" justifyContent="space-between" sx={styles.container}>
            <Grid item display="flex" alignItems="center">
                <StriimTypography variant="h2">Connection Profiles</StriimTypography>
                <a
                    href={Dictionary.get()["MANAGE_STRIIM_CONNECTION_PROFILE"].href}
                    target="_blank"
                    style={{ ...styles.documentationIcon }}
                >
                    <DocumentationIcon />
                </a>
            </Grid>
            {showAddButton && (
                <Grid item>
                    <StriimButton
                        variant="primary"
                        data-test-id="add-connection-profile-button"
                        onClick={handleAddClick}
                    >
                        Add connection profile
                    </StriimButton>
                </Grid>
            )}
        </Grid>
    );
};

export default ConnectionProfilesListHeader;
