import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ breakpoints, spacing }) => ({
    wrapper: {
        marginTop: spacing(12),
        marginBottom: spacing(12),
        [breakpoints.up("lg")]: {
            paddingLeft: 176,
            paddingRight: 176,
        },
        [breakpoints.down("md")]: {
            paddingLeft: spacing(4),
            paddingRight: spacing(4),
        },
    },
    container: {
        width: "100%",
        padding: 0,
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: spacing(12),
    },
    topContainer: {
        paddingInline: 192,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#fcb7b7",
    },
    boxContainer: {
        background: "#FFFFFF",
        borderRadius: 6,
        padding: "12px 16px",
        width: "100%",
        margin: spacing(2, 0)
    },
    header: {
        fontSize: 24,
        fontWeight: 600
    },
    h2: {
        fontSize: 20,
        fontWeight: 600,
    },
    paragraph: {
        color: "#4E5C62",
        fontSize: 20,
    },
    subtitle: {
        color: "#4E5C62",
        fontSize: 18,
        fontWeight: 400,
        paddingTop: 6
    },
    desc: {
        width: 610,
        marginLeft: 30,
        [breakpoints.down("sm")]: {
            width: "100%"
        },
    },
    btnContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 24px",
        width: "208px",
        height: "41px",
        background: "#F3CA49",
        borderRadius: "1000px",
        border: "1px solid #F3CA49",
        cursor: "pointer"
    },
    btnLabel: {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#122036",
    },
    arrowForwardIcon: {
        marginLeft: 5,
        color: "#122036"
    },
    img: {
        width: 70,
        margin: spacing(2, 0)
    },
    img1: {
        width: 230
    }
}));
