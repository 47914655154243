export const styles = {
    container: {
        height: "calc(100% - 335px)"
    },
    icon: {
        width: 20,
        height: 20,
        fill: ({ palette }) => palette.greyscale[50],
        "& > path": {
            stroke: ({ palette }) => palette.greyscale[900]
        }
    }
};
