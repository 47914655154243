import { makeStyles } from "@material-ui/core/styles";

export const styles = {
    aiText: {
        background: "linear-gradient(90deg, #F31779 0.1%, #7930D9 52.98%, #0C6FE8 99.87%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
    },
    aiIcon: {
        height: "16px",
        width: "auto"
    },
    iconButton: {
        minWidth: "unset",
        px: 1,
        py: 1 / 2,
        "& .MuiButton-startIcon": {
            mx: 0
        }
    },
    icon: {
        fontSize: 16,
        fill: "none",
        "& > path": {
            stroke: ({ palette }) => palette.secondary[500]
        }
    },
    disabledIcon: {
        "& > path": {
            stroke: ({ palette }) => palette.greyscale[400]
        }
    },
    backIcon: {
        fontSize: "20px !important"
    },
    chevronIcon: {
        fontSize: "10px !important",
        fill: "none",
        "& > path": {
            fill: ({ palette }) => palette.greyscale[800]
        }
    },
    guardianIcon: {
        width: 14,
        height: 14,
        fill: "none"
    },
    caption: {
        fontFamily: "Inter",
        color: "greyscale.900",
        letterSpacing: "0.2px"
    },
    closeIcon: {
        fontSize: 20,
        fill: "none",
        cursor: "pointer"
    },
    buttonIcon: {
        fontSize: 16,
        fill: "none"
    },
    lockIcon: {
        fontSize: 16,
        fill: "none",
        "& > path": {
            stroke: ({ palette }) => palette.greyscale[50]
        }
    },
    bottomBorder: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    divider: {
        width: "1px",
        height: "16px",
        backgroundColor: "greyscale.200"
    },
    menuList: {
        minWidth: 350
    },
    downloadButton: {
        width: "20px",
        height: "20px",
        fill: "none",
        "& path": {
            stroke: ({ palette }) => palette.secondary[500]
        }
    }
};

export const useStyles = makeStyles(() => ({
    componentIcon: {
        width: "40px",
        height: "40px"
    },
    sourceIconWrapper: {
        width: "24px",
        height: "24px"
    },
    sourceIcon: {
        padding: "6px"
    }
}));
