import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing }) => {
    return {
        appMetricStatusContainer: {
            marginLeft: spacing(2)
        },
        appMetricTimeContainer: {
            marginLeft: spacing(1)
        }
    };
});
