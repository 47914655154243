import App from "app";
import React, { useEffect } from "react";
import {
    DisclaimerSection,
    LogoSection,
    Section,
    SherlockGlobalReportTitle,
    TimeFilterSection
} from "../components/report-components";
import { Box, Grid } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import {
    SourcesWithSensitiveDataTable,
    TopSDIWithApps,
    TopSDIWithEventsAndApps
} from "../components/report-tables/sherlock-report-tables";
import SensitiveDataIdentifierChart from "../../guardian/components/sensitive-data-identifier-chart/sensitive-data-identifier-chart";
import { getTopAppsData } from "../../guardian/components/job-list-insights-view/job-list-insights-view";
import { TopHighImportanceSDIs } from "./sherlock-report-components";
import { InsightsChart } from "../components/report-charts/sherlock-report-charts";

const SherlockGlobalReport = ({ reportData, triggerDownload }) => {
    useEffect(() => {
        setTimeout(() => triggerDownload(), 500);
    }, [reportData]);

    return (
        <Grid>
            <LogoSection />
            <Grid m={5}>
                <SherlockGlobalReportTitle
                    appCount={reportData.appDataStatus.totalApps}
                    sourceCount={reportData.appDataStatus.totalSources}
                    username={App?.user?.name ?? ""}
                />
                <TimeFilterSection selectedRange={reportData.timeRange} />
                <DisclaimerSection type={"Sherlock"} />
                <Section showborderBottom={false}>
                    <Box my={2}>
                        <StriimTypography variant="h1">Summary</StriimTypography>
                        <Box mt={2}>
                            <StriimTypography variant="h3">
                                Top 10 Sensitive Data Identifiers Discovered
                            </StriimTypography>
                        </Box>
                    </Box>
                    <TopSDIWithApps data={reportData.chartData} totalApps={reportData.appDataStatus.totalApps} />
                </Section>
                <Section>
                    <InsightsChart appDataStatus={reportData.appDataStatus} jobStatus={reportData.jobStatus} />
                </Section>
                <Section showborderBottom={false}>
                    <Box my={2}>
                        <SensitiveDataIdentifierChart
                            title="Top 15 Apps w/ Sensitive Data"
                            rightActionButton={null}
                            data={getTopAppsData(reportData.appsList)}
                            chartTitles={["APP NAME", "ENTITIES"]}
                            onLabelClick={null}
                            isReport={true}
                        />
                    </Box>
                </Section>
                <Section showborderBottom={false}>
                    <Grid container my={2} gap={2}>
                        <StriimTypography variant="h3">Top 5 Sensitive Data Identifiers</StriimTypography>
                        <TopSDIWithEventsAndApps
                            data={reportData.chartData}
                            totalApps={reportData.appDataStatus.totalApps}
                            totalEntities={reportData.appDataStatus.sensitiveData}
                        />
                    </Grid>
                </Section>
                <Section showborderBottom={false}>
                    <Grid container my={2} gap={2}>
                        <StriimTypography variant="h3">Top 5 Sources with Sensitive Data</StriimTypography>
                        <SourcesWithSensitiveDataTable data={reportData.appsDetails.slice(0, 5)} />
                    </Grid>
                </Section>
                <Section>
                    <TopHighImportanceSDIs identifiers={reportData.identifiers} />
                </Section>
            </Grid>
        </Grid>
    );
};

export default SherlockGlobalReport;
