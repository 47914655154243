import React, { Dispatch, SetStateAction, useState } from "react";
import { StriimModal, StriimTypography } from "@striim/striim-ui";
import automatedGroupStore from "./automated-app-group-details.store";
import metaobjectConverter from "../../../../../../../core/services/metaStoreService/metaobject-converter";
import api from "../../../../../../../core/api/api";
import growl from "../../../../../../../app/components/common/growl";
import LoadingIndicator from "../../../../../../generic/loading-indicator/loading-indicator";
import Tracker from "../../../../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../../../core/services/tracker/constants";

interface GenericModalProps {
    isVisible: boolean;
    setisVisible: Dispatch<SetStateAction<boolean>>;
    groupName: string;
    fullAppName: string;
}

const getModalInfo = (groupName, appName) => {
    const appGroupFQN = automatedGroupStore.groupName.id;
    const trackEventClicked = (buttonClicked) => {
        Tracker.getInstance().track(TRACKER_STRINGS.schema.automatedAppGroup, {
            event: TRACKER_STRINGS.eventClicked.automatedAppGroup,
            buttonClicked: buttonClicked,
            appType: "IL & CDC"
        });
    }
    return {
        default: {
            title: "Stop " + groupName + " automated pipeline",
            content:
                "If you proceed, " +
                appName +
                " will be stopped and then undeployed immediately. Any data currently being processed will be discarded, and the monitoring metrics will be reset. You can resume the automated pipeline anytime.",
            confirmContent: "Stop & Undeploy",
            onConfirm: async () => {
                await api.stopILCDC(appGroupFQN);
                await api.undeployILCDC(appGroupFQN);
                trackEventClicked("Stop & Undeploy");
            }
        },
        inflight: {
            title: "Write inflight data and stop " + groupName + " automated pipeline (Quiesce)",
            content:
                "If you proceed, " +
                appName +
                " will be stopped after all inflight data is written to the target. You can resume the automated pipeline anytime.",
            confirmContent: "Stop",
            onConfirm: async () => {
                await api.quiesceILCDC(appGroupFQN);
                trackEventClicked("Quiesce");
            }
        },
        undeploy: {
            title: "Stop " + groupName + " automated pipeline without undeploying",
            content:
                "If you proceed, " +
                appName +
                " will be stopped immediately, and any data currently being processed will be discarded. The apps will remain deployed on their respective nodes. You can resume the automated pipeline anytime.",
            confirmContent: "Stop",
            onConfirm: async () => {
                await api.stopILCDC(appGroupFQN);
                trackEventClicked("Stop & Undeploy");
            }
        },
        reset: {
            title: "Reset " + groupName,
            content:
                "If you proceed, Striim will try to stop and undeploy both the apps and bring them to a CREATED state",
            confirmContent: "Reset",
            onConfirm: async () => {
                await api.resetILCDC(appGroupFQN);
                trackEventClicked("Reset");
            }
        }
    };
};
const AppGroupConfirmationModal = ({ isVisible, setisVisible, groupName, fullAppName }: GenericModalProps) => {
    const appName = fullAppName ? metaobjectConverter.getName(fullAppName) : null;
    const currentInfo = getModalInfo(groupName, appName)[automatedGroupStore.stopType];
    const [isLoading, setisLoading] = useState<boolean>(false);

    return (
        <StriimModal
            autoHeight
            confirmContent={currentInfo?.confirmContent}
            onCancel={() => {
                setisVisible(false);
            }}
            onConfirm={async () => {
                try {
                    automatedGroupStore.setisAppTransitioning(true);
                    setisLoading(true);
                    await currentInfo?.onConfirm();
                } catch (e) {
                    growl.error(e);
                } finally {
                    setisLoading(false);
                    setisVisible(false);
                    automatedGroupStore.setisAppTransitioning(false);
                }
            }}
            isVisible={isVisible}
            size={"Small"}
            confirmButtonProps={{
                disabled : isLoading
            }}
            cancelButtonProps={{
                disabled : isLoading
            }}
            titleContent={<StriimTypography variant="h2">{currentInfo?.title}</StriimTypography>}
        >
            {isLoading && <LoadingIndicator isGlobal={false} />}
            <StriimTypography variant="body4" color="greyscale.800">
                {currentInfo?.content}
            </StriimTypography>
        </StriimModal>
    );
};

export default AppGroupConfirmationModal;
