import ApplicationModel from "./application-model";
import AppListStore from "./app-list-store";
import { AppAction } from "src/status-management";
import { getSources, getTargets } from "src/source-target-list/fake-data";
import { v4 as uuid } from "uuid";

const sources = getSources();
const targets = getTargets();

const flowStatuses = ["CREATED", "DEPLOYED", "RUNNING", "CRASH"];

const dbEngines = ["InnoDB", "MyISAM", "MEMORY", "CSV", "BLACKHOLE", "ARCHIVE"];

const getRandomArrayValue = array => {
    return array[Math.floor(Math.random() * array.length)];
};

const getStats = () => {
    return {
        // Reader Data
        "source-input": Math.floor(Math.random() * 100),
        "source-rate": Math.floor(Math.random() * 50),
        "last-io-time": new Date(),
        // Writer Data
        "target-output": Math.floor(Math.random() * 100),
        "target-rate": Math.floor(Math.random() * 50),
        "last-io-time": new Date(),
        "table-info": '{"QATEST.GHIRI": {}}'
    };
};

const getRandomArrayValues = (array, count, addStats) => {
    const result = [];
    for (let i = 0; i < count; i++) {
        result.push(getRandomArrayValue(array));
        if (addStats) result[i]["most-recent-data"] = getStats();
    }
    return result;
};

const namespaces = ["admin", "test", "posapp"];

const getApp = (name, addStats = false) => {
    let applicationModel = new ApplicationModel();
    applicationModel.id = name ?? uuid();
    applicationModel.name = `${getRandomArrayValue(dbEngines)} to ${getRandomArrayValue(dbEngines)}`;
    applicationModel.nsName = namespaces[Math.floor(Math.random() * 2)];
    applicationModel.flowStatus = getRandomArrayValue(flowStatuses);
    applicationModel.sources = getRandomArrayValues(sources, 3, addStats);
    applicationModel.targets = getRandomArrayValues(targets, 3, addStats);
    applicationModel.wactions = Math.floor(Math.random() * 10000);
    applicationModel.rate = Math.floor(Math.random() * 10000);

    return applicationModel;
};

const flows = [
    { name: "Flow1", id: "admin.FLOW.Flow1" },
    { name: "Flow2", id: "admin.FLOW.Flow2" },
    { name: "Flow3", id: "admin.FLOW.Flow3" }
];
const rawGroups = ["default", "Agent"];
const groups = rawGroups.map(rawGroup => {
    return {
        value: rawGroup,
        label: rawGroup
    };
});

const fakeApi = {
    getAllApplicationsTQL: appIds => {
        let result = {};
        appIds.forEach(x => (result[x] = "SELECT * FROM " + x));
        return result;
    }
};

class FakeAppListStore extends AppListStore {
    constructor() {
        super(fakeApi);
    }

    fetchApps = async () => {};
    getStats = async () => {};
    listenToAppStatusChange = () => {};
    getDeploymentGroups = () => {
        return groups;
    };
    getFlows = () => {
        return flows;
    };
    onAppAction = async (app, action) => {
        switch (action) {
            case AppAction.VIEW:
                break;
            case AppAction.MONITOR:
                break;
            case AppAction.EXPORT:
                break;
            case AppAction.START:
                break;
            case AppAction.STOP:
                break;
            case AppAction.QUIESCE:
                break;
            case AppAction.UNDEPLOY:
                break;
            case AppAction.DEPLOY:
                break;
            case AppAction.RESUME:
                break;
            case AppAction.DROP:
                break;
            case AppAction.DASHBOARD:
                break;
            case AppAction.SHOW_ERRORS:
                break;
            case AppAction.CHANGE_GROUP:
                break;
        }
    };
}

export const createStore = itemsCount => {
    const apps = [];

    apps.push(getApp("Fake_App", true));
    apps.push(getApp("Fake_App_CDC", true));
    for (let i = 0; i < (itemsCount - 2 || 10); i++) {
        apps.push(getApp());
    }

    let fakeApi;
    let metaStoreService;

    var store = new FakeAppListStore(fakeApi, metaStoreService, {});
    store.apps = apps;

    return store;
};
