import Backbone from "backbone";
import NestedTypes from "backbone.nestedtypes";
import $ from "jquery";
import _ from "underscore";
import api from "core/api/api";
import dataTypes from "core/data-types";
import ignoredPatternsFilter from "app/components/common/ignoredPatternsFilter";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import PasswordEncryptor from "../password-encryptor";
import NameFormatter from "app/components/common/editor/name-formatter";

const baseMetaObject = {};

baseMetaObject.Model = NestedTypes.Model.extend({
    _actions: {},
    idAttribute: "id",
    defaults: {
        id: String,
        type: String,
        /** should override **/
        name: String,
        nsName: String,
        namespaceId: String,
        metaObjectClass: String,
        metaInfoStatus: dataTypes.MetaInfoStatus,
        description: "",
        uri: String,
        uuid: null,
        version: Number,
        ctime: Number,
        statusInfo: Object,
        isEditable: true,
    },
    properties: {
        defaultHeader: function () {
            return NameFormatter.format(this.type);
        },
        header: function () {
            return this.defaultHeader;
        },
    },

    constructor: function (data) {
        data = this.parse(data);
        this._cleanPropertiesOtherThanDefaults(data);
        this.passwordEncryptor = new PasswordEncryptor(propertyTemplateService);
        NestedTypes.Model.apply(this, arguments);
    },
    isNew: function () {
        return this.get("uuid") === null;
    },
    fetchMetaObject: function () {
        var def = $.Deferred();
        def.resolve(this);
        return def.promise();
    },

    save: function (options) {
        // we need to create our own deferred object, because Backbone's save method might return 'false' and not always a promise,
        // so we wrap it all in our promise. This makes it easier for final model user to handle errors.
        const deferred = $.Deferred();
        this.passwordEncryptor.ensurePasswordsEncrypted(this);
        const response = Backbone.Model.prototype.save.apply(this, [undefined, options]); // let the internal function call sync instead.
        if (response) {
            response.then(
                function (result) {
                    deferred.resolve(result);
                },
                function (result) {
                    deferred.reject(result);
                }
            );
        } else {
            deferred.reject();
        }

        return deferred.promise();
    },
    sync: function (method, model, options) {
        var api_method = method.toUpperCase();
        var $deferred = new $.Deferred();
        var that = this;
        options = options || {};
        if ((method === "create" || method === "update") && !that.isValid()) {
            $deferred.reject(that.validationError);
            return $deferred;
        }
        var JSON_value = this.toJSON();
        _.each(JSON_value, function (obj, i) {
            if (
                obj === null ||
                obj === "" ||
                obj === undefined ||
                (_.isObject(obj) && !_.isArray(obj) && _.isEmpty(obj))
            ) {
                delete JSON_value[i];
            }
        });
        var api_promise = api._CRUDHandler(options.flow, api_method, JSON_value);
        api_promise
            .then(function(data) {
                data = data[0];
                data = that.parse(data);
                that.set(that._cleanPropertiesOtherThanDefaults(data));
                $deferred.resolve(that);
            })
            .fail(function (e) {
                $deferred.reject(e);
            });

        return $deferred.promise();
    },
    getActions: function () {
        return this._actions;
    },
    parse: function (data) {
        return data;
    },
    validate: function () {
        var actions = propertyTemplateService.getActions(this.get("type"));
        actions = actions || {};
        var that = this;
        var validationError = {};
        _.each(actions, function (value, key) {
            var keyValue = that._retrieveValueByKey(key);
            var isObject = $.isPlainObject(keyValue);
            var isArray = $.isArray(keyValue);

            if (value.isRequired) {
                var isEmptyValue = isArray
                    ? $.isEmptyObject(keyValue)
                    : keyValue === "" || keyValue === null || keyValue === undefined;
                if (isObject ? $.isEmptyObject(keyValue) : isEmptyValue) {
                    validationError[that._retrieveMainKey(key)] = "Value for " + key + " is required";
                }
            }
        });
        return _.isEmpty(validationError) ? undefined : validationError;
    },
    getNameWithNamespace: function () {
        return this.nsName + "." + this.name;
    },
    _retrieveMainKey: function (key) {
        return key.split(".")[0];
    },
    _retrieveValueByKey: function (key) {
        var parts = key.split(".");
        var result = this.toJSON();
        parts.forEach(function (part) {
            result = result[part];
        });
        return result;
    },
    _cleanPropertiesOtherThanDefaults: function (data) {
        var keys = Object.keys(this.__defaults);
        for (var key in data) {
            if (keys.indexOf(key) === -1) {
                delete data[key];
            }
        }
        return data;
    },
});

baseMetaObject.Collection = NestedTypes.Collection.extend({
    sync: function (method, collection, options) {
        var api_method = method.toUpperCase();
        var $deferred = new $.Deferred();
        if (api_method === "READ") {
            var temp_model = new collection.model();
            var type = temp_model.get("type");
            options = options || {};
            return this._fetchFresh(collection, $deferred, type, options);
        } else {
            $deferred.reject("Only READ operations are allowed on collections"); //@TODO aswin - is this the right way?
        }
        return $deferred.promise();
    },
    _fetchFresh: function (collection, $deferred, type, options) {
        var api_promise = api._CRUDHandler(options.flow, "READ", {
            type: type,
        });

        api_promise
            .then(function(data) {
                const filteredData = data.filter(ignoredPatternsFilter);
                filteredData.forEach(model_data => {
                    var actions = {};
                    if (model_data._actions) {
                        actions = model_data._actions;
                        model_data._actions = undefined;
                    }
                    var model = new collection.model(model_data);
                    model._actions = actions;
                    collection.add(model);
                });
                $deferred.resolve(collection);
            })
            .fail(function (e) {
                $deferred.reject(e);
            });
        return $deferred.promise();
    },
});

export default baseMetaObject;
