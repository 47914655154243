import React from "react";
import { components } from "react-select";
import { Box, Grid } from "@mui/material";
import { StriimLink, StriimTypography } from "@striim/striim-ui";

import { OAUTH_FIELDS_KEYS, OAUTH_FIELDS, OAUTH_URL_PROPERTIES } from "./constants";
import { styles } from "./connection-profiles-list/connection-profile-list.styles";
import DataSource from "../../../../../../app/components/common/editor/control/property-template/property-template-datasource";
import { getCPNameValidation, namespaceValidation } from "./cp-name.validation";
import CloseIcon from "src/generic/icon/manage-striim/close.svg";

const getOAuthFieldValues = values => {
    const oauthValues = Object.keys(values).reduce((previous, current) => {
        if (OAUTH_FIELDS.includes(current)) {
            previous[current] = values[current];
        }
        return previous;
    }, {});
    return oauthValues;
};

const getTokenExpiresAtTimeInSeconds = (dateTime, expiresIn = 0) => {
    const timeMilliseconds = dateTime ? new Date(dateTime).getTime() : new Date().getTime();
    const timeSeconds = Math.floor(timeMilliseconds / 1000) + expiresIn;
    return timeSeconds;
};

const getOAuthResponseFormatted = response => {
    let formattedProperties = Object.keys(response).reduce((previous, current) => {
        if (OAUTH_FIELDS.includes(current)) {
            previous[current] = response[current];
        }
        return previous;
    }, {});

    if (formattedProperties[OAUTH_FIELDS_KEYS.ExpiresIn]) {
        formattedProperties[OAUTH_FIELDS_KEYS.ExpiresAt] = getTokenExpiresAtTimeInSeconds(
            null,
            formattedProperties[OAUTH_FIELDS_KEYS.ExpiresIn]
        );
    } else if (formattedProperties[OAUTH_FIELDS_KEYS.ExpiresAt]) {
        formattedProperties[OAUTH_FIELDS_KEYS.ExpiresAt] = getTokenExpiresAtTimeInSeconds(
            formattedProperties[OAUTH_FIELDS_KEYS.ExpiresAt],
            0
        );
    }
    //specific to snowflake
    if (formattedProperties[OAUTH_FIELDS_KEYS.RefreshTokenExpiresIn]) {
        formattedProperties[OAUTH_FIELDS_KEYS.RefreshTokenExpiresAt] = getTokenExpiresAtTimeInSeconds(
            null,
            formattedProperties[OAUTH_FIELDS_KEYS.RefreshTokenExpiresIn]
        );
    }
    formattedProperties[OAUTH_FIELDS_KEYS.OAuthResponse] = JSON.stringify(response);
    return formattedProperties;
};

const endpointFields = (fdEndpoint, namespaceOptions, endPoints, isEditMode) => {
    const endpointNameVisibility = fdEndpoint !== null && endPoints?.length == 1 ? "false" : "true";
    return [
        {
            required: true,
            type: "java.lang.String",
            defaultValue: "",
            group: "",
            visibility: "",
            viewWeight: 0,
            name: "connectionProfileName",
            label: "Connection Profile Name",
            disabled: isEditMode,
            validationSchema: getCPNameValidation(isEditMode)
        },
        {
            required: true,
            type: "Enum",
            defaultValue: "",
            group: "",
            viewWeight: 0,
            values: namespaceOptions,
            name: "namespace",
            label: "Namespace",
            disabled: isEditMode,
            validationSchema: namespaceValidation
        },
        {
            required: true,
            type: "Enum",
            defaultValue: "",
            group: "",
            visibility: endpointNameVisibility,
            viewWeight: 0,
            values: endPoints,
            name: "endpointName",
            label: "Endpoint Name",
            disabled: isEditMode,
            additionalInputProps: {
                components: {
                    Option: componentProps => {
                        return (
                            <components.Option {...componentProps}>
                                <Grid display={"flex"} alignItems={"center"} gap={1}>
                                    <img src={componentProps.data.icon} style={styles.icon} />
                                    <div>{componentProps.data.label}</div>
                                </Grid>
                            </components.Option>
                        );
                    }
                }
            }
        }
    ];
};

const getOAuthURL = (authrHostURL, oauthFields, idp, values, passwordFields = []) => {
    let url = `${authrHostURL}/do/${idp}/init?origin=${authrHostURL}`;
    let propertiesInURL = oauthFields.reduce((previous, current) => {
        let propertyValue = passwordFields.includes(current) ? values[current] : values[current]?.trim();
        if (propertyValue) {
            if (current.toLowerCase() === OAUTH_URL_PROPERTIES.host.toLowerCase()) {
                propertyValue = `https://${propertyValue}`;
            }
            previous += `&${OAUTH_URL_PROPERTIES[current.toLowerCase()]}=${encodeURIComponent(propertyValue)}`;
        }
        return previous;
    }, "");
    url = `${url}${propertiesInURL}`;
    return url;
};

const formatMessage = (message, description, options) => {
    const formattedMessage = (
        <Box display={"flex"} justifyContent={"space-between"}>
            <div>
                {message ? (
                    <div>
                        <StriimTypography variant="body3">{message}</StriimTypography>
                    </div>
                ) : null}
                {description ? (
                    <>
                        {description["Root Cause"] && (
                            <div>
                                <StriimTypography variant="body3">Root Cause: </StriimTypography>
                                <StriimTypography variant="body4" sx={styles.responseTextValue}>
                                    {description["Root Cause"]}
                                </StriimTypography>
                            </div>
                        )}
                        {description["Summary"] && (
                            <div>
                                <StriimTypography variant="body3">Summary: </StriimTypography>
                                <StriimTypography variant="body4" sx={styles.responseTextValue}>
                                    {description["Summary"]}
                                </StriimTypography>
                            </div>
                        )}
                        {description["Suggested Action"] && (
                            <div>
                                <StriimTypography variant="body3">Suggested Action: </StriimTypography>
                                <StriimTypography variant="body4" sx={styles.responseTextValue}>
                                    {description["Suggested Action"]}
                                </StriimTypography>
                            </div>
                        )}
                        {options?.warning && (
                            <div>
                                <StriimTypography variant="body3">Warning: </StriimTypography>
                                <StriimTypography variant="body4" sx={styles.responseTextValue}>
                                    {options.warning}
                                </StriimTypography>
                            </div>
                        )}
                        {description["Documentation Link"] && (
                            <div>
                                <StriimLink href={description["Documentation Link"]} target="_blank">
                                    View Documentation
                                </StriimLink>
                            </div>
                        )}
                    </>
                ) : (
                    ""
                )}
            </div>
            {options?.onClose && (
                <div>
                    <CloseIcon style={styles.closeIcon} onClick={options.onClose} />
                </div>
            )}
        </Box>
    );

    return formattedMessage;
};

const formatAuthrResponse = (errorData, options) => {
    const formattedMessage = (
        <Box display={"flex"} justifyContent={"space-between"}>
            <div>
                {errorData ? (
                    <>
                        {errorData.root_cause && (
                            <div>
                                <StriimTypography variant="body3">Root Cause: </StriimTypography>
                                <StriimTypography variant="body4" sx={styles.responseTextValue}>
                                    {errorData.root_cause}
                                </StriimTypography>
                            </div>
                        )}
                        {errorData.summary && (
                            <div>
                                <StriimTypography variant="body3">Summary: </StriimTypography>
                                <StriimTypography variant="body4" sx={styles.responseTextValue}>
                                    {errorData.summary}
                                </StriimTypography>
                            </div>
                        )}
                        {errorData.suggested_action && (
                            <div>
                                <StriimTypography variant="body3">Suggested Action: </StriimTypography>
                                <StriimTypography variant="body4" sx={styles.responseTextValue}>
                                    {errorData.suggested_action}
                                </StriimTypography>
                            </div>
                        )}
                        {errorData.doc_link && (
                            <div>
                                <StriimLink href={errorData.doc_link} target="_blank">
                                    View Documentation
                                </StriimLink>
                            </div>
                        )}
                    </>
                ) : (
                    <div>Error fetching</div>
                )}
            </div>
            {options?.onClose && (
                <div>
                    <CloseIcon style={styles.closeIcon} onClick={options.onClose} />
                </div>
            )}
        </Box>
    );
    return formattedMessage;
};

const getFormattedEndpointName = value => {
    let endpoint = value === "ADLSGen2" ? "azure" : value;
    let imgPath = "src/generic/icon/manage-striim/" + endpoint?.toLowerCase() + ".svg";
    return (
        <>
            <img style={styles.icon} src={imgPath} alt={endpoint} />
            {value}
        </>
    );
};

const getEndpointNameWithIcon = value => {
    let endpoint = value === "ADLSGen2" ? "azure" : value;
    let icon = "src/generic/icon/manage-striim/" + endpoint?.toLowerCase() + ".svg";
    return {
        label: value,
        value,
        icon
    };
};

const setButtonVisibility = (buttonRef, visibility) => {
    if (buttonRef && buttonRef.current) buttonRef.current.style.display = visibility ? "flex" : "none";
};

const getCPValues = connectionProfile => {
    let properties = { ...connectionProfile.properties };
    let propertyTemplatesStore = DataSource("connectionprofile");
    const endpointFields = propertyTemplatesStore.find(
        data => data.id === `Global.PROPERTYTEMPLATE.${connectionProfile.endPoint}`
    );
    properties = Object.keys(properties).reduce((accumulated, current) => {
        accumulated[current.toLowerCase()] = properties[current];
        return accumulated;
    }, {});

    const fields = JSON.parse(JSON.stringify(endpointFields?.refModel.attributes.propertyMap));
    return fields.reduce((accumulated, current) => {
        let name = current.name.toLowerCase();
        let value = properties[name];
        accumulated[current.name] = value;
        return accumulated;
    }, {});
};

export {
    endpointFields,
    getOAuthResponseFormatted,
    getOAuthURL,
    getOAuthFieldValues,
    formatAuthrResponse,
    formatMessage,
    getFormattedEndpointName,
    getEndpointNameWithIcon,
    setButtonVisibility,
    getCPValues
};
