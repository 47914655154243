import React from "react";
import { Box, Grid } from "@mui/material";
import AppDeployTable from "../app-deploy-table/app-deploy-table";

import { DeploymentInfoType } from "../automated-group-deploy-modal";
import statusManagement from "../../../../../../../../status-management";

interface DeployModalContentProps {
    appIds: string[];
    ilDeploymentInfo: DeploymentInfoType;
    setIlDeploymentInfo: (value: DeploymentInfoType) => void;
    cdcDeploymentInfo: DeploymentInfoType;
    setCdcDeploymentInfo: (value: DeploymentInfoType) => void;
    appStatuses: Record<string, string>;
}
const DeployModalContent: React.FunctionComponent<DeployModalContentProps> = ({
    appIds,
    ilDeploymentInfo,
    setIlDeploymentInfo,
    cdcDeploymentInfo,
    setCdcDeploymentInfo,
    appStatuses
}) => {
    const [ilAppStatus, cdcAppStatus] = Object.values(appStatuses);
    return (
        <div>
            {statusManagement.isApplicationCreated(ilAppStatus) && (
                <AppDeployTable appId={appIds[0]} formValues={ilDeploymentInfo} setFormValues={setIlDeploymentInfo} />
            )}
            <Box mt={1}>
                {statusManagement.isApplicationCreated(cdcAppStatus) && (
                    <AppDeployTable
                        appId={appIds[1]}
                        formValues={cdcDeploymentInfo}
                        setFormValues={setCdcDeploymentInfo}
                    />
                )}
            </Box>
        </div>
    );
};
export default DeployModalContent;
