import React, { useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import { observer } from "mobx-react";
import numeral from "numeral";
import Tracker from "../../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../core/services/tracker/constants";
import { styles } from "./total-stats.styles";
import statusManagement from "../../../../status-management";
import { flowInputColor, flowOutputColor } from "../../table-progress.styles";
import { DB_PROVIDERS } from "../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/configs/db-providers";
let executeOnce = false;

const COLORS = { source: flowInputColor, target: flowOutputColor };

const StatsBox: React.FunctionComponent<{ label: string; value: number; valueColor: string }> = ({
    label,
    value,
    valueColor
}) => {
    return (
        <Box sx={styles.statsBox}>
            <StriimTypography variant="caption" sx={styles.statLabel}>
                {label}
            </StriimTypography>
            <StriimTypography variant="caption" sx={{ ...styles.statValue, color: valueColor }}>
                {value}
            </StriimTypography>
        </Box>
    );
};

const getTotalIO = recentData => {
    let totalIP = recentData["source-input"];
    let totalOP = null;

    if (recentData["target-acked"] !== null && recentData["target-acked"] >= 0) {
        totalOP = recentData["target-acked"];
        if (totalOP > 0 && !executeOnce) {
            Tracker.getInstance().track(TRACKER_STRINGS.schema.targetAcked, {
                event: TRACKER_STRINGS.eventLabel.targetAcked,
                targetAcked: totalOP
            });
            executeOnce = true;
        }
    } else {
        totalOP = recentData["target-output"];
    }
    return {
        TotalInput: numeral(totalIP).format("0.[0000] a"),
        TotalOutput: numeral(totalOP).format("0.[0000] a")
    };
};

export const TotalStats = observer(({ store, appStatus, appType }) => {
    const appID = store?.appModel?.get("uuid");
    const appStats = store?.stats?.[appID] ?? {};
    const tablesType = useMemo(() => {
        let sourceAdapters = store?.sourceAdapters ?? [];
        const useObject = sourceAdapters?.some(adapter => {
            const type = adapter?.type.toUpperCase();
            const sourceData = DB_PROVIDERS?.[type];
            return sourceData?.whatIsTable === "Object";
        });

        return useObject ? "Objects" : "Tables";
    }, [store?.sourceAdapters]);
    if (appStats) {
        const recentData = appStats["most-recent-data"];
        if (!recentData) {
            return;
        }
        const InputRate = numeral(recentData["source-rate"]).format("0.[00] a");
        const OutputRate = numeral(recentData["target-rate"]).format("0.[00] a");
        let schemaCreationCount = 0;
        try {
            if (appType === "IL") {
                let sourceAdapters = store?.sourceAdapters ?? [];
                sourceAdapters.forEach(({ id }) => {
                    const sourceAdapterStats = store?.stats?.[id]?.["most-recent-data"];
                    if (!sourceAdapterStats) {
                        return;
                    }
                    try {
                        schemaCreationCount +=
                            (Object.values(JSON.parse(sourceAdapterStats?.["table-info"])).reduce(
                                (acc: number, item: string) => {
                                    if (typeof item === "string") {
                                        let data = JSON.parse(item);
                                        if (data?.SchemaCreationStatus === "Success") {
                                            return acc + 1;
                                        }
                                        return acc;
                                    }
                                    return acc;
                                },
                                0
                            ) as number) ?? 0;
                    } catch (e) {
                        console.log("Error while calculating tables count ", e);
                    }
                });
            }
        } catch (err) {
            schemaCreationCount = 0;
        }

        const { TotalInput, TotalOutput } = getTotalIO(recentData);

        const getColor = type => (statusManagement.isApplicationCrash(appStatus) ? "critical.500" : COLORS[type]);

        return (
            <Grid container sx={styles.statsWrapper}>
                <StatsBox label={"Total Input"} value={TotalInput} valueColor={getColor("source")} />
                <StatsBox label={"Total Output"} value={TotalOutput} valueColor={getColor("target")} />
                <StatsBox label={"Input Rate (records/s)"} value={InputRate} valueColor={getColor("source")} />
                <StatsBox label={"Output Rate (records/s)"} value={OutputRate} valueColor={getColor("target")} />
                {appType === "IL" && (
                    <StatsBox
                        label={`${tablesType} Created`}
                        value={Number(schemaCreationCount)}
                        valueColor={getColor("target")}
                    />
                )}
            </Grid>
        );
    }
});
