import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { useUserManagement } from "core/user-management/user-management-context";
import BasicInfo from "./components/basic-info/basic-info";
import RoleTable from "../components/role-table/role-table";
import PermissionTable from "../components/permission-table/permission-table";
import UsersTable from "../components/users-table/users-table";
import { ButtonsBar, CancelEditModal, DeleteModal } from "src/modules/apps/pages/user-management/common";
import { isRoleDeletable } from "core/user-management/update.control";
import { Grid, LinearProgress } from "@mui/material";
import styles from "../../common/users-management.styles";

const RoleDetails = ({ id, setEditing }) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [editMode, setEditMode] = useState(state && state.canEdit);

    const [addedRoles, setAddedRoles] = useState([]);
    const [revokedRoles, setRevokedRoles] = useState([]);
    const [addedPermissions, setAddedPermissions] = useState([]);
    const [revokedPermissions, setRevokedPermissions] = useState([]);
    const [addedUsers, setAddedUsers] = useState([]);
    const [revokedUsers, setRevokedUsers] = useState([]);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [cancelDialogVisible, setCancelDialogVisible] = useState(false);

    const {
        userManagementProviderBusy,
        roleDetails,
        getRoleDetailsById,
        users,
        getUsers,
        updateRole,
        deleteRole
    } = useUserManagement();

    useEffect(() => {
        getRoleDetailsById(id);
        if (!users.length) {
            getUsers();
        }
    }, []);

    useEffect(() => {
        if (state?.canEdit) {
            setEditing(true);
            setEditMode(true);
        }
    }, [state]);

    const handleSave = async () => {
        await updateRole(roleDetails[0].name, false, {
            addedRoles,
            addedPermissions,
            addedUsers,
            revokedRoles,
            revokedPermissions,
            revokedUsers
        });
        navigate("/users", { state: { activeTab: 1 } });
    };

    const handleDelete = () => {
        setDeleteDialogVisible(true);
    };

    const onDeleteConfirm = () => {
        deleteRole(id);
        setDeleteDialogVisible(false);
        navigate("/users", { state: { activeTab: 1 } });
    };

    const roleTableData = useMemo(() => (roleDetails[0]?.roles || []).map(toRolesRow), [roleDetails]);

    const permissionTableData = useMemo(
        () => (roleDetails.length ? roleDetails[0]?.permissions.map(toPermissionsRow) : []),
        [roleDetails]
    );

    const usersTableData = useMemo(
        () =>
            roleDetails.length
                ? users.filter(o => o.roles.map(o2 => o2.name).includes(roleDetails[0].name)).map(toUsersRow)
                : [],
        [roleDetails, users]
    );

    return (
        <>
            {userManagementProviderBusy ? (
                <Grid item xs={12}>
                    <LinearProgress color="secondary" />
                </Grid>
            ) : (
                <Grid item xs={12} sx={styles.contentContainer}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <BasicInfo details={roleDetails} />
                        </Grid>
                        <Grid item md={12}>
                            <RoleTable
                                data-testid={"roles-table"}
                                roles={roleTableData}
                                addedRoles={addedRoles}
                                setAddedRoles={setAddedRoles}
                                revokedRoles={revokedRoles}
                                setRevokedRoles={setRevokedRoles}
                                editable={editMode}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <PermissionTable
                                permissions={permissionTableData}
                                addedPermissions={addedPermissions}
                                setAddedPermissions={setAddedPermissions}
                                revokedPermissions={revokedPermissions}
                                setRevokedPermissions={setRevokedPermissions}
                                editable={editMode}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <UsersTable
                                users={usersTableData}
                                addedUsers={addedUsers}
                                setAddedUsers={setAddedUsers}
                                revokedUsers={revokedUsers}
                                setRevokedUsers={setRevokedUsers}
                                editable={editMode}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {editMode && (
                <>
                    <Grid item xs={12} mt={3}>
                        <ButtonsBar
                            isDeletable={isRoleDeletable(roleDetails[0])}
                            onDeleteClick={handleDelete}
                            onCancelClick={() => setCancelDialogVisible(true)}
                            onSaveClick={handleSave}
                        />
                    </Grid>
                    <DeleteModal
                        id={id}
                        type={"role"}
                        deleteDialogVisible={deleteDialogVisible}
                        setDeleteDialogVisible={setDeleteDialogVisible}
                        onDeleteConfirm={onDeleteConfirm}
                    />
                    <CancelEditModal
                        title={`Are you sure you want to discard editing role ${id}?`}
                        dialogVisible={cancelDialogVisible}
                        setDialogVisible={setCancelDialogVisible}
                        onConfirm={() => navigate("/users", { state: { activeTab: 1 } })}
                    />
                </>
            )}
        </>
    );
};

const toRolesRow = (v, i) => ({
    id: v.name,
    ...v
});

const toPermissionsRow = v => ({
    id: v.text,
    name: v.text,
    action: v.actions.join(", "),
    namespace: v.domains[0],
    objectName: v.objectNames[0],
    objectType: v.objectTypes.join(", "),
    ...v
});

const toUsersRow = (v, i) => ({
    id: i,
    ["User Id"]: v["User Id"],
    contactInfo: (v.contactMechanisms || []).map(x => x.data).join(", ") || "-"
});

export default RoleDetails;
