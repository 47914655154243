import numeral from "numeral";
import statusManagement from "../../../../../../../status-management";

export const rateFormatter = (number, status) => {
    if (typeof number === "undefined" || isNaN(number) || !statusManagement.isValidStateToShowAdapterRate(status)) {
        return "-";
    }
    const formattedNumber = numeral(number).format("0.[00] a");
    return formattedNumber + " records/s";
};

export const lagFormatter = lagObj => {
    const lagFirst = lagObj?.first;
    const lagSecond = lagObj?.second;
    if (typeof lagFirst === "undefined" || lagSecond == "undefined" || isNaN(lagFirst) || isNaN(lagSecond)) {
        return "-";
    }
    if (lagFirst == 0 || lagSecond == 0) {
        return "0 ms";
    }
    let lag = (lagFirst / lagSecond)?.toFixed(0);
    if (lag < 1000) {
        // Display milliseconds only
        return `${lag} ms`;
    } else if (lag < 60 * 1000) {
        // Display seconds and milliseconds
        const seconds = Math.floor(lag / 1000);
        const milliseconds = lag % 1000;
        return `${seconds}s ${milliseconds}ms`;
    } else if (lag < 60 * 60 * 1000) {
        // Display minutes and seconds
        const minutes = Math.floor(lag / (60 * 1000));
        const seconds = Math.floor((lag % (60 * 1000)) / 1000);
        return `${minutes}m ${seconds}s`;
    } else {
        // Display hours, minutes, and seconds
        const hours = Math.floor(lag / (60 * 60 * 1000));
        const minutes = Math.floor((lag % (60 * 60 * 1000)) / (60 * 1000));
        const seconds = Math.floor((lag % (60 * 1000)) / 1000);
        return `${hours}h ${minutes}m ${seconds}s`;
    }
};

export const getLagInt = lagObj => {
    return (lagObj?.first / lagObj?.second)?.toFixed(0);
};
