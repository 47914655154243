import dateLib from "core/utils/date-utils";

export const getAddedMessage = date => {
    if (!date) return "Added";
    let relativeMonths = `1 month`;
    const startDate = new Date();
    const endDate = new Date(date);

    const monthDifference = dateLib(startDate).diff(endDate, "months");

    if (monthDifference > 1) {
        relativeMonths = `${monthDifference} months`;
    }

    return `Added ${relativeMonths} ago`;
};
