import React from "react";
import { StriimTypography } from "@striim/striim-ui-v2";
import { getStageInfo } from "../automated-app-group-stage-helper";
import { observer } from "mobx-react-lite";
import automatedGroupStore from "../automated-app-group-details.store";
import { Grid } from "@mui/material";
import statusManagement from "../../../../../../../status-management";

const AppGroupDescription: React.FunctionComponent = observer(() => {
    let currentStage = automatedGroupStore.statusInfo?.stageInfo;
    const stageInfo = getStageInfo(automatedGroupStore?.appStatuses)?.[currentStage];
    if (!stageInfo) {
        return;
    }
    return (
        //@ts-ignore
        <StriimTypography variant="body4" color="greyscale.900">
            {stageInfo["description"]}
        </StriimTypography>
    );
});

const AppGroupStageInfo: React.FunctionComponent = observer(() => {
    let currentStage = automatedGroupStore.statusInfo?.stageInfo;
    const stageInfo = getStageInfo()[currentStage];
    if (
        !stageInfo ||
        ["CREATED", "DEPLOYED", "RESET"].includes(currentStage) ||
        statusManagement.isTransientStatus(currentStage) ||
        automatedGroupStore.isAppTransitioning
    ) {
        return true;
    }
    return (
        <Grid item container alignItems="center" justifyContent="space-between">
            <Grid item>
            <StriimTypography variant="h3" color="primary.700">
                Stage : {stageInfo["name"]}
            </StriimTypography>
            </Grid>
            <StriimTypography variant="caption3" color="greyscale.700">
                {automatedGroupStore?.startStopTime}
            </StriimTypography>
        </Grid>
    );
});

export { AppGroupDescription, AppGroupStageInfo };
