import { makeStyles } from "@material-ui/core/styles";

const MARGIN_INLINE = "120px";

export default makeStyles(({spacing}) => ({
    widget: {
        marginInline: MARGIN_INLINE,
        marginTop: spacing(5)
    },
    container: {
        marginInline: 0,
        marginTop: 80,
        flexWrap: 'nowrap'
    },
    banner: {
        marginInline: MARGIN_INLINE
    }
}));
