import React, { useEffect } from "react";
import { autorun } from "mobx";
import { StriimToast } from "@striim/striim-ui";
import { removeHtmlResponse } from "app/components/flow/designer/appErrors/exception-helper.js";

const Toasts = ({ store }) => {
    useEffect(() => {
        autorun(() => {
            [...store.events, ...store.volatileGrowls].forEach(event => {
                if (event.shouldGrowl && !event.hasGrowled) {
                    StriimToast({
                        message: removeHtmlResponse(event.message),
                        severity: event.severity,
                        title: event.title,
                        additionalComponent: event.button,
                        column: event.column,
                        styles: {
                            width: "500px"
                        }
                    });
                    event.hasGrowled = true;
                    store.persistEvents();
                }
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <></>;
};

export default Toasts;
