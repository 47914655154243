import React, { useCallback, useMemo } from "react";
import { Divider, Grid } from "@material-ui/core";
import HomepageCard from "../../../common/homepage_card";
import { StriimLink, StriimTypography } from "@striim/striim-ui";
import MetricCard from "../../../common/metric_card";
import numeral from "numeral";
import formatter from "../../../../../../app/components/monitor/common/valueFormatter";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
    wrapper: {
        border: "2px solid #e1e1e1",
        borderRadius: "8px 8px 0px 0px",
        marginBottom: 0,
        paddingBottom: 0,
        backgroundColor: "white"
    },
    secondaryWrapper: {
        textAlign: "center",
        border: "2px solid #e1e1e1",
        borderRadius: "8px 8px 0px 0px",
        padding: "32px"
    },
    styledH1: {
        fontSize: 32,
        textAlign: "center"
    },
    styledText: {
        paddingLeft: 20
    },
    styledDivider: {
        margin: "2em 0 2em 0"
    },
    styledImg: {
        width: 40,
        height: 40
    },
    textCustomColor: {
        color: "#515C61"
    },
    linkColor: {
        color: "#00A7E5"
    }
}));

const AppMetricsCard = ({ appData, running, crash, created }) => {
    const navigate = useNavigate();

    const classes = useStyles();
    const getFractionalDisplay = useCallback((numerator, denominator) => {
        const formatInput = input => numeral(input).format("0.[0000] a");
        return (
            <StriimTypography variant="h1" className={classes.styledH1}>
                {formatInput(numerator)} / {formatInput(denominator)}
            </StriimTypography>
        );
    }, []);

    const getPercentageDisplay = useCallback(
        value => (!value ? "Fetching…" : value === 0 ? "0%" : formatter.toPercentageString(value)),
        []
    );

    const getCPUPieChartData = useCallback(
        value => [
            { name: "Used", color: "#4E5C62", value: value },
            { name: "Free", color: "#D9D9D9", value: 1 - value }
        ],
        []
    );

    const lagValues = useMemo(() => {
        if (
            appData?.["most-recent-data"]?.["lag-rate"]?.["first"] ||
            appData?.["most-recent-data"]?.["lag-rate"]?.["second"]
        ) {
            return `${(
                appData?.["most-recent-data"]?.["lag-rate"]?.["first"] /
                appData?.["most-recent-data"]?.["lag-rate"]?.["second"]
            )?.toFixed(0)}`;
        } else return 0;
    }, [appData]);

    return running ? (
        <Grid item container className={classes.wrapper}>
            <Grid item xs>
                <HomepageCard
                    header={{
                        title: (
                            <StriimTypography className={classes.styledText} variant="h3">
                                Reads/Writes
                            </StriimTypography>
                        )
                    }}
                    content={getFractionalDisplay(
                        appData?.["most-recent-data"]?.["source-input"],
                        appData?.["most-recent-data"]?.["target-output"]
                    )}
                />
            </Grid>
            <Divider orientation="vertical" flexItem className={classes.styledDivider} />
            <Grid item xs>
                <HomepageCard
                    header={{
                        title: (
                            <StriimTypography className={classes.styledText} variant="h3">
                                Read/Write Data
                            </StriimTypography>
                        )
                    }}
                    content={getFractionalDisplay(appData?.["source-rate"], appData?.["target-rate"])}
                />
            </Grid>
            <Divider orientation="vertical" flexItem className={classes.styledDivider} />
            <Grid item xs>
                <HomepageCard
                    header={{
                        title: (
                            <StriimTypography className={classes.styledText} variant="h3">
                                Lag
                            </StriimTypography>
                        )
                    }}
                    content={
                        <StriimTypography variant="h1" display="inline" className={classes.styledH1}>
                            {lagValues}
                            <StriimTypography variant="caption" display="inline">
                                ms
                            </StriimTypography>
                        </StriimTypography>
                    }
                />
            </Grid>
            <Divider orientation="vertical" flexItem className={classes.styledDivider} />
            <Grid item xs>
                <MetricCard
                    title="CPU"
                    pieChartData={getCPUPieChartData(appData?.["most-recent-data"]?.["cpu"])}
                    displayText={getPercentageDisplay(appData?.["most-recent-data"]?.["cpu"])}
                />
            </Grid>
        </Grid>
    ) : crash ? (
        <Grid container className={classes.secondaryWrapper}>
            <Grid item xs={12}>
                <img
                    className={classes.styledImg}
                    src="src/modules/apps/pages/homepage/resources/images/exclamation.png"
                />
            </Grid>
            <Grid item xs={12}>
                <StriimTypography variant="h3" className={classes.textCustomColor}>
                    This App has crashed due to some errors.
                </StriimTypography>
                <StriimLink
                    className={classes.linkColor}
                    onClick={() => {
                        navigate("/flow/" + appData["full-name"] + "/view/exceptions");
                    }}
                >
                    Show errors
                </StriimLink>
            </Grid>
        </Grid>
    ) : created ? (
        <Grid container className={classes.secondaryWrapper}>
            <Grid item xs={12}>
                <img
                    className={classes.styledImg}
                    src="src/modules/apps/pages/homepage/resources/images/exclamation.png"
                />
            </Grid>
            <Grid item xs={12}>
                <StriimTypography variant="h3" className={classes.textCustomColor}>
                    Your app is empty. Please construct an app to see data.
                </StriimTypography>
            </Grid>
        </Grid>
    ) : (
        <Grid container className={classes.secondaryWrapper}>
            <Grid item xs={12}>
                <img
                    className={classes.styledImg}
                    src="src/modules/apps/pages/homepage/resources/images/exclamation.png"
                />
            </Grid>
            <Grid item xs={12}>
                <StriimTypography variant="h3" className={classes.textCustomColor}>
                    Start the App to Monitor its progress here
                </StriimTypography>
            </Grid>
        </Grid>
    );
};

export default AppMetricsCard;
