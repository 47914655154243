import React, { createContext, useMemo, useState } from "react";
import AppDeployModal from "./app-deploy-modal";
import DataTypeValidation from "./data-type-validation-modal";
import { DeploymentInfoType } from "./types";
import withTheme from "../theme/theme";
// Steps
// 0 : Deploy cofiguration
// 1 : Data type validation errors
export const DeployModalContext = createContext(null);

interface DeployModalProps {
    appId: string;
    isVisible?: boolean;
    setIsVisible?: (isVisible: boolean) => void;
}
const DeployModalWrapper: React.FunctionComponent<DeployModalProps> = ({
    appId,
    isVisible = true,
    setIsVisible = null
}) => {
    const [step, setStep] = useState<number>(0);
    const [deploymentInfo, setDeploymentInfo] = useState<DeploymentInfoType | null>(null);
    const [dataTypeValidationErrors, setDataTypeValidationErrors] = useState([]);
    const [isValidationEnabled, setisvalidationEnabled] = useState<boolean>(false);
    const [validateTableMapping, setvalidateTableMapping] = useState<boolean>(false);
    // this wrapper has been used both in mariontte views and react
    // this state will be used only for marionette views
    const [isglobalModalVisible, setisGlobalModalVisible] = useState<boolean>(true);
    const getStep = useMemo(() => {
        switch (step) {
            case 0:
                return (
                    <AppDeployModal
                        appId={appId}
                        isVisible={setIsVisible ? isVisible : isglobalModalVisible}
                        setIsVisible={setIsVisible ?? setisGlobalModalVisible}
                    />
                );
            case 1:
                return <DataTypeValidation appId={appId} setIsVisible={setIsVisible ?? setisGlobalModalVisible} />;

            default:
                break;
        }
    }, [step, isVisible, isglobalModalVisible, setIsVisible]);
    if (!appId) {
        console.log("App ID is not present");
        return;
    }
    return (
        <DeployModalContext.Provider
            value={{
                appId,
                setStep,
                step,
                deploymentInfo,
                setDeploymentInfo,
                isValidationEnabled,
                validateTableMapping,
                setisvalidationEnabled,
                setvalidateTableMapping,
                dataTypeValidationErrors,
                setDataTypeValidationErrors
            }}
        >
            {getStep}
        </DeployModalContext.Provider>
    );
};

export default withTheme(DeployModalWrapper);
