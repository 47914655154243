import React, { useState } from "react";
import PropTypes from "prop-types";

import { StriimTypography, StriimDropdown } from "@striim/striim-ui";
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { ExpandMoreRounded, ExpandLessRounded } from "@mui/icons-material";
import { PieChart, Pie, Cell } from "recharts";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    cardRoot: {
        minWidth: 250,
        height: 154
    },
    metricHeaderRoot: {
        padding: "24px 0px 0px 32px"
    },
    metricContentRoot: {
        padding: "8px 0px 0px 32px"
    },
    dropDownContainer: {
        maxWidth: 150
    },
    dropDownTextContainer: {
        marginLeft: 8,
        cursor: "pointer"
    },
    dropDownIconContainer: {
        height: 24,
        width: 24,
        color: "#48525C"
    }
}));

const MetricCard = ({
    title,
    pieChartData,
    displayText,
    displayColor,
    dropdownList,
    CustomComponent,
    customCardStyles
}) => {
    const [show, setShow] = useState(false);
    const classes = useStyles();
    return (
        <Card elevation={0} className={clsx(classes.cardRoot, customCardStyles)}>
            <CardHeader
                classes={{ root: classes.metricHeaderRoot }}
                disableTypography
                title={<StriimTypography variant="h3">{title}</StriimTypography>}
            />
            <CardContent classes={{ root: classes.metricContentRoot }}>
                <Grid container justifyContent="flex-start" alignItems="center" direction="row">
                    <Grid item>
                        <PieChart width={64} height={64}>
                            <Pie
                                data={pieChartData}
                                innerRadius={20}
                                outerRadius={32}
                                stroke=""
                                dataKey="value"
                                isAnimationActive={false}
                            >
                                {pieChartData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                ))}
                            </Pie>
                        </PieChart>
                    </Grid>
                    <Grid item className={classes.dropDownContainer}>
                        {CustomComponent ? (
                            <CustomComponent />
                        ) : (
                            <StriimDropdown
                                extendOnClick
                                controlState={[show, setShow]}
                                openDelay={400}
                                closeDelay={5}
                                closeOnMouseLeave
                                content={dropdownList}
                            >
                                <Grid container alignItems="center" className={classes.dropDownTextContainer}>
                                    <Grid item>
                                        <StriimTypography
                                            variant="h1"
                                            display="inline"
                                            style={
                                                displayColor && {
                                                    color: displayColor
                                                }
                                            }
                                        >
                                            {displayText}
                                        </StriimTypography>
                                    </Grid>
                                    {dropdownList && (
                                        <Grid item className={classes.dropDownIconContainer}>
                                            {show ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                                        </Grid>
                                    )}
                                </Grid>
                            </StriimDropdown>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default MetricCard;
MetricCard.propTypes = {
    title: PropTypes.string.isRequired,
    displayText: PropTypes.string,
    displayColor: PropTypes.string,
    pieChartData: PropTypes.array,
    dropdownList: PropTypes.node,
    CustomComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.oneOf([null])])
};
