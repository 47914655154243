import React, { useEffect } from "react";
import loadBackboneApp from "../../../../app/load-backbone-app";
import api from "../../../..//core/api/api";
import { SetupView } from "../../../../app/components/alertmanager/alertmanager";
import { ALERT_TYPE } from "../../../../app/components/alertmanager/alert-utils";
import AlertManagerRoot from "../alert-manager-root";

function SMTPSetup() {
    useEffect(() => {
        api.getEmailConfigStatus()
            .then(emailConfigStatus => {
                if (emailConfigStatus === "RUNNING") {
                    api.getSMTPConfig().then(res => {
                        loadBackboneApp(
                            // @ts-ignore
                            new SetupView({
                                type: ALERT_TYPE.EMAIL,
                                existingConfigDtls: res || null
                            })
                        );
                    });
                } else {
                    loadBackboneApp(
                        // @ts-ignore
                        new SetupView({
                            type: ALERT_TYPE.EMAIL,
                            existingConfigDtls: null
                        })
                    );
                }
            })
            .fail(e => {
                console.error("Error retrieving Email configuration status", e);
            });
    }, []);

    return <></>;
}

export default function() {
    return (
        <AlertManagerRoot>
            <SMTPSetup />
        </AlertManagerRoot>
    );
}
