import $ from "jquery";
import NestedTypes from "backbone.nestedtypes";
import Backbone from "backbone";
import DateRangePicker from "app/components/common/editor/control/date-range-picker/date-range-picker";
import predefinedTimeOptions, { last6hourOption } from "app/components/monitor/eventsLog/predefinedTimeOptions";
import SearchableMultiSelectView from "app/components/common/searchable-lists/SearchableMultiSelect";
import exceptionListContainer from "./exception-list-container.html";
import exceptionListItemTemplate from "./exception-list-item.html";
import exceptionsStoreApi from "./exception-store-api";

const noExceptionsResultTemplate = `
<div class="no-exceptions">
    <img src="/app/images/exceptions-no-search-result.png">
    <h2 class="bold">No search result</h2>
    <ul>
        <li>Try a different search <a class="clear-filters action bolder">clear search filter</a></li>
        <li>The exceptions may be deleted based on your settings. <a class="manage-history action bolder">Manage history</a> to store them for longer in the future.</li>
    </ul>
</div>`;

const NoExceptionsResultView = Backbone.Marionette.ItemView.extend({
    template: _.template(noExceptionsResultTemplate),

    ui: {
        manageHistory: ".manage-history",
        clearFilters: ".clear-filters"
    },

    triggers: {
        "click @ui.clearFilters": "clear-filters",
        "click @ui.manageHistory": "manage-history"
    }
});

const ExceptionItemView = Backbone.Marionette.ItemView.extend({
    template: _.template(exceptionListItemTemplate),
    ui: {
        action: ".exception-list-item"
    },
    triggers: {
        "click @ui.action": "item-selected"
    }
});

const GroupView = Backbone.Marionette.CompositeView.extend({
    template: _.template('<div class="exceptions-list"></div>'),

    tagName: "div",

    className: "exceptions-group",

    childView: ExceptionItemView,

    childViewContainer: ".exceptions-list",

    childEvents: {
        "item-selected": "onItemSelected"
    },

    initialize: function(options) {
        this.collection = new Backbone.Collection(options.model.get("exceptions"));
    },

    onItemSelected: function(child, childView) {
        this.trigger("item-selected", childView.model);
    }
});

const GroupsCollectionView = Backbone.Marionette.CollectionView.extend({
    tagName: "div",
    childView: GroupView,
    childEvents: {
        "item-selected": "onItemSelected",
        "clear-filters": "onClearFilters",
        "manage-history": "onManageHistory"
    },
    emptyView: NoExceptionsResultView,

    onRender: function() {
        setTimeout(this._onResize.bind(this), 100);
        $(window).on("resize.exceptionList", this._onResize.bind(this));
    },

    onBeforeDestroy: function() {
        $(window).off("resize.exceptionList");
    },

    _onResize: function() {
        const verticalPadding = 40;

        const sidebar = this.$el.closest(".sidebar.right-sidebar");
        const newHeight =
            sidebar.height() - //height of sidebar
            sidebar.find(".title-bar").height() -
            sidebar.find(".exceptions-sidebar .filters").height() -
            verticalPadding;

        const exceptionsGroups = this.$el.closest(".exceptions-groups");
        exceptionsGroups.css("height", newHeight + "px");
        if (!this._scrollbarInitialized) {
            exceptionsGroups.perfectScrollbar();
        }
    },

    onItemSelected: function(view, itemModel) {
        this.trigger("item-selected", itemModel);
    },

    onClearFilters: function() {
        this.trigger("clear-filters");
    },

    onManageHistory: function() {
        this.trigger("manage-history");
    }
});

//#region exception list container

export const ExceptionsListContainerModel = NestedTypes.Model.extend({
    defaults: {
        numberOfExceptions: Number,
        exceptionsCollection: Object,
        appModel: Object
    }
});

export const ExceptionsListContainerView = Backbone.Marionette.LayoutView.extend({
    tagName: "div",

    template: _.template(exceptionListContainer),

    ui: {
        searchFilter: ".search-content",

        manageHistory: ".manage-history",
        applicationHistory: ".application-history-filter",
        applicationHistoryText: ".app-history-filter-text",
        applicationHistoryWrapper: ".app-history-filter-wrapper",

        timeFilter: ".time-filter",
        timeDropdown: ".time-dropdown",
        timeInfo: ".time-info",
        selectedTimeFilterText: ".selected-time-filter-text",

        componentFilter: ".component-filter",
        componentDropdown: ".component-dropdown",
        componentsCount: ".componentsCount",

        numberOfExceptions: ".exceptions-number"
    },

    modelEvents: {
        "change:numberOfExceptions": "_updateNumberOfException"
    },

    regions: {
        timeFilter: ".time-dropdown",
        exceptionGroups: ".exceptions-groups",
        componentFilterRegion: ".component-dropdown"
    },

    events: {
        "click @ui.timeFilter": "onTimeFilterClicked",
        "click @ui.componentFilter": "onComponentFilterClick",
        "keydown @ui.searchFilter": "onStartSearching",
        "click @ui.manageHistory": "onManageHistory"
    },

    onItemSelected: function(view, itemModel) {
        this.trigger("item-selected", itemModel);
    },

    onRender: function() {
        const _this = this;

        const groupsCollectionView = new GroupsCollectionView({
            collection: this.model.exceptionsCollection
        });
        groupsCollectionView.on("item-selected", itemModel => {
            this.trigger("item-selected", itemModel);
        });
        groupsCollectionView.on("clear-filters", () => {
            try {
                this._applyingFiltersInProgress = true;

                this.ui.searchFilter.val("");
                this.dateRangePicker.clearValue();
                this.componentsDropdownView.clearSelection();

                this._applyingFiltersInProgress = false;
                this._triggerFiltersChange();
            } catch (exc) {
                this._applyingFiltersInProgress = false;
            }
            //TODO: #xceptions store clear filters
        });
        groupsCollectionView.on("manage-history", () => {
            this.trigger("manage-history");
        });
        this.getRegion("exceptionGroups").show(groupsCollectionView);

        function getSelectedTimeFilterText(from, to, displayType) {
            const dateFormat = "MMMM DD, YYYY hh:mm a";

            let selectedTimeFilterText = `since ${from.format(dateFormat)}`;
            if (displayType === "Custom") {
                selectedTimeFilterText = `from ${from.format(dateFormat)} to ${to.format(dateFormat)}`;
            }

            return selectedTimeFilterText;
        }

        function renderTimeFilter() {
            const extendedOptions = predefinedTimeOptions.slice();
            extendedOptions.splice(1, 0, last6hourOption);

            _this.dateRangePicker = DateRangePicker(extendedOptions).create();
            _this.getRegion("timeFilter").show(_this.dateRangePicker);
            _this.listenTo(_this.dateRangePicker.model, "change:value", viewModel => {
                _this.ui.timeDropdown.hide();
                _this.ui.timeInfo.html(viewModel.value.displayType);
                _this._triggerFiltersChange();
                _this.ui.selectedTimeFilterText.html(
                    getSelectedTimeFilterText(viewModel.value.from, viewModel.value.to, viewModel.value.displayType)
                );
            });

            const defaultValue = _this.dateRangePicker.model.predefinedOptions[0];
            _this.ui.selectedTimeFilterText.html(getSelectedTimeFilterText(defaultValue.from()));
        }

        function renderComponentFilter() {
            _this.componentsDropdownView = new SearchableMultiSelectView({
                dataArray: []
            });

            exceptionsStoreApi.loadComponentsList(_this.model.get("appModel")).then(components => {
                _this.componentsDropdownView.setDataSource(components);
            });

            _this.componentFilterRegion.show(_this.componentsDropdownView);
            _this.listenTo(_this.componentsDropdownView, "selectedListItems", selectedValues => {
                _this.ui.componentDropdown.hide();
                _this.ui.componentsCount.html(selectedValues.length > 0 ? "(" + selectedValues.length + ")" : "");
                _this._triggerFiltersChange();
            });
        }

        renderTimeFilter();
        renderComponentFilter();
    },

    onTimeFilterClicked: function() {
        this.ui.componentDropdown.hide();
        this.ui.timeDropdown.toggle();
    },

    onComponentFilterClick: function() {
        this.ui.timeDropdown.hide();
        this.ui.componentDropdown.toggle();
    },

    _searchTimeout: null,
    onStartSearching: function() {
        if (this._searchTimeout) {
            clearTimeout(this._searchTimeout);
        }
        this._searchTimeout = setTimeout(this._triggerFiltersChange.bind(this), 500);
    },

    _getFilters: function() {
        let fromDate, toDate;
        const dateValue = this.dateRangePicker.getViewValue();
        if (dateValue) {
            fromDate = dateValue.from;
            if (dateValue !== "Custom") {
                toDate = dateValue.to;
            }
        }

        return {
            searchTerm: this.ui.searchFilter.val(),
            dateFrom: fromDate,
            dateTo: toDate,
            components: this.componentsDropdownView.selectedValues
        };
    },

    onManageHistory: function() {
        this.trigger("manage-history");
    },

    _triggerFiltersChange: function() {
        if (this._applyingFiltersInProgress) {
            return;
        }
        this.trigger("filters-changed", this._getFilters());
    },

    _updateNumberOfException: function() {
        if (this.ui.numberOfExceptions.html) {
            const numberOfExceptions = this.model.get("numberOfExceptions");
            if (numberOfExceptions > 0) {
                this.ui.numberOfExceptions.html(numberOfExceptions + " messages");
            }
            this.ui.numberOfExceptions.toggleClass("hidden-element", numberOfExceptions === 0);
        }
    }
});

//#endregion
