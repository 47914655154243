import _ from "underscore";
import SingleColumn from "./single-column";
import ColumnsList from "../columns-picker/columns-list";

var View = ColumnsList.View.extend({
    initialize: function (options) {
        ColumnsList.View.prototype.initialize.apply(this, [options]);

        this.gridView = new SingleColumn.CollectionView({
            collection: new SingleColumn.Collection([]),
            model: this.model,
            config: options.transformationConfig,
        });

        ColumnsList.View.prototype.attachListeners.apply(this);
    },
});

var Model = ColumnsList.Model.extend({});

export default _.extend({}, ColumnsList, {
    Model: Model,
    View: View,
});
