import FieldTypeModifierControl from "app/components/common/editor/control/columns-picker/type-modifier-list";
import TypeChangeFunctions from "app/components/common/editor/control/select/types-datasource.json";

var Transformation = {};

Transformation.OnlyDbSources = false;

Transformation.createColumnControl = function () {
    return FieldTypeModifierControl.extend({
        hideLabel: true,
        hidden: false,
        transformationConfig: {
            preFillColumns: true,
            addAvailable: false,
            autoFillAlias: false,
            hideRemoveIcon: true,
            allowClearColumns: false,
            functionsSource: getFunctionsListPerType,
            labels: {
                column1: "Field Name and Current Type",
                column2: "New Type",
            },
        },
    }).create();
};

function getFunctionsListPerType(columnType) {
    return TypeChangeFunctions;
}

Transformation.buildTql = function (tqlBuilder, columns) {
    columns.forEach(function (column) {
        if (!column.column) {
            return;
        }

        let transformationFunction = getTransformFunction(column);

        if (transformationFunction) {
            tqlBuilder.addColumn(column.column, transformationFunction, null, column.column);
        } else {
            tqlBuilder.addColumn(column.column, null, null, null);
        }
    });
};

function getTransformFunction(column) {
    if (!column.tqlFunction || column.tqlFunction === column.columnType) {
        return null;
    }

    let transformationFunction = null;
    const f = TypeChangeFunctions.find((t) => t.id === column.tqlFunction);
    if (f) {
        transformationFunction = f.convertFunction;
    }

    return transformationFunction;
}

export default Transformation;
