import dateLib from "core/utils/date-utils";
import EventLogEntry from "./eventLogEntry";
import { FlowStatus } from "src/status-management";

function convert(status) {
    const date = dateLib(status.timestamp);
    const fqName = status.fqName.replace(/['"]+/g, "");
    let title;
    if (
        status.currentStatus.indexOf(FlowStatus.CREATED) > -1 &&
        status.previousStatus.indexOf(FlowStatus.UNKNOWN) > -1
    ) {
        title = `App Status : ${status.currentStatus}`;
    } else {
        title = `App status changed to ${status.currentStatus} from ${status.previousStatus}`;
    }

    return new EventLogEntry({
        title: title,
        status: "info",
        message: null,
        date: date.toDate(),
        formattedDate: date.format("LLL"),
        address: fqName,
        fullAddress: fqName,
        server: status.type
    });
}

export default convert;
