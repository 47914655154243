import appStatusSynchronizer from "../../../../../../../core/appStatusSynchronizer";
import statusManagement from "../../../../../../status-management";

class AutomatedAppGroupStatusSynchronizer {
    statuses: { [key: string]: string };
    ids: string[];
    currentFocusedApp: "IL" | "CDC";

    constructor(ids: string[]) {
        this.ids = ids;
        this.statuses = {};
        this.currentFocusedApp = "IL";
        this.fetchStatuses();
    }
    async fetchStatuses() {
        this.ids?.forEach(async (id, index) => {
            const status = await appStatusSynchronizer.getStatus(id);
            this.statuses[id] = status;
        });
        return this.statuses;
    }
    get getStatuses() {
        return this.statuses;
    }
    listenToAppsStatusChange(callback: (statuses: { [key: string]: string }) => void) {
        const ilAppId = this.ids[0];
        const cdcAppId = this.ids[1];
        appStatusSynchronizer.on("status_change", ({ appId, newStatus, isTranscientStatus }) => {
            if (appId === ilAppId) {
                this.statuses[ilAppId] = newStatus;
                callback(this.statuses);
            }
            if (appId === cdcAppId) {
                this.statuses[cdcAppId] = newStatus;
                callback(this.statuses);
            }
        });
    }
    clearStatusChangeListener() {
        this.ids?.forEach((id, index) => {
            appStatusSynchronizer.off(id);
        });
    }
    getCurrentFocusedApp(statuses) {
        const ilStatus = statuses[this.ids[0]];
        if (statusManagement.isCompletedStatus(ilStatus)) {
            this.currentFocusedApp = "CDC";
        } else {
            this.currentFocusedApp = "IL";
        }
        return this.currentFocusedApp;
    }
}

export { AutomatedAppGroupStatusSynchronizer };
