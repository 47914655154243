import React from "react";
import { useNavigate } from "react-router-dom";
import { StriimDropdown, StriimIconButton, StriimMenuList, StriimMenuItem } from "@striim/striim-ui";
import { MoreHoriz } from "@material-ui/icons";

import useStyles from "./dashboard-tile-actions.styles";

import Tracker from "core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../../../../../core/services/tracker/constants";
import { Box } from "@mui/material";

const DashboardTileActions = ({ dashboard, setRenameModalVisible, setDeleteModalVisible }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const trackEventClicked = (buttonClicked) => {
        Tracker.getInstance().track(TRACKER_STRINGS.schema.dashboard, {
            id: `${dashboard?.nsName}.${dashboard?.name}`,
            event: TRACKER_STRINGS.eventClicked.dashboard,
            buttonClicked: buttonClicked
        });
    }
    return (
        <div
            onClick={e => {
                e.stopPropagation(); //to prevent app selection
            }}
        >
            <StriimDropdown
                extendOnClick
                closeOnSelect
                content={
                    <StriimMenuList className="dashtile--menu">
                        <StriimMenuItem
                            onClick={() => {
                                setRenameModalVisible(true);
                            }}
                            data-test-id="rename-dashboard"
                        >
                            Rename
                        </StriimMenuItem>
                        <StriimMenuItem
                            onClick={() => {
                                navigate(`/dashboard/edit/${dashboard?.nsName}.${dashboard?.name}/${dashboard?.defaultLandingPage}`);

                                trackEventClicked("Edit");
                            }}
                            data-test-id="edit-dashboard"
                        >
                            Edit
                        </StriimMenuItem>
                        <StriimMenuItem
                            onClick={() => {
                                setDeleteModalVisible(true);
                                trackEventClicked("Delete");
                            }}
                            data-test-id="delete-dashboard"
                        >
                            Delete
                        </StriimMenuItem>
                    </StriimMenuList>
                }
            >
                <StriimIconButton classes={{ root: classes.actions }}>
                    <Box
                        component={MoreHoriz}
                        color="greyscale.900"
                        data-test-id={dashboard?.nsName + "-" + (dashboard?.title || dashboard?.name) + "-menu"}
                    />
                </StriimIconButton>
            </StriimDropdown>
        </div>
    );
};

export default DashboardTileActions;
