import React, { useState } from "react";
import { StriimTypography, StriimButton, StriimTable } from "@striim/striim-ui";
import { ManageAccounts } from "src/generic/icon/customIcons";
import {
    EmptyTableInfo,
    AddRolesModal,
    applyCellRendererToColumns
} from "src/modules/apps/pages/user-management/common";
import { roleTableColumns } from "../../constants";
import { Grid, useTheme } from "@mui/material";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";
import styles from "../../../common/table-element.styles";

const RoleTable = React.memo(
    ({ userRoles, isEditing, setAddedRoles, addedRoles = [], revokedRoles = [], setRevokedRoles }) => {
        const { palette } = useTheme();

        const [addRoleModalVisible, setAddRoleModalVisible] = useState(false);

        const columnsWithRenderer = applyCellRendererToColumns(roleTableColumns, revokedRoles);

        const columns = isEditing ? columnsWithRenderer : columnsWithRenderer.slice(0, 2);
        let rolesToShow = [...userRoles.concat(addedRoles)];

        if (isEditing) {
            rolesToShow = rolesToShow.map(x => {
                const revoked = revokedRoles.indexOf(x.name) !== -1;

                const actions = (
                    <Grid container justifyContent="space-between">
                        <StriimButton
                            variant="text"
                            onClick={() => {
                                setRevokedRoles([...revokedRoles, x.name]);
                            }}
                            disabled={revoked}
                            data-testid={`${x.name}-revoke-button`}
                            sx={styles.buttonWrapper}
                        >
                            {revoked ? "Revoked" : "Revoke"}
                        </StriimButton>{" "}
                        <StriimButton
                            variant="text"
                            onClick={() => {
                                setRevokedRoles(revokedRoles.filter(r => r !== x.name));
                            }}
                            style={revoked ? {} : { display: `none` }}
                            data-testid={`${x.name}-revoke-undo-button`}
                            sx={styles.buttonWrapper}
                        >
                            Undo
                        </StriimButton>
                    </Grid>
                );

                return { ...x, actions };
            });
        }

        return (
            <>
                <Grid container spacing={1}>
                    <Grid container item alignItems="center" justifyContent="space-between">
                        <StriimTypography variant="h3" color="primary.900">
                            Roles
                        </StriimTypography>
                        {isEditing && (
                            <StriimButton
                                variant="text"
                                onClick={() => setAddRoleModalVisible(true)}
                                data-testid="add-role-button"
                                startIcon={<AddIcon />}
                            >
                                Add role
                            </StriimButton>
                        )}
                    </Grid>
                    <Grid item md={12}>
                        <StriimTable
                            data={rolesToShow}
                            columns={columns}
                            TableProps={{ "data-testid": "roles-table" }}
                            TableHeaderCellProps={{
                                style: {
                                    backgroundColor: palette.greyscale[50]
                                }
                            }}
                            NoRowsComponent={
                                <EmptyTableInfo
                                    Icon={ManageAccounts}
                                    text={`You can start adding roles for new user using the 'Add role' button`}
                                />
                            }
                            indexedRow
                            TableBodyProps={{ seperated: true }}
                        />
                    </Grid>
                </Grid>
                {isEditing && (
                    <AddRolesModal
                        rolesToExclude={rolesToShow}
                        visible={addRoleModalVisible}
                        onConfirm={selectedValues => {
                            selectedValues = selectedValues || [];
                            setAddedRoles([...addedRoles, ...selectedValues]);
                            setAddRoleModalVisible(false);
                        }}
                        onCancel={() => {
                            setAddRoleModalVisible(false);
                        }}
                    />
                )}
            </>
        );
    }
);

RoleTable.displayName = "RoleTable";

export default RoleTable;
