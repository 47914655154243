import React from "react";
import { StriimMessageBox } from "@striim/striim-ui";

import { styles } from "./form-message-box.styles";
import { formatAuthrResponse, formatMessage } from "../../utils";

interface FormMessageBoxProps {
    isAuthrResponse: boolean;
    text: string;
    description?: object;
    options?: {
        warning?: string;
        onClose?: () => void;
    };
}

const FormMessageBox = ({ isAuthrResponse, text, description = null, options }: FormMessageBoxProps) => {
    const formattedMessage = isAuthrResponse
        ? formatAuthrResponse(text, options)
        : formatMessage(text, description, options);
    return <StriimMessageBox type="error" text={formattedMessage} customCardStyles={{ ...styles.messageBox }} />;
};

export default FormMessageBox;
