import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    homepageContainer: {
        backgroundColor: v5theme => v5theme.palette.greyscale[100],
        paddingInline: 0,
        minWidth: 1280,
        margin: 0,
        padding: 0
    },
    container: {
        display: "flex",
        justifyContent: "center",
        margin: 0,
        padding: 0
    }
}));
