import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames";
import utils from "core/utils";
import metaObjectHelper from "app/components/common/editor/meta-object-editors/metaObjectHelper";

export default {
    isTransformationComponent: function (predefinedComponentName) {
        return (
            [
                PredefinedComponentNames.TransformationMasking.name,
                PredefinedComponentNames.TransformationFieldRemover.name,
                PredefinedComponentNames.TransformationFieldRenamer.name,
                PredefinedComponentNames.TransformationFieldTypeModifier.name,
                PredefinedComponentNames.TransformationFieldFilter.name,
                PredefinedComponentNames.TransformationFieldSplitter.name,
                PredefinedComponentNames.TransformationFieldAdder.name,
                PredefinedComponentNames.TransformationJsonReader.name,
                PredefinedComponentNames.TransformationTypedEventToWaDbEvent.name,
                PredefinedComponentNames.TransformationFieldEnricher.name,
                PredefinedComponentNames.TransformationWaeMetaFilter.name,
                PredefinedComponentNames.TransformationWaeDbTableFilter.name,
                PredefinedComponentNames.TransformationWaeOperatorFilter.name,
                PredefinedComponentNames.TransformationWaeAddUserData.name,
                PredefinedComponentNames.TransformationWaeToTypedEvent.name,
                PredefinedComponentNames.TransformationWaeToDwStage.name,
                PredefinedComponentNames.TransformationWaeDataModifier.name,
                PredefinedComponentNames.TransformationShield.name,
                PredefinedComponentNames.TransformationSentinel.name
            ].indexOf(predefinedComponentName) !== -1
        );
    },
    isWAEventTypeTransformationRelated: function (predefinedComponentName) {
        return (
            [
                PredefinedComponentNames.TransformationWaeMetaFilter.name,
                PredefinedComponentNames.TransformationWaeDbTableFilter.name,
                PredefinedComponentNames.TransformationWaeOperatorFilter.name,
                PredefinedComponentNames.TransformationWaeAddUserData.name,
                PredefinedComponentNames.TransformationWaeToTypedEvent.name,
                PredefinedComponentNames.TransformationWaeToDwStage.name,
                PredefinedComponentNames.TransformationWaeDataModifier.name,
            ].indexOf(predefinedComponentName) !== -1
        );
    },
    isAvailable: function (predefinedComponentName, metaObject) {
        return new Promise((resolve) => {
            if (!this.isTransformationComponent(predefinedComponentName)) {
                resolve(true);
                return;
            }

            if (metaObject.type === utils.ENTITIES.STREAM) {
                let isWaeEventDataType = metaObjectHelper.isWAEventDataType(metaObject.attributes.dataType);

                if (this.isWAEventTypeTransformationRelated(predefinedComponentName)) {
                    metaObjectHelper.hasValidDbSource(metaObject.id).then((hasValidDbSource) => {
                        resolve(isWaeEventDataType && hasValidDbSource);
                    });
                } else {
                    resolve(!isWaeEventDataType);
                }
            } else {
                resolve(false);
            }
        });
    },
};
