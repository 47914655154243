// A base HighCharts mixin
import App from "app";
import "jquery";
import "underscore";
import "backbone";
import calculateMarkerSize from "./calculateMarkerSize";
import "app/components/dashboard/visualization/charts/seriesgenerators/default/generator";
import * as L from "leaflet";
import "node_modules/leaflet-polylinedecorator/dist/leaflet.polylineDecorator";
import "node_modules/leaflet-svg-shape-markers/dist/leaflet-svg-shape-markers";
import evaluator from "app/components/dashboard/visualization/charts/gauge/expressionEvaluator";

App.module("Chart.SeriesGenerators.Leaflet", function (Generator, App, Backbone, Marionette, $, _) {
    Generator.SeriesGeneratorFactory = function () {};

    Generator.SeriesGeneratorFactory.prototype.create = function (options) {
        return new LeafletSeriesGenerator(options);
    };

    function LeafletSeriesGenerator(options) {
        this.latitudeField = options.latitudeField;
        this.longitudeField = options.longitudeField;
        this.valueField = options.valueField;
        this.timeField = options.timeField;
        this.areaIDField = options.areaIDField;
        this.minBubbleSize = options.minBubbleSize;
        this.maxBubbleSize = options.maxBubbleSize;
        this.areas = options.areas;
        this.viewZoom = options.viewZoom;
        this.clickHandler = options.clickHandler;
        this.retentionStrategyType = options.retentionStrategyType;
        this.markerTypes = options.markerTypes;

        App.Chart.SeriesGenerators.Default.SeriesGenerator.prototype.setOptions.call(this, options);
    }

    LeafletSeriesGenerator.prototype = new App.Chart.SeriesGenerators.Default.SeriesGenerator();
    LeafletSeriesGenerator.prototype.generate = function () {
        if (typeof this.data !== "undefined") {
            const sourceData = this.getFlatData(this.categoryField, this.data);

            return {
                markersLayer: this.generateSeries(sourceData),
                areaColors: this.getAreaColors(sourceData),
            };
        }
    };

    LeafletSeriesGenerator.prototype.generateSeries = function (data) {
        var markers = new L.FeatureGroup();
        if (this.latitudeField === "" || this.longitudeField === "") return markers;

        var _this = this;

        _(data).each(function (row, index) {
            var val = row[_this.valueField];
            if (!_this.minValue || val < _this.minValue) {
                _this.minValue = val;
            }
            if (!_this.maxValue || val > _this.maxValue) {
                _this.maxValue = val;
            }
        });

        _(data).each(function (row) {
            var colorAliasValue = _this.categoryField;
            if (_this.colorField !== "") {
                colorAliasValue = _this.colorField;
            }
            var colorAndAlias = _this.getPointColorAndAlias(row, colorAliasValue);

            if (!_this.isKeyInLegendData(colorAndAlias.alias)) {
                _this.addKeyAndColorToLegendData(colorAndAlias.alias, colorAndAlias.color);
            }

            var marker = _this.createMarker(row, colorAndAlias.color);
            marker.on("click", function () {
                _this.clickHandler(row);
            });
            markers.addLayer(marker);
        });

        if (_this.categoryField && _this.retentionStrategyType === App.QueryManager.RETENTION_STRATEGY_ALL) {
            let grouped = _.groupBy(data, _this.categoryField);
            Object.keys(grouped).forEach((key) => {
                const group = grouped[key];
                if (group.length <= 1) {
                    return;
                }
                if (this.timeField) {
                    group.sort((row1, row2) => {
                        return row1[this.timeField] - row2[this.timeField];
                    });
                }
                const routeColor = "#2f393d";
                const coordinates = group.map((row) => L.latLng(row[this.latitudeField], row[this.longitudeField]));
                for (let i = 0; i < coordinates.length - 1; i++) {
                    let start = coordinates[i];
                    let end = coordinates[i + 1];
                    let path = L.polyline([start, end], {
                        color: routeColor,
                        weight: 1,
                        dashArray: "5",
                    }).addTo(markers);
                    L.polylineDecorator(path, {
                        patterns: [
                            {
                                offset: "50%",
                                repeat: 0,
                                symbol: L.Symbol.arrowHead({
                                    pixelSize: 6,
                                    polygon: false,
                                    pathOptions: { weight: 1, color: routeColor, fill: true, fillOpacity: 1 },
                                }),
                            },
                        ],
                    }).addTo(markers);
                }
            });
        }

        return markers;
    };

    LeafletSeriesGenerator.prototype.getAreaColors = function (data) {
        if (!this.areaIDField) {
            return null;
        }

        const areaColors = {};

        let grouped = _.groupBy(data, this.areaIDField);
        Object.keys(grouped).forEach((areaID) => {
            const areaDataPoints = grouped[areaID];
            if (this.timeField) {
                areaDataPoints.sort((row1, row2) => {
                    return row1[this.timeField] - row2[this.timeField];
                });
            }

            const lastAreaDataPoint = _.last(areaDataPoints);
            const area = _.findWhere(this.areas, { ID: lastAreaDataPoint[this.areaIDField] });
            if (area) {
                area.colors.forEach((colorCondition) => {
                    if (evaluator.evaluate(colorCondition.expression, lastAreaDataPoint) === true) {
                        areaColors[area.ID] = colorCondition.color;
                    }
                });
            }
        });

        return areaColors;
    };

    LeafletSeriesGenerator.prototype.createMarker = function (row, seriesColor) {
        let markerSize;
        var value = row[this.valueField];
        if (!this.valueField) {
            markerSize = 8; // when value is not set, treat the point as a marker.;
        } else {
            markerSize = calculateMarkerSize(
                value,
                +this.minValue,
                +this.maxValue,
                +this.minBubbleSize,
                +this.maxBubbleSize
            );
        }

        const marker = L.shapeMarker(L.latLng(row[this.latitudeField], row[this.longitudeField]), {
            shape: this.getMarkerType(row),
            radius: markerSize / 2, // shape marker expects the size to be in pixels as it uses svg paths with pixel sizes
            color: seriesColor,
            fillColor: seriesColor,
            fill: true,
            fillOpacity: 1,
            stroke: true,
        });

        var popup = L.popup({
            offset: L.point(0, -5),
        });
        popup.setContent(this.generateToolTip(row));
        marker.bindPopup(popup);

        marker.on("mouseover", function () {
            marker.openPopup();
        });

        return marker;
    };

    LeafletSeriesGenerator.prototype.getMarkerType = function (row) {
        let markerType = "circle";
        if (!this.markerTypes) {
            return markerType;
        }

        this.markerTypes.forEach((type) => {
            if (evaluator.evaluate(type.expression, row) === true) {
                markerType = type.markerType;
            }
        });

        return markerType;
    };

    LeafletSeriesGenerator.prototype.generateToolTip = function (rowData) {
        var generateToolTipRow = function (label, value) {
            return '<div><span style="font-weight: bold">' + label + ": </span><span>" + value + "</span></div>";
        };
        var content = '<div style="font-weight: bold; color: black">';
        if (this.tooltipConfig.show_all) {
            var length = this.fieldList.length;
            for (var i = 0; i < length; i++) {
                var currentField = this.fieldList[i];
                content = content + generateToolTipRow(currentField.field, rowData[currentField.field]);
            }
        } else {
            for (var i = 0; i < this.tooltipConfig.tooltip_values.length; i++) {
                var tooltipValue = this.tooltipConfig.tooltip_values[i];
                content = content + generateToolTipRow(tooltipValue.label, rowData[tooltipValue.srcfield]);
            }
        }
        return content + "</div>";
    };
});
