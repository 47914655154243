import Backbone from "backbone";
import App from "app";
import menu from "app/components/common/menu/menu";
import menuOption from "app/components/common/menu/menu-option";
import statusManagement, { FlowStatus } from "src/status-management";
import _ from "underscore";
import Dropdown from "app/components/common/dropdown";
import AppIndicator from "app/components/common/app-indicator";
import AppControl from "app/components/common/app-control/app-control";
import appStatusSynchronizer from "core/appStatusSynchronizer";
import localErrorsStorage from "./appErrors/localErrorsStorage";
import appStatusManagementTemplate from "./templates/appStatusManagementTemplate.html";
import { hasSavedValidationErrors } from "app/components/flow/designer/data-type-validation/validation-errors-storage";
import utils from "core/utils";
import MarionetteWrapper from "../../marionette-wrapper-for-react";
import DeployModalWrapper from "../../../../src/generic/deploy-modal/deploy-modal-wrapper";

function setDisplayFlex(el) {
    el.css("display", "flex");
}

function disableElement(el) {
    el.addClass("disabled");
}

function enableElement(el) {
    el.removeClass("disabled");
}
export default Backbone.Marionette.LayoutView.extend({
    template: _.template(appStatusManagementTemplate),
    tagName: "ul",
    onBeforeDeploy: null,

    ui: {
        preloader: "#appStatusPreloader",
        appIndicator: "#appIndicator",
        appStatus: "#appStatus",
        actionStatusWrapper: "#action-status-wrapper",
        actionStatus: "#action-status",
        appStatusMenu: "#appStatusMenu"
    },

    regions: {
        appIndicator: "#appIndicator",
        reactDeployModal: "#react-deploy-modal"
    },

    initialize: function() {
        var _this = this;

        this.appIndicatorControl = new AppIndicator({
            identifier: this.model.app,
            position: "bottom",
            show_tooltip: false
        });

        this.appControl = new AppControl({
            appIdentifier: this.model.app
        });

        function hidePreloader() {
            _this.ui.preloader.hide();
            _this.ui.appIndicator.css("display", "flex");
            enableElement(_this.ui.actionStatusWrapper);
        }

        this.listenTo(App.vent, "progress:end", hidePreloader);
        this.listenTo(
            App.vent,
            "cancel:deployment",
            function() {
                hidePreloader();
                _this.ui.appStatus.text(utils.capitalize(_this.status));
            }.bind(this)
        );
        this.listenTo(
            App.vent,
            "start:data-type-validation",
            function() {
                this.ui.appIndicator.hide();
                setDisplayFlex(this.ui.preloader);
                disableElement(this.ui.actionStatusWrapper);
                _this.ui.appStatus.text("VALIDATING");
            }.bind(this)
        );
    },

    onRender: function() {
        var _this = this;

        this.getRegion("appIndicator").show(this.appIndicatorControl);

        _this.menu = this.createMenu([
            {
                id: "deployStatus",
                text: "Deploy"
            },
            {
                id: "runningStatus",
                text: "Start"
            },
            {
                id: "showErrorsStatus",
                text: "Show Errors",
                visible: false
            },
            {
                id: "quiesceStatus",
                text: "Quiesce App",
                visible: true
            },
            {
                id: "validationErrors",
                text: "Table mapping validation",
                visible: false
            }
        ]);

        this.actionStatusMenu = new Dropdown({
            parentView: _this,
            content: _this.menu,
            trigger: _this.ui.actionStatus,
            position: "bottom",
            classes: "app-status-dropdown",
            before_show: function() {
                var status = appStatusSynchronizer.getStatus(_this.model.app.id);
                // Do not show the dropdown for Automated Apps
                if (_this.options.isAutomatedGroup) return false;
                return statusManagement.isInterruptibleStatus(status);
            },
            on_show: function(tooltip, dropDownContainer) {
                _this.ui.actionStatusWrapper.find("#statusTooltip").tooltipster("hide");
                _this.ui.actionStatus.addClass("active");
                var status = appStatusSynchronizer.getStatus(_this.model.app.id);

                var deployStatus = dropDownContainer.options.model.attributes.options.models[0];
                var runningStatus = dropDownContainer.options.model.attributes.options.models[1];
                var showErrorsStatus = dropDownContainer.options.model.attributes.options.models[2];
                var quiesceStatus = dropDownContainer.options.model.attributes.options.models[3];
                var showValidationErrors = dropDownContainer.options.model.attributes.options.models[4];

                if (
                    statusManagement.isDeployedState(status) ||
                    status === FlowStatus.QUIESCED ||
                    status === FlowStatus.COMPLETED ||
                    status === FlowStatus.RUNNING ||
                    status === FlowStatus.DEPLOYING ||
                    status === FlowStatus.HALT ||
                    status === FlowStatus.CRASH ||
                    status === FlowStatus.TERMINATED ||
                    status === FlowStatus.NOT_ENOUGH_SERVERS
                ) {
                    deployStatus.text = "Undeploy App";
                } else {
                    deployStatus.text = "Deploy App";
                }

                if (
                    status === FlowStatus.RUNNING ||
                    status === FlowStatus.STARTING ||
                    status === FlowStatus.STARTING_SOURCES ||
                    status === FlowStatus.RECOVERING_SOURCES
                ) {
                    runningStatus.text = "Stop App";
                } else if (
                    status === FlowStatus.HALT ||
                    status === FlowStatus.CRASH ||
                    status === FlowStatus.TERMINATED
                ) {
                    runningStatus.text = "Resume App";
                } else {
                    runningStatus.text = "Start App";
                }

                deployStatus.visible =
                    status === FlowStatus.CREATED ||
                    status === FlowStatus.DEPLOY_FAILED ||
                    statusManagement.isDeployedState(status) ||
                    status === FlowStatus.QUIESCED ||
                    status === FlowStatus.COMPLETED ||
                    status === FlowStatus.NOT_ENOUGH_SERVERS ||
                    status === FlowStatus.DEPLOYING ||
                    statusManagement.isApplicationCrash(status);

                runningStatus.visible =
                    status === FlowStatus.RUNNING ||
                    status === FlowStatus.QUIESCED ||
                    status === FlowStatus.COMPLETED ||
                    status === FlowStatus.STARTING ||
                    status === FlowStatus.STARTING_SOURCES ||
                    status === FlowStatus.HALT ||
                    status === FlowStatus.CRASH ||
                    status === FlowStatus.TERMINATED ||
                    statusManagement.isDeployedState(status);

                showErrorsStatus.visible =
                    statusManagement.isApplicationCrash(status) ||
                    status === FlowStatus.INVALID ||
                    status === FlowStatus.DEPLOY_FAILED ||
                    (status === FlowStatus.CREATED && localErrorsStorage.load(_this.model.app).length > 0);

                showValidationErrors.visible = false;
                if (
                    (status === FlowStatus.CREATED || status === FlowStatus.DEPLOY_FAILED) &&
                    !showErrorsStatus.visible
                ) {
                    hasSavedValidationErrors(_this.model.app).then(hasValidationErrors => {
                        showValidationErrors.visible = hasValidationErrors;
                    });
                }

                quiesceStatus.visible = status === FlowStatus.RUNNING;

                var arrowBorderSpan = tooltip.find(".tooltipster-arrow-bottom>span");
                arrowBorderSpan.addClass("tooltipster-border");
                arrowBorderSpan.removeAttr("style");

                var arrowSpan = arrowBorderSpan.clone();
                arrowSpan.addClass("tooltipster-background");
                arrowSpan.removeAttr("style");
                tooltip.find(".tooltipster-arrow-bottom").append(arrowSpan);
            },
            on_hide: function() {
                _this.ui.actionStatus.removeClass("active");
            }
        });
    },

    createMenu: function(menuOptions) {
        var _this = this;
        this.actions = new menuOption.Model.Collection();
        menuOptions.forEach(function(mi) {
            var miModel = new menuOption.Model(mi);
            miModel.id = mi.id;
            _this.actions.add(miModel);
        });

        var menuModel = new menu.Model({
            options: _this.actions
        });

        var menuView = new menu.View({
            model: menuModel
        });

        _this.listenTo(menuView, "option:click", function(optionId) {
            menuView.trigger("dropdown:hide");
            var menuAction = _this["execute_" + optionId + "Action"];
            if (menuAction) {
                menuAction.bind(this)();
            } else {
                alert(optionId);
            }
        });

        return menuView;
    },

    onShow: function() {
        var _this = this;
        function refresh(status) {
            if (_this.isDestroyed) {
                return;
            }
            _this.model.app.flowStatus = status.toUpperCase();
            if (statusManagement.isTransientStatus(status) && !statusManagement.isRecoveringStatus(status)) {
                _this.ui.appIndicator.hide();
                setDisplayFlex(_this.ui.preloader);
                disableElement(_this.ui.actionStatusWrapper);
            } else {
                _this.ui.appIndicator.css("display", "flex");
                _this.ui.preloader.hide();
                enableElement(_this.ui.actionStatusWrapper);
            }

            _this.status = status;
            let statusText = statusManagement.getStatusDisplayName(status);
            _this.ui.appStatus.text(statusText);

            statusManagement.setAppStatusClassOnNode(_this.$el, status.toLowerCase());

            var tooltip_content =
                "This app is " + statusManagement.getStatusDisplayName(_this.appIndicatorControl.status) + ".";

            if (_this.appIndicatorControl.status.toUpperCase() === FlowStatus.INVALID) {
                tooltip_content = "This app is invalid. Please fix errors to revalidate.";
            }
            if (_this.appIndicatorControl.status.toUpperCase() === FlowStatus.DEPLOY_FAILED) {
                tooltip_content = "This app has failed during deployment. Please fix errors to revalidate.";
            }
            if (_this.appIndicatorControl.status.toUpperCase() === FlowStatus.NOT_ENOUGH_SERVERS) {
                tooltip_content = "This app does not have enough servers. Please start enough servers to revalidate";
            }
            if (statusManagement.isApplicationCrash(_this.appIndicatorControl.status)) {
                tooltip_content = `This app has ${
                    _this.appIndicatorControl.status.toUpperCase() === FlowStatus.HALT ? "halted" : "terminated"
                }. Please fix the errors to resume.`;
            }
            // Tooltipp For Automated Apps
            if (_this.options.isAutomatedGroup) {
                tooltip_content = `Use the <span class='tooltipster-link'>automated pipeline</span> to manage the App status`;
                disableElement(_this.ui.actionStatusWrapper);
            }

            _this.ui.appIndicator.toggleClass(
                "clickable",
                statusManagement.isApplicationCrash(_this.appIndicatorControl.status)
            );
            _this.ui.actionStatusWrapper.find("#statusTooltip").tooltipster("content", tooltip_content);
        }

        _this.ui.appIndicator.on("click", function() {
            if (!_this.appIndicatorControl.status) {
                return;
            }

            if (statusManagement.isApplicationCrash(_this.appIndicatorControl.status)) {
                _this.trigger("show-app-errors", false);
            }
        });

        _this.ui.actionStatusWrapper.find("#statusTooltip").tooltipster({
            position: "bottom",
            contentAsHTML: true,
            interactive: _this.options.isAutomatedGroup,
            functionReady: function(_origin, _tooltip) {
                _this.actionStatusMenu.hide();
                $(".tooltipster-link").on("click", () => {
                    _this.options.moveToAppGroups();
                });
                /*var $toolbar = $("#toolbar");
                tooltip.offset({
                    top: $toolbar.position().top + $toolbar.outerHeight(),
                    left: tooltip.offset().left
                });*/
            }
        });

        this.listenTo(
            appStatusSynchronizer,
            this.model.app.id,
            function(app_status) {
                var uiStatus = _this.status;
                if (_this.model.app.flowStatus === app_status.toUpperCase() && uiStatus === app_status) {
                    return;
                }

                refresh(app_status);
            }.bind(this)
        );
        var status = appStatusSynchronizer.getStatus(_this.model.app.id);
        if (status) {
            refresh(status);
        }
    },

    onDestroy: function() {
        this.ui.actionStatusWrapper.find("#statusTooltip").tooltipster("destroy");
    },

    execute_deployStatusAction: function() {
        var _this = this;

        var status = this.model.app.flowStatus;

        if (status === FlowStatus.RUNNING || status === FlowStatus.INVALID) {
            return;
        }

        if (
            statusManagement.isDeployedState(status) ||
            status === FlowStatus.QUIESCED ||
            status === FlowStatus.COMPLETED
        ) {
            this.ui.appIndicator.hide();
            setDisplayFlex(this.ui.preloader);
            disableElement(this.ui.actionStatusWrapper);
            this.appControl.undeploy();
            return;
        }

        if (status === FlowStatus.CREATED || status === FlowStatus.DEPLOY_FAILED) {
            this.onBeforeDeploy().then(function() {
                _this.ui.appIndicator.hide();
                setDisplayFlex(_this.ui.preloader);
                disableElement(_this.ui.actionStatusWrapper);
                //set-react-deploy-modal
                const ReactDeployModalView = MarionetteWrapper(DeployModalWrapper, {
                    appId: _this.model.app.id
                });
                _this.getRegion("reactDeployModal").empty();
                _this.getRegion("reactDeployModal").show(ReactDeployModalView);
                // _this.appControl.deploy();
            });
            return;
        }

        if (
            statusManagement.isApplicationCrash(status) ||
            status === FlowStatus.NOT_ENOUGH_SERVERS ||
            status === FlowStatus.DEPLOYING
        ) {
            this.appControl.undeploy();
        }
    },

    execute_runningStatusAction: function() {
        var status = this.model.app.flowStatus;

        if (statusManagement.isApplicationCreated(status) || status === FlowStatus.NOT_ENOUGH_SERVERS) {
            return;
        }

        this.ui.appIndicator.hide();
        setDisplayFlex(this.ui.preloader);
        disableElement(this.ui.actionStatusWrapper);

        if (
            statusManagement.isDeployedState(status) ||
            status === FlowStatus.QUIESCED ||
            status === FlowStatus.COMPLETED
        ) {
            this.appControl.start();
            return;
        }

        if (status === FlowStatus.RUNNING || status === FlowStatus.STARTING || status === FlowStatus.STARTING_SOURCES) {
            this.appControl.stop();
            return;
        }

        if (statusManagement.isApplicationCrash(status)) {
            this.appControl.resume();
            return;
        }

        console.warn("no action for status: " + status);
    },

    execute_quiesceStatusAction: function() {
        var status = this.model.app.flowStatus;

        if (status !== FlowStatus.RUNNING) {
            return;
        }

        this.ui.appIndicator.hide();
        setDisplayFlex(this.ui.preloader);
        disableElement(this.ui.actionStatusWrapper);
        this.appControl.quiesce();
    },

    execute_showErrorsStatusAction: function() {
        var loadLocal =
            this.model.app.flowStatus === FlowStatus.CREATED || this.model.app.flowStatus === FlowStatus.DEPLOY_FAILED;
        this.trigger("show-app-errors", loadLocal);
    },

    execute_validationErrorsAction: function() {
        this.trigger("show-validation-errors");
    }
});
