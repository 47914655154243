import _ from "underscore";
import Papa from "papaparse";

import prefillProperties from "app/components/flow/designer/nodeProcessing/prefillProperties";
import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import { DB_PROVIDERS } from "../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/configs/db-providers";

import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import metaObjects from "core/services/metaStoreService/meta-objects";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import api from "core/api/api";
import utils from "core/utils";

import TARGET_FORM_INFO from "../target/target-form-info";
import { computeSSLConfig, getConnectionUrl, setParalleThreadsForSource } from "./wizards-methods-helper";
import { validationUtils, targetUtils } from "../utils";

import { sourceInfoType, targetAdapterPropsType, targetInfoType } from "../target/Types";
import localStorageUtils from "../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/local-storage-utils";
import {
    getCDCName,
    isAutomatedWizard
} from "../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";
import { populateDWHProperties } from "../utils/populateDWHTarget";
import { TYPES } from "../utils/wizard-types";
import {
    getPropertyVariable,
    setCDCSourcePropertyVariables,
    setILSourcePropertyVariables
} from "../utils/encrypt-url-utils";

import { APP_ADAPTER_DEFAULTS } from "../../../../app/components/appwizard/component-wizards/wizards/common/app-adapters-global-config";

function isSourceAppAdapter(sourceInfo) {
    const source = sourceInfo.adapterName;
    let predefinedComponentName = PredefinedComponentNames[source]?.name;
    return (
        Object.values(DB_PROVIDERS).find(adapter => adapter?.name === predefinedComponentName)?.isAppAdapter || false
    );
}

function fetchCDDLData() {
    return fetch("src/modules/wizards/resources/cddlData.csv").then(function(response) {
        let reader = response.body.getReader();
        let decoder = new TextDecoder("utf-8");
        return reader.read().then(function(result) {
            return decoder.decode(result.value);
        });
    });
}

const getDatabaseType = (sourceProps, uppercase = true) => {
    const type = sourceProps["DatabaseProviderType"] ?? sourceProps.adapterName;
    return uppercase ? type?.toUpperCase() : type;
};

const getAdvancedFields = adapter =>
    TARGET_FORM_INFO[validationUtils.getTargetType(adapter).toUpperCase()]?.advanced_fields ?? [];

export const getTablesKey = targetAdapter => {
    var targetAdapterPropertyTemplate = propertyTemplateService.propertyTemplates.findWhere({ id: targetAdapter });
    let tablesField = null;

    if (targetAdapterPropertyTemplate) {
        _.each(targetAdapterPropertyTemplate.get("propertyMap"), function(propertyMap) {
            if (propertyMap.name.toLowerCase() === "tables") {
                tablesField = propertyMap.name;
            }
        });
    }
    return tablesField;
};

// Adds COLUMNMAP to the tables format
const addColumnMapping = (object, targetSchemaPrefix) => `${object}.%,${targetSchemaPrefix}.% COLUMNMAP()`;

async function saveTarget(
    componentName: string,
    sourceInfo: sourceInfoType,
    targetInfo: targetInfoType,
    isAutomatedILCDC: boolean,
    cdcSourceInfo: sourceInfoType,
    cdctargetInfo: targetInfoType,
    wizardType: string,
    sourceEncryptedURL: { encryptedSSLConfig?: string; encryptedConnectionURL?: string } | boolean,
    targetEncryptedURL: { encryptedSSLConfig?: string; encryptedConnectionURL?: string } | boolean
) {
    const sourceType = getDatabaseType(sourceInfo.sourceAdapter.properties, false);
    function createTargetModel(componentName: string) {
        let targetAdapterProps: targetAdapterPropsType = {};
        targetAdapterProps = prefillProperties(
            "TARGET",
            targetUtils.getPredefinedComponentName(componentName),
            PredefinedComponentNames,
            targetAdapterProps
        );

        const propertyTemplateID = "Global.PROPERTYTEMPLATE." + targetAdapterProps.adapter.handler;
        targetAdapterProps.adapter.handler = propertyTemplateID;
        return new metaObjects.TARGET.Model(targetAdapterProps);
    }
    function populateRequiredProperties(targetModel, values: targetInfoType, isCDC: boolean = false): void {
        const stringifiedValues: Object | {} = {};
        const formData = values.targetFormValues;
        const targetFormInfo =
            TARGET_FORM_INFO[validationUtils.getTargetType(componentName).toUpperCase()]?.form_fields;
        const targetFormInfoObject = {};
        for (let formField of targetFormInfo) {
            targetFormInfoObject[formField.name] = formField;
        }
        Object.keys(formData)?.forEach(key => {
            if (targetFormInfoObject[key]?.addToModel) {
                if (typeof formData[key] === "object" && formData[key].hasOwnProperty("label")) {
                    return (stringifiedValues[key] = formData[key]?.value);
                }
                stringifiedValues[key] = formData[key];
            }
        });
        _.extend(stringifiedValues, values.defaultProperties);
        targetModel.get("adapter").properties = stringifiedValues;
        populateAdvancedSettings(targetModel, targetInfo, isCDC);
    }

    const populateAdvancedSettings = (targetModel, values: targetInfoType, isCDC: boolean = false) => {
        const adapterProperties = targetModel.get("adapter").properties;
        const targetFormInfo = getAdvancedFields(componentName);
        targetFormInfo.map(field => {
            if (field.addToModel) {
                if (field.name === "ParallelThreads" && (isCDC || values.targetFormValues["ParallelThreads"] === ""))
                    return null;
                return (adapterProperties[field.name] = values.targetFormValues[field.name]);
            }
        });
    };

    async function populateAdapterSpecificProperties(
        targetModel,
        values: targetInfoType,
        isCDC: boolean = false
    ): Promise<void> {
        const formData = values.targetFormValues;
        const adapterProperties = targetModel.get("adapter").properties;
        if (validationUtils.isDWH(componentName)) {
            populateDWHProperties(adapterProperties, values, componentName, isAutomatedILCDC, isCDC, sourceType);
        } else {
            // For SalesforceCDCReader and Not DWH Set the COLUMNMAP
            if (isAutomatedILCDC && isCDC && cdcSourceInfo.adapterName === "SalesforceCDCReader") {
                const tableKey = getTablesKey(targetModel.get("adapter").handler);
                adapterProperties[tableKey] += " COLUMNMAP()";
            }
        }

        const targetAdapterHandler = targetModel.get("adapter").handler;
        if (isAutomatedILCDC && isCDC && targetAdapterHandler === "Global.PROPERTYTEMPLATE.DatabaseWriter") {
            adapterProperties["IgnorableExceptionCode"] =
                "DUPLICATE_ROW_EXISTS,NO_OP_UPDATE,NO_OP_PKUPDATE,NO_OP_DELETE";
        }

        const adapterName = validationUtils.getTargetType(componentName);
        if (validationUtils.isRDBMS(adapterName)) {
            const sslConfig = computeSSLConfig(adapterName.toUpperCase(), formData, true);
            if (targetEncryptedURL?.encryptedConnectionURL) {
                adapterProperties["ConnectionURL"] = getPropertyVariable(targetEncryptedURL.encryptedConnectionURL);
            } else {
                adapterProperties["ConnectionURL"] = await getConnectionUrl(adapterName.toUpperCase(), formData);
            }
            if (targetEncryptedURL?.encryptedSSLConfig) {
                adapterProperties["sslConfig"] = getPropertyVariable(targetEncryptedURL.encryptedSSLConfig);
            } else {
                sslConfig && (adapterProperties["sslConfig"] = sslConfig);
            }
        }
        targetModel.get("adapter").properties = adapterProperties;
    }

    let shortAppName = utils.getName(sourceInfo.appId);
    let nsName = utils.getNamespace(sourceInfo.appId);

    // If Target already saved, Fetch the target model
    var targetModel = await metaStoreService.findById(`${nsName}.TARGET.${shortAppName}_Target`);
    if (!targetModel) targetModel = createTargetModel(componentName);

    let isCDCWizard = wizardType === TYPES.CDC;

    if (isAutomatedILCDC) {
        const ILSourceModel = await metaStoreService.findById(sourceInfo.sourceId);
        setILSourcePropertyVariables(ILSourceModel, sourceEncryptedURL);
        setParalleThreadsForSource(ILSourceModel, targetInfo);
        await ILSourceModel.save({ flow: `${shortAppName}_SourceFlow` });
    }

    if (isAutomatedILCDC || isCDCWizard) {
        // Fetch CDDL csv
        const csvdata = await fetchCDDLData();
        const CDDL = Papa.parse(csvdata, { delimiter: ";" }).data;
        // Get source model and add CDDL data

        let CDCSourceModel, sourceReaderType;

        if (isAutomatedILCDC) {
            CDCSourceModel = await metaStoreService.findById(cdcSourceInfo.sourceId);
            sourceReaderType = cdcSourceInfo.adapterName.toUpperCase();
        } else {
            CDCSourceModel = await metaStoreService.findById(sourceInfo.sourceId);
            sourceReaderType = sourceInfo.adapterName.toUpperCase();
        }

        const CDDLAction = targetInfo.targetFormValues?.["CDDLAction"]?.toUpperCase();
        if (CDDLAction) {
            // finds the index of the CDDL action
            const getCDDLActionIndex = action => CDDL[0].indexOf(action);
            // finds the index of the source
            const getSourceIndex = source => CDDL.findIndex(element => element[0] === source);

            const sourceIndex = getSourceIndex(sourceReaderType);
            const cddlActionIndex = getCDDLActionIndex(CDDLAction);

            const CDDLProps = JSON.parse(CDDL[sourceIndex][cddlActionIndex]);
            // If CDDLTrackingTable is present populate the data
            if (CDDLProps.CDDLTrackingTable)
                CDDLProps.CDDLTrackingTable = targetInfo.targetFormValues["CDDLTrackingTable"];

            CDCSourceModel.get("adapter").properties = { ...CDCSourceModel.get("adapter").properties, ...CDDLProps };
        }
        if (isAutomatedILCDC) {
            // Use property variable for CDC source
            setCDCSourcePropertyVariables(CDCSourceModel, sourceEncryptedURL);
        }
        const AppName = isAutomatedILCDC ? getCDCName(shortAppName) : shortAppName;
        const flowName = `${AppName}_SourceFlow`;
        await CDCSourceModel.save({ flow: flowName });
    }

    targetModel.set("inputStream", sourceInfo.inputStream);
    targetModel.set("nsName", nsName);
    targetModel.set("name", targetUtils.getTargetComponentName(shortAppName));

    populateRequiredProperties(targetModel, targetInfo);
    await populateAdapterSpecificProperties(targetModel, targetInfo, isCDCWizard);
    await targetModel.save({ flow: shortAppName });

    if (isAutomatedILCDC) {
        //-- Setting recovery period to 30seconds for auomated apps --
        const ilAppModel = await metaStoreService.findById(sourceInfo.appId);
        ilAppModel.set("recoveryPeriod", 30);
        await api.updateApplicationSettings(ilAppModel.attributes);

        const cdcAppModel = await metaStoreService.findById(cdcSourceInfo.appId);
        cdcAppModel.set("recoveryPeriod", 30);
        await api.updateApplicationSettings(cdcAppModel.attributes);
    }

    if (isAutomatedILCDC) {
        let cdcShortAppName = utils.getName(cdcSourceInfo.appId);
        let cdcTargetModel = await metaStoreService.findById(`${nsName}.TARGET.${cdcShortAppName}_Target`);
        if (!cdcTargetModel) {
            cdcTargetModel = createTargetModel(componentName);
        }
        cdcTargetModel.set("inputStream", cdcSourceInfo.inputStream);
        cdcTargetModel.set("nsName", nsName);
        cdcTargetModel.set("name", targetUtils.getTargetComponentName(cdcShortAppName));
        populateRequiredProperties(cdcTargetModel, cdctargetInfo, true);
        await populateAdapterSpecificProperties(cdcTargetModel, cdctargetInfo, true);
        await cdcTargetModel.save({ flow: cdcShortAppName });
        //Compiling CDC app which contains CDC source and target
        await api.compile(cdcSourceInfo.appId);
    }

    if (isSourceAppAdapter(sourceInfo)) {
        const appModel = await metaStoreService.findById(sourceInfo.appId);
        appModel.set("recoveryPeriod", 300);
        await api.updateApplicationSettings(appModel.attributes);
    }
    //Compiling IL app which contains IL source and target
    await api.compile(sourceInfo.appId);
}

async function getAdapterDefaultProperties(
    appModel,
    componentName: string,
    sourceInfo: sourceInfoType
): Promise<Object> {
    const PredefinedComponentName = targetUtils.getPredefinedComponentName(componentName);
    async function getCustomprops(sourceObject, targetAdapter) {
        const isCDCSource = sourceObject => {
            const adapterProps = sourceObject.get("adapter");
            if (adapterProps && adapterProps.handler) {
                return (
                    adapterProps.handler.toLowerCase().indexOf("oraclereader") !== -1 ||
                    adapterProps.handler.toLowerCase().indexOf("mysqlreader") !== -1 ||
                    adapterProps.handler.toLowerCase().indexOf("postgresqlreader") !== -1 ||
                    adapterProps.handler.toLowerCase().indexOf("mssqlreader") !== -1
                );
            }
            return false;
        };
        const existingProps = await api.getAdapterProperties(targetAdapter);
        const isCDC = isCDCSource(sourceObject);
        const sourceAdapter = sourceInfo?.adapterName;
        let adapterProps = {};
        adapterProps = _.extend(adapterProps, existingProps);
        switch (targetAdapter) {
            case "SnowflakeWriter": {
                adapterProps["appendOnly"] = !isCDC;
                return adapterProps;
            }
            case "AzureSQLDWHWriter":
            case "RedSh": {
                if (!isCDC) {
                    adapterProps["uploadPolicy"] = "eventcount:10000,interval:60s";
                }
                return adapterProps;
            }
            case "BigQueryWriter": {
                if (isSourceAppAdapter(sourceInfo)) {
                    adapterProps["CDDLOptions"] = APP_ADAPTER_DEFAULTS.CDDLOPTIONS;
                }
                return adapterProps;
            }
            case "DeltaLakeWriter": {
                if (!isCDC) adapterProps["uploadPolicy"] = "eventcount:10000,interval:120s";
                return adapterProps;
            }
            case "FabricDataWarehouseWriter":
                if (!isCDC) adapterProps["uploadPolicy"] = "eventcount:10000,interval:120s";
                return adapterProps;
            default:
                return adapterProps;
        }
    }
    async function setTablesProperty(appModel, currentProps: Object, targetAdapter: string) {
        let targetSchemaPrefix = targetUtils.setTargetSchemaPrefix(currentProps);
        try {
            let getTablesProperty = function(sourceObject) {
                const adapterProps = sourceObject.get("adapter");
                // if (clearTables(adapterProps?.properties?.adapterName, targetAdapter)) return null;
                let tableMapping = [];
                // This regex is taken from com.striim.IdentifierUtils.CaseSensitivityUtils.NON_SPL_CHAR_REGEX
                const regex = new RegExp("^[a-zA-Z][a-zA-Z0-9_]*$");
                if (adapterProps && adapterProps.properties && adapterProps.properties.Tables) {
                    if (adapterProps.properties.adapterName === "ServiceNowReader") {
                        const objects = adapterProps.properties.Tables.split(";");
                        _.each(objects, function(object) {
                            tableMapping.push(`${object}, ${targetSchemaPrefix + object}.%`);
                        });
                    } else {
                        const tables = adapterProps.properties.Tables.split(";");
                        let wildcardSchemas = new Set();
                        _.each(tables, function(table) {
                            let chunks = table.split(".");
                            let firstPart = null;
                            if (chunks.length === 3) firstPart = chunks.shift();

                            let secondPartOriginal = chunks.shift();
                            let secondPart = secondPartOriginal;
                            let secondPartMod = targetUtils.getValidName(secondPart);
                            let isSafe = true;
                            if (regex.test(secondPartMod)) secondPart = secondPartMod;
                            else isSafe = false;

                            let thirdPart = chunks.shift();
                            if (thirdPart) {
                                let thirdPartMod = targetUtils.getValidName(thirdPart);
                                if (regex.test(thirdPartMod)) thirdPart = thirdPartMod;
                                else isSafe = false;
                            }

                            if (isSafe) {
                                let targetSchema = targetUtils.getTargetSchema(
                                    componentName,
                                    targetSchemaPrefix,
                                    secondPart
                                );
                                let sourceSchema =
                                    (firstPart != null ? firstPart + "." : "") + secondPartOriginal + '."%"';
                                wildcardSchemas.add(JSON.stringify({ source: sourceSchema, target: targetSchema }));
                            } else {
                                let targetTable = targetUtils.getTargetTable(
                                    componentName,
                                    targetSchemaPrefix,
                                    secondPart,
                                    thirdPart
                                );
                                tableMapping.push(table + "," + targetTable);
                            }
                        });
                        wildcardSchemas.forEach((item: { source: string; target: string }) => {
                            item = JSON.parse(item.toString());
                            tableMapping.push(item.source + "," + item.target);
                        });
                    }
                } else if (adapterProps && adapterProps.properties && adapterProps.properties.sObjects) {
                    //  This is displayed in target's tables field, for Salesforce source
                    // For AutomatedWizards, use salesforce as default schema name
                    if (isAutomatedWizard()) tableMapping.push("%,salesforce.%");
                    else {
                        const objects = adapterProps.properties.sObjects.split(";");
                        _.each(objects, function(object) {
                            tableMapping.push(`${object}, ${targetSchemaPrefix + object}.%`);
                        });
                    }
                }
                return tableMapping.join(";");
            };
            let tablesField = getTablesKey(targetAdapter);
            if (tablesField !== null) {
                const sourceObjects = await appModel.getApplicationComponentsByType("SOURCE");
                const firstSource = sourceObjects.at(0);

                const tablesProperty = getTablesProperty(firstSource);
                currentProps[tablesField] = tablesProperty;
                return currentProps;
            }
        } catch (e) {
            console.error("Error processing property template", e);
        }
    }

    let targetAdapterProps: targetAdapterPropsType = {};
    targetAdapterProps = prefillProperties(
        "TARGET",
        PredefinedComponentName,
        PredefinedComponentNames,
        targetAdapterProps
    );
    const targetAdapter = "Global.PROPERTYTEMPLATE." + targetAdapterProps.adapter.handler;
    const sourceObjects = await appModel.getApplicationComponentsByType("SOURCE");
    const firstSource = sourceObjects.at(0);
    const customProps = await getCustomprops(firstSource, targetAdapterProps.adapter.handler);

    // For all adapters except SalesForceReader, don't set Tables Property
    if (isSourceAppAdapter(sourceInfo) && !["SalesForceReader"].includes(sourceInfo.adapterName)) {
        return customProps;
    }
    let tables = await setTablesProperty(appModel, customProps, targetAdapter);
    return tables;
}

async function deployAndStartApplication(fullAppName: string, cdcfullAppName: string, wizardAppName: string) {
    const isIlCDC = isAutomatedWizard();
    let targetDGName: string = null;

    const getAppFlows = (fullAppName: string): { appName: string; sourceFlow: string } => {
        const appName = utils.getName(fullAppName);
        const sourceFlow = `${appName}_SourceFlow`;
        return {
            appName,
            sourceFlow
        };
    };

    const namespace = utils.getNamespace(fullAppName);
    const sourceConnectionParams = await JSON.parse(localStorage.getItem(wizardAppName))?.connectionParams;
    const agentDGName = sourceConnectionParams?.agentDGName;
    const IlAppFlow = getAppFlows(fullAppName);
    try {
        const NO_DG = ["agents", "defaultagentmonitoring"];
        const DGs = propertyTemplateService.deploymentGroups;
        for (let i = 0; i < DGs.length; i++) {
            const DG = DGs.at(i);
            if (NO_DG.indexOf(DG.get("name").toLowerCase()) === -1 && DG._servers?.length > 0) {
                targetDGName = DG["name"];
                break;
            }
        }
        try {
            const deployApp = async (appData: { appName: string; sourceFlow: string }) => {
                const { appName, sourceFlow } = appData;
                const flows = {};
                flows[appName] = {
                    flow: appName,
                    group: targetDGName,
                    strategy: "any"
                };
                // if agent is selected, deploy the sourceflow on agent
                // and app on the available DG
                if (agentDGName) {
                    flows[sourceFlow] = {
                        flow: sourceFlow,
                        group: agentDGName,
                        strategy: "any"
                    };
                }
                const where = {
                    namespace: namespace,
                    appName: appName
                };
                await api.deploy(flows, where);
            };
            //IL app
            await deployApp(IlAppFlow);
            //Deplou CDC app if it is IL/CDC
            if (isIlCDC) {
                const cdcAppFlow = getAppFlows(cdcfullAppName);
                await deployApp(cdcAppFlow);
            }
        } catch (e) {
            let errorMessage = typeof e === "object" ? e.message : JSON.stringify(e);

            return {
                result: false,
                message: `There was a problem deploying your application : <br/> ${errorMessage}<br/>Please start the application manually.`
            };
        } finally {
            localStorageUtils.removeItem(wizardAppName);
            localStorageUtils.removeItem(localStorageUtils.getTargetKey(wizardAppName));
        }

        if (isAutomatedWizard()) {
            const name = utils.getName(fullAppName);
            const ILFullName = namespace + "." + name;
            const CDCFullName = namespace + "." + getCDCName(name);
            const statement = `START APPLICATION ${ILFullName} on COMPLETED (START APPLICATION ${CDCFullName});`;
            await api.startAutomated(statement);
        } else await api.startApp(IlAppFlow.appName);
        return { result: true };
    } catch (e) {
        console.log("error is ", e);
        return {
            result: false,
            message: `There was a problem starting your application. Please start the application manually.`
        };
    }
}

const isCDDLSupported = async appName => {
    const sourceConfig = localStorageUtils.getItem(appName)?.connectionParams;
    const sourceParams = validationUtils.getSourceParams(sourceConfig);
    const cddlSupport = await api.isCDDLSupported(sourceParams, sourceConfig.agentName);
    return cddlSupport === "true";
};

export {
    saveTarget,
    getAdapterDefaultProperties,
    deployAndStartApplication,
    getAdvancedFields,
    getDatabaseType,
    isCDDLSupported
};
