import $ from "jquery";
import ComponentView from "./componentView";
import groupTemplate from "./templates/groupTemplate.html";
import "perfectscrollbar";
import { initMaterialDesignCollapsible } from "../../../../../core/utils/material-design/material-design-collapsible";
import licenseService from "core/services/metaStoreService/license-service";
import metaStoreService from "../../../../../core/services/metaStoreService/meta-store-service";

var PredefinedComponentsGroupView = Backbone.Marionette.CompositeView.extend({
    template: _.template(groupTemplate),
    tagName: "li",
    childViewContainer: "div.items",
    childView: ComponentView,
    className: "group",
    attributes: function() {
        return {
            "data-test-id": this.model.get("name")
        };
    },

    isInSharedHeightMode: false,
    currentNumberOfVisibleGroups: 0,

    ui: {
        header: ".group-header",
        collectionContainer: "div.items",
        arrow: "span.arrow"
    },

    modelEvents: {
        "change:isExpanded": "_changeExpanded",
        "change:isVisible": "_changeVisible"
    },

    _changeExpanded: function() {
        this.$el.toggleClass("active", this.model.isExpanded);
        const groupContent = this.$el[0].querySelector(".collapsible-body");
        if (groupContent) {
            if (this.model.isExpanded) {
                groupContent.style.display = "block";
            } else {
                groupContent.style.display = "none";
            }
        }
        this.ui.arrow.toggleClass("icon-arrow-expanded", this.model.isExpanded);
        this.ui.arrow.toggleClass("icon-forward", !this.model.isExpanded);
    },

    _changeVisible: function(view, value) {
        this.$el.toggleClass("hidden", !value);
    },

    sortComponents: function() {
        this.collection.models.sort((a, b) => (a.isEnabled === b.isEnabled ? 0 : a.isEnabled ? -1 : 1));
    },

    hideUnavailableComponents: function(isAIInsightsDisabled) {
        this.collection.models = this.collection.filter(component => {
            if (component.componentType !== metaStoreService.entities.SENTINEL) {
                return true;
            }
            return !isAIInsightsDisabled;
        });
    },

    initialize: function() {
        var _this = this;

        this.collection = this.model.components;
        const isAIInsightsDisabled = licenseService?.isAIInsightsDisabled;

        this.collection.bind(
            "change:isVisible",
            function() {
                var visibleChildren = _this.collection.filter({
                    isVisible: true
                });
                this.$el.toggleClass("hidden", visibleChildren.length === 0);
            },
            this
        );
        this.sortComponents();
        this.hideUnavailableComponents(isAIInsightsDisabled);
    },
    onRender: function() {
        this._changeExpanded();
    },

    onShow: function() {
        var _this = this;

        var setContainerHeight = _.debounce(function() {
            if (_this.isInSharedHeightMode) {
                _this.setSharedGroupHeight(_this.currentNumberOfVisibleGroups);
            } else {
                _this.setFullHeight();
            }
        }, 150);
        $(window).resize(setContainerHeight);

        setContainerHeight();
        _this.ui.collectionContainer.perfectScrollbar({
            suppressScrollX: true
        });

        setTimeout(() => {
            //M.Collapsible.init(document.querySelectorAll(".component-groups.collapsible"), {});
            initMaterialDesignCollapsible(document.querySelectorAll(".component-groups.collapsible"));
        }, 0);
    },

    setFullHeight: function() {
        if (!this.ui.collectionContainer.outerHeight) {
            return;
        }
        var bottomOffset = 60;
        var groupHeaders = $(".left-menu .group-header");
        var height =
            $(".left-menu").height() -
            $(".left-menu .search-input").outerHeight() -
            $(groupHeaders[0]).outerHeight() * groupHeaders.length -
            bottomOffset;

        this.ui.collectionContainer.css("max-height", height);
        this.ui.collectionContainer.perfectScrollbar("update");
    },

    setSharedGroupHeight: function(numberOfVisibleGroups) {
        if (!this.ui.collectionContainer.outerHeight) {
            return;
        }
        if (numberOfVisibleGroups === 0) {
            return;
        }
        var bottomOffset = 60;

        var height =
            $(".left-menu").height() -
            $(".left-menu .search-input").outerHeight() -
            this.ui.header.outerHeight() * numberOfVisibleGroups -
            bottomOffset;
        height = height / numberOfVisibleGroups;

        this.ui.collectionContainer.css("max-height", height);
        this.ui.collectionContainer.perfectScrollbar("update");
    }
});

export default PredefinedComponentsGroupView;
