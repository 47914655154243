import React, { useState, useEffect } from "react";
import api from "core/api/api";
import lastConnectionTestRepository from "app/components/common/editor/control/connection-text-field/last-connection-test-repository";
import { connectionStatuses } from "app/components/common/editor/control/connection-text-field/last-connection-test-repository";
import App from "app";
import { getValidationDetails, getDateTime } from "./test-connection-helper";
import TestConnectionMsgBox from "./test-connection-msg-box";
import {
    getDatabaseType,
    isNoSQLAdapter
} from "app/components/common/editor/control/connection-text-field/connection-test-view";
import TestConnectionForAgent from "./test-connection-for-agent";
import { IActionBtn } from "../fd-form-builder/action-button";

interface TestConnectionProps {
    formRef: object;
    propertiesModel?: object;
    metaObjectId?: string;
    handler: string;
    isFormEnabled: boolean;
    buttonProps: IActionBtn;
}

type DeploymentGroup = {
    label: string;
    value: string;
};

export interface ValidationDtls {
    Username: string;
    ConnectionURL: string;
    adapterName: string;
    Password: string | object;
    encrypted: boolean;
    SSLConfig: string;
    databaseProviderType: string;
    agentDeploymentGroups: DeploymentGroup[];
    databaseType: string;
}

const TestConnection = ({
    propertiesModel,
    metaObjectId,
    handler,
    formRef,
    isFormEnabled,
    buttonProps
}: TestConnectionProps) => {
    const adapter = handler?.split(".")?.pop();
    if (!isFormEnabled) {
        return null;
    }
    const useApplicationHelperAPI = path => {
        const ApplicationHelperRegex = /^com.webaction.web.api.ApplicationHelper/m;
        return ApplicationHelperRegex.test(path);
    };

    const isApplicationHelperAPI = useApplicationHelperAPI(buttonProps.path);
    let validationDtls = getValidationDetails({
        propertiesModel,
        handler,
        formRef,
        apiParams: buttonProps.parameters,
        isApplicationHelperAPI
    });
    const [showAgentModal, setShowAgentModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [connectionSuccess, setConnectionSuccess] = useState(false);
    const [lastValidationDate, setLastValidateDate] = useState(null);
    const [unavailableDriverDBType, setUnavailableDriverDBType] = useState(null);

    useEffect(() => {
        (async () => {
            const validationStatus = await lastConnectionTestRepository.load(metaObjectId, handler);
            switch (validationStatus?.status) {
                case connectionStatuses.ok: {
                    setConnectionSuccess(true);
                    setLastValidateDate(validationStatus.validationDate);
                    break;
                }
                case connectionStatuses.connectionFailed: {
                    setErrorMsg(validationStatus.message);
                    break;
                }
                case connectionStatuses.driversNotFound: {
                    setUnavailableDriverDBType(validationStatus.message);
                    break;
                }
                default: {
                    setErrorMsg("");
                    setConnectionSuccess(false);
                    setUnavailableDriverDBType(null);
                }
            }
        })();
    }, []);

    const checkDriver = async databaseType => {
        const driverExists = await api.checkDrivers(databaseType);
        return !driverExists;
    };

    const handleTestConnectionClick = () => {
        if (validationDtls?.agentDeploymentGroups?.length > 0) {
            setShowAgentModal(true);
            return;
        }
        testConnection();
    };

    const constructRequestParams = validationDtls => {
        let request;

        if (buttonProps.parameters_type === "MAP") {
            request = {};
            Object.values(buttonProps.parameters).forEach(key => {
                request[key] = validationDtls[key];
            });
        }

        return [request];
    };

    const testConnection = (agentDeploymentGroup = "") => {
        validationDtls = getValidationDetails({
            propertiesModel,
            handler,
            formRef,
            apiParams: buttonProps.parameters,
            isApplicationHelperAPI
        });
        const { databaseType } = validationDtls;

        (async () => {
            let validationResponse;
            let isDriverUnavailable = false;
            const testedDate = getDateTime();
            const isRDBMSAdapter = getDatabaseType(adapter);
            setErrorMsg("");
            setConnectionSuccess(false);
            setLastValidateDate("");
            setUnavailableDriverDBType(null);
            setLoading(true);
            let params = constructRequestParams(validationDtls);
            const adapterProperties = params[0];
            try {
                if (isRDBMSAdapter) {
                    isDriverUnavailable = await checkDriver(databaseType);
                    setUnavailableDriverDBType(isDriverUnavailable ? databaseType : null);
                    if (isDriverUnavailable) {
                        validationResponse = {
                            result: false,
                            message: databaseType
                        };
                        return;
                    }
                    params = [api.token, databaseType, ...params, agentDeploymentGroup, metaObjectId || null];
                }

                if (isNoSQLAdapter(adapter)) {
                    params = [...params, agentDeploymentGroup];
                }

                let res;
                if (isApplicationHelperAPI) {
                    res = await api.call(buttonProps.path, [...params]);
                } else {
                    res = await api.executePropertyAction(
                        metaObjectId,
                        handler,
                        buttonProps.path,
                        adapterProperties,
                        agentDeploymentGroup
                    );
                }
                validationResponse = JSON.parse(res);
                if (validationResponse?.result) {
                    setConnectionSuccess(validationResponse.message);
                    setLastValidateDate(testedDate);
                } else {
                    setErrorMsg(validationResponse.message);
                }
            } catch (e) {
                validationResponse = {
                    result: false,
                    message: e?.message
                };
                setErrorMsg(e?.message);
            } finally {
                const status = isDriverUnavailable
                    ? connectionStatuses.driversNotFound
                    : validationResponse?.result
                    ? connectionStatuses.ok
                    : connectionStatuses.connectionFailed;
                if (metaObjectId) {
                    lastConnectionTestRepository.save(metaObjectId, handler, {
                        status,
                        validationDate: testedDate,
                        message: validationResponse?.message
                    });
                }
                App.vent.trigger("component:validation", {
                    modelId: metaObjectId,
                    status,
                    message: validationResponse?.message
                });
                setLoading(false);
            }
        })();
    };

    return (
        <>
            {showAgentModal && (
                <TestConnectionForAgent
                    validationDtls={validationDtls}
                    setShowAgentModal={setShowAgentModal}
                    showAgentModal={showAgentModal}
                    testConnection={testConnection}
                    loading={loading}
                    errorMsg={errorMsg}
                    connectionSuccess={connectionSuccess}
                    unavailableDriverDBType={unavailableDriverDBType}
                />
            )}
            <TestConnectionMsgBox
                loading={loading}
                testConnection={handleTestConnectionClick}
                errorMsg={errorMsg}
                connectionSuccess={connectionSuccess}
                lastValidationDate={lastValidationDate}
                unavailableDriverDBType={unavailableDriverDBType}
                testCxnBtnName={buttonProps?.name}
                handler={handler}
            />
        </>
    );
};
export default TestConnection;
