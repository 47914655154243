import React from "react";
import { PLAYERSTATUS } from "./automated-apps-states";
import statusmanager, { FlowStatus as FLOWSTATUS } from "../../../../../../status-management";

const IL_PLAYER_PREFIX = "IL";
const CDC_PLAYER_PREFIX = "CDC";
const IL_CDC_PLAYER_PREFIX = "IL&CDC";
const SCHEMA_CREATION_PREFIX = "SCHEMA_CREATION";

export const getStageInfo = (statuses?: Record<string, string>) => {
    let ilAppStatus = "",
        cdcAppStatus = "";
    if (statuses) {
        [ilAppStatus, cdcAppStatus] = Object.values(statuses);
    }

    return {
        CREATED: {
            description: (
                <span>
                    The automated pipeline has not been started yet. Click the <i>Start</i> button to create the schema at
                    the target, automatically followed by initial load and CDC.
                </span>
            )
        },
        DEPLOYED: {
            description: (
                <span>
                    The automated pipeline has been deployed but not started yet. Click the <i>Start</i> button to create
                    the schema at the target, automatically followed by initial load and CDC.
                </span>
            )
        },
        SCHEMA_CREATION_RUNNING: {
            name: "Schema Creation",
            description: (
                <span>
                    The initial load (IL) App is running. Striim is creating the schema at the target to match the
                    schema at the source. The initial load will automatically start after the schema is created.
                </span>
            )
        },
        SCHEMA_CREATION_STOPPED: {
            name: "Schema Creation",
            description: (
                <span>
                    The initial load (IL) App has stopped. Resume to continue creating the schema at the target to match
                    the schema at the source. The initial load will automatically start after the schema is created.
                </span>
            )
        },
        SCHEMA_CREATION_TERMINATED: {
            name: "Schema Creation",
            description: (
                <span>
                    The initial load (IL) App has halted due to an error. Resolve it to continue creating the schema at
                    the target to match the schema at the source. The initial load will automatically start once the
                    schema is created.
                </span>
            )
        },
        IL_RUNNING: {
            name: "Initial Load",
            description:
                "The initial load (IL) App is running. This App will run until the initial data in the source has been synced with the target.  Once IL completes, the change-data-capture (CDC) App will automatically start and continue to load any updates at the source into the target."
        },
        IL_STOPPED: {
            name: "Initial Load",
            description:
                "The initial load (IL) App has stopped. Resume to continue syncing the IL data from the source to the target.  Once IL completes, the change-data-capture (CDC) App will automatically start and continuously sync updates at the source to the target."
        },
        IL_TERMINATED: {
            name: "Initial Load",
            description:
                "The initial load (IL) App has terminated or halted due to an error. Resolve it to continue syncing the initial data from the source to the target. Once IL completes, the change-data-capture (CDC) App will automatically start and continuously sync updates at the source to the target."
        },
        CDC_START: {
            name: "Change Data Capture",
            description: (
                <span>
                    The change-data-capture (CDC) App has not been started yet. Click the <i>Start</i> button to begin
                    the change data capture process.
                </span>
            )
        },
        CDC_RUNNING: {
            name: "Change Data Capture",
            description:
                "The change-data-capture (CDC) App is now running. This App will continue to load any updates at the source into the target in real time."
        },
        CDC_STOPPED: {
            name: "Change Data Capture",
            description:
                "The change-data-capture (CDC) App has stopped. Resume it to continue loading updates at the source to the target in real-time."
        },
        CDC_TERMINATED: {
            name: "Change Data Capture",
            description:
                "The change-data-capture (CDC) App has terminated or halted due to an error. Resolve it to continue loading updates at the source to the target in real time."
        },
        IL_TRANSITIONAL: {
            name: "Initial Load",
            description: `The initial load (IL) App is now  ${statusmanager.getStatusDisplayName(ilAppStatus)}`
        },
        CDC_TRANSITIONAL: {
            name: "Change Data Capture",
            description: `The change-data-capture (CDC) App is now ${statusmanager.getStatusDisplayName(cdcAppStatus)}`
        },
        RESET: {
            name: "RESET",
            description: (
                <span>
                    Striim encountered an error in the application group. Click <i>Reset</i> to resolve it by attempting
                    to stop and undeploy both the IL and CDC apps. This action will not affect the application's
                    recovery position
                </span>
            )
        }
    };
};
const withUnderscore = (prefix: string, stage: string): string => {
    return prefix + "_" + stage;
};
export const getStatusInfo = (AppStatuses: Record<string, string>, schemaCreationRunning: boolean) => {
    const appStatuses = Object.values(AppStatuses);
    const ILAppStatus = appStatuses[0];
    const CDCAppStatus = appStatuses[1];

    if (statusmanager.isApplicationCreated(ILAppStatus) && statusmanager.isApplicationCreated(CDCAppStatus)) {
        return {
            playerStatus: PLAYERSTATUS.START,
            stageInfo: FLOWSTATUS.CREATED
        };
    }
    if (statusmanager.isApplicationDeployed(ILAppStatus) && statusmanager.isApplicationDeployed(CDCAppStatus)) {
        return {
            playerStatus: [PLAYERSTATUS.START_ONLY, PLAYERSTATUS.UNDEPLOY],
            stageInfo: FLOWSTATUS.DEPLOYED
        };
    }

    if (statusmanager.isApplicationDeployed(CDCAppStatus)) {
        if (statusmanager.isRunningState(ILAppStatus)) {
            const stageInfoPrefix = schemaCreationRunning ? SCHEMA_CREATION_PREFIX : IL_PLAYER_PREFIX;
            return {
                playerStatus: withUnderscore(IL_PLAYER_PREFIX, PLAYERSTATUS.STOP),
                stageInfo: withUnderscore(stageInfoPrefix, FLOWSTATUS.RUNNING)
            };
        }
        if (statusmanager.isStoppedStatus(ILAppStatus)) {
            const stageInfoPrefix = schemaCreationRunning ? SCHEMA_CREATION_PREFIX : IL_PLAYER_PREFIX;
            return {
                playerStatus: [
                    withUnderscore(IL_PLAYER_PREFIX, PLAYERSTATUS.START_ONLY),
                    withUnderscore(IL_PLAYER_PREFIX, PLAYERSTATUS.UNDEPLOY)
                ],
                stageInfo: withUnderscore(stageInfoPrefix, FLOWSTATUS.STOPPED)
            };
        }
        if (statusmanager.isApplicationCrash(ILAppStatus) && ILAppStatus !== FLOWSTATUS.DEPLOY_FAILED) {
            const stageInfoPrefix = schemaCreationRunning ? SCHEMA_CREATION_PREFIX : IL_PLAYER_PREFIX;
            return {
                playerStatus: [withUnderscore(IL_PLAYER_PREFIX, PLAYERSTATUS.UNDEPLOY)],
                stageInfo: withUnderscore(stageInfoPrefix, FLOWSTATUS.TERMINATED)
            };
        }
    }
    if (statusmanager.isCompletedStatus(ILAppStatus)) {
        if (statusmanager.isRunningState(CDCAppStatus)) {
            return {
                playerStatus: withUnderscore(CDC_PLAYER_PREFIX, PLAYERSTATUS.STOP),
                stageInfo: withUnderscore(CDC_PLAYER_PREFIX, FLOWSTATUS.RUNNING)
            };
        }
        if (CDCAppStatus === FLOWSTATUS.STOPPED)
            return {
                playerStatus: [
                    withUnderscore(CDC_PLAYER_PREFIX, PLAYERSTATUS.RESUME),
                    withUnderscore(CDC_PLAYER_PREFIX, PLAYERSTATUS.UNDEPLOY)
                ],
                stageInfo: withUnderscore(CDC_PLAYER_PREFIX, FLOWSTATUS.STOPPED)
            };
        if (CDCAppStatus === FLOWSTATUS.DEPLOYED || CDCAppStatus === FLOWSTATUS.QUIESCED)
            return {
                playerStatus: [
                    withUnderscore(CDC_PLAYER_PREFIX, PLAYERSTATUS.START_ONLY),
                    withUnderscore(CDC_PLAYER_PREFIX, PLAYERSTATUS.UNDEPLOY)
                ],
                stageInfo: withUnderscore(CDC_PLAYER_PREFIX, FLOWSTATUS.STOPPED)
            };

        if (statusmanager.isApplicationCrash(CDCAppStatus) && CDCAppStatus !== FLOWSTATUS.DEPLOY_FAILED)
            return {
                playerStatus: [
                    withUnderscore(CDC_PLAYER_PREFIX, PLAYERSTATUS.RESUME),
                    withUnderscore(CDC_PLAYER_PREFIX, PLAYERSTATUS.UNDEPLOY)
                ],
                stageInfo: withUnderscore(CDC_PLAYER_PREFIX, FLOWSTATUS.TERMINATED)
            };
    }
    if (statusmanager.isCompletedStatus(ILAppStatus)) {
        if (statusmanager.isRunningState(CDCAppStatus)) {
            return {
                playerStatus: withUnderscore(CDC_PLAYER_PREFIX, PLAYERSTATUS.STOP),
                stageInfo: withUnderscore(CDC_PLAYER_PREFIX, FLOWSTATUS.RUNNING)
            };
        }
    }

    if (statusmanager.isCompletedStatus(ILAppStatus) && statusmanager.isApplicationCreated(CDCAppStatus)) {
        return {
            playerStatus: withUnderscore(CDC_PLAYER_PREFIX, PLAYERSTATUS.START),
            stageInfo: withUnderscore(CDC_PLAYER_PREFIX, PLAYERSTATUS.START)
        };
    }

    if (statusmanager.isCompletedStatus(ILAppStatus) && statusmanager.isApplicationCreated(CDCAppStatus)) {
        return {
            playerStatus: withUnderscore(CDC_PLAYER_PREFIX, PLAYERSTATUS.START),
            stageInfo: withUnderscore(CDC_PLAYER_PREFIX, PLAYERSTATUS.START)
        };
    }
    if (statusmanager.isTransientStatus(ILAppStatus)) {
        return {
            playerStatus: null,
            stageInfo: withUnderscore(IL_PLAYER_PREFIX, "TRANSITIONAL")
        };
    }

    if (statusmanager.isTransientStatus(CDCAppStatus)) {
        return {
            playerStatus: null,
            stageInfo: withUnderscore(CDC_PLAYER_PREFIX, "TRANSITIONAL")
        };
    }
    return {
        playerStatus: PLAYERSTATUS.RESET,
        stageInfo: PLAYERSTATUS.RESET
    };
};
