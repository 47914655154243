import dateLib from "core/utils/date-utils";
import { parseTitle } from "./titleParser";
import EventLogEntry from "./eventLogEntry";

function convert(exception) {
    const date = dateLib(exception.time);
    const fqName = exception.fqName.replace(/['"]+/g, "");
    return new EventLogEntry({
        title: parseTitle(exception.issue),
        status: "error",
        message: exception.issue,
        date: date.toDate(),
        formattedDate: date.format("LLL"),
        address: fqName.substring(0, 25),
        fullAddress: fqName,
        server: ""
    });
}

export default convert;
