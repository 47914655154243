import _ from "underscore";
import BaseControl from "./../base-control";
import UIControl from "app/components/common/editor/control/ui-control";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import FieldDataSource from "./../select/field-datasource";
import template from "./json-reader.html";
import ColumnsEnricherList from "app/components/common/editor/control/columns-picker/columns-enricher-list";

const supportedJsonColumnType = "com.fasterxml.jackson.databind.JsonNode";

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "columns-splitter",

    regions: {
        columnSelectorRegion: ".column-selector",
        aliasSelectorRegion: ".alias-selector",
    },

    setViewValue: function () {},

    getViewValue: function () {
        const list = this.aliasSelector.getValue();
        const fieldName = this.columnSelector.getValue();

        const viewValue = list.map((item) => {
            return {
                column: item.column,
                tqlFunction: item.tqlFunction,
                alias: item.alias,
                fieldName: fieldName,
            };
        });

        return viewValue;
    },

    setEnabled: function (enabled) {
        BaseControl.View.prototype.setEnabled.apply(this, [enabled]);

        this.columnSelector.setEnabled(enabled);
        this.aliasSelector.setEnabled(enabled);
    },

    initialize: function (options) {
        this.columnSelector = UIControl.Select({})
            .extend({
                placeholder: "select",
                noMatchesMessage: `No columns found of type '${supportedJsonColumnType}'.`,
            })
            .create();

        var columnsDataSource = [
            {
                id: "String",
                text: "String",
            },
            {
                id: "Integer",
                text: "Integer",
            },
            {
                id: "Double",
                text: "Double",
            },
            {
                id: "Boolean",
                text: "Boolean",
            },
        ];

        this.aliasSelector = ColumnsEnricherList.extend({
            hideLabel: true,
            hidden: false,
            transformationConfig: {
                columnsDataSource: columnsDataSource,
                addAvailable: true,
                preFillColumns: false,
                labels: {
                    column1: "New Field Name",
                    column2: "Type",
                    column3: "JSON Key",
                },
            },
        }).create();
    },

    onRender: function () {
        this.columnSelectorRegion.show(this.columnSelector);
        this.aliasSelectorRegion.show(this.aliasSelector);
        //this.toggleAliasSelector(this.columnSelector.model.value);

        if (this.model.value && this.model.value[0]) {
            this.columnSelector.setValue(this.model.value[0].fieldName);
            this.aliasSelector.model.value = this.model.value;
            this.toggleAliasSelector(this.model.value);
        }

        this.listenTo(
            this.aliasSelector.model,
            "change:value",
            function () {
                this.setValueFromView();
            }.bind(this)
        );

        this.listenTo(
            this.columnSelector.model,
            "change:value",
            function (viewModel) {
                this.aliasSelector.setValue([]);
                this.trigger(UIControl.EVENT.VALUE_CHANGED, this.getViewValue());

                this.toggleAliasSelector(viewModel.value);
            }.bind(this)
        );
    },

    setStream: function (componentId) {
        metaStoreService.findById(componentId).done(
            function (componentModel) {
                if (!componentModel) {
                    return;
                }

                var fieldName =
                    componentModel.type === metaStoreService.entities.CACHE
                        ? componentModel.typename
                        : componentModel.dataType;

                this.columnsDataSource = new FieldDataSource(fieldName).then((fields) => {
                    var filtered = _.filter(fields, function (field) {
                        return field.description === supportedJsonColumnType;
                    });

                    return filtered;
                });

                this.columnSelector.setDataSource(this.columnsDataSource);

                if (this.aliasSelector.gridView.collection && this.aliasSelector.gridView.collection.length === 0) {
                    this.aliasSelector.newColumnAdded();
                }
            }.bind(this)
        );
    },

    toggleAliasSelector(value) {
        if (!value) {
            this.aliasSelector.$el.hide();
        } else {
            this.aliasSelector.$el.show();
        }
    },
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: Array,
    },
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
