import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var module = {};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "EXTERNALCACHE",
        externalCacheProperties: Object,
        typename: String,
    },
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model,
});

export default module;
