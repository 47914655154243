import { makeStyles } from "@material-ui/core/styles";

const marginTop = 65;
const marginLeft = 15;
//const baseHeightPadding = 20;
const baseWidthPadding = 50;
const tabBorderMargin = 5;

export default makeStyles(({ palette, spacing }) => ({
    gridContainer: {
        padding: spacing(3),
        gap: 12,
        flexWrap: "nowrap",
        top: spacing(6),
        position: "relative",
        bottom: 0,
        height: "calc(100vh - 48px)"
    },
    header: { marginTop: 0, marginBottom: spacing(2) },
    wrapper: {
        margin: `${65}px auto auto auto`,
        minHeight: `calc(100vh - ${marginTop}px)`,
        backgroundColor: palette.common.white
    },
    tabSelector: {
        borderBottom: "solid 1px lightgray",
        margin: `0 ${tabBorderMargin}px`,
        padding: `0px ${baseWidthPadding + marginLeft - tabBorderMargin}px 1px`,
        "& .Mui-selected": {
            fontWeight: 600,
            borderBottom: "4px solid " + palette.primary.main
        },
        "& >": {
            transition: "all .5s ease"
        }
    },
    learnMoreLink: {
        color: palette.primary.main,
        position: "absolute",
        right: 0,
        top: 0,
        marginTop: 10,
        marginRight: 20,

        display: "none" //TODO: for beta link is hidden
    },
    card: {
        backgroundColor: palette.paper.background,
        border: `1px solid ${palette.paper.border}`,
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
        position: "fixed",
        borderRadius: spacing(2),

        width: "-webkit-fill-available",
        "@supports (-moz-appearance:none)": {
            width: "-moz-available"
        },
        marginRight: spacing(2),
        overflow: "auto",
        height: "calc(100% - 135px)"
    }
}));
