var comment = "--";

function isCommentedOut(statement) {
    return statement.indexOf(comment) !== -1;
}

export default {
    extractApplicationName: function(tql) {
        var regex = new RegExp("[- \\t]*CREATE(\\s+OR\\s+REPLACE)?\\s+APPLICATION\\s+(?:\\w*\\.)?(\\w*)", "i");
        var match = regex.exec(tql);

        if (!match || match.length < 3 || !match[2].trim() || isCommentedOut(match[0])) {
            return null;
        }

        return match[2];
    },
    extractNamespaceFromAppName: function(tql) {
        var regex = new RegExp("[- \\t]*CREATE(\\s+OR\\s+REPLACE)?\\s+APPLICATION\\s+(\\w*\\.)?(\\w*)", "i");
        var match = regex.exec(tql);
        if (!match || match.length < 3 || !match[2] || isCommentedOut(match[0])) {
            return null;
        }
        let res = match[2].replace(".", "").trim();
        return res ? res : null;
    },

    extractNamespace: function(tql) {
        function tryFindUseStatement(tql) {
            var lines = tql.split("\n");

            // trim all lines
            lines = lines.map(function(line) {
                return line.trim();
            });

            // remove comments and empty lines
            lines = lines.filter(function(line) {
                return line.indexOf("--") !== 0 && line !== "";
            });

            // make each statement single line
            var statements = [];
            statements.push("");
            lines.forEach(function(line) {
                statements[statements.length - 1] += " " + line;
                if (line.indexOf(";") === line.length - 1) {
                    statements.push("");
                }
            });

            // heuristic - assume that USE statement should be at the beggining
            // in first three lines
            var maxIndex = statements.length < 3 ? statements.length : 3;
            for (var i = 0; i < maxIndex; i++) {
                var statement = statements[i].trim();
                if (statement.indexOf("USE") === 0) {
                    return statement.substring(3, statement.length - 1).trim();
                }
            }
            return null;
        }

        if (!tql) {
            return null;
        }

        var regex = new RegExp("[-]*\\s*CREATE NAMESPACE (\\w*)", "i");
        var match = regex.exec(tql);

        if (!match || match.length < 2 || isCommentedOut(match[0])) {
            const regex2 = new RegExp("[-]*\\s*CREATE OR REPLACE NAMESPACE (\\w*)", "i");
            match = regex2.exec(tql);
        }

        if (!match || match.length < 2 || isCommentedOut(match[0])) {
            return tryFindUseStatement(tql);
        }

        return match[1];
    }
};
