import _ from "underscore";

// Bart Sekula - refactored this strange code out of property-template.js
// the original commit id is fdc240ea90f07311a0bd95998201c99bfeb84a17
export default {
    process: function (propertiesFromStore) {
        if (propertiesFromStore.id === "Global.PROPERTYTEMPLATE.RedshiftWriter") {
            // DEV-8666 Override Mode property of Adapter to Incremental
            var mode = _.find(propertiesFromStore.get("propertyMap"), {
                name: "Mode",
            });
            if (mode) {
                mode.defaultValue = "Incremental";
            }
        }
    },
};
