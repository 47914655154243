import Marionette from "marionette";
import $ from "jquery";
import _ from "underscore";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import GraphNodeModel from "./graphNodes/graphNodeModel";
import utils from "core/utils";

var FlowDatasource = Marionette.Object.extend({
    // cached list of external nodes
    _exteranlNodes: [],

    initialize: function(options) {
        this.options = options || {};
        this.graphModel = [];
    },

    load: function() {
        var _this = this;
        this._exteranlNodes = [];
        var id = metaObjectConverter.toTypedId(this.options.appName, metaStoreService.entities.APPLICATION);

        return metaStoreService
            .findById(id)
            .then(function(app) {
                _this.app = app;
                return _this.loadFlowsForApplication(app);
            })
            .then(function(graph) {
                _this.graphModel = graph;
                return _this.graphModel;
            });
    },

    findNodeById: function(id) {
        return _.find(this.graphModel, function(node) {
            return node.metaObject.id === id;
        });
    },

    /**
     * Find component referenced from other applications
     * @param id - component id
     * @param flowId - application id
     * @returns {*}
     */
    findAndAttachExternalNodeById: function(id, flowId) {
        var key = id + "-" + flowId;

        // external component should not be added to graph more than once
        if (this._exteranlNodes[key]) {
            return this._exteranlNodes[key];
        }

        return metaStoreService.findById(id).then(
            function(metaObject) {
                // external component should not be added to graph more than once
                if (this._exteranlNodes[key]) {
                    return this._exteranlNodes[key];
                }

                var node = {
                    flowId: flowId,
                    id: id,
                    metaObject: metaObject || {
                        id: id,
                        type: metaObjectConverter.getType(id),
                        name: metaObjectConverter.getName(id),
                        nsName: metaObjectConverter.getNamespace(id)
                    },
                    targets: [],
                    isExternal: !!metaObject,
                    isMissing: !metaObject
                };
                node.metaObject.isEditable = false;
                // DEV-8400 - Use same style for invalid nodes - we don't want to render missing nodes for now
                if (node.isMissing) {
                    return;
                }
                this.graphModel.push(node);
                this._exteranlNodes[key] = node;
                return node;
            }.bind(this)
        );
    },

    /**
     * Find component from current application
     * or find component from referenced from other applications
     * @param id - component id
     * @param flowId - application id
     * @returns node
     */
    _findAndAttachNode: function(id, flowId) {
        return this.findNodeById(id) || this.findAndAttachExternalNodeById(id, flowId);
    },

    loadFlowsForApplication: function(app) {
        var _this = this;

        if (!app) return null;

        return app
            .fetchObjects()
            .then(function(appItems) {
                var deferred = $.Deferred();

                $.when
                    .apply(
                        $,
                        appItems
                            .filter(function(appItem) {
                                return appItem.type === "FLOW";
                            })
                            .map(function(flow) {
                                //match each MetaObject with the flow it belongs to (MetaObject can belong only to one flow)
                                return flow.fetchObjects().then(function(metaObjects) {
                                    return metaObjects
                                        .filter(function(metaObject) {
                                            return metaObject.type !== "TYPE";
                                        })
                                        .map(function(metaObject) {
                                            return {
                                                id: metaObject.id,
                                                flowId: flow.id,
                                                metaObject: metaObject,
                                                targets: []
                                            };
                                        });
                                });
                            })
                    )
                    .then(function(/* we don't know how many results, so we use 'arguments' array */) {
                        // we need additionally to add top level flow (application) MetaObjects
                        var nodes = appItems
                            .filter(function(appItem) {
                                return (
                                    appItem.type !== "TYPE" &&
                                    appItem.type !== "PROPERTYSET" &&
                                    appItem.type !== "DASHBOARD"
                                );
                            })
                            .map(function(metaObject) {
                                return {
                                    id: metaObject.id,
                                    flowId: app.id,
                                    metaObject: metaObject,
                                    targets: []
                                };
                            });
                        nodes.push({
                            id: app.id,
                            flowId: "",
                            metaObject: app,
                            targets: []
                        });

                        // concatenate with previously build graph (need to flatten arguments as it's array of arrays)
                        _this.graphModel = nodes.concat(_.flatten(arguments, true));

                        // FIXME filter out anonymous meta objects
                        /*
                                    _this.graphModel = _this.graphModel.filter(function (node) {
                                        return node.metaObject.id.indexOf('c7a91b1a2e2') === -1;
                                    });
                */

                        _this._removeAnonymousSourceOutputs();

                        _this.graphModel = _this.graphModel.filter(function(node) {
                            var isAnonymousAndNotGenerated =
                                node.metaObject.metaInfoStatus &&
                                node.metaObject.metaInfoStatus.isAnonymous &&
                                !node.metaObject.metaInfoStatus.isGenerated;
                            if (isAnonymousAndNotGenerated) {
                                console.log("node will not be displayed ID:" + node.id + " / FlowId:" + node.flowId);
                            }
                            return !isAnonymousAndNotGenerated;
                        });

                        deferred.resolve();
                    });

                return deferred.promise();
            })
            .then(function() {
                //
                // finally we need to update the targets for each GraphNodeModel node
                //
                var deferred = $.Deferred();
                var externalDeferreds = [];
                _this.graphModel.forEach(function(node) {
                    var outputNode, inputNode;
                    if (
                        (node.metaObject.type === metaStoreService.entities.SOURCE &&
                            node.metaObject.outputclause === null) ||
                        node.metaObject.type === metaStoreService.entities.EXTERNALSOURCE
                    ) {
                        if (node.targets.length > 0) {
                            return;
                        }
                        outputNode =
                            _this.findNodeById(node.metaObject.outputStream) ||
                            _this.findAndAttachExternalNodeById(node.metaObject.outputStream, node.flowId);
                        externalDeferreds.push(
                            $.when(outputNode).then(function(outputNode) {
                                if (outputNode) {
                                    node.targets.push(outputNode);
                                }
                            })
                        );
                    } else if (
                        node.metaObject.type === metaStoreService.entities.SOURCE &&
                        node.metaObject.outputclause !== null
                    ) {
                        _.each(node.metaObject.outputclause, function(outputclause) {
                            if (typeof outputclause.map === "undefined") {
                                outputNode =
                                    _this.findNodeById(outputclause.outputStream) ||
                                    _this.findAndAttachExternalNodeById(node.metaObject.outputStream, node.flowId);
                            } else {
                                outputNode =
                                    _this.findNodeById(outputclause.map.streamName) ||
                                    _this.findAndAttachExternalNodeById(node.metaObject.outputStream, node.flowId);
                            }
                            externalDeferreds.push(
                                $.when(outputNode).then(function(outputNode) {
                                    if (outputNode) {
                                        node.targets.push(outputNode);
                                    }
                                })
                            );
                        });
                    } else if (node.metaObject.type === metaStoreService.entities.CACHE) {
                        if (node.metaObject.isEventTable) {
                            var attachEventTableStream = function(streamParent) {
                                if (!streamParent || !streamParent.properties) {
                                    return;
                                }
                                var streamName = streamParent.properties.name;
                                if (!streamName) {
                                    return;
                                }
                                var stream = _this._findAndAttachNode(streamName, node.flowId);
                                externalDeferreds.push(
                                    $.when(stream).then(function(inputNode) {
                                        if (inputNode) {
                                            inputNode.targets.push(node);
                                        }
                                    })
                                );
                            };

                            // Input stream
                            attachEventTableStream(node.metaObject.adapter);
                            // Delete stream
                            attachEventTableStream(node.metaObject.parser);
                        }
                    } else if (node.metaObject.type === metaStoreService.entities.CQ) {
                        if (node.metaObject.output) {
                            outputNode =
                                _this.findNodeById(node.metaObject.output) ||
                                _this.findAndAttachExternalNodeById(node.metaObject.output, node.flowId);
                            externalDeferreds.push(
                                $.when(outputNode).then(function(outputNode) {
                                    if (outputNode) {
                                        node.targets.push(outputNode);
                                    }
                                })
                            );
                        }

                        _.each(node.metaObject.inputs, function(inputId) {
                            // TODO why is STREAM_GENERATOR here?
                            if (inputId.indexOf("STREAM_GENERATOR") > 0) {
                                return;
                            }
                            inputNode =
                                _this.findNodeById(inputId) ||
                                _this.findAndAttachExternalNodeById(inputId, node.flowId);

                            externalDeferreds.push(
                                $.when(inputNode).then(function(inputNode) {
                                    if (inputNode) {
                                        inputNode.targets.push(node);
                                    }
                                })
                            );
                        });
                    } else if (node.metaObject.type === metaStoreService.entities.WINDOW) {
                        inputNode =
                            _this.findNodeById(node.metaObject.stream) ||
                            _this.findAndAttachExternalNodeById(node.metaObject.stream, node.flowId);
                        externalDeferreds.push(
                            $.when(inputNode).then(function(inputNode) {
                                if (inputNode) {
                                    inputNode.targets.push(node);
                                }
                            })
                        );
                    } else if (node.metaObject.type === metaStoreService.entities.TARGET) {
                        inputNode =
                            _this.findNodeById(node.metaObject.inputStream) ||
                            _this.findAndAttachExternalNodeById(node.metaObject.inputStream, node.flowId);
                        externalDeferreds.push(
                            $.when(inputNode).then(function(inputNode) {
                                if (inputNode) {
                                    inputNode.targets.push(node);
                                }
                            })
                        );
                    } else if (node.metaObject.type === metaStoreService.entities.ROUTER) {
                        inputNode =
                            _this.findNodeById(node.metaObject.inStream) ||
                            _this.findAndAttachExternalNodeById(node.metaObject.inStream, node.flowId);
                        externalDeferreds.push(
                            $.when(inputNode).then(function(inputNode) {
                                if (inputNode) {
                                    inputNode.targets.push(node);
                                }
                            })
                        );
                        _.each(node.metaObject.forwardingRules, fwdRule => {
                            let streamID = fwdRule["outStream"];

                            var outputStreamNode =
                                _this.findNodeById(streamID) ||
                                _this.findAndAttachExternalNodeById(streamID, node.flowId);
                            externalDeferreds.push(
                                $.when(outputStreamNode).then(function(outputStreamNode) {
                                    if (outputStreamNode) {
                                        node.targets.push(outputStreamNode);
                                    }
                                })
                            );
                        });
                    } else if (node.metaObject.type === metaStoreService.entities.OPENPROCESSOR) {
                        if (node.metaObject.from) {
                            var pcFromNode =
                                _this.findNodeById(node.metaObject.from) ||
                                _this.findAndAttachExternalNodeById(node.metaObject.from, node.flowId);
                            externalDeferreds.push(
                                $.when(pcFromNode).then(function(n) {
                                    if (n) {
                                        n.targets.push(node);
                                    }
                                })
                            );
                        }
                        if (node.metaObject.output) {
                            outputNode =
                                _this.findNodeById(node.metaObject.output) ||
                                _this.findAndAttachExternalNodeById(node.metaObject.output, node.flowId);
                            externalDeferreds.push(
                                $.when(outputNode).then(function(outputNode) {
                                    if (outputNode) {
                                        node.targets.push(outputNode);
                                    }
                                })
                            );
                        }
                        if (node.metaObject.enrichment) {
                            _.each(node.metaObject.enrichment, function(enrichmentId) {
                                var enrichmentNode =
                                    _this.findNodeById(enrichmentId) ||
                                    _this.findAndAttachExternalNodeById(enrichmentId, node.flowId);
                                externalDeferreds.push(
                                    $.when(enrichmentNode).then(function(enrichmentNode) {
                                        if (enrichmentNode) {
                                            enrichmentNode.targets.push(node);
                                        }
                                    })
                                );
                            });
                        }
                    } else if (node.metaObject.type === metaStoreService.entities.SENTINEL) {
                        inputNode =
                            _this.findNodeById(node.metaObject.inputStream) ||
                            _this.findAndAttachExternalNodeById(node.metaObject.inputStream, node.flowId);
                        externalDeferreds.push(
                            $.when(inputNode).then(function(inputNode) {
                                if (inputNode) {
                                    inputNode.targets.push(node);
                                }
                            })
                        );
                        outputNode =
                            _this.findNodeById(node.metaObject.outputStream) ||
                            _this.findAndAttachExternalNodeById(node.metaObject.outputStream, node.flowId);
                        externalDeferreds.push(
                            $.when(outputNode).then(function(outputNode) {
                                if (outputNode) {
                                    node.targets.push(outputNode);
                                }
                            })
                        );
                    }
                });

                $.when.apply($, externalDeferreds).then(function() {
                    _this.graphModel.forEach(function(node) {
                        if (node.metaObject.type === metaStoreService.entities.FLOW) {
                            // find meta objects belonging to this FLOW
                            // get it's targets' flow IDs
                            // flatten the list
                            // and make those unique
                            // find flow meta objects
                            // set those as this FLOW targets
                            node.targets = _.chain(_this.graphModel)
                                .filter({
                                    flowId: node.metaObject.id
                                })
                                .map(function(metaObject) {
                                    return _.chain(metaObject.targets)
                                        .pluck("flowId")
                                        .without(metaObject.flowId)
                                        .value();
                                })
                                .flatten()
                                .unique()
                                .map(_this.findNodeById.bind(_this))
                                .value();

                            // metaObjects belonging to the FLOW
                            //node.targets = node.targets.concat(_.chain(_this.graphModel)
                            //    .where({flowId: node.metaObject.id})
                            //    .value());
                        }
                    });
                    deferred.resolve(_this.graphModel);
                });

                return deferred.promise();
            });
    },

    _removeAnonymousSourceOutputs: function() {
        /*
         * Handling the scenario where Source use anonymous CQ:
         *
         *     Source -> Anonymous Stream -> Anonymous CQ -> Stream
         *
         * In Flow Designer we should see
         *
         *     Source -> Stream
         *
         * */

        var allSources = _(this.graphModel).filter(function(node) {
            return node.metaObject.type === metaStoreService.entities.SOURCE;
        });

        allSources.forEach(function(source) {
            // check if source has output to anonymous Stream
            var stream = this.findNodeById(source.metaObject.outputStream);
            if (!stream) {
                return;
            }

            if (!stream.metaObject.metaInfoStatus || !stream.metaObject.metaInfoStatus.isAnonymous) {
                return;
            }

            var cq = _(this.graphModel).find(function(node) {
                return (
                    node.metaObject.type === metaStoreService.entities.CQ &&
                    (node.metaObject.inputs || []).indexOf(stream.id) !== -1
                );
            });

            // check if anonymous Stream is connected to anonymus CQ
            if (!cq || !cq.metaObject.metaInfoStatus || !cq.metaObject.metaInfoStatus.isAnonymous) {
                return;
            }

            stream.targets.push(stream);

            // remove anonymous CQ and Stream
            this.graphModel = this.graphModel.filter(function(node) {
                return node.id !== stream.id && node.id !== cq.id;
            });
        }, this);
    },

    _removeAnonymousStandaloneStreams: function() {
        /*
         * Handling the scenario where created a source with an outputclause and inline select
         * */

        var allStreams = _(this.graphModel).filter(function(node) {
            return node.metaObject.type === metaStoreService.entities.STREAM;
        });

        allStreams.forEach(function(stream) {
            var cq = _(this.graphModel).find(function(node) {
                return (node.metaObject.inputs || []).indexOf(stream.id) !== -1;
            });

            // remove anonymous CQ and Stream
            this.graphModel = this.graphModel.filter(function(node) {
                return node.id !== stream.id && node.id !== cq.id;
            });
        }, this);
    },

    getObjectsForFlowId: function(flowId) {
        var _this = this;

        flowId = flowId || this.app.id;

        // filter only current FLOW meta objects
        var flowObjects = this.graphModel
            .filter(function(node) {
                return node.flowId === flowId;
            })
            .map(function(node) {
                return new GraphNodeModel({
                    id: node.id,
                    applicationId: _this.app.id,
                    flowId: node.flowId,
                    metaObject: node.metaObject,
                    targets: node.targets.map(function(targetNode) {
                        return targetNode.flowId === flowId ? targetNode.metaObject.id : targetNode.flowId;
                    }),
                    isExternal: node.isExternal,
                    isMissing: node.isMissing
                });
            });

        //output flows - any FLOW that current FLOW's META OBJECT has it as target
        var outputFlowObjects = _.find(this.graphModel, function(node) {
            return node.id === flowId;
        }).targets.map(function(node) {
            return new GraphNodeModel({
                id: node.id,
                flowId: node.flowId,
                metaObject: node.metaObject,
                targets: []
            });
        });
        flowObjects = flowObjects.concat(outputFlowObjects);

        //input flows - any FLOW that has META OBJECT having target in current FLOW
        var inputFlowObjects = this.graphModel.filter(function(node) {
            var targets = _.pluck(node.targets, "flowId");
            return node.flowId !== flowId && _.contains(targets, flowId);
        });

        var groupInputNodes = utils.group(inputFlowObjects, function(inputNode) {
            return inputNode.flowId;
        });
        _.each(groupInputNodes, function(flowNodeTargets, inputFlowId) {
            var targets = [];
            _.each(flowNodeTargets, function(targetNode) {
                targets = targets.concat(targetNode.targets);
            });
            var targetIds = _.chain(targets)
                .filter({
                    flowId: flowId
                })
                .pluck("id")
                .value();

            // cyclic graph? if we already have this one as outputFlowObject then yes
            var existingFlowNode = _.find(outputFlowObjects, {
                id: inputFlowId
            });

            var flowMetaObject = _.find(_this.graphModel, {
                id: inputFlowId
            });
            flowObjects.push(
                new GraphNodeModel({
                    id: existingFlowNode ? flowMetaObject.id + "_cyclic" : flowMetaObject.id, // FIXME, probably better solution available than playing with id field...
                    flowId: flowMetaObject.flowId,
                    metaObject: flowMetaObject.metaObject,
                    targets: targetIds
                })
            );
        });

        return flowObjects;
    }
});

export default FlowDatasource;
