import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import MaskingTransformation from "./maskingTransformation";
import FieldRemoverTransformation from "./fieldRemoverTransformation";
import FieldRenamerTransformation from "./fieldRenamerTransformation";
import FieldTypeModifierTransformation from "./fieldTypeModifierTransformation";
import FieldFilterTransformation from "./fieldFilterTransformation";
import FieldSplitterTransformation from "./fieldSplitterTransformation";
import FieldAdderTransformation from "./fieldAdderTransformation";
import JsonReaderTransformation from "./JsonReaderTransformation";
import TypedEventToWaDbEventTransformation from "./typedEventToWaDbEventTransformation";
import WaeMetaFilterTransformation from "./waeMetaFilterTransformation";
import WaeDbTableFilterTransformation from "./waeDbTableFilterTransformation";
import WaeOperatorFilterTransformation from "./waeOperatorFilterTransformation";
import WaeAddUserDataTransformation from "./waeAddUserDataTransformation";
import WaeToTypedEventTransformation from "./waeToTypedEventTransformation";
import FieldEnricherTransformation from "./fieldEnricherTransformation";
import WaeToDwStageTransformation from "./waeToDwStageTransformation";
import ShieldTransformation from "./shieldTransformation";
import WaeDataModifierTransformation from "./waeDataModifierTransformation";

var Provider = {};

Provider.getTransformationByName = function (componentName) {
    if (componentName === PredefinedComponentNames.TransformationMasking.name) {
        return MaskingTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationFieldRemover.name) {
        return FieldRemoverTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationFieldRenamer.name) {
        return FieldRenamerTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationFieldTypeModifier.name) {
        return FieldTypeModifierTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationFieldFilter.name) {
        return FieldFilterTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationFieldSplitter.name) {
        return FieldSplitterTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationFieldEnricher.name) {
        return FieldEnricherTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationJsonReader.name) {
        return JsonReaderTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationTypedEventToWaDbEvent.name) {
        return TypedEventToWaDbEventTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationFieldAdder.name) {
        return FieldAdderTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationWaeMetaFilter.name) {
        return WaeMetaFilterTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationWaeDbTableFilter.name) {
        return WaeDbTableFilterTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationWaeOperatorFilter.name) {
        return WaeOperatorFilterTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationWaeAddUserData.name) {
        return WaeAddUserDataTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationWaeToTypedEvent.name) {
        return WaeToTypedEventTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationWaeToDwStage.name) {
        return WaeToDwStageTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationShield.name) {
        return ShieldTransformation;
    } else if (componentName === PredefinedComponentNames.TransformationWaeDataModifier.name) {
        return WaeDataModifierTransformation;
    }

    return null;
};

export default Provider;
