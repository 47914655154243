import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { StriimTypography, StriimButton, StriimLink } from "@striim/striim-ui";
import { VaultModal } from "./";
import Dictionary from "app/components/common/helpable/online-help-dictionary";
import { Documentation } from "src/generic/icon/customIcons";
import { SvgIcon } from "@mui/material";
import AddIcon from "app/images/striimline/add-alerts.svg";

const styles = {
    headerContainer: { display: "flex", alignItems: "center" },
    vaultsEmptyWrapper: {
        gap: 5,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center"
    }
};

const VaultsEmpty = ({ onAdded }) => {
    const [isModalShown, setIsModalShown] = useState(false);
    return (
        <>
            <Box sx={styles.headerContainer} data-test-id="vaults-empty">
                <StriimTypography variant="h2">Vaults</StriimTypography>
                <Box sx={{ ...styles.headerContainer, ml: 1 }}>
                    <SvgIcon
                        component={Documentation}
                        sx={({ palette }) => ({
                            fill: "none",
                            "& > path": {
                                stroke: palette.secondary[500]
                            }
                        })}
                    />
                </Box>
            </Box>
            <Box sx={styles.vaultsEmptyWrapper}>
                <Box component="img" src="src/generic/icon/manage-striim/01-ManageStriim-Files-Empty.svg" />
                <StriimTypography variant="h1">Add your first vault</StriimTypography>
                <StriimTypography variant="body4" color="greyscale.700" sx={{ width: "25%" }}>
                    By storing keys in Striim Vault, HashiCorp Vault, Azure Key Vault and Google Secret Manager you can
                    use the keys connecting adapter without storing this information locally.{" "}
                </StriimTypography>
                <Box>
                    <StriimLink href={Dictionary.get()["MANAGE_STRIIM_VAULTS"].href} target="_blank">
                        View Documentation
                    </StriimLink>
                </Box>
                <Box>
                    <StriimButton
                        variant="primary"
                        onClick={() => setIsModalShown(true)}
                        style={{ marginTop: 20 }}
                        data-test-id="vaults-add-vault-button"
                        startIcon={<SvgIcon component={AddIcon} sx={{ fill: "none" }} />}
                    >
                        New Vault
                    </StriimButton>
                </Box>
            </Box>
            {isModalShown && <VaultModal setIsModalShown={setIsModalShown} onAdded={onAdded} />}
        </>
    );
};

export default VaultsEmpty;
