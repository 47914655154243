import App from "../app";
import securityService from "core/services/securityService/securityService";
import alertManagerRoutes from "src/modules/alert-manager/routes.json";
import AI_ROUTES from "src/modules/ai-insights/routes.json";

export default {
    AppErrors: function(appName, trigger) {
        App.navigate("/flow/" + appName + "/view/exceptions", {
            trigger: trigger
        });
    },
    AppSettings: function(appName) {
        App.navigate("/flow/" + appName + "/view/appSettings");
    },
    Progress: function(appName) {
        if (securityService.isSnowflakePartnerConnect()) {
            App.navigate("/flow/" + appName + "/view/snowflake_complete");
        } else {
            App.navigate("/flow/" + appName + "/view/showProgress");
        }
    },
    DeploymentSettings: function(appName) {
        App.navigate("/flow/" + appName + "/view/deploySettings");
    },
    Subflow: function(appName, flowName, trigger = false) {
        App.navigate("/flow/" + appName + "/" + flowName, {
            trigger
        });
    },
    DashboardEdit: function(dashboard) {
        const dashboardName = dashboard.nsName + "." + dashboard.name;
        const pageName = dashboard.pages[0].name;
        App.navigate("/dashboard/edit/" + dashboardName + "/" + pageName, {
            trigger: true
        });
    },
    getEditNodeUrl: function(appName, nodeName, force) {
        return "/flow/" + appName + "/edit/" + nodeName;
    },
    EditNode: function(appName, nodeName, force) {
        const url = this.getEditNodeUrl(appName, nodeName, force);
        App.navigate(url, {
            trigger: force
        });
    },
    getEditFlowNodeUrl: function(appName, flowName, nodeName, force) {
        return "/flow/" + appName + "/" + flowName + "/edit/" + nodeName;
    },
    EditFlowNode: function(appName, flowName, nodeName, force) {
        const url = this.getEditFlowNodeUrl(appName, flowName, nodeName, force);
        App.navigate(url, {
            trigger: force
        });
    },
    App: function(appName, trigger = false) {
        App.navigate("/flow/" + appName, {
            trigger
        });
    },
    AppRedirect: function(appName) {
        App.navigate("/flow/" + appName, {
            trigger: true
        });
    },
    Apps: function() {
        App.navigate("/applications", {
            trigger: true
        });
    },
    AppsSilently: function() {
        App.navigate("/applications", {
            replace: true
        });
    },
    Monitor: function(appName) {
        App.navigate("/monitor/" + appName, {
            trigger: true
        });
    },
    AppWizardTemplate: function({ appName, templateId, stepId, origin, componentName, stepName, force }) {
        var wizardStep = stepId || 0;
        const formattedStepName = stepName.toLowerCase();
        if (origin) {
            wizardStep += "?origin=" + encodeURIComponent(origin);
            if (componentName) {
                wizardStep += "&name=" + encodeURIComponent(componentName);
            }
        }
        App.navigate("/appwizard/" + appName + "/" + templateId + "/" + wizardStep + "/" + formattedStepName, {
            trigger: force
        });
    },
    AlertManager: function() {
        App.navigate(alertManagerRoutes.alertmanager, {
            trigger: true
        });
    },
    AlertManagerTab: function(tab, force = false) {
        App.navigate(`${alertManagerRoutes.alertmanager}/view/${tab}`, {
            trigger: force
        });
    },
    Alert(tab, category, id, force = false) {
        App.navigate(`${alertManagerRoutes.alertmanager}/view/${tab}/${category}/${id}`, {
            trigger: force
        });
    },
    AddNewAlert: function() {
        App.navigate(alertManagerRoutes.addAlert);
    },
    SMTPSetup: function() {
        App.navigate(alertManagerRoutes.setupSMTP, {
            trigger: true
        });
    },
    SlackSetup: function() {
        App.navigate(alertManagerRoutes.setupSlack, {
            trigger: true
        });
    },
    TeamsSetup: function() {
        App.navigate(alertManagerRoutes.setupTeams, {
            trigger: true
        });
    },
    GuardianViewJob: function(name) {
        App.navigate(`${AI_ROUTES.view}/${name}`, {
            trigger: true
        });
    },
    AppSherlock: function(appName, jobName, trigger = false) {
        App.navigate("/flow/" + appName + "/view/sherlock-ai" + (jobName ? `/${jobName}` : ""), {
            trigger
        });
    },
    AppSentinelLiveMonitor: function(appName, sentinelName, trigger = false) {
        App.navigate(`/flow/${appName}/view/sentinel-ai-activity${sentinelName ? `/${sentinelName}` : ""}`, {
            trigger
        });
    },
    AppSentinelView: function(appName, sentinelName, trigger = false) {
        App.navigate("/flow/" + appName + "/edit/" + sentinelName, {
            trigger
        });
    }
};
