import React from "react";
import { getDbType, getLoadDriverApi } from "./drivers-check-view";
import api from "core/api/api";
import { StriimTheme, StriimFileUploader } from "@striim/striim-ui-v2";

const FileUploadWrapper = ({ moduleData }) => {
    const onUpload = file => {
        return new Promise((resolve, reject) => {
            try {
                var data = new FormData();
                if (!file) {
                    reject("Please upload a local file");
                    return;
                }

                var fileName = file.name;
                data.append("file", file);
                var loc = window.location.origin + "/upload";

                $.ajax({
                    url: loc,
                    data: data,
                    processData: false,
                    type: "POST",
                    contentType: false,
                    headers: {
                        "X-fileName": fileName
                    },
                    success: function(data) {
                        const dbType = getDbType(moduleData);
                        const loadDriverApi = getLoadDriverApi(dbType);
                        if (loadDriverApi) {
                            loadDriverApi
                                .bind(api)(data, "")
                                .then(result => {
                                    resolve("File uploaded successfully");
                                })
                                .fail(result => {
                                    reject("Cannot load drivers");
                                    console.error("Cannot load drivers: " + result);
                                });
                        } else {
                            reject("Unknown database: " + moduleData);
                        }
                    },
                    error: function() {
                        reject("Unknown database: " + "Please upload a local file");
                    }
                });
            } catch (err) {
                reject("File upload failed");
            }
        });
    };

    return (
        <StriimTheme>
            <StriimFileUploader onUpload={onUpload} fileType={{ "application/java-archive": [".jar"] }} />
        </StriimTheme>
    );
};

export default FileUploadWrapper;
