import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react";
import { Box } from "@material-ui/core";

import useDashboardsStore from "src/stores/dashboards/useDashboardsStore";
import LoadingIndicator from "src/generic/loading-indicator";
import { useReactHeader } from "src/hooks/useReactHeader";
import useInitializedEffect from "src/hooks/useInitializedEffect";

import DashboardsNotAvailable from "./components/dashboards-not-available";
import NoDashboards from "./components/no-dashboards";
import NoSearchResults from "./components/no-search-results";
import DashboardListHeader from "./components/dashboards-list-header";
import DashboardsList from "./components/dashboards-list";
import AddDashboardModal from "./components/add-dashboard-dialog/add-dashboard-dialog";
import { StriimStickyProvider } from "@striim/striim-ui";
import securityService from "core/services/securityService/securityService";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import useStyles from "./components/dashboards-list/dashboard-list.style";

const DashboardListPage = () => {
    useReactHeader({ title: "Dashboards" });
    const store = useDashboardsStore();
    const dashboards = useObserver(() => store.dashboards);
    const filteredDashboards = useObserver(() => store.filteredDashboards);
    const isLoading = useObserver(() => store.dashboardsLoading);
    const [isAddDashboardDialogVisible, setIsAddDashboardDialogVisible] = useState(false);
    const [mode, setMode] = useState("grid");
    const [sortToggled, setSortToggled] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        store.resetFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useInitializedEffect(() => {
        store.dashboardsFetch();
    }, [store]);

    useEffect(
        () => () => {
            store.setFilters({ nameSpaceFilter: null, textFilter: null });
        },
        [store]
    );

    if (!securityService.isSegmentationFeatureEnabled(addonFeaturesKeys.DASHBOARDS)) {
        return <DashboardsNotAvailable />;
    }

    return (
        <>
            {isLoading ? (
                <Box minHeight={10}>
                    <LoadingIndicator />
                </Box>
            ) : !dashboards || dashboards?.length === 0 ? (
                <NoDashboards openAddDashboardDialog={setIsAddDashboardDialogVisible} />
            ) : (
                <div className={classes.wrapper}>
                    <StriimStickyProvider
                        top={48}
                        stickyElement={
                            <DashboardListHeader
                                openAddDashboardDialog={setIsAddDashboardDialogVisible}
                                mode={mode}
                                {...{ sortToggled, setSortToggled }}
                                onModeChange={() => setMode(mode === "grid" ? "list" : "grid")}
                            />
                        }
                    >
                        {filteredDashboards.length == 0 ? (
                            <NoSearchResults />
                        ) : (
                            <DashboardsList mode={mode} sortToggled={sortToggled} dashboards={filteredDashboards} />
                        )}
                    </StriimStickyProvider>
                </div>
            )}
            <AddDashboardModal
                isVisible={isAddDashboardDialogVisible}
                setIsAddDashboardDialogVisible={setIsAddDashboardDialogVisible}
            />
        </>
    );
};

export default DashboardListPage;
