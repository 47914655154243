import ignoredPatterns from "app/components/common/ignoredPatterns.json";

export default function (item) {
    const name =  item.name + item.fqName + item.id  ;
    return (
        name &&
        !ignoredPatterns.some((pattern) => {
            return name.toLowerCase().indexOf(pattern) !== -1;
        })
    );
}
