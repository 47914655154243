import _ from "underscore";
import BaseControl from "./../base-control";
import React from "react";
import ReactDOM from "react-dom/client";

import InfoboxFormControl from "../../../../../../src/generic/infobox-form-control/infobox-form-control";
import { StriimTheme } from "@striim/striim-ui";

/*class InfoBoxStore {
    @observable hide = true;
    @observable properties = null;

    constructor() {}

    @action setHide(value) {
        this.hide = value;
    }

    @action setProperties(value) {
        this.properties = value;
    }
}*/

var Model = BaseControl.Model.extend({
    defaults: {
        value: null
    }
});

var View = BaseControl.View.extend({
    template: _.template(`<div>   </div>`),

    getViewValue: function() {
        return this.model.get("value"); // No op, we're doing it in react.
    },

    setViewValue: function() {
        // No op, we're doing it in react.
    },

    valueChanged: function(value) {
        this.setValue(value);
        this.forceChanged();
    },

    setHide(hide) {
        if (hide) {
            this.$el.slideUp("fast");
        } else {
            this.$el.slideDown("fast");
        }
    },

    onShow: function() {
        try {
            if (this.hide) {
                this.$el.hide();
            }
            const root = ReactDOM.createRoot(this.$el[0]);
            root.render(
                <StriimTheme>
                    <InfoboxFormControl
                        title={this.title}
                        message={this.message}
                        actionTitle={this.actionTitle}
                        actionClickHandler={this.actionClickHandler}
                        noCustomIcon={this.noCustomIcon}
                    />
                </StriimTheme>
            );
        } catch (e) {
            console.error("Error rendering Info Box", e);
        }
    }
});

function buildTextField() {
    return function (props) {
        return _.extend({}, BaseControl, {
            View: View.extend(props),
            Model: Model
        });
    };
}

export default buildTextField();
