import api from "core/api/api";
import Backbone from "backbone";
import NestedTypes from "backbone.nestedtypes";
import $ from "jquery";
import _ from "underscore";
import template from "./templates/statsreporting.html";
import reportFileTemplate from "text-loader!./templates/reportFileTemplate.txt";
import fileSaver from "file-saver";
import dateLib from "core/utils/date-utils";
import growl from "../../../common/growl";

var StatsReporting = {};

StatsReporting.Model = NestedTypes.Model.extend({
    defaults: {
        IsRunning: Boolean,
        AppId: null,
        ResultsAvailable: false,
        NoData: false,

        TotalEvents: null,
        FirstEventTime: null,
        FirstEvent: null,
        AverageThroughput: null,
        TotalWactionsCreated: null,
        LastWactionTime: null,
        LastWaction: null,
        LastCheckpointTime: null,
        TotalEventsOutput: null,
        LastEventTime: null,
        LastEvent: null,
        LastEventOutput: null
    }
});

StatsReporting.View = Backbone.Marionette.LayoutView.extend({
    className: "statsReporting",
    template: _.template(template),
    templateHelpers: {
        dateLib: dateLib
    },

    modelEvents: {
        change: "render"
    },

    events: {
        "click .startStopButton": "startStop",
        "click .downloadButton": "download"
    },

    initialize: function() {
        api.isReportRunning(this.model.AppId).then(
            function(isRunning) {
                this.model.IsRunning = isRunning;
            }.bind(this)
        );
    },

    startStop: function() {
        this.model.IsRunning = !this.model.IsRunning;
        if (this.model.IsRunning) {
            api.startReport(this.model.AppId);
        } else {
            api.stopReport(this.model.AppId)
                .then(
                    function(result) {
                        this.model.set(
                            {
                                TotalEvents: null,
                                FirstEventTime: null,
                                FirstEvent: null,
                                AverageThroughput: null,
                                TotalWactionsCreated: null,
                                LastWactionTime: null,
                                LastWaction: null,
                                LastCheckpointTime: null,
                                TotalEventsOutput: null,
                                LastEventTime: null,
                                LastEvent: null,
                                LastEventOutput: null
                            },
                            {
                                silent: true
                            }
                        );
                        this.model.set(
                            {
                                NoData: $.isEmptyObject(result)
                            },
                            {
                                silent: true
                            }
                        );
                        this.model.set(result, {
                            silent: true
                        });
                        this.model.ResultsAvailable = true;
                    }.bind(this)
                )
                .fail(
                    function(e) {
                        growl.error(e.message || e, "Error generating report");
                        this.model.ResultsAvailable = false;
                    }.bind(this)
                );
        }
    },
    download: function() {
        var content = this.getReportText();
        this.downloadFile("Interval Report for " + this.model.get("AppId") + ".txt", content);
    },
    getReportText: function() {
        var fileTemplate = _.template(reportFileTemplate);
        var reportText = fileTemplate(this.model);
        return reportText;
    },
    downloadFile: function(filename, text) {
        var blob = new Blob([text], { type: "text/plain;charset=utf-8" });
        fileSaver.saveAs(blob, filename);
    }
});

export default StatsReporting;
