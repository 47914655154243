import React, { useMemo } from "react";
import ProgressDetails from "../../automated-app-group/components/progress-details/progress-details";
import automatedGroupStore from "../automated-app-group-details.store";
import { Grid } from "@mui/material";
import statusManagement from "../../../../../../../status-management";

const AutomatedAppGroupProgressInfo: React.FunctionComponent<String[]> = ({ appIds }) => {
    let currentStage = automatedGroupStore.statusInfo?.stageInfo;
    const MemoizedAppProgressDetails = useMemo(() => {
        const currentFocusedAppStatus = automatedGroupStore.currentFocusedAppStatus;
        return (
            <ProgressDetails
                status={currentFocusedAppStatus}
                stage={automatedGroupStore.currentFocusedApp}
                sortedGroups={appIds}
            />
        );
    }, [automatedGroupStore.currentFocusedAppStatus, automatedGroupStore.currentFocusedApp, appIds]);
    if (
        [
            "CREATED",
            "DEPLOYED",
            "SCHEMA_CREATION_STOPPED",
            "SCHEMA_CREATION_TERMINATED",
            "SCHEMA_CREATION_RUNNING",
            "RESET"
        ].includes(currentStage) ||
        statusManagement.isTransientStatus(currentStage) ||
        automatedGroupStore.isAppTransitioning
    ) {
        return true;
    }
    return <Grid item>{MemoizedAppProgressDetails}</Grid>;
};

export default AutomatedAppGroupProgressInfo;
