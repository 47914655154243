export const classes = {
    borderBottom: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    borderTop: {
        borderTop: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    icon: {
        width: 24,
        height: 24,
        "border-radius": 4,
        background: "#C7784C",
        fill: "none"
    },
    newTabIcon: {
        fill: "secondary.500",
        width: "18px",
        height: "18px"
    },
    learnLink: {
        px: 0.5,
        height: "18px",
        ".MuiButton-endIcon": { ml: 0.5 }
    },
    expanded: {
        transform: "rotate(180deg)"
    },
    iconButton: {
        minWidth: "unset",
        px: 1,
        py: 0.5,
        "& .MuiButton-startIcon": {
            mx: 0
        }
    },
    startIcon: {
        fontSize: 20,
        fill: "none"
    },
    header: {
        display: "flex",
        alignItems: "center",
        padding: 2,
        "&:hover": {
            cursor: "pointer"
        },
        "& svg": {
            color: "greyscale.700"
        }
    },
    entitiesIcon: {
        "& svg": {
            color: "greyscale.900"
        }
    },
    customIcon: {
        "& svg": {
            fill: "transparent"
        }
    },
    panelHeader: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    mousePointer: {
        "&:hover": {
            cursor: "pointer"
        }
    },
    errorBox: {
        display: "flex",
        justifyContent: "center",
        marginBottom: theme => theme.spacing(3),
        "& .MuiCard-root": {
            backgroundColor: theme => theme.palette.critical[50],
            border: theme => `1px solid ${theme.palette.critical[100]}`,
            boxShadow: "unset",
            width: "100%",
            "& .MuiTypography-root": {
                color: theme => theme.palette.greyscale[900]
            }
        },
        "& .MuiCardHeader-avatar": {
            "& svg": {
                fill: theme => theme.palette.critical[500],
                color: theme => theme.palette.critical[500]
            }
        }
    },
    footer: {
        position: "absolute",
        zIndex: 1,
        width: "100%",
        backgroundColor: "#fff",
        bottom: 0,
        right: 0
    }
};
