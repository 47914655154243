const sxStyles = {
    container: {
        position: "relative",
        margin: theme => theme.spacing(1, 0)
    },
    relativeContainer: {
        position: "relative"
    },
    textContainer: {
        position: "absolute",
        zIndex: 1000,
        height: theme => theme.spacing(4.5),
        alignItems: "center",
        marginLeft: theme => theme.spacing(1.5)
    },
    inlineText: {
        color: theme => theme.palette.greyscale[800],
        marginRight: theme => theme.spacing(1),
        display: "flex",
        gap: theme => theme.spacing(0.75)
    },
    alignRight: {
        justifyContent: "flex-end",
        marginLeft: 0
    },
    timeElapsed: {
        color: theme => theme.palette.greyscale[900]
    },
    errorsCount: {
        color: theme => theme.palette.critical[500]
    }
};
export default sxStyles;
