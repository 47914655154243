import React from "react";
import { Grid, SvgIcon } from "@mui/material";
import numeral from "numeral";

import { SeverityWarning, SquareArrowDown, SquareArrowUp } from "../../../generic/icon/customIcons";
import {
    ActiveSentinelsEventsProcessed,
    AllSentinelsEventsProcessed,
    AppSDIDrillDownFindings,
    FlowDesignerPreview,
    SensitiveDataOccurrences,
    SentinelSDIDrillDownFindings,
    SentinelsEventsProcessed,
    SentinelsSDIDrillDownEvents,
    TimeFormat
} from "../guardian/sentinel-monitor/service/sentinel-monitor.types";
import { SDIChartData } from "./components/sdi-chart/sdi-chart";
import dateLib from "core/utils/date-utils";
import InfoIcon from "app/images/striimline/info-icon.svg";
import WithTooltip from "../../../generic/tooltip/tooltip";
import { ALL_SENTINELS_LABEL, SelectedSensitiveDataIdentifier, SentinelMenuItem } from "./sentinel-live-monitor";
import metaStoreService from "../../../../core/services/metaStoreService/meta-store-service";
import { getPercentage } from "../reports/components/report-tables/report-tables.helper";

const styles = {
    icon: {
        width: 16,
        height: 16
    },
    iconInfo: {
        height: "20px",
        width: "20px",
        fill: "transparent",
        "& path": {
            stroke: theme => theme.palette.greyscale[900]
        },
        marginTop: "7px"
    }
};

const IMPORTANCE_LEVEL = {
    HIGH: "HIGH",
    MEDIUM: "MEDIUM",
    LOW: "LOW"
} as const;

export const ImportanceLevelIcons = {
    [IMPORTANCE_LEVEL.HIGH]: <SvgIcon component={SquareArrowUp} sx={styles.icon} />,
    [IMPORTANCE_LEVEL.MEDIUM]: <SvgIcon component={SeverityWarning} sx={styles.icon} />,
    [IMPORTANCE_LEVEL.LOW]: <SvgIcon component={SquareArrowDown} sx={styles.icon} />
};

export const ImportanceLevelIconPaths = {
    [IMPORTANCE_LEVEL.HIGH]: "src/generic/icon/customIcons/SDI-Importance.svg",
    [IMPORTANCE_LEVEL.MEDIUM]: "src/generic/icon/customIcons/Severity-Events.svg",
    [IMPORTANCE_LEVEL.LOW]: "src/generic/icon/customIcons/square-arrow-down.svg"
};

type Keys = keyof typeof IMPORTANCE_LEVEL;
export type ImportanceLevels = typeof IMPORTANCE_LEVEL[Keys];

export const getSDIChartDataParsed = (data: FlowDesignerPreview): SDIChartData[] => {
    const properties = data.sensitiveDataIdentifierOccurrences;
    const identifiers = Object.keys(properties);
    return identifiers
        .map(item => {
            const sdi = properties[item];
            return {
                dataIdentifier: sdi.label,
                importanceLevel: sdi.importance,
                occurrences: sdi.total,
                actions: {
                    encrypted: sdi.ENCRYPT,
                    masked: sdi.MASK,
                    noAction: sdi.NO_ACTION
                }
            };
        })
        .sort((x, y) => y.occurrences - x.occurrences);
};

export function pollingFn<T extends (...args: any[]) => any, A extends Parameters<T>>(
    funcName: T,
    intervalRef,
    interval,
    ...args: A
): void {
    try {
        if (intervalRef?.current) {
            clearInterval(intervalRef.current);
        }
        funcName(...args);
        intervalRef.current = setInterval(async () => {
            try {
                funcName(...args);
            } catch (error) {
                clearInterval(intervalRef.current);
            }
        }, interval);
    } catch (error) {
        console.error(error);
    }
}

export const getTabsDataParsed = (
    eventsProcessed:
        | AllSentinelsEventsProcessed
        | ActiveSentinelsEventsProcessed
        | SentinelsEventsProcessed
        | SentinelsSDIDrillDownEvents
        | null,
    sensitiveDataOccurrences: SensitiveDataOccurrences | AppSDIDrillDownFindings | SentinelSDIDrillDownFindings,
    currentTabIndex: number,
    selectedSDI: SelectedSensitiveDataIdentifier | null,
    selectedSentinel: SentinelMenuItem | null
) => {
    const data =
        currentTabIndex === 0
            ? eventsProcessed && Object.keys(eventsProcessed).length
                ? eventsProcessed
                : sensitiveDataOccurrences
            : sensitiveDataOccurrences && Object.keys(sensitiveDataOccurrences).length
            ? sensitiveDataOccurrences
            : eventsProcessed;
    const isAllSentinelSelected = !selectedSentinel || selectedSentinel?.value === "All";
    return [
        {
            label: "Events Processed",
            sdiLabel: "Events W/",
            subLabel: "",
            amount:
                selectedSDI && isAllSentinelSelected && data
                    ? "eventsProcessedWithSDI" in data
                        ? data.eventsProcessedWithSDI
                        : 0
                    : data?.eventsProcessed,
            sdiPercentage:
                selectedSDI && isAllSentinelSelected && data
                    ? getPercentage(data?.eventsProcessedWithSDI, data?.eventsProcessed)
                    : getPercentage(
                          data?.eventsProcessed,
                          data?.eventsWithSensitiveData + data?.eventsWithoutSensitiveData
                      ),
            data: [
                ...(selectedSDI
                    ? [
                          {
                              field: "sensitiveDataWithSDI",
                              value: data?.eventsWithoutCurrSDI,
                              valuePercentage: getPercentage(data?.eventsWithoutCurrSDI, data?.eventsProcessed) + "%"
                          }
                      ]
                    : [{ field: "sensitiveData", value: data?.eventsWithSensitiveData }]),
                {
                    field: "nonSensitiveData",
                    value: data?.eventsWithoutSensitiveData
                }
            ]
        },
        {
            label: (
                <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"} gap={1}>
                    Occurrences of Sensitive Data
                    {WithTooltip(
                        <SvgIcon component={InfoIcon} sx={styles.iconInfo} />,
                        <>
                            Count of sensitive data detected across all fields of all events scanned by Sentinel. An
                            event may contain multiple occurrences of sensitive data. Therefore, the occurrences of
                            sensitive data detected may exceed the number of events that contain sensitive data.
                        </>
                    )}
                </Grid>
            ),
            sdiLabel: "Occurrences of",
            subLabel: `${data?.sensitiveIdentifiers || 0} IDENTIFIERS`,
            amount: data?.occurrencesOfSensitiveData,
            data: [
                {
                    field: "encrypted",
                    value: data?.sensitiveDataActions?.ENCRYPTED
                },
                {
                    field: "masked",
                    value: data?.sensitiveDataActions?.MASKED
                },
                {
                    field: "noAction",
                    value: data?.sensitiveDataActions?.NO_ACTION
                }
            ]
        }
    ];
};
const oneHourInMilliseconds = 3600 * 1000;
export const getTimestampsAsOneHourWindow = (fromTime, toTime) => {
    let from,
        to = toTime;
    if (!fromTime || !toTime) return null;
    const difference = Math.abs(toTime - fromTime);
    if (difference > oneHourInMilliseconds) {
        from = toTime - oneHourInMilliseconds;
    } else {
        from = fromTime;
    }
    return { from, to };
};

export const getEntityTypeAndName = (selectedSentinel, appName) => {
    const entityType =
        !selectedSentinel || selectedSentinel.value === ALL_SENTINELS_LABEL
            ? metaStoreService.entities.APPLICATION
            : metaStoreService.entities.SENTINEL;
    const entityName =
        entityType === metaStoreService.entities.APPLICATION ? appName : selectedSentinel?.value?.split(".")?.pop();
    return { entityType, entityName };
};

export const getStartAndEndTimestamps = (isOneHourTimeframe: boolean, selectedDateRange: SelectedDateRange | null) => {
    const currentTime = dateLib();
    if (!!selectedDateRange) {
        return { fromTime: selectedDateRange.from, toTime: selectedDateRange.to };
    } else if (isOneHourTimeframe) {
        const toTime = currentTime.valueOf();
        const fromTime = currentTime.subtract(1, "hour").valueOf();
        return { fromTime, toTime };
    }
    const toTime = currentTime.valueOf();
    const fromTime = currentTime.subtract(24, "hour").valueOf();
    return { fromTime, toTime };
};

export const getStartAndEndTimestampsForChart = () => {
    const currentTime = dateLib();
    const toTime = currentTime.valueOf();
    const fromTime = currentTime.subtract(24, "hour").valueOf();
    return { fromTime, toTime };
};

export const getTimeFormat = (isOneHourTimeFrame: boolean): TimeFormat => {
    return isOneHourTimeFrame ? "minute" : "hour";
};
//Format number in format 1235 to 1.23K
export const formatNumber = (value: number): string =>
    numeral(value)
        .format("0.00a")
        .toUpperCase();
