import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing }) => {
    return {
        wrapper: {
            marginBottom: spacing(2)
        },
        templateGroupTitle: {
            paddingBottom: spacing(2),
            "&:not(:empty)": {
                paddingTop: spacing(2),
                borderTop: "1px solid #D8E4F0"
            }
        },
        templatesWrapper: {
            gap: spacing(2)
        },
        expandableContainer: {},
        itemGridWrapper: {
            minWidth: spacing(32.5),
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(296px, 1fr))",
            gridAutoFlow: "dense",
            gridGap: spacing(2),
            width: "100%",
            cursor: "pointer",
            flex: "1 0 20%"
        },
        groupIcon: {
            marginRight: spacing(1),
            height: spacing(2.75),
            width: "auto",
            color: "#346CC2"
        },
        genericIcon: {
            fill: "none"
        }
    };
});
