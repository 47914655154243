import tooltipyo from "app/components/common/tooltipyo/tooltipyo";
import Backbone from "backbone";
import _ from "underscore";
import utils from "core/utils";
import NestedTypes from "backbone.nestedtypes";
import filterModel from "core/filterModel";
import queryParameters from "core/services/queryService/queryParameters.json";
import ReportType from "../../monitor/overview/reportType/reportType";
import UIControl from "app/components/common/editor/control/ui-control";
import _template from "./filtersBar.html";
import "lib/vendor/jquery.clearsearch";

var FiltersBar = {};

FiltersBar.filtersBarHeight = 60;

FiltersBar.Model = NestedTypes.Model.extend({
    defaults: {
        title: "Global Filters",
        searchQuery: "",
        timeFilterEnabled: true,
        fullTextSearchFilterEnabled: true,
        report: {
            type: ReportType.Options.RealTime,
            selectedDate: null,
            selectedTime: null,
            startTime: null,
            endTime: null,
            value: null,
            title: ""
        }
    }
});

FiltersBar.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(_template),
    regions: {
        searchBoxRegion: ".search-box"
    },
    ui: {
        timeRange: ".timeRange",
        searchBox: ".search-box",
        rangeSelection: ".range-selection",
        filteringDisabled: ".filtering-disabled"
    },
    events: {
        "click @ui.rangeSelection": "_rangeSelected"
    },
    modelEvents: {
        "change:timeFilterEnabled": "_enable",
        "change:fullTextSearchFilterEnabled": "_enable"
    },
    initialize: function() {
        this.getInitialParameters();
        this.text = UIControl.ChangeableTextField.Search().create();
        this.text.setValue(this.initialParameters[queryParameters.Search]);
        this.listenTo(
            this.text.model,
            "change:value",
            function() {
                var obj = {};
                obj[queryParameters.Search] = this.text.model.value;
                filterModel.setParameters(obj);
            }.bind(this)
        );

        this.listenTo(
            this.model,
            "change:report",
            function() {
                this.ui.timeRange.html(this.model.report.value);
                var obj = {};
                var isTimeFilterApplied = this.model.report.startTime && this.model.report.endTime;
                obj[queryParameters.Time] = isTimeFilterApplied
                    ? {
                          from: this.model.report.startTime.valueOf().toString(),
                          to: this.model.report.endTime.valueOf().toString()
                      }
                    : null;
                filterModel.setParameters(obj);
            }.bind(this)
        );
    },
    onRender: function() {
        var initTimeParam = this.initialParameters[queryParameters.Time];
        if (initTimeParam) {
            this.ui.timeRange.html(ReportType.DateRangeFormat(initTimeParam.from, initTimeParam.to));
            this.model.report.startTime = ReportType.EnsureDateLibObject(initTimeParam.from);
            this.model.report.endTime = ReportType.EnsureDateLibObject(initTimeParam.to);
            this.model.report.type = ReportType.Options.CustomRange;
        }

        this.searchBoxRegion.show(this.text);
    },
    getInitialParameters: function() {
        this.initialParameters = filterModel.getParameters();
    },
    _rangeSelected: function() {
        this.rangeSelectorTooltipContent = new ReportType.View({
            model: this.model,
            pickers: [ReportType.Options.RealTime, ReportType.Options.CustomRange]
        });

        this.rangeSelectorTooltipContent.render();
        tooltipyo.show(this.rangeSelectorTooltipContent.$el, this.ui.rangeSelection, "bottom", {
            customClass: "monitor dashboard time-range",
            hideCloseButton: true,
            onBeforeClose: utils.isDatePickerOpened
        });

        this.listenTo(this.rangeSelectorTooltipContent, "timeRange:close", function() {
            tooltipyo.hide();
        });
    },
    _enable: function() {
        var timeFilterEnabled = this.model.timeFilterEnabled;
        var fullTextSearchFilterEnabled = this.model.fullTextSearchFilterEnabled;
        if (this.isDestroyed) {
            return;
        }

        this.text.setEnabled(fullTextSearchFilterEnabled);
        this.ui.rangeSelection.toggle(timeFilterEnabled);
        const enabled = timeFilterEnabled || fullTextSearchFilterEnabled;
        this.ui.filteringDisabled.toggle(!timeFilterEnabled);

        this.$el.toggleClass("disabled", !enabled);
        if (!enabled) {
            this.$el.prop("title", "None of the charts on this page can be filtered");
            this.$el.tooltipster({
                position: "bottom",
                offsetY: -10,
                animation: "grow"
            });
        }
    }
});

export default FiltersBar;
