import React, { useState, useRef, useEffect } from "react";
import {
    StriimButton,
    StriimCheckbox,
    StriimControlLabel,
    StriimModal,
    StriimToggleButton,
    StriimTypography,
    StriimDataTable
} from "@striim/striim-ui";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";

import AppListService from "src/modules/apps/pages/app-list/app-list-service";
import { GreenCheck, RowDrag } from "src/generic/icon/customIcons";
import constants from "./columns.json";

import "./edit-columns-modal.styles.css";
import { Box } from "@mui/material";

const useStyles = makeStyles(() => ({
    checkIcon: {
        display: "flex",
        alignSelf: "center",
        height: 20,
        width: 20
    },
    dragIcon: {
        display: "flex",
        alignSelf: "center",
        height: 20,
        width: 20
    },
    menuIcon: {
        margin: "auto",
        height: 20,
        width: 20
    },
    rowDrag: {
        display: "flex",
        alignSelf: "center",
        cursor: "move"
    },
    gridCentering: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}));

const EditColumnsDialog = ({ isVisible, setIsEditColumnsDialogVisible, setEditColumnsModalSubmitted }) => {
    const classes = useStyles();
    const columns = AppListService.loadColumnState();
    const initialColumnState = constants.initial_column_state;
    const [pinnedRows, setPinnedRows] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [checkboxState, setCheckboxState] = useState(false);
    const ref = useRef();
    const gridRef = useRef();
    const gridColumnApiRef = useRef();

    const columnDefs = [
        {
            colId: "colId",
            field: "colId",
            headerName: "Column Name",
            suppressMovable: true,
            flex: 1,
            headerClass: "no-hover",
            cellRenderer: "colNameRenderer",
            minWidth: 150
        },
        {
            colId: "hide",
            field: "hide",
            headerName: "Visible",
            cellRenderer: "visibleRenderer",
            suppressMovable: true,
            headerClass: "header-center no-hover",
            cellClass: "cell-center",
            width: 100
        },
        {
            colId: "displayOrder",
            field: "displayOrder",
            headerName: "Order",
            suppressMovable: true,
            cellRenderer: "displayOrderRenderer",
            width: 100,
            headerClass: "header-center no-hover",
            cellClass: "cell-center"
        }
    ];

    const Visible = ({ data }) => {
        const [switchState, setSwitchState] = useState(!data.hide);

        const handleSwitchChange = event => {
            setSwitchState(event.target.checked);
            setCheckboxState(false);
            data.hide = !event.target.checked;
        };
        if (data.colId !== constants.app_name_col_id && data.colId !== constants.flow_status_col_id) {
            return (
                <StriimToggleButton
                    data-test-id={"columnstoggle--" + data.colId}
                    checked={switchState}
                    onChange={handleSwitchChange}
                />
            );
        } else {
            return <GreenCheck className={classes.checkIcon} />;
        }
    };

    const DisplayOrder = props => {
        const myRef = useRef(null);

        useEffect(() => {
            if (myRef.current) {
                props.registerRowDragger(myRef.current);
            }
        }, [myRef]);

        if (props.data.colId !== constants.app_name_col_id && props.data.colId !== constants.flow_status_col_id) {
            return (
                <div className={classes.rowDrag} ref={myRef}>
                    <RowDrag className={classes.dragIcon} />
                </div>
            );
        } else {
            return null;
        }
    };

    const ColName = ({ data }) => {
        if (data.colId == constants.menu_col_id) {
            return <MoreHoriz className={classes.menuIcon} />;
        } else {
            return <span>{data.colId}</span>;
        }
    };

    const components = {
        visibleRenderer: Visible,
        displayOrderRenderer: DisplayOrder,
        colNameRenderer: ColName
    };

    const getRowNodeId = data => {
        return data.colId;
    };

    const onGridReady = params => {
        setCheckboxState(false);
        gridRef.current = params.api;
        gridColumnApiRef.current = params.columnApi;
        if (columns?.length > 1) {
            // Pin the first two rows to the top
            const topRows = columns?.slice(0, 2);
            gridRef.current.setPinnedTopRowData(topRows);
            setPinnedRows(topRows);
            // Set remaining rows as normal rows
            const remainingRows = columns.slice(2);
            setRowData(remainingRows);
        }
    };

    const onSave = () => {
        const newOrder = [];
        gridRef.current.forEachNode(rowNode => {
            newOrder[rowNode.rowIndex] = rowNode.data;
        });
        let updatedColumnDefs = [];
        const fixedColumns = columns.slice(0, 2);
        updatedColumnDefs = [...fixedColumns, ...newOrder];

        if (checkboxState) {
            AppListService.saveColumnState(initialColumnState);
        } else {
            AppListService.saveColumnState(updatedColumnDefs);
        }
        setIsEditColumnsDialogVisible(false);
        setEditColumnsModalSubmitted(prev => !prev);
    };

    return (
        <StriimModal
            onCancel={setIsEditColumnsDialogVisible}
            isVisible={isVisible}
            onConfirm={onSave}
            autoHeight
            size="medium"
            footerContent={
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <StriimControlLabel
                            label={
                                <StriimTypography color="greyscale.700" variant="body4">
                                    Restore all default settings
                                </StriimTypography>
                            }
                            control={
                                <StriimCheckbox
                                    data-test-id="restore-column-defaults"
                                    checked={checkboxState}
                                    onClick={() => {
                                        if (!checkboxState) {
                                            gridRef.current.setRowData(initialColumnState.slice(2));
                                        } else {
                                            gridRef.current.setRowData(columns.slice(2));
                                        }
                                        setCheckboxState(!checkboxState);
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item className={classes.gridCentering}>
                        <StriimButton
                            onClick={() => {
                                setIsEditColumnsDialogVisible();
                            }}
                            variant="text"
                        >
                            Cancel
                        </StriimButton>
                        <StriimButton
                            data-test-id="edit-columns-confirm-button"
                            onClick={() => {
                                onSave();
                            }}
                            variant="primary"
                        >
                            Confirm
                        </StriimButton>
                    </Grid>
                </Grid>
            }
            titleContent={
                <StriimTypography variant="h2" data-test-id="add-dashboard-button">
                    Edit Table Columns
                </StriimTypography>
            }
        >
            <Box ref={ref} sx={{ my: 3 }}>
                <StriimDataTable
                    ref={gridRef}
                    onGridReady={onGridReady}
                    rowDragManaged={true}
                    getRowNodeId={getRowNodeId}
                    components={components}
                    suppressRowClickSelection
                    suppressCellFocus
                    suppressRowTransform
                    sizeColumnsToFit
                    animateRows="true"
                    data={rowData}
                    columns={columnDefs}
                    onRowDragEnd={() => setCheckboxState(false)}
                    topPinnedRows={pinnedRows}
                />
            </Box>
        </StriimModal>
    );
};

EditColumnsDialog.propTypes = {
    isVisible: PropTypes.bool,
    setIsEditColumnsDialogVisible: PropTypes.func
};

export default EditColumnsDialog;
