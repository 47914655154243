import React, { useEffect } from "react";
import { useUserManagement } from "core/user-management/user-management-context";
import LoadingIndicator from "src/generic/loading-indicator";
import SsoContainer from "./sso/sso-container";

const SingleSignOnPage = ({ ssoConfigured, ssoProperties, setSsoProperties }) => {
    const {
        userManagementProviderBusy,
        getRoles,
    } = useUserManagement();

    useEffect(() => {
        getRoles();
    }, []);

    return userManagementProviderBusy ? (
        <LoadingIndicator isGlobal={false} />
    ) : (
        <SsoContainer
            ssoConfigured={ssoConfigured}
            ssoProperties={ssoProperties}
            setSsoProperties={setSsoProperties}
        />
    );
};

export default SingleSignOnPage;
