import _ from "underscore";

export default (function () {
    var FilterValue = (function () {
        function FilterValue(name, sourceName) {
            this.name = name;
            this.sourceName = sourceName;
        }

        return FilterValue;
    })();

    function MetricTypes() {}

    MetricTypes.AppRate = new FilterValue("App rate", "source-rate");
    MetricTypes.AppInput = new FilterValue("Events Processed", "source-input");
    MetricTypes.Cpu = new FilterValue("App CPU %", "cpu-per-node");
    MetricTypes.ServerMemory = new FilterValue("Server memory", "memory-free");
    MetricTypes.ServerRate = new FilterValue("Server CPU %", "cpu-rate");
    MetricTypes.List = [
        MetricTypes.Cpu,
        MetricTypes.AppRate,
        MetricTypes.AppInput,
        MetricTypes.ServerRate,
        MetricTypes.ServerMemory,
    ];

    /**
     * @return {boolean}
     */
    MetricTypes.IsServerRelated = function (value) {
        return value === MetricTypes.ServerMemory.name || value === MetricTypes.ServerRate.name;
    };

    MetricTypes.GetByName = function (name) {
        return _.find(MetricTypes.List, function (item) {
            return item.name === name;
        });
    };

    MetricTypes.IsServerMemory = function (value) {
        return value === MetricTypes.ServerMemory.name;
    };

    MetricTypes.IsRateRelated = function (value) {
        return value === MetricTypes.ServerRate.name || value === MetricTypes.Cpu.name;
    };

    MetricTypes.IsEventRate = function (value) {
        return value === MetricTypes.AppRate.name;
    };

    MetricTypes.IsEventsProcessed = function (value) {
        return value === MetricTypes.AppInput.name;
    };

    return MetricTypes;
})();
