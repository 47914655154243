import React, { useRef, useEffect, useState, useCallback } from "react";
import { StriimInputField, StriimForm, StriimTheme } from "@striim/striim-ui";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import VaultsService from "./vaults-service";
import { mapOptions } from "../utils";
import { VaultTypes, httpMatch } from "./constants";

const sxStyles = {
    container: {
        marginBottom: 2
    },
    grid: {
        columnGap: 3,
        justifyContent: "space-between"
    }
};

const AzureVault = ({ setFormValues, setIsValid, dataForEdit }) => {
    const [options, setOptions] = useState([]);
    const [clientSecretInput, setClientSecretInput] = useState(false);

    const values = dataForEdit || {
        name: "",
        nsName: "",
        properties: {
            VaultType: VaultTypes.AZURE_VAULT,
            ConnectionURL: "",
            ClientID: "",
            ClientSecret: "",
            TenantID: ""
        }
    };

    useEffect(() => {
        async function fetchData() {
            const theData = await VaultsService.getNamespaces();
            setOptions(theData?.models);
        }

        if (!options.length) {
            fetchData();
        }
    }, [options]);

    useEffect(() => {
        if (clientSecretInput) {
            clientSecretRef.current.focus();
        }
    }, [clientSecretInput]);

    // TODO: optimization: find out why this is getting called multiple times per render
    const getOptions = useCallback(options => mapOptions(options), [options]);
    const formRef = useRef();
    const clientSecretRef = useRef(null);

    const handleClientSecretField = event => {
        const value = event.target.value;
        if (dataForEdit && value.length <= 0) {
            clientSecretRef.current.blur();
            setClientSecretInput(false);
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        nsName: Yup.string().required("Required"),
        properties: Yup.object({
            ConnectionURL: Yup.string()
                .required("Required")
                .matches(httpMatch, "Please enter a valid URL"),
            ClientID: Yup.string().required("Required"),
            ClientSecret: dataForEdit ? null : Yup.string().required("Required"),
            TenantID: Yup.string().required("Required")
        })
    });

    return (
        <StriimTheme>
            <StriimForm values={values} validationSchema={validationSchema} formRef={formRef} validateOnMount={true}>
                {({ values, isValid }) => {
                    setFormValues(values);
                    setIsValid(isValid);
                    return (
                        <>
                            <StriimInputField
                                required
                                name="name"
                                label="Name"
                                placeholder="Enter Vault name"
                                isFormElement
                                sx={sxStyles.container}
                                disabled={dataForEdit ? true : false}
                            />
                            <StriimInputField
                                required
                                name="nsName"
                                label="Namespace"
                                select
                                id="data-test-id-namespace-select"
                                placeholder="Namespace"
                                SelectProps={{ options: getOptions(options), menuPosition: "fixed" }}
                                isFormElement
                                sx={sxStyles.container}
                                disabled={dataForEdit ? true : false}
                            />
                            <Grid container sx={{ ...sxStyles.container, ...sxStyles.grid }}>
                                <Grid item xs={7}>
                                    <StriimInputField
                                        required
                                        name="properties.ConnectionURL"
                                        label="Connection URL"
                                        helperText='For example: "https://vaultname.vault.azure.net/"'
                                        isFormElement
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <StriimInputField
                                        required
                                        name="properties.ClientID"
                                        label="Client ID"
                                        isFormElement
                                        helperText='For example : "abc123"'
                                    />
                                </Grid>
                            </Grid>
                            <div
                                data-test-id="vaults-encrypted-data-field"
                                onClick={() => {
                                    if (dataForEdit) {
                                        setClientSecretInput(true);
                                    }
                                }}
                            >
                                <StriimInputField
                                    required
                                    name="properties.ClientSecret"
                                    label="Client Secret"
                                    inputRef={clientSecretRef}
                                    isFormElement
                                    sx={sxStyles.container}
                                    type="password"
                                    placeholder={dataForEdit ? "(encrypted)" : null}
                                    disabled={dataForEdit ? !clientSecretInput : false}
                                    previewPassword
                                    helperText={
                                        dataForEdit
                                            ? "Click on the input field, to enter a new access token"
                                            : 'For example : "abc123"'
                                    }
                                    onChange={event => {
                                        handleClientSecretField(event);
                                    }}
                                    onBlur={event => {
                                        handleClientSecretField(event);
                                    }}
                                />
                            </div>
                            <StriimInputField
                                required
                                name="properties.TenantID"
                                label="Tenant ID"
                                helperText='For example: "abc123"'
                                isFormElement
                                sx={sxStyles.container}
                            />
                        </>
                    );
                }}
            </StriimForm>
        </StriimTheme>
    );
};

export default AzureVault;
