import React, { useContext, useRef, useState, useEffect } from "react";
import WizardLayout from "../../../common/wizard-layout/wizard-layout";
import Footer from "../../../common/footer/footer";
import { Steps, getCurrentStepHeader, getCurrentStepSubHeader, stepDetails } from "../../target-steps";
import { WizardContext } from "../../../wizard-context";
import Header from "../../../common/header/header";
import Stepper from "../../../common/stepper/stepper";
import ValidationList from "../../../common/validation-list/validation-list";
import { checkTargetPrivileges, getEncryptedUrl, saveTarget, validateConnection } from "../../../services";
import NProgress from "nprogress";
import growl from "../../../../../../app/components/common/growl";
import sanitizer from "../../../../../../app/components/appwizard/component-wizards/wizards/target/base-simple-target/server-error-sanitizer.js";
import metaobjectConverter from "../../../../../../core/services/metaStoreService/metaobject-converter";
import { getSourceConfig, getTargetConfig } from "../../../common/review/review.helper";
import { validationUtils, VALIDATION_TIMEOUT } from "../../../utils";
import { isAutomatedWizard } from "../../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";
import _ from "underscore";
import { saveHideURLStatus } from "../../../utils/encrypt-url-utils";
import Tracker from "../../../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../../core/services/tracker/constants";

interface ValidateTargetProps {
    target: string;
}

const ValidateTarget = ({ target }: ValidateTargetProps) => {
    const [apiStatus, setAPIStatus] = useState({
        isAPISuccessful: false,
        isAPICompleted: false,
        canRetry: false
    });
    const [enableBackButton, setEnableBackButton] = useState(false);

    const { setCurrentStep, targetInfo, sourceInfo, cdcSourceInfo, cdcTargetInfo, wizardType } = useContext(
        WizardContext
    );

    const validationListItems = [
        {
            status: "todo",
            text: "Trying to reach your target",
            method: function() {
                return validateConnection(target, targetInfo);
            }
        },
        {
            status: "todo",
            text: "Validating Permissions",
            method: function() {
                return checkTargetPrivileges(target, targetInfo);
            }
        }
    ];

    const childRef = useRef();
    const heading = getCurrentStepHeader(Steps.ValidateTarget, target);
    const subHeading = getCurrentStepSubHeader(Steps.ValidateTarget);
    const stepper = <Stepper steps={stepDetails} activeStep={stepDetails.ValidateTarget.id} />;
    const getEncryptedURLs = async () => {
        const AppName = sourceInfo?.appId;
        const shortAppName = metaobjectConverter.convertFullNameToShortName(AppName);
        let sourceEncryptedURL = false,
            targetEncryptedURL = false;

        // Source
        const sourceData = sourceInfo.sourceAdapter.properties;
        let sourceDB =
            sourceData.adapterName === "DatabaseReader" ? sourceData.DatabaseProviderType : sourceData.adapterName;
        const sourceConfig = getSourceConfig(shortAppName, sourceDB);
        const EncryptedSourceUrl = validationUtils.isRDBMS(sourceDB)
            ? await getEncryptedUrl(sourceDB, sourceConfig, shortAppName, sourceConfig.agentName)
            : null;
        // Target
        const targetDB = validationUtils.getTargetType(target);
        const targetConfig = getTargetConfig(shortAppName, targetDB);
        const EncryptedTargetUrl = validationUtils.isRDBMS(targetDB)
            ? await getEncryptedUrl(targetDB, targetConfig, shortAppName)
            : null;
        try {
            const sourceURL = JSON.parse(EncryptedSourceUrl);
            sourceEncryptedURL = _.isEmpty(sourceURL) ? null : sourceURL;
            saveHideURLStatus(shortAppName, _.isEmpty(sourceURL) ? null : true);
            const targetURL = JSON.parse(EncryptedTargetUrl);
            targetEncryptedURL = _.isEmpty(targetURL) ? null : targetURL;
        } catch (e) {
            console.error(e);
        } finally {
            return { source: sourceEncryptedURL, target: targetEncryptedURL };
        }
    };

    useEffect(() => {
        const isAutomatedILCDC = isAutomatedWizard();
        if (isAutomatedILCDC) {
            getEncryptedURLs();
        }
    }, []);

    const trackEventClicked = buttonClicked => {
        const appId = isAutomatedWizard() ? cdcSourceInfo.appId : sourceInfo.appId;
        Tracker.getInstance().track(
            TRACKER_STRINGS.schema.wizards,
            {
                id: appId,
                event: TRACKER_STRINGS.eventClicked.wizards,
                step: Steps.ConfigureTarget,
                adapterType: target,
                buttonClicked: buttonClicked,
                isAutomatedILCDC: isAutomatedWizard()
            },
            TRACKER_STRINGS.version.withModification
        );
    };

    const handleSaveTarget = async () => {
        NProgress.start();
        const isAutomatedILCDC = isAutomatedWizard();

        let encryptedURL = { source: false, target: false };
        if (isAutomatedILCDC) encryptedURL = await getEncryptedURLs();

        saveTarget(
            target,
            sourceInfo,
            validationUtils.replaceConnectionURL(targetInfo),
            isAutomatedILCDC,
            cdcSourceInfo,
            validationUtils.replaceConnectionURL(cdcTargetInfo),
            wizardType,
            encryptedURL.source,
            encryptedURL.target
        )
            .then(
                () => {
                    setCurrentStep(Steps.Review);
                },
                e => {
                    let msg = "Please correct the errors and try again.";
                    if (Array.isArray(e)) {
                        msg += e.join(", ");
                    } else {
                        msg += sanitizer.sanitize(e.message);
                    }
                    setCurrentStep(Steps.ConfigureTarget);
                    growl.error(msg, "Error saving your target.");
                }
            )
            .finally(() => {
                NProgress.done();
            });
    };
    const handleNext = async () => {
        if (apiStatus.canRetry) {
            childRef?.current?.retryAPI();
        } else {
            setTimeout(async () => {
                await handleSaveTarget();
            }, VALIDATION_TIMEOUT);
        }
        setAPIStatus(prevState => ({ ...prevState, isAPISuccessful: false, isAPICompleted: false }));
        trackEventClicked("Next");
    };
    return (
        <WizardLayout
            Header={<Header heading={heading} subHeading={subHeading} />}
            Stepper={stepper}
            inlineDocKey={"validation-pages"}
            Footer={
                <Footer
                    nextDisabled={!apiStatus.isAPICompleted}
                    backDisabled={!apiStatus.isAPICompleted && !enableBackButton}
                    cancelDisabled={!apiStatus.isAPICompleted}
                    onNext={handleNext}
                    next={apiStatus.canRetry ? "Retry" : "Next"}
                    onBack={() => {
                        setCurrentStep(Steps.ConfigureTarget);
                        trackEventClicked("Back");
                    }}
                />
            }
        >
            <ValidationList
                validationList={validationListItems}
                setAPIStatus={setAPIStatus}
                ref={childRef}
                onSuccess={handleNext}
            />
        </WizardLayout>
    );
};

export default ValidateTarget;
