import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import Resources from "./resources_en.json";
import AppAdapterResources from "./app-adapters-resources_en.json";
import APP_ADAPTERS from "./app-adapters";

const lowerCaseResources = [];
const lowerCaseAppAdapterResources = [];

for (let key in Resources) {
    if (Resources.hasOwnProperty(key)) {
        lowerCaseResources[key.toLowerCase()] = Resources[key];
    }
}
for (let key in AppAdapterResources) {
    if (AppAdapterResources.hasOwnProperty(key)) {
        lowerCaseAppAdapterResources[key.toLowerCase()] = AppAdapterResources[key];
    }
}

/**
 * @function getControlResource
 * get resource for property
 * @param propertyName - name of property
 * @param resourceNamespace - optional resource namespace
 */
export default function getControlResource(
    propertyName,
    resourceNamespace,
    sourceAdapters = [],
    databaseType = null,
    isresourceNamespaceFullName = true
) {
    let key = (propertyName || "").toLocaleLowerCase();
    let resource = lowerCaseResources[key];
    if (resourceNamespace) {
        if (isresourceNamespaceFullName) {
            resourceNamespace = MetaObjectConverter.getName(resourceNamespace);
        }
        if (isAppAdapter(resourceNamespace)) {
            return getAppAdapterResource(propertyName, resourceNamespace);
        }
        let hierarchicalKey;
        if (databaseType && propertyName.toLocaleLowerCase() === "connectionurl") {
            hierarchicalKey = getConnectionUrl(databaseType, resourceNamespace);
        } else {
            let hierarchicalName = (resourceNamespace + "." + key).toLocaleLowerCase();
            hierarchicalKey = getResource(resource, hierarchicalName, sourceAdapters, resourceNamespace);
        }
        if (hierarchicalKey) {
            resource = hierarchicalKey;
        }
    }
    return resource;
}

function isAppAdapter(resourceNamespace) {
    return APP_ADAPTERS.includes(resourceNamespace);
}
function getAppAdapterResource(propertyName, resourceNamespace) {
    let key = (propertyName || "").toLocaleLowerCase();
    let resource = lowerCaseAppAdapterResources[key];

    if (resourceNamespace) {
        let hierarchicalName = (resourceNamespace + "." + key).toLocaleLowerCase();
        resource = lowerCaseAppAdapterResources[hierarchicalName] ?? resource;
    }
    return resource;
}

/**
 * @function convertNameToHumanRedable
 * convert property name to user friendly name
 * @param propertyName - name of property
 */
export function convertNameToHumanRedable(propertyName) {
    if (!propertyName) {
        return propertyName;
    }

    let result = propertyName.trim();

    if (!result || result.length < 2) {
        return propertyName;
    }

    result = result[0].toUpperCase() + result.substr(1);

    try {
        result = result
            .replace(/([A-Z]+)/g, " $1")
            .replace(/([A-Z][a-z])/g, " $1")
            .trim();
        result = result.replace(/  +/g, " ");
    } catch (ex) {
        console.error("Cannot convert property name", ex);
    }

    return result;
}

export function toTitleCase(str) {
    if (!str) {
        return str;
    }

    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

function getResource(resource, hierarchicalName, sourceAdapters, resourceNamespace = "") {
    if (resource?.name === "Tables") {
        return generateTableMappingFormat(
            lowerCaseResources[hierarchicalName],
            sourceAdapters,
            hierarchicalName,
            resourceNamespace
        );
    }
    return lowerCaseResources[hierarchicalName];
}

function isSchemaLessAdapter(adapter) {
    // Currently Salesforce and ServiceNow are schemaless databases
    if (adapter.toLowerCase().includes("salesforce") || adapter.toLowerCase().includes("servicenow")) return true;
    return false;
}
/**
 * Sets Table tooltip based on source adapter types
 */
function generateTableMappingFormat(property, sourceAdapters, hierarchicalName, resourceNamespace) {
    // property null check
    if (!property) return null;

    const targetAdapter = hierarchicalName.substr(0, hierarchicalName.indexOf("."));
    // init key & src adapter types
    let key = { ...property };
    // TODO Have a common source of truth for different source/target info to be utilized everywhere
    let isSchemaLess = sourceAdapters.some(srcAdapter => srcAdapter && isSchemaLessAdapter(srcAdapter));
    let isSchema = sourceAdapters.some(srcAdapter => srcAdapter && !isSchemaLessAdapter(srcAdapter));

    // Shows tooltip depending on the targer adapter specific information (schema/schemaless)
    const targetAdapterTables =
        lowerCaseResources[`tables.sample.${targetAdapter.toLowerCase()}.${isSchemaLess ? "schemaless" : "schema"}`];

    if (targetAdapterTables) {
        key.description += targetAdapterTables;
    } else if (sourceAdapters.length) {
        // append default for schema or no srcs
        if (isSchema || (!isSchema && !isSchemaLess)) {
            key.description += lowerCaseResources["tables.default"];
        }
        // append or if both types
        if (isSchema && isSchemaLess) {
            key.description += lowerCaseResources["tables.or"];
        }
        // append obj for schmealess(salesforce)
        if (isSchemaLess) {
            key.description += lowerCaseResources["tables.objectsource"];
        }
    } else {
        if (resourceNamespace === "DatabaseWriter") {
            key.description += lowerCaseResources["tables.default"];
        }
    }
    return key;
}

function mergedConnectionUrl() {
    return {
        name: lowerCaseResources.connectionurl.name,
        description: Object.values(lowerCaseResources.databasetypeurls)
            .map(item => item)
            .join("<br></br>")
    };
}

function getConnectionUrl(databaseType, resourceNamespace) {
    if (databaseType.toLowerCase() === "default") {
        switch (resourceNamespace) {
            case "DatabaseReader":
            case "DatabaseWriter":
            case "IncrementalBatchReader":
            case "ExternalCache":
                return mergedConnectionUrl();
            default:
                return lowerCaseResources[`${resourceNamespace.toLowerCase()}.connectionurl`];
        }
    }
    return {
        name: lowerCaseResources.connectionurl.name,
        description: lowerCaseResources.databasetypeurls[databaseType] || null
    };
}
