import _ from "underscore";
import NestedTypes from "backbone.nestedtypes";
import flowUtils from "./flowUtils";
import converter from "core/services/metaStoreService/metaobject-converter";
import GraphNodeModel from "./graphNodes/graphNodeModel";
import metaObjectStorage from "app/components/flow/designer/metaObjectStorage";

const NodesClipboard = NestedTypes.Model.extend({
    defaults: {
        copied: null,
        elements: [],
    },

    _store: function (nodes) {
        var _this = this;
        var nodeIds = _.map(nodes, function (item) {
            return item.id;
        });
        _this.elements = nodeIds;
    },

    copy: function (nodes) {
        this.copied = true;
        metaObjectStorage.save(nodes[0].metaObject);
        this._store(nodes);
    },

    cut: function (nodes) {
        this.copied = false;
        this._store(nodes);
    },

    load: function () {
        const loadedItem = metaObjectStorage.load();
        if (loadedItem) {
            if (this.copied === null) {
                this.copied = true;
            }
            this._store([loadedItem]);
        }
    },

    clear: function () {
        this.copied = null;
        this.elements = null;
        metaObjectStorage.clear();
    },

    removeNodes: function (nodes) {
        if (!this.elements) {
            return;
        }
        var nodeIds = _.map(nodes, function (item) {
            return item.id;
        });

        var newElements = _.reject(this.elements, function (id) {
            return _.contains(nodeIds, id);
        });
        this.elements = newElements;
    },

    properties: {
        containElements: function () {
            return this.elements != null && this.elements.length > 0;
        },
    },
});

var GraphModel = NestedTypes.Model.extend({
    defaults: {
        app: {},
        currentFlowId: String,
        currentFlowName: String,
        nodes: GraphNodeModel.Collection,
        datasource: Object,
        isEditing: false,
        link: String,
        actions: [],
        nodesClipboard: NodesClipboard,
    },

    findNodeByFullName: function (id) {
        var node = this.nodes.find(function (node) {
            return node.fullName == id;
        });
        return node;
    },

    properties: {
        current_app_full: function () {
            return this.app.nsName + "." + this.app.name;
        },

        currentDeploymentPlanName: function () {
            if (this.app.deploymentInfo) {
                var _this = this;
                var currentDeploymentPlan = _.find(this.app.deploymentInfo, function (deploymentPlan) {
                    return deploymentPlan.flowName == _this.currentFlowId;
                });
                if (currentDeploymentPlan) {
                    return converter.getName(currentDeploymentPlan.dg);
                }
                return "";
            }
            return "";
        },
    },

    toJSON: function () {
        return flowUtils.serializeModelToJson(this);
    },
});

export default GraphModel;
