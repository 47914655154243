import React from "react";
import PropTypes from "prop-types";
import { StriimListItem } from "@striim/striim-ui"
import { Link } from "react-router-dom";
import MuiLink from "@material-ui/core/Link";


const LinkButtonListItem = ({ text, to, id, href, openInTab }) => {
    const isHtmlLink = !!href;

    return (
        <StriimListItem component={isHtmlLink ? MuiLink : Link} to={to} id={id} href={href} target={openInTab?"_blank":""} text={text} />
    );
};

LinkButtonListItem.propTypes = {
    text: PropTypes.string,
    to: PropTypes.string,
    id: PropTypes.string,
    href: PropTypes.string,
    openInTab: PropTypes.bool
};

export default LinkButtonListItem;
