import _ from "underscore";
import Backbone from "backbone";
import Logger from 'core/logger'
var module = {};

module._apiLog = [];
module._perMinuteAggregateIn = 0;
module._perMinuteAggregateOut = 0;

setInterval(function () {
    if (!module.isDebugEnabled()) {
        return;
    }

    module.trigger("change:debug-network-rate", module._perMinuteAggregateIn, module._perMinuteAggregateOut);
    module._perMinuteAggregateIn = 0;
    module._perMinuteAggregateOut = 0;
}, 1000);

module.persistAndClearNetworkLogs = function () {
    sessionStorage.setItem("striim_latest_network_log", JSON.stringify(module._apiLog));
    module._apiLog = [];
};

module.getPersistedNetworkLogs = function () {
    var fromStorage = sessionStorage.getItem("striim_latest_network_log") || "[]";
    return JSON.parse(fromStorage);
};

module.isDebugEnabled = function () {
    return sessionStorage.getItem("striim_debug_mode") === "true";
};
module.setDebugEnabled = function (val) {
    sessionStorage.setItem("striim_debug_mode", val === true);
    module.trigger("change:debugEnabled");
};

module.isDebugNetworkRecording = function () {
    return sessionStorage.getItem("striim_debug_recording") === "true";
};
module.setDebugNetworkRecording = function (val) {
    sessionStorage.setItem("striim_debug_recording", val === true);
    module.trigger("change:debugNetworkRecording");
};

module.addApiEntry = function (isIncoming, data) {
    if (!module.isDebugEnabled()) {
        return;
    }
    if (isIncoming) {
        module._perMinuteAggregateIn++;
    } else {
        module._perMinuteAggregateOut++;
    }


    try{
        let payload = data.params;
        let tag = '';
        let message = '';
        let color = '';
        if(data.class){
            tag = `Req: ${data.callbackIndex}`
            color= Logger.COLORS.NETWORK_REQ;
            message = `Method: ${data.method} , Class: ${data.class} `
        }
        else{
            tag = `Res: ${data.callbackIndex}`
            if(data.success){
                color= Logger.COLORS.NETWORK_RES_OK;
            }
            else{
                color= Logger.COLORS.NETWORK_RES_FAIL;
            }
            message = "Response recieved.. ";
            payload = payload[0];
        }
        Logger.log(tag,color, message, {"With Params: ": payload} )

    }
    catch(e){
        let tag = (isIncoming)? "Res": "Req";
        let color =  Logger.COLORS.NETWORK_REQ;
        Logger.log(tag,color, data )
    }
    if (module.isDebugNetworkRecording()) {
        module._apiLog.push({
            isIncoming: isIncoming,
            data: data,
            time: new Date().getTime(),
        });
        if (module._apiLog.length > 1000) {
            module._apiLog.shift();
        }
    }
};

_.extend(module, Backbone.Events);
export default module;
