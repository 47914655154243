import securityService from "./securityService";
import adapterSecurityCheck from "./adapter-security-check";

const module = {};

module.checkWithLicenseValidation = function(adapter, sourceType) {
    const isFabricSolution = securityService.isFabricSolution();
    const isAdapterEnabled = adapterSecurityCheck.checkIfEnabled([adapter]);

    if (adapter?.toLowerCase() === "databasereader" && isAdapterEnabled) {
        if (isFabricSolution && sourceType !== "SQL Server" && sourceType !== "SQL Server Initial Load") {
            return false;
        }
    }
    return isAdapterEnabled;
};

export default module;
