/**
 * Makes Marionette Views Helpable. This library turns on context-help for our product.
 *
 * Usage:
 *
 * 1) Open app/components/common/helpable/online-help-dictionary.js and add an entry into the dictionary.
 *    Each entry should have an ID, a title and a href to navigate to.
 *
 * 2) Add helpableMixin to the define part in your module. This will include this dependency in your file.
 *
 * 3) To any Marionette view, add this Mixin.
 *      Example:
 *          Backbone.Marionette.CollectionView.extend(helpableMixin).extend({............})
 *
 * 4) when extending the view, also include a function getHelpID
 *      Example:
 *          Dashboards.CollectionView = Backbone.Marionette.CollectionView.extend(helpableMixin).extend({
            getHelpID: function(){
                return "DASHBOARDS"
            },
 *
 * 5) OPTIONAL IN MOST CASES: If a sub-dom entry needs to be the helper, add a function called getHelperContentElement(). This
 *      function should return a JQUERY reference to the DOM element that should be the helper.
 *      In most cases, this wont be necessary.
 *
 * 6) If the view has initialize(), also call the module's initialize.
  *     Example :
  *         Dashboards.CollectionView = Backbone.Marionette.CollectionView.extend(helpableMixin).extend({
            getHelpID: function(){
                return "DASHBOARDS"
            },
            initialize: function() {
                helpableMixin.initialize.apply(this, arguments);
 *
 */

import $ from "jquery";
import _ from "underscore";
import Marionette from "marionette";
import Dictionary from "app/components/common/helpable/online-help-dictionary";
import helpButtonTemplate from "app/components/common/helpable/help-button-template.html";

var module = {};
var TOOLTIP_HIDE_TIMEOUT = 1000;

/**
 *  Entry point into the module.
 *
 */
module.initialize = function () {
    if (!(this instanceof Marionette.View)) {
        throw Error("Helpable library should only be extended to Marionette Views");
    }
    var key = "DEFAULT";
    if (typeof this.getHelpID === "function") {
        key = this.getHelpID();
    }

    var dictionary_entry = Dictionary.get()[key];
    if (!dictionary_entry) {
        console.error("Help ID Does not exist in dictionary", this);
        dictionary_entry = Dictionary.get()["DEFAULT"];
    }

    this.on("show", function () {
        this.startContextHelp(dictionary_entry);
    });
};

/**
 * OnShow handler that initializes class and tooltips
 *
 * @param dictionary_entry Entry from app/components/common/helpable/online-help-dictionary.js
 */
module.startContextHelp = function (dictionary_entry) {
    var $el = this.$el;
    //var $tooltip = null;

    if (typeof this.getHelperContentElement === "function") {
        $el = this.getHelperContentElement();
    }

    $el.addClass("helpable-container");
    module._addHelpButton(dictionary_entry, $el);

    //commenting this as the question marks are right next to the corresponding text/element
    /*$el.on('mouseover', function(){
        if(!helpableConfig.get()) return;

        if($tooltip === null){
            $tooltip =  module._addTooltip(dictionary_entry, $el);
            module._extendTooltipDisplay($el);
        }
        module.showTooltip($el, $tooltip);
    });

    //As we are removing the toggle feature
    /*$el.on('mouseleave', function(){
        if(!helpableConfig.get()) return;
        module._hideHelpAfterDelay($el, $tooltip);
    });*/
};

/**
 * Adds and returns the help button icon
 *
 */

module._addHelpButton = function (dictionary_entry, $el) {
    var template = _.template(helpButtonTemplate);
    var helpButton = $(
        template({
            dictionary_entry: dictionary_entry,
        })
    );

    $el.append(helpButton);

    helpButton.on("mouseover", function () {
        $(this).addClass("helpable-button-hover");
    });

    helpButton.on("mouseleave", function () {
        $(this).removeClass("helpable-button-hover");
    });

    return helpButton;
};
/**
 * Adds and returns the Tooltip Jquery element. Also has the mouseOver handler to extend the timeout for the
 *     tooltip auto-hide.
 *
 * @param dictionary_entry
 * @param $el
 * @returns {*|jQuery|HTMLElement}
 * @private
 */
module._addTooltip = function (dictionary_entry, $el) {
    var position = $el.position();
    var left = position.left - 2 + "px";
    var template = _.template(tooltipTemplate);
    var html = template({
        left: left,
        dictionary_entry: dictionary_entry,
    });
    var $tooltip = $(html);
    $el.append($tooltip);
    $tooltip.on("mouseover", function () {
        module._extendTooltipDisplay($el);
    });
    return $tooltip;
};

/**
 * mouseOver handler for $el - show the tooltip and add the borders around the helpable container
 *
 * @param $el
 * @param $tooltip
 */
module.showTooltip = function ($el, $tooltip) {
    $el.addClass("helpable-container-hover");
    var position = $el.position();
    var tooltip_top = position.top - 26 + "px";
    $tooltip.css({
        opacity: "1",
        top: tooltip_top,
    });
};

/**
 * auto-hide the tooltip and boders when the mouse exits the $el
 *
 * @param $el
 * @param $tooltip
 * @private
 */
module._hideHelpAfterDelay = function ($el, $tooltip) {
    var timer = setTimeout(function () {
        var position = $el.position();
        var component_top = position.top + "px";
        $el.removeClass("helpable-container-hover");
        $tooltip.css({
            opacity: "0",
            top: component_top,
        });
    }, TOOLTIP_HIDE_TIMEOUT);

    $el.data("mouseout-timer", timer);
};

/**
 * Every time the mouse enters the $el or $tooltip, clear the timout to hide the tooltip.
 *
 * @param $el
 * @private
 */
module._extendTooltipDisplay = function ($el) {
    clearTimeout($el.data("mouseoutTimer"));
};

export default {
    initialize: module.initialize,
    startContextHelp: module.startContextHelp,
};
