import React, { useEffect } from "react";
import loadBackboneApp from "../../../app/load-backbone-app";
import { StyleView } from "../../../app/components/debug/editors-test";

export default function TestStyleView() {
    useEffect(() => {
        const view = new StyleView();
        loadBackboneApp(view);
    }, []);

    return <></>;
}
