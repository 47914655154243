import React from "react";
import { sxStyles } from "./error-message-box.styles";
import { StriimTypography, StriimMessageBox } from "@striim/striim-ui";
import { Box, Grid } from "@mui/material";
import ErrorIcon from "@material-ui/icons/Error";
import _ from "underscore";
import xss from "xss";

const convertToFieldName = string => {
    return string.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
};

const filterEmptyFields = (allFields, values) => {
    let emptyFields = [];
    const keys = Object.keys(allFields);
    Object.values(allFields).forEach((v, index) => {
        if (v.type === "array") {
            const innerFields = v.innerType.fields;
            const innerKeys = Object.keys(innerFields);

            Object.values(innerFields).forEach((field, innerIndex) => {
                values[keys[index]].forEach((item, vIndex) => {
                    if (
                        field.tests &&
                        field.tests.some(x => x?.name === "required") &&
                        !values[keys[index]][vIndex][innerKeys[innerIndex]]
                    ) {
                        emptyFields.push(innerKeys[innerIndex]);
                    }
                });
            });
        } else if (v.tests && v.tests.some(x => x?.name === "required") && !values[keys[index]]) {
            emptyFields.push(keys[index]);
        }
    });
    return emptyFields;
};

const getErrorMessage = ({ validationSchema, values, errors, emptyFieldErrors = true, otherFieldErrors = false }) => {
    let text = "";
    const allFields = validationSchema.describe().fields;
    const filteredFields = filterEmptyFields(allFields, values);
    const emptyFields = [...new Set(filteredFields)];
    let emptyFieldsCount = emptyFields.length || 0;
    text =
        emptyFieldErrors && emptyFieldsCount
            ? `Please complete the missing
            ${
                emptyFieldsCount === 1 && emptyFields[0] === "targetDatabase"
                    ? filteredFields.length === 1
                        ? ` field.<br />`
                        : ` fields.<br />`
                    : `
                    ${convertToFieldName(emptyFields[0])}
                    ${
                        emptyFieldsCount === 2
                            ? ` and ${convertToFieldName(emptyFields[1])}`
                            : emptyFieldsCount > 2
                            ? ` and ${filteredFields.length - 1} other`
                            : ``
                    }
                    ${filteredFields.length > 1 ? ` fields.` : ` field.`} <br/>`
            }`
            : ``;

    if (otherFieldErrors) {
        let errorObj = {};
        for (let property in errors) {
            if (Array.isArray(errors[property])) {
                errors[property].map((v, i) => {
                    if (v) errorObj[Object.keys(v)[0]] = Object.values(v)[0];
                });
                continue;
            }
            errorObj[property] = errors[property];
        }
        const otherFields = _.omit(errorObj, emptyFields);
        const otherErrors = Object.values(otherFields);
        if (otherErrors.length) {
            text += `${otherErrors.join("<br />")}`;
        }
    }
    return text;
};

function ErrorMessageBox(props) {
    let text = getErrorMessage(props);

    return text ? (
        <Box sx={sxStyles.errorBox}>
            <StriimMessageBox
                text={
                    <Grid>
                        <StriimTypography
                            sx={{ color: theme => theme.palette.greyscale[900] }}
                            dangerouslySetInnerHTML={{ __html: xss(text) }}
                            data-test-id="error-box-message"
                        ></StriimTypography>
                    </Grid>
                }
                type="ERROR"
            />
        </Box>
    ) : (
        <></>
    );
}

export default ErrorMessageBox;
