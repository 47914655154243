import _ from "underscore";
import TimeParser from "./time-parser";
import Interval from "./interval";

var View = Interval.View.extend({
    getViewValue: function () {
        return TimeParser.toMicroseconds(this.value.getValue(), this.unit.getValue());
    },
});

export default _.extend({}, Interval, {
    Model: Interval.Model,
    View: View,
});
