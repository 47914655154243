import _ from "underscore";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import securityCheck from "core/services/securityService/adapter-security-check";

export default function(adapterType) {
    var results = [];

    var items = propertyTemplateService.propertyTemplates;
    if (adapterType) {
        items = items.where({
            adapterType: adapterType
        });
    } else {
        items = items.toArray();
    }
    _.each(items, function(model) {
        const enabled = securityCheck.checkIfEnabled([model.name]);
        var name = model.name;
        var version = null;
        if (metaObjectConverter.isVersioned(model.id)) {
            version = "Version: " + metaObjectConverter.getVersion(model.id);
            name = metaObjectConverter.getWithoutVersion(model.name);
        }

        results.push({
            id: enabled ? model.id : name + " (Unavailable due to license restrictions)",
            text: name,
            type: model.type,
            refModel: model,
            disabled: !enabled,
            description: version
        });
    });

    let retVal = _.sortBy(results, function(item) {
        return item.text;
    });
    return retVal;
}
