import React, { useEffect, useState } from "react";
import { StriimTypography, StriimButton, StriimMessageBox } from "@striim/striim-ui";
import { Box, Grid } from "@mui/material";
import sxClasses from "./segmentation.styles";
import {
    addonFeaturesTitles,
    addonFeaturesMessages
} from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import customIcons from "src/generic/icon/customIcons-legacy";
import getAddonIconKey from "src/modules/user-plan/pages/user-plan/tabs/shared-compoenets/addon-icons";
import securityService from "core/services/securityService/securityService";
import Tracker from "../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../core/services/tracker/constants";

function SegmentationModal({ licenseKey, variant, closeTooltip, setModalOpen }) {
    const [data, setData] = useState({});
    const [Icon, setIcon] = useState(null);

    useEffect(() => {
        var title = addonFeaturesTitles[licenseKey];
        var description = addonFeaturesMessages[licenseKey];
        var key = getAddonIconKey(securityService.currentSegmentationLicense, licenseKey);
        if (!key) {
            key = "AccountTreeOutlined";
        }
        var Icon = customIcons(key);
        setIcon(Icon);
        const data = {
            title,
            description,
        }
        setData(data)
    }, [])
    const handleLearnMoreClick = () => {
        closeTooltip();
        setModalOpen(true);
        Tracker.getInstance().track(TRACKER_STRINGS.schema.learnMore, {
            event: TRACKER_STRINGS.eventClicked.learnMore
        });
    };

    return (
        <StriimMessageBox
            type="INFO"
            customIcon={<></>}
            actions={
                <Grid container sx={sxClasses.actionsContainer}>
                    <StriimButton
                        onClick={closeTooltip}
                        variant="secondaryText"
                        sx={sxClasses.modalText}
                    >Cancel</StriimButton>
                    <StriimButton
                        onClick={handleLearnMoreClick}
                        variant="primary">Learn More</StriimButton>
                </Grid>
            }
            text={
                <Box>
                    <Grid container sx={sxClasses.tooltipContainer}>
                        <Box >
                            {
                                Icon &&
                                <div
                                    className={`seg-circle ${variant}-circle ${licenseKey}`}
                                    dangerouslySetInnerHTML={{ __html: Icon }}></div>
                            }

                        </Box>
                        <StriimTypography variant="h2" sx={sxClasses.modalText}>
                            {data?.title}
                        </StriimTypography>
                    </Grid>
                    <Box sx={{ margin: "16px 8px" }}>
                        <StriimTypography variant="body" sx={sxClasses.modalText}>
                            {data?.description}
                        </StriimTypography>
                    </Box>

                </Box>
            }
        />
    );
}

export default SegmentationModal;
