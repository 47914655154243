export const classes = {
    heading: {
        marginBottom: 0,
        color: theme => theme.palette.primary[700]
    },
    heading2: {
        marginTop: 0,
        color: theme => theme.palette.greyscale[700]
    },
    heading2Container: {
        marginBottom: theme => theme.spacing(2),
        width: 400
    },
    headingContainer: {
        marginTop: theme => theme.spacing(3)
    },
    searchResults: {
        color: theme => theme.palette.primary[700]
    }
};
