import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing, breakpoints }) => {
    return {
        dashboardName: {
            [breakpoints.up("md")]: {
                marginRight: spacing(2)
            }
        },
        dashboardSearchInput: {
            flexGrow: 1,
            minWidth: 300
        },
        dashboardNameSpaceInput: {
            flexGrow: 1,
            minWidth: 160
        },
        dashboardSubHeaderWrapper: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: spacing(4)
        },
        dashboardCountContainer: {
            display: "flex",
            alignItems: "center",
            flexGrow: 1
        },
        dashboardCount: {
            marginRight: "auto"
        },
        secondRowOnMd: {
            [breakpoints.down("sm")]: {
                marginTop: spacing(2)
            }
        },
        wrapper: {
            marginTop: spacing(10),
            marginBottom: -spacing(11),
            paddingLeft: spacing(3),
            paddingRight: spacing(3),
            zIndex: 100
        },
        wrapperInner: {
            marginTop: spacing(3),
            marginBottom: spacing(3)
        },
        button: {
            minWidth: "25%",
            height: "40px",
            [breakpoints.down("xs")]: {
                width: "100%"
            }
        }
    };
});
