import React, { useEffect, useRef } from "react";
import MetadataManager from "app/components/metadatabrowser/metadatabrowser";
import App from "app";
import { MetadataManagerRegionName } from "../manage-striim-page";

export default function LegacyMetadataManager() {
    const useInitializeRef = useRef(false);
    useEffect(() => {
        if (useInitializeRef.current) {
            return;
        }

        const metadataRegion = {};
        metadataRegion[MetadataManagerRegionName] = "#" + MetadataManagerRegionName;
        const createdRegions = App.addRegions(metadataRegion);
        createdRegions[MetadataManagerRegionName].show(new MetadataManager.View());
        useInitializeRef.current = true;
    }, []);

    return <></>;
}
