export const styles = {
    leftContainer: {
        display: "flex",
        overflowY: "auto",
        justifyContent: "center",
        backgroundColor: ({ palette }) => palette.greyscale[50],
        border: "none",
        borderRadius: 0,
        padding: 8.875
    },
    logoContainer: {
        flexDirection: "row",
        pt: 8.87,
        pl: 4,
        backgroundColor: ({ palette }) => palette.greyscale[200]
    },
    iconContainer: {
        margin: 0,
        paddingTop: 1,
        flexWrap: "nowrap",
        columnGap: 2
    },
    iconContentItem: {
        display: "flex",
        flexDirection: "column"
    },
    icon: {
        fill: "none",
        fontSize: 20
    }
};
