import dateLib from "core/utils/date-utils";

export default [
    {
        key: "lasthour",
        name: "Last Hour",
        from: function() {
            let start = dateLib().subtract(1, "hour");
            const remainder = 15 - (start.minute() % 15);
            return dateLib(start).add(remainder, "minute");
        },
        to: function() {
            let end = dateLib();
            const remainder = 15 - (end.minute() % 15);
            return dateLib(end).add(remainder, "minute");
        }
    },
    {
        key: "lastday",
        name: "Last Day",
        from: function() {
            return dateLib().subtract(1, "day");
        },
        to: function() {
            return dateLib();
        }
    }
];

export const last6hourOption = {
    key: "last6hours",
    name: "Last 6 Hours",
    from: function() {
        return dateLib().subtract(6, "hour");
    },
    to: function() {
        return dateLib();
    }
};
