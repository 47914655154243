import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StriimTypography, StriimModal } from "@striim/striim-ui";

import Form from "./form";

const DashboardRenameModal = ({ dashboard, renameModalVisible, setRenameModalVisible }) => {
    const formRef = useRef(null);
    return (
        <StriimModal
            isVisible={renameModalVisible}
            disableMaxHeight
            autoHeight
            size="small"
            confirmButtonProps={{ "data-test-id": "confirm-rename-button" }}
            cancelButtonProps={{ "data-test-id": "cancel-rename-button" }}
            titleContent={<StriimTypography variant="h2">Rename Dashboard</StriimTypography>}
            onConfirm={() => {
                formRef.current.handleSubmit();
            }}
            onCancel={() => {
                setRenameModalVisible(false);
            }}
        >
            <Form ref={formRef} dashboard={dashboard} submitCallback={() => setRenameModalVisible(false)} />
        </StriimModal>
    );
};

export default DashboardRenameModal;
