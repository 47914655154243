var module = {
    _data: {},
    get: function () {
        return this._data;
    },
    set: function (data) {
        this._data = data;
    },

    getTablesList: function () {
        var moduleData = this.get();

        var tables = "";
        if (moduleData.tableSelectionType === "wildcard") {
            tables = moduleData.selectedFilter;
        } else {
            var ids = [];
            if (moduleData.selectedTables) {
                moduleData.selectedTables.each(function (model) {
                    ids.push(model.get("schema") + "." + model.get("table"));
                });
            }
            tables = ids.join(";");
        }
        return tables;
    },
};
export default module;
