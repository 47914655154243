import React, { useMemo, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import growl from "../../../../../../app/components/common/growl";
import { formatBytes } from "../utils/number";
import { ProgressIndicator, Card } from "../components";
import { StriimButton, StriimInputField } from "@striim/striim-ui";
import { Grid, Box } from "@material-ui/core";
import { isUserAdmin } from "core/user-management/update.control";
// we assign totalBytes not using useState because useState is async and totalBytes is null when progress reaches 100 quickly
let totalBytes = 0;

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#00A7E5",
    borderStyle: "dashed",
    backgroundColor: "#FEFEFE",
    color: "#BDBDBD",
    outline: "none",
    transition: "border .24s ease-in-out"
};

const focusedStyle = {
    borderColor: "#2196F3"
};

const acceptStyle = {
    borderColor: "#00E676"
};

const rejectStyle = {
    borderColor: "#FF1744"
};

function UploadDropzone({
    setFilePath,
    deleteFile,
    onFileDone,
    directories,
    selectedDirectory,
    setSelectedDirectory,
    userDirectory,
    xhrRequest,
    setXhrRequest
}) {
    const [percentage, setPercentage] = useState(null);
    const [currentBytes, setCurrentBytes] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [fileUploading, setFileUploading] = useState(false);
    const uploadFolder = "UploadedFiles";
    const uploadPath = userDirectory.substring(userDirectory.indexOf(uploadFolder), userDirectory.lastIndexOf("/"));
    const isAdmin = isUserAdmin();

    const onProgress = event => {
        const percentages = +((event.loaded / event.total) * 100).toFixed(2);
        setPercentage(percentages);
        setCurrentBytes(Math.floor(totalBytes * (percentages / 100)));
    };

    const onLoadEnd = (event, xhr, acceptedFiles) => {
        setFilePath(acceptedFiles[0].name);
        setFileUploading(false);
        if (xhr.readyState !== 4) return;
        if (xhr.status !== 200) {
            //if not cancelled by user then show a toast
            if (xhr.status !== 0) {
                growl.error(xhr.responseText, "There was a problem uploading the file");
            }
            setPercentage(null);
            setFilePath(null);
            return;
        }
        onFileDone && onFileDone(acceptedFiles[0].name);
        xhr.removeEventListener("progress", onProgress);
        xhr.removeEventListener("loadend", onLoadEnd);
        growl.success("File Upload Successful");
    };

    const onDrop = useCallback(
        acceptedFiles => {
            if (acceptedFiles.length) {
                const file = acceptedFiles[0];
                // Don't allow uploading hidden files
                if (file.name[0] === ".") {
                    growl.error("Cannot upload hidden files");
                    return;
                }
                totalBytes = file.size;
                setFileName(file.name);
                const formData = new FormData();
                formData.append("file", file);
                const xhr = new XMLHttpRequest();
                xhr.upload.addEventListener("progress", event => onProgress(event));
                xhr.addEventListener("loadend", event => onLoadEnd(event, xhr, acceptedFiles));
                xhr.open("POST", `/upload?path=${decodeURIComponent(selectedDirectory.value)}`);
                setFileUploading(true);
                setXhrRequest(xhr);
                xhr.send(formData);
            } else {
                growl.error("Please select only a single file");
            }
        },
        [acceptedFiles, selectedDirectory]
    );

    const isFileLoaded = () => {
        return percentage === 100;
    };

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
        maxFiles: 1,
        onDrop: onDrop
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <>
            {!isFileLoaded() && !fileUploading && (
                <>
                    <Grid container alignItems="center">
                        {isAdmin ? (
                            <>
                                <Grid item>
                                    <p style={{ paddingRight: 8 }}>Upload a new file to the folder: {uploadPath}</p>
                                </Grid>
                                <Grid item xs={3} ml={4}>
                                    <StriimInputField
                                        select
                                        defaultValue={directories[0]}
                                        SelectProps={{
                                            options: directories,
                                            isClearable: false,
                                            menuPosition: "fixed"
                                        }}
                                        onChange={val => {
                                            setSelectedDirectory(val);
                                        }}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <Grid item>
                                <p style={{ paddingRight: 8 }}>Upload files to {uploadPath}</p>
                            </Grid>
                        )}
                    </Grid>
                    <div
                        className="container"
                        style={{ cursor: "pointer", width: "100%", marginBottom: "8px" }}
                        data-test-id="upload-container"
                    >
                        <div {...getRootProps({ style })}>
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <span
                                    className="material-icons-outlined"
                                    style={{
                                        fontSize: 30,
                                        transform: "rotate(-180deg)",
                                        color: "#00A7E5"
                                    }}
                                >
                                    file_download
                                </span>
                                <input {...getInputProps()} />
                                <p style={{ color: "#4E5C62", paddingLeft: 24 }}>
                                    Drag and drop to upload or <span style={{ color: "#00A7E5" }}>Browse</span>
                                </p>
                            </Box>
                        </div>
                    </div>
                </>
            )}
            {(percentage || fileUploading) && (
                <div style={{ padding: 24 }} data-test-id="uploaded-file">
                    <Card>
                        <div style={{ marginBottom: 22 }}>{fileUploading ? "File Uploading" : "File Uploaded"}</div>
                        <ProgressIndicator
                            percentage={percentage}
                            variant={isFileLoaded() && fileUploading ? "indeterminate" : "none"}
                        />
                        <Grid container alignItems="center" style={{ display: "flex", marginTop: 10 }}>
                            <Grid item>{fileName}</Grid>
                            <Grid item>
                                {fileUploading ? (
                                    <StriimButton
                                        variant="primaryText"
                                        onClick={() => {
                                            xhrRequest.abort();
                                        }}
                                        data-test-id="cancel-file-upload-button"
                                    >
                                        Cancel
                                    </StriimButton>
                                ) : (
                                    <StriimButton
                                        variant="primaryText"
                                        onClick={() => {
                                            setPercentage(null);
                                            const directoryPath = selectedDirectory?.value?.length
                                                ? selectedDirectory?.value
                                                : userDirectory;

                                            deleteFile(`${directoryPath}/${fileName}`, false);
                                        }}
                                        data-test-id="delete-file-button-modal"
                                    >
                                        Delete
                                    </StriimButton>
                                )}
                            </Grid>
                        </Grid>
                        <div>{formatBytes(currentBytes)}</div>
                    </Card>
                </div>
            )}
        </>
    );
}

export default UploadDropzone;
