import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./property-template.html";
import DataSource from "./property-template-datasource";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import propertyTemplateOverrides from "./property-template-overrides";
import $__app_components_common_editor_control_ui_control from "app/components/common/editor/control/ui-control";
import $__app_components_common_editor_form_builder from "app/components/common/editor/form-builder";
import copyPropertiesFrom from "./copy-properties/copy-properties-view";
import securityService from "core/services/securityService/securityService";
import growl from "app/components/common/growl";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import getControlResource from "../../../../common/editor/control-resource-resolver";
import FormBuilderMarionetteWrapper from "app/components/marionette-wrapper-for-react-form";
import FormBuilder, { TooltipComponent } from "src/generic/fd-form-builder/fd-form-builder";
import SegmentationLabel from "src/generic/fd-form-builder/segmentation-label.jsx";
import { FIELD_TYPES } from "@striim/striim-ui";
import MetaObjectConverter from "../../../../../../core/services/metaStoreService/metaobject-converter";

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "property-template",
    disallowedControls: [],
    regions: {
        handlerRegion: ".handler",
        requiredPropertiesRegion: ".properties-required",
        optionalPropertiesRegion: ".properties-optional"
    },

    ui: {
        showHideProperties: ".show-hide-optional-properties"
    },

    events: {
        "click @ui.showHideProperties": "toggleOptionalProperties"
    },

    initialize: function() {
        this._UIControl = $__app_components_common_editor_control_ui_control;
        this._FormBuilder = $__app_components_common_editor_form_builder;
        this.firstLoad = true;
        this.currentValues = {};
        BaseControl.View.prototype.initialize.apply(this, arguments);

        const additionalAction = this._isAdditionalActionAvailable() ? "content_copy" : undefined;

        var property_templates_store = new DataSource(this.model.adapterType);
        this.propertySelect = this._UIControl
            .Select(property_templates_store, {
                showSelectionDescription: true,
                customViewOptions: this.customViewOptions,
                additionalAction: additionalAction,
                allowClear: false
            })
            .create();
    },

    setViewEnabled: function() {
        try {
            let propertySelectValue = this.propertySelect.getValue();
            var propertiesFromStore = propertyTemplateService.propertyTemplates.findWhere({
                id: propertySelectValue
            });
            this.renderForm(this.propertiesModel, propertiesFromStore, propertySelectValue);
        } catch (error) {
            console.log(error);
        }
        var enabled = this.getEnabled();
        this.propertySelect.setEnabled(enabled);
        this.propertySelect.showAdditionalAction(this._isAdditionalActionAvailable());

        if (this.options?.disableAdapterSelect) {
            this.propertySelect.setEnabled(false);
        }

        // condition to check if forms were created
        if (this.requiredFieldsForm) {
            this.requiredFieldsForm.setReadOnly(!enabled);
        }
        if (this.optionalFieldsForm) {
            this.optionalFieldsForm.setReadOnly(!enabled);
        }
    },

    refreshShowHideParameters: function(templateModel) {
        if (templateModel.get("handler")) {
            this.ui.showHideProperties.show().css("display", "inline-block");
        } else {
            this.ui.showHideProperties.hide();
        }
    },

    setDataSource: function(dataSource) {
        if (!dataSource || dataSource.length === 0 || !this.propertySelect) {
            return;
        }

        this.propertySelect.setDataSource(dataSource);
    },
    getSegmentationInfo: function(segmentationInfo, propertyName) {
        var segmentationResult = {
            disabled: false
        };
        const addonAvailable = securityService.isSegmentationFeatureEnabled(segmentationInfo);
        const variant = securityService.getSegmentationVariant(segmentationInfo);
        if (!addonAvailable) {
            segmentationResult.disabled = true;
            segmentationResult.label = (
                <SegmentationLabel licenseKey={segmentationInfo} label={propertyName} variant={variant} />
            );
        }
        return segmentationResult;
    },
    parseMapOrJsonUiConfig: function(jsonOrMapFields, property, defaultValue) {
        let { name } = property;
        try {
            let value = [];
            let uiconfig = JSON.parse(property.uiconfig);
            let stringValue = defaultValue;
            if (uiconfig.type === "MAP") {
                jsonOrMapFields[name] = uiconfig;
                if (stringValue) {
                    let rows = stringValue?.split(uiconfig.rowDelimiter);
                    rows.forEach(row => {
                        let kv = row.split(uiconfig.columnDelimiter);
                        value.push({
                            key: kv[0].trim(),
                            value: kv[1]
                        });
                    });
                    return value;
                }
            }
            if (uiconfig.type === "JSON") {
                jsonOrMapFields[name] = uiconfig;
                if (stringValue) {
                    let JSONValue = JSON.parse(stringValue);
                    for (let key in JSONValue) {
                        value.push({
                            key: key.trim(),
                            value: JSONValue[key]
                        });
                    }
                    return value;
                }
            }
            return null;
        } catch (error) {
            console.log("error is ", error);
            return null;
        }
    },
    renderForm: function(propertiesModel, propertiesFromStore, selectedAdapter, forceRender = false) {
        try {
            if (!propertiesFromStore || _.isEmpty(propertiesModel)) {
                return;
            }
            // Stop rendering if the method is called by setViewEnabled
            // and the form is rendered with the same enabled value before
            const formEnabled = this?.options?.model?.enabled;
            if (this.formEnabled === formEnabled && !forceRender) {
                return;
            } else {
                this.formEnabled = formEnabled;
            }

            const that = this;
            const initialFieldValues = {};
            const resourceNamespace = propertiesFromStore?.id;
            const propertiesModelJson = propertiesModel.toJSON();
            const formattedPropertiesModelMap = new Map();

            // Loop - 1 required loop  - convert the keys to lowercase
            Object.keys(propertiesModelJson).forEach(key => {
                formattedPropertiesModelMap.set(key.toLowerCase(), propertiesModelJson[key]);
            });

            let databaseType = null;
            const propertyTemplatesToSetDefault = [
                "Global.PROPERTYTEMPLATE.DatabaseReader",
                "Global.PROPERTYTEMPLATE.DatabaseWriter",
                "Global.PROPERTYTEMPLATE.IncrementalBatchReader"
            ];
            if (propertyTemplatesToSetDefault.includes(this.model.get("value")?.handler)) {
                databaseType = "DEFAULT";
            }
            const propertyTemplateName = MetaObjectConverter.getName(resourceNamespace);

            // Get the correct property ket even if the key case is changed
            const model_keys_object = that._getPropertyKeyObjectForPropertyTemplateKey(
                propertiesFromStore?.propertyMap,
                propertiesModel
            );
            let jsonOrMapFields = {};

            // Loop-2 requied loop - Create field data for
            // form Builder form each property template field

            let formData = propertiesFromStore.propertyMap?.map(property => {
                let { defaultValue, type, name } = property;

                var model_key = model_keys_object[name];
                var lincesePropertyName = that._getLicenseByPropertyName(model_key);

                if (!["handler", "adapterName", "parserName"].includes(name)) {
                    let formattedPropertyName = name.toLowerCase();
                    initialFieldValues[model_key] = formattedPropertiesModelMap.get(formattedPropertyName);
                }
                if (!property.required && property.conditionalRequired) {
                    property.required = property.conditionalRequired;
                }

                if (property.hasOwnProperty("uiconfig")) {
                    let value;
                    if (initialFieldValues[model_key] !== undefined) {
                        value = that.parseMapOrJsonUiConfig(jsonOrMapFields, property, initialFieldValues[model_key]);
                        value && (initialFieldValues[model_key] = value);
                    }
                    value = that.parseMapOrJsonUiConfig(jsonOrMapFields, property, defaultValue);
                    value && (defaultValue = value);
                }
                // returns {name,description}
                // converts the field name to a human readable string for label
                const controlResource = getControlResource(
                    name,
                    propertyTemplateName,
                    this.options?.sourceAdapters,
                    databaseType,
                    false
                );

                let segmentationResult = {};

                if (lincesePropertyName) {
                    segmentationResult = that.getSegmentationInfo(lincesePropertyName, controlResource?.name);
                }

                if (type === "java.lang.Boolean" && typeof defaultValue === "string") {
                    defaultValue = defaultValue.toLowerCase() === "true";
                }

                if (type === "com.webaction.security.Password" || type === FIELD_TYPES.PASSWORD) {
                    type = FIELD_TYPES.PROPERTYTEMPLATE_PASSWORD;
                }

                // convert specific fields to textbox fields
                const textAreaControls = ["template", "tables", "augumentqueryclause", "sobjects"];

                if (textAreaControls.includes(name.toLowerCase())) {
                    type = FIELD_TYPES.TEXTAREA;
                }
                return {
                    ...property,
                    name: model_key,
                    defaultValue: defaultValue,
                    label: controlResource?.name,
                    type: type,
                    disabled: !formEnabled,
                    additionalLabelcomponent: (
                        <TooltipComponent
                            data-test-id={`tooltip-${property?.name}`}
                            description={controlResource?.description}
                        />
                    ),
                    ...segmentationResult
                };
            });

            const formComponent = FormBuilderMarionetteWrapper(FormBuilder, {
                formFields: formData,
                propertiesModel: propertiesModel,
                initialValues: initialFieldValues,
                jsonOrMapFields: jsonOrMapFields,
                handler: this.model.get("value")?.handler || selectedAdapter,
                metaObjectId: that.options.metaObjectId,
                adapterType: that.options.model.get("adapterType"),
                isFormEnabled: this?.options?.model?.enabled
            });
            this.formComponent = formComponent;
            this.requiredPropertiesRegion.show(formComponent);
        } catch (e) {
            console.log(e);
        }
    },

    onRender: function() {
        var that = this;
        this.propertiesModel = new Backbone.Model({});
        this.templateModel = new Backbone.Model(this.getValue());
        this.refreshShowHideParameters(this.templateModel);

        var templateSelectForm = new that._FormBuilder({
            model: that.templateModel,
            autoSave: true
        });

        function onPropertySelectValueChanged() {
            let propertySelectValue = that.propertySelect.getValue();
            var propertiesFromStore = propertyTemplateService.propertyTemplates.findWhere({
                id: propertySelectValue
            });
            if (propertiesFromStore) {
                // we cannot use model properties cause it's triggering change event that causes the form isDirty
                // flag to change to true and trigger the trackingWarnings
                that.requiresFormatter = propertiesFromStore.requiresFormatter;
                that.requiresParser = propertiesFromStore.requiresParser;

                propertyTemplateOverrides.process(propertiesFromStore);
            }
            if (that.templateModel.get("handler") === propertySelectValue) {
                that.propertiesModel.set(that.templateModel.get("properties"));
            } else {
                that.propertiesModel.clear({ silent: true });
                that.templateModel.clear({ silent: true });
            }

            that.ui.showHideProperties.hide();
            that.renderForm(that.propertiesModel, propertiesFromStore, propertySelectValue, true);
            that.propertySelect.showAdditionalAction(that._isAdditionalActionAvailable() && !!propertySelectValue);
        }

        that.propertySelect.on(that._UIControl.EVENT.VALUE_CHANGED, onPropertySelectValueChanged);

        function onAdditionalAction() {
            let selectedValue = that.propertySelect.getViewValue();
            copyPropertiesFrom(selectedValue, that.options.metaObjectId)
                .then(component => {
                    let propertiesFromStore = propertyTemplateService.propertyTemplates.findWhere({
                        id: selectedValue
                    });
                    let componentProperties = component.properties;
                    let propertiesFromStoreJson = propertiesFromStore.toJSON().propertyMap;
                    propertiesFromStoreJson.forEach(property => {
                        if (property?.type?.toLowerCase().indexOf("password") !== -1) {
                            growl.info(
                                `For security reasons, property "${property?.name}" was not copied. Please enter the value manually.`,
                                "Password not copied",
                                that.options.metaObjectId
                            );
                            componentProperties[property.name] = "";
                        }
                    });
                    that.propertiesModel.set(componentProperties);
                    that.renderForm(that.propertiesModel, propertiesFromStore, true);

                    return;
                    function isPassword(propertiesFromStore, propertyName) {
                        if (!propertiesFromStore) return;
                        let propertyMap = propertiesFromStore.get("propertyMap");
                        let passwordFound = false;
                        _.each(propertyMap, function(property) {
                            if (
                                propertyName === property.name &&
                                property.type.toLowerCase().indexOf("password") !== -1
                            ) {
                                passwordFound = true;
                            }
                        });
                        if (passwordFound) {
                            growl.info(
                                `For security reasons, property "${propertyName}" was not copied. Please enter the value manually.`,
                                "Password not copied",
                                that.options.metaObjectId
                            );
                        }
                        return passwordFound;
                    }

                    function copyProperty(control) {
                        let propertiesFromStore = propertyTemplateService.propertyTemplates.findWhere({
                            id: control.resourceNamespace
                        });
                        let property = null;
                        for (let p in component.properties) {
                            if (
                                component.properties.hasOwnProperty(p) &&
                                control.propertyName.toLowerCase() === p.toLowerCase() &&
                                !isPassword(propertiesFromStore, control.propertyName)
                            ) {
                                property = component.properties[p];
                                break;
                            }
                        }
                        if (!property) {
                            return;
                        }

                        control.view.setValue(property);
                        control.view.forceChanged();
                    }
                })
                .catch(() => {});
        }
        that.propertySelect.on("additional-action", onAdditionalAction);

        templateSelectForm.addRequiredControl(that.propertySelect, "handler", that.model.adapterType);
        var templateSelectionView = templateSelectForm.create();
        this.handlerRegion.show(templateSelectionView);
        onPropertySelectValueChanged();
        this.setValueFromView();
        that.trigger(this._UIControl.EVENT.VALUE_CHANGED, that.getValue());

        BaseControl.View.prototype.onRender.apply(this, arguments);
    },

    _isAdditionalActionAvailable: function() {
        return this.model && this.model.adapterType !== "process" && this.getEnabled();
    },

    onShow: function() {
        this.templateModel.on(
            "change",
            function() {
                this.setValueFromView();
            }.bind(this)
        );

        this.propertiesModel.on(
            "change",
            function(model, options) {
                if (!options.ignoreChange) {
                    this.templateModel.set("properties", this.propertiesModel.toJSON());
                }
            }.bind(this)
        );
        _.each(this.disallowedControls, control => {
            control.setEnabled(false);
            control.$el
                .closest(".field-row")
                .addClass("disabled")
                .prop("title", "Disabled due to License Restrictions.");
        });
    },

    _getLicenseByPropertyName: function(propertyName) {
        if (propertyName === "BiDirectionalMarkerTable") {
            return addonFeaturesKeys.BIDIRECTIONAL;
        }
        return undefined;
    },

    validate: async function() {
        const _this = this;
        if (_this.formComponent) {
            let errors = await _this.formComponent.validateForm();
            if (errors) {
                let dirtyFields = Object.keys(errors);
                return dirtyFields.length > 0 ? dirtyFields : false;
            }
        }
        return false;
    },

    getSelectedHandler: function() {
        return this.propertySelect.getValue();
    },

    getViewValue: function() {
        var value = this.templateModel.toJSON();
        if (!value.handler) {
            value.handler = this.propertySelect.getValue();
        }
        if (value && value.handler && !value.properties) {
            value.properties = {};
        }

        return value.handler ? value : {};
    },

    setViewValue: function() {},
    /**
     *
     * @param {*} propertyMap
     * @param {*} propertiesModel
     * @returns `{ propertyMapKey :propertyModelKey }[]`
     */
    _getPropertyKeyObjectForPropertyTemplateKey: function(propertyMap, propertiesModel) {
        const propertyModelMap = new Map();
        propertiesModel.keys().forEach(key => {
            propertyModelMap.set(key.toLowerCase(), key);
        });
        const propertyMapMap = new Map();
        propertyMap.forEach(property => {
            propertyMapMap.set(property.name, property.name.toLowerCase());
        });

        const resultObj = {};
        propertyMap.forEach(property => {
            const value = propertyMapMap.get(property.name);
            resultObj[property.name] = propertyModelMap.has(value) ? propertyModelMap.get(value) : property.name;
        });
        return resultObj;
    }
});

export default function(adapterType) {
    var Model = BaseControl.Model.extend({
        defaults: {
            adapterType: adapterType
        }
    });

    return _.extend({}, BaseControl, {
        Model: Model,
        View: View
    });
}
