import App from "app";
import WAHighChartsMixin from "app/components/dashboard/visualization/charts/heatmap-chart/mixins/heatmap-highcharts";
import "app/components/dashboard/visualization/charts/chart";

App.module("Chart.HeatMap", function (HeatMap, App, Backbone, Marionette, $, _) {
    HeatMap.View = App.Chart.Default.ChartView.extend({
        initialize: function () {
            _(this).extend(WAHighChartsMixin);
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
        },
    });
});
