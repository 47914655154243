import React from "react";
import automatedGroupStore from "../automated-app-group-details.store";
import { observer } from "mobx-react-lite";
import AppGroupConfirmationModal from "../automated-app-group-confirmation-modal";

const AutomatedAppGroupConfirmationModal: React.FunctionComponent = observer(() => {
    let appId = null;
    const currentFocusedApp = automatedGroupStore?.currentFocusedApp;
    if (currentFocusedApp === "IL") {
        appId = automatedGroupStore?.appIds[0];
    } else {
        appId = automatedGroupStore?.appIds[1];
    }
    function closeConfirmationModal() {
        automatedGroupStore.setIsConfirmationModalVisible(false);
    }
    if (!appId) {
        return;
    }
    return (
        <AppGroupConfirmationModal
            isVisible={automatedGroupStore.isConfirmationModalVisible}
            setisVisible={closeConfirmationModal}
            //@ts-ignore
            groupName={automatedGroupStore.groupName?.name}
            fullAppName={appId}
        />
    );
});
export default AutomatedAppGroupConfirmationModal;
