import App from "app";
import Tracker from "core/services/tracker/tracker";
import { SOURCE_LOCAL } from "../../../src/modules/message-log/message-log.store";
import { TRACKER_STRINGS } from "../../../core/services/tracker/constants";

var lastSourceId = null;

function tryToGetComponentFromURL() {
    let loc = window.location.hash;
    const FDRegex = /#\/flow\/([a-zA-Z0-9_-]*).([a-zA-Z0-9_-]*)\/*.*/gm;
    const DashboardRegex = /#\/dashboard\/([a-zA-Z0-9_-]*).([a-zA-Z0-9_-]*)\/*.*/gm;
    let m = FDRegex.exec(loc);
    if (m && m[1] && m[2]) {
        return `${m[1]}.APPLICATION.${m[2]}`;
    }

    m = DashboardRegex.exec(loc);
    if (m && m[1] && m[2]) {
        return `${m[1]}.DASHBOARD.${m[2]}`;
    }
}

function tryToGetApplicationFromURL() {
    let loc = window.location.hash;
    const FDRegex = /#\/flow\/([a-zA-Z0-9_-]*).([a-zA-Z0-9_-]*)\/.*/gm;
    let m = FDRegex.exec(loc);
    if (m && m[1] && m[2]) {
        return `${m[1]}.APPLICATION.${m[2]}`;
    }
}

function _addLogEntry(severity, description, title, sourceId, componentName, applicationName, button, column) {
    setTimeout(function() {
        if (lastSourceId && lastSourceId === sourceId) {
            return;
        }
        if (!componentName) {
            componentName = tryToGetComponentFromURL();
        }
        if (!applicationName) {
            applicationName = tryToGetApplicationFromURL();
        }
        lastSourceId = sourceId;
        App.commands.execute("errorconsole:notify", {
            title: title,
            message: description?.message ? description.message : description,
            severity: severity,
            shouldGrowl: true,
            hasGrowled: false,
            componentName,
            applicationName,
            source: SOURCE_LOCAL,
            button: button,
            column: column
        });
        lastSourceId = null;
    }, 250);
}

export default {
    /**
     * Displays an info.
     * @param {String} description
     * @param {String} [title] - an optional title
     */
    info: function(description, title, componentName, applicationName, button, column = false) {
        _addLogEntry("info", description, title || "Info", this.id, componentName, applicationName, button, column);
    },

    /**
     * Displays a success.
     * @param {String} description
     * @param {String} [title] - an optional title
     */
    success: function(description, title, componentName, applicationName) {
        Tracker.getInstance().track(TRACKER_STRINGS.schema.success, {
            id: applicationName,
            event: "Success:" + title,
            message: description,
        });
        _addLogEntry("success", description, title || "Success", this.id, componentName, applicationName);
    },

    /**
     * Displays a warning.
     * @param {String} description
     * @param {String} [title] - an optional title
     */
    warning: function(description, title, componentName, applicationName) {
        Tracker.getInstance().track(TRACKER_STRINGS.schema.warning,{
            id: applicationName,
            event: "Warning:" + title,
            message: description
        });
        _addLogEntry("warning", description, title || "Warning", this.id, componentName, applicationName);
    },

    /**
     * Displays an error.
     * @param {String} description
     * @param {String} [title] - an optional title
     */
    error: function(description, title, componentName, applicationName) {
        Tracker.getInstance().track(TRACKER_STRINGS.schema.error,{
            id: applicationName,
            event: "Error:" + title,
            message: description
        });
        _addLogEntry("error", description, title || "Error", this.id, componentName, applicationName);
    },

    /**
     * Add a log entry to the console log, does not display a growl
     * @param {String} description
     * @param {String} [title] - an optional title
     */
    logError: function(description, title, componentName, applicationName) {
        _addLogEntry("error", description, title || "Error", this.id, componentName, applicationName);
    }
};
