import App from "app";
import "app/components/dashboard/visualization/charts/chart";
import UIControl from "app/components/common/editor/control/ui-control";

App.module("Chart.SelectVisualization", function (Chart, App, Backbone, Marionette, $, _) {
    Chart.View = App.Chart.Default.ChartView.extend({
        className: "chart select-visualization",

        regions: _.extend(
            {
                dropdown: ".visualization-select",
            },
            App.Chart.Default.ChartView.prototype.regions
        ),

        template: _.template("<div class='visualization-select'></div>"),

        setConfiguration: function (config) {
            this.chartConfig = config;
        },

        serializeData: function () {
            return this.chartConfig;
        },

        renderChart: function () {
            if (!this.chartConfig) {
                return;
            }

            if (!this._selectInitialized) {
                this._selectInitialized = true;

                let options = {
                    allowClear: true,
                    hideSearch: false,
                    width: "100%",
                };
                const config = this.model.get("config");
                if (config && config.get("placeholder")) {
                    options.placeholder = config.get("placeholder");
                }
                this._selectControl = UIControl.Select([], options).create();
                this._selectControl.$el.addClass("visualization-select");
                this.getRegion("dropdown").show(this._selectControl);

                this.listenTo(
                    this._selectControl.model,
                    "change:value",
                    function (selectModel) {
                        var config = this.model.get("config");
                        if (config.get("drilldown").show_drilldown) {
                            const show_drilldown = config.get("drilldown").show_drilldown;
                            const pageparams = config.get("drilldown").pageparams;
                            const page = config.get("drilldown").page;
                            function getValue() {
                                if (!selectModel.value) {
                                    return null;
                                }
                                let selectedItem = selectModel.dataSource.filter((item) => {
                                    return item.id === selectModel.value;
                                });
                                if (selectedItem.length === 0) {
                                    return null;
                                }
                                return selectedItem[0].ref;
                            }

                            this.handleDrillDown(getValue(), page, show_drilldown, pageparams);
                        }
                    }.bind(this)
                );
            }

            this.model.config.lastRendered = new Date().getTime();

            this._setSelectData();
        },

        _setSelectData: function () {
            const model = this.model;
            const dataSource = model.get("dataSource");
            if (!dataSource) {
                return;
            }
            const data = dataSource.get("data");
            if (!data) {
                return;
            }
            const config = model.get("config");
            if (!config) {
                return;
            }
            const valueField = config.get("valueField");
            if (!valueField) {
                return;
            }
            this._valueField = valueField;

            let textField = config.get("textField");
            if (!textField) {
                textField = valueField;
            }
            let newDataSource = data.map((item) => {
                return {
                    id: item[valueField],
                    text: item[textField],
                    ref: item,
                };
            });
            newDataSource = _.sortBy(newDataSource, "text");
            this._selectControl.setDataSource(newDataSource);
        },
    });
});
