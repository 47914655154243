import React, { useCallback } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { StriimButton } from "@striim/striim-ui";
import NamespaceSelect from "../namespace-select/namespace-select";
import NamespaceValidation from "../namespace-select/namespace.validation";
import FormikField from "src/generic/formik-field";
import usePagesStore from "src/stores/pages/usePagesStore";
import useDashboardsStore from "src/stores/dashboards/useDashboardsStore";
import App from "app";
import useNamespaceStore from "src/stores/namespaces/useNamespaceStore";
import growl from "app/components/common/growl";
import namespaceFactory from "app/components/createapp/namespace-factory";
import { getNameValidation } from "src/modules/apps/pages/create-app-name-app-page/name.validation";
import Tracker from "core/services/tracker/tracker";
import useStyles from "./add-new-dashboard.styles";
import navigateTo from "src/navigate-to";
import { Box, Grid } from "@mui/material";
import { TRACKER_STRINGS } from "../../../../../../../../../core/services/tracker/constants";

export const getValidationSchema = (createFromScratch, entityType) =>
    Yup.object({
        name: getNameValidation(createFromScratch, entityType),
        nsName: NamespaceValidation
    });

const AddNewDashboard = () => {
    const pagesStore = usePagesStore();
    const dashboardStore = useDashboardsStore();
    const namespaceStore = useNamespaceStore();
    const classes = useStyles();
    const createFromScratch = true;
    const entityType = "DASHBOARD";

    const handleSubmit = useCallback(
        async ({ nsName, name }) => {
            const dashboards = await dashboardStore.dashboardsFetch();
            const duplicatedDashboard = dashboards.filter(
                x => x.name.toLowerCase() === name.toLowerCase() && x.nsName === nsName
            );
            if (duplicatedDashboard.length > 0) {
                growl.error(`Dashboard '${nsName}.${name}' already exists`, "Error");
                return;
            }

            const page = pagesStore.createPageObject({ nsName });
            try {
                await namespaceFactory.create(nsName);
                await namespaceStore.namespaceSet(nsName);
                await pagesStore.pageCreate(page);
                await dashboardStore.dashboardCreate(
                    {
                        nsName,
                        name
                    },
                    page
                );

                navigateTo.DashboardEdit({
                    nsName,
                    name,
                    pages: [{ name: page.name }]
                });

                Tracker.getInstance().track(TRACKER_STRINGS.schema.dashboard, {
                    id: `${nsName}.${name}`,
                    event: TRACKER_STRINGS.eventClicked.dashboard,
                    buttonClicked:"Create Dashboard",
                });
            } catch (e) {
                console.error(e);
            }
        },
        [pagesStore, dashboardStore, namespaceStore]
    );

    return (
        <Formik
            initialValues={{
                name: "",
                nsName: "admin"
            }}
            initialTouched={{
                name: true
            }}
            validationSchema={getValidationSchema(createFromScratch, entityType)}
            validateOnChange
            onSubmit={handleSubmit}
        >
            <Box component={Form} className={classes.createDashboardFormContainer}>
                <Box
                    required
                    component={FormikField}
                    name="name"
                    dataTestId="data-test-id-dashboard-name-input"
                    label="Dashboard Name"
                />
                <Box sx={{ my: 2 }} />
                <NamespaceSelect required name="nsName" doNotCreateNamespaces />
                <Grid container item justifyContent="flex-end">
                    <Box
                        className={classes.createDashboardBtn}
                        component={StriimButton}
                        type="submit"
                        variant="primary"
                        data-test-id="submit-dashboard-button"
                    >
                        Create Dashboard
                    </Box>
                </Grid>
            </Box>
        </Formik>
    );
};

AddNewDashboard.propTypes = {};

export default AddNewDashboard;
