import utils from "core/utils";
import * as Yup from "yup";

export const getSysAlertComponentType = objName => {
    const { 0: type } = objName.match(/\w+/g);
    return `${utils.capitalize(type)}s`;
};

export const getTab = alert => {
    const { isSysDefined, objectName } = alert;
    if (isSysDefined) {
        return "smart_alerts";
    } else if (objectName.indexOf(".SERVER") !== -1) {
        return "nodes";
    } else if (objectName.indexOf(".APPLICATION") !== -1) {
        return "apps";
    } else {
        return "components";
    }
};

export const ALERT_TYPE = {
    WEB: "WEB",
    EMAIL: "EMAIL",
    SLACK: "SLACK",
    TEAMS: "TEAMS"
};

export const emailValidation = Yup.array()
    .transform(function(value, originalValue) {
        if (this.isType(value) && value !== null) {
            return value;
        }
        return originalValue ? originalValue.split(",") : [];
    })
    .test({
        name: "emails",
        test: function(value) {
            const isValid =
                value &&
                value.every(val =>
                    Yup.string()
                        .email()
                        .required()
                        .isValidSync(val.trim())
                );
            return isValid;
        },
        message: "Please specify a valid email"
    });
