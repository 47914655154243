export const styles = {
    gridContainerBase: {
        margin: 0,
        paddingTop: 1,
        flexWrap: "nowrap",
        columnGap: 2,
        wordBreak: "break-word"
    },
    leftPositionedIcon: {
        margin: "auto",
        justifyContent: "start"
    },
    rightPositionedIcon: {
        margin: "auto",
        justifyContent: "space-between",
        flexDirection: "row-reverse"
    },
    gridstatus: {
        position: "relative"
    },
    gridItemBase: {
        display: "flex",
        flexDirection: "column"
    },
    typography: {
        color: ({ palette }) => palette.greyscale[900]
    },
    circularProgressBase: {
        position: "absolute",
        width: 100
    },
    outerCircularProgress: {
        color: ({ palette }) => palette.greyscale[300]
    },
    svgIcon: {
        color: "transparent",
        height: 20,
        width: 18
    }
};

export default styles;
