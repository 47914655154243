import React, { useState } from "react";

import PanelHeaderControls from "./panel-header-controls";
import { Grid } from "@mui/material";
import { ImportanceLevels } from "../../utils";

export const TABS = ["1 HOUR", "24 HOURS"];

const PanelHeaderControlsComponent = args => {
    const [panelWidth, setPanelWidth] = useState("408px");
    const [selectedSentinel, setSelectedSentinel] = useState<{ label: string; value: string } | null>(null);
    const [selectedTab, setSelectedTab] = useState<string>(TABS[0]);
    const [selectedSDI, setSelectedSDI] = useState<{ name: string; importanceLevel: ImportanceLevels } | null>(null);
    const [isPanelExpanded, setIsPanelExpanded] = useState<boolean>(false);

    const handleTabChange = value => {
        setSelectedTab(value);
    };

    return (
        <>
            <Grid container justifyContent={"flex-end"} width={panelWidth} mb={4}>
                <PanelHeaderControls
                    {...args}
                    selectedTab={selectedTab}
                    handleTabChange={handleTabChange}
                    selectedSentinel={selectedSentinel}
                    setSelectedSentinel={setSelectedSentinel}
                    selectedSDI={selectedSDI}
                    setSelectedSDI={setSelectedSDI}
                    isPanelExpanded={isPanelExpanded}
                    onToggleFullScreen={() => {
                        setIsPanelExpanded(value => !value);
                        setPanelWidth(value => (value === "408px" ? "100%" : "408px"));
                    }}
                />
            </Grid>
            <div
                onClick={() => {
                    setSelectedSDI({ name: "Credit Card Number", importanceLevel: "HIGH" });
                }}
            >
                Credit Card Number
            </div>
            <div
                onClick={() => {
                    setIsPanelExpanded(true);
                    setPanelWidth("100%");
                }}
            >
                Expand
            </div>
        </>
    );
};

export default {
    title: "AI Insights/Sentinel Live Monitor/Panel Header Controls",
    component: PanelHeaderControlsComponent,
    tags: ["autodocs"],
    args: {
        onSentinelClick: value => {
            console.log(value);
        },
        selectedSentinel: "All",
        sentinels: [
            {
                name: "admin.Sentinel1",
                isDiscoveryDisabled: false
            },
            {
                name: "admin.Sentinel2",
                isDiscoveryDisabled: true
            }
        ],
        isPanelExpanded: true,
        showBottomBorder: true,
        selectedDateRange: null
    },
    argTypes: {
        eventsTagged: { control: "number" },
        totalEvents: { control: "number" },
        taggingEnabledSentinels: { control: "object" },
        taggingDisabledSentinels: { control: "object" },
        showBottomBorder: { control: "boolean" },
        selectedSentinel: { control: "text" },
        sentinels: { control: "object" },
        isPanelExpanded: { control: "boolean" },
        selectedDateRange: { control: "text" }
    }
};

export const Default = {};
