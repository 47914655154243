/**
 *
 * Note: All subscribers in this directory should implement this interface. This interface basically outlines all the methods
 * that need to be overridden by the subscriber to operate properly. As of now, track() is the only method.
 */
var baseSubscriber = {};

baseSubscriber.initialize = function () {
    throw new Error("Initialize needs to be overridden");
};

baseSubscriber.identify = function () {
    throw new Error("Identify needs to be overridden");
};

baseSubscriber.track = function (event, extra_params) {
    throw new Error("Track method needs to be overridden");
};

baseSubscriber.register = function (params) {
    throw new Error("Register method needs to be overriden");
};

baseSubscriber.setUserDetails = function (params) {
    throw new Error("SetUserDetails method needs to be overriden");
};

baseSubscriber.reset = function (params) {
    throw new Error("Reset method needs to be overriden");
};

export default baseSubscriber;
