import React from "react";
import { Grid } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";

import ToggleIcon from "src/generic/icon/manage-striim/toggle.svg";
import PopoutIcon from "src/generic/icon/manage-striim/popout.svg";
import { styles } from "./help-guide.styles";

interface HelpGuideProps {
    content: any;
    helpUrl: string;
    isCollapsed?: boolean;
    setIsCollapsed?: (value: boolean) => void;
    showToggleButton?: boolean;
}

const HelpGuide = ({ content, helpUrl, isCollapsed, setIsCollapsed, showToggleButton = true }: HelpGuideProps) => {
    const isCurrentViewFD = !showToggleButton;
    return (
        <Grid
            display="flex"
            justifyContent="flex-start"
            width={isCurrentViewFD ? 390 : isCollapsed ? 68 : 390}
            sx={styles.sidebarContainer}
            gap={1}
        >
            {!isCollapsed && (
                <Grid item flex={1}>
                    <Grid item sx={styles.content}>
                        {content}
                    </Grid>
                </Grid>
            )}
            <Grid item display="flex" flexDirection="column">
                {showToggleButton && (
                    <Grid sx={{ ...styles.icon }}>
                        <ToggleIcon style={{ ...styles.icon }} onClick={() => setIsCollapsed(value => !value)} />
                    </Grid>
                )}
                <Grid sx={{ ...styles.icon }}>
                    <a href={helpUrl} target="_blank" style={{ color: "#F7FBFF" }}>
                        <PopoutIcon style={{ ...styles.icon }} />
                    </a>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HelpGuide;
