import React from "react";
import TableProgressTable, { AdapterIOTimes } from "../table-progress";
import { Box } from "@mui/material";
import { TotalStats } from "./total-stats/total-stats";
import AppRateGraph from "../Components/app-rate/app-rate";
import IlTotalProgress from "../Components/stacked-linear-progress/stacked-linear-progress";
import SchemaCreationPage from "../Components/schema-creation-page/schema-creation-component";
import { observer } from "mobx-react";
import LoadingIndicator from "../../../generic/loading-indicator";
import statusManagement from "../../../status-management";
import { Store } from "../table-progress-types";
import ProgressAppErrorsComponent from "../Components/progress-app-error-component/progress-app-error-component";

const styles = {
    section: {
        padding: 3,
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    }
};

const Section = ({ children }) => <Box sx={styles.section}>{children}</Box>;
type Props = {
    appStatus: string;
    store: Store;
    appType: "IL" | "CDC";
    appId: string;
    closeModal: () => void;
};
const TableProgressContent: React.FunctionComponent<Props> = observer(
    ({ appId, appStatus, store, appType, closeModal }) => {
        if (store?.isLoading) {
            return <LoadingIndicator isGlobal={false} />;
        }
        if (appType === "IL" && store?.ilInfo.schemaCreationDetails?.status === "Pending") {
            return <SchemaCreationPage appStatus={appStatus} isLite={false} />;
        }
        return (
            <Box>
                {appType === "IL" && statusManagement.isApplicationRunning(appStatus) && (
                    <Box sx={{ padding: 2 }}>
                        <IlTotalProgress progressText="INITIAL LOAD: RUNNING" appStatus={appStatus} />
                    </Box>
                )}
                {statusManagement.isApplicationCrash(appStatus) && (
                    <ProgressAppErrorsComponent closeModal={closeModal} appId={appId} />
                )}
                <TotalStats store={store} appStatus={appStatus} appType={appType} />
                <Section>
                    <AppRateGraph appStatus={appStatus} store={store} />
                </Section>
                <Section>
                    <AdapterIOTimes />
                </Section>
                <Section>{appType && <TableProgressTable appType={appType} />}</Section>
            </Box>
        );
    }
);

export default TableProgressContent;
