import utils from "core/utils";
import allowedTargetNodeTypesResolver from "./allowedTargetNodeTypesResolver";
import newComponentItem from "./newComponentItem";
import newComponentTemplate from "./templates/newComponentTemplate.html";

var NewComponentView = Backbone.Marionette.CompositeView.extend({
    template: _.template(newComponentTemplate),
    tagName: "div",
    className: "new-component",
    childViewContainer: "div.available-nodes",
    childView: newComponentItem.View,

    shouldCloseOnStatusChange: true,

    ui: {
        myObjectsSeparator: "div.new-component-separator"
    },

    initialize: function() {
        var _this = this;

        this.collection = new newComponentItem.Model.Collection([
            {
                id: "cq",
                icon: "icon-btn-cq",
                metaObject: {
                    type: utils.ENTITIES.CQ
                },
                displayName: "CQ",
                isEnabled: true
            },
            {
                id: "router",
                icon: "icon icon-btn-transformation",
                metaObject: {
                    type: utils.ENTITIES.ROUTER
                },
                displayName: "Router",
                isEnabled: true
            },
            {
                id: "sentinel",
                icon: "icon-sentinel",
                metaObject: {
                    type: utils.ENTITIES.SENTINEL
                },
                displayName: "Sentinel",
                isEnabled: true
            },
            {
                id: "target",
                icon: "icon-btn-target",
                metaObject: {
                    type: utils.ENTITIES.TARGET
                },
                displayName: "Target",
                isEnabled: true
            },
            {
                id: "store",
                icon: "icon-btn-store",
                metaObject: {
                    type: utils.ENTITIES.WACTIONSTORE
                },
                displayName: "WActionStore",
                isEnabled: true
            },
            {
                id: "window",
                icon: "icon-btn-window",
                metaObject: {
                    type: utils.ENTITIES.WINDOW
                },
                displayName: "Window",
                isEnabled: true
            }
        ]);

        this.on("childview:new-component-selected", function(model, dataType) {
            _this.trigger("new-component-selected", dataType);
        });
    },

    toggleSeparatorVisible: function(isVisible) {
        if (isVisible) {
            this.ui.myObjectsSeparator.css("display", "flex");
        } else {
            this.ui.myObjectsSeparator.hide();
        }
    },

    updateCollection: function(nodeType) {
        var allowedTypes = allowedTargetNodeTypesResolver.getAllowedChildren(nodeType);

        var hasEnabledCollectionElements = false;

        this.collection.forEach(function(node) {
            node.isEnabled = allowedTypes.indexOf(node.metaObject.type) >= 0;
            hasEnabledCollectionElements |= node.isEnabled;
        });

        this.toggleSeparatorVisible(hasEnabledCollectionElements);
    }
});

export default NewComponentView;
