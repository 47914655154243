import React, { useContext, useEffect, useRef, useState } from "react";
import { StriimButton, StriimInputField, StriimTheme, StriimTypography, StriimTooltip } from "@striim/striim-ui";
import Grid from "@material-ui/core/Grid";
import useStyles from "./console.styles";
import Container from "@material-ui/core/Container";
import { Controlled as CodeMirrorView } from "react-codemirror2";
import { Pos } from "codemirror";
import "cm/mode/sql/sql";
import "cm/addon/edit/matchbrackets";
import "cm/addon/edit/closebrackets";
import "cm/addon/scroll/simplescrollbars";
import "cm/addon/comment/comment";
import "cm/addon/comment/continuecomment";
import "cm/addon/hint/show-hint";
import "cm/addon/hint/sql-hint";
import api from "../../../core/api/api";
import App from "../../../app";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useReactHeader } from "../../hooks/useReactHeader";
import { ReactContext } from "../rootRouter";
import LoadingIndicator from "../../generic/loading-indicator";
import { useTheme } from "@mui/material/styles";

let buffer = "";

const CMBox = ({ consoleResponse }) => {
    const classes = useStyles();
    const editor = useRef();
    const wrapper = useRef();
    const editorWillUnmount = () => {
        if (editor.current) editor.current.display?.wrapper?.remove();
    };
    useEffect(() => {
        if (document.getElementsByClassName("CodeMirror-scroll")[0]) {
            document.getElementsByClassName("CodeMirror-scroll")[0].scrollTo(0, 1000000);
        }
    }, [consoleResponse]);

    return (
        <CodeMirrorView
            ref={wrapper}
            value={consoleResponse}
            editorDidMount={e => (editor.current = e)}
            editorWillUnmount={editorWillUnmount}
            className={classes.codeMirrorContainer}
            onBeforeChange={() => {}}
            autoSave={true}
            options={{
                mode: "text/x-tql"
            }}
            onChange={(editor, data) => {
                if (!data.text) return;
                data.text.forEach((val, i) => {
                    if (val.indexOf("<mark>") !== -1) {
                        let markOptions = {
                            css: "background-color: #F9A825"
                        };
                        let hideMarkTagOption = {
                            css: "display: none"
                        };
                        let markTagStart = Pos(i, val.indexOf("<mark>"));
                        let markTagEnd = Pos(i, val.indexOf("<mark>") + 6);
                        let closeMarkTagStart = Pos(i, val.indexOf("</mark>"));
                        let closeMarkTagEnd = Pos(i, val.indexOf("</mark>") + 7);

                        editor.markText(markTagEnd, closeMarkTagStart, markOptions);
                        editor.markText(markTagStart, markTagEnd, hideMarkTagOption);
                        editor.markText(closeMarkTagStart, closeMarkTagEnd, hideMarkTagOption);
                    }
                    if (val.indexOf("-> SUCCESS") !== -1) {
                        let markOptions = {
                            css: "color: #0D874C; font-weight: 600"
                        };
                        let start = Pos(i, 0);
                        let end = Pos(i, val.length);

                        editor.markText(start, end, markOptions);
                    }
                    if (val.indexOf("-> FAILURE") !== -1) {
                        let markOptions = {
                            css: "color: #DD3711; font-weight: 600"
                        };
                        let start = Pos(i, 0);
                        let end = Pos(i, val.length);

                        editor.markText(start, end, markOptions);
                    }
                });
            }}
            autoScroll={true}
        />
    );
};

const ConsoleLayout = ({}) => {
    useReactHeader({ title: "Striim Console" });
    const themeV5 = useTheme();
    const classes = useStyles(themeV5);
    const [consoleResponse, setConsoleResponse] = useState("");
    const [statement, setStatement] = useState("");
    const [nsName, setNsName] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const { loading: pageLoading } = useContext(ReactContext);

    const open = Boolean(anchorEl);
    const [history, setHistory] = useState([]);
    let commandBox = useRef(null);
    let historyFromStore = sessionStorage.getItem("striim-command-history");
    let ctrlPressed = false;
    let enterPressed = false;

    /**
     * Initialize
     */
    useEffect(() => {
        if (pageLoading) {
            return;
        }
        initialize();
        return () => {
            // Clean up the subscription
            console.log("Clear Venting..");
            App.vent.off("api:CONSOLE_ON_UI");
        };
    }, [pageLoading]);

    const listenToVent = msg => {
        if (
            msg[0].indexOf("Processing -") !== -1 ||
            msg[0].indexOf("Elapsed time:") !== -1 ||
            msg[0].indexOf("-> SUCCESS") !== -1
        ) {
            msg = "-- " + msg;
        }
        buffer += msg;
        setConsoleResponse(buffer);
    };

    const initialize = async () => {
        buffer = "";
        if (historyFromStore) {
            setHistory(JSON.parse(historyFromStore));
        }
        App.vent.off("api:CONSOLE_ON_UI");
        App.vent.on("api:CONSOLE_ON_UI", listenToVent);

        let ns = await api.getConsoleNamespace();
        setNsName(ns);
    };

    /**
     * Query Key handler ( Ctrl + Enter )
     * @param key
     */
    const setKeyStroke = key => {
        if (key === "Control" || key === "Meta") {
            ctrlPressed = true;
        } else if (key === "Enter") {
            enterPressed = true;
        } else {
            ctrlPressed = false;
            enterPressed = false;
        }

        if (ctrlPressed && enterPressed) {
            ctrlPressed = false;
            enterPressed = false;
            executeStatement();
        }
    };

    /**
     * History Menu details
     * @param event
     */
    const ITEM_HEIGHT = 48;

    const openHistoryDialog = event => {
        setAnchorEl(event.currentTarget);
    };
    const closeHistoryDialog = () => {
        setAnchorEl(null);
    };

    /**
     * Statement Executor
     * @returns {Promise<void>}
     */
    const executeStatement = async () => {
        if (!statement) {
            buffer += "-- Please enter a command before executing \n";
            setConsoleResponse(buffer);
            return;
        }
        setIsProcessing(true);
        if (statement.charAt(statement.length - 1) !== ";") {
            setStatement(statement + ";");
        }
        if (history.indexOf(statement) === -1) {
            history.push(statement);
            sessionStorage.setItem("striim-command-history", JSON.stringify(history));
        }
        await api.execute(statement);

        setConsoleResponse(buffer);
        let ns = await api.getConsoleNamespace();
        setNsName(ns);
        setStatement("");
        setIsProcessing(false);
        if (commandBox != null) {
            commandBox.current.autofocus = true;
            commandBox.current.focus();
        }
    };

    const isExecuteEnabled = () => {
        return statement && statement.trim();
    };

    if (pageLoading) {
        return <LoadingIndicator />;
    }

    /**
     * View
     */
    return (
        <StriimTheme>
            <Container maxWidth={false} className={classes.output}>
                <CMBox consoleResponse={consoleResponse} />
            </Container>
            <Container maxWidth={false}>
                <LinearProgress hidden={!isProcessing} />
            </Container>

            <Container maxWidth={false}>
                <StriimTypography variant="h4">{`Namespace: W(${nsName})`}</StriimTypography>
                <Grid container className={classes.actionsContainer}>
                    <Grid
                        item
                        xs={12}
                        className={classes.input}
                        data-test-id={isProcessing ? "command-executor-disabled" : "command-executor-enabled"}
                    >
                        <StriimInputField
                            id="console-statement-input"
                            placeholder="Enter Statements to execute. Press Ctrl+Enter or CMD+Enter to execute"
                            multiline
                            rows={5}
                            value={statement}
                            onChange={(val: React.SetStateAction<string>) => {
                                setStatement(val);
                            }}
                            inputRef={commandBox}
                            InputProps={{
                                onKeyDown: e => {
                                    setKeyStroke(e.key);
                                }
                            }}
                            disabled={isProcessing}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StriimButton
                            variant="primary"
                            onClick={executeStatement}
                            disabled={!isExecuteEnabled() || isProcessing}
                            data-testid="execute-command"
                            className={classes.execButton}
                        >
                            Execute
                        </StriimButton>
                        <StriimButton
                            variant="secondary"
                            onClick={openHistoryDialog}
                            disabled={isProcessing}
                            data-testid="show-history"
                        >
                            History
                        </StriimButton>
                    </Grid>
                </Grid>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={closeHistoryDialog}
                    transformOrigin={{ vertical: "bottom", horizontal: "left" }}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "80ch"
                        }
                    }}
                >
                    {history.length === 0 ? (
                        <div style={{ padding: 8 }}>Start executing commands to save to history</div>
                    ) : (
                        history.map(option => (
                            <MenuItem
                                className="console--history-entry"
                                key={option}
                                onClick={() => {
                                    closeHistoryDialog();
                                    setStatement(option);
                                }}
                            >
                                <StriimTooltip title={option}>
                                    <StriimTypography variant="body4" className={classes.historyList}>
                                        {option}
                                    </StriimTypography>
                                </StriimTooltip>
                            </MenuItem>
                        ))
                    )}
                </Menu>
            </Container>
        </StriimTheme>
    );
};

export default ConsoleLayout;
