import App from "app";
import Backbone from "backbone";
import _ from "underscore";
import dateLib from "core/utils/date-utils";
import moreDetailsTemplate from "../templates/lineageMetadataModalTemplate.html";
import "highcharts";
import NestedTypes from "backbone.nestedtypes";

var Table = App.module("Chart.Table");

var MetadataTableView = Backbone.Marionette.LayoutView.extend({
    template: _.template("<div class='table'></div>"),
    regions: {
        table: ".table"
    },
    modelEvents: {
        change: "update"
    },
    columns: [],

    initialize: function(options) {
        this.metadataManager = options.metadataManager;
        this.setColumns(this.metadataManager.columns);
    },

    onRender: function() {
        var that = this;
        this.interval = setInterval(function() {
            that.fetchDataAndRenderTable();
        }, 5000);
        that.fetchDataAndRenderTable();
    },

    onDestroy: function() {
        clearInterval(this.interval);
    },

    setColumns: function(columns) {
        columns = columns.filter(column => !column.visibleFor || column.visibleFor === this.metadataManager.type);
        columns.forEach(function(column) {
            column.drilldown = {
                show_drilldown: false
            };
        });   

        this.columns = columns;
    },

    fetchDataAndRenderTable: function() {
        var formatData = function(data) {
            if (_.isArray(data)) {
                var i = 1;
                _.each(data, function(entry) {
                    entry.rowID = i;
                    if (!entry.numberOfEvents) {
                        entry.numberOfEvents = "";
                    }
                    if (entry.externalFileCreationTime) {
                        entry.externalFileCreationTime = dateLib(entry.externalFileCreationTime).format(
                            "DD MMM YY h:mm:ss a "
                        );
                    } else {
                        entry.externalFileCreationTime = "Fetching…";
                    }
                    if (entry.firstEventTimestamp) {
                        entry.firstEventTimestamp = dateLib(entry.firstEventTimestamp).format("DD MMM YY h:mm:ss a ");
                    } else {
                        entry.firstEventTimestamp = "Fetching…";
                    }
                    if (entry.lastEventTimestamp) {
                        entry.lastEventTimestamp = dateLib(entry.lastEventTimestamp).format("DD MMM YY h:mm:ss a ");
                    } else {
                        entry.lastEventTimestamp = "Fetching…";
                    }
                    i++;
                });
            }

            return data;
        };
        var id = this.model.get("nsName") + "." + this.model.get("name");
        var that = this;

        this.metadataManager
            .getMetadata(id, this.model.get("type"))
            .then(function(data) {
                data = formatData(data);
                that.update(data);
            })
            .fail(function(/*e*/) {
                that.update([]); // show empty table.
            });
    },

    update: function(data) {
        var tableView;
        if (this.table.currentView) {
            tableView = this.table.currentView;
            tableView.chartConfig.config.data = data;
            tableView.render();
        } else {
            var TableModel = NestedTypes.Model.extend({
                defaults: {
                    config: {}
                }
            });

            var TBodyEmptyView = Backbone.Marionette.ItemView.extend({
                template: _.template("No " + this.metadataManager.featureName + " found for this component"),
                className: "tbody-empty-view"
            });

            tableView = new Table.View({
                model: new TableModel(),
                emptyView: TBodyEmptyView,
                columnWidths: [50] // first alone at 50
            });

            tableView.chartConfig = {
                config: {
                    show_headers: true,
                    pagesize: 8,
                    columns: this.columns,
                    data: data,
                    defaultSortOrder: "desc"
                }
            };

            this.table.show(tableView);
        }
    },

    setParentHeight: function(recordLength) {
        if (recordLength > 0) {
            var parentHeight = 35 * recordLength + 45;
            this.$el.parent().css("min-height", parentHeight);
        }
    }
});

var module = {};

module.View = App.FormMixinDialog.View.extend({
    template: _.template(moreDetailsTemplate),
    seriesToRenderInThisAdapter: null,
    regions: {
        content: ".modal-body .inner",
        table: ".modal-body .inner .metadata-table"
    },
    ui: {
        featureNameElement: "#feature-name"
    },
    triggers: {
        "click .controls a.submit": "modal:close"
    },
    initialize: function(options) {
        this.metadataManager = options.metadataManager;

        App.FormMixinDialog.View.prototype.initialize.apply(this, arguments);
    },
    onRender: function() {
        this.ui.featureNameElement.html(this.metadataManager.featureName);
        this.getRegion("table").show(
            new MetadataTableView({
                model: this.model,
                metadataManager: this.metadataManager
            })
        );
    }
});

export default module;
