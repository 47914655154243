import NestedTypes from "backbone.nestedtypes";
import $ from "jquery";
import "./templates/newComponentTemplate.html";

var NewComponentItemModel = NestedTypes.Model.extend({
    defaults: {
        id: String,
        icon: String,
        metaObject: Object,
        displayName: String,
        isEnabled: Boolean.value(true)
    }
});

var NewComponentView = Backbone.Marionette.ItemView.extend({
    template: _.template(
        '<span class="icon {{icon}}"></span><span class="title"><span>{{displayName}}</span> component</span>'
    ),
    tagName: "div",
    className: "node enabled",

    initialize: function() {
        var _this = this;
        this.listenTo(this.model, "change:isEnabled", function(model, isEnabled) {
            _this.$el.toggleClass("enabled", isEnabled);
        });
    },

    onRender: function() {
        var _this = this;

        this.$el.data("type", this.model.metaObject.type);

        this.$el.click(function() {
            if (!_this.model.isEnabled) {
                return;
            }
            _this.trigger("new-component-selected", $(this).data("type"));
        });
    }
});

export default {
    Model: NewComponentItemModel,
    View: NewComponentView
};
