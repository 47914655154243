/*
 Dropdown Menu
 Wraps tooltipster.js

    Usage:
        var dropdown = new Dropdown({
            option: foo,
            option: bar,
            etc.
        });

    Available methods:
        dropdown.show().then(callback);
        dropdown.hide().then(callback);
        dropdown.destroy();

    Options:
        // Should the dropdown automatically hide if the user clicks away? (Usually this is a good idea)
        autohide: true,

        // What user action should trigger the dropdown -- click or hover
        action: 'click',

        // A DOM element that serves as the trigger to open/close the dropdown. If a user clicks this, the dropdown will open/close. Will take care of adding a class of "active" to the trigger element when the dropdown opens.
        // Specifying this is optional. You can always define your own opening/closing functionality by manually  calling .show() or .hide() and utilizing on_show and on_hide callbacks.
        trigger: $('#menu-button'),

        // CSS classes to add to the dropdown, separated by a space (you will likely want to style your dropdown)
        classes: 'my-dropdown foo-bar',

        // Where to show the dropdown
        // Choose from bottom-right, bottom-left, or right-top
        position: 'bottom-left',

        // Animation to open the dropdown. Choose from 'fade' or 'slip'
        // 'slip' is the default effect used in the UI (see header dropdowns for example)
        animation: 'slip',

        // How fast to animate
        speed: 100,

        // The View that "owns" this Dropdown (workaround for the fact that a Dropdown cannot be treated like a region).
        // When this View is destroyed, the dropdown should also be destroyed.
        parentView: someView,

        // The View to show in the dropdown
        content: new MyView(),

        // Callbacks on show and on hide
        on_show: function() { },
        on_hide: function() { }
*/

import $ from "jquery";
import _ from "underscore";
import Marionette from "marionette";
import "tooltips";

var Dropdown = Marionette.LayoutView.extend({
    is_open: false,
    initialize: function (opts) {
        var _this = this;
        _(this).bindAll("_on_click");

        this._processOptions(opts);
        this._setupTrigger();
        this._createDropdown();
        this._renderViewInTooltip();
    },
    _processOptions: function () {
        this.options || (this.options = {});
        var default_opts = {
            autohide: true,
            trigger: null,
            action: "click",
            delegate: null,
            classes: "",
            position: "bottom-left",
            animation: "slip",
            speed: 100,
            on_show: null,
            on_hide: null,
        };
        _(this.options).defaults(default_opts);

        if (this.options.content instanceof jQuery || _(this.options.content).isElement()) {
            // Note: template-less ItemView does not seem to be working as documented in Marionette.
            // Using workaround for now.
            var ContentView = Marionette.ItemView.extend({
                el: this.options.content,
                render: function () {
                    // Do nothing
                },
            });
            this.options.content = new ContentView();
        } else if (_(this.options.content).isString()) {
            var ContentView = Marionette.ItemView.extend({
                template: _.template(this.options.content),
            });
            this.options.content = new ContentView();
        }

        if (!(this.options.content instanceof Backbone.View)) return console.warn("Bad content View provided.");

        // Allow controlling the dropdown through the view
        this.listenTo(this.options.content, "dropdown:hide", function () {
            this.hide();
        });

        this.namespace = _.uniqueId("dropdown");

        this.$trigger = this.options.delegate
            ? $(this.options.trigger).find(this.options.delegate)
            : $(this.options.trigger);

        if (this.options.parentView) {
            this.listenTo(this.options.parentView, "destroy", this.destroy);
        }
    },
    _setupTrigger: function () {
        // If this element already has a dropdown attached to it, destroy it first
        if (this.$trigger.data("dropdown")) this.destroy();

        // Identify trigger as belonging to this dropdown
        this.$trigger.data("dropdown", this.namespace);

        // Handle open and close
        if (this.options.action == "click") {
            if (this.options.delegate)
                $(this.options.trigger).on(
                    this.options.action + "." + this.namespace,
                    this.options.delegate,
                    this._on_click
                );
            else $(this.options.trigger).on(this.options.action + "." + this.namespace, this._on_click);
        }
    },
    _createDropdown: function () {
        var _this = this;

        // Create the dropdown
        var default_tooltipster_config = {
            autoClose: true,
            content: "", // Cannot be null, else tooltipster won't show up. We will replace this with the view.
            contentCloning: false, // Use the actual DOM nodes provided
            trigger: this.options.action == "click" ? "custom" : "hover",
            interactive: true,
            theme: "dropdown" + (this.options.classes ? " " + this.options.classes : ""),
            functionBefore: function (origin, continueTooltip) {
                if (_this.options.before_show) {
                    var bs = _this.options.before_show.apply(_this, [_this.options.content]);
                    if (bs === false) return false;
                }
                continueTooltip();
            },
            functionReady: function (el, dd) {
                if (_this.$trigger) _this.$trigger.addClass("active");
                if (_this.options.on_show) _this.options.on_show.apply(_this, [dd, _this.options.content]);
            },
            functionAfter: function (origin) {
                if (_this.$trigger) _this.$trigger.removeClass("active");
                if (_this.options.on_hide) _this.options.on_hide.apply(_this, [_this.options.content]);
            },
        };

        var tooltipster_opts = _(this.options).pick(["position", "animation", "minWidth", "speed"]);
        var tooltipster_config = _(tooltipster_opts).extend(default_tooltipster_config);

        this.$trigger.tooltipster(tooltipster_config);
    },
    _renderViewInTooltip: function () {
        var $contentDiv = $("<div/>", {
            class: "striim-dropdown-content",
        });
        // Render the content
        this.options.content.render();
        // Show the rendered HTML in the tooltip
        $contentDiv.append(this.options.content.el);

        this.$trigger.tooltipster("content", $contentDiv);
    },
    _on_click: function (e) {
        if (this.is_open) this.hide();
        else this.show();
    },
    show: function () {
        var _this = this;
        var deferred = new $.Deferred();
        try {
            this.$trigger.tooltipster(
                "show",
                function () {
                    this.is_open = true;
                    // Simulate "show" event
                    if (this.options.content.onShow) this.options.content.onShow();
                    this.options.content.trigger("show");
                    deferred.resolve();
                }.bind(this)
            );
        } catch (e) {
            console.warn(e);
        }
        this._bind_blur();
        return deferred.promise();
    },
    hide: function () {
        var deferred = new $.Deferred();
        try {
            var _this = this;
            this.$trigger.tooltipster("hide", function () {
                _this.is_open = false;
                deferred.resolve();
            });
        } catch (e) {
            //console.warn(e);
        }
        this._unbind_blur();
        return deferred.promise();
    },
    _bind_blur: function () {
        // Automatically hide the dropdown
        var _this = this;
        this._unbind_blur();

        if (this.options.autohide) {
            $(document).on("click." + this.namespace + " touchstart." + this.namespace, function (e) {
                //console.log('Blurring', _this.$trigger.data('tooltipster-ns'))
                try {
                    if ($(_this.$trigger.tooltipster("content")).has(e.target).length > 0) return;
                    if (_this.is_open) _this.hide();
                } catch (e) {
                    console.warn("Dev error: tooltipster not initialized", _this.options, "Error:", e);
                }
            });
        }
    },
    _unbind_blur: function () {
        //console.log('Unblurring', this.namespace)
        $(document).off("click." + this.namespace + " touchstart." + this.namespace);
    },
    onBeforeDestroy: function () {
        try {
            this.$trigger.tooltipster("destroy");
        } catch (e) {
            //console.warn(e);
        }
        // Destroy the content view
        this.options.content.destroy();

        this._unbind_blur();

        if (this.$trigger) this.$trigger.off(this.options.action + "." + this.namespace);
    },
});

export default Dropdown;
