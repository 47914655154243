import React, { useState } from "react";
import PropTypes from "prop-types";
import {
    StriimSimpleTableCell,
    StriimInputField,
    StriimButton,
    StriimMenuList,
    StriimMenuItem,
    StriimDropdown,
    StriimIconButton
} from "@striim/striim-ui";
import { Grid, Box } from "@material-ui/core";
import _ from "underscore";
import { MoreHoriz } from "@mui/icons-material";

const classes = {
    root: {
        marginTop: 22
    },
    tableCell: {
        backgroundColor: "#D7F1FB"
    }
};

const isNameUnique = (name, properties) => {
    return !Object.keys(properties).some(key => key === name);
};

function PropertySetValueRowReadOnly({
    properties,
    name,
    value,
    isDeleteAllowed,
    deleteRow,
    isEditAllowed,
    editRow,
    editedKey,
    setEditedKey
}) {
    if (editedKey === name) {
        return (
            <PropertySetValueRowNew
                properties={_.omit(properties, name)}
                onSave={(newName, value) => editRow(name, newName, value)}
                onCancel={() => setEditedKey(null)}
                defaultValue={value}
                defaultName={name}
            />
        );
    }

    return (
        <>
            <StriimSimpleTableCell
                textVariant="body4"
                sxProps={{ color: "greyscale.900" }}
                value={name}
                tableCellProps={{ "data-test-id": "property-set-value-name" }}
            />
            <StriimSimpleTableCell
                textVariant="body4"
                sxProps={{ color: "greyscale.900" }}
                value={value}
                tableCellProps={{ "data-test-id": "property-set-value-value" }}
            />
            {(isEditAllowed || isDeleteAllowed) && (
                <StriimSimpleTableCell>
                    <StriimDropdown
                        extendOnClick
                        content={
                            <StriimMenuList>
                                {isEditAllowed && (
                                    <StriimMenuItem
                                        onClick={() => setEditedKey(name)}
                                        data-test-id="property-set-value-edit-button"
                                    >
                                        Edit
                                    </StriimMenuItem>
                                )}
                                {isDeleteAllowed && (
                                    <StriimMenuItem
                                        onClick={() => deleteRow && deleteRow(name)}
                                        style={{ color: "#DD3711" }}
                                        data-test-id="property-set-value-delete-button"
                                    >
                                        Delete
                                    </StriimMenuItem>
                                )}
                            </StriimMenuList>
                        }
                        closeOnSelect
                    >
                        <StriimIconButton
                            classes={{ root: classes.cardActionsButton }}
                            data-testid="property-set-value-menu-button"
                        >
                            <Box component={MoreHoriz} color="text.secondary" />
                        </StriimIconButton>
                    </StriimDropdown>
                </StriimSimpleTableCell>
            )}
        </>
    );
}

function PropertySetValueRowNew({ properties, onSave, onCancel, defaultName, defaultValue }) {
    const [name, setName] = useState(defaultName);
    const [value, setValue] = useState(defaultValue);
    const [error, setError] = useState(false);

    const startsWithNumber = str => {
        return /^\d/.test(str);
    };

    const containsSymbol = str => {
        const symbols = /[`€£!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/;
        return symbols.test(str);
    };

    return (
        <>
            <StriimSimpleTableCell classes={classes.tableCell}>
                <StriimInputField
                    value={name ?? ""}
                    onChange={changedName => {
                        setError(
                            !isNameUnique(changedName, properties) ||
                                startsWithNumber(changedName) ||
                                containsSymbol(changedName)
                        );
                        setName(changedName);
                    }}
                    helperText={
                        !isNameUnique(name, properties)
                            ? "key must be unique"
                            : startsWithNumber(name)
                            ? "cannot start with a number"
                            : containsSymbol(name)
                            ? "cannot contain a symbol"
                            : " "
                    }
                    error={error}
                    classes={{ root: classes.root }}
                    id="data-test-id-property-set-key"
                />
            </StriimSimpleTableCell>
            <StriimSimpleTableCell classes={classes.tableCell}>
                <StriimInputField
                    value={value ?? ""}
                    onChange={changedValue => setValue(changedValue)}
                    id="data-test-id-property-set-value"
                />
            </StriimSimpleTableCell>
            <StriimSimpleTableCell classes={classes.tableCell}>
                <Grid container>
                    <Grid item>
                        <StriimButton
                            variant="primaryText"
                            onClick={async () => {
                                onSave(name, value);
                            }}
                            data-test-id="add-property-set-value-save-button"
                            disabled={
                                !isNameUnique(name, properties) ||
                                startsWithNumber(name) ||
                                containsSymbol(name) ||
                                !name?.length ||
                                !value?.length
                            }
                        >
                            Save
                        </StriimButton>
                    </Grid>
                    <Grid item>
                        <StriimButton
                            component="button"
                            variant="primaryText"
                            onClick={() => {
                                onCancel(false);
                            }}
                            style={{ color: "#DD3711" }}
                            data-test-id="add-property-set-value-cancel-button"
                        >
                            Cancel
                        </StriimButton>
                    </Grid>
                </Grid>
            </StriimSimpleTableCell>
        </>
    );
}

export default function PropertySetValueRow({ data }) {
    const {
        name,
        value,
        properties,
        onSave,
        onCancel,
        isDeleteAllowed,
        deleteRow,
        isEditAllowed,
        editRow,
        isNew,
        editedKey,
        setEditedKey
    } = data;

    return isNew ? (
        <PropertySetValueRowNew properties={properties} onSave={onSave} onCancel={onCancel} />
    ) : (
        <PropertySetValueRowReadOnly
            properties={properties}
            name={name}
            value={value}
            isDeleteAllowed={isDeleteAllowed}
            deleteRow={deleteRow}
            isEditAllowed={isEditAllowed}
            editRow={editRow}
            editedKey={editedKey}
            setEditedKey={setEditedKey}
        />
    );
}

PropertySetValueRow.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        properties: PropTypes.object,
        isNew: PropTypes.bool,
        onSave: PropTypes.func,
        onCancel: PropTypes.func,
        deleteRow: PropTypes.func
    }).isRequired
};
