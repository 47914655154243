import Backbone from "backbone";
import api from "core/api/api";
import { loadDGsForAgents, getAgentForDeploymentGroup } from "app/components/flow/designer/agents-loader";
import { DB_PROVIDERS } from "./configs/db-providers";
import { getParams } from "../../../../../../../src/modules/wizards/services";
import { isAutomatedWizard } from "./app-services";

export const ConnectionParams = Backbone.Model.extend({
    defaults: {
        host: "",
        port: "",
        username: "",
        password: "",
        consumerKey: "",
        consumerSecret: "",
        securityToken: "",
        authToken: "",
        mode: null,
        customObjects: false,
        dbName: "",
        sid: "",
        agentName: null,
        agentDGName: null,
        type: null,
        validateSourceOnAgent: null,
        connectionUrl: null,
        isOauth: true,
        clientId: "",
        clientSecret: "",
        refreshToken: "",
        privateAppToken: "",
        refreshToken: "",
        privateAppToken: "",
        clientCustomerId: "",
        managerId: "",
        accessToken: "",
        refreshToken: "",
        privateAppToken: "",
        clientCustomerId: "",
        managerId: "",
        accessToken: "",
        refreshToken: "",
        developerToken: "",
        privateAppToken: "",
        isPDB: false,
        containerName: "",
        useConnectionProfile: false,
        connectionProfileName: ""
    },

    isAppAdapter() {
        let type = this.get("type");
        return (
            //DO-NOT-REMOVE-BELOW-COMMENT
            //add-new-adapter
            type === DB_PROVIDERS.PAYMO.id ||
            type === DB_PROVIDERS.BAMBOOHR.id ||
            type === DB_PROVIDERS.LINKEDINADS.id ||
            type === DB_PROVIDERS.GOOGLEANALYTICS.id ||
            type === DB_PROVIDERS.JIRA.id ||
            type === DB_PROVIDERS.STRIPE.id ||
            type === DB_PROVIDERS.SALESFORCE.id ||
            type === DB_PROVIDERS.SERVICENOW.id ||
            type === DB_PROVIDERS.SALESFORCEPARDOT.id ||
            type === DB_PROVIDERS.ZENDESK.id ||
            type === DB_PROVIDERS.GOOGLEADS.id ||
            type === DB_PROVIDERS.INTERCOM.id ||
            type === DB_PROVIDERS.HUBSPOT.id
        );
    },

    getJDBCURL() {
        let type = this.get("type");
        if (type === DB_PROVIDERS.ORACLE.id || type === DB_PROVIDERS.OJET.id) {
            if (type === DB_PROVIDERS.OJET.id && this.get("supportDownStreamCapture")) {
                return (
                    "jdbc:oracle:thin:@//" +
                    this.get("primaryDatabaseHostname") +
                    ":" +
                    this.get("primaryDatabasePort") +
                    "/" +
                    this.get("serviceName")
                );
            }
            return "jdbc:oracle:thin:@//" + this.get("host") + ":" + this.get("port") + "/" + this.get("sid");
        }
        //TODO : add oci to url during creation

        if (type === DB_PROVIDERS.MYSQL.id) {
            return "jdbc:mysql://" + this.get("host") + ":" + this.get("port");
        }
        if (type === DB_PROVIDERS.MARIADB.id) {
            return "jdbc:mariadb://" + this.get("host") + ":" + this.get("port");
        }
        if (type === DB_PROVIDERS.MARIADBXPAND.id) {
            return "jdbc:mariadb://" + this.get("host") + ":" + this.get("port");
        }
        if (type === DB_PROVIDERS.POSTGRES.id) {
            return "jdbc:postgresql://" + this.get("host") + ":" + this.get("port") + "/" + this.get("dbName");
        }
        if (type === DB_PROVIDERS.YUGABYTE.id) {
            return "jdbc:postgresql://" + this.get("host") + ":" + this.get("port") + "/" + this.get("dbName");
        }
        if (type === DB_PROVIDERS.SQLSERVER.id || type === DB_PROVIDERS.MSJET.id) {
            return (
                "jdbc:sqlserver://" + this.get("host") + ":" + this.get("port") + ";DatabaseName=" + this.get("dbName")
            );
        }
        if (type === DB_PROVIDERS.MONGODB.id) {
            if (this.get("host").indexOf("mongodb+srv://") === 0) {
                return this.get("host");
            }
            return this.get("host") + ":" + this.get("port");
        }
        if (type === DB_PROVIDERS.MONGO_COSMOSDB.id) {
            return this.get("host") + ":" + this.get("port");
        }
        if (type === DB_PROVIDERS.COSMOSDB.id) {
            return this.get("serviceEndpoint");
        }
        if (type === DB_PROVIDERS.BIGQUERY.id) {
            return this.get("connectionUrl");
        }
    },
    getJDBCDownstreamURL() {
        let type = this.get("type");
        if (type === DB_PROVIDERS.OJET.id) {
            return (
                "jdbc:oracle:thin:@//" +
                this.get("primaryDatabaseHostname") +
                ":" +
                this.get("primaryDatabasePort") +
                "/" +
                this.get("serviceName")
            );
        }
    },
    isDownstreamInfoFilled() {
        const connectionInfoFilled =
            this.get("supportDownStreamCapture") &&
            this.get("primaryDatabaseUsername") &&
            this.get("primaryDatabasePassword") &&
            this.get("primaryDatabaseHostname") &&
            this.get("primaryDatabaseServiceName") &&
            this.get("primaryDatabasePort");
        return connectionInfoFilled || false;
    },

    checkResponse(payload) {
        try {
            if (payload) {
                if (typeof payload === "string" && payload === "Completed.") {
                    return;
                }
                payload = JSON.parse(payload);
                if (payload.result !== true) {
                    throw new Error(payload.message);
                }
            }
        } catch (err) {
            console.log(err);
        }
    },
    getTables() {},
    async checkConnection(isCDC, checkPrimaryDatabase = false) {
        let type = this.get("type").toLowerCase();
        let username = this.get("username");
        let password = this.get("password");
        const wizardURL = this.getJDBCURL();
        let agentName = null;
        let agentDGName = null;
        const selectedAgentValue = this.get("validateSourceOnAgent");
        if (selectedAgentValue === true) {
            const agentDeploymentGroups = loadDGsForAgents();
            let agentDeploymentGroup = agentDeploymentGroups[0].get("id");
            agentDGName = agentDeploymentGroup;
            agentName = getAgentForDeploymentGroup(agentDeploymentGroup);
        } else if (typeof selectedAgentValue === "string" && selectedAgentValue) {
            agentDGName = selectedAgentValue;
            agentName = getAgentForDeploymentGroup(selectedAgentValue);
        }
        this.set("agentName", agentName);
        this.set("agentDGName", agentDGName);
        return this.validateConnection(type, username, password, wizardURL, agentName, isCDC, checkPrimaryDatabase);
    },

    async validateConnection(type, username, password, wizardURL, agentName, isCDC, checkPrimaryDatabase = false) {
        const that = this;
        if (this.get("type") === DB_PROVIDERS.BIGQUERY.id) {
            return new Promise(function(resolve, reject) {
                api.bigQueryValidateConnection({
                    useConnectionProfile: that.get("useConnectionProfile"),
                    connectionProfileName: that.get("connectionProfileName"),
                    connectionUrl: that.get("connectionUrl")
                }).then(
                    function(data) {
                        data = JSON.parse(data);
                        let message = data.message;
                        if (data.result === true) {
                            resolve(data);
                        } else {
                            reject(message);
                        }
                    },
                    function(e) {
                        console.error(e);
                        reject("Problem Validating Connection. Please check the logs for detailed information.");
                    }
                );
            });
        }
        if (this.get("type") === DB_PROVIDERS.SALESFORCE.id) {
            if (isCDC) {
                return new Promise(function(resolve, reject) {
                    api.salesforceCDCValidateConnection(
                        {
                            useConnectionProfile: that.get("useConnectionProfile"),
                            connectionProfileName: that.get("connectionProfileName"),
                            username,
                            password,
                            securityToken: that.get("securityToken"),
                            consumerKey: that.get("consumerKey"),
                            consumerSecret: that.get("consumerSecret"),
                            accesstoken: that.get("authToken"),
                            apiEndPoint: that.get("host")
                        },
                        agentName
                    ).then(
                        function(data) {
                            data = JSON.parse(data);
                            let message = data.message;
                            if (data.result === true) {
                                that.set("authToken", message);
                                resolve(data);
                            } else {
                                reject(data);
                            }
                        },
                        function(e) {
                            console.error(e);
                            reject("Problem Validating Connection. Please check the logs for detailed information.");
                        }
                    );
                });
            } else {
                return new Promise(function(resolve, reject) {
                    api.salesforceValidateConnection(
                        username,
                        password,
                        that.get("securityToken"),
                        that.get("consumerKey"),
                        that.get("consumerSecret"),
                        that.get("authToken"),
                        that.get("host"),
                        that.get("connectionProfileName"),
                        agentName
                    ).then(
                        function(data) {
                            data = JSON.parse(data);
                            let message = data.message;
                            if (data.result === true) {
                                that.set("authToken", message);
                                resolve(data);
                            } else {
                                reject(data);
                            }
                        },
                        function(e) {
                            console.error(e);
                            reject("Problem Validating Connection. Please check the logs for detailed information.");
                        }
                    );
                });
            }
        }
        //DO-NOT-REMOVE-BELOW-COMMENT
        //add-validate-case
        else if (this.get("type") === DB_PROVIDERS.PAYMO.id) {
            return new Promise(function(resolve, reject) {
                api.paymoValidateConnection(
                    {
                        apiKey: that.get("apiKey"),
                    },
                    agentName
                ).then(
                    function(data) {
                        data = JSON.parse(data);
                        let message = data.message;
                        if (data.result === true) {
                            that.set("authToken", message);
                            resolve(data);
                        } else {
                            reject(message);
                        }
                    },
                    function(e) {
                        console.error(e);
                        reject("Problem Validating Connection. Please check the logs for detailed information.");
                    }
                );
            });
        } else if (this.get("type") === DB_PROVIDERS.BAMBOOHR.id) {
            return new Promise(function(resolve, reject) {
                api.bambooHrValidateConnection(
                    {
                        apiKey: that.get("apiKey"),
                        domain: that.get("domain")
                    },
                    agentName
                ).then(
                    function(data) {
                        data = JSON.parse(data);
                        let message = data.message;
                        if (data.result === true) {
                            that.set("authToken", message);
                            resolve(data);
                        } else {
                            reject(message);
                        }
                    },
                    function(e) {
                        console.error(e);
                        reject("Problem Validating Connection. Please check the logs for detailed information.");
                    }
                );
            });
        } else if (this.get("type") === DB_PROVIDERS.GOOGLEANALYTICS.id) {
            return new Promise(function(resolve, reject) {
                api.googleAnalyticsValidateConnection(
                    {
                        clientId: that.get("clientId"),
                        clientSecret: that.get("clientSecret"),
                        accessToken: that.get("accessToken"),
                        refreshToken: that.get("refreshToken"),
                        propertyId: that.get("propertyId"),
                        autoTokenRenewal: that.get("autoTokenRenewal")
                    },
                    agentName
                ).then(
                    function(data) {
                        data = JSON.parse(data);
                        let message = data.message;
                        if (data.result === true) {
                            that.set("authToken", message);
                            resolve(data);
                        } else {
                            reject(message);
                        }
                    },
                    function(e) {
                        console.error(e);
                        reject("Problem Validating Connection. Please check check the logs for detailed information.");
                    }
                );
            });
        } else if (this.get("type") === DB_PROVIDERS.LINKEDINADS.id) {
            return new Promise(function(resolve, reject) {
                api.linkedInAdsValidateConnection(
                    {
                        clientId: that.get("clientId"),
                        clientSecret: that.get("clientSecret"),
                        accessToken: that.get("accessToken"),
                        refreshToken: that.get("refreshToken"),
                        autoTokenRenewal: that.get("autoTokenRenewal")
                    },
                    agentName
                ).then(
                    function(data) {
                        data = JSON.parse(data);
                        let message = data.message;
                        if (data.result === true) {
                            that.set("authToken", message);
                            resolve(data);
                        } else {
                            reject(message);
                        }
                    },
                    function(e) {
                        console.error(e);
                        reject("Problem Validating Connection. Please check check the logs for detailed information.");
                    }
                );
            });
         } else if (this.get("type") === DB_PROVIDERS.JIRA.id) {
            return new Promise(function(resolve, reject) {
                api.jiraValidateConnection(
                    {
                        clientId: that.get("clientId"),
                        clientSecret: that.get("clientSecret"),
                        authScheme: that.get("authMode"),
                        endpointUrl: that.get("endpointUrl"),
                        apiToken: that.get("apiToken"),
                        refreshToken: that.get("refreshToken"),
                        userName: that.get("userName")
                    },
                    agentName
                ).then(
                    function(data) {
                        data = JSON.parse(data);
                        let message = data.message;
                        if (data.result === true) {
                            resolve(data);
                        } else {
                            reject(message);
                        }
                    },
                    function(e) {
                        console.error(e);
                        reject("Problem Validating Connection. Please check the logs for detailed information.");
                    }
                );
            });
        } else if (this.get("type") === DB_PROVIDERS.STRIPE.id) {
            return new Promise(function(resolve, reject) {
                api.stripeValidateConnection(
                    {
                        useConnectionProfile: that.get("useConnectionProfile"),
                        connectionProfileName: that.get("connectionProfileName"),
                        accountId: that.get("accountId"),
                        apiKey: that.get("apiKey"),
                        isConnectedAccount: that.get("connectedAccount"),
                        isOauth: that.get("authMode") === "OAuth",
                        accessToken: that.get("accessToken"),
                        clientId: that.get("clientId"),
                        clientSecret: that.get("clientSecret"),
                        refreshToken: that.get("refreshToken")
                    },
                    agentName
                ).then(
                    function(data) {
                        data = JSON.parse(data);
                        if (data.result === true) {
                            resolve(data);
                        } else {
                            reject(data);
                        }
                    },
                    function(e) {
                        console.error(e);
                        reject("Problem Validating Connection. Please check the logs for detailed information.");
                    }
                );
            });
        } else if (this.get("type") === DB_PROVIDERS.SERVICENOW.id) {
            return new Promise(function(resolve, reject) {
                api.serviceNowValidateConnection(
                    {
                        connectionUrl: that.get("connectionUrl"),
                        username: that.get("username"),
                        password: that.get("password"),
                        clientId: that.get("clientId"),
                        clientSecret: that.get("clientSecret"),
                        useConnectionProfile: that.get("useConnectionProfile"),
                        connectionProfileName: that.get("connectionProfileName")
                    },
                    agentName
                ).then(
                    function(data) {
                        data = JSON.parse(data);
                        let message = data.message;
                        if (data.result === true) {
                            that.set("authToken", message);
                            resolve(data);
                        } else {
                            reject(data);
                        }
                    },
                    function(e) {
                        console.error(e);
                        reject("Problem Validating Connection. Please check the logs for detailed information.");
                    }
                );
            });
        } else if (this.get("type") === DB_PROVIDERS.GOOGLEADS.id) {
            return new Promise(function(resolve, reject) {
                api.googleAdsValidateConnection(
                    {
                        clientCustomerId: that.get("clientCustomerId"),
                        managerId: that.get("managerId"),
                        isAutoTokenRenewal: that.get("isAutoTokenRenewal"),
                        clientId: that.get("clientId"),
                        clientSecret: that.get("clientSecret"),
                        refreshToken: that.get("refreshToken"),
                        accessToken: that.get("accessToken")
                    },
                    agentName
                ).then(
                    function(data) {
                        data = JSON.parse(data);
                        let message = data.message;
                        if (data.result === true) {
                            that.set("authToken", message);
                            resolve(data);
                        } else {
                            reject(message);
                        }
                    },
                    function(e) {
                        console.error(e);
                        reject("Problem Validating Connection. Please check the logs for detailed information.");
                    }
                );
            });
        } else if (this.get("type") === DB_PROVIDERS.SALESFORCEPARDOT.id) {
            return new Promise(function(resolve, reject) {
                api.salesforcePardotValidateConnection(
                    {
                        consumerKey: that.get("consumerKey"),
                        consumerSecret: that.get("consumerSecret"),
                        username: that.get("username"),
                        password: that.get("password"),
                        securityToken: that.get("securityToken"),
                        businessUnitId: that.get("businessUnitId")
                    },
                    agentName
                ).then(
                    function(data) {
                        data = JSON.parse(data);
                        let message = data.message;
                        if (data.result === true) {
                            that.set("authToken", message);
                            resolve(data);
                        } else {
                            reject(data);
                        }
                    },
                    function(e) {
                        console.error(e);
                        reject("Problem Validating Connection. Please check the logs for detailed information.");
                    }
                );
            });
        } else if (this.get("type") === DB_PROVIDERS.SNOWFLAKE.id) {
            return new Promise(function(resolve, reject) {
                api.snowflakeReaderValidateConnection(
                    {
                        useConnectionProfile: true,
                        connectionProfileName: that.get("connectionProfileName")
                    },
                    agentName
                ).then(
                    function(data) {
                        data = JSON.parse(data);
                        let message = data.message;
                        if (data.result === true) {
                            that.set("authToken", message);
                            resolve(data);
                        } else {
                            reject(message);
                        }
                    },
                    function(e) {
                        console.error(e);
                        reject("Problem Validating Connection. Please check the logs for detailed information.");
                    }
                );
            });
        } else if (this.get("type") === DB_PROVIDERS.HUBSPOT.id) {
            return new Promise(function(resolve, reject) {
                api.hubSpotValidateConnection(
                    {
                        useConnectionProfile: that.get("useConnectionProfile"),
                        connectionProfileName: that.get("connectionProfileName"),
                        isOauth: that.get("authMode") === "OAuth",
                        accessToken: that.get("accessToken"),
                        privateAppToken: that.get("privateAppToken"),
                        clientId: that.get("clientId"),
                        clientSecret: that.get("clientSecret"),
                        refreshToken: that.get("refreshToken")
                    },
                    agentName
                ).then(
                    function(data) {
                        data = JSON.parse(data);
                        let message = data.message;
                        if (data.result === true) {
                            that.set("authToken", message);
                            resolve(data);
                        } else {
                            reject(data);
                        }
                    },
                    function(e) {
                        console.error(e);
                        reject("Problem Validating Connection. Please check the logs for detailed information.");
                    }
                );
            });
        } else if (this.get("type") === DB_PROVIDERS.ZENDESK.id) {
            return new Promise(function(resolve, reject) {
                api.zendeskValidateConnection(
                    {
                        useConnectionProfile: that.get("useConnectionProfile"),
                        connectionProfileName: that.get("connectionProfileName"),
                        connectionUrl: that.get("connectionUrl"),
                        accessToken: that.get("access_token"),
                        readerMode: that.get("mode") ?? "Automated",
                        username: that.get("username"),
                        password: that.get("password"),
                        authMode: that.get("authMode"),
                        apiToken: that.get("apiToken")
                    },
                    agentName
                ).then(
                    function(data) {
                        data = JSON.parse(data);
                        if (data.result === true) {
                            resolve(data);
                        } else {
                            reject(data);
                        }
                    },
                    function(e) {
                        console.error(e);
                        reject("Problem Validating Connection. Please check the logs for detailed information.");
                    }
                );
            });
        } else if (this.get("type") === DB_PROVIDERS.INTERCOM.id) {
            return new Promise(function(resolve, reject) {
                api.intercomValidateConnection(
                    {
                        apiKey: that.get("apiKey"),
                        accessToken: that.get("accessToken"),
                        isoauth: that.get("authMode") === "OAuth"
                    },
                    agentName
                ).then(
                    function(data) {
                        data = JSON.parse(data);
                        let message = data.message;
                        if (data.result === true) {
                            that.set("authToken", message);
                            resolve(data);
                        } else {
                            reject(message);
                        }
                    },
                    function(e) {
                        console.error(e);
                        reject("Problem Validating Connection. Please check the logs for detailed information.");
                    }
                );
            });
        } else if (this.get("type") === DB_PROVIDERS.OJET.id) {
            const isILCDC = isAutomatedWizard();
            if (checkPrimaryDatabase) {
                let sslParams, sslConfigMap;
                const primaryDatabaseUsername = this.get("primaryDatabaseUsername");
                const primaryDatabasePassword = this.get("primaryDatabasePassword");

                return new Promise(async (resolve, reject) => {
                    sslParams = await getParams(this.get("type"), that, true);
                    sslParams?.ConnectionURL && (wizardURL = sslParams.ConnectionURL);
                    sslConfigMap = sslParams?.sslConfigMap ?? null;
                    try {
                        let data = await api.validateConnection(
                            type,
                            primaryDatabaseUsername,
                            primaryDatabasePassword,
                            wizardURL,
                            agentName,
                            false,
                            null,
                            sslConfigMap
                        );
                        data = JSON.parse(data);
                        if (data.result === true) {
                            resolve(data);
                        } else {
                            reject(data);
                        }
                    } catch (error) {
                        console.log(error);
                        reject(error);
                    }
                });
            } else {
                let sslParams, sslConfigMap;
                return new Promise(async (resolve, reject) => {
                    sslParams = await getParams(this.get("type"), that);
                    sslParams?.ConnectionURL && (wizardURL = sslParams.ConnectionURL);
                    sslConfigMap = sslParams?.sslConfigMap ?? null;
                    try {
                        let data = await api.validateConnection(
                            type,
                            username,
                            password,
                            wizardURL,
                            agentName,
                            false,
                            null,
                            sslConfigMap
                        );
                        data = JSON.parse(data);
                        if (data.result === true) {
                            resolve(data);
                        } else {
                            reject(data);
                        }
                    } catch (error) {
                        console.log(error);
                        reject(error);
                    }
                });
            }
        } else {
            if (this.get("type") === DB_PROVIDERS.COSMOSDB.id) {
                username = null;
                type = "cosmos";
                password = this.get("accessKey");
            }
            if (this.get("type") === DB_PROVIDERS.MONGO_COSMOSDB.id) {
                type = "mongocosmos";
            }
            return new Promise(async function(resolve, reject) {
                const isILCDC = isAutomatedWizard();
                let sslParams, sslConfigMap;
                if (isILCDC) {
                    sslParams = await getParams(type, that);
                    sslParams?.ConnectionURL && (wizardURL = sslParams.ConnectionURL);
                    sslConfigMap = sslParams?.sslConfigMap ?? null;
                } else if (type === DB_PROVIDERS.MYSQL.id.toLowerCase() && !isCDC) {
                    // For MySQL Initial Load, call getConnectionUrl
                    sslParams = await getParams(type, that);
                    sslParams?.ConnectionURL && (wizardURL = sslParams.ConnectionURL);
                }
                api.validateConnection(type, username, password, wizardURL, agentName, false, null, sslConfigMap).then(
                    function(data) {
                        data = JSON.parse(data);
                        if (data.result === true) {
                            resolve(data);
                        } else {
                            reject(data);
                        }
                    },
                    function(e) {
                        reject("Problem Validating Connection. Please check the logs for detailed information.");
                    }
                );
            });
        }
    },

    async checkCDCPermissions() {
        const type = this.get("type");
        const username = this.get("username");
        const password = this.get("password");
        const wizardURL = this.getJDBCURL();
        const agentName = this.get("agentName");
        const isILCDC = isAutomatedWizard();

        let response;

        if (type === DB_PROVIDERS.ORACLE.id) {
            // CDC Permission check
            const params = await getParams(type, this);
            response = await api.OraclecheckCDCConfigurations(params, agentName);
            return response;
        } else if (type === DB_PROVIDERS.OJET.id) {
            const params = await getParams(type, this, this.isDownstreamInfoFilled());
            return api.OjetcheckCDCConfigurations(params, agentName);
        } else if (type === DB_PROVIDERS.MYSQL.id) {
            // ILCDC-Todo Change to Params
            const params = await getParams(type, this);
            response = await api.MYSQLcheckCDCConfig(params, agentName);
            return response;
        } else if (type === DB_PROVIDERS.MARIADBXPAND.id) {
            const params = await getParams(type, this);
            response = await api.MariaDBXpandCheckCDCConfigurations(params, agentName);
            return response;
        } else if (type === DB_PROVIDERS.MARIADB.id) {
            const params = await getParams(type, this);
            response = await api.MariaDBCheckCDCConfigurations(params, agentName);
            return response;
        } else if (type === DB_PROVIDERS.SQLSERVER.id) {
            const params = await getParams(type, this);
            response = await api.MSSQLcheckCDCEnabled(params, agentName);
            return response;
        } else if (type === DB_PROVIDERS.MSJET.id) {
            const params = await getParams(type, this);
            response = await api.MSJETcheckCDCConfigurations(params, agentName);
            return response;
        } else if (type === DB_PROVIDERS.MONGODB.id) {
            // ILCDC-Todo Check if working
            response = await api.mongoDBCheckCDC(username, password, wizardURL, agentName);
            this.checkResponse(response);
            response = await api.mongodbCheckPrivs(username, password, wizardURL, agentName);
            this.checkResponse(response);
        } else if (type === DB_PROVIDERS.SALESFORCE.id) {
            const params = await getParams(type, this);
            response = await api.salesforceCDCValidateConnection(params, agentName);
            return response;
        } else if (type === DB_PROVIDERS.POSTGRES.id) {
            const params = await getParams(type, this);
            response = await api.postgresCheckCDCConfigurations(params, agentName);
            return response;
        } else if (type === DB_PROVIDERS.YUGABYTE.id) {
            const params = await getParams(type, this);
            response = await api.yugabytecheckCDCConfigurations(params, agentName);
            return response;
        }

        return true;
    },

    async fetchTableWrapper(isCDC) {
        const type = this.get("type");
        const username = this.get("username");
        let password = this.get("password");
        const wizardURL = this.getJDBCURL();
        const agentName = this.get("agentName");

        if (type === DB_PROVIDERS.MYSQL.id) {
            const params = await getParams(type, this);
            return api.MYSQLGetDBs(params, agentName);
        } else if (type === DB_PROVIDERS.SQLSERVER.id || type === DB_PROVIDERS.MSJET.id) {
            const params = await getParams(type, this);
            return api.MSSQLgetSchemas(params, agentName);
        } else if (type === DB_PROVIDERS.MARIADBXPAND.id) {
            const params = await getParams(type, this);
            return api.MariaDBXpandGetDBs(params, agentName);
        } else if (type === DB_PROVIDERS.MARIADB.id) {
            const params = await getParams(type, this);
            return api.MariaDBGetDBs(params, agentName);
        } else if (type === DB_PROVIDERS.ORACLE.id) {
            const params = await getParams(type, this);
            return api.OracleGetSchemas(params, agentName);
        } else if (type === DB_PROVIDERS.OJET.id) {
            const isILCDC = isAutomatedWizard();

            if (this.isDownstreamInfoFilled()) {
                const params = await getParams(type, this, true);
                return api.OjetGetSchemas(params, agentName);
            }
            const params = await getParams(type, this);
            return api.OjetGetSchemas(params, agentName);
        } else if (type === DB_PROVIDERS.POSTGRES.id) {
            const params = await getParams(type, this);
            return api.PostgresGetSchemas(params, agentName);
        } else if (type === DB_PROVIDERS.YUGABYTE.id) {
            const params = await getParams(type, this);
            return api.yugabyteGetSchemas(params, agentName);
        } else if (type === DB_PROVIDERS.MONGODB.id) {
            return api.mongodbGetDBs(username, password, wizardURL, agentName);
        } else if (type === DB_PROVIDERS.MONGO_COSMOSDB.id) {
            return api.mongoCosmosdbGetDBs(username, password, wizardURL, agentName);
        } else if (type === DB_PROVIDERS.COSMOSDB.id) {
            password = this.get("accessKey");
            return api.cosmosdbGetDBs(null, password, wizardURL, agentName);
        } else if (type === DB_PROVIDERS.BIGQUERY.id) {
            return api.bigQuerygetDatasets({
                useConnectionProfile: this.get("useConnectionProfile"),
                connectionProfileName: this.get("connectionProfileName"),
                connectionUrl: this.get("connectionUrl")
            });
        } else if (type === DB_PROVIDERS.SALESFORCE.id) {
            if (isCDC) {
                return api.salesforceCDCGetChannels(
                    {
                        useConnectionProfile: this.get("useConnectionProfile"),
                        connectionProfileName: this.get("connectionProfileName"),
                        username: this.get("username"),
                        password: this.get("password"),
                        securityToken: this.get("securityToken"),
                        consumerKey: this.get("consumerKey"),
                        consumerSecret: this.get("consumerSecret"),
                        accesstoken: this.get("authToken"),
                        apiEndPoint: this.get("host")
                    },
                    agentName
                );
            } else {
                return api.salesforceGetObjects(
                    this.get("authToken"),
                    this.get("host"),
                    this.get("customObjects") ? "true" : "false",
                    this.get("connectionProfileName"),
                    agentName
                );
            }
        }
        //DO-NOT-REMOVE-BELOW-COMMENT
        //add-getObjects-case
        else if (type === DB_PROVIDERS.PAYMO.id) {
            return api.paymoGetObjects(
                {
                    apiKey: this.get("apiKey")                },
                agentName
            );
        } else if (type === DB_PROVIDERS.BAMBOOHR.id) {
            return api.bambooHrGetObjects(
                {
                    apiKey: this.get("apiKey"),
                    domain: this.get("domain")
                },
                agentName
            );
        } else if (type === DB_PROVIDERS.GOOGLEANALYTICS.id) {
                   return api.googleAnalyticsGetObjects(
                       {
                           clientId: this.get("clientId"),
                           clientSecret: this.get("clientSecret"),
                           accessToken: this.get("accessToken"),
                           refreshToken: this.get("refreshToken"),
                           propertyId: this.get("propertyId"),
                           autoTokenRenewal: this.get("autoTokenRenewal")
                       },
                       agentName
                   );
        } else if (type === DB_PROVIDERS.JIRA.id) {
            return api.jiraGetObjects(
                {
                    clientId: this.get("clientId"),
                    clientSecret: this.get("clientSecret"),
                    authScheme: this.get("authMode"),
                    endpointUrl: this.get("endpointUrl"),
                    apiToken: this.get("apiToken"),
                    refreshToken: this.get("refreshToken"),
                    userName: this.get("userName")
                },
                agentName
            );
        } else if (type === DB_PROVIDERS.SNOWFLAKE.id) {
            return api.snowflakeReaderGetDBs({
                useConnectionProfile: true,
                connectionProfileName: this.get("connectionProfileName")
            });
        } else if (type === DB_PROVIDERS.LINKEDINADS.id) {
                     return api.linkedInAdsGetObjects(
                         {
                             clientId: this.get("clientId"),
                             clientSecret: this.get("clientSecret"),
                             accessToken: this.get("accessToken"),
                             refreshToken: this.get("refreshToken"),
                             autoTokenRenewal: this.get("autoTokenRenewal")
                         },
                         agentName
                     );
        } else if (type === DB_PROVIDERS.STRIPE.id) {
            return api.stripeGetObjects(
                {
                    useConnectionProfile: this.get("useConnectionProfile"),
                    connectionProfileName: this.get("connectionProfileName"),
                    accountId: this.get("accountId"),
                    apiKey: this.get("apiKey"),
                    isOauth: this.get("authMode") === "OAuth",
                    isConnectedAccount: this.get("connectedAccount"),
                    accessToken: this.get("accessToken"),
                    clientId: this.get("clientId"),
                    clientSecret: this.get("clientSecret"),
                    refreshToken: this.get("refreshToken")
                },
                agentName
            );
        } else if (type === DB_PROVIDERS.SERVICENOW.id) {
            return api.serviceNowGetObjects(
                {
                    connectionUrl: this.get("connectionUrl"),
                    username: this.get("username"),
                    password: this.get("password"),
                    clientId: this.get("clientId"),
                    clientSecret: this.get("clientSecret"),
                    useConnectionProfile: this.get("useConnectionProfile"),
                    connectionProfileName: this.get("connectionProfileName")
                },
                agentName
            );
        } else if (type === DB_PROVIDERS.SALESFORCEPARDOT.id) {
            return api.salesforcePardotGetObjects({
                consumerKey: this.get("consumerKey"),
                consumerSecret: this.get("consumerSecret"),
                username: this.get("username"),
                password: this.get("password"),
                securityToken: this.get("securityToken")
            });
        } else if (type === DB_PROVIDERS.ZENDESK.id) {
            return api.zendeskGetObjects(
                {
                    useConnectionProfile: this.get("useConnectionProfile"),
                    connectionProfileName: this.get("connectionProfileName"),
                    connectionUrl: this.get("connectionUrl"),
                    accessToken: this.get("access_token"),
                    readerMode: this.get("mode") ?? "Automated",
                    username: this.get("username"),
                    password: this.get("password"),
                    authMode: this.get("authMode"),
                    apiToken: this.get("apiToken")
                },
                agentName
            );
        } else if (type === DB_PROVIDERS.GOOGLEADS.id) {
            return api.googleAdsGetObjects({
                clientCustomerId: this.get("clientCustomerId"),
                managerId: this.get("managerId"),
                isAutoTokenRenewal: this.get("isAutoTokenRenewal"),
                clientId: this.get("clientId"),
                clientSecret: this.get("clientSecret"),
                refreshToken: this.get("refreshToken"),
                accessToken: this.get("accessToken")
            });
        } else if (type === DB_PROVIDERS.INTERCOM.id) {
            return api.intercomGetObjects(
                {
                    apiKey: this.get("apiKey"),
                    accessToken: this.get("accessToken"),
                    isoauth: this.get("authMode") === "OAuth"
                },
                agentName
            );
        } else if (type === DB_PROVIDERS.HUBSPOT.id) {
            return api.hubSpotGetObjects(
                {
                    useConnectionProfile: this.get("useConnectionProfile"),
                    connectionProfileName: this.get("connectionProfileName"),
                    isOauth: this.get("authMode") === "OAuth",
                    accessToken: this.get("accessToken"),
                    privateAppToken: this.get("privateAppToken"),
                    clientId: this.get("clientId"),
                    clientSecret: this.get("clientSecret"),
                    refreshToken: this.get("refreshToken")
                },
                agentName
            );
        }
    },

    fetchObjects(channel, isCDC) {
        const type = this.get("type");
        const username = this.get("username");
        let password = this.get("password");
        const agentName = this.get("agentName");

        return api.salesforceCDCGetObjects(
            {
                useConnectionProfile: this.get("useConnectionProfile"),
                connectionProfileName: this.get("connectionProfileName"),
                username,
                password,
                securityToken: this.get("securityToken"),
                consumerKey: this.get("consumerKey"),
                consumerSecret: this.get("consumerSecret"),
                accesstoken: this.get("authToken"),
                apiEndPoint: this.get("host")
            },
            channel,
            agentName
        );
    }
});

export const Table = Backbone.Model.extend({
    defaults: {
        id: null,
        name: null,
        isValid: true,
        isSelected: false,
        fullTableName: null
    }
});

export const Tables = Backbone.Collection.extend({
    model: Table
});

export const Schema = Backbone.Model.extend({
    defaults: {
        id: null,
        tables: null,
        isEnabled: false,
        schemaName: null,
        schemaDisplayName: null
    }
});

export const Schemas = Backbone.Collection.extend({
    model: Schema
});

export const WizardModel = Backbone.Model.extend({
    defaults: {
        appName: null,
        connectionParams: null,
        schemas: null,
        template: null,
        isCDC: false
    },
    getTargetType() {
        let target = this.get("template").steps ? this.get("template").steps[1] : null;
        switch (target) {
            case "SnowflakeWriter":
                return "snowflake";
            case "OutputPostgresDatabase":
            case "CloudSqlPostgreSqlWriter":
            case "AzurePostgreSqlWriter":
                return DB_PROVIDERS.POSTGRES.name.toLowerCase();
            case "OutputOracleDatabase":
                return DB_PROVIDERS.ORACLE.name.toLowerCase();
            case "OutputMySQLDatabase":
            case "CloudSqlMySqlWriter":
                return DB_PROVIDERS.MYSQL.name.toLowerCase();
            case "OutputSQLServerDatabase":
            case "CloudSqlSQLServerWriter":
            case "OutputAzureSQLServerDatabase":
                return DB_PROVIDERS.SQLSERVER.name.toLowerCase();
            case "MariaDBDatabase":
                return DB_PROVIDERS.MARIADB.name.toLowerCase();
            case "BigQuery":
                return "bigquery";
            case "AzureSQLDWHWriter":
            case "FabricDataWarehouseWriter":
                return "azuresynapse";
            case "DeltaLakeWriter":
                return "deltalake";
            default:
                throw new Error("Invalid target type set during Schema Migration Compatibility Check");
        }
    }
});
