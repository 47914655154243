import React, { useState, useEffect, useImperativeHandle, Dispatch, SetStateAction, forwardRef } from "react";
import App from "app";

import { Grid } from "@mui/material";
import { StriimTypography, StriimCodeBlock } from "@striim/striim-ui";

import CircularProgress from "@mui/material/CircularProgress";

import { styles } from "./validation-list.styles";
import WarningIcon from "../../../../generic/icon/wizard-icon/warning.svg";
import SuccessIcon from "../../../../generic/icon/wizard-icon/successOutlined.svg";
import ErrorIcon from "../../../../generic/icon/wizard-icon/errorFilled.svg";
import Tracker from "../../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../core/services/tracker/constants";

interface validationListProperty {
    status: string;
    text: string;
    message?: string;
    children?: object;
    method: any;
}
interface setAPIStatusProperty {
    isAPISuccessful?: boolean;
    isAPICompleted?: boolean;
    canRetry?: boolean;
}
interface ValidationListProps {
    validationList: validationListProperty[];
    left?: boolean;
    setAPIStatus?: Dispatch<SetStateAction<setAPIStatusProperty>>;
    componentRef?: any;
    async?: boolean;
    onSuccess: () => {};
}

export const ValidationList = forwardRef(
    ({ validationList, left = true, setAPIStatus = null, async = true, onSuccess }: ValidationListProps, ref) => {
        const [validationListState, setValidationListState] = useState(validationList);

        useEffect(() => {
            App.vent.trigger("appwizard:next:disable");
            executeValidationListAPI();
        }, []);

        useImperativeHandle(ref, () => ({
            retryAPI
        }));

        const retryAPI = () => {
            setValidationListState(validationList);
            executeValidationListAPI();
        };

        const trackError = (title, message) => {
            Tracker.getInstance().track(TRACKER_STRINGS.schema.error, {
                id: "",
                event: "Error" + title,
                message: message
            });
        };

        const updateStatus = async (listItem, index) => {
            let hasFailure,
                hasWarning,
                failedStep = false,
                failAllPending = false;
            const hasChildValidationList = ["Validating Permissions", "Validating CDC Permissions"];
            try {
                let response = await listItem.method();
                // To-Do Remove once the BE is fixed
                if (response === "Completed." || response === "Connection Successful!")
                    response = JSON.stringify({ result: true });
                const parsedResponse = typeof response === "string" ? JSON.parse(response) : response;
                if (parsedResponse) {
                    if (parsedResponse?.viewDocumentation) {
                        listItem.viewDocumentation = parsedResponse.viewDocumentation;
                    }
                    if (parsedResponse?.result || parsedResponse === true) {
                        listItem.status = "success";
                    } else if (parsedResponse.result === false) {
                        failedStep = true;
                    } else if (hasChildValidationList.includes(listItem?.text)) {
                        if (Array.isArray(parsedResponse)) {
                            hasFailure = parsedResponse.some(childList => {
                                return childList.status === "failed" && childList.isWarn !== true;
                            });
                            hasWarning = parsedResponse.some(childList => {
                                return childList.isWarn === true;
                            });
                            listItem.status = hasFailure ? "failed" : hasWarning ? "warn" : "success";
                            listItem.children = parsedResponse;
                        } else {
                            failedStep = true;
                        }
                    } else {
                        failedStep = true;
                    }
                } else {
                    failedStep = true;
                }
                if (failedStep) {
                    listItem.status = "failed";
                    failAllPending = true;
                    listItem.message = parsedResponse.message ? parsedResponse.message : null;
                    trackError(listItem.text, listItem.message);
                }
            } catch (e) {
                listItem.status = "failed";
                failAllPending = true;
                listItem.message = e.message ? e.message : e;
                trackError(listItem.text, listItem.message);
            }

            setValidationListState(prevstate => {
                prevstate[index] = listItem;
                updateButtonState(prevstate);
                return prevstate;
            });

            return !async ? failAllPending : undefined;
        };

        const executeValidationListAPI = async () => {
            const validationList = [...validationListState];
            if (async) {
                validationList.map(async (listItem, index) => {
                    updateStatus(listItem, index);
                });
            } else {
                let failAllPending = false;
                let newList = [...validationList];

                for (let index = 0; index < validationList.length; index++) {
                    const listItem = validationList[index];
                    if (failAllPending) {
                        listItem.status = "failed";
                        newList[index] = listItem;
                        setValidationListState(newList);
                        updateButtonState(newList);
                        trackError(listItem.text, listItem.message);
                    } else {
                        failAllPending = await updateStatus(listItem, index);
                    }
                }
            }
        };

        const updateButtonState = validationList => {
            const isAPICompleted = !validationList.some(item => {
                return item.status === "todo";
            });
            const isAPISuccessful = validationList.every(item => {
                return item.status === "done" || item.status === "success" || item.status === "warn";
            });
            const canRetry = isAPICompleted && !isAPISuccessful ? true : false;

            setAPIStatus({
                isAPICompleted: isAPICompleted,
                isAPISuccessful: isAPISuccessful,
                canRetry: canRetry
            });
            if (isAPISuccessful) {
                // If Warning is present, Don't transition automatically
                const hasWarning = validationList.some(item => item.status === "warn");
                if (hasWarning) App.vent.trigger("appwizard:next:enable");
                else onSuccess && onSuccess();
            }
        };

        const customCircularProgress = (
            <>
                <CircularProgress
                    variant="determinate"
                    size={20}
                    thickness={8}
                    value={100}
                    sx={{ ...styles.circularProgressBase, ...styles.outerCircularProgress }}
                />
                <CircularProgress disableShrink color="secondary" size={20} thickness={8} />
            </>
        );

        const statusMapping = currentStatus => {
            const icons = {
                todo: customCircularProgress,
                success: <SuccessIcon style={styles.svgIcon} />,
                failed: <ErrorIcon style={styles.svgIcon} />,
                done: <SuccessIcon style={styles.svgIcon} />,
                fail: <ErrorIcon style={styles.svgIcon} />,
                warn: <WarningIcon style={styles.svgIcon} />
            };

            return icons[currentStatus];
        };
        const renderListItem = listItem => {
            return (
                <Grid
                    container
                    sx={{
                        ...styles.gridContainerBase,
                        ...(left ? styles.leftPositionedIcon : styles.rightPositionedIcon)
                    }}
                >
                    <Grid item sx={styles.gridstatus}>
                        {statusMapping(listItem.isWarn ? "warn" : listItem.status)}
                    </Grid>
                    <Grid item sx={styles.gridItemBase}>
                        <StriimTypography variant="body4" sx={styles.typography}>
                            {listItem.text || listItem.name}
                        </StriimTypography>
                        {listItem.message &&
                            (listItem.status === "failed" || listItem.status === "fail" || listItem.isWarn) && (
                                <StriimTypography variant="body4" sx={styles.typography}>
                                    {listItem.message}{" "}
                                </StriimTypography>
                            )}
                        {listItem.viewDocumentation && listItem.viewDocumentation}
                        {listItem.helpCommand &&
                            listItem.helpCommand !== "null" &&
                            (listItem.status === "failed" || listItem.status === "fail" || listItem.isWarn) && (
                                <StriimCodeBlock
                                    code={listItem.helpCommand}
                                    copyButton={listItem.instructionType === "SQL"}
                                    isText={listItem.instructionType !== "SQL"}
                                />
                            )}
                        {listItem.children?.map(childItem => renderListItem(childItem))}
                    </Grid>
                </Grid>
            );
        };

        return <>{validationListState?.map(listItem => renderListItem(listItem))} </>;
    }
);

export default ValidationList;
