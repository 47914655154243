/**
 */
import App from "app";

var FormElements = App.module("UI.FormElements", function (FormElements, App, Backbone, Marionette, $, _) {
    var RadioGroupItemView = Backbone.Marionette.ItemView.extend({
        tagName: "span",
        template: _.template(
            '<input id="radioButton-{{id}}-{{ value }}" name="Radios" type="radio" value="{{ value }}" {{selected}}><label for="radioButton-{{id}}-{{ value }}">{{ label }}</label> '
        ),
    });

    FormElements.RadioGroup = Backbone.Marionette.CompositeView.extend({
        template: _.template('<form id="form-{{id}}"></form>'),
        className: "ui-radio-group",
        childView: RadioGroupItemView,
        childViewContainer: "form",
        events: {
            "change input": "handleClick",
        },
        handleClick: function () {
            var value = this.$el.find("input[type=radio]:checked").val();
            this.trigger("change:value", value);
        },
        onRender: function () {},
        serializeData: function () {
            return this.model.toJSON();
        },
        initialize: function (options) {
            this.collection = options.collection;
            this.collection.each(function (element, index) {
                element.set("id", options.id);
                element.set("selected", element.get("selected") ? "checked" : "");
            });
            this.model = new Backbone.Model();
            this.model.set("id", options.id);
        },
    });
});

export default FormElements;
