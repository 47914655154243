import metaStoreService from "core/services/metaStoreService/meta-store-service";

export default {
    process: function (newNodeType, nodeModel, parentNode) {
        function isEventTable() {
            return (
                nodeModel &&
                nodeModel.attributes &&
                nodeModel.attributes.metaObject &&
                nodeModel.attributes.metaObject.autoProperties &&
                nodeModel.attributes.metaObject.autoProperties.isEventTable
            );
        }

        if (newNodeType === metaStoreService.entities.CACHE && isEventTable() && parentNode) {
            nodeModel.metaObject.autoProperties = nodeModel.metaObject.autoProperties || {};

            if (parentNode.metaObject.type === "STREAM") {
                nodeModel.metaObject.autoProperties.adapter = {
                    handler: "STREAM",
                    properties: {
                        name: parentNode.metaObject.id,
                    },
                };
            }
        }
    },
};
