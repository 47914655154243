import $ from "jquery";
import _ from "underscore";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import BaseStaticControl from "./../base-static-control";
import FieldsList from "./../fields-list/fields-list";
import template from "./type-viewer.html";

var View = BaseStaticControl.View.extend({
    template: _.template(template),
    className: "typeEditor",
    events: {
        "click @ui.toggleDetails": "toggleDetails"
    },
    regions: {
        fieldsListRegion: ".fields-list-container"
    },
    ui: {
        typeName: ".type-name",
        fieldsList: ".fields-list-container",
        toggleDetails: ".toggleDetails",
        typePreview: ".type-preview"
    },

    initialize: function() {
        this.fieldsListControl = FieldsList.create();
    },

    hideDetails: function() {
        if (typeof this.ui.toggleDetails.text === "function") {
            this.ui.toggleDetails.text("View");
            this.ui.fieldsList.hide();
        }
        this.collapsed = true;
    },

    toggleDetails: function() {
        if (!this.model.value) {
            return;
        }

        var target = this.ui.toggleDetails;
        if (target.text() === "View") {
            target.text("Hide");
            this.collapsed = false;
            this.ui.fieldsList.show();
        } else {
            this.hideDetails();
        }
    },

    fieldsListVisible: function() {
        return this.ui.fieldsList.css("display") === "block";
    },

    viewTypeOf: function(metaObjectId) {
        var deferred = $.Deferred();

        if (MetaObjectConverter.isValidId(metaObjectId)) {
            metaStoreService.findById(metaObjectId).then(
                function(metaObject) {
                    if (metaObject) {
                        if (!this.ui.toggleDetails.prop) {
                            return deferred.resolve().promise();
                        }

                        var value = metaObject.dataType;

                        if (value) {
                            metaStoreService.findById(value).then(
                                function(type) {
                                    var originalFieldsList = type ? type.fields : [];
                                    this.model.originalFieldsList = originalFieldsList;
                                    this.fieldsListControl.setValue(originalFieldsList);

                                    if (typeof this.ui.toggleDetails.show !== "function") {
                                        return;
                                    }

                                    this.ui.toggleDetails.show();
                                    this.ui.typeName.html(type ? type.name : value);

                                    if (this.fieldsListVisible() || !this.collapsed) {
                                        this.ui.toggleDetails.text("Hide");
                                        this.ui.fieldsList.show();
                                    }
                                    this.ui.toggleDetails.show();
                                    this.fieldsListControl.setEnabled(false);
                                    this.fieldsListControl.setViewEnabled();
                                    deferred.resolve();
                                }.bind(this)
                            );
                        } else {
                            this.displayNotSelectedMessage();
                            deferred.resolve();
                        }
                    } else {
                        deferred.resolve();
                    }
                }.bind(this)
            );
        } else {
            this.displayNotSelectedMessage();
            this.setValue(null);
            deferred.resolve();
        }

        return deferred.promise();
    },

    displayNotSelectedMessage: function() {
        this.fieldsListControl.setValue([]);
        this.ui.fieldsList.hide();
        this.ui.toggleDetails.hide();
        this.ui.typeName.html("Not Selected");
    },

    onRender: function() {
        this.fieldsListRegion.show(this.fieldsListControl);
        BaseStaticControl.View.prototype.onRender.call(this);
    }
});

export default _.extend({}, BaseStaticControl, {
    View: View
});
