import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import BaseEditor from "app/components/common/editor/meta-object-editors/base";

var Module = function (metadata_object, options) {
    options = options || {};
    options.displayFields = options.displayFields || ["typename", "externalCacheProperties"];
    BaseEditor.apply(this, arguments);
};

Module.prototype = _.extend({}, BaseEditor.prototype, {
    addCustomFields: function () {
        var _this = this;

        let metaObjectId = this.MetaObjectModel && this.MetaObjectModel.id;

        this.fieldMapper.externalCacheProperties = UIControl.PropertyTemplate("cache")
            .extend({
                subControl: true,
                hideLabel: true,
                metaObjectId: metaObjectId,
            })
            .create();

        this.fieldMapper.typename = UIControl.Type.extend({
            hideLabel: true,
            collapsed: false,
            keyRequired: false,
        }).create();

        this.fieldMapper.typename.on(
            "type-saved",
            function () {
                this.Form.view.clearDirty(this.fieldMapper.typename.model);
            }.bind(this)
        );
    },

    postRender: function () {
        this.fieldMapper.typename.setValue(this.MetaObjectModel.typename);
        this.fieldMapper.externalCacheProperties.getRegion("handlerRegion").currentView.$el.hide(); // for external cache, no other adapter should be selected
    },
});

export default Module;
