import React from "react";
import { StriimTypography, StriimDropdown, StriimMenuItem, StriimButton } from "@striim/striim-ui";
import { Box, Divider, Grid, SvgIcon } from "@mui/material";
import numeral from "numeral";

import { styles } from "./events-tagged-info.styles";
import { SpecialChevronDown } from "../../../../../generic/icon/customIcons";

interface EventsTaggedInfoProps {
    eventsTagged: number;
    totalEvents: number;
    taggingEnabledSentinels: string[] | null;
    taggingDisabledSentinels: string[] | null;
    showBottomBorder?: boolean;
    onSentinelClick: (name: string) => void;
}

const EventsTaggedInfo: React.FC<EventsTaggedInfoProps> = ({
    eventsTagged,
    totalEvents,
    taggingEnabledSentinels,
    taggingDisabledSentinels,
    showBottomBorder = true,
    onSentinelClick
}) => {
    return (
        <>
            <Grid container p={2} gap={1} flexDirection={"column"}>
                <Grid container>
                    <StriimTypography
                        variant="caption1"
                        color="greyscale.900"
                        fontSize={14}
                        lineHeight={"20px"}
                        fontWeight={400}
                        fontFamily="Inter"
                    >
                        Events Tagged as Sensitive
                    </StriimTypography>
                </Grid>
                <Grid container>
                    <StriimTypography
                        name="tagged-events-count"
                        variant="caption1"
                        color="#5D54AE"
                        fontSize="22px"
                        lineHeight={"28px"}
                        fontFamily="Nunito"
                    >
                        {numeral(eventsTagged)
                            .format("0.[00]a")
                            .toUpperCase()}
                        /
                        {numeral(totalEvents)
                            .format("0.[00]a")
                            .toUpperCase()}
                    </StriimTypography>
                </Grid>
                <Grid container alignItems={"center"} mt={-1}>
                    <StriimTypography
                        variant="caption3"
                        color="greyscale.700"
                        lineHeight={"18px"}
                        fontFamily="Inter"
                        name="tagging-enabled-info"
                    >
                        Tagging enabled in {taggingEnabledSentinels?.length || 0}/
                        {(taggingEnabledSentinels?.length || 0) + (taggingDisabledSentinels?.length || 0)} sentinels
                    </StriimTypography>
                    <StriimDropdown
                        extendOnClick
                        placement="bottom-end"
                        content={
                            <Box display={"flex"} flexDirection={"column"} minWidth={200}>
                                {taggingEnabledSentinels?.length ? (
                                    <>
                                        <StriimTypography
                                            variant="caption3"
                                            color="greyscale.700"
                                            fontFamily="Inter"
                                            fontSize={"10px"}
                                            px={1.5}
                                            py={1}
                                        >
                                            ENABLED
                                        </StriimTypography>
                                        {taggingEnabledSentinels.map(value => (
                                            <StriimTypography
                                                key={value}
                                                variant="body4"
                                                color="greyscale.900"
                                                fontFamily="Inter"
                                                px={1.5}
                                                py={1}
                                                onClick={() => onSentinelClick(value)}
                                            >
                                                {value}
                                            </StriimTypography>
                                        ))}
                                    </>
                                ) : null}

                                <Divider variant="fullWidth" sx={{ borderColor: "greyscale.200" }} />
                                {taggingDisabledSentinels?.length ? (
                                    <>
                                        <StriimTypography
                                            variant="caption3"
                                            color="greyscale.700"
                                            fontSize={"10px"}
                                            fontFamily="Inter"
                                            px={1.5}
                                            py={1}
                                        >
                                            DISABLED
                                        </StriimTypography>
                                        {taggingDisabledSentinels.map(value => (
                                            <StriimTypography
                                                key={value}
                                                variant="body4"
                                                color="greyscale.900"
                                                fontFamily="Inter"
                                                px={1.5}
                                                py={1}
                                                onClick={() => onSentinelClick(value)}
                                            >
                                                {value}
                                            </StriimTypography>
                                        ))}
                                    </>
                                ) : null}
                            </Box>
                        }
                        closeOnSelect
                    >
                        <StriimButton variant="text" sx={styles.iconButton}>
                            <SvgIcon sx={styles.chevronIcon}>
                                <SpecialChevronDown />
                            </SvgIcon>
                        </StriimButton>
                    </StriimDropdown>
                </Grid>
            </Grid>
            {showBottomBorder && <Divider variant="fullWidth" sx={{ width: "100%", borderColor: "greyscale.200" }} />}
        </>
    );
};

export default EventsTaggedInfo;
