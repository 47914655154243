export const styles = {
    bar: {
        height: 10,
        width: 80,
        backgroundColor: "greyscale.200",
        "& .MuiLinearProgress-bar": {
            backgroundColor: "#7E7ED6"
        }
    }
};
