import utils from "core/utils";
import metaStoreService from "core/services/metaStoreService/meta-store-service";

export default {
    format: function (type) {
        if (type === metaStoreService.entities.WACTIONSTORE) {
            return "WActionStore";
        }
        if (type === metaStoreService.entities.CQ) {
            return metaStoreService.entities.CQ;
        }
        if (type === metaStoreService.entities.OPENPROCESSOR) {
            return "Open Processor";
        }
        if (type === metaStoreService.entities.EXTERNALCACHE) {
            return "External Cache";
        }
        if (type === metaStoreService.entities.EXTERNALSOURCE) {
            return "External Source";
        }
        return utils.capitalize(type);
    }
};
