import AdlsGen2 from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/adls-gen2.svg";
import Apache from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/apache.svg";
import App from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/app.svg";
import Autolayout from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/autolayout.svg";
import Avro from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/avro.svg";
import AzureEventHub from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/azure-event-hub.svg";
import AzureEventhub from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/azure-eventhub.svg";
import AzureHdInsights from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/azure-hd-insights.svg";
import AzureHdfs from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/azure-hdfs.svg";
import AzureKafka from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/azure-kafka.svg";
import AzureNewlogo from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/azure-newlogo.svg";
import AzurePostgresql from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/azure-postgresql.svg";
import AzureSynapseAnalytics from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/azure-synapse-analytics.svg";
import AzureStorage from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/azure-storage.svg";
import Azure from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/azure.svg";
import Azurepostgresql from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/azurepostgresql.svg";
import Back from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/back.svg";
import Bigquery from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/bigquery.svg";
import Binary from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/binary.svg";
import Cache1 from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/cache-1.svg";
import Cache from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/cache.svg";
import Cassandra from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/cassandra.svg";
import ChoroplethMap from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/choropleth-map.svg";
import Cisco from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/cisco.svg";
import ClouderaHive from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/cloudera-hive.svg";
import Cloudsql from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/cloudsql.svg";
import Cloudstorage from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/cloudstorage.svg";
import Cluster from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/cluster.svg";
import Cobol from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/cobol.svg";
import Collectd from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/collectd.svg";
import ComponentsOutputOracle from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/components-output-oracle.svg";
import ComponentsProcessingOpenProcessor from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/components-processing-open-processor.svg";
import Cosmosdb from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/cosmosdb.svg";
import Cq from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/cq.svg";
import DataStriim from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/data-striim.svg";
import DataWarehouse from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/data-warehouse.svg";
import Database from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/database.svg";
import Db from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/db.svg";
import DelimSeparated from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/delim-separated.svg";
import Delimiter from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/delimiter.svg";
import Databricks from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/databricks.svg";
import Description from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/description.svg";
import Email from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/email.svg";
import EventTable from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/event-table.svg";
import Exporttql from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/exporttql.svg";
import ExternalCache from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/externalcache.svg";
import ExternalSource from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/externalsource.svg";
import FileWriter from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/file-writer.svg";
import File from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/file.svg";
import Flow from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/flow.svg";
import Freeformtext from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/freeformtext.svg";
import Goldengate from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/goldengate.svg";
import GoogleAdsReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/googleads.svg";
import GooglebigqueryCopy from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/googlebigquery copy.svg";
import Googlebigquery from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/googlebigquery.svg";
import GooglecloudplatformWriter from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/googlecloudplatform-writer.svg";
import Googlecloudplatform from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/googlecloudplatform.svg";
import Googlecloudstroge from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/googlecloudstroge.svg";
import Grab from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/grab.svg";
import Hadoop from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/hadoop.svg";
import Hazelcast from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/hazelcast.svg";
import Hbase from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/hbase.svg";
import Hdfs from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/hdfs.svg";
import Hive from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/hive.svg";
import Hp from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/hp.svg";
import Http from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/http.svg";
import HubSpotReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/hubspot.svg";
import IncrementalBatchReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/incremental-batch-reader.svg";
import Jdbc from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/jdbc.svg";
import JiraReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/jira.svg";
import Jms from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/jms.svg";
import Jmx from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/jmx.svg";
import Jpa from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/jpa.svg";
import Json from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/json.svg";
import Kafka from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/kafka.svg";
import Key from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/key.svg";
import Kinesis from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/kinesis.svg";
import Kudu from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/kudu.svg";
import Lakehouse from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/lakehouse.svg";
import Launch from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/launch.svg";
import Mapr from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/mapr.svg";
import Maprdb from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/maprdb.svg";
import MariaDb from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/mariadb.svg";
import Maprfs from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/maprfs.svg";
import Maprstreams from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/maprstreams.svg";
import Memsql from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/memsql.svg";
import Mongodb from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/mongodb.svg";
import MongoCosmos from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/mongo-cosmos.svg";
import Monitor from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/monitor.svg";
import Mqtt from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/mqtt.svg";
import Mssql from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/mssql.svg";
import MultiFileReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/multi-file-reader.svg";
import Mysql from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/mysql.svg";
import NameValuePair from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/name-value-pair.svg";
import Nav from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/nav.svg";
import Netflow from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/netflow.svg";
import Node from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/node.svg";
import Nvp from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/nvp.svg";
import Objects from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/objects.svg";
import OpcUaReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/opc-ua-reader.svg";
import Opcua from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/opcua.svg";
import OpenProcessor from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/open-processor.svg";
import Oracle from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/oracle.svg";
import OutputSql from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/output-sql.svg";
import PostgrateSql from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/postgrate-sql.svg";
import PostgreSql from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/postgrate-sql.svg";
import PostgresNotselected from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/postgres-notselected.svg";
import Postgresqlcdc from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/postgresqlcdc.svg";
import Redshift from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/redshift.svg";
import Router from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/router.svg";
import Saphana from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/saphana.svg";
import S3 from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/s3.svg";
import S3Reader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/s3reader.svg";
import Salesforce from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/salesforce.svg";
import ServiceNow from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/servicenow.svg";
import SalesforcePardotReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/salesforce.svg";
import Server from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/server.svg";
import Slack from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/slack.svg";
import Snmp from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/snmp.svg";
import Snowflake from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/snowflake.svg";
import Source from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/source.svg";
import Spanner from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/spanner.svg";
import Sql from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/sql.svg";
import Start from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/start.svg";
import Status from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/status.svg";
import Store from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/store.svg";
import Stream from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/stream.svg";
import Striim from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/striim.svg";
import SysOut from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/sys-out.svg";
import Target from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/target.svg";
import Tcp from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/tcp.svg";
import Teams from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/teams.svg";
import Template from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/template.svg";
import ThreatmapDark from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/threatmap-dark.svg";
import Threatmap from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/threatmap.svg";
import Tooltip from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/tooltip.svg";
import Tpc from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/tpc.svg";
import TransformationAdduserdata from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-adduserdata.svg";
import TransformationFieldrenamer from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-fieldrenamer.svg";
import TransformationFieldsplitter from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-fieldsplitter.svg";
import TransformationJson from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-json.svg";
import TransformationRemovefieldCopy from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-removefield copy.svg";
import TransformationRemovefield from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-removefield.svg";
import TransformationSecurity from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-security.svg";
import TransformationTypedeventenricher from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-typedeventenricher.svg";
import TransformationShield from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-shield.svg";
import TransformationSentinel from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-sentinel.svg";
import TransformationTypedeventtowaevent from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-typedeventtowaevent.svg";
import TransformationTypeeventfilter from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-typeeventfilter.svg";
import TransformationTypemodifier from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-typemodifier.svg";
import TransformationValueFilter from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-value-filter.svg";
import TransformationWaeventdatamodifier from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-waeventdatamodifier.svg";
import TransformationWaeventtodwstage from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-waeventtodwstage.svg";
import TransformationWaeventtotypedevent from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-waeventtotypedevent.svg";
import TransformationWametafilterCopy from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-wametafilter-copy.svg";
import TransformationWametafilter from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/transformation-wametafilter.svg";
import Type from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/type.svg";
import Udp from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/udp.svg";
import Undeploy from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/undeploy.svg";
import VectorMap from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/vector-map.svg";
import Waction from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/waction.svg";
import WaeventToDwstage from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/waevent-to-dwstage.svg";
import Warning from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/warning.svg";
import Webalert from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/webalert.svg";
import Window from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/window.svg";
import Windows from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/windows.svg";
import Xml from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/xml.svg";
import FabricMirror from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/fabric-mirror.svg";
//DO-NOT-REMOVE-BELOW-COMMENT
//add-new-import
import YugabyteDBReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/yugabytedb.svg";
import BambooHrReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/bamboohr.svg";
import GoogleSheetsReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/googlesheets.svg";
import StripeReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/stripe.svg";
import Zendesk from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/zendesk.svg";
import ADLSReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/adls.svg";
import Intercom from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/intercom.svg";
import DataverseWriter from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/dataverse.svg";
import GoogleAnalyticsReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/googleanalytics.svg";
import LinkedInAdsReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/linkedinads.svg";
import PaymoReader from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/paymo.svg";
import Yellowbrick from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/yellowbrick.svg";
import Db2Zos from "-!svg-inline-loader?removeSVGTagAttrs=true&removeTags=true!./flow-designer-icon/db2-zos.svg";

let icons = {};
icons["adls-gen2"] = AdlsGen2;
icons["apache"] = Apache;
icons["app"] = App;
icons["application"] = App;
icons["autolayout"] = Autolayout;
icons["avro"] = Avro;
icons["azure-event-hub"] = AzureEventHub;
icons["azure-eventhub"] = AzureEventhub;
icons["azure-hd-insights"] = AzureHdInsights;
icons["azure-hdfs"] = AzureHdfs;
icons["azure-kafka"] = AzureKafka;
icons["azure-newlogo"] = AzureNewlogo;
icons["azure-postgresql"] = AzurePostgresql;
icons["azure-synapse-analytics"] = AzureSynapseAnalytics;
icons["azure-storage"] = AzureStorage;
icons["azure"] = Azure;
icons["azurepostgresql"] = Azurepostgresql;
icons["back"] = Back;
icons["bigquery"] = Bigquery;
icons["binary"] = Binary;
icons["cache-1"] = Cache1;
icons["cache"] = Cache;
icons["cassandra"] = Cassandra;
icons["choropleth-map"] = ChoroplethMap;
icons["cisco"] = Cisco;
icons["cloudera-hive"] = ClouderaHive;
icons["cloudsql"] = Cloudsql;
icons["cloudstorage"] = Cloudstorage;
icons["cluster"] = Cluster;
icons["cobol"] = Cobol;
icons["collectd"] = Collectd;
icons["components-output-oracle"] = ComponentsOutputOracle;
icons["components-processing-open-processor"] = ComponentsProcessingOpenProcessor;
icons["cosmosdb"] = Cosmosdb;
icons["cq"] = Cq;
icons["data-striim"] = DataStriim;
icons["database"] = Database;
icons["data-warehouse"] = DataWarehouse;
icons["db"] = Db;
icons["delim-separated"] = DelimSeparated;
icons["delimiter"] = Delimiter;
icons["databricks"] = Databricks;
icons["description"] = Description;
icons["email"] = Email;
icons["event-table"] = EventTable;
icons["exporttql"] = Exporttql;
icons["externalcache"] = ExternalCache;
icons["externalsource"] = ExternalSource;
icons["file-writer"] = FileWriter;
icons["file"] = File;
icons["flow"] = Flow;
icons["freeformtext"] = Freeformtext;
icons["goldengate"] = Goldengate;
icons["googlebigquery copy"] = GooglebigqueryCopy;
icons["googlebigquery"] = Googlebigquery;
icons["googlecloudplatform-writer"] = GooglecloudplatformWriter;
icons["googlecloudplatform"] = Googlecloudplatform;
icons["googlecloudstroge"] = Googlecloudstroge;
icons["grab"] = Grab;
icons["hadoop"] = Hadoop;
icons["hazelcast"] = Hazelcast;
icons["hbase"] = Hbase;
icons["hdfs"] = Hdfs;
icons["hive"] = Hive;
icons["hp"] = Hp;
icons["http"] = Http;
icons["hubspot"] = HubSpotReader;
icons["incremental-batch-reader"] = IncrementalBatchReader;
icons["jdbc"] = Jdbc;
icons["jira"] = JiraReader;
icons["jms"] = Jms;
icons["jmx"] = Jmx;
icons["jpa"] = Jpa;
icons["json"] = Json;
icons["kafka"] = Kafka;
icons["key"] = Key;
icons["kinesis"] = Kinesis;
icons["kudu"] = Kudu;
icons["lakehouse"] = Lakehouse;
icons["launch"] = Launch;
icons["mapr"] = Mapr;
icons["maprdb"] = Maprdb;
icons["maprfs"] = Maprfs;
icons["maprstreams"] = Maprstreams;
icons["memsql"] = Memsql;
icons["mongodb"] = Mongodb;
icons["mongo-cosmos"] = MongoCosmos;
icons["monitor"] = Monitor;
icons["mqtt"] = Mqtt;
icons["mssql"] = Mssql;
icons["multi-file-reader"] = MultiFileReader;
icons["mysql"] = Mysql;
icons["name-value-pair"] = NameValuePair;
icons["nav"] = Nav;
icons["netflow"] = Netflow;
icons["node"] = Node;
icons["nvp"] = Nvp;
icons["objects"] = Objects;
icons["opc-ua-reader"] = OpcUaReader;
icons["opcua"] = Opcua;
icons["mariadb"] = MariaDb;
icons["open-processor"] = OpenProcessor;
icons["oracle"] = Oracle;
icons["output-sql"] = OutputSql;
icons["postgrate-sql"] = PostgrateSql;
icons["postgreSQL"] = PostgreSql;
icons["postgres-notselected"] = PostgresNotselected;
icons["postgresqlcdc"] = Postgresqlcdc;
icons["redshift"] = Redshift;
icons["router"] = Router;
icons["saphana"] = Saphana;
icons["s3"] = S3;
icons["s3reader"] = S3Reader;
icons["salesforce"] = Salesforce;
icons["server"] = Server;
icons["slack"] = Slack;
icons["servicenow"] = ServiceNow;
icons["salesforcepardot"] = SalesforcePardotReader;
icons["snmp"] = Snmp;
icons["snowflake"] = Snowflake;
icons["source"] = Source;
icons["spanner"] = Spanner;
icons["sql"] = Sql;
icons["start"] = Start;
icons["status"] = Status;
icons["store"] = Store;
icons["stream"] = Stream;
icons["striim"] = Striim;
icons["sys-out"] = SysOut;
icons["target"] = Target;
icons["tcp"] = Tcp;
icons["teams"] = Teams;
icons["template"] = Template;
icons["threatmap-dark"] = ThreatmapDark;
icons["threatmap"] = Threatmap;
icons["tooltip"] = Tooltip;
icons["tpc"] = Tpc;
icons["transformation-adduserdata"] = TransformationAdduserdata;
icons["transformation-fieldrenamer"] = TransformationFieldrenamer;
icons["transformation-fieldsplitter"] = TransformationFieldsplitter;
icons["transformation-json"] = TransformationJson;
icons["transformation-removefield copy"] = TransformationRemovefieldCopy;
icons["transformation-removefield"] = TransformationRemovefield;
icons["transformation-security"] = TransformationSecurity;
icons["transformation-typedeventenricher"] = TransformationTypedeventenricher;
icons["transformation-shield"] = TransformationShield;
icons["transformation-sentinel"] = TransformationSentinel;
icons["sentinel"] = TransformationSentinel;
icons["transformation-typedeventtowaevent"] = TransformationTypedeventtowaevent;
icons["transformation-typeeventfilter"] = TransformationTypeeventfilter;
icons["transformation-typemodifier"] = TransformationTypemodifier;
icons["transformation-value-filter"] = TransformationValueFilter;
icons["transformation-waeventdatamodifier"] = TransformationWaeventdatamodifier;
icons["transformation-waeventtodwstage"] = TransformationWaeventtodwstage;
icons["transformation-waeventtotypedevent"] = TransformationWaeventtotypedevent;
icons["transformation-wametafilter-copy"] = TransformationWametafilterCopy;
icons["transformation-wametafilter"] = TransformationWametafilter;
icons["type"] = Type;
icons["udp"] = Udp;
icons["undeploy"] = Undeploy;
icons["vector-map"] = VectorMap;
icons["waction"] = Waction;
icons["waevent-to-dwstage"] = WaeventToDwstage;
icons["warning"] = Warning;
icons["webalert"] = Webalert;
icons["window"] = Window;
icons["windows"] = Windows;
icons["xml"] = Xml;
//DO-NOT-REMOVE-BELOW-COMMENT
//add-new-property
icons["yugabytedb"] = YugabyteDBReader;
icons["bamboohr"] = BambooHrReader;
icons["stripe"] = StripeReader;
icons["zendesk"] = Zendesk;
icons["adls"] = ADLSReader;
icons["intercom"] = Intercom;
icons["googleads"] = GoogleAdsReader;
icons["dataverse"] = DataverseWriter;
icons["yellowbrick"] = Yellowbrick;
icons["db2-zos"] = Db2Zos;
icons["googleanalytics"] = GoogleAnalyticsReader;
icons["linkedinads"] = LinkedInAdsReader;
icons["googlesheets"] = GoogleSheetsReader;
icons["paymo"] = PaymoReader;
icons["fabricmirror"] = FabricMirror;

export default function(icon) {
    if (!icons[icon]) {
        // eslint-disable-next-line no-console
        console.error("Icon not found:" + icon);
        return icons[0];
    }
    return icons[icon];
}
