import React from "react";
import PropTypes from "prop-types";
import useStyles from "./dashboard-list-header.styles";
import { Box, Grid, InputAdornment } from "@material-ui/core";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";
import { StriimTypography, StriimInputField, StriimButton } from "@striim/striim-ui";
import useDashboardsStore from "src/stores/dashboards/useDashboardsStore";
import { useObserver } from "mobx-react";
import { Search } from "@material-ui/icons";
import DisplayMode from "src/modules/apps/pages/app-list/components/display-mode/display-mode";

const DashboarListHeader = ({ openAddDashboardDialog, mode, onModeChange, sortToggled, setSortToggled }) => {
    const classes = useStyles();
    const store = useDashboardsStore();
    const namespaceOptions = useObserver(() => store.namespaceOptions);
    const getDashboardsPlural = number => {
        return number.toString() + " Dashboard" + (number === 1 ? "" : "s");
    };
    const numberOfDashboards = getDashboardsPlural(store?.filteredDashboards.length);

    return (
        <Box className={classes.wrapper}>
            <Grid container item justifyContent="space-between" className={classes.wrapperInner}>
                <Grid item container md={8} xs={6} alignItems="center">
                    <Box component={StriimTypography} variant="h1">
                        Dashboards
                    </Box>
                </Grid>
                <Grid
                    item
                    container
                    md={4}
                    xs={6}
                    alignItems="center"
                    justifyContent="flex-end"
                    className={classes.secondRowOnMd}
                >
                    <StriimButton
                        className={classes.button}
                        onClick={() => {
                            openAddDashboardDialog(true);
                        }}
                        data-test-id="add-dashboard-button"
                        variant="primary"
                        startIcon={<AddIcon />}
                    >
                        Add Dashboard
                    </StriimButton>
                </Grid>
            </Grid>
            <Grid container item justifyContent="space-between" className={classes.wrapperInner}>
                <Grid container item xs={4} justifyContent="space-between" direction="row">
                    <Box className={`${classes.dashboardName} ${classes.dashboardSearchInput}`}>
                        <StriimInputField
                            placeholder="Search By Dashboard name"
                            onChange={searchValue => {
                                store.setFilters({ textFilter: searchValue });
                            }}
                            debounceTime={800}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search opacity={0.45} />
                                    </InputAdornment>
                                ),
                                "data-test-id": "dashboard-search-input"
                            }}
                        />
                    </Box>
                </Grid>
                <Grid
                    container
                    item
                    xs={3}
                    justifyContent="space-between"
                    direction="row"
                    className={classes.secondRowOnMd}
                >
                    <Box className={classes.dashboardNameSpaceInput}>
                        <StriimInputField
                            select
                            SelectProps={{
                                options: namespaceOptions,
                                isSearchable: true
                            }}
                            placeholder="Namespace"
                            onChange={namespace => store.setFilters({ nameSpaceFilter: namespace?.value })}
                        />
                    </Box>
                </Grid>
                <Grid
                    container
                    item
                    xs={5}
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{ cursor: "pointer", userSelect: "none" }}
                >
                    <div onClick={() => setSortToggled(!sortToggled)}>{!sortToggled ? "Sort by: Dashboard name (A-Z)" : "Sort by: Dashboard name (Z-A)"}</div>
                    <div
                        style={
                            sortToggled
                                ? {
                                      transform: "rotate(180deg)",
                                      width: 24,
                                      height: 24
                                  }
                                : { width: 24, height: 24 }
                        }
                    >
                        <img
                            src="src/modules/apps/pages/homepage/resources/images/sort-arrow.png"
                            style={{ width: 24, height: 24 }}
                        />
                    </div>
                    <Box>
                        <DisplayMode mode={mode} onModeChange={onModeChange} />
                    </Box>
                </Grid>
            </Grid>
            <Box className={classes.dashboardSubHeaderWrapper}>
                <Box className={classes.dashboardCountContainer}>
                    <Box className={classes.dashboardCount}>
                        <StriimTypography color="greyscale.700" variant="caption" data-test-id="dashboard-count">
                            {numberOfDashboards}
                        </StriimTypography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

DashboarListHeader.propTypes = {
    openAddDashboardDialog: PropTypes.func,
    mode: PropTypes.oneOf(["grid", "list"]),
    onModeChange: PropTypes.func,
    sortToggled: PropTypes.bool,
    setSortToggled: PropTypes.func
};

export default DashboarListHeader;
