export const sxStyles = {
    parentGrid: {
        border: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
        borderRadius: 2
    },
    iconGrid: {
        width: 40,
        alignItems: "center",
        justifyContent: "center",
        display: "grid",
    },
    childGrid: {
        cursor: "pointer",
        flexGrow: 1,
    },
    childGridContainer: {
        alignItems: "center",
        gap: 4,
        padding: ({ spacing }) => spacing(0,1.25)
    },
    fullWidth: {
        width: "90%"
    },
    smallWidth: {
        width: "7%"
    },
    typography :{
        color : ({palette}) => palette.greyscale[900]
    },
    inverted: {
        transform: "rotate(135deg)"
    },
    expanded: {
        transition: "max-height 0.40s ease-in"
    },
    collapsed: {
        maxHeight: 0,
        overflow: "hidden",
        transition: "max-height 0.20s ease-out"
    },
    showBorder : {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    hideBorder : {
        borderBottom : 0
    },
    collapsibleIcon : {
        fill: "none",
        fontSize: 18,
        
    }
}