import React from "react";
import { Grid, Box } from "@mui/material";

import { StriimTypography } from "@striim/striim-ui";

import classes from "./templates-no-results.styles";
import { useNavigate } from "react-router-dom";

const NoTemplates = () => {
    const navigate = useNavigate();
    return (
        <Grid container direction="column" alignItems="center" sx={classes.wrapper}>
            <Box
                sx={classes.imgContainer}
                component="img"
                src="src/modules/apps/pages/create-app-page/images/Empty_Templates.svg"
            />
            <Box sx={classes.imgDescriptionContainer}>
                <Box component={StriimTypography} variant="body" sx={classes.noResultsText}>
                    No Search Results found
                </Box>
                <Box sx={classes.subtitleContainer}>
                    <StriimTypography sx={classes.imgDescription} variant="body4">
                        {"Can't find what you're looking for? Click "}
                    </StriimTypography>
                    <StriimTypography
                        sx={classes.imgDescription}
                        onClick={() => navigate("#/create-app/name-app")}
                        variant="body4"
                        sx={classes.link}
                    >
                        here
                    </StriimTypography>
                    <StriimTypography sx={classes.imgDescription} variant="body4">
                        {" to go to the flow designer and build an application manually."}
                    </StriimTypography>
                </Box>
            </Box>
        </Grid>
    );
};

export default NoTemplates;
