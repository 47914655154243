import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, InputAdornment } from "@material-ui/core";
import { components } from "react-select";
import Search from "@material-ui/icons/Search";
import { StriimButton, StriimTypography, StriimInputField, StriimLink } from "@striim/striim-ui";
import VaultsService from "./vaults-service";
import Dictionary from "app/components/common/helpable/online-help-dictionary";
import DocumentationIcon from "src/generic/icon/manage-striim/documentationIcon.svg";
import AddIcon from "app/images/striimline/add-alerts.svg";
import { SvgIcon } from "@mui/material";

const useStyles = makeStyles(({ spacing }) => ({
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: spacing(2.5)
    },
    selectImage: {
        maxWidth: 24,
        maxHeight: 24,
        marginRight: 30
    },
    typography: {
        marginBottom: 23
    },
    vaultButton: {
        display: "flex",
        justifyContent: "flex-end",
        minWidth: "fit-content"
    },
    header: {
        display: "flex",
        alignItems: "center",
        gap: 4
    },
    documentationIcon: {
        color: "#FFF",
        fontSize: 20,
        display: "flex"
    }
}));

const VaultsHeader = ({ setIsModalShown, setSearchQuery, setActiveFilter }) => {
    const classes = useStyles();

    return (
        <>
            <Grid container className={classes.headerContainer} data-test-id="vaults-header">
                <Grid item xs={8}>
                    <Grid className={classes.header}>
                        <StriimTypography variant="h2">Vaults</StriimTypography>
                        <a
                            href={Dictionary.get()["MANAGE_STRIIM_VAULTS"].href}
                            target="_blank"
                            className={classes.documentationIcon}
                        >
                            <DocumentationIcon />
                        </a>
                    </Grid>
                    <StriimTypography variant="body" color="greyscale.600" style={{ fontSize: 12 }}>
                        By storing keys in Striim Vault, HashiCorp Vault, Azure Key Vault and Google Secret Manager, you
                        can use the keys connecting adapters without storing this information locally.
                    </StriimTypography>
                </Grid>
                <Grid item xs={4} className={classes.vaultButton}>
                    <StriimButton
                        variant="primary"
                        onClick={() => setIsModalShown(true)}
                        data-test-id="vaults-add-vault-button"
                        startIcon={<SvgIcon component={AddIcon} sx={{ fill: "none" }} />}
                    >
                        New vault
                    </StriimButton>
                </Grid>
            </Grid>
            <Grid
                container
                style={{
                    width: "70%",
                    gap: "8px",
                    marginBottom: 30
                }}
            >
                <Grid item xs={5}>
                    <StriimInputField
                        id="data-test-id-vault-filter-input"
                        onChange={string => {
                            if (string.length) {
                                setSearchQuery(string);
                            } else {
                                setSearchQuery();
                            }
                        }}
                        placeholder="Search by vault name or namespace"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search opacity={0.45} />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <StriimInputField
                        name="vaultFilterType"
                        select
                        placeholder="All Vaults"
                        onChange={evt => {
                            setActiveFilter(evt?.value);
                        }}
                        SelectProps={{
                            options: VaultsService.getVaultTypes(),
                            menuPosition: "fixed",
                            components: {
                                Option: props => (
                                    <components.Option {...props}>
                                        <Grid container>
                                            <Grid item>
                                                <img className={classes.selectImage} src={props.data.icon} />
                                            </Grid>
                                            <Grid item>
                                                <div>{props.label}</div>
                                            </Grid>
                                        </Grid>
                                    </components.Option>
                                )
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default VaultsHeader;
