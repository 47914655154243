import React from "react";
import { Grid } from "@mui/material";
import { StriimTypography, StriimMessageBox } from "@striim/striim-ui";
import xss from "xss";

import { TestConnectionState, TestConnection } from "./ai-engine";
import StriimCircularProgress from "../../../../../apps/pages/manage-striim/connection-profiles/components/circular-progress/circular-progress";
import { AlertCircle } from "../../../../../../generic/icon/customIcons";
import { formatMessage } from "../../../../../../../app/components/flow/designer/appErrors/appExceptions";

const styles = {
    messageBox: {
        backgroundColor: ({ palette }) => palette.critical[50],
        borderColor: ({ palette }) => palette.critical[100],
        wordBreak: "break-word",
        "& svg": {
            fontSize: "20px",
            fill: ({ palette }) => palette.critical[50]
        },
        "& a": {
            padding: 0
        }
    }
};

interface AIEngineTestConnectionProps {
    testConnectionState: TestConnection;
}

const AIEngineTestConnection: React.FC<AIEngineTestConnectionProps> = ({ testConnectionState }) => {
    return testConnectionState.value === TestConnectionState.INPROGRESS ? (
        <Grid container alignItems={"center"} justifyContent={"center"} height={300} gap={1}>
            <StriimCircularProgress size={24} />
            <StriimTypography variant="h3" color="primary.800">
                Testing Connection
            </StriimTypography>
        </Grid>
    ) : testConnectionState.value === TestConnectionState.ERROR && testConnectionState.errorMessage ? (
        <Grid container px={3} pt={2}>
            {Array.isArray(testConnectionState.errorMessage) &&
                testConnectionState.errorMessage?.map((response, index) => {
                    let errorMessage;
                    try {
                        errorMessage = formatMessage(JSON.parse(response.jsonErrorDescription).Summary);
                    } catch (error) {
                        errorMessage = "Error testing connection.";
                    }
                    return (
                        !response.result && (
                            <StriimMessageBox
                                key={index}
                                text={
                                    <StriimTypography
                                        variant="caption3"
                                        fontFamily="Inter"
                                        color="greyscale.900"
                                        data-test-id="error-box-message"
                                        dangerouslySetInnerHTML={{ __html: xss(errorMessage) }}
                                    />
                                }
                                type="ERROR"
                                customIcon={<AlertCircle />}
                                customCardStyles={{ ...styles.messageBox }}
                            />
                        )
                    );
                })}
        </Grid>
    ) : null;
};

export default AIEngineTestConnection;
