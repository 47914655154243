import _ from "underscore";
import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var namespaceModule = {};

namespaceModule.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "NAMESPACE",
        nsName: "Global",
    },
    validate: function () {
        var validationError = {};
        var name = this.get("name");
        if (baseMetaObject.Model.prototype.validate) {
            validationError = baseMetaObject.Model.prototype.validate.call(this);
            validationError = validationError === undefined ? {} : validationError;
        }
        if (!name) {
            validationError["name"] = "Value for name is required";
        } else if (name.indexOf(" ") !== -1) {
            validationError["name"] = "Please enter a name without spaces.";
        } else if (/^[a-zA-Z0-9_ ]*$/.test(name) == false) {
            validationError["name"] = "Please enter a name with only alphanumeric characters or underscore.";
        } else if (name.toUpperCase() == "GLOBAL") {
            validationError["name"] = "Global is reserved for system use. Please use another namespace ";
        }
        return _.isEmpty(validationError) ? undefined : validationError;
    },
});

namespaceModule.Collection = baseMetaObject.Collection.extend({
    model: namespaceModule.Model,
});

export default namespaceModule;
