export function parseTitle(issue) {
    if (!issue) {
        return "";
    }

    const regex = /Problem executing call:(.*?):/gim;
    const title = regex.exec(issue);

    if (title === null) {
        return "Server Exception";
    } else if (title.length === 2) {
        return title[1].trim();
    }
    return "Server Exception";
}
