import App from "app";
import api from "core/api/api";
import $ from "jquery";
import NodeViewBase from "./nodeViewBase";
import GraphNodeModel from "./graphNodeModel";
import MouseClickHandler from "../mouseClickHandler";
import template from "./templates/flowGraphNodeTemplate.html";

var FlowGraphNodeModel = GraphNodeModel.extend({
    defaults: {
        name: String,
    },
});

var FlowGraphNodeView = NodeViewBase.extend({
    template: _.template(template),
    tagName: "div",
    className: "node flow-node jsplumb-draggable",

    ui: {
        node: "", // whole $el
    },

    events: {
        //'click': 'onNodeClick'
        "mousedown @ui.node": "onNodeMouseDown",
        "mouseup @ui.node": "onNodeMouseUp",
    },

    onRender: function () {
        this.$el.attr("id", this.model.elementId);
        this.clickHandler = new MouseClickHandler(this.$el, this.onNodeClick.bind(this), null);
    },

    onNodeClick: function (e) {
        //e.stopPropagation();
        //e.preventDefault();
        this.trigger("flow-node-click", this.model);
    },

    setActive: function () {
        this.$el.addClass("active");
    },
});

export default {
    View: FlowGraphNodeView,
    Model: FlowGraphNodeModel,
};
