import React, { Dispatch, SetStateAction, useState } from "react";
import { StriimLink, StriimTypography, StriimButton, StriimInputField } from "@striim/striim-ui";
import { Box, Grid, SvgIcon, InputAdornment } from "@mui/material";
import { Search, Clear } from "@mui/icons-material";
import { Reset, Sentinel } from "../../../../../../generic/icon/customIcons";
import NewTab from "app/images/New Tab.svg";
import styles from "./default-actions.styles";
import IdentifierActionsTable, {
    TableData,
    MaskingOption,
    ImportanceOption
} from "../../../../components/identifier-actions-table/identifier-actions-table";
import dictionary from "../../../../../../../app/components/common/helpable/online-help-dictionary";

interface DefaultActionsProps {
    tablesData: TableData[];
    maskingOptions: MaskingOption[];
    importanceOptions: ImportanceOption[];
    showSearch?: boolean;
    isAdmin: boolean;
    rowData: TableData[];
    setRowData: Dispatch<SetStateAction<TableData[]>>;
    partialMaskingSDIOptions: string[];
    defaultActionsData: TableData[];
}

const DefaultActions: React.FC<DefaultActionsProps> = ({
    tablesData = [],
    maskingOptions = [],
    importanceOptions = [],
    isAdmin = false,
    showSearch = true,
    partialMaskingSDIOptions = [],
    rowData = [],
    setRowData = () => {},
    defaultActionsData = []
}) => {
    const [searchQuery, setSearchQuery] = useState<string>("");
    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" marginBottom={2}>
                <Grid item sx={styles.headerContainer}>
                    <SvgIcon component={Sentinel} sx={styles.guardianIcon} />
                    <StriimTypography variant="h3" color="primary.700" sx={{ marginLeft: 1 }}>
                        Sentinel AI: Default Settings for Sensitive Data Identifiers
                    </StriimTypography>
                </Grid>
                {isAdmin && (
                    <Grid item>
                        <StriimButton
                            startIcon={<SvgIcon component={Reset} sx={styles.resetIcon} />}
                            onClick={() => {
                                setRowData(defaultActionsData);
                            }}
                        >
                            Reset
                        </StriimButton>
                    </Grid>
                )}
            </Grid>
            <Box sx={styles.descriptionbox}>
                <StriimTypography variant="body4" color="greyscale.700" letterSpacing="0.14px">
                    {isAdmin
                        ? `As an admin, you can set the recommended actions for when Sentinel detects sensitive data.
                            When your users add Sentinel to an app, it comes preconfigured with these actions, which they can modify as needed.`
                        : `These are recommended actions when Sentinel detects sensitive data.
                            When you add Sentinel to your app, it comes preconfigured with these actions, that you can modify as needed.`}
                    <StriimLink
                        endIcon={<NewTab style={styles.newTabIcon} />}
                        sx={styles.learnLink}
                        href={dictionary.get()["AI_INSIGHTS_SENTINEL"].href}
                        target="_blank"
                    >
                        <StriimTypography color="inherit" variant="caption3">
                            Learn more
                        </StriimTypography>
                    </StriimLink>
                </StriimTypography>
            </Box>
            <Box gap={2} display={"grid"}>
                {showSearch && (
                    <StriimInputField
                        placeholder="Search by Data Identifier"
                        value={searchQuery}
                        onChange={setSearchQuery}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search opacity={0.45} />
                                </InputAdornment>
                            ),
                            endAdornment: searchQuery && (
                                <InputAdornment position="end">
                                    <Clear cursor="pointer" onClick={() => setSearchQuery("")} />
                                </InputAdornment>
                            )
                        }}
                    />
                )}
                <IdentifierActionsTable
                    tablesData={tablesData}
                    rowData={rowData}
                    setRowData={setRowData}
                    maskingOptions={maskingOptions}
                    importanceOptions={importanceOptions}
                    isEditable={isAdmin}
                    isSelectable={isAdmin}
                    isSortable={!isAdmin}
                    customHeight={800}
                    searchQuery={searchQuery}
                    isSettingsPage={true}
                    partialMaskingSDIOptions={partialMaskingSDIOptions}
                />
            </Box>
        </>
    );
};

export default DefaultActions;
