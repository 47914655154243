import App from "app";
import api from "core/api/api";
import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import flow from "core/services/metaStoreService/metaObjects/flow";

var module = {};

module.Model = flow.Model.extend({
    defaults: {
        type: "APPLICATION",
        strategy: String,
        deploymentGroup: String,
        flow: String,
        FailOverRule: String,
        importStatements: Array,
        objects: Object,
        deploymentPlan: Array,
        recoveryType: Number,
        recoveryPeriod: Number,
        encrypted: Boolean,
        flowStatus: String,
        eHandlers: Object,
        notifyEmail: Array,
        owner: String,
        eventsToPreview: Number,
        deploymentInfo: Array,
        isFlowDesignerCompatible: Boolean,
        isDataValidationEnabled: Boolean,
        exceptionstoreName: String,
        enableAutoResume: Boolean,
        autoResumeRetryInterval: Number,
        autoResumeRetryCount: Number,
    },
    properties: {
        applicationDashboardId: function () {
            var dashboard = "DASHBOARD";
            var dashboards = this.objects[dashboard];
            return dashboards ? dashboards[0] : null;
        },
    },
    validate: function () {
        var validationError;
        var name = this.name;
        var nsName = this.nsName;

        if (flow.Model.__super__.validate) {
            validationError = flow.Model.__super__.validate.call(this);
            validationError = validationError === undefined ? {} : validationError;
        }

        var nonAlphanumericOrUnderscore = /\W/g;

        if (!name) {
            validationError.name = "Value for name is required";
        } else if (name.indexOf(" ") !== -1) {
            validationError.name = "Please enter a name without spaces.";
        } else if (nonAlphanumericOrUnderscore.test(name)) {
            validationError.name = "Please enter a name with only alphanumeric or underscore characters.";
        }
        if (!nsName) {
            validationError.nsName = "Value for namespace is required";
        } else if (nsName.indexOf(" ") !== -1) {
            validationError.nsName = "Please enter a namespace without spaces.";
        } else if (nonAlphanumericOrUnderscore.test(nsName)) {
            validationError.nsName = "Please enter a namespace with only alphanumeric or underscore characters.";
        } else if (!isNaN(parseInt(nsName))) {
            validationError.nsName = "Namespace cannot start with a number";
        } else if (nsName.toUpperCase() === "GLOBAL") {
            validationError.nsName = "Global is reserved for system use. Please use another namespace ";
        }

        return _.isEmpty(validationError) ? undefined : validationError;
    },
});

module.Collection = flow.Collection.extend({
    model: module.Model,
    comparator: function (model) {
        return model.get("ctime");
    },
});

export default module;
