import _ from "underscore";
import Marionette from "marionette";
import $ from "jquery";
import App from "app";
import template from "./templates/sidebar.html";

var SidebarViewBase = Marionette.LayoutView.extend({
    template: _.template(template),

    regions: {
        editors: ".editors",
        exceptions: ".exception-items"
    },

    ui: {
        editors: ".editors",
        previewButton: ".preview-button",
        metadataButton: ".metadata-modal-button",
        exceptionsContainer: ".exceptions-container",
        exceptionsClear: "#clearBtn"
    },

    onSidebarResize: function(extraOffset) {
        var exceptionContainer = this.$el.find("div.exceptions-container");
        var actionsBar = this.$el.find("div.actions");

        if (this.$el.find(".exception-message").hasClass("expanded")) {
            exceptionContainer.css({ "max-height": "450px", overflow: "auto" });
        } else {
            exceptionContainer.css({ "max-height": "unset", overflow: "unset" });
        }

        var newHeight =
            this.$el.parent().height() - //height of sidebar
            (actionsBar.is(":visible") ? actionsBar.outerHeight() : 0) -
            (exceptionContainer.is(":visible")
                ? exceptionContainer.height() + sidebarExceptionHeightOffset +
                  (actionsBar.is(":visible") ? 0 : parseInt(exceptionContainer.css("bottom")))
                : 0);

        if (extraOffset) {
            newHeight -= extraOffset;
        }

        this.$el.find("div.editors>div>ul.controls").css("height", newHeight + "px");
    },

    initSideBarResizer: function() {
        var _this = this;

        // the editors containers height has to be calculated in javascript

        setTimeout(function() {
            _this.onSidebarResize();
        }, 100);
        $(window).resize(function() {
            _this.onSidebarResize();
        });
        this.listenTo(App.vent, "app:errors:exceptions-container:resized", function() {
            _this.onSidebarResize();
        });
    }
});

export default SidebarViewBase;

// Temporary solution for [DEV-41432]
const sidebarExceptionHeightOffset = -30;
