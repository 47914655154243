/*
 * Plugin/snippet to make select2 behave like a combobox
 * Inspired by https://github.com/ivaynberg/select2/issues/100
 * Usage:
    $('#myinput').select2_combobox({
        choices: [
            { id: "foo", text: "Foo"},
            { id: "bar", text: "Bar"}
        ], // Existing choices
        new_item_prefix: 'New namespace:', // Optional. What to show in front of custom entry in list as it is typed
        search_placeholder: 'Search or type a new name', // Optional. Placeholder/hint to display in search field
        persist_custom_entries: false // Add newly-typed entries to dropdown?,
        default_value: "foo"
    });
 */

$.fn.select2_combobox = function (opts) {
    opts || (opts = {});

    return this.each(function () {
        var $select = $(this);
        var choices = opts.choices;

        // Select the first value
        $select.val(opts.default_value);

        // Preserve any select2 options passed in
        var select2_config = {};
        var blacklist = ["choices", "new_item_prefix", "search_placeholder", "persist_custom_entries"];
        for (var key in opts) {
            if (blacklist.indexOf(key) == -1) select2_config[key] = opts[key];
        }

        // Setup select2
        $(this).addClass("combobox");
        var formatter = function (object, container, query) {
            if (object.custom && opts.new_item_prefix) {
                return '<span class="secondary">' + opts.new_item_prefix + "</span> " + object.text;
            }
            return object.text;
        };
        var combobox_config = {
            allowClear: true,
            minimumResultsForSearch: 0,
            initSelection: function (element, callback) {
                // the input tag has a value attribute preloaded that points to a preselected movie's id
                // this function resolves that id attribute to an object that select2 can render
                // using its formatResult renderer - that way the movie name is shown preselected
                var id = $(element).val();
                callback({ id: id, text: id });
            },
            formatResult: formatter,
            formatSelection: formatter,
            dropdownCssClass: "combobox",
            combobox: true,
            selectOnBlur: true,
            createSearchChoice: function (term, data) {
                for (var choice of choices) {
                    if (choice.text && choice.text.toLowerCase() === term.toLowerCase()) {
                        return undefined;
                    }
                }
                return { id: term, text: term, custom: true };
            },
            query: function (options) {
                var results = [];
                var text = options.term;
                results[0] = {
                    text: "Suggested",
                    children: [],
                };
                results[1] = {
                    text: "Other Available",
                    children: [],
                };
                _.each(choices, function (choice, i) {
                    if (choice.text.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
                        results[0].children.push(choice);
                    } else {
                        results[1].children.push(choice);
                    }
                });
                if (results[1].children.length === 0) {
                    results.pop();
                }
                if (results[0].children.length === 0) {
                    results.shift();
                }
                options.callback({
                    more: false,
                    results: results,
                });
            },
            data: choices,
        };

        // Extend the select2_config with the combobox config above
        select2_config = $.extend(combobox_config, select2_config);

        // Create the select2
        $select.select2(select2_config);

        // If persist entries is true, add the new entry permanently to the list
        if (opts.persist_custom_entries) {
            $select.on("change", function () {
                // Add new items to the collection of choices
                var result = $(this).select2("val"),
                    i,
                    found = false;
                for (i = 0; i < choices.length; i++) {
                    if (choices[i].id.localeCompare(result) === 0) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    choices.push({ id: result, text: result });
                }
            });
        }

        // Add the input field

        var $input = $select.select2("container").find(".select2-search input");
        // Set search placeholder
        if (opts.search_placeholder) $input.attr("placeholder", opts.search_placeholder);
        $input.appendTo($select.select2("container"));

        $select.select2("val", "");

        $input.on("focus", function () {
            $select.select2("open");
        });
        $input.on("blur", function () {
            $select.select2("container").find(".select2-focusser").blur();
        });

        $input.on("keyup", function () {
            var val = ($(this).val() || "").trim();
            if (val.length > 0) {
                $select.select2("open");
            }
        });

        $input.on("keydown", function (e) {
            if (e.keyCode == 13) {
                $input.blur();
            }
        });

        // add X icon to combobox
        const $abbr = $select.select2("container").find("abbr.select2-search-choice-close");
        $abbr.appendTo($select.select2("container"));
        $abbr.on("click", function () {
            $select.select2("val", "");
            $input.val("");
            //$input.change();
            $select.change();
        });
        $abbr.toggleClass("hidden-element", !opts.default_value);

        $select.on("change", function () {
            const result = $(this).select2("val");
            $abbr.toggleClass("hidden-element", !result);
        });
    });
};
