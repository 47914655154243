import React, { useEffect } from "react";
import { Download, PlayerPlay, PlayerStop, RocketOff } from "../../../../../../../generic/icon/customIcons";
import { ArrowUpward } from "../../../../../../../generic/icon/customIcons";
import { StriimTypography } from "@striim/striim-ui-v2";
import { styles } from "./buttongroup.styles";
import api from "../../../../../../../../core/api/api";
import growl from "../../../../../../../../app/components/common/growl";
import {
    downloadAllReports,
    downloadAutomatedReport,
    downloadILReport,
    downloadReport,
    downloadTablesReport
} from "../services/download-reports.service";
import Tracker from "../../../../../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../../../../core/services/tracker/constants";
import { sortAutomatedApps } from "../../automated-app-group/utils/progress-details.helper";

const getDownloadToolTipContent = (isSchemaReportReady, isILReportReady) => {
    if (!isILReportReady && !isSchemaReportReady) return null;
    const toolTipText = isILReportReady
        ? "Download the IL report here"
        : isSchemaReportReady
        ? "Download the schema creation report here"
        : null;

    return (
        <div>
            <div style={styles.downloadToolTipIcon}>
                <ArrowUpward fontSize="16" />
            </div>
            <StriimTypography variant="caption3" color="greyscale.50">
                {toolTipText}
            </StriimTypography>
        </div>
    );
};

export const useGetButtonInfo = automatedGroupStore => {
    const downloadToolTipContent = getDownloadToolTipContent(
        automatedGroupStore?.isSchemaReportReady,
        automatedGroupStore?.isILReportReady
    );
    const downloadToolTipDuration =
        automatedGroupStore?.showToolTipforReport &&
        (automatedGroupStore?.isSchemaReportReady || automatedGroupStore?.isILReportReady)
            ? 3000
            : null;

    useEffect(() => {
        setTimeout(() => {
            //Tooltip should visible only once after the report is ready else the value
            // will be true and tooltip would be visible for every render
            automatedGroupStore?.setShowToolTipforReport(false);
        }, downloadToolTipDuration);
    }, [automatedGroupStore?.isILReportReady, automatedGroupStore?.isSchemaReportReady]);

    const trackEventClicked = (buttonClicked, appType) => {
        Tracker.getInstance().track(TRACKER_STRINGS.schema.automatedAppGroup, {
            event: TRACKER_STRINGS.eventClicked.automatedAppGroup,
            buttonClicked: buttonClicked,
            appType: appType
        });
    };
    return {
        START: {
            primaryBtnlabel: "Start",
            primaryBtnIcon: <PlayerPlay style={styles.iconBase} />,
            dropdownBtnToolTip: "Deploy only (Start at a later time)",
            primayBtnOnClick: () => {
                automatedGroupStore?.setIsDeployAndRunModalVisible(true);
            },
            dropdownList: [
                {
                    title: "Start",
                    description: "Specify the nodes where you want to deploy the automated pipeline and start it.",
                    default: true,
                    onClick: () => {
                        automatedGroupStore?.setIsDeployAndRunModalVisible(true);
                        trackEventClicked("Start", "IL & CDC");
                    }
                },
                {
                    title: "Deploy only",
                    description:
                        "Specify and dedicate resources for your automated pipeline. You can start the automated pipeline at a later time.",
                    onClick: () => {
                        automatedGroupStore?.setIsDeployModalVisible(true);
                        trackEventClicked("Deploy only", "IL & CDC");
                    }
                }
            ]
        },
        START_ONLY: {
            primaryBtnlabel: "Start",
            primaryBtnIcon: <PlayerPlay style={styles.iconBase} />,
            primayBtnOnClick: async () => {
                try {
                    automatedGroupStore.setisAppTransitioning(true);
                    const appGroupFQN = automatedGroupStore?.groupName?.id;
                    await api.runILCDC(appGroupFQN);
                } catch (e) {
                    console.log(e);
                    growl.error(e);
                } finally {
                    automatedGroupStore.setisAppTransitioning(false);
                    trackEventClicked("Start", "IL & CDC");
                }
            }
        },
        IL_START_ONLY: {
            primaryBtnlabel: "Start",
            primaryBtnIcon: <PlayerPlay style={styles.iconBase} />,
            primayBtnOnClick: async () => {
                try {
                    automatedGroupStore.setisAppTransitioning(true);
                    const appGroupFQN = automatedGroupStore?.groupName?.id;
                    await api.runILCDC(appGroupFQN);
                } catch (e) {
                    console.log(e);
                    growl.error(e);
                } finally {
                    automatedGroupStore.setisAppTransitioning(false);
                    trackEventClicked("Start", "IL");
                }
            }
        },
        UNDEPLOY: {
            primaryBtnlabel: "Undeploy",
            primaryBtnIcon: <RocketOff style={styles.iconBase} />,
            primayBtnOnClick: async () => {
                try {
                    automatedGroupStore.setisAppTransitioning(true);
                    const appGroupFQN = automatedGroupStore?.groupName?.id;
                    await api.undeployILCDC(appGroupFQN);
                } catch (e) {
                    growl.error(e);
                } finally {
                    automatedGroupStore.setisAppTransitioning(false);
                    trackEventClicked("Undeploy", "IL & CDC");
                }
            }
        },
        IL_STOP: {
            primaryBtnlabel: "Stop",
            primaryBtnIcon: <PlayerStop style={styles.iconBase} />,
            primayBtnOnClick: () => {
                automatedGroupStore?.setIsConfirmationModalVisible(true, "default");
                trackEventClicked("Stop", "IL");
            },
            dropdownList: [
                {
                    title: "Stop",
                    description: "Discard inflight data, and immediately stop and undeploy the automated pipeline.",
                    default: true,
                    onClick: () => {
                        automatedGroupStore?.setIsConfirmationModalVisible(true, "default");
                        trackEventClicked("Stop", "IL");
                    }
                },
                {
                    title: "Stop without undeploying",
                    description:
                        "Discard inflight data and immediately stop the automated pipeline but keep it deployed on the specified nodes.",
                    onClick: () => {
                        automatedGroupStore?.setIsConfirmationModalVisible(true, "undeploy");
                        trackEventClicked("Stop without undeploying", "IL");
                    }
                }
            ]
        },
        IL_RESUME: {
            primaryBtnlabel: "Resume",
            primaryBtnIcon: <PlayerPlay style={styles.iconBase} />,
            primayBtnOnClick: async () => {
                try {
                    automatedGroupStore.setisAppTransitioning(true);
                    const appGroupFQN = automatedGroupStore?.groupName?.id;
                    await api.resumeILCDC(appGroupFQN);
                } catch (e) {
                    console.log(e);
                    growl.error(e);
                } finally {
                    automatedGroupStore.setisAppTransitioning(false);
                    trackEventClicked("Resume", "IL");
                }
            }
        },
        IL_UNDEPLOY: {
            primaryBtnlabel: "Undeploy",
            primaryBtnIcon: <RocketOff style={styles.iconBase} />,
            primayBtnOnClick: async () => {
                try {
                    automatedGroupStore.setisAppTransitioning(true);
                    const appGroupFQN = automatedGroupStore?.groupName?.id;
                    await api.undeployILCDC(appGroupFQN);
                } catch (e) {
                    growl.error(e);
                } finally {
                    automatedGroupStore.setisAppTransitioning(false);
                    trackEventClicked("Undeploy", "IL");
                }
            }
        },
        CDC_START: {
            primaryBtnlabel: "Start",
            primaryBtnIcon: <PlayerPlay style={styles.iconBase} />,
            primayBtnOnClick: () => {
                automatedGroupStore?.setIsDeployAndRunModalVisible(true);
                trackEventClicked("Start", "CDC");
            }
        },
        CDC_START_ONLY: {
            primaryBtnlabel: "Start",
            primaryBtnIcon: <PlayerPlay style={styles.iconBase} />,
            primayBtnOnClick: async () => {
                try {
                    automatedGroupStore.setisAppTransitioning(true);
                    const appGroupFQN = automatedGroupStore?.groupName?.id;
                    await api.runILCDC(appGroupFQN);
                } catch (e) {
                    growl.error(e);
                } finally {
                    automatedGroupStore.setisAppTransitioning(false);
                }
            }
        },
        CDC_STOP: {
            primaryBtnlabel: "Stop",
            primaryBtnIcon: <PlayerStop style={styles.iconBase} />,
            primayBtnOnClick: () => {
                automatedGroupStore?.setIsConfirmationModalVisible(true);
            },
            dropdownList: [
                {
                    title: "Stop",
                    description: "Discard inflight data, and immediately stop and undeploy the automated pipeline.",
                    default: true,
                    onClick: () => {
                        automatedGroupStore?.setIsConfirmationModalVisible(true);
                        trackEventClicked("Stop", "CDC");
                    }
                },
                {
                    title: "Write inflight data to target and stop (Quiesce)",
                    description:
                        "This may take upto few minutes as as Striim writes the inflight data to the target before stopping and undeploying the automated pipeline",
                    onClick: () => {
                        automatedGroupStore?.setIsConfirmationModalVisible(true, "inflight");
                    }
                },
                {
                    title: "Stop without undeploying",
                    description:
                        "Discard inflight data and immediately stop the automated pipeline but keep it deployed on the specified nodes.",
                    onClick: () => {
                        automatedGroupStore?.setIsConfirmationModalVisible(true, "undeploy");
                        trackEventClicked("Stop without undeploying", "CDC");
                    }
                }
            ]
        },
        CDC_RESUME: {
            primaryBtnlabel: "Resume",
            primaryBtnIcon: <PlayerPlay style={styles.iconBase} />,
            primayBtnOnClick: async () => {
                try {
                    automatedGroupStore.setisAppTransitioning(true);
                    const appGroupFQN = automatedGroupStore?.groupName?.id;
                    await api.resumeILCDC(appGroupFQN);
                } catch (e) {
                    growl.error(e);
                } finally {
                    automatedGroupStore.setisAppTransitioning(false);
                    trackEventClicked("Resume", "CDC");
                }
            }
        },
        CDC_UNDEPLOY: {
            primaryBtnlabel: "Undeploy",
            primaryBtnIcon: <RocketOff style={styles.iconBase} />,
            primayBtnOnClick: async () => {
                try {
                    automatedGroupStore.setisAppTransitioning(true);
                    const appGroupFQN = automatedGroupStore?.groupName?.id;
                    await api.undeployILCDC(appGroupFQN);
                } catch (e) {
                    growl.error(e);
                } finally {
                    automatedGroupStore.setisAppTransitioning(false);
                    trackEventClicked("Undeploy", "CDC");
                }
            }
        },
        RESET: {
            primaryBtnlabel: "Reset",
            primayBtnOnClick: async () => {
                try {
                    automatedGroupStore.setisAppTransitioning(true);
                    automatedGroupStore?.setIsConfirmationModalVisible(true, "reset");
                } catch (e) {
                    growl.error(e);
                } finally {
                    automatedGroupStore.setisAppTransitioning(false);
                }
            }
        },
        download: {
            primaryBtnlabel: <Download style={{ ...styles.iconBase, ...styles.downloadIcon }} />,
            dropdownBtnToolTip: downloadToolTipContent,
            dropdownBtnToolTipDuration: downloadToolTipDuration,
            primayBtnOnClick: () => {
                const ilAppId = sortAutomatedApps(automatedGroupStore?.appIds)?.[0];
                downloadAllReports(automatedGroupStore?.groupName?.id, ilAppId);
            },
            dropdownList: [
                {
                    title: "Download all",
                    default: true,
                    onClick: () => {
                        const ilAppId = sortAutomatedApps(automatedGroupStore?.appIds)?.[0];
                        downloadAllReports(automatedGroupStore?.groupName?.id, ilAppId);
                    }
                },
                {
                    title: "Automated pipeline configuration",
                    onClick: () => downloadAutomatedReport(automatedGroupStore?.groupName?.name)
                },
                {
                    title: "Initial load report",
                    onClick: () => {
                        const ilAppId = sortAutomatedApps(automatedGroupStore?.appIds)?.[0];
                        downloadILReport(ilAppId);
                    }
                }
            ]
        }
    };
};
