import React from "react";
import { Formik } from "formik";
import FormikField from "src/generic/formik-field";
import { StriimTypography, StriimButton, StriimIconWrapperBase } from "@striim/striim-ui";
import { Grid, Box } from "@mui/material";
import { useStyles, sxStyles } from "./cosmosdb-cassandra-api.styles";
import { icons } from "src/generic/icon/flow-designer-icon";
import * as Yup from "yup";
import ErrorMessageBox from "../common/error-message-box/error-message-box";
import { toJS } from "mobx";
import { COSMOSDB_CONFIG } from "../cosmosdb-target/config";
import DatabaseMapping from "../common/database-mapping/database-mapping";
import {
    getTargetNameValidation,
    accountEndpointValidation,
    portValidation,
    accountKeyValidation,
    keySpaceValidation,
    cosmosCassandraTableMappingValidation
} from "../common/target.validations";
import FormikErrorFocus from "../common/formik-error-focus/formik-error-focus";
import Footer from "../common/footer/footer";

export const cassandraValidationSchema = Yup.object().shape({
    targetName: getTargetNameValidation(),
    accountEndpoint: accountEndpointValidation,
    port: portValidation,
    accountKey: accountKeyValidation,
    keySpace: keySpaceValidation,
    dbMapping: cosmosCassandraTableMappingValidation
});

const dbMappingColumns = [
    { Header: "Source Table", accessor: "source" },
    { Header: "Target Table", accessor: "target" }
];

const CosmosDBCassandraApi = ({ store, nsName, sourceDatabases, targetApi, formRef, targetConfig }) => {
    const classes = useStyles();

    const { defaultValues } = COSMOSDB_CONFIG.cassandra;
    const formikValues = toJS(targetConfig) || {
        ...defaultValues,
        nsName,
        dbMapping: sourceDatabases.map(v => ({ sourceTable: v, targetTable: v.toLowerCase() }))
    };

    return (
        <Grid container direction="column">
            <Grid container sx={sxStyles.headerContainer} justifyContent="flex-start" alignItems="center">
                <Grid item className={classes.icons}>
                    <StriimIconWrapperBase Icon={icons[targetApi.icon]} type="target" />
                </Grid>
                <Grid item className={classes.title}>
                    <StriimTypography variant="h2">Configure Cosmos DB ({targetApi.targetName})</StriimTypography>
                </Grid>
                <Grid item className={classes.helpBtn}>
                    <StriimButton variant="primaryText" target="_blank" href={COSMOSDB_CONFIG.cassandra.docsLink}>
                        View Documentation
                    </StriimButton>
                </Grid>
            </Grid>
            <Formik
                initialValues={formikValues}
                initialTouched={{
                    dbMapping: formikValues?.dbMapping?.map(v => ({ targetTable: true }))
                }}
                validateOnChange={true}
                validateOnBlur={false}
                validationSchema={cassandraValidationSchema}
                enableReinitialize={true}
                innerRef={formRef}
            >
                {({ values, errors, touched, submitCount }) => {
                    return (
                        <>
                            {submitCount > 0 && (
                                <ErrorMessageBox
                                    values={values}
                                    errors={errors}
                                    validationSchema={cassandraValidationSchema}
                                    emptyFieldErrors={true}
                                    otherFieldErrors={false}
                                />
                            )}
                            <Grid container direction="column" sx={sxStyles.fieldsContainer}>
                                <Grid item xs={12} className={classes.gridItemHeader}>
                                    <Box component={FormikField} name="targetName" label={"Target name"} />
                                </Grid>
                                <Grid item container xs={12} className={classes.formInput}>
                                    <Grid item xs={8}>
                                        <Box
                                            component={FormikField}
                                            name="accountEndpoint"
                                            label={"Account endpoint"}
                                            placeholder="Example: cassandracosmos.cassandra.cosmos.azure.com"
                                        />
                                    </Grid>
                                    <Grid item xs={4} className={classes.columnSpacing}>
                                        <Box
                                            component={FormikField}
                                            name="port"
                                            label={"Port"}
                                            placeholder="Example: 10350"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={classes.inputGroup}>
                                    <Box
                                        component={FormikField}
                                        name="accountKey"
                                        label={"Account key"}
                                        type="password"
                                        placeholder="********"
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.inputGroup}>
                                    <Box
                                        component={FormikField}
                                        name="keySpace"
                                        label={"Key space"}
                                        placeholder="Example: mykeyspace"
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.inputGroup}>
                                    <StriimTypography
                                        variant="h3"
                                        sx={sxStyles.tableMappingTitle}
                                        style={{ marginBottom: 0 }}
                                    >
                                        TABLE MAPPING
                                    </StriimTypography>
                                    <StriimTypography variant="body4" sx={sxStyles.caption}>
                                        Please map your source table name to target Cosmos DB table name. Kindly ensure
                                        mapped tables/collections are created on the target Cosmos account.
                                    </StriimTypography>
                                </Grid>
                                <Grid item xs={12} className={classes.formInput}>
                                    <DatabaseMapping
                                        id={COSMOSDB_CONFIG.cassandra.id}
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        dbMappingColumns={dbMappingColumns}
                                    />
                                </Grid>
                            </Grid>
                            <FormikErrorFocus />
                        </>
                    );
                }}
            </Formik>
            <Footer store={store} onBack={() => store.goToPreviousStep()} onNext={() => store.goToNextStep()} />
        </Grid>
    );
};

export default CosmosDBCassandraApi;
