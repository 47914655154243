import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PropTypes as MobxPropTypes } from "mobx-react";
import { ChevronLeft } from "@material-ui/icons";
import { StriimInputField, StriimButton, StriimIconButton, StriimTypography } from "@striim/striim-ui";
import { groupNameInvalid, getValidationMsg } from "../../group.validation";
import useStyles from "./app-group-select.styles";
import App from "app";
import { Box, Grid } from "@mui/material";

const AppGroupRecord = ({ groups, onClose, setCreateGroupMode }) => {
    const classes = useStyles();
    const [currentName, setCurrentName] = useState("");
    const [currentDescription, setCurrentDescription] = useState("");

    const isEmpty = (currentName || "") === "";

    const nameInvalid = groupNameInvalid(currentName, groups, App?.user?.userId);
    const validationMsg = getValidationMsg(currentName, groups, App?.user?.userId);

    return (
        <div className={classes.wrapper} id="groups-dropdown--add">
            <div className={classes.header}>
                <Grid container alignItems="center">
                    <StriimIconButton
                        onClick={() => {
                            setCreateGroupMode(false);
                        }}
                    >
                        <ChevronLeft />
                    </StriimIconButton>
                    <StriimTypography variant="h3">Add to a new group</StriimTypography>
                </Grid>
            </div>
            <Box className={classes.content}>
                <Box
                    required
                    component={StriimInputField}
                    value={currentName}
                    onChange={setCurrentName}
                    autoFocus
                    id="name"
                    label="Name"
                    error={nameInvalid}
                    helperText={validationMsg}
                />
                <StriimInputField
                    value={currentDescription}
                    onChange={setCurrentDescription}
                    id="description"
                    label="Description (optional)"
                    multiline
                    rows={2}
                />
            </Box>
            <div className={classes.divider} />
            <div className={classes.actions}>
                <StriimButton
                    variant="secondaryText"
                    data-test-id={"create-app-group-cancel-btn"}
                    sx={{color: 'secondary.500'}}
                    onClick={() => onClose()}
                >
                    Cancel
                </StriimButton>
                <Box
                    data-test-id="add-to-group-button"
                    className={classes.addToGroupBtn}
                    component={StriimButton}
                    variant="secondary"
                    disabled={isEmpty || nameInvalid}
                    onClick={() => {
                        if (nameInvalid) {
                            return;
                        }
                        onClose({ name: currentName, description: currentDescription });
                        setCurrentName("");
                    }}
                >
                    Add to group
                </Box>
            </div>
        </div>
    );
};

AppGroupRecord.propTypes = {
    groups: MobxPropTypes.observableArray,
    onClose: PropTypes.func,
    setCreateGroupMode: PropTypes.func
};

export default AppGroupRecord;
