import { FlowStatus } from "src/status-management";

import _ from "underscore";

const globalApps = ["Global.MonitoringProcessApp", "Global.MonitoringSourceApp", "System$Alerts.AlertingApp"];
var tempCores = 0;

const calculateServerStats = function(serverStats) {
    // declare server variables
    let total_cpu = 0;
    let all_clusters_total_cores = 0;
    let cpu_rate = 0;
    let memory_max = 0;
    let memory_free = 0;
    let mem_rate = 0;
    let total_nodes = 0;
    let num_running_nodes = 0;
    let node_data = [];

    // START calculating server stats
    serverStats.forEach(serverStat => {
        // validate stats
        if (!serverStat["most-recent-data"]) {
            return;
        }
        // count nodes
        total_nodes++;
        if (serverStat.status === FlowStatus.RUNNING) num_running_nodes++;
        // calc CPU
        total_cpu += serverStat["most-recent-data"]["cpu-rate"] ?? 0;
        all_clusters_total_cores += serverStat["most-recent-data"]["cores"] ?? 0;
        // calc mem
        memory_max += serverStat["most-recent-data"]["memory-max"] ?? 0;
        memory_free += serverStat["most-recent-data"]["memory-free"] ?? 0;
        // inputRate += serverStat["most-recent-data"]["source-rate"] ?? 0;
        // outputRate += serverStat["most-recent-data"]["target-rate"] ?? 0;
        // calc node data
        let cpu_of_node;
        let mem_of_node;
        let status_of_node;
        try {
            cpu_of_node =
                (serverStat["most-recent-data"]["cpu-rate"] ?? 0) / (serverStat["most-recent-data"]["cores"] ?? 0);
        } catch (exception) {
            cpu_of_node = 0;
        }
        try {
            mem_of_node =
                ((serverStat["most-recent-data"]["memory-max"] ?? 0) -
                    (serverStat["most-recent-data"]["memory-free"] ?? 0)) /
                (serverStat["most-recent-data"]["memory-max"] ?? 0);
        } catch (exception) {
            mem_per_node = 0;
        }
        status_of_node = serverStat.status === FlowStatus.RUNNING ? "Running" : "Down";
        node_data.push({
            name: serverStat["name"],
            cpu: cpu_of_node,
            mem: mem_of_node,
            status: status_of_node
        });
    });

    // Happens when we get historic report. It does not have cores, so get from previous.
    if (all_clusters_total_cores === 0) all_clusters_total_cores = tempCores;
    // overall calculation
    if (all_clusters_total_cores === 0) {
        cpu_rate = 0;
    } else {
        cpu_rate = total_cpu / all_clusters_total_cores;
    }
    mem_rate = (memory_max - memory_free) / memory_max;
    // END calculating server stats

    return {
        cpuRate: cpu_rate,
        memRate: mem_rate,
        numRunningNodes: num_running_nodes,
        ttlNodes: serverStats.length,
        nodeData: node_data
    };
};

const calculateAppStats = function(appStats) {
    // declare app variables
    let num_terminated_apps = 0;
    let num_running_apps = 0;
    let num_halted_apps = 0;
    let num_other_apps = 0;

    let ttlInput = 0;
    let ttlOutput = 0;

    let inputRate = 0;
    let outputRate = 0;

    let lagFirst = 0;
    let lagSecond = 0;

    let otherAppsMap = new Map();

    // START calculating app stats
    appStats.forEach(app => {
        ttlInput += app?.["most-recent-data"]?.["source-input"] ?? 0;
        ttlOutput += app?.["most-recent-data"]?.["target-output"] ?? 0;

        inputRate += app?.["most-recent-data"]?.["source-rate"] ?? 0;
        outputRate += app?.["most-recent-data"]?.["target-rate"] ?? 0;

        lagFirst += app?.["most-recent-data"]?.["lag-rate"]?.first ?? 0;
        lagSecond += app?.["most-recent-data"]?.["lag-rate"]?.second ?? 0;

        switch (app.status) {
            case FlowStatus.TERMINATED:
            case FlowStatus.CRASH:
                num_terminated_apps++;
                break;
            case FlowStatus.HALT:
                num_halted_apps++;
                break;
            case FlowStatus.RUNNING:
                num_running_apps++;
                break;
            default:
                otherAppsMap.set(app.status, 1 + (otherAppsMap.get(app.status) ?? 0));
                num_other_apps++;
                break;
        }
    });
    const otherAppsList = [...otherAppsMap].map(([status, count]) => ({ status, count }));
    // END calculating app stats
    return {
        ttlApps: appStats.length,
        numTerminatedApps: num_terminated_apps,
        numRunningApps: num_running_apps,
        numHaltedApps: num_halted_apps,
        numOthersApps: num_other_apps,
        otherAppsList: otherAppsList,
        ttlInput: ttlInput,
        ttlOutput: ttlOutput,
        inputRate: inputRate,
        outputRate: outputRate,
        lagFirst: lagFirst,
        lagSecond: lagSecond
    };
};

const getMonitorData = function(stats) {
    // fetch app
    var appStats = _.filter(stats, function(item) {
        return (
            item &&
            item.type === "APPLICATION" &&
            //TODO implement it properly instead of "namedQueryeffb438"
            item.name.indexOf("namedQueryeffb438") === -1 &&
            //TODO is there any other way to filter out adhocqueries ?
            item.name.indexOf("adhocquery") === -1 &&
            globalApps.indexOf(item["full-name"]) === -1
        );
    });
    // fetch nodes
    var serverStats = _.filter(stats, function(item) {
        return item && item.type === "SERVER";
    });
    // get desired stats
    var serverResult = calculateServerStats(serverStats);
    var appResult = calculateAppStats(appStats);

    return {
        ...serverResult,
        ...appResult
    };
};
export default getMonitorData;
