import React, { useState, useRef, Fragment } from "react";
import { ListItemText, ListSubheader, Grid } from "@mui/material";
import { StriimTypography, StriimButton, StriimList, StriimListItem } from "@striim/striim-ui";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import Divider from "@material-ui/core/Divider";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import xss from "xss";

const useStyles = makeStyles(theme => ({
    listSection: {
        backgroundColor: "inherit"
    },
    ul: {
        backgroundColor: "inherit",
        padding: 0
    },
    info: {
        color: theme.palette.primary.light
    },
    error: {
        color: theme.palette.error.light
    },
    warning: {
        color: theme.palette.warning.light
    }
}));
const sxClasses = {
    list: {
        width: "100%",
        maxWidth: "360px",
        minWidth: "240px",
        position: "relative",
        overflow: "auto",
        maxHeight: "400px"
    },
    icon: {
        width: "36px",
        minWidth: "36px" //override
    },
    text: {
        margin: "0px",
        "& .MuiListItemText-secondary": {
            margin: "0px"
        },
        overflowWrap: "break-word"
    },
    primaryText: {
        margin: "0px"
    }
};
const SecondaryText = ({ item }) => {
    const classes = useStyles();

    return (
        <>
            <StriimTypography className={classes.noMargin} variant={"h5"}>
                {item.name}
            </StriimTypography>
            <StriimTypography className={classes.noMargin} variant={"h5"}>
                At: {item.timeStampInDateFormat}
            </StriimTypography>
        </>
    );
};

const pageSize = 10;

const AlertsList = observer(({ alertsModalState, store }) => {
    const classes = useStyles();

    const [page, setPage] = useState(1);

    const timer = useRef();

    const delayedClose = () => {
        timer.current = setTimeout(() => {
            alertsModalState[1](false);
        }, 300);
    };

    const hasOpened = () => {
        if (timer.current) {
            clearTimeout(timer);
        }
    };

    return (
        <StriimList
            sx={sxClasses.list}
            id={"nav--alerts-container"}
            onMouseLeave={() => {
                delayedClose();
            }}
            onMouseEnter={() => {
                hasOpened();
            }}
        >
            {store.alerts.length > 0 ? (
                <>
                    <ListSubheader disableSticky={true}>Your Recent Alerts</ListSubheader>
                    {store.alerts.slice(0, pageSize * page).map((item, idx) => (
                        <Fragment key={item.keyVal + idx.toString()}>
                            <Divider />
                            <StriimListItem key={item.keyVal} data-test-id={"nav--alerts-item"}>
                                <ListItemIcon sx={sxClasses.icon}>
                                    {(() => {
                                        switch (item.severity) {
                                            case "info":
                                                return <InfoIcon className={classes.info} />;
                                            case "warning":
                                                return <WarningIcon className={classes.warning} />;
                                            case "error":
                                                return <ErrorIcon className={classes.error} />;
                                            default:
                                                return <InfoIcon className={classes.info} />;
                                        }
                                    })()}
                                </ListItemIcon>
                                <ListItemText
                                    sx={sxClasses.text}
                                    primary={
                                        <StriimTypography
                                            variant={"h4"}
                                            sx={sxClasses.primaryText}
                                            dangerouslySetInnerHTML={{ __html: xss(item.message) }}
                                        ></StriimTypography>
                                    }
                                    secondary={<SecondaryText item={item}></SecondaryText>}
                                />
                            </StriimListItem>
                        </Fragment>
                    ))}
                </>
            ) : (
                <ListSubheader disableSticky>You don't have any alerts right now.</ListSubheader>
            )}

            {store.alerts.length > pageSize * page && (
                <StriimListItem
                    onClick={() => {
                        setPage(page + 1);
                    }}
                >
                    <Grid container justifyContent="space-around" className={classes.noMargin}>
                        <StriimButton variant="primary" onClick={() => setPage(page + 1)}>
                            Load more
                        </StriimButton>
                    </Grid>
                </StriimListItem>
            )}
        </StriimList>
    );
});

export default AlertsList;
