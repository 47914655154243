import React, { useEffect } from "react";
import loadBackboneApp from "../../../app/load-backbone-app";
import { generateDetailsView } from "../../../app/components/debug/editors-test";

export default function TestCrud() {
    useEffect(() => {
        const view = generateDetailsView();
        loadBackboneApp(view);
    }, []);

    return <></>;
}
