import $ from "jquery";
import _ from "underscore";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";

var Readers = {
    FileReader: "FileReader",
    HDFSReader: "HDFSReader",
    HTTPReader: "HTTPReader",
    JMSReader: "JMSReader",
    KafkaReader: "KafkaReader",
    MQTTReader: "MQTTReader",
    TCPReader: "TCPReader",
    UDPReader: "UDPReader",
};

var Parsers = {
    XMLParser: "XMLParser",
    JSONParser: "JSONParser",
    AvroParser: "AvroParser",
    GGTrailParser: "GGTrailParser",
    AALParser: "AALParser",
    NVPParser: "NVPParser",
    FreeFormTextParser: "FreeFormTextParser",
    BinaryParser: "BinaryParser",
    CollectdParser: "CollectdParser",
    DSVParser: "DSVParser",
    NetflowParser: "NetflowParser",
    SNMPParser: "SNMPParser",
    StriimParser: "StriimParser",
    XMLParserV2: "XMLParserV2",
};

export default function (metaObjectType, dependantEntity) {
    var availableOptions = [];
    dependantEntity = $.isPlainObject(dependantEntity) ? dependantEntity.handler : dependantEntity;

    if (metaObjectType === metaStoreService.entities.SOURCE) {
        if (
            dependantEntity ===
            MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, Parsers.XMLParser)
        ) {
            availableOptions = [
                Readers.FileReader,
                Readers.HDFSReader,
                Readers.HTTPReader,
                Readers.JMSReader,
                Readers.KafkaReader,
                Readers.TCPReader,
                Readers.UDPReader,
            ];
        } else if (
            dependantEntity ===
            MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, Parsers.JSONParser)
        ) {
            availableOptions = [
                Readers.FileReader,
                Readers.HDFSReader,
                Readers.HTTPReader,
                Readers.JMSReader,
                Readers.KafkaReader,
                Readers.TCPReader,
                Readers.UDPReader,
            ];
        } else if (
            dependantEntity ===
            MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, Parsers.AvroParser)
        ) {
            availableOptions = [Readers.FileReader];
        } else if (
            dependantEntity ===
            MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, Parsers.GGTrailParser)
        ) {
            availableOptions = [Readers.FileReader];
        } else if (
            dependantEntity ===
            MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, Parsers.AALParser)
        ) {
            availableOptions = [
                Readers.FileReader,
                Readers.HDFSReader,
                Readers.HTTPReader,
                Readers.JMSReader,
                Readers.KafkaReader,
                Readers.TCPReader,
                Readers.UDPReader,
            ];
        } else if (
            dependantEntity ===
            MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, Parsers.NVPParser)
        ) {
            availableOptions = [
                Readers.FileReader,
                Readers.HDFSReader,
                Readers.HTTPReader,
                Readers.JMSReader,
                Readers.KafkaReader,
                Readers.TCPReader,
                Readers.UDPReader,
            ];
        } else if (
            dependantEntity ===
            MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, Parsers.FreeFormTextParser)
        ) {
            availableOptions = [
                Readers.FileReader,
                Readers.HDFSReader,
                Readers.HTTPReader,
                Readers.JMSReader,
                Readers.TCPReader,
                Readers.UDPReader,
            ];
        } else if (
            dependantEntity ===
            MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, Parsers.BinaryParser)
        ) {
            availableOptions = [
                Readers.FileReader,
                Readers.HDFSReader,
                Readers.HTTPReader,
                Readers.TCPReader,
                Readers.UDPReader,
            ];
        } else if (
            dependantEntity ===
            MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, Parsers.CollectdParser)
        ) {
            availableOptions = [Readers.UDPReader];
        } else if (
            dependantEntity ===
            MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, Parsers.DSVParser)
        ) {
            availableOptions = [
                Readers.FileReader,
                Readers.HDFSReader,
                Readers.JMSReader,
                Readers.KafkaReader,
                Readers.TCPReader,
                Readers.UDPReader,
            ];
        } else if (
            dependantEntity ===
            MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, Parsers.NetflowParser)
        ) {
            availableOptions = [Readers.UDPReader];
        } else if (
            dependantEntity ===
            MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, Parsers.SNMPParser)
        ) {
            availableOptions = [Readers.UDPReader];
        } else if (
            dependantEntity ===
            MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, Readers.KafkaReader)
        ) {
            availableOptions = [
                Parsers.AALParser,
                Parsers.AvroParser,
                Parsers.DSVParser,
                Parsers.JSONParser,
                Parsers.NVPParser,
                Parsers.StriimParser,
                Parsers.XMLParser,
                Parsers.XMLParser,
                Parsers.XMLParserV2,
            ];
        } else if (
            dependantEntity ===
            MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, Readers.MQTTReader)
        ) {
            availableOptions = [
                Parsers.AvroParser,
                Parsers.DSVParser,
                Parsers.JSONParser,
                Parsers.XMLParser,
                Parsers.XMLParserV2,
            ];
        }
    }

    return _.map(availableOptions, function (item) {
        return {
            id: MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, item),
            text: item,
        };
    });
}
