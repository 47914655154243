import $ from "jquery";
import _ from "underscore";
import App from "app";
import BaseControl from "./../base-control";
import template from "./multiple-stream-selector.html";

var View = BaseControl.View.extend({
    template: _.template(template),
    ui: {
        list: "ul",
    },
    regions: {},
    className: "multiple-stream-selector",
    events: {},
    initialize: function () {},
    getViewValue: function () {
        return this.value;
    },

    setViewValue: function () {
        this.value = this.getValue();
    },

    onRender: function () {
        var _this = this;
        _.each(this.getValue(), function (outputclause) {
            var stream;
            if (typeof outputclause.map === "undefined") {
                stream = outputclause.outputStream;
            } else {
                stream = outputclause.map.streamName;
            }
            var link = $("<a></a>");
            link.text(stream);
            link.bind("click", function () {
                App.vent.trigger("node-selected", stream);
            });
            var listItem = $("<li></li>");
            listItem.append(link);
            _this.ui.list.append(listItem);
        });
    },
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: Array,
    },
});

export default _.extend({}, BaseControl, {
    View: View,
    Model: Model,
});
