import { Grid, Box } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import React, { useEffect, useMemo } from "react";
import { StackedLinearProgress } from "../stacked-linear-progress/stacked-linear-progress";
import useProgressStore from "../../../../stores/table-progress/useProgressStore";
import sxStyles from "../stacked-linear-progress/stacked-linear-progress.styles";
import { observer } from "mobx-react";
import { GreenCheck } from "../../../../generic/icon/customIcons";
import statusManagement from "../../../../status-management";
import AppProgressErrorBox from "../schema-creation-errors/schema-creation-errors";
import growl from "../../../../../app/components/common/growl";
import Tracker from "../../../../../core/services/tracker/tracker";

type Props = {
    appStatus: string;
    isLite: boolean;
};
type schemaCreationData = {
    totalTablesCount: number;
    schemaCreatedTablesCount: number;
};

const SchemaCreationPage: React.FunctionComponent<Props> = observer(({ appStatus, isLite }) => {
    useEffect(() => {
        schemaCreationDetails?.schemaCreatedTablesCount && Tracker.getInstance().track("schema-creation", {
            event: "Wizards:Schema Creation",
            totalTablesCount: schemaCreationDetails?.totalTablesCount,
            schemaCreatedTablesCount: schemaCreationDetails?.schemaCreatedTablesCount
        });
    }, []);
    const store = useProgressStore();
    const schemaCreationDetails: schemaCreationData = useMemo(() => {
        let totalTablesCount = store?.ilInfo?.schemaCreationDetails?.totalTablesCount ?? null;
        let schemaCreatedTablesCount = store?.ilInfo?.schemaCreationDetails?.schemaCreationCompletedTablesCount ?? null;
        return { totalTablesCount, schemaCreatedTablesCount };
    }, [store?.ilInfo]);

    const ErrorMessageBoxComponent: React.ReactElement = useMemo(() => {
        try {
            const appId = store?.appModel?.id;
            if (appId) {
                if (statusManagement.isApplicationCrash(appStatus)) {
                    return <AppProgressErrorBox appId={appId} />;
                }
                return null;
            }
            return null;
        } catch (error) {
            growl.error("Cannot get information why the application was crashed/terminated");
            console.log(error);
            return null;
        }
    }, [appStatus]);
    const ProgressComponent = () => {
        return (
            <Box>
                <StackedLinearProgress
                    error={statusManagement.isApplicationCrash(appStatus)}
                    stopped={statusManagement.isStoppedStatus(appStatus)}
                    success={schemaCreationDetails?.schemaCreatedTablesCount ?? 0}
                    total={schemaCreationDetails?.totalTablesCount ?? 0}
                    timeElapsed={store?.elapsedTime}
                    text={
                        <>
                            {schemaCreationDetails?.schemaCreatedTablesCount != 0 &&
                            schemaCreationDetails?.schemaCreatedTablesCount ===
                                schemaCreationDetails?.totalTablesCount ? (
                                <Box>
                                    <GreenCheck />
                                    <span style={{ marginLeft: "16px" }}>
                                        {schemaCreationDetails?.schemaCreatedTablesCount}/
                                        {schemaCreationDetails?.totalTablesCount} tables created successfully
                                    </span>
                                </Box>
                            ) : (
                                <>
                                    Tables Created:
                                    <Box sx={sxStyles.timeElapsed}>
                                        {schemaCreationDetails?.schemaCreatedTablesCount} of{" "}
                                        {schemaCreationDetails?.totalTablesCount}
                                    </Box>
                                </>
                            )}
                        </>
                    }
                />
            </Box>
        );
    };
    if (isLite) {
        return (
            schemaCreationDetails?.schemaCreatedTablesCount &&
            schemaCreationDetails?.totalTablesCount && <ProgressComponent />
        );
    }
    return (
        <Grid container alignItems="center" justifyContent="center">
            <Grid container flexDirection="column" gap={2} sx={{ maxWidth: "600px", marginTop: "90px" }}>
                <Box>
                    <img src="src/modules/table-progress/images/Schema-loading.svg" />
                </Box>
                <Box>
                    <StriimTypography variant="h2" sx={{ color: "primary.700" }}>
                        Creating schemas on the target
                    </StriimTypography>
                    <StriimTypography variant="caption3" sx={{ color: "greyscale.700", mt: 0.5 }}>
                        Striim is creating the schemas on the target to match the ones in the source. This may take upto
                        few minutes
                    </StriimTypography>
                </Box>
                <ProgressComponent />
                <Box>{ErrorMessageBoxComponent}</Box>
            </Grid>
        </Grid>
    );
});

export default SchemaCreationPage;
