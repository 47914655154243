import React from "react";

const UnauthorizedTemplate = () => {
    return ( 
        <div class="alerts-unauthorized-container p-56px">
            <header class="warning ta-c">
                <h1>
                    <i class="fa fa-warning "></i> Striim Alerts can be set up by Administrators only.
                </h1>
                <h3 class="mt-32px">
                    Please contact your system administrator to get started with Alerts.
                </h3>
                <a href="#/landing" class="button primary mt-56px"> Back to Home</a>
            </header>
        </div>

    );
}
 
export default UnauthorizedTemplate;