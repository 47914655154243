import React from "react";
import { SvgIcon } from "@mui/material";
import { StriimChip, StriimLink } from "@striim/striim-ui";
import { HistoryToggle, Rotate, SettingsAutomation } from "../../../../../../generic/icon/customIcons";
import styles from "../../create-app-page-styles";
import HelpResources from "../../../../../../../app/components/common/helpable/online-help-dictionary";
import APPTYPE from "../../services/create-app-page-app-types.const.json";
import licenseService from "../../../../../../../core/services/metaStoreService/license-service";

export const fetchLabelBasedOnLicense = license => {
    const labels = {
        SQL2FABRICSOLUTION: {
            [APPTYPE.IL]: "Snapshot - Mirror Selected Tables only",
            [APPTYPE.CDC]: "Incremental - Mirror Changes only",
            [APPTYPE.AUTOMATEDILCDC]: "Full - Mirror Selected Tables and Changes (Default)",
            [APPTYPE.AUTOMATEDMODE]: "Automated Mode"
        },
        DEFAULT: {
            [APPTYPE.IL]: "Initial load only",
            [APPTYPE.CDC]: "Change data capture only",
            [APPTYPE.AUTOMATEDILCDC]: "Automated pipeline",
            [APPTYPE.AUTOMATEDMODE]: "Automated Mode"
        }
    };
    return labels[license] || labels.DEFAULT;
};

export const AppTypeInfo = () => {
    const label = fetchLabelBasedOnLicense(licenseService?.nonSegmentedlicenseName);
    return {
        AUTOMATEDILCDC: {
            title: label[APPTYPE.AUTOMATEDILCDC],
            content: (
                <>
                    A managed application group that handles the lifecycle of your pipeline from Initial load to CDC.
                    <StriimLink
                        target="_blank"
                        href={HelpResources.get().CREATE_APP_AUTOMATED_PIPELINE.href}
                        size="small"
                        sx={styles.dropdownItemLink}
                    >
                        Learn More
                    </StriimLink>
                </>
            ),
            icon: <SvgIcon component={SettingsAutomation} sx={styles.icon} />,
            additionalComponent: (
                <StriimChip hideAvatar type="tag-chip" variant="success" label="New" sx={{ height: 18, mt: 1 }} />
            )
        },
        IL: {
            title: label[APPTYPE.IL],
            content: "Creates matching schemas in Target and loads existing data from the Source.",
            icon: <SvgIcon component={HistoryToggle} sx={styles.icon} />
        },
        CDC: {
            title: label[APPTYPE.CDC],
            content: "Read and write continuous changes from the Source to the Target.",
            icon: <SvgIcon component={Rotate} sx={styles.icon} />
        },
        AUTOMATEDMODE: {
            title: label[APPTYPE.AUTOMATEDMODE],
            content: "Automates the process of Creating Schema + Initial Load + Incremental Load.",
            icon: <SvgIcon component={SettingsAutomation} sx={styles.icon} />
        }
    };
};
