export const styles = {
    frameContainer: {
        backgroundColor: "#fff",
        border: "1px solid",
        borderColor: "greyscale.200",
        borderRadius: "10px",
        height: "calc(100vh - 168px)",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "space-between"
    },
    frameChildrenContainer: {
        overflow: "auto",
        flex: 1
    },
    divider: {
        position: "relative",
        width: "100%"
    },
    frameFooter: {
        maxHeight: "84px"
    },
    content: {
        marginTop: 2
    },
    headerWrapper: {
        display: "flex",
        justifyContent: "space-between"
    },
    infoNewTabIcon: {
        fill: ({ palette }) => palette.greyscale[100]
    },
    link: {
        paddingX: 0.5,
        paddingY: 0,
        height: "18px",
        fontSize: "12px",
        fontWeight: 400
    },
    guardianHeaderLeft: {
        display: "flex",
        flexDirection: "row",
        marginBottom: ({ spacing }) => `${spacing(2)} !important`,
        alignItems: "center"
    },
    guardHeaderRight: {
        display: "flex",
        justifyContent: "flex-end",
        flex: 1
    },
    guardianHeaderIcon: {
        color: "primary.700",
        width: "24px",
        height: "24px",
        marginRight: 1,
        "& svg": {
            fill: "none"
        }
    },
    guardianHeaderInfoIcon: {
        color: "greyscale.700",
        width: "20px",
        height: "20px",
        marginLeft: 1,
        cursor: "pointer",
        "& svg": {
            fill: "none"
        }
    },
    guardianHeaderTitle: {
        color: "primary.700"
    },
    guardianContainer: {
        top: 48,
        bottom: 0,
        height: "calc(100vh - 68px)",
        padding: 3,
        position: "relative",
        flexWrap: "nowrap",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
    },
    guardianContentWrapper: {
        width: "100%",
        backgroundColor: "#fff",
        borderColor: "greyscale.200",
        borderRadius: "10px",
        height: "calc(100% - 66px)",
        overflowY: "auto"
    },
    emptyWrapper: {
        width: "100%",
        flex: 1,
        backgroundColor: "#fff",
        borderColor: "greyscale.200",
        borderRadius: "10px"
    },
    guardianContentContainer: {
        display: "block"
    },
    noMargin: {
        margin: 0
    },
    discoverDataHeader: {
        marginBottom: 2,
        "& .MuiStepper-root": {
            display: "flex",
            justifyContent: "flex-start",
            marginTop: 2
        },
        flex: `1 !important`
    },
    configureHeading: {
        display: "flex",
        flexDirection: "column",
        margin: 3,
        "& h2": {
            color: "primary.700"
        },
        "& span": {
            color: "greyscale.800"
        }
    },
    configureContent: {
        marginLeft: 3,
        "& .MuiGrid-container": {
            paddingLeft: 0,
            paddingRight: 0
        },
        width: "50%"
    },
    configureNotificationsContainer: {
        display: "flex",
        flexDirection: "column",
        maxWidth: "400px",
        "& span": {
            color: "greyscale.700"
        }
    },
    configureNotifyHeading: {
        display: "flex",
        alignItems: "center",
        color: "primary.700",
        "& svg": {
            fill: "none",
            marginRight: 0.5
        }
    },
    configureNotifySubHeading: {
        marginTop: 1,
        marginBottom: 2,
        fontFamily: "Inter"
    },
    inputElement: {
        marginLeft: 4,
        marginTop: 1
    },
    editNotificationIcon: {
        width: "20px",
        height: "20px"
    },
    notificationValue: {
        marginLeft: 1,
        color: ({ palette }) => `${palette.primary["700"]} !important`
    },
    notConfiguredMessageBox: {
        width: 400
    },
    notConfiguredMessageLink: {
        padding: "0 4px",
        height: "inherit",
        "& span": {
            marginLeft: 0,
            "& svg": {
                fill: "none"
            }
        }
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "100px",
        marginTop: 2,
        height: "calc(100vh - 180px)"
    },
    wrapperEmpty: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 2
    },
    emptyTitle: {
        paddingTop: 1
    },
    securityImage: {
        width: "200px",
        height: "142.6px",
        marginBottom: "20px"
    },
    description: {
        width: "400px",
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: "400",
        color: "greyscale.700",
        marginTop: "20px",
        marginBottom: "20px"
    },
    aiButtons: {
        display: "flex",
        "& > :nth-child(1)": {
            marginRight: 1
        }
    },
    collapsibleSection: {
        backgroundColor: ({ palette }) => palette.greyscale[50],
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
        position: "sticky",
        top: 0,
        zIndex: 996
    },
    errorBox: {
        display: "flex",
        justifyContent: "center",
        marginRight: theme => theme.spacing(1),
        "& .MuiCard-root": {
            backgroundColor: theme => theme.palette.critical[50],
            border: theme => `1px solid ${theme.palette.critical[100]}`,
            boxShadow: "unset"
        },
        "& .MuiCardHeader-avatar": {
            "& svg": {
                fill: theme => theme.palette.critical[500],
                color: theme => theme.palette.critical[500]
            }
        }
    },
    errorsList: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 0,
        mt: 3,
        borderBottom: "none"
    },
    stepsContent: {
        fontFamily: "Inter",
        lineHeight: "20px",
        alignItems: "center",
        gap: 1,
        display: "flex"
    },
    stepCount: {
        textAlign: "center",
        borderRadius: "12px",
        minWidth: "24px",
        height: "24px",
        alignContent: "center"
    },
    tabIcon: {
        "& path": {
            fill: ({ palette }) => palette.greyscale[700]
        }
    }
    //TODO: Use only when we have the troubleshooting guide
    // newTabIcon: {
    //     fill: "secondary.500",
    //     width: "18px",
    //     height: "18px"
    // },
    // learnLink: {
    //     px: 0.5,
    //     height: "18px",
    //     ".MuiButton-endIcon": { ml: 0.5 }
    // }
};
