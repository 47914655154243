import React from "react";
import { Grid } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui";
import { AppTypeInfoProps } from "../create-app-name-app-page-form";

const styles = {
    imageDiv: {
        height: "85%",
        borderBottomLeftRadius: 16,
        borderTopLeftRadius: 16,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
    },
    imageContainer: {
        width: "100%",
        height: "100%"
    }
};

interface PropTypes {
    currentAppTypeInfo: AppTypeInfoProps;
}

const CreateAppNameAppPageReview: React.FunctionComponent<PropTypes> = ({ currentAppTypeInfo }) => {
    return (
        <>
            <Grid item>
                <StriimTypography variant="h3" color="greyscale.900">
                    Preview
                </StriimTypography>
                <StriimTypography variant="body4" color="greyscale.700">
                    {currentAppTypeInfo.preview}
                </StriimTypography>
            </Grid>
            <Grid item sx={styles.imageContainer}>
                <div
                    style={{
                        ...styles.imageDiv,
                        backgroundImage: `url(${currentAppTypeInfo.img_src})`
                    }}
                />
            </Grid>
        </>
    );
};

export default CreateAppNameAppPageReview;
