import React from "react";
import { Card as MuiCard } from "@mui/material";

const styles = {
    card: {
        height: "100%",
        py: 2,
        px: 3,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "greyscale.200",
        borderRadius: "8px",
        boxShadow: "none"
    }
};

const Card = ({ children, useFullWidth, relativeContainer, ...props }) => {
    return (
        <MuiCard
            sx={{
                ...styles.card
            }}
            {...props}
        >
            {children}
        </MuiCard>
    );
};

export default Card;