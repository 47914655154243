import React, { useMemo } from "react";
import { Grid, Box } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import sxStyles from "../../table-progress-modal/table-progress-modal.styles";
import { getGraphColors, graphColors } from "../app-rate/app-rate";

const GraphLegend: React.FunctionComponent<{ appStatus: string }> = ({ appStatus }) => {
    let lineColors: graphColors = useMemo(() => getGraphColors(appStatus), [appStatus]);
    return (
        <Grid container>
            <div>
                <Grid container alignItems="center" gap={1} sx={{ mr: 3 }}>
                    <Box sx={{ ...sxStyles.legend, backgroundColor: lineColors.inColor }}></Box>
                    <Box>
                        <StriimTypography variant="caption" sx={{ color: "greyscale.700" }}>
                            Input
                        </StriimTypography>
                    </Box>
                </Grid>
            </div>
            <div>
                <Grid container alignItems="center" gap={1}>
                    {lineColors.outDashStyle === "dash" ? (
                        <Box sx={sxStyles.dashedLegendContainer}>
                            <Box sx={{ ...sxStyles.dashedLegend, backgroundColor: lineColors.outColor }}></Box>
                            <Box sx={{ ...sxStyles.dashedLegend, backgroundColor: lineColors.outColor }}></Box>
                        </Box>
                    ) : (
                        <Box sx={{ ...sxStyles.legend, backgroundColor: lineColors.outColor }}></Box>
                    )}

                    <div>
                        <StriimTypography variant="caption" sx={{ color: "greyscale.700" }}>
                            Output
                        </StriimTypography>
                    </div>
                </Grid>
            </div>
        </Grid>
    );
};

export default GraphLegend;
