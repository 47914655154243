import React, { useEffect, useRef } from "react";
import { useParams } from "react-router";

import App from "../../../app";
import useDashboardOptions from "./use-dashboard-options";
import Dashboard from "../../../app/components/dashboard/dashboard";

export default function ReadonlyDashboard() {
    // @ts-ignore
    const { id, page } = useParams();
    const currentIdRef = useRef<string | undefined>();
    const currentPageRef = useRef<string | undefined>();
    const options = useDashboardOptions(id);

    useEffect(() => {
        if (currentIdRef.current === id && currentPageRef.current === page) {
            return;
        }

        Dashboard.controller.begin_show_dashboard(id, page, false, options);
        currentIdRef.current = id;
        currentPageRef.current = page;

        return () => {
            App.content.empty();
        };
    }, [id, page]);

    return <div></div>;
}
