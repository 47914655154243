import { makeStyles } from "@material-ui/core/styles";

import useAppGroupSelectStyles from "../app-group-select/app-group-select.styles";

const useAppGroupEditStyles = makeStyles(({ spacing }) => ({
    dialogContentRoot: {
        padding: "0 !important",
        overflow: "hidden"
    },
    content: {
        margin: `0 0 ${spacing(2)}px`
    },
    formInput: {
        marginBottom: spacing(2)
    },
    formInputMarginTop: {
        marginTop: spacing(2)
    }
}));

const useStyles = () => ({
    ...useAppGroupSelectStyles(),
    ...useAppGroupEditStyles()
});

export default useStyles;
