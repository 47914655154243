import React, { createContext, useState, useEffect, Dispatch, SetStateAction } from "react";
import { isEmbedDashboard } from "../../app/components/dashboard/embedDashboard";

const EXPANDED_NAVBAR_WIDTH = 174;
const FD_LEFTSIDEBAR_WIDTH = 267;

type NavbarContextType = {
    isNavbarToggled: boolean;
    chatbotOpen: boolean;
    setChatbotOpen: Dispatch<SetStateAction<boolean>>;
};

export const NavbarContext = createContext<NavbarContextType | null>(null);

const PageFrame = ({ Navbar, Topbar, children }) => {
    const [isNavbarToggled, setIsNavbarToggled] = useState<boolean>(false);
    const [chatbotOpen, setChatbotOpen] = useState<boolean>(false);

    useEffect(() => {
        setContentLayout(EXPANDED_NAVBAR_WIDTH);
    }, []);

    function setContentLayout(currentWidth) {
        setIsNavbarToggled(currentWidth === EXPANDED_NAVBAR_WIDTH);

        const backboneContent = document.getElementById("content");
        const reactContent = document.getElementById("page-content");

        if (isEmbedDashboard()) {
            backboneContent.style.width = "100%";
            backboneContent.style.marginLeft = "0";
            reactContent.style.display = "none";
        } else {
            backboneContent.style.width = `calc(100vw - ${currentWidth + 8}px)`;
            backboneContent.style.marginLeft = `${currentWidth}px`;
            reactContent.style.width = `calc(100vw - ${currentWidth + 11}px)`;
            reactContent.style.marginLeft = `${currentWidth}px`;
        }

        const fdContent = document.querySelector<HTMLElement>(".with-left-menu");
        const fdRightContent = document.querySelector<HTMLElement>(".flow-designer-root .right-content");
        const fdRightSidebarToggled = document.querySelector<HTMLElement>(".flow-designer-root .right-sidebar");
        if (fdRightSidebarToggled) {
            fdRightSidebarToggled.classList.toggle("navbar-collapsed", isNavbarToggled);
        }
        if (fdContent) {
            fdContent.style.left = `${currentWidth + FD_LEFTSIDEBAR_WIDTH}px`;
        } else if (fdRightContent) {
            fdRightContent.style.left = `${currentWidth}px`;
        }
    }

    return (
        <div style={{ display: "flex" }}>
            <NavbarContext.Provider
                value={{
                    isNavbarToggled,
                    chatbotOpen,
                    setChatbotOpen
                }}
            >
                <Navbar setContentLayout={setContentLayout} />
                <div id="page-content">
                    <>{Topbar}</>
                    <>{children}</>
                </div>
            </NavbarContext.Provider>
        </div>
    );
};

export default PageFrame;
