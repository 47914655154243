import App from "app";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import template from "./field.html";
import "./editor";

App.module("Dashboard.VisEditor", function (VisEditor, App, Backbone, Marionette, $, _) {
    VisEditor[dataVisualizations.EDITOR.FIELD] = VisEditor.Editor.LayoutView.extend({
        className: "field-editor",
        templateHTML: template,
        onBeforeDestroy: function () {
            this.$("select").select2("destroy");
        },
        onRender: function () {
            var _this = this;

            var $axis = this.$("select");
            $axis.val(this.model.config[this.model.editor.attribute]);

            _(this.model.editor.options).defaults({
                placeholder: "Drag or select a field",
                allow_blank: true,
            });

            $axis.droppableSelect({
                allow_blank: this.model.editor.options.allow_blank,
                id: this.model.editor.attribute,
                placeholder: this.model.editor.options.placeholder,
            });

            $axis.off("change.custom").on("change.custom", function () {
                var field = $(this).val();
                // Set the current series' [X or Y]-axis to the one identified by the selected field
                // (e.g. values4)
                _this.model.config.set(_this.model.editor.attribute, field);
            });
        },
    });
});
