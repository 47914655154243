import Marionette from "marionette";
import _ from "underscore";
import template from "./control-label.html";

export default Marionette.ItemView.extend({
    template: _.template(template),

    ui: {
        description: ".description"
    },

    initialize: function() {
        if (this.model.get("isSubform") === null) {
            if (this.model?.controlView?.subForm === true) {
                this.model.set("isSubform", true);
            } else {
                this.model.set("isSubform", false);
            }
        }
        if (this.model.get("subControl") === null) {
            if (this.model?.controlView?.subControl === true) {
                this.model.set("subControl", true);
            } else {
                this.model.set("subControl", false);
            }
        }
    },

    onRender: function() {
        if (this.model.description) {
            this.ui.description.tooltipster({
                interactive: true,
                content: this.model.description,
                maxWidth: 408,
                theme: "control-label",
                offsetX: -4,
                offsetY: 1,
                position: "left",
                speed: 0,
                delay: 200,
                contentAsHTML: true
            });
        }
    }
});
