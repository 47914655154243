import $ from "jquery";
import _ from "underscore";
import HighMapsMixin from "app/components/dashboard/visualization/charts/mixins/highmaps";
import { tooltipStriimlineHighcharts } from "../../../../../../styles/materialize/components-striimline/tooltip.export";
// import Highmaps from 'highmaps';
export default _({}).extend(HighMapsMixin, {
    setConfiguration: function(config) {
        var _this = this;
        var theme = config.theme;
        var config = config.config;
        if (!theme) return console.warn("No theme provided.");
        this.chartConfig = {
            chart: {
                backgroundColor: "none",
                animation: true,
                events: {}
            },
            title: {
                text: config.title
            },
            colorAxis: {
                type: "map",
                minColor: config.minColor,
                maxColor: config.maxColor
            },
            mapNavigation: {
                enabled: true,
                buttonOptions: {
                    verticalAlign: "bottom"
                }
            },
            viewZoom: config.viewZoom,
            viewXOffset: config.viewXOffset,
            viewYOffset: config.viewYOffset
        };
        var series = [];
        var data = this.generateData(config, this.model.dataSource.data);

        if (!this.maps) {
            this.maps = [];
        }

        var map = this._getFormattedMapKey(config);
        if (map !== "world-highres") {
            map = `${map}-all`;
        }
        var getMapData = this.maps[map] || $.getJSON(`lib/vendor/mapdata/geojson/countries/${map}.geo.json`);

        $.when(getMapData).then(function(mapData) {
            _this.maps[map] = mapData;
            var mapSeries = _this.generateMapSeries(config, data, mapData);
            series.push(mapSeries);
            _this.chartConfig.series = series;
        });
    },
    generateData: function(config, data) {
        if (!data) return [];
        if (data.length === 0) return [];
        var configData = [{}];
        var newObject = {};
        var field = config.field,
            value = config.value;

        for (var i = 0; i < data.length; i++) {
            if (data[i][field]) {
                newObject.value = data[i][value];
                switch (config.joinBy) {
                    case "postal-code": {
                        newObject.mapKey = data[i][field].toUpperCase();
                        break;
                    }
                    case "name": {
                        newObject.mapKey = this.toTitleCase(data[i][field]);
                        break;
                    }
                    default:
                        newObject.mapKey = data[i][field].toLowerCase();
                }
                configData.push(newObject);
                newObject = {};
            }
        }
        return configData;
    },
    toTitleCase: function(str) {
        var formatted;
        formatted = str.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
        return formatted;
    },
    generateMapSeries: function(config, data, mapData) {
        var mapName = config.title ? config.title : "Choropleth Map";
        return {
            data: data,
            mapData: mapData,
            joinBy: [config.joinBy, "mapKey"],
            dataLabels: {
                enabled: true,
                color: config.labelColor,
                format: "{point.name}",
                style: {
                    textShadow: "none",
                    HcTextStroke: null
                }
            },
            name: mapName,
            tooltip: {
                pointFormat: "{point.mapKey}: {point.value}" + config.unit,
                ...tooltipStriimlineHighcharts
            },
            states: {
                hover: {
                    color: config.hoverColor
                }
            },
            point: {
                events: {
                    click: function(e) {
                        var element = e.currentTarget;
                        console.log("currentTarget is ", e.currentTarget, "amd the e is ", e);
                        var key = e.currentTarget.properties["hc-key"];
                        var newConfig = config;
                        key = key.replace(/-/g, "_").toUpperCase();
                        newConfig.map = key;
                        this.model.get("config").set(newConfig);
                        this.model.save();
                        this.trigger("datapoint:click", element);
                    }.bind(this)
                }
            },
            cursor: "pointer"
        };
    },
    _getFormattedMapKey: function(config) {
        return config.map ? config.map.toLowerCase().replace("_", "-") : "us";
    }
});
