import React from "react";
import { StriimTheme } from "@striim/striim-ui";

const withTheme = Component => {
    const WithTheme = props => {
        return (
            <StriimTheme preselector=".striim.materialize.light:not(#\20)">
                <Component {...props} />
            </StriimTheme>
        );
    };
    // to fix es-lint error to provide display name
    WithTheme.displayName = `withTheme(${Component.displayName || Component.name})`;
    return WithTheme;
};

export default withTheme;
