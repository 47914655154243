import _ from "underscore";
import with_category from "./with_category";
import dateLib from "core/utils/date-utils";
import utils from "core/utils";

var CurrentWithCategory = function() {};

CurrentWithCategory.prototype = _.extend(new with_category(), {
    _evictSeriesData: function(data) {
        // protected
        // Trim the data to the maximum size
        if (data.length > 1) {
            // console.log(data);
            data = data[0].slice(0, 100);
        }
        return data;
    },
    _getXAxisLabel: function(stat, xAxisIsTime) {
        if (stat.first === undefined) {
            stat.first = stat.start;
        }
        if (stat.last === undefined) {
            stat.last = stat.end;
        }
        if (xAxisIsTime) {
            if (stat.first === -1) {
                return dateLib(stat.last).format("HH:mm:ss");
            } else {
                return dateLib(stat.first).format("HH:mm:ss") + " - " + dateLib(stat.last).format("HH:mm:ss");
            }
        }
        return stat.first + "-" + stat.last;
    },

    /**
     * Given an object, turn it into HTML so we can display in the tooltip
     * @param obj
     * @returns {string}
     * @private
     */
    _createToolTipHTML: function(obj) {
        var fullStats = "<br />";
        var that = this;
        var toolTipTemplate = _.template(
            '<span style="padding-left:10px; text-transform: capitalize">{{key}}: {{value}} </span><br/>'
        );

        _.each(obj, function(val, key) {
            if (that._isTimetamp(val)) {
                fullStats += toolTipTemplate({
                    key: key,
                    value: dateLib(val).format("h:mm:ss A")
                });
            } else {
                fullStats += toolTipTemplate({
                    key: key,
                    value: val
                });
            }
        });
        return fullStats;
    },

    /**
     * A intelligent way to figure out if a passed number is a timestamp or not.
     * If the difference between the value and the current timestamp is less than  100*24*60*60*1000 and is a positive number, it means it's a timestamp.
     * The data coming here is usually realtime, so we are pretty sure we will never ever get 100 days old data.
     * @param value
     * @returns {boolean}
     * @private
     */
    _isTimetamp: function(value) {
        var curr = new Date().getTime();
        if (curr - value < 100 * 24 * 60 * 60 * 1000 && curr - value > 0) {
            return true;
        }
        return false;
    },

    /**
     * Add Items and format the data for further use.
     * @param xadded
     * @param data
     * @param fieldList
     * @param categoryField
     * @param lastUsedHash
     * @returns {*}
     * @private
     */
    _addItems: function(xadded, data, fieldList, categoryField, lastUsedHash) {
        var that = this;
        if (data.length === 0) {
            data.push({});
        }
        data[0] = {};
        if (
            xadded.length === 1 &&
            xadded[0].data &&
            xadded[0].data.dataPoints &&
            xadded[0].data.dataPoints.length > 0
        ) {
            var string_response = xadded[0].data.dataPoints[0];
            var objectID = xadded[0].data.dataPoints[1];
            var json = JSON.parse(string_response);
            var setData = function(objectsMap, isTimestampForXAxis) {
                _.each(objectsMap, function(objectEntry) {
                    if (objectEntry.meta && objectEntry.meta.id) {
                        _.each(objectEntry.stats, function(stat) {
                            if (!stat.last && !isTimestampForXAxis) return; // when no operations are happening in that interval, no-op

                            var key = that._getXAxisLabel(stat, isTimestampForXAxis);
                            var componentName = utils.getName(objectEntry.meta.id);
                            if (!data[0][key]) {
                                stat["Count for " + componentName] = stat.count;
                                data[0][key] = [
                                    {
                                        STRIIM_VALIDATION_STATS: stat,
                                        STRIIM_VALIDATION_COMPONENT: componentName,
                                        STRIIM_VALIDATION_IS_VALID: true,
                                        STRIIM_VALIDATION_X: key,
                                        STRIIM_VALIDATION_Y: stat.count
                                    }
                                ];
                            } else {
                                var rollupStats = data[0][key][0]["STRIIM_VALIDATION_STATS"];
                                var newCount = rollupStats.count + stat.count;
                                rollupStats["Count for " + componentName] = stat.count;
                                rollupStats.count = newCount;
                                data[0][key][0]["STRIIM_VALIDATION_Y"] = newCount;
                                data[0][key][0]["STRIIM_VALIDATION_STATS"] = rollupStats;
                            }

                            lastUsedHash[key] = new Date();
                        });
                    }
                });
            };

            _.each(json, function(timeChunk) {
                // populateSourceRate(timeChunk);
                var isTimestampForXAxis = timeChunk.SOURCE && timeChunk.SOURCE.time === 0;
                if (objectID.indexOf("SOURCE") !== -1 && timeChunk.SOURCE) {
                    setData(timeChunk.SOURCE, isTimestampForXAxis);
                } else if (objectID.indexOf("TARGET") !== -1 && timeChunk.TARGET) {
                    setData(timeChunk.TARGET, isTimestampForXAxis);
                }
            });
        }

        _.each(data[0], function(val, key) {
            // create tooltip and remove additional stats.
            val[0].STRIIM_VALIDATION_STATS["Total Count"] = val[0].STRIIM_VALIDATION_STATS.count;
            delete val[0].STRIIM_VALIDATION_STATS.count;
            delete val[0].STRIIM_VALIDATION_STATS.start;
            delete val[0].STRIIM_VALIDATION_STATS.end;
            val[0].STRIIM_VALIDATION_STATS = that._createToolTipHTML(val[0].STRIIM_VALIDATION_STATS);
        });

        return data;
    }
});

export default CurrentWithCategory;
