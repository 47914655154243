import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    loader: {
        position: "fixed",
        top: "48px",
        width: "100%",
        zIndex: "2000"
    }
}));
