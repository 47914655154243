const key = "FLOW_copied_meta_object";

export default {
    save: function (metaObject) {
        localStorage.setItem(key, JSON.stringify(metaObject));
    },
    load: function () {
        let metaObject = localStorage.getItem(key);

        if (metaObject) {
            metaObject = JSON.parse(metaObject);
        }

        return metaObject;
    },
    clear: function () {
        localStorage.removeItem(key);
    },
};
