import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing, palette, shadows, breakpoints }) => {
    return {
        wrapper: {
            marginTop: 48,
            marginBottom: -64,
            paddingTop: spacing(4),
            [breakpoints.up("lg")]: {
                paddingLeft: 176,
                paddingRight: 176
            },
            [breakpoints.down("md")]: {
                paddingLeft: spacing(4),
                paddingRight: spacing(4)
            },
            borderBottom: `1px solid ${palette.paper.border}`,
            backgroundColor: palette.paper.background,
            boxShadow: shadows[1],
            "& .MuiGrid-spacing-xs-2 > .MuiGrid-item": {
                padding: spacing(1)
            }
        },
        headingContainer: {
            padding: spacing(1, 0)
        },
        inputsContainer: {
            padding: spacing(2, 0)
        },
        topButton: {
            marginLeft: spacing(1)
        },
        noOptionsMessage: {
            marginTop: spacing(2.2),
            marginBottom: spacing(2.2),
            paddingLeft: spacing(2.2)
        },
        filterLabel: {
            marginLeft: spacing(1)
        },
        viewAllResults: {
            alignItems: "center",
            padding: spacing(1.5)
        },
        viewAllText: {
            marginLeft: spacing(0.5)
        },
        menuContainer: {
            maxHeight: 600,
            overflowY: "auto",
            paddingTop: spacing(0.5),
            paddingBottom: spacing(0.5)
        },
        tabLabel: {
            display: "flex",
            alignItems: "center"
        },
        ssoIcon: {
            display: "flex",
            marginLeft: spacing(0.5),
            "& svg": {
                width: 16,
                height: 16
            }
        },
        ssoConfigured: {
            color: themeV5 => themeV5.palette.success[500]
        },
        ssoNotConfigured: {
            color: themeV5 => themeV5.palette.critical[500]
        }
    };
});
