import $ from "jquery";

var module = {};

module.show = function ($content, $selector, position, options) {
    var arrow_position = module._getArrowPosition(position);
    var $wrapper = $("<div>").addClass("tooltipyo").addClass(arrow_position);
    options = options || {};

    if (options.customClass) {
        $wrapper.addClass(options.customClass);
    }

    module.$wrapper = $wrapper;
    $wrapper.css("opacity", "0");
    $wrapper.css("display", "none");

    var close = function () {
        if (typeof options.onBeforeClose === "function" && !options.onBeforeClose()) {
            return;
        }

        $wrapper.css("opacity", "0");
        setTimeout(function () {
            $wrapper.css("display", "none");
            $wrapper.remove();
        }, 500);
    };

    $wrapper.on("remove", function () {
        if (options.onClosed && typeof options.onClosed === "function") {
            options.onClosed($wrapper);
        }
    });

    if (!options.hideCloseButton) {
        var $close_button = $('<div class="close">Close</div>');
        $content.append($close_button);
        $close_button.click(function () {
            close();
        });
    }

    $wrapper.append(
        $("<div>")
            .addClass("card")
            .addClass("tooltip-card")
            .addClass("z-depth-8")
            .html($content)
    );

    $("body").append($wrapper);
    var positionNew = module._getToolTipPositionForSelector($selector, position, $wrapper);

    $wrapper.css("left", positionNew.x + "px");
    $wrapper.css("top", positionNew.y + "px");
    $wrapper.css("opacity", "1");
    $wrapper.css("display", "block");

    var close_on_outside = function () {
        $("body").click(close);
    };

    $($wrapper).click(function (event) {
        event.stopPropagation();
    });

    setTimeout(close_on_outside, 500);
};

module._getArrowPosition = function (position) {
    switch (position) {
        case "top":
            return "bottom";
        case "bottom":
        case "bottomRight":
            return "top";
        case "left":
            return "right";
        case "right":
            return "left";
        default:
            throw new Error("Unknown Direction. Please refer the documentation");
    }
};

module._getToolTipPositionForSelector = function ($selector, position, $wrapper) {
    var selector_offset = $selector.offset();
    var selector_x = selector_offset.left;
    var selector_y = selector_offset.top;
    var selector_height = $selector.height();
    var selector_width = $selector.width();
    var selector_mid_x = selector_x + selector_width / 2;
    var selector_mid_y = selector_y + selector_height / 2;

    var position_css = {};

    //let's set the x
    if (position === "top" || position === "bottom") {
        position_css.x = selector_mid_x - $wrapper.width() / 2;
    }
    if (position === "left") {
        position_css.x = selector_x - $wrapper.width() - 20;
    }
    if (position === "right") {
        position_css.x = selector_x + selector_width + 20;
    }

    //let's set the y

    if (position === "left" || position === "right") {
        position_css.y = selector_mid_y - $wrapper.height() / 2;
    }
    if (position === "top") {
        position_css.y = selector_y - $wrapper.height();
    }
    if (position === "bottom") {
        position_css.y = selector_y + selector_height;
    }

    if (position === "bottomRight") {
        position_css.x = selector_x - $wrapper.width() + 20;
        position_css.y = selector_y + selector_height;
    }

    return position_css;
};

module.hide = function () {
    if (!module.$wrapper) return;
    module.$wrapper.css("opacity", "0");

    setTimeout(function () {
        module.$wrapper.css("display", "none");
        module.$wrapper.remove();
        //$('#content').animate({"opacity": 1});
    }, 500);
};

export default module;
