export const getSources = () => [
    {
        type: "SOURCE",
        "adapter-name": "JMSReader"
    },
    {
        type: "SOURCE",
        "adapter-name": "HDFSReader"
    },
    {
        type: "SOURCE",
        "adapter-name": "MQTTReader"
    },
    {
        type: "SOURCE",
        "adapter-name": "HTTPReader"
    },
    {
        type: "SOURCE",
        "adapter-name": "HPNonStopEnscribeReader"
    },
    {
        type: "SOURCE",
        "adapter-name": "HPNonStopSQLMPReader"
    }
];
export const getTargets = () => [
    { type: "TARGET", "adapter-name": "MQTTWriter" },
    { type: "TARGET", "adapter-name": "S3Writer" },
    { type: "TARGET", "adapter-name": "RedshiftWriter" },
    { type: "TARGET", "adapter-name": "WebAlertAdapter" },
    { type: "TARGET", "adapter-name": "ClickatellSMSAdapter" },
    { type: "TARGET", "adapter-name": "SysOut" },
    { type: "TARGET", "adapter-name": "EmailAdapter" },
    { type: "TARGET", "adapter-name": "BigQueryWriter" },
    { type: "TARGET", "adapter-name": "MapRDBWriter" },
    { type: "TARGET", "adapter-name": "MapRStreamWriter" },
    { type: "TARGET", "adapter-name": "HazelcastWriter" },
    { type: "TARGET", "adapter-name": "AzureBlobWriter" },
    { type: "TARGET", "adapter-name": "AzureEventHubWriter" },
    { type: "TARGET", "adapter-name": "GCSWriter" },
    { type: "TARGET", "adapter-name": "DatabaseWriter" }
];
