const TRACKER_STRINGS = {
    schema: { 
        appControl: "app-control",
        automatedAppGroup: "automated-app-group",
        automatedAppDetails: "automated-app-progress-details",
        dashboard: "dashboard",
        error: "error",
        flowDesigner: "flow-designer",
        logIn: "log-in",
        learnMore: "learn-more",
        navigationBar: "navigation-bar",
        schemaSelection: "schema-selection",
        warning: "warning",
        wizards: "wizards",
        wizardsSummary: "wizards-summary",
        success: "success",
        targetAcked: "target-acked"
    },
    eventClicked: {
        appControl: "App:Clicked",
        dashboard: "Dashboard:Clicked",
        flowDesigner: "Flow Designer:Clicked",
        learnMore: "Learn more:Clicked",
        navigationBar: "Navigation Bar:Clicked",
        wizards: "Wizards:Clicked",
        automatedAppGroup: "Automated App Group:Clicked"
    },
    eventLabel: {
        automatedAppDetails: "Automated App Group:Progress details",
        targetAcked:"Target-acked Logged"
    },
    version: {
        withModification: "1-0-1"
    }
};

export {
 TRACKER_STRINGS
};