import React, { useRef, useEffect, useState, useCallback } from "react";
import { StriimInputField, StriimForm, StriimTheme, StriimButton } from "@striim/striim-ui";
import * as Yup from "yup";
import { Grid } from "@mui/material";

import VaultsService from "./vaults-service";
import { mapOptions } from "../utils";
import { VaultTypes } from "./constants";
import FileSelectionModal from "./file-selection-modal";

const sxStyles = {
    container: {
        marginBottom: 2
    },
    grid: {
        flexWrap: "nowrap",
        alignItems: "center",
        gap: 2.5
    }
};
const GoogleSecretManager = ({ setFormValues, setIsValid, prefillData }) => {
    const [options, setOptions] = useState([]);
    const [fileUploadVisiblity, setFileUploadVisibility] = useState(false);
    const values = prefillData || {
        name: "",
        nsName: "",
        properties: {
            VaultType: VaultTypes.GOOGLE_SECRET_MANAGER,
            projectID: "",
            serviceAccountKeyPath: ""
        }
    };

    useEffect(() => {
        async function fetchData() {
            const theData = await VaultsService.getNamespaces();
            setOptions(theData?.models);
        }

        if (!options.length) {
            fetchData();
        }
    }, [options]);

    const getOptions = useCallback(options => mapOptions(options), [options]);

    const formRef = useRef();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        nsName: Yup.string().required("Required"),
        properties: Yup.object({
            projectID: Yup.string().required("Required"),
            serviceAccountKeyPath: Yup.string().required("Required")
        })
    });

    return (
        <StriimTheme>
            <StriimForm values={values} validationSchema={validationSchema} formRef={formRef} validateOnMount={true}>
                {({ values, setFieldValue, isValid }) => {
                    setFormValues(values);
                    setIsValid(isValid);
                    return (
                        <>
                            <StriimInputField
                                required
                                name="name"
                                label="Name"
                                placeholder="Enter Vault name"
                                isFormElement
                                sx={sxStyles.container}
                                disabled={!!prefillData}
                            />
                            <StriimInputField
                                required
                                name="nsName"
                                label="Namespace"
                                select
                                id="data-test-id-namespace-select"
                                placeholder="Namespace"
                                SelectProps={{ options: getOptions(options), menuPosition: "fixed" }}
                                isFormElement
                                sx={sxStyles.container}
                                disabled={!!prefillData}
                            />

                            <StriimInputField
                                required
                                name="properties.projectID"
                                label="Project ID"
                                helperText="For example : “abcd1234”"
                                isFormElement
                                sx={sxStyles.container}
                            />
                            <Grid container sx={{ ...sxStyles.container, ...sxStyles.grid }}>
                                <Grid item xs={10}>
                                    <StriimInputField
                                        required
                                        name="properties.serviceAccountKeyPath"
                                        label="Service Account Key"
                                        helperText="For example : “/username/filename”"
                                        isFormElement
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <StriimButton
                                        onClick={() => {
                                            setFileUploadVisibility(val => !val);
                                        }}
                                    >
                                        Select File
                                    </StriimButton>
                                </Grid>
                            </Grid>
                            {fileUploadVisiblity && (
                                <FileSelectionModal
                                    setIsModalShown={setFileUploadVisibility}
                                    onFileDone={file => {
                                        setFieldValue("properties.serviceAccountKeyPath", file);
                                    }}
                                />
                            )}
                        </>
                    );
                }}
            </StriimForm>
        </StriimTheme>
    );
};

export default GoogleSecretManager;
