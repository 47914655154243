import { observable } from "mobx";

export default class GroupModel {
    @observable id;
    @observable name;
    @observable description;
    @observable orderNumber;
    @observable collapsed;
    @observable isDefaultGroup;
    @observable groupType;
    @observable applications = [];
    @observable validations = [];
}
