import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PropTypes } from "prop-types";
const useStyles = makeStyles(({ palette }) => ({
    progressBar: {
        backgroundColor: palette.primary.light,
        width: props => (props.percentage ? `${props.percentage}%` : "0%"),
        borderRadius: "4px",
        height: 8
    },
    progressBarAnimated: {
        width: "100%",
        height: "100%",
        backgroundColor: palette.primary.light,
        animation: "$indeterminateAnimation 1s infinite linear",
        transformOrigin: "0% 50%"
    },
    progressBarContainer: {
        borderRadius: "4px",
        height: 8,
        willChange: "transform",
        transition: "all 0.5s",
        width: "100%",
        backgroundColor: "#E1E1E1",
        overflow: "hidden"
    },
    container: {
        display: props => (props.variant === "indeterminate" ? "block" : props.percentage ? "flex" : "none"),
        alignItems: "center",
        width: "100%",
        margin: props => (props.variant === "indeterminate" ? "auto" : "none")
    },
    "@keyframes indeterminateAnimation": {
        "0%": {
            transform: "translateX(0) scaleX(0)"
        },
        "40%": {
            transform: "translateX(0) scaleX(0.4)"
        },
        "100%": {
            transform: "translateX(100%) scaleX(0.5)"
        }
    }
}));

function ProgressIndicator({ percentage, variant }) {
    const classes = useStyles({ percentage });
    return (
        <div className={classes.container}>
            <div className={classes.progressBarContainer}>
                <div className={variant === "indeterminate" ? classes.progressBarAnimated : classes.progressBar}></div>
            </div>
        </div>
    );
}

export default ProgressIndicator;

ProgressIndicator.proptypes = {
    percentage: PropTypes.number,
    variant: PropTypes.oneOf(["indeterminate"])
};
