import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import {
    StriimControlLabel,
    StriimInputField,
    StriimForm,
    StriimRadioGroup,
    StriimRadio,
    StriimTypography,
    StriimFormBuilder,
    FIELD_TYPES
    // @ts-ignore
} from "@striim/striim-ui";

import Password from "../../../../../generic/fd-password/fd-password";
import FileBrowser from "../../../../../generic/filebrowser/filebrowser";
import embeddingGeneratorService from "./embedding-generator-service";
import NamespaceValidation from "../../../../dashboards/pages/dashboard-list/components/add-dashboard-dialog/components/namespace-select/namespace.validation";
import propertyTemplateService from "../../../../../../core/services/metaStoreService/property-template-service";
import { mapOptions } from "../utils";
import { ModelProviderType } from "../../../../../../core/services/metaStoreService/metaObjects/embeddinggenerator";
import { TooltipComponent } from "../../../../../generic/fd-form-builder/fd-form-builder";

export const VERTEXAI_ID = "Global.PROPERTYTEMPLATE.VERTEXAI";
export const OPENAI_ID = "Global.PROPERTYTEMPLATE.OPENAI";
export const APIKEY_TYPE = "com.webaction.security.Password";

const useStyles = makeStyles(({ palette, spacing }) => ({
    formElementWrapper: {
        marginBottom: spacing(2)
    },

    formProperties: {
        marginTop: spacing(3)
    },

    providerTypeWrapper: {
        marginLeft: spacing(-3),
        marginRight: spacing(-3),
        // @ts-ignore
        borderBottom: `solid 1px ${palette.paper.border}`
    },

    providerType: {
        padding: spacing(0, 3),
        marginBottom: spacing(2)
    }
}));

interface EmbeddingGeneratorDetailsForm {
    setIsValid: (valid: boolean) => void;
    initialValues?: any;
    formValues: React.RefObject<null>;
}

export default function EmbeddingGeneratorDetailsForm({
    setIsValid,
    initialValues,
    formValues
}: EmbeddingGeneratorDetailsForm) {
    const [nSoptions, setNSOptions] = useState([]);
    const [isPropertiesModelValid, setIsPropertiesModelValid] = useState(false);
    const [isMainModelValid, setIsMainModelValid] = useState(false);
    const [properties, setProperties] = useState(initialValues.get("properties"));
    const [modelProvider, setModelProvider] = useState(initialValues.get("modelProvider") || ModelProviderType.OpenAI);
    const classes = useStyles();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        nsName: NamespaceValidation
    });

    const propertyRef = useRef();

    useEffect(() => {
        async function fetchData() {
            const theData = await embeddingGeneratorService.getNamespaces();
            setNSOptions(theData?.models);
        }

        if (!nSoptions.length) {
            fetchData();
        }
    }, [nSoptions]);

    useEffect(() => {
        setIsValid(isPropertiesModelValid && isMainModelValid);
    }, [isPropertiesModelValid, isMainModelValid]);

    const staticControlsTop = [
        {
            name: "title",
            component: <div />
        },
        {
            name: "message",
            component: <div />
        }
    ];

    const changeProperties = values => {
        initialValues.set("properties", values);
        setProperties(values);
    };

    const getNSOptions = useCallback(options => mapOptions(options), [nSoptions]);

    const getPropertyMap = model => {
        const vertexTemplate = propertyTemplateService.getPropertyTemplateById(VERTEXAI_ID);
        const openAiTemplate = propertyTemplateService.getPropertyTemplateById(OPENAI_ID);

        const VERTEXAI_PROPERTYMAP = vertexTemplate?.propertyMap.map(attribute => {
            if (attribute.type === APIKEY_TYPE) {
                attribute.type = FIELD_TYPES.PROPERTYTEMPLATE_PASSWORD;
            }
            attribute.additionalLabelcomponent = (
                <TooltipComponent data-test-id={`tooltip-${attribute?.name}`} description={attribute?.description} />
            );
            return attribute;
        });

        const OPENAI_PROPERTYMAP = openAiTemplate?.propertyMap.map(attribute => {
            if (attribute.type === APIKEY_TYPE) {
                attribute.type = FIELD_TYPES.PROPERTYTEMPLATE_PASSWORD;
            }
            attribute.additionalLabelcomponent = (
                <TooltipComponent data-test-id={`tooltip-${attribute?.name}`} description={attribute?.description} />
            );
            return attribute;
        });

        switch (model) {
            case ModelProviderType.VertexAI:
                return VERTEXAI_PROPERTYMAP;
            case ModelProviderType.OpenAI:
            default:
                return OPENAI_PROPERTYMAP;
        }
    };

    const propertiesField = useMemo(() => {
        return (
            <StriimFormBuilder
                staticControlsTop={staticControlsTop}
                validateOnChange={true}
                setFormValid={setIsPropertiesModelValid}
                data={getPropertyMap(modelProvider)}
                onChange={changeProperties}
                customFields={{
                    [FIELD_TYPES.PROPERTYTEMPLATE_PASSWORD]: props => <Password {...props} />,
                    [FIELD_TYPES.FILE]: props => <FileBrowser directoryOnly={false} {...props} />
                }}
                initialFieldValues={properties}
                formRef={propertyRef}
            />
        );
    }, [modelProvider]);

    return (
        <div>
            <StriimForm
                values={initialValues.attributes}
                validationSchema={validationSchema}
                formRef={formValues}
                validateOnMount={true}
            >
                {({ values, isValid, errors }) => {
                    if (isMainModelValid !== isValid) {
                        setIsMainModelValid(isValid);
                    }
                    return (
                        <>
                            <StriimInputField
                                required
                                name="name"
                                label="Name"
                                placeholder="Name"
                                id="data-test-embedding-generator-object-name"
                                value={values.name}
                                onChange={val => {
                                    initialValues.set("name", val);
                                    formValues?.current?.setFieldValue("name", val);
                                }}
                                disabled={initialValues.id}
                            />
                            <StriimInputField
                                onChange={option => {
                                    initialValues.set("nsName", option?.value);
                                    formValues?.current?.setFieldValue("nsName", option?.value);
                                }}
                                required
                                name="nsName"
                                label="Namespace"
                                select
                                id="data-test-id-namespace-select"
                                placeholder="Namespace"
                                SelectProps={{ options: getNSOptions(nSoptions), menuPosition: "fixed" }}
                                value={values.nsName ? { value: values.nsName, label: values.nsName } : null}
                                disabled={initialValues.id}
                            />
                            <br />
                            <div className={classes.providerTypeWrapper}>
                                <div className={classes.providerType}>
                                    <StriimTypography variant="caption3" color="greyscale.800" fontFamily="inter">
                                        AI Model Provider
                                    </StriimTypography> 
                                    <StriimRadioGroup
                                        required
                                        value={modelProvider}
                                        onChange={(event, eventValue) => {
                                            initialValues.set("modelProvider", eventValue);
                                            formValues?.current?.setFieldValue("modelProvider", eventValue);
                                            setModelProvider(eventValue);
                                        }}
                                        row={false}
                                        disabled={initialValues.id}
                                    >
                                        <StriimControlLabel
                                            label={
                                                <StriimTypography color="greyscale.700" variant="body4">
                                                    {ModelProviderType.OpenAI}
                                                </StriimTypography>
                                            }
                                            value={ModelProviderType.OpenAI}
                                            control={
                                                <StriimRadio
                                                    id="model-provider-openai"
                                                    checked={modelProvider === ModelProviderType.OpenAI}
                                                    disabled={initialValues.id}
                                                />
                                            }
                                            isFormElement
                                        />
                                        <StriimControlLabel
                                            value={ModelProviderType.VertexAI}
                                            label={
                                                <StriimTypography color="greyscale.700" variant="body4">
                                                    {ModelProviderType.VertexAI}
                                                </StriimTypography>
                                            }
                                            control={
                                                <StriimRadio
                                                    id="model-provider-vertexai"
                                                    checked={modelProvider === ModelProviderType.VertexAI}
                                                    disabled={initialValues.id}
                                                />
                                            }
                                            isFormElement
                                        />
                                    </StriimRadioGroup>
                                </div>
                            </div>
                        </>
                    );
                }}
            </StriimForm>
            {modelProvider && propertiesField}
        </div>
    );
}
