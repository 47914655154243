import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { StriimTypography, StriimMessageBox, StriimLink, StriimButton } from "@striim/striim-ui-v2";
import styles from "./schema-creation-errors.styles";
import api from "../../../../../core/api/api";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import utils from "../../../../../core/utils";

type Props = {
    appId: string;
};
const AppProgressErrorBox: React.FunctionComponent<Props> = ({ appId }) => {
    const [errorMessage, setErrorMessage] = useState<string>(null);
    const [showMore, setShowMore] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            try {
                const appName = appId ? utils.getName(appId) : null;
                if (appName) {
                    const flowErrors = await api.getFlowErrors(appName);
                    if (flowErrors) {
                        setErrorMessage(JSON.parse(flowErrors)?.message);
                    } else {
                        setErrorMessage(null);
                    }
                } else {
                    setErrorMessage(null);
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }, [appId]);
    if (!errorMessage) return;
    return (
        <Box sx={styles.errorBox}>
            <StriimMessageBox
                text={
                    <Grid>
                        <Box>
                            <StriimTypography
                                variant="body4"
                                sx={{ color: theme => theme.palette.greyscale[900], wordBreak: "break-word" }}
                                data-test-id="error-box-message"
                            >
                                {showMore ? errorMessage : `${errorMessage.substring(0, 160)} ...`}{" "}
                            </StriimTypography>
                            <Box mt={1} ml={-2}>
                                <StriimLink
                                    endIcon={showMore ? <HiChevronUp /> : <HiChevronDown />}
                                    onClick={() => setShowMore(prev => !prev)}
                                >
                                    Details
                                </StriimLink>
                            </Box>
                        </Box>
                    </Grid>
                }
                type="ERROR"
            />
        </Box>
    );
};

export default AppProgressErrorBox;
