import React, { useState, useMemo } from "react";
import { StriimButton, StriimDataTable, StriimTypography } from "@striim/striim-ui-v2";
import App from "app";
import { ErrorsArrayType } from "./types";

const getColumnDefs = (setisModalVisible: Function) => [
    {
        headerName: "Source",
        field: "srcShortName",
        flex: 1,
        resizable: true,

        cellRenderer: ({ value, data }) => {
            return (
                <StriimButton
                    variant="link"
                    onClick={() => {
                        App.vent.trigger("edit:component", data.srcComponentName);
                        App.vent.trigger("cancel:deployment");
                        setisModalVisible(false);
                    }}
                >
                    {value}
                </StriimButton>
            );
        }
    },
    {
        headerName: "Table",
        field: "srcTable",
        flex: 1,

        resizable: true
    },
    {
        headerName: "Column",
        field: "srcCol",
        flex: 1,

        resizable: true
    },
    {
        headerName: "Target",
        field: "targetShortName",
        flex: 1,

        resizable: true,
        cellRenderer: ({ value, data }) => {
            return (
                <StriimButton
                    variant="link"
                    onClick={() => {
                        App.vent.trigger("edit:component", data.tarComponentName);
                        App.vent.trigger("cancel:deployment");
                        setisModalVisible(false);
                    }}
                >
                    {value}
                </StriimButton>
            );
        }
    },
    {
        headerName: "Table",
        field: "tarTable",
        flex: 1,

        resizable: true
    },
    {
        headerName: "Column",
        field: "tarCol",
        flex: 1,

        resizable: true
    },
    {
        headerName: "Message",
        field: "message",
        flex: 3,
        cellRenderer: ({ value, data }) => {
            return (
                <StriimTypography color="greyscale.900" variant="body4" data-test-id={value}>
                    {value}
                </StriimTypography>
            );
        },
        wrapText: true,
        resizable: true
    }
];
interface DataTypeValidationTableProps {
    errors: ErrorsArrayType;
    setisModalVisible: (isVisible: boolean) => void;
}
const DataTypeValidationTable: React.FunctionComponent<DataTypeValidationTableProps> = ({
    errors: tableData,
    setisModalVisible
}) => {
    const autoSizeStrategy = useMemo(() => {
        return {
            type: "fitGridWidth",
            defaultMinWidth: 120
        };
    }, []);
    const columuns = getColumnDefs(setisModalVisible);
    return (
        <div className="ag-theme-quartz-dark">
            <StriimDataTable
                autoSizeStrategy={autoSizeStrategy}
                maxHeight={700}
                rowHeight={90}
                data={tableData}
                columns={columuns}
            ></StriimDataTable>
        </div>
    );
};

export default DataTypeValidationTable;
