export const styles = {
    chevronIcon: {
        fontSize: '10px !important',
        fill: 'none',
        "& > path": {
            fill: ({ palette }) => palette.greyscale[800],
        }
    },
    input: {
        minWidth: '300px',
        "& .Mui-focused": {
            color: 'transparent',
            borderColor: ({ palette }) => `${palette.greyscale[200]} !important`
        }
    },
    inputAdornment: {
        marginLeft: "auto"
    },
    menuList: {
        width: '375px',
        maxHeight: '500px',
        overflowY: 'auto'
    },
    menuItem: {
        padding: 0
    },
    versionName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'block'
    }
}