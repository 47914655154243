export const sxStyles = {
    errorBox: {
        margin: ({ spacing }) => spacing(3, 3, -1, 3),
        "& .MuiCard-root": {
            backgroundColor: theme => theme.palette.critical[50],
            border: theme => `1px solid ${theme.palette.critical[100]}`,
            boxShadow: "unset"
        },
        "& .MuiCardHeader-avatar": {
            "& svg": {
                fill: theme => theme.palette.critical[500],
                color: theme => theme.palette.critical[500]
            }
        }
    }
};
