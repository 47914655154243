import _ from "underscore";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";

export const aliasBuilder = {
    getAlias: function (metaObjectId, isRightOperand) {
        //TODO: skpped for now because of potential tql reserved words violations
        // const name = metaObjectConverter.getName(metaObjectId);
        // let alias = name[0];
        // if (isRightOperand) {
        //     alias += name[1];
        // }
        // return alias.toLowerCase();
        return isRightOperand ? "e" : "i";
    },
};

class JoinClauseBuilder {
    constructor(source, target, sourceField, targetField) {
        this._source = source;
        this._target = target;
        this._sourceField = sourceField;
        this._targetField = targetField;
    }

    buildTql() {
        if (!this._source || !this._target || !this._sourceField || !this._targetField) {
            return "";
        }

        const name = metaObjectConverter.getName(this._target);
        const isFromTheSameNamespace =
            metaObjectConverter.getNamespace(this._source) === metaObjectConverter.getNamespace(this._target);
        const targetName = isFromTheSameNamespace ? name : metaObjectConverter.convertFullNameToShortName(this._target);
        return ` join ${targetName} ${this._getTargetAlias()} on ${this._getSourceAlias()}.${
            this._sourceField
        } = ${this._getTargetAlias()}.${this._targetField} `;
    }

    _getSourceAlias() {
        return aliasBuilder.getAlias(this._source);
    }

    _getTargetAlias() {
        return aliasBuilder.getAlias(this._target, true);
    }
}

/*
 *
 * Used to simplify building TQL string
 * for specified columns and stream
 *
 */
function TqlBuilder() {
    this.stream = null;
    this.columns = [];
    this.conditions = [];
    this.conditionsLogicalOperator = "AND";
    this.streamAlias = "x";
    this.wildcard = "";
}

/**
 *
 * [STATIC] Autofixes some common tql mistakes like quotes etc..
 *
 * @param {string} tql
 * @returns {string}
 */
TqlBuilder.ensureValidTql = function (tql) {
    // make sure only singlequotes are used
    return tql.replace(/"/g, "'");
};

/**
 *
 * [STATIC] Make sure tql fragment is using the correct single quotes
 *
 * @param {string} tql
 * @returns {string}
 */
TqlBuilder.wrapInQuotes = function (tql) {
    // clear all quotes and wrap in singlequote
    return `'${tql.replace(/"/g, "").replace(/'/g, "")}'`;
};

/**
 *
 * Adds a stream used after FROM statement
 *
 * @param {string} stream
 * @returns {TqlBuilder}
 */
TqlBuilder.prototype.withStream = function (stream) {
    this.stream = stream;
    this.streamAlias = aliasBuilder.getAlias(stream);
    return this;
};

/**
 *
 * Adds a column to SELECT statement. TransformationFunction and Alias are optional
 *
 * @param {string} name
 * @param {string=} transformationFunction
 * @param {Array=} params
 * @param {string=} alias
 * @returns {TqlBuilder}
 */
TqlBuilder.prototype.addColumn = function (name, transformationFunction, params, alias) {
    var column = {
        name: name,
        transformationFunction: transformationFunction,
        params: params,
        alias: alias,
    };
    this.columns.push(column);
    return this;
};

/**
 *
 * Adds an expression to SELECT statement.
 *
 * @param {string} name
 * @param {string=} transformationFunction
 * @param {string=} expression
 * @returns {TqlBuilder}
 */
TqlBuilder.prototype.addExpression = function (alias, transformationFunction, expression) {
    this.columns.push({
        name: expression,
        transformationFunction: transformationFunction,
        alias: alias,
    });
    return this;
};

/**
 *
 * Adds a wildcard to match all columns
 *
 * @returns {TqlBuilder}
 */
TqlBuilder.prototype.addWildcard = function () {
    this.wildcard = "*";
    return this;
};

/**
 *
 * Adds a join clause on a specific field
 *
 * @returns {TqlBuilder}
 */
TqlBuilder.prototype.addJoinClause = function (column, target, targetColumn) {
    this.column = column;
    this.target = target;
    this.targetColumn = targetColumn;
    return this;
};

/**
 *
 * Adds a wildcard to match stream in WAEvent
 *
 * @returns {TqlBuilder}
 */
TqlBuilder.prototype.addWAEventWildcard = function () {
    this.wildcard = this.streamAlias;
};

/**
 *
 * Adds a column split to SELECT statement.
 *
 * @param {string} name
 * @param {string=} delimiter
 * @param {Array=} index
 * @param {string=} alias
 * @returns {TqlBuilder}
 */
TqlBuilder.prototype.addSplitColumn = function (name, delimiter, index, alias) {
    let columnName = 'split("' + delimiter + '", ' + name + ", " + index + ")";

    var column = {
        name: columnName,
        transformationFunction: null,
        params: null,
        alias: alias,
    };
    this.columns.push(column);
    return this;
};

/**
 *
 * Adds a column JSON to SELECT statement.
 *
 * @param {string} fieldName
 * @param {string=} functionType
 * @param {Array=} index
 * @param {string=} alias
 * @returns {TqlBuilder}
 */
TqlBuilder.prototype.addJsonColumn = function (fieldName, functionType, jsonProperty, alias) {
    function getFunctionName() {
        switch (functionType) {
            case "Integer":
                return "JSONGetInteger";
            case "Double":
                return "JSONGetDouble";
            case "Boolean":
                return "JSONGetBoolean";
            case "Array":
                return "JSONArrayAdd";
        }
        return "JSONGetString";
    }

    let columnName = `${getFunctionName()}(${fieldName}, '${jsonProperty}')`;

    var column = {
        name: columnName,
        transformationFunction: null,
        params: null,
        alias: alias,
    };
    this.columns.push(column);
    return this;
};

/**
 *
 * Adds Meta filtering condition to WHERE statement.
 *
 * @param {string} name
 * @param {string} operator
 * @param {Array} params
 * @returns {TqlBuilder}
 */
TqlBuilder.prototype.addMetaFilteringStatement = function (metaFieldName, operator, params) {
    var condition = {
        columnName: "TO_STRING(" + this.getMetaDataValueStatement(metaFieldName) + ")",
        operator: operator,
        params: "'" + params + "'",
    };

    this.conditionsLogicalOperator = "OR";
    this.conditions.push(condition);
    return this;
};

/**
 *
 * Adds case statement.
 *
 * @param {Array} params
 * @returns {TqlBuilder}
 */
TqlBuilder.prototype.addCaseStatement = function (condition, thenExpression, elseExpression) {
    var statement = "CASE WHEN " + condition + " THEN " + thenExpression + " ELSE " + elseExpression + " END";

    this.columns.push({
        name: statement,
    });

    return this;
};

/**
 *
 * Adds a column to SELECT statement. Adds a condition to WHERE statement.
 *
 * @param {string} name
 * @param {string} operator
 * @param {Array} params
 * @returns {TqlBuilder}
 */
TqlBuilder.prototype.addFilteringColumn = function (name, operator, params) {
    var condition = {
        columnName: name,
        operator: operator,
        params: params,
    };
    this.conditions.push(condition);
    return this;
};

/**
 *
 * Build TQL string based on given Stream and Columns
 *
 * @returns {string}
 */
TqlBuilder.prototype.build = function () {
    if (!this.stream) {
        throw new Error("Stream cannot be empty");
    }

    if (this.columns.length === 0 && !this.wildcard) {
        throw new Error("At least one column should be added");
    }

    var columns = this.columns.map((column) => this.getColumnStatement(column), this);

    let conditionStatement = "";
    if (this.conditions.length > 0) {
        let conditions = this.conditions.map((condition) => {
            return condition.columnName + " " + condition.operator + " " + condition.params;
        });

        conditionStatement = " WHERE " + conditions.join(" " + this.conditionsLogicalOperator + " ");
    }

    const joinClauseBuilder = new JoinClauseBuilder(this.stream, this.target, this.column, this.targetColumn);
    const joinStatement = joinClauseBuilder.buildTql();

    let select = "SELECT ";
    if (this.wildcard) {
        columns.unshift(this.wildcard);
    }
    return (
        select +
        columns.join(", ") +
        " FROM " +
        this.stream +
        " " +
        this.streamAlias +
        joinStatement +
        conditionStatement +
        ";"
    );
};

TqlBuilder.prototype.getColumnStatement = function (column) {
    var name = column.name;
    if (column.transformationFunction) {
        name = column.transformationFunction + "(" + name;

        if (column.params) {
            if (_.isArray(column.params) && !column.objectParams) {
                name += ', "' + column.params.join('", "').replace(/\\/g, "\\\\") + '"';
                1;
            } else if (_.isArray(column.params) && column.objectParams) {
                name += ", " + column.params.join(", ");
            } else {
                name += ', "' + column.params + '"';
            }
        }

        name += ")";
    }

    if (column.operator) {
        name = name + " " + column.operator + column.params;
    }

    if (column.alias) {
        name += " AS " + column.alias;
    }
    return name;
};

TqlBuilder.prototype.getMetaDataValueStatement = function (metadataName) {
    return "META(" + this.streamAlias + ", '" + metadataName + "')";
};

TqlBuilder.prototype.getPutUserDataStatement = function (params) {
    var putUserDataParameters = [];

    params.forEach((param) => {
        putUserDataParameters.push("'" + param.key + "'");
        putUserDataParameters.push(param.value);
    });

    var column = {
        name: this.streamAlias,
        transformationFunction: "PutUserData",
        params: putUserDataParameters,
        objectParams: true,
        alias: null,
    };

    return this.getColumnStatement(column);
};

TqlBuilder.prototype.getChangeOperationToInsertStatement = function (event) {
    return "ChangeOperationToInsert(" + event + ")";
};

export default TqlBuilder;
