/** load and save data validation type errors from local storage
 */

function _getKey(app) {
    return "validation_exc_" + app.uuid;
}

/** @function save errors
 * save data validation type errors to local storage
 *  @param {string} app - current application
 *  @param {array} errors - list of application errors
 *  @param {object} deploymentInfo - hashmap that contains information about deployment groups {flow, deployment group}
 */
function saveValidationErrors(app, errors, deploymentInfo) {
    return new Promise(function (resolve, reject) {
        if (errors && errors.length > 0) {
            const content = JSON.stringify({
                errors: errors,
                deploymentInfo: deploymentInfo,
            });

            localStorage.setItem(_getKey(app), content);
        } else {
            localStorage.removeItem(_getKey(app));
        }
        resolve();
    });
}

/** @function loadValidationErrors
 * load data validation type from local storage
 *  @param {string} app - current application
 *  @returns {object} information about errors and deployment groups
 */
function loadValidationErrors(app) {
    return new Promise(function (resolve, reject) {
        let content = localStorage.getItem(_getKey(app));
        if (content) {
            try {
                content = JSON.parse(content);
            } catch (error) {
                console.error("Data type validation: wrong JSON format", error);
            }
        }
        if (!content) {
            content = {};
        }
        if (!content.hasOwnProperty("deploymentInfo")) {
            content.deploymentInfo = {};
        }
        if (!content.hasOwnProperty("errors")) {
            content.deploymentInfo = {};
        }

        resolve(content);
    });
}

/** @function hasSavedValidationErrors
 * checks if local storage contains errors for application
 *  @param {string} app - current application
 *  @returns {Boolean} true when application has data type validation errors
 */
function hasSavedValidationErrors(app) {
    return new Promise(function (resolve, reject) {
        loadValidationErrors(app).then((content) => {
            resolve(content && content.errors && content.errors.length > 0);
        });
    });
}

export { saveValidationErrors, loadValidationErrors, hasSavedValidationErrors };
