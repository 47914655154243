import React from "react";
import metaObjectHelper from "../../../../../../../../app/components/common/editor/meta-object-editors/metaObjectHelper";
import { StriimTypography } from "@striim/striim-ui-v2";
import { formatDate } from "../../../../../../table-progress/table-progress.store";
import { IL_SUFFIX } from "../../../../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";
import numeral from "numeral";
import statusManagement, { FlowStatus } from "../../../../../../../status-management";

const sortAutomatedApps = apps => {
    // IL should be the first App, CDC second
    if (!apps.length) return [];
    if (apps[0].endsWith(IL_SUFFIX)) return apps;
    return apps.reverse();
};

const getTablesCount = tableInfo => {
    try {
        if (tableInfo) {
            const tables = JSON.parse(tableInfo);
            return Object.keys(tables).length;
        }
        return 0;
    } catch (e) {
        console.error("Error parsing tableInfo", e);
        return 0;
    }
};

const getIcons = (appList, sortedGroups) => {
    const CDCAppStats = appList.filter(appData => appData.id === sortedGroups[1])[0];
    return {
        sourceIcon: metaObjectHelper.getIconClassByMetaObject(CDCAppStats?.sources[0], true),
        targetIcon: metaObjectHelper.getIconClassByMetaObject(CDCAppStats?.targets[0], true)
    };
};

const getProgressInformation = (stage, group, appList, status, mostRecentData) => {
    const readerData = mostRecentData.readerData;
    const writerData = mostRecentData.writerData;

    const appendRateSuffix = (value, unit) => {
        if (typeof value === "number" || typeof value === "string") {
            value = numeral(value).format("0.[00] a");
            return `${value} ${unit}`;
        }
        return "-";
    };
    const rateUnit = " records/s";

    const TotalWithDiscardedEvents = () => {
        return (
            <div>
                <StriimTypography variant="body3">
                    {writerData?.["target-output"] ?? "-"}
                    <StriimTypography variant="body4" sx={{ color: "greyscale.700" }}>
                        {" | Discarded "}
                    </StriimTypography>
                    {writerData?.["discarded-event-count"] ?? "-"}
                </StriimTypography>
            </div>
        );
    };

    const computeLastWriteTime = lastWriteAge => {
        if (!lastWriteAge) return "-";
        const currentTime = new Date().getTime() - lastWriteAge * 1000;
        return formatDate(currentTime);
    };
    const getReadOrWriteRate = (value, status) => {
        if (status && value && statusManagement.isValidStateToShowAdapterRate(status)) {
            return value;
        }
        return "-";
    };

    if (stage === "IL") {
        return {
            sourceStats: [
                { label: "Total records", value: readerData?.["source-input"] },
                {
                    label: "Read rate",
                    value: getReadOrWriteRate(appendRateSuffix(readerData?.["source-rate"], rateUnit), status)
                },
                {
                    label: "Last read",
                    value: formatDate(readerData?.["last-io-time"] ?? readerData?.["latest-activity"])
                }
            ],
            targetStats: [
                { label: "Total records", value: <TotalWithDiscardedEvents /> },
                {
                    label: "Write rate",
                    value: getReadOrWriteRate(appendRateSuffix(writerData?.["target-rate"], rateUnit), status)
                },
                {
                    label: "Last write",
                    value: formatDate(writerData?.["last-io-time"] ?? writerData?.["latest-activity"])
                }
            ],
            tablesCount: getTablesCount(readerData?.["table-info"])
        };
    } else if (stage === "CDC") {
        return {
            sourceStats: [
                { label: "Total records", value: readerData?.["source-input"] },
                {
                    label: "Read rate",
                    value: getReadOrWriteRate(appendRateSuffix(readerData?.["source-rate"], rateUnit), status)
                },
                {
                    label: "Last read",
                    value: formatDate(readerData?.["last-io-time"] ?? readerData?.["latest-activity"])
                }
            ],
            targetStats: [
                { label: "Total records", value: <TotalWithDiscardedEvents /> },
                {
                    label: "Write rate",
                    value: getReadOrWriteRate(appendRateSuffix(writerData?.["target-rate"], rateUnit), status)
                },
                {
                    label: "Last write",
                    value: computeLastWriteTime(writerData?.["last-write-age"])
                }
            ],
            tablesCount: getTablesCount(readerData?.["table-info"])
        };
    }
};

export { getProgressInformation, getIcons, sortAutomatedApps };
