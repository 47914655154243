import React, { useMemo } from "react";
import { StriimTypography } from "@striim/striim-ui";

import { ROLE_MANAGEMENT_STRINGS } from "../../../constants";
import { Grid } from "@mui/material";

const BasicInfo = ({ details }) => {
    const basicInfo = useMemo(
        () =>
            details.length
                ? {
                      name: details[0].name,
                      namespace: details[0].name.split(".")[0],
                      roleName: details[0].name.split(".")[1]
                  }
                : { name: "-", namespace: "-", roleName: "-" },
        [details]
    );

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item xs={12} marginBottom={1}>
                <StriimTypography variant="h3" color="primary.900">
                    {ROLE_MANAGEMENT_STRINGS.label.basicInfo}
                </StriimTypography>
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    <Grid item>
                        <StriimTypography variant="h5" color="greyscale.800">
                            {ROLE_MANAGEMENT_STRINGS.label.namespace}
                        </StriimTypography>
                    </Grid>
                    <Grid item>
                        <StriimTypography variant="body4" color="greyscale.700">
                            {basicInfo.namespace}
                        </StriimTypography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    <Grid item>
                        <StriimTypography variant="h5" color="greyscale.800">
                            {ROLE_MANAGEMENT_STRINGS.label.roleName}
                        </StriimTypography>
                    </Grid>
                    <Grid item>
                        <StriimTypography variant="body4" color="greyscale.700">
                            {basicInfo.roleName}
                        </StriimTypography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BasicInfo;
