import dateLib from "core/utils/date-utils";
import _ from "underscore";
import { loadExceptions, loadStatuses } from "./eventLogDataLoader";
import exceptionConverter from "./exceptionConverter";
import statusConverter from "./statusConverter";
import eventsLogTypes from "./eventsLogTypes";

export default class EventsDataSource {
    exceptionEntries = [];
    statusEntries = [];
    searchTerm = "";
    nodes = [];
    types = [];

    filter() {
        const filterArray = (array, type) => {
            if (this.types && this.types.length > 0) {
                if (_.contains(this.types, type) === false) {
                    return [];
                }
            }

            if (this.searchTerm) {
                const exp = new RegExp(this.searchTerm, "i");

                array = _.filter(array, entry => {
                    return entry.title.match(exp) || (entry.message && entry.message.match(exp));
                });
            }

            if (this.nodes && this.nodes.length > 0) {
                array = _.filter(array, entry => {
                    return _.contains(this.nodes, entry.address);
                });
            }

            return array;
        };

        const filteredExceptions = filterArray(this.exceptionEntries, eventsLogTypes.issues);
        const filteredStatusEntries = filterArray(this.statusEntries, eventsLogTypes.statusChanges);

        return _.first(
            _.sortBy(filteredExceptions.concat(filteredStatusEntries), item => -item.date),
            200
        );
    }

    async load(startDate, endDate) {
        startDate = startDate.valueOf();
        endDate = endDate.valueOf();

        const exceptions = await loadExceptions(startDate, endDate);
        const statuses = await loadStatuses(startDate, endDate);

        this.exceptionEntries = _.map(exceptions, exceptionConverter);
        this.statusEntries = _.map(statuses, statusConverter);
    }

    getAvailableNodes() {
        return _.sortBy(
            _.uniq(this.exceptionEntries.concat(this.statusEntries), entry => entry.address).map(entry => entry.address)
        ).reverse();
    }

    initialize() {
        return this.load(dateLib().subtract(1, "hour"), dateLib());
    }

    search(searchTerm) {
        this.searchTerm = searchTerm;
    }
}
