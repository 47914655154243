import NestedTypes from "backbone.nestedtypes";

const LocalStorageKey = "DashboardClipboard";

const DashboardClipboard = NestedTypes.Model.extend({
    defaults: {
        items: [],
    },
});

if (!localStorage.getItem(LocalStorageKey)) {
    localStorage.setItem(LocalStorageKey, JSON.stringify([]));
}

DashboardClipboard.prototype.initialize = function () {
    this.items = JSON.parse(localStorage.getItem(LocalStorageKey));
};

DashboardClipboard.prototype.addItems = function (newItems) {
    this.items = newItems;
    this.synchronizeLocalStorage();
};

DashboardClipboard.prototype.synchronizeLocalStorage = function () {
    localStorage.setItem(LocalStorageKey, JSON.stringify(this.items));
};

DashboardClipboard.prototype.getAllItems = function () {
    return this.items;
};

DashboardClipboard.prototype.deactivate = function () {
    this.items.forEach(function (item) {
        item._active = false;
    });
    this.synchronizeLocalStorage();
};

DashboardClipboard.prototype.removeItem = function (item) {
    var index = this.items.indexOf(item);
    if (index !== -1) {
        this.items.splice(index, 1);
    }
    this.synchronizeLocalStorage();
};

DashboardClipboard.prototype.clear = function () {
    this.items = [];
    this.synchronizeLocalStorage();
};

export default DashboardClipboard;
