import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing, shadows }) => ({
    cardContentRoot: {
        padding: 24
    },
    icons: {
        fontSize: 56,
        lineHeight: 0
    },
    titleContainer: {
        flex: 1,
        marginLeft: spacing(2.25)
    },
    learnMorePadding: {
        textAlign: "right"
    },
    iconBase: {
        width: spacing(7.75),
        height: spacing(7.75),
        borderRadius: spacing(1)
    }
}));

export const sxStyles = {
    appTile: {
        width: "100%",
        marginBottom: ({ spacing }) => spacing(3),
        border: ({ palette }) => `1px solid ${palette?.greyscale[200]}`,
        borderRadius: ({ spacing }) => spacing(1),
        backgroundColor: ({ palette }) => palette.additional.white,
        "&:hover": {
            backgroundColor: ({ palette }) => palette.greyscale[50],
            borderColor: ({ palette }) => palette.greyscale[300],
            cursor: "pointer"
        }
    },
    apiTileSelected: {
        backgroundColor: ({ palette }) => palette.greyscale[200],
        borderColor: ({ palette }) => palette.greyscale[400],
        "&:hover": {
            backgroundColor: ({ palette }) => palette.greyscale[300],
            cursor: "default"
        }
    },

    title: {
        color: ({ palette }) => palette.greyscale[900]
    },
    cardTitle: {
        color: ({ palette }) => palette.primary[700]
    },
    infoMessage: {
        color: ({ palette }) => palette.greyscale[900]
    },
    description: {
        color: ({ palette }) => palette.greyscale[700]
    },
    infoBoxContainer: {
        "& .MuiCard-root": {
            backgroundColor: ({ palette }) => palette.greyscale[100],
            borderColor: ({ palette }) => palette.greyscale[200],
            "& .MuiCardHeader-avatar": {
                "& svg": {
                    fill: ({ palette }) => palette.greyscale[600]
                }
            }
        }
    },
    buttonsContainer: {
        gap: ({ spacing }) => spacing(2)
    },
    header: {
        padding: ({ spacing }) => spacing(3),
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    }
};
