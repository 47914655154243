import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { StriimTypography } from "@striim/striim-ui";

import FormikField from "src/generic/formik-field";
import NamespaceSelect from "../../../../../../../dashboards/pages/dashboard-list/components/add-dashboard-dialog/components/namespace-select/namespace-select";
import { USER_MANAGEMENT_STRINGS } from "../../../../users-user/constants";
import { Box, Grid } from "@mui/material";

export const validationSchema = Yup.object({
    namespace: Yup.string().required("Required"),
    rolename: Yup.string().required("Required")
});

const useStyles = makeStyles(({ spacing }) => ({
    container: {
        margin: 0
    },
    form: {
        width: "100%",
        "& .MuiGrid-spacing-xs-2 > .MuiGrid-item": {
            padding: spacing(1)
        }
    },
    checkboxLabel: {
        marginLeft: -10
    },
    formInput: {
        marginBottom: 2
    }
}));

const CreateRoleBasicInfo = ({ formRef }) => {
    const classes = useStyles();

    return (
        <>
            <Grid container>
                <Formik
                    initialValues={{
                        namespace: "",
                        rolename: ""
                    }}
                    validationSchema={validationSchema}
                    validateOnChange
                    innerRef={formRef}
                >
                    {({ values, handleChange }) => {
                        return (
                            <Box component={Form}>
                                <Grid item xs={12}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12} marginBottom={1}>
                                            <StriimTypography variant="h3" color="primary.900">
                                                {USER_MANAGEMENT_STRINGS.label.basicInfo}
                                            </StriimTypography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <NamespaceSelect
                                                required
                                                name="namespace"
                                                label="Namespace"
                                                doNotCreateNamespaces
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box
                                                required
                                                component={FormikField}
                                                name="rolename"
                                                label="Role name"
                                                className={classes.formInput}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        );
                    }}
                </Formik>
            </Grid>
        </>
    );
};

export default CreateRoleBasicInfo;
