import _ from "underscore";

/**
 * @param {String} value
 */
export function className(value) {
    return function decorator(target) {
        target.prototype.className = value;
    };
}

/**
 * @param {String} value
 */
export function tagName(value) {
    return function decorator(target) {
        target.prototype.tagName = value;
    };
}

/**
 * @param {Object} value
 */
export function ui(value) {
    return function decorator(target) {
        target.prototype.ui = value;
    };
}

/**
 * @param {Object} value
 */
export function triggers(value) {
    return function decorator(target) {
        target.prototype.triggers = value;
    };
}

/**
 * @param {Object} value
 */
export function regions(value) {
    return function decorator(target) {
        target.prototype.regions = value;
    };
}

/**
 * @param {Object} value
 */
export function attributes(value) {
    return function decorator(target) {
        target.prototype.attributes = value;
    };
}

/**
 * @param {String} eventName
 */
export function on(eventName) {
    return function(target, name, descriptor) {
        if (!target.events) {
            target.events = {};
        }
        if (_.isFunction(target.events)) {
            throw new Error("The on decorator is not compatible with an events method");
        }
        if (!eventName) {
            throw new Error("The on decorator requires an eventName argument");
        }
        target.events[eventName] = name;
        return descriptor;
    };
}

/**
 * @param {String} eventName
 */
export function onChild(eventName) {
    return function(target, name, descriptor) {
        if (!target.childEvents) {
            target.childEvents = {};
        }
        if (_.isFunction(target.childEvents)) {
            throw new Error("The on decorator is not compatible with an childEvents method");
        }
        if (!eventName) {
            throw new Error("The on decorator requires an eventName argument");
        }
        target.childEvents[eventName] = name;
        return descriptor;
    };
}

/**
 * @param {{change: string}} eventName
 */
export function modelEvent(eventName) {
    return function(target, name, descriptor) {
        if (!target.modelEvents) {
            target.modelEvents = {};
        }
        if (_.isFunction(target.modelEvents)) {
            throw new Error("The modelEvents decorator is not compatible with an events method");
        }
        if (!eventName) {
            throw new Error("The on decorator requires an eventName argument");
        }
        target.modelEvents[eventName] = name;
        return descriptor;
    };
}

/**
 * @param {String} value
 */
export function template(value) {
    return function decorator(target) {
        target.prototype.template = _.template(value);
    };
}

/**
 * @param {String} childViewContainer
 */
export function childViewContainer(childViewContainer) {
    return function decorator(target) {
        target.prototype.childViewContainer = childViewContainer;
    };
}

/**
 * @param {Function} childView
 */
export function childView(childView) {
    return function decorator(target) {
        target.prototype.childView = childView;
    };
}

/**
 * @param {Function} emptyView
 */
export function emptyView(emptyView) {
    return function decorator(target) {
        target.prototype.emptyView = emptyView;
    };
}

/**
 * @param {Object} value
 */
export function props(value) {
    return function decorator(target) {
        _.extend(target.prototype, value);
    };
}
