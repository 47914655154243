import React, { useState, useEffect, useRef } from "react";
import { Link, ListItemText } from "@material-ui/core";
import { StriimTypography, StriimList, StriimListItem } from "@striim/striim-ui";

import useStyles from "./dropdown-help-content.styles";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import LinkButtonListItem from "../../../../../link-button-list-item";
import securityService from "core/services/securityService/securityService";
import { getChipData, getVariantForLicense } from "src/modules/common/segmentation/segmentation.consts";
import Dictionary from "app/components/common/helpable/online-help-dictionary";

const DropdownHelpContent = ({ helpModalState, openAboutModal, openAgentModal }) => {
    const classes = useStyles();
    const [version, setVersion] = useState("");

    const licenseLabelText = getChipData(getVariantForLicense(securityService.currentSegmentationLicense)).label;

    const timer = useRef();

    const delayedClose = () => {
        timer.current = setTimeout(() => {
            helpModalState[1](false);
        }, 300);
    };

    const hasOpened = () => {
        if (timer.current) {
            clearTimeout(timer);
        }
    };

    useEffect(() => {
        let serverModel = null;
        propertyTemplateService.servers.each(model => {
            if (model.isAgent === false) {
                serverModel = model;
            }
        });
        setVersion(serverModel.get("version"));
    }, []);
    return (
        <StriimList
            onMouseLeave={() => {
                delayedClose();
            }}
            onMouseEnter={() => {
                hasOpened();
            }}
        >
            <StriimListItem id={"nav--about-striim"}>
                <ListItemText
                    primary={
                        <StriimTypography
                            variant="h4"
                            onClick={() => {
                                openAboutModal();
                            }}
                        >
                            About Striim{` ${licenseLabelText}`}
                        </StriimTypography>
                    }
                />
            </StriimListItem>
            <StriimListItem id={"nav--download-agent"}>
                <ListItemText
                    primary={
                        <Link
                            onClick={() => {
                                openAgentModal();
                            }}
                        >
                            <ListItemText primary="Download Agent"  className={classes.agent} />
                        </Link>
                    }
                />
            </StriimListItem>
            <StriimListItem onClick={() => window.open("https://striim-downloads.striim.com/Releases/"+version+"/Striim_Utilities_"+version+".zip", "_blank")}>
                <ListItemText primary="Download Utilities" />
            </StriimListItem>
            <StriimListItem onClick={() => window.open(Dictionary.getRootPath(), "_blank")}>
                <ListItemText primary="Documentation" />
            </StriimListItem>
            <StriimListItem button onClick={() => window.open(Dictionary.getPDFPath(), "_blank")}>
                <ListItemText primary="Documentation (PDF)" />
            </StriimListItem>
            <LinkButtonListItem
                id={"nav--contact-us"}
                href="https://www.striim.com/contact/"
                text="Contact us"
                openInTab={true}
            />
        </StriimList>
    );
};

export default DropdownHelpContent;
