import React from "react";
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer } from "recharts";
import { StriimTypography, StriimTooltip } from "@striim/striim-ui";
import { Box, Divider, Grid } from "@mui/material";
import numeral from "numeral";

import { styles } from "./sensitive-data-actions.styles";
import { InfoCircle } from "../../../../../generic/icon/customIcons";

export const actionColors = {
    encrypted: "#356BC0",
    masked: "#ED88BA",
    noAction: "#D9DEE4"
};

const legendColors = Object.values(actionColors);

interface SensitiveDataActionsChartProps {
    sensitiveDataOccurrences: number;
    data: {
        encrypted: number;
        masked: number;
        noAction: number;
    };
    showBottomBorder?: boolean;
}

const SensitiveDataActionsChart: React.FC<SensitiveDataActionsChartProps> = ({
    sensitiveDataOccurrences,
    data,
    showBottomBorder = true
}) => {
    const encryptedData = `Encrypted (${numeral(data.encrypted)
        .format("0.[00]a")
        .toUpperCase()})`;
    const maskedData = `Masked (${numeral(data.masked)
        .format("0.[00]a")
        .toUpperCase()})`;
    const noActionData = `No Action (${numeral(data.noAction)
        .format("0.[00]a")
        .toUpperCase()})`;

    const renderLegend = props => {
        const { payload } = props;

        return (
            <div>
                <StriimTypography variant="caption4" color="greyscale.900" fontFamily="Inter">
                    POLICY ACTIONS
                </StriimTypography>
                {payload.map((entry, index) => (
                    <Grid key={entry.value} container alignItems={"center"} gap={"5px"} mb={0.5}>
                        <Box width={11} height={11} sx={{ backgroundColor: legendColors[index] }} />
                        <Box component={"span"} sx={styles.legendText}>
                            {entry.value}
                        </Box>
                    </Grid>
                ))}
            </div>
        );
    };

    return (
        <>
            <Grid container p={2} gap={1} flexDirection={"column"}>
                <Grid container gap={1}>
                    <StriimTypography
                        variant="caption1"
                        color="greyscale.900"
                        fontSize={14}
                        lineHeight={"20px"}
                        fontWeight={400}
                        fontFamily="Inter"
                    >
                        Occurrences of Sensitive Data
                    </StriimTypography>
                    <StriimTooltip title="Count of sensitive data detected across all fields of all events scanned by Sentinel. An event may contain multiple occurrences of sensitive data. Therefore, the occurrences of sensitive data detected may exceed the number of events that contain sensitive data.">
                        <Box display={"flex"} alignItems={"center"}>
                            <InfoCircle style={styles.tooltipIcon} />
                        </Box>
                    </StriimTooltip>
                </Grid>
                <Grid container>
                    <StriimTypography
                        variant="caption1"
                        color="#5D54AE"
                        fontSize={"22px"}
                        lineHeight={"32px"}
                        fontFamily="Nunito"
                        textTransform="uppercase"
                        name="occurences-count"
                    >
                        {numeral(sensitiveDataOccurrences)
                            .format("0.[00]a")
                            .toUpperCase()}
                    </StriimTypography>
                </Grid>
                <Grid container mt={-1}>
                    <ResponsiveContainer width={"100%"} height={100}>
                        <BarChart
                            width={408}
                            height={20}
                            data={[data]}
                            layout="vertical"
                            margin={styles.barChartPosition}
                            barSize={12}
                            style={styles.barChart}
                        >
                            <XAxis hide type="number" />
                            <YAxis hide type="category" />
                            <Bar
                                name={encryptedData}
                                dataKey="encrypted"
                                stackId="single-stack"
                                fill={actionColors.encrypted}
                                radius={!data.noAction && !data.masked ? 4 : [4, 0, 0, 4]}
                            />
                            <Bar
                                name={maskedData}
                                dataKey="masked"
                                stackId="single-stack"
                                fill={actionColors.masked}
                                radius={!data.encrypted && !data.noAction ? 4 : !data.noAction ? [0, 4, 4, 0] : null}
                            />
                            <Bar
                                name={noActionData}
                                dataKey="noAction"
                                stackId="single-stack"
                                fill={actionColors.noAction}
                                radius={!data.encrypted && !data.masked ? 4 : [0, 4, 4, 0]}
                            />
                            <Legend
                                verticalAlign="bottom"
                                align="center"
                                height={36}
                                layout="vertical"
                                content={renderLegend}
                                wrapperStyle={styles.legendWrapper}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
            {showBottomBorder && <Divider variant="fullWidth" sx={{ width: "100%", borderColor: "greyscale.200" }} />}
        </>
    );
};

export default SensitiveDataActionsChart;
