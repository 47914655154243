import NestedTypes from "backbone.nestedtypes";
import $ from "jquery";
import _ from "underscore";
import dataVisualization from "./dataVisualizations";

/*
 * TODO: To document.
 * A TypeSpec is the blueprint for a NestedTypes Model with certain properties.
 * Each DataVisualization has a `spec` property that holds a TypeSpec for itself.
 * Each TypeSpec can be defined several ways.
 * 1. If the type is an object (a DV is an object), provide property specs in `properties`.
 * (A property spec simply links a certain attribute in the object with a type.)
 * 2. If the type is a collection, define `itemtype` with the TypeSpec for the items inside the collection.
 * 3. If the type is a native type like an Array or String, simply enter Array, String, etc.
 */

// Convert our type spec grammar to a Backbone Model
export default function () {
    this._types = {}; // Type def cache

    // Extend a base type (e.g. Graph)
    // Note: this does a "shallow" extend.
    this.extend_type = function (type, extended) {
        if (!extended) {
            return type;
        }

        // Deep clone super class
        extended = $.extend(true, {}, extended);

        // First do a shallow extend to extend top level props (properties, itemtype, initialize, etc.)
        _(type).defaults(_(extended).omit(["name"]));

        var extend_properties = function (type, extended) {
            _(extended.properties).each(function (property) {
                // See if this superclass property is defined in the provided type
                var defined = _(type.properties).findWhere({
                    attribute: property.attribute,
                });
                //console.log(defined ? '    Extending' : '    Adding', property.attribute, ':', property)
                // If it's not, add it to the property list
                if (!defined) {
                    type.properties.push(property);
                }
                // If it's defined, extend the property definition with the base type's
                else {
                    _(defined).defaults(property);
                }
            });
        };

        // Extend properties array
        extend_properties(type, extended);

        // Extend itemtype
        if (extended.itemtype) {
            this.extend_type(type.itemtype, extended.itemtype);
        }

        return type;
    };

    // Convert a property spec to a Backbone NT attribute
    this.prop_to_attribute = function (property, root) {
        property = $.extend(true, {}, property);

        // If the type provided was just a function, like String or Array, fill it in to the "ctr" prop
        if (_(property.type).isFunction()) {
            property.type = {
                ctr: property.type,
            };
        }

        // Ensure property has an object for a type
        _(property).defaults({
            type: {},
        });

        // Process the type definition -- read the provided constructor, create a new type from the properties (recursive call to this same method), or set it to be a Collection. Extend any existing types
        var property_type = property.type;

        var Constructor = null;

        if (property_type.ctr) {
            // e.g. String, Array, Number
            Constructor = property_type.ctr;
        } else {
            // Else, type should have been defined either with properties hash or itemtype, if collection
            Constructor = this.type_to_model(property_type, root);
        }

        // Create the Backbone attribute definition, consisting of the Constructor and the default value
        var nested_type_options = {};
        if (Constructor) {
            nested_type_options.type = Constructor;
        }

        // If a default value is provided, or if there wasn't a constructor, specify at least a value
        // per NestedTypes' wishes
        var _has_default_value = "value" in property;
        // Else, just set the provided value as the default for this attribute
        if (_has_default_value || !Constructor) {
            nested_type_options.value = property.value;
        }

        // Define custom functionality
        if (property.set) {
            nested_type_options.set = function () {
                return property.set.apply(this);
            };
        }
        if (property.get) {
            nested_type_options.get = function () {
                return property.get.apply(this);
            };
            nested_type_options.toJSON = function () {
                return this.get(property.attribute);
            };
        }
        /*
        if (property.persist === false) {
            nested_type_options.toJSON = function(options) {
                options || (options = {});
                var is_sync = _(options).has('success');
                var value = this.get(property.attribute);
                return value && value.toJSON ? value.toJSON() : value
            }
        }
        */

        // Create the NestedTypes attribute
        //console.log('  Property:', property.attribute, ' Done:', nested_type_options)
        return NestedTypes.options(nested_type_options);
    };

    this.type_to_model = function (typespec, root) {
        if (typespec.extends) {
            this.extend_type(typespec, typespec.extends);
        }

        //console.log('Creating type from specs:', typespec, 'Root:', root)

        // The use of a named Type indicates a necessity to use the same *instance* of NestedTypes.
        // We cache the constructor of any Type that provided a name, for when it is referenced later.
        // Example: a "match" property requires that it be the same type (as far as Javascript is concerned)
        // as every member of the Conditions collection. Equality in type specs is not enough for Type equality.

        // If the requested Type has a name and it is one we already have saved, simply return that Type.
        if (typespec.name && this._types[typespec.name]) {
            return this._types[typespec.name];
        }
        // Else, create a new Type (whether it has a name or not)
        else {
            if (typespec.properties) {
                var defaults = {};

                // Generate a NestedTypes attribute for each of the properties specified
                _(typespec.properties).each(
                    function (property) {
                        defaults[property.attribute] = this.prop_to_attribute(property, root);
                    }.bind(this)
                );

                var ConfigModel = NestedTypes.Model.extend(
                    _({
                        defaults: defaults,
                    }).extend(_(typespec).omit(["properties", "itemtype"]))
                );

                if (typespec.name) {
                    this._types[typespec.name] = ConfigModel;
                }

                return ConfigModel;
            } else if (typespec.itemtype) {
                var CollectionItemType = this.type_to_model(typespec.itemtype);
                return CollectionItemType.Collection;
            }
        }
    };

    this.create_model = function (vistype) {
        var typespec_json = $.extend(true, {}, dataVisualization[vistype].typespec);
        var base_config_model_type = {
            properties: [
                {
                    attribute: "data",
                    value: null,
                    persist: false,
                },
                {
                    attribute: "last_data",
                    value: null,
                    persist: false,
                },
                {
                    attribute: "colors",
                    type: Array,
                    persist: false,
                },
                {
                    attribute: "_spec",
                    value: dataVisualization[vistype].typespec,
                    persist: false,
                },
            ],
        };
        typespec_json = this.extend_type(typespec_json, base_config_model_type);

        return this.type_to_model(typespec_json, typespec_json);
    };
}
