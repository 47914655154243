import Backbone from "backbone";
import { View } from "../database-reader/base-database-reader";
import { DB_PROVIDERS } from "../database-reader/configs/db-providers";

class CosmosILReader extends View {
    setup() {
        this.model.get("connectionParams").set("type", DB_PROVIDERS.COSMOSDB.id);
        this.model.set("isCDC", false);
    }
}

export default {
    View: CosmosILReader,
    vent: new Backbone.Model()
};
