import React, { useMemo } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Box, Grid } from "@mui/material";
import { StriimLink, StriimTooltip, StriimTypography } from "@striim/striim-ui-v2";

import SentinelTableCommon from "./sentinel-table-common";
import { styles } from "./sentinel-table.styles";
import { commaThousands, nFormatter, roundUp } from "./utils";
import {
    EmptyBar,
    SentinelTableBarTooltip,
    SentinelTableTypeRowFields,
    Colors,
    elementsSDIIdentifier
} from "./sentinel-table-utils";
import WithTooltip from "../../../../../generic/tooltip/tooltip";

export interface SentinelTableTypeRow {
    [SentinelTableTypeRowFields.name]: string;
    [SentinelTableTypeRowFields.namespace]: string;
    [SentinelTableTypeRowFields.events]: number;
    [SentinelTableTypeRowFields.sensitiveData]: number;
    [SentinelTableTypeRowFields.nonSensitiveData]: number;
    [SentinelTableTypeRowFields.sdiSensitiveData]: number;
    [SentinelTableTypeRowFields.sdiTaggedSensitiveData]: number;
    [SentinelTableTypeRowFields.sentinels]: number;
}

interface SentinelTableTypeProps {
    title: string;
    tableData: SentinelTableTypeRow[];
    filteredTableData: SentinelTableTypeRow[];
    type: string;
    showProgress?: boolean;
}

const SentinelTableIdentifierType = ({
    title,
    tableData,
    filteredTableData,
    type,
    showProgress
}: SentinelTableTypeProps) => {
    const helpers = useMemo(() => {
        const maxOfEventType = roundUp(
            filteredTableData.reduce(
                (max, row) =>
                    max > row[SentinelTableTypeRowFields.sentinels] ? max : row[SentinelTableTypeRowFields.sentinels],
                0
            )
        );

        return {
            eventsSum: filteredTableData.reduce(
                (partialSum, row) => partialSum + row[SentinelTableTypeRowFields.events],
                0
            ),
            sensitiveDataSum: filteredTableData.reduce(
                (partialSum, row) => partialSum + row[SentinelTableTypeRowFields.sensitiveData],
                0
            ),
            maxOfEventType,
            maxOfEventTypeHalf: maxOfEventType / 2,
            sentinelsSum: filteredTableData.reduce(
                (partialSum, row) => partialSum + row[SentinelTableTypeRowFields.sentinels],
                0
            )
        };
    }, [filteredTableData]);

    const tableColumns = useMemo(
        () => [
            {
                headerName: "App Name",
                headerClass: "dataTableHeader",
                field: SentinelTableTypeRowFields.name,
                flex: 0.12,
                suppressMovable: true,
                sortable: true,
                resizable: true,
                cellRenderer: ({ data }) =>
                    WithTooltip(
                        <StriimLink href={`/#/flow/${data.namespace + "." + data.name}`}>
                            <Box component="span" sx={styles.longValueWrap}>
                                {data.name}
                            </Box>
                        </StriimLink>,
                        data.name
                    ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: "Namespace",
                headerClass: "dataTableHeader",
                field: SentinelTableTypeRowFields.namespace,
                flex: 0.12,
                suppressMovable: true,
                resizable: true,
                sortable: true,
                cellRenderer: ({ value }) => (
                    <StriimTypography color="greyscale.700" variant="body4">
                        {value}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerComponent: () => (
                    <Box sx={styles.customHeader}>
                        <Box display="flex" alignItems="flex-end">
                            <StriimTypography variant="body3" color="greyscale.600" display="inline" mr={0.5}>
                                Events
                            </StriimTypography>
                            <StriimTypography
                                variant="caption3"
                                fontFamily="Inter"
                                color="greyscale.600"
                                display="inline"
                            >
                                ({nFormatter(helpers.eventsSum, 2)})
                            </StriimTypography>
                        </Box>
                    </Box>
                ),
                headerClass: "dataTableHeader",
                field: SentinelTableTypeRowFields.events,
                suppressMovable: true,
                flex: 0.1,
                cellClass: "dataCellTextEnd",
                initialWidth: 90,
                sortable: true,
                resizable: true,
                cellRenderer: ({ value }) => (
                    <StriimTypography color="greyscale.900" variant="body4">
                        {commaThousands(value)}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerComponent: () => (
                    <Box sx={styles.customHeader}>
                        <Box display="flex" alignItems="flex-end">
                            <StriimTypography variant="body3" color="greyscale.600" display="inline" mr={0.5}>
                                Events W/ Sensitive Data
                            </StriimTypography>
                            {/* <StriimTypography
                                variant="caption3"
                                fontFamily="Inter"
                                color="greyscale.600"
                                display="inline"
                            >
                                (of {nFormatter(helpers.sensitiveDataSum, 2)})
                            </StriimTypography> */}
                            {/* <SvgIcon component={InfoCircle} sx={styles.infoCircle} /> */}
                        </Box>
                    </Box>
                ),
                headerClass: "dataTableHeader",
                sortable: true,
                field: SentinelTableTypeRowFields.sensitiveData,
                suppressMovable: true,
                flex: 0.15,
                cellClass: "dataCellTextEnd",
                initialWidth: 90,
                resizable: true,
                cellRenderer: ({ value }) => (
                    <StriimTypography variant="body4" color="greyscale.700">
                        {commaThousands(value)}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerComponent: () => (
                    <Box sx={styles.customHeader}>
                        <Box display="flex" alignItems="center">
                            <span
                                style={{
                                    ...styles.sensitiveDataSquare,
                                    backgroundColor: Colors.Identifier,
                                    paddingRight: "11px"
                                }}
                            ></span>
                            <StriimTypography variant="body3" color="greyscale.600" display="inline" mr={0.5}>
                                Events W/ {type}
                            </StriimTypography>
                        </Box>
                    </Box>
                ),
                headerClass: "dataTableHeader",
                sortable: true,
                field: SentinelTableTypeRowFields.eventsWithSDI,
                suppressMovable: true,
                resizable: true,
                flex: 0.2,
                cellRenderer: ({ data }) => {
                    if (!(data.sensitiveData + data.nonSensitiveData > 0)) return <EmptyBar />;
                    return (
                        <StriimTooltip
                            slotProps={{ tooltip: { sx: styles.tooltip } }}
                            title={
                                <SentinelTableBarTooltip
                                    data={data}
                                    elements={elementsSDIIdentifier(type, SentinelTableTypeRowFields.sdiSensitiveData)}
                                />
                            }
                        >
                            <Grid container sx={styles.chartWrapper}>
                                <ResponsiveContainer width="100%" height={20}>
                                    <BarChart height={20} data={[data]} layout="vertical">
                                        <XAxis hide type="number" domain={[0, "dataMax"]} />
                                        <YAxis hide type="category" />

                                        <Bar
                                            dataKey={SentinelTableTypeRowFields.sdiSensitiveData}
                                            stackId="single-stack"
                                            animationDuration={0}
                                            fill={Colors.Identifier}
                                        />

                                        <Bar
                                            dataKey={SentinelTableTypeRowFields.sensitiveData}
                                            stackId="single-stack"
                                            animationDuration={0}
                                            fill={Colors.sensitiveBarColor}
                                        />
                                        <Bar
                                            dataKey={SentinelTableTypeRowFields.nonSensitiveData}
                                            stackId="single-stack"
                                            animationDuration={0}
                                            fill={Colors.NonSensitive}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                                <StriimTypography
                                    variant="caption3"
                                    fontFamily="Inter"
                                    color="greyscale.700"
                                    paddingLeft={0.6}
                                >
                                    {data.sdiSensitiveData}
                                </StriimTypography>
                            </Grid>
                        </StriimTooltip>
                    );
                },
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerComponent: () => (
                    <Box sx={styles.customHeader}>
                        <Box display="flex" alignItems="flex-end">
                            <StriimTypography variant="body3" color="greyscale.600" display="inline" mr={0.5}>
                                # Sentinels{" "}
                            </StriimTypography>
                            <StriimTypography
                                variant="caption3"
                                fontFamily="Inter"
                                color="greyscale.600"
                                display="inline"
                            >
                                ({helpers.sentinelsSum})
                            </StriimTypography>
                        </Box>
                    </Box>
                ),
                headerClass: "dataTableHeader",
                sortable: true,
                field: SentinelTableTypeRowFields.sentinels,
                flex: 0.1,
                suppressMovable: true,
                cellClass: "dataCellTextEnd",
                resizable: true,
                cellRenderer: ({ value }) => (
                    <StriimTypography color="greyscale.900" variant="body4">
                        {value}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            }
        ],
        [tableData, filteredTableData, type]
    );

    return (
        <SentinelTableCommon
            tableColumns={tableColumns}
            title={title}
            showSentinelsFilter
            tableData={tableData}
            customFilterName="App"
            showProgress={showProgress}
        />
    );
};

export default SentinelTableIdentifierType;
