import App from "app";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import ModalManager from "app/components/common/modal/ModalManager";
import confirmationTemplate from "./dialogWindow.html";
import "app/components/common/_deprecated/form-mixin-dialog/dialog";

var ConfirmDialog = App.FormMixinDialog.View.extend({
    template: _.template(confirmationTemplate),
    regions: {
        content: ".modal-body .inner"
    },

    ui: {
        cancelButton: "button.cancel",
        title: ".heading h3"
    },

    initialize: function(options) {
        options.bind_submit = true;
        options.submit_on_enter = true;
        options.bind_cancel = true;
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
    },
    onRender: function() {
        // This editor provides .modal-inner inside the modal, to help with padding
        this.$el.removeClass("modal-inner");
        this.$el.addClass("confirm-dialog-content");

        if (!this.options.showCancel) {
            this.ui.cancelButton.hide();
        }
        this.ui.title.html(this.options.title);
        if (this.options.additionalClass) {
            this.$el.addClass(this.options.additionalClass);
        }
    },
    validate: function(/*data*/) {
        return [];
    },

    serialize: function() {
        return {};
        //return this.serialize_form(this.$('form#renameForm'));
    }
});

ConfirmDialog.Controller = App.FormMixinDialog.Controller;

function showDialog(showCancel, title, message, modalManager, additionalClass, submitText) {
    var deferred = $.Deferred();

    var dialog = new ConfirmDialog({
        id: 1,
        showCancel: showCancel,
        title: title,
        additionalClass: additionalClass,
        model: new Backbone.Model({
            message: message,
            submit_text: submitText || "OK"
        })
    });

    if (!modalManager) {
        modalManager = new ModalManager();
        modalManager.additionalBackgroundCssClass = "modal-confirm";
        modalManager.additionalContainerClass = "confirm-dialog";
    }

    var dialogView = modalManager.add(dialog);
    /*var controller =*/
    new ConfirmDialog.Controller({
        view: dialog
    });

    dialog.on("form:submit", function(dialog) {
        //  dialog.destroy();
        dialogView.destroy();
        dialog.destroy();
        deferred.resolve(true);
    });

    dialog.on("cancel", function() {
        //dialog.options.animation.hide = 'morph';
        //    dialog.destroy();
        dialogView.destroy();
        dialog.destroy();
        deferred.resolve(false);
    });

    dialog.on("before:destroy", function() {
        //if (modalManager) {
        //modalManager.remove_all_modals();
        //}
    });

    return deferred.promise();
}

function confirmDialog(message, modalManager, additionalClass, title, submitText) {
    const dialogTitle = title || "Confirm";
    // const manager = modalManager || App.Dashboards.ModalManager;
    return showDialog(true, dialogTitle, message, modalManager, additionalClass, submitText);
}

function alertDialog(message, modalManager, additionalClass, title) {
    title = title || "Alert";
    return showDialog(false, title, message, modalManager, additionalClass);
}

export default {
    confirm: confirmDialog,
    alert: alertDialog
};
