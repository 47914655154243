import React from "react";
import { Box } from "@material-ui/core";

import RightToolBarActions from "./components/right-toolbar-actions/right-toolbar-actions.jsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    rightToolBar: {
        display: "flex",
        alignItems: "center"
    }
}));

const RightToolBar = () => {
    const classes = useStyles();
    return (
        <Box className={classes.rightToolBar}>
            <Box component={RightToolBarActions} />
        </Box>
    );
};

export default RightToolBar;
