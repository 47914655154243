// credit, with minor modification: https://codesandbox.io/s/react-hooks-material-ui-pagination-example-trp9o?file=/src/Pagination.js:0-777
import { useState } from "react";

function usePagination(data, itemsPerPage) {
    const [currentPage, setCurrentPage] = useState(1);
    const maxPage = Math.ceil(data.length / itemsPerPage);

    function currentData(theSort = undefined) {
        let theData = data;
        if (theSort) {
            theData = theData.sort(theSort);
        }
        const length = theData.length;
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage > length ? length : begin + itemsPerPage;
        return theData.slice(begin, end);
    }

    function next() {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
    }

    function prev() {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    }

    function jump(page) {
        const pageNumber = Math.max(1, page);
        setCurrentPage(_ => Math.min(pageNumber, maxPage));
    }

    return { next, prev, jump, currentData, currentPage, maxPage };
}

export default usePagination;
