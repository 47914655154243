import React from "react";
import { StriimTypography } from "@striim/striim-ui";
import { useReactHeader } from "../../../hooks/useReactHeader";
import { Grid, SvgIcon } from "@mui/material";
import { Navigate, useParams } from "react-router";

import { styles } from "./guardian.styles";
import { ShieldLock, InfoCircle, Settings, Sherlock, Sentinel } from "../../../generic/icon/customIcons";
import GuardianTabs from "./guardian-tabs";
import { SettingsTab } from "./settings";
import { isUserAdmin } from "../../../../core/user-management/update.control";
import { DiscoveryTab } from "./discovery";
import SentinelMonitor from "./sentinel-monitor/sentinel-monitor";
import SentinelMonitorContext from "./sentinel-monitor/sentinel-monitor-context";
import ROUTES from "../routes.json";
import WithTooltip from "../../../generic/tooltip/tooltip";

const GuardianPageHeader = () => {
    return (
        <Grid container sx={styles.headerWrapper}>
            <Grid item sx={styles.guardianHeaderLeft}>
                <SvgIcon sx={styles.guardianHeaderIcon}>
                    <ShieldLock />
                </SvgIcon>
                <StriimTypography variant="h1" sx={styles.guardianHeaderTitle}>
                    Sensitive Data Governance
                </StriimTypography>
                {WithTooltip(
                    <SvgIcon sx={styles.guardianHeaderInfoIcon}>
                        <InfoCircle />
                    </SvgIcon>,
                    `Discover sensitive data identifiers that can flow through your applications using Sherlock AI. Monitor the sensitive data detected by Sentinels in real-time in the apps where they are added.`
                )}
            </Grid>
        </Grid>
    );
};

const getDefaultOptionKey = (tab: string): number => {
    return tab ? tabOptions.find(option => option.key === tab)?.id ?? tabOptions[0].id : tabOptions[0].id;
};

const tabOptions = [
    {
        id: 0,
        key: "discovery",
        label: "Sherlock AI Reports",
        component: <DiscoveryTab />,
        icon: <SvgIcon component={Sherlock} sx={styles.tabIcon} />
    },
    {
        id: 1,
        key: "sentinel-monitor",
        label: "Sentinel AI Activity",
        component: (
            <SentinelMonitorContext>
                <SentinelMonitor />
            </SentinelMonitorContext>
        ),
        icon: <Sentinel />
    },
    {
        id: 2,
        key: "settings",
        label: "Settings",
        component: <SettingsTab />,
        icon: <Settings style={{ fill: "none" }} />
    }
];

const GuardianHome = () => {
    const isAdmin = isUserAdmin();
    useReactHeader({ title: "Sherlock AI Reports" });
    const { tab } = useParams<{ tab: string }>();

    if (!isAdmin) {
        return <Navigate to={{ pathname: ROUTES.unauthorized }} />;
    }

    return (
        <Grid container flexDirection={"column"} sx={styles.guardianContainer}>
            <GuardianPageHeader />
            <GuardianTabs
                options={tabOptions}
                isAdmin={isAdmin}
                defaultOptionKey={getDefaultOptionKey(tab)}
                // maskingOptions={maskingOptions}
                // tablesData={tablesData}
                // jobsListData={data}
                // appsListData={data}
            />
        </Grid>
    );
};

export default GuardianHome;
