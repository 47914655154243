import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ breakpoints, spacing, palette }) => ({
    wrapper: {
        overflow: "hidden",
        marginTop: spacing(12),
        [breakpoints.up("lg")]: {
            paddingLeft: 176,
            paddingRight: 176
        },
        [breakpoints.down("md")]: {
            paddingLeft: spacing(4),
            paddingRight: spacing(4)
        }
    },
    headingContainer: {
        padding: spacing(3, 3, 2, 3)
    },
    heading: {
        textAlign: "start",
        overflowWrap: "anywhere",
        display: "block",
        marginBottom: spacing(1),
        color: palette => palette.primary[700]
    },
    subtitle: {
        color: palette => palette.greyscale[700],
        "& li,ul": {
            listStyleType: "disc",
            marginLeft: spacing(2),
            color: palette => palette.greyscale[700]
        }
    },
    bottomButtonsContainer: {
        padding: spacing(3),
        "& button": {
            margin: spacing(0, 0, 0, 2)
        }
    },
    form: {
        backgroundColor: palette.background.paper,
        width: 600,
        marginBottom: 300,
        border: palette => `1px solid ${palette.greyscale[200]}`,
        borderRadius: spacing(1)
    },
    driversCheckContainer: {
        marginBottom: spacing(5)
    },
    driversCheck: {
        backgroundColor: palette.blue,
        position: "absolute",
        top: 48,
        left: 0,
        width: "100%",
        "& p": {
            marginTop: spacing(1.75),
            marginBottom: spacing(1.75)
        },
        "& p,a": {
            color: palette.common.white
        },
        "& svg": {
            marginLeft: 8,
            position: "absolute",
            width: 20,
            height: 20,
            cursor: "pointer"
        }
    },
    formInput: {
        marginBottom: spacing(2)
    },
    bottomButton: {
        marginTop: spacing(4)
    },
    formContent: {
        overflow: "visible",
        padding: spacing(3),
        borderTop: palette => `1px solid ${palette.greyscale[200]}`,
        borderBottom: palette => `1px solid ${palette.greyscale[200]}`,
        gap: spacing(2)
    }
}));
