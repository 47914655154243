import NestedTypes from "backbone.nestedtypes";
import Marionette from "marionette";
import $ from "jquery";
import _ from "underscore";
import template from "./list.html";

const List = {};

List.Model = NestedTypes.Model.extend({
    defaults: {
        editor: Object,
        editorCreateMethod: null,
        initial: Boolean,
        value: Array,
        addText: "Add new...",
        emptyMessage: "",
    },

    clear: function () {
        this.items = this.defaults().items;
        this.trigger("clear");
    },
});

const rootClassesWhenChildrenExists = {
    addField: "with-add-field"
}

List.View = Marionette.LayoutView.extend({
    template: _.template(template),
    className: "items-list",
    ui: {
        list: ".list",
        add: ".add",
        addField: ".addField",
        emptyMessage: ".empty-message",
    },

    events: {
        "click @ui.add": "add",
    },

    add: function () {
        var _this = this;
        var rendered = _this.createNewItem();
        this.trigger("added-new-editor", rendered);

        this.toggleEmptyMessage();
    },

    modelEvents: {
        clear: "clear",
    },

    setEnabled: function (enabled) {
        _.each(this.childViews, function (childView) {
            childView.setEnabled(enabled);
        });

        this.ui.add.toggle(enabled);
        this.ui.addField.toggle(enabled);
        if (enabled) {
            this.$(".close-btn").show();
            this.$el.addClass(rootClassesWhenChildrenExists.addField);
        } else {
            this.$(".close-btn").hide();
            this.$el.removeClass(rootClassesWhenChildrenExists.addField);
        }
    },

    refresh: function () {
        this.model.value = _.map(this.childViews, function (childView) {
            return childView.getValue();
        }).filter(function (v) {
            return !!v;
        });

        this.toggleEmptyMessage();
    },

    initialize: function () {
        this.childViews = [];
    },

    clear: function () {
        _.each(this.childViews, function (childView) {
            childView.remove();
        });
        this.childViews = [];

        this.toggleEmptyMessage();
    },

    setValue: function (value) {
        this.model.value = value;
    },

    createNewItem: function (initValue) {
        var _this = this;
        var newItemView;
        if (_this.model.editorCreateMethod) {
            newItemView = _this.model.editorCreateMethod();
        } else {
            newItemView = _this.model.editor.create();
        }
        newItemView.model.on("change", function () {
            _this.refresh();
        });
        newItemView.model.value = initValue;

        _this.childViews.push(newItemView);
        var rendered = newItemView.render().$el;
        rendered.addClass("lineItem");
        _this.ui.list.append(rendered);
        var removeButton = $("<a class='close-btn btn icon'><i class='material-icons'>close</i></a>");
        removeButton.click(function () {
            newItemView.remove();
            _this.childViews.splice(_this.childViews.indexOf(newItemView), 1);
            _this.refresh();
        });
        $(rendered)
            .find(".field-header .type-preview")
            .append(removeButton);
        return rendered;
    },

    onRender: function () {
        var _this = this;
        _.each(_this.model.value, function (modelValue) {
            _this.createNewItem(modelValue);
            _this.refresh();
        });
        if (this.model.initial && _this.model.value.length === 0) {
            this.add();
        }
        this.toggleEmptyMessage();
    },

    toggleEmptyMessage: function () {
        if (!this.ui.emptyMessage.toggleClass) {
            return;
        }
        if (!this.childViews) {
            this.ui.emptyMessage.toggleClass("hidden-element", true);
        }

        const hasValue = this.childViews.length > 0;
        this.ui.emptyMessage.toggleClass("hidden-element", hasValue);
        this.ui.list.toggleClass("hidden-element", !hasValue);
    },
});

export default List;
