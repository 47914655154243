import React from "react";
import { StriimMessageBox } from "@striim/striim-ui";

const MessageBox = ({ objectType = "items" }) => {
    return (
        <StriimMessageBox
            type="NOTIFICATION"
            text={`Note: You can choose to add ${objectType} with TQL from web console.`}
        />
    );
};

export default MessageBox;
