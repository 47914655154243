import App from "app";

const reservedUsers = ["admin", "sys", "saasadmin"];
const reservedNamespaces = [
    "global",
    "saasadmin",
    "system$alerts",
    "admin",
    "system$notification",
    "system$appmanager",
    "system$saml",
    "system$piiglobal"
];

const isUserEditable = user => {
    return user && !reservedUsers.includes(user["User Id"]?.toLowerCase());
};

const isReservedUser = user => reservedUsers.includes(user);

const isReservedRole = role => {
    reservedNamespaces.includes(role);
    const namespace = role?.split(".")[0].toLowerCase();
    return reservedNamespaces.includes(namespace);
};

const canActivateDeactivateUser = user => {
    const currentUser = App.user.name;
    return (
        user &&
        user["User Id"] &&
        isUserEditable(user) &&
        user.originType !== "SAAS" &&
        user["User Id"] !== currentUser &&
        typeof user["isActive"] === "boolean"
    );
};

const isUserDeletable = user => {
    const currentUser = App.user.name;
    return isUserEditable(user) && user.originType !== "SAAS" && user["User Id"] !== currentUser;
};

const isRoleEditable = role => {
    const namespace = role?.name?.split(".")[0].toLowerCase();
    return role && !reservedNamespaces.includes(namespace);
};

const isRoleDeletable = role => {
    return isRoleEditable(role) && role.originType !== "SAAS";
};

const isUserAdmin = () => {
    const result = App.user?.roles?.some(
        role => role?.domain === "Global" && (role?.roleName === "admin" || role?.roleName === "saasadmin")
    );
    return result;
};

const isSaaSUser = user => {
    return user && user.originType === "SAAS";
};

export {
    isUserAdmin,
    isSaaSUser,
    isUserEditable,
    isUserDeletable,
    isRoleEditable,
    isRoleDeletable,
    canActivateDeactivateUser,
    isReservedUser,
    isReservedRole
};
