export const styles = {
    grid: {
        display: "block",
        marginBottom: 3
    },
    box: {
        paddingLeft: 3,
        minWidth: 350
    },
    fullWidth: {
        minWidth: "100%"
    },
    subForm: {
        marginBottom: 0
    },
    modal: {
        "& .MuiDialogActions-root": {
            paddingTop: 0
        }
    },
    modalContent: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.14px"
    },
    messageBox: {
        backgroundColor: "warning.50",
        borderColor: "warning.100",
        marginBottom: ({ spacing }) => spacing(3),
        "& svg": {
            fontSize: "20px",
            color: "warning.500"
        }
    }
};
