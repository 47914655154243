import React from "react";
import { StriimForm, StriimInputField } from "@striim/striim-ui";
import { Box, Grid } from "@mui/material";
import * as Yup from "yup";

import { getNameValidation } from "../../../../apps/pages/create-app-name-app-page/name.validation";
import metaStoreService from "../../../../../../core/services/metaStoreService/meta-store-service";

const notLongerThan4096CharactersValidation = value => {
    return !value || value.length <= 4096;
};

interface NewDiscoveryJobFormProps {
    formRef: object;
    initialValues: object;
    editJob?: boolean;
}

const NewDiscoveryJobForm = ({ initialValues, formRef, editJob = false }: NewDiscoveryJobFormProps) => {
    const validationSchema = Yup.object().shape({
        name: getNameValidation(!editJob, metaStoreService.entities.AIAGENT),
        description: Yup.string().test(
            "cannotBeLongerThan4096Chars",
            "Description cannot be longer than 4096 characters",
            notLongerThan4096CharactersValidation
        )
    });

    return (
        <Grid container p={2}>
            <Grid item width={"100%"}>
                <StriimForm
                    values={initialValues}
                    validationSchema={validationSchema}
                    formRef={formRef}
                    validateOnMount={true}
                    validateOnChange={true}
                    additionalProps={{
                        initialTouched: {
                            name: !!initialValues.name
                        }
                    }}
                >
                    <Box display={"flex"} mb={2}>
                        <StriimInputField
                            name="name"
                            label="Name"
                            id="data-test-job-name"
                            isFormElement
                            required
                            disabled={editJob}
                        />
                    </Box>

                    <StriimInputField
                        name="description"
                        label="Description"
                        isFormElement
                        id="data-test-description"
                        multiline={true}
                        rows={5}
                    />
                </StriimForm>
            </Grid>
        </Grid>
    );
};

export default NewDiscoveryJobForm;
