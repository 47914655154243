import React from "react";
import { Grid, Box } from "@mui/material";
import { StriimTypography, StriimButton } from "@striim/striim-ui";
import { Navigate, useNavigate } from "react-router";
import { isUserAdmin } from "core/user-management/update.control";
import AI_ROUTES from "../../routes.json";
import licenseService from "core/services/metaStoreService/license-service";

const GuardianUnauthorized = () => {
    const isAdmin = isUserAdmin();
    const navigate = useNavigate();
    if (isAdmin && !licenseService?.isAIInsightsDisabled) {
        return <Navigate to={{ pathname: AI_ROUTES.sherlockAI }} />;
    }
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ marginTop: 12 }}>
            <Box component={StriimTypography} variant="h2">
                Sensitive Data Governance can only be managed by administrators
            </Box>
            <Box component={StriimTypography} variant="body" sx={{ marginBottom: 8 }}>
                Please contact your Striim administrator to get the required permissions.
            </Box>
            <StriimButton variant="primaryText" onClick={() => navigate("/landing")}>
                Back to home
            </StriimButton>
        </Grid>
    );
};

export default GuardianUnauthorized;
