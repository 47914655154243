import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import App from "app";
import multi_select_search_template from "./templates/multi_select_text_dropdown.html";
import multi_select_item_template from "./templates/multi_select_item.html";

var MultiSelectItemView = Backbone.Marionette.ItemView.extend({
    template: _.template(multi_select_item_template),
    tagName: "li",
    className: "js-show collection-item",
    initialize: function (options) {
        this.model.set("cid", options.model.cid);
    },
    modelEvents: {
        isChecked: "render",
        isHidden: "render",
    },

    ui: {
        checkbox: 'input[type="checkbox"]',
        container: "div.mb-checkbox",
    },

    events: {
        "change @ui.checkbox": function (event) {
            this.model.set("isChecked", event.currentTarget.checked);
        },
        click: function (eve) {
            if (eve.target !== this.ui.container[0]) {
                return;
            }
            this.ui.checkbox.prop("checked", !this.ui.checkbox.is(":checked"));
            this.ui.checkbox.change();
        },
    },
    onRender: function () {
        if (this.model.get("isHidden")) {
            this.$el.hide();
            this.$el.removeClass("js-show");
        } else {
            this.$el.addClass("js-show");
        }
    },
});

export default Backbone.Marionette.CompositeView.extend({
    template: _.template(multi_select_search_template),
    className: "searchable-select",
    getChildView: function () {
        return MultiSelectItemView;
    },
    childViewContainer: "@ui.listData",
    ui: {
        listData: ".type-list",
        doneButton: ".apply",
        filterText: ".text-search",
        clearButton: ".clear",
    },
    events: {
        "click @ui.doneButton": function (evt) {
            this.selectedValues = [];
            _.each(
                this.collection.models,
                function (model) {
                    if (model.get("isChecked")) {
                        this.selectedValues.push(model.get("name"));
                    }
                },
                this
            );
            this.trigger("selectedListItems", this.selectedValues, this.currentAppName);
            this.ui.filterText.val("");
            _.invoke(this.collection.models, "set", {
                isHidden: false,
            });
            this.collection.reset(this.collection.models);
            evt.stopPropagation();
        },
        click: function (evt) {
            evt.stopPropagation();
        },
        "keyup @ui.filterText": function (event, param) {
            this.targetNumber = -1;
            var enteredValue = event.currentTarget.value;
            _.each(this.collection.models, function (datum) {
                if (datum.get("name").toUpperCase().indexOf(enteredValue.toUpperCase()) !== -1) {
                    datum.set("isHidden", false); // Flag to decide object dropdown show/hide based on user entered text in searchbox.
                } else {
                    datum.set("isHidden", true);
                }
            });
            if (param && param.isOutsideClick) {
                this.onCancelResetSelection();
            }
            this.collection.reset(this.collection.models);
        },
        "click @ui.cancelButton": function (evt) {
            this.$el.parent().hide();
            this.trigger("selectedListItems", this.selectedValues || [], this.currentAppName);
            this.onCancelResetSelection();
            this.collection.reset(this.collection.models);
            evt.stopPropagation();
        },
        "click @ui.clearButton": function (evt) {
            this.clearSelection();
            evt.stopPropagation();
        },
    },

    clearSelection: function () {
        this.ui.filterText.val("");
        _.invoke(this.collection.models, "set", {
            isChecked: false,
            isHidden: false,
        });
        this.selectedValues = [];
        this.trigger("selectedListItems", this.selectedValues, this.currentAppName);
        this.collection.reset(this.collection.models);
    },

    _prepareDataSource: function (items, selectedItems) {
        var _this = this;
        return _.map(items, function (data) {
            return {
                name: data,
                displayName: _this.nameMap ? _this.nameMap[data] : data,
                isChecked: selectedItems ? selectedItems.indexOf(data) !== -1 : false,
            };
        });
    },
    initialize: function (options) {
        this.collection = new Backbone.Collection();
        this.nameMap = options.nameMap;
        this.collection.set(this._prepareDataSource(options.dataArray));
        this.targetNumber = -1;
        this.currentAppName = options.currentAppName;
        this._dropDownPosition = options.dropDownPosition;
    },
    setDataSource: function (items, selectedItems) {
        this.collection.reset(this._prepareDataSource(items, selectedItems));
    },
    onCancelResetSelection: function () {
        if (this.selectedValues) {
            _.each(
                this.collection.models,
                function (model) {
                    if (-1 === this.selectedValues.indexOf(model.get("name"))) {
                        model.set("isChecked", false);
                    } else {
                        model.set("isChecked", true);
                    }
                },
                this
            );
        } else {
            _.each(
                this.collection.models,
                function (model) {
                    if (model.get("isChecked")) {
                        model.set("isChecked", false);
                    }
                },
                this
            );
        }
    },
    onRender: function () {
        this.ui.listData.slimScroll({
            height: "220px",
            alwaysVisible: true,
        });

        if (this._dropDownPosition === "right") {
            this.$el.find(".mb-dropdown").removeClass("left");
        }
    },
});
