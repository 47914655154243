import React from "react";
import { StriimModal, StriimTypography } from "@striim/striim-ui-v2";
import utils from "../../../../../../../core/utils";
import { Box } from "@mui/material";
import LoadingIndicator from "../../../../../../generic/loading-indicator";
import { sortAutomatedApps } from "../automated-app-group/utils/progress-details.helper";

const getTitle = (
    type: string,
    name: string,
    apps: Array<string>
): {
    titleContent: string;
    bodyContent: JSX.Element;
} => {
    if (type === "ILCDC") {
        let ILAppName, CDCAppName;

        if (apps?.[0] && apps?.[1]) {
            ILAppName = utils.getName(apps[0]);
            CDCAppName = utils.getName(apps[1]);
        }
        return {
            titleContent: `Delete ${name} automation`,
            bodyContent: (
                <span>
                    If you proceed, both Apps - {ILAppName} and {CDCAppName} - will be permanently deleted. This action
                    cannot be undone. Are you sure you want to delete the pipeline?
                </span>
            )
        };
    } else {
        return {
            titleContent: "Delete Group",
            bodyContent: (
                <span>
                    Are you sure you want to delete <strong>{name}</strong> group? This will not delete apps in the
                    group. All apps in the group will be moved to the default group.
                </span>
            )
        };
    }
};

interface DeleteGroupModalProps {
    group: {
        groupType: string;
        name: string;
        applications: Array<string>;
    };
    isVisible: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    pageLoading?: boolean;
}

const DeleteGroupModal: React.FunctionComponent<DeleteGroupModalProps> = ({
    group,
    isVisible,
    onConfirm,
    onCancel,
    pageLoading = false
}) => {
    const { titleContent, bodyContent } = getTitle(
        group?.groupType,
        group?.name,
        sortAutomatedApps(group?.applications)
    );

    return (
        <StriimModal
            variant="error"
            autoHeight
            titleContent={
                <>
                    <StriimTypography variant="h2">{titleContent}</StriimTypography>
                    {pageLoading && (
                        <Box sx={{ margin: ({ spacing }) => spacing(0, -3) }} mb={1}>
                            <LoadingIndicator isGlobal={false} />
                        </Box>
                    )}
                </>
            }
            isVisible={isVisible}
            confirmContent="Delete"
            onConfirm={onConfirm}
            onCancel={onCancel}
            confirmButtonProps={{ disabled: pageLoading, "data-testid": "confirm-delete-group-button" }}
            cancelButtonProps={{ disabled: pageLoading, "data-testid": "cancel-delete-group-button" }}
        >
            <>{bodyContent}</>
        </StriimModal>
    );
};

export default DeleteGroupModal;
