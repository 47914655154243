import React from "react";
import { Grid } from "@mui/material";

import { styles } from "./entity-summary.styles";
import CollapsibleSummary from "./collapsible-summary";
import DiscoverySummaryChart from "../discovery-summary-chart/discovery-summary-chart";
import DiscoveryReportChart from "../discovery-report-chart/discovery-report-chart";
import { SensitiveIdentifiersInEntities, Source } from "../../pii.types";

const getEntityReportChartData = (
    data: SensitiveIdentifiersInEntities
): {
    name: string;
    count: number;
    items: string[];
}[] => {
    const list = Object.entries(data);
    if (!list.length) return [];
    const formattedData = list.sort((x, y) => y[1].length - x[1].length);
    return formattedData.map(item => ({
        name: item[0],
        count: item[1].length,
        items: item[1]
    }));
};

interface SourceSummaryProps {
    title?: string;
    defaultOpen?: boolean;
    activeSource: Source;
}

const SourceSummary = ({ title = "Overview", defaultOpen = false, activeSource }: SourceSummaryProps) => {
    return (
        <Grid item alignItems={"center"} sx={[styles.container, styles.overviewWrapper]} maxWidth={332} mt={"51px"}>
            <CollapsibleSummary title={title} defaultOpen={defaultOpen}>
                <DiscoverySummaryChart
                    entity={activeSource.type}
                    entitiesScanned={activeSource.totalEntities}
                    summaryData={{
                        sensitiveData: activeSource.entitiesWithSensitiveData,
                        nonSensitiveData: activeSource.totalEntities - activeSource.entitiesWithSensitiveData
                    }}
                    showBottomBorder={true}
                />
                <DiscoveryReportChart
                    entity={activeSource.type}
                    data={getEntityReportChartData(activeSource.sensitiveIdentifiersInEntities)}
                    sensitiveDataCount={activeSource.entitiesWithSensitiveData}
                    showBottomBorder={false}
                    width={290}
                />
            </CollapsibleSummary>
        </Grid>
    );
};

export default SourceSummary;
