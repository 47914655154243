import { extendObservable, action } from "mobx";

export default function(rawAction, { actionType = "Request", name = "data" }) {
    const exec = async (...params) => {
        this[`${name}Loading`] = true;
        try {
            const result = await rawAction(...params);
            this[name] = result;
            return result;
        } catch (e) {
            this[`${name}Error`] = e;
            throw e;
        } finally {
            this[`${name}Loading`] = false;
        }
    };
    extendObservable(
        this,
        {
            [name]: [],
            [`${name}Loading`]: false,
            [`${name}Error`]: null,
            [`${name}${actionType}`]: exec
        },
        {
            [`${name}${actionType}`]: action
        }
    );
}
