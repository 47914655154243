import React, { useContext, useEffect, useState, useRef, MutableRefObject, useMemo } from "react";
import { Steps, getCurrentStepHeader, getCurrentStepSubHeader, stepDetails } from "../../target-steps";
import Header from "../../../common/header/header";
import Stepper from "../../../common/stepper/stepper";
import { WizardContext } from "../../../wizard-context";
import WizardLayout from "../../../common/wizard-layout/wizard-layout";
import Footer from "../../../common/footer/footer";
import TargetFormBuilder from "../target-form-builder/target-form-builder";
import AdapterSpecificForm from "./adapter-specific-form";
import { getAdapterDefaultProperties } from "../../../services";
import _ from "underscore";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import { styles } from "./adapter-specific-form.styles";
import TARGET_FORM_INFO from "../../target-form-info";
import { Box, Grid } from "@mui/material";
import { StriimMessageBox, StriimTypography } from "@striim/striim-ui";
import { convertNameToHumanRedable } from "app/components/common/editor/control-resource-resolver";
import { sourceInfoType, targetInfoType } from "../../Types";
import { targetUtils, validationUtils } from "../../../utils";
import AdvancedSettingsForm from "../advanced-settings/advanced-settings-form";
import { getWizardType } from "../../../utils/checkAdapterType";
import { TYPES } from "../../../utils/wizard-types";
import { getAdvancedFields, isCDDLSupported } from "../../../services/target";
import navigateTo from "../../../../../navigate-to";
import { DB_PROVIDERS } from "../../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/configs/db-providers";
import { getDatabaseType } from "../../../../../../app/components/appwizard/component-wizards/wizards/common/databaseTypes";
import api from "../../../../../../core/api/api";
import { cloneDeep } from "lodash";
import { getCDCName } from "../../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";
import Tracker from "../../../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../../core/services/tracker/constants";

export interface ConfigureTargetProps {
    target: string;
}

const ErrorMessageBox = ({ errors, labelMap }) => {
    if (!_.isEmpty(errors)) {
        let errorFields = Object.keys(errors);
        errorFields = errorFields.map(field => labelMap?.[field] ?? field);
        return (
            <Box sx={styles.errorBox}>
                <StriimMessageBox
                    text={
                        <Grid>
                            <StriimTypography
                                variant="body4"
                                sx={styles.messageBoxText}
                                data-test-id="error-box-message"
                            >
                                Fix the following errors in the target configuration <br />
                                Provide the missing inputs: {errorFields.join(", ")}
                            </StriimTypography>
                        </Grid>
                    }
                    type="ERROR"
                />
            </Box>
        );
    }
};
const ConfigureTarget: React.FunctionComponent<ConfigureTargetProps> = ({ target }) => {
    const {
        setCurrentStep,
        targetInfo,
        setTargetInfo,
        wizardAppName,
        setSourceInfo,
        sourceInfo,
        isAutomatedILCDC,
        cdcSourceInfo,
        setCdcSourceInfo,
        cdcTargetInfo,
        setCdcTargetInfo,
        setWizardType,
        wizardType,
        templateID
    } = useContext(WizardContext);
    const [enableNext, setEnableNext] = useState(false);
    const [errors, setErrors] = useState({});
    const [labelMap, setLabelMap] = useState({});
    const formRef: MutableRefObject<any> = useRef();
    const advancedSettingsForm: MutableRefObject<any> = useRef();
    const adapterSpecificFormRef: MutableRefObject<any> = useRef();
    const targetDisplayName = PredefinedComponentNames[target]?.name;
    const heading = getCurrentStepHeader(Steps.ConfigureTarget, targetDisplayName);
    const subHeading = getCurrentStepSubHeader(Steps.ConfigureTarget, targetDisplayName);
    const inlineDocKey = targetDisplayName?.replace(/\s+/g, "-").toLowerCase() + "-configure-target";

    const isOracleWizard = (sourceDB: string): boolean => {
        return ["Oracle", "OracleReader"].includes(sourceDB) && !templateID.includes("OJet");
    };

    const header = (
        <Header
            heading={heading}
            subHeading={subHeading}
            additionalComponent={<ErrorMessageBox labelMap={labelMap} errors={errors} />}
        />
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const stepper = <Stepper steps={stepDetails} activeStep={stepDetails.ConfigureTarget.id} />;

    const sourceData = sourceInfo?.sourceAdapter?.properties;
    let sourceDB, whatIsTable;
    if (sourceData?.adapterName === "DatabaseReader") {
        sourceDB = sourceData.DatabaseProviderType;
        whatIsTable = DB_PROVIDERS[sourceDB.toUpperCase()]?.whatIsTable ?? "Table";
    } else if (sourceData?.adapterName) {
        sourceDB = sourceData.adapterName;
        whatIsTable = DB_PROVIDERS[getDatabaseType(sourceDB)]?.whatIsTable ?? "Table";
    }
    const pluralizeWhatisTable = whatIsTable + "s";

    // Based on the source Adapter, Returns custom fields
    const getCustomFields = formData => {
        const fields = cloneDeep(formData);

        if (isOracleWizard(sourceDB)) {
            const CDDLField = fields.filter(item => item.name == "CDDLAction")?.[0];

            if (CDDLField) {
                // Remove "(Default)" suffix
                const defaultSuffix = " (Default)";
                CDDLField.options[0].label = CDDLField.options[0].label.replace(defaultSuffix, "");
                // Make "Ignore" default
                const defaultIndex = CDDLField.options.findIndex(item => item.value == "Ignore");
                CDDLField.defaultValue = CDDLField.options[defaultIndex];
                CDDLField.options[defaultIndex].label = CDDLField.options[defaultIndex].label + defaultSuffix;
                // For Oracle with no CDDL support, hide the field & Make "Ignore" default
                if (!sourceInfo["isCDDLSupported"]) CDDLField.visibility = "false";
            }
        }
        if (
            ["SQLSERVER", "MSSQLREADER", "MARIADB", "MARIADBREADER"].includes(sourceDB.toUpperCase()) &&
            !(cdcSourceInfo?.adapterName?.toUpperCase() === "MSJET")
        ) {
            const CDDLField = fields.filter(item => item.name == "CDDLAction")?.[0];
            if (CDDLField) CDDLField.defaultValue = null;
        }
        return fields;
    };

    // creates the label map with key as name and value as label
    useEffect(() => {
        const formFields = [
            ...TARGET_FORM_INFO[validationUtils.getTargetType(target).toUpperCase()]?.form_fields,
            ...getAdvancedFields(target)
        ];
        let obj = {};
        formFields.forEach(field => {
            obj[field.name] = field?.label ?? convertNameToHumanRedable(field.name);
        });
        setLabelMap(obj);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const fetch = async () => {
            const sourceInfo: sourceInfoType = {};
            let nsName = wizardAppName ? wizardAppName.split(".")[0] : null;
            let appName = wizardAppName ? wizardAppName.split(".")[1] : null;
            let appId = metaObjectConverter.getId(nsName, "APPLICATION", appName);
            let appModel = await metaStoreService.findById(appId);
            //need to be changed
            let isSourceModeAutomated = false;
            let sourceAdapterName;

            const sourceObjects = await appModel.getApplicationComponentsByType("SOURCE");
            let streamObjects = await appModel.getApplicationComponentsByType("STREAM");

            let firstStream = streamObjects.at(0);
            const firstSource = sourceObjects.at(0);

            sourceInfo["inputStream"] = firstStream.get("id");
            sourceInfo["sourceAdapter"] = firstSource.get("adapter");
            sourceInfo["appName"] = appName;
            sourceInfo["appId"] = appId;
            sourceAdapterName = sourceInfo?.sourceAdapter.properties?.adapterName;
            sourceInfo["adapterName"] = sourceAdapterName;
            sourceInfo["sourceId"] = firstSource.get("id");

            const typeOfWizard = getWizardType(sourceInfo["adapterName"]);
            setWizardType(typeOfWizard);

            if (["HubSpotReader", "ZendeskReader", "StripeReader"].includes(sourceAdapterName)) {
                if (sourceInfo?.sourceAdapter?.properties?.Mode === "Automated") {
                    isSourceModeAutomated = true;
                }
            }
            sourceInfo["isSourceModeAutomated"] = isSourceModeAutomated;

            // For Oracle, Check if it has CDDL Support
            const databaseType = sourceInfo?.sourceAdapter?.properties?.DatabaseProviderType ?? sourceAdapterName;
            if (isOracleWizard(databaseType) && typeOfWizard !== "InitialLoad") {
                sourceInfo["isCDDLSupported"] = await isCDDLSupported(wizardAppName);
            }

            setSourceInfo((prevInfo: sourceInfoType) => ({
                ...prevInfo,
                ...sourceInfo
            }));
            if (isAutomatedILCDC) {
                const cdcSourceInfo: sourceInfoType = {};
                let cdcAppName = getCDCName(appName);
                let cdcAppId = metaObjectConverter.getId(nsName, "APPLICATION", cdcAppName);
                var cdcAppModel = await metaStoreService.findById(cdcAppId);
                const cdcSourceObjects = await cdcAppModel.getApplicationComponentsByType("SOURCE");
                let cdcStreamObjects = await cdcAppModel.getApplicationComponentsByType("STREAM");

                let cdcFirstStream = cdcStreamObjects.at(0);
                const cdcFirstSource = cdcSourceObjects.at(0);

                cdcSourceInfo["inputStream"] = cdcFirstStream.get("id");
                cdcSourceInfo["sourceAdapter"] = cdcFirstSource.get("adapter");
                cdcSourceInfo["appName"] = cdcAppName;
                cdcSourceInfo["appId"] = cdcAppId;
                cdcSourceInfo["adapterName"] = cdcSourceInfo?.sourceAdapter.properties?.adapterName;
                cdcSourceInfo["sourceId"] = cdcFirstSource.get("id");

                let cdcTargetProperties = await getAdapterDefaultProperties(cdcAppModel, target, cdcSourceInfo);
                setCdcSourceInfo((prevInfo: sourceInfoType) => ({ ...prevInfo, ...cdcSourceInfo }));
                setCdcTargetInfo((prevInfo: targetInfoType) => ({
                    ...prevInfo,
                    defaultProperties: cdcTargetProperties
                }));
            }
            let targetproperties = await getAdapterDefaultProperties(appModel, target, sourceInfo);
            setTargetInfo((prevInfo: targetInfoType) => ({
                ...prevInfo,
                defaultProperties: targetproperties
            }));
            setIsLoading(false);
        };
        fetch();
    }, []);

    const trackEvent = buttonClicked => {
        Tracker.getInstance().track(
            TRACKER_STRINGS.schema.wizards,
            {
                id: sourceInfo["appId"],
                event: TRACKER_STRINGS.eventClicked.wizards,
                step: stepDetails.ConfigureTarget.stepName,
                adapterType: isAutomatedILCDC ? cdcSourceInfo?.adapterName : sourceData?.adapterName,
                buttonClicked: buttonClicked,
                isAutomatedILCDC: isAutomatedILCDC
            },
            TRACKER_STRINGS.version.withModification
        );
    };

    const handleBack = () => {
        const navigateParams = {
            appName: wizardAppName,
            templateId: templateID,
            stepName: "select-" + pluralizeWhatisTable,
            force: true
        };
        navigateTo.AppWizardTemplate(navigateParams);
        trackEvent("Back");
    };

    const handleNext = async () => {
        try {
            formRef.current.submitForm();
            advancedSettingsForm.current?.submitForm();
            const errorFields = await formRef.current.validateForm();
            const advancedErrorFields = await advancedSettingsForm.current?.validateForm();
            const adapterSpecificFormErrorFields = await adapterSpecificFormRef?.current?.validateForm();
            if (
                !_.isEmpty(errorFields) ||
                !_.isEmpty(adapterSpecificFormErrorFields) ||
                !_.isEmpty(advancedErrorFields)
            ) {
                setErrors({ ...errorFields, ...advancedErrorFields, ...adapterSpecificFormErrorFields });
                return;
            }
            let values = { ...formRef?.current?.values, ...advancedSettingsForm?.current?.values } || {};
            Object.keys(values)?.forEach(val => {
                if (typeof values[val] === "object") {
                    values[val] = values[val].value;
                }
            });
            if (target === "FabricDataWarehouseWriter") {
                const formattedConnectionURL = await api.FabricDataWarehouseWriterGetConnectionURL(values);
                values["formattedConnectionUrl"] = formattedConnectionURL;
            }
            const targetDetails = { ...targetInfo, targetFormValues: values };
            const cdcTargetDetails = { ...targetInfo, targetFormValues: values };
            setTargetInfo(prev => ({
                ...prev,
                targetFormValues: values
            }));
            setCdcTargetInfo(prev => ({
                ...prev,
                targetFormValues: values
            }));
            if (targetUtils.skipTargetValidation(target)) {
                const encryptedURL = await targetUtils.getEncryptedURLs(sourceInfo, target);
                targetUtils.saveTargetAndNavigate(
                    target,
                    sourceInfo,
                    targetDetails,
                    cdcSourceInfo,
                    cdcTargetDetails,
                    wizardType,
                    encryptedURL.source,
                    encryptedURL.target,
                    setCurrentStep
                );
            } else {
                setCurrentStep(Steps.ValidateTarget);
            }
            trackEvent("Next");
        } catch (error) {
            console.log(error);
        }
    };

    const showAdvancedSettings = wizardType => [TYPES.Automated, TYPES.CDC].includes(wizardType);

    const MemoisedAdvancedSettings = useMemo(
        () => (
            // To-Do - Combine the AdvancedSettingsForm and target form together
            <AdvancedSettingsForm
                formRef={advancedSettingsForm}
                target={target}
                source={sourceDB?.toUpperCase()}
                cdcSource={cdcSourceInfo?.adapterName?.toUpperCase()}
                initialValues={targetInfo?.targetFormValues}
                getCustomFields={getCustomFields}
            />
        ),
        [sourceInfo, cdcSourceInfo]
    );

    return (
        <WizardLayout
            Header={header}
            showLoader={isLoading}
            Stepper={stepper}
            inlineDocKey={inlineDocKey}
            Footer={
                <Footer
                    onNext={() => {
                        handleNext();
                    }}
                    onBack={() => {
                        handleBack();
                    }}
                />
            }
        >
            {!_.isEmpty(sourceInfo) && (
                <TargetFormBuilder
                    initialValues={targetInfo?.targetFormValues}
                    enableNext={enableNext}
                    setEnableNext={setEnableNext}
                    adapterName={target}
                    formRef={formRef}
                    source={sourceDB?.toUpperCase()}
                    cdcSource={cdcSourceInfo?.adapterName?.toUpperCase()}
                    getCustomFields={getCustomFields}
                />
            )}
            <AdapterSpecificForm
                adapterName={target}
                formRef={formRef}
                enableNext={enableNext}
                initialValues={targetInfo?.targetFormValues}
                sourceInfo={sourceInfo}
                ref={adapterSpecificFormRef}
            />
            {showAdvancedSettings(wizardType) ? MemoisedAdvancedSettings : null}
        </WizardLayout>
    );
};

export default ConfigureTarget;
