import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing }) => ({
    cancelButton: {
        color: palette.common.pomegranate,
        padding: "5px 0",
        "&:hover": {
            backgroundColor: `${palette.common.pomegranate}14`
        }
    },
    link: {
        marginLeft: spacing(0.5),
        color: palette.primary.main
    },
    details: {
        gap: spacing(0.5)
    }
}));
