import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import { groupNameInvalid, getValidationMsg } from "../../group.validation";
import useStyles from "./app-group-edit.styles";
import { StriimInputField, StriimTypography, StriimModal, StriimMessageBox, StriimButton } from "@striim/striim-ui";
import App from "app";
import WithTooltip from "../../../../../../generic/tooltip/tooltip";

const AppGroupEdit = ({
    open,
    name,
    groupType,
    isNameEditable,
    description,
    onClose,
    setDeleteDialogDisplayed,
    deleteDialogDisplayed,
    groups,
    isNewGroup,
    onDeleteConfirm,
    isDeletable
}) => {
    const [currentName, setCurrentName] = useState("");
    const [currentDescription, setCurrentDescription] = useState(description);
    const classes = useStyles();
    const isEmpty = (currentName || "") === "";
    const isILCDCGroup = groupType === "ILCDC";
    const nameInvalid = () => {
        if (name === currentName && !isNewGroup) {
            return false;
        }
        const groupName = isNewGroup && !currentName ? "" : currentName;
        return groupNameInvalid(groupName, groups, App?.user?.userId);
    };
    const validationMsg = () => {
        const groupName = isNewGroup && !currentName ? "" : currentName;
        if (name === currentName && !isNewGroup) {
            return "";
        }
        return getValidationMsg(groupName, groups, App?.user?.userId);
    };

    useEffect(() => {
        setCurrentName(isNewGroup ? "" : name);
        setCurrentDescription(isNewGroup ? "" : description);
    }, []);

    useEffect(() => {
        if (isNewGroup) {
            setCurrentName("");
            setCurrentDescription("");
        } else {
            setCurrentName(name);
            setCurrentDescription(description);
        }
    }, [isNewGroup]);

    const FooterContent = () => {
        return (
            <Grid container justifyContent="space-between">
                {!isNewGroup && (
                    <Box>
                        {WithTooltip(
                            <StriimButton
                                sx={{ color: "critical.500" }}
                                variant="text"
                                onClick={() => setDeleteDialogDisplayed(true)}
                                disabled={!isNameEditable || !isDeletable}
                                data-test-id={name + "-remove-group"}
                            >
                                Delete Group
                            </StriimButton>,
                            !isDeletable ? "Stop the automated pipeline to delete it" : null
                        )}
                    </Box>
                )}
                <Box sx={{ ml: "auto" }}>
                    <StriimButton
                        variant="text"
                        onClick={() => {
                            setCurrentName(name);
                            setCurrentDescription(description);
                            onClose(null);
                        }}
                    >
                        Cancel
                    </StriimButton>
                    <StriimButton
                        variant="primary"
                        sx={{ ml: 1 }}
                        onClick={() => {
                            onClose({ name: currentName, description: currentDescription });
                        }}
                        disabled={isEmpty || nameInvalid()}
                        data-testid="rename-group-modal-confirm-btn"
                    >
                        Confirm
                    </StriimButton>
                </Box>
            </Grid>
        );
    };

    const modalTitle = isNewGroup ? "New Group" : `Edit group${isILCDCGroup ? `: ${name}` : ""}`;
    return (
        <StriimModal
            disableMaxHeight
            size="medium"
            autoHeight
            titleContent={<StriimTypography variant="h2">{modalTitle}</StriimTypography>}
            isVisible={open}
            footerBorder
            footerContent={<FooterContent />}
        >
            <div className={classes.content} id="groupedit--modal">
                {!isNameEditable && !isNewGroup && (
                    <StriimMessageBox text="The default group name cannot be edited" type={"INFO"}></StriimMessageBox>
                )}
                {!isILCDCGroup && (
                    <Box sx={{ mb: 2 }}>
                        <Box
                            required
                            component={StriimInputField}
                            value={isNewGroup ? undefined : currentName}
                            onChange={setCurrentName}
                            autoFocus
                            id="name"
                            label="Name"
                            disabled={!isNameEditable}
                            error={nameInvalid()}
                            helperText={validationMsg()}
                        />
                    </Box>
                )}
                <StriimInputField
                    value={isNewGroup ? undefined : currentDescription}
                    onChange={setCurrentDescription}
                    id="description"
                    label="Description (Optional)"
                    multiline
                    rows={10}
                />
            </div>
        </StriimModal>
    );
};

AppGroupEdit.propTypes = {
    open: PropTypes.bool,
    name: PropTypes.string,
    isNameEditable: PropTypes.bool,
    description: PropTypes.string,
    onClose: PropTypes.func,
    groups: PropTypes.array
};

export default AppGroupEdit;
