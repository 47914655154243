import React, { useState, useEffect, useRef, useCallback } from "react";
import { StriimTypography } from "@striim/striim-ui";
import { useReactHeader } from "../../../hooks/useReactHeader";
import { Container, Grid } from "@mui/material";
import { toJS } from "mobx";

import App from "app";
import { styles } from "./guardian.styles";
import GuardianFrame from "./components/frame";
import Stepper from "../../wizards/common/stepper/stepper";
import GuardianSelectStep from "./pages/guardian-select-step";
import GuardianConfigureStep from "./pages/guardian-configure-step";
import GuardianReviewStep from "./pages/guardian-review-step";
import TableFilters from "../components/select-tables-component/table-filters";
import useStores from "../../../utils/use-stores";
import GuardianFooter from "./components/guardian-footer/guardian-footer";
import guardianService from "./guardian-service";
import growl from "../../../../app/components/common/growl";
import { useNavigate, useParams } from "react-router";
import LoadingIndicator from "../../../generic/loading-indicator";
import navigateTo from "../../../../src/navigate-to";
import AIROUTES from "../routes.json";
import NewDiscoveryJobForm from "../discover-pii-panel/components/new-discovery-job/new-discovery-job-form";
import { filterApps } from "../components/select-tables-component/select-tables-component";

const MainHeader = ({ children }) => {
    return (
        <Grid item xs={6} sx={styles.discoverDataHeader}>
            <StriimTypography variant="h4">Create a Sensitive Data Discovery Report</StriimTypography>
            {children}
        </Grid>
    );
};

const SelectAppsStep = {
    SelectApps: {
        id: 0,
        name: "Select Apps",
        isLastStep: false
    }
};

const ConfigureReviewSteps = {
    ConfigureJob: {
        id: 1,
        name: "Configure Your Report",
        isLastStep: false
    },
    ReviewDiscovery: {
        id: 2,
        name: "Review",
        isLastStep: true
    }
};

const GuardianSelectApps = ({ selectApps, editJob = false }) => {
    useReactHeader({ title: "Create a Sensitive Data Discovery Report" });
    const navigate = useNavigate();
    const { jobName } = useParams<{ jobName: string }>();

    let Steps = selectApps ? { ...SelectAppsStep, ...ConfigureReviewSteps } : ConfigureReviewSteps;

    Object.keys(Steps).forEach((key, index) => (Steps[key].id = index));

    const StepNames = Object.values(Steps).map(v => v.name);
    const StepsValues = Object.values(Steps);

    const [job, setJob] = useState({ applications: [] });
    const [activeStep, setActiveStep] = useState<number>(0);
    const [currentStepName, setCurrentStepName] = useState<string>(StepNames[0]);
    const [formValues, setFormValues] = useState({});
    const [isValid, setIsValid] = useState<boolean>(false);
    const { store } = useStores();
    const [apps, setApps] = useState<AppProps[]>(store.apps);
    const [tableData, setTableData] = useState<AppProps[]>(apps);
    const [selectedRows, setSelectedRows] = useState<AppProps[]>([]);
    const [filters, setFilters] = useState({
        searchQuery: "",
        selectedSource: null,
        selectedNamespace: null,
        selectedStatus: null
    });
    const [hasRun, setHasRun] = useState<boolean>(false);

    const formRef = useRef();
    const ref = useCallback(node => {
        if (node) {
            formRef.current = node;
            setIsValid(node.isValid);
        }
    }, []);

    const gridRef = useRef<any>(null);

    const appSort = (a, b) => (a.id.toLowerCase() > b.id.toLowerCase() ? 1 : -1);

    useEffect(() => {
        const getJobInfo = async () => {
            try {
                const jobInfo = await guardianService.getJob(jobName.split(".")[1], jobName.split(".")[0]);
                setJob(jobInfo[0]);
            } catch (error) {
                growl.error("Error getting Report Info", error);
            }
        };

        if (editJob) {
            getJobInfo();
        }
    }, []);

    useEffect(() => {
        if (editJob) {
            if (tableData.length !== 0 && job.applications.length !== 0) {
                const initialSelectedRows = [];

                tableData.forEach(obj => {
                    job.applications.some(str => {
                        const [nsName, name] = str.split(".");

                        if (obj.name === name && obj.nsName === nsName) {
                            initialSelectedRows.push(toJS(obj));
                        }
                    });
                });

                setSelectedRows(initialSelectedRows);
            }
        } else if (!selectApps && apps?.length) {
            setSelectedRows(filterApps(apps));
        }
    }, [job, tableData, apps]);

    useEffect(() => {
        const fetchData = async () => {
            await store.fetchApps();
            await store.getAppListTableStats();
            setApps(store.apps.sort(appSort));
        };
        fetchData();
    }, [store]);

    useEffect(() => {
        setTableData(apps.sort(appSort));
    }, [apps]);

    const getHeaderComponent = () => {
        switch (currentStepName) {
            case Steps?.SelectApps?.name:
                return (
                    <TableFilters
                        sourceApps={apps}
                        setTableData={setTableData}
                        selectedRows={selectedRows}
                        onFilterChange={() => {
                            gridRef.current?.api?.deselectAll();
                            setSelectedRows([]);
                        }}
                        filters={filters}
                        setFilters={setFilters}
                    />
                );
            case Steps.ConfigureJob.name:
                return (
                    <Grid container sx={styles.configureHeading}>
                        <StriimTypography variant="h2">Configure your Sensitive Data Discovery Report</StriimTypography>
                    </Grid>
                );
            case Steps.ReviewDiscovery.name:
                return (
                    <StriimTypography variant="h2" color="primary.700">
                        Review
                    </StriimTypography>
                );
            default:
                return null;
        }
    };

    const getActiveStepComponent = () => {
        switch (currentStepName) {
            case Steps?.SelectApps?.name:
                return (
                    <GuardianSelectStep
                        apps={apps}
                        tableData={tableData}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                    />
                );
            case Steps.ConfigureJob.name:
                const name = !!jobName ? jobName.split(".")[1] : formValues?.name;
                const description = formValues?.description ?? job?.description;

                return (
                    <Grid container p={2}>
                        <Grid container sx={styles.configureContent}>
                            <NewDiscoveryJobForm
                                formRef={ref}
                                initialValues={{
                                    name,
                                    description,
                                    nsName: App.user.defaultNamespace
                                }}
                                editJob={editJob}
                            />
                        </Grid>
                    </Grid>
                );
            case Steps.ReviewDiscovery.name:
                return (
                    <GuardianReviewStep
                        apps={apps}
                        tableData={selectApps ? tableData.filter(app => selectedRows.includes(app)) : filterApps(apps)}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        goToEdit={goToEdit}
                        selectApps={selectApps}
                    />
                );
            default:
                return null;
        }
    };

    const updateJob = async () => {
        try {
            const applications = selectedRows.map(value => `${value.nsName}.${value.name}`);
            const description = formRef?.current?.values?.description ?? job.description;
            const updatedJob = await guardianService.updateJobDescription(
                jobName.split(".")[1],
                jobName.split(".")[0],
                description,
                {
                    name: jobName.split(".")[1],
                    nsName: jobName.split(".")[0],
                    description,
                    completedTime: job.completedTime,
                    status: job.status,
                    applications,
                    type: job.type
                }
            );

            setJob(updatedJob[0]);
        } catch (error) {
            growl.error("Error updating report", error);
        }
    };

    const createAndRunPIIJob = async apps => {
        const name = editJob ? jobName.split(".")[1] : formValues?.name;
        const description = formRef?.current?.values?.description;
        const appsList = selectApps
            ? selectedRows.map(value => `${value.nsName}.${value.name}`)
            : apps.map(value => `${value.nsName}.${value.name}`);
        const nsName = App.user.defaultNamespace;

        try {
            setHasRun(true);

            if (editJob) {
                await updateJob();
            } else {
                await guardianService.createPIIJob(name, nsName, description, appsList);
            }

            await guardianService.startPIIJob(name, nsName);
            navigateTo.GuardianViewJob(`${nsName}.${name}`);
        } catch (error) {
            growl.error(error);
        } finally {
            setHasRun(false);
        }
    };

    const goToEdit = () => {
        setCurrentStepName(StepNames[0]);
        setActiveStep(0);
    };

    const handleNavigation = goTo => {
        // goTo === TRUE => go next, goTo === FALSE => go back
        const currentStep = Object.values(Steps).find(step => step.name === currentStepName);
        if (goTo && currentStep.isLastStep) {
            createAndRunPIIJob(apps);
            return;
        }
        const nextStep = StepsValues.find(step => step.id === (goTo ? currentStep.id + 1 : currentStep.id - 1));

        nextStep && setCurrentStepName(nextStep.name);
        setActiveStep(step =>
            goTo
                ? // do not allow to make more steps than listed
                  step + 1 < StepNames.length
                    ? step + 1
                    : step
                : step - 1 >= 0
                ? step - 1
                : step
        );
        setFormValues(formRef?.current?.values);
    };

    const handleNextClick = () => handleNavigation(true);
    const handleBackClick = () => handleNavigation(false);

    const createPIIJob = async editJob => {
        const name = editJob ? jobName.split(".")[1] : formRef?.current?.values?.name;
        const description = formRef?.current?.values?.description ?? "";
        const apps = selectedRows.map(value => `${value.nsName}.${value.name}`);
        const nsName = App.user.defaultNamespace;

        try {
            editJob
                ? updateJob()
                : await guardianService.createPIIJob(name || "", nsName || "", description || "", apps || []);
            formRef.current.values = {};
            navigate(AIROUTES.sherlockAI);
        } catch (error) {
            growl.error(error);
        }
    };

    const showHeaderBorder = activeStep !== 0 || currentStepName === Steps.ConfigureJob.name;

    return (
        <Grid container flexDirection={"column"} sx={styles.guardianContainer}>
            {hasRun && <LoadingIndicator isGlobal={true} />}
            <MainHeader>
                <Stepper steps={StepNames} activeStep={activeStep} />
            </MainHeader>

            <GuardianFrame
                Header={getHeaderComponent()}
                Footer={
                    <GuardianFooter
                        handleNextClick={handleNextClick}
                        handleBackClick={handleBackClick}
                        handleSaveAndExit={createPIIJob}
                        stepId={activeStep}
                        StepNames={StepNames}
                        selectedRowsCount={selectedRows.length}
                        formValues={formValues}
                        hideBackButton={activeStep === 0 || activeStep === StepNames.length - 1}
                        runButtonClicked={hasRun}
                        editJob={editJob}
                        isValid={isValid}
                    />
                }
                showHeaderBorder={showHeaderBorder}
                showFooterBorder={true}
            >
                {getActiveStepComponent()}
            </GuardianFrame>
        </Grid>
    );
};

export default GuardianSelectApps;
