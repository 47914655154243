import api from "core/api/api";
import _ from "underscore";

var module = {};
module._templates = [];
module._isEnabled = false;
module.featureName = "Oracle Lineage";

module.init = function () {
    api.getOracleMetadataEnabledTempaltes().then(function(data) {
        module._templates = data;
    });
    api.isOracleMetadataEnabled().then(function(isEnabled) {
        module._isEnabled = isEnabled;
    });
};

module.checkIfFeatureEnabled = function () {
    return module._isEnabled;
};

module.checkIfEnabled = function (templateID) {
    if (!templateID || !_.isString(templateID)) {
        return false;
    }

    templateID = templateID.replace("Global.PROPERTYTEMPLATE.", "");
    return module._templates.indexOf(templateID) !== -1;
};

module.getMetadata = function (appID, type) {
    return api.getOracleMetadata(appID, type);
};

module.columns = [
    {
        label: "#",
        srcfield: "rowID",
    },
    {
        label: "File Name",
        srcfield: "fileName",
    },
    {
        label: "Status",
        srcfield: "status",
    },
    {
        label: "Directory Name",
        srcfield: "directoryName",
    },
    {
        label: "File Creation Time",
        srcfield: "externalFileCreationTime",
    },
    {
        label: "Number Of Events",
        srcfield: "numberOfEvents",
    },
    {
        label: "First Event Timestamp",
        srcfield: "firstEventTimestamp",
    },
    {
        label: "Last Event Timestamp",
        srcfield: "lastEventTimestamp",
    },
    {
        label: "Wrap Number",
        srcfield: "wrapNumber",
    },
    {
        label: "Sequence Number",
        srcfield: "sequenceNumber",
    },
    {
        label: "Thread Id",
        srcfield: "threadId",
    },
    {
        label: "First Change Number",
        srcfield: "firstChangeNumber",
    },
    {
        label: "Last Change Number",
        srcfield: "lastChangeNumber",
    },
];

export default module;
