import React, {useMemo} from "react";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { CurrentPlanSection, PlanIncludesList, AddOnFeatures } from "./components";
import { toTitleCase } from "app/components/common/editor/control-resource-resolver";
import securityService from "core/services/securityService/securityService";
import { addonFeaturesTitles } from "./components/add-on-features/add-on-features-utils";
import useStyles from "../striim-units-usage/striim-units-usage-tab.styles";
import {StriimTypography} from "./components/StriimTypographyCustom";

const UserPlanTab = ({ thisMonthSummary, userPlanState, onDetailsClick }) => {
    const classes = useStyles();

    const featureList = (userPlanState.userPlanContext.defaultList || []).filter(
        item => !!addonFeaturesTitles[item.addonKey]
    );

    const planType = useMemo(() =>
            toTitleCase(securityService.currentSegmentationLicense),
        [securityService.currentSegmentationLicense]
    );

    return (
        <>
            <CurrentPlanSection
                onDetailOpen={onDetailsClick}
                planType={planType}
                unitAmount={thisMonthSummary?.total || 0}
                unit={thisMonthSummary?.unit || ""}
            />
            {featureList.length > 0 && (
                <>
                    <hr className={classes.separator} />
                    <Grid container>
                        <Grid item>
                            <StriimTypography variant="h3">Your current features</StriimTypography>
                            <PlanIncludesList list={featureList} />
                        </Grid>
                    </Grid>
                </>
            )}
            <hr className={classes.separator} />
            <AddOnFeatures {...userPlanState} planType={planType} />
        </>
    );
};

UserPlanTab.propTypes = {
    onDetailsClick: PropTypes.func
};

export default UserPlanTab;
