import React from "react";
import { PieChart, Pie, Cell, Label, Tooltip } from "recharts";
import { StriimTypography } from "@striim/striim-ui-v2";
import { Box, Grid } from "@mui/material";
import { styles } from "./semi-piechart.styles";

interface SemiPieChartProps {
    data: Array<{ name: String; value: Number; color: String }>;
    label: String;
    tooltipTitle: String;
    handleClick?: (e: any) => void;
}

const SemiPieChart: React.FC<SemiPieChartProps> = ({ data, label, tooltipTitle, handleClick }) => {
    const CustomLabel = ({ label }) => {
        return (
            <g>
                <foreignObject x={0} y={35} width={"100%"} height={"24px"} style={{ pointerEvents: "none" }}>
                    <StriimTypography variant="caption3" textAlign={"center"} fontSize={"20px"} color={"greyscale.900"}>
                        {label}
                    </StriimTypography>
                </foreignObject>
            </g>
        );
    };

    const CustomTooltip = ({ payload }) => {
        if (payload[0]) {
            return (
                <Grid sx={styles.tooltipContainer} minWidth={"100px"} padding={1} container gap={1}>
                    <StriimTypography variant="caption3">{tooltipTitle}</StriimTypography>
                    <Grid container alignItems={"center"} gap={1}>
                        <Box sx={{ ...styles.tooltipColorBox, backgroundColor: payload[0].payload.fill }}></Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <StriimTypography variant="caption3" color={"greyscale.700"}>
                                {payload[0].name}
                            </StriimTypography>
                        </Box>
                        <StriimTypography variant="caption3">{payload[0].value}</StriimTypography>
                    </Grid>
                </Grid>
            );
        }
        return null;
    };
    return (
        <Grid sx={styles.chartStyles}>
            <PieChart width={110} height={60}>
                <Pie
                    data={data}
                    cx={50}
                    cy={50}
                    startAngle={180}
                    endAngle={0}
                    innerRadius={42}
                    outerRadius={50}
                    paddingAngle={2}
                    dataKey="value"
                    stroke="transparent"
                    isAnimationActive={false}
                    onClick={handleClick}
                >
                    <Label position="center" dy={-10} content={<CustomLabel label={label} />} />
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
                <Tooltip allowEscapeViewBox={{ x: false, y: true }} content={<CustomTooltip />} />
            </PieChart>
        </Grid>
    );
};

export default SemiPieChart;
