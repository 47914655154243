import React, { useState } from "react";
import { StriimUnitisTileDate } from "../striim-units-tile/striim-units-tile-date";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, ListItemText, ListItemSecondaryAction, Box } from "@mui/material";
import { StriimList, StriimListItem, StriimLink, StriimInputField, StriimTooltip } from "@striim/striim-ui";
import PercentageBarWithDescription from "./components/percentage-bar-with-description";
import { getValueColorStyles, formatItemizedItemName } from "./components/utils";
import { useTheme } from "@material-ui/core/styles";
import NoDataComponent from "./components/no-data-component";
import { StriimTypography } from "../../../user-plan/components/StriimTypographyCustom";
import { useNavigate } from "react-router-dom";
import { styles, useStyles } from "./striim-unit-usage-breakdown.styles.js";

const UsageBreakdown = ({
    breakdownOptions,
    billingId,
    setBillingId,
    selectedBillingOption,
    setSelectedBillingOption,
    breakdown,
    unit
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const isLastUsageSelected =
        breakdown &&
        breakdownOptions &&
        breakdownOptions.length > 0 &&
        breakdown.billingId === breakdownOptions[breakdownOptions.length - 1].value;

    const listData = !breakdown
        ? []
        : [
              {
                  primaryTypography: (
                      <StriimTypography color="greyscale.700" variant="body4">
                          Total usage
                      </StriimTypography>
                  ),
                  secondaryTypography: (
                      <StriimTypography variant="body3">
                          {breakdown.usageTotalFormatted}{" "}
                          <StriimTypography variant="body4">{breakdown.usageUnitFormatted}</StriimTypography>
                      </StriimTypography>
                  )
              },
              {
                  primaryTypography: (
                      <StriimTypography color="greyscale.700" variant="body4">
                          Credits
                      </StriimTypography>
                  ),
                  secondaryTypography: (
                      <StriimTypography variant="body4" style={getValueColorStyles(breakdown.usageCredit, theme)}>
                          <StriimTypography variant="body3" style={getValueColorStyles(breakdown.usageCredit, theme)}>
                              {breakdown.usageCreditFormatted}
                          </StriimTypography>{" "}
                          {breakdown.usageUnitFormatted}
                      </StriimTypography>
                  )
              },
              {
                  primaryTypography: <StriimTypography variant="body4">Net Usage</StriimTypography>,
                  secondaryTypography: (
                      <StriimTypography
                          sx={{ display: "flex", alignItems: "center" }}
                          variant="h1"
                          component="span"
                          data-test-id="account-and-billing__usageBreakdown__unitsToPay"
                      >
                          {breakdown.usageBalanceFormatted}
                          <StriimTypography ml={0.5} variant="body4" color="greyscale.900" component="span">
                              {breakdown.usageUnitFormatted}
                          </StriimTypography>
                      </StriimTypography>
                  )
              }
          ];

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
                <Grid item>
                    <StriimTypography variant="h3">{unit} Usage</StriimTypography>
                </Grid>
                <Grid item>
                    <StriimInputField
                        SelectProps={{ options: breakdownOptions, isClearable: false }}
                        value={selectedBillingOption}
                        onChange={option => {
                            setBillingId(option.value);
                            setSelectedBillingOption(option);
                        }}
                        select
                    />
                </Grid>
            </Grid>
            {breakdown ? (
                <Grid container sx={styles.breakdownContainer}>
                    <Grid item xs={6} className={classes.factorsListContainer}>
                        <StriimList>
                            <StriimListItem key="components" pt={1} sx={{ ...styles.listItem, ...styles.textCursor }}>
                                <ListItemText
                                    id={-1}
                                    primary={
                                        <StriimTypography color="greyscale.700" variant="body4">
                                            Components
                                        </StriimTypography>
                                    }
                                />
                                <ListItemSecondaryAction className={classes.factorDescription}>
                                    <StriimUnitisTileDate
                                        startTime={breakdown.startTime}
                                        endTime={breakdown.endTime}
                                        todayLabel={isLastUsageSelected}
                                        noMargin
                                    />
                                </ListItemSecondaryAction>
                            </StriimListItem>
                            {breakdown.usageItemized.map(x => (
                                <StriimListItem
                                    py={2}
                                    key={x.itemId}
                                    sx={styles.listItem}
                                    data-test-id={`account-and-billing__usageBreakdown__${x.itemName
                                        .replace(" ", "")
                                        .toLowerCase()}`}
                                >
                                    <StriimTooltip
                                        title={
                                            formatItemizedItemName(x.itemName).length > 18
                                                ? formatItemizedItemName(x.itemName)
                                                : ""
                                        }
                                    >
                                        <StriimLink
                                            onClick={() => {
                                                navigate(
                                                    `/account-and-billing?itemId=${x.itemId}&billingId=${billingId}&tab=1`
                                                );
                                            }}
                                            sx={{ p: 0 }}
                                        >
                                            <StriimTypography
                                                color="secondary.500"
                                                variant="body4"
                                                sx={styles.truncatedText}
                                            >
                                                {formatItemizedItemName(x.itemName)}
                                            </StriimTypography>
                                        </StriimLink>
                                    </StriimTooltip>

                                    <ListItemSecondaryAction className={classes.factorDescription}>
                                        <PercentageBarWithDescription
                                            value={x.usageTotal}
                                            total={breakdown.usageTotal}
                                            units={breakdown.usageUnitFormatted}
                                            className={classes.descriptionContent}
                                        />
                                    </ListItemSecondaryAction>
                                </StriimListItem>
                            ))}
                        </StriimList>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ borderTop: ({ palette }) => `1px solid ${palette.greyscale[200]}` }} />
                    </Grid>
                    <Grid item xs={6}>
                        <StriimList>
                            {listData.map((item, idx) => (
                                <StriimListItem key={idx} sx={styles.summaryListItem}>
                                    <ListItemText primary={item.primaryTypography} sx={styles.textCursor} />
                                    <ListItemSecondaryAction className={classes.factorDescription}>
                                        {item.secondaryTypography}
                                    </ListItemSecondaryAction>
                                </StriimListItem>
                            ))}
                        </StriimList>
                    </Grid>
                </Grid>
            ) : (
                <div className={classes.noDataContainer}>
                    <NoDataComponent
                        text={`We couldn't find any data matching selected cycle.
                        Please select different cycle.`}
                        image
                    />
                </div>
            )}
        </>
    );
};

export default UsageBreakdown;
