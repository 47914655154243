export default (function() {
    var moduleToAclBinding = {
        admin: "admin",
        apps: "apps",
        dashboard: "dashboard",
        dashboards: "dashboard",
        monitor: "monitor",
        preview: "sourcepreview"
    };

    var editFragment = "edit";
    var uiFragment = "ui";
    var separator = "_";

    function PermissionsManager() {
        this.getPermissionName = function(moduleId, urlParams) {
            if (moduleId == "users" || moduleId == "roles") {
                moduleId = "admin";
            }
            if (!moduleId) {
                return undefined;
            }

            var moduleAlias = moduleToAclBinding[moduleId];
            if (!moduleAlias) {
                return undefined;
            }
            var fragment_array = [moduleAlias, uiFragment];
            if (urlParams && urlParams.indexOf(editFragment) !== -1) {
                fragment_array = [moduleAlias, uiFragment, editFragment];
            }

            var permission_string = fragment_array.join(separator);
            return "*:*:" + permission_string + ":*";
        };
    }
    return PermissionsManager;
})();
