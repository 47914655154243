import App from "app";
import ApplicationController from "lib/controllers/application_controller";
import saveModal from "./saveModal";
import file_config from "./file_info.html";
import type_config from "./source_type.html";
import parser_config from "./parser_config.html";
import table_template from "./table_template.html";
import row_template from "./row_template.html";
import date_format_modal from "./date_format_modal.html";
import Spinner from "spinner";
import ModalManager from "app/components/common/modal/ModalManager";
import tqlValidator from "app/components/common/tqlValidator";
import dialog from "app/components/common/dialogs/dialogWindow";
import template from "./templates/previewPage.html";
import "./file_save_template.html";
import "./file_save_modal.html";
import "lib/jquery.select2-combobox";
import "app/components/common/_deprecated/form-mixin-dialog/dialog";
import "core/services/dataService/filepreviewservice";
import resources from "app/components/common/editor/resources_en.json";

var modalManager = new ModalManager({
    container: "body"
});

var previewPage = {};
previewPage.Controller = ApplicationController.extend({
    initialize: function(options) {
        var self = this;
        this.model = options.model;
        /* This is the mainview for all over Source Preview */
        this.mainView = options.mainView;
        var viewOptions;
        self.spinner = new previewPage.spinnerView();
        self.mainView.mainRegion.show(self.spinner);

        this.listenTo(App.vent, "savePreviewData", this.savePreviewRequest, this);
        this.listenTo(App.vent, "changeData", this.updateModel, this);
        this.listenTo(App.vent, "toggleDSVHeader", this.toggleHeader, this);
        this.listenTo(App.vent, "changeDocType", this.updateDocType, this);
        this.listenTo(App.vent, "setColumn", this.updateColumns, this);

        var deferredInitial = App.request("preview:initial", options.model);

        deferredInitial.then(function(data) {
            self.options.previewModel = data;
            self.model = data;
            viewOptions = {
                model: self.model
            };

            var deferredPostAnalysis = App.request("preview:update", self.model);
            deferredPostAnalysis.then(function() {
                self.spinner.destroy();
                self.view = new previewPage.mainView(viewOptions);
                self.setMainView(self.view);
                self.mainView.mainRegion.show(self.view);
            });
        });
    },
    onBeforeDestroy: function() {
        var $select2 = $(".select2-drop");
        if ($select2) {
            $select2.remove();
        }
        this.view.destroy();
    },
    toggleHeader: function(on) {
        this.model.set("header", on, {
            silent: true
        });
        var parserProps = this.model.get("parserProperties");
        if (parserProps) {
            _.extend(parserProps, {
                header: on
            });
        } else {
            this.model.set("parserProperties", {
                header: on
            });
        }
        var deferred = App.request("preview:update", this.model);
        deferred.then(function() {
            App.vent.trigger("updatedFileModel");
        });
    },
    updateDocType: function(data) {
        if (data !== "AAL") {
            this.model.set("doctype", data, {
                silent: true
            });
            this.model.set("parserProperties", null, {
                silent: true
            });
        } else {
            this.model.set("doctype", "DSV", {
                silent: true
            });
            this.model.set("parserProperties", {
                columndelimiter: "", // Should be empty to show input placeholder
                quoteset: "[]:\""
            }, {
                silent: true
            });
        }

        var deferred = App.request("preview:update", this.model);
        deferred.then(function() {
            App.vent.trigger("updatedFileModel");
        });
    },
    updateModel: function(data, field) {
        if (data === null || data === undefined || !field) {
            App.vent.trigger("updatedFileModel");
            return;
        }

        if (data === field) {
            return;
        }
        data = this.model.removeSlashes(data);
        var parserObj = this.model.get("parserProperties");
        if (parserObj) {
            parserObj[field] = data;
        } else {
            parserObj = {};
            parserObj[field] = data;
        }

        this.model.set("parserProperties", parserObj, {
            silent: true
        });
        var deferred = App.request("preview:update", this.model);
        deferred.then(function() {
            App.vent.trigger("updatedFileModel");
        });
    },

    savePreviewRequest: function(model) {
        var nsName = $("#namespaceSelect").val();
        if (!nsName) {
            dialog.alert("Please select or create a namespace", modalManager);
            App.vent.trigger("preview:enableSaveClick");
            return;
        }
        var appName = $("#appname").val();
        if (appName) {
            model.set("appname", appName, {
                silent: true
            });
        } else {
            dialog.alert("Please select or create an application", modalManager);
            App.vent.trigger("preview:enableSaveClick");
            return;
        }
        var cache = $("#cacheCheckbox").is(":checked");
        var source = $("#sourceCheckbox").is(":checked");
        var objName = $("#objname").val();
        if (!objName) {
            if (cache) {
                dialog.alert("Please enter the name for the cache", modalManager);
            } else {
                dialog.alert("Please enter the name for the source", modalManager);
            }
            App.vent.trigger("preview:enableSaveClick");
            return;
        }
        if (cache) {
            var cacheKeyProp = $("#cacheKeyProp").val();
            if (!cacheKeyProp) {
                dialog.alert("Please select the cache key", modalManager);
                App.vent.trigger("preview:enableSaveClick");
                return;
            }
            model.set("cachename", objName, {
                silent: true
            });
            if ($("#loadDataCheckbox").is(":checked")) {
                model.set("loaddata", true, {
                    silent: true
                });
            } else {
                model.set("loaddata", false, {
                    silent: true
                });
            }
        }
        var wildcard = $("#wildcard").select2("data");
        if (wildcard) {
            if (wildcard.id !== "none") {
                model.set("wildcard", wildcard.id, {
                    silent: true
                });
            }
        }
        if (source) {
            model.set("sourcename", objName, {
                silent: true
            });
        }

        App.request("preview:save", model);
    },
    updateColumns: function(data) {
        var columns = this.model.get("columns");

        var idx = parseInt(data.index);
        var prop = data.prop;
        var val = data.val;
        var curCol = columns[idx];
        curCol[prop] = val;
        console.log("Updating columns in preview");
        console.log(columns);
        if (prop === "type" && val !== "DateTime") {
            if (curCol.format) {
                delete curCol.format;
            }
        }
        App.vent.trigger("updatedFileModel");
    }
});

previewPage.spinnerView = Backbone.Marionette.ItemView.extend({
    id: "spinnerView",
    template: _.template("<div id=\"spinContainer\"></div>"),
    onRender: function() {
        var target = this.$el;
        var spinner = new Spinner().spin();
        $(target).append(spinner.el);
    }
});

previewPage.mainView = Backbone.Marionette.LayoutView.extend({
    id: "preview-page",
    template: _.template(
        "<div id=\"source-config-region\" class=\"clear-fix\"></div>" +
        "<div id=\"data-table-region\" class=\"card data-table clear-fix\"></div>"
    ),
    regions: {
        sourceConfigRegion: "#source-config-region",
        dataTableRegion: "#data-table-region"
    },
    initialize: function(options) {
        this.model = options.model;
        this.configView = new previewPage.sourceConfigView({
            model: this.model
        });
        this.tableView = new previewPage.dataTableComposite({
            model: this.model
        });
    },
    onShow: function() {
        $("#preview-page").addClass("select2_theme_dark");
        this.sourceConfigRegion.show(this.configView);
        this.dataTableRegion.show(this.tableView);
    }
});

previewPage.sourceInfoView = Backbone.Marionette.ItemView.extend({
    id: "source-info-view",
    template: _.template(file_config),
    initialize: function(options) {
        this.model = options.model;
    }
});

previewPage.rowModel = Backbone.Model.extend({});

previewPage.rowCollection = Backbone.Collection.extend({
    model: previewPage.rowModel
});
previewPage.dataRowView = Backbone.Marionette.ItemView.extend({
    template: _.template(row_template),
    tagName: "tr"
});

previewPage.dateFormatController = App.FormMixinDialog.Controller.extend({
    initialize: function(options) {
        App.FormMixinDialog.Controller.prototype.initialize.apply(this, arguments);
        this.view = options.view;
        this.model = options.model;
    }
});

previewPage.dateFormat = App.FormMixinDialog.View.extend({
    id: "dateFormatModal",
    regions: {
        mainregion: "#date-format-region"
    },
    template: _.template(date_format_modal),
    initialize: function(options) {
        this.model = options.model;
        this.index = options.index;
        this.app = options.app;
        this.model.set("firstRowAsHeader", !!this.model.get("parserProperties")?.header);
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
        this.triggers["click .submit"] = "onSubmit";
        this.triggers["click .cancel"] = "onCancel";

        this.delegateEvents();
        this.listenTo(this, "onSubmit", this.onSubmit);
        this.listenTo(this, "onCancel", this.onCancel);
    },
    onCancel: function() {
        this.trigger("before:dialog:cancel"); // Temp, until we transition to new Dialog module
        this.trigger("cancel");
        var curIdx = this.model.get("curDateFormattingIndex");
        var obj = {
            prop: "type",
            index: curIdx,
            val: "String"
        };
        this.app.vent.trigger("setColumn", obj);
    },
    onSubmit: function() {
        var columnInput = this.modalModel.attributes.columns;
        if (columnInput) {
            this.model.set("columns", columnInput);
            this.destroy();
        } else if (this.$("#unix_ts_toggle")[0].checked) {
            this.destroy();
        } else {
            dialog.alert("Please add a format or cancel", modalManager);
        }
    },
    onRender: function() {
        var self = this;
        var app = App;
        this.$("#sourcepreview_help_toggle").bind(
            "click",
            {
                data: self
            },
            function(event) {
                var _this = event.data.data;
                _this.$("#formatting_help").toggle();
            }
        );
        this.$("#unix_ts_toggle").bind(
            "change",
            {
                data: self,
                app: app
            },
            function(event) {
                event.preventDefault();
                var _this = event.data.data;
                if (this.checked) {
                    var model = event.data.data.model;
                    var curIndex = model.get("curDateFormattingIndex");
                    var columns = model.get("columns");
                    if (columns[curIndex].format) {
                        delete columns[curIndex].format;
                    }
                    _this.$("#format_input_container").toggle();
                    _this.$("#formatting_help").toggle(false);
                } else {
                    _this.$("#format_input_container").toggle();
                }
            }
        );
        this.$("#dateFormatForm").bind(
            "submit",
            {
                data: self,
                app: app
            },
            function(event) {
                event.preventDefault();
                var val = this.DateFormatInput.value;
                var model = event.data.data.model;
                var curIndex = model.get("curDateFormattingIndex");
                var columns = model.get("columns");
                columns[curIndex].format = val;
                var modalModel = event.data.data.modalModel;
                modalModel.set("columns", columns);
                var obj = {
                    prop: "format",
                    index: curIndex,
                    val: val
                };
                event.data.app.vent.trigger("setColumn", obj);
                return false;
            }
        );

        this.$("#DateFormatInput").bind(
            "blur",
            {
                data: self,
                app: app
            },
            function(event) {
                event.preventDefault();
                var val = this.value;
                var model = event.data.data.model;
                var curIndex = model.get("curDateFormattingIndex");
                var columns = model.get("columns");
                columns[curIndex].format = val;
                var modalModel = event.data.data.modalModel;
                modalModel.set("columns", columns);
                var obj = {
                    prop: "format",
                    index: curIndex,
                    val: val
                };
                event.data.app.vent.trigger("setColumn", obj);
                return false;
            }
        );
    }
});
previewPage.dataTableComposite = Backbone.Marionette.CompositeView.extend({
    id: "data-table-view",
    className: "row",
    childViewContainer: "tbody",
    childView: previewPage.dataRowView,
    template: _.template(table_template),
    modelEvents: {
        change: "modelChanged"
    },
    initialize: function() {
        this.array = this.model.get("events");
        this.collection = new previewPage.rowCollection();
        for (var arr in this.array) {
            var mod = new previewPage.rowModel(this.array[arr]);
            this.collection.add(mod);
        }
        this.listenTo(App.vent, "updatedFileModel", this.render, this);
    },

    onDomRefresh: function() {
        const screenHeightWithoutTable =
            $("#react-content header").outerHeight() +
            ($("#preview-page").outerHeight() - $("#data-table-region").outerHeight());

        $("#preview-page .data-table").css({
            "max-height": `calc(100vh - ${screenHeightWithoutTable}px)`
        });
    },
    onRender: function() {
        var nameSelects = this.$(".col-name-select");
        var typeSelects = this.$(".col-type-select");
        var checkboxes = this.$(".discardCheckbox");

        this.resolveNameSelects(nameSelects);
        this.resolveTypeSelects(typeSelects);
        this.resolveCheckboxes(checkboxes);
        this.array = this.model.get("events");
        this.collection = new previewPage.rowCollection();
        for (var arr in this.array) {
            var mod = new previewPage.rowModel(this.array[arr]);
            this.collection.add(mod);
        }

        this.$(".discardCheckbox").change(function() {
            var index = $(this).attr("data");
            var value = this.checked ? "false" : "true";
            var obj = {
                prop: "discard",
                index: index,
                val: value
            };
            App.vent.trigger("setColumn", obj);
        });
    },
    getTypeId: function(type) {
        switch (type) {
            case "String":
                return "string";
            case "Long":
                return "long";
            case "Short":
                return "short";
            case "DateTime":
                return "timestamp";
            case "Double":
                return "double";
            case "Integer":
                return "int";
            case "Float":
                return "float";
        }
    },
    resolveCheckboxes: function(checkboxes) {
        var self = this;
        _.each(checkboxes, function(checkbox, index) {
            var curCol = self.model.get("columns")[index];
            var discard = curCol["discard"];
            var value = discard === "false";

            $(checkbox).prop("checked", value);
        });
    },
    resolveTypeSelects: function(typeSelects) {
        _.each(
            typeSelects,
            function(select, index) {
                var self = this;
                var array = [
                    {
                        id: "string",
                        text: "String"
                    },
                    {
                        id: "long",
                        text: "Long"
                    },
                    {
                        id: "short",
                        text: "Short"
                    },
                    {
                        id: "timestamp",
                        text: "DateTime"
                    },
                    {
                        id: "double",
                        text: "Double"
                    },
                    {
                        id: "int",
                        text: "Integer"
                    },
                    {
                        id: "float",
                        text: "Float"
                    }
                ];
                var columns = this.model.get("columns");
                var curColumn = columns[index];
                var typeId = self.getTypeId(curColumn.type);
                this.$(select)
                    .select2({
                        minimumResultsForSearch: -1,
                        data: array,
                        width: "95px"
                    })
                    .val(typeId)
                    .trigger("change");

                this.$(select).on("change", function() {
                    var index = parseInt($.attr(this, "data"));
                    var value = $(this).select2("data").text;
                    if (value === "DateTime") {
                        self.formatDate(index);
                    }

                    console.log("Setting type of col" + index + ": " + value);
                    var obj = {
                        prop: "type",
                        index: index,
                        val: value
                    };
                    App.vent.trigger("setColumn", obj);
                });
            },
            this
        );
    },
    formatDate: function(index) {
        this.model.set("curDateFormattingIndex", index, {
            silent: true
        });
        var options = {
            model: this.model,
            index: index,
            app: App,
            id: "formatDateModal",
            container: "body",
            origin: "data-table-view"
        };
        var formatModal = new previewPage.dateFormat(options);
        var formatModalView = modalManager.add(formatModal);
        new previewPage.dateFormatController({
            view: formatModal,
            model: this.model
        });
        this.listenTo(formatModalView, "form-submit-success", function(formatModalView) {
            formatModalView.disable();
            formatModalView.destroy();
        });
    },
    _isValid: function($target) {
        function showValidationError(error) {
            $target.tooltipster({
                content: error
            });
            $target.tooltipster("show");
            $target.addClass("error");
            return false;
        }

        function hideValidationError() {
            if ($target.hasClass("tooltipstered")) {
                $target.tooltipster("hide");
            }
            $target.removeClass("error");
            return true;
        }

        hideValidationError();
        if (!isNaN(parseInt($target.val()))) {
            return showValidationError("Column name cannot be a number or start with non-alphabetic characters");
        } else if (/^[a-zA-Z0-9 ]*$/.test($target.val()) === false) {
            return showValidationError("Column name cannot contain non-alphabetic characters");
        } else if (tqlValidator.isReservedKeyword($target.val())) {
            return showValidationError($target.val() + " is a reserved keyword.");
        } else {
            return hideValidationError();
        }
    },
    resolveNameSelects: function(nameSelects) {
        var _this = this;
        _.each(
            nameSelects,
            function(select) {
                this.$(select).on("change", function() {
                    var index = $.attr(this, "idx");
                    var $target = $(this);
                    var value = $target.val();
                    console.log("Setting name of col" + index + ": " + value);
                    var isValid = _this._isValid($target);
                    if (isValid) {
                        var obj = {
                            prop: "name",
                            index: index,
                            val: value
                        };
                        App.vent.trigger("setColumn", obj);
                    }

                    App.vent.trigger("preview:isValid", isValid);
                });
            },
            this
        );
    },

    modelChanged: function() {
        this.array = this.model.get("Events");
        var modArray = [];
        for (var arr in this.array) {
            var mod = new previewPage.rowModel(this.array[arr]);
            modArray.push(mod);
        }
        this.collection.reset(modArray);
        this.render();
    }
});

previewPage.sourceConfigView = Backbone.Marionette.LayoutView.extend({
    id: "source-config-view",
    className: "row height-max",
    template: _.template(template),
    regions: {
        sourceInfo: "#source-info",
        sourceType: "#source-type",
        sourceProperties: "#source-properties"
    },
    events: {
        "click #save-button": "savePreview"
    },
    savePreview: function(msg) {
        if (!this.model.isValid) {
            return;
        }

        var $origin = msg.currentTarget;
        var id = "save-dialog";
        if (!this.model.get("parserProperties")) {
            dialog.alert("Please configure parser properties before attempting to save preview data", modalManager);
            return;
        }

        var saveView = new saveModal.View({
            id: id,
            container: "body",
            model: this.model.clone(),
            origin: $origin
        });
        new saveModal.Controller({
            view: saveView,
            model: this.model
        });
        modalManager.add(saveView);

        this.listenTo(saveView, "form-submit-success", function(saveView) {
            saveView.disable();
            App.vent.trigger("savePreviewData", this.model);
        });
    },

    initialize: function(options) {
        this.model = options.model;
        this.model.isValid = true;
        this.infoView = new previewPage.sourceInfoView({
            model: this.model
        });
        this.typeView = new previewPage.sourceTypeView({
            model: this.model
        });
        this.propView = new previewPage.sourcePropertiesView({
            model: this.model
        });

        this.listenTo(App.vent, "preview:isValid", function(isValid) {
            this.$("#save-button").toggleClass("disabled", !isValid);
            this.model.isValid = isValid;
        });
    },

    onRender: function() {
        this.sourceInfo.show(this.infoView);
        this.sourceType.show(this.typeView);
        this.sourceProperties.show(this.propView);

        this.$(".goBack").on("click", function() {
            window.history.back(-1);
        });
    }
});

previewPage.sourceTypeView = Backbone.Marionette.ItemView.extend({
    id: "source-type-view",
    events: {
        change: "changeType"
    },
    template: _.template(type_config),
    initialize: function() {
        this.currentType = "dsv";
        this.jsonProps = ["eventType"];
        this.aalProps = ["columndelimiter", "quoteset"];
        this.fftProps = ["recordbegin", "regex", "timestamp", "separator"];
    },
    changeType: function(event) {
        var val = event.val;
        var model = this.model;
        if (val !== this.currentType) {
            if (val === "DSV") {
                var propertyTemplate = ["columndelimiter", "rowdelimiter", "quoteset"];
                model.set("propertyTemplate", propertyTemplate, {
                    silent: true
                });
                model.set("parserType", "DSV", {
                    silent: true
                });
            } else if (val === "JSON") {
                model.set("propertyTemplate", this.jsonProps, {
                    silent: true
                });
                model.set("parserType", "JSON", {
                    silent: true
                });
            } else if (val === "AAL") {
                model.set("propertyTemplate", this.aalProps, {
                    silent: true
                });
                model.set("parserType", "AAL", {
                    silent: true
                });
            } else if (val === "FreeFormText") {
                model.set("parserType", "FFT", {
                    silent: true
                });
                model.set("propertyTemplate", this.fftProps);
            }
        }
        App.vent.trigger("changeDocType", val);
    },
    onRender: function() {
        var arrayParser = [
            {
                id: "DSV",
                text: "DSV Parser"
            },
            {
                id: "AAL",
                text: "Apache Access Log Parser"
            },
            {
                id: "FreeFormText",
                text: "Free Form Text Parser"
            }
        ];
        this.$("#parser")
            .select2({
                minimumResultsForSearch: -1,
                data: arrayParser
                // dropdownCssClass: "select2_theme_green",
            })
            .val(this.model.attributes.docType)
            .trigger("change");
    }
});

previewPage.sourcePropertiesView = Backbone.Marionette.ItemView.extend({
    id: "source-prop-view",
    template: _.template(parser_config),
    modelEvents: {
        change: "render"
    },
    initialize: function(options) {
        this.model = options.model;
    },
    onBeforeRender: function() {
        $(".select2-with-searchbox.select2-drop-active").remove();
    },
    onRender: function() {
        if (this.$(".select2-drop-active").size() > 0) {
            return;
        }

        $(document).on("keydown", function(e) {
            if (e.which === 8 && !$(e.target).is("input, textarea")) {
                e.preventDefault();
            }
        });
        var self = this;
        var analysis = this.model.get("recommendation");
        var propertyTemplate = this.model.get("propertyTemplate");
        var currentProps = this.model.get("parserProperties");

        this.$("#headerCheckbox").on("change", function(e) {
            let confirmVal = true;
            if (self.model.get("columns").length > 1) {
                confirmVal = confirm("This change may erase your column settings");
            }

            if (confirmVal) {
                self.model.set("header", this.checked);
                App.vent.trigger("toggleDSVHeader", this.checked);
            } else {
                self.render();
            }
        });

        for (var thing in propertyTemplate) {
            var prop = propertyTemplate[thing];
            var arrayOfChoices = [];
            var dupeChecker = {};
            for (var a in analysis) {
                var curAnalysis = analysis[a][prop];
                if (curAnalysis) {
                    var obj = {};
                    if (typeof curAnalysis === "number") {
                        curAnalysis = curAnalysis.toString();
                    }

                    obj.text = curAnalysis;
                    if (dupeChecker[curAnalysis]) {
                        continue;
                    }
                    dupeChecker[curAnalysis] = true;
                    obj.id = prop;
                    arrayOfChoices.push(obj);
                }
            }
            var selector = "#" + prop;
            var str = "Choose suggestion or enter your own";
            var config = {
                choices: arrayOfChoices,
                search_placeholder: str,
                width: "100%"
            };
            if (currentProps) {
                var val = currentProps[prop];
                val = this.model.addSlashes(val);

                config.default_value = val;
            }
            this.$(selector).select2_combobox(config);

            this.$(selector).on("change", function(mod) {
                mod.preventDefault();
                var data = $(this).select2("data").text;
                if (data === "/\n") {
                    data = "\n";
                }

                var field = $(this).attr("id");

                function eraseColumnsAction(shouldErase) {
                    if (shouldErase) {
                        App.vent.trigger("changeData", data, field);
                    } else {
                        self.render();
                    }
                }

                if (self.model.get("columns").length > 1) {
                    dialog.confirm("This change may erase your column settings").then(function(result) {
                        eraseColumnsAction(result);
                    });
                } else {
                    eraseColumnsAction(true);
                }
            });
        }
    },
    serializeData() {
        let data = this.model.toJSON();
        let propertyTemplates = this.model.get("propertyTemplate");
        data["propertyTemplateObjects"] = [];
        if (propertyTemplates.length > 0) {
            data["propertyTemplateObjects"] = propertyTemplates.map(key => ({
                key,
                value: resources[key] ? resources[key].name : ""
            }));
        }
        return data;
    }
});

export default previewPage;
