import React from "react";
import { Box } from "@mui/material";
import { useReactHeader } from "../../../../hooks/useReactHeader";
import CreateAppUsingTemplates from "./create-app-page-templates";

const styles = {
    pageWrapper: {
        margin: theme => theme.spacing(10.12, 4, 4, 4)
    }
};

const CreateAppPageUsingTemplates: React.FunctionComponent = () => {
    useReactHeader({ title: "Apps" });
    return (
        <Box sx={styles.pageWrapper}>
            <CreateAppUsingTemplates />
        </Box>
    );
};

export default CreateAppPageUsingTemplates;
