import React, { useContext } from "react";

import api from "core/api/api";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import appStatusSynchronizer from "core/appStatusSynchronizer";
import appGroupService from "app/components/common/app-control/app-groups-service";

import AppGroupsStore from "./app-groups-store";
import AppListStore from "./app-list-store";
import DashboardsStore from "./dashboards";
import NamespaceStore from "./namespaces";
import PagesStore from "./pages";
import CreateAppsStore from "./create-apps/create-apps-store";
import NavbarStore from "./nav-bar";
import BackboneStore from "./backbone";

import { createStore } from "./fake-app-list-store";
import { createProgressStore } from "./table-progress/fake-progress-store";
import { createGroupStore } from "./fake-groups-store";
import { createDashboardsStore } from "./dashboards/fake-dashboards-store";
import tableProgressStore from "../modules/table-progress/table-progress.store";

const store = createStore(50);
const groupsStore = createGroupStore(store);
const backboneStore = new BackboneStore();
const progressStore = createProgressStore();

// eslint-disable-next-line no-undef
export const contextStore = process.env.STORYBOOK_STORE
    ? {
          store,
          groupsStore,
          dashboardsStore: createDashboardsStore(),
          backboneStore,
          navBarStore: new NavbarStore(),
          tableProgressStore: progressStore
      }
    : {
          store: new AppListStore(api, metaStoreService, appStatusSynchronizer),
          groupsStore: new AppGroupsStore(appGroupService),
          namespacesStore: new NamespaceStore(api),
          dashboardsStore: new DashboardsStore(),
          pagesStore: new PagesStore(),
          createAppsStore: new CreateAppsStore(backboneStore),
          navBarStore: new NavbarStore(),
          backboneStore,
          tableProgressStore
      };
export const storesContext = React.createContext(contextStore);