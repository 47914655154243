import React from "react";
import { Grid } from "@mui/material";
import { StriimButton, StriimTypography } from "@striim/striim-ui";

import SuccessIcon from "src/generic/icon/manage-striim/success.svg";
import ErrorIcon from "src/generic/icon/manage-striim/error.svg";
import WarningIcon from "src/generic/icon/manage-striim/warning.svg";
import { styles } from "./cp-action-button.styles";

export interface IActionBtn {
    component: React.ReactNode;
    endpoint: string;
    path: string;
    name: string;
    parameters: object;
    parameters_type: string;
    viewWeight: number;
    visibility: boolean | string;
}

interface ActionButtonProps {
    button: IActionBtn;
    OauthbuttonRef: React.MutableRefObject<any>;
    signedInTextRef: React.MutableRefObject<any>;
    signInFailedTextRef: React.MutableRefObject<any>;
    notSignedInTextRef: React.MutableRefObject<any>;
    handleSignin: () => Promise<any>;
    buttonText: String;
}

const CPActionButton = ({
    button,
    OauthbuttonRef,
    signedInTextRef,
    signInFailedTextRef,
    notSignedInTextRef,
    handleSignin,
    buttonText
}: ActionButtonProps) => {
    const getActionButton = () => {
        switch (button?.name) {
            case "SIGNIN_WITH_OAUTH":
                return (
                    <Grid display="flex" justifyContent="flex-start" gap={1.5}>
                        <Grid container item width={"fit-content"} minWidth={"fit-content"}>
                            <StriimButton
                                ref={OauthbuttonRef}
                                variant="secondary"
                                onClick={() => {
                                    handleSignin();
                                }}
                                data-test-id="sign-in-using-oauth"
                            >
                                Sign In using {buttonText}
                            </StriimButton>
                        </Grid>
                        <Grid container item>
                            <Grid
                                item
                                display="flex"
                                alignItems="center"
                                gap={0.5}
                                ref={signedInTextRef}
                                sx={styles.signInStates}
                            >
                                <SuccessIcon style={{ fontSize: 20 }} />
                                <StriimTypography variant="body4" sx={styles.signInStates}>
                                    Signed In!
                                </StriimTypography>
                            </Grid>
                            <Grid
                                item
                                display="flex"
                                alignItems="center"
                                gap={0.5}
                                ref={signInFailedTextRef}
                                sx={styles.signInStates}
                            >
                                <ErrorIcon style={{ fontSize: 20 }} />
                                <StriimTypography variant="body4" sx={styles.signInStates}>
                                    Sign-In Failed
                                </StriimTypography>
                            </Grid>
                            <Grid
                                item
                                display="flex"
                                alignItems="center"
                                gap={0.5}
                                ref={notSignedInTextRef}
                                minWidth={"max-content"}
                            >
                                <WarningIcon style={{ fontSize: 20 }} />
                                <StriimTypography variant="body4" sx={styles.signInStates}>
                                    Sign-In to Authenticate
                                </StriimTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            case "TEST_CONNECTION":
                return <></>;
            default:
                return null;
        }
    };

    return <>{getActionButton()}</>;
};
export default CPActionButton;
