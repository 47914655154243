import { template, className, on } from "core/decorators";
import { ItemView } from "marionette";
import App from "app";
import SetupValidationTemplate from "./templates/setup-validation-template.template.html";
import { DB_PROVIDERS } from "./configs/db-providers";
import helpLinkProvider from "app/components/common/helpable/helpLinkProvider";

@className("connection-test-view card")
@template(SetupValidationTemplate)
export default class SetupValidationView extends ItemView {
    initialize() {
        let model = App.reqres.request("get:wizard:model");
        App.vent.trigger("appwizard:next:disable");
        App.vent.trigger("appwizard:back:enable");
        this.model = model;
        this.collection = new Backbone.Collection();
    }

    serializeData() {
        let data = this.model.toJSON();
        const type = this.model.get("type");
        data["type"] = type;
        data["DB_PROVIDERS"] = DB_PROVIDERS;
        data["helpLink"] = "";
        if (type === DB_PROVIDERS.OJET.id) {
            data["helpLink"] = helpLinkProvider.getHelpLinkByKey(`${App.ENTITIES.WIZARD}_OJet`);
        } else if (type === DB_PROVIDERS.MSJET.id) {
            data["helpLink"] = helpLinkProvider.getHelpLinkByKey(`${App.ENTITIES.WIZARD}_MSJet`);
        }
        return data;
    }

    @on("change input")
    updateNextBtnState() {
        const allCheckboxesSelected = $("input[type='checkbox']:checked").length === $("input[type='checkbox']").length;
        if (this.model.get("schemas").length > 0 && allCheckboxesSelected) {
            App.vent.trigger("appwizard:next:enable");
        } else {
            App.vent.trigger("appwizard:next:disable");
        }
    }

    canMoveForward() {
        return new Promise(function(resolve, reject) {
            resolve();
        });
    }

    retry() {
        this.render();
    }
}
