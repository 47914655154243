import api from "../../../../../../core/api/api";
import { getFakeSentinelMonitorService } from "./fake-sentinel-monitor-service";
import {
    AllSentinelsEventsProcessed,
    FlowDesignerPreview,
    RunningAppsProps,
    RunningAppsWithSentinelsProps,
    SentinelDetailProps,
    SDIDrillDownOccurrenceProps,
    SensitiveDataOccurrences,
    AllSentinelsSDIDrillDownEvents,
    SentinelsSDIDrillDownEvents,
    AppSDIDrillDownFindings,
    SDIWithApplicationDetails,
    EventsProcessedChartData,
    TimeFormat,
    SensitiveDataOccurrencesChartData,
    AllSentinelsSDIDrillDownEventsChartData,
    SDIDrillDownFindingsChartData,
    SentinelSDIDrillDownFindings,
    SensitiveDataOccurrencesForApps,
    EventsProcessedTableData,
    SDIDrillDownEventsProcessedTableData,
    SDIDrillDownOccurrencesTableData,
    GlobalSentinelReportData
} from "./sentinel-monitor.types";

export interface SentinelMonitorServiceProps {
    getRunningAppsWithSentinelsDashboard: () => Promise<RunningAppsProps>;
    getFlowDesignerPreview: (
        entityType: string,
        namespace: string,
        name: string,
        fromTime: number,
        toTime: number
    ) => Promise<FlowDesignerPreview>;
    getAppSentinelsAIActivityEventsProcessed: (
        namespace: string,
        appName: string,
        fromTime: number,
        toTime: number
    ) => Promise<AllSentinelsEventsProcessed>;
    getAppSentinelsEventsProcessedChartData: (
        namespace: string,
        appName: string,
        fromTime: number,
        toTime: number,
        timeFormat: TimeFormat
    ) => Promise<EventsProcessedChartData>;
    getSentinelEventsProcessed: (
        namespace: string,
        sentinelName: string,
        fromTime: number,
        toTime: number
    ) => Promise<AllSentinelsEventsProcessed>;
    getSentinelEventsProcessedChartData: (
        namespace: string,
        sentinelName: string,
        fromTime: number,
        toTime: number,
        timeFormat: TimeFormat
    ) => Promise<EventsProcessedChartData>;
    getRunningAppsSentinelsDetail: () => Promise<SentinelDetailProps>;
    getRunningAppsWithSentinelsDetail: () => Promise<SentinelDetailProps>;
    getRunningAppsWithSentinels: () => Promise<RunningAppsWithSentinelsProps>;
    getAppsEventsProcessedData: (
        appNames: string[],
        fromTime: number,
        toTime: number
    ) => Promise<EventsProcessedTableData>;
    getAppsEventsProcessedChartData: (
        appNames: string[],
        fromTime: number,
        toTime: number,
        timeFormat: TimeFormat
    ) => Promise<EventsProcessedChartData>;
    getSDIDrillDownEventsProcessedForApps: (
        appNames: string[],
        fromTime: number,
        toTime: number,
        sdi: string
    ) => Promise<SDIDrillDownEventsProcessedTableData>;
    getSDIDrillDownEventsProcessedForAppsChartData: (
        appNames: string[],
        fromTime: number,
        toTime: number,
        sdi: string,
        timeFormat: TimeFormat
    ) => Promise<AllSentinelsSDIDrillDownEventsChartData>;
    getSDIDrillDownOccurrences: (
        applications: string[],
        fromTime: number,
        toTime: number
    ) => Promise<SDIDrillDownOccurrenceProps>;
    getSensitiveDataOccurrences: (
        entity: string,
        namespace: string,
        name: string,
        appName: string,
        fromTime: number,
        toTime: number
    ) => Promise<SensitiveDataOccurrences>;
    getSensitiveDataOccurrencesChartData: (
        entity: string,
        namespace: string,
        name: string,
        fromTime: number,
        toTime: number,
        timeFormat: TimeFormat
    ) => Promise<SensitiveDataOccurrencesChartData>;
    getSDIDrillDownOccurrencesForApps: (
        applications: string[],
        fromTime: number,
        toTime: number,
        sdi: string
    ) => Promise<SDIDrillDownOccurrencesTableData>;
    getSDIDrillDownOccurrencesForAppsChartData: (
        applications: string[],
        fromTime: number,
        toTime: number,
        sdi: string,
        timeFormat
    ) => Promise<SensitiveDataOccurrencesChartData>;
    getAppSentinelsSDIEventsProcessed: (
        namespace: string,
        appName: string,
        fromTime: number,
        toTime: number,
        sdi: string
    ) => Promise<AllSentinelsSDIDrillDownEvents>;
    getAppSentinelsSDIDrillDownEventsChartData: (
        namespace: string,
        appName: string,
        fromTime: number,
        toTime: number,
        sdi: string,
        timeFormat: TimeFormat
    ) => Promise<AllSentinelsSDIDrillDownEventsChartData>;
    getSentinelSDIDrillDownEventsChartData: (
        namespace: string,
        sentinelName: string,
        fromTime: number,
        toTime: number,
        sdi: string,
        timeFormat: TimeFormat
    ) => Promise<AllSentinelsSDIDrillDownEventsChartData>;
    getSentinelSDIDrillDownEvents: (
        namespace: string,
        sentinelName: string,
        fromTime: number,
        toTime: number,
        sdi: string
    ) => Promise<SentinelsSDIDrillDownEvents>;
    getAppSDIDrillDownFindings: (
        namespace: string,
        appName: string,
        fromTime: number,
        toTime: number,
        sdi: string
    ) => Promise<AppSDIDrillDownFindings>;
    getSentinelSDIDrillDownFindings: (
        namespace: string,
        sentinelName: string,
        fromTime: number,
        toTime: number,
        sdi: string
    ) => Promise<SentinelSDIDrillDownFindings>;
    getSDIDrillDownFindingsChartData: (
        entityType: string,
        namespace: string,
        name: string,
        fromTime: number,
        toTime: number,
        sdi: string,
        timeFormat: TimeFormat
    ) => Promise<SDIDrillDownFindingsChartData>;
    getSensitiveDataOccurrencesForApps: (
        applications: string[],
        fromTime: number,
        toTime: number
    ) => Promise<SensitiveDataOccurrencesForApps>;
    getSensitiveDataOccurrencesForAppsChartData: (
        appNames: string[],
        fromTime: number,
        toTime: number,
        timeFormat: TimeFormat
    ) => Promise<SensitiveDataOccurrencesChartData>;
    getSDIWithApplicationDetails: (
        applications: string[],
        fromTime: number,
        toTime: number
    ) => Promise<SDIWithApplicationDetails>;
    getGlobalSentinelReportData: (applications: string[], fromTime: number, toTime: number) => Promise<GlobalSentinelReportData>;
}

function getApiSentinelMonitorService(): SentinelMonitorServiceProps {
    // Add once APIs are available
    return {
        getRunningAppsWithSentinelsDashboard: async () => {
            return api.getRunningAppsWithSentinelsDashboard();
        },
        getFlowDesignerPreview: async (entityType, namespace, name, fromTime, toTime) => {
            return api.getFlowDesignerPreview(entityType, namespace, name, fromTime, toTime);
        },
        getAppSentinelsAIActivityEventsProcessed: async (namespace, appName, fromTime, toTime) => {
            return api.getAppSentinelsAIActivityEventsProcessed(namespace, appName, fromTime, toTime);
        },
        getAppSentinelsEventsProcessedChartData: async (namespace, appName, fromTime, toTime, timeFormat) => {
            return api.getAppSentinelsEventsProcessedChartData(namespace, appName, fromTime, toTime, timeFormat);
        },
        getSentinelEventsProcessed: async (namespace, sentinelName, fromTime, toTime) => {
            return api.getSentinelEventsProcessed(namespace, sentinelName, fromTime, toTime);
        },
        getSentinelEventsProcessedChartData: async (namespace, sentinelName, fromTime, toTime, timeFormat) => {
            return api.getSentinelEventsProcessedChartData(namespace, sentinelName, fromTime, toTime, timeFormat);
        },
        getRunningAppsSentinelsDetail: async () => {
            const sentinelApps = await api.getRunningAppsSentinelsDetail();
            return sentinelApps?.sentinels?.filter(
                sentinel => !sentinel?.namespace?.toLowerCase()?.includes("system$")
            );
        },
        getRunningAppsWithSentinelsDetail: async () => {
            return api.getRunningAppsWithSentinelsDetail();
        },
        getRunningAppsWithSentinels: async () => {
            return api.getRunningAppsWithSentinels();
        },
        getAppsEventsProcessedData: async (appNames, fromTime, toTime) => {
            return api.getAppsEventsProcessedData(appNames, fromTime, toTime);
        },
        getAppsEventsProcessedChartData: async (appNames, fromTime, toTime, timeFormat) => {
            return api.getAppsEventsProcessedChartData(appNames, fromTime, toTime, timeFormat);
        },
        getSensitiveDataOccurrences: async (entity, namespace, name, appName, fromTime, toTime) => {
            return api.getSensitiveDataOccurrences(entity, namespace, name, appName, fromTime, toTime);
        },
        getSensitiveDataOccurrencesChartData: async (entity, namespace, name, fromTime, toTime, timeFormat) => {
            return api.getSensitiveDataOccurrencesChartData(entity, namespace, name, fromTime, toTime, timeFormat);
        },
        getAppSentinelsSDIEventsProcessed: async (namespace, appName, fromTime, toTime, sdi) => {
            return api.getAppSentinelsSDIEventsProcessed(namespace, appName, fromTime, toTime, sdi);
        },
        getAppSentinelsSDIDrillDownEventsChartData: async (namespace, appName, fromTime, toTime, sdi, timeFormat) => {
            return api.getAppSentinelsSDIDrillDownEventsChartData(
                namespace,
                appName,
                fromTime,
                toTime,
                sdi,
                timeFormat
            );
        },
        getSentinelSDIDrillDownEventsChartData: async (namespace, sentinelName, fromTime, toTime, sdi, timeFormat) => {
            return api.getSentinelSDIDrillDownEventsChartData(
                namespace,
                sentinelName,
                fromTime,
                toTime,
                sdi,
                timeFormat
            );
        },
        getSentinelSDIDrillDownEvents: async (namespace, sentinelName, fromTime, toTime, sdi) => {
            return api.getSentinelSDIDrillDownEvents(namespace, sentinelName, fromTime, toTime, sdi);
        },
        getSDIDrillDownFindingsChartData: async (entityType, namespace, name, fromTime, toTime, sdi, timeFormat) => {
            return api.getSDIDrillDownFindingsChartData(entityType, namespace, name, fromTime, toTime, sdi, timeFormat);
        },
        getAppSDIDrillDownFindings: async (namespace, appName, fromTime, toTime, sdi) => {
            return api.getAppSDIDrillDownFindings(namespace, appName, fromTime, toTime, sdi);
        },
        getSentinelSDIDrillDownFindings: async (namespace, name, fromTime, toTime, sdi) => {
            return api.getSentinelSDIDrillDownFindings(namespace, name, fromTime, toTime, sdi);
        },
        getSDIDrillDownOccurrences: async (applications, fromTime, toTime) => {
            return api.getSDIDrillDownOccurrences(applications, fromTime, toTime);
        },
        getSDIDrillDownOccurrencesForApps: async (applications, fromTime, toTime, sdi) => {
            return api.getSDIDrillDownOccurrencesForApps(applications, fromTime, toTime, sdi);
        },
        getSDIDrillDownOccurrencesForAppsChartData: async (applications, fromTime, toTime, sdi, timeFormat) => {
            return api.getSDIDrillDownOccurrencesForAppsChartData(applications, fromTime, toTime, sdi, timeFormat);
        },
        getSDIDrillDownEventsProcessedForApps: async (applications, fromTime, toTime, sdi) => {
            return api.getSDIDrillDownEventsProcessedForApps(applications, fromTime, toTime, sdi);
        },
        getSDIDrillDownEventsProcessedForAppsChartData: async (applications, fromTime, toTime, sdi, timeFormat) => {
            return api.getSDIDrillDownEventsProcessedForAppsChartData(applications, fromTime, toTime, sdi, timeFormat);
        },
        getSensitiveDataOccurrencesForApps: async (applications, fromTime, toTime) => {
            return api.getSensitiveDataOccurrencesForApps(applications, fromTime, toTime);
        },
        getSensitiveDataOccurrencesForAppsChartData: async (appNames, fromTime, toTime, timeFormat) => {
            return api.getSensitiveDataOccurrencesForAppsChartData(appNames, fromTime, toTime, timeFormat);
        },
        getGlobalSentinelReportData: async (applications, fromTime, toTime) => {
            return api.getGlobalSentinelReportData(applications, fromTime, toTime);
        }
    };
}

export default function getSentinelMonitorService(): SentinelMonitorServiceProps {
    // @ts-ignore
    if (window.useFakeMonitorService) return getFakeSentinelMonitorService();
    return getApiSentinelMonitorService();
}
