import SYSALERTRULE from "core/services/metaStoreService/metaObjects/sysalertrule";

function modifyAlert(alertData) {
    return new Promise(function(resolve, reject) {
        let model = new SYSALERTRULE.Model();
        model.set(alertData);
        model
            .save()
            .done(() => {
                resolve(model.toJSON());
            })
            .fail(e => {
                reject(e);
            })
            .always(() => {
                model.fetch();
                console.log(model);
            });
    });
}

export default modifyAlert;
