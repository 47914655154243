import cachestable from "./cachestable";
import exteralCachesTable from "./externalCachesTable";
import eventstabletable from "./eventstabletable";
import queriestable from "./queriestable";
import sourcestable from "./sourcestable";
import streamstable from "./streamstable";
import targetstable from "./targetstable";
import wactionstorestable from "./wactionstorestable";
import windowstable from "./windowstable";

export default {
    cachestable: cachestable,
    externalcachestable: exteralCachesTable,
    eventstabletable: eventstabletable,
    queriestable: queriestable,
    sourcestable: sourcestable,
    streamstable: streamstable,
    targetstable: targetstable,
    wactionstorestable: wactionstorestable,
    windowstable: windowstable,
};
