import { isAutomatedWizard } from "app/components/appwizard/component-wizards/wizards/source/database-reader/app-services.js";
import { TYPES } from "./wizard-types";
import { DB_PROVIDERS } from "../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/configs/db-providers";
import { getDatabaseType } from "../../../../app/components/appwizard/component-wizards/wizards/common/databaseTypes";

export const isCDCAdapter = adapter => {
    switch (adapter) {
        case "OracleReader":
        case "MSSqlReader":
        case "MysqlReader":
        case "PostgreSQLReader":
        case "MariaDBReader":
        case "MariaDBXpandReader":
        case "MSJet":
        case "OJet":
        case "SalesforceCDCReader":
            return true;
        default:
            return false;
    }
};

export const isAppAdapter = adapter => { 
    return DB_PROVIDERS[getDatabaseType(adapter)]?.isAppAdapter
}

export const getWizardType = sourceAdapter => {
    if (isAutomatedWizard()) return TYPES.Automated;
    if (isAppAdapter(sourceAdapter) && sourceAdapter !== 'SalesforceCDCReader') return TYPES.Automated_Mode_App_Adapter;
    else return isCDCAdapter(sourceAdapter) ? TYPES.CDC : TYPES.InitialLoad;
};