import React, { useRef } from "react";
import { Box, Divider, Grid } from "@mui/material";
import { StriimButton, StriimTypography, StriimLink } from "@striim/striim-ui";
import { CreateFromScratch, ImportTQLFile, OpenLink } from "../../../../../generic/icon/customIcons";
import { useNavigate } from "react-router-dom";
import QuickStartGroup from "../../create-app-page/components/create-app-content/components/quick-start-group";
import styles from "../create-app-page-styles";

const CreateAppPageCustomFlow: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const tqlUploadInputRef = useRef<HTMLInputElement>();
    return (
        <Box width={500} my={4}>
            <Divider sx={styles.divider}>
                <StriimTypography variant="c1" color="greyscale.900" sx={{ fontSize: 14 }}>
                    Build a Custom Flow
                </StriimTypography>
            </Divider>
            <Grid container justifyContent="space-between" alignItems="center" gap={2} mt={3} mb={4}>
                <StriimButton
                    sx={styles.customFlowButton}
                    variant="secondary"
                    onClick={() => navigate("/create-app/name-app")}
                    id="createapp--Start_from_scratch"
                >
                    <CreateFromScratch /> Start from scratch
                </StriimButton>
                <StriimButton
                    sx={styles.customFlowButton}
                    variant="secondary"
                    onClick={() => tqlUploadInputRef.current.click()}
                >
                    <ImportTQLFile />
                    Import a TQL file
                </StriimButton>
            </Grid>
            <Grid container justifyContent="center">
                <StriimLink sx={styles.link} onClick={() => navigate("/create-app-templates")}>
                    View all wizards <OpenLink />
                </StriimLink>
            </Grid>
            <QuickStartGroup showTemplates={false} tqlUploadInputRef={tqlUploadInputRef} />
        </Box>
    );
};
export default CreateAppPageCustomFlow;
