import $ from "jquery";
import _ from "underscore";

export default (function () {
    function UrlBuilder() {
        var _this = this;

        this.fragment = "";
        this.paths = [];
        this.queryString = {};

        this.parse = function (url) {
            // to be implemented
            return new UrlBuilder();
        };
        this.setQueryParam = function (paramName, paramValue) {
            if (paramName && paramValue) {
                _this.queryString[paramName] = encodeURIComponent(JSON.stringify(paramValue));
            }
            return _this;
        };
        this.setFragment = function (fragment) {
            if (fragment) {
                _this.fragment = fragment;
            }
            return _this;
        };
        this.setPath = function (path) {
            _this.paths.push(path);
            return _this;
        };
        this.getQueryString = function () {
            return $.param(_this.queryString);
        };
        this.build = function () {
            var url = "";
            if (_this.fragment) {
                url += "#/" + _this.fragment + "/";
            }
            if (_this.paths.length > 0) {
                url += _this.paths.join("/");
            }
            if ($.isEmptyObject(_this.queryString) === false) {
                url += "?" + _this.getQueryString();
            }
            return url;
        };
    }

    return UrlBuilder;
})();
