import React, { useEffect, useRef, useState } from "react";
import { Card, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { StriimTypography } from "@striim/striim-ui";
import { formatNumber } from "../../../striim-unit-usage-breakdown/components/utils";

const styles = {
    cardStyles: {
        height: "180px",
        borderRadius: 2,
        padding: 2,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: 0
    },
    valueFont: {
        fontSize: "32px",
        lineHeight: "48px",
        letterSpacing: "-0.32px",
        fontWeight: "700"
    }
};

const Tile = ({ title, value, unit, customWidth, tooltip }) => {
    const ref = useRef(null);
    const [hover, setHover] = useState(false);
    const compareSize = () => {
        const compare = ref.current.scrollWidth > ref.current.getBoundingClientRect().width;
        setHover(!compare);
    };

    useEffect(() => {
        if (tooltip) {
            compareSize();
            window.addEventListener("resize", compareSize);
        }

        return () => {
            window.removeEventListener("resize", compareSize);
        };
    }, [tooltip]);

    return (
        <Card sx={{ ...styles.cardStyles, minWidth: customWidth || "230px" }} ref={ref}>
            {typeof title === "string" ? (
                <Tooltip title={title} disableHoverListener={!hover}>
                    <StriimTypography noWrap variant="h3" color="primary.700">
                        {title}
                    </StriimTypography>
                </Tooltip>
            ) : (
                title
            )}
            {typeof value === "number" ? (
                <Typography noWrap variant="h1" color="primary.700" sx={styles.valueFont}>
                    {formatNumber(value, false)}
                </Typography>
            ) : (
                value
            )}
            {typeof unit === "string" ? (
                <StriimTypography noWrap variant="h3" color="greyscale.700" gutterBottom>
                    {unit}
                </StriimTypography>
            ) : (
                unit
            )}
        </Card>
    );
};

export default Tile;
