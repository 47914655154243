import $ from "jquery";
import _ from "underscore";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import api from "../../../../../../core/api/api.js";
import ignoredPatternsFilter from "app/components/common/ignoredPatternsFilter";

export default {
    get: function() {
        var deferred = $.Deferred();
        var metadata = {};

        metaStoreService
            .fetchCollections(["STREAM", "WACTIONSTORE", "CACHE", "WINDOW"])
            .then(function(streams, wactionstores, caches, windows) {
                try {
                    let fetchMap = {};
                    const regularModels = _.sortBy(
                        _.union(streams.models, wactionstores.models, caches.models, windows.models).filter(
                            ignoredPatternsFilter
                        ),
                        function(item) {
                            return item.get("name");
                        }
                    );
                    const sortMetaData = type => {
                        return type ? _.sortBy(_.pluck(type.fields, "name"), item => item) : [];
                    };

                    regularModels.forEach(async model => {
                        let typeFieldValueMap = {};
                        const parent_obj_name = model.nsName + "." + model.name;
                        // each metaobject has the type in a different property name...
                        const stream = _.findWhere(streams.models, {
                            id: model.stream
                        });
                        const typeFieldValue =
                            model.dataType || model.typename || model.contextType || (stream ? stream.dataType : null);

                        if (typeFieldValue) {
                            let type;
                            if (!fetchMap[typeFieldValue]) {
                                type = api._CRUDHandler("", "READ", {
                                    id: typeFieldValue,
                                    type: "TYPE"
                                });
                                fetchMap[typeFieldValue] = type;
                                await type.then(value => {
                                    const theValue = value[0];
                                    typeFieldValueMap[typeFieldValue] = theValue;
                                    metadata[parent_obj_name] = sortMetaData(theValue);
                                });
                            } else {
                                const theValue = await fetchMap[typeFieldValue].then(value => value[0]);
                                metadata[parent_obj_name] = sortMetaData(theValue);
                            }
                        } else {
                            metadata[parent_obj_name] = [];
                        }
                    });
                    Promise.all(Object.entries(fetchMap)).then(() => {
                        deferred.resolve(metadata);
                    });
                } catch (err) {
                    console.log(err);
                }
            });

        return deferred.promise();
    }
};
