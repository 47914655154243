import App from "app";
import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import dialog from "app/components/common/dialogs/dialogWindow";

var HDFSService = {};
App.addInitializer(function () {
    App.reqres.setHandler("hdfsbrowse:initial", HDFSService.browseInitial);
    App.reqres.setHandler("hdfsbrowse:update", HDFSService.update);
    App.reqres.setHandler("hdfs:browseDirById", HDFSService.browseDirById);
});

HDFSService.File = Backbone.Model.extend({
    url: function () {
        var base = "/file_browser";
        var url = base;
        var id = this.get("id");
        if (this.get("id")) {
            url = url + "?path=" + this.get("id") + "&children=true";
        } else {
            url += "&children=true";
        }
        if (this.get("url")) {
            if (id) {
                url = url + "&hdfsurl=";
            }
            url += this.get("url");
        }
        return url;
    },
    initialize: function () {
        var id = this.get("id");
        if (typeof id === "undefined") {
            var path = this.get("currentPath");
            if (path) {
                var name = this.get("name");
                if (path === "/") {
                    this.id = path + name;
                    this.set("id", this.id);
                } else {
                    this.id = path + "/" + name;
                    var tmpId = path + "/" + name;
                    this.set("id", tmpId);
                }
            }
        }
    },
});

HDFSService.Collection = Backbone.Collection.extend({
    model: HDFSService.File,
});

HDFSService.browseInitial = function (url) {
    var col = new HDFSService.Collection();
    var file = new HDFSService.File({
        id: "/",
        url: url,
    });

    var deferred = $.Deferred();
    $("#spinnerIcon").show();
    file.fetch({
        timeout: 8000,
    })
        .then(function(data, textStatus, jqXHR) {
            file.set("isDirectory", data.isDirectory);
            file.set("name", data.name);
            file.set("children", new HDFSService.Collection(data.children));
            col.add(file);
            deferred.resolve(file, textStatus, jqXHR);
        })
        .fail(function (msg) {
            var message =
                `${msg.statusText} <br/><br/> Please ensure your Hadoop cluster is running and ` +
                `accessible via your local network using the provided Namenode URL`;
            dialog.alert(message);
        })
        .always(function () {
            $("#spinnerIcon").hide();
        });
    return deferred;
};

HDFSService.browseDirById = function (collection, id, root) {
    var model = collection.get(id);

    var deferred;

    /* Collections are nested. If model doesn't exist in current collection, recursively iterate
     through each model in collection to see if child exists in one of the models' nested collections
     */
    if (model) {
        /* Create deferred object only when model corresponding to chosen file/dir is found */

        deferred = $.Deferred();
        model.set("url", root.get("url"));
        model.fetch().then(function(data, textStatus, jqXHR) {
            model.set("children", new HDFSService.Collection(data.children));
            model.set("hasChildren", true);
            deferred.resolve(model, collection, data, textStatus, jqXHR);
        });
    } else {
        _.each(collection.models, function (child) {
            if (child.get("isDirectory") && child.get("children")) {
                /* Recursive call */
                var ret = HDFSService.browseDirById(child.get("children"), id, root);
                if (ret) {
                    deferred = ret;
                }
            }
        });
    }
    if (deferred) {
        return deferred;
    }
};

HDFSService.update = function (model) {
    var deferred = $.Deferred();
    var url = model.get("url");
    var col = new HDFSService.Collection();
    var file = new HDFSService.File({
        id: "/",
        url: url,
    });

    file.fetch().then(function(data, textStatus, jqXHR) {
        file.set("isDirectory", data.isDirectory);
        file.set("name", data.name);
        file.set("children", new HDFSService.Collection(data.children));
        col.add(file);
        deferred.resolve(file, textStatus, jqXHR);
    });
    return deferred;
};

export default HDFSService;
