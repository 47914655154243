import React from "react";
import { FieldArray } from "formik";
import FormikField from "src/generic/formik-field";
import { StriimTable } from "@striim/striim-ui";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { COSMOSDB_CONFIG } from "../../cosmosdb-target/config";

const useEmptyStyles = makeStyles(() => ({}));

const useCellStyles = makeStyles(({ palette }) => ({
    tableCell: num => ({
        [`& .MuiTableRow-root:nth-of-type(${num}) > .MuiTableCell-root`]: {
            backgroundColor: palette.danger.main + "14"
        }
    })
}));

const defaultTableColumns = [
    { Header: "Source Database", accessor: "source" },
    { Header: "Target Database", accessor: "target" }
];

const DatabaseMapping = ({ id = "", values, errors, touched, dbMappingColumns = defaultTableColumns }) => {
    let tableRootStyles = [];

    const dbMappingData = values.dbMapping?.map((v, i) => {
        const hasError =
            id === COSMOSDB_CONFIG.cassandra.id
                ? touched.dbMapping &&
                  touched.dbMapping[i]?.targetTable &&
                  errors.dbMapping &&
                  errors.dbMapping[i]?.targetTable
                : touched.dbMapping &&
                  touched.dbMapping[i]?.targetDatabase &&
                  errors.dbMapping &&
                  errors.dbMapping[i]?.targetDatabase;
        if (hasError) {
            tableRootStyles.push(useCellStyles(i + 1).tableCell);
        } else {
            tableRootStyles.push(useEmptyStyles());
        }

        const source = id === COSMOSDB_CONFIG.cassandra.id ? v.sourceTable : v.sourceDatabase;
        const targetName = id === COSMOSDB_CONFIG.cassandra.id ? "targetTable" : "targetDatabase";
        return {
            source: <div style={{ marginTop: "8px" }}>{source}</div>,
            target: <Box component={FormikField} name={`dbMapping.${i}.${targetName}`} />
        };
    });

    return (
        <FieldArray
            name="dbMapping"
            render={() => {
                return (
                    <StriimTable
                        data={dbMappingData}
                        columns={dbMappingColumns}
                        TableBodyProps={{
                            classes: { root: classNames(...tableRootStyles) }
                        }}
                    />
                );
            }}
        />
    );
};

export default DatabaseMapping;
