import React, { useEffect } from "react";
import loadBackboneApp from "../../../app/load-backbone-app";
import { useParams } from "react-router-dom";
import AppDetails from "../../../app/components/monitor/appdetails/appdetails";
import App from "../../../app";
import MonitorRoot from "./monitor-root";

function MonitorAppDetails() {
    const { appName, dataType } = useParams<{ appName: string, dataType: string }>();
    let monitorParams = { appName: appName, dataType: dataType || null };
    let backboneApp;

    useEffect(() => {
        
        const view = new AppDetails.View({...monitorParams});
        backboneApp = loadBackboneApp(view);
        if (dataType) {
            App.vent.trigger("monitor:showData", appName, dataType);
        } else {
            App.vent.trigger("monitor:showData", appName);
        }
    }, [monitorParams]);

    return (
        <></>
    );
}

export default function() {
    return (
        <MonitorRoot>
            <MonitorAppDetails />
        </MonitorRoot>
    );
}