import UIControl from "app/components/common/editor/control/ui-control";

var Transformation = {};

Transformation.OnlyDbSources = false;

Transformation.createColumnControl = function () {
    return UIControl.ColumnsPicker.extend({
        hideLabel: true,
        hidden: false,
        transformationConfig: {
            preFillColumns: true,
            autoFillAlias: true,
            hideRemoveIcon: true,
            allowClearColumns: false,
            labels: {
                column1: "Existing Field Name",
                column2: "New Field Name",
            },
        },
    }).create();
};

Transformation.buildTql = function (tqlBuilder, columns) {
    columns.forEach(function (column) {
        if (!column.column) {
            return;
        }

        tqlBuilder.addColumn(column.column, null, null, column.alias);
    });
};
export default Transformation;
