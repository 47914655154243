import App from "app";
import Backbone from "backbone";
import UIControl from "app/components/common/editor/control/ui-control";
import fieldFilterHelper from "./fieldFilterHelper";
import fieldDetailsControls from "./fieldDetailsControls";
import _template from "./fieldDetails.html";

var FieldDetails = {};

FieldDetails.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(_template),
    className: "search-field-details",
    regions: {
        filterTypeRegion: ".filter-type",
        filterValueSelectionRegion: ".filter-value-selection"
    },
    events: {
        "click .apply-btn": "_applyClick",
        "click .clear-btn": "_clearClick",
        "click .cancel-btn": "_cancelClick"
    },
    initialize: function() {
        var availableTypes = fieldFilterHelper.GetAvailableFilterTypes(this.model.type);
        this.filterType = UIControl.Select(availableTypes, {
            allowClear: false,
            hideSearch: true,
            width: "100%"
        }).create();
        this.filterType.setValue(fieldFilterHelper.GetFilterTypeFromCurrentValue(this.model.currentValue));
        this.controlView = fieldDetailsControls.GetControlForType(this.model);

        var that = this;
        this.listenTo(this.filterType.model, "change:value", function(selected) {
            that.controlView.setFilterType(selected);
        });
    },
    onRender: function() {
        this.filterTypeRegion.show(this.filterType);
        this.filterValueSelectionRegion.show(this.controlView);
    },
    _cancelClick: function() {
        this.model.trigger("field-details:cancel");
    },
    _clearClick: function() {
        this.model.trigger("field-details:apply", {});
    },
    _applyClick: function() {
        var result = {};
        result[this.model.name] = this.filterValueSelectionRegion.currentView.getValue();
        this.model.trigger("field-details:apply", result);
    }
});

export default FieldDetails;
