import React from "react";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router";

import { StriimTypography, StriimIconButton, StriimButton } from "@striim/striim-ui";
import { styles } from "./connection-profile-item-header.styles";
import EditIcon from "src/generic/icon/manage-striim/edit.svg";
import DeleteIcon from "src/generic/icon/manage-striim/delete.svg";
import TestIcon from "src/generic/icon/manage-striim/checklist.svg";
import AppIcon from "src/generic/icon/manage-striim/app.svg";
import MANAGE_STRIIM_ROUTES from "../../../routes.json";

interface ConnectionProfileHeaderProps {
    showBackButton?: boolean;
    showEditButton?: boolean;
    showDeleteButton?: boolean;
    onBackClick?: () => void;
    onEditClick?: () => void;
    onTestClick?: () => void;
    onAssociatedAppsClick?: () => void;
    onSaveClick?: () => void;
    onDeleteClick?: () => void;
    title: string;
    showActionControls?: boolean;
}

const ConnectionProfileHeader = ({
    showBackButton = true,
    showEditButton = false,
    showDeleteButton = false,
    title,
    showActionControls = false,
    onBackClick,
    onEditClick,
    onTestClick,
    onAssociatedAppsClick,
    onSaveClick,
    onDeleteClick
}: ConnectionProfileHeaderProps) => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        if (onBackClick) {
            onBackClick();
        } else {
            navigate(MANAGE_STRIIM_ROUTES.connectionProfiles);
        }
    };

    const handleEditClick = () => {
        onEditClick && onEditClick();
    };

    const handleAssociatedAppsClick = () => {
        onAssociatedAppsClick && onAssociatedAppsClick();
    };

    const handleTestClick = () => {
        onTestClick && onTestClick();
    };

    const handleSaveClick = () => {
        onSaveClick && onSaveClick();
    };

    const handleDeleteClick = () => {
        onDeleteClick && onDeleteClick();
    };

    return (
        <Grid container display="flex" justifyContent="space-between" alignItems="center" sx={styles.container}>
            <Grid item display="flex" alignItems="center">
                {showBackButton && (
                    <StriimIconButton variant="secondary" size="small" sx={styles.backButton} onClick={handleBackClick}>
                        <ChevronLeftIcon sx={styles.icon} />
                    </StriimIconButton>
                )}
                <StriimTypography variant="h1" sx={styles.heading} data-test-id="edit-header">
                    {title}
                </StriimTypography>
            </Grid>
            <Grid item>
                {showActionControls && (
                    <div>
                        <>
                            {showEditButton && (
                                <StriimButton
                                    variant="text"
                                    sx={styles.actionButton}
                                    onClick={() => handleEditClick()}
                                    data-test-id="edit-connection-profile"
                                >
                                    <EditIcon style={{ ...styles.actionIcon }} />
                                    Edit
                                </StriimButton>
                            )}
                            <StriimButton
                                variant="text"
                                sx={styles.actionButton}
                                onClick={() => handleAssociatedAppsClick()}
                                data-test-id="connection-profile-associated-apps"
                            >
                                <AppIcon style={{ ...styles.actionIcon }} />
                                Associated Apps
                            </StriimButton>
                            <StriimButton
                                variant="text"
                                sx={styles.actionButton}
                                onClick={() => handleTestClick()}
                                data-test-id="test-connection-profile"
                            >
                                <TestIcon style={{ ...styles.actionIcon }} />
                                Test Connection
                            </StriimButton>
                            {showDeleteButton && (
                                <StriimButton
                                    variant="text"
                                    sx={styles.deleteIcon}
                                    onClick={() => handleDeleteClick()}
                                    data-test-id="delete-connection-profile"
                                >
                                    <DeleteIcon />
                                    Delete
                                </StriimButton>
                            )}
                        </>
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

export default ConnectionProfileHeader;
