import React, { useContext, useState } from "react";
import { Grid } from "@mui/material";

import { styles } from "../sentinel-monitor.styles";
import { SidebarStates, SidebarWidth } from "./sidebar.consts";
import { ExpandSidebar, OpenSidebar, RefreshedStats, SidebarProps } from "./sidebar-controls";
import { SentinelMonitorContext } from "../sentinel-monitor-context";

const SidebarHeader: React.FC<SidebarProps> = ({ sidebarState, setSidebarState }) => {
    const isOpened = sidebarState !== SidebarStates.CLOSED;

    return (
        <Grid>
            {isOpened ? (
                <Grid sx={styles.headerContainer}>
                    <RefreshedStats />
                    <Grid container width={"auto"} flex={"auto"} justifyContent={"end"}>
                        <ExpandSidebar sidebarState={sidebarState} setSidebarState={setSidebarState} />
                        <OpenSidebar sidebarState={sidebarState} setSidebarState={setSidebarState} />
                    </Grid>
                </Grid>
            ) : (
                <Grid sx={styles.closedHeader}>
                    <OpenSidebar sidebarState={sidebarState} setSidebarState={setSidebarState} />
                </Grid>
            )}
        </Grid>
    );
};

interface SentinelMonitorSidebarProps {
    content: React.ReactElement;
    expandedContent: React.ReactElement;
}

const SentinelMonitorSidebar: React.FC<SentinelMonitorSidebarProps> = ({ content, expandedContent }) => {
    const { sidebarState, setSidebarState } = useContext(SentinelMonitorContext);
    const showSidebarLeftContent = sidebarState !== SidebarStates.CLOSED;
    const showSidebarRightContent = sidebarState !== SidebarStates.OPENED;
    return (
        <>
            <SidebarHeader sidebarState={sidebarState} setSidebarState={setSidebarState} />
            {showSidebarLeftContent && (
                <Grid container flexDirection={"row"} height={"100%"} flexWrap={"nowrap"}>
                    <Grid sx={{ ...styles.sidebarContent, width: SidebarWidth[SidebarStates.OPENED] }}>{content}</Grid>
                    {showSidebarRightContent && <Grid flexGrow={1}>{expandedContent}</Grid>}
                </Grid>
            )}
        </>
    );
};

export default SentinelMonitorSidebar;
