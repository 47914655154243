import _ from "underscore";
import BaseControl from "./../base-control";

export default function (text) {
    var View = BaseControl.View.extend({
        hideLabel: true,
        template: _.template(""),
        getViewValue: function () {},

        setViewValue: function () {},

        onRender: function () {
            var _this = this;
            var tag = this.tag || "h3";
            var classList = this.classList || [];
            var element = document.createElement(tag);
            element.innerHTML = text;
            this.$el.attr("title", text);
            _.each(classList, function (c) {
                _this.$el.addClass(c);
            });

            this.$el.append(element);
        },
    });

    return _.extend({}, BaseControl, {
        View: View,
    });
}
