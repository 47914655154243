import React, { useMemo } from "react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, CartesianGrid, LabelList, Tooltip } from "recharts";
import { StriimTypography } from "@striim/striim-ui";
import { Grid, useTheme } from "@mui/material";

import { styles } from "./sensitive-data-identifier-chart.styles";
import { getFormattedChartData } from "../../../discover-pii-panel/utils";
import { CustomTooltip } from "../../../discover-pii-panel/components/discovery-report-chart/discovery-report-chart";

const valuePadding = 6;
const xOffsetToPlaceOutside = 22;
const xOffsetToPlaceInside = -6;

const renderCustomizedLabel = props => {
    const { x, y, width, value } = props;
    const { palette } = useTheme();
    const length = Math.abs(value).toString().length;
    const isLongEnough = (length + valuePadding) * 2 < width;
    const xOffset = !isLongEnough ? xOffsetToPlaceOutside : xOffsetToPlaceInside;
    const color = !isLongEnough ? styles.barColor : palette.greyscale[50];

    return (
        <g>
            <text
                x={width + x + xOffset}
                y={y + 8}
                fill={color}
                fontFamily="Inter"
                fontSize={10}
                fontWeight={500}
                dominantBaseline="middle"
                textAnchor="end"
            >
                {value}
            </text>
        </g>
    );
};

const truncateText = (text, length) => (text.length > length ? text.slice(0, length) + "…" : text);

const CustomYAxisTick = props => {
    const { y, payload } = props;
    const { palette } = useTheme();
    const isClickable = props.onLabelClick && payload.value !== "Others";

    return (
        <g transform={`translate(${0},${y + 4})`}>
            <text
                x={0}
                y={0}
                textAnchor="start"
                fill={palette.greyscale[700]}
                fontFamily="Inter"
                fontSize={12}
                fontWeight={400}
                letterSpacing={"0.24px"}
                data-testid={payload.value.toLowerCase().replaceAll(" ", "-")}
                onClick={() => {
                    isClickable && props.onLabelClick(payload.value);
                }}
                style={{
                    cursor: isClickable ? "pointer" : "arrow",
                    ...props?.labelStyle
                }}
            >
                <title>{payload.value}</title>
                {truncateText(payload.value, 35)}
            </text>
        </g>
    );
};

const CustomXAxisTick = props => {
    const { x, y, payload } = props;
    const { palette } = useTheme();

    return (
        <g>
            <text
                x={x}
                y={y + 8}
                textAnchor="middle"
                fill={palette.greyscale[700]}
                fontFamily="Inter"
                fontSize={12}
                fontWeight={400}
                letterSpacing={"0.24px"}
            >
                {payload.value}
            </text>
        </g>
    );
};

interface SensitiveDataIdentifierChartProps {
    title: string;
    rightActionButton?: JSX.Element;
    data: {
        name: string;
        count: number;
    }[];
    chartTitles: [string, string];
    onLabelClick?: (name: string) => void;
    labelStyle?: Object;
    groupLargeDataset?: boolean;
    isReport: boolean;
}

const SensitiveDataIdentifierChart = ({
    title,
    rightActionButton,
    data,
    chartTitles,
    onLabelClick,
    labelStyle,
    groupLargeDataset = false,
    isReport = false
}: SensitiveDataIdentifierChartProps) => {
    let chartHeight = 33 * data.length;
    let containerProps = { width: "100%" };

    if (isReport) {
        const minHeight = 140;
        const additionalHeight = data.length >= 3 ? data.length * 25 : 0;
        const chartHeight = minHeight + additionalHeight;
        containerProps = { width: 700, height: chartHeight };
    }

    const updatedData = useMemo(() => {
        return groupLargeDataset ? getFormattedChartData(data) : data;
    }, [data, groupLargeDataset]);

    return (
        <Grid container p={2} gap={2.5} flexDirection={"column"} sx={styles.container}>
            <Grid container justifyContent={"space-between"}>
                <StriimTypography variant="h3" color="primary.700">
                    {title}
                </StriimTypography>
                {rightActionButton}
            </Grid>
            <Grid container height={chartHeight} minHeight={140} {...(isReport ? { flex: 1 } : {})}>
                <Grid container mb={1}>
                    <Grid item width={240}>
                        <StriimTypography variant="caption4" sx={styles.caption}>
                            {chartTitles[0]}
                        </StriimTypography>
                    </Grid>
                    <Grid item>
                        <StriimTypography variant="caption4" sx={styles.caption}>
                            {chartTitles[1]}
                        </StriimTypography>
                    </Grid>
                </Grid>
                <ResponsiveContainer {...containerProps}>
                    <BarChart
                        data={updatedData}
                        layout="vertical"
                        margin={{
                            left: -0.5,
                            bottom: 16
                        }}
                    >
                        <CartesianGrid strokeDasharray="2 2" horizontal={false} />
                        <XAxis
                            dataKey="count"
                            type="number"
                            axisLine={false}
                            tickLine={false}
                            tick={<CustomXAxisTick />}
                            allowDecimals={false}
                            domain={[0, dataMax => dataMax + Math.floor(dataMax / 3)]}
                        />
                        <YAxis
                            dataKey="name"
                            type="category"
                            axisLine={false}
                            tickLine={false}
                            width={240}
                            tick={<CustomYAxisTick onLabelClick={onLabelClick} labelStyle={labelStyle} />}
                        />
                        <Tooltip content={<CustomTooltip />} cursor={false} />
                        <Bar
                            dataKey="count"
                            fill={styles.barColor}
                            background={{ fill: "none" }}
                            barSize={16}
                            radius={[0, 4, 4, 0]}
                        >
                            <LabelList dataKey="count" position="insideRight" content={renderCustomizedLabel} />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
    );
};

export default SensitiveDataIdentifierChart;
