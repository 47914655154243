import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Grid, Box } from "@material-ui/core";
import { StriimTypography } from "@striim/striim-ui";

const useStyles = makeStyles(({ spacing }) => ({
    divider: {
        margin: spacing(4, 0, 2, 0)
    },
    featuresContainer: {
        margin: spacing(3, 0)
    }
}));

const FeaturesDivider = () => {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>
        </Grid>
    );
};

const FeatureLeft = ({ title, description, imgComponent }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.featuresContainer}>
            <Grid item xs={12}></Grid>
            <Grid item xs={5} alignItems="center" container>
                <div>
                    <StriimTypography variant="h2">{title}</StriimTypography>
                    <StriimTypography variant="body" paragraph>
                        {description}
                    </StriimTypography>
                </div>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={5}>
                <Box style={{ width: "100%" }}>
                    {imgComponent}
                </Box>
            </Grid>
        </Grid>
    );
};

const FeatureRight = ({ title, description, imgComponent }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.featuresContainer}>
            <Grid item xs={5} alignItems="center">
                <Box style={{ width: "100%" }}>
                    {imgComponent}
                </Box>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={5} alignItems="center" container>
                <div>
                    <StriimTypography variant="h2">{title}</StriimTypography>
                    <StriimTypography variant="body" paragraph>
                        {description}
                    </StriimTypography>
                </div>
            </Grid>
        </Grid>
    );
};

export const featureSize = {
    width: 480,
    height: 480
};

export { FeaturesDivider, FeatureLeft, FeatureRight };
