import React, { useState, useMemo, useEffect } from "react";
import { Clear, Search } from "@mui/icons-material";
import { Grid, InputAdornment } from "@mui/material";
import { StriimButton, StriimIconButton, StriimInputField } from "@striim/striim-ui-v2";
import { components } from "react-select";

import { styles } from "./entity-table.styles";
import useDebounce from "../../../../../hooks/useDebounce";
import { CONFIDENCE_LEVELS, CONFIDENCE_SCORES, TableData } from "./utils";

interface JobTableFiltersProps {
    tableData: TableData[];
    fieldType: string;
    sensitiveDataIdentifiers: { label: string; value: string }[];
    confidenceScores: [];
    setFilteredTableData: React.Dispatch<React.SetStateAction<TableData[]>>;
}

const EntityTableFilter: React.FC<JobTableFiltersProps> = ({
    tableData,
    fieldType,
    sensitiveDataIdentifiers,
    confidenceScores,
    setFilteredTableData
}) => {
    const confidenceScoreOptions = confidenceScores.map(v => ({ label: v[0], value: v[1] }));

    const [filters, setFilters] = useState({
        searchQuery: "",
        sensitiveDataIdentifiers: null,
        confidenceScore: null
    });

    useEffect(() => {
        handleClearFilters();
    }, [tableData]);

    const hasFilters = useMemo(
        () =>
            filters.searchQuery ||
            filters.sensitiveDataIdentifiers ||
            filters.confidenceScore ||
            filters.confidenceScore === 0, //confidenceScore Low = 0
        [filters]
    );

    const filterTable = () => {
        setFilteredTableData(
            tableData.filter(table => {
                const matchesSearch = table.name.toLowerCase().includes(filters.searchQuery?.toLowerCase());
                const matchesSensitiveIdentifier =
                    !filters.sensitiveDataIdentifiers ||
                    table.sensitiveDataIdentifiers.filter(v => v.name === filters.sensitiveDataIdentifiers).length;
                const matchesConfidenceScore =
                    filters.confidenceScore === null ||
                    filters.confidenceScore === undefined ||
                    ((filters.confidenceScore || filters.confidenceScore === 0) &&
                        table.sensitiveDataIdentifiers.filter(v => {
                            const sdiConfidenceScore = Number(v.confidenceScore);
                            if (filters.confidenceScore === CONFIDENCE_SCORES[CONFIDENCE_LEVELS.HIGH])
                                return sdiConfidenceScore >= filters.confidenceScore;
                            else if (filters.confidenceScore === CONFIDENCE_SCORES[CONFIDENCE_LEVELS.LOW])
                                return sdiConfidenceScore < CONFIDENCE_SCORES[CONFIDENCE_LEVELS.MEDIUM];
                            return (
                                sdiConfidenceScore >= filters.confidenceScore &&
                                sdiConfidenceScore < CONFIDENCE_SCORES[CONFIDENCE_LEVELS.HIGH]
                            );
                        }).length);
                return matchesSearch && matchesSensitiveIdentifier && matchesConfidenceScore;
            })
        );
    };

    const setFiltersDebounced = useDebounce(filterTable, 500);

    const handleFilterChange = (field: string, value: any) => {
        setFilters(prevFilters => ({ ...prevFilters, [field]: value }));
    };

    const clearSearch = () => {
        setFilters(prevFilters => ({ ...prevFilters, searchQuery: "" }));
        setFiltersDebounced();
    };

    const handleClearFilters = () => {
        setFilters({
            searchQuery: "",
            sensitiveDataIdentifiers: null,
            confidenceScore: null
        });
        setFilteredTableData(tableData);
    };

    return (
        <Grid container height={36} justifyContent="space-between" mb={3}>
            <Grid item display={"flex"} height={36} gap={1.5} width={"auto"}>
                <StriimInputField
                    sx={styles.searchInput}
                    placeholder={`Search by ${fieldType} name`}
                    value={filters.searchQuery}
                    onChange={searchValue => {
                        handleFilterChange("searchQuery", searchValue);
                        setFiltersDebounced();
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search opacity={0.45} />
                            </InputAdornment>
                        ),
                        endAdornment: filters.searchQuery ? (
                            <StriimIconButton
                                variant="primary"
                                sx={styles.endAdornmentContainer}
                                onClick={() => {
                                    clearSearch();
                                }}
                            >
                                <Clear sx={styles.iconButton} />
                            </StriimIconButton>
                        ) : null
                    }}
                />
                <StriimInputField
                    id="data-test-id-sensitive-data-identifier"
                    name="sensitiveDataIdentifiers"
                    select
                    placeholder="All Sensitive data identifiers"
                    sx={styles.filter}
                    value={
                        sensitiveDataIdentifiers.find(option => option.value === filters.sensitiveDataIdentifiers) || ""
                    }
                    onChange={option => {
                        handleFilterChange("sensitiveDataIdentifiers", option?.value);
                        setFiltersDebounced();
                    }}
                    SelectProps={{
                        options: sensitiveDataIdentifiers,
                        components: {
                            Option: componentProps => (
                                <components.Option {...componentProps}>
                                    <Grid display="flex" alignItems="center" gap={1}>
                                        <div>{componentProps.data.label}</div>
                                    </Grid>
                                </components.Option>
                            )
                        }
                    }}
                />
                <StriimInputField
                    id="data-test-id-confidence-score"
                    name="confidenceScore"
                    select
                    placeholder="All Confidence Scores"
                    sx={styles.filter}
                    value={confidenceScoreOptions.find(option => option.value === filters.confidenceScore) || ""}
                    onChange={option => {
                        handleFilterChange("confidenceScore", option?.value);
                        setFiltersDebounced();
                    }}
                    SelectProps={{
                        options: confidenceScoreOptions,
                        components: {
                            Option: componentProps => (
                                <components.Option {...componentProps}>
                                    <Grid display="flex" alignItems="center" gap={1}>
                                        <div>{componentProps.data.label}</div>
                                    </Grid>
                                </components.Option>
                            )
                        }
                    }}
                />
            </Grid>
            <StriimButton
                variant="text"
                data-test-id="clear-filters"
                onClick={handleClearFilters}
                disabled={!hasFilters}
            >
                Clear filters
            </StriimButton>
        </Grid>
    );
};

export default EntityTableFilter;
