import _ from "lodash";

const getAvailableSources = sourceApps => {
    const sourceMetrics = [];
    const targetMetrics = [];
    sourceApps.map(app => app?.sources)?.map(metrics => metrics?.forEach(metric => sourceMetrics.push(metric)));
    sourceApps.map(app => app?.targets)?.map(metrics => metrics?.forEach(metric => targetMetrics.push(metric)));

    const getUniqueSortedCollection = collection =>
        _.uniqBy(collection, "adapter-name").map(component => {
            return { label: component["adapter-name"], value: component["adapter-name"] };
        });

    return getUniqueSortedCollection(sourceMetrics);
};

export default getAvailableSources;
