import React, { useMemo } from "react";
import { StriimMessageBox, StriimTypography, StriimButton } from "@striim/striim-ui";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import withTheme from "../theme/theme";
import { useTheme } from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

const useAlertStyles = makeStyles(({ spacing }) => ({
    configureBtns: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between"
    },
    autoCorrectBox: {
        paddingLeft: spacing(1)
    },
    haltedMessageWrapper: {
        display: "flex",
        justifyContent: "space-between",
        fontFamily: "Inter",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: 0.14
    },
    haltedMessageLink: {
        textTransform: "none",
        fontWeight: 500,
        "&:hover": {
            cursor: "pointer"
        }
    },
    warningIcon: {
        fill: "#F4B718",
        paddingBottom: 4
    }
}));
const WarningBox = ({ text }) => {
    return <StriimMessageBox text={text} type="WARNING" />;
};
const HaltExceptionMessageBox = ({ onResumeClick, onViewDtlsClick, isAutomatedGroup, moveToAppGroups }) => {
    const classes = useAlertStyles();

    return (
        <StriimMessageBox
            text={
                <div className={classes.haltedMessageWrapper}>
                    {isAutomatedGroup ? (
                        <span>
                            To edit the application, undeploy the automated pipeline. Once all the exceptions have been
                            resolved, resume the pipeline.{" "}
                            <Box
                                component={StriimTypography}
                                variant="textButton"
                                onClick={moveToAppGroups}
                                color="#00A7E5"
                                className={classes.haltedMessageLink}
                            >
                                Go to the Automated Pipeline
                            </Box>
                        </span>
                    ) : (
                        <span>
                            The application was halted due to exceptions. Please fix them and{" "}
                            <Box
                                component={StriimTypography}
                                variant="textButton"
                                onClick={onResumeClick}
                                color="#00A7E5"
                                id="resume"
                                data-test-id="resume"
                                className={classes.haltedMessageLink}
                            >
                                resume
                            </Box>{" "}
                            the app.{" "}
                        </span>
                    )}
                    <span>
                        <Box
                            component={StriimTypography}
                            variant="textButton"
                            onClick={onViewDtlsClick}
                            color="#00A7E5"
                            id="viewDtls"
                            data-test-id="viewDtls"
                            className={classes.haltedMessageLink}
                        >
                            View details
                        </Box>
                    </span>
                </div>
            }
            type="WARNING"
            customIcon={<WarningRoundedIcon className={classes.warningIcon} />}
        />
    );
};

//TODO:  To be made dynamic when moving inside individual Alert form during Alerts revamp
const AlertConfigMessageBox = ({ email, slack, teams }) => {
    const classes = useAlertStyles();
    const theme = useTheme();
    const getInfoBoxMsg = () => {
        let infoMsg;
        let platformsToConfigure = "";
        if (email.notConfigured) {
            platformsToConfigure = "email";
            if (slack.notConfigured && teams.notConfigured) {
                platformsToConfigure += ", slack or teams";
            } else if (teams.notConfigured) {
                platformsToConfigure += " or teams";
            } else if (slack.notConfigured) {
                platformsToConfigure += " or slack";
            }
        } else if (slack.notConfigured) {
            platformsToConfigure = "slack";
            if (teams.notConfigured) {
                platformsToConfigure += " or teams";
            }
        } else if (teams.notConfigured) {
            platformsToConfigure = "teams";
        }
        const emailConfigured = !email.notConfigured;
        const slackConfigured = !slack.notConfigured;
        const teamsConfigured = !teams.notConfigured;
        let configuredPlatforms = "";
        if (emailConfigured) {
            configuredPlatforms = "Email";
            if (slackConfigured && teamsConfigured) {
                configuredPlatforms += ", slack and teams are configured.";
            } else if (teamsConfigured) {
                configuredPlatforms += " and teams are configured";
            } else if (slackConfigured) {
                configuredPlatforms += " and slack are configured";
            }
        } else if (slackConfigured) {
            configuredPlatforms = "Slack";
            if (teamsConfigured) {
                configuredPlatforms += " and teams are configured";
            }
        } else if (teamsConfigured) {
            configuredPlatforms = "Teams";
        }
        if (emailConfigured && slackConfigured && teamsConfigured) {
            infoMsg = configuredPlatforms;
        } else {
            if (configuredPlatforms) {
                configuredPlatforms =
                    configuredPlatforms.indexOf("configured") > -1
                        ? configuredPlatforms
                        : `${configuredPlatforms} is configured`;
                infoMsg = `${configuredPlatforms} but additional configuration is required so that Striim can send alerts via ${platformsToConfigure}.`;
            } else {
                infoMsg = `Additional configuration is required so that Striim can send alerts via ${platformsToConfigure}.`;
            }
        }
        return infoMsg;
    };

    const sxClasses = useMemo(
        () => ({
            "& .MuiCard-root": {
                backgroundColor: "#fff",
                border: `1px solid ${theme.palette.greyscale[200]}`,
                boxShadow: "unset"
            },
            "& .MuiCardHeader-avatar": {
                "& svg": {
                    fill: theme.palette.greyscale[600]
                }
            }
        }),
        [theme]
    );

    return (
        <Box sx={sxClasses}>
            <StriimMessageBox
                text={
                    <div>
                        <Box sx={{ marginBottom: "16px" }}>
                            <StriimTypography variant="body4" sx={{ color: "#48525C", marginBottom: "16px" }}>
                                {getInfoBoxMsg()}
                            </StriimTypography>
                        </Box>
                        <div className={classes.configureBtns}>
                            <StriimButton
                                onClick={email.onClick}
                                variant="text"
                                id="configure-email"
                                data-test-id="configure-email"
                            >
                                {email.notConfigured ? "Configure Email" : "Edit Email"}
                            </StriimButton>
                            <StriimButton
                                onClick={slack.onClick}
                                variant="text"
                                id="configure-slack"
                                data-test-id="configure-slack"
                            >
                                {slack.notConfigured ? "Configure Slack" : "Edit Slack"}
                            </StriimButton>
                            <StriimButton
                                onClick={teams.onClick}
                                variant="text"
                                id="configure-teams"
                                data-test-id="configure-teams"
                            >
                                {teams.notConfigured ? "Configure Teams" : "Edit Teams"}
                            </StriimButton>
                        </div>
                    </div>
                }
                type="INFO"
            />
        </Box>
    );
};

const AutoCorrectMessageBox = ({ onAutocorrectClick, label, msg }) => {
    const classes = useAlertStyles();
    return (
        <StriimMessageBox
            text={
                <div>
                    {msg}
                    <StriimButton
                        className={classes.autoCorrectBox}
                        onClick={onAutocorrectClick}
                        variant="primaryText"
                        id="auto-repair"
                        data-test-id="auto-repair"
                    >
                        {label}
                    </StriimButton>
                </div>
            }
            type="ERROR"
        />
    );
};
HaltExceptionMessageBox.propTypes = {
    onResumeClick: PropTypes.func.isRequired,
    onViewDtlsClick: PropTypes.func.isRequired
};
export const AlertConfigMsgBox = withTheme(AlertConfigMessageBox);
export const AutoCorrectMsgBox = withTheme(AutoCorrectMessageBox);
export const WarningMsgBox = withTheme(WarningBox);
export default withTheme(HaltExceptionMessageBox);
