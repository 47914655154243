import React, {useState} from "react";
import PropTypes from "prop-types";

import useStyles from "./addon-tile.styles";
import {StriimButton, StriimModal} from "@striim/striim-ui";
import {getAddedMessage} from "./utils";
import {addonFeaturesTitles, addonFeaturesMessages, SegmentationFeaturesStates} from "./add-on-features-utils";
import getAddonIcon from "../../../shared-compoenets/addon-icons";
import CustomIcon from "../../../../../../../../generic/icon/customIcons";
import {StriimTypography} from "../StriimTypographyCustom";

const typeConfirmation = {
    open: PropTypes.bool,
    name: PropTypes.string,
    onClose: PropTypes.func
};

const Confirmation = ({open, name, onClose}) => {
    return (
        <StriimModal
            size="small"
            isVisible={open}
            centerContent
            onCancel={() => onClose(false)}
            onConfirm={() => onClose(true)}
            titleContent={<StriimTypography variant="h2">Confirm</StriimTypography>}
            confirmContent="Remove"
            variant="error"
        >
            <StriimTypography variant="body4">
                <span>
                    Are you sure you want to remove <strong>{name}</strong> addon?
                </span>
            </StriimTypography>
        </StriimModal>
    );
};

Confirmation.prototype = typeConfirmation;

export const typeAddonTile = {
    price: PropTypes.string,
    status: PropTypes.string.isRequired,
    addonKey: PropTypes.string.isRequired,
    added: PropTypes.string
};

const AddonTile = ({addonKey, status, added, price, onClick, planType}) => {
    const classes = useStyles();
    const [removeVisible, setRemoveVisible] = useState(false);

    const addedMessage = getAddedMessage(added);

    const onRemove = result => {
        setRemoveVisible(false);

        if (result) {
            onClick();
        }
    };

    return (
        <div className={classes.addonTile}
             data-test-id={'feature-' + addonKey}
             data-test-active={
                 status === SegmentationFeaturesStates.ADDON_DISABLED ? false :
                     status === SegmentationFeaturesStates.ADDON_ENABLED ? true :
                         null
             }
        >
            <div className={classes.addonHeader}>
                <div className={classes.largeIcon}>
                    <CustomIcon style={{height: 20, width: 20}} icon={getAddonIcon(planType, addonKey)}/>
                </div>
                <StriimTypography variant="h4" className={classes.addonHeaderBody}>
                    {addonFeaturesTitles[addonKey]}
                </StriimTypography>
            </div>
            <div className={classes.addonBodyWrapper}>
                <div className={classes.addonBody}>
                    <StriimTypography variant="caption3">{addonFeaturesMessages[addonKey]}</StriimTypography>
                    <div className={classes.addonPricing}>
                        <div className={classes.smallIcon}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                            >
                                <path
                                    d="M15.7629 9.34391C15.5817 9.02967 15.3185 8.77061 15.0015 8.59446C14.6844 8.41831 14.3254 8.3317 13.9629 8.34391H11.9629C11.4325 8.34391 10.9238 8.55462 10.5487 8.92969C10.1736 9.30477 9.96289 9.81347 9.96289 10.3439C9.96289 10.8743 10.1736 11.383 10.5487 11.7581C10.9238 12.1332 11.4325 12.3439 11.9629 12.3439H13.9629C14.4933 12.3439 15.002 12.5546 15.3771 12.9297C15.7522 13.3048 15.9629 13.8135 15.9629 14.3439C15.9629 14.8743 15.7522 15.383 15.3771 15.7581C15.002 16.1332 14.4933 16.3439 13.9629 16.3439H11.9629C11.6004 16.3561 11.2414 16.2695 10.9243 16.0934C10.6072 15.9172 10.344 15.6581 10.1629 15.3439M12.9629 7.34375V17.3438M3.96289 12.3438C3.96289 13.5256 4.19568 14.696 4.64797 15.7879C5.10027 16.8798 5.7632 17.872 6.59893 18.7077C7.43466 19.5434 8.42681 20.2064 9.51874 20.6587C10.6107 21.111 11.781 21.3438 12.9629 21.3438C14.1448 21.3438 15.3151 21.111 16.407 20.6587C17.499 20.2064 18.4911 19.5434 19.3269 18.7077C20.1626 17.872 20.8255 16.8798 21.2778 15.7879C21.7301 14.696 21.9629 13.5256 21.9629 12.3438C21.9629 11.1619 21.7301 9.99153 21.2778 8.8996C20.8255 7.80767 20.1626 6.81552 19.3269 5.97979C18.4911 5.14406 17.499 4.48113 16.407 4.02883C15.3151 3.57654 14.1448 3.34375 12.9629 3.34375C11.781 3.34375 10.6107 3.57654 9.51874 4.02883C8.42681 4.48113 7.43466 5.14406 6.59893 5.97979C5.7632 6.81552 5.10027 7.80767 4.64797 8.8996C4.19568 9.99153 3.96289 11.1619 3.96289 12.3438Z"
                                    stroke="#1E1E5C"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <StriimTypography variant="body4">{price}</StriimTypography>
                    </div>
                </div>
            </div>
            <div className={classes.footer}>
                {status === SegmentationFeaturesStates.ADDON_ENABLED && (
                    <div className={classes.addonStatusWrapper}>
                        <div className={classes.addonStatus}>
                            <div className={classes.success}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20"
                                     fill="none">
                                    <g id="Group 15">
                                        <rect id="Rectangle 1" x="0.862891" y="0.744141" width="19.2" height="19.2"
                                              rx="9.6" fill="#2AAC5E"/>
                                        <path id="Vector" d="M5.68304 10.3867L9.26732 13.9614L15.2768 7.93652"
                                              stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                </svg>
                            </div>
                            <StriimTypography variant="body" className={classes.added}>{addedMessage}</StriimTypography>
                        </div>
                        <StriimButton
                            onClick={() => setRemoveVisible(true)}
                            variant="secondaryText"
                            className={classes.cancelButton}
                        >
                            Remove
                        </StriimButton>
                    </div>
                )}
                {status === SegmentationFeaturesStates.ADDON_DISABLED && (
                    <div className={classes.addonStatusWrapper}>
                        <StriimButton onClick={onClick} variant="primary" data-test-id="button-add">
                            Add
                        </StriimButton>
                    </div>
                )}
            </div>

            <Confirmation open={removeVisible} name={addonFeaturesTitles[addonKey]} onClose={onRemove}/>
        </div>
    );
};

AddonTile.prototype = typeAddonTile;

export default AddonTile;
