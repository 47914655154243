import React from "react";
import PropTypes from "prop-types";
import { StriimTypography } from "@striim/striim-ui";

import ComponentIcon from "src/generic/icon/component-icon";
import { ChevronRight } from "src/generic/icon/customIcons";

import { classes } from "./source-target-list.styles";
import { Grid, Box } from "@mui/material";

const normalizeList = components => {
    components = components || [];

    if (typeof components.toJS === "function") {
        components = components.toJS();
    }

    return components;
};

const putDuplicatesToTheEndOfTheArray = allComponents => {
    const uniqueComponents = allComponents.filter(function(item, pos) {
        return allComponents.indexOf(item) == pos;
    });

    for (let i = 0; i < uniqueComponents.length; i++) {
        allComponents.splice(allComponents.indexOf(uniqueComponents[i]), 1);
    }

    return uniqueComponents.concat(allComponents);
};

const getComponentsMarkup = (components, max, type, appId, iconSize) => {
    let componentsToRender = [];
    let extraComponents = [];
    let index = 0;

    if (components.length <= max) {
        componentsToRender = components;
    } else {
        componentsToRender = components.slice(0, max - 1);
        extraComponents = components.slice(max - 1);
    }

    let markup = componentsToRender.map(component => {
        index++;
        return (
            <ComponentIcon
                key={`${index}-${type}`}
                component={component}
                componentType={type}
                appId={appId}
                iconSize={iconSize}
            />
        );
    });

    if (extraComponents.length > 0) {
        index++;
        markup.push(
            <ComponentIcon
                key={`${index}-${type}`}
                componentType={type}
                text={extraComponents.length}
                appId={appId}
                extraComponents={extraComponents}
                iconSize={iconSize}
            />
        );
    }

    return markup;
};
const SourceTargetList = ({
    sources,
    targets,
    className,
    maxSources = 2,
    maxTargets = 3,
    classes: propClasses,
    appId,
    appTileClasses,
    targetListsEmptyTileWide,
    customEmptyMessage = "No Sources or Targets",
    iconSize
}) => {
    if ((!sources && !targets) || (sources.length === 0 && targets.length === 0)) {
        return (
            <Grid sx={targetListsEmptyTileWide}>
                <StriimTypography color="greyscale.900" variant="body4">
                    {customEmptyMessage}
                </StriimTypography>
            </Grid>
        );
    }
    const normalizedSources = putDuplicatesToTheEndOfTheArray(normalizeList(sources));
    const normalizedTargets = putDuplicatesToTheEndOfTheArray(normalizeList(targets));
    const sourcesMarkup = getComponentsMarkup(normalizedSources, maxSources, "source", appId, iconSize);
    const targetsMarkup = getComponentsMarkup(normalizedTargets, maxTargets, "target", appId, iconSize);

    return (
        <Grid container alignItems="center" sx={[className, propClasses?.root, classes.wrapper, appTileClasses]}>
            <Grid data-test-id="source-list" sx={[propClasses?.sources, classes.sourcesList]}>
                {sourcesMarkup}
            </Grid>
            {sourcesMarkup.length > 0 && targetsMarkup.length > 0 && (
                <Box sx={classes.arrowRightIcon}>
                    <ChevronRight />
                </Box>
            )}
            <Grid data-test-id="target-list" sx={[propClasses?.targets, classes.targetsList]}>
                {targetsMarkup}
            </Grid>
        </Grid>
    );
};

SourceTargetList.propTypes = {
    sources: PropTypes.object.isRequired,
    targets: PropTypes.object.isRequired,
    maxSources: PropTypes.number,
    maxTargets: PropTypes.number,
    className: PropTypes.string,
    customEmptyMessage: PropTypes.string,
    classes: PropTypes.shape({
        root: PropTypes.string,
        sources: PropTypes.string,
        targets: PropTypes.string
    })
};

export default SourceTargetList;
