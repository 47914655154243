import React, { ReactElement, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import {
    CreateAppTypeDropdownComponent,
    CreateAppPageGetStartedButton,
    CreateAppPageTemplateHeader,
    CreateAppPageCustomFlow,
    CreateAppPageAdaptersSelection
} from "./Components";
import { optionsType } from "./Components/create-app-page-app-type-dropdown";
import { useSearchParams, useParams } from "react-router-dom";
import { createAppUtils } from "./create-app-page-utils";
import { getSupportedAppType } from "./services";
import useCreateAppsStore from "../../../../stores/create-apps/useCreateAppsStore";
import { setSessionStorageItem, sessionStorageItemExists, getSessionStorageItem } from "./session-storage.utils";
import App from "../../../../../app";
import licenseService from "../../../../../core/services/metaStoreService/license-service";

export interface adapterType {
    id: string;
    icon: ReactElement;
    type: "SOURCE" | "TARGET";
    category?: string;
    value?: string;
}
const CreateAppUsingTemplates: React.FunctionComponent = () => {
    const { fromPage } = useParams();

    const getCreateAppInfo = license => {
        const createAppInfo = {
            SQL2FABRICSOLUTION_SQL2FABRIC: {
                defaultSource: "SQL Server",
                defaultTarget: "Fabric Mirroring",
                hideChangeButton: true,
                hideCustomFlow: true,
                hideTargetLabel: true,
                headerInfo: {
                    title: "Create a SQL2Fabric-Mirroring pipeline",
                    description:
                        "Fully managed, automated data pipeline to mirror data from SQLServer to Fabric."
                }
            },
            DEFAULT: {
                defaultSource: null,
                defaultTarget: null,
                hideChangeButton: false,
                hideCustomFlow: false,
                hideTargetLabel: false,
                headerInfo: {
                    title: "Create an App using a wizard",
                    description: "An App is a sequence of actions that moves data from a source to a target."
                }
            }
        };

        const licenseName = fromPage ? license + "_" + fromPage?.toUpperCase() : license;
        return createAppInfo[licenseName] || createAppInfo.DEFAULT;
    };
    const createAppInfo = getCreateAppInfo(licenseService?.nonSegmentedlicenseName);

    const [searchParams, setSearchParams] = useSearchParams();
    const createAppStore = useCreateAppsStore();

    const checkIfFirstTimeUser = () => {
        const sessionStorageKey = App.user.nsName + "." + App.user.name;
        if (sessionStorageItemExists(sessionStorageKey)) {
            setSessionStorageItem(sessionStorageKey, { firstTimeUser: false });
            return false;
        } else {
            setSessionStorageItem(sessionStorageKey, { firstTimeUser: true });
            return true;
        }
    };
    const [isFirstTimeUser, setIsFirstTimeUser] = useState<boolean>(checkIfFirstTimeUser());

    useEffect(() => {
        if (!searchParams.get("source") && !fromPage && !createAppInfo?.defaultSource) {
            resetPage();
        }
    }, [searchParams]);

    const getSource = () => {
        const sourceID = searchParams.get("source");
        const defaultSource = createAppInfo?.defaultSource;

        function getSourceData(id: string) {
            return id ? (createAppUtils.getSources().find(sourceData => sourceData.value === id) as adapterType) : null;
        }
        const sourceData = getSourceData(sourceID || defaultSource);
        return sourceData;
    };
    const getTarget = () => {
        const targetID = searchParams.get("target");
        const sourceID = searchParams.get("source");

        const defaultSource = createAppInfo?.defaultSource;
        const defaultTarget = createAppInfo?.defaultTarget;

        function getTargetData(sourceName: string, targetName: string) {
            return sourceName && targetName
                ? (createAppUtils
                      .getTargets({ value: sourceName })
                      .find(targetData => targetData.value === targetName) as adapterType)
                : null;
        }
        const targetData = targetID ? getTargetData(sourceID, targetID) : getTargetData(defaultSource, defaultTarget);
        return targetData;
    };
    const getTemplateInfo = () => {
        const templateID = searchParams.get("template");

        if (templateID && Source && Target) {
            const supportedFlows: optionsType[] = getSupportedAppType(Source, Target);
            const optionsData = supportedFlows.find(flow => flow.value === templateID);
            return optionsData as optionsType;
        }
        return null;
    };

    const resetPage = () => {
        setSource(null);
        setTarget(null);
        setTemplateInfo(null);
        setExpanded("source");
    };

    const [Source, setSource] = useState<adapterType>(() => getSource());
    const [Target, setTarget] = useState<adapterType>(() => getTarget());
    const [templateInfo, setTemplateInfo] = useState<optionsType>(() => getTemplateInfo());

    const [expanded, setExpanded] = useState<"source" | "target" | boolean>("source");

    useEffect(() => {
        if (!Source) {
            setExpanded("source");
        } else if (Source && !Target) {
            setExpanded("target");
        } else {
            setExpanded(false);
        }
    }, []);

    useEffect(() => {
        createAppStore.clearStore();
    }, []);

    const setParams = (params: URLSearchParams, key: string, value: string): void => {
        if (!value) {
            params.delete(key);
        } else {
            params.set(key, value);
        }
    };

    function handleChange(value: adapterType | optionsType, cb: (adapterType) => void, type: string): void {
        const setTemplateToDefault = (params: URLSearchParams) => {
            setTemplateInfo(null);
            setParams(params, "template", null);
        };
        setSearchParams((params: URLSearchParams) => {
            if (type === "source") {
                cb(value);
                setParams(params, "source", value?.value);
                Target && setParams(params, "target", null);
                setTemplateToDefault(params);
                Target && setTarget(null);
            } else if (type === "target") {
                setParams(params, type, value?.value);
                setTemplateToDefault(params);
                cb(value);
            } else if (type === "template") {
                setParams(params, type, value?.value);
                createAppStore?.setSelectedAppType(value?.value);
                cb(value);
            }
            return params;
        });
    }

    return (
        <Grid container alignItems="center" justifyContent="center" flexDirection="column">
            <CreateAppPageTemplateHeader hideImage={true} headerInfo={createAppInfo.headerInfo} />
            <Grid container flexDirection="column" gap={1} alignItems="center" width="auto">
                <CreateAppPageAdaptersSelection
                    type="source"
                    adapter={Source}
                    setAdapter={setSource}
                    expanded={expanded === "source"}
                    setExpanded={setExpanded}
                    handleChange={handleChange}
                    isFirstTimeUser={isFirstTimeUser}
                    licenseChangesInfo={createAppInfo}
                />
                <CreateAppPageAdaptersSelection
                    type="target"
                    sourceAdapter={Source}
                    adapter={Target}
                    setAdapter={setTarget}
                    disabled={Source === null}
                    expanded={expanded === "target"}
                    setExpanded={setExpanded}
                    handleChange={handleChange}
                    isFirstTimeUser={isFirstTimeUser}
                    licenseChangesInfo={createAppInfo}
                />
            </Grid>
            <Grid mt={2}>
                {Source && Target && (
                    <CreateAppTypeDropdownComponent
                        sourceAdapter={Source}
                        targetAdapter={Target}
                        templateInfo={templateInfo}
                        setTemplateInfo={setTemplateInfo}
                        handleChange={handleChange}
                    />
                )}
                <CreateAppPageGetStartedButton
                    sourceAdapter={Source}
                    targetAdapter={Target}
                    templateInfo={templateInfo}
                />
                {!createAppInfo?.hideCustomFlow && <CreateAppPageCustomFlow />}
            </Grid>
        </Grid>
    );
};
export default CreateAppUsingTemplates;
