import { action } from "mobx";
import GroupModel from "./group-model";
import AppGroupStore from "./app-groups-store";
class FakeAppGroupStore extends AppGroupStore {
    @action async fetch() {}

    @action
    delete = async groupName => {
        const group = this.groups.filter(group => group.name === groupName)[0];
        if (!group) {
            return;
        }
        const groupIndex = this.groups.indexOf(group);
        this.groups.splice(groupIndex, 1);
    };

    @action
    validationApiCall = async groupName => {
        const group = this.groups.filter(group => group.name === groupName)[0];
        setTimeout(() => {
            group.validations.forEach((validation, index) => {
                validation.state = index % 2 == 0 ? "success" : "error";
            });
        }, 2000);
    };

    @action
    toggleCollapsed = () => {};

    @action
    edit(oldName, newName, newDescription) {
        const group = this.groups.filter(x => x.name === oldName)[0];
        setTimeout(() => {
            group.name = newName.replace(new RegExp(" ", "g"), "_");
            group.description = newDescription.replace(new RegExp(" ", "g"), "_");
        }, 500);
    }
}

function getGroup(idx) {
    const group = new GroupModel();
    group.name = "Group " + idx;
    group.description =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus orci orci, ullamcorper cursus mi tempus, porttitor luctus nunc.";
    group.collapsed = false;
    group.orderNumber = idx;
    group.applications = [];
    group.validations = [];
    return group;
}

export const createGroupStore = fakeAppListStore => {
    var store = new FakeAppGroupStore();

    store._appGroupService = {
        assign: async () => {},
        moveUp: async () => {},
        moveDown: async () => {}
    };

    store.groups = [getGroup(1), getGroup(2), getGroup(3), getGroup(4)];

    fakeAppListStore.apps.forEach((app, idx) => {
        store.groups[idx % store.groups.length].applications.push(app.id);
    });

    store.groups[1].validations = [
        { name: "kafka", state: "success" },
        { name: "postgres", state: "error" }
    ];
    store.groups[1].validationEnabled = true;

    store.groups.unshift(getGroup(5));

    return store;
};
