const config = {
    time: {
        homepageIntervalMontoringData: 300_000,
        appsPageIntervalMontoringData: 60_000,
        silentBillingPageDataUpdateInterval: 60_000
    },
    commonStyles: {
        allAppsCardsMargin: 10 //px
    }
};

export default config;
