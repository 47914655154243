import AdaptersData from "../../../../resources/adapter-names.json";
import PredefinedComponentNames from "../../../../app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";

let iconsSpriteIDMapping: spriteIDIconMapping;
let adapterInfo: adapterInfo;
const iconSize: number = 112;

interface adapterSpriteInfo {
    Category: string;
    Component: keyof typeof PredefinedComponentNames;
    "Image ID": string;
    "Newly Released": string;
    "Sprite ID": string;
    Type: "SOURCE" | "TARGET";
}
interface spriteIDIconMapping {
    [id: string]: adapterSpriteInfo["Sprite ID"];
}
interface adapterInfo {
    [component: string]: adapterSpriteInfo;
}

export function getSpriteData(): adapterSpriteInfo[] {
    return Object.values(AdaptersData) as adapterSpriteInfo[];
}

function getNativeIconsSpriteIDMapping(): spriteIDIconMapping {
    if (iconsSpriteIDMapping) {
        return iconsSpriteIDMapping;
    }
    const iconsDataArr: adapterSpriteInfo[] = getSpriteData();
    let data: spriteIDIconMapping = {};
    iconsDataArr.forEach((iconData: adapterSpriteInfo) => (data[iconData["Image ID"]] = iconData["Sprite ID"]));
    return data as spriteIDIconMapping;
}

export function getAdapterData(): adapterInfo {
    return AdaptersData as adapterInfo;
}

function getAlphabetValue(letter: String): number {
    return letter.charCodeAt(0) - 64;
}
export interface position {
    x: number;
    y: number;
}
export function getIconSpritePosition(name: string, spriteIdProp?: string): position {
    let spriteID: string = spriteIdProp;

    if (!spriteIdProp) {
        const iconsData = getNativeIconsSpriteIDMapping();
        spriteID = iconsData[name];
    }

    const columnNumber = getAlphabetValue(spriteID[0]);
    const rowNumber = spriteID[1];
    return {
        x: ((columnNumber - 1) * iconSize) / 2,
        y: ((rowNumber - 1) * iconSize) / 2
    };
}
