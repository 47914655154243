import App from "app";
import WAHighChartsMixin from "app/components/dashboard/visualization/charts/predictivechart/mixins/predictivechart-highcharts";
import "app/components/dashboard/visualization/charts/chart";

App.module("Chart.PredictiveChart", function (PredictiveChart, App, Backbone, Marionette, $, _) {
    PredictiveChart.View = App.Chart.Default.ChartView.extend({
        initialize: function () {
            // Mixin the Highcharts line chart
            this.predictiveType = "realTime";
            _(this).extend(WAHighChartsMixin);
            // Call super initialize
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
            var that = this;
            this.on("change:graph", function (d) {
                this.$el.fadeTo("fast", 0.4).delay(2000).fadeTo("fast", 1); //hack alert. I should probably listen to an event and trigger this.
                that.predictiveType = d;
                that.render();
            });
        },
    });
});
