import React, { useMemo } from "react";
import { Box, Grid, SvgIcon } from "@mui/material";
import { StriimDataTable, StriimTypography, StriimTooltip, StriimLink } from "@striim/striim-ui";

import { AppNameContainer, SourceTarget } from "../../../components/select-tables-component/select-tables-component";
import { ErrorCross, WarningOutline } from "../../../../../generic/icon/customIcons";
import { styles } from "./data-identifier-apps-table.styles";
import WithTooltip from "../../../../../generic/tooltip/tooltip";
import InfoIcon from "app/images/striimline/info-icon.svg";
import navigateTo from "../../../../../navigate-to";
import { JobStatuses } from "../../guardian-job.types";
import dictionary from "../../../../../../app/components/common/helpable/online-help-dictionary";

const availableCellWidth = 210; // 300(width) - 46(padding) - 44 (warning icon)

const CustomHeader = props => {
    const { columnHeader } = props;
    return (
        <Grid container gap={0.5}>
            {columnHeader}
            {WithTooltip(
                <SvgIcon component={InfoIcon} sx={styles.iconInfo} />,
                <>
                    Entities discovered with sensitive data out of the total number of entities sampled.
                    <StriimLink href={dictionary.get()["AI_INSIGHTS_SHERLOCK"].href} target="_blank" sx={styles.link}>
                        Learn more
                    </StriimLink>
                </>
            )}
        </Grid>
    );
};

interface EntityBarProps {
    completed: number;
    maxValue: number;
    hasError?: boolean;
}

const EntityBar = ({ completed, maxValue, hasError = false }: EntityBarProps) => {
    const percentage = (completed / maxValue) * 100;
    const width = percentage === 100 ? availableCellWidth : (percentage * availableCellWidth) / 100;
    const hasSpacingForLabel = width >= 22;
    return (
        <Grid container justifyContent="left" gap={0.5} alignItems="center" padding="10px 10px 10px 0">
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                pr={width ? 0.5 : 0}
                sx={styles.bar}
                width={width}
            >
                {hasSpacingForLabel && (
                    <StriimTypography variant="caption4" color="greyscale.50">
                        {completed}
                    </StriimTypography>
                )}
            </Box>
            {!hasSpacingForLabel && (
                <StriimTypography variant="caption3" color="#7E7ED6">
                    {completed}
                </StriimTypography>
            )}
            {hasError && (
                <StriimTooltip title={"Striim encountered some errors during the discovery process in this App."}>
                    <Box lineHeight={0}>
                        <SvgIcon component={WarningOutline} sx={styles.warningIcon} />
                    </Box>
                </StriimTooltip>
            )}
        </Grid>
    );
};

interface DataIdentifierAppsTableInterface {
    tableData: AppProps[];
    jobName: string;
}

const DataIdentifierAppsTable = ({ tableData: data, jobName }: DataIdentifierAppsTableInterface) => {
    const tableData = data.sort((x, y) => {
        return y.entitiesWithSensitiveData?.completed - x.entitiesWithSensitiveData?.completed;
    });

    const tableColumns = useMemo(() => {
        const maxValue = tableData[0].entitiesWithSensitiveData?.completed || 0;
        return [
            {
                headerName: `App Name (${tableData.length})`,
                field: "name",
                flex: 1,
                suppressMovable: true,
                resizable: true,
                cellRenderer: params => (
                    <AppNameContainer
                        name={params.data.name}
                        status={params.data.flowStatus}
                        nsName={params.data.nsName}
                        onClick={appFQN => {
                            navigateTo.AppSherlock(appFQN, jobName, true);
                        }}
                        isAppAvailable={true}
                    />
                ),
                cellClass: "appNamecellClass",
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: "Namespace",
                field: "nsName",
                suppressMovable: true,
                resizable: true,
                cellRenderer: ({ value }) => (
                    <StriimTypography variant="body4" color="greyscale.700">
                        {value}
                    </StriimTypography>
                )
            },
            {
                headerName: "Source-Target",
                field: "sourcetarget",
                suppressMovable: true,
                resizable: true,
                headerClass: "header-center no-hover",
                cellClass: "cell-center",
                cellRenderer: SourceTarget
            },
            {
                headerComponent: CustomHeader,
                field: "entitiesWithSensitiveData",
                headerComponentParams: {
                    columnHeader: "# Entities w/ Sensitive Data "
                },
                width: 300,
                suppressMovable: true,
                resizable: false,
                cellRenderer: params => (
                    <EntityBar
                        maxValue={maxValue}
                        completed={params.value.completed}
                        hasError={params.data.jobStatus.status === JobStatuses.ERROR}
                    />
                )
            }
        ];
    }, [data, jobName]);

    return (
        <Box sx={styles.dataTableWrapper}>
            <StriimDataTable data={tableData} columns={tableColumns} />;
        </Box>
    );
};

export default DataIdentifierAppsTable;
