import metaStoreService from "../../core/services/metaStoreService/meta-store-service";
import api from "core/api/api";
export default class SourceTargetLoader {
    async loadForApp(app) {
        let appComponents = await api.getApplicationComponentsByTypes(
            app.id,
            [
                metaStoreService.entities.SOURCE,
                metaStoreService.entities.EXTERNALSOURCE,
                metaStoreService.entities.TARGET,
                metaStoreService.entities.WACTIONSTORE
            ],
            true
        );
        app.sources = appComponents.filter(x => x.type === metaStoreService.entities.SOURCE || x.type === metaStoreService.entities.EXTERNALSOURCE);
        app.targets = appComponents.filter(
            x => x.type === metaStoreService.entities.TARGET || x.type === metaStoreService.entities.WACTIONSTORE
        );
    }
}
