import React, { useState } from "react";
import { StriimTypography, StriimButton, StriimToast } from "@striim/striim-ui";
import { Grid } from "@mui/material";

import api from "core/api/api";
import { styles } from "./sso-container.styles";
import { ssoLabels } from "./sso.common";
import DeleteModal from "./sso-delete-modal";
import { EditSsoIcon, DeleteIcon } from "../../../../../../../generic/icon/customIcons";
import { getSSOProperties } from "../../users-page";

const SsoConfiguration = ({ ssoProperties, setAddSso, setEdit, setSsoProperties, setEditValues }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const configLabels = ssoLabels.find(option => option.idp === ssoProperties.idp);
    const orderTable = ["idp", "applicationID", "certPath", "loginURL"];

    const toggleDeleteModal = () => setShowDeleteModal(prevState => !prevState);
    const editSsoConfig = () => {
        setEditValues({ ...ssoProperties });
        setEdit(true);
    };
    const handleDeleteSSO = () => {
        deleteSSO();
        toggleDeleteModal();
    };
    const deleteSSO = async () => {
        try {
            const deleteResponse = await api.deleteSSO();

            if (deleteResponse) {
                getSSOProperties(setSsoProperties);
                setAddSso(false);
                StriimToast({
                    message: "Single Sign On deleted",
                    severity: "success"
                });
            }
        } catch (e) {
            console.error(e);
            StriimToast({
                message: "Something went wrong",
                severity: "error"
            });
        }
    };

    const getLabel = key => (key === "idp" ? configLabels["idpLabel"] : configLabels[key]);

    return (
        <>
            <Grid container sx={styles.wrapperRoot}>
                <Grid container sx={styles.ssoConfigHeading}>
                    <Grid item>
                        <StriimTypography variant="h3">Single Sign On</StriimTypography>
                    </Grid>
                    <Grid item>
                        <StriimButton
                            variant="primaryText"
                            startIcon={<EditSsoIcon />}
                            sx={styles.configEditButton}
                            onClick={editSsoConfig}
                            data-test-id="sso-config-edit-button"
                        >
                            Edit SSO
                        </StriimButton>
                        <StriimButton
                            variant="errorText"
                            startIcon={<DeleteIcon />}
                            sx={styles.configDeleteButton}
                            onClick={toggleDeleteModal}
                            data-test-id="sso-config-delete-button"
                        >
                            Delete
                        </StriimButton>
                    </Grid>
                </Grid>

                <Grid container>
                    {orderTable.map((key, index) => (
                        <Grid item xs={12} sx={styles.configListingItem} key={index} data-test-id={`sso-row-${key}`}>
                            <Grid item xs={5}>
                                <StriimTypography variant="body" sx={styles.configListingName}>
                                    {getLabel(key)}
                                </StriimTypography>
                            </Grid>
                            <Grid item xs={6}>
                                <StriimTypography variant="body" sx={styles.configListingValue}>
                                    {ssoProperties[key]}
                                </StriimTypography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <DeleteModal
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={toggleDeleteModal}
                onDeleteConfirm={handleDeleteSSO}
            />
        </>
    );
};

export default SsoConfiguration;
