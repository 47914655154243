import React, { useMemo } from "react";
import { StriimDataTable } from "@striim/striim-ui";

const createColumns = unit => [
    {
        headerName: "Component",
        field: "componentName",
        flex: 1,
        suppressMovable: true,
        sortable: true
    },
    {
        headerName: "App",
        field: "appName",
        flex: 1,
        suppressMovable: true,
        sortable: true
    },
    {
        headerName: `Free IL Events (${unit})`,
        field: "ilConsumptionFree",
        flex: 1,
        suppressMovable: true,
        sortable: true,
        headerTooltip: `Free IL Events (${unit})`
    },
    {
        headerName: `Billed IL Events (${unit})`,
        field: "ilConsumptionBilled",
        flex: 1,
        suppressMovable: true,
        sortable: true,
        headerTooltip: `Billed IL Events (${unit})`
    },
    {
        headerName: `Non IL Events (${unit})`,
        field: "nonIL",
        valueGetter: params => params.data.nonIlConsumption,
        flex: 1,
        suppressMovable: true,
        sortable: true,
        headerTooltip: `Non IL Events (${unit})`
    },
    {
        headerName: `Total Consumption (${unit})`,
        field: "consumptionValue",
        flex: 1,
        suppressMovable: true,
        sortable: true,
        headerTooltip: `Total Consumption (${unit})`
    }
];

const ComponentTable = ({ data, consumptionUnit, ...props }) => {
    const [apiData, columns] = useMemo(() => {
        if (!data?.length) return [[], []];
        const unit = consumptionUnit || "in Million-Events";
        return [data, createColumns(unit)];
    }, [data, consumptionUnit]);
    return <StriimDataTable data={apiData} columns={columns} {...props} />;
};

export default ComponentTable;
