import CONSTANTS from "./resources";

export function containsSpaces(value) {
    return value && value.indexOf(" ") > -1;
}

export function containsSplChars(value) {
    const nonAlphanumericOrUnderscore = /\W/;
    return nonAlphanumericOrUnderscore.test(value);
}

export function isMuchLonger(name, namespace) {
    return (name + namespace + ".GROUP.").length > 254;
}

export function groupAlreadyExists(groupName, groupNames) {
    return groupNames.some(name => name.toLowerCase() === groupName.toLowerCase());
}
export const groupNameInvalid = (name, groups, namespace) => {
    if (!name) return false;
    const groupNames = groups.map(group => group.name);
    return (
        containsSpaces(name) ||
        containsSplChars(name) ||
        groupAlreadyExists(name, groupNames) ||
        groupStartsWithNumber(name) ||
        groupIsAll(name) ||
        isMuchLonger(name, namespace)
    );
};

export const getValidationMsg = (name, groups, namespace) => {
    if (!name) return "";
    if (groupStartsWithNumber(name)) {
        return CONSTANTS.VALIDATION_MSG.NO_NUMBER_FIRST_CHARACTER_ALLOWED;
    }
    if (isMuchLonger(name, namespace)) {
        return CONSTANTS.VALIDATION_MSG.NO_LONGER_STRINGS_ALLOWED;
    }
    if (containsSpaces(name)) {
        return CONSTANTS.VALIDATION_MSG.NO_SPACES_ALLOWED;
    } else if (containsSplChars(name)) {
        return CONSTANTS.VALIDATION_MSG.NO_SPL_CHARS_ALLOWED;
    }
    const groupNames = groups.map(group => group.name);
    if (groupAlreadyExists(name, groupNames) || groupIsAll(name)) {
        return CONSTANTS.VALIDATION_MSG.NO_DUPLICATES_ALLOWED;
    }
    return "";
};

const groupStartsWithNumber = str => {
    return /^\d/.test(str);
};

export const groupIsAll = name => {
    return name.toLowerCase() === "all";
};
