import React from "react";
import { useNavigate } from "react-router";
import { StriimButton, StriimTypography, StriimLink, StriimMessageBox, StriimGradientChip } from "@striim/striim-ui";
import { Grid, Box } from "@mui/material";

import { styles } from "../guardian.styles";
import AI_ROUTES from "../../routes.json";
import { Sherlock } from "../../../../generic/icon/customIcons";
import NewTab from "app/images/New Tab.svg";
import dictionary from "../../../../../app/components/common/helpable/online-help-dictionary";
import { StriimAIEngine } from "../../hooks/useStriimAIEngine";

interface GuardianEmptyInterface {
    alignText?: string;
    alignImage?: string;
    configured?: boolean;
    isAdmin?: boolean;
    striimAIEngine: StriimAIEngine;
}

const GuardianEmpty = ({
    alignText = "center",
    alignImage = "center",
    configured = true,
    isAdmin = false,
    striimAIEngine
}: GuardianEmptyInterface) => {
    const navigate = useNavigate();

    const gotToDiscoverApps = () => {
        navigate(AI_ROUTES.discoverApps);
    };

    const gotToSelectApps = () => {
        navigate(AI_ROUTES.selectApps);
    };

    return (
        <div>
            {striimAIEngine && striimAIEngine.isConfigured && !striimAIEngine.isConnected && (
                <StriimMessageBox
                    type="ERROR"
                    heading="Could not connect to Striim AI Service"
                    text={
                        <p>
                            AI Insights could not connect to Striim AI service. Please ensure that the AI service is up
                            and running. Click{" "}
                            <a
                                href={dictionary.get()["AI_INSIGHTS_ENGINE"].href}
                                target="_blank"
                                style={{ color: "white", textDecoration: "underline", fontWeight: "bold" }}
                            >
                                here
                            </a>{" "}
                            for simple steps for an initial easy configuration.
                            <pre>{striimAIEngine.connectivityErrorMessage}</pre>
                        </p>
                    }
                    sx={{ width: "50%" }}
                ></StriimMessageBox>
            )}

            <Grid container sx={styles.wrapper}>
                <Sherlock
                    style={{ ...styles.securityImage, alignSelf: alignImage === "left" ? "flex-start" : alignImage }}
                />

                <Grid container sx={{ ...styles.wrapperEmpty, alignItems: alignText }}>
                    <StriimGradientChip version="ai" />

                    <StriimTypography variant="h1" sx={styles.emptyTitle}>
                        Discover Sensitive Data in your Sources
                    </StriimTypography>

                    <StriimTypography
                        variant="body"
                        paragraph={true}
                        sx={{ ...styles.description, textAlign: alignText }}
                    >
                        Discover sensitive data identifiers that can flow through your apps by examining the sources,
                        enabling you to design the apps to meet your data governance policies.
                        <StriimLink
                            href={dictionary.get()["AI_INSIGHTS_SHERLOCK"].href}
                            target="_blank"
                            endIcon={<NewTab />}
                            sx={styles.notConfiguredMessageLink}
                        >
                            Learn more
                        </StriimLink>
                    </StriimTypography>

                    {configured ? (
                        <Box component="div" sx={styles.aiButtons}>
                            <StriimButton
                                variant="primary"
                                data-test-id="guardian-discover-apps"
                                onClick={gotToDiscoverApps}
                            >
                                Discover in All Apps
                            </StriimButton>

                            <StriimButton variant="text" data-test-id="guardian-select-apps" onClick={gotToSelectApps}>
                                Select Apps
                            </StriimButton>
                        </Box>
                    ) : null}
                </Grid>
            </Grid>
        </div>
    );
};

export default GuardianEmpty;
