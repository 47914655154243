// A base HighCharts mixin
import Backbone from "backbone";
import { tooltipStriimlineHighcharts } from "../../../../../styles/materialize/components-striimline/tooltip.export";

export default {
    createCollectionFromLegendData: function() {
        var collection = new Backbone.Collection();
        for (var key in this.chartConfig.legendData) {
            var newItem = {
                name: key,
                color: this.chartConfig.legendData[key]
            };
            collection.push(newItem);
        }
        return collection;
    },
    generateToolTipConfig: function (shared = false) {
        var generateToolTipRow = function(label, value) {
            return '<div><span style="font-weight: bold">' + label + ": </span><span>" + value + "</span></div>";
        };
        return {
            enabled: true,
            useHTML: true,
            shared,
            followPointer: true,
            formatter: function() {
                var content = "";
                var i;
                var tooltipConfig = this.point.tooltipConfig;
                if (!tooltipConfig) {
                    return false;
                }
                if (tooltipConfig.show_all) {
                    var length = this.point.fieldList.length;
                    for (i = 0; i < length; i++) {
                        var currentField = this.point.fieldList[i];
                        content =
                            content + generateToolTipRow(currentField.field, this.point.rowData[currentField.field]);
                    }
                } else {
                    for (i = 0; i < tooltipConfig.tooltip_values.length; i++) {
                        var tooltipValue = tooltipConfig.tooltip_values[i];
                        content =
                            content + generateToolTipRow(tooltipValue.label, this.point.rowData[tooltipValue.srcfield]);
                    }
                }
                // We don't want to return empty string, because empty tooltip will be shown. Return false disables the tooltip
                return content === "" ? false : content;
            },
            ...tooltipStriimlineHighcharts
        };
    }
};
