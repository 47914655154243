import App from "app";
import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import browser_template from "./samplebrowser.html";
import sample_template from "./sampletemplate.html";
import "app/components/common/_deprecated/form-mixin-dialog/dialog";

var sampleBrowser = {};

sampleBrowser.View = App.FormMixinDialog.View.extend({
    regions: {
        browserRegion: "#samplebrowser",
    },
    template: _.template(browser_template),

    ui: {
        submitButton: ".controls .submit",
    },

    initialize: function (options) {
        this.model = options.model;
        //options.context  = this.options.vismodel.toJSON();
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
        // Define what to do once the dialog form has successfully been saved.
        // In this case, update the vismodel, and close the dialog
        this.listenTo(this, "dialog-save", function (context) {
            this.options.vismodel.set(context);
            this.destroy();
        });
        this.browserView = new sampleBrowser.BrowserView({
            model: this.model,
        });

        this.listenTo(App.vent, "killModals", this.close);

        this.listenTo(
            this.model,
            "change:previewModel",
            function () {
                this.ui.submitButton.removeClass("disabled");
            }.bind(this)
        );

        // Hook up the submit button
        this.triggers["click .submit"] = "submit";
        this.delegateEvents();
        this.listenTo(this, "submit", this.submit);
        this.browserView._parent = this;
    },

    validate: function (/*data*/) {
        if (!this.model.get("previewModel")) {
            return ["Nothing selected"];
        }
        return [];
    },

    close: function () {
        this.destroy();
    },
});

sampleBrowser.BrowserView = Backbone.Marionette.ItemView.extend({
    id: "browserView",
    template: _.template(sample_template),
    events: {
        "click .sample_container": "handleClick",
        "dblclick .sample_container": "handleDoubleClick",
    },
    initialize: function (options) {
        this.model = options.model;
    },
    handleDoubleClick: function (e) {
        this.handleClick(e);
        this._parent.trigger("submit");
    },
    handleClick: function (e) {
        _.each($(".sample_container"), function (choice) {
            $(choice).removeClass("sampleSelected");
        });

        $(e.currentTarget).addClass("sampleSelected");
        var name = $(e.currentTarget).attr("data");
        var item = _.findWhere(this.model.get("samples"), {
            Name: name,
        });
        this.model.set("previewModel", item);
        this.model.set("previewPath", item.Path);
        this.model.set("fileType", "FileReader");
    },
});

sampleBrowser.Controller = App.FormMixinDialog.Controller.extend({
    initialize: function (options) {
        var self = this;
        this.view = options.view;
        this.model = options.model;
        App.FormMixinDialog.Controller.prototype.initialize.apply(this, arguments);

        var samplePromise = App.request("preview:samples");
        samplePromise.then(function(data) {
            self.model.set("samples", data.get("samples"));
            self.view.browserRegion.show(self.view.browserView);
        });
    },
});

export default sampleBrowser;
