import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Collapse, Grid, SvgIcon } from "@mui/material";
import { classes } from "./sentinel-panel-container.styles";
import {
    AITag,
    Delete,
    FileImport,
    NewTab,
    SentinelIcon,
    SidebarCollapse,
    SDIImportance as ImportanceHigh,
    SquareArrowDown as ImportanceLow,
    SeverityWarning as ImportanceMedium
} from "../../../../generic/icon/customIcons";
import {
    StriimGradientChip,
    StriimInputField,
    StriimLink,
    StriimTypography,
    StriimButton,
    StriimTooltip,
    StriimMessageBox,
    StriimFeaturePopover
} from "@striim/striim-ui";
import AutoFillEntitiesModal from "./sentinel-entity-create-action-modal";
import metaStoreService from "../../../../../core/services/metaStoreService/meta-store-service";
import { ExpandLessRounded, ExpandMoreRounded, Add } from "@mui/icons-material";
import sentinelService from "../sentinel-service";
import dateLib from "core/utils/date-utils";

import { DataIdentifierActions, UnconfiguredEngine, OutputStreamSelector, ActionsOnEntities } from "../components";
import { Entity } from "../components/actions-on-entities/components";
import DeleteAllEntitiesModal from "./sentinel-entity-delete-all-modal";
import growl from "../../../../../app/components/common/growl";
import { SentinelObject, StreamOption } from "../sentinel.types";
import LoadingIndicator from "../../../../generic/loading-indicator";
import appStatusSynchronizer from "../../../../../core/appStatusSynchronizer";
import { FlowStatus } from "../../../../status-management";
import { PIISettings, PIISettingsOptions } from "../../guardian/guardian-job.types";
import navigateTo from "../../../../navigate-to";
import guardianService from "../../guardian/guardian-service";
import { SENTINEL_ACTIONS } from "./sentinel-panel-consts";
import getSentinelMonitorService from "../../guardian/sentinel-monitor/service/sentinel-moniter-service";
import ConnectionProfileSelector from "../../../../generic/connection-profile-selector/connection-profile-selector";
import dictionary from "../../../../../app/components/common/helpable/online-help-dictionary";

type SentinelOptions = {
    identifiersMap: object;
    actionsMap: object;
};

export type ShieldEntityValidType = {
    isShieldProfileValid: boolean;
    isEntityNameValid: boolean;
};

export type TaggingLevel = {
    value: string;
    label: string;
    shortenedLabel: string;
    icon: React.ReactElement<SvgIcon>;
};

export const taggingOptionsWithIcons: TaggingLevel[] = [
    { value: "HIGH", label: "High Importance", shortenedLabel: "High", icon: <ImportanceHigh /> },
    { value: "MEDIUM", label: "Medium Importance", shortenedLabel: "Medium", icon: <ImportanceMedium /> },
    { value: "LOW", label: "Low Importance", shortenedLabel: "Low", icon: <ImportanceLow /> }
];

interface SentinelPanelProps {
    toggleFullScreen: Function;
    streams: StreamOption[];
    isSaved: boolean;
    sources: any;
    onCancel: Function;
    onDelete: Function;
    appName: string;
    nsName: string;
    metaObjectType: string;
    isAdmin: boolean;
    parentStream: string;
    savedSentinelObject?: SentinelObject;
    setSentinelFormDirty: Function;
    onSave: Function;
    validateOnSave: Function;
    flowStatus: string;
    entityDataFromSource: any;
    onViewActivityClick;
}

const emptyEntity: Entity = {
    name: "",
    fields: [{ name: "", action: { label: "", value: "" } }]
};

const errorMessages = {
    name: {
        required: "Value for name is required",
        duplicate: "This name already exists",
        invalidFormat: "Please enter a name with only alphanumeric or underscore characters.",
        noSpaces: "Please enter a name without spaces.",
        startsWithNumber: "Name cannot start with a number"
    },
    inputStream: {
        required: "Value for Input Stream is required"
    }
};

export const handleNameValidation = async (value: string, metaObjectType: string, nsName: string) => {
    if (!value) {
        return "required";
    }
    if (/\s/.test(value)) {
        return "noSpaces";
    }
    if (/\W/g.test(value)) {
        return "invalidFormat";
    }
    if (/^\d/.test(value)) {
        return "startsWithNumber";
    }
    const isDuplicate = await metaStoreService
        .findById(nsName + "." + metaObjectType + "." + value)
        .then(item => !!item);
    if (isDuplicate) {
        return "duplicate";
    }
    return false;
};

const SentinelPanel = ({
    toggleFullScreen,
    streams,
    onCancel,
    onDelete,
    sources,
    appName,
    nsName,
    metaObjectType,
    savedSentinelObject,
    isAdmin = true,
    parentStream,
    setSentinelFormDirty,
    onSave,
    validateOnSave,
    flowStatus,
    entityDataFromSource,
    isSaved,
    onViewActivityClick
}: SentinelPanelProps) => {
    const [showFeaturePopover, setShowFeaturePopover] = useState<boolean>(false);
    const [entityData, setEntityData] = useState([]);
    const [savedEntityData, setSavedEntityData] = useState([]);
    const [openStates, setOpenStates] = useState<Record<string, boolean>>({});
    const [isSidebarExpanded, setIsSidebarExpanded] = useState<boolean>(false);
    const [isAutoFillModalShown, setIsAutoFillModalShown] = useState(false);
    const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
    const [isDisabled, setIsDisabled] = useState(flowStatus !== FlowStatus.CREATED);
    const [isSingleSource, setIsSingleSource] = useState(sources?.length == 1);
    const [collapse, setCollapse] = useState({
        openDataIdentifiers: false,
        openEntities: false,
        openOutput: false,
        openEncryption: false
    });
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isError, setIsError] = useState({
        name: "",
        inputStream: ""
    });
    const [sentinelObject, setSentinelObject] = useState<SentinelObject | null>(savedSentinelObject);
    const [name, setName] = useState<string>(sentinelObject?.name ?? "");
    const currentInputStream = parentStream || sentinelObject?.inputStream;
    const [inputStream, setInputStream] = useState(
        currentInputStream
            ? {
                  label: currentInputStream.split(".").pop(),
                  value: currentInputStream
              }
            : null
    );

    const [piiSettings, setPIISettings] = useState<PIISettings>();
    const [tablesData, setTablesData] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [actionToggle, setActionToggle] = useState(Boolean(sentinelObject?.properties?.detectionMode));
    const [taggingLevels, setTaggingLevels] = useState(() => {
        const incomingLevels = sentinelObject?.properties?.taggingLevels || "";
        const levelsArray = incomingLevels.split(",");
        return taggingOptionsWithIcons.filter(option => levelsArray.includes(option.value));
    });
    const [tagEventsToggle, setTagEventsToggle] = useState(Boolean(sentinelObject?.properties?.taggingLevels?.length));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isActivityDataAvailable, setIsActivityDataAvailable] = useState<boolean>(false);

    const currentStreamOption = sentinelObject?.outputStream;
    const [streamOption, setStreamOption] = useState<{ label: string; value: string | number } | null>(
        currentStreamOption ? { label: currentStreamOption.split(".").pop(), value: currentStreamOption } : null
    );
    const [connectionProfile, setConnectionProfile] = useState<string | null>(
        sentinelObject ? sentinelObject.properties.encryptionProfile : null
    );
    const [sentinelOptions, setSentinelOptions] = useState<SentinelOptions | null>(null);
    const [partialMaskingSDIOptions, setPartialMaskingSDIOptions] = useState<string[]>([]);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    const [inputStreamOptions, setInputStreamOptions] = useState<StreamOption[]>([]);
    const [outputStreamOptions, setOutputStreamOptions] = useState<StreamOption[]>([]);
    const [showEncryptionSettings, setShowEncryptionSettings] = useState<boolean>(false);
    const [initialChanges, setInitialChanges] = useState({ rowData: [], entitiesData: [], actionToggle });
    const [isShieldOrEntityValid, setIsShieldOrEntityValid] = useState<ShieldEntityValidType>({
        isShieldProfileValid: true,
        isEntityNameValid: true
    });
    const [isOutputStreamValid, setIsOutputStreamValid] = useState<boolean>(true);
    const [outputStreamContinousValidity, setOutputStreamContinousValidity] = useState(Boolean(currentStreamOption));
    const [isNewSelected, setIsNewSelected] = useState(!streamOption);
    let componentRef = useRef<boolean>();

    const [entityNameErrors, setEntityNameErrors] = useState<Record<string, boolean>>({});

    useEffect(() => {
        appStatusSynchronizer.on("status_change", ({ newStatus }) => {
            setIsDisabled(newStatus !== FlowStatus.CREATED);
        });
        componentRef.current = true;
        (async () => {
            try {
                const sfp = await sentinelService.getShowFeaturePopover();
                setShowFeaturePopover(sfp);
                // Wait for PII settings to load
                const result = await sentinelService.getPIISettings();
                const parsedData: PIISettings = JSON.parse(result);
                setPIISettings(parsedData);
            } catch (error) {
                console.error("Error loading PII data:", error);
            }
        })();
        (async () => {
            try {
                if (!savedSentinelObject?.name) return;
                const currentTime = dateLib();
                let toTime = currentTime.valueOf();
                let fromTime = currentTime.subtract(1, "hour").valueOf();
                const previewData = await getSentinelMonitorService().getFlowDesignerPreview(
                    metaStoreService.entities.SENTINEL,
                    nsName,
                    savedSentinelObject?.name,
                    fromTime,
                    toTime
                );
                const isDataAvailable = !!(previewData && Object.keys(previewData)?.length);
                setIsActivityDataAvailable(isDataAvailable);
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    useEffect(() => {
        const loadData = async () => {
            try {
                const data = await sentinelService.getLabelMaps();
                const { sensitiveDataIdentifiers = {}, sdiConfig = {} } = data;
                //should this be gaurdian?
                const result = await guardianService.listPIISettingsOptions();
                const piiActions: object = JSON.parse(result).piiActions;
                const partialMaskingSDI = await sentinelService.getPartialMaskingSDIOptions();
                const partialMaskingSDIStrings = sentinelService.mapPartialMaskingSDIToString(
                    partialMaskingSDI,
                    sensitiveDataIdentifiers
                );
                setPartialMaskingSDIOptions(partialMaskingSDIStrings);
                let entitiesData = savedSentinelObject?.properties?.entityActions
                    ? sentinelService.parseEntitiesData(savedSentinelObject.properties.entityActions, piiActions)
                    : [];
                const entityTableData = savedSentinelObject?.properties?.entityActions
                    ? sentinelService.convertEntityActionsToTableData(
                          savedSentinelObject.properties.entityActions,
                          piiActions
                      )
                    : [];

                const allDefaultData = sentinelService.convertPIIActionsToTableData(
                    sensitiveDataIdentifiers,
                    piiActions,
                    sdiConfig
                );

                let tableData;
                if (savedSentinelObject?.properties?.typeActions) {
                    const typeActions = JSON.parse(savedSentinelObject?.properties?.typeActions);
                    let parsedItems = {};
                    Object.entries(typeActions).map(([key, value]) => {
                        parsedItems[key] = {
                            ...value,
                            importance: sdiConfig[key].importance,
                            actionConfig: { action: value?.action }
                        };
                    });
                    tableData = sentinelService.convertPIIActionsToTableData(
                        sensitiveDataIdentifiers,
                        piiActions,
                        parsedItems
                    );
                } else {
                    tableData = allDefaultData;
                }
                setTablesData(allDefaultData);

                //TODO: shouldn't be both NO_ACTION and DO_NOTHING
                const filteredRowData = tableData.filter(
                    id =>
                        id?.action?.value !== undefined &&
                        id.action.value !== SENTINEL_ACTIONS.DO_NOTHING &&
                        id.action.value !== SENTINEL_ACTIONS.NO_ACTION
                );
                setRowData(filteredRowData);

                if (!isDirty) {
                    setInitialChanges({
                        rowData: [...filteredRowData],
                        entitiesData: [...entitiesData],
                        actionToggle: JSON.parse(JSON.stringify(actionToggle))
                    });
                }

                setSentinelOptions({
                    identifiersMap: sensitiveDataIdentifiers,
                    actionsMap: piiActions
                });
                setEntityData(entityDataFromSource ?? entityTableData);
                setSavedEntityData(entityDataFromSource ?? entityTableData);
            } catch (error) {
                growl.error(error);
            }
        };
        if (piiSettings) {
            loadData();
        }
    }, [savedSentinelObject, piiSettings]);

    useEffect(() => {
        if (componentRef.current) {
            componentRef.current = false;
        } else {
            setIsDirty(true);
        }
    }, [name, inputStream, streamOption, connectionProfile]);

    useEffect(() => {
        let hasEncryption = false;
        entityData.some(entity => {
            entity.fields.some(field => {
                if (field?.action?.value?.toLowerCase() === "encrypt") {
                    hasEncryption = true;
                }
            });
        });
        rowData.some(data => {
            if (data?.action?.value?.toLowerCase() === "encrypt") {
                hasEncryption = true;
            }
        });
        setShowEncryptionSettings(hasEncryption);
    }, [rowData, entityData]);

    useEffect(() => {
        const compareChanges = () => {
            if (isDirty) return null;

            if (
                initialChanges.rowData.length !== rowData.length ||
                initialChanges.entitiesData.length !== entityData.length
            ) {
                setIsDirty(true);
                return null;
            }

            const [entityChanged, rowDataChanged] = sentinelService.hasRowOrEntityDataChanged(
                rowData,
                entityData,
                initialChanges
            );

            if (rowDataChanged || entityChanged) {
                setIsDirty(true);
            }
        };

        compareChanges();
    }, [rowData, entityData, actionToggle]);

    useEffect(() => {
        if (connectionProfile !== null) {
            handleOutputOrShieldErrorState("isShieldProfileValid", Boolean(connectionProfile));
        }
    }, [connectionProfile]);

    useEffect(() => {
        const checkValidity = async () => {
            let validState;
            if (streamOption !== null) {
                const nameError = await handleNameValidation(streamOption?.label, "STREAM", nsName);
                validState = nameError === false || (nameError === "duplicate" && !isNewSelected);
            } else {
                validState = false;
            }
            setOutputStreamContinousValidity(validState);
            // Handles case where user fixes a valdiity issue
            if (validState && !isOutputStreamValid) {
                setIsOutputStreamValid(true);
            }
        };
        checkValidity();
    }, [streamOption, outputStreamOptions]);

    useEffect(() => {
        setSentinelFormDirty(!isDisabled ? isDirty : false);
    }, [isDirty, isDisabled]);

    useEffect(() => {
        setInputStreamOptions(streams?.filter(option => option.value !== streamOption?.value));
        setOutputStreamOptions(streams?.filter(option => option.value !== inputStream?.value));
    }, [streamOption, inputStream]);

    const handleNameChange = async (value: string) => {
        setName(value);
        const errorType = await handleNameValidation(value, metaObjectType, nsName);
        handleIsError("name", errorType);
        setIsDirty(true);
    };

    const handleInputStreamChange = (value: any) => {
        setInputStream(value);
        handleIsError("inputStream", value === null ? "required" : "");
        setIsDirty(true);
    };

    const handleIsError = (field: string, errorType: string) => {
        setIsError(prevState => ({ ...prevState, [field]: errorType }));
    };

    const handleOutputOrShieldErrorState = (field: string, isValid: boolean) => {
        setIsShieldOrEntityValid(prevState => ({ ...prevState, [field]: isValid }));
    };

    const [errorsCount, setErrorsCount] = useState(0);

    const validateForm = () => {
        let isValid = true;
        let count = 0;
        let options: ShieldEntityValidType = {
            isShieldProfileValid: true,
            isEntityNameValid: true
        };
        const newEntityNameErrors: Record<string, boolean> = {};

        if (isError.name || name === "" || name === undefined) {
            handleIsError("name", "required");
            isValid = false;
            count++;
        }
        if (inputStream?.value === undefined) {
            handleIsError("inputStream", "required");
            isValid = false;
            count++;
        }
        if (!outputStreamContinousValidity) {
            setIsOutputStreamValid(false);
            handleCollapse("openOutput", true);
            isValid = false;
            count++;
        }
        if (showEncryptionSettings && !connectionProfile) {
            options.isShieldProfileValid = false;
            handleCollapse("openEncryption", true);
            isValid = false;
            count++;
        }

        entityData.forEach(entity => {
            if (!entity.name.trim()) {
                isValid = false;
                count++;
                newEntityNameErrors[entity.id] = true;
            }
            entity.fields.forEach(field => {
                if (!field.name) {
                    isValid = false;
                    count++;
                }
                if (!field.action || !field.action.value) {
                    isValid = false;
                    count++;
                }
            });
        });

        if (Object.keys(newEntityNameErrors).length) {
            handleCollapse("openEntities", true);
        }

        setEntityNameErrors(newEntityNameErrors);
        setIsShieldOrEntityValid(options);
        return {
            isValid: isValid && options.isShieldProfileValid && options.isEntityNameValid,
            count
        };
    };

    const handleCollapse = (row: string, value: any) => {
        setCollapse(prevState => ({ ...prevState, [row]: value }));
    };

    const saveSentinel = async () => {
        const { isValid, count } = validateForm();
        const formattedEntities = sentinelService.formatActionOnEntitiesData(entityData);
        const formattedSDIData = sentinelService.reverseTableData(rowData, sentinelOptions?.identifiersMap);
        const formattedTaggingLevels = tagEventsToggle ? taggingLevels.map(option => option.value).join(",") : "";
        setIsFormValid(isValid);
        setErrorsCount(count);
        if (isValid) {
            setIsLoading(true);
            setIsDirty(false);
            (async () => {
                try {
                    const updatedSentinelObj = await sentinelService.createUpdateSentinel(
                        sentinelObject?.id,
                        appName,
                        name,
                        nsName,
                        {
                            detectionMode: actionToggle,
                            taggingLevels: formattedTaggingLevels,
                            entityActions: JSON.stringify(formattedEntities),
                            typeActions: JSON.stringify(formattedSDIData),
                            encryptionProfile: connectionProfile
                        },
                        inputStream?.value,
                        streamOption?.value
                    );
                    setSentinelObject(updatedSentinelObj[0]);
                    growl.success("Saved " + name, "Component saved");
                    setIsLoading(false);
                    onSave(updatedSentinelObj[0]?.id, updatedSentinelObj[0]?.outputStream);
                    componentRef.current = true;
                } catch (error) {
                    growl.error(error);
                    setIsLoading(false);
                }
            })();
        } else {
            validateOnSave(sentinelObject?.id, count);
        }
    };

    const addEntity = e => {
        e.stopPropagation();
        const newEntityId = `entity-${entityData.length}`;
        const newEntity = { ...JSON.parse(JSON.stringify(emptyEntity)), id: newEntityId };
        setEntityData(prev => [...prev, newEntity]);
        handleCollapse("openEntities", true);
        setOpenStates(prev => ({ ...prev, [newEntityId]: false }));
    };

    const maskingOptions = useMemo(() => {
        return [
            { label: "Encrypt", value: SENTINEL_ACTIONS.ENCRYPT },
            { label: "Mask Completely", value: SENTINEL_ACTIONS.MASK_COMPLETELY },
            { label: "Mask Partially", value: SENTINEL_ACTIONS.MASK_PARTIALLY },
            { label: "Custom Mask", value: SENTINEL_ACTIONS.CUSTOM_MASK }
        ];
    }, []);

    const entitiesMaskingOptions = useMemo(() => {
        return [...maskingOptions, { label: "No Action", value: SENTINEL_ACTIONS.NO_ACTION }];
    }, [maskingOptions]);

    const handleActionToggle = value => {
        setActionToggle(value);
        setIsDirty(true);
    };

    const handleTagEventsToggle = value => {
        setTagEventsToggle(value);
        setIsDirty(true);
    };
    return (
        <Grid
            container
            width={"100%"}
            height={"100%"}
            flexWrap={"nowrap"}
            overflow="auto"
            flexGrow={1}
            sx={classes.border}
            flexDirection={"column"}
            data-test-id="sidebar"
        >
            <Grid container>
                <Box
                    width={"100%"}
                    sx={classes.borderBottom}
                    display={"flex"}
                    gap={1}
                    padding={2}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Box display={"flex"} gap={1}>
                        <SvgIcon component={SentinelIcon} sx={classes.icon}></SvgIcon>
                        <StriimTypography
                            variant="h3"
                            color={"primary.700"}
                            width={!isSidebarExpanded ? 240 : "inherit"}
                            sx={classes.panelHeader}
                        >
                            {sentinelObject?.name ? name : "New Sentinel"}
                        </StriimTypography>
                    </Box>
                    <StriimButton
                        variant="text"
                        startIcon={<SvgIcon component={SidebarCollapse} sx={classes.startIcon} />}
                        sx={{ ...classes.iconButton, ...(isSidebarExpanded && classes.expanded) }}
                        onClick={() => {
                            setIsSidebarExpanded(value => !value);
                            toggleFullScreen();
                        }}
                    />
                    {showFeaturePopover && (
                        <Box sx={{ position: "absolute", zIndex: 1000, top: "50px" }}>
                            <StriimFeaturePopover
                                content="Click the button to expand the side panel and configure your component in full-browser mode."
                                handleClose={async () => {
                                    await sentinelService.closeFeaturePopover();
                                    setShowFeaturePopover(false);
                                }}
                            />
                        </Box>
                    )}
                </Box>
                <Box padding={2} display={"flex"} flexDirection={"column"} sx={classes.borderBottom}>
                    {!isFormValid && (
                        <Box sx={classes.errorBox} data-test-id="error-box">
                            <StriimMessageBox
                                text={
                                    <StriimTypography variant="body4" data-test-id="error-box-message">
                                        {errorsCount + ` error${errorsCount > 1 ? "s" : ""} found in the configuration`}
                                    </StriimTypography>
                                }
                                type="ERROR"
                            />
                        </Box>
                    )}
                    <StriimTypography variant="caption3" color={"greyscale.700"} fontFamily={"Inter"}>
                        Detect and protect sensitive data in an input stream based on data identifiers or field names.
                        <StriimLink
                            endIcon={<NewTab style={classes.newTabIcon} />}
                            sx={classes.learnLink}
                            href={dictionary.get()["AI_INSIGHTS_SENTINEL"].href}
                        >
                            <StriimTypography color="inherit" variant="caption3">
                                Learn more
                            </StriimTypography>
                        </StriimLink>
                    </StriimTypography>
                </Box>
                <Box sx={classes.borderBottom} padding={2} width="100%" hidden={!isSaved}>
                    <StriimTooltip
                        title={
                            !isActivityDataAvailable
                                ? "No activity data is available. This may be due to the app not running or no events flowing through the Sentinel in the last 24 hours."
                                : ""
                        }
                    >
                        <span>
                            <StriimButton
                                variant="secondary"
                                disabled={!isActivityDataAvailable}
                                onClick={() => {
                                    onViewActivityClick(nsName + "." + sentinelObject?.name);
                                }}
                            >
                                View Activity
                            </StriimButton>
                        </span>
                    </StriimTooltip>
                </Box>
            </Grid>
            <Grid container mb={8.6}>
                <Box
                    width={"100%"}
                    padding={2}
                    gap={2}
                    display={"flex"}
                    flexDirection={"column"}
                    sx={classes.borderBottom}
                >
                    {!sentinelObject?.name && (
                        <StriimInputField
                            name={"name"}
                            label={"Name"}
                            autoFocus
                            sx={{ maxWidth: 407 }}
                            value={name}
                            onChange={value => handleNameChange(value)}
                            error={isError.name}
                            helperText={isError.name && errorMessages.name[isError.name]}
                            required
                            disabled={isDisabled}
                        />
                    )}
                    <StriimInputField
                        dataTestId="input-stream-select"
                        name={"inputStream"}
                        label={"Input Stream"}
                        required
                        select
                        value={inputStream}
                        SelectProps={{
                            options: inputStreamOptions,
                            components: {
                                IndicatorSeparator: () => null
                            }
                        }}
                        onChange={value => handleInputStreamChange(value)}
                        error={isError.inputStream}
                        helperText={isError.inputStream && errorMessages.inputStream.required}
                        placeholder={"Select"}
                        sx={{ maxWidth: 407 }}
                        disabled={isDisabled}
                    />
                </Box>
                <Box width={"100%"}>
                    <StriimTypography
                        variant="h4"
                        color="primary.700"
                        sx={[classes.header, classes.borderBottom]}
                        onClick={() => handleCollapse("openDataIdentifiers", !collapse.openDataIdentifiers)}
                        gap={1}
                    >
                        {collapse.openDataIdentifiers ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                        Actions on Data Identifiers
                        {isSidebarExpanded ? (
                            <StriimGradientChip version="ai" />
                        ) : (
                            <SvgIcon sx={{ width: 24, height: 18 }}>
                                <AITag />
                            </SvgIcon>
                        )}
                    </StriimTypography>

                    <Collapse in={collapse.openDataIdentifiers} sx={classes.borderBottom}>
                        {piiSettings?.aiEngine?.trim() ? (
                            <DataIdentifierActions
                                actionToggle={actionToggle}
                                setActionToggle={handleActionToggle}
                                tagEventsToggle={tagEventsToggle}
                                setTagEventsToggle={handleTagEventsToggle}
                                isDisabled={isDisabled}
                                isExpanded={isSidebarExpanded}
                                tablesData={tablesData}
                                rowData={rowData}
                                setRowData={setRowData}
                                maskingOptions={maskingOptions}
                                taggingLevels={taggingLevels}
                                setTaggingLevels={setTaggingLevels}
                                partialMaskingSDIOptions={partialMaskingSDIOptions}
                                setIsDirty={setIsDirty}
                            />
                        ) : (
                            <UnconfiguredEngine isAdmin={isAdmin} />
                        )}
                    </Collapse>
                </Box>
                <Box width={"100%"} sx={classes.borderBottom}>
                    <StriimTypography
                        variant="h4"
                        color="primary.700"
                        sx={[classes.header, isSidebarExpanded ? { maxWidth: 1260 } : { maxWidth: "100%" }]}
                        onClick={() => handleCollapse("openEntities", !collapse.openEntities)}
                        justifyContent={"space-between"}
                    >
                        <Box display={"flex"} gap={1} alignItems={"center"}>
                            {collapse.openEntities ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                            Actions on Fields
                        </Box>
                        {!isDisabled && (
                            <Box display={"flex"} gap={1} alignItems={"center"} sx={classes.entitiesIcon}>
                                <SvgIcon onClick={e => addEntity(e)}>
                                    <Add />
                                </SvgIcon>
                                {/* auto-fill is not supported in the 5.0.2 release so we are temporarily disabling it heres */}
                                {/* <StriimTooltip
                                    title={
                                        !isSingleSource
                                            ? "Auto-filling using the discovery reports is only supported for sentinels receiving input directly from a single source"
                                            : ""
                                    }
                                >
                                    <SvgIcon
                                        sx={{
                                            ...classes.customIcon,
                                            opacity: !isSingleSource ? "50%" : "inherit",
                                            cursor: !isSingleSource ? "not-allowed" : "pointer"
                                        }}
                                        onClick={e => {
                                            if (isSingleSource) {
                                                e.stopPropagation();
                                                setIsAutoFillModalShown(true);
                                            }
                                        }}
                                    >
                                        <FileImport />
                                    </SvgIcon>
                                </StriimTooltip> */}
                                <SvgIcon
                                    id="delete-action-on-fields"
                                    sx={classes.customIcon}
                                    onClick={e => {
                                        if (entityData.length > 0) {
                                            e.stopPropagation;
                                            setIsDeleteModalShown(true);
                                        }
                                    }}
                                >
                                    <Delete />
                                </SvgIcon>
                            </Box>
                        )}
                    </StriimTypography>

                    <Collapse in={collapse.openEntities} sx={classes.borderTop}>
                        <ActionsOnEntities
                            entityData={entityData}
                            setEntityData={setEntityData}
                            savedEntityData={savedEntityData}
                            isDisabled={isDisabled}
                            isSidebarExpanded={isSidebarExpanded}
                            addEntity={addEntity}
                            openStates={openStates}
                            setOpenStates={setOpenStates}
                            maskingOptions={entitiesMaskingOptions}
                            setIsOutputShieldOrEntityValid={setIsShieldOrEntityValid}
                            entityNameErrors={entityNameErrors}
                            setEntityNameErrors={setEntityNameErrors}
                        />
                    </Collapse>
                </Box>
                {showEncryptionSettings && (
                    <Box width={"100%"}>
                        <StriimTypography
                            variant="h4"
                            color="primary.700"
                            sx={[classes.header, classes.borderBottom]}
                            onClick={() => handleCollapse("openEncryption", !collapse.openEncryption)}
                            gap={1}
                        >
                            {collapse.openEncryption ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                            Encryption settings
                        </StriimTypography>

                        <Collapse in={collapse.openEncryption} sx={classes.borderBottom}>
                            <Box sx={{ display: "grid", p: 2, backgroundColor: "greyscale.50" }}>
                                <ConnectionProfileSelector
                                    supportingConnectionProfiles={"[GoogleKMS]"}
                                    value={connectionProfile}
                                    enabled={true}
                                    useForm={false}
                                    label={"Connection profile to use for encryption"}
                                    onChange={val => setConnectionProfile(val)}
                                    required={true}
                                    error={!isShieldOrEntityValid?.isShieldProfileValid}
                                    errorText={"Value for Connection Profile is required"}
                                />
                            </Box>
                        </Collapse>
                    </Box>
                )}
                <Box width={"100%"}>
                    <StriimTypography
                        variant="h4"
                        color="primary.700"
                        sx={[classes.header, classes.borderBottom]}
                        onClick={() => handleCollapse("openOutput", !collapse.openOutput)}
                        gap={1}
                    >
                        {collapse.openOutput ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                        Output
                    </StriimTypography>

                    <Collapse in={collapse.openOutput} sx={classes.borderBottom}>
                        <OutputStreamSelector
                            streams={outputStreamOptions}
                            streamOption={streamOption}
                            setStreamOption={setStreamOption}
                            isDisabled={isDisabled}
                            nsName={nsName}
                            isValid={isOutputStreamValid}
                            isNewSelected={isNewSelected}
                            setIsNewSelected={setIsNewSelected}
                        />
                    </Collapse>
                </Box>
                {!isDisabled && (
                    <Grid mt={"auto"} sx={classes.footer}>
                        {isLoading && <LoadingIndicator isGlobal={false} />}
                        <Grid
                            container
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            p={2}
                            sx={[classes.borderTop, classes.entitiesIcon]}
                        >
                            <SvgIcon sx={[classes.customIcon, classes.mousePointer]} onClick={() => onDelete()}>
                                <Delete />
                            </SvgIcon>
                            <Grid container width={"auto"} gap={0.5}>
                                <StriimButton variant="text" onClick={() => onCancel()} data-test-id="cancel-btn">
                                    Cancel
                                </StriimButton>
                                <StriimButton variant="primary" disabled={!isDirty} onClick={() => saveSentinel()}>
                                    Save
                                </StriimButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            {isAutoFillModalShown && (
                <AutoFillEntitiesModal sources={sources} setIsModalShown={setIsAutoFillModalShown} />
            )}
            {isDeleteModalShown && (
                <DeleteAllEntitiesModal setIsModalShown={setIsDeleteModalShown} onConfirm={() => setEntityData([])} />
            )}
        </Grid>
    );
};

export default SentinelPanel;
