import React, { useState } from "react";
import { StriimGradientChip, StriimTypography, StriimMessageBox, StriimLink } from "@striim/striim-ui";
import { Box } from "@mui/material";
import styles from "../data-identifier-actions.styles";
import { NewTab } from "../../../../../../generic/icon/customIcons";
import AI_ROUTES from "../../../../routes.json";

const UnconfiguredEngine = ({ isAdmin }) => {
    return (
        <Box gap={2} padding={2} display={"grid"} backgroundColor={"greyscale.50"}>
            <Box width={"167px"}>
                <StriimGradientChip version="ai" />
            </Box>
            <StriimTypography variant="body4" color="greyscale.700">
                {
                    "Configure the default AI Engine to start discovering over 20 sensitive data identifiers that can flow through your app in real-time, allowing you to manage it according to your governance policies."
                }
            </StriimTypography>
            <StriimMessageBox
                customCardStyles={styles.messageBoxHeader}
                text={
                    <StriimTypography variant="caption3" color="greyscale.800">
                        Enabling may impact the throughput.
                    </StriimTypography>
                }
                type="NOTIFICATION"
            />
            <StriimLink
                endIcon={<NewTab />}
                sx={{ width: "fit-content", p: 0 }}
                href={isAdmin ? `#${AI_ROUTES.sherlockSettings}` : "#"}
            >
                <StriimTypography color="inherit" variant="body3">
                    {isAdmin ? "Configure AI Engine" : "Instructions"}
                </StriimTypography>
            </StriimLink>
        </Box>
    );
};

export default UnconfiguredEngine;
