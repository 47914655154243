export const circularStyles = {
    loaderBG: {
        color: "greyscale.200"
    },
    successIcon: {
        color: "success.main"
    },
    errorIcon: {
        color: "critical.main"
    },
    warningIcon: {
        color: "warning.main"
    },
    circle: {
        strokeDasharray: "25px, 200px"
    },
    root: {
        left: 0,
        position: "absolute",
        color: "secondary.main"
    },
    wrapper: {
        display: "flex",
        alignItems: "center",
        position: "relative"
    }
};
