import React, { useEffect, useMemo, useRef, useState } from "react";
import { Divider, Grid } from "@mui/material";

import growl from "../../../../app/components/common/growl";
import LoadingIndicator from "../../../generic/loading-indicator";
import PanelHeader from "./components/panel-header/panel-header";
import EventsProcessedChart from "./components/events-processed-chart/events-processed-chart";
import EventsTaggedInfo from "./components/events-tagged-info/events-tagged-info";
import SDIChart from "./components/sdi-chart/sdi-chart";
import PanelHeaderControls from "./components/panel-header-controls/panel-header-controls";
import getSentinelMonitorService from "../guardian/sentinel-monitor/service/sentinel-moniter-service";
import metaStoreService from "../../../../core/services/metaStoreService/meta-store-service";
import {
    ActiveSentinelsEventsProcessed,
    AllSentinelsEventsProcessed,
    AllSentinelsSDIDrillDownEvents,
    AllSentinelsSDIDrillDownEventsChartData,
    EventsProcessedChartData,
    FlowDesignerPreview,
    AppSDIDrillDownFindings,
    SensitiveDataOccurrences,
    SensitiveDataOccurrencesChartData,
    SentinelsSDIDrillDownEvents,
    SentinelState,
    SDIDrillDownFindingsChartData,
    SentinelSDIDrillDownFindings
} from "../guardian/sentinel-monitor/service/sentinel-monitor.types";
import SentinelTabs, { prepareTabsData } from "./components/sentinel-tabs/sentinel-tabs";
import EventsProcessedContent from "./components/events-processed-content/events-processed-content";
import OccurrencesContent from "./components/occurrences-content/occurrences-content";
import {
    getEntityTypeAndName,
    getSDIChartDataParsed,
    getStartAndEndTimestamps,
    getStartAndEndTimestampsForChart,
    getTabsDataParsed,
    getTimeFormat,
    ImportanceLevels,
    pollingFn
} from "./utils";
import dateLib from "core/utils/date-utils";
import ViewSettingsSection from "./components/view-settings-section/view-settings-section";
import navigateTo from "../../../navigate-to";
import SentinelAIEmpty from "../guardian/sentinel-monitor/components/sentinel-ai-empty-component/sentinel-ai-empty-component";
import { FlowStatus } from "../../../status-management";
import AppStopped from "./components/app-stopped-component/app-stopped-component";
import appStatusSynchronizer from "../../../../core/appStatusSynchronizer";
import SensitiveDataActionsChart from "./components/sensitive-data-actions-chart/sensitive-data-actions";
import { downloadSentinelCSVReport, fetchSentinelAppReportData } from "../reports/reports.helper";

export const TABS = ["1 HOUR", "24 HOURS"];

export type EventsProcessedData = {
    allSentinel?: AllSentinelsEventsProcessed | null;
    activeSentinel?: ActiveSentinelsEventsProcessed | null;
};
//TODO: Remove below
// window.useFakeMonitorService = true;
const sentinelMonitorService = getSentinelMonitorService();

const POLL_INTERVAL = 60_000;

export type SelectedSensitiveDataIdentifier = { name: string; importanceLevel: ImportanceLevels; value: string };

export type SentinelMenuItem = { label: string; value: string };

type SelectedDateRange = {
    from: number;
    to: number;
};

interface SentinelLiveMonitorProps {
    defaultSentinelSelected: string | null;
    sentinelsInApp: any[];
    flowStatus: string;
    appName: string;
    namespace: string;
    onToggleFullScreen: Function;
    onClose: Function;
    onViewSettingsClick: Function;
}

export const ALL_SENTINELS_LABEL = "All";

const SentinelLiveMonitor: React.FC<SentinelLiveMonitorProps> = ({
    defaultSentinelSelected,
    sentinelsInApp,
    flowStatus,
    appName,
    namespace,
    onToggleFullScreen,
    onClose,
    onViewSettingsClick
}) => {
    const [isPanelExpanded, setIsPanelExpanded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [isAppStopped, setIsAppStopped] = useState<boolean>(flowStatus !== FlowStatus.RUNNING);
    const [appStatus, setAppStatus] = useState<string>(flowStatus);
    const [selectedTab, setSelectedTab] = useState<string>(TABS[0]);
    const [previewData, setPreviewData] = useState<FlowDesignerPreview>();
    const [sentinels, setSentinels] = useState<SentinelState[]>();
    const [selectedSentinel, setSelectedSentinel] = useState<SentinelMenuItem | null>(null);
    const [selectedSDI, setSelectedSDI] = useState<SelectedSensitiveDataIdentifier | null>(null);
    const [liveDataToggled, setLiveDataToggled] = useState<boolean>(true);
    const [showTaggedData, setShowTaggedData] = useState<boolean>(false);
    const [eventsProcessedData, setEventsProcessedData] = useState<EventsProcessedData>({
        allSentinel: null,
        activeSentinel: null
    });
    const [eventsProcessedChartData, setEventsProcessedChartData] = useState<
        EventsProcessedChartData | AllSentinelsSDIDrillDownEventsChartData | null
    >(null);
    const [sensitiveDataOccurrences, setSensitiveDataOccurrences] = useState<SensitiveDataOccurrences>();
    const [sensitiveDataOccurrencesChartData, setSensitiveDataOccurrencesChartData] = useState<
        SensitiveDataOccurrencesChartData | SDIDrillDownFindingsChartData | null
    >(null);
    const [allSentinelSDIDrillDownEvents, setAllSentinelSDIDrillDownEvents] = useState<
        AllSentinelsSDIDrillDownEvents
    >();
    const [sentinelsSDIDrillDownEvents, setSentinelsSDIDrillDownEvents] = useState<SentinelsSDIDrillDownEvents>();
    const [sdiDrilldownFindings, setSDIDrilldownFindings] = useState<
        AppSDIDrillDownFindings | SentinelSDIDrillDownFindings
    >();
    const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange | null>(null);
    const [showProgress, setShowProgress] = useState<boolean>(false);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const intervalID = useRef();
    const intervalID2 = useRef();
    const fullAppName = `${namespace}.${appName}`;

    useEffect(() => {
        if (!sentinelsInApp || !sentinelsInApp.length) {
            return;
        }
        appStatusSynchronizer.on("status_change", ({ newStatus }) => {
            setIsAppStopped(newStatus !== FlowStatus.RUNNING);
            setAppStatus(newStatus);
        });
        const currentTime = dateLib();
        let toTime = currentTime.valueOf();
        let fromTime = currentTime.subtract(1, "hour").valueOf();
        (async () => {
            try {
                setLoading(true);
                const entityType =
                    !!defaultSentinelSelected && defaultSentinelSelected !== ALL_SENTINELS_LABEL
                        ? metaStoreService.entities.SENTINEL
                        : metaStoreService.entities.APPLICATION;
                const entityName =
                    entityType === metaStoreService.entities.SENTINEL
                        ? defaultSentinelSelected.split(".").pop()
                        : appName;
                const result = await sentinelMonitorService.getFlowDesignerPreview(
                    entityType,
                    namespace,
                    entityName,
                    fromTime,
                    toTime
                );
                const sentinelsObj = result.sentinels;
                const availableSentinels = Object.keys(sentinelsObj).map(sentinel => ({
                    name: sentinel,
                    isDiscoveryDisabled: !sentinelsObj[sentinel].discoveryEnabled,
                    isTaggingEnabled: sentinelsObj[sentinel].taggingEnabled
                }));

                setSentinels(availableSentinels);
                if (!!defaultSentinelSelected) {
                    setSelectedSentinel({ label: defaultSentinelSelected, value: defaultSentinelSelected });
                }
                setPreviewData(result);
            } catch (error) {
                growl.error(error);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            clearPollingIntervals();
        };
    }, [defaultSentinelSelected]);

    useEffect(() => {
        if (!sentinelsInApp || !sentinelsInApp.length) {
            return;
        }
        const { entityType, entityName } = getEntityTypeAndName(selectedSentinel, appName);

        if (!isPanelExpanded) {
            if (!isAppStopped || (isAppStopped && previewData && Object.keys(previewData)?.length)) {
                setShowProgress(true);
                pollingFn(
                    getPreviewData,
                    intervalID,
                    POLL_INTERVAL,
                    entityType,
                    namespace,
                    entityName,
                    selectedTab === TABS[0] //= 1 hour timeframe
                );
            }
        } else {
            let fnName;
            setShowProgress(true);
            //Occurrences Tab
            if (currentTabIndex === 1) {
                if (selectedSDI) {
                    const fnName =
                        entityType === metaStoreService.entities.APPLICATION
                            ? getAppSDIDrillDownFindingsChartData
                            : getSentinelSDIDrillDownFindingsChartData;
                    pollingFn(
                        fnName,
                        intervalID,
                        POLL_INTERVAL,
                        entityType,
                        namespace,
                        entityName,
                        selectedSDI.value,
                        selectedTab === TABS[0] // isOneHourTimeFrame
                    );
                } else {
                    pollingFn(
                        getSensitiveDataOccurrencesChartData,
                        intervalID,
                        POLL_INTERVAL,
                        entityType,
                        namespace,
                        entityName,
                        selectedTab === TABS[0] // isOneHourTimeFrame
                    );
                }
            } else {
                if (selectedSDI) {
                    const fnName =
                        entityType === metaStoreService.entities.APPLICATION
                            ? getAppSentinelsSDIEventsProcessedChartData
                            : getSentinelSDIDrillDownEventsChartData;

                    pollingFn(
                        fnName,
                        intervalID,
                        POLL_INTERVAL,
                        namespace,
                        entityName,
                        selectedSDI.value,
                        selectedTab === TABS[0] // isOneHourTimeFrame
                    );
                } else {
                    fnName =
                        entityType === metaStoreService.entities.APPLICATION
                            ? getAllSentinelsEventsProcessedDataChartData
                            : getSentinelEventsProcessedChartData;
                    //Fetching chart
                    pollingFn(
                        fnName,
                        intervalID,
                        POLL_INTERVAL,
                        namespace,
                        entityName,
                        selectedTab === TABS[0] // isOneHourTimeFrame
                    );
                }
            }
        }
    }, [selectedSentinel, selectedSDI, isPanelExpanded, currentTabIndex, selectedTab, isAppStopped, liveDataToggled]);

    useEffect(() => {
        if (!sentinelsInApp || !sentinelsInApp.length || !!selectedDateRange || !isPanelExpanded) {
            intervalID2?.current && clearInterval(intervalID2.current);
            if (!isPanelExpanded) {
                return;
            }
        }
        const { entityType, entityName } = getEntityTypeAndName(selectedSentinel, appName);

        setShowProgress(true);
        //Occurrences Tab
        if (currentTabIndex === 1) {
            if (selectedSDI) {
                const fnName =
                    entityType === metaStoreService.entities.APPLICATION
                        ? getAppSDIDrillDownFindings
                        : getSentinelSDIDrillDownFindings;
                if (!!selectedDateRange) {
                    fnName(
                        entityType,
                        namespace,
                        entityName,
                        selectedSDI.value,
                        selectedTab === TABS[0], // isOneHourTimeFrame
                        selectedDateRange
                    );
                    return;
                }
                //Live data
                pollingFn(
                    fnName,
                    intervalID2,
                    POLL_INTERVAL,
                    entityType,
                    namespace,
                    entityName,
                    selectedSDI.value,
                    selectedTab === TABS[0], // isOneHourTimeFrame
                    selectedDateRange
                );
            } else {
                if (!!selectedDateRange) {
                    getSensitiveDataOccurrences(
                        entityType,
                        namespace,
                        entityName,
                        selectedTab === TABS[0], // isOneHourTimeFrame
                        selectedDateRange
                    );
                    return;
                }
                pollingFn(
                    getSensitiveDataOccurrences,
                    intervalID2,
                    POLL_INTERVAL,
                    entityType,
                    namespace,
                    entityName,
                    selectedTab === TABS[0], // isOneHourTimeFrame
                    selectedDateRange
                );
            }
        } else {
            if (selectedSDI) {
                const fnName =
                    entityType === metaStoreService.entities.APPLICATION
                        ? getAppSentinelsSDIEventsProcessed
                        : getSentinelSDIDrillDownEvents;
                if (!!selectedDateRange) {
                    fnName(
                        namespace,
                        entityName,
                        selectedSDI.value,
                        selectedTab === TABS[0], // isOneHourTimeFrame
                        selectedDateRange
                    );
                    return;
                }

                pollingFn(
                    fnName,
                    intervalID2,
                    POLL_INTERVAL,
                    namespace,
                    entityName,
                    selectedSDI.value,
                    selectedTab === TABS[0], // isOneHourTimeFrame
                    selectedDateRange
                );
            } else {
                const fnName =
                    entityType === metaStoreService.entities.APPLICATION
                        ? getAllSentinelsEventsProcessedData
                        : getSentinelEventsProcessedData;
                if (!!selectedDateRange) {
                    fnName(
                        namespace,
                        entityName,
                        selectedTab === TABS[0], // isOneHourTimeFrame,
                        selectedDateRange
                    );
                    return;
                }
                pollingFn(
                    fnName,
                    intervalID2,
                    POLL_INTERVAL,
                    namespace,
                    entityName,
                    selectedTab === TABS[0], // isOneHourTimeFrame,
                    selectedDateRange
                );
            }
        }
    }, [selectedSentinel, selectedSDI, isPanelExpanded, currentTabIndex, selectedTab, selectedDateRange, isAppStopped]);

    const getPreviewData = async (
        entityType: string,
        namespace: string,
        appName: string,
        isOneHourTimeFrame: boolean = true
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestamps(isOneHourTimeFrame, null);
            const result = await sentinelMonitorService.getFlowDesignerPreview(
                entityType,
                namespace,
                appName,
                fromTime,
                toTime
            );
            setPreviewData(result);
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const getAllSentinelsEventsProcessedData = async (
        namespace: string,
        appName: string,
        isOneHourTimeFrame: boolean = true,
        selectedDateRange: SelectedDateRange | null
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestamps(isOneHourTimeFrame, selectedDateRange);
            const result = await sentinelMonitorService.getAppSentinelsAIActivityEventsProcessed(
                namespace,
                appName,
                fromTime,
                toTime
            );
            setEventsProcessedData({
                allSentinel: result,
                activeSentinel: null
            });
            setAllSentinelSDIDrillDownEvents(null);
            setSentinelsSDIDrillDownEvents(null);
            setEventsProcessedData({
                allSentinel: result,
                activeSentinel: null
            });
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const getAllSentinelsEventsProcessedDataChartData = async (
        namespace: string,
        appName: string,
        isOneHourTimeFrame: boolean = true
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestampsForChart();
            const timeFormat = getTimeFormat(isOneHourTimeFrame);

            const chartData = await sentinelMonitorService.getAppSentinelsEventsProcessedChartData(
                namespace,
                appName,
                fromTime,
                toTime,
                timeFormat
            );
            setEventsProcessedChartData(chartData);
            setSensitiveDataOccurrencesChartData(null);
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const getSentinelEventsProcessedData = async (
        namespace: string,
        sentinelName: string,
        isOneHourTimeFrame: boolean = true,
        selectedDateRange: SelectedDateRange | null
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestamps(isOneHourTimeFrame, selectedDateRange);
            const result = await sentinelMonitorService.getSentinelEventsProcessed(
                namespace,
                sentinelName,
                fromTime,
                toTime
            );
            setAllSentinelSDIDrillDownEvents(null);
            setSentinelsSDIDrillDownEvents(null);
            setEventsProcessedData({
                allSentinel: null,
                activeSentinel: result
            });
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const getSentinelEventsProcessedChartData = async (
        namespace: string,
        sentinelName: string,
        isOneHourTimeFrame: boolean = true
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestampsForChart();
            const timeFormat = getTimeFormat(isOneHourTimeFrame);
            const chartData = await sentinelMonitorService.getSentinelEventsProcessedChartData(
                namespace,
                sentinelName,
                fromTime,
                toTime,
                timeFormat
            );
            setSensitiveDataOccurrencesChartData(null);
            setEventsProcessedChartData(chartData);
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const getSensitiveDataOccurrences = async (
        entityType: string,
        namespace: string,
        name: string,
        isOneHourTimeFrame: boolean = true,
        selectedDateRange: SelectedDateRange | null
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestamps(isOneHourTimeFrame, selectedDateRange);
            const result = await sentinelMonitorService.getSensitiveDataOccurrences(
                entityType,
                namespace,
                name,
                namespace + "." + appName,
                fromTime,
                toTime
            );
            setEventsProcessedData({ activeSentinel: null, allSentinel: null });
            setAllSentinelSDIDrillDownEvents(null);
            setSentinelsSDIDrillDownEvents(null);
            setSDIDrilldownFindings(null);
            setSensitiveDataOccurrences(result);
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const getSensitiveDataOccurrencesChartData = async (
        entityType: string,
        namespace: string,
        name: string,
        isOneHourTimeFrame: boolean = true
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestampsForChart();
            const timeFormat = getTimeFormat(isOneHourTimeFrame);
            const chartData = await sentinelMonitorService.getSensitiveDataOccurrencesChartData(
                entityType,
                namespace,
                name,
                fromTime,
                toTime,
                timeFormat
            );
            setEventsProcessedChartData(null);
            setSensitiveDataOccurrencesChartData(chartData);
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const getAppSentinelsSDIEventsProcessed = async (
        namespace: string,
        name: string,
        sdi: string,
        isOneHourTimeFrame: boolean = true,
        selectedDateRange: SelectedDateRange | null
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestamps(isOneHourTimeFrame, selectedDateRange);
            const result = await sentinelMonitorService.getAppSentinelsSDIEventsProcessed(
                namespace,
                name,
                fromTime,
                toTime,
                sdi
            );
            setEventsProcessedData({ activeSentinel: null, allSentinel: null });
            setAllSentinelSDIDrillDownEvents(result);
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const getAppSentinelsSDIEventsProcessedChartData = async (
        namespace: string,
        appName: string,
        sdi: string,
        isOneHourTimeFrame: boolean = true
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestampsForChart();
            const timeFormat = getTimeFormat(isOneHourTimeFrame);

            const chartData = await sentinelMonitorService.getAppSentinelsSDIDrillDownEventsChartData(
                namespace,
                appName,
                fromTime,
                toTime,
                sdi,
                timeFormat
            );
            setEventsProcessedChartData(chartData);
            setSensitiveDataOccurrencesChartData(null);
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const getSentinelSDIDrillDownEvents = async (
        namespace: string,
        sentinelName: string,
        sdi: string,
        isOneHourTimeFrame: boolean = true,
        selectedDateRange: SelectedDateRange | null
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestamps(isOneHourTimeFrame, selectedDateRange);
            const result = await sentinelMonitorService.getSentinelSDIDrillDownEvents(
                namespace,
                sentinelName,
                fromTime,
                toTime,
                sdi
            );
            setEventsProcessedData({ activeSentinel: null, allSentinel: null });
            setAllSentinelSDIDrillDownEvents(null);
            setSentinelsSDIDrillDownEvents(result);
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const getSentinelSDIDrillDownEventsChartData = async (
        namespace: string,
        sentinelName: string,
        sdi: string,
        isOneHourTimeFrame: boolean = true
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestampsForChart();
            const timeFormat = getTimeFormat(isOneHourTimeFrame);

            const chartData = await sentinelMonitorService.getSentinelSDIDrillDownEventsChartData(
                namespace,
                sentinelName,
                fromTime,
                toTime,
                sdi,
                timeFormat
            );
            setEventsProcessedChartData(chartData);
            setSensitiveDataOccurrencesChartData(null);
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const getAppSDIDrillDownFindings = async (
        entityType: string,
        namespace: string,
        name: string,
        sdi: string,
        isOneHourTimeFrame: boolean = true,
        selectedDateRange: SelectedDateRange | null
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestamps(isOneHourTimeFrame, selectedDateRange);
            const result = await sentinelMonitorService.getAppSDIDrillDownFindings(
                namespace,
                appName,
                fromTime,
                toTime,
                sdi
            );

            setSensitiveDataOccurrences(null);
            setSentinelsSDIDrillDownEvents(null);
            setAllSentinelSDIDrillDownEvents(null);
            setSDIDrilldownFindings(result);
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const getAppSDIDrillDownFindingsChartData = async (
        entityType: string,
        namespace: string,
        name: string,
        sdi: string,
        isOneHourTimeFrame: boolean = true
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestampsForChart();
            const timeFormat = getTimeFormat(isOneHourTimeFrame);
            const chartData = await sentinelMonitorService.getSDIDrillDownFindingsChartData(
                entityType,
                namespace,
                name,
                fromTime,
                toTime,
                sdi,
                timeFormat
            );
            setSensitiveDataOccurrencesChartData(chartData);
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const getSentinelSDIDrillDownFindings = async (
        entityType: string,
        namespace: string,
        name: string,
        sdi: string,
        isOneHourTimeFrame: boolean = true,
        selectedDateRange: SelectedDateRange | null
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestamps(isOneHourTimeFrame, selectedDateRange);
            const result = await sentinelMonitorService.getSentinelSDIDrillDownFindings(
                namespace,
                name,
                fromTime,
                toTime,
                sdi
            );

            setSensitiveDataOccurrences(null);
            setSentinelsSDIDrillDownEvents(null);
            setAllSentinelSDIDrillDownEvents(null);
            setSDIDrilldownFindings(result);
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const getSentinelSDIDrillDownFindingsChartData = async (
        entityType: string,
        namespace: string,
        name: string,
        sdi: string,
        isOneHourTimeFrame: boolean = true
    ) => {
        try {
            const { fromTime, toTime } = getStartAndEndTimestampsForChart();
            const timeFormat = getTimeFormat(isOneHourTimeFrame);
            const chartData = await sentinelMonitorService.getSDIDrillDownFindingsChartData(
                entityType,
                namespace,
                name,
                fromTime,
                toTime,
                sdi,
                timeFormat
            );
            setSensitiveDataOccurrencesChartData(chartData);
        } catch (error) {
            growl.error(error);
        } finally {
            setShowProgress(false);
        }
    };

    const clearPollingIntervals = () => {
        intervalID?.current && clearInterval(intervalID.current);
        intervalID2?.current && clearInterval(intervalID2.current);
    };

    const onLiveViewClick = () => {
        clearPollingIntervals();
        setLiveDataToggled(value => !value);
        setSelectedDateRange(null);
    };

    const handleTabChange = value => {
        setSelectedDateRange(null);
        setSelectedTab(value);
    };

    const handleSentinelChange = value => {
        if (defaultSentinelSelected) {
            navigateTo.AppSentinelLiveMonitor(namespace + "." + appName, value, false);
        }
    };

    const sentinelsWithTaggingEnabled = useMemo(() => {
        return previewData?.sentinels
            ? Object.keys(previewData.sentinels).filter(sentinel => !!previewData.sentinels[sentinel].taggingEnabled)
            : [];
    }, [previewData]);

    const sentinelsWithTaggingDisabled = useMemo(() => {
        return previewData?.sentinels
            ? Object.keys(previewData.sentinels).filter(sentinel => !previewData.sentinels[sentinel].taggingEnabled)
            : [];
    }, [previewData]);
    const eventsProcessed =
        eventsProcessedData.allSentinel ??
        eventsProcessedData.activeSentinel ??
        allSentinelSDIDrillDownEvents ??
        sentinelsSDIDrillDownEvents;

    const dataForFindingsTab = sensitiveDataOccurrences ?? sdiDrilldownFindings;

    const currentSentinelInfo = sentinels?.find(sentinel => sentinel.name === selectedSentinel?.value);

    if (!sentinelsInApp || !sentinelsInApp.length) {
        // onToggleFullScreen(); //For post  v1
        return (
            <Grid
                container
                width={"100%"}
                height={"100%"}
                flexDirection={"column"}
                justifyContent={"flex-start"}
                flexWrap={"nowrap"}
            >
                <PanelHeader isPanelExpanded={false} isEmptyView={true} onClose={onClose} onToggleFullScreen={null} />
                <SentinelAIEmpty isGlobalView={false} />
            </Grid>
        );
    }

    if (!loading && isAppStopped) {
        // if (!isPanelExpanded && (!previewData || !Object.keys(previewData).length)) onToggleFullScreen(); //For post v1
        // if (
        //     (!isPanelExpanded && (!previewData || !Object.keys(previewData).length)) || //For post v1
        //     (isPanelExpanded && !dataForFindingsTab && !eventsProcessed)
        // )
        if (!previewData || !Object.keys(previewData).length) {
            return (
                <Grid
                    container
                    width={"100%"}
                    height={"100%"}
                    flexDirection={"column"}
                    justifyContent={"flex-start"}
                    flexWrap={"nowrap"}
                >
                    <PanelHeader
                        isPanelExpanded={false} //TODO: Change post v1
                        isEmptyView={true}
                        onClose={onClose}
                        onToggleFullScreen={null}
                    />
                    <AppStopped />
                </Grid>
            );
        }
    }

    const parsedTabsData = getTabsDataParsed(
        eventsProcessed,
        dataForFindingsTab,
        currentTabIndex,
        selectedSDI,
        selectedSentinel
    );
    const convertedTabsData = useMemo(() => prepareTabsData(parsedTabsData, selectedSDI), [
        eventsProcessed,
        dataForFindingsTab,
        selectedSDI
    ]);

    const showSettingsSection = useMemo(
        () => isAppStopped || (selectedSentinel && selectedSentinel.value !== ALL_SENTINELS_LABEL),
        [isAppStopped, selectedSentinel]
    );

    return (
        <Grid container width={"100%"} height={"100%"} flexWrap={"nowrap"}>
            {loading ? (
                <LoadingIndicator isGlobal={false} />
            ) : (
                <Grid
                    container
                    flex={1}
                    flexDirection={"column"}
                    justifyContent={"flex-start"}
                    alignSelf={"flex-start"}
                    flexWrap={"nowrap"}
                    height="100%"
                >
                    {!isPanelExpanded && (
                        <PanelHeader
                            isPanelExpanded={isPanelExpanded}
                            onToggleFullScreen={newToggleState => {
                                setIsPanelExpanded(value => !value);
                                onToggleFullScreen();
                            }}
                            onClose={onClose}
                        />
                    )}
                    <PanelHeaderControls
                        sentinels={sentinels}
                        selectedTab={selectedTab}
                        handleTabChange={handleTabChange}
                        selectedSentinel={selectedSentinel}
                        setSelectedSentinel={value => {
                            handleSentinelChange(value.value);
                            setSelectedSentinel(value);
                            setShowTaggedData(false);
                        }}
                        onBackClick={() => {
                            setSelectedSDI(null);
                            onLiveViewClick();
                        }}
                        selectedSDI={selectedSDI}
                        isPanelExpanded={isPanelExpanded}
                        selectedDateRange={selectedDateRange}
                        showBottomBorder={showSettingsSection}
                        onToggleFullScreen={isExpanded => {
                            setIsPanelExpanded(value => !value);
                            onToggleFullScreen();
                        }}
                        onClose={onClose}
                        fetchReportData={async () => {
                            const isOneHourTimeFrame = selectedTab === TABS[0];
                            const reportData = await fetchSentinelAppReportData(
                                fullAppName,
                                namespace,
                                appName,
                                selectedDateRange,
                                isOneHourTimeFrame
                            );
                            const { fromTime, toTime } = getStartAndEndTimestamps(
                                isOneHourTimeFrame,
                                selectedDateRange
                            );
                            downloadSentinelCSVReport(
                                [fullAppName],
                                metaStoreService.entities.APPLICATION,
                                fromTime,
                                toTime
                            );

                            return {
                                ...reportData,
                                isOneHourTimeFrame: isOneHourTimeFrame,
                                sentinelsCount: sentinelsInApp.length,
                                legendData: convertedTabsData
                            };
                        }}
                        appName={fullAppName}
                    />
                    {!isPanelExpanded ? (
                        <Grid container height={"calc(100% - 172px)"} sx={{ overflowY: "scroll" }}>
                            {showSettingsSection ? (
                                <>
                                    <Grid container p={2} flexDirection={"column"}>
                                        <ViewSettingsSection
                                            isAppStopped={isAppStopped}
                                            appStatus={appStatus}
                                            isTaggingEnabled={currentSentinelInfo?.isTaggingEnabled}
                                            isDiscoveryDisabled={currentSentinelInfo?.isDiscoveryDisabled}
                                            selectedSentinel={selectedSentinel}
                                            viewSettings={() => {
                                                const sentinelName = selectedSentinel.value.split(".").pop();
                                                navigateTo.AppSentinelView(
                                                    namespace + "." + appName,
                                                    sentinelName,
                                                    false
                                                );

                                                onViewSettingsClick(selectedSentinel.value);
                                            }}
                                        />
                                    </Grid>
                                    <Divider variant="fullWidth" sx={{ width: "100%", borderColor: "greyscale.200" }} />
                                </>
                            ) : null}
                            <EventsProcessedChart
                                eventsProcssed={previewData.eventsProcessed}
                                eventsData={{
                                    eventsWithoutSensitiveData: previewData.eventsWithoutSensitiveData,
                                    eventsWithSensitiveData: previewData.eventsWithSensitiveData
                                }}
                            />
                            <EventsTaggedInfo
                                eventsTagged={previewData.eventsTaggedAsSensitive}
                                totalEvents={previewData.eventsWithSensitiveData}
                                taggingEnabledSentinels={sentinelsWithTaggingEnabled}
                                taggingDisabledSentinels={sentinelsWithTaggingDisabled}
                                onSentinelClick={sentinel => {
                                    handleSentinelChange(sentinel);
                                    setSelectedSentinel({ label: sentinel, value: sentinel });
                                }}
                            />
                            <SensitiveDataActionsChart
                                sensitiveDataOccurrences={previewData.occurrencesOfSensitiveData}
                                data={{
                                    encrypted: previewData.sensitiveDataActions.ENCRYPTED,
                                    masked: previewData.sensitiveDataActions.MASKED,
                                    noAction: previewData.sensitiveDataActions.NO_ACTION
                                }}
                            />
                            <SDIChart
                                sensitiveDataOccurrences={previewData.occurrencesOfSensitiveData}
                                data={getSDIChartDataParsed(previewData)}
                            />
                        </Grid>
                    ) : (
                        <Grid container px={3} pb={3} overflow="auto">
                            {showSettingsSection ? (
                                <Grid container py={2}>
                                    <ViewSettingsSection
                                        isAppStopped={isAppStopped}
                                        appStatus={appStatus}
                                        isTaggingEnabled={currentSentinelInfo?.isTaggingEnabled}
                                        isDiscoveryDisabled={currentSentinelInfo?.isDiscoveryDisabled}
                                        selectedSentinel={selectedSentinel}
                                        viewSettings={() => {
                                            const sentinelName = selectedSentinel.value.split(".").pop();
                                            navigateTo.AppSentinelView(namespace + "." + appName, sentinelName, true);
                                        }}
                                    />
                                </Grid>
                            ) : null}
                            <SentinelTabs
                                firstTabContent={
                                    <EventsProcessedContent
                                        selectedSentinel={selectedSentinel}
                                        eventsProcessedData={eventsProcessedData}
                                        showTaggedData={showTaggedData}
                                        setShowTaggedData={setShowTaggedData}
                                        selectedTab={selectedTab}
                                        selectedDateRange={selectedDateRange}
                                        setSelectedDateRange={setSelectedDateRange}
                                        setSelectedSentinel={value => {
                                            handleSentinelChange(value.value);
                                            setSelectedSentinel(value);
                                        }}
                                        chartData={eventsProcessedChartData}
                                        allSentinelSDIDrillDownEvents={allSentinelSDIDrillDownEvents}
                                        sentinelsSDIDrillDownEvents={sentinelsSDIDrillDownEvents}
                                        selectedSDI={selectedSDI}
                                        showProgress={showProgress}
                                        showChartLoader={!eventsProcessedChartData}
                                        hasAppStopped={isAppStopped}
                                        onLiveViewClick={onLiveViewClick}
                                        legendData={convertedTabsData[0].data}
                                        isTaggingEnabled={currentSentinelInfo?.isTaggingEnabled}
                                    />
                                }
                                secondTabContent={
                                    <OccurrencesContent
                                        selectedSentinel={selectedSentinel}
                                        setSelectedSentinel={value => {
                                            handleSentinelChange(value.value);
                                            setSelectedSentinel(value);
                                        }}
                                        sensitiveDataOccurrences={sensitiveDataOccurrences}
                                        chartData={sensitiveDataOccurrencesChartData}
                                        sdiDrilldownFindings={sdiDrilldownFindings}
                                        selectedTab={selectedTab}
                                        selectedDateRange={selectedDateRange}
                                        setSelectedDateRange={setSelectedDateRange}
                                        selectedSDI={selectedSDI}
                                        setSelectedSDI={value => {
                                            setSensitiveDataOccurrences(null);
                                            setEventsProcessedData({ allSentinel: null, activeSentinel: null });
                                            setSelectedSDI(value);
                                        }}
                                        setCurrentTabIndex={setCurrentTabIndex}
                                        showProgress={showProgress}
                                        showChartLoader={!sensitiveDataOccurrencesChartData}
                                        hasAppStopped={isAppStopped}
                                        onLiveViewClick={onLiveViewClick}
                                        legendData={convertedTabsData[1].data}
                                    />
                                }
                                currentTabIndex={currentTabIndex}
                                setCurrentTabIndex={value => {
                                    setSelectedDateRange(null);
                                    setCurrentTabIndex(value);
                                }}
                                isLoading={
                                    showProgress ||
                                    (currentTabIndex === 0 && !eventsProcessed) ||
                                    (currentTabIndex === 1 && !dataForFindingsTab)
                                }
                                tabs={parsedTabsData}
                                selectedSDI={selectedSDI}
                            />
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default SentinelLiveMonitor;
