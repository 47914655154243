import { useEffect } from "react";
import useBackboneStore from "src/stores/backbone/useBackboneStore";
import { useObserver } from "mobx-react";

const useInitializedEffect = (fn, dependencies) => {
    const store = useBackboneStore();
    const initialized = useObserver(() => store.initialized);

    return useEffect(initialized ? fn : () => {}, [initialized, ...dependencies]);
};

export default useInitializedEffect;
