import React from "react";
import { StriimTypography, StriimButton } from "@striim/striim-ui-v2";
import { Grid, Box } from "@mui/material";
import navigateTo from "../../../../navigate-to";
import utils from "../../../../../core/utils";
import metaobjectConverter from "../../../../../core/services/metaStoreService/metaobject-converter";

interface ProgressAppErrorsComponentProps {
    appId: string;
    closeModal: () => void;
}
const styles = {
    container: {
        backgroundColor: "warning.50",
        border: ({ palette }) => `1px solid ${palette.warning[100]}`,
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: ({ spacing }) => spacing(1),
        padding: 1
    },
    wrapper: {
        padding: ({ spacing }) => spacing(2, 3)
    }
};
const ProgressAppErrorsComponent: React.FunctionComponent<ProgressAppErrorsComponentProps> = ({
    appId,
    closeModal
}) => {
    const appName = metaobjectConverter.convertFullNameToShortName(appId);
    return (
        <Box sx={styles.wrapper}>
            <Grid container justifyContent="space-between" sx={styles.container}>
                <Box>
                    <StriimTypography variant="body4" color="greyscale.900" ml={1}>
                        An error occured in the application.
                    </StriimTypography>
                </Box>
                <StriimButton
                    onClick={() => {
                        closeModal();
                        appName && navigateTo.AppErrors(appName, true);
                    }}
                    variant="text"
                >
                    View details
                </StriimButton>
            </Grid>
        </Box>
    );
};

export default ProgressAppErrorsComponent;
