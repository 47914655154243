export const styles = {
    modalContainer: {
        height: "92vh !important"
    },
    titleContainer: {
        padding: ({ spacing }) => spacing(3),
        width: "100%",
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale["200"]}`,
        "& svg": {
            fontSize: 20
        }
    },
    leftContainer: {
        borderRight: ({ palette }) => `2px solid ${palette.greyscale["200"]}`,
        position: "relative",
        height: "100%"
    },
    formContainer: {
        maxHeight: `calc(100vh - 162px)`,
        overflowY: "scroll",
        padding: ({ spacing }) => spacing(3),
        minWidth: "40vw"
    },
    footerContainer: {
        padding: ({ spacing }) => spacing(3, 3, 3, 2),
        borderTop: ({ palette }) => `1px solid ${palette.greyscale["200"]}`
    }
};
