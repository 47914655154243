import { makeStyles } from "@material-ui/core/styles";
import React, { useMemo, useEffect, useRef } from "react";
import { StriimTable } from "@striim/striim-ui";
import DataTableCell from "./data-table-cell";
import StatusChip from "src/generic/status-chip/status-chip";
import ActionBtnsContainer from "./action-buttons-container";
import { getTab } from "app/components/alertmanager/alert-utils.js";
import utils from "core/utils";
import classNames from "classnames";
import navigateTo from "src/navigate-to";
import PropTypes from "prop-types";
import EmptyTableInfo from "src/modules/apps/pages/user-management/common/empty-table-info.jsx";

const useStyles = makeStyles(({ spacing }) => ({
    root: {
        marginTop: spacing(2)
    }
}));
const getSysAlertComponentType = componentType => {
    return componentType.charAt(0).toUpperCase() + componentType.slice(1).toLowerCase() + "s";
};
const getComponentName = (alert, componentId, componentType) => {
    if (!alert["isSysDefined"]) return utils.getName(componentId);
    return getSysAlertComponentType(componentType);
};

const useCellStyles = makeStyles(() => ({
    tableCell: {
        [`& .MuiTableRow-root > .MuiTableCell-root`]: {
            alignItems: "center",
            display: "flex"
        }
    }
}));

function SmartAlertsTable({ componentId, componentType, alertsCollection, toggleAlertStatus, setShowModal }) {
    let tableRootStyles = [useCellStyles().tableCell];
    const booleanSort = useMemo(
        () => (rowA, rowB, columnId) => {
            let valueA = rowA.values[columnId];
            let valueB = rowB.values[columnId];
            if (valueA >= valueB) {
                return 1;
            } else {
                return -1;
            }
        },
        []
    );
    const nameCellSort = useMemo(
        () => (rowA, rowB, columnId) => {
            let valueA = rowA.values[columnId]["alertName"].toLowerCase();
            let valueB = rowB.values[columnId]["alertName"].toLowerCase();
            if (valueA >= valueB) {
                return 1;
            } else {
                return -1;
            }
        },
        []
    );
    const initialSortBySmartAlert = useMemo(
        () => (rowA, rowB) => {
            if (getTab(rowA) >= getTab(rowB)) {
                return 1;
            } else {
                return -1;
            }
        },
        []
    );
    const columns = [
        {
            Header: "Data",
            accessor: "data",
            Cell: ({ value }) => {
                return (
                    <DataTableCell
                        isSmartAlert={value.isSmartAlert}
                        alertType={value.alertType}
                        alertName={value.alertName}
                    />
                );
            },
            sortType: nameCellSort
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: ({ value }) => <StatusChip label={value} />,
            sortType: booleanSort
        },
        {
            Header: "Action",
            accessor: "action",
            disableSortBy: true
        }
    ];
    const tableRef = useRef();
    const classes = useStyles();
    useEffect(() => {
        tableRef.current.scrollTo(0, 0);
    }, [alertsCollection]);
    let tableData = alertsCollection.sort(initialSortBySmartAlert).map(alert => {
        return {
            data: {
                isSmartAlert: getTab(alert) === "smart_alerts",
                alertType: alert.alertType,
                alertName: alert.name
            },
            status: alert.isEnabled ? "Enabled" : "Disabled",
            action: (
                <ActionBtnsContainer
                    enabled={alert.isEnabled}
                    isSmartAlert={getTab(alert) === "smart_alerts"}
                    alertName={alert.name}
                    handleConfigureClick={() => {
                        setShowModal(false);
                        const tab = getTab(alert);
                        const category = getComponentName(alert, componentId, componentType);
                        navigateTo.Alert(tab, category, alert.id, true);
                    }}
                    handleToggleEnableClick={() => toggleAlertStatus(alert)}
                />
            )
        };
    });
    return (
        <div className={classes.root} ref={tableRef}>
            <StriimTable
                TableProps={{ "data-testid": "alerts-table" }}
                TableBodyProps={{
                    classes: { root: classNames(...tableRootStyles) }
                }}
                {...{ data: tableData, columns }}
                sortable
            />
            {alertsCollection.length === 0 && (
                <EmptyTableInfo text={`You can start adding alerts using the 'Create' button`} />
            )}
        </div>
    );
}

SmartAlertsTable.propTypes = {
    componentId: PropTypes.string.isRequired,
    componentType: PropTypes.string.isRequired,
    alertsCollection: PropTypes.array.isRequired,
    toggleAlertStatus: PropTypes.func.isRequired,
    setShowModal: PropTypes.func.isRequired
};

export default SmartAlertsTable;
