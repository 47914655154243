import React, { useState } from "react";
import PropTypes from "prop-types";

import useStyles from "./drag_n_drop.styles";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, CardHeader, Grid, CardContent } from "@material-ui/core";
import { StriimIconButton, StriimTypography, StriimInputField, StriimButton } from "@striim/striim-ui";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { components } from "react-select";
import utils from "core/utils";
import { useTheme } from "@mui/material/styles";

const DragAndDrop = ({ selected, removed, metrics, apps }) => {
    const v5theme = useTheme();
    const classes = useStyles(v5theme);
    const [metricAppId, setMetricAppId] = useState(null);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [displaced] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, displaced);
        return result;
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const items = reorder(selected[0], result.source.index, result.destination.index);
        selected[1](items);
    };

    const handleRemove = index => {
        const list = Array.from(selected[0]);
        const [displaced] = list.splice(index, 1);
        selected[1](list);

        if (!metrics || displaced.attr) {
            const temp = Array.from(removed[0]);
            temp.push(displaced);
            removed[1](temp);
        }
    };

    const handleAddBack = (item, index) => {
        const list = Array.from(selected[0]);
        list.push(item);
        selected[1](list);

        const temp = Array.from(removed[0]);
        temp.splice(index, 1);
        removed[1](temp);
    };

    // TODO: make this more functional
    const addAppMetric = () => {
        let title = "App Metrics - " + `${utils.getNamespace(metricAppId.value)}.${metricAppId.label}`;
        let item = { attr: false, title: title, comp: "App", appId: metricAppId.value };
        const list = Array.from(selected[0]);
        list.push(item);
        selected[1](list);
        setMetricAppId(null);
    };

    const getOptions = apps => {
        let options = [];
        const list = [...selected[0]];
        options = apps
            .filter(app => !list.some(item => item.appId === app.id))
            .map(app => {
                return { label: app.name, value: app.id };
            });
        return options;
    };

    const AppsMenuItem = props => {
        const namespace = utils.getNamespace(props.data.value);
        return (
            <components.Option {...props}>
                <Grid container className={classes.appMetricsAddContainer}>
                    {props.label}
                    <span>{namespace}</span>
                </Grid>
            </components.Option>
        );
    };

    const hasElements = () => {
        let arr = Array.from(selected[0]);
        return arr && arr.length > 0;
    };

    return (
        <>
            <Box className={hasElements() ? classes.draggableContainer : ""}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {provided => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {selected[0] &&
                                    selected[0].map((item, index) => (
                                        <Draggable draggableId={item.title.toString()} index={index} key={item.title}>
                                            {provided => (
                                                <div
                                                    className={classes.draggableDiv}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Box
                                                        data-test-id={`homepage-customisation-draggable-item-${item.title}`}
                                                    >
                                                        <CardHeader
                                                            className={classes.draggableCardHeader}
                                                            classes={{
                                                                action: classes.actionButtonContainer,
                                                                avatar: classes.avatarStyles
                                                            }}
                                                            title={
                                                                <StriimTypography
                                                                    variant="body"
                                                                    data-test-id="customization-widget-title"
                                                                    color="greyscale.900"
                                                                >
                                                                    {item.title}
                                                                </StriimTypography>
                                                            }
                                                            action={
                                                                <Grid>
                                                                    <StriimIconButton
                                                                        onClick={() => {
                                                                            handleRemove(index);
                                                                        }}
                                                                        data-test-id={`remove-${item.title}`}
                                                                    >
                                                                        <CloseIcon className={classes.removeButton} />
                                                                    </StriimIconButton>
                                                                </Grid>
                                                            }
                                                            avatar={
                                                                <Grid
                                                                    item
                                                                    className={`${classes.dragButton} ${classes.dragButtonContainer}`}
                                                                    data-test-id={`drag-icon-${item.title}`}
                                                                >
                                                                    <DragIndicatorIcon className={classes.dragButton} />
                                                                </Grid>
                                                            }
                                                        />
                                                    </Box>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
            <StriimTypography variant="body3" color="greyscale.900">
                Other available widgets
            </StriimTypography>
            {!metrics && selected[0] && selected[0].length >= 4 && (
                <StriimTypography variant="caption" className={classes.warningText}>
                    Only up to 4 attributes can be selected at a time.
                </StriimTypography>
            )}
            {removed[0] &&
                removed[0].map((item, index) => (
                    <Box key={item.title} data-test-id="removed-app">
                        <CardHeader
                            className={classes.cardHeader}
                            classes={{
                                action: classes.actionButtonContainer
                            }}
                            action={
                                <StriimIconButton
                                    data-test-id="add-app--button"
                                    disabled={!metrics && selected[0].length >= 4}
                                    onClick={() => {
                                        handleAddBack(item, index);
                                    }}
                                >
                                    <AddCircleOutlineIcon
                                        className={
                                            !metrics && selected[0].length >= 4
                                                ? classes.disabledButton
                                                : classes.addButton
                                        }
                                    />
                                </StriimIconButton>
                            }
                            disableTypography={true}
                            title={
                                <StriimTypography
                                    variant="body"
                                    className={classes.appMetricsAddLabel}
                                    color="greyscale.900"
                                >
                                    {item.title}
                                </StriimTypography>
                            }
                        />
                    </Box>
                ))}

            {metrics && apps && apps.length > 0 && (
                <Box style={{ overflow: "visible" }}>
                    <CardContent className={classes.appMetricsAddContent}>
                        <Grid container className={classes.appMetricsAddContainer}>
                            <Grid item>
                                <StriimInputField
                                    SelectProps={{
                                        components: {
                                            Option: AppsMenuItem
                                        },
                                        options: getOptions(apps)
                                    }}
                                    select
                                    placeholder="Select App"
                                    value={metricAppId}
                                    onChange={app => setMetricAppId(app)}
                                    className={classes.appMetricsAddInputField}
                                />
                            </Grid>
                            <Grid item>
                                <StriimButton
                                    variant="text"
                                    data-test-id={"add-app-metric-button"}
                                    disabled={!metricAppId}
                                    onClick={() => {
                                        addAppMetric();
                                    }}
                                    startIcon={<AddCircleOutlineIcon />}
                                >
                                    Add Metrics
                                </StriimButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Box>
            )}
        </>
    );
};

export default DragAndDrop;

DragAndDrop.propTypes = {
    selected: PropTypes.array.isRequired, // selected attributes
    removed: PropTypes.array.isRequired, // removed attributes
    metrics: PropTypes.bool,
    apps: PropTypes.array
};
DragAndDrop.defaultProps = {
    metrics: false
};
