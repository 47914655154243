import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./header-with-help.html";
import helpableMixin from "app/components/common/helpable/helpable";

export default function(text, helpId) {
    var Model = BaseControl.Model.extend({
        defaults: {
            text: text
        }
    });

    var View = BaseControl.View.extend(helpableMixin).extend({
        template: _.template(template),
        className: "",
        hideLabel: true,
        setViewValue: function() {},
        getViewValue: function() {},
        getHelpID: function() {
            return helpId;
        },
        getHelperContentElement: function() {
            return this.$el.find(".helpLink");
        }
    });

    return _.extend({}, BaseControl, {
        Model: Model,
        View: View
    });
}
