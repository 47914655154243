import { useEffect, useState } from "react";

import guardianService from "../guardian/guardian-service";
import { PIISettings } from "../guardian/guardian-job.types";
import { DEFAULT_STRIIM_AI_ENGINE } from "../guardian/settings/settings-tab";
import connectionProfileService from "../../apps/pages/manage-striim/connection-profiles/connection-profile-service";
import growl from "../../../../app/components/common/growl";

export type StriimAIEngine = {
    isLoading: boolean;
    isConfigured: boolean;
    isConnected: boolean;
    connectivityErrorMessage: string | null;
};

const useStriimAIEngine = () => {
    const [striimAIEngine, setStriimAIEngine] = useState<StriimAIEngine>({
        isLoading: true,
        isConfigured: false,
        isConnected: false,
        connectivityErrorMessage: null
    });
    useEffect(() => {
        (async () => {
            let AIEngineStatus = {
                isLoading: true,
                isConfigured: false,
                isConnected: false,
                connectivityErrorMessage: null
            };
            try {
                const settingsResult = await guardianService.getPIISettings();
                const settings: PIISettings = JSON.parse(settingsResult);
                const aiEngine = settings?.aiEngine?.trim();
                if (aiEngine === DEFAULT_STRIIM_AI_ENGINE) {
                    let AIEngineConnectivity;
                    AIEngineConnectivity = await connectionProfileService.testExistingConnectionProfile(aiEngine);
                    AIEngineConnectivity = JSON.parse(AIEngineConnectivity);
                    const connectivityError = JSON.parse(AIEngineConnectivity[0]?.jsonErrorDescription)?.["Summary"];
                    AIEngineStatus = {
                        isLoading: false,
                        isConfigured: true,
                        isConnected: AIEngineConnectivity[0]?.result,
                        connectivityErrorMessage: connectivityError
                    };
                }
            } catch (error) {
                growl.error(error);
            } finally {
                setStriimAIEngine({ ...AIEngineStatus, isLoading: false });
            }
        })();
    }, []);

    return striimAIEngine;
};

export default useStriimAIEngine;
