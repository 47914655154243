import Backbone from "backbone";
import { View } from "../database-reader/base-database-reader";
import { DB_PROVIDERS } from '../database-reader/configs/db-providers'
const module = {};
class HubSpotView extends View {
    setup() {
        this.label = "HubSpot";
        this.model.get("connectionParams").set("type", DB_PROVIDERS.HUBSPOT.id);
        this.model.set("isCDC", false);
    }
}

module.View = HubSpotView;
module.vent = new Backbone.Model();
export default module;