import { targetInfoType } from "../target/Types";
import { getParams } from "../services";
import { DB_PROVIDERS } from "../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/configs/db-providers";
import PredefinedComponentNames from "../../../../app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
const VALIDATION_TIMEOUT = 2_000;

const validationUtils = {
    getTargetType: function(target: string): string {
        switch (target) {
            case "OutputOracleDatabase":
                return DB_PROVIDERS.ORACLE.name;
            case "OutputPostgresDatabase":
            case "CloudSqlPostgreSqlWriter":
            case "AzurePostgreSqlWriter":
                return DB_PROVIDERS.POSTGRES.name;
            case "OutputMySQLDatabase":
            case "CloudSqlMySqlWriter":
                return DB_PROVIDERS.MYSQL.name;
            case "OutputSQLServerDatabase":
                return "MSSQL";
            case "MariaDBDatabase":
                return DB_PROVIDERS.MARIADB.name;
            default:
                return target;
        }
    },
    isAppAdapter: function(adapterName: string): boolean {
        if (!adapterName) {
            return false;
        }
        const sourceName = PredefinedComponentNames[adapterName]?.name?.toUpperCase();

        if (DB_PROVIDERS?.[sourceName]) {
            return !!DB_PROVIDERS?.[sourceName]?.isAppAdapter;
        }
        return (
            Object.values(DB_PROVIDERS).find(
                adapterData => adapterData?.name?.toUpperCase() === sourceName?.toUpperCase()
            )?.isAppAdapter ?? false
        );
    },
    isDWH: function(adapterName: string): boolean {
        const DWHList = [
            "RedshiftWriter",
            "SnowflakeWriter",
            "AzureSQLDWHWriter",
            "FabricDataWarehouseWriter",
            "BigQuery",
            "DeltaLakeWriter",
            "HiveWriter",
            "FabricMirrorWriter"
        ];
        return DWHList.includes(adapterName);
    },
    isRDBMS: function(adapterName: string): boolean {
        const RDBMSList = [
            "MARIADB",
            "MARIADBXPAND",
            "SQLSERVER",
            "MSSQL",
            "MYSQL",
            "POSTGRES",
            "ORACLE",
            "YUGABYTEDB",
            "YUGABYTECDC"
        ];
        return RDBMSList.includes(adapterName.toUpperCase());
    },
    getRDBMSAdvancedParams: function(connectionParams): Object {
        return {
            CDDLAction: connectionParams?.CDDLAction,
            CDDLTrackingTable: connectionParams?.CDDLTrackingTable,
            ParallelThreads: connectionParams?.ParallelThreads
        };
    },
    getParams: async function(target: string, targetInfo: targetInfoType) {
        const connectionParams: Object = { ...targetInfo.targetFormValues };
        const formatProperties = {
            username: "userName",
            connectionUrl: "URL"
        };
        if (validationUtils.isRDBMS(target)) {
            const connParams = await getParams(target, connectionParams, false, true);
            const advancedParams = validationUtils.getRDBMSAdvancedParams(connectionParams);
            return { ...connParams, ...advancedParams };
        } else {
            for (const key in formatProperties) {
                if (connectionParams.hasOwnProperty(key)) {
                    connectionParams[formatProperties[key]] = connectionParams[key];
                    delete connectionParams[key];
                }
            }
            if (connectionParams.hasOwnProperty("password")) {
                connectionParams["passwordEncrypted"] = false;
            }
            if (connectionParams.hasOwnProperty("catalog") && !connectionParams["catalog"]) {
                delete connectionParams["catalog"];
            }
            if (connectionParams.hasOwnProperty("formattedConnectionUrl")) {
                connectionParams["ConnectionURL"] = connectionParams["formattedConnectionUrl"];
                delete connectionParams["formattedConnectionUrl"];
            }
            connectionParams["streamingUpload"] =
                typeof connectionParams["streamingUpload"] === "boolean"
                    ? connectionParams["streamingUpload"]
                    : connectionParams["streamingUpload"] === "true";
            return connectionParams;
        }
    },
    isAutomatedILCDCSource: source => {
        const automatedILCDCSources = [
            "ORACLE",
            "SQLSERVER",
            "MYSQL",
            "POSTGRES",
            "MARIADB",
            "MARIADBXPAND",
            "OJET",
            "MSJET",
            "SALESFORCE",
            "SALESFORCECDCREADER"
        ];
        return automatedILCDCSources.includes(source);
    },
    getSourceParams: connectionParams => {
        return {
            UserName: connectionParams["username"],
            Password: connectionParams["password"],
            ConnectionURL: connectionParams["connectionUrl"],
            encrypted: false,
            containerName: connectionParams["containerName"] || undefined,
            service: connectionParams["service"]
        };
    },
    replaceConnectionURL: (formInfo: targetInfoType): targetInfoType => {
        const targetFields = { ...formInfo?.targetFormValues };

        if (targetFields?.["formattedConnectionUrl"]) {
            targetFields["connectionUrl"] = targetFields["formattedConnectionUrl"];
            delete targetFields["formattedConnectionUrl"];
        }
        return { ...formInfo, targetFormValues: targetFields };
    }
};

export { validationUtils, VALIDATION_TIMEOUT };
