import DashboardsStore from "./index";

class FakeDashboardsStore extends DashboardsStore {
    fetchDashboards = async () => {};
}

export const createDashboardsStore = itemsCount => {
    const store = new FakeDashboardsStore({});
    store.dashboards = [
        ...new Array(itemsCount).map((_, i) => ({
            id: `${i}admin.DASHBOARD.somename`,
            nsName: "admin",
            name: "somename",
            type: "DASHBOARD",
            subType: "DASHBOARD",
            ctime: 1420677413092,
            metaInfoStatus: {
                isAdhoc: false,
                isAnonymous: false,
                isGenerated: false,
                isValid: true,
                isDropped: false
            },
            isEditable: true
        }))
    ];

    return store;
};
