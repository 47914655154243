import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import growl from "../../../app/components/common/growl";

export default function NotFound() {
    useEffect(() => {
        growl.error("404 Error", "Oops, we can't seem to find the page you requested");
    }, []);

    return <Navigate to="/landing"></Navigate>;
}
