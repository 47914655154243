import { View } from "../database-reader/base-database-reader";
import { DB_PROVIDERS } from "../database-reader/configs/db-providers";
const module = {};
class ZendeskView extends View {
    setup() {
        this.label = "Zendesk";
        this.model.get("connectionParams").set("type", DB_PROVIDERS.ZENDESK.id);
        this.model.set("isCDC", false);
    }
}

module.View = ZendeskView;
module.vent = new Backbone.Model();
export default module;
