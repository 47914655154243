import UIControl from "app/components/common/editor/control/ui-control";

var Transformation = {};

Transformation.OnlyDbSources = false;

Transformation.createColumnControl = function () {
    return UIControl.ColumnSplitter.extend({
        hideLabel: true,
        hidden: false,
    }).create();
};

Transformation.buildTql = function (tqlBuilder, columns) {
    tqlBuilder.addWildcard();

    for (let i = 0; i < columns.length; i++) {
        let column = columns[i];

        if (!column.column || !column.delimiter) {
            continue;
        }

        tqlBuilder.addSplitColumn(column.column, column.delimiter, i, column.alias);
    }
};

export default Transformation;
