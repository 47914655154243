export default {
    openButton: {
        position: "fixed",
        bottom: 0,
        zIndex: 1000,
        background: theme => theme.palette.primary[500],
        color: theme => theme.palette.primary[100],
        width: 200,
        margin: "0px !important",
        textAlign: "center",
        left: "calc(50vw - 100px)",
        padding: theme => theme.spacing(0.5, 3),
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        cursor: "pointer",
        fontFamily: "Inter"
    },
    openButtonClosed: {
        background: theme => theme.palette.primary[500],
        color: theme => theme.palette.primary[100],
        width: 200,
        margin: "0!important",
        textAlign: "center",
        left: "calc(50vw - 100px)",
        padding: theme => theme.spacing(0.5, 3),
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        cursor: "pointer",
        position: "relative",
        fontFamily: "Inter",
        display: "block"
    },
    slider: {
        background: "transparent !important"
    },
    messagesContainer: {
        minHeight: 300,
        maxHeight: "66vh",
        overflowY: "scroll"
    },
    paddingPaper: {
        padding: theme => theme.spacing(2, 4)
    },
    messageLogEntry: {
        padding: theme => theme.spacing(2, 4),
        pointerEvents: "none"
    },
    avatar: {
        minWidth: 35,
        marginTop: "-6px"
    },
    messageEntryTitle: {
        wordBreak: "break-word",
        color: theme => theme.palette.greyscale[900]
    },
    dateTimeColor: {
        paddingLeft: theme => theme.spacing(3),
        color: theme => theme.palette.greyscale[700]
    },
    messageEntryContainersSecondary: {
        margin: "0px !important"
    },
    entryMessage: {
        color: theme => theme.palette.greyscale[900]
    },
    componentName: {
        color: theme => theme.palette.greyscale[700]
    },
    filtersMidPadding: {
        padding: theme => theme.spacing(0.5)
    },
    buttonPadding: {
        padding: theme => theme.spacing(0.5),
        paddingTop: "26px"
    },
    dateTimeMargin: {
        paddingRight: theme => theme.spacing(1)
    },
    bigButton: {
        background: theme => theme.palette.secondary[500],
        color: theme => theme.palette.greyscale[50]
    },
    toastTextDefault: {
        color: "white",
        maxWidth: 500,
        overflowWrap: "anywhere"
    },
    toastContentRoot: {
        padding: theme => theme.spacing(3) + " !important"
    },
    toastTitle: {
        margin: 0
    },
    toastDescription: {
        marginTop: theme => theme.spacing(1.5),
        marginLeft: theme => -theme.spacing(4),
        marginBottom: 0
    },
    toastMessage: {
        padding: "0px !important",
        alignItems: "flex-start !important",
        "& svg": {
            marginRight: theme => theme.spacing(1.5) + " !important"
        }
    },
    toastAction: {
        marginRight: "0px !important",
        paddingLeft: theme => theme.spacing(1.5) + " !important",
        alignSelf: "flex-start !important"
    },
    iconSuccess: {
        color: theme => theme.palette.success[500]
    },
    iconError: {
        color: theme => theme.palette.critical[500]
    },
    iconWarning: {
        color: theme => theme.palette.warning[500]
    },
    iconInfo: {
        height: "20px",
        width: "20px",
        "& path": {
            stroke: theme => theme.palette.greyscale[900]
        }
    },
    loadMoreButton: {
        textAlign: "center"
    },
    chips: {
        marginRight: theme => theme.spacing(0.5),
        maxWidth: "100%"
    },
    undoButton: {
        marginLeft: "5px !important"
    },
    listItemContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 0.5
    },
    fontSize: {
        fontSize: 14
    },
    tagChip: {
        height: 18,
        padding: theme => theme.spacing(0, 0.5),
    }
};
