import App from "app";
import React, { useEffect, useMemo } from "react";
import { Grid } from "@mui/material";

import {
    EventsProcessedChartData,
    EventsProcessedDataForApps,
    GlobalSentinelReportData,
    SDIDrillDownOccurrenceProps,
    SensitiveDataOccurrences,
    SensitiveDataOccurrencesChartData
} from "../../../guardian/sentinel-monitor/service/sentinel-monitor.types";
import {
    AppFilterSection,
    DisclaimerSection,
    EventsProcessedSection,
    IdentifiersSection,
    LogoSection,
    SDIAppsSection,
    SDISection,
    SummarySection,
    TimeFilterSection
} from "../../components/report-components";
import { EventsProcessedChart, SDIChart } from "../../components/report-charts/sentinel-report-charts";
import { formatNumber } from "../../../sentinel-live-monitor/utils";
import { updateIdentifiersData } from "../../../guardian/sentinel-monitor/service/sentinel-monitor-utils";
import { prepareTabsData } from "../../../sentinel-live-monitor/components/sentinel-tabs/sentinel-tabs";
import { getEventsProcessedTab } from "../../../guardian/sentinel-monitor/components/sentinel-monitor-tabs/events-processed-tab";
import { getSensitiveDataTab } from "../../../guardian/sentinel-monitor/components/sentinel-monitor-tabs/sensitive-data-tab";

type GlobalReportProps = {
    selectedApps: string[];
    selectedRange: { from: string; to: string };
    timeRange: { from: string; to: string };
    reportData?: {
        eventsProcessedDataForApps: EventsProcessedDataForApps;
        sensitiveDataOccurrences: SensitiveDataOccurrences;
        SDIDrillDownOccurrences: SDIDrillDownOccurrenceProps;
        appsEventsProcessedChartData: EventsProcessedChartData;
        sensitiveDataOccurrencesChartData: SensitiveDataOccurrencesChartData;
        globalSentinelReportData: GlobalSentinelReportData[];
    };
    triggerDownload?: () => {};
    isOneHourTimeFrame: boolean;
};

const getSourceCount = (globalSentinelReportData: GlobalSentinelReportData[]) => {
    let appSourceMapping = {};

    globalSentinelReportData.forEach(item => {
        Object.entries(item.applications).forEach(([appName, app]) => {
            if (!appSourceMapping[appName]) {
                appSourceMapping[appName] = new Set();
            }
            appSourceMapping[appName].add(...(app.sourceNames || []));
        });
    });
    return Object.values(appSourceMapping).reduce((accumulated, current) => {
        accumulated += current.size;
        return accumulated;
    }, 0);
};
const GlobalReport = ({
    selectedApps,
    selectedRange,
    timeRange,
    reportData,
    triggerDownload,
    isOneHourTimeFrame
}: GlobalReportProps) => {
    const {
        eventsProcessedDataForApps,
        appsEventsProcessedChartData,
        sensitiveDataOccurrencesChartData,
        sensitiveDataOccurrences,
        globalSentinelReportData
    } = reportData;

    const sentinelsCount = useMemo(() => Object.values(reportData.sensitiveDataOccurrences.sentinels)?.length ?? 0, [
        sensitiveDataOccurrences
    ]);
    const sourceCount = useMemo(() => getSourceCount(globalSentinelReportData), [globalSentinelReportData]);

    useEffect(() => {
        setTimeout(() => triggerDownload(), 500);
    }, [reportData]);

    const identifiersData = useMemo(
        () => updateIdentifiersData(sensitiveDataOccurrences.sensitiveDataIdentifierOccurrences),
        [sensitiveDataOccurrences]
    );

    const totalEventsWithIdentifier = identifiersData.reduce((acc, curr) => {
        return curr.eventsWithIdentifier ? acc + curr.eventsWithIdentifier : acc;
    }, 0);

    const eventsProcessedTab = getEventsProcessedTab(eventsProcessedDataForApps);
    const occurrencesTab = getSensitiveDataTab(sensitiveDataOccurrences);

    const convertedTabsData = useMemo(() => prepareTabsData([eventsProcessedTab, occurrencesTab]), [
        eventsProcessedDataForApps,
        occurrencesTab
    ]);

    return (
        <Grid>
            <LogoSection />
            <Grid m={5}>
                <AppFilterSection
                    apps={selectedApps}
                    sourceCount={sourceCount}
                    sentinelCount={sentinelsCount}
                    username={App?.user?.name ?? ""}
                />
                <TimeFilterSection selectedRange={timeRange} />
                <DisclaimerSection type={"Sentinel"} />
                <SummarySection
                    totalEvents={eventsProcessedDataForApps.eventsProcessed}
                    sensitiveEvents={eventsProcessedDataForApps.eventsWithSensitiveData}
                    occurences={eventsProcessedDataForApps.occurrencesOfSensitiveData}
                />
                <IdentifiersSection
                    sensitiveDataIdentifierOccurrences={sensitiveDataOccurrences.sensitiveDataIdentifierOccurrences}
                />
                <EventsProcessedChart
                    eventsProcessedChartData={appsEventsProcessedChartData}
                    eventsProcessed={eventsProcessedDataForApps.eventsProcessed}
                    eventsWithSensitiveData={eventsProcessedDataForApps.eventsWithSensitiveData}
                    eventsWithoutSensitiveData={eventsProcessedDataForApps.eventsWithoutSensitiveData}
                    appsCount={selectedApps.length}
                    sentinelsCount={sentinelsCount}
                    legendData={convertedTabsData[0].data}
                    isOneHourTimeFrame={isOneHourTimeFrame}
                    timeRange={timeRange}
                />
                <EventsProcessedSection eventsProcessedDataForApps={eventsProcessedDataForApps} />
                <div class="pagebreak"> </div>
                <SDIChart
                    appsCount={selectedApps.length}
                    sentinelsCount={sentinelsCount}
                    sensitiveDataOccurrences={sensitiveDataOccurrences}
                    sensitiveDataOccurrencesChartData={sensitiveDataOccurrencesChartData}
                    legendData={convertedTabsData[1].data}
                    isOneHourTimeFrame={isOneHourTimeFrame}
                    timeRange={timeRange}
                />
                <SDISection
                    sensitiveDataIdentifierOccurrences={sensitiveDataOccurrences.sensitiveDataIdentifierOccurrences}
                    appsCount={selectedApps.length}
                    sentinelsCount={sentinelsCount}
                    isGlobalReport={true}
                />
                <div class="pagebreak"> </div>
                <SDIAppsSection
                    globalSentinelReportData={globalSentinelReportData}
                    appsCount={selectedApps.length}
                    sentinelsCount={sentinelsCount}
                />
            </Grid>
        </Grid>
    );
};

export default GlobalReport;
