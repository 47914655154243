import $ from "jquery";
import _ from "underscore";
import flowUtils from "../flowUtils";
import CQProcessor from "../nodeProcessing/cqProcessor";
import cloneMetaObjectProcessor from "../nodeProcessing/cloneMetaObjectProcessor";
import clearPasswordsProcessor from "../nodeProcessing/clearPasswordsProcessor";
import ProcessComponentProcessor from "../nodeProcessing/processComponentProcessor";
import TargetProcessor from "../nodeProcessing/targetProcessor";
import RouterProcessor from "../nodeProcessing/routerProcessor";
import WindowProcessor from "../nodeProcessing/windowProcessor";
import EventTableProcessor from "../nodeProcessing/eventTableProcessor";
import PredefinedComponentProcessor from "../nodeProcessing/predefinedComponentProcessor";
import GraphNodeModel from "./graphNodeModel";

var NewNodeBuilder = function (collection) {
    this.jsPlumb = null;

    /**
     * Calculates position of new node
     */
    var calculate = function (parentModel) {
        var result = {
            left: 0,
            top: 0,
        };

        if (parentModel.targets.length > 0) {
            var lastTargetId = flowUtils.sanitizeElementId(parentModel.targets[parentModel.targets.length - 1]);
            lastTargetId = $("#" + lastTargetId);
            result.left = parseInt(lastTargetId.css("left"), 10) + lastTargetId.width() + 50;
            result.top = parseInt(lastTargetId.css("top"), 10);
            result.parentWidth = lastTargetId.width();
        } else {
            var dropModeElement = $("#" + parentModel.elementId);
            result.left = parseInt(dropModeElement.css("left"));
            result.top = parseInt(dropModeElement.css("top")) + 100;
            result.parentWidth = dropModeElement.outerWidth();
        }

        return result;
    };

    this.placeOnGraph = function (parentModel, newNodeType, predefinedComponentName) {
        var nodePosition = calculate(parentModel);
        var newNodeId = GraphNodeModel.prototype.tempNodeId;

        // add new node as target to drop element
        parentModel.targets.push(newNodeId);
        return this.addToGraph(newNodeType, nodePosition, parentModel, predefinedComponentName);
    };

    this.addToGraph = function (newNodeType, nodePosition, parentNode, predefinedComponentName, initialProperties) {
        var newNodeId = GraphNodeModel.prototype.tempNodeId;

        // add new node to graph
        var nodeModel = new GraphNodeModel({
            //FIXME: GW how should I create metaObject
            id: newNodeId,
            metaObject: {
                type: newNodeType,
                name: "New " + predefinedComponentName,
            },
            predefinedComponentName: predefinedComponentName,
            targets: [],
            parentNode: parentNode,
        });

        var nodeProcessors = [
            CQProcessor,
            TargetProcessor,
            RouterProcessor,
            WindowProcessor,
            PredefinedComponentProcessor,
            ProcessComponentProcessor,
            cloneMetaObjectProcessor,
            clearPasswordsProcessor,
            EventTableProcessor,
        ];
        _.each(nodeProcessors, function (processor) {
            processor.process(newNodeType, nodeModel, parentNode, predefinedComponentName, initialProperties);
        });

        this.addNodeToGraph(nodeModel, nodePosition);
        return nodeModel;
    };

    this.addNodeToGraph = function (nodeModel, nodePosition) {
        collection.add(nodeModel);
        var elementId = flowUtils.sanitizeElementId(nodeModel.id);

        // set node position
        var $node = $("#" + elementId);
        var newNodeWidth = $node.outerWidth();
        $node.css({
            top: nodePosition.top,
            left: nodePosition.left + ((nodePosition.parentWidth || 0) - newNodeWidth) / 2,
        });

        // add node to JsPlumb and make it draggable
        this.jsPlumb.draggable(jsPlumb.getSelector("#" + elementId), {
            grid: [20, 20],
        });
        return nodeModel;
    };
};

export default NewNodeBuilder;
