import api from "core/api/api";
import _ from "underscore";
import { template, className, regions } from "core/decorators";
import { LayoutView } from "marionette";
import App from "app";
import ConnectionValidationTemplate from "./templates/connection-test-template.template.html";
import * as Models from "./models";
import stringUtils from "core/utils/string-utils";
import { DB_PROVIDERS } from "./configs/db-providers";
import { getDatabaseType } from "../../common/databaseTypes";
import MarionetteWrapper from "../../../../../marionette-wrapper-for-react";
import ValitateSource from "./validate-source";
import { checkSourcePrivileges } from "src/modules/wizards/services/index.ts";
import { isAutomatedWizard } from "./app-services";
import InlineHelpMarionetteContainer from "../inline-help-container";

@template(ConnectionValidationTemplate)
@className("connection-test-view card")
@regions({
    container: "#connection-test-container"
})
class ValidationReactView extends InlineHelpMarionetteContainer {
    initialize() {
        let model = App.reqres.request("get:wizard:model");
        App.vent.trigger("appwizard:next:disable");
        App.vent.trigger("appwizard:back:enable");
        this.model = model;

        let validationArray;
        validationArray = [
            {
                status: "todo",
                text: "Trying to reach your Database",
                method: this.checkConnection.bind(this)
            },
            {
                status: "todo",
                text: "Fetching Metadata about your Database",
                method: this.fetchSchemas.bind(this)
            }
        ];
        if (this.model.get("type") === "OJET" && this.model.get("connectionParams").get("supportDownStreamCapture")) {
            validationArray = [
                {
                    status: "todo",
                    text: "Trying to reach your Downstream Database",
                    method: this.checkConnection.bind(this)
                },
                {
                    status: "todo",
                    text: "Trying to reach your Primary Database",
                    method: this.checkConnection.bind(this, true)
                },
                {
                    status: "todo",
                    text: "Fetching Metadata about your Database",
                    method: this.fetchSchemas.bind(this)
                }
            ];
        }
        if ((this.model.get("isCDC") && !this.ignoreCDCChecks) || isAutomatedWizard()) {
            if (this.hasAutomatedCDCPermissionCheck()) {
                validationArray.push({
                    status: "todo",
                    text: "Validating CDC Permissions",
                    method: this.checkCDCPermissions.bind(this)
                });
            }

            if (this.hasPrivilegeCheck(this.model.get("type"))) {
                validationArray.push({
                    status: "todo",
                    text: "Validating Permissions",
                    method: this.privilegeCheck.bind(this)
                });
            }
        }
        this.validationArray = validationArray;
        super.initialize("validation-pages");

    }
    hasAutomatedCDCPermissionCheck() {
        const type = this.model.get("type");
        switch (type) {
            case "SALESFORCE":
                return false;
            default:
                return true;
        }
    }
    serializeData() {
        let data = this.model.toJSON();
        data["displayName"] = DB_PROVIDERS[this.model.get("connectionParams").get("type")].rootElement.toLowerCase();
        return data;
    }
    canMoveForward() {
        let remainders = this.validationArray.some(item => item.status);
        return new Promise(function(resolve, reject) {
            if (remainders) {
                resolve();
            } else reject();
        });
    }
    hasPrivilegeCheck(type) {
        // Privilege check API to be called for these Database types
        switch (type) {
            case "ORACLE":
            case "MSJET":
            case "OJET":
            case "MARIADB":
            case "MARIADBXPAND":
            case "SQLSERVER":
            case "MYSQL":
            case "POSTGRES":
                return true;
            default:
                return false;
        }
    }

    async privilegeCheck() {
        const connectionParams = this.model.get("connectionParams");
        const type = this.model.get("type");
        return checkSourcePrivileges(type, connectionParams);
    }

    async checkConnection(checkPrimaryDatabase = false) {
        const response = await this.model
            .get("connectionParams")
            .checkConnection(this.model.get("isCDC"), checkPrimaryDatabase);
        return response;
    }

    async checkCDCPermissions() {
        const response = await this.model.get("connectionParams").checkCDCPermissions();
        return response;
    }

    getTypes = steps => [this.getSourceDatabaseType(steps[0]), getDatabaseType(steps[1])];

    getAdapterTypes = () => {
        if (this.model.get("type") === DB_PROVIDERS.BIGQUERY.id) {
            return [DB_PROVIDERS.BIGQUERY.id, this.model.get("template").targetAdapter];
        }
        if (this.model.get("type") === DB_PROVIDERS.SNOWFLAKE.id) {
            return [DB_PROVIDERS.SNOWFLAKE.id, this.model.get("template").targetAdapter];
        }
        return [this.model.get("template").sourceAdapter, this.model.get("template").targetAdapter];
    };
    getSourceDatabaseType = adapter => {
        var dbType = getDatabaseType(adapter);
        if (dbType === "MARIADBXPANDDB") {
            return "MARIADB";
        }
        return dbType;
    };
    async prepareSchemasCollection(schemas) {
        let schemasCollection = new Models.Schemas();

        let errors = null;
        const steps = this.model.get("template").steps;
        const [sourceType, targetType] = this.getTypes(steps);
        // Call validateSchemaName only for wizards that support auto schema conversion
        const isCDC = this.model.get("isCDC") ?? false;
        const createSchema = isAutomatedWizard() ? true : this.model.get("connectionParams").get("createSchema");
        const isSchemaValidationEnabled = await api.isSchemaValidationSupported(
            sourceType,
            targetType,
            createSchema,
            isCDC ? "CDC" : "IL"
        );
        this.model.set("isValidationSupported", isSchemaValidationEnabled);

        if (isSchemaValidationEnabled) {
            errors = await api.validateSchemaName(sourceType, targetType, schemas);
        }
        _.each(schemas, function(schema) {
            schemasCollection.add(
                new Models.Schema().set({
                    id: schema,
                    isEnabled: false,
                    schemaName: schema,
                    schemaDisplayName: stringUtils.encodeHtml(schema),
                    tables: new Models.Tables(),
                    // Disable the schema if errors are present
                    isDisabled: errors ? !!errors[schema]?.length : false,
                    // Store schema level error messages
                    errors: errors ? errors[schema] : null
                })
            );
        });
        return schemasCollection;
    }

    async fetchSchemas() {
        try {
            let isCDC = this.model.get("isCDC");
            let data = await this.model.get("connectionParams").fetchTableWrapper(isCDC);
            data = JSON.parse(data);
            if (this.model.get("connectionParams").isAppAdapter()) {
                if (data.result) {
                    let tables = JSON.parse(data.message);
                    let schemasCollection = await this.prepareSchemasCollection(tables);
                    if (isCDC) {
                        this.model.set("channels", schemasCollection);
                    } else {
                        this.model.set("schemas", schemasCollection);
                    }
                    App.vent.trigger("appwizard:retry:hide");
                    return { result: true };
                } else {
                    throw new Error(data.message);
                }
            } else {
                if (data && data.length >= 1) {
                    let schemasCollection = await this.prepareSchemasCollection(data);
                    this.model.set("schemas", schemasCollection);
                    App.vent.trigger("appwizard:retry:hide");
                    return { result: true };
                } else {
                    throw new Error("Could not get all the Schemas in the database");
                }
            }
        } catch (e) {
            throw e;
        }
    }

    onRender() {
        //  For all sources except Salesforce, Run the validation Asyncronously.
        const type = this.model.get("connectionParams").get("type");
        const runAsync = type !== DB_PROVIDERS.SALESFORCE.id;

        const ValidationView = MarionetteWrapper(ValitateSource, {
            validationList: this.validationArray,
            async: runAsync
        });
        this.getRegion("container").show(ValidationView);
    }
}

export { ValidationReactView };
