import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ChevronLeft } from "@mui/icons-material";
import { useUserManagement } from "core/user-management/user-management-context";
import RoleDetails from "./role-details/role-details";
import CreateRole from "./role-create/role-create";
import { StriimButton } from "@striim/striim-ui";
import { CancelEditModal } from "../common";
import UserManagementRoot from "../user-management-root";
import { StriimIconButton, StriimTypography } from "@striim/striim-ui-v2";
import { Box, Grid, SvgIcon } from "@mui/material";
import { isReservedRole } from "../../../../../../core/user-management/update.control";
import styles from "../common/users-management.styles";
import { EditIcon } from "../../../../../generic/icon/customIcons";

const RoleDetailsPage = () => {
    const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
    const [editing, setEditing] = useState(false);
    const { roleId } = useParams();
    const { roles, getRoles, userManagementProviderBusy } = useUserManagement();
    const navigate = useNavigate();

    useEffect(() => {
        if (!roles.length) {
            getRoles();
        }
    }, []);

    const handleReturn = () => {
        if (!roleId || editing) {
            setCancelDialogVisible(true);
        } else {
            navigate("/users", { state: { activeTab: 1 } });
        }
    };

    const onDiscardConfirm = () => {
        navigate("/users", { state: { activeTab: editing ? 1 : 0 } });
    };

    const pageUserHeader = {
        create: "Create Role",
        edit: "Edit Role:",
        details: "Role:"
    };

    const navigateToEdit = () => {
        navigate(`/users/role/${roleId}`, { state: { canEdit: true } });
        setEditing(true);
    };

    return (
        <>
            {!roles ? (
                <></>
            ) : (
                <Box sx={{ p: 3, mt: 6 }}>
                    <Box display="flex" alignItems="center" mb={3}>
                        <StriimIconButton
                            variant="secondary"
                            onClick={handleReturn}
                            data-testid={"back-button"}
                            sx={styles.backButton}
                        >
                            <ChevronLeft />
                        </StriimIconButton>
                        {roleId ? (
                            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                <Box display="flex">
                                    <StriimTypography variant="h3" color="primary.700">
                                        {editing ? pageUserHeader.edit : pageUserHeader.details}
                                    </StriimTypography>
                                    <StriimTypography variant="h3" sx={{ ml: "8px !important" }} color="secondary.500">
                                        {roleId}
                                    </StriimTypography>
                                </Box>
                                {!editing && !isReservedRole(roleId) && (
                                    <Box>
                                        <StriimButton
                                            variant="primary"
                                            onClick={navigateToEdit}
                                            data-testid={"edit-role-button"}
                                            startIcon={
                                                <SvgIcon
                                                    component={EditIcon}
                                                    sx={({ palette }) => ({
                                                        fill: "none",
                                                        "& > path": {
                                                            stroke: palette?.greyscale[50]
                                                        }
                                                    })}
                                                />
                                            }
                                        >
                                            Edit
                                        </StriimButton>
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <StriimTypography variant="h3" color="primary.900">
                                {pageUserHeader.create}
                            </StriimTypography>
                        )}
                    </Box>

                    <Grid container>
                        {roleId ? <RoleDetails id={roleId} setEditing={setEditing} /> : <CreateRole />}
                    </Grid>
                    <CancelEditModal
                        title={`Are you sure you want to discard ${
                            roleId ? `editing role ${roleId}?` : `the new role?`
                        }`}
                        dialogVisible={cancelDialogVisible}
                        setDialogVisible={setCancelDialogVisible}
                        onConfirm={onDiscardConfirm}
                    />
                </Box>
            )}
        </>
    );
};

export default function() {
    return (
        <UserManagementRoot>
            <RoleDetailsPage />
        </UserManagementRoot>
    );
}
