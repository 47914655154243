import React, { useState, useCallback } from "react";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Search from "@material-ui/icons/Search";
import { FileSection, UploadModal } from "./";
import { makeStyles } from "@material-ui/core/styles";
import { StriimTypography, StriimButton, StriimInputField } from "@striim/striim-ui";
import { Grid, InputAdornment } from "@material-ui/core";
import { Box, SvgIcon } from "@mui/material";
import { Documentation, CloudUpload } from "src/generic/icon/customIcons";

const useStyles = makeStyles(({ spacing, palette }) => ({
    card: {
        background: "#FEFEFF",
        border: "1px solid #E1E1E1",
        padding: 21,
        width: "100%",
        borderRadius: 8,
        justifyContent: "space-between"
    },
    buttons: {
        display: "flex",
        gap: "46px"
    },
    fileName: {
        fontWeight: 600
    },
    root: {
        "&[aria-expanded] > div": {
            padding: spacing(1.2)
        }
    },
    group: {
        marginLeft: 0
    },
    label: {
        padding: 0,
        marginLeft: spacing(3.75)
    },
    searchBox: {
        width: "25%",
        marginBottom: 28
    },
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: spacing(2.5)
    },
    uploadFile: {
        display: "flex",
        justifyContent: "flex-end",
        minWidth: "fit-content"
    },
    childTreeIconContainer: {
        display: "none"
    },
    childTreeLabel: {
        marginLeft: spacing(4.3),
        borderRadius: 0,
        padding: 0
    },
    childTreeRoot: {
        borderTop: `1px solid ${palette.gray1}`
    }
}));

const styles = {
    headerContainer: { display: "flex", alignItems: "center" },
    fileSectionWrapper: { marginLeft: -1.5 },
    treeItemWrapper: {
        border: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
        borderRadius: ({ spacing }) => spacing(1),
        "> li:not(:first-child)": {
            borderTop: ({ palette }) => `1px solid ${palette.greyscale[200]}`
        }
    }
};

const FilesPresent = ({ files, fetchData, selectFile, directories, userDirectory, setFileSelectionModalShown }) => {
    const classes = useStyles();
    const [isModalShown, setIsModalShown] = useState(false);
    const [searchFiles, setSearchFiles] = useState();

    const getLabel = file => {
        if (file.isDirectory && !file?.children?.length) {
            return <></>;
        }
        return file.isDirectory ? (
            <StriimTypography variant="body4" data-test-id="file-directory" color="greyscale.900">
                {file.name}
            </StriimTypography>
        ) : (
            <Box sx={styles.fileSectionWrapper}>
                <FileSection file={file} fetchData={fetchData} selectFile={selectFile} />
            </Box>
        );
    };

    // Checks if the file is not a hidden file
    const isValidFile = file => file.name[0] !== ".";

    // Checks if the folder has atleast one non hidden files
    const hasValidFiles = file => {
        if (file.children) if (file.children.some(isValidFile)) return true;
        return false;
    };

    const createSection = useCallback(
        file => {
            if (file.isDirectory) {
                if (!hasValidFiles(file)) return;
            } else if (!isValidFile(file)) return;

            return (
                <TreeItem
                    key={file.currentPath + file.name}
                    nodeId={file.currentPath + file.name}
                    label={getLabel(file)}
                    classes={{
                        group: classes.group,
                        label: classes.label,
                        root: classes.root
                    }}
                    data-test-id="file-section"
                >
                    {file?.children?.map(child => {
                        if (!isValidFile(child)) return;
                        return child.isDirectory ? (
                            createSection(child)
                        ) : (
                            <TreeItem
                                nodeId={child.currentPath + child.name}
                                key={child.currentPath + child.name}
                                label={<FileSection fetchData={fetchData} file={child} selectFile={selectFile} />}
                                classes={{
                                    root: classes.childTreeRoot,
                                    iconContainer: classes.childTreeIconContainer,
                                    label: classes.childTreeLabel
                                }}
                                data-test-id="file"
                            />
                        );
                    })}
                </TreeItem>
            );
        },
        [files, searchFiles]
    );

    const getFlatFiles = files => {
        return files.reduce((prev, curr) => {
            if (curr.isDirectory === false) {
                prev.push(curr);
            } else if (curr?.children) {
                prev.push(...getFlatFiles(curr.children));
            }
            return prev;
        }, []);
    };

    const getComponents = useCallback(
        theFiles => {
            return theFiles.reduce((acc, file) => {
                acc.push(createSection(file));
                return acc;
            }, []);
        },
        [files, searchFiles]
    );

    return (
        <>
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                data-test-id="files-present"
            >
                <Grid container className={classes.headerContainer}>
                    <Grid item xs={8}>
                        <Box sx={styles.headerContainer}>
                            <StriimTypography variant="h2">Files</StriimTypography>
                            <Box sx={{ ...styles.headerContainer, ml: 1 }}>
                                <SvgIcon
                                    component={Documentation}
                                    sx={({ palette }) => ({
                                        fill: "none",
                                        "& > path": {
                                            stroke: palette.secondary[500]
                                        }
                                    })}
                                />
                            </Box>
                        </Box>
                        <StriimTypography color="greyscale.700" variant="caption3">
                            Files allow you to upload and store the files.
                        </StriimTypography>
                    </Grid>
                    <Grid item xs={4} className={classes.uploadFile}>
                        <StriimButton
                            startIcon={
                                <SvgIcon
                                    component={CloudUpload}
                                    sx={{
                                        fill: "none",
                                        "& > path": {
                                            stroke: "white"
                                        }
                                    }}
                                />
                            }
                            variant="primary"
                            onClick={() => {
                                userDirectory && setIsModalShown(true);
                            }}
                            data-test-id="files-upload-file-button"
                        >
                            Upload File
                        </StriimButton>
                    </Grid>
                </Grid>
                <div className={classes.searchBox}>
                    <StriimInputField
                        onChange={string => {
                            if (string.length) {
                                setSearchFiles(
                                    getFlatFiles(files).filter(file =>
                                        file.name.toLowerCase().includes(string.toLowerCase())
                                    )
                                );
                            } else {
                                setSearchFiles();
                            }
                        }}
                        placeholder="Search by File name"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search opacity={0.45} />
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                <Box sx={styles.treeItemWrapper}>{getComponents(searchFiles ? searchFiles : files)}</Box>
            </TreeView>
            {isModalShown && (
                <UploadModal
                    directories={directories}
                    setIsModalShown={setIsModalShown}
                    fetchData={fetchData}
                    userDirectory={userDirectory}
                    setFileSelectionModalShown={setFileSelectionModalShown}
                />
            )}
        </>
    );
};

export default FilesPresent;
