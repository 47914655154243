import React, { useState, useEffect, Dispatch, SetStateAction, useRef } from "react";
import { SvgIcon, Box, Collapse, Divider, IconButton, InputAdornment } from "@mui/material";
import { AddCircleOutline, ExpandLessRounded, ExpandMoreRounded, Clear } from "@mui/icons-material";

import { StriimTypography, StriimButton, StriimInputField } from "@striim/striim-ui";

import { EntityComponent, Entity } from "./components";
import { Close, Search } from "../../../../../generic/icon/customIcons";
import { MaskingOption, TableData } from "./components/actions-on-entities-table";
import { ShieldEntityValidType } from "../../panel/sentinel-panel-container";

const styles = {
    searchIcon: {
        fontSize: 20,
        "& > path": {
            stroke: ({ palette }: any) => palette.greyscale[800]
        }
    },
    container: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "greyscale.50"
    },
    entitySort: {
        mt: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
};
interface ActionsOnEntitiesProps {
    isDisabled?: boolean;
    entityData: any;
    savedEntityData: any;
    setEntityData: Function;
    isSidebarExpanded: boolean;
    addEntity: Function;
    openStates: any;
    setOpenStates: Function;
    maskingOptions: MaskingOption[];
    setIsOutputShieldOrEntityValid: Dispatch<SetStateAction<ShieldEntityValidType>>;
    entityNameErrors: Record<string, boolean>;
    setEntityNameErrors: Dispatch<SetStateAction<Record<string, boolean>>>;
}

const ActionsOnEntities: React.FC<ActionsOnEntitiesProps> = ({
    isDisabled = false,
    entityData,
    setEntityData,
    isSidebarExpanded = false,
    addEntity,
    openStates,
    setOpenStates,
    maskingOptions,
    setIsOutputShieldOrEntityValid,
    entityNameErrors,
    setEntityNameErrors,
    savedEntityData
}) => {
    const [sortToggled, setSortToggled] = useState(false);
    const [searchMode, setSearchMode] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [adjustedEntityData, setAdjustedEntityData] = useState(entityData);
    const searchInputRef = useRef(null);
    const updateEntityFields = (entityId: string, newFields: TableData[]) => {
        setEntityData((prevData: any) =>
            prevData.map((entity: Entity) => (entity.id === entityId ? { ...entity, fields: newFields } : entity))
        );
    };
    useEffect(() => {
        if (searchInputRef.current && searchMode) {
            searchInputRef.current.focus();
        }
    }, [searchMode]);
    const allEntityNames = entityData.map(entity => entity.name);
    useEffect(() => {
        const sortedData = [...entityData].sort((a, b) => {
            if (a.name < b.name) return sortToggled ? 1 : -1;
            if (a.name > b.name) return sortToggled ? -1 : 1;
            return 0;
        });
        setEntityData(sortedData);
    }, [sortToggled]);

    useEffect(() => {
        const searchedEntityData =
            searchQuery === ""
                ? entityData
                : entityData.filter(e => e.name.toLowerCase().includes(searchQuery.toLowerCase()));

        setAdjustedEntityData(searchedEntityData);
    }, [searchQuery, entityData]);

    const deleteEntity = (id: string) => {
        setEntityData(prev => prev.filter(entity => entity.id !== id));
        setOpenStates(prev => {
            const newState = { ...prev };
            delete newState[id];
            return newState;
        });
    };

    const clearSearch = () => {
        setSearchMode(false);
        setSearchQuery("");
    };

    const handleEntityNameChange = (entityId: string, newName: string) => {
        setEntityData(prevData => prevData.map(e => (e.id === entityId ? { ...e, name: newName } : e)));
        if (newName.trim() !== "") {
            setEntityNameErrors(prev => ({ ...prev, [entityId]: false }));
        }
    };

    return (
        <Box sx={styles.container}>
            <Box m={2}>
                <StriimTypography variant="caption3" color="greyscale.600" letterSpacing="0.24px" fontFamily="Inter">
                    The Sentinel actions below will target specific sensitive fields, overriding the Policy Actions on
                    data in those fields.
                </StriimTypography>

                {searchMode && (
                    <StriimInputField
                        placeholder="Search"
                        value={searchQuery}
                        inputRef={searchInputRef}
                        onChange={setSearchQuery}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search opacity={0.45} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Clear cursor="pointer" onClick={clearSearch} />
                                </InputAdornment>
                            )
                        }}
                    />
                )}
                <Box sx={styles.entitySort}>
                    {!searchMode && (
                        <SvgIcon component={Search} sx={styles.searchIcon} onClick={() => setSearchMode(true)} />
                    )}
                    <Box sx={{ cursor: "pointer", userSelect: "none" }} onClick={() => setSortToggled(!sortToggled)}>
                        {!sortToggled ? "Sort by: Entity name (A-Z)" : "Sort by: Entity name (Z-A)"}
                    </Box>
                    <Box
                        sx={
                            sortToggled
                                ? {
                                      transform: "rotate(180deg)",
                                      width: 24,
                                      height: 24
                                  }
                                : { width: 24, height: 24 }
                        }
                    >
                        <img
                            src="src/modules/apps/pages/homepage/resources/images/sort-arrow.png"
                            style={{ width: 24, height: 24 }}
                        />
                    </Box>
                </Box>
            </Box>
            <Divider />
            <Box>
                {adjustedEntityData.map(entity => (
                    <Box key={entity.id}>
                        <Box width="100%" px={2} pt={1}>
                            <StriimTypography display="flex" justifyContent="space-between">
                                <Box
                                    display="flex"
                                    gap={1}
                                    alignItems="center"
                                    onClick={() =>
                                        setOpenStates(prev => ({ ...prev, [entity.id]: !openStates[entity.id] }))
                                    }
                                >
                                    {openStates[entity.id] ? <ExpandMoreRounded /> : <ExpandLessRounded />}
                                    {openStates[entity.id] ? entity.name : ""}
                                </Box>
                                <Box display="flex" gap={1} alignItems="center">
                                    {!openStates[entity.id] && (
                                        <IconButton
                                            disabled={isDisabled}
                                            size="small"
                                            onClick={() => deleteEntity(entity.id)}
                                        >
                                            <SvgIcon
                                                component={Close}
                                                sx={{
                                                    opacity: isDisabled ? "30%" : "100%",
                                                    width: "20px",
                                                    height: "auto"
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                </Box>
                            </StriimTypography>
                            <Collapse in={!openStates[entity.id]}>
                                <Box mt={1}>
                                    <EntityComponent
                                        entity={entity}
                                        isDisabled={isDisabled}
                                        onNameChange={newName => handleEntityNameChange(entity.id, newName)}
                                        updateFields={(newFields: TableData[]) =>
                                            updateEntityFields(entity.id, newFields)
                                        }
                                        allEntityNames={allEntityNames}
                                        isSidebarExpanded={isSidebarExpanded}
                                        maskingOptions={maskingOptions}
                                        setIsOutputShieldOrEntityValid={setIsOutputShieldOrEntityValid}
                                        hasError={entityNameErrors[entity.id]}
                                        setHasError={hasError =>
                                            setEntityNameErrors(prev => ({ ...prev, [entity.id]: hasError }))
                                        }
                                        savedEntity={savedEntityData?.find(entity => entity.id === entity.id) || {}}
                                    />
                                </Box>
                            </Collapse>
                        </Box>
                        <Divider sx={{ mt: 2 }} />
                    </Box>
                ))}
            </Box>
            <Box my={1}>
                <StriimButton
                    sx={{
                        color: ({ palette }) => palette.secondary[500]
                    }}
                    startIcon={<AddCircleOutline />}
                    disabled={isDisabled}
                    onClick={e => addEntity(e)}
                >
                    Entity
                </StriimButton>
            </Box>
        </Box>
    );
};

export default ActionsOnEntities;
