import { Box, Grid } from "@mui/material";
import { StriimButton, StriimModal, StriimTypography } from "@striim/striim-ui-v2";
import React, { useState, useContext } from "react";
import { Close } from "../icon/customIcons";
import LoadingIndicator from "../loading-indicator";
import App from "app";
import DataTypeValidationTable from "./data-type-validation-table";
import { DeployModalContext } from "./deploy-modal-wrapper";
import { deployApp, exportAsCSV } from "./utils/app-deploy-utils";

interface DataTypeValidationProps {
    appId: string;
    setIsVisible: (isVisible: boolean) => void;
}

const DataTypeValidation: React.FunctionComponent<DataTypeValidationProps> = ({ appId, setIsVisible }) => {
    const [isModalVisible, setisModalVisible] = useState<boolean>(true);
    const [isLoading, setisLoading] = useState<boolean>(false);
    const { dataTypeValidationErrors, deploymentInfo, setStep } = useContext(DeployModalContext);

    function exportErrors() {
        const errors = dataTypeValidationErrors;
        if (!errors) {
            return;
        }
        exportAsCSV(errors, appId);
    }
    function handleCancel() {
        App.vent.trigger("cancel:deployment");
        setisModalVisible(false);
        setIsVisible(false);
        setStep(0);
    }
    async function handleIgnore() {
        setisLoading(true);
        await deployApp(deploymentInfo, appId);
        setisLoading(false);
        setisModalVisible(false);
        setIsVisible(false);
        setStep(0);
    }
    return (
        <StriimModal
            dialogProps={{
                sx: { ".MuiDialog-paper": { width: "auto", minWidth: "1200px" } }
            }}
            size="large"
            autoHeight
            isVisible={isModalVisible}
            footerBorder
            headerBorder
            footerContent={
                <Grid container justifyContent="space-between">
                    <Box>
                        <StriimButton variant="text" onClick={handleCancel}>
                            Cancel
                        </StriimButton>
                    </Box>
                    <Grid>
                        <StriimButton variant="text" onClick={exportErrors}>
                            Export
                        </StriimButton>
                        <StriimButton variant="primary" onClick={handleIgnore}>
                            Ignore
                        </StriimButton>
                    </Grid>
                </Grid>
            }
            titleContent={
                <Box>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <StriimTypography variant="h2" color="primary.700">
                            Table mapping validation
                        </StriimTypography>
                        <div style={{ cursor: "pointer" }} onClick={handleCancel}>
                            <Close />
                        </div>
                    </Grid>
                </Box>
            }
            onCancel={handleCancel}
        >
            {isLoading && <LoadingIndicator isGlobal={false} />}
            <DataTypeValidationTable errors={dataTypeValidationErrors} setisModalVisible={setisModalVisible} />
        </StriimModal>
    );
};

export default DataTypeValidation;
