import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import navigateTo from "../../../navigate-to";
import nProgress from "nprogress";
import { isAutomatedWizard } from "../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";
import { getEncryptedUrl, saveTarget } from "../services";
import sanitizer from "../../../../app/components/appwizard/component-wizards/wizards/target/base-simple-target/server-error-sanitizer";
import growl from "../../../../app/components/common/growl";
import { Steps } from "../target/target-steps";
import metaobjectConverter from "../../../../core/services/metaStoreService/metaobject-converter";
import { getSourceConfig, getTargetConfig } from "../common/review/review.helper";
import { validationUtils } from "./validation-utils";
import { saveHideURLStatus } from "./encrypt-url-utils";
import _ from "underscore";

const targetUtils = {
    getTargetComponentName: function(appName: string): string {
        return appName + "_Target";
    },
    getPredefinedComponentName: function(componentName: string): string {
        return PredefinedComponentNames[componentName].name;
    },
    setTargetSchemaPrefix: function(currentProps) {
        if (currentProps.connectionUrl && currentProps.connectionUrl.indexOf("dbc:snowflake") !== -1) {
            let queryParams = currentProps.connectionUrl.slice(currentProps.connectionUrl.lastIndexOf("?") + 1);
            let vars = queryParams.split("&");
            for (let i = 0; i < vars.length; i++) {
                let pair = vars[i].split("=");
                if (decodeURIComponent(pair[0]) == "db") {
                    return decodeURIComponent(pair[1]) + ".";
                }
            }
        }
        return "";
    },
    getTargetSchema: function(componentName: string, targetSchemaPrefix: string, secondPart: string): string {
        switch (componentName) {
            case "KuduWriter":
                return "%";
            default:
                return targetSchemaPrefix + secondPart + ".%";
        }
    },
    getTargetTable: function(
        componentName: string,
        targetSchemaPrefix: string,
        secondPart: string,
        thirdPart: string
    ): string {
        switch (componentName) {
            case "KuduWriter":
                return thirdPart;
            default:
                return targetSchemaPrefix + secondPart + "." + thirdPart;
        }
    },
    getValidName: function(name: string): string {
        // Remove leading and trailing quotes and escape char before '_'
        // since underscore is not treated as special char as per regex.
        return name
            .replace(/^"/, "")
            .replace(/"$/, "")
            .replace(/\\_/g, "_");
    },
    navigateNext: function(isILCDC: boolean, appName: string): void {
        if (isILCDC) {
            navigateTo.Apps();
        } else {
            navigateTo.AppRedirect(appName);
        }
    },
    skipTargetValidation: function(adapterName: string): boolean {
        return adapterName === "FabricMirrorWriter";
    },
    getEncryptedURLs: async function(sourceInfo, target) {
        let sourceEncryptedURL, targetEncryptedURL;
        const AppName = sourceInfo?.appId;
        const shortAppName = metaobjectConverter.convertFullNameToShortName(AppName);
        // Source
        const sourceData = sourceInfo.sourceAdapter.properties;
        let sourceDB =
            sourceData.adapterName === "DatabaseReader" ? sourceData.DatabaseProviderType : sourceData.adapterName;
        const sourceConfig = getSourceConfig(shortAppName, sourceDB);
        const EncryptedSourceUrl = validationUtils.isRDBMS(sourceDB)
            ? await getEncryptedUrl(sourceDB, sourceConfig, shortAppName, sourceConfig.agentName)
            : null;
        // Target
        const targetDB = validationUtils.getTargetType(target);
        const targetConfig = getTargetConfig(shortAppName, targetDB);
        const EncryptedTargetUrl = validationUtils.isRDBMS(targetDB)
            ? await getEncryptedUrl(targetDB, targetConfig, shortAppName)
            : null;
        try {
            const sourceURL = JSON.parse(EncryptedSourceUrl);
            sourceEncryptedURL = _.isEmpty(sourceURL) ? null : sourceURL;
            const targetURL = JSON.parse(EncryptedTargetUrl);
            targetEncryptedURL = _.isEmpty(targetURL) ? null : targetURL;
            saveHideURLStatus(shortAppName, _.isEmpty(sourceURL) ? null : true);
            return { source: sourceEncryptedURL, target: targetEncryptedURL };
        } catch (e) {
            console.error(e);
        }
    },
    saveTargetAndNavigate: function(
        target,
        sourceInfo,
        targetInfo,
        cdcSourceInfo,
        cdcTargetInfo,
        wizardType,
        sourceEncryptedURL = null,
        targetEncryptedURL = null,
        setCurrentStep
    ) {
        nProgress.start();
        const isAutomatedILCDC = isAutomatedWizard();
        saveTarget(
            target,
            sourceInfo,
            targetInfo,
            isAutomatedILCDC,
            cdcSourceInfo,
            cdcTargetInfo,
            wizardType,
            sourceEncryptedURL,
            targetEncryptedURL
        )
            .then(
                () => setCurrentStep(Steps.Review),
                e => {
                    let msg = "Please correct the errors and try again.";
                    if (Array.isArray(e)) {
                        msg += e.join(", ");
                    } else {
                        msg += sanitizer.sanitize(e.message);
                    }
                    growl.error(msg, "Error saving your target.");
                }
            )
            .finally(() => nProgress.done());
    }
};
export { targetUtils };
