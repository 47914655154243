export const styles = {
    cardContent: {
        height: "100%",
        border: ({ palette }) => `1px solid ${palette.greyscale["200"]}`,
        borderRadius: 2,
        borderTopLeftRadius: 0,
        padding: 0,
        "&:last-child": {
            padding: 0
        }
    },
    fullHeight: {
        height: 'calc(100vh - 180px)',
    },
    monitorContainer: {
        display: "flex",
        height: "100%",
        "> div": {
            transition: "all 0.3s"
        }
    },
    iconButton: {
        minWidth: "unset",
        px: 1,
        py: 1 / 2,
        "& .MuiButton-startIcon": { svg: { fontSize: 16 }, mx: 0 }
    },
    expandedContent: {
        width: 0,
        overflow: "hidden"
    },
    collapsedContent: {
        width: "100%"
    },
    icon: {
        fill: "none",
        "& path": { stroke: ({ palette }) => `${palette.greyscale["900"]}` }
    },
    sidebar: {
        borderLeft: ({ palette }) => `1px solid ${palette.greyscale["200"]}`
    },
    headerContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 1,
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale["200"]}`
    },
    closedHeader: {
        display: "flex",
        padding: "8px",
        justifyContent: "end"
    },
    refreshedTime: {
        display: "flex",
        alignItems: "center",
        gap: 1 / 2,
        color: "greyscale.700",
        "& svg": {
            fill: "none"
        }
    },
    closeIcon: {
        transform: "rotate(180deg)"
    },
    sidebarContent: {
        borderRight: ({ palette }) => `1px solid ${palette.greyscale["200"]}`,
        height: "calc(100% - 53px)",
        overflowY: "scroll",
        overflowX: "hidden"
    },
    appLink: {
        cursor: "pointer",
        color: theme => theme.palette.secondary[500]
    }
};
