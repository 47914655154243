// A base HighCharts mixin
import App from "app";
import "app/components/dashboard/visualization/charts/seriesgenerators/default/generator";

App.module("Chart.SeriesGenerators.LatLong", function (Generator, App, Backbone, Marionette, $, _) {
    Generator.SeriesGeneratorFactory = function () {};

    Generator.SeriesGeneratorFactory.prototype.create = function (options) {
        return new LatLongSeriesGenerator(options);
    };

    function LatLongSeriesGenerator(options) {
        this.latitudeField = options.latitudeField;
        this.longitudeField = options.longitudeField;
        this.valueField = options.valueField;
        this.minBubbleSize = options.minBubbleSize;
        this.maxBubbleSize = options.maxBubbleSize;

        App.Chart.SeriesGenerators.Default.SeriesGenerator.prototype.setOptions.call(this, options);
    }

    LatLongSeriesGenerator.prototype = new App.Chart.SeriesGenerators.Default.SeriesGenerator();
    LatLongSeriesGenerator.prototype.generate = function () {
        if (typeof this.data !== "undefined") {
            var sourceData = this.getFlatData(this.categoryField, this.data);
            var dataSeries = this.generateSeries(sourceData);
        }
        return dataSeries;
    };

    LatLongSeriesGenerator.prototype.generateSeries = function (sourceData) {
        var _this = this;
        var s = [];
        _(sourceData).each(function (row, i) {
            var dataPoint = _this.generatePoint(row);

            var colorAliasValue = _this.categoryField;
            if (_this.colorField !== "") {
                colorAliasValue = _this.colorField;
            }
            var colorAndAlias = _this.getPointColorAndAlias(row, colorAliasValue);
            dataPoint.color = colorAndAlias.color;

            if (!_this.isKeyInLegendData(colorAndAlias.alias)) {
                _this.addKeyAndColorToLegendData(colorAndAlias.alias, colorAndAlias.color);
            }

            s.push(dataPoint);
        });

        return s;
    };

    LatLongSeriesGenerator.prototype.generatePoint = function (row) {
        return {
            lat: row[this.latitudeField],
            lon: row[this.longitudeField],
            z: row[this.valueField],
            value: row[this.valueField],
            rowData: row,
            tooltipConfig: this.tooltipConfig,
            fieldList: this.fieldList,
        };
    };
});
