import React, { ReactElement } from "react";
import { Grid, SvgIcon } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui";
import { HistoryToggle, Rotate } from "../../../../../generic/icon/customIcons.jsx";
import { styles } from "../create-app-name-app-page-styles";
import APPTYPE from "../../create-app-page-templates/services/create-app-page-app-types.const.json";
import { DB_PROVIDERS } from "../../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/configs/db-providers.js";
import licenseService from "../../../../../../core/services/metaStoreService/license-service.js";

interface IconTextGridPropTypes {
    IconComponent: string;
    title: string;
    content: string;
}

const IconTextGrid: React.FunctionComponent<IconTextGridPropTypes> = ({ IconComponent, title, content }) => (
    <Grid container sx={styles.iconContainer}>
        <Grid item>
            <SvgIcon component={IconComponent} sx={styles.icon} />
        </Grid>
        <Grid item sx={styles.iconContentItem}>
            <StriimTypography variant="body3" color="greyscale.700">
                {title}
            </StriimTypography>
            <StriimTypography variant="body4" color="greyscale.700">
                {content}
            </StriimTypography>
        </Grid>
    </Grid>
);

const getNameAppTypeInfo = (source: string, target: string, fileName: string): object => {
    return {
        DEFAULT: {
            title: "Name Your App",
            content: "",
            img_src:
                "/src/modules/apps/pages/create-app-name-app-page-templates/components/create-app-name-app-page-preview/preview-images/Automated-ILCDC.png"
        },
        AUTOMATEDILCDC: {
            title: "Name Your Automated Pipeline",
            preview: "A preview of the pipeline after connecting to the source and target.",
            img_src:
                "/src/modules/apps/pages/create-app-name-app-page-templates/components/create-app-name-app-page-preview/preview-images/Automated-ILCDC.png",
            content: (
                <Grid>
                    <StriimTypography variant="body4" color="greyscale.700">
                        This template creates two apps to sync data
                    </StriimTypography>
                    <IconTextGrid
                        IconComponent={HistoryToggle}
                        title={"Initial Load App"}
                        content={`Creates matching schemas in ${target} and loads existing table rows from ${source}.`}
                    />
                    <IconTextGrid
                        IconComponent={Rotate}
                        title={"Change Data Capture App"}
                        content={
                            " Once the IL is completed the CDC app automatically starts reading and writing the table changes."
                        }
                    />
                </Grid>
            )
        },
        SQL2FABRICSOLUTIONAUTOMATEDILCDC: {
            title: "Name your Automated Mirroring Pipeline",
            preview: "Preview of the two pipelines (Initial Load and CDC) mirroring data into Fabric.",
            img_src:
                "/src/modules/apps/pages/create-app-name-app-page-templates/components/create-app-name-app-page-preview/preview-images/Fabric-Automated-ILCDC.png",
            content: (
                <Grid>
                    <StriimTypography variant="body4" color="greyscale.700">
                        This will create two Striim Apps (or data pipelines) to mirror data to Fabric
                    </StriimTypography>
                    <IconTextGrid
                        IconComponent={HistoryToggle}
                        title={"Initial Load App (first Striim App)"}
                        content={`Mirrors initial snapshot data to Fabric. Creates matching schemas in Fabric and loads existing table rows from on-premise SQLServer.`}
                    />
                    <IconTextGrid
                        IconComponent={Rotate}
                        title={"Change Data Capture App (second Striim App)"}
                        content={
                            "Once the selected Tables Snapshot is completed, this app will continuously mirror ongoing changes to those tables."
                        }
                    />
                </Grid>
            )
        },
        //To Do : Update the info's whenever the content is provided by UX Team
        IL: {
            title: "IL APP",
            content: "",
            img_src: ""
        },
        CDC: {
            title: "CDC APP",
            content: "",
            img_src: ""
        },
        AUTOMATEDMODE: {
            title: "Automated Mode",
            content: "",
            img_src: ""
        },
        IMPORTAPP: {
            title: "Importing " + fileName,
            content: "",
            img_src: ""
        }
    };
};

const getAppType = (templateTitle: string, sourceAdapter: string, isILCDC: boolean) => {
    if (!templateTitle) return APPTYPE.DEFAULT;

    const CDCKeyword = ["CDC", "MSJet", "OJet"];

    if (isILCDC) {
        return APPTYPE.AUTOMATEDILCDC;
    } else if (CDCKeyword.some(keyword => templateTitle.includes(keyword))) {
        return APPTYPE.CDC;
    } else if (templateTitle.includes("Initial Load")) {
        return APPTYPE.IL;
    } else if (DB_PROVIDERS[sourceAdapter?.toUpperCase()]?.isAppAdapter) {
        return APPTYPE.AUTOMATEDMODE;
    }
    return APPTYPE.DEFAULT;
};

const getCurrentAppTypeInfo = (
    appType: string,
    templateTitle: string,
    sourceAdapter: string,
    targetAdapter: string,
    isILCDC: boolean,
    fileName: string
) => {
    const appTypeToUse = appType || getAppType(templateTitle, sourceAdapter, isILCDC);
    const licenseSpecificAppType = licenseService?.nonSegmentedlicenseName + appTypeToUse;
    const appTypeInfo = getNameAppTypeInfo(sourceAdapter, targetAdapter, fileName);
    return appTypeInfo[licenseSpecificAppType] || appTypeInfo[appTypeToUse];
};

export default getCurrentAppTypeInfo;
