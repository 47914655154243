import Backbone from "backbone";
import { View } from "../database-reader/base-database-reader";
import { DB_PROVIDERS } from "../database-reader/configs/db-providers";
const module = {};
class StripeReaderView extends View {
    setup() {
        this.label = "Stripe";
        this.model.get("connectionParams").set("type", DB_PROVIDERS.STRIPE.id);
        this.model.set("isCDC", false);
    }
}

module.View = StripeReaderView;
module.vent = new Backbone.Model();
export default module;
