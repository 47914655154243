import $ from "jquery";
import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./checkbox-list.html";

var View = BaseControl.View.extend({
    template: _.template(template),

    getViewValue: function () {
        return this.$("input:checked")
            .map(function () {
                return this.value;
            })
            .get();
    },

    setViewValue: function () {
        var _this = this;
        var valueArray = this.getValue();
        this.$("input").each(function (index, item) {
            _this.$(item).prop("checked", valueArray.indexOf($(item).val()) !== -1);
        });
    },
});

export default function (dataSource) {
    var Model = BaseControl.Model.extend({
        defaults: {
            dataSource: dataSource,
            value: Array,
            name: Math.random(),
        },
    });

    return _.extend({}, BaseControl, {
        Model: Model,
        View: View,
    });
}
