import React from "react";
import { Box, Grid } from "@mui/material";
import styles from "./frame.styles";
import LoadingIndicator from "../../../../generic/loading-indicator/loading-indicator";
import InlineDocPanel from "../../../../generic/inline-doc/inline-doc-panel";
import InlineDocContainer from "../../../../generic/inline-doc/inline-doc-container";
import InlineDocDictionary from "../../../../generic/inline-doc/inline-doc-dictionary";

interface FrameProps {
    showLoader?: boolean;
    Header?: React.ReactNode;
    Footer?: React.ReactNode;
    children: React.ReactNode;
    inlineDocKey?: string;
}

// Frame is a dummy component that jus renders everything received from Wizard Layout

const Frame = ({ Header, Footer, children, showLoader = false, inlineDocKey }: FrameProps) => {
    const hasHeader = !!Header;
    const hasFooter = !!Footer;
    const docData = InlineDocDictionary[inlineDocKey] || {
        path: inlineDocKey ? inlineDocKey + ".html" : "",
        cssSelector: "section"
    };
    return (
        <Grid container sx={styles.wrapper} data-content-path={docData.path + ":" + docData.cssSelector}>
            <InlineDocPanel
                path={docData.path}
                inlineDocContent={<InlineDocContainer path={docData.path} className={docData.className} />}
            >
                {hasHeader && (
                    <Grid item sx={styles.headerContainer}>
                        {Header}
                    </Grid>
                )}
                <Grid item sx={styles.childContainer}>
                    {showLoader ? <LoadingIndicator isGlobal={false} /> : <Box sx={{ m: 3 }}>{children}</Box>}
                </Grid>
            </InlineDocPanel>

            {hasFooter && (
                <Grid item sx={styles.footerContainer}>
                    {Footer}
                </Grid>
            )}
        </Grid>
    );
};

export default Frame;
