import api from "core/api/api";
import _ from "underscore";
import fileLineageMetadataManager from "./fileLineageMetadataManager";
import oracleLineageMetadataManager from "./oracleLineageMetadataManager";

var module = {};
module._templates = [];
module._isEnabled = false;

module.specificTemplatesManagers = [fileLineageMetadataManager, oracleLineageMetadataManager];

module.init = function () {
    _.each(module.specificTemplatesManagers, function (manager) {
        manager.init();
    });
};

function normalizeTemplateId(templateID) {
    return templateID.replace("Global.PROPERTYTEMPLATE.", "");
}

module.getSpecificManager = function (templateID) {
    if (!templateID || !_.isString(templateID)) {
        return null;
    }

    var normalizedTemplateId = normalizeTemplateId(templateID);

    var result = false;
    _.each(module.specificTemplatesManagers, function (manager) {
        if (manager._templates.indexOf(normalizedTemplateId) !== -1) {
            result = manager;
        }
    });

    return result;
};

export default module;
