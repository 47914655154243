import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing, palette }) => ({
    wrapper: {
        minWidth: 368
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: 60,
        padding: `0 ${spacing(2)}px`
    },
    content: {
        display: "flex",
        flexDirection: "column",
        gap: spacing(2.5),
        margin: `0 ${spacing(2)}px ${spacing(2)}px`
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        minHeight: 60,
        position: "relative",
        marginTop: 0,
        borderTop: `1px solid ${palette.gray1}`
    },
    actionButton: {
        marginRight: spacing(2)
    },
    divider: {
        position: "absolute",
        left: 0,
        right: 0,
        borderTop: `1px solid ${palette.paper.border}`
    },
    cancel: {
        marginRight: spacing(3)
    },
    addToGroup: {
        color: "#00A7E5",
        fontSize: "14px !important",
        fontWeight: "600 !important",
        marginRight: `${spacing(3)}px !important`
    },
    addToGroupBtn: {
        marginRight: spacing(3)
    }
}));
