import _ from "underscore";
import App from "app";
import api from "core/api/api";
import FormBuilder from "app/components/common/editor/form-builder";
import UIControl from "app/components/common/editor/control/ui-control";
import navigateTo from "src/navigate-to";
import template from "./templates/appSettings.html";
import growl from "app/components/common/growl";
import "tooltips";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import { initMaterialDesignTabs } from "../../../../core/utils/material-design/tabs/material-design-style-tabs";
import Backbone from "backbone";

const defaultAutoResumeRetryInterval = 60;
const defaultAutoResumeRetryCount = 2;

var AppSettings = {};

var AppSettingsView = Marionette.ItemView.extend({
    options: {
        readOnly: false
    },
    initialize: function() {
        this.form = new FormBuilder({
            model: this.model,
            submitText: "Save",
            cancelText: "Cancel",
            readOnly: this.options.readOnly
        });
        /*const addTitleClass = (text, id) =>
            this.form.addStaticControl(UIControl.Header(text).extend({
                tag: "h3",
                classList: ["settingsTitle"]
            }).create(), id);*/

        this.dataValidationToggle = UIControl.Toggle.extend({
            hideLabel: true,
            description: "Enable"
        }).create();
        this.form.addStaticControl(
            UIControl.Header("Enable Data Validation")
                .extend({ tag: "h4" })
                .create(),
            "enableDataValidationHeader"
        );
        this.form.addControl(
            this.dataValidationToggle,
            "isDataValidationEnabled",
            undefined,
            addonFeaturesKeys.DATAVALIDATION
        );

        //addTitleClass("Recovery","recoveryHeader");
        this.form.addRequiredControl(UIControl.Seconds.create(), "recoveryPeriod");

        //addTitleClass("Encryption","encryptionHeader");
        this.form.addControl(
            UIControl.Toggle.extend({
                description: "Enable"
            }).create(),
            "encrypted"
        );

        if (!this.options.readOnly) {
            //addTitleClass("Load/Unload Open Processor","loadPCHeader");
            this.form.addStaticControl(UIControl.ProcessComponentLoader().create(), "loadPC");
        }

        let hideJavaPackages =
            this.options.readOnly && (!this.model.importStatements || this.model.importStatements.length === 0);
        if (!hideJavaPackages) {
            //addTitleClass("Java Package", "javaPackageHeader");
            this.form.addControl(
                UIControl.StringList("Add Another Package", "Package list is empty").create(),
                "importStatements"
            );
        }

        //addTitleClass("Exception Handling","exceptionHandlingHeader");
        this.form.addControl(
            UIControl.Ehandlers({
                properties: [
                    "AdapterException",
                    "ArithmeticException",
                    "ClassCastException",
                    "ConnectionException",
                    //"DBConnectionException",
                    //"InterruptedException",
                    "InvalidDataException",
                    "NullPointerException",
                    "NumberFormatException",
                    //"MetadataUnavailableException",
                    //"RejectedExecutionException",
                    //"SourceFileConnectionException",
                    "SystemException",
                    "UnExpectedDDLException",
                    "UnknownException"
                ],
                options: [
                    {
                        name: "Ignore",
                        value: "IGNORE"
                    },
                    {
                        name: "Stop Processing",
                        value: "STOP"
                    }
                ]
            }).create(),
            "eHandlers"
        );

        //addTitleClass("Auto-resume", "autoResumeHeader");
        this.enableAutoResumeControl = UIControl.Toggle.extend({
            //hideLabel: true,
            description: "Enable"
        }).create();
        this.form.addControl(this.enableAutoResumeControl, "enableAutoResume");
        this.autoResumeRetryIntervalControl = UIControl.PositiveNumberField.extend({
            defaultValue: defaultAutoResumeRetryInterval,
            subControl: true
        }).create();

        this.form.addControl(this.autoResumeRetryIntervalControl, "autoResumeRetryInterval");
        this.autoResumeRetryCountControl = UIControl.PositiveNumberField.extend({
            defaultValue: defaultAutoResumeRetryCount,
            subControl: true
        }).create();
        this.form.addControl(this.autoResumeRetryCountControl, "autoResumeRetryCount");

        //this.form.addControl(UIControl.StringList("Add Another Email").create(), 'notifyEmail');
        //addTitleClass("Application Information", "informationHeader");
        this.form.addControl(UIControl.TextOnly("MM/DD/YYYY", "Created: ").create(), "ctime");
    }
});

var DeploymentAndTestView = Marionette.ItemView.extend({
    initialize: function() {
        this.form = new FormBuilder({
            model: this.model,
            submitText: "Save",
            cancelText: "Cancel",
            readOnly: this.options.readOnly
        });
        this.form.addStaticControl(
            UIControl.InfoBox({
                message: "You can view your deployment plan here after you deploy your application.",
                noCustomIcon: true
            }).create()
        );
        //this.form.addStaticControl(UIControl.Header("Deployment Plan").extend({tag: "h4"}).create(), "deploymentPlanHeader");
        var deploymentPlanList = UIControl.DeploymentPlanList()
            .extend({
                //hideLabel: true,
            })
            .create();
        this.form.addControl(deploymentPlanList, "deploymentInfo");
        //deploymentPlanList is readonly
        deploymentPlanList.setEnabled(false);
    }
});

AppSettings.View = Marionette.LayoutView.extend({
    template: _.template(template),

    currentTabView: null,

    shouldCloseOnStatusChange: true,

    regions: {
        tabs: ".tabs",
        editors: ".editors"
    },

    ui: {
        appSettingsLink: ".tabs .appSettings",
        deploymentAndTestLink: ".tabs .deploymentAndTest",
        previewButton: ".preview-button",
        editors: ".editors"
    },

    initialize: function(options) {
        this.deployment = options.deployment;
        this.readOnly = options.readOnly;
    },

    showAppSettings: function() {
        this.ui.appSettingsLink.addClass("active");
        this.ui.deploymentAndTestLink.removeClass("active");

        this.currentTabView = new AppSettingsView({
            model: this.model,
            readOnly: this.readOnly
        });

        this._handleTabView();
        this._handleAutoResumeState();
    },

    showDeploymentSettings: function() {
        this.ui.appSettingsLink.removeClass("active");
        this.ui.deploymentAndTestLink.addClass("active");
        this.currentTabView = new DeploymentAndTestView({
            model: this.model,
            readOnly: this.readOnly
        });

        this._handleTabView();
    },
    disableDataValidationEnableFormFields: function() {
        if (
            this.currentTabView &&
            this.currentTabView.form &&
            this.currentTabView.dataValidationToggle &&
            this.currentTabView.form.model &&
            this.currentTabView.form.model.get("readOnly") !== true
        ) {
            this.currentTabView.dataValidationToggle.$el.attr(
                "title",
                "This application does not support Data Validation"
            );
            this.currentTabView.dataValidationToggle.$el.tooltipster({
                animation: "grow",
                position: "top",
                offsetX: "0px",
                contentAsHTML: true
            });
            this.currentTabView.dataValidationToggle.$("input").prop("disabled", true);
        }
    },
    onRender: function() {
        this.ui.previewButton.hide();

        this.ui.appSettingsLink.on(
            "click",
            function(event) {
                event.preventDefault();
                this.showAppSettings();
                this.$el
                    .find(".actions .submit")
                    .removeAttr("disabled")
                    .removeClass("disabled");
                navigateTo.AppSettings(this.model.nsName + "." + this.model.name);
            }.bind(this)
        );
        this.ui.deploymentAndTestLink.on(
            "click",
            function(event) {
                event.preventDefault();
                this.showDeploymentSettings();
                this.$el
                    .find(".actions .submit")
                    .attr("disabled", "disabled")
                    .addClass("disabled");
                navigateTo.DeploymentSettings(this.model.nsName + "." + this.model.name);
            }.bind(this)
        );

        if (this.deployment) {
            this.showDeploymentSettings();
            this.$el
                .find(".actions .submit")
                .attr("disabled", "disabled")
                .addClass("disabled");
        } else {
            this.showAppSettings();
        }
    },

    isDirty: function() {
        return this.currentTabView.form && this.currentTabView.form.model.isDirty;
    },

    _handleTabView: function() {
        var _this = this;
        var oldIsEligibleForDataValidation = this.model.get("isDataValidationEnabled");

        let form = this.currentTabView.form.create();
        this.editors.show(form);
        form.startTracking();
        api.isEligibleForDataValidation(this.model.get("id")).then(function(isEligible) {
            if (!isEligible) {
                _this.disableDataValidationEnableFormFields();
            }
        });

        this.listenTo(this.currentTabView.form, "form-builder:save", function() {
            // FIXME deploymentInfo saving doesn't work at the moment (03/31/2016), so we're nulling :D
            _this.model.deploymentInfo = null;

            if (oldIsEligibleForDataValidation !== _this.model.get("isDataValidationEnabled")) {
                api.setEnableDataValidation(_this.model.get("id"), _this.model.get("isDataValidationEnabled"))
                    .then(function() {
                        oldIsEligibleForDataValidation = _this.model.get("isDataValidationEnabled");
                        App.reloadPage();
                    })
                    .fail(function() {
                        growl.error("Failed to enable Data Validation", "Error");
                    });
            }

            api.updateApplicationSettings(_this.model.attributes)
                .then(function() {
                    growl.success("App Settings saved");
                })
                .fail(function(error) {
                    if (!error) return;
                    growl.error(error.message, "Failed to save");
                });
        });
        this.listenToOnce(this.currentTabView.form, "form-builder:cancel", function() {
            console.log(_this.model);
            this.trigger("appSettings-cancel");
        });
    },

    _handleAutoResumeState: function() {
        if (
            !this.currentTabView ||
            !this.currentTabView.form ||
            !this.currentTabView.enableAutoResumeControl ||
            !this.currentTabView.autoResumeRetryCountControl ||
            !this.currentTabView.autoResumeRetryIntervalControl
        ) {
            return;
        }

        const enableControl = this.currentTabView.enableAutoResumeControl;
        this._updateAutoResumeControlsState(enableControl.getViewValue() && !this.readOnly);
        this._updateAutoResumeControlsValues(enableControl.getViewValue());

        enableControl.$("input").on("click", () => {
            this._updateAutoResumeControlsState(enableControl.getViewValue());
            this._updateAutoResumeControlsValues(enableControl.getViewValue(), true);
        });
    },

    _updateAutoResumeControlsState: function(enabled) {
        const countControl = this.currentTabView.autoResumeRetryCountControl;
        const intervalControl = this.currentTabView.autoResumeRetryIntervalControl;

        if (enabled) {
            countControl.$("input").prop("disabled", false);
            countControl.setEnabled(true);

            intervalControl.$("input").prop("disabled", false);
            intervalControl.setEnabled(true);
        } else {
            countControl.$("input").prop("disabled", true);
            countControl.setEnabled(false);

            intervalControl.$("input").prop("disabled", true);
            intervalControl.setEnabled(false);
        }
    },

    _updateAutoResumeControlsValues: function(enabled, setDefaultValues) {
        const countControl = this.currentTabView.autoResumeRetryCountControl;
        const intervalControl = this.currentTabView.autoResumeRetryIntervalControl;

        if (enabled) {
            if (setDefaultValues) {
                countControl.setValue(defaultAutoResumeRetryCount);
                intervalControl.setValue(defaultAutoResumeRetryInterval);
            }
        } else {
            countControl.setValue(0);
            intervalControl.setValue(0);
        }
    },

    onShow: function() {
        const ctx = this;
        setTimeout(() => {
            ctx.tabsRemoveCallback = initMaterialDesignTabs(document.querySelector("#app-settings-tabs"));
        }, 0);
    },

    destroy() {
        Backbone.Marionette.LayoutView.prototype.destroy.apply(this, arguments);
        if (this.tabsRemoveCallback) {
            this.tabsRemoveCallback();
        }
    }
});

export default AppSettings;
