import React from "react";
import TestConnection from "../test-connection/test-connection";

export const TEST_PRIMARY_CONNECTION = "TEST_PRIMARY_CONNECTION";

export interface IActionBtn {
    component: React.ReactNode;
    endpoint: string;
    path: string;
    name: string;
    parameters: object;
    parameters_type: string;
    viewWeight: number;
    visibility: boolean | string;
}

interface ActionButtonProps {
    button: IActionBtn;
    formRef: object;
    handler: string;
    propertiesModel: object;
    metaObjectId: string;
    isFormEnabled: boolean;
}

const ActionButton = ({
    button,
    formRef,
    handler,
    propertiesModel,
    metaObjectId,
    isFormEnabled
}: ActionButtonProps) => {
    const getActionButton = () => {
        switch (button?.name) {
            case "TEST_CONNECTION":
            case TEST_PRIMARY_CONNECTION:
                return (
                    <TestConnection
                        handler={handler}
                        propertiesModel={propertiesModel?.toJSON()}
                        metaObjectId={metaObjectId}
                        isFormEnabled={isFormEnabled}
                        buttonProps={button}
                        formRef={formRef}
                    />
                );

            default:
                return null;
        }
    };

    return <>{getActionButton()}</>;
};
export default ActionButton;
