import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import SchemaCreationPage from "../../../../../../table-progress/Components/schema-creation-page/schema-creation-component";
import IlTotalProgress from "../../../../../../table-progress/Components/stacked-linear-progress/stacked-linear-progress";
import automatedGroupStore from "../automated-app-group-details.store";
import statusManagement from "../../../../../../../status-management";
import useProgressStore from "../../../../../../../stores/table-progress/useProgressStore";
import { observer } from "mobx-react";

const AppProgressLinearProgress: React.FunctionComponent = observer(() => {
    const tableProgressStore = useProgressStore();
    const currentStage = automatedGroupStore?.statusInfo?.stageInfo;

    const GetView = useMemo(() => {
        if (automatedGroupStore.isAppTransitioning) {
            return null;
        }
        if (["CREATED", "DEPLOYED", "RESET"].includes(currentStage)) {
            return null;
        }
        if (automatedGroupStore?.currentFocusedApp === "CDC") {
            return null;
        }
        const ilAppStatus = Object.values(automatedGroupStore?.appStatuses ?? {})?.[0];
        if (!ilAppStatus) {
            return null;
        }
        //@ts-ignore
        const isschemaCreationRunning = tableProgressStore?.ilInfo.schemaCreationDetails?.status === "Pending";
        tableProgressStore.setAppModel(automatedGroupStore?.ilAppModel);
        if (!tableProgressStore.elapsedTime) {
            tableProgressStore.getElapsedTime();
        }
        if (isschemaCreationRunning) {
            return <SchemaCreationPage isLite appStatus={ilAppStatus} />;
        } else {
            return <IlTotalProgress appStatus={ilAppStatus} />;
        }
        //@ts-ignore
    }, [
        automatedGroupStore.currentFocusedApp,
        automatedGroupStore.appStatuses,
        tableProgressStore.ilInfo,
        automatedGroupStore.isAppTransitioning,
        automatedGroupStore.ilAppModel
    ]);
    return (
        <React.Fragment>
            <Grid item>{GetView}</Grid>
        </React.Fragment>
    );
});

export default AppProgressLinearProgress;
