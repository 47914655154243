import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { StriimDropdown, StriimIconButton, StriimMenuList, StriimMenuItem } from "@striim/striim-ui";
import { MoreHoriz } from "@material-ui/icons";

import { useNavigate } from "react-router-dom";
import statusManagement from "../../../../status-management";

const AppMetricsActions = ({ setMenuVisible, appData }) => {
    const navigate = useNavigate();
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        setShowErrors(appData?.status ? statusManagement.isApplicationCrash(appData.status) : false);
    }, [appData]);

    return (
        <div
            onClick={e => {
                e.stopPropagation(); //to prevent app selection
            }}
        >
            <StriimDropdown
                extendOnClick
                closeOnSelect
                content={
                    <StriimMenuList className="app-metrics--menu">
                        {showErrors ? (
                            <StriimMenuItem
                                onClick={() => {
                                    navigate("/flow/" + appData["full-name"] + "/view/exceptions");
                                    setMenuVisible(false);
                                }}
                                data-test-id="app-metrics-show-errors--menu"
                            >
                                Show Errors
                            </StriimMenuItem>
                        ) : (
                            <StriimMenuItem
                                onClick={() => {
                                    navigate("/flow/" + appData["full-name"]);
                                    setMenuVisible(false);
                                }}
                                data-test-id="app-metrics-show-flow--menu"
                            >
                                Manage Flow
                            </StriimMenuItem>
                        )}
                    </StriimMenuList>
                }
            >
                <StriimIconButton>
                    <Box
                        component={MoreHoriz}
                        color="secondary.main"
                        data-test-id={`${appData?.["full-name"]}-app-metrics--menu`}
                    />
                </StriimIconButton>
            </StriimDropdown>
        </div>
    );
};

export default AppMetricsActions;
