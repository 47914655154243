import NestedTypes from "backbone.nestedtypes";
import $ from "jquery";
import _ from "underscore";
import App from "app";
import utils from "core/utils";
import growl from "app/components/common/growl";
import ModalManager from "app/components/common/modal/ModalManager";
import AppExceptions from "app/components/flow/designer/appErrors/appExceptions";
import MetaObjectEditors from "app/components/common/editor/meta-object-editors";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import MetaStoreService from "core/services/metaStoreService/meta-store-service";
import MetaObjects from "core/services/metaStoreService/meta-objects";
import navigateTo from "src/navigate-to";
import GraphNodeModel from "./graphNodes/graphNodeModel";
import SidebarViewBase from "./SidebarViewBase";
import statusManagement from "src/status-management";
import localErrorsStorage from "./appErrors/localErrorsStorage";
import focuser from "./focuser";
import lineageMetadataModal from "./lineageMetadata/lineageMetadataModal";
import lineageMetadataManagerProvider from "./lineageMetadata/lineageMetadataManagerProvider";
import securityService from "core/services/securityService/securityService";
import { segmentationTooltip, segmentationIndicator } from "app/components/common/segmentationComponents";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import { loadAscendantsSources } from "app/components/flow/designer/component-acendants-loader";
import NProgress from "nprogress";
import { parseHtml } from "./appErrors/exception-helper";

var Sidebar = {};
var modalManager = new ModalManager({
    container: "body"
});

Sidebar.Model = NestedTypes.Model.extend({
    defaults: {},
    currentForm: null
});

Marionette.ItemView.extend({
    className: "unsupported-editor",
    template: `<div >
                         <h3> Routers cannot be edited from UI in this version. Please use the Router from the Tungsten Console.</h3>
                </div>`
});

function _showProgressBar() {
    NProgress.configure({
        parent: ".sidebar-actions",
        minimum: 0.25,
        trickleRate: 0.1,
        trickleSpeed: 800
    });
    NProgress.start();
}

function _hideProgressBar() {
    NProgress.done();
}

Sidebar.View = SidebarViewBase.extend({
    className: "sidebar-flex-container",

    events: {
        "click @ui.previewButton": "onPreviewClick",
        "click @ui.exceptionsClear": "clearException"
    },

    // preview button should be visible only for Stream nodes
    _currentModelType: null,

    previewVisibility: function (app_status) {
        if (this._currentModelType !== utils.ENTITIES.STREAM) {
            return;
        }

        var titleLi = this.editors.$el.find(".controls li:first");

        if (statusManagement.isApplicationRunning(app_status) || statusManagement.isDeployedState(app_status)) {
            titleLi.addClass("with-preview-button");
            titleLi.prepend(this.ui.previewButton);
            this.ui.previewButton.show();
        } else {
            titleLi.removeClass("with-preview-button");
            this.ui.previewButton.hide();
        }
    },

    initialize: function (options) {
        this.options = options || {};
        this.appShortName = this.options.flowId.split(".")[2];
    },

    setAppStatus: function (app_status) {
        if (!this.model.currentForm) {
            return;
        }

        var shouldBeReadOnly = !statusManagement.isApplicationCreated(app_status);
        this.model.currentForm.setReadOnly(shouldBeReadOnly);
        this.onSidebarResize(10);
        this.previewVisibility(app_status);
        this.metadataVisibility(app_status);
    },

    isDirty: function () {
        return this.model.currentForm && this.model.currentForm.model.isDirty;
    },

    /**
     * This method creates the form for the sidebar
     * @param {object}  model           - the model of the selected metaobject
     * @param {array}   sourceAdapters  - list of source adapters passed specifically for DataBaseWriter
     * @returns void                    - creates the form
     */
    _createForm: function (model, sourceAdapters = null) {
        const _this = this;
        // init form
        var form = MetaObjectEditors.createForMetadataObject(model, {
            app: _this.options.app,
            ...(sourceAdapters && { sourceAdapters: sourceAdapters })
        });

        form.on("save", function (changedControls) {
            // we always save FD meta objects with application's namespace
            model.nsName = _this.options.app.nsName;

            // @TODO: ask Basar if we can remove this hack
            if (
                model.type === "SOURCE" &&
                (model.outputclause === null ||
                    (_.isArray(model.outputclause) && model.outputclause.length === 1 && !model.outputclause[0].select))
            ) {
                model.outputclause = [
                    {
                        outputStream: model.outputStream
                    }
                ];
            }

            _showProgressBar();

            model
                .save({
                    flow: _this.appShortName // TODO this should be full flowId
                })
                .then(function () {
                    _this.trigger("sidebar-save", {
                        id: model.id,
                        nodeTargets: _.map(model.targets, _.clone)
                    });
                    _this.hideCrudException();
                    _this.model.currentForm.setDefault();
                    growl.success("Saved " + model.name, "Component saved");
                    _hideProgressBar();
                    _this.showPIIPopover(model);
                })
                .fail(function (error) {
                    _hideProgressBar();
                    console.log(error);
                    form.model.dirtyControls = changedControls;
                    form.model.refreshDirty();
                    _this._scrollToFirstError();

                    if (!error) {
                        return;
                    }

                    growl.logError(null, error.message, model.id);
                    _this.showCrudException(error.message);
                });
        });

        form.on("cancel", function () {
            _this.trigger("sidebar-cancel");
        });

        form.on("validation-errors", () => {
            _this._scrollToFirstError();
        });

        form.on("go-to-wizard", function (sourceTargetConfig) {
            var shortAppName = MetaObjectConverter.convertFullNameToShortName(_this.options.app.id);
            const navigateParams = {
                appName: shortAppName, 
                templateId:sourceTargetConfig,
                stepId: 0,
                origin:window.location.hash,
                componentName:form.getCurrentValue("name"),
                force: true
            }
            navigateTo.AppWizardTemplate(navigateParams);
        });

        if (!_this.editors) {
            // if editors are not set it means that sidebar was not completely loaded
            // and user clicked on another node
            // There should be no further processing
            return;
        }

        _this.editors.show(form);

        _this._currentModelType = model.type;
        _this.previewVisibility(_this.options.app.flowStatus);
        _this.metadataVisibility(_this.options.app.flowStatus);

        form.addCustomActionDelete(() => {
            _this.trigger("delete");
        }, {
            classes: "critical"
        });

        var shouldBeReadOnly = !statusManagement.isApplicationCreated(_this.options.app.flowStatus);

        // when WactionStore is an exception store, then editor should be readonly
        if (model.get("isExceptionstore")) {
            shouldBeReadOnly = true;
        }

        form.setReadOnly(shouldBeReadOnly);

        _this.onSidebarResize(10);
        _this.model.currentForm = form;
        _this.model.currentForm.startTracking();

        _this.$el.find(".meta-object-editor").addClass("sidebar-container");
        _this.$el.find(".meta-object-editor>.controls").addClass("sidebar-box");
        _this.$el.find(".meta-object-editor>.actions.card").addClass("sidebar-box sidebar-actions");
    },

    onRender: function () {
        var _this = this;

        var promise;

        if (this.model.id !== GraphNodeModel.prototype.tempNodeId) {
            promise = MetaStoreService.findById(this.model.id);
        } else {
            promise = $.when(new MetaObjects[this.model.metaObject.type].Model());
        }

        this.listenTo(
            App.vent,
            "forms:clear-dirty",
            function (editorModel) {
                if (!this.model.currentForm) {
                    return;
                }

                this.model.currentForm.clearDirty(editorModel);
            }.bind(this)
        );

        this.previewVisibility(this.options.app.flowStatus);
        const hasLineageLicense = this.metadataVisibility(this.options.app.flowStatus);
        if (hasLineageLicense) {
            this.ui.metadataButton.click(this.onMetadataButtonClick.bind(this));
        }

        promise.then(function (model) {
            var autoProperties = _this.model.metaObject.autoProperties;
            if (autoProperties) {
                for (var prop in autoProperties) {
                    if (autoProperties.hasOwnProperty(prop)) {
                        model.set(prop, autoProperties[prop]);
                    }
                }
            }

            // check if it is DataBaseWriter
            if (
                model.type === "TARGET" &&
                model?.attributes?.adapter?.handler === "Global.PROPERTYTEMPLATE.DatabaseWriter" &&
                model?.attributes?.inputStream
            ) {
                // Pass in source adapter information for tables tooltip
                loadAscendantsSources(model.attributes.inputStream).then(sources => {
                    let srcAdapters = sources.map(src => src?.attributes?.adapter?.handler);
                    _this._createForm(model, srcAdapters);
                });
            } else {
                _this._createForm(model);
            }
        });

        this._showAppExceptions();
    },

    onShow: function () {
        this.initSideBarResizer();

        this.$el.find(".actions a.icon").tooltipster({
            position: "top"
        });
    },

    _scrollToFirstError: function () {
        function isInViewPort(element) {
            const delta = 100;

            let $window = $(window);
            let $element = $(element);

            let pageTop = $window.scrollTop();
            let pageBottom = pageTop + $window.height();
            let elementTop = $element.offset().top;
            let elementBottom = elementTop + $element.height();

            return pageTop + delta < elementTop && pageBottom - delta > elementBottom;
        }

        let errors = $(".error.message, .Mui-error");
        if (errors.length > 0) {
            let error = errors[0];
            if (!isInViewPort(error)) {
                error.scrollIntoView();
            }
        }
    },

    setSidebarEnabled: function (isEnabled) {
        if (!this.model.currentForm) {
            return;
        }
        this.model.currentForm.setActionButtonEnabled("delete", isEnabled);
    },

    hideCrudException: function () {
        this.ui.exceptionsContainer.toggleClass("hidden", true);
        this.onSidebarResize(10);
    },

    showCrudException: function (message) {
        var _this = this;
        _this.ui.exceptionsContainer.toggleClass("hidden", false);
        _this.ui.exceptionsContainer.find(".exception-summary").toggleClass("hidden", true);

        var crudExceptionView = new AppExceptions.CRUDExceptionView({
            message: message
        });
        crudExceptionView.on("show", function () {
            _this.onSidebarResize(10);
        });

        _this.exceptions.show(crudExceptionView);

        _this.ui.exceptionsContainer.css("width", _this.ui.exceptionsContainer.parent().width() + "px");
        setTimeout(function () {
            _this.ui.exceptionsContainer.css("width", _this.ui.exceptionsContainer.parent().width() + "px");
        }, 50);
    },

    showPIIPopover: function(model) {
        //if its the first source - > show PII popover
        if (model.type === "SOURCE") {
            const hidePIIPopover = JSON.parse(localStorage.getItem("hidePIIPopover")) || false;
            if (!hidePIIPopover) {
                App.vent.trigger("showPIIPopover");
                localStorage.setItem("hidePIIPopover", JSON.stringify(!hidePIIPopover));
            }
        }
    },

    _showAppExceptions: function () {
        var _this = this;

        this.ui.editors.css("height", "");

        var flowErrors = localErrorsStorage.load(this.options.app);
        flowErrors.map(err => (err.message = parseHtml(err.message)));

        var relatedToCurrentComponent = _.filter(flowErrors, function (e) {
            return _this.model.metaObject.id === e.nsName + "." + e.componentType + "." + e.componentName;
        });

        if (relatedToCurrentComponent.length <= 0) {
            setTimeout(function () {
                //const height = _this.ui.exceptionsContainer.innerHeight();
                //_this.$el.find("div.actions").css("bottom", `${height}px`);
            }, 50);
            return;
        }
        _this.ui.exceptionsContainer.toggleClass("hidden", false);
        _this.ui.exceptionsContainer.find(".exception-summary").toggleClass("hidden", false);

        var collection = new AppExceptions.Collection(relatedToCurrentComponent);
        var exceptionCollectionView = new AppExceptions.ExceptionCollectionView({
            collection: collection
        });

        _this.exceptions.show(exceptionCollectionView);

        //_this.ui.exceptionsContainer.css("width", _this.ui.exceptionsContainer.parent().width() + "px");
        setTimeout(function () {
            //_this.ui.exceptionsContainer.css("width", _this.ui.exceptionsContainer.parent().width() + "px");
            //const height = _this.ui.exceptionsContainer.innerHeight();
            //_this.$el.find("div.actions").css("bottom", `${height}px`);
        }, 50);
    },

    focusFirstInputField: function () {
        focuser.focusFirstInputIn(".sidebar.main");
    },

    onPreviewClick: function () {
        this.trigger("preview-data", this.model);
    },

    clearException: function () {
        this.trigger("clear-and-reload");
        this.hideCrudException();
    },

    metadataVisibility: function (app_status) {
        let hasLineageLicense = true;

        if (
            !this.model ||
            !this.model.metaObject ||
            !this.model.metaObject.adapter ||
            !this.model.metaObject.adapter.handler
        ) {
            return hasLineageLicense;
        }

        if (!this.lineageMetadataManager) {
            this.lineageMetadataManager = lineageMetadataManagerProvider.getSpecificManager(
                this.model.metaObject.adapter.handler
            );
        }

        var titleLi = this.editors.$el.find(".controls li:first");
        if (this._shouldShowMetadataButton(app_status)) {
            titleLi.addClass("with-metadata-modal-button");
            titleLi.prepend(this.ui.metadataButton);
            this.ui.metadataButton.text("View " + this.lineageMetadataManager.featureName);
            this.ui.metadataButton.show();

            if (!securityService.isSegmentationFeatureEnabled(addonFeaturesKeys.FLM)) {
                hasLineageLicense = false;
                const variant = securityService.getSegmentationVariant(addonFeaturesKeys.FLM);
                const licenseIndicator = segmentationIndicator(variant, "seg-indicator-component-view");
                this.ui.metadataButton.append(licenseIndicator);
                this.ui.metadataButton.tooltipster(
                    segmentationTooltip(variant, addonFeaturesKeys.FLM, this.ui.metadataButton)
                );
            }
        } else {
            titleLi.removeClass("with-metadata-modal-button");
            this.ui.metadataButton.hide();
            return hasLineageLicense;
        }

        if (!this._isMetadataFeatureEnabled()) {
            if (hasLineageLicense) {
                this.ui.metadataButton.addClass("disabled");
                var $content = $(
                    '<div class="tooltip-text">Please enable ' +
                    this.lineageMetadataManager.featureName +
                    " Metadata feature</div>"
                );
                this.ui.metadataButton.tooltipster({
                    debug: false,
                    autoClose: true,
                    interactive: false,
                    position: "bottom",
                    content: $content,
                    offsetY: -4
                });
            }
        }

        return hasLineageLicense;
    },

    _shouldShowMetadataButton: function (app_status) {
        var metaObjectModel = this.model.metaObject;
        var adapter = null;
        if (metaObjectModel) {
            adapter = metaObjectModel.adapter;
        }

        if (
            statusManagement.isApplicationRunning(app_status) === false &&
            statusManagement.isDeployedState(app_status) === false &&
            statusManagement.isApplicationCrash(app_status) === false
        ) {
            return false;
        }

        if (
            !(
                (this._currentModelType === utils.ENTITIES.SOURCE ||
                    this._currentModelType === utils.ENTITIES.TARGET) &&
                adapter &&
                adapter.handler
            )
        ) {
            return false;
        }

        return !!(this.lineageMetadataManager && this.lineageMetadataManager.checkIfEnabled(adapter.handler));

    },
    _isMetadataFeatureEnabled: function () {
        return this.lineageMetadataManager.checkIfFeatureEnabled();
    },

    onMetadataButtonClick: function () {
        if (!this._isMetadataFeatureEnabled()) {
            return;
        }
        this.lineageMetadataManager.type = this._currentModelType;

        var dialog = new lineageMetadataModal.View({
            model: this.model.get("metaObject"),
            metadataManager: this.lineageMetadataManager
        });
        modalManager.add(dialog);
        new App.FormMixinDialog.Controller({
            view: dialog
        });

        dialog.on("modal:close", function () {
            dialog.destroy();
        });
    }
});

export default Sidebar;
