import App from "app";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import template from "./heading.html";
import "./editor";

App.module("Dashboard.VisEditor", function (VisEditor, App, Backbone, Marionette, $, _) {
    VisEditor[dataVisualizations.EDITOR.HEADING] = VisEditor.Editor.LayoutView.extend({
        className: "flow-inline",
        template: _.template(template),
        onBeforeDestroy: function () {
            this.$("select").select2("destroy");
        },
        onRender: function () {
            var _this = this;

            // To capture initial dropdown value.
            var cssClassName = this.$("select").val();

            var templateText = _this.model.config.get("userTemplate");

            /**
             *  Validating for heading type class or user defined custom css user templates.
             */
            if ($(templateText).hasClass("text-widget")) {
                // Show on UI purpose : To show userTemplate with out any div tag (div tag with css class name) on template field.
                _this.$("#template-text").val($(templateText).html());
            } else {
                _this.model.config.set("userTemplate", '<div class="' + cssClassName + '">' + templateText + "</div>");
                _this.$("#template-text").val($(_this.model.config.get("userTemplate")).html());
            }
            /**
             * On change event : If user selects new heading template from dropdown , then we are updating userTemplate with
             * new css class name.
             */
            this.$("select")
                .val($(_this.model.config.get("userTemplate")).attr("class"))
                .on("change", function () {
                    var style = $(this).val();
                    var newHtml = $(_this.model.config.get("userTemplate")).attr("class", style);
                    _this.model.config.set("userTemplate", newHtml[0].outerHTML);
                });
            /**
             * On change event : If user updates template data, then we are updating userTemplate with user updated data.
             */
            _this.$("#template-text").on("input", function () {
                var style = $(_this.$("select")).val();
                _this.model.config.set(
                    "userTemplate",
                    '<div class="' + style + '">' + _this.$("#template-text").val() + "</div>"
                );
            });
            _(function () {
                _this.$("select").select2({
                    dropdownCssClass: "light",
                });
            }).defer();
        },
        serializeData: function () {},
        initialize: function () {},
    });
});
