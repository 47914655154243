import React from "react";
import { StriimInputField } from "@striim/striim-ui";
import { InputAdornment, Grid } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ spacing }) => ({
    wrapper: {
        marginTop: spacing(1),
        marginBottom: spacing(1)
    }
}));

const ModalSearchBox = ({ field, manualState, setManualState, inputFieldValue }) => {
    const classes = useStyles();

    return (
        <Grid container item alignItems="center" className={classes.wrapper}>
            <Grid container item xs={12} justifyContent="flex-start">
                <StriimInputField
                    placeholder="Search"
                    value={inputFieldValue}
                    onChange={value => {
                        setManualState({
                            ...manualState,
                            filters: {
                                ...manualState.filters,
                                [field]: value
                            }
                        });
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search opacity={0.45} />
                            </InputAdornment>
                        ),
                        "data-test-id": `search-${field}-input`
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default ModalSearchBox;
