export const styles = {
    headingContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 2
    },
    filter: {
        width: "200px"
    },
    filtersWrapper: {
        "& > *": {
            marginLeft: "16px !important"
        }
    },
    nameRowLabel: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& button, a": {
            paddingLeft: 0,
            display: "flex",
            justifyContent: "flex-start"
        }
    },
    sensitiveDataSquare: {
        width: "11px",
        height: "11px",
        marginRight: "8px",
        display: "inline-block",
        borderRadius: "2px"
    },
    customHeader: {
        display: "flex",
        flexDirection: "column",
        width: "100%",

        margin: "auto 0"
    },
    customHeaderRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        margin: 0,
        paddingTop: 1,
        paddingRight: 1
    },
    customHeader2Rows: {
        display: "flex",
        flexDirection: "column",
        width: "100%",

        margin: 0,
        paddingTop: 1
    },
    sensitiveDataLabelTop: {
        // display: "block",
        display: "flex",
        textOverflow: "ellipsis",
        overflow: "hidden",
        flexDirection: "row",
        alignItems: "center"
    },
    sensitiveDataLastValue: {
        transform: "translate(-50%, 0)"
    },
    sensitiveDataLabelBottom: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 2
    },
    sensitiveDataBar: {
        height: 10,
        width: "100%",
        backgroundColor: "#49C7BB",
        "& .MuiLinearProgress-bar": {
            backgroundColor: "#7E7ED6"
        }
    },
    tooltipWrapper: {
        borderRadius: "4px",
        border: "1px solid",
        borderColor: "greyscale.200",
        backgroundColor: "additional.white",
        boxShadow: "0px 6px 15px 0px rgba(72, 82, 92, 0.12)",
        minWidth: "221px",
        display: "flex",
        flexDirection: "column"
    },
    tooltipHeading: {
        borderBottom: "1px solid",
        borderColor: "greyscale.200",
        color: "greyscale.700",
        padding: 1,
        marginBottom: 1
    },
    tooltipSquare: {
        width: "7px",
        height: "7px",
        marginRight: "4px",
        display: "inline-block",
        borderRadius: "2px"
    },
    tooltipLabel: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        lineHeight: 1.5,
        justifyContent: "space-between",
        padding: theme => theme.spacing(0.5, 1)
    },
    tooltipLabelTitle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    tooltipValue: {
        marginLeft: theme => theme.spacing(1)
    },
    filterOptions: {},
    dataTable: {
        "& .ag-header-cell-comp-wrapper": {
            alignItems: "flex-start !important"
        },
        "& .ag-cell-label-container": {
            padding: 0
        },
        "& .ag-header-cell-resize": {
            zIndex: 0,
            marginTop: -1
        },
        "& .dataCellTextEnd": {
            display: "flex",
            justifyContent: "flex-end"
        },
        "& .table-styles": {
            minHeight: "210px"
        },
        "& .ag-header-cell-label": {
            alignItems: "flex-start",
            margin: "auto 0"
        }
    },
    dataTableManyHeaderRows: {
        "& .ag-header-cell-label": {
            marginTop: 1
        }
    },
    chartWrapper: {
        "& .recharts-wrapper": {
            position: "initial !important"
        },
        "& .recharts-tooltip-wrapper": {
            width: "inherit"
        }
    },
    infoCircle: {
        width: "16px",
        height: "19px",
        fill: "none",
        marginLeft: 0.5,

        "& *": {
            stroke: theme => theme.palette.greyscale[700]
        }
    },
    lineSpanWrapper: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "-4px",
        marginRight: "20px"
    },
    lineSpan: {
        "&:before": {
            content: '""',
            display: "inline-block",
            width: "1px",
            height: "4px",
            backgroundColor: "#D8E4F0",
            verticalAlign: "middle",
            marginRight: 0.5
        }
    },
    tooltip: {
        padding: 0,
        backgroundColor: "transparent"
    },
    learnMore: {
        padding: 0,
        fontSize: "12px",
        height: "12px",
        marginBottom: "2px",
        "&:hover": {
            cursor: "pointer"
        }
    },
    downArrow: {
        "& svg": {
            transform: "rotate(180deg)"
        }
    },
    longValueWrap: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }
};
