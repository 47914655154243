import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing }) => ({
    icons: {
        fontSize: 40,
        lineHeight: 0
    },
    title: {
        marginLeft: spacing(1.5)
    },
    helpBtn: {
        marginLeft: "auto"
    },
    fieldsContainer: {
        marginTop: spacing(3)
    },
    formInput: {
        marginTop: spacing(2)
    },
    inputGroup: {
        marginTop: spacing(3.5)
    },
    columnSpacing: {
        paddingLeft: spacing(2)
    }
}));
