import React, { useState, useRef } from "react";
import BasicInfo, { validationSchema } from "./components/basic-info";
import { ButtonsBar, CancelEditModal } from "src/modules/apps/pages/user-management/common";
import { useUserManagement } from "core/user-management/user-management-context";
import { useNavigate } from "react-router-dom";
import RoleTable from "../common/role-table";
import PermissionsTable from "../common/permissions-table";
import { Grid } from "@mui/material";
import styles from "../../../common/users-management.styles";

const CreateUser = () => {
    const { createUser, grantPermissionsToUser } = useUserManagement();
    const navigate = useNavigate();
    const formRef = useRef();

    const [addedRoles, setAddedRoles] = useState([]);
    const [addedPermissions, setAddedPermissions] = useState([]);
    const [revokedRoles, setRevokedRoles] = useState([]);
    const [revokedPermissions, setRevokedPermissions] = useState([]);
    const [cancelDialogVisible, setCancelDialogVisible] = useState(false);

    const handleSave = async () => {
        const { values: basicInfoValues } = formRef.current;
        var isValid = validationSchema.isValidSync(basicInfoValues);
        if (!isValid) {
            formRef && formRef.current && formRef.current.handleSubmit();
            return;
        }

        const rolesToAdd = addedRoles.map(x => x.name).filter(x => revokedRoles.indexOf(x) === -1);

        await createUser(
            basicInfoValues.name,
            basicInfoValues.password,
            "admin",
            rolesToAdd,
            basicInfoValues.ldapuser,
            basicInfoValues.name !== basicInfoValues.ldapUserId ? basicInfoValues.ldapUserId : "",
            basicInfoValues.propertyset?.value,
            basicInfoValues.email,
            basicInfoValues.firstName,
            basicInfoValues.lastName,
            basicInfoValues.phone,
            basicInfoValues.timezone
        );

        const permissionsToGrant = addedPermissions.filter(x => revokedPermissions.map(p => p.id).indexOf(x.id) === -1);
        for (let i = 0; i < permissionsToGrant.length; i++) {
            const permissionToAdd = permissionsToGrant[i];
            await grantPermissionsToUser(
                basicInfoValues.name,
                `${permissionToAdd.namespace}.${permissionToAdd.objectName}`,
                permissionToAdd.actions,
                permissionToAdd.objectTypes
            );
        }

        navigate("/users");
    };

    return (
        <>
            <Grid item xs={12} sx={styles.contentContainer}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <BasicInfo formRef={formRef} />
                    </Grid>
                    <Grid item xs={12}>
                        <RoleTable
                            userRoles={[]}
                            addedRoles={addedRoles}
                            setAddedRoles={setAddedRoles}
                            revokedRoles={revokedRoles}
                            setRevokedRoles={setRevokedRoles}
                            isEditing
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PermissionsTable
                            userPermissions={[]}
                            addedPermissions={addedPermissions}
                            setAddedPermissions={setAddedPermissions}
                            revokedPermissions={revokedPermissions}
                            setRevokedPermissions={setRevokedPermissions}
                            isEditing
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} mt={3}>
                <ButtonsBar
                    isDeletable={false}
                    onCancelClick={() => setCancelDialogVisible(true)}
                    onSaveClick={handleSave}
                />
            </Grid>
            <CancelEditModal
                title={`Are you sure you want to discard the new user?`}
                dialogVisible={cancelDialogVisible}
                setDialogVisible={setCancelDialogVisible}
                onConfirm={() => navigate("/users")}
            />
        </>
    );
};

export default CreateUser;
