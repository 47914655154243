import App from "app";
import api from "core/api/api";
import Backbone from "backbone";
import NestedTypes from "backbone.nestedtypes";
import $ from "jquery";
import _ from "underscore";
import utils from "core/utils";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import metaobjectConverter from "core/services/metaStoreService/metaobject-converter";
import appStatusSynchronizer from "core/appStatusSynchronizer";
import EventsLogView from "./../eventsLog/eventsLogView";
import Sidebar from "app/components/common/sidebar/commonSidebar";
import template from "./templates/appdetails.html";
import AppInfoPanel from "./appinfopanel/appinfopanel";
import AppMenu from "./appmenu/appmenu";
import AppOverview from "./appdata/appoverview";
import StatsReporting from "./appdata/statsreporting";
import lagReporting from "./appdata/lagreporting";
import MonitorReportView from "./appdata/monitorReport/monitorReport";
import helpableMixin from "app/components/common/helpable/helpable";
import tables from "./appdata/tables/tables";
import { DetailsDialog } from "app/components/monitor/appdetails/appdata/tables/basetable";
import ModalManager from "app/components/common/modal/ModalManager";
import { TYPES } from "../../../../core/services/monitorService";
import formatter from "../common/valueFormatter";

const overview = "overview";
const statsreporting = "statsreporting";
const lagreporting = "lagreporting";
const monitorReport = "monitorReport";
const AppDetails = {};

const modalManager = new ModalManager({
    container: "body"
});

const TableModel = NestedTypes.Model.extend({
    defaults: {
        data: []
    }
});

AppDetails.Model = NestedTypes.Model.extend({
    defaults: {
        appName: "",
        dataType: "",
        appinfopanel: Backbone.Model.extend({
            defaults: {
                nodeCnt: 0,
                clusterMemoryFree: null,
                clusterMemoryTotal: null,
                totalCacheSize: 0,
                totalWindowSize: 0,
                lagReport: null,

                performance: 0,
                performanceFormatted: null,
                status: null,
                wactionsPerSecond: 0,
                sourceInput: 0,
                targetOutput: 0,
                targetAcked: 0,
                appStatsMostRecentData: null,
                appStatsTimeSeriesData: null,

                componentsCnt: null,
                componentsCntFormatted: 0
            }
        }),
        appdata: NestedTypes.Model.extend({
            defaults: {
                queries: TableModel,
                caches: TableModel,
                eventstable: TableModel,
                windows: TableModel,
                streams: TableModel,
                wactionstores: TableModel,
                targets: TableModel,
                sources: TableModel,
                externalcaches: TableModel,
                rate: [],
                cpu: []
            }
        })
    },

    _loadAppComponents: function() {
        var _this = this;

        var appId = metaobjectConverter.convertShortNameToFullName(this.appName, "APPLICATION");
        return metaStoreService.findById(appId).then(function(app) {
            return app.fetchObjects(true).then(function(appComponents) {
                _this._appComponents = appComponents;
                return appComponents;
            });
        });
    },

    _isEventTable: function(item) {
        if (!this._appComponents || !this._appComponents.models) {
            console.log("appDetails: _appComponents not loaded " + item.name);
            return false;
        }
        var cache = this._appComponents.models.filter(function(c) {
            return c.id === metaobjectConverter.getId(item["ns-name"], item.type, item.name);
        });
        if (!cache || cache.length === 0) {
            console.log("appDetails: item not found " + item.name);
            return false;
        }
        if (cache.length > 1) {
            console.log("appDetails: should find one component" + item.name);
            return false;
        }
        return cache[0].isEventTable;
    },

    updateValues: function(components, serverDetails) {
        var _this = this;
        try {
            let stats = components;
            if (location.hash.indexOf("#/monitor") === -1) {
                return;
            }

            var serverStats = _.filter(serverDetails, function(item) {
                return item.type === "SERVER";
            });
            var serverData = serverStats[0]["most-recent-data"];

            var appStats = _.find(stats, function(item) {
                return item["full-name"] === _this.appName;
            });

            function tryParseJSON(value, logError) {
                try {
                    return JSON.parse(value);
                } catch (e) {
                    if (logError) {
                        console.error(e);
                    }
                    return null;
                }
            }

            function tryParseLagReport(value) {
                var result;
                result = tryParseJSON(value);
                if (result !== null) {
                    return result;
                }

                result = tryParseJSON("[" + lagReport + "]", true);
                if (result !== null) {
                    return result;
                }

                return {};
            }

            var lagReport = appStats["most-recent-data"]["lag-report-json"];
            if (lagReport) {
                lagReport = tryParseLagReport(lagReport);
            }
            if (appStats) {
                _this.appinfopanel.set({
                    nodeCnt: appStats["num-servers"],
                    clusterMemoryFree: utils.bytesFormatter(serverData["memory-free"]),
                    clusterMemoryTotal: utils.bytesFormatter(serverData["memory-max"]),
                    totalCacheSize: appStats["most-recent-data"]["cache-size"],
                    totalWindowSize: appStats["most-recent-data"]["windows-size"],
                    lagReport: lagReport,
                    performance: appStats["most-recent-data"]["source-rate"], // DEV-7967  - II
                    performanceFormatted: utils.formatBigNumber(appStats["most-recent-data"]["source-rate"]),
                    status: formatter.formatStatus(appStats.status),
                    wactionsPerSecond: appStats["most-recent-data"]["wactions-created-rate"],
                    appStatsMostRecentData: appStats["most-recent-data"],
                    appStatsTimeSeriesData: appStats["time-series-data"],
                    sourceInput: appStats["most-recent-data"]["source-input"],
                    targetOutput: appStats["most-recent-data"]["target-output"],
                    targetAcked: appStats["most-recent-data"]["target-acked"],

                    componentsCnt: appStats["num-components"],
                    componentsCntFormatted: utils.formatBigNumber(appStats["num-components"])
                });

                var componentStats = _.filter(components, function(item) {
                    return item.applications === _this.appName;
                });

                var queries = componentStats.filter(function(item) {
                    return item.type === "CQ";
                });
                _this.appdata.queries.set({
                    data: queries
                });
                _this.appdata.caches.set({
                    data: componentStats.filter(function(item) {
                        return item.type === "CACHE" && !_this._isEventTable(item);
                    })
                });
                _this.appdata.externalcaches.set({
                    data: componentStats.filter(function(item) {
                        if (item.type === "EXTERNALCACHE") console.log(item);
                        return item.type === "EXTERNALCACHE" && !_this._isEventTable(item);
                    })
                });
                _this.appdata.eventstable.set({
                    data: componentStats.filter(function(item) {
                        return item.type === "CACHE" && _this._isEventTable(item);
                    })
                });
                _this.appdata.windows.set({
                    data: componentStats.filter(function(item) {
                        return item.type === "WINDOW";
                    })
                });
                _this.appdata.streams.set({
                    data: componentStats.filter(function(item) {
                        return item.type === "STREAM";
                    })
                });
                _this.appdata.wactionstores.set({
                    data: componentStats.filter(function(item) {
                        return item.type === "WACTIONSTORE";
                    })
                });
                _this.appdata.targets.set({
                    data: componentStats.filter(function(item) {
                        return item.type === "TARGET";
                    })
                });

                _this.appdata.sources.set({
                    data: componentStats.filter(function(item) {
                        return item.type === "SOURCE" || item.type === "EXTERNALSOURCE";
                    })
                });

                var timeSeries = appStats["time-series-data"];
                if (timeSeries && !$.isEmptyObject(timeSeries)) {
                    _this.appdata.set({
                        rate: timeSeries["source-rate"],
                        cpu: timeSeries["cpu-per-node"]
                    });
                }
            }
        } catch (e) {
            console.error(e);
        }
    },
    updateAppDetailsModel: async function() {
        var _this = this;
        let name = utils.getName(_this.appName);
        let nsName = utils.getNamespace(_this.appName);
        let appStats = await api.getMonitoringStatsForAppWithoutTableInfo(`${nsName}.APPLICATION.${name}`, TYPES.FULL);
        let allServers = await metaStoreService.fetchIdentifiersByType("SERVER");
        let serverIDs = allServers.map(model => {
            return model.get("id");
        });
        let serverDetails = await api.getMonitoringStatsForComponentsWithoutTableInfo(serverIDs, TYPES.FULL);
        this.updateValues(appStats, serverDetails);

        this.timer = setTimeout(() => {
            this.updateAppDetailsModel();
        }, 5000);
    }
});

AppDetails.View = Backbone.Marionette.LayoutView.extend(helpableMixin).extend({
    template: _.template(template),

    regions: {
        appinfopanel: ".appinfopanel",
        appmenu: ".appmenu",
        appdata: ".appdata"
    },

    ui: {
        goToAppButton: "a.go-to-app",
        eventLogButton: "a.event-log",
        intervalReportLink: "a.interval-report",
        appMetricsButton: "a.app-metrics-link"
    },

    events: {
        "click @ui.goToAppButton": "navigateToApp",
        "click @ui.eventLogButton": "showEventsLog",
        "click @ui.appMetricsButton": "showAppMetrics",
        "click @ui.intervalReportLink": "showIntervalReportPage"
    },
    getHelpID: function() {
        return "MONITOR";
    },
    getHelperContentElement: function() {
        return this.$el.find("#info-icon");
    },
    initialize: function(options) {
        helpableMixin.initialize.apply(this, arguments);
        // TODO: ideally we shouldn't be touching the HTML tag...
        this.listenTo(this, "before:destroy", function() {
            $("html").removeClass("monitor");
        });
        this.listenTo(this, "render", function() {
            $("html").addClass("monitor");
        });

        this.model = new AppDetails.Model();
        this.model.appName = options.appName;
        this.model._loadAppComponents().then(
            function() {
                this.model.updateAppDetailsModel();
            }.bind(this)
        );

        this.listenTo(App.vent, "monitor:showData", this.updateAppDataView.bind(this));

        // render again when application status changed
        var appFullName = metaobjectConverter.convertShortNameToFullName(
            this.model.appName,
            utils.ENTITIES.APPLICATION
        );
        this.listenTo(
            appStatusSynchronizer,
            appFullName,
            function() {
                this.updateAppDataView(this.model.appName, this.model.dataType);
            }.bind(this)
        );
    },
    onDestroy() {
        clearInterval(this.model.timer);
    },
    updateAppDataView: function(appName, dataType) {
        let view;
        this.model.dataType = dataType || monitorReport;
        if (dataType === statsreporting) {
            view = new StatsReporting.View({
                model: new StatsReporting.Model({
                    AppId: appName
                })
            });
        } else if (dataType === lagreporting) {
            view = new lagReporting.View({
                model: this.model.appinfopanel
            });
        } else if (!dataType || dataType === monitorReport) {
            view = new MonitorReportView({
                appName: this.model.appName,
                model: this.model.appinfopanel
            });
        } else if (dataType === overview) {
            view = new AppOverview.View({
                model: this.model.appdata
            });
        } else {
            const viewType = tables[dataType + "table"];
            const appFullName = metaobjectConverter.convertShortNameToFullName(appName, utils.ENTITIES.APPLICATION);
            const appStatus = appStatusSynchronizer.getStatus(appFullName);
            view = new viewType.View({
                model: this.model.appdata[dataType],
                appStatus: appStatus,
                appData: this.model.appdata
            });
        }

        this.appdata.show(view);
        view.model.trigger("change", view.model);
        this.appmenu.currentView.highlight(this.model.dataType);
    },

    onRender: function() {
        this.appinfopanel.show(
            new AppInfoPanel.View({
                model: this.model.appinfopanel
            })
        );

        this.appmenu.show(
            new AppMenu.View({
                appName: this.model.appName
            })
        );

        var eventsLogView = new EventsLogView({
            model: this.model
        });

        this.eventLogSidebar = new Sidebar.View({
            contentView: eventsLogView,
            parentView: this,
            customCssClass: "common-sidebar-event-log"
        });
    },

    navigateToApp: function() {
        var url = "#/flow/" + this.model.appName;
        App.navigate(url, {
            trigger: true
        });
    },

    showEventsLog: function() {
        this.eventLogSidebar.toggle();
    },

    showAppMetrics: function() {
        const that = this;

        const appinfopanel = this.model.get("appinfopanel");
        if (!appinfopanel) {
            return;
        }
        const timeseriesData = appinfopanel.get("appStatsTimeSeriesData");
        const mostRecentData = appinfopanel.get("appStatsMostRecentData");

        const model = new Backbone.Model({
            allValues: {
                "time-series-data": timeseriesData,
                "most-recent-data": mostRecentData
            }
        });

        function onModelChange() {
            model.trigger("change");
        }

        this.model.on("change", onModelChange);

        const appFQN =
            this.model.get("appName").split(".")[0] + ".APPLICATION." + this.model.get("appName").split(".")[1];

        this._dialog = new DetailsDialog({
            model: model,
            componentFullName: appFQN
        });
        modalManager.add(this._dialog);
        new App.FormMixinDialog.Controller({
            view: this._dialog
        });

        this._dialog.on("form:submit", function (_dialog) {
            that._dialog.destroy();
            that._dialog = null;
        });

        this._dialog.on("before:destroy", () => {
            modalManager.remove_all_modals();
            that.model.off("change", onModelChange);
            that._dialog = null;
        });
    },

    showIntervalReportPage: function() {
        App.navigate("#/monitor/" + this.model.get("appName") + "/statsreporting", {
            trigger: true
        });
    }
});

export default AppDetails;
