import _ from "underscore";
import ColumnWithFunction from "./column-with-function";
import ColumnsList from "./columns-list";

var View = ColumnsList.View.extend({
    initialize: function (options) {
        ColumnsList.View.prototype.initialize.apply(this, [options]);

        this.gridView = new ColumnWithFunction.CollectionView({
            collection: new ColumnWithFunction.Collection([]),
            model: this.model,
            config: options.transformationConfig,
        });

        ColumnsList.View.prototype.attachListeners.apply(this);
    },
});

var Model = ColumnsList.Model.extend({});

export default _.extend({}, ColumnsList, {
    Model: Model,
    View: View,
});
