import _ from "underscore";
import with_category from "./with_category";

var AllWithCategory = function () {};

AllWithCategory.prototype = _.extend(new with_category(), {
    _evictSeriesData: function (data) {
        // private
        // Trim the data to the maximum size
        if (data.length > this.maxValues) {
            var numToSlice = data.length - this.maxValues;
            data = data.slice(numToSlice);
        }
        return data;
    },
});

export default AllWithCategory;
