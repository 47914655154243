import React, { useState } from "react";
import SsoEmpty from "./sso-empty";
import SsoConfiguration from "./sso-configuration";
import SsoAddConfiguration from "./sso-add-configuration"

const SsoContainer = ({ ssoConfigured, ssoProperties = {}, setSsoProperties }) => {
    const [addSso, setAddSso] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editValues, setEditValues] = useState({});

    const hasConfigurationDetails = Object.keys(ssoProperties).length !== 0;
    const showConfigurationDetails = hasConfigurationDetails && !edit;
    const addOrEditConfig = (!ssoConfigured && addSso) || (ssoConfigured && edit);

    const toggleAddSso = () => setAddSso(prevAddSso => !prevAddSso);

    const content = showConfigurationDetails
        ? <SsoConfiguration
            ssoProperties={ssoProperties}
            setSsoProperties={setSsoProperties}
            setAddSso={setAddSso}
            setEdit={setEdit}
            setEditValues={setEditValues}
        />
        : addOrEditConfig
            ? <SsoAddConfiguration
                setAddSso={setAddSso}
                setSsoProperties={setSsoProperties}
                edit={edit}
                setEdit={setEdit}
                editValues={editValues}
            />
            : <SsoEmpty toggleAddSso={toggleAddSso} />;

    return content;
};

export default SsoContainer;
