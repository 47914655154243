import React from "react";
import { StriimTypography } from "@striim/striim-ui";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import Tracker from "../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../core/services/tracker/constants";

const useStyles = makeStyles(({ spacing }) => ({
    documentationItem: {
        paddingTop: 10,
        paddingRight: 10,
        paddingLeft: 0,
        paddingBottom: 10
    },
    container: {
        background: v5theme => v5theme.palette.greyscale[200],
        paddingInline: "120px",
        paddingBlock: "40px",
        marginTop: spacing(4)
    },
    gridItem: {
        marginBottom: 10,
        marginRight: 10
    },
    button: {
        paddingLeft: 0,
        color: v5theme => v5theme.palette.secondary[500]
    },
    text: {
        color: v5theme => v5theme.palette.greyscale[700]
    }
}));

const DocumentationLinks = () => {
    const v5theme = useTheme();
    const classes = useStyles(v5theme);
    const trackEventClicked = () => {
        Tracker.getInstance().track(TRACKER_STRINGS.schema.learnMore, {
            event: TRACKER_STRINGS.eventClicked.learnMore
        });
        return true;
    };

    return (
        <div className={classes.container}>
            <StriimTypography variant="h4" sx={{ mb: "10px !important" }}>
                Learn more about the Striim platform
            </StriimTypography>
            <Grid item container xs={12}>
                <Grid container>
                    <Grid item xs={4} className={classes.gridItem}>
                        <Grid item className={classes.documentationItem}>
                            <StriimTypography
                                variant="body4"
                                className={classes.button}
                                target="_blank"
                                component="a"
                                href="https://www.striim.com/tutorial/stream-data-from-salesforce-and-oracle-to-azure-synapse/"
                                onClick={trackEventClicked}
                            >
                                Stream data from Salesforce and Oracle to Azure Synapse
                            </StriimTypography>
                        </Grid>
                        <StriimTypography variant="body4" className={classes.text}>
                            Analyze real-time operational, and transactional data from Salesforce and Oracle in Synapse.
                        </StriimTypography>
                    </Grid>
                    <Grid item xs={4} className={classes.gridItem}>
                        <Grid item className={classes.documentationItem}>
                            <StriimTypography
                                variant="body4"
                                className={classes.button}
                                target="_blank"
                                component="a"
                                href="https://www.striim.com/tutorial/deliver-real-time-insights-and-fresh-data-with-dbt-and-striim-on-snowflake-partner-connect/"
                                onClick={trackEventClicked}
                            >
                                Deliver real-time insights with DBT and Striim on Snowflake
                            </StriimTypography>
                        </Grid>
                        <StriimTypography variant="body4" className={classes.text}>
                            Stream data from PostgreSQL to Snowflake and coordinate transform jobs in dbt.
                        </StriimTypography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4} className={classes.gridItem}>
                        <Grid item className={classes.documentationItem}>
                            <StriimTypography
                                variant="body4"
                                className={classes.button}
                                target="_blank"
                                component="a"
                                href="https://www.striim.com/tutorial/building-real-time-data-products-on-google-cloud-with-striim/"
                                onClick={trackEventClicked}
                            >
                                Building real-time data products on Google cloud with Striim
                            </StriimTypography>
                        </Grid>
                        <StriimTypography variant="body4" className={classes.text}>
                            Create decoupled, decentralized real-time data products in Streaming SQL.
                        </StriimTypography>
                    </Grid>
                    <Grid item xs={4} className={classes.gridItem}>
                        <Grid item className={classes.documentationItem}>
                            <StriimTypography
                                variant="body4"
                                className={classes.button}
                                target="_blank"
                                component="a"
                                href="https://www.striim.com/tutorial/ensure-data-freshness-with-streaming-sql/"
                                onClick={trackEventClicked}
                            >
                                Ensure data freshness with streaming SQL
                            </StriimTypography>
                        </Grid>
                        <StriimTypography variant="body4" className={classes.text}>
                            Use Striim&#39;s SQL to monitor and alert on the lag between source and target systems.
                        </StriimTypography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default DocumentationLinks;
