import _ from "underscore";

function format(logEntries) {
    let stringBuilder = [];

    _.each(logEntries, (logEntry) => {
        stringBuilder.push("Title:   " + logEntry.title);
        stringBuilder.push("Message: " + logEntry.message);
        stringBuilder.push("Status:  " + logEntry.status);
        stringBuilder.push("Date:    " + logEntry.formattedDate);
        stringBuilder.push("Address: " + logEntry.address);
        stringBuilder.push("Node:    " + logEntry.server);
        stringBuilder.push("--------------------------------------------------------------------");
    });

    return stringBuilder.join("\n");
}

export default {
    format: format,
};
