export const AppAdapters = {
    APPADAPTERSSTRIPE: "APPADAPTERSSTRIPE",
    APPADAPTERSZENDESK: "APPADAPTERSZENDESK",
    APPADAPTERSHUBSPOT: "APPADAPTERSHUBSPOT",
    APPADAPTERSSTRIPEANDZENDESK: "APPADAPTERSSTRIPEANDZENDESK",
    APPADAPTERSSTRIPEANDHUBSPOT: "APPADAPTERSSTRIPEANDHUBSPOT",
    APPADAPTERSHUBSPOTANDZENDESK: "APPADAPTERSHUBSPOTANDZENDESK",
    APPADAPTERSENTERPRISE: "APPADAPTERSENTERPRISE"
};

export const ProductTiers = {
    NONSEGMENTED: "NONSEGMENTED",
    STANDARD: "STANDARD",
    ENTERPRISE: "ENTERPRISE",
    ENTERPRISEPLUS: "ENTERPRISEPLUS",
    DEVELOPER: "DEVELOPER",
    ...AppAdapters
};

export const SegmentationFeaturesStates = {
    NOTAPPLICABLE: "NOTAPPLICABLE",
    ADDON_ENABLED: "ADDON_ENABLED",
    ADDON_DISABLED: "ADDON_DISABLED",
    DEFAULT: "DEFAULT"
};

export const addonFeaturesKeys = {
    ALERTMANAGER: "ALERTMANAGER",
    PERSISTENTSTREAMS: "PERSISTENTSTREAMS",
    ROUTER_COMP: "ROUTER_COMP",
    EXTERNAL_CACHE: "EXTERNAL_CACHE",
    OPENPROCESSOR: "OPENPROCESSOR",
    BIDIRECTIONAL: "BIDIRECTIONAL",
    EXCEPTION_STORE: "EXCEPTION_STORE",
    METADATAMANAGER: "METADATAMANAGER",
    RESTAPIFUNCTIONS: "RESTAPIFUNCTIONS",
    PARTITIONEDWINDOWS: "PARTITIONEDWINDOWS",
    WINDOWS: "WINDOWS",
    CACHE_COMP: "CACHE_COMP",
    APPLICATIONGROUPS: "APPLICATIONGROUPS",
    LEE_REPORT: "LEE_REPORT",
    FLM: "FLM",
    DASHBOARDS: "DASH_BOARD",
    PARTITIONEDSTREAMS: "PARTITIONEDSTREAMS",
    WACTION_STORE: "WACTION_STORE",
    DATAVALIDATION: "DATAVALIDATION",
    MLFUNCTIONS: "MLFUNCTIONS",
    MON_TIMESERIES_REPORT: "MON_TIMESERIES_REPORT",
    EVENT_TABLE: "EVENT_TABLE"
};

export const addonFeaturesTitles = {
    [addonFeaturesKeys.ALERTMANAGER]: "Alert Manager",
    [addonFeaturesKeys.PERSISTENTSTREAMS]: "Persistent Streams",
    [addonFeaturesKeys.ROUTER_COMP]: "Stream Router",
    [addonFeaturesKeys.EXTERNAL_CACHE]: "External Cache",
    [addonFeaturesKeys.EVENT_TABLE]: "Event Table",
    [addonFeaturesKeys.OPENPROCESSOR]: "Open Processor",
    [addonFeaturesKeys.BIDIRECTIONAL]: "Bi-Directional Replication",
    [addonFeaturesKeys.EXCEPTION_STORE]: "Exception Store",
    [addonFeaturesKeys.METADATAMANAGER]: "Metadata Manager",
    [addonFeaturesKeys.RESTAPIFUNCTIONS]: "App Management REST API",
    [addonFeaturesKeys.PARTITIONEDWINDOWS]: "Partitioned Windows",
    [addonFeaturesKeys.WINDOWS]: "Windows",
    [addonFeaturesKeys.CACHE_COMP]: "Striim Cache",
    [addonFeaturesKeys.APPLICATIONGROUPS]: "Application Groups",
    [addonFeaturesKeys.LEE_REPORT]: "End-to-End Lag Report",
    [addonFeaturesKeys.FLM]: "File Lineage Metadata",
    [addonFeaturesKeys.DASHBOARDS]: "Custom Dashboards",
    [addonFeaturesKeys.PARTITIONEDSTREAMS]: "Partitioned Streams",
    [addonFeaturesKeys.WACTION_STORE]: "WActionStore",
    [addonFeaturesKeys.DATAVALIDATION]: "Data Validation",
    [addonFeaturesKeys.MLFUNCTIONS]: "ML Functions",
    [addonFeaturesKeys.MON_TIMESERIES_REPORT]: "Timeseries Monitor Reports"
};

export const addonFeaturesMessages = {
    [addonFeaturesKeys.ALERTMANAGER]:
        "	Out-of-the-box configurable alerts for common scenarios that can help you maximize operational uptime",
    [addonFeaturesKeys.PERSISTENTSTREAMS]:
        "	Provides the capability of storing all events in your streams for enhanced data replay and failover support",
    [addonFeaturesKeys.ROUTER_COMP]:
        "	Route your data to different streams and data targets with flexible rules that are easy to configure",
    [addonFeaturesKeys.EXTERNAL_CACHE]:
        "	Enrich your real-time data with streaming SQL joins using data from your external database of choice",
    [addonFeaturesKeys.EVENT_TABLE]: "  Reference cache  populated by an input stream",
    [addonFeaturesKeys.OPENPROCESSOR]:
        "	Build custom, user-defined functions using embeddable java code for maximum control and flexibility in your data processing logic",
    [addonFeaturesKeys.BIDIRECTIONAL]:
        "	Reliably keep multiple databases in sync with bi-directional replication and validation of data consistency between multiple hosts",
    [addonFeaturesKeys.EXCEPTION_STORE]:
        "	Store discarded events that were not compatible with their respective target data store for analysis, resolution, and replay",
    [addonFeaturesKeys.METADATAMANAGER]: "	Browse all metadata objects and schemas within the Striim Platform",
    [addonFeaturesKeys.RESTAPIFUNCTIONS]:
        "	A comprehensive API to manage the life cycle and state of your applications and objects in Striim",
    [addonFeaturesKeys.PARTITIONEDWINDOWS]:
        "	In-memory stream processing windows that can be distributed amongst multiple nodes, partitioned by a user-defined key",
    [addonFeaturesKeys.WINDOWS]:
        "	Bound streaming, real-time data in-memory with user-defined windows configurable by a time-range, event-count, or a hybrid of both",
    [addonFeaturesKeys.CACHE_COMP]:
        "	A scalable, in-memory cache to enrich your real-time data with streaming SQL joins",
    [addonFeaturesKeys.APPLICATIONGROUPS]: "	Organize your applications",
    [addonFeaturesKeys.LEE_REPORT]:
        "	Visualize the latency from the exact time data appeared in your source to the point that is ackowledged in your target system",
    [addonFeaturesKeys.FLM]:
        "	Detailed lineage of files created from various adapters such as Striim's OracleReader, FileReader, S3Reader and others",
    [addonFeaturesKeys.DASHBOARDS]:
        "	Build custom real-time dashboards for your key performance metrics with the power and flexibility of SQL queries (requires WAction Store)",
    [addonFeaturesKeys.PARTITIONEDSTREAMS]:
        "	Distribute your in-memory streams in multi-node scenarios using user-defined partitioning keys",
    [addonFeaturesKeys.WACTION_STORE]: "	An indexing layer where streaming data can be stored and queried",
    [addonFeaturesKeys.DATAVALIDATION]: "	Visualize data validation between your source and target data platforms",
    [addonFeaturesKeys.MLFUNCTIONS]:
        "	Access and analyze your data in real-time using Striim's suite of machine learning functions including Linear and Polynomial Regression",
    [addonFeaturesKeys.MON_TIMESERIES_REPORT]:
        "	Get full visiblity into Striim's performance metrics with advanced time series reports and dashboards"
};

export const addonsSorter = (a, b) => {
    if (!a || !a.item || !b || !b.item) {
        return 0;
    }

    const aKey = addonFeaturesTitles[a.item.addonKey];
    const bKey = addonFeaturesTitles[b.item.addonKey];

    if (aKey < bKey) {
        return -1;
    }
    if (aKey > bKey) {
        return 1;
    }
    return 0;
};
