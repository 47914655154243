export const styles = {
    container: {
        backgroundColor: "greyscale.50",
    },
    topBorder: {
        borderTop: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
    },
    bottomBorder: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
    },
    infoBox: {
        ".MuiCardHeader-root": {
            padding: 1.25
        }
    },
    infoIcon: {
        width: 16,
        height: 16,
        fill: ({ palette }) => palette.greyscale[100]
    },
    infoText: {
        letterSpacing: "0.24px"
    },
    icon: {
        width: 20,
        height: 20,
        fill: ({ palette }) => palette.greyscale[50]
    },
    docIcon: {
        width: 14,
        height: 14,
        fill: ({ palette }) => palette.greyscale[100]
    },
    link: {
        paddingX: 0.5,
        paddingY: 0,
        height: "18px",
        minWidth: '20px'
    },
    divider: {
        position: "relative",
        right: "32px",
        width: "calc(100% + 64px)",
    }
};