import Backbone from "backbone";
import { LayoutView } from "marionette";
import NestedTypes from "backbone.nestedtypes";
import _ from "underscore";
import ControlLabel from "./control-label";
import template from "./form-field.html";
import securityService from "core/services/securityService/securityService";
import { segmentationTooltip, segmentationChip } from "app/components/common/segmentationComponents";
import dialog from "app/components/common/dialogs/dialogWindow";
import ModalManager from "app/components/common/modal/ModalManager";

var FormField = {};

FormField.Model = NestedTypes.Model.extend({
    defaults: {
        label: String,
        description: String,
        propertyName: String,
        controlView: Backbone.View,
        containerClass: null,
        cssClassName: String,
        isSubform: null,
        subControl: null,
        segmentationInfo: null,
        isRequired: false
    }
});

FormField.Collection = Backbone.Collection.extend({
    model: FormField.Model
});

FormField.View = LayoutView.extend({
    template: _.template(template),
    tagName: "li",
    serializeData: function() {
        var containerClass = "from-control-container-" + this.model.cssClassName.replace(" ", "_").replace(":", "_");
        this.model.set("containerClass", containerClass);
        return this.model.toJSON();
    },
    regions: {
        headRegion: ".head",
        controlRegion: ".body"
    },
    ui: {
        head: ".head",
        row: ".field-row"
    },

    onRender: function() {
        if (this.model.controlView.hideLabel || !this.model.get("label")) {
            this.ui.head.hide();
            this.$el.addClass("no-label");
        }
        if (this.model.controlView.subControl) {
            this.ui.head.addClass("header");
            this.$el.addClass("master-control");
        }
        if (this.model.controlView.hidden) {
            this.ui.row.hide();
        }

        this.headRegion.show(
            new ControlLabel({
                model: this.model
            })
        );
        this.controlRegion.show(this.model.controlView);
        this.model.controlView.parentFormField = this.model.controlView.$el.parent();

        if (this.model.controlView.elementAdapter) {
            this.model.controlView.elementAdapter(this.model.controlView.$el);
        }

        if (this.model.segmentationInfo) {
            this.ui.head.addClass("addon");
            const addonAvailable = securityService.isSegmentationFeatureEnabled(this.model.segmentationInfo);
            const variant = securityService.getSegmentationVariant(this.model.segmentationInfo);
            const chip = segmentationChip(variant);
            chip.setAttribute("data-test-id", `segmentation-chip-${variant}-${this.model.segmentationInfo}`);
            this.ui.head.find(">div").append(chip);
            if (!addonAvailable) {
                const original = this.model.controlView.setEnabled;
                this.model.controlView.setEnabled = function() {
                    original.call(this.model.controlView, false);
                }.bind(this);

                this.ui.head.tooltipster(
                    segmentationTooltip(
                        variant,
                        this.model.segmentationInfo,
                        this.ui.head,
                        tooltipFunctionReadyFunction
                    )
                );
            }
        }
    }
});

export default FormField;

const tooltipFunctionReadyFunction = (origin, tooltip) => {
    tooltip.find(".submit").click(submitEvent => {
        submitEvent.preventDefault();
        dialog
            .confirm(
                "You have unsaved changes. Do you want to proceed?",
                new ModalManager({
                    container: $(".flow-designer-root"),
                    freeze: false
                })
            )
            .then(function(result) {
                if (result) {
                    window.location.href = submitEvent.target.href;
                }
            });
    });
};
