import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";

export default _({}).extend({
    // Note: be sure to pass in a deep-cloned config object!

    initialRender: true,
    setConfiguration: function (config) {
        var theme = config.theme;
        var config = config.config;
        if (!theme) return console.warn("No theme provided.");

        this.chartConfig = {
            data: config.data,
            columns: config.columns,
            config: config,
        };

        if (this.initialRender) {
            this.initialRender = false;
            this.chartConfig.renderSearchView = true;
        } else {
            this.chartConfig.renderSearchView = false;
        }
    },
    resize: function () {
        this.renderTable();
    },
});
