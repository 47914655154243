import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { StriimTypography, StriimTabs, StriimTab, StriimButton, StriimPagination } from "@striim/striim-ui";
import AppTile from "../../apps/pages/app-list/components/app-tile/app-tile";
import { Grid } from "@mui/material";
import usePagination from "../common/pagination";
import useStyles from "./your_apps.styles";
import headerStore from "src/stores/header";
import appFilterMap, { getSortText } from "../common";
import { useNavigate } from "react-router";
import config from "src/config";

const tabMap = new Map();
tabMap.set(0, "Recent");
tabMap.set(1, "Running");
tabMap.set(2, "Terminated");
tabMap.set(3, "Halted");

const filterMap = appFilterMap();

const isRecent = index => tabMap.get(index) === "Recent";

export const getNumRecordsString = (page, pageCount, numApps) => {
    let start;
    let end;

    // if not on last page
    if (page < pageCount) {
        start = 1 + 5 * (page - 1);
        end = 5 + 5 * (page - 1);
    }
    // if on last page and number of apps is less than the page total
    else if (page === pageCount && numApps === 1 + 5 * (page - 1)) {
        start = 1 + 5 * (page - 1);
        return `${start} of ${numApps}`;
    }
    // if on last page
    else {
        start = 1 + 5 * (page - 1);
        end = numApps;
    }
    return `${start} - ${end} of ${numApps}`;
};

const YourApps = ({ apps, visibleApps }) => {
    const classes = useStyles();
    const [tabIndex, setTabIndex] = useState(0);
    const [sortToggled, setSortToggled] = useState(false);
    const navigate = useNavigate();
    const appSort = (a, b) => {
        return !sortToggled
            ? a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : -1
            : a.name.toLowerCase() < b.name.toLowerCase()
            ? 1
            : -1;
    };
    const [page, setPage] = useState(1);
    const [filteredApps, setFilteredApps] = useState(headerStore.latestApps.slice());
    const paginatedApps = usePagination(filteredApps, 5);
    const currentData = paginatedApps.currentData(!isRecent(tabIndex) ? appSort : undefined);
    const additionalPage = currentData.length <= 5 ? 0 : 1;
    const pageCount = Math.ceil(filteredApps.length / 5) + additionalPage;
    const canSort = !isRecent(tabIndex) && !!filteredApps.length;

    const handleTabChange = (_event, newValue) => {
        setTabIndex(newValue);
        setSortToggled(false);
        if (newValue === 0) {
            setFilteredApps(headerStore.latestApps.slice());
            return;
        }
        const theFilter = filterMap.get(tabMap.get(newValue));
        setFilteredApps(apps.slice().filter(theFilter));
    };

    const handlePageChange = (e, page) => {
        setPage(page);
        paginatedApps.jump(page);
    };

    const matchVisibleAppsWithCurrentData = useMemo(() => {
        return currentData?.map(item => visibleApps?.find(t2 => t2?.id === item?.id));
    }, [currentData, visibleApps]);

    const SortComponent = () => {
        return (
            <Grid item style={{ width: "100%", cursor: "none" }}>
                <Grid
                    item
                    onClick={() => {
                        setSortToggled(!sortToggled);
                    }}
                    className={classes.sortContainer}
                >
                    <Grid item>
                        <StriimTypography
                            style={{ userSelect: "none" }}
                            data-test-id="button--homepage-recent-apps-sort"
                        >
                            {getSortText(sortToggled, filteredApps.length, tabMap.get(tabIndex))}
                        </StriimTypography>
                    </Grid>
                    {!isRecent(tabIndex) && !!filteredApps.length && (
                        <Grid
                            item
                            style={
                                sortToggled
                                    ? {
                                          transform: "rotate(180deg)"
                                      }
                                    : {}
                            }
                        >
                            <img
                                src="src/modules/apps/pages/homepage/resources/images/sort-arrow.png"
                                className={classes.sortIcon}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    };
    return (
        <>
            <Grid container style={{ width: "100%", flexDirection: "row" }}>
                <Grid container style={{ alignItems: "center", justifyContent: "space-between" }}>
                    <StriimTypography variant="h3" data-test-id="header--homepage-recent-apps">
                        Your Apps{" "}
                    </StriimTypography>
                </Grid>
                <Tabs
                    handleTabChange={handleTabChange}
                    tabIndex={tabIndex}
                    apps={apps}
                    filterMap={filterMap}
                    rightContent={
                        <StriimButton
                            variant="text"
                            onClick={() => {
                                navigate("/applications");
                            }}
                        >
                            View All Apps
                        </StriimButton>
                    }
                    content={
                        <>
                            {canSort && <SortComponent />}
                            <Apps currentData={matchVisibleAppsWithCurrentData} />
                        </>
                    }
                />
            </Grid>
            {!isRecent(tabIndex) && !!currentData.length && (
                <Grid container className={classes.paginationContainer}>
                    <Grid item>{getNumRecordsString(page, pageCount, filteredApps.length)}</Grid>
                    <Grid item>
                        <StriimPagination
                            shape="rounded"
                            count={pageCount}
                            page={page}
                            onChange={handlePageChange}
                            classes={{ selected: classes.selected }}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export const Tabs = ({ handleTabChange, tabIndex, apps, filterMap, content, rightContent }) => {
    const getCount = status =>
        apps
            ?.slice()
            ?.filter(filterMap.get(status))
            .length.toString();

    return (
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <StriimTabs value={tabIndex} onChange={handleTabChange} content={content} rightContent={rightContent}>
                <StriimTab data-test-id="tab--homepage-recent-apps" label="Recent" />
                <StriimTab
                    data-test-id="tab--homepage-running-apps"
                    label={"Running"}
                    badgeContent={getCount("Running")}
                    badgeProps={{ color: "success" }}
                    onClick={() => handleTabChange(undefined, 1)}
                />
                <StriimTab
                    data-test-id="tab--homepage-terminated-apps"
                    label={"Terminated"}
                    badgeContent={getCount("Terminated")}
                    badgeProps={{ color: "critical" }}
                    onClick={() => handleTabChange(undefined, 2)}
                />
                <StriimTab
                    data-test-id="tab--homepage-halted-apps"
                    label={"Halted"}
                    badgeContent={getCount("Halted")}
                    badgeProps={{ color: "warning" }}
                    onClick={() => handleTabChange(undefined, 3)}
                />
            </StriimTabs>
        </Grid>
    );
};

export const Apps = ({ currentData }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.appTilesContainer}>
            {currentData?.length ? (
                currentData?.map(app => {
                    return (
                        !!app && (
                            <Grid item xs={12} key={app.id}>
                                <AppTile
                                    key={app.id}
                                    app={app}
                                    checkboxDisabled={true}
                                    wide={true}
                                    dataFetchInterval={config.time.homepageIntervalMontoringData}
                                />
                            </Grid>
                        )
                    );
                })
            ) : (
                <>
                    <Grid item xs={12} className={classes.empty}>
                        <img
                            data-test-id="image--homepage-recent-apps-empty"
                            src="src/generic/icon/manage-striim/03-Apps-CreateApps.svg"
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.empty}>
                        <StriimTypography data-test-id="header--homepage-recent-apps-empty">
                            There are no apps in this tab at the moment
                        </StriimTypography>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default YourApps;

YourApps.propTypes = {
    apps: PropTypes.array.isRequired
};
