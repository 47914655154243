import App from "app";
import _ from "underscore";
import Backbone from "backbone";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import formBuilderUtils from "app/components/common/editor/meta-object-editors";
import metaObjects from "core/services/metaStoreService/meta-objects";
import setUpTargetTemplate from "./set-up-target-template.html";
import "lib/vendor/jquery.deparam";
import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import prefillProperties from "app/components/flow/designer/nodeProcessing/prefillProperties";
import sanitizer from "./server-error-sanitizer";
import objectValidator from "./object-validator";
import NProgress from "nprogress";
import api from "core/api/api";
import utils from "core/utils";
import MarionetteWrapper from "app/components/marionette-wrapper-for-react";
import { WarningMsgBox } from "src/generic/message-box/message-box";
import ensureRegions from "../../../../../common/_ensureRegions";
import growl from "app/components/common/growl";
import { DB_PROVIDERS } from "../../source/database-reader/configs/db-providers";
import headerStore from "src/stores/header";
import getControlResource from "../../../../../common/editor/control-resource-resolver";
import { validationUtils } from "../../../../../../../src/modules/wizards/utils";
import { APP_ADAPTER_DEFAULTS } from "../../common/app-adapters-global-config";
import InlineHelpMarionetteContainer from "../../source/inline-help-container";

NProgress.configure({
    minimum: 0.25,
    trickleRate: 0.1,
    trickleSpeed: 800
});

let ModuleModel = Backbone.Model.extend({
    defaults: {
        errors: null,
        targetModel: null,
        nsName: null,
        appName: null,
        targetTypeName: null,
        appModel: new Backbone.Model()
    }
});

/**
 *
 */
class BaseSimpleTarget {
    constructor(predefinedComponentName, getCustomAdapterProperties) {
        if (getCustomAdapterProperties) {
            this.View = this.getView().extend({
                predefinedComponentName: predefinedComponentName,
                getCustomAdapterProperties: getCustomAdapterProperties
            });
        } else {
            this.View = this.getView().extend({
                predefinedComponentName: predefinedComponentName
            });
        }
    }
    get() {
        return {
            View: this.View
        };
    }

    getView() {
        let View = InlineHelpMarionetteContainer.extend({
            template: _.template(setUpTargetTemplate),
            predefinedComponentName: null,
            regions: {
                formContainer: ".form-container"
            },
            ui: {
                wizardContainer: ".target-wizard-container"
            },
            initialize: function(options) {
                this.model = new ModuleModel();
                let predefinedComponentName = PredefinedComponentNames[this.predefinedComponentName];
                let appName = this.options.appName ? this.options.appName.split(".")[1] : null;

                if (!predefinedComponentName) {
                    throw new Error("Component not found");
                }
                this.model.set("targetTypeName", predefinedComponentName.name || "");

                let targetAdapterProps = {};
                targetAdapterProps = prefillProperties(
                    "TARGET",
                    predefinedComponentName.name,
                    PredefinedComponentNames,
                    targetAdapterProps
                );
                targetAdapterProps.adapter.handler = "Global.PROPERTYTEMPLATE." + targetAdapterProps.adapter.handler;
                let targetModel = new metaObjects.TARGET.Model(targetAdapterProps);
                this.model.set("targetModel", targetModel);
                this.fetchDependencies();
                this.on("do:next", () => {
                    this.saveModel();
                });
                const ID =
                    this.model
                        .get("targetTypeName")
                        ?.replace(/\s+/g, "-")
                        .toLowerCase() + "-configure-target";
                InlineHelpMarionetteContainer.prototype.initialize.call(this, ID);
            },

            onRender: function() {
                ensureRegions(this);
                this.showErrors(null);
                let form = formBuilderUtils.createForMetadataObject(this.model.get("targetModel"), {
                    autoSave: true,
                    displayFields: ["name", "adapter", "formatter"],
                    sourceAdapters: [this.options.sourceAdapter],
                    disableAdapterSelect: true
                });
                this.getRegion("formContainer").show(form);
                this.form = form;
            },

            showErrors(error) {
                let messageBox = this.$el.find(".error-box-container");
                let errorMessage = this.$el.find(".error-message");
                if (!error) {
                    messageBox.hide();
                    errorMessage.html("");
                } else {
                    errorMessage.html(error);
                    messageBox.show();
                }
            },
            setAppMonitorOnLandingPage(namespace, appName) {
                let appId = namespace + ".APPLICATION." + utils.getName(appName);
                let title = "App Metrics - " + appName;
                let item = { attr: false, title: title, comp: "App", appId };
                let list = JSON.parse(localStorage.getItem("seenWidgets"));
                Array.isArray(list) ? list.splice(1, 0, item) : (list = [item]);
                localStorage.setItem("seenWidgets", JSON.stringify(list));
                headerStore.addToLatestApps(this.appModel.toJSON());
            },
            showError: function(err) {
                let domErrorContainer = $(".error-box-container");
                let domErrorMessage = $(".error-message");
                if (!err) {
                    domErrorContainer.hide();
                } else {
                    domErrorMessage.html(err);
                    domErrorContainer.show();
                    // this.$el.find(".error.message .msg").html(err);
                    // errors.show();
                    // let offsetTop = $("#appwizard-componentwizard").offset().top;
                    // let offsetBottom = $("#appwizard-footer").outerHeight();
                    // if (!utils.isElementInViewport(errors[0], offsetTop, offsetBottom)) {
                    //     errors[0].scrollIntoView({ behavior: "smooth" });
                    // }
                }
            },
            async validateControls() {
                const controls = this.form.options.model.controls;
                let formDirty = false;
                let errorFields = [];
                for (const control of controls) {
                    try {
                        if (["formatter", "adapter"].includes(control.propertyName)) {
                            const res = await control.view.validate(control?.resourceLabel);
                            if (Array.isArray(res) && res.length > 0) {
                                errorFields = errorFields.concat(res);
                                formDirty = true;
                            }
                        } else {
                            try {
                                await control.view.validate(control?.resourceLabel);
                            } catch (error) {
                                formDirty = true;
                                errorFields.push(control.propertyName);
                            }
                        }
                    } catch (error) {
                        console.log("Error while validating controls", error);
                    }
                }
                return errorFields;
            },

            async saveModel() {
                let appName = this.options.appName ? this.options.appName.split(".")[1] : null;
                let namespace = this.options.appName ? this.options.appName.split(".")[0] : null;
                App.vent.trigger("appwizard:next:disable");
                NProgress.configure({
                    parent: "#appwizard-topbar"
                });
                NProgress.start();
                this.model.set("errors", null);
                this.showErrors(null);
                let targetModel = this.model.get("targetModel");

                try {
                    let errorFields = await this.validateControls();
                    if (errorFields.length > 0) {
                        errorFields = errorFields.map(field => getControlResource(field)?.name ?? field);
                        this.showErrors(
                            "Fix the following errors in the target configuration</br>Provide the missing inputs: " +
                                errorFields.join(", ")
                        );
                        return;
                    } else {
                        this.showErrors(null);
                    }
                    await targetModel.save({ flow: appName });
                    await api.compile(this.options.appName);
                    this.setAppMonitorOnLandingPage(namespace, appName);
                    App.vent.trigger("appwizard:next:step");
                } catch (e) {
                    this.showErrors(sanitizer.sanitize("Error Creating Component: " + e.message));
                } finally {
                    NProgress.done();
                    App.vent.trigger("appwizard:next:enable");
                }
            },

            setTargetSchemaPrefix(currentProps) {
                if (currentProps.connectionUrl && currentProps.connectionUrl.indexOf("dbc:snowflake") !== -1) {
                    let queryParams = currentProps.connectionUrl.slice(currentProps.connectionUrl.lastIndexOf("?") + 1);
                    let vars = queryParams.split("&");
                    for (let i = 0; i < vars.length; i++) {
                        let pair = vars[i].split("=");
                        if (decodeURIComponent(pair[0]) == "db") {
                            return decodeURIComponent(pair[1]) + ".";
                        }
                    }
                }
                return "";
            },

            getTargetSchema(targetSchemaPrefix, secondPart) {
                switch (this.predefinedComponentName) {
                    case "KuduWriter":
                        return "%";
                    default:
                        return targetSchemaPrefix + secondPart + ".%";
                }
            },

            getTargetTable(targetSchemaPrefix, secondPart, thirdPart) {
                switch (this.predefinedComponentName) {
                    case "KuduWriter":
                        return thirdPart;
                    default:
                        return targetSchemaPrefix + secondPart + "." + thirdPart;
                }
            },

            getValidName(name) {
                // Remove leading and trailing quotes and escape char before '_'
                // since underscore is not treated as special char as per regex.
                return name
                    .replace(/^"/, "")
                    .replace(/"$/, "")
                    .replace(/\\_/g, "_");
            },
            async validateControls() {
                const controls = this.form.options.model.controls;
                let formDirty = false;
                let errorFields = [];
                for (const control of controls) {
                    try {
                        if (["formatter", "adapter"].includes(control.propertyName)) {
                            const res = await control.view.validate(control?.resourceLabel);
                            if (Array.isArray(res) && res.length > 0) {
                                errorFields = errorFields.concat(res);
                                formDirty = true;
                            }
                        } else {
                            try {
                                await control.view.validate(control?.resourceLabel);
                            } catch (error) {
                                formDirty = true;
                                errorFields.push(control.propertyName);
                            }
                        }
                    } catch (error) {
                        console.log("Error while validating controls", error);
                    }
                }
                return errorFields;
            },

            clearTables(source, target) {
                const targetAdapter = target?.split(".").pop();
                return validationUtils.isAppAdapter(source) || validationUtils.isAppAdapter(targetAdapter);
            },
            getTablesProperty(properties) {
                return properties?.Tables ?? properties?.tables ?? null;
            },

            async setTablesProperty(appModel, currentProps, targetAdapter) {
                let targetSchemaPrefix = this.setTargetSchemaPrefix(currentProps);
                try {
                    let that = this;
                    let getTablesProperty = function(sourceObject) {
                        const adapterProps = sourceObject.get("adapter");
                        if (that.clearTables(adapterProps?.properties?.adapterName, targetAdapter)) return null;
                        let tableMapping = [];
                        // This regex is taken from com.striim.IdentifierUtils.CaseSensitivityUtils.NON_SPL_CHAR_REGEX
                        const regex = new RegExp("^[a-zA-Z][a-zA-Z0-9_]*$");
                        if (
                            adapterProps &&
                            adapterProps.properties &&
                            that.getTablesProperty(adapterProps.properties)
                        ) {
                            if (adapterProps.properties.adapterName === "ServiceNowReader") {
                                const objects = adapterProps.properties.Tables.split(";");
                                _.each(objects, function(object) {
                                    tableMapping.push(`${object}, ${targetSchemaPrefix + object}.%`);
                                });
                            } else {
                                const tables = that.getTablesProperty(adapterProps.properties).split(";");
                                let wildcardSchemas = new Set();
                                _.each(tables, function(table) {
                                    let chunks = table.split(".");
                                    let firstPart = null;
                                    if (chunks.length === 3) firstPart = chunks.shift();

                                    let secondPartOriginal = chunks.shift();
                                    let secondPart = secondPartOriginal;
                                    let secondPartMod = that.getValidName(secondPart);
                                    let isSafe = true;
                                    if (regex.test(secondPartMod)) secondPart = secondPartMod;
                                    else isSafe = false;

                                    let thirdPart = chunks.shift();
                                    if (thirdPart) {
                                        let thirdPartMod = that.getValidName(thirdPart);
                                        if (regex.test(thirdPartMod)) thirdPart = thirdPartMod;
                                        else isSafe = false;
                                    }

                                    if (isSafe) {
                                        let targetSchema = that.getTargetSchema(targetSchemaPrefix, secondPart);
                                        let sourceSchema =
                                            (firstPart != null ? firstPart + "." : "") + secondPartOriginal + '."%"';
                                        wildcardSchemas.add(
                                            JSON.stringify({ source: sourceSchema, target: targetSchema })
                                        );
                                    } else {
                                        let targetTable = that.getTargetTable(
                                            targetSchemaPrefix,
                                            secondPart,
                                            thirdPart
                                        );
                                        tableMapping.push(table + "," + targetTable);
                                    }
                                });
                                wildcardSchemas.forEach(item => {
                                    item = JSON.parse(item);
                                    tableMapping.push(item.source + "," + item.target);
                                });
                            }
                        } else if (adapterProps && adapterProps.properties && adapterProps.properties.sObjects) {
                            //  This is displayed in target's tables field, for Salesforce source
                            const objects = adapterProps.properties.sObjects.split(";");
                            _.each(objects, function(object) {
                                tableMapping.push(`${object}, ${targetSchemaPrefix + object}.%`);
                            });
                        }
                        return tableMapping.join(";");
                    };
                    var targetAdapterPropertyTemplate = propertyTemplateService.propertyTemplates.findWhere({
                        id: targetAdapter
                    });
                    let tablesField = null;
                    if (targetAdapterPropertyTemplate) {
                        _.each(targetAdapterPropertyTemplate.get("propertyMap"), function(propertyMap) {
                            if (propertyMap.name.toLowerCase() === "tables") {
                                tablesField = propertyMap.name;
                            }
                        });
                    }
                    if (tablesField !== null) {
                        const sourceObjects = await appModel.getApplicationComponentsByType("SOURCE");
                        const firstSource = sourceObjects.at(0);
                        const tablesProperty = getTablesProperty(firstSource);
                        currentProps[tablesField] = tablesProperty;
                        return currentProps;
                    }
                } catch (e) {
                    console.error("Error processing property template", e);
                }
            },

            fetchDependencies() {
                let nsName = this.options.appName ? this.options.appName.split(".")[0] : null;
                let appName = this.options.appName ? this.options.appName.split(".")[1] : null;
                var appId = metaObjectConverter.getId(nsName, "APPLICATION", appName);
                (async () => {
                    try {
                        this.appModel = await metaStoreService.findById(appId);
                        let streamObjects = await this.appModel.getApplicationComponentsByType("STREAM");
                        let firstStream = streamObjects.at(0);
                        let targetModel = this.model.get("targetModel");
                        targetModel.set("inputStream", firstStream.get("id"));
                        targetModel.set("nsName", nsName);
                        let name = this.model.get("targetTypeName").replace(/[^A-Z0-9]+/gi, "_");
                        targetModel.set("name", `${name}_${appName}_Target`);
                        await this.setTablesProperty(
                            this.appModel,
                            targetModel.get("adapter").properties,
                            targetModel.get("adapter").handler
                        );

                        let customProperties = await this.getCustomAdapterProperties(this.predefinedComponentName);

                        await this.configureForAppAdapterSource(
                            targetModel.get("adapter").properties,
                            customProperties,
                            targetModel.get("adapter").handler?.split(".")[2]
                        );

                        _.extend(targetModel.get("adapter").properties, customProperties);
                        this.render();
                        this.ui.wizardContainer.show();
                    } catch (e) {
                        growl.error(e, "Error");
                    } finally {
                        App.vent.trigger("appwizard:render:footer");
                        App.vent.trigger("appwizard:back:hide");
                    }
                })();
            },

            configureForAppAdapterSource: async function(currentProps, customProperties, targetAdapter) {
                if (validationUtils.isAppAdapter(this.options.sourceAdapter)) {
                    this.appModel.set("recoveryPeriod", 300);
                    await api.updateApplicationSettings(this.appModel.get("attributes"));
                    if (validationUtils.isDWH(targetAdapter)) {
                        currentProps["CDDLOptions"] = APP_ADAPTER_DEFAULTS.CDDLOPTIONS;
                        currentProps["Mode"] = APP_ADAPTER_DEFAULTS.MODE;
                        if (targetAdapter === "SnowflakeWriter") {
                            customProperties["appendOnly"] = false;
                        }
                    }
                }
            },

            getCustomAdapterProperties: function() {
                return new Promise((resolve, reject) => {
                    resolve({});
                });
            }
        });

        return View;
    }
}

export default BaseSimpleTarget;
