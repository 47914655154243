import { observer } from "mobx-react-lite";
import growl from "../../../app/components/common/growl";
import { ClearMemory, FileBrowserContent } from "./file_browser_content";
import { StriimModal, StriimTypography } from "@striim/striim-ui";
import React, { useEffect } from "react";

const UploadModal = observer(({ localStore, modalState, onSelect }) => {
    useEffect(() => {
        ClearMemory();
    }, [modalState]);

    async function process() {
        if (localStore.fileToUpload) {
            let formData = new FormData();
            formData.append("file", localStore.fileToUpload);
            let res = await fetch("/upload?path=" + encodeURIComponent(localStore.directoryToUpload), {
                method: "POST",
                body: formData
            });
            if (res.ok) {
                let payload = await res.text();
                growl.success("File Upload Successful");
                onSelect(payload);
                ClearMemory();
            } else {
                let payload = await res.text();
                growl.error(payload, "There was a problem uploading the file");
            }
            localStore.setFileToUpload(null);
        } else {
            onSelect(localStore.selected.fullPath);
        }

        modalState[1](false);
    }
    return (
        <StriimModal
            size="medium"
            autoHeight
            isVisible={modalState[0]}
            onConfirm={() => {
                process();
            }}
            onCancel={() => {
                localStore.setSelected(null);
                localStore.setFileToUpload(null);
                modalState[1](false);
            }}
            variant="primary"
            confirmContent={localStore.fileToUpload ? "Upload and Select" : "Select File"}
            titleContent={<StriimTypography variant={"h2"}>Select or Upload Files</StriimTypography>}
            confirmButtonProps={{
                disabled: !(localStore.fileToUpload || localStore.selected),
                "data-test-id": "confirm-select-file"
            }}
        >
            <FileBrowserContent localStore={localStore} />
        </StriimModal>
    );
});
export default UploadModal;
