import React from "react";
import { StriimTypography } from "@striim/striim-ui";
import { Box } from "@mui/material";
import styles from "./header.styles";

interface HeaderProps {
    heading?: string | JSX.Element;
    subHeading?: string | JSX.Element;
    additionalComponent?: JSX.Element;
    rightContent?: JSX.Element;
}

const Header = ({ heading, subHeading, additionalComponent, rightContent }: HeaderProps) => {
    return (
        <Box sx={styles.container}>
            <Box sx={styles.titleContainer}>
                {heading && <StriimTypography variant="h2">{heading}</StriimTypography>}
                {rightContent}
            </Box>
            {subHeading && (
                <StriimTypography sx={styles.subHeading} variant="body4">
                    {subHeading}
                </StriimTypography>
            )}
            {additionalComponent}
        </Box>
    );
};
export default Header;
