import api from "../../../../core/api/api";
import AdhocQueryService from "core/services/queryService/adhoc-query-service.ts";
import { Job, JobStatuses, JobVersion, PIISettings } from "./guardian-job.types";
import metaStoreService from "../../../../core/services/metaStoreService/meta-store-service";

interface GuardianService {
    createPIIJob: (name: string, nsName: string, description: string, apps: string[]) => Promise<string>;
    updateJobDescription: (
        name: string,
        nsName: string,
        description: string,
        currentJobVersion: JobVersion
    ) => Promise<any>;
    startPIIJob: (name: string, nsName: string) => Promise<string>;
    retryPiiJob: (name: string) => Promise<string>;
    getPIIJobsForApp: (appName: string) => Promise<JobVersion[]>;
    getAIAgentsList: () => Promise<Job[]>;
    getJob: (name: string, nsName: string) => Promise<Job>;
    getJobs: () => Promise<Job[]>;
    getJobDetails: (jobName: string) => Promise<Job[]>;
    getPIISettings: () => Promise<string>;
    setPIISettings: (settings: PIISettings) => Promise<string>;
    getSensitiveDataCount: (jobName: string) => Promise<[{
        count?: number
    }]>;
    getNonSensitiveDataCount: (jobName: string) => Promise<[{
        count?: number
    }]>;
    getErrorCount: (jobName: string) => Promise<[{
        count?: number
    }]>;
    getDistinctSourceCount: (jobName: string) => Promise<[{
        count?: number
    }]>;
    getDistinctSourceWithPIICount: (jobName: string) => Promise<[{
        count?: number
    }]>;
    getReportForJob: (jobName: string) => Promise<string>;
    getReportsOfAllAppsForCurrentUser: () => Promise<string>;
    listPIISettingsOptions: () => Promise<string>;
    deleteJob: (name: string, nsName: string) => Promise<string>;
    cancelPIIJob: (name: string) => Promise<string>;
    getSourceTypeInfo: (fqn: string) => Promise<string>;
    getAppInfoBySourceType: (fqn: string) => Promise<string>;
}

const guardianService: GuardianService = {
    createPIIJob: async (name, nsName, description, apps) => {
        return api._CRUDHandler("", "CREATE", {
            name,
            description,
            applications: apps,
            status: JobStatuses.CREATED,
            nsName: nsName,
            type: metaStoreService.entities.AIAGENT
        });
    },

    updateJobDescription: async (name, nsName, description, currentJobVersion) => {
        return api._CRUDHandler("", "UPDATE", {
            name,
            nsName,
            description,
            applications: currentJobVersion.applications,
            status: currentJobVersion.status,
            type: metaStoreService.entities.AIAGENT
        });
    },

    startPIIJob: async (name, nsName) => {
        return api.startPIIJob(`${nsName}.${name}`);
    },

    retryPiiJob: async name => {
        return api.retryPiiJob(name);
    },

    getPIIJobsForApp: async appName => {
        return api.getPIIJobsForApp(appName);
    },

    setPIISettings: async settings => {
        return api.setPIISettings(settings);
    },

    getAIAgentsList: async () => {
        return api._CRUDHandler("", "READ", {
            type: metaStoreService.entities.AIAGENT
        });
    },

    getJob: async (name, nsName) => {
        return api._CRUDHandler("", "READ", {
            type: "AIAGENT",
            name: name,
            nsName: nsName
        });
    },

    getJobs: () => {
        const query = `select * from System$PIIGlobal.AI_PIIStore;`;
        return AdhocQueryService.executeQuery(query, true);
    },

    getJobDetails: jobName => {
        const query = `select * from System$PIIGlobal.AI_PIIStore where jobName = "${jobName}";`;
        return AdhocQueryService.executeQuery(query, false);
    },

    getPIISettings: async () => {
        return api.getPIISettings();
    },

    getSensitiveDataCount: jobName => {
        const query = `SELECT COUNT(DISTINCT appName) AS count FROM System$PIIGlobal.AI_PIIStore WHERE jobName = '${jobName}' and piiData != '{}';`;
        return AdhocQueryService.executeQuery(query);
    },

    getNonSensitiveDataCount: jobName => {
        const query = `SELECT COUNT(DISTINCT appName) AS count FROM System$PIIGlobal.AI_PIIStore WHERE jobName = '${jobName}' and piiData = '{}';`;
        return AdhocQueryService.executeQuery(query);
    },

    getErrorCount: jobName => {
        const query = `SELECT COUNT(DISTINCT appName) AS count FROM System$PIIGlobal.AI_PIIStore WHERE jobName = '${jobName}' and status = "ERROR";`;
        return AdhocQueryService.executeQuery(query);
    },

    getDistinctSourceCount: jobName => {
        const query = `select count(Distinct(sourceName)) AS count FROM System$PIIGlobal.AI_PIIStore where jobName = '${jobName}';`;
        return AdhocQueryService.executeQuery(query);
    },

    getDistinctSourceWithPIICount: jobName => {
        const query = `select count(Distinct(sourceName)) as count from System$PIIGlobal.AI_PIIStore where jobName = '${jobName}' and piiData !='{}';`;
        return AdhocQueryService.executeQuery(query);
    },

    getReportForJob: async jobName => {
        return api.getReportForJob(jobName);
    },

    getReportsOfAllAppsForCurrentUser: async () => {
        return api.getReportsOfAllAppsForCurrentUser();
    },
    listPIISettingsOptions: async () => {
        return api.listPIISettingsOptions();
    },
    deleteJob: async (name, nsName) => {
        return api._CRUDHandler("", "DELETE", {
            type: "AIAGENT",
            name: name,
            nsName: nsName
        });
    },
    cancelPIIJob: async (jobFQNName) => {
        return api.cancelPIIJob(jobFQNName);
    },
    getSourceTypeInfo: async fqn => {
        return api.getSourceTypeInfo(fqn);
    },
    getAppInfoBySourceType: async fqn => {
        return api.getAppInfoBySourceType(fqn);
    }
};

export default guardianService;
