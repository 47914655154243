import Backbone from "backbone";
import _ from "underscore";
import $ from "jquery";
import Dropdown from "app/components/common/dropdown";
import GraphNodeModel from "./graphNodes/graphNodeModel";
import FlowGraphNode from "./graphNodes/flowGraphNode";
import GraphInitializer from "./graphInitializer";
import NewSubflowDialog from "./newSubflowDialog";
import MetaObjects from "core/services/metaStoreService/meta-objects";
import statusManagement, {FlowStatus} from "src/status-management";
import template from "./templates/flowMapDropdownTemplate.html";

var FlowMapView = Backbone.Marionette.CompositeView.extend({
    template: _.template(template),
    tagName: "div",
    childView: FlowGraphNode.View,
    childViewContainer: "#flow-map",

    ui: {
        newSubflowButton: ".new-subflow-button",
        dropdownLabel: "#flow-dropdown",
        appFlowReload: "#app-flow-reload",
        editButton: "i.edit-button",
        deploymentInfo: ".deployment-info"
    },

    isEnabled: true,

    initialize: function(options) {
        var _this = this;
        this.collection = new GraphNodeModel.Collection();
        this.modalManager = options.modalManager;

        this.listenTo(this.model, "change:nodes", function() {
            //_this._updateNodes();
        });
        this.on("childview:flow-node-click", function(view, model) {
            _this._hideDropdownIfOpen();
            _this.trigger("flow-node-click", model);
        });

        this._updateNodes();
        this.graphInitializer = new GraphInitializer();

        var hideDropdown = _.debounce(function() {
            if (_this.flowmap_dropdown) {
                _this.flowmap_dropdown.hide();
            }
        }, 150);
        $(window).resize(hideDropdown);
    },

    setAppStatus: function(app_status) {
        this.toggleElementsEnabled(app_status.toUpperCase());
    },

    onRender: function() {
        var _this = this;
        this.flowmap_dropdown = new Dropdown({
            trigger: this.$el.find("#flow-dropdown"),
            content: this.$("#flow-map-dropdown"),
            dynamic: true, // though we will still update it manually most of the time
            classes: "flow-dropdown",
            on_show: this.showDdl.bind(this),
            on_hide: function() {
                _this.$el.find("#flow-dropdown").removeClass("active");
            },
            before_show: function() {
                return _this.isEnabled;
            }
        });

        this.ui.newSubflowButton.click(this.createNewSubFlow.bind(this));
        this.ui.appFlowReload.click(this.reloadApplication.bind(this));

        this.setActiveNode();

        this.toggleElementsEnabled(
            this.model.app.metaInfoStatus.isValid ? this.model.app.flowStatus : FlowStatus.INVALID
        );
    },

    onBeforeDestroy: function() {
        this.flowmap_dropdown.destroy();
        this.flowmap_dropdown = null;
    },

    reloadApplication: function() {
        this._hideDropdownIfOpen();
        this.trigger("flow-node-click", null);
    },

    _hideDropdownIfOpen: function() {
        if (this.flowmap_dropdown) {
            this.flowmap_dropdown.hide();
        }
    },

    showDdl: function(tooltip) {
        this.$el.find("#flow-dropdown").addClass("active");
        //
        // set droppdown top baseline
        // and set arrow color - we need to use additional span element
        //
        var $toolbar = $("#toolbar");
        var leftOffset = $("#striim-navbar").width();
        var topOffset = -5;
        tooltip.css("top", window.pageYOffset + $toolbar.position().top + $toolbar.outerHeight() + topOffset + "px");
        tooltip.css("left", window.pageXOffset + leftOffset + "px");

        // because we are using dropdown
        // the container element cannot be found using this.$el
        // and we need to look for it across whole HTML document
        this.graphInitializer.$containerEl = $("#flow-map");
        if (this.dropDownjsPlumbInstance) {
            this.dropDownjsPlumbInstance.reset();
        }

        //
        //  reset collection
        //
        this._updateNodes();
        this.setActiveNode();

        this.dropDownjsPlumbInstance = this.graphInitializer.setGraph(this.collection, 16, 16, 32);
    },

    setActiveNode: function() {
        var activeModel = this.collection.byId("dropdown_" + this.model.currentFlowId);
        if (activeModel) {
            var activeView = this.children.findByModel(activeModel);
            if (activeView) {
                activeView.setActive();
            }
        }
    },

    _updateNodes: function() {
        var graphModel = this.model.datasource
            .getObjectsForFlowId()
            .filter(function(node) {
                return node.metaObject.type === "FLOW";
            })
            .map(function(node) {
                return {
                    id: "dropdown_" + node.id,
                    metaObject: node.metaObject,
                    targets: node.targets
                        // we want only FLOW targets in the flow dropdown
                        .filter(function(id) {
                            return id.indexOf(".FLOW.") > -1;
                        })
                        .map(function(id) {
                            return "dropdown_" + id;
                        })
                };
            });
        this.collection.reset(graphModel);
    },

    createNewSubFlow: function() {
        var _this = this;

        var dialog = new NewSubflowDialog({
            id: 1,
            model: new Backbone.Model({
                title: "",
                collection: _this.collection,
                namespace: this.model.app.nsName
            })
        });

        this.modalManager.add(dialog);
        new NewSubflowDialog.Controller({
            view: dialog
        });

        _this.listenToOnce(dialog, "form:submit", function(dialog) {
            _this.model.set("currentFlowName", dialog.model.get("title"));

            // TODO should this be here or in designer.js?
            var newFlow = new MetaObjects.FLOW.Model();
            newFlow.name = dialog.model.get("title");
            newFlow.nsName = _this.model.app.nsName;
            newFlow
                .save({
                    flow: _this.model.app.name
                })
                .then(function(savedFlow) {
                    _this.trigger("new-flow", {
                        metaObject: {
                            id: savedFlow.id
                        },
                        attributes: {
                            metaObject: {
                                name: savedFlow.name
                            }
                        }
                    });
                    dialog.stopProgress();
                    dialog.destroy();
                })
                // Can't create flow due to error from the server
                .fail(function(error) {
                    dialog.setError(error.message);
                });
        });

        this.listenTo(dialog, "cancel-custom", function(dialog) {
            dialog.view.options.animation.hide = "morph";
            dialog.view.destroy();
        });

        _this.listenTo(dialog, "before:destroy", function() {
            _this.modalManager.remove_all_modals();
        });
    },

    setDropdownEnabled: function(isEnabled) {
        this._hideDropdownIfOpen();
        this.isEnabled = isEnabled;
        if (isEnabled) {
            this.ui.editButton.show();
        } else {
            this.ui.editButton.hide();
        }
    },

    toggleElementsEnabled: function(appStatus) {
        var allowEditing = statusManagement.isApplicationCreated(appStatus);
        if (allowEditing) {
            this.ui.newSubflowButton.show();
            this.ui.deploymentInfo.hide();
        } else {
            this.ui.newSubflowButton.hide();
            if (this.model.currentDeploymentPlanName) {
                this.ui.deploymentInfo.show();
            }
        }
    }
});

export default FlowMapView;
