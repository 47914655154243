export const ssoLabels = [
    {
        idpLabel: "Identity Provider",
        idp: "Microsoft Entra",
        applicationID: "Application ID",
        certPath: "Select Certificate Path",
        certPathLabel: "Select Certificate Path",
        loginURL: "Login URL",
        appIdPlaceholder: "Enter your Application ID",
        certPathPlaceholder: "Enter your Certificate Path",
        certPathHelper: "Striim must be able to access the *.pem file at all times to validate the users logging in",
        urlPlaceholder: "Enter your Login URL"
    },
    {
        idpLabel: "Identity Provider",
        idp: "Okta",
        applicationID: "Issuer URL",
        certPath: "Select Certificate Path",
        certPathLabel: "Select Certificate Path",
        loginURL: "Sign On URL",
        appIdPlaceholder: "Enter your Issuer URL",
        certPathPlaceholder: "Enter your Certificate Path",
        certPathHelper: "Striim must be able to access the *.pem file at all times to validate the users logging in",
        urlPlaceholder: "Enter your Sign On URL"
    }
];
