import React from "react";
import { Grid, Box } from "@material-ui/core";
import { Observer } from "mobx-react";
import useDashboardsStore from "src/stores/dashboards/useDashboardsStore";
import { StriimTypography } from "@striim/striim-ui";

import useStyles from "./no-search-results.styles";

const NoDashboards = () => {
    const classes = useStyles();
    const store = useDashboardsStore();
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.wrapper}>
            <StriimTypography variant="h2" data-test-id="no-results">
                No Search Results found
            </StriimTypography>
            <Box className={classes.noDashboardImage} component="img" src="app/images/no_dashboards_results.svg" />
            <Observer>
                {() =>
                    store.filters.textFilter ? (
                        <StriimTypography variant="h3" data-test-id="no-results-message">
                            We didn’t find any dashboard by the name {store.filters.textFilter}. Try a different search
                            or clear the filters.
                        </StriimTypography>
                    ) : (
                        <StriimTypography variant="h3">
                            We didn’t find any dashboard matching filters. Try a different search or clear the filters.
                        </StriimTypography>
                    )
                }
            </Observer>
        </Grid>
    );
};

export default NoDashboards;
