import React from "react";
import PropTypes from "prop-types";
import { StriimButton, StriimMessageBox } from "@striim/striim-ui";
import { HiLightBulb } from "react-icons/hi";
import { Box } from "@mui/material";
import sxStyles from "./infobox-form.styles";

const InfoboxFormControl = ({ title, message, actionTitle, actionClickHandler, noCustomIcon }) => {
    return (
        <Box sx={sxStyles}>
            <StriimMessageBox
                heading={title}
                text={message}
                type="INFO"
                customIcon={noCustomIcon ? undefined : <HiLightBulb size={"32px"} />}
                actions={
                    actionTitle ? (
                        <StriimButton
                            variant="text"
                            data-test-id="configure-using-wizard-button"
                            disableFocusRipple={true}
                            onClick={() => {
                                actionClickHandler?.();
                            }}
                        >
                            {actionTitle}
                        </StriimButton>
                    ) : null
                }
            />
        </Box>
    );
};

InfoboxFormControl.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    actionTitle: PropTypes.string,
    actionClickHandler: PropTypes.func,
    noCustomIcon: PropTypes.bool
};

export default InfoboxFormControl;
