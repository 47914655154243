import React, { useState, createRef, useRef, useEffect } from "react";
import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Box } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import styles from "../../table-progress.styles";
import _ from "underscore";
import { observer } from "mobx-react";
import { RowIL } from "./table-rows-il";
import getColumns from "./table-summary-column-data";
import { RowCDC } from "./table-rows-cdc";
import SummaryHeaderContainer from "./summary-header-container";
import { Store } from "../../table-progress-types";
import metaStoreService from "../../../../../core/services/metaStoreService/meta-store-service";
import metaobjectConverter from "../../../../../core/services/metaStoreService/metaobject-converter";
import { getDatabaseType as getDBName } from "../../../wizards/services";
import utils from "../../../../../core/utils";
import { getDatabaseType } from "../../../../../app/components/appwizard/component-wizards/wizards/common/databaseTypes";

const DEFAULT_MIN_WIDTH_CELL = 120;
const DEFAULT_MAX_WIDTH_CELL = 800;

const TableDetailsMui: React.FunctionComponent<{ store: Store; appType: string }> = observer(({ store, appType }) => {
    const isResizing = useRef(-1);
    const [rows, setRows] = useState([]);
    const [sourceAdapters, setSourceAdapters] = useState(null);
    const [targetAdapters, setTargetAdapters] = useState(null);
    const [expandAllRows, setExpandAllRows] = useState(false);
    const headerRef = useRef(null);
    const [Columns, setColumns] = useState(prevColumns => prevColumns ?? getColumns(null));
    const columnRefs = Columns.map(() => createRef());

    useEffect(() => {
        headerRef?.current?.filterRows();
    }, [store?.tableProgress]);
    useEffect(() => {
        document.onmousemove = handleOnMouseMove;
        document.onmouseup = handleOnMouseUp;
        return () => {
            document.onmousemove = null;
            document.onmouseup = null;
        };
    }, [isResizing, columnRefs]);
    const adjustWidthColumn = (index: number, width: number) => {
        const minWidth = Columns[index]?.minWidth ?? DEFAULT_MIN_WIDTH_CELL;
        const maxWidth = columnRefs[index]?.maxWidth ?? DEFAULT_MAX_WIDTH_CELL;
        const newWidth = width > maxWidth ? maxWidth : width < minWidth ? minWidth : width;

        columnRefs[index].current.parentElement.style.width = newWidth + "px";
    };

    const setCursorDocument = isResizing => {
        document.body.style.cursor = isResizing ? "ew-resize" : "auto";
    };
    const handleOnMouseMove = (e: MouseEvent) => {
        if (isResizing.current >= 0) {
            const newWidth =
                e.clientX - columnRefs[isResizing.current].current.parentElement?.getBoundingClientRect().left;
            adjustWidthColumn(isResizing.current, newWidth);
        }
    };

    const handleOnMouseUp = () => {
        isResizing.current = -1;
        setCursorDocument(false);
    };

    const onClickResizeColumn = (index: number) => {
        isResizing.current = index;
        document.onmousemove = handleOnMouseMove;
        document.onmouseup = handleOnMouseUp;
        setCursorDocument(true);
    };
    useEffect(() => {
        (async () => {
            const { sourceAdapters, targetAdapters } = await store?.getAvailableAdapters();
            setSourceAdapters(sourceAdapters);
            setTargetAdapters(targetAdapters);
            setColumns(getColumns(sourceAdapters, targetAdapters));
        })();
    }, []);

    if (!store?.tableProgress || _.isEmpty(store?.tableProgress || !appType)) {
        return true;
    }
    return (
        <>
            <SummaryHeaderContainer
                ref={headerRef}
                store={store}
                setRows={setRows}
                expandAllRows={expandAllRows}
                setExpandAllRows={setExpandAllRows}
                appType={appType}
                sourceAdapters={sourceAdapters}
                targetAdapters={targetAdapters}
            />
            <Box sx={styles.tableContainer}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {appType === "CDC" && <TableCell sx={{ width: "20px" }}></TableCell>}
                                {Columns.map((col, colIndex) => {
                                    return (
                                        <TableCell
                                            key={col.field}
                                            align="center"
                                            style={{
                                                minWidth: DEFAULT_MIN_WIDTH_CELL,
                                                width: col?.width ?? "auto",
                                                position: "relative",
                                                textAlign: "left"
                                            }}
                                        >
                                            <StriimTypography variant="body3" sx={{ color: "greyscale.700" }}>
                                                {col.headerName}
                                            </StriimTypography>

                                            <Box
                                                sx={styles.resizeDiv}
                                                onMouseDown={() => onClickResizeColumn(colIndex)}
                                                ref={columnRefs[colIndex]}
                                                className={"resizeLine"}
                                            />
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(row => {
                                return appType === "IL" ? (
                                    <RowIL key={row?.tableName} row={row} />
                                ) : (
                                    <RowCDC key={row?.tableName} expandAllRows={expandAllRows} row={row} />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
});
export default TableDetailsMui;
