import React, { Dispatch, SetStateAction, useState } from "react";
import {
    StriimIconWrapperBase,
    StriimButton,
    StriimTypography,
    StriimDropdown,
    StriimTooltip
} from "@striim/striim-ui";
import { Box, Grid, SvgIcon } from "@mui/material";

import { Download, Expand, Close, SpecialChevronDown, ArrowBack } from "src/generic/icon/customIcons";
import { styles, useStyles } from "./discovery-panel-source-header.styles";
import { icons } from "../../../../../generic/icon/flow-designer-icon";
import SearchableList from "./searchable-list";
import { Source, Entity } from "../../pii.types";
import { TableData } from "../entity-table/utils";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";
import { Sherlock } from "../../../../../generic/icon/customIcons";
import Printable from "../../../reports/printable";
import SherlockAppReport from "../../../reports/sherlock-report/sherlock-app-report";
import DownloadIcon from "../../../../../generic/icon/customIcons/Download.svg";
import { fetchSherlockAppReportData } from "../../../reports/reports.helper";

interface ReportButtonProps {
    sources: Source[];
    appName: string;
    isFullScreen?: boolean;
    timeRange: { from: string; to: string };
    jobName: string;
}

const PrintableReport: React.FC<ReportButtonProps> = ({
    sources,
    appName,
    timeRange,
    jobName,
    isFullScreen = false
}) => {
    const DownloadButton = isFullScreen ? (
        <StriimButton variant="secondary" startIcon={<SvgIcon component={Download} sx={styles.buttonIcon} />}>
            Report
        </StriimButton>
    ) : (
        <StriimButton variant="text" sx={styles.iconButton}>
            <SvgIcon component={DownloadIcon} sx={styles.downloadButton} />
        </StriimButton>
    );

    return (
        <Printable
            name={`${appName}_Sherlock_Report`}
            component={SherlockAppReport}
            appendToBody={true}
            fetchReportData={async () => {
                const data = await fetchSherlockAppReportData(sources, appName, jobName, timeRange);
                return data;
            }}
            buttonComponent={DownloadButton}
        />
    );
};

interface DiscoveryPanelSourceHeaderProps {
    source: Source;
    setActiveSource: Dispatch<SetStateAction<Source>>;
    sources?: Source[];
    addSentinel: Function;
    onDownload: Function;
    onFullScreen: (source: Source) => void;
    additionalProps?: any;
    lastUpdatedDateTime?: string;
    isFullScreenView?: boolean;
    onClose?: Function;
    showBackButton?: boolean;
    onBackClick?: Function;
    activeEntity?: Entity | null;
    setActiveEntity?: (value: Entity) => void;
    setTableData?: React.Dispatch<React.SetStateAction<TableData[]>>;
    isAddSentinelDisabled: boolean;
    appName: string;
    timeRange: any;
    jobName: string;
}

const DiscoveryPanelSourceHeader = ({
    source,
    setActiveSource,
    sources = [],
    addSentinel,
    onDownload,
    onFullScreen,
    additionalProps,
    isFullScreenView = false,
    lastUpdatedDateTime,
    onClose,
    showBackButton = false,
    onBackClick,
    activeEntity,
    setActiveEntity,
    setTableData,
    isAddSentinelDisabled,
    appName,
    timeRange,
    jobName
}: DiscoveryPanelSourceHeaderProps) => {
    const dropdownControlState = useState<boolean>(false);
    const classes = useStyles();
    const isEntitySet = !!activeEntity?.name;

    return (
        <Grid
            container
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            py={2}
            px={isFullScreenView ? 4 : 2}
            sx={styles.bottomBorder}
            {...additionalProps?.container}
        >
            {isFullScreenView ? (
                <>
                    <Grid display={"flex"} item gap={1.5} alignItems={"center"}>
                        {showBackButton && (
                            <StriimButton
                                variant="text"
                                startIcon={<SvgIcon component={ArrowBack} sx={styles.backIcon} />}
                                sx={styles.iconButton}
                                onClick={onBackClick}
                            />
                        )}
                        <div style={{ fontSize: 40 }}>
                            <StriimIconWrapperBase
                                Icon={icons[source.className]}
                                classNameContainer={classes.componentIcon}
                                type={"source"}
                            />
                        </div>
                        <Grid display="flex" item flexDirection={"column"} justifyContent={"space-around"}>
                            <Grid display={"flex"} item gap={0.5} alignItems={"center"}>
                                <SvgIcon component={Sherlock} sx={styles.aiIcon} />
                                <StriimTypography variant="caption4" sx={styles.aiText} fontFamily="Inter">
                                    Sherlock AI
                                </StriimTypography>
                                {isEntitySet && (
                                    <>
                                        <Box sx={styles.divider} mx={1} />
                                        <StriimTypography variant="h5" color="greyscale.900">
                                            Source: {source.name}
                                        </StriimTypography>
                                    </>
                                )}
                            </Grid>
                            <Grid display={"flex"} item gap={0.5} alignItems={"center"} maxHeight="28px">
                                {!isEntitySet ? (
                                    <>
                                        <StriimTypography variant="h3" color="primary.900">
                                            Sensitive Data in Source: {source.name}
                                        </StriimTypography>
                                        {sources.length ? (
                                            <StriimDropdown
                                                extendOnClick
                                                controlState={dropdownControlState}
                                                placement="bottom-end"
                                                content={
                                                    <SearchableList
                                                        type="Source"
                                                        list={sources}
                                                        onListItemClick={(source: Source) => {
                                                            setActiveSource(source);
                                                            setTableData(null);
                                                            dropdownControlState[1](false);
                                                        }}
                                                    />
                                                }
                                            >
                                                <StriimButton
                                                    variant="text"
                                                    startIcon={
                                                        <SvgIcon
                                                            component={SpecialChevronDown}
                                                            sx={styles.chevronIcon}
                                                        />
                                                    }
                                                    sx={styles.iconButton}
                                                    onClick={onFullScreen}
                                                />
                                            </StriimDropdown>
                                        ) : null}
                                    </>
                                ) : (
                                    <>
                                        <>
                                            <StriimTypography variant="h3" color="primary.900">
                                                Sensitive Data in {source.type}: {activeEntity.name}
                                            </StriimTypography>
                                            {source?.entities.length ? (
                                                <StriimDropdown
                                                    controlState={dropdownControlState}
                                                    extendOnClick
                                                    placement="bottom-end"
                                                    content={
                                                        <SearchableList
                                                            type={source.type}
                                                            list={source.entities}
                                                            onListItemClick={(item: Entity) => {
                                                                setActiveEntity(item);
                                                                setTableData(null);
                                                                dropdownControlState[1](false);
                                                            }}
                                                        />
                                                    }
                                                >
                                                    <StriimButton
                                                        variant="text"
                                                        startIcon={
                                                            <SvgIcon
                                                                component={SpecialChevronDown}
                                                                sx={styles.chevronIcon}
                                                            />
                                                        }
                                                        sx={styles.iconButton}
                                                    />
                                                </StriimDropdown>
                                            ) : null}
                                        </>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid display={"flex"} item alignItems={"center"} gap={2}>
                        {lastUpdatedDateTime && (
                            <StriimTypography variant="caption3" color="greyscale.700" letterSpacing="0.24px">
                                Updated: {lastUpdatedDateTime}
                            </StriimTypography>
                        )}
                        <Box sx={styles.divider} />
                        {!isEntitySet && (
                            <>
                                <StriimTooltip
                                    title="Add a Sentinel to the output of the source to detect and protect sensitive data in real-time. Sherlock will configure the Sentinel based on this discovery report."
                                    placement="top"
                                >
                                    <StriimButton
                                        variant="primary"
                                        startIcon={<SvgIcon component={AddIcon} />}
                                        onClick={addSentinel}
                                        disabled={isAddSentinelDisabled}
                                    >
                                        Sentinel
                                    </StriimButton>
                                </StriimTooltip>
                                <PrintableReport
                                    sources={sources}
                                    appName={appName}
                                    timeRange={timeRange}
                                    jobName={jobName}
                                    isFullScreen={true}
                                />
                            </>
                        )}

                        <StriimButton
                            variant="text"
                            startIcon={<SvgIcon component={Close} sx={styles.closeIcon} />}
                            sx={styles.iconButton}
                            onClick={onClose}
                        />
                    </Grid>
                </>
            ) : (
                <>
                    <Grid container display={"flex"} flexDirection={"column"}>
                        <Grid container width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                            <Grid item sx={{ fontSize: 24 }}>
                                <StriimIconWrapperBase
                                    Icon={icons[source.className]}
                                    type={"source"}
                                    className={classes.sourceIcon}
                                    classNameContainer={classes.sourceIconWrapper}
                                />
                            </Grid>
                            <Grid display={"flex"} item>
                                <StriimTooltip
                                    title="Add a Sentinel to the output of the source to detect and protect sensitive data in real-time. Sherlock will configure the Sentinel based on this discovery report."
                                    placement="top"
                                >
                                    <StriimButton
                                        variant="text"
                                        onClick={addSentinel}
                                        startIcon={<SvgIcon component={AddIcon} />}
                                        disabled={isAddSentinelDisabled}
                                    >
                                        Sentinel
                                    </StriimButton>
                                </StriimTooltip>
                                <PrintableReport
                                    sources={sources}
                                    appName={appName}
                                    timeRange={timeRange}
                                    jobName={jobName}
                                />
                                <StriimButton
                                    variant="text"
                                    startIcon={<SvgIcon component={Expand} sx={styles.icon} />}
                                    sx={styles.iconButton}
                                    onClick={() => {
                                        onFullScreen(source);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <StriimTypography variant="h4" color="primary.700" lineHeight="18px">
                            {source.name}
                        </StriimTypography>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default DiscoveryPanelSourceHeader;
