import React from "react";
import { StriimIconButton, StriimTheme } from "@striim/striim-ui-v2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WithTooltip from "../../../generic/tooltip/tooltip";

type BackButtonProps = {
    moveToAppGroups: Function;
};

const styles = {
    backButton: {
        py: 1,
        px: 2,
        width: "52px",
        height: "36px"
    }
};

const FlowBackButton: React.FunctionComponent<BackButtonProps> = ({ moveToAppGroups }) => {
    return (
        <StriimTheme>
            {WithTooltip(
                <StriimIconButton variant="secondary" sx={styles.backButton} onClick={() => moveToAppGroups()}>
                    <ArrowBackIcon />
                </StriimIconButton>,
                "Back to the automated pipeline"
            )}
        </StriimTheme>
    );
};

export default FlowBackButton;
