const PLAYERSTATUS = {
    START: "START",
    RUN: "RUN",
    RESUME: "RESUME",
    STOP: "STOP",
    UNDEPLOY: "UNDEPLOY",
    RESET: "RESET",
    START_ONLY: "START_ONLY"
};

export { PLAYERSTATUS };
