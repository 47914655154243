import _ from "underscore";
import NestedTypes from "backbone.nestedtypes";
import BaseControl from "./../base-control";
import UIControl from "app/components/common/editor/control/ui-control";
import FormBuilder from "app/components/common/editor/form-builder";
import { TransactionPolicyParser } from "./transaction-policy-parser";
import Consts from "app/components/common/editor/control/window-size/window-size-consts";

const template = `<div>
    <div class="transaction-policy-form"></div>
</div>`;

const Model = NestedTypes.Model.extend({
    defaults: {
        transactionPolicyEnabled: false,
        messageCount: null,
        time: null,
    },
});

const View = BaseControl.View.extend({
    template: _.template(template),
    className: "transaction-policy",
    events: {
        // override change event
    },
    regions: {
        formRegion: ".transaction-policy-form",
    },

    setViewValue: function () {
        const value = TransactionPolicyParser.parseFromString(this.getValue());
        if (value) {
            this.isEnabledControl.setValue(true);
            this.eventsCountControl.setValue(value.eventsCount);
            if (value.interval && value.intervalUnit) {
                this.intervalControl.setValue(value.interval + " " + value.intervalUnit);
            }
        } else {
            this.isEnabledControl.setValue(false);
        }
    },

    getViewValue: function () {
        return this._getTransactionPolicyValue();
    },

    setViewEnabled: function () {
        if (!this.$el.html()) {
            return;
        }

        const isTransactionPolicyEnabled = !!this.isEnabledControl.getValue();

        const enabled = this.getEnabled();
        this.eventsCountControl.setEnabled(enabled && isTransactionPolicyEnabled);
        this.intervalControl.setEnabled(enabled && isTransactionPolicyEnabled);
    },

    initialize: function () {
        BaseControl.View.prototype.initialize.call(this);

        const formModel = new Model();
        this.form = new FormBuilder({
            model: formModel,
            ReadOnly: this.options.enabled,
            autoSave: true,
        });

        this.isEnabledControl = UIControl.Toggle.create();
        this.eventsCountControl = UIControl.NumberField.create();
        this.intervalControl = UIControl.ShortTimeFormat.create();

        this.form.addControl(this.isEnabledControl, "transactionPolicyEnabled");
        this.form.addControl(this.eventsCountControl, "messageCount");
        this.form.addControl(this.intervalControl, Consts.Fields.Time);
    },

    onRender: function () {
        BaseControl.View.prototype.onRender.call(this);

        this.formRegion.show(this.form.create());

        this.isEnabledControl.on(
            UIControl.EVENT.VALUE_CHANGED,
            function (val) {
                this._setTextFieldsEnabled();
                this.setValueFromView();
            }.bind(this)
        );

        this.eventsCountControl.on(
            UIControl.EVENT.VALUE_CHANGED,
            function (val) {
                this.setValueFromView();
            }.bind(this)
        );

        this.intervalControl.on(
            UIControl.EVENT.VALUE_CHANGED,
            function (val) {
                this.setValueFromView();
            }.bind(this)
        );
    },

    _getTransactionPolicyValue: function () {
        const isEnabled = this.isEnabledControl.getValue();
        if (!isEnabled) {
            return false;
        }
        const eventsCount = this.eventsCountControl.getValue();
        const interval = this.intervalControl.getValue();

        const result = TransactionPolicyParser.convertToString(eventsCount, interval);
        return result;
    },

    _setTextFieldsEnabled: function () {
        if (!this.$el.html()) {
            return;
        }
        const enabled = this.getEnabled();
        if (!enabled) {
            return;
        }
        const isTransactionPolicyEnabled = this.isEnabledControl.getValue();
        this.eventsCountControl.setEnabled(isTransactionPolicyEnabled);
        this.intervalControl.setEnabled(isTransactionPolicyEnabled);
    },
});

export default _.extend({}, BaseControl, {
    View: View,
});
