import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing }) => ({
    dashboardsTitleWrapper: {
        alignItems: "center",
        justifyContent: "space-between"
    },
    dashboardsSortContainer: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        marginTop: spacing(3)
    },
    dashboardsUserSelect: {
        userSelect: "none"
    },
    dashboardsRedirectButton: {
        width: "auto"
    },
    dashboardLoaderContainer: {
        minHeight: 10
    }
}));
