import api from "core/api/api";
import $ from "jquery";
import "select2";
import _ from "underscore";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import BaseControl from "./../base-control";
import helpLinkProvider from "app/components/common/helpable/helpLinkProvider";
import template from "./select.html";
import selectItemTemplate from "./select-item.html";
import itemTemplate from "./item.html";
import newItemTemplate from "./new-item.html";

function transformType(type) {
    if (type) {
        var t = type.toLowerCase();
        return t === "wactionstore" ? "store" : t;
    } else {
        return undefined;
    }
}
function arraysEqual(a1, a2) {
    return JSON.stringify(a1) === JSON.stringify(a2);
}
var view = BaseControl.View.extend({
    template: _.template(template),
    events: {
        change: "setValueFromView"
    },
    ui: _.extend(
        {
            additionalAction: ".additional-action",
            additionalActionIcon: ".additional-action-icon",
            errorMessage: ".error-message"
        },
        BaseControl.View.prototype.ui
    ),
    triggers: _.extend(
        {
            "click @ui.additionalAction": "additional-action"
        },
        BaseControl.View.prototype.triggers
    ),
    className: "select2-control",
    customSelect2Options: {},
    initialize: function() {
        var _this = this;
        var options = this.options || {};
        var itemTemplateFunction = _.template(itemTemplate);
        var selectItemTemplateFunction = _.template(selectItemTemplate);
        var newItemTemplateFunction = _.template(newItemTemplate);
        var addNewText = typeof this.addNewText !== "undefined" ? this.addNewText : "New: ";
        var allowClear = typeof this.allowClear !== "undefined" ? this.allowClear : true;
        var hideSearch = typeof this.hideSearch !== "undefined" ? this.hideSearch : false;
        var doNotFilterCharacters =
            typeof this.doNotFilterCharacters !== "undefined" ? this.doNotFilterCharacters : false;
        var customCssClass = this.customCssClass || options.customCssClass || "";
        this.hasCustomPlaceholder = typeof this.placeholder !== "undefined";
        this.select2options = {
            matcher: this.customFilter,
            formatResult: function(item) {
                if (!item.id) {
                    return item.text;
                }
                var text = item.text;
                var type = item.type;
                var description = item.description;
                if (item.custom) {
                    let newItem = {
                        addNewText: addNewText,
                        id: item.id,
                        name: MetaObjectConverter.getName(item.text)
                    };
                    return newItemTemplateFunction({ newItem: newItem });
                }
                let selectedItem = {
                    id: item.id,
                    type: transformType(type),
                    subType: transformType(item.subType || item.type),
                    name: text,
                    description: description
                };
                return selectItemTemplateFunction({ selectedItem: selectedItem });
            },
            formatSelection: function(item) {
                var name = MetaObjectConverter.getName(item.text);
                if (_this.multiple) {
                    return name;
                }
                if (item.custom) {
                    _this.model.isNewElement = true;
                    let newItem = {
                        addNewText: addNewText,
                        id: item.id,
                        name: name
                    };
                    return newItemTemplateFunction({ newItem: newItem });
                } else {
                    _this.model.isNewElement = false;
                }
                if (!item.id) {
                    return name;
                }
                var type = item.type;
                var selectedItem = {
                    id: item.id,
                    type: transformType(type),
                    subType: transformType(item.subType || item.type),
                    name: item.selectedText || name
                };
                if (_this.showSelectionDescription) {
                    selectedItem.description = item.description;
                }
                return itemTemplateFunction({ selectedItem: selectedItem });
            },
            dropdownCssClass: "custom-results " + customCssClass,
            placeholder: this.hasCustomPlaceholder ? this.placeholder : "Select...",
            containerCssClass: "select2-control-container",
            allowClear: allowClear,
            minimumResultsForSearch: hideSearch ? -1 : 0,
            headerPlaceholder: this.headerPlaceholder,
            initSelection: function(element, callback) {
                var value = _this.getValue();
                if (_this.customViewOptions && _this.customViewOptions.defaultHelpLinkKey && value) {
                    _this.initSelectionHelpLink = helpLinkProvider.getHelpLink(value);
                }
                if (_this.multiple) {
                    callback(
                        _.map(value, function(item) {
                            return {
                                id: item,
                                text: item
                            };
                        })
                    );
                } else {
                    if (_this.valueConverter) {
                        value = _this.valueConverter.in(value);
                    }
                    if (!this) {
                        callback({
                            id: value,
                            text: value
                        });
                        return;
                    }
                    var list = _.flatten(
                        _.map(this.data, function(item) {
                            if (item.children) {
                                return item.children;
                            } else {
                                return item;
                            }
                        })
                    );
                    var item = _.findWhere(list, {
                        id: value
                    });
                    if (item) {
                        callback(item);
                        return;
                    }
                    item = _.findWhere(this.data, {
                        id: value
                    });
                    if (item) {
                        callback(item);
                    } else {
                        callback({
                            id: value,
                            text: value
                        });
                    }
                }
            },
            escapeMarkup: function(m) {
                return m;
            },
            formatNoMatches: function() {
                if (options && options.noMatchesMessage) {
                    return options.noMatchesMessage;
                }
                return "No matches found";
            }
        };
        if (typeof this.dropdownAutoWidth !== "undefined") {
            this.select2options.dropdownAutoWidth = this.dropdownAutoWidth;
        }
        if (this.addNew === true) {
            this.select2options.hideValidationTooltip = function() {
                var errorDiv = _this.$(".error");
                errorDiv.remove();
            };
            this.select2options.createSearchChoice = function(item) {
                if (doNotFilterCharacters) {
                    // when allowing the user to insert complex strings like fully qualified types names
                    var parts = item.split(".");
                    var shortTypeName = _.last(parts);
                    return {
                        id: item,
                        text: shortTypeName,
                        custom: true
                    };
                } else {
                    _this.select2options.hideValidationTooltip();
                    const getValidationMsg = _this.customValidateFn || _this.defaultValidationFn;
                    const validationMsg = getValidationMsg(item);
                    if (validationMsg) {
                        _this.showValidationTooltip(validationMsg);
                        return false;
                    }
                }
                if (_this.nameOnly) {
                    return {
                        id: item,
                        text: item,
                        custom: true
                    };
                }
                var type = _this.model.type;
                var namespace = api.getCurrentNamespace();
                if (type === "NAMESPACE") {
                    //item = item;
                } else if (namespace && type) {
                    item = MetaObjectConverter.getId(namespace, type, item);
                }
                return {
                    id: item,
                    text: item,
                    custom: true
                };
            };
        }
        if (this.width) {
            this.select2options.width = this.width;
        }
        this.select2options = _.extend(this.select2options, this.customSelect2Options);
    },

    defaultValidationFn: function(value) {
        const nonAlphanumericOrUnderscore = /\W/g;
        if (nonAlphanumericOrUnderscore.test(value)) {
            return "Please enter only alphanumeric or underscore characters.";
        }
        return "";
    },
    showAdditionalAction: function(visible) {
        if (this.ui.additionalAction.toggleClass) {
            this.ui.additionalAction.toggleClass("hidden-element", !visible);
        }
    },
    updateHelpLink: function(link) {
        if (link) {
            this.$(".help-link a").attr("href", link);
        }
        this.$(".help-link").toggleClass("hidden-element", !link);
    },
    getViewValue: function() {
        if (this.$select2) {
            var value = this.$select2.select2("val");
            if (this.valueConverter) {
                return this.valueConverter.out(value);
            } else {
                return value;
            }
        }
    },
    setViewEnabled: function() {
        if (this.$select2) {
            this.$select2.prop("disabled", !this.getEnabled());
            this.$select2.select2(this.getEnabled() ? "enable" : "disable");
        }
    },
    setViewValue: function() {
        if (this.$select2) {
            if (this.valueConverter) {
                this.$select2.select2("val", this.valueConverter.in(this.getValue()));
            } else {
                this.$select2.select2("val", this.getValue());
            }
        }
    },
    setDataSource: function(dataSource) {
        var def = $.Deferred();
        var _this = this;
        $.when(dataSource).then(function(data) {
            if (!arraysEqual(_this.model.dataSource, data)) {
                _this.onDestroy();
                _this.refreshSelect2(data);
            }
            def.resolve();
        });
        return def.promise();
    },
    refreshSelect2: function(data) {
        if (!this.$select2) {
            return;
        }
        var _this = this;
        _this.model.dataSource = data;
        var settings = _.extend(_this.select2options, {
            data: data,
            multiple: !!_this.multiple
        });
        _this.$select2.select2(settings);
        _this.$select2.on("select2-open", function() {
            _this.showSearchPlaceholder();
        });
        _this.$select2.on("select2-close", function() {
            $(".select2-input").attr("placeholder", null);
        });
    },
    showSearchPlaceholder: function() {
        if (this.getValue() && this.hasCustomPlaceholder) {
            $(".select2-input").attr("placeholder", this.placeholder);
        } else {
            if (this.addNew && !this.hasCustomPlaceholder) {
                $(".select2-input").attr("placeholder", "Type to add new or filter...");
            } else {
                $(".select2-input").attr("placeholder", "");
            }
        }
    },
    onRender: function() {
        var _this = this;
        this.$select2 = this.$(".select2-control-container");
        this.$select2.empty();
        $.when(this.model.dataSource).then(function(data) {
            _this.refreshSelect2(data);
            _this.setViewValue();
        });
        this._initializeHelpLink();
        if (this.additionalAction) {
            this.ui.additionalAction.removeClass("hidden-element");
            this.ui.additionalActionIcon.html(this.additionalAction);
        }
        BaseControl.View.prototype.onRender.apply(this, arguments);
    },
    onDestroy: function() {
        if (!this.$select2) {
            return;
        }
        this.$select2.off("select2-open");
        this.$select2.off("select2-close");
        this.$select2.select2("destroy");
    },
    _initializeHelpLink: function() {
        if (this.customViewOptions && this.customViewOptions.defaultHelpLinkKey) {
            var _this = this;
            let value = _this.getValue();
            let selectionHelpLink = value ? helpLinkProvider.getHelpLink(value) : null;

            if (selectionHelpLink) {
                this.updateHelpLink(selectionHelpLink);
            } else {
                var defaultLink = helpLinkProvider.getHelpLinkByKey(this.customViewOptions.defaultHelpLinkKey);
                this.updateHelpLink(defaultLink);
            }
            this.listenTo(this.model, "change:value", function(model) {
                var _link;
                if (model.value) {
                    _link = helpLinkProvider.getHelpLink(model.value);
                }
                if (!model.value || !_link) {
                    _link = helpLinkProvider.getHelpLinkByKey(_this.customViewOptions.defaultHelpLinkKey);
                }
                this.updateHelpLink(_link);
            });
        }
    },

    showValidationTooltip: function(message) {
        var errorDiv = this.$(".error");
        if (!errorDiv.length) {
            errorDiv = $('<div class="message error show" data-test-id="validation-error"></div>');
            this.$el.append(errorDiv);
        }
        errorDiv.html(message);
    },

    showError(message) {
        if (!message) {
            this.ui.errorMessage.html("");
            this.ui.errorMessage.toggleClass("hidden-element", true);
            return;
        }
        this.ui.errorMessage.html(message);
        this.ui.errorMessage.toggleClass("hidden-element", false);
    },
    clearError() {
        this.showError(null);
    }
});
/**
 * Returns a constructor function of the Select Control
 * @param {Array} DataSource
 * @param {Object} options
 * @return {Number} constructor function of the Select Control
 */
export default function(dataSource, options) {
    var Model = BaseControl.Model.extend({
        defaults: {
            dataSource: dataSource,
            value: options && options.multiple ? Array : String,
            type: options && options.type ? options.type : null,
            isNewElement: Boolean
        },
        properties: {
            selectedModel: function() {
                var _this = this;
                var result = null;
                _.each(this.dataSource, function(item) {
                    if (item.id === _this.value) {
                        result = item;
                    }
                    if (item.children) {
                        _.each(item.children, function(option) {
                            if (option.id === _this.value) {
                                result = option;
                            }
                        });
                    }
                });
                if (result) {
                    return result.refModel;
                }
            }
        }
    });
    var View = view.extend(options);
    return _.extend({}, BaseControl, {
        Model: Model,
        View: View
    });
}
