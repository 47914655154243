export default {
    card: {
        height: "100%",
        margin: 0,
        borderRadius: 2,
        borderTopLeftRadius: 0,
        boxShadow: "none",
        transition: "none"
    },
    tab: {
        '& svg': {
            fill: 'none',
            width: "16px",
            height: "16px"
        }
    }
};
