import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var module = {};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "PROPERTYVARIABLE",
        value: String
    }
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model
});

export default module;
