import securityCheck from "core/services/securityService/adapter-security-check";

export const getOptionsByName = (selectedSearchOptions, name) =>
    selectedSearchOptions.map(({ label, value }) => (label.includes(name) ? value : null)).filter(Boolean);

export const findNotExactTags = (searchOption, element) => [element && element.tag].flat().includes(searchOption);

export const filterTemplates = (selectedSearchOptions, templatesList) => {
    const resultSet = templatesList.filter(template => {
        let qualified = securityCheck.checkIfEnabledSync(template.uses);
        selectedSearchOptions.forEach(option => {
            qualified = qualified && template.tags.includes(typeof option === "object" ? option.value : option);
        });
        if (qualified) {
            return template;
        }
    });
    return resultSet;
};

export default filterTemplates;
