import App from "app";
import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import dialog from "app/components/common/dialogs/dialogWindow";
import { formatBytes } from "src/modules/apps/pages/manage-striim/utils/number.js";

var FilePreview = {};
App.addInitializer(function() {
    App.reqres.setHandler("preview:initial", FilePreview.previewInitial);
    App.reqres.setHandler("preview:samples", FilePreview.samples);
    App.reqres.setHandler("preview:update", FilePreview.update);
    App.reqres.setHandler("preview:save", FilePreview.save);
});

FilePreview.PreviewModel = Backbone.Model.extend({
    url: function() {
        var url = "";
        var base = "/preview";
        url += base;
        var path = this.get("path");
        if (path) {
            url += "?path=";
            url += path;
            var readerType = this.get("readerType");
            if (readerType) {
                url += "&readertype=" + readerType;
            }
            var docType = this.get("doctype");
            if (docType) {
                url += "&" + "doctype=" + docType;
            }
            var parserProperties = this.get("parserProperties");
            if (parserProperties) {
                var parserPropertiesString;
                if (docType === "DSV") {
                    parserPropertiesString = this.generateDSVParserPropertiesString();
                } else if (docType === "XML") {
                    parserPropertiesString = this.generateXMLParserPropertiesString();
                } else if (docType === "FreeFormText") {
                    parserPropertiesString = this.generateFFTParserPropertiesString();
                }
                url += "&" + parserPropertiesString;
            }
        }

        return url;
    },
    initialize: function(options) {
        if (options.fileType) {
            this.set("fileType", options.fileType);
        }
        if (options.path) {
            this.set("path", options.path);
            this.set("id", options.path);
            this.id = options.path;
        }
    },
    generateFFTParserPropertiesString: function() {
        var retstr = "";
        var props = this.get("parserProperties");
        if (props) {
            retstr = "parserProperties=";
            var begin = props["recordbegin"];
            if (begin) {
                retstr += "recordbegin:" + encodeURIComponent(this.removeSlashes(begin));
            }
            var regex = props["regex"];
            if (regex) {
                retstr += "|" + "regex:" + encodeURIComponent(this.removeSlashes(regex));
                //                        retstr+='|'+'regex:'+encodeURIComponent(regex);
            }
            var ts = props["timestamp"];
            if (ts) {
                retstr += "|" + "timestamp:" + encodeURIComponent(ts);
            }
            var sep = props["separator"];
            if (sep) {
                retstr += "|" + "separator:" + encodeURIComponent(sep);
            }
            if (!regex || !begin || !sep) {
                retstr = "";
            }
        }
        return retstr;
    },
    /**
     * add slashes to all the strings so they are properly escaped.
     * @param str
     * @returns {string}
     */
    removeSlashes: function(str) {
        if (typeof str !== "string") {
            return;
        }
        str = str
            .replace(/\\n/g, "\n")
            .replace(/\\r/g, "\r")
            .replace(/\\t/g, "\t");
        return str;
    },
    addSlashes: function(str) {
        if (typeof str !== "string") {
            return;
        }
        str = str
            .replace(/\n/g, "\\n")
            .replace(/\r/g, "\\r")
            .replace(/\t/g, "\\t");

        return str;
    },
    generateXMLParserPropertiesString: function() {
        //TODO add special parsing/rendering for XML
    },
    generateDSVParserPropertiesString: function() {
        var handler;
        var retstr = "parserProperties=";
        var rowdelim;
        var coldelim;
        var linenum;
        var parserProps = this.get("parserProperties");
        if (parserProps) {
            if (parserProps["rowdelimiter"]) {
                rowdelim = "rowdelimiter:" + encodeURIComponent(parserProps["rowdelimiter"]);
            } else {
                if (typeof this.get("analysis")[0] !== "undefined") {
                    var analysis = this.get("analysis")[0]["rowdelimiter"];

                    if (analysis) {
                        rowdelim = "rowdelimiter:" + encodeURIComponent(this.removeSlashes(analysis));
                    }
                } else {
                    rowdelim = "rowdelimiter:" + encodeURIComponent("\n");
                }
            }
            retstr += rowdelim;

            if (parserProps["columndelimiter"]) {
                coldelim = "columndelimiter:" + encodeURIComponent(parserProps["columndelimiter"]);
                retstr += "|" + coldelim;
            }
            if (parserProps["LineNumber"]) {
                linenum = "linenumber:" + encodeURIComponent(parserProps["LineNumber"]);
                retstr += "|" + linenum;
            }
            if (parserProps["quoteset"]) {
                var quoteset = "quoteset:" + encodeURIComponent(parserProps["quoteset"]);
                retstr += "|" + quoteset;
            }

            if (this.get("doctype") === "DSV") {
                handler = "handler:DSVParser";
            } else if (this.get("doctype") === "XML") {
                handler = "handler:XMLParser";
            }
            retstr += "|" + handler;
            return retstr;
        } else {
            alert("Please enter valid configuration");
            return;
        }
    }
});

FilePreview.samples = function() {
    var def = $.Deferred();
    var options = {
        path: "samples"
    };
    var model = new FilePreview.PreviewModel(options);
    model.fetch().then(function(data, textstatus, jqXHR) {
        console.log(data);
        def.resolve(model, data);
    });
    return def;
};
FilePreview.previewInitial = function(initModel) {
    var def = $.Deferred();
    var readerType = initModel.get("fileType");
    var options = {
        readerType: initModel.get("fileType"),
        path: initModel.get("previewPath")
    };
    if (readerType === "FileReader") {
        options["directory"] = initModel.get("");
    } else if (readerType === "HDFSReader") {
        options["url"] = initModel.get("url");
    }
    if (initModel.get("fileinfo")) {
        options["fileinfo"] = initModel.get("fileinfo");
    }
    if (initModel.get("doctype")) {
        options["doctype"] = initModel.get("doctype");
    }

    var model = new FilePreview.PreviewModel(options);
    model
        .fetch()
        .then(function(data, textStatus, jqXHR) {
            model.set("events", data["events"], {
                silent: true
            });
            model.set("analysis", data["analysis"], {
                silent: true
            });
            model.set("header", false, {
                silent: true
            });

            var docType = data["doctype"];
            if (docType) {
                model.set("docType", docType, {
                    silent: true
                });
            }
            model.set("parserType", docType, {
                silent: true
            });
            var propertyTemplate;
            if (docType === "XML") {
                propertyTemplate = ["rootnode", "columnlist"];
            } else if (docType === "DSV") {
                propertyTemplate = ["columndelimiter", "rowdelimiter", "quoteset"];

                const fileName = data.fileinfo.Name.toLowerCase();
                switch (fileName.split(".").pop()) {
                    case "csv":
                        model.set("fileIcon", "CSV");
                        break;
                    case "xls":
                        model.set("fileIcon", "XLS");
                        break;
                    case "log":
                        model.set("fileIcon", "LOG");
                        break;
                    case "tql":
                        model.set("fileIcon", "TQL");
                        break;
                    case "jar":
                        model.set("fileIcon", "JAR");
                        break;
                    case "srm":
                        model.set("fileIcon", "SRM");
                        break;
                    default:
                        model.set("fileIcon", "UNKNOWN");
                        break;
                }
            }
            if (docType === "JSON" || docType === "XML") {
                var message =
                    "Striim does not support web-based preview for JSON or XML Files." +
                    "\n" +
                    "Please refer to the documentation for examples on using the JSONParser and XMLParser in TQL";
                dialog.alert(message).always(function() {
                    location = window.location.origin + "/#/manage-striim/files";
                    location.reload();
                    model.set("propertyTemplate", propertyTemplate, {
                        silent: true
                    });
                    def.resolve(model, data, textStatus, jqXHR);
                });
            } else {
                model.set("propertyTemplate", propertyTemplate, {
                    silent: true
                });
                def.resolve(model, data, textStatus, jqXHR);
            }
        })
        .fail(function(obj) {
            if (obj.status !== 200) {
                //alert(obj.responseText + '\n' + "Please try again");
                var message;
                if (obj.responseText) {
                    message = obj.responseText;
                } else {
                    message =
                        obj.statusText +
                        "\n" +
                        "Striim could not preview this file. We currently do not support file previews for:" +
                        "\n" +
                        "MS Word Files" +
                        "\n" +
                        "MS Excel Files" +
                        "\n" +
                        "Files with non-Unix/Linux line endings" +
                        "\n" +
                        "Any PDFS, Images, or other non-text files" +
                        "\n" +
                        "If you are attempting to preview data from an excel spreadsheet, please convert it to a CSV or TSV first.";
                }

                dialog.alert(message).always(function() {
                    App.navigate("#/manage-striim/files", {
                        trigger: true
                    });
                });
            }
        });
    return def;
};
FilePreview.save = function(model) {
    /* This is the object we will send in the post request */
    var objectForServer = {};
    objectForServer["path"] = model.get("path");
    objectForServer["columns"] = model.get("columns");
    objectForServer["parserproperties"] = model.get("parserProperties");
    objectForServer["doctype"] = model.get("doctype");
    objectForServer["readertype"] = model.get("readerType");
    if (model.get("sourcename")) {
        objectForServer["sourcename"] = model.get("sourcename");
    }
    if (model.get("appname")) {
        objectForServer["appname"] = model.get("appname");
    }
    if (model.get("cacheproperties")) {
        var cacheProps = model.get("cacheproperties");
        objectForServer["cachename"] = model.get("cachename");
        objectForServer["cacheproperties"] = cacheProps;
        objectForServer["loaddata"] = model.get("loaddata");

        if (cacheProps["refreshintervalunit"]) {
            var unit = cacheProps["refreshintervalunit"];
            var interval = cacheProps["refreshinterval"];
            switch (unit) {
                case "microseconds":
                    objectForServer["cacheproperties"]["refreshinterval"] = interval;
                    break;
                case "milliseconds":
                    objectForServer["cacheproperties"]["refreshinterval"] = interval * 1000;
                    break;
                case "minutes":
                    objectForServer["cacheproperties"]["refreshinterval"] = interval * 60000000;
                    break;
                case "hours":
                    objectForServer["cacheproperties"]["refreshinterval"] = interval * 3600000000;
                    break;
                case "days":
                    objectForServer["cacheproperties"]["refreshinterval"] = interval * 86400000000;
                    break;
            }
        }
        delete objectForServer["cacheproperties"]["refreshintervalunit"];
    }
    if (model.get("header")) {
        objectForServer["parserproperties"]["header"] = true;
    }
    var ns = model.get("namespace");
    if (ns) {
        objectForServer["namespace"] = ns;
    }
    var hadoopUrl = model.get("url");

    if (hadoopUrl) {
        var path = model.get("path");

        objectForServer["path"] = hadoopUrl + path;
    }
    objectForServer["token"] = api.token;
    var wildcard = model.get("wildcard");
    if (wildcard) {
        objectForServer["wildcard"] = wildcard;
        var tmpPath = objectForServer["path"];
        var options = tmpPath.match("^(.+)/([^/]+)$");
        var w = options[1] + "/" + wildcard;
        objectForServer["path"] = w;
    }
    console.log("Save object ", objectForServer);
    var loc = window.location.origin + "/preview";
    var str = JSON.stringify(objectForServer);
    var obj = {
        model: str
    };
    var suc = function(data, textStatus, jq) {
        App.vent.trigger("savePreviewSuccess");
    }.bind(this);
    $.post(loc, obj, suc, "text").error(function(msg) {
        console.log("reinstating click");
        App.vent.trigger("preview:enableSaveClick");
        alert(msg.responseText);
    });
};
FilePreview.update = function(model) {
    var def = $.Deferred();
    model
        .fetch()
        .then(function(data, textStatus, jqXHR) {
            if (data["recommendation"]) {
                var curRec = data["recommendation"];
                for (var rec in curRec) {
                    var tmp = curRec[rec];
                    for (var key in tmp) {
                        if (tmp.hasOwnProperty(key)) {
                            tmp[key] = model.addSlashes(tmp[key]);
                        }
                    }
                }
                model.set("analysis", data["recommendation"], {
                    silent: true
                });
            }
            if (data["events"]) {
                model.set("events", data["events"], {
                    silent: true
                });
                var events = model.get("events");
                for (var event in events) {
                    var curEvent = events[event];

                    for (var row in curEvent["data"]) {
                        /* Escape tags which sometimes appear in XML so they do not get rendered improperly in
                         * html tables*/
                        curEvent["data"][row] = _.escape(curEvent["data"][row]);
                    }
                    if (curEvent["meta"]["OriginTimestamp"]) {
                        model.set("hasTimestamp", true, {
                            silent: true
                        });
                        var rawTime = curEvent["meta"]["OriginTimestamp"];
                        var d = new Date(rawTime);
                        curEvent["data"].splice(0, 0, d.toDateString());
                    }
                }
                var columns = [];
                var eventSize = model.get("events").length > 0 ? model.get("events")[0]["data"].length : 0;
                if (model.get("header")) {
                    var colNames = [];
                    var firstRow = model.get("events")[0]["data"];
                    for (var r in firstRow) {
                        var trimmed = firstRow[r].replace(/ /g, "");
                        colNames.push(trimmed);
                    }
                }
                /* Set column metadata for preview page */
                for (var i = 0; i < eventSize; i++) {
                    var obj = {};
                    if (model.get("hasTimestamp")) {
                        if (i === 0) {
                            obj["name"] = "eventTime";
                        } else {
                            obj["name"] = "ColumnName" + i;
                        }
                        obj["type"] = "String";
                        obj["discard"] = "false";
                    } else {
                        /**
                         * Set the column name. Use first row for names if user specifies it has a header
                         */
                        if (model.get("header")) {
                            obj["name"] = colNames[i];
                        } else {
                            obj["name"] = "ColumnName" + i;
                        }
                        obj["type"] = "String";
                        obj["discard"] = "false";
                    }
                    columns.push(obj);
                }

                var curColumns = model.get("columns");
                if (curColumns) {
                    if (curColumns.length !== columns.length || model.get("header")) {
                        model.set("columns", columns);
                    }
                } else {
                    model.set("columns", columns);
                }
            }
            if (data["fileinfo"]) {
                var size = data["fileinfo"]["Size"];
                if (size) {
                    data["fileinfo"]["Size"] = formatBytes(size);
                }
                model.set("fileinfo", data["fileinfo"]);
            }
            def.resolve(model, data, textStatus, jqXHR);
            //example url
            // /preview?path=/Users/johnkutay/Documents/workspace/WebAction/git/Product/Platform/UploadedFiles/devices5.csv&readerType=FileReader&docType=DSV"
        })
        .fail(function(obj) {
            if (obj.status !== 200) {
                alert(obj.statusText + "\n" + "Please try again");
                var orig = window.location.origin;
                location = orig + "/#/preview";
                location.reload();
            }
        });
    return def;
};

export default FilePreview;
