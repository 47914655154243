import React from "react";
import { StriimTypography, StriimList, StriimListItem } from "@striim/striim-ui";
import { Box, ListItemText } from "@mui/material";
import { observer } from "mobx-react";
import NonTransientIndicator from "../../apps/pages/app-list/components/status-indicator/non-transient-indicator";
import { Link } from "react-router-dom";
import styles from "./latest-items.styles";

const LatestApps = observer(({ headerStore, navbarStateManager }) => {
    const route = "/applications";

    return (
        <StriimList>
            {headerStore.latestApps.length > 0 && (
                <Box sx={styles.captionPadding}>
                    <StriimTypography variant="caption4" sx={{ ...styles.caption4Color }}>
                        Recently Opened
                    </StriimTypography>
                </Box>
            )}
            {headerStore.latestApps.length > 0 ? (
                headerStore.latestApps.slice(0, 5).map(latestApp => {
                    const { name, nsName, id, flowStatus } = latestApp;
                    const subRoute = `/flow/${nsName}.${name}`;
                    return (
                        <Link
                            key={id}
                            to={subRoute}
                            data-testid={`nav--app-${id}`}
                            id={"nav--app-" + id}
                            onClick={() => {
                                navbarStateManager.updateSelection({ route, subRoute });
                                navbarStateManager.setActiveMenu(current => ({
                                    ...current,
                                    open: false
                                }));
                            }}
                        >
                            <StriimListItem
                                sx={styles.recentItem}
                                selected={subRoute === navbarStateManager.currentSelection?.subRoute}
                                divider={false}
                            >
                                <div>
                                    <NonTransientIndicator flowStatus={flowStatus} />
                                </div>
                                <ListItemText
                                    sx={styles.latestItemDtls}
                                    primary={
                                        <StriimTypography
                                            variant="body4"
                                            title={name}
                                            sx={{ ...styles.appNameColor, ...styles.overflowEllipsis }}
                                        >
                                            {name}
                                        </StriimTypography>
                                    }
                                    secondary={
                                        <StriimTypography
                                            title={nsName}
                                            variant="caption4"
                                            sx={{
                                                ...styles.namespace,
                                                ...styles.overflowEllipsis
                                            }}
                                        >
                                            {nsName}
                                        </StriimTypography>
                                    }
                                />
                            </StriimListItem>
                        </Link>
                    );
                })
            ) : (
                <Box sx={styles.captionPadding}>
                    <StriimTypography variant="caption4" sx={styles.caption4Color}>
                        No Recent Apps
                    </StriimTypography>
                </Box>
            )}
        </StriimList>
    );
});
export default LatestApps;
