import ChameleonLibrary from "./tracker-subscribers/chameleon";

/**
 *
 * The library can be used to add in-application guided tours in our webaction UI. 
 *
 *
 * @param subscribers array
 * TO USE:
 *
 * 1. identify:  identifies your users and determines if a specific user should qualify for the content you have lined up in Chameleon
 */
var module = {};

module.libraries = {
    CHAMELEON: ChameleonLibrary
};

module.ChameleonTracker = function (subscribers) {
    subscribers = subscribers || [];
    this.addSubscribers(subscribers);
};

module.ChameleonTracker.prototype = {
    _subscribers: [],
    /**
     *  The identify method loops through all the subscribers and publishes the identify method on them. Each subsciber should
     *  implement subscribers/interface.js and should implement all the methods in that.
     * @param event_name
     * @param params
     */
    identify: function (params) {
        for (var i = 0; i < this._subscribers.length; i++) {
            this._subscribers[i].identify(params);
        }
    },
    /***
     * The method to add the subscribers.
     *@param subscribers array
     */
    addSubscribers: function (subscribers) {
        for (var i = 0; i < subscribers.length; i++) {
            if (this._subscribers.indexOf(subscribers[i]) === -1) {
                this._subscribers.push(subscribers[i]);
                subscribers[i].initialize();
            }
        }
    },
};

export default {
    libraries: module.libraries,
    getInstance: function () {
        module.instance = module.instance || new module.ChameleonTracker();
        return module.instance;
    },
};