import Backbone from "backbone";
import Marionette from "marionette";
import _ from "underscore";
import NestedTypes from "backbone.nestedtypes";
import App from "app";
import template from "./templates/appException.html";
import CRUDExceptionTemplate from "./templates/crud-exception.html";
import stringUtils from "../../../../../core/utils/string-utils";
import { HTMLregex, parseHtml } from "./exception-helper";

const AppExceptions = {};

AppExceptions.Model = NestedTypes.Model.extend({
    defaults: {
        nsName: String,
        isComponent: Boolean,
        componentName: String,
        componentType: String,
        exception: String,
        message: String,
        relatedEvents: String,
        showComponentLink: Boolean
    }
});

AppExceptions.CRUDExceptionView = Marionette.ItemView.extend({
    template: _.template(CRUDExceptionTemplate),
    className: "crud-exception-view",
    ui: {
        details: ".details"
    },
    initialize: function(options) {
        this.model = new Backbone.Model();
        this.model.set("message", options.message || "");
    },
    onRender: function() {
        this.ui.details.show();
    }
});

AppExceptions.Collection = NestedTypes.Collection.extend({
    model: AppExceptions.Model
});

export const formatMessage = function(message) {
    //from https://stackoverflow.com/questions/49634850/convert-plain-text-links-to-clickable-links
    function linkify(inputText) {
        let replacedText, replacePattern1, replacePattern2, replacePattern3;

        //URLs starting with http://, https://, or ftp://
        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

        //Change email addresses to mailto:: links.
        replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

        return replacedText;
    }
    // Find if response is a HTML response
    const HtmlResponse = message.match(HTMLregex);

    message = message.replaceAll("Message:", "");
    message = message.replaceAll("Summary of the problem :", "<h4>Summary of the problem :</h4>");
    message = message.replaceAll("Potential root cause :", "<h4>Potential root cause :</h4>");
    message = message.replaceAll("Suggested Actions:", "<h4>Suggested Actions: </h4>");
    message = message.replaceAll("Component Name:", "<h4>Component Name: </h4>");
    message = message.replaceAll("Component Type:", "<h4>Component Type: </h4>");
    message = message.replaceAll("Cause:", "<h4>Cause: </h4>");
    message = linkify(message);
    // Additional parsing for HTML response
    if (HtmlResponse) message = parseHtml(message, HtmlResponse[0]);

    return message;
};

AppExceptions.ExceptionView = Marionette.ItemView.extend({
    template: _.template(template),

    tagName: "li",

    events: {
        "click .toggle-link": "toggleDetails",
        "mouseenter .exception-title-name": "showTooltip",
        "mouseleave .exception-title-name": "hideTooltip"
    },

    showTooltip: function(e) {
        e.preventDefault();
        var $message = this.$(".exception-title-name");
        var fullMessage = this.model.get("componentName");
        $message.attr("title", fullMessage);
    },

    hideTooltip: function(e) {
        e.preventDefault();
        var $message = this.$(".exception-title-name");
        $message.removeAttr("title");
    },

    triggers: {
        "click .node-link": {
            event: "node-link-clicked",
            preventDefault: true, // this param is optional and will default to true
            stopPropagation: true
        }
    },

    ui: {
        componentNameSeparator: ".component-name-separator",
        componentName: ".component-name",
        toggleLink: ".toggle-link",
        details: ".details",
        message: ".exception-message"
    },

    toggleDetails: function(e) {
        e.preventDefault();
        this.ui.toggleLink.toggle();
        this.ui.details.toggle();
        this.ui.message.toggleClass("expanded");
        _triggerResizedEvent();
    },

    onRender: function() {
        this.ui.componentName.toggleClass("node-link", this.model.isComponent);
        this.ui.componentName.toggle(this.model.showComponentLink);
        this.ui.componentNameSeparator.toggle(this.model.showComponentLink);
        this.ui.message.perfectScrollbar();
    },
    serializeData() {
        let data = this.model.toJSON();
        if (data.message?.indexOf("Compilation Exception") !== -1) {
            data.message = stringUtils.stringToHtml(data.message);
        }
        data.message = formatMessage(data.message);

        //growl.error(data.message, "Error Saving Component");
        return data;
    },
    onShow: function() {
        _triggerResizedEvent();
    }
});

function _triggerResizedEvent() {
    App.vent.trigger("app:errors:exceptions-container:resized");
}

AppExceptions.ExceptionCollectionView = Backbone.Marionette.CollectionView.extend({
    tagName: "ul",
    childView: AppExceptions.ExceptionView,
    collection: AppExceptions.Collection
});

export default AppExceptions;
