import React, { useState, useEffect, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { ComponentDetailsBanner, ComponentDetailsDashboard, ComponentDetailsHeader } from ".";
import LoadingIndicator from "src/generic/loading-indicator";
import useStyles from "../../../../user-plan-page.styles";
import { StriimTypography, StriimChip } from "@striim/striim-ui";
import { formatUnitName } from "../striim-unit-usage-breakdown/components/utils";
import { Card } from "../../../../../../../apps/pages/manage-striim/components";
import ComponentTable from "./component-details-table/component-table";
import { useNavigate } from "react-router";
import USER_PLAN_ROUTES from "../../../../../../routes.json";
import { useQuery } from "../../../../../../../dashboard/use-query";

const ONE_HOUR = 3600000;

const UserPlanPageDetail = ({ api, billingId, itemId }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [apiError, setApiError] = useState("");
    const [fetchTime, setFetchTime] = useState(() => Date.now());
    const query = useQuery();
    const preselectedTab = query.get("tab");

    useEffect(() => {
        async function fetchData() {
            try {
                const itemRate = await api.meteringGetItemRate(itemId);
                const aggregatedConsumption = await api.meteringGetAggregatedConsumptions(billingId, itemId);
                setData({
                    ...aggregatedConsumption,
                    ...itemRate
                });
                setFetchTime(Date.now());
            } catch (error) {
                setApiError(error);
            }
        }

        async function updateData() {
            await fetchData();
            return setInterval(fetchData, ONE_HOUR);
        }

        const interval = updateData();
        return () => clearInterval(interval);
    }, [api, billingId, itemId]);

    const dashboardData = useMemo(() => {
        let retVal = [];
        if (!data) {
            return retVal;
        }
        const unit = data?.consumptionUnit;
        if (Object.prototype.hasOwnProperty.call(data, "ilConsumptionFree")) {
            retVal.push({
                title: "Free IL Events",
                value: data?.ilConsumptionFree,
                unit: unit
            });
        }
        if (Object.prototype.hasOwnProperty.call(data, "ilConsumptionBilled")) {
            retVal.push({
                title: "Billed IL Events",
                value: data?.ilConsumptionBilled,
                unit: unit
            });
        }
        if (
            Object.prototype.hasOwnProperty.call(data, "consumptionTotal") &&
            Object.prototype.hasOwnProperty.call(data, "ilConsumptionBilled")
        ) {
            retVal.push({
                title: "Non IL Events",
                value: data?.consumptionTotal - data?.ilConsumptionBilled,
                unit: unit
            });
        }
        if (Object.prototype.hasOwnProperty.call(data, "consumptionTotal")) {
            retVal.push({
                title: "Total Consumption",
                value: data?.consumptionTotal,
                unit: unit
            });
        }
        if (Object.prototype.hasOwnProperty.call(data, "usageTotal")) {
            const usageTotal = data?.usageTotal || 0;
            retVal.push({
                title: data?.rate ? (
                    "Striim Credits Usage"
                ) : (
                    <StriimTypography noWrap variant="h3" color="primary.700">
                        {"Striim Credits Usage "}
                        <StriimChip
                            type="tag-chip"
                            variant="intresting"
                            label="FREE"
                            sx={{
                                backgroundColor: "#6765C3",
                                color: "white"
                            }}
                        />
                    </StriimTypography>
                ),
                value: Number(usageTotal.toFixed(2)),
                unit: data?.rate ? (
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                            <StriimTypography variant="caption3" color="success.500">
                                Cost/{unit}{" "}
                            </StriimTypography>
                        </Grid>
                        <Grid item>
                            <StriimTypography variant="caption2" color="success.500">
                                {`${data.rate} ${formatUnitName(data.unit)}`}
                            </StriimTypography>
                        </Grid>
                    </Grid>
                ) : (
                    ""
                ),
                tooltip: true
            });
        }
        retVal.length <= 3 && retVal.push({});
        retVal[retVal.length - 1] = { ...retVal[retVal.length - 1], customWidth: "fill" };
        return retVal;
    }, [data]);

    if (!data) {
        return (
            <div className={classes.wrapper}>
                {apiError ? (
                    <StriimTypography variant="h1" style={{ textAlign: "center" }} className={classes.header}>
                        {apiError?.message}
                    </StriimTypography>
                ) : (
                    <LoadingIndicator />
                )}
            </div>
        );
    }
    return (
        <Grid container className={classes.gridContainer} direction="column">
            <ComponentDetailsHeader
                componentName={data.itemName}
                startTime={data.startTime}
                endTime={data.endTime}
                todayLabel
                onClick={() => {
                    navigate(`${USER_PLAN_ROUTES.plan}?tab=${preselectedTab}`);
                }}
            />
            <Card>
                <ComponentDetailsBanner time={fetchTime} />
                <ComponentDetailsDashboard data={dashboardData} />
                <Box mt={3} sx={{ height: "47vh" }}>
                    <ComponentTable data={data?.components} consumptionUnit={data?.consumptionUnit} />
                </Box>
            </Card>
        </Grid>
    );
};

export default UserPlanPageDetail;
