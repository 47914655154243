import React from "react";
import { StriimTypography, StriimModal, StriimButton } from "@striim/striim-ui";
import { Box, SvgIcon } from "@mui/material";
import { Delete } from "../../../../generic/icon/customIcons";

const styles = {
    customIcon: {
        "& svg": {
            fill: "transparent"
        },
        height: 30,
        width: "auto",
        color: ({ palette }) => palette.critical[500]
    }
};

interface DeleteAllEntitiesModalProps {
    setIsModalShown: (value: boolean) => void;
    onConfirm?: () => void;
}

const DeleteAllEntitiesModal = ({ setIsModalShown, onConfirm }: DeleteAllEntitiesModalProps) => {
    return (
        <StriimModal
            isVisible={true}
            size="small"
            autoHeight
            onCancel={() => setIsModalShown(false)}
            titleContent={
                <Box display="flex" alignItems="center">
                    <SvgIcon sx={styles.customIcon} onClick={() => setIsModalShown(true)}>
                        <Delete />
                    </SvgIcon>
                    <StriimTypography sx={{ ml: "8px !important" }} variant={"h2"} color="primary.700">
                        Remove All Entities
                    </StriimTypography>
                </Box>
            }
            footerContent={
                <Box>
                    <StriimButton data-test-id="cancel-button" onClick={() => setIsModalShown(false)}>
                        Cancel
                    </StriimButton>

                    <StriimButton
                        data-test-id="delete-button"
                        variant="critical"
                        onClick={() => {
                            setIsModalShown(false);
                            if (onConfirm) onConfirm();
                        }}
                        ml={1}
                    >
                        Remove
                    </StriimButton>
                </Box>
            }
        >
            <StriimTypography mt={3} variant="body4" color="greyscale.800">
                Are you sure you want to remove all entities? This action cannot be undone.
            </StriimTypography>
        </StriimModal>
    );
};

export default DeleteAllEntitiesModal;
