import _ from "underscore";
import App from "app";
import HighChartsMixin from "app/components/dashboard/visualization/charts/mixins/highcharts";

export default _({}).extend(HighChartsMixin, {
    // Note: be sure to pass in a deep-cloned config object!
    setConfiguration: function (_config) {
        var theme = _config.theme;
        var config = _config.config;

        this.chartConfig = {
            chart: {
                type: "columnrange",
                inverted: config.layout === "horizontal",
                backgroundColor: theme.chart.background,
                animation: false,
            },
            credits: {
                enabled: false,
            },
            tooltip: this.generateToolTipConfig(),
            plotOptions: {
                series: {
                    cursor: "pointer",
                    animation: false,
                    events: {
                        hide: function (e) {
                            this.trigger("series-hidden", e);
                        }.bind(this),
                        show: function (e) {
                            this.trigger("series-shown", e);
                        }.bind(this),
                    },
                    point: {
                        events: {
                            click: function (e) {
                                var element = e.currentTarget.options.rowData;
                                this.trigger("datapoint:click", element);
                            }.bind(this),
                        },
                    },
                },
            },
            legend: {
                enabled: false,
            },
            title: {
                text: config.title,
                style: {
                    display: "none",
                },
            },

            yAxis: {
                title: {
                    text: config.valueLabel,
                },
                lineColor: theme.chart.line.color,
                gridLineColor: theme.chart.line.color,
                tickColor: theme.chart.tick ? theme.chart.tick.color : theme.chart.line.color,
                labels: {
                    style: {
                        color: theme.axis.labels ? theme.axis.labels.color : theme.axis.title.color,
                    },
                },
            },
            xAxis: {
                title: {
                    text: config.categoryLabel,
                },
                lineColor: theme.chart.line.color,
                tickColor: theme.chart.tick ? theme.chart.tick.color : theme.chart.line.color,
                labels: {
                    style: {
                        color: theme.axis.labels ? theme.axis.labels.color : theme.axis.title.color,
                    },
                },
                type: "category",
            },
        };

        if (config.chartValueType !== "scalar") {
            const format = this._getDateFormat(config.chartValueType);
            const dateTimeFormatter = this._getTimeFormatter(format, { type: "datetime" });
            dateTimeFormatter.step = 2;
            _(this.chartConfig.yAxis.labels).extend(dateTimeFormatter);
        }

        if (
            !config.data ||
            config.data.length === 0 ||
            !config.lowValueField ||
            !config.highValueField ||
            !config.categoryField
        ) {
            return;
        }
        const lowValueField = config.lowValueField;
        const highValueField = config.highValueField;

        const categories = Object.keys(config.data[0]);
        const series = Object.values(config.data[0]);

        const seriesGenerator = new App.Chart.SeriesGenerators.Default.SeriesGenerator();
        let colorAliasValue = config.categoryField;
        if (config.colorField !== "") {
            colorAliasValue = config.colorField;
        }

        const format = (value) => {
            return value instanceof Date ? value.getTime() : value;
        };

        this.chartConfig.xAxis.categories = categories;
        this.chartConfig.series = [
            {
                data: series.map((d) => {
                    const singleValue = d[0];
                    const colorAndAlias = seriesGenerator.getPointColorAndAlias(singleValue, colorAliasValue);
                    const fieldList = Object.keys(singleValue).map((key) => {
                        return { field: key };
                    });

                    return {
                        fieldList: fieldList,
                        low: format(singleValue[lowValueField]),
                        tooltipConfig: config.tooltips,
                        rowData: singleValue,
                        high: format(singleValue[highValueField]),
                        color: colorAndAlias.color,
                    };
                }),
            },
        ];
    },
    updateChartCategories: function () {
        if (!this.$el.highcharts().series[0] || !this.chartConfig.xAxis.categories) {
            return;
        }

        this.$el.highcharts().series[0].xAxis.categories = this.chartConfig.xAxis.categories;
    },
});
