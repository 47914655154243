import React, { useEffect, useState } from "react";
import {
    StriimInputField,
    StriimRadio,
    StriimTypography,
    StriimRadioGroup,
    StriimControlLabel
} from "@striim/striim-ui-v2";

import { Box, Grid, LinearProgress, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LoadingIndicator from "../../../../generic/loading-indicator";
import sxStyles from "./select-pdb.styles";
import App from "app";
import stringUtils from "../../../../../core/utils/string-utils";
import withTheme from "../../../../generic/theme/theme";
import InlineDocPanel from "../../../../generic/inline-doc/inline-doc-panel";
import InlineDocContainer from "../../../../generic/inline-doc/inline-doc-container";
import InlineDocDictionary from "../../../../generic/inline-doc/inline-doc-dictionary";
import { DB_PROVIDERS } from "app/components/appwizard/component-wizards/wizards/source/database-reader/configs/db-providers"

interface selectPDBIndicatorType {
    isLoading?: boolean;
    showMessage?: boolean;
    type?: string;
}
const PDBLoadingIndicatorComponent: React.FunctionComponent<selectPDBIndicatorType> = ({
    isLoading = true,
    showMessage = true,
    type = ''
}) => (
    <Grid sx={sxStyles.container} container>
        <img src="app/components/appwizard/images/get_pdb_loading.svg" />
        {showMessage && <StriimTypography variant="body">Connecting to {DB_PROVIDERS[type]?.name || ''}</StriimTypography>}
        {isLoading && (
            <Grid item sx={sxStyles.loadingIndicatorWrapper} px={1}>
                <LoadingIndicator isGlobal={false} variant="indeterminate" sx={sxStyles.loadingIndicator} />
            </Grid>
        )}
    </Grid>
);
interface selectPDBType {
    pdbList: string[];
    model: object;
}
const SelectPDBComponent: React.FunctionComponent<selectPDBType> = ({ pdbList, model }) => {
    const [data, setData] = useState<string[]>(pdbList);
    const [selectedPDB, setSelectedPDB] = useState<string | null>(null);
    const setSearchData = (searchValue: string) => {
        if (!searchValue) {
            setData(pdbList);
            return;
        }
        const filteredData = [...data].filter(option => {
            return option?.toLowerCase()?.indexOf(searchValue?.toLowerCase()) !== -1;
        });
        setData(filteredData);
    };
    const handleSelectPDb = (value: string) => {
        setSelectedPDB(value);
        model.set("containerName", value);
        App.vent.trigger("appwizard:next:enable");
    };

    const key = `${model.get("type")?.toLowerCase()}-pluggable-database`;
    const docData = InlineDocDictionary[key] || { path: key + ".html", cssSelector: "section" };
    // set the selected value onLoad
    useEffect(() => {
        if (model.get("containerName")) {
            setSelectedPDB(model.get("containerName"));
            App.vent.trigger("appwizard:next:enable");
        }
    }, []);

    return (
        <Box>
            <InlineDocPanel
                path={docData.path}
                inlineDocContent={<InlineDocContainer path={docData.path} className={docData.className} />}
            >
                <Box p={3} sx={sxStyles.headingContainer}>
                    <Grid container gap={1} direction={"column"}>
                        <StriimTypography variant="h2" sx={{ color: "primary.700" }}>
                            Select a Pluggable Database to Read
                        </StriimTypography>
                        <StriimTypography variant="body4" mb={1} sx={{ color: "greyscale.700" }}>
                            Striim detected the following pluggable databases in your Source.
                            <br /> Select one to read from.
                        </StriimTypography>
                    </Grid>
                    <Box sx={sxStyles.searchContainer}>
                        <StriimInputField
                            placeholder="Search"
                            onChange={setSearchData}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" sx={{ width: "8px" }}>
                                        <SearchIcon opacity={0.45} />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
                <Box p={3} sx={sxStyles.childContainer}>
                    <StriimRadioGroup
                        row={false}
                        required
                        value={selectedPDB}
                        onChange={event => {
                            handleSelectPDb(event.target.value);
                        }}
                        name="pdb"
                        id="data-test-id-select-pdb-radio-input"
                    >
                        {data.map((item, key) => (
                            <StriimControlLabel
                                sx={{ mt: 1 }}
                                key={key}
                                value={item}
                                label={
                                    <StriimTypography variant="body4" sx={{ color: "greyscale.700" }}>
                                        {stringUtils.encodeHtml(item)}
                                    </StriimTypography>
                                }
                                control={<StriimRadio checked={selectedPDB === item} />}
                            />
                        ))}
                    </StriimRadioGroup>
                </Box>
            </InlineDocPanel>
        </Box>
    );
};
const PDBLoadingIndicator = withTheme(PDBLoadingIndicatorComponent);
const SelectPDB = withTheme(SelectPDBComponent);
export { SelectPDB, PDBLoadingIndicator };
