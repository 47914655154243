import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    wrapper: {
        overflow: "hidden",
        marginTop: spacing(12),
        [breakpoints.up("lg")]: {
            paddingLeft: 176,
            paddingRight: 176
        },
        [breakpoints.down("md")]: {
            paddingLeft: spacing(4),
            paddingRight: spacing(4)
        }
    },
    heading: {
        marginBottom: 0
    },
    heading2: {
        marginTop: 0,
        marginBottom: 16
    }
}));
