import _ from "underscore";
import BaseControl from "./../base-control";
import { filterComponentsByAdapters } from "app/components/flow/designer/component-acendants-loader";
import { loadAscendantsDbSources } from "app/components/flow/designer/component-acendants-loader";
import loadTables from "app/components/flow/designer/tables-loader.js";
import { loadDGsForAgents, getAgentForDeploymentGroup } from "app/components/flow/designer/agents-loader";
import UIControl from "app/components/common/editor/control/ui-control";
import ArrayDataSource from "./../select/array-datasource";
import FieldSelector from "./field-selector";
import template from "./wae-data-modifier.html";

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "columns-splitter",

    regions: {
        fileNameInputRegion: ".file-name-input",
        agentSelectorRegion: ".agent-selector",
        tableSelectorRegion: ".table-selector",
        querySelectorRegion: ".query-selector",
    },

    ui: {
        agentSelectorContainer: ".agent-selector-container",
        tableSelectorContainer: ".table-selector-container",
        fileNameInputContainer: ".file-name-container",
        tableSelectorLoader: ".table-selector-container .loader",
    },

    setViewValue: function () {},

    getViewValue: function () {
        let value = this.querySelector.getViewValue();
        if (!value) {
            return;
        }

        value.metadataName = "TableName";
        value.metadataValue = this.tableSelector.getValue();
        value.agent = this.agentSelector.getValue();

        if (!value.metadataValue) {
            return [];
        }

        return [value];
    },

    setEnabled: function (enabled) {
        BaseControl.View.prototype.setEnabled.apply(this, [enabled]);
        this.agentSelector.setEnabled(enabled);
        this.tableSelector.setEnabled(enabled);
        this.querySelector.setEnabled(enabled);
    },

    initialize: function (options) {
        this.agentSelector = UIControl.Select([])
            .extend({
                placeholder: "select",
            })
            .create();

        this.tableSelector = UIControl.Select([])
            .extend({
                placeholder: "select",
            })
            .create();

        this.querySelector = FieldSelector.create();
    },

    onRender: function () {
        this.agentSelectorRegion.show(this.agentSelector);
        this.tableSelectorRegion.show(this.tableSelector);
        this.querySelectorRegion.show(this.querySelector);

        this.toggleAgentsSelector();

        if (this.model.value && this.model.value.length > 0) {
            this.agentSelector.setValue(this.model.value[0].agent);
            this.tableSelector.setValue(this.model.value[0].metadataValue);
            this.querySelector.setValue(this.model.value[0]);
        }

        this.listenTo(
            this.agentSelector.model,
            "change:value",
            function (viewModel) {
                this.trigger(UIControl.EVENT.VALUE_CHANGED, this.getViewValue());

                if (!this.firstLoad) {
                    this.tableSelector.model.value = "";
                }

                if (viewModel.value === "server") {
                    this.toggleTableSelector(viewModel.value);
                } else {
                    let agent = getAgentForDeploymentGroup(viewModel.value);
                    this.toggleTableSelector(agent);
                }
            }.bind(this)
        );

        this.listenTo(
            this.tableSelector.model,
            "change:value",
            function (viewModel) {
                this.tableSelector.clearError();
                this.trigger(UIControl.EVENT.VALUE_CHANGED, this.getViewValue());
                this.toggleQuerySelector(viewModel.value);
            }.bind(this)
        );

        this.listenTo(
            this.querySelector.model,
            "change:value",
            function () {
                this.trigger(UIControl.EVENT.VALUE_CHANGED, this.getViewValue());
            }.bind(this)
        );
    },

    setStream: function (componentId) {
        if (!this.streamId) {
            this.firstLoad = true;
        }
        this.streamId = componentId;

        loadAscendantsDbSources(componentId).then((result) => {
            if (!this.ui.agentSelectorContainer.hide) {
                return;
            }

            this.sourceComponent = result[0];
            this.toggleAgentsSelector();
        });

        this.querySelector.updateExpressionFieldsSource(this.streamId);
    },

    toggleQuerySelector: function (selectedTable) {
        if (!this.firstLoad) {
            this.querySelector.clear();
        }

        if (selectedTable) {
            let columnsSource = this.getColumnsSource(selectedTable);
            if (columnsSource.length > 0) {
                this.querySelector.setFieldsSource(columnsSource);
                this.querySelector.$el.show();
            } else {
                this.tableSelector.showError("No Columns loaded for selected Table.");
            }
        } else {
            this.querySelector.$el.hide();
            this.querySelector.clear();
        }

        this.querySelector.updateExpressionFieldsSource(this.streamId);
    },

    toggleAgentsSelector: function () {
        this.querySelector.$el.hide();

        let agents = loadDGsForAgents();
        this.agentSelector.setDataSource(this.toAgentsSelectSource(agents));

        if (agents.length < 2) {
            this.ui.agentSelectorContainer.hide();
            this.agentSelector.model.value = "server";
            this.agentSelector.model.trigger("change:value", this.agentSelector.model);
        } else {
            this.ui.agentSelectorContainer.show();
        }
    },

    toggleTableSelector: function (agentValue) {
        // should not load columns when settings didn't change
        if (this._lastSelectedAgent === agentValue && this._lastStreamId === this.streamId) {
            return;
        } else {
            this._lastSelectedAgent = agentValue;
            this._lastStreamId = this.streamId;
        }

        if (!agentValue) {
            this.ui.tableSelectorContainer.hide();
            this.tableSelector.setDataSource([]);
        } else {
            this.ui.tableSelectorContainer.show();

            if (!this.streamId) {
                this.tableSelector.setDataSource([]);
                return;
            }

            if (agentValue === "server") {
                agentValue = "";
            }

            this.tableSelector.setDataSource([]);
            this.toggleTableSelectorLoader(true);

            loadTables(this.sourceComponent, agentValue, true)
                .then((tables) => {
                    this.tablesHashmap = tables;
                    this.toggleTableSelectorLoader(false);

                    let tablesSourceFunction = ArrayDataSource(Object.keys(tables));
                    this.tableSelector.setDataSource(tablesSourceFunction);
                    if (this.firstLoad && this.tableSelector.model.value) {
                        this.tableSelector.model.trigger("change:value", this.tableSelector.model);
                        this.firstLoad = false;
                    } else if (this.tableSelector.model.value) {
                        this.tableSelector.model.value = "";
                        this.tableSelector.model.trigger("change:value", this.tableSelector.model);
                    }
                })
                .catch((error) => {
                    this.toggleTableSelectorLoader(false);
                    this.tableSelector.setDataSource([]);
                    this.trigger("show-error-msg", error);
                });
        }
    },

    toAgentsSelectSource: function (agents) {
        let agentsSource = [{ id: "server", text: "Server" }];

        _.each(agents, function (elem) {
            agentsSource.push({
                id: elem.attributes.id,
                text: elem.attributes.name,
            });
        });

        return agentsSource;
    },

    getColumnsSource: function (tableName) {
        if (!tableName || !this.tablesHashmap) {
            return [];
        }

        let columnsHashmap = this.tablesHashmap[tableName];
        let columnsNames = Object.keys(columnsHashmap);

        let columnsSource = [];

        for (let i = 0; i < columnsNames.length; i++) {
            columnsSource.push({
                id: "column_" + i,
                text: columnsNames[i],
                description: "data[" + i + "]<br/>" + columnsHashmap[columnsNames[i]] + "",
            });
        }

        return columnsSource;
    },

    toggleTableSelectorLoader: function (visible) {
        if (this.ui.tableSelectorLoader) {
            this.ui.tableSelectorLoader.toggleClass("hidden-element", !visible);
        }

        if (this.getEnabled()) {
            this.tableSelector.setEnabled(!visible);
        }
    },
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: Array,
    },
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
