// A base HighCharts mixin
import App from "app";
import _ from "underscore";
import _chartCommonMixin from "./_chartCommonMixin";
import _chartRendererMixin from "./_chartRendererMixin";
import _highchartsSeriesMixin from "./_highchartsSeriesMixin";
import "app/components/dashboard/visualization/charts/seriesgenerators/default/generator";
import dataTypes from "core/data-types";

var highchartsMixin = {};
_(highchartsMixin).extend(_chartCommonMixin);
_(highchartsMixin).extend(_chartRendererMixin);
_(highchartsMixin).extend(_highchartsSeriesMixin);
_(highchartsMixin).extend({
    _formatXAxis: function (xAxis, theme) {
        var that = this;
        return _(xAxis).map(function (axis) {
            var formatted_xAxis = {
                lineColor: theme.chart.line.color,
                tickColor: theme.chart.tick ? theme.chart.tick.color : theme.chart.line.color,
                //_categories: axis._categories,
                type: axis.type,
                title: {
                    text: axis.title,
                    style: {
                        color: theme.axis.title.color,
                    },
                },
                labels: {
                    style: {
                        color: theme.axis.labels ? theme.axis.labels.color : theme.axis.title.color,
                    },
                },
            };
            // format the dateTime to the proper format.
            if (axis.type === "datetime") {
                var formatterString = that._getDateFormat(axis.dateTimeFormat);
                var dateTimeFormatter = that._getTimeFormatter(formatterString, axis);
                _(formatted_xAxis.labels).extend(dateTimeFormatter);
                if (formatted_xAxis.labels) {
                    formatted_xAxis.labels.step = 2; // avoid overcrowding of datetime DEV-12249
                }
            }

            return formatted_xAxis;
        });
    },
    /**
     * gets an object to extend with the axis object.
     * @param formatterString
     * @param axis
     * @returns {{formatter: Function, rotation: number}}
     * @private
     */
    _getTimeFormatter: function (formatterString, axis) {
        return {
            formatter: function () {
                if (axis.type === "datetime") {
                    var date = new dataTypes.dateTime(this.value);
                    date.setFormatString(formatterString);
                    return date.toString();
                }
            },
            rotation: 0,
        };
    },

    _getNumberFormatter: function (axis) {
        return {
            formatter: function () {
                if (axis.type === "logarithmic") {
                    function isFloat(n) {
                        return Number(n) === n && n % 1 !== 0;
                    }

                    if (typeof this.value !== "number") {
                        return this.value;
                    }

                    if (!isFloat(this.value)) {
                        return this.value;
                    }

                    return Math.round(this.value * 10000) / 10000;
                }
            },
            rotation: 0,
        };
    },

    /**
     *
     * @param type human readable string..
     * @returns {string} a dayJS format.
     * @private
     */
    _getDateFormat: function (type) {
        var formatterString = "MMM Do YYYY <br> h:mm:ss a";
        switch (type) {
            case "date":
                formatterString = "MMM Do YYYY";
                break;
            case "time":
                formatterString = "h:mm:ss a";
                break;
            case "dateTime":
                formatterString = "MMM Do YYYY <br> h:mm:ss a";
                break;
        }
        return formatterString;
    },

    _formatYAxis: function (yAxis, theme) {
        const that = this;

        return _(yAxis).map(function (axis) {
            let formatted_yAxis = {
                gridLineColor: theme.chart.grid.color,
                type: axis.type,
                title: {
                    text: axis.title,
                    style: {
                        color: theme.axis.title.color,
                    },
                },
                labels: {
                    style: {
                        color: theme.axis.labels ? theme.axis.labels.color : theme.axis.title.color,
                    },
                },
            };

            // format the dateTime to the proper format.
            if (axis.type === "logarithmic") {
                const numberFormatter = that._getNumberFormatter(axis);
                _(formatted_yAxis.labels).extend(numberFormatter);
            }

            return formatted_yAxis;
        });
    },
    destroyChart: function () {
        var hc = this.$el.highcharts();
        if (hc) {
            hc.destroy();
        }
    },
    renderChart: function () {
        this.renderIfNeeded(this.chart, this.createChart, this.updateChart);
    },
    createChart: function () {
        var _this = this;
        this.destroyChart();
        if (this.chartConfig) {
            this.chartConfig.noData = {
                style: {
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "#819197",
                },
            };
            this.chartConfig.lang = {
                noData: "Waiting for data",
            };
            this.chart = this.$el.highcharts(this.chartConfig);
            var tooltip = _this.$el.highcharts().tooltip;
            if (tooltip) {
                var originalFormatter = tooltip.options.formatter;
                this.$el.bind("mousemove", function (e) {
                    if (e.buttons) {
                        tooltip.options.formatter = function () {
                            return null;
                        };
                    }
                });
                this.$el.bind("mouseup", function () {
                    tooltip.options.formatter = originalFormatter;
                });
            }
        }
    },
    updateChart: function () {
        this.updateChartSeries();
        this.updateChartCategories();
        this.$el.highcharts().redraw();
    },
    resize: function () {
        if (this.$el.highcharts()) {
            this.$el.highcharts().reflow();
        }
    },
    updateChartCategories: function () {
        if (!this.$el.highcharts().series[0]) {
            return;
        }

        if (
            this.$el.highcharts().series[0].xAxis &&
            this.$el.highcharts().series[0].xAxis.userOptions.type === "category"
        ) {
            this.$el.highcharts().series[0].xAxis.categories = this.chartConfig.xAxis[0].categories;
        }

        if (
            this.$el.highcharts().series[0].yAxis &&
            this.$el.highcharts().series[0].yAxis.userOptions.type === "category"
        ) {
            this.$el.highcharts().series[0].yAxis.categories = this.chartConfig.yAxis[0].categories;
        }
    },
    generateSeriesCategoriesAndLegendData: function (seriesIndex, datavisualizationConfig, chartConfig, fieldList) {
        if (datavisualizationConfig.xAxis.length === 0 || datavisualizationConfig.yAxis.length === 0) {
            return {
                seriesData: [],
                xCategories: [],
                yCategories: [],
            };
        }

        var xAxisDefinition = this.findXAxisDefinition(
            datavisualizationConfig,
            datavisualizationConfig.series[seriesIndex].xAxis
        );
        var yAxisDefinition = this.findYAxisDefinition(
            datavisualizationConfig,
            datavisualizationConfig.series[seriesIndex].yAxis
        );
        var zAxisDefinition = this.findZAxisDefinition(
            datavisualizationConfig,
            datavisualizationConfig.series[seriesIndex].zAxis
        );

        var xField = "";
        var yField = "";
        var zField = "";

        if (xAxisDefinition) {
            xField = xAxisDefinition.field;
        }
        if (yAxisDefinition) {
            yField = yAxisDefinition.field;
        }
        if (zAxisDefinition) {
            zField = zAxisDefinition.field;
        }

        var seriesConfigurationOptions = {
            isXAxisTypeCategory: xAxisDefinition.type === "category",
            isYAxisTypeCategory: yAxisDefinition.type === "category",
            xField: xField,
            yField: yField,
            zField: zField,
            includePointColor:
                datavisualizationConfig.includePointColor ||
                (typeof datavisualizationConfig.includePointColor === "undefined" &&
                    (datavisualizationConfig.categoryField !== "" || datavisualizationConfig.colorField !== "")),
            categoryField: datavisualizationConfig.categoryField,
            colorField: datavisualizationConfig.colorField,
            includeCategoryWithDataPoint: datavisualizationConfig.includeCategoryWithDataPoint,
            tooltipConfig: datavisualizationConfig.tooltips,
            fieldList: fieldList,
            retentionStrategyType: datavisualizationConfig.retentionStrategyType,
            conditionalColorRules: datavisualizationConfig.conditionalColor,
            seriesAlias: datavisualizationConfig.series[seriesIndex].alias,
        };

        var seriesGeneratorFactory = new App.Chart.SeriesGenerators.Default.SeriesGeneratorFactory();

        if (datavisualizationConfig.retentionStrategyType === "all" && datavisualizationConfig.categoryField !== "") {
            seriesConfigurationOptions.data = this.model.dataSource.data[0];
        } else {
            seriesConfigurationOptions.data = this.model.dataSource.data;
        }
        // console.log('***** Generating Data For: Retention: '+ datavisualizationConfig.retentionStrategyType + ' categoryField: ' + seriesConfigurationOptions.categoryField + ' using data: ' + seriesConfigurationOptions.data);
        var seriesGenerator = seriesGeneratorFactory.create(seriesConfigurationOptions);
        var seriesAndCategories = {
            series: seriesGenerator.generate(),
            xCategories: seriesGenerator.getXCategories(),
            yCategories: seriesGenerator.getYCategories(),
            legendData: seriesGenerator.getLegendData(),
        };
        return seriesAndCategories;
    },
    setChartConfigWithSeriesCategoryAndChartData: function (seriesIndex, datavisualizationConfig, seriesAndCategories) {
        if (
            !datavisualizationConfig.series ||
            !datavisualizationConfig.xAxis ||
            !datavisualizationConfig.yAxis ||
            datavisualizationConfig.xAxis.length === 0 ||
            datavisualizationConfig.yAxis.length === 0
        ) {
            return;
        }

        var xAxisDefinition = this.findXAxisDefinition(
            datavisualizationConfig,
            datavisualizationConfig.series[seriesIndex].xAxis
        );
        var xAxisIndex = this.findXAxisIndex(
            datavisualizationConfig,
            datavisualizationConfig.series[seriesIndex].xAxis
        );
        var yAxisDefinition = this.findYAxisDefinition(
            datavisualizationConfig,
            datavisualizationConfig.series[seriesIndex].yAxis
        );
        var yAxisIndex = this.findYAxisIndex(
            datavisualizationConfig,
            datavisualizationConfig.series[seriesIndex].yAxis
        );

        if (!this.chartConfig.legendData) {
            this.chartConfig.legendData = [];
        }
        // this.chartConfig.legendData = seriesAndCategories.legendData;
        this.mergeLegendData(seriesAndCategories.legendData);
        if (!this.chartConfig.series) {
            this.chartConfig.series = [];
        }

        for (var i = 0; i < seriesAndCategories.series.length; i++) {
            this.chartConfig.series.push(seriesAndCategories.series[i]);
        }

        if (datavisualizationConfig.xAxis.length > 0 && xAxisDefinition.type === "category") {
            this.chartConfig.xAxis[xAxisIndex].categories = seriesAndCategories.xCategories;
        }

        if (datavisualizationConfig.yAxis.length > 0 && yAxisDefinition.type === "category") {
            this.chartConfig.yAxis[yAxisIndex].categories = seriesAndCategories.yCategories;
        }
    },
    chartHasXAxis: function () {
        return this.$el.highcharts().xAxis ? true : false;
    },
    chartHasYAxis: function () {
        return this.$el.highcharts().yAxis ? true : false;
    },
    findXAxisDefinition: function (config, id) {
        for (var i = 0; i < config.xAxis.length; i++) {
            if (config.xAxis[i].id === id) {
                return config.xAxis[i];
            }
        }

        return {};
    },
    findYAxisDefinition: function (config, id) {
        for (var i = 0; i < config.yAxis.length; i++) {
            if (config.yAxis[i].id === id) {
                return config.yAxis[i];
            }
        }

        return {};
    },
    findZAxisDefinition: function (config, id) {
        for (var i = 0; i < config.zAxis.length; i++) {
            if (config.zAxis[i].id === id) {
                return config.zAxis[i];
            }
        }

        return {};
    },
    findXAxisIndex: function (config, id) {
        for (var i = 0; i < config.xAxis.length; i++) {
            if (config.xAxis[i].id === id) {
                return i;
            }
        }

        return -1;
    },
    findYAxisIndex: function (config, id) {
        for (var i = 0; i < config.yAxis.length; i++) {
            if (config.yAxis[i].id === id) {
                return i;
            }
        }

        return -1;
    },
    findZAxisIndex: function (config, id) {
        for (var i = 0; i < config.zAxis.length; i++) {
            if (config.zAxis[i].id === id) {
                return i;
            }
        }

        return -1;
    },
    mergeLegendData: function (newLegendData) {
        for (var key in newLegendData) {
            if (newLegendData.hasOwnProperty(key)) {
                this.chartConfig.legendData[key] = newLegendData[key];
            }
        }
    },
});

export default highchartsMixin;
