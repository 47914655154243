/*
 Lest side panel - list of draggable elements
 */
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import statusManagement from "src/status-management";
import GroupView from "./groupView";
import predefinedComponentsTemplate from "./templates/predefinedComponentsTemplate.html";
import "lib/vendor/jquery.clearsearch";
import App from "app";
import { onDrag, onDrop } from "../../../common/drag-and-drop";
import predefinedComponentProcessor from "../nodeProcessing/predefinedComponentProcessor";
import securityCheck from "../../../../../core/services/securityService/adapter-license-check";

const PredefinedComponentsView = Backbone.Marionette.CompositeView.extend({
    template: _.template(predefinedComponentsTemplate),
    tagName: "div",
    childViewContainer: "ul.component-groups",
    childView: GroupView,

    isDragDropEnabled: true,
    currentAppStatus: null,

    ui: {
        searchContainer: ".search-input",
        searchBox: ".search-input input",
        groupsContainer: ".component-groups"
    },

    events: {
        "keyup @ui.searchBox": "_onFilter",
        "change @ui.searchBox": "_onFilterEmpty"
    },

    initialize: function() {
        this.collection.models[0].isExpanded = true;

        this.listenTo(this, "childview:group-expanded", function(view, model) {
            if (this.ui.searchBox.val()) {
                return;
            }

            this.collection.each(function(groupModel) {
                if (groupModel === model) {
                    groupModel.isExpanded = !groupModel.isExpanded;
                } else {
                    groupModel.isExpanded = false;
                }
            });
        });
        this.setEnabledComponents();
    },

    setAppStatus: function(app_status) {
        this.currentAppStatus = app_status.toUpperCase();
        this.toggleElementsEnabled(true);
    },

    onRender: function() {
        var _this = this;
        this.toggleElementsEnabled(true);
        // this.ui.searchBox.addClear();
    },

    onShow: function() {
        this._initDragDrop();
    },

    _initDragDrop: function() {
        var _this = this;
        const element = this.$el.find(".draggable-sidebar-component.enabled");

        onDrag(
            "predefined-component",
            element.get(),
            clickedElement => {
                const componentType = $(clickedElement).data("type");
                const name = $(clickedElement).data("name");
                return {
                    componentType,
                    name
                };
            },
            element
                .closest(".left-menu")
                .parent()
                .get()[0],
            clickedElement => {
                clickedElement.attributes.removeNamedItem("data-id");
                clickedElement.classList.add("drag-start");
                clickedElement.classList.add("predefined-component-proxy");
            },
            dropData => {
                _this.trigger("start-drag", dropData.componentType, dropData.name);
            }
        );

        onDrop("predefined-component", proxyElement => {
            App.vent.trigger("metadata-browser:close");
            $(proxyElement).removeClass("drag-start");
            _this.trigger("end-drag");
        });
    },

    setEnabledComponents: function() {
        this.collection.each(function(groupModel) {
            groupModel.components.each(function(componentModel) {
                var returnObj = { metaObject: { autoProperties: {} } };
                predefinedComponentProcessor.process(
                    componentModel.get("componentType"),
                    returnObj,
                    null,
                    componentModel.get("name")
                );
                if (returnObj.metaObject.autoProperties.adapter) {
                    let adapterTypeFull = returnObj.metaObject.autoProperties.adapter.handler;
                    let adapterType = adapterTypeFull.replace("Global.PROPERTYTEMPLATE.", "");
                    let enabled = securityCheck.checkWithLicenseValidation(adapterType, componentModel.name);
                    componentModel.isEnabled = !!enabled;
                }
            });
        });
    },

    _resetGroupViewState: function() {
        var _this = this;
        // reseting group view
        this.collection.each(function(groupModel) {
            groupModel.components.each(function(componentModel) {
                componentModel.isVisible = true;
            });

            // Expand Source Category by default and collapse others...
            groupModel.isExpanded = groupModel === _this.collection.models[0];

            _this.children.each(function(groupView) {
                groupView.isInSharedHeightMode = false;
                groupView.currentNumberOfVisibleGroups = 0;
                groupView.setFullHeight();
            });
        });
    },

    _onFilterEmpty: function() {
        if (this.ui.searchBox.val() === "") {
            this._resetGroupViewState();
        }
    },

    refresh: function() {
        if (this.ui.searchBox.val) {
            this.ui.searchBox.val("");
            this._resetGroupViewState();
        }
    },

    _onFilter: function(e) {
        var _this = this;
        var searchTerm = this.ui.searchBox.val().toLowerCase();

        this._resetGroupViewState();

        if (searchTerm === "") {
            return;
        }

        // collapse Source category which is expanded by default
        _this.collection.models[0].set("isExpanded", false);

        this.collection.each(function(group) {
            group.isExpanded = true;
            group.components.each(function(component) {
                component.isVisible = component.name.toLowerCase().indexOf(searchTerm) >= 0;
            });
        });

        var visibleGroups = this.collection.reject(function(groupModel) {
            return groupModel.hasVisibleComponents === false;
        });
        _.each(visibleGroups, function(groupModel) {
            var groupView = _this.children.findByModel(groupModel);
            groupView.isInSharedHeightMode = true;
            groupView.currentNumberOfVisibleGroups = visibleGroups.length;
            groupView.setSharedGroupHeight(visibleGroups.length);
        });
    },

    toggleElementsEnabled: function(isEnabled) {
        var isEnabledForStatus =
            this.currentAppStatus != null && statusManagement.isApplicationCreated(this.currentAppStatus);
        if (!isEnabledForStatus) {
            this.setControlEnabled(false);
            return;
        }
        this.setControlEnabled(isEnabled);
    },

    setControlEnabled: function(isEnabled) {
        this.isDragDropEnabled = isEnabled;
        this.$el.toggleClass("active", this.isDragDropEnabled);
    }
});

export default PredefinedComponentsView;
