export const styles = {
    legendText: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '10px',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0.2px'
    },
    legendWrapper: {
        left: 0,
        top: 24
    },
    barChartPosition: {
        left: 0,
        top: -16
    },
    barChart: {
        stroke: '#FFF',
        strokeWidth: 2
    },
    bottomBorder: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
}