import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing }) => {
    return {
        wrapper: {
            height: `calc(100vh - ${spacing(6)}px)`
        },
        noAppsImg: {
            margin: `${spacing(3)}px 0px ${spacing(3)}px 0px`
        }
    };
});
