// A base View for a menu like the kind used in context flyout menus.
import Backbone from "backbone";
import NestedTypes from "backbone.nestedtypes";
import _ from "underscore";
import menuOption from "./menu-option.js";
import "tooltips";

const menu = {};

menu.Model = NestedTypes.Model.extend({
    defaults: {
        options: menuOption.Model.Collection,
    },
});

menu.View = Backbone.Marionette.CompositeView.extend({
    className: "options-menu",
    template: _.template("<ul></ul>"),
    childViewContainer: "ul",
    childView: menuOption.View,
    initialize: function () {
        this.collection = this.model.options;

        this.on("childview:click", function (childview) {
            if (childview.model.enabled === true) {
                var optionID = childview.model.value || childview.model.id;
                this.trigger("option:click", optionID, childview);
            }
        });
    },
});

export default menu;
