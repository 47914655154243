import { template, className } from "core/decorators";
import { LayoutView } from "marionette";
import App from "app";
import SchemaSelectionTemplate from "./templates/schema-selection.template.html";
import "datatables.net-dt";
import "datatables.net-select";
import stringUtils from "core/utils/string-utils";

import _ from "underscore";

@className("card schema-selection-view")
@template(SchemaSelectionTemplate)
export default class SchemaSelectionView extends LayoutView {
    initialize() {
        let model = App.reqres.request("get:wizard:model");
        this.model = model;
    }

    delayedUpdate() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            App.vent.trigger("appwizard:next:disable");
        }, 100);
    }

    onShow() {
        const isFooterAvailable = $("#appwizard-footer");
        if (isFooterAvailable.length) {
            App.vent.trigger("appwizard:next:disable");
        } else {
            this.delayedUpdate();
        }

        let data = [];
        let context = this;
        let channels = this.model.get("channels");
        channels.each(function(channel) {
            data.push({
                icon: `<label style="pointer-events: none;">
                        <input type="radio" name="select_channel" class="with-gap"/>
                        <span> </span>
                    </label>`,
                name: channel.get("schemaName"),
                displayName: stringUtils.encodeHtml(channel.get("schemaName")),
                DT_RowId: channel.get("id")
            });
        });

        var dataTable = this.$el.find("#schema-selection").DataTable({
            data: data,
            defaultContent: "",
            scrollCollapse: true,
            paging: false,
            stripeClasses: [],
            autoWidth: false,
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            info: false,
            columns: [
                {
                    sortable: false,
                    data: "icon"
                },
                { title: "Channel Name ", data: "displayName", sortable: false }
            ],
            columnDefs: [
                {
                    className: "select-radiobutton",
                    targets: 0,
                    width: 30
                }
            ]
        });
        dataTable.on("click", "tr", function() {
            let channelName = null;
            var checkbox = $(this).find('input[type="radio"]');
            checkbox[0].checked = !checkbox[0].checked;
            context.$el.find(".selected").removeClass("selected");
            if (checkbox[0].checked) {
                channelName = $(this).attr("id");
                $(this).addClass("selected");
                App.vent.trigger("appwizard:next:enable");
            } else {
                App.vent.trigger("appwizard:next:disable");
            }
            context.model.set("selectedChannel", channelName);
        });
    }

    async canMoveForward() {
        App.vent.trigger("appwizard:retry:hide");
        App.vent.trigger("appwizard:next:enable");
    }

    onBeforeDestroy() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }
}
