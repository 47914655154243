import React from "react";
import { Formik } from "formik";
import FormikField from "src/generic/formik-field";
import { StriimTypography, StriimButton, StriimIconWrapperBase } from "@striim/striim-ui";
import { Grid, Box } from "@mui/material";
import { icons } from "src/generic/icon/flow-designer-icon";
import * as Yup from "yup";
import { useStyles } from "./cosmosdb-mongo-api.styles";
import ErrorMessageBox from "../common/error-message-box/error-message-box";
import { toJS } from "mobx";
import { COSMOSDB_CONFIG } from "../cosmosdb-target/config";
import DatabaseMapping from "../common/database-mapping/database-mapping";
import {
    getTargetNameValidation,
    hostValidation,
    portValidation,
    usernameValidation,
    passwordValidation,
    dbMappingValidation
} from "../common/target.validations";
import FormikErrorFocus from "../common/formik-error-focus/formik-error-focus";
import { sxStyles } from "../cosmosdb-cassandra-api/cosmosdb-cassandra-api.styles";

import Footer from "../common/footer/footer";
export const mongoValidationSchema = Yup.object().shape({
    targetName: getTargetNameValidation(),
    host: hostValidation,
    port: portValidation,
    username: usernameValidation,
    password: passwordValidation,
    dbMapping: dbMappingValidation
});

const CosmosDBMongoApi = ({ store, nsName, sourceDatabases, targetApi, formRef, targetConfig }) => {
    const classes = useStyles();

    const { defaultValues } = COSMOSDB_CONFIG.mongo;
    const formikValues = toJS(targetConfig) || {
        ...defaultValues,
        nsName,
        dbMapping: sourceDatabases.map(v => ({ sourceDatabase: v, targetDatabase: v }))
    };

    return (
        <Grid container direction="column">
            <Grid container justifyContent="flex-start" sx={sxStyles.headerContainer} alignItems="center">
                <Grid item className={classes.icons}>
                    <StriimIconWrapperBase Icon={icons[targetApi.icon]} type="target" />
                </Grid>
                <Grid item className={classes.title}>
                    <StriimTypography variant="h2">Configure Cosmos DB ({targetApi.targetName})</StriimTypography>
                </Grid>
                <Grid item className={classes.helpBtn}>
                    <StriimButton variant="primaryText" target="_blank" href={COSMOSDB_CONFIG.mongo.docsLink}>
                        View Documentation
                    </StriimButton>
                </Grid>
            </Grid>
            <Formik
                initialValues={formikValues}
                validateOnChange={true}
                validateOnBlur={false}
                validationSchema={mongoValidationSchema}
                enableReinitialize={true}
                innerRef={formRef}
            >
                {({ values, errors, touched, submitCount }) => {
                    return (
                        <>
                            {submitCount > 0 && (
                                <ErrorMessageBox
                                    values={values}
                                    errors={errors}
                                    validationSchema={mongoValidationSchema}
                                    emptyFieldErrors={true}
                                    otherFieldErrors={false}
                                />
                            )}
                            <Grid container direction="column" sx={sxStyles.fieldsContainer}>
                                <Grid item xs={12} className={classes.gridItemHeader}>
                                    <Box component={FormikField} name="targetName" label={"Target name"} />
                                </Grid>
                                <Grid item container xs={12} className={classes.formInput}>
                                    <Grid item xs={8}>
                                        <Box
                                            component={FormikField}
                                            name="host"
                                            label={"Host"}
                                            placeholder="IP Address or Hostname"
                                        />
                                    </Grid>
                                    <Grid item xs={4} className={classes.columnSpacing}>
                                        <Box
                                            component={FormikField}
                                            name="port"
                                            label={"Port"}
                                            placeholder="Example: 443"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.inputGroup}>
                                    <Box
                                        component={FormikField}
                                        name="username"
                                        label={"Username"}
                                        placeholder=" Example: scott"
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.inputGroup}>
                                    <Box
                                        component={FormikField}
                                        name="password"
                                        label={"Password"}
                                        type="password"
                                        placeholder="********"
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.inputGroup}>
                                    <StriimTypography variant="h3" sx={sxStyles.tableMappingTitle}>
                                        DATABASE MAPPING
                                    </StriimTypography>
                                    <StriimTypography variant="body4" sx={sxStyles.caption}>
                                        Please map your source database or schema name to target Cosmos DB database
                                        name. Kindly ensure mapped tables/collections are created on the target Cosmos
                                        account.
                                    </StriimTypography>
                                </Grid>
                                <Grid item xs={12} className={classes.formInput}>
                                    <DatabaseMapping values={values} errors={errors} touched={touched} />
                                </Grid>
                            </Grid>
                            <FormikErrorFocus />
                        </>
                    );
                }}
            </Formik>
            <Footer store={store} onBack={() => store.goToPreviousStep()} onNext={() => store.goToNextStep()} />
        </Grid>
    );
};

export default CosmosDBMongoApi;
