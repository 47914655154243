import React, { useState, useContext, useEffect } from "react";
import { StriimButton, StriimTypography } from "@striim/striim-ui";
import footerClasses from "./footer.styles";
import { Grid } from "@mui/material";
import { WizardContext } from "../../wizard-context";
import DropAppModal from "../../../../generic/drop-app-modal/drop-app-modal";
import useStores from "src/utils/use-stores";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import WithTooltip from "../../../../generic/tooltip/tooltip";
import { isAutomatedWizard } from "../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";

interface FooterProps {
    hasCancel?: boolean;
    next?: string;
    onBack?: () => void;
    backButtonText?: string;
    onNext?: () => void;
    cancelDisabled?: boolean;
    backDisabled?: boolean;
    nextDisabled?: boolean;
    nextTooltip?: string;
    hasBack?: boolean;
    hasSave?: boolean;
    onSave?: () => void;
    saveDisabled?: boolean;
}

const Footer = ({
    hasCancel = true,
    cancelDisabled,
    onBack,
    backDisabled,
    backButtonText = "Back",
    next = "Next",
    onNext,
    nextDisabled,
    nextTooltip = null,
    hasBack = true,
    hasSave,
    onSave,
    saveDisabled
}: FooterProps) => {
    const { store } = useStores();
    const { wizardAppName } = useContext(WizardContext);
    const [modalVisible, setModalVisible] = useState(false);
    const [appModel, setAppModel] = useState(null);
    const [nsName, appName] = wizardAppName.split(".");

    useEffect(() => {
        (async () => {
            const app = await metaStoreService.findById(
                metaObjectConverter.getId(nsName, metaStoreService.entities.APPLICATION, appName)
            );
            const appJSON = app.toJSON();
            appJSON.shortName = wizardAppName;
            setAppModel(appJSON);
        })();
    }, []);

    const TooltipButton = ({ tooltip, ...props }) => {
        return tooltip ? (
            WithTooltip(
                <StriimButton {...props} />,
                <StriimTypography color="greyscale.50" variant="caption3">
                    {tooltip}
                </StriimTypography>
            )
        ) : (
            <StriimButton {...props} />
        );
    };

    return (
        <>
            <Grid container sx={footerClasses.footer}>
                <Grid item>
                    {hasCancel && (
                        <StriimButton
                            variant="text"
                            onClick={() => setModalVisible(true)}
                            disabled={cancelDisabled}
                            data-test-id="cancel-button"
                        >
                            Cancel
                        </StriimButton>
                    )}
                </Grid>
                <Grid item sx={footerClasses.buttonContainer}>
                    {hasBack && (
                        <StriimButton
                            variant="secondary"
                            onClick={onBack}
                            disabled={backDisabled}
                            sx={{ mr: 1 }}
                            data-test-id={"back-button"}
                        >
                            {backButtonText}
                        </StriimButton>
                    )}
                    {hasSave && (
                        <TooltipButton
                            variant="secondary"
                            sx={{ mr: 1 }}
                            onClick={onSave}
                            data-test-id={"save-button"}
                            tooltip={`Save and edit the ${
                                isAutomatedWizard() ? "Apps" : "App"
                            } later in Flow Designer.`}
                            disabled={saveDisabled}
                        >
                            Save & Exit
                        </TooltipButton>
                    )}
                    <TooltipButton
                        variant="primary"
                        onClick={onNext}
                        disabled={nextDisabled}
                        data-test-id={"next-button"}
                        tooltip={nextTooltip}
                    >
                        {next}
                    </TooltipButton>
                </Grid>
            </Grid>
            {modalVisible && (
                <DropAppModal
                    store={store}
                    app={appModel}
                    dropModalVisible={modalVisible}
                    setDropModalVisible={setModalVisible}
                    isILCDC={isAutomatedWizard()}
                />
            )}
        </>
    );
};

export default Footer;
