import App from "app";
import api from "core/api/api";
import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var module = {};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "DASHBOARD",
        title: String,
        defaultLandingPage: String,
        pages: Array,
        ctime: null,
        isPrebuilt: false,
    },
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model,
});

export default module;
