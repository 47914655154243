import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ spacing }) => ({
    userName: {
        margin: spacing(0)
    },
    accountRoot: {
        minWidth: spacing(19),
        maxWidth: spacing(24)
    },
    userNameWrapper: {
        display: "flex",
        flexDirection: "column"
    }
}));

export const sxStyles = {
    userNameListItem: {
        margin: 0,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
    }
};
