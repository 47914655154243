import SnowplowLibrary from "./tracker-subscribers/snowplow";

/**
 *
 * The tracker library can be used to track events in our webaction UI. This library is extendable and can take any number of
 *    subscribers and send tracking to all of them.
 *
 * Subscribers are 3rd party integrations that can do the tracking for us. As of now, Snowplow is the only subscriber.
 * We can add more subscribers as we build APIs for them - we never know, Webaction can be one of our tracking subscribers soon !!
 *
 *
 * @param subscribers array
 * TO USE:
 *
 * 1. track: track user activity for e.g.,
 * to track how a user created an app:
 * App.execute('tracker:track', "created new app", {type:'template'});
 * 2. register: Register properties which will be sent with every event:
 * App.execute('tracker:register', {"company" : "Striim"});
 * 3.setUserDetails: set properties used to describe a user e.g., signup date
 * App.execute('tracker:setUserDetails', {"Customer-type": "Paid"});
 */
var module = {};

module.libraries = {
    SNOWPLOW: SnowplowLibrary,
};

module.Tracker = function (subscribers) {
    subscribers = subscribers || [];
    this.addSubscribers(subscribers);
};

module.Tracker.prototype = {
    _subscribers: [],
    /**
     *  The track method loops through all the subscribers and publishes the track event on them. Each subsciber should
     *  implement subscribers/interface.js and should implement all the methods in that.
     * @param event_name
     * @param params
     */
    track: function (schemaName, params, version) {
        for (var i = 0; i < this._subscribers.length; i++) {
            this._subscribers[i].track(schemaName, params, version);
        }
    },
    pageView: function (params) {
        for (var i = 0; i < this._subscribers.length; i++) {
            this._subscribers[i].pageView(params);
        }
    },
    /***
     * The method to add the subscribers.
     *@param subscribers array
     */
    addSubscribers: function (subscribers) {
        for (var i = 0; i < subscribers.length; i++) {
            if (this._subscribers.indexOf(subscribers[i]) === -1) {
                this._subscribers.push(subscribers[i]);
                subscribers[i].initialize();
            }
        }
    },
};

export default {
    libraries: module.libraries,
    getInstance: function () {
        module.instance = module.instance || new module.Tracker();
        return module.instance;
    },
};