const mapOptions = options => {
    if (!options || options.length === 0) {
        return [];
    }

    return [...options]
        .map(option => {
            return {
                label: option.attributes.name,
                value: option.attributes.name
            };
        })
        .sort((a, b) => {
            return a?.label?.localeCompare(b?.label);
        });
};
export { mapOptions };
