import $ from "jquery";
import _ from "underscore";
import metaStoreService from "core/services/metaStoreService/meta-store-service";

export default function (type, options) {
    options = options || {};
    var deferred = $.Deferred();
    var results = [];

    metaStoreService.fetchIdentifiersByType(type).then(function(items) {
        items.forEach(function (model) {
            results.push({
                id: model.id,
                text: model.name,
                type: model.type,
                refModel: model,
                description: model.nsName,
            });
        });

        deferred.resolve(
            _.sortBy(results, function (item) {
                return item.text;
            })
        );
    });

    return deferred.promise();
}
