import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import BaseEditor from "app/components/common/editor/meta-object-editors/base";
import RouterConditionEditor from "../control/router-condition-editor/router-condition-editor";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import MetaObjectDataSource from "../control/select/metaobject-datasource";

var Module = function(metadata_object, options) {
    options = options || {};
    options.displayFields = ["inStream", "alias", "typeViewer", "details"];

    this.typeViewer = UIControl.TypeViewer.extend({
        hideLabel: true,
        collapsed: true,
        fullWidth: true
    }).create();

    options.StaticControls = {
        typeViewer: this.typeViewer
    };

    BaseEditor.apply(this, arguments);
};

Module.prototype = _.extend({}, BaseEditor.prototype, {
    addCustomFields: function() {
        const streams = new MetaObjectDataSource([metaStoreService.entities.STREAM]);
        this.fieldMapper.inStream = UIControl.Select(streams, {
            type: metaStoreService.entities.STREAM
        }).create();

        this.fieldMapper.alias = UIControl.TextField.create();

        this.fieldMapper.inStream.on(UIControl.EVENT.VALUE_CHANGED, streamId => {
            this.typeViewer.viewTypeOf(streamId);
            if (streamId) {
                metaStoreService.findById(streamId).then(stream => {
                    this.fieldMapper.details.setType(stream?.dataType, true);
                });
            } else {
                this.fieldMapper.details.setType(null, true);
            }
        });

        this.fieldMapper.details = RouterConditionEditor.extend({
            hideLabel: true
        }).create();

        this.fieldMapper.details.on("tqlModeChanged", () => {
            this.toggleAliasField(true);
        });
    },

    postRender: function() {
        if (!this.MetaObjectModel.inStream) {
            this.fieldMapper.details.setType(null, true);
            this.toggleAliasField(false);
            return;
        }
        metaStoreService.findById(this.MetaObjectModel.inStream).then(stream => {
            this.typeViewer.viewTypeOf(this.MetaObjectModel.inStream).always(() => {
                this.Form.view.clearDirty(this.typeViewer.model);
            });

            this.fieldMapper.details.setType(stream.dataType, false);
        });

        this.fieldMapper.details.setForwardingRules(this.MetaObjectModel.forwardingRules);
        if (!this.MetaObjectModel.id) {
            this.toggleAliasField(false);
        } else {
            if (
                this.MetaObjectModel.uiconfig.conditionalOutputStreams &&
                this.MetaObjectModel.uiconfig.conditionalOutputStreams.length > 0
            ) {
                this.fieldMapper.details.setValue(this.MetaObjectModel.uiconfig);
                this.toggleAliasField(false);
            } else {
                this.toggleAliasField(true);
                this.fieldMapper.alias.setValue(this.MetaObjectModel.uiconfig.alias);
            }
        }
    },

    toggleAliasField: function(tqlMode) {
        const closestEditor = this.fieldMapper.alias.$el.closest(".field-row");
        closestEditor.toggle(tqlMode);
    }
});

export default Module;
