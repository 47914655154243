import App from "app";
import api from "core/api/api";
import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var module = {};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "CACHE",
        adapter: Object,
        parser: Object,
        queryProperties: Object,
        typename: String,
        isEventTable: false,
    },

    save: function () {
        if (this.queryProperties && typeof this.queryProperties.persistPolicy !== "undefined") {
            this.queryProperties.persistPolicy = this.queryProperties.persistPolicy.toString();
        }

        if (this.queryProperties && typeof this.queryProperties.skipinvalid !== "undefined") {
            this.queryProperties.skipinvalid = this.queryProperties.skipinvalid.toString();
        }

        return baseMetaObject.Model.prototype.save.apply(this, arguments);
    },
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model,
});

export default module;
