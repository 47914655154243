import React, { useContext, useEffect, useState } from "react";
import api from "../../../../core/api/api";
import growl from "../../../../app/components/common/growl";
import { components } from "react-select";
import { Box, Grid } from "@mui/material";
import { styles } from "./select-workspace.styles";
import { StriimInputField, StriimTypography, StriimCircularLoader } from "@striim/striim-ui-v2";
import {
    SelectWorkspaceProps,
    optionsType,
    APIResponseType,
    APICAllStatus,
    APIWorkspaceObjectType,
    CustomMenuListProps,
    workspaceObject
} from "./select-workspace-types.types";
import { CPInlineDocContext } from "../../../modules/apps/pages/manage-striim/connection-profiles/create-edit-connection-profile/create-edit-connection-profile";

const initialDefaultAPIResponse = {
    error: null,
    status: APICAllStatus.NOT_STARTED,
    data: null
};

const SelectWorkspaceInputField: React.FC<SelectWorkspaceProps> = props => {
    const { onChange, value, error, errorText, name, label, placeholder, formRef, enabled } = props;
    const [selectedWorkSpace, setSelectedWorkSpace] = useState<optionsType>(null);
    const [apiResponse, setApiResponse] = useState<APIResponseType>(initialDefaultAPIResponse);
    const { connectionProfile } = useContext(CPInlineDocContext);

    useEffect(() => {
        if (!value) {
            setSelectedWorkSpace(null);
        } else {
            if (typeof value === "string") {
                const res = JSON.parse(value);
                setSelectedWorkSpace({
                    label: res.Workspace,
                    value: res.WorkspaceId
                });
            } else if (typeof value === "object") {
                setSelectedWorkSpace({ label: value.Workspace, value: value.WorkspaceId });
            }
        }
    }, [value]);

    async function handleWorkSpaceFetch(): Promise<void> {
        try {
            const { authenticationType } = formRef.current.values;

            if ((selectedWorkSpace && apiResponse.data?.length >= 1) || apiResponse.status === APICAllStatus.FETCHING) {
                return;
            }

            setApiResponse((prevState: APIResponseType) => ({
                ...prevState,
                status: APICAllStatus.FETCHING,
                data: null
            }));

            const workSpacesRes = await api.FabricMirrorGetWorkspaces({
                ...formRef?.current?.values,
                cpUUID: connectionProfile?.get("uuid") ?? null,
                authenticationType: authenticationType ? authenticationType.value : null
            });
            const workSpacesObj = JSON.parse(workSpacesRes);

            if (workSpacesObj.result === true) {
                const workspaceList: Array<APIWorkspaceObjectType> = JSON.parse(workSpacesObj.message);
                const workSpacesSelectOptions: Array<optionsType> = workspaceList.map(workspaceData => {
                    return {
                        label: workspaceData.workSpaceName,
                        value: workspaceData.workSpaceID
                    };
                });

                setApiResponse({
                    status: APICAllStatus.SUCCESS,
                    data: workSpacesSelectOptions,
                    error: null
                });
            } else {
                throw workSpacesObj?.message ?? "Error fetching workspaces";
            }
        } catch (e) {
            const errorMessage = e instanceof Error ? e.message : e;
            growl.error(errorMessage);
            setApiResponse({
                status: APICAllStatus.FAILURE,
                data: null,
                error: errorMessage
            });
        }
    }

    return (
        <Box mt={2}>
            <Box mt={1.25} onClick={handleWorkSpaceFetch}>
                <StriimInputField
                    disabled={!enabled}
                    name={name}
                    label={label}
                    required
                    error={!!apiResponse.error || error}
                    placeholder={placeholder}
                    helperText={apiResponse.error ?? errorText}
                    SelectProps={{
                        options: apiResponse.data ?? [],
                        isClearable: false,
                        menuPosition: "absolute",
                        components: {
                            IndicatorSeparator: () => null,
                            CrossIcon: () => null,
                            MenuList: menuListProps => <CustomMenuList apiResponse={apiResponse} {...menuListProps} />,
                            NoOptionsMessage: () => <CustomNoOptionsMessage apiResponse={apiResponse} />
                        },
                        menuShouldScrollIntoView: false,
                        menuPortalTarget: document.body,
                        zIndex: 1502
                    }}
                    value={selectedWorkSpace ?? null}
                    select
                    onChange={(val: optionsType) => {
                        const workSpaceObject: workspaceObject = {
                            Workspace: val.label,
                            WorkspaceId: val.value
                        };
                        setSelectedWorkSpace(val);
                        onChange(workSpaceObject);
                    }}
                />
            </Box>
        </Box>
    );
};

export default SelectWorkspaceInputField;

const CustomMenuList: React.FC<CustomMenuListProps> = props => {
    const apiResponse = props.apiResponse;
    return <components.MenuList {...props}>{props.children}</components.MenuList>;
};
const CustomNoOptionsMessage: React.FC<{ apiResponse: APIResponseType }> = props => {
    const apiResponse = props.apiResponse;
    return (
        <Box sx={styles.noOptionsContainer}>
            {apiResponse.status === APICAllStatus.FETCHING ? (
                <Grid sx={styles.loaderContainer}>
                    <StriimCircularLoader size={20} thickness={8} />
                    <StriimTypography variant="body4" sx={styles.infoText}>
                        Retrieving Workspaces
                    </StriimTypography>
                </Grid>
            ) : (
                apiResponse.status === APICAllStatus.SUCCESS && (
                    <Box>
                        <StriimTypography variant="body4" sx={styles.infoText}>
                            No Workspaces available
                        </StriimTypography>
                    </Box>
                )
            )}
        </Box>
    );
};
