/**
 *  Just passes the tracking info to the snowplow library.
 */
/* global snowplow */
import trackerInterface from "./base-subscriber";
import { newTracker, trackPageView, trackSelfDescribingEvent } from "@snowplow/browser-tracker";
import SaaSCookieExists from "../SaaSCookieExists";
import trackingCookieExists from "../trackingCookieExists";

var Snowplow_library = {};
var collector = "https://prodsnowplow.striim.com";

Snowplow_library.initialize = function() {
    newTracker("snowplow", collector, {
        appId: trackingCookieExists() && !SaaSCookieExists() ? "striim-developer" : "striim-saas",
        discoverRootDomain: true,
        cookieSameSite: "Lax"
    });
};

/**
 * Creates tracker instance
 * @param {*} namespace - tracker namespace
 * @param {*} appId - application ID
 * @param {*} collector -  collector endpoint
 */
Snowplow_library.newTracker = (namespace, appId) => {
    newTracker(namespace, collector, { 
        appId: appId, 
        discoverRootDomain: true,
        cookieSameSite: 'Lax'
    });
}

/**
 * Track custom self-describing events
 * @param {*} schemaName - schema name
 * @param {*} params - properties of the event
 * @param {*} version - schema version
 */
Snowplow_library.track = (schemaName, params, version = "1-0-0") => {
    trackSelfDescribingEvent({ 
        event: {
            schema: `iglu:com.striim/${schemaName}/jsonschema/${version}`,
            data: params
        }
    });
}; 

/**
 * Tracks page views
 * Records the maximum scroll left / right and up / down in the last ping period
 */
Snowplow_library.pageView = () => { 
    trackPageView();
};


Snowplow_library = Object.assign({}, trackerInterface, Snowplow_library);

export default Snowplow_library;