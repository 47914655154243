import { Box, Grid, SvgIcon } from "@mui/material";
import { StriimButton, StriimTypography } from "@striim/striim-ui-v2";
import React, { useContext, useEffect } from "react";
import TableSummary from "../tables-summary/tables-summary";
import { styles } from "./review.styles";
import {
    getSourceTables,
    getTargetDetails,
    getSourceDetails,
    getAdvancedDetails,
    getSSLDetails
} from "./review.helper";
import PredefinedComponentNames from "../../../../../app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import { DB_PROVIDERS } from "../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/configs/db-providers.js";
import navigateTo from "../../../../navigate-to";
import { Steps } from "../../target/target-steps";
import { WizardContext } from "../../wizard-context";
import { InfoCircle, InfoEdit } from "../../../../generic/icon/customIcons";
import { getDatabaseType } from "app/components/appwizard/component-wizards/wizards/common/databaseTypes.js";
import { TYPES } from "../../utils/wizard-types";
import useConnectionProfileData from "./useConnectionProfileData.hooks";
import WithTooltip from "../../../../generic/tooltip/tooltip";
import {
    getTruncatedName,
    isAutomatedWizard
} from "../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";
import TableEditComponent from "./editable-target-table";
import { fetchHideURLStatus } from "../../utils/encrypt-url-utils";
import { validationUtils } from "../../utils";
import Tracker from "../../../../../core/services/tracker/tracker";

import DownloadIcon from "src/generic/icon/wizard-icon/Download.svg";
import {
    downloadTablesReport,
    generateTablesReport
} from "../../../apps/pages/app-list/components/automated-app-group-detail/services/download-reports.service";
import utils from "../../../../../core/utils";

interface SectionProps {
    title: string;
    children?: React.ReactNode;
    editNavigateTo: string;
    target?: boolean;
    editable?: boolean;
    isPDFReport?: boolean;
    customComponent?: React.ReactNode;
}
const Section = ({
    title,
    children,
    editNavigateTo,
    target = null,
    editable = true,
    customComponent,
    isPDFReport = false
}: SectionProps) => {
    const { wizardAppName, templateID, setCurrentStep, ...rest } = useContext(WizardContext);
    const isEditable = editable && editNavigateTo;

    return (
        <Grid>
            <StriimTypography variant="h3" sx={styles.sectionTitle(isPDFReport)}>
                {title}
                {isEditable && (
                    <StriimButton
                        sx={{ marginLeft: 1 }}
                        onClick={() => {
                            if (target) {
                                setCurrentStep(editNavigateTo);
                            } else {
                                const navigateParams = {
                                    appName: wizardAppName,
                                    templateId: templateID,
                                    stepName: editNavigateTo,
                                    force: true
                                };
                                navigateTo.AppWizardTemplate(navigateParams);
                            }
                        }}
                    >
                        <SvgIcon component={InfoEdit} sx={styles.editIcon} />
                    </StriimButton>
                )}
                {customComponent && customComponent}
            </StriimTypography>
            <Box sx={{ marginTop: 1.5 }}>{children}</Box>
        </Grid>
    );
};
const DownloadSourceTableComponent = ({ sourceInfo }) => {
    const handleDownload = async () => {
        const groupName = getTruncatedName(utils.getName(sourceInfo.appId));
        const csvBlob = await generateTablesReport(sourceInfo.sourceAdapter, groupName);
        await downloadTablesReport(groupName, csvBlob);
    };
    return (
        <StriimButton sx={{ marginLeft: 1 }} onClick={handleDownload}>
            <SvgIcon component={DownloadIcon} sx={styles.editIcon} />
        </StriimButton>
    );
};

const DataTable = ({ title = null, children }) => {
    return (
        <div>
            <Box sx={{ mb: 2 }}>
                <StriimTypography variant="h5" sx={styles.dataTitle}>
                    {title}
                </StriimTypography>
            </Box>
            <Grid sx={styles.grid}>{children}</Grid>
        </div>
    );
};

const TooltipComponent = ({ text }) => {
    return WithTooltip(<InfoCircle style={styles.tooltipIcon} />, text);
};

const DataRecord = ({ title, data = null, isTitle = false, tooltip = null, isSubComponent = false }) => {
    return (
        <>
            <StriimTypography
                variant="body4"
                sx={isTitle ? styles.recordTitle : styles.title}
                ml={isSubComponent ? 2 : 0}
            >
                {title}
                {tooltip && <TooltipComponent text={tooltip} />}
            </StriimTypography>
            <StriimTypography variant="body3" sx={styles.record}>
                {!tooltip && data}
            </StriimTypography>
        </>
    );
};

const TablesList = ({ table, name }) => {
    return (
        <Grid sx={styles.tableWrapper}>
            <Grid sx={styles.tableTitle}>
                <StriimTypography variant="body4" sx={styles.tableText}>
                    List of tables to load from {name}: {table.length}
                </StriimTypography>
            </Grid>
            <Grid sx={styles.tablesList}>
                {table.map((table, index) => (
                    <StriimTypography variant="body4" sx={styles.tableText} key={index}>
                        {table}
                    </StriimTypography>
                ))}
            </Grid>
        </Grid>
    );
};

const getChannelName = cdcSourceInfo => {
    const channel = cdcSourceInfo?.sourceAdapter?.properties?.customChannel;
    if (!channel) return "ChangeEvents";
    return channel;
};

interface ReviewProps {
    sourceInfo: Record<string, any>;
    targetInfo: Record<string, any>;
    cdcSourceInfo: Record<string, any>;
    target: string;
    isPDFReport?: boolean;
}

const Review = ({ sourceInfo, targetInfo, target, isPDFReport = false, cdcSourceInfo, ...rest }: ReviewProps) => {
    const { wizardType } = useContext(WizardContext);
    const sourceData = sourceInfo.sourceAdapter.properties;
    const targetConnectionProfileData = useConnectionProfileData(targetInfo.targetFormValues);
    const sourceConnectionProfileData = useConnectionProfileData(sourceData);
    const groupName = getTruncatedName(sourceInfo?.appName);

    let sourceDB, sourceType, sourceTableWrapper, sourceName;
    if (sourceData.adapterName === "DatabaseReader") {
        sourceDB = sourceData.DatabaseProviderType;
        sourceType = DB_PROVIDERS[sourceDB.toUpperCase()]?.whatIsTable ?? "Table";
        sourceTableWrapper = DB_PROVIDERS[sourceDB.toUpperCase()]?.tableWrapper;
    } else {
        sourceDB = sourceData.adapterName;
        sourceType = DB_PROVIDERS[getDatabaseType(sourceDB)]?.whatIsTable ?? "Table";
        sourceTableWrapper = DB_PROVIDERS[getDatabaseType(sourceDB)]?.tableWrapper;
    }
    if (isAutomatedWizard()) {
        sourceName = cdcSourceInfo?.adapterName;
    } else {
        sourceName = sourceDB;
    }
    const pluralizeTableWrapper = sourceTableWrapper + "s";

    const targetDB = PredefinedComponentNames[target]?.name;

    const AppName = sourceInfo?.appId.split(".APPLICATION.").join(".");
    const sourceDetails = getSourceDetails(sourceDB, sourceData, sourceConnectionProfileData) ?? [];
    const targetDetails = getTargetDetails(targetDB, targetInfo.targetFormValues, targetConnectionProfileData) ?? [];
    const advancedDetails = getAdvancedDetails(targetDB, targetInfo.targetFormValues, targetConnectionProfileData);
    const [sourceSSLDetails, targetSSLDetails] = getSSLDetails(
        AppName,
        sourceData.adapterName === "DatabaseReader"
            ? sourceData.DatabaseProviderType
            : getDatabaseType(sourceData.adapterName),
        getDatabaseType(target)
    );
    // const showDB = hasDatabase(sourceDB);
    const hideURL = fetchHideURLStatus(AppName);

    const { Schemas, Tables } = getSourceTables(sourceInfo.sourceAdapter, sourceType === "Object");

    const tableData =
        sourceType === "Table"
            ? Schemas.map(schema => {
                  return {
                      label: <StriimTypography variant="body4">{schema}</StriimTypography>,
                      component: <TablesList table={Tables?.[schema]} name={schema} />
                  };
              })
            : Schemas;
    const showTables = tableData.length && !isPDFReport;

    const Channel = cdcSourceInfo?.adapterName === "SalesforceCDCReader" ? getChannelName(cdcSourceInfo) : null;

    const ConnectionURL = ({ hideURL }) => {
        if (!validationUtils.isRDBMS(sourceDB)) return;
        if (isAutomatedWizard() && hideURL === false) return;

        if (hideURL) {
            return (
                <DataRecord
                    title={"Connection URL"}
                    tooltip={"Connection URL is hidden since it has encypted password and secret keys"}
                />
            );
        } else {
            if (sourceName === "OJet") {
                return (
                    <>
                        <DataRecord title={"IL Connection URL"} data={sourceData.ConnectionURL} />
                        <DataRecord
                            title={"CDC Connection URL"}
                            data={cdcSourceInfo?.sourceAdapter?.properties?.ConnectionURL}
                        />
                    </>
                );
            } else {
                return <DataRecord title={"Connection URL"} data={sourceData.ConnectionURL} />;
            }
        }
    };

    useEffect(() => {
        Tracker.getInstance().track("wizards-review", {
            event: "Wizards:Review",
            isAutomatedILCDC: isAutomatedWizard(),
            sourceDB: sourceDB,
            sourceType: sourceType,
            tableCount: tableData.length,
            targetDB: targetDB,
            wizardType: wizardType
        });
    }, []);

    return (
        <Grid component="ol" sx={styles.reviewWrapper}>
            {isPDFReport && (
                <>
                    <Box sx={styles.striimLogo}>
                        <img src={"app/images/striim-logo-whitebg.png"} alt="striim-logo" />
                    </Box>
                    <StriimTypography variant={"h1"} sx={{ wordBreak: "break-word" }}>
                        {"Automated Pipeline Configuration - "}
                        {groupName}
                    </StriimTypography>
                </>
            )}
            <Section
                isPDFReport={isPDFReport}
                title={`1. Source Configuration (${sourceName})`}
                editable={!isPDFReport}
                editNavigateTo="source-info"
            >
                <DataTable>
                    <ConnectionURL hideURL={hideURL} />
                    {[...sourceDetails, ...sourceSSLDetails].map(
                        (data, index) =>
                            data.value && (
                                <DataRecord key={index} title={data.name} data={data.value} tooltip={data.tooltip} />
                            )
                    )}
                </DataTable>
            </Section>
            {showTables ? (
                <Section
                    title={`2. ${sourceType}s to be synced to ${targetDB}`}
                    editable={!isPDFReport}
                    editNavigateTo={"select-" + pluralizeTableWrapper}
                    customComponent={<DownloadSourceTableComponent sourceInfo={sourceInfo} />}
                >
                    <TableSummary tableData={tableData} isObject={sourceType === "Object"} channel={Channel} />
                </Section>
            ) : null}
            <Section
                isPDFReport={isPDFReport}
                title={`${isPDFReport ? 2 : 3}. Target Configuration (${targetDB})`}
                editable={!isPDFReport}
                editNavigateTo={Steps.ConfigureTarget}
                target={true}
            >
                <Grid sx={styles.targetConfiguration}>
                    <DataTable title={"CONNECTION DETAILS"}>
                        {[...targetDetails, ...targetSSLDetails].map(
                            (data, index) =>
                                data.value && <DataRecord key={index} title={data.name} data={data.value} />
                        )}
                    </DataTable>
                    <TableEditComponent isPDFReport={isPDFReport} {...rest} />
                    {["OutputMySQLDatabase", "MariaDBDatabase"].includes(target) && (
                        <DataTable title={"RECOVERY SETTINGS"}>
                            <DataRecord
                                title={"Checkpoint Table Name"}
                                data={targetInfo.targetFormValues?.CheckPointTable}
                                isTitle={false}
                            />
                        </DataTable>
                    )}
                    {wizardType !== TYPES.InitialLoad && wizardType !== TYPES.Automated_Mode_App_Adapter && (
                        <DataTable title={"ADVANCED SETTINGS"}>
                            {advancedDetails.map(
                                (data, index) =>
                                    (data.value || data.isTitle) && (
                                        <DataRecord
                                            key={index}
                                            title={data.name}
                                            data={data.value}
                                            isTitle={data.isTitle}
                                            isSubComponent={data.isSubComponent}
                                        />
                                    )
                            )}
                        </DataTable>
                    )}
                </Grid>
            </Section>
        </Grid>
    );
};

export { Review, DataTable, DataRecord };
