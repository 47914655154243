import React, { useRef, useImperativeHandle, forwardRef, useEffect } from "react";
import { StriimFormBuilder, FIELD_TYPES, StriimTheme, StriimTooltip, StriimMessageBox } from "@striim/striim-ui";
import FileBrowser from "../filebrowser-v2/filebrowser";
import _ from "underscore";
import Password from "../fd-password/fd-password";
import xss from "xss";
import ConnectionProfileSelector from "../connection-profile-selector/connection-profile-selector";
import sxClasses from "./segmentation.styles";
import { SvgIcon } from "@mui/material";
import JsonInput from "../json-input/json-input";
import InfoIcon from "app/images/striimline/info-icon.svg";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import utils from "core/utils";
import ActionButton, { TEST_PRIMARY_CONNECTION } from "./action-button";
import { PRESERVED_NAMES } from "core/constants";
import App from "app";

const processMapAndJsonValue = (jsonOrMapFields, key, values) => {
    let UIConfig = jsonOrMapFields[key];
    if (UIConfig.type === "MAP") {
        let strings = [];
        values.forEach(entry => {
            if (entry && entry["key"] && entry["value"] !== null && entry["value"] !== "") {
                strings.push([entry["key"], entry["value"]].join(UIConfig.columnDelimiter));
            }
        });
        let value = strings.join(UIConfig.rowDelimiter);
        return value;
    }
    if (UIConfig.type === "JSON") {
        let storedValue = {};
        values.forEach(entry => {
            if (entry && entry["key"] && entry["value"] !== null && entry["value"] !== "") {
                storedValue[entry["key"]] = entry["value"];
            }
        });
        return JSON.stringify(storedValue);
    }
    return "";
};

const FormBuilder = (props, ref) => {
    const { initialValues, formFields, propertiesModel, jsonOrMapFields, handler, isFormEnabled, metaObjectId } = props;
    const formRef = useRef();
    const triggerRef = useRef(null);
    const adapterName = handler ? utils.getName(handler) : "";

    if (adapterName) {
        let buttons = _.filter(propertyTemplateService.propertyActionButtons, function(item) {
            return item.adapterName === adapterName;
        });

        buttons.forEach(function(button) {
            const { disabled, group, viewWeight, visibility, name } = button;
            formFields.push({
                disabled,
                group,
                viewWeight,
                visibility,
                required: name !== TEST_PRIMARY_CONNECTION,
                type: "component",
                component: (
                    <ActionButton
                        button={button}
                        handler={handler}
                        propertiesModel={propertiesModel}
                        metaObjectId={metaObjectId}
                        isFormEnabled={isFormEnabled}
                        formRef={formRef}
                    />
                ),
                isActionBtn: true
            });
        });
    }
    useEffect(() => {
        App.vent.on("save:adapter:defaults", function() {
            const stringifiedValues = getValues();
            propertiesModel.set(stringifiedValues);
        });
        return () => {
            App.vent.off("save:adapter:defaults");
        };
    }, []);
    function getValues(formValues = null) {
        let stringifiedValues = {};
        const values = formValues ?? formRef.current.values;
        Object.keys(values).forEach(key => {
            if (key === "" || key === "undefined" || key === "null") {
                return;
            }
            if (Array.isArray(values[key]) && jsonOrMapFields[key] !== undefined) {
                let processedValue = processMapAndJsonValue(jsonOrMapFields, key, values[key]);
                return (stringifiedValues[key] = processedValue);
            }
            if (typeof values[key] === "object" && values[key]["value"] !== undefined) {
                return (stringifiedValues[key] = values[key]?.value);
            }
            stringifiedValues[key] = values[key];
        });
        return stringifiedValues;
    }

    function onChange(values) {
        //onchange is called initially since we are changing the values before setting to the form
        if (!triggerRef.current) {
            triggerRef.current = true;
            return;
        }
        let stringifiedValues = getValues(values);
        if (_.isEqual(stringifiedValues, initialValues)) {
            return;
        }
        propertiesModel.set(stringifiedValues);
    }
    function validateForm() {
        return formRef.current
            .submitForm()
            .then(formRef.current.validateForm)
            .then(errors => {
                return errors;
            });
    }

    useImperativeHandle(ref, () => ({
        validateForm
    }));

    return (
        <StriimTheme preselector=".striim.materialize.light:not(#\20)">
            <StriimFormBuilder
                formProps={{}}
                onChange={onChange}
                initialFieldValues={initialValues}
                data={formFields}
                validateOnChange={false}
                previewPassword={true}
                setFieldToDefaultOnHidden={true}
                formRef={formRef}
                customFields={{
                    [FIELD_TYPES.CONNECTIONPROFILE]: props => <ConnectionProfileSelector {...props} />,
                    [FIELD_TYPES.FILE]: props => <FileBrowser directoryOnly={false} {...props} />,
                    [FIELD_TYPES.JSON]: props => <JsonInput {...props} />,
                    [FIELD_TYPES.PROPERTYTEMPLATE_PASSWORD]: props => <Password {...props} />
                }}
                preservedNames={PRESERVED_NAMES}
                usePropertyPanelLayout
            />
        </StriimTheme>
    );
};
export default forwardRef(FormBuilder);

const classes = {
    tooltipIcon: {
        height: "15px",
        width: "15px",
        fill: "none",
        cursor: "pointer"
    }
};

export const TooltipComponent = ({ description }) => {
    return (
        <>
            {description && (
                <StriimTooltip
                    title={<div style={sxClasses.fdTooltip} dangerouslySetInnerHTML={{ __html: xss(description) }} />}
                    arrow
                    placement="left"
                >
                    <div style={{ display: "flex" }}>
                        <SvgIcon component={InfoIcon} sx={classes.tooltipIcon} />
                    </div>
                </StriimTooltip>
            )}
        </>
    );
};
