import React from "react";
import { useField } from "formik";
import { StriimInputField } from "@striim/striim-ui";

const FormikField = ({ helperText, setFieldValue, ...props }) => {
    const [field, meta] = useField(props.name);
    return (
        <StriimInputField
            {...field}
            {...props}
            error={!!meta.error && meta.touched}
            helperText={(meta.touched && meta.error) || helperText}
            onChange={value => props.onChange(value, setFieldValue)}
        />
    );
};

export default FormikField;
