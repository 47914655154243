
import App from "../../../app";
import React, {useEffect, useRef} from "react";
import { Controller } from "../../../app/components/preview/preview";
import { useReactHeader } from "../../hooks/useReactHeader";
import { useLocation } from "react-router-dom";
import Backbone from "backbone";

const PreviewFile = () => {
    useReactHeader({title: "Source Preview"})
    const useInitializedRef = useRef(false);
    let {pathname, search} = useLocation();
    const path = decodeURIComponent(search.slice(1))
    const index = pathname.lastIndexOf('/');
    const type = pathname.slice(index+1);

    useEffect(() => {
        if(useInitializedRef.current){
            return;
        }
        const controller = new Controller();
        const model = new Backbone.Model({
            fileType: type,
            previewPath: path
        });
    
        controller.renderPreviewFile(model);
        useInitializedRef.current = true;
    }, [])

    return <></>
}

export default PreviewFile;

