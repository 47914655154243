import App from "app";
import utils from "core/utils";
import "underscore";
import NestedTypes from "backbone.nestedtypes";

export default App.module("Metadata.DefaultMetaObject", function (DefaultMetaObject, App, Backbone, Marionette, $, _) {
    DefaultMetaObject.Model = NestedTypes.Model.extend({
        idAttribute: "id",
        defaults: {
            type: String,
            name: String,
            nsName: String,
            namespaceId: String,
            metaObjectClass: String,
            metaInfoStatus: null,
            description: "",
            uri: String,
            uuid: null,
        },
        initialize: function () {
            this.set_ids();
            this.listenTo(this, "change:name change:nsName change:id", this.set_ids);

            // Basic relational attributes support.
            // TODO: if we can find a plugin (like Backbone.Relational) that 1.) Does what we need and 2.) will play nice with NestedTypes, let's use it. Until then, this is better than nothing.
            if (this.relations) {
                _(this.relations).each(
                    function (relation, attr) {
                        if (this.get(attr) instanceof Backbone.Collection) {
                            var method = _(relation.action).isFunction() ? relation.action : this[relation.action];
                            method = _(method).bind(this);
                            this.get(attr).each(method);
                            this.listenTo(this.get(attr), "add", method);
                        }
                    }.bind(this)
                );
            }
        },
        // If only ID was specified but not name or nsName (or vice versa), fill in the others
        set_ids: function () {
            if (!this.get("nsName") && this.get("id")) {
                this.set("nsName", utils.getNamespace(this.get("id")));
            }
            if (!this.get("name") && this.get("id")) {
                this.set("name", utils.getName(this.get("id")));
            }
            if (!this.get("id") && this.get("nsName") && this.get("name")) {
                this.set("id", utils.getFQN(this));
            }
        },
    });
});
