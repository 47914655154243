import $ from "jquery";

var MouseClickHandler = function ($targetElement, onClick, onMove) {
    var isMoving = false;

    $targetElement.mousedown(function () {
        isMoving = false;
    });
    $targetElement.mousemove(function () {
        isMoving = true;
    });
    $targetElement.mouseup(function (e) {
        if (isMoving) {
            if (onMove) {
                onMove.apply($targetElement[0], arguments);
            }
            return;
        }

        if (onClick) {
            onClick.apply($targetElement[0], arguments);
        }
    });
};

export default MouseClickHandler;
