export const styles = {
    settingsIcon: { height: "20px", width: "auto", marginTop: "2px", marginRight: "4px", "& > path": { fill: "#000" } },
    installationGuideLink: { display: "flex", justifyContent: "flex-start", mt: -2, ml: 2 },
    noSpaceLabel: { pb: 0, mb: 0 },
    controlLabel: { ".MuiTypography-root": { color: "greyscale.700" } },
    icon: { width: "18px", height: "auto" },
    logoIcon: {
        width: 20,
        height: 20
    },
    closeIcon: {
        width: 20,
        height: 20,
        cursor: "pointer"
    },
    footerContainer: {
        padding: ({ spacing }) => spacing(3, 3, 3, 2),
        borderTop: ({ palette }) => `1px solid ${palette.greyscale["200"]}`,
        position: "absolute",
        bottom: 0,
        left: 0
    },
    input: { marginBottom: 2 },
    customSelector: {
        width: 400,
    },
    messageBox: {
        marginLeft: 3,
        backgroundColor: "critical.50",
        borderColor: "critical.100",
        marginBottom: ({ spacing }) => spacing(3),
        "& svg": {
            fontSize: "20px",
            fill: "#DB2A4A"
        },
        "& > div:first-child": {
            px: 2,
            py: 1,
            alignItems: 'center',
        }
    },
    documentationIcon: {
        color: "#FFF",
        fontSize: "14px",
        display: "flex",
        marginLeft: ({ spacing }) => spacing(1)
    }
};
