import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { StriimTypography } from "@striim/striim-ui";

const useStyles = makeStyles(({ spacing }) => ({
    selected: {
        background: "rgba(0, 167, 229, 0.16)"
    },
    section: {
        height: spacing(10),
        padding: spacing(2, 2, 2, 3),
        marginInline: `-${spacing(3)}px`
    },
    displayBlock: {
        display: "block"
    }
}));

const NavSection = ({ section, selectedSection, caption, title }) => {
    const [isSelected, setIsSelected] = useState(false);
    const classes = useStyles();
    useEffect(() => {
        if (selectedSection.toLowerCase() === section.toLowerCase()) {
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    }, [selectedSection]);
    return (
        <Link to={`/manage-striim/${section}`}>
            <div className={isSelected ? `${classes.selected} ${classes.section}` : classes.section}>
                <StriimTypography variant="bodySemibold" className={classes.displayBlock}>
                    {title}
                </StriimTypography>
                <StriimTypography variant="caption" className={classes.displayBlock}>
                    {caption}
                </StriimTypography>
            </div>
        </Link>
    );
};

export default NavSection;
