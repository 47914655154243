import AccountTreeOutlined from "./customIcons/AccountTreeOutlined.svg";
import Add from "./customIcons/Add.svg";
import AlertCircle from "./customIcons/AlertCircle.svg";
import AlertManagerStandardEnterprise from "./customIcons/AlertManager_standard_enterprise.svg";
import ArrowForwardBlue from "./customIcons/Arrow-forward.svg";
import ArrowUpward from "./customIcons/arrow_upward.svg";
import ArrowDownward from "./customIcons/arrow_downward.svg";
import ArrowUpRightCircle from "./customIcons/arrow_up_right_circle.svg";
import BidirectionalEnterpriseEnterpriseplus from "./customIcons/Bidirectional_enterprise_enterpriseplus.svg";
import BidirectionalStandard from "./customIcons/Bidirectional_standard.svg";
import BellIcon from "./customIcons/Bell.svg";
import CancelOutlined from "./customIcons/cancel_outlined.svg";
import CheckCircle from "./customIcons/CheckCircle.svg";
import DashboardsStandard from "./customIcons/Dashboards_standard.svg";
import DashboardsEnterprise from "./customIcons/Dashboards_enterprise.svg";
import Documentation from "./customIcons/Documentation.svg";
import Edit from "./customIcons/edit.svg";
import EditSsoIcon from "./customIcons/edit_icon.svg";
import EveenttableStandard from "./customIcons/Eveenttable_standard.svg";
import ExceptionStoreStandard from "./customIcons/ExceptionStore_standard.svg";
import ExternalCacheStandard from "./customIcons/ExternalCache_standard.svg";
import FlmStandard from "./customIcons/FLM_standard.svg";
import MLfunctionsStandardEnterprise from "./customIcons/MLfunctions_standard_enterprise.svg";
import MetadatamanagerStandard from "./customIcons/Metadatamanager_standard.svg";
import MonitorTimeSeriesReportStandard from "./customIcons/MonitorTimeSeriesReport_standard.svg";
import MultinodeSupportStandardEnterprise from "./customIcons/MultinodeSupport_standard_enterprise.svg";
import OpenProcessorStandard from "./customIcons/OpenProcessor_standard.svg";
import P2DataMaskingStandard from "./customIcons/P2DataMasking_standard.svg";
import PartitionedStreamStandardEnterprise from "./customIcons/PartitionedStream_standard_enterprise.svg";
import PartitionedWindowEnterprise from "./customIcons/PartitionedWindow_enterprise.svg";
import PartitionedWindowStandard from "./customIcons/PartitionedWindow_standard.svg";
import PeopleAlt from "./customIcons/people_alt.svg";
import Rule from "./customIcons/rule.svg";
import ManageAccounts from "./customIcons/manage_accounts.svg";
import PersistentStreamStandardEnterprise from "./customIcons/PersistentStream_standard_enterprise.svg";
import RestapiStandard from "./customIcons/Restapi_standard.svg";
import RouterStandard from "./customIcons/Router_standard.svg";
import ValidationStandardEnterprise from "./customIcons/Validation_standard_enterprise.svg";
import WactionStoreStandard from "./customIcons/WactionStore_standard.svg";
import WactionStoreEnterprise from "./customIcons/WactionStore_enterprise.svg";
import ApplicationProgress from "./customIcons/application_progress.svg";
import ExceptionsStore from "./customIcons/exceptions-store.svg";
import IconGroup from "./customIcons/icon-group.svg";
import SegAddOn from "./customIcons/seg_add_on.svg";
import SegEnterpriseTier from "./customIcons/seg_enterprise_tier.svg";
import SegEnterpriseplusTier from "./customIcons/seg_enterpriseplus_tier.svg";
import SegStandardTier from "./customIcons/seg_standard_tier.svg";
import StandardTier from "./customIcons/standard_tier.svg";
import UseraacountEnterprise from "./customIcons/useraacount_enterprise.svg";
import UseraccountEnterpriseplus from "./customIcons/useraccount_enterpriseplus.svg";
import UseraccountStandard from "./customIcons/useraccount_standard.svg";
import GridView from "./customIcons/Grid_View.svg";
import GuardianBlue from "./customIcons/guardian-blue.svg";
import ListView from "./customIcons/List_View.svg";
import ThreeDots from "./customIcons/Three_Dots.svg";
import ArrowRight from "./customIcons/arrow_right_large.svg";
import ChevronRight from "./customIcons/Chevron_Right.svg";
import ChevronUp from "./customIcons/Chevron_Up.svg";
import ChevronDown from "./customIcons/Chevron_Down.svg";
import AppIcon from "./customIcons/icon-app.svg";
import MonitorIcon from "./customIcons/icon-monitor.svg";
import ManageIcon from "./customIcons/icon-managestriim.svg";
import DashboardIcon from "./customIcons/icon-dashboard.svg";
import HomeIcon from "./customIcons/icon-home.svg";
import ConsoleIcon from "./customIcons/icon-console.svg";
import EditColumns from "./customIcons/Edit_Columns.svg";
import GreenCheck from "./customIcons/Green_Check.svg";
import AddUserIcon from "./customIcons/BEInvite.svg";
import RowDrag from "./customIcons/Row_Drag.svg";
import Close from "./customIcons/Close.svg";
import Eye from "./customIcons/Eye.svg";
import Download from "./customIcons/Download.svg";
import Delete from "./customIcons/Delete.svg";
import CloudUpload from "./customIcons/Cloud_Upload.svg";
import EditIcon from "./customIcons/edit-icon.svg";
import EyeIcon from "./customIcons/Eye.svg";
import DeleteIcon from "./customIcons/Delete.svg";
import ToggledOff from "./customIcons/ToggledOff.svg";
import ToggledOn from "./customIcons/ToggledOn.svg";
import InfoCircle from "./customIcons/Info_Circle.svg";
import InfoEdit from "./customIcons/Edit-Info.svg";
import FillCheck from "./customIcons/Fill_Check.svg";
import ChevronLeft from "./customIcons/Chevron_Left.svg";
import ExpandAll from "./customIcons/ExpandAll.svg";
import CollapseAll from "./customIcons/CollpaseAll.svg";
import DownloadOutlinedBlue from "./wizard-icon/Download.svg";
import SpecialChevronDown from "./customIcons/Special_chevron_down.svg";
import PlayerPlay from "./customIcons/Player_play.svg";
import PlayerStop from "./customIcons/Player_stop.svg";
import RocketOff from "./customIcons/Rocket_off.svg";
import OpenLink from "./customIcons/open_link.svg";
import SettingsBlue from "./customIcons/Settings_Blue.svg";
import NewTab from "./customIcons/New_Tab.svg";
import Reset from "./customIcons/Reset.svg";
import Sparkles from "./customIcons/sparkles.svg";
import Guardian from "./customIcons/guardian.svg";
import Sherlock from "./customIcons/sherlock.svg";
import SidebarCollapse from "./customIcons/sidebar_collapse.svg";
import Settings from "./customIcons/Settings.svg";
import Lock from "./customIcons/Lock.svg";
import Expand from "./customIcons/Expand.svg";
import ArrowBack from "./customIcons/Arrow-back.svg";
import Notifications from "./customIcons/Notifications.svg";
import Retry from "./customIcons/Retry.svg";
import HelpCircle from "./customIcons/Help_Circle.svg";
import ErrorCross from "./customIcons/error_cross.svg";
import WarningOutline from "./customIcons/warning_outline.svg";
import App from "./customIcons/App.svg";
import LockOpen from "./customIcons/LockOpen.svg";
import TransitionBottom from "./customIcons/TransitionBottom.svg";
import Insights from "./customIcons/Insights.svg";
import ShieldLock from "./customIcons/ShieldLock.svg";
import Checkmark from "./customIcons/Checkmark.svg";
import MoreHorizontal from "./customIcons/MoreHoriz_rounded.svg";
import SquareArrowUp from "./customIcons/square-arrow-up.svg";
import SquareArrowDown from "./customIcons/square-arrow-down.svg";
import SeverityWarning from "./customIcons/Severity-Events.svg";
import SeverityRounded from "./customIcons/Severity-Rounded.svg";
import ConfidenceLow from "./customIcons/Confidence-Low.svg";
import ShieldSearch from "./customIcons/shield-search-1.svg";
import ShieldLockWhite from "./customIcons/shield-lock-1.svg";
import SentinelIcon from "./customIcons/sentinel.svg";
import Sentinel from "./customIcons/Sentinel_ai.svg";
import FileImport from "./customIcons/file-import.svg";
import AITag from "./customIcons/ai-tag.svg";
import Search from "./customIcons/Search.svg";
import Refresh from "./customIcons/Refresh.svg";
import WarningTriangle from "./customIcons/Warning_Triangle.svg";
import OpenAI from "./customIcons/open-ai-logo.svg";
import VertexAI from "./customIcons/vertex-ai.svg";
import Minimize from "./customIcons/minimize.svg";
import LastRefresh from "./customIcons/last-refresh.svg";
import LivePhoto from "./customIcons/Live-photo.svg";
import StriimAI from "./customIcons/striim-ai.svg";
import SDIImportance from "./customIcons/SDI-Importance.svg";
import AutomatedCampaign from "./customIcons/Automated_campaign.svg";
import NewReleasesCampaign from "./customIcons/New_Releases_Campaign.svg";
import Rotate from "./customIcons/Rotate.svg";
import SettingsAutomation from "./customIcons/Settings_Automation.svg";
import HistoryToggle from "./customIcons/History_Toggle.svg";
import ArrowForward from "./customIcons/Arrow_Forward.svg";
import CreateFromScratch from "./customIcons/CreateFromScratch.svg";
import ImportTQLFile from "./customIcons/ImportTQL.svg";
import NewRelease from "./customIcons/NewRelease.svg";
import MirrorCampaign from "./customIcons/mirrorCampaign.svg";
import FabricMirrorCampaign from "./customIcons/fabricMirrorCampaign.svg";

export { AccountTreeOutlined };
export { Add };
export { AlertCircle };
export { FabricMirrorCampaign };
export { AlertManagerStandardEnterprise };
export { ArrowForwardBlue };
export { ArrowUpward };
export { ArrowDownward };
export { BidirectionalEnterpriseEnterpriseplus };
export { BidirectionalStandard };
export { BellIcon };
export { CancelOutlined };
export { CheckCircle };
export { DashboardsStandard };
export { DashboardsEnterprise };
export { Documentation };
export { Edit };
export { EditSsoIcon };
export { EveenttableStandard };
export { ExceptionStoreStandard };
export { ExternalCacheStandard };
export { FlmStandard };
export { MLfunctionsStandardEnterprise };
export { MetadatamanagerStandard };
export { MonitorTimeSeriesReportStandard };
export { MultinodeSupportStandardEnterprise };
export { OpenProcessorStandard };
export { P2DataMaskingStandard };
export { PartitionedStreamStandardEnterprise };
export { PartitionedWindowEnterprise };
export { PartitionedWindowStandard };
export { PeopleAlt };
export { Rule };
export { ManageAccounts };
export { PersistentStreamStandardEnterprise };
export { RestapiStandard };
export { RouterStandard };
export { ValidationStandardEnterprise };
export { WactionStoreStandard };
export { WactionStoreEnterprise };
export { ApplicationProgress };
export { ExceptionsStore };
export { IconGroup };
export { SegAddOn };
export { SegEnterpriseTier };
export { SegEnterpriseplusTier };
export { SegStandardTier };
export { StandardTier };
export { UseraacountEnterprise };
export { UseraccountEnterpriseplus };
export { UseraccountStandard };
export { GridView };
export { ListView };
export { ThreeDots };
export { ArrowRight };
export { ChevronRight };
export { EditColumns };
export { GreenCheck };
export { RowDrag };
export { Close };
export { HomeIcon };
export { AppIcon };
export { ManageIcon };
export { MonitorIcon };
export { DashboardIcon };
export { ConsoleIcon };
export { AddUserIcon };
export { EyeIcon };
export { EditIcon };
export { DeleteIcon };
export { ToggledOff };
export { ToggledOn };
export { CloudUpload };
export { Eye };
export { Download };
export { Delete };
export { InfoCircle };
export { InfoEdit };
export { FillCheck };
export { DownloadOutlinedBlue };
export { ExpandAll };
export { CollapseAll };
export { SpecialChevronDown };
export { PlayerPlay };
export { RocketOff };
export { PlayerStop };
export { OpenLink };
export { GuardianBlue };
export { SettingsBlue };
export { NewTab };
export { Reset };
export { Sparkles };
export { Guardian };
export { Sherlock };
export { SidebarCollapse };
export { Lock };
export { Settings };
export { Expand };
export { ArrowBack };
export { Notifications };
export { ChevronLeft };
export { Retry };
export { HelpCircle };
export { ErrorCross };
export { WarningOutline };
export { App };
export { LockOpen };
export { TransitionBottom };
export { Insights };
export { ShieldLock };
export { ChevronUp };
export { ChevronDown };
export { Checkmark };
export { MoreHorizontal };
export { SquareArrowUp };
export { SquareArrowDown };
export { SeverityWarning };
export { ShieldSearch };
export { ShieldLockWhite };
export { SentinelIcon };
export { FileImport };
export { AITag };
export { Search };
export { Refresh };
export { WarningTriangle };
export { ArrowUpRightCircle };
export { OpenAI };
export { VertexAI };
export { Minimize };
export { LastRefresh };
export { LivePhoto };
export { Sentinel };

export { StriimAI };
export { SDIImportance };
export { NewReleasesCampaign };
export { AutomatedCampaign };
export { Rotate };
export { SettingsAutomation };
export { HistoryToggle };
export { ArrowForward };
export { CreateFromScratch };
export { ImportTQLFile };
export { NewRelease };
export { MirrorCampaign };
export { SeverityRounded };
export { ConfidenceLow };
let icons = {};

icons["AccountTreeOutlined"] = AccountTreeOutlined;
icons["Add"] = Add;
icons["AlertCircle"] = AlertCircle;
icons["AlertManager_standard_enterprise"] = AlertManagerStandardEnterprise;
icons["arrow_upward"] = ArrowUpward;
icons["arrow_downward"] = ArrowDownward;
icons["ArrowUpRightCircle"] = ArrowUpRightCircle;
icons["Bidirectional_enterprise_enterpriseplus"] = BidirectionalEnterpriseEnterpriseplus;
icons["Bidirectional_standard"] = BidirectionalStandard;
icons["BellIcon"] = BellIcon;
icons["cancel_outlined"] = CancelOutlined;
icons["CheckCircle"] = CheckCircle;
icons["Dashboards_standard"] = DashboardsStandard;
icons["Dashboards_enterprise"] = DashboardsEnterprise;
icons["DeleteIcon"] = DeleteIcon;
icons["Documentation"] = Documentation;
icons["edit"] = Edit;
icons["EditSsoIcon"] = EditSsoIcon;
icons["Eveenttable_standard"] = EveenttableStandard;
icons["ExceptionStore_standard"] = ExceptionStoreStandard;
icons["ExternalCache_standard"] = ExternalCacheStandard;
icons["FLM_standard"] = FlmStandard;
icons["MLfunctions_standard_enterprise"] = MLfunctionsStandardEnterprise;
icons["Metadatamanager_standard"] = MetadatamanagerStandard;
icons["MonitorTimeSeriesReport_standard"] = MonitorTimeSeriesReportStandard;
icons["MultinodeSupport_standard_enterprise"] = MultinodeSupportStandardEnterprise;
icons["OpenProcessor_standard"] = OpenProcessorStandard;
icons["P2DataMasking_standard"] = P2DataMaskingStandard;
icons["PartitionedStream_standard_enterprise"] = PartitionedStreamStandardEnterprise;
icons["PartitionedWindow_enterprise"] = PartitionedWindowEnterprise;
icons["PartitionedWindow_standard"] = PartitionedWindowStandard;
icons["people_alt"] = PeopleAlt;
icons["rule"] = Rule;
icons["manage_accounts"] = ManageAccounts;
icons["PersistentStream_standard_enterprise"] = PersistentStreamStandardEnterprise;
icons["Restapi_standard"] = RestapiStandard;
icons["Router_standard"] = RouterStandard;
icons["Validation_standard_enterprise"] = ValidationStandardEnterprise;
icons["WactionStore_standard"] = WactionStoreStandard;
icons["WactionStore_enterprise"] = WactionStoreEnterprise;
icons["application_progress"] = ApplicationProgress;
icons["exceptions-store"] = ExceptionsStore;
icons["icon-group"] = IconGroup;
icons["seg_add_on"] = SegAddOn;
icons["seg_enterprise_tier"] = SegEnterpriseTier;
icons["seg_enterpriseplus_tier"] = SegEnterpriseplusTier;
icons["seg_standard_tier"] = SegStandardTier;
icons["standard_tier"] = StandardTier;
icons["useraacount_enterprise"] = UseraacountEnterprise;
icons["useraccount_enterpriseplus"] = UseraccountEnterpriseplus;
icons["useraccount_standard"] = UseraccountStandard;
icons["Grid_View"] = GridView;
icons["Guardian_Blue"] = GuardianBlue;
icons["List_View"] = ListView;
icons["Three_Dots"] = ThreeDots;
icons["arrow_right"] = ArrowRight;
icons["Chevron_Right"] = ChevronRight;
icons["Edit_Columns"] = EditColumns;
icons["Green_Check"] = GreenCheck;
icons["Row_Drag"] = RowDrag;
icons["Close"] = Close;
icons["Documentation"] = Documentation;
icons["Cloud_Upload"] = CloudUpload;
icons["Eye"] = Eye;
icons["Download"] = Download;
icons["Delete"] = Delete;
icons["Info_Circle"] = InfoCircle;
icons["InfoEdit"] = InfoEdit;
icons["SpecialChevronDown"] = SpecialChevronDown;
icons["PlayerPlay"] = PlayerPlay;
icons["RocketOff"] = RocketOff;
icons["PlayerStop"] = PlayerStop;
icons["OpenLink"] = OpenLink;
icons["Sparkles"] = Sparkles;
icons["Guardian"] = Guardian;
icons["Sherlock"] = Sherlock;
icons["SidebarCollapse"] = SidebarCollapse;
icons["Lock"] = Lock;
icons["Settings"] = Settings;
icons["Expand"] = Expand;
icons["ArrowBack"] = ArrowBack;
icons["Notifications"] = Notifications;
icons["Chevron_Left"] = ChevronLeft;
icons["Retry"] = Retry;
icons["HelpCircle"] = HelpCircle;
icons["ErrorCross"] = ErrorCross;
icons["WarningOutline"] = WarningOutline;
icons["App"] = App;
icons["LockOpen"] = LockOpen;
icons["TransitionBottom"] = TransitionBottom;
icons["Insights"] = Insights;
icons["ShieldLock"] = ShieldLock;
icons["SettingsBlue"] = SettingsBlue;
icons["New_Tab"] = NewTab;
icons["Reset"] = Reset;
icons["ChevronUp"] = ChevronUp;
icons["ChevronDown"] = ChevronDown;
icons["Checkmark"] = Checkmark;
icons["MoreHorizontal"] = MoreHorizontal;
icons["SquareArrowUp"] = SquareArrowUp;
icons["SquareArrowDown"] = SquareArrowDown;
icons["SeverityWarning"] = SeverityWarning;
icons["ShieldSearch"] = ShieldSearch;
icons["ShieldLockWhite"] = ShieldLockWhite;
icons["SentinelIcon"] = SentinelIcon;
icons["Sentinel"] = Sentinel;
icons["FileImport"] = FileImport;
icons["AITag"] = AITag;
icons["Search"] = Search;
icons["Refresh"] = Refresh;
icons["WarningTriangle"] = WarningTriangle;
icons["OpenAI"] = OpenAI;
icons["VertexAI"] = VertexAI;
icons["ArrowForwardBlue"] = ArrowForwardBlue;
icons["LivePhoto"] = LivePhoto;
icons["StriimAI"] = StriimAI;
icons["SDIImportance"] = SDIImportance;
icons["NewReleasesCampaign"] = NewReleasesCampaign;
icons["AutomatedCampaign"] = AutomatedCampaign;
icons["Rotate"] = Rotate;
icons["SettingsAutomation"] = SettingsAutomation;
icons["HistoryToggle"] = HistoryToggle;
icons["ArrowForward"] = ArrowForward;
icons["CreateFromScratch"] = CreateFromScratch;
icons["ImportTQLFile"] = ImportTQLFile;
icons["NewRelease"] = NewRelease;
icons["MirrorCampaign"] = MirrorCampaign;
icons["FabricMirrorCampaign"] = FabricMirrorCampaign;
icons["SeverityRounded"] = SeverityRounded;
icons["ConfidenceLow"] = ConfidenceLow;

import React from "react";
import PropTypes from "prop-types";

export const findKeyStringInsensitive = iconName => {
    const keys = Object.keys(icons).filter(x => x.toLowerCase() === iconName.toLowerCase());
    if (keys.length > 1) {
        console.log("There is more than one key", keys);
    }
    if (keys.length === 0) {
        return null;
    }
    return keys[0];
};

export default class IconByName extends React.Component {
    render() {
        if (!icons[this.props.icon]) {
            // eslint-disable-next-line no-console
            // console.error("Icon not found:" + this.props.icon);
            return <div>{this.props.icon}</div>;
        }
        let Tag = icons[this.props.icon];
        return <Tag {...this.props} />;
    }
}

IconByName.propTypes = {
    icon: PropTypes.string.isRequired
};
