import api from "/core/api/api";
import App from "/app";

function validateQuery(query: string): void {
    query = query.toLowerCase().trim();
    if (!query.startsWith("select")) {
        throw new Error("Please check your query. All queries should start with a select statement.");
    }
}
interface ID {
    stringRep: string;
    uuidstring: string;
}
interface MetaInfoStatus {
    isValid: boolean;
    isAnonymous: boolean;
    isDropped: boolean;
    isAdhoc: boolean;
    isGenerated: boolean;
    anonymous: boolean;
    generated: boolean;
    valid: boolean;
    dropped: boolean;
    adhoc: boolean;
}

interface Query {
    name: string;
    uuid: ID;
    nsName: string;
    type: "QUERY";
    sourceText: string;
    queryDefinition: string;
    projectionFields: Array<object>;
    metaInfoStatus: MetaInfoStatus;
    appUUID: ID;
    streamUUID: ID;
    cqUUID: ID;
}

async function getDataForAdhocQuery(id: string, includeTimeout: boolean): Promise<unknown> {
    return new Promise((resolve, reject) => {
        const key = id + ":data";
        let hasData = false;

        App.vent.on(key, function (data) {
            const returnValues = [];
            const dataPoints = data?.xadded || [];
            const keys = data?.xadded?.[0]?.data?.fieldsInfo || [];

            dataPoints.forEach((dataPoint) => {
                const entry = {};
                const values = dataPoint.data?.dataPoints || [];
                values.forEach((val, i) => {
                    entry[keys[i]] = val;
                })
                returnValues.push(entry);
            })
            hasData = true;
            resolve(returnValues);
        });
        if (includeTimeout)
            setTimeout(() => {
                if (!hasData) resolve([]);
            }, 3000)
    });
}

export default {
    executeQuery: async function (query: string, includeTimeout = true): Promise<Array<object>> {
        validateQuery(query);
        const queryObject: Query = await api.createAHQuery(query);
        const [startAHQuery, dataResponse] = await Promise.all([api.startAHQuery(queryObject.uuid.uuidstring), getDataForAdhocQuery(queryObject.uuid.uuidstring, includeTimeout)]);
        await api.stopAHQuery(queryObject.uuid.uuidstring);
        await api.deleteAHQuery(queryObject.uuid.uuidstring);
        console.log(dataResponse);
        return dataResponse;
    }
};
