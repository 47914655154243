import _ from "underscore";
import HighChartsMixin from "app/components/dashboard/visualization/charts/mixins/highcharts";

export default _({}).extend(HighChartsMixin, {
    // Note: be sure to pass in a deep-cloned config object!
    setConfiguration: function (config) {
        var _this = this;
        var theme = config.theme;
        var config = config.config;
        if (!theme) return console.warn("No theme provided.");

        // Only show series with data (i.e. exclude series whose job was solely to generate dynamic series)
        config.series = _(config.series).filter(function (series) {
            return series.data;
        });

        // Translate our configuration to Highcharts configuration
        this.chartConfig = {
            chart: {
                type: "heatmap",
                backgroundColor: theme.chart.background,
                animation: false,
            },
            credits: {
                enabled: false,
            },
            tooltip: this.generateToolTipConfig({ shared: true }),
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true,
                    },
                    enableMouseTracking: false,
                },
                series: {
                    animation: false,
                    dataLabels: {
                        enabled: config.label_cells,
                        color: "#000000",
                    },
                    cursor: "pointer",
                    events: {
                        hide: function (e) {
                            this.trigger("series-hidden", e);
                        }.bind(this),
                        show: function (e) {
                            this.trigger("series-shown", e);
                        }.bind(this),
                    },
                    point: {
                        events: {
                            click: function (e) {
                                var element = e.currentTarget.options.rowData;
                                this.trigger("datapoint:click", element);
                            }.bind(this),
                        },
                    },
                },
            },
            legend: {
                enabled: true,
            },
            colorAxis: {
                stops: [
                    [0, "#0D47A1"],
                    [0.5, "#FFECB3"],
                    [0.9, "#BF360C"],
                ],
                min: -5,
            },
            title: {
                text: config.title,
                style: {
                    display: "none",
                },
            },
            xAxis: this._formatXAxis(config.xAxis, theme),
            yAxis: this._formatYAxis(config.yAxis, theme),
        };

        config.includePointColor = false;
        for (var i = 0; i < config.series.length; i++) {
            var seriesCategoriesAndLegendData = this.generateSeriesCategoriesAndLegendData(
                i,
                config,
                this.chartConfig,
                this.model.dataSource.fieldList
            );
            this.setChartConfigWithSeriesCategoryAndChartData(0, config, seriesCategoriesAndLegendData);
        }
    },
});
