export const styles = {
    infoBox: {
        display: "flex",
        minHeight: "90px",
        height: "90px",
        width: "100%",
        backgroundColor: "greyscale.50",
        border: "1px solid",
        borderColor: "greyscale.100",
        borderRadius: "8px"
    },
    leftColumn: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    rightColumn: {
        display: "flex",
        flex: 1,
    },
    valueContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    values: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
    },
    value: {
        fontSize: "24px",
        color: "primary.700",
        fontFamily: "Nunito",
        marginRight: 0.5
    },
    subValue: {
        color: "primary.800",
        fontFamily: "Nunito"
    },
    icon: {
        marginRight: 1,
        "& svg": {
            fill: "none"
        }
    }
};
