import App from "app";

import ExportDialog from "app/components/common/app-control/exportApp";
import ModalManager from "app/components/common/modal/ModalManager";
import growl from "../../common/growl";

const utils = {};
var modalManager = new ModalManager();

utils.exportApplication = function (applicationName) {
    let exportDialog = new ExportDialog({
        model: new Backbone.Model({ apps: [applicationName] }),
    });
    modalManager.add(exportDialog);
    new App.FormMixinDialog.Controller({
        view: exportDialog,
    });

    exportDialog.on("export:done", () => {
        exportDialog.destroy();
    });
    exportDialog.on("export:fail", (e) => {
        exportDialog.destroy();
        growl.error("Export failed! " + e.message + "", "Error");
    });
};

export default utils;
