import Column from "../columns-picker/column";

var SingleColumn = {};

SingleColumn.View = Column.View.extend({
    modelEvents: {
        "change:column": "modified",
    },

    onRender: function () {
        Column.View.prototype.onRender.apply(this);
        this.$el.find("#alias-wrapper").hide();
        this.$el.find(".columns").addClass("single-column");
    },

    setEnabled: function (enabled) {
        Column.View.prototype.setEnabled.apply(this, [enabled]);
        this.updateWidth(enabled);
    },

    updateWidth: function (enabled) {
        if (enabled) {
            this.$el.find(".columns").css("cssText", "width: 400px");
        } else {
            this.$el.find(".columns").css("cssText", "width: 460px");
        }
    },
});

SingleColumn.CollectionView = Column.CollectionView.extend({
    childView: SingleColumn.View,
    onRender: function () {
        Column.CollectionView.prototype.onRender.apply(this);
        this.$el.find("thead,tbody").addClass("without-col2");
    }
});

SingleColumn.Model = Column.Model.extend({
    defaults: {
        column: String,
        delimiter: String,
        alias: String,
        columns: Array,
        tqlFunction: String,
        enabled: true,
    },
});

SingleColumn.Collection = Column.Collection.extend({
    model: SingleColumn.Model,
});

export default SingleColumn;
