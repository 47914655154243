import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./text-only.html";
import dateLib from "core/utils/date-utils";

export default function (dateFormat, prefix) {
    var View = BaseControl.View.extend({
        template: _.template(template),

        getViewValue: function() {},

        setViewValue: function() {
            if (!dateFormat) {
                this.$("span").text((prefix || "") + this.getValue());
            } else {
                this.$("span").text((prefix || "") + dateLib(this.getValue()).format(dateFormat));
            }
        }
    });

    return _.extend({}, BaseControl, {
        View: View
    });
}
