import queryString from "query-string";
import _ from "underscore";
import axios from "axios";
import ignoredPatternsFilter from "app/components/common/ignoredPatternsFilter";

async function load(type, startDate, endDate) {
    const query = queryString.stringify({
        start: startDate,
        end: endDate /*1470556973000*/,
    });
    const response = await axios(`/health/${type}?${query}`);
    return _.uniq(response.data, function (statusChange) {
        return JSON.stringify(statusChange);
    }).filter(ignoredPatternsFilter);
}

export async function loadExceptions(startDate, endDate) {
    return load("issues", startDate, endDate);
}

export async function loadStatuses(startDate, endDate) {
    return load("statusChanges", startDate, endDate);
}
