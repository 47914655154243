import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing }) => {
    return {
        emptyDashboardWrapper: {
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: spacing(5.5)
        },
        emptyDashboardText: {
            maxWidth: "400px",
            marginBottom: spacing(1.5),
            textAlign: "center",
            color: v5theme => v5theme.palette.greyscale[700]

        },
        emptyDashboardImage: {
            margin: spacing(2)
        }
    };
});
