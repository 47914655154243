export const styles = {
    container: {
        margin: "0px 20px",
        display: "flex",
        flexGrow: 1,
        alignItems: "center"
    },
    dot: {
        borderStyle: "solid",
        borderWidth: "4px",
        borderRadius: "4px"
    },
    line: {
        width: "100%",
        border: "1px solid",
        height: 0,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    arrow: {
        borderTop: "4px solid transparent",
        borderBottom: "4px solid transparent",
        borderLeftStyle: "solid",
        borderLeftWidth: "8px"
    },
    lineLabel: {
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        margin: "auto",
        gap: 1 / 2,
        padding: "0px 8px",
        color: "inherit",
        backgroundColor: "greyscale.50",
        fontFamily: "Inter"
    }
};
