import React from "react";
import { Box, Grid } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Warning } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

import { circularStyles } from "./circular-progress.styles";

interface CircularProgressProps {
    variant?: "determinate" | "indeterminate";
    status?: string | null;
    size: number;
    thickness?: number;
    value?: number;
    wrapperClass?: string;
}

const StriimCircularProgress = ({
    variant = "indeterminate",
    status,
    size = 24,
    thickness = 10,
    value,
    wrapperClass
}: CircularProgressProps) => {
    const icons = [];
    if (status) {
        status = status.toLowerCase();
        icons["success"] = <CheckCircleIcon sx={circularStyles.successIcon} />;
        icons["error"] = <ErrorIcon sx={circularStyles.errorIcon} />;
        icons["failed"] = <ErrorIcon sx={circularStyles.errorIcon} />;
        icons["warning"] = <Warning sx={circularStyles.warningIcon} />;
    }

    return (
        <Box display={"flex"}>
            {icons[status] ? (
                icons[status]
            ) : (
                <Grid display={"flex"} alignItems={"center"} sx={[circularStyles.wrapper, wrapperClass]}>
                    <CircularProgress
                        size={size}
                        variant={"determinate"}
                        value={100}
                        thickness={thickness}
                        sx={{ svg: circularStyles.loaderBG }}
                    />
                    <CircularProgress
                        size={size}
                        disableShrink
                        variant={variant}
                        value={value}
                        thickness={thickness}
                        sx={{ ...circularStyles.root, circle: circularStyles.circle }}
                    />
                </Grid>
            )}
        </Box>
    );
};

export default StriimCircularProgress;
