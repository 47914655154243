import React, { useState, useCallback } from "react";
import { StriimTypography, StriimModal } from "@striim/striim-ui";
import growl from "../../../../../../app/components/common/growl";
import { UPLOAD_PATH } from "../constants";
import { FilesPage } from "../files";
import { Box } from "@material-ui/core";

const FileSelectionModal = ({ fetchData, setIsModalShown, onFileDone }) => {
    const [filePath, setFilePath] = useState();

    const deleteFile = useCallback(
        async filePath => {
            try {
                await fetch("/upload?path=" + encodeURIComponent(filePath), {
                    method: "DELETE"
                });
                fetchData && (await fetchData());
            } catch (e) {
                fetchData && (await fetchData());
                growl.error("Error deleting file");
            }
        },
        [fetchData]
    );

    const selectFile = file => {
        onFileDone(file.currentPath);
        setIsModalShown(false);
    };

    return (
        <StriimModal
            isVisible={true}
            size="large"
            titleContent={<StriimTypography variant="h2">Choose a file</StriimTypography>}
            confirmContent={"Done"}
            confirmButtonProps={{ disabled: !filePath }}
            cancelButtonProps={{ "data-test-id": "file-upload-cancel-button" }}
            onConfirm={async () => {
                fetchData && (await fetchData());
                setIsModalShown(false);
            }}
            onCancel={async () => {
                if (filePath) {
                    await deleteFile(UPLOAD_PATH + filePath);
                    setIsModalShown(false);
                } else {
                    setFilePath(null);
                    setIsModalShown(false);
                }
            }}
            data-test-id="file-modal"
        >
            <Box p={1}>
                <FilesPage selectFile={selectFile} setFileSelectionModalShown={setIsModalShown} />
            </Box>
        </StriimModal>
    );
};

export default FileSelectionModal;
