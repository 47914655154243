import _ from "underscore";
import Backbone from "backbone";
import NestedTypes from "backbone.nestedtypes";
import App from "app";
import ModalManager from "app/components/common/modal/ModalManager";
import UIControl from "app/components/common/editor/control/ui-control";
import exceptionsStoreApi from "./exception-store-api";

const template = `<div>
    <p>Delete exceptions after</p>
    <div class="interval-container"></div>
</div>
`;

/**
 * Dialog model
 */
const ManageHistoryModel = NestedTypes.Model.extend({
    defaults: {
        selectedInterval: String,
    },
});

/**
 * Dialog content view
 */
const ContentView = Backbone.Marionette.LayoutView.extend({
    template: _.template(template),

    regions: {
        intervalRegion: ".interval-container",
    },

    initialize: function () {
        this.interval = UIControl.ShortTimeFormat.create();
        this.listenTo(
            this.interval.model,
            "change:value",
            function (selected) {
                this.model.selectedInterval = this.interval.getViewValue();
                this.trigger("value-set", !this.interval.isEmpty());
            }.bind(this)
        );
    },

    onRender: function () {
        this.intervalRegion.show(this.interval);
        this.interval.setValue(this.model.selectedInterval);
    },
});

/**
 * Dialog mixin
 */
const ManageHistoryDialogMixin = App.FormMixinDialog.View.extend({
    initialize: function (options) {
        options.contentview = new ContentView({ model: options.dialogModel });
        options.bind_submit = true;
        this.listenTo(
            options.contentview,
            "value-set",
            function (enabled) {
                this.ui.submitButton.toggleClass("disabled", !enabled);
            }.bind(this)
        );
        App.FormMixinDialog.View.prototype.initialize.apply(this, arguments);
    },

    onRender: function () {
        this.ui.submitButton.addClass("disabled");
        this.$el.addClass("manage-history-dialog");
    },
});

/**
 * Show confirmation dialog
 * @returns {Promise<any>}
 */
function showDialog(model) {
    const dialog = new ManageHistoryDialogMixin({
        model: new Backbone.Model({
            title: "Manage history",
            showSubmitButton: true,
            cancel_text: "Cancel",
            submit_text: "Save",
        }),
        dialogModel: model,
    });

    const modalManager = new ModalManager({
        container: "body",
    });
    modalManager.add(dialog);
    new ManageHistoryDialogMixin.Controller({
        view: dialog,
    });

    let dialogSubmit;
    dialog.on("cancel", () => {
        dialogSubmit = false;
        dialog.destroy();
    });

    dialog.on("submit", () => {
        dialogSubmit = true;
        dialog.destroy();
    });

    return new Promise((resolve, reject) => {
        dialog.on("before:destroy", () => {
            modalManager.remove_all_modals();
            if (dialogSubmit) {
                resolve();
            } else {
                reject();
            }
        });
    });
}

ManageHistoryDialogMixin.Controller = App.FormMixinDialog.Controller;

function manageHistory(appId) {
    return new Promise((resolve, reject) => {
        exceptionsStoreApi.getManageHistory(appId).then((currentInterval) => {
            let model = new ManageHistoryModel({
                selectedInterval: currentInterval,
            });
            showDialog(model)
                .then(() => {
                    resolve(model.selectedInterval);
                })
                .catch(() => {
                    reject();
                });
        });
    });
}

export default manageHistory;
