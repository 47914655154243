import _ from "underscore";
import with_category from "./with_category";

var CurrentWithCategory = function () {};

CurrentWithCategory.prototype = _.extend(new with_category(), {
    _evictSeriesData: function (data) {
        // protected
        // Trim the data to the maximum size
        if (data.length > 1) {
            data = data.slice(data.length - 1);
        }
        return data;
    },
});

export default CurrentWithCategory;
