import _ from "underscore";
import $ from "jquery";
import Column from "./column";
import Select from "./../select/select";
import gridTemplate from "./grid-with-function.html";
import rowTemplate from "./row-with-function.html";

var ColumnWithFunction = {};

ColumnWithFunction.View = Column.View.extend({
    template: _.template(rowTemplate),

    modelEvents: {
        "change:column": "columnModified",
        "change:tqlFunction": "modified",
        "change:alias": "modified",
    },

    ui: {
        columns: ".columns",
        functions: ".functions",
        alias: ".alias",
        controls: ".action-controls",
        iconClose: ".icon-close",
    },

    regions: {
        columnsRegion: ".columns",
        functionsRegion: ".functions",
    },

    initialize: function (options) {
        Column.View.prototype.initialize.apply(this, [options]);

        this.functions = Select(this.config.functionsSource())
            .extend({
                placeholder: "select",
            })
            .create();

        var _this = this;
        this.listenTo(this.functions.model, "change:value", function (viewModel) {
            _this.model.tqlFunction = viewModel.value;
        });
    },

    columnModified: function () {
        let _this = this;
        this.functions.model.set("value", "");

        this.getSelectedColumnType().then((columnType) => {
            _this.functions.setDataSource(_this.config.functionsSource(columnType));
            _this.model.columnType = columnType;
        });

        Column.View.prototype.columnModified.apply(this);
    },

    setColumnsDataSource: function (dataSource) {
        Column.View.prototype.setColumnsDataSource.apply(this, [dataSource]);

        if (this.model.columnType) {
            this.functions.setDataSource(this.config.functionsSource(this.model.columnType));
            this.functions.setValue(this.model.tqlFunction);
            this.functions.setViewValue();
        }
    },

    getSelectedColumnType: function () {
        return this.model.columns.then((columns) => {
            for (let i = 0; i < columns.length; i++) {
                if (columns[i].id === this.model.column) {
                    return columns[i].description;
                }
            }

            return "";
        });
    },

    setEnabled: function (enabled) {
        Column.View.prototype.setEnabled.apply(this, [enabled]);
        this.functions.setEnabled(enabled);
        this.ui.functions.show();
    },

    onRender: function () {
        Column.View.prototype.onRender.apply(this);
        this.functionsRegion.show(this.functions);
        this.functions.setValue(this.model.tqlFunction);
    },
});

ColumnWithFunction.CollectionView = Column.CollectionView.extend({
    template: _.template(gridTemplate),
    childView: ColumnWithFunction.View,
    onRender: function () {
        Column.CollectionView.prototype.onRender.apply(this);
        this.$el.find("#column3header").text(this.options.config.labels.column3);
    },
});

ColumnWithFunction.Model = Column.Model.extend({});

ColumnWithFunction.Collection = Column.Collection.extend({
    model: ColumnWithFunction.Model,
});

export default ColumnWithFunction;
