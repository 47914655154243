import TextField from "./text-field/text-field";
import ChangeableTextField from "./text-field/changeable-text-field";
import StaticTextField from "./text-field/static-text-field";
import ConnectionTextField from "./connection-text-field/connection-text-field";
import TextOnly from "./text-field/text-only";
import MetaObjectTextField from "./text-field/metaobject-text-field";
import Checkbox from "./checkbox/checkbox";
import Toggle from "./toggle/toggle";
import Tql from "./tql/tql";
import Autocomplete from "./autocomplete/autocomplete";
import Button from "./button/button";
import MetaInfoStatus from "./meta-info-status/meta-info-status";
import Select from "./select/select";
import StreamSelector from "./stream-selector/stream-selector";
import MultipleStreamSelector from "./multiple-stream-selector/multiple-stream-selector";
import Interval from "./interval/interval";
import Seconds from "./interval/seconds";
import MicroSeconds from "./interval/microseconds";
import ShortTimeFormat from "./interval/shortTimeFormat";
import PropertyTemplate from "./property-template/property-template";
import FieldsList from "./fields-list/fields-list";
import ColumnsPicker from "./columns-picker/columns-list";
import ColumnsWithFunctionPicker from "./columns-picker/columns-with-function-list";
import ColumnSplitter from "./columns-splitter/column-splitter";
import JsonReader from "./json-reader/json-reader";
import ColumnJoin from "./columns-join/column-join";
import Type from "./type/type";
import TypeViewer from "./type/type-viewer";
import WindowSize from "./window-size/window-size";
import NumberField from "./text-field/number-field";
import PositiveNumberField from "./text-field/positive-number-field";
import PasswordField from "./text-field/password-field";
import CheckBoxList from "./checkbox-list/checkbox-list";
import TextArea from "./text-area/text-area";
import RadioButtonList from "./radiobutton-list/radiobutton-list";
import Ehandlers from "./ehandlers/ehandlers";
import DeploymentPlanList from "./deployment-plan-list/deployment-plan-list";
import StringList from "./string-list/string-list";
import ProcessComponentLoader from "./process-component/process-component-loader/process-component-loader";
import Header from "./header/header";
import HeaderWithIcon from "./header-with-icon/header-with-icon";
import HeaderWithHelp from "./header-with-help/header-with-help";
import TypeAndFieldList from "./type-and-field-list/type-and-field-list";
import WactionstorePersistence from "./wactionstore-persistence/wactionstore-persistence";
import CacheQueryProperties from "./cache-query-properties/cache-query-properties";
import TimePicker from "./time-picker/time-picker";
import DatePicker from "./date-picker/date-picker";
import SMTPConnection from "./connection-text-field/smtp-connection";
import TeamsConnection from "./connection-text-field/teams-connection";
import TransactionPolicy from "./transaction-policy/transaction-policy";
import Map from "./map/map";
import LineBreak from "./line-break/line-break";
import FileBrowser from "./filebrowser/filebrowser";
import InfoBox from "./info-box/info-box";
import ErrorBox from "./error-box/error-box";
import ToggleContent from "./toggle-content/toggle-content";

import AlertEnabledInfoBox from "./alert-enabled-info-box/alert-enabled-info-box";
import wizardFormBuilder from "./wizard-form-builder/wizard-form-builder";
import ConnectionProfileSelectField from "./connection-profile-select-field/connection-profile-select-field";

export default {
    EVENT: {
        VALUE_CHANGED: "value:set"
    },
    TextField: TextField,
    ChangeableTextField: ChangeableTextField,
    StaticTextField: StaticTextField,
    ConnectionTextField: ConnectionTextField,
    TextOnly: TextOnly,
    MetaObjectTextField: MetaObjectTextField,
    Checkbox: Checkbox,
    Toggle: Toggle,
    Tql: Tql,
    Autocomplete: Autocomplete,
    Button: Button,
    MetaInfoStatus: MetaInfoStatus,
    Select: Select,
    StreamSelector: StreamSelector,
    MultipleStreamSelector: MultipleStreamSelector,
    Interval: Interval,
    Seconds: Seconds,
    MicroSeconds: MicroSeconds,
    ShortTimeFormat: ShortTimeFormat,
    PropertyTemplate: PropertyTemplate,
    FieldsList: FieldsList,
    ColumnsPicker: ColumnsPicker,
    ColumnsWithFunctionPicker: ColumnsWithFunctionPicker,
    ColumnSplitter: ColumnSplitter,
    JsonReader: JsonReader,
    ColumnJoin: ColumnJoin,
    Type: Type,
    TypeViewer: TypeViewer,
    WindowSize: WindowSize,
    NumberField: NumberField,
    PositiveNumberField: PositiveNumberField,
    PasswordField: PasswordField,
    CheckBoxList: CheckBoxList,
    RadioButtonList: RadioButtonList,
    Ehandlers: Ehandlers,
    DeploymentPlanList: DeploymentPlanList,
    TextArea: TextArea,
    StringList: StringList,
    ProcessComponentLoader: ProcessComponentLoader,
    Header: Header,
    HeaderWithIcon: HeaderWithIcon,
    HeaderWithHelp: HeaderWithHelp,
    TypeAndFieldList: TypeAndFieldList,
    WactionstorePersistence: WactionstorePersistence,
    CacheQueryProperties: CacheQueryProperties,
    TimePicker: TimePicker,
    DatePicker: DatePicker,
    SMTPConnection: SMTPConnection,
    TeamsConnection: TeamsConnection,
    TransactionPolicy: TransactionPolicy,
    Map: Map,
    LineBreak: LineBreak,
    FileBrowser: FileBrowser,
    InfoBox: InfoBox,
    ToggleContent: ToggleContent,
    WizardFormBuilder: wizardFormBuilder,
    AlertEnabledInfoBox: AlertEnabledInfoBox,
    ErrorBox: ErrorBox,
    ConnectionProfileSelectField: ConnectionProfileSelectField
};
