import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import BaseControl from "./base-control";

var Model = BaseControl.Model.extend({
    defaults: {
        value: Object,
        enabled: Boolean(false),
    },
});

var View = BaseControl.View.extend({
    setViewValue: function () {},

    getViewValue: function () {},
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
