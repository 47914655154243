import React from "react";
import PropTypes from "prop-types";
import { ResponsiveContainer, Legend, YAxis, XAxis, Tooltip, CartesianGrid, AreaChart, Area } from "recharts";
import { withStyles } from "@material-ui/core/styles";

import { tooltipStyles } from "./chart-elements";
import typography from "../../../../../../../../theme/typography";
import getColor, { colorSequenceOptions } from "./chart-colors";

const styles = {
    root12: {
        "&. svg": {
            overflow: "visible"
        },

        "& .recharts-legend-item-text": {
            fontFamily: typography.fontFamily,
            fontSize: "16px"
        }
    },
    root14: {
        "& .recharts-legend-item-text": {
            fontFamily: typography.fontFamily,
            fontSize: "14px"
        }
    }
};

const StriimChart = ({
    classes,
    data,
    withLegend,
    keys,
    legendShape,
    fontSize,
    hideTooltipLabel,
    legendAlign,
    showCartesianGrid,
    customColor,
    showAxisLine,
    title,
    // tickFormat,
    hideXaxis,
    hideYaxis,
    disableAnimation,
    useColorSequence,
    colorSequence,
    xAxisType,
    yAxisType,
    yTickFormatter,
    tooltipFormatter
}) => {
    const rootClassName = fontSize === "14px" ? classes.root14 : classes.root12;
    const tooltipLabelCustomStyle = hideTooltipLabel ? { display: "none" } : {};
    const axisLine = showAxisLine ? { stroke: "#EBECEC" } : false;
    const gradientName = `${title}-gradient-color`;
    const getLocalColor = (dataKey, index) =>
        useColorSequence
            ? colorSequenceOptions[colorSequence][index]
            : customColor
            ? getColor(customColor)
            : getColor(dataKey, index - 1);

    return (
        <ResponsiveContainer>
            <AreaChart data={data} className={rootClassName}>
                {showCartesianGrid && <CartesianGrid strokeDasharray="3 3" />}

                <YAxis
                    type={yAxisType}
                    domain={[0, "dataMax"]}
                    tickLine={false}
                    fontFamily={typography.fontFamily}
                    fontSize={fontSize}
                    axisLine={axisLine}
                    hide={hideYaxis}
                    tickFormatter={yTickFormatter}
                />
                <XAxis
                    type={xAxisType}
                    domain={["dataMin", "dataMax"]}
                    dataKey={"category"}
                    fontFamily={typography.fontFamily}
                    fontSize={fontSize}
                    tickSize={5}
                    tickLine={false}
                    axisLine={false}
                    width={80}
                    // tickFormatter={val => (tickFormat ? epochConvert(val, tickFormat) : val)}
                    hide={hideXaxis}
                />

                {withLegend && <Legend iconType={legendShape} iconSize={12} align={legendAlign} />}

                {keys.map((dataKey, index) => {
                    const color = getLocalColor(dataKey, index);

                    return (
                        <defs key={index}>
                            <linearGradient id={`${index}-${gradientName}`} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={color} stopOpacity={0.5} />
                                <stop offset="100%" stopColor={color} stopOpacity={0} />
                            </linearGradient>
                        </defs>
                    );
                })}

                {keys.map((dataKey, index) => {
                    if (index === 0) {
                        return;
                    }

                    const color = getLocalColor(dataKey, index);

                    return (
                        <Area
                            key={dataKey}
                            type="linear"
                            dataKey={dataKey}
                            fill={`url(#${index}-${gradientName})`}
                            fillOpacity={1}
                            stroke={color}
                            dot={false}
                            lineSize={10}
                            strokeWidth={4}
                            isAnimationActive={!disableAnimation}
                        />
                    );
                })}

                <Tooltip {...tooltipStyles} labelStyle={tooltipLabelCustomStyle} formatter={tooltipFormatter} />
            </AreaChart>
        </ResponsiveContainer>
    );
};

StriimChart.propTypes = {
    classes: PropTypes.object.isRequired,
    withLegend: PropTypes.bool,
    data: PropTypes.array.isRequired,
    keys: PropTypes.array,
    legendShape: PropTypes.string,
    fontSize: PropTypes.string,
    hideTooltipLabel: PropTypes.bool,
    legendAlign: PropTypes.string,
    showCartesianGrid: PropTypes.bool,
    showAxisLine: PropTypes.bool,
    title: PropTypes.string,
    tickFormat: PropTypes.string,
    hideXaxis: PropTypes.bool,
    hideYaxis: PropTypes.bool,
    xAxisType: PropTypes.string,
    yAxisType: PropTypes.string
};

StriimChart.defaultProps = {
    withLegend: true,
    keys: ["category", "value"],
    legendShape: "circle",
    fontSize: "12px",
    hideTooltipLabel: true,
    legendAlign: "center",
    showCartesianGrid: true,
    showAxisLine: false,
    title: "",
    tickFormat: "",
    hideXaxis: false,
    hideYaxis: false,
    xAxisType: "number",
    yAxisType: "number"
};

export default withStyles(styles)(StriimChart);
