export const styles = {
    progressContainer: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        justifyContent: "space-between",
        mt: 2
    },
    readerInfo: {
        flexGrow: 1
    },
    writerInfo: {
        flexGrow: 0
    },
    titleContainer: {
        alignItems: "center",
        mb: 2,
        gap: 1
    }
};
