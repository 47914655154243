const OriginType = {
    Internal: "INTERNAL",
    Ldap: "LDAP",
    Saas: "SAAS"
};

const Action = {
    create: "create",
    drop: "drop",
    update: "update",
    read: "read",
    resume: "resume",
    grant: "grant",
    select: "select",
    start: "start",
    deploy: "deploy",
    undeploy: "undeploy",
    stop: "stop",
    quiesce: "quiesce",
    all: "all",
    memory: "memory",
    checkpoint: "checkpoint"
};

const ObjectType = {
    cluster: "cluster",
    node: "node",
    application: "application",
    propertytemplate: "propertytemplate",
    type: "type",
    stream: "stream",
    window: "window",
    cq: "cq",
    query: "query",
    source: "source",
    externalsource: "externalsource",
    target: "target",
    sorter: "sorter",
    router: "router",
    open_processor: "open_processor",
    propertyset: "propertyset",
    propertyvariable: "propertyvariable",
    wactionstore: "wactionstore",
    exceptionstore: "exceptionstore",
    cache: "cache",
    externalcache: "externalcache",
    wi: "wi",
    alertsubscriber: "alertsubscriber",
    user: "user",
    role: "role",
    permission: "permission",
    server: "server",
    deploymentgroup: "deploymentgroup",
    initializer: "initializer",
    visualization: "visualization",
    subscription: "subscription",
    unknown: "unknown",
    namespace: "namespace",
    flow: "flow",
    stream_generator: "stream_generator",
    dashboard: "dashboard",
    page: "page",
    queryvisualization: "queryvisualization",
    dashboard_ui: "dashboard_ui",
    sourcepreview_ui: "sourcepreview_ui",
    apps_ui: "apps_ui",
    admin_ui: "admin_ui",
    monitor_ui: "monitor_ui",
    connectionprofile: "connectionprofile",
    vault: "vault"
};

export { OriginType, Action, ObjectType };
