import React, { useState } from "react";
import { StriimTypography, StriimButton, StriimLink } from "@striim/striim-ui";
import { UploadModal } from "./";
import Dictionary from "app/components/common/helpable/online-help-dictionary";
import { Box, SvgIcon } from "@mui/material";
import _ from "underscore";
import { Documentation, CloudUpload } from "src/generic/icon/customIcons";

const styles = {
    headerContainer: { display: "flex", alignItems: "center" },
    filesEmptyWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "76vh",
        paddingTop: 2,
        textAlign: "center"
    }
};

const FilesEmpty = ({ fetchData, directories, userDirectory, setFileSelectionModalShown }) => {
    const [isModalShown, setIsModalShown] = useState(false);
    return (
        <>
            <Box sx={styles.headerContainer}>
                <StriimTypography variant="h2">Files</StriimTypography>
                <Box sx={{ ...styles.headerContainer, ml: 1 }}>
                    <SvgIcon
                        component={Documentation}
                        sx={({ palette }) => ({
                            fill: "none",
                            "& > path": {
                                stroke: palette.secondary[500]
                            }
                        })}
                    />
                </Box>
            </Box>
            <StriimTypography color="greyscale.700" variant="caption3">
                Files allow you to upload and store the files.
            </StriimTypography>
            <Box sx={styles.filesEmptyWrapper} data-test-id="files-empty">
                <img src="src/generic/icon/manage-striim/01-ManageStriim-Files-Empty.svg" style={{ width: "200px", margin: "20px" }} />
                <StriimTypography variant="h1">Add your first file</StriimTypography>
                <StriimTypography sx={{ mt: 2, mb: 1 }} variant="body">
                    Files allow you to upload and store the files
                </StriimTypography>
                <StriimLink href={Dictionary.get()["MANAGE_STRIIM_FILES"].href} target="_blank" sx={{ mb: 2 }}>
                    View Documentation
                </StriimLink>
                <StriimButton
                    startIcon={
                        <SvgIcon
                            component={CloudUpload}
                            sx={({ palette }) => ({
                                fill: "none",
                                "& > path": {
                                    stroke: "white"
                                }
                            })}
                        />
                    }
                    variant="primary"
                    onClick={() => {
                        userDirectory && setIsModalShown(true);
                    }}
                    data-test-id="files-upload-file-button"
                >
                    Upload File
                </StriimButton>
            </Box>
            {isModalShown && (
                <UploadModal
                    directories={directories}
                    userDirectory={userDirectory}
                    setIsModalShown={setIsModalShown}
                    fetchData={fetchData}
                    setFileSelectionModalShown={setFileSelectionModalShown}
                />
            )}
        </>
    );
};

export default FilesEmpty;
