export const styles = {
    errorGridContainer: {
        width: 491,
        overflowWrap: "break-word"
    },
    errorItemContainer: {
        flexWrap: "nowrap",
        columnGap: 2
    },
    container: {
        margin: "0 auto",
        gap: ({ spacing }) => spacing(1),
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: ({ spacing }) => `${spacing(5)} 0`
    },
    typography: {
        color: ({ palette }) => palette.greyscale[700]
    },
    content: {
        textAlign: "center",
        display: "block"
    },
    header: {
        color: "primary.700",
        textAlign: "center"
    },
    svgIcon: {
        color: "transparent",
        height: 20,
        width: 18
    },
    viewDocumentation: {
        marginRight: "auto",
        padding: ({ spacing }) => spacing(0.65)
    },
    flowDesignerButton: {
        marginLeft: "auto"
    },
    loadingIndicatorWrapper: {
        width: "100%"
    },
    loadingIndicator: {
        height: 8,
        borderRadius: 4,
        backgroundColor: "greyscale.200",
        " .MuiLinearProgress-bar": {
            backgroundColor: "secondary.500",
            width: 56,
            borderRadius: ({ spacing }) => spacing(0.5)
        },
        ".MuiLinearProgress-bar2Indeterminate": {
            left: -56
        }
    }
};
