import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import useStyles from "./filebrowser.styles";
import _ from "../../../lib/config/underscore";
import LinearProgress from "@material-ui/core/LinearProgress";
import TreeItem from "@material-ui/lab/TreeItem";
import Grid from "@material-ui/core/Grid";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { StriimModal, StriimTypography, StriimMenuItem } from "@striim/striim-ui";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Card from "@material-ui/core/Card";
import FileUploadBox from "./file_upload_box";
import Menu from "@material-ui/core/Menu";
import growl from "app/components/common/growl";
import ManageStriimService from "src/modules/apps/pages/manage-striim/manage-striim-service.js";

let memoize = {};

const FileEntry = observer(({ child, localStore, onDelete }) => {
    const isDirectory = child.isDirectory;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isDeleted, setIsDeleted] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);

    const handleClick = event => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleCopy = async event => {
        event.preventDefault();
        await navigator.clipboard.writeText(child.fullPath);
        growl.success("Copied path: " + child.fullPath, "File path copied.");
        setAnchorEl(null);
    };

    const askToDelete = async event => {
        event.preventDefault();
        setShowConfirm(true);
        setAnchorEl(null);
    };

    const downloadFile = async event => {
        event.preventDefault();
        ManageStriimService.downloadFile(child.fullPath);
    };

    const handleDelete = async event => {
        let res = await fetch("/upload?path=" + encodeURIComponent(child.fullPath), { method: "DELETE" });
        if (res.ok) {
            setIsDeleted(true);
            growl.success("File was deleted. <br /> " + child.fullPath, "File Deleted.");
        }
        setShowConfirm(false);
        setAnchorEl(null);
        onDelete(child);
    };

    const handleClose = event => {
        event.preventDefault();
        setAnchorEl(null);
    };

    const setSelected = function(value) {
        if (value.isDirectory) {
            localStore.setDirectoryToUpload(value.fullPath);
            localStore.selected && localStore.setSelected(null);
        } else {
            localStore.setSelected(value);
            localStore.setDirectoryToUpload(value.folderPath || localStore.defaultPath);
        }
    };

    return (
        <div>
            {isDeleted ? (
                <></>
            ) : (
                <div>
                    <TreeItem
                        nodeId={child.fullPath}
                        classes={{
                            label: classes.itemLabel,
                            content: classes.itemContent,
                            selected: classes.selectedItem,
                            group: classes.itemGroup
                        }}
                        label={
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item className={classes.fileBrowserEntry}>
                                    {isDirectory ? (
                                        <FolderOpenOutlinedIcon className={classes.fileBrowserEntryIcon} />
                                    ) : (
                                        <DescriptionOutlinedIcon className={classes.fileBrowserEntryIcon} />
                                    )}
                                    {child.name}
                                </Grid>
                                <Grid item className={classes.menuIcon} data-test-id="actions-menu-icon">
                                    <MoreHorizIcon onClick={handleClick} />
                                </Grid>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <StriimMenuItem onClick={handleCopy}>Copy Path</StriimMenuItem>
                                    {!isDirectory && <StriimMenuItem onClick={askToDelete}>Delete File</StriimMenuItem>}
                                    {!isDirectory && (
                                        <StriimMenuItem onClick={downloadFile} data-test-id="download-file">
                                            Download File
                                        </StriimMenuItem>
                                    )}
                                </Menu>
                            </Grid>
                        }
                        key={child.fullPath}
                        onClick={() => {
                            setSelected(child);
                        }}
                    >
                        {isDirectory ? <DirectoryNode root={child.fullPath} localStore={localStore} /> : null}
                    </TreeItem>

                    <StriimModal
                        size="small"
                        autoHeight
                        isVisible={showConfirm}
                        onConfirm={() => {
                            setShowConfirm(false);
                            handleDelete();
                        }}
                        onCancel={() => {
                            setShowConfirm(false);
                        }}
                        variant="error"
                        confirmContent={"Delete"}
                        titleContent={<StriimTypography variant="h2">Please confirm this operation</StriimTypography>}
                        dialogContentProps={{
                            classes: { root: classes.dialogContentRoot }
                        }}
                    >
                        <StriimTypography variant="body4">
                            Are you sure you want to delete <b>{child.name}</b> ?
                        </StriimTypography>
                    </StriimModal>
                </div>
            )}
        </div>
    );
});

const DirectoryNode = observer(({ root, localStore }) => {
    const [children, setChildren] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const deleteFile = function(child) {
        let temp_children = [];
        children.forEach(temp_child => {
            if (temp_child.fullPath !== child.fullPath) {
                temp_children.push(temp_child);
            } else {
                localStore.setSelected(null);
                delete memoize[root]; // this is the one that's getting delete, so let's clear the cache
            }
        });
        setChildren(temp_children);
    };

    let getChildren = async () => {
        if (memoize[root]) {
            localStore.setDirectoryToUpload(memoize[root].currentPath);
            setChildren(memoize[root].children);
        } else {
            setIsLoading(true);
            fetch("/file_browser?path=" + root)
                .then(response => response.json())
                .then(data => {
                    if (root === "") {
                        localStore.setDefaultPath(data.currentPath);
                        localStore.setDirectoryToUpload(data.currentPath);
                    }
                    if (data.children) {
                        data.children = _.sortBy(data.children, function(child) {
                            child.fullPath = child.currentPath;
                            child.folderPath = root;
                            return !child.isDirectory;
                        });
                        data.children = data.children.filter(file => file.name[0] !== ".");
                        memoize[root] = data;
                        setChildren(data.children);
                    }
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        getChildren();
    }, []);

    return (
        <>
            {isLoading ? <LinearProgress /> : <> </>}
            {children.length === 0 ? (
                <TreeItem
                    key={root + "/children"}
                    nodeId={root + "/children"}
                    label={"No Files under this directory"}
                />
            ) : (
                <></>
            )}
            {children.map(child => {
                return <FileEntry localStore={localStore} child={child} key={child.fullPath} onDelete={deleteFile} />;
            })}
        </>
    );
});

export const FileBrowserContent = observer(({ localStore }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState(null);

    // Deselects when clicked on empty space in the fileBrowserContainer
    const deSelect = e => {
        if (e.target.id === "fileBrowserContainer") {
            setExpanded([]);
            setSelected(null);
            localStore.setSelected(null);
            localStore.setDirectoryToUpload(localStore.defaultPath);
        }
    };
    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };
    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };

    return (
        <div className={classes.modalContainer}>
            <StriimTypography variant={"h3"}>Select Existing File</StriimTypography>
            <div className={classes.fileBrowserContainer} id="fileBrowserContainer" onClick={deSelect}>
                <TreeView
                    className={classes.fileBrowserRoot}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                >
                    <DirectoryNode root={""} localStore={localStore} />
                </TreeView>
            </div>
            <StriimTypography variant={"h3"}>Or Upload a file</StriimTypography>
            <Card className={classes.uploadBox}>
                <FileUploadBox localStore={localStore} />
            </Card>
        </div>
    );
});

export function ClearMemory() {
    memoize = {};
}
export default {
    FileBrowserContent: FileBrowserContent,
    ClearMemory: ClearMemory
};
