import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import App from "app";
import single_select_search_template from "./templates/single_select_text_dropdown.html";

var SingleSelectItemView = Backbone.Marionette.ItemView.extend({
    template: _.template("<label for={{cid}}> {{name}} </label><span>{{shortInfo}}</span>"),
    tagName: "li",
    className: "js-show collection-item",
    initialize: function (options) {
        this.model.set("cid", options.model.cid);
        if (options.model.get("isChecked")) {
            this.$el.addClass("active");
        }
    },
    triggers: {
        click: "selectedValues",
    },
    modelEvents: {
        isChecked: "render",
        isHidden: "render",
    },
    onRender: function () {
        if (this.model.get("isHidden")) {
            this.$el.hide();
            this.$el.removeClass("js-show");
        } else {
            this.$el.addClass("js-show");
        }
    },
});

export default Backbone.Marionette.CompositeView.extend({
    template: _.template(single_select_search_template),
    className: "searchable-select",
    getChildView: function () {
        return SingleSelectItemView;
    },
    childViewContainer: "@ui.listData",
    ui: {
        listData: ".type-list",
        doneButton: ".apply",
        filterText: ".text-search",
        cancelButton: ".clear",
    },
    events: {
        click: function (evt) {
            evt.stopPropagation();
        },
        "keyup @ui.filterText": function (event) {
            this.targetNumber = -1;
            var enteredValue = event.currentTarget.value;
            _.each(this.collection.models, function (datum) {
                if (datum.get("name").toUpperCase().indexOf(enteredValue.toUpperCase()) !== -1) {
                    datum.set("isHidden", false); // Flag to decide object dropdown show/hide based on user entered text in searchbox.
                } else {
                    datum.set("isHidden", true);
                }
            });
            this.collection.reset(this.collection.models);
        },
        "click @ui.cancelButton": function (evt) {
            this.$el.parent().hide();
            this.ui.filterText.val("");
            this.$el.find("li").removeClass("active");
            this.$el.parent().parent().find(".mb-ellipses").html(this.$el.parent().parent().attr("data"));
            this.$el.parent().parent().find(".mb-ellipses").attr("title", this.$el.parent().parent().attr("data"));
            _.invoke(this.collection.models, "set", {
                isChecked: false,
                isHidden: false,
            });
            this.collection.reset(this.collection.models);
            this.trigger("selectedValues");
            evt.stopPropagation();
        },
    },
    childEvents: {
        selectedValues: function (child) {
            this.$el.find("li").removeClass("active");
            child.$el.addClass("active");
            this.$el.parent().parent().find(".mb-ellipses").html(child.model.get("name"));
            this.$el.parent().parent().find(".mb-ellipses").attr("title", child.model.get("name"));
            _.invoke(this.collection.models, "set", {
                isChecked: false,
                isHidden: false,
            });
            child.model.set("isChecked", true);
            this.trigger("selectedValues", child.model.get("name"), child.model.get("shortInfo"));
            this.ui.filterText.val("");
            this.collection.reset(this.collection.models);
        },
    },
    /**
     * dataArray - Object array to display
     * extraInfoMap - extra information to show of element in dataArray
     */
    initialize: function (options) {
        this.collection = new Backbone.Collection();
        this.extraInfoMap = options.extraInfoMap || {};
        var objArr = [],
            _this = this;

        _.each(options.dataArray, function (data, index) {
            var obj = {};
            obj.name = data;
            obj.isChecked = false;
            obj.shortInfo = _this.extraInfoMap[index] ? _this.extraInfoMap[index].shortInfo : "";
            objArr.push(obj);
        });
        this.collection.set(objArr);
        this.targetNumber = -1;
        this.listenTo(this, "setValue", function (value) {
            var item = _this.collection.where({
                name: value,
            });
            if (item.length > 0) {
                _.invoke(this.collection.models, "set", {
                    isChecked: false,
                    isHidden: false,
                });
                item[0].set("isChecked", true); // since this is single select so taking only first element assuming collection will return only one element.
                _this.$el.parent().parent().find(".mb-ellipses").html(value);
                _this.$el.parent().parent().find(".mb-ellipses").attr("title", value);
                _this.collection.reset(_this.collection.models);
            }
        });
    },
    onRender: function () {
        this.ui.listData.slimScroll({
            height: "220px",
            alwaysVisible: true,
        });
    },
});
