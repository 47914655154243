

export const styles = {
    bar: {
        height: 16,
        backgroundColor: "#7E7ED6",
        borderRadius: '0px 2px 2px 0px'
    },
    icon: {
        fontSize: 16,
        fill: "none"
    },
    iconInfo: {
        height: 16,
        width: 16,
        fill: "transparent",
        "& path": {
            stroke: theme => theme.palette.greyscale[600]
        }
    },
    warningIcon: {
        width: 20,
        height: 20,
        fill: ({ palette }) => palette.warning[500],
        "& > path": {
            stroke: ({ palette }) => palette.additional.white
        }

    },
    dataTableWrapper: {
        "& .appNamecellClass": {
            width: "inherit",
            display: "flex",
            "& .ag-cell-wrapper": {
                width: "inherit"
            },
            "& .ag-cell-wrapper > span > div": {
                display: "flex",
                flexWrap: "nowrap"
            }
        },
        "& .header-center.no-hover": {
            paddingLeft: "0 !important"
        }
    },
    link: {
        fontWeight: 400,
        height: 0,
        fontSize: '12px'
    }
};
