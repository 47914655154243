import React, { useMemo } from "react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList, Tooltip, Label } from "recharts";
import { StriimTypography } from "@striim/striim-ui";
import { Box, Grid } from "@mui/material";
import numeral from "numeral";

import { styles } from "./sdi-chart.styles";
import { actionColors } from "../sensitive-data-actions-chart/sensitive-data-actions";
import { ImportanceLevelIconPaths, ImportanceLevels } from "../../utils";

const getFormattedChartData = (data: Data[]) => {
    const formattedList = data.map(item => ({
        ...item,
        dataIdentifier: item.dataIdentifier
    }));
    const otherData =
        formattedList.length > 5
            ? formattedList.slice(4).reduce((accumulated, current) => {
                  accumulated["occurrences"] = (accumulated["occurrences"] ?? 0) + current.occurrences;

                  if (accumulated["actions"]) {
                      const accumulatedActions = accumulated["actions"];
                      accumulated["actions"] = {
                          encrypted: accumulatedActions.encrypted + (current.actions.encrypted ?? 0),
                          masked: accumulatedActions.masked + (current.actions.masked ?? 0),
                          noAction: accumulatedActions.noAction + (current.actions.noAction ?? 0)
                      };
                  } else {
                      accumulated["actions"] = {
                          encrypted: 0,
                          masked: 0,
                          noAction: 0
                      };
                  }
                  return accumulated;
              }, {})
            : null;
    return [
        ...formattedList.slice(0, 4),
        ...(otherData
            ? [
                  {
                      dataIdentifier: "Others",
                      occurrences: otherData["occurrences"],
                      actions: otherData["actions"],
                      items: formattedList.slice(4)
                  }
              ]
            : formattedList[4]
            ? [formattedList[4]]
            : [])
    ];
};

const renderCustomizedNameLabel = (props, data) => {
    const { x, y, value, index } = props;
    const importanceLevel = data[index].importanceLevel;
    const iconFilePath = importanceLevel ? ImportanceLevelIconPaths[importanceLevel] : null;
    const xPosition = iconFilePath ? 22 : 0;

    return (
        <g>
            {iconFilePath ? <image href={iconFilePath} x={2} y={y - 18} /> : <></>}
            <text
                x={xPosition}
                y={y - 9}
                fill="#48525C"
                stroke="initial"
                fontFamily="Inter"
                fontSize={12}
                fontWeight={400}
                dominantBaseline="middle"
                textAnchor="start"
                letterSpacing={"0.24px"}
            >
                {value}
            </text>
        </g>
    );
};

const renderCustomizedValueLabel = (props, width) => {
    const { y, value } = props;

    return (
        <g>
            <text
                x={width}
                y={y - 9}
                fill="#48525C"
                stroke="initial"
                fontFamily="Inter"
                fontSize={12}
                fontWeight={400}
                dominantBaseline="middle"
                textAnchor="end"
                letterSpacing={"0.24px"}
            >
                {numeral(value).format("0.[00]a")}
            </text>
        </g>
    );
};

const TooltipListItem = ({
    label,
    value,
    percentage,
    type
}: {
    label: string;
    value: any;
    percentage: number;
    type: "encrypted" | "masked" | "noAction";
}) => {
    return (
        <Grid container justifyContent={"space-between"}>
            <Grid display="flex" item gap={0.5} alignItems={"center"}>
                <Box borderRadius={"2px"} width={"6px"} height={"6px"} sx={{ backgroundColor: actionColors[type] }} />
                <StriimTypography variant="caption3" color="greyscale.700" fontFamily="Inter">
                    {label}
                </StriimTypography>
            </Grid>
            <Grid item>
                <StriimTypography variant="caption3" color="primary.700" fontFamily="Inter">
                    {value}{" "}
                </StriimTypography>
                <StriimTypography variant="caption3" color="primary.700" fontFamily="Inter">
                    {percentage ? `(${percentage | 0}%)` : null}
                </StriimTypography>
            </Grid>
        </Grid>
    );
};

const CustomToolTipComponent = ({ title, data, isGrouped }: { title: string; data: any[]; isGrouped: boolean }) => {
    return (
        <Grid
            container
            flexDirection={"column"}
            gap={1.5}
            sx={[styles.tooltipWrapper, isGrouped ? styles.tooltipGrouped : null]}
        >
            <Grid display="flex" item flexDirection={"column"}>
                <StriimTypography variant="caption1" color="greyscale.900" sx={styles.tooltipText}>
                    Actions Taken on
                </StriimTypography>
                <StriimTypography variant="caption1" color="greyscale.900" sx={styles.tooltipTitle}>
                    {title}
                </StriimTypography>
            </Grid>
            {data.map(item => (
                <>
                    {isGrouped && (
                        <StriimTypography variant="caption3" color="greyscale.700" sx={styles.tooltipTitle}>
                            {item.dataIdentifier}
                        </StriimTypography>
                    )}
                    <Grid
                        key={item.dataIdentifier}
                        ml={isGrouped ? 1.5 : 0}
                        mt={isGrouped ? -1 : 0}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"space-between"}
                        item
                    >
                        <TooltipListItem
                            label={"Encrypted"}
                            type={"encrypted"}
                            value={item.actions.encrypted ?? 0}
                            percentage={item.actions.encrypted ? (item.actions.encrypted / item.occurrences) * 100 : 0}
                        />
                        <TooltipListItem
                            label={"Masked"}
                            type={"masked"}
                            value={item.actions.masked ?? 0}
                            percentage={item.actions.masked ? (item.actions.masked / item.occurrences) * 100 : 0}
                        />
                        <TooltipListItem
                            label={"No Action"}
                            type={"noAction"}
                            value={item.actions.noAction ?? 0}
                            percentage={item.actions.noAction ? (item.actions.noAction / item.occurrences) * 100 : 0}
                        />
                    </Grid>
                </>
            ))}
        </Grid>
    );
};

export const CustomTooltip = ({ active, payload }) => {
    if (active && payload?.length) {
        let title, data, isGrouped;
        if (payload[0].payload?.dataIdentifier === "Others") {
            title = "Other Sensitive Data Identifiers";
            data = payload[0].payload.items;
            isGrouped = true;
        } else {
            title = payload[0].payload.dataIdentifier;
            data = [payload[0].payload];
            isGrouped = false;
        }
        return <CustomToolTipComponent title={title} data={data} isGrouped={isGrouped} />;
    }
    return null;
};

type Actions = {
    encrypted?: number;
    masked?: number;
    noAction?: number;
};

export type SDIChartData = {
    dataIdentifier: string;
    occurrences: number;
    actions: Actions;
    importanceLevel: ImportanceLevels;
};

interface SDIChartProps {
    sensitiveDataOccurrences: number;
    data: SDIChartData[];
    showBottomBorder?: boolean;
    width?: number;
}

const SDIChart = ({ sensitiveDataOccurrences, data, showBottomBorder = true, width = 367 }: SDIChartProps) => {
    const sensitiveDataIdentifiers = data.length;
    const chartHeight = 48 * (data.length > 5 ? 5 : data.length);

    const updatedData = useMemo(() => {
        return getFormattedChartData(data);
    }, [data]);

    return (
        <Grid container p={2} gap={0.5} flexDirection={"column"} sx={[showBottomBorder && styles.bottomBorder]}>
            <Grid container>
                <StriimTypography
                    variant="caption1"
                    color="greyscale.900"
                    fontSize={14}
                    lineHeight={"20px"}
                    fontWeight={400}
                    fontFamily="Inter"
                >
                    Sensitive Data Identifiers
                </StriimTypography>
            </Grid>
            <Grid container pb={2}>
                <StriimTypography
                    variant="caption1"
                    fontSize={"22px"}
                    lineHeight={"32px"}
                    fontFamily="Nunito"
                    sx={styles.sensitiveDataCount}
                >
                    {sensitiveDataIdentifiers}
                </StriimTypography>
            </Grid>
            <Grid container justifyContent={"space-between"} pb={2}>
                <StriimTypography variant="caption4" sx={styles.chartLegend}>
                    DATA IDENTIFER
                </StriimTypography>
                <StriimTypography variant="caption4" sx={styles.chartLegend}>
                    OCCURRENCES (OF {numeral(sensitiveDataOccurrences).format("0.[00]a")})
                </StriimTypography>
            </Grid>
            <Grid container height={chartHeight}>
                <ResponsiveContainer width="100%">
                    <BarChart
                        data={updatedData}
                        layout="vertical"
                        margin={{
                            left: -0.5
                        }}
                        style={styles.barChart}
                    >
                        <XAxis hide type="number" domain={[0, sensitiveDataOccurrences]} />
                        <YAxis hide type="category" />
                        <Tooltip
                            content={<CustomTooltip />}
                            wrapperStyle={{ pointerEvents: "auto" }}
                            cursor={false}
                            position={{ x: 0, y: -80 }}
                        />
                        <Bar
                            dataKey="actions.encrypted"
                            stackId="single-stack"
                            fill={actionColors.encrypted}
                            background={{ fill: "#EBF2FA", radius: 4 }}
                            barSize={10}
                            radius={[2, 0, 0, 2]}
                            maxBarSize={20}
                        >
                            <LabelList
                                dataKey="dataIdentifier"
                                content={props => renderCustomizedNameLabel(props, updatedData)}
                            />
                            <LabelList
                                dataKey="occurrences"
                                content={props => renderCustomizedValueLabel(props, width)}
                            />
                        </Bar>
                        <Bar dataKey="actions.masked" stackId="single-stack" fill={actionColors.masked} />
                        <Bar dataKey="actions.noAction" stackId="single-stack" fill={actionColors.noAction} />
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
    );
};

export default SDIChart;
