import React, { useState } from "react";
import classNames from "classnames";
import { StriimModal, StriimTypography, StriimRadioGroup, StriimControlLabel, StriimRadio } from "@striim/striim-ui";
import PropTypes from "prop-types";
import useStyles from "./add-dashboard-dialog.styles";
import AddNewDashboard from "./components/add-new-dashboard";
import ImportDashboard from "./components/import-dashboard";
import { Box, Grid } from "@mui/material";
import { Close } from "src/generic/icon/customIcons";
import { StriimIconButton } from "@striim/striim-ui-v2";

const AddDashboardModal = ({ isVisible, setIsAddDashboardDialogVisible }) => {
    const classes = useStyles();
    const [currentRadio, setCurrentRadio] = useState("scratch");

    return (
        <StriimModal
            onCancel={setIsAddDashboardDialogVisible}
            isVisible={isVisible}
            disableMaxHeight
            size="medium"
            dialogActionProps={{
                classes: {
                    root: classes.dialogAction
                }
            }}
            dialogContentProps={{
                classes: { root: classes.overflowVisible }
            }}
            dialogProps={{
                classes: { paper: classNames(classes.overflowVisible, classes.heightAuto) }
            }}
            titleContent={
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <StriimTypography variant="h2" data-test-id="add-dashboard-button">
                            Add Dashboard
                        </StriimTypography>
                    </Grid>
                    <Grid item>
                        <StriimIconButton onClick={() => setIsAddDashboardDialogVisible(false)}><Close/></StriimIconButton>
                    </Grid>
                </Grid>
            }
        >
            <Box id="dashboards--add-dashboard-modal" className={classes.modal}>
                <StriimRadioGroup
                    sx={{py: 3}}
                    row
                    name={"add dashboard"}
                    value={currentRadio}
                    onChange={e => setCurrentRadio(e.currentTarget.value)}
                >
                    <StriimControlLabel data-test-id="dashboard-scratch-tab" value="scratch" label="Start from scratch" control={<StriimRadio />} />
                    <StriimControlLabel data-test-id="dashboard-import-tab" value="import" label="Import" control={<StriimRadio />} />
                </StriimRadioGroup>
                {currentRadio == "scratch" && <AddNewDashboard />}
                {currentRadio == "import" && <ImportDashboard />}
            </Box>
        </StriimModal>
    );
};

AddDashboardModal.propTypes = {
    isVisible: PropTypes.bool,
    setIsAddDashboardDialogVisible: PropTypes.func
};

export default AddDashboardModal;
