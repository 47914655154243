import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing }) => ({
    componentIcon: {
        width: ({ iconSize }) => iconSize,
        height: ({ iconSize }) => iconSize,
        padding: spacing(0.5)
    },
    componentIconText: {
        fontSize: spacing(2),
        lineHeight: spacing(2),
        letterSpacing: spacing(-0.04),
        color: palette.common.white,
        marginTop: 0,
        marginBottom: 0
    },
    mirror: {
        position: "absolute",
        width: spacing(1),
        height: spacing(1.5),
        bottom: "0px",
        right: "0px"
    },
    iconContainer: {
        height: spacing(4),
        position: "relative"
    },
    iconWrapper: {
        width: ({ iconSize }) => iconSize,
        height: ({ iconSize }) => iconSize
    }
}));
