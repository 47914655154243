import { action, observable, computed } from "mobx";
import { v4 } from "uuid";
import api from "../../../core/api/api";

export const SOURCE_LOCAL = "Local";
export const SOURCE_MESSAGE_LOG = "Message Log";
export const SESSIONSTORAGE_KEY = "striim_notifications";
const QUERY_BATCH_LIMIT = 25;

class LocalStore {
    @observable events = [];
    @observable remoteEvents = [];
    @observable volatileGrowls = [];
    @observable isAPILoading = false;
    @observable _keyWord = "";
    @observable _severity = null;
    @observable _component = null;
    @observable _startTime = null;
    @observable _endTime = null;
    @observable _paginationNextTime = null;
    @observable componentsAvailable = new Set();

    constructor() {
        let persistedEvents = sessionStorage.getItem(SESSIONSTORAGE_KEY);
        if (persistedEvents) {
            persistedEvents = JSON.parse(persistedEvents);
            persistedEvents.forEach(persistedEvent => {
                if (!persistedEvents.id) persistedEvents.id = v4();
                if (persistedEvent.componentName) this.componentsAvailable.add(persistedEvent.componentName);
                if (persistedEvent.applicationName) this.componentsAvailable.add(persistedEvent.applicationName);
                persistedEvent.date = new Date(persistedEvent.date);
            });
            this.events = persistedEvents;
        }
        this.resetTime();
    }

    /**
     *
     * @param obj {{severity: (string), message: string, id: *, componentName: (string), title: string, shouldGrowl: boolean, hasGrowled: boolean,  applicationName: string, source: string }}
     */
    @action addLocalEvent(obj) {
        if (!obj.date) obj.date = new Date();
        if (!obj.id) obj.id = v4();
        if (obj.componentName) this.componentsAvailable.add(obj.componentName);
        if (obj.applicationName) this.componentsAvailable.add(obj.applicationName);
        for (let i = 0; i < this.events.length; i++) {
            let itemInTheStore = this.events[i];
            if (itemInTheStore.date.getTime() <= obj.date.getTime()) {
                this.events.splice(i, 0, obj);
                this.persistEvents();
                return;
            }
        }
        this.events.unshift(obj);
        this.persistEvents();
    }

    @action addVolatileGrowl(obj) {
        if (!obj.date) obj.date = new Date();
        this.volatileGrowls.push(obj);
        this.addComponentAvailable(obj.componentName);
        this.addComponentAvailable(obj.applicationName);
    }

    @computed
    get filteredResults() {
        function merge(left, right) {
            let arr = [];
            let i = 0;
            let j = 0;
            if (!right.length) return left;
            if (!left.length) return right;
            while (i + j < left.length + right.length) {
                if (i === left.length || j === right.length) {
                    break;
                }
                if (left[i].date > right[j].date) {
                    arr.push(left[i]);
                    i++;
                } else {
                    arr.push(right[j]);
                    j++;
                }
            }

            arr = [...arr, ...left.slice(i), ...right.slice(j)];
            return arr;
        }

        let results = merge(this.events, this.remoteEvents);

        if (this._keyWord) {
            results = this.getKeywordFilteredResults(results);
        }

        if (this._severity) {
            results = this.getSeverityFilteredResults(results);
        }

        if (this._component) {
            results = this.getComponentFilterResults(results);
        }

        if (this._startTime) {
            results = this.getStartTimeFilteredResults(results);
        }
        if (this._endTime) {
            results = this.getEndTimeFilteredResults(results);
        }
        return results;
    }

    @computed
    get isFiltered() {
        if (this._keyWord || this._severity || this._component || this._endTime || this._startTime) {
            return true;
        }
        return false;
    }

    get component() {
        return this._component;
    }

    @action
    setComponent(value) {
        this._component = value;
        this._paginationNextTime = null;
    }
    get severity() {
        return this._severity;
    }

    @action
    setSeverity(value) {
        this._severity = value;
        this._paginationNextTime = null;
    }

    get startTime() {
        return this._startTime;
    }

    @action
    setStartTime(value) {
        this._startTime = value;
        this._paginationNextTime = null;
    }

    get endTime() {
        return this._endTime;
    }

    @action
    setEndTime(value) {
        this._endTime = value;
        this._paginationNextTime = null;
    }

    @action
    setRemoteEvents(events) {
        this.remoteEvents = events;
    }

    @action
    addComponentAvailable(componentName) {
        if (componentName && componentName !== "null") this.componentsAvailable.add(componentName);
    }

    get keyWord() {
        return this._keyWord;
    }
    @action
    setKeyWord(value) {
        this._keyWord = value;
        this._paginationNextTime = null;
    }
    @action reset() {
        this.events = [];
        this.volatileGrowls = [];
        this.remoteEvents = [];
        this.resetTime();
        sessionStorage.removeItem(SESSIONSTORAGE_KEY);
    }

    @action resetTime() {
        // const now = dayjs();
        // const lastWeek = now.subtract(7, "days");
        // this.setStartTime(lastWeek.toDate());
        // this.setEndTime(now.toDate());
    }

    @action fetchEvents(loadMore) {
        let filterStrings = [];
        this.isAPILoading = true;
        if (this.keyWord) {
            filterStrings.push(`title like "%${this.keyWord}%" OR message like "%${this.keyWord}%"`);
        }
        if (this.component) {
            filterStrings.push(
                `componentName like "%${this.component}%" OR applicationName like "%${this.component}%"`
            );
        }
        if (this.severity) {
            let severityString = this.severity
                .map(severity => {
                    return 'messageType = "' + severity + '"';
                })
                .join(" OR ");
            filterStrings.push(severityString);
        }
        if (this.startTime) {
            filterStrings.push(`time > ${this.startTime.valueOf()}L`);
        }
        if (this.endTime) {
            filterStrings.push(`time < ${this.endTime.valueOf()}L`);
        }
        if (this._paginationNextTime) {
            filterStrings.push(`time < ${this._paginationNextTime}L`);
        }
        let filterString = filterStrings.join(" AND ");
        let query = `SELECT *  FROM System$Notification.notificationStore ${
            filterString ? " WHERE " + filterString : ""
        } ORDER BY time DESC LIMIT ${QUERY_BATCH_LIMIT};`;
        api.getHistoricalMessageData(query).done(data => {
            data.forEach(entry => {
                entry.date = new Date(entry.time);
                entry.source = SOURCE_MESSAGE_LOG;
                entry.severity = entry.messageType;
                entry.id = entry.notificationID;
            });
            if (data?.length === QUERY_BATCH_LIMIT) {
                this._paginationNextTime = data[QUERY_BATCH_LIMIT - 1].time;
            } else {
                this._paginationNextTime = null;
            }
            if (loadMore) {
                data.forEach(entry => {
                    this.remoteEvents.push(entry);
                });
            } else {
                this.setRemoteEvents(data);
            }

            this.isAPILoading = false;
        });
    }

    persistEvents() {
        sessionStorage.setItem(SESSIONSTORAGE_KEY, JSON.stringify(this.events));
    }

    getKeywordFilteredResults(results) {
        results = results.filter(event => {
            if (event.message && event.message?.toLowerCase().indexOf(this._keyWord.toLowerCase()) !== -1) return true;
            if (event.title && event.title?.toLowerCase().indexOf(this._keyWord.toLowerCase()) !== -1) return true;
            return false;
        });
        return results;
    }

    getSeverityFilteredResults(results) {
        results = results.filter(event => {
            return this._severity.indexOf(event.severity) !== -1;
        });
        return results;
    }

    getComponentFilterResults(results) {
        results = results.filter(event => {
            return (
                (event.componentName && this._component.indexOf(event.componentName) !== -1) ||
                (event.applicationName && this._component.indexOf(event.applicationName) !== -1)
            );
        });
        return results;
    }

    getStartTimeFilteredResults(results) {
        results = results.filter(event => {
            return this._startTime.getTime() < event.date.getTime();
        });
        return results;
    }

    getEndTimeFilteredResults(results) {
        results = results.filter(event => {
            return this.endTime.getTime() >= event.date.getTime();
        });
        return results;
    }
}
const localStore = new LocalStore();
export default localStore;
