import React, { useState } from "react";
import { Box, Grid, InputAdornment } from "@mui/material";
import {
    StriimInputField,
    StriimModal,
    StriimTypography,
    StriimTooltip,
    StriimButton,
    ClearMemory
} from "@striim/striim-ui-v2";
import { styles } from "src/modules/apps/pages/user-management/users/components/sso/sso-container.styles.js";
import FolderIcon from "src/generic/icon/manage-striim/folder.svg";
import FileSelectModalContent from "../../modules/apps/pages/user-management/users/components/sso/file-select-modal-content";
import { Close } from "../icon/customIcons";

interface filebrowserProps {
    label: React.ReactNode | string;
    placeholder?: string;
    errorText?: string;
    enabled: boolean;
    value: string;
    onChange: Function;
    error?: boolean;
    name: string;
}
interface selectedFileType {
    isDirectory: boolean;
    currentPath: string;
    name: string;
    fullPath: string;
    folderPath: string;
    path?: string;
}
const FileBrowser = (props: filebrowserProps) => {
    const { enabled = true, value = "", onChange, error = false, label = "", placeholder = "", errorText = "" } = props;
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<selectedFileType | null>(null);
    return (
        <Box>
            <Box sx={{ width: "100%" }}>
                <StriimInputField
                    id="form-field-file-input"
                    disabled={!enabled}
                    label={label}
                    value={value}
                    onChange={value => {
                        onChange(value);
                    }}
                    placeholder={placeholder}
                    InputProps={{
                        // onClick: () => setShowModal(true),
                        endAdornment: (
                            <InputAdornment position="end">
                                {value && (
                                    <Close
                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                            onChange("");
                                            e.stopPropagation();
                                        }}
                                        style={{ fontSize: "20px", fill: "none", cursor: "pointer" }}
                                    />
                                )}
                                <FolderIcon
                                    id="folder-icon"
                                    onClick={() => setShowModal(true)}
                                    style={{ fontSize: "20px", fill: "none", cursor: "pointer" }}
                                />
                            </InputAdornment>
                        )
                    }}
                    error={error}
                    helperText={error ? errorText : ""}
                ></StriimInputField>
            </Box>
            {showModal && (
                <StriimModal
                    size="medium"
                    autoHeight
                    isVisible={showModal}
                    footerBorder
                    footerContent={
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item sx={styles.selectedFileName}>
                                {selectedFile && (
                                    <>
                                        <StriimTypography variant="caption3" sx={styles.modalFooterText}>
                                            Selected File{" "}
                                        </StriimTypography>
                                        <StriimTooltip title={selectedFile.name}>
                                            <StriimTypography
                                                variant="caption3"
                                                color="secondary.500"
                                                sx={styles.modalFooterText}
                                            >
                                                {selectedFile.name}
                                            </StriimTypography>
                                        </StriimTooltip>
                                    </>
                                )}
                            </Grid>
                            <Grid item>
                                <StriimButton
                                    variant="text"
                                    onClick={() => {
                                        setSelectedFile(null);
                                        ClearMemory();
                                        setShowModal(false);
                                    }}
                                >
                                    Cancel
                                </StriimButton>
                                <StriimButton
                                    disabled={!selectedFile}
                                    data-test-id={"confirm-select-file"}
                                    onClick={() => {
                                        ClearMemory();
                                        onChange && onChange(selectedFile?.currentPath ?? selectedFile.path);
                                        setShowModal(false);
                                    }}
                                    variant="primary"
                                >
                                    Done
                                </StriimButton>
                            </Grid>
                        </Grid>
                    }
                >
                    <FileSelectModalContent
                        selectedFile={selectedFile}
                        onCancel={() => setShowModal(false)}
                        onSelected={(file: selectedFileType) => {
                            setSelectedFile(file);
                        }}
                        allowedExtensions={[]}
                    />
                </StriimModal>
            )}
        </Box>
    );
};

export default FileBrowser;
