import App from "app";
import "app/components/dashboard/visualization/charts/seriesgenerators/default/generator";

function getObjectFromJsonOrObject(str) {
    try {
        return JSON.parse(str);
    } catch (e) {
        console.error(e);
        return str;
    }
}

App.module("Chart.SeriesGenerators.Prediction", function (Generator, App, Backbone, Marionette, $, _) {
    Generator.SeriesGeneratorFactory = function () {};

    Generator.SeriesGeneratorFactory.prototype.create = function (options) {
        if (options.predictiveType === "realTime") {
            return new PredictionSeriesGeneratorRealTime(options);
        }
        if (options.predictiveType === "diagnostics") {
            return new PredictionSeriesGeneratorDiagnostics(options);
        }
    };

    function PredictionSeriesGeneratorRealTime(options) {
        this.historySize = options.historySize;
        this.historyResolution = options.historyResolution;
        this.options = options;
        App.Chart.SeriesGenerators.Default.SeriesGenerator.prototype.setOptions.call(this, options);
    }

    PredictionSeriesGeneratorRealTime.prototype = new App.Chart.SeriesGenerators.Default.SeriesGenerator();
    PredictionSeriesGeneratorRealTime.prototype.generate = function () {
        var dataSeries = [];
        if (typeof this.data !== "undefined") {
            dataSeries = this.generateSeries();
        }
        return dataSeries;
    };

    PredictionSeriesGeneratorRealTime.prototype.generateSeries = function () {
        var resultField = this.options.resultField;
        var observedTimeField = this.options.observedTimeField;
        var observedValueField = this.options.observedValueField;

        if (resultField === null || observedTimeField === null || observedValueField === null) {
            return [];
        }
        var seriesData = [];
        this.data = _chunkDataByHistory(this.data, this.historyResolution, this.historySize, this.options);
        var last = _.last(this.data);

        var historicData = {};
        historicData.data = [];
        historicData.dashStyle = "Solid";
        historicData.name = "Value";
        historicData.color = "#FF0000";
        $.each(this.data, function (i, j) {
            historicData.data.push({
                x: j[observedTimeField],
                y: j[observedValueField],
            });
        });

        var uppperBoundSeries = _getPredictionSeriesObject("Upper Bound");
        uppperBoundSeries.color = "#EC8209";

        var lowerBoundSeries = _getPredictionSeriesObject("Lower Bound");
        lowerBoundSeries.color = "#EC8209";

        var predictionSeries = _getPredictionSeriesObject("Prediction");
        predictionSeries.color = "#a2cf0f";

        var lastEvent = last;
        if (lastEvent) {
            try {
                var predictionPoints = getObjectFromJsonOrObject(lastEvent[resultField]);
                $.each(predictionPoints, function (i, j) {
                    uppperBoundSeries.data.push({
                        x: new Date(j.timestamp),
                        y: j.upper_boundary,
                    });
                    lowerBoundSeries.data.push({
                        x: new Date(j.timestamp),
                        y: j.lower_boundary,
                    });
                    predictionSeries.data.push({
                        x: new Date(j.timestamp),
                        y: j.prediction,
                    });
                });
            } catch (e) {
                console.error(e);
                // DEV-5876 - lastEvent[resultField] sometimes returns empty string which causes JSON.parse exception. In this case we can ignore it.
            }
        }
        seriesData.push(historicData);
        seriesData.push(uppperBoundSeries);
        seriesData.push(predictionSeries);
        seriesData.push(lowerBoundSeries);
        return seriesData;
    };

    function PredictionSeriesGeneratorDiagnostics(options) {
        this.historySize = options.historySize;
        this.historyResolution = options.historyResolution;
        this.options = options;
        App.Chart.SeriesGenerators.Default.SeriesGenerator.prototype.setOptions.call(this, options);
    }

    PredictionSeriesGeneratorDiagnostics.prototype = new App.Chart.SeriesGenerators.Default.SeriesGenerator();
    PredictionSeriesGeneratorDiagnostics.prototype.generate = function () {
        var dataSeries = [];
        if (typeof this.data !== "undefined") {
            dataSeries = this.generateSeries();
        }
        return dataSeries;
    };

    PredictionSeriesGeneratorDiagnostics.prototype.generateSeries = function () {
        var resultField = this.options.resultField;
        var observedTimeField = this.options.observedTimeField;
        var observedValueField = this.options.observedValueField;

        if (resultField === null || observedTimeField === null || observedValueField === null) {
            return [];
        }

        var historicData = {};
        historicData.data = [];
        historicData.dashStyle = "Solid";
        historicData.name = "Value";
        historicData.color = "#F00";
        var uppperBoundSeries = _getPredictionSeriesObject("Upper Bound");
        uppperBoundSeries.color = "#EC8209";

        var lowerBoundSeries = _getPredictionSeriesObject("Lower Bound");
        lowerBoundSeries.color = "#EC8209";

        var predictionSeries = _getPredictionSeriesObject("Prediction");
        predictionSeries.color = "#a2cf0f";

        var seriesData = [];
        this.data = _chunkDataByHistory(this.data, this.historyResolution, this.historySize, this.options);
        if (this.data.length === 0) {
            return seriesData;
        }
        var last = _.last(this.data);
        var first = _.first(this.data);
        var first_prediction_time = null;
        if (first[resultField] !== "") {
            var predictionPoints = getObjectFromJsonOrObject(first[resultField]);
            var prediction = _.first(predictionPoints);
            first_prediction_time = prediction.timestamp;

            $.each(this.data, function (i, event) {
                if (event[observedTimeField].getTime() > first_prediction_time) {
                    historicData.data.push({
                        x: event[observedTimeField],
                        y: event[observedValueField],
                    });
                }
                var predictionPoints = getObjectFromJsonOrObject(event[resultField]);
                if (predictionPoints.length > 0) {
                    var prediction = _.first(predictionPoints);
                    if (prediction.timestamp < last[observedTimeField].getTime()) {
                        uppperBoundSeries.data.push({
                            x: new Date(prediction.timestamp),
                            y: prediction.upper_boundary,
                        });
                        lowerBoundSeries.data.push({
                            x: new Date(prediction.timestamp),
                            y: prediction.lower_boundary,
                        });
                        predictionSeries.data.push({
                            x: new Date(prediction.timestamp),
                            y: prediction.prediction,
                        });
                    }
                }
            });
        }

        seriesData.push(historicData);
        seriesData.push(uppperBoundSeries);
        seriesData.push(predictionSeries);
        seriesData.push(lowerBoundSeries);
        return seriesData;
    };

    function _chunkDataByHistory(data, historyResolution, historySize, options) {
        if (data.length === 0) {
            return [];
        }

        var last = _.last(data);
        var observedTimeField = options.observedTimeField;

        //@TODO-HACK - undo Hack below
        if (typeof last[observedTimeField] === "number") {
            last[observedTimeField] = new Date(last[observedTimeField]);
        }

        historySize = parseInt(historySize, 10);

        var multiplier = 1000;
        switch (historyResolution) {
            case "Minutes":
                multiplier = multiplier * 60;
                break;
            case "Hours":
                multiplier = multiplier * 60 * 60;
                break;
        }

        data = _.filter(data, function (obj) {
            //@TODO-HACK - undo Hack below
            if (typeof obj[observedTimeField] === "number") {
                obj[observedTimeField] = new Date(obj[observedTimeField]);
            }
            return obj[observedTimeField].getTime() > last[observedTimeField].getTime() - multiplier * historySize;
        });
        return data;
    }

    function _getPredictionSeriesObject(name) {
        var seriesObject = {};
        seriesObject.data = [];
        seriesObject.dashStyle = "ShortDash";
        seriesObject.name = name;
        seriesObject.marker = {
            enabled: true,
            symbol: "circle",
            radius: 2,
        };

        return seriesObject;
    }
});
