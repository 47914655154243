import React, { useCallback, useMemo, useRef, useState } from "react";
import { Grid, SvgIcon } from "@mui/material";
import { StriimDataTable, StriimTypography, StriimChip, StriimDropdown } from "@striim/striim-ui";
import numeral from "numeral";

import { styles } from "./entity-table.styles";
import { SpecialChevronDown } from "../../../../../generic/icon/customIcons";
import { TableData, getConfidenceScoreIcon } from "./utils";
import WithTooltip from "../../../../../generic/tooltip/tooltip";

interface EntityTableProps {
    tableData: TableData[];
    fieldType: string;
    eventType: string;
}

const EntityTable = ({ tableData, fieldType, eventType }: EntityTableProps) => {
    const gridRef = useRef();
    const [rowSensitiveIdentifiersCellWidth, setRowSensitiveIdentifiersCellWidth] = useState(285);

    const tableColumns = useMemo(
        () => [
            {
                headerName: `${fieldType} Name (${tableData.length})`,
                field: "name",
                initialWidth: 370,
                // flex: 0.4,
                suppressMovable: true,
                resizable: true,
                sortable: true,
                cellRenderer: ({ value }) => (
                    WithTooltip(
                        <StriimTypography color="greyscale.900" variant="body4" sx={styles.nameField}>
                            {value}
                        </StriimTypography>,
                        value
                    )
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: `${eventType}s w/ Sensitive Data Identifiers in the Sample`,
                field: "sensitiveDataIdentifiers",
                flex: 1,
                suppressMovable: true,
                resizable: true,
                cellStyle: { display: "flex", alignItems: "center", paddingLeft: "10px", paddingRight: "10px" },
                headerClass: "no-hover",
                cellRenderer: ({ value }) => {
                    //20 is horizontal padding
                    let remainingWidth = rowSensitiveIdentifiersCellWidth ? rowSensitiveIdentifiersCellWidth - 20 : 0;
                    let breakMap = false;
                    const sortedValues = value.sort((x, y) => y.confidenceScore - x.confidenceScore);
                    return (
                        <Grid container gap={0.5}>
                            {sortedValues.map((item, index) => {
                                //12 = horizontal padding of the chip, 12 = gap b/w chips, 8 ~ width for each char, 50 = width of score icon + percentage
                                const currentWidth = item.name.length * 8 + 12 + 22 + 50;
                                const remainingIdentifiers = sortedValues.slice(index);
                                const Icon = getConfidenceScoreIcon(item.confidenceScore);
                                const confidenceScore = numeral(item.confidenceScore).format("0") + "%";
                                if (breakMap) return;
                                if (currentWidth > remainingWidth) {
                                    breakMap = true;
                                    return (
                                        <StriimDropdown
                                            key={item.name}
                                            hover
                                            closeOnMouseLeave
                                            placement="bottom"
                                            content={
                                                <Grid container gap={1} p={2}>
                                                    {remainingIdentifiers.map(item => (
                                                        <StriimChip
                                                            size="large"
                                                            type="tag-chip"
                                                            variant="intresting"
                                                            label={
                                                                <Grid container gap={0.5} alignItems={"center"}>
                                                                    {Icon}
                                                                    {item.name}: {confidenceScore}
                                                                </Grid>
                                                            }
                                                            sx={styles.dropdownChip}
                                                        />
                                                    ))}
                                                </Grid>
                                            }
                                        >
                                            <StriimChip
                                                size="large"
                                                type="tag-chip"
                                                variant="intresting"
                                                label={
                                                    <>
                                                        +{remainingIdentifiers.length}{" "}
                                                        <SvgIcon
                                                            component={SpecialChevronDown}
                                                            sx={styles.chevronIcon}
                                                        />
                                                    </>
                                                }
                                                sx={styles.dropdownChip}
                                            />
                                        </StriimDropdown>
                                    );
                                } else {
                                    //22 = width of the +x tag
                                    remainingWidth = remainingWidth - currentWidth + 22;
                                    return (
                                        <StriimChip
                                            size="large"
                                            type="tag-chip"
                                            variant="intresting"
                                            label={
                                                <Grid container gap={0.5} alignItems={"center"}>
                                                    {Icon}
                                                    {item.name}: {confidenceScore}
                                                </Grid>
                                            }
                                            sx={styles.chip}
                                        />
                                    );
                                }
                            })}
                        </Grid>
                    );
                }
            }
        ],
        [tableData, rowSensitiveIdentifiersCellWidth]
    );

    const onColumnResized = useCallback(params => {
        const column = params?.columns?.find(v => v.colId === "sensitiveDataIdentifiers");
        if (column) {
            setRowSensitiveIdentifiersCellWidth(column.actualWidth);
        }
    }, []);

    return (
        <Grid width="100%" maxHeight={"85%"}>
            <StriimDataTable ref={gridRef} onColumnResized={onColumnResized} data={tableData} columns={tableColumns} />
        </Grid>
    );
};

export default EntityTable;
