import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useBreadcrumbs, StriimTopBar } from "@striim/striim-ui";

import RightToolbar from "./components/right-toolbar/right-toolbar.jsx";
import { getLevels } from "./levels.helper.js";

const styles = {
    appBar: {
        zIndex: 997
    },
    breadcrumb: {
        display: "flex"
    }
};

const TopBar = () => {
    const location = useLocation();
    const { levelsList, setLevelsList } = useBreadcrumbs();

    useEffect(() => {
        setLevelsList(getLevels(location.pathname));
    }, [location]);

    return (
        <StriimTopBar
            levelsList={levelsList}
            rightContent={<RightToolbar />}
            breadcrumbsProps={{ sx: styles.breadcrumb }}
            appBarProps={{ style: styles.appBar }}
        />
    );
};

export default TopBar;
