import React from "react";
import { Box, Grid } from "@material-ui/core";
import useStyles from "./empty_dashboard_list.styles";
import { StriimButton, StriimTypography } from "@striim/striim-ui";
import { useTheme } from "@mui/material/styles";

const EmptyDashboardList = ({ openAddDashboardDialog }) => {
    const v5theme = useTheme();
    const classes = useStyles(v5theme);
    
    return (
        <Grid container className={classes.emptyDashboardWrapper}>
            <Box
                component="img"
                src="app/images/empty_dashboard_list.svg"
                className={classes.emptyDashboardImage}
                data-test-id="image--homepage-dashboards-empty"
            />

            <StriimTypography className={classes.emptyDashboardText} data-test-id="header--homepage-dashboards-empty">
                Use custom real-time dashboards for your key performance metrics with the power and flexibility of SQL
                queries.
            </StriimTypography>

            <StriimButton
                onClick={() => {
                    openAddDashboardDialog(true);
                }}
                data-test-id="create-dashboard-button"
                variant="secondary"
            >
                Create dashboard
            </StriimButton>
        </Grid>
    );
};

export default EmptyDashboardList;
