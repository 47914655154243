import App from "app";
import api from "../../../../../../core/api/api";
import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import dateLib from "core/utils/date-utils";
import ModalManager from "app/components/common/modal/ModalManager";
import moreDetailsTemplate from "../templates/more-details-template.html";
import "highcharts";
import { formatTableValue, getHumanReadableString } from "./tableFormatters";
import ensureMonitorEventTypesCache from "./monitorEventTypesCache";
import targetLagInformationDialog, { getLagDataFromModel } from "./TargetLagInformationDialog";
import securityService from "core/services/securityService/securityService";
import { segmentationTooltip, segmentationIndicator } from "app/components/common/segmentationComponents";
import { segmentationVariants } from "src/modules/common/segmentation/segmentation.consts";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import noDataTemplate from "../templates/noDataTemplate";
import { tooltipStriimlineHighcharts } from "../../../../../styles/materialize/components-striimline/tooltip.export";

let monitorEventTypesCache = {};
App.addInitializer(function() {
    ensureMonitorEventTypesCache().then(result => {
        monitorEventTypesCache = result;
    });
});

var BaseTable = {};
var modalManager = new ModalManager({
    container: "body"
});

var sparlLineChartConfig = {
    chart: {
        backgroundColor: null,
        borderWidth: 0,
        type: "area",
        margin: [2, 0, 2, 0],
        width: 120,
        height: 20,
        style: {
            overflow: "visible"
        },
        skipClone: true
    },
    title: {
        text: ""
    },
    credits: {
        enabled: false
    },
    xAxis: {
        labels: {
            enabled: false
        },
        title: {
            text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: []
    },
    yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
            enabled: false
        },
        title: {
            text: null
        },
        tickPositions: [0]
    },
    legend: {
        enabled: false
    },
    tooltip: {
        borderWidth: 0,
        shadow: false,
        useHTML: true,
        hideDelay: 0,
        shared: true,
        padding: 0,
        formatter: function() {
            return dateLib(this.x).format("hh:mm:ss") + ": <b>" + this.y + "</b>";
        },
        ...tooltipStriimlineHighcharts
    },
    plotOptions: {
        series: {
            animation: false,
            lineWidth: 3,
            shadow: false,
            states: {
                hover: {
                    lineWidth: 3
                }
            },
            marker: {
                radius: 3,
                states: {
                    hover: {
                        radius: 4
                    }
                }
            },
            fillOpacity: 0.25
        },
        column: {
            negativeColor: "#910000",
            borderColor: "silver"
        }
    }
};

var lineGraphConfig = {
    title: false,
    chart: {
        backgroundColor: null
    },
    legend: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    tooltip: {
        formatter: function() {
            let isTimestamp = function(val) {
                val = parseInt(val);
                return val > 1400000000000 && val < 10000000000000;
            };
            let y = isTimestamp(this.y) ? dateLib(this.y).format("hh:mm:ss") : this.y;
            return "At " + dateLib(this.x).format("hh:mm:ss") + ": <b>" + y + "</b>";
        },
        ...tooltipStriimlineHighcharts
    },
    yAxis: {
        labels: {
            enabled: true
        },
        title: {
            text: null
        },
        min: 0,
        gridLineColor: "rgba(100,100,100, 0.3)"
    },
    xAxis: {
        type: "datetime",
        labels: {
            formatter: function() {
                return dateLib(this.value).format("hh:mm:ss");
            }
        }
    },
    plotOptions: {
        series: {
            animation: false,
            lineWidth: 3,
            shadow: false,
            states: {
                hover: {
                    lineWidth: 3
                }
            },
            marker: {
                radius: 3,
                states: {
                    hover: {
                        radius: 4
                    }
                }
            },
            fillOpacity: 0.25
        }
    }
};

BaseTable.CellItemSparklineView = Backbone.Marionette.ItemView.extend({
    template: _.template('<div class="graph" style="position: relative"></div>'),
    tagName: "td",
    modelEvents: {
        change: "render"
    },
    onRender: function() {
        var value = this.model.get("value");
        if (value) {
            var data = value.map(function(data) {
                return [data.first, data.second];
            });
            sparlLineChartConfig.series = [
                {
                    data: data
                }
            ];
            this.$(".graph").highcharts(sparlLineChartConfig);
        } else {
            this.$(".graph").html("Fetching…");
        }
    }
});

var ValueCellView = Backbone.Marionette.ItemView.extend({
    tagName: "td",
    template: _.template("{{value === '' ? 'Fetching…' : value}}"),
    modelEvents: {
        change: "render"
    }
});

const targetDetailsTemplate = `<div>
  <div class="lee-button-container"><button class="waves-effect waves-light btn secondary lag-button" data-test-id="end-to-end-lag-button">End to End lag</button></div>
  <button class="waves-effect waves-light btn secondary more-details">More Details</button>
</div>`;

BaseTable.MoreDetailsTargetView = Backbone.Marionette.ItemView.extend({
    tagName: "td",
    template: _.template(targetDetailsTemplate),
    ui: {
        lagButton: "button.lag-button"
    },
    triggers: {
        "click button.more-details": "click:moreInfo"
    },

    _enabled: true,

    _handleLagClick: function() {
        if (!this._enabled) {
            return;
        }
        this.trigger("click:lag-info");
    },

    onBeforeDestroy: function() {
        this.model.get("dataModel").off("change", this._setButtonState);
    },

    onRender: function() {
        this._hasLicense = securityService.isSegmentationFeatureEnabled(addonFeaturesKeys.LEE_REPORT);
        if (!this._hasLicense) {
            const variant = securityService.getSegmentationVariant(addonFeaturesKeys.LEE_REPORT);
            if (variant === segmentationVariants.none) {
                this.ui.lagButton
                    .parent()
                    .tooltipster(
                        segmentationTooltip(variant, addonFeaturesKeys.LEE_REPORT, this.ui.lagButton.parent())
                    );
                const licenseIndicator = segmentationIndicator(
                    variant,
                    "seg-indicator-component-view seg-indicator-lee"
                );
                this.ui.lagButton.parent().append(licenseIndicator);
            }
        } else {
            this.ui.lagButton.on("click", this._handleLagClick.bind(this));
        }

        this._setButtonState();
        this.model.get("dataModel").on("change", this._setButtonState, this);
    },

    _setButtonState: function() {
        const hasLeeData = !!getLagDataFromModel(this.model.get("dataModel"));

        this._enabled = hasLeeData && this._hasLicense;
        this.ui.lagButton.toggleClass("disabled", !this._enabled);

        if (this._hasLicense && !hasLeeData) {
            this.ui.lagButton.attr("title", hasLeeData ? "" : "End to End lag is not supported for this target");
        }
    }
});

BaseTable.MoreDetailsButtonView = Backbone.Marionette.ItemView.extend({
    tagName: "td",
    template: _.template('<button class="waves-effect btn secondary">More Details</button>'),
    triggers: {
        "click button": "click:moreInfo"
    }
});


BaseTable.CellPseudoLink = Backbone.Marionette.ItemView.extend({
    template: _.template('<div class="pseudo-link">{{value}}</div>'),
    tagName: "td"
});

BaseTable.GraphsView = Backbone.Marionette.ItemView.extend({
    seriesToRenderInThisAdapter: null,

    template: _.template(""),

    onRender: function() {
        this.$el.addClass("row");
    },

    _drawGraphSkeleton: function(columnClasses, series) {
        let $div = $(
            `<div class="col ${columnClasses} p-8px">` +
                `<div class="card p-8px">` +
                `<h4>${getHumanReadableString(series)}</h4>` +
                `<div class="graph-container" id="graph-${series}"></div>` +
                `</div></div>`
        );
        this.$el.append($div);
    },

    /**
     * Don't panic - this is just a simple algorithm that makes sure we plot graphs neatly without rough edges.
     * @param seriesToRenderInThisAdapter
     */
    drawGraphSkeletons: function(seriesToRenderInThisAdapter) {
        var that = this;
        while (seriesToRenderInThisAdapter.length > 4) {
            var series = seriesToRenderInThisAdapter.splice(0, 3);
            _.each(series, function(entry) {
                that._drawGraphSkeleton("s12 l6 xl4", entry);
            });
        }

        if (seriesToRenderInThisAdapter.length === 1) {
            that._drawGraphSkeleton("s12", seriesToRenderInThisAdapter[0]);
        } else if (seriesToRenderInThisAdapter.length === 2) {
            that._drawGraphSkeleton("s12 xl6", seriesToRenderInThisAdapter[1]);
            that._drawGraphSkeleton("s12 xl6", seriesToRenderInThisAdapter[0]);
        } else if (seriesToRenderInThisAdapter.length === 3) {
            that._drawGraphSkeleton("s12 l6 xxl4", seriesToRenderInThisAdapter[2]);
            that._drawGraphSkeleton("s12 l6 xxl4", seriesToRenderInThisAdapter[1]);
            that._drawGraphSkeleton("s12 l12 xxl4", seriesToRenderInThisAdapter[0]);
        } else if (seriesToRenderInThisAdapter.length === 4) {
            that._drawGraphSkeleton("s12 l6 xxl3", seriesToRenderInThisAdapter[3]);
            that._drawGraphSkeleton("s12 l6 xxl3", seriesToRenderInThisAdapter[2]);
            that._drawGraphSkeleton("s12 l6 xxl3", seriesToRenderInThisAdapter[1]);
            that._drawGraphSkeleton("s12 l6 xxl3", seriesToRenderInThisAdapter[0]);
        } else {
            var placeholder = new noDataTemplate({
                text: "No Graphs available for this component."
            });
            this.$el.append(placeholder.el);
        }
    },

    drawgraphs: function() {
        this._charts = this._charts || {};
        var timeSeriesData = this.model.get("allValues")["time-series-data"];
        var that = this;
        if (this.seriesToRenderInThisAdapter === null) {
            var seriesInAdapter = this.getDrawableSeries(timeSeriesData);
            seriesInAdapter = _.without(seriesInAdapter, "latest-activity", "cpu-per-node", "lag-end2end");
            this.seriesToRenderInThisAdapter = _.clone(seriesInAdapter);
            this.trigger("seriesToRenderInThisAdapterChanged", this.seriesToRenderInThisAdapter);
            this.drawGraphSkeletons(seriesInAdapter);
        }
        _.each(this.seriesToRenderInThisAdapter, function(seriesToRender) {
            var series = timeSeriesData[seriesToRender];
            var seriesForHighCharts = {
                name: seriesToRender,
                data: []
            };
            for (var i in series) {
                var entry = series[i].second;
                var date = series[i].first;

                if (_.isNumber(entry)) {
                    seriesForHighCharts.data.push([date, entry]);
                } else if (_.isString(entry)) {
                    entry = entry.replace("%", "");
                    entry = parseFloat(entry);
                    if (!_.isNaN(entry)) {
                        seriesForHighCharts.data.push([date, entry]);
                    }
                }
            }
            var config = _.extend({}, lineGraphConfig, {
                series: [seriesForHighCharts]
            });
            if (!that._charts["#graph-" + seriesToRender]) {
                that._charts["#graph-" + seriesToRender] = that.$("#graph-" + seriesToRender).highcharts(config);
            } else {
                const chart = that._charts["#graph-" + seriesToRender].highcharts();
                if (chart.series.length > 0) {
                    chart.series[0].remove(false);
                    chart.addSeries(seriesForHighCharts, false);
                    chart.series[0].color = "#7CB5EC";
                    chart.redraw();
                }
            }
        });
    },

    getDrawableSeries: function(timeSeriesData) {
        var drawableSeries = [];
        var that = this;
        _.each(timeSeriesData, function(val, i) {
            if (that.hasTimeSeriesXAxis(val) && that.hasNumericYAxis(val)) {
                drawableSeries.push(i);
            }
        });
        return drawableSeries;
    },

    hasTimeSeriesXAxis: function(val) {
        return !!(
            _.isArray(val) &&
            val.length > 0 &&
            val[0].hasOwnProperty("first") &&
            _.isNumber(val[0].first) &&
            val[0].first > 1400000000000 &&
            val[0].first < 10000000000000
        ); //If Javascript still exists on Saturday, November 20, 2286 5:46:40 PM, i'd be sad i'm dead. I'd still probably have a job.
    },

    hasNumericYAxis: function(val) {
        var entry = val[0].second;
        if (_.isNumber(entry)) {
            return true;
        } else if (_.isString(entry)) {
            entry = entry.replace("%", "");
            entry = parseFloat(entry);
            if (!_.isNaN(entry)) {
                return true;
            }
        }
        return false;
    }
});

var MoreDetailsDialog = App.FormMixinDialog.View.extend({
    template: _.template(moreDetailsTemplate),
    ui: {
        dialogBody: ".more-details-dialog"
    },
    regions: {
        content: ".modal-body .inner",
        graphsContainer: ".graphs"
    },

    initialize: function(options) {
        options.bind_submit = true;
        options.submit_on_enter = true;
        options.bind_cancel = true;
        options.classes = "large more-details-container-dialog";

        this.model = options.model;
        this.componentFullName = options?.componentFullName;
        var that = this;
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
        this.model.on("change", async function() {
            that.refreshGraphs();
            await that.drawRecentMetrics();
        });
    },

    refreshGraphs: function() {
        if (this.graphsView) {
            this.graphsView.drawgraphs();
        }
    },

    drawRecentMetrics: async function() {
        var mostRecentData = this.model.get("allValues")["most-recent-data"];
        try {
            const result = await api.getTableInfo(this.componentFullName);
            mostRecentData = { ...mostRecentData, ...result["most-recent-data"] };
        } catch (err) {
            console.error("Error fetching table info for " + this?.componentFullName, err);
        }
        this.$(".recent-metrics").empty();

        for (var key in mostRecentData) {
            if (typeof mostRecentData[key] === "object" && !window.showMonitorComplexValues) {
                continue;
            }
            if (key.toLowerCase().indexOf("lag-report") === -1) {
                let title = getHumanReadableString(key);
                let value = formatTableValue(mostRecentData[key], key);
                if (monitorEventTypesCache[key] && monitorEventTypesCache[key].title) {
                    title = monitorEventTypesCache[key].title;
                }
                this.$(".recent-metrics").append(`<tr><td>${title}</td><td>${value}</td></tr>`);
            }
        }

        this.$(".loading").remove();
    },

    onRender: function() {
        var that = this;

        this.graphsView = new BaseTable.GraphsView({
            model: this.model
        });
        this.graphsContainer.show(this.graphsView);

        const windowHeight = $(window).height() - 200;
        this.ui.dialogBody.css("max-height", windowHeight + "px");

        setTimeout(function() {
            that.refreshGraphs();
            that.drawRecentMetrics();
            that.fitPositionToModalContent();
            $(window).trigger("resize");
        }, 300);
    }
});

var TableRowView = Backbone.Marionette.CollectionView.extend({
    tagName: "tr",
    className: "jqgrow",
    childEvents: {
        "click:moreInfo": "showMoreInfo",
        "click:lag-info": "showLagInfo"
    },
    getComponentFullyQualifiedName: function() {
        const componentName = this.model.get("allValues")?.["full-name"];
        const componentType = this.model.get("allValues")?.["type"];
        if (typeof componentName === "string" && typeof componentType === "string") {
            return componentName.split(".")?.[0] + "." + componentType + "." + componentName.split(".")?.[1];
        }
        return "";
    },
    showMoreInfo: function() {
        var that = this;
        this._dialog = new MoreDetailsDialog({
            model: this.model,
            componentFullName: this.componentFullName
        });
        modalManager.add(this._dialog);
        this._dialog.on("form:submit", function() {
            that._dialog.destroy();
            that._dialog = null;
        });
    },
    showLagInfo: function() {
        let sourcesList = [];
        const sources = this.appData && this.appData["sources"];
        if (sources) {
            const data = sources.get("data");
            if (data) {
                sourcesList = data.map(item => item["full-name"]);
            }
        }
        targetLagInformationDialog(modalManager, this.model, sourcesList);
    },

    getChildView: function(item) {
        return item.get("View");
    },
    setModelData: function() {
        var that = this;
        _.each(this.columns, function(col) {
            var value = that.model.get(col.dataName || col.srcfield);
            var model = new Backbone.Model({
                value: typeof value === "undefined" ? "" : value,
                View: col.view || ValueCellView,
                id: col.srcfield,
                dataModel: that.model
            });
            that.collection.add(model, {
                merge: true
            });
        });

        if (that._dialog) {
            that._dialog.fitPositionToModalContent();
        }
    },
    initialize: function(options) {
        this.collection = new Backbone.Collection();
        this.model = options.model;
        this.componentFullName = this.getComponentFullyQualifiedName();
        this.appData = options.appData;
        this.columns = options.columns;
        this.setModelData();
        var that = this;
        this.model.on("change", function() {
            that.setModelData();
        });
    }
});

BaseTable.View = Backbone.Marionette.CompositeView.extend({
    columns: [],

    template: _.template(
        '<div class="table"><table class="ui-jqgrid-htable"><thead><tr></tr></thead><tbody class="ui-widget-content ui-jqgrid"></tbody></table></div>'
    ),

    collection: new Backbone.Collection(),

    childViewContainer: "tbody",

    childView: TableRowView,

    childViewOptions: function() {
        return {
            columns: this.columns,
            appData: this.appData
        };
    },

    getEmptyView: function() {
        var emptyTemplate = "<td colspan='" + this.columns.length + "'>No entries</td>";
        return Backbone.Marionette.ItemView.extend({
            template: _.template(emptyTemplate),
            tagName: "tr",
            className: "tbody-empty-view jqgrow"
        });
    },

    ui: {
        tableHeader: "thead tr"
    },

    modelEvents: {
        change: "_renderTable"
    },

    dataMapper: function() {
        throw new Error("dataMapper not implemented");
    },

    initialize: function(options) {
        this.filterColumns(options.appStatus);
        this.appData = options.appData;

        var Collection = Backbone.Collection.extend({
            modelId: function(attrs) {
                return attrs.label;
            },
            comparator: "label"
        });
        this.collection = new Collection();
    },

    onRender: function() {
        this._renderTableHeader();
    },

    _renderTable: function() {
        this._renderTableHeader();
        var that = this;
        var tableData = this.model.data.map(this.dataMapper);
        _.each(tableData, function(data) {
            var model = new Backbone.Model(data);
            that.collection.add(model, {
                merge: true
            });
        });
    },

    _renderTableHeader: function() {
        var cols = _.map(this.columns, function(column) {
            return `<th>${column.label}</th>`;
        });
        const columnsHtml = cols.join("");
        if (columnsHtml == this._lastRenderedColumnsHtml) {
            return;
        }
        this._lastRenderedColumnsHtml = columnsHtml;
        this.ui.tableHeader.html(columnsHtml);
    },

    /**
     * Filter columns based on Application status
     * @param {string} appStatus -
     */
    filterColumns: function(appStatus) {}
});

export default BaseTable;

export const DetailsDialog = MoreDetailsDialog;
