import _ from "underscore";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import MetaObjectDataSource from "app/components/common/editor/control/select/metaobject-datasource";
import BaseControl from "./../base-control";
import TypeAndField from "./../type-and-field/type-and-field";
import List from "./../list/list";

var View = BaseControl.View.extend({
    template: _.template("<div></div>"),

    initialize: function () {
        var _this = this;
        this.listModel = new List.Model({
            editor: TypeAndField,
            addText: "Add Event Type",
        });
        this.listenTo(this.listModel, "change", function () {
            _this.setValueFromView();
        });
        _this.model.on("change", function (model) {
            _this.listModel.value = model.value;
        });

        this.listView = new List.View({
            model: this.listModel,
        });
    },

    refreshItems: function () {
        _.each(this.listView.childViews, function (childView) {
            var types = new MetaObjectDataSource([metaStoreService.entities.TYPE]);
            childView.typeSelector.typeSelector.setDataSource(types);
        });
    },

    setViewEnabled: function () {
        if (!this.$el.html()) {
            return;
        }

        this.listView.setEnabled(this.getEnabled());
    },

    onRender: function () {
        this.$el.append(this.listView.render().$el);
    },

    getViewValue: function () {
        return this.listView.model.value;
    },

    setViewValue: function () {
        this.listView.setValue(this.getValue());
    },
});

export default function (addText) {
    var Model = BaseControl.Model.extend({
        defaults: {
            value: Array,
            addText: addText,
        },
    });

    return _.extend({}, BaseControl, {
        Model: Model,
        View: View,
    });
}
