/**
 * Used to check if selected node type can be used
 * as child of specific node type
 */

import utils from "core/utils";
import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";

export default {
    getAllowedParents: function(childNodeType, childNodeComponentName) {
        switch (childNodeType) {
            case utils.ENTITIES.SOURCE:
            case utils.ENTITIES.EXTERNALSOURCE:
            case utils.ENTITIES.STREAM:
            case utils.ENTITIES.EXTERNALCACHE:
                return [];
            case utils.ENTITIES.CACHE:
                if (childNodeComponentName === PredefinedComponentNames.BaseEventTable.name) {
                    return [utils.ENTITIES.STREAM];
                }
                return [];
            case utils.ENTITIES.CQ:
                return [
                    utils.ENTITIES.STREAM,
                    utils.ENTITIES.WINDOW,
                    utils.ENTITIES.CACHE,
                    utils.ENTITIES.WACTIONSTORE
                ];
            case utils.ENTITIES.WINDOW:
            case utils.ENTITIES.ROUTER:
            case utils.ENTITIES.TARGET:
            case utils.ENTITIES.SENTINEL:
                return [utils.ENTITIES.STREAM];
            case utils.ENTITIES.WACTIONSTORE:
                return [utils.ENTITIES.CQ];
            case utils.ENTITIES.OPENPROCESSOR:
                return [utils.ENTITIES.STREAM, utils.ENTITIES.CACHE];
            default:
                throw new Error("Unknown childNodeType type [" + childNodeType + "]");
        }
    },

    getAllowedChildren: function(parentNodeType) {
        switch (parentNodeType) {
            case utils.ENTITIES.SOURCE:
            case utils.ENTITIES.EXTERNALSOURCE:
            case utils.ENTITIES.CQ:
            case utils.ENTITIES.ROUTER:
            case utils.ENTITIES.OPENPROCESSOR:
            case utils.ENTITIES.SENTINEL:
                return [utils.ENTITIES.STREAM];
            case utils.ENTITIES.CACHE:
            case utils.ENTITIES.EXTERNALCACHE:
            case utils.ENTITIES.WINDOW:
            case utils.ENTITIES.WACTIONSTORE:
                return [utils.ENTITIES.CQ];
            case utils.ENTITIES.TARGET:
                return [];
            case utils.ENTITIES.STREAM:
                return [utils.ENTITIES.WINDOW, utils.ENTITIES.CQ, utils.ENTITIES.TARGET, utils.ENTITIES.ROUTER, utils.ENTITIES.SENTINEL];
            default:
                throw new Error("Unknown parentNodeType type [" + parentNodeType + "]");
        }
    }
};
