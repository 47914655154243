import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => {
    return {
        header: {
            margin: "0px 0px 8px 0px !important"
        },
        operationWrapper: {
            display: "flex",
            margin: "auto",
            paddingTop: theme.spacing(2),
            flexDirection: "column"
        },
        operationContainer: {
            display: "flex",
            width: "100%"
        },
        flexOne: {
            flex: 1
        },
        successIcon: {
            color: theme.palette.success.main
        },
        failIcon: {
            color: theme.palette.danger.main
        },
        errorMsg: {
            paddingLeft: theme.spacing(3)
        }
    };
});
export const sxStyles = {
    header: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    title: {
        color: ({ palette }) => palette.primary[700]
    },
    description: {
        color: ({ palette }) => palette.greyscale[700]
    },
    container: {
        padding: ({ spacing }) => spacing(1, 3, 3, 3)
    },
    circularProgress: {
        color: ({ palette }) => palette.greyscale[300],
        marginRight: ({ spacing }) => spacing(2)
    },
    iconBaseStyle: {
        position: "absolute",
        width: 100
    },
    icon: {
        color: "transparent",
        height: ({ spacing }) => spacing(2),
        width: ({ spacing }) => spacing(2)
    }
};
