import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui";
import { ChevronDown, ChevronUp } from "../icon/customIcons";

interface PropTypes {
    isAscending: boolean;
    setIsAscending: Function;
}

const styles = {
    container: {
        border: theme => `1px solid ${theme.palette.greyscale[200]}`,
        padding: ({ spacing }) => spacing(0.25, 1),
        width: "fit-content",
        borderRadius: 1,
        cursor: "pointer",
        fontFamily: "Inter"
    },
    icon: {
        "& svg": {
            fill: "transparent",
            "& path": {
                stroke: theme => theme.palette.greyscale[700]
            }
        }
    }
};

const AlphabeticalSortButton: React.FC<PropTypes> = ({ isAscending, setIsAscending }) => {
    return (
        <Box sx={styles.container} onClick={() => setIsAscending(prev => !prev)} data-test-id="sort-button">
            <Grid container alignItems="center">
                <StriimTypography
                    variant="caption2"
                    color="greyscale.700"
                    data-test-id={`${isAscending ? "asc" : "desc"}`}
                >
                    {isAscending ? "Sort by: A -> Z" : "Sort by: Z -> A"}
                </StriimTypography>
                <Box mb={-0.5} ml={0.5} sx={styles.icon}>
                    {isAscending ? <ChevronDown /> : <ChevronUp />}
                </Box>
            </Grid>
        </Box>
    );
};

export default AlphabeticalSortButton;
