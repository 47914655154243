export const getSortText = (sortToggled, numApps, label) => {
    if (label === "Recent") {
        return "Sorted by: App name (A-Z)";
    } else if (label === "MostRecent") {
        return "Sorted by: Most Recent";
    } else if (numApps === 0) {
        return "";
    } else {
        return !sortToggled ? "Sort by: App name (A-Z)" : "Sort by: App name (Z-A)";
    }
};
