import { Box, Grid } from "@mui/material";
import { StriimModal, StriimToggleButton, StriimTypography, StriimButton } from "@striim/striim-ui-v2";
import React, { useContext, useEffect, useState } from "react";
import { Checkmark, Close } from "../icon/customIcons";
import LoadingIndicator from "../loading-indicator";
import AppDeployTable from "../../modules/apps/pages/app-list/components/automated-app-group/group-deploy-modal/app-deploy-table/app-deploy-table";
import App from "app";
import api from "../../../core/api/api";
import { DeployModalContext } from "./deploy-modal-wrapper";
import { convertConfigToHashMap, deployApp } from "./utils/app-deploy-utils";
import validate from "../../../app/components/flow/designer/data-type-validation/data-type-validation-loader";

interface AppDeployModalProps {
    appId: string;
    isVisible: boolean;
    setIsVisible?: (isVisible: boolean) => void;
}

const AppDeployModal: React.FunctionComponent<AppDeployModalProps> = ({ appId, isVisible, setIsVisible }) => {
    const {
        deploymentInfo,
        setDeploymentInfo,
        isValidationEnabled,
        setisvalidationEnabled,
        validateTableMapping,
        setvalidateTableMapping,
        setStep,
        setDataTypeValidationErrors
    } = useContext(DeployModalContext);
    const [isLoading, setisLoading] = useState<boolean>(false);
    const [isModalVisible, setisModalVisible] = useState<boolean>(isVisible);

    useEffect(() => {
        (async () => {
            try {
                const res = await api.isValidationEnabled(appId);
                setisvalidationEnabled(res);
                setvalidateTableMapping(res);
            } catch (error) {
                console.error("validation method failed", error);
            }
        })();
    }, []);
    useEffect(() => {
        setisModalVisible(isVisible);
    }, [isVisible]);
    async function onConfirm() {
        try {
            if (isValidationEnabled && validateTableMapping) {
                // Show validation errors if validation is supported and user selects validate table mapping
                App.vent.trigger("start:data-type-validation");
                const dgInfo = convertConfigToHashMap(deploymentInfo);
                const errors = await validate(appId, dgInfo);
                if (errors === null || errors?.length === 0) {
                    setDataTypeValidationErrors(null);
                    setisLoading(true);
                    await deployApp(deploymentInfo, appId);
                    App.vent.trigger("progress:end", appId);
                    setIsVisible(false);
                } else {
                    setDataTypeValidationErrors(errors);
                    setStep(1);
                }
            } else {
                setisLoading(true);
                await deployApp(deploymentInfo, appId);
                App.vent.trigger("progress:end", appId);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setisLoading(false);
            setisModalVisible(false);
            setIsVisible(false);
        }
    }
    function handleCancel() {
        App.vent.trigger("cancel:deployment");
        setisModalVisible(false);
        setIsVisible(false);
    }
    return (
        <StriimModal
            dialogProps={{
                sx: { ".MuiDialog-paper": { width: "auto", minWidth: "700px" } }
            }}
            autoHeight
            isVisible={isModalVisible}
            footerBorder
            footerContent={
                <Grid container justifyContent="space-between">
                    <StriimButton
                        onClick={handleCancel}
                        disabled={isLoading}
                        data-test-id="app-deploy-modal-cancel-button"
                        variant="text"
                    >
                        Cancel
                    </StriimButton>
                    <StriimButton
                        onClick={onConfirm}
                        disabled={isLoading}
                        data-test-id="app-deploy-modal-confirm-button"
                        variant="primary"
                        startIcon={
                            <Box sx={{ "& svg": { fill: "none", "& path": { stroke: "#FFF" } } }}>
                                <Checkmark color="white" />
                            </Box>
                        }
                    >
                        Deploy
                    </StriimButton>
                </Grid>
            }
            titleContent={
                <Box>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <StriimTypography variant="h2" color="primary.700">
                            Deploy App
                        </StriimTypography>
                        <div onClick={handleCancel} style={{ cursor: "pointer" }}>
                            <Close />
                        </div>
                    </Grid>
                </Box>
            }
        >
            <Box
                sx={{
                    borderTop: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
                    width: "calc(100% + 48px)",
                    mx: -3,
                    mb: 2
                }}
            >
                {isLoading && <LoadingIndicator isGlobal={false} />}
            </Box>
            <AppDeployTable
                showAppNameInTitle={false}
                appId={appId}
                formValues={deploymentInfo}
                setFormValues={setDeploymentInfo}
            />
            {isValidationEnabled && (
                <Grid container alignItems="center" mt={2} justifyContent="flex-end">
                    <StriimTypography variant="body4" color="greyscale.900">
                        Validate Table Mapping
                        <StriimToggleButton
                            sx={{ ml: 1 }}
                            checked={validateTableMapping}
                            onChange={e => {
                                setvalidateTableMapping(e.target.checked);
                            }}
                        />
                    </StriimTypography>
                </Grid>
            )}
        </StriimModal>
    );
};

AppDeployModal.defaultProps = {
    isVisible: true
};
export default AppDeployModal;
