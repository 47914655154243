import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { StriimTypography, StriimButton, StriimLink } from "@striim/striim-ui";
import { AddPropertyVariableModal } from "./";
import Dictionary from "app/components/common/helpable/online-help-dictionary";
import { AddCircleOutlineRounded } from "@mui/icons-material";

const styles = {
    outerBoxStyles: {
        gap: "20px",
        pb: 4.5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    imgStyles: { width: "200px" },
    infoTextStyles: { maxWidth: "400px", textAlign: "center" }
};
const PropertyVariablesEmpty = ({ onAdded }) => {
    const [isModalShown, setIsModalShown] = useState(false);
    return (
        <>
            <Box sx={styles.outerBoxStyles} data-test-id="property-variables-empty">
                <img src="src/generic/icon/manage-striim/01-ManageStriim-Files-Empty.svg" style={styles.imgStyles} />
                <StriimTypography variant="h1">Add your first property variable</StriimTypography>
                <StriimTypography variant="body4" color="greyscale.700" sx={styles.infoTextStyles}>
                    Property variables allow you to store values for adapter properties in an encrypted form, so they
                    may be used as passwords and tokens applications without sharing the clear text with users.
                </StriimTypography>
                <Box>
                    <StriimLink href={Dictionary.get()["MANAGE_STRIIM_PROPERTY_VARIABLES"].href} target="_blank">
                        View Documentation
                    </StriimLink>
                </Box>
                <Box data-test-id="add-property-variable-button">
                    <StriimButton
                        variant="primary"
                        onClick={() => setIsModalShown(true)}
                        startIcon={<AddCircleOutlineRounded />}
                    >
                        New property variable
                    </StriimButton>
                </Box>
            </Box>
            {isModalShown && <AddPropertyVariableModal setIsModalShown={setIsModalShown} onAdded={onAdded} />}
        </>
    );
};

export default PropertyVariablesEmpty;
