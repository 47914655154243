import NestedTypes from "backbone.nestedtypes";
import dataVisualizations from "./dataVisualizations";

const dataVisualizationsEditors = {};
dataVisualizationsEditors.PropertyEditors = NestedTypes.Collection.extend({
    model: function (attrs, options) {
        // The below model is not yet defined at this point, so we get it dynamically
        return new dataVisualizationsEditors.PropertyEditor(attrs, options);
    },
});

dataVisualizationsEditors.CollectionItemEditor = NestedTypes.Model.extend({
    defaults: {
        type: dataVisualizations.EDITOR.PROPERTIES, // assumed, as in top-level object
        description: null,
        events: null,
        options: Object,
        propertyeditors: dataVisualizationsEditors.PropertyEditors,
    },
});

dataVisualizationsEditors.PropertyEditor = NestedTypes.Model.extend({
    defaults: {
        attribute: null,
        get_value: null,
        on_change: null,
        events: null,
        type: null,
        description: null,
        style: null,
        // Parameters to pass to the above editor. label, description, etc.
        // e.g. for ENUM, this should contain a property holding an array of values
        options: Object,
        // If the associated attribute has its own attributes (i.e. it's an object, and editor type is PROPERTIES),
        // specify this property with its own editor array
        propertyeditors: dataVisualizationsEditors.PropertyEditors,
        // If collection and carousel
        _current_item_id: null,
        // If editor type is COLLECTION, specify this property to define how each item in the collection should
        // be edited. i.e. what type of editor should be used for it
        itemeditor: dataVisualizationsEditors.CollectionItemEditor,
        validate: null,
    },
});

// The model needed to render a single editor (color, string, etc.)
// TODO: rename to make distinction between Editor and EditorModel clearer. This is the model for the editor view, while Editor is the editor def used by users. EditorModel contains an Editor.
dataVisualizationsEditors.EditorViewModel = NestedTypes.Model.extend({
    defaults: {
        _queryvisualization: null, // The top most QV whose DV is being edited (if any)
        _datavisualization: null, // The top most DV being edited
        _theme: null, // theme (needed for color picker)
        // In the parent editors array (accessible in parent), what is index of this editor
        _parent_editor_id: null,
        // If collection: In the parent config array (accessible in parent), what is index of this config
        _parent_config_id: null,
        _parent: null,
        editor: dataVisualizationsEditors.PropertyEditor,
        config: NestedTypes.Model, // what is being configured (the visualization, etc.)
    },
});

export default dataVisualizationsEditors;
