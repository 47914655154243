import React, { useState, useEffect, useMemo } from "react";
import { StriimTab, StriimTabs } from "@striim/striim-ui";
import { Grid } from "@material-ui/core";
import securityService from "core/services/securityService/securityService";
import UserPlanTab from "./tabs/user-plan";
import StriimUnitsUsageTab from "./tabs/striim-units-usage";
import LoadingIndicator from "src/generic/loading-indicator";
import useStyles from "./user-plan-page.styles";

import { useUserPlanState } from "./tabs/user-plan/use-user-plan-state";
import loadInitialData from "./user-plan-load-initial-data";
import { convertNameToHumanRedable } from "app/components/common/editor/control-resource-resolver";
import api from "./metering-api";
import UserPlanRoot from "../../user-plan-root";
import { StriimTypography } from "./tabs/user-plan/components/StriimTypographyCustom";
import UserPlanPageDetail from "./tabs/striim-units-usage/components/component-details/user-plan-page-detail";
import { useSearchParams } from "react-router-dom";

const UserPlanPage = () => {
    const [searchParams, setSearchParams] = useSearchParams({});
    const preselectedTab = searchParams.get("tab") || 0;
    const userPlanState = useUserPlanState();

    const classes = useStyles();

    const setTheActivetab = tab => {
        setActiveTab(tab);
        if (searchParams.get("tab") !== tab) {
            setSearchParams({ tab });
        }
    };
    const [activeTab, setActiveTab] = useState(preselectedTab || (securityService.segmentingEnabled() ? 0 : 1));
    const [usageTabName, setTabName] = useState("Striim Units usage");
    const [initialData, setInitialData] = useState(null);
    const [apiError, setApiError] = useState("");

    useEffect(() => {
        loadInitialData(api)
            .then(data => {
                setApiError(false);
                setInitialData(data);
                data.thisMonthSummary && setTabName(convertNameToHumanRedable(data.thisMonthSummary.unit) + " usage");
            })
            .catch(error => {
                setApiError(error);
            });
    }, []);

    const localInitialData = initialData || {};

    const ActiveTabComponent = useMemo(() => {
        if (Number(activeTab) === 0) {
            return (
                <UserPlanTab
                    onDetailsClick={() => setTheActivetab(1)}
                    thisMonthSummary={localInitialData.thisMonthSummary}
                    userPlanState={userPlanState}
                />
            );
        }
        return (
            <StriimUnitsUsageTab
                thisMonthSummary={localInitialData.thisMonthSummary}
                lastMonthSummary={localInitialData.lastMonthSummary}
                awardedCredits={localInitialData.awardedCredits}
                allUsageSummary={localInitialData.allUsageSummary}
                goToUsagePlanTab={() => {
                    setTheActivetab(0);
                }}
                api={api}
                basicMinEventUsage={localInitialData.basicMinEventUsage}
            />
        );
    }, [activeTab, initialData, userPlanState]);

    if (!initialData) {
        return (
            <div className={classes.wrapper}>
                {apiError ? (
                    <StriimTypography variant="h1" style={{ textAlign: "center" }} className={classes.header}>
                        {apiError?.message}
                    </StriimTypography>
                ) : (
                    <LoadingIndicator />
                )}
            </div>
        );
    }

    return (
        <Grid container className={classes.gridContainer} direction="column">
            <Grid item>
                <StriimTypography variant="h1">
                    {securityService.segmentingEnabled() ? "Account and Billing" : usageTabName}
                </StriimTypography>
            </Grid>
            <Grid xs={12} item>
                {securityService.segmentingEnabled() && (
                    <div style={{ position: "relative" }}>
                        <StriimTabs
                            value={Number(activeTab)}
                            onChange={(e, newValue) => {
                                setTheActivetab(Number(newValue));
                            }}
                            content={ActiveTabComponent}
                        >
                            <StriimTab
                                label={
                                    <StriimTypography variant="caption2" data-test-id="account-and-billing__plan">
                                        Plan
                                    </StriimTypography>
                                }
                            />
                            <StriimTab
                                label={
                                    <StriimTypography variant="caption2" data-test-id="account-and-billing__usage">
                                        {usageTabName}
                                    </StriimTypography>
                                }
                            />
                        </StriimTabs>
                        <StriimTypography
                            className={classes.learnMoreLink}
                            variant="textButton"
                            component="div"
                            align="right"
                        >
                            <a href="http://striim.com/pricing" target="_blank" rel="noreferrer">
                                Learn how pricing works
                            </a>
                        </StriimTypography>
                    </div>
                )}

                {!securityService.segmentingEnabled() && (
                    <div className={classes.card}>
                        <StriimUnitsUsageTab
                            thisMonthSummary={localInitialData.thisMonthSummary}
                            lastMonthSummary={localInitialData.lastMonthSummary}
                            awardedCredits={localInitialData.awardedCredits}
                            allUsageSummary={localInitialData.allUsageSummary}
                            goToUsagePlanTab={() => {
                                setTheActivetab(0);
                            }}
                            api={api}
                            basicMinEventUsage={localInitialData.basicMinEventUsage}
                        />
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

export default function() {
    const [searchParams, setSearchParams] = useSearchParams({});
    const itemId = searchParams.get("itemId");
    const billingId = searchParams.get("billingId");

    return (
        <UserPlanRoot>
            {itemId && billingId ? (
                <UserPlanPageDetail api={api} itemId={itemId} billingId={billingId} />
            ) : (
                <UserPlanPage />
            )}
        </UserPlanRoot>
    );
}
