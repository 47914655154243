import securityService from "./core/services/securityService/securityService";
import { action, observable } from "mobx";

export function checkIfUserLoggedIn(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
        try {
            // noinspection TypeScriptValidateTypes
            let userLoggedIn: boolean = await securityService.isLoggedIn();
            resolve(userLoggedIn);
        } catch (e) {
            reject(e);
        }
    });
};

export async function initializeUser(): Promise<boolean> {
    try {
        // noinspection TypeScriptValidateTypes
        let authenticate = await securityService.authenticate();
        return true;
    } catch (e) {
        return false;
    }
};


class AuthStatus {
    @observable isLoggedIn = null;
    constructor() {}

    @action setIsLoggedIn(value) {
        this.isLoggedIn = value;
    }
}
export const authStatus = new AuthStatus();
