import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { StriimInputField, StriimButton, StriimTypography, StriimTab, StriimTabs, StriimChip } from "@striim/striim-ui";
import useStyles from "./users-page-filters.styles";
import { useUserManagement } from "core/user-management/user-management-context";
import { Search } from "@material-ui/icons";
import _ from "underscore";
import { USER_TABLE_STRINGS } from "../../constants";
import USERS_ROUTES from "../../../routes.json";
import { Box, Grid, SvgIcon } from "@mui/material";
import { useNavigate } from "react-router";
import { AddUserIcon } from "../../../../../../../generic/icon/customIcons";
import securityService from "../../../../../../../../core/services/securityService/securityService";
const styles = {
    selectContainer: {
        maxWidth: { xs: "100px", sx: "200px", md: "350px", lg: "500px", xl: "1000px" }
    }
};

const UsersPageFilters = ({ activeTab, setActiveTab, content, ssoConfigured }) => {
    const { filter, clearFilter, filterTable, users, roles } = useUserManagement();
    const themeV5 = useTheme();
    const classes = useStyles(themeV5);
    const navigate = useNavigate();

    const [filterValue, setFilterValue] = useState([]);
    const [filterOptions, setFilterOptions] = useState([]);
    const [filterSuggestions, setFilterSuggestions] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isNoOptionMenuOpen, setIsNoOptionMenuOpen] = useState(false);
    const [showViewAllResults, setShowViewAllResults] = useState(false);
    const searchInputRef = useRef();

    const isSsoTab = activeTab === 2;

    useEffect(() => {
        if (filterValue && filterValue?.length !== 0) {
            setFilterValue([]);
            clearFilter();
        }

        return () => {
            if (!filter.isResultEmpty) {
                clearFilter();
            }
        };
    }, [activeTab, users, roles]);

    const handleInput = (input, { action }) => {
        if (action === "input-change") {
            setIsMenuOpen(true);
        } else if (action === "input-blur") {
            setIsNoOptionMenuOpen(false);
        } else {
            isMenuOpen && setIsMenuOpen(false);
        }
        if (input === "" || input === null) {
            filterSuggestions.length !== 0 && setFilterSuggestions([]);
            searchInputRef.current = "";
            return;
        }
        searchInputRef.current = input;
        const searchInput = input.toLowerCase();
        const searchParam = input
            .split(":")
            .pop()
            .trim();
        loadOptions(input, searchInput, searchParam);
    };

    const loadOptions = (input, searchInput, searchParam) => {
        let filteredOptions;
        if (
            searchInput.startsWith("user:") ||
            searchInput.startsWith("role:") ||
            searchInput.startsWith("permission:")
        ) {
            filteredOptions = filterOptions.filter(v => v.value.toLowerCase().includes(searchInput));
        } else {
            filteredOptions = filterOptions.filter(v => v.label.toLowerCase().includes(searchInput));
        }
        const uniqueOptions = _.uniq(filteredOptions, "value");
        const updatedFilterSuggestions =
            uniqueOptions.length === 0
                ? [{ label: input, value: input }]
                : uniqueOptions.length > 3 && searchParam
                ? [...uniqueOptions.slice(0, 3)]
                : uniqueOptions;
        if (searchParam && uniqueOptions.length > 3) {
            setShowViewAllResults(true);
        } else {
            setShowViewAllResults(false);
        }
        setFilterSuggestions(updatedFilterSuggestions);
    };

    const loadAllOptions = searchInput => {
        let filteredOptions;
        if (
            searchInput.startsWith("user:") ||
            searchInput.startsWith("role:") ||
            searchInput.startsWith("permission:")
        ) {
            filteredOptions = filterOptions.filter(v => v.value.toLowerCase().includes(searchInput));
        } else {
            filteredOptions = filterOptions.filter(v => v.label.toLowerCase().includes(searchInput));
        }
        const uniqueOptions = _.uniq(filteredOptions, "value");
        const updatedFilterSuggestions = uniqueOptions;
        setFilterSuggestions(updatedFilterSuggestions);
    };

    const loadFilterOptions = () => {
        setIsNoOptionMenuOpen(true);
        const isFilterForUserTable = activeTab === 0;

        const dataList = isFilterForUserTable ? users : roles;
        const options = [
            ...dataList
                .map(v => {
                    const name = isFilterForUserTable
                        ? {
                              label: v[USER_TABLE_STRINGS.label.userId],
                              value: `User:${v[USER_TABLE_STRINGS.label.userId]}`
                          }
                        : { label: v.name, value: `Role:${v.name}` };
                    const roles = v.roles?.map(v => ({ label: v.name, value: `Role:${v.name}` }));
                    const permissions = v.permissionsText.map(v => ({ label: v.name, value: `Permission:${v.name}` }));
                    return [name, ...permissions, ...roles];
                })
                .flat()
        ];
        setFilterOptions(options);
    };

    const handleFilterChange = input => {
        let values = input?.map(v => ({ label: v.value || v, value: v.value || v, searchAll: v.searchAll }));
        setFilterValue(values);
        filterTable(values, activeTab);
    };

    const NoOptionsMessage = () => {
        return (
            <Grid container>
                <Grid item className={classes.noOptionsMessage}>
                    <StriimTypography variant="body">Search by</StriimTypography>
                    {activeTab === 0 && (
                        <StriimChip type="tag-chip" variant="select" label="User" className={classes.filterLabel} />
                    )}
                    <StriimChip type="tag-chip" variant="select" label="Role" className={classes.filterLabel} />
                    <StriimChip type="tag-chip" variant="select" label="Permission" className={classes.filterLabel} />
                </Grid>
            </Grid>
        );
    };

    const MenuList = props => {
        const { MenuListFooter } = props.selectProps.components;

        return (
            <Grid container className={classes.menuContainer}>
                {props.children}
                {props.children.length && MenuListFooter}
            </Grid>
        );
    };

    const MenuListFooter = () => (
        <Grid
            container
            item
            className={classes.viewAllResults}
            data-test-id="view-all-results-option"
            onClick={e => {
                loadAllOptions(searchInputRef.current?.toLowerCase());
                setShowViewAllResults(false);
            }}
        >
            <Search opacity={0.45} />
            <StriimTypography variant="body" className={classes.viewAllText}>
                View all results for {searchInputRef.current}
            </StriimTypography>
        </Grid>
    );

    const SsoIcon = () => {
        const icon = ssoConfigured ? (
            <CheckCircleIcon className={classes.iconConfigured} data-test-id="ssoIndicator-true" />
        ) : (
            <CancelIcon className={classes.iconNotConfigured} data-test-id="ssoIndicator-false" />
        );
        const iconClass = ssoConfigured ? classes.ssoConfigured : classes.ssoNotConfigured;

        return <span className={clsx(classes.ssoIcon, iconClass)}>{icon}</span>;
    };

    return (
        <Grid container spacing={2} px={4}>
            <Grid item xs={12}>
                <Grid
                    className={classes.headingContainer}
                    container
                    spacing={2}
                    alignItems="center"
                    alignContent="space-between"
                >
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <StriimTypography variant="h1" color="primary.700">
                                    User and Role Management
                                </StriimTypography>
                            </Grid>
                            <Grid item xs={6} display="flex" alignItems="center" justifyContent="end">
                                {!isSsoTab && (
                                    <Box
                                        data-test-id="search-user-role"
                                        display="flex"
                                        flexGrow="2"
                                        marginRight={2}
                                        sx={styles.selectContainer}
                                    >
                                        <StriimInputField
                                            placeholder="Search"
                                            select
                                            value={filterValue}
                                            onFocus={loadFilterOptions}
                                            onChange={handleFilterChange}
                                            fullWidth
                                            SelectProps={{
                                                components: {
                                                    DropdownIndicator: () => null,
                                                    IndicatorsContainer: () => null,
                                                    NoOptionsMessage: isNoOptionMenuOpen =>
                                                        isNoOptionMenuOpen ? <NoOptionsMessage /> : null,
                                                    MenuList: MenuList,
                                                    MenuListFooter: showViewAllResults && <MenuListFooter />
                                                },
                                                onInputChange: handleInput,
                                                options: filterSuggestions,
                                                isMulti: true,
                                                isClearable: true,
                                                menuIsOpen: isMenuOpen || isNoOptionMenuOpen,
                                                disableFirstLetterFiltering: true,
                                                withSearchIcon: true
                                            }}
                                        />
                                    </Box>
                                )}

                                <Box display="flex" height="100%" alignItems="center" minWidth="260px">
                                    <StriimButton
                                        data-test-id="add-user-button"
                                        onClick={() => navigate(USERS_ROUTES.usersUserCreate)}
                                        variant="primary"
                                        startIcon={<SvgIcon component={AddUserIcon} sx={{ fill: "none" }} />}
                                        sx={{ marginRight: 2 }}
                                    >
                                        Create user
                                    </StriimButton>
                                    <StriimButton
                                        data-test-id="create-role-button"
                                        onClick={() => navigate(USERS_ROUTES.usersRoleCreate)}
                                        variant="secondary"
                                    >
                                        Create role
                                    </StriimButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {!filter.isResultEmpty && (
                <Grid item xs={12}>
                    <StriimTypography variant="body4">{filter.filterMessage}</StriimTypography>
                </Grid>
            )}

            <Grid item xs={12}>
                <StriimTabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} content={content}>
                    <StriimTab
                        data-testid="user-management-tab"
                        label={
                            <StriimTypography variant="caption2" className={classes.tabLabel}>
                                User management
                            </StriimTypography>
                        }
                    />
                    <StriimTab
                        data-testid="role-management-tab"
                        label={
                            <StriimTypography variant="caption2" className={classes.tabLabel}>
                                Role management
                            </StriimTypography>
                        }
                    />
                    {!securityService.isSaaSEnvironment() && (
                        <StriimTab
                            data-testid="single-sign-tab"
                            label={
                                <StriimTypography variant="caption2" className={classes.tabLabel}>
                                    Single Sign On
                                    <SsoIcon />
                                </StriimTypography>
                            }
                        />
                    )}
                </StriimTabs>
            </Grid>
        </Grid>
    );
};

export default UsersPageFilters;
