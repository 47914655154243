import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./ehandlers.html";

var View = BaseControl.View.extend({
    template: _.template(template),

    getViewValue: function () {
        var that = this;
        var result = {};

        _.each(that.model.dataSource.properties, function (property) {
            result[property] = that.$('input[name="' + property + '"]:checked').val();
        });
        return result;
    },

    setViewValue: function () {
        var _this = this;

        _.each(_this.model.dataSource.properties, function (property) {
            var value = _this.getValue();

            var exceptionStatus;
            if (value && value[property]) {
                exceptionStatus = value[property];
            } else {
                exceptionStatus = "STOP";
            }

            _this
                .$('input[name="' + property + '"]')
                .val([exceptionStatus])
                .on("change", function () {
                    _this.model.value = _this.getViewValue();
                });
        });
    },
});

export default function (dataSource) {
    var Model = BaseControl.Model.extend({
        defaults: {
            dataSource: dataSource,
            value: Object,
        },
    });

    return _.extend({}, BaseControl, {
        Model: Model,
        View: View,
    });
}
