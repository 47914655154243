import React, { useEffect, useRef, useState } from "react";
import ValidationList from "src/modules/wizards/common/validation-list/validation-list.tsx";
import { StriimTheme } from "@striim/striim-ui-v2";
import App from "app";

const ValitateSource = ({ validationList, async = true }) => {
    const childRef = useRef();
    const [validations, setValidations] = useState(validationList);
    const [apiStatus, setApiStatus] = useState({
        isAPISuccessful: false,
        isAPICompleted: false,
        canRetry: false
    });

    useEffect(() => {
        App.vent.trigger("appwizard:retry:hide");
        if (apiStatus.canRetry) {
            App.vent.trigger("appwizard:retry:show");
        }
    }, [apiStatus]);

    const clearValidationStatus = () => {
        const list = [...validations];
        list.map(item => {
            item.children = null;
            item.status = "todo";
        });
        setValidations(list);
    };

    useEffect(() => {
        function handleClick() {
            clearValidationStatus();
            App.vent.trigger("appwizard:retry:hide");
            App.vent.trigger("appwizard:next:disable");
            childRef?.current?.retryAPI();
        }
        $("#footer-retry-button").on("click", handleClick);
        return () => {
            $("#footer-retry-button").off("click", handleClick);
        };
    }, []);

    return (
        <StriimTheme>
            <ValidationList
                validationList={validations}
                setAPIStatus={setApiStatus}
                ref={childRef}
                onSuccess={() => App.vent.trigger("appwizard:goForward", "validate-source")}
                async={async}
            />
        </StriimTheme>
    );
};

export default ValitateSource;
