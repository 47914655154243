import React, { useState } from "react";
import { StriimInputField, StriimIconButton, StriimToggleButton } from "@striim/striim-ui";
import { useObserver } from "mobx-react-lite";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import FileBrowserAsTextbox from "../filebrowser/filebrowser_as_textbox";

export const KeySelector = ({ store, selectedKey, uuid }) => {
    // eslint-disable-next-line no-unused-vars
    const [currentKey, setCurrentKey] = useState(selectedKey);
    const creatable = store.UIConfig.allowCustom;
    const options = Object.keys(store.UIConfig.options)
        .filter(entry => {
            let show = true;
            store.values.forEach(value => {
                if (value["Property"] && value["Property"].trim() === entry && !value["isDeleted"]) show = false;
            });
            return show;
        })
        .map(key => {
            return { value: key, label: key };
        });

    const getTypeFromUIConfig = function(key) {
        for (let k in store.UIConfig.options) {
            if (k === key && Array.isArray(store.UIConfig.options[k])) return "ARRAY";
            if (k === key && store.UIConfig.options[k] !== undefined) return store.UIConfig.options[k];
        }
        return "TEXT";
    };

    const updateKey = function(key) {
        setCurrentKey(key);
        let type = getTypeFromUIConfig(key);
        store.updateKey(uuid, key);
        if(type === "TOGGLE"){
            store.updateValue(uuid, false);
        }
    };

    return useObserver(() => (
        <div uuid={uuid} data-test-id={"adapter-map-property-input--" + selectedKey} title={selectedKey}>
            <StriimInputField
                SelectProps={{
                    options: options,
                    isClearable: false,
                    menuPosition: "absolute",
                    maxMenuHeight: 88,
                    components: {
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        CrossIcon: () => null
                    },
                    selectVariant: creatable ? "creatable" : "select",
                    formatCreateLabel: val => {
                        return <>New: {val} </>;
                    }
                }}
                placeholder={"Select.."}
                value={selectedKey ? { value: selectedKey, label: selectedKey } : null}
                select
                onChange={val => {
                    updateKey(val.value);
                }}
                disabled={!store.enabled}
            />
        </div>
    ));
};

export const DeleteButton = ({ store, uuid, selectedKey }) => {
    function deleteRow() {
        store.deleteRow(uuid);
    }

    return (
        <StriimIconButton
            data-test-id={"adapter-map-del-input--" + selectedKey}
            onClick={() => {
                deleteRow();
            }}
            disabled={!store.enabled}
        >
            <DeleteOutlineOutlinedIcon />
        </StriimIconButton>
    );
};

export const ValueEntry = ({ store, selectedValue, selectedKey, uuid }) => {
    const getTypeFromUIConfig = function(key) {
        for (let k in store.UIConfig.options) {
            if (k === key && Array.isArray(store.UIConfig.options[k])) return "ARRAY";
            if (k === key && store.UIConfig.options[k] !== undefined) return store.UIConfig.options[k];
        }
        return "TEXT";
    };
    let type = getTypeFromUIConfig(selectedKey);
    let [toggleState, setToggleState] = useState(selectedValue === "true");

    let options = [];
    if (type === "ARRAY") {
        options = store.UIConfig.options[selectedKey].map(entry => {
            return { value: entry, label: entry };
        });
    }
    const updateValue = function(value) {
        store.updateValue(uuid, value);
    };

    return useObserver(() => (
        <div data-test-id={"adapter-map-value-input--" + selectedKey}>
            {type === "TEXT" && (
                <StriimInputField
                    defaultValue={selectedValue}
                    onChange={value => {
                        updateValue(value);
                    }}
                    disabled={!store.enabled}
                />
            )}
            {type === "PASSWORD" && (
                <StriimInputField
                    defaultValue={selectedValue}
                    type="password"
                    onChange={value => {
                        updateValue(value);
                    }}
                    disabled={!store.enabled}
                />
            )}
            {type === "TOGGLE" && (
                <StriimToggleButton
                    checked={toggleState}
                    onClick={() => {
                        updateValue(!toggleState);
                        setToggleState(!toggleState);
                    }}
                    disabled={!store.enabled}
                />
            )}
            {type === "FILE" && (
                <FileBrowserAsTextbox
                    defaultValue={selectedValue}
                    onChange={value => {
                        updateValue(value);
                    }}
                    disabled={!store.enabled}
                />
            )}
            {type === "ARRAY" && (
                <StriimInputField
                    SelectProps={{
                        options: options,
                        isClearable: false,
                        menuPosition: "fixed",
                        components: {
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            CrossIcon: () => null
                        }
                    }}
                    value={{ value: selectedValue, label: selectedValue }}
                    select
                    onChange={val => {
                        updateValue(val.value);
                    }}
                    disabled={!store.enabled}
                />
            )}
        </div>
    ));
};
