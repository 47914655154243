import React from "react";
import { Navigate } from "react-router-dom";
import { isUserAdmin } from "../../../core/user-management/update.control";
import securityService from "../../../core/services/securityService/securityService";
import ALERT_MANAGER_ROUTES from "./routes.json";
import { addonFeaturesKeys } from "../user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";

export default function AlertManagerRoot({ children }) {
    const isAdmin = isUserAdmin();

    const isFeatureEnabled = securityService.isSegmentationFeatureEnabled(addonFeaturesKeys.ALERTMANAGER);

    if ((!isAdmin && securityService.isDeveloperLicense()) || !isFeatureEnabled) {
        return <Navigate to={{ pathname: ALERT_MANAGER_ROUTES.unauthorized }} />;
    }

    return <>{children}</>;
}
