import utils from "core/utils";

export const FlowStatus = {
    UNKNOWN: "UNKNOWN",
    /** Created status. */
    CREATED: "CREATED",
    DEPLOY_FAILED: "DEPLOY_FAILED",
    /** Created, but has compile-time errors */
    INVALID: "INVALID",
    DEPLOYING: "DEPLOYING",
    /** Deployed status. */
    DEPLOYED: "DEPLOYED",
    STARTING: "STARTING",
    STARTING_SOURCES: "STARTING_SOURCES",
    RECOVERING_SOURCES: "RECOVERING_SOURCES",
    RUNNING: "RUNNING",
    STOPPING: "STOPPING",
    /** Deployed status - on UI it should works the same as {@link FlowStatus.DEPLOYED}. */
    STOPPED: "STOPPED",
    /** Was in RUNNING state, but run-time errors occured */
    /** Crash status has been renamed to {@link FlowStatus.TERMINATED}. */
    CRASH: "CRASH",
    TERMINATED: "TERMINATED",
    HALT: "HALT",
    NOT_ENOUGH_SERVERS: "NOT_ENOUGH_SERVERS",
    QUIESCING: "QUIESCING",
    QUIESCED: "QUIESCED",
    COMPLETED: "COMPLETED",
    VERIFYING_STARTING: "VERIFYING_STARTING",

    APPROVING_QUIESCE: "APPROVING_QUIESCE",
    RUNNING_UNTIL_QUIESCE: "RUNNING_UNTIL_QUIESCE",
    RUNNING_UNTIL_END: "RUNNING_UNTIL_END",
    LOAD_BALANCE_STOPPED: "LOAD_BALANCE_STOPPED", // Still under auto load balancing, will redeploy the app (the app should not stay at this status)
    LOAD_BALANCE_DEPLOYED: "LOAD_BALANCE_DEPLOYED",
    FLUSHING: "FLUSHING"
};

export const StateToChipVariant = {
    running: "success",
    running_until_quiesce: "success",
    running_until_end: "success",
    created: "default",
    deploying: "default",
    flushing: "default",
    load_balance_deployed: "secondary",
    deployed: "secondary",
    starting: "secondary",
    stopping: "success",
    quiescing: "default",
    completed: "secondary",
    load_balance_stopped: "secondary",
    stopped: "secondary",
    quiesced: "secondary",
    crash: "error",
    terminated: "error",
    invalid: "error",
    deploy_failed: "error",
    not_enough_servers: "error",
    halted: "warning",
    others: "others"
};

export const AppAction = {
    VIEW: "VIEW",
    MONITOR: "MONITOR",
    EXPORT: "EXPORT",
    START: "START",
    STOP: "STOP",
    QUIESCE: "QUIESCE",
    UNDEPLOY: "UNDEPLOY",
    DEPLOY: "DEPLOY",
    RESUME: "RESUME",
    DROP: "DROP",
    DASHBOARD: "DASHBOARD",
    SHOW_ERRORS: "SHOW_ERRORS",
    CHANGE_GROUP: "CHANGE_GROUP",
    SHERLOCK: "SHERLOCK"
};

export default {
    isValidStateToShowAdapterRate: function(status) {
        if (!status) {
            return false;
        }
        const validStatusesToShowAdapterRate = [
            FlowStatus.RUNNING,
            FlowStatus.QUIESCING,
            FlowStatus.STOPPING,
            FlowStatus.STARTING,
            FlowStatus.STARTING_SOURCES,
            FlowStatus.RECOVERING_SOURCES
        ];
        status = status.toUpperCase();
        return validStatusesToShowAdapterRate.indexOf(status) !== -1;
    },
    /** Check if state is Deployed or Stopped. On the UI, STOPPED is equivalent to DEPLOYED */
    isDeployedState: function(status) {
        const deployedStatuses = [
            FlowStatus.DEPLOYED,
            FlowStatus.STOPPED,
            FlowStatus.QUIESCED,
            FlowStatus.COMPLETED,
            FlowStatus.LOAD_BALANCE_DEPLOYED,
            FlowStatus.LOAD_BALANCE_STOPPED
        ];

        status = status.toUpperCase();
        return deployedStatuses.indexOf(status) !== -1;
    },

    /** Check if status is transient and will change without user action */
    isTransientStatus: function(status) {
        if (!status) return true;
        status = status.toUpperCase();
        return (
            status === FlowStatus.STOPPING ||
            status === FlowStatus.DEPLOYING ||
            status === FlowStatus.STARTING ||
            status === FlowStatus.QUIESCING ||
            status === FlowStatus.STARTING_SOURCES ||
            status === FlowStatus.RECOVERING_SOURCES ||
            status === FlowStatus.VERIFYING_STARTING ||
            status === FlowStatus.FLUSHING ||
            status === FlowStatus.APPROVING_QUIESCE
        );
    },

    isCompletedStatus: function(status) {
        status = status.toUpperCase();
        return status === FlowStatus.COMPLETED;
    },
    isStoppedStatus: function(status) {
        status = status.toUpperCase();
        return status === FlowStatus.STOPPED || status === FlowStatus.LOAD_BALANCE_STOPPED;
    },
    isRunningState: function(status) {
        status = status.toUpperCase();
        return (
            status === FlowStatus.RUNNING ||
            status === FlowStatus.RUNNING_UNTIL_END ||
            status === FlowStatus.RUNNING_UNTIL_QUIESCE
        );
    },
    isRecoveringStatus: function(status) {
        status = status.toUpperCase();
        return status === FlowStatus.STARTING_SOURCES || status === FlowStatus.RECOVERING_SOURCES;
    },
    isStartingState: function(status) {
        status = status.toUpperCase();
        return status === FlowStatus.STARTING;
    },
    isRunningState: function(status) {
        status = status.toUpperCase();
        return (
            status === FlowStatus.RUNNING ||
            status === FlowStatus.RUNNING_UNTIL_END ||
            status === FlowStatus.RUNNING_UNTIL_QUIESCE
        );
    },

    isInterruptibleStatus: function(status) {
        status = status.toUpperCase();
        return (
            status !== FlowStatus.STOPPING &&
            status !== FlowStatus.QUIESCING &&
            status !== FlowStatus.STARTING_SOURCES &&
            status !== FlowStatus.RECOVERING_SOURCES
        );
    },

    setAppStatusClassOnNode: function(node, appStatus) {
        Object.keys(FlowStatus).forEach(function(key) {
            var status = FlowStatus[key];
            node.removeClass(status);
            node.removeClass(status.toLowerCase());
        });
        node.addClass(appStatus);
    },

    isApplicationRunning: function(flowStatus) {
        let status = flowStatus.toUpperCase();
        return (
            status === FlowStatus.RUNNING ||
            status === FlowStatus.RUNNING_UNTIL_END ||
            status === FlowStatus.RUNNING_UNTIL_QUIESCE
        );
    },

    isApplicationCreated: function(flowStatus) {
        const createdStatuses = [FlowStatus.CREATED, FlowStatus.INVALID, FlowStatus.DEPLOY_FAILED];
        const status = flowStatus.toUpperCase();
        return createdStatuses.indexOf(status) !== -1;
    },

    isApplicationDeployed: function(flowStatus) {
        const status = flowStatus.toUpperCase();
        return status === FlowStatus.DEPLOYED || status === FlowStatus.LOAD_BALANCE_DEPLOYED;
    },
    isApplicationCrash: function(flowStatus) {
        return (
            flowStatus.toUpperCase() === FlowStatus.CRASH ||
            flowStatus.toUpperCase() === FlowStatus.TERMINATED ||
            flowStatus.toUpperCase() === FlowStatus.DEPLOY_FAILED ||
            flowStatus.toUpperCase() === FlowStatus.HALT
        );
    },

    isApplicationFailedOnDeployment: function(flowStatus) {
        return flowStatus.toUpperCase() === FlowStatus.DEPLOY_FAILED;
    },

    isApplicationInvalid: function(flowStatus) {
        return flowStatus.toUpperCase() === FlowStatus.INVALID;
    },

    getStatusDisplayName: function(flowStatus) {
        if (!flowStatus) return "";
        const statusLowerCase = flowStatus.toLowerCase();
        if (statusLowerCase === FlowStatus.STARTING_SOURCES.toLowerCase()) {
            return "Starting sources";
        }
        if (statusLowerCase === FlowStatus.RECOVERING_SOURCES.toLowerCase()) {
            return "Recovering sources";
        }
        if (statusLowerCase === FlowStatus.NOT_ENOUGH_SERVERS.toLowerCase()) {
            return "Not enough servers";
        }

        if (statusLowerCase === FlowStatus.DEPLOY_FAILED.toLowerCase()) {
            return "Deploy failed";
        }

        if (statusLowerCase === FlowStatus.HALT.toLowerCase()) {
            return "Halted";
        }

        if (statusLowerCase === FlowStatus.CRASH.toLowerCase()) {
            return "Terminated";
        }

        if (statusLowerCase === FlowStatus.VERIFYING_STARTING.toLowerCase()) {
            return "Verifying starting";
        }
        return utils.capitalize(flowStatus);
    },

    getStatusChipDisplayName: function(flowStatus, action, previousStatus) {
        const statusMap = {
            [AppAction.START]: {
                skipStatus: [FlowStatus.STARTING_SOURCES, FlowStatus.VERIFYING_STARTING],
                showStatus: FlowStatus.STARTING
            },
            [AppAction.RESUME]: {
                skipStatus: [FlowStatus.STARTING_SOURCES, FlowStatus.VERIFYING_STARTING],
                showStatus: FlowStatus.STARTING
            },
            [AppAction.STOP]: {
                skipStatus: [FlowStatus.DEPLOYING],
                showStatus: FlowStatus.STOPPING
            },
            [FlowStatus.STARTING]: {
                skipStatus: [FlowStatus.STARTING_SOURCES, FlowStatus.VERIFYING_STARTING],
                showStatus: FlowStatus.STARTING
            },
            [FlowStatus.STOPPING]: {
                skipStatus: [FlowStatus.DEPLOYING],
                showStatus: FlowStatus.STOPPING
            },
            [FlowStatus.QUIESCING]: {
                skipStatus: [FlowStatus.STOPPING, FlowStatus.DEPLOYING],
                showStatus: FlowStatus.QUIESCING
            }
        };

        if (action && statusMap[action] && statusMap[action].skipStatus.includes(flowStatus)) {
            return this.getStatusDisplayName(statusMap[action].showStatus);
        }
        if (!action && statusMap[previousStatus] && statusMap[previousStatus].skipStatus.includes(flowStatus)) {
            return this.getStatusDisplayName(statusMap[previousStatus].showStatus);
        }
        return this.getStatusDisplayName(flowStatus);
    },

    getStatus: function(status) {
        const getCrashActions = () => {
            return [
                AppAction.VIEW,
                AppAction.MONITOR,
                AppAction.EXPORT,
                AppAction.RESUME,
                AppAction.UNDEPLOY,
                AppAction.SHOW_ERRORS,
                AppAction.CHANGE_GROUP
            ];
        };
        status = status.toUpperCase();

        function getQuiescedActions() {
            return [
                AppAction.VIEW,
                AppAction.MONITOR,
                AppAction.EXPORT,
                AppAction.START,
                AppAction.UNDEPLOY,
                AppAction.DASHBOARD,
                AppAction.CHANGE_GROUP
            ];
        }

        switch (status) {
            case FlowStatus.DEPLOYED:
            case FlowStatus.STOPPED:
            case FlowStatus.LOAD_BALANCE_DEPLOYED:
            case FlowStatus.LOAD_BALANCE_STOPPED:
                return {
                    id: FlowStatus.DEPLOYED,
                    title: "Deployed",
                    actions: [
                        AppAction.VIEW,
                        AppAction.MONITOR,
                        AppAction.EXPORT,
                        AppAction.START,
                        AppAction.UNDEPLOY,
                        AppAction.CHANGE_GROUP
                    ]
                };
            case FlowStatus.STOPPING:
            case FlowStatus.QUIESCING:
                return {
                    id: FlowStatus.STOPPING,
                    title: "Stopping",
                    actions: [AppAction.VIEW, AppAction.MONITOR, AppAction.EXPORT, AppAction.CHANGE_GROUP]
                };
            case FlowStatus.STARTING:
                return {
                    id: FlowStatus.STARTING,
                    title: "Starting",
                    actions: [
                        AppAction.VIEW,
                        AppAction.MONITOR,
                        AppAction.EXPORT,
                        AppAction.STOP,
                        AppAction.CHANGE_GROUP
                    ]
                };
            case FlowStatus.STARTING_SOURCES:
                return {
                    id: FlowStatus.STARTING_SOURCES,
                    title: "Starting sources",
                    actions: [
                        AppAction.VIEW,
                        AppAction.MONITOR,
                        AppAction.EXPORT,
                        AppAction.STOP,
                        AppAction.CHANGE_GROUP
                    ]
                };
            case FlowStatus.DEPLOYING:
                return {
                    id: FlowStatus.DEPLOYING,
                    title: "Deploying",
                    actions: [
                        AppAction.VIEW,
                        AppAction.MONITOR,
                        AppAction.EXPORT,
                        AppAction.UNDEPLOY,
                        AppAction.CHANGE_GROUP
                    ]
                };
            case FlowStatus.INVALID:
                return {
                    id: FlowStatus.INVALID,
                    title: "Invalid",
                    actions: [
                        AppAction.VIEW,
                        AppAction.EXPORT,
                        AppAction.DROP,
                        AppAction.SHOW_ERRORS,
                        AppAction.CHANGE_GROUP
                    ]
                };
            case FlowStatus.CREATED:
                return {
                    id: FlowStatus.CREATED,
                    title: "Created",
                    actions: [
                        AppAction.VIEW,
                        AppAction.MONITOR,
                        AppAction.EXPORT,
                        AppAction.DEPLOY,
                        AppAction.DROP,
                        AppAction.DASHBOARD,
                        AppAction.CHANGE_GROUP
                    ]
                };
            case FlowStatus.DEPLOY_FAILED:
                return {
                    id: FlowStatus.DEPLOY_FAILED,
                    title: "Deploy failed",
                    actions: [
                        AppAction.VIEW,
                        AppAction.MONITOR,
                        AppAction.EXPORT,
                        AppAction.DEPLOY,
                        AppAction.DROP,
                        AppAction.DASHBOARD,
                        AppAction.SHOW_ERRORS,
                        AppAction.CHANGE_GROUP
                    ]
                };
            case FlowStatus.RUNNING:
            case FlowStatus.RUNNING_UNTIL_END:
            case FlowStatus.RUNNING_UNTIL_QUIESCE:
                return {
                    id: FlowStatus.RUNNING,
                    title: "Running",
                    //TODO: quiesce is temporary not visible. Uncomment the line below when functionality is ready (DEV-11824)
                    actions: [
                        AppAction.VIEW,
                        AppAction.MONITOR,
                        AppAction.EXPORT,
                        AppAction.STOP,
                        AppAction.DASHBOARD /*, AppAction.QUIESCE*/,
                        AppAction.CHANGE_GROUP
                    ]
                };
            case FlowStatus.QUIESCED:
                return {
                    id: FlowStatus.QUIESCED,
                    title: "Quiesced",
                    actions: getQuiescedActions()
                };
            case FlowStatus.COMPLETED:
                return {
                    id: FlowStatus.COMPLETED,
                    title: "Completed",
                    actions: getQuiescedActions()
                };
            case FlowStatus.CRASH:
            case FlowStatus.TERMINATED:
                return {
                    id: FlowStatus.CRASH,
                    title: "Terminated",
                    actions: getCrashActions()
                };
            case FlowStatus.HALT:
                return {
                    id: FlowStatus.HALT,
                    title: "Halt",
                    actions: getCrashActions()
                };
            case FlowStatus.NOT_ENOUGH_SERVERS:
                return {
                    id: FlowStatus.NOT_ENOUGH_SERVERS,
                    title: "Not Enough Servers",
                    actions: [
                        AppAction.VIEW,
                        AppAction.MONITOR,
                        AppAction.EXPORT,
                        AppAction.UNDEPLOY,
                        AppAction.CHANGE_GROUP
                    ]
                };
        }
    },

    appActions: [
        {
            id: AppAction.VIEW,
            icon: "sitemap",
            text: "Manage Flow",
            description: "Edit this app's flows and components"
        },
        {
            id: AppAction.MONITOR,
            icon: "area-chart",
            text: "Monitor App",
            description: "Monitor App Performance"
        },
        {
            id: AppAction.DASHBOARD,
            icon: "dashboard",
            text: "View dashboard"
        },
        {
            id: AppAction.EXPORT,
            icon: "file-code-o",
            text: "Export TQL",
            description: "Export this application as a TQL script"
        },
        {
            id: AppAction.START,
            icon: "play",
            text: "Start",
            visible: false
        },
        {
            id: AppAction.STOP,
            icon: "stop",
            text: "Stop",
            visible: false
        },
        {
            id: AppAction.QUIESCE,
            icon: "stop",
            text: "Quiesce",
            visible: false
        },
        {
            id: AppAction.UNDEPLOY,
            icon: "reply",
            text: "Undeploy",
            visible: false
        },
        {
            id: AppAction.DEPLOY,
            icon: "rocket",
            text: "Deploy",
            visible: false
        },
        {
            id: AppAction.RESUME,
            icon: "play",
            text: "Resume",
            visible: false
        },
        {
            id: AppAction.DROP,
            icon: "trash",
            text: "Drop",
            visible: false
        },
        {
            id: AppAction.SHOW_ERRORS,
            icon: "exclamation",
            text: "Show Errors",
            visible: false
        },
        {
            id: AppAction.CHANGE_GROUP,
            icon: "tags",
            text: "Move to group",
            description: "Move application to group"
        }
    ]
};
