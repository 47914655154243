import Backbone from "backbone";

var AppMenu = {};

AppMenu.MenuItemView = Backbone.Marionette.ItemView.extend({
    tagName: "li",
    className: "",
    template:
        '<span><a class="collection-item" href="#/monitor/{{appName}}/{{dataType}}"><div class="striimline-icon {{icon}}"></div>{{title}}</a></span>',

    modelEvents: {
        "change:active": "highlight",
    },

    initialize: function (options) {
        this.model.set({
            appName: options.appName,
            active: false,
        });
    },
    highlight: function () {
        if (this.model.get("active")) {
            this.$el[0].children[0].classList.add("active");
        } else if (this.$el[0].children[0]) {
            this.$el[0].children[0].classList.remove("active");
        }
    },
});
AppMenu.View = Backbone.Marionette.CollectionView.extend({
    tagName: "ul",
    className: "collection",
    collection: new Backbone.Collection([
        {
            dataType: "monitorReport",
            icon: "appmenu_monitor-report",
            title: "Monitor Report",
        },
        {
            dataType: "sources",
            icon: "appmenu_sources",
            title: "Sources",
        },
        {
            dataType: "targets",
            icon: "appmenu_targets",
            title: "Targets",
        },
        {
            dataType: "lagreporting",
            icon: "appmenu_latency-report",
            title: "Latency Report",
        },
        {
            dataType: "statsreporting",
            icon: "appmenu_interval-report",
            title: "Interval Report",
        },
        {
            dataType: "overview",
            icon: "appmenu_app-overview",
            title: "App Overview",
        },
        {
            dataType: "caches",
            icon: "appmenu_caches",
            title: "Caches",
        },
        {
            dataType: "eventstable",
            icon: "appmenu_event-tables",
            title: "Event tables",
        },
        {
            dataType: "externalcaches",
            icon: "appmenu_external-caches",
            title: "External Caches",
        },
        {
            dataType: "queries",
            icon: "appmenu_queries",
            title: "Queries",
        },
        {
            dataType: "streams",
            icon: "appmenu_streams",
            title: "Streams",
        },
        {
            dataType: "wactionstores",
            icon: "appmenu_wactionstores",
            title: "WActionStores",
        },
        {
            dataType: "windows",
            icon: "appmenu_windows",
            title: "Windows",
        },
    ]),
    childView: AppMenu.MenuItemView,
    childViewOptions: function () {
        return {
            appName: this.options.appName,
        };
    },
    highlight: function (dataType = "monitorReport") {
        this.collection.each(function (childModel) {
            if (childModel.get("dataType") === dataType) {
                childModel.set("active", true);
            } else {
                childModel.set("active", false);
            }
        });
    },
});

export default AppMenu;
