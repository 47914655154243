import { ItemView } from "marionette";
import App from "app";
import { template, tagName, ui, triggers } from "core/decorators";
import errorTemplate from "./validation-error.html";

@template(errorTemplate)
@ui({
    sourceComponent: "a.source",
    targetComponent: "a.target",
})
@tagName("li")
@triggers({
    "click @ui.sourceComponent": {
        event: "source-click",
    },
    "click @ui.targetComponent": {
        event: "target-click",
    },
})
export default class ValidationErrorView extends ItemView {
    onShow() {
        App.vent.trigger("app:errors:exceptions-container:resized");
    }
}
