import _ from "underscore";
import BaseControl from "./../base-control";

export default function() {
    var View = BaseControl.View.extend({
        hideLabel: true,
        template: _.template("<div ></div>"),
        className: "line-break",
        getViewValue: function() {},

        setViewValue: function() {},

        onShow: function() {
            setTimeout(() => {
                let width = this.$el.closest(".meta-object-editor").width();
                this.$el.width(width + "px");
            }, 100);
        }
    });

    return _.extend({}, BaseControl, {
        View: View
    });
}
