import React from "react";
import PropTypes from "prop-types";
import { PropTypes as MobxPropTypes } from "mobx-react";
import { Box, ListItemText } from "@mui/material";
import { StriimButton, StriimTypography, StriimTooltip, StriimList, StriimListItem } from "@striim/striim-ui";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";

import useStyles from "./app-group-select.styles";
import useStores from "src/utils/use-stores";

const styles = {
    listItemText: {
        maxWidth: 368,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    listItem: {
        px: 2,
        py: 1.25
    }
};

const AppGroupSelectList = ({ groups, applicationId, onClose, setCreateGroupMode, selectedApps }) => {
    const classes = useStyles();
    const { store } = useStores();

    const getNotAvailableGroups = () => {
        const selectedAppGroups = selectedApps.reduce((selectedGroups, { id }) => {
            const group = groups.find(x => x.applications.indexOf(id) !== -1);
            if (group) {
                selectedGroups.add(group.name);
            }
            return selectedGroups;
        }, new Set());
        return selectedAppGroups.size === 1 ? Array.from(selectedAppGroups) : [];
    };

    const notAvailableGroups = applicationId
        ? groups.filter(x => x.applications.indexOf(applicationId) !== -1).map(x => x.name)
        : getNotAvailableGroups();

    const sortedGroups = groups
        .slice()
        .filter(group => group.groupType !== "ILCDC")
        .sort((group1, group2) => notAvailableGroups.indexOf(group1.name) - notAvailableGroups.indexOf(group2.name));

    const appName = applicationId
        ? applicationId.split(".")[2]
        : store.selectedApps.length === 1
        ? store.selectedApps[0].name
        : "";

    return (
        <div className={classes.wrapper} id="apptile--groups-dropdown">
            <div className={classes.header}>
                <StriimTypography variant="h3">Select a Group</StriimTypography>
                <StriimButton
                    startIcon={<AddIcon />}
                    id="groups-dropdown--create"
                    variant="text"
                    onClick={e => {
                        setCreateGroupMode(true);
                        e.stopPropagation();
                    }}
                >
                    Create New
                </StriimButton>
            </div>
            <StriimList>
                {sortedGroups.map(({ name, description }) => {
                    const isDisabled = notAvailableGroups.indexOf(name) !== -1;
                    const tooltipTitle = isDisabled
                        ? appName
                            ? `${appName} is already in this group`
                            : `Selected apps are already in this group`
                        : description || "";
                    return (
                        <StriimTooltip key={name} title={tooltipTitle}>
                            <div /* Tooltip is not working with child:disabled prop */>
                                <StriimListItem
                                    className={"groups-dropdown--group"}
                                    onClick={() => onClose({ name })}
                                    disabled={isDisabled}
                                    sx={styles.listItem}
                                >
                                    <ListItemText sx={styles.listItemText}>{name}</ListItemText>
                                </StriimListItem>
                            </div>
                        </StriimTooltip>
                    );
                })}
            </StriimList>
            <div className={classes.actions}>
                <Box
                    component={StriimButton}
                    className={classes.actionButton}
                    variant="text"
                    onClick={() => onClose()}
                    mr={3}
                >
                    Cancel
                </Box>
            </div>
        </div>
    );
};

AppGroupSelectList.propTypes = {
    groups: MobxPropTypes.observableArray,
    applicationId: PropTypes.string,
    onClose: PropTypes.func,
    setCreateGroupMode: PropTypes.func
};

export default AppGroupSelectList;
