import React from "react";
import { Navigate } from "react-router-dom";

import securityService from "../../../core/services/securityService/securityService";
import USER_PLAN_ROUTES  from "../user-plan/routes.json";

export default function UserPlanRoot({ children }) {
    const authorized = !securityService.isDeveloperLicense();

    if (!authorized) {
        return <Navigate to={{ pathname: USER_PLAN_ROUTES.planUnauthorized }} />;
    }

    return <>{children}</>;
}
