import React, { useRef, useEffect, useState, useCallback } from "react";
import { mapOptions } from "../utils";
import PropTypes from "prop-types";
import { StriimInputField, StriimForm } from "@striim/striim-ui";
import * as Yup from "yup";
import VaultsService from "../vaults/vaults-service";
import { Box } from "@mui/material";

export default function AddPropertySetModalContent({ setFormValues, setIsValid, theOptions = [] }) {
    const [options, setOptions] = useState(theOptions);
    const values = {
        name: "",
        nsName: ""
    };

    const formRef = useRef();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        nsName: Yup.string().required("Required")
    });

    const getOptions = useCallback(options => mapOptions(options), [options]);

    useEffect(() => {
        async function fetchData() {
            const theData = await VaultsService.getNamespaces();
            setOptions(theData?.models);
            console.log({ options });
        }

        if (!options.length) {
            fetchData();
        }
    }, [options]);

    return (
        <StriimForm values={values} validationSchema={validationSchema} formRef={formRef} validateOnMount={true}>
            {({ values, isValid }) => {
                setFormValues(values);
                setIsValid(isValid);
                return (
                    <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
                        <StriimInputField
                            required
                            name="name"
                            label="Name"
                            placeholder="Enter property set name"
                            isFormElement
                            id="data-test-id-add-property-set-name"
                        />
                        <StriimInputField
                            required
                            name="nsName"
                            label="Namespace"
                            select
                            id="data-test-id-namespace-select"
                            placeholder="Namespace"
                            SelectProps={{ options: getOptions(options), menuPosition: "fixed" }}
                            isFormElement
                        />
                    </Box>
                );
            }}
        </StriimForm>
    );
}

AddPropertySetModalContent.propTypes = {
    setFormValues: PropTypes.func.isRequired,
    setIsValid: PropTypes.func.isRequired
};
