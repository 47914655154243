import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import BaseEditor from "app/components/common/editor/meta-object-editors/base";
import MetaObjectDataSource from "app/components/common/editor/control/select/metaobject-datasource";
import FieldDataSource from "app/components/common/editor/control/select/field-datasource";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";

var Module = function(metadata_object, options) {
    options = options || {};
    options.displayFields = options.displayFields || [
        "mode",
        "headerInputFrom",
        "stream",
        "typeViewer",
        "partitionFields",
        "size"
    ];
    this.typeViewer = UIControl.TypeViewer.extend({
        hideLabel: true
    }).create();

    options.StaticControls = {
        headerInputFrom: UIControl.Header("Input From")
            .extend({
                tag: "h3"
            })
            .create(),
        typeViewer: this.typeViewer
    };
    BaseEditor.apply(this, arguments);
};

Module.prototype = _.extend({}, BaseEditor.prototype, {
    addCustomFields: function() {
        var _this = this;
        var jumpingDataSource = [
            {
                id: "jumping",
                text: "Jumping"
            },
            {
                id: "sliding",
                text: "Sliding"
            },
            {
                id: "session",
                text: "Session"
            }
        ];

        this.fieldMapper.mode = UIControl.Select(jumpingDataSource, {
            allowClear: false,
            hideSearch: true
        }).create();

        var streams = new MetaObjectDataSource(["STREAM"]);
        this.fieldMapper.stream = UIControl.Select(streams).create();

        var dataSource = new FieldDataSource();
        const partitionFields = UIControl.Select(dataSource, {
            multiple: true
        }).create();

        this.fieldMapper.partitionFields = partitionFields;
        this.licenseCheck["partitionFields"] = addonFeaturesKeys.PARTITIONEDWINDOWS;

        var sizeField = UIControl.WindowSize.extend({
            subControl: true
        }).create();

        sizeField.streamId = this.MetaObjectModel.stream;
        sizeField.isSliding = !this.MetaObjectModel.jumping;
        this.fieldMapper.size = sizeField;

        this.fieldMapper.stream.on(UIControl.EVENT.VALUE_CHANGED, function(stream_value) {
            _this.typeViewer.viewTypeOf(stream_value);

            var selectedStream = _this.fieldMapper.stream.model.selectedModel;
            if (selectedStream) {
                selectedStream.fetchMetaObject().then(function(metaObject) {
                    dataSource = new FieldDataSource(metaObject.dataType);
                    partitionFields.setDataSource(dataSource);
                });
            } else {
                partitionFields.setDataSource([]);
            }

            sizeField.trigger("change:stream:projections", stream_value);
        });

        this.fieldMapper.mode.on(UIControl.EVENT.VALUE_CHANGED, function(mode) {
            // sizeField.trigger("change:isSliding", (_this.MetaObjectModel.get('mode') === 'sliding') );
            _this.setsizeFieldProps(mode);
        });
        this.setsizeFieldProps(this.MetaObjectModel.get("mode"));
    },

    setsizeFieldProps: function(mode) {
        var _this = this;
        setTimeout(function() {
            _this.fieldMapper.size.trigger("change:mode", mode);
        }, 200);
    },

    postRender: function() {
        var _this = this;
        if (!this.MetaObjectModel.stream) {
            return;
        }
        metaStoreService.findById(this.MetaObjectModel.stream).then(function(stream) {
            if (!stream) {
                return;
            }

            _this.typeViewer.viewTypeOf(_this.MetaObjectModel.stream).always(function() {
                _this.Form.view.clearDirty(_this.typeViewer.model);
            });
            _this.fieldMapper.partitionFields.setDataSource(new FieldDataSource(stream.dataType));
        });
    }
});

export default Module;
