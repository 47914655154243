const USER_TABLE_STRINGS = {
    label: {
        role: "role",
        permission: "permission",
        userId: "User Id"
    },
    button: {
        view: "View",
        edit: "Edit",
        delete: "Delete"
    }
};

const userColumnKey = {
    name: "users",
    rolesPermissions: "rolesPersmissions",
    actions: "actions",
    originType: "originType"
};

const userTableColumns = [
    {
        Header: "Users",
        accessor: userColumnKey.name
    },
    {
        Header: "Roles and Permissions",
        accessor: userColumnKey.rolesPermissions,
        minWidth: 600
    },
    {
        Header: "Created By",
        accessor: userColumnKey.originType,
        width: 90
    },
    {
        Header: "",
        accessor: userColumnKey.actions,
        width: 160
    }
];

const ROLE_TABLE_STRINGS = {
    label: {
        name: "name",
        role: "role",
        permission: "permission"
    },
    button: {
        view: "View",
        edit: "Edit",
        delete: "Delete"
    }
};

const roleColumnKey = {
    role: "role",
    permissions: "permissions",
    actions: "actions"
};

const roleTableColumns = [
    {
        Header: "Roles",
        accessor: roleColumnKey.role
    },
    {
        Header: "Permissions",
        accessor: roleColumnKey.permissions,
        minWidth: 600
    },
    {
        Header: "",
        accessor: roleColumnKey.actions,
        width: 90
    }
];

export { USER_TABLE_STRINGS, userColumnKey, userTableColumns, ROLE_TABLE_STRINGS, roleColumnKey, roleTableColumns };
