import prefillProperties from "app/components/flow/designer/nodeProcessing/prefillProperties";
import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import metaObjects from "core/services/metaStoreService/meta-objects";
import utils from "core/utils";
import api from "core/api/api";
import { COSMOSDB_CONFIG } from "./config";

export async function saveTarget(componentName, values, appName, inputStream, id) {
    let shortAppName = utils.getName(appName);
    let nsName = utils.getNamespace(appName);
    let targetModel = createTargetModel(componentName);
    targetModel.set("inputStream", inputStream);
    targetModel.set("nsName", nsName);
    targetModel.set("name", values.targetName);
    populateRequiredProperties(targetModel, values, id);
    await targetModel.save({ flow: shortAppName });
    await api.compile(appName);
}
function createTargetModel(componentName) {
    let targetAdapterProps = {};
    targetAdapterProps = prefillProperties("TARGET", componentName, PredefinedComponentNames, targetAdapterProps);
    const propertyTemplateID = "Global.PROPERTYTEMPLATE." + targetAdapterProps.adapter.handler;
    targetAdapterProps.adapter.handler = propertyTemplateID;
    return new metaObjects.TARGET.Model(targetAdapterProps);
}

function populateRequiredProperties(targetModel, values, id) {
    let getTableMappingString = function() {
        let tableMappings = [];
        values.dbMapping.forEach(value => {
            if (id === COSMOSDB_CONFIG.cassandra.id) {
                tableMappings.push(value.sourceTable + "," + value.targetTable + "");
            } else {
                tableMappings.push(value.sourceDatabase + "," + value.targetDatabase + "");
            }
        });
        return tableMappings.join(";");
    };

    switch (targetModel.adapter.handler) {
        case "Global.PROPERTYTEMPLATE.CosmosDBWriter":
            targetModel.get("adapter").properties = {
                AccessKey: values.accessKey,
                ServiceEndpoint: `${values.serviceEndpoint}:${values.port}`,
                collections: getTableMappingString()
            };
            break;
        case "Global.PROPERTYTEMPLATE.CassandraCosmosDBWriter":
            targetModel.get("adapter").properties = {
                AccountKey: values.accountKey,
                AccountEndPoint: values.accountEndpoint,
                Tables: getTableMappingString(),
                Port: values.port,
                KeySpace: values.keySpace
            };
            break;
        case "Global.PROPERTYTEMPLATE.MongoCosmosDBWriter":
            targetModel.get("adapter").properties = {
                ConnectionURL: `${values.host}:${values.port}`,
                collections: getTableMappingString(),
                Username: values.username,
                Password: values.password
            };
            break;
    }
}
