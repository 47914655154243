import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import template from "./templates/infopanel.html";
import "highcharts_gauge";
import { tooltipStriimlineHighcharts } from "../../../../styles/materialize/components-striimline/tooltip.export";

var InfoPanel = {};

InfoPanel.View = Backbone.Marionette.ItemView.extend({
    template: _.template(template),
    className: "panels",

    modelEvents: {
        change: "render"
    },

    initialize: function() {},

    onRender: function() {
        //Memory chart
        var memoryRate = this.model.memoryTotalInt
            ? parseInt((this.model.memoryUsedInt / this.model.memoryTotalInt) * 100, 10)
            : 0;

        this.colorCode = this.colorCodeBasedOnUtilization(memoryRate);

        var gaugeOptions = {
            chart: {
                backgroundColor: "rgba(255, 255, 255, 0.0)",
                type: "solidgauge",
                plotBorderColor: "red"
            },
            title: "",
            credits: {
                enabled: false
            },
            pane: {
                center: ["50%", "50%"],
                size: "100%",
                startAngle: 180,
                endAngle: 360,
                background: {
                    backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || "#EEE",
                    innerRadius: "90%",
                    outerRadius: "100%",
                    shape: "circle"
                }
            },
            tooltip: {
                enabled: false
            },
            // the value axis
            yAxis: {
                min: 0,
                max: 50,
                stops: [[0.1, this.colorCode]],
                lineWidth: 0,
                minorTickInterval: null,
                tickPixelInterval: 100,
                tickWidth: 0,
                labels: {
                    enabled: false
                }
            },
            plotOptions: {
                solidgauge: {
                    innerRadius: "90%",
                    dataLabels: {
                        y: 35,
                        x: 3,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            series: [
                {
                    data: [memoryRate],
                    dataLabels: {
                        format: '<p style="text-align:center; color:' + this.colorCode + '; font-size:18px;">{y}%</p>'
                    }
                }
            ]
        };
        //this.$el.find("#memory-monitor").highcharts(gaugeOptions);

        //CPU utilization chart
        var cpuRate = 0;
        if (this.options.model.cpuRate) {
            //cpuRate = parseFloat(this.options.model.cpuRate.slice(0, -1));
        }
        //this.colorCode = this.colorCodeBasedOnUtilization(cpuRate);
        gaugeOptions.series = [
            {
                data: [cpuRate],
                dataLabels: {
                    format:
                        '<p style="text-align:center; color:' +
                        this.colorCode +
                        '; font-size:18px;">' +
                        cpuRate +
                        "%</p>"
                }
            }
        ];
        gaugeOptions.yAxis.stops = [[0.1, this.colorCode]];
        //this.$el.find("#cpu-monitor").highcharts(gaugeOptions);

        //Apps chart
        var pieOptions = gaugeOptions;
        pieOptions.chart.animation = false;
        pieOptions.chart.margin = -13;

        pieOptions.tooltip = {
            pointFormat: "{series.name}: <b>{point.y:.0f}</b>",
            ...tooltipStriimlineHighcharts
        };
        pieOptions.plotOptions = {
            pie: {
                size: "150%",
                dataLabels: {
                    enabled: true,
                    distance: -23,
                    style: {
                        fontWeight: "bold",
                        color: "black",
                        "text-transform": "uppercase"
                    },
                    formatter: function() {
                        if (this.y !== 0) {
                            return this.key;
                        }
                    }
                },
                startAngle: -90,
                endAngle: 90,
                center: ["50%", "100%"]
            }
        };
        pieOptions.series = [
            {
                animation: false,
                type: "pie",
                name: "Apps",
                innerSize: "75%",
                data: [
                    {
                        name: "Running",
                        y: this.options.model.appsRunning,
                        color: "#0d874c"
                    },
                    {
                        name: "Not Running",
                        y: this.options.model.appsNotRunning,
                        color: "#00A7E5"
                    },
                    {
                        name: "Down",
                        y: this.options.model.appsDown,
                        color: "#DD3711"
                    }
                ]
            }
        ];

        if (this.model.get("appsTotal")) {
            $("#apps-groups").highcharts(pieOptions);
        } else {
            $("#apps-groups").html("No Apps to monitor");
        }
    },
    colorCodeBasedOnUtilization: function(percentage) {
        if (percentage < 60) {
            return "#0d874c";
        } else if (percentage >= 60 && percentage < 80) {
            return "#F4C244";
        } else if (percentage >= 80 && percentage <= 100) {
            return "#EA6F28";
        } else {
            return "#d45260";
        }
    }
});

export default InfoPanel;
