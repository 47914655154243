import _ from "underscore";

export default (function () {
    function Parser() {
        this.parse = function (queryString) {
            if (!queryString) {
                return undefined;
            }
            if (queryString.indexOf("=") === -1) {
                return undefined;
            }

            var decoded = decodeURIComponent(queryString);

            return _.chain(decoded.split("&"))
                .map(function (params) {
                    var p = params.split("=");
                    return [p[0], decodeURIComponent(p[1])];
                })
                .object()
                .value();
        };
    }
    return Parser;
})();
