import React, { SetStateAction, useMemo, useState } from "react";
import {
    StriimInputField,
    StriimTypography,
    StriimCheckbox,
    StriimControlLabel,
    StriimDropdown,
    StriimTooltip
} from "@striim/striim-ui";
import { Box, Grid, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import { SpecialChevronDown } from "../../../../../../generic/icon/customIcons";
import { styles } from "./sentinel-apps-dropdown.styles";
import { pluralize } from "../../../../reports/components/report-components";

type MenuProps = {
    title: string;
    searchText: string;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    filteredApps: Array<any>;
    selectedApps: Array<any>;
    setSelectedApps: React.Dispatch<React.SetStateAction<any>>;
    appSentinelMapping: Array<any>;
};

const MenuContent = ({
    title,
    searchText,
    setSearchText,
    filteredApps,
    selectedApps,
    setSelectedApps,
    appSentinelMapping
}: MenuProps) => {
    const AppControlLabel = ({ value }) => {
        const isSelected = useMemo(() => selectedApps.includes(value), [selectedApps]);

        const handleSelect = val => {
            if (isSelected) setSelectedApps(apps => apps.filter(app => app !== val));
            else setSelectedApps(apps => [...apps, val]);
        };

        return (
            <Grid container px={2} py={1} justifyContent={"space-between"} alignItems={"center"}>
                <StriimControlLabel
                    control={<StriimCheckbox checked={isSelected} onClick={() => handleSelect(value)} id={value} />}
                    label={
                        <StriimTooltip title={value} placement="top">
                            <StriimTypography variant={"subtitle2"} maxWidth={"150px"} sx={styles.controlLabel}>
                                {value}
                            </StriimTypography>
                        </StriimTooltip>
                    }
                />
                <StriimTypography variant="caption3" color="greyscale.700">
                    {`${pluralize("Sentinel", appSentinelMapping[value]?.length || 0)}`}
                </StriimTypography>
            </Grid>
        );
    };

    return (
        <div style={{ width: "320px" }}>
            <Box p={2} sx={styles.divider}>
                <StriimTooltip title={title} placement="top">
                    <StriimTypography variant={"body4"} display={"block"} maxWidth={"260px"} sx={styles.controlLabel}>
                        {title}
                    </StriimTypography>
                </StriimTooltip>
            </Box>
            <Box p={1} sx={styles.divider}>
                <StriimInputField
                    placeholder="Search"
                    InputProps={{
                        id: "search-apps",
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search opacity={0.45} />
                            </InputAdornment>
                        )
                    }}
                    value={searchText}
                    onChange={e => setSearchText(e)}
                />
            </Box>
            <Grid container flexDirection="column">
                {filteredApps.map((app, index) => (
                    <AppControlLabel key={index} value={app} />
                ))}
            </Grid>
        </div>
    );
};

type SentinelAppsDropdownProps = {
    apps: Array<string>;
    sentinelsCount: number;
    handleAppsSelect?: (apps: Array<string>) => any;
    selectedApps: string[];
    setSelectedApps: React.Dispatch<SetStateAction<string[]>>;
    appSentinelMapping: Array<any>;
};

const SentinelAppsDropdown = ({
    apps,
    sentinelsCount,
    handleAppsSelect,
    selectedApps,
    setSelectedApps,
    appSentinelMapping
}: SentinelAppsDropdownProps) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const selectedSentinelsCount = selectedApps?.reduce(
        (total, app) => total + (appSentinelMapping[app]?.length || 0),
        0
    );

    const title =
        apps.length === selectedApps.length
            ? `All Running Apps (${pluralize("App", apps.length)}, ${pluralize("Sentinel", selectedSentinelsCount)})`
            : selectedApps.length === 1
            ? `${selectedApps[0]} (${pluralize("Sentinel", appSentinelMapping[selectedApps[0]]?.length || 0)})`
            : `${pluralize("App", selectedApps.length)}, ${pluralize("Sentinel", selectedSentinelsCount)}`;

    const [searchText, setSearchText] = useState("");

    const filteredApps = useMemo(() => {
        if (!searchText) return apps;
        return apps.filter(app => app.toLowerCase().includes(searchText.toLowerCase()));
    }, [searchText]);

    return (
        <div onClick={() => setOpenDropdown(true)}>
            <StriimDropdown
                extendOnClick
                content={
                    <MenuContent
                        searchText={searchText}
                        setSearchText={setSearchText}
                        filteredApps={filteredApps}
                        title={title}
                        selectedApps={selectedApps}
                        setSelectedApps={setSelectedApps}
                        appSentinelMapping={appSentinelMapping}
                    />
                }
                onClose={() => {
                    if (openDropdown) {
                        handleAppsSelect && handleAppsSelect(selectedApps);
                        setOpenDropdown(false);
                    }
                }}
                placement="bottom"
            >
                <Box width={"320px"} p={1} sx={styles.containerStyles}>
                    <StriimTooltip title={title} placement="top">
                        <StriimTypography variant={"body4"} maxWidth={"260px"} sx={styles.controlLabel}>
                            {title}
                        </StriimTypography>
                    </StriimTooltip>
                    <SpecialChevronDown style={styles.iconStyles} />
                </Box>
            </StriimDropdown>
        </div>
    );
};

export default SentinelAppsDropdown;
