const styles = {
    contentContainer: {
        px: 3,
        py: 3,
        mt: 1,
        background: "white",
        borderRadius: 2,
        border: "1px solid",
        borderColor: "greyscale.200"
    },
    backButton: {
        width: "30px",
        height: "30px",
        padding: 1,
        mr: 2
    }
};

export default styles;
