//TODO: this file should be cleaned up, why do we still need to encode \n or \t
// or why do we escape \\ to \\\\ but not vice versa (this causes UNC network shares to fail (i.e. \\my-network-share\folder

const textAreaControls = ["template", "tables", "augumentqueryclause", "sobjects"];

const EXCLUDE_ESCAPING_PROPERTIES = ["FindAndReplaceDDL"];

//@TODO - Replace after moving to Node 16
function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
}

function unescapeApostropheCharacter(text) {
    text = text.replace(/'/g, "\\'");
    return text;
}
// when reading
function unescapeSpecialCharacters(text, propertyName) {
    // if(text.indexOf("\\\\") !== -1){
    //     console.log("un-escaped: "+text)
    //     text = text.replace("\\\\", "\\");
    // }
    //
    // text = text.replace("\\t", String.fromCharCode(9));
    // text = text.replace("\\n", String.fromCharCode(10));ˀ˳
    // text = text.replace("\\r", String.fromCharCode(13));
    // text = text.replace("\\u0001", String.fromCharCode(1));

    text = replaceAll(text, String.fromCharCode(9), "\\t");
    if (textAreaControls.indexOf(propertyName.toLowerCase()) === -1)
        text = replaceAll(text, String.fromCharCode(10), "\\n");
    text = replaceAll(text, String.fromCharCode(13), "\\r");

    // if(textAreaControls.indexOf(propertyName.toLowerCase()) !== -1)
    // {
    //
    // }//
    // text = text.replace(tempHash, "\\");
    return text;
}

// When Saving...
function escapeSpecialCharacters(text) {
    text = replaceAll(text, String.raw`\\t`, String.fromCharCode(9));
    text = replaceAll(text, String.raw`\\n`, String.fromCharCode(10));
    text = replaceAll(text, String.raw`\\r`, String.fromCharCode(13));

    if (text.indexOf("\\") !== -1) {
        text = text.replace("\\", "\\\\");
    }

    return text;
}

function escapeObjectProperties(object) {
    if (object && object.properties) {
        var properties = object.properties;

        Object.keys(properties).forEach(function(propertyName) {
            var propertyValue = properties[propertyName];
            if (typeof propertyValue !== "string" || EXCLUDE_ESCAPING_PROPERTIES.includes(propertyName)) {
                return;
            }
            properties[propertyName] = escapeSpecialCharacters(propertyValue);
        });
    }
}

function unescapeObjectProperties(object) {
    if (object) {
        var properties = object.properties;
        Object.keys(properties).forEach(function(propertyName) {
            var propertyValue = properties[propertyName];
            if (typeof propertyValue !== "string" || EXCLUDE_ESCAPING_PROPERTIES.includes(propertyName)) {
                return;
            }
            properties[propertyName] = unescapeSpecialCharacters(propertyValue, propertyName);
        });
    }
}

export default {
    // when saving..
    escapeMetaObject: function(metaObject) {
        if (!metaObject) {
            return;
        }
        //
        escapeObjectProperties(metaObject.adapter);
        escapeObjectProperties(metaObject.parser);
        escapeObjectProperties(metaObject.formatter);
    },
    unescapeMetaObject: function(metaObject) {
        if (!metaObject) {
            return;
        }
        unescapeObjectProperties(metaObject.adapter, metaObject.type);
        unescapeObjectProperties(metaObject.parser, metaObject.type);
        unescapeObjectProperties(metaObject.formatter, metaObject.type);
    }
};
