/**
 * This component adds sidebar to Marionette.LayoutView
 *
 * Usage:
 *
 * 1) Create instance of view which will be rendered inside the sidebar.
 *      Example:
 *         var eventsLogView = new EventsLogView({
 *              model: eventsLogModel
 *         });
 *
 * 2) Create Sidebar.View.
 *      Example:
 *          this.eventLogSidebar = new Sidebar.View({
 *              contentView: eventsLogView,
 *              parentView: this
 *          });
 *
 * 3) Use methods to control sidebar behaviour.
 *      Example:
 *          this.eventLogSidebar.toggle();
 *          this.eventLogSidebar.close();
 *          this.eventLogSidebar.currentView.open();
 *
 *****************************
 * OPTIONAL: Sidebar Manager - controls behaviour of multiple sidebars on the same page
 *
 * 1) Create multiple sidebar views as described above (points 3).
 *      Example:
 *          this.eventLogSidebar = this.eventLogSidebar = new Sidebar.View({...
 *          this.eventLogSidebar2 = this.eventLogSidebar = new Sidebar.View({...
 *
 * 2) Create instance of Sidebar.Manager.
 *      Example:
 *          _this.sidebarManager = new Sidebar.Manager();
 *
 * 3) Add sidebar views to the manager.
 *      Example:
 *          _this.sidebarManager.AddSidebar(this.eventLogSidebar);
 *          _this.sidebarManager.AddSidebar(this.eventLogSidebar2);
 *
 * 4) Control sidebars behaviour through sidebarManager methods.
 *      Example:
 *          this.sidebarManager.ToggleSidebar(this.eventLogSidebar.id);
 *          this.sidebarManager.closeSidebar(this.eventLogSidebar.id);
 *          this.sidebarManager.openSidebar(this.eventLogSidebar.id);
 *
 *          this.sidebarManager.ToggleSidebar(this.eventLogSidebar.id);
 *          this.sidebarManager.closeSidebar(this.eventLogSidebar.id);
 *          this.sidebarManager.openSidebar(this.eventLogSidebar.id);
 */

import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import "lib/vendor/jquery.sidebar.min";

var Sidebar = {};

/**
 * Sidebar sides options.
 */
Sidebar.Sides = {
    left: "left",
    right: "right",
    top: "top",
    bottom: "bottom",
};

Sidebar.Manager = function () {
    var sidebarWidth = 375;
    var sidebarHeight = 999;

    var manager = {};
    var sidebars = [];

    /**
     * adds sidebar to the Sidebar.Manager
     * attaches close and resize handlers from Sidebar.View
     *
     * @param sidebar Sidebar.View instance
     */
    manager.addSidebar = function (sidebar) {
        sidebar.$el.on({
            "manager:sidebar:closed": function () {
                manager.closeSidebar(sidebar.id);
            },
        });

        sidebar.$el.on({
            "manager:sidebar:toggleFullWidth": function () {
                manager.handleFullWidth(sidebar.id);
            },
        });

        sidebars.push(sidebar);
    };

    /**
     * removes sidebar from the Sidebar.Manager
     *
     * @param sidebar Sidebar.View instance
     */
    manager.removeSidebar = function (id) {
        var sidebarView = _.findWhere(sidebars, {
            id: id,
        });
        if (sidebarView) {
            sidebars = _.without(sidebars, sidebarView);
        }
    };

    /**
     * Opens sidebar.
     *
     * @param id - id of the sidebar
     */
    manager.openSidebar = function (id) {
        var sidebarView = _.findWhere(sidebars, {
            id: id,
        });
        if (!sidebarView) {
            return;
        }

        var otherSidebars = filteredSidebars(sidebars, id, true, sidebarView.side);
        if (otherSidebars.length < 1) {
            sidebarView.open();
            return;
        }

        sidebarView.open(getAnimateData(otherSidebars.length, sidebarView.side));
    };

    /**
     * Closes sidebar. Aligns other sidebars opened on the same side.
     *
     * @param id - id of the sidebar
     */
    manager.closeSidebar = function (id) {
        var _this = this;

        var sidebarView = _.findWhere(sidebars, {
            id: id,
        });
        if (!sidebarView) {
            return;
        }

        if (sidebarView.isOpened) {
            sidebarView.close();
        }

        var otherSidebars = filteredSidebars(sidebars, id, true, sidebarView.side);
        if (otherSidebars.length < 1) {
            return;
        }

        _.each(otherSidebars, function (item) {
            item.close();
        });

        _.each(otherSidebars, function (item) {
            _this.openSidebar(item.id);
        });
    };

    /**
     * Toggles sidebar.
     *
     * @param id - id of the sidebar
     */
    manager.toggleSidebar = function (id) {
        var sidebarView = _.findWhere(sidebars, {
            id: id,
        });
        if (!sidebarView) {
            return;
        }

        if (sidebarView.isOpened) {
            this.closeSidebar(id);
        } else {
            this.openSidebar(id);
        }
    };

    /**
     * Closes all opened sidebars except the one passed as argument.
     *
     * @param id - id of the sidebar
     */
    manager.handleFullWidth = function (id) {
        var sidebarView = _.findWhere(sidebars, {
            id: id,
        });
        if (!sidebarView) {
            return;
        }

        //Close other sidebars
        var otherSidebars = filteredSidebars(sidebars, id);

        _.each(otherSidebars, function (item) {
            item.close();
        });
    };

    /**
     * Generates js object with appropriate sidebar position
     *
     * @param index - index of the sidebar on the specific side
     * @param side - Sidebar.Sides option
     */
    function getAnimateData(index, side) {
        switch (side) {
            case Sidebar.Sides.left:
                return {
                    left: sidebarWidth * index + "px",
                };
            case Sidebar.Sides.right:
                return {
                    right: sidebarWidth * index + "px",
                };
            case Sidebar.Sides.top:
                return {
                    top: sidebarHeight * index + "px",
                };
            case Sidebar.Sides.bottom:
                return {
                    bottom: sidebarHeight * index + "px",
                };
        }
    }

    /**
     * Returns filtered list of sidebar views
     *
     * @param array     - array of sidebar views
     * @param id        - id of sidebar which should be excluded from the result
     * @param opened    - boolean to determine if is should return only opened or closed elements
     * @param side      - Sidebar.Sides option to determine if is should return only sidebars from specific side
     */
    function filteredSidebars(array, id, opened, side) {
        var result = [];
        _.each(array, function (item) {
            if (
                (item.isOpened === opened || opened === undefined) &&
                (item.side === side || side === undefined) &&
                item.id !== id
            ) {
                result.push(item);
            }
        });

        return result;
    }

    return manager;
};

Sidebar.View = Backbone.Marionette.LayoutView.extend({
    template: _.template("<div class='sidebar-content'></div>"),
    className: "common-sidebar normal-width hidden",

    regions: {
        sidebarContentRegion: ".sidebar-content",
    },

    /**
     *  Initializes sidebar. Adds sidebar element to markup programmatically.
     *
     *  @param options
     *              contentView   - view to be rendered inside the sidebar
     *              side          - Sidebar.Sides option
     *              parent        - parent view for sidebar
     */
    initialize: function (options) {
        this.id = _.uniqueId("sidebar_");
        this.side = options.side || Sidebar.Sides.right;
        this.isOpened = false;
        this.contentView = options.contentView;
        this.customCssClass = options.customCssClass;

        var parent = options.parentView;
        parent.$el.append("<div class='" + this.id + "'></div>");
        parent.addRegion(this.id, "." + this.id);
        parent[this.id].show(this);
    },

    /**
     *  Adds class with selected side. Renders contentView inside sidebar. Adds handlers for events from contentView.
     *
     */
    onRender: function () {
        if (this.customCssClass) {
            this.$el.addClass(this.customCssClass);
        }

        this.$el.addClass(this.side);
        this.$el.sidebar({
            side: this.side,
        });
        this.sidebarContentRegion.show(this.contentView);

        var _this = this;
        this.listenTo(this.contentView, "sidebar:close", function () {
            _this.close();
        });

        this.listenTo(this.contentView, "sidebar:toggleFullWidth", function () {
            _this.$el.toggleClass("full-width");
            _this.$el.toggleClass("normal-width");
            _this.$el.trigger("manager:sidebar:toggleFullWidth");
        });
    },

    /**
     *  Opens sidebar
     *
     * @param data - result from getAnimateData() method
     */
    open: function (data) {
        var _this = this;
        if (this.$el.hasClass("hidden")) {
            _this.$el.removeClass("hidden");
        }

        this.$el.trigger("sidebar:open", data);
        this.isOpened = true;
        this.contentView.setSidebarActive(true);
    },

    /**
     *  Closes sidebar
     *
     * @param data - result from getAnimateData() method
     */
    close: function (data) {
        this.$el.trigger("sidebar:close", data);
        this.isOpened = false;

        this.$el.trigger("manager:sidebar:closed");
        this.contentView.setSidebarActive(false);
    },

    /**
     *  Toggles sidebar
     *
     * @param data - result from getAnimateData() method
     */
    toggle: function (data) {
        var _this = this;
        if (this.$el.hasClass("hidden")) {
            _this.$el.removeClass("hidden");
        }

        this.$el.trigger("sidebar:toggle", data);
        this.isOpened = !this.isOpened;

        this.contentView.setSidebarActive(this.isOpened);
    },
});

export default Sidebar;
