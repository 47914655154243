import React from "react";
import ReactDOM from "react-dom/client";
import _ from "underscore";
import Backbone from "backbone";
import { StriimTheme } from "@striim/striim-ui";

import App from "app";
import DiscoverPIIPanel from "../../../../../src/modules/ai-insights/discover-pii-panel/discover-pii-panel.tsx";
import metaStoreService from "../../../../../core/services/metaStoreService/meta-store-service.js";
import { isUserAdmin } from "../../../../../core/user-management/update.control.js";
import sentinelService from "../../../../../src/modules/ai-insights/sentinel/sentinel-service.ts";

const DiscoverPIISidebarView = Backbone.Marionette.LayoutView.extend({
    template: _.template(`<div>   </div>`),

    getViewValue: function() {},

    setViewValue: function() {},

    initialize: async function(options) {
        this.value = options;
    },

    onShow: async function() {
        try {
            if (!this.root) {
                this.root = ReactDOM.createRoot(this.$el[0]);
            }
            const sources = await this.model.app.getApplicationComponentsByType(metaStoreService.entities.SOURCE);
            const isAdmin = isUserAdmin();
            const flowStatus = this.model.app.get("flowStatus");

            this.root.render(
                <StriimTheme preselector=".striim.materialize.light:not(#\20)">
                    <DiscoverPIIPanel
                        defaultJobSelected={this.value?.jobName}
                        namespace={App.user.defaultNamespace}
                        appName={this.model.app.name}
                        appFQN={this.model.app.nsName + "." + this.model.app.name}
                        sources={sources?.models}
                        nodes={this.model.nodes}
                        flowStatus={flowStatus}
                        onCancel={() => {
                            this.trigger("discover-pii-cancel");
                        }}
                        toggleFullScreen={() => {
                            this.trigger("discover-pii-toggle");
                        }}
                        isAdmin={isAdmin}
                        addSentinel={async (source, outputStreamNode) => {
                            const entityData = await sentinelService.transformEntities(source.entities);
                            this.trigger("new-sentinel-selected", {
                                outputStreamNode: outputStreamNode,
                                entityDataFromSource: entityData
                            });
                        }}
                    />
                </StriimTheme>
            );
        } catch (e) {
            console.error("Error rendering", e);
        }
    },

    onBeforeDestroy() {
        if (this.root) {
            this.root.unmount();
        }
    }
});

export default DiscoverPIISidebarView;
