import React, { SetStateAction, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { flushSync } from "react-dom";
import { Box, Divider, Grid } from "@mui/material";
import { StriimTypography, StriimCheckbox, StriimTheme, StriimButton } from "@striim/striim-ui";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts/react/highstock";

import { formatTimestampForTooltip, getTwoPointPrecision } from "./utils";
import {
    commonOptions,
    CustomLegend,
    CustomPlotbandTooltip,
    DataLegend,
    eventsChartColors,
    reportOptions
} from "./constants";
import { ArrowForwardBlue } from "../../../../../generic/icon/customIcons";
import {
    AllSentinelsSDIDrillDownEventsChartData,
    EventsProcessedChartData,
    LegendData
} from "../../../guardian/sentinel-monitor/service/sentinel-monitor.types";

const TaggedDataLegend = ({ width }) => {
    return (
        <Box
            width={width}
            height={4}
            borderRadius={2}
            sx={{ backgroundColor: eventsChartColors.sensitiveTaggedLineColor }}
        />
    );
};

const CustomTooltip = ({ points, showTaggedData = false, chart, dataUnavailableTimestamp }) => {
    const xValue = formatTimestampForTooltip(points?.[0].x);
    const sensitiveValue = points?.[1].y;
    const sensitivePercentage = getTwoPointPrecision(points?.[1].percentage);
    const nonSensitiveValue = points?.[0].y;
    const nonSensitivePercentage = getTwoPointPrecision(points?.[0].percentage);
    const taggedValue = points?.[2]?.y;

    if (dataUnavailableTimestamp && dataUnavailableTimestamp < points?.[0].x) {
        chart.tooltip.label?.element ? (chart.tooltip.label.element.style.display = "none") : "";
        return;
    } else {
        chart.tooltip.label?.element ? (chart.tooltip.label.element.style.display = "block") : "";
    }

    return (
        <Grid container minWidth={230} mt={2.5}>
            <StriimTypography variant="caption4" color="greyscale.700" fontFamily={"Inter"} p={1}>
                {xValue}
            </StriimTypography>
            <Divider
                variant="fullWidth"
                sx={{ width: "100%", borderColor: "greyscale.200", width: "calc(100% + 16px)", marginLeft: -1 }}
            />
            <Grid container p={1}>
                <Grid display="flex" width={"100%"} item justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item display="flex" alignItems={"center"} gap={0.5}>
                        <CustomLegend color={eventsChartColors.sensitiveBarColor} />
                        <StriimTypography variant="caption3" color="greyscale.700" fontFamily={"Inter"}>
                            W/ Sensitive Data
                        </StriimTypography>
                    </Grid>
                    <StriimTypography variant="caption3" color="primary.700" fontFamily={"Inter"}>
                        {sensitiveValue}
                        {sensitivePercentage ? ` (${sensitivePercentage}%)` : ""}
                    </StriimTypography>
                </Grid>
                <Grid display="flex" width={"100%"} item justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item display="flex" alignItems={"center"} gap={0.5}>
                        <CustomLegend color={eventsChartColors.nonSensitiveBarColor} />
                        <StriimTypography variant="caption3" color="greyscale.700" fontFamily={"Inter"}>
                            W/O Sensitive Data
                        </StriimTypography>
                    </Grid>
                    <StriimTypography variant="caption3" color="primary.700" fontFamily={"Inter"}>
                        {nonSensitiveValue}
                        {nonSensitivePercentage ? ` (${nonSensitivePercentage}%)` : ""}
                    </StriimTypography>
                </Grid>
                {showTaggedData && (
                    <Grid display="flex" width={"100%"} item justifyContent={"space-between"}>
                        <Grid item display="flex" alignItems={"center"} gap={0.5}>
                            <TaggedDataLegend width={8} />
                            <StriimTypography variant="caption3" color="greyscale.700" fontFamily={"Inter"}>
                                Tagged
                            </StriimTypography>
                        </Grid>
                        <StriimTypography variant="caption3" color="primary.700" fontFamily={"Inter"}>
                            {taggedValue}
                        </StriimTypography>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

interface EventsProcessedTimelineChartProps {
    chartRef: object;
    chartData: EventsProcessedChartData | AllSentinelsSDIDrillDownEventsChartData | null;
    onScroll: Function;
    hideNavigator?: boolean;
    sensitiveDataTaggedPercentage: number;
    selectedDateRange: { from: number; to: number } | null;
    isDiscoveryDisabled?: boolean;
    hasAppStopped?: boolean;
    dataUnavailableTimestamp?: number | null;
    showTaggedData: boolean;
    setShowTaggedData: React.Dispatch<SetStateAction<boolean>>;
    isTaggedDataOptionAvailable: boolean;
    legendData: LegendData;
    onLiveViewClick: Function;
    isReport?: boolean;
}

const EventsProcessedTimelineChart: React.FC<EventsProcessedTimelineChartProps> = React.memo(
    ({
        chartRef,
        chartData,
        onScroll,
        hideNavigator = false,
        sensitiveDataTaggedPercentage,
        selectedDateRange,
        isDiscoveryDisabled = false,
        hasAppStopped = false,
        dataUnavailableTimestamp,
        showTaggedData,
        setShowTaggedData,
        isTaggedDataOptionAvailable = true,
        legendData,
        onLiveViewClick,
        isReport = false
    }) => {
        const [chartOptions, setChartOptions] = useState(null);
        const [plotBand, setPlotBand] = useState(null);

        useEffect(() => {
            const options = {
                chart: isReport ? reportOptions.chart : commonOptions.chart,
                credits: commonOptions.credits,
                series: [
                    {
                        name: "W/O Sensitive Data",
                        color: eventsChartColors.nonSensitiveBarColor,
                        type: "column",
                        data: chartData.nonSensitiveData
                    },
                    {
                        name: "W/ Sensitive Data",
                        data: chartData.sensitiveData,
                        type: "column",
                        color: eventsChartColors.sensitiveBarColor
                    },
                    {
                        name: "Sensitive data tagged",
                        data: chartData.taggedSensitiveData,
                        type: "spline",
                        color: eventsChartColors.sensitiveTaggedLineColor,
                        lineWidth: 2,
                        visible: showTaggedData
                    }
                ],
                plotOptions: isReport ? reportOptions.plotOptions : commonOptions.plotOptions,
                xAxis: {
                    ...commonOptions.xAxis,
                    events: {
                        setExtremes: function(event) {
                            const dateRange = event.max !== this.dataMax ? { from: event.min, to: event.max } : null;
                            onScroll(event, dateRange);
                        }
                    },
                    range: hideNavigator ? 3600 * 24 * 1000 : 3600 * 1000 //hideNavigator = true -> 24 hour chart, 3600 = 1hr - all in milliseconds
                },
                yAxis: commonOptions.yAxis,
                scrollbar: { enabled: !isReport && !hideNavigator },
                navigator: {
                    ...commonOptions.navigator,
                    enabled: !isReport && !hideNavigator,
                    fillOpacity: 0,
                    series: {
                        data: chartData.sensitiveData,
                        lineColor: eventsChartColors.sensitiveBarColor
                    }
                },
                rangeSelector: {
                    ...commonOptions.rangeSelector,
                    enabled: !hideNavigator
                },
                tooltip: {
                    ...commonOptions.tooltip,
                    formatter: function() {
                        const chart = chartRef.current.chart;
                        const tooltipDiv = document.createElement("div");
                        const root = ReactDOM.createRoot(tooltipDiv);
                        flushSync(() => {
                            root.render(
                                <StriimTheme preselector=".striim.materialize.light:not(#\20)">
                                    <CustomTooltip
                                        points={this.points}
                                        showTaggedData={showTaggedData}
                                        chart={chart}
                                        dataUnavailableTimestamp={dataUnavailableTimestamp}
                                    />
                                </StriimTheme>
                            );
                        });
                        return tooltipDiv.innerHTML;
                    }
                }
            };
            setChartOptions(options);
        }, [showTaggedData, chartData, hideNavigator, dataUnavailableTimestamp]);

        useEffect(() => {
            if (chartRef?.current?.chart?.xAxis) {
                const chart = chartRef.current.chart;
                if (isDiscoveryDisabled || hasAppStopped) {
                    if (!plotBand?.id) {
                        const id = "custom-plot-band";
                        chart.xAxis[0].addPlotBand({
                            from: dataUnavailableTimestamp,
                            to: chartData.sensitiveData[chartData.sensitiveData.length - 1][0],
                            color: "#EBF2FA",
                            id: id,
                            events: {
                                mouseover: function(event) {
                                    const xValue = chart.xAxis[0].toValue(event.clientX);
                                    const plotbandEl = document.getElementById("custom-plot-band");
                                    if (plotbandEl) {
                                        plotbandEl.style.display = "block";
                                        setPlotBand(prev => ({ ...prev, x: xValue }));
                                    }
                                },
                                mouseout: function() {
                                    const plotbandEl = document.getElementById("custom-plot-band");
                                    if (plotbandEl) {
                                        plotbandEl.style.display = "none";
                                    }
                                }
                            }
                        });
                        setPlotBand(prev => ({ ...prev, id }));
                    }
                } else if (plotBand?.id) {
                    setPlotBand({ id: null });
                    setChartOptions(prevOptions => ({
                        ...prevOptions,
                        xAxis: {
                            ...prevOptions.xAxis,
                            plotBands: []
                        }
                    }));
                }
            }
        }, [isDiscoveryDisabled, hasAppStopped, plotBand, dataUnavailableTimestamp, chartData, chartRef?.current]);

        useEffect(() => {
            updateChart();
        }, [hideNavigator]);

        const updateChart = () => {
            const chart = chartRef?.current?.chart;
            if (!chart) return;
            chart.xAxis[0].setExtremes(null, null, false);
            chart.redraw();
        };

        const scrollToLatest = () => {
            const chart = chartRef.current.chart;
            const xAxis = chart.xAxis[0];
            xAxis.setExtremes(null, null, false);
        };

        return (
            <>
                <Grid container justifyContent={"space-between"}>
                    <Grid item display={"flex"} alignItems={"center"} gap={1}>
                        {isTaggedDataOptionAvailable && (
                            <>
                                <StriimCheckbox
                                    checked={showTaggedData}
                                    onChange={event => setShowTaggedData(event.target.checked)}
                                />
                                <TaggedDataLegend width={12} />
                                <StriimTypography variant="body4" color="greyscale.800">
                                    Show Events Tagged ({(sensitiveDataTaggedPercentage ?? 0).toFixed(0)}%)
                                </StriimTypography>
                            </>
                        )}
                    </Grid>
                    <Grid item display="flex">
                        <DataLegend data={legendData} isReport={isReport} />
                    </Grid>
                </Grid>

                <HighchartsReact
                    ref={chartRef}
                    highcharts={Highcharts}
                    constructorType={"stockChart"}
                    options={chartOptions}
                />
                {!!selectedDateRange && !hideNavigator && !isReport ? (
                    <div style={{ textAlign: "right" }}>
                        <StriimButton
                            onClick={() => {
                                onLiveViewClick();
                                scrollToLatest();
                            }}
                            variant="text"
                            endIcon={<ArrowForwardBlue />}
                        >
                            Live View
                        </StriimButton>
                    </div>
                ) : null}
                <div id="custom-plot-band" style={{ position: "absolute", top: -70, right: 0 }}>
                    {plotBand?.x ? (
                        <CustomPlotbandTooltip
                            x={plotBand?.x}
                            isDiscoveryDisabled={isDiscoveryDisabled}
                            hasAppStopped={hasAppStopped}
                        />
                    ) : null}
                </div>
            </>
        );
    }
);

export default EventsProcessedTimelineChart;
