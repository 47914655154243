import React, { useEffect } from "react";
import { useUserManagement } from "core/user-management/user-management-context";
import LoadingIndicator from "src/generic/loading-indicator";
import RoleTable from "./role-table/role-table";
import NoSearchResults from "../../common/no-search-results";

const RolesListPage = () => {
    const {
        userManagementProviderBusy,
        roles,
        getRoles,
        handleRevokeRoleByName,
        filter,
        filteredRoles
    } = useUserManagement();

    useEffect(() => {
        getRoles();
    }, []);

    return userManagementProviderBusy ? (
        <LoadingIndicator isGlobal={false} />
    ) : filter.isRoleFilterActive && filter.isResultEmpty ? (
        <NoSearchResults />
    ) : (
        <RoleTable roles={filter.isRoleFilterActive ? filteredRoles : roles} />
    );
};

export default RolesListPage;
