export const styles = {
    legendLabel: {
        color: ({ palette }) => palette.greyscale[700],
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0.24px'
    },
    legendValue: {
        color: ({ palette }) => palette.greyscale[900],
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0.24px',
        mr: 3
    },
    legendWrapper: {
        left: 0,
        top: 40,
    },
    barChartPosition: {
        left: 0,
        top: -16
    },
    bottomBorder: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    nonSensitive: {
        fill: ({ palette }) => palette.success[200],
    },
    customLegend: {
        "& .recharts-default-legend": {
            display: "flex",
            justifyContent: "space-between"
        }
    },
    insightsChartContainer: {
        "& .recharts-default-legend": {
            display: "flex",
            justifyContent: "space-between"
        },
    },
    tooltipWrapper: {
        borderRadius: "4px",
        border: "1px solid",
        borderColor: "greyscale.200",
        backgroundColor: "additional.white",
        boxShadow: "0px 6px 15px 0px rgba(72, 82, 92, 0.12)",
        padding: 1,
        paddingTop: 0.5,
        paddingBottom: 0.5
    },
    tooltipName: {
        fontSize: "10px",
        color: "greyscale.700"
    },
    tooltipValue: {
        fontSize: "10px",
        color: "primary.700",
        marginLeft: "11px",
        fontWeight: 500
    },
    tooltipSquare: {
        width: "7px",
        height: "7px",
        display: "inline-block",
        marginRight: 0.5,
        borderRadius: "2px"
    }
}
