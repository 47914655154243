import Backbone from "backbone";
import _ from "underscore";
import utils from "core/utils";
import UIControl from "app/components/common/editor/control/ui-control";
import BaseEditor from "app/components/common/editor/meta-object-editors/base";
import MetaObjectDataSource from "app/components/common/editor/control/select/metaobject-datasource";

var Module = function (metadata_object, options) {
    options = options || {};
    options.displayFields = options.displayFields || ["adapter", "from", "output", "enrichment"];
    options.subType = "Open Processor";

    BaseEditor.apply(this, arguments);
};

Module.prototype = _.extend({}, BaseEditor.prototype, {
    addCustomFields: function () {
        this.fieldMapper.adapter = UIControl.PropertyTemplate("process")
            .extend({
                subControl: true,
                hideLabel: true,
            })
            .create();

        var inputDataSource = new MetaObjectDataSource([utils.ENTITIES.STREAM, utils.ENTITIES.CACHE]);
        this.fieldMapper.from = UIControl.Select(inputDataSource, {
            //addNew: true//,
            //type: utils.ENTITIES.CACHE
        }).create();

        var outputDataSource = new MetaObjectDataSource([utils.ENTITIES.STREAM, utils.ENTITIES.WINDOW]);
        this.fieldMapper.output = UIControl.Select(outputDataSource, {
            //addNew: true,
            //type: utils.ENTITIES.STREAM
        }).create();

        var caches = new MetaObjectDataSource([utils.ENTITIES.CACHE]);
        this.fieldMapper.enrichment = UIControl.Select(caches, {
            addNew: false,
            multiple: true,
            plceholder: "Choose enrichment",
            type: utils.ENTITIES.CACHE,
        }).create();
    },
    postRender: function () {},
});

export default Module;
