import Backbone from "backbone";
import accessTimeIcon from "app/images/access-time.svg";
import categoryIcon from "app/images/category.svg";
import exceptionDetailsTemplate from "./exception-details.html";
import $ from "jquery";

const template = `
<div class="collapsed"><div>{{jsonSimplify}}</div><a class="action expand-action btn-flat waves-effect waves-blue bolder">Expand</a></div>
<div class="expanded hidden-element"><pre>{{jsonExpanded}}</pre><a class="action collapse-action btn-flat waves-effect waves-blue bolder">Collapse</a></div>
`;

const EventView = Backbone.Marionette.ItemView.extend({
    tagName: "div",

    className: "event-details",

    template: _.template(template),

    ui: {
        collapsedContainer: ".collapsed",
        expandedContainer: ".expanded",
        expandAction: ".expand-action",
        collapseAction: ".collapse-action"
    },

    events: {
        "click @ui.expandAction": "onExpand",
        "click @ui.collapseAction": "onCollapse"
    },

    _changeContainer: function(expand) {
        this.ui.collapsedContainer.toggleClass("hidden-element", expand);
        this.ui.expandedContainer.toggleClass("hidden-element", !expand);
    },

    onExpand: function() {
        this._changeContainer(true);
    },

    onCollapse: function() {
        this._changeContainer(false);
    }
});

const ExceptionView = Backbone.Marionette.CompositeView.extend({
    tagName: "div",

    className: "details",

    template: _.template(exceptionDetailsTemplate),

    childView: EventView,

    childViewContainer: ".events",

    ui: {
        topBarInfo: ".top-bar-info",
        accessTimeIcon: ".access-time",
        entityNameIcon: ".entity-name",
        events: ".events",
        singleEventContainer: ".single-event-container" // used when events contains only one item
    },

    initialize: function() {
        if (this.model.get("hasEvents")) {
            this.collection = new Backbone.Collection(this.model.get("relatedObjectsValue"));
        }
    },

    onRender: function() {
        this.ui.accessTimeIcon.html(accessTimeIcon);
        this.ui.entityNameIcon.html(categoryIcon);

        if (this.model.get("hasEvents")) {
            this.ui.events.removeClass("hidden-element");
        } else {
            this.ui.singleEventContainer.removeClass("hidden-element");
        }

        setTimeout(this._onResize.bind(this), 100);
        $(window).on("resize.exceptionDetails", this._onResize.bind(this));
    },

    onBeforeDestroy: function() {
        $(window).off("resize.exceptionDetails");
    },

    _onResize: function() {
        if (!this.ui.topBarInfo.height) {
            return;
        }

        const verticalPadding = 40;

        const sidebar = this.$el.closest(".sidebar.right-sidebar");
        const newHeight =
            sidebar.height() - //height of sidebar
            this.ui.topBarInfo.height() -
            verticalPadding;

        if (this.model.get("hasEvents")) {
            this.ui.events.css("height", newHeight + "px");
            if (!this._scrollbarInitialized) {
                this.ui.events.perfectScrollbar();
            }
        } else {
            this.ui.singleEventContainer.css("height", newHeight + "px");
            if (!this._scrollbarInitialized) {
                this.ui.singleEventContainer.perfectScrollbar();
            }
        }
    }
});

export default ExceptionView;
