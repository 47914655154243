import React, { useEffect, useRef } from "react";
import QueryStringParser from "../../../app/components/dashboard/url/query-string-parser";
import AppControl from "../../../app/components/common/app-control/app-control";
import appStatusSynchronizer from "../../../core/appStatusSynchronizer";
import MetaObjectConverter from "../../../core/services/metaStoreService/metaobject-converter";
import ConfigureAppView from "../../../app/components/appwizard/appwizard";
import App from "../../../app";
import utils from "../../../core/utils";
import { useParams, useLocation } from "react-router-dom";

const showTemplate = (app_name, template_id, component_id, params, step_name) => {
    try {
        var forceDeleteApplication = true;
        var origin = "";
        var predefinedName = "";
        if (params) {
            var queryStringParser = new QueryStringParser();
            var queryParams = queryStringParser.parse(params);
            if (queryParams) {
                if (queryParams.origin && typeof queryParams.origin === "string") {
                    forceDeleteApplication = false;
                    origin = queryParams.origin;
                }
                if (queryParams.name && typeof queryParams.name === "string") {
                    predefinedName = queryParams.name;
                }
            }
        }

        var appControlView = new AppControl({
            app: app_name
        });

        //TODO: FIX
        const onNavigateAway = () => {
            let fqn = MetaObjectConverter.convertShortNameToFullName(app_name, utils.ENTITIES.APPLICATION);
            let status = appStatusSynchronizer.getStatus(fqn);
            // if (status !== "CREATED") {
            //     if (evt && evt.target && evt.target.href) {
            //         window.location = evt.target.href;
            //         return;
            //     }
            // }
            if (forceDeleteApplication === false && origin) {
                App.navigate(origin, { trigger: true });
                return;
            }

            /**When user tries navigating away, confirm if they want to cancel the wizard and delete the created app **/
            //if there is no appName we can't do anything
            if (!app_name) {
                return true;
            }
            //confirm if user wants to delete the app

            appControlView.drop();
        };

        if (component_id === null) {
            component_id = 0;
        }
        component_id = parseInt(component_id, 10);
        var view = new ConfigureAppView({
            templateID: template_id,
            appName: app_name,
            componentID: component_id,
            stepName: step_name,
            appControlView: appControlView,
            params: params,
            forceDeleteApplication: forceDeleteApplication,
            origin: origin,
            predefinedName: predefinedName
        });
        App.content.show(view);
    } catch (e) {
        console.error(e);
    }
};

const AppWizards = () => {
    const { app_name, template_id, component_id, step_name } = useParams<{
        app_name: string;
        template_id: string;
        component_id: string;
        step_name: string;
    }>();
    let { search } = useLocation();
    const params = search ? search.slice(1) : null;

    useEffect(() => {
        showTemplate(app_name, template_id, component_id, params, step_name);
    }, [app_name, template_id, component_id, params, step_name]);
};
export default AppWizards;
