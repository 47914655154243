import React, { useState } from "react";
import { Grid, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { StriimInputField, StriimDataTable } from "@striim/striim-ui";
import { components } from "react-select";

import { styles } from "./connection-profile-list.styles";
import DropDownModal from "./components/table-menu";
import ConnectionProfilesListHeader from "../components/connection-profiles-list-header";
import WarningMessageBox from "../components/warning-message-box";
import { getFormattedEndpointName, getEndpointNameWithIcon } from "../utils";
interface ConnectionProfileListProps {
    connectionProfiles: ConnectionProfile[];
    refreshConnectionProfiles?: () => void;
}

const columns = [
    {
        headerName: "Profile Name",
        field: "name",
        flex: 1,
        suppressMovable: true,
        sortable: true,
        cellRenderer: ({ value }) => {
            return <Grid data-test-id={value}>{value}</Grid>;
        }
    },
    {
        headerName: "Endpoint Name",
        field: "endPoint",
        flex: 1,
        suppressMovable: true,
        sortable: true,
        comparator: (endpointA, endpointB) => {
            if (endpointA?.value === endpointB?.value) return 0;
            return endpointA?.value > endpointB.value ? 1 : -1;
        },
        cellRenderer: ({
            value: { value },
            data: { connectionProfile, refreshConnectionProfiles, setShowDeleteWarning, setCurrentCpName }
        }) => {
            return (
                <Grid
                    container
                    justifyContent="space-between"
                    data-test-id={connectionProfile.nsName + "." + connectionProfile.name + "-endpoint"}
                >
                    <Grid item display="flex" alignItems="center" gap={0.5}>
                        {getFormattedEndpointName(value)}
                    </Grid>
                    <Grid item data-test-id={connectionProfile.nsName + "." + connectionProfile.name + "-dropdown"}>
                        <DropDownModal
                            connectionProfile={connectionProfile}
                            setShowDeleteWarning={setShowDeleteWarning}
                            setCurrentCpName={setCurrentCpName}
                            refreshConnectionProfiles={refreshConnectionProfiles}
                        />
                    </Grid>
                </Grid>
            );
        }
    }
];

export const ConnectionProfileList = ({
    connectionProfiles,
    refreshConnectionProfiles
}: ConnectionProfileListProps) => {
    const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
    const [currentCpName, setCurrentCpName] = useState<string>("");
    const connectionProfileData = connectionProfiles.map(connectionProfile => ({
        id: connectionProfile.id,
        name: connectionProfile.nsName + "." + connectionProfile.name,
        connectionProfile,
        refreshConnectionProfiles,
        setShowDeleteWarning,
        setCurrentCpName,
        endPoint: { value: connectionProfile.endPoint, name: connectionProfile.name }
    }));
    let endpointsList = [...new Set(connectionProfiles.map(connectionProfile => connectionProfile.endPoint))].map(
        getEndpointNameWithIcon
    );
    endpointsList.unshift({ label: "All", value: "All" });

    const [searchParams, setSearchParams] = useState<string>("");
    const [endpointFilter, setEndpointFilter] = useState<string>("");

    const [tableData, setTableData] = useState(connectionProfileData);

    const onSearchFilterChange = string => {
        const searchQuery = string.toLowerCase();
        setSearchParams(searchQuery);
        const filteredData =
            endpointFilter !== undefined
                ? connectionProfileData.filter(data => {
                      return (
                          (data?.name?.toLowerCase()?.includes(searchQuery) ||
                              data?.endPoint?.value?.toLowerCase()?.includes(searchQuery)) &&
                          data?.endPoint?.value?.toLowerCase()?.includes(endpointFilter)
                      );
                  })
                : connectionProfileData.filter(data => {
                      return (
                          data?.name?.toLowerCase()?.includes(searchQuery) ||
                          data?.endPoint?.value?.toLowerCase()?.includes(searchQuery)
                      );
                  });

        setTableData(filteredData);
    };

    const onEndpointFilterChange = evt => {
        const selectedItem = evt?.value?.toLowerCase();
        setEndpointFilter(selectedItem);
        const filteredData =
            selectedItem === "all" || selectedItem === undefined
                ? connectionProfileData.filter(data => {
                      return (
                          data?.name?.toLowerCase()?.includes(searchParams) ||
                          data?.endPoint?.value?.toLowerCase()?.includes(searchParams)
                      );
                  })
                : connectionProfileData.filter(data => {
                      return (
                          data?.endPoint?.value?.toLowerCase()?.includes(selectedItem) &&
                          (data?.name?.toLowerCase()?.includes(searchParams) ||
                              data?.endPoint?.value?.toLowerCase()?.includes(searchParams))
                      );
                  });

        setTableData(filteredData);
    };

    return (
        <Grid display={"flex"} flexDirection={"column"} gap={2} sx={styles.container}>
            {showDeleteWarning && (
                <Grid item>
                    <WarningMessageBox
                        name={currentCpName}
                        handleDoneClick={() => {
                            setCurrentCpName("");
                            setShowDeleteWarning(false);
                        }}
                    />
                </Grid>
            )}
            <ConnectionProfilesListHeader showAddButton={true} />
            <Grid container gap={2} sx={styles.filterContainer}>
                <Grid item xs={5}>
                    <StriimInputField
                        name="search-profile-name"
                        onChange={onSearchFilterChange}
                        placeholder="Search by endpoints or profile names"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon opacity={0.45} />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <StriimInputField
                        id="data-test-id-endpoint-filter"
                        name="endpoint-filter"
                        select
                        placeholder="Endpoints: All"
                        onChange={onEndpointFilterChange}
                        SelectProps={{
                            options: endpointsList,
                            components: {
                                Option: componentProps => {
                                    return (
                                        <components.Option {...componentProps}>
                                            <Grid display={"flex"} alignItems={"center"} gap={1}>
                                                {componentProps.data.icon && (
                                                    <img src={componentProps.data.icon} style={styles.icon} />
                                                )}
                                                <div>{componentProps.data.label}</div>
                                            </Grid>
                                        </components.Option>
                                    );
                                }
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <Grid>
                <StriimDataTable data={tableData} columns={columns} />
            </Grid>
        </Grid>
    );
};

export default ConnectionProfileList;
