import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette, spacing }) => ({
    tabContentWrapper: {
        padding: "20px 25px",
        "& .MuiGrid-spacing-xs-3 > .MuiGrid-item": {
            padding: spacing(1.5)
        }
    },
    chartTitle: {
        fontSize: 16,
        fontWeight: 600,
        marginBottom: 30
    },
    chartWrapper: {
        height: 441,
        paddingRight: spacing(6)
    },
    unitsDetailedWrapper: {
        border: `1px solid ${palette.paper.border}`,
        borderRadius: spacing(2)
    },
    separator: {
        margin: spacing(3, -3),
        border: "none",
        borderTop: `1px solid ${palette.paper.border}`,
        height: 0
    }
}));
