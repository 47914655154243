import $ from "jquery";
import _ from "underscore";
import ColorConverter from "app/components/monitor/common/colorconverter";
import { tooltipStriimlineHighcharts } from "../../../styles/materialize/components-striimline/tooltip.export";

var color = "#bdc7cb"; // yAxis line
var bulletChartTextColor = "#2f393d";
var axisOptions = {
    gridLineColor: color,
    lineColor: color,
    tickColor: color,
    title: {
        text: ""
    },
    labels: {
        style: {
            color: "#2f393d" // yAxis text
        }
    }
};

var colorConverter = new ColorConverter();

export default (function() {
    return {
        getColoredTime: function(name, tooltip) {
            return $.extend(true, this.time, {
                plotOptions: {
                    series: {
                        color: colorConverter.convert(name)
                    }
                },
                tooltip: {
                    formatter: function() {
                        var value = tooltip || name;
                        return "<b>" + value + "</b><br/>" + this.y;
                    },
                    ...tooltipStriimlineHighcharts
                }
            });
        },
        getTimeFormatXaxis: function() {
            return {
                xAxis: {
                    type: "datetime",
                    labels: {
                        formatter: function() {
                            return Highcharts.dateFormat("%H:%M:%S", this.value);
                        }
                    }
                }
            };
        },
        time: {
            chart: {
                type: "area",
                backgroundColor: "transparent",
                animation: false,
                events: {
                    redraw: function() {
                        $(window).resize();
                    }
                }
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    stacking: "normal"
                },
                series: {
                    borderWidth: 0,
                    animation: false,
                    cursor: "pointer"
                }
            },
            legend: {
                enabled: false
            },
            title: {
                style: {
                    display: "none"
                }
            },
            xAxis: _.extend({}, axisOptions, {
                type: "datetime",
                labels: {
                    formatter: function() {
                        return Highcharts.dateFormat("%H:%M:%S", this.value);
                    }
                }
            }),
            yAxis: axisOptions
        },
        bullet: {
            legend: {
                enabled: false
            },
            tooltip: {
                enabled: true,
                formatter: function() {
                    return `Current: <b>${this.y}</b>`;
                },
                ...tooltipStriimlineHighcharts
            },
            plotOptions: {
                bar: {
                    color: "rgba(0, 0, 0, 0.1)",
                    shadow: false,
                    pointPadding: 0.5,
                    borderWidth: 0.1
                }
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            title: {
                text: ""
            },
            chart: {
                backgroundColor: "transparent",
                animation: false,
                defaultSeriesType: "bar",
                marginTop: 5,
                marginRight: 15,
                marginBottom: 20,
                marginLeft: 100,
                borderWidth: 0
            },
            xAxis: {
                tickLength: 0,
                lineWidth: 1,
                categories: ["Min. Avg. Max."],
                gridLineColor: color,
                lineColor: "rgba(0, 0, 0, 0.1)",
                tickColor: color,
                title: {
                    text: ""
                },
                labels: {
                    style: {
                        color: bulletChartTextColor
                    }
                }
            },
            yAxis: {
                gridLineColor: color,
                lineColor: color,
                tickColor: color,
                title: {
                    text: ""
                },
                labels: {
                    y: 10,
                    style: {
                        color: bulletChartTextColor
                    }
                },
                tickWidth: 1,
                tickLength: 3,
                gridLineWidth: 0,
                endOnTick: true
            }
        }
    };
})();
