import App from "app";
import Marionette from "marionette";

export default Marionette.Controller.extend({
    show: function (view, options) {
        options || (options = {});
        this.region = options.region || App.getRegion("content");
        view = view.getMainView ? view.getMainView() : view;
        this.setMainView(view);
        return this.region.show(view);
    },
    getMainView: function () {
        return this._mainView;
    },
    setMainView: function (view) {
        if (this._mainView) {
            return;
        }
        this._mainView = view;
        this.listenTo(view, "destroy", this.destroy);
    },
});
