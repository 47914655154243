import React from "react";
import { StriimTooltip } from "@striim/striim-ui";

const WithTooltip = (Component, tooltipContent, interactive = false) => {
    // If tooltip is empty, Don't add tooltip
    if (tooltipContent) {
        return (
            <StriimTooltip
                PopperProps={{ "data-test-id": "striim-tooltip" }}
                title={tooltipContent}
                interactive={interactive}
            >
                <div>{Component}</div>
            </StriimTooltip>
        );
    } else return Component;
};

export default WithTooltip;
