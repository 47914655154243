import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import useStores from "src/utils/use-stores";
import { Card, Grid, Typography } from "@material-ui/core";
import useStyles from "../../app-list-page.styles";
import AppGroup from "./app-group";
import AppListService from "../../app-list-service";
import { ALL_GROUP_NAME } from "../../consts";
import InfoOutlinedIcon from "../../images/info-circle.svg";
import Dictionary from "app/components/common/helpable/online-help-dictionary";
import { StriimTypography } from "@striim/striim-ui-v2";
import { Box } from "@mui/material";

export const getDefaultGroup = apps => {
    return {
        name: ALL_GROUP_NAME,
        numberOfApps: apps.length,
        applications: apps.map(app => app.name),
        isSelected: true
    };
};

const AppGroups = props => {
    const { groups, selectedGroup, onGroupChange, apps, isFiltering, filteredApps } = props;
    const classes = useStyles();
    const { groupsStore } = useStores();

    const isGroupPresent = theGroup => {
        return groups.some(group => {
            return theGroup?.name === group.name;
        });
    };

    useEffect(() => {
        const storedGroup = AppListService.getSelectedGroup();
        if (storedGroup?.name === ALL_GROUP_NAME) {
            setSelectedGroup(getDefaultGroup(apps));
        } else if (isGroupPresent(selectedGroup)) {
            setSelectedGroup(selectedGroup);
        } else if (storedGroup && !selectedGroup) {
            setSelectedGroup(groups.find(group => storedGroup.name === group.name));
        } else {
            setSelectedGroup(getDefaultGroup(apps));
        }
    }, [groups]);

    const setSelectedGroup = group => {
        onGroupChange(group);
        AppListService.setSelectedGroup(group);
    };

    const isGroupSelected = group => {
        return group?.name?.toLowerCase() === selectedGroup?.name?.toLowerCase();
    };

    const getGroupComponents = useCallback(() => {
        const theGroups = [getDefaultGroup(apps), ...groups];
        return theGroups.map((group, index) => {
            let appsInGroup;
            if (index) {
                appsInGroup = groupsStore.filterAppsByGroup(group, apps);
            } else if (!index && !isFiltering) {
                appsInGroup = apps;
            } else if (!index && isFiltering) {
                appsInGroup = filteredApps;
            } else {
                appsInGroup = groupsStore.filterAppsByGroup(group, apps);
            }

            return (
                <AppGroup
                    key={group.name}
                    name={group.name}
                    collapsed={group.collapsed}
                    isDeleteAvailable={!group.isDefaultGroup}
                    validations={group.validations}
                    description={group.description}
                    group={group}
                    numberOfApps={appsInGroup?.length}
                    appsList
                    appsInGroup={appsInGroup}
                    setSelectedGroup={setSelectedGroup}
                    isSelected={isGroupSelected(group)}
                    groups={theGroups}
                    groupType={group.groupType}
                />
            );
        });
    }, [isFiltering, apps, groups, groupsStore, filteredApps]);

    return (
        <Grid item xs={2}>
            <Card className={classes.card}>
                <Box container className={classes.groupContainer}>
                    <Typography className={classes.header} variant="h3">
                        Groups
                    </Typography>
                    <InfoOutlinedIcon
                        onClick={() => {
                            window.open(Dictionary.get()["APPS"].href, "_blank");
                        }}
                        className={classes.infoIcon}
                    />
                </Box>
                {getGroupComponents()}
            </Card>
        </Grid>
    );
};

AppGroups.propTypes = {
    groups: PropTypes.array,
    selectedGroup: PropTypes.object,
    onGroupChange: PropTypes.func,
    apps: PropTypes.array,
    isFiltering: PropTypes.bool,
    filteredApps: PropTypes.array
};

export default AppGroups;
