import Backbone from "backbone";
import _ from "underscore";
import template from "./templates/reportType.html";
import dateLib from "core/utils/date-utils";
import $__app_components_common_editor_control_ui_control from "app/components/common/editor/control/ui-control";
import BaseControl from "../../../common/editor/control/base-control";
import { segmentationChip, segmentationTooltip } from "../../../common/segmentationComponents";

/* Custom radio button list */

const RadioButtonTemplate = `<div class="flex-column {{standardStyling ? '' : 'custom'}}">
<% _.each(dataSource, function(item) { %>
    <label id="{{item.id}}" data-test-id="{{item.id}}" >
        <input type="radio" class="with-gap" name="{{this}}" disabled value="{{item.value}}"/>
        <span>{{item.name}}</span>
    </label>
<% }, name); %>
</div>`;

const RadioButtonListView = BaseControl.View.extend({
    template: _.template(RadioButtonTemplate),
    className: "",

    getViewValue: function() {
        return this.$("input:checked").val();
    },

    setViewValue: function() {
        this.$('input[name="' + this.model.name + '"]').val([this.getValue()]);
    },

    onRender: function() {
        BaseControl.View.prototype.onRender.apply(this, arguments);

        this.model.dataSource.forEach(item => {
            if (item.customLicense) {
                const licenseIndicator = segmentationChip(item.customLicense.variant);
                const itemEl = this.$el.find("#" + item.id);
                itemEl.append(licenseIndicator);

                if (item.customLicense.isFeatureEnabled) {
                    return;
                }

                setTimeout(() => {
                    itemEl.find("input").attr("disabled", true);
                }, 0);

                itemEl.tooltipster(
                    segmentationTooltip(
                        item.customLicense.variant,
                        item.customLicense.licenseKey,
                        itemEl,
                        undefined,
                        () => this.trigger("close-dropdown")
                    )
                );
            }
        });
    }
});

function CustomRadioButtonList(dataSource, standardStyling) {
    var name = Math.random()
        .toString(36)
        .substring(7);
    var Model = BaseControl.Model.extend({
        defaults: {
            dataSource: dataSource,
            value: String,
            standardStyling: standardStyling || String,
            name: name
        }
    });

    return _.extend({}, BaseControl, {
        Model: Model,
        View: RadioButtonListView
    });
}

/*  */

function applyTime(date, timeString) {
    if (!date || !timeString) {
        return;
    }
    const time = dateLib(timeString, "HH:mm:ss");
    date = date.set("hour", time.hour()).set("minute", time.minute());
    return date;
}

var ReportType = {};

ReportType.Options = {
    RealTime: "Real Time",
    TimeRange: "Specific Time",
    CustomRange: "Custom Range Time"
};

ReportType.EnsureDateLibObject = function(date) {
    return dateLib.isDayjs(date) ? date : dateLib(parseInt(date));
};

ReportType.DateRangeFormat = function(from, to) {
    from = ReportType.EnsureDateLibObject(from);
    to = ReportType.EnsureDateLibObject(to);

    return from.format("MMM D") + ", " + from.format("h:mmA") + " - " + to.format("MMM D") + ", " + to.format("h:mmA");
};

ReportType.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(template),
    className: "reportType",
    regions: {
        listRegion: ".list",
        dateRegion: ".datePicker",
        timeRegion: ".timePicker",

        //Custom range
        startDateRegion: ".startDate",
        startTimeRegion: ".startTime",
        endTimeRegion: ".endTime",
        endDateRegion: ".endDate"
    },
    ui: {
        picker: ".picker",
        customRangePicker: ".custom-range-picker",
        buttons: ".buttons",
        startDatePicker: ".startDate .datePicker",
        endDatePicker: ".endDate .datePicker",
        startTimePicker: ".startTime ._timePicker",
        endTimePicker: ".endTime ._timePicker"
    },
    events: {
        "click .apply-btn": "_applyClick",
        "click .cancel-btn": "_cancelClick",
        "change @ui.startDatePicker": function() {
            this.setDateRange('endDate');
            this.setDateRange('startDate');
        },
        "change @ui.endDatePicker": function() {
            this.setDateRange('startDate');
            this.setDateRange('endDate');
        },
        "change @ui.startTimePicker": function() {
            this.setTimeRange('endTime');
            this.setTimeRange('startTime');
        },
        "change @ui.endTimePicker": function() {
            this.setTimeRange('startTime');
            this.setTimeRange('endTime');
        }
    },

    initialize: function(options) {
        this.pickers = options.pickers || [];
        this.itemsAsObjects = typeof options.itemsAsObjects === "undefined" ? false : options.itemsAsObjects;
    },
    onRender: function() {
        this._UIControl = $__app_components_common_editor_control_ui_control;

        let dataSource;
        if (this.itemsAsObjects) {
            dataSource = this.pickers;
        } else {
            dataSource = this.pickers.map(function(p, index) {
                return {
                    id: "reportType" + index,
                    name: p,
                    value: p
                };
            });
        }

        this.reportTypes = CustomRadioButtonList(dataSource, true).create();

        this._togglePicker();

        this.listenTo(
            this.reportTypes.model,
            "change:value",
            function() {
                this._togglePicker();
                this._toggleCustomTimeRangePicker();
            }.bind(this)
        );

        this.reportTypes.setValue(this.model.report.type);
        this.listRegion.show(this.reportTypes);

        this.listenTo(this.reportTypes, "close-dropdown", () => {
            this.trigger("timeRange:close");
        });

        this.date = this._UIControl.DatePicker.extend({
            minDate: -1,
            maxDate: "0",
            valueFormat: "MMM D, YYYY",
            valueFormatPicker: "M d, yy"
        }).create();
        this.date.setValue(this.model.report.selectedDate);
        this.dateRegion.show(this.date);

        this.time = this._UIControl.TimePicker();
        this.time.setValue(this.model.report.selectedTime || new dateLib().format("HH:mm:ss"));

        this.timeRegion.show(this.time);

        //Custom range
        this.startDate = this._UIControl.DatePicker.extend({
            maxDate:  this.model.report.endTime ? this.model.report.endTime?.format("YYYY-MM-D") : null,
        }).create();
        this.startDateRegion.show(this.startDate);
        this.startDate.setValue(dateLib.isDayjs(this.model.report.startTime) ? this.model.report.startTime : dateLib());

        this.startTime = this._UIControl.TimePicker();
        this.startTimeRegion.show(this.startTime);
        this.startTime.setValue(dateLib.isDayjs(this.model.report.startTime) ? this.model.report.startTime : dateLib());

        this.endTime = this._UIControl.TimePicker();
        this.endTimeRegion.show(this.endTime);
        this.endTime.setValue(dateLib.isDayjs(this.model.report.endTime) ? this.model.report.endTime : dateLib());

        this.endDate = this._UIControl.DatePicker.extend({
            minDate:  this.model.report.startTime ? this.model.report.startTime?.format("YYYY-MM-D") : null,
        }).create();
        this.endDateRegion.show(this.endDate);
        this.endDate.setValue(dateLib.isDayjs(this.model.report.endTime) ? this.model.report.endTime : dateLib());
    },
    _togglePicker: function() {
        this.ui.picker.toggle(this.reportTypes.model.value === ReportType.Options.TimeRange);
    },
    _toggleCustomTimeRangePicker: function() {
        this.ui.customRangePicker.toggle(this.reportTypes.model.value === ReportType.Options.CustomRange);
        this.setDateRange("endDate");
        this.setDateRange("startDate");
    },
    _applyClick: function() {
        this._updateModel();
        this.trigger("timeRange:close");
    },
    _cancelClick: function() {
        this.trigger("timeRange:close");
    },
    _updateModel: function() {
        this.model.report.type = this.reportTypes.model.value;

        if (this.model.report.type === ReportType.Options.RealTime) {
            this.model.report.startTime = null;
            this.model.report.endTime = null;
            this.model.report.selectedDate = null;
            this.model.report.selectedTime = null;
            this.model.report.value = ReportType.Options.RealTime;
        } else if (this.model.report.type === ReportType.Options.CustomRange) {
            var from = this.startDate.getValue();
            var to = this.endDate.getValue();
            from = applyTime(from, this.startTime.getValue());
            to = applyTime(to, this.endTime.getValue());

            this.model.report.startTime = from;
            this.model.report.endTime = to;
            this.model.report.value = ReportType.DateRangeFormat(from, to);
        } else {
            // Specific time
            var date = this.date.getValue();
            var timeString = this.time.getValue();
            this.model.report.selectedDate = date;
            this.model.report.selectedTime = timeString;

            if (!(date && timeString)) {
                return;
            }

            var time = dateLib(timeString, "HH:mm:ss");
            let dateWithTime = date
                .set("hour", time.hour())
                .set("minute", time.minute())
                .set("second", time.second());
            let startTimePlus15Minutes = dateLib(dateWithTime).add(15, "minute");

            this.model.report.startTime = dateWithTime;
            this.model.report.endTime = startTimePlus15Minutes;
            this.model.report.value =
                dateWithTime.format("MMM D") +
                ", " +
                time.format("h:mmA") +
                " - " +
                startTimePlus15Minutes.format("h:mmA");
        }

        this.model.trigger("change:report", this.model, this.model.report);
    },
    setTimeRange: function(typeofTime) {
        var maxEndTime, minEndTime;
        var element = typeofTime == "endTime" ? ".endTime ._timePicker" : ".startTime ._timePicker";
        if(this.endDate?.getValue().format("YYYY-MM-D") == this.startDate?.getValue().format("YYYY-MM-D"))
        {
            const startTime = dateLib(this.startTime?.getValue(), "HH:mm").format("HH:mm");
            const endTime = dateLib(this.endTime?.getValue(), "HH:mm").format("HH:mm");
            if (
                startTime === endTime ||
                this.startTime?.getValue() >= this.endTime?.getValue()
            ) {
                var setStartTime = new dateLib().set("hour",0o0).set("minute", 0)
                var setEndTime = new dateLib().set("hour",23).set("minute", 45)

                this.startTime?.setValue(setStartTime);
                this.endTime?.setValue(setEndTime);

            }
                minEndTime =
                    typeofTime == "endTime"
                        ? dateLib(this.startTime?.getValue(), "HH:mm ")
                            .add(15, "minutes")
                            .format("HH:mm")
                        : null;
                maxEndTime =
                    typeofTime == "endTime"
                        ? "11:45pm"
                        : dateLib(this.endTime?.getValue(), "HH:mm ")
                            .subtract(15, "minutes")
                            .format("HH:mm");
                $(element).timepicker("option", {
                    minTime: minEndTime,
                    maxTime: maxEndTime,
                    step: 15
                });
        }
        else {
            minEndTime = " ";
            maxEndTime = " ";
            $(".endTime ._timePicker").timepicker("option", {
                minTime: minEndTime,
                maxTime: maxEndTime,
                step: 15
            });
            $(".startTime ._timePicker").timepicker("option", {
                minTime: minEndTime,
                maxTime: maxEndTime,
                step: 15
            });
        }
    },
    setDateRange: function(date) {
        const element = date === "endDate" ? ".endDate .datePicker" : ".startDate .datePicker";
        const datevariable = date === "endDate" ? this.startDate?.getValue() : this.endDate?.getValue();

        $(element).datepicker("option", {
            minDate: date == "endDate" ? datevariable?.format("YYYY-MM-D") : null,
            maxDate: date == "startDate" ? datevariable?.format("YYYY-MM-D") : null
        });
        this.setTimeRange("endTime");
        this.setTimeRange("startTime");
    },
});

export default ReportType;

