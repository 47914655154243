export const styles = {
    reviewWrapper: {
        display: "flex",
        gap: 3,
        flexDirection: "column",
        position: "relative"
    },
    grid: {
        display: "grid",
        rowGap: 1,
        gridTemplateColumns: "auto 1fr"
    },
    sectionTitle: isPDFReport => ({
        color: "primary.700",
        mb: 2,
        fontWeight: isPDFReport ? 900 : 600
    }),
    dataTitle: {
        margin: "16px 0px",
        color: "primary.900"
    },
    recordTitle: {
        my: 1,
        color: "greyscale.900"
    },
    title: {
        display: "flex",
        whiteSpace: "nowrap",
        color: "greyscale.700",
        pr: 3
    },
    record: {
        color: "primary.700",
        wordBreak: "break-word",
        whiteSpace: "break-spaces"
    },
    tableWrapper: {
        padding: 3,
        color: "greyscale.900"
    },
    tableTitle: {
        mb: 2
    },
    targetTableTitle: {
        display: "flex",
        whiteSpace: "nowrap",
        color: "primary.900",
        pr: 0
    },
    editIconSmall: {
        fill: "none",
        fontSize: 16,
        "& > path": {
            stroke: ({ palette }) => palette.secondary[500]
        },
        mt: 0.5
    },
    tableText: {
        color: "greyscale.700",
        mt: 1 / 2
    },
    tablesList: {
        display: "flex",
        flexDirection: "column",
        gap: 1
    },
    editIcon: {
        fill: "none",
        fontSize: 20,
        "& > path": {
            stroke: ({ palette }) => palette.secondary[500]
        }
    },
    targetConfiguration: {
        display: "flex",
        flexDirection: "column",
        gap: 3
    },
    tooltipIcon: {
        width: "18px",
        height: "18px",
        color: "transparent",
        marginLeft: "4px"
    },
    striimLogo: {
        width: ({ spacing }) => spacing(14),
        height: ({ spacing }) => spacing(14),
        position: "absolute",
        top: ({ spacing }) => spacing(-7),
        right: "0px",
        " & img": {
            width: "100%"
        }
    }
};
