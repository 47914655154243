import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import "highcharts";
import App from "app";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import dateLib from "core/utils/date-utils";
import stringUtils from "core/utils/string-utils";
import dialog from "app/components/common/dialogs/dialogWindow";
import { LEEReportView } from "./lee-report-view";
import { initMaterialDesignTabs } from "../../../../../../core/utils/material-design/tabs/material-design-style-tabs";
import { tooltipStriimlineHighcharts } from "../../../../../styles/materialize/components-striimline/tooltip.export";

export const lineGraphConfig = {
    title: false,
    chart: {
        backgroundColor: null
    },
    legend: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    tooltip: {
        formatter: function() {
            return `Lag is ${this.y} s at ${dateLib(this.x).format("hh:mm:ss")}`;
        },
        ...tooltipStriimlineHighcharts
    },
    yAxis: {
        labels: {
            enabled: true
        },
        title: {
            text: "Lag (seconds)"
        },
        min: 0,
        gridLineColor: "rgba(100,100,100, 0.3)"
    },
    xAxis: {
        type: "datetime",
        labels: {
            formatter: function() {
                return dateLib(this.value).format("hh:mm:ss");
            }
        }
    },
    plotOptions: {
        series: {
            animation: false,
            lineWidth: 4,
            shadow: false,
            states: {
                hover: {
                    lineWidth: 4
                }
            },
            marker: {
                enabled: true,
                symbol: "circle",
                radius: 2,
                states: {
                    hover: {
                        radius: 4
                    }
                }
            },
            fillOpacity: 0.25
        }
    }
};

export const getLagDataFromModel = model => {
    if (window._lagReportNotAvailable) {
        return null;
    }

    if (!model) {
        return null;
    }

    const allValues = model.get("allValues");
    if (!allValues) {
        return null;
    }

    const timeSeriesData = allValues["time-series-data"];
    if (!timeSeriesData) {
        return null;
    }

    const lagEndToEnd = timeSeriesData["lag-end2end"];
    if (!lagEndToEnd || lagEndToEnd.length === 0) {
        return null;
    }

    return lagEndToEnd;
};

const lagEndToEndParser = (model, sourcesList) => {
    if (window._fakeLagTargets) {
        const data = [
            { x: 1, y: 1 },
            { x: 2, y: 2 },
            { x: 3, y: 3 },
            { x: 4, y: 4 },
            { x: 5, y: 5 }
        ];
        return {
            source1: data,
            source2: data,
            source3: data,
            source5: data,
            source6: data,
            source7: data,
            source8: data,
            source9: data,
            source10: data
        };
    }

    const lagEndToEnd = getLagDataFromModel(model);
    if (lagEndToEnd === null) {
        return null;
    }

    const sources = {};

    const data = lagEndToEnd.filter(item => !item.second.empty).map(item => item.second.data);

    data.forEach(item => {
        if (!item) {
            return;
        }
        item.forEach(sourceItem => {
            if (sourcesList && sourcesList.indexOf(sourceItem.source) === -1) {
                return;
            }

            if (!sources[sourceItem.source]) {
                sources[sourceItem.source] = [];
            }
            sources[sourceItem.source].push({
                x: sourceItem["at"],
                y: sourceItem["lee"]
            });
        });
    });

    return sources;
};

const GraphsView = Backbone.Marionette.ItemView.extend({
    seriesToRenderInThisAdapter: null,

    template: _.template(""),

    initialize: function(options) {
        this.report = options.report;
    },

    onRender: function() {
        this.$el.addClass("row p-16px");
    },

    _drawGraphSekeleton: function(cssClass, val, id, withTopMargin) {
        if (withTopMargin) {
            cssClass += " mt-20px p-16px";
        }
        const elementStr = `<div class="${cssClass}"><h3>${val}</h3></div>`;
        var $div = $(elementStr);
        if (id) {
            $div.prop("id", "graph-" + id);
            $div.removeClass("deep");
            $div.addClass("graph-container");
        }

        //this.ui.graphsContainer.append($div);
        this.$el.append($div);
    },

    _keyToElementId: function(key) {
        return stringUtils.replaceAll(key, "\\.", "-");
    },

    _isGraphsInitialized: false,

    drawgraphs: function() {
        var that = this;

        if (!this._isGraphsInitialized) {
            //this.trigger("seriesToRenderInThisAdapterChanged", this.report);
            let addMargin = false;
            Object.keys(this.report).forEach(key => {
                const name = "End to End Lag from " + metaObjectConverter.getName(key);
                that._drawGraphSekeleton("col-sm-12 graph-header", name, "", addMargin);
                that._drawGraphSekeleton("col-sm-12", "", that._keyToElementId(key));
                addMargin = true;
            });
            this._isGraphsInitialized = true;
        }

        this._charts = this._charts || {};
        Object.keys(this.report).forEach(key => {
            const elementQuery = "#graph-" + that._keyToElementId(key);

            var series = that.report[key];
            var seriesForHighCharts = {
                name: key,
                data: []
            };

            for (var i in series) {
                var entry = series[i].y;
                var date = series[i].x;
                seriesForHighCharts.data.push([date, entry]);
            }
            var config = _.extend({}, lineGraphConfig, {
                series: [seriesForHighCharts]
            });
            if (!that._charts[elementQuery]) {
                that._charts[elementQuery] = that.$(elementQuery).highcharts(config);
            } else {
                const chart = that._charts[elementQuery].highcharts();
                if (chart.series.length > 0) {
                    chart.series[0].remove(false);
                    chart.addSeries(seriesForHighCharts, false);
                    // chart.series[0].color = "#7CB5EC";
                    chart.series[0].color = "#55C2FF";
                    chart.redraw();
                }
            }
        });
    }
});

const modalTemplate = `<header>
    <div class="heading">
        <div class="inner" style="padding-left: 8px;">
            <h1>End to End lag</h1>
        </div>
    </div>
    <div class="controls">
        <a href="#" class="button cancel"><i>&times;</i></a>
    </div>
</header>
<section class="md-tabs-container">
    <nav  class="tabs-box" id="end-to-end-tabs">
        <div aria-label="tabs" class="tabs-menu variant-segment js-tabs-menu active" role="tablist">
            <button class="tab-button js-tab-button" type="button" role="tab" data-tab="test1">
            <span class="tab-button__content">Realtime End to End</span>
            </button>
            <button class="tab-button js-tab-button" type="button" role="tab" data-tab="test2">
            <span class="tab-button__content">End to End Report</span>
            </button>
            <!--<span class="tab-indicator js-tab-indicator"></span>-->
        </div>
    </nav >
</section>
<div class="modal-body more-details-dialog lag-end-to-end-dialog" style="overflow-y: auto">
    <div class="inner" style="width: 1300px">
        <div class="flexbox">
            <div class="row lee-report-tabs">
                  <div id="test1" class="col s12 tab-content">
                    <div class="graphs col-sm-12">
                    </div>
                   </div>
                  <div id="test2" class="col s12  tab-content hidden-element">
                    <div class="lee-report-view"></div>
                  </div>
            </div>
        </div>
    </div>
</div>

`;

const TargetLagInformationDialogView = App.FormMixinDialog.View.extend({
    template: _.template(modalTemplate),

    ui: {
        dialogBody: ".more-details-dialog"
    },
    regions: {
        content: ".modal-body .inner",
        graphsContainer: ".graphs",
        reportView: ".lee-report-view"
    },

    initialize: function(options) {
        options.bind_submit = true;
        options.submit_on_enter = true;
        options.bind_cancel = true;
        this.model = options.model;
        this.sourcesList = options.sourcesList;
        this.report = options.report;
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
        this.model.on("change", this.refreshGraphs, this);
    },

    onBeforeDestroy: function() {
        if (this.tabsRemoveCallback) {
            this.tabsRemoveCallback();
        }
        this.model.off("change", this.refreshGraphs);
        if (App.FormMixinDialog.View.prototype.onBeforeDestroy) {
            App.FormMixinDialog.View.prototype.onBeforeDestroy.apply(this, arguments);
        }
        $("body").css("overflow", "visible");
    },

    refreshGraphs: function() {
        if (this.graphsView) {
            this.graphsView.report = lagEndToEndParser(this.model, this.sourcesList);
            this.graphsView.drawgraphs();
        }
    },

    onRender: function() {
        var that = this;
        $("body").css("overflow", "hidden");
        this.graphsView = new GraphsView({
            model: this.model,
            report: this.report
        });
        this.graphsContainer.show(this.graphsView);

        const windowHeight = $(window).height() - 200;
        this.ui.dialogBody.css("max-height", windowHeight + "px");
        that.getRegion("reportView").show(
            new LEEReportView({ targetName: this.model.get("label"), sourcesList: this.sourcesList })
        );

        setTimeout(function() {
            that.tabsRemoveCallback = initMaterialDesignTabs(document.querySelector("#end-to-end-tabs"));

            document.querySelectorAll("#end-to-end-tabs button.tab-button").forEach(btn => {
                btn.addEventListener("click", () => {
                    const tab = btn.dataset.tab;
                    document.querySelectorAll(".lee-report-tabs .tab-content").forEach(tabContent => {
                        if (tabContent.id === tab) {
                            tabContent.classList.remove("hidden-element");
                        } else {
                            tabContent.classList.add("hidden-element");
                        }
                    });
                });
            });
            that.refreshGraphs();
            that.fitPositionToModalContent();
        }, 300);
    }
});

export default function targetLagInformationDialog(modalManager, model, sourcesList) {
    const parsedReport = lagEndToEndParser(model, sourcesList);
    if (parsedReport === null) {
        dialog.alert("Lag end to end report is not available", undefined, undefined, " ");
        return;
    }

    let reportDialog = new TargetLagInformationDialogView({
        model: model,
        sourcesList: sourcesList,
        report: parsedReport
    });
    modalManager.add(reportDialog);
    new App.FormMixinDialog.Controller({
        view: reportDialog
    });

    reportDialog.on("form:submit", function() {
        reportDialog.destroy();
        reportDialog = null;
    });
}
