import React from "react";
import securityService from "../../../../../../core/services/securityService/securityService";
import {
    addonFeaturesKeys
} from "../../../../user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import MetadataManagerNotAvailable from "./metadata-manager-not-available";
import LegacyMetadataManager from "./legacy-metadata-manager";

export default function MetadataManager() {
    const metadataManagerAvailable = securityService.isSegmentationFeatureEnabled(addonFeaturesKeys.METADATAMANAGER);
    if (!metadataManagerAvailable) {
        return <MetadataManagerNotAvailable />;
    }

    return <LegacyMetadataManager />;
}
