import React, { useEffect, useState } from "react";
import { StriimButton, StriimModal, StriimTypography } from "@striim/striim-ui";
import { Box, Grid } from "@mui/material";

import StriimCircularProgress from "../../components/circular-progress/circular-progress";
import connectionProfileService from "../../connection-profile-service";
import { formatMessage } from "../../utils";

interface TestConnectionResponseType {
    result: boolean;
    message: string;
    apiName: string;
    warning: string;
    hide?: boolean;
    jsonErrorDescription?: string;
}

interface TestConnectionModalProps {
    cpName: string;
    testCPModalVisible: boolean;
    setTestCPModalVisible: (value: boolean) => void;
}

const TestConnectionModal = ({ cpName, testCPModalVisible, setTestCPModalVisible }: TestConnectionModalProps) => {
    const [testConnectionResponse, setTestConnectionResponse] = useState<TestConnectionResponseType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const testConnectionProfile = async () => {
        setLoading(true);
        try {
            const result = await connectionProfileService.testExistingConnectionProfile(cpName);
            setTestConnectionResponse(result && JSON.parse(result));
        } catch (error) {
            setTestConnectionResponse(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        testConnectionProfile();
    }, []);

    const footerContent = value => {
        if (value === "success") {
            return (
                <StriimButton
                    variant="primary"
                    data-test-id="test-connection-done-btn"
                    onClick={() => setTestCPModalVisible(false)}
                >
                    Done
                </StriimButton>
            );
        }
        if (value === "error") {
            return (
                <>
                    <StriimButton
                        variant="text"
                        data-test-id="test-connection-retry-btn"
                        onClick={() => testConnectionProfile()}
                    >
                        Retry
                    </StriimButton>
                    <StriimButton
                        variant="primary"
                        data-test-id="test-connection-done-btn"
                        onClick={() => setTestCPModalVisible(false)}
                    >
                        Done
                    </StriimButton>
                </>
            );
        } else return null;
    };

    const errors = Array.isArray(testConnectionResponse)
        ? testConnectionResponse.filter(response => !response.result)
        : [];
    const value = loading ? "" : errors.length ? "error" : "success";

    return (
        <StriimModal
            size="medium"
            isVisible={testCPModalVisible}
            autoHeight
            cancelContent={null}
            confirmContent={null}
            titleContent={<StriimTypography variant="h2">Test Connection</StriimTypography>}
            footerContent={footerContent(value)}
        >
            <Grid display="flex" flexDirection="column" gap={1.25}>
                <Grid gap={1} display="flex">
                    <Box>
                        <StriimCircularProgress size={24} status={value} />
                    </Box>
                    <Box>
                        {value === "success" && (
                            <StriimTypography variant="h3">Testing connection completed successfully!</StriimTypography>
                        )}
                        {value === "error" && <StriimTypography variant="h3">{errors[0].message}</StriimTypography>}
                        {(!value || value === null) && (
                            <StriimTypography variant="h3"> Testing connection in progress... </StriimTypography>
                        )}
                    </Box>
                </Grid>
                {!loading && errors.length
                    ? testConnectionResponse.map(response => {
                          if (response.result) return;
                          const formattedResponse = formatMessage(
                              `${response.apiName} Failed`,
                              JSON.parse(response.jsonErrorDescription),
                              {
                                  warning: response.warning
                              }
                          );
                          return formattedResponse;
                      })
                    : null}
            </Grid>
        </StriimModal>
    );
};

export default TestConnectionModal;
