import App from "../../../app";
import api from "../../../core/api/api";

let isInitialized = false;

const localCache = {
    key: "localUserCache",

    getAll: function() {
        let localUserCache: {};
        const localUserCacheStorage = localStorage.getItem(this.key);
        try {
            localUserCache = JSON.parse(localUserCacheStorage);
        } catch {
            localUserCache = {};
        }

        return localUserCache || {};
    },

    store: function(settings: Record<string, unknown>) {
        localStorage.setItem(this.key, JSON.stringify(settings));
    },

    set: function(preferenceName: string, stringValue: string) {
        const localUserCache = this.getAll();
        localUserCache[preferenceName] = stringValue;
        localStorage.setItem(this.key, JSON.stringify(localUserCache));
    },

    get: function(preferenceName: string) {
        const localUserCache = this.getAll();
        return localUserCache[preferenceName];
    }
};

function get(preferenceName: string) {
    return localCache.get(preferenceName);
}

function put(preferenceName: string, stringValue: any) {
    if (!isInitialized) {
        return false;
    }

    localCache.set(preferenceName, stringValue);

    const current = localCache.getAll();
    current[preferenceName] = stringValue;
    api.putUserProfile(App.user.name, current);
}

const load = async () => {
    const username = App?.user?.name;
    if (!username) {
        return;
    }

    const uiConfigString = await api.getUserProfile(username);
    if (!uiConfigString) {
        isInitialized = true;
        return;
    }
    try {
        const uiConfig = JSON.parse(uiConfigString);
        localCache.store(uiConfig);
    } catch {
    }

    isInitialized = true;
};

const module = {
    get: get,
    put: put,
    load: load
};

export default module;
