import Backbone from "backbone";
import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import BaseEditor from "app/components/common/editor/meta-object-editors/base";
import MetaObjectDataSource from "app/components/common/editor/control/select/metaobject-datasource";
import FieldDataSource from "app/components/common/editor/control/select/field-datasource";
import api from "core/api/api";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";

var Module = function(metadata_object, options) {
    options = options || {};
    options.displayFields = options.displayFields || [
        "dataType",
        "partitioned",
        "partitionExprs",
        "persist",
        "propertySet",
        "gracePeriodInterval",
        "gracePeriodField"
    ];
    BaseEditor.apply(this, arguments);
};

Module.prototype = _.extend({}, BaseEditor.prototype, {
    addCustomFields: function() {
        var dataSource = new FieldDataSource();

        const partitionFields = UIControl.Select(dataSource, {
            multiple: true
        }).create();

        this.fieldMapper.partitionExprs = partitionFields;

        this.gracePeriodField = UIControl.Select(dataSource).create();

        this.fieldMapper.partitioned = UIControl.Toggle.create();
        this.fieldMapper.persist = UIControl.Toggle.create();
        this.licenseCheck["persist"] = addonFeaturesKeys.PERSISTENTSTREAMS;
        this.licenseCheck["partitionExprs"] = addonFeaturesKeys.PARTITIONEDSTREAMS;

        var propertySetDataSource = new MetaObjectDataSource("PROPERTYSET");
        this.fieldMapper.propertySet = UIControl.Select(propertySetDataSource).create();

        this.fieldMapper.dataType = UIControl.Type.extend({
            hideLabel: true
        }).create();
        this.fieldMapper.dataType.model.on(
            "change:value",
            function(viewModel) {
                dataSource = new FieldDataSource(viewModel.value);
                this.fieldMapper.partitionExprs.setDataSource(dataSource);
                this.gracePeriodField.setDataSource(dataSource);
                this.fieldMapper.dataType.readonly = this.MetaObjectModel.metaInfoStatus.isAnonymous;
            }.bind(this)
        );
        this.fieldMapper.dataType.on(
            "type-saved",
            function() {
                this.Form.view.clearDirty(this.fieldMapper.dataType.model);
            }.bind(this)
        );

        this.fieldMapper.gracePeriodInterval = UIControl.Interval.create();
        this.fieldMapper.gracePeriodField = this.gracePeriodField;
    },

    postRender: function() {
        this.fieldMapper.partitionExprs.setDataSource(new FieldDataSource(this.MetaObjectModel.dataType));
        this.fieldMapper.dataType.setValue(this.MetaObjectModel.dataType);
        this.fieldMapper.dataType.readonly = this.MetaObjectModel.metaInfoStatus.isAnonymous;

        var $propertySet = this.fieldMapper.propertySet.$el.closest(".field-row");
        var openOrClosePropertySet = function(hide) {
            if (hide) {
                this.fieldMapper.propertySet.hidden = true;
                $propertySet.hide(hide);
            } else {
                this.fieldMapper.propertySet.hidden = false;
                $propertySet.show(hide);
            }
        }.bind(this);

        this.fieldMapper.persist.on(UIControl.EVENT.VALUE_CHANGED, function(persist) {
            openOrClosePropertySet(!persist);
        });

        if (!this.MetaObjectModel.get("persist")) {
            openOrClosePropertySet(true);
        }

        /**
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         * HACK , NEVER RELEASE THIS CODE..
         *
         */
        // (async () => {
        //     let enabled = await api.getLicensedFeatures();
        //     if(!enabled['$PersistentStreams']){
        //         this.fieldMapper.persist.$el.addClass("disabled");
        //         this.fieldMapper.persist.$el.find('label').tooltipster({
        //             contentAsHTML: true,
        //             animation: "grow",
        //             delay: 500,
        //             content: "Disabled due to license restrictions "
        //         });
        //     }
        // })();
    }
});

export default Module;
