import React, { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { StriimButton, StriimTypography } from "@striim/striim-ui";
import { Grid, Box, SvgIcon } from "@mui/material";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";

import dateLib from "core/utils/date-utils";
import PanelHeader from "./components/panel-header/panel-header";
import NewDiscoveryJobForm from "./components/new-discovery-job/new-discovery-job-form";
import AIInfoSection from "../guardian/components/ai-info-section/ai-info-section";
import DiscoveryInProgress from "./components/discovery-in-progress/discovery-in-progress";
import NoJobsRun from "./components/no-jobs-run/no-jobs-run";
import { Download, Refresh, Close } from "../../../generic/icon/customIcons";
import PanelResults from "./components/panel-results/panel-results";
import growl from "../../../../app/components/common/growl";
import guardianService from "../guardian/guardian-service";
import { Job, JobStatuses, JobVersion, PIISettings, Version } from "../guardian/guardian-job.types";
import { Source } from "./pii.types";
import { styles } from "./discover-pii-panel.styles";
import LoadingIndicator from "../../../generic/loading-indicator";
import { getFormattedSourcesData, getFormattedJobVersionsList, getFormattedJobVersionsListByStartTime } from "./utils";
import WithTooltip from "../../../generic/tooltip/tooltip";
import navigateTo from "../../../navigate-to";
import SherlockAppReport from "../reports/sherlock-report/sherlock-app-report";
import { fetchSherlockAppReportData } from "../reports/reports.helper";
import Printable from "../reports/printable";

interface PanelState {
    noJobsRunYet: boolean;
    isAIEngineConfigured: boolean;
    showDiscoveryForm: boolean;
    isLoading: boolean;
}

interface DiscoverPIIProps {
    sources: {
        name?: string;
    }[];
    appName: string;
    namespace: string;
    appFQN: string;
    onCancel: Function;
    onSave: Function;
    toggleFullScreen: Function;
    isAdmin: boolean;
    addSentinel: Function;
    nodes: any;
    defaultJobSelected?: string;
    flowStatus: string;
}

const getCurrentTimestampFormatted = () => {
    return dateLib().format("DDMMMYY_HHmm");
};

const DiscoverPII = ({
    sources,
    appName,
    namespace,
    appFQN,
    onCancel,
    toggleFullScreen,
    isAdmin,
    addSentinel,
    nodes,
    defaultJobSelected,
    flowStatus
}: DiscoverPIIProps) => {
    const [isSidebarExpanded, setIsSidebarExpanded] = useState<boolean>(false);
    const [piiSettings, setPiiSettings] = useState<PIISettings | null>(null);
    const [showLeftSidePanel, setShowLeftSidePanel] = useState<boolean>(false);
    const [showPanelInFullScreen, setShowPanelInFullScreen] = useState<boolean>(false);
    const [activeSource, setActiveSource] = useState<Source>();
    const [currentJobName, setCurrentJobName] = useState<string | null>(null);
    const [isDiscoverPIIInProgress, setIsDiscoverPIIInProgress] = useState<boolean>(false);
    const [isDiscoverPIIComplete, setIsDiscoverPIIComplete] = useState<boolean>(false);
    const [panelState, setPanelState] = useState<PanelState>({
        noJobsRunYet: false,
        isAIEngineConfigured: false,
        showDiscoveryForm: false,
        isLoading: true
    });
    const disableRun = sources.length === 0;
    const disabledTooltip = "Add at least one Source to your App to discover sensitive data.";

    const hideAIInfo = useMemo(() => {
        try {
            return JSON.parse(localStorage.getItem("hideAIInfo"));
        } catch (error) {
            console.log(error);
            return false;
        }
    }, []);
    const [formValid, setFormValid] = useState<boolean>(false);
    const [jobVersions, setJobVersions] = useState<JobVersion[]>([]);
    const [currentJobVersion, setCurrentJobVersion] = useState<JobVersion>();
    const [sourceData, setSourceData] = useState<Source[]>([]);

    const getJobName = job => `${job?.nsName}.${job?.name}`;
    const { timeRange, jobName } = useMemo(() => {
        return {
            timeRange: { from: currentJobVersion?.startTime, to: currentJobVersion?.completedTime },
            jobName: getJobName(currentJobVersion)
        };
    }, [currentJobVersion]);

    useEffect(() => {
        (async () => {
            try {
                const jobsList = await guardianService.getAIAgentsList();
                const result = await guardianService.getPIISettings();
                const settings: PIISettings = JSON.parse(result);
                const noJobsRunYet = !jobsList || !jobsList.length || Object.keys(jobsList[0]).length <= 1;
                const isAIEngineConfigured = settings?.aiEngine?.trim()?.length > 0;
                const jobsResult = await guardianService.getPIIJobsForApp(appFQN);
                const showDiscoveryForm = !noJobsRunYet && isAIEngineConfigured;
                let jobsForApp = [];
                let selectedJobVersion;
                if (jobsResult?.length) {
                    jobsForApp = getFormattedJobVersionsListByStartTime(jobsResult);

                    if (jobsForApp.length) {
                        const mostRecentJobName = defaultJobSelected ?? jobsForApp[0].nsName + "." + jobsForApp[0].name;
                        const jobReport = await guardianService.getReportForJob(mostRecentJobName);
                        const parsedReport: Job[] = await JSON.parse(jobReport);
                        const formattedSourcesData = getFormattedSourcesData(parsedReport, sources);
                        selectedJobVersion = jobsForApp.find(job => `${job.nsName}.${job.name}` === mostRecentJobName);
                        if ([JobStatuses.RUNNING, JobStatuses.CREATED].includes(selectedJobVersion?.status)) {
                            setCurrentJobName(selectedJobVersion.name);
                            setCurrentJobVersion(null);
                            setIsDiscoverPIIInProgress(true);
                            const jobName = `${selectedJobVersion.nsName}.${selectedJobVersion.name}`;

                            let [isJobPending, reportForJob] = await runPIIJob(jobName);
                            if (isJobPending) {
                                setPanelState(prev => ({
                                    ...prev,
                                    isAIEngineConfigured,
                                    showDiscoveryForm,
                                    isLoading: false
                                }));
                            }

                            intervalID.current = setInterval(async () => {
                                isJobPending
                                    ? ([isJobPending, reportForJob] = await runPIIJob(jobName))
                                    : (async () => {
                                          clearInterval(intervalID.current);
                                          const jobsForApp = await guardianService.getPIIJobsForApp(appFQN);
                                          const formattedJobVersionList = getFormattedJobVersionsList(jobsForApp);
                                          const formattedSourcesData = getFormattedSourcesData(reportForJob, sources);
                                          setJobVersions(formattedJobVersionList);
                                          setSourceData(formattedSourcesData);
                                          setCurrentJobVersion(
                                              formattedJobVersionList.find(job => job.name === selectedJobVersion.name)
                                          );
                                          setIsDiscoverPIIComplete(true);
                                          setIsDiscoverPIIInProgress(false);
                                          setFinalStates(
                                              settings,
                                              formattedJobVersionList,
                                              noJobsRunYet,
                                              isAIEngineConfigured,
                                              showDiscoveryForm
                                          );
                                      })();
                            }, 3000);
                        } else {
                            setCurrentJobVersion(selectedJobVersion);
                            setSourceData(formattedSourcesData);
                            setIsDiscoverPIIComplete(true);
                            setFinalStates(settings, jobsForApp, noJobsRunYet, isAIEngineConfigured, showDiscoveryForm);
                        }
                    }
                } else {
                    setJobVersions(jobsForApp);
                    setFinalStates(settings, jobsForApp, noJobsRunYet, isAIEngineConfigured, showDiscoveryForm);
                }
            } catch (error) {
                growl.error(error);
                setPanelState(prev => ({ ...prev, isLoading: false }));
            }
        })();

        const setFinalStates = (
            settings: PIISettings,
            jobsForApp: JobVersion[],
            noJobsRunYet: boolean,
            isAIEngineConfigured: boolean,
            showDiscoveryForm: boolean
        ) => {
            setPiiSettings(settings);
            setJobVersions(jobsForApp);
            setPanelState({
                noJobsRunYet,
                isAIEngineConfigured,
                showDiscoveryForm,
                isLoading: false
            });
        };

        return () => {
            if (intervalID?.current) {
                clearInterval(intervalID.current);
            }
        };
    }, [defaultJobSelected]);

    const ref = useRef();
    const intervalID = useRef();
    const formRef = useCallback(node => {
        if (node) {
            ref.current = node;
            setFormValid(node.isValid);
        }
    }, []);

    const defaultJobName = useMemo(() => {
        return appName ? `${appName}_${getCurrentTimestampFormatted()}` : "";
    }, [appName]);

    const handleSidebarToggle = () => {
        setIsSidebarExpanded(value => !value);
        toggleFullScreen();
    };

    const handleFullScreenToggle = () => {
        setShowPanelInFullScreen(value => !value);
        toggleFullScreen();
    };

    const createAndStartPIIJob = async () => {
        try {
            const { name, description } = ref?.current?.values;
            setCurrentJobName(name);
            setIsDiscoverPIIInProgress(true);
            const jobName = `${namespace}.${name}`;
            await guardianService.createPIIJob(name, namespace, description, [appFQN]);
            await guardianService.startPIIJob(name, namespace);

            let [isJobPending, reportForJob] = await runPIIJob(jobName);

            intervalID.current = setInterval(async () => {
                isJobPending
                    ? ([isJobPending, reportForJob] = await runPIIJob(jobName))
                    : (async () => {
                          clearInterval(intervalID.current);
                          const jobsForApp = await guardianService.getPIIJobsForApp(appFQN);
                          const formattedJobVersionList = getFormattedJobVersionsList(jobsForApp);
                          const formattedSourcesData = getFormattedSourcesData(reportForJob, sources);
                          setJobVersions(formattedJobVersionList);
                          setSourceData(formattedSourcesData);
                          setCurrentJobVersion(formattedJobVersionList.find(job => job.name === name));
                          setIsDiscoverPIIComplete(true);
                          setIsDiscoverPIIInProgress(false);
                      })();
            }, 3000);
        } catch (error) {
            growl.error(error);
            setIsDiscoverPIIInProgress(false);
        }
    };

    const createNewJob = () => {
        setPanelState(prev => ({ ...prev, showDiscoveryForm: true }));
        setCurrentJobName(null);
        setCurrentJobVersion(null);
        setIsDiscoverPIIComplete(false);
        setSourceData([]);
    };

    const retryJob = async () => {
        const jobFQN = `${currentJobVersion.nsName}.${currentJobVersion.name}`;
        const jobName = currentJobVersion.name;
        const jobNsName = currentJobVersion.nsName;
        setCurrentJobName(jobName);
        setCurrentJobVersion(null);
        setIsDiscoverPIIComplete(false);
        setIsDiscoverPIIInProgress(true);
        try {
            await guardianService.retryPiiJob(jobFQN);
            let [isJobPending, reportForJob] = await runPIIJob(jobFQN);

            intervalID.current = setInterval(async () => {
                isJobPending
                    ? ([isJobPending, reportForJob] = await runPIIJob(jobFQN))
                    : (async () => {
                          clearInterval(intervalID.current);
                          const jobsForApp = await guardianService.getPIIJobsForApp(appFQN);
                          const formattedJobVersionList = getFormattedJobVersionsList(jobsForApp);
                          const formattedSourcesData = getFormattedSourcesData(reportForJob, sources);
                          setJobVersions(formattedJobVersionList);
                          setSourceData(formattedSourcesData);
                          setCurrentJobVersion(
                              formattedJobVersionList.find(job => job.name === jobName && job.nsName === jobNsName)
                          );
                          setIsDiscoverPIIComplete(true);
                          setIsDiscoverPIIInProgress(false);
                      })();
            }, 3000);
        } catch (error) {
            growl.error(error);
            setIsDiscoverPIIInProgress(false);
        }
    };

    const runPIIJob = async (jobName): Promise<[boolean, Job[]]> => {
        try {
            const result = await guardianService.getReportForJob(jobName);
            const reportForJob: Job[] = await JSON.parse(result);
            const jobInfo = await guardianService.getJob(jobName.split(".")[1], jobName.split(".")[0]);
            const isJobPending =
                (!reportForJob ||
                    !reportForJob.length ||
                    !!reportForJob.filter(report => [JobStatuses.CREATED, JobStatuses.RUNNING].includes(report.status))
                        .length) &&
                [JobStatuses.CREATED, JobStatuses.RUNNING].includes(jobInfo[0].status);
            return [isJobPending, reportForJob];
        } catch (error) {
            growl.error(error);
        }
    };

    const handleVersionChange = async (version: Version) => {
        try {
            const selectedJobVersion = jobVersions.find(item => item.name === version.name);
            if (defaultJobSelected) {
                navigateTo.AppSherlock(
                    namespace + "." + appName,
                    selectedJobVersion.nsName + "." + selectedJobVersion.name,
                    false
                );
            }
            const jobName = `${selectedJobVersion.nsName}.${selectedJobVersion.name}`;
            const jobReport = await guardianService.getReportForJob(jobName);
            const parsedReport: Job[] = await JSON.parse(jobReport);
            const formattedSourcesData = getFormattedSourcesData(parsedReport, sources);
            setCurrentJobName(selectedJobVersion.name);
            setCurrentJobVersion(selectedJobVersion);
            setSourceData(formattedSourcesData);
        } catch (error) {
            growl.error(error);
        }
    };

    const fetchUpdatedJobVersionDetails = async () => {
        try {
            const jobsForApp = await guardianService.getPIIJobsForApp(appFQN);
            const formattedList = getFormattedJobVersionsList(jobsForApp);
            setJobVersions(formattedList);
            setCurrentJobVersion(formattedList.find(job => job.name === currentJobVersion.name));
        } catch (error) {
            growl.error(error);
        }
    };

    const sourcesWithError = sourceData.filter(source => source.status === JobStatuses.ERROR).map(v => v.name);
    const hasSensitiveData = !!sourceData.filter(source => source.entitiesWithSensitiveData).length;
    // const isPrimaryActionReport = !!(isDiscoverPIIComplete || currentJobVersion);

    return (
        <Grid container width={"100%"} height={"100%"} flexWrap={"nowrap"}>
            {/* {showLeftSidePanel && (
                    <Grid container flex={1} sx={styles.leftPanel} alignItems={"flex-start"}>
                        <Grid container justifyContent={"space-between"} p={2}>
                            <Grid item display={"flex"} alignItems={"center"} gap={1}></Grid>
                            <Grid item display={"flex"} alignItems={"center"}>
                                <StriimButton
                                    variant="text"
                                    startIcon={<SvgIcon component={Close} sx={styles.icon} />}
                                    sx={styles.iconButton}
                                    onClick={() => {
                                        setShowLeftSidePanel(false);
                                        setIsSidebarExpanded(false);
                                        toggleFullScreen();
                                        setActiveSource(null);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )} */}
            <Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"}>
                <Grid container display={"flex"} flexDirection={"column"} height={"100%"}>
                    {panelState.isLoading ? (
                        <LoadingIndicator isGlobal={false} />
                    ) : !panelState.showDiscoveryForm ? (
                        <NoJobsRun
                            isAIEngineConfigured={panelState.isAIEngineConfigured}
                            isUserAdmin={isAdmin}
                            onDiscover={() => {
                                setPanelState(prev => ({ ...prev, showDiscoveryForm: true }));
                            }}
                            onClose={onCancel}
                            disabled={sources.length === 0}
                            disabledTooltip={disabledTooltip}
                        />
                    ) : (
                        <>
                            <Grid
                                container
                                flex={1}
                                flexDirection={"column"}
                                justifyContent={"flex-start"}
                                alignSelf={"flex-start"}
                                flexWrap={"nowrap"}
                                sx={styles.panelContent}
                            >
                                {isDiscoverPIIComplete || currentJobVersion ? (
                                    currentJobVersion ? (
                                        <PanelResults
                                            currentJobVersion={currentJobVersion}
                                            timeRange={timeRange}
                                            jobName={jobName}
                                            sources={sourceData}
                                            appName={`${namespace}.${appName}`}
                                            showLeftSidePanel={showLeftSidePanel}
                                            isSidebarExpanded={isSidebarExpanded}
                                            showPanelInFullScreen={showPanelInFullScreen}
                                            formValues={ref?.current?.values}
                                            onToggle={() => {
                                                setIsSidebarExpanded(value => !value);
                                                toggleFullScreen();
                                            }}
                                            onClose={() => {
                                                setShowLeftSidePanel(false);
                                                setIsSidebarExpanded(false);
                                                setShowPanelInFullScreen(false);
                                                // toggleFullScreen();
                                                setActiveSource(undefined);
                                                onCancel();
                                            }}
                                            toggleFullScreen={handleFullScreenToggle}
                                            jobVersions={jobVersions}
                                            updatedTime={Number(currentJobVersion.completedTime)}
                                            sourcesWithError={sourcesWithError}
                                            handleVersionChange={handleVersionChange}
                                            hasSensitiveData={hasSensitiveData}
                                            fetchUpdatedJobVersionDetails={fetchUpdatedJobVersionDetails}
                                            addSentinel={(source, outputStreamNode) => {
                                                addSentinel(source, outputStreamNode);
                                            }}
                                            nodes={nodes}
                                            namespace={namespace}
                                            flowStatus={flowStatus}
                                            retryJob={retryJob}
                                            onCreateNewJob={createNewJob}
                                        />
                                    ) : (
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                            height={"100%"}
                                        >
                                            <StriimTypography variant="body4" sx={{ padding: 3 }}>
                                                Something went wrong. Please refresh the page to continue.
                                            </StriimTypography>
                                        </Box>
                                    )
                                ) : (
                                    <>
                                        <PanelHeader
                                            name={
                                                isDiscoverPIIInProgress
                                                    ? currentJobName ?? ref?.current?.values?.name
                                                    : null
                                            }
                                            isSidebarExpanded={isSidebarExpanded}
                                            onClose={() => {
                                                setShowLeftSidePanel(false);
                                                setIsSidebarExpanded(false);
                                                // toggleFullScreen();
                                                setActiveSource(undefined);
                                                onCancel();
                                            }}
                                            onToggle={() => {
                                                setIsSidebarExpanded(value => !value);
                                                toggleFullScreen();
                                            }}
                                        />
                                        {!isDiscoverPIIInProgress ? (
                                            <NewDiscoveryJobForm
                                                formRef={formRef}
                                                initialValues={{
                                                    name: defaultJobName,
                                                    description: "",
                                                    nsName: namespace
                                                }}
                                            />
                                        ) : (
                                            <DiscoveryInProgress />
                                        )}
                                        {!isDiscoverPIIInProgress && !hideAIInfo && (
                                            <Grid container sx={styles.infoContainer}>
                                                <AIInfoSection
                                                    showBottomBorder={false}
                                                    containerProps={{ p: 2, maxHeight: "unset" }}
                                                />
                                            </Grid>
                                        )}
                                    </>
                                )}
                                {/* <Grid container item sx={styles.piiInfoContainer}></Grid> */}
                            </Grid>
                            {!showPanelInFullScreen && (
                                <Grid
                                    container
                                    item
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    p={2}
                                    sx={styles.footer}
                                >
                                    <StriimButton variant="text" onClick={onCancel}>
                                        Cancel
                                    </StriimButton>
                                    <Grid item display={"flex"} gap={1}>
                                        {isDiscoverPIIComplete && (
                                            <StriimButton
                                                variant="secondary"
                                                sx={styles.refreshButton}
                                                startIcon={<SvgIcon component={AddIcon} />}
                                                disabled={isDiscoverPIIInProgress || (!currentJobVersion && !formValid)}
                                                onClick={createNewJob}
                                            />
                                        )}
                                        {isDiscoverPIIComplete && (
                                            <Printable
                                                name={`${appName}_Sherlock_Report`}
                                                component={SherlockAppReport}
                                                appendToBody={true}
                                                fetchReportData={async () => {
                                                    const data = await fetchSherlockAppReportData(
                                                        sourceData,
                                                        `${namespace}.${appName}`,
                                                        jobName,
                                                        timeRange
                                                    );
                                                    return data;
                                                }}
                                                buttonComponent={
                                                    <StriimButton
                                                        variant="primary"
                                                        startIcon={
                                                            <SvgIcon component={Download} sx={styles.buttonIcon} />
                                                        }
                                                    >
                                                        Report
                                                    </StriimButton>
                                                }
                                            />
                                        )}
                                        {!isDiscoverPIIComplete
                                            ? WithTooltip(
                                                  <StriimButton
                                                      variant="primary"
                                                      disabled={
                                                          isDiscoverPIIInProgress ||
                                                          (!currentJobVersion && !formValid) ||
                                                          disableRun
                                                      }
                                                      // startIcon={
                                                      //     isPrimaryActionReport ? (
                                                      //         <SvgIcon component={Download} sx={styles.downloadIcon} />
                                                      //     ) : null
                                                      // }
                                                      onClick={() => {
                                                          // if (isPrimaryActionReport) {
                                                          //     // TODO: Handle report download
                                                          // } else {
                                                          createAndStartPIIJob();
                                                          // }
                                                      }}
                                                  >
                                                      Run
                                                      {/* {isPrimaryActionReport ? "Report" : "Run"} */}
                                                  </StriimButton>,
                                                  disableRun ? disabledTooltip : null
                                              )
                                            : null}
                                        {sourcesWithError?.length ? (
                                            <StriimButton
                                                variant="primary"
                                                sx={styles.refreshButton}
                                                disabled={isDiscoverPIIInProgress}
                                                onClick={retryJob}
                                            >
                                                <SvgIcon component={Refresh} sx={[styles.refreshIcon, styles.retry]} />
                                                {sourcesWithError.length === sourceData?.length ? "Retry" : null}
                                            </StriimButton>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </Box>
        </Grid>
    );
};

export default DiscoverPII;
