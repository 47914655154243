import Backbone from "backbone";
import _ from "underscore";
import metadatabrowser from "app/components/metadatabrowser/metadatabrowser";
import statusManagement, { FlowStatus } from "src/status-management";

var MyObjects = {};

MyObjects.View = Backbone.Marionette.LayoutView.extend({
    template: _.template("<div></div>"),

    regions: {
        metadatabrowserRegion: "div",
    },

    initialize: function (options) {
        this.options = options || {};
    },

    onRender: function () {
        var metadatabrowserView = new metadatabrowser.View({
            isSmallView: true,
            showDetailofId: true,
            currentAppName: this.options.app.name,
            isEditable: statusManagement.isApplicationCreated(this.options.
                app.flowStatus),
        });
        // TODO refactor, do we really need MyObjects.View wrapper?
        metadatabrowserView.$el.removeClass("large").addClass("right-sidebar small sh-lt-4dp");
        this.metadatabrowserRegion.show(metadatabrowserView);

    },
});

export default MyObjects;
