import App from "app";
import NestedTypes from "backbone.nestedtypes";

App.module("Dashboard.Grid.Component.Controls", function (Controls, App, Backbone, Marionette, $, _, template) {
    // GridComponent controls
    var Handle = NestedTypes.Model.extend({
        defaults: {
            _active: true,
            enabled: true,
        },
    });
    var Handles = NestedTypes.Collection.extend({
        model: Handle,
    });
    Controls.Model = NestedTypes.Model.extend({
        defaults: {
            _active: false, // are the controls visible?
            snap: true, // snap to grid when resizing or moving?
            stepsize: 10, // pixels per step
            depth: 0, // How many steps to expand out from its boundaries (used in nesting)
            handles: Handles, // the handles used for dragging
        },
        initialize: function () {
            // Create a new Handles collection
            // Currently there is no variation between components with regard to what handles they have,
            // so it makes sense for the model to create the standard set automatically.
            this.set(
                "handles",
                new Handles([
                    {
                        id: "N",
                    },
                    {
                        id: "NE",
                    },
                    {
                        id: "E",
                    },
                    {
                        id: "SE",
                    },
                    {
                        id: "S",
                    },
                    {
                        id: "SW",
                    },
                    {
                        id: "W",
                    },
                    {
                        id: "NW",
                    },
                ])
            );
        },
    });

    var HandlesView = Marionette.CollectionView.extend({
        className: "handles-collection",
        childView: Marionette.ItemView.extend({
            className: "handle",
            //template: _.template('<span class="icon {{ id == "N" || id == "S" ? "fa fa-ellipsis-h" : (id == "E" || id == "W" ? "fa fa-ellipsis-v" : "corner") }}"></span>'),
            template: _.template('<span class="handle-inner"></span>'),
            initialize: function () {
                this.listenTo(this.model, "change:_active", this.render);
            },
            onRender: function () {
                var handle = this;
                handle.$el.addClass(handle.model.id).data("direction", handle.model.id);
                if (handle.model._active && handle.model.enabled) handle.$el.show();
                else handle.$el.hide();
            },
        }),
    });

    Controls.View = Marionette.LayoutView.extend({
        className: "handles",
        regions: {
            header: ".component-header-container",
            handles: ".handles-container",
        },
        template: _.template('<div class="component-header-container"></div><div class="handles-container"></div>'),
        onRender: function () {
            this.getRegion("handles").show(
                new HandlesView({
                    model: this.model,
                    collection: this.model.handles,
                })
            );

            /*
            // Expand the controls into position
            var step = this.model.depth * this.model.stepsize;
            // +1 to account for border
            var border_width = 1;
            var css = {
                top: this.model.handles.get('N')._active && this.model.handles.get('N').enabled ? (step+border_width) * -1 : 0,
                left: this.model.handles.get('W')._active && this.model.handles.get('W').enabled ? (step+border_width) * -1 : 0,
                paddingRight: this.model.handles.get('E')._active && this.model.handles.get('E').enabled ? step : 0,
                paddingLeft: this.model.handles.get('W')._active && this.model.handles.get('W').enabled ? step : 0,
                paddingTop: this.model.handles.get('N')._active && this.model.handles.get('N').enabled ? step : 0,
                paddingBottom: this.model.handles.get('S')._active && this.model.handles.get('S').enabled ? step : 0,
            };
            this.$el.css(css);
            */

            this.show_or_hide();
        },
        initialize: function () {
            this.listenTo(this.model, "change:_active change:depth", this.show_or_hide);
        },
        show_or_hide: function () {
            if (this.model.get("_active")) {
                this.$el.addClass("active");
            } else {
                this.$el.removeClass("active");
            }
        },
    });
});
