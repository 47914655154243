import _ from "underscore";
import Backbone from "backbone";
import NestedTypes from "backbone.nestedtypes";
import App from "app";
import ModalManager from "app/components/common/modal/ModalManager";
import Modal from "app/components/common/modal/modal";
import template from "./copy-properties-template.html";
import UIControl from "app/components/common/editor/control/ui-control";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import hierarchicalFormatter from "app/components/common/editor/control/select/hierarchicalFormatter";
import dialog from "app/components/common/dialogs/dialogWindow";

/**
 * Dialog model
 */
let CopyPropertiesModel = NestedTypes.Model.extend({
    defaults: {
        selectOptions: [],
        selectedPropertyTemplate: Object,
    },
});

/**
 * Dialog content view
 */
let ContentView = Backbone.Marionette.LayoutView.extend({
    template: _.template(template),

    ui: {
        selectContainer: ".select-container",
    },

    regions: {
        selectContainerRegion: ".select-container",
    },

    initialize: function () {
        this.componentSelect = UIControl.Select(hierarchicalFormatter(this.model.selectOptions), {
            allowClear: false,
        }).create();
        this.listenTo(
            this.componentSelect.model,
            "change:value",
            function (selected) {
                this.model.selectedPropertyTemplate = selected.selectedModel.propertyTemplate;
                this.trigger("value-set");
            }.bind(this)
        );
    },

    onRender: function () {
        this.selectContainerRegion.show(this.componentSelect);
    },
});

/**
 * Dialog mixin
 */
let CopyPropertiesDialogMixin = App.FormMixinDialog.View.extend({
    initialize: function (options) {
        options.contentview = new ContentView({ model: options.dialogModel });
        options.bind_submit = true;
        this.listenToOnce(
            options.contentview,
            "value-set",
            function () {
                this.ui.submitButton.removeClass("disabled");
            }.bind(this)
        );
        options.modalSettings = new Modal.Model({
            animation: {
                hide: "morph",
            },
        });
        App.FormMixinDialog.View.prototype.initialize.apply(this, arguments);
    },

    onRender: function () {
        this.ui.submitButton.addClass("disabled");
    },
});

/**
 * Show confirmation dialog
 * @returns {Promise<any>}
 */
function showDialog(model) {
    let dialog = new CopyPropertiesDialogMixin({
        model: new Backbone.Model({
            title: "Copy properties from",
            showSubmitButton: true,
            cancel_text: "Close",
            submit_text: "Copy",
        }),
        dialogModel: model,
    });

    let modalManager = new ModalManager({
        container: "body",
    });
    modalManager.add(dialog);
    new CopyPropertiesDialogMixin.Controller({
        view: dialog,
    });

    let dialogSubmit;
    dialog.on("cancel", () => {
        dialogSubmit = false;
        dialog.destroy();
    });

    dialog.on("submit", () => {
        if (!model.selectedPropertyTemplate) {
            return;
        }
        dialogSubmit = true;
        dialog.destroy();
    });

    return new Promise((resolve, reject) => {
        dialog.on("before:destroy", () => {
            modalManager.remove_all_modals();
            if (dialogSubmit) {
                resolve();
            } else {
                reject();
            }
        });
    });
}

CopyPropertiesDialogMixin.Controller = App.FormMixinDialog.Controller;

function getSelectOptions(propertyType, metaObjectId) {
    return new Promise((resolve) => {
        let selectOptions = [];
        metaStoreService.fetchCollections(["SOURCE", "CACHE", "TARGET"]).then(function(sources, caches, targets) {
            let allComponents = sources.models.concat(caches.models).concat(targets.models);

            _.each(allComponents, function (component) {
                if (metaObjectId && component.id === metaObjectId) {
                    return;
                }

                if (component.adapter && component.adapter.handler === propertyType) {
                    component.propertyTemplate = component.adapter;
                    selectOptions.push(component);
                } else if (component.parser && component.parser.handler === propertyType) {
                    component.propertyTemplate = component.parser;
                    selectOptions.push(component);
                }
            });
            resolve(selectOptions);
        });
    });
}

function copyPropertiesFrom(propertyType, metaObjectId) {
    return new Promise((resolve, reject) => {
        getSelectOptions(propertyType, metaObjectId).then((selectOptions) => {
            if (selectOptions.length === 0) {
                dialog.alert("There are no components to copy config properties from.");
                reject();
                return;
            }
            let model = new CopyPropertiesModel();
            model.selectOptions = selectOptions;
            model.selectedPropertyTemplate = null;
            showDialog(model)
                .then(() => {
                    resolve(model.selectedPropertyTemplate);
                })
                .catch(() => {
                    reject();
                });
        });
    });
}

export default copyPropertiesFrom;
