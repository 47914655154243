/*
* Function to extract the Snowplow Domain User Information from the first-party cookie set by the Snowplow JavaScript Tracker
* @return string or bool The ID string if the cookie exists or false if the cookie has not been set yet
*/

const trackingCookieExists = () => {
    var cookieName = '_sp_';
    var matcher = new RegExp(cookieName + 'id\\.[a-f0-9]+=([^;]+);?');
    var match = document.cookie.match(matcher);
    var split = match && match[1].split('.');
    if (match && match[1]) {
      return split[0]
    } else {
      return false;
    }
};
  
export default trackingCookieExists;
