import React from "react";
import TimeAgo from "react-timeago";
import { Box } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui";

const timeAgoFormatter = (value, unit, suffix) => {
    if (unit === "second") {
        return `less than one min ago`;
    }
    if (unit === "minute") {
        unit = "min";
    }
    return value + " " + unit + (value > 1 ? "s" : "") + " " + suffix;
};


const Banner = ({ time }) => {
    return (
        <Box sx={{ mb: 2 }}>
            <StriimTypography variant="h3" color="primary.700">
                Usage
            </StriimTypography>
            <StriimTypography variant="caption4" color="greyscale.700">
                Refreshed <TimeAgo date={time} minPeriod={60} formatter={timeAgoFormatter} />
            </StriimTypography>
        </Box>
    );
};

export default Banner;
