export const styles = {
    heading: {
        maxWidth: '345px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'primary.700'
    },
    simpleHeading: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    iconInfo: {
        height: 16,
        width: 16,
        fill: "transparent",
        "& path": {
            stroke: theme => theme.palette.greyscale[600]
        }
    },
    dataTableWrapper: {
        "& .appNamecellClass": {
            width: "inherit",
            display: "flex",
            "& .ag-cell-wrapper": {
                width: "inherit"
            },
            "& .ag-cell-wrapper > span > div": {
                display: "flex",
                flexWrap: "nowrap"
            }
        }
    },
    openIcon: {
        "& span": {
            margin: 0
        }
    },
    specialChevronDown: {
        width: "12px",
        height: "12px"
    }
};
