import ColumnsEnricherList from "app/components/common/editor/control/columns-picker/columns-enricher-list";
import types from "app/components/common/editor/control/select/types-datasource.json";

const Transformation = {};

Transformation.OnlyDbSources = false;

Transformation.createColumnControl = function () {
    return ColumnsEnricherList.extend({
        hideLabel: true,
        hidden: false,
        transformationConfig: {
            addAvailable: true,
            preFillColumns: false,
            labels: {
                column2: "Type",
                column3: "Expression",
            },
        },
    }).create();
};

Transformation.buildTql = function (tqlBuilder, columns) {
    columns.forEach(function (column) {
        let transformationFunction = null;
        if (column.tqlFunction) {
            const f = types.find((t) => t.id === column.tqlFunction);
            if (f) {
                transformationFunction = f.convertFunction;
            }
        }

        if (column.column && column.alias) {
            tqlBuilder.addExpression(column.alias, transformationFunction, column.column);
        }
    });
    tqlBuilder.addWildcard();
};

export default Transformation;
