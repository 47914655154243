const fieldsToStripOut = ["data", "last_data", "dataSource"];

function removeProps(obj, keys) {
    if (!obj) {
        return;
    }

    if (obj instanceof Array) {
        obj.forEach(function (item) {
            removeProps(item, keys);
        });
    } else if (typeof obj === "object") {
        Object.getOwnPropertyNames(obj).forEach(function (key) {
            if (keys.indexOf(key) !== -1) {
                delete obj[key];
            } else {
                removeProps(obj[key], keys);
            }
        });
    }
}

export default {
    sanitize: function (obj) {
        if (!obj) {
            return {};
        }
        removeProps(obj, fieldsToStripOut);
        return obj;
    },
};
