import _ from "underscore";
import BaseControl from "./../base-control";
import UIControl from "app/components/common/editor/control/ui-control";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import FieldDataSource from "./../select/field-datasource";
import SingleColumn from "./single-column-list";
import template from "./column-splitter.html";

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "columns-splitter",

    regions: {
        columnSelectorRegion: ".column-selector",
        delimiterRegion: ".delimiter",
        aliasSelectorRegion: ".alias-selector",
    },

    setViewValue: function () {},

    getViewValue: function () {
        let list = this.aliasSelector.getValue();

        var viewValue = [];

        for (let i = 0; i < list.length; i++) {
            viewValue.push({
                column: this.columnSelector.getValue(),
                delimiter: this.delimiter.getValue(),
                alias: list[i].alias,
            });
        }
        return viewValue;
    },

    setEnabled: function (enabled) {
        BaseControl.View.prototype.setEnabled.apply(this, [enabled]);

        this.columnSelector.setEnabled(enabled);
        this.delimiter.setEnabled(enabled);
        this.aliasSelector.setEnabled(enabled);
    },

    initialize: function (options) {
        this.columnSelector = UIControl.Select({})
            .extend({
                placeholder: "select",
                noMatchesMessage: "No columns found of type 'java.lang.String'.",
            })
            .create();

        this.delimiter = UIControl.TextField.create();

        this.aliasSelector = SingleColumn.extend({
            hideLabel: true,
            hidden: false,
            transformationConfig: {
                addAvailable: true,
                labels: {
                    column2: "Alias",
                },
            },
        }).create();
    },

    onRender: function () {
        this.columnSelectorRegion.show(this.columnSelector);
        this.delimiterRegion.show(this.delimiter);
        this.aliasSelectorRegion.show(this.aliasSelector);
        this.toggleAliasSelector(this.columnSelector.model.value);

        if (this.model.value && this.model.value[0]) {
            this.columnSelector.setValue(this.model.value[0].column);
            this.delimiter.model.value = this.model.value[0].delimiter;
            this.aliasSelector.model.value = this.model.value;
            this.toggleAliasSelector(this.model.value);
        }

        this.listenTo(
            this.delimiter.model,
            "change:value",
            function () {
                this.setValueFromView();
            }.bind(this)
        );

        this.listenTo(
            this.aliasSelector.model,
            "change:value",
            function () {
                this.setValueFromView();
            }.bind(this)
        );

        this.listenTo(
            this.columnSelector.model,
            "change:value",
            function (viewModel) {
                this.aliasSelector.setValue([]);
                this.trigger(UIControl.EVENT.VALUE_CHANGED, this.getViewValue());

                this.toggleAliasSelector(viewModel.value);
            }.bind(this)
        );
    },

    setStream: function (componentId) {
        metaStoreService.findById(componentId).then(
            function (componentModel) {
                if (!componentModel) {
                    return;
                }

                var fieldName =
                    componentModel.type === metaStoreService.entities.CACHE
                        ? componentModel.typename
                        : componentModel.dataType;

                this.columnsDataSource = new FieldDataSource(fieldName).then((fields) => {
                    var filtered = _.filter(fields, function (field) {
                        return field.description === "java.lang.String";
                    });

                    return filtered;
                });

                this.columnSelector.setDataSource(this.columnsDataSource);

                if (this.aliasSelector.gridView.collection && this.aliasSelector.gridView.collection.length === 0) {
                    this.aliasSelector.newColumnAdded();
                }
            }.bind(this)
        );
    },

    toggleAliasSelector(value) {
        if (!value) {
            this.aliasSelector.$el.hide();
        } else {
            this.aliasSelector.$el.show();
        }
    },
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: Array,
    },
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
