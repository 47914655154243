import _ from "underscore";
import BaseControl from "./../base-control";
import NumberField from "./number-field";

var View = NumberField.View.extend({
    events: {
        "keyup input": "setValueFromView",
        "change input": "setValueFromView",
        "keydown input": "keydown",
        "focusout input": "onFocusOut",
    },
    onFocusOut: function () {
        const value = this.getViewValue();
        if (value < 1) {
            this.setValue(this.defaultValue || 1);
        }
    },
});

export default _.extend({}, BaseControl, {
    View: View,
});
