import React, { useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui";
import { Bar, BarChart, LabelList, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";

import { styles } from "./job-insights-chart.styles";
import { JobStatuses } from "../../guardian-job.types";

//TODO refactor
const additional_violette = "#7E7ED6";

interface JobInsightsChartProps {
    data: {
        sensitiveData: number;
        nonSensitiveData: number;
        errors?: number;
        totalApps: number;
        totalSources: number;
    };
    shortNames?: boolean;
    barSize?: number;
    barWidth?: number;
    legendAlignment?: string;
    customLegendStyles?: object;
    customBarChartMargins?: object;
    status: JobStatuses;
    isReport: boolean;
}

const JobInsightsChart = ({
    data,
    shortNames = false,
    barSize = 24,
    barWidth = 408,
    legendAlignment = "bottom",
    customLegendStyles = {},
    customBarChartMargins = {},
    status,
    isReport = false
}: JobInsightsChartProps) => {
    const theme = useTheme();
    const [currentlyHoveredBar, setCurrentlyHoveredBar] = useState("");

    const sensitiveName = shortNames ? "W/ sensitive data" : "Apps w/ sensitive data";
    const notSensitiveName = shortNames ? "W/O sensitive data" : "Apps w/o sensitive data";
    const sensitiveData = data.sensitiveData || 0;
    const nonSensitiveData = data.nonSensitiveData || 0;
    const errors = data.errors || 0;
    const totalSources = data.totalSources || 0;
    const totalCompleted = sensitiveData + nonSensitiveData + errors;
    const completionPercentage = totalCompleted > 0 ? (totalCompleted / totalSources) * 100 : 0;
    const isFilled = totalSources === totalCompleted;
    const backgroundBar = {
        totalSources: totalSources - totalCompleted
    };
    const containerProps = isReport ? { width: 400 } : { width: "100%" };

    const sensitiveNonSensitiveData = [
        {
            value: data.sensitiveData,
            type: "square",
            id: "sensitiveData",
            color: additional_violette
        },
        {
            value: data.nonSensitiveData,
            type: "square",
            id: "nonSensitiveData",
            color: theme.palette.success[300]
        }
    ];

    const errorData = {
        value: data.errors,
        type: "square",
        id: "errors",
        color: theme.palette.critical[300]
    };

    const legendPayload = [...sensitiveNonSensitiveData, { ...errorData }];

    const labels = {
        sensitiveData: sensitiveName,
        nonSensitiveData: notSensitiveName,
        errors: "Errors"
    };

    const legendFormatter = (item, value) => {
        return shortNames ? (
            <Box component="span" sx={styles.legendLabel}>
                {labels[value.id]}
            </Box>
        ) : (
            <>
                <Box component="span" sx={styles.legendLabel}>
                    {labels[value.id]}:{" "}
                </Box>
                <Box component="span" sx={styles.legendValue}>
                    {value.value}
                </Box>
            </>
        );
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const activeData = payload.find(entry => entry.dataKey === currentlyHoveredBar);

            if (activeData) {
                const label = labels[activeData.name];

                return (
                    <Box sx={styles.tooltipWrapper}>
                        <Box component="span" sx={{ ...styles.tooltipSquare, backgroundColor: activeData.color }}></Box>
                        <StriimTypography variant="caption" sx={styles.tooltipName}>
                            {label}
                        </StriimTypography>
                        <StriimTypography variant="caption" sx={styles.tooltipValue}>
                            {activeData.value}
                        </StriimTypography>
                    </Box>
                );
            }
        }
    };

    return (
        <Grid container sx={shortNames ? styles.insightsChartContainer : {}}>
            <ResponsiveContainer height={80} {...containerProps}>
                <BarChart
                    width={barWidth}
                    height={80}
                    data={[{ ...data, ...backgroundBar }]}
                    layout="vertical"
                    margin={{ ...styles.barChartPosition, ...customBarChartMargins }}
                    barSize={barSize}
                >
                    <XAxis hide type="number" domain={[0, totalSources]} />
                    <YAxis hide type="category" />

                    <Bar
                        dataKey="sensitiveData"
                        stackId="single-stack"
                        fill={additional_violette}
                        radius={!nonSensitiveData && !errors && isFilled ? [0, 4, 4, 0] : null}
                        onMouseEnter={() => setCurrentlyHoveredBar("sensitiveData")}
                        onMouseLeave={() => setCurrentlyHoveredBar("")}
                    />
                    <Bar
                        dataKey="nonSensitiveData"
                        stackId="single-stack"
                        fill={theme.palette.success[200]}
                        radius={!errors && isFilled ? [0, 4, 4, 0] : null}
                        onMouseEnter={() => setCurrentlyHoveredBar("nonSensitiveData")}
                        onMouseLeave={() => setCurrentlyHoveredBar("")}
                    />
                    <Bar
                        dataKey="errors"
                        stackId="single-stack"
                        fill={theme.palette.critical[300]}
                        radius={isFilled ? [0, 4, 4, 0] : null}
                        onMouseEnter={() => setCurrentlyHoveredBar("errors")}
                        onMouseLeave={() => setCurrentlyHoveredBar("")}
                    />
                    <Bar
                        dataKey="totalSources"
                        stackId="single-stack"
                        fill={theme.palette.greyscale[100]}
                        radius={[0, 4, 4, 0]}
                    >
                        {[JobStatuses.CREATED, JobStatuses.RUNNING].includes(status) && (
                            <LabelList
                                dataKey="totalSources"
                                position="insideRight"
                                content={() => (
                                    <text
                                        x="99%"
                                        y="19%"
                                        fill={theme.palette.greyscale[700]}
                                        textAnchor="end"
                                        dominantBaseline="middle"
                                    >
                                        {`${completionPercentage.toFixed()}% completed`}
                                    </text>
                                )}
                            />
                        )}
                    </Bar>
                    {shortNames && <Tooltip content={<CustomTooltip />} cursor={false} />}
                    {!isReport && (
                        <Legend
                            payload={legendPayload}
                            verticalAlign={legendAlignment}
                            align="left"
                            height={36}
                            iconSize={11}
                            wrapperStyle={{ ...styles.legendWrapper, ...customLegendStyles }}
                            formatter={legendFormatter}
                            className={styles.customLegend}
                        />
                    )}
                </BarChart>
            </ResponsiveContainer>
        </Grid>
    );
};

export default JobInsightsChart;
