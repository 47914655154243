import App from "app";
import WAHighChartsMixin from "app/components/dashboard/visualization/charts/donutchart/mixins/donutchart-highcharts";
import "app/components/dashboard/visualization/charts/chart";

App.module("Chart.DonutChart", function (Chart, App, Backbone, Marionette, $, _) {
    Chart.View = App.Chart.Default.ChartView.extend({
        initialize: function () {
            // Mixin the Highcharts chart
            _(this).extend(WAHighChartsMixin);

            // Call super initialize
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
        },
    });
});
