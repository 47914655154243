import theme from "../../../../../../../../theme";

const Colors = {
    green: theme.palette.success.main,
    orange: theme.palette.warning.main,
    red: theme.palette.danger.main,
    blue: theme.palette.primary.main,
    gray: theme.palette.gray,
    purple: theme.palette.purple
};
const greenColorSequence = ["#006737", "#0D874C", "#C4EAD3", "#29BB6F", "#74D09B", "#9EDDB7", "#004726"];
const defaultColors = [Colors.green, Colors.blue, "#b5ed1a", "#00b3b7", "#42e066", "#007aa8", "#0097bc"];
const statusColors = {
    usage: Colors.blue,
    warning: Colors.orange,
    error: Colors.red,
    failure: Colors.red,
    failed: Colors.red,
    pending: Colors.gray,
    migrated: Colors.green,
    live: Colors.green,
    "not migrated": Colors.red,
    skipped: Colors.gray,
    done: Colors.green,
    "in progress": Colors.blue,
    "no changes": "#51B991",
    "with striim intelligence": Colors.purple,
    "striim intelligence": Colors.purple,
    "need manual changes": "#00CEF9",
    "cannot be migrated": "#FC6D73",
    needs: Colors.orange,
    compatible: Colors.green,
    incompatible: Colors.red,
    "compatible with striim intelligence": Colors.purple,
    edited: Colors.blue,
    excluded: Colors.gray
};
const getColor = (label, index) => {
    if (label && typeof label.toLowerCase === "function") {
        const statusColor = statusColors[label.toLowerCase()];

        if (statusColor) {
            return statusColor;
        }
    }
    return defaultColors[index % defaultColors.length];
};

export const colorSequenceOptions = {
    green: greenColorSequence
};

export default getColor;
