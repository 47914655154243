import App from "app";
import api from "core/api/api";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import statusManagement, { FlowStatus } from "src/status-management";
import growl from "app/components/common/growl";
import FlowMapView from "./flow-map-dropdown";
import navigateTo from "src/navigate-to";
import dialog from "app/components/common/dialogs/dialogWindow";
import ModalManager from "app/components/common/modal/ModalManager";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import appStatusSynchronizer from "core/appStatusSynchronizer";
import designerKeyboardSupport from "./designerKeyboardSupport";
import GraphNodesView from "./graphNodes/graphNodesView";
import PredefinedComponentsView from "./predefinedComponents/predefinedComponentsView";
import predefinedComponentsDataSource from "./predefinedComponents/predefinedComponentsDataSource";
import PredefinedComponentNames from "./predefinedComponents/predefinedComponentNames.json";
import SentinelSidebarView from "./sentinel/sentinel-sidebar";
import ToolbarGraphActions from "./toolbarGraphActions";
import { ExceptionsSidebarView, ExceptionsSidebarModel } from "./app-exceptions/exceptions-sidebar";
import DiscoverPIISidebarView from "./discover-pii/discover-pii-sidebar";
import exceptionStoreApi from "./app-exceptions/exception-store-api";
import Sidebar from "./sidebar";
import AppSettings from "./appSettings";
import localErrorsStorage from "./appErrors/localErrorsStorage";
import appErrorsDataSource from "./appErrors/appErrorsDataSource";
import AppErrors from "./appErrors/appErrors";
import buildValidationErrorsView from "./data-type-validation/errors/validation-errors";
import AppExceptions from "./appErrors/appExceptions";
import MyObjects from "./myObjects";
import NewComponentView from "./newComponentView";
import AppStatusManagement from "./appStatusManagement";
import RateCounter from "./rateCounter";
import PreviewDataView from "./previewDataView";
import { clearByApplication } from "./nodesPositionStorage";
import dropApplication from "./dropApplication";
import lineageMetadataManagerProvider from "./lineageMetadata/lineageMetadataManagerProvider";
import designerPasteAction from "./designer-paste-action";
import designerTemplate from "./templates/designerTemplate.html";
import ProgressView from "./progressView";
import headerStore from "../../../../src/stores/header";
import localStore from "../../../../src/modules/message-log/message-log.store";
import AppControl from "app/components/common/app-control/app-control";
import MarionetteWrapper from "../../marionette-wrapper-for-react";
import HaltExceptionMessageBox from "../../../../src/generic/message-box/message-box";
import ensureRegions from "../../common/_ensureRegions";
import FlowBackButton from "../../../../src/modules/flow-designer/components/flowBackButton";
import appListService from "../../../../src/modules/apps/pages/app-list/app-list-service";
import SentinelLiveMonitorView from "./sentinel-live-monitor/sentinel-live-monitor";

var Designer = {};

Designer.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(designerTemplate),
    tagName: "div",
    className: "flow-designer-root",
    attributes: function() {
        return {
            "data-test-id": "flow-designer-page"
        };
    },
    // is adding new node
    _inInNewNodeMode: false,

    // is designer enabled
    _isEnabled: true,

    regions: {
        flowDesignerContainer: "#flowDesignerContainer",
        leftToolbarArea: "#leftToolbarArea",
        leftMenu: ".left-menu",
        toolbarGraphMenu: ".toolbar-graph-menu",
        sidebar: ".main.sidebar",
        myObjectsSidebar: "#myObjectsSidebar",
        appStatusManagement: "div.app-status",
        previewData: "div.preview-data",
        progressBar: "#progressBar",
        exceptionMsgContainer: "#exception-msg-box",
        backButton: "#flowBackButton"
    },

    ui: {
        appStatusManagement: "div.app-status",
        toolbarGraphMenu: ".toolbar-graph-menu",
        flowGraphArea: ".right-content",
        sidebar: ".main.sidebar",
        myObjectsSidebar: "#myObjectsSidebar",
        leftMenu: ".left-menu",
        flow: "> .flow"
    },

    initialize: function() {
        var _this = this;
        this.modalManager = new ModalManager({
            container: this.$el,
            freeze: false
        });
        this.isAutomatedGroup = this.options.group?.groupType === "ILCDC" ?? false;
        this.rateCounter = new RateCounter();
        this.rateCounter.model = this.model;
        this.rateCounter.start();
        lineageMetadataManagerProvider.init();
        this.listenTo(this.model, "change:nodes", function() {
            var selectedNodes = _this.model.nodes.getSelectedNodes();
            this.ui.flow.toggleClass("node-selected", selectedNodes.length > 0);
        });

        this.sentinelSidebarHasUnsavedChanges = false;

        this.appControl = new AppControl({
            appIdentifier: this.model.app
        });

        this.listenTo(App.vent, "edit:component", this.showComponent.bind(this));
        headerStore.addToLatestApps(this.model.app.toJSON());
        this.listenTo(App.vent, "compilation-needed", notify => {
            api.compile(this.model.app.name).then(
                compilationResult => {
                    if (notify) {
                        growl.success(compilationResult, "Compilation");
                    }
                    if (this._inInNewNodeMode) {
                        // when adding new component and saving a type graph is rendered again
                        // because components could be displayed in invalid state and
                        // they need to be refreshed. During this action, the new component is removed.

                        // When _inInNewNodeMode the graph won't be rendered again.
                        // There is a small chance that someone break/fix the nodes.

                        // The proper solution would be to not remove new node from graph when refreshing
                        return;
                    }
                    this.reloadCurrentFlow();
                },
                compilationResult => {
                    growl.error(compilationResult, "Compilation failed");
                    // when adding new component and saving a type graph is rendered again
                    // because components could be displayed in invalid state and
                    // they need to be refreshed. During this action, the new component is removed.

                    // When _inInNewNodeMode the graph won't be rendered again.
                    // There is a small chance that someone break/fix the nodes.

                    // The proper solution would be to not remove new node from graph when refreshing
                    if (this._inInNewNodeMode) {
                        return;
                    }
                    this.reloadCurrentFlow();
                }
            );
        });

        this._lastSaveApplicationStatus = null;

        this.listenTo(
            appStatusSynchronizer,
            this.model.app.id,
            function(app_status) {
                if (!window.location.href.includes("#/flow")) {
                    return;
                }

                _this.showApplicationProgress(app_status);
                this.toolbarGraphActionsView.toggleElementsEnabled(app_status.toUpperCase());

                if (_this._lastSaveApplicationStatus !== null && _this._lastSaveApplicationStatus !== app_status) {
                    this._setLeftSidebarVisibilityForStatus(app_status);
                }

                this.graphNodesView.toggleRecentDataVisibility(app_status);
                this.predefinedComponentsPanel.setAppStatus(app_status);
                this.flowMapView.setAppStatus(app_status);

                if (this._isEditingSidebarVisible()) {
                    this.sidebar.currentView.setAppStatus(app_status);
                }
                statusManagement.setAppStatusClassOnNode(this.ui.flow, app_status);

                if (this.sidebar.currentView && this.sidebar.currentView.shouldCloseOnStatusChange) {
                    this._hideSideBarElement(true);
                }

                this.notifyHaltedException(app_status);

                if (app_status === FlowStatus.RUNNING) {
                    localErrorsStorage.clear(this.model.app);
                    _this.loadAppErrorsFromServer();
                }

                if (app_status !== FlowStatus.CREATED) {
                    if (
                        statusManagement.isApplicationCrash(app_status) ||
                        statusManagement.isApplicationFailedOnDeployment(app_status)
                    ) {
                        if (_this._lastSaveApplicationStatus !== app_status) {
                            _this.loadAppErrorsFromServer();
                        }
                    } else {
                        this.loadAppErrorsFromLocalStorage(this.model);
                    }
                } else {
                    this._closePreviewData();
                }

                if (
                    this._lastSaveApplicationStatus === FlowStatus.CREATED ||
                    app_status === FlowStatus.CREATED ||
                    this._lastSaveApplicationStatus === FlowStatus.DEPLOY_FAILED ||
                    app_status === FlowStatus.DEPLOY_FAILED
                ) {
                    this._hideMyObjects();
                }

                _this._lastSaveApplicationStatus = app_status;

                if (!this.previewData.hasView()) {
                    return;
                }

                var selectedNodes = _this.model.nodes.getSelectedNodes();
                if (selectedNodes.length === 0) {
                    return;
                }

                if (_this._lastSaveApplicationStatus !== null && _this._lastSaveApplicationStatus !== app_status) {
                    if (statusManagement.isApplicationRunning(app_status)) {
                        this._reloadPreviewDataView(selectedNodes[0]);
                    }
                }
            }.bind(this)
        );

        this.listenTo(
            App.vent,
            "TQL-full-screen",
            function(isInFullscreen) {
                if (isInFullscreen) {
                    this.lockDesigner();
                    this._setLeftSidebarVisibility(true);
                } else {
                    this.unlockDesigner();
                    this._setLeftSidebarVisibilityForStatus(this._lastSaveApplicationStatus);
                }
            }.bind(this)
        );

        //used when editor change node
        this.listenTo(App.vent, "node-selected", function(nodeId) {
            _this.graphNodesView.unselectAllNodes();
            var node = _this.model.nodes.byId(nodeId);
            if (node) {
                _this.showSidebar(node);
                node.isSelected = true;
            }
        });

        localStore.setComponent(this.model.app.id);
    },

    notifyHaltedException: function(app_status) {
        let status = app_status || this.model.app.get("flowStatus");
        if (status === FlowStatus.HALT) {
            const haltMsgBox = MarionetteWrapper(HaltExceptionMessageBox, {
                onResumeClick: this.appControl.resume.bind(this.appControl),
                onViewDtlsClick: this.handleShowAppErrors.bind(this),
                isAutomatedGroup: this.isAutomatedGroup,
                moveToAppGroups: this.moveToAppGroups.bind(this)
            });

            this.getRegion("exceptionMsgContainer").show(haltMsgBox);
        } else {
            this.getRegion("exceptionMsgContainer").empty();
        }
    },

    onBeforeDestroy: function() {
        this.rateCounter.stop();
        this.rateCounter = null;
    },

    showApplicationProgress: function(app_status) {
        try {
            const OK_STATUSES = ["RUNNING", "QUIESCING", "STOPPING", "QUIESCED", "STOPPED", "COMPLETED"];
            if (!app_status) {
                app_status = this.model.app.flowStatus;
            }
            if (OK_STATUSES.indexOf(app_status) !== -1 && this.getRegion("progressBar").currentView === undefined) {
                this.getRegion("progressBar").show(new ProgressView.LiteView({ model: this.model }));
                this.ui.flowGraphArea.addClass("with-progress-bar");
            } else {
                this.getRegion("progressBar").empty();
                this.ui.flowGraphArea.removeClass("with-progress-bar");
            }
        } catch (e) {
            console.error("Error adding app status", e);
        }
    },
    /**
     *
     * @param app_status
     * @param forceReload - should reload sidebar, default true
     * @private
     */
    _setLeftSidebarVisibilityForStatus: function(app_status, forceReload) {
        var isSidebarHiddenForStatus =
            statusManagement.isApplicationRunning(app_status) ||
            statusManagement.isDeployedState(app_status) ||
            statusManagement.isApplicationCrash(app_status) ||
            statusManagement.isTransientStatus(app_status);
        this._setLeftSidebarVisibility(isSidebarHiddenForStatus, forceReload);
    },

    _setLeftSidebarVisibility: function(isHidden, forceReload) {
        if (typeof forceReload === "undefined") {
            forceReload = true;
        }

        this.ui.leftMenu.toggleClass("hidden", isHidden);
        this.$el.find(".empty-view").toggleClass("hidden", isHidden);
        if (!isHidden) {
            // Removes the dynamically added no-components-flow content
            this.$el.find(".no-components-flow").remove();
        } else {
            // Checks if no-components-flow is already present
            if (this.$el.find(".no-components-flow").length === 0) {
                const noComponentsFlow = `
                <div class="no-components-flow">
                  <img src="src/generic/icon/manage-striim/03-Apps-CreateApps.svg" width="96" />
                  <h2>This Flow does not contain any Components.</h2>
                </div>
              `;
                // Appends the new element as a sibling
                this.$el
                    .find(".empty-view")
                    .parent()
                    .append(noComponentsFlow);
            }
        }
        if (isHidden === false && this.predefinedComponentsPanel) {
            if (forceReload) {
                this.predefinedComponentsPanel.refresh();
            }
        }
        this.ui.flowGraphArea.toggleClass("with-left-menu", !isHidden);
        const navbarWidth = $("#striim-navbar").width();
        //width of the left side menu in FD
        const leftMenuWidth = 267;
        this.ui.flowGraphArea.css("left", !isHidden ? navbarWidth + leftMenuWidth : navbarWidth);
    },

    sidebarHasUnsavedChanges: function() {
        return (
            this.sidebar.currentView &&
            typeof this.sidebar.currentView.isDirty === "function" &&
            this.sidebar.currentView.isDirty()
        );
    },

    promptIfUnsavedChanged: function() {
        var deferred = $.Deferred();
        // we need to focusout the input fields because otherwise the event is not triggered
        $(".cancel").focus();

        if (!this.sentinelSidebarHasUnsavedChanges && !this.sidebarHasUnsavedChanges()) {
            deferred.resolve();
            return deferred.promise();
        }
        var message = "You have unsaved changes. Do you want to proceed?";
        dialog.confirm(message, this.modalManager).then(
            function(result) {
                if (result) {
                    this.sentinelSidebarHasUnsavedChanges = false;
                    deferred.resolve();
                } else {
                    deferred.reject();
                }
            }.bind(this)
        );

        return deferred.promise();
    },

    initializeGraphNodesViewRegion: function() {
        var _this = this;

        this.graphNodesView = new GraphNodesView({
            model: this.model,
            collection: this.model.nodes
        });
        this.flowDesignerContainer.show(this.graphNodesView);
        this.graphNodesView.toggleRecentDataVisibility(this.model.app.flowStatus);

        // TODO maybe this can be implemented better, but only .right-content wraps full graph, e.g. flow-designer-container doesn't when graph is scrolled
        // we use unbind and bind with namespace because otherwise jquery duplicates event binding
        // for each initializeGraphNodesViewRegion (right-content is outside of it,
        // so it's not destoryed with the view)
        this.ui.flowGraphArea.unbind("click.designer-close").bind(
            "click.designer-close",
            "click",
            function(event) {
                if (this.$(".flow-designer-container").is(":visible")) {
                    this._onDesignerClick(event);
                }
            }.bind(this)
        );

        //
        // graph nodes view
        //
        this.listenTo(
            this.graphNodesView,
            "node-selected",
            function(node) {
                this.promptIfUnsavedChanged().then(function() {
                    _this.graphNodesView.unselectAllNodes(node.id);
                    node.isSelected = true;
                    _this.graphNodesView.graphNodesHighlighting.highlightConnection(node);

                    _this.showSidebar(node);
                    designerKeyboardSupport.focus();

                    if (!_this.previewData.hasView()) {
                        return;
                    }
                    if (
                        statusManagement.isApplicationRunning(_this.model.app.flowStatus) &&
                        node.metaObject.get("type") === metaStoreService.entities.STREAM
                    ) {
                        _this._reloadPreviewDataView(node);
                    } else {
                        _this._closePreviewData();
                    }
                });
            }.bind(this)
        );
        this.listenTo(this.graphNodesView, "flow-node-click", this.reloadFlowGraphWithContextRefresh.bind(this));
        this.listenTo(this.graphNodesView, "connect-new-node", this._connectNewComponent.bind(this));
        this.listenTo(this.graphNodesView, "preview-on-run", this._previewData.bind(this));
        this.listenTo(
            this.graphNodesView,
            "new-node-created",
            function() {
                this._inInNewNodeMode = true;
                this.lockDesigner();
            }.bind(this)
        );
    },

    initializeFlowMapView: function() {
        this.flowMapView = new FlowMapView({
            model: this.model,
            modalManager: this.modalManager
        });
        this.getRegion("leftToolbarArea").show(this.flowMapView);
        this.listenTo(this.flowMapView, "flow-node-click", this.reloadFlowGraphWithContextRefresh.bind(this));
        this.listenTo(this.flowMapView, "new-flow", this.reloadFlowGraphWithContextRefresh.bind(this));
    },

    onRender: function() {
        var _this = this;

        this._setLeftSidebarVisibilityForStatus(this.model.app.flowStatus);
        statusManagement.setAppStatusClassOnNode(this.ui.flow, this.model.app.flowStatus);
        this.initializeGraphNodesViewRegion();
        this.initializeFlowMapView();

        _this._lastSaveApplicationStatus = this.model.app.flowStatus;

        //
        // draggable nodes panel
        //
        var predefinedComponentsCollection = predefinedComponentsDataSource.load();
        this.predefinedComponentsPanel = new PredefinedComponentsView({
            collection: predefinedComponentsCollection,
            model: this.model
        });
        this.getRegion("leftMenu").show(this.predefinedComponentsPanel);
        this.predefinedComponentsPanel.setAppStatus(this.model.app.flowStatus);
        this._setLeftSidebarVisibilityForStatus(this.model.app.flowStatus);
        this.listenTo(
            this.predefinedComponentsPanel,
            "start-drag",
            function(dndType, dndComponentName) {
                this.graphNodesView.dragDropHandler.activateDragDrop(dndType, dndComponentName);
                this.$el.addClass("dragDropActive");
            }.bind(this)
        );
        this.listenTo(
            this.predefinedComponentsPanel,
            "end-drag",
            function() {
                this.graphNodesView.dragDropHandler.deactivateDragDrop();
                this.$el.removeClass("dragDropActive");
            }.bind(this)
        );

        // For Automated Apps, Show back button
        if (this.isAutomatedGroup) {
            const BackButton = MarionetteWrapper(FlowBackButton, { moveToAppGroups: this.moveToAppGroups.bind(this) });
            this.getRegion("backButton").show(BackButton);
        }

        //
        // toolbar
        //
        this.toolbarGraphActionsView = new ToolbarGraphActions({
            model: this.model,
            isAutomatedGroup: this.isAutomatedGroup,
            parentView: this,
            moveToAppGroups: this.moveToAppGroups.bind(this)
        });
        this.getRegion("toolbarGraphMenu").show(this.toolbarGraphActionsView);
        this.listenTo(
            this.toolbarGraphActionsView,
            "node-delete",
            function(/*node*/) {
                var selectedNodes = this.graphNodesView.getSelectedNodes();
                if (selectedNodes.length === 0) {
                    dropApplication(_this.modalManager, this.model.current_app_full).then(function(result) {
                        if (!result) {
                            return;
                        }
                        var fullAppName = _this.model.current_app_full;
                        App.vent.trigger("app:deleted", _this.model.app.id);

                        setTimeout(function() {
                            if (
                                _this.model.get("app").get("exceptionstoreName") !== null &&
                                !result.doNotDeleteExceptions
                            ) {
                                // drop exceptionstore and then drop the app
                                exceptionStoreApi
                                    .deleteLogs(_this.model.app.id)
                                    .then(
                                        result => {
                                            if (result !== true) {
                                                console.warn("cannot delete logs for " + _this.model.app.id, result);
                                            }
                                            growl.success("Success", "ExceptionStore Deleted.");
                                        },
                                        () => {
                                            growl.error("There was a problem deleting the Exception Store.", "Error");
                                        }
                                    )
                                    .finally(() => {
                                        api.dropObject({
                                            type: "application",
                                            name: fullAppName
                                        })
                                            .then(
                                                () => {
                                                    growl.success("Application Dropped.", "Success!");
                                                },
                                                e => {
                                                    const errorMsg =
                                                        "Unable to drop app! " +
                                                        e.message +
                                                        ". Resolve the errors before retry or force drop app. Contact Striim support if problem persists.";
                                                    growl.error(errorMsg, "Error");
                                                }
                                            )
                                            .always(() => {
                                                navigateTo.Apps();
                                            });
                                    });
                            } else {
                                api.dropObject({
                                    type: "application",
                                    name: fullAppName
                                })
                                    .then(
                                        () => {
                                            growl.success("Application Dropped.", "Success!");
                                        },
                                        e => {
                                            const errorMsg =
                                                "Unable to drop app! " +
                                                e.message +
                                                ". Resolve the errors before retry or force drop app. Contact Striim support if problem persists.";
                                            growl.error(errorMsg, "Error");
                                        }
                                    )
                                    .always(() => {
                                        navigateTo.Apps();
                                    });
                            }

                            // api.dropObject({
                            //     type: "application",
                            //     name: fullAppName
                            // }).done(function() {
                            //     if (_this.model.get('app').get('exceptionstoreName') !== null && !result.doNotDeleteExceptions) {
                            //         exceptionStoreApi.deleteLogs(_this.model.app.id).then((result) => {
                            //             if (result !== true) {
                            //                 console.warn('cannot delete logs for ' + _this.model.app.id, result);
                            //             }
                            //             growl.success("", "Application deleted.");
                            //             NavigateTo.Apps();
                            //         }).fail((e)=>{console.log(e)});
                            //     } else {
                            //         growl.success("", "Application deleted.");
                            //         NavigateTo.Apps();
                            //     }
                            // }).fail(function() {
                            //     growl.error("Application drop failed!", "Error");
                            // });
                        }, 500);
                    });

                    return;
                }

                this._deleteSelectedNodes();
            }.bind(this)
        );

        function cutCopyNode(operation, callback) {
            let message;

            let selectedNodes = _this.graphNodesView.getSelectedNodes();
            if (selectedNodes.length === 0) {
                return;
            }

            _this.graphNodesView.unselectAllNodes();
            if (selectedNodes.length > 0) {
                this._hideSideBarElement();
                this._hideMyObjects();

                if (selectedNodes.length === 1) {
                    message = `Component ${operation} successfully`;
                } else {
                    message = selectedNodes.length + ` components ${operation} successfully`;
                }

                growl.success(message);
            }

            callback.call(_this, selectedNodes);
        }

        this.listenTo(
            this.toolbarGraphActionsView,
            "nodes-cut",
            cutCopyNode.bind(this, "cut", nodes => _this.model.nodesClipboard.cut(nodes))
        );

        this.listenTo(
            this.toolbarGraphActionsView,
            "nodes-copy",
            cutCopyNode.bind(this, "copied", nodes => _this.model.nodesClipboard.copy(nodes))
        );

        this.listenTo(this.toolbarGraphActionsView, "nodes-paste", function() {
            _this.graphNodesView.unselectAllNodes();

            let clipboard = _this.model.nodesClipboard;
            if (clipboard.copied) {
                this.graphNodesView.pasteNode(clipboard);
            } else {
                designerPasteAction(
                    _this.model.currentFlowId,
                    _this.model.app,
                    clipboard,
                    _this.model.nodes,
                    _this.modalManager
                ).then(_this.reloadCurrentFlow.bind(_this));
            }
        });

        this.listenTo(
            this.toolbarGraphActionsView,
            "show-app-settings",
            function() {
                this.promptIfUnsavedChanged().then(function() {
                    // deploymentInfo can be out of date. It should be restored from server
                    metaStoreService.findById(_this.model.app.id).then(function(app) {
                        _this.model.app.deploymentInfo = app.deploymentInfo;
                        _this.showAppSettings(_this.model);
                    });
                });
            }.bind(this)
        );

        this.listenTo(
            this.toolbarGraphActionsView,
            "show-app-exceptions",
            function(hasExceptions) {
                this.promptIfUnsavedChanged().then(
                    function() {
                        this.resetSidebarAndFlow();
                        this._showSideBarElement(_this.model);
                        const exceptionsView = new ExceptionsSidebarView({
                            model: new ExceptionsSidebarModel({
                                app: this.model.app,
                                hasExceptions: hasExceptions
                            })
                        });
                        this.listenTo(exceptionsView, "cancel", this._hideSideBarElement.bind(this));
                        this.listenTo(exceptionsView, "refreshcounter", () => {
                            setTimeout(() => {
                                this.toolbarGraphActionsView._updateExceptionIcon();
                            }, 3000);
                        });
                        this.sidebar.show(exceptionsView);
                    }.bind(this)
                );
            }.bind(this)
        );

        this.listenTo(
            this.toolbarGraphActionsView,
            "my-objects",
            function() {
                var myObjectsVisible = _this.myObjectsSidebar && _this.myObjectsSidebar.currentView;
                if (myObjectsVisible) {
                    _this._hideMyObjects();
                } else {
                    _this.showMyObjects();
                }
            }.bind(this)
        );
        this.listenTo(
            this.toolbarGraphActionsView,
            "graph-autolayout",
            function() {
                clearByApplication(this.model.app.id);
                this.graphNodesView.runAutoLayout();
            }.bind(this)
        );

        this.listenTo(
            this.toolbarGraphActionsView,
            "discover-pii",
            function() {
                this.showDiscoverPII(this.model);
            }.bind(this)
        );

        this.listenTo(
            this.toolbarGraphActionsView,
            "sentinel-live-monitor",
            function() {
                this.showSentinelLiveMonitor(this.model, null, this, this.showSentinel);
            }.bind(this)
        );

        // app status management
        this.appStatusManagementView = new AppStatusManagement({
            model: this.model,
            isAutomatedGroup: this.isAutomatedGroup,
            moveToAppGroups: this.moveToAppGroups.bind(this)
        });
        this.appStatusManagementView.onBeforeDeploy = function() {
            var def = $.Deferred();
            if (!_this.sentinelSidebarHasUnsavedChanges && !_this.sidebarHasUnsavedChanges()) {
                def.resolve();
                return def.promise();
            }
            dialog.alert(
                "There are unsaved changes. Please save or cancel changes before proceeding.",
                _this.modalManager
            );
            def.reject();
            return def.promise();
        };

        this.getRegion("appStatusManagement").show(this.appStatusManagementView);

        this.listenTo(this.appStatusManagementView, "show-app-errors", _this.handleShowAppErrors.bind(this));
        // this.listenTo(this.graphNodesView, "show-app-errors", _this.handleShowAppErrors.bind(this));
        this.listenTo(this.appStatusManagementView, "show-validation-errors", () => {
            buildValidationErrorsView(this.model).then(validationErrorsView => {
                if (!validationErrorsView) {
                    return;
                }

                this.graphNodesView.unselectAllNodes();
                this._hideSideBarElement();
                this._showSideBarElement(this.model);

                this.sidebar.show(validationErrorsView);
                /* TODO: navigate to validation errors navigateTo.AppErrors(this.model.current_app_full); */
            });
        });

        this.listenTo(App.vent, "metadata-browser:close", function() {
            _this._hideMyObjects();
        });

        this.showApplicationProgress();
    },

    moveToAppGroups: function() {
        appListService.setSelectedGroup(this.options.group);
        navigateTo.Apps();
    },

    _showAppErrors: function() {
        var _this = this;
        this.promptIfUnsavedChanged().then(function() {
            _this.showAppErrors(_this.model);
        });
    },

    showDiscoverPII: function(model, jobName) {
        var _this = this;
        this.resetSidebarAndFlow();
        this._showSideBarElement(model);
        const discoverPIIView = new DiscoverPIISidebarView({
            model,
            jobName
        });
        this.sidebar.show(discoverPIIView);
        navigateTo.AppSherlock(this.model.current_app_full, jobName);

        this.listenTo(
            discoverPIIView,
            "discover-pii-cancel",
            function() {
                this._hideSideBarElement();
                this.$el.find(".right-sidebar").removeClass("full-screen");
            }.bind(this)
        );

        this.listenTo(
            discoverPIIView,
            "before:destroy",
            function() {
                this.$el.find(".right-sidebar").removeClass("full-screen");
            }.bind(this)
        );

        this.listenTo(
            discoverPIIView,
            "discover-pii-toggle",
            function() {
                this.$el.find(".right-sidebar").toggleClass("full-screen");
            }.bind(this)
        );

        this.listenTo(discoverPIIView, "new-sentinel-selected", async function(node) {
            node.outputStreamNode.isSelected = true;
            var newNode = this.graphNodesView.addNodeToSelectedNode("SENTINEL");
            _this._hideSideBarElement();
            _this.graphNodesView.unselectAllNodes();
            newNode.isSelected = true;
            newNode.targetsToBeConnectedWithSentinel = newNode.targetsToBeConnectedWithSentinel || [];
            newNode.targetsToBeConnectedWithSentinel.push(
                ...node.outputStreamNode.targets.filter(target => target !== "temp-node")
            );
            newNode.entityDataFromSource = node.entityDataFromSource;
            _this.graphNodesView.selectNode(newNode);
        });
    },

    showSentinelLiveMonitor: function(model, sentinelName, context = this, onViewSettingsClick = this.showSentinel) {
        var _this = this;
        if (!_this) {
            _this = context;
        }
        _this.resetSidebarAndFlow();
        _this._showSideBarElement(model);

        const sentinelLiveMonitorView = new SentinelLiveMonitorView({
            model,
            sentinelName,
            appModel: _this.model.app,
            onViewSettingsClick: selectedSentinel => {
                const sentinelFQN = selectedSentinel?.split(".")?.join(".SENTINEL.");
                const currentSentinelNodeModel = _this.model?.nodes?.find(node => node?.id === sentinelFQN);
                onViewSettingsClick(currentSentinelNodeModel, _this.showSentinelLiveMonitor, _this); //onViewSettingsClick => showSentinel method
            }
        });
        _this.sidebar.show(sentinelLiveMonitorView);
        navigateTo.AppSentinelLiveMonitor(_this.model.current_app_full, sentinelName, false);

        _this.listenTo(
            sentinelLiveMonitorView,
            "sentinel-live-monitor-close",
            function() {
                _this._hideSideBarElement();
                _this.$el.find(".right-sidebar").removeClass("full-screen");
            }.bind(this)
        );

        _this.listenTo(
            sentinelLiveMonitorView,
            "before:destroy",
            function() {
                _this.$el.find(".right-sidebar").removeClass("full-screen");
            }.bind(this)
        );

        _this.listenTo(
            sentinelLiveMonitorView,
            "sentinel-live-monitor-toggle",
            function() {
                _this.$el.find(".right-sidebar").toggleClass("full-screen");
            }.bind(this)
        );
    },

    showSentinel: function(nodeModel, onViewActivityClick, context) {
        var _this = this;
        if (!_this) {
            _this = context;
        }
        const sentinelView = new SentinelSidebarView({
            appModel: _this.model.app,
            nodeModel: nodeModel,
            onViewActivityClick: sentinelName =>
                onViewActivityClick(_this.model, sentinelName, _this, _this.showSentinel) //onViewActivityClick => showSentinelLiveMonitor method
        });
        _this.sidebar.show(sentinelView);

        _this.listenTo(
            sentinelView,
            "cancel-sentinel",
            function() {
                _this.promptIfUnsavedChanged().then(
                    function() {
                        _this.resetSidebarAndFlow();
                    }.bind(this)
                );
            }.bind(this)
        );

        _this.listenTo(sentinelView, "delete-sentinel", _this._deleteSelectedNodes.bind(this));

        _this.listenTo(
            sentinelView,
            "before:destroy",
            function() {
                _this.ui.sidebar.removeClass("full-screen");
            }.bind(this)
        );

        _this.listenTo(
            sentinelView,
            "full-screen-sentinel",
            function() {
                _this.ui.sidebar.toggleClass("full-screen");
            }.bind(this)
        );

        _this.listenTo(
            sentinelView,
            "isDirty",
            function(isDirty) {
                _this.sentinelSidebarHasUnsavedChanges = isDirty;
            }.bind(this)
        );

        _this.listenTo(
            sentinelView,
            "sentinel-save",
            function(savedNode) {
                api.compile(_this.model.app.name)
                    .then(_this.reloadCurrentFlow.bind(_this), function(error) {
                        growl.error(error, "Error Saving your component.");
                    })
                    .then(function() {
                        localErrorsStorage.clear(_this.model.app);

                        if (!savedNode) {
                            return;
                        }

                        var selectedNode = _this.model.nodes.byId(savedNode.id);
                        if (selectedNode) {
                            var metaObjectName = selectedNode.metaObject.name;
                            if (_this.isShowingMainFlow()) {
                                navigateTo.EditNode(_this.model.current_app_full, metaObjectName);
                            } else {
                                navigateTo.EditFlowNode(
                                    _this.model.current_app_full,
                                    MetaObjectConverter.getName(_this.model.currentFlowId),
                                    metaObjectName
                                );
                            }
                            if (_this._inInNewNodeMode && !_.isEmpty(selectedNode.targets)) {
                                var difTargets = _.difference(selectedNode.targets, savedNode.nodeTargets);
                                if (difTargets && difTargets.length === 1) {
                                    selectedNode = _this.model.nodes.byId(selectedNode.targets[0]);
                                }
                            }
                            if (selectedNode) {
                                selectedNode.isSelected = true;
                            }
                        }

                        App.vent.trigger("node-selected", savedNode.id);
                        const updateTargets = async () => {
                            const promises = nodeModel.targetsToBeConnectedWithSentinel.map(async targetId => {
                                const targetModel = await metaStoreService.findById(targetId);
                                targetModel.set("inputStream", savedNode.outputStream);
                                return targetModel.save({ flow: _this.model.app.name });
                            });

                            await Promise.all(promises);
                            _this.reloadCurrentFlow();
                        };
                        updateTargets();
                    })
                    .fail(_this.reloadCurrentFlow.bind(_this));
            }.bind(this)
        );
    },

    _deleteSelectedNodes: function() {
        var _this = this;

        this.graphNodesView
            .deleteSelectedNodes()
            .then(
                function() {
                    _this._inInNewNodeMode = false;
                    _this._hideSideBarElement();
                    api.compile(_this.model.app.name).always(_this.reloadCurrentFlow.bind(_this));
                },
                function(e) {
                    if (!e) {
                        return;
                    }
                    growl.error("Components drop failed! " + e.message + "", "Error");
                }
            )
            .always(function() {
                _this.unlockDesigner();
            });
    },

    reloadCurrentFlow: function() {
        return this.reloadFlowGraph({
            metaObject: {
                id: this.model.currentFlowId
            }
        });
    },

    onShow: function() {
        App.vent.trigger("designer:show");
        designerKeyboardSupport.initialize(this.model, this.graphNodesView);
        this.notifyHaltedException();
    },

    _onDesignerClick: function(e) {
        // if new node was added to designer,
        // then cannot unselect by clicking on designer
        // user have to explicit click on sidebar CANCEL
        if (this.graphNodesView.isTempNodeAdded()) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        if (this.graphNodesView.dragDropHandler.isSelecting) {
            return;
        }
        this.promptIfUnsavedChanged().then(
            function() {
                this.graphNodesView.unselectAllNodes();
                this._hideSideBarElement();
                this.unlockDesigner();
            }.bind(this)
        );
    },

    isShowingMainFlow: function() {
        var flowName = MetaObjectConverter.getName(this.model.currentFlowId);
        var appName = MetaObjectConverter.getName(this.model.app.name);
        return appName === flowName;
    },

    reloadFlowGraphWithContextRefresh: function(node) {
        this._hideSideBarElement();
        this._hideMyObjects();
        return this.reloadFlowGraph(node);
    },

    /**
     *
     * @param node - node used to select subflow
     */
    reloadFlowGraph: function(node) {
        this.model.currentFlowId = node !== null ? node.metaObject.id : this.model.datasource.app.id;

        var result = $.Deferred();

        var scrollTop = this.ui.flowGraphArea[0].scrollTop;

        this.model.datasource.invalidateCache();
        this.model.datasource.load().then(
            function() {
                if (this.isDestroyed) {
                    return;
                }

                this.model.nodes = this.model.datasource.getObjectsForFlowId(this.model.currentFlowId);
                this.model.app = this.model.datasource.app;
                var status = appStatusSynchronizer.getStatus(this.model.app.id);
                if (status) {
                    this.model.app.flowStatus = status;
                }

                var rootNode = this.model.datasource.findNodeById(this.model.currentFlowId);
                if (rootNode) {
                    this.model.currentFlowName = rootNode.metaObject.name;
                }

                this.initializeGraphNodesViewRegion();
                this.initializeFlowMapView();

                App.vent.trigger("designer:show");

                this.ui.flowGraphArea[0].scrollTop = scrollTop;

                if (!node) {
                    navigateTo.App(this.model.current_app_full);
                }

                if (node && node.attributes) {
                    navigateTo.Subflow(this.model.current_app_full, node.attributes.metaObject.name);
                }

                this._inInNewNodeMode = false;
                this.unlockDesigner();

                result.resolve();
            }.bind(this)
        );

        return result;
    },

    _connectNewComponent: function(model) {
        var newComponentView = new NewComponentView();
        model.isSelected = true;

        var _this = this;

        this._hideSideBarElement();
        this._showSideBarElement(model);

        this.listenTo(newComponentView, "new-component-selected", function(componentType) {
            var newNode = this.graphNodesView.addNodeToSelectedNode(componentType);
            _this._hideSideBarElement();
            _this.graphNodesView.unselectAllNodes();
            newNode.isSelected = true;
            _this.graphNodesView.selectNode(newNode);
        });

        this.sidebar.show(newComponentView);
        newComponentView.updateCollection(model.metaObject.type);
    },

    _previewData: function(model) {
        ensureRegions(this);
        this._hideSideBarElement();
        this._reloadPreviewDataView(model);
    },

    resetSidebarAndFlow: function(hideMyObjects = true) {
        ensureRegions(this);
        this._hideSideBarElement(hideMyObjects);
        this._setLeftSidebarVisibilityForStatus(this._lastSaveApplicationStatus, false);
        this.reloadCurrentFlow();
    },

    showSidebar: function(model) {
        var _this = this;

        this._showSideBarElement(model);

        var sidebarView = new Sidebar.View({
            flowId: this.model.currentFlowId,
            app: this.model.app,
            model: model
        });

        this.listenTo(sidebarView, "preview-data", this._previewData.bind(this));
        this.listenTo(sidebarView, "clear-and-reload", () => localErrorsStorage.clear(this.model.app));

        this.listenTo(
            sidebarView,
            "sidebar-save",
            function(savedNode) {
                api.compile(_this.model.app.name)
                    .then(_this.reloadCurrentFlow.bind(_this), function(error) {
                        growl.error(error, "Error Saving your component.");
                    })
                    .then(function() {
                        localErrorsStorage.clear(_this.model.app);

                        if (!savedNode) {
                            return;
                        }

                        var selectedNode = _this.model.nodes.byId(savedNode.id);
                        if (selectedNode) {
                            var metaObjectName = selectedNode.metaObject.name;
                            if (_this.isShowingMainFlow()) {
                                navigateTo.EditNode(_this.model.current_app_full, metaObjectName);
                            } else {
                                navigateTo.EditFlowNode(
                                    _this.model.current_app_full,
                                    MetaObjectConverter.getName(_this.model.currentFlowId),
                                    metaObjectName
                                );
                            }
                            if (_this._inInNewNodeMode && !_.isEmpty(selectedNode.targets)) {
                                var difTargets = _.difference(selectedNode.targets, savedNode.nodeTargets);
                                if (difTargets && difTargets.length === 1) {
                                    selectedNode = _this.model.nodes.byId(selectedNode.targets[0]);
                                }
                            }
                            if (selectedNode) {
                                selectedNode.isSelected = true;
                            }
                        }

                        App.vent.trigger("node-selected", savedNode.id);
                    })
                    .fail(_this.reloadCurrentFlow.bind(_this));
            }.bind(this)
        );

        this.listenTo(
            sidebarView,
            "sidebar-cancel",
            function() {
                this.resetSidebarAndFlow(false);
            }.bind(this)
        );

        this.listenTo(sidebarView, "delete", this._deleteSelectedNodes.bind(this));

        if (_this._inInNewNodeMode) {
            this.listenToOnce(sidebarView, "show", function() {
                _this.sidebar.currentView.focusFirstInputField();
            });
        }

        if (model?.metaObject?.type === metaStoreService.entities.SENTINEL) {
            this.showSentinel(model, this.showSentinelLiveMonitor, this);
        } else {
            this.sidebar.show(sidebarView);
        }
        sidebarView.setSidebarEnabled(this._isEnabled);

        if ("id" in model.metaObject) {
            //when adding new component (no ID), then there should be no routing
            if (this.isShowingMainFlow()) {
                navigateTo.EditNode(this.model.current_app_full, model.metaObject.name);
            } else {
                navigateTo.EditFlowNode(
                    this.model.current_app_full,
                    MetaObjectConverter.getName(this.model.currentFlowId),
                    model.metaObject.name
                );

                /* TODO: selected metaObject after page reload have no highlighted connectors
                _this.graphNodesView.graphNodesHighlighting.highlightConnection(model.metaObject);
                */
            }
        }

        // we use data-type attribute to easily remember type selected (easier this way than storing in the model I guess)
        this.ui.flow.removeClass(this.ui.flow.attr("data-type")).removeAttr("data-type");
        this.ui.flow.addClass(model.metaObject.type).attr("data-type", model.metaObject.type);
    },

    _reloadPreviewDataView: function(nodeModel) {
        ensureRegions(this);
        var _this = this;
        this._closePreviewData();
        var previewDataView = new PreviewDataView({
            model: nodeModel
        });
        this.previewData.show(previewDataView);
        this.ui.flowGraphArea.addClass("with-preview-data");
        this.listenTo(previewDataView, "preview-data-close", function() {
            _this._closePreviewData();
        });
        this.listenTo(
            previewDataView,
            "fullscreen-mode",
            function(isInFullscreenMode) {
                if (isInFullscreenMode) {
                    _this.lockDesigner();
                } else {
                    _this.unlockDesigner();
                }
            }.bind(this)
        );
    },

    _closePreviewData: function() {
        this.ui.flowGraphArea.removeClass("with-preview-data");
        if (this.previewData.hasView()) {
            this.previewData.reset();
        }
    },

    // used when start editing node
    lockDesigner: function() {
        this._isEnabled = false;
        this.predefinedComponentsPanel.setControlEnabled(false);
        this.flowMapView.setDropdownEnabled(false);
        this.toolbarGraphActionsView.setAllActionsEnabled(false);
        if (this._isEditingSidebarVisible()) {
            this.sidebar.currentView.setSidebarEnabled(false);
        }
    },

    // used when end editing node
    unlockDesigner: function() {
        var isEnabled = this._inInNewNodeMode === false;
        this._isEnabled = isEnabled;

        this.predefinedComponentsPanel.toggleElementsEnabled(isEnabled);
        this.flowMapView.setDropdownEnabled(true);
        this.toolbarGraphActionsView.setAllActionsEnabled(isEnabled);
        if (this._isEditingSidebarVisible()) {
            this.sidebar.currentView.setSidebarEnabled(isEnabled);
        }
    },

    handleShowAppErrors: function(loadLocal) {
        if (loadLocal && loadLocal === true) {
            this.loadAppErrorsFromLocalStorage(this.model);
        } else {
            this.loadAppErrorsFromServer();
        }
    },

    loadAppErrorsFromLocalStorage: function(model) {
        var errors = localErrorsStorage.load(model.app);

        if (errors.length <= 0) {
            return;
        }

        this.showAppErrors(model, errors);
    },

    loadAppErrorsFromServer: function() {
        var appName = this.model.app.getNameWithNamespace();
        var app = this.model.app;
        var _this = this;

        this._getAppErrors(appName).then(function(errors) {
            if (errors.length > 0) {
                localErrorsStorage.save(app, errors);
                _this.showAppErrors(_this.model, errors);
            }
        });
    },

    showComponent: function(nodeId) {
        // TODO probably there is a better way to navigate to desired flow with selected node
        var graphNode = _.find(this.model.datasource.graphModel, function(node) {
            return node.id === nodeId;
        });

        if (!graphNode) {
            return;
        }

        this.reloadFlowGraphWithContextRefresh({
            metaObject: {
                id: graphNode.flowId
            }
        }).then(function() {
            App.vent.trigger("node-selected", nodeId);
        });
    },

    showAppErrors: function(model, errors) {
        this.graphNodesView.unselectAllNodes();
        this._hideSideBarElement();
        this._showSideBarElement(model);

        var appErrorsView = new AppErrors.View({
            model: model,
            appStatus: this._lastSaveApplicationStatus,
            collection: new AppExceptions.Collection(errors),
            isAutomatedGroup: this.isAutomatedGroup,
            moveToAppGroups: this.moveToAppGroups.bind(this)
        });

        this.listenTo(appErrorsView, "show-node", this.showComponent.bind(this));

        this.listenTo(
            appErrorsView,
            "clear-and-reload",
            function() {
                localErrorsStorage.clear(this.model.app);
                appErrorsView.collection.reset();
            }.bind(this)
        );

        this.sidebar.show(appErrorsView);
        navigateTo.AppErrors(this.model.current_app_full);
    },

    showProgress: function() {
        this.graphNodesView.unselectAllNodes();
        this._hideSideBarElement();
        setTimeout(() => {
            this.$("#action-progress").click();
            navigateTo.Progress(this.model.current_app_full);
        }, 500);
    },

    showAppSettings: function(model, deployment) {
        this.graphNodesView.unselectAllNodes();
        this._hideSideBarElement();
        this._showSideBarElement(model);
        var app_status = model.app.flowStatus;
        var appSettingsView = new AppSettings.View({
            model: model.app,
            deployment: deployment,
            readOnly:
                !model.isEditing ||
                statusManagement.isApplicationRunning(app_status) ||
                statusManagement.isDeployedState(app_status) ||
                statusManagement.isApplicationCrash(app_status)
        });

        this.listenTo(
            appSettingsView,
            "appSettings-cancel",
            function() {
                this._hideSideBarElement();
            }.bind(this)
        );
        this.sidebar.show(appSettingsView);
        if (deployment) {
            navigateTo.DeploymentSettings(this.model.current_app_full);
        } else {
            navigateTo.AppSettings(this.model.current_app_full);
        }
    },

    _showSideBarElement: function(/*model*/) {
        this.ui.sidebar.removeClass("hidden");
        this.ui.myObjectsSidebar.find(".metadatabrowser-container").addClass("secondary");
        this.ui.flowGraphArea.addClass("with-sidebar");
    },

    _hideSideBarElement: function(hideMyObjects) {
        this.sidebar.empty();
        if (hideMyObjects !== false) {
            this._hideMyObjects();
        }
        this.ui.flow.removeClass("node-selected");
        this.ui.sidebar.addClass("hidden");
        this.ui.myObjectsSidebar.find(".metadatabrowser-container").removeClass("secondary");
        this.ui.flowGraphArea.removeClass("with-sidebar");

        if (this.isShowingMainFlow()) {
            navigateTo.App(this.model.current_app_full);
        } else {
            navigateTo.Subflow(this.model.current_app_full, MetaObjectConverter.getName(this.model.currentFlowId));
        }

        this.ui.flow.removeClass(this.ui.flow.attr("data-type")).removeAttr("data-type");
    },

    _hideMyObjects: function() {
        this.ui.myObjectsSidebar.addClass("hidden");
        this.ui.flowGraphArea.removeClass("with-myObjects-sidebar");
        this.myObjectsSidebar.empty();
        this.toolbarGraphActionsView.toggleMyObjectsActive(false);
    },

    // true when main sidebar contains component edit sidebar
    _isEditingSidebarVisible: function() {
        return this.sidebar && this.sidebar.currentView && this.sidebar.currentView.constructor === Sidebar.View;
    },

    _getAppErrors: function(appName) {
        var _this = this;
        var result = $.Deferred();

        api.getFlowErrors(appName).then(function(results) {
            var error;

            const appStatus = _this.model.app.flowStatus;
            if (statusManagement.isApplicationCreated(appStatus) || statusManagement.isApplicationInvalid(appStatus)) {
                api.compile(appName)
                    .fail(function(result) {
                        error = result;
                    })
                    .always(function() {
                        var flowErrors = appErrorsDataSource.parseFlowErrors(results, _this.model.app, true);

                        if (error) {
                            flowErrors.splice(
                                0,
                                0,
                                appErrorsDataSource.getCompilationError(_this.model.app, error, true)
                            );
                        }

                        result.resolve(flowErrors);
                    });
            } else {
                var flowErrors = appErrorsDataSource.parseFlowErrors(results, _this.model.app, true);
                result.resolve(flowErrors);
            }
        });

        return result.promise();
    },

    showMyObjects: function() {
        var isEditingSidebarVisible = this._isEditingSidebarVisible() === true;

        // if sidebar contains view other than editing sidebar
        // then it should be closed
        if (isEditingSidebarVisible === false) {
            this._hideSideBarElement();
        }

        this.ui.myObjectsSidebar.removeClass("hidden");
        this.ui.flowGraphArea.addClass("with-myObjects-sidebar");

        var myObjectsView = new MyObjects.View({
            app: this.model.app
        });
        this.myObjectsSidebar.show(myObjectsView);

        this.ui.myObjectsSidebar.find(".metadatabrowser-container").toggleClass("secondary", isEditingSidebarVisible);
        this.toolbarGraphActionsView.toggleMyObjectsActive(true);
    }
});

export default Designer;
