import React from "react";
import { StriimTooltip, StriimIconButton } from "@striim/striim-ui";
import { SvgIcon } from "@mui/material";

const styles = {
    buttonWrapper: ({ spacing }) => ({
        height: spacing(3.5)
    }),
    icon: ({ palette }) => ({
        fill: "none",
        // Fill stroke by our color
        "& > path": {
            stroke: palette?.secondary[500]
        },
        "& > g > path": {
            stroke: palette?.secondary[500]
        }
    })
};

const TableIconButton = ({ tooltipTitle, Icon, navigate, dataTestId }) => {
    return (
        <StriimTooltip placement="top" title={tooltipTitle}>
            <span>
                <StriimIconButton data-testid={dataTestId} onClick={navigate} sx={styles.buttonWrapper}>
                    <SvgIcon component={Icon} sx={styles.icon} />
                </StriimIconButton>
            </span>
        </StriimTooltip>
    );
};

export default TableIconButton;
