import propertyTemplateService from "core/services/metaStoreService/property-template-service";

export default function () {
    return propertyTemplateService.deploymentGroups.map(function (model) {
        return {
            id: model.id,
            text: model.name,
            description: model.nsName,
        };
    });
}
