import userPreferences from "core/services/userPreferences/user-preferences";

const APP_LIST_SELECTED_GROUP_KEY = "app_list_selected_group";
const APP_LIST_SELECTED_VIEW = "app_list_selected_view";
const APP_LIST_FILTERS = "app_list_filters";
const APP_LIST_DATA_TABLE_COLUMNS_KEY = "data_table_column_state";

export default {
    setSelectedGroup: group => {
        group && userPreferences.put(APP_LIST_SELECTED_GROUP_KEY, group);
    },
    getSelectedGroup: () => {
        return userPreferences.get(APP_LIST_SELECTED_GROUP_KEY);
    },
    setSelectedView: view => {
        userPreferences.put(APP_LIST_SELECTED_VIEW, view);
    },
    getSelectedView: () => {
        return userPreferences.get(APP_LIST_SELECTED_VIEW);
    },
    setAppListFilters: filters => {
        return userPreferences.put(APP_LIST_FILTERS, filters);
    },
    getAppListFilters: () => {
        return userPreferences.get(APP_LIST_FILTERS);
    },
    saveColumnState: colArr => {
        if (!colArr) return;
        userPreferences.put(APP_LIST_DATA_TABLE_COLUMNS_KEY, colArr);
    },
    loadColumnState: () => {
        return userPreferences.get(APP_LIST_DATA_TABLE_COLUMNS_KEY);
    }
};
