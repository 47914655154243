import App from "app";
import NestedTypes from "backbone.nestedtypes";
import iframeDetector from "core/iframeDetector";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import "app/components/common/ui/formElements";

App.module("Chart.Default", function (Default, App, Backbone, Marionette, $, _) {
    App.Chart.Model = NestedTypes.Model.extend({
        defaults: {
            config: NestedTypes.Model,
            theme: Object,
        },
    });

    Default.ChartView = Backbone.Marionette.LayoutView.extend({
        className: "chart",
        template: _.template(""),
        // Has the element been added to the page flow?
        _inDOM: function () {
            return this.$el.height() > 0;
        },

        initialize: function () {
            //console.log('Initializing chart')
            _(this).bindAll("_toggle_series");

            // Listen to user series show/hide events and update the model
            this.listenTo(this, "series-shown series-hidden", this._toggle_series);

            // Re-render when datavis issues event (usually on query update)
            this.listenTo(this, "data:ready", this.render);
            this.rendered = false;

            _.bind(this.defaultPointClickHandler, this);
            this.listenTo(this, "datapoint:click", this.defaultPointClickHandler);
        },
        _toggle_series: function (e) {
            var series_id = e.currentTarget.userOptions.id;
            var series = this.model.config.series.get(series_id);
            if (series) {
                series.set("visible", e.type !== "hide");
            }
        },
        render: function () {
            // refresh the chart only when data changed
            if (!this.model.get("forceRender") && this.model.dataSource && this.model.dataSource.data) {
                var currentData = JSON.stringify(this.model.dataSource.data);
                if (this.model.dataSource.data.length > 0 && this._lastData === currentData) {
                    return;
                }
                this._lastData = currentData;
            }

            var _this = this;
            if (!this.rendered) {
                Marionette.LayoutView.prototype.render.apply(this, arguments);
                this.rendered = true;
            }

            // The mixin's render method
            if (this.renderChart)
                _(function () {
                    try {
                        _this.renderChart();
                        _this.trigger("chart-render");
                    } catch (e) {
                        console.warn("Error rendering chart:", e);
                    }
                }).delay(10);
            else {
                console.warn("No chart mixin implemented.");
            }
        },
        onBeforeDestroy: function () {
            if (this.destroyChart) {
                this.destroyChart();
            }
        },
        defaultPointClickHandler: function (element) {
            if (iframeDetector.isInIframe()) {
                return;
            }

            var config = this.model.get("config");
            var show_drilldown = config.get("drilldown").get("show_drilldown");
            if (show_drilldown) {
                var drilldown = config.get("drilldown");
                var page = drilldown.get("page");
                var pageparams = drilldown.get("pageparams");
                this.handleDrillDown(element, page, show_drilldown, pageparams);
            }
        },
        handleDrillDown: function (element, page, show_drilldown, pageparams) {
            if (show_drilldown) {
                var drillDownValues = new Backbone.Model();
                pageparams.forEach(function (obj) {
                    if (element) {
                        drillDownValues.set(obj.pageparam, element[obj.srcfield]);
                    } else {
                        drillDownValues.set(obj.pageparam, null);
                    }
                });

                App.vent.trigger("dashboard:drilldown", page, drillDownValues);
            }
        },
    });

    Default.LegendItemView = Backbone.Marionette.ItemView.extend({
        className: "legend-item",
        template: _.template(
            '<div class="legend-color-block" style="background-color: {{color}};"></div><div class="legend-label">{{name}}</div>'
        ),
    });

    Default.LegendItemModel = NestedTypes.Model.extend({
        defaults: {
            name: String,
            color: String,
        },
    });

    Default.LegendView = Backbone.Marionette.CollectionView.extend({
        childView: Default.LegendItemView,
        initialize: function () {
            this.listenTo(this, "data:ready", function () {
                this.collection = this.model.config.legendData;
                this.render();
                this.trigger("legend-render");
            });
        },
    });

    // Generic chart
    Default.View = Backbone.Marionette.LayoutView.extend({
        className: "chart-layout",
        template: _.template(
            '<div class="chart-container"></div><div class="legend-container"><div class="legend-message message">No legend to display.</div><div class="legend-view-container"></div></div>'
        ),
        regions: {
            chartRegion: ".chart-container",
            legendRegion: ".legend-view-container",
        },
        initialize: function () {
            //If the container's size changes, resize the view.
            // the resize functions are written in the mixins for now.
            var that = this;
            this.listenTo(this, "resize", function () {
                var chartRegion = this.getRegion("chartRegion");
                var chartViewRegion = chartRegion.currentView;
                if (chartViewRegion && chartViewRegion.resize) {
                    this.getRegion("chartRegion").currentView.resize();
                }
            });

            this.listenTo(App.vent, "dashboard:menu:open dashboard:menu:close", function () {
                var chartRegion = that.getRegion("chartRegion");
                var chartViewRegion = chartRegion.currentView;
                if (chartViewRegion && chartViewRegion.resize) {
                    that.getRegion("chartRegion").currentView.resize();
                }
            });
        },
        onRender: function () {
            var visualizationType = this.model.visualizationType;
            if (
                visualizationType !== "Value" && // Value and Label does not need a query.
                visualizationType !== "Label" &&
                (this.model.query.id === undefined || this.model.query.id === "")
            ) {
                return;
            } //DEV-4672 - if the query is not set, dont add the graphs and legends.

            var chartView = new App.Chart[visualizationType].View({
                model: this.model,
            });

            var legendView = new Default.LegendView({
                model: this.model,
            });
            this.getRegion("chartRegion").show(chartView);
            this.getRegion("legendRegion").show(legendView);

            this.bindListeners();
        },
        onLegendRender: function () {
            // Show or hide the legend area
            if (this.model.config.show_legend) this.$el.removeClass("no-legend").addClass("has-legend");
            else this.$el.addClass("no-legend").removeClass("has-legend");

            // TODO: If there are no legend items to display, show a message
            if ((this.model.config.legendData || []).length === 0) {
                this.$el.addClass("legend-empty");
            } else {
                this.$el.removeClass("legend-empty");
            }
        },
        bindListeners: function () {
            var _this = this;
            this.listenTo(this, "data:ready", function () {
                _this.updateConfiguration();
                var legendData;
                if (this.model.config.show_legend) {
                    legendData = _this.getRegion("chartRegion").currentView.createCollectionFromLegendData();
                } else {
                    legendData = new Backbone.Collection();
                }

                _this.model.config.legendData = legendData;

                // Update configuration
                _this.getRegion("legendRegion").currentView.trigger("data:ready");

                this.listenToOnce(_this.getRegion("legendRegion").currentView, "legend-render", function () {
                    this.onLegendRender();
                    _this.getRegion("chartRegion").currentView.trigger("data:ready");
                });
            });
        },
        setConfiguration: function (config) {
            this.getRegion("chartRegion").currentView.setConfiguration(config);
        },
        getConfiguration: function () {
            return this.chartConfig || {};
        },
        // Translate data in the DataVisualization config format to one we can send to the mixin.
        // Generally this involves doing things like assigning colors to each series, or adding generated series
        // given the data.
        // TODO: should this just be part of the mixin translation process, or is it higher level than that?
        // Assigning colors is OK, since we need to do that for every mixin, but generated series is a highcharts specific concern.
        // TODO 2: Each of the chart-specific processConfiguration should be broken out to that chart's view.
        // e.g. series
        processConfiguration: function () {
            // Check each property's spec, and see if it's any Type we know about. If so, process it.
            _(this.model.config._spec.properties).each(
                function (property) {
                    //console.log('Property:', property)

                    // TODO: here we use the type name to determine if the property type is or extends this type.
                    // This is obviously not a good way to do it. (What if type has long string of types it extends)
                    // When time, figure out a better way. Need something like "instanceof".
                    if (
                        property.type &&
                        (property.type.name === dataVisualizations.WEBACTION_TYPES.CONDITIONS.name ||
                            (property.type.extends || {}).name === dataVisualizations.WEBACTION_TYPES.CONDITIONS.name)
                    ) {
                        var last_data = this.model.config.last_data;
                        this.model.config[property.attribute].each(function (condition) {
                            var exp = condition.expression;

                            // TODO: this is a very simple string/number comparison for demo purposes only.
                            // The values need to be properly interpolated, or a more robust expression object used
                            _(last_data).each(function (value, field) {
                                exp = exp.replace(
                                    new RegExp(field, "g"),
                                    _(value).isNumber() ? value : '"' + value + '"'
                                );
                            });
                            var matched = false;
                            try {
                                matched = eval(exp);
                            } catch (e) {
                                console.warn("Warning: Could not evaluate expression: ", exp, "\nError:", e);
                            }
                            condition.matched = matched;
                        });
                    }
                }.bind(this)
            );

            // DataVisualizations with a series property (TimeSeries)
            // TODO: this is specific to series. Move out of here.
            // var chartconfig = $.extend(true, {}, this.model.config.toJSON());
            var chartconfig = this.model.config.toJSON();

            if (chartconfig.series) {
                // Populate all series with the latest query (except generated)
                _(chartconfig.series).each(function (series, i) {
                    //if (series._generated) return;
                    if (series.xAxis == null || series.yAxis == null) return;
                    series.data = chartconfig.data;
                });
            }

            return chartconfig;
        },
        updateConfiguration: function () {
            var config = this.processConfiguration();

            this.setConfiguration({
                config: config,
                theme: this.model.theme,
            });
        },
        onBeforeDestroy: function () {
            //console.log('  ', this.cid, 'Destroying chart')
            if (this.destroyChart) this.destroyChart();
        },
        defaultPointClickHandler: function (element) {
            var config = this.model.get("config");
            var show_drilldown = config.get("drilldown").get("show_drilldown");
            if (show_drilldown) {
                var drilldown = config.get("drilldown");
                var page = drilldown.get("page");
                var pageparams = drilldown.get("pageparams");
                this.handleDrillDown(element, page, show_drilldown, pageparams);
            }
        },
        handleDrillDown: function (element, page, show_drilldown, pageparams) {
            if (show_drilldown) {
                var drillDownValues = new Backbone.Model();
                pageparams.forEach(function (obj, i) {
                    drillDownValues.set(obj.pageparam, element[obj.srcfield]);
                });

                App.vent.trigger("dashboard:drilldown", page, drillDownValues);
            }
        },
    });

    Default.MultiGraphView = Default.View.extend({
        template: _.template(
            '<div class="chart-container"></div><div class="toggle-visualization"></div><div class="legend-container"><div class="legend-message message">No legend to display.</div><div class="legend-view-container"></div></div>'
        ),
        regions: {
            toggleVisualization: ".toggle-visualization",
            chartRegion: ".chart-container",
            legendRegion: ".legend-container",
        },
        /**
         *
         */
        onRender: function () {
            var that = this;
            var chartView = new App.Chart[this.model.visualizationType].View({
                model: this.model,
            });
            var legendView = new Default.LegendView({
                model: this.model,
            });

            this.getRegion("chartRegion").show(chartView);
            this.getRegion("legendRegion").show(legendView);

            var buttonCollection = this._getButtonCollection();
            var radioButtonView = new App.UI.FormElements.RadioGroup({
                collection: buttonCollection,
                id: this.model.id,
            });
            radioButtonView.on("change:value", function (value) {
                chartView.trigger("change:graph", value);
            });
            chartView.on("data:ready", function () {
                that.getRegion("toggleVisualization").show(radioButtonView);
            });

            this.bindListeners();
        },
        /**
         * gets a backbone collection to be passed to the radio buttons.
         * @returns {Backbone.Collection}
         * @private
         */
        _getButtonCollection: function () {
            var buttonCollection = new Backbone.Collection();
            if (this.model.visualizationType === "PredictiveChart") {
                buttonCollection.add({
                    value: "realTime",
                    label: "Real Time",
                    selected: true,
                });
                buttonCollection.add({
                    value: "diagnostics",
                    label: "Past Predictions",
                });
            }
            return buttonCollection;
        },
    });
});
