const styles = {
    wrapper: {
        backgroundColor: "background.paper",
        justifyContent: "center",
        border: ({ palette }) => `1px solid ${palette.greyscale["200"]}`,
        borderRadius: "10px",
        flexDirection: "column",
        width: "900px",
        margin: ({ spacing }) => `${spacing(0.5)} auto 0 auto`
    },
    headerContainer: {},
    childContainer: {
        height: 300,
        borderTop: ({ palette }) => `1px solid ${palette.greyscale["200"]}`
    },
    footerContainer: {}
};

export default styles;
