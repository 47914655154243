import App from "app";
import api from "core/api/api";
import config from "config";
import $ from "jquery";
import _ from "underscore";
import axios from "axios";
import { contextStore } from "src/stores";
import licenseService from "core/services/metaStoreService/license-service";
import copilotService from "core/services/copilotService";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import { SegmentationFeaturesStates } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components";
import { segmentationVariants } from "src/modules/common/segmentation/segmentation.consts";
import {
    addonFeaturesKeys,
    ProductTiers,
    AppAdapters
} from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import monitorService from "../monitorService";
import Tracker from "core/services/tracker/tracker";
import dictionary from "../../../app/components/common/helpable/online-help-dictionary";
import userPreferences from "../userPreferences/user-preferences";
import trackingCookieExists from "../tracker/trackingCookieExists";
import { TRACKER_STRINGS } from "../tracker/constants";

let _isTrialLicense = true;

var securityService = {};
const PERIDIC_CHECK_TIMER = 60 * 1000;
let PERIDIC_CHECK_INTERVAL = null;

/**
 * Link used when addon is not available
 */
securityService.addonNotAvailableLink = "#/account-and-billing";

securityService.appPermissions = [
    "*:*:dashboard_ui:*",
    "*:*:sourcepreview_ui:*",
    "*:*:apps_ui:*",
    "*:*:admin_ui:*",
    "*:*:monitor_ui:*"
];
securityService.licensingOptions = null;
securityService.segmentationFeaturesStates = null;
securityService.currentSegmentationLicense = null;

securityService.start = function() {
    App.reqres.setHandler("user:permissions", securityService.fetchPermissions);
    App.reqres.setHandler("security:isAdvancedVisualizationsLicense", securityService.isAdvancedVisualizationsLicense);
};

securityService.getToken = async function() {
    return new Promise(async (resolve, reject) => {
        if (api.token) {
            resolve(api.token);
        } else {
            try {
                let token = await api.getToken();
                resolve(token);
            } catch (e) {
                reject(e);
            }
        }
    });
};

securityService.isLoggedIn = async function() {
    return new Promise(async (resolve, reject) => {
        try {
            let token = await securityService.getToken();
            let isAuthenticated = await api.isAuthenticated(token);
            if (isAuthenticated) {
                await setToken(token);
                resolve(true);
            } else {
                await setToken(null);
                reject();
            }
        } catch (e) {
            reject(e);
        }
    });
};

/**
 * @param token
 * @returns {*}
 */
const setToken = async function(token) {
    return new Promise(async (resolve, reject) => {
        if (!token) {
            resolve();
        } else {
            try {
                api.setToken(token);
                await api.createContext();
                resolve();
            } catch (e) {
                reject(e);
            }
        }
    });
};

const periodicCheck = async function() {
    clearInterval(PERIDIC_CHECK_INTERVAL);
    PERIDIC_CHECK_INTERVAL = null;
    try {
        let isLoggedIn = await securityService.isLoggedIn();
        if (!isLoggedIn) {
            securityService.logout();
        }
    } catch (e) {
        securityService.logout();
    }

    PERIDIC_CHECK_INTERVAL = setTimeout(function() {
        periodicCheck();
    }, PERIDIC_CHECK_TIMER);
};

/*
 * Call from any module"s init to determine if the current token is still valid
 */
securityService.authenticate = async function() {
    return new Promise(async (resolve, reject) => {
        try {
            let isLoggedIn = await securityService.isLoggedIn();
            const user = await securityService.getUser();
            App.user = user;
            if (isLoggedIn) {
                const [
                    licensingOptions,
                    _void,
                    _void2,
                    isTrial,
                    currentLicense,
                    documentationRoot,
                    inlineDocRoot
                ] = await Promise.all([
                    api.getCurrentLicensingOption(),
                    propertyTemplateService.init(),
                    securityService.fetchPermissions(),
                    api.isTrialLicense(),
                    api.getCurrentLicense(),
                    api.getDocumentationRoot(),
                    api.getInlineDocRoot()
                ]);
                App.trigger("app:authenticated");
                securityService.licensingOptions = licensingOptions;
                _isTrialLicense = isTrial;
                monitorService.init();
                securityService.updateSegmentationFeatureStates();
                securityService.currentSegmentationLicense = currentLicense && currentLicense.productTier;
                dictionary.updateRootPath(documentationRoot);
                securityService.inlineDocRoot = inlineDocRoot;
                await licenseService.init();
                await copilotService.init();
                if (securityService.isAppAdapterLicense()) {
                    const docVersion = dictionary.getDocVersion().replaceAll(".", "");
                    dictionary.updateRootPath(
                        "https://striim.com/docs/archive/" + docVersion + "/StriimCloudForApplicationIntegration/"
                    );
                }
                PERIDIC_CHECK_INTERVAL = setTimeout(function() {
                    periodicCheck();
                }, PERIDIC_CHECK_TIMER);

                if (securityService.isDeveloperLicense() && trackingCookieExists()) {
                    setTimeout(() => {
                        Tracker.getInstance().track(
                            TRACKER_STRINGS.schema.logIn,
                            {
                                event: "Signed in to Striim Developer",
                                user: user?.displayname
                            },
                            TRACKER_STRINGS.version.withModification
                        );

                        Tracker.getInstance().track(TRACKER_STRINGS.schema.success, {
                            event: "Success:Signed in to Striim Developer",
                            id: user?.displayname,
                            message: "Developer license user"
                        });
                    }, 1000);
                }
                resolve(true);
            } else {
                reject();
            }
        } catch (e) {
            console.error(e);
            reject(e);
        }
    });
};

securityService.loginDashboard = async function(key) {
    return new Promise(async (resolve, reject) => {
        try {
            let token = await api.authenticateDashboardEmbedUser(key);
            token = token.uuidstring;
            api.token = token;
            await securityService.authenticate(token, true);
            resolve();
        } catch (e) {
            console.error(e);
            reject(e);
        }
    });
};

securityService.fetchPermissions = async function() {
    return new Promise(async (resolve, reject) => {
        try {
            if (!App.user) {
                resolve([]);
            } else {
                if (App.userPermissions) {
                    resolve(App.userPermissions);
                } else {
                    const permissions = await api.getAllowedPermissions(App.user.name, securityService.appPermissions);
                    if (config.get("UIReadOnly")) {
                        permissions["*:*:dashboard_ui_edit:*"] = false;
                        permissions["*:*:sourcepreview_ui_edit:*"] = false;
                        permissions["*:*:apps_ui_edit:*"] = false;
                        permissions["*:*:admin_ui_edit:*"] = false;
                        permissions["*:*:monitor_ui_edit:*"] = false;
                    } else {
                        permissions["*:*:dashboard_ui_edit:*"] = true;
                        permissions["*:*:sourcepreview_ui_edit:*"] = true;
                        permissions["*:*:apps_ui_edit:*"] = true;
                        permissions["*:*:admin_ui_edit:*"] = true;
                        permissions["*:*:monitor_ui_edit:*"] = true;
                    }
                    App.userPermissions = permissions;
                    resolve(permissions);
                }
            }
        } catch (e) {
            console.error(e);
            reject(e);
        }
    });
};

securityService.login = async function(model) {
    return new Promise(async (resolve, reject) => {
        try {
            const formData = "username=" + model.username + "&password=" + encodeURIComponent(model.password);
            const result = await axios.post("/security/authenticate", formData);
            const token = result.data.token;
            await setToken(token);
            let authenticated = await securityService.authenticate();
            if (authenticated) {
                await userPreferences.load();
                resolve(token);
                App.trigger("app:authenticated");
            } else {
                setToken(null);
                reject();
            }
        } catch (e) {
            setToken(null);
            const msg = e.response?.data?.message || e.message;
            reject(msg);
        }
    });
};

securityService.getUser = async function() {
    return new Promise(async (resolve, reject) => {
        try {
            let user = await api.getAuthenticatedUser();
            user.displayname =
                user.firstName && user.lastName ? user.firstName + " " + user.lastName : user.mainEmail || user.name;
            resolve(user);
        } catch (e) {
            console.error(e);
            reject(e);
        }
    });
};

securityService.isSaaSEnvironment = function() {
    let host = window.location.host;

    let isSaaS = App.user?.originType === "SAAS";
    if (isSaaS) return true;

    switch (true) {
        case /us-striim.app/.test(host):
        case /stg-az-striimcloudapp.com/.test(host):
        case /striim.app/.test(host): // testing for striim.app has to come in the last "case" statement
        case /stg-striimcloudapp.com/.test(host):
        case /dev-striimcloudapp.com/.test(host):
            return true;
        default:
            return false;
    }
};

const sessionEnded = (isSaaS = false) => {
    if (isSaaS) {
        App.navigate("#/ended");
    } else {
        App.navigate("#/");
    }
    api.disconnect();
    clearInterval(PERIDIC_CHECK_INTERVAL);
    window.location.reload();
};

securityService.logout = function() {
    App.token = null;
    let isSaaS = App.user?.originType === "SAAS";
    App.userPermissions = undefined;
    // localStorage.clear(); // to avoid personalization resets
    sessionStorage.clear();
    api.token = null;
    contextStore.backboneStore.setPermissionsChecked(false);
    $.ajax({
        type: "POST",
        url: "/security/logout/",
        success: function() {
            sessionEnded(isSaaS);
        },
        error: function() {
            console.log("problem logging out");
            sessionEnded(isSaaS);
        }
    });
};

const cloudLicenses = [
    "AzureSQLServer",
    "AzureHDInsight",
    "AzureStorage",
    "AzureEventHub",
    "AzureDMS",
    "AzureSqlDW",
    "AzureCosmosDB",
    "AzureSqlDwIncrementalSource",
    "GooglePostgresSql",
    "GoogleMySql",
    "GoogleGCS",
    "GoogleBigQuery",
    "AzureCosmosDB",
    "AzurePostgreSQL",
    "AwsRedShift",
    "GoogleSpanner",
    "AzureSqlDW",
    "Snowflake",
    "DeltaLake",
    "SnowflakePartnerConnect",
    "SMSGC"
];

securityService.getCurrentLicensingOption = function() {
    var deferred = $.Deferred();

    if (securityService.licensingOptions) {
        deferred.resolve(securityService.licensingOptions);
    } else {
        api.getCurrentLicensingOption().then(licensingOptions => {
            securityService.licensingOptions = licensingOptions;
            deferred.resolve(licensingOptions);
        });
    }

    return deferred.promise();
};

securityService.isCloudLicense = function() {
    var deferred = $.Deferred();

    securityService.getCurrentLicensingOption().then(licensingOptions => {
        var result = _.intersection(cloudLicenses, licensingOptions).length > 0;
        deferred.resolve(result);
    });

    return deferred.promise();
};

/**
 * Checks if it's a bidirectional server
 * @returns {*|jQuery}
 */
securityService.isBidirectional = function() {
    var deferred = $.Deferred();
    securityService.getCurrentLicensingOption().done(licensingOptions => {
        if (licensingOptions.indexOf("Bidirectional") !== -1) {
            deferred.resolve(true);
        } else {
            deferred.resolve(false);
        }
    });
    return deferred.promise();
};

securityService.isAdvancedVisualizationsLicense = function() {
    const licenses = ["AdvancedVisualizations"];

    return _.intersection(licenses, securityService.licensingOptions).length > 0;
};

securityService.isDefaultLicense = function() {
    return securityService.licensingOptions.length === 0;
};

securityService.isTrialLicense = function() {
    return _isTrialLicense;
};

securityService.isSnowflakePartnerConnect = function() {
    const licenses = ["SnowflakePartnerConnect"];

    return _.intersection(licenses, securityService.licensingOptions).length > 0;
};

securityService.isSMSGC = function() {
    const licenses = ["SMSGC"];

    return _.intersection(licenses, securityService.licensingOptions).length > 0;
};

securityService.updateSegmentationFeatureStates = () => {
    api.getFeatureStates().done(result => {
        securityService.segmentationFeaturesStates = result;
        App.vent.trigger("segmentation:refreshedstates");
    });
};

securityService.getSegmentationVariant = feature => {
    var state = securityService.getSegmentationFeatureState(feature);

    switch (state) {
        case SegmentationFeaturesStates.ADDON_DISABLED:
            return segmentationVariants.addOn;

        case SegmentationFeaturesStates.ADDON_ENABLED:
            return segmentationVariants.added;

        case SegmentationFeaturesStates.NOTAPPLICABLE:
            return [addonFeaturesKeys.DASHBOARDS, addonFeaturesKeys.WACTION_STORE].indexOf(feature) !== -1
                ? segmentationVariants.enterprise
                : segmentationVariants.enterprisePlus;

        case SegmentationFeaturesStates.DEFAULT:
            return segmentationVariants.none;

        default:
            return segmentationVariants.none;
    }
};

securityService.isSegmentationFeatureEnabled = feature => {
    var state = securityService.getSegmentationFeatureState(feature);

    return state === SegmentationFeaturesStates.DEFAULT || state === SegmentationFeaturesStates.ADDON_ENABLED;
};

securityService.getSegmentationFeatureState = feature => {
    if (!securityService.segmentingEnabled()) {
        return SegmentationFeaturesStates.DEFAULT;
    }

    if (!securityService.segmentationFeaturesStates || !securityService.segmentationFeaturesStates[feature]) {
        return SegmentationFeaturesStates.DEFAULT;
    }

    return securityService.segmentationFeaturesStates[feature];
};

securityService.segmentingEnabled = () => {
    return securityService.currentSegmentationLicense !== ProductTiers.NONSEGMENTED;
};

securityService.isDeveloperLicense = () => {
    return securityService.currentSegmentationLicense === ProductTiers.DEVELOPER;
};

securityService.isAppAdapterLicense = () => {
    return Object.values(AppAdapters).includes(securityService.currentSegmentationLicense);
};

securityService.isLicenseOptionSQL2FabricSolution = () => {
    return api.isLicenseOptionSQL2FabricSolution();
};

securityService.isFabricSolution = () => {
    const licenses = ["SQL2FabricSolution"];
    return _.intersection(licenses, securityService.licensingOptions).length > 0;
};

securityService.getCurrentAppAdapterLicenseName = () => {
    switch (securityService.currentSegmentationLicense) {
        case AppAdapters.APPADAPTERSSTRIPE:
            return "Stripe";
        case AppAdapters.APPADAPTERSZENDESK:
            return "Zendesk";
        case AppAdapters.APPADAPTERSHUBSPOT:
            return "HubSpot";
        default:
            return "";
    }
};

securityService.isLicenseOptionDisableAIInsights = () => {
    return api.isLicenseOptionDisableAIInsights();
};

export default securityService;
