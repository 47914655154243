import _ from "underscore";

var appErrorsDataSource = {};

appErrorsDataSource.parseFlowErrors = function (results, app, showComponentLink) {
    var noRelatedEvents = "No related events";
    var notApplicable = "Not Applicable";

    return results.map(JSON.parse).map(function (error) {
        error.nsName = app.nsName;
        error.isComponent = error.componentName !== app.nsName + "." + app.name;
        error.componentName = error.componentName
            ? error.componentName.substr(error.componentName.lastIndexOf(".") + 1)
            : notApplicable;
        error.exception = error.exception
            ? error.exception.substr(error.exception.lastIndexOf(".") + 1)
            : "Unspecified Exception";
        error.message = error.message || "Unspecified exception, please see server logs for more details.";
        error.relatedEvents = error.relatedEvents || noRelatedEvents;
        error.showComponentLink = showComponentLink;
        return error;
    });
};

appErrorsDataSource.getCompilationError = function (app, error, showComponentLink) {
    return {
        nsName: app.nsName,
        isComponent: false,
        componentName: "Application",
        exception: "Compilation Exception",
        message: _.isObject(error) ? error.message : error,
        relatedEvents: "No related events",
        showComponentLink: showComponentLink,
    };
};

export default appErrorsDataSource;
