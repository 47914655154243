export const styles = {
    endAdornmentContainer: {
        backgroundColor: "transparent",
        color: "greyscale.800",
        border: "none",
        padding: 0.5,
        "&:hover, &:active": {
            backgroundColor: "transparent",
            border: "none"
        }
    },
    iconButton: {
        width: 20,
        height: 20
    },
    autoWidth: {
        width: "auto"
    },
    titleContainer: {
        display: "flex",
        alignItems: "center",
        gap: 2
    },
    editIcon: {
        fill: "none",
        fontSize: 20,
        "& > path": {
            stroke: ({ palette }) => palette.secondary[500]
        },
        "&:hover": {
            cursor: "pointer"
        }
    },
    actionIcon: {
        fill: "transparent",
        fontSize: 16,
        "& > path": {
            stroke: ({ palette }) => palette.primary[700]
        }
    },
    reviewHeader: {
        display: "flex",
        alignItems: "center",
        gap: 0.5
    },
    appNameContainer: {
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
        width: "inherit"
    },
    appNameWrapper: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        "& > div": {
            display: "flex",
            width: "inherit"
        }
    },
    appName: {
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "inherit",
        marginRight: "60px"
    },
    dataTableWrapper: {
        "& .appNamecellClass": {
            width: "inherit",
            display: "flex",
            "& .ag-cell-wrapper": {
                width: "inherit"
            },
            "& .ag-cell-wrapper > span > div": {
                display: "flex",
                flexWrap: "nowrap"
            }
        }
    }
};
