import { Box, Card, Container, Grid, RadioGroup } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import {
    StriimButton,
    StriimCheckbox,
    StriimControlLabel,
    StriimForm,
    StriimInputField, StriimModal,
    StriimRadio, StriimTypography
} from "@striim/striim-ui";
import classNames from "classnames";
import growl from "../../../app/components/common/growl";
import { setIn } from "formik";

export default function AuthrPlayGround() {
    const values = {
        host: "https://localhost:3000",
        identifier: "salesforce"
    };
    const ref = useRef();
    const iframeRef = useRef(null);
    const [showConfirm, setShowConfirm] = React.useState(false);

    const [json, setJSON] = useState(null)
    const formRef = React.useCallback(node => {
        if (node) {
            ref.current = node;
            if (node.values) {
                console.log(node.values);
            }
        }
    }, []);

    async function getData(host,identifier, jsonRes){
        console.log(host, identifier, jsonRes)
        let url2 = `${host}/do/${identifier}/get?token=${jsonRes.token}`;
        let req = await fetch(url2);
        let jsonVal = await req.json();
        setJSON(JSON.stringify(jsonVal, null, 4))

        console.log(jsonVal);
    }
    async function login(host, identifier){
        const license_key = "";
        const product_key = "";
        let url = `${host}/do/${identifier}/init?origin=${host}`;
        let req = await fetch(url, {method: "POST", body: JSON.stringify({ license_key, product_key }),});
        let jsonRes = await req.json();

        const _win = window.open(jsonRes.loginURL, "StriimOauthLauncher")
        growl.success("Launched Oauth. Please check the next tab", "Success");
        let interval = setInterval(()=>{
            if(_win.closed){
                getData(host, identifier, jsonRes)
                growl.info("Oauth Tab closed, checking for data", "Success");
                clearInterval(interval);
            }
        },1000)
    }


    const validationSchema = Yup.object().shape(
        {
            host: Yup.string().required()
        },
        [["authToken", "username", "password", "consumerKey", "consumerSecret", "securityToken", "migrateSchema"]]
    );
    return (
        <Container style={{ marginTop: 100 }}>
            <Paper style={{ padding: 10 }}>
                <Grid container>
                    <Grid xs={6} item>
                        <StriimForm
                            values={values}
                            validationSchema={validationSchema}
                            formRef={formRef}
                            validateOnMount={true}
                            validateOnBlur={true}
                        >
                            {({ values, setValues, setFieldValue }) => (
                                <>
                                    <StriimInputField
                                        name="host"
                                        label="Authr Endpoint"
                                        placeholder="Example: http://localhost:3000"
                                        isFormElement={true}
                                        fullWidth={true}
                                        disabled={false}
                                    />

                                    <StriimInputField
                                        name="identifier"
                                        label="Identifier"
                                        placeholder="Example: salesforce"
                                        isFormElement={true}
                                        fullWidth={true}
                                        disabled={false}
                                    />

                                    <StriimButton
                                        style={{ marginTop: 10 }}
                                        onClick={() => {
                                            login(values.host, values.identifier);
                                        }}
                                    >
                                        Login
                                    </StriimButton>


                                </>
                            )}
                        </StriimForm>
                    </Grid>
                    <Grid xs={6} item>
                        <Card style={{padding: 10}}>
                            <pre>{json}</pre>
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}
