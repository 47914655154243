import { GenericStore } from "../storeUtils";
import { observable, action, computed } from "mobx";

export default class BackboneStore extends GenericStore {
    @observable started = false;
    @observable permissionsChecked = false;

    @action setStarted() {
        this.started = true;
    }
    @action setPermissionsChecked(state = true) {
        this.permissionsChecked = state;
    }

    @computed get initialized() {
        return this.started && this.permissionsChecked;
    }
}
