import React from "react";
import { StriimTypography, StriimModal } from "@striim/striim-ui";

const DeleteModal = ({ id, type, deleteDialogVisible, setDeleteDialogVisible, onDeleteConfirm }) => {
    return (
        <StriimModal
            size="small"
            isVisible={deleteDialogVisible}
            onConfirm={onDeleteConfirm}
            onCancel={setDeleteDialogVisible}
            autoHeight
            variant="error"
            confirmContent={"Delete"}
            confirmButtonProps={{ "data-test-id": "confirm-user-delete-button" }}
            cancelButtonProps={{ "data-test-id": "cancel-user-delete-button" }}
            titleContent={
                <StriimTypography variant="h2" color="primary.700">
                    Are you sure to delete {type} {id}?
                </StriimTypography>
            }
        >
            <StriimTypography variant="body4">
                Deleting a {type} is not retrievable, all the data relating to this {type} will be eliminated.
            </StriimTypography>
        </StriimModal>
    );
};

export default DeleteModal;
