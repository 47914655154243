import _ from "underscore";
import TextField from "./text-field";

var View = TextField.View.extend({
    onRender: function () {
        this.$("input").attr("disabled", "disabled");
    },
});

export default _.extend({}, TextField, {
    View: View,
});
