import React, { useContext, useState, useMemo, useEffect, useRef, useCallback } from "react";
import { CardContent, Box, Divider } from "@mui/material";
import { StriimButton } from "@striim/striim-ui";

import classes from "./settings-tab.styles";
import { AIEngine, DefaultActions } from "./components";
import GuardianTabsContext from "../guardian-tabs-context";
import guardianService from "../guardian-service";
import growl from "../../../../../app/components/common/growl";
import LoadingIndicator from "../../../../generic/loading-indicator";
import { PIISettings, PIISettingsOptions } from "../guardian-job.types";
import connectionProfileService from "../../../apps/pages/manage-striim/connection-profiles/connection-profile-service";
import sentinelService from "../../sentinel/sentinel-service";
import { SENTINEL_ACTIONS } from "../../sentinel/panel/sentinel-panel-consts";
import {
    SDIImportance as ImportanceHigh,
    SquareArrowDown as ImportanceLow,
    SeverityWarning as ImportanceMedium
} from "../../../../generic/icon/customIcons";

export const NEW_AI_ENGINE_IDENTIFIER = "New";
export const DEFAULT_STRIIM_AI_ENGINE = "System$PIIGlobal.striimAICP";

export const modelProviders = {
    openAI: "OpenAI_CP",
    vertexAI: "VertexAI_CP",
    striimAI: "StriimAICP"
};

export const AIEngines = {
    striim: DEFAULT_STRIIM_AI_ENGINE,
    custom: "custom"
};

const importanceIconsMap = {
    high: <ImportanceHigh />,
    medium: <ImportanceMedium />,
    low: <ImportanceLow />
};

const sortOrder = {
    LOW: 1,
    MEDIUM: 2,
    HIGH: 3
};

type SentinelOptions = {
    identifiersMap: object;
    actionsMap: object;
    importanceMap: object;
};

const SettingsTab = () => {
    const context = useContext(GuardianTabsContext);
    const { isAdmin } = context;
    const [savedEngine, setSavedEngine] = useState<string>("");
    const [selectedEngine, setSelectedEngine] = useState<string>("");
    const [rowData, setRowData] = useState<any[]>([]);
    const [tablesData, setTablesData] = useState<any[]>([]);
    const [customAIEngines, setCustomAIEngines] = useState<any[]>([]);
    const [selectedCustomAIEngine, setSelectedCustomAIEngine] = useState<{ label: string; value: string } | null>();
    const maxWidth = 650;
    const [loading, setLoading] = useState<boolean>(true);
    const previousCustomEngineRef = useRef<string>();
    const [sentinelOptions, setSentinelOptions] = useState<SentinelOptions | null>(null);
    const [partialMaskingSDIOptions, setPartialMaskingSDIOptions] = useState<string[]>([]);
    const [defaultActionsData, setDefaultActionsData] = useState<any[]>([]);

    useEffect(() => {
        loadSettingsData();
    }, []);

    const loadSettingsData = async () => {
        try {
            const result = await guardianService.listPIISettingsOptions();
            const settings: PIISettings = JSON.parse(await guardianService.getPIISettings());
            const settingsOptions: PIISettingsOptions = JSON.parse(result);
            const defaultPiiActions = settingsOptions.piiDefaultSDIConfig;
            const aiEngine = settings?.aiEngine?.trim();
            const allActions = settingsOptions.piiActions;
            const importanceLevels = settingsOptions.importanceLevels;
            const piiActions = settings.sdiConfig;
            const sensitiveDataIdentifiers = settingsOptions.sensitiveDataIdentifiers;
            const partialMaskingSDI = await sentinelService.getPartialMaskingSDIOptions();
            const partialMaskingSDIStrings = sentinelService.mapPartialMaskingSDIToString(
                partialMaskingSDI,
                sensitiveDataIdentifiers
            );
            setPartialMaskingSDIOptions(partialMaskingSDIStrings);
            setSentinelOptions({
                identifiersMap: sensitiveDataIdentifiers,
                actionsMap: allActions,
                importanceMap: importanceLevels
            });
            setDefaultActionsData(
                Object.keys(sensitiveDataIdentifiers).map(sdi => {
                    const piiAction = defaultPiiActions[sdi].actionConfig.action;
                    const piiImportance = defaultPiiActions[sdi].importance;
                    return {
                        name: sensitiveDataIdentifiers[sdi],
                        action: {
                            label: allActions[piiAction],
                            value: piiAction
                        },
                        importance: {
                            label: [piiImportance],
                            value: piiImportance,
                            icon: importanceIconsMap[defaultPiiActions[sdi].importance.toLowerCase()]
                        }
                    };
                })
            );
            const tableData = sentinelService.convertPIIActionsToTableData(
                sensitiveDataIdentifiers,
                allActions,
                piiActions,
                importanceIconsMap,
                importanceLevels
            );
            const aiEngineConnectionProfiles = await connectionProfileService.getCpsByEndpoints([
                modelProviders.openAI,
                modelProviders.vertexAI
            ]);
            let aiEngineOptions = aiEngineConnectionProfiles.map(profile => ({ label: profile, value: profile }));
            setTablesData(tableData);
            setRowData(tableData);
            let previousSavedEngine;
            if ([AIEngines.striim].includes(aiEngine)) {
                previousSavedEngine = aiEngine;
            } else {
                previousSavedEngine = AIEngines.custom;
                previousCustomEngineRef.current = aiEngine;
                setSelectedCustomAIEngine({ label: aiEngine, value: aiEngine });
            }
            aiEngineOptions.push({
                label: "+ New",
                value: NEW_AI_ENGINE_IDENTIFIER
            });
            setSelectedEngine(previousSavedEngine);
            setSavedEngine(previousSavedEngine);
            setCustomAIEngines(aiEngineOptions);
        } catch (error) {
            growl.error(error);
        } finally {
            setLoading(false);
        }
    };

    const isMatchingAction = useCallback(
        ({ name, action, importance }, { name: elemName, action: elemAction, importance: elemImportance }) =>
            name === elemName && action?.value === elemAction?.value && importance?.value === elemImportance?.value,
        []
    );

    const isSaveDisabled = useMemo(() => {
        const isSameEngine = selectedEngine === savedEngine;
        const isSameActions = tablesData?.every(element => rowData.some(el => isMatchingAction(el, element)));
        const isCustomEngineUnSelected = selectedEngine === AIEngines.custom && !selectedCustomAIEngine?.value;
        const isSameCustomAIEngine =
            selectedEngine === AIEngines.custom && selectedCustomAIEngine?.value
                ? selectedCustomAIEngine.value === previousCustomEngineRef.current
                : true;
        return (isSameActions && isSameEngine && isSameCustomAIEngine) || isCustomEngineUnSelected;
    }, [selectedEngine, savedEngine, tablesData, rowData, selectedCustomAIEngine]);

    const handleSave = async () => {
        try {
            //was previously using settingsReverseTableData
            const formattedActions = sentinelService.reverseSettingsTableData(rowData, sentinelOptions.identifiersMap);
            const settings = {
                aiEngine: selectedEngine !== AIEngines.custom ? selectedEngine : selectedCustomAIEngine.value,
                sdiConfig: formattedActions
            };
            await guardianService.setPIISettings(settings);
            growl.success("Settings saved successfully");
            previousCustomEngineRef.current = "";
            setSelectedCustomAIEngine(null);
            loadSettingsData();
        } catch (error) {
            growl.error("Error saving settings", error);
        }
    };

    const importanceOptions = useMemo(() => {
        return sentinelOptions
            ? Object.entries(sentinelOptions.importanceMap)
                  .map(([value, label]: [string, string]) => ({
                      label,
                      value,
                      icon: importanceIconsMap[value.toLowerCase()]
                  }))
                  .sort((a, b) => sortOrder[a.value] - sortOrder[b.value])
            : [];
    }, [sentinelOptions]);

    const maskingOptions = useMemo(() => {
        return [
            { label: "Mask Completely", value: SENTINEL_ACTIONS.MASK_COMPLETELY },
            { label: "Mask Partially", value: SENTINEL_ACTIONS.MASK_PARTIALLY },
            { label: "Custom Mask", value: SENTINEL_ACTIONS.CUSTOM_MASK },
            { label: "No Action", value: SENTINEL_ACTIONS.NO_ACTION }
        ];
    }, []);

    return loading ? (
        <LoadingIndicator isGlobal={false} />
    ) : (
        <CardContent sx={classes.cardContent}>
            <Box sx={isAdmin ? classes.settingsContent : ""}>
                <AIEngine
                    isAdmin={isAdmin}
                    selectedEngine={selectedEngine}
                    setSelectedEngine={setSelectedEngine}
                    customAIEngines={customAIEngines}
                    setCustomAIEngines={setCustomAIEngines}
                    selectedCustomAIEngine={selectedCustomAIEngine}
                    setSelectedCustomAIEngine={setSelectedCustomAIEngine}
                />
                <Divider variant="fullWidth" sx={{ borderColor: "greyscale.200" }} />
                <Box maxWidth={maxWidth} px={4} py={2}>
                    <DefaultActions
                        tablesData={tablesData}
                        defaultActionsData={defaultActionsData}
                        maskingOptions={maskingOptions}
                        importanceOptions={importanceOptions}
                        isAdmin={isAdmin}
                        rowData={rowData}
                        setRowData={setRowData}
                        showSearch={isAdmin}
                        partialMaskingSDIOptions={partialMaskingSDIOptions}
                    />
                </Box>
            </Box>
            {isAdmin && (
                <>
                    <Divider variant="fullWidth" sx={{ borderColor: "greyscale.200" }} />
                    <Box gap={2} component="div" p={3} display="flex" maxWidth={maxWidth} justifyContent={"end"}>
                        <StriimButton variant="primaryText" data-test-id="cancel-btn" disabled={isSaveDisabled}>
                            Cancel
                        </StriimButton>
                        <StriimButton
                            variant="primary"
                            data-test-id="save-btn"
                            disabled={isSaveDisabled}
                            onClick={handleSave}
                        >
                            Save
                        </StriimButton>
                    </Box>
                </>
            )}
        </CardContent>
    );
};

export default SettingsTab;
