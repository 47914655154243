import React, { useEffect, useState } from "react";
import { DataTable, DataRecord } from "../../../../../../../wizards/common/review/review";
import { StriimTypography } from "@striim/striim-ui-v2";
import { Grid } from "@mui/material";
import Arrow from "./arrow/arrow";
import { styles } from "./progress-details.styles";
import { observer } from "mobx-react-lite";
import useStores from "../../../../../../../../utils/use-stores";
import { getIcons, getProgressInformation } from "../../utils";
import api from "../../../../../../../../../core/api/api";
import { AdapterIcon } from "../../../../../../../../generic/icon/component-icon/component-icon";
import Tracker from "../../../../../../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../../../../../core/services/tracker/constants";
import useMostRecentData from "./use-most-recent-data";

interface TitleWithIconProps {
    name: string;
    icon: string;
    type: string;
    arrowEl?: JSX.Element;
}

const TitleWithIcon: React.FunctionComponent<TitleWithIconProps> = ({ name, icon, type, arrowEl = null }) => {
    return (
        <Grid container sx={styles.titleContainer}>
            {icon && type && <AdapterIcon name={icon} type={type} size={24} />}
            <StriimTypography variant="h4">{name}</StriimTypography>
            {arrowEl}
        </Grid>
    );
};

interface ReaderInfoProps {
    icon: string;
    dbName: string;
    data: Array<any>;
    tablesCount: number;
    status: string;
}

const ReaderInfo: React.FunctionComponent<ReaderInfoProps> = ({ icon, dbName, data, tablesCount, status }) => {
    return (
        <Grid sx={styles.readerInfo}>
            <TitleWithIcon
                name={dbName}
                icon={icon}
                type="source"
                arrowEl={<Arrow tablesCount={tablesCount} status={status} />}
            />
            <DataTable>
                {data.map((item, idx) => (
                    <DataRecord key={idx} title={item.label} data={item.value ?? "-"} />
                ))}
            </DataTable>
        </Grid>
    );
};

interface WriterInfoProps {
    icon: string;
    dbName: string;
    data: Array<any>;
}

const WriterInfo: React.FunctionComponent<WriterInfoProps> = ({ icon, dbName, data }) => {
    return (
        <Grid sx={styles.writerInfo}>
            <TitleWithIcon name={dbName} icon={icon} type="target" />
            <DataTable>
                {data?.map((item, idx) => (
                    <DataRecord key={idx} title={item.label} data={item.value ?? "-"} />
                ))}
            </DataTable>
        </Grid>
    );
};

interface ProgressDetailsProps {
    stage: string;
    status: string;
    sortedGroups: Array<string>;
}

const ProgressDetails: React.FunctionComponent<ProgressDetailsProps> = observer(({ stage, status, sortedGroups }) => {
    const { store } = useStores();
    const [Icons, setIcons] = useState(null);
    const [tablesCount, setTablesCount] = useState(0);

    const mostRecentData = useMostRecentData(sortedGroups[stage === "IL" ? 0 : 1], stage);
    // Get reader and writer information to display
    const { sourceStats, targetStats } = getProgressInformation(
        stage,
        sortedGroups,
        store.apps,
        status,
        mostRecentData
    );

    // Get Icons for the CDC App
    useEffect(() => setIcons(getIcons(store.apps, sortedGroups)), [sortedGroups]);
    const TrimmedComponentName = Icons?.sourceIcon?.name?.replace(/\b(?:Initial Load|CDC)/g, "") ?? "";
    useEffect(() => {
        (async () => {
            try {
                const appId = sortedGroups[stage === "IL" ? 0 : 1];
                if (!appId || !stage) {
                    return;
                }
                const data = await api.getTableCountForApp(appId, ["TARGET"]);
                const tablesCount = Number(Object.values(data)[0] ?? 0);
                setTablesCount(tablesCount);
            } catch (e) {
                console.log("Error while fetching tables count", e);
                setTablesCount(0);
            }
        })();
    }, [stage, sortedGroups, store.appsListStatsFetchedToggle]);

    useEffect(() => {
        Tracker.getInstance().track(TRACKER_STRINGS.schema.automatedAppDetails, {
            event: TRACKER_STRINGS.eventLabel.automatedAppDetails,
            sourceStats: sourceStats,
            targetStats: targetStats
        });
    }, []);

    return (
        <Grid sx={styles.progressContainer}>
            <ReaderInfo
                icon={Icons?.sourceIcon?.className}
                dbName={TrimmedComponentName}
                data={sourceStats}
                tablesCount={tablesCount}
                status={status}
            />
            <WriterInfo icon={Icons?.targetIcon?.className} dbName={Icons?.targetIcon?.name} data={targetStats} />
        </Grid>
    );
});

export default ProgressDetails;
