import { Box, Grid } from "@mui/material";
import { StriimButton, StriimTypography } from "@striim/striim-ui-v2";
import React, { ReactElement, useEffect } from "react";
import { AdapterIcon } from "../../../../../../generic/icon/component-icon/component-icon";
import AddIcon from "@mui/icons-material/Add";
import { styles } from "./create-app-page-adapters-selection.styles";
import { createAppUtils } from "../../create-app-page-utils";
import CreateAppPageAdapterSelectionLayout from "./adapters-selection-layout/create-app-page-adapter-selection-layout";
import utils from "../../../../../../../core/utils";
import { adapterType } from "../../create-app-page-templates";

type licenseChangesInfoProps = {
    defaultSource: String;
    defaultTarget: String;
    hideChangeButton: Boolean;
    hideCustomFlow: Boolean;
    hideTargetLabel: Boolean;
};

type CreateAppPageAdaptersSelectionProps = {
    type: string;
    adapter: {
        id: String;
        icon: ReactElement;
    };
    setAdapter: Function;
    expanded: boolean;
    setExpanded: Function;
    disabled?: boolean;
    sourceAdapter?: Object;
    handleChange: (value: adapterType, cb: () => void, type: string) => void;
    isFirstTimeUser: boolean;
    licenseChangesInfo: licenseChangesInfoProps;
};
interface AdapterTabProps {
    adapter: {
        spriteId: any;
        newlyReleased: boolean;
        id: String;
        icon: ReactElement;
    };
    disabled: boolean;
    expanded: boolean;
    licenseChangesInfo: licenseChangesInfoProps;
}

const getAdapters = (type: string, sourceAdapter: Object) => {
    if (type === "source") return createAppUtils.getSources();
    return createAppUtils.getTargets(sourceAdapter);
};

const CreateAppPageAdaptersSelection: React.FunctionComponent<CreateAppPageAdaptersSelectionProps> = ({
    type,
    adapter,
    setAdapter,
    disabled,
    sourceAdapter,
    expanded,
    setExpanded,
    handleChange,
    isFirstTimeUser,
    licenseChangesInfo
}) => {
    const adapters = getAdapters(type, sourceAdapter);
    const onSelect = adapter => {
        handleChange(adapter, setAdapter, type);
        setExpanded(false);
    };

    const resetSelection = () => {
        handleChange(null, setAdapter, type);
        setExpanded(type);
    };

    const AdapterTab: React.FunctionComponent<AdapterTabProps> = ({
        adapter,
        disabled,
        expanded,
        licenseChangesInfo
    }) => {
        const toHighlight = !(expanded || disabled);
        if (adapter) {
            return (
                <Box sx={{ ...styles.tabContainer, padding: adapter ? 2 : 3 }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Box sx={styles.adapterTabBox} data-test-id={`selected-${type}-${adapter.id}`}>
                            <AdapterIcon
                                spriteId={adapter.spriteId}
                                name={adapter.icon}
                                type={type}
                                useNative
                                size={40}
                            />
                            <Box sx={styles.adapterTabLabel}>
                                <StriimTypography color="greyscale.800" variant="caption3">
                                    {licenseChangesInfo.hideTargetLabel && type === "target"
                                        ? ""
                                        : utils.capitalize(type)}
                                </StriimTypography>
                                <StriimTypography
                                    color="greyscale.900"
                                    variant="body3"
                                    sx={styles.selectedAdapterLabel}
                                >
                                    {adapter.id}
                                </StriimTypography>
                            </Box>
                        </Box>
                        {!licenseChangesInfo.hideChangeButton && (
                            <StriimButton onClick={() => resetSelection()}>Change</StriimButton>
                        )}
                    </Grid>
                </Box>
            );
        } else {
            return (
                <Box
                    sx={{
                        ...styles.tabContainer,
                        padding: adapter ? 2 : 3,
                        ...(toHighlight && {
                            border: theme => `1px solid ${theme.palette.secondary[500]}`
                        })
                    }}
                    onClick={() => !disabled && setExpanded(type)}
                    data-test-id="select-target-container"
                >
                    <StriimTypography
                        variant="body3"
                        color={disabled ? "greyscale.400" : toHighlight ? "secondary.500" : "greyscale.900"}
                        sx={styles.selectLabel}
                    >
                        <AddIcon fontSize="inherit" /> Select {utils.capitalize(type)}
                    </StriimTypography>
                </Box>
            );
        }
    };

    return (
        <Grid>
            <>
                {expanded ? (
                    <CreateAppPageAdapterSelectionLayout
                        adapterList={adapters}
                        type={type}
                        onClose={() => setExpanded(false)}
                        handleSelection={onSelect}
                        isFirstTimeUser={isFirstTimeUser}
                    />
                ) : (
                    <AdapterTab
                        adapter={adapter}
                        disabled={disabled}
                        expanded={expanded}
                        licenseChangesInfo={licenseChangesInfo}
                    />
                )}
            </>
        </Grid>
    );
};

export default CreateAppPageAdaptersSelection;
