import Backbone from "backbone";
import _ from "underscore";
import $ from "jquery";
import debuggerConfig from "app/components/debug/debugger-config";
import FormBuilder from "app/components/common/editor/form-builder";
import UIControl from "app/components/common/editor/control/ui-control";
import HomeTemplate from "./templates/debug-home.html";
import debugFloaterTemplate from "./templates/debug-floater.html";
import { tooltipStriimlineHighcharts } from "../../styles/materialize/components-striimline/tooltip.export";

export function showFloatingView() {
    var $view = new FloatingView().render().$el;
    $("body").append($view);

    function toggleDebugMenu() {
        if (debuggerConfig.isDebugEnabled()) {
            $view.show();
        } else {
            $view.hide();
        }
    }

    debuggerConfig.on("change:debugEnabled", function() {
        toggleDebugMenu();
    });
    toggleDebugMenu();
}

const FloatingView = Backbone.Marionette.ItemView.extend({
    template: _.template(debugFloaterTemplate),
    events: {
        "click .start-stop-recording": "startStopRecording"
    },
    onRender: function() {
        this.setLabels();
        var that = this;
        debuggerConfig.on("change:debug-network-rate", function(inRate, outRate) {
            that.$el.find("#debug-msgs-in").html(inRate);
            that.$el.find("#debug-msgs-out").html(outRate);
            that.doGraph(inRate, outRate);
        });
    },
    setLabels: function() {
        if (debuggerConfig.isDebugNetworkRecording()) {
            this.$el.find(".start-stop-recording").html("Stop Logging");
        } else {
            this.$el.find(".start-stop-recording").html("Start Logging");
        }
    },
    startStopRecording: function() {
        debuggerConfig.setDebugNetworkRecording(!debuggerConfig.isDebugNetworkRecording());
        if (!debuggerConfig.isDebugNetworkRecording()) {
            debuggerConfig.persistAndClearNetworkLogs();
        }
        this.setLabels();
    },
    doGraph: function(inRate, outRate) {
        var inTime = new Date().getTime();
        var outTimeMills = new Date().getTime();
        outTimeMills += 200;
        var outTime = new Date(outTimeMills).getTime();

        if (this.chart) {
            this.chart.series[0].addPoint([inTime, inRate]);
            this.chart.series[1].addPoint([outTime, outRate]);

            if (this.chart.series[0].data.length > 30) {
                this.chart.series[0].removePoint(0, false);
            }
            if (this.chart.series[1].data.length > 30) {
                this.chart.series[1].removePoint(0, false);
            }
            this.chart.reflow();
        } else {
            this.chart = new Highcharts.Chart({
                chart: {
                    renderTo: "spark-line-throughput",
                    margin: [0, 0, 0, 0],
                    backgroundColor: "transparent",
                    animation: false
                },
                title: {
                    text: ""
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    labels: {
                        enabled: false
                    }
                },
                yAxis: {
                    maxPadding: 0,
                    minPadding: 0,
                    gridLineWidth: 0,
                    endOnTick: false,
                    labels: {
                        enabled: false
                    }
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    pointFormat: "",
                    headerFormat: "",
                    pointFormatter: function() {
                        return this.series.name + ": " + this.y + "records/s";
                    },
                    ...tooltipStriimlineHighcharts
                },
                plotOptions: {
                    series: {
                        enableMouseTracking: true,
                        lineWidth: 1,
                        shadow: false,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        marker: {
                            enabled: false,
                            radius: 0,
                            states: {
                                hover: {
                                    radius: 2
                                }
                            }
                        }
                    }
                },
                series: [
                    {
                        type: "line",
                        color: "#00A7E5",
                        name: "In",
                        data: [[inTime, inRate]]
                    },
                    {
                        type: "line",
                        color: "#0d874c",
                        name: "Out",
                        data: [[outTime, outRate]]
                    }
                ]
            });
        }
    }
});

export const ParentFrame = Backbone.Marionette.LayoutView.extend({
    template: _.template(HomeTemplate),
    className: "flexbox debug-page",
    regions: {
        toggleForm: ".toggle-form",
        frameContent: ".frame-content"
    },
    initialize: function() {
        this.model = new Backbone.Model({
            debugEnabled: debuggerConfig.isDebugEnabled(),
            disableInlineDocCache: sessionStorage.getItem("disableInlineDocCache") === "true"
        });
        var that = this;
        this.model.on("change:debugEnabled", function() {
            that.onChangeDebugEnabled();
        });
        this.model.on("change:disableInlineDocCache", (model) => {
            sessionStorage.setItem("disableInlineDocCache", that.model.get("disableInlineDocCache"))
            if(that.model.get("disableInlineDocCache")){
                for (let i = 0; i < sessionStorage.length; i++) {
                    let key = sessionStorage.key(i);
                    if (key.startsWith("inlinedoc::")) {
                        sessionStorage.removeItem(key);
                        i--;
                    }
                }
            }
        });
    },
    onShow: function() {
        var builderConfig = {
            model: this.model,
            ReadOnly: false,
            mapper: {
                debugEnabled: UIControl.Toggle,
                disableInlineDocCache: UIControl.Toggle,
            },
            labels: {
                debugenabled: {
                    name: "Enable Debug Mode"
                }
            },
            autoSave: true
        };

        var debugForm = new FormBuilder(builderConfig).create();
        this.getRegion("toggleForm").show(debugForm);
    },
    onChangeDebugEnabled: function() {
        debuggerConfig.setDebugEnabled(this.model.get("debugEnabled"));
    }
});
