const getAppType = adaptersWithTableMonitoring => {
    if (!adaptersWithTableMonitoring) {
        return null;
    }
    let type = null;
    Object.values(adaptersWithTableMonitoring).forEach(adapterType => {
        if (adapterType === "IL") {
            type = "IL";
        }
        if (adapterType === "CDC") {
            type = "CDC";
        }
    });
    return type;
};
export { getAppType };
