import React from "react";
import { Grid, Box } from "@material-ui/core";
import { Link } from "react-router-dom";

import APPS_ROUTES from "src/modules/apps/routes.json";

import { StriimButton, StriimTypography } from "@striim/striim-ui";

import useStyles from "./no-apps.styles";

const NoApps = () => {
    const classes = useStyles();
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.wrapper}
            id="appslist--empty"
        >
            <StriimTypography variant="h2">Add your first App</StriimTypography>
            <Box className={classes.noAppsImg} component="img" src="src/generic/icon/manage-striim/03-Apps-CreateApps.svg" />
            <Box component={StriimTypography} variant="subtitle1">
                When you create apps, you will see them listed here.
            </Box>
            <StriimButton component={Link} to={APPS_ROUTES.appCreate}>
                Create App
            </StriimButton>
        </Grid>
    );
};

export default NoApps;
