import UIControl from "app/components/common/editor/control/ui-control";

var Transformation = {};

Transformation.OnlyDbSources = false;

Transformation.createColumnControl = function () {
    return UIControl.JsonReader.extend({
        hideLabel: true,
        hidden: false,
    }).create();
};

Transformation.buildTql = function (tqlBuilder, columns) {
    columns.forEach((column) => {
        if (!column.column || !column.tqlFunction) {
            return;
        }

        tqlBuilder.addJsonColumn(column.fieldName, column.tqlFunction, column.column, column.alias);
    });
};

export default Transformation;
