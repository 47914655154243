import React from "react";
import { Grid, Box } from "@material-ui/core";
import { StriimTypography } from "@striim/striim-ui";
import { ManageAccounts } from "src/generic/icon/customIcons";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    wrapper: {
        height: `calc(100vh - 500px)`
    },
    icon: {
        height: 40,
        width: 40
    }
}));

const NoSearchResults = () => {
    const classes = useStyles();
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.wrapper}>
            <Grid item container justifyContent="center">
                <ManageAccounts className={classes.icon} />
            </Grid>
            <StriimTypography variant="bodySemibold" data-test-id="no-results">
                No results found, please try to search again
            </StriimTypography>
        </Grid>
    );
};

export default NoSearchResults;
