import api from "core/api/api";
import $ from "jquery";
import _ from "underscore";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";

var module = {};

module.cache = {};
module.loadAll = function() {
    var $def = new $.Deferred();
    if (!_.isEmpty(module.cache)) {
        $def.resolve();
    } else {
        var adapters = [];
        propertyTemplateService.propertyTemplates.each(function(model) {
            //TODO: To be removed once backend changes are added
            // model.set(
            //     "propertyMap",
            //     model.get("propertyMap").map(obj => ({
            //         ...obj,
            //         group: "Connection Properties"
            //     }))
            // );
            adapters.push(model.get("name"));
        });
        api.areTemplatesAllowed(adapters).then(function(data) {
            module.cache = data;
            $def.resolve();
        });
    }

    return $def;
};

module.clearCache = function() {
    module.cache = {};
};

module.checkIfEnabled = function(adapters) {
    var enabled = true;
    for (var i in adapters) {
        if (adapters.hasOwnProperty(i)) {
            var key = adapters[i];
            enabled = enabled && module.cache[key];
        }
    }

    return enabled;
};

module.checkIfEnabledSync = function(adapters) {
    if (!module.cache) {
        throw new Error("This method can only be used in sync after loadAll()");
    }
    return adapters.every(adapter => module.cache[adapter]);
};

export default {
    loadAll: module.loadAll,
    checkIfEnabled: module.checkIfEnabled,
    checkIfEnabledSync: module.checkIfEnabledSync,
    clearCache: module.clearCache
};
