import Backbone from "backbone";
import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./router-condition-editor.html";
import Toggle from "../toggle/toggle";
import Condition from "./condition";
import ConditionList from "./conditions-list";
import MetaObjectDataSource from "../select/metaobject-datasource";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import UIControl from "../ui-control";
import toggleFullScreen from "./../../../../flow/designer/toggleFullScreen";
import dialog from "app/components/common/dialogs/dialogWindow";

const Model = BaseControl.Model.extend({
    defaults: {
        value: null
    }
});

const View = BaseControl.View.extend({
    className: "router-condition-editor",
    template: _.template(template),

    regions: {
        groupByOutputStreamToggleRegion: ".group-by-output-stream-toggle",
        conditionsRegion: ".conditions",
        defaultOutputStreamRegion: ".default-output-stream"
    },

    ui: {
        header: ".header",
        conditions: ".conditions",
        else: ".else-condition",
        toggleFullScreen: ".toggle-full-screen",
        customize: ".customize",
        convertTql: ".convert-tql"
    },

    events: {
        "click @ui.toggleFullScreen": "toggleFullScreen",
        "click @ui.convertTql": "convertTql"
    },

    toggleFullScreen: function(e) {
        toggleFullScreen(this);
        e.stopPropagation(true);
    },

    convertTql: function() {
        const message = `TQL allows you to write customized queries. Once you save the router in the TQL mode, you cannot go back to the rule builder.`;
        dialog.confirm(message, null, null, "Edit rules using TQL", "Continue").then(result => {
            if (result === false) {
                return;
            }

            this.openTQLMode(true);
        });
    },

    getViewValue: function() {
        return {
            defaultOutputStream: this.defaultStreamSelector.getValue(),
            conditionalOutputStreams: this.getConditionsValue()
        };
    },

    getConditionsValue: function() {
        const conditions = this.conditions.toJSON();
        return conditions.map(condition => {
            if (condition.tqlMode) {
                return {
                    forwardingExpr: condition.forwardingExpr,
                    outputStream: condition.outputStream
                };
            } else {
                return {
                    attribute: condition.attribute,
                    condition: condition.condition,
                    value: condition.value,
                    outputStream: condition.outputStream,
                    attributeDataType:condition.dataTypeFromAttribute ?? condition.attributeDataType
                };
            }
        });
    },

    setViewValue: function() {
        //initial value
        const value = this.getValue();
        if (!value) {
            this.openTQLMode(false);
            return;
        }

        if (value.conditionalOutputStreams || value.defaultOutputStream) {
            if (value.defaultOutputStream) {
                this.defaultStreamSelector.setValue(value.defaultOutputStream);
            }

            if (value.conditionalOutputStreams) {
                value.conditionalOutputStreams.forEach((condition, index) => {
                    const conditionModel = this.conditions.models[index];
                    conditionModel.set("attribute", condition.attribute);
                    conditionModel.set("condition", condition.condition);
                    conditionModel.set("value", condition.value);
                    conditionModel.set("attributeDataType", condition.attributeDataType);
                });
            }
            this.openTQLMode(false);
        } else {
            this.openTQLMode(true);
        }
    },

    openTQLMode: function(tqlMode) {
        this.conditionsEditor.setTQLMode(tqlMode);
        if (tqlMode) {
            this.ui.customize.hide();
        } else {
            if (this.model.enabled) {
                this.ui.customize.show();
            }
        }
        this.trigger("tqlModeChanged");
    },
    setDefault: function() {
        if (this.model.fullScreen) {
            toggleFullScreen(this);
        }
    },
    valueChanged: function() {
        const viewValue = this.getViewValue();
        this.setValue(viewValue);
        this.trigger("value:set", this.getValue());
        this.setViewEnabled();
    },

    setType: function(type, changedType) {
        if (type) {
            this.$el.show();
        } else {
            this.$el.hide();
        }
        this.conditionsEditor.setType(type, changedType);
    },

    setForwardingRules: function(forwardingRules) {
        if (forwardingRules.length === 0) {
            return;
        }
        this.openTQLMode(true);
        const rules = [];
        forwardingRules.forEach(rule => {
            if (rule.forwardingExpr) {
                rules.push(
                    new Condition.Model({
                        forwardingExpr: rule.forwardingExpr,
                        outputStream: rule.outStream,
                        tqlMode: true
                    })
                );
            }
        });
        this.conditions.set(rules);
        const lastRule = forwardingRules[forwardingRules.length - 1];
        if (lastRule && !lastRule.forwardingExpr) {
            this.defaultStreamSelector.setValue(lastRule.outStream);
        }
    },

    initialize: function() {
        this.conditions = new Condition.Collection();
        this.toggle = Toggle.create();
        this.listenTo(this.toggle, "value:set", value => {
            this.conditionsEditor.setGroupBy(value);
        });

        this.conditionsEditor = new ConditionList({
            collection: this.conditions
        });
        this.conditionsEditor.on("modified", this.valueChanged.bind(this));

        const streams = new MetaObjectDataSource([metaStoreService.entities.STREAM]);
        this.defaultStreamSelector = UIControl.Select(streams, {
            addNew: true,
            type: metaStoreService.entities.STREAM
        }).create();
        this.listenTo(this.defaultStreamSelector.model, "change:value", () => this.valueChanged());
    },

    setViewEnabled: function() {
        BaseControl.View.prototype.setViewEnabled.apply(this);

        var enabled = this.getEnabled();
        this.model.enabled = enabled;
        if (!this.ui.convertTql.toggle) {
            return;
        }
        this.ui.convertTql.toggle(enabled);
        if (!enabled) {
            this.ui.customize.hide();
        }
        this.conditionsEditor.setEnabled(enabled);
        this.defaultStreamSelector.setEnabled(enabled);
    },

    onRender: function() {
        this.groupByOutputStreamToggleRegion.show(this.toggle);
        this.conditionsRegion.show(this.conditionsEditor);
        this.defaultOutputStreamRegion.show(this.defaultStreamSelector);
    }
});

function buildTextField() {
    return _.extend({}, BaseControl, {
        View: View,
        Model: Model
    });
}

export default buildTextField();
