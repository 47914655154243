import React from "react";
import { StriimTypography, StriimModal } from "@striim/striim-ui";

const DeleteModal = ({ showDeleteModal, setShowDeleteModal, onDeleteConfirm }) => {
    return (
        <StriimModal
            size="small"
            isVisible={showDeleteModal}
            onConfirm={onDeleteConfirm}
            onCancel={setShowDeleteModal}
            variant="error"
            confirmContent={"Delete"}
            confirmButtonProps={{ "data-test-id": "sso-confirm-delete-button" }}
            cancelButtonProps={{ "data-test-id": "sso-cancel-delete-button" }}
            titleContent={<StriimTypography variant="h2">Are you sure to delete SSO?</StriimTypography>}
        >
            <>
                <StriimTypography variant="body4">Please proceed to delete your SSO.</StriimTypography>
                <StriimTypography variant="body4">This action cannot be undone.</StriimTypography>
            </>
        </StriimModal>
    );
};

export default DeleteModal;
