import React from "react";
import { StriimChip } from "@striim/striim-ui";
import withTheme from "../theme/theme";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ spacing }) => ({
    label: {
        fontSize: 10
    },
    root: {
        padding: spacing(0.5, 1),
        marginLeft: spacing(0.5)
    }
}));

const Chip = props => {
    const styles = useStyles();
    return <StriimChip {...props} classes={{ label: styles.label, root: styles.root }}></StriimChip>;
};

export default withTheme(Chip);
