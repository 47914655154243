import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom/client";
import ReactApp from "./src/app";
import BackboneApp from "./backbone-app";
import api from "./core/api/api";

import { StriimTheme, StriimTypography } from "@striim/striim-ui";
import { Box } from "@material-ui/core";
import { checkIfUserLoggedIn, authStatus, initializeUser } from "./init";
import { observer } from "mobx-react-lite";
import Login from "./src/modules/login/login";
import App from "./app";
import LoadingIndicator from "./src/generic/loading-indicator/loading-indicator";
import userPreferences from "./core/services/userPreferences/user-preferences";

const container = document.getElementById("react-content");
const root = ReactDOM.createRoot(container);
const eventAggregator = App.vent;

const WebUI = observer(({ authStatus }) => {
    const [isWSLoaded, setIsWSLoaded] = useState(false);

    useEffect(() => {
        eventAggregator.on("onWebSocketOpen", async () => {
            let isLoggedIn = false;
            if (!App.hasStarted) {
                App.start();
            }
            try {
                isLoggedIn = await checkIfUserLoggedIn();
                api.call("SETUPDATEMODE", [true]);

                if (isLoggedIn) {
                    const userAuthenticated = await initializeUser();
                    isLoggedIn = isLoggedIn && userAuthenticated;
                }

                if (isLoggedIn) {
                    await userPreferences.load();
                }
            } catch (e) {
                console.error(e);
            } finally {
                authStatus.setIsLoggedIn(isLoggedIn);
                setIsWSLoaded(true);
            }
        });
        api.start({
            dispatcher: eventAggregator
        });
    }, []);
    return (
        <>
            {isWSLoaded ? (
                <>
                    {authStatus.isLoggedIn === true && (
                        <>
                            <BackboneApp dispatcher={eventAggregator} />
                            <ReactApp dispatcher={eventAggregator} />
                        </>
                    )}
                    {authStatus.isLoggedIn === false && (
                        <StriimTheme preselector="">
                            <Login />
                        </StriimTheme>
                    )}
                </>
            ) : (
                <StriimTheme preselector="">
                    <LoadingIndicator isGlobal={false} />
                </StriimTheme>
            )}
        </>
    );
});

root.render(<WebUI authStatus={authStatus} />);

eventAggregator.on("global:app:error", () => {
    root.render(
        <StriimTheme preselector="">
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <StriimTypography variant="h2">Connection to the server was lost</StriimTypography>
            </Box>
        </StriimTheme>
    );
});
