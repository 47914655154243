import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useObserver } from "mobx-react";
import { StriimInputField } from "@striim/striim-ui";
import { useField } from "formik";
import useNamespaceStore from "src/stores/namespaces/useNamespaceStore";
import NamespaceValidation from "./namespace.validation";

const NamespaceSelect = ({
    name,
    label = "Namespaces",
    className,
    disabled,
    doNotCreateNamespaces,
    namespaceOptions,
    required
}) => {
    const store = useNamespaceStore();

    const removeDuplicates = arr =>
        arr.filter((arr, index, self) => index === self.findIndex(t => t.label === arr.label && t.value === arr.value));

    const namespacesOptions = useObserver(() => {
        let result = store.getNamespaceOptions(true);
        if (store.newNamespace && result.map(x => x.value).indexOf(store.newNamespace.value) === -1) {
            result = result.concat([store.newNamespace]);
        }

        if (namespaceOptions?.length) {
            result = result.concat(namespaceOptions);
        }

        return removeDuplicates(result);
    });
    const [, { error, value, touched }, { setTouched, setValue, setError }] = useField(name);
    const [isInProgress, setIsInProgress] = useState(false);

    useEffect(() => {
        const asyncCall = async () => {
            setIsInProgress(true);
            await store.namespacesFetch();
            setIsInProgress(false);
        };

        asyncCall();
    }, [store]);

    const createNamespace = useCallback(
        async name => {
            if (doNotCreateNamespaces) {
                setIsInProgress(true);
                store.addNewNamespaceToOptions(name);
                setValue(name);
                setIsInProgress(false);
            } else {
                setIsInProgress(true);
                try {
                    NamespaceValidation.validateSync(name);
                    await store.namespaceCreate(name);
                    await store.namespacesFetch();
                    setValue(name);
                } catch (e) {
                    setTouched(true);
                    setError(e?.message);
                } finally {
                    setIsInProgress(false);
                }
            }
        },
        [doNotCreateNamespaces, store, setValue, setTouched, setError]
    );

    return (
        <StriimInputField
            required={required}
            className={className}
            id="namespace-select"
            select
            error={touched && !!error}
            helperText={touched && error}
            label={label}
            disabled={disabled || isInProgress}
            SelectProps={{
                options: namespacesOptions,
                selectVariant: "creatable",
                onCreateOption: createNamespace,
                name,
                onChange: data => {
                    setTouched(true);
                    setValue((data && data.value) || "");
                },
                onBlur: () => setTouched(true),
                value: (namespacesOptions && namespacesOptions.find(option => option.value === value)) || "",
                testid: "namespace-select"
            }}
        />
    );
};

NamespaceSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string
};

export default NamespaceSelect;
