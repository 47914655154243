const styles = {
    leftContainer: {
        height: "100vh",
        display: "flex",
        overflowY: "auto",
        justifyContent: "center",
        backgroundColor: ({ palette }) => palette.greyscale[50],
        border: "none",
        borderRadius: 0
    },
    formWrapper: {
        width: "350px",
        margin: "auto",
        "& form": {
            width: "100%"
        }
    },
    button: {
        width: "238px",
        height: "36px",
        margin: "auto"
    },
    loader: {
        position: "absolute",
        left: ({ spacing }) => spacing(2)
    },
    separatorContainer: {
        height: "20px",
        textAlign: "center",
        position: "relative",
        marginTop: ({ spacing }) => spacing(1),
        marginBottom: ({ spacing }) => spacing(1)
    },
    separator: {
        position: "absolute",
        color: ({ palette }) => palette.greyscale[700],
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
        letterSpacing: "0.14px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "100%",
        "&::before": {
            content: 'url("/app/images/separator.svg")',
            position: "relative",
            zIndex: 100,
            left: "-16px",
            top: "-3px"
        },
        "&::after": {
            content: 'url("/app/images/separator.svg")',
            position: "relative",
            zIndex: 100,
            right: "-16px",
            top: "-3px"
        }
    },
    logoContainer: {
        position: "relative",
        border: "none",
        color: ({ palette }) => palette.additional.white,
        height: "100vh",
        display: "flex",
        flexWrap: "nowrap",
        backgroundColor: ({ palette }) => palette.primary[700],
        flexDirection: "column",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: "url(/app/images/illustration.png)",
        flexGrow: 0,
        maxWidth: "50%",
        flexBasis: "50%"
    },
    logoBackground: {
        width: "100%",
        height: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPosition: "center",
        backgroundImage: 'url("/app/images/striim-logo.svg")'
    },
    footer: {
        color: ({ palette }) => palette.primary[300],
        fontFamily: "Inter, serif",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        letterSpacing: "0.24px",
        marginBottom: ({ spacing }) => spacing(4),
        textAlign: "center"
    },
    link: {
        color: ({ palette }) => palette.primary[300],
        fontFamily: "Inter, serif",
        fontSize: "12px",
        fontWeight: 400,
        padding: 0
    },
    messageBox: {
        width: "100%",
        marginBottom: ({ spacing }) => spacing(1),
        backgroundColor: ({ palette }) => palette.critical[50],
        borderColor: ({ palette }) => palette.critical[100],
        "& .MuiCardHeader-content": {
            fontWeight: 400,
            fontSize: 14,
            fontFamily: "Inter",
            color: ({ palette }) => palette.greyscale[800],
            lineHeight: "20px",
            letterSpacing: "0.14px"
        },
        "& span": {
            color: ({ palette }) => palette.greyscale[800]
        },
        "& svg": {
            fill: ({ palette }) => palette.critical[500]
        },
        "& a": {
            color: ({ palette }) => `${palette.secondary[500]} !important`
        }
    }
};

export default styles;
