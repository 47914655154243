import React, { useContext } from "react";
import FrameContainer from "../frame/frame";
import { Grid } from "@mui/material";
import styles from "./wizard-layout.styles";

interface LayoutProps {
    source?: string;
    target?: string;
    Header?: JSX.Element;
    Footer?: JSX.Element;
    showLoader?: boolean;
    children?: any;
    Stepper?: JSX.Element;
    inlineDocKey?:string;
}

const WizardLayout: React.FunctionComponent<LayoutProps> = ({
    children,
    source,
    target,
    Header,
    Footer,
    showLoader,
    Stepper,
    inlineDocKey
}) => {
    const hasStepper = !!Stepper;
    return (
        <Grid container sx={styles.wrapper}>
            <Grid item xs={6}>
                {hasStepper && Stepper}
            </Grid>
            <Grid item xs={10} sx={styles.frameWrapper}>
                <FrameContainer Header={Header} Footer={Footer} showLoader={showLoader} inlineDocKey={inlineDocKey}>
                    {children}
                </FrameContainer>
            </Grid>
        </Grid>
    );
};
export default WizardLayout;
