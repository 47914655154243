import _ from "underscore";
import BaseControl from "./../base-control";
import UIControl from "app/components/common/editor/control/ui-control";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import FieldDataSource, { CompositeFieldDataSource } from "./../select/field-datasource";
import template from "./column-join.html";
import MetaObjectDataSource from "app/components/common/editor/control/select/metaobject-datasource";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import { aliasBuilder } from "app/components/common/tqlBuilder";

const View = BaseControl.View.extend({
    template: _.template(template),
    className: "columns-splitter",

    regions: {
        columnSelectorRegion: ".column-selector",
        targetRegion: ".target",
        targetColumnSelectorRegion: ".target-column-selector",
        outputFieldsSelectorRegion: ".output-fields-selector",
    },

    setViewValue: function () {},

    getViewValue: function () {
        return {
            column: this.columnSelector.getValue(),
            target: this.target.getValue(),
            targetColumn: this.targetColumnSelector.getValue(),
            outputFields: this.outputFieldsSelector.getValue(),
        };
    },

    setEnabled: function (enabled) {
        BaseControl.View.prototype.setEnabled.apply(this, [enabled]);

        this.columnSelector.setEnabled(enabled);
        this.target.setEnabled(enabled);
        this.targetColumnSelector.setEnabled(enabled);
        this.outputFieldsSelector.setEnabled(enabled);
    },

    initialize: function () {
        this.columnSelector = UIControl.Select({}).create();
        const targetDataSource = MetaObjectDataSource(
            [metaStoreService.entities.CACHE, metaStoreService.entities.WINDOW, metaStoreService.entities.WACTIONSTORE],
            null,
            true
        );
        this.target = UIControl.Select(targetDataSource).create();
        this.targetColumnSelector = UIControl.Select([]).create();
        this.outputFieldsSelector = UIControl.ColumnsPicker.extend({
            hideLabel: true,
            hidden: false,
            transformationConfig: {
                addAvailable: true,
                autoFillAlias: true,
                getShortAliasName: true,
                labels: {
                    column1: "Field Name",
                    column2: "Alias",
                },
            },
        }).create();
    },

    onRender: function () {
        this.columnSelectorRegion.show(this.columnSelector);
        this.targetRegion.show(this.target);
        this.targetColumnSelectorRegion.show(this.targetColumnSelector);
        this.outputFieldsSelectorRegion.show(this.outputFieldsSelector);

        if (this.model.value && this.model.value.length > 0 && this.model.value[0]) {
            const value = this.model.value[0];
            this.columnSelector.setValue(value.column);
            this.target.setValue(value.target);
            this.targetColumnSelector.setValue(value.targetColumn);
            this.outputFieldsSelector.setValue(value.outputFields);
        }

        this.listenTo(this.columnSelector.model, "change:value", () => {
            this.setValueFromView();
        });

        this.listenTo(this.target.model, "change:value", async () => {
            await this._ensureTargetField();
            await this._ensureOutputFields();
            await this._populateOutputFieldsControl();
            this.setValueFromView();
        });

        this.listenTo(this.targetColumnSelector.model, "change:value", () => {
            this.setValueFromView();
        });

        this.listenTo(this.outputFieldsSelector.model, "change:value", () => {
            this.setValueFromView();
        });
    },

    _getTypeId: async function (metaObjectId) {
        const componentModel = await metaStoreService.findById(metaObjectId);
        if (!componentModel) {
            return;
        }

        return componentModel.type === metaStoreService.entities.CACHE
            ? componentModel.typename
            : componentModel.dataType;
    },

    _getFieldDataSource: async function (componentId) {
        if (!componentId) {
            return [];
        }

        const fieldName = await this._getTypeId(componentId);
        return new FieldDataSource(fieldName);
    },

    _ensureTargetField: async function () {
        const fieldDataSource = await this._getFieldDataSource(this.getViewValue().target);
        this.targetColumnSelector.setDataSource(fieldDataSource);
    },

    _ensureOutputFields: async function () {
        const outputFieldsDataSource = await this._getOutputFieldsDataSource(this.streamId, this.getViewValue().target);
        if (outputFieldsDataSource.length === 0) {
            return;
        }

        this.outputFieldsSelector.$el.closest(".field-row").show();
        this.outputFieldsSelector.columnsDataSource = outputFieldsDataSource;
    },

    _populateOutputFieldsControl: async function () {
        this.outputFieldsSelector.gridView.setColumnsDataSource(this.outputFieldsSelector.columnsDataSource);
        this.outputFieldsSelector.columnsReset();
    },

    _getOutputFieldsDataSource: async function (sourceId, targetId) {
        if (!sourceId || !targetId) {
            return [];
        }

        const sourceTypeId = await this._getTypeId(sourceId);
        const targetTypeId = await this._getTypeId(targetId);

        return new CompositeFieldDataSource([
            { type: sourceTypeId, prefix: aliasBuilder.getAlias(sourceId) },
            { type: targetTypeId, prefix: aliasBuilder.getAlias(targetId, true) },
        ]);
    },

    setStream: async function (componentId) {
        this.streamId = componentId;
        const fieldDataSource = await this._getFieldDataSource(componentId);
        this.columnSelector.setDataSource(fieldDataSource);

        await this._ensureTargetField();
        await this._ensureOutputFields();
        if (this.model.value && this.model.value.length > 0 && this.model.value[0].outputFields.length === 0) {
            await this._populateOutputFieldsControl();
        }
    },
});

const Model = BaseControl.Model.extend({
    defaults: {
        value: Array,
    },
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
