import React, { useState, useEffect } from "react";
import { StriimTypography, StriimModal, StriimControlLabel, StriimCheckbox } from "@striim/striim-ui";
import { Box, Grid } from "@mui/material";
import App from "app";
import exceptionStoreApi from "app/components/flow/designer/app-exceptions/exception-store-api";
import { AppAction } from "src/status-management";
import AppControl from "app/components/common/app-control/app-control";
import LoadingIndicator from "../loading-indicator/loading-indicator";
import growl from "../../../app/components/common/growl";
import {
    getCDCName,
    getTruncatedName,
    isAutomatedWizard
} from "../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";
import metaStoreService from "../../../core/services/metaStoreService/meta-store-service";
import metaobjectConverter from "../../../core/services/metaStoreService/metaobject-converter";
import useStores from "../../utils/use-stores";
import DeleteGroupModal from "../../modules/apps/pages/app-list/components/app-group/delete-group-modal";
import Tracker from "../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../core/services/tracker/constants";

type appType = {
    name: string;
    id: string;
    nsName: string;
    shortName: string;
};
type storeType = {
    onAppAction: (app: appType, action: string, extraparameters: Object) => void | Promise<void>;
};

interface dropAppModalProps {
    store: storeType;
    app: appType;
    dropModalVisible: boolean;
    setDropModalVisible: (value: boolean) => void;
    onConfirmDrop?: () => Promise<void>;
    onExportApp?: () => void;
    isILCDC: boolean;
}

const styles = {
    modalWrapper: {
        "& .MuiDialogContent-root": {
            overflow: "anywhere"
        }
    },
    dialogContent: {
        overflowWrap: "anywhere"
    },
    checkboxContainer: {
        display: "flex"
    },
    learnLink: {
        height: "40px",
        display: "inline-block",
        marginLeft: "8px",
        lineHeight: "40px",
        fontWeight: "bold"
    },
    LoadingIndicator: {
        width: "100%"
    },
    modalContent: {
        padding: 0
    }
};

const DropAppModal = ({
    store,
    app,
    dropModalVisible,
    setDropModalVisible,
    onConfirmDrop,
    onExportApp,
    isILCDC
}: dropAppModalProps) => {
    const [doNotDeleteExceptionStore, setDoNotDeleteExceptionStore] = useState<boolean>(false);
    const [exceptionStoreEnabled, setExceptionStoreEnabed] = useState<boolean>(false);
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const { groupsStore } = useStores();
    const appName = metaobjectConverter.getName(app.shortName);

    useEffect(() => {
        exceptionStoreApi.checkExceptionsEnabled(app.id).then((isEnabled: string) => {
            if (isEnabled) {
                setExceptionStoreEnabed(true);
            }
            setDoNotDeleteExceptionStore(false);
        });
    }, []);

    // Deletes group if the group exists
    const deleteGroupIfExists = async (groupName: string) => {
        const isGroupPresent = async (groupName: string) => {
            await groupsStore.fetch();
            return groupsStore.groups.some(group => group.name === groupName);
        };
        const groupExists = await isGroupPresent(groupName);
        if (groupExists) {
            await groupsStore.delete(groupName);
            await groupsStore.fetch();
        }
    };

    const dropApplication = async () => {
        try {
            setPageLoading(true);
            await store.onAppAction(app, AppAction.DROP, { doNotDeleteExceptionStore: doNotDeleteExceptionStore });
            if (isAutomatedWizard()) {
                const cdcAppId = getCDCName(app.id);
                const cdcApp = await metaStoreService.findById(cdcAppId);
                if (cdcApp) {
                    const cdcAppJSON = cdcApp.toJSON();
                    cdcAppJSON.shortName = metaobjectConverter.convertFullNameToShortName(cdcAppId);
                    await store.onAppAction(cdcAppJSON, AppAction.DROP, {
                        doNotDeleteExceptionStore: doNotDeleteExceptionStore
                    });
                }
                const groupName = getTruncatedName(metaobjectConverter.getName(app.id));
                await deleteGroupIfExists(groupName);
            }
            onConfirmDrop && (await onConfirmDrop());
            App.vent.trigger("app:deleted", app.id);
        } catch (e) {
            // Because of strange case the `error` can be undefined
            growl.error(
                "Unable to drop app! " +
                    (e?.message || "") +
                    ". Resolve the errors before retry or force drop app. Contact Striim support if problem persists.",
                "Error"
            );
        } finally {
            setPageLoading(false);
            setDropModalVisible(false);
        }
    };
    const exportApp = async () => {
        setDropModalVisible(false);
        try {
            const exportApp = async (appId: string) => {
                const app = await metaStoreService.findById(appId);
                const appControl = new AppControl({
                    appIdentifier: app
                });
                appControl.export();
            };
            exportApp(app.id);
            if (isAutomatedWizard()) {
                exportApp(getCDCName(app.id));
            }
            onExportApp && onExportApp();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box sx={styles.modalWrapper}>
            {isILCDC ? (
                <DeleteGroupModal
                    group={{
                        groupType: "ILCDC",
                        name: appName,
                        applications: [appName, getCDCName(appName)]
                    }}
                    isVisible={dropModalVisible}
                    onConfirm={dropApplication}
                    onCancel={() => setDropModalVisible(false)}
                    pageLoading={pageLoading}
                />
            ) : (
                <StriimModal
                    size="medium"
                    variant="error"
                    isVisible={dropModalVisible}
                    centerContent
                    autoHeight
                    onCancel={(state: boolean) => setDropModalVisible(state)}
                    onConfirm={dropApplication}
                    titleContent={<StriimTypography variant="h2">Drop App</StriimTypography>}
                    confirmContent="Drop"
                    confirmButtonProps={{
                        "data-test-id": "drop-app-modal-confirm-btn",
                        disabled: pageLoading
                    }}
                    cancelButtonProps={{
                        disabled: pageLoading
                    }}
                    dialogProps={{
                        "data-testid": "delete-app-modal",
                        sx: { ".MuiDialogContent-root": styles.modalContent }
                    }}
                >
                    {pageLoading && (
                        <Box sx={styles.LoadingIndicator} mb={1}>
                            <LoadingIndicator isGlobal={false} />
                        </Box>
                    )}
                    <Grid py={0} px={3}>
                        <StriimTypography variant="body4">
                            <span>
                                Are you sure you want to drop <strong>{app?.name}</strong> application? We recommend{" "}
                                <a id="export-button" className="cta-link" onClick={exportApp}>
                                    exporting your work to a TQL file
                                </a>{" "}
                                first. These changes cannot be undone.
                            </span>
                            {exceptionStoreEnabled && (
                                <Box sx={styles.checkboxContainer}>
                                    <StriimControlLabel
                                        control={
                                            <StriimCheckbox
                                                data-test-id="do_not_delete_logs_checkbox"
                                                onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
                                                    setDoNotDeleteExceptionStore(value.target.checked)
                                                }
                                            />
                                        }
                                        label="Do not delete the exception logs"
                                    />
                                    <a
                                        style={styles.learnLink}
                                        href="/onlinedocs/en/index-en.html?contextId=ddl_create_exceptionstore"
                                        target="_blank"
                                        onClick={() => {
                                            Tracker.getInstance().track(TRACKER_STRINGS.schema.learnMore, {
                                                event: TRACKER_STRINGS.eventClicked.learnMore
                                            });
                                            return true;
                                        }}
                                    >
                                        Learn more
                                    </a>
                                </Box>
                            )}
                        </StriimTypography>
                    </Grid>
                </StriimModal>
            )}
        </Box>
    );
};

export default DropAppModal;
