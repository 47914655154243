const storageKey = "last-validation-results";

import userPreferences from "core/services/userPreferences/user-preferences";

export const connectionStatuses = {
    unknown: 0,
    ok: 1,
    driversNotFound: 2,
    connectionFailed: 3
};

export const getStatusDisplayName = status => {
    switch (status) {
        case connectionStatuses.ok:
            return "Connection valid";
        case connectionStatuses.driversNotFound:
            return "Drivers missing";
        case connectionStatuses.connectionFailed:
            return "Connection failed";
    }
    return "";
};

const load = (componentId, adapterType) => {
    var lastValidationResults = userPreferences.get(storageKey);
    if (!lastValidationResults) {
        return null;
    }

    const componentResult = lastValidationResults[componentId];
    if (componentResult && componentResult.adapterType === adapterType) {
        return componentResult.results;
    }
};

const save = (componentId, adapterType, validationResult) => {
    var lastValidationResults = userPreferences.get(storageKey);
    if (!lastValidationResults) {
        lastValidationResults = {};
    }

    if (validationResult) {
        lastValidationResults[componentId] = {
            adapterType: adapterType,
            results: validationResult
        };
    } else {
        if (lastValidationResults[componentId]) {
            delete lastValidationResults[componentId];
        }
    }

    userPreferences.put(storageKey, lastValidationResults);
    return new Promise(resolve => {
        resolve();
    });
};

const module = {
    load: load,
    save: save
};

export default module;
