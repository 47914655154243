import App from "app";
import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import api from "core/api/api";
import PollingQueryState from "./PollingQueryState";
import filterModel from "core/filterModel";
import { getNamedQuery, getFilteredQuery } from "core/services/queryService/queryService";
import globalProgress from "core/services/globalProgress";

export default function (queryToPoll) {
    var _this = this;
    _.extend(this, Backbone.Events);

    var query = null;
    var queryParams = {};
    var timeoutBetweenSubsequentQueries = 5000;
    var timeoutBetweenStartAndStopAHQuery = 1000;

    var state = new PollingQueryState();
    var _loadDeferred = null;

    var stopQuery = function () {
        query.off("onDataLoaded");
        return query.destroy();
    };

    var load = function () {
        _loadDeferred = $.Deferred();

        var initialDeferred = query;

        var pageParams = App.request("QueryManager:getParameters");
        var hasPageParamsBeenApplied = !$.isEmptyObject(pageParams);
        var newQueryParams = hasPageParamsBeenApplied ? pageParams : filterModel.getParameters(queryToPoll.uuid);

        if (query && JSON.stringify(newQueryParams) !== JSON.stringify(queryParams)) {
            globalProgress.start();
            stopQuery().then(function () {
                queryParams = {};
                query = null;
                _loadDeferred.resolve();
            });
            return _loadDeferred.promise();
        }

        if (!query) {
            initialDeferred = $.Deferred();
            var method = !hasPageParamsBeenApplied && queryToPoll.canBeFiltered ? getFilteredQuery : getNamedQuery;
            method
                .call(
                    filterModel,
                    queryToPoll.uuid,
                    filterModel.prepareParametersForQuery(queryToPoll.uuid, newQueryParams)
                )
                .then(function (q) {
                    state.onQueryCreated();
                    queryParams = $.extend(true, {}, newQueryParams);
                    query = q;
                    initialDeferred.resolve(query);
                    query.on("onDataLoaded", function (data) {
                        _this.trigger("onDataLoaded", data);
                        state.onDataReceived(data);
                    });
                })
                .always(function () {
                    globalProgress.stop();
                });
        }

        if (state.shouldSkipQuery()) {
            _loadDeferred.resolve();
        } else {
            $.when(initialDeferred).then(function (query) {
                query.execute(timeoutBetweenStartAndStopAHQuery, state).then(function () {
                    if (_loadDeferred.state() !== "resolved") {
                        _loadDeferred.resolve();
                    }
                });
                query.once("onQueryFailed", function () {
                    if (_loadDeferred.state() !== "resolved") {
                        _loadDeferred.resolve();
                    }
                });
            });
        }

        return _loadDeferred.promise();
    };

    var loadContinuously = function () {
        var now = new Date().getTime();
        if (state.isDisabled()) {
            return;
        }
        load().then(function () {
            var then = new Date().getTime();
            var timeTaken = then - now;
            if (timeTaken > timeoutBetweenSubsequentQueries) {
                loadContinuously(); // it took greater than 5 seconds to complete the query, so start executing again right away...
            } else {
                setTimeout(loadContinuously, timeoutBetweenSubsequentQueries - timeTaken); // it took less than 5 seconds to complete the query, so wait until the 5 second mark has passed before executing again
            }
        });
    };

    this.start = function () {
        this.listenTo(filterModel, "params-changed", function (queryUUID) {
            if (queryUUID !== "global") {
                if (queryToPoll.uuid !== queryUUID) {
                    return;
                }
            }

            if (!_loadDeferred) {
                return;
            }

            if (query) {
                stopQuery().then(function () {
                    query = null;
                    _loadDeferred.resolve();
                });
            } else {
                _loadDeferred.resolve();
            }
        });
        state.enable();
        loadContinuously();
    };

    this.stopPolling = function () {
        if (!query) {
            return;
        }

        query.off("onDataLoaded");
    };

    this.pause = function () {
        state.pause();
    };

    this.resume = function () {
        state.resume();
    };

    this.stop = function () {
        state.disable();
        this.stopListening(filterModel);

        if (query) {
            stopQuery();
        }
    };
}
