import React, { useEffect, useMemo } from "react";

import { useUserManagement } from "core/user-management/user-management-context";
import RoleTable from "../common/role-table";
import PermissionTable from "../common/permissions-table";
import BasicInfo from "./components/basic-info";
import { Grid, LinearProgress } from "@mui/material";
import styles from "../../../common/users-management.styles";

const UserDetails = ({ id }) => {
    const { userManagementProviderBusy, userDetails, getUserDetailsById } = useUserManagement();

    useEffect(() => {
        getUserDetailsById(id);
    }, [getUserDetailsById, id]);

    const userRoles = useMemo(
        () =>
            (userDetails?.roles || []).map(v => ({
                id: v.name,
                ...v
            })),
        [userDetails]
    );

    const userPermissions = useMemo(() => userDetails?.permissions || [], [userDetails]);

    return userManagementProviderBusy ? (
        <Grid item xs={12}>
            <LinearProgress color="secondary" />
        </Grid>
    ) : (
        <Grid item xs={12} sx={styles.contentContainer}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <BasicInfo userDetails={userDetails} />
                </Grid>
                <Grid item xs={12}>
                    <RoleTable userRoles={userRoles} />
                </Grid>
                <Grid item xs={12}>
                    <PermissionTable userPermissions={userPermissions} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserDetails;
