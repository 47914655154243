import React, { useCallback, useMemo, useRef, useState } from "react";
import { Grid, SvgIcon } from "@mui/material";
import { StriimDataTable, StriimTypography, StriimChip, StriimDropdown } from "@striim/striim-ui";

import { styles } from "./source-details-table.styles";
import WithTooltip from "../../../../../generic/tooltip/tooltip";
import InfoIcon from "app/images/striimline/info-icon.svg";
import { SpecialChevronDown } from "../../../../../generic/icon/customIcons";
import { TableData } from "../entity-table/utils";

const CustomHeader = props => {
    const { columnHeader } = props;
    return (
        <Grid container gap={0.5}>
            {columnHeader}
            {/* TODO: Replace tooltip content when its available */}
            {WithTooltip(<SvgIcon component={InfoIcon} sx={styles.infoIcon} />, <>Test</>)}
        </Grid>
    );
};

interface SourceDetailsTableProps {
    tableData: TableData[];
    entityName: string;
    eventType: string;
    fieldType: string;
    onClick: Function;
}

const SourceDetailsTable = ({ tableData, entityName, eventType, fieldType, onClick }: SourceDetailsTableProps) => {
    const gridRef = useRef();
    const [sensitiveDataIdentifiersCellWidth, setSensitiveDataIdentifiersCellWidth] = useState(285);

    const tableColumns = useMemo(
        () => [
            {
                headerComponent: CustomHeader,
                headerComponentParams: {
                    columnHeader: `${entityName} Name (${tableData.length})`
                },
                field: "name",
                flex: 1,
                suppressMovable: true,
                resizable: true,
                sortable: true,
                cellRenderer: data => (
                    <StriimTypography
                        color="secondary.500"
                        variant="body4"
                        onClick={() => {
                            onClick(data.data);
                        }}
                    >
                        {data.value}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: "Sensitive Data Identifiers",
                field: "sensitiveDataIdentifiers",
                initialWidth: 290,
                suppressMovable: true,
                resizable: true,
                cellStyle: { display: "flex", alignItems: "center", paddingLeft: "10px", paddingRight: "10px" },
                headerClass: "no-hover",
                cellRenderer: ({ value }) => {
                    //20 is horizontal padding
                    let remainingWidth = sensitiveDataIdentifiersCellWidth ? sensitiveDataIdentifiersCellWidth - 20 : 0;
                    let breakMap = false;
                    return (
                        <Grid container gap={0.5}>
                            {value.map((item, index) => {
                                //12 = horizontal padding of the chip, 12 = gap b/w chips, 8 ~ width for each char
                                const currentWidth = item.length * 8 + 12 + 22;
                                const remainingIdentifiers = value.slice(index);
                                if (breakMap) return;
                                if (currentWidth > remainingWidth) {
                                    breakMap = true;
                                    return (
                                        <StriimDropdown
                                            key={item}
                                            hover
                                            closeOnMouseLeave
                                            placement="bottom"
                                            content={
                                                <Grid container gap={1} p={2}>
                                                    {remainingIdentifiers.map(item => (
                                                        <StriimChip
                                                            key={item}
                                                            size="large"
                                                            type="tag-chip"
                                                            variant="intresting"
                                                            label={item}
                                                            sx={styles.dropdownChip}
                                                        />
                                                    ))}
                                                </Grid>
                                            }
                                        >
                                            <StriimChip
                                                size="large"
                                                type="tag-chip"
                                                variant="intresting"
                                                label={
                                                    <>
                                                        +{remainingIdentifiers.length}{" "}
                                                        <SvgIcon
                                                            component={SpecialChevronDown}
                                                            sx={styles.chevronIcon}
                                                        />
                                                    </>
                                                }
                                                sx={styles.dropdownChip}
                                            />
                                        </StriimDropdown>
                                    );
                                } else {
                                    //22 = width of the +x tag
                                    remainingWidth = remainingWidth - currentWidth + 22;
                                    return (
                                        <StriimChip
                                            size="large"
                                            type="tag-chip"
                                            variant="intresting"
                                            label={item}
                                            sx={styles.chip}
                                        />
                                    );
                                }
                            })}
                        </Grid>
                    );
                }
            },
            {
                headerName: `${fieldType}s w/ Sensitive Data`,
                field: "columnsWithSensitiveData",
                suppressMovable: true,
                resizable: true,
                sortable: true,
                flex: 0.7,
                cellClass: "cell-right",
                cellRenderer: ({ value }) => (
                    <StriimTypography variant="body4" color="greyscale.900">
                        {value}
                    </StriimTypography>
                )
            }
        ],
        [tableData, entityName, sensitiveDataIdentifiersCellWidth]
    );

    const onColumnResized = useCallback(params => {
        const column = params?.columns?.find(v => v.colId === "sensitiveDataIdentifiers");
        if (column) {
            setSensitiveDataIdentifiersCellWidth(column.actualWidth);
        }
    }, []);

    return (
        <Grid width="100%" maxHeight={"90%"}>
            <StriimDataTable ref={gridRef} onColumnResized={onColumnResized} data={tableData} columns={tableColumns} />
        </Grid>
    );
};

export default SourceDetailsTable;
