import api from "core/api/api";
import App from "app";
import file_save_template from "./file_save_template.html";
import file_save_modal from "./file_save_modal.html";
import save_sample_template from "./save-sample-template.html";
import growl from "app/components/common/growl";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import namespaceFactory from "app/components/createapp/namespace-factory";
import formBuilderUtils from "app/components/common/editor/meta-object-editors";
import metaObjects from "core/services/metaStoreService/meta-objects";
import UIControl from "app/components/common/editor/control/ui-control";
import FormBuilder from "app/components/common/editor/form-builder";
import SimpleMetaObjectDataSource from "app/components/common/editor/control/select/simple-metaobject-datasource";
import "app/components/common/_deprecated/form-mixin-dialog/dialog";
import ensureRegions from "../common/_ensureRegions";

var saveModal = {};
saveModal.View = App.FormMixinDialog.View.extend({
    template: _.template(file_save_modal),
    regions: {
        toggleRegion: "#toggle-region",
        saveRegion: "#save-region"
    },
    events: {
        "click #save-button:not(.disabled)": "onSave",
        "click #next-button:not(.disabled)": "onNext"
    },
    initialize: function(options) {
        options.classes = "large";
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
        this.delegateEvents();
    },
    onNext: function() {
        this.showSaveButton();
        this.saveView.trigger("show:sourceSetup");
    },
    onSave: function() {
        var that = this;
        if (!this.saveView.saveModel.get("app")) {
            this.addApp(this.saveView.newAppModel).then(function() {
                that.doCreateSource(that.saveView.newAppModel.get("id"));
            });
        } else {
            this.doCreateSource(this.saveView.saveModel.get("app"));
        }
    },
    addApp: function(appModel) {
        ensureRegions(this);
        var deferred = new $.Deferred();
        var validationError = appModel.validate();

        if (validationError || !appModel.get("nsName") || !appModel.get("name")) {
            appModel.save();
        } else {
            namespaceFactory
                .create(appModel.get("nsName"))
                .then(function() {
                    metaStoreService
                        .findById(appModel.get("nsName") + ".APPLICATION." + appModel.get("name"))
                        .then(function(app) {
                            if (app) {
                                var error =
                                    "Please delete the existing application " +
                                    appModel.get("name") +
                                    " or create the new application with a different name or in a different namespace";
                                growl.error(error, "Duplicate Application");
                            } else {
                                appModel
                                    .save()
                                    .then(function() {
                                        growl.success("Created the new application");
                                        deferred.resolve();
                                    })
                                    .fail(function(error) {
                                        deferred.reject(error);
                                    });
                            }
                        })
                        .fail(function(error) {
                            growl.error(error);
                            deferred.reject(error);
                        });
                })
                .fail(function(error) {
                    growl.error(error);
                    deferred.reject(error);
                });
        }

        return deferred.promise();
    },
    doCreateSource: function(app_id) {
        ensureRegions(this);
        var chunks = app_id.split(".");
        var nsName = chunks[0];
        var app_name = chunks[2];
        var def = null;
        var that = this;

        if (this.saveView.saveModel.get("type") === "source") {
            def = this.saveSourceModel(nsName, app_name);
        } else {
            def = this.saveCacheModel(nsName, app_name);
        }

        def.then(function() {
            App.navigate("#/flow/" + nsName + "." + app_name, {
                trigger: true
            });
        }).fail(function(e) {
            var errorMsg = typeof e === "string" ? e : undefined;
            growl.error(errorMsg);
            that.close();
        });
    },

    getParserType: function() {
        ensureRegions(this);
        var type = this.model.attributes.parserType;
        switch (type) {
            case "DSV":
                return "Global.PROPERTYTEMPLATE.DSVParser";
            case "AAL":
                return "Global.PROPERTYTEMPLATE.AALParser";
            case "FFT":
                return "Global.PROPERTYTEMPLATE.FreeFormTextParser";
        }
    },
    saveSourceModel: function(nsName, app_name) {
        ensureRegions(this);
        var def = $.Deferred();
        var that = this;

        var model = new metaObjects.SOURCE.Model();
        model.set("name", this.saveView.saveModel.get("name"));
        var reader = this.model.attributes.readerType;
        var adapter;

        switch (reader) {
            case "HDFSReader":
                adapter = {
                    handler: "Global.PROPERTYTEMPLATE.HDFSReader",
                    properties: {
                        Wildcard: this.saveView.saveModel.get("wildcard"),
                        hadoopurl: this.saveView.saveModel.get("directory"),
                        PositionByEof: false
                    }
                };
                break;
            case "FileReader":
                adapter = {
                    handler: "Global.PROPERTYTEMPLATE.FileReader",
                    properties: {
                        Wildcard: this.saveView.saveModel.get("wildcard"),
                        Directory: this.saveView.saveModel.get("directory"),
                        PositionByEof: false
                    }
                };
                break;
        }
        model.set("adapter", adapter);

        var parser = {
            handler: this.getParserType(),
            properties: this.getParserProperties()
        };
        model.set("parser", parser);
        model.set("nsName", nsName);
        model.set("outputStream", nsName + ".STREAM." + this.saveView.saveModel.get("name") + "_Stream");

        // DEV-8471 and DEV-8477 - Temporary solution!! TODO Please remove after implementing new editor for source.
        model.set("outputclause", [
            {
                outputStream: model.get("outputStream")
            }
        ]);
        model
            .save({
                flow: app_name
            })
            .then(function() {
                that.createTypeCastedCQ(nsName, app_name, that.saveView.saveModel.get("name") + "_Stream")
                    .then(function() {
                        def.resolve();
                    })
                    .fail(function() {
                        def.reject("Could not create CQ");
                    });
            })
            .fail(function() {
                def.reject("Could not create source");
            });

        return def;
    },

    createTypeCastedCQ: function(nsName, appName, sourceOutputStreamName) {
        ensureRegions(this);
        var fields = this.model.get("columns");
        var that = this;
        var model = new metaObjects.CQ.Model();
        model.set("output", nsName + ".STREAM." + this.saveView.saveModel.get("name") + "_TransformedStream");
        model.set("nsName", nsName);
        model.set("name", sourceOutputStreamName + "_CQ");

        var select = "SELECT ";
        var select_chunks = [];

        _.each(fields, function(field, i) {
            if (field.discard === "false") {
                if (field.type === "DateTime" && field.format) {
                    select_chunks.push(
                        that.getTransformationFunction(field.type) +
                            "(data[" +
                            i +
                            "], " +
                            '"' +
                            field.format +
                            '"' +
                            ") as " +
                            field.name +
                            "\n"
                    );
                } else {
                    select_chunks.push(
                        that.getTransformationFunction(field.type) + "(data[" + i + "]) as " + field.name + "\n"
                    );
                }
            }
        });
        select += select_chunks.join(",");
        select += " FROM " + sourceOutputStreamName;
        model.set("select", select);
        return model.save({
            flow: appName
        });
    },

    getTransformationFunction: function(datatype) {
        ensureRegions(this);
        datatype = datatype.toLowerCase();
        switch (datatype) {
            case "byte":
                return null;
            case "double":
                return "TO_DOUBLE";
            case "float":
                return "TO_FLOAT";
            case "integer":
                return "TO_INT";
            case "long":
                return "TO_LONG";
            case "short":
                return "TO_SHORT";
            case "string":
                return "TO_STRING";
            case "datetime":
                return "TO_DATE";
        }
    },
    saveCacheModel: function(nsName, app_name) {
        ensureRegions(this);
        var cacheModel = new metaObjects.CACHE.Model();
        var that = this;
        var def = $.Deferred();
        cacheModel.set("name", this.saveView.saveModel.get("name"));
        var adapter;
        var reader = this.model.attributes.readerType;
        switch (reader) {
            case "HDFSReader":
                adapter = {
                    handler: "Global.PROPERTYTEMPLATE.HDFSReader",
                    properties: {
                        Wildcard: this.saveView.saveModel.get("wildcard"),
                        hadoopurl: this.saveView.saveModel.get("directory"),
                        PositionByEof: false
                    }
                };
                break;
            case "FileReader":
                adapter = {
                    handler: "Global.PROPERTYTEMPLATE.FileReader",
                    properties: {
                        Wildcard: this.saveView.saveModel.get("wildcard"),
                        Directory: this.saveView.saveModel.get("directory"),
                        PositionByEof: false
                    }
                };
                break;
        }
        cacheModel.set("adapter", adapter);

        var parser = {
            handler: this.getParserType(),
            properties: this.getParserProperties()
        };
        cacheModel.set("parser", parser);
        cacheModel.set("nsName", nsName);

        var fields = this.model.get("columns");
        var typeModel = new metaObjects.TYPE.Model();
        typeModel.set("generated", true);
        typeModel.set("name", this.saveView.saveModel.get("name") + "Type");
        typeModel.set("nsName", nsName);
        typeModel.set("fields", fields);
        typeModel.set("isKey", false);

        typeModel
            .save({
                flow: app_name
            })
            .then(function() {
                var queryProperties = {};
                queryProperties.keytomap = that.saveView.saveModel.get("cachekey");
                queryProperties.refreshinterval = that.saveView.saveModel.get("cacherefresh");
                cacheModel.set("queryProperties", queryProperties);
                cacheModel.set("typename", typeModel.get("id"));

                cacheModel
                    .save({
                        flow: app_name
                    })
                    .then(function() {
                        def.resolve();
                    })
                    .fail(function(e) {
                        if (e) {
                            def.reject("Problem creating Cache. Please try again " + e.message);
                        } else {
                            def.reject("Error creating Cache");
                        }
                    });
            })
            .fail(function(e) {
                if (e) {
                    def.reject("Problem creating Type. Please try again. " + e.message);
                } else {
                    def.reject("Error creating Type");
                }
            });

        return def;
    },
    getParserProperties: function() {
        var properties = {};
        var propertyTemplate = this.model.get("proptemplate");
        _.each(propertyTemplate, function(entry, i) {
            if (entry.DefaultValue === "false") {
                entry.DefaultValue = false;
            }
            if (entry.DefaultValue === "true") {
                entry.DefaultValue = true;
            }

            properties[i.toLowerCase()] = entry.DefaultValue;
        });

        // override with custom properties.
        var parserProperties = this.model.get("parserProperties");
        _.each(parserProperties, function(entry, i) {
            if (entry === "false") {
                entry = false;
            }
            if (entry === "true") {
                entry = true;
            }

            properties[i.toLowerCase()] = entry;
        });

        return properties;
    },
    onRender: function() {
        var that = this;

        this.saveView = new saveModal.SaveView({
            model: this.model
        });

        this.saveRegion.show(this.saveView);

        //enable save if all steps are completed
        this.listenTo(this.saveView, "save-enable", function() {
            that.enableSaveButton(true);
        });
        this.listenTo(this.saveView, "save-disable", function() {
            that.enableSaveButton(false);
        });

        this.listenTo(this.saveView, "next-enable", function() {
            that.enableNextButton(true);
        });

        this.listenTo(this.saveView, "next-disable", function() {
            that.enableNextButton(false);
        });
    },
    close: function() {
        this.destroy();
    },
    disable: function() {
        this.stopListening(this, "submit", this.submit);
    },
    enable: function() {
        this.listenTo(this, "submit", this.submit);
    },
    enableSaveButton: function(enable) {
        if (enable) {
            this.$el.find("#save-button").removeClass("disabled");
        } else {
            this.$el.find("#save-button").addClass("disabled");
        }
    },
    enableNextButton: function(enable) {
        if (enable) {
            this.$el.find("#next-button").removeClass("disabled");
        } else {
            this.$el.find("#next-button").addClass("disabled");
        }
    },
    showSaveButton: function() {
        this.$el.find("#save-button").removeClass("hidden");
        this.$el.find("#next-button").addClass("hidden");
    }
});

saveModal.SaveView = Backbone.Marionette.LayoutView.extend({
    id: "save-form-container",
    template: _.template(save_sample_template),
    ui: {
        sourceSetupRegion: "#source-setup-region",
        appSetupContainer: "#app-setup-region"
    },
    regions: {
        newAppRegion: ".new-app-region",
        choseAppRegion: ".chose-app-region",
        sourceDetails: ".create-source-region",
        cacheDetails: ".create-cache-region"
    },
    initialize: function(options) {
        var that = this;
        this.model = options.model;

        this.listenTo(this, "show:sourceSetup", function() {
            that.createSourceAndCacheForms();
        });
    },
    onRender: function() {
        ensureRegions(this);
        this.newAppModel = new metaObjects.APPLICATION.Model();
        this.newAppModel.set("nsName", api.getCurrentNamespace());

        var fileInfo = this.model.get("fileinfo");
        var fileName = fileInfo.Name;
        var directory = fileInfo.Path.substring(0, fileInfo.Path.lastIndexOf("/") + 1);

        this.saveModel = new Backbone.Model();
        this.saveModel.set("wildcard", fileName);
        this.saveModel.set("directory", directory);
        this.saveModel.set("type", "source");
        this.saveModel.set("nsName", api.getCurrentNamespace());

        var that = this;
        var newAppForm = formBuilderUtils.createForMetadataObject(this.newAppModel, {
            readOnly: false,
            autoSave: true,
            displayFields: ["name", "nsName"],
            hideTitle: true,
            theme: "light"
        });

        var appsStore = new SimpleMetaObjectDataSource(metaStoreService.entities.APPLICATION);

        this.selectAppForm = new FormBuilder({
            model: this.saveModel,
            ReadOnly: false,
            mapper: {
                app: UIControl.Select(appsStore)
            },
            mapperControlSettings: {
                app: {
                    customCssClass: "light"
                }
            },
            labels: {
                app: {
                    name: "Select App"
                }
            },
            autoSave: true
        });

        this.getRegion("choseAppRegion").show(this.selectAppForm.create());
        this.getRegion("newAppRegion").show(newAppForm);

        this.$el.find("input[type=radio]").change(function() {
            if (this.value === "new") {
                clearExistingAppForm(that);

                that.saveModel.set("app", undefined);
                that.getRegion("choseAppRegion").$el.slideUp("fast");
                that.getRegion("newAppRegion").$el.slideDown("fast");
            }
            if (this.value === "existing") {
                clearNewAppForm(that);

                that.getRegion("choseAppRegion").$el.slideDown("fast");
                that.getRegion("newAppRegion").$el.slideUp("fast");
            }
            if (this.value === "source") {
                that.createSourceForm.reset();
                that.saveModel.set({
                    type: "source",
                    name: ""
                });
                that.getRegion("sourceDetails").$el.slideDown("fast");
                that.getRegion("cacheDetails").$el.slideUp("fast");
            }
            if (this.value === "cache") {
                that.createCacheForm.reset();
                that.saveModel.set({
                    type: "cache",
                    name: ""
                });
                that.getRegion("sourceDetails").$el.slideUp("fast");
                that.getRegion("cacheDetails").$el.slideDown("fast");
            }
        });

        this.saveModel.on("change", function() {
            if (this.hasChanged("app")) {
                var newNsName = this.get("app") ? this.get("app").split(".")[0] : "";
                var previousNsName = this.previous("app") ? this.previous("app").split(".")[0] : "";
                //if nsName has changed update the savemodel nsname for metaobjectext fields
                if (newNsName !== previousNsName) {
                    that.updateSourceSetupNsName(newNsName);
                }
                if (that.checkIfAppStepCompleted() && that.ui.sourceSetupRegion.is(":hidden")) {
                    that.trigger("next-enable");
                }
            } else {
                that.setSaveButtonStatus();
            }
        });

        this.newAppModel.on("change", function() {
            //if nsName has changed update the savemodel nsname for metaobjectext fields
            if (this.hasChanged("nsName")) {
                that.updateSourceSetupNsName(this.get("nsName"));
            }

            if (that.checkIfAppStepCompleted() && that.ui.sourceSetupRegion.is(":hidden")) {
                that.trigger("next-enable");
            }
        });

        this.saveModel.on("invalid", function() {
            this.set("name", ""); //since we don't set original metaobject model when view value become invalid (e.g., test in invalid so the original model value will remain tes)
            if (that.ui.sourceSetupRegion.is(":hidden")) {
                that.trigger("next-disable");
            } else {
                that.trigger("save-disable");
            }
        });

        this.newAppModel.on("invalid", function() {
            this.set("name", "");
            that.trigger("next-disable");
        });
    },
    updateSourceSetupNsName: function(nsName) {
        this.saveModel.set("nsName", nsName);
    },
    createSourceAndCacheForms: function() {
        ensureRegions(this);
        /**create source and cache forms**/
        var cache_keys = [];
        var columns = this.model.get("columns");
        _.each(columns, function(obj) {
            cache_keys.push({
                id: obj.name,
                text: obj.name
            });
        });
        /**source form**/
        this.createSourceForm = new FormBuilder({
            model: this.saveModel,
            ReadOnly: false,
            mapper: {
                name: UIControl.MetaObjectTextField(this.saveModel.get("nsName"), metaStoreService.entities.SOURCE)
            },
            labels: {
                name: {
                    name: "Name"
                },
                cachekey: {
                    name: "Cache Key"
                },
                cacherefresh: {
                    name: "Cache Refresh"
                }
            },
            autoSave: true
        });

        this.createCacheForm = new FormBuilder({
            model: this.saveModel,
            ReadOnly: false,
            mapper: {
                name: UIControl.MetaObjectTextField(this.saveModel.get("nsName"), metaStoreService.entities.CACHE),
                cachekey: UIControl.Select(cache_keys, {
                    customCssClass: "light"
                }),
                cacherefresh: UIControl.Interval
            },
            mapperControlSettings: {
                cacherefresh: {
                    customCssClass: "light"
                }
            },
            labels: {
                name: {
                    name: "Name"
                },
                cachekey: {
                    name: "Cache Key"
                },
                cacherefresh: {
                    name: "Cache Refresh"
                }
            },
            autoSave: true
        });

        this.getRegion("sourceDetails").show(this.createSourceForm.create());
        this.getRegion("cacheDetails").show(this.createCacheForm.create());
        this.ui.sourceSetupRegion.slideDown("fast");
        this.ui.appSetupContainer.slideUp("fast");
    },
    setSaveButtonStatus: function() {
        if (this.checkIfAppStepCompleted() && this.checkIfSourceStepCompleted()) {
            this.trigger("save-enable");
        } else {
            this.trigger("save-disable");
        }
    },
    checkIfAppStepCompleted: function() {
        var appSelected = this.saveModel.get("app") || (this.newAppModel.get("name") && this.newAppModel.get("nsName"));
        return appSelected;
    },
    checkIfSourceStepCompleted: function() {
        return this.saveModel.get("name");
    }
});
/* Controller for the dialog, not any specific view within the dialog */
saveModal.Controller = App.FormMixinDialog.Controller.extend({
    initialize: function(options) {
        App.FormMixinDialog.Controller.prototype.initialize.apply(this, arguments);
        this.view = options.view;
        this.model = options.model;
        this.listenTo(App.vent, "savePreviewSuccess", this.routeToApp, this);
    },
    routeToApp: function() {
        var appName = this.model.get("appname");
        if (appName) {
            var ns = this.model.get("namespace");
            location = "/flow/#" + ns + "." + appName;
        } else {
            App.navigate("#/landing", {
                trigger: true
            });
            location.reload();
            alert(
                "You successfully created your source. You can now create an application or dashboard using this data"
            );
        }
    }
});

export default saveModal;

function clearExistingAppForm(that) {
    that.selectAppForm.reset();
    that.trigger("next-disable");
}

function clearNewAppForm(that) {
    that.newAppModel.set("name", undefined);
    that.newAppModel.set("nsName", undefined);
    that.newAppRegion.$el.find("input[type=text]").val("");
    that.newAppRegion.$el.find(".select2-control-container").select2("val", "");
    that.trigger("next-disable");
}
