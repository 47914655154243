import api from "core/api/api";
import App from "app";
import growl from "app/components/common/growl";
import utils from "core/utils";
import queryService from "core/services/dataService/queryService";
import "core/data-types";
import "core/services/appservice/query_manager/models/datasource";

var queryManager = App.module("QueryManager", function (QueryManager, App, Backbone, Marionette, $) {
    var manager;

    QueryManager.RETENTION_STRATEGY_ALL = "all";
    QueryManager.RETENTION_STRATEGY_CURRENT = "current";

    QueryManager.Manager = Marionette.Controller.extend({
        initialize: function () {
            this.queryMap = {};
            this.dataSourceMap = {};
        },
        register: function (queryName, queryString, retentionStrategyType, categoryField, pollingEnabled, callback) {
            if (!queryName) {
                return;
            }

            var dataSourceKey = manager.createDataSourceKey(queryName, retentionStrategyType, categoryField);
            var hasDataSource = manager.hasDataSourceInMap(dataSourceKey);
            if (hasDataSource) {
                manager.registerWithExistingDataSource(dataSourceKey, callback);
            } else {
                manager.registerNewDataSource(
                    queryName,
                    queryString,
                    retentionStrategyType,
                    categoryField,
                    pollingEnabled,
                    callback
                );
            }
        },
        registerWithExistingDataSource: function (dataSourceKey, callback) {
            var dataSource = manager.getDataSource(dataSourceKey);
            dataSource.incrementSubscribers();

            if (callback) {
                callback(dataSource);
            }
        },
        registerNewDataSource: function (
            queryName,
            queryString,
            retentionStrategyType,
            categoryField,
            pollingEnabled,
            callback
        ) {
            var query = manager.getQueryForDataSource(queryName, queryString);
            var dataSourceKey = manager.createDataSourceKey(queryName, retentionStrategyType, categoryField);

            var dataSource = new QueryManager.Models.DataSourceModel({
                key: dataSourceKey,
                retentionStrategyType: retentionStrategyType,
                categoryField: categoryField,
                query: query,
                pollingEnabled: pollingEnabled,
            });

            manager.addDataSource(dataSourceKey, dataSource);
            dataSource.incrementSubscribers();

            if (callback) {
                callback(dataSource);
            }
        },
        getQueryForDataSource: function (queryName, queryString) {
            var queryKey, hasQuery, query;
            queryKey = manager.createQueryKey(queryName);
            hasQuery = manager.hasQueryInMap(queryKey);
            if (hasQuery) {
                query = manager.getQuery(queryKey);
            } else {
                query = manager.createNewQuery(queryName, queryString);
            }
            return query;
        },
        createNewQuery: function (queryName, queryString) {
            var query = new queryService.WAQueryModel({
                id: queryName,
            });

            manager.getQueryTemplate(query, queryName, queryString).then(function(data) {
                query.set(data);
                query.params = manager.getParameters();
                var promise = query.prepare();
                promise.then(function() {
                    query.startInstance();
                });
            });

            return query;
        },
        unregister: function (queryName, retentionStrategyType, categoryField) {
            var dataSource, dataSourceKey, hasDataSource, queryKey, hasQuery, query;

            dataSourceKey = manager.createDataSourceKey(queryName, retentionStrategyType, categoryField);
            hasDataSource = manager.hasDataSourceInMap(dataSourceKey);

            if (hasDataSource) {
                dataSource = manager.getDataSource(dataSourceKey);
                dataSource.decrementSubscribers();
                if (dataSource.subscriberCount === 0) {
                    manager.removeDataSource(dataSourceKey);

                    queryKey = manager.createQueryKey(queryName);
                    hasQuery = manager.hasQueryInMap(queryKey);
                    if (hasQuery) {
                        query = manager.getQuery(queryKey);
                        if (query.subscriberCount === 0) {
                            manager.removeQuery(queryKey);
                        }
                    }
                }
            }
        },
        getQuery: function (queryKey) {
            return manager.queryMap[queryKey];
        },
        addQuery: function (queryKey, query) {
            manager.queryMap[queryKey] = query;
        },
        removeQuery: function (queryKey) {
            delete manager.queryMap[queryKey];
        },
        hasQueryInMap: function (queryKey) {
            return manager.queryMap.hasOwnProperty(queryKey);
        },
        hasDataSourceInMap: function (dataSourceKey) {
            return manager.dataSourceMap.hasOwnProperty(dataSourceKey);
        },
        createQueryKey: function (queryName) {
            return queryName;
        },
        createDataSourceKey: function (queryName, retentionStrategyType, categoryField) {
            return queryName + ":" + retentionStrategyType + ":" + categoryField;
        },
        getDataSource: function (dataSourceKey) {
            return manager.dataSourceMap[dataSourceKey];
        },
        addDataSource: function (dataSourceKey, dataSource) {
            manager.dataSourceMap[dataSourceKey] = dataSource;
        },
        removeDataSource: function (dataSourceKey) {
            delete manager.dataSourceMap[dataSourceKey];
        },
        getQueryTemplate: function (query, queryName, queryString) {
            manager.addQuery(manager.createQueryKey(queryName), query);
            var d = this.fetchOrCreateQuery(queryName, queryString);
            return d;
        },
        fetchOrCreateQuery: function (queryName, queryString) {
            var d = $.Deferred();
            var nsName = utils.getNamespace(queryName);
            var name = utils.getName(queryName);
            var query = new queryService.WAQueryModel({
                nsName: nsName,
                name: name,
                id: nsName + ".QUERY." + name,
            });
            query.fetch().then(function() {
                // If we fetch and query.queryDefinition is not the same as queryString
                // we need to call createQuery, otherwise continue.
                // Essentially we need to tell the backend to drop and recreate the query because the parameters have changed.
                if (
                    (query.uuid &&
                        queryString === query.get("queryDefinition") &&
                        query.get("id") === nsName + ".QUERY." + name) ||
                    (queryName !== "" && queryString === "")
                ) {
                    d.resolve(query.toJSON());
                } else {
                    var existingNamespace = api.getCurrentNamespace();
                    api.setCurrentNamespace(nsName);
                    api.compileTQL(queryString)
                        .then(function () {
                            manager
                                .createQuery(queryName, queryString)
                                .then(function() {
                                    query = new queryService.WAQueryModel({
                                        nsName: nsName,
                                        name: name,
                                        id: nsName + ".QUERY." + name,
                                    });
                                    query.params = manager.getParameters();
                                    query.save().then(function() {
                                        var queryJSON = query.toJSON();
                                        manager.changeQueryInDataSources(queryName, queryJSON);
                                        d.resolve(queryJSON);
                                    });
                                })
                                .fail(function (data) {
                                    d.reject(data);
                                });
                            api.setCurrentNamespace(existingNamespace);
                        })
                        .fail(function (data) {
                            d.reject(data);
                            api.setCurrentNamespace(existingNamespace);
                            growl.error('Failed creating query: "' + queryName + '" -  ' + data.message);
                        });
                }
            });

            return d;
        },
        createQuery: function (queryName, queryString) {
            var d = $.Deferred();
            queryService
                .createParameterizedQuery(queryName, queryString)
                .then(function(data) {
                    if (data !== "Completed.") {
                        d.resolve();
                    }
                })
                .fail(function (data) {
                    d.reject(data);
                });

            return d;
        },
        changeQueryInDataSources: function (queryName, queryData) {
            for (var key in manager.dataSourceMap) {
                if (manager.dataSourceMap.hasOwnProperty(key)) {
                    var ds = manager.getDataSource(key);
                    var dsQuery = ds.query;
                    if (dsQuery.id === queryName) {
                        dsQuery.stop();
                        dsQuery.set(queryData);
                        var promise = dsQuery.prepare();
                        promise.then(function(tquery) {
                            dsQuery = tquery;
                            dsQuery.startInstance();
                        });
                    }
                }
            }
        },
        clearParameters: function () {
            manager.parameters = {};
            manager.clearParametersAndRestartQueries();
        },
        clearParametersAndRestartQueries: function () {
            for (var key in manager.queryMap) {
                if (manager.queryMap.hasOwnProperty(key)) {
                    var query = manager.queryMap[key];
                    query.stop();
                    query.clearParameters();
                    var d = query.prepare();
                    d.then(function(perparedQuery) {
                        perparedQuery.startInstance();
                    });
                }
            }
        },
        updateParameters: function (parameters) {
            manager.parameters = parameters;
            manager.updateParametersAndRestartQueries();
        },
        updateParametersAndRestartQueries: function () {
            for (var key in manager.queryMap) {
                if (manager.queryMap.hasOwnProperty(key)) {
                    var query = manager.queryMap[key];
                    var didWeUpdateParameters = query.updateParameters(manager.parameters);
                    if (didWeUpdateParameters) {
                        query.stop();
                        var d = query.prepare();
                        d.then(function(preparedQuery) {
                            preparedQuery.startInstance();
                        });
                    }
                }
            }
        },
        getParameters: function () {
            return manager.parameters;
        },
    });

    QueryManager.addInitializer(function () {
        // in tests, the initializer is executed immediately, so the QueryManager.Manager has to be defined before

        manager = new QueryManager.Manager();

        App.reqres.setHandler("QueryManager:register", manager.register);
        App.reqres.setHandler("QueryManager:unregister", manager.unregister);
        App.reqres.setHandler("QueryManager:updateParameters", function () {
            manager.updateParameters.apply(manager, arguments);
        });
        App.commands.setHandler("QueryManager:clearParameters", function () {
            manager.clearParameters.apply(manager, arguments);
        });
        App.reqres.setHandler("QueryManager:getParameters", manager.getParameters);
        App.reqres.setHandler("QueryManager:fetchOrCreateQuery", manager.fetchOrCreateQuery);
    });
});

export default queryManager;
