import React, { useState, useEffect } from "react";
import { StriimTypography, StriimTable, StriimModal } from "@striim/striim-ui";

import { useUserManagement } from "core/user-management/user-management-context";
import { EmptyTableInfo, ModalSearchBox } from "./";
import { userTableColumns } from "../users-role/constants";
import { useTheme } from "@mui/material";

const styles = {
    ".MuiDialog-paper": { width: "750px" },
    ".MuiDialogContent-root": { paddingY: 2 },
    ".MuiDialogActions-root": {
        borderTop: "1px solid",
        borderColor: "greyscale.200"
    }
};

const AddUsersModal = ({ visible, onConfirm, onCancel, usersToExclude = [], prefetch }) => {
    const { palette } = useTheme();

    const { users, getUsers } = useUserManagement();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [manualState, setManualState] = useState({
        filters: { user: "" }
    });

    useEffect(() => {
        if (prefetch) {
            getUsers();
        }
    }, []);

    const idsToExclude = usersToExclude.map(x => x["User Id"]);
    const nonAssignedUsers = (users || [])
        .map(x => ({
            ...x,
            id: x["User Id"],
            contactInfo: (x.contactMechanisms || []).map(v => v.data).join(", ") || "-"
        }))
        .filter(x => idsToExclude.indexOf(x.id) == -1);

    const clearFilter = () => {
        if (manualState.filters.user) {
            setManualState({ filters: { user: "" } });
        }
    };

    const filterUsers = ({ filters }) => {
        const filtered = nonAssignedUsers.filter(v => {
            return v["User Id"].includes(filters.user) || v.contactInfo.includes(filters.user);
        });
        setFilteredUsers(filtered);
    };

    return (
        <StriimModal
            size="medium"
            dialogProps={{ sx: styles }}
            autoHeight
            isVisible={visible}
            onConfirm={() => onConfirm(selectedUsers)}
            onCancel={() => {
                clearFilter();
                onCancel();
            }}
            confirmContent={"Save"}
            confirmButtonProps={{ "data-test-id": "confirm-user-add-button" }}
            cancelButtonProps={{ "data-test-id": "cancel-user-add-button" }}
            titleContent={
                <StriimTypography variant="h2" color="primary.700">
                    Add Users
                </StriimTypography>
            }
        >
            <ModalSearchBox
                field="user"
                manualState={manualState}
                setManualState={setManualState}
                inputFieldValue={manualState.filters.user}
            />
            <StriimTable
                TableProps={{ "data-testid": "users-table" }}
                TableHeaderCellProps={{
                    style: {
                        background: palette.greyscale[50]
                    }
                }}
                data={filteredUsers}
                columns={userTableColumns.slice(0, 2)}
                onStateChange={filterUsers}
                manualState={manualState}
                selectable
                onRowsSelect={rows => {
                    const tmp = rows.map(x => x.values);

                    if (selectedUsers.length !== tmp.length) {
                        setSelectedUsers(tmp);
                    }
                }}
                NoRowsComponent={<EmptyTableInfo />}
                indexedRow
                TableBodyProps={{ seperated: true }}
            />
        </StriimModal>
    );
};

export default AddUsersModal;
