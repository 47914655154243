import App from "app";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import Dictionary from "app/components/common/helpable/online-help-dictionary";

var module = {};

module.getHelpLink = function (id) {
    var type = MetaObjectConverter.getType(id);
    if (!type) {
        return null;
    }

    if (type === App.ENTITIES.PROPERTYTEMPLATE) {
        var name = MetaObjectConverter.getName(id);
        var help = Dictionary.get()[App.ENTITIES.PROPERTYTEMPLATE + "_" + name];

        if (help && help.href) {
            return help.href;
        }

        return null;
    }

    return null;
};

module.getHelpLinkByKey = function (key) {
    let entry = Dictionary.get()[key];
    if (entry && entry.href) {
        return entry.href;
    }

    return entry;
};

export default module;
