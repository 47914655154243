import { GenericStore } from "../storeUtils";
import { action, observable } from "mobx";
import api from "core/api/api";
import ignoredPatternsFilter from "app/components/common/ignoredPatternsFilter";

export default class NamespaceStore extends GenericStore {
    constructor() {
        super();
        this.addCreateNamespaceAction();
        this.addFetchnamespacesAction();
        this.addSetCurrentNamespace();
    }

    @observable newNamespace = null;

    @action getNamespaceOptions(filterPublicNamespaces) {
        let namespaces = this.namespaces || [];
        namespaces = namespaces.filter(ignoredPatternsFilter);

        return namespaces.map(({ name }) => ({ label: name, value: name }));
    }

    @action addNewNamespaceToOptions(name) {
        this.newNamespace = { label: name, value: name };
    }

    addFetchnamespacesAction() {
        this.actionCreator(async () => await api.read(undefined, { type: "NAMESPACE" }), {
            actionType: "Fetch",
            name: "namespaces"
        });
    }

    addCreateNamespaceAction() {
        this.actionCreator(
            async name => {
                const namespace = api.create(undefined, {
                    type: "NAMESPACE",
                    nsName: "Global",
                    isEditable: true,
                    metaInfoStatus: {},
                    version: 0,
                    name
                });
                this.namespacesFetch();
                return namespace;
            },
            {
                actionType: "Create",
                name: "namespace"
            }
        );
    }

    addSetCurrentNamespace() {
        this.actionCreator(
            async namespace => {
                await api.setCurrentNamespace("Global." + namespace);
            },
            {
                actionType: "Set",
                name: "namespace"
            }
        );
    }
}
