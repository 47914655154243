import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import {
    StriimIconButton,
    StriimTypography,
    StriimFileBrowser,
    StriimInputField,
    StriimModal,
    StriimFileUploader,
    StriimToast
} from "@striim/striim-ui";
import CloseIcon from "@mui/icons-material/Close";

import growl from "../../../../../../../../app/components/common/growl";
import LoadingIndicator from "../../../../../../../generic/loading-indicator";
import api from "core/user-management/userManagementApi/userManagementApi";
import ManageStriimService from "../../../../manage-striim/manage-striim-service";
import { styles } from "./sso-container.styles";

const handleCopy = async child => {
    await navigator.clipboard.writeText(child.fullPath);
    StriimToast({
        title: "File path copied.",
        message: "Copied path: " + child.fullPath,
        severity: "success"
    });
};

const handleDownload = async child => {
    ManageStriimService.downloadFile(child.fullPath);
};

const filterFiles = theFiles => {
    return theFiles?.filter(file => !file?.name?.includes("tmp"));
};

const FileSelectModalContent = ({ selectedFile, onCancel, onSelected, allowedExtensions }) => {
    const [directories, setDirectories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDirectory, setSelectedDirectory] = useState({ label: "/", value: "" });
    const [showConfirm, setShowConfirm] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const [valueParentFolder, setValueParentFolder] = useState("");
    const callbackFnRef = useRef(null);

    const selectedFileDirectory = `Current file path: ${selectedDirectory?.value || ""}`;

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setSelectedDirectory(directories[0]);
    }, [directories]);

    const onDelete = (file, callbackFunction) => {
        setCurrentFile(file);
        callbackFnRef.current = callbackFunction;
        setShowConfirm(true);
    };

    const handleDelete = async () => {
        return fetch("/upload?path=" + encodeURIComponent(currentFile.currentPath), { method: "DELETE" })
            .then(response => {
                if (response.ok) {
                    StriimToast({
                        title: "File Deleted.",
                        message: "File was deleted." + currentFile.currentPath,
                        severity: "success"
                    });
                }
                callbackFnRef.current && callbackFnRef.current(currentFile);
            })
            .catch(error => {
                growl.error(error);
            });
    };

    const fileActions = [
        {
            id: "copy",
            name: "Copy Path",
            onClick: handleCopy
        },
        {
            id: "delete",
            name: "Delete File",
            onClick: onDelete
        },
        {
            id: "download",
            name: "Download File",
            onClick: handleDownload
        }
    ];

    const fetchUsers = async (directories, defaultPath) => {
        const users = await api.getUsers();
        const filterDuplicates = userID => !directories.map(d => d.label).includes(`/${userID}`) && userID !== "sys";

        if (users.length) {
            directories.push(
                ...users
                    .filter(user => filterDuplicates(user["User Id"]))
                    .map(user => {
                        const id = user["User Id"];
                        return { label: `/${id}`, value: `${defaultPath}${id}/` };
                    })
            );
        }
    };

    const fetchNode = async file => await (await fetch("/file_browser?path=" + file.currentPath)).json();

    const fetchNodes = async theFiles => {
        return new Promise(async resolve => {
            const promises = theFiles.map(async file => {
                if (file.isDirectory) {
                    const node = await fetchNode(file);
                    if (node?.children?.length) {
                        file.children = node.children;
                        await fetchNodes(file.children);
                    } else {
                        theFiles.splice(theFiles.indexOf(file), 1);
                    }
                }
            });
            await Promise.all(promises);
            resolve();
        });
    };

    const fetchData = async () => {
        setIsLoading(true);
        setDirectories([]);
        try {
            const theFiles = await (await fetch("/file_browser")).json();
            const defaultPath = theFiles?.currentPath || "";
            await fetchNodes([...filterFiles(theFiles?.children)]);
            let directories = [{ label: "/", value: defaultPath }];
            await fetchUsers(directories, defaultPath);
            theFiles && setDirectories(directories);
        } catch (e) {
            console.error({ e });
            StriimToast({
                title: "Error fetching your files",
                message: e,
                severity: "error"
            });
        } finally {
            setIsLoading(false);
        }
        return;
    };

    const onFileUpload = file => {
        if (file.name[0] === ".") {
            StriimToast({
                title: "Cannot upload hidden files",
                severity: "error"
            });
            return;
        }
        const formData = new FormData();
        formData.append("file", file);
        const xhr = new XMLHttpRequest();
        return new Promise((resolve, reject) => {
            xhr.open("POST", `/upload?path=${decodeURIComponent(selectedDirectory.value)}`);
            xhr.onload = () => {
                if (xhr.readyState !== 4) return;
                if (xhr.status !== 200) {
                    if (xhr.status !== 0) {
                        reject("File upload successful");
                    }
                }
                const fileWithUpdatedPath = { path: xhr?.responseText || file.path, name: file.name };
                onSelected(fileWithUpdatedPath);
                resolve("File was uploaded successfully");
            };
            xhr.send(formData);
        });
    };

    const onClear = () => {
        onSelected("");
    };

    const getSubdirectories = path => {
        return fetch("/file_browser?path=" + path).then(response => response.json());
    };

    const updateValueParentFolder = value => {
        const path = value?.currentPath.slice(0, value?.currentPath.lastIndexOf("/") + 1);

        setValueParentFolder(value ? path : "");
    };

    return isLoading ? (
        <LoadingIndicator />
    ) : (
        <>
            <Grid container>
                <Grid item xs={11} mb={2}>
                    <StriimTypography variant="h2">Select file</StriimTypography>
                </Grid>
                <Grid item xs={1} sx={styles.closeModalContainer}>
                    <StriimIconButton
                        onClick={onCancel}
                        variant="secondaryText"
                        data-test-id="file-select-close-dialog"
                    >
                        <CloseIcon />
                    </StriimIconButton>
                </Grid>
            </Grid>
            <Grid container gap={2}>
                <Grid container item>
                    <StriimFileBrowser
                        onSelected={value => {
                            updateValueParentFolder(value);
                            onSelected(value);
                        }}
                        fileActions={fileActions}
                        getSubdirectories={getSubdirectories}
                        fileBrowserContainerProps={{
                            style: { ...styles.fileBrowserContainer }
                        }}
                        allowedExtensions={allowedExtensions}
                    />
                </Grid>
                <Grid container item>
                    <StriimTypography variant="h3">or</StriimTypography>
                </Grid>
                <Grid container item>
                    <StriimTypography variant="h2">Upload new file</StriimTypography>
                </Grid>
                <Grid container item mb={2}>
                    <StriimInputField
                        select
                        sx={styles.fileUploadSelect}
                        defaultValue={directories[0] ?? ""}
                        SelectProps={{
                            options: directories,
                            isClearable: false,
                            menuPosition: "fixed"
                        }}
                        label="Upload a new file to folder:"
                        onChange={val => {
                            setSelectedDirectory(val);
                        }}
                        helperText={selectedFileDirectory}
                    />
                </Grid>
            </Grid>
            <StriimFileUploader onUpload={onFileUpload} onClear={onClear} />
            {showConfirm && (
                <StriimModal
                    size="small"
                    autoHeight
                    isVisible={showConfirm}
                    onConfirm={() => {
                        setShowConfirm(false);
                        setCurrentFile(null);
                        handleDelete();
                    }}
                    onCancel={() => {
                        setShowConfirm(false);
                        setCurrentFile(null);
                        callbackFnRef.current = null;
                    }}
                    variant="error"
                    confirmContent={"Delete"}
                    titleContent={<StriimTypography variant="h2">Please confirm this operation</StriimTypography>}
                    dialogContentProps={{
                        classes: { root: styles.dialogContentRoot }
                    }}
                >
                    <StriimTypography variant="body4">
                        Are you sure you want to delete <b>{currentFile?.name}</b> ?
                    </StriimTypography>
                </StriimModal>
            )}
        </>
    );
};

export default FileSelectModalContent;
