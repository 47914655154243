import _ from "underscore";
import DirectedGraph from "./directed-graph";
import graphTraverser from "./graph-traverser";
import graphLayout from "./graph-layout";

export default {
    /*
     * Autolayout function
     * Based on an implementation of the Sugiyama Layered Graph algorithm by Gansner et al. (http://www.graphviz.org/Documentation/TSE93.pdf)
     * @param n: array of node IDs (should contain all the nodes for now)
     */
    // TODO (low priority): add support for dummy nodes for smarter edges
    autolayout: function (nodes) {
        var directedGraph = new DirectedGraph(nodes, false);

        var uncrossedGraph = graphTraverser.uncross(directedGraph);
        var alignedGraph = graphLayout.align(uncrossedGraph);

        var result = {};
        _.each(alignedGraph, function (node) {
            result[node.id] = {
                id: node.id,
                layer: node.layer,
                coords: node.coords,
                order: node.order,
            };
        });
        return result;
    },
};
