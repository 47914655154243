import api from "core/api/api";
import $ from "jquery";
import _ from "underscore";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";

function convert(item, showFullName, groupBy) {
    return {
        id: item.id,
        text: item.name,
        selectedText: showFullName ? metaObjectConverter.getShortId(item.nsName, item.name) : null,
        type: item.type,
        subType: item.subType || item.type,
        description: item.nsName,
        groupBy: item[groupBy],
        refModel: item,
    };
}

export default function (items, showFullName, groupBy) {
    groupBy = groupBy || "nsName";
    var convertedItems = items.map(function (item) {
        if (item.namespace) {
            item.nsName = item.namespace;
            item.id = item.nsName + "." + item.type + "." + item.name;
        }
        return convert(item, showFullName, groupBy);
    });

    var groupedItems = _.groupBy(convertedItems, function (s) {
        return s.groupBy;
    });

    var result = [];
    for (var key in groupedItems) {
        if (groupedItems.hasOwnProperty(key)) {
            result.push({
                text: key,
                children: _.sortBy(groupedItems[key], function (item) {
                    return item.text.toLowerCase();
                }),
            });
        }
    }

    return _.sortBy(result, function (item) {
        return item.text.toLowerCase();
    });
}
