import { useContext } from "react";
import { NavbarContext } from "../modules/frame";

export const useNavbarToggle = () => {
    // True is navbar expanded, False is navbar collapsed
    const context = useContext(NavbarContext);
    if (context === undefined) {
        throw new Error("useNavbarToggle must be used within NavbarContextProvider");
    }
    return context;
};
