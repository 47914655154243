import React, { useEffect } from "react";
import { useParams } from "react-router";

import designerFactory from "../../../app/components/flow/designer/designerFactory";
import App from "../../../app";
import { contextStore } from "../../stores";
import localStore from "../message-log/message-log.store";

function updateHeader(appName, flowName?) {
    contextStore.navBarStore.setPageTitle("Flow");
    document.title = "Flow " + (flowName || appName);
}

export default function FlowDesigner() {
    // @ts-ignore
    const { appName, flowName, nodeName, viewName, itemName } = useParams();

    useEffect(() => {
        if (flowName) {
            updateHeader(appName, flowName);
            if (nodeName) {
                designerFactory.initialize(App.content, {
                    appName: appName,
                    flowName: flowName,
                    nodeName: nodeName
                });
            } else {
                designerFactory.initialize(App.content, {
                    appName: appName,
                    flowName: flowName
                });
            }
        } else {
            updateHeader(appName);
            if (viewName) {
                if (viewName === "exceptions") {
                    designerFactory.initialize(App.content, {
                        appName: appName,
                        appErrors: true
                    });
                } else if (viewName === "appSettings") {
                    designerFactory.initialize(App.content, {
                        appName: appName,
                        appSettings: true
                    });
                } else if (viewName === "deploySettings") {
                    designerFactory.initialize(App.content, {
                        appName: appName,
                        deploySettings: true
                    });
                } else if (viewName === "showProgress") {
                    designerFactory.initialize(App.content, {
                        appName: appName,
                        progress: true
                    });
                } else if (viewName === "sherlock-ai") {
                    designerFactory.initialize(App.content, {
                        appName: appName,
                        jobName: itemName,
                        sherlock: true
                    });
                } else if (viewName === "sentinel-ai-activity") {
                    designerFactory.initialize(App.content, {
                        appName: appName,
                        sentinelName: itemName,
                        sentinelLiveMonitor: true
                    });
                }
            } else {
                designerFactory.initialize(App.content, {
                    appName: appName,
                    nodeName: nodeName
                });
            }
        }

        return () => {
            App.content.empty();
            localStore.setComponent(null);
        };
    }, [appName, flowName, nodeName, viewName, itemName]);

    return <div></div>;
}
