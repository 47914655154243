import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var sentinelModule = {};

sentinelModule.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "SENTINEL",
        inputStream: String,
        outputStream: String,
        properties: Object
    }
});

sentinelModule.Collection = baseMetaObject.Collection.extend({
    model: sentinelModule.Model
});

export default sentinelModule;
