import BaseTable from "./basetable";
import formatter from "app/components/monitor/common/valueFormatter";

var WActionStoresTable = {};

WActionStoresTable.View = BaseTable.View.extend({
    columns: [
        {
            label: "Name",
            srcfield: "label",
        },
        {
            label: "CPU",
            srcfield: "cpu-per-node",
            dataName: "cpu",
        },
        {
            label: "Nodes",
            srcfield: "nodes",
        },
        {
            label: "Writes/sec",
            srcfield: "writesPerSecond",
        },
        {
            label: "Writes/sec",
            srcfield: "writesPerSecondGraph",
            view: BaseTable.CellItemSparklineView,
        },
    ],

    dataMapper: function (server) {
        return {
            label: server["full-name"],
            cpu: formatter.toPercentageString(server["most-recent-data"]["cpu-per-node"]),
            nodes: server["num-servers"],
            writesPerSecond: formatter.formatRate(server["most-recent-data"]["rate"]),
            writesPerSecondGraph: server["time-series-data"]["rate"],
        };
    },
});

export default WActionStoresTable;
