import _ from "underscore";
import Backbone from "backbone";
import NestedTypes from "backbone.nestedtypes";
import dateLib from "core/utils/date-utils";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import template from "./exceptions-sidebar.html";
import exceptionsStoreApi from "./exception-store-api";
import ExceptionDetailView from "./exception-details";
import { ExceptionsListContainerView, ExceptionsListContainerModel } from "./exceptions-list";
import fileSaver from "file-saver";
import Papa from "papaparse";
import menu from "app/components/common/menu/menu";
import menuOption from "app/components/common/menu/menu-option";
import Dropdown from "app/components/common/dropdown";
import dialog from "app/components/common/dialogs/dialogWindow";
import manageHistory from "./manage-history";
import iconWarning from "app/images/report-problem.svgr";
import NProgress from "nprogress";
import securityService from "core/services/securityService/securityService";
import { segmentationChip } from "app/components/common/segmentationComponents";
import { addonFeaturesKeys } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import { addonFeaturesMessages } from "src/modules/user-plan/pages/user-plan/tabs/user-plan/components/add-on-features/add-on-features-utils";
import { HTMLregex, parseHtml } from "../appErrors/exception-helper";
import growl from "../../../common/growl";

const exceptionsNotActiveTemplate = `
<img src="src/generic/icon/manage-striim/07-FlowDesigner-ExceptionStore.svg" alt="Exception empty" />
<h3>Start logging Exceptions</h3>
<p class="exceptions-description">View all exceptions in this app by turning on Exception Store. <a class="action bold" href="/onlinedocs/en/index-en.html?contextId=ddl_create_exceptionstore" target="_blank" >Learn more</a></p>
<p class="warning exceptions-warning"><span class="icon"></span><span class="text">This may impact your storage.</span></p>
<a data-test-id="turn-on-exceptions" class="turn-on-button submit btn waves-effect waves-light">Turn On</a>
`;

const ExceptionsNotActiveView = Backbone.Marionette.ItemView.extend({
    template: _.template(exceptionsNotActiveTemplate),

    tagName: "div",

    className: "exceptions-not-active",

    ui: {
        turnOn: ".turn-on-button",
        iconWarning: ".warning .icon",
        description: ".exceptions-description",
        warning: ".exceptions-warning"
    },

    initialize: function(options) {
        this.options = options;
    },

    onRender: function() {
        this.ui.iconWarning.html(iconWarning);

        if (!this.options.exceptionsStoreAvailable) {
            const description = addonFeaturesMessages[addonFeaturesKeys.EXCEPTION_STORE];
            this.ui.turnOn.text("Learn more");
            this.ui.description.text(description);
            this.ui.warning.hide();
            this.ui.turnOn.attr("href", securityService.addonNotAvailableLink);
            this.ui.turnOn.off("click");
        } else {
            this.ui.turnOn.click(() => {
                this.trigger("turn-on-exceptions");
            });
        }
    }
});

function _showProgressBar() {
    NProgress.configure({
        parent: ".right-sidebar",
        minimum: 0.25,
        trickleRate: 0.1,
        trickleSpeed: 800
    });
    NProgress.start();
}

function _hideProgressBar() {
    NProgress.done();
}

export const ExceptionsSidebarModel = NestedTypes.Model.extend({
    defaults: {
        app: Object,
        hasExceptions: Boolean
    }
});

export const ExceptionsSidebarView = Backbone.Marionette.LayoutView.extend({
    template: _.template(template),

    tagName: "div",

    className: "exceptions-sidebar",

    ui: {
        cancel: ".icon-close",
        noExceptions: ".no-exceptions",
        detailsTitle: ".details-title",
        listTitle: ".list-title",
        sidebarTitle: ".sidebar-title",
        download: ".icon-download",
        contextMenu: ".context-menu",

        listContainer: ".exceptions-list-container"
    },

    regions: {
        list: ".exceptions-list-container",
        details: ".exceptions-details-container"
    },

    triggers: {
        "click @ui.cancel": "cancel"
    },

    events: {
        "click @ui.detailsTitle": "_onBackToList",
        "click @ui.download": "_onDownloadClick"
    },

    _lastResult: null,

    onRender: function() {
        this._createContextMenu();

        const exceptionsStoreAvailable = securityService.isSegmentationFeatureEnabled(
            addonFeaturesKeys.EXCEPTION_STORE
        );

        exceptionsStoreApi.checkExceptionsEnabled(this.model.app.id).then(enabled => {
            this.ui.download.toggleClass("hidden-element", !enabled);
            this.ui.contextMenu.toggleClass("hidden-element", !enabled);

            if (enabled && exceptionsStoreAvailable) {
                // show list of exceptions
                this._reloadExceptions();
            } else {
                this._showNoExceptionsView(exceptionsStoreAvailable);
            }

            if (!exceptionsStoreAvailable) {
                const variant = securityService.getSegmentationVariant(addonFeaturesKeys.EXCEPTION_STORE);
                this.ui.listTitle.append(segmentationChip(variant));
            }
        });
    },

    onBeforeDestroy: function() {
        this.contextMenu.destroy();
    },

    _showNoExceptionsView: function(exceptionsStoreAvailable) {
        // show 'Turn on' information
        const exceptionsNotActiveView = new ExceptionsNotActiveView({
            exceptionsStoreAvailable: exceptionsStoreAvailable
        });
        exceptionsNotActiveView.on("turn-on-exceptions", () => {
            exceptionsStoreApi
                .turnOn(this.model.app.id)
                .then(() => {
                    this.model.app.fetch().then(() => {
                        this.getRegion("details").reset();
                        this._reloadExceptions();
                        this.trigger("refreshcounter");
                    });
                })
                .catch(err => {
                    growl.error(err);
                });
        });
        this.ui.noExceptions.addClass("hidden-element");
        this.getRegion("list").reset();
        this.getRegion("details").show(exceptionsNotActiveView);
    },

    _createContextMenu: function() {
        const menuOptions = [
            {
                id: "manageHistory",
                text: "Manage history"
            },
            {
                id: "turnOffLogs",
                text: "Turn off exception logs"
            }
        ];

        const actions = new menuOption.Model.Collection();
        menuOptions.forEach(
            function(mi) {
                const miModel = new menuOption.Model(mi);
                miModel.id = mi.id;
                actions.add(miModel);
            }.bind(this)
        );

        const menuModel = new menu.Model({
            options: actions
        });

        const menuView = new menu.View({
            model: menuModel
        });

        this.listenTo(menuView, "option:click", function(optionId) {
            menuView.trigger("dropdown:hide");

            var menuAction = this["execute_" + optionId + "Action"];
            if (menuAction) {
                menuAction.bind(this)();
            } else {
                alert(optionId);
            }
        });

        this.contextMenu = new Dropdown({
            parentView: this,
            content: menuView,
            trigger: this.ui.contextMenu,
            position: "bottom",
            classes: "exceptions-menu",
            on_show: function(tooltip) {
                //
                // set droppdown top baseline
                // and set arrow color - we need to use additional span element
                //
                var arrowBorderSpan = tooltip.find(".tooltipster-arrow-bottom>span");
                arrowBorderSpan.addClass("tooltipster-border");
                arrowBorderSpan.removeAttr("style");

                var arrowSpan = arrowBorderSpan.clone();
                arrowSpan.addClass("background");
                arrowSpan.removeAttr("style");

                tooltip.find(".tooltipster-arrow-bottom").append(arrowSpan);
            }
        });
    },

    execute_manageHistoryAction: function() {
        manageHistory(this.model.app.id)
            .then(interval => {
                exceptionsStoreApi.manageHistory(this.model.app.id, interval).then(() => {});
            })
            .catch(() => {});
    },

    execute_turnOffLogsAction: function() {
        const appName = metaObjectConverter.getName(this.model.app.id);
        const message = `Are you sure you want to turn off the logging exceptions for <strong>${appName}</strong>. This would delete all existing logs as well.  You can turn this feature on again from the App settings.`;
        dialog.confirm(message, null, "turn-off-logs", "Turn off logging of exceptions", "Turn off").then(result => {
            if (!result) {
                return;
            }
            exceptionsStoreApi.turnOff(this.model.app.id).then(() => {
                this._showNoExceptionsView(true);
                this.ui.contextMenu.addClass("hidden-element");
                this.trigger("refreshcounter");
            });
        });
    },

    _reloadExceptions: function() {
        const fromDate = dateLib().subtract(1, "hour"); // by detault exceptions from last hour are diplayed
        _showProgressBar();
        exceptionsStoreApi
            .loadExceptions(this.model.get("app"), "", fromDate, null, null)
            .then(result => {
                this._lastResult = result;
                this._showExceptionsList();
                _hideProgressBar();
            })
            .catch(() => {
                _hideProgressBar();
                this._lastResult = {
                    exceptions: [],
                    numberOfExceptions: 0
                };
                this._showExceptionsList();
            });
    },

    _showExceptionsList: function() {
        if (!this._lastResult) {
            return;
        }

        const { exceptions, numberOfExceptions } = this._lastResult;

        const hasExceptions = this.model.hasExceptions;
        this.ui.noExceptions.toggleClass("hidden-element", hasExceptions);
        this.ui.download.toggleClass("hidden-element", !hasExceptions);
        this.ui.contextMenu.removeClass("hidden-element");

        if (hasExceptions) {
            const exceptionsCollection = new Backbone.Collection(exceptions);
            const listModel = new ExceptionsListContainerModel({
                numberOfExceptions: numberOfExceptions,
                exceptionsCollection: exceptionsCollection,
                appModel: this.model.get("app")
            });
            const view = new ExceptionsListContainerView({
                model: listModel
            });
            view.on("item-selected", itemModel => {
                this.ui.download.addClass("hidden-element");
                this.ui.contextMenu.addClass("hidden-element");

                // Parsing for HTML response
                const HtmlResponse = itemModel.get("message")?.match(HTMLregex);
                if (HtmlResponse) itemModel.set("message", parseHtml(itemModel.get("message"), HtmlResponse[0]));

                const detailsView = new ExceptionDetailView({
                    model: itemModel
                });
                this.getRegion("details").show(detailsView);

                this.ui.listTitle.addClass("hidden-element");
                this.ui.detailsTitle.removeClass("hidden-element");
                this.ui.sidebarTitle.html(itemModel.get("exceptionType"));

                this.ui.listContainer.addClass("hidden-element");
            });
            view.on("manage-history", this.execute_manageHistoryAction.bind(this));
            view.on("filters-changed", filters => {
                _showProgressBar();
                const { searchTerm, dateFrom, dateTo, components } = filters || {};
                exceptionsStoreApi
                    .loadExceptions(this.model.get("app"), searchTerm, dateFrom, dateTo, components)
                    .then(result => {
                        this._lastResult = result;
                        _hideProgressBar();
                        listModel.set("numberOfExceptions", result.numberOfExceptions);
                        listModel.get("exceptionsCollection").reset(result.exceptions);
                    });
            });
            this.getRegion("list").show(view);
        }
    },

    _onBackToList: function() {
        this.ui.detailsTitle.addClass("hidden-element");
        this.ui.listTitle.removeClass("hidden-element");
        this.ui.listContainer.removeClass("hidden-element");

        this.ui.download.removeClass("hidden-element");
        this.ui.contextMenu.removeClass("hidden-element");
    },

    _onDownloadClick: function() {
        if (!this._lastResult) {
            return;
        }

        const exceptions = [];
        this._lastResult.exceptions.forEach(group => {
            Array.prototype.push.apply(exceptions, group.exceptions);
        });

        const exceptionsWithDisplayNames = exceptions.map(exc => {
            return {
                "Exception type": exc.exceptionType,
                Action: exc.action,
                "Application name": exc.appName,
                "Application ID": exc.appid,
                "Entity Type": exc.entityType,
                "Entity Name": exc.entityName,
                "Class name": exc.className,
                Message: exc.message,
                "Exception time": exc.exceptionTime ? dateLib(exc.exceptionTime).format() : exc.exceptionTime,
                "EPOCH number": exc.epochNumber,
                "Related Entity": exc.relatedEntity,
                "Exception Code": exc.exceptionCode,
                "Related activity": exc.relatedActivity,
                "Related objects": exc.relatedObjects
            };
        });

        const csvString = Papa.unparse(exceptionsWithDisplayNames);

        const filename = "Application exceptions.csv";
        let blob = new Blob([csvString], { type: "text/plain;charset=utf-8" });
        fileSaver.saveAs(blob, filename);
    }
});
