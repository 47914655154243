import metaStoreService from "core/services/metaStoreService/meta-store-service";

export const EMBEDDINGS_GENERATOR_NAMESPACE = "System$EG";

const MetaobjectUtilities = {
    getNamespaces: async function() {
        return new Promise(resolve => {
            metaStoreService.fetchIdentifiersByType(metaStoreService.entities.NAMESPACE).then(namespaces => {
                // remove invalid global namespace
                const filteredNamespaces = namespaces.models.filter(ns => ns.get("name").toLowerCase() !== "global");
                resolve({ ...namespaces, models: filteredNamespaces });
            });
        });
    },
    getFilteredNamespaces: async function() {
        const namespaces = await this.getNamespaces();
        namespaces.models = namespaces?.models?.filter(ns => {
            const name = ns.get("name").toLowerCase();
            return name !== "global" && name !== EMBEDDINGS_GENERATOR_NAMESPACE.toLowerCase();
        });
        return namespaces;
    }
};
export default MetaobjectUtilities;
