import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing }) => ({
    appList: {
        marginBottom: spacing(3),
        flexWrap: "nowrap",
        marginTop: spacing(3),
        paddingRight: spacing(3),
        paddingLeft: spacing(3),
        gap: spacing(3)
    },
    card: {
        padding: spacing(2.5),
        borderRadius: "8px",
        border: "1px solid #E1E1E1",
        height: "100%",
        backgroundColor: "#F7FBFF"
    },
    header: {
        margin: 0
    },
    groupContainer: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: spacing(1),
        marginBottom: spacing(1),
        marginTop: spacing(-1)
    },
    infoIcon: {
        color: "transparent",
        minWidth: 15,
        minHeight: 15,
        cursor: "pointer"
    }
}));
