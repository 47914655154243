import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import template from "./templates/appoverview.html";
import ChartOptions from "app/components/monitor/common/chartoptions";
import noDataTemplate from "./templates/noDataTemplate";
import { tooltipStriimlineHighcharts } from "../../../../styles/materialize/components-striimline/tooltip.export";

var AppOverview = {};

AppOverview.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(template),
    modelEvents: {
        change: "update"
    },
    regions: {
        sourceRateRegion: "#sourceRateRegion",
        cpuUsageRegion: "#cpuUsageRegion"
    },

    prepareData: function(serie) {
        if (!serie) {
            return [];
        }

        var empty = $.grep(serie, function(item) {
            return item.second === 0;
        });

        if (empty.length === serie.length) {
            return [];
        }

        return _.map(serie.slice(serie.length - this.visiblePointsCount), function(item) {
            return {
                x: new Date(item.first),
                y: item.second ? parseFloat(item.second) : 0
            };
        });
    },

    update: function() {
        this.updateProcessRateChart();
        this.updateCpuUsageChart();
    },

    getAverage: function(arr, iteratee) {
        return (
            _.reduce(
                arr,
                function(memo, item) {
                    return memo + iteratee(item);
                },
                0
            ) / (arr.length === 0 ? 1 : arr.length)
        );
    },

    getBulletAxis: function(data) {
        var first = _.min(data, function(item) {
            return item.y;
        }).y;

        var last = _.max(data, function(item) {
            return item.y;
        }).y;

        var avg = this.getAverage(data, function(item) {
            return item.y;
        });

        var current = _.last(data).y;
        var second = _.min([avg, current]);
        var third = _.max([avg, current]);

        var plotBands = [
            {
                from: first,
                to: second,
                color: "rgba(103,103,103,.35)"
            },
            {
                from: second,
                to: third,
                color: "rgba(153,153,153,.35)"
            },
            {
                from: third,
                to: last,
                color: "rgba(204,204,204,.35)"
            }
        ];

        return {
            current: current,
            min: first,
            max: last,
            plotBands: plotBands
        };
    },

    updateProcessRateChart: function() {
        var data = this.prepareData(this.model.rate);

        if (data.length === 0) {
            this.sourceRateRegion.show(
                new noDataTemplate({
                    text: "No source rate data to show."
                })
            );
            this.$el.find(".processRateBulletChart").hide();
            return;
        }
        this.$el.find(".processRateBulletChart").show();
        this.sourceRateRegion.$el.hide();

        if (this.processRateChart) {
            var series = this.processRateChart.series[0];
            series.setData(data);
        } else {
            var extend = $.extend(
                true,
                {
                    series: [
                        {
                            data: data
                        }
                    ]
                },
                {
                    chart: {
                        renderTo: this.$el.find(".processRateChart")[0]
                    }
                },
                ChartOptions.getColoredTime("rate"),
                ChartOptions.getTimeFormatXaxis(),
                {
                    tooltip: {
                        formatter: function() {
                            return `<b>${this.y}</b> events/sec`;
                        }
                    }
                }
            );
            this.processRateChart = new Highcharts.Chart(extend);
        }

        var yAxis = this.getBulletAxis(data);

        if (this.processRateBulletChart) {
            this.processRateBulletChart.yAxis[0].update(yAxis);
            this.processRateBulletChart.series[0].setData([yAxis.current]);
            this.processRateBulletChart.series[1].setData([yAxis.current]);
        } else {
            this.processRateBulletChart = new Highcharts.Chart(
                $.extend(
                    true,
                    {
                        yAxis: yAxis
                    },
                    {
                        series: [
                            {
                                pointWidth: 5,
                                data: [yAxis.current]
                            },
                            {
                                type: "scatter",
                                data: [yAxis.current]
                            }
                        ]
                    },
                    {
                        chart: {
                            renderTo: this.$el.find(".processRateBulletChart")[0]
                        }
                    },
                    ChartOptions.bullet
                )
            );
        }
    },

    updateCpuUsageChart: function() {
        var data = this.prepareData(this.model.cpu);

        if (data.length === 0) {
            this.cpuUsageRegion.show(
                new noDataTemplate({
                    text: "No cpu rate data to show."
                })
            );
            this.$el.find(".cpuUsageBulletChart").hide();
            return;
        }
        this.$el.find(".cpuUsageBulletChart").show();
        this.cpuUsageRegion.$el.hide();

        if (this.cpuUsageChart) {
            var series = this.cpuUsageChart.series[0];
            series.setData(data);
        } else {
            var extend = $.extend(
                true,
                {
                    series: [
                        {
                            data: data
                        }
                    ]
                },
                {
                    chart: {
                        renderTo: this.$el.find(".cpuUsageChart")[0]
                    }
                },
                ChartOptions.getColoredTime("cpu-per-node"),
                ChartOptions.getTimeFormatXaxis(),
                {
                    tooltip: {
                        formatter: function() {
                            return `<b>${this.y}</b>% CPU`;
                        },
                        ...tooltipStriimlineHighcharts
                    }
                }
            );
            this.cpuUsageChart = new Highcharts.Chart(extend);
        }

        var yAxis = this.getBulletAxis(data);

        if (this.cpuBulletChart) {
            this.cpuBulletChart.yAxis[0].update(yAxis);
            this.cpuBulletChart.series[0].setData([yAxis.current]);
            this.cpuBulletChart.series[1].setData([yAxis.current]);
        } else {
            this.cpuBulletChart = new Highcharts.Chart(
                $.extend(
                    true,
                    {},
                    {
                        yAxis: yAxis
                    },
                    {
                        series: [
                            {
                                pointWidth: 5,
                                data: [yAxis.current]
                            },
                            {
                                type: "scatter",
                                data: [yAxis.current]
                            }
                        ]
                    },
                    {
                        chart: {
                            renderTo: this.$el.find(".cpuUsageBulletChart")[0]
                        }
                    },
                    ChartOptions.bullet
                )
            );
        }
    },

    initialize: function() {
        this.visiblePointsCount = 120;
    },

    onRender: function() {
        this.update();
    }
});

export default AppOverview;
