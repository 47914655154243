import { saveAs as saveFileAs } from "file-saver";

const ManageStriimService = {
    deleteFile: async function(path) {
        await fetch("/upload?path=" + encodeURIComponent(path), {
            method: "DELETE"
        });
    },
    downloadFile: async function(path, customFileName) {
        const fileName = customFileName ?? path.split("/").pop();
        // TODO: what is the correct way to do this?
        fetch("/upload?path=" + encodeURIComponent(path)).then(response => {
            response.blob().then(blob => saveFileAs(new Blob([blob], { type: blob.type }), fileName));
        });
    },
    readJSONFile: async function(path) {
        try {
            if (!path.includes(".json")) {
                console.error("The file is not valid");
                return;
            }
            const response = await fetch("/upload?path=" + encodeURIComponent(path));
            const responseBlob = await response.blob();
            const jsonResponse = await responseBlob.text();
            return JSON.parse(jsonResponse);
        } catch (e) {
            console.error("Error while parsing the file", e);
        }
    }
};

export default ManageStriimService;
