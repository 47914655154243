import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var module = {};

export const Default_Group = "Default_Group";

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "GROUP",
        title: String,
        collapsed: Boolean,
        applications: Array,
        orderNumber: Number,
        groupType: String,
        // not used by the server
        isFirstGroup: Boolean,
        isLastGroup: Boolean,
        appCount: Number,
        isDefaultGroup: Boolean,
        isAutomatedGroup: Boolean,
        validations: null,
    },
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model,
});

export default module;
