define(["underscore.original"], function(_) {
    // Set template settings
    _.templateSettings = {
        interpolate: /\{\{(.+?)\}\}/g,
        escape: /\{\{\{([\s\S]+?)\}\}\}/g,
        evaluate: /<%([\s\S]+?)%>/g,
    };

    // Add mixins`
    _.mixin({
        pluralize: function (word, num) {
            return num !== 1 ? word + "s" : word;
        },
        capitalize: function (string) {
            return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
        },
        forceArray: function (obj) {
            if (_(obj).isArray()) return obj;
            return [obj];
        },
        threshold: function (vals, threshold) {
            for (var i = 1; i < vals.length; i++) {
                if (!(vals[i] >= vals[i - 1] - threshold && vals[i] <= vals[i - 1] + threshold)) return false;
            }
            return true;
        },
        average: function (list, func) {
            var sum = 0;
            _(list).each(function (item) {
                sum += func.apply(item, [item]);
            });
            return sum / _(list).size();
        },
        strcontains: function (string, substr, casesensitive) {
            if (casesensitive) {
                string = string.toUpperCase();
                substr = substr.toUpperCase();
            }
            return string.indexOf(substr) != -1;
        },
        group: function (object, group_if) {
            var new_object = $.extend(true, {}, object);
            _.each(object, function (value, key) {
                var groupname = group_if.apply(this, [value, key]);
                if (!groupname) return false;
                if (!new_object[groupname]) new_object[groupname] = {};
                new_object[groupname][key] = value;
                delete new_object[key];
            });
            return new_object;
        },
    });

    return _;
});
