import Column from "../columns-picker/column-with-function";

var TypeModifier = {};

TypeModifier.View = Column.View.extend({
    onRender: function () {
        Column.View.prototype.onRender.apply(this);
        this.$el.find("#alias-wrapper").hide();
    },

    setEnabled: function (enabled) {
        Column.View.prototype.setEnabled.apply(this, [enabled]);
        this.columns.setEnabled(enabled);
    }
});

TypeModifier.CollectionView = Column.CollectionView.extend({
    childView: TypeModifier.View,
    onRender: function () {
        Column.CollectionView.prototype.onRender.apply(this);
        this.$el.find("thead,tbody").addClass("without-col3");
    }
});

TypeModifier.Model = Column.Model.extend({
    defaults: {
        column: String,
        delimiter: String,
        alias: String,
        columns: Array,
        tqlFunction: String,
        enabled: true,
    },
});

TypeModifier.Collection = Column.Collection.extend({
    model: TypeModifier.Model,
});

export default TypeModifier;
