import _ from "underscore";
import utils from "core/utils";

export default {
    process: function (newNodeType, nodeModel, parentNode, predefinedComponentName, initialProperties) {
        if (!initialProperties) {
            return;
        }

        let properties;
        switch (newNodeType) {
            case utils.ENTITIES.CACHE:
                properties = ["adapter", "parser", "queryProperties", "typename", "isEventTable"];
                break;
            case utils.ENTITIES.SOURCE:
                properties = ["adapter", "parser"];
                break;
            case utils.ENTITIES.TARGET:
                properties = ["adapter", "formatter"];
                break;
            default:
                properties = [];
        }

        nodeModel.metaObject.autoProperties = _.pick(initialProperties, properties);
    },
};
