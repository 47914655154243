// A base HighMaps mixin
import _ from "underscore";
import _chartCommonMixin from "./_chartCommonMixin";
import _chartRendererMixin from "./_chartRendererMixin";
import _highchartsSeriesMixin from "./_highchartsSeriesMixin";
import "highmaps";
import "highmaps_world_highres";

var highmapsMixin = {};
_(highmapsMixin).extend(_chartCommonMixin);
_(highmapsMixin).extend(_chartRendererMixin);
_(highmapsMixin).extend(_highchartsSeriesMixin);
_(highmapsMixin).extend({
    // Format each data point as array that holds only the values
    // of the the columns referenced by the 2 axes
    destroyChart: function () {
        if (!this.chartConfig) {
            return console.warn("Destroy: Config malformed.");
        }
        var hc = this.$el.highcharts();
        if (hc) {
            hc.destroy();
        }
    },
    renderChart: function () {
        this.renderIfNeeded(this.$el.highcharts(), this.createChart, this.updateChart);
    },

    resize: function () {
        if (this.$el.highcharts()) {
            this.$el.highcharts().setSize(this.$el.width(), this.$el.height());
        }
    },
    createChart: function () {
        // Destroy chart before recreating
        // http://api.highcharts.com/highcharts#Chart.destroy
        this.destroyChart();

        // Do not proceed if the target node is detached (as _ensureElement in Backbone)
        // This will be the case if the chart is being updated before the element is attached to the DOM
        if (typeof this.$el.parent()[0] === "undefined") {
            console.warn(
                "Error: target node is detached. Not rendering chart. This can happen because:\n1. " +
                    "The chartview $el has not yet been attached to the DOM (no problem). \n2. " +
                    "You called generate_chart_model, and then destroyed the view where the chart currently is displayed " +
                    "(you should look into that).\nConfig:",
                this.chartConfig
            );
        }

        this.$el.highcharts("Map", this.chartConfig);

        var chart = this.$el.highcharts();
        if (
            this.chartConfig.viewZoom !== null &&
            this.chartConfig.viewXOffset !== null &&
            this.chartConfig.viewYOffset !== null &&
            this.chartConfig.viewZoom !== "" &&
            this.chartConfig.viewXOffset !== "" &&
            this.chartConfig.viewYOffset !== ""
        ) {
            chart.mapZoom(
                Number(this.chartConfig.viewZoom),
                Number(this.chartConfig.viewXOffset),
                Number(this.chartConfig.viewYOffset)
            );
        }
    },
    updateChart: function () {
        this.updateChartSeries();
        this.$el.highcharts().redraw();
        this.$el.highcharts().setSize(this.$el.width(), this.$el.height());
    },
});

export default highmapsMixin;
