import React from "react";
import { StriimTypography, StriimList, StriimListItem } from "@striim/striim-ui";
import { ListItemText, Box } from "@mui/material";
import { observer } from "mobx-react";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { Link } from "react-router-dom";
import Tracker from "core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../core/services/tracker/constants";

import styles from "./latest-items.styles";

const LatestDashboards = observer(({ headerStore, navbarStateManager }) => {
    const route = "/dashboards";
    const { subRoute: currentSubroute } = navbarStateManager.currentSelection || {};
    return (
        <>
            <StriimList data-testid={"dashboard-name"}>
                {headerStore.latestDashboards.length > 0 && (
                    <Box sx={styles.captionPadding}>
                        <StriimTypography variant="caption4" sx={{ ...styles.caption4Color }}>
                            Recently Opened
                        </StriimTypography>
                    </Box>
                )}
                {headerStore.latestDashboards.length > 0 ? (
                    headerStore.latestDashboards.slice(0, 5).map(latestDashboard => {
                        const { title, nsName, id, page, editable } = latestDashboard;
                        const subRoute = `/dashboard/${editable ? `edit` : `view`}/${nsName}.${title}/${page}`;
                        return (
                            <Link
                                key={id}
                                to={subRoute}
                                data-testid={`nav--dashboard-${id}`}
                                id={"nav--dashboard-" + id}
                                onClick={() => {
                                    Tracker.getInstance().track(TRACKER_STRINGS.schema.dashboard, {
                                        id: `${nsName}.${title}`,
                                        event: TRACKER_STRINGS.eventClicked.dashboard,
                                        buttonClicked: "View"
                                    });
                                    navbarStateManager.updateSelection({ route, subRoute });
                                    navbarStateManager.setActiveMenu(current => ({
                                        ...current,
                                        open: false
                                    }));
                                }}
                            >
                                <StriimListItem
                                    sx={styles.recentItem}
                                    selected={subRoute === currentSubroute}
                                    divider={false}
                                >
                                    <Box sx={{ ...styles.statusIcon, ...styles.dashboardIcon }}>
                                        <DashboardOutlinedIcon sx={styles.iconSize}> </DashboardOutlinedIcon>
                                    </Box>
                                    <ListItemText
                                        sx={styles.latestItemDtls}
                                        primary={
                                            <StriimTypography
                                                title={title}
                                                variant="body4"
                                                sx={{ ...styles.appNameColor, ...styles.overflowEllipsis }}
                                            >
                                                {title}
                                            </StriimTypography>
                                        }
                                        secondary={
                                            <StriimTypography
                                                variant="caption4"
                                                title={nsName}
                                                sx={{
                                                    ...styles.namespace,
                                                    ...styles.overflowEllipsis
                                                }}
                                            >
                                                {nsName}
                                            </StriimTypography>
                                        }
                                    />
                                </StriimListItem>
                            </Link>
                        );
                    })
                ) : (
                    <Box sx={styles.captionPadding}>
                        <StriimTypography variant="caption4" sx={styles.caption4Color}>
                            No Recent Dashboards
                        </StriimTypography>
                    </Box>
                )}
            </StriimList>
        </>
    );
});

export default LatestDashboards;
