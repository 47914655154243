import { makeStyles } from "@material-ui/core/styles";

export const styles = {
    breakdownContainer: {
        border: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
        borderRadius: "8px",
        py: 1
    },
    listItem: {
        px: 3,
        cursor: "default",
        border: "none",
        "&:active, &:hover": {
            color: "inherit",
            backgroundColor: "inherit",
            borderColor: "inherit"
        }
    },
    summaryListItem: {
        px: 3,
        py: 2,
        border: "none",
        "&:active, &:hover": {
            color: "inherit",
            backgroundColor: "inherit",
            borderColor: "inherit"
        },
        cursor: "default"
    },
    textCursor: {
        cursor: "text",
        userSelect: "text"
    },
    truncatedText: {
        verticalAlign: "middle",
        textAlign: "left",
        width: "147px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }
};

export const useStyles = makeStyles(({ spacing }) => ({
    header: {
        paddingBottom: spacing(2)
    },
    factorsListContainer: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        "& > ul": {
            overflowY: "auto",
            marginBottom: "auto"
        }
    },
    noDataContainer: {
        height: 600
    },
    factorDescription: {
        width: "calc(100% - 200px)"
    },
    descriptionContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        "& .progress-line": {
            width: "300px"
        },
        "& .units-text": {
            marginLeft: spacing(1)
        }
    }
}));
