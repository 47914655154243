import App from "app";
import _ from "underscore";
import dataTypes from "core/data-types";

var Base = function () {};

Base.prototype = {
    maxPoints: 500,
    maxValues: 100,

    add: function (incomingData, data, fieldList, categoryField, lastUsedHash) {
        // public
        var lastItemsBatch =
            incomingData && incomingData.xadded ? this._getMaxAllItems(incomingData.xadded) : incomingData._added;
        return this._addItems(lastItemsBatch, data, fieldList, categoryField, lastUsedHash);
    },

    evict: function (data, lastUsedHash) {
        // public
        return this._evictItems(data, lastUsedHash);
    },

    _addItems: function (incomingData, data, fieldList, categoryField, lastUsedHash) {
        // protected abstract
        throw new Error("add not implemented");
    },

    _evictItems: function (data, lastUsedHash) {
        // protected abstract
        throw new Error("add not implemented");
    },

    _transformData: function (data, fieldList) {
        // protected
        var newObject = {};
        for (var x = 0; x < fieldList.length; x++) {
            var field = fieldList.at(x);
            if (field.get("type") === "org.joda.time.DateTime") {
                newObject[field.get("field")] = new dataTypes.dateTime(data.dataPoints[x]).getDateObject();
            } else {
                newObject[field.get("field")] = data.dataPoints[x];
            }
        }

        return newObject;
    },

    _getMaxAllItems: function (data) {
        // private
        return _.last(data, this.maxPoints);
    },
};

export default Base;
