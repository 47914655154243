import Backbone from "backbone";
import _ from "underscore";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import formBuilderUtils from "app/components/common/editor/meta-object-editors";
import NestedTypes from "backbone.nestedtypes";
import Tql from "app/components/common/editor/control/tql/tql";
import template from "./templates/tql-test.html";

const TestModel = NestedTypes.Model.extend({
    defaults: {
        id: String,
        format: "json",
        metaObject: null
    }
});

const TestView = Backbone.Marionette.LayoutView.extend({
    template: _.template(template),
    regions: {
        editorRegion: ".editor",
        tqlRegion: ".tql"
    },
    events: {
        "click .toggleFormat": "toggleFormat"
    },
    ui: {
        formatName: ".formatName"
    },
    initialize: function() {
        this.tqlEditor = Tql.extend({
            showHeader: false
        }).create();
    },
    onRender: function() {
        metaStoreService.findById(this.model.id).then(
            function(metaObject) {
                metaObject.set("isEditable", true);
                var form = formBuilderUtils.createForMetadataObject(metaObject, {
                    readOnly: false,
                    autoSave: true
                });
                this.model.metaObject = metaObject;

                metaObject.on(
                    "change",
                    function() {
                        this.model.metaObject = metaObject;
                        this.showValue();
                    }.bind(this)
                );

                form.on("form-builder:save", function() {
                    var result = metaObject.save();
                    if (result === false) {
                    }
                });
                this.showValue();
                this.editorRegion.show(form);
            }.bind(this)
        );
        this.tqlRegion.show(this.tqlEditor);
    },
    toggleFormat: function() {
        if (this.model.format === "json") {
            this.model.format = "tql";
        } else {
            this.model.format = "json";
        }
        this.showValue();
    },
    showValue: function() {
        var value;
        if (this.model.format === "json") {
            value = JSON.stringify(this.model.metaObject, null, 4);
        } else {
            value = "TQL";
        }
        this.tqlEditor.setValue(value);
        this.ui.formatName.html(this.model.format);
    }
});

export { TestModel, TestView };
