import React, { useImperativeHandle, forwardRef, useCallback, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toJS } from "mobx";
import { useObserver } from "mobx-react";
import { Box } from "@material-ui/core";
import LoadingIndicator from "src/generic/loading-indicator";

import App from "app";
import useDashboardsStore from "src/stores/dashboards/useDashboardsStore";
import headerStore from "src/stores/header";
import FormikField from "src/generic/formik-field";
import { getNameValidation } from "src/modules/apps/pages/create-app-name-app-page/name.validation";
import growl from "../../../../../../../../../app/components/common/growl";

const getValidationSchema = (createFromScratch, entityType) =>
    Yup.object({
        title: getNameValidation(createFromScratch, entityType)
    });

const RenameForm = (props, ref) => {
    useImperativeHandle(
        ref,
        () => ({
            handleSubmit: props.handleSubmit
        }),
        [props.handleSubmit]
    );
    return (
        <Form>
            <FormikField label="Name" id="title" name="title" />
        </Form>
    );
};

const RenameFormWithRef = forwardRef(RenameForm);

const FormProvider = ({ dashboard, submitCallback }, ref) => {
    const store = useDashboardsStore();
    const isLoading = useObserver(() => {
        store.dashboardLoading;
    });
    let isMountedRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);
    const onSubmitHandler = useCallback(
        async values => {
            try {
                await store.dashboardUpdate({ ...toJS(dashboard), ...values });
                headerStore.updateLatestDashboards({
                    id: dashboard.id,
                    nsName: dashboard.nsName,
                    name: values.title,
                    title: values.title
                });
                growl.success("Renamed Dashboard.", "Success" , dashboard.id);
            } catch (e) {
                growl.error("There was an error renaming this Dashboard: " + e.message, "Error", dashboard.id)
            } finally {
                if (isMountedRef.current) submitCallback();
            }
        },
        [dashboard, store, submitCallback, isMountedRef]
    );
    return (
        <>
            {isLoading && (
                <Box minHeight={10}>
                    <LoadingIndicator isGlobal={false} />
                </Box>
            )}
            <Formik
                innerRef={ref}
                initialValues={{ name: dashboard.name, title: dashboard.title, nsName: dashboard.nsName }}
                component={RenameFormWithRef}
                initialTouched={{
                    title: true
                }}
                validationSchema={getValidationSchema(true, "DASHBOARD")}
                validateOnChange
                onSubmit={onSubmitHandler}
            />
        </>
    );
};

export default forwardRef(FormProvider);
