import { DB_PROVIDERS } from "./configs/db-providers";
/**
 * Note: Before touching this file, refer the doc listed here to make sure it's compatible.
 *
 * Reference: https://docs.google.com/spreadsheets/d/13HIuzV3XsbW2yt4zsIKp_rKZqlWmHlw3MTvGdQk2JzA/edit#gid=0
 *
 * @param tableName
 * @param connectionParams
 * @param isCDC
 */
export default function getTableNameWithCorrectDots(tableName, connectionParams, isCDC) {
    const provider = connectionParams.get("type");
    const isPDB = connectionParams.get("isPDB");
    const containerName = connectionParams.get("containerName");
    if ([DB_PROVIDERS.ORACLE.id, DB_PROVIDERS.OJET.id].includes(provider) && isCDC && isPDB && containerName) {
        let chunks = tableName.split(".");
        chunks.unshift(containerName);
        return chunks.join(".");
    }
    if (
        provider === DB_PROVIDERS.ORACLE.id ||
        provider === DB_PROVIDERS.OJET.id ||
        provider === DB_PROVIDERS.MYSQL.id ||
        provider === DB_PROVIDERS.MARIADB.id ||
        provider === DB_PROVIDERS.MARIADBXPAND.id ||
        provider === DB_PROVIDERS.POSTGRES.id ||
        (provider === DB_PROVIDERS.SQLSERVER.id && isCDC) ||
        (provider === DB_PROVIDERS.MSJET.id && isCDC)
    ) {
        let chunks = tableName.split(".");
        if (chunks.length === 3) {
            chunks.shift();
        }
        return chunks.join(".");
    } else {
        return tableName;
    }
}
