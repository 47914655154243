import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import BaseEditor from "app/components/common/editor/meta-object-editors/base";
import MetaObjectDataSource from "app/components/common/editor/control/select/metaobject-datasource";

var Module = function(metadata_object, options) {
    options = options || {};
    options.displayFields = options.displayFields || ["properties", "outputStream", "pSetHeader", "propertySet"];
    this.pSetHeader = UIControl.Header("Kafka Property Set").create();
    options.StaticControls = {
        pSetHeader: this.pSetHeader
    };
    BaseEditor.apply(this, arguments);
};

Module.prototype = _.extend({}, BaseEditor.prototype, {
    addCustomFields: function() {
        let metaObjectId = this.MetaObjectModel && this.MetaObjectModel.id;

        this.fieldMapper.properties = UIControl.PropertyTemplate("externalsource")
            .extend({
                subControl: true,
                hideLabel: true,
                metaObjectId: metaObjectId
            })
            .create();
        this.fieldMapper.outputStream = UIControl.StreamSelector.extend({
            externalStreams: true
        }).create();

        const propertySetDataSource = new MetaObjectDataSource("PROPERTYSET");
        this.fieldMapper.propertySet = UIControl.Select(propertySetDataSource)
            .extend({ hideLabel: true })
            .create();
    },
    postRender: function() {
        this.fieldMapper.properties.getRegion("handlerRegion").currentView.$el.hide(); // for external source, no other adapter should be selected
        var $propertySet = this.fieldMapper.propertySet.$el.closest(".field-row");
        var hidePropertySet = function(hide) {
            // hides property set header & dropdown
            if (hide) {
                this.fieldMapper.propertySet.setValue(null);
                this.pSetHeader.$el.hide();
                this.fieldMapper.propertySet.hidden = true;
                $propertySet.hide(hide);
            } else {
                this.pSetHeader.$el.show();
                this.fieldMapper.propertySet.hidden = false;
                $propertySet.show(hide);
            }
        }.bind(this);
        hidePropertySet(this.MetaObjectModel.attributes.outputStream);
        this.fieldMapper.outputStream.on("mode:set", function(mode) {
            hidePropertySet(mode !== "new");
        });
    }
});

export default Module;
