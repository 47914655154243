import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { StriimTypography, StriimButton } from "@striim/striim-ui";
import { AddPropertySetModal, PropertySetDocumentationLink } from "./";
import { AddCircleOutlineRounded } from "@mui/icons-material";

const styles = {
    outerBoxStyles: {
        gap: 5,
        pb: 4.5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
};
export default function PropertySetsEmpty({ onAdded, theOptions = [] }) {
    const [isModalShown, setIsModalShown] = useState(false);
    return (
        <>
            <Box sx={styles.outerBoxStyles} data-test-id="property-sets-empty">
                <img src="src/generic/icon/manage-striim/01-ManageStriim-Files-Empty.svg" />
                <StriimTypography variant="h1">Add your first property set</StriimTypography>
                <PropertySetDocumentationLink />
                <div data-test-id="add-property-set-button">
                    <StriimButton
                        variant="primary"
                        onClick={() => setIsModalShown(true)}
                        startIcon={<AddCircleOutlineRounded />}
                    >
                        New property set
                    </StriimButton>
                </div>
            </Box>
            {isModalShown && (
                <AddPropertySetModal theOptions={theOptions} setIsModalShown={setIsModalShown} onAdded={onAdded} />
            )}
        </>
    );
}

PropertySetsEmpty.propTypes = {
    onAdded: PropTypes.func.isRequired
};
