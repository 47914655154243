import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { StriimTypography, StriimButton } from "@striim/striim-ui";
import CustomizeHomepageModal from "./customize_homepage_modal";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";

const getCurrentFormattedDate = () => {
    const date = new Date();
    const suffix = date.getHours() >= 12 ? "pm" : "am";
    const hours = ((date.getHours() + 11) % 12) + 1;
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `
        ${date.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric"
        })} | ${hours}:${minutes}${suffix}`;
};

const useStyles = makeStyles(theme => ({
    homepageButton: {
        alignSelf: "center",
        minWidth: 136
    },
    customizeButton: {
        whiteSpace: "nowrap",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "left",
            paddingTop: 12
        },
        [theme.breakpoints.up("sm")]: {
            justifyContent: "right"
        }
    },
    header: {
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    }
}));

const TopBanner = ({ username, widgets, apps, defaultSeenWidgets, allWidgets, onChange }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [currentDate, setCurrentDate] = useState(getCurrentFormattedDate());
    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => setCurrentDate(getCurrentFormattedDate()), 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Grid container>
                <Grid item className={classes.header} xs={9}>
                    <StriimTypography variant="h1" color="primary.700" sx={{ mb: 0 }} data-test-id="welcome-message">
                        Hi <span style={{ wordBreak: "break-all" }}>{username}</span>, Welcome to Striim!
                    </StriimTypography>
                    <StriimTypography variant="caption1" color="greyscale.700" data-test-id="current-date">
                        {currentDate}
                    </StriimTypography>
                </Grid>
                <Grid item xs className={classes.homepageButton}>
                    <Grid container direction="row-reverse" className={classes.customizeButton}>
                        <Grid item style={{ display: "flex", gap: "16px" }}>
                            <StriimButton
                                variant="primary"
                                startIcon={<AddIcon />}
                                data-test-id="create-an-app--button"
                                onClick={() => navigate("/create-app")}
                            >
                                Create App
                            </StriimButton>
                            <StriimButton
                                variant="secondary"
                                onClick={() => {
                                    setIsVisible(true);
                                }}
                                data-test-id="customize-homepage-button"
                            >
                                Customize Page
                            </StriimButton>
                            <CustomizeHomepageModal
                                apps={apps}
                                widgets={widgets}
                                show={[isVisible, setIsVisible]}
                                allWidgets={allWidgets}
                                defaultSeenWidgets={defaultSeenWidgets}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default TopBanner;

TopBanner.propTypes = {
    username: PropTypes.string.isRequired,
    widgets: PropTypes.array.isRequired,
    defaultSeenWidgets: PropTypes.array.isRequired,
    allWidgets: PropTypes.array.isRequired,
    apps: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};
