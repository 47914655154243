import React, { useState } from "react";
import { useParams } from "react-router";
import { ChevronLeft } from "@mui/icons-material";
import UserDetails from "./components/user-details";
import UserEdit from "./components/user-edit";
import CreateUser from "./components/user-create";
import { StriimIconButton } from "@striim/striim-ui";
import { useNavigate } from "react-router-dom";
import { CancelEditModal } from "../common";
import UserManagementRoot from "../user-management-root";
import { StriimButton, StriimTypography } from "@striim/striim-ui-v2";
import { Box, Grid, SvgIcon } from "@mui/material";
import { isReservedUser } from "../../../../../../core/user-management/update.control";
import styles from "../common/users-management.styles";
import { EditIcon } from "../../../../../generic/icon/customIcons";

const UserDetailsPage = ({ editing = false }) => {
    const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
    const { userId } = useParams();
    const navigate = useNavigate();

    const handleReturn = () => {
        if (!userId || editing) {
            setCancelDialogVisible(true);
        } else {
            navigate("/users");
        }
    };

    const onDiscardConfirm = () => {
        navigate("/users");
    };

    const navigateToEdit = () => {
        navigate(`/users/user/${userId}/edit`);
    };

    const pageUserHeader = {
        create: "Create User",
        edit: "Edit User:",
        details: "User:"
    };

    return (
        <Box sx={{ p: 3, mt: 6 }}>
            <Box display="flex" alignItems="center" mb={3}>
                <StriimIconButton
                    variant="secondary"
                    onClick={handleReturn}
                    data-testid={"back-button"}
                    sx={styles.backButton}
                >
                    <ChevronLeft />
                </StriimIconButton>
                {userId ? (
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <Box display="flex">
                            <StriimTypography variant="h3" color="primary.700">
                                {editing ? pageUserHeader.edit : pageUserHeader.details}
                            </StriimTypography>
                            <StriimTypography variant="h3" sx={{ ml: "8px !important" }} color="secondary.500">
                                {userId}
                            </StriimTypography>
                        </Box>
                        {!editing && !isReservedUser(userId) && (
                            <Box>
                                <StriimButton
                                    variant="primary"
                                    onClick={navigateToEdit}
                                    data-testid={`${userId}-edit`}
                                    startIcon={
                                        <SvgIcon
                                            component={EditIcon}
                                            sx={({ palette }) => ({
                                                fill: "none",
                                                "& > path": {
                                                    stroke: palette?.greyscale[50]
                                                }
                                            })}
                                        />
                                    }
                                >
                                    Edit
                                </StriimButton>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <StriimTypography variant="h3" color="primary.900">
                        {pageUserHeader.create}
                    </StriimTypography>
                )}
            </Box>
            <Grid container>
                {userId ? <>{editing ? <UserEdit id={userId} /> : <UserDetails id={userId} />}</> : <CreateUser />}
            </Grid>
            <CancelEditModal
                title={`Are you sure you want to discard ${userId ? `editing user ${userId}?` : `the new user?`}`}
                dialogVisible={cancelDialogVisible}
                setDialogVisible={setCancelDialogVisible}
                onConfirm={onDiscardConfirm}
            />
        </Box>
    );
};

export default function(props) {
    return (
        <UserManagementRoot>
            <UserDetailsPage {...props} />
        </UserManagementRoot>
    );
}
