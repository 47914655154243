export const sxStyles = {
    container: {
        "& .MuiFormLabel-root": {
            whiteSpace: "wrap"
        }
    },
    labelHeader: {
        color: theme => theme.palette.greyscale[900]
    },
    labelDescription: {
        color: theme => theme.palette.greyscale[900]
    },
    marginStyle: {
        marginLeft: ({ spacing }) => spacing(4)
    },
    toggleMarginStyle: {
        marginLeft: ({ spacing }) => spacing(5.5)
    },
    unsetMarginTop: {
        marginTop: 0
    },
    fieldLabelContainer: {
        my: 1,
        whiteSpace: "normal"
    },
    fieldLabel: {
        color: "greyscale.900"
    },
    radioTitle: {
        color: "greyscale.800",
        mb: 1.5
    },
    lineSeparator: {
        borderTop: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
        margin: ({ spacing }) => spacing(2.3, -3, 3, -3)
    },
    radioSubtitle: {
        display: "block",
        color: "greyscale.600"
    },
    toggleContainer: {
        display: "flex",
        flexDirection: "column"
    },
    toggleLabel: {
        mb: 0
    }
};
