import React from "react";
import { toJS } from "mobx";
import { StriimTypography, StriimModal } from "@striim/striim-ui";
import { useObserver } from "mobx-react";
import { Box } from "@material-ui/core";
import LoadingIndicator from "src/generic/loading-indicator";

import useDashboardsStore from "src/stores/dashboards/useDashboardsStore";

import useStyles from "./dashboard-delete-modal.styles";
import growl from "../../../../../../../../../app/components/common/growl";

const DashboardDeleteModal = ({ dashboard, deleteModalVisible, setDeleteModalVisible }) => {
    const classes = useStyles();
    const store = useDashboardsStore();
    const isLoading = useObserver(() => store.dashboardLoading);

    return (
        <StriimModal
            variant="error"
            isVisible={deleteModalVisible}
            disableMaxHeight
            autoHeight
            size="small"
            titleContent={<StriimTypography variant="h2">Delete Dashboard</StriimTypography>}
            cancelContent="No, Keep this dashboard"
            confirmContent="Delete"
            dialogProps={{
                classes: {
                    paper: classes.paperDialog
                }
            }}
            onConfirm={async () => {
                try {
                    await store.dashboardDelete(toJS(dashboard));
                    growl.success("Deleted Dashboard.", "Success", dashboard.id);
                } catch (e) {
                    growl.error("There was an error deleting this Dashboard: " + e.message, "Error", dashboard.id);
                }
                setDeleteModalVisible(false);
            }}
            onCancel={() => {
                setDeleteModalVisible(false);
            }}
        >
            <div>
                {isLoading && (
                    <Box minHeight={10}>
                        <LoadingIndicator isGlobal={false} />
                    </Box>
                )}
                <StriimTypography variant="body4" id="dashlist--delete-modal-text">
                    Are you sure you want to delete this dashboard?
                </StriimTypography>
            </div>
        </StriimModal>
    );
};

export default DashboardDeleteModal;
