import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { StriimSimpleTable, StriimLink, StriimTypography, StriimModal } from "@striim/striim-ui";
import CollapsibleSection from "../../../../../generic/collapsible-section/collapsible-section";
import { AddCircleOutlineRounded } from "@mui/icons-material";
import { Box } from "@mui/material";
import { PropertySetCollapsibleContent, PropertySetValueRow } from "./index";
import growl from "app/components/common/growl";
import _ from "underscore";
import api from "../../../../../../core/api/api";

const classes = {
    collapsibleSection: {
        marginBottom: 34
    },
    rowStyle: {
        verticalAlign: "top"
    },
    emptyContainer: {
        borderColor: "greyscale.200",
        borderWidth: "1px",
        borderStyle: "solid",
        borderTop: "none",
        padding: 4,
        textAlign: "center"
    }
};

export default function PropertySetEditor({
    uuid,
    propertySetName,
    propertySetNsName,
    propertySetProperties,
    onSave,
    deletePropertySet
}) {
    const [isAddingNewValue, setIsAddingNewValue] = useState(false);
    const [editedKey, setEditedKey] = useState(null);
    const [properties, setProperties] = useState(propertySetProperties);
    const [mappedProperties, setMappedProperties] = useState([]);
    const [dropConfirmVisible, setDropConfirmVisible] = useState(false);
    const [allowedActions, setAllowedActions] = useState({
        edit: false,
        delete: false
    });
    const collapsibleRef = useRef(null);

    useEffect(() => {
        (async function() {
            try {
                const hasUpdatePermission = await api.checkPermission(uuid, "update");
                const hasDropPermission = await api.checkPermission(uuid, "drop");
                setAllowedActions({
                    edit: hasUpdatePermission,
                    delete: hasDropPermission
                });
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const cancelPropertySetValue = () => {
        setIsAddingNewValue(false);
    };

    useEffect(() => {
        if (isAddingNewValue) {
            setEditedKey(null);
        }
    }, [setIsAddingNewValue]);

    useEffect(() => {
        if (editedKey) {
            setIsAddingNewValue(false);
        }
    }, [editedKey]);

    useEffect(() => {
        const newProperties = Object.keys(properties).map(key => {
            return {
                name: key,
                value: properties[key],
                properties: properties,
                onSave: savePropertySetValue,
                onCancel: cancelPropertySetValue,
                isEditAllowed: allowedActions.edit,
                editRow: editRow,
                isDeleteAllowed: allowedActions.delete,
                deleteRow: deleteRow,
                isNew: false,
                editedKey: editedKey,
                setEditedKey: setEditedKey
            };
        });

        if (isAddingNewValue) {
            newProperties.push({
                name: "",
                value: "",
                isNew: true,
                properties: properties,
                onSave: savePropertySetValue,
                onCancel: cancelPropertySetValue
            });
        }

        setMappedProperties(newProperties);
    }, [properties, editedKey, isAddingNewValue, allowedActions]);

    const savePropertySetValue = async (name, value) => {
        try {
            const newProperties = { ...properties };
            newProperties[name] = value;

            setProperties(newProperties);
            setIsAddingNewValue(false);
            await onSave(newProperties);
            growl.success("The property set value was added to " + propertySetName);
            setEditedKey(null);
        } catch (e) {
            growl.error(e, "Error saving Property Set");
        }
    };

    const deleteRow = async name => {
        try {
            const newProperties = { ...properties };
            delete newProperties[name];
            await onSave(newProperties);
            setProperties(newProperties);
            setEditedKey(null);
        } catch (e) {
            growl.error(e, "Error deleting property set");
        }
    };

    const editRow = async (oldName, name, value) => {
        try {
            const newProperties = { ..._.omit(properties, oldName) };
            newProperties[name] = value;
            setProperties(newProperties);
            setIsAddingNewValue(false);
            await onSave(newProperties);
            growl.success("You have successfully edited " + oldName);
            setEditedKey(null);
        } catch (e) {
            growl.error(e, "Error editing Property Set");
        }
    };

    const head = [
        "Property Set Label",
        "Property Set Value",
        (allowedActions.edit || allowedActions.delete) && "Action"
    ];

    const addPropertySetValue = () => {
        collapsibleRef.current.unCollapse();
        setEditedKey(null);
        setIsAddingNewValue(true);
    };

    const isEmpty = Object.keys(properties).length === 0;

    return (
        <div key={propertySetName} sx={classes.collapsibleSection} data-test-id="propertySet">
            <CollapsibleSection
                label={propertySetName}
                content={
                    <PropertySetCollapsibleContent
                        nsName={propertySetNsName}
                        propertySetName={propertySetName}
                        isEmpty={isEmpty}
                        isEditAllowed={allowedActions.edit}
                        addPropertySetValue={addPropertySetValue}
                        isDeleteAllowed={allowedActions.delete}
                        deletePropertySet={deletePropertySet}
                    />
                }
                color="#0D1417"
                fullWidth
                isExpanded
                ref={collapsibleRef}
            >
                <StriimSimpleTable
                    CustomRowComponent={PropertySetValueRow}
                    head={head}
                    data={mappedProperties}
                    rowStyles={editedKey || isAddingNewValue ? classes.rowStyle : {}}
                />
                {isEmpty && !isAddingNewValue && (
                    <Box sx={classes.emptyContainer} data-test-id="property-set-values-empty-message">
                        <>
                            <StriimTypography variant="p4" color="greyscale.700">
                                You can create and use property sets for applications, data <br />
                                streams and LDAP users.
                            </StriimTypography>
                            <br />

                            <StriimLink
                                variant="text"
                                startIcon={<AddCircleOutlineRounded />}
                                onClick={addPropertySetValue}
                                data-test-id="add-property-set-value-link"
                            >
                                New Property Set Value
                            </StriimLink>
                        </>
                    </Box>
                )}
            </CollapsibleSection>
            <StriimModal
                size="small"
                variant="error"
                isVisible={dropConfirmVisible}
                autoHeight
                onCancel={state => setDropConfirmVisible(state)}
                onConfirm={() => deleteRow(properties)}
                titleContent={<StriimTypography variant="h2">Delete property set value</StriimTypography>}
                confirmContent="Drop"
                confirmButtonProps={{ "data-test-id": "delete-property-set-modal-btn" }}
                dialogProps={{ "data-testid": "delete-property-set-modal" }}
            >
                <StriimTypography variant="body4">
                    Are you sure you want to delete this property set value?
                </StriimTypography>
            </StriimModal>
        </div>
    );
}

PropertySetEditor.propTypes = {
    propertySetName: PropTypes.string.isRequired,
    propertySetNsName: PropTypes.string.isRequired,
    propertySetProperties: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    deletePropertySet: PropTypes.func.isRequired
};
