import App from "app";
import Backbone from "backbone";
import UIControl from "app/components/common/editor/control/ui-control";
import dateLib from "core/utils/date-utils";
import fieldFilterHelper from "./fieldFilterHelper";
import utils from "core/utils";

var FieldDetailsControls = {};

FieldDetailsControls.StringFilterView = Backbone.Marionette.LayoutView.extend({
    template: _.template('<div class="pull-left">Value</div><div class="text-input pull-left"></div>'),
    regions: {
        textInputRegion: ".text-input"
    },
    initialize: function() {
        this.text = UIControl.TextField.create();
    },
    onRender: function() {
        if (this.model.currentValue) {
            this.text.setValue(this.model.currentValue);
        }

        this.textInputRegion.show(this.text);
    },
    getValue: function() {
        return this.textInputRegion.currentView.model.value;
    },
    setFilterType: function(filterType) {}
});

FieldDetailsControls.NumberFilterView = Backbone.Marionette.LayoutView.extend({
    template: _.template(
        '<div class="pull-left">Value</div><div class="number-input first-number"></div><div class="range-separator">to</div><div class="number-input second-number"></div>'
    ),
    regions: {
        firstNumberRegion: ".first-number",
        secondNumberRegion: ".second-number"
    },
    ui: {
        rangeSeparator: ".range-separator",
        secondNumber: ".second-number"
    },
    initialize: function() {
        this.firstNumber = UIControl.NumberField.extend({
            allowNegative: true
        }).create();
        this.secondNumber = UIControl.NumberField.extend({
            allowNegative: true
        }).create();
    },
    onRender: function() {
        this.firstNumberRegion.show(this.firstNumber);
        this.secondNumberRegion.show(this.secondNumber);

        this.initValues(this.model.currentValue);
        this.initControls();
    },
    getValue: function() {
        switch (this.filterType) {
            case fieldFilterHelper.filterTypes.Equal.id:
                return Number(this.firstNumberRegion.currentView.model.value);

            case fieldFilterHelper.filterTypes.Range.id:
                return {
                    from: Number(this.firstNumberRegion.currentView.model.value),
                    to: Number(this.secondNumberRegion.currentView.model.value)
                };

            case fieldFilterHelper.filterTypes.Less.id:
                return {
                    from: fieldFilterHelper.fieldValues.MinNumber,
                    to: Number(this.firstNumberRegion.currentView.model.value)
                };

            case fieldFilterHelper.filterTypes.Greater.id:
                return {
                    from: Number(this.firstNumberRegion.currentView.model.value),
                    to: fieldFilterHelper.fieldValues.MaxNumber
                };

            default:
                return this.firstNumberRegion.currentView.model.value;
        }
    },
    initValues: function(initValue) {
        this.filterType = fieldFilterHelper.GetFilterTypeFromCurrentValue(initValue);

        switch (this.filterType) {
            case fieldFilterHelper.filterTypes.Range.id:
                this.firstNumber.setValue(initValue.from);
                this.secondNumber.setValue(initValue.to);
                break;

            case fieldFilterHelper.filterTypes.Equal.id:
                this.firstNumber.setValue(initValue);
                break;

            case fieldFilterHelper.filterTypes.Less.id:
                this.firstNumber.setValue(initValue.to);
                break;

            case fieldFilterHelper.filterTypes.Greater.id:
                this.firstNumber.setValue(initValue.from);
                break;
        }
    },
    initControls: function() {
        switch (this.filterType) {
            case fieldFilterHelper.filterTypes.Range.id:
                this.ui.rangeSeparator.show();
                this.ui.secondNumber.show();
                break;

            case fieldFilterHelper.filterTypes.Equal.id:
            case fieldFilterHelper.filterTypes.Less.id:
            case fieldFilterHelper.filterTypes.Greater.id:
                this.ui.rangeSeparator.hide();
                this.ui.secondNumber.hide();
                break;
        }
    },
    setFilterType: function(filterType) {
        this.filterType = filterType.value;
        this.initControls();
    }
});

FieldDetailsControls.DateFilterView = Backbone.Marionette.LayoutView.extend({
    template: _.template(
        '<div class="pull-left">Value</div><div class="date-input startDate"></div><div class="time-input startTime"></div><div class="range-separator">to</div><div class="time-input endTime"></div><div class="date-input endDate"></div>'
    ),
    regions: {
        startDateInputRegion: ".startDate",
        startTimeInputRegion: ".startTime",
        endDateInputRegion: ".endDate",
        endTimeInputRegion: ".endTime",
        dateRangeRegion: ".date-range"
    },
    ui: {
        rangeSeparator: ".range-separator",
        endDateInput: ".endDate",
        endTimeInput: ".endTime"
    },
    initialize: function() {
        this.startDate = UIControl.DatePicker.create();
        this.startTime = UIControl.TimePicker();

        this.endDate = UIControl.DatePicker.create();
        this.endTime = UIControl.TimePicker();
    },
    onRender: function() {
        this.startDateInputRegion.show(this.startDate);
        this.startTimeInputRegion.show(this.startTime);
        this.endDateInputRegion.show(this.endDate);
        this.endTimeInputRegion.show(this.endTime);

        this.initValues(this.model.currentValue);
        this.initControls();
    },
    getValue: function() {
        function applyTime(date, timeString) {
            if (!date || !timeString) {
                return;
            }

            const time = dateLib(timeString, "HH:mm:ss");
            date = date.set("hour", time.hour()).set("minute", time.minute());
            return date;
        }

        var startDate = this.startDate.getValue();
        startDate = applyTime(startDate, this.startTime.getValue());

        var endDate = this.endDate.getValue();
        endDate = applyTime(endDate, this.endTime.getValue());

        switch (this.filterType) {
            case fieldFilterHelper.filterTypes.Equal.id:
                return startDate.valueOf();

            case fieldFilterHelper.filterTypes.Range.id:
                return {
                    from: startDate.valueOf(),
                    to: endDate.valueOf()
                };

            default:
                return this.firstNumberRegion.currentView.model.value;
        }
    },

    initValues: function(initValue) {
        this.filterType = fieldFilterHelper.GetFilterTypeFromCurrentValue(initValue);

        switch (this.filterType) {
            case fieldFilterHelper.filterTypes.Equal.id:
                var startDate = dateLib(initValue);
                this.startDate.setValue(startDate.isValid() ? startDate : dateLib());
                this.startTime.setValue(startDate.isValid() ? startDate : dateLib());
                this.endDate.setValue(dateLib());
                this.endTime.setValue(dateLib());
                break;

            case fieldFilterHelper.filterTypes.Range.id:
                var startDate = dateLib(initValue.from);
                this.startDate.setValue(startDate.isValid() ? startDate : dateLib());
                this.startTime.setValue(startDate.isValid() ? startDate : dateLib());

                var endDate = dateLib(initValue.to);
                this.endDate.setValue(endDate.isValid() ? endDate : dateLib());
                this.endTime.setValue(endDate.isValid() ? endDate : dateLib());
                break;
        }
    },

    initControls: function() {
        switch (this.filterType) {
            case fieldFilterHelper.filterTypes.Equal.id:
                this.ui.rangeSeparator.hide();
                this.ui.endDateInput.hide();
                this.ui.endTimeInput.hide();
                break;

            case fieldFilterHelper.filterTypes.Range.id:
                this.ui.rangeSeparator.show();
                this.ui.endDateInput.show();
                this.ui.endTimeInput.show();
                break;
        }
    },
    setFilterType: function(filterType) {
        this.filterType = filterType.value;
        this.initControls();
    }
});

FieldDetailsControls.GetControlForType = function(model) {
    switch (model.type) {
        case utils.NATIVE_TYPES.Short.classname:
        case utils.NATIVE_TYPES.Integer.classname:
        case utils.NATIVE_TYPES.Long.classname:
        case utils.NATIVE_TYPES.Float.classname:
        case utils.NATIVE_TYPES.Double.classname:
            return new FieldDetailsControls.NumberFilterView({
                model: model
            });

        case utils.NATIVE_TYPES.String.classname:
            return new FieldDetailsControls.StringFilterView({
                model: model
            });

        case utils.NATIVE_TYPES.DateTime.classname:
            return new FieldDetailsControls.DateFilterView({
                model: model
            });
    }
};

export default FieldDetailsControls;
