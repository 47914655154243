import BaseTable from "./basetable";
import formatter from "app/components/monitor/common/valueFormatter";

var StreamsTable = {};

StreamsTable.View = BaseTable.View.extend({
    columns: [
        {
            label: "Name",
            srcfield: "label",
        },
        {
            label: "CPU",
            srcfield: "cpu-per-node",
            dataName: "cpu",
        },
        {
            label: "Nodes",
            srcfield: "nodes",
        },
        {
            label: "Events in",
            srcfield: "eventsIn",
        },
        {
            label: "Events/s",
            srcfield: "eventsPerSecond",
        },
        {
            label: "Events/s",
            srcfield: "eventsPerSecondGraph",
            view: BaseTable.CellItemSparklineView,
        },
    ],

    dataMapper: function (server) {
        return {
            label: server["full-name"],
            cpu: formatter.toPercentageString(server["most-recent-data"]["cpu-per-node"]),
            nodes: server["num-servers"],
            eventsIn: server["most-recent-data"]["input"],
            eventsPerSecond: formatter.formatRate(server["most-recent-data"]["rate"]),
            eventsPerSecondGraph: server["time-series-data"]["rate"],
        };
    },
});

export default StreamsTable;
