import { LayoutView, CompositeView } from "@striim/backbone.marionette";
import InlineHelpDictionary from "../../../../../../src/generic/inline-doc/inline-doc-dictionary";
import { on } from "../../../../../../core/decorators";
import ReactDOM from "react-dom/client";
import InlineDocContainer, { fetchHTML } from "../../../../../../src/generic/inline-doc/inline-doc-container";
import React from "react";

export default class InlineHelpMarionetteContainer extends LayoutView {
    initialize(id) {
        this.helpOptions = InlineHelpDictionary[id] || {
            path: id + ".html",
            cssSelector: "section.section"
        };
    }

    @on("click #toggleHelpPanel")
    toggleButton() {
        this.$el
            .find(".inline-help-helptext")
            .toggleClass("open")
            .toggleClass("closed");
        this.toggleHelpTimeout = setTimeout(() => {
            this.$el.find(".helpContent").toggle();
        }, 200);
    }

    @on("click #openInNewTab")
    openLink() {
        window.open("https://striim.com/docs/" + this.helpOptions.path, "_blank");
    }

    onBeforeDestroy() {
        if (this.toggleHelpTimeout) {
            clearTimeout(this.toggleHelpTimeout);
        }

        if (this.renderHelpContentTimeout) {
            clearTimeout(this.renderHelpContentTimeout);
        }
    }

    onShow() {
        const doCall = async () => {
            const data = await fetchHTML(null, this.helpOptions.path, this.helpOptions.cssSelector);
            if (data) {
                const buttonsHTML = `
                <div id="inline-doc-buttons">
                  <button class="secondary btn" id="toggleHelpPanel" title="View or hide Documentation for this screen."><img src="/app/images/striimline/sidebar-collapse.svg" /></button>
                  </div>
                  <div class="helpContent" />
                `;
                this.renderHelpContentTimeout = setTimeout(() => {
                    const inlineHelpContainer = this.$el.find(".inline-help-helptext");
                    inlineHelpContainer.append(buttonsHTML);
                    inlineHelpContainer.fadeIn();
                    const height = this.$el.find(".inline-help-content").height();
                    inlineHelpContainer.height(height);
                    const helpContent = this.$el.find("div.helpContent")[0];
                    if (helpContent) {
                        const root = ReactDOM.createRoot(helpContent);
                        root.render(
                            <InlineDocContainer
                                path={this.helpOptions.path}
                                cssSelector={this.helpOptions.cssSelector}
                            />
                        );
                    }
                    this.$el.find(".btn").tooltipster({
                        position: "bottom",
                        animation: "grow",
                        delay: 200
                    });
                }, 500);
                this.$el.find(".inline-help-helptext").show();
            }
        };
        doCall();
    }
}
