import App from "app";
import Backbone from "backbone";
import _ from "underscore";
import browser_template from "./hdfsbrowser.html";
import input_template from "./hdfsinput.html";
import dialog from "app/components/common/dialogs/dialogWindow";
import "app/components/common/_deprecated/form-mixin-dialog/dialog";
import "core/services/dataService/hdfsbrowserservice";

var hdfsBrowser = {};

hdfsBrowser.inputView = Backbone.Marionette.ItemView.extend({
    id: "hadoopUrlInput",
    template: _.template(input_template),
    initialize: function (options) {
        this.model = options.model;
        this.parentView = options.parentView;
    },
    onRender: function () {
        var self = this;
        this.$("#hadoopInputForm").submit(function (event) {
            event.preventDefault();
            var newFile = this.hadoopUrl.value;
            self.parentView.trigger("hadoopUrlChange", newFile);
            return false;
        });

        this.$("#hadoopUrl").submit(function (event) {
            event.preventDefault();
            return false;
        });

        this.$("#fileSubmitButton").click(function (event) {
            event.preventDefault();
            var thing = document.getElementById("hadoopInputForm");
            var val = thing.hadoopUrl.value;
            if (val === "") {
                dialog.alert("Please enter a URL");
                return;
            }
            self.parentView.trigger("hadoopUrlChange", val);
            return false;
        });
    },
});
hdfsBrowser.View = App.FormMixinDialog.View.extend({
    regions: {
        browserRegion: "#hdfsBrowser",
        inputRegion: "#inputBar",
    },
    template: _.template(browser_template),
    initialize: function (options) {
        this.model = options.model;
        //options.context  = this.options.vismodel.toJSON();
        App.FormMixinDialog.View.prototype.initialize.call(this, options);
        // Define what to do once the dialog form has successfully been saved.
        // In this case, update the vismodel, and close the dialog
        this.listenTo(this, "dialog-save", function (context) {
            this.options.vismodel.set(context);
            this.destroy();
        });

        this.triggers["click .cancel"] = "cancel";
        // Hook up the submit button
        this.triggers["click .submit"] = "submit";
        this.delegateEvents();
        this.listenTo(this, "submit", this.submit);
    },
    prepareModel: function (model) {
        var curURL = model.get("url");
        if (!curURL) {
            return;
        }

        var parsedURL = curURL.match("hdfs://[0-9]*[.][0-9]*[.][0-9]*[.][0-9]*:[0-9]*");
        if (parsedURL === null) {
            parsedURL = curURL.match("hdfs://localhost:[0-9]*");
        }
        model.set("url", parsedURL[0]);
    },
    onRender: function () {
        var opt = {
            model: this.model,
            parentView: this,
        };
        this.inputView = new hdfsBrowser.inputView(opt);
        this.inputView.render();
        this.inputRegion.show(this.inputView);
    },
    renderTree: function (model, collection) {
        var self = this;
        var options = {
            model: model,
            parentView: self,
        };
        var fileView = App.Preview.FileBrowser.FileView;
        this.treeView = new App.Preview.FileBrowser.TreeRoot({
            collection: collection,
            tagName: "ul",
            childView: fileView,
            childViewOptions: options,
        });
        this.treeView.render();
        this.browserRegion.show(this.treeView);
    },
    updateTree: function () {
        this.treeView.render();
        this.browserRegion.show(this.treeView);
    },
});

hdfsBrowser.Controller = App.FormMixinDialog.Controller.extend({
    initialize: function (options) {
        var self = this;
        this.view = options.view;
        this.model = options.model;
        App.FormMixinDialog.Controller.prototype.initialize.apply(this, arguments);
        this.listenTo(this.view, "hadoopUrlChange", this.changeURL, this);
        self.listenTo(this.view, "browseDirByIdClick", self.browse);
        self.listenTo(this.view, "previewByIdClick", self.previewFile);
    },
    browse: function (collection, fullPath) {
        var self = this;
        if (self.hdfsModel.id === fullPath) {
            return;
        }
        /* Send root model in requeest because it contains hadoop URL */
        var filePromise = App.request("hdfs:browseDirById", collection, fullPath, this.hdfsModel);

        filePromise.then(function(/*data*/) {
            self.view.updateTree();
        });
    },
    previewFile: function (id) {
        this.model.set("previewPath", id);
        this.model.set("url", this.hdfsModel.get("url"));
        this.model.set("fileType", "HDFSReader");
    },
    /***
     Change URL used to connecto HDFS namenode
     */
    changeURL: function (url) {
        var self = this;
        if (this.hdfsModel) {
            if (url === this.hdfsModel.get("url")) {
                return;
            }
            this.hdfsModel.set("url", url);
            this.updateModel();
        } else {
            var promise = App.request("hdfsbrowse:initial", url);
            promise.then(function(data) {
                self.hdfsModel = data;
                self.view.renderTree(data, data.collection);
            });
        }
    },
    updateModel: function () {
        var self = this;
        var updatePromise = App.request("hdfsbrowse:update", this.hdfsModel);
        updatePromise.then(function(data) {
            self.hdfsModel = data;
            self.hdfsCollection = data.collection;
            self.view.renderTree(self.hdfsModel, self.hdfsCollection);
        });
    },
});

export default hdfsBrowser;
