import Backbone from "backbone";
import _ from "underscore";
import App from "app";
import api from "core/api/api";
import masterDetailView from "app/components/common/master-detail-view/master-detail-view";
import metaObjects from "core/services/metaStoreService/meta-objects";
import apiTemplate from "./templates/api-test.html";
import crudTemplate from "./templates/crud-test.html";
import styleTemplate from "./templates/style-template.html";
import "lib/vendor/jquery.deparam";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import $ from "jquery";
import { initMaterialDesignTabs } from "../../../core/utils/material-design/tabs/material-design-style-tabs";

const APIView = Backbone.Marionette.LayoutView.extend({
    template: _.template(apiTemplate),
    events: {
        "click #api-execute": "execute"
    },
    execute: function(e) {
        e.preventDefault();
        var start = new Date();
        var that = this;
        var className, methodName;
        var methodParams = [];
        var params = this.$el.find("form").serializeArray();
        var serialized = this.$el.find("form").serialize();
        App.navigate("#/test/api/" + serialized);
        _.each(params, function(obj) {
            if (obj.name === "classNameValue") {
                className = obj.value;
                localStorage.setItem("test-classname", className);
            } else if (obj.name === "methodName") {
                methodName = obj.value;
                localStorage.setItem("test-methodname", methodName);
            } else if (obj.value !== "") {
                var formattedResult = that.formatDataType(obj.value);

                try {
                    formattedResult = JSON.parse(formattedResult);
                } catch (e) {
                    // console.error(formattedResult, e);
                }

                methodParams.push(formattedResult);
            }
        });
        var promise = api.call_direct(className, methodName, methodParams);
        promise.then(
            function(data) {
                data = JSON.stringify(data, null, 4);
                that.$el.find(".right code").html(data);
                that.$el
                    .find(".right")
                    .addClass("success")
                    .removeClass("fail");
            },
            function(data) {
                data = JSON.stringify(data, null, 4);
                that.$el.find(".right code").html(data);
                that.$el
                    .find(".right")
                    .addClass("fail")
                    .removeClass("success");
            }
        );

        promise.finally(function() {
            var end = new Date();
            var diff = end.getTime() - start.getTime();
            that.$el.find("#time-taken").html(diff + " ms");
            var data = JSON.stringify(arguments).length;
            that.$el.find("#payload-size").html(data + " Bytes");
        });
    },
    formatDataType: function(data) {
        if (data.indexOf("-") === -1 && !_.isNaN(parseFloat(data)) && _.isFinite(parseFloat(data))) {
            return parseFloat(data);
        } else if (data === "false") {
            return false;
        } else if (data === "true") {
            return true;
        } else {
            return data;
        }
    },
    onRender: function() {
        var params = $.deparam(this.options.params);
        for (var i in params) {
            this.$el.find('[name="' + i + '"]').val(params[i]);
        }
        this.$el.find('[name="param1"]').val(api.token);
        this.$el
            .find('[name="classNameValue"]')
            .val(localStorage.getItem("test-classname") || "com.webaction.security.WASecurityManager");
        this.$el.find('[name="methodName"]').val(localStorage.getItem("test-methodname") || "getAuthenticatedUser");
    }
});

const CRUDView = Marionette.LayoutView.extend({
    template: _.template(crudTemplate),
    events: {
        "click #get-execute": "get",
        "click #empty-execute": "createNew"
    },
    onShow: function() {
        setTimeout(() => {
            var type = this.$el.find('input[name="type"]').val();
            let actions = propertyTemplateService.getActions(type);
            this.$el.find(".actions").html(JSON.stringify(actions, null, 4));
        }, 1000);
    },
    get: function(e) {
        var start = new Date();
        e.preventDefault();
        var type = this.$el.find('input[name="type"]').val();
        var nsName = this.$el.find('input[name="nsName"]').val();
        var name = this.$el.find('input[name="name"]').val();
        var that = this;
        let actions = propertyTemplateService.getActions(type);
        that.$el.find(".actions").html(JSON.stringify(actions, null, 4));
        if (name) {
            var Model = metaObjects[type].Model;
            var model = new Model({
                id: nsName + "." + type + "." + name,
                name: name,
                nsName: nsName,
                type: type
            });
            model
                .fetch()
                .then(function() {
                    var data = JSON.stringify(model, null, 4);
                    that.$el.find(".right textarea").html(data);
                    that.$el
                        .find(".right")
                        .addClass("success")
                        .removeClass("fail");
                })
                .fail(
                    function(e) {
                        var data = JSON.stringify(model, null, 4);
                        alert("No object was found with that value. Creating an empty object instead");
                    },
                    function(e) {
                        var data = JSON.stringify(e, null, 4);
                        that.$el.find(".right textarea").html(data);
                        that.$el
                            .find(".right")
                            .addClass("fail")
                            .removeClass("success");
                    }
                )
                .finally(function() {
                    var end = new Date();
                    var diff = end.getTime() - start.getTime();
                    that.$el.find("#time-taken").html(diff + " ms");
                    var data = JSON.stringify(arguments).length;
                    that.$el.find("#payload-size").html(data + " Bytes");
                });
        } else {
            metaStoreService
                .fetchCollection(type)
                .then(function(collection) {
                    var data = JSON.stringify(collection, null, 4);
                    that.$el.find(".right textarea").html(data);
                    that.$el
                        .find(".right")
                        .addClass("success")
                        .removeClass("fail");
                })
                .fail(function(e) {
                    var data = JSON.stringify(e, null, 4);
                    that.$el.find(".right textarea").html(data);
                    that.$el
                        .find(".right")
                        .addClass("fail")
                        .removeClass("success");
                })
                .always(function() {
                    var end = new Date();
                    var diff = end.getTime() - start.getTime();
                    that.$el.find("#time-taken").html(diff + " ms");
                    var data = JSON.stringify(arguments).length;
                    that.$el.find("#payload-size").html(data + " Bytes");
                });
        }
    }
});

const StyleView = Marionette.LayoutView.extend({
    template: _.template(styleTemplate),

    ui: {
        tabs: "#test-tabs button.tab-button"
    },

    onShow: function() {
        //M.AutoInit();
        this.tabsRemoveCallback = initMaterialDesignTabs(document.querySelector("#test-tabs"));

        this.ui.tabs.click(function() {
            if (this.classList.contains("disabled")) {
                return;
            }
            let tab = this.dataset.tab;
            document.querySelectorAll("#test-tab-container .tab-content").forEach(x => {
                if (x.id !== tab) {
                    x.classList.add("hidden-element");
                } else {
                    x.classList.remove("hidden-element");
                }
            });
        });
    },

    destroy() {
        Backbone.Marionette.LayoutView.prototype.destroy.apply(this, arguments);
        if (this.tabsRemoveCallback) {
            this.tabsRemoveCallback();
        }
    }
});

const generateDetailsView = function() {
    var collection = new Backbone.Collection();
    var id = 0;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });
    id++;
    collection.add({
        id: id,
        name: "name" + id
    });

    var MasterListItemView = masterDetailView.MasterListItemView.extend({});

    var DetailLayoutView = masterDetailView.DetailLayoutView.extend({
        template: _.template("{{id}}ssss")
    });

    var MasterCollectionView = masterDetailView.MasterCollectionView.extend({
        childView: MasterListItemView
    });

    var MasterLayoutView = masterDetailView.MasterLayoutView.extend({
        template: _.template('<div class="foo"></div>'),
        regions: {
            masterCollectionView: ".foo"
        },
        onRender: function() {
            this.getRegion("masterCollectionView").show(
                new MasterCollectionView({
                    collection: collection
                })
            );
            masterDetailView.MasterLayoutView.prototype.onRender.apply(this);
        }
    });

    return masterDetailView.create({
        MasterLayoutView: MasterLayoutView,
        DetailLayoutView: DetailLayoutView
    });
};

export { APIView, CRUDView, StyleView, generateDetailsView };
