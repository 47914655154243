import React, { useMemo, Dispatch, SetStateAction } from "react";
import { Box } from "@mui/material";
import { StriimDataTable, StriimTypography, StriimIconWrapperBase } from "@striim/striim-ui";
import numeral from "numeral";

import { useStyles } from "./source-sensitive-data-table.styles";
import { viewAllTypes } from "../view-all-page/view-all-content";
import { AdapterIcon } from "../../../../../generic/icon/component-icon/component-icon";

interface SourceSensitiveDataTableProps {
    data: {
        source: {
            name: string;
            className: string;
        };
        sensitiveApps: number;
        sensitiveEntities: number;
    }[];
    setViewType?: Dispatch<SetStateAction<{ type: string;}>>;
    setViewAllView?: Dispatch<SetStateAction<boolean>>;
    setSelectedSource?: Dispatch<SetStateAction<string>>;
}

const SourceSensitiveDataTable = ({
    data,
    setViewType,
    setViewAllView,
    setSelectedSource
}: SourceSensitiveDataTableProps) => {
    const classes = useStyles();

    const tableColumns = useMemo(
        () => [
            {
                headerName: "Source",
                field: "source",
                flex: 1,
                minWidth: 200,
                suppressMovable: true,
                resizable: true,
                cellClass: "sourceCellClass",
                cellRenderer: ({ value }) => (
                    <Box display="flex" alignItems="center">
                        <AdapterIcon
                            name={value.className}
                            type={"source"}
                            outlined={true}
                            size={24}
                        />
                        <StriimTypography
                            variant="body4"
                            color="secondary.500"
                            paddingLeft={1}
                            onClick={() => {
                                setViewType({ type: viewAllTypes.sourceAppsList });
                                setSelectedSource(value.name);
                                setViewAllView(true);
                            }}
                        >
                            {value.name}
                        </StriimTypography>
                    </Box>
                )
            },
            {
                headerName: "Sensitive Apps",
                field: "sensitiveApps",
                suppressMovable: true,
                resizable: true,
                cellRenderer: ({ value }) => (
                    <StriimTypography variant="body4" color="greyscale.700">
                        {numeral(value).format("0a")}
                    </StriimTypography>
                )
            },
            {
                headerName: "Sensitive Entities",
                field: "sensitiveEntities",
                suppressMovable: true,
                resizable: true,
                cellRenderer: ({ value }) => (
                    <StriimTypography variant="body4" color="greyscale.700">
                        {numeral(value).format("0a")}
                    </StriimTypography>
                )
            }
        ],
        [data]
    );

    return (
        <Box className={classes.dataTableWrapper}>
            <StriimDataTable data={data} columns={tableColumns} rowHeight={40} />
        </Box>
    )
};

export default SourceSensitiveDataTable;
