import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ spacing }) => ({
    wrapper: {
        height: `calc(100vh - 250px)`
    },
    noDashboardImage: {
        width: 320,
        margin: spacing(3, 0)
    }
}));
