import $ from "jquery";
import _ from "underscore";
import metaStoreService from "core/services/metaStoreService/meta-store-service";

/**
 *
 * @param types - array of types..
 * @param whereFilterObject can be either a function (Backbone.Collection.filter), an Objcet(Backbobone.Collection.where) or undefined
 * @param app_or_flow_name - string or undefined.
 * @returns {*}
 */
const namespaceDataSource = async function() {
    const deferred = $.Deferred();
    let return_val = [];
    const collection = await getFilteredNamespaces()?.models;
    collection.each(function(model) {
        return_val.push({
            id: model.name,
            text: model.name
        });
    });
    deferred.resolve(return_val);

    return deferred.promise();
};

export default namespaceDataSource;
