import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { flushSync } from "react-dom";
import { Divider, Grid } from "@mui/material";
import { StriimTypography, StriimTheme, StriimButton } from "@striim/striim-ui";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts/react/highstock";

import { formatTimestampForTooltip, getTwoPointPrecision } from "./utils";
import { ArrowForwardBlue } from "../../../../../generic/icon/customIcons";
import {
    actionsChartColors,
    commonOptions,
    CustomPlotbandTooltip,
    CustomLegend,
    DataLegend,
    reportOptions
} from "./constants";
import {
    LegendData,
    SensitiveDataOccurrencesChartData
} from "../../../guardian/sentinel-monitor/service/sentinel-monitor.types";

const styles = {
    tooltipText: {
        "&:first-letter": {
            textDecoration: "underline"
        }
    }
};

const CustomTooltip = ({ points, chart, dataUnavailableTimestamp }) => {
    //points[0] => No Action, points[1] => Masked, points[2] => Encrypted
    const xValue = formatTimestampForTooltip(points?.[0].x);
    const maskedValue = points?.[1]?.y;
    const maskedPercentage = getTwoPointPrecision(points?.[1]?.percentage);
    const noActionValue = points?.[0]?.y;
    const noActionPercentage = getTwoPointPrecision(points?.[0]?.percentage);
    const encryptedValue = points?.[2]?.y;
    const encryptedPercentage = getTwoPointPrecision(points?.[2]?.percentage);

    if (dataUnavailableTimestamp && dataUnavailableTimestamp < points?.[0]?.x) {
        chart.tooltip.label?.element ? (chart.tooltip.label.element.style.display = "none") : "";
        return;
    } else {
        chart.tooltip.label?.element ? (chart.tooltip.label.element.style.display = "block") : "";
    }

    return (
        <Grid container minWidth={200} mt={2.5}>
            <StriimTypography variant="caption4" color="greyscale.700" fontFamily={"Inter"} p={1}>
                {xValue}
            </StriimTypography>
            <Divider
                variant="fullWidth"
                sx={{ width: "100%", borderColor: "greyscale.200", width: "calc(100% + 16px)", marginLeft: -1 }}
            />
            <Grid container p={1}>
                <StriimTypography variant="caption3" color="greyscale.900" fontFamily={"Inter"}>
                    Policy Actions
                </StriimTypography>
                <Grid display="flex" width={"100%"} item justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item display="flex" alignItems={"center"} gap={0.5}>
                        <CustomLegend color={actionsChartColors.encryptedBarColor} />
                        <StriimTypography
                            variant="caption3"
                            color="greyscale.700"
                            fontFamily={"Inter"}
                            sx={styles.tooltipText}
                        >
                            Encrypted
                        </StriimTypography>
                    </Grid>
                    <StriimTypography variant="caption3" color="primary.700" fontFamily={"Inter"}>
                        {encryptedValue}
                        {encryptedPercentage ? ` (${encryptedPercentage}%)` : ""}
                    </StriimTypography>
                </Grid>
                <Grid display="flex" width={"100%"} item justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item display="flex" alignItems={"center"} gap={0.5}>
                        <CustomLegend color={actionsChartColors.maskedBarColor} />
                        <StriimTypography
                            variant="caption3"
                            color="greyscale.700"
                            fontFamily={"Inter"}
                            sx={styles.tooltipText}
                        >
                            Masked
                        </StriimTypography>
                    </Grid>
                    <StriimTypography variant="caption3" color="primary.700" fontFamily={"Inter"}>
                        {maskedValue}
                        {maskedPercentage ? ` (${maskedPercentage}%)` : ""}
                    </StriimTypography>
                </Grid>
                <Grid display="flex" width={"100%"} item justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item display="flex" alignItems={"center"} gap={0.5}>
                        <CustomLegend color={actionsChartColors.noActionBarColor} />
                        <StriimTypography
                            variant="caption3"
                            color="greyscale.700"
                            fontFamily={"Inter"}
                            sx={styles.tooltipText}
                        >
                            No Action
                        </StriimTypography>
                    </Grid>
                    <StriimTypography variant="caption3" color="primary.700" fontFamily={"Inter"}>
                        {noActionValue} {noActionPercentage ? ` (${noActionPercentage}%)` : ""}
                    </StriimTypography>
                </Grid>
            </Grid>
        </Grid>
    );
};

interface SensitiveDataOccurrencesChartProps {
    chartRef: object;
    chartData: SensitiveDataOccurrencesChartData;
    onScroll: Function;
    hideNavigator?: boolean;
    selectedDateRange: { from: number; to: number } | null;
    isDiscoveryDisabled?: boolean;
    hasAppStopped?: boolean;
    dataUnavailableTimestamp?: number | null;
    onLiveViewClick: Function;
    legendData: LegendData;
    isReport?: boolean;
}

const SensitiveDataOccurrencesChart: React.FC<SensitiveDataOccurrencesChartProps> = ({
    chartRef,
    chartData,
    onScroll,
    hideNavigator = false,
    selectedDateRange,
    isDiscoveryDisabled = false,
    hasAppStopped = false,
    dataUnavailableTimestamp,
    legendData,
    onLiveViewClick,
    isReport = false
}) => {
    const [chartOptions, setChartOptions] = useState(null);
    const [plotBand, setPlotBand] = useState(null);

    useEffect(() => {
        const options = {
            chart: isReport ? reportOptions.chart : commonOptions.chart,
            credits: commonOptions.credits,
            series: [
                {
                    name: "No Action",
                    color: actionsChartColors.noActionBarColor,
                    type: "column",
                    data: chartData.noAction
                },
                {
                    name: "Masked",
                    color: actionsChartColors.maskedBarColor,
                    type: "column",
                    data: chartData.masked
                },
                {
                    name: "Encrypted",
                    type: "column",
                    color: actionsChartColors.encryptedBarColor,
                    data: chartData.encrypted
                }
            ],

            plotOptions: isReport ? reportOptions.plotOptions : commonOptions.plotOptions,
            xAxis: {
                ...commonOptions.xAxis,
                events: {
                    setExtremes: function(event) {
                        const dateRange = event.max !== this.dataMax ? { from: event.min, to: event.max } : null;
                        onScroll(event, dateRange);
                    }
                },
                range: hideNavigator ? 3600 * 24 * 1000 : 3600 * 1000 //hideNavigator = true -> 24 hour chart, 3600 = 1hr - all in milliseconds
            },
            yAxis: commonOptions.yAxis,
            scrollbar: { enabled: !isReport && !hideNavigator },
            navigator: {
                ...commonOptions.navigator,
                enabled: !isReport && !hideNavigator,
                series: {
                    lineColor: actionsChartColors.navigatorLineColor,
                    fillOpacity: 0,
                    data: chartData.occurrencesOfSensitiveData
                }
            },
            rangeSelector: commonOptions.rangeSelector,
            tooltip: {
                ...commonOptions.tooltip,
                formatter: function() {
                    const chart = chartRef.current.chart;
                    const tooltipDiv = document.createElement("div");
                    const root = ReactDOM.createRoot(tooltipDiv);
                    flushSync(() => {
                        root.render(
                            <StriimTheme preselector=".striim.materialize.light:not(#\20)">
                                <CustomTooltip
                                    points={this.points}
                                    chart={chart}
                                    dataUnavailableTimestamp={dataUnavailableTimestamp}
                                />
                            </StriimTheme>
                        );
                    });
                    return tooltipDiv.innerHTML;
                }
            }
        };
        setChartOptions(options);
    }, [chartData, hideNavigator, dataUnavailableTimestamp]);

    useEffect(() => {
        const updateChart = () => {
            const chart = chartRef?.current?.chart;
            if (!chart) return;
            chart.xAxis[0].setExtremes(null, null, false);
            chart.redraw();
        };
        updateChart();
    }, [hideNavigator]);

    useEffect(() => {
        if (chartRef.current?.chart?.xAxis) {
            const chart = chartRef.current.chart;
            if (isDiscoveryDisabled || hasAppStopped) {
                if (!plotBand?.id) {
                    const id = "custom-plot-band";
                    chart.xAxis[0].addPlotBand({
                        from: dataUnavailableTimestamp,
                        to: chartData.encrypted[chartData.encrypted.length - 1][0],
                        color: "#EBF2FA",
                        id: id,
                        events: {
                            mouseover: function(event) {
                                const xValue = chart.xAxis[0].toValue(event.clientX);
                                const plotbandEl = document.getElementById("custom-plot-band");
                                if (plotbandEl) {
                                    plotbandEl.style.display = "block";
                                    setPlotBand(prev => ({ ...prev, x: xValue }));
                                }
                            },
                            mouseout: function() {
                                const plotbandEl = document.getElementById("custom-plot-band");
                                if (plotbandEl) {
                                    plotbandEl.style.display = "none";
                                }
                            }
                        }
                    });
                    setPlotBand(prev => ({ ...prev, id }));
                }
            } else if (plotBand?.id) {
                setPlotBand({ id: null });
                setChartOptions(prevOptions => ({
                    ...prevOptions,
                    xAxis: {
                        ...prevOptions.xAxis,
                        plotBands: []
                    }
                }));
            }
        }
    }, [isDiscoveryDisabled, hasAppStopped, plotBand, dataUnavailableTimestamp, chartData]);

    const scrollToLatest = () => {
        const chart = chartRef.current.chart;
        const xAxis = chart.xAxis[0];
        xAxis.setExtremes(null, null, false);
    };

    return (
        <div>
            <DataLegend data={legendData} isReport={isReport} />
            <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                constructorType={"stockChart"}
                options={chartOptions}
            />
            {!!selectedDateRange && !hideNavigator && !isReport ? (
                <div style={{ textAlign: "right" }}>
                    <StriimButton
                        onClick={() => {
                            onLiveViewClick();
                            scrollToLatest();
                        }}
                        variant="text"
                        endIcon={<ArrowForwardBlue />}
                    >
                        Live View
                    </StriimButton>
                </div>
            ) : null}
            <div id="custom-plot-band" style={{ position: "absolute", top: -70, right: 0 }}>
                {plotBand?.x ? (
                    <CustomPlotbandTooltip
                        x={plotBand.x}
                        isDiscoveryDisabled={isDiscoveryDisabled}
                        hasAppStopped={hasAppStopped}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default SensitiveDataOccurrencesChart;
