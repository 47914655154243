import Backbone from "backbone";
import Marionette from "marionette";

var BaseLayout = Marionette.LayoutView.extend({
    initialize: function(options) {
        var _this = this;
        this.collection = options.collection;
        let index = options.currentStepName ? this.getStepIndex(options.currentStepName) : 0;
        this.collection.setActive(index);
        this.currentStepIndex = this.collection.getActive();
        this.vent = new Backbone.Model();

        this.vent.on("do:next", function() {
            _this.doNextHandler();
        });
        this.vent.on("do:prev", function() {
            _this.doPrevHandler();
        });
    },
    doNextHandler: function() {
        throw new Error("Please override the handler");
    },
    doPrevHandler: function() {
        throw new Error("Please override the handler");
    },
    getStepIndex: function(currentStepName) {
        const stepArray = this.collection;
        for (let stepIndex = 0; stepIndex < stepArray.length; stepIndex++) {
            const stepName = stepArray.models[stepIndex].get("stepName").toLowerCase();
            if (stepName === currentStepName) {
                return stepIndex;
            }
        }
        return 0;
    },
    setURL: function(index) {
        var currentStep = this.collection.models[index];
        currentStep.get("navigateToStep")?.();
    },
    getCurrentView: function(index) {
        var currentStep = this.collection.models[index];
        var currentView = currentStep.get("view");
        return new currentView({
            model: currentStep,
            vent: this.vent
        });
    },
    getCurrentStepIndex: function() {
        return this.currentStepIndex;
    }
});

export default BaseLayout;
