import React from "react";
import { AppStatusChip } from "../app-tile/components/app-status-indicators";
import statusManagement from "../../../../../../status-management";


function NonTransientIndicator({ flowStatus }) {
    const chipStatusVariant = statusManagement.getStatusChipDisplayName(flowStatus)
    return (
        <AppStatusChip label={chipStatusVariant} hideLabel />
    );
}

export default NonTransientIndicator;
