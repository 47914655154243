import _ from "underscore";

// Reorder the x coordinate of members of each row to minimize crossovers
function exchange_positions(node1, node2) {
    var node1_x = node1.coords[0];
    node1.coords[0] = node2.coords[0];
    node2.coords[0] = node1_x;
}

export default {
    uncross: function (nodes) {
        var layers = _(nodes).groupBy(function (node) {
            return node.layer;
        });
        _(layers).each(function (row, r) {
            layers[r] = _(row).sortBy(function (node) {
                return node.order;
            });
        });

        var getFirstCoordFromNode = function (id) {
            return nodes[id].coords[0];
        };

        var max_iterations = 8;
        for (var t = 0; t < max_iterations; t++) {
            var swaps_count = 0;

            for (var r = _.size(layers) - 2; r >= 0; r--) {
                var row = layers[r];

                var n = 0;
                var c = 0;
                while (n <= row.length - 2) {
                    var node1 = row[n];
                    var node2 = row[n + 1];

                    var node1obj = nodes[node1.id];

                    if (node1obj.edges_out.length === 0) {
                        // If this node has no parents, skip it.
                        n++;
                    } else {
                        var node2obj = nodes[node2.id];

                        var node1_child_x = _(node1obj.edges_out).map(getFirstCoordFromNode);
                        var node2_child_x = _(node2obj.edges_out).map(getFirstCoordFromNode);
                        var crossed = false;

                        _(node1_child_x).each(function (x1) {
                            _(node2_child_x).each(function (x2) {
                                if (x1 > x2) {
                                    crossed = true;
                                }
                            });
                        });

                        if (crossed) {
                            exchange_positions(node1, node2);
                            swaps_count++;
                        }
                        n++;
                    }
                    layers[r] = _.sortBy(row, function (node) {
                        return node.coords[0];
                    });

                    if (c > row.length - 2) {
                        break;
                    }
                    c++;
                }
            }

            if (swaps_count === 0) {
                break;
            }
        }

        return nodes;
    },
};
