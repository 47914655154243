import $ from "jquery";

export default {
    evaluate: function (expression, data) {
        var computable = _substituteValues(expression, data);
        var value = _compute(computable);
        return value;
    },
};

function _substituteValues(expression, data) {
    if ($.isNumeric(expression)) {
        return expression;
    }

    for (var fieldName in data) {
        if (data.hasOwnProperty(fieldName)) {
            if ($.isNumeric(data[fieldName])) {
                expression = expression.split(fieldName).join(data[fieldName]);
            }
            if (_.isString(data[fieldName])) {
                expression = expression.split(fieldName).join('"' + data[fieldName] + '"');
            }
        }
    }

    return expression;
}

function _compute(expression) {
    var value;
    try {
        //eval is needed to evaluate expression.
        value = eval(expression); // jshint ignore:line
    } catch (ex) {
        console.error(ex);
        value = 0.0;
    }

    if (!value) {
        value = 0.0;
    }

    return value;
}
