import React, { useEffect, useState } from "react";
import EmbeddingGeneratorHeader from "./embedding-generator-header";
import EmbeddingGeneratorList from "./embedding-generator-list";
import { EmbeddingGenerator } from "./embedding-generator-service";

interface EmbeddingGeneratorList {
    list: EmbeddingGenerator[];
    onAdd: () => void;
    onEdit: (item: EmbeddingGenerator) => void;
    onDelete: (item: EmbeddingGenerator) => void;
}

export default function EmbeddingGeneratorContent({ list, onAdd, onEdit, onDelete }: EmbeddingGeneratorList) {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredList, setFilteredList] = useState(list);

    useEffect(() => {
        setFilteredList(list);
        setSearchTerm("");
    }, [list]);

    const onSearch = (value: string) => {
        setSearchTerm(value);
        if (value) {
            // filter list by search term
            value = value.toLowerCase();
            const filteredListResult = list.filter(
                x =>
                    x
                        .get("name")
                        ?.toLowerCase()
                        .indexOf(value) !== -1 ||
                    x
                        .get("modelProvider")
                        ?.toLowerCase()
                        .indexOf(value) !== -1
            );
            setFilteredList(filteredListResult);
        } else {
            setFilteredList(list);
        }
    };

    return (
        <>
            <EmbeddingGeneratorHeader onAdd={onAdd} searchTerm={searchTerm} onSearch={onSearch} />
            {filteredList?.length > 0 ? (
                <EmbeddingGeneratorList list={filteredList} onEdit={onEdit} onDelete={onDelete} />
            ) : (
                <div data-test-id="embedding-generator-empty">Nothing matches your search criteria</div>
            )}
        </>
    );
}
