import { DB_PROVIDERS } from "../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/configs/db-providers";
import { BigQueryUtils } from "./dwh-utils";
import { commonDWHUtils } from "./dwh-utils/common-utils";
import { validationUtils } from "./validation-utils";
import { APP_ADAPTER_DEFAULTS } from "../../../../app/components/appwizard/component-wizards/wizards/common/app-adapters-global-config";
export const populateDWHProperties = (
    adapterProperties,
    values,
    componentName,
    isAutomatedILCDC,
    isCDC,
    sourceType
) => {
    const formData = values.targetFormValues;
    // Streaming Upload should be Boolean
    // In Some cases, While Selecting Streaming Upload from set of RadioButtons, It should be converted from string to Boolean
    if (typeof adapterProperties["streamingUpload"] !== "boolean")
        adapterProperties["streamingUpload"] = adapterProperties["streamingUpload"] !== "false";

    const getPrefix = (dataset: string): string => {
        if (["SnowflakeWriter", "FabricDataWarehouseWriter", "DeltaLakeWriter"].includes(componentName)) {
            return dataset ? `${dataset}.salesforce` : "salesforce";
        }
        return dataset || "salesforce";
    };
    const getCDDLOptions = (target: string) => {
        // For Databricks datatype for STRIIM__OPTIME should be TIMESTAMP
        // For all others datatype for STRIIM__OPTIME should be DATETIME
        let OPTIMEType: string;
        switch (target) {
            case "DeltaLakeWriter":
                OPTIMEType = "TIMESTAMP";
                break;
            case "FabricDataWarehouseWriter":
                OPTIMEType = "DATETIME2(3)";
                break;
            default:
                OPTIMEType = "DATETIME";
        }
        const STREAMOPTYPEType = target === "FabricDataWarehouseWriter" ? "VARCHAR(500)" : "STRING";
        return JSON.stringify({
            CreateTable: {
                action: "IgnoreIfExists",
                options: [
                    { CreateSchema: { action: "IgnoreIfExists" } },
                    { AddColumns: { STRIIM__OPTIME: OPTIMEType, STRIIM__OPTYPE: STREAMOPTYPEType } }
                ]
            }
        });
    };

    const removeQuotes = str => str.split('"').join("");

    // If Mode is APPENDONLY add Audit columns to tables
    const onAppendOnly = (tableKey, dataset = null, threePart = false) => {
        // To be set to tables property of DWH, Should be called only If MODE is APPENDONLY
        const addColumnMap = (tables, dataset, threePart) => {
            let parts = tables.split(";");
            let suffix = "ColumnMap(STRIIM__OPTIME=@METADATA(OPERATION_TS),STRIIM__OPTYPE=@METADATA(OperationName))";

            if (["SALESFORCEREADER", "SALESFORCECDCREADER"].includes(sourceType.toUpperCase())) {
                let salesforcePrefix = getPrefix(dataset);
                const salesforceSuffix = isCDC
                    ? "ColumnMap(STRIIM__OPTIME=@METADATA(commitTimestamp),STRIIM__OPTYPE=@METADATA(OperationName))"
                    : "ColumnMap(STRIIM__OPTIME=@METADATA(SystemModstamp),STRIIM__OPTYPE=@METADATA(OperationName))";
                return `%,${salesforcePrefix}.% ${salesforceSuffix}`;
            }

            // For each table add dataset, Column map suffix
            return parts
                .map(part => {
                    let schemaName = part.split(".")[0];
                    if (sourceType.toUpperCase() === DB_PROVIDERS.SQLSERVER.id && !isCDC) {
                        if (!dataset) return `${part} ${suffix}`;

                        const secondPart = part.split(".")[1];
                        const sourceSchemaName = schemaName + "." + secondPart;

                        return `${sourceSchemaName}."%",${dataset}.${removeQuotes(secondPart)}.% ${suffix}`;
                    }
                    if (!dataset) {
                        return `${schemaName}.%,${removeQuotes(schemaName)}.% ${suffix}`;
                    }
                    return !threePart
                        ? `${schemaName}.%,${dataset}.% ${suffix}`
                        : `${schemaName}.%,${dataset}.${schemaName.slice(1, -1)}.% ${suffix}`;
                })
                .join(";");
        };
        adapterProperties["CDDLOptions"] = getCDDLOptions(componentName);
        adapterProperties[tableKey] = addColumnMap(adapterProperties[tableKey], dataset, threePart);
    };

    /**
     *
     * @param tablesKey The key where the tables property is to be stored
     * @param dataset The dataset / SchemaName
     * @param hasAppendOnly Used to set AppendOnly property
     * ILCDC/ILApp
     *   Mode should always be APPENDONLY
     * ILCDC/CDCApp
     *   Mode should be selected from the form
     * ILCDC/*
     *   if selected Mode is APPENDONLY, Add audit columns
     * IL-Only
     *   Mode should always be APPENDONLY
     * CDC-Only
     *   Mode should be selected from the form
     */
    const handleModeProperty = (tablesKey, dataset = null, hasAppendOnly = false, threePart = false) => {
        if (isAutomatedILCDC) {
            adapterProperties["Mode"] = isCDC ? formData["Mode"] : "APPENDONLY";
            if (formData["Mode"] === "APPENDONLY") onAppendOnly(tablesKey, dataset, threePart);
        } else if (validationUtils.isAppAdapter(sourceType)) {
            adapterProperties["Mode"] = APP_ADAPTER_DEFAULTS.MODE;
        } else {
            adapterProperties["Mode"] = isCDC ? formData["Mode"] : "APPENDONLY";
            if (isCDC) adapterProperties[tablesKey] = `%,${dataset}.%`;
        }
        if (hasAppendOnly) adapterProperties["appendOnly"] = isCDC ? formData["Mode"] === "APPENDONLY" : true;
    };

    /**
     * ILCDC/ILApp
     *   streamingUpload should always be false
     * ILCDC/CDCApp
     *   streamingUpload should be selected from the form
     *  IL-Only
     *      streamingUpload should always be false
     *  CDC-Only
     *      streamingUpload should be selected from the form
     */
    const handleStreamingUpload = () => {
        adapterProperties["streamingUpload"] = isCDC ? formData["streamingUpload"] : false;
    };

    const addSalesforceColumnMap = (tableKey: string): void => {
        if (!commonDWHUtils.isAppendOnly(formData["Mode"]) && isCDC) adapterProperties[tableKey] += " COLUMNMAP()";
    };

    const handleThreePartName = (catalog: string, tablesKey = "Tables"): void => {
        let tablesProperty = values.defaultProperties[tablesKey];
        if (catalog) {
            let tableMappingArr = tablesProperty.split(";");
            tableMappingArr = tableMappingArr.map(function(value: string): string {
                let targetTableMapping;
                if (catalog) {
                    targetTableMapping = value.split(",");
                    targetTableMapping[1] = `${catalog}.${targetTableMapping[1]}`;
                    return targetTableMapping.join(",");
                }
                return value;
            });
            adapterProperties[tablesKey] = tableMappingArr.join(";");
        }
        if (commonDWHUtils.isSourceSalesforce(sourceType)) addSalesforceColumnMap(tablesKey);
    };

    /**
     * For ORACLE, MYSQL sources
     * If Mode is MERGE
     *      Set optimizedMerge to true;
     */
    const handleOptimizedMerge = () => {
        if (["ORACLE", "MYSQL"].includes(sourceType.toUpperCase())) {
            adapterProperties["optimizedMerge"] = isCDC && formData["Mode"] === "MERGE";
        }
    };

    const convertToTwoPart = tables => {
        let parts = tables.split(";");
        return parts
            .map(table => {
                const [sourceSide, targetSide] = table.split(",");
                const [dbName, schemaName, tableName] = sourceSide.split(".");
                return `${schemaName}.${tableName},${targetSide}`;
            })
            .join(";");
    };

    switch (componentName) {
        case "BigQuery":
            const BQUtils = new BigQueryUtils(adapterProperties, formData, sourceType, isAutomatedILCDC, isCDC);
            BQUtils.populateProperties();
            break;
        case "SnowflakeWriter":
            handleModeProperty("tables", formData["dbName"], true, true);
            handleOptimizedMerge();
            if (commonDWHUtils.isSourceSalesforce(sourceType) && formData["Mode"] !== "APPENDONLY") {
                handleThreePartName(formData["dbName"], "tables");
            }
            break;
        case "DeltaLakeWriter":
            const catalog = values.targetFormValues["catalog"];
            handleModeProperty("Tables", catalog, false, true);
            if (formData["Mode"] !== "APPENDONLY") handleThreePartName(catalog);
            handleOptimizedMerge();
            break;
        case "FabricDataWarehouseWriter":
            // Fabric to have three part tables
            const warehouse = formData["warehouse"];
            handleModeProperty("Tables", warehouse, false, true);
            if (formData["Mode"] !== "APPENDONLY") handleThreePartName(warehouse);
            break;
        case "FabricMirrorWriter":
            if (sourceType.toUpperCase() === DB_PROVIDERS.SQLSERVER.id && isCDC) {
                adapterProperties["Tables"] = convertToTwoPart(adapterProperties["Tables"]);
            }
            return;
    }
    handleStreamingUpload();
};
