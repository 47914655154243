import React from "react";
import dateLib from "core/utils/date-utils";
import { Grid } from "@material-ui/core";
import { StriimUnitsTile, striimUnitsTileVariants, StriimChart } from "./components";
import { dateFormat } from "./utils";
import useStyles from "./striim-units-usage-tab.styles";
import YourCredits from "./components/your-credits";
import UsageBreakdown from "./components/striim-unit-usage-breakdown";
import { convertNameToHumanRedable } from "app/components/common/editor/control-resource-resolver";
import { formatNumber } from "./components/striim-unit-usage-breakdown/components/utils";
import NoData from "./components/striim-unit-usage-breakdown/components/no-data-component";
import {StriimTypography} from "../user-plan/components/StriimTypographyCustom";

const StriimUnitsUsageTab = ({
    allUsageSummary,
    thisMonthSummary,
    lastMonthSummary,
    awardedCredits,
    goToUsagePlanTab,
    api,
    basicMinEventUsage
}) => {
    const classes = useStyles();

    const gradientChartData = allUsageSummary
        ? allUsageSummary.slice(allUsageSummary.length - 20, allUsageSummary.length).map(row => {
              return {
                  category: dateLib(row.startTime).format(dateFormat),
                  usage: formatNumber(row.usageTotal, false)
              };
          })
        : [];

    const unit = thisMonthSummary ? convertNameToHumanRedable(thisMonthSummary.unit) : "";

    return (
        <>
            <Grid container className={classes.unitsDetailedWrapper}>
                <Grid item xs={3}>
                    <StriimUnitsTile data={thisMonthSummary} type={striimUnitsTileVariants.currentMonth} todayLabel />
                </Grid>
                <Grid item xs={3}>
                    <StriimUnitsTile data={lastMonthSummary} type={striimUnitsTileVariants.lastMonth} />
                </Grid>
                <Grid item xs={6}>
                    <YourCredits awardedCredits={awardedCredits} />
                </Grid>
            </Grid>
            <hr className={classes.separator} />
            <Grid container>
                <Grid item xs={12}>
                    <StriimTypography variant="h3" className={classes.chartTitle}>
                        {unit} Consumption Trend
                    </StriimTypography>
                    <div className={classes.chartWrapper}>
                        {gradientChartData.length >= 2 ? (
                            <StriimChart
                                data={gradientChartData}
                                keys={["date", "usage"]}
                                fontSize={"16px"}
                                showCartesianGrid={true}
                                showAxisLine={true}
                                withLegend={false}
                                xAxisType={"category"}
                                yTickFormatter={x => formatNumber(x)}
                                tooltipFormatter={x => formatNumber(x)}
                            />
                        ) : (
                            <NoData
                                text={`We are still collecting the data. There isn't enough information to show a usage trend yet.
                                        Please check again later.`}
                                image
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
            <hr className={classes.separator} />
            <UsageBreakdown
                allUsageSummary={allUsageSummary}
                goToUsagePlanTab={goToUsagePlanTab}
                unit={unit}
                api={api}
                basicMinEventUsage={basicMinEventUsage}
            />
        </>
    );
};

export default StriimUnitsUsageTab;
