import App from "app";
import ReactDOM from "react-dom";
import tooltipyo from "app/components/common/tooltipyo/tooltipyo";
import createWizardView from "./createWizardView";
import baseTemplate from "./templates/appwizard.html";
import footerTemplate from "./templates/footer.html";
import helpableMixin from "app/components/common/helpable/helpable";
import appStatusSynchronizer from "core/appStatusSynchronizer";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import utils from "core/utils";
import navigateTo from "src/navigate-to";
import ensureRegions from "../common/_ensureRegions";
import securityService from "core/services/securityService/securityService";
import { isAutomatedWizard } from "./component-wizards/wizards/source/database-reader/app-services";
import WizardTagChip from "../../../src/modules/wizards/common/wizard-tag-chip/wizard-tag-chip";
import localStorageUtils from "app/components/appwizard/component-wizards/wizards/source/database-reader/local-storage-utils.js";
import DropWrapper from "../../../src/modules/wizards/common/modals/dropWrapper";
import MarionetteWrapper from "../marionette-wrapper-for-react";
import { VALIDATION_TIMEOUT } from "../../../src/modules/wizards/utils";
import { TYPES as WIZARD_TYPES } from "../../../src/modules/wizards/utils/wizard-types";
import appTemplates from "app/components/appwizard/templates";
import { isAppAdapter } from "../../../src/modules/wizards/utils/checkAdapterType";

const WizardView = App.module("AppWizard", function(AppWizard, App, Backbone, Marionette, $, _) {
    AppWizard.DISABLEDMESSAGE = "This wizard is disabled for the current template.";

    AppWizard.ConfigureAppView = (AppWizard, App, Backbone, Marionette, $, _) =>
        Marionette.LayoutView.extend({
            template: _.template(baseTemplate),
            templateHelpers: {
                isAutomatedWizard: isAutomatedWizard()
            },
            regions: {
                topbar: "#appwizard-topbar",
                content: "#appwizard-componentwizard",
                footer: "#appwizard-footer",
                modals: "#appwizard-modals"
            },
            initialize: function(options) {
                this.options = options;
            },
            isPageChanged: fromStep => fromStep && !window.location.href.includes("/" + fromStep),
            onRender: function() {
                ensureRegions(this);
                const wizard = createWizardView(this.options);
                let componentWizard = wizard.view;

                const templateID = this.options.templateID;
                const componentID = this.options.componentID;
                const isSource = componentID === 0;
                const AutomatedWizard = isAutomatedWizard();

                var footerview = new AppWizard.footerView({
                    stepName: this.options.stepName
                });

                this.getRegion("topbar").show(
                    new AppWizard.topbarView({
                        collection: wizard.steps,
                        componentID: componentID,
                        templateID: templateID,
                        className: "topbarView"
                    })
                );
                var that = this;

                footerview.on("do:cancel", () => {
                    //confirm if user wants to delete the app

                    let fqn = MetaObjectConverter.convertShortNameToFullName(
                        that.options.appName,
                        utils.ENTITIES.APPLICATION
                    );
                    let status = appStatusSynchronizer.getStatus(fqn);
                    if (status !== "CREATED") {
                        App.navigate("#apps", {
                            trigger: true
                        });
                        return;
                    }

                    if (this.options.forceDeleteApplication) {
                        const ModalDrop = MarionetteWrapper(DropWrapper, { shortName: this.options.appName });
                        this.modals.show(ModalDrop);
                    } else if (this.options.origin) {
                        App.navigate(this.options.origin, {
                            trigger: true
                        });
                    }
                });
                footerview.on("do:next", function(force = false) {
                    // force: To proceed even if the next button is disabled.
                    if (!footerview.disableNext || force) {
                        componentWizard.trigger("do:next");
                        App.vent.trigger("appwizard:retry:hide");
                    }
                });
                footerview.on("do:back", function() {
                    if (!footerview.disableBack) {
                        componentWizard.trigger("do:back");
                        App.vent.trigger("appwizard:retry:hide");
                    }
                });
                footerview.on("do:retry", function() {
                    // We remove the retry listeners on the page's onDestroy function.
                    // So only the listener specific to the current page will be called.
                    App.vent.trigger("appwizard:retry:checkpdb");
                    App.vent.trigger("appwizard:retry:schema-validation");
                });
                this.getRegion("content").show(componentWizard);

                if (isSource) {
                    this.getRegion("footer").show(footerview);
                }
                this.listenTo(App.vent, "appwizard:render:footer", () => {
                    this.getRegion("footer").show(footerview);
                });
                this.listenTo(App.vent, "appwizard:goForward", (fromStep = null) => {
                    setTimeout(() => {
                        if (that.isPageChanged(fromStep)) return;
                        footerview.trigger("do:next", true);
                    }, VALIDATION_TIMEOUT);
                });
                this.listenTo(App.vent, "appwizard:next:step", (params, stepName) => {
                    const step_id = componentID + 1;
                    const appName = this.options.appName;
                    if (wizard.steps.models[step_id]) {
                        if (params) {
                            App.navigate(
                                "#/appwizard/" +
                                    appName +
                                    "/" +
                                    templateID +
                                    "/" +
                                    step_id +
                                    "/" +
                                    stepName +
                                    "?" +
                                    params,
                                {
                                    trigger: true
                                }
                            );
                        } else {
                            App.navigate("#/appwizard/" + appName + "/" + templateID + "/" + step_id + "/" + stepName, {
                                trigger: true
                            });
                        }
                    } else {
                        var appUrl = "#/flow/" + that.options.appName + "/view/showProgress";
                        App.navigate(appUrl, {
                            trigger: true
                        });
                    }
                });

                this.listenTo(App.vent, "app:deleted", () => {
                    // Clean Up localstorage wizard data
                    localStorageUtils.cleanUp(this?.options?.appName);
                    if (window.location.href.includes("appwizard")) {
                        navigateTo.Apps();
                    }
                });
            }
        });

    AppWizard.ConfigureApp = AppWizard.ConfigureAppView(AppWizard, App, Backbone, Marionette, $, _);

    AppWizard.TemplateTopView = Marionette.ItemView.extend(helpableMixin).extend({
        template: _.template('<div><h4>Create New App &nbsp; <div class="template-info"></div></h4></div>'),
        getHelpID: function() {
            return "WIZARD";
        },
        getHelperContentElement: function() {
            return this.$el.find(".template-info");
        },
        onDestroy: function() {
            tooltipyo.hide();
        }
    });

    AppWizard.topbarElementView = Marionette.ItemView.extend(helpableMixin).extend({
        template: _.template(
            '<span class="{{cssClass}}">{{title}}</span> <div class="template-info"></div><i class="fa fa-angle-right icon"></i>'
        ),
        tagName: "div",
        className: "topbarEntry",
        onDestroy: function() {
            tooltipyo.hide();
        },
        initialize: function() {
            // Don't show Help link for Schema Conversion
            if (this.model.get("counter") !== 3) {
                helpableMixin.initialize.apply(this, arguments);
            }
        },
        serializeData: function() {
            var data = this.model.toJSON();
            data.cssClass = this.model.get("current") === true ? "current" : "notcurrent";
            return data;
        },
        getHelpID: function() {
            const name = this.model.get("title");

            if (this.model.get("counter") === 1) {
                // Help links for source step
                switch (name) {
                    case "HubSpot":
                        return "WIZARDSOURCE_HubSpot";
                    case "Zendesk":
                        return "WIZARDSOURCE_Zendesk";
                    case "Stripe":
                        return "WIZARDSOURCE_Stripe";
                    default:
                        return "WIZARDSOURCE";
                }
            } else {
                // Help links for target step
                const isAppAdapter = securityService.isAppAdapterLicense();
                if (isAppAdapter && name === "BigQuery") return "WIZARDTARGET_BigQuery";
                return "WIZARDTARGET";
            }
        },
        getHelperContentElement: function() {
            return this.$el.find(".template-info");
        }
    });

    AppWizard.footerView = Marionette.ItemView.extend({
        template: _.template(footerTemplate),
        className: "footerView",
        disableNext: false,
        ui: {
            footerBackButton: "#footer-back-button",
            footerNextButton: "#footer-next-button",
            footerRetryButton: "#footer-retry-button"
        },
        triggers: {
            "click #footer-cancel-button": "do:cancel",
            "click #footer-back-button": "do:back",
            "click #footer-next-button": "do:next",
            "click #footer-retry-button": "do:retry"
        },
        initialize: function(options) {
            this.stepNameAtInit = options.stepName;
        },
        isPageChanged: fromStep => {
            /**
             * Since we use the same footer component across the source wizards,
             * The Triggers from the previous page's api calls affects the current page.
             * This shouldn't happen, To avoid this check if the trigger is from the current page
             **/
            return fromStep && !window.location.href.includes("/" + fromStep);
        },
        onRender: function() {
            var that = this;
            if (that.stepNameAtInit === "source-info") {
                that.$el.find("#footer-back-button").hide();
            } else if (that.stepNameAtInit === "validate-schemas") {
                that.$el.find("#footer-next-button").addClass("disabled");
                that.$el.find("#footer-next-button").attr("disabled", true);
            }
            App.vent.on("appwizard:next:disable", function(fromStep = null) {
                if (that.isPageChanged(fromStep)) return;
                that.disableNext = true;
                that.$el.find("#footer-next-button").addClass("disabled");
                that.$el.find("#footer-next-button").attr("disabled", true);
            });
            App.vent.on("appwizard:next:enable", function() {
                that.disableNext = false;
                that.$el.find("#footer-next-button").removeClass("disabled");
                that.$el.find("#footer-next-button").removeAttr("disabled");
            });
            App.vent.on("appwizard:back:disable", function() {
                that.disableBack = true;
                that.$el.find("#footer-back-button").show();
                that.$el.find("#footer-back-button").addClass("disabled");
                that.$el.find("#footer-back-button").attr("disabled", true);
            });
            App.vent.on("appwizard:back:hide", function() {
                that.disableBack = true;
                that.$el.find("#footer-back-button").hide();
            });
            App.vent.on("appwizard:back:enable", function() {
                that.disableBack = false;
                that.$el.find("#footer-back-button").show();
                that.$el.find("#footer-back-button").removeClass("disabled");
                that.$el.find("#footer-back-button").removeAttr("disabled");
            });
            App.vent.on("appwizard:retry:enable", function() {
                that.disableRetry = false;
                that.$el.find("#footer-retry-button").removeClass("disabled");
                that.$el.find("#footer-retry-button").removeAttr("disabled");
            });
            App.vent.on("appwizard:retry:disable", function() {
                that.disableRetry = true;
                that.$el.find("#footer-retry-button").addClass("disabled");
                that.$el.find("#footer-retry-button").attr("disabled", true);
            });
            App.vent.on("appwizard:retry:show", function(fromStep = null) {
                if (that.isPageChanged(fromStep)) return;
                that.$el.find("#footer-retry-button").show();
                that.$el.find("#footer-next-button").hide();
            });
            App.vent.on("appwizard:retry:hide", function() {
                that.$el.find("#footer-retry-button").hide();
                that.$el.find("#footer-next-button").show();
            });
        }
    });

    AppWizard.tooltipEntry = Marionette.ItemView.extend({
        template: _.template(
            '<div><h4 class="ta-l"><span class="ta-c">{{counter}}</span>{{step_tooltip_title}}</h4><p>{{step_tooltip_text}}</p></div>'
        ),
        className: "wizard-top-bar-tool-tip-entry ml-8px"
    });

    AppWizard.toolTip = Marionette.CompositeView.extend({
        template: _.template(
            '<div><h3 class="mb-16px ta-c">{{tooltip_title}}</h3></div><div class="tooltip-steps"></div>'
        ),
        childViewContainer: ".tooltip-steps",
        className: "wizard-top-bar-tool-tip p-16px",
        childView: AppWizard.tooltipEntry,
        initialize: function(options) {
            this.collection = options.collection;
            this.model = options.model;
        }
    });

    AppWizard.topbarView = Marionette.CompositeView.extend({
        template: _.template('<div id="wizard-tag-chip"></div><div class ="template-steps-list"></div>'),
        childViewContainer: ".template-steps-list",
        childView: AppWizard.topbarElementView,
        initialize: function(options) {
            const type = this.getWizardType(options.templateID);
            this.model = new Backbone.Model({
                wizardType: type
            });
        },
        getWizardType: function(templateID) {
            const sourceAdapter = appTemplates.getByURL(templateID)?.sourceAdapter;
            if (templateID.includes("automated-ILCDC")) {
                return WIZARD_TYPES.Automated;
            } else if (sourceAdapter !== "SalesforceCDCReader" && isAppAdapter(sourceAdapter)) {
                return WIZARD_TYPES.Automated_Mode_App_Adapter;
            } else if (templateID.includes("to-create-schema")) {
                return WIZARD_TYPES.IL_With_Schema_Conversion;
            }
        },
        onRender: function() {
            ReactDOM.render(
                <WizardTagChip wizardType={this.model.get("wizardType")?.toUpperCase()} />,
                this.el.querySelector("#wizard-tag-chip")
            );
        },
        onBeforeDestroy() {
            ReactDOM.unmountComponentAtNode(this.el.querySelector("#wizard-tag-chip"));
        }
    });
});

export default WizardView.ConfigureApp;
