import _ from "underscore";
import BaseControl from "./../../../base-control";
import Select from "./../../../select/select";
import DgDataSource from "./../../../select/dg-datasource";
import template from "./deployment-plan.html";

var view = BaseControl.View.extend({
    template: _.template(template),
    regions: {
        dgRegion: ".tbl-dg",
        nodesRegion: ".tbl-nodes",
    },
    initialize: function () {
        var _this = this;

        _this.model.on("change:value", function () {
            var name = this.value.flowName.split(".")[2];
            this.set(
                {
                    name: name,
                },
                {
                    silent: true,
                }
            );
        });

        var dgDataSource = new DgDataSource();
        _this.dgSelector = Select(dgDataSource, {
            allowClear: false,
        }).create();

        var nodesDataSource = [
            {
                id: "ON_ALL",
                text: "On All",
            },
            {
                id: "ON_ONE",
                text: "On One",
            },
        ];
        _this.nodesSelector = Select(nodesDataSource, {
            allowClear: false,
            hideSearch: true,
        }).create();
    },
    onRender: function () {
        var _this = this;
        _this.dgSelector.setEnabled(_this.enabled);
        _this.nodesSelector.setEnabled(_this.enabled);

        _this.dgRegion.show(_this.dgSelector);
        _this.nodesRegion.show(_this.nodesSelector);
        _this.setViewValue();

        _this.listenTo(_this.dgSelector.model, "change:value", function () {
            _this.setValueFromView();
        });

        _this.listenTo(_this.nodesSelector.model, "change:value", function () {
            _this.setValueFromView();
        });
    },
    getViewValue: function () {
        var value = this.getValue();
        value.dg = this.dgSelector.getValue();
        value.strategy = this.nodesSelector.getValue();
        return value;
    },

    setViewValue: function () {
        var value = this.getValue();
        if (!value) {
            return;
        }

        this.dgSelector.setValue(value.dg);
        this.nodesSelector.setValue(value.strategy);
    },
    setViewEnabled: function () {
        BaseControl.View.prototype.setViewEnabled.apply(this);
        var enabled = this.getEnabled();
        if (this.dgSelector) {
            this.dgSelector.setEnabled(enabled);
        }
        if (this.nodesSelector) {
            this.nodesSelector.setEnabled(enabled);
        }
    },
});

export default function () {
    var View = view;
    var Model = BaseControl.Model.extend({
        defaults: {
            value: Object,
            name: String,
        },
    });

    return _.extend({}, BaseControl, {
        Model: Model,
        View: View,
    });
}
