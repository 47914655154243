import App from "app";
import style_modal_template from "./style_config.html";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import dataVisualizationsEditors from "core/services/metadataService/dataVisualizationsEditors";
import "app/components/dashboard/editors/propertieseditor";

App.module("Dashboard", function (Dashboard, App, Backbone, Marionette, $, _) {
    // Style Configuration Modal
    Dashboard.StyleModal = App.FormMixinDialog.View.extend({
        template: _.template(style_modal_template),
        regions: {
            inner: ".modal-body .inner",
        },
        initialize: function (options) {
            var _this = this;

            options.draggable = false;
            options.origin = false;

            // Because we are directly extending, rather than mixing in, we need to explicitly call super
            App.FormMixinDialog.View.prototype.initialize.call(this, options);

            // Hook up the submit button
            this.triggers["click .submit"] = "submit";
            this.delegateEvents();
            this.listenTo(this, "submit", function () {
                _this.$("input").blur(); // DEV-9867: Dashboards: Style dialog DONE button not saving state
                _this.submit.call(arguments);
            });
        },
        onRender: function () {
            // Using our dynamic VisEditor implementation to render the grid cell style editor
            var ActionsView = new App.Dashboard.VisEditor[dataVisualizations.EDITOR.PROPERTIES]({
                model: new dataVisualizationsEditors.EditorViewModel({
                    _theme: App.Metadata.Themes.WebActionDark,
                    editor: new dataVisualizationsEditors.PropertyEditor({
                        propertyeditors: [
                            {
                                attribute: "border",
                                type: dataVisualizations.EDITOR.PROPERTIES,
                                options: {
                                    label: "Border",
                                    label_style: "heading",
                                    flow: "inline",
                                },
                                propertyeditors: [
                                    {
                                        attribute: "color",
                                        type: dataVisualizations.EDITOR.COLOR,
                                        options: {
                                            flow: "inline",
                                        },
                                    },
                                    {
                                        attribute: "size",
                                        type: dataVisualizations.EDITOR.NUMBER,
                                        options: {
                                            flow: "inline",
                                            length: 4.5,
                                            placeholder: "ex. 1 (px)",
                                        },
                                    },
                                ],
                            },
                            {
                                attribute: "background",
                                type: dataVisualizations.EDITOR.PROPERTIES,
                                options: {
                                    label: "Background",
                                    label_style: "heading",
                                    flow: "inline",
                                },
                                propertyeditors: [
                                    {
                                        attribute: "color",
                                        type: dataVisualizations.EDITOR.COLOR,
                                        options: {
                                            flow: "inline",
                                        },
                                    },
                                ],
                            },
                            {
                                attribute: "margin",
                                type: dataVisualizations.EDITOR.NUMBER,
                                options: {
                                    label: "Margin",
                                    label_style: "heading",
                                    flow: "inline",
                                    length: 4.5,
                                    placeholder: "ex. 1 (px)",
                                },
                            },
                        ],
                    }),
                    config: this.model.style,
                }),
            });
            this.getRegion("inner").show(ActionsView);
        },
    });
});
