import React from "react";
import { StriimButton, StriimTypography, StriimLink, StriimMessageBox, StriimGradientChip } from "@striim/striim-ui";
import { Grid, SvgIcon } from "@mui/material";

import { styles } from "./no-jobs-run.styles";
import NewTab from "app/images/New Tab.svg";
import { InfoCircle, Close } from "src/generic/icon/customIcons";
import AI_ROUTES from "../../../routes.json";
import WithTooltip from "../../../../../generic/tooltip/tooltip";
import { Sherlock } from "../../../../../generic/icon/customIcons";
import dictionary from "../../../../../../app/components/common/helpable/online-help-dictionary";

interface NoJobsRunInterface {
    isUserAdmin?: boolean;
    isAIEngineConfigured?: boolean;
    onDiscover: Function;
    onClose: Function;
    disabled: boolean;
    disabledTooltip: string;
}

const NoJobsRun = ({
    isUserAdmin = true,
    isAIEngineConfigured = true,
    onDiscover,
    onClose,
    disabled,
    disabledTooltip
}: NoJobsRunInterface) => {
    return (
        <Grid container flexDirection={"column"} alignItems={"center"}>
            <StriimMessageBox
                type="INFO"
                heading="Please ensure that the AI Service is up and running before starting your Data Discovery Job."
                text={
                    <p>
                         Click{" "}
                        <a
                            href={dictionary.get()["AI_INSIGHTS_ENGINE"].href}
                            target="_blank"
                            style={{ color: "white", textDecoration: "underline", fontWeight: "bold" }}
                        >
                            here
                        </a>{" "}
                        for simple steps for an initial easy configuration.
                    </p>
                }
                sx={{ width: "50%" }}
            ></StriimMessageBox>


            <Grid container justifyContent={"space-between"} px={2} py={3}>
                <Sherlock style={{ ...styles.securityImage, alignSelf: "flex-start" }} />
                <StriimButton
                    variant="text"
                    startIcon={<SvgIcon component={Close} sx={styles.closeIcon} />}
                    sx={styles.iconButton}
                    onClick={onClose}
                />
            </Grid>
            <Grid container px={2} gap={1} flexDirection={"column"} alignItems={"flex-start"}>
                <StriimGradientChip version="ai" />
                <StriimTypography variant="h3" color="primary.700">
                    Discover Sensitive Data with Sherlock AI
                </StriimTypography>
            </Grid>
            <Grid container px={2} pt={2} pb={3}>
                <StriimTypography variant="body4" color="greyscale.700" sx={styles.text}>
                    Discover sensitive data identifiers that can flow through your app by examining the sources,
                    enabling you to design the app to meet your data governance policies.
                </StriimTypography>
            </Grid>

            <Grid container px={2} gap={1.5}>
                {isAIEngineConfigured ? (
                    <>
                        {WithTooltip(
                            <StriimButton
                                variant="primary"
                                data-test-id="panel-discover-button"
                                onClick={onDiscover}
                                disabled={disabled}
                            >
                                Discover
                            </StriimButton>,
                            disabled ? disabledTooltip : null
                        )}

                        <StriimLink href={dictionary.get()["AI_INSIGHTS_SHERLOCK"].href} target="_blank">
                            Learn more
                        </StriimLink>
                    </>
                ) : (
                    <StriimMessageBox
                        type="NOTIFICATION"
                        customIcon={<SvgIcon component={InfoCircle} sx={styles.infoIcon} />}
                        heading={
                            <>
                                <StriimTypography variant="caption3" color="greyscale.800" sx={styles.infoText}>
                                    {isUserAdmin
                                        ? "Configure the default AI Engine to start discovering sensitive data in Striim."
                                        : "Contact your Striim admin to configure the AI Engine."}
                                </StriimTypography>
                                <StriimLink
                                    endIcon={<SvgIcon component={NewTab} sx={styles.infoNewTabIcon} />}
                                    //TODO: Replace url with contextID/
                                    href={isUserAdmin ? `#${AI_ROUTES.sherlockSettings}` : "#"}
                                    target={"_blank"}
                                    sx={styles.link}
                                >
                                    {isUserAdmin ? "Settings" : "Instructions"}
                                </StriimLink>
                            </>
                        }
                        customCardStyles={styles.infoBox}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default NoJobsRun;
