import { getTemplateGroups } from "../apps/pages/create-app-page/utils";
import prominentSources from "./prominent-sources.json";
import predefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import securityService from "../../../core/services/securityService/securityService";
import HelpResources from "app/components/common/helpable/online-help-dictionary";
import { homePageInfo } from "./homepage_info";
import licenseService from "../../../core/services/metaStoreService/license-service";

const targetsMap = {};

const homepageService = {
    getSources: function() {
        let sources = [];
        if (Object.keys(targetsMap).length > 0) {
            sources = Object.keys(targetsMap);
        } else {
            const { templatesList } = getTemplateGroups();
            templatesList.forEach(template => {
                const source = template.steps[0];
                if (!targetsMap[source]) targetsMap[source] = new Set();
                if (template.steps[1]) targetsMap[source].add({ id: template.steps[1], url: template.url });
            });
            sources = Object.keys(targetsMap);
        }
        sources = sources.sort();
        let sourcesList = sources.map(source => {
            const details = predefinedComponentNames[source];
            return {
                id: details.name,
                value: source,
                type: "SOURCE",
                label: details.name,
                icon: details.className
            };
        });

        return sourcesList.sort((a, b) => (a.id > b.id ? 1 : -1));
    },
    getBigQuerySources: function() {
        return this.getSources()
            .filter(d => d.value !== "GoogleBigQuery")
            .filter(source => source.targets.some(target => target.steps.indexOf("BigQuery") > 0));
    },
    getTargets: function(source) {
        if (!source) {
            return [];
        }

        let sourceName = source.value;
        let targets = targetsMap[sourceName];
        let targetsList = [];

        targets.forEach(target => {
            const details = predefinedComponentNames[target.id];
            targetsList.push({
                id: details.name,
                icon: details.className,
                url: target.url,
                label: details.name
            });
        });
        targetsList = targetsList.sort((a, b) => (a.id > b.id ? 1 : -1));
        return targetsList;
    },
    isGoogleBigQuery: function() {
        return false;
        // return securityService.isGoogleBigQuery();
    },
    getDatabaseReplicationOptions: function() {
        return this.getSources().filter(s =>
            prominentSources.replication.some(item => item.toLowerCase() === s?.label?.toLowerCase())
        );
    },
    getDatabaseReplicationCardInfo: function() {
        return {
            image: "src/modules/apps/pages/homepage/resources/images/Group20.png",
            header: "Database replication",
            copy: "Data replication to enable application availability and disaster recovery.",
            buttonText: "View Wizards",
            buttonLink: "/create-app",
            options: this.getDatabaseReplicationOptions()
        };
    },
    getMoveYourDataToTheCloudOptions: function() {
        return this.getSources().filter(s =>
            prominentSources.cloud.some(item => item.toLowerCase() === s?.label?.toLowerCase())
        );
    },
    getMoveYourDataToTheCloudCardInfo: function() {
        return {
            image: "src/modules/apps/pages/homepage/resources/images/Group20.png",
            header: "Move your data to the Cloud",
            copy: "Power your organization's operations and business intelligence with real-time data",
            buttonText: "View Wizards",
            buttonLink: "/create-app",
            options: this.getMoveYourDataToTheCloudOptions()
        };
    },
    getRealtimeInsightsOptions: function() {
        return this.getSources().filter(s =>
            prominentSources.insights.some(item => item.toLowerCase() === s?.label?.toLowerCase())
        );
    },
    getRealtimeInsightsCardInfo: function() {
        return {
            image: "src/modules/apps/pages/homepage/resources/images/Group20.png",
            header: "Get real time insights",
            copy: "Move your data to cloud data warehouses for real time insights.",
            buttonText: "View Wizards",
            buttonLink: "/create-app",
            options: this.getRealtimeInsightsOptions()
        };
    },
    bigQueryCdcOptions: function() {
        const options = this.getSources().filter(s =>
            prominentSources.bigQueryCdc.some(item => item.toLowerCase() === s.label.toLowerCase())
        );

        return {
            image: "src/modules/apps/pages/homepage/resources/images/Group20.png",
            header: "Continuously move your data to BigQuery",
            copy: "With the power of CDC, you can move your data continuously into BigQuery.",
            buttonText: "View Wizards",
            buttonLink: "/create-app",
            options,
            isBigQuery: true
        };
    },
    bigQueryLoadDataOptions: function() {
        const options = this.getSources().filter(s =>
            prominentSources.bigQueryLoadData.some(item => item.toLowerCase() === s.label.toLowerCase())
        );

        return {
            image: "src/modules/apps/pages/homepage/resources/images/Group20.png",
            header: "Load your data into BigQuery",
            copy: "Automatically convert your schema and load your data to BigQuery",
            buttonText: "View Wizards",
            buttonLink: "/create-app",
            options,
            isBigQuery: true
        };
    },
    bigQueryNonRdbmsOptions: function() {
        const options = this.getSources().filter(s =>
            prominentSources.bigQueryNonRdbms.some(item => item.toLowerCase() === s.label.toLowerCase())
        );

        return {
            image: "src/modules/apps/pages/homepage/resources/images/Group20.png",
            header: "Move your non-RDBMS sources into BigQuery ",
            copy: "Striim can help move data from non-RDBMS into Big Query.",
            buttonText: "View Wizards",
            buttonLink: "/create-app",
            options,
            isBigQuery: true
        };
    },
    getExploreCopy: function() {
        if (securityService.isAppAdapterLicense()) {
            return [
                {
                    title: "Stream data from HubSpot to BigQuery",
                    link: HelpResources.get().WIZARDSOURCE_HubSpot.href,
                    description: "Learn how to configure HubSpot to BigQuery pipeline"
                },
                {
                    title: "Stream data from Stripe to BigQuery",
                    link: HelpResources.get().WIZARDSOURCE_Stripe.href,
                    description: "Learn how to configure Stripe to BigQuery pipeline"
                },
                {
                    title: "Stream data from Zendesk to BigQuery",
                    link: HelpResources.get().WIZARDSOURCE_Zendesk.href,
                    description: "Learn how to configure Zendesk to BigQuery pipeline"
                }
            ];
        } else if (licenseService.nonSegmentedlicenseName === "SQL2FABRICSOLUTION") {
            return null;
        } else {
            return [
                {
                    title: "Stream data from PostgreSQL to Snowflake",
                    link:
                        "https://www.striim.com/tutorial/stream-data-from-postgresql-cdc-to-snowflake-with-striim-cloud/",
                    description: "Stream and visualize real-time analytics using Striim’s powerful Change Data Capture"
                },
                {
                    title: "Real-time analytics with Striim and BigQuery",
                    link:
                        "https://www.striim.com/tutorial/real-time-point-of-sale-pos-analytics-with-striim-and-bigquery/",
                    description: "Generate transaction reports and send alerts when transaction counts are unusual."
                },
                {
                    title: "Stream data from PostgreSQL to BigQuery",
                    link:
                        "https://www.striim.com/tutorial/stream-data-from-postgresql-to-google-bigquery-with-striim-cloud-part-2/",
                    description: "Stream CDC data securely from a PostgreSQL database into Google BigQuery"
                }
            ];
        }
    },
    getVideoData: function() {
        const videoData = {
            SQL2FABRICSOLUTION: {
                url: "https://dng3nk8mjmiic.cloudfront.net/SQL2Fabric%20Demo_Added%20Music.mp4",
                width: "75%",
                thumbnail: "app/images/sql2fabric_solution_thumbnail.png"
            },
            DEFAULT: {
                url: !securityService.isDeveloperLicense()
                    ? "https://striim-video.s3.amazonaws.com/Striim-Overview.mp4"
                    : "https://www.youtube.com/embed/tKZVp9jEKGE",
                width: "85%",
                style: {
                    objectFit: "inherit",
                    height: "33vh"
                },
                thumbnail: "app/images/homepage_thumbnail.png"
            }
        };
        return videoData[licenseService.nonSegmentedlicenseName] || videoData.DEFAULT;
    },
    getCurrentHomePageInfo: function() {
        return homePageInfo()[licenseService?.nonSegmentedlicenseName] ?? homePageInfo()["DEFAULT"];
    },
    getWelcomeMessageInfo: function(sourceName, isAppAdapterLicense) {
        return (
            homePageInfo(sourceName, isAppAdapterLicense)[licenseService?.nonSegmentedlicenseName]?.welcomeMsgInfo ??
            homePageInfo(sourceName, isAppAdapterLicense)["DEFAULT"]?.welcomeMsgInfo
        );
    },
    getExploreInfo: function() {
        return (
            homePageInfo()[licenseService?.nonSegmentedlicenseName]?.exploreInfo ??
            homePageInfo()["DEFAULT"].exploreInfo
        );
    }
};

export default homepageService;
// https://www.youtube.com/watch?v=D_aqa0lmV1k
