import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var module = {};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "SHIELDPROFILE",
        shieldProviderType: String,
        properties: Object
    }
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model
});

export default module;
