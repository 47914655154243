import React, { useEffect, useState, useCallback } from "react";
import growl from "../../../../../../app/components/common/growl";
import { FilesEmpty, FilesPresent } from "./";
import { Card } from "../components";
import LoadingIndicator from "../../../../../generic/loading-indicator/loading-indicator";
import api from "core/user-management/userManagementApi/userManagementApi.js";
// The server occasionally returns tmp files for some reason; this filters them out
const filterFiles = theFiles => {
    return theFiles?.filter(file => !file?.name?.includes("tmp"));
};

const FilesPage = ({ selectFile, setFileSelectionModalShown }) => {
    const [files, setFiles] = useState(null);
    const [fetchInProgress, setFetchInProgress] = useState(true);
    const [directories, setDirectories] = useState([]);
    const [userDirectory, setUserDirectory] = useState(null);

    useEffect(() => {
        if (!files) {
            fetchData();
        }
    }, [files]);

    const fetchNodes = async theFiles => {
        return new Promise(async resolve => {
            setFiles([...sortFiles(theFiles)]);
            const promises = theFiles.map(async file => {
                if (file.isDirectory) {
                    const node = await fetchNode(file);
                    if (node?.children?.length) {
                        file.children = node.children;
                        await fetchNodes(file.children);
                        setFiles([...sortFiles(theFiles)]);
                    } else {
                        theFiles.splice(theFiles.indexOf(file), 1);
                        setFiles([...sortFiles(theFiles)]);
                    }
                }
            });
            await Promise.all(promises);
            resolve();
        });
    };
    const fetchUsers = async (directories, defaultPath) => {
        const users = await api.getUsers();
        const filterDuplicates = userID => !directories.map(d => d.label).includes(`/${userID}`) && userID !== "sys";

        if (users.length) {
            directories.push(
                ...users
                    .filter(user => filterDuplicates(user["User Id"]))
                    .map(user => {
                        const id = user["User Id"];
                        return { label: `/${id}`, value: `${defaultPath}${id}/` };
                    })
            );
        }
    };
    const fetchNode = async file => await (await fetch("/file_browser?path=" + file.currentPath)).json();
    const fetchData = async () => {
        setDirectories([]);
        try {
            const theFiles = await (await fetch("/file_browser")).json();
            const defaultPath = theFiles?.currentPath || "";
            setUserDirectory(theFiles.currentPath);
            await fetchNodes([...filterFiles(theFiles?.children)]);
            let directories = [{ label: "/", value: "" }];
            await fetchUsers(directories, defaultPath);
            theFiles && setDirectories(directories);
            setFetchInProgress(false);
        } catch (e) {
            setFetchInProgress(false);
            console.error({ e });
            growl.error(`Error fetching your files : ${e}`);
        }
        return;
    };

    const sortFiles = files => {
        const theDirectories = files
            .filter(theDir => {
                if (theDir.isDirectory === false) return false;
                if (theDir.children) {
                    if (theDir.children.some(file => file.name[0] !== ".")) return true;
                    return false;
                }
                return true;
            })
            .sort((a, b) => {
                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0;
            });
        const theFiles = files
            .filter(theFile => theFile.isDirectory === false && theFile.name[0] !== ".")
            .sort((a, b) => {
                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0;
            });
        return [...theFiles, ...theDirectories];
    };
    const getComponent = () => {
        if (fetchInProgress || !directories?.length || !userDirectory) return <LoadingIndicator isGlobal={false} />;
        else if (!files?.length) {
            return (
                <FilesEmpty
                    setFileSelectionModalShown={setFileSelectionModalShown}
                    fetchData={fetchData}
                    directories={directories}
                    userDirectory={userDirectory}
                />
            );
        } else {
            return (
                <FilesPresent
                    setFileSelectionModalShown={setFileSelectionModalShown}
                    selectFile={selectFile}
                    files={files}
                    directories={directories}
                    fetchData={fetchData}
                    userDirectory={userDirectory}
                />
            );
        }
    };
    return <Card>{getComponent()}</Card>;
};

export default FilesPage;
