import React from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@material-ui/core";

import { StriimButton, StriimTypography } from "@striim/striim-ui";

import useStyles from "./no-dashboards.styles";

const NoDashboards = ({ openAddDashboardDialog }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.wrapper}>
            <StriimTypography variant="h2">Add your first Dashboard</StriimTypography>
            <Box className={classes.emptyDashboardImage} component="img" src="src/generic/icon/manage-striim/04-Home-Dashboards.svg" />
            <Box component={StriimTypography} variant="subtitle1" className={classes.emptyDashboardMsg}>
                When you create dashboards, you will see them listed here.
            </Box>
            <StriimButton
                onClick={() => {
                    openAddDashboardDialog(true);
                }}
                data-test-id="create-dashboard-button"
            >
                Create Dashboards
            </StriimButton>
        </Grid>
    );
};

NoDashboards.propTypes = {
    openAddDashboardDialog: PropTypes.func
};

export default NoDashboards;
