import propertyTemplateService from "../../../../../../core/services/metaStoreService/property-template-service";
import metaStoreService from "../../../../../../core/services/metaStoreService/meta-store-service";
import api from "../../../../../../core/api/api";
import CONNECTIONPROFILE from "core/services/metaStoreService/metaObjects/connectionProfile";
import growl from "../../../../../../app/components/common/growl";
import { getBodyParams } from "../../../../../generic/oauth-sign-in/utils";
import { modelProviders } from "../../../../ai-insights/guardian/settings/settings-tab";

const AI_ENGINE_ENDPOINTS = Object.values(modelProviders);

interface ConnectionProfileService {
    getNamespaces: () => Promise<ConnectionProfile[]>;
    getConnectionProfiles: () => Promise<ConnectionProfile[]>;
    getConnectionProfile: (name: string) => Promise<ConnectionProfile>;
    createConnectionProfile: (name: string, namespace: string, sendPoint: string, properties: object) => Promise<void>;
    updateConnectionProfile: (
        connectionProfile: ConnectionProfile,
        endPoint: string,
        updatedProperties: any
    ) => Promise<void>;
    getAssociatedApps: (id: string) => Promise<string[]>;
    deleteConnectionProfile: (name: string) => boolean | Promise<any>;
    getCpsByEndpoints: (endpoints: string[]) => Promise<string[]>;
    getConnectionProfileEndpoints: () => Promise<string[]>;
    testNewConnectionProfile: (properties: object, endpoint: string) => Promise<string>;
    testExistingConnectionProfile: (name: string) => Promise<string>;
    testExistingConnectionProfileWithEncryptedProperties: (
        properties: object,
        endpoint: string,
        connectionProfileName: string
    ) => Promise<string>;
    signIn: (
        idp: string,
        url: string,
        authrHostURL: string,
        setShowOAuthInProgressModal: React.Dispatch<React.SetStateAction<boolean>>
    ) => Promise<any>;
    checkCpsAppsAreDeployed: (name: string) => Promise<boolean>;
    handleErrorMessagesForCP: (endpoint: string, uiPayload: string) => Promise<string>;
    getAuthrHostURL: () => Promise<string>;
}

const getId = (name: string) => {
    const parts = name.split(".");
    parts.splice(1, 0, metaStoreService.entities.CONNECTIONPROFILE);
    const id = parts.join(".");
    return id;
};

const connectionProfileService: ConnectionProfileService = {
    getNamespaces: async function() {
        return new Promise(resolve => {
            metaStoreService.fetchIdentifiersByType(metaStoreService.entities.NAMESPACE).then(namespaces => {
                const filteredNamespaces = namespaces.models.filter(ns => ns.get("name").toLowerCase() !== "global");
                resolve(filteredNamespaces);
            });
        });
    },
    getConnectionProfiles: () => {
        return new Promise((resolve, reject) => {
            metaStoreService
                .fetchCollection(metaStoreService.entities.CONNECTIONPROFILE)
                .then(cp => {
                    resolve(cp.models.filter(model => !AI_ENGINE_ENDPOINTS.includes(model.get("endPoint"))));
                })
                .fail(() => {
                    reject();
                });
        });
    },

    getConnectionProfile: (name: string) => {
        const id = getId(name);
        return metaStoreService.findById(id);
    },

    createConnectionProfile: (name, namespace, endPoint, connectionProfile) => {
        let model = new CONNECTIONPROFILE.Model();
        let propertyTemplate = propertyTemplateService.getPropertyTemplateById(`Global.PROPERTYTEMPLATE.${endPoint}`);
        let props = propertyTemplate.toJSON();
        let properties = [];
        props.propertyMap.forEach(propEntry => {
            let value = connectionProfile[propEntry.name];
            if (propEntry.type === "com.webaction.security.Password") {
                let isEncrypted: boolean;
                const type = typeof value;
                let encryptedPropertyName = `${propEntry.name}_encrypted`;
                if (type === "object") {
                    isEncrypted = true;
                } else if (type === "string") {
                    isEncrypted = false;
                }
                properties.push({ name: encryptedPropertyName, value: isEncrypted });
            }
            if (typeof connectionProfile[propEntry.name] === "object" && propEntry.name !== "workspaceEntity") {
                connectionProfile[propEntry.name] = connectionProfile[propEntry.name]?.value;
            }
            const formattedValue =
                typeof connectionProfile[propEntry.name] === "string" &&
                propEntry.type !== "com.webaction.security.Password"
                    ? connectionProfile[propEntry.name]?.trim()
                    : connectionProfile[propEntry.name];
            properties.push({ name: propEntry.name, value: formattedValue });
        });
        model.set("name", name);
        model.set("nsName", namespace);
        model.set("endPoint", endPoint);
        // model.set("accessType", connectionProfile);
        model.set("properties", properties);
        return model.save();
    },

    updateConnectionProfile: async (connectionProfileObj, endPoint, values) => {
        let connectionProfile = await metaStoreService.findById(connectionProfileObj.id);

        let propertyTemplate = propertyTemplateService.getPropertyTemplateById(`Global.PROPERTYTEMPLATE.${endPoint}`);
        let props = propertyTemplate.toJSON();
        let properties = [];
        props.propertyMap.forEach(propEntry => {
            let value = values[propEntry.name];
            if (propEntry.type === "com.webaction.security.Password") {
                let isEncrypted: boolean;
                const type = typeof value;
                let encryptedPropertyName = `${propEntry.name}_encrypted`;
                if (type === "object") {
                    isEncrypted = true;
                } else if (type === "string") {
                    isEncrypted = false;
                }
                properties.push({ name: encryptedPropertyName, value: isEncrypted });
            }
            if (typeof value === "number") {
                value = value.toString();
            }
            value =
                typeof value === "string" && propEntry.type !== "com.webaction.security.Password"
                    ? value?.trim()
                    : value;
            properties.push({ name: propEntry.name, value });
        });

        connectionProfile.set({
            properties
        });

        return new Promise((resolve, reject) => {
            connectionProfile
                .save()
                .then(response => {
                    resolve(response);
                })
                .fail(error => {
                    reject(error);
                });
        });
    },

    getAssociatedApps: async id => {
        return api.getCpApps(id);
    },

    // @ts-ignore
    deleteConnectionProfile: name => {
        return api.dropObject({
            type: metaStoreService.entities.CONNECTIONPROFILE,
            name: name
        });
    },

    getCpsByEndpoints: endpoints => {
        return api.getCpsByEndpoints(endpoints);
    },

    getConnectionProfileEndpoints: () => {
        return new Promise((resolve, reject) => {
            api.getCpEndpoints()
                .then(endpoints => {
                    resolve(endpoints.filter(endpoint => !AI_ENGINE_ENDPOINTS.includes(endpoint)));
                })
                .catch(() => {
                    reject();
                });
        });
    },

    testNewConnectionProfile: (properties, endpoint) => {
        return api.testConnectionProfile(false, properties, endpoint);
    },

    testExistingConnectionProfile: name => {
        return api.testConnectionProfile(true, name);
    },

    testExistingConnectionProfileWithEncryptedProperties: (properties, endpoint, connectionProfileName) => {
        return api.testConnectionProfile(true, properties, endpoint, connectionProfileName, true);
    },

    signIn: async (idp, url, authrHostURL, setShowOAuthInProgressModal) => {
        let request;
        let jsonResponse;
        try {
            request = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: getBodyParams()
            });
            jsonResponse = await request.json();
            if (jsonResponse.AUTHR_ERROR_CODE) {
                return jsonResponse;
            }
        } catch (error) {
            throw new Error(error);
        }

        const _win = window.open(jsonResponse.loginURL, "StriimOauthLauncher");
        //@ts-ignore
        growl.success("Launched OAuth. Please check the next tab", "Success");
        setShowOAuthInProgressModal(true);
        return new Promise((resolve, reject) => {
            let interval = setInterval(async () => {
                let resultData;
                if (_win.closed) {
                    try {
                        resultData = await getData(jsonResponse);
                    } catch (error) {
                        reject(error);
                    } finally {
                        setShowOAuthInProgressModal(false);
                        clearInterval(interval);
                    }
                    resolve(resultData);
                }
            }, 1000);
        });

        async function getData(jsonRes) {
            const URL = `${authrHostURL}/do/${idp}/get`;
            let req = await fetch(URL, {
                headers: {
                    Authorization: `Bearer ${jsonRes.token}`
                }
            });
            let jsonVal = await req.json();
            return jsonVal;
        }
    },

    getAuthrHostURL: async () => {
        return api.getAuthrHostURL();
    },

    checkCpsAppsAreDeployed: async name => {
        return api.checkCpsAppsAreDeployed(name);
    },

    handleErrorMessagesForCP: async (endpoint, uiPayload) => {
        return api.handleErrorMessagesForCP(endpoint, uiPayload);
    }
};

export default connectionProfileService;
