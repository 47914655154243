import React, { useContext, useEffect, useMemo, useState } from "react";
import { StriimDataTable } from "@striim/striim-ui-v2";
import { Grid } from "@mui/material";
import { Columns } from "./sentinel-table.helper";
import SentinelTableHeader from "./sentinel-table-header";
import { SentinelMonitorContext } from "../../sentinel-monitor-context";

const SentinelTable = ({ defaultFilter }) => {
    const { SensitiveDataTable } = useContext(SentinelMonitorContext);
    const [filterState, setFilterState] = useState(defaultFilter);

    useEffect(() => setFilterState(defaultFilter), [defaultFilter]);

    const updateFilterState = (key: string, value: string | boolean) => {
        setFilterState(filterState => ({ ...filterState, [key]: value }));
    };

    const filteredData = useMemo(() => {
        return SensitiveDataTable.filter(data => {
            const { action, importance, apps } = filterState;
            return (
                (!action || data.action === action) &&
                (!importance || data.importance === importance) &&
                (!apps || apps.includes(data.fullAppName))
            );
        });
    }, [filterState]);

    return (
        <Grid p={3}>
            <SentinelTableHeader filterState={filterState} updateFilterState={updateFilterState} />
            <StriimDataTable data={filteredData} columns={Columns} maxHeight={600} />
        </Grid>
    );
};

export default SentinelTable;
