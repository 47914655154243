import App from "app";
import "app/components/dashboard/visualization/charts/chart";
import { getIcons } from "core/services/metadataService/dataVisualizations";

App.module("Chart.Icon", function (Chart, App, Backbone, Marionette, $, _) {
    Chart.View = App.Chart.Default.ChartView.extend({
        className: "chart icon",
        initialize: function () {
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
        },
        setConfiguration: function (config) {
            this.chartConfig = config;
        },
        set_size: function () {
            var current_width = this.$(".icon").outerWidth();
            var current_height = this.$(".icon").outerHeight();
            var component_width = this.$el.innerWidth() - 10; // Padding needed... TODO
            var component_height = this.$el.innerHeight();
            var max_width_scale = component_width / current_width;
            var max_height_scale = component_height / current_height;

            var height = current_height * Math.min(max_width_scale, max_height_scale);
            this.$el.css({
                "line-height": component_height + "px",
            });
            this.$("span").css({
                "font-size": height + "px",
            });
        },
        render: function () {
            if (!this.chartConfig) {
                return;
            }

            // The currently matched expression
            var current = this.model.config.conditions.findWhere({
                matched: true,
            });

            if (!current) {
                return;
            }

            let icon = "";
            const currentIconId = current.icon && current.icon.toLowerCase();
            if (currentIconId !== "none") {
                icon = getIcons().filter((i) => {
                    return i.id.toLowerCase() === currentIconId;
                });
                if (icon.length > 0) {
                    icon = icon[0].icon;
                    icon = `<span class="value_container icon fa ${icon}"></span>`;
                }
            }

            // Get the template for the current icon matched by the expression
            this.$el.addClass(current.icon.toLowerCase());
            this.template = _.template(icon);
            // Render
            Backbone.Marionette.ItemView.prototype.render.apply(this, arguments);

            var _this = this;
            if (this.chartConfig.config.drilldown.show_drilldown) {
                $(".value_container").on("click", function () {
                    _this.trigger("datapoint:click", _this.model.config.last_data);
                });
                $(".value_container").addClass("vis-link");
            }

            // Change the color
            this.$("span").css("color", current.color);

            this.set_size();

            if (!this._inDOM()) {
                this.$("span").hide();
            } else {
                this.$("span").show();
            }
        },
    });
});
