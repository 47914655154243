import { template, ui, regions, on } from "core/decorators";
import FormBuilder from "app/components/common/editor/form-builder";
import Backbone from "backbone";

import { LayoutView } from "marionette";
import UIControl from "app/components/common/editor/control/ui-control";
import MetaObjectDataSource from "../../../../common/editor/control/select/metaobject-datasource";
import utils from "../../../../../../core/utils";
import predefinedTimeOptions from "../../../eventsLog/predefinedTimeOptions";
import DateRange from "../../../../common/editor/control/date-range-picker/custom-date-range";
import api from "core/api/api";
import NProgress from "nprogress";
import growl from "app/components/common/growl";
import dateLib from "core/utils/date-utils";
import { tooltipStriimlineHighcharts } from "../../../../../styles/materialize/components-striimline/tooltip.export";

NProgress.configure({
    minimum: 0.25,
    trickleRate: 0.1,
    trickleSpeed: 800
});

@template(`
    <div>
       <div class="row">
          <div class="col s3">
             <div>
                <h4>
                    <div> Select Time Range</div>
                </h4>
                <div class="date-picker" > </div>
                <div class="form" > </div>
             </div>
             <div class="message error p-8px">
                Please fill all the required fields before running LEE report.
             </div>
             <div class="row">
                <input type="button" id="processReport" class="btn waves-effect waves-light" style="margin-left: 8px;" value="Run Report">
             </div>
          </div>
          <div class="col s9">
             <div class="col s12 graphs" id="lee-report-chart"> <div style="text-align: center; margin-top: 40%;">Please select the time range to run the report</div></div>
          </div>
       </div>
    </div>
`)
@regions({
    form: ".form",
    datePicker: ".date-picker"
})
@ui({
    errorBox: ".error.message"
})
export class LEEReportView extends LayoutView {
    onRender() {
        this.model = new Backbone.Model({ selectInterval: "1" });
        this.form = new FormBuilder({
            model: this.model,
            autoSave: true
        });

        const rollupIntervalOptions = [
            {
                id: "1",
                text: "1 minute"
            },
            {
                id: "15",
                text: "15 minutes"
            },
            {
                id: "30",
                text: "30 minutes"
            },
            {
                id: "60",
                text: "1 hour"
            }
        ];
        let sources = this.options.sourcesList;
        sources = sources.map(source => {
            let id = source.split(".");
            id.push("SOURCE");
            let temp = id[1];
            id[1] = id[2];
            id[2] = temp;
            return id.join(".");
        });
        let inputDataSource = new MetaObjectDataSource([utils.ENTITIES.SOURCE], null, true, sources);

        this.form.addControl(
            UIControl.Select(rollupIntervalOptions, {
                allowClear: false,
                hideSearch: true
            }).create(),
            "selectInterval"
        );
        this.form.addControl(
            UIControl.Select(inputDataSource, {
                //addNew: true//,
                //type: utils.ENTITIES.CACHE
            }).create(),
            "selectSource"
        );

        this.getRegion("form").show(this.form.create());
        this.dateRange = DateRange(predefinedTimeOptions).create();
        this.getRegion("datePicker").show(this.dateRange);
        this.model.on("change", () => {
            this.ui.errorBox.hide();
        });
    }

    @on("click #processReport")
    async runReport() {
        NProgress.configure({
            parent: ".heading"
        });
        this.ui.errorBox.hide();
        let dateRange = this.dateRange.getViewValue();
        let startDate = dateRange.from.valueOf();
        let endDate = dateRange.to.valueOf();
        let sourceName = this.model.get("selectSource");
        let intervalString = this.model.get("selectInterval");
        if (!startDate || !endDate || !sourceName || !intervalString) {
            this.ui.errorBox.show();
            return;
        }
        let interval = parseInt(intervalString) * 60 * 1000;
        NProgress.start();
        try {
            let response = await api.getLeeStatsReport(
                sourceName,
                this.options.targetName,
                startDate,
                endDate,
                interval
            );
            let responseJSON = JSON.parse(response);
            console.log(responseJSON);
            this.renderChart(responseJSON);
        } catch (e) {
            growl.error(e.message);
        }
        NProgress.done();
    }

    renderChart(responseJSON) {
        let min = [];
        let max = [];
        let avg = [];
        _.each(responseJSON, entry => {
            let time = new Date(entry.timestamp).getTime();
            if (!time) return;
            min.push([time, parseFloat(entry.minLEE)]);
            avg.push([time, parseFloat(entry.avgLEE)]);
            max.push([time, parseFloat(entry.maxLEE)]);
        });
        Highcharts.setOptions({
            global: {
                useUTC: false
            }
        });
        this.chart = new Highcharts.Chart({
            chart: {
                renderTo: "lee-report-chart",
                animation: false
            },
            title: {
                text: "LEE Report Graph"
            },
            credits: {
                enabled: false
            },
            xAxis: {
                labels: {
                    enabled: true
                },
                type: "datetime",
                startOnTick: true,
                endOnTick: true
            },
            yAxis: {
                maxPadding: 0,
                minPadding: 0,
                gridLineWidth: 1,
                endOnTick: false,
                min: 0,
                labels: {
                    enabled: true
                },
                title: {
                    text: "Lag end-to-end (seconds)"
                }
            },
            legend: {
                enabled: true
            },
            tooltip: {
                enabled: true,
                pointFormat: "",
                headerFormat: "",
                pointFormatter: function() {
                    return `${this.series.name}: ${this.y}s <br /> at ${dateLib(this.x).format("h:mm:ss a ")}`;
                },
                ...tooltipStriimlineHighcharts
            },
            plotOptions: {
                series: {
                    enableMouseTracking: true,
                    lineWidth: 4,
                    shadow: false,
                    states: {
                        hover: {
                            lineWidth: 4
                        }
                    },
                    marker: {
                        enabled: false,
                        symbol: "circle",
                        radius: 2,
                        states: {
                            hover: {
                                radius: 4
                            }
                        }
                    }
                }
            },
            series: [
                {
                    type: "line",
                    color: "#55C2FF",
                    name: "Average",
                    data: avg
                },
                {
                    type: "line",
                    color: "#69C9B9",
                    name: "Min",
                    data: min
                },
                {
                    type: "line",
                    color: "#E6A6C7",
                    name: "Max",
                    data: max
                }
            ]
        });

        $(".lee-report-view").scroll();
    }
}
