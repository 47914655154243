import _ from "underscore";

function appendTargetsFromNode(nodes, array, ids, node) {
    // summary:
    //      Recursively traverses the node parents and produces a list of node descendants
    // returns:
    //      void
    if (!node || !node.targets) {
        return;
    }

    node.targets.forEach(function (targetId) {
        // skip duplicates
        if (array.indexOf(targetId) === -1) {
            array.push(targetId);
            appendTargetsFromNode(
                nodes,
                array,
                ids,
                _.findWhere(nodes, {
                    id: targetId,
                })
            );
        }
    });
}

export default {
    hasCycles: function (nodes) {
        // summary:
        //      Finds cycles in a graph
        // returns:
        //      Boolean
        var nodesIds = _.pluck(nodes, "id");

        // map the list of graph nodes into a list of nodes together with all descendants
        var nodesWithAllTargets = nodes.map(function (node) {
            var nodeTargets = [];
            appendTargetsFromNode(nodes, nodeTargets, nodesIds, node);
            return {
                id: node.id,
                targets: nodeTargets,
            };
        });

        // is there at least one target that has a reference to back to the origin?
        return nodesWithAllTargets.some(function (node) {
            // get all targets ids
            var targetIds = node.targets.filter(function (targetId) {
                return nodesIds.indexOf(targetId) !== -1;
            });

            return targetIds.some(function (targetId) {
                var item = _.findWhere(nodes, {
                    id: targetId,
                });
                return item.targets.some(function (targetId) {
                    return targetId === node.id;
                });
            });
        });
    },
};
