import theme from "../../../../../../../../theme";
import {
    tooltipStriimlineMakeStyles
} from "../../../../../../../../../app/styles/materialize/components-striimline/tooltip.export";

const tooltipStyles = {
    itemStyle: {
        fontFamily: theme.typography.fontFamily,
        fontSize: "12px",
        fontWeight: 400,
        color: "#fff",
    },
    contentStyle: {
        fontFamily: theme.typography.fontFamily,
        fontSize: "12px",
        fontWeight: 400,

        borderRadius: 4,
        border: "none",
        ...tooltipStriimlineMakeStyles,
        padding: "0 8px",
    },
    labelStyle: {
        fontFamily: theme.typography.fontFamily,
        fontSize: "14px",
        fontWeight: 500,

        whiteSpace: "normal",
        wordBreak: "break-all",
        color: "#fff"
    },
    //cursor: { fill: "rgba(206, 206, 206, 0.2)" },
    wrapperStyle: {}
};

export { tooltipStyles };
