import _ from "underscore";
import { contextStore } from "../../stores";
import QueryStringParser from "../../../app/components/dashboard/url/query-string-parser";
import { useQuery } from "./use-query";

export default function useDashboardOptions(id) {
    const query = useQuery();

    document.title = "Dashboard " + id;
    contextStore.navBarStore.setPageTitle("Dashboard");

    var options = {};
    var queryStringParser = new QueryStringParser();
    var queryParams = queryStringParser.parse(query.toString());
    if (queryParams && queryParams.pageparams) {
        _.extend(options, {
            pageparams: JSON.parse(queryParams.pageparams)
        });
    }

    return options;
}
