import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import InfoIcon from "app/images/striimline/info-icon.svg";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import {
    Avatar,
    Box,
    Card,
    Container,
    Grid,
    InputAdornment,
    LinearProgress,
    ListItemAvatar,
    ListItemText,
    Paper,
    SvgIcon
} from "@mui/material";
import {
    StriimButton,
    StriimChip,
    StriimDatePicker,
    StriimInputField,
    StriimTypography,
    StriimList,
    StriimListItem
} from "@striim/striim-ui";
import utils from "core/utils";
import xss from "xss";
import styles from "./message-log.styles";
import dateLib from "../../../core/utils/date-utils";
import { SOURCE_MESSAGE_LOG } from "./message-log.store";

const MessageEntry = React.memo(({ entry }) => {
    const getSimpleName = function(fqn) {
        const nsName = utils.getNamespace(fqn);
        const name = utils.getName(fqn);
        return `${nsName}.${name}`;
    };

    const getType = function(fqn) {
        const type = utils.getType(fqn);
        return utils.capitalize(type);
    };

    return (
        <StriimListItem key={entry.id} dense data-test-id={"message-log-entry"} sx={styles.messageLogEntry}>
            <ListItemAvatar sx={styles.avatar}>
                <Avatar sx={{ width: 32, height: 32, backgroundColor: "transparent" }}>
                    {entry.severity === "info" && (
                        <SvgIcon component={InfoIcon} sx={styles.iconInfo} data-test-id={"message-log-entry-icon"} />
                    )}
                    {entry.severity === "warning" && (
                        <ReportProblemOutlinedIcon sx={styles.iconWarning} data-test-id={"message-log-entry-icon"} />
                    )}
                    {entry.severity === "error" && (
                        <ErrorOutlineIcon sx={styles.iconError} data-test-id={"message-log-entry-icon"} />
                    )}
                    {entry.severity === "success" && (
                        <CheckCircleOutlinedIcon sx={styles.iconSuccess} data-test-id={"message-log-entry-icon"} />
                    )}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                sx={styles.listItemContainer}
                primary={
                    <Grid container>
                        <Grid flexGrow="1">
                            <StriimTypography
                                variant={"body3"}
                                sx={styles.messageEntryTitle}
                                data-test-id={"message-log-entry-title"}
                            >
                                {entry.title.toUpperCase()}
                            </StriimTypography>
                        </Grid>
                        <Grid display="flex">
                            <Box>
                                {entry.source && entry.source === SOURCE_MESSAGE_LOG ? (
                                    <StriimChip
                                        variant="default"
                                        label={entry.source}
                                        title={
                                            "This message was received from the Message Log and was sent by the component " +
                                            entry.componentName
                                        }
                                        data-test-id={"message-log-entry-chip"}
                                        type="tag-chip"
                                        hideAvatar
                                        sx={styles.tagChip}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Box>
                            <Box>
                                <StriimTypography variant={"caption3"} align={"right"} sx={styles.dateTimeColor}>
                                    {dateLib(entry.date).format("D.MM.YY h:mm:ss")}
                                </StriimTypography>
                            </Box>
                        </Grid>
                    </Grid>
                }
                secondary={
                    <Box display="flex" flexDirection="column" gap={0.5} sx={styles.messageEntryContainersSecondary}>
                        {entry.message && (
                            <div>
                                <StriimTypography
                                    variant={"body4"}
                                    data-test-id={"message-log-entry-desc"}
                                    dangerouslySetInnerHTML={{ __html: xss(entry.message) }}
                                    sx={styles.entryMessage}
                                ></StriimTypography>
                            </div>
                        )}
                        {entry.componentName && (
                            <StriimTypography
                                variant={"caption3"}
                                data-test-id={"message-log-entry-component-name"}
                                sx={styles.componentName}
                            >
                                Notified by {getType(entry.componentName)}: {getSimpleName(entry.componentName)}
                            </StriimTypography>
                        )}
                    </Box>
                }
            />
        </StriimListItem>
    );
});
MessageEntry.displayName = "MessageEntry";

const MessageLogContents = observer(({ store }) => {
    const [keywordFilter, setKeywordFilter] = useState(store.keyWord);
    const [component, setComponent] = useState(store.component);
    const [startTime, setStartTime] = useState(store.startTime);
    const [endTime, setEndTime] = useState(store.endTime);
    const [endTimeValidationMessage, setEndTimeValidationMessage] = useState(null);
    const [severityFilter, setSeverityFilter] = useState(
        store.severity
            ? store.severity.map(val => {
                  return { value: val, label: val };
              })
            : null
    );

    const now = dateLib();
    const lastWeek = now.subtract(7, "days");
    const severityOptions = [
        { value: "info", label: "info" },
        { value: "success", label: "success" },
        { value: "warning", label: "warning" },
        { value: "error", label: "error" }
    ];

    const setSeverityFilters = function(val) {
        if (val === null) {
            store.setSeverity(null);
        } else {
            let filters = [];
            val.forEach(entry => {
                filters.push(entry.value);
            });
            store.setSeverity(filters);
        }
    };

    const callAPI = () => {
        store.setKeyWord(keywordFilter);
        setSeverityFilters(severityFilter);
        store.setComponent(component);
        store.setStartTime(startTime);
        store.setEndTime(endTime);
        store.fetchEvents();
    };
    const doSearch = function() {
        store.isAPILoading = true;
        store.setRemoteEvents([]);
        callAPI();
    };

    useEffect(() => {
        store.isAPILoading = true;
        store.setRemoteEvents([]);
        callAPI();
    }, []);

    useEffect(() => {
        setComponent(store.component);
    }, [store.component]);

    useEffect(() => {
        if (startTime?.getTime() > endTime?.getTime()) {
            setEndTimeValidationMessage("Until Time cannot be less than From Time");
        } else {
            setEndTimeValidationMessage(null);
        }
    }, [startTime, endTime]);

    const FormatOptionLabel = ({ value }) => (
        <div>
            <StriimTypography variant={"subtitle2"}>
                {" "}
                {utils.getNamespace(value)}.{utils.getName(value)}
            </StriimTypography>
        </div>
    );

    return (
        <React.Fragment>
            <Paper sx={styles.paddingPaper}>
                <Grid container={true}>
                    <Grid xs={3} item={true} sx={styles.filtersMidPadding} data-test-id={"message-log-search-keyword"}>
                        <StriimInputField
                            label="Search by keyword"
                            placeholder="Search"
                            value={keywordFilter}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlinedIcon opacity={0.45} />
                                    </InputAdornment>
                                )
                            }}
                            onChange={val => {
                                setKeywordFilter(val);
                            }}
                            disabled={store.isAPILoading}
                        />
                    </Grid>
                    <Grid xs={2} item={true} sx={styles.filtersMidPadding} data-test-id={"message-log-search-severity"}>
                        <StriimInputField
                            SelectProps={{ options: severityOptions, isMulti: true }}
                            select
                            label="Notification type"
                            placeholder="Select.."
                            value={severityFilter}
                            onChange={val => {
                                setSeverityFilter(val);
                            }}
                            disabled={store.isAPILoading}
                        />
                    </Grid>
                    <Grid
                        xs={2}
                        item={true}
                        sx={styles.filtersMidPadding}
                        data-test-id={"message-log-search-component"}
                    >
                        <StriimInputField
                            SelectProps={{
                                options: [...store.componentsAvailable].map(component => {
                                    return { value: component, label: component };
                                }),
                                formatOptionLabel: FormatOptionLabel,
                                dataTestId: "component-select"
                            }}
                            value={component ? { value: component, label: component } : null}
                            select
                            label="Search by component"
                            placeholder="Select.."
                            onChange={val => {
                                setComponent(val?.value);
                            }}
                            disabled={store.isAPILoading}
                        />
                    </Grid>
                    <Grid xs={4} item={true} sx={styles.filtersMidPadding}>
                        <Grid container={true}>
                            <Grid item xs={6} sx={styles.dateTimeMargin}>
                                <StriimDatePicker
                                    label={"Start"}
                                    onChange={d => {
                                        setStartTime(d);
                                    }}
                                    initialValue={startTime}
                                    minDate={lastWeek.toDate()}
                                    disabled={store.isAPILoading}
                                    data-test-id={"message-log-search-from"}
                                    placeholder={"Click to select From Date"}
                                    clearableInput
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <StriimDatePicker
                                    label={"End"}
                                    onChange={d => {
                                        setEndTime(d);
                                    }}
                                    initialValue={endTime}
                                    minDate={lastWeek.toDate()}
                                    disabled={store.isAPILoading}
                                    data-test-id={"message-log-search-until"}
                                    placeholder={"Click to select Until Date"}
                                    error={endTimeValidationMessage && endTimeValidationMessage.length > 0}
                                    helperText={endTimeValidationMessage}
                                    clearableInput
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={1} item={true} sx={styles.buttonPadding}>
                        <StriimButton
                            fullWidth={true}
                            onClick={() => {
                                doSearch();
                            }}
                            sx={styles.bigButton}
                            data-test-id={"message-log-search-submit"}
                            disabled={endTimeValidationMessage || store.isAPILoading}
                        >
                            Search
                        </StriimButton>
                    </Grid>
                </Grid>
                {store.isFiltered ? (
                    <div>
                        <StriimTypography variant={"h5"} sx={styles.filtersMidPadding}>
                            Filters Applied:
                        </StriimTypography>
                        <StriimTypography variant={"subtitle2"} data-test-id={"message-log-applied-filters"}>
                            {store.keyWord ? (
                                <StriimChip variant="select" label={"Keyword: " + store.keyWord} sx={styles.chips} />
                            ) : null}
                            {store.component ? (
                                <StriimChip
                                    variant="select"
                                    label={"Component: " + store.component}
                                    sx={styles.chips}
                                />
                            ) : null}
                            {store.severity ? (
                                <StriimChip
                                    variant="select"
                                    label={"Notification type: " + store.severity.join(",")}
                                    sx={styles.chips}
                                />
                            ) : null}
                            {store.startTime ? (
                                <StriimChip
                                    variant="select"
                                    label={"Start: " + store.startTime.toString()}
                                    sx={styles.chips}
                                />
                            ) : null}
                            {store.endTime ? (
                                <StriimChip
                                    variant="select"
                                    label={"End: " + store.endTime.toString()}
                                    sx={styles.chips}
                                />
                            ) : null}
                        </StriimTypography>
                    </div>
                ) : (
                    <></>
                )}
            </Paper>
            <LinearProgress hidden={!store.isAPILoading} />
            <StriimList hidden={store.isAPILoading}>
                {store.filteredResults.length === 0 && !store.isAPILoading && (
                    <Card elevation={0}>
                        <StriimTypography
                            variant={"subtitle2"}
                            align={"center"}
                            data-test-id={"message-log-search-no-results"}
                            sx={styles.fontSize}
                        >
                            No results were found for this search
                        </StriimTypography>
                    </Card>
                )}
                {store.filteredResults.map(entry => {
                    return <MessageEntry entry={entry} key={entry.id} />;
                })}
            </StriimList>
            {store._paginationNextTime && !store.isAPILoading && (
                <Container sx={styles.loadMoreButton}>
                    <StriimButton
                        variant={"primaryText"}
                        onClick={() => {
                            store.fetchEvents(true);
                        }}
                    >
                        {" "}
                        Load More
                    </StriimButton>
                </Container>
            )}
        </React.Fragment>
    );
});
MessageLogContents.propTypes = {
    store: PropTypes.object.isRequired
};

export default MessageLogContents;
