export default {
    process: function (newNodeType, nodeModel, parentNode) {
        if (newNodeType === "TARGET" && parentNode) {
            nodeModel.metaObject.autoProperties = nodeModel.metaObject.autoProperties || {};

            if (parentNode.metaObject.type === "STREAM") {
                nodeModel.metaObject.autoProperties.inputStream = parentNode.metaObject.id;
            }
        }
    },
};
