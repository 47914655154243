import _ from "underscore";
import Marionette from "marionette";
import BaseLayout from "app/components/common/wizardmaker/baseLayout";
import tabTemplate from "./templates/tabLayout.html";

var TabView = Marionette.ItemView.extend({
    template: _.template("<div>{{title}}</div>"),
    className: "tab-name",
    initialize: function (options) {
        this.vent = options.vent;
        if (this.model.get("isActive")) {
            //make the first step active
            this.$el.addClass("active-step");
        }

        this.listenTo(this.model, "change", function () {
            //update the views as the steps are completed
            this.$el.toggleClass("active-step");
        });
    },
});

var TabCollectionView = Marionette.CollectionView.extend({
    childView: TabView,
    childViewOptions: function (model, index) {
        return {
            vent: this.vent,
        };
    },
    initialize: function (options) {
        this.currentStepIndex = options.currentStepIndex;
        this.vent = options.vent;
    },
});

var tabLayout = BaseLayout.extend({
    type: "tab",
    template: _.template(tabTemplate),
    regions: {
        tabs: ".tabs",
        tabContent: ".tab-content",
    },
    getChildView: function (item) {
        return item.get("view");
    },
    onRender: function () {
        var _this = this;
        var tabsView = new TabCollectionView({
            collection: _this.collection,
            currentStepIndex: _this.currentStepIndex,
            vent: _this.vent,
        });
        var tabContentView = this.getCurrentView(this.currentStepIndex);
        this.getRegion("tabs").show(tabsView);
        this.getRegion("tabContent").show(tabContentView);
    },
    doNextHandler: function () {
        this.currentStepIndex = this.collection.next();
        var tabContentView = this.getCurrentView(this.currentStepIndex);
        this.getRegion("tabContent").show(tabContentView);
    },
    doPrevHandler: function () {
        this.currentStepIndex = this.collection.prev();
        var tabContentView = this.getCurrentView(this.currentStepIndex);
        this.getRegion("tabContent").show(tabContentView);
    },
});

export default tabLayout;
