import React, { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Grid, Divider } from "@mui/material";
import _ from "underscore";

import {
    AllSentinelsSDIDrillDownEvents,
    AppSDIDrillDownFindings,
    SDIDrillDownFindingsChartData,
    SensitiveDataOccurrences,
    SensitiveDataOccurrencesChartData
} from "../../../guardian/sentinel-monitor/service/sentinel-monitor.types";
import SensitiveDataOccurrencesChart from "../timeline-view-chart/sensitive-data-occurrences-chart";
import { TABS } from "../panel-header-controls/panel-header-controls.stories";
import SentinelOrAppsTableSDI from "../sentinel-table/sentinel-table-sdi";
import { SelectedSensitiveDataIdentifier } from "../../sentinel-live-monitor";
import SentinelTableBreakdown from "../sentinel-table/sentinel-table-breakdown";
import StriimCircularProgress from "../../../../apps/pages/manage-striim/connection-profiles/components/circular-progress/circular-progress";
import { getTimestampsAsOneHourWindow } from "../../utils";

interface OccurrencesContentProps {
    selectedSentinel: { label: string; value: string } | null;
    setSelectedSentinel: React.Dispatch<SetStateAction<{ label: string; value: string }>>;
    sensitiveDataOccurrences: SensitiveDataOccurrences;
    chartData: SensitiveDataOccurrencesChartData | SDIDrillDownFindingsChartData | null;
    selectedTab: string;
    selectedDateRange: { from: number; to: number } | null;
    setSelectedDateRange: React.Dispatch<SetStateAction<{ from: number; to: number } | null>>;
    selectedSDI: SelectedSensitiveDataIdentifier | null;
    setSelectedSDI: React.Dispatch<SetStateAction<SelectedSensitiveDataIdentifier | null>>;
    setCurrentTabIndex: React.Dispatch<SetStateAction<number>>;
    sdiDrilldownFindings: AppSDIDrillDownFindings;
    showProgress: boolean;
    showChartLoader: boolean;
    hasAppStopped?: boolean;
    onLiveViewClick: Function;
}

const OccurrencesContent: React.FC<OccurrencesContentProps> = ({
    selectedSentinel,
    setSelectedSentinel,
    sensitiveDataOccurrences,
    chartData,
    selectedTab,
    selectedDateRange,
    setSelectedDateRange,
    selectedSDI,
    setSelectedSDI,
    setCurrentTabIndex,
    sdiDrilldownFindings,
    showProgress,
    showChartLoader,
    hasAppStopped = false,
    legendData,
    onLiveViewClick
}) => {
    const [isDiscoveryDisabled, setIsDiscoveryDisabled] = useState<boolean>(false);
    const [dataUnavailableTimestamp, setDataUnavailableTimestamp] = useState<number | null>();
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [SDITableData, setSDITableData] = useState([]);
    const chartRef = useRef(null);

    useEffect(() => {
        if (!sensitiveDataOccurrences && !sdiDrilldownFindings) return;
        const tableData = sensitiveDataOccurrences
            ? sensitiveDataOccurrences?.sensitiveDataIdentifierOccurrences
            : prepareDrillDownSentinelData(sdiDrilldownFindings?.sentinels);

        setSDITableData(tableData || []);
        setFilteredTableData(tableData || []);
    }, [sensitiveDataOccurrences, sdiDrilldownFindings]);

    const loadData = useCallback(
        _.debounce((event, dateRange) => {
            const updatedDateRange = getTimestampsAsOneHourWindow(dateRange?.from, dateRange?.to);
            setSelectedDateRange(updatedDateRange);
        }, 300),
        []
    );

    const prepareDrillDownSentinelData = sentinels => {
        const disabledSentinel = {
            name: "",
            processedEvents: "-",
            dataTagged: "-",
            disabled: true,
            sensitiveData: 0,
            nonSensitiveData: 0,
            eventsWithSDI: {
                total: 0,
                eventsWithSensitiveData: 0,
                eventsWithoutSensitiveData: 0
            }
        };
        const sentinelsArray = [];

        for (const key in sentinels) {
            if (sentinels.hasOwnProperty(key)) {
                const currentSentinel = sentinels[key];
                const sentinelDisabled = !currentSentinel.discoveryEnabled;
                const sentinel = sentinelDisabled
                    ? { ...disabledSentinel, name: key }
                    : { ...currentSentinel, name: key, ...currentSentinel.actions };

                sentinelsArray.push(sentinel);
            }
        }

        return sentinelsArray;
    };

    const isIndividualSentinelSelected = !(!selectedSentinel || selectedSentinel.value == "All");
    const showDivider =
        (!selectedSDI && !isIndividualSentinelSelected) ||
        (isIndividualSentinelSelected && !selectedSDI) ||
        (!isIndividualSentinelSelected && selectedSDI);
    const timeRange = selectedDateRange || selectedTab === TABS[0] ? "1 HOUR" : "24 HOURS";

    return (
        <div>
            <div style={{ position: "relative" }}>
                {showChartLoader ? (
                    <Grid container justifyContent={"center"} alignItems={"center"} minHeight={325}>
                        <StriimCircularProgress size={24} />
                    </Grid>
                ) : chartData ? (
                    <SensitiveDataOccurrencesChart
                        chartRef={chartRef}
                        chartData={chartData}
                        onScroll={loadData}
                        selectedDateRange={selectedDateRange}
                        isDiscoveryDisabled={isDiscoveryDisabled}
                        hasAppStopped={hasAppStopped}
                        dataUnavailableTimestamp={dataUnavailableTimestamp}
                        hideNavigator={selectedTab === TABS[1]}
                        legendData={legendData}
                        onLiveViewClick={onLiveViewClick}
                    />
                ) : null}
            </div>

            {showDivider && (
                <Divider
                    sx={{
                        width: "calc(100% + 48px)",
                        marginLeft: -3,
                        marginTop: 3,
                        marginBottom: 3
                    }}
                />
            )}

            <Grid container width="100%" mt={4}>
                {!selectedSDI && !isIndividualSentinelSelected ? (
                    <SentinelOrAppsTableSDI
                        title="Sensitive Data Identifiers Detected"
                        tableData={SDITableData}
                        filteredTableData={filteredTableData}
                        setFilteredTableData={setFilteredTableData}
                        setSelectedSDI={setSelectedSDI}
                        setCurrentTabIndex={setCurrentTabIndex}
                        timeRange={timeRange}
                        isSentinelLevel={true}
                        showProgress={showProgress}
                        setSelectedDateRange={setSelectedDateRange}
                    />
                ) : isIndividualSentinelSelected && !selectedSDI ? (
                    <SentinelOrAppsTableSDI
                        title="Sensitive Data Identifiers Detected"
                        tableData={SDITableData}
                        filteredTableData={filteredTableData}
                        setFilteredTableData={setFilteredTableData}
                        setSelectedSDI={setSelectedSDI}
                        setCurrentTabIndex={setCurrentTabIndex}
                        timeRange={timeRange}
                        isSentinelLevel={true}
                        hideLastColumn={true}
                        showProgress={showProgress}
                        setSelectedDateRange={setSelectedDateRange}
                    />
                ) : !isIndividualSentinelSelected && selectedSDI ? (
                    <SentinelTableBreakdown
                        title="Breakdown by Sentinels"
                        tableData={SDITableData}
                        filteredTableData={filteredTableData}
                        setFilteredTableData={setFilteredTableData}
                        timeRange={timeRange}
                        showProgress={showProgress}
                        setSelectedSentinel={setSelectedSentinel}
                    />
                ) : null}
            </Grid>
        </div>
    );
};

export default OccurrencesContent;
