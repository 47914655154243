import App from "app";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import "./editor";

App.module("Dashboard.VisEditor", function(VisEditor, App, Backbone, Marionette, $, _) {
    VisEditor[dataVisualizations.EDITOR.BOOLEAN] = VisEditor.Editor.LayoutView.extend({
        templateHTML:
            '<div class="switch"><label class="toggle"><input type="checkbox" class="filled-in" name="{{ editor.attribute }}" /><span class="lever"></span></label></div>',
        onRender: function() {
            var _this = this;
            var key = this.model.editor.attribute;
            if (this.model.config[key]) {
                this.$("input").prop("checked", true);
            }

            this.$("input").on("change", function() {
                _this.model.config.set(key, $(this).prop("checked"));
            });
        }
    });
});
