import _ from "underscore";
import metaStoreService from "../../../../core/services/metaStoreService/meta-store-service";
import localStorageUtils from "../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/local-storage-utils";

const HIDE_URL_SUFFIX = "-hideSourceURL";

export const saveHideURLStatus = (AppName: string, status: boolean) => {
    localStorageUtils.setItem(AppName + HIDE_URL_SUFFIX, status);
};

export const fetchHideURLStatus = (AppName: string) => {
    return localStorageUtils.getItem(AppName + HIDE_URL_SUFFIX);
};

export const getPropertyVariable = variable => "$" + variable;

export const setCDCSourcePropertyVariables = async (cdcSourceModel, sourceEncryptedURL) => {
    if (sourceEncryptedURL) {
        if (sourceEncryptedURL?.encryptedSSLConfig) {
            const SSLConfigVariable = getPropertyVariable(sourceEncryptedURL.encryptedSSLConfig);
            cdcSourceModel.adapter.properties.SSLConfig = SSLConfigVariable;
        }
        if (sourceEncryptedURL?.encryptedConnectionURL) {
            const ConnectionURLVariable = getPropertyVariable(sourceEncryptedURL.encryptedConnectionURL);
            cdcSourceModel.adapter.properties.ConnectionURL = ConnectionURLVariable;
        }
    }
};

export const setILSourcePropertyVariables = (sourceModel: Object, sourceEncryptedURL: Object): void => {
    if (sourceEncryptedURL) {
        if (sourceEncryptedURL?.encryptedSSLConfig) {
            const SSLConfigVariable = getPropertyVariable(sourceEncryptedURL.encryptedSSLConfig);
            sourceModel.adapter.properties.SSLConfig = SSLConfigVariable;
        }
        if (sourceEncryptedURL?.encryptedConnectionURL) {
            const ConnectionURLVariable = getPropertyVariable(sourceEncryptedURL.encryptedConnectionURL);
            sourceModel.adapter.properties.ConnectionURL = ConnectionURLVariable;
        }
    }
};
