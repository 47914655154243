import React from "react";
import { Grid, LinearProgress } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import { styles } from "./progress-bar.styles";

const ProgressBar = ({ completed, total, status }) => {
    var successPercent = completed === 0 ? 0 : (completed * 100) / total;
    return status === "error" ? (
        <StriimTypography variant="body4" color="critical.500">
            n/a
        </StriimTypography>
    ) : (
        <Grid container justifyContent="left" gap={2} alignItems="center" padding="10px 10px 10px 0">
            <LinearProgress variant="determinate" value={successPercent} sx={styles.bar} />
            <StriimTypography variant="body4" color="greyscale.700">{`${completed}/${total}`}</StriimTypography>
        </Grid>
    );
};

export default ProgressBar;
