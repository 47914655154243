import localStorageUtils from "app/components/appwizard/component-wizards/wizards/source/database-reader/local-storage-utils";
import { sourceFormConfig } from "app/components/appwizard/component-wizards/wizards/source/database-reader/configs/source-form-info.js";

import { readerData, writerData } from "./review.consts";
import { validationUtils } from "../../utils";
import { PRESERVED_NAMES } from "core/constants";

const removeQuotes = string => string?.replace(/^"|"$/g, "");

export const getSourceTables = (adapterProps, isObject = false) => {
    let Schemas = new Set();
    let Tables = [];

    if (isObject) {
        if (adapterProps.properties.ZendeskObjects)
            return { Schemas: adapterProps.properties.ZendeskObjects.split(";"), Tables: null };
        if (adapterProps.properties.sObjects)
            return { Schemas: adapterProps.properties.sObjects.split(";"), Tables: null };
        else
            return {
                Schemas: adapterProps.properties.Tables
                    ? adapterProps.properties.Tables.split(";")
                    : adapterProps.properties.tables.split(";"),
                Tables: null
            };
    }

    if (
        adapterProps &&
        adapterProps.properties &&
        (adapterProps.properties.Tables || adapterProps.properties.collections || adapterProps.properties.Containers)
    ) {
        const tables = adapterProps.properties.Tables
            ? adapterProps.properties.Tables.split(";")
            : adapterProps.properties.collections
            ? adapterProps.properties.collections.split(",")
            : adapterProps.properties.Containers.split(",");

        if (tables) {
            tables.forEach(table => {
                let chunks = table.split(".");
                if (chunks.length === 3) {
                    chunks.shift();
                }
                const schemaName = removeQuotes(chunks[0]);
                const tableName = removeQuotes(chunks[1]);
                Schemas.add(schemaName);
                Tables[schemaName] = Tables[schemaName] || [];
                Tables[schemaName].push(tableName);
            });
        }
    }
    return { Schemas: Array.from(Schemas), Tables: Tables };
};

const getPattern = database => {
    switch (database) {
        case "Oracle":
        case "OracleReader":
        case "Postgres":
        case "PostgreSQLReader":
            return /\/\/([^:/]+):(\d+)\/(\w+)/;
        case "SQLServer":
        case "MSSqlReader":
            return /\/\/(.*?):(\d+);DatabaseName=(.*)/;
        case "MySQL":
        case "MysqlReader":
        case "MariaDB":
        case "MariaDBReader":
            return /\/\/(.*?):(\d+)/;
    }
};

// Computes Hostname, Port, Database from ConnectionURL
export const parseConnectionURL = (url, database) => {
    const pattern = getPattern(database);
    const match = url?.match(pattern);
    return { Hostname: `${match?.[1]}:${match?.[2]}`, Database: match?.[3] };
};

export const hasDatabase = database => {
    switch (database) {
        case "MySQL":
        case "MysqlReader":
        case "MariaDB":
        case "MariaDBReader":
            return false;
        default:
            return true;
    }
};

const getReviewData = (dataFormat, database, databaseDetails) =>
    dataFormat[database.toUpperCase()]
        ? dataFormat[database.toUpperCase()]
              .filter(({ value, key }) => value || databaseDetails?.[key])
              .map(({ name, key, value }) => ({
                  name: name,
                  value: value || databaseDetails?.[key]
              }))
        : [];

export const getSourceDetails = (source, sourceDetails, sourceConnectionProfileData) => {
    const resource = {
        ApiToken: "Api Token",
        ZendeskCreds: "Zendesk Creds"
    };

    const getLabel = name => resource?.[name] || name;

    sourceDetails = { ...sourceDetails, ...sourceConnectionProfileData?.connectionDetails };
    const ConnectionProfile = sourceDetails?.useConnectionProfile
        ? [
              { name: "Connection Profile", value: sourceDetails?.connectionProfileName },
              { name: "Host", value: sourceDetails?.host }
          ]
        : [];

    switch (source) {
        case "ZendeskReader":
            if (sourceDetails?.useConnectionProfile) return ConnectionProfile;
            const AuthDetails = sourceDetails?.authflow
                ? [{ name: "Auth Mode", value: "Auth Token" }]
                : [
                      { name: "Auth Mode", value: getLabel(sourceDetails?.AuthMode) },
                      { name: "Username", value: sourceDetails?.Username }
                  ];
            return [{ name: "Connection Url", value: sourceDetails?.ConnectionUrl }, ...AuthDetails];
        case "StripeReader":
            const ConnectedAccount = sourceDetails?.ConnectedAccount
                ? [{ name: "Account ID", value: sourceDetails?.AccountId }]
                : sourceDetails?.ApiKey?.encrypted
                ? [{ name: "API Key", value: `${sourceDetails?.ApiKey?.encrypted} (encrypted)` }]
                : [{ name: "Authentication Mode", value: sourceDetails?.AuthMode }];
            return [...ConnectedAccount];
        case "SalesForceReader":
        case "SalesforceCDCReader":
            if (sourceDetails?.useConnectionProfile) return ConnectionProfile;
            else return [{ name: "API Endpoint", value: sourceDetails?.apiEndPoint }];
        case "ServiceNowReader":
            if (sourceDetails?.useConnectionProfile) return ConnectionProfile;
            else return getReviewData(readerData, source, sourceDetails);
        // To-do Add details for other targets
        default:
            return getReviewData(readerData, source, sourceDetails);
    }
};

export const getTargetDetails = (target, targetDetails, connectionProfileData) => {
    targetDetails = { ...targetDetails, ...connectionProfileData?.connectionDetails };
    const ConnectionProfile = targetDetails?.useConnectionProfile
        ? [{ name: "Connection Profile", value: targetDetails?.connectionProfileName }]
        : [];

    switch (target) {
        case "Snowflake":
            return [
                ...ConnectionProfile,
                { name: "Authentication type", value: targetDetails?.authenticationType },
                { name: "Connection URL", value: targetDetails?.connectionUrl },
                { name: "Username", value: targetDetails?.username },
                { name: "Database", value: targetDetails?.dbName }
            ];
        case "BigQuery":
            return [
                ...ConnectionProfile,
                ConnectionProfile.length && { name: "Connection URL", value: targetDetails?.ConnectionUrl },
                { name: "Project ID", value: targetDetails?.projectId },
                { name: "Billing Project ID", value: targetDetails?.BillingProjectId },
                {
                    name: "Private Service Connect Endpoint",
                    value: targetDetails?.PrivateServiceConnectEndpoint
                },
                { name: "Dataset", value: targetDetails?.dataset }
            ];
        case "Databricks":
            return [
                ...ConnectionProfile,
                { name: "Connection URL", value: targetDetails?.connectionUrl },
                {
                    name: "Catalog Name",
                    value: targetDetails?.catalog
                }
            ];
        case "Fabric Data Warehouse":
            return [
                { name: "Connection URL", value: targetDetails?.connectionUrl },
                { name: "Username", value: targetDetails?.username },
                { name: "Account Name", value: targetDetails?.accountName },
                { name: "Warehouse", value: targetDetails?.warehouse }
            ];
        // To-do Add details for other targets
        case "Fabric Mirroring":
            return [
                { name: "Mirror Database Name", value: targetDetails?.MirroredDatabaseName },
                { name: "Connection Profile Name", value: targetDetails?.connectionProfileName },
                { name: "Authentication type", value: PRESERVED_NAMES[targetDetails?.authenticationType] },
                { name: "Username", value: targetDetails?.Username },
                { name: "Workspace Entity", value: targetDetails?.workspaceEntity }
            ];
        default:
            return getReviewData(writerData, target, targetDetails);
    }
};

export const getAdvancedDetails = (target, targetDetails, targetConnectionProfileData) => {
    const resource = {
        Process: "Automatically propagate schema changes to the target (Default)",
        Ignore: "Do not propagate schema changes to the target and keep the pipeline running",
        Halt: "Pause the pipeline when the schema changes",
        MERGE: "Directly (default)",
        APPENDONLY: "As audit records",
        aws: "Amazon S3",
        az: "Azure ADLSGen2",
        DatabricksFileSystem: "Databricks File System",
        PSL: "Personal Staging Location",
        S3: "Amazon S3",
        ADLSGen2: "Azure ADLSGen2",
        PersonalStagingLocation: "Personal Staging Username",
        DBFSROOT: "Databricks File System"
    };

    const externalStageDetails = targetConnectionProfileData?.externalStageDetails ?? targetDetails;

    const getLabel = name => resource?.[name] || name;

    const Details = {
        CDDLAction: { name: "When schema changes in the source database", value: getLabel(targetDetails?.CDDLAction) },
        Mode: { name: `Write continous changes to ${target}`, value: getLabel(targetDetails?.Mode) },
        ParallelThreads: {
            name: "Number of parallel thread to use during initial load",
            value:
                getLabel(targetDetails?.ParallelThreads) === ""
                    ? "1 (Do not use parallelism)"
                    : getLabel(targetDetails?.ParallelThreads)
        },
        CDDLTrackingTable: {
            name: "CDDL Tracking table",
            value: getLabel(targetDetails?.CDDLTrackingTable)
        },
        CSP: {
            name: "External Staging Type",
            value: getLabel(targetDetails?.externalStageType),
            isSubComponent: true
        },
        StagingCP: {
            name: "External Staging Connection Profile",
            value: getLabel(targetDetails?.externalStageConnectionProfileName),
            isSubComponent: true
        },
        StreamingConfig: {
            name: "Streaming configuration",
            value: getLabel(targetDetails?.StreamingConfiguration),
            isSubComponent: true
        },
        UserRole: {
            name: "User Role",
            value: getLabel(targetDetails?.userRole),
            isSubComponent: true
        },
        UploadType: {
            name: `Mode to use for writing continuous changes to ${target}`,
            value: getLabel(targetDetails?.streamingUpload ? "Snowpipe streaming" : "File upload")
        }
    };

    const getStageDetails = (targetDetails, stageDetails, showAsSubComponent = false) => {
        const stagingCP = targetDetails?.externalStageConnectionProfileName ? [Details.StagingCP] : [];
        const type = targetDetails?.externalStageType;
        switch (type) {
            case "S3":
                return [
                    ...stagingCP,
                    { name: "S3 Region", value: getLabel(stageDetails.s3Region), isSubComponent: showAsSubComponent },
                    {
                        name: "S3 Bucket Name",
                        value: getLabel(stageDetails.s3BucketName),
                        isSubComponent: showAsSubComponent
                    }
                ];
            case "ADLSGen2":
                return [
                    ...stagingCP,
                    {
                        name: "Azure Account Name",
                        value: getLabel(stageDetails.azureAccountName),
                        isSubComponent: showAsSubComponent
                    },
                    {
                        name: "Azure Container Name",
                        value: getLabel(stageDetails.azureContainerName),
                        isSubComponent: showAsSubComponent
                    }
                ];
            case "PersonalStagingLocation":
                return [
                    {
                        name: "Personal Staging Username",
                        value: getLabel(stageDetails.personalStagingUsername),
                        isSubComponent: showAsSubComponent
                    }
                ];
            default:
                return [];
        }
    };

    const getStreamingUploadDetails = targetDetails => {
        return targetDetails?.streamingUpload ? [Details.StreamingConfig, Details.UserRole] : [];
    };

    const TrackingTable = targetDetails?.CDDLTrackingTable ? Details.CDDLTrackingTable : {};

    switch (target) {
        case "Snowflake":
            return [
                Details.CDDLAction,
                TrackingTable,
                Details.Mode,
                Details.UploadType,
                Details.ParallelThreads,
                ...getStreamingUploadDetails(targetDetails),
                ...(targetDetails?.externalStageType && !targetDetails?.streamingUpload
                    ? [Details.CSP, ...getStageDetails(targetDetails, externalStageDetails, true)]
                    : [])
            ];
        case "BigQuery":
            return [
                Details.ParallelThreads,
                Details.CDDLAction,
                TrackingTable,
                Details.Mode,
                {
                    name: `Mode to use for writing continuous changes to ${target}`,
                    value:
                        typeof targetDetails?.streamingUpload === "boolean"
                            ? getLabel(targetDetails?.streamingUpload ? "Streaming Upload" : "File upload")
                            : getLabel(targetDetails?.streamingUpload === "true" ? "Streaming Upload" : "File upload")
                },
                ...getStreamingUploadDetails(targetDetails),
                ...(targetDetails?.externalStageType
                    ? [Details.CSP, ...getStageDetails(targetDetails, externalStageDetails, true)]
                    : [])
            ];
        case "Databricks":
            return [
                Details.ParallelThreads,
                Details.CDDLAction,
                TrackingTable,
                Details.Mode,
                Details.CSP,
                ...[...getStageDetails(targetDetails, externalStageDetails, true)]
            ];
        case "Fabric Data Warehouse":
            return [Details.ParallelThreads, Details.CDDLAction, TrackingTable, Details.Mode];
        case "Fabric Mirroring":
            return [
                { name: "Batch Policy", value: targetDetails?.batchPolicy },
                Details.ParallelThreads,
                Details.CDDLAction,
                TrackingTable
            ];
        default:
            return [Details.CDDLAction, TrackingTable, Details.ParallelThreads];
    }
};

const getFileName = path => path.split("/").pop();

const formatValue = (value, isFile, options) => {
    if (!value) return "";
    if (options) return options.find(option => option.value === value).label;
    if (typeof value === "boolean") return value ? "Yes" : "No";
    return isFile ? getFileName(value) : value;
};

export const getSSLDetails = (appName, source, target) => {
    if (localStorageUtils.isKeyPresent(appName)) {
        const sourceConfig = localStorageUtils.getItem(appName).connectionParams;
        const targetConfig = localStorageUtils.getItem(`${appName}-target`).targetInfo.targetFormValues;
        const sourceSSLFields = sourceFormConfig.getReviewFields(source.toUpperCase());
        const targetSSLFields = sourceFormConfig.getReviewFields(target.toUpperCase());

        const populateSSLData = (fields, config) =>
            fields
                ?.map(({ label, name, isFile, options }) => ({
                    name: label,
                    value: formatValue(config[name], isFile, options)
                }))
                .filter(item => item.value);

        return [
            populateSSLData(sourceSSLFields, sourceConfig) ?? [],
            populateSSLData(targetSSLFields, targetConfig) ?? []
        ];
    } else return [[], []];
};

export const getSourceConfig = (AppName, sourceType) => {
    return validationUtils.isRDBMS(sourceType.toUpperCase())
        ? localStorageUtils.getItem(AppName).connectionParams
        : null;
};

export const getTargetConfig = (AppName, targetType) => {
    return validationUtils.isRDBMS(targetType.toUpperCase())
        ? localStorageUtils.getItem(`${AppName}-target`).targetInfo.targetFormValues
        : null;
};
