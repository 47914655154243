import React, { useRef, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "../../../../images/Add.svg";
import DownloadIcon from "../../../../images/Download.svg";
import LogIcon from "../../../../images/Log.svg";
import useCreateAppsStore from "src/stores/create-apps/useCreateAppsStore";
import growl from "app/components/common/growl";
import resources from "app/components/createapp/resources";
import { useAppContentStyles, styles } from "./quick-start-group.styles";
import { TemplateGroup } from "../template-group/template-group";
import { useObserver } from "mobx-react";
import { Box } from "@mui/material";
import useNamespaceStore from "src/stores/namespaces/useNamespaceStore";

const allowedFilesToImport = [".tql"];

const QuickStartGroup = ({ showTemplates = true, tqlUploadInputRef = null }) => {
    const classes = useAppContentStyles();
    const inputRef = useRef();
    const navigate = useNavigate();
    const store = useCreateAppsStore();
    const namespaceStore = useNamespaceStore();
    const [fetchingNamespaces, setFetchingNamespaces] = useState(false);
    useEffect(() => {
        const asyncCall = async () => {
            setFetchingNamespaces(true);
            await namespaceStore.namespacesFetch();
            setFetchingNamespaces(false);
        };

        asyncCall();
    }, [namespaceStore]);

    const namespacesOptions = useObserver(() => {
        return Array.from(new Set(namespaceStore.getNamespaceOptions(true)));
    });

    const importAppFromFile = useCallback(
        uploaded_file => {
            const reader = new FileReader();
            reader.readAsDataURL(uploaded_file);
            reader.onload = function(event) {
                const result = event.target.result;
                const base64 = result.split(",")[1];
                const decoded = atob(base64);

                store.tql = decoded;
                store.fileName = uploaded_file.name;
                if (store.nameFromTql === null) {
                    growl.error("Invalid TQL. Application name not found");
                    return;
                }
                if (
                    store.namespaceFromAppName &&
                    store.namespaceFromAppName !== "admin" &&
                    namespacesOptions?.map(options => options.value).indexOf(store.namespaceFromAppName) === -1
                ) {
                    growl.error(`Invalid TQL. Namespace ${store.namespaceFromAppName} is not found`);
                    return;
                }
                navigate(`/create-app/name-app`);
                return;
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetchingNamespaces, namespacesOptions]
    );

    const validateImportedFile = useCallback(uploaded_file => {
        const ext = uploaded_file.name.split(".").pop();
        if (allowedFilesToImport.indexOf("." + ext) === -1) {
            growl.error(resources.onlyFilesWithExtensions(allowedFilesToImport), "Invalid file");
            return false;
        }
        return true;
    }, []);

    const handleFocusBack = useCallback(() => {
        window.removeEventListener("focus", handleFocusBack);
        setTimeout(() => {
            if (!window.location.href.includes("create-app/name-app")) {
                navigate(`/applications`);
            }
        }, 500);
    }, [navigate]);

    const onTQLSelectorClick = () => {
        window.addEventListener("focus", handleFocusBack);
    };

    const changeHandler = useCallback(
        event => {
            window.removeEventListener("focus", handleFocusBack);
            const uploaded_file = event.target.files[0];

            if (validateImportedFile(uploaded_file)) {
                importAppFromFile(uploaded_file);
            }
            //reseting the input so it will accept the same file again as input in case of file errors
            event.target.value = "";
        },
        [importAppFromFile, validateImportedFile, handleFocusBack]
    );
    return (
        <Box sx={styles.quickGroupContainer}>
            <input
                ref={tqlUploadInputRef ?? inputRef}
                type="file"
                onChange={changeHandler}
                onClick={onTQLSelectorClick}
                accept=".tql"
                className={classes.pathInput}
                id="createapp--import-input"
            />
            {showTemplates && (
                <TemplateGroup
                    templates={[
                        {
                            id: "addNewApp",
                            fromIcon: AddIcon,
                            title: "Start from scratch",
                            url: "/create-app/name-app"
                        },
                        {
                            id: "importTQL",
                            fromIcon: DownloadIcon,
                            title: "Import TQL file",
                            onClick: () => {
                                if (tqlUploadInputRef) {
                                    tqlUploadInputRef.current.click();
                                } else {
                                    inputRef.current.click();
                                }
                            }
                        },
                        {
                            id: "sourcePreview",
                            fromIcon: LogIcon,
                            title: "Source Preview",
                            url: "/preview"
                        }
                    ]}
                />
            )}
        </Box>
    );
};

export default QuickStartGroup;
