import Column from "../columns-picker/column";

var SingleColumn = {};

SingleColumn.View = Column.View.extend({
    onRender: function () {
        Column.View.prototype.onRender.apply(this);
        this.$el.find("#columns-wrapper").hide();
    },

    setEnabled: function (enabled) {
        Column.View.prototype.setEnabled.apply(this, [enabled]);
    }
});

SingleColumn.CollectionView = Column.CollectionView.extend({
    childView: SingleColumn.View,
    onRender: function () {
        Column.CollectionView.prototype.onRender.apply(this);
        this.$el.find("thead,tbody").addClass("without-col1");
    }
});

SingleColumn.Model = Column.Model.extend({
    defaults: {
        column: String,
        delimiter: String,
        alias: String,
        columns: Array,
        tqlFunction: String,
        enabled: true,
    },
});

SingleColumn.Collection = Column.Collection.extend({
    model: SingleColumn.Model,
});

export default SingleColumn;
