import _ from "underscore";
import $ from "jquery";
import api from "core/api/api";
import UIControl from "app/components/common/editor/control/ui-control";
import ComparisonOperators from "./comparisonOperators.json";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import { loadAscendantsDbSources } from "app/components/flow/designer/component-acendants-loader";

const Transformation = {};

Transformation.OnlyDbSources = true;

Transformation.createColumnControl = function () {
    return UIControl.ColumnsWithFunctionPicker.extend({
        hideLabel: true,
        hidden: false,
        transformationConfig: {
            preFillColumns: false,
            addAvailable: true,
            addLabel: "ADD CONDITION",
            functionsSource: getFunctionsListPerType,
            columnsSource: getColumnsSource,
            addNew: true,
            labels: {
                column1: "Meta-Field",
                column2: "Operator",
                column3: "Value",
            },
        },
    }).create();
};

Transformation.buildTql = function (tqlBuilder, columns) {
    tqlBuilder.addWAEventWildcard();

    columns.forEach(function (column) {
        if (!column.column || !column.tqlFunction || !column.alias) {
            return;
        }

        tqlBuilder.addMetaFilteringStatement(column.column, column.tqlFunction, column.alias);
    });
};

function isSupportedDatabase(adapterName) {
    return (
        [
            "MYSQLREADER",
            "ORACLEREADER",
            "MSSQLREADER",
            "DATABASEREADER",
            "POSTGRESREADER",
            "INCREMENTALBATCHREADER",
        ].indexOf(adapterName.toUpperCase()) !== -1
    );
}

function getFunctionsListPerType() {
    var operatorsList = _.filter(ComparisonOperators, (operator) => {
        return operator.allowMeta;
    });

    return toSourceList(operatorsList);
}

function getColumnsSource(streamId) {
    let deferred = $.Deferred();
    loadAscendantsDbSources(streamId).then((sources) => {
        let list = [];
        if (sources.length > 0) {
            let adapterName = metaObjectConverter.getName(sources[0].adapter.handler).toUpperCase();

            if (isSupportedDatabase(adapterName)) {
                api.getDatabaseMetadata(adapterName)
                    .then((results) => {
                        deferred.resolve(
                            results.map((x) => {
                                return {
                                    id: x.Name,
                                    text: x.Name,
                                    description: x.Description,
                                    function: x.Name,
                                    selectedText: x.Name,
                                };
                            })
                        );
                    })
                    .fail(() => {
                        deferred.resolve(list);
                    });
            } else {
                deferred.resolve(list);
            }
        } else {
            deferred.resolve(list);
        }
    });
    return deferred.promise();
}

function toSourceList(operators) {
    return operators.map((operator) => {
        return {
            id: operator.operator,
            text: operator.displayAs,
            description: operator.operator,
            function: operator.operator,
            selectedText: operator.displayAs,
        };
    });
}

export default Transformation;
