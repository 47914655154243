import numeral from "numeral";
import { convertNameToHumanRedable } from "app/components/common/editor/control-resource-resolver";
import { addonFeaturesTitles } from "../../../../user-plan/components/add-on-features/add-on-features-utils";

const formatNumber = (number, includeAbbreviation = true) => {
    if (!number) {
        return 0;
    }

    return includeAbbreviation
        ? numeral(number.toFixed(6))
              .format("0.[000000]a")
              .toUpperCase()
        : parseFloat(number.toFixed(6));
};

const formatStorageNumber = value => {
    if (!value) {
        return 0;
    }

    return numeral(value).format("0.[000] b");
};

const formatPercentage = value => {
    if (!value) {
        return 0;
    }

    return numeral(value.toFixed(2)).format("0.[00]");
};

const getValueColorStyles = (value, theme) => {
    if (!theme) {
        return {};
    }

    let color = theme.palette.success.main;

    if (value < 0) {
        color = theme.palette.danger.main;
    }

    return { color: color };
};

const formatItemizedItemName = name => {
    try {
        let allCaps = name.toUpperCase();
        if (addonFeaturesTitles[allCaps]) {
            return addonFeaturesTitles[allCaps];
        }
        return convertNameToHumanRedable(name.split(" ")[0]);
    } catch {
        return name;
    }
};

const formatUnitName = name => {
    let unit = name.split("/")[0];
    return convertNameToHumanRedable(unit);
};

export {
    formatNumber,
    getValueColorStyles,
    formatStorageNumber,
    formatPercentage,
    formatItemizedItemName,
    formatUnitName
};
