import React, { useState, useMemo } from "react";
import api from "core/api/api";
import UsersListPage from "./components/users-listing";
import RolesListPage from "./components/roles-listing";
import UsersPageFilters from "./components/users-page-filters";
import SingleSignOnPage from "./components/single-sign-on";
import { useLocation } from "react-router-dom";
import { useReactHeader } from "src/hooks/useReactHeader";
import UserManagementRoot from "../user-management-root";
import { Box } from "@mui/material";

export const getSSOProperties = async (setSsoProperties, customAction = null) => {
    try {
        const resp = await api.getSSOProperties();

        setSsoProperties(resp);
    } catch (e) {
        console.error(e);
    } finally {
        customAction && customAction();
    }
};

const UsersPage = () => {
    useReactHeader({ title: "Users" });
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location?.state?.activeTab || 0);
    const [ssoConfigured, setSsoConfigured] = useState(false);
    const [ssoProperties, setSsoProperties] = useState({});

    React.useEffect(() => {
        isSAMLConfigured();
    }, []);

    React.useEffect(() => {
        setSsoConfigured(Object.keys(ssoProperties).length !== 0);
    }, [ssoProperties])

    const ActiveTabComponent = useMemo(() => {
        switch (activeTab) {
            case 0:
                return <UsersListPage />;
            case 1:
                return <RolesListPage />;
            case 2:
                return <SingleSignOnPage
                    ssoConfigured={ssoConfigured}
                    ssoProperties={ssoProperties}
                    setSsoProperties={setSsoProperties}
                />;
            default:
                return <UsersListPage />;
        }
    }, [activeTab, ssoConfigured, ssoProperties]);

    const isSAMLConfigured = async () => {
        try {
            const resp = await api.isSAMLConfigured();

            setSsoConfigured(resp);

            if (resp) {
                getSSOProperties(setSsoProperties);
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Box mt={8}>
            <UsersPageFilters setActiveTab={setActiveTab} activeTab={activeTab} content={ActiveTabComponent} ssoConfigured={ssoConfigured} />
        </Box>
    );
};

export default function() {
    return (
        <UserManagementRoot>
            <UsersPage />
        </UserManagementRoot>
    );
}
