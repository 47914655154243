import _ from "underscore";
import BaseControl from "./../base-control";
import dateLib from "core/utils/date-utils";
import template from "./time-picker.html";
import "timepicker";

var fullTimeFormat = "HH:mm:ss";
var shortTimeFormat = "h:mmA";
var shortTimeFormatDropdown = "g:iA"; // lib/vendor/timepicker/jquery.timepicker.js:1052
var Model = BaseControl.Model.extend({
    defaults: {
        value: null
    }
});

export default function(options) {
    var View = BaseControl.View.extend({
        template: _.template(template),
        className: "time-picker",
        events: _.extend(
            {
                "keydown input": "validateInput"
            },
            BaseControl.View.prototype.events
        ),
        validateInput: function(event) {
            this.$("input").focus();
            return (
                event.keyCode === 8 || // backspace
                event.keyCode === 46 || // delete
                event.keyCode === 37 || // left arrow
                event.keyCode === 39 || // right arrow
                (event.keyCode >= 48 && event.keyCode <= 57)
            ); // digits
        },
        getViewValue: function() {
            var val = this.$("input").val();
            if (val) {
                return dateLib(val, shortTimeFormat).format(fullTimeFormat);
            } else {
                return null;
            }
        },
        setViewValue: function() {
            var value = this.getValue();
            if (value) {
                this.$("input").val(dateLib(value, fullTimeFormat).format(shortTimeFormat));
            } else {
                this.$("input").val(null);
            }
        },
        onRender: function() {
            this.$("input").timepicker({
                appendTo: this.$("input").parent(),
                scrollDefault: "now",
                step: 15,
                forceRoundTime: true,
                timeFormat: shortTimeFormatDropdown,
                customClassName: options?.customClassName || ""
            });
            BaseControl.View.prototype.onRender.apply(this, arguments);
        }
    });
    return _.extend({}, BaseControl, {
        Model: Model,
        View: View
    }).create();
}
