import React, { useEffect, useRef } from "react";
import "core/core";
import App from "./app";
import api from "./core/api/api";
import { contextStore } from "./src/stores";
import "app/components/dashboards/dashboards";

export default function BackboneApp({ dispatcher }) {
    const startedRef = useRef(false);

    useEffect(() => {
        if (startedRef.current) {
            return;
        }
        startedRef.current = true;
        contextStore.backboneStore.setStarted();
    }, []);

    return <></>;
}
