import React, { useRef, useState, useCallback, useMemo } from "react";
import {
    StriimDataTable,
    StriimInputField,
    StriimIconButton,
    StriimChip,
    StriimTypography,
    StriimTooltip
} from "@striim/striim-ui";
import { Clear, Search } from "@mui/icons-material";
import { Box, Grid, InputAdornment } from "@mui/material";

import { FilterType } from "./jobs-list-filters";
import { styles } from "./jobs-list-table.styles";
import { ProgressBar } from "../../components";
import { useNavigate } from "react-router";
import { JobStatuses } from "../../guardian-job.types";
import AI_ROUTES from "../../../../ai-insights/routes.json";
import StriimCircularProgress from "../../../../apps/pages/manage-striim/connection-profiles/components/circular-progress/circular-progress";
import useDebounce from "../../../../../hooks/useDebounce";

export const SearchBar = ({ isGroupTab = true, userSearchInput, setUserSearchInput, setFiltersDebounced }) => {
    const searchInputRef = useRef();

    return (
        <StriimInputField
            placeholder={isGroupTab ? "Search by Report name" : "Search by App name"}
            inputRef={searchInputRef}
            value={userSearchInput}
            onChange={searchValue => {
                setUserSearchInput(searchValue);
                setFiltersDebounced();
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search opacity={0.45} />
                    </InputAdornment>
                ),
                ...(searchInputRef.current?.value?.length && {
                    endAdornment: (
                        <StriimIconButton
                            variant="primary"
                            sx={styles.endAdornmentContainer}
                            onClick={() => {
                                searchInputRef.current.value = "";
                                setUserSearchInput("");
                                setFiltersDebounced();
                            }}
                            data-test-id="search-app-name-clear-button"
                        >
                            <Clear />
                        </StriimIconButton>
                    )
                })
            }}
        />
    );
};

export const formatUnixTimestamp = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true
    });
};

const JobsListTable = ({ data, customMaxHeight, tabs }) => {
    const [userSearchInput, setUserSearchInput] = useState("");
    const [filteredResults, setFilteredResults] = useState(data);
    const navigate = useNavigate();

    const getStatusVariant = useCallback(status => {
        switch (status.toLowerCase()) {
            case "completed":
                return "success";
            case "errors":
            case "error":
                return "error";
            case "warning":
            case "incomplete":
                return "warning";
            case "running":
            default:
                return "default";
        }
    }, []);

    const isCreated = status => status === JobStatuses.CREATED;

    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            resizable: true,
            sortable: true,
            cellRenderer: ({ data: { name, status } }) => (
                <StriimTooltip title={name} placement="top">
                    <StriimTypography
                        variant={"body4"}
                        color="secondary.500"
                        onClick={() => {
                            status === JobStatuses.CREATED
                                ? navigate(`${AI_ROUTES.selectApps}/${name}`)
                                : navigate(`${AI_ROUTES.view}/${name}`);
                        }}
                    >
                        {name}
                    </StriimTypography>
                </StriimTooltip>
            )
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            resizable: true,
            sortable: true,
            cellRenderer: ({ value }) => {
                return (
                    <StriimChip
                        variant={getStatusVariant(value)}
                        label={value}
                        data-testid="report-status"
                        icon={[JobStatuses.RUNNING].includes(value) ? <StriimCircularProgress size={12} /> : null}
                    />
                );
            }
        },

        {
            field: "generationTime",
            headerName: "Started (PST)",
            flex: 1,
            resizable: true,
            sortable: true,
            cellRenderer: ({ value }) => (value ? formatUnixTimestamp(value) : "-")
        },
        {
            field: "completedTime",
            headerName: "Completed (PST)",
            flex: 1,
            resizable: true,
            sortable: true,
            cellRenderer: ({ value }) => (value ? formatUnixTimestamp(value) : "-")
        },
        {
            field: "appsWithSensitiveData",
            headerName: "Apps w/ Sensitive Data",
            flex: 1,
            resizable: true,
            sortable: true,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                const value = isDescending ? -1 : 2;
                const ratioA = isCreated(nodeA.data.status) ? value : nodeA.data.apps / nodeA.data.totalApps || 0;
                const ratioB = isCreated(nodeB.data.status) ? value : nodeB.data.apps / nodeB.data.totalApps || 0;
                return ratioA - ratioB;
            },
            cellRenderer: ({ data: { apps, totalApps, status } }) =>
                status === JobStatuses.CREATED ? (
                    "-"
                ) : (
                    <Box>
                        <ProgressBar completed={apps} total={totalApps} status={status} />
                    </Box>
                )
        }
    ];

    const handleFilteredResults = useMemo(() => {
        if (userSearchInput.length) {
            const userInputFilter = FilterType.UserInput(userSearchInput);
            setFilteredResults(data.filter(userInputFilter.callbackFunction));
        } else {
            setFilteredResults(data);
        }
    }, [userSearchInput, data]);

    const setFiltersDebounced = useDebounce(handleFilteredResults, 300);

    return (
        <>
            <Grid container justifyContent={"space-between"} alignItems={"center"} mb={2}>
                {tabs}
                <Box width={356}>
                    <SearchBar
                        userSearchInput={userSearchInput}
                        setUserSearchInput={setUserSearchInput}
                        setFiltersDebounced={setFiltersDebounced}
                    />
                </Box>
            </Grid>
            <StriimDataTable
                data={filteredResults}
                columns={columns}
                sortable={true}
                filterable={true}
                animateRows="true"
                maxHeight={customMaxHeight}
            />
        </>
    );
};

export default JobsListTable;
