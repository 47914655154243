import React from "react";
import { Box, Grid } from "@mui/material";

import JobDetailsHeader from "../job-details-header/job-details-header";
import { TABS } from "../job-list-insights-view/job-list-insights-view";

interface ViewAllPageInterface {
    name: string;
    setViewAllView?: React.Dispatch<React.SetStateAction<boolean>>;
    subtitle?: string;
    completedTime?: number;
    setDefaultSelectedTab?: React.Dispatch<React.SetStateAction<string>>;
    children: React.ReactNode;
    customHeading?: JSX.Element;
    onBackAction?: Function;
}

const ViewAllPage = ({
    name = "",
    setViewAllView,
    subtitle = "",
    completedTime,
    setDefaultSelectedTab,
    children,
    customHeading = null,
    onBackAction
}: ViewAllPageInterface) => {
    return (
        <Grid container height={"100%"}>
            <JobDetailsHeader
                name={name}
                onBack={onBackAction}
                onDownload={() => null}
                generationTime={0}
                status={null}
                simpleHeading={true}
                subtitle={subtitle}
                completedTime={completedTime}
                customHeading={customHeading}
            />
            <Box width="100%" height={"calc(100% - 70px)"}>
                {children}
            </Box>
        </Grid>
    );
};

export default ViewAllPage;
