import $ from "jquery";
import _ from "underscore";
import api from "core/api/api";
import growl from "app/components/common/growl";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import dialog from "app/components/common/dialogs/dialogWindow";
import FlowDatasource from "./CachedFlowDatasource";

function pasteComponents(currentFlowId, app, nodesClipboard, nodes, modalManager) {
    function loadComponentsById(listOfIds) {
        // get components by id from metaStoreService

        var result = [];
        var promises = [];
        var def = $.Deferred();
        _.each(listOfIds, function (nodeId) {
            var promise = metaStoreService.findById(nodeId).then(function (model) {
                result.push(model);
            });
            promises.push(promise);
        });

        $.when.apply($, promises).then(function() {
            def.resolve(result);
        });

        return def.promise();
    }

    function sortComponentsByDepth(components) {
        // using flowDatasource get max depth for every component and then sort ascending

        var def = $.Deferred();

        var flowDatasource = new FlowDatasource({
            appName: app.nsName + "." + app.name,
        });

        flowDatasource.invalidateCache();
        flowDatasource.load().then(function (items) {
            var componentsWithDepth = components.map(function (c) {
                function calcComponentDepth(id, currentDepth) {
                    if (!currentDepth) {
                        currentDepth = 0;
                    }

                    var component = _.find(items, function (item) {
                        return item.id === id;
                    });
                    if (!component || !component.targets) {
                        return currentDepth;
                    }
                    if (currentMax > 100) {
                        console.log("connections have loop");
                        return 100;
                    }
                    var currentMax = currentDepth;
                    component.targets.forEach(function (t) {
                        var targetDepth = calcComponentDepth(t.id, currentDepth + 1);
                        if (targetDepth > currentMax) {
                            currentMax = targetDepth;
                        }
                    });
                    return currentMax;
                }

                return {
                    component: c,
                    depth: calcComponentDepth(c.id),
                };
            });

            var ordered = componentsWithDepth
                .sort(function (a, b) {
                    return b.depth - a.depth;
                })
                .map(function (item) {
                    return item.component;
                });

            def.resolve(ordered);
        });

        return def.promise();
    }

    function updateFlowId(components) {
        // for sorted components change flow id and save

        var def = $.Deferred();
        var promises = [];
        _.each(components, function (model) {
            var def = $.Deferred();
            promises.push(def);

            model
                .save({
                    flow: MetaObjectConverter.getName(currentFlowId),
                })
                .then(function() {
                    def.resolve();
                })
                .fail(function (error) {
                    def.resolve();
                    if (!error) {
                        return;
                    }
                    growl.error(error.message, "Cannot paste components - " + model.name);
                });
        });
        $.when.apply($, promises).then(function() {
            def.resolve(components);
        });
        return def.promise();
    }

    function afterSave(components) {
        // application has to be recompiled and info message should be shown

        var def = $.Deferred();
        nodesClipboard.clear();
        api.compile(app.name).then(function () {
            var message;
            if (components.length > 0) {
                if (components.length === 1) {
                    message = "Component pasted successfully";
                } else {
                    message = components.length + " components pasted successfully";
                }
                growl.success(message);
                def.resolve();
            }
        });
        return def.promise();
    }

    // check if clipboard contains elements
    if (nodesClipboard.containElements === false) {
        return $.Deferred().resolve().promise();
    }

    // check if pasting on the same flow
    var firstNode = nodes.byId(nodesClipboard.elements[0]);
    if (firstNode) {
        nodesClipboard.clear();
        return $.Deferred().resolve().promise();
    }

    return loadComponentsById(nodesClipboard.elements).then(sortComponentsByDepth).then(updateFlowId).then(afterSave);
}

export default pasteComponents;
