import Backbone from "backbone";
import _ from "underscore";
import Select from "./../select/select";
import NestedTypes from "backbone.nestedtypes";
import gridTemplate from "./grid.html";
import rowTemplate from "./row.html";
import TqlBuilder from "app/components/common/tqlBuilder";

var Column = {};

Column.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(rowTemplate),
    tagName: "tr",

    modelEvents: {
        "change:column": "columnModified",
        "change:alias": "modified"
    },

    ui: {
        columns: ".columns",
        alias: ".alias",
        controls: ".action-controls",
        iconClose: ".icon-close"
    },

    regions: {
        columnsRegion: ".columns"
    },

    triggers: {
        "click .icon-close": {
            event: "removeColumn",
            preventDefault: true, // this param is optional and will default to true
            stopPropagation: true
        }
    },

    events: {
        "keyup @ui.alias": "changeAlias"
    },

    initialize: function(options) {
        var _this = this;
        this.config = _.defaults(options.config, {
            allowClearColumns: true,
            addNew: false
        });

        this.columns = Select(this.model.columns, {
            allowClear: this.config.allowClearColumns,
            addNew: this.config.addNew
        }).create();

        this.listenTo(this.columns.model, "change:value", function(viewModel) {
            _this.model.column = viewModel.value;
        });
    },

    modified: function() {
        this.trigger("modified");
    },

    columnModified: function() {
        if (this.config.autoFillAlias) {
            this.ui.alias.val(this.config.getShortAliasName ? this.model.column.replace(".", "_") : this.model.column);
            this.changeAlias();
        }

        this.modified();
    },

    setColumnsDataSource: function(dataSource) {
        this.columns.setDataSource(dataSource);
    },

    changeAlias: function() {
        this.model.alias = TqlBuilder.ensureValidTql(this.ui.alias.val());
    },

    setEnabled: function(enabled) {
        enabled = !!enabled;
        this.model.enabled = enabled;
        this.ui.controls.toggle(enabled);
        this.ui.alias.prop("disabled", !enabled);
        this.columns.setEnabled(enabled);
        this.ui.columns.show();
    },

    onRender: function() {
        this.$el.attr("data-backbone-cid", this.model.cid); //TODO: is it really needed?
        this.columnsRegion.show(this.columns);
        this.columns.setValue(this.model.column);

        if (this.config.hideRemoveIcon) {
            this.ui.iconClose.hide();
        }
    }
});

Column.CollectionView = Backbone.Marionette.CompositeView.extend({
    tagName: "table",
    template: _.template(gridTemplate),
    childView: Column.View,
    initialize: function(options) {
        this.config = options.config || {};
    },
    childViewOptions: function(model) {
        return {
            enabled: model.enabled,
            config: this.config
        };
    },
    modified: function() {
        this.trigger("modified");
    },
    childEvents: {
        removeColumn: "removeColumn",
        modified: "modified"
    },
    behaviors: {
        Sortable: {}
    },
    ui: {
        table: "tbody",
        cancel: ".cancel",
        save: ".save",
        column1header: "#column1header",
        column2header: "#column2header"
    },
    events: {
        "click @ui.cancel": "cancel",
        "click @ui.save": "save"
    },
    setColumnsDataSource: function(dataSource) {
        this.children.each(function(c) {
            c.setColumnsDataSource(dataSource);
        });
    },
    setEnabled: function(enabled) {
        this.children.each(function(c) {
            c.setEnabled(enabled);
        });
        this.$el.find("tbody").sortable(enabled ? "enable" : "disable");
    },
    scrollToBottom: function() {
        var scrollTo_val = this.$("tbody").prop("scrollHeight");
        this.$("tbody").scrollTop(scrollTo_val);
    },
    removeColumn: function(childView) {
        this.removeChildView(childView);
        this.trigger("remove", childView);
        this.trigger("modified");
    },
    onRender: function() {
        if (this.config.labels) {
            if (this.config.labels.column1) {
                this.ui.column1header.text(this.config.labels.column1);
            }

            if (this.config.labels.column2) {
                this.ui.column2header.text(this.config.labels.column2);
            }
        }
    }
});

Column.Model = NestedTypes.Model.extend({
    defaults: {
        column: String,
        tqlFunction: String,
        alias: String,
        enabled: true,
        columns: [],
        columnType: String
    },
    validate: function() {
        if (!this.name) {
            return "name cannot be empty!";
        }
    },
    properties: {
        hasErrors: function() {
            return this.validate();
        }
    }
});

Column.Collection = NestedTypes.Collection.extend({
    model: Column.Model,
    properties: {
        hasErrors: function() {
            if (this.models.length === 0) {
                return true;
            }

            return _.some(this.models, function(m) {
                return m.hasErrors;
            });
        }
    }
});

export default Column;
