import { template, className, regions, on } from "core/decorators";
import { LayoutView } from "marionette";
import App from "app";
import ConfigViewTemplate from "./templates/config-view.template.html";
import FormBuilder from "app/components/common/editor/form-builder";
import UIControl from "app/components/common/editor/control/ui-control";
import DriversCheckView from "app/components/appwizard/component-wizards/wizards/source/base-cdc/drivers-check-view";
import { DB_PROVIDERS } from "./configs/db-providers";
import { loadDGsForAgents } from "app/components/flow/designer/agents-loader";
import _ from "underscore";
import { sourceFormConfig } from "./configs/source-form-info";
import ensureRegions from "../../../../../common/_ensureRegions";
import { isAutomatedWizard } from "./app-services";
import React from "react";
import InlineHelpMarionetteContainer from "../inline-help-container";
import securityService from "../../../../../../../core/services/securityService/securityService";

@className("sourceInfoView card")
@template(ConfigViewTemplate)
@regions({
    form: ".source-info-form",
    optionalForm: ".source-info-optional-form",
    driversCheckContainer: "#drivers-check"
})
export default class ConfigView extends InlineHelpMarionetteContainer {
    initialize() {
        let model = App.reqres.request("get:wizard:model");
        this.model = model;
        this.model.set("sourceName", this.getSourceName(model));
        this.model.get("connectionParams").set("connectionUrl", null);
        App.vent.trigger("appwizard:next:disable");
        App.vent.trigger("appwizard:back:hide");
        const type = this.model.get("type").toLowerCase();
        const ID = type + "-configure-source";
        super.initialize(ID);
    }

    getSourceName(model) {
        return DB_PROVIDERS[model.get("connectionParams").get("type")].name;
    }

    onRender() {
        this.createConfigViewForm();
        this.createDriverCheckForm();
    }

    createConfigViewForm() {
        ensureRegions(this);
        let model = this.model.get("connectionParams");
        let sourceID = this.model.get("connectionParams").get("type");
        let isCDC = this.model.get("isCDC");
        let hasAutoSchemaConversion = this.model.get("hasAutoSchemaConversion");
        const isFabricSolution = securityService.isFabricSolution();
        let isAutomatedILCDC = isAutomatedWizard();
        let form = new FormBuilder({
            model: model,
            autoSave: true,
            labels: {}
        });
        const formFields = sourceFormConfig.getFormFields(sourceID, isCDC, isAutomatedWizard());
        const reactForm = UIControl.WizardFormBuilder.create({
            formFields,
            populateModel: model,
            hasAutoSchemaConversion,
            isAutomatedILCDC,
            sourceID,
            isFabricSolution
        });
        this.reactForm = reactForm;
        form.addStaticControl(reactForm);
        let agentDeploymentGroups = loadDGsForAgents();
        if (agentDeploymentGroups.length > 0) {
            if (agentDeploymentGroups.length === 1) {
                form.addControl(UIControl.Toggle.create(), "validateSourceOnAgent");
            } else {
                let selectOptions = [];
                _.each(agentDeploymentGroups, function(agentDeploymentGroup) {
                    selectOptions.push({
                        id: agentDeploymentGroup.get("id"),
                        text: agentDeploymentGroup.get("name"),
                        description: "Deployment Group"
                    });
                });
                form.addControl(UIControl.Select(selectOptions).create(), "validateSourceOnAgent");
            }
        }

        const configForm = form.create();
        this.getRegion("form").show(configForm);
        this._configForm = configForm;
    }

    createDriverCheckForm() {
        ensureRegions(this);
        let type = DB_PROVIDERS[this.model.get("connectionParams").get("type")].name;
        let driversCheckView = new DriversCheckView({
            moduleData: {
                customOptions: {
                    dbType: type
                },
                templateID: this.model.get("templateID")
            }
        });
        this.getRegion("driversCheckContainer").show(driversCheckView);
    }

    enableNext(changedProperties) {
        let [validation, notRequiredFields] = this.model.get("connectionParams").validate(this.model.get("isCDC"));
        if (_.isEmpty(validation)) {
            App.vent.trigger("appwizard:next:enable");
        } else {
            App.vent.trigger("appwizard:next:disable");
        }
        if (this._configForm && changedProperties) {
            const _this = this;
            setTimeout(() => {
                validation = validation || {};
                _this._configForm.model.controls.forEach(control => {
                    if (notRequiredFields.length && notRequiredFields.includes(control.propertyName)) {
                        control.view.model.set("enabled", false);
                        this.model.get("connectionParams").set("port", "");
                        control.view.model.set("value", "");
                        return;
                    }
                    control.view.model.set("enabled", true);
                    if (changedProperties.indexOf(control.propertyName) === -1) {
                        return;
                    }

                    const validationError = validation[control.propertyName];
                    if (validationError) {
                        control.view.trigger("value:invalid", validationError, null);
                    }
                });
                if (_this.model.get("connectionParams").get("type") === DB_PROVIDERS.OJET.id) {
                    _this._configAdditionalForm.model.controls.forEach(control => {
                        if (changedProperties.indexOf(control.propertyName) === -1) {
                            return;
                        }

                        const validationError = validation[control.propertyName];
                        if (validationError) {
                            control.view.trigger("value:invalid", validationError, null);
                        }
                    });
                }
            }, 0);
        }
    }

    canMoveForward() {
        return new Promise(function(resolve, reject) {
            resolve();
        });
    }

    serializeData() {
        let data = this.model.toJSON();
        data["displayName"] =
            DB_PROVIDERS[this.model.get("connectionParams").get("type")].name +
            " " +
            DB_PROVIDERS[this.model.get("connectionParams").get("type")].rootElement;
        return data;
    }
}
