import $ from "jquery";

export default {
    ranges: {
        second: "SECOND",
        minute: "MINUTE",
        hour: "HOUR",
        day: "DAY",
    },

    toMicroseconds: function (value, unit) {
        // summary:
        //   Converts a value/unit timestamp object to microseconds
        // parameters:
        //   value: Integer
        //   unit: SECOND/s MINUTE/m HOUR/h DAY/d

        switch (unit.toUpperCase()[0]) {
            case this.ranges.second.toUpperCase()[0]:
                return value * 1000000;
            case this.ranges.minute.toUpperCase()[0]:
                return value * 1000000 * 60;
            case this.ranges.hour.toUpperCase()[0]:
                return value * 1000000 * 60 * 60;
            case this.ranges.day.toUpperCase()[0]:
                return value * 1000000 * 60 * 60 * 24;
            default:
                throw Error("unsupported data format!");
        }
    },

    toString: function (value) {
        // summary:
        //   Returns human readable time string, for example "5 SECONDS" or "1 HOUR"
        // parameters:
        //    value: Long, number of microseconds

        var d = this.inDuration(value);
        return d.value + " " + d.unit + (d.value === 1 ? "" : "s");
    },

    inDuration: function (value) {
        // summary:
        //   Converts microseconds to a value/unit timestamp object,
        //   If the value is not a numeric it will try to parse it
        // parameters:
        //   value: Long, number of microseconds

        if ($.isNumeric(value)) {
            var seconds = Math.floor(value / 1000000);
            if (seconds < 60) {
                return {
                    value: seconds,
                    unit: this.ranges.second,
                };
            }
            var minutes = Math.floor(seconds / 60);
            if (minutes < 60) {
                return {
                    value: minutes,
                    unit: this.ranges.minute,
                };
            }
            var hours = Math.floor(minutes / 60);
            if (hours < 24) {
                return {
                    value: hours,
                    unit: this.ranges.hour,
                };
            }
            var days = Math.floor(hours / 24);

            return {
                value: days,
                unit: this.ranges.day,
            };
        } else {
            var splits = value.split(" ");
            return {
                value: splits[0],
                unit: splits[1].toUpperCase(),
            };
        }
    },
};
