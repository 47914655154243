export const styles = {
    iconButton: {
        minWidth: 'unset',
        padding: 0,
        "& .MuiButton-startIcon": {
            mx: 0,
        }
    },
    chevronIcon: {
        marginLeft: 1.5,
        marginTop: 1.5,
        "& svg": {
            fontSize: '10px !important',
            fill: 'none',
            "& > path": {
                fill: ({ palette }) => palette.greyscale[800],
            }
        }

    },
    menuList: {
        minWidth: 350
    },
}