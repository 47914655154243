import _ from "underscore";

// has to be mixed in to an object derived from Chart.js (App.Chart.Default.ChartView)
export default {
    _shouldRecreateVisualization: function (visualization) {
        var latestConfig = JSON.stringify(_.omit(this.model.config.toJSON(), "data", "last_data"));

        if (!visualization) {
            this._currentConfig = null;
        }

        if (!this._currentConfig || this._currentConfig !== latestConfig) {
            this._currentConfig = latestConfig;
            return true;
        }

        return false;
    },

    renderIfNeeded: function (visualization, renderFunction, updateFunction) {
        if (this.isDestroyed) {
            return;
        }

        if (!this.chartConfig) {
            return;
        }

        // Do not proceed if the target node is detached (as _ensureElement in Backbone)
        // This will be the case if the chart is being updated before the element is attached to the DOM
        if (typeof this.$el.parent()[0] === "undefined") {
            console.warn(
                "Error: chart el is detached. Not rendering chart. This can happen because:\n1. The chartview $el has not yet been attached to the DOM (no problem). \n2. You called generate_chart_model, and then destroyed the view where the chart currently is displayed (you should look into that).\nConfig:",
                this.chartConfig
            );
        }

        if (this._shouldRecreateVisualization(visualization)) {
            renderFunction.call(this);
        }

        if (typeof updateFunction !== "function") {
            return;
        }

        if (!this.model.config.lastRendered || this.model.config.lastRendered <= this.model.dataSource.lastUpdated) {
            this.model.config.lastRendered = new Date().getTime();
            updateFunction.call(this);
        }
    },
};
