import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import { AppStatusChip } from "../../app-tile/components/app-status-indicators";
import Menu from "../menu-dropdown";

import { StriimDataTable } from "@striim/striim-ui";
import useStores from "src/utils/use-stores";
import { AppAction } from "src/status-management";
import useEnteredScreen from "src/hooks/useEnteredScreen";
import SourceTargetLoader from "../../../../../../../stores/sources-targets-loader";
import SourceTargetList from "src/source-target-list/source-target-list";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import statusManagement from "src/status-management";
import { lagFormatter, rateFormatter, getLagInt } from "../helpers/app-list-helpers";
import { observer } from "mobx-react-lite";
import AppListService from "../../../app-list-service";
import constants from "./columns.json";
import { Box } from "@mui/material";

const styles = {
    cardHeaderTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        cursor: "pointer",
        maxWidth: 140,
        color: theme => theme.palette.secondary[500]
    }
};

const DataTable = observer(
    ({
        filteredApps,
        selectedGroup,
        setSelectedApps,
        clearSelections,
        setClearSelections,
        editColumnsModalSubmitted,
        dropApplication
    }) => {
        const gridRef = useRef();
        const gridColumnApiRef = useRef();
        const [rowData, setRowData] = useState([]);
        const ref = useRef();
        const isVisible = useEnteredScreen(ref);
        const appActionRef = useRef();
        const [previousStatus, setPreviousStatus] = useState("");
        const { store } = useStores();

        //update necessary values for initial_column_state in columns.json if values are changed below
        const initialColumnDefs = [
            {
                colId: constants.app_name_col_id,
                headerName: constants.app_name_col_id,
                valueGetter: params => params.data.name.toLowerCase(),
                lockPinned: true,
                headerCheckboxSelection: true,
                checkboxSelection: params => {
                    return !!params.data;
                },
                cellRenderer: "nameRenderer",
                suppressMovable: true,
                lockPosition: true,
                sortable: true,
                minWidth: 120,
                width: 280,
                resizable: true,
                hide: false
            },
            {
                colId: constants.flow_status_col_id,
                headerName: constants.flow_status_col_id,
                valueGetter: params => params.data.flowStatus,
                lockPinned: true,
                cellRenderer: "statusRenderer",
                sortable: true,
                headerClass: "header-center",
                cellClass: "cell-center",
                suppressMovable: true,
                lockPosition: true,
                minWidth: 110,
                width: 110,
                resizable: true,
                hide: false
            },
            {
                colId: constants.namespace_col_id,
                headerName: constants.namespace_col_id,
                valueGetter: params => params.data.nsName.toLowerCase(),
                lockPinned: true,
                sortable: true,
                flex: 1,
                minWidth: 100,
                resizable: true,
                hide: false
            },
            {
                colId: constants.reader_rate_col_id,
                headerName: constants.reader_rate_col_id,
                valueGetter: params => params.data?.mostRecentData?.["source-rate"],
                lockPinned: true,
                cellRenderer: "readerRateRenderer",
                headerClass: "header-right",
                cellClass: "cell-right",
                sortable: true,
                minWidth: 100,
                flex: 1,
                resizable: true,
                hide: false
            },
            {
                colId: constants.writer_rate_col_id,
                headerName: constants.writer_rate_col_id,
                valueGetter: params => params.data?.mostRecentData?.["target-rate"],
                lockPinned: true,
                cellRenderer: "writerRateRenderer",
                headerClass: "header-right",
                cellClass: "cell-right",
                sortable: true,
                minWidth: 100,
                flex: 1,
                resizable: true,
                hide: false
            },
            {
                colId: constants.lag_col_id,
                headerName: constants.lag_col_id,
                valueGetter: params => getLagInt(params.data?.mostRecentData?.["lag-rate"]),
                lockPinned: true,
                cellRenderer: "lagRenderer",
                headerClass: "header-right",
                cellClass: "cell-right",
                sortable: true,
                minWidth: 100,
                flex: 1,
                resizable: true,
                hide: false
            },
            {
                colId: constants.source_target_col_id,
                headerName: constants.source_target_col_id,
                lockPinned: true,
                sortable: false,
                headerClass: "header-center no-hover",
                cellClass: "cell-center",
                cellRenderer: "sourceTargetRenderer",
                minWidth: 180,
                flex: 1,
                resizable: true,
                hide: false
            },
            {
                colId: constants.menu_col_id,
                headerName: "",
                lockPinned: true,
                cellRenderer: "menuRenderer",
                cellRendererParams: params => ({
                    app: params.data,
                    onAppAction,
                    applicationGroupsDisabled: store.applicationGroupsDisabled,
                    dropApplication
                }),
                sortable: false,
                cellClass: "cell-center",
                width: 65,
                resizable: true,
                hide: false
            }
        ];

        const [columnDefs, setColumnDefs] = useState(initialColumnDefs);

        useEffect(() => {
            setRowData(filteredApps);
        }, [selectedGroup, filteredApps]);

        useEffect(() => {
            if (clearSelections && gridRef.current && gridRef.current.api) {
                gridRef.current.api.deselectAll();
            }
        }, [clearSelections]);

        useEffect(() => {
            store.listenToProgressBarChange();
            () => {
                store.disposeListeners();
            };
        }, [store, filteredApps]);

        const onAppAction = action => {
            appActionRef.current = action;
        };

        const Name = ({ data: app }) => (
            <Box
                component="span"
                sx={styles.cardHeaderTitle}
                data-test-id={"apptitle--" + app.nsName + "." + app.name}
                onClick={() => store.onAppAction(app, AppAction.VIEW)}
            >
                {app.name}
            </Box>
        );
        const ReaderRate = observer(({ data: app }) => {
            const { flowStatus } = app;
            return <span>{rateFormatter(app?.mostRecentData?.["source-rate"], flowStatus)}</span>;
        });
        const WriterRate = observer(({ data: app }) => {
            const { flowStatus } = app;
            return <span>{rateFormatter(app?.mostRecentData?.["target-rate"], flowStatus)}</span>;
        });

        const Lag = observer(({ data: app }) => <span>{lagFormatter(app?.mostRecentData?.["lag-rate"])}</span>);

        const Status = observer(({ data: app }) => {
            const { flowStatus } = app;
            const appStatusLabel = statusManagement.getStatusChipDisplayName(
                flowStatus,
                appActionRef.current,
                previousStatus
            );
            useEffect(() => {
                if (isVisible) {
                    const loader = new SourceTargetLoader();
                    loader.loadForApp(app).then(() => {
                        app.loadedSourcesAndTargets = true;
                    });
                    const fetchPreviousStatus = async () => {
                        const appModel = await metaStoreService.findById(app.id);
                        if (!appModel) return;
                        setPreviousStatus(flowStatus);
                    };
                    fetchPreviousStatus();
                }
            }, [isVisible]);
            return <AppStatusChip label={appStatusLabel} />;
        });

        const SourceTarget = observer(({ data: app }) => (
            <SourceTargetList
                sources={app?.sources}
                targets={app?.targets}
                appId={app?.id}
                maxSources={1}
                maxTargets={1}
                iconSize={24}
            />
        ));

        const components = {
            nameRenderer: Name,
            statusRenderer: Status,
            menuRenderer: Menu,
            sourceTargetRenderer: SourceTarget,
            readerRateRenderer: ReaderRate,
            writerRateRenderer: WriterRate,
            lagRenderer: Lag
        };

        const onSelectionChanged = useCallback(() => {
            const selectedRows = gridRef.current.api.getSelectedRows();
            if (selectedRows.length > 0) {
                setClearSelections(false);
            }
            setSelectedApps(selectedRows);
        }, []);

        const onGridReady = params => {
            gridColumnApiRef.current = params.columnApi;
            const colState = AppListService.loadColumnState();
            if (!colState) {
                saveColumns();
            }
        };

        const loadSavedColumns = () => {
            const colArr = AppListService.loadColumnState();
            let updatedColumnDefs = [];
            if (colArr) {
                colArr.forEach(colState => {
                    const column = initialColumnDefs.find(colDef => colDef.colId === colState.colId);
                    const { sort, hide, width, flex } = colState;
                    if (column) {
                        const updatedColumn = { ...column, sort, hide, width, flex };
                        updatedColumnDefs.push(updatedColumn);
                    }
                });
            } else {
                updatedColumnDefs = initialColumnDefs;
            }
            setColumnDefs(updatedColumnDefs);
        };
        useEffect(() => {
            loadSavedColumns();
        }, [editColumnsModalSubmitted]);

        const saveColumns = () => {
            if (!gridColumnApiRef || !gridColumnApiRef.current) return;
            const colArr = gridColumnApiRef.current.getColumnState();
            AppListService.saveColumnState(colArr);
        };

        const handleColumnResize = ({ finished }) => {
            if (finished) {
                saveColumns();
            }
        };

        return (
            <div ref={ref} style={{ marginTop: 16 }}>
                <StriimDataTable
                    components={components}
                    onGridReady={onGridReady}
                    data={rowData}
                    columns={columnDefs}
                    onSelectionChanged={onSelectionChanged}
                    animateRows="true"
                    sizeColumnsToFit
                    enableCellTextSelection
                    rowSelection={"multiple"}
                    ref={gridRef}
                    onSortChanged={saveColumns}
                    onColumnMoved={saveColumns}
                    onColumnVisible={saveColumns}
                    onColumnResized={e => handleColumnResize(e)}
                    onRowSelect={selectedRows => selectedRows}
                    maxHeight={714}
                />
            </div>
        );
    }
);

DataTable.propTypes = {
    store: PropTypes.object,
    groupsStore: PropTypes.object
};

export default DataTable;
