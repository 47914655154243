import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import styles from "./create-app-page-adapter-selection-layout.styles";
import CreateAppPageAdaptersFilter from "../adapters-filter/create-app-page-adapters-filters";
import CreateAppPageAdaptersList from "../adapters-list/create-app-page-adapters-list";

interface SelectionLayoutProps {
    type: string;
    onClose: Function;
    handleSelection: Function;
    adapterList: Array<any>;
    isFirstTimeUser: boolean;
}
export type filtersType = {
    id: string;
    category: string;
};
const CreateAppPageAdapterSelectionLayout: React.FunctionComponent<SelectionLayoutProps> = ({
    type,
    onClose,
    handleSelection,
    adapterList,
    isFirstTimeUser
}) => {
    const [filters, setFilters] = useState<{ id: string; category: string }>(
        { id: "", category: "all" } // defaulting to "All"
    );
    const [selectedView, setSelectedView] = useState<"list" | "grid">("grid");
    const [filteredAdapters, setFilteredAdapters] = useState(adapterList);
    const [isAscendingSort, setIsAscendingSort] = useState<boolean>(true);

    const getCategoryFilter = adapter => {
        if (filters.category === "all") return true;
        if (filters.category === "new") {
            return adapter.newlyReleased;
        }
        return adapter.category?.toLowerCase() === filters.category?.toLowerCase();
    };

    useEffect(() => {
        if (filters.id || filters.category !== "all") {
            const filteredData = adapterList.filter(adapter => {
                const searchFilter = adapter.id.toLowerCase().includes(filters.id.toLowerCase());
                const categoryFilter = getCategoryFilter(adapter);
                return searchFilter && categoryFilter;
            });
            setFilteredAdapters(filteredData);
        } else {
            setFilteredAdapters(adapterList);
        }
    }, [filters]);

    return (
        <Grid container sx={styles.wrapper}>
            <Grid item sx={styles.headerContainer}>
                <CreateAppPageAdaptersFilter
                    selectedView={selectedView}
                    setSelectedView={setSelectedView}
                    type={type}
                    filters={filters}
                    setFilters={setFilters}
                    onClose={onClose}
                    isAscendingSort={isAscendingSort}
                    setIsAscendingSort={setIsAscendingSort}
                />
            </Grid>
            <Grid item sx={styles.childContainer}>
                <CreateAppPageAdaptersList
                    selectedView={selectedView}
                    adapters={filteredAdapters}
                    handleSelection={handleSelection}
                    type={type}
                    isAscendingSort={isAscendingSort}
                    filters={filters}
                />
            </Grid>
        </Grid>
    );
};

export default CreateAppPageAdapterSelectionLayout;
