export const styles = {
    chip: {
        height: 18,
        px: 0.5,
        borderRadius: "6px",
        color: "#7675DF",
        backgroundColor: "#F0F3FD"
    },
    dropdownChip: {
        height: 18,
        px: 0.5,
        borderRadius: "6px",
        color: "greyscale.700",
        backgroundColor: "greyscale.100",
        display: 'flex',
        alignItems: 'center',
    },
    chevronIcon: {
        fontSize: '8px !important',
        fill: 'none',
        "& > path": {
            fill: ({ palette }) => palette.greyscale[800],
        }
    },
    endAdornmentContainer: {
        backgroundColor: "transparent",
        color: "greyscale.800",
        border: "none",
        padding: 0.5,
        "&:hover, &:active": {
            backgroundColor: "transparent",
            border: "none"
        }
    },
    iconButton: {
        width: 20,
        height: 20
    },
    searchInput: {
        width: '290px'
    },
    filter: {
        width: "250px"
    },
    infoIcon: {
        height: 16,
        width: 16,
        fill: "transparent",
        "& path": {
            stroke: theme => theme.palette.greyscale[600]
        }
    },
}