import Backbone from "backbone";
import { View } from "./base-database-reader";
import { DB_PROVIDERS } from "./configs/db-providers";
class OracleDatabaseReader extends View {
    setup() {
        this.model.get("connectionParams").set("type", DB_PROVIDERS.POSTGRES.id);
    }
}

export default {
    View: OracleDatabaseReader,
    vent: new Backbone.Model()
};
