import App from "app";
import api from "core/api/api";
import queryVisualizationParser from "./queryVisualizationParser";
import metaobjectSanitizer from "./metaobjectSanitizer";
import queryService from "core/services/dataService/queryService";
import "./themes";
import "app/components/dashboard/grid/grid";
import "./defaultmetaobject";
import "core/services/appservice/query_manager/models/datasource";
import NestedTypes from "backbone.nestedtypes";

const metadataService = App.module("Metadata.Queryvisualizations", function (
    Queryvisualizations,
    App,
    Backbone,
    Marionette,
    $,
    _
) {
    Queryvisualizations.addInitializer(function () {
        App.reqres.setHandler("new:metadata:queryvisualization", Queryvisualizations.create);
        App.reqres.setHandler("clone:metadata:queryvisualization", Queryvisualizations.cloneVisualization);
        App.reqres.setHandler("metadata:queryvisualization", Queryvisualizations.read);
    });

    Queryvisualizations.Model = App.Metadata.DefaultMetaObject.Model.extend({
        defaults: {
            type: "QUERYVISUALIZATION",
            title: String,
            ctime: null,
            isEditable: null,
            visualizationType: "",
            forceRender: Boolean,
            _polling: true,
            query: queryService.WAQueryModel,
            config: NestedTypes.options({
                type: NestedTypes.Model,
                value: null, // Will be generated with TypeSpecParser
            }),
            dataSource: App.QueryManager.Models.DataSourceModel,
            theme: NestedTypes.options({
                value: App.Metadata.Themes.WebActionLight,
                parse: function (id) {
                    return App.Metadata.Themes[id];
                },
            }),
        },
        initialize: function () {
            App.Dashboard.Grid.Component.Model.prototype.initialize.apply(this, arguments);

            this.listenTo(this, "query:filter", function (params) {
                this.query.trigger("query:filter", params);
            });
        },
        sync: function (method) {
            var $deferred = new $.Deferred();

            var that = this;

            var nsName = this.get("nsName");
            var full_id = nsName + ".QUERYVISUALIZATION." + this.get("name");
            var api_method = method.toUpperCase();
            var json_data = metaobjectSanitizer.sanitize(this.toJSON());
            json_data.id = full_id;
            json_data.type = "QUERYVISUALIZATION";

            var api_promise = api._CRUDHandler(undefined, api_method, json_data);
            api_promise
                .then(function(result) {
                    var parsedObject = queryVisualizationParser.parse(result[0], nsName);
                    that.set(parsedObject);
                    $deferred.resolve(that);
                })
                .fail(function (e) {
                    $deferred.reject(e);
                });

            return $deferred;
        },
    });

    Queryvisualizations.QueryvisualizationsCollection = NestedTypes.Collection.extend({
        model: Queryvisualizations.Model,
        sync: function (method) {
            var $deferred = new $.Deferred();
            var that = this;

            var api_method = method.toUpperCase();
            var json_data = {};
            json_data.type = "QUERYVISUALIZATION";

            var api_promise = api._CRUDHandler(undefined, api_method, json_data);
            api_promise
                .then(function(data) {
                    _.each(data, function (queryViz) {
                        var model = new Queryvisualizations.Model(queryViz);
                        that.add(model);
                    });
                    $deferred.resolve(that);
                })
                .fail(function (e) {
                    $deferred.reject(e);
                });

            return $deferred;
        },
    });

    Queryvisualizations.create = function () {
        return new Queryvisualizations.Model();
    };

    Queryvisualizations.cloneVisualization = function (originalVisualizationJson) {
        return new Queryvisualizations.Model(originalVisualizationJson);
    };

    Queryvisualizations.read = function (queryvisualizationid) {
        var queryvisualization = new Queryvisualizations.Model({
            id: queryvisualizationid,
        });

        var deferred = $.Deferred();
        queryvisualization
            .fetch()
            .then(function(data, textStatus, jqXHR) {
                deferred.resolve(queryvisualization, data, textStatus, jqXHR);
            })
            .fail(deferred.reject);
        return deferred.promise();
    };
});

export default metadataService;
