let logger = {};

export const COLORS={
    NETWORK_RES_OK: "#1b5e20",
    NETWORK_RES_FAIL: "#C62828",
    NETWORK_RES_WARN: "#ff8f00",
    NETWORK_REQ: "#39abe0"
}

export function log(tag, tagColor , message, payload){
    let props = [];
    props.push(`%c ${tag} %c ${message}`)
    props.push( `color: white; background-color: ${tagColor} `)
    props.push( ``) // for message
    if(payload){
        props.push( payload)
    }
    console.log.apply(null,props);
}

export default {
    COLORS: COLORS,
    log: log
}
