import api from "core/api/api";
import _ from "underscore";
import {
    template,
    className,
    regions,
    childView,
    childViewContainer,
    on,
    modelEvent,
    emptyView
} from "core/decorators";
import { LayoutView, ItemView, CompositeView } from "marionette";
import App from "app";
import Backbone from "backbone";
import TableSelectionTemplate from "./templates/table-selection-view.html";
import TableTemplate from "./templates/table-selection.template.html";
import sourceTQLTemplate from "text-loader!./templates/source.template.tql";
import oracleCDC_TQLTemplate from "text-loader!./templates/oracle_cdc.template.tql";
import SQLServerCDC_TQLTemplate from "text-loader!./templates/sqlserver_cdc.template.tql";
import MsJet_TQLTemplate from "text-loader!./templates/msjet_cdc.template.tql";
import OJet_TQLTemplate from "text-loader!./templates/ojet_cdc.template.tql";
import OJet_Downstream_TQLTemplate from "text-loader!./templates/ojet_cdc_downstream.template.tql";
import BigQueryILTQLTemplate from "text-loader!./templates/bigQuery-il.template.tql";
import mysql_TQLTemplate from "text-loader!./templates/mysql_cdc.template.tql";
import mariadbxpand_TQLTemplate from "text-loader!./templates/mariadbxpand.template.tql";
import mariadb_TQLTemplate from "text-loader!./templates/mariadb.template.tql";
import postgress_TQLTemplate from "text-loader!./templates/postgres_cdc.template.tql";
import snowflake_il_template from "text-loader!./templates/snowflake_il.template.tql";
import mongodb_cdc_template from "text-loader!./templates/mongdb_cdc.template.tql";
import mongodb_cosmos_template from "text-loader!./templates/mongodb_cosmos.template.tql";
import snowflake_cdc_TQLTemplate from "text-loader!./templates/snowflake_cdc.template.tql";
import cosmos_TQLTemplate from "text-loader!./templates/cosmos.template.tql";
import yugabye_cdc_TQL_Template from "text-loader!./templates/yugabyte.template.tql";
import masterDetailView from "app/components/common/master-detail-view/master-detail-view";
import { DB_PROVIDERS } from "./configs/db-providers";
import growl from "app/components/common/growl";
import getTableNameWithCorrectDots from "./table-name-validator";
import ensureRegions from "../../../../../common/_ensureRegions";
import NProgress from "nprogress";
import MetaObjects from "../../../../../../../core/services/metaStoreService/meta-objects";
import utils from "../../../../../../../core/utils";
import { computeSSLConfig, getConnectionUrl, getDownStreamConnectionURL } from "src/modules/wizards/services/index.ts";
import { getCDCName, isAutomatedWizard, moveToNewGroup } from "./app-services";
import { getParams } from "../../../../../../../src/modules/wizards/services";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import InlineHelpMarionetteContainer from "../inline-help-container";
import exceptionsStoreApi from "app/components/flow/designer/app-exceptions/exception-store-api";

NProgress.configure({
    minimum: 0.25,
    trickleRate: 0.1,
    trickleSpeed: 800
});

@template(TableTemplate)
class TableItemView extends ItemView {
    serializeData() {
        let data = this.model.toJSON();
        data["disabledText"] = "";
        data["checkedText"] = "";
        if (!this.model.get("isValid")) {
            data["disabledText"] = "disabled";
        }
        if (this.model.get("isSelected")) {
            data["checkedText"] = "checked";
        }
        return data;
    }

    @modelEvent("change")
    onModelChanged() {
        this.model.collection.trigger("model:changed");
    }

    @on("change input")
    onTableSelectionChange() {
        let checked = false;
        if (this.$el.find("input").is(":checked")) {
            checked = true;
        }
        this.model.set("isSelected", checked);
    }

    // Convert list of error messages to display in HTML
    getErrorsList(errors) {
        return `<div>${errors.violations
            .map((msg, index) => `${index + 1}. ${msg.errorDescription}</br>`)
            .join("")}</div>`;
    }
    onRender() {
        if (!this.model.get("isValid")) {
            const errors = this.model.get("errors");

            const message = errors
                ? this.getErrorsList(errors)
                : "Table cannot be moved due to incompatible Datatypes.";

            this.$el.find("label").tooltipster({
                interactive: false,
                content: message,
                delay: 200,
                contentAsHTML: true,
                animation: "grow",
                position: "right"
            });
        }
    }
}

@template('<div class="mt-50px"> Sorry, could not find any tables. </div>')
class tbEmptyView extends ItemView {}

@template(`<% if(!listEmpty){ %><h3 class="wizard-subtitle">Please select the list of {{whatIsTable}}s to load from {{schemaDisplayName || schemaName}}</h3>
           <h4 class="mb-16px mt-24px"><label> <input type="checkbox" id="select-all" class="filled-in" /> <span class="p4Label">Select All</span> </label></h4> <% } %>
           <div class="all-tables-list"></div>`)
@childViewContainer("div")
@childView(TableItemView)
@emptyView(tbEmptyView)
class TableSelectionCollectionView extends CompositeView {
    onShow() {
        this.delayedUpdate();
        this.collection.on("model:changed", () => {
            this.delayedUpdate();
        });
    }

    serializeData() {
        let data = this.model.toJSON();
        let model = App.reqres.request("get:wizard:model");
        data["whatIsTable"] = model.get("whatIsTable");
        data["listEmpty"] = this.collection.length === 0;
        return data;
    }

    delayedUpdate() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.setSelectAllstate();
        }, 100);
    }
    setSelectAllstate() {
        let firstSelected = this.collection.findWhere({ isSelected: true });
        let firstUnselected = this.collection.filter(item => item.get("isValid")).some(item => !item.get("isSelected"));
        if (firstSelected && firstUnselected) {
            this.$el.find("#select-all").prop("indeterminate", true);
        } else if (firstSelected && !firstUnselected) {
            this.$el.find("#select-all").prop("indeterminate", false);
            this.$el.find("#select-all").prop("checked", true);
        } else if (!firstSelected && firstUnselected) {
            this.$el.find("#select-all").prop("indeterminate", false);
            this.$el.find("#select-all").prop("checked", false);
        }
    }

    @on("change #select-all")
    onAllSelectChange() {
        let checked = false;
        if (this.$el.find("#select-all").is(":checked")) {
            checked = true;
        }
        let intermediate = false;
        this.collection.each(function(model) {
            if (!model.get("isValid")) {
                intermediate = true;
            } else {
                model.set("isSelected", checked);
            }
        });
        this.$el.find(".all-tables-list input:enabled").prop("checked", checked);
    }
}

const MasterListItemView = masterDetailView.MasterListItemView.extend({
    initialize() {
        this.wizardModel = App.reqres.request("get:wizard:model");
    },
    template: _.template(`
                     <div style="flex-grow:1"><label><span class="p4Label">{{schemaDisplayName || schemaName}}</span></spanL></label></div>
                    <div></div>
                    `),
    onRender: function() {
        this.delayedUpdate();
        masterDetailView.MasterListItemView.prototype.onRender.apply(this, arguments);
    },

    delayedUpdate() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.setSelectAllstate();
        }, 100);
    },

    onShow() {
        this.model.get("tables").on("model:changed", () => {
            this.delayedUpdate();
        });
    },

    // Returns true if any table is selected from all selected schemas
    isTableSelected: function() {
        return this.wizardModel
            .get("schemas")
            .some(schema => schema.get("isEnabled") && schema.get("tables").where({ isSelected: true }).length > 0);
    },

    setSelectAllstate: function() {
        let firstSelected = this.model.get("tables").findWhere({ isSelected: true });
        let firstUnselected = this.model.get("tables").findWhere({ isSelected: false });
        if (firstSelected && firstUnselected) {
            this.$el.find("input").prop("indeterminate", true);
            App.vent.trigger("appwizard:next:enable");
        } else if (firstSelected && !firstUnselected) {
            this.$el.find("input").prop("indeterminate", false);
            this.$el.find("input").prop("checked", true);
            App.vent.trigger("appwizard:next:enable");
        } else if (!firstSelected && firstUnselected) {
            this.$el.find("input").prop("indeterminate", false);
            this.$el.find("input").prop("checked", false);
            if (!this.isTableSelected()) {
                App.vent.trigger("appwizard:next:disable");
            }
        }
    }
});

const MasterCollectionView = masterDetailView.MasterCollectionView.extend({
    childView: MasterListItemView
});

@template("No stats available.")
class EmptyView extends ItemView {}

const MasterLayoutView = masterDetailView.MasterLayoutView.extend({
    className: "stream-list-master-view",
    template: _.template('<div class="master-collection-view collection"></div>'),
    regions: {
        masterCollectionView: ".master-collection-view"
    },
    initialize: function() {
        let model = App.reqres.request("get:wizard:model");
        this.model = model;
    },
    onRender: function() {
        ensureRegions(this);
        let allowedSchemas = this.model.get("schemas").where({ isEnabled: true });
        this.getRegion("masterCollectionView").show(
            new MasterCollectionView({
                collection: new Backbone.Collection(allowedSchemas)
            })
        );
        masterDetailView.MasterLayoutView.prototype.onRender.apply(this);
    }
});

@template("<div> hi yo </div>")
@regions({
    tableSelection: "div"
})
@className("detail-view-container")
class DetailLayoutView extends masterDetailView.DetailLayoutView {
    initialize() {
        let model = App.reqres.request("get:wizard:model");
    }

    onRender() {
        ensureRegions(this);
        this.getRegion("tableSelection").show(
            new TableSelectionCollectionView({
                model: this.model,
                collection: this.model.get("tables")
            })
        );
    }
}

@className("dbreader-table-selection card")
@template(TableSelectionTemplate)
@regions({
    masterDetailContainer: ".table-selection-container"
})
export default class TableSelectionView extends InlineHelpMarionetteContainer {
    initialize() {
        let model = App.reqres.request("get:wizard:model");
        this.model = model;
        App.vent.trigger("appwizard:next:disable");
        super.initialize("select-tables");
    }

    onRender() {
        let mater_detail_view = masterDetailView.create({
            MasterLayoutView: MasterLayoutView,
            DetailLayoutView: DetailLayoutView,
            emptyDetailMessage: "Select a stream from the left panel to start mapping"
        });
        this.getRegion("masterDetailContainer").show(mater_detail_view);
    }

    async canMoveForward() {
        return new Promise(async (resolve, reject) => {
            App.vent.trigger("appwizard:next:disable");
            try {
                await this.run();
                // For Automated Wizards
                if (isAutomatedWizard()) {
                    // Create or Fetch the CDC app
                    let CDCApp;
                    const name = this.model.get("appName");
                    const namespace = utils.getNamespace(name);
                    const CDCName = getCDCName(utils.getName(name));

                    const appId = MetaObjectConverter.getId(namespace, "APPLICATION", CDCName);
                    CDCApp = await metaStoreService.findById(appId);
                    if (!CDCApp) {
                        CDCApp = new MetaObjects.APPLICATION.Model({
                            name: CDCName,
                            nsName: namespace
                        });
                    }
                    await CDCApp.save();
                    //enable Exception store
                    await exceptionsStoreApi.turnOn(appId);
                    await this.run(CDCName, true);
                    // Move IL and CDC apps to a new group
                    await moveToNewGroup(name, CDCName);
                }
                resolve();
            } catch (e) {
                growl.error(e, "Error creating Source");
                reject(e);
            } finally {
                App.vent.trigger("appwizard:next:enable");
            }
        });
    }
    async setStartPosition(model) {
        return new Promise(async (resolve, reject) => {
            try {
                const databaseType = model.get("type");
                const agentName = model.get("agentName");
                const isDownStreamEnabled = this.model.get("connectionParams").get("supportDownStreamCapture");
                const params = await getParams(databaseType, this.model.get("connectionParams"), isDownStreamEnabled);
                let res;
                if (databaseType === DB_PROVIDERS.ORACLE.id) {
                    res = await api.OraclegetStartPosition(params, agentName);
                } else if (databaseType === DB_PROVIDERS.OJET.id) {
                    res = await api.OjetgetStartPosition(params, agentName);
                } else if (databaseType === DB_PROVIDERS.MYSQL.id) {
                    res = await api.MySQLgetStartPosition(params, agentName);
                } else if (databaseType === DB_PROVIDERS.MARIADB.id) {
                    res = await api.MariaDBgetStartPosition(params, agentName);
                } else if (databaseType === DB_PROVIDERS.MARIADBXPAND.id) {
                    res = await api.MariaDBXpandgetStartPosition(params, agentName);
                } else if (databaseType === DB_PROVIDERS.POSTGRES.id) {
                    res = await api.PostgresgetStartPosition(params, agentName);
                } else if (databaseType === DB_PROVIDERS.SQLSERVER.id || databaseType === DB_PROVIDERS.MSJET.id) {
                    res = await api.MSSQLgetStartPosition(params, agentName);
                } else {
                    console.error("Unknown database type");
                }
                const startPosition = await JSON.parse(res);
                startPosition && model.set(startPosition);
                resolve();
            } catch (error) {
                growl.error("Error on setting start position: ", error);
                reject(error);
            }
        });
    }
    async run(forceAppName, forceIsCDC) {
        const appName = forceAppName || this.model.get("appName");
        let isCDC = forceIsCDC || this.model.get("isCDC");
        NProgress.start();
        let that = this;
        return new Promise(async function(resolve, reject) {
            try {
                let model = new Backbone.Model();
                let username = that.model.get("connectionParams").get("username");
                let password = that.model.get("connectionParams").get("password");
                let useConnectionProfile = that.model.get("connectionParams").get("useConnectionProfile");
                let connectionProfileName = that.model.get("connectionParams").get("connectionProfileName");
                let databaseType = that.model.get("connectionParams").get("type");
                let createSchema = that.model.get("connectionParams").get("createSchema");
                let agentName = that.model.get("connectionParams").get("agentName");

                const primaryDatabaseUsername = that.model.get("connectionParams").get("primaryDatabaseUsername") || "";
                const primaryDatabasePassword = that.model.get("connectionParams").get("primaryDatabasePassword") || "";
                const supportDownStreamCapture =
                    that.model.get("connectionParams").get("supportDownStreamCapture") || "";
                const downstreamCaptureMode = that.model.get("connectionParams").get("downstreamCaptureMode") || "";
                if (databaseType === DB_PROVIDERS.COSMOSDB.id) {
                    password = that.model.get("connectionParams").get("accessKey");
                }
                const isILCDC = isAutomatedWizard();
                model.set("isILCDC", isILCDC);
                let connectionURL;
                let sslConfig;
                model.set("createSchema", isILCDC ? true : createSchema);
                if (isILCDC) {
                    const connectionParams = that.model.get("connectionParams");
                    const adapterName = connectionParams.get("type");
                    // Get SSL ConnectionURL
                    connectionURL = await getConnectionUrl(adapterName, connectionParams);
                    sslConfig = computeSSLConfig(adapterName, connectionParams, false);

                    if (databaseType === DB_PROVIDERS.OJET.id) {
                        if (!forceIsCDC) {
                            isCDC = false;
                            databaseType = DB_PROVIDERS.ORACLE.id;
                            if (supportDownStreamCapture) {
                                username = primaryDatabaseUsername;
                                password = primaryDatabasePassword;
                                let downstreamConnectionURL = await getDownStreamConnectionURL(
                                    that.model.get("connectionParams")
                                );
                                connectionURL = downstreamConnectionURL;
                            }
                        } else {
                            connectionURL = await getConnectionUrl("OJETCDC", connectionParams);
                        }
                    } else if (databaseType === DB_PROVIDERS.MSJET.id && !forceIsCDC) {
                        isCDC = false;
                        databaseType = DB_PROVIDERS.SQLSERVER.id;
                    }
                    model.set("connectionURL", connectionURL);
                    model.set("sslConfig", sslConfig);
                } else {
                    model.set("sslConfig", null);
                    // For MySQL Initial Load, call getConnectionUrl
                    if (databaseType === DB_PROVIDERS.MYSQL.id && !isCDC) {
                        const connectionParams = that.model.get("connectionParams");
                        connectionURL = await getConnectionUrl(databaseType, connectionParams);
                    } else {
                        connectionURL = that.model.get("connectionParams").getJDBCURL();
                    }
                }
                let tables = [];
                that.model.get("schemas").each(schema => {
                    if (!schema.get("isEnabled")) {
                        return;
                    }
                    let selectedTables = schema.get("tables").where({ isSelected: true });
                    let tablesInSchema = _.map(selectedTables, selectedTable => {
                        return getTableNameWithCorrectDots(
                            selectedTable.get("fullTableName"),
                            that.model.get("connectionParams"),
                            isCDC
                        );
                    });
                    tables = _.union(tables, tablesInSchema);
                });

                api.getEscapedNames(tables).then(async escapedTables => {
                    if (
                        that.model.get("type") === DB_PROVIDERS.MONGODB.id ||
                        that.model.get("type") === DB_PROVIDERS.COSMOSDB.id ||
                        that.model.get("type") === DB_PROVIDERS.MONGO_COSMOSDB.id
                    ) {
                        tables = tables.join(",");
                    } else {
                        tables = escapedTables.join(";");
                    }

                    model.set("appName", appName);
                    model.set("type", databaseType);
                    model.set("username", username);
                    model.set("password", password);
                    model.set("connectionURL", connectionURL);
                    model.set("agentName", agentName);
                    model.set("useConnectionProfile", useConnectionProfile);
                    model.set("connectionProfileName", connectionProfileName);

                    if (isILCDC && isCDC) {
                        await that.setStartPosition(model);
                    }
                    if (databaseType === DB_PROVIDERS.OJET.id) {
                        const downstreamConnectionURL = await getDownStreamConnectionURL(
                            that.model.get("connectionParams")
                        );
                        model.set("primaryDatabasePassword", primaryDatabasePassword);
                        model.set("connectionURL", connectionURL.replace("thin", "oci"));
                        if (downstreamConnectionURL) {
                            model.set("downstreamConnectionURL", downstreamConnectionURL.replace("thin", "oci"));
                        } else {
                            model.set("downstreamConnectionURL", "");
                        }
                        model.set("downstreamCaptureMode", downstreamCaptureMode);
                        model.set("supportDownStreamCapture", supportDownStreamCapture || false);
                        model.set("primaryDatabaseUsername", primaryDatabaseUsername);
                    }
                    model.set("replicationSlot", that.model.get("connectionParams").get("replicationSlot"));
                    model.set("tables", tables);
                    model.set("dbName", that.model.get("connectionParams").get("dbName"));
                    model.set("isCDC", isCDC);
                    model.set("isPDB", that.model.get("connectionParams").get("isPDB"));
                    model.set("containerName", that.model.get("connectionParams").get("containerName"));

                    let templateFunction;

                    if (databaseType === DB_PROVIDERS.COSMOSDB.id) {
                        templateFunction = _.template(cosmos_TQLTemplate);
                    } else if (databaseType === DB_PROVIDERS.MONGO_COSMOSDB.id) {
                        templateFunction = _.template(mongodb_cosmos_template);
                    } else if (isCDC === false && databaseType !== DB_PROVIDERS.MONGODB.id) {
                        if (databaseType === DB_PROVIDERS.SNOWFLAKE.id) {
                            templateFunction = _.template(snowflake_il_template);
                        } else if (databaseType === DB_PROVIDERS.BIGQUERY.id) {
                            templateFunction = _.template(BigQueryILTQLTemplate);
                        } else {
                            if (databaseType === DB_PROVIDERS.YUGABYTE.id) {
                                model.set("type", "YugabyteDB");
                            }
                            templateFunction = _.template(sourceTQLTemplate);
                        }
                    } else if (databaseType === DB_PROVIDERS.ORACLE.id) {
                        templateFunction = _.template(oracleCDC_TQLTemplate);
                    } else if (databaseType === DB_PROVIDERS.OJET.id) {
                        if (that.model.get("connectionParams").isDownstreamInfoFilled()) {
                            templateFunction = _.template(OJet_Downstream_TQLTemplate);
                        } else {
                            templateFunction = _.template(OJet_TQLTemplate);
                        }
                    } else if (databaseType === DB_PROVIDERS.SQLSERVER.id) {
                        templateFunction = _.template(SQLServerCDC_TQLTemplate);
                    } else if (databaseType === DB_PROVIDERS.MYSQL.id) {
                        templateFunction = _.template(mysql_TQLTemplate);
                    } else if (databaseType === DB_PROVIDERS.MARIADBXPAND.id) {
                        templateFunction = _.template(mariadbxpand_TQLTemplate);
                    } else if (databaseType === DB_PROVIDERS.MARIADB.id) {
                        templateFunction = _.template(mariadb_TQLTemplate);
                    } else if (databaseType === DB_PROVIDERS.POSTGRES.id) {
                        templateFunction = _.template(postgress_TQLTemplate);
                    } else if (databaseType === DB_PROVIDERS.MSJET.id) {
                        templateFunction = _.template(MsJet_TQLTemplate);
                    } else if (databaseType === DB_PROVIDERS.MONGODB.id) {
                        templateFunction = _.template(mongodb_cdc_template);
                    } else if (databaseType === DB_PROVIDERS.SNOWFLAKE.id) {
                        templateFunction = _.template(snowflake_cdc_TQLTemplate);
                    } else if (databaseType === DB_PROVIDERS.YUGABYTE.id) {
                        templateFunction = _.template(yugabye_cdc_TQL_Template);
                    }
                    const str = templateFunction(model.toJSON());

                    api.compileTQL(str)
                        .done(function() {
                            NProgress.done();
                            resolve();
                        })
                        .fail(function(error) {
                            NProgress.done();
                            reject(error.message);
                        });
                });
            } catch (e) {
                reject(e);
            }
        });
    }
}
