import api from "core/api/api";
import _ from "underscore";
import { template, className, childView, childViewContainer, modelEvent } from "core/decorators";
import { LayoutView, CompositeView } from "marionette";
import App from "app";
import Backbone from "backbone";
import ConnectionValidationTemplate from "./templates/schema-validation-template.template.html";
import ConnectionValidationEntryTemplate from "./templates/connection-test-entry-template.html";
import * as Models from "./models";
import { DB_PROVIDERS } from "./configs/db-providers";
import { getParams } from "../../../../../../../src/modules/wizards/services";
import { isAutomatedWizard } from "./app-services";
import Tracker from "../../../../../../../core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../../../core/services/tracker/constants";
@template(ConnectionValidationEntryTemplate)
class ConnectionValidationEntry extends LayoutView {
    @modelEvent("change")
    modelChanged() {
        this.render();
    }

    onRender() {
        if (this.model.get("status") === "fail") {
            this.$el.find(".connection-remedy").slideDown("fast");
        }
    }
}

@className("connection-test-view card")
@template(ConnectionValidationTemplate)
@childViewContainer("#connection-test-container")
@childView(ConnectionValidationEntry)
export default class SchemaMigrationValidation extends CompositeView {
    initialize() {
        let model = App.reqres.request("get:wizard:model");
        App.vent.trigger("appwizard:next:disable");
        App.vent.trigger("appwizard:back:enable");
        this.model = model;
        this.failed = false;

        let selectedSchemas = this.model.get("schemas").where({ isEnabled: true });
        this.collection = new Backbone.Collection();
        const isValidationSupported = this.model.get("isValidationSupported") ?? false;
        _.each(selectedSchemas, selectedSchema => {
            this.collection.add({
                id: selectedSchema.get("id") + "::FETCH",
                status: "todo",
                text: "Fetching Metadata about " + selectedSchema.get("schemaDisplayName"),
                remedy: null,
                selectedSchema: selectedSchema
            });
            if (isValidationSupported) {
                this.collection.add({
                    id: selectedSchema.get("id") + "::VALIDATE",
                    status: "todo",
                    text: "Validating Tables in " + selectedSchema.get("id"),
                    remedy: null,
                    selectedSchema: selectedSchema
                });
            }
        });
        const type = this.model.get("type").toLowerCase();
        const ID = type + "-validate-schemas";
        super.initialize(ID);

        App.vent.on("appwizard:retry:schema-validation", () => {
            this.failed = false;
            this.proceedTo(0);
        });
        Tracker.getInstance().track(TRACKER_STRINGS.schema.schemaSelection, {
            event: "Wizards:Select Schemas",
            schemaCount: selectedSchemas.length,
            isAutomatedWizard: isAutomatedWizard()
        });
    }

    onRender() {
        this.failed = false;
        this.proceedTo(0);
    }
    onDestroy() {
        App.vent.off("appwizard:retry:schema-validation");
    }

    async proceedTo(id) {
        const currentPage = `validate-${this.model.get("whatsInside")?.toLowerCase()}`;
        App.vent.trigger("appwizard:retry:hide");
        App.vent.trigger("appwizard:next:disable", currentPage);
        let step = this.collection.at(id);
        if (!step) {
            if (this.failed) {
                App.vent.trigger("appwizard:retry:show");
                return;
            }
            App.vent.trigger("appwizard:retry:hide");
            if ($(".connection-test-view").length > 0) {
                App.vent.trigger("appwizard:goForward", currentPage);
            }
            return;
        }
        let selectedSchema = step.get("selectedSchema");
        try {
            if (step.get("id").indexOf("::FETCH") !== -1) {
                await this.fetchTables(selectedSchema);
                step.set("status", "done");
            } else if (step.get("id").indexOf("::VALIDATE") !== -1) {
                await this.checkTableCompatibility(selectedSchema);
                step.set("status", "done");
            }
        } catch (e) {
            step.set("remedy", "System Error: " + e);
            step.set("status", "fail");
            Tracker.getInstance().track(TRACKER_STRINGS.schema.error, {
                id: step.get("id"),
                event: "Error:Schema migration validation",
                message: e
            });
            this.failed = true;
        }
        id++;
        this.proceedTo(id);
    }

    async fetchTables(selectedSchema) {
        let type = this.model.get("type").toLowerCase();
        const username = this.model.get("connectionParams").get("username");
        let password = this.model.get("connectionParams").get("password");
        const wizardURL = this.model.get("connectionParams").getJDBCURL();
        let method = null;
        const agentName = this.model.get("connectionParams").get("agentName");

        return new Promise(async (resolve, reject) => {
            switch (type) {
                case "cosmosdb": {
                    password = this.model.get("connectionParams").get("accessKey");
                    method = api.cosmosdbGetTables(
                        null,
                        password,
                        wizardURL,
                        selectedSchema.get("schemaName"),
                        agentName
                    );
                    break;
                }
                case "snowflake": {
                    const connectionProfileName = this.model.get("connectionParams").get("connectionProfileName");
                    method = api.snowflakeReaderGetTables(
                        {
                            useConnectionProfile: true,
                            connectionProfileName: connectionProfileName
                        },
                        selectedSchema.get("schemaName") + ".%",
                        agentName
                    );
                    break;
                }
                case "bigquery": {
                    const connectionProfileName = this.model.get("connectionParams").get("connectionProfileName");
                    const useConnectionProfile = this.model.get("connectionParams").get("useConnectionProfile");
                    const connectionUrl = this.model.get("connectionParams").get("connectionUrl");
                    method = api.bigQuerygetDatasetTables(
                        {
                            useConnectionProfile: useConnectionProfile,
                            connectionProfileName: connectionProfileName,
                            ConnectionURL: connectionUrl
                        },
                        selectedSchema.get("schemaName") + ".%",
                        agentName
                    );
                    break;
                }

                default: {
                    const supportDownStreamCapture =
                        this.model.get("connectionParams").get("supportDownStreamCapture") ?? false;
                    let params;
                    if (this.model.get("type") == "OJET" && supportDownStreamCapture) {
                        params = await getParams(this.model.get("type"), this.model.get("connectionParams"), true);
                    } else {
                        params = await getParams(this.model.get("type"), this.model.get("connectionParams"));
                    }
                    type = type === "mongo_cosmosdb" ? "mongocosmos" : type;
                    method = api.getDatabaseTables(
                        type,
                        params,
                        selectedSchema.get("schemaName") + ".%",
                        agentName,
                        false,
                        null
                    );
                }
            }

            method.then(
                async data => {
                    data = JSON.parse(data);
                    let message = data.message;
                    if (data.result === true) {
                        let tables = JSON.parse(message);
                        this.prepareSchemasCollection(tables);
                        resolve();
                    } else {
                        reject("Problem fetching Tables for " + selectedSchema.get("schemaName"));
                    }
                },
                () => {
                    reject("Problem fetching Tables for " + selectedSchema.get("schemaName"));
                    this.failAllPending();
                }
            );
        });
    }

    async checkTableCompatibility(selectedSchema) {
        const type = this.model.get("type").toLowerCase();
        const params = await getParams(type, this.model.get("connectionParams"));
        let tableCollection = selectedSchema.get("tables");
        let tableString = null;
        if (type.toLowerCase() === "sqlserver" || type.toLowerCase() === "msjet") {
            tableString =
                this.model.get("connectionParams").get("dbName") + "." + selectedSchema.get("schemaName") + ".%";
        } else {
            tableString = selectedSchema.get("schemaName") + ".%";
        }
        return new Promise((resolve, reject) => {
            // Remove hard-code for target type to specify type based on selected wizard
            api.validateConvertSchema(type, params, this.model.getTargetType(), tableString).then(
                data => {
                    try {
                        let compatibility = data[selectedSchema.get("schemaName")];
                        if (!compatibility) {
                            return;
                        }
                        let successes = _.union(
                            compatibility["compatible"],
                            compatibility["compatibleWithIntelligence"]
                        );
                        _.each(successes, success => {
                            let tableName = success.tableName;
                            if (
                                this.model.get("type").toLowerCase() === "sqlserver" ||
                                this.model.get("type").toLowerCase() === "msjet"
                            ) {
                                tableName = this.model.get("connectionParams").get("dbName") + "." + tableName;
                            }
                            let model = tableCollection.findWhere({
                                id: tableName
                            });
                            model.set("isValid", true);
                        });

                        let fails = _.union(compatibility["incompatible"]);
                        _.each(fails, fail => {
                            let tableName = fail.tableName;
                            if (
                                this.model.get("type").toLowerCase() === "sqlserver" ||
                                this.model.get("type").toLowerCase() === "msjet"
                            ) {
                                tableName = this.model.get("connectionParams").get("dbName") + "." + tableName;
                            }
                            let model = tableCollection.findWhere({ id: tableName });
                            model.set("isValid", false);
                            // Stores table level errors
                            model.set("errors", fail);
                        });

                        resolve();
                    } catch (e) {
                        console.error(e);
                        Tracker.getInstance().track(TRACKER_STRINGS.schema.error, {
                            id: selectedSchema.get("schemaName"),
                            event: "Error:Validating tables",
                            message: e
                        });
                        reject("Problem validating tables for  " + selectedSchema.get("schemaName"));
                    }
                },
                f => {
                    reject("Problem validating tables for  " + selectedSchema.get("schemaName"));
                }
            );
        });
    }

    prepareSchemasCollection(tables) {
        let schemasCollection = this.model.get("schemas");
        let that = this;
        _.each(tables, function(table) {
            let chunks = table.split(".");
            let schema = null;
            if (
                ![DB_PROVIDERS.MONGODB.id, DB_PROVIDERS.MARIADBXPAND.id].includes(that.model.get("type")) &&
                chunks.length === 3
            ) {
                chunks.shift();
                schema = chunks.shift();
            } else {
                schema = chunks.shift();
            }
            let name = chunks.join(".");
            const fullTableName = table;
            const id = table;
            let isValid = true;
            if (that.model.get("type") === DB_PROVIDERS.MONGODB.id) {
                if (id.indexOf("system.keys") !== -1 || id.indexOf("system.sessions") !== -1) {
                    return;
                }
            }
            const tableModel = new Models.Table({ id: id, name: name, fullTableName: fullTableName, isValid });
            schemasCollection
                .findWhere({ id: schema })
                .get("tables")
                .add(tableModel);
        });
    }

    failAllPending() {
        let remainders = this.collection.where({ status: "todo" });
        _.each(remainders, model => {
            model.set("status", "fail");
        });
        App.vent.trigger("appwizard:retry:show");
    }

    canMoveForward() {
        let remainders = this.collection.where({ status: "todo" });
        return new Promise(function(resolve, reject) {
            if (remainders.length === 0) {
                resolve();
            } else reject();
        });
    }

    retry() {
        this.render();
    }
}
