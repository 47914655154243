import React, { useState } from "react";
import { StriimButton, StriimTable, StriimTypography } from "@striim/striim-ui";
import { Rule } from "src/generic/icon/customIcons";
import {
    AddPermissionsModal,
    applyCellRendererToColumns,
    EmptyTableInfo
} from "src/modules/apps/pages/user-management/common";
import { permissionEditTableColumns } from "../../constants";
import { Grid, useTheme } from "@mui/material";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";
import styles from "../../../common/table-element.styles";

const getActionsCell = (permission, revokedPermissions, setRevokedPermissions) => {
    if (permission.disabled) {
        return (
            <Grid container justifyContent="flex-start">
                <StriimButton variant="text" disabled sx={styles.buttonWrapper}>
                    Revoked
                </StriimButton>
            </Grid>
        );
    }

    const revoked = revokedPermissions.map(r => r.name).indexOf(permission.name) !== -1;

    return (
        <Grid container justifyContent="space-between">
            <StriimButton
                variant="text"
                onClick={() => {
                    setRevokedPermissions([...revokedPermissions, permission]);
                }}
                data-testid={`${permission.name}-revoke-button`}
                disabled={revoked}
                sx={styles.buttonWrapper}
            >
                {revoked ? "Revoked" : "Revoke"}
            </StriimButton>{" "}
            <StriimButton
                variant="text"
                onClick={() => {
                    setRevokedPermissions(revokedPermissions.filter(r => r.name !== permission.name));
                }}
                style={revoked ? {} : { display: `none` }}
                data-testid={`${permission.name}-undo-button`}
                sx={styles.buttonWrapper}
            >
                Undo
            </StriimButton>
        </Grid>
    );
};

const PermissionsTable = React.memo(
    ({
        userPermissions,
        isEditing,
        addedPermissions = [],
        setAddedPermissions,
        revokedPermissions = [],
        setRevokedPermissions,
        isEditable = true
    }) => {
        const { palette } = useTheme();

        const [addPermissionsModalVisible, setAddPermissionsModalVisible] = useState(false);

        const columnsWithRenderer = applyCellRendererToColumns(
            permissionEditTableColumns,
            revokedPermissions.map(x => x.id)
        );
        const columns = isEditing && isEditable ? columnsWithRenderer : columnsWithRenderer.slice(0, 4);
        let permissionsToShow = [...userPermissions.map(toPermissionsRow).concat(addedPermissions)];

        if (isEditing && isEditable) {
            permissionsToShow = permissionsToShow.map(permission => {
                const actions = getActionsCell(permission, revokedPermissions, setRevokedPermissions);
                return { ...permission, actions };
            });
        }

        return (
            <>
                <Grid container spacing={1}>
                    <Grid container item alignItems="center" justifyContent="space-between">
                        <StriimTypography variant="h3" color="primary.900">
                            Permissions
                        </StriimTypography>
                        {isEditing && isEditable && (
                            <StriimButton
                                variant="text"
                                onClick={() => setAddPermissionsModalVisible(true)}
                                data-testid="add-permission-button"
                                startIcon={<AddIcon />}
                            >
                                Add permission
                            </StriimButton>
                        )}
                    </Grid>
                    <Grid item md={12}>
                        <StriimTable
                            data={permissionsToShow}
                            columns={columns}
                            TableProps={{ "data-testid": "permissions-table" }}
                            TableHeaderCellProps={{
                                style: {
                                    backgroundColor: palette.greyscale[50]
                                }
                            }}
                            NoRowsComponent={
                                <EmptyTableInfo
                                    Icon={Rule}
                                    text={`You can start adding permissions for new user using the 'Add permission' button`}
                                />
                            }
                            indexedRow
                            TableBodyProps={{ seperated: true }}
                        />
                    </Grid>
                </Grid>
                {isEditing && isEditable && (
                    <AddPermissionsModal
                        visible={addPermissionsModalVisible}
                        onConfirm={newPermission => {
                            setAddedPermissions([...addedPermissions, toPermissionsRow(newPermission)]);
                            setAddPermissionsModalVisible(false);
                        }}
                        onCancel={() => {
                            setAddPermissionsModalVisible(false);
                        }}
                    />
                )}
            </>
        );
    }
);
PermissionsTable.displayName = "PermissionsTable";

export default PermissionsTable;

const toPermissionsRow = v => ({
    id: v.text,
    name: v.text,
    action: v.actions.join(", "),
    namespace: v.domains[0],
    objectName: v.objectNames[0],
    objectType: v.objectTypes.join(", "),
    ...v
});
