import React from "react";
import MultiValue from "src/generic/multi-value/multi-value";
import { useField } from "formik";
import FormikField from "src/generic/formik-field";

const FormikMultiSelectField = ({ name, label, placeholder, availableOptions, required = false }) => {
    const [, , { setTouched, setValue }] = useField(name);

    return (
        <FormikField
            required={required}
            name={name}
            label={label}
            placeholder={placeholder}
            select
            SelectProps={{
                components: {
                    IndicatorSeparator: () => null
                },
                options: availableOptions,
                isMulti: true,
                onChange: data => {
                    setTouched(true);

                    setValue(data || []);
                },
                onBlur: () => setTouched(true)
            }}
        />
    );
};

export default FormikMultiSelectField;
