import App from "app";
import WAHighChartsMixin from "app/components/dashboard/visualization/charts/columnrange/mixins/columnrange-highcharts";
import "app/components/dashboard/visualization/charts/chart";

App.module("Chart.ColumnRangeChart", function (ColumnRangeChart, App, Backbone, Marionette, $, _) {
    ColumnRangeChart.View = App.Chart.Default.ChartView.extend({
        initialize: function () {
            // Mixin the Highcharts column range chart
            _(this).extend(WAHighChartsMixin);

            // Call super initialize
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
        },
    });
});
