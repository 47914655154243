const sxClasses = {
    addSmallMargin: {
        marginTop: "4px !important"
    },
    righSideButton: {
        ".MuiIconButton-root":{
            left: 1
        }
    },
    uncapitalize: {
        textTransform: "unset"
    },
    noItemsMessage: {
        textAlign: "center"
    }
}
export default sxClasses