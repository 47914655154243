export const styles = {
    icon: {
        fill: "none",
        "& path": {
            fill: ({ palette }) => palette.greyscale[900]
        }
    },
    sentinelIcon: {
        fill: "none",
        "& > g": {
            stroke: ({ palette }) => palette.greyscale[900]
        }
    },
    insightsIcon: {
        cursor: "pointer",
        width: "37px",
        height: "24px",
        borderRadius: "4px",
        background:
            "linear-gradient(132deg, rgba(243, 23, 121, 0.80) 0.9%, rgba(121, 48, 217, 0.80) 66.21%, rgba(17, 0, 0, 0.80) 127.52%) padding-box, linear-gradient(to bottom right, #F31779,#7930D9, #51247a) border-box",
        border: "1px solid transparent"
    },
    guardianIcon: {
        width: "16px",
        height: "16px",
        fill: "none"
    },
    chevronIcon: {
        width: "8px",
        height: "8px",
        fill: "none",
        "& > path": {
            fill: "#FFF"
        }
    },
    verticalDivider: {
        borderLeft: ({ palette }) => `1px solid ${palette.greyscale[200]}`,
        height: ({ spacing }) => spacing(4)
    },
    dropdownList: {
        marginTop: ({ spacing }) => `${spacing(1)} !important`,
        width: "350px"
    },
    menu: {
        marginTop: ({ spacing }) => spacing(3)
    }
};
