import _ from "underscore";
import base from "./base";

var WithCategory = function () {};

WithCategory.prototype = _.extend(new base(), {
    maxSeries: 20,
    _addItems: function (xadded, data, fieldList, categoryField, lastUsedHash) {
        if (data.length === 0) {
            data.push({});
        }

        // Create a new objects to pass to the data visualization
        // date comes back as two arrays [fields], [datapoints]
        // The visualizations require data as property:value
        for (var i = 0; i < xadded.length; i++) {
            var newObject = this._transformData(xadded[i].data, fieldList);
            var categoryValue = newObject[categoryField];

            // If there is no entry here create a new array
            if (!data[0].hasOwnProperty(categoryValue)) {
                // Keep a count of the total series so we don't have the overhead of counting the keys
                // in the data map
                data[0][categoryValue] = [];
            }
            data[0][categoryValue].push(newObject);
            lastUsedHash[categoryValue] = new Date();
        }
        return data;
    },

    _evictItems: function (data, lastUsedHash) {
        var collection = _.pairs(lastUsedHash);
        collection = _.sortBy(collection, 1).reverse(); //descending order.
        collection = collection.slice(0, this.maxSeries);
        var keys = _.pluck(collection, 0);
        data[0] = _.pick(data[0], keys);
        // For each entry in the
        for (var key in data[0]) {
            if (data[0].hasOwnProperty(key)) {
                data[0][key] = this._evictSeriesData(data[0][key]);
            }
        }
        return data;
    },
    _evictSeriesData: function () {
        // protected
    },
});

export default WithCategory;
