import React from "react";
import { Navigate } from "react-router-dom";
import { StriimAlertsProvider } from "@striim/striim-ui";

import { isUserAdmin } from "../../../../../core/user-management/update.control";
import { UserManagementProvider } from "../../../../../core/user-management/user-management-context";
import USERS_ROUTES from "./routes.json";

export default function UserManagementRoot({ children }) {
    const isAdmin = isUserAdmin();
    if (!isAdmin) {
        return <Navigate to={{ pathname: USERS_ROUTES.usersUnauthorized }} />;
    }

    return (
        <StriimAlertsProvider maxAlertsVisible={2}>
            <UserManagementProvider>{children}</UserManagementProvider>
        </StriimAlertsProvider>
    );
}
