export const classes = {
    modalHeading: {
        margin: ({ spacing }) => spacing(0)
    },
    addAlertHeader: {
        alignItems: "end",
        justifyContent: "space-between"
    },
    heading: {
        marginTop: ({ spacing }) => spacing(1)
    },

    addAlertContent: {
        marginBottom: ({ spacing }) => spacing(1)
    },
    subHeading: {
        margin: ({ spacing }) => spacing(1, 0, 1, 0)
    },

    alertInputField: {
        width: "400px",
        padding: ({ spacing }) => spacing(0, 0, 1, 5)
    },

    errors: {
        color: "critical.main",
        fontWeight: "400",
        marginRight: "10px",
        fontSize: "12px"
    },
    progressBar: {
        position: "sticky",
        top: 0,
        zIndex: 1000,
        margin: ({ spacing }) => spacing(0, -3)
    },
    asterisk: {
        color: "critical.main",
        position: "relative",
        top: -2
    }
};