import Marionette from "marionette";

// Single-view pattern used for components so far (Work-in-progress)
export default Marionette.Controller.extend({
    initialize: function (opts) {
        opts || (opts = {});
        this.options = opts;
        if (opts.view) this.setView(opts.view);
        if (opts.region) this.show(opts.region);
    },
    setView: function (view) {
        this.view = view;
        view.controller = this;

        this.listenTo(this.view, "destroy", function () {
            this.view = null;
        });
        this.trigger("view-set", this.view);
    },
    getView: function () {
        return this.view;
    },
    show: function (region) {
        if (!region || !this.view) return;
        region.show(this.view);
    },
    close: function () {
        this.view.destroy();
    },
});
