import _ from "underscore";
import DebuggerConfig from "app/components/debug/debugger-config";
import api from "./api";

let connectionTryCount = 1;
const maxConnectionTryCount = 5;
const durationNextConnectionTry = 2000;

/*
 *  Utility functions (ported from Underscore)
 */
var defaults = function(obj) {
    if (!obj) {
        obj = {};
    }
    Array.prototype.slice.call(arguments, 1).forEach(function(source) {
        if (source) {
            for (var prop in source) {
                if (obj[prop] === void 0) {
                    obj[prop] = source[prop];
                }
            }
        }
    });
    return obj;
};

/*
 * RMIFramework Class
 */
var RMIFrameworkProto = {
    pingIntervalDuration: 60000,
    _triggerDisconnectEventEnabled: false,

    _inferWebSocketUrl: function(opts) {
        var noprotocol = location.href.substring(location.href.indexOf("://") + 3);
        var rmiFrameworkBaseUrl = opts.baseUrl || noprotocol.substring(0, noprotocol.indexOf("/"));

        var protocol = location.href.substring(0, location.href.indexOf("://"));
        var newProtocol = protocol === "https" ? "wss" : "ws";
        var wsUri = newProtocol + "://" + rmiFrameworkBaseUrl + "/rmiws/";

        return wsUri;
    },
    initialize: function(opts) {
        // Fill in defaults for opts and process opts
        opts = defaults(opts, {
            env: "production",
            wsurl: this._inferWebSocketUrl(opts)
        });
        this.options = opts;
        this.callbacks = [];
        this.resolvedCallbacks = [];
        this.pingInterval = null;
        this.env = this.options.env || null;
        this.dispatcher = this.options.dispatcher;
        api.env = this.env;
    },
    /*
     * Create a WebSocket connection by creating a new WebSocket to a given URL.
     * If a WebSocket is already open, a new one will not be created.
     */
    connect: function(connect_opts) {
        this._triggerDisconnectEventEnabled = true;

        connect_opts = connect_opts || {};

        if (this.ws && this.ws.readyState !== 3) {
            if (this.env === "dev") {
                console.log("Not reconnecting. An active WebSocket exists:", this.ws);
            }
            return;
        }

        if (this.env === "dev") {
            console.log("Attempting to connect....");
        }

        // If there is not already a WebSocket
        if (!this.ws) {
            var url = connect_opts.wsurl || this.options.wsurl;
            this._createNewWebSocket(url);
        } else if (this.env === "dev") {
            console.log("Using existing WebSocket...");
        }
        this._prepareWebSocket(this.ws);
    },
    /*
     * Create a brand new WebSocket instance to a given URL
     */
    _createNewWebSocket: function(url) {
        if (this.env === "dev") {
            console.log("Creating brand new WebSocket...");
        }
        if (url) {
            this.ws = new WebSocket(url);
            if (this.env === "dev") {
                console.log("Connected to", url, this.ws);
            }
        } else {
            return console.error("Error connecting: no WebSocket could be created.");
        }
        return this.ws;
    },
    /*
     * Configure a given WebSocket with RMIFramework hooks
     * For every WebSocket event (open, close, error, message), call the appropriate RMIFramework function,
     * as well as any existing hooks (though there should never be any)
     */
    _prepareWebSocket: function(ws) {
        var rmifw = this;
        var _onopen = ws.onopen;
        ws.onopen = function() {
            rmifw._onWebSocketOpen.apply(rmifw);
            if (_onopen) {
                _onopen.apply(this, arguments);
            }
        };
        var _onclose = ws.onclose;
        ws.onclose = function() {
            rmifw._onWebSocketClose.apply(rmifw);
            if (_onclose) {
                _onclose.apply(this, arguments);
            }
        };
        var _onerror = ws.onerror;
        ws.onerror = function(e) {
            if (rmifw.env === "dev") {
                console.warn("WebSocket error:", e);
            }
            if (_onerror) {
                _onerror.apply(this, arguments);
            }
        };
        var _onmessage = ws.onmessage;
        ws.onmessage = function(message) {
            if (_onmessage) {
                _onmessage.apply(this, arguments);
            }
            rmifw._onWebSocketMessage(message);
        };
    },

    _onWebSocketOpen: function() {
        if (this.env === "dev") {
            //console.log("WebSocket opened.");
        }
        this.dispatcher.trigger("onWebSocketOpen");
        api.isWSStarted = true;

        //Start pinging the server
        this._startPingPong();
        this.dispatcher.trigger("global:app:connected", connectionTryCount);
        connectionTryCount = 1;
    },
    _startPingPong: function() {
        if (!this.ws) {
            return;
        }
        var _this = this;
        this.pingInterval = window.setInterval(function() {
            _this._ping();
        }, this.pingIntervalDuration);
    },
    _ping: function() {
        // TODO: why we need this? For keep the connection?

        var method = {
            class: "this",
            method: "ping"
        };
        var params = [];

        this.call(method, params).then(() => {
            /*if (this.env === "dev") {
                console.log(r);
            }*/
        });
    },
    /*
     * Request an API call, and return a promise to the user
     * Arguments: { method (method name object), params (array of parameters for this method) }
     */
    call: function(method, parameters) {
        var _this = this;

        return new Promise((resolve, reject) => {
            if (!method) {
                _(function() {
                    reject(_this.createError("Cannot create RMI call. No method name was provided."));
                }).defer();
            }

            // Create RMIFW callbacks to call when this RMI method returns with a response
            // Add the callbacks and get the callbackIndex to send to the server along with our message
            var callbackIndex = this._createCallbacks(method, parameters, resolve, reject);

            // Try sending the message
            try {
                this._sendRMICall(method, parameters, callbackIndex);
            } catch (e) {
                _(function(e) {
                    reject(e);
                }).defer();
            }
        });
    },

    /*
     * Create the success and failure RMI callbacks, whose main job is to fulfill or reject the promise,
     * which contains the actual user callbacks.
     * Add callbacks to the callbacks collection.
     */
    _createCallbacks: function(method, parameters, resolve, reject) {
        var persist = _(["subscribe", "ping"]).contains(method.method);
        var callbacks = {
            success: function(response) {
                response = this._cleanRMIResponse(response);

                // If this Deferred is already resolved, we must be dealing with a subscription/queue
                // Fire an event
                if (!this.resolvedCallbacks[index]) {
                    resolve(response);

                    if (this.env === "dev") {
                        const highlight = "font-weight:bold";
                        const normal = "font-weight:normal";
                        console.groupCollapsed(
                            "%c[API call] %c%s%c -> %c%s%c",
                            normal,
                            highlight,
                            method.class,
                            normal,
                            highlight,
                            method.method,
                            normal
                        );
                        console.debug("Request:", parameters);
                        console.debug("Response:", response);
                        console.groupEnd();
                    }

                    this.resolvedCallbacks[index] = true;
                } else {
                    // If this is a queue, and the response is not a string (hack), push the message into the event
                    if (this.dispatcher && parameters && persist && !_(response).isString()) {
                        this.dispatcher.trigger(parameters[0] + ":message", response, method, parameters);
                    }
                }
            },
            failure: function(response) {
                response = this._cleanRMIResponse(response, true);
                // Build the error message (message text and type)
                var error_data = response ? this.createError(response[0], response[1]) : null;
                reject(error_data);
            },
            // Hack, because we don't have Push: For certain API methods, we want to make sure we do not erase the callback after it is called once
            persist: persist
        };

        let index = this._addCallbacks(callbacks);
        this.resolvedCallbacks[index] = false;
        return index;
    },
    _cleanRMIResponse: function(response, multiple_args) {
        if (response?.params) {
            if (multiple_args && response.params.length > 1) {
                return response.params;
            }
            return response.params[0];
        }
        return response;
    },
    _addCallbacks: function(callbacks) {
        var callbackIndex = this.callbacks.indexOf(null);
        if (callbackIndex === -1) {
            callbackIndex = this.callbacks.push(callbacks) - 1;
        } else {
            this.callbacks[callbackIndex] = callbacks;
        }
        return callbackIndex;
    },
    /*
     * Build the WebSocket message and send
     */
    _sendRMICall: function(method, parameters, callbackIndex) {
        var message = {
            class: method.class,
            method: method.method,
            params: parameters,
            callbackIndex: callbackIndex
        };

        if (!message.callbackIndex && message.callbackIndex !== 0 && this.env === "dev") {
            console.warn("CallbackIndex about to be sent is invalid.");
        }

        // Send the message
        if (this.ws && this.ws.readyState === 1) {
            DebuggerConfig.addApiEntry(false, message);
            let crudeMessage = JSON.stringify(message);
            let encodedMessage = encodeURIComponent(crudeMessage);
            this.ws.send(encodedMessage);
        } else {
            throw this.createError("The WebSocket is not open.");
        }
    },

    /*
     * When a WebSocket message arrives, get the RMI method callback ID, retrieve the callback, and call it.
     */
    _onWebSocketMessage: function(message) {
        let data = decodeURIComponent(message.data);
        let response = JSON.parse(data, (_, value, context) => {
            // Special check for BigInt case
            if (
                typeof value === "number" &&
                (value > Number.MAX_SAFE_INTEGER || value < Number.MIN_SAFE_INTEGER) &&
                JSON.stringify(value) !== context.source
            ) {
                return BigInt(context.source);
            }
            return value;
        });
        localStorage.setItem("STRIIM_ONLINE_TIME", new Date().getTime());

        DebuggerConfig.addApiEntry(true, response);

        if (!("callbackIndex" in response)) {
            console.error("Received response:", response, ". No callback index was defined.");
            return response;
        }

        this._invokeCallbacks(response);

        return response;
    },
    /*
     * Given an RMI method response from the server, invoke the appropriate success or failurepreparing query ...  callback.
     */
    _invokeCallbacks: function(response) {
        if (this._isSystemCallback(response) && this.dispatcher) {
            this.dispatcher.trigger("api:" + response.callbackIndex, response.params);
        }

        var callbacks = this._getCallbacks(response.callbackIndex);

        if (!callbacks) {
            return;
        }

        // Remove callbacks from the collection
        this._removeCallbacks(response.callbackIndex);

        if (response.success) {
            callbacks.success.call(this, response);
        } else {
            callbacks.failure.call(this, response);
        }
    },
    _isSystemCallback: function(response) {
        return response.callbackIndex === "status_change" || response.callbackIndex === "CONSOLE_ON_UI";
    },
    /*
     * Get a callback from the collection once that call has been resolved
     */
    _getCallbacks: function(callbackIndex) {
        if (callbackIndex >= this.callbacks.length || callbackIndex < 0) {
            if (this.env === "dev") {
                console.error("Callback index " + callbackIndex + " is out of bounds.");
            }
            return;
        }
        if (!this.callbacks[callbackIndex]) {
            return;
        }

        // Deep-copy the requested callbacks, in case the persist logic below deletes one of them
        // $.extend(true, {}, this.callbacks[callbackIndex]);
        var callbackObject = {};
        for (var callback_type in this.callbacks[callbackIndex]) {
            if (this.callbacks[callbackIndex].hasOwnProperty(callback_type)) {
                callbackObject[callback_type] = this.callbacks[callbackIndex][callback_type];
            }
        }

        return callbackObject;
    },
    /*
     * Remove a callback from the callbacks collection (once that call has been resolved)
     */
    _removeCallbacks: function(callbackIndex) {
        // Unless this is a set of callbacks for an API call that can fire a callback multiple times
        // (such as a queue subscription), clear the callback
        if (!this.callbacks[callbackIndex].persist) {
            this.callbacks[callbackIndex] = null;
        }
    },

    /*
     * Force the WebSocket connection to close.
     */
    disconnect: function() {
        this._triggerDisconnectEventEnabled = false;

        if (!this.ws) {
            return;
        }
        this.ws.close();
    },

    _reconnectWebSocket: function() {
        if (connectionTryCount < maxConnectionTryCount) {
            const _this = this;
            setTimeout(() => {
                console.log("Trying to reconnect");
                if (this.ws) {
                    _this.ws.onopen = null;
                    _this.ws.onclose = null;
                    _this.ws.onerror = null;
                    _this.ws.onmessage = null;
                    this.ws = null;
                    connectionTryCount++;
                }
                this.connect();
            }, (connectionTryCount + 1) * durationNextConnectionTry);
        } else {
            this.dispatcher.trigger("global:app:disconnected");
        }
    },

    _onWebSocketClose: function() {
        if (this._triggerDisconnectEventEnabled) {
            console.log("server connection lost");
            this.dispatcher.trigger("global:app:error");
            this._reconnectWebSocket();
        } else {
            this.ws = null;
        }
        if (this.env === "dev") {
            console.log("WebSocket has been closed.");
        }
        this._stopPingPong();
    },
    _stopPingPong: function() {
        clearInterval(this.pingInterval);
    },
    /*
     * Utility method to create an error object
     */
    createError: function(message, type) {
        function APIError(message) {
            this.name = type || "APIError";
            this.message = message || "Error";
        }

        APIError.prototype = Object.create(Error.prototype);
        APIError.prototype.constructor = APIError;
        return new APIError(message);
    }
};

var RMIFramework = function(opts) {
    // Initialize
    this.initialize(opts);
    // Try to connect automatically
    this.connect();
};
_(RMIFramework.prototype).extend(RMIFrameworkProto);

export default RMIFramework;
