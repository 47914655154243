import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { StriimChip } from "@striim/striim-ui";
import { segmentationVariants, getChipData, getVariants } from "./segmentation.consts";
import classNames from "classnames";
import CustomIcon from "../../../generic/icon/customIcons";

const useStyles = makeStyles(({ spacing, typography }) => {
    const variants = getVariants();

    const styles = {
        label: {
            ...typography.caption,
            fontWeight: 600
        },
        root: {
            padding: spacing(0, 1, 0, 0.5)
        },
        icon: {
            height: 14,
            width: 14
        }
    };

    variants.forEach(v => {
        styles[`root_${v.id}`] = {
            backgroundColor: `${v.color}1a`
        };

        styles[`icon_${v.id}`] = {
            fill: v.color
        };

        styles[`label_${v.id}`] = {};
        if ([segmentationVariants.addOn, segmentationVariants.userStandardTier].indexOf(v.id) === -1) {
            styles[`label_${v.id}`].color = v.color;
        }
    });

    return styles;
});

const AddOnChip = ({ variant, style }) => {
    const classes = useStyles();
    const chipData = getChipData(variant);

    if (variant === segmentationVariants.none) {
        return <></>;
    }
    if (chipData.label === "Cloud Mission Critical") {
        chipData.label = "Striim " + chipData.label;
    }

    return (
        <StriimChip
            style={style}
            label={chipData.label}
            classes={{
                label: classNames(classes.label, classes[`label_${variant}`]),
                root: classNames(classes.root, classes[`root_${variant}`])
            }}
            icon={<CustomIcon className={classNames(classes.icon, classes[`icon_${variant}`])} icon={chipData.icon} />}
        />
    );
};

PropTypes.AddOnChip = {
    variant: PropTypes.oneOf(Object.values(segmentationVariants))
};

export default AddOnChip;
