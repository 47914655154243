import React from "react";
import { Box, Grid } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import { styles } from "./arrow.styles";
import Running from "src/generic/icon/customIcons/running.svg";
import Stopped from "src/generic/icon/customIcons/stop.svg";

const colors = {
    RUNNING: "#2AAC5E",
    STOPPED: "#A7B7C7"
};

const getStatusIcon = (status: string) => {
    switch (status) {
        case "RUNNING":
        case "COMPLETED":
            return { Icon: Running, color: colors.RUNNING };
        default:
            return { Icon: Stopped, color: colors.STOPPED };
    }
};

const pluralize = (word, num) => (num !== 1 ? `${num} ${word}s` : `${num} ${word}`);

interface ArrowProps {
    tablesCount: number;
    status: string;
}

const Arrow: React.FunctionComponent<ArrowProps> = ({ tablesCount, status }) => {
    const { Icon, color } = getStatusIcon(status?.toUpperCase());
    const isRunning = status?.toUpperCase() === "RUNNING";

    return (
        <Grid sx={{ ...styles.container, color: color }}>
            <Box className="dot" sx={styles.dot}></Box>
            <Box className="line" sx={{ ...styles.line, border: isRunning ? "1px solid" : "1px dashed" }}>
                <Box>
                    <StriimTypography variant="caption2" sx={styles.lineLabel}>
                        <Icon />
                        {pluralize("Table", tablesCount)}
                    </StriimTypography>
                </Box>
            </Box>
            <Box className="arrow" sx={styles.arrow}></Box>
        </Grid>
    );
};

export default Arrow;
