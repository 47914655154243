import { CollectionView, ItemView } from "marionette";
import _ from "underscore";
import EventsEntryView from "./eventEntryView";
import { template, childView, childViewContainer, emptyView } from "core/decorators";

@template("<div></div>")
@emptyView(
    ItemView.extend({
        template: _.template("<div class='empty-list'>No records</div>"),
    })
)
@childViewContainer("div.list")
@childView(EventsEntryView)
export default class EventsLogListView extends CollectionView {
    onShow() {
        setTimeout(() => {
            this.$el.perfectScrollbar();
        }, 3000);
    }

    updateLayout() {
        this.$el.perfectScrollbar("destroy");
        this.$el.perfectScrollbar();
    }
}
