import _ from "underscore";
import Column from "./column";
import ColumnEnricher from "./column-enricher";
import ColumnsList from "./columns-list";

const View = ColumnsList.View.extend({
    initialize: function (options) {
        ColumnsList.View.prototype.initialize.apply(this, [options]);

        this.gridView = new ColumnEnricher.CollectionView({
            collection: new Column.Collection([]),
            model: this.model,
            config: options.transformationConfig,
            columnsDataSource: options.columnsDataSource,
        });

        ColumnsList.View.prototype.attachListeners.apply(this);
    },
});

export default _.extend({}, ColumnsList, {
    Model: ColumnsList.Model,
    View: View,
});
