import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";
import _ from "underscore";

var module = {};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "WACTIONSTORE",
        contextType: String,
        eventTypes: Array,
        persistence: Object,
        isExceptionstore: false,
    },
    validate: function () {
        var validationError = {};
        if (baseMetaObject.Model.prototype.validate) {
            validationError = baseMetaObject.Model.prototype.validate.call(this);
            validationError = validationError === undefined ? {} : validationError;
        }
        var allEventTypesValid = _.every(this.eventTypes, function (eventType) {
            return eventType.typename && eventType.keyField;
        });
        if (!allEventTypesValid) {
            validationError["eventTypes"] = "All Event Types should have an associated Key Field.";
        }
        return _.isEmpty(validationError) ? undefined : validationError;
    },
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model,
});

export default module;
