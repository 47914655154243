import App from "app";
import WAHighChartsMixin from "app/components/dashboard/visualization/charts/areachart/mixins/areachart-highcharts";
import "app/components/dashboard/visualization/charts/chart";

App.module("Chart.AreaChart", function (AreaChart, App, Backbone, Marionette, $, _) {
    AreaChart.View = App.Chart.Default.ChartView.extend({
        initialize: function () {
            // Mixin the Highcharts line chart
            _(this).extend(WAHighChartsMixin);

            // Call super initialize
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
        },
    });
});
