import React from "react";
import { Grid, LinearProgress } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui";
import { formatNumber, formatPercentage } from "./utils";

const PercentageBarWithDescription = ({ value, total, units, customDescription, className }) => {
    const percentage = total === 0 ? 0 : (value / total) * 100;
    return (
        <Grid container alignItems="center" justifyContent="space-evenly" className={className}>
            <Grid item className="progress-line">
                <LinearProgress
                    variant="determinate"
                    value={percentage}
                    sx={{
                        minWidth: "100px",
                        minHeight: 1,
                        borderRadius: 0.5,
                        "& .MuiLinearProgress-bar": {
                            backgroundColor: "secondary.500" // Change the bar color here
                        },
                        backgroundColor: "greyscale.200" // Change the background color here
                    }}
                />
            </Grid>
            <Grid item data-test-id="account-and-billing__usageBreakdown__unit" className="units-text">
                {!customDescription ? (
                    <>
                        <StriimTypography color="greyscale.900" variant="body3">
                            {formatNumber(value)} {units}
                        </StriimTypography>{" "}
                        <StriimTypography color="greyscale.700" variant="body4">
                            ({formatPercentage(percentage)}%)
                        </StriimTypography>
                    </>
                ) : (
                    <StriimTypography color="greyscale.900" variant="body3">
                        {customDescription}
                    </StriimTypography>
                )}
            </Grid>
        </Grid>
    );
};

export default PercentageBarWithDescription;
