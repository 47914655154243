import cookies from "js-cookie"; 

const SasSCookieExists = () => {
    const trackingId = cookies.get("tid");

    if (typeof trackingId === "undefined") {
        return false;
    } 
    return trackingId;
};

export default SasSCookieExists;