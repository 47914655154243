import React, { useEffect, useState } from "react";
import { StriimSimpleTableCell, StriimInputField, StriimButton } from "@striim/striim-ui";
import { SvgIcon } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { FileSelectionModal } from ".";
import classNames from "classnames";

import VaultsService from "./vaults-service";
import growl from "app/components/common/growl";
import { Close, Checkmark } from "src/generic/icon/customIcons";

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 22
    },
    tableCell: {
        backgroundColor: "#D7F1FB",
        verticalAlign: "middle"
    },
    valueTypeCell: {
        minWidth: 180
    },
    vaultKeyCell: {
        paddingTop: 30
    },
    actionCell: {
        minWidth: 50
    }
}));

const sxStyles = {
    div: {
        display: "flex"
    },
    typographyProps: {
        variant: "body4",
        color: "greyscale.900"
    },
    actionIcons: {
        fill: "none",
        fontSize: 20
    },
    actionIconSave: {
        "& > path": {
            stroke: ({ palette }) => palette.success[500],
            strokeWidth: 2
        }
    },
    actionIconExit: {
        "& > path": {
            fill: ({ palette }) => palette.critical[500],
            strokeWidth: 2
        }
    }
};

const VaultValueRowEdit = ({ data, setEditMode, fetchData }) => {
    const { vault, isKeyUnique } = data;
    const [dataKey, setDataKey] = useState();
    const [vaultValue, setVaultValue] = useState();
    const [vaultValueType, setVaultValueType] = useState();
    const [isModalShown, setIsModalShown] = useState(false);
    const [error, setError] = useState(false);
    const [vaultValueTypeError, setVaultValueTypeError] = useState(false);
    const [vaultValueError, setVaultValueError] = useState(false);
    const classes = useStyles();

    const onFileDone = file => {
        setVaultValueType("FILE");
        setVaultValue(file);
    };
    const getHelperTextForFile = function(path) {
        if (!path) {
            return "Please select a File";
        } else if (path.includes("UploadedFiles/")) {
            return `File selected: ${path.substring(path.lastIndexOf("/") + 1)}`;
        } else {
            return "Click to change File";
        }
    };
    useEffect(() => {
        if (vaultValueType === "FILE" && !vaultValue) {
            setVaultValueError(true);
            setVaultValueType(undefined);
            return;
        }
        setVaultValueError(false);
    }, [vaultValueType, vaultValue]);

    useEffect(() => {
        if (data?.data?.isSaved) {
            setDataKey(data.dataKey);
            setVaultValue(data?.data?.value);
            setVaultValueType(data?.data?.valueType);
        } else {
            setDataKey("");
            setVaultValue("");
            setVaultValueType("");
        }
    }, [data.data, data.dataKey]);
    return (
        <>
            <StriimSimpleTableCell classes={classNames(classes.tableCell, classes.vaultKeyCell)}>
                <StriimInputField
                    value={dataKey ?? ""}
                    onChange={value => {
                        if (value !== data.dataKey) {
                            setError(!isKeyUnique(value, vault));
                        }
                        setDataKey(value);
                    }}
                    disabled={data?.data?.isSaved}
                    helperText={data?.data?.isSaved ? "Key cannot be modified" : "Value must be unique"}
                    error={error}
                    classes={{ root: classes.root }}
                    id="data-test-id-vault-key"
                />
            </StriimSimpleTableCell>
            <StriimSimpleTableCell classes={classNames(classes.tableCell, classes.valueTypeCell)}>
                <StriimInputField
                    SelectProps={{ options: VaultsService.getVaultValueTypes() }}
                    value={{ label: vaultValueType, value: vaultValueType }}
                    select
                    onChange={value => {
                        setVaultValue(undefined);
                        if (!value?.value) {
                            setVaultValueType(undefined);
                            setVaultValueTypeError(true);
                            return;
                        }
                        setVaultValueTypeError(false);
                        setVaultValueType(value.value);
                        if (value.value === "FILE") {
                            setIsModalShown(true);
                        }
                    }}
                    error={vaultValueTypeError}
                    id="data-test-id-vault-value-type"
                />
            </StriimSimpleTableCell>
            <StriimSimpleTableCell value={data?.data?.value} classes={[classes.tableCell]}>
                <div data-test-id="change-file" onClick={() => vaultValueType === "FILE" && setIsModalShown(true)}>
                    <StriimInputField
                        value={vaultValue || ""}
                        type={"password"}
                        onChange={value => setVaultValue(value)}
                        id="data-test-id-vault-value"
                        classes={{ root: vaultValueType === "FILE" ? classes.root : "" }}
                        helperText={vaultValueType === "FILE" && getHelperTextForFile(vaultValue)}
                        error={vaultValueError}
                        previewPassword={vaultValueType === "STRING" ? true : false}
                        hidePasswordOnBlur={true}
                        inputProps={
                            vaultValueType === "FILE" && {
                                disabled: "true"
                            }
                        }
                    />
                </div>
            </StriimSimpleTableCell>
            <StriimSimpleTableCell
                value={data && VaultsService.getVaultUsage(vault, dataKey)}
                classes={[classes.tableCell]}
                typographyProps={sxStyles.typographyProps}
            />
            <StriimSimpleTableCell classes={classNames(classes.tableCell, classes.actionCell)}>
                <div style={sxStyles.div}>
                    <StriimButton
                        variant="primaryText"
                        onClick={async () => {
                            try {
                                if (vaultValueType === "FILE" && vaultValue && !vaultValue.includes("UploadedFiles/")) {
                                    growl.warning("Please select a valid file");
                                    return;
                                }
                                data?.data?.isSaved
                                    ? await VaultsService.alterVaultValue(
                                          vaultValueType,
                                          vault.get("id"),
                                          dataKey,
                                          vaultValue
                                      )
                                    : await VaultsService.addVaultValue(
                                          vault.get("id"),
                                          vaultValueType,
                                          dataKey,
                                          vaultValue
                                      );
                                growl.success("Vault value successfully saved");
                            } catch (e) {
                                growl.error(e);
                            }
                            data.setEditingData(null);
                            await fetchData();
                        }}
                        disabled={
                            !isKeyUnique(dataKey, vault) || !dataKey?.length || !vaultValueType || vaultValueError
                        }
                        data-test-id="vault-value-save-button"
                    >
                        <SvgIcon component={Checkmark} sx={{ ...sxStyles.actionIcons, ...sxStyles.actionIconSave }} />
                    </StriimButton>
                    <StriimButton
                        component="button"
                        variant="errorText"
                        onClick={() => {
                            setEditMode(false);
                        }}
                        data-test-id="vault-value-cancel-button"
                    >
                        <SvgIcon component={Close} sx={{ ...sxStyles.actionIcons, ...sxStyles.actionIconExit }} />
                    </StriimButton>
                </div>
            </StriimSimpleTableCell>
            {isModalShown && <FileSelectionModal setIsModalShown={setIsModalShown} onFileDone={onFileDone} />}
        </>
    );
};

export default VaultValueRowEdit;
