export const styles = {
    backButton: {
        p: 1
    },
    backIcon: {
        fill: "none",
        fontSize: "20px"
    },
    downloadIcon: {
        fill: 'none',
        "& > path": {
            stroke: ({ palette }) => palette.additional.white
        }
    },
    secondaryDownloadIcon: {
        fill: 'none',
        "& > path": {
            stroke: ({ palette }) => palette.secondary[500]
        }
    },
    retryIcon: {
        fill: 'none',
        "& > path": {
            stroke: ({ palette }) => palette.additional.white
        }
    },
    refreshIcon: {
        fill: 'none',
        "& > path": {
            fill: ({ palette }) => palette.additional.white
        }
    },
    heading: {
        maxWidth: '345px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'primary.700'
    },
    simpleHeading: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    infoIcon: {
        width: 16,
        height: 16,
        fill: 'none'
    },
    simpleName: {
        flex: 1,
        textAlign: "right",
        color: "greyscale.500",
        fontSize: "10px"
    },
    caption: {
        fontFamily: "Inter",
        color: "greyscale.700",
        letterSpacing: "0.24px"
    },
    captionDate: {
        fontFamily: "Inter",
        color: "primary.700",
        letterSpacing: "0.24px",
    },
    descriptionWrapper: {
        width: 400,
        p: 2
    },
}
