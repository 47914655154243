import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Observer } from "mobx-react";
import { Card, CardContent, CardHeader, CardActions, Grid, Box, Divider } from "@material-ui/core";
import { MoreHoriz, ChevronRight, AccountTreeOutlined } from "@material-ui/icons";
import camelCase from "camelcase";
import clsx from "clsx";

import useStores from "src/utils/use-stores";
import SourceTargetList from "src/source-target-list/source-target-list";
import { AppAction } from "src/status-management";
import AppGroupSelect from "../app-group-select/app-group-select";
import AppControl from "app/components/common/app-control/app-control";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import exceptionsStoreApi from "app/components/flow/designer/app-exceptions/exception-store-api";
import monitorService from "core/services/monitorService";
import statusManagement from "src/status-management";

import {
    StriimDropdown,
    StriimIconButton,
    StriimTypography,
    StriimModal,
    StriimCheckbox,
    StriimTooltip,
    StriimMenuList,
    StriimMenuItem
} from "@striim/striim-ui";

import useStyles from "./app-tile.styles";
import useStylesTile from "./app-tile-wide.styles";

import { SegmentationChip, SegmentationModal } from "../../../../../common/segmentation";
import formatter from "../../../../../../../app/components/monitor/common/valueFormatter";
import { AppStatusChip, TileStatusIndicator } from "./components/app-status-indicators";
import growl from "app/components/common/growl";
import { formatGroupError } from "../../../../../../utils/error-formatter";
import useEnteredScreen from "src/hooks/useEnteredScreen";
import SourceTargetLoader from "../../../../../../stores/sources-targets-loader";
import { AppTileWideStatusIndicator } from "./components/app-status-indicators/app-status-indicators";
import api from "../../../../../../../core/api/api";
import getMonitorData from "../../../../../homepage/systeam_health_overview/monitor/system_health_monitor_service";
import numeral from "numeral";
import DropAppModal from "../../../../../../generic/drop-app-modal/drop-app-modal";
import { useTheme } from "@mui/material/styles";
import DeployModalWrapper from "../../../../../../generic/deploy-modal/deploy-modal-wrapper";

const formatNumber = num => numeral(num ?? 0).format("0.[00]a");

const AppTile = ({ app, wide, applicationGroupsDisabled, checkboxDisabled, appsList, dataFetchInterval }) => {
    const v5theme = useTheme();
    const { store, groupsStore } = useStores();
    const classes = useStyles();
    const tileClasses = useStylesTile(v5theme);

    const [dropConfirmVisible, setDropConfirmVisible] = useState(false);
    const [exceptionsStoreEnabled, setExceptionsStoreEnabled] = useState(false);
    const [appDeployVisible, setAppDeployVisible] = useState(false);
    const [doNotDeleteExceptionStore, setDoNotDeleteExceptionStore] = useState(false);
    const groupSelectState = useState(false);
    const menuState = useState(false);
    const [applicationGroupsDisabledModal, setApplicationGroupsDisabledModal] = useState(false);
    const appActionRef = useRef();
    const [previousStatus, setPreviousStatus] = useState("");
    const [isAPILoading, setIsAPILoading] = useState(false);
    const ref = useRef();
    const isVisible = useEnteredScreen(ref);

    let monitorSubscriber = null;
    useEffect(() => {
        if (isVisible) {
            setIsAPILoading(true);
            const loader = new SourceTargetLoader();
            loader.loadForApp(app).then(
                () => {
                    setIsAPILoading(false);
                    app.loadedSourcesAndTargets = true;
                },
                () => {
                    setIsAPILoading(false);
                }
            );
            monitorSubscriber = monitorService.subscribe(app.id, stat => {
                const data = stat ? stat["most-recent-data"] : {};
                app.cpu = typeof data.cpu === "undefined" ? "Fetching…" : data.cpu;
                app.rate = typeof data["source-rate"] === "undefined" ? "0" : formatter.formatRate(data["source-rate"]);
                app.wactions = data["wactions-created"] || 0;
            });

            const fetchPreviousStatus = async () => {
                const appId = app.nsName + ".APPLICATION." + app.name;
                const appModel = await metaStoreService.findById(appId);
                if (!appModel) return;
                const flowStatus = appModel.get("flowStatus");
                setPreviousStatus(flowStatus);
            };
            fetchPreviousStatus();

            return () => {
                monitorService.unSubscribe(monitorSubscriber);
            };
        }
    }, [isVisible]);

    useEffect(() => {
        store.listenToProgressBarChange();
        () => {
            store.disposeListeners();
        };
    }, [store, app]);

    async function handleClose(action) {
        const appId = app?.nsName + ".APPLICATION." + app?.name;
        appActionRef.current = action;

        if (action === AppAction.DROP) {
            menuState[1](false);
            exceptionsStoreApi.checkExceptionsEnabled(appId).then(isEnabled => {
                setExceptionsStoreEnabled(isEnabled);
                setDoNotDeleteExceptionStore(false);
                setDropConfirmVisible(true);
            });
        } else if (action === AppAction.DEPLOY) {
            menuState[1](false);
            setAppDeployVisible(true);
        } else if (action === AppAction.CHANGE_GROUP) {
            if (applicationGroupsDisabled) {
                menuState[1](false);
                setApplicationGroupsDisabledModal(true);
                return;
            }

            groupSelectState[1](false);
        } else if (action === AppAction.EXPORT) {
            menuState[1](false);
            const appId = app.nsName + ".APPLICATION." + app.name;
            metaStoreService.findById(appId).then(app => {
                const appControl = new AppControl({
                    appIdentifier: app
                });
                appControl.export();
            });
        } else if (typeof action === "string") {
            menuState[1](false);
            await store.onAppAction(app, action);
        }
    }

    const onDrop = () =>
        new Promise(async function(resolve, reject) {
            try {
                await store.fetchApps();
                await groupsStore.fetch();
                resolve();
            } catch (error) {
                reject(error);
            }
        });

    async function onDeploy() {
        setAppDeployVisible(false);
        await store.onAppAction(app, AppAction.DEPLOY, deployConfig.config);
    }

    const MoveToAgroupLabel = ({ groupsDisabled, onClick }) => (
        <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            classes={{ root: classes.moveToGroupActionContainer }}
            onClick={onClick}
        >
            Move to a group{groupsDisabled && <SegmentationChip variant="addOn" />}
            <ChevronRight />
        </Grid>
    );

    const actionsCta = {
        [AppAction.VIEW]: "Manage flow",
        [AppAction.MONITOR]: "Monitor app",
        [AppAction.SHOW_ERRORS]: "Show Errors",
        [AppAction.CHANGE_GROUP]: applicationGroupsDisabled ? (
            <MoveToAgroupLabel groupsDisabled />
        ) : (
            <div
                onClick={e => {
                    e.stopPropagation(); //to prevent exec handleClose handler
                }}
                onMouseDown={e => {
                    e.stopPropagation(); //to prevent focus animation on menuitem
                }}
            >
                <StriimDropdown
                    controlState={groupSelectState}
                    content={
                        <AppGroupSelect onClose={onSelectClose} groups={groupsStore.groups} applicationId={app.id} />
                    }
                >
                    <Box>
                        <MoveToAgroupLabel />
                    </Box>
                </StriimDropdown>
            </div>
        )
    };

    const menu = (
        <StriimMenuList className="apptile--menu" data-test-id={app.nsName + "-" + app.name + "-menu"}>
            {app.statusTransitions?.actions?.map(menuItem => (
                <StriimMenuItem
                    sx={{ overflow: "visible" }}
                    key={menuItem}
                    disabled={app.isFetching}
                    data-test-id={app.nsName + "-" + app.name + "-" + menuItem}
                    onClick={() => {
                        handleClose(menuItem);
                    }}
                >
                    {actionsCta[menuItem] || camelCase(menuItem, { pascalCase: true })}
                </StriimMenuItem>
            ))}
        </StriimMenuList>
    );

    const sourceTargetList = (
        <Observer
            render={() => (
                <>
                    {isVisible && !isAPILoading ? (
                        <SourceTargetList
                            sources={app.sources}
                            targets={app.targets}
                            className={classes.icons}
                            appId={app.nsName + ".APPLICATION." + app.name}
                            appTileClasses={tileClasses.targetListsTileWide}
                            targetListsEmptyTileWide={tileClasses.targetListsEmptyTileWide}
                            maxSources={2}
                            maxTargets={2}
                        />
                    ) : (
                        <StriimTypography variant={"subtitle2"}>Fetching app components..</StriimTypography>
                    )}
                </>
            )}
        />
    );

    const appActionsButton = app?.statusTransitions && app?.statusTransitions?.actions && (
        <Observer
            render={() => (
                <div
                    onClick={e => {
                        e.stopPropagation(); //to prevent app selection
                    }}
                    className={app.isFetching ? classes.appActionDisabled : null}
                    data-test-id={app.nsName + "-" + app.name + "-open-menu"}
                >
                    <StriimDropdown
                        extendOnClick
                        closeOnSelect
                        controlState={menuState}
                        onClose={() => {
                            groupSelectState[1](false); // Be sure to close the MoveToGroup additional dropdown
                        }}
                        content={menu}
                    >
                        <StriimIconButton classes={{ root: classes.cardActionsButton }}>
                            <Box component={MoreHoriz} color="text.secondary" />
                        </StriimIconButton>
                    </StriimDropdown>
                </div>
            )}
        />
    );

    async function onSelectClose(selectedGroup) {
        if (!selectedGroup) {
            groupSelectState[1](false);
            menuState[1](false);
            return;
        }
        try {
            await groupsStore.assignToGroup([app], selectedGroup.name, selectedGroup.description);
            groupSelectState[1](false);
            setTimeout(() => menuState[1](false), 0);
        } catch (error) {
            const message = formatGroupError(error, selectedGroup.name);
            growl.error(message, "Error creating group");
        }
    }

    // declare all states
    const [loaded, setLoaded] = useState(false);
    const [ttlInput, setTtlInput] = useState(0);
    const [ttlOutput, setTtlOutput] = useState(0);
    const [ttlInputRate, setTtlInputRate] = useState(0);
    const [ttlOutputRate, setTtlOutputRate] = useState(0);
    const [lagFirst, setLagFirst] = useState(0);
    const [lagSecond, setLagSecond] = useState(0);

    const setMonitorData = useCallback(model => {
        setTtlInput(model?.ttlInput);
        setTtlOutput(model?.ttlOutput);
        setTtlInputRate(model?.inputRate);
        setTtlOutputRate(model?.outputRate);
        setLagFirst(model?.lagFirst);
        setLagSecond(model?.lagSecond);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            // init monitor Data
            let data = await api.getMonitoringStatsForAppWithoutTableInfo(app?.id);
            setMonitorData(getMonitorData(data));

            // set loaded to true to display data
            if (!loaded) setLoaded(true);
            // update data every 5 mins
            return setInterval(async () => {
                let data = await api.getMonitoringStatsForAppWithoutTableInfo(app?.id);

                setMonitorData(getMonitorData(data));
            }, dataFetchInterval);
        };

        const interval = fetchData();
        // cleanUp fn to remove interval callback
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const lagValues = useMemo(() => {
        if (lagFirst || lagSecond) {
            return `${(lagFirst / lagSecond)?.toFixed(0)}`;
        } else return 0;
    }, [lagFirst, lagSecond]);

    return (
        <Observer
            render={() => {
                const appEvents = (
                    <Box className={tileClasses.appInfoContainerText}>
                        <StriimTypography className={tileClasses.appInfoContainerText}>Read/writes</StriimTypography>
                        <StriimTypography variant="bodySemibold">
                            {formatNumber(ttlInput) || 0}/{formatNumber(ttlOutput) || 0}
                        </StriimTypography>
                    </Box>
                );
                const appRate = (
                    <Box className={tileClasses.appInfoContainerText}>
                        <StriimTypography className={tileClasses.appInfoContainerText}>
                            Read/Write rate
                        </StriimTypography>
                        <StriimTypography variant="bodySemibold">
                            {formatNumber(ttlInputRate) || 0}/{formatNumber(ttlOutputRate) || 0} records/s
                        </StriimTypography>
                    </Box>
                );
                const lagRate = (
                    <Box className={tileClasses.appInfoContainerText}>
                        <StriimTypography className={tileClasses.appInfoContainerText}>Lag</StriimTypography>
                        <StriimTypography variant="bodySemibold">{lagValues || 0} ms</StriimTypography>
                    </Box>
                );

                const handleAppChecked = e => {
                    app.isSelected = !app.isSelected;
                    e.stopPropagation();
                };

                const handleAppTileClick = () => {
                    store.onAppAction(app, AppAction.VIEW);
                };

                const appSelector = checkboxDisabled ? (
                    <Grid>
                        <AccountTreeOutlined />
                    </Grid>
                ) : (
                    <Grid>
                        <StriimCheckbox
                            className={"appTileCheckbox"}
                            checked={app.isSelected}
                            onClick={handleAppChecked}
                        />
                    </Grid>
                );
                const appStatusLabel = statusManagement.getStatusChipDisplayName(
                    app.flowStatus,
                    appActionRef.current,
                    previousStatus
                );

                const appStatus = <AppStatusChip label={appStatusLabel} />;

                const avatar = (
                    <>
                        {appSelector}
                        {appStatus}
                    </>
                );
                return (
                    <>
                        {wide ? (
                            // new tile wide
                            <Grid
                                className={tileClasses.appTile}
                                container
                                item
                                onClick={handleAppTileClick}
                                id={"apptile--" + app.nsName + "." + app.name}
                                data-test-id={"apptile--" + app.nsName + "." + app.name}
                                ref={ref}
                            >
                                <Grid item xs={12} className={tileClasses.appTileWrapper}>
                                    <Grid item xs={3} className={tileClasses.appTileNameContainer}>
                                        <AppTileWideStatusIndicator label={appStatusLabel} />
                                        {appsList && (
                                            <Grid item className={tileClasses.appSelector}>
                                                {appSelector}
                                            </Grid>
                                        )}

                                        <Grid item>
                                            <Grid
                                                item
                                                container
                                                className={clsx(
                                                    tileClasses.appNames,
                                                    !appsList && tileClasses.appNamesList
                                                )}
                                            >
                                                {app?.name?.length > 20 ? (
                                                    <StriimTooltip title={app.name}>
                                                        <span>
                                                            <StriimTypography
                                                                variant="h3"
                                                                className={tileClasses.title}
                                                            >
                                                                {app.name}
                                                            </StriimTypography>
                                                        </span>
                                                    </StriimTooltip>
                                                ) : (
                                                    <StriimTypography variant="h3" className={tileClasses.title}>
                                                        {app.name}
                                                    </StriimTypography>
                                                )}

                                                <StriimTypography className={tileClasses.subTitle}>
                                                    {app.nsName}
                                                </StriimTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item className={tileClasses.appDetailsContainer}>
                                        <Grid item className={tileClasses.appInfoWrapper}>
                                            <Grid item className={tileClasses.appDetailsSpacing}>
                                                {appStatus}
                                            </Grid>
                                            <Grid container className={tileClasses.appDataContainer}>
                                                <Grid item className={tileClasses.appInfoContainerDivider}>
                                                    <StriimTypography className={tileClasses.appInfoContainerText}>
                                                        Read/Writes
                                                    </StriimTypography>
                                                    <StriimTypography variant="bodySemibold">
                                                        {formatNumber(ttlInput) || 0}/{formatNumber(ttlOutput) || 0}
                                                    </StriimTypography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    className={clsx(
                                                        tileClasses.appInfoContainerDivider,
                                                        tileClasses.appStatsWidth
                                                    )}
                                                >
                                                    <StriimTypography className={tileClasses.appInfoContainerText}>
                                                        Read/Write rate
                                                    </StriimTypography>
                                                    <StriimTypography variant="bodySemibold">
                                                        {formatNumber(ttlInputRate) || 0}/
                                                        {formatNumber(ttlOutputRate) || 0} records/s
                                                    </StriimTypography>
                                                </Grid>

                                                <Grid item className={tileClasses.appInfoContainer}>
                                                    <StriimTypography className={tileClasses.appInfoContainerText}>
                                                        Lag
                                                    </StriimTypography>
                                                    <StriimTypography variant="bodySemibold">
                                                        {(lagValues && `${lagValues} ms`) || 0}
                                                    </StriimTypography>
                                                </Grid>
                                                <Grid item className={tileClasses.gridSourceTargetList}>
                                                    {sourceTargetList}
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                justifyContent="flex-end"
                                                className={tileClasses.gridAppActionButton}
                                                xs={1}
                                            >
                                                {appActionsButton}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <Card
                                className={classes.appTile}
                                classes={{ root: classes.cardRoot }}
                                onClick={handleAppTileClick}
                                id={"apptile--" + app.nsName + "." + app.name}
                                data-test-id={"apptile--" + app.nsName + "." + app.name}
                                ref={ref}
                            >
                                <TileStatusIndicator label={appStatusLabel} />
                                <CardHeader
                                    classes={{
                                        root: classes.cardHeaderRoot,
                                        action: classes.cardHeaderAction,
                                        title: classes.cardHeaderTitle,
                                        content: classes.cardHeaderContent,
                                        subheader: classes.cardHeaderSubHeader,
                                        avatar: classes.cardHeaderAvatar
                                    }}
                                    avatar={avatar}
                                    title={app.name}
                                    titleTypographyProps={{ variant: "h3" }}
                                    subheader={app.nsName}
                                    subheaderTypographyProps={{ variant: "caption" }}
                                />
                                <CardContent classes={{ root: classes.cardContentRoot }}>
                                    <div className={classes.cardAppInfo}>
                                        <div>{appEvents}</div>
                                        <Grid container alignItems="center" justifyContent="flex-start">
                                            <div>{appRate}</div>
                                            <Divider className={classes.divider} />
                                            <div>{lagRate}</div>
                                        </Grid>
                                    </div>
                                </CardContent>
                                <div className={classes.cardContentSeparator} />
                                <CardActions
                                    classes={{
                                        root: classes.cardActionsRoot
                                    }}
                                >
                                    {sourceTargetList}
                                    {appActionsButton}
                                </CardActions>
                            </Card>
                        )}
                        <DropAppModal
                            store={store}
                            app={app}
                            dropModalVisible={dropConfirmVisible}
                            setDropModalVisible={setDropConfirmVisible}
                            onConfirmDrop={onDrop}
                        />
                        <DeployModalWrapper
                            appId={app.id}
                            isVisible={appDeployVisible}
                            setIsVisible={setAppDeployVisible}
                        />
                        <SegmentationModal
                            isVisible={applicationGroupsDisabledModal}
                            onCancel={() => {
                                setApplicationGroupsDisabledModal(false);
                            }}
                            title="Application groups"
                            variant="addOn"
                        >
                            <StriimTypography variant="body">
                                Highlight the benefits. Highlight the benefits. Highlight the benefits.
                            </StriimTypography>
                        </SegmentationModal>
                    </>
                );
            }}
        />
    );
};

AppTile.propTypes = {
    app: PropTypes.object.isRequired,
    wide: PropTypes.bool,
    checkboxDisabled: PropTypes.bool
};

AppTile.defaultProps = {
    wide: false,
    checkboxDisabled: false
};

export default AppTile;
