import React, { useRef } from "react";
import { StriimModal, StriimTypography } from "@striim/striim-ui";
import { Grid } from "@mui/material";
import { StriimButton } from "@striim/striim-ui";
import AddNewAlert from "../add-new-alert/add-new-alert";
import SmartAlertsTable from "../smart-alerts-table/smart-alerts-table";
import { classes } from "../add-new-alert/add-new-alert.styles";
import LoadingIndicator from "src/generic/loading-indicator";
import utils from "core/utils";
import PropTypes from "prop-types";

const SmartAlertsModal = ({
    componentId,
    componentTitle,
    componentType,
    alertsCollection,
    toggleAlertStatus,
    addNewAlert,
    formLoading,
    tableRef,
    showModal,
    setShowModal
}) => {
    const formRef = useRef();
    const addAlertContainerRef = useRef();
    const handleCreateClick = () => {
        addAlertContainerRef.current.scrollIntoView({});
    };
    return (
        <StriimModal
            autoHeight
            isVisible={showModal}
            size="medium"
            confirmContent="Save Alert"
            dialogProps={{
                sx: {'.MuiDialog-paper': {width: '780px'}},
            }}
            cancelContent="Close"
            titleContent={
                <StriimTypography variant={"h2"} className={classes.modalHeading}>
                    Configure Alerts for {componentTitle}
                </StriimTypography>
            }
            confirmButtonProps={{
                "data-test-id": "add-new-alerts-save-button"
            }}
            onConfirm={() => {
                formRef.current.submitForm();
            }}
            onCancel={() => {
                setShowModal(false);
            }}
        >
            {formLoading && <LoadingIndicator isGlobal={false} sx={classes.progressBar} />}
            <Grid>
                <Grid item sx={{ marginTop: "20px" }} ref={tableRef}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <StriimTypography variant={"h2"}>
                            Created Alerts for this {utils.capitalize(componentType)}
                        </StriimTypography>
                        <StriimButton variant="tertiary" onClick={handleCreateClick}>
                            Create
                        </StriimButton>
                    </Grid>
                    <SmartAlertsTable
                        {...{
                            componentId,
                            componentType,
                            toggleAlertStatus,
                            alertsCollection,
                            setShowModal
                        }}
                    />
                </Grid>
                <Grid item sx={{ marginTop: "20px" }}>
                    <AddNewAlert
                        {...{
                            componentType,
                            addNewAlert,
                            addAlertContainerRef,
                            formRef
                        }}
                    />
                </Grid>
            </Grid>
        </StriimModal>
    );
};

SmartAlertsModal.propTypes = {
    addNewAlert: PropTypes.func.isRequired,
    alertsCollection: PropTypes.array.isRequired,
    componentId: PropTypes.string.isRequired,
    componentTitle: PropTypes.string.isRequired,
    componentType: PropTypes.string.isRequired,
    formLoading: PropTypes.bool.isRequired,
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    tableRef: PropTypes.shape({ current: PropTypes.object }),
    toggleAlertStatus: PropTypes.func.isRequired
};

export default SmartAlertsModal;
