import Backbone from "backbone";
import _ from "underscore";
import BaseControl from "./../base-control";
import Interval from "./../interval/interval";
import Select from "./../select/select";
import ControlLabel from "./../../control-label";
import FormField from "./../../form-field";
import WactionstorePersistenceProperties from "./wactionstore-persistence-properties";
import wactionStorePersistenceTypes from "./wactionstore-persistence-types.json";
import Consts from "./wactionstore-persistence-consts";
import template from "./wactionstore-persistence.html";

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "waction-store-persistence",
    regions: {
        persistenceTypeLabelRegion: ".persistence-type-label",
        persistenceTypeRegion: ".persistence-type",
        frequencyLabelRegion: ".frequency-label",
        frequencyRegion: ".frequency",
        persistencePropertiesRegion: ".persistence-properties"
    },

    ui: {
        showHideProperties: ".show-hide-optional-properties",
        parameters: ".persistence-properties",
        frequencyField: ".frequency-field"
    },

    events: {
        "click @ui.showHideProperties": "onClickShowHideProperties"
    },

    onRender: function() {
        var _this = this;

        var value = this.getValue();

        if (!value.properties) {
            value.properties = {};
            value.properties[Consts.PropertyNames.StorageProvider] = Consts.StorageProviders.ElasticSearch;
            value[Consts.PropertyNames.Frequency] = undefined;
        } else {
            if (!value.properties[Consts.PropertyNames.StorageProvider]) {
                value.properties[Consts.PropertyNames.StorageProvider] = Consts.StorageProviders.InMemory;
            }
        }

        this.persistenceTypeLabel = new ControlLabel({
            model: new FormField.Model({
                label: "Storage Provider",
                isRequired: true
                //,description: 'enter description here'
            })
        });

        this.persistenceType = Select(wactionStorePersistenceTypes, {
            allowClear: false,
            hideSearch: true
        }).create();

        if (value.properties) {
            this.persistenceType.setValue(value.properties[Consts.PropertyNames.StorageProvider]);
        }
        this.persistenceType.on("value:set", function(value) {
            _this.propertyEditorModel.clear();
            _this.frequency.setValue(undefined);
            if (value !== Consts.StorageProviders.InMemory) {
                _this.propertyEditorModel.set(Consts.PropertyNames.StorageProvider, value);
            }

            _this.propertyEditor.render();
            _this.refreshShowHideParameters();
            _this.setValueFromView();
        });

        this.frequencyLabel = new ControlLabel({
            model: new FormField.Model({
                label: "Frequency"
                //,description: 'enter description here'
            })
        });

        this.frequency = Interval.extend({
            hidden: true
        }).create();
        this.frequency.setValue(value.frequency);
        this.frequency.on("value:set", function() {
            _this.setValueFromView();
        });

        this.propertyEditorModel = new Backbone.Model(value.properties);
        this.propertyEditor = new WactionstorePersistenceProperties({
            model: this.propertyEditorModel
        });
        this.listenTo(this.propertyEditorModel, "change", function() {
            _this.setValueFromView();
        });

        this.persistenceTypeLabelRegion.show(this.persistenceTypeLabel);
        this.persistenceTypeRegion.show(this.persistenceType);
        this.frequencyLabelRegion.show(this.frequencyLabel);
        this.frequencyRegion.show(this.frequency);
        this.persistencePropertiesRegion.show(this.propertyEditor);

        this.refreshShowHideParameters();

        BaseControl.View.prototype.onRender.apply(this, arguments);
    },

    refreshShowHideParameters: function() {
        if (this.persistenceType.getValue() === Consts.StorageProviders.ElasticSearch) {
            this.ui.showHideProperties.show().css("display", "inline-block");
            this.ui.parameters.hide();
            this.ui.frequencyField.hide();
        } else if (this.persistenceType.getValue() === Consts.StorageProviders.InMemory) {
            this.ui.showHideProperties.hide();
            this.ui.parameters.hide();
            this.ui.frequencyField.hide();
        } else {
            this.ui.showHideProperties.hide();
            this.ui.parameters.show();
            this.ui.frequencyField.show();
        }
    },

    onClickShowHideProperties: function() {
        var toggle = this.ui.showHideProperties.find("#toggle-mode");
        if (!this.ui.parameters.is(":visible")) {
            toggle.html("Hide");
            this.ui.parameters.show();
            if (
                this.persistenceType.getValue() !== Consts.StorageProviders.ElasticSearch &&
                this.persistenceType.getValue() !== Consts.StorageProviders.InMemory
            ) {
                this.ui.frequencyField.show();
            }
        } else {
            toggle.html("Show");
            this.ui.parameters.hide();
            this.ui.frequencyField.hide();
        }
    },

    setViewEnabled: function() {
        if (!this.$el.html()) {
            return;
        }

        var enabled = this.getEnabled();
        this.persistenceType.setEnabled(enabled);
        this.frequency.setEnabled(enabled);
        this.propertyEditor.setEnabled(enabled);
    },

    getViewValue: function() {
        var obj = {
            properties: this.propertyEditorModel.toJSON()
        };
        var persistence_type = this.persistenceType.getValue();
        if (persistence_type === Consts.StorageProviders.InMemory) {
            obj.properties = {};
            obj.properties[Consts.PropertyNames.StorageProvider] = undefined;
        } else {
            obj.properties[Consts.PropertyNames.StorageProvider] = persistence_type;
        }

        var frequency = this.frequency.getValue();
        if (frequency) {
            obj.frequency = frequency;
        } else if (persistence_type !== Consts.StorageProviders.InMemory) {
            obj.frequency = undefined;
        }

        return obj;
    },

    setViewValue: function() {}
});

export default _.extend({}, BaseControl, {
    View: View
});
