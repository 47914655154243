import React, { useState, useEffect } from "react";

import { ConnectionProfilesEmpty } from "./";
import ManageStriimPageWrapper from "../manage-striim-wrapper";
import connectionProfileService from "./connection-profile-service";
import ConnectionProfileList from "./connection-profiles-list/connection-profile-list";

const ConnectionProfilesPage = () => {
    const [connectionProfiles, setConnectionProfiles] = useState<ConnectionProfile[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        (async function getConnectionProfiles() {
            const result = await connectionProfileService.getConnectionProfiles();
            setConnectionProfiles(result);
            setLoading(false);
        })();
    }, []);

    const refreshConnectionProfiles = async () => {
        setLoading(true);
        const result = await connectionProfileService.getConnectionProfiles();
        setConnectionProfiles(result);
        setLoading(false);
    };

    return (
        <ManageStriimPageWrapper loading={loading}>
            {connectionProfiles.length ? (
                <ConnectionProfileList
                    connectionProfiles={connectionProfiles}
                    refreshConnectionProfiles={refreshConnectionProfiles}
                />
            ) : (
                <ConnectionProfilesEmpty />
            )}
        </ManageStriimPageWrapper>
    );
};

export default ConnectionProfilesPage;
