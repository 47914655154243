import React, { useState, useEffect } from "react";
import { Box, Badge, useTheme, Drawer } from "@mui/material";
import { StriimIconButton, StriimDropdown, StriimModal, StriimTypography, StriimButton } from "@striim/striim-ui";
import useStyles from "./right-toolbar-actions.styles";
import DropdownAccountContent from "./components/dropdown-account-content/dropdown-account-content.jsx";
import DropdownHelpContent from "./components/dropdown-help-content/dropdown-help-content.jsx";
import AboutContent from "./components/dropdown-help-content/about-modal-content";
import AgentDownloadContent from "./components/dropdown-help-content/download-agent-modal-content";
import headerStore from "../../../../../../stores/header";
import AlertsList from "./components/alerts-list/alerts-list";
import { observer } from "mobx-react";
import DropdownDocumentationContent from "../dropdown-documentation-content/dropdown-documentation-content";
import securityService from "core/services/securityService/securityService";
import { getChipData, getVariantForLicense } from "src/modules/common/segmentation/segmentation.consts";
import UploadModal from "src/generic/filebrowser/file_upload_modal";
import * as UploadModalStore from "src/generic/filebrowser/file_browser.store";
import growl from "app/components/common/growl";
import Bell from "src/generic/icon/topbar/bell.svg";
import Help from "src/generic/icon/topbar/help.svg";
import UserCircle from "src/generic/icon/topbar/user-circle.svg";
import StriimGPTWrapper from "src/modules/apps/pages/striim-gpt/striim-gpt-wrapper";
import copilotService from "core/services/copilotService";

const uploadModalStore = new UploadModalStore.default();

const AlertIcon = observer(({ store }) => {
    const classes = useStyles();
    return (
        <StriimIconButton id={"nav--alerts"}>
            <Badge
                overlap="rectangular"
                badgeContent={store.alerts.length}
                color="secondary"
                max={999}
                data-test-id={"nav-alerts-count"}
                classes={{ badge: classes.badge }}
            >
                <Bell className={classes.iconWhite} />
            </Badge>
        </StriimIconButton>
    );
});

const RightToolBarActions = () => {
    const hideDocsThreshold = 1080;
    const [hideDocs, setHideDocs] = useState(window.innerWidth < hideDocsThreshold);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setHideDocs(window.innerWidth < hideDocsThreshold);
        });
        return () =>
            window.removeEventListener("resize", () => {
                setHideDocs(window.innerWidth < hideDocsThreshold);
            });
    }, []);

    const theme = useTheme();
    const classes = useStyles({ paletteV2: theme.palette });

    const [notificationState, helpState, accountState, documentationState] = [
        useState(false),
        useState(false),
        useState(false),
        useState(false)
    ];
    const [isAboutOpen, setIsAboutOpen] = useState(false);
    const uploadModalState = useState(false);
    const [isAgentDownloadOpen, setIsAgentDownloadOpen] = useState(false);

    const licenseLabelText = getChipData(getVariantForLicense(securityService.currentSegmentationLicense)).label;

    const openAboutModal = () => {
        setIsAboutOpen(true);
    };

    const copyPath = async path => {
        try {
            await navigator.clipboard.writeText(path);
            growl.success("Copied path: " + path, "File path copied.");
        } catch (e) {
            growl.info("Please copy the path manually to use it: " + path, "Error copying to your clipboard.");
        }
    };

    const openAgentModal = () => {
        setIsAgentDownloadOpen(true);
    };

    const closeAllExcept = function(key) {
        switch (key) {
            case "ACCOUNT":
                notificationState[1](false);
                helpState[1](false);
                documentationState[1](false);
                break;
            case "ABOUT":
                notificationState[1](false);
                accountState[1](false);
                documentationState[1](false);
                break;
            case "ALERTS":
                helpState[1](false);
                accountState[1](false);
                documentationState[1](false);
                break;
            case "DOC":
                helpState[1](false);
                accountState[1](false);
                notificationState[1](false);
                break;
        }
    };

    return (
        <Box className={classes.flex}>
            {/*Modals*/}
            <StriimModal
                isVisible={isAboutOpen}
                titleContent={<StriimTypography variant="h2">About Striim{` ${licenseLabelText}`}</StriimTypography>}
                onCancel={() => {
                    setIsAboutOpen(false);
                }}
                cancelContent="Close"
                size={"medium"}
                dialogProps={{
                    classes: { paper: classes.heightAuto }
                }}
                confirmButtonProps={{ style: { display: "none" } }}
                onConfirm={() => {
                    setIsAboutOpen(false);
                }}
            >
                <AboutContent> </AboutContent>
            </StriimModal>
            <UploadModal
                localStore={uploadModalStore}
                modalState={uploadModalState}
                onSelect={path => {
                    copyPath(path);
                }}
            />

            <StriimModal
                isVisible={isAgentDownloadOpen}
                titleContent={<StriimTypography variant="h2">Download Striim Forwarding Agent</StriimTypography>}
                onCancel={() => {
                    setIsAgentDownloadOpen(false);
                }}
                cancelContent="Close"
                size={"medium"}
                confirmButtonProps={{ style: { display: "none" } }}
                onConfirm={() => {
                    setIsAgentDownloadOpen(false);
                }}
            >
                <AgentDownloadContent> </AgentDownloadContent>
            </StriimModal>

            {/*Documentation Dropdown*/}
            {!hideDocs && (
                <>
                    <StriimDropdown
                        onOpen={() => {
                            closeAllExcept("DOC");
                        }}
                        placement="bottom"
                        content={<DropdownDocumentationContent openDocumentationModal={documentationState} />}
                        openDelay={300}
                        closeOnMouseLeave
                    >
                        <Box
                            variant="text"
                            component={StriimButton}
                            className={classes.whiteButtonVariant}
                            mr={3}
                            id={"nav--documentation"}
                        >
                            Documentation
                        </Box>
                    </StriimDropdown>
                </>
            )}
            <div className={classes.divider}></div>
            <div className={classes.IconContainer}>
                {/*Alerts Dropdown*/}
                <StriimDropdown
                    onOpen={() => {
                        closeAllExcept("ALERTS");
                    }}
                    placement="bottom"
                    controlState={notificationState}
                    TooltipProps={{
                        classes: classes.tooltipWrapper
                    }}
                    content={<AlertsList alertsModalState={notificationState} store={headerStore} />}
                    openDelay={300}
                    closeOnMouseLeave
                >
                    <Box>
                        <AlertIcon store={headerStore} />
                    </Box>
                </StriimDropdown>

                {/*About DropDown*/}
                <StriimDropdown
                    onOpen={() => {
                        closeAllExcept("ABOUT");
                    }}
                    placement="bottom"
                    closeOnSelect={true}
                    content={
                        <DropdownHelpContent
                            helpModalState={helpState}
                            openAboutModal={openAboutModal}
                            openAgentModal={openAgentModal}
                        />
                    }
                    controlState={helpState}
                    TooltipProps={{
                        classes: { tooltip: classes.tooltipWrapper }
                    }}
                    openDelay={300}
                    closeOnMouseLeave
                >
                    <StriimIconButton>
                        <Help className={classes.iconWhite} id={"nav--about"} />
                    </StriimIconButton>
                </StriimDropdown>
                {/*Chatbot Button*/}
                {copilotService.isCopilotEnabled && <StriimGPTWrapper classes={classes} />}
                {/*Account Dropdown*/}
                <StriimDropdown
                    onOpen={() => {
                        closeAllExcept("ACCOUNT");
                    }}
                    placement="bottom"
                    content={<DropdownAccountContent accountState={accountState} uploadModalState={uploadModalState} />}
                    controlState={accountState}
                    TooltipProps={{
                        classes: { tooltip: classes.tooltipWrapper }
                    }}
                    openDelay={300}
                    closeOnMouseLeave
                >
                    <StriimIconButton>
                        <UserCircle className={classes.iconWhite} id={"nav--account"} />
                    </StriimIconButton>
                </StriimDropdown>
            </div>
        </Box>
    );
};

export default RightToolBarActions;
