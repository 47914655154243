import _ from "underscore";
import BaseControl from "./../base-control";
import UIControl from "app/components/common/editor/control/ui-control";
import { loadAscendantsDbSources } from "app/components/flow/designer/component-acendants-loader";

var View = BaseControl.View.extend({
    template: _.template(""),
    className: "",

    initialize: function (options) {
        this.config = options.transformationConfig || {};
    },

    setViewValue: function () {},

    getViewValue: function () {
        return this.model.value;
    },

    setStream: function (componentId) {
        this.$el.closest(".field-row").hide();

        setTimeout(
            function () {
                this.model.value = [{ column: componentId }];
                this.trigger(UIControl.EVENT.VALUE_CHANGED, this.model.value);
            }.bind(this),
            0
        );
    },
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: Array,
    },
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
