import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    wrapperRoot: {
        marginTop: spacing(1)
    },
    ssoChip: {
        borderRadius: 6,
        padding: spacing(0, 0.5),
        background: themeV5 => themeV5.palette.success[50],
        "& span": {
            fontSize: 12,
            color: themeV5 => themeV5.palette.success[500]
        }
    },
    striimChip: {
        borderRadius: 6,
        padding: spacing(0, 0.5),
        background: themeV5 => themeV5.palette.greyscale[100],
        "& span": {
            fontSize: 12,
            color: themeV5 => themeV5.palette.greyscale[700]
        }
    }
}));
