import Backbone from "backbone";
import { CompositeView } from "marionette";
import App from "app";
import ValidationErrorView from "./validation-error";
import { loadValidationErrors } from "../validation-errors-storage";
import dataTypeValidationLoader from "../data-type-validation-loader";
import { template, className, childViewContainer, ui, childView, on, onChild } from "core/decorators";
import errorsTemplate from "./validation-errors-template.html";

const editComponentEventName = "edit:component";

@template(errorsTemplate)
@className("app-errors")
@childViewContainer(".app-exceptions")
@childView(ValidationErrorView)
@ui({
    validateLink: "header a",
})
class ValidationErrorsView extends CompositeView {
    _isValidating = false;

    onDestroy() {
        $(window).off("resize.validationErrorResize");
    }

    @onChild("source-click")
    onSourceClick(childView) {
        if (this._isValidating === true) {
            return;
        }
        App.vent.trigger(editComponentEventName, childView.model.get("srcComponentName"));
    }

    @onChild("target-click")
    onTargetClick(childView) {
        if (this._isValidating === true) {
            return;
        }
        App.vent.trigger(editComponentEventName, childView.model.get("tarComponentName"));
    }

    _onSidebarResize() {
        let newHeight = this.$el.parent().outerHeight() - this.$el.find("header").outerHeight(); //height of sidebar

        this.$el.find(".app-exceptions").css("height", newHeight + "px");
    }

    initialize(options) {
        this.deploymentInfo = options.deploymentInfo;
        this.initSideBarResizer();
    }

    onRender() {
        setTimeout(this._onSidebarResize.bind(this), 100);
    }

    initSideBarResizer() {
        // the editors containers height has to be calculated in javascript
        $(window)
            .off("resize.validationErrorResize")
            .on("resize.validationErrorResize", this._onSidebarResize.bind(this));
    }

    @on("click @ui.validateLink")
    validateAgain() {
        if (this._isValidating === true) {
            return;
        }
        if (!this.deploymentInfo) {
            console.error("deploymentInfo is not set");
            return;
        }
        this.$el.addClass("reloading");
        this._isValidating = true;
        dataTypeValidationLoader(this.model.app.id, this.deploymentInfo)
            .then((errors) => {
                if (errors === null) {
                    errors = [];
                }
                this.collection.reset(errors);
            })
            .finally(
                function () {
                    this.$el.removeClass("reloading");
                    this._isValidating = false;
                }.bind(this)
            );
    }
}

function createView(model) {
    return loadValidationErrors(model.app).then((content) => {
        if (!content || !content.errors || content.errors.length === 0) {
            return null;
        }

        return new ValidationErrorsView({
            model: model,
            deploymentInfo: content.deploymentInfo,
            collection: new Backbone.Collection(content.errors),
        });
    });
}

export default createView;
