import App from "app";
import Api from "core/api/api";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import growl from "app/components/common/growl";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import FormBuilder from "app/components/common/editor/form-builder";
import formBuilderUtils from "app/components/common/editor/meta-object-editors";
import UIControl from "app/components/common/editor/control/ui-control";
import template from "./default-pre-built-dashboard-view.html";

var module = {};

module.View = Backbone.Marionette.LayoutView.extend({
    template: _.template(template),
    className: "kafka-wizard",
    regions: {
        formContainer: "#source-form-container",
    },
    ui: {
        description: "#description",
    },
    initialize: function (options) {
        var that = this;

        this.nsName = MetaObjectConverter.getNamespace(options.appName);
        var appId = MetaObjectConverter.ensureFullId(options.appName, "APPLICATION");

        this.on("do:next", function () {
            var templateFileName = that.getDashboardTemplateName();

            var templateData = that.form.model.data.attributes;
            templateData.namespaceName = that.nsName;

            var templateDataString = JSON.stringify(templateData);

            Api.importPartialDashboard(appId, templateFileName, templateDataString)
                .then(function() {
                    growl.success(null, "Created dashboard.", appId, appId);
                })
                .fail(function (e) {
                    growl.error("Cannot create dashboard." + e.message, "Error");
                })
                .always(function () {
                    App.vent.trigger("appwizard:next:step");
                });
        });
    },

    onRender: function () {
        var description = this.getDescription();
        if (description) {
            this.ui.description.html(description);
            this.ui.description.show();
        }

        this.form = this.getFormBuilder().create();
        this.getRegion("formContainer").show(this.form);
    },

    getFormBuilder: function () {
        this.formModel = new Backbone.Model({
            dashboardName: "",
        });

        return new FormBuilder({
            model: this.formModel,
            autoSave: true,
            mapper: {
                dashboardName: UIControl.TextField,
            },
            labels: {
                dashboardname: {
                    name: "Dashboard Name",
                },
            },
        });
    },

    getDashboardTemplateName: function () {
        return "sample_dashboard.json.template";
    },

    getDescription: function () {
        return "<p></p>";
    },
});

export default module;
