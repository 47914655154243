import React, { useEffect, useMemo, useState } from "react";
import { StriimMessageBox, StriimTypography, StriimLink, StriimButton } from "@striim/striim-ui";
import { Box, Grid, SvgIcon } from "@mui/material";
import { AddCircleOutline as AddIcon } from "@mui/icons-material";

import PanelHeader from "../panel-header/panel-header";
import { JobStatuses, JobVersion, Version } from "../../../guardian/guardian-job.types";
import DiscoveryPanelSourceHeader from "../discovery-panel-source-header/discovery-panel-source-header";
import SourceDetails from "./source-details";
import { Entity, SensitiveIdentifiersInEntities, Source } from "../../pii.types";
import DiscoveryReportChart from "../discovery-report-chart/discovery-report-chart";
import DiscoverySummaryChart from "../discovery-summary-chart/discovery-summary-chart";
import CollapsibleHeading from "../../../guardian/components/collapsible-heading/collapsible-heading";
import dictionary from "../../../../../../app/components/common/helpable/online-help-dictionary";
import dateLib from "core/utils/date-utils";
import { styles } from "./panel-results.styles";
import growl from "../../../../../../app/components/common/growl";
import guardianService from "../../../guardian/guardian-service";
import { WarningTriangle } from "../../../../../generic/icon/customIcons";
import { getCollapsibleSectionContent } from "../../utils";
import {
    ErrorBox,
    ErrorMessagesModal,
    SelectedErrorModal
} from "../../../guardian/components/job-list-insights-view/job-list-insights-view";
import { FlowStatus } from "../../../../../status-management";
import appStatusSynchronizer from "../../../../../../core/appStatusSynchronizer";

interface PanelResultsProps {
    sources: Source[];
    onToggle: Function;
    onClose: Function;
    showLeftSidePanel?: boolean;
    showPanelInFullScreen?: boolean;
    isSidebarExpanded: boolean;
    formValues: {
        name: string;
        description?: string;
    };
    toggleFullScreen: Function;
    jobVersions?: JobVersion[];
    updatedTime: number;
    sourcesWithError: string[];
    currentJobVersion: JobVersion;
    handleVersionChange: (value: Version) => void;
    hasSensitiveData?: boolean;
    fetchUpdatedJobVersionDetails: () => Promise<void>;
    addSentinel: Function;
    nodes: any;
    namespace: string;
    flowStatus: string;
    retryJob: () => Promise<void>;
    onCreateNewJob: () => void;
    appName: string;
    timeRange: { from: string; to: string };
    jobName: string;
}

export const getEntityReportChartData = (
    data: SensitiveIdentifiersInEntities
): {
    name: string;
    count: number;
    items: string[];
}[] => {
    const list = Object.entries(data);
    if (!list.length) return [];
    const formattedData = list.sort((x, y) => y[1].length - x[1].length);
    return formattedData.map(item => ({
        name: item[0],
        count: item[1].length,
        items: item[1]
    }));
};

const getSensitiveIdentifiers = (data, isTypeEntity): { label: string; value: string }[] => {
    return [
        ...new Set(
            data?.reduce(
                (acc, curr) => [
                    ...acc,
                    ...(isTypeEntity ? curr.sensitiveDataIdentifiers.map(v => v.name) : curr.sensitiveDataIdentifiers)
                ],
                []
            )
        )
    ]
        .sort()
        .map(value => ({
            value,
            label: value
        }));
};

const getSourceNamesFormatted = sources => {
    let result = sources.join(", ");
    if ((result.match(/,/g) || []).length > 0) {
        return result.replace(/,([^,]*)$/, " &$1");
    }
    return result;
};

const isCurrentJobVersionOlderThanAMonth = (jobVersion: JobVersion): boolean => {
    const updatedTime = dateLib.unix(Number(jobVersion.completedTime) / 1000);
    const oneMonthAgo = dateLib().subtract(1, "month");
    return updatedTime.isBefore(oneMonthAgo);
};

const PanelResults = ({
    sources,
    currentJobVersion,
    formValues,
    showLeftSidePanel = false,
    showPanelInFullScreen = false,
    isSidebarExpanded,
    onToggle,
    toggleFullScreen,
    onClose,
    jobVersions,
    updatedTime,
    sourcesWithError = [],
    handleVersionChange,
    hasSensitiveData = true,
    fetchUpdatedJobVersionDetails,
    addSentinel,
    nodes,
    namespace,
    flowStatus,
    retryJob,
    onCreateNewJob,
    appName,
    timeRange,
    jobName
}: PanelResultsProps) => {
    const [activeSource, setActiveSource] = useState<Source>(sources[0]);
    const [activeEntity, setActiveEntity] = useState<Entity | null>(null);
    const [filteredTableData, setFilteredTableData] = useState(null);
    const { name, description } = currentJobVersion ?? formValues;
    const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);
    const [showSelectedErrorModal, setShowSelectedErrorModal] = useState<boolean>(false);
    const [selectedError, setSelectedError] = useState<{ index: number; description: string } | null>(null);
    const errorMessages = useMemo(
        () =>
            sources
                .filter(source => source.status === JobStatuses.ERROR)
                .map(sourceWithError => sourceWithError.errorMessage),
        [sources]
    );
    const [isDisabled, setIsDisabled] = useState(flowStatus !== FlowStatus.CREATED);

    useEffect(() => {
        appStatusSynchronizer.on("status_change", ({ newStatus }) => {
            setIsDisabled(newStatus !== FlowStatus.CREATED);
        });
    }, []);

    const updateDescription = async updatedDescription => {
        try {
            await guardianService.updateJobDescription(
                currentJobVersion.name,
                currentJobVersion.nsName,
                updatedDescription,
                currentJobVersion
            );
            await fetchUpdatedJobVersionDetails();
        } catch (error) {
            growl.error("Error updating description", error);
        }
    };

    const currentTableData = (!activeEntity ? activeSource?.entities : activeEntity.columns) ?? [];
    const sensitiveDataIdentifiers = getSensitiveIdentifiers(currentTableData, !!activeEntity);

    const formattedUpdateTime = dateLib.unix(updatedTime / 1000).format("MMM DD, YYYY hh:mm a");
    const errorSourceNames = sourcesWithError.length ? getSourceNamesFormatted(sourcesWithError) : null;

    const versionOptions = useMemo(() => {
        return jobVersions?.map(version => ({ name: version.name, updatedTime: Number(version.completedTime) }));
    }, [jobVersions]);

    const mostRecentJobVersion = jobVersions[0];
    const isReportOlderThanAMonth = isCurrentJobVersionOlderThanAMonth(currentJobVersion);
    const showNewerReportAvailabilityInfobox =
        mostRecentJobVersion.name !== currentJobVersion.name && !isReportOlderThanAMonth;
    const outputStreamNode = nodes.filter(node => node.id === activeSource?.outputStream)?.[0];
    let sentinelsConnectedToOutputStreamNode = outputStreamNode?.targets?.find(target =>
        target.startsWith(`${namespace}.SENTINEL`)
    );

    return (
        <>
            {!showPanelInFullScreen && (
                <>
                    <PanelHeader
                        name={name}
                        description={description}
                        isSidebarExpanded={isSidebarExpanded}
                        onClose={onClose}
                        onToggle={onToggle}
                        versions={versionOptions}
                        selectedVersion={currentJobVersion}
                        updatedTime={updatedTime}
                        updateDescription={updateDescription}
                        handleVersionChange={handleVersionChange}
                    />
                    {sourcesWithError.length ? (
                        // TODO: Change message box text
                        <Grid container p={2} sx={styles.bottomBorder}>
                            <ErrorBox
                                messages={errorMessages}
                                setShowErrorsModal={setShowErrorsModal}
                                setShowSelectedErrorModal={setShowSelectedErrorModal}
                                setSelectedError={setSelectedError}
                            />
                            <ErrorMessagesModal
                                jobName={currentJobVersion?.name}
                                messages={errorMessages}
                                showErrorsModal={showErrorsModal}
                                setShowErrorsModal={setShowErrorsModal}
                                setShowSelectedErrorModal={setShowSelectedErrorModal}
                                setSelectedError={setSelectedError}
                                isDiscoverPIIPanel={true}
                            />
                            <SelectedErrorModal
                                selectedError={selectedError}
                                showSelectedErrorModal={showSelectedErrorModal}
                                setShowSelectedErrorModal={setShowSelectedErrorModal}
                                setShowErrorsModal={setShowErrorsModal}
                                messages={errorMessages}
                                isDiscoverPIIPanel={true}
                                handleRetry={retryJob}
                            />
                        </Grid>
                    ) : null}
                    {showNewerReportAvailabilityInfobox || isReportOlderThanAMonth ? (
                        <Grid p={2} sx={styles.bottomBorder}>
                            <StriimMessageBox
                                type="WARNING"
                                text={
                                    <Grid container display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                                        <StriimTypography
                                            variant="caption3"
                                            fontFamily="Inter"
                                            color="greyscale.900"
                                            letterSpacing="0.24px"
                                        >
                                            {isReportOlderThanAMonth
                                                ? `This report is older than a month and the results may not accurately reflect the sensitive data that can flow into your app.`
                                                : `This is an older report and the results may not accurately reflect the
                                            sensitive data that can flow into your app.`}
                                        </StriimTypography>
                                        <StriimLink
                                            href=""
                                            target="_blank"
                                            onClick={() => {
                                                isReportOlderThanAMonth
                                                    ? onCreateNewJob()
                                                    : handleVersionChange(versionOptions[0]);
                                            }}
                                            sx={{ p: 0, fontWeight: 400 }}
                                        >
                                            {isReportOlderThanAMonth ? (
                                                <>
                                                    <SvgIcon component={AddIcon} sx={styles.createReportIcon} />
                                                    Report
                                                </>
                                            ) : (
                                                `View the most recent report`
                                            )}
                                        </StriimLink>
                                    </Grid>
                                }
                                customCardStyles={styles.warningMessageBox}
                                customIcon={<SvgIcon component={WarningTriangle} sx={styles.warningIcon} />}
                            />
                        </Grid>
                    ) : null}
                </>
            )}
            {showPanelInFullScreen ? (
                <DiscoveryPanelSourceHeader
                    activeEntity={activeEntity}
                    setActiveEntity={(entity: Entity) => {
                        setFilteredTableData(entity.columns);
                        setActiveEntity(entity);
                    }}
                    source={activeSource}
                    appName={appName}
                    timeRange={timeRange}
                    setActiveSource={(source: Source) => {
                        setFilteredTableData(source.entities);
                        setActiveSource(source);
                    }}
                    lastUpdatedDateTime={formattedUpdateTime}
                    onClose={() => {
                        setActiveSource(sources[0]);
                        setFilteredTableData(null);
                        setActiveEntity(sources[0]?.entities);
                        toggleFullScreen();
                    }}
                    onDownload={() => {}}
                    onFullScreen={toggleFullScreen}
                    isFullScreenView={showPanelInFullScreen}
                    addSentinel={() => {
                        addSentinel(activeSource, outputStreamNode);
                    }}
                    sources={sources}
                    showBackButton={!!activeEntity}
                    onBackClick={() => {
                        setFilteredTableData(null);
                        setActiveEntity(null);
                    }}
                    setTableData={setFilteredTableData}
                    isAddSentinelDisabled={!!sentinelsConnectedToOutputStreamNode?.length || isDisabled}
                    jobName={jobName}
                />
            ) : (
                <Box sx={styles.sourcesContainer}>
                    <Grid container sx={styles.collapsibleSection}>
                        <CollapsibleHeading
                            title="How to use these results"
                            defaultOpen={true}
                            url={dictionary.get()["AI_INSIGHTS_SHERLOCK"].href}
                            externalTrigger={null}
                        >
                            {getCollapsibleSectionContent(hasSensitiveData)}
                        </CollapsibleHeading>
                    </Grid>
                    {sources.map((source, index) => {
                        const outputStreamNode = nodes.filter(node => node.id === source.outputStream)?.[0];
                        sentinelsConnectedToOutputStreamNode = outputStreamNode?.targets?.find(target =>
                            target.startsWith(`${namespace}.SENTINEL`)
                        );
                        return (
                            <Grid sx={index % 2 !== 0 && styles.evenSection}>
                                <DiscoveryPanelSourceHeader
                                    activeEntity={activeEntity}
                                    setActiveEntity={(entity: Entity) => {
                                        setFilteredTableData(entity.columns);
                                        setActiveEntity(entity);
                                    }}
                                    source={source}
                                    appName={appName}
                                    timeRange={timeRange}
                                    setActiveSource={(source: Source) => {
                                        setFilteredTableData(source.entities);
                                        setActiveSource(source);
                                    }}
                                    entity={source.type}
                                    lastUpdatedDateTime={formattedUpdateTime}
                                    onClose={() => {
                                        setActiveSource(null);
                                        setFilteredTableData(null);
                                        setActiveEntity(null);
                                        toggleFullScreen();
                                    }}
                                    onDownload={() => {}}
                                    onFullScreen={source => {
                                        setActiveSource(source);
                                        setFilteredTableData(source.entities);
                                        setActiveEntity(null);
                                        toggleFullScreen();
                                    }}
                                    isFullScreenView={showPanelInFullScreen}
                                    addSentinel={() => {
                                        addSentinel(source, outputStreamNode);
                                    }}
                                    sources={sources}
                                    showBackButton={!!activeEntity}
                                    onBackClick={() => {
                                        setFilteredTableData(null);
                                        setActiveEntity(null);
                                    }}
                                    setTableData={setFilteredTableData}
                                    isAddSentinelDisabled={!!sentinelsConnectedToOutputStreamNode?.length || isDisabled}
                                    jobName={jobName}
                                />
                                <DiscoverySummaryChart
                                    entity={source.type}
                                    entitiesScanned={source.totalEntities}
                                    summaryData={{
                                        sensitiveData: source.entitiesWithSensitiveData,
                                        nonSensitiveData: source.totalEntities - source.entitiesWithSensitiveData
                                    }}
                                    showBottomBorder={true}
                                />
                                <DiscoveryReportChart
                                    entity={source.type}
                                    data={getEntityReportChartData(source.sensitiveIdentifiersInEntities)}
                                    sensitiveDataCount={source.entitiesWithSensitiveData}
                                    showBottomBorder={index !== sources.length - 1}
                                />
                            </Grid>
                        );
                    })}
                </Box>
            )}
            {showPanelInFullScreen && (
                <SourceDetails
                    tableData={currentTableData}
                    filteredTableData={filteredTableData ?? (currentTableData || [])}
                    setFilteredTableData={setFilteredTableData}
                    activeSource={activeSource}
                    activeEntity={activeEntity}
                    setActiveEntity={(entity: Entity) => {
                        setFilteredTableData(entity.columns);
                        setActiveEntity(entity);
                    }}
                    sensitiveDataIdentifiers={sensitiveDataIdentifiers}
                />
            )}
        </>
    );
};

export default PanelResults;
