var systemTime = "System Time";
var eventTime = "Event Time";
export default {
    Type: {
        TimeBased: "time",
        CountBased: "count",
        Hybrid: "hybrid",
    },
    Fields: {
        Time: "time",
        Count: "count",
        OnField: "onField",
        Timeout: "timeout",
        Type: "type",
        OutputInterval: "outputInterval",
    },
    SystemTime: systemTime,
    EventTime: eventTime,
    TimeOptions: [
        {
            id: systemTime,
            text: systemTime,
        },
        {
            id: eventTime,
            text: eventTime,
        },
    ],
};
