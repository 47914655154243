import React, { useEffect, useState } from "react";
import useStores from "../../../../utils/use-stores";
import metaStoreService from "../../../../../core/services/metaStoreService/meta-store-service";
import metaobjectConverter from "../../../../../core/services/metaStoreService/metaobject-converter";
import DropAppModal from "../../../../generic/drop-app-modal/drop-app-modal";
import { StriimTheme } from "@striim/striim-ui";
import { isAutomatedWizard } from "../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";

const DropWrapper = ({ shortName }) => {
    const [modalVisible, setModalVisible] = useState(true);
    const [appModel, setAppModel] = useState(null);
    const [namespace, appName] = shortName.split(".");
    const { store } = useStores();

    useEffect(() => {
        metaStoreService
            .findById(metaobjectConverter.getId(namespace, metaStoreService.entities.APPLICATION, appName))
            .then(app => {
                const appJSON = app.toJSON();
                appJSON.shortName = shortName;
                setAppModel(appJSON);
            });
    }, []);

    return (
        <StriimTheme>
            {appModel && (
                <DropAppModal
                    store={store}
                    app={appModel}
                    dropModalVisible={modalVisible}
                    setDropModalVisible={setModalVisible}
                    isILCDC={isAutomatedWizard()}
                />
            )}
        </StriimTheme>
    );
};

export default DropWrapper;
