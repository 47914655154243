import React from "react";
import { Section } from "../report-components";
import { Box, Grid } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import DiscoverySummaryChart from "../../../discover-pii-panel/components/discovery-summary-chart/discovery-summary-chart";
import { AdapterIcon } from "../../../../../generic/icon/component-icon/component-icon";
import { styles } from "../../sentinel-reports/global-report/sentinel-global-report.styles";

import JobInsightsChart from "../../../guardian/components/job-insights-chart/job-insights-chart";
import { getPercentage } from "../report-tables/report-tables.helper";
import { ChartLegend } from "./sentinel-report-charts";
import { SherlockAppSDI } from "../report-tables/sherlock-report-tables";

const getEntityData = source => {
    let totalColumns = 0,
        columnsWithSensitiveData = 0;

    source.entities.forEach(item => {
        totalColumns += item.totalColumns;
        columnsWithSensitiveData += item.columnsWithSensitiveData;
    });

    return {
        fieldType: source.fieldType,
        totalColumns: totalColumns,
        columnsWithSensitiveData: columnsWithSensitiveData
    };
};

const getSDI = data => data.map(item => ({ name: item[0], count: item[1].length }));

export const SourceCharts = ({ sourceData }) => {
    return (
        <>
            {sourceData.map(source => {
                const { fieldType, totalColumns, columnsWithSensitiveData } = getEntityData(source);
                const SDIData = getSDI(Object.entries(source.sensitiveIdentifiersInEntities));

                return (
                    <Grid my={2} key={source.name}>
                        <Grid container flexDirection={"row"} justifyContent={"space-between"}>
                            <Box display={"flex"} gap={1} alignItems={"center"}>
                                <AdapterIcon name={source.className} type={"source"} size={32} outlined={true} />
                                <StriimTypography variant="h3">Source: {source.name}</StriimTypography>
                            </Box>
                            <StriimTypography sx={styles.smallFont}>Type: {source.adapterName}</StriimTypography>
                        </Grid>
                        <Section>
                            <DiscoverySummaryChart
                                entity={source.type}
                                entitiesScanned={source.totalEntities}
                                summaryData={{
                                    sensitiveData: source.entitiesWithSensitiveData,
                                    nonSensitiveData: source.totalEntities - source.entitiesWithSensitiveData
                                }}
                                showBottomBorder={true}
                                isReport={true}
                            />
                            <DiscoverySummaryChart
                                entity={fieldType}
                                entitiesScanned={totalColumns}
                                summaryData={{
                                    sensitiveData: columnsWithSensitiveData,
                                    nonSensitiveData: totalColumns - columnsWithSensitiveData
                                }}
                                showBottomBorder={true}
                                isReport={true}
                            />
                        </Section>
                        <Section showborderBottom={false}>
                            <Grid container gap={2} my={2}>
                                <StriimTypography variant="h3">Top 10 Sensitive Data Identifiers</StriimTypography>
                                <SherlockAppSDI data={SDIData} totalTables={source.entitiesWithSensitiveData} />
                            </Grid>
                        </Section>
                    </Grid>
                );
            })}
        </>
    );
};

export const InsightsChart = ({ appDataStatus, jobStatus }) => {
    return (
        <Grid container item gap={1} mb={1} justifyContent={"space-between"}>
            <Box>
                <Box mt={1}>
                    <StriimTypography variant="h3" sx={{ marginTop: 1 }}>
                        Apps with Sensitive Data: {appDataStatus.sensitiveData}/{appDataStatus.totalApps} (
                        {getPercentage(appDataStatus.sensitiveData, appDataStatus.totalApps)}
                        %)
                    </StriimTypography>
                </Box>
                <JobInsightsChart
                    data={{
                        sensitiveData: appDataStatus.sensitiveData,
                        nonSensitiveData: appDataStatus.nonSensitiveData,
                        errors: appDataStatus.errors,
                        totalApps: appDataStatus.totalApps,
                        totalSources: appDataStatus.totalSources
                    }}
                    status={jobStatus}
                    isReport={true}
                />
            </Box>
            <Box display={"flex"} flexDirection={"column"} alignItems={"end"}>
                <ChartLegend
                    data={[
                        { color: "#6262C2", label: "W/ sensitive data" },
                        { color: "#F5607A", label: "Errors" },
                        { color: "#67C5BC", label: "W/O sensitive data" }
                    ]}
                />
                <StriimTypography sx={styles.smallFont}>
                    Sensitive Data identifiers discovered: {appDataStatus.sensitiveData}
                </StriimTypography>
                <StriimTypography sx={styles.smallFont}>
                    Sources with Sensitive Data: {appDataStatus.sourcesWithPII}/{appDataStatus.totalSources}
                </StriimTypography>
            </Box>
        </Grid>
    );
};
