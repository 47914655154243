import React from "react";
// @ts-ignore
import { StriimLink } from "@striim/striim-ui";
import dictionary from "app/components/common/helpable/online-help-dictionary";

export default function EmbeddingGeneratorDocumentationLink() {
    return (
        <StriimLink
            href={dictionary.get()["MANAGE_STRIIM_EMBEDDING_GENERATOR"].href}
            target="_blank"
            sx={{ marginBottom: "5px", fontWeight: "400" }}
        >
            View Documentation
        </StriimLink>
    );
}
