import Marionette from "marionette";
import "jquery-ui";

var Behaviors = {};

Behaviors.Sortable = Marionette.Behavior.extend({
    onShow: function () {},
    onRender: function () {
        var _this = this;
        var collection = this.view.collection;
        var items = this.view.children._views;
        var view;

        for (var v in items) {
            if (items.hasOwnProperty(v)) {
                view = items[v];
                view.$el.attr("data-backbone-cid", view.model.cid); //TODO: is it really needed?
            }
        }
        var el = this.$el[0].localName === "table" ? this.$el.find("tbody") : this.$el;
        el.sortable({
            // Make the list sortable
            axis: this.options.axis || false,
            grid: this.options.grid || false,
            containment: this.options.containment || false,
            cursor: "move",
            handle: this.options.handle || false,
            revert: this.options.revert || false,
            update: function (event, ui) {
                var model = collection.get(ui.item.data("backbone-cid"));
                collection.remove(model, {
                    silent: true,
                });
                collection.add(model, {
                    at: ui.item.index(),
                    silent: true,
                });
                _this.view.trigger("modified");
            },
        });
    },
});

Marionette.Behaviors.behaviorsLookup = function () {
    return Behaviors;
};

export default Behaviors;
