import Backbone from "backbone";
import Marionette from "marionette";
import $ from "jquery";
import "lib/vendor/jquery-migrate/jquery-migrate";
import _ from "underscore";
import ModalManager from "./app/components/common/modal/ModalManager";
import videoDialogTemplate from "./app/components/common/app-control/video-dialog.html";
import helpableConfig from "./app/components/common/helpable/helpable-config";
import iframeDetector from "./core/iframeDetector";
import errorConsole from "./app/components/errorconsole/errorConsole";
import utils from "./core/utils";
import analytics from "./core/services/analytics";
import Tracker from "./core/services/tracker/tracker";
import ChameleonTracker from "./core/services/tracker/chameleonTracker";
import trackingCookieExists from "./core/services/tracker/trackingCookieExists";
import SaaSCookieExists from "./core/services/tracker/SaaSCookieExists";

/*import mariaDBIPSVG from "app/images/components-input-mariadb.svg";
import mariaDBOPSVG from "app/images/components-output-mariadb.svg";
import azureSynapse from "app/images/components-output-azure-synapse.svg";
import mongoDBSVG from "app/images/components-output-mongo.svg";
import SpannerInputSVG from "app/images/Spanner_components-input-spanner.svg";
import ExternalCacheSVG from "app/images/icon-btn-externalcache.svg";
import SAPHANA from "app/images/components-output-saphana.svg";
import HTTPWRITERSVG from "app/images/http-writer.svg";
import DB_TRANSFORMER_TO_STAGING from "app/images/db-event-trasnformers-to-staging_db-event-transformers-to-staging.svg";
import TRANSFORMATION_JSON_ICON from "app/images/transformation-json.svg";*/

import "app/styles/materialize/materialize.scss";
import "app/styles/materialize/materialize-striimline.less";
import "app/styles/style.less";
import { TRACKER_STRINGS } from "./core/services/tracker/constants";

var WebActionUI = new Marionette.Application();

WebActionUI.addRegions({
    header: "#header",
    content: "#content",
    debugger: "#debugger",
    reactContent: "#react-content"
});

WebActionUI.addInitializer(function() {
    // Fire an app-wide window resize event (debounced)
    // TODO: where does this belong
    $(window)
        .off("resize.webaction")
        .on(
            "resize.webaction",
            _(function() {
                var data = {
                    width: $(this).innerWidth(),
                    height: $(this).innerHeight()
                };
                WebActionUI.vent.trigger("window:resize", data);
            }).debounce(50)
        );
});

WebActionUI.addInitializer(function() {
    $("#adBlockMessage").remove();
});

WebActionUI.addInitializer(function() {
    /*let addIcons = function(selector, icon) {
        $(selector).each(function() {
            if ($(this).find("svg").length === 0) {
                let size = $(this).css("font-size");
                let color = $(this).css("color");
                let $svgNode = $(icon).css({ width: size });
                $svgNode.find("path").css({ fill: color });
                $(this).append($svgNode);
            }
        });
    };

    setInterval(() => {
        addIcons("div .icon-components-input-mariadb", mariaDBIPSVG);
        addIcons("div .icon-components-output-mariadb", mariaDBOPSVG);
        addIcons("div .icon-components-output-azuresqldwh-svg", azureSynapse);
        addIcons("div .icon-components-output-mongodb", mongoDBSVG);
        addIcons("div .icon-components-input-spanner", SpannerInputSVG);
        addIcons("div .icon-btn-externalcache", ExternalCacheSVG);
        addIcons("div .icon-components-output-saphana", SAPHANA);
        addIcons("div .icon-components-output-http", HTTPWRITERSVG);
        addIcons("div .icon-btn-transformation-waeventtodwstage-svg", DB_TRANSFORMER_TO_STAGING);
        addIcons("div .icon-btn-transformation-json-svg", TRANSFORMATION_JSON_ICON);
    }, 1000);*/

    var VideoView = WebActionUI.FormMixinDialog.View.extend({
        template: _.template(videoDialogTemplate),
        triggers: {
            "click .cancel": "form:cancel"
        }
    });
    let modalManager = new ModalManager();

    $("body").on("click", ".samples-demo-video", function(e) {
        let alertDialogView = new VideoView({
            model: new Backbone.Model()
        });

        alertDialogView.on("cancel", () => {
            alertDialogView.destroy();
        });

        modalManager.add(alertDialogView);
    });
});

WebActionUI.on("start", function() {
    WebActionUI.hasStarted = true;
});

WebActionUI.addInitializer(function() {
    document.getElementById("workerProgress").style.display = "none";

    if (iframeDetector.isInIframe()) {
        return;
    }

    errorConsole.initialize();

    WebActionUI.commands.setHandler("errorconsole:notify", function(options) {
        // add a command handler..
        errorConsole.notify(options);
    });

    WebActionUI.vent.on(
        "global:app:error",
        function() {
            $(".error-message-overlay").css("display", "table");
        }.bind(this)
    );

    WebActionUI.vent.on("global:app:connected", function(connectionTryCount) {
        $(".error-message-overlay").css("display", "none");
    });

    WebActionUI.vent.on("global:app:disconnected", function() {
        utils.clearIntervals();
    });
});

/**
 * Start Online help
 */
WebActionUI.addInitializer(function() {
    helpableConfig.init();
});

WebActionUI.addInitializer(function() {
    analytics.enableTrackingIfEnabled();

    var trackingId = trackingCookieExists();

    setTimeout(() => {
        ChameleonTracker.getInstance().identify(trackingId);

        if (trackingId && SaaSCookieExists()) {
            Tracker.getInstance().track(TRACKER_STRINGS.schema.success, {
                event: "Success:Signed in to Service",
                id: "",
                message: "SaaS user"
            });
        }
    }, 1000);
});

export default WebActionUI;
