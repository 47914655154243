export const commonDWHUtils = {
    isAppendOnly: (mode: string): boolean => mode === "APPENDONLY",
    getColumnMappingSuffix: (sourceType: string, isCDC: boolean): string => {
        if (["SALESFORCEREADER", "SALESFORCECDCREADER"].includes(sourceType.toUpperCase())) {
            return isCDC
                ? "ColumnMap(STRIIM__OPTIME=@METADATA(commitTimestamp),STRIIM__OPTYPE=@METADATA(OperationName))"
                : "ColumnMap(STRIIM__OPTIME=@METADATA(SystemModstamp),STRIIM__OPTYPE=@METADATA(OperationName))";
        }
        return "ColumnMap(STRIIM__OPTIME=@METADATA(OPERATION_TS),STRIIM__OPTYPE=@METADATA(OperationName))";
    },
    getCDDLOptions: (OPTIME_Type: string, OPTYPE_Type: string): string => {
        return JSON.stringify({
            CreateTable: {
                action: "IgnoreIfExists",
                options: [
                    { CreateSchema: { action: "IgnoreIfExists" } },
                    { AddColumns: { STRIIM__OPTIME: OPTIME_Type, STRIIM__OPTYPE: OPTYPE_Type } }
                ]
            }
        });
    },
    getModeForAppAdapters: (): string => "MERGE",
    isSourceSalesforce: (sourceType: string): boolean =>
        ["SALESFORCEREADER", "SALESFORCECDCREADER"].includes(sourceType.toUpperCase())
};
