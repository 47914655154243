import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => {
    return {
        fileBrowserContainer: {
            height: 330,
            marginBottom: 16,
            overflowY: "auto",
            width: "100%",
            backgroundColor: theme.palette.grey["100"],
            padding: 8
        },
        uploadBox: {
            width: "100%",
            height: "100px",
            padding: 8
        },
        fileBrowserRoot: {
            flexGrow: 1,
            width: "100%"
        },
        fileBrowserEntry: {
            wordBreak: "break-word",
            display: "flex",
            paddingTop: 8,
            paddingBottom: 8
        },
        fileBrowserEntryIcon: {
            marginRight: 8
        },
        dialogContentRoot: {
            overflowWrap: "break-word"
        },
        menuIcon: {
            display: "flex",
            paddingRight: theme.spacing(3)
        },
        itemContent: {
            paddingLeft: "10px",
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)"
            }
        },
        itemLabel: {
            backgroundColor: "transparent !important"
        },
        selectedItem: {
            "& > .MuiTreeItem-content": {
                backgroundColor: "rgba(0, 167, 229, 0.08)"
            }
        },
        itemGroup: {
            marginLeft: "0px !important",
            '& .MuiTreeItem-label':{
                paddingLeft:'21px'
            }
        },
    };
});
