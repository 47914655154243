export const styles = {
    container: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    icon: {
        width: 14,
        height: 14,
        fill: ({ palette }) => palette.additional.white
    },
    expanded: {
        transform: "rotate(180deg)"
    },
    iconButton: {
        minWidth: "unset",
        px: 1,
        py: 1 / 2,
        "& .MuiButton-startIcon": {
            mx: 0
        }
    },
    sentinelIcon: {
        fontSize: 22,
        "& svg": {
            fill: "none"
        },
        "&:nth-of-type(1)": {
            fontSize: `22px !important`,
            marginLeft: 0.25,
            marginTop: 0.25
        }
    },
    startIcon: {
        fontSize: 22,
        "& svg": {
            fill: "none",
            "& path": {
                stroke: "#6E7D8C"
            }
        },
        "&:nth-of-type(1)": {
            fontSize: `22px !important`,
            marginLeft: 0.25,
            marginTop: 0.25
        }
    },
    closeIcon: {
        "& svg": {
            "& path": {
                stroke: "none"
            }
        }
    },
    iconInfo: {
        height: "20px",
        width: "20px",
        fill: "transparent",
        "& path": {
            stroke: theme => theme.palette.greyscale[900]
        },
        marginTop: "7px"
    }
};
