export const styles = {
    chartLegend: {
        color: "greyscale.900",
        fontFamily: 'Inter',
        letterSpacing: '0.24px',
    },
    bottomBorder: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    sensitiveDataCount: {
        color: '#5D54AE'
    },
    tooltipWrapper: {
        borderRadius: "4px",
        border: "1px solid",
        borderColor: "greyscale.200",
        backgroundColor: "additional.white",
        boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.05)",
        padding: 2,
        minWidth: '230px'
    },
    tooltipGrouped: {
        maxHeight: 300,
        overflowY: 'auto',
        flexWrap: 'nowrap'
    },
    tooltipText: {
        fontFamily: 'Inter',
    },
    barChart: {
        stroke: '#FFF',
        strokeWidth: 2
    },
    tooltipTitle: {
        fontFamily: 'Inter',
        textTransform: 'capitalize'
    }
}