import ReactDOM from "react-dom/client";
import { StriimTheme } from "@striim/striim-ui";

export const appendComponentToBody = (data, component) => {
    let divElement = document.getElementById("printable-container");

    if (!divElement) {
        divElement = document.createElement("div");
        divElement.id = "printable-container"; // Set the id for the div
    }

    document.body.appendChild(divElement);
    const root = ReactDOM.createRoot(divElement);

    const element = <StriimTheme preselector="">{component}</StriimTheme>;
    root.render(element);
};
