import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import {
    StriimButton,
    StriimIconButton,
    StriimDropdown,
    StriimModal,
    StriimTypography,
    StriimTooltip,
    StriimMenuItem,
    StriimMenuList
} from "@striim/striim-ui";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import VaultsService from "./vaults-service";
import { VaultTypes } from "./constants";
import { MoreHoriz } from "@material-ui/icons";
import { VaultModal } from "./";
import growl from "app/components/common/growl";

const useStyles = makeStyles(({ palette }) => ({
    collapsibleContent: {
        alignItems: "center",
        justifyContent: "space-between"
    },
    link: {
        marginRight: 4
    },
    editOptions: {
        alignItems: "center",
        justifyContent: "right"
    },
    info: {
        display: "flex",
        color: palette.primary.light,
        width: 18,
        height: 18
    }
}));

const sxStyles = {
    deleteButton: {
        color: "critical.500"
    }
};

const VaultCollapsibleContent = ({
    vault,
    isEditAllowed,
    isDeleteAllowed,
    addVaultValue,
    refresh,
    collapsibleRef,
    ...rest
}) => {
    const classes = useStyles();
    const [showConfirm, setShowConfirm] = useState(false);
    const [isModalShown, setIsModalShown] = useState(false);
    const vaultName = VaultsService.getVaultTypes().find(x => x.value === vault?.vaultType).label;

    const menu = (
        <StriimMenuList data-test-id={`${vault?.name}-menu-options`}>
            {vault?.vaultType === VaultTypes.STRIIM_VAULT && isEditAllowed && (
                <StriimMenuItem
                    data-test-id="add-vault-link"
                    onClick={event => {
                        collapsibleRef.current.unCollapse();
                        addVaultValue(vault);
                    }}
                    disabled={vault.isFetching}
                >
                    Add vault value
                </StriimMenuItem>
            )}
            {vault?.vaultType !== VaultTypes.STRIIM_VAULT && isEditAllowed && (
                <StriimMenuItem
                    data-test-id="vaults-edit-menuitem"
                    onClick={() => {
                        setIsModalShown(true);
                    }}
                >
                    Edit
                </StriimMenuItem>
            )}
            {isDeleteAllowed && (
                <StriimMenuItem
                    data-test-id="vaults-delete-menuitem"
                    sx={sxStyles.deleteButton}
                    onClick={() => {
                        setShowConfirm(true);
                    }}
                >
                    Delete
                </StriimMenuItem>
            )}
        </StriimMenuList>
    );

    return (
        <>
            <Grid container className={classes.collapsibleContent} {...rest}>
                <Grid item xs={2}>
                    <p data-test-id="vault-value-namespace">{VaultsService.getNamespace(vault)}</p>
                </Grid>
                <Grid item container xs={8} className={classes.editOptions}>
                    <Grid item>
                        {vault?.vaultType !== VaultTypes.STRIIM_VAULT && (
                            <StriimTooltip
                                key={vault.id}
                                arrow
                                title={`You cannot add a ${
                                    vault?.vaultType === VaultTypes.GOOGLE_SECRET_MANAGER ? "secret" : "key"
                                } to the ${vaultName} using Striim. Please add the ${
                                    vault?.vaultType === VaultTypes.GOOGLE_SECRET_MANAGER ? "secret" : "key"
                                } by interacting directly with your ${
                                    vault?.vaultType === VaultTypes.GOOGLE_SECRET_MANAGER
                                        ? "Google Cloud Secret Manager"
                                        : vaultName
                                } environment.`}
                                data-test-id="cannot-add-tooltip"
                                placement="left"
                            >
                                <InfoOutlinedIcon className={classes.info} />
                            </StriimTooltip>
                        )}
                    </Grid>
                    {((vault?.vaultType !== VaultTypes.STRIIM_VAULT && isEditAllowed) || isDeleteAllowed) && (
                        <Grid
                            item
                            onClick={e => {
                                e.stopPropagation();
                            }}
                            className={classes.editOptions}
                        >
                            <StriimDropdown
                                extendOnClick
                                closeOnSelect
                                content={menu}
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                                placement="bottom"
                            >
                                <StriimIconButton
                                    classes={{ root: classes.cardActionsButton }}
                                    data-testid={vault?.name + "-menu"}
                                >
                                    <Box component={MoreHoriz} color="text.secondary" />
                                </StriimIconButton>
                            </StriimDropdown>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {/* Below grid is used to prevent the propagation of modal to collapsibleContent */}
            <Grid
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <StriimModal
                    size="small"
                    autoHeight
                    isVisible={showConfirm}
                    onConfirm={async () => {
                        setShowConfirm(false);
                        try {
                            await VaultsService.deleteVault(vault.name, vault.nsName);
                            growl.success(`Vault ${vault.name} deleted successfully`);
                            refresh();
                        } catch (e) {
                            growl.error(e.message, "Error");
                        }
                    }}
                    variant="error"
                    cancelButtonProps={{
                        onClick: event => {
                            event.stopPropagation();
                            setShowConfirm(false);
                        }
                    }}
                    confirmContent={"Delete"}
                    titleContent={<StriimTypography variant="h2">Please confirm this operation</StriimTypography>}
                    confirmButtonProps={{ "data-test-id": "vaults-delete-vault-button-confirm" }}
                >
                    <StriimTypography variant="body4">Are you sure you want to delete this vault?</StriimTypography>
                </StriimModal>

                {isModalShown && (
                    <VaultModal setIsModalShown={setIsModalShown} onAdded={refresh} dataForEdit={vault} isEdit={true} />
                )}
            </Grid>
        </>
    );
};

export default VaultCollapsibleContent;