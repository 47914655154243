import React from "react";
import { StriimMessageBox, StriimTypography } from "@striim/striim-ui-v2";
import { Grid, Box } from "@mui/material";
import withTheme from "../theme/theme";
const sxClasses = {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme => theme.spacing(3),
    "& .MuiCard-root": {
        backgroundColor: theme => theme.palette.critical[50],
        border: theme => `1px solid ${theme.palette.critical[100]}`,
        boxShadow: "unset",
        maxWidth: "400px",
        "& .MuiTypography-root": {
            color: theme => theme.palette.greyscale[900]
        }
    },
    "& .MuiCardHeader-avatar": {
        "& svg": {
            fill: theme => theme.palette.critical[500],
            color: theme => theme.palette.critical[500]
        }
    }
};

const ErrorBox: React.FunctionComponent<{ message: string }> = ({ message }) => {
    return (
        <Box sx={sxClasses} data-test-id="error-box">
            <StriimMessageBox
                text={
                    <StriimTypography variant="body4" data-test-id="error-box-message">
                        {message}
                    </StriimTypography>
                }
                type="ERROR"
            />
        </Box>
    );
};

export default withTheme(ErrorBox);
