import Backbone from "backbone";
import componentWizards from "app/components/appwizard/component-wizards/component-wizards";
import appTemplates from "app/components/appwizard/templates";
import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
function getStepsJSON(templateID) {
    let template = appTemplates.getByURL(templateID);
    return template;
}

export default function (options) {
    const stepsCollection = new Backbone.Collection();
    const json = getStepsJSON(options.templateID);

    let counter = 1;
    json.steps.forEach((step, id) => {
        // Use the clone of PredefinedComponentNames. To avoid changing the original object
        let predefinedComponentName = { ...PredefinedComponentNames[step] };
        if(!predefinedComponentName){
            console.error(` ${step} was not found in predefinedComponentNames.json`);
        }
        // In case of IL-CDC, Remove the string "Initial Load" from the step title
        if (options.templateID.includes("automated-ILCDC"))
            predefinedComponentName.name = predefinedComponentName.name.replace(/\b(?:Initial Load|CDC)/g, "");

        stepsCollection.add({
            title: predefinedComponentName.name,
            wizard_location: step,
            step_tooltip_title: predefinedComponentName.name,
            step_tooltip_text: predefinedComponentName.name,
            counter: counter++,
            current: id === options.componentID,
            isAutomatedILCDC: options.templateID.includes("automated-ILCDC")
        });
    });

    // console.log(stepsCollection, options);

    const component_wizard = stepsCollection.at(options.componentID);
    const component_wizard_id = component_wizard.get('wizard_location');
    const predefinedValues = {
        name: options.predefinedName,
    };
    let componentWizardView = componentWizards.getComponentWizardView(
        component_wizard_id,
        options.appName,
        options.templateID,
        options.params,
        json.steps[0],
        predefinedValues,
        options.stepName
    );

    return {
        view: componentWizardView,
        steps: stepsCollection,
    };
}
