var module = {};
var ENABLED_LOCALSTORAGE_KEY = "get:context:help:enabled";
module._enabled = null;
module.init = function () {
    module.set(true);
    module._enabled = window.sessionStorage.getItem(ENABLED_LOCALSTORAGE_KEY) === "true";
};
module.get = function () {
    return module._enabled;
};

module.set = function (enable) {
    var enableString = enable !== true ? "false" : "true";
    return window.sessionStorage.setItem(ENABLED_LOCALSTORAGE_KEY, enableString);
};

module.toggle = function () {
    module._enabled = !module._enabled;
    var enableString = module._enabled !== true ? "false" : "true";
    return window.sessionStorage.setItem(ENABLED_LOCALSTORAGE_KEY, enableString);
};

export default module;
