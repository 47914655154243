import OracleCDCWizard from "app/components/appwizard/component-wizards/wizards/source/oracle-cdc/oracle-cdc";
import MSSQLCDCWizard from "app/components/appwizard/component-wizards/wizards/source/mssql-cdc/mssql-cdc";
import MSJetWizard from "app/components/appwizard/component-wizards/wizards/source/msjet-cdc/msjet-cdc";
import MYSQLCDCWizard from "app/components/appwizard/component-wizards/wizards/source/mysql-cdc/mysql-cdc";
import MongoDBCDCWizard from "app/components/appwizard/component-wizards/wizards/source/mongodb/mongodb-cdc";
import MongoCosmosDBCDCWizard from "app/components/appwizard/component-wizards/wizards/source/mongodb/mongodb-cosmos-cdc";
import CosmosDBCDCWizard from "app/components/appwizard/component-wizards/wizards/source/cosmosdb/cosmosdb-cdc";
import IncremenalSourceWizard from "app/components/appwizard/component-wizards/wizards/source/incremental-batch-reader-simple-target/incremental-batch-reader-simple-target";
import MongoDBReaderWizard from "app/components/appwizard/component-wizards/wizards/source/mongodb/mongodb-il";
import MongoCosmosDBReaderWizard from "app/components/appwizard/component-wizards/wizards/source/mongodb/mongodb-cosmos-il";
import CosmosDBReaderWizard from "app/components/appwizard/component-wizards/wizards/source/cosmosdb/cosmosdb-il";
import PostgreSQLReaderWizard from "app/components/appwizard/component-wizards/wizards/source/postgres-simple-source/postgres-simple-source";
import MariaDBReader from "app/components/appwizard/component-wizards/wizards/source/mariadb-simple-source/mariadb-simple-source";
import MariaDBCDCReader from "app/components/appwizard/component-wizards/wizards/source/mariadb-cdc-simple-source/mariadb-cdc-simple-source";
import MariaDBXpandReader from "app/components/appwizard/component-wizards/wizards/source/database-reader/mariadbxpand-database-reader";
import S3DBReader from "app/components/appwizard/component-wizards/wizards/source/s3-simple-source/s3-simple-source";
import HdfsReader from "app/components/appwizard/component-wizards/wizards/source/hdfs-simple-source/hdfs-simple-source";
import SalesForceReader from "app/components/appwizard/component-wizards/wizards/source/sales-force-simple-source/sales-force-simple-source";
import SalesForceCDCReader from "app/components/appwizard/component-wizards/wizards/source/sales-force-simple-source/sales-force-cdc";
import SalesforcePardotReader from "app/components/appwizard/component-wizards/wizards/source/salesforce-pardot-simple-source/salesforce-pardot-simple-source";
import GoogleAdsReader from "app/components/appwizard/component-wizards/wizards/source/googleads-simple-source/googleads-simple-source";
import ServiceNowReader from "app/components/appwizard/component-wizards/wizards/source/servicenow-simple-source/servicenow-simple-source";
import HubSpotReader from "app/components/appwizard/component-wizards/wizards/source/hubspot-simple-source/hubspot-simple-source";
import BigQueryILReader from "app/components/appwizard/component-wizards/wizards/source/bigQuey-simple-source/bigQuery-il-simple-source";
import IntercomReader from "app/components/appwizard/component-wizards/wizards/source/intercom-simple-source/intercom-simple-source";
import SnowflakeILReader from "app/components/appwizard/component-wizards/wizards/source/snowflake-il-simple-source/snowflake-il-simple-source";
//DO-NOT-REMOVE-BELOW-COMMENT
//add-new-import
import PaymoReader from "app/components/appwizard/component-wizards/wizards/source/paymo-reader-simple-source/paymo-reader-simple-source.js";
import BambooHrReader from "app/components/appwizard/component-wizards/wizards/source/bamboohr-simple-source/bamboohr-simple-source.js";
import LinkedInAdsReader from "app/components/appwizard/component-wizards/wizards/source/linkedin-ads-simple-source/linkedin-ads-simple-source.js";
import GoogleAnalyticsReader from "app/components/appwizard/component-wizards/wizards/source/google-analytics-simple-source/google-analytics-simple-source.js";
import YugabyteCDCReader from "app/components/appwizard/component-wizards/wizards/source/yugabyte-reader-simple-source/yugabyte-reader-simple-source.js";
import YugabyteDBReader from "app/components/appwizard/component-wizards/wizards/source/yugabyte-initial-load-simple-source/yugabyte-initial-load-simple-source.js";
import JiraReader from "app/components/appwizard/component-wizards/wizards/source/jira-reader-simple-source/jira-reader-simple-source.js";
import StripeReader from "app/components/appwizard/component-wizards/wizards/source/stripe-reader-simple-source/stripe-reader-simple-source.js";
import OracleDatabase from "app/components/appwizard/component-wizards/wizards/source/database-reader/oracle-database-reader";
import MysqlDatabase from "app/components/appwizard/component-wizards/wizards/source/database-reader/mysql-database-reader";
import MariaDBDatabase from "app/components/appwizard/component-wizards/wizards/source/database-reader/mariadb-database-reader";
import SqlServerDatabase from "app/components/appwizard/component-wizards/wizards/source/database-reader/sqlserver-database-reader";
import PostgresDatabase from "app/components/appwizard/component-wizards/wizards/source/database-reader/postgres-database-reader";
import OJet from "app/components/appwizard/component-wizards/wizards/source/ojet/ojet";
import GCSReader from "app/components/appwizard/component-wizards/wizards/source/gcs-simple-source/gcs-simple-source";
import ZendeskReader from "app/components/appwizard/component-wizards/wizards/source/zendesk-simple-source/zendesk-simple-source";
import MariaDBXpandDB from "app/components/appwizard/component-wizards/wizards/source/database-reader/mariaxpand-database-reader";
import ADLSReader from "app/components/appwizard/component-wizards/wizards/source/adls-simple-source/adls-simple-source";
import SnowflakeCDCReader from "app/components/appwizard/component-wizards/wizards/source/snowflake-cdc-simple-source/snowflake-cdc-source";

import BaseSimpleTarget from "./wizards/target/base-simple-target/base-simple-target";
import CosmosDBTargetWizard from "./wizards/target/cosmosdb-target/cosmosdb-target";
import TargetWizard from "./wizards/target/target-wizard/target-wizard";

import getCustomAdapterProperties from "app/components/appwizard/component-wizards/wizards/target/custom-adapter-properties";

import PreBuiltDashboardView from "app/components/appwizard/component-wizards/wizards/dashboard/default-pre-built-dashboard-view";
import { isAutomatedWizard } from "./wizards/source/database-reader/app-services";

const module = {};

module.componentWizardMap = {};
module.componentWizardMap["OracleCDC"] = OracleCDCWizard;
module.componentWizardMap["MSSQLCDC"] = MSSQLCDCWizard;
module.componentWizardMap["MSJet"] = MSJetWizard;
module.componentWizardMap["MySQLCDC"] = MYSQLCDCWizard;
module.componentWizardMap["IncrementalBatchReader"] = IncremenalSourceWizard;
module.componentWizardMap["MongoDBReaderIL"] = MongoDBReaderWizard;
module.componentWizardMap["PostgreSQLReader"] = PostgreSQLReaderWizard;
module.componentWizardMap["MariaDB"] = MariaDBReader;
module.componentWizardMap["MariaDBReader"] = MariaDBCDCReader;
module.componentWizardMap["MariaDBXpandReader"] = MariaDBXpandReader;
module.componentWizardMap["S3Reader"] = S3DBReader;
module.componentWizardMap["HDFS"] = HdfsReader;
module.componentWizardMap["Salesforce"] = SalesForceReader;
module.componentWizardMap["SalesforceCDCReader"] = SalesForceCDCReader;
module.componentWizardMap["SalesforcePardotReader"] = SalesforcePardotReader;
module.componentWizardMap["ServiceNowReader"] = ServiceNowReader;
module.componentWizardMap["GoogleAdsReader"] = GoogleAdsReader;
module.componentWizardMap["HubSpotReader"] = HubSpotReader;
module.componentWizardMap["IntercomReader"] = IntercomReader;
module.componentWizardMap["OracleDB"] = OracleDatabase;
module.componentWizardMap["MySQLDB"] = MysqlDatabase;
module.componentWizardMap["MSSQLDB"] = SqlServerDatabase;
module.componentWizardMap["PostgreSQLDB"] = PostgresDatabase;
module.componentWizardMap["MariaDB"] = MariaDBDatabase; //todo
module.componentWizardMap["OJet"] = OJet; //todo
module.componentWizardMap["MongoDBReader"] = MongoDBCDCWizard;
module.componentWizardMap["GCSReader"] = GCSReader;
module.componentWizardMap["MariaDBXpandDB"] = MariaDBXpandDB;
module.componentWizardMap["ADLSReader"] = ADLSReader;
module.componentWizardMap["SnowflakeILReader"] = SnowflakeILReader;
module.componentWizardMap["BigQueryILReader"] = BigQueryILReader;
module.componentWizardMap["SnowflakeCDCReader"] = SnowflakeCDCReader;
//DO-NOT-REMOVE-BELOW-COMMENT
//add-new-adapter
module.componentWizardMap["BambooHrReader"] = BambooHrReader;
module.componentWizardMap["PaymoReader"] = PaymoReader;
module.componentWizardMap["GoogleAnalyticsReader"] = GoogleAnalyticsReader;
module.componentWizardMap["LinkedInAdsReader"] = LinkedInAdsReader;
module.componentWizardMap["YugabyteCDC"] = YugabyteCDCReader;
module.componentWizardMap["YugabyteDBReader"] = YugabyteDBReader;
module.componentWizardMap["JiraReader"] = JiraReader;
module.componentWizardMap["StripeReader"] = StripeReader;
module.componentWizardMap["ZendeskReader"] = ZendeskReader;

module.componentWizardMap["CosmosDBReaderIL"] = CosmosDBReaderWizard;
module.componentWizardMap["CosmosDBReaderCDC"] = CosmosDBCDCWizard;

module.componentWizardMap["MongoCosmosDBReaderIL"] = MongoCosmosDBReaderWizard;
module.componentWizardMap["MongoCosmosDBReaderCDC"] = MongoCosmosDBCDCWizard;

module.componentWizardMap["CosmosDB"] = CosmosDBTargetWizard;

module.componentWizardMap["SnowflakeWriter"] = new BaseSimpleTarget(
    "SnowflakeWriter",
    getCustomAdapterProperties
).get();

module.componentWizardMap["BigQuery"] = new BaseSimpleTarget("BigQuery", getCustomAdapterProperties).get();

module.componentWizardMap["AzureSQLDWHWriter"] = new BaseSimpleTarget(
    "AzureSQLDWHWriter",
    getCustomAdapterProperties
).get();

module.componentWizardMap["RedSh"] = new BaseSimpleTarget("RedSh", getCustomAdapterProperties).get();

// this also works .. module.componentWizardMap["HDInsightKafkaWriter"] = new BaseSimpleTarget("HDInsightKafkaWriter").get();

module.componentWizardMap["wizards/dashboard/default-pre-built-dashboard-view"] = PreBuiltDashboardView;

const isSourceRelationalDB = source => {
    const relationalSourceDBs = [
        "OracleDB",
        "OracleCDC",
        "OJet",
        "MSSQLCDC",
        "MSSQLDB",
        "MSJet",
        "MySQLCDC",
        "MySQLDB",
        "PostgreSQLReader",
        "PostgreSQLDB",
        "MariaDB",
        "MariaDBXpandReader"
    ];
    const adapter = ["HubSpotReader", "ZendeskReader", "StripeReader"];
    return relationalSourceDBs.includes(source) || adapter.includes(source);
};

const isTargetRelationalDBOrDWH = target => {
    const relationalTargetDBs = [
        "OutputOracleDatabase",
        "OutputPostgresDatabase",
        "OutputMySQLDatabase",
        "OutputSQLServerDatabase",
        "MariaDBDatabase"
    ];
    const DWHTargets = ["SnowflakeWriter", "BigQuery", "DeltaLakeWriter", "FabricDataWarehouseWriter"];
    return relationalTargetDBs.includes(target) || DWHTargets.includes(target);
};

module.getComponentWizardView = function(id, app_name, templateID, params, sourceAdapter, predefinedValues, stepName) {
    try {
        let componentWizard = module.componentWizardMap[id];
        if ((sourceAdapter !== id && isAutomatedWizard()) || id === "BigQuery") {
            componentWizard = new TargetWizard(id, getCustomAdapterProperties, stepName).get();
        }
        if (!componentWizard) {
            componentWizard = new BaseSimpleTarget(id).get();
        }
        return new componentWizard.View({
            appName: app_name,
            templateID: templateID,
            params: params,
            sourceAdapter: sourceAdapter,
            predefinedValues: predefinedValues,
            stepName: stepName
        });
    } catch (e) {
        console.error(e);
    }
};

export default {
    getComponentWizardView: module.getComponentWizardView
};
