import "select2";
import _ from "underscore";
import BaseControl from "../base-control";
import controlTemplate from "app/components/common/editor/control/connection-text-field/teams-connection-template.html";
import { template, ui, on } from "core/decorators";
import api from "core/api/api";
import UIControl from "app/components/common/editor/control/ui-control";

var Model = BaseControl.Model.extend({});

@template(controlTemplate)
@ui({
    loading: ".message.info",
    error: ".message.teams-error",
    errorMessage: ".message.teams-error .error-message",
    success: ".message.success",
    sendTestMsg: "button.send-test-msg"
})
class View extends BaseControl.View {
    onShow() {
        let that = this;
        this.ui.sendTestMsg.prop("disabled", true);
        this.$("#teams-channel-url").change(function() {
            if ($(this).val()) {
                that.ui.sendTestMsg.prop("disabled", false);
            } else {
                that.ui.sendTestMsg.prop("disabled", true);
            }
        });
    }

    @on("click button.send-test-msg")
    onClickSendTestMsg() {
        let teamsClientId = this.formModel.get(this.teamsClientId);
        let teamsClientSec = this.formModel.get(this.teamsClientSec);
        let teamsRefToken = this.formModel.get(this.teamsRefToken);
        let channelUrl = this.$("#teams-channel-url").val();

        this.ui.error.hide();
        this.ui.success.hide();
        this.ui.loading.slideDown(
            {
                start: function() {
                    $(this).css({
                        display: "flex",
                        "align-items": "center"
                    });
                }
            },
            200
        );
        this.ui.sendTestMsg.prop("disabled", true);
        api.sendTestToTeams(teamsClientId, teamsClientSec, teamsRefToken, channelUrl)
            .then(() => {
                this.ui.loading.hide();
                this.ui.success.show(200);
            })
            .fail(e => {
                this.ui.loading.hide();
                this.ui.errorMessage.html(e.message);
                this.ui.error.show(200);
            })
            .always(() => {
                this.ui.sendTestMsg.prop("disabled", false);
            });
    }

    getViewValue() {
        this.formModel.set(this.teamsClientId, this.$("#teams-client-id").val());
        this.formModel.set(this.teamsClientSec, this.$("#teams-client-secret").val());
        this.formModel.set(this.teamsRefToken, this.$("#teams-refresh-token").val());
    }

    setViewValue() {
        let model = this.formModel;
        this.$("#teams-client-id").val(model.get(this.teamsClientId));
        this.$("#teams-client-secret").val(model.get(this.teamsClientSec));
        this.$("#teams-refresh-token").val(model.get(this.teamsRefToken));
    }
}

export default function(formModel, teamsClientId, teamsClientSec, teamsRefToken) {
    return _.extend({}, BaseControl, {
        Model: Model,
        View: View.extend({
            formModel: formModel,
            teamsClientId: teamsClientId,
            teamsClientSec: teamsClientSec,
            teamsRefToken: teamsRefToken,
            hideLabel: true
        })
    });
}
