import _ from "underscore";
import dateLib from "core/utils/date-utils";
import stringUtils from "core/utils/string-utils";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import getRealApi from "./real-exceptions-api";

let exceptionsApi = getRealApi();

/**
 * turn on logging application exceptions
 * @param appId
 * @returns {Promise<any>} Promise resolved when logging is enabled
 */
function turnOn(appId) {
    return new Promise((resolve, reject) => {
        exceptionsApi
            .turnOn(appId)
            .then(() => {
                resolve(true);
            })
            .catch(err => {
                reject(err);
            });
    });
}

/**
 * turn off logging application exceptions
 * @param appId
 * @returns {Promise<any>} Promise resolved when logging is turned off
 */
function turnOff(appId) {
    return new Promise(resolve => {
        exceptionsApi.turnOff(appId).then(() => {
            resolve(false);
        });
    });
}

/**
 * delete logs for application
 * @param appId
 * @returns {Promise<any>} Promise resolved when logs are deleted
 */
function deleteLogs(appId) {
    return new Promise(resolve => {
        try {
            exceptionsApi.turnOff(appId).then(() => {
                resolve(true);
            });
        } catch (error) {
            resolve(error);
        }
    });
}

/**
 * check if logging exceptions for application is active
 * @param appId
 * @returns {Promise<any>} Promise resolved with store name depend if logging exceptions is enabled
 */
function checkExceptionsEnabled(appId) {
    return exceptionsApi.checkExceptionsEnabled(appId);
}

/**
 * Return number of exceptions for application
 * @param appId
 * @returns {*} Promise resolved with number of exceptions
 */
function countExceptions(appModel) {
    return exceptionsApi.checkExceptionsEnabled(appModel.id).then(storeName => {
        return metaStoreService.findById(appModel.id).then(latestApp => {
            return exceptionsApi.countExceptions(appModel.nsName, storeName, latestApp.uuid);
        });
    });
}

/**
 *
 * @param appModel
 * @param searchTerm
 * @param dateFrom
 * @param dateTo
 * @param components
 * @returns {Promise<any>} Promise resolved with array of exceptions
 */
function loadExceptions(appModel, searchTerm, dateFrom, dateTo, components) {
    return new Promise(resolve => {
        exceptionsApi.checkExceptionsEnabled(appModel.id).then(storeName => {
            exceptionsApi
                .loadExceptions(appModel, storeName, searchTerm, dateFrom, dateTo, components)
                .then(exceptions => {
                    let numberOfExceptions = exceptions.length;
                    exceptions.forEach(exc => {
                        if (!exc.exceptionTime) {
                            numberOfExceptions = 0;
                            exceptions = null;
                            return;
                        }
                        let relatedObjectsValue = null;
                        try {
                            relatedObjectsValue = JSON.parse(exc.relatedObjects);
                        } catch {
                            if (exc.relatedObjects) relatedObjectsValue = [exc.relatedObjects];
                        }

                        const date = new Date(parseInt(exc.exceptionTime));
                        exc.simpleDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                        exc.time = dateLib(date).format("YYYY h:mm:ss a");
                        exc.dateMD = dateLib(date).format("MMM DD,");
                        exc.dateTime = dateLib(date).format("MMM DD, hh:mm a");
                        exc.hasEvents = _.isArray(relatedObjectsValue);

                        if (!relatedObjectsValue) {
                            relatedObjectsValue = [];
                        } else if (!exc.hasEvents) {
                            // make sure that realtedObject is always an array
                            relatedObjectsValue = [relatedObjectsValue];
                        }
                        if (relatedObjectsValue) {
                            exc.relatedObjectsValue = relatedObjectsValue.map(event => {
                                return {
                                    jsonSimplify: stringUtils.replaceAll(JSON.stringify(event), '":"', '" : "'),
                                    jsonExpanded: JSON.stringify(event, null, 2)
                                };
                            });
                        }
                    });
                    exceptions = _.sortBy(exceptions, function(exc) {
                        return -exc.simpleDate;
                    });
                    exceptions = _.groupBy(exceptions, function(exc) {
                        return exc.simpleDate;
                    });

                    exceptions = Object.keys(exceptions).map(key => {
                        const date = dateLib(key);
                        return {
                            date: key,
                            dateStr: date.format("MMM DD"),
                            exceptions: exceptions[key]
                        };
                    });

                    resolve({ exceptions, numberOfExceptions });
                });
        });
    });
}

/**
 * Updates TTL of exceptions store
 * @param appId
 * @param interval - time in microseconds
 * @returns {Promise<any>} Promise resolved when interval is updated
 */
function manageHistory(appId, interval) {
    return new Promise(resolve => {
        try {
            exceptionsApi.manageHistory(appId, interval).then(() => {
                resolve(true);
            });
        } catch (error) {
            resolve(error);
        }
    });
}

/**
 * Get current Interval for Exceptions store history
 * @param appId
 * @returns {*} Promise resolved with current application logging history interval
 */
function getManageHistory(appId) {
    return exceptionsApi.getManageHistory(appId);
}

/**
 * load list of all `entityName` for selected appModel
 * the method is used to populate component filter
 * @param appModel - applciation model
 * @returns {*} Promise resolved with array of distincs 'entityName'
 */
function loadComponentsList(appModel) {
    return exceptionsApi.checkExceptionsEnabled(appModel.id).then(storeName => {
        return exceptionsApi.loadComponentsList(storeName, appModel.get("nsName"));
    });
}

function getAppVersions(appId) {
    return exceptionsApi.getAppVersions(appId);
}

export default {
    turnOn: turnOn,
    turnOff: turnOff,
    checkExceptionsEnabled: checkExceptionsEnabled,
    countExceptions: countExceptions,
    loadExceptions: loadExceptions,
    loadComponentsList: loadComponentsList,
    deleteLogs: deleteLogs,
    manageHistory: manageHistory,
    getManageHistory: getManageHistory,
    getAppVersions: getAppVersions
};
