import React, { useRef, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import {
    StriimForm,
    StriimControlLabel,
    StriimInputField,
    StriimRadio,
    StriimTypography,
    StriimRadioGroup
} from "@striim/striim-ui";
import PropTypes from "prop-types";
import * as Yup from "yup";
import alertResources from "app/components/alertmanager/alert-resources.json";
import { ALERT_TYPE, emailValidation } from "app/components/alertmanager/alert-utils";
import growl from "app/components/common/growl";
import { classes } from "./add-new-alert.styles";

const ScrollInputField = props => {
    const teamsRef = useRef();

    useEffect(() => {
        teamsRef.current.scrollIntoView({ behavior: "smooth" });
    }, []);

    return (
        <div ref={teamsRef}>
            <StriimInputField {...props}></StriimInputField>
        </div>
    );
};

const RequiredLabel = ({ label }) => {
    return (
        <StriimTypography variant="h3" sx={classes.subHeading}>
            {label} <StriimTypography sx={classes.asterisk}>*</StriimTypography>
        </StriimTypography>
    );
};

const AddNewAlert = ({ componentType, addNewAlert, formRef, addAlertContainerRef }) => {
    const values = {
        alertName: "",
        alertType: "",
        condition: "",
        toAddress: "",
        emailInputField: "",
        slackInputField: "",
        teamsInputField: "",
        alertInterval: "",
        alertIntervalUnit: {
            label: "SECOND",
            value: "SECOND"
        }
    };
    const snooozeOptions = [
        {
            label: "HOUR",
            value: "HOUR"
        },
        {
            label: "MINUTE",
            value: "MINUTE"
        },
        {
            label: "SECOND",
            value: "SECOND"
        },
        {
            label: "DAY",
            value: "DAY"
        }
    ];

    let validComponent = alertResources[componentType]?.filter(
        alertResource => alertResource.comparator && alertResource.alertValue
    );

    const validationSchema = Yup.object().shape({
        condition: Yup.string().required("An option must be selected"),
        alertType: Yup.string().required("An option must be selected"),
        alertName: Yup.string()
            .trim()
            .required("Value for Alert Name is required")
            .min(2, "Alert Name must be at least 2 characters")
            .test("cannotStartWithaNumber", "Name cannot start with a number", value => isNaN(parseInt(value)))
            .test("noSpaces", "Please enter without spaces.", value => value && value.indexOf(" ") === -1)
            .matches(new RegExp(/^\w+$/), "Please enter only alphanumeric or underscore characters."),
        emailInputField: Yup.array().when("alertType", {
            is: ALERT_TYPE.EMAIL,
            then: emailValidation
        }),
        slackInputField: Yup.string().when("alertType", {
            is: ALERT_TYPE.SLACK,
            then: Yup.string()
                .trim()
                .required("Slack Channel Required")
                .min(2, "Slack channel name must be at least 2 characters")
        }),
        teamsInputField: Yup.string().when("alertType", {
            is: ALERT_TYPE.TEAMS,
            then: Yup.string()
                .trim()
                .required("Teams ID Required")
                .min(2, "Teams ID must be at least 2 characters")
        })
    });

    const formatAlertValues = () => {
        const formData = formRef.current.values;
        const alertIntervalData = formData?.alertInterval + " " + formData?.alertIntervalUnit.value;
        return {
            name: formData.alertName,
            alertType: formData.alertType,
            isEnabled: true,
            isSysDefined: false,
            toAddress: formData.toAddress,
            alertInterval: !formData?.alertInterval ? "0 SECOND" : alertIntervalData
        };
    };
    const handleSubmit = () => {
        const submitValues = formatAlertValues(values);
        addNewAlert(submitValues, formRef.current.values.condition)
            .then(() => formRef.current.resetForm())
            .catch(err => {
                growl.error(err, "Error, while saving alert");
            });
    };

    return (
        <StriimForm
            values={values}
            formRef={formRef}
            validationSchema={validationSchema}
            validateOnMount={true}
            validateOnChange
            onSubmit={() => {
                handleSubmit();
            }}
        >
            {({ errors, values, handleChange, touched, setFieldValue }) => {
                return (
                    <Grid container direction={"column"}>
                        <Grid item direction={"row"} container sx={classes.addAlertHeader}>
                            <StriimTypography variant="h2" sx={classes.heading} ref={addAlertContainerRef}>
                                Add a New Alert
                            </StriimTypography>
                        </Grid>
                        <Grid item sx={classes.addAlertContent}>
                            <RequiredLabel label="Alert Name" />
                            <Box sx={{ width: "400px" }}>
                                <StriimInputField
                                    id="data-test-id-designer-alert-modal-add-name"
                                    xs={3}
                                    isFormElement
                                    name="alertName"
                                    placeholder="Name for the Alert"
                                ></StriimInputField>
                            </Box>
                        </Grid>
                        {validComponent && (
                            <Grid item sx={classes.addAlertContent}>
                                <RequiredLabel label="Alert me when" />
                                {errors.condition && touched.condition ? (
                                    <Box sx={classes.errors}>{errors.condition}</Box>
                                ) : null}

                                <StriimRadioGroup
                                    required
                                    value={values.condition}
                                    onChange={event => {
                                        setFieldValue("condition", event.target.value);
                                        handleChange(event);
                                    }}
                                    name="condition"
                                    isFormElement
                                    id="data-test-id-designer-alert-modal-add-when"
                                >
                                    {validComponent.map((item, key) => (
                                        <StriimControlLabel
                                            key={key}
                                            value={item.label}
                                            label={item.label}
                                            isFormElement
                                            control={<StriimRadio checked={values.condition === item.label} />}
                                        />
                                    ))}
                                </StriimRadioGroup>
                            </Grid>
                        )}
                        <Grid item sx={classes.addAlertContent}>
                            <RequiredLabel label="Send Alerts to" />
                            {errors.alertType && touched.alertType ? (
                                <Box sx={classes.errors}>{errors.alertType}</Box>
                            ) : null}
                            <StriimRadioGroup
                                required
                                onChange={event => {
                                    setFieldValue("alertType", event.target.value);
                                    handleChange(event);
                                }}
                                name="alertType"
                                isFormElement
                                id="data-test-id-designer-alert-modal-add-mode"
                            >
                                <Grid>
                                    <StriimControlLabel
                                        xs={3}
                                        value={ALERT_TYPE.WEB}
                                        label="Web"
                                        id="data-test-id-designer-alert-modal-add-mode-web"
                                        control={
                                            <StriimRadio checked={values.alertType === ALERT_TYPE.WEB} isFormElement />
                                        }
                                    />
                                </Grid>
                                <Grid>
                                    <StriimControlLabel
                                        xs={3}
                                        value={ALERT_TYPE.EMAIL}
                                        label="Email"
                                        onChange={() => {
                                            values.slackInputField = "";
                                            values.toAddress = "";
                                        }}
                                        control={
                                            <StriimRadio
                                                checked={values.alertType === ALERT_TYPE.EMAIL}
                                                isFormElement
                                            />
                                        }
                                    />

                                    {values.alertType === ALERT_TYPE.EMAIL ? (
                                        <ScrollInputField
                                            xs={3}
                                            isFormElement
                                            name="emailInputField"
                                            placeholder="List of email addresses comma separated"
                                            helperText="Please make sure Email is configured in Alert Manager"
                                            onChange={e => {
                                                values.toAddress = e.target.value;
                                            }}
                                            sx={classes.alertInputField}
                                        />
                                    ) : null}
                                </Grid>
                                <Grid>
                                    <StriimControlLabel
                                        value={ALERT_TYPE.SLACK}
                                        label="Slack"
                                        onChange={() => {
                                            values.emailInputField = "";
                                            values.toAddress = "";
                                        }}
                                        control={
                                            <StriimRadio
                                                checked={values.alertType === ALERT_TYPE.SLACK}
                                                isFormElement
                                            />
                                        }
                                    />
                                    {values.alertType === ALERT_TYPE.SLACK ? (
                                        <ScrollInputField
                                            xs={3}
                                            isFormElement
                                            name="slackInputField"
                                            placeholder="Channel Name in Slack Workspace"
                                            helperText="Please make sure Slack is configured in Alert Manager"
                                            sx={classes.alertInputField}
                                            onChange={e => {
                                                values.toAddress = e.target.value;
                                            }}
                                        ></ScrollInputField>
                                    ) : null}
                                </Grid>
                                <Grid>
                                    <StriimControlLabel
                                        value={ALERT_TYPE.TEAMS}
                                        label="Teams"
                                        onChange={() => {
                                            values.teamsInputField = "";
                                            values.toAddress = "";
                                        }}
                                        control={
                                            <StriimRadio
                                                checked={values.alertType === ALERT_TYPE.TEAMS}
                                                isFormElement
                                            />
                                        }
                                    />
                                    {values.alertType === ALERT_TYPE.TEAMS ? (
                                        <ScrollInputField
                                            xs={3}
                                            isFormElement
                                            name="teamsInputField"
                                            placeholder="Link to the Teams Channel from Teams App"
                                            sx={classes.alertInputField}
                                            helperText="Please make sure Teams is configured in Alert Manager"
                                            onChange={e => {
                                                values.toAddress = e.target.value;
                                            }}
                                        />
                                    ) : null}
                                </Grid>
                            </StriimRadioGroup>
                        </Grid>
                        <Grid>
                            <StriimTypography xs={3} variant="h3" sx={classes.subHeading}>
                                Snooze After Alert
                            </StriimTypography>

                            <Grid
                                item
                                container
                                sx={{ width: "400px", marginBottom: "20px", justifyContent: "space-between" }}
                            >
                                <Grid item sx={{ width: "195px" }}>
                                    <StriimInputField
                                        xs={3}
                                        isFormElement
                                        name="alertInterval"
                                        type="number"
                                        helperText="This setting will snooze alerts until the interval expires"
                                    ></StriimInputField>
                                </Grid>
                                <Grid item sx={{ width: "195px" }}>
                                    <StriimInputField
                                        name="alertIntervalUnit"
                                        id="data-test-id-renew-check-period"
                                        select
                                        SelectProps={{
                                            options: snooozeOptions,
                                            menuPosition: "fixed",
                                            isClearable: false
                                        }}
                                        isFormElement
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }}
        </StriimForm>
    );
};

AddNewAlert.defaultProps = {
    componentType: "SOURCE"
};
AddNewAlert.propTypes = {
    addNewAlert: PropTypes.func.isRequired,
    addAlertContainerRef: PropTypes.shape({ current: PropTypes.object }),
    componentType: PropTypes.string.isRequired,
    formRef: PropTypes.shape({ current: PropTypes.object })
};

export default AddNewAlert;
