import React, { useRef, useEffect, useContext } from "react";
import loadBackboneApp from "../../../app/load-backbone-app";
import Backbone from "backbone";
import Overview from "../../../app/components/monitor/overview/overview";
import MonitorRoot from "./monitor-root";
import { useReactHeader } from "../../hooks/useReactHeader";

function Monitor() {
    useReactHeader({title: "Monitor"});
    let backboneApp;
    const initializedRef = useRef(false);

    useEffect(() => {
        if (initializedRef.current) {
            return;
        }
        const view = new Overview.View({model: new Backbone.Model()});
        backboneApp = loadBackboneApp(view);
        initializedRef.current = true;
    }, []);

    return (
        <></>
    );
}

export default function() {
    return (
        <MonitorRoot>
            <Monitor />
        </MonitorRoot>
    );
}