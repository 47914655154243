import _ from "underscore";
import BaseControl from "./../base-control";
import { filterComponentsByAdapters } from "app/components/flow/designer/component-acendants-loader";
import { loadAscendantsDbSources } from "app/components/flow/designer/component-acendants-loader";
import loadTables from "app/components/flow/designer/tables-loader.js";
import { loadDGsForAgents, getAgentForDeploymentGroup } from "app/components/flow/designer/agents-loader";
import UIControl from "app/components/common/editor/control/ui-control";
import ArrayDataSource from "./../select/array-datasource";
import template from "./table-selector.html";

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "columns-splitter",

    regions: {
        agentSelectorRegion: ".agent-selector",
        tableSelectorRegion: ".table-selector",
    },

    ui: {
        agentSelectorContainer: ".agent-selector-container",
        tableSelectorContainer: ".table-selector-container",
        tableSelectorLoader: ".table-selector-container .loader",
        errorMessage: ".columns-errors",
    },

    setValue: function (value) {
        if (!value || !value.length) {
            return;
        }

        this.agentSelector.setValue(value[0].agent);
        this.tableSelector.setValue(value[0].table);
        this.model.trigger("change:value", this.getViewValue());
    },

    setViewValue: function () {},

    getViewValue: function () {
        let selectedTable = this.tableSelector.getValue();
        let columns = [];
        if (this.tablesHashmap && selectedTable) {
            let columnObject = this.tablesHashmap[selectedTable];
            let keys = Object.keys(columnObject) || [];
            columns = keys.map((key) => {
                return {
                    column: key,
                    type: columnObject[key],
                    agent: this.agentSelector.getValue(),
                    table: selectedTable,
                };
            });
        }

        return columns;
    },

    clear: function () {
        this.agentSelector.setValue("");
        this.tableSelector.setValue("");
    },

    setEnabled: function (enabled) {
        BaseControl.View.prototype.setEnabled.apply(this, [enabled]);
        this.agentSelector.setEnabled(enabled);
        this.tableSelector.setEnabled(enabled);
    },

    initialize: function (options) {
        this.agentSelector = UIControl.Select([])
            .extend({
                placeholder: "select",
            })
            .create();

        this.tableSelector = UIControl.Select([])
            .extend({
                placeholder: "select",
            })
            .create();
    },

    onRender: function () {
        this.agentSelectorRegion.show(this.agentSelector);
        this.tableSelectorRegion.show(this.tableSelector);

        this.toggleAgentsSelector();

        if (this.model.value && this.model.value.length > 0) {
            this.agentSelector.setValue(this.model.value[0].agent);
            this.tableSelector.setValue(this.model.value[0].table);
            this.initTablesHashMap();
        }

        this.listenTo(
            this.agentSelector.model,
            "change:value",
            function (viewModel) {
                this.trigger(UIControl.EVENT.VALUE_CHANGED, this.getViewValue());

                if (!this.firstLoad) {
                    this.tableSelector.model.value = "";
                }

                if (viewModel.value === "server") {
                    this.toggleTableSelector(viewModel.value);
                } else {
                    let agent = getAgentForDeploymentGroup(viewModel.value);
                    this.toggleTableSelector(agent);
                }
            }.bind(this)
        );

        this.listenTo(
            this.tableSelector.model,
            "change:value",
            function (viewModel) {
                this.trigger(UIControl.EVENT.VALUE_CHANGED, this.getViewValue());
            }.bind(this)
        );
    },

    initTablesHashMap: function () {
        this.tablesHashmap = {};
        this.tablesHashmap[this.model.value[0].table] = {};
        for (let i = 0; i < this.model.value.length; i++) {
            this.tablesHashmap[this.model.value[0].table][this.model.value[i].column] = this.model.value[i].type;
        }
    },

    setStream: function (streamId) {
        if (!this.streamId) {
            this.firstLoad = true;
        }
        this.streamId = streamId;

        loadAscendantsDbSources(this.streamId).then((result) => {
            this.sourceComponent = result[0];
            this.toggleAgentsSelector();
        });
    },

    toggleAgentsSelector: function () {
        let agents = loadDGsForAgents();
        this.agentSelector.setDataSource(this.toAgentsSelectSource(agents));

        if (agents.length < 2) {
            this.ui.agentSelectorContainer.hide();
            this.agentSelector.model.value = "server";
            this.agentSelector.model.trigger("change:value", this.agentSelector.model);
        } else {
            this.ui.agentSelectorContainer.show();
        }
    },

    toggleTableSelector: function (agentValue) {
        // should not load columns when settings didn't change
        if (this._lastSelectedAgent === agentValue && this._lastStreamId === this.streamId) {
            return;
        } else {
            this._lastSelectedAgent = agentValue;
            this._lastStreamId = this.streamId;
        }

        if (!agentValue) {
            this.ui.tableSelectorContainer.hide();
            this.tableSelector.setDataSource([]);
        } else {
            this.ui.tableSelectorContainer.show();

            if (!this.streamId) {
                this.tableSelector.setDataSource([]);
                return;
            }

            if (agentValue === "server") {
                agentValue = "";
            }

            this.tableSelector.setDataSource([]);
            this.toggleTableSelectorLoader(true);

            loadTables(this.sourceComponent, agentValue, true)
                .then((tables) => {
                    this.tablesHashmap = tables;
                    this.toggleTableSelectorLoader(false);

                    let tablesSourceFunction = ArrayDataSource(Object.keys(tables));
                    this.tableSelector.setDataSource(tablesSourceFunction);
                    if (this.firstLoad && this.tableSelector.model.value) {
                        this.tableSelector.model.trigger("change:value", this.tableSelector.model);
                        this.firstLoad = false;
                    } else if (this.tableSelector.model.value) {
                        this.tableSelector.model.value = "";
                        this.tableSelector.model.trigger("change:value", this.tableSelector.model);
                    }
                })
                .catch((error) => {
                    this.toggleTableSelectorLoader(false);
                    this.tableSelector.setDataSource([]);
                    this.trigger("show-error-msg", error);
                });
        }
    },

    toAgentsSelectSource: function (agents) {
        let agentsSource = [{ id: "server", text: "Server" }];

        _.each(agents, function (elem) {
            agentsSource.push({
                id: elem.attributes.id,
                text: elem.attributes.name,
            });
        });

        return agentsSource;
    },

    toggleTableSelectorLoader: function (visible) {
        if (this.ui.tableSelectorLoader) {
            this.ui.tableSelectorLoader.toggleClass("hidden-element", !visible);
        }

        if (this.getEnabled()) {
            this.tableSelector.setEnabled(!visible);
        }
    },
});

var Model = BaseControl.Model.extend({
    defaults: {
        value: Array,
    },
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
