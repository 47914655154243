import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {useObserver} from "mobx-react";
import {Card, CardContent, CardHeader, Grid, Box, Divider} from "@mui/material";

import DashboardTileActions from "./components/dashboard-tile-actions";
import useStyles, {styles} from "./dashboard-tile.styles";
import {StriimTypography, StriimTooltip} from "@striim/striim-ui";
import DashboardRenameModal from "./components/dashboard-rename-modal";
import DashboardDeleteModal from "./components/dashboard-delete-modal";
import clsx from "clsx";

import Tracker from "core/services/tracker/tracker";
import { TRACKER_STRINGS } from "../../../../../../../core/services/tracker/constants";

const DashboardTile = ({wide, dashboard, homepage}) => {
    const [renameModalVisible, setRenameModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();

    const chartsCount = dashboard?.pages?.reduce((count, page) => count + (page.queryVisualizations?.length || 0), 0);

    const dashboardCharts = (
        <div>
            <StriimTypography variant="body4" color="greyscale.700">
                Charts
            </StriimTypography>
            <StriimTypography
                sx={{ml: 1}}
                variant="body3"
                color="greyscale.900"
                data-test-id="dashboard-charts-count"
            >
                {chartsCount}
            </StriimTypography>
        </div>
    );

    const dashboardPages = (
        <div>
            <StriimTypography variant="body4" color="greyscale.700">
                Pages
            </StriimTypography>
            <StriimTypography sx={{ml: 1}} variant="body3" color="greyscale.900" data-test-id="dashboard-pages-count">
                {dashboard?.pages?.length}
            </StriimTypography>
        </div>
    );

    const dashboardActionButton = (
        <DashboardTileActions dashboard={dashboard} {...{setRenameModalVisible, setDeleteModalVisible}} />
    );

    const handleDashboardTileClick = () => {
        navigate(`/dashboard/view/${dashboard?.nsName}.${dashboard?.name}/${dashboard?.defaultLandingPage}`);

        Tracker.getInstance().track(TRACKER_STRINGS.schema.dashboard, {
            id: `${dashboard?.nsName}.${dashboard?.name}`,
            event: TRACKER_STRINGS.eventClicked.dashboard,
            buttonClicked:"View"
        });
    };
    return useObserver(() => (
        <>
            {wide ? (
                <Grid
                    container
                    justifyContent="space-between"
                    onClick={handleDashboardTileClick}
                    sx={styles.card}
                    classes={{root: classNames(clsx(classes.rowRoot, homepage && classes.rowRootHomepage))}}
                    data-test-id={"dashtile-wide--" + dashboard?.nsName + "." + dashboard?.name}
                >
                    <Grid item container xs={8} className={classes.dashboardNames}>
                        <StriimTooltip
                            title={dashboard?.title ? dashboard.title.length > 20 ? dashboard?.title : "" : dashboard?.name}>
                            <StriimTypography
                                variant="body2"
                                color="greyscale.900"
                                data-test-id="dashboard-title"
                                className={classes.dashboardText}
                            >
                                {dashboard?.title || dashboard?.name}
                            </StriimTypography>
                        </StriimTooltip>
                        <StriimTypography variant="caption" color="greyscale.700" className={classes.dashboardText}>
                            {dashboard?.nsName}
                        </StriimTypography>
                    </Grid>
                    <Grid item xs={4} container alignItems="center" justifyContent="flex-end">
                        <Grid item container xs={8} className={classes.dashboardNames}>
                        </Grid>
                        <Box sx={{mr: 4}}>{dashboardCharts}</Box>
                        <Box sx={{mr: 4}}>{dashboardPages}</Box>
                        <Box>{dashboardActionButton}</Box>
                    </Grid>
                </Grid>
            ) : (
                <Card
                    onClick={handleDashboardTileClick}
                    classes={{root: classes.cardRoot}}
                    sx={styles.card}
                    id={"dashtile--" + dashboard?.nsName + "." + dashboard?.name}
                    data-test-id={"dashtile--" + dashboard?.nsName + "." + dashboard?.name}
                >
                    <CardHeader
                        classes={{
                            title: classes.cardHeaderTitle,
                            content: classes.cardHeaderContent,
                            subheader: classes.cardHeaderSubHeader
                        }}
                        disableTypography
                        title={<StriimTooltip
                            title={dashboard?.title ? dashboard.title.length > 20 ? dashboard?.title : "" : dashboard?.name}>
                            <StriimTypography
                                variant="h3"
                                color="greyscale.900"
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}
                            >
                                {dashboard?.title || dashboard?.name}
                            </StriimTypography>
                        </StriimTooltip>}
                        subheader={dashboard?.nsName}
                        subheaderTypographyProps={{variant: "caption3", color: "greyscale.700"}}
                        action={dashboardActionButton}
                    />
                    <Divider sx={styles.divider}/>
                    <CardContent className={classes.content}>
                        <div className={classes.cardDashboardInfo}>
                            {dashboardCharts}
                            <Box sx={{mx: 3}}/>
                            {dashboardPages}
                        </div>
                    </CardContent>
                </Card>
            )}
            <DashboardRenameModal dashboard={dashboard} {...{renameModalVisible, setRenameModalVisible}} />
            <DashboardDeleteModal dashboard={dashboard} {...{deleteModalVisible, setDeleteModalVisible}} />
        </>
    ));
};

DashboardTile.propTypes = {
    wide: PropTypes.bool,
    dashboard: PropTypes.object.isRequired
};

export default DashboardTile;
