import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import NestedTypes from "backbone.nestedtypes";

var Model = NestedTypes.Model.extend({
    defaults: {
        value: Object,
        enabled: Boolean(true)
    }
});

var View = Backbone.Marionette.LayoutView.extend({
    events: {
        "change input": "setValueFromView"
    },

    modelEvents: {
        "change:value": "_setViewValue",
        "change:enabled": "setViewEnabled"
    },

    setValueFromView: function() {
        var viewValue = this.getViewValue();
        if (_.isEqual(viewValue, this.model.value)) {
            return;
        }

        this.model.value = viewValue;
        this.trigger("value:set", this.getValue());
        this.hideError();
    },

    forceChanged: function() {
        this.trigger("value:set", this.getValue());
    },

    setValue: function(value) {
        if (this.valueConverter) {
            this.model.value = this.valueConverter.in(value);
        } else {
            this.model.value = value;
        }
    },

    getValue: function() {
        if (this.valueConverter) {
            return this.valueConverter.out(this.model.value);
        } else {
            return this.model.value;
        }
    },

    validate: function() {},

    _setViewValue: function() {
        if (!this.$el.html()) {
            return;
        }
        if (_.isEqual(this.getViewValue(), this.getValue())) {
            return;
        }

        this.setViewValue();
        this.hideError();
    },

    setViewValue: function() {
        throw new Error("setViewValue not implemented");
    },

    getViewValue: function() {
        throw new Error("getViewValue not implemented");
    },

    getEnabled: function() {
        return this.model.enabled;
    },

    setDefault: function() {},

    focus: function() {},

    setViewEnabled: function() {
        this.$("input").prop("disabled", !this.getEnabled());
        this.$el.find("label").toggleClass("disabled", !this.getEnabled());
    },

    setEnabled: function(enabled) {
        this.model.enabled = enabled;
    },

    showError: function(message) {
        var errorDiv = this.$(".error");
        if (!errorDiv.length) {
            errorDiv = $('<div class="message error show" data-test-id="validation-error"></div>');
            this.$el.append(errorDiv);
        }
        errorDiv.html(message);
    },

    hideError: function() {
        var errorDiv = this.$(".error");
        errorDiv.remove();
    },

    onRender: function() {
        this.setViewValue();
        this.setViewEnabled();
    },

    onShow: function() {
        this.setViewEnabled();
    }
});

var simpleImplementation = {
    Model: Model,
    View: View,

    // options - set of attributes passed to View
    create: function(options) {
        var model = new this.Model();
        return new this.View(
            _.extend(
                {
                    model: model
                },
                options
            )
        );
    }
};

export default $.extend({}, simpleImplementation, {
    extend: function(options) {
        var _this = this;
        return $.extend({}, simpleImplementation, {
            create: function(runtimeOptions) {
                var model = new _this.Model();
                var opts = {
                    model: model
                };
                $.extend(opts, options, runtimeOptions);
                var view = new _this.View(opts);
                if (options) {
                    $.extend(view, options);
                }
                return view;
            }
        });
    }
});
