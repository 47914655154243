import App from "app";
import Cocktail from "backbone.cocktail";
import dataVisualizations from "core/services/metadataService/dataVisualizations";
import dataVisualizationsEditors from "core/services/metadataService/dataVisualizationsEditors";
import template from "./axes.html";
import "./axis";
import "app/components/common/_deprecated/form/form";
import "../visualization/visualization-view";

App.module("Dashboard.VisEditor", function(VisEditor, App, Backbone, Marionette, $, _) {
    VisEditor[dataVisualizations.EDITOR.AXES] = Cocktail.mixin(Marionette.LayoutView.extend({}), App.Form.Mixin, {
        className: "axes-editor",

        template: _.template(template),
        regions: {
            preview: ".vis-preview"
        },
        triggers: {
            "click .delete-series": "remove-item"
        },
        // TODO: destroy dropdowns onbeforedestroy?
        update_preview: function() {
            console.log(this.model.config.id, "Updating preview...");

            // save reference to query apply to deep clone
            const preview_model = this.model._parent._parent.deepClone();
            preview_model.set("forceRender", true);
            preview_model.set("theme", preview_model.__attributes.theme.parse("WebActionLight")); // TODO: fix

            // Show or hide series depending on what the current selected series is
            const current_series = this.model.config.id;
            preview_model.config.series.each(function(series) {
                const visible =
                    series._parent === current_series
                        ? null
                        : series.id === current_series || _(current_series).isUndefined();
                series.set("visible", visible, {
                    silent: true
                });
            });

            const VisView = App.Dashboard.VisualizationView.extend({
                className: "visualization-preview visualization-container",
                template: _.template('<div class="notifications"></div><div class="content"></div>'),
                model: preview_model,
                regions: {
                    content: ".content"
                },
                showVisualizationNotifications: function() {
                    const that = this;

                    let text;
                    if (that.model.config.series.length === 0) {
                        text = "Add some series!";
                    } else if (that.model.config.xAxis.length === 0 || that.model.config.yAxis.length === 0) {
                        text = "Add X- and Y- axes by dragging fields into the axis regions.";
                    }

                    if (text) {
                        this.showVisualizationNotification(text);
                    } else {
                        this.hideVisualizationNotification();
                    }
                }
            });

            const vis = new VisView();
            this.getRegion("preview").show(vis);
        },
        _update_axes: function() {
            console.log("Updating axes");
            // Scrub axes collections
            _(["xAxis", "yAxis", "zAxis"]).each(
                function(axis) {
                    const chartConfig = this.model._parent.config;
                    const series = this.model._parent.config.series;

                    const axes = chartConfig.get(axis);
                    if (!axes) {
                        return;
                    }
                    const used_axes = _.chain(series.filter(seriesSingle => !seriesSingle._generated))
                        .pluck(axis)
                        .unique()
                        .value();
                    const current_axes = _(chartConfig.get(axis).pluck("field")).unique();
                    const unused_axes = _(current_axes).difference(used_axes);
                    const new_axes = _(used_axes).difference(current_axes); // Generally will only be 1

                    // Remove axes that are no longer used after this change
                    _(unused_axes).each(function(axis_id) {
                        axes.remove(axis_id);
                    });

                    // Add a new axis if the field selected does not yet have an axis saved for it.
                    // TODO: specify axis type! xAxes that were previously of type "category" now are no longer that
                    _(new_axes).each(function(axis_id) {
                        if (!axis_id) {
                            return;
                        }
                        const new_axis = {
                            id: axis_id, // must be same as field (for now)
                            field: axis_id,
                            title: ""
                        };
                        axes.add(new_axis);
                    });
                }.bind(this)
            );
        },
        onRender: function() {
            const chartConfig = this.model._parent.config;
            const series = this.model.config;

            // Update series name as user edits
            this.$('[name="series-name"]')
                .off("change.custom")
                .on("change.custom", function() {
                    series.set("name", $(this).val());
                });
            this.$('[name="series-alias"]')
                .off("change.custom")
                .on("change.custom", function() {
                    series.set("alias", $(this).val());
                });

            // Render the Chart preview

            this.update_preview();
            this.listenTo(this.model._parent.config, "change", () => {
                this.update_preview();
            });

            // Render the Axis editors
            this.model.editor.propertyeditors.each(
                function(axis_editor) {
                    const editorType = axis_editor.type || dataVisualizations.EDITOR.AXIS;

                    if (editorType === dataVisualizations.EDITOR.AXIS) {
                        // Configure the axes collections to maintain its order with respect to the series
                        chartConfig.get(axis_editor.attribute).comparator = function(axis) {
                            const series = chartConfig.get("series").findWhere({
                                xAxis: axis.get("id")
                            });
                            if (series) {
                                return chartConfig.get("series").indexOf(series);
                            }
                        };

                        // Create the axis editor
                        const AxisConfig = VisEditor[editorType];
                        const axis_editor_view = new AxisConfig({
                            model: new dataVisualizationsEditors.EditorViewModel({
                                _queryvisualization: this.model._queryvisualization,
                                _datavisualization: this.model._datavisualization,
                                _theme: this.model._theme,
                                _parent: this.model,
                                editor: axis_editor,
                                config: this.model.config
                            })
                        });
                        $("<div/>", {
                            class: "axis-editor-container " + axis_editor.options.axis.toLowerCase() + "Axis"
                        }).appendTo(this.$(".vis-preview-container"));
                        const axis_region = this.addRegion(
                            axis_editor.options.axis + "Axis",
                            ".axis-editor-container." + axis_editor.options.axis.toLowerCase() + "Axis"
                        );
                        axis_region.show(axis_editor_view);
                    } else {
                        // Categories, etc.
                        const Config = VisEditor[editorType];
                        const editor_view = new Config({
                            model: new dataVisualizationsEditors.EditorViewModel({
                                _queryvisualization: this.model._queryvisualization,
                                _datavisualization: this.model._datavisualization,
                                _theme: this.model._theme,
                                _parent: this.model,
                                editor: axis_editor,
                                config: this.model.config
                            })
                        });
                        $("<div/>", {
                            class: "properties-editor-container"
                        }).appendTo(this.$(".vis-preview-container"));

                        const region = this.addRegion(_.uniqueId("region"), ".properties-editor-container");
                        region.show(editor_view);
                    }
                }.bind(this)
            );

            // When the series updates in any way, sync up axes.
            this.listenTo(this.model._parent.config.series, "add remove", this._update_axes);
            this.listenTo(series, "change", this._update_axes);
        }
    });
});
