/**
 *
 */
var metaInfoStatus = function (metaInfoStatus) {
    metaInfoStatus = metaInfoStatus || {};
    this.isAdhoc = metaInfoStatus.isAdhoc;
    this.isAnonymous = metaInfoStatus.isAnonymous;
    this.isGenerated = metaInfoStatus.isGenerated;
    this.isDropped = metaInfoStatus.isDropped;
    this.isValid = metaInfoStatus.isValid;
};
/**
 * rest of object definitions.
 * @type {{toString: Function}}
 */
metaInfoStatus.prototype = {};

export default metaInfoStatus;
