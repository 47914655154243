import { loadDGsForAgents } from "app/components/flow/designer/agents-loader";
import { getDatabaseType } from "app/components/common/editor/control/connection-text-field/connection-test-view";
import dateLib from "core/utils/date-utils";
import { timezone } from "../../../core/utils/date-utils";

export const getValidationDetails = ({ propertiesModel, handler, formRef, apiParams, isApplicationHelperAPI }) => {
    const validationDtls = {};
    Object.values(apiParams).forEach(key => {
        const userInput = formRef?.current?.values[key];
        if (isApplicationHelperAPI) {
            validationDtls[key] = typeof userInput === "object" ? userInput?.value : userInput;
        } else {
            // Send Passwords as object to executePropertyActions
            if (typeof userInput === "object" && !userInput.encrypted) validationDtls[key] = userInput?.value;
            else validationDtls[key] = userInput;
        }
    });

    const agentDeploymentGroups = loadDGsForAgents();
    const getCaseInsensitiveModel = () => {
        let caseInsensitiveModel = {};
        Object.keys(propertiesModel).forEach(propertyName => {
            caseInsensitiveModel[propertyName.toLowerCase()] = propertiesModel[propertyName];
        });
        return caseInsensitiveModel;
    };

    let caseInsensitiveModel = getCaseInsensitiveModel();

    validationDtls.adapterName = getAdapterName(caseInsensitiveModel?.adaptername, handler);

    if (isApplicationHelperAPI) {
        if (Object.values(apiParams).includes("Password")) {
            if (formRef?.current?.touched?.Password) {
                validationDtls.encrypted = false;
            } else {
                validationDtls.Password =
                    getPassword(caseInsensitiveModel?.password) || getPassword(validationDtls.Password);
                validationDtls.encrypted = !!caseInsensitiveModel.password_encrypted;
            }
        }
    }

    validationDtls.databaseProviderType = caseInsensitiveModel.databaseProviderType;
    validationDtls.agentDeploymentGroups = agentDeploymentGroups
        ? agentDeploymentGroups.map(group => {
              return {
                  label: group.get("name"),
                  value: group.get("id")
              };
          })
        : null;
    validationDtls.databaseType = getDatabaseType(validationDtls.adapterName, propertiesModel.databaseProviderType);
    return validationDtls;
};

export const getAdapterName = (adapterName, resourceNamespace) => {
    if (adapterName) {
        return adapterName;
    }

    // for new components the adaptername is not provided
    // we parse this from resourceNamespace which contains adapter name
    if (!resourceNamespace) {
        return null;
    }
    let nameParts = resourceNamespace.split(".");
    if (nameParts.length !== 3) {
        return null;
    }
    return nameParts[2];
};

export const getPassword = password => {
    if (password && password.encrypted) {
        return password.encrypted;
    }
    return password;
};

export const getDateTime = () => {
    const date = dateLib();
    return `${date.format("ll")} at ${date.format("h:mma")} ${timezone}`;
};
