import {StriimTypography as StriimTypographyMUI} from "@striim/striim-ui-v2";
import React from "react";

// Should be fixed and removed in after 4.3.0 release

export const StriimTypography = (props) => {
    switch (props.variant?.toLowerCase()) {
        case "h1":
        case "h3":
        case "h2":
        case "h4":
            return <StriimTypographyMUI color="primary.700" {...props}>{props.children}</StriimTypographyMUI>
        case "body":
        case "body1":
            return <StriimTypographyMUI color="greyscale.700" {...props}>{props.children}</StriimTypographyMUI>
        case "body3":
        case "body4":
            return <StriimTypographyMUI color="greyscale.900" {...props}>{props.children}</StriimTypographyMUI>
        case "caption2":
        case "caption3":
            return <StriimTypographyMUI color="greyscale.700" {...props} sx={{
                fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
                ...props?.sx
            }}>{props.children}</StriimTypographyMUI>
        default:
            return <StriimTypographyMUI {...props} sx={{
                fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
                ...props?.sx
            }}>
                {props.children}
            </StriimTypographyMUI>
    }
}
