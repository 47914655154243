import React, { SetStateAction, useCallback, useMemo, useRef, useState } from "react";
import EventsProcessedTimelineChart from "../../../../sentinel-live-monitor/components/timeline-view-chart/events-processed-timeline-chart";
import {
    AllSentinelsSDIDrillDownEventsChartData,
    EventsProcessedChartData,
    EventsProcessedTableData,
    LegendData
} from "../../service/sentinel-monitor.types";
import _ from "underscore";
import { SelectedSensitiveDataIdentifier, TABS } from "../../../../sentinel-live-monitor/sentinel-live-monitor";
import SDIEventsChart from "../../../../sentinel-live-monitor/components/timeline-view-chart/sdi-events-chart";
import { getTimestampsAsOneHourWindow } from "../../../../sentinel-live-monitor/utils";
import { getPercentage } from "../../../../reports/components/report-tables/report-tables.helper";

interface EventsProcessedDataForAppsProps {
    eventsProcessedDataForApps: EventsProcessedTableData;
    eventsProcessedChartDataForApps: EventsProcessedChartData;
    sdiEventsProcessedChartDataForApps: AllSentinelsSDIDrillDownEventsChartData;
    chartType: string;
    selectedSDI: SelectedSensitiveDataIdentifier | null;
    selectedDateRange: { from: number; to: number } | null;
    setSelectedDateRange: React.Dispatch<SetStateAction<{ from: number; to: number } | null>>;
    onLiveViewClick: Function;
    legendData: LegendData;
}

export const getEventsProcessedTab = (eventsProcessedDataForApps, selectedSDI = null) => {
    return {
        label: "Events Processed",
        sdiLabel: "Events W/",
        subLabel: "",
        amount:
            selectedSDI && eventsProcessedDataForApps
                ? "eventsProcessedWithSDI" in eventsProcessedDataForApps
                    ? eventsProcessedDataForApps.eventsProcessedWithSDI
                    : 0
                : eventsProcessedDataForApps?.eventsProcessed,
        sdiPercentage:
            selectedSDI && eventsProcessedDataForApps
                ? "eventsProcessedWithSDI" in eventsProcessedDataForApps
                    ? getPercentage(
                          eventsProcessedDataForApps.eventsProcessedWithSDI,
                          eventsProcessedDataForApps.eventsProcessed
                      )
                    : 0
                : getPercentage(
                      eventsProcessedDataForApps?.eventsProcessed,
                      eventsProcessedDataForApps?.eventsWithSensitiveData +
                          eventsProcessedDataForApps?.eventsWithoutSensitiveData
                  ),
        data: [
            ...(selectedSDI
                ? [
                      {
                          field: "sensitiveDataWithSDI",
                          value: eventsProcessedDataForApps?.eventsWithoutCurrSDI,
                          valuePercentage:
                              getPercentage(
                                  eventsProcessedDataForApps?.eventsWithoutCurrSDI,
                                  eventsProcessedDataForApps?.eventsProcessed
                              ) + "%"
                      }
                  ]
                : [{ field: "sensitiveData", value: eventsProcessedDataForApps?.eventsWithSensitiveData }]),
            { field: "nonSensitiveData", value: eventsProcessedDataForApps?.eventsWithoutSensitiveData }
        ],
        isLoading: !eventsProcessedDataForApps
    };
};

const EventsProcessedChart = ({
    eventsProcessedDataForApps,
    eventsProcessedChartDataForApps,
    sdiEventsProcessedChartDataForApps,
    chartType,
    selectedSDI,
    selectedDateRange,
    setSelectedDateRange,
    onLiveViewClick,
    legendData
}: EventsProcessedDataForAppsProps) => {
    const [isDiscoveryDisabled, setIsDiscoveryDisabled] = useState<boolean>(false);
    const [dataUnavailableTimestamp, setDataUnavailableTimestamp] = useState<number | null>();
    const [showTaggedData, setShowTaggedData] = useState<boolean>(false);
    const chartRef = useRef(null);

    const loadData = useCallback(
        _.debounce((event, dateRange) => {
            const updatedDateRange = getTimestampsAsOneHourWindow(dateRange?.from, dateRange?.to);
            setSelectedDateRange(updatedDateRange);
        }, 300),
        []
    );

    const sensitiveDataTaggedPercentage = useMemo(() => {
        const data = eventsProcessedDataForApps;
        if (!data) return;
        return data.eventsTaggedAsSensitive ? (data.eventsTaggedAsSensitive / data.eventsWithSensitiveData) * 100 : 0;
    }, [eventsProcessedDataForApps]);

    return (
        <div style={{ position: "relative" }}>
            {!!eventsProcessedChartDataForApps ? (
                <EventsProcessedTimelineChart
                    chartRef={chartRef}
                    hideNavigator={chartType === TABS[1]}
                    sensitiveDataTaggedPercentage={sensitiveDataTaggedPercentage}
                    chartData={{
                        sensitiveData: eventsProcessedChartDataForApps?.sensitiveData,
                        nonSensitiveData: eventsProcessedChartDataForApps?.nonSensitiveData,
                        taggedSensitiveData: eventsProcessedChartDataForApps?.taggedSensitiveData
                    }}
                    selectedDateRange={selectedDateRange}
                    onScroll={loadData}
                    isDiscoveryDisabled={isDiscoveryDisabled}
                    hasAppStopped={false}
                    dataUnavailableTimestamp={dataUnavailableTimestamp}
                    showTaggedData={showTaggedData}
                    setShowTaggedData={setShowTaggedData}
                    isTaggedDataOptionAvailable={!selectedSDI}
                    onLiveViewClick={onLiveViewClick}
                    legendData={legendData}
                />
            ) : !!sdiEventsProcessedChartDataForApps ? (
                <SDIEventsChart
                    chartRef={chartRef}
                    onScroll={loadData}
                    chartData={{
                        sensitiveData: sdiEventsProcessedChartDataForApps?.sensitiveData,
                        nonSensitiveData: sdiEventsProcessedChartDataForApps?.nonSensitiveData,
                        identifierSensitiveData: sdiEventsProcessedChartDataForApps?.identifierSensitiveData
                    }}
                    selectedDateRange={selectedDateRange}
                    isDiscoveryDisabled={isDiscoveryDisabled}
                    hasAppStopped={false}
                    dataUnavailableTimestamp={dataUnavailableTimestamp}
                    hideNavigator={chartType === TABS[1]}
                    onLiveViewClick={onLiveViewClick}
                    legendData={legendData}
                />
            ) : null}
        </div>
    );
};

export default EventsProcessedChart;
