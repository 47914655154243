import _ from "underscore";
import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

var sourceModule = {};

sourceModule.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "SOURCE",
        adapter: Object,
        parser: Object,
        outputStream: null,
        outputclause: null,
        map: Object, // {name: String (outputStream), mappingProperties: {table:"tableName"}}
        select: String, // select Statement
    },
    validate: function () {
        var errors = baseMetaObject.Model.prototype.validate.apply(this, arguments) || {};

        var outputStream = this.get("outputStream");
        var stream_regex = /[a-zA-Z0-9]+.(STREAM|WACTIONSTORE).[a-zA-Z0-9]+/;
        if (outputStream && !outputStream.match(stream_regex)) {
            errors["outputStream"] = "Please enter a name with only alphanumeric or underscore characters.";
        }

        return _.isEmpty(errors) ? undefined : errors;
    },
});

sourceModule.Collection = baseMetaObject.Collection.extend({
    model: sourceModule.Model,
});

export default sourceModule;
