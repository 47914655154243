import App from "app";
import utils from "core/utils";
import naturalSort from "app/components/common/natural-sort";
import WATableMixin from "app/components/dashboard/visualization/charts/table/mixins/table-webaction";
import table_template from "./templates/table.html";
import column_header_template from "./templates/column_header.html";
import cell_template from "./templates/cell.html";
import footer_template from "./templates/footer.html";
import link_template from "./templates/link.html";
import "app/components/dashboard/visualization/charts/chart";
import "app/components/dashboard/visualization/charts/table/GridHelper";
import { getIcons } from "core/services/metadataService/dataVisualizations";
import evaluator from "app/components/dashboard/visualization/charts/gauge/expressionEvaluator";

App.module("Chart.Table", function(Table, App, Backbone, Marionette, $, _) {
    Table.HeaderItemView = Backbone.Marionette.ItemView.extend({
        template: _.template(column_header_template),
        tagName: "th",
        onRender: function() {
            var _this = this;
            this.$(".js-sortable").on("click", function() {
                var srcfield = _this.model.get("srcfield");
                _this.trigger("column:sort", srcfield);
            });
        }
    });

    Table.THeadCollectionView = Backbone.Marionette.CollectionView.extend({
        initialize: function(options) {
            this.collection = options.columns;
            var _this = this;
            this.on("childview:column:sort", function(childview, srcfield) {
                _this.trigger("column:sort", srcfield);
            });
        },
        childView: Table.HeaderItemView,
        tagName: "thead"
    });

    Table.CellItemView = Backbone.Marionette.ItemView.extend({
        template: _.template(cell_template),
        tagName: "td",
        onRender: function() {
            this.$el.removeAttr("title");
        }
    });

    Table.CellItemDrillView = Backbone.Marionette.ItemView.extend({
        events: {
            click: "clickHandler"
        },
        template: _.template(link_template),
        tagName: "td",
        clickHandler: function(evt) {
            this.trigger("datapoint:click", this.model);
            evt.preventDefault();
        }
    });

    Table.RowCollectionView = Backbone.Marionette.CollectionView.extend({
        initialize: function(options) {
            this.data = options.data;
            this.show_lines = options.show_lines;
            var _this = this;
            this.listenTo(this, "childview:datapoint:click", function(childView, model) {
                _this.trigger("datapoint:click", model);
            });
        },
        childView: Table.CellItemView,
        tagName: "tr",
        buildChildView: function(child, ChildViewClass, childViewOptions) {
            var srcfield = child.get("srcfield");
            var value = this.data.get(srcfield);

            var model = new Backbone.Model();
            model.set("value", value);
            model.set("config", child);
            model.set("data", this.data);

            var options = _.extend(
                {
                    model: model
                },
                childViewOptions
            );
            var view = new ChildViewClass(options);
            return view;
        },
        getChildView: function(item) {
            if (item.get("view")) {
                return item.get("view");
            } else if (item.get("drilldown").show_drilldown) {
                return Table.CellItemDrillView;
            }
            return Table.CellItemView;
        }
    });

    Table.TBodyCollectionView = Backbone.Marionette.CollectionView.extend({
        initialize: function(options) {
            this.data = options.data;
            this.columns = options.columns;
            this.collection = this.data;
            this.emptyView = options.emptyView || Table.TBodyEmptyView;

            var _this = this;
            this.listenTo(this, "childview:datapoint:click", function(childView, model) {
                _this.trigger("datapoint:click", model);
            });
        },
        childView: Table.RowCollectionView,
        tagName: "tbody",
        buildChildView: function(child, ChildViewClass, childViewOptions) {
            var options = _.extend(
                {
                    collection: this.columns,
                    data: child
                },
                childViewOptions
            );
            var view = new ChildViewClass(options);
            return view;
        }
    });

    Table.TableCollectionView = Backbone.Marionette.CollectionView.extend({
        initialize: function() {
            var _this = this;
            this.on("childview:column:sort", function(childview, srcfield) {
                _this.trigger("column:sort", srcfield);
            });
            this.listenTo(this, "childview:datapoint:click", function(childView, model) {
                _this.trigger("datapoint:click", model);
            });
        },
        tagName: "table",
        className: "wa-table",
        childViewOptions: function() {
            return {
                emptyView: this.options.emptyView
            };
        },
        getChildView: function(item) {
            // There are only two values in this collection, type='columns' : column information and type='data' : data rows
            if (item.get("type") === "columns") {
                return Table.THeadCollectionView;
            }
            return Table.TBodyCollectionView;
        },
        buildChildView: function(child, ChildViewClass, childViewOptions) {
            var options = _.extend(
                {
                    data: child.get("data"),
                    columns: child.get("columns")
                },
                childViewOptions
            );
            var view = new ChildViewClass(options);
            return view;
        }
    });

    Table.ComponentView = App.Chart.Default.ChartView.extend({
        template: _.template(table_template),
        SORT_ASCENDING: true,
        SORT_DESCENDING: false,
        initialize: function() {
            _(this).extend(WATableMixin);
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
            _.bind(this.handleColumnSort, this);
            _.bind(this.handlePageForward, this);
            _.bind(this.handlePageBack, this);
            _.bind(this.handlePageFirst, this);
            _.bind(this.handlePageLast, this);
            this.sortDirection = this.SORT_ASCENDING;
            this.pagenum = 0;
            this.emptyView = this.options.emptyView;
        },
        regions: {
            tableRegion: ".js-region-wa-table",
            footerRegion: ".js-region-wa-table-footer"
        },
        renderChart: function() {
            var columns = this.chartConfig.config.columns;
            var data = this.chartConfig.config.data;
            if (this.model.config.categoryField !== "" && typeof this.model.config.categoryField !== "undefined") {
                var flatData = [];
                for (var key in data[0]) {
                    flatData.push(data[0][key][0]);
                }
                data = flatData;
            }

            var columnsCollection = new Backbone.Collection(columns);
            this.pagesize = Number(this.chartConfig.config.pagesize);
            data = this._sortData(data);

            var pageData = this.createPage(data, this.pagesize, this.pagenum);
            var dataCollection = new Backbone.Collection(pageData);
            this.totalPages = this.getTotalPages(data);

            var columnModel = new Backbone.Model();
            columnModel.set("type", "columns");
            columnModel.set("columns", columnsCollection);

            var dataModel = new Backbone.Model();
            dataModel.set("type", "data");
            dataModel.set("columns", columnsCollection);
            dataModel.set("data", dataCollection);

            var tableCollection = new Backbone.Collection();
            if (this.chartConfig.config.show_headers) {
                tableCollection.push(columnModel);
            }
            tableCollection.push(dataModel);
            var tableCollectionView = new Table.TableCollectionView({
                emptyView: this.emptyView,
                collection: tableCollection
            });

            var _this = this;
            this.listenTo(tableCollectionView, "datapoint:click", function(model) {
                console.log("bubbled to top");
                _this.trigger("datapoint:click", model);
            });

            var footerModel = new Backbone.Model();
            footerModel.set("pagenum", this.pagenum + 1);
            footerModel.set("totalpages", this.totalPages);
            var footerView = new Table.FooterView({
                model: footerModel
            });

            this.getRegion("tableRegion").show(tableCollectionView);
            if (this.chartConfig.config.show_lines === false) {
                tableCollectionView.$el.find("td").addClass("noborder");
            }
            if (data.length > this.chartConfig.config.pagesize) {
                this.getRegion("footerRegion").show(footerView);
            } else {
                this.getRegion("footerRegion").empty();
            }

            this.setupListeners(tableCollectionView, footerView);

            this._setupSortIcons();
        },
        /**
         * takes the data, checks if we have any table sorters and sorts the data.
         * @param data
         * @returns data
         * @private
         */
        _sortData: function(data) {
            if (this.sortField === undefined && this.chartConfig.config.defaultSortField) {
                // first time only.
                this.sortField = this.chartConfig.config.defaultSortField;
                this.sortDirection =
                    this.chartConfig.config.defaultSortOrder === "asc" ? this.SORT_ASCENDING : this.SORT_DESCENDING;
            }
            if (this.sortField !== undefined) {
                data = _.sortBy(data, this.sortField);
                if (this.sortDirection === this.SORT_DESCENDING) {
                    data = data.reverse(); // writing code from the plane, and am missing you google :(
                }
            }
            return data;
        },
        /**
         * Set which sort icons to show and which to hide.
         * @private
         */
        _setupSortIcons: function() {
            this.$el.find(".iconset .icon").show();
            if (this.sortField !== undefined) {
                var sortField;
                sortField = utils.escape_chars(this.sortField);
                var disablingArrowClass = this.sortDirection === this.SORT_DESCENDING ? "fa-angle-up" : "fa-angle-down";
                this.$el.find(".iconset-" + sortField + " ." + disablingArrowClass + "").hide();
            }
        },
        setupListeners: function(tableCollectionView, footerView) {
            this.listenTo(tableCollectionView, "column:sort", this.handleColumnSort);
            this.listenTo(footerView, "navigate:page:back", this.handlePageBack);
            this.listenTo(footerView, "navigate:page:forward", this.handlePageForward);
            this.listenTo(footerView, "navigate:page:first", this.handlePageFirst);
            this.listenTo(footerView, "navigate:page:last", this.handlePageLast);
        },

        handleColumnSort: function(srcfield) {
            if (this.sortField !== srcfield) {
                this.sortField = srcfield;
                this.sortDirection = this.SORT_ASCENDING;
            } else {
                this.sortDirection = !this.sortDirection;
            }
            this.sortedField = srcfield;

            console.log("Sorting on: " + this.sortField + " Direction: " + this.sortDirection);
            this.render();
        },
        handlePageBack: function() {
            this.pagenum--;
            if (this.pagenum < 0) {
                this.pagenum = 0;
            }
            this.renderTable();
        },
        handlePageForward: function() {
            this.pagenum++;
            if (this.pagenum > this.totalPages - 1) {
                this.pagenum = this.totalPages - 1;
            }
            this.renderTable();
        },
        handlePageFirst: function() {
            this.pagenum = 0;
            this.renderTable();
        },
        handlePageLast: function() {
            this.pagenum = this.totalPages - 1;
            this.renderTable();
        },
        createPage: function(data, pagesize, pagenum) {
            if (typeof pagesize === "undefined" || pagesize === "" || typeof data === "undefined") {
                return data;
            }
            if (data.length < pagesize) {
                return data;
            }

            var startingRecord = pagenum * pagesize;
            var endingRecord = startingRecord + pagesize;
            if (endingRecord >= data.length) {
                endingRecord = data.length;
            }

            return data.slice(startingRecord, endingRecord);
        },
        getTotalPages: function(data) {
            if (typeof data === "undefined") {
                return 0;
            }
            var size = data.length;
            var modPages = size % this.pagesize;
            var totalPages = Math.floor(size / this.pagesize);
            if (modPages !== 0) {
                totalPages++;
            }

            return totalPages;
        },
        defaultPointClickHandler: function(element) {
            var config = element.get("config");
            if (config.get("drilldown").show_drilldown) {
                var show_drilldown = config.get("drilldown").show_drilldown;
                var pageparams = config.get("drilldown").pageparams;
                var page = config.get("drilldown").page;
                this.handleDrillDown(element.get("data").toJSON(), page, show_drilldown, pageparams);
            }
        }
    });

    Table.FooterView = Backbone.Marionette.ItemView.extend({
        template: _.template(footer_template),
        tagName: "div",
        className: "footer-controls",
        onRender: function() {
            var _this = this;
            this.$(".js-page-nav-back").on("click", function() {
                _this.trigger("navigate:page:back");
            });
            this.$(".js-page-nav-forward").on("click", function() {
                _this.trigger("navigate:page:forward");
            });

            this.$(".js-page-nav-first").on("click", function() {
                _this.trigger("navigate:page:first");
            });

            this.$(".js-page-nav-last").on("click", function() {
                _this.trigger("navigate:page:last");
            });
        }
    });

    //TODO JqGrid Table component starts from here .. all the code from this point is required for table component

    Table.TBodyEmptyView = Marionette.ItemView.extend({
        template: _.template("Waiting for data"),
        className: "tbody-empty-view"
    });

    function findFirstMatchingExpression(conditions, dataRow) {
        if (!conditions || conditions.length === 0) {
            return null;
        }

        let result = null;
        conditions.some(condition => {
            if (!condition.expression) {
                return false;
            }

            const computed = evaluator.evaluate(condition.expression, dataRow);
            if (computed === true) {
                result = condition;
                return true;
            }
            return false;
        });
        return result;
    }

    Table.View = App.Chart.Default.ChartView.extend({
        template: _.template(table_template),

        _iconsHash: {},

        initialize: function() {
            _(this).extend(WATableMixin);

            this._initializeIconsHash();

            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
            this.emptyView = this.options.emptyView || Table.TBodyEmptyView;
            //keep all the latest column widths in a local variable
            this.columnWidths = this.options.columnWidths || [];
            this.sortOrder = "";
            this.sortName = "";
            this.columnOrder = [];
            this.pageNumber = 1;

            this.listenTo(App.vent, "window:resize", function() {
                this.resize();
            });
        },

        _initializeIconsHash: function() {
            let that = this;

            let icons = getIcons();
            icons.forEach(i => {
                that._iconsHash[i.id.toUpperCase()] = i;
            });
        },

        regions: {
            tableRegion: ".js-region-wa-table",
            footerRegion: ".js-region-wa-table-footer"
        },
        _setDataCollection: function() {
            var data = this.chartConfig.config.data;
            if (this.model.config.categoryField !== "" && typeof this.model.config.categoryField !== "undefined") {
                var flatData = [];
                var firstElement = data[0];
                for (var key in firstElement) {
                    flatData.push(firstElement[key][0]);
                }
                data = flatData;
            }

            this.dataCollection = new Backbone.Collection(data);

            _.each(this.dataCollection.models, function(datum) {
                datum.set("modelid", datum.cid);
            });
        },
        calculateColumnsWidth: function(columns, outerWidth) {
            var widthsPercentages = [];
            var numberOfEmptyValues = 0;
            var sumOfSetValues = 0;

            _.each(columns, function(column) {
                var widthPercentage = column["widthPercentage"];
                if (widthPercentage) {
                    sumOfSetValues += widthPercentage;
                } else {
                    numberOfEmptyValues++;
                    widthPercentage = 0;
                }

                widthsPercentages.push(widthPercentage);
            });

            var percentageToDivideBetweenUnsetValues = 100 - sumOfSetValues;
            if (percentageToDivideBetweenUnsetValues < 0) {
                percentageToDivideBetweenUnsetValues = 0;
            }

            var percentageOfUnsetValues = percentageToDivideBetweenUnsetValues / numberOfEmptyValues;

            var columnWidths = [];
            _.each(widthsPercentages, function(widthPercentage) {
                if (widthPercentage === 0) {
                    widthPercentage = percentageOfUnsetValues;
                }

                columnWidths.push((widthPercentage / 100) * outerWidth);
            });

            return columnWidths;
        },
        renderTable: function() {
            if (!this.chartConfig) {
                return;
            }
            if (this.chartConfig && this.chartConfig.config.enablePercentageColumnsWidth) {
                this.columnWidths = this.calculateColumnsWidth(this.chartConfig.config.columns, this.$el.outerWidth());
            }

            var columns = this.chartConfig.config.columns;

            columns = _.sortBy(columns, function(col) {
                return col.sortOrder;
            });

            this._setDataCollection();

            this.columnsCollection = new Backbone.Collection(columns);

            //render grid view
            var _this = this;
            var colNames = [];
            var colModel = [];
            _.each(this.columnsCollection.models, function(column, index) {
                var viewFormatter = function(cellvalue, options) {
                    var model = new Backbone.Model();
                    model.set("value", cellvalue);
                    var view = new options.colModel.view({
                        model: model
                    }).render();
                    return view.el.innerHTML;
                };

                var obj = {};
                obj.name = column.get("srcfield");
                obj.index = column.get("srcfield");
                obj.drilldown = column.get("drilldown");
                if (_this.columnWidths[index]) {
                    obj.width = _this.columnWidths[index];
                }
                obj.sortfunc = naturalSort;
                if (column.get("view")) {
                    obj.view = column.get("view");
                    obj.formatter = viewFormatter;
                } else {
                    // check if there are icon settings and format cell value
                    let iconConditions = column.get("iconConditions");
                    let conditionalColors = column.get("conditionalColors");
                    if (
                        (iconConditions && iconConditions.length > 0) ||
                        (conditionalColors && conditionalColors.length > 0)
                    ) {
                        obj.formatter = function(cellvalue, options, row) {
                            return _this._formatCell(iconConditions, conditionalColors, row, cellvalue);
                        };
                    }
                }
                if (obj.drilldown && obj.drilldown.show_drilldown) {
                    obj.classes = "grid-drilldown-cell";
                }
                if (index === _this.columnsCollection.models.length - 1) {
                    obj.classes += " last-column";
                }
                colModel.push(obj);
                colNames.push(column.get("label"));
            });
            colNames.push("modelid");
            colModel.push({
                name: "modelid",
                index: "modelid",
                sorttype: "string",
                hidden: true
            });

            var gridModel = {
                datatype: "local",
                height: "auto",
                colNames: colNames,
                colModel: colModel,
                viewrecords: true,
                multiselect: false,
                resizable: true,
                autowidth: true,
                data: this.dataCollection.toJSON(),
                onCellSelect: function(rowid, colid, content) {
                    // cell click handler
                    var prop = $(this).getGridParam().colModel[colid];
                    if (prop && prop.drilldown && prop.drilldown.show_drilldown) {
                        var cid = $(this).getCell(rowid, "modelid");
                        var model = new Backbone.Model();
                        model.set("data", _this.dataCollection.get(cid));
                        model.set("config", new Backbone.Model(prop));
                        model.set("value", content);
                        _this.trigger("datapoint:click", model);
                    }
                },
                onSortCol: function() {
                    var grid = $(this);
                    _this.sortOrder = grid.jqGrid("getGridParam", "sortorder");
                    _this.sortName = grid.jqGrid("getGridParam", "sortname");
                },
                sortable: {
                    update: function(relativeColumnOrder) {
                        var grid = _this.currentGrid;
                        var defaultColIndicies = [];

                        for (var i = 0; i < colModel.length; i++) {
                            defaultColIndicies.push(colModel[i].name);
                        }

                        var columnOrder = [];
                        var currentColModel = grid.getGridParam("colModel");
                        for (var j = 0; j < relativeColumnOrder.length; j++) {
                            columnOrder.push(defaultColIndicies.indexOf(currentColModel[j].name));
                        }

                        // columnOrder now contains exactly what's necessary to pass to to remapColumns
                        // now save columnOrder somewhere
                        _this.columnOrder = columnOrder;
                    }
                },
                onPaging: function(pgButton) {
                    var pageIdentifier = pgButton.split("_")[0];
                    if (pageIdentifier === "next") {
                        _this.pageNumber++;
                    } else if (pageIdentifier === "prev") {
                        _this.pageNumber--;
                    } else if (pageIdentifier === "last") {
                        _this.pageNumber = $(this).getGridParam("lastpage");
                    } else if (pageIdentifier === "first") {
                        _this.pageNumber = 1;
                    }
                }
            };

            if (this.chartConfig.config.show_lines === false) {
                gridModel.show_lines = false;
            }

            if (this.chartConfig.config.show_headers) {
                gridModel.show_headers = this.chartConfig.config.show_headers;
            }

            if (Number(this.chartConfig.config.pagesize) === Number.POSITIVE_INFINITY) {
                gridModel.rowNum = this.dataCollection.length;
                gridModel.pagination = false;
            } else {
                gridModel.pagination = true;
                gridModel.rowNum = Number(this.chartConfig.config.pagesize);
            }

            if (_this.columnWidths.length === 0) {
                gridModel.shrinkToFit = true;
            } else {
                gridModel.shrinkToFit = false;
            }

            var renderGrid = function() {
                var gridHelper = App.module("GridHelper");
                if (this.tableRegion) {
                    this.tableRegion.empty();
                }
                if (this.footerRegion) {
                    this.footerRegion.empty();
                }
                // render new table
                var gData = new gridHelper.View({
                    tableModel: gridModel,
                    el: _this.$el.find(".js-region-wa-table")
                });

                var grid = gData.render().grid;
                _this.currentGrid = grid;
                if (_this.sortOrder && _this.sortName) {
                    grid.jqGrid("setGridParam", {
                        sortorder: _this.sortOrder
                    });
                    grid.jqGrid("sortGrid", _this.sortName, true);
                }
                if (_this.columnOrder.length > 0) {
                    grid.remapColumns(_this.columnOrder, true, false);
                }
                if (_this.pageNumber) {
                    grid.jqGrid("setGridParam", {
                        page: _this.pageNumber
                    });
                    grid.trigger("reloadGrid");
                }
                // add footer message for empty collection
                if (this.dataCollection.length === 0) {
                    var region = this.getRegion("footerRegion");
                    if (region) {
                        region.show(new this.emptyView());
                        gData.trigger("hidePagination");
                    }
                } else if (this.dataCollection.length && gridModel.pagination) {
                    if (Number(this.chartConfig.config.pagesize) >= this.dataCollection.length) {
                        gData.trigger("hidePagination");
                    }
                }

                //remove all titles from the columns as they create unnecessary tooltips
                this.$el.find("td").removeAttr("title");
                this.$el.find("th").removeAttr("title");
            };

            setTimeout(function() {
                renderGrid.call(_this);
            }, 0);
        },

        _formatCell: function(iconConditions, conditionalColors, row, cellvalue) {
            // display an icon
            if (iconConditions && iconConditions.length > 0) {
                let matchingCondition = findFirstMatchingExpression(iconConditions, row);
                if (matchingCondition && matchingCondition.icon) {
                    let iconSetting = this._iconsHash[matchingCondition.icon.toUpperCase()];
                    if (iconSetting) {
                        if (iconSetting.icon) {
                            let color = "";
                            if (matchingCondition.color) {
                                color = `style="color: ${matchingCondition.color}"`;
                            }
                            return `<span ${color} class="value_container cell-icon icon fa ${iconSetting.icon}"></span>`;
                        } else {
                            // when 'none' icon is configured, then cell should be empty
                            return "";
                        }
                    }
                }
            }

            // display font with color
            if (conditionalColors && conditionalColors.length > 0) {
                let matchingCondition = findFirstMatchingExpression(conditionalColors, row);
                if (matchingCondition) {
                    return `<span style="color: ${matchingCondition.color}">${cellvalue}</span>`;
                }
            }

            return cellvalue;
        },

        renderChart: function() {
            if (this.isDestroyed) {
                return;
            }

            if (!this.chartConfig) {
                return;
            }

            // if footerRegion contains emptyView the grid should be rendered
            // to have pager initialized
            var gridDisplayEmptyView =
                this.footerRegion &&
                this.footerRegion.currentView &&
                this.footerRegion.currentView.constructor == this.emptyView;

            if (gridDisplayEmptyView || this.model._editable || !this.currentGrid || !this.dataCollection) {
                this.renderTable();
            } else {
                var currentPage = this.currentGrid.jqGrid("getGridParam", "page");

                this.currentGrid.jqGrid("clearGridData");
                this._setDataCollection();
                this.currentGrid.jqGrid("setGridParam", {
                    data: this.dataCollection.toJSON()
                });
                this.currentGrid.jqGrid("setGridParam", {
                    page: currentPage
                });
                this.currentGrid.trigger("reloadGrid");
            }

            this.model.config.lastRendered = new Date().getTime();
        },
        defaultPointClickHandler: function(element) {
            // drill down event _this.trigger('datapoint:click', model)
            var config = element.get("config");
            if (config.get("drilldown").show_drilldown) {
                var show_drilldown = config.get("drilldown").show_drilldown;
                var pageparams = config.get("drilldown").pageparams;
                var page = config.get("drilldown").page;
                this.handleDrillDown(element.get("data").toJSON(), page, show_drilldown, pageparams);
            }
        }
    });
});
