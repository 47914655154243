import App from "app";

export default function toggleFullScreen(control) {
    let parentFormField = control.parentFormField;
    if (!parentFormField) {
        control.parentFormField = control.$el.parent();
    }

    const graphParent = $("#flowDesignerContainer");
    const graph = $(".flow-designer-container");
    const applicationProgress = $(".application-progress-container");
    const rightContent = $(".right-content.with-progress-bar");
    const obj = control.$el.detach();

    if (!control.model.fullScreen) {
        control.$el.addClass("full-screen");
        graph.hide();
        applicationProgress.hide();
        rightContent.css({ top: "104px" });
        graphParent.append(obj);
        control.model.fullScreen = true;
        control.ui.toggleFullScreen.html('<i class="material-icons import-size">fullscreen_exit');
    } else {
        control.$el.removeClass("full-screen");
        control.parentFormField.append(obj);
        graph.show();
        applicationProgress.show();
        rightContent.css({ top: "300px" });
        control.model.fullScreen = false;
        control.ui.toggleFullScreen.html('<i class="material-icons import-size">fullscreen');
    }

    if (control.cm) {
        control.cm.setSize("100%", null);
        control.cm.refresh();
    }

    App.vent.trigger("TQL-full-screen", control.model.fullScreen);
}
