/**
 * This module gets a dashboard JSON file and removes any hard namespace dependencies in the JSON object..
 * basically, things like "query: ns1.query1234" gets replaced as "query: query1234" .. so the dashboard will be able
 * to get imported into different namespaces
 */

import _ from "underscore";

var getJustThename = function (name) {
    if (name.indexOf(".") === -1) {
        return name;
    } else {
        var chunks = name.split(".");
        if (chunks.length === 2) {
            return chunks[1];
        } else {
            return chunks[2];
        }
    }
};

var clean_dashboard = function (dashboard) {
    var pages = [];
    _.each(dashboard.pages, function (page) {
        pages.push(getJustThename(page));
    });
    delete dashboard.nsName;
    dashboard.pages = pages;
};

var clean_pages = function (pages) {
    _.each(pages, function (page) {
        var qvs = [];
        _.each(page.queryVisualizations, function (qv) {
            qvs.push(getJustThename(qv));
        });
        delete page.nsName;
        page.queryVisualizations = qvs;
    });
};

var clean_qvs = function (qvs) {
    _.each(qvs, function (qv) {
        qv.query = getJustThename(qv.query);
        delete qv.nsName;
    });
};
var clean_queries = function (queries) {
    _.each(queries, function (query) {
        delete query.nsName;
    });
};

var clean = function (input) {
    var json;
    if (_.isString(input)) {
        json = JSON.parse(input);
    } else if (_.isObject(input)) {
        json = input;
    } else if (_.isNull(input) || _.isUndefined(input)) {
        json = null;
        return;
    } else {
        throw new Error("The Dashboard provided is not of a valid format. (1)");
    }

    if (!json.dashboard || !json.pages || !json.queryVisualizations || !json.parametrizedQuery) {
        throw new Error("The Dashboard provided is not of a valid format. (2)");
    }
    clean_dashboard(json.dashboard);
    clean_pages(json.pages);
    clean_qvs(json.queryVisualizations);
    clean_queries(json.parametrizedQuery);
    return JSON.stringify(json, null, 4);
};

export default {
    clean: clean,
};
