import React, { useState, useEffect } from "react";
import { useField } from "formik";
import FormikField from "src/generic/formik-field";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import { EMBEDDINGS_GENERATOR_NAMESPACE } from "core/services/metadataService/metaobject-utilities";

const PropertySetSelect = ({ name, label, placeholder, helperText }) => {
    const [availableOptions, setAvailableOptions] = useState([]);
    const [, { value }, { setTouched, setValue }] = useField(name);

    useEffect(() => {
        const getPropertySet = async () => {
            let results = await metaStoreService.fetchIdentifiersByType("PROPERTYSET");
            const options = results?.models
                ?.filter(result => result.nsName !== EMBEDDINGS_GENERATOR_NAMESPACE)
                .map(v => {
                    const nsName = v?.attributes?.nsName;
                    const name = v?.attributes?.name;
                    return {
                        label: `${nsName}.${name}`,
                        value: `${nsName}.${name}`
                    };
                });
            setAvailableOptions(options);
        };

        getPropertySet();
    }, []);

    return (
        <FormikField
            required
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            select
            SelectProps={{
                components: {
                    IndicatorSeparator: () => null
                },
                options: availableOptions,
                onChange: data => {
                    setTouched(true);
                    setValue(data || "");
                },
                onBlur: () => setTouched(true)
            }}
        />
    );
};

export default PropertySetSelect;
