export const sxStyles = {
    automated: {
        backgroundColor: "primary.100",
        color: "primary.500",
        borderRadius: ({ spacing }) => spacing(1),
        height: ({ spacing }) => spacing(3),
        border: 1,
        borderColor: ({ palette }) => palette.primary[100]
    },
    schemaConversion: {
        backgroundColor: "greyscale.200",
        border: 1,
        borderColor: "greyscale.100",
        borderRadius: ({ spacing }) => spacing(1),
        height: ({ spacing }) => spacing(3)
    },
    typography: {
        fontWeight: 600
    }
};
