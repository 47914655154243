import "app/components/dashboard/visualization/charts/chart";
import App from "app";
import d3 from "d3";
import "d3floorplan";
import "d3floorplan_heatmap";
import "lib/vendor/floorplan/imagelayer";
import "lib/vendor/floorplan/pathplot";

App.module("Chart.ImageMap", function (Chart, App, Backbone, Marionette, $, _) {
    Chart.View = App.Chart.Default.ChartView.extend({
        className: "chart icon",
        initialize: function () {
            this.d3svgID = arguments[0].model.attributes.id.replace(/\./g, "-") + "-D3SVG";
            this.template = _.template('<svg id="' + this.d3svgID + '" width="100%" height="100%"></svg>');
            App.Chart.Default.ChartView.prototype.initialize.apply(this, arguments);
            Backbone.Marionette.ItemView.prototype.render.apply(this, arguments);
        },
        setConfiguration: function (config) {
            this.chartConfig = config;
            config.config.maxSeries = parseInt(config.config.maxZones) + config.config.maxPaths;
            config.config.maxValues =
                parseInt(config.config.maxZones) + config.config.maxPaths * config.config.maxPathEntries;
        },
        render: function () {
            var _this = this;

            if (!this.chartConfig) {
                return;
            }

            var svgElement = d3.select("#" + this.d3svgID);

            var width = this.$el.width();
            var height = this.$el.height();

            var dimensionsChanged = false;
            if (_this.lastWidth && _this.lastHeight) {
                if (_this.lastWidth !== width || _this.lastHeight !== height) {
                    dimensionsChanged = true;
                }
            }
            _this.lastWidth = width;
            _this.lastHeight = height;

            var limitToProperties = function (key, value) {
                if (key === "_spec" || key === "data" || key === "last_data" || key === "__proto__") {
                    return undefined;
                } else {
                    return value;
                }
            };

            var propsAsString = JSON.stringify(_this.chartConfig.config, limitToProperties);
            if (_this.lastPropsAsString && _this.lastPropsAsString === propsAsString && !dimensionsChanged) {
                console.log("Properties are the same as last render");
            } else {
                if (this.d3SVDGEl) {
                    console.log("Properties have changed since last render");
                    svgElement.select("g").remove();

                    this.d3SVDGEl = null;
                } else {
                    console.log("This is the first render");
                }
            }
            _this.lastPropsAsString = propsAsString;

            console.log("Image Map Render", _this);

            var orientX = _this.chartConfig.config.orientX;
            var invertX = orientX === "right";
            var orientY = _this.chartConfig.config.orientY;
            var invertY = orientY === "bottom";

            var pathHeadSize = _this.chartConfig.config.pathHeadSize;
            var pathAnimSize = _this.chartConfig.config.pathAnimSize;
            var pathAnimTimes = _this.chartConfig.config.pathAnimTimes;
            var pathTimeOut = _this.chartConfig.config.pathTimeOut;

            var minX = _this.chartConfig.config.minX;
            var maxX = _this.chartConfig.config.maxX;
            var minY = _this.chartConfig.config.minY;
            var maxY = _this.chartConfig.config.maxY;

            var xSize = maxX - minX;
            var ySize = maxY - minY;

            var hmBoxes = _this.chartConfig.config.hmBoxes;
            var hmBinSize = Math.min(xSize / hmBoxes, ySize / hmBoxes);

            var processData = function (thing) {
                // get the things we need
                // we only need one of each zone
                // and the last _this.chartConfig.config.maxPathEntries path entries for each path

                var itemsToProcess = {};
                var prevHeatMap = {};

                $.each(thing, function (key, value) {
                    _.each(value, function (item) {
                        var zoneName = item[_this.chartConfig.config.zoneNameField];
                        if (zoneName) {
                            itemsToProcess[key] = [item];
                        }
                        var pathName = item[_this.chartConfig.config.pathNameField];
                        var now = new Date().getTime();
                        if (!item.firstTime) {
                            item.firstTime = new Date().getTime();
                        }
                        var then = item.firstTime;
                        var age = now - then;

                        if (pathName && (pathTimeOut === -1 || age < pathTimeOut * 1000)) {
                            if (!itemsToProcess[key]) {
                                itemsToProcess[key] = [];
                            }
                            itemsToProcess[key].push(item);
                            while (itemsToProcess[key].length > _this.chartConfig.config.maxPathEntries) {
                                itemsToProcess[key].shift();
                            }

                            var pathGeom = item[_this.chartConfig.config.pathGeomField];

                            var heatmapx = Math.floor(pathGeom.lat / hmBinSize) * hmBinSize;
                            var heatmapy = Math.floor(pathGeom.lon / hmBinSize) * hmBinSize;

                            var heatmapbox = _this.layerData.heatmap.map.find(function (x) {
                                return x.x === heatmapx && x.y === heatmapy;
                            });

                            if (heatmapbox) {
                                heatmapbox.value = heatmapbox.value + 1;
                            } else {
                                heatmapbox = {
                                    x: heatmapx,
                                    y: heatmapy,
                                    value: 1,
                                };
                                _this.layerData.heatmap.map.push(heatmapbox);
                            }
                            var prevHeatMapX,
                                prevHeatMapY,
                                xdelta,
                                ydelta,
                                deltalength,
                                samples,
                                xinc,
                                yinc,
                                hm,
                                x,
                                y,
                                fx,
                                fy,
                                hmkey;
                            if (prevHeatMap[key]) {
                                prevHeatMapX = prevHeatMap[key].x;
                                prevHeatMapY = prevHeatMap[key].y;

                                xdelta = prevHeatMapX - heatmapx;
                                ydelta = prevHeatMapY - heatmapy;
                                deltalength = Math.sqrt(xdelta * xdelta + ydelta * ydelta);
                                samples = (deltalength * 2) / hmBinSize;
                                xinc = xdelta / samples;
                                yinc = ydelta / samples;

                                var crossedBoxes = {};
                                for (hm = 0; hm < samples; hm++) {
                                    x = heatmapx + xinc * hm;
                                    y = heatmapy + yinc * hm;

                                    fx = Math.floor(x / hmBinSize) * hmBinSize;
                                    fy = Math.floor(y / hmBinSize) * hmBinSize;

                                    hmkey = "x:" + fx + " y:" + fy;

                                    if (!crossedBoxes[hmkey]) {
                                        crossedBoxes[hmkey] = {};
                                        crossedBoxes[hmkey].value = 1;
                                        crossedBoxes[hmkey].x = fx;
                                        crossedBoxes[hmkey].y = fy;
                                    } else {
                                        crossedBoxes[hmkey].value = crossedBoxes[hmkey].value + 1;
                                    }
                                }
                                $.each(crossedBoxes, function (hmkey, hmvalue) {
                                    if (
                                        (hmvalue.x !== heatmapx || hmvalue.y !== heatmapy) &&
                                        (hmvalue.x !== prevHeatMapX || hmvalue.y !== prevHeatMapY)
                                    ) {
                                        var heatmapbox = _this.layerData.heatmap.map.find(function (x) {
                                            return x.x === hmvalue.x && x.y === hmvalue.y;
                                        });
                                        if (heatmapbox) {
                                            heatmapbox.value = heatmapbox.value + 1;
                                        } else {
                                            heatmapbox = {
                                                x: hmvalue.x,
                                                y: hmvalue.y,
                                                value: 1,
                                            };
                                            _this.layerData.heatmap.map.push(heatmapbox);
                                        }
                                    }
                                });
                            } else {
                                prevHeatMap[key] = {};
                            }
                            prevHeatMap[key].x = heatmapx;
                            prevHeatMap[key].y = heatmapy;
                        }
                    });
                });

                $.each(itemsToProcess, function (key, value) {
                    _.each(value, function (item) {
                        var zoneName = item[_this.chartConfig.config.zoneNameField];
                        if (zoneName) {
                            //                                console.log("Dealing with Zone for Item", item);
                            var zoneColor = item[_this.chartConfig.config.zoneColorField];
                            var zoneGeom = item[_this.chartConfig.config.zoneGeomField];

                            var zone = _this.layerData.zones.map.find(function (x) {
                                return x.name === zoneName;
                            });
                            if (zone) {
                                zone.color = zoneColor;
                                zone.name = zoneName;
                                zone.points.length = 0;
                            } else {
                                zone = {
                                    value: 17.0,
                                    name: zoneName,
                                    color: zoneColor,
                                    points: [],
                                };
                                _this.layerData.zones.map.push(zone);
                            }
                            _.each(zoneGeom.latLonPairs, function (llp) {
                                zone.points.push({
                                    x: llp[0],
                                    y: llp[1],
                                });
                            });
                        }
                        var pathName = item[_this.chartConfig.config.pathNameField];
                        if (pathName) {
                            //                                console.log("Dealing with Path for Item", item);
                            var pathColor = item[_this.chartConfig.config.pathColorField];
                            var pathGeom = item[_this.chartConfig.config.pathGeomField];
                            var path = _this.layerData.pathplot.find(function (x) {
                                return x.id === pathName;
                            });
                            if (path) {
                                path.color = pathColor;
                                path.id = pathName;
                            } else {
                                path = {
                                    id: pathName,
                                    color: pathColor,
                                    points: [],
                                    headSize: pathHeadSize,
                                    animSize: pathAnimSize,
                                    animTimes: pathAnimTimes,
                                };
                                _this.layerData.pathplot.push(path);
                            }
                            path.points.unshift({
                                x: pathGeom.lat,
                                y: pathGeom.lon,
                            });
                            while (path.points.length > _this.chartConfig.config.maxPathEntries) {
                                path.points.pop();
                            }
                        }
                    });
                });
            };

            if (!this.d3SVDGEl) {
                // Load floorplan css
                var cssId = "floorplanCss";

                if (!document.getElementById(cssId)) {
                    var head = document.getElementsByTagName("head")[0];
                    var link = document.createElement("link");
                    link.id = cssId;
                    link.rel = "stylesheet";
                    link.type = "text/css";
                    link.href = "lib/vendor/floorplan/d3.floorplan.css";
                    link.media = "all";
                    head.appendChild(link);
                }

                var xscale = d3.scale
                        .linear()
                        .domain(invertX ? [maxX, minX] : [minX, maxX])
                        .range([0, width]),
                    yscale = d3.scale
                        .linear()
                        .domain(invertY ? [maxY, minY] : [minY, maxY])
                        .range([0, height]);

                _this.map = d3.floorplan().xScale(xscale).yScale(yscale);
                var zones = d3.floorplan.heatmap("zones"),
                    pathplot = d3.floorplan.pathplot("paths"),
                    heatmap = d3.floorplan.heatmap("heatmap"),
                    mapdata = {};

                _.each(_this.chartConfig.config.images, function (item) {
                    var imagelayer = d3.floorplan.imagelayer(item.label, item.display);
                    mapdata[imagelayer.id()] = [
                        {
                            url: item.imageSrc,
                            x: invertX ? maxX : minX,
                            width: invertX ? -xSize : xSize,
                            y: invertY ? maxY : minY,
                            height: invertY ? -ySize : ySize,
                            opacity: item.opacity,
                        },
                    ];
                    _this.map.addLayer(imagelayer);
                });

                _this.map.addLayer(zones).addLayer(heatmap).addLayer(pathplot);

                this.layerData = {
                    zones: {
                        binSize: 3,
                        units: "",
                        map: [],
                    },
                    heatmap: {
                        binSize: hmBinSize,
                        units: "",
                        map: [],
                    },
                    pathplot: [],
                };

                _.each(_this.chartConfig.config.data, processData);

                mapdata[zones.id()] = this.layerData.zones;
                mapdata[pathplot.id()] = this.layerData.pathplot;
                mapdata[heatmap.id()] = this.layerData.heatmap;

                this.d3SVDGEl = svgElement
                    .attr("width", width)
                    .attr("height", height)
                    .append("g")
                    .attr("width", width)
                    .attr("height", height)
                    .datum(mapdata)
                    .call(_this.map);
            } else {
                _.each(_this.layerData.pathplot, function (item) {
                    item.points.length = 0;
                });
                _this.layerData.heatmap.map.length = 0;
                _.each(_this.chartConfig.config.data, processData);

                this.d3SVDGEl = svgElement
                    .attr("width", width)
                    .attr("height", height)
                    .select("g")
                    .attr("width", width)
                    .attr("height", height)
                    .call(_this.map);
            }

            if (!this._inDOM()) {
                this.$("svg").hide();
            } else {
                this.$("svg").show();
            }
        },
    });
});
