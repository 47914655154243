import { LayoutView } from "marionette";
import ReactDOM from "react-dom/client";
import React from "react";
import TargetLayout from "src/modules/wizards/target/components/target-layout/target-layout";
import WizardProvider from "src/modules/wizards/wizard-context";

class TargetWizard {
    constructor(predefinedComponentName, getCustomAdapterProperties, stepName) {
        this.View = this.getView().extend({
            predefinedComponentName,
            getCustomAdapterProperties,
            stepName
        });
    }

    get() {
        return {
            View: this.View
        };
    }

    getView() {
        const TargetWizard = LayoutView.extend({
            template: _.template(""),
            predefinedComponentName: null,
            initialize: function(options) {
                this.options = options;
            },
            onRender: function() {
                const root = ReactDOM.createRoot(this.$el[0]);
                root.render(
                    <WizardProvider>
                        <TargetLayout target={this.predefinedComponentName} {...this.options} />
                    </WizardProvider>
                );
            }
        });
        return TargetWizard;
    }
}

export default TargetWizard;
