import _ from "underscore";
import growl from "./growl";

/** @constructor
 * @param {String} id - the id of the component that will be sending the notifications
 * */
var GrowlFrom = function (id) {
    this.id = id;
    _.extend(this, growl);
};

export default GrowlFrom;
