import api from "../../api/api";

let licenseService = {};
licenseService = {
    isAIInsightsDisabled: false,
    nonSegmentedlicenseName: null
};

licenseService.init = function() {
    let deferred = $.Deferred();

    (async () => {
        let isAIInsightsDisabled;
        let nonSegmentedlicenseName = null;
        try {
            isAIInsightsDisabled = await api.isLicenseOptionDisableAIInsights();
            licenseService.isAIInsightsDisabled = isAIInsightsDisabled;

            nonSegmentedlicenseName = await api.isLicenseOptionSQL2FabricSolution();
            licenseService.nonSegmentedlicenseName = nonSegmentedlicenseName ? "SQL2FABRICSOLUTION" : null;
        } catch (e) {
            console.error(e);
        }
        deferred.resolve();
    })();

    return deferred.promise();
};

export default licenseService;
