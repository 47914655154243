import App from "app";
import Cocktail from "backbone.cocktail";
import modal from "app/components/common/modal/modal";
import dialog_template_html from "app/components/common/_deprecated/form-mixin-dialog/dialog.html";
import "app/components/common/_deprecated/form/form";
import NestedTypes from "backbone.nestedtypes";
import NProgress from "nprogress";
import ensureRegions from "../../_ensureRegions";
NProgress.configure({
    minimum: 0.25,
    trickleRate: 0.1,
    trickleSpeed: 2000,
});

App.module("FormMixinDialog", function (Dialog, App, Backbone, Marionette, $, _) {
    Dialog.Controller = modal.Controller.extend({
        initialize: function () {
            this.listenTo(this, "view-set", function (view) {
                this.listenTo(view, "cancel", function () {
                    NProgress.done();
                    this.close();
                });
            });

            modal.Controller.prototype.initialize.apply(this, arguments);
        },
    });

    Dialog.View = Cocktail.mixin(modal.View.extend({}), App.Form.Mixin, {
        template: _.template(dialog_template_html),
        triggers: {},

        ui: {
            submitButton: ".btn.submit",
        },

        initialize: function (opts) {
            _(this).bindAll("submit", "save");

            if (this.model && !(this.model instanceof NestedTypes.Model)) {
                if (!this.model.get("submit_text")) {
                    this.model.set("submit_text", "Save");
                }
                if (!this.model.get("cancel_text")) {
                    this.model.set("cancel_text", "<i class='material-icons'>close</i>");
                }
            }

            if (this.model && !this.model.get("actions")) {
                this.model.set("actions", []);
            }

            _(this.options).extend(opts || {});

            _(this.options).defaults({
                bind_submit: false, // automatically bind submit button to fire submit? (common)
                bind_cancel: true, // automatically bind cancel button to fire cancel? (common)
                submit_on_enter: false,
                autosave: true, // Automatically update the editor context as the form is edited
            });

            // Automatically bind submit and cancel buttons
            if (this.options.bind_submit) {
                this.triggers["click .submit"] = "submit";
                this.delegateEvents();
                this.listenTo(this, "submit", this.submit);
            }
            if (this.options.bind_cancel) {
                this.triggers["click .cancel"] = "cancel-clicked";
                this.delegateEvents();
                this.listenTo(this, "cancel-clicked", function () {
                    this.trigger("before:dialog:cancel"); // Temp, until we transition to new Dialog module
                    this.trigger("cancel");
                });
            }

            this.listenTo(this, "progress:start", function () {
                NProgress.configure({
                    parent: "footer"
                });
                NProgress.start();
            });

            this.listenTo(this, "progress:end", function () {
                NProgress.done();
            })

            // Changes to form elements, reordering of clonable elements, etc. trigger context update
            this.listenTo(this, "autosave", function () {
                this.save({
                    silent: true,
                });
            });

            this.listenTo(this, "before:render", function () {
                // Destroy existing dropdowns
                this._destroy_dropdowns();

                // Turn off change listeners
                var $inner = this.$(".modal-body .inner");
                $inner.off("change.dialog");
            });

            this.listenTo(this, "render", function () {
                var _this = this;

                if (this.options.contentview) {
                    var region = this.addRegion("content", "#dialog-body");
                    ensureRegions(_this);
                    region.show(this.options.contentview);
                }

                // Every time any input in the view is changed, update the context (call save())
                if (this.options.autosave) {
                    var $inner = this.$(".modal-body .inner");
                    $inner.on("change.dialog", "input, select, textarea", function () {
                        _this.trigger("autosave");
                    });
                }
            });

            // Wait until all mixins have rendered, so form element is available
            this.listenTo(this, "show", function () {
                var _this = this;

                // When the enter key is pressed, submit.
                if (this.options.submit_on_enter) {
                    var $form = this.$("form").first();
                    if ($form.length > 0) {
                        $form.on("submit", function (e) {
                            e.stopPropagation();
                            return false;
                        });
                    }
                    $(document).on("keypress." + this.options.id, function (e) {
                        if (e.which === 13 || e.keyCode === 13) {
                            _this.trigger("submit");
                            return false;
                        }
                    });
                }
            });

            this.listenTo(this, "before:destroy", function () {
                $(document).off("keypress." + this.options.id);
            });
        },

        onRender: function () {
            if (this.model && this.model.get("showSubmitButton") === false) {
                this.ui.submitButton.hide();
            }

            if (this.model && this.model.get("actions")) {
                const actions = this.model.get("actions") || [];
                actions.forEach((action) => {
                    this.$el.find("#custom_dialog_action_" + action.id).click((e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        action.click();
                    });
                });
            }
        },
    });
});
