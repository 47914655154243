import Backbone from "backbone";
import NestedTypes from "backbone.nestedtypes";
import _ from "underscore";
import $ from "jquery";
import api from "core/api/api";
import metaObjects from "./meta-objects";

var metaObjectIdentifier = {};

metaObjectIdentifier.Model = NestedTypes.Model.extend({
    _actions: {},
    idAttribute: "id",
    defaults: {
        id: String,
        type: String,
        /** should override **/
        subType: String,
        name: String,
        nsName: String,
        ctime: Number,
        metaInfoStatus: null,
        isEditable: true,
    },
    fetchMetaObject: function () {
        var $def = new $.Deferred();
        api.read(null, {
            id: this.id,
            type: this.type,
        }).then(function (data) {
            data = data[0];

            //TODO: why does it return an array for ids that does not exist?
            if (!data.type) {
                $def.resolve(null);
            } else {
                var Model = metaObjects[data.type].Model;
                var model = new Model(data);
                $def.resolve(model);
            }
        });

        return $def.promise();
    },
    sync: function () {
        throw new Error("Identifiers cannot be saved or fetched");
    },
});

metaObjectIdentifier.Collection = NestedTypes.Collection.extend({
    model: metaObjectIdentifier.Model,
});

export default metaObjectIdentifier;
