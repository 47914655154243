import React, { useRef } from "react";
import { Box, Grid, InputAdornment } from "@mui/material";
import { StriimChip, StriimInputField, StriimTypography, StriimIconButton } from "@striim/striim-ui";
import CloseIcon from "@mui/icons-material/Close";
import { Clear, Search } from "@mui/icons-material";
import { GridView, ListView, NewRelease } from "../../../../../../..//generic/icon/customIcons";
import styles from "../../../create-app-page-styles";
import AlphabeticalSortButton from "../../../../../../../generic/alphabetical-sort-button/alphabetical-sort-button";
import utils from "../../../../../../../../core/utils";
import { createAppUtils } from "../../../create-app-page-utils";
import { filtersType } from "../adapters-selection-layout/create-app-page-adapter-selection-layout";

const Categories = (type: "source" | "target") => [
    { label: "All", value: "all" },
    {
        label: (
            <Box display={"flex"} alignItems={"center"} gap={1}>
                <NewRelease fontSize={16} />
                Newly Released
            </Box>
        ),
        value: "new"
    },
    ...(type === "source"
        ? createAppUtils.getCategoriesForFilters().sourceCategories
        : createAppUtils.getCategoriesForFilters().targetCategories)
];

interface CategoryListProps {
    filters: filtersType;
    setFilters: (filter: Object) => {};
    type: "source" | "target";
}
const CategoryList: React.FC<CategoryListProps> = ({ filters, setFilters, type = "target" }) => {
    const setCategory = e => setFilters(prevFilters => ({ ...prevFilters, category: e }));
    return (
        <Box sx={styles.categoriesGrid}>
            {Categories(type).map(item => (
                <StriimChip
                    type="tag-chip"
                    variant="default"
                    key={item.value}
                    label={item.label}
                    hideAvatar
                    onClick={() => setCategory(item.value)}
                    sx={item.value === filters.category ? styles.activeCategory : {}}
                    clickable={false}
                />
            ))}
        </Box>
    );
};

interface SearchProps {
    type: string;
    setFilters: (filter: Object) => {};
}
const SearchFilter: React.FC<SearchProps> = ({ setFilters, type }) => {
    const searchInputRef = useRef(null);
    const setSearch = e => setFilters(prevFilters => ({ ...prevFilters, id: e, category: "all" }));
    const clearFilters = () => setFilters(prevFilters => ({ ...prevFilters, id: "" }));
    return (
        <StriimInputField
            placeholder={`Search 100+ ${type}s`}
            inputRef={searchInputRef}
            onChange={setSearch}
            sx={{
                my: 2,
                ".MuiInputBase-input": {
                    pl: 0
                }
            }}
            autoFocus
            InputProps={{
                "data-test-id": "search-adapter",
                startAdornment: (
                    <InputAdornment position="start">
                        <Search opacity={0.45} />
                    </InputAdornment>
                ),
                ...(searchInputRef.current?.value?.length && {
                    endAdornment: (
                        <StriimIconButton
                            variant="text"
                            onClick={() => {
                                searchInputRef.current.value = "";
                                clearFilters();
                            }}
                            data-test-id="search-adapter-clear-button"
                        >
                            <Clear sx={styles.searchCloseIcon} />
                        </StriimIconButton>
                    )
                })
            }}
        />
    );
};
interface ViewSelectionProps {
    selectedView: "list" | "grid";
    setSelectedView: Function;
}
const ViewSelection: React.FunctionComponent = ({ selectedView, setSelectedView }) => {
    return (
        <Box sx={styles.viewSelectionGrid}>
            <Box
                data-test-id="grid-view-select"
                sx={styles.viewIcon}
                className={selectedView === "grid" ? "selected" : ""}
                onClick={() => setSelectedView("grid")}
            >
                <GridView />
            </Box>
            <Box
                data-test-id="list-view-select"
                sx={styles.viewIcon}
                className={selectedView === "list" ? "selected" : ""}
                onClick={() => setSelectedView("list")}
            >
                <ListView />
            </Box>
        </Box>
    );
};

interface FilterProps {
    filters: Object;
    setFilters: Function;
    type: string;
    onClose: () => {};
    selectedView: "list" | "grid";
    setSelectedView: Function;
    isAscendingSort: boolean;
    setIsAscendingSort: Function;
}
const CreateAppPageAdaptersFilter: React.FC<FilterProps> = ({
    filters,
    setFilters,
    type,
    onClose,
    selectedView,
    setSelectedView,
    isAscendingSort,
    setIsAscendingSort
}) => {
    return (
        <Grid m={3}>
            <Grid container justifyContent={"space-between"}>
                <StriimTypography
                    sx={styles.selectText}
                    variant="h3"
                    color="primary.700"
                    data-test-id={`select-${type}-header`}
                >
                    Select a {utils.capitalize(type)}
                </StriimTypography>
            </Grid>
            <SearchFilter setFilters={setFilters} type={type} />
            <Grid container justifyContent="space-between">
                <CategoryList type={type} filters={filters} setFilters={setFilters} />
                <Grid item>
                    <Grid container alignItems="center" gap={1.5}>
                        <AlphabeticalSortButton isAscending={isAscendingSort} setIsAscending={setIsAscendingSort} />
                        <ViewSelection selectedView={selectedView} setSelectedView={setSelectedView} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CreateAppPageAdaptersFilter;
