import { styles } from "./sentinel-table.styles";
import { StriimTypography } from "@striim/striim-ui-v2";
import { Box } from "@mui/material";
import React from "react";

interface TooltipElement {
    name: string;
    fieldName: string;
    color: string;
}

interface SentinelTableBarTooltipProps {
    data: object;
    elements: TooltipElement[];
}

export const SentinelTableBarTooltip = ({ data, elements }: SentinelTableBarTooltipProps) => (
    <Box sx={styles.tooltipWrapper}>
        {data.tooltipDate ? (
            <StriimTypography variant="caption4" fontFamily="Inter" sx={styles.tooltipHeading}>
                {data.tooltipDate}
            </StriimTypography>
        ) : null}

        {elements.map(el => (
            <Box sx={styles.tooltipLabel} key={el.name}>
                <Box component="span" sx={styles.tooltipLabelTitle}>
                    <Box sx={{ ...styles.tooltipSquare, backgroundColor: el.color }} />
                    <StriimTypography variant="caption3" fontFamily="Inter" color="greyscale.700">
                        {el.name}
                    </StriimTypography>
                </Box>

                <StriimTypography variant="caption3" fontFamily="Inter" color="#1E1E5C" sx={styles.tooltipValue}>
                    {data[el.fieldName]}
                </StriimTypography>
            </Box>
        ))}
    </Box>
);

export enum Colors {
    Identifier = "#B6BEEB",
    Sensitive = "#6C64C7",
    NonSensitive = "#49C7BB",
    Masked = "#ED88BA",
    Encrypted = "#356BC0",
    NoAction = "#D9DEE4",
    Rest = "#EBF2FA", // @greyscale.100
    sensitiveBarColor = "#7E7ED6",
    noActionBarColor = "#D8E4F0"
}

// Easy to change when BE be available
export enum SentinelTableTypeRowFields {
    name = "name",
    namespace = "namespace",
    events = "events",
    sensitiveData = "sensitiveData",
    nonSensitiveData = "nonSensitiveData",
    eventsWithSDI = "eventsWithSDI",
    sentinels = "sentinels",
    sdiSensitiveData = "sdiSensitiveData",
    sdiTaggedSensitiveData = "sdiTaggedSensitiveData"
}

export enum EventsWithSDIFields {
    sensitiveData = "eventsWithSDI.sensitiveData",
    nonSensitiveData = "eventsWithSDI.nonSensitiveData",
    taggedSensitiveData = "eventsWithSDI.taggedSensitiveData"
}

export enum AllAppsTableTypeRowFields {
    name = "name",
    namespace = "namespace",
    events = "eventsProcessed",
    sensitiveData = "eventsWithSensitiveData",
    nonSensitiveData = "eventsWithoutSensitiveData",
    eventsTagged = "eventsWithSensitiveDataTagged"
}

export enum SDIDrillDownOccurrencesRowFields {
    name = "name",
    namespace = "namespace",
    label = "label",
    eventsWithIdentifier = "eventsWithIdentifier",
    occurrences = "occurrences",
    ENCRYPT = "ENCRYPT",
    MASK = "MASK",
    NO_ACTION = "NO_ACTION",
    sentinelCount = "sentinelCount",
    applications = "applications"
}

export const elementsSensAndNon = [
    {
        name: "Events W/ Sensitive Data",
        fieldName: SentinelTableTypeRowFields.sensitiveData,
        color: Colors.Sensitive
    },
    {
        name: "Events W/O Sensitive Data",
        fieldName: SentinelTableTypeRowFields.nonSensitiveData,
        color: Colors.NonSensitive
    }
];

export const elementsSDISensAndNon = [
    {
        name: "Events W/ Sensitive Data",
        fieldName: SentinelTableTypeRowFields.sensitiveData,
        color: Colors.Sensitive
    },
    {
        name: "Events W/O Sensitive Data",
        fieldName: SentinelTableTypeRowFields.nonSensitiveData,
        color: Colors.NonSensitive
    }
];

export const elementsIdentifier = (name: string, fieldName: string) => {
    return [
        {
            name: "Sensitive Data W/ " + name ?? "Identifier",
            fieldName: fieldName,
            color: Colors.Identifier
        },
        ...elementsSensAndNon
    ];
};

export const elementsSDIIdentifier = (name: string, fieldName: string) => {
    return [
        {
            name: "Sensitive Data W/ " + name ?? "Identifier",
            fieldName: fieldName,
            color: Colors.Identifier
        },
        ...elementsSDISensAndNon
    ];
};

export const EmptyBar = () => "-";

export const sortTableData = (tableData, order = true) => {
    const zeroVal = tableData.filter(el => el.eventsWithIdentifier === 0);
    const notZeroVal = tableData.filter(el => el.eventsWithIdentifier !== 0);

    notZeroVal.sort((valA, valB) => {
        return order
            ? valA.eventsWithIdentifier - valB.eventsWithIdentifier
            : valB.eventsWithIdentifier - valA.eventsWithIdentifier;
    });

    const sortedData = [...notZeroVal, ...zeroVal];

    return sortedData;
};
