import React from "react";
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer } from "recharts";
import { StriimTypography } from "@striim/striim-ui";
import { Box, Divider, Grid } from "@mui/material";
import numeral from "numeral";

import { styles } from "./events-processed-chart.styles";

const colors = {
    purple: "#7E7ED6",
    turquoise: "#49C7BB"
};

interface EventsProcessedChartProps {
    eventsProcssed: number;
    eventsData: {
        eventsWithSensitiveData: number;
        eventsWithoutSensitiveData: number;
    };
    showBottomBorder?: boolean;
}

const EventsProcessedChart: React.FC<EventsProcessedChartProps> = ({
    eventsProcssed,
    eventsData,
    showBottomBorder = true
}) => {
    const sensitiveDataBarName = `W/ sensitive data (${numeral(eventsData.eventsWithSensitiveData)
        .format("0.[00]a")
        .toUpperCase()})`;
    const nonSensitiveDataBarName = `W/O sensitive data (${numeral(eventsData.eventsWithoutSensitiveData)
        .format("0.[00]a")
        .toUpperCase()})`;

    return (
        <>
            <Grid container p={2} gap={1} flexDirection={"column"}>
                <Grid container>
                    <StriimTypography
                        variant="caption1"
                        color="greyscale.900"
                        fontSize={14}
                        lineHeight={"20px"}
                        fontWeight={400}
                        fontFamily="Inter"
                    >
                        Events Processed
                    </StriimTypography>
                </Grid>
                <Grid container>
                    <StriimTypography
                        variant="caption1"
                        color="primary.700"
                        fontSize={"22px"}
                        lineHeight={"32px"}
                        fontFamily="Nunito"
                        name="processed-events-count"
                    >
                        {numeral(eventsProcssed)
                            .format("0.[00]a")
                            .toUpperCase()}
                    </StriimTypography>
                </Grid>
                <Grid container>
                    <ResponsiveContainer width={"100%"} height={70}>
                        <BarChart
                            width={408}
                            height={20}
                            data={[eventsData]}
                            layout="vertical"
                            margin={styles.barChartPosition}
                            barSize={12}
                            style={styles.barChart}
                        >
                            <XAxis hide type="number" />
                            <YAxis hide type="category" />

                            <Bar
                                name={sensitiveDataBarName}
                                dataKey="eventsWithSensitiveData"
                                stackId="single-stack"
                                fill={colors.purple}
                                radius={[4, 0, 0, 4]}
                            />
                            <Bar
                                name={nonSensitiveDataBarName}
                                dataKey="eventsWithoutSensitiveData"
                                stackId="single-stack"
                                fill={colors.turquoise}
                                radius={[0, 4, 4, 0]}
                            />
                            <Legend
                                verticalAlign="bottom"
                                align="center"
                                height={36}
                                iconSize={11}
                                iconType="square"
                                layout="vertical"
                                wrapperStyle={styles.legendWrapper}
                                formatter={value => (
                                    <Box component={"span"} sx={styles.legendText}>
                                        {value}
                                    </Box>
                                )}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
            {showBottomBorder && <Divider variant="fullWidth" sx={{ width: "100%", borderColor: "greyscale.200" }} />}
        </>
    );
};

export default EventsProcessedChart;
