const styles = {
    wrapper: {
        backgroundColor: "background.paper",
        justifyContent: "center",
        border: ({ palette }) => `1px solid ${palette.greyscale["200"]}`,
        borderRadius: ({ spacing }) => spacing(1),
        flexDirection: "column",
        width: "auto",
        margin: ({ spacing }) => `${spacing(2)} auto 0 auto`
    },
    headerContainer: {},
    childContainer: {
        maxHeight: "60vh",
        overflow: "auto"
    },
    footerContainer: {}
};

export default styles;
