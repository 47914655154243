import App from "app";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import HighChartsMixin from "app/components/dashboard/visualization/charts/mixins/highcharts";
import "app/components/dashboard/visualization/charts/seriesgenerators/prediction/generator";
import { tooltipStriimlineHighcharts } from "../../../../../../styles/materialize/components-striimline/tooltip.export";

export default _({}).extend(HighChartsMixin, {
    /**
     *  Gets an object for X axis traning window plotband
     *
     * @param config
     * @returns {{from: Date, to: *, color: string, label: {text: string, style: {color: string}, y: number}}[]}
     * @private
     */
    _getXAxisTrainingWindowConfig: function(config) {
        this.observedTimeField = config.observedTimeField;

        $.each(config.data, function(i, obj) {
            //@TODO-HACK - undo Hack below
            if (typeof obj.observed_time == "number") obj.observed_time = new Date(obj.observed_time);
        });
        var lastRecord = config.data[config.data.length - 1];
        var trainingWindowSize = 1000 * 60 * lastRecord.window_size_minutes;
        var trainingWindowColor = "#283134";
        var trainingWindowTextColor = "#999999";
        var trainingTimeStamp = lastRecord.observed_time.getTime() - trainingWindowSize;
        var trainingTime = new Date(trainingTimeStamp);
        return [
            {
                from: trainingTime,
                to: lastRecord.observed_time,
                color: trainingWindowColor,
                label: {
                    text: "Training window",
                    style: {
                        color: trainingWindowTextColor
                    },
                    y: 40
                }
            }
        ];
    },
    /**
     *
     * @param config
     * @returns {*}
     */
    setConfiguration: function(config) {
        var theme = config.theme;
        var config = config.config;
        this.config = config;
        this.resultField = config.resultField;
        this.observedTimeField = config.observedTimeField;
        this.observedValueField = config.observedValueField;
        var xAxisConfig = this._formatXAxis(config.xAxis, theme);
        if (xAxisConfig.length === 0) return;
        if (config.data !== undefined && config.data.length > 0 && this.predictiveType === "realTime") {
            xAxisConfig[0].plotBands = this._getXAxisTrainingWindowConfig(config);
        }

        var yAxisConfig = this._formatYAxis(config.yAxis, theme);
        if (
            config.yAxisLimits !== undefined &&
            config.yAxisLimits.lowerBound !== "" &&
            config.yAxisLimits.upperBound !== ""
        ) {
            var lowerBound = parseInt(config.yAxisLimits.lowerBound, 10);
            var upperBound = parseInt(config.yAxisLimits.upperBound, 10);
            // when we set min and max on each Y axis,
            // then every serie will have own Y axis.
            // To have limit together with single axis it has to be set
            // only for the first axis
            if (yAxisConfig.length > 0) {
                var yAxis = yAxisConfig[0];
                yAxis.min = lowerBound;
                yAxis.max = upperBound;
            }
        }

        if (!theme) return console.warn("No theme provided.");

        this.chartConfig = {
            chart: {
                type: "line",
                backgroundColor: theme.chart.background,
                animation: false
            },
            credits: {
                enabled: false
            },
            tooltip: {
                ...this.generateToolTipConfig(),
                crosshairs: true,
                shared: true
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: false
                    },
                    enableMouseTracking: true,
                    marker: {
                        symbol: "circle"
                    }
                },
                series: {
                    cursor: "pointer",
                    animation: false,
                    events: {
                        hide: function(e) {
                            this.trigger("series-hidden", e);
                        }.bind(this),
                        show: function(e) {
                            this.trigger("series-shown", e);
                        }.bind(this)
                    },
                    point: {
                        events: {
                            click: function(e) {
                                //var index = e.point.index;
                                //var element = this.model.get('query').get('data')[index];
                                var element = e.currentTarget.options.rowData;
                                this.trigger("datapoint:click", element);
                            }.bind(this)
                        }
                    },
                    marker: {
                        enabled: true,
                        radius: 4
                    }
                }
            },
            legend: {
                enabled: false
            },
            title: {
                text: config.title,
                style: {
                    display: "none"
                }
            },
            xAxis: xAxisConfig,
            yAxis: yAxisConfig
        };

        var seriesGeneratorFactory = new App.Chart.SeriesGenerators.Prediction.SeriesGeneratorFactory();
        var seriesGeneratorConfig = {
            fieldList: this.model.dataSource.fieldList,
            data: config.data,
            historyResolution:
                this.predictiveType === "realTime"
                    ? config.realTimeHistoryResolution
                    : config.diagnosticsHistoryResolution,
            historySize:
                this.predictiveType === "realTime" ? config.realTimeHistorySize : config.diagnosticsHistorySize,
            predictiveType: this.predictiveType,
            resultField: this.resultField,
            observedTimeField: this.observedTimeField,
            observedValueField: this.observedValueField
        };

        var generator = seriesGeneratorFactory.create(seriesGeneratorConfig);
        this.chartConfig.series = generator.generate();
    },
    shouldWeCreateChart: function() {
        return true; // always redraw the chart since the plotBands should be rolling too... not just the data points.
    },
    createCollectionFromLegendData: function() {
        var collection = new Backbone.Collection();
        // console.log(this.config);
        var firstSeries = this.config.series[0];
        collection.add({
            name: firstSeries.name || "Value",
            color: "#F00"
        });
        collection.add({
            name: "Prediction",
            color: "#a2cf0f"
        });
        collection.add({
            name: "Upper Bound",
            color: "#EC8209"
        });
        collection.add({
            name: "Lower Bound",
            color: "#EC8209"
        });
        return collection;
    },
    generateToolTipConfig: function() {
        var generateToolTipRow = function(label, value) {
            return '<div><span style="font-weight: bold">' + label + ": </span><span>" + value + "</span></div>";
        };
        return {
            enabled: true,
            useHTML: true,
            formatter: function() {
                var content = "";
                //var tooltipConfig = this.point.tooltipConfig;
                content = content + generateToolTipRow("Time", this.point.x);
                content = content + generateToolTipRow("Value", this.point.y);
                return content;
            },
            ...tooltipStriimlineHighcharts
        };
    }
});
