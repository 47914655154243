import React, { useMemo } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Box, Grid } from "@mui/material";
import { StriimLink, StriimTooltip, StriimTypography } from "@striim/striim-ui-v2";

import SentinelTableCommon from "./sentinel-table-common";
import { styles } from "./sentinel-table.styles";
import { commaThousands, nFormatter } from "./utils";
import {
    elementsSensAndNon,
    EmptyBar,
    SentinelTableBarTooltip,
    Colors,
    AllAppsTableTypeRowFields
} from "./sentinel-table-utils";
import WithTooltip from "../../../../../generic/tooltip/tooltip";

export interface AllAppsTableTypeRow {
    [AllAppsTableTypeRowFields.name]: string;
    [AllAppsTableTypeRowFields.namespace]: string;
    [AllAppsTableTypeRowFields.events]: number;
    [AllAppsTableTypeRowFields.sensitiveData]: number;
    [AllAppsTableTypeRowFields.eventsTagged]: number;
    [AllAppsTableTypeRowFields.nonSensitiveData]: number;
}

interface AllAppsTableProps {
    title: string;
    tableData: AllAppsTableTypeRow[];
    filteredTableData: AllAppsTableTypeRow[];
    showProgress?: boolean;
}

export const getHighestAmount = filteredTableData => {
    let highestAmount = 0;

    filteredTableData.forEach(el => {
        let totalData = el.eventsProcessed;

        if (totalData > highestAmount) {
            highestAmount = totalData < 1000 ? Math.ceil(totalData / 50) * 50 : Math.ceil(totalData / 1000) * 1000;
        }
    });

    return highestAmount;
};

const AllAppsTable = ({ title, tableData, filteredTableData, showProgress }: AllAppsTableProps) => {
    const helpers = useMemo(() => {
        return {
            eventsSum: filteredTableData.reduce(
                (partialSum, row) => partialSum + row[AllAppsTableTypeRowFields.events],
                0
            ),
            sensitiveDataSum: filteredTableData.reduce(
                (partialSum, row) => partialSum + row[AllAppsTableTypeRowFields.sensitiveData],
                0
            )
        };
    }, [filteredTableData]);

    const highestAmount = useMemo(() => getHighestAmount(tableData), [tableData]);

    const tableColumns = useMemo(
        () => [
            {
                headerName: "App Name",
                headerClass: "dataTableHeader",
                field: AllAppsTableTypeRowFields.name,
                flex: 0.12,
                suppressMovable: true,
                sortable: true,
                resizable: true,
                cellRenderer: ({ data }) =>
                    WithTooltip(
                        <StriimLink href={`/#/flow/${data.namespace + "." + data.name}`}>
                            <Box component="span" sx={styles.longValueWrap}>
                                {data.name}
                            </Box>
                        </StriimLink>,
                        data.name
                    ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: "Namespace",
                headerClass: "dataTableHeader",
                field: AllAppsTableTypeRowFields.namespace,
                flex: 0.12,
                suppressMovable: true,
                resizable: true,
                sortable: true,
                cellRenderer: ({ value }) => (
                    <StriimTypography color="greyscale.700" variant="body4">
                        {value}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerComponent: () => (
                    <Box sx={styles.customHeader}>
                        <Box display="flex" alignItems="flex-end">
                            <StriimTypography variant="body3" color="greyscale.600" display="inline" mr={0.5}>
                                Events
                            </StriimTypography>
                            <StriimTypography
                                variant="caption3"
                                fontFamily="Inter"
                                color="greyscale.600"
                                display="inline"
                            >
                                ({nFormatter(helpers.eventsSum, 2)})
                            </StriimTypography>
                        </Box>
                    </Box>
                ),
                headerClass: "dataTableHeader",
                field: AllAppsTableTypeRowFields.events,
                suppressMovable: true,
                flex: 0.1,
                cellClass: "dataCellTextEnd",
                initialWidth: 90,
                sortable: true,
                resizable: true,
                cellRenderer: ({ value }) => (
                    <StriimTypography color="greyscale.900" variant="body4">
                        {commaThousands(value)}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerComponent: () => (
                    <Box sx={styles.customHeader}>
                        <Box display="flex" alignItems="center">
                            <StriimTypography variant="body3" color="greyscale.600" display="inline" mr={0.5}>
                                Events Tagged
                            </StriimTypography>
                        </Box>
                    </Box>
                ),
                headerClass: "dataTableHeader",
                field: AllAppsTableTypeRowFields.eventsTagged,
                suppressMovable: true,
                flex: 0.1,
                cellClass: "dataCellTextEnd",
                initialWidth: 90,
                sortable: true,
                resizable: true,
                cellRenderer: ({ value }) => (
                    <StriimTypography color="greyscale.900" variant="body4">
                        {commaThousands(value)}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerClass: "dataTableHeader",
                sortable: true,
                field: AllAppsTableTypeRowFields.sensitiveData,
                suppressMovable: true,
                flex: 0.2,
                resizable: true,
                headerCheckboxSelection: false,
                checkboxSelection: false,
                headerComponent: () => (
                    <Box sx={styles.customHeader2Rows}>
                        <Box sx={styles.sensitiveDataLabelTop}>
                            <span
                                style={{
                                    ...styles.sensitiveDataSquare,
                                    backgroundColor: Colors.sensitiveBarColor,
                                    paddingRight: "11px"
                                }}
                            ></span>
                            <StriimTypography variant="body3" color="greyscale.600" display="inline" mr={0.5}>
                                Events W/ Sensitive Data
                            </StriimTypography>
                        </Box>
                        {highestAmount !== 0 && (
                            <>
                                <Box sx={{ ...styles.sensitiveDataLabelBottom, marginTop: 0 }}>
                                    <StriimTypography color="greyscale.900" variant="caption4">
                                        0
                                    </StriimTypography>
                                    <StriimTypography color="greyscale.900" variant="caption4">
                                        {highestAmount / 2}
                                    </StriimTypography>
                                    <StriimTypography
                                        color="greyscale.900"
                                        variant="caption4"
                                        sx={styles.sensitiveDataLastValue}
                                    >
                                        {highestAmount}
                                    </StriimTypography>
                                </Box>
                                <Box sx={styles.lineSpanWrapper}>
                                    <Box component="span" sx={styles.lineSpan} />
                                    <Box component="span" sx={styles.lineSpan} />
                                    <Box component="span" sx={styles.lineSpan} />
                                </Box>
                            </>
                        )}
                    </Box>
                ),
                cellRenderer: ({ data }) => {
                    if (!(data.eventsWithSensitiveData + data.eventsWithoutSensitiveData > 0)) return <EmptyBar />;

                    return (
                        <StriimTooltip
                            slotProps={{ tooltip: { sx: styles.tooltip } }}
                            title={<SentinelTableBarTooltip data={data} elements={elementsSensAndNon} />}
                        >
                            <Grid container sx={styles.chartWrapper}>
                                <ResponsiveContainer width="100%" height={20}>
                                    <BarChart height={20} data={[data]} layout="vertical">
                                        <XAxis hide type="number" domain={[0, 300]} />
                                        <YAxis hide type="category" />

                                        <Bar
                                            dataKey={AllAppsTableTypeRowFields.sensitiveData}
                                            stackId="single-stack"
                                            animationDuration={0}
                                            fill={Colors.sensitiveBarColor}
                                        />
                                        <Bar
                                            dataKey={AllAppsTableTypeRowFields.nonSensitiveData}
                                            stackId="single-stack"
                                            animationDuration={0}
                                            fill={Colors.NonSensitive}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                                <StriimTypography
                                    variant="caption4"
                                    fontFamily="Inter"
                                    color="greyscale.700"
                                    paddingLeft={0.6}
                                >
                                    {`${data.eventsWithSensitiveData} (${Math.round(
                                        (100 * data.eventsWithSensitiveData) / data.eventsProcessed
                                    )}%)`}
                                </StriimTypography>
                            </Grid>
                        </StriimTooltip>
                    );
                }
            }
        ],
        [tableData, filteredTableData]
    );

    return (
        <SentinelTableCommon
            tableColumns={tableColumns}
            title={title}
            showSentinelsFilter
            tableData={tableData}
            customFilterName="App"
            showProgress={showProgress}
        />
    );
};

export default AllAppsTable;
