import _ from "underscore";
import App from "app";
import propertyTemplateService from "core/services/metaStoreService/property-template-service";

export default {
    getAgentName: function (agentDeploymentGroupID) {
        var servers = propertyTemplateService.servers;
        var DG = propertyTemplateService.getDeploymentGroupById(agentDeploymentGroupID);
        var agentName = null;
        servers.each(function (server) {
            _.each(server.deploymentGroupsIDs, function (deploymentGroup) {
                if (deploymentGroup === DG?.get("uuid")) {
                    agentName = server.get("name");
                }
            });
        });
        return agentName; // we just need one agent in the deployment group.
    },

    /**
     * Get all Deployment Groups where agents are subscribed to.
     * @returns agentDeploymentGroups Array
     * @private
     */
    getDGsForAgents: function () {
        var groupToExclude = "Global.DG.DefaultAgentMonitoring";
        var servers = propertyTemplateService.servers;
        var deploymentGroups = propertyTemplateService.deploymentGroups;
        var deploymentGroupIDs = [];
        servers.each(function (server) {
            if (server.get("isAgent") === true) {
                var agentDeploymentGroups = server.deploymentGroupsIDs;
                _.each(agentDeploymentGroups, function (agentDeploymentGroup) {
                    deploymentGroupIDs.push(agentDeploymentGroup);
                });
            }
        });
        return deploymentGroups.filter(function (dg) {
            if (dg.get("id") === groupToExclude) {
                return false;
            }

            return deploymentGroupIDs.indexOf(dg.get("uuid")) !== -1;
        });
    },
};
