import React from "react";
import Marionette from "marionette";
import ReactDOM from "react-dom/client";

const wrapReactInMarionette = (component, props) => {
    return class extends Marionette.View {
        get className() {
            return "marionette-wrapper-for-react";
        }

        getRoot() {
            if (!this.root) {
                this.root = ReactDOM.createRoot(this.el);
            }
            return this.root;
        }

        onShow() {
            const root = this.getRoot();
            root.render(React.createElement(component, props));
        }

        onDestroy() {
            const root = this.getRoot();
            root.unmount(this.el);
        }
    };
};

const MarionetteWrapper = (component, props) => {
    const WrapperView = wrapReactInMarionette(component, props);
    return new WrapperView();
};

export default MarionetteWrapper;
