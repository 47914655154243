import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Grid, Box, SvgIcon } from "@mui/material";
import { StriimTypography, StriimLink, StriimChip, StriimTooltip } from "@striim/striim-ui-v2";

import SentinelTableCommon from "./sentinel-table-common";
import { ArrowUpward, InfoCircle } from "../../../../../generic/icon/customIcons";
import { styles } from "./sentinel-table.styles";
import { formatNumber, ImportanceLevelIconPaths } from "../../utils";
import { EmptyBar, Colors, SDIDrillDownOccurrencesRowFields, sortTableData } from "./sentinel-table-utils";
import WithTooltip from "../../../../../generic/tooltip/tooltip";
import dictionary from "../../../../../../app/components/common/helpable/online-help-dictionary";
import { updateIdentifiersData } from "../../../guardian/sentinel-monitor/service/sentinel-monitor-utils";
import { SelectedSensitiveDataIdentifier } from "../../sentinel-live-monitor";
import { AppSDIOccurrencesDetailed } from "../../../guardian/sentinel-monitor/service/sentinel-monitor.types";

export interface SDIDrillDownOccurrencesRow {
    [SDIDrillDownOccurrencesRowFields.name]: string;
    [SDIDrillDownOccurrencesRowFields.namespace]: string;
    [SDIDrillDownOccurrencesRowFields.label]: string;
    [SDIDrillDownOccurrencesRowFields.eventsWithIdentifier]: number;
    [SDIDrillDownOccurrencesRowFields.occurrences]: number;
    [SDIDrillDownOccurrencesRowFields.ENCRYPT]: number;
    [SDIDrillDownOccurrencesRowFields.MASK]: number;
    [SDIDrillDownOccurrencesRowFields.NO_ACTION]: number;
    [SDIDrillDownOccurrencesRowFields.sentinelCount]: number;
}

interface SentinelOrAppsTableSDIProps {
    title: String;
    tableData: AppSDIOccurrencesDetailed | SDIDrillDownOccurrencesRow[];
    timeRange: String;
    isSentinelLevel: boolean;
    setSelectedSDI: React.Dispatch<SetStateAction<SelectedSensitiveDataIdentifier | null>>;
    setCurrentTabIndex: React.Dispatch<SetStateAction<number>>;
    hideLastColumn?: boolean;
    showProgress?: boolean;
    isSDIOccurrencesTableForApps?: boolean;
    setSelectedDateRange?: React.Dispatch<SetStateAction<{ from: number; to: number } | null>>;
}

const importanceImage = importance => (
    <img alt="Importance" src={ImportanceLevelIconPaths[importance]} width="16px" height="16px" />
);

export const getPriorityImage = importance => {
    const lowerCaseValue = importance?.toLowerCase();
    switch (lowerCaseValue) {
        case "high":
            return importanceImage("HIGH");
        case "medium":
            return importanceImage("MEDIUM");
        case "low":
            return importanceImage("LOW");
    }
};

const SentinelOrAppsTableSDI = ({
    title,
    tableData,
    timeRange,
    isSentinelLevel,
    setSelectedSDI,
    setCurrentTabIndex,
    hideLastColumn = false,
    showProgress,
    isSDIOccurrencesTableForApps = false,
    setSelectedDateRange
}: SentinelOrAppsTableSDIProps) => {
    const [filteredTableData, setFilteredTableData] = useState(tableData);

    useEffect(() => setFilteredTableData(tableData), [tableData]);
    const identifiersData = useMemo(() => updateIdentifiersData(tableData), [tableData]);
    const updatedFilteredData = useMemo(() => updateIdentifiersData(filteredTableData), [filteredTableData]);
    const [sortOrder, setSortOrder] = useState(true);

    const totalOccurrences = identifiersData.reduce((acc, curr) => {
        return curr.occurrences ? acc + curr.occurrences : acc;
    }, 0);
    const totalEventsWithIdentifier = identifiersData.reduce((acc, curr) => {
        return curr.eventsWithIdentifier ? acc + curr.eventsWithIdentifier : acc;
    }, 0);
    const totalSentinels = identifiersData.reduce((acc, curr) => {
        return curr.sentinelCount ? acc + curr.sentinelCount : acc;
    }, 0);
    const sortedUpdatedFilteredData = sortTableData(updatedFilteredData, sortOrder);

    // update the calculation with getTwoPointPrecision method
    const getTwoPointPrecision = num => (num ? (num % 1 ? num.toFixed(2) : num) : 0);

    const tableColumns = useMemo(
        () => [
            ...(isSDIOccurrencesTableForApps
                ? [
                      {
                          headerName: "App Name",
                          headerClass: "dataTableHeader",
                          field: SDIDrillDownOccurrencesRowFields.name,
                          flex: 0.12,
                          suppressMovable: true,
                          sortable: true,
                          resizable: true,
                          cellRenderer: ({ data }) =>
                              WithTooltip(
                                  <StriimLink href={`/#/flow/${data.namespace + "." + data.name}`}>
                                      <Box component="span" sx={styles.longValueWrap}>
                                          {data.name}
                                      </Box>
                                  </StriimLink>,
                                  data.name
                              ),
                          headerCheckboxSelection: false,
                          checkboxSelection: false
                      }
                  ]
                : []),
            ...(isSDIOccurrencesTableForApps
                ? [
                      {
                          headerName: "Namespace",
                          headerClass: "dataTableHeader",
                          field: SDIDrillDownOccurrencesRowFields.namespace,
                          flex: 0.12,
                          suppressMovable: true,
                          resizable: true,
                          sortable: true,
                          cellRenderer: ({ value }) =>
                              WithTooltip(
                                  <StriimTypography color="greyscale.700" variant="body4" sx={styles.longValueWrap}>
                                      {value}
                                  </StriimTypography>,
                                  value
                              ),
                          headerCheckboxSelection: false,
                          checkboxSelection: false
                      }
                  ]
                : []),
            ...(!isSDIOccurrencesTableForApps
                ? [
                      {
                          headerName: "Sensitive Data Identifier",
                          headerClass: "dataTableHeader",
                          field: SDIDrillDownOccurrencesRowFields.label,
                          initialWidth: 370,
                          flex: 0.3,
                          suppressMovable: true,
                          resizable: true,
                          headerComponent: () => (
                              <Box sx={styles.customHeader}>
                                  <Box display="flex" alignItems="center">
                                      <StriimTypography variant="body3" color="greyscale.600" display="inline" mr={1}>
                                          Sensitive Data Identifier
                                      </StriimTypography>

                                      {WithTooltip(
                                          <SvgIcon component={InfoCircle} sx={styles.infoCircle} />,
                                          <>
                                              List of Sensitive Data Identifiers detected by Sentinel.
                                              <StriimLink
                                                  href={dictionary.get()["AI_INSIGHTS_SHERLOCK"].href}
                                                  target="_blank"
                                                  sx={styles.learnMore}
                                              >
                                                  Learn more
                                              </StriimLink>{" "}
                                              about the supported data identifiers.
                                          </>
                                      )}
                                  </Box>
                              </Box>
                          ),
                          cellRenderer: ({ value, data }) => {
                              const Image = getPriorityImage(data.importance);

                              return WithTooltip(
                                  <StriimTypography
                                      color="greyscale.900"
                                      variant="body4"
                                      sx={{
                                          ...styles.nameRowLabel,
                                          justifyContent: "flex-start" // overwrite default alignment for this column
                                      }}
                                  >
                                      <Box component="span" display="flex" mr={1}>
                                          {Image}
                                      </Box>
                                      <StriimLink
                                          href=""
                                          onClick={() => {
                                              setSelectedSDI({
                                                  name: value,
                                                  importanceLevel: data.importance?.toUpperCase(),
                                                  value: data.value
                                              });
                                              setSelectedDateRange(null);
                                              setCurrentTabIndex(0);
                                          }}
                                      >
                                          <Box component="span" sx={styles.longValueWrap}>
                                              {value}
                                          </Box>
                                      </StriimLink>

                                      {data?.disabled && (
                                          <StriimChip
                                              hideAvatar
                                              type="tag-chip"
                                              variant="default"
                                              label="DETECTION DISABLED"
                                          />
                                      )}
                                  </StriimTypography>,
                                  value
                              );
                          },
                          headerCheckboxSelection: false,
                          checkboxSelection: false
                      }
                  ]
                : []),
            {
                headerName: "Events W/ Identifier",
                headerClass: "dataTableHeader",
                field: "eventsWithIdentifier",
                initialWidth: 350,
                flex: 0.3,
                suppressMovable: true,
                resizable: true,
                headerComponent: ({ value, data }) => (
                    <Box sx={styles.customHeader}>
                        <Box display="flex" alignItems="center">
                            <span
                                style={{
                                    ...styles.sensitiveDataSquare,
                                    backgroundColor: isSDIOccurrencesTableForApps
                                        ? Colors.Identifier
                                        : Colors.sensitiveBarColor,
                                    paddingRight: "11px"
                                }}
                            ></span>
                            <StriimTypography variant="body3" color="greyscale.600" display="inline" mr={1}>
                                Events W/ Identifier
                            </StriimTypography>
                            <StriimTypography
                                variant="caption3"
                                fontFamily="Inter"
                                color="greyscale.600"
                                display="inline"
                            >
                                {`(of ${formatNumber(totalEventsWithIdentifier) || 0})`}
                            </StriimTypography>
                            {!isSDIOccurrencesTableForApps &&
                                WithTooltip(
                                    <SvgIcon component={InfoCircle} sx={styles.infoCircle} />,
                                    <>
                                        These metrics are calculated against the number of events that contain sensitive
                                        data.
                                    </>
                                )}
                            <Box
                                component="span"
                                onClick={() => setSortOrder(prevVal => !prevVal)}
                                sx={sortOrder ? null : styles.downArrow}
                            >
                                <ArrowUpward fontSize="16" />
                            </Box>
                        </Box>
                    </Box>
                ),
                cellRenderer: ({ data }) => {
                    if (!(data.eventsWithIdentifier + totalEventsWithIdentifier > 0)) return <EmptyBar />;

                    return (
                        <Grid container sx={styles.chartWrapper}>
                            <ResponsiveContainer width="100%" height={20}>
                                <BarChart
                                    height={20}
                                    data={[
                                        {
                                            eventsWithIdentifier: data.eventsWithIdentifier,
                                            otherIdentifiersAmount:
                                                totalEventsWithIdentifier - data.eventsWithIdentifier
                                        }
                                    ]}
                                    layout="vertical"
                                >
                                    <XAxis hide type="number" domain={[0, 300]} />
                                    <YAxis hide type="category" />

                                    <Bar
                                        dataKey="eventsWithIdentifier"
                                        stackId="single-stack"
                                        animationDuration={0}
                                        fill={
                                            isSDIOccurrencesTableForApps ? Colors.Identifier : Colors.sensitiveBarColor
                                        }
                                    />
                                    <Bar
                                        dataKey="otherIdentifiersAmount"
                                        stackId="single-stack"
                                        animationDuration={0}
                                        fill={Colors.Rest}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                            <StriimTypography
                                variant="caption4"
                                fontFamily="Inter"
                                color="greyscale.700"
                                paddingLeft={0.6}
                            >
                                {`${data.eventsWithIdentifier} (${Math.round(
                                    (100 * data.eventsWithIdentifier) / totalEventsWithIdentifier
                                )}%)`}
                            </StriimTypography>
                        </Grid>
                    );
                },
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: "Occurrences",
                headerClass: "dataTableHeader",
                field: SDIDrillDownOccurrencesRowFields.occurrences,
                flex: 0.2,
                suppressMovable: true,
                resizable: true,
                sortable: true,
                headerComponent: ({ value, data }) => (
                    <Box sx={styles.customHeader}>
                        <Box display="flex" alignItems="center">
                            <StriimTypography variant="body3" color="greyscale.600" display="inline" mr={1}>
                                Occurrences
                            </StriimTypography>
                            <StriimTypography
                                variant="caption3"
                                fontFamily="Inter"
                                color="greyscale.600"
                                display="inline"
                            >
                                {`(${formatNumber(totalOccurrences)})`}
                            </StriimTypography>

                            {WithTooltip(
                                <SvgIcon component={InfoCircle} sx={styles.infoCircle} />,
                                <>
                                    Count of sensitive data detected across all fields of all events scanned by
                                    Sentinel. An event may contain multiple occurrences of sensitive data. Therefore,
                                    the occurrences of sensitive data detected may exceed the number of events that
                                    contain sensitive data.
                                </>
                            )}
                        </Box>
                    </Box>
                ),
                cellClass: "dataCellTextEnd",
                cellRenderer: ({ value }) => (
                    <StriimTypography color="greyscale.900" variant="body4">
                        {value}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: isSDIOccurrencesTableForApps ? "Actions" : "Actions Taken",
                headerClass: "dataTableHeader",
                flex: 0.3,
                suppressMovable: true,
                resizable: true,
                cellRenderer: ({ data }) => {
                    const masked = isNaN(data.MASK) ? 0 : Number(data.MASK);
                    const encrypted = isNaN(data.ENCRYPT) ? 0 : Number(data.ENCRYPT);
                    const noaction = isNaN(data.NO_ACTION) ? 0 : Number(data.NO_ACTION);
                    const totalAmountActions = masked + encrypted + noaction;

                    const maskedPercentage = getTwoPointPrecision((100 * masked) / totalAmountActions);
                    const encryptedPercentage = getTwoPointPrecision((100 * encrypted) / totalAmountActions);
                    const noactionPercentage = getTwoPointPrecision((100 * noaction) / totalAmountActions);

                    if (!(data.eventsWithIdentifier + data.occurrences > 0) || totalAmountActions === 0)
                        return <EmptyBar />;

                    return (
                        <StriimTooltip
                            slotProps={{ tooltip: { sx: styles.tooltip } }}
                            title={
                                <Box sx={styles.tooltipWrapper}>
                                    <StriimTypography variant="caption4" fontFamily="Inter" sx={styles.tooltipHeading}>
                                        {`LAST ${timeRange?.toUpperCase()}`}
                                    </StriimTypography>

                                    <StriimTypography variant="caption4" fontFamily="Inter" p={1}>
                                        Policy Actions
                                    </StriimTypography>

                                    {!!encrypted && (
                                        <Box sx={styles.tooltipLabel}>
                                            <Box component="span" sx={styles.tooltipLabelTitle}>
                                                <span
                                                    style={{
                                                        ...styles.tooltipSquare,
                                                        backgroundColor: Colors.Encrypted
                                                    }}
                                                ></span>
                                                <StriimTypography
                                                    variant="caption3"
                                                    fontFamily="Inter"
                                                    color="greyscale.700"
                                                >
                                                    Encrypted
                                                </StriimTypography>
                                            </Box>

                                            <Box component="span">
                                                <StriimTypography variant="caption3" fontFamily="Inter" color="#1E1E5C">
                                                    {`${encrypted} (${encryptedPercentage}%)`}
                                                </StriimTypography>
                                            </Box>
                                        </Box>
                                    )}

                                    {!!masked && (
                                        <Box sx={styles.tooltipLabel}>
                                            <Box component="span" sx={styles.tooltipLabelTitle}>
                                                <span
                                                    style={{ ...styles.tooltipSquare, backgroundColor: Colors.Masked }}
                                                ></span>
                                                <StriimTypography
                                                    variant="caption3"
                                                    fontFamily="Inter"
                                                    color="greyscale.700"
                                                >
                                                    Masked
                                                </StriimTypography>
                                            </Box>

                                            <Box component="span">
                                                <StriimTypography variant="caption3" fontFamily="Inter" color="#1E1E5C">
                                                    {`${masked} (${maskedPercentage}%)`}
                                                </StriimTypography>
                                            </Box>
                                        </Box>
                                    )}

                                    {!!noaction && (
                                        <Box sx={styles.tooltipLabel}>
                                            <Box component="span" sx={styles.tooltipLabelTitle}>
                                                <span
                                                    style={{
                                                        ...styles.tooltipSquare,
                                                        backgroundColor: Colors.noActionBarColor
                                                    }}
                                                ></span>
                                                <StriimTypography
                                                    variant="caption3"
                                                    fontFamily="Inter"
                                                    color="greyscale.700"
                                                >
                                                    No Action
                                                </StriimTypography>
                                            </Box>

                                            <Box component="span">
                                                <StriimTypography variant="caption3" fontFamily="Inter" color="#1E1E5C">
                                                    {`${noaction} (${noactionPercentage}%)`}
                                                </StriimTypography>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            }
                        >
                            <Grid container sx={styles.chartWrapper}>
                                <ResponsiveContainer width="100%" height={20}>
                                    <BarChart
                                        height={20}
                                        data={[
                                            {
                                                masked: data.MASK,
                                                encrypted: data.ENCRYPT,
                                                noaction: data.NO_ACTION
                                            }
                                        ]}
                                        layout="vertical"
                                    >
                                        <XAxis hide type="number" domain={[0, "dataMax"]} />
                                        <YAxis hide type="category" />

                                        <Bar
                                            dataKey="encrypted"
                                            stackId="single-stack"
                                            animationDuration={0}
                                            fill={Colors.Encrypted}
                                        />
                                        <Bar
                                            dataKey="masked"
                                            stackId="single-stack"
                                            animationDuration={0}
                                            fill={Colors.Masked}
                                        />
                                        <Bar dataKey="noaction" stackId="single-stack" fill={Colors.noActionBarColor} />
                                    </BarChart>
                                </ResponsiveContainer>
                                <Grid display="inline-flex" gap={0.5}>
                                    <StriimTypography
                                        variant="caption4"
                                        fontFamily="Inter"
                                        color="greyscale.700"
                                        paddingLeft={0.6}
                                    >
                                        {!!encrypted && `E: (${encryptedPercentage}%)`}
                                    </StriimTypography>
                                    <StriimTypography
                                        variant="caption4"
                                        fontFamily="Inter"
                                        color="greyscale.700"
                                        paddingLeft={0.6}
                                    >
                                        {!!masked && `M: (${maskedPercentage}%)`}
                                    </StriimTypography>
                                    <StriimTypography
                                        variant="caption4"
                                        fontFamily="Inter"
                                        color="greyscale.700"
                                        paddingLeft={0.6}
                                    >
                                        {!!noaction && `N: (${noactionPercentage}%)`}
                                    </StriimTypography>
                                </Grid>
                            </Grid>
                        </StriimTooltip>
                    );
                },
                headerCheckboxSelection: false,
                checkboxSelection: false
            },
            {
                headerName: isSentinelLevel ? "# Sentinels" : "#Apps",
                headerClass: "dataTableHeader",
                field: isSentinelLevel
                    ? SDIDrillDownOccurrencesRowFields.sentinelCount
                    : SDIDrillDownOccurrencesRowFields.applications,
                flex: isSentinelLevel ? 0.2 : 0.1,
                initialWidth: isSentinelLevel ? 135 : 90,
                suppressMovable: true,
                resizable: true,
                sortable: true,
                headerComponent: () => (
                    <Box sx={styles.customHeader}>
                        <Box display="flex" alignItems="flex-end">
                            <StriimTypography variant="body3" color="greyscale.600" display="inline" mr={1}>
                                {isSentinelLevel ? (
                                    <>
                                        # Sentinels{" "}
                                        <StriimTypography
                                            variant="caption3"
                                            fontFamily="Inter"
                                            color="greyscale.600"
                                            display="inline"
                                        >
                                            {`(${totalSentinels})`}
                                        </StriimTypography>
                                    </>
                                ) : (
                                    "#Apps"
                                )}
                            </StriimTypography>
                        </Box>
                    </Box>
                ),
                cellClass: "dataCellTextEnd",
                cellRenderer: ({ value }) => (
                    <StriimTypography color="greyscale.900" variant="body4">
                        {value}
                    </StriimTypography>
                ),
                headerCheckboxSelection: false,
                checkboxSelection: false
            }
        ],
        [tableData, filteredTableData, sortOrder]
    );

    if (hideLastColumn) {
        tableColumns.pop();
    }

    return (
        <SentinelTableCommon
            tableColumns={tableColumns}
            title={title}
            tableData={sortedUpdatedFilteredData}
            showActionsFilter
            showImportanceFilter={!isSDIOccurrencesTableForApps && isSentinelLevel}
            showIdentifiersFilter={!isSDIOccurrencesTableForApps && isSentinelLevel}
            showProgress={showProgress}
            maxHeight={210}
        />
    );
};

export default SentinelOrAppsTableSDI;
