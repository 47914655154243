import React, { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import automatedGroupStore from "../automated-app-group-details.store";
import TableProgressModal from "../../../../../../table-progress/table-progress-modal/table-progress-modal";
import statusManagement from "../../../../../../../status-management";

const AutomatedGroupTableProgress: React.FunctionComponent = () => {
    const [isTableProgressVisible, setisTableProgressVisible] = useState<boolean>(false);
    const currentStage = automatedGroupStore.statusInfo?.stageInfo;

    const MemoizedTableProgressModal = useMemo(() => {
        if (isTableProgressVisible) {
            const currentFocusedApp = automatedGroupStore?.currentFocusedApp ?? null;
            const appModel =
                currentFocusedApp == "IL" ? automatedGroupStore.ilAppModel : automatedGroupStore.cdcAppModel;
            if (!appModel) {
                return true;
            }
            return (
                <TableProgressModal
                    appModel={appModel}
                    setTableProgressVisible={setisTableProgressVisible}
                    isTableProgressVisible={isTableProgressVisible}
                />
            );
        }
        return true;
    }, [
        automatedGroupStore?.currentFocusedApp,
        isTableProgressVisible,
        automatedGroupStore.ilAppModel,
        automatedGroupStore.cdcAppModel
    ]);
    if (
        [
            "CREATED",
            "DEPLOYED",
            "SCHEMA_CREATION",
            "SCHEMA_CREATION_STOPPED",
            "SCHEMA_CREATION_TERMINATED",
            "SCHEMA_CREATION_RUNNING",
            "RESET"
        ].includes(currentStage) ||
        statusManagement.isTransientStatus(currentStage) ||
        automatedGroupStore.isAppTransitioning
    ) {
        return true;
    }
    return (
        <React.Fragment>
            {MemoizedTableProgressModal}
            <Box mt={2}>
                <StriimTypography variant="body4">
                    <Box
                        component="span"
                        sx={{ color: "secondary.500", cursor: "pointer" }}
                        onClick={() => {
                            setisTableProgressVisible(true);
                        }}
                    >
                        Click here to view monitoring information{" "}
                    </Box>
                    <Box component="span" sx={{ color: "greyscale.900" }}>
                        for the{" "}
                    </Box>
                    <Box component="span" sx={{ color: "primary.900" }}>
                        {automatedGroupStore.currentFocusedApp} process.
                    </Box>
                </StriimTypography>
            </Box>
        </React.Fragment>
    );
};

export default AutomatedGroupTableProgress;
