export default function ensureRegions(module) {
    if (!module.regions) {
        return;
    }

    Object.keys(module.regions).forEach(region => {
        if (!module[region].el) {
            module[region].el = module.regions[region];
        }
    });
}
