import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import resources from "../system_health_monitor_resources.json";

import { StriimTypography, StriimList, StriimListItem } from "@striim/striim-ui";
import { ListSubheader, Grid, LinearProgress } from "@mui/material";
import formatter from "app/components/monitor/common/valueFormatter";
import MetricCard from "src/modules/homepage/common/metric_card.jsx";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles(() => ({
    dropDownBar: {
        margin: 10,
        backgroundColor: "#D9D9D9",
        minHeight: "8px",
        minWidth: "95px"
    },
    customCardStyles: {
        border: "1px solid #D8E4F0",
        borderRadius: "0px"
    }
}));

const CPUMonitorTile = ({ value, data }) => {
    const v5theme = useTheme();
    const classes = useStyles();

    const getPieChartData = () => [
        { name: "Used", color: getDisplayColor(value), value: value },
        { name: "Free", color: resources.emptyColor, value: 1 - value }
    ];

    const getDisplayText = value => (!value ? "Fetching…" : value === 0 ? "0%" : formatter.toPercentageString(value));

    const getDisplayColor = value =>
        value > 0.9
            ? v5theme.palette.critical.main
            : value < 0.5
            ? v5theme.palette.success.main
            : v5theme.palette.warning.main;

    const getDropDownList = () => {
        return (
            <StriimList
                sx={{ minWidth: "320px" }}
                subheader={<ListSubheader disableSticky>CPU usage per node</ListSubheader>}
            >
                {data.map(nodeData => (
                    <StriimListItem
                        key={nodeData.name + nodeData.cpu}
                        text={
                            <Grid container>
                                <Grid item xs={5}>
                                    <StriimTypography variant="body">{nodeData.name}</StriimTypography>
                                </Grid>
                                <Grid item xs>
                                    <LinearProgress
                                        className={classes.dropDownBar}
                                        variant="determinate"
                                        value={nodeData.cpu * 100}
                                        sx={{
                                            ".MuiLinearProgress-bar": {
                                                backgroundColor:
                                                    nodeData.cpu > 0.9
                                                        ? "critical.main"
                                                        : nodeData.cpu < 0.5
                                                        ? "success.main"
                                                        : "warning.main"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <StriimTypography
                                        display="inline"
                                        style={{
                                            color: getDisplayColor(nodeData.cpu)
                                        }}
                                        variant="bodySemibold"
                                    >
                                        {getDisplayText(nodeData.cpu)}
                                    </StriimTypography>
                                </Grid>
                            </Grid>
                        }
                    />
                ))}
            </StriimList>
        );
    };

    return (
        <MetricCard
            title="CPU Use"
            pieChartData={getPieChartData()}
            displayText={getDisplayText(value)}
            displayColor={getDisplayColor(value)}
            dropdownList={getDropDownList()}
            customCardStyles={classes.customCardStyles}
        />
    );
};

export default CPUMonitorTile;

CPUMonitorTile.propTypes = {
    value: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired
};
