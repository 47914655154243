import api from "core/api/api";
import _ from "underscore";
import utils from "core/utils";
import { FlowStatus } from "src/status-management";
import formatter from "app/components/monitor/common/valueFormatter";
import isBackpressured from "app/components/flow/designer/is-backpressured";

var RateCounter = function () {
    var _this = this;

    this.model = null;

    var interval = 5000;
    var intervalId = null;

    this.start = function () {
        refreshStatus();
        intervalId = setInterval(refreshStatus, interval);
    };

    this.stop = function () {
        if (intervalId) {
            clearInterval(intervalId);
        }
    };

    function refreshStatus() {
        if (_this.model.app.flowStatus !== FlowStatus.RUNNING) {
            return;
        }
        api.getMonitoringStatsForAppWithoutTableInfo(_this.model.app.id).then(function (stats) {
            //console.log(stats);
            show_component_metrics(stats);
        });
    }

    function show_component_metrics(objects) {
        //var _this = this;

        if (!_.isEmpty(objects)) {
            _this.poll_stats_count = 0;
        } else {
            console.warn("No stats found in response");
            console.log(objects);
        }
        // Go through each Waction and display metric next to appropriate component
        _(objects).each(
            function (data /*, key*/) {
                //console.log('Component Data:', data);
                if (!data["most-recent-data"]) {
                    return false;
                }

                var appName = data.applications;
                if (appName !== _this.model.current_app_full) {
                    return;
                }

                var id = data["full-name"];
                /*

                            var in_app = this.components.find(function(model) {
                                var id = model.get('id');
                                return model.get('app') == _this.app && model.get('id') == id;
                            });
            */

                var graphNode = _this.model.findNodeByFullName(id);

                if (!graphNode) {
                    return;
                }
                var metric = formatter.formatRate(data["most-recent-data"].rate); // rate

                graphNode.recentDataRate = metric;

                if (graphNode.metaObject.type === utils.ENTITIES.CACHE) {
                    graphNode.additionalStatisticsInfo = "Size: " + data["most-recent-data"]["cache-size"];
                }

                graphNode.streamFull = isBackpressured(data["most-recent-data"]["stream-full"]);
            }.bind(_this)
        );
    }
};

export default RateCounter;
