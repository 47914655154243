import React from "react";
import { Grid } from "@mui/material";
import { CreateAppNameAppForm, CreateAppNameAppPageReview } from "./components";
import { styles } from "./create-app-name-app-page-styles";
import { AppTypeInfoProps } from "./components/create-app-name-app-page-form";

interface PropTypes {
    createAppStore: object;
    currentAppTypeInfo: AppTypeInfoProps;
    currentAdapter: string;
    handleSubmit: () => void;
    handleCancel: () => void;
    trackEventClicked: () => void;
}

const CreateAppNameAppPageTemplates: React.FunctionComponent<PropTypes> = ({
    createAppStore,
    currentAppTypeInfo,
    currentAdapter,
    handleSubmit,
    handleCancel,
    trackEventClicked
}) => {
    return (
        <Grid container height={"95vh"} mt={6} sx={{ overflow: "hidden" }}>
            <Grid container item xs={5} sx={styles.leftContainer} gap={8}>
                <CreateAppNameAppForm
                    createAppStore={createAppStore}
                    currentAppTypeInfo={currentAppTypeInfo}
                    currentAdapter={currentAdapter}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                    trackEventClicked={trackEventClicked}
                />
            </Grid>
            <Grid container item xs={7} sx={styles.logoContainer} gap={2}>
                <CreateAppNameAppPageReview currentAppTypeInfo={currentAppTypeInfo} />
            </Grid>
        </Grid>
    );
};

export default CreateAppNameAppPageTemplates;
