import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";

// Manages modals on a page (technically they must be Dialogs)
export default Backbone.Marionette.RegionManager.extend({
    _modals: {},

    additionalBackgroundCssClass: "",
    additionalContainerClass: "",

    initialize: function(opts) {
        this.options = opts;
        _(opts).defaults({
            container: "body"
        });
        this.container = opts.container;
        this.on("remove:region", function(name, region) {
            region.$el.remove();
        });
    },
    add: function(view, options) {
        options || (options = {});
        var region;
        var region_id = "modal-region_" + _.uniqueId();

        if (this.get(region_id)) {
            console.warn("A modal in this " + region_id + " is already opened.");
            return this._modals[region_id];
        }

        // Add a new region for the modal
        var containerClass = "modal-region";
        if (this.additionalContainerClass) {
            containerClass += " " + this.additionalContainerClass;
        }
        var $region = $("<div/>", {
            el: "modal-region",
            class: containerClass,
            id: region_id
        }).appendTo(this.container);
        region = this.addRegion(region_id, "#" + region_id);

        // Closes the Modal on clicking anywhere outside the modal
        $region.on("click", event => {
            // When click table sort SVG icon, className can be object of {baseVal, animVal}
            if (event.target.className.includes?.("modal-region")) this.remove_all_modals();
        });
        // Show the background
        if (options.background !== false) {
            this._show_bg();
        }

        // Create the modal in this region
        this._modals[region_id] = view;
        view.options.freeze = this.options.freeze;
        region.show(view);

        // Blur the previous modal, and set current modal as current
        this._blur(this.current_modal);
        this._modals[region_id]._prev = this.current_modal;
        this._focus(region_id);

        // When this modal is destroyed, register this modal as removed and clean up
        this.listenTo(view, "destroy", function() {
            this.remove_modal(region_id);
        });

        this.trigger("modal:add", view);

        return this._modals[region_id];
    },
    // Remove the modal
    // If there are no other modals, remove the background tint and unfreeze any bg nodes
    remove_modal: function(region_id) {
        var modalview = this._modals[region_id];

        var finish = function() {
            this.trigger("modal:remove", modalview);
            this.current_modal = null;
            var prev_modal = this._modals[region_id]._prev;

            // If there is another modal under this one, focus that modal
            if (prev_modal && this._modals[prev_modal]) {
                this._focus(prev_modal);
                this._trigger_removed();
            } else {
                // Otherwise, remove background
                this._cleanup();
            }

            this.removeRegion(region_id);
            delete this._modals[region_id];
            this.stopListening(modalview);
        }.bind(this);

        // If the modal has not yet been destroyed by other parts of the app, destroy it now
        if (!modalview.isDestroyed) {
            this.listenTo(modalview, "destroy", finish);
            modalview.destroy();
        }
        // Otherwise wrap up immediately
        else {
            finish();
        }
    },
    remove_all_modals: function() {
        //close all open modals from the current view
        _.each(
            this._modals,
            function(modal) {
                modal.destroy(true);
            },
            this
        );
    },
    // Hide the bg and unfreeze any frozen bg DOM nodes
    // Can be cancelled if another modal opens immediately after this one closes
    _cleanup: function() {
        var _this = this;

        this.bg_ready = false;

        // We need to unfreeze even if another modal will open after to prevent scrollbar jumping around
        this._unfreeze();

        this._finish_timeout = setTimeout(
            function() {
                if (this._opening) {
                    console.warn("Canceling bg fadeout");
                    this._opening = false;
                    return;
                }
                this._hide_bg().then(function() {
                    _this._trigger_removed("remove");
                });
            }.bind(this),
            10
        );
    },
    _trigger_removed: function() {
        this.trigger("remove");
    },
    _cancel_cleanup: function() {
        clearTimeout(this._finish_timeout);
    },
    _show_bg: function() {
        var _this = this;

        if (this.bg_ready) {
            return;
        }

        this._opening = true;

        // Freeze
        this._freeze();

        // Stop bg fadeout
        this._cancel_cleanup();

        if (!this.bg_exists) {
            var cssClass = "modal-bg";
            if (this.additionalBackgroundCssClass) {
                cssClass += " " + this.additionalBackgroundCssClass;
            }

            var id = Math.random().toString();
            $(`<div id='${id}'/>`, {
                class: cssClass
            }).appendTo("body");
            this.addRegion("modal-background", "#" + id);
            this.bg_exists = true;
        }

        this.get("modal-background")
            .$el.css({
                display: "block",
                opacity: 0
            })
            .animate(
                {
                    opacity: 1
                },
                this.speed,
                function() {
                    _this.bg_ready = true;
                    _this._opening = false;
                }
            );
    },
    _hide_bg: function() {
        var _this = this;
        var deferred = new $.Deferred();

        var bg = this.get("modal-background");
        if (bg) {
            bg.$el.fadeOut(this.speed, function() {
                _this.removeRegion("modal-background");
                _this.bg_exists = false;
                deferred.resolve(_this);
            });
        } else {
            this.bg_exists = false;
            deferred.resolve(this);
        }
        return deferred.promise();
    },
    // "Freeze" scrolling on the background content.
    // This does not work on all elements. TODO: standardize app regions so there is a clear region that can be frozen on every page
    _freeze: function() {
        if (!this.options.freeze) {
            return;
        }
        var scroll_top = $(document).scrollTop();
        this.$freeze = $(this.options.freeze);

        this.$freeze.css({
            //'margin-top': this.$freeze.offset().top,
            width: this.$freeze.width(),
            position: "fixed",
            transform: "translate(0px, " + scroll_top * -1 + "px)"
        });
        this.$freeze.addClass("js-frozen").data("freeze-scroll", scroll_top);
        window.scrollTo(0, 0);
    },
    _unfreeze: function() {
        if (!this.$freeze || this.$freeze.data("freeze-scroll") === undefined) {
            return;
        }
        var scroll_top = parseInt(this.$freeze.data("freeze-scroll"));
        this.$freeze
            .removeClass("js-frozen")
            .removeData("freeze-scroll")
            .css({
                "margin-top": "",
                width: "", // css('width') does not return width as defined.
                position: "",
                transform: ""
            }); // Restore the original CSS
        window.scrollTo(0, scroll_top);
    },
    _blur: function(region_id) {
        if (!this._modals[region_id]) {
            return;
        }
        var modalview = this._modals[region_id];
        if (modalview) {
            modalview.blur();
        }
    },
    _unblur: function(region_id) {
        if (!this._modals[region_id]) {
            return;
        }
        var modalview = this._modals[region_id];
        if (modalview) {
            modalview.unblur();
        }
    },
    _focus: function(region_id) {
        this.current_modal = region_id;
        this._unblur(region_id);
    }
});
