import App from "app";
import api from "core/api/api";
import _ from "underscore";
import UIControl from "app/components/common/editor/control/ui-control";
import BaseEditor from "app/components/common/editor/meta-object-editors/base";
import StreamDataSource from "app/components/common/editor/control/select/transformation-inputstream-datasource";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import metaObjectHelper from "app/components/common/editor/meta-object-editors/metaObjectHelper";
import TqlBuilder from "app/components/common/tqlBuilder";
import cqTransformationProvider from "app/components/common/editor/meta-object-editors/cqTransformations/transformationProvider";
import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames";
import predefinedTransformationComponents from "app/components/flow/designer/predefinedComponents/predefinedTransformationComponents";
import dialog from "app/components/common/dialogs/dialogWindow";
import { filterDbSources, loadAscendantsSources } from "app/components/flow/designer/component-acendants-loader";

export interface ITransformationConfiguration {
    convertToCQ: () => void;
    OnlyDbSources?: boolean;
    allowChangeSource?: boolean;
    createColumnControl?: () => void;
    buildTql?: (tqlBuilder: any, columns: any[], options: any) => void;
    postProcessing?: (cqControl: any, options: any, metadata_object: any) => void;
    createRqlBuilder?: (stream: string, columns: any[], options: any, transformation: any) => any;
}

function getSubtypeName(metaObjectModel) {
    if (metaObjectModel.uiconfig && metaObjectModel.uiconfig.subType) {
        return metaObjectModel.uiconfig.subType;
    }

    return "";
}

var Module = function(metadata_object, options) {
    options = options || {};
    this._options = options;
    options.displayFields = options.displayFields || ["select", "lb1", "output"];
    this.transformation = cqTransformationProvider.getTransformationByName(getSubtypeName(metadata_object));

    if (this.transformation) {
        options.displayFields.unshift("convertToCQ");
        options.displayFields.unshift("columns");
        if (metadata_object.uiconfig.subType === PredefinedComponentNames.TransformationFieldAdder.name) {
            options.displayFields.unshift("header");
            options.displayFields.unshift("originalFields");
            options.displayFields.unshift("originalFieldsHeader");
        }

        options.displayFields.unshift("input");
        const isWAE = predefinedTransformationComponents.isWAEventTypeTransformationRelated(
            metadata_object.uiconfig.subType
        );
        const streams = new StreamDataSource(isWAE);
        this.input = UIControl.Select(streams).create();
        if (metadata_object.uiconfig.config && metadata_object.uiconfig.config.stream) {
            this.input.setValue(metadata_object.uiconfig.config.stream);
        }

        this.originalFieldsHeader = UIControl.Header("Existing fields").create();

        this.originalFields = UIControl.TypeViewer.extend({
            fullWidth: true,
            collapsed: true,
            hideLabel: true
        }).create();

        this.header = UIControl.Header("New fields").create();

        this.input.on(
            UIControl.EVENT.VALUE_CHANGED,
            function(value) {
                this.input.clearError();
                this.columns.setValue([]);

                if (!value) {
                    var columnsPickerRow = this.getControlRow(this.columns);
                    columnsPickerRow.hide();
                }

                loadAscendantsSources(value).then(sources => {
                    if (this.transformation.OnlyDbSources) {
                        let dbSources = filterDbSources(sources);

                        if (dbSources.length === 0) {
                            this.input.showError("Component supports only DB sources.");
                            this.toggleColumnsList(null);
                            return;
                        } else if (sources.length > 1) {
                            this.input.showError("Only one source is supported.");
                            this.toggleColumnsList(null);
                            return;
                        }
                    }

                    metadata_object.uiconfig.config.stream = value;
                    this.toggleColumnsList(value);
                    if (this.columns.clearColumnsOnStreamChanged) {
                        this.columns.clearColumnsDataSource();
                    }
                    this.fieldMapper.select.setValue("");
                    this.originalFields.viewTypeOf(value);
                });
            }.bind(this)
        );

        this.convertToCQ = UIControl.Button("Convert to CQ").create();
        this.convertToCQ.on(
            "clicked",
            function() {
                const message = "Are you sure you want to convert to CQ? This is a one-way process.";
                dialog.confirm(message, App.Dashboards.ModalManager).then(result => {
                    if (result) {
                        try {
                            this.Form.model.isDirty = true;
                            metadata_object.uiconfig = null;
                            this.getControlRow(this.input).hide();
                            this.getControlRow(this.columns).hide();
                            this.getControlRow(this.originalFieldsHeader).hide();
                            this.getControlRow(this.originalFields).hide();
                            this.getControlRow(this.header).hide();
                            this.getControlRow(this.convertToCQ).hide();

                            this.transformation?.convertToCQ?.apply(this);

                            this.fieldMapper.select.readOnly = false;
                            this.fieldMapper.select.setViewEnabled();
                            this.options.StaticControls.title.setType(metaStoreService.entities.CQ, "cq");
                        } catch (e) {
                            console.log(e);
                        }
                    }
                });
            }.bind(this)
        );

        this.columns = this.transformation.createColumnControl();
        this.columns.setValue(metadata_object.uiconfig.config.columns);
        this.columns.on(
            UIControl.EVENT.VALUE_CHANGED,
            function(value) {
                metadata_object.uiconfig.config.columns = value;
                this.refreshTql(value);
            }.bind(this)
        );

        this.columns.on(
            "show-error-msg",
            function(message) {
                this.input.showError(message);
                this.toggleColumnsList(null);
            }.bind(this)
        );

        options.StaticControls = {
            input: this.input,
            originalFieldsHeader: this.originalFieldsHeader,
            originalFields: this.originalFields,
            header: this.header,
            columns: this.columns,
            convertToCQ: this.convertToCQ
        };
        try {
            if (this?.transformation?.postProcessing) {
                this.transformation.postProcessing(this, options, metadata_object);
            }
        } catch (e) {
            console.log(e);
        }
    }

    BaseEditor.apply(this, arguments);
};

Module.prototype = _.extend({}, BaseEditor.prototype, {
    getControlRow: function(control) {
        return control.$el.closest(".field-row");
    },

    toggleColumnsList: async function(value) {
        var columnsPickerRow = this.getControlRow(this.columns);
        if (!value) {
            columnsPickerRow.hide();
        } else {
            columnsPickerRow.show();
            await this.columns.setStream(value);
            if (this.transformation.allowChangeSource !== true) {
                this.input.setEnabled(false);
            }
        }
    },

    addCustomFields: function() {
        this.fieldMapper.select = UIControl.Tql.extend({
            hideLabel: true,
            readOnly: this.transformation !== null
        }).create();
        this.fieldMapper.output = UIControl.StreamSelector.extend({
            typesToShow: [metaStoreService.entities.STREAM, metaStoreService.entities.WACTIONSTORE]
        }).create();
        this.options.StaticControls.lb1 = new UIControl.LineBreak().create();
    },

    postRender: function() {
        if (this.transformation) {
            this.toggleColumnsList(this.input.getValue());
            if (_.isString(this.input.getValue()) && !_.isEmpty(this.input.getValue())) {
                metaStoreService
                    .findById(this.input.getValue())
                    .then(() => {
                        this.originalFields.viewTypeOf(this.input.getValue()).always(() => {
                            this.Form.view.clearDirty(this.originalFields.model);
                        });
                    })
                    .catch((err: Error) => {
                        console.error(err);
                    });
            }
        }
    },

    refreshTql: function(columns?: any[]) {
        const value = columns || this.columns.getValue();
        if (
            !value ||
            value.length === 0 ||
            !_.some(value, function(c) {
                return !!c.column;
            })
        ) {
            this.fieldMapper.select.setValue("");
            return;
        }

        const selectedStreamId = this.input.model.value;
        const stream =
            api.getCurrentNamespace() === metaObjectConverter.getNamespace(selectedStreamId)
                ? metaObjectConverter.getName(selectedStreamId)
                : metaObjectConverter.convertFullNameToShortName(selectedStreamId);
        var tqlBuilder = this.transformation.createRqlBuilder
            ? this.transformation.createRqlBuilder(stream, value, this._options, this.transformation)
            : new TqlBuilder().withStream(stream);

        this.transformation.buildTql(tqlBuilder, value, this._options);

        let tql = tqlBuilder.build();
        this.fieldMapper.select.setValue(tql);
    }
});

export default Module;
