export const styles = {
    signInStates: {
        color: "greyscale.900",
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px"
    }
};
