import _ from "underscore";
import Backbone from "backbone";
import App from "app";
import api from "core/api/api";
import statusManagement, { FlowStatus } from "src/status-management";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import ignoredPatternsFilter from "app/components/common/ignoredPatternsFilter";
import headerStore from "../src/stores/header";
var lastState = {};

var initializeData = function(_module) {
    api.getAllApplicationStatuses().then(function(data) {
        lastState = data;
        _.each(data, function(status, appID) {
            _module.trigger(appID, status); // just to make sure if this api is slower than page load, we trigger it.
        });
        headerStore.evictAndUpdateApps(lastState);
    });
};

var listenToFutureData = function(_module) {
    App.vent.on("api:status_change", function(d) {
        _module.trigger("all-applications", d);

        _.each(d.filter(ignoredPatternsFilter), function(statusObject) {
            function updateStatus(appId, newStatus) {
                console.log("Status updated: " + appId + "-" + newStatus);
                if (lastState[appId] !== newStatus) {
                    // do not trigger if app is already in that state
                    lastState[appId] = newStatus;

                    module.trigger(appId, newStatus);
                    module.trigger("status_change", {
                        appId: appId,
                        newStatus: newStatus,
                        isTranscientStatus: statusManagement.isTransientStatus(newStatus)
                    });
                }
            }
            function getLatestStatus() {
                return metaStoreService.findById(statusObject.id).then(function(app) {
                    if (!app) {
                        return;
                    }
                    var currentStatus = app.metaInfoStatus.isValid ? app.flowStatus : FlowStatus.INVALID;
                    if (lastState[app.id] !== currentStatus) {
                        updateStatus(app.id, currentStatus);
                        return true;
                    }
                    return false;
                });
            }
            if (statusManagement.isTransientStatus(statusObject.currentStatus)) {
                console.log("Transcient status: " + statusObject.id + "-" + statusObject.currentStatus);
                // when status is transient, then application can't be in invalid state.
                // we don't want to wait for "metaStoreService.findById" end, but immediately retur the status
                updateStatus(statusObject.id, statusObject.currentStatus);
            } else {
                getLatestStatus().then(result => {
                    if (!result) {
                        setTimeout(getLatestStatus, 2000);
                    }
                });
            }
        });
    });
};

var module = {
    start: function() {
        App.on("app:authenticated", function() {
            initializeData(module);
            listenToFutureData(module);
        });
    },
    getStatus: function(fullAppName) {
        return lastState[fullAppName] || FlowStatus.UNKNOWN;
    },
    isTransientStatus: function(fullAppName) {
        return statusManagement.isTransientStatus(lastState[fullAppName]);
    }
};

export default _.extend(module, Backbone.Events);
