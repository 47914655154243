import React from "react";
import Grid from "@mui/material/Grid";

import { StriimButton } from "@striim/striim-ui";
import { styles } from "./connection-profile-footer.styles";
import { useNavigate } from "react-router";

interface ConnectionProfileFooterProps {
    cancelDisabled?: boolean;
    onCancel?: () => void;
    saveDisabled?: boolean;
    onSave?: () => void;
}

const ConnectionProfileFooter = ({
    cancelDisabled = false,
    onCancel,
    saveDisabled = false,
    onSave
}: ConnectionProfileFooterProps) => {
    const navigate = useNavigate();

    const handleTestClick = () => {};
    const handleCancelClick = () => {
        onCancel();
    };

    const handleSaveClick = () => {
        onSave && onSave();
    };

    return (
        <Grid display="flex" justifyContent="flex-end" alignItems="center" sx={styles.container}>
            <StriimButton
                variant="text"
                disabled={cancelDisabled}
                onClick={handleCancelClick}
                sx={styles.cancelButton}
                data-test-id="cancel-connection-profile"
            >
                Cancel
            </StriimButton>
            <StriimButton
                variant="primary"
                disabled={saveDisabled}
                onClick={handleSaveClick}
                data-test-id="add-connection-profile-save-button"
            >
                Save
            </StriimButton>
        </Grid>
    );
};

export default ConnectionProfileFooter;
