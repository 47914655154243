import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import Dashboard from "../../../app/components/dashboard/dashboard";
import securityService from "../../../core/services/securityService/securityService";

export default function EmbedDashboard() {
    // @ts-ignore
    const { id, page, token } = useParams();
    const currentIdRef = useRef<string | undefined>();
    const currentPageRef = useRef<string | undefined>();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        let tokenValue = atob(token); // take in the token only when embedding dashboards.
        securityService.loginDashboard(tokenValue).then(() => {
            setIsAuthenticated(true);
        });
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        if (currentIdRef.current === id && currentPageRef.current === page) {
            return;
        }

        Dashboard.controller.begin_show_dashboard(id, page, false);
        currentIdRef.current = id;
        currentPageRef.current = page;
    }, [isAuthenticated, id, page, token]);

    if (!isAuthenticated) {
        return null;
    }

    return <div></div>;
}
