import api from "core/api/api";
import App from "app";
import headerStore from "../src/stores/header";

function initialize() {
    api.read(undefined, { type: "DASHBOARD" }).then(result => {
        var dashboards = result.filter(dashboard => !dashboard.isPrebuilt);
        const dashboardsMap = {};
        dashboards.map(x => (dashboardsMap[x.id] = x));
        headerStore.evictAndUpdateDashboards(dashboardsMap);
    });
}

export default {
    start: function() {
        App.on("app:authenticated", function() {
            initialize();
        });
    }
};
