import React from "react";

interface MessageProps {
    text: string;
}

export default function Message({ text }: MessageProps) {
    return (
        <div style={{ margin: 100, textAlign: "center" }}>
            <h3>{text}</h3>
        </div>
    );
}
