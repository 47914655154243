import Marionette from "marionette";
import NestedTypes from "backbone.nestedtypes";
import _ from "underscore";
import DeploymentPlan from "./deployment-plan/deployment-plan";
import template from "./deployment-plan-form.html";

const DeploymentPlanList = {};

DeploymentPlanList.Model = NestedTypes.Model.extend({
    defaults: {
        value: Array,
    },

    clear: function () {
        this.items = this.defaults().items;
        this.trigger("clear");
    },
});

DeploymentPlanList.View = Marionette.LayoutView.extend({
    template: _.template(template),
    className: "deployment-plan-list-form",
    ui: {
        list: ".deployment-plan-list",
    },

    modelEvents: {
        clear: "clear",
    },

    refresh: function () {
        this.model.value = _.map(this.childViews, function (childView) {
            return childView.getValue();
        });
    },

    initialize: function () {
        this.childViews = [];
    },

    clear: function () {
        _.each(this.childViews, function (childView) {
            childView.remove();
        });
        this.childViews = [];
    },
    createNewItem: function (initValue) {
        var _this = this;
        var newItemView = DeploymentPlan().create();
        newItemView.setEnabled(_this.enabled);
        newItemView.model.on("change", function () {
            _this.refresh();
        });

        newItemView.model.value = initValue;

        _this.childViews.push(newItemView);
        var rendered = newItemView.render().$el;
        rendered.addClass("lineItem");
        _this.ui.list.append(rendered);

        return rendered;
    },

    onRender: function () {
        var _this = this;
        _.each(_this.model.value, function (modelValue) {
            _this.createNewItem(modelValue);
            _this.refresh();
        });
    },
    setEnabled: function (enabled) {
        this.enabled = enabled;
    },
});

export default DeploymentPlanList;
