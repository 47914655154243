import { className, regions, template } from "core/decorators";
import { LayoutView, ItemView, CollectionView, CompositeView } from "marionette";
import ReactDOM from "react-dom/client";
import React from "react";
import CosmosDBTarget from "../../../../../../../src/modules/wizards/target/cosmosdb/cosmosdb-target/cosmosdb-target";
import CosmosdbTargetStore from "../../../../../../../src/modules/wizards/target/cosmosdb/cosmosdb-target/cosmosdb-target-store";
import App from "../../../../../../../app";
import ValidateConnectionStore from "../../../../../../../src/modules/wizards/target/cosmosdb/common/validate-connection/validate-connection-store";

/**
 * Main Alert layout with the left and right sidebars.
 */
@template("")
@className("cosmos-wizard-container")
@regions({
    leftSidebar: ".left-side-bar",
    content: ".content"
})
class CosmosDBTargetWizardView extends LayoutView {
    constructor(options) {
        super();
        this.options = options;
        this.store = new CosmosdbTargetStore(options.appName, options.templateID);
        this.validateCxnStore = new ValidateConnectionStore();
        this.on("do:next", () => {
            this.store.goToNextStep();
        });
        this.on("do:back", () => {
            this.store.goToPreviousStep();
        });
        App.vent.trigger("appwizard:back:disable");
    }
    onShow() {
        const root = ReactDOM.createRoot(this.$el[0]);
        root.render(<CosmosDBTarget store={this.store} validateCxnStore={this.validateCxnStore} />);
    }
}

export default {
    View: CosmosDBTargetWizardView
};
