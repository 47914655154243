import React, { useEffect, useState } from "react";
import { FIELD_TYPES, StriimTypography } from "@striim/striim-ui";
import { Grid } from "@mui/material";

import { Card } from "../components";
import growl from "app/components/common/growl";
import EmbeddingGeneratorEmpty from "./embedding-generator-empty";
import LoadingIndicator from "../../../../../generic/loading-indicator";
import EmbeddingGeneratorDetailsModal from "./embedding-generator-details-modal";
import EmbeddingGeneratorContent from "./embedding-generator-content";
import { getEmptyDetails, default as EmbeddingGeneratorService } from "./embedding-generator-service";
import Embeddinggenerator from "core/services/metaStoreService/metaObjects/embeddinggenerator";
import propertyTemplateService from "../../../../../../core/services/metaStoreService/property-template-service";
import { APIKEY_TYPE, OPENAI_ID } from "./embedding-generator-details-form";
import { useReactHeader } from "../../../../../hooks/useReactHeader";

const styles = {
    gridContainer: {
        padding: 3,
        gap: 1.5,
        flexWrap: "nowrap",
        top: 48,
        position: "relative",
        bottom: 0,
        height: "calc(100vh - 48px)"
    }
};

interface EmbeddingGeneratorPageProps {
    growlNotification?: any;
}

export const EmbeddingGeneratorWrapper = () => {
    useReactHeader({ title: "Embeddings Generator" });

    return (
        <Grid container sx={styles.gridContainer} direction="column">
            <Grid item xs>
                <StriimTypography variant="h1">Embeddings Generator</StriimTypography>
            </Grid>
            <Grid xs={12} item>
                <EmbeddingGeneratorPage />
            </Grid>
        </Grid>
    );
};

export default function EmbeddingGeneratorPage({ growlNotification }: EmbeddingGeneratorPageProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [embeddingGeneratorList, setEmbeddingGeneratorList] = useState<Embeddinggenerator[]>([]);
    const [initialValues, setInitialValues] = useState<any>(getEmptyDetails());

    if (!growlNotification) {
        growlNotification = growl;
    }

    const reload = () => {
        setIsLoading(true);
        EmbeddingGeneratorService.load().then(data => {
            setIsLoading(false);
            setEmbeddingGeneratorList(data);
        });
    };

    useEffect(() => {
        reload();
    }, []);

    const addPasswordEncryptedProperty = formValues => {
        if (formValues?.modelProvider === "OpenAI") {
            const properties = formValues.get("properties");
            propertyTemplateService.getPropertyTemplateById(OPENAI_ID)?.propertyMap.forEach(attribute => {
                if (attribute.type === APIKEY_TYPE || attribute.type === FIELD_TYPES.PROPERTYTEMPLATE_PASSWORD) {
                    let isEncrypted: boolean;
                    const value = properties[attribute.name];
                    const type = typeof value;
                    let encryptedPropertyName = `${attribute.name}_encrypted`;
                    if (type === "object") {
                        isEncrypted = true;
                    } else if (type === "string") {
                        isEncrypted = false;
                    }
                    properties[encryptedPropertyName] = isEncrypted;
                }
            });
            formValues.set("properties", properties);
        }
        return formValues;
    };

    const saveEmbeddingGenerator = (formValues: any) => {
        let promise: Promise<string>;
        formValues = addPasswordEncryptedProperty(formValues);
        if (formValues?.id) {
            promise = EmbeddingGeneratorService.update(formValues.id, formValues);
        } else {
            promise = EmbeddingGeneratorService.add(formValues);
        }
        promise
            .then(() => {
                reload();
                growlNotification.success(`Vector Embeddings Generator saved successfully.`);
            })
            .catch(e => {
                growlNotification.error("Error saving vector embeddings generator: " + e);
            })
            .finally(() => {
                setShowDialog(false);
            });
    };

    if (isLoading) {
        return (
            <Card height="calc(100% - 88px)" overflow="auto" useFullWidth>
                <LoadingIndicator isGlobal={false} />
            </Card>
        );
    }

    const onAdd = () => {
        setInitialValues(getEmptyDetails());
        setShowDialog(true);
    };

    const onEdit = async (embeddingGenerator: any) => {
        const generator = await EmbeddingGeneratorService.loadById(embeddingGenerator.id);
        setInitialValues(generator);
        setShowDialog(true);
    };

    return (
        <>
            <Card height="calc(100% - 88px)" overflow="auto" useFullWidth>
                {embeddingGeneratorList?.length === 0 && <EmbeddingGeneratorEmpty onAdded={onAdd} />}
                {embeddingGeneratorList?.length > 0 && (
                    <EmbeddingGeneratorContent
                        list={embeddingGeneratorList}
                        onAdd={onAdd}
                        onEdit={onEdit}
                        onDelete={async embeddingGenerator => {
                            await EmbeddingGeneratorService.deleteEmbeddingGenerator(embeddingGenerator.get("id"));
                            const index = embeddingGeneratorList.findIndex(x => x.id === embeddingGenerator.get("id"));
                            embeddingGeneratorList.splice(index, 1);
                            setEmbeddingGeneratorList([...embeddingGeneratorList]);
                            growlNotification.success("Vector Embeddings Generator removed successfully");
                        }}
                    />
                )}
            </Card>
            <EmbeddingGeneratorDetailsModal
                open={showDialog}
                onCancel={() => setShowDialog(false)}
                onSave={saveEmbeddingGenerator}
                initialValues={initialValues}
            />
        </>
    );
}
