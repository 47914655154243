import $ from "jquery";
import _ from "underscore";
import api from "core/api/api";
import metaObjects from "core/services/metaStoreService/meta-objects";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import Utils from "core/utils";
import simpleSourceTql from "text-loader!./tqlTemplates/simpleSource.tql";
import advancedSourceTql from "text-loader!./tqlTemplates/advancedSource.tql";
import fileWriterTarget from "text-loader!./tqlTemplates/fileWriterTarget.tql";
import kafkaWriterTarget from "text-loader!./tqlTemplates/kafkaWriterTarget.tql";

var module = {};

function compileApp(tqlTemplate, flowID, properties) {
    var $def = $.Deferred();
    var namespace = metaObjectConverter.getNamespace(flowID);
    api.setCurrentNamespace(namespace, true).then(function() {
        var templateFunction = _.template(tqlTemplate);
        var params = {
            appName: metaObjectConverter.getName(flowID),
            nsName: namespace,
        };
        if (properties) {
            _.extend(params, properties);
        }
        console.log(params);
        var tql = templateFunction(params);
        api.compileTQL(tql)
            .then(function() {
                $def.resolve();
            })
            .fail(function (error) {
                $def.reject(error.message);
            });
    });

    return $def;
}

/**
 * Create simple source with basic fields
 * @param flowID
 * @returns {*}
 */
module.createSimpleSource = function (flowID) {
    return compileApp(simpleSourceTql, flowID);
};

/**
 * Create advanced source
 * @param flowID
 */
module.createAdvancedSource = function (flowID) {
    return compileApp(advancedSourceTql, flowID);
};

module.createFileWriterTarget = function (flowID, properties) {
    return compileApp(fileWriterTarget, flowID, properties);
};

module.createKafkaWriterTarget = function (flowID, properties) {
    return compileApp(kafkaWriterTarget, flowID, properties);
};

export default module;
