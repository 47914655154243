import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({spacing}) => ({
    appTilesContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        gap: 16
    },
    action: {
        margin: 8,
        display: "flex"
    },
    button: {
        minWidth: 110
    },
    empty: {
        textAlign: "center"
    },
    selected: {
        "& .Mui-selected": {
            backgroundColor: "transparent",
            borderBottom: "2px solid #00A7E5",
            borderRadius: 1,
            "&:hover": {
                backgroundColor: "transparent"
            }
        },
        "& ul > li > button:not(.Mui-selected)": {
            backgroundColor: "transparent"
        },
        "& ul": {
            justifyContent: "end"
        }
    },
    paginationContainer: {
        justifyContent: "space-between",
        alignItems: "center"
    },
    sortContainer: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "right",
        alignItems: "center"
    },
    sortIcon:{
        width: spacing(3),
        height: spacing(3)
    }
}));
