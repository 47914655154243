/*
 Initialize jsPlumb graph and Dagre autolayout
 */
import App from "app";
import _ from "underscore";
import $ from "jquery";
import flowUtils from "./flowUtils";
import graphCycleFinder from "./graphCycleFinder";
import AutoLayout from "./autolayout/autolayout";
import { loadByApplication } from "./nodesPositionStorage";
import dagre from "dagre";
import {flowStriimline} from "../../../styles/materialize/components-striimline/routes/flow";
import userPreferences from "../../../../core/services/userPreferences/user-preferences";

var GraphInitializer = function(application) {
    var self = this;
    var MAIN_VIEW = true;
    this.$containerEl = null;

    var jsPlumbInstance = null;

    // TODO this is just for debug purposes to test autolayout alghoritms
    App.vent.on("autolayout:change", async function() {
        if (self.$containerEl) {
            await self.initAutoLayout();
            await self.runAutoLayout();
        }
    });

    this.setGraph = function(nodes, nodeSep, rankSep) {
        var _this = this;
        MAIN_VIEW = self.$containerEl.hasClass("flow-designer-container");
        jsPlumbInstance = jsPlumb.getInstance({
            DragOptions: {
                cursor: "pointer",
                zIndex: 2000
            },
            Container: "canvas"
        });

        this.initialNodes = nodes;
        var nd = nodes;
        jsPlumbInstance.batch(async function() {
            nd.forEach(function(node) {
                node.targets.forEach(function(target) {
                    _this.connectNode(nd, node.id, target);
                });
            });

            await self.initAutoLayout(nodeSep, rankSep);
            await self.runAutoLayout();
        });

        return jsPlumbInstance;
    };

    this.connectNode = function(nodes, sourceNodeId, targetNodeId) {
        var connectionsColor = flowStriimline.nodeConnectionBorder;

        var paintStyle = {
            fillStyle: "transparent", // not connectionsColor, because now used custom CSS style
            radius: 4
        };

        var bottomAnchor = "Bottom";
        var topAnchor = "Top";

        var targetNode = nodes.byId(targetNodeId);
        var sourceNode = nodes.byId(sourceNodeId);

        sourceNodeId = flowUtils.sanitizeElementId(sourceNodeId);
        targetNodeId = flowUtils.sanitizeElementId(targetNodeId);

        var bottomEndpoint = sourceNodeId + bottomAnchor;
        var topEndpoint = targetNodeId + topAnchor;

        const sourceEndpoint = sourceNode.metaObject.type === "STREAM" ? "Blank" : "Rectangle";
        const targetEndpoint = targetNode.metaObject.type === "STREAM" ? "Blank" : "Rectangle";

        var endPoint = jsPlumbInstance.addEndpoint(
            sourceNodeId,
            {
                isSource: true,
                endpoint: sourceEndpoint,
                cssClass: "jsplumb-source-endpoint",
                paintStyle: paintStyle
            },
            {
                anchor: bottomAnchor,
                uuid: bottomEndpoint
            }
        );
        endPoint.setEnabled(false);

        endPoint = jsPlumbInstance.addEndpoint(
            targetNodeId,
            {
                isTarget: true,
                endpoint: targetEndpoint,
                cssClass: "jsplumb-target-endpoint",
                paintStyle: paintStyle
            },
            {
                anchor: topAnchor,
                uuid: topEndpoint
            }
        );
        endPoint.setEnabled(false);

        // stub defines minimal distances from the node of the connecting line 'turning'.
        // All nodes use one approach, but for STREAM we need to adjust it a little differently
        var stub = [44, 15];
        if (sourceNode.metaObject.type === "STREAM") {
            stub = [0, 15];
        }
        if (targetNode.metaObject.type === "STREAM") {
            stub = [8, 5];
        }

        jsPlumbInstance.connect({
            uuids: [bottomEndpoint, topEndpoint],
            maxConnections: -1,
            connector: [
                "Flowchart",
                {
                    cornerRadius: 10,
                    alwaysRespectStubs: MAIN_VIEW ? true : false, //looks silly, but we want explicitely boolean assignment
                    stub: MAIN_VIEW ? stub : 30
                }
            ],
            paintStyle: {
                lineWidth: 2,
                strokeStyle: connectionsColor
            }
        });
    };

    self.initOldAutoLayout = function(graphModel) {
        var nodes = {};

        graphModel.models.forEach(function(node) {
            nodes[node.id] = {
                edges_in: [],
                edges_out: node.targets
            };
        });
        graphModel.models.forEach(function(node) {
            node.targets.forEach(function(target) {
                nodes[target].edges_in.push(node.id);
            });
        });

        //var autolayoutNodes = AutoLayout.autolayout(nodes);

        // we trick the autolayout alghoritm to omitt STREAM nodes (STREAM inputs become STEAM's output's inputs :D, etc)
        var streamNodes = {};
        var noStreamNodes = {};

        // calculate nodes max depth
        // nodes with higher depth are displayed on the left
        function assignMaxNodeDepth(nodes) {
            var leafs = _.filter(nodes, function(node, id) {
                return nodes[id].edges_out.length === 0;
            });
            function setMaxDepthForAllPArents(n) {
                _.each(n.edges_in, function(parent) {
                    var parentComponent = nodes[parent];
                    if ((parentComponent.maxDepth || 0) <= n.maxDepth) {
                        parentComponent.maxDepth = n.maxDepth + 1;
                    }
                    setMaxDepthForAllPArents(parentComponent);
                });
            }

            _.each(leafs, function(n) {
                n.maxDepth = 0;
                setMaxDepthForAllPArents(n);
            });
        }
        assignMaxNodeDepth(nodes);

        _.each(nodes, function(node, id) {
            node.edges_in = _.chain(node.edges_in)
                .map(function(edgeNodeId) {
                    // we detach only stream with any inputs (otherwise it's 'detached' stream)
                    return edgeNodeId.indexOf(".STREAM") > -1 && nodes[edgeNodeId].edges_in.length > 0
                        ? nodes[edgeNodeId].edges_in
                        : edgeNodeId;
                })
                .flatten()
                .value();

            node.edges_out = _.chain(node.edges_out)
                .map(function(edgeNodeId) {
                    return edgeNodeId.indexOf(".STREAM") > -1 ? nodes[edgeNodeId].edges_out : edgeNodeId;
                })
                .flatten()
                .value();
            node.id = id;
            // we detach only stream with any inputs (otherwise it's 'detached' stream)
            if (id.indexOf(".STREAM.") > -1 && nodes[id].edges_in.length > 0) {
                streamNodes[id] = node;
            } else {
                noStreamNodes[id] = node;
            }
        });

        // we autolayout only non STREAM nodes
        var autolayoutNodes = AutoLayout.autolayout(noStreamNodes);

        // fixing x gaps
        _.chain(autolayoutNodes)
            .groupBy("layer")
            .map(function(nodes) {
                return _.map(nodes, function(node) {
                    return {
                        x: node.coords[0],
                        node: node
                    };
                });
            })
            .filter(function(layer) {
                return layer.length > 1;
            })
            .each(function(layer) {
                _.chain(layer)
                    .sortBy("x")
                    .each(function(node, i, sortedLayer) {
                        if (i === sortedLayer.length - 1) {
                            return;
                        }
                        var cur = sortedLayer[i];
                        var next = sortedLayer[i + 1];

                        if (cur.x !== next.x - 1) {
                            // gap found
                            // 0 is our baseline
                            if (cur.x < 0) {
                                // move all nodes on the left to the right
                                sortedLayer.slice(0, i + 1).forEach(function(item) {
                                    item.node.coords[0]++;
                                });
                            } else {
                                // move all nodes on the right to the left
                                sortedLayer.slice(i + 1).forEach(function(item) {
                                    item.node.coords[0]--;
                                });
                            }
                        }
                    });
            });

        // another trick, let's pick max order input node and use it as coords for STREAM (will move it a little bit lower next)
        _.each(streamNodes, function(node, id) {
            var parentNode = _.chain(node.edges_in)
                .map(function(output) {
                    return autolayoutNodes[output];
                })
                .max("order")
                .value();

            autolayoutNodes[id] = {
                id: id,
                coords: parentNode.coords.slice(0), // we need copy of the coords array, slice(0) does that
                layer: parentNode.layer,
                order: parentNode.order
            };
        });

        // we need addionally layout streams that have multiple sources
        _.chain(autolayoutNodes)
            .filter(function(node) {
                return node.id.indexOf(".STREAM.") > -1;
            })
            //.groupBy('layer')
            .groupBy(function(node) {
                return node.coords[0] + 1000 * node.coords[1]; // FIXME grouping nodes with same coords
            })
            .filter(function(group) {
                return group.length > 1;
            })
            .forEach(function(group) {
                _.each(group, function(stream, index) {
                    stream.coords[0] += index;
                });
            });

        self.runAutoLayout = function() {
            var NODE_SEP = MAIN_VIEW ? 32 : 16;
            var RANK_SEP = MAIN_VIEW ? 80 : 40;
            var NODE_WIDTH = MAIN_VIEW ? 240 : 128;
            var NODE_HEIGHT = MAIN_VIEW ? 58 : 32;

            // calculate min and max coords, so we can calculate graph width
            var nodes = [];
            var minX = Number.MAX_VALUE;
            var maxX = Number.MIN_VALUE;
            var minY = Number.MAX_VALUE;
            var maxY = Number.MIN_VALUE;

            _.each(autolayoutNodes, function(node) {
                var nodeEl = $('div[id="' + node.id.replace(/\./g, "_") + '"]');
                nodeEl.data("node-id", node.id);

                // calculate nodes px positions based on autolayout coords
                node.pos = {
                    left: (NODE_WIDTH + NODE_SEP) * (node.coords ? node.coords[0] : 0),
                    top: (NODE_HEIGHT + RANK_SEP) * (node.coords ? node.coords[1] : 0)
                };
                if (node.id.indexOf(".STREAM.") > -1) {
                    node.pos.top += 70;
                }

                var left = node.pos.left + Math.round((NODE_WIDTH - nodeEl.outerWidth()) / 2);
                var top = node.pos.top + Math.round((NODE_HEIGHT - nodeEl.outerHeight()) / 2);

                nodeEl.css("left", left + "px");
                nodeEl.css("top", top + "px");

                minX = Math.min(left, minX);
                maxX = Math.max((left < 240 ? 0 : left) + NODE_WIDTH, maxX);
                minY = Math.min(top, minY);
                maxY = Math.max(top + NODE_HEIGHT, maxY);
                nodes.push(nodeEl);
            });

            // TODO this code should be probably moved out of graphInitializer
            // center the graph both in main view and flow dropdown
            var MARGIN = {
                LEFT: NODE_SEP,
                TOP: 52 // top component should be below App name
                // we have to include 'msg/s counts' when App is running (DEV-8207)
            };
            var LEFT_TOOLBAR_WIDTH = MAIN_VIEW ? 106 : 0;
            var PAGE_WIDTH = MAIN_VIEW ? $("body").width() : self.$containerEl.width();
            var GRAPH_WIDTH = maxX - minX;
            var GRAPH_HEIGHT = maxY - minY;

            var GRAPH_DIMENSIONS = {
                height: GRAPH_HEIGHT,
                width: GRAPH_WIDTH
            };
            App.vent.trigger("graph-width:change", GRAPH_DIMENSIONS);

            var LEFTMARGIN = 30;
            var offset = 0;
            if (!MAIN_VIEW || GRAPH_WIDTH > PAGE_WIDTH - LEFTMARGIN * 2) {
                offset = LEFTMARGIN;
            } else {
                offset = (PAGE_WIDTH - GRAPH_WIDTH) / 2 - LEFT_TOOLBAR_WIDTH;
            }

            // old layout creates less than zero coords, we don't want that
            offset += -Math.min(0, minX);

            if (minX + offset < 10) {
                offset = -minX + 10;
            }

            var storedNodesPosition = {};
            if (application) {
                storedNodesPosition = loadByApplication(application.id);
            }

            nodes.forEach(function(nodeEl) {
                var savedLeftOffset = 0;
                var savedTopOffset = 0;

                if (MAIN_VIEW) {
                    var storedPos = storedNodesPosition[nodeEl.data("node-id")];
                    if (storedPos) {
                        savedLeftOffset = storedPos.left;
                        savedTopOffset = storedPos.top;
                    }
                }

                nodeEl.css("left", nodeEl.position().left + offset + savedLeftOffset + "px");
                nodeEl.css("top", nodeEl.position().top + MARGIN.TOP + savedTopOffset + "px");
            });

            jsPlumbInstance.repaintEverything();
        };
    };

    self.initAutoLayout = function(nodeSep, rankSep) {
        const dagreLayout = userPreferences.get("dagreLayout");
        if (
            !dagreLayout &&
            !graphCycleFinder.hasCycles(self.initialNodes.models)
        ) {
            try {
                self.initOldAutoLayout(self.initialNodes);
                return;
            } catch (e) {
                console.log(e);
                console.log("trying to load dagre layout");
            }
        }

        nodeSep = nodeSep || 32;
        rankSep = rankSep || 60;

        var nodes = self.$containerEl.find(".node");
        var edges = jsPlumbInstance.getAllConnections();
        var g = new dagre.graphlib.Graph();
        g.setGraph({
            rankdir: "TB",
            marginX: 0,
            marginY: 0,
            nodesep: nodeSep,
            ranksep: rankSep
        });
        g.setDefaultEdgeLabel(function() {
            return {};
        });

        var nodeWidth = _.max(
            _.map(nodes, function(n) {
                return $(n).outerWidth();
            })
        );
        var nodeHeight = 58;

        nodes.each(function(index, node) {
            //g.setNode(node.id, {width: $(node).width(), height: $(node).height()});
            g.setNode(node.id, {
                width: nodeWidth,
                height: nodeHeight
            });
        });
        edges.forEach(function(edge) {
            g.setEdge(edge.source.id, edge.target.id);
        });

        dagre.layout(g);
        _.chain(g._nodes)
            .map(function(value, key) {
                return {
                    id: key,
                    value: value
                };
            })
            .groupBy(function(node) {
                return node.value.y;
            })
            .reduce(
                function(acc, group) {
                    var streamNodesCnt = _.filter(group, function(node) {
                        return node.id.indexOf("_STREAM_") > -1;
                    }).length;

                    if (streamNodesCnt === group.length) {
                        acc.prevStream = true;
                        acc.moveBy += 40;
                    } else {
                        acc.moveBy += acc.prevStream ? 40 : 0;
                        acc.prevStream = false;
                    }
                    _.each(group, function(node) {
                        node.value.y -= acc.moveBy;
                    });
                    return acc;
                },
                {
                    prevStream: false,
                    moveBy: 0
                }
            );

        //
        // calculate graph container center position
        //
        function calculateNodesCenterOffset() {
            //var containerWidth = self.$containerEl.width();
            var pageWidth = $("body").width();

            var minX = pageWidth;
            var maxX = 0;
            g.nodes().forEach(function(v) {
                var nodeX = g.node(v).x;
                if (nodeX < minX) {
                    minX = nodeX;
                }
                if (nodeX > maxX) {
                    maxX = nodeX;
                }
            });
            var graphWidth = maxX + nodeWidth - minX;
            var offset = (pageWidth - graphWidth) / 2 - minX - 110;
            return offset < -minX ? -minX : offset;
        }

        self.runAutoLayout = function() {
            var offset = calculateNodesCenterOffset();

            g.nodes().forEach(function(v) {
                var node = g.node(v);
                var nodeEl = $('div[id="' + v + '"]');

                // center smaller nodes
                var xOffset = Math.round((nodeWidth - nodeEl.outerWidth()) / 2);
                var yOffset = Math.round((nodeHeight - nodeEl.outerHeight()) / 2);

                nodeEl.css("left", node.x + xOffset + offset + "px");
                nodeEl.css("top", node.y + yOffset + "px");
            });
            jsPlumbInstance.repaintEverything();
        };
    };
};

// TODO this is just for debug purposes to test autolayout alghoritms
window.changeLayout = function() {
    GraphInitializer.dagreLayout = !GraphInitializer.dagreLayout;
    userPreferences.put("dagreLayout", GraphInitializer.dagreLayout);
    App.vent.trigger("autolayout:change");
};

export default GraphInitializer;
