import metaStoreService from "core/services/metaStoreService/meta-store-service";
import MetaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import prefillProperties from "./prefillProperties";
export default {
    process: function(newNodeType, nodeModel, parentNode, predefinedComponentName) {
        nodeModel.metaObject.autoProperties = nodeModel.metaObject.autoProperties || {};
        nodeModel.metaObject.autoProperties = prefillProperties(
            newNodeType,
            predefinedComponentName,
            PredefinedComponentNames,
            nodeModel.metaObject.autoProperties
        );
        function withNamespace(name) {
            return MetaObjectConverter.getGlobalId(metaStoreService.entities.PROPERTYTEMPLATE, name);
        }

        if (nodeModel.metaObject.autoProperties.adapter) {
            nodeModel.metaObject.autoProperties.adapter.handler = withNamespace(
                nodeModel.metaObject.autoProperties.adapter.handler
            );
        }

        if (nodeModel.metaObject.autoProperties.parser) {
            nodeModel.metaObject.autoProperties.parser.handler = withNamespace(
                nodeModel.metaObject.autoProperties.parser.handler
            );
        }
    }
};
