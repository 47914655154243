const sxStyles = {
    container: {
        width: "70vw",
        paddingTop: "100px",
        alignItems: "center",
        flexDirection: "column",
        gap: 1,
        marginBottom: theme => theme.spacing(3)
    },
    loadingIndicatorWrapper: {
        width: "200px"
    },
    loadingIndicator: {
        height: 8,
        borderRadius: 4,
        backgroundColor: "greyscale.200",
        " .MuiLinearProgress-bar": {
            backgroundColor: "secondary.500",
            width: 56,
            borderRadius: ({ spacing }) => spacing(0.5)
        },
        ".MuiLinearProgress-bar2Indeterminate": {
            left: -56
        }
    },
    headingContainer: {
        borderBottom: ({ palette }) => `1px solid ${palette.greyscale[200]}`
    },
    searchContainer: {
        width: "350px",
        mt: 1
    },
    childContainer: {
        minHeight: 400,
        maxHeight: 570,
        overflow: "auto"
    }
};
export default sxStyles;
