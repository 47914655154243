import React, { useCallback, useMemo, useRef, useEffect, useState } from "react";
import _ from "underscore";
import AddIcon from "@mui/icons-material/Add";
import { Box, IconButton, SvgIcon } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { Close } from "@material-ui/icons";

import { StriimDataTable, StriimInputField, StriimButton, StriimTypography, StriimTooltip } from "@striim/striim-ui";

import styles from "../../../../components/identifier-actions-table/identifier-actions-table.styles";
import { InfoCircle } from "../../../../../../generic/icon/customIcons";
import "./actions-on-entities-table.styles.css";
import { RowNode } from "ag-grid-community";
import ActionsDropdown from "../../../../components/identifier-actions-table/actions-dropdown";
import { SENTINEL_ACTIONS } from "../../../panel/sentinel-panel-consts";
import ActionsCustomMaskingModal from "../../../../components/identifier-actions-table/actions-custom-masking-modal";

export interface MaskingOption {
    label: string;
    value: string;
}

export interface TableData {
    name: string;
    action: MaskingOption;
    entityName: string;
    maskingString?: string;
    maskingType?: string;
    m?: string | number;
    n?: string | number;
    isNew?: boolean;
    nameError?: boolean;
    actionError?: boolean;
    nameErrorMessage?: string;
    rowIndex?: number;
}

interface ActionsOnEntitiesTableProps {
    data: TableData[];
    isDisabled: boolean;
    updateFields: Function;
    isSelectable?: boolean;
    maskingOptions: MaskingOption[];
    savedEntityFields: TableData[];
}

const FieldNameRenderer: React.FC = () => (
    <>
        <StriimTypography variant="body3" color="greyscale.600">
            Field Name
        </StriimTypography>
        <StriimTooltip
            title={
                "Provide the field name in the entity in the source. For example, column name in a relational database, field or key name in a NoSQL database. For all other sources refer to the documentation."
            }
            placement="top"
        >
            <Box pl={"2px"}>
                <SvgIcon component={InfoCircle} sx={styles.infoIcon} />
            </Box>
        </StriimTooltip>
    </>
);

const ActionsOnEntitiesTable: React.FC<ActionsOnEntitiesTableProps> = ({
    data,
    isDisabled = false,
    updateFields,
    isSelectable = false,
    maskingOptions,
    savedEntityFields
}) => {
    const gridRef = useRef<AgGridReact>(null);
    const tableRef = useRef<HTMLDivElement>(null);
    const [tableData, setTableData] = useState<TableData[]>(data);
    const [selectedRows, setSelectedRows] = useState<TableData[]>([]);
    const [selectedBulkAction, setSelectedBulkAction] = useState<MaskingOption>();
    const isRowSelectable = useCallback((rowNode: RowNode) => rowNode?.rowPinned !== "bottom", []);
    const tempTableDataRef = useRef<TableData[]>([]);
    const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
    const [currentEditingRow, setCurrentEditingRow] = useState<TableData | null>(null);

    useEffect(() => {
        tempTableDataRef.current = tableData;
    }, [tableData]);

    useEffect(() => {
        updateFields(tableData);
    }, [tableData]);

    const pinnedBottomRowData = useMemo(() => [{ name: "TEST" }], []);

    const handleRemoveRow = useCallback((rowIndex: number) => {
        setTableData(prevData => {
            const updatedData = prevData.filter((_, index) => index !== rowIndex);
            tempTableDataRef.current = updatedData;
            return updatedData;
        });
    }, []);

    const handleValidation = useCallback(() => {
        let updatedData = [...tempTableDataRef.current];
        let shouldUpdateData = false;

        const fieldNameCounts = new Map();
        updatedData = updatedData.filter(row => {
            if (row.name) {
                fieldNameCounts.set(row.name, (fieldNameCounts.get(row.name) || 0) + 1);
            }
            if (row.isNew && row.name === "" && (!row.action || row.action.value === "")) {
                shouldUpdateData = true;
                return false;
            }
            return true;
        });

        updatedData = updatedData.map(row => {
            const nameEmpty = !row.name || row.name.trim() === "";
            const actionEmpty = !row.action || row.action.value === "";
            const isDuplicate = fieldNameCounts.get(row.name) > 1;
            if (nameEmpty || actionEmpty || isDuplicate) {
                shouldUpdateData = true;
                return {
                    ...row,
                    nameError: nameEmpty || isDuplicate,
                    actionError: actionEmpty,
                    isNew: false,
                    nameErrorMessage: nameEmpty ? "Required Field" : isDuplicate ? "Not unique" : ""
                };
            }

            if (row.nameError || row.actionError || row.isNew || row.nameErrorMessage) {
                shouldUpdateData = true;
                return {
                    ...row,
                    nameError: false,
                    actionError: false,
                    isNew: false,
                    nameErrorMessage: ""
                };
            }

            return row;
        });

        if (shouldUpdateData) {
            setTableData(updatedData);
        }
    }, [tableData]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (isCustomModalOpen) {
                return;
            }

            if (tableRef.current && !tableRef.current.contains(event.target as Node)) {
                setTableData(tempTableDataRef.current);
                handleValidation();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isCustomModalOpen]);

    const CustomPinnedRowRenderer: React.FC = () => {
        const addNewRow = () => {
            const newRow = { name: "", action: { label: "", value: "" }, isNew: true };
            const updatedData = [...tempTableDataRef.current, newRow];
            tempTableDataRef.current = updatedData;
            setTableData(updatedData);
        };
        return (
            <StriimButton
                sx={{
                    ml: -2.5,
                    position: "absolute",
                    color: theme => theme.palette.secondary[500]
                }}
                variant="text"
                disabled={isDisabled}
                startIcon={<AddIcon />}
                onClick={addNewRow}
            >
                Field
            </StriimButton>
        );
    };

    const ActionsInput: React.FC<any> = props => {
        const [localValue, setLocalValue] = useState(props.value);

        const handleChange = useCallback(
            (option: MaskingOption) => {
                if (option.value === SENTINEL_ACTIONS.CUSTOM_MASK) {
                    setCurrentEditingRow({
                        ...props.node.data,
                        rowIndex: props.node.rowIndex
                    });
                    setIsCustomModalOpen(true);
                    return;
                }

                setLocalValue(option);
                tempTableDataRef.current[props.rowIndex] = {
                    ...tempTableDataRef.current[props.rowIndex],
                    action: option,
                    actionError: false,
                    maskingString: undefined,
                    maskingType: undefined,
                    m: undefined,
                    n: undefined
                };

                setTableData(data =>
                    data.map(entry =>
                        entry.name === props.data.name
                            ? {
                                  ...entry,
                                  action: option,
                                  maskingString: undefined,
                                  maskingType: undefined,
                                  m: undefined,
                                  n: undefined
                              }
                            : entry
                    )
                );
            },
            [props.node.rowIndex, props.value?.value, props.node.data]
        );

        return (
            <ActionsDropdown
                value={localValue}
                options={maskingOptions}
                isEditable={!props.isDisabled}
                maskingString={props.data.maskingString}
                onChange={handleChange}
                error={props.data.actionError}
                helperText={props.data.actionError ? "Required Field" : ""}
                isEntitiesPage={true}
            />
        );
    };

    const RemoveRowButton: React.FC<{ data: TableData; rowIndex: number; node: any }> = ({
        data,
        rowIndex,
        node,
        ...props
    }) => {
        if (node.rowPinned) return null;

        return (
            <IconButton
                size="small"
                onClick={() => handleRemoveRow(rowIndex)}
                disabled={props.isDisabled}
                id="remove-entity"
            >
                <Close />
            </IconButton>
        );
    };

    const handleCellValueChanged = useCallback((params: any) => {
        const { colDef, newValue, rowIndex } = params;
        setTableData(prevData => {
            const updatedData = [...prevData];
            updatedData[rowIndex] = {
                ...updatedData[rowIndex],
                [colDef.field]: newValue,
                nameError: false,
                nameErrorMessage: ""
            };
            return updatedData;
        });
    }, []);

    const getRowHeight = useCallback((params: any) => {
        return params.data.nameError || params.data.actionError ? 60 : 40;
    }, []);

    const columnDefs = useMemo(
        () => [
            {
                field: "name",
                headerName: "Field Name",
                headerComponent: "fieldNameRenderer",
                headerCheckboxSelection: isSelectable,
                checkboxSelection: isSelectable,
                minWidth: 140,
                suppressKeyboardEvent: () => true,
                cellStyle: { paddingRight: 0 },
                flex: 1,
                cellRendererSelector: (params: any) => {
                    if (params.node.rowPinned) {
                        return { component: CustomPinnedRowRenderer };
                    } else {
                        return {
                            component: (props: any) => {
                                const [inputValue, setInputValue] = useState(props.data.name);

                                const handleInputChange = (value: string) => {
                                    setInputValue(value);
                                    tempTableDataRef.current[props.rowIndex] = {
                                        ...tempTableDataRef.current[props.rowIndex],
                                        name: value
                                    };
                                };

                                return (
                                    <StriimInputField
                                        id="field-name-input"
                                        disabled={savedEntityFields?.find(field => field.name === inputValue) || isDisabled}
                                        onChange={handleInputChange}
                                        autoFocus={props.data.isNew}
                                        value={inputValue}
                                        error={props.data.nameError}
                                        helperText={
                                            props.data.nameErrorMessage ||
                                            (props.data.nameError ? "Required Field" : "")
                                        }
                                    />
                                );
                            }
                        };
                    }
                }
            },
            {
                field: "action",
                headerName: "Action",
                minWidth: 120,
                flex: 1,
                cellRendererSelector: (params: any) => {
                    if (!params.node.rowPinned) {
                        return { component: "actionRenderer" };
                    }
                },
                cellRendererParams: {
                    isDisabled
                },
                cellStyle: { paddingRight: 0 }
            },
            {
                field: "remove",
                headerName: "",
                minWidth: 1,
                flex: isSelectable ? undefined : 1,
                cellRenderer: "removeRowRenderer",
                cellRendererParams: {
                    isDisabled
                },
                cellStyle: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingRight: 0,
                    paddingLeft: 2
                }
            }
        ],
        [isSelectable, isDisabled]
    );

    const onSelectionChanged = useCallback(() => {
        setSelectedRows(gridRef.current?.api?.getSelectedRows() as TableData[]);
    }, []);

    useEffect(() => {
        if (selectedRows.length > 0) {
            const bulkAction = maskingOptions.find(maskingOption =>
                selectedRows.every(row => row.action.value === maskingOption.value)
            );
            setSelectedBulkAction(bulkAction ?? { value: "mixed", label: "Mixed" });
        }
    }, [selectedRows, maskingOptions]);

    const handleBulkActionChange = useCallback(
        (option: MaskingOption) => {
            const selectedIds: string[] = selectedRows.map(entry => entry?.name);
            setTableData(prevData =>
                prevData.map(entry => (selectedIds.includes(entry?.name) ? { ...entry, action: option } : entry))
            );
            setSelectedBulkAction(option);
            gridRef.current?.api?.deselectAll();
        },
        [selectedRows]
    );

    const handleCustomMaskingConfirm = useCallback(
        (maskingConfig: { maskingType: string; maskingString: string; m?: number; n?: number }) => {
            if (currentEditingRow) {
                setTableData(data =>
                    data.map((entry, index) =>
                        index === currentEditingRow.rowIndex
                            ? {
                                  ...entry,
                                  action: { label: "Custom Mask", value: SENTINEL_ACTIONS.CUSTOM_MASK },
                                  maskingString: maskingConfig.maskingString,
                                  maskingType: maskingConfig.maskingType,
                                  m: maskingConfig.m,
                                  n: maskingConfig.n
                              }
                            : entry
                    )
                );
                setCurrentEditingRow(null);
            }
            setIsCustomModalOpen(false);
        },
        [currentEditingRow, maskingOptions, setTableData]
    );

    return (
        <Box ref={tableRef}>
            {isSelectable && selectedRows.length > 0 && (
                <Box sx={styles.multiselectContainer} mb={1}>
                    <StriimTypography variant="body4" color="greyscale.900">
                        {selectedRows.length} Selected
                    </StriimTypography>
                    <Box display="flex" alignItems="center">
                        <StriimTypography mr={2} color="greyscale.700" variant="body4">
                            Action
                        </StriimTypography>
                        <StriimInputField
                            SelectProps={{
                                options: maskingOptions,
                                menuShouldScrollIntoView: false,
                                menuPosition: "absolute",
                                zIndex: 1502,
                                isClearable: false
                            }}
                            onChange={value => handleBulkActionChange(value)}
                            value={selectedBulkAction}
                            select
                        />
                    </Box>
                </Box>
            )}
            <StriimDataTable
                ref={gridRef}
                key={isSelectable ? "selectable" : "non-selectable"}
                components={{
                    fieldNameRenderer: FieldNameRenderer,
                    actionRenderer: ActionsInput,
                    removeRowRenderer: RemoveRowButton
                }}
                data={tableData}
                columns={columnDefs}
                enableCellTextSelection
                pinnedBottomRowData={pinnedBottomRowData}
                onCellValueChanged={handleCellValueChanged}
                getRowHeight={getRowHeight}
                onSelectionChanged={onSelectionChanged}
                isRowSelectable={isRowSelectable}
                rowSelection="multiple"
            />
            <ActionsCustomMaskingModal
                isVisible={isCustomModalOpen}
                setCustomMaskingModalVisible={setIsCustomModalOpen}
                onConfirm={handleCustomMaskingConfirm}
                initialMaskingConfig={
                    currentEditingRow
                        ? {
                              maskingType: currentEditingRow.maskingType || SENTINEL_ACTIONS.MASK_M_N,
                              maskingString: currentEditingRow.maskingString || ""
                          }
                        : undefined
                }
            />
        </Box>
    );
};

export default ActionsOnEntitiesTable;
