import NestedTypes from "backbone.nestedtypes";
import _ from "underscore";
import BaseControl from "./../base-control";
import template from "./header-with-icon.html";
import nameFormatter from "app/components/common/editor/name-formatter";
import { iconMaker } from "app/components/common/icon-maker/icon-maker";
import ensureRegions from "../../../_ensureRegions";

var Model = NestedTypes.Model.extend({
    defaults: {
        text: String,
        tag: String,
        iconName: String,
        iconType: String,
        title: String,
        showTooltip: true,
        linkText: String,
        linkClass: String,
        showLink: false,
        linkTooltip: String,
        showLinkTooltip: false
    }
});

var linkClickEvent = "click:header-link";
var tooltipShownEvent = "tooltip-shown";

var View = BaseControl.View.extend({
    hideLabel: true,

    className: "header-with-icon",

    template: _.template(template),
    model: Model,

    ui: {
        link: "a.link",
        componentIcon: ".component-icon",
        componentTitle: ".component-title span",
        iconContainer: ".icon-container"
    },

    regions: {
        iconRegion: ".icon-container"
    },

    triggers: _.extend(
        {
            "click @ui.link": linkClickEvent
        },
        BaseControl.View.prototype.triggers
    ),

    modelEvents: _.extend(
        {
            "change:showLink": "_toggleLink",
            "change:showLinkTooltip": "_showLinkTooltip",
            "change:iconClass": "render",
            "change:text": "render",
            "change:title": "render"
        },
        BaseControl.View.prototype.events
    ),

    getViewValue: function() {},

    setViewValue: function() {},

    onRender: function() {
        ensureRegions(this);
        BaseControl.View.prototype.onRender.apply(this, arguments);

        var _this = this;

        // showTooltip is true by deafult
        var showTooltip =
            typeof _this.model.get("showTooltip") === "undefined" ? true : !!_this.model.get("showTooltip");

        function initTooltip(jqueryEl) {
            jqueryEl.attr("title", _this.model.attributes.title || _this.model.attributes.text);
            jqueryEl.tooltipster({
                position: "bottom-left",
                contentAsHTML: true
            });
        }
        if (showTooltip) {
            initTooltip(this.ui.componentIcon);
            initTooltip(this.ui.componentTitle);
        }

        this._toggleLink();
        this._setLinkClass();
        this.ui.link.html(this.model.linkText);

        if (this.model.iconName) {
            const icon = iconMaker.createGraphNodeIcon(this.model.iconType, this.model.iconName);
            if (this.getRegion("iconRegion").hasView()) {
                this.getRegion("iconRegion").empty();
            }
            this.getRegion("iconRegion").show(icon);
            this.ui.iconContainer.show();
        }
    },

    onShow: function() {
        BaseControl.View.prototype.onShow.apply(this, arguments);
        setTimeout(
            function() {
                // the tooltipster won't work unitl element is added to DOM
                this._showLinkTooltip();
            }.bind(this),
            500
        );
    },

    _initializeLinkTooltip: function() {
        if (typeof this.ui.link !== "object") {
            return;
        }
        if (this._isTooltipInitialized && this.ui.link.hasClass("tooltipstered")) {
            this.ui.link.tooltipster("destroy");
        }
        this._isTooltipInitialized = false;

        if (this.model.linkTooltip) {
            this.ui.link.tooltipster({
                position: "bottom",
                content: this.model.linkTooltip
            });
            this._isTooltipInitialized = true;
        }
    },

    setType: function(type, iconName) {
        const title = `New ${nameFormatter.format(type)}`;

        this.model.set({
            text: title,
            title: title,
            iconTye: type,
            iconName: iconName
        });
    },

    _setLinkClass: function() {
        if (typeof this.ui.link !== "object") {
            return;
        }
        var className = "link";
        if (this.model.linkClass) {
            className += " " + this.model.linkClass;
        }
        this.ui.link[0].className = className;
    },

    _toggleLink: function() {
        if (typeof this.ui.link !== "object") {
            return;
        }
        if (this.model.showLink) {
            this.ui.link.show();
        } else {
            this.ui.link.hide();
        }
    },

    _showLinkTooltip: function() {
        this._initializeLinkTooltip();

        if (typeof this.ui.link !== "object") {
            return;
        }
        if (!this._isTooltipInitialized) {
            return;
        }
        if (this.model.showLinkTooltip) {
            this.ui.link.tooltipster("show");
            this.trigger(tooltipShownEvent);
        } else {
            this.ui.link.tooltipster("hide");
        }
    }
});

View.prototype.linkClickEvent = linkClickEvent;
View.prototype.tooltipShownEvent = tooltipShownEvent;

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View
});
