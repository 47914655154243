import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { ArrowDownward } from "../../../../../generic/icon/customIcons";
import { StriimTypography } from "@striim/striim-ui-v2";
import { getPriorityImage } from "../../../sentinel-live-monitor/components/sentinel-table/sentinel-table-sdi";
import { Box, Grid } from "@mui/material";
import { styles } from "./report-table.styles";
import PredefinedComponentNames from "../../../../../../app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import { AdapterIcon } from "../../../../../generic/icon/component-icon/component-icon";
import { ChevronRight } from "@material-ui/icons";
import { getPercentage } from "./report-tables.helper";
import { DataTypography, ImportanceLabelWithIcon, ReportTable, TitleTypography } from "./report-table-componets";
import { nFormatter } from "../../../sentinel-live-monitor/components/sentinel-table/utils";
import { getAdapterObject } from "./sherlock-report-tables";
import metaStoreService from "../../../../../../core/services/metaStoreService/meta-store-service";
import metaObjectHelper from "../../../../../../app/components/common/editor/meta-object-editors/metaObjectHelper";

export const IdentifiersTable = ({ data, totalEvents }) => {
    return (
        <ReportTable
            header={
                <TableRow>
                    <TableCell align="left">
                        <TitleTypography>Sensitive Data Identifier</TitleTypography>
                    </TableCell>
                    <TableCell align="right">
                        <TitleTypography>Events W/ Identifier </TitleTypography>
                        <DataTypography>
                            (of {totalEvents}) <ArrowDownward />
                        </DataTypography>
                    </TableCell>
                </TableRow>
            }
            content={
                <>
                    {data.map(row => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                <DataTypography>{row.name}</DataTypography>
                            </TableCell>
                            <TableCell align="right">
                                <DataTypography>{row.events}</DataTypography>
                            </TableCell>
                        </TableRow>
                    ))}
                </>
            }
        />
    );
};

export const EventsProcessedTable = ({ data, totalEvents, totalSensitiveDataEvents }) => {
    return (
        <ReportTable
            header={
                <TableRow>
                    <TableCell align="left">
                        <TitleTypography>App name</TitleTypography>
                    </TableCell>
                    <TableCell align="left">
                        <TitleTypography>Namespace</TitleTypography>
                    </TableCell>
                    <TableCell align="right">
                        <TitleTypography>Events </TitleTypography>
                        <DataTypography>({nFormatter(totalEvents, 2)})</DataTypography>
                    </TableCell>
                    <TableCell align="right">
                        <TitleTypography>Events W/ Sensitive Data </TitleTypography>
                        <DataTypography>
                            (of {nFormatter(totalSensitiveDataEvents, 2)}) <ArrowDownward />
                        </DataTypography>
                    </TableCell>
                    <TableCell align="right">
                        <TitleTypography>Events Tagged</TitleTypography>
                    </TableCell>
                </TableRow>
            }
            content={
                <>
                    {data.map(row => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                <DataTypography>{row.name}</DataTypography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <DataTypography>{row.namespace}</DataTypography>
                            </TableCell>
                            <TableCell align="right">
                                <DataTypography>{row.events}</DataTypography>
                            </TableCell>
                            <TableCell align="right">
                                <DataTypography>{row.sensitiveData}</DataTypography>
                            </TableCell>
                            <TableCell align="right">
                                <DataTypography>{row.eventsTagged}</DataTypography>
                            </TableCell>
                        </TableRow>
                    ))}
                </>
            }
        />
    );
};

export const SDITable = ({ data, totalEvents, totalOccurences, isGlobalReport = false }) => {
    const PolicyLabels = () => (
        <Box display={"flex"} gap={2}>
            <StriimTypography variant="caption" sx={styles.lightText}>
                Policy Actions
            </StriimTypography>
            <ActionTypeLabel type={"Encrypted"} />
            <ActionTypeLabel type={"Masked"} />
            <ActionTypeLabel type={"No Action"} />
        </Box>
    );
    const ActionTypeLabel = ({ type }) => {
        return (
            <StriimTypography variant="caption" sx={styles.lightText}>
                <label style={{ marginRight: "8px", fontWeight: 500, color: "#202020" }}>{type[0]}</label> {type}
            </StriimTypography>
        );
    };

    return (
        <>
            <ReportTable
                legend={
                    <Grid
                        container
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        sx={{ color: "rgba(32, 32, 32, 0.50);" }}
                        my={2}
                    >
                        <Box display={"flex"} gap={2}>
                            <StriimTypography variant="caption" sx={styles.lightText}>
                                Importance
                            </StriimTypography>
                            <ImportanceLabelWithIcon label={"High"} icon={getPriorityImage("HIGH")} />
                            <ImportanceLabelWithIcon label={"Medium"} icon={getPriorityImage("MEDIUM")} />
                            <ImportanceLabelWithIcon label={"Low"} icon={getPriorityImage("LOW")} />
                        </Box>
                        <PolicyLabels />
                    </Grid>
                }
                header={
                    <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="left">
                            <TitleTypography>Sensitive Data Identifier </TitleTypography>
                        </TableCell>
                        <TableCell align="right">
                            <TitleTypography>Events W/ Identifier </TitleTypography>
                            <DataTypography>
                                (of {totalEvents}) <ArrowDownward />
                            </DataTypography>
                        </TableCell>
                        <TableCell align="right">
                            <TitleTypography>Occurrences ({totalOccurences})</TitleTypography>
                        </TableCell>
                        <TableCell align="center">
                            <TitleTypography>E</TitleTypography>
                        </TableCell>
                        <TableCell align="center">
                            <TitleTypography>M</TitleTypography>
                        </TableCell>
                        <TableCell align="center">
                            <TitleTypography>N</TitleTypography>
                        </TableCell>
                        {isGlobalReport && (
                            <TableCell align="center">
                                <TitleTypography># Apps</TitleTypography>
                            </TableCell>
                        )}
                    </TableRow>
                }
                content={
                    <>
                        {data.map(row => {
                            const totalActions = row.MASK + row.ENCRYPT + row.NO_ACTION;
                            return (
                                <TableRow key={row.label}>
                                    <TableCell component="th" scope="row">
                                        {getPriorityImage(row.importance)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <DataTypography>{row.label}</DataTypography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <DataTypography>{row.eventsWithIdentifier}</DataTypography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <DataTypography>{row.occurrences}</DataTypography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <DataTypography>{getPercentage(row.ENCRYPT, totalActions)}%</DataTypography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <DataTypography>{getPercentage(row.MASK, totalActions)}%</DataTypography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <DataTypography>{getPercentage(row.NO_ACTION, totalActions)}%</DataTypography>
                                    </TableCell>
                                    {isGlobalReport && (
                                        <TableCell align="center">
                                            <DataTypography>{row.applications}</DataTypography>
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </>
                }
            />
        </>
    );
};

export const AppsWithIdentifiers = ({ data, totalEventsWithIdentifiers, totalOccurrences }) => {
    return (
        <ReportTable
            header={
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center">
                        <TitleTypography>App Name</TitleTypography>
                    </TableCell>
                    <TableCell align="center">
                        <TitleTypography>Nsname</TitleTypography>
                    </TableCell>
                    <TableCell align="right">
                        <TitleTypography>Events W/ Identifier</TitleTypography>
                        <DataTypography>
                            (of {totalEventsWithIdentifiers}) <ArrowDownward />
                        </DataTypography>
                    </TableCell>
                    <TableCell align="right">
                        <TitleTypography>Occurrences ({nFormatter(totalOccurrences, 2)})</TitleTypography>
                    </TableCell>
                    <TableCell align="center">
                        <TitleTypography>E</TitleTypography>
                    </TableCell>
                    <TableCell align="center">
                        <TitleTypography>M</TitleTypography>
                    </TableCell>
                    <TableCell align="center">
                        <TitleTypography>N</TitleTypography>
                    </TableCell>
                </TableRow>
            }
            content={
                <>
                    {data.length ? (
                        <>
                            {data.map(row => {
                                const { sourceAdapters = [], targetAdapters = [] } = row;
                                return (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row" style={{ width: "50px" }}>
                                            <Grid
                                                display={"flex"}
                                                alignItems={"center"}
                                                width={"fit-content"}
                                                gap={1 / 2}
                                            >
                                                {sourceAdapters.map(source => {
                                                    const sourceObject = getAdapterObject(
                                                        metaStoreService.entities.SOURCE,
                                                        source
                                                    );
                                                    const sourceIcon = metaObjectHelper.getIconClassByMetaObject(
                                                        sourceObject
                                                    );
                                                    return (
                                                        <Box mx={1 / 4} key={source}>
                                                            <AdapterIcon
                                                                name={sourceIcon}
                                                                type={"source"}
                                                                outlined={true}
                                                                size={32}
                                                            />
                                                        </Box>
                                                    );
                                                })}
                                                <ChevronRight style={{ fill: "#8091A3", width: "16px" }} />
                                                {targetAdapters.map(target => {
                                                    const targetObject = getAdapterObject(
                                                        metaStoreService.entities.TARGET,
                                                        target
                                                    );
                                                    const targetIcon = metaObjectHelper.getIconClassByMetaObject(
                                                        targetObject
                                                    );
                                                    return (
                                                        <Box mx={1 / 4} key={target}>
                                                            <AdapterIcon
                                                                name={targetIcon}
                                                                type={"target"}
                                                                outlined={true}
                                                                size={32}
                                                            />
                                                        </Box>
                                                    );
                                                })}
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center">
                                            <DataTypography>{row.name}</DataTypography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <DataTypography>{row.nameSpace}</DataTypography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <DataTypography>{row.eventsWithIdentifier}</DataTypography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <DataTypography>{row.occurrences}</DataTypography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <DataTypography>
                                                {getPercentage(row.ENCRYPTED, row.eventsWithIdentifier)}%
                                            </DataTypography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <DataTypography>
                                                {getPercentage(row.MASKED, row.eventsWithIdentifier)}%
                                            </DataTypography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <DataTypography>
                                                {getPercentage(row.NO_ACTION, row.eventsWithIdentifier)}%
                                            </DataTypography>
                                        </TableCell>

                                        <TableCell align="right">
                                            <DataTypography>{row.applications}</DataTypography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </>
                    ) : (
                        <>No data</>
                    )}
                </>
            }
        />
    );
};
