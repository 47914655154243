import Marionette from "marionette";
import _ from "underscore";
import BaseLayout from "app/components/common/wizardmaker/baseLayout";
import flatTemplate from "./templates/flatLayout.html";

var FlatCompositeLayout = Marionette.CollectionView.extend({
    initialize: function (options) {
        this.currentStepIndex = options.currentStepIndex;
        this.vent = options.vent;
    },
    getChildView: function (item) {
        var view = item.get("view");
        var _this = this;
        view.prototype.initialize = function (options) {
            this.vent = _this.vent; //pass on the vent from the layout view

            if (this.model.get("isActive")) {
                //make the first step active
                this.$el.addClass("active-step");
            }

            this.listenTo(this.model, "change", function () {
                //update the views as the steps are completed
                this.$el.toggleClass("active-step");
            });
        };
        return view;
    },
    childViewOptions: function (model, index) {
        if (index == this.currentStepIndex) {
            return {
                isActive: true,
            };
        }
    },
});

var FlatLayout = BaseLayout.extend({
    type: "flat",
    template: _.template(flatTemplate),
    regions: {
        steps: ".flat-list-container",
    },
    events: {
        "click .next-button-container": "doNextHandler",
        "click .prev-button-container": "doPrevHandler",
    },
    onRender: function () {
        var _this = this;
        console.log(this.el);
        //view for the current step
        var steps = new FlatCompositeLayout({
            collection: _this.collection,
            currentStepIndex: _this.currentStepIndex,
            vent: _this.vent,
        });
        console.log(this.getRegion("steps"));
        this.getRegion("steps").show(steps);
    },
    doNextHandler: function () {
        this.currentStepIndex = this.collection.next();
    },
    doPrevHandler: function () {
        this.currentStepIndex = this.collection.prev();
    },
});

export default FlatLayout;
