import React, { useEffect } from "react";
import { useObserver } from "mobx-react";
import { components } from "react-select";
import { Grid, Box } from "@mui/material";
import PropTypes from "prop-types";
import { StriimInputField, StriimTypography } from "@striim/striim-ui";
import { styles } from "./create-app-header.styles";
import useCreateAppsStore from "src/stores/create-apps/useCreateAppsStore";

const CreateAppHeader = () => {
    const store = useCreateAppsStore();
    const options = useObserver(() => store.searchOptions);
    useEffect(() => () => {
        store.setSearchOptions([]);
    });

    return (
        <Box>
            <Grid container alignItems="center" sx={styles.wrapperInner}>
                <Box component={StriimTypography} variant="h1" sx={styles.subTitle}>
                    Create App
                </Box>
                <Grid
                    container
                    item
                    xs={9}
                    sm={6}
                    justifyContent="flex-end"
                    direction="row"
                    data-test-id={"template-search-bar"}
                >
                    <StriimInputField
                        SelectProps={{
                            options,
                            disableFirstLetterFiltering: true,
                            isMulti: true,
                            withSearchIcon: true,
                            isSearchable: true,
                            components: {
                                DropdownIndicator: () => null,
                                IndicatorsContainer: () => null,
                                Option: props => {
                                    return (
                                        <div data-test-id="search-template-menu-item">
                                            <components.Option {...props} />
                                        </div>
                                    );
                                },
                                NoOptionsMessage: props => {
                                    return (
                                        <div data-test-id="search-template-no-options-message">
                                            <components.NoOptionsMessage {...props} />
                                        </div>
                                    );
                                }
                            }
                        }}
                        select
                        placeholder="Search for wizards"
                        onChange={currentOptions => {
                            store.setSearchOptions(currentOptions || []);
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

CreateAppHeader.propTypes = {
    isStatic: PropTypes.bool
};

export default CreateAppHeader;
