import _ from "underscore";
import metaObjectHelper from "../../../common/editor/meta-object-editors/metaObjectHelper";
import flowUtils from "../flowUtils";
import PredefinedComponentNames from "app/components/flow/designer/predefinedComponents/predefinedComponentNames.json";
import NestedTypes from "backbone.nestedtypes";
import utils from "core/utils";

var GraphNodeModel = NestedTypes.Model.extend({
    defaults: {
        id: String,
        predefinedComponentName: String,
        metaObject: Object,
        flowId: String,
        applicationId: String,
        targets: Array,
        parentNode: Object,
        isSelected: Boolean.value(false),

        /// nullable number
        ///    when rate is -1 no counters will be shown (initial state)
        ///    once the server starts sending stats
        ///    it will either show a real number of msg/s
        ///    or it will show N/A if the stats for the
        ///    particular component are not ready yet (once the server
        ///    explicitly returns null while getting component stats)
        recentDataRate: -1,

        additionalStatisticsInfo: String,

        // statistics for back-pressured streams
        streamFull: Boolean,

        isExternal: false,
        isMissing: false,

        isInClipboard: false,
        isCut: false, // node is in clipboard and it's cut
    },

    collection: {
        filterFlowNodes: function () {
            return this.reject(function (m) {
                return m.metaObject.type !== "FLOW";
            });
        },

        getSelectedNodes: function () {
            return this.reject(function (m) {
                return m.isSelected === false;
            });
        },

        byId: function (id) {
            return this.where(
                {
                    id: id,
                },
                true
            );
        },

        /**
         * returns all nodes that don't have parent node
         */
        filterRootNodes: function () {
            var allTargets = [];

            this.forEach(function (n) {
                allTargets = _.union(allTargets, n.targets);
            });
            allTargets = _.uniq(allTargets);

            var result = this.reject(function (n) {
                return _.contains(allTargets, n.id) === true;
            });
            return result;
        },
    },

    properties: {
        className: function () {
            if (this.predefinedComponentName) {
                var predefinedComponent = _.find(PredefinedComponentNames, {
                    name: this.predefinedComponentName,
                });
                return predefinedComponent.className;
            }
            return metaObjectHelper.getIconClassByMetaObject(this.metaObject);
        },

        elementId: function () {
            return flowUtils.sanitizeElementId(this.id);
        },

        fullName: function () {
            return this.id.replace("." + this.metaObject.type + ".", ".");
        },

        description: function () {
            return metaObjectHelper.descriptionResolver(this.metaObject, this.applicationId);
        },

        isFlowNode: function () {
            return this.metaObject && this.metaObject.type === "FLOW";
        },

        breakableName: function () {
            if (this.metaObject && this.metaObject.name) {
                return this.metaObject.name.replace(/([a-z])([A-Z])/g, "$1&shy;$2");
            }
        },

        duplicable: function () {
            return (
                [utils.ENTITIES.EXTERNALSOURCE, utils.ENTITIES.SOURCE, utils.ENTITIES.TARGET, utils.ENTITIES.CACHE].indexOf(this.metaObject.type) !==
                -1
            );
        },
    },

    toJSON: function () {
        return flowUtils.serializeModelToJson(this);
    },
});

GraphNodeModel.prototype.tempNodeId = "temp-node";

export default GraphNodeModel;
