import App from "app";
import {
    childView,
    childViewContainer,
    className,
    emptyView,
    modelEvent,
    on,
    onChild,
    regions,
    tagName,
    template,
    triggers,
    ui
} from "core/decorators";
import {CollectionView, CompositeView, LayoutView} from "marionette";
import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import leftSidebarTemplate from "./templates/left-siderbar-template.html";
import groupHeaderTemplate from "./templates/group-header-template.html";
import repairDialogTemplate from "./templates/repair-dialog-template.html";
import leftListItemView from "./templates/left-list-item-view.template.html";
import utils from "core/utils";
import converter from "core/services/metaStoreService/metaobject-converter";
import api from "core/api/api";
import growl from "app/components/common/growl";
import PageBlocker from "app/components/flow/designer/page-blocker";
import MarionetteWrapper from "../../components/marionette-wrapper-for-react";
import {AlertConfigMsgBox, AutoCorrectMsgBox} from "src/generic/message-box/message-box";
import {AlertManagerIntegrationStatuses} from "./alertmanager-integration-statuses";
import {ALERT_TYPE, getSysAlertComponentType} from "./alert-utils";
import navigateTo from "src/navigate-to";
import {initMaterialDesignTabs} from "../../../core/utils/material-design/tabs/material-design-style-tabs";
import {initMaterialDesignCollapsible} from "../../../core/utils/material-design/material-design-collapsible";

/*let modalManager = new ModalManager({
    container: "body"
});*/

@template(leftSidebarTemplate)
@className("left-sidebar-container-wrapper")
@regions({
    groups: ".groups",
    configureAlert: "#configure-alert",
    autoRepairEmail: "#auto-repair-email",
    autoRepairSlack: "#auto-repair-slack",
    autoRepairTeams: "#auto-repair-teams"
})
@ui({
    tabs: ".tabs-menu button",
    groups: ".groups",
    addButton: ".add-alert",
    "search-content": "#search-content"
})
export class LeftSidebar extends LayoutView {
    onRender() {
        this.pageBlocker = new PageBlocker("#content");
        const that = this;

        this.addConfigurationMsg();
        this.addAutoRepairMsg();

        this.ui.tabs.click(function() {
            let tab = $(this).attr("data-tab");
            navigateTo.AlertManagerTab(tab);
            that.model.set("tab", tab);
            that.showTab();
        });
        this.showTab();

        this.ui.addButton.tooltipster({
            position: "bottom",
            offsetY: -10,
            animation: "grow"
        });
    }

    onShow() {
        const ctx = this;
        setTimeout(() => {
            ctx.tabsRemoveCallback = initMaterialDesignTabs(document.querySelector("#alerts-tabs"));
            const accordionInstance = initMaterialDesignCollapsible(
                document.querySelectorAll(".collapsible.expandable"),
                {
                    accordion: false
                }
            );
            const category = ctx.model.get("category");
            const item = ctx.model.get("item");

            if (category) {
                const selectedCategory = this.$el.find(`.left-group[data-category='${category}']`);
                accordionInstance[0].open(selectedCategory.index());
                if (item) {
                    const container = $(".left-side-bar .groups");
                    const selectedItem = ctx.$el.find(`.left-list-item[data-id='${item}']`);
                    const selectedItemOffset = selectedItem.offset();

                    // Can be empty
                    if (selectedItemOffset) {
                        container.animate({
                            scrollTop: selectedItemOffset.top - container.offset().top + container.scrollTop()
                        });
                    }

                    selectedItem.click();
                }
            }
        }, 0);
    }

    destroy() {
        Backbone.Marionette.LayoutView.prototype.destroy.apply(this, arguments);
        if (this.tabsRemoveCallback) {
            this.tabsRemoveCallback();
        }
    }

    showTab(filter) {
        if (window.location.hash !== "#/alertmanager/new") {
            App.vent.trigger("alertmanager:show:detail", null);
        }

        const groupsCollection = this.getGroupCollection(filter);
        this.getRegion("groups").show(new GroupsCollectionView({ collection: groupsCollection }));
        this.ui.tabs.removeClass("active");
        this.$('*[data-tab="' + this.model.get("tab") + '"]').addClass("active");
        initMaterialDesignCollapsible(document.querySelectorAll(".collapsible.expandable"), {
            accordion: false
        });
    }

    addConfigurationMsg() {
        const emailConfigStatus = this.model.get("emailConfigStatus");
        const slackConfigStatus = this.model.get("slackConfigStatus");
        const teamsConfigStatus = this.model.get("teamsConfigStatus");

        const alertConfigMsgBox = MarionetteWrapper(AlertConfigMsgBox, {
            email: {
                onClick: () => {
                    navigateTo.SMTPSetup();
                },
                notConfigured: emailConfigStatus === AlertManagerIntegrationStatuses.Email.NotConfigured
            },
            slack: {
                onClick: () => {
                    navigateTo.SlackSetup();
                },
                notConfigured: slackConfigStatus === AlertManagerIntegrationStatuses.Slack.NotConfigured
            },
            teams: {
                onClick: () => {
                    navigateTo.TeamsSetup();
                },
                notConfigured: teamsConfigStatus === AlertManagerIntegrationStatuses.Teams.NotConfigured
            }
        });
        this.getRegion("configureAlert").show(alertConfigMsgBox);
    }

    addAutoRepairMsg() {
        const emailConfigStatus = this.model.get("emailConfigStatus");
        const slackConfigStatus = this.model.get("slackConfigStatus");
        const teamsConfigStatus = this.model.get("teamsConfigStatus");
        if (
          emailConfigStatus === "NOT_EXISTING" ||
          emailConfigStatus === "NOT_RUNNING" ||
          emailConfigStatus === "INVALID"
        ) {
            const autoRepairMsg = MarionetteWrapper(AutoCorrectMsgBox, {
                onAutocorrectClick: this.autoRepairAlerts.bind(this),
                label: "Auto Correct Email",
                msg: "Something went wrong with Email Configuration."
            });
            this.getRegion("autoRepairEmail").show(autoRepairMsg);
        }
        if (
          slackConfigStatus === "NOT_EXISTING" ||
          slackConfigStatus === "NOT_RUNNING" ||
          slackConfigStatus === "INVALID"
        ) {
            const autoRepairMsg = MarionetteWrapper(AutoCorrectMsgBox, {
                onAutocorrectClick: this.autoRepairAlerts.bind(this),
                label: "Auto Correct Slack",
                msg: "Something went wrong with Slack Configuration."
            });
            this.getRegion("autoRepairSlack").show(autoRepairMsg);
        }
        if (
          teamsConfigStatus === "NOT_EXISTING" ||
          teamsConfigStatus === "NOT_RUNNING" ||
          teamsConfigStatus === "INVALID"
        ) {
            const autoRepairMsg = MarionetteWrapper(AutoCorrectMsgBox, {
                onAutocorrectClick: this.autoRepairAlerts.bind(this),
                label: "Auto Correct Teams",
                msg: "Something went wrong with Teams Configuration."
            });
            this.getRegion("autoRepairTeams").show(autoRepairMsg);
        }
    }

    @on("keydown #search-content")
    onFilter(e) {
        if (this.searchKeyDown) {
            clearTimeout(this.searchKeyDown);
        }
        this.searchKeyDown = setTimeout(() => {
            let filter = $(e.target).val();
            this.showTab(filter);
        }, 500);
    }

    getGroupCollection(filter) {
        let map = {};
        let getAlertsForGroup;
        if (this.model.get("isLiteMode")) {
            getAlertsForGroup = this.model.get("alertCollection").toArray();
        } else {
            getAlertsForGroup = this.getAlertsForGroup(filter);
        }
        getAlertsForGroup = _.sortBy(getAlertsForGroup, function(alert) {
            // sort alerts alphabetically
            return alert.attributes.name.toLowerCase();
        });
        _.each(getAlertsForGroup, function(alert) {
            if (map[alert.get("objectName")] === undefined) {
                map[alert.get("objectName")] = [];
            }
            map[alert.get("objectName")].push(alert);
        });

        let collection = new Backbone.Collection();
        _.each(map, (alertsArray, objectName) => {
            collection.add({
                name: utils.getName(objectName) ? utils.getName(objectName) : `${getSysAlertComponentType(objectName)}`,
                nsName: utils.getNamespace(objectName) ? utils.getNamespace(objectName) : "any",
                type: converter.getType(objectName),
                tab: this.model.get("tab"),
                isLiteMode: this.model.get("isLiteMode"),
                collection: new Backbone.Collection(alertsArray)
            });
        });

        collection.comparator = function(group) {
            // Sort groups alphabetically
            return group.get("name").toLowerCase();
        };
        collection.sort();
        return collection;
    }

    getAlertsForGroup(filter) {
        filter = filter ? filter.toLowerCase() : "";

        function passesFilter(model) {
            return (
                model
                    .get("objectName")
                    .toLowerCase()
                    .indexOf(filter) !== -1 ||
                model
                    .get("name")
                    .toLowerCase()
                    .indexOf(filter) !== -1
            );
        }

        if (this.model.get("tab") === "smart_alerts") {
            return this.model.get("alertCollection").filter(model => {
                return model.get("isSysDefined") === true && passesFilter(model);
            });
        } else if (this.model.get("tab") === "nodes") {
            return this.model.get("alertCollection").filter(model => {
                return (
                  model.get("isSysDefined") === false &&
                  model.get("objectName").indexOf(".SERVER") !== -1 &&
                  passesFilter(model)
                );
            });
        } else if (this.model.get("tab") === "apps") {
            return this.model.get("alertCollection").filter(model => {
                return (
                  model.get("isSysDefined") === false &&
                  model.get("objectName").indexOf(".APPLICATION") !== -1 &&
                  passesFilter(model)
                );
            });
        } else {
            return this.model.get("alertCollection").filter(model => {
                return (
                  model.get("isSysDefined") === false &&
                  model.get("objectName").indexOf(".APPLICATION") === -1 &&
                  model.get("objectName").indexOf(".SERVER") === -1 &&
                  passesFilter(model)
                );
            });
        }
    }

    autoRepairAlerts() {
        console.debug("ShowRepairDialog");
        this.pageBlocker.block();
        (async () => {
            try {
                await api.doAlertAppCleanup();
                await api.createAndRunAlertApp();
                growl.success("Alert app has been repaired successfully.", "Success");
                this.pageBlocker.unblock();
                navigateTo.AlertManager();
            } catch (e) {
                console.error(e);
                growl.error(
                    "There was an error fixing the System Alerts. Please contact support for next steps.",
                    "Error"
                );
                this.pageBlocker.unblock();
            }
        })();
    }
}

@template(repairDialogTemplate)
class RepairDialogView extends App.FormMixinDialog.View {
    @on("click #repair-alerts-button")
    doRepair() {
        this.$el
            .find("#repair-alerts-button")
            .addClass("disabled")
            .html("Please wait while we process your request...");
        api.doAlertAppCleanup()
            .done(() => {
                growl.success("Please re-configure your SMTP Credentails to resume System Alerts", "Success");
            })
            .fail(e => {
                growl.error(
                    "There was an error fixing the System Alerts. Please contact support for next steps.",
                    "Error"
                );
                growl.error(e.message, "System Error");
            })
            .always(() => {
                this.trigger("form:submit");
                navigateTo.SMTPSetup();
            });
    }
}

@template(leftListItemView)
@className("left-list-item collection-item")
@triggers({
    "click ": {
        event: "item:click"
    }
})
class ListItemView extends LayoutView {
    onRender() {
        this.$el.attr("data-id", this.model.get("id"));
    }

    serializeData() {
        let data = this.model.toJSON();
        if (this.model.get("alertType") === ALERT_TYPE.EMAIL) {
            data["alertTypeText"] = "Email";
        } else if (this.model.get("alertType") === ALERT_TYPE.SLACK) {
            data["alertTypeText"] = "Slack";
        } else if (this.model.get("alertType") === ALERT_TYPE.TEAMS) {
            data["alertTypeText"] = "Teams";
        } else {
            data["alertTypeText"] = "In App";
        }
        data["status"] = this.model.get("isEnabled") === true ? "Enabled" : "Not Enabled";
        data["statusClass"] = this.model.get("isEnabled") === true ? "enabled" : "not-enabled";
        data["name"] = this.model.get("name");
        return data;
    }

    @modelEvent("change")
    doRender() {
        this.render();
    }
}

@template(groupHeaderTemplate)
@childViewContainer(".item-list-container")
@childView(ListItemView)
@className("left-group")
@tagName("li")
class GroupItemView extends CompositeView {
    initialize(options) {
        this.model = options.model;
        this.collection = options.model.get("collection");
        this.$el.attr("data-category", this.model.get("name"));
    }

    serializeData() {
        let data = this.model.toJSON();
        if (data["type"] === "APPLICATION") data["type"] = "app";
        if (data["type"] === "WACTIONSTORE") data["type"] = "store";
        return data;
    }

    @onChild("item:click")
    onChildClicked(view) {
        if (!this.model.get("isLiteMode")) {
            navigateTo.Alert(this.model.get("tab"), this.model.get("name"), view.model.get("id"));
        }
        App.vent.trigger("alertmanager:show:detail", view.model);
    }
}

@template('<div class="no-alerts"><img src="app/images/striimline/alerts-empty.svg" alt="No alerts" class="illustration" /><div class="mt-16px ta-c image-description">No alerts found</div></div>')
class NoResultsView extends LayoutView {}

@emptyView(NoResultsView)
@childView(GroupItemView)
@className("collapsible no-shadow expandable")
@tagName("ul")
class GroupsCollectionView extends CollectionView {}
