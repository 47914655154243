import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui-v2";
import { styles } from "../automated-group-deploy-modal.styles";
import metaStoreService from "../../../../../../../../../core/services/metaStoreService/meta-store-service";
import { DeploymentInfoType } from "../automated-group-deploy-modal";
import AppDeployTableComponent from "./app-deploy-table-component";
import growl from "../../../../../../../../../app/components/common/growl";

export interface GroupType {
    ctime: number;
    id: string;
    isEditable: boolean;
    metaInfoStatus: object;
    name: string;
    nsName: string;
    subType: string;
    type: string;
}
export interface FlowType {
    ctime: number;
    id: string;
    name: string;
}

const getDefaultFormValues = (groups: GroupType[], flows: FlowType[]): DeploymentInfoType => {
    const formValues: DeploymentInfoType = {};
    flows.forEach((flow: FlowType) => {
        formValues[flow?.name] = {
            group: groups?.[0]?.name,
            strategy: "any",
            flow: flow?.name
        };
    });
    return formValues;
};

export interface AppDeployTableProps {
    appId: string;
    formValues: DeploymentInfoType;
    setFormValues: (values: DeploymentInfoType) => void;
    showAppNameInTitle: boolean;
}
const AppDeployTable: React.FunctionComponent<AppDeployTableProps> = ({
    appId,
    formValues,
    setFormValues,
    showAppNameInTitle = true
}) => {
    const [deploymentGroups, setDeploymentGroups] = useState<GroupType[] | null>(null);
    const [appFlows, setAppFlows] = useState<FlowType[] | null>(null);
    const [appName, setAppName] = useState<string | null>(null);
    useEffect(() => {
        (async () => {
            try {
                const appModel = await metaStoreService.findById(appId);
                var flows_p = appModel.getApplicationComponentsByType(metaStoreService.entities.FLOW);
                var dg_p = metaStoreService.fetchIdentifiersByType(metaStoreService.entities.DG);

                var [flows, groups] = await Promise.all([flows_p, dg_p]);

                flows.add(appModel);
                groups = groups.toJSON();
                const specialGroups = ["default", "Agents"];
                groups = groups
                    .sort((a: GroupType, b: GroupType) => {
                        if (specialGroups.includes(a.name) && specialGroups.includes(b.name)) {
                            return -a.name.localeCompare(b.name);
                        } else if (specialGroups.includes(a.name)) {
                            return -1;
                        } else if (specialGroups.includes(b.name)) {
                            return 1;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    })
                    .filter(group => {
                        return group.name !== "DefaultAgentMonitoring";
                    });
                setDeploymentGroups(groups);
                setAppFlows(flows.toArray());
                setFormValues(getDefaultFormValues(groups, flows.toArray()));
                setAppName(appModel?.name);
            } catch (error) {
                growl.error(error);
                console.log(error);
            }
        })();
    }, [appId]);

    const handleFormChange = (flow: FlowType, key: string, value: string): void => {
        const flowName = flow?.name;
        const temp = { ...formValues };
        temp[flowName] && (temp[flowName][key] = value);
        setFormValues(temp);
    };
    return (
        <Box sx={styles.table}>
            <Box mb={1} hidden={!showAppNameInTitle}>
                <StriimTypography variant="h4">{appName}</StriimTypography>
            </Box>
            {deploymentGroups && appFlows && (
                <AppDeployTableComponent {...{ formValues, deploymentGroups, appFlows, handleFormChange }} />
            )}
        </Box>
    );
};

export default AppDeployTable;
