import _ from "underscore";

var module = {};
module.nl2br = function(str) {
    if (!_.isString(str)) {
        throw "arg0 should be string";
    }
    return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1<br />$2");
};

module.endsWith = function(string, suffix) {
    if (!string) {
        if (!suffix) {
            return true;
        }
        return false;
    }
    return string.indexOf(suffix, string.length - suffix.length) !== -1;
};

module.ensureEndsWith = function(string, suffix) {
    return module.endsWith(string, suffix) ? string : string + suffix;
};

/**
 * Replace all `search` occurrence with the `replacement`
 */
module.replaceAll = function(string, search, replacement) {
    return string.replace(new RegExp(search, "g"), replacement);
};

function calculateHash(value) {
    var hash = 0,
        i,
        chr;
    if (value.length === 0) return hash;
    for (i = 0; i < value.length; i++) {
        chr = value.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

/**
 * Encode value that will be added to HTML
 * @param value
 * @returns {string | jQuery}
 */
const encodeHtml = value => {
    return $("<div>")
        .text(value)
        .html();
};

const stringToHtml = value => module.nl2br(value.replace(/\</g, "&lt;").replace(/\>/g, "&gt;"));

export default {
    nl2br: module.nl2br,
    endsWith: module.endsWith,
    ensureEndsWith: module.ensureEndsWith,
    replaceAll: module.replaceAll,
    calculateHash: calculateHash,
    encodeHtml: encodeHtml,
    stringToHtml: stringToHtml
};
