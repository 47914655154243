const styles = {
    optionGrid: {
        margin: 0,
        flexWrap: "nowrap",
        columnGap: 2
    },
    optionContentGird: {
        display: "flex",
        flexDirection: "column"
    },
    icon: {
        fill: "none",
        fontSize: 24
    },
    customFlowButton: {
        height: ({ spacing }) => spacing(7),
        flex: 1,
        "& svg": {
            fill: "transparent",
            width: ({ spacing }) => spacing(3),
            height: ({ spacing }) => spacing(3),
            marginRight: ({ spacing }) => spacing(1)
        }
    },
    link: {
        "& svg": {
            fill: "transparent",
            width: ({ spacing }) => spacing(2.5),
            height: ({ spacing }) => spacing(2.5),
            marginLeft: ({ spacing }) => spacing(0.5)
        }
    },
    carouselContainerImage: {
        width: "350px",
        height: "180px",
        fill: "transparent",
        marginTop: "65px",
        marginBottom: "80px"
    },
    categoriesGrid: {
        display: "flex",
        gap: "12px",
        cursor: "pointer",
        flexWrap: "wrap",
        width: "75%"
    },
    viewSelectionGrid: {
        display: "flex",
        gap: "12px",
        cursor: "pointer"
    },
    activeCategory: {
        backgroundColor: "secondary.50",
        color: "primary.600",
        "&.MuiChip-root": {
            backgroundColor: "secondary.50",
            border: ({ palette }) => `1px solid ${palette.secondary[100]}`
        }
    },
    selectText: {
        fontFamily: "Nunito"
    },
    viewIcon: {
        width: ({ spacing }) => spacing(2.5),
        height: ({ spacing }) => spacing(2.5),
        backgroundColor: "transparent",
        borderRadius: 1.5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& svg": {
            width: ({ spacing }) => spacing(2),
            height: ({ spacing }) => spacing(2)
        },
        "&.selected": {
            backgroundColor: "greyscale.200"
        }
    },
    searchCloseIcon: {
        width: 20,
        height: 20
    },
    headerContainer: {
        width: 500,
        paddingBottom: 8
    },
    headerDescription: {
        width: 400
    },
    dropdownItemLink: {
        p: 0,
        m: 0,
        height: "auto",
        ml: 0.5,
        mt: -0.2
    },
    divider: {
        "&::before, &::after": {
            borderColor: "greyscale.300"
        }
    }
};

export default styles;
