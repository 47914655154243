import utils from "core/utils";

export default {
    process: function (newNodeType, nodeModel, parentNode, predefinedComponentName) {
        if (newNodeType !== utils.ENTITIES.OPENPROCESSOR) {
            return;
        }
        if (!parentNode) {
            return;
        }

        if (
            !(
                parentNode.metaObject.type === utils.ENTITIES.STREAM ||
                parentNode.metaObject.type === utils.ENTITIES.CACHE
            )
        ) {
            return;
        }

        nodeModel.metaObject.autoProperties = nodeModel.metaObject.autoProperties || {};
        nodeModel.metaObject.autoProperties.from = parentNode.metaObject.id;
    },
};
