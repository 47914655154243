import React from "react";
import PropTypes from "prop-types";
import { StriimTypography } from "@striim/striim-ui";

import useStyles from "./multi-value.styles";
import { Chip } from "@material-ui/core";

const MultiValue = ({ data, removeProps }) => {
    const classes = useStyles();

    return (
        <Chip
            className={classes.chip}
            size="small"
            variant="outlined"
            label={<StriimTypography variant="body">{data.label}</StriimTypography>}
            onDelete={() => removeProps.onClick()}
        ></Chip>
    );
};
MultiValue.propTypes = {
    data: PropTypes.object,
    removeProps: PropTypes.object
};

export default MultiValue;
