import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUserManagement } from "core/user-management/user-management-context";
import BasicInfo, { validationSchema } from "./components/basic-info/basic-info";
import { ButtonsBar, CancelEditModal } from "src/modules/apps/pages/user-management/common";
import RoleTable from "../components/role-table/role-table";
import PermissionTable from "../components/permission-table/permission-table";
import UsersTable from "../components/users-table/users-table";
import { Grid } from "@mui/material";
import styles from "../../common/users-management.styles";

const RoleCreate = () => {
    const { createRole } = useUserManagement();
    const navigate = useNavigate();
    const formRef = useRef();

    const [addedRoles, setAddedRoles] = useState([]);
    const [addedPermissions, setAddedPermissions] = useState([]);
    const [addedUsers, setAddedUsers] = useState([]);
    const [revokedRoles, setRevokedRoles] = useState([]);
    const [revokedPermissions, setRevokedPermissions] = useState([]);
    const [revokedUsers, setRevokedUsers] = useState([]);
    const [cancelDialogVisible, setCancelDialogVisible] = useState(false);

    const handleSave = async () => {
        const { values: basicInfoValues } = formRef.current;
        const isValid = validationSchema.isValidSync(basicInfoValues);
        if (!isValid) {
            formRef && formRef.current && formRef.current.handleSubmit();
            return;
        }

        await createRole(basicInfoValues.namespace, `${basicInfoValues.namespace}.${basicInfoValues.rolename}`, {
            addedRoles,
            addedPermissions,
            addedUsers,
            revokedRoles,
            revokedPermissions,
            revokedUsers
        });

        navigate("/users", { state: { activeTab: 1 } });
    };

    return (
        <>
            <Grid item xs={12} sx={styles.contentContainer}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <BasicInfo formRef={formRef} />
                    </Grid>
                    <Grid item md={12}>
                        <RoleTable
                            data-testid={"roles-table"}
                            roles={[]}
                            addedRoles={addedRoles}
                            setAddedRoles={setAddedRoles}
                            revokedRoles={revokedRoles}
                            setRevokedRoles={setRevokedRoles}
                            editable={true}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <PermissionTable
                            data-testid={"permisions-table"}
                            permissions={[]}
                            addedPermissions={addedPermissions}
                            setAddedPermissions={setAddedPermissions}
                            revokedPermissions={revokedPermissions}
                            setRevokedPermissions={setRevokedPermissions}
                            editable={true}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <UsersTable
                            data-testid={"users-table"}
                            users={[]}
                            addedUsers={addedUsers}
                            setAddedUsers={setAddedUsers}
                            revokedUsers={revokedUsers}
                            setRevokedUsers={setRevokedUsers}
                            editable={true}
                            prefetch={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} mt={3}>
                <ButtonsBar
                    isDeletable={false}
                    onCancelClick={() => setCancelDialogVisible(true)}
                    onSaveClick={handleSave}
                />
            </Grid>

            <CancelEditModal
                title={`Are you sure you want to discard the new role?`}
                dialogVisible={cancelDialogVisible}
                setDialogVisible={setCancelDialogVisible}
                onConfirm={() => navigate("/users")}
            />
        </>
    );
};

export default RoleCreate;
