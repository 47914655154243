import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import itemTemplate from "./templates/item.html";
import listTemplate from "./templates/list.html";
import MetricTypes from "app/components/monitor/overview/appdata/metrictypes";

var List = {};

List.ItemView = Backbone.Marionette.ItemView.extend({
    initialize: function (options) {
        this.parentModel = options.parentModel;
    },
    tagName: "li",
    ui: {
        listItem: "li",
    },
    template: _.template(itemTemplate),
    serializeData: function () {
        var data = this.model.toJSON();
        switch (data.type) {
            case "SERVER":
                data.icon = "storage";
                break;
            case "APPLICATION":
                data.icon = "layers";
                break;
            default:
                data.icon = "bookmark";
        }
        return data;
    },
    onRender: function () {
        // console.log(this.model);
        if (this.model.id === this.parentModel.selectedItem) {
            this.$el[0].children[0].classList.add("active");
        }
    },
});

List.View = Backbone.Marionette.CompositeView.extend({
    template: _.template(listTemplate),
    childViewContainer: "ul",
    childView: List.ItemView,
    events: {
        "click a": "changeActiveItem",
    },

    childViewOptions: function () {
        return {
            parentModel: this.model,
        };
    },

    changeActiveItem: function (e) {
        e.preventDefault();
        this.model.selectedItem = $(e.currentTarget).data("id");
    },

    modelEvents: {
        change: "render",
    },

    initialize: function (data) {
        this.model.availableMetrics = MetricTypes;
        this.preserveScroll = data.preserveScroll;
        this.scrollDistance = 0;
    },

    onRender: function () {
        // Avoids scrolling to the top when the list re-renders
        if (this.preserveScroll) {
            $(this.el).find("ul").on( "scroll", evt => this.scrollDistance = evt.target.scrollTop);
            if (this.scrollDistance > 0) {
                $(this.el).find("ul").scrollTop(this.scrollDistance);
            }
        }
        var stopOuterscroll = function (evt) {
            evt.stopPropagation();
        };
        $(this.el).find("ul").bind("mousewheel DOMMouseScroll", stopOuterscroll);
    },
});

export default List;
