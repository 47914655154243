import api from "core/api/api";
import $ from "jquery";
import metaStoreService from "core/services/metaStoreService/meta-store-service";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import metaObjects from "core/services/metaStoreService/meta-objects";

export default {
    create: function createNamespaceIfNotExists(desiredNamespace) {
        var deferred = new $.Deferred();

        var id = metaObjectConverter.getId("Global", metaStoreService.entities.NAMESPACE, desiredNamespace);
        metaStoreService.findById(id).then(function(namespace_exists) {
            if (namespace_exists) {
                deferred.resolve(namespace_exists);
            } else {
                var new_namespace = new metaObjects[metaStoreService.entities.NAMESPACE].Model();
                new_namespace.set("name", desiredNamespace);
                new_namespace
                    .save()
                    .then(function() {
                        deferred.resolve(new_namespace);
                    })
                    .fail(function (error) {
                        deferred.reject("Could not create namespace " + error);
                    });
            }
        });

        return deferred.promise();
    },
    setNamespaceContext: function (nsName) {
        var deferred = $.Deferred();

        this.create(nsName)
            .then(function() {
                api.setCurrentNamespace(nsName, true).then(function() {
                    deferred.resolve(nsName);
                });
            })
            .fail(function (error) {
                deferred.reject(error);
            });

        return deferred.promise();
    },
};
