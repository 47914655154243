import App from "app";
import ComponentController from "lib/controllers/component-controller";
import visualizationCapabilities from "./visualizationCapabilities";
import "app/components/dashboard/visualization/charts/charts";
import ensureRegions from "../../common/_ensureRegions";

App.module("Dashboard", function(Dashboard, App, Backbone, Marionette, $, _) {
    Dashboard.VisualizationViewMixin = {
        // Return the QueryVisualization model (which may or may not be this.getVisualizationModel())
        getVisualizationModel: function() {
            // NOT USED ANYWHERE!
            return this.model;
        },
        initialize: function() {
            this.initializeDataSource();
        },
        initializeDataSource: function() {
            var _this = this;

            this.listenTo(this, "reset:datasource", function() {
                _this.unregisterAndRemoveDataSource();
                _this.registerAndSetDataSource();
            });

            var query = this.getVisualizationModel().query;
            this.listenTo(query, "about:to:fetchnew", function() {
                _this.unregisterAndRemoveDataSource();
            });

            this.listenTo(query, "after:fetch", function() {
                _this.registerAndSetDataSource();
            });

            this.registerAndSetDataSource();
        },
        registerAndSetDataSource: function() {
            var visualizationModel = this.getVisualizationModel();
            var config = visualizationModel.config;
            var query = visualizationModel.query;

            if (this.dsRegistered || !query.id || !visualizationCapabilities.supportsQuery(visualizationModel)) {
                return false;
            }

            this.dsRegistered = true;
            this.queryId = query.id;
            this.queryString = query.queryString;
            this.retentionStrategyType = config.retentionStrategyType;
            this.categoryField = config.categoryField;
            App.request(
                "QueryManager:register",
                query.id,
                query.queryString,
                config.retentionStrategyType,
                config.categoryField,
                !visualizationModel._editable,
                function(dataSource) {
                    visualizationModel.dataSource = dataSource;
                    var maxValues = config.maxValues !== undefined ? parseInt(config.maxValues, 10) : 100;
                    var maxPoints = config.maxPoints !== undefined ? parseInt(config.maxPoints, 10) : 500;
                    var maxSeries = config.maxSeries !== undefined ? parseInt(config.maxSeries, 10) : 20;
                    dataSource.retentionStrategy.maxValues = maxValues;
                    dataSource.retentionStrategy.maxPoints = maxPoints;
                    dataSource.retentionStrategy.maxSeries = maxSeries;
                }
            );
        },
        unregisterAndRemoveDataSource: function() {
            if (this.dsRegistered) {
                App.request("QueryManager:unregister", this.queryId, this.retentionStrategyType, this.categoryField);
                this.clean_query_data();
                this.dsRegistered = false;
                var ds = this.getVisualizationModel().dataSource;
                if (ds && typeof ds.stopQueryPolling === "function") {
                    ds.stopQueryPolling();
                }
            }
        },
        onDataPoll: function() {
            if (!this.hasNewData()) {
                return;
            }

            this.showVisualizationNotifications();
            // Scrub data first
            this.clean_query_data();
            // Add fresh data
            this.add_query_data();
        },
        hasNewData: function() {
            var dataSource = this.getVisualizationModel().dataSource;
            if (!dataSource) {
                return false;
            }

            var savedDateTime = this.getVisualizationModel().config.lastRendered;
            if (!savedDateTime) {
                return true;
            }

            return dataSource.lastUpdated > savedDateTime;
        },
        clean_query_data: function() {
            var config = this.getVisualizationModel().config;
            if (config.unset) {
                // NestedType object
                config.unset("data");
                config.unset("last_data");
            } else {
                config.data = null;
                config.last_data = null;
            }
        },
        add_query_data: function() {
            ensureRegions(this);
            var dataSource = this.getVisualizationModel().dataSource;
            if (!dataSource) {
                return;
            }

            // Add the query data and colors to the chart model, so that any dynamic values can reach it
            var config = this.getVisualizationModel().config;
            config.generateSeries = dataSource.categoryField !== "";
            config.data = dataSource.data;
            if (config.data) {
                config.last_data = config.data[config.data.length - 1];
            }
            // Do not update chart if this view hasn"t been shown yet
            var region = this.getRegion("content");
            if (this._isShown && region) {
                region.currentView.trigger("data:ready");
            }
        },
        showVisualizationNotifications: function() {
            var text;
            var visualizationType = this.getVisualizationModel().visualizationType;

            if (
                visualizationType !== "Value" &&
                visualizationType !== "Label" &&
                visualizationType !== "Icon" &&
                !this.getVisualizationModel().query.id
            ) {
                text =
                    'No Query set yet.<div class="instructions">To set a Query, click&nbsp; the' +
                    '<img src="app/components/dashboard/images/light/icon_edit_query_dark.svg"/>' +
                    "button in the toolbar above.</div>";
            }

            if (text) {
                this.showVisualizationNotification(text);
            } else {
                this.hideVisualizationNotification();
            }
        },
        showVisualizationNotification: function(text) {
            this.$(".notifications").html('<div class="notification-content">' + text + "</div>");
        },
        hideVisualizationNotification: function() {
            this.$(".notifications").html("");
        },
        onRender: function() {
            this.renderVisualization();
        },
        renderVisualization: function() {
            var vismodel = this.getVisualizationModel();
            var region = this.getRegion("content");
            var chartview = null;
            if (vismodel.visualizationType === "PredictiveChart") {
                chartview = new App.Chart.Default.MultiGraphView({
                    model: vismodel
                });
            } else {
                chartview = new App.Chart.Default.View({
                    model: vismodel
                });
            }

            region.show(chartview);

            var controller = new App.Dashboard.VisualizationController({
                view: this,
                model: this.getVisualizationModel()
            });

            this.listenTo(controller, "query:poll", this.onDataPoll);

            controller.start();
        },
        onBeforeDestroy: function() {
            this.unregisterAndRemoveDataSource();
        }
    };
    Dashboard.VisualizationView = Marionette.LayoutView.extend(Dashboard.VisualizationViewMixin);

    Dashboard.VisualizationController = ComponentController.extend({
        initialize: function(opts) {
            if (opts.model) {
                this.model = opts.model;
            }

            _(opts).defaults({
                pause: false,
                speed: 2000
            });

            _(this).bindAll("poll");

            ComponentController.prototype.initialize.call(this, opts);
        },
        start: function() {
            this.listenTo(this.model, "change:_polling", function() {
                if (!this.model._polling) {
                    this.stop_polling();
                } else {
                    this.restart_polling();
                }
            });

            this.restart_polling();
        },
        poll: function() {
            this.trigger("query:poll");
        },
        stop_polling: function() {
            if (this.interval) {
                clearInterval(this.interval);
            }
            if (this.model.dataSource.pollingQueryDataLoader) {
                this.model.dataSource.pollingQueryDataLoader.pause();
            }
        },
        restart_polling: function() {
            this.stop_polling();

            this.interval = setInterval(this.poll, this.options.speed);
            var _this = this;
            setTimeout(function() {
                _this.poll();
            }, 1000);

            if (this.model.dataSource.pollingQueryDataLoader) {
                this.model.dataSource.pollingQueryDataLoader.resume();
            }

            if (this.options.pause) {
                return this.stop_polling();
            }
        },
        onBeforeDestroy: function() {
            delete this.options;
            this.stop_polling();
        }
    });
});
