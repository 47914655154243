import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { StriimModal, StriimTypography } from "@striim/striim-ui";
import { Grid } from "@mui/material";

import ManageStriimPageWrapper from "../../manage-striim-wrapper";
import { ConnectionProfileItemHeader } from "..";
import MANAGE_STRIIM_ROUTES from "../../routes.json";
import { styles } from "./view-connection-profile.styles";
import growl from "../../../../../../../app/components/common/growl";
import connectionProfileService from "../connection-profile-service";
import DeleteConnectionProfileModal from "../connection-profiles-list/components/delete-connection-profile-modal";
import TestConnectionModal from "../connection-profiles-list/components/test-connection-modal";
import WarningMessageBox from "../components/warning-message-box";
import { ENDPOINTS, OAUTH_FIELDS_KEYS } from "../constants";
import api from "../../../../../../../core/api/api";
import { getCPValues } from "../utils";

const ViewConnectionProfile = () => {
    const [connectionProfileObj, setConnectionProfileObj] = useState<ConnectionProfile>();
    const [loading, setLoading] = useState<boolean>(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
    const [showTestCPModal, setShowTestCPModal] = useState<boolean>(false);
    const [showAssociatedAppsModal, setShowAssociatedAppsModal] = useState<boolean>(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
    const [associatedApps, setAssociatedApps] = useState([]);
    const [hasUpdatePermission, setHasUpdatePermission] = useState<boolean>(false);
    const [hasDropPermission, setHasDropPermission] = useState<boolean>(false);
    let { name: cpName } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const getCurrentConnectionProfile = async () => {
            try {
                setLoading(true);
                const result = await connectionProfileService.getConnectionProfile(cpName);
                const isUpdateAllowed = await api.checkPermission(result.uuid, "update");
                const isDropAllowed = await api.checkPermission(result.uuid, "drop");
                const formattedCPObject = result;
                const properties = getCPValues(result);
                formattedCPObject.properties = properties;
                setConnectionProfileObj(formattedCPObject);
                setHasUpdatePermission(isUpdateAllowed);
                setHasDropPermission(isDropAllowed);
                setLoading(false);
            } catch (error) {
                //@ts-ignore
                growl.error(error);
                navigate(MANAGE_STRIIM_ROUTES.connectionProfiles);
            }
        };

        if (!connectionProfileObj) {
            getCurrentConnectionProfile();
        }
    }, []);

    const onEditClick = () => {
        const name = connectionProfileObj.nsName + "." + connectionProfileObj.name;
        navigate(`/manage-striim/connection-profiles/edit/${name}`);
    };

    const endPointFields = {
        connectionProfileName: connectionProfileObj?.name,
        endpoint: connectionProfileObj?.endPoint
    };

    const formatKey = key => {
        if (key === OAUTH_FIELDS_KEYS.ExpiresAt) {
            return "Access Token Exipres At";
        }
        const key1 = key
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .replace(/_/g, " ")
            .replace(/\b\w/g, match => match.toUpperCase());
        const newKey = key1.charAt(0).toUpperCase() + key1.substring(1);
        return newKey;
    };

    const formatValue = (key, value) => {
        let formattedValue;
        if (key === OAUTH_FIELDS_KEYS.ExpiresAt) {
            //convert to milliseconds
            formattedValue = !isNaN(value) ? new Date(Number.parseInt(value) * 1000).toString() : value;
        } else if (key === OAUTH_FIELDS_KEYS.RefreshTokenExpiresAt) {
            formattedValue = !isNaN(value) ? new Date(Number.parseInt(value) * 1000).toString() : value;
        } else {
            formattedValue = typeof value !== "object" ? value : "*******";
        }
        if (key === "Endpoint") {
            formattedValue = ENDPOINTS[value.toLowerCase()];
        }
        return formattedValue;
    };

    const formatFields = isEndpoint => {
        let formatdata = isEndpoint ? endPointFields : connectionProfileObj?.properties;
        return (
            <>
                {Object.entries(formatdata).map(([key, values]) => {
                    if (!key.includes("_encrypted")) {
                        const formattedKey = formatKey(key);
                        if (
                            !values ||
                            [OAUTH_FIELDS_KEYS.RefreshTokenExpiresIn, OAUTH_FIELDS_KEYS.ExpiresIn].includes(key)
                        ) {
                            return;
                        }
                        const formattedValue = formatValue(key, values);
                        return (
                            <Grid sx={styles.grid} key={key} data-test-id={`${key}-fields`} mb={1}>
                                <Grid item xs={5} data-test-id="properties">
                                    <StriimTypography variant="body3" data-test-id={formattedKey}>
                                        {formattedKey}
                                    </StriimTypography>
                                </Grid>
                                <Grid item xs={7} data-test-id="value">
                                    <StriimTypography variant="body3" data-test-id={formattedValue}>
                                        {formattedValue}
                                    </StriimTypography>
                                </Grid>
                            </Grid>
                        );
                        return null;
                    }
                })}
            </>
        );
    };

    const onTestClick = () => {
        setShowTestCPModal(true);
    };

    const onAssociatedAppsClick = async () => {
        try {
            const result = await connectionProfileService.getAssociatedApps(connectionProfileObj.id);
            setAssociatedApps(result);
            setShowAssociatedAppsModal(true);
        } catch (error) {
            //@ts-ignore
            growl.error(error);
        }
    };

    const onDeleteClick = () => {
        setShowDeleteConfirmationModal(true);
    };

    const handleDelete = async () => {
        try {
            await connectionProfileService.deleteConnectionProfile(
                connectionProfileObj.nsName + "." + connectionProfileObj.name
            );
            //@ts-ignore
            growl.success(`Connection profile ${connectionProfileObj.name} deleted successfully`);
            navigate(MANAGE_STRIIM_ROUTES.connectionProfiles);
        } catch (error) {
            //@ts-ignore
            growl.error(error);
            setShowDeleteConfirmationModal(false);
            setShowDeleteWarning(true);
        }
    };

    return (
        <ManageStriimPageWrapper
            header={
                <ConnectionProfileItemHeader
                    title={connectionProfileObj ? connectionProfileObj.nsName + "." + connectionProfileObj.name : ""}
                    showBackButton={true}
                    showEditButton={hasUpdatePermission}
                    showDeleteButton={hasDropPermission}
                    showActionControls={true}
                    onAssociatedAppsClick={onAssociatedAppsClick}
                    onTestClick={onTestClick}
                    onEditClick={onEditClick}
                    onDeleteClick={onDeleteClick}
                />
            }
            loading={loading || !connectionProfileObj}
        >
            {!loading && connectionProfileObj && (
                <Grid>
                    {showDeleteWarning && (
                        <Grid item>
                            <WarningMessageBox
                                name={`${connectionProfileObj.nsName}.${connectionProfileObj.name}`}
                                handleDoneClick={() => setShowDeleteWarning(false)}
                            />
                        </Grid>
                    )}
                    <Grid item xs={8} mt={2} md={8} mb={2}>
                        <StriimTypography variant="h3">1. Endpoint Configurations</StriimTypography>
                        <div style={styles.div}>{formatFields(true)}</div>
                    </Grid>
                    <Grid item xs={8} md={8} mb={4}>
                        <StriimTypography variant="h3">2. Connection Properties</StriimTypography>
                        <div style={styles.div}>{formatFields(false)}</div>
                    </Grid>
                </Grid>
            )}
            <>
                <StriimModal
                    isVisible={showAssociatedAppsModal}
                    titleContent={"Associated Apps"}
                    cancelContent={null}
                    confirmContent={"Done"}
                    onConfirm={() => setShowAssociatedAppsModal(false)}
                >
                    {associatedApps.length ? (
                        <ol
                            style={{
                                listStyle: "inside"
                            }}
                        >
                            {associatedApps.map(app => (
                                <li key={app.name}>{`${app.nsName}.${app.name}`}</li>
                            ))}
                        </ol>
                    ) : (
                        "No Associated Apps found"
                    )}
                </StriimModal>
                {showDeleteConfirmationModal && (
                    <DeleteConnectionProfileModal
                        isVisible={showDeleteConfirmationModal}
                        setShowDeleteConfirmationModal={setShowDeleteConfirmationModal}
                        onDeleteClick={handleDelete}
                    />
                )}
                {showTestCPModal && (
                    <TestConnectionModal
                        cpName={connectionProfileObj.nsName + "." + connectionProfileObj.name}
                        testCPModalVisible={showTestCPModal}
                        setTestCPModalVisible={setShowTestCPModal}
                    />
                )}
            </>
        </ManageStriimPageWrapper>
    );
};

export default ViewConnectionProfile;
