import api from "core/api/api";
import Tracker from "core/services/tracker/tracker";
import ChameleonTracker from "core/services/tracker/chameleonTracker";

var module = {};

module.enableTrackingIfEnabled = function () {
    api.shouldTrackUI().then(function(shouldTrack) {
        if (shouldTrack) {
            Tracker.getInstance().addSubscribers([Tracker.libraries.SNOWPLOW]);
            ChameleonTracker.getInstance().addSubscribers([ChameleonTracker.libraries.CHAMELEON]);
        }
    });
};

export default module;
