import React, { useMemo } from "react";
import { FlowStatus } from "../../../../status-management";
import { CircularProgress } from "@material-ui/core";
import AppMetricsCard from "./app_metrics_card/app_metrics_card";

const AppMetricsComponent = ({ loaded, appData, app }) => {
    const getAppMetricComponent = useMemo(() => {
        if (loaded && appData?.status === FlowStatus.RUNNING) {
            return <AppMetricsCard appData={appData} running />;
        } else if (appData?.status === FlowStatus.CRASH) {
            return <AppMetricsCard appData={appData} crash />;
        } else if (appData === null && app?.flowStatus !== FlowStatus.CREATED) {
            return <AppMetricsCard appData={appData} created />;
        } else {
            return <AppMetricsCard />;
        }
    }, [appData, loaded, app]);

    return loaded ? getAppMetricComponent : <CircularProgress />;
};

export default AppMetricsComponent;
