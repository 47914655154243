import React from "react";
import { StriimLink, StriimTypography } from "@striim/striim-ui";
import PropTypes from "prop-types";
import sxStyles from "./explore.styles";
import { Box } from "@mui/material";

const ExploreListItem = ({ item }) => {
    return (
        <Box sx={sxStyles.documentationItem}>
            {item.title && (
                <StriimLink target="_blank" href={item.link} sx={sxStyles.listItem}>
                    {item.title}
                </StriimLink>
            )}
            <br />
            {item.description && (
                <StriimTypography variant="body4" color="greyscale.700">
                    {item.description}
                </StriimTypography>
            )}
        </Box>
    );
};

export default ExploreListItem;

ExploreListItem.propTypes = {
    item: PropTypes.object.isRequired
};
