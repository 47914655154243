import dateLib from "core/utils/date-utils";
import getApiSentinelMonitorService from "../../../guardian/sentinel-monitor/service/sentinel-moniter-service";
import { SDIOccurrencesDetailed } from "../../../guardian/sentinel-monitor/service/sentinel-monitor.types";
import { timezone } from "../../../../../../core/utils/date-utils";

export const getPercentage = (a, b) => {
    const res = (a / b) * 100;
    return res ? Number(Number(res).toFixed(2)) : 0;
};

export const getReadableTime = ms => dateLib(ms).format("MMM D, YYYY h:mm a") + " " + timezone;

export const getGeneratedTime = ms => dateLib(ms).format("MMM D, YYYY [at] h:mm a ") + timezone;

export const getTopTenElements = (arr, key) => arr.sort((a, b) => b[key] - a[key]).slice(0, 10);

export const sortData = (arr, key) => arr.sort((a, b) => b[key] - a[key]);

export const formatIdentifiersTableData = (data: SDIOccurrencesDetailed, appLevel: boolean = false) => {
    let totalEvents = 0;
    const filterByImportance = item => {
        return item.importance === "HIGH" || (appLevel && item.importance === "MEDIUM");
    };

    const tableData = Object.values(data)
        .filter(filterByImportance)
        .map(item => {
            totalEvents = totalEvents + item.eventsWithIdentifier;
            const eventsPercentage = getPercentage(item.eventsWithIdentifier, item.occurrences);
            return {
                name: item.label,
                events: `${item.eventsWithIdentifier} (${eventsPercentage}%)`,
                eventsWithIdentifier: item.eventsWithIdentifier
            };
        });
    return [totalEvents, getTopTenElements(tableData, "eventsWithIdentifier")];
};

export const fetchReportData = async (selectedApps: string[], selectedRange: { from: number; to: number }, timeFormat, fromTime, toTime) => {
    const sentinelMonitorService = getApiSentinelMonitorService();
    try {
        const [
            eventsProcessedDataForApps,
            appsEventsProcessedChartData,
            sensitiveDataOccurrencesChartData,
            sensitiveDataOccurrences,
            globalSentinelReportData,
        ] = await Promise.all([
            sentinelMonitorService.getAppsEventsProcessedData(
                selectedApps,
                fromTime,
                toTime,
            ),
            sentinelMonitorService.getAppsEventsProcessedChartData(
                selectedApps,
                selectedRange.from,
                selectedRange.to,
                timeFormat
            ),
            sentinelMonitorService.getSensitiveDataOccurrencesForAppsChartData(
                selectedApps,
                selectedRange.from,
                selectedRange.to,
                timeFormat
            ),
            sentinelMonitorService.getSensitiveDataOccurrencesForApps(
                selectedApps,
                fromTime,
                toTime,
            ),
            sentinelMonitorService.getGlobalSentinelReportData(selectedApps, fromTime, toTime)
        ]);

        return {
            eventsProcessedDataForApps,
            appsEventsProcessedChartData,
            sensitiveDataOccurrencesChartData,
            sensitiveDataOccurrences,
            globalSentinelReportData
        };
    } catch (error) {
        console.error("Error fetching report data:", error);
    }
};

export const formatSDIApplications = applications => {
    const result = [];
    let totalEventsWithIdentifiers = 0;
    let totalOccurrences = 0;

    for (const [key, value] of Object.entries(applications)) {
        const [nameSpace, name] = key.split(".");
        const { ENCRYPT, MASK, NO_ACTION } = value.sensitiveDataActions;

        totalEventsWithIdentifiers = totalEventsWithIdentifiers + value.eventsWithIdentifier;
        totalOccurrences = totalOccurrences + value.occurrences;

        result.push({
            sourceAdapters: value.sourceAdapters,
            targetAdapters: value.targetAdapters,
            sourceNames: value.sourceNames,
            name: name,
            nameSpace: nameSpace,
            eventsWithIdentifier: value.eventsWithIdentifier,
            occurrences: value.occurrences,
            ENCRYPTED: ENCRYPT,
            MASKED: MASK,
            NO_ACTION: NO_ACTION
        });
    }
    return {
        formattedData: getTopTenElements(result, "eventsWithIdentifier"),
        totalEventsWithIdentifiers: totalEventsWithIdentifiers,
        totalOccurrences: totalOccurrences
    };
};
