import propertyTemplateService from "../../../core/services/metaStoreService/property-template-service";

const OAUTH_URL_PROPERTIES = {
    connectionUrl: "HOST",
    ConnectionUrl: "HOST"
};

const AUTHR_FEATURE_OFF_STATUS_CODE = 412;

const PROPERTIES = {
    STRIPE: {
        idp: "stripe",
        values: {},
        OAuthQueryParams: []
    },
    ZENDESK: {
        values: {
            connectionUrl: "https://striim.com"
        },
        OAuthQueryParams: ["connectionUrl"],
        idp: "zendesk"
    },
    HUBSPOT: {
        idp: "hubspot",
        values: {},
        OAuthQueryParams: []
    }
};

const getAuthrParams = source => {
    switch (source) {
        case "StripeReader":
            return PROPERTIES.STRIPE;
        case "ZendeskReader":
            return PROPERTIES.ZENDESK;
        case "HubSpotReader":
            return PROPERTIES.HUBSPOT;
    }
};

const getOAuthURL = (authrHostURL, OAuthQueryParams = [], idp, values = {}) => {
    let url = `${authrHostURL}/do/${idp}/init?origin=${authrHostURL}`;
    let propertiesInURL = OAuthQueryParams.reduce((previous, current) => {
        let propertyValue = values[current];
        if (propertyValue) {
            previous += `&${OAUTH_URL_PROPERTIES[current]}=${propertyValue}`;
        }
        return previous;
    }, "");
    url = `${url}${propertiesInURL}`;
    return url;
};

const isOAuthServiceEnabled = async (source, authrHostURL) => {
    const { idp, values, OAuthQueryParams } = getAuthrParams(source);
    let url = "";
    try {
        url = getOAuthURL(authrHostURL, OAuthQueryParams, idp, values);
    } catch (error) {
        return false;
    }
    let request;
    try {
        request = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: getBodyParams()
        });
        return request?.status !== AUTHR_FEATURE_OFF_STATUS_CODE;
    } catch (error) {
        return false;
    }
};

const getBodyParams = () => {
    const { licenseKey, productKey, clusterName, companyName } = propertyTemplateService.getServerInfo();
    const data = new URLSearchParams();
    data.append("product_key", productKey);
    data.append("license_key", licenseKey);
    data.append("cluster_name", clusterName);
    data.append("company_name", companyName);
    return data;
}

export { isOAuthServiceEnabled, getOAuthURL, getBodyParams };
