import React, { useContext, useEffect } from "react";
import ConfigureTarget from "../configure-target/configure-target";
import ValidateTarget from "../validate-target/validate-target";
import ReviewWrapper from "../summary/summary";
import { Steps, getStepID } from "../../target-steps";
import { WizardContext } from "../../../wizard-context";
import navigateTo from "../../../../../navigate-to";
import localStorageUtils from "../../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/local-storage-utils";
import { getWizardType } from "../../../utils/checkAdapterType";
import { isAutomatedWizard } from "../../../../../../app/components/appwizard/component-wizards/wizards/source/database-reader/app-services";

interface TargetLayoutProps {
    target: string;
    appName: string;
    templateID: string;
    stepName: string;
}

const TargetLayout = ({ target, appName, templateID, stepName }: TargetLayoutProps) => {
    const {
        currentStep,
        setWizardAppName,
        setCurrentStep,
        setTemplateID,
        setIsAutomatedILCDC,
        setWizardType,
        setSourceInfo,
        setCdcSourceInfo,
        setTargetInfo,
        setCdcTargetInfo
    } = useContext(WizardContext);

    const navigateParams = {
        appName,
        templateId: templateID,
        stepId: 1,
        force: false
    };

    useEffect(() => {
        const isILCDCApp = isAutomatedWizard();
        setTemplateID(templateID);
        setCurrentStep(getStepID(stepName));
        setWizardAppName(appName);
        setIsAutomatedILCDC(isILCDCApp);
    }, []);

    useEffect(() => {
        if (localStorageUtils.isKeyPresent(localStorageUtils.getTargetKey(appName))) {
            const localStoragetData = localStorageUtils.getItem(localStorageUtils.getTargetKey(appName));
            setTargetInfo(localStoragetData.targetInfo);
            setCdcTargetInfo(localStoragetData.cdcTargetInfo);
            setSourceInfo(localStoragetData.sourceInfo);
            setCdcSourceInfo(localStoragetData.cdcSourceInfo);
            setWizardType(getWizardType(localStoragetData.sourceInfo.adapterName));
        }
    }, []);

    switch (currentStep) {
        case Steps.ConfigureTarget:
            navigateTo.AppWizardTemplate({ ...navigateParams, stepName: "configure-target" });
            return <ConfigureTarget target={target} />;
        case Steps.ValidateTarget:
            navigateTo.AppWizardTemplate({ ...navigateParams, stepName: "validate-target" });
            return <ValidateTarget target={target} />;
        case Steps.Review:
            navigateTo.AppWizardTemplate({ ...navigateParams, stepName: "review" });
            return <ReviewWrapper target={target} />;
    }
};

export default TargetLayout;
