import _ from "underscore";
import TimeParser from "./time-parser";
import BaseControl from "./../base-control";
import NumberField from "./../text-field/number-field";
import Select from "./../select/select";
import ArrayDataSource from "./../select/array-datasource";
import template from "./interval.html";

var Model = BaseControl.Model.extend({
    defaults: {
        value: null,
        units: [TimeParser.ranges.second, TimeParser.ranges.minute, TimeParser.ranges.hour, TimeParser.ranges.day],
    },
});

var View = BaseControl.View.extend({
    template: _.template(template),
    className: "interval-control",

    regions: {
        valueRegion: ".value",
        unitRegion: ".unit",
    },

    initialize: function () {
        this.value = NumberField.create();
        this.unit = Select(ArrayDataSource(this.model.units), {
            allowClear: false,
            hideSearch: true,
        }).create();
    },

    onRender: function () {
        this.valueRegion.show(this.value);
        this.unitRegion.show(this.unit);
        this.unit.setValue(TimeParser.ranges.second);

        BaseControl.View.prototype.onRender.apply(this, arguments);

        this.listenTo(
            this.value.model,
            "change:value",
            function () {
                this.setValueFromView();
            }.bind(this)
        );

        this.listenTo(
            this.unit.model,
            "change:value",
            function () {
                this.setValueFromView();
            }.bind(this)
        );
    },

    getValueInMicroseconds: function (value) {
        return value;
    },

    setViewEnabled: function () {
        BaseControl.View.prototype.setViewEnabled.apply(this);
        var enabled = this.getEnabled();
        this.value.setEnabled(enabled);
        this.unit.setEnabled(enabled);
    },

    getViewValue: function () {
        var val = this.value.getValue();
        if (!val) {
            return null;
        }

        return val + " " + this.unit.getValue();
    },

    setViewValue: function () {
        var value = this.getValue();
        if (!value) {
            this.value.setValue("");
            this.unit.setValue(TimeParser.ranges.second);
        } else {
            var duration = TimeParser.inDuration(this.getValueInMicroseconds(value));
            this.value.setValue(duration.value);
            this.unit.setValue(duration.unit);
        }
    },
});

export default _.extend({}, BaseControl, {
    Model: Model,
    View: View,
});
