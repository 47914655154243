import metaStoreService from "core/services/metaStoreService/meta-store-service";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import api from "core/api/api";

const PropertyVariablesService = {
    savePropertyVariable: propertyVariable => {
        return new Promise((resolve, reject) => {
            const newPropertyVariable = new metaStoreService.create(metaStoreService.entities.PROPERTYVARIABLE);

            newPropertyVariable.set("value", propertyVariable.value);
            newPropertyVariable.set("name", propertyVariable.name);
            newPropertyVariable.set("nsName", propertyVariable.nsName.value);

            newPropertyVariable
                .save()
                .then(function() {
                    resolve(propertyVariable);
                })
                .fail(function(e) {
                    reject(e);
                });
        });
    },
    deletePropertyVariable: propertyVariable => {
        return new Promise((resolve, reject) => {
            api.dropObject({
                type: metaStoreService.entities.PROPERTYVARIABLE,
                name: metaObjectConverter.getShortId(propertyVariable.nsName, propertyVariable.name)
            })
                .then(function() {
                    resolve(propertyVariable);
                })
                .fail(function(e) {
                    reject(e);
                });
        });
    },
    getPropertyVariables: function() {
        return new Promise((resolve, reject) => {
            metaStoreService
                .fetchCollection(metaStoreService.entities.PROPERTYVARIABLE)
                .then(propertyVariables => {
                    resolve(propertyVariables.models);
                })
                .fail(e => {
                    reject(e);
                });
        });
    }
};
export default PropertyVariablesService;
