import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ breakpoints, spacing }) => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        minHeight: `calc(100vh - ${spacing(6)}px)`
    },
    content: {
        marginTop: 80,
        [breakpoints.up("lg")]: {
            paddingLeft: spacing(3),
            paddingRight: spacing(3),
        },
        [breakpoints.down("md")]: {
            paddingLeft: spacing(2),
            paddingRight: spacing(2)
        }
    },
    listWrapper: {
        display: "flex",
        flexWrap: "wrap",
        [breakpoints.up("lg")]: {
            marginLeft: spacing(-2),
            marginRight: spacing(-2)
        }
    },
    listWrapperWide: {
        display: "flex",
        flexWrap: "wrap",
        [breakpoints.up("lg")]: {
            marginLeft: spacing(-2),
            marginRight: spacing(-2)
        },
        flexDirection: "column",
        marginRight: "18px !important"
    }
}));
