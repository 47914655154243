import baseMetaObject from "core/services/metaStoreService/metaObjects/base-meta-object";

const module = {};

module.Model = baseMetaObject.Model.extend({
    defaults: {
        type: "ROUTER",
        inStream: String,
        alias: String,
        forwardingRules: Array,
        details: Object,
        uiconfig: Object
    },
    validate: function() {
        var validationError = {};
        if (baseMetaObject.Model.prototype.validate) {
            validationError = baseMetaObject.Model.prototype.validate.call(this);
            validationError = validationError === undefined ? {} : validationError;
        }
        function validateValue(rule){
            if (["java.lang.String","string"].includes(rule.attributeDataType) && ["like", "notlike", "equal", "notequal"].includes(rule.condition)) {
                return true;
            }              
            return false;
        }
        var allRulesValid =
            this.details &&
            this.details.conditionalOutputStreams &&
            _.every(this.details.conditionalOutputStreams, function(rule) {
                if (rule.forwardingExpr && rule.outputStream) {
                    return true;
                } else {
                    return rule.attribute && rule.condition && rule.outputStream && (validateValue(rule) || rule.value);
                }
            });
        if (!allRulesValid) {
            validationError["details"] = "Conditions are not configured correctly. Please review and try again.";
        }
        return _.isEmpty(validationError) ? undefined : validationError;
    }
});

module.Collection = baseMetaObject.Collection.extend({
    model: module.Model
});

export default module;
