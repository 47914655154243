import { Box } from "@mui/material";
import React from "react";
import { getPriorityImage } from "../../../sentinel-live-monitor/components/sentinel-table/sentinel-table-sdi";

const SidebarStates = {
    CLOSED: "closed",
    OPENED: "opened",
    EXPANDED: "expanded"
} as const;

const SidebarWidth = {
    [SidebarStates.CLOSED]: "50px",
    [SidebarStates.OPENED]: "200px",
    [SidebarStates.EXPANDED]: "100%"
};

const ActionLabel = {
    encrypt: "Encrypt Action",
    mask: "Mask Action",
    no_action: "No Action"
};

const ImportanceElement = ({ icon, label }) => {
    return (
        <Box sx={{ display: "inline-flex" }} alignItems={"center"} gap={1 / 2}>
            {icon}
            {label}
        </Box>
    );
};

const ImportanceLabel = {
    high: <ImportanceElement label="High Importance Identifiers" icon={getPriorityImage("HIGH")} />,
    medium: <ImportanceElement label="Medium Importance Identifiers" icon={getPriorityImage("MEDIUM")} />,
    low: <ImportanceElement label="Low Importance Identifiers" icon={getPriorityImage("LOW")} />
};

type SidebarType = typeof SidebarStates[keyof typeof SidebarStates];

export { SidebarStates, SidebarWidth, SidebarType, ActionLabel, ImportanceLabel };
