import React, { useState, useEffect } from "react";
import { StriimButton } from "@striim/striim-ui";
import footerClasses from "../../../../common/footer/footer.styles";
import { Grid } from "@mui/material";
import DropAppModal from "../../../../../../generic/drop-app-modal/drop-app-modal";
import metaObjectConverter from "core/services/metaStoreService/metaobject-converter";
import metaStoreService from "core/services/metaStoreService/meta-store-service";

const Footer = ({
    store,
    hasCancel = true,
    cancelDisabled,
    onBack,
    backDisabled,
    backButtonText = "Back",
    next = "Next",
    onNext,
    nextDisabled,
    hasSave,
    onSave
}) => {
    const { appName: wizardAppName } = store;
    const [modalVisible, setModalVisible] = useState(false);
    const [appModel, setAppModel] = useState(null);
    const [nsName, appName] = wizardAppName.split(".");

    useEffect(() => {
        (async () => {
            const app = await metaStoreService.findById(
                metaObjectConverter.getId(nsName, metaStoreService.entities.APPLICATION, appName)
            );
            const appJSON = app.toJSON();
            appJSON.shortName = wizardAppName;
            setAppModel(appJSON);
        })();
    }, []);

    return (
        <>
            <Grid container sx={footerClasses.footer}>
                <Grid item>
                    {hasCancel && (
                        <StriimButton
                            variant="text"
                            onClick={() => setModalVisible(true)}
                            disabled={cancelDisabled}
                            data-test-id="cancel-button"
                        >
                            Cancel
                        </StriimButton>
                    )}
                </Grid>
                <Grid item>
                    <StriimButton
                        variant="secondary"
                        onClick={onBack}
                        disabled={backDisabled}
                        sx={{ mr: 1 }}
                        data-test-id={"back-button"}
                    >
                        {backButtonText}
                    </StriimButton>
                    {hasSave && (
                        <StriimButton variant="secondary" sx={{ mr: 1 }} onClick={onSave} data-test-id={"save-button"}>
                            Save & Exit
                        </StriimButton>
                    )}
                    <StriimButton
                        variant="primary"
                        onClick={onNext}
                        disabled={nextDisabled}
                        data-test-id={"next-button"}
                    >
                        {next}
                    </StriimButton>
                </Grid>
            </Grid>
            {modalVisible && (
                <DropAppModal
                    store={store}
                    app={appModel}
                    dropModalVisible={modalVisible}
                    setDropModalVisible={setModalVisible}
                />
            )}
        </>
    );
};

export default Footer;
