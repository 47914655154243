import React from "react";
import { Grid } from "@mui/material";
import { StriimTypography } from "@striim/striim-ui";
import StatusChip from "src/generic/status-chip/status-chip";
import PropTypes from "prop-types";

function DataTableCell({ isSmartAlert, alertType, alertName }) {
    return (
        <Grid>
            <Grid container style={{ gap: "5px" }}>
                <StriimTypography variant={"h3"}>{alertName}</StriimTypography>
                {isSmartAlert && <StatusChip label="Smart Alert" />}
            </Grid>
            <StriimTypography>Type: {alertType}</StriimTypography>
        </Grid>
    );
}
DataTableCell.propTypes = {
    alertName: PropTypes.string.isRequired,
    isSmartAlert: PropTypes.bool.isRequired,
    alertType: PropTypes.string.isRequired
};

export default DataTableCell;
